import React from "react";
import "../../assets/css/skeleton.css";

const SketetonClientMatches: React.FC = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4 col-xl-4 col-12 d-flex justify-content-center align-items-center">
          <div className="box max-w-263 ">
            <div className="skeleton ">
              <div className="flex1">
                <div className="flex justify-content-end">
                  <div className="close-circle"></div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="circle "></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h8 w50 mt-3"></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h8 w50 mt-2"></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h4 mt-3 mb-1"></div>
                  </div>
                </div>
                <div className="flex1 mt-2 mb-3">
                  <div className="flex flex-row justify-content-center gap-4 align-items-center">
                    <div className="btn-circle "></div>
                    <div className="btn-circle "></div>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center">
                  <div className="square h25 w75"></div>
                </div>
                <div className="flex1 mt-3">
                  <div className="square h40"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xl-4 col-12 d-flex justify-content-center align-items-center">
          <div className="box max-w-263 ">
            <div className="skeleton ">
              <div className="flex1">
                <div className="flex justify-content-end">
                  <div className="close-circle"></div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="circle "></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h8 w50 mt-3"></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h8 w50 mt-2"></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h4 mt-3 mb-1"></div>
                  </div>
                </div>
                <div className="flex1 mt-2 mb-3">
                  <div className="flex flex-row justify-content-center gap-4 align-items-center">
                    <div className="btn-circle "></div>
                    <div className="btn-circle "></div>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center">
                  <div className="square h25 w75"></div>
                </div>
                <div className="flex1 mt-3">
                  <div className="square h40"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xl-4 col-12 d-flex justify-content-center align-items-center">
          <div className="box max-w-263 ">
            <div className="skeleton ">
              <div className="flex1">
                <div className="flex justify-content-end">
                  <div className="close-circle"></div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="circle "></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h8 w50 mt-3"></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h8 w50 mt-2"></div>
                  </div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h4 mt-3 mb-1"></div>
                  </div>
                </div>
                <div className="flex1 mt-2 mb-3">
                  <div className="flex flex-row justify-content-center gap-4 align-items-center">
                    <div className="btn-circle "></div>
                    <div className="btn-circle "></div>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center">
                  <div className="square h25 w75"></div>
                </div>
                <div className="flex1 mt-3">
                  <div className="square h40"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SketetonClientMatches;
