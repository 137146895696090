import React, { useEffect, useState, useMemo } from "react";
import { Card, Col, Row } from "reactstrap";
import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";
import moment from "moment";

const NewFormEight = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;
  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    traumaHistory: {
      isBadAccident: null,
      badAccidentDescribe: "",
      isNaturalDisaster: null,
      naturalDisasterdDescribe: "",
      isMilitaryCombat: null,
      militoryCombatDescribe: "",
      isSeriousAttack: null,
      seriousAttackDescribe: "",
      isSexualCoercion: null,
      ageOfSexualCoercion: 0,
      describeSexualCoercion: "",
      isTraumaticExperience: null,
      describeTraumaticExperience: "",
      intrusiveThoughts: null,
      avoidanceBehavior: null,
      hypervigilance: null,
      emotionalDetachment: null,
      selfBlame: null,
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        traumaHistory: data?.traumaHistory,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues, // Use memoized values here
      }));
    }
  }, [memoizedUpdateValues]);

  const handleUpdateData = () => {
    const updateDetails = {
      formHeader: {
        ...formData?.formHeader,
      },
      traumaHistory: {
        ...formData?.traumaHistory,
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              //   updateAssesmentDetails();
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Card className="match-card-header p-2 mb-2">
          <h5 className="fw-500" style={{ margin: "auto" }}>
            TRAUMA HISTORY : Share only what you feel comfortable sharing
          </h5>
        </Card>
        <Card className="match-card-body p-3">
          <Row className="mb-0">
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                Have you ever been in a really bad accident (e.g.,Car,Work,etc.) ?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isBadAccident === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isBadAccident === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isBadAccident: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isBadAccident === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isBadAccident === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isBadAccident: result ? null : false,
                        badAccidentDescribe: "",
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={12}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      If yes, describe :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{
                        width: "100%",

                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        border: "1px dotted  #ccc",
                        padding: "2px",
                        outline: "none",
                        borderRadius: "8px",
                      }}
                      type="text"
                      disabled={!formData?.traumaHistory?.isBadAccident}
                      value={formData?.traumaHistory?.badAccidentDescribe || ""}
                      onChange={e => {
                        setFormData(() => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            badAccidentDescribe: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                Have you ever been in a natural disaster ?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isNaturalDisaster === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isNaturalDisaster === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isNaturalDisaster: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isNaturalDisaster === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isNaturalDisaster === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isNaturalDisaster: result ? null : false,
                        naturalDisasterdDescribe: "",
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={12}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      If yes, describe :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{
                        width: "100%",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        border: "1px dotted  #ccc",
                        padding: "2px",
                        outline: "none",
                        borderRadius: "8px",
                      }}
                      type="text"
                      disabled={!formData?.traumaHistory?.isNaturalDisaster}
                      value={formData?.traumaHistory?.naturalDisasterdDescribe || ""}
                      onChange={e => {
                        setFormData(() => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            naturalDisasterdDescribe: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                Have you ever been in military combat ?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isMilitaryCombat === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isMilitaryCombat === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isMilitaryCombat: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isMilitaryCombat === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isMilitaryCombat === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isMilitaryCombat: result ? null : false,
                        militoryCombatDescribe: "",
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={12}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      If yes, describe :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{
                        width: "100%",
                        //  border: "none",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        border: "1px dotted  #ccc",
                        padding: "2px",
                        outline: "none",
                        borderRadius: "8px",
                      }}
                      type="text"
                      disabled={!formData?.traumaHistory?.isMilitaryCombat}
                      value={formData?.traumaHistory?.militoryCombatDescribe || ""}
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            militoryCombatDescribe: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                Has anyone (including a family member or friend) ever attacked you with a weapon OR with the intent to seriously injure or kill you?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isSeriousAttack === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isSeriousAttack === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isSeriousAttack: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isSeriousAttack === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isSeriousAttack === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isSeriousAttack: result ? null : false,
                        seriousAttackDescribe: "",
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={12}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      If yes, describe :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{
                        width: "100%",
                        //  border: "none",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        border: "1px dotted  #ccc",
                        padding: "2px",
                        outline: "none",
                        borderRadius: "8px",
                      }}
                      type="text"
                      disabled={!formData?.traumaHistory?.isSeriousAttack}
                      value={formData?.traumaHistory?.seriousAttackDescribe || ""}
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            seriousAttackDescribe: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                Has anyone (including a family member or friend) ever used physical force, threats, or coercion to make you have unwanted sexual contact?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isSexualCoercion === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isSexualCoercion === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isSexualCoercion: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isSexualCoercion === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isSexualCoercion === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isSexualCoercion: result ? null : false,
                        ageOfSexualCoercion: 0,
                        describeSexualCoercion: "",
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={6}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      If yes, at what age:
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="number"
                      min={1}
                      disabled={!formData?.traumaHistory?.isSexualCoercion}
                      value={formData?.traumaHistory?.ageOfSexualCoercion ?? ""}
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            ageOfSexualCoercion: Number(e.target.value),
                            isBadAccident: formData?.traumaHistory?.isBadAccident ?? null,
                            badAccidentDescribe: formData?.traumaHistory?.badAccidentDescribe ?? "",
                            isNaturalDisaster: formData?.traumaHistory?.isNaturalDisaster ?? null,
                            naturalDisasterdDescribe: formData?.traumaHistory?.naturalDisasterdDescribe ?? "",
                            isMilitaryCombat: formData?.traumaHistory?.isMilitaryCombat ?? null,
                            militoryCombatDescribe: formData?.traumaHistory?.militoryCombatDescribe ?? "",
                            isSeriousAttack: formData?.traumaHistory?.isSeriousAttack ?? null,
                            seriousAttackDescribe: formData?.traumaHistory?.seriousAttackDescribe ?? "",
                            isSexualCoercion: formData?.traumaHistory?.isSexualCoercion ?? null,

                            describeSexualCoercion: formData?.traumaHistory?.describeSexualCoercion ?? "",
                            isTraumaticExperience: formData?.traumaHistory?.isTraumaticExperience ?? null,
                            describeTraumaticExperience: formData?.traumaHistory?.describeTraumaticExperience ?? "",
                            intrusiveThoughts: formData?.traumaHistory?.intrusiveThoughts ?? null,
                            avoidanceBehavior: formData?.traumaHistory?.avoidanceBehavior ?? null,
                            hypervigilance: formData?.traumaHistory?.hypervigilance ?? null,
                            emotionalDetachment: formData?.traumaHistory?.emotionalDetachment ?? null,
                            selfBlame: formData?.traumaHistory?.selfBlame ?? null,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Describe :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      disabled={!formData?.traumaHistory?.isSexualCoercion}
                      value={formData?.traumaHistory?.describeSexualCoercion || ""}
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            describeSexualCoercion: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                Have you had any other traumatic experiences?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isTraumaticExperience === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isTraumaticExperience === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isTraumaticExperience: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.isTraumaticExperience === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.isTraumaticExperience === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        isTraumaticExperience: result ? null : false,
                        describeTraumaticExperience: "",
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={12}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      If yes, describe :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{
                        width: "100%",
                        // border: "none",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        border: "1px dotted  #ccc",
                        padding: "2px",
                        outline: "none",
                        borderRadius: "8px",
                      }}
                      type="text"
                      value={formData?.traumaHistory?.describeTraumaticExperience || ""}
                      disabled={!formData?.traumaHistory?.isTraumaticExperience}
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          traumaHistory: {
                            ...formData?.traumaHistory,
                            describeTraumaticExperience: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={12}>
              <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                If yes to any of the above, <b>IN THE LAST MONTH</b>, have you :
              </h6>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                Had thoughts about any of the events when you did not want to
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.intrusiveThoughts === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.intrusiveThoughts === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        intrusiveThoughts: result ? null : false,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.intrusiveThoughts === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.intrusiveThoughts === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        intrusiveThoughts: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                Tried to avoid any thoughts or reminders of the event.
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.avoidanceBehavior === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.avoidanceBehavior === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        avoidanceBehavior: result ? null : false,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.avoidanceBehavior === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.avoidanceBehavior === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        avoidanceBehavior: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                Been constantly on guard, watchful, or easily startled ?
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.hypervigilance === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.hypervigilance === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        hypervigilance: result ? null : false,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.hypervigilance === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.hypervigilance === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        hypervigilance: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                Felt numb or detached from people/activities/surroundings.
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.emotionalDetachment === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.emotionalDetachment === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        emotionalDetachment: result ? null : false,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.emotionalDetachment === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.emotionalDetachment === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        emotionalDetachment: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                {" "}
                Felt guilty or unable to stop blaming yourself/others for the events
              </h6>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.selfBlame === false}
                  onClick={e => {
                    const result = formData?.traumaHistory?.selfBlame === false;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        selfBlame: result ? null : false,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  No
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="col-auto d-flex">
                <input
                  style={{ fontSize: "larger", marginRight: "0.5rem" }}
                  type="radio"
                  className="form-check-input"
                  checked={formData?.traumaHistory?.selfBlame === true}
                  onClick={e => {
                    const result = formData?.traumaHistory?.selfBlame === true;
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      traumaHistory: {
                        ...formData?.traumaHistory,
                        selfBlame: result ? null : true,
                      },
                    }));
                  }}
                />
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Yes
                </label>
              </div>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                // const updateDetails = {
                //   formHeader: {
                //     ...formData?.formHeader,
                //   },
                //   traumaHistory: {
                //     ...formData?.traumaHistory,
                //   },
                // };
                // handleBulkFormDataUpdate(updateDetails);
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 8 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormEight;
