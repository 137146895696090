import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Modal, Offcanvas, ModalHeader, OffcanvasHeader, ModalBody, OffcanvasBody, Table, FormGroup, Input, Button, ButtonGroup, Label, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";
import { PriorityNumber, Therapist } from "../../models/Therapist";
import Swal from "sweetalert2";
import { GenderTypes, UserDetails2, UserRole, UserStatus } from "../../models/User";
import { SubscriptionStatus } from "../../models/CardTypes";
import { Transaction, TransactionType } from '../../models/Transaction';
import { TransactionService } from '../../services/TransactionService';
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";
import { format } from "date-fns";
import { MeetingData } from "../../models/MeetingData";
import moment from "moment";
import { toast } from "react-toastify";
import Close from "../../assets/images/icons/close.png";
import ViewTherapistProfile from "./AdminViewTherapistComponents/viewTherapistProfile";
import ViewTherapistSessions from "./AdminViewTherapistComponents/viewTherapistSessions";
import { Rating } from "react-simple-star-rating";
import UnmatchModal from "../Popup/AdminStatModals/unmatchModal";
import SubmitClaimPartitionModal from "../Popup/AdminStatModals/submitClaimPartitionModal";

const ViewAllTherapists: React.FC = () => {
  const [isRight, setIsRight] = useState(false);
  const [isRight1, setIsRight1] = useState(false);
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [meetingData, setMeetingData] = useState<MeetingData[]>([] as MeetingData[]);
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const [offsetTranscribe, setOffsetTranscribe] = useState(1);
  const limitTranscribe = 10;
  const [transcribeCount, setTranscribeCount] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isGender, setIsGender] = useState(GenderTypes.ALL as GenderTypes);
  const [isSubscription, setSubscription] = useState(SubscriptionStatus.ALL as SubscriptionStatus);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [status, setStatus] = useState("");
  const [isCreating, setCreating] = useState(false);
  const [userCount, setUserCount] = useState(10);
  const [terapistId, setTherapistId] = useState("");
  const [terapistName, setTherapistName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isAccmulatedBalance, setIsAccmulatedBalance] = useState({ accumulatedBalance: "" });
  const history = useHistory();
  const [isOpenBlockReasonModal, setIsOpenBlockReasonModal] = useState(false)
  const [userId, setUserId] = useState<string>()
  const [reason, setReason] = useState({ sendReason: "" });
  const [reasonError, setReasonError] = useState(false)
  const [isOpenPayRateModal, setIsOpenPayRateModal] = useState(false)
  const [therapistPayRate, setTherapistPayRate] = useState({ type: '', percentage: null as number | null, flatValue: null as number | null });
  const [therpistUpdateId, setTherpaistUpdateId] = useState<any>()
  const [error, setError] = useState(false)
  const [isOpenPriorityModal, setIsOpenPriorityModal] = useState(false)
  const [pNumbers, setPnumber] = useState<string[]>([]);
  const [selectedPnumber, setSetectedPnumber] = useState<number>();
  const [blackTherapyPnumber, setBlackTherapyPnumber] = useState<string[]>([]);
  const [selectedBlackTherapyPnumber, setSelectedBlackTherapyPnumber] = useState<number>();
  const [mensTherapyPnumber, setMensTherapyPnumber] = useState<string[]>([]);
  const [selectedMensTherapyPnumber, setSelectedMensTherapyPnumber] = useState<number>();
  const [relationshipTherapyPnumber, setRelationshipTherapyPnumber] = useState<string[]>([]);
  const [selectedRelationshipTherapyPnumber, setSelectedRelationshipTherapyPnumber] = useState<number>();
  const [count, setCount] = useState(0);
  const [priorityError, setPriorityError] = useState<boolean>(false)
  const [transactionId, setTransactionId] = useState<string>();
  const [transactionAmount, setTransactionAmount] = useState<number>();
  const [prevTransactionAmount, setPrevTransactionAmount] = useState<number>();
  const [paidStatus, setPaidStatus] = useState<string>();
  const [disabledPayBtn, setDisabledPayBtn] = useState<boolean>(false);
  const [selectedPriorityTherapyType, setSelectedPriorityTherapyType] = useState<string>('mainPriority');
  const [moreOptionsdropdownOpen, setMoreOptionsDropdownOpen] = useState(false);
  const [moreOptionTherapistId, setMoreOptionTherapistId] = useState('')
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(0);
  const [selectedTherapistId, setSelectedTherapistId] = useState<any>();
  const [isUpdateReviewMode, setIsUpdateReviewMode] = useState(false);
  const [isOpenTherapistReviewModal, setIsOpenTherapistReviewModal] = useState(false);
  const [reviewUser, setReviewUser] = useState<Therapist>();
  const [reviewData, setReviewData] = useState({ reviewMessage: "" });
  const [rating, setRating] = useState(0);
  const [modalOpenUnmatch, setModalOpenUnmatch] = useState(false);
  const [modalOpenClaimPartition, setModalOpenClaimPartition] = useState(false);
  const [therapistData, setTherapistData] = useState<Therapist>({} as Therapist);
  const [selectedTherapistIdForClaim, setSelectedTherapistIdForClaim] = useState<string>("");

  useEffect(() => {
    searchTherapists(true);
  }, []);

  const eventModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleReviewMessage = (e: { target: { value: any; }; }) => {
    setReviewData({ reviewMessage: e.target.value });
  };

  const toggleMoreOptionDropdown = (therapistId: any) => {
    setMoreOptionTherapistId(therapistId)
    setMoreOptionsDropdownOpen(prevState => !prevState)
  };

  const getSelectedPriorityNumber = (event: any) => {
    const selectedValue = event.target.value;
    setSetectedPnumber(selectedValue);
    const updatedPNumbers = pNumbers.filter((num) => num !== selectedValue);
    setPnumber(updatedPNumbers);
  }

  const getSelectedBlackTherapyPriorityNumber = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedBlackTherapyPnumber(selectedValue);
    const updatedPNumbers = blackTherapyPnumber.filter((num) => num !== selectedValue);
    setBlackTherapyPnumber(updatedPNumbers);
  }

  const getSelectedMensTherapyPriorityNumber = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedMensTherapyPnumber(selectedValue);
    const updatedPNumbers = mensTherapyPnumber.filter((num) => num !== selectedValue);
    setMensTherapyPnumber(updatedPNumbers);
  }

  const getSelectedRelationshipTherapyPriorityNumber = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedRelationshipTherapyPnumber(selectedValue);
    const updatedPNumbers = relationshipTherapyPnumber.filter((num) => num !== selectedValue);
    setRelationshipTherapyPnumber(updatedPNumbers);
  }

  const resetPriorityNumber = (userId: string | undefined) => {
    if (selectedPriorityTherapyType == "mainPriority") {
      const data: PriorityNumber = {
        userId: userId,
        priorityNumber: 0
      }
      if (data) {
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Reset it!"
        }).then((result) => {
          if (result.isConfirmed) {

            AdminService.updateTherapistPnumberByAdmin(data).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "You have Reset Priority Number !",
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                });
                const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
                const previousTherapist = { ...therapists[therapistKey] };
                const updatedTherapist = { ...previousTherapist, priorityNumber: data.priorityNumber };
                therapists[therapistKey] = updatedTherapist;
                setTherapists(therapists);
                setSetectedPnumber(data.priorityNumber)
                setPriorityError(false)
                setIsOpenPriorityModal(false)
              }
              else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                })
                setPriorityError(false)
              }
            });
          }
        });
      }
    } else if (selectedPriorityTherapyType == "blackTherapy") {
      const data: PriorityNumber = {
        userId: userId,
        blackTherapyPriorityNumber: 0
      }
      if (data) {
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Reset it!"
        }).then((result) => {
          if (result.isConfirmed) {

            AdminService.updateBlackTherapyPnumberByAdmin(data).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "You have Reset Priority Number !",
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                });
                const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
                const previousTherapist = { ...therapists[therapistKey] };
                const updatedTherapist = { ...previousTherapist, blackTherapyPriorityNumber: data.blackTherapyPriorityNumber };
                therapists[therapistKey] = updatedTherapist;
                setTherapists(therapists);
                setSelectedBlackTherapyPnumber(data.blackTherapyPriorityNumber)
                setPriorityError(false)
                setIsOpenPriorityModal(false)
              }
              else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                })
                setPriorityError(false)
              }
            });
          }
        });
      }
    } else if (selectedPriorityTherapyType == "mensTherapy") {
      const data: PriorityNumber = {
        userId: userId,
        mensMentalTherapyPriorityNumber: 0
      }
      if (data) {
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Reset it!"
        }).then((result) => {
          if (result.isConfirmed) {

            AdminService.updateMensTherapyPnumberByAdmin(data).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "You have Reset Priority Number !",
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                });
                const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
                const previousTherapist = { ...therapists[therapistKey] };
                const updatedTherapist = { ...previousTherapist, mensMentalTherapyPriorityNumber: data.mensMentalTherapyPriorityNumber };
                therapists[therapistKey] = updatedTherapist;
                setTherapists(therapists);
                setSelectedMensTherapyPnumber(data.mensMentalTherapyPriorityNumber)
                setPriorityError(false)
                setIsOpenPriorityModal(false)
              }
              else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                })
                setPriorityError(false)
              }
            });
          }
        });
      }
    } else if (selectedPriorityTherapyType == "relationshipTherapy") {
      const data: PriorityNumber = {
        userId: userId,
        relationshipTherapyPriorityNumber: 0
      }
      if (data) {
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Reset it!"
        }).then((result) => {
          if (result.isConfirmed) {

            AdminService.updateRelationshipTherapyPnumberByAdmin(data).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "You have Reset Priority Number !",
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                });
                const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
                const previousTherapist = { ...therapists[therapistKey] };
                const updatedTherapist = { ...previousTherapist, relationshipTherapyPriorityNumber: data.relationshipTherapyPriorityNumber };
                therapists[therapistKey] = updatedTherapist;
                setTherapists(therapists);
                setSelectedRelationshipTherapyPnumber(data.relationshipTherapyPriorityNumber)
                setPriorityError(false)
                setIsOpenPriorityModal(false)
              }
              else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                  timer: 2000
                })
                setPriorityError(false)
              }
            });
          }
        });
      }
    }


  }

  const savePriorityNumber = (userId: string | undefined) => {
    setPriorityError(false)
    if (selectedPriorityTherapyType == "mainPriority") {
      if (selectedPnumber && selectedPnumber <= 0) {
        setPriorityError(true)
      } else if (selectedPnumber && selectedPnumber > count) {
        setPriorityError(true)
      } else {
        const data: PriorityNumber = {
          userId: userId,
          priorityNumber: selectedPnumber
        }

        AdminService.updateTherapistPnumberByAdmin(data).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Priority Number Added !",
              confirmButtonColor: "#FD7F00",
              timer: 2000
            });
            const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
            const previousTherapist = { ...therapists[therapistKey] };
            const updatedTherapist = { ...previousTherapist, priorityNumber: data.priorityNumber };
            therapists[therapistKey] = updatedTherapist;
            setTherapists(therapists);
            setSetectedPnumber(data.priorityNumber)
            setPriorityError(false)
            setIsOpenPriorityModal(false)
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
              timer: 2000
            })
            setPriorityError(false)
          }

        });
      }
    } else if (selectedPriorityTherapyType == "blackTherapy") {
      if (selectedBlackTherapyPnumber && selectedBlackTherapyPnumber <= 0) {
        setPriorityError(true)
      } else if (selectedBlackTherapyPnumber && selectedBlackTherapyPnumber > count) {
        setPriorityError(true)
      } else {
        const data: PriorityNumber = {
          userId: userId,
          blackTherapyPriorityNumber: selectedBlackTherapyPnumber
        }

        AdminService.updateBlackTherapyPnumberByAdmin(data).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Priority Number Added !",
              confirmButtonColor: "#FD7F00",
              timer: 2000
            });
            const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
            const previousTherapist = { ...therapists[therapistKey] };
            const updatedTherapist = { ...previousTherapist, blackTherapyPriorityNumber: data.blackTherapyPriorityNumber };
            therapists[therapistKey] = updatedTherapist;
            setTherapists(therapists);
            setSelectedBlackTherapyPnumber(data.blackTherapyPriorityNumber)
            setPriorityError(false)
            setIsOpenPriorityModal(false)
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
              timer: 2000
            })
            setPriorityError(false)
          }

        });
      }
    } else if (selectedPriorityTherapyType == "mensTherapy") {
      if (selectedMensTherapyPnumber && selectedMensTherapyPnumber <= 0) {
        setPriorityError(true)
      } else if (selectedMensTherapyPnumber && selectedMensTherapyPnumber > count) {
        setPriorityError(true)
      } else {
        const data: PriorityNumber = {
          userId: userId,
          mensMentalTherapyPriorityNumber: selectedMensTherapyPnumber
        }

        AdminService.updateMensTherapyPnumberByAdmin(data).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Priority Number Added !",
              confirmButtonColor: "#FD7F00",
              timer: 2000
            });
            const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
            const previousTherapist = { ...therapists[therapistKey] };
            const updatedTherapist = { ...previousTherapist, mensMentalTherapyPriorityNumber: data.mensMentalTherapyPriorityNumber };
            therapists[therapistKey] = updatedTherapist;
            setTherapists(therapists);
            setSelectedMensTherapyPnumber(data.mensMentalTherapyPriorityNumber)
            setPriorityError(false)
            setIsOpenPriorityModal(false)
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
              timer: 2000
            })
            setPriorityError(false)
          }

        });
      }
    } else if (selectedPriorityTherapyType == "relationshipTherapy") {
      if (selectedRelationshipTherapyPnumber && selectedRelationshipTherapyPnumber <= 0) {
        setPriorityError(true)
      } else if (selectedRelationshipTherapyPnumber && selectedRelationshipTherapyPnumber > count) {
        setPriorityError(true)
      } else {
        const data: PriorityNumber = {
          userId: userId,
          relationshipTherapyPriorityNumber: selectedRelationshipTherapyPnumber
        }

        AdminService.updateRelationshipTherapyPnumberByAdmin(data).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Priority Number Added !",
              confirmButtonColor: "#FD7F00",
              timer: 2000
            });
            const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
            const previousTherapist = { ...therapists[therapistKey] };
            const updatedTherapist = { ...previousTherapist, relationshipTherapyPriorityNumber: data.relationshipTherapyPriorityNumber };
            therapists[therapistKey] = updatedTherapist;
            setTherapists(therapists);
            setSelectedRelationshipTherapyPnumber(data.relationshipTherapyPriorityNumber)
            setPriorityError(false)
            setIsOpenPriorityModal(false)
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
              timer: 2000
            })
            setPriorityError(false)
          }

        });
      }
    }

  }

  const togglePriorityModal = () => {
    setIsOpenPriorityModal(!isOpenPriorityModal)
  }

  const prioritizeTherapist = (userId: string | undefined, name: string | undefined, priorityNumber: any, user: Therapist) => {
    if (userId && name) {
      setUserId(userId);
      setTherapistName(name);
      setIsOpenPriorityModal(true);
      setSetectedPnumber(priorityNumber !== null ? priorityNumber : undefined)
      setSelectedBlackTherapyPnumber(user?.blackTherapyPriorityNumber !== null ? user?.blackTherapyPriorityNumber : undefined)
      setSelectedMensTherapyPnumber(user?.mensMentalTherapyPriorityNumber !== null ? user?.mensMentalTherapyPriorityNumber : undefined)
      setSelectedRelationshipTherapyPnumber(user?.relationshipTherapyPriorityNumber !== null ? user?.relationshipTherapyPriorityNumber : undefined)
    }
  }

  const getTransactionsByTherapist = async (therapist: any) => {
    setTherapistId(therapist._id);
    if (therapist.firstname !== undefined) {
      setTherapistName(therapist.firstname + " " + therapist.lastname);
    } else {
      setTherapistName("-");
    }

    setOffsetTranscribe(1)
    await TransactionService.getAllTransactionsByTherapist(therapist._id, limitTranscribe, 1).then(res => {
      if (res.success) {
        setTransactions(res.data);
        setTranscribeCount(res.data?.length)
        setIsRight(!isRight);
      }
      else {
        toast.error('Somthing went wrong!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      }
    })
  }


  const getUpdateTransactionsByTherapist = async (terapistId: any) => {

    setOffsetTranscribe(1)
    await TransactionService.getAllTransactionsByTherapist(terapistId, limitTranscribe, 1).then(res => {
      if (res.success) {
        setTransactions(res.data);
        setTranscribeCount(res.data?.length)
      }
      else {
        toast.error('Somthing went wrong!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      }
    })
  }
  const getMeatingHistoryByTherapist = (id: any) => {
    setTherapistId(id);
    setOffsetTranscribe(1)
    TransactionService.getAllMeetingsForTherapistForTranscribe(id, limitTranscribe, 1).then(res => {
      if (res.success) {
        setMeetingData(res.data);
        setTranscribeCount(res.data?.length)
        setIsRight1(!isRight1);

      }
    })
  }

  const seeMore1 = () => {
    const updatedSkip = offsetTranscribe + 1;

    setOffsetTranscribe(updatedSkip);

    TransactionService.getAllMeetingsForTherapistForTranscribe(terapistId, 10, updatedSkip).then(res => {
      setIsLoading(true);

      if (res.success) {
        res.data.map((data: any) => {
          setMeetingData(meetingData => [...meetingData, data]);
        });

        setTranscribeCount(res.data?.length);
      }
    });
  }
  const seeMore2 = () => {
    const updatedSkip = offsetTranscribe + 1;

    setOffsetTranscribe(updatedSkip);
    setTranscribeCount(10)
    TransactionService.getAllTransactionsByTherapist(terapistId, 10, updatedSkip).then(res => {
      setIsLoading(true);

      if (res.success) {
        res.data.map((data: any) => {
          setTransactions(meetingData => [...meetingData, data]);
        });
      }
      setTranscribeCount(res.data?.length);
      setIsLoading(false);
    });
  }

  const searchTherapists = (onload: boolean) => {
    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: status,
      isSubscription: isSubscription,
      zipCode: zipCode,
      role: UserRole.CLIENT
    }

    setIsLoading(true);

    AdminService.searchAllTherapistsByAdmin(data, limit, offset).then(res => {
      if (res.success) {
        setTherapists(res.data.userSet);
        setUserCount(res.data.userSet.length);
        setCount(res.data?.totalCount)
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  }

  const filterTherapists = () => {
    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: status,
      isSubscription: isSubscription,
      zipCode: zipCode,
      role: UserRole.CLIENT
    }

    setIsLoading(true);

    const updatedOfsetSkip = 1;
    setOffset(updatedOfsetSkip);

    AdminService.searchAllTherapistsByAdmin(data, limit, 1).then(res => {
      if (res.success) {
        setTherapists(res.data.userSet);
        setUserCount(res.data.userSet.length);
        setCount(res.data?.totalCount)
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  }

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const toggleRightCanvas1 = () => {
    setIsRight1(!isRight1);
  };

  const toggleReasonModal = () => {
    setIsOpenBlockReasonModal(!isOpenBlockReasonModal)
  }
  const blockUserWithReason = (userId: string | undefined) => {
    setReasonError(false)
    setUserId(userId)
    setIsOpenBlockReasonModal(true)
  }
  const blockUser = (userId: string | undefined) => {
    setReasonError(false)
    if (userId && reason.sendReason && reason.sendReason.length > 0) {
      // Swal.fire({
      //   icon: "warning",
      //   title: "Are you sure you want to block?",
      //   showCancelButton: true,
      //   confirmButtonText: "Block",
      //   confirmButtonColor: "#FD7F00",
      //   cancelButtonColor: "#FD7F00",
      // }).then(result => {
      //   if (result.isConfirmed) {
      const data = {
        userId: userId,
        reasonToBlock: reason.sendReason,
        // priorityNumber: null
      };

      AdminService.blockUserByAdmin(data).then(res => {
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "User blocked!",
            confirmButtonColor: "#FD7F00",
          });
          const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
          const previousTherapist = { ...therapists[therapistKey] };
          const updatedTherapist = { ...previousTherapist, blockedByAdmin: true, priorityNumber: 0 };
          therapists[therapistKey] = updatedTherapist;
          setTherapists(therapists);
          toggleReasonModal()
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to block the user.",
            confirmButtonColor: "#FD7F00",
          });
          toggleReasonModal
        }
      });
      //   }
      // });
    } else {
      setReasonError(true)
    }
  };

  const unblockUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to unblock?",
        showCancelButton: true,
        confirmButtonText: "Unblock",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            userId: userId,
          };
          AdminService.unblockUserByAdmin(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User unblocked!",
                confirmButtonColor: "#FD7F00",
              });
              const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == userId);
              const previousTherapist = { ...therapists[therapistKey] };
              const updatedTherapist = { ...previousTherapist, blockedByAdmin: false };
              therapists[therapistKey] = updatedTherapist;
              setTherapists(therapists);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to unblock the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const transferSessionAmmount = async (transactionId: any) => {
    setDisabledPayBtn(true);
    await TransactionService.getAllTransactionsByTherapist(terapistId, limitTranscribe, 1).then(res => {
      if (res.success) {
        if (parseFloat(res.data[0]?.accumulatedBalance.toString()) < parseFloat(isAccmulatedBalance?.accumulatedBalance)) {
          Swal.fire({
            icon: "error",
            title: "Your account balance is insufficient",
            confirmButtonColor: "#FD7F00",
          });
          setDisabledPayBtn(false);
        } else if (terapistId) {
          if (!transactionAmount) {
            toast.error('The amount is required.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'foo-bar'
            });

            setDisabledPayBtn(false);

            return;
          }

          if (transactionAmount && transactionAmount <= 0) {
            toast.error('Amount is higher than 0.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'foo-bar'
            });
            setDisabledPayBtn(false);
            return;
          }

          const data = {
            userId: terapistId,
            transactionId: transactionId,
            amount: transactionAmount
          };

          AdminService.tranceferAmmountBySession(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: res.message,
                confirmButtonColor: "#FD7F00",
              });

              getUpdateTransactionsByTherapist(terapistId);
              setTransactionId(undefined)
              setTransactionAmount(undefined)
              setPaidStatus("")
              setShowModal(false);
              setDisabledPayBtn(false);
            } else {
              Swal.fire({
                icon: "error",
                title: res.error || "insufficient_capabilities_for_transfer",
                confirmButtonColor: "#FD7F00",
              });

              setDisabledPayBtn(false);
            }
          });
        }
      } else {
        toast.error('Somthing went wrong!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });

        setDisabledPayBtn(false);
      }
    })
  };

  const markAsPaid = async () => {
    await TransactionService.getAllTransactionsByTherapist(terapistId, limitTranscribe, 1).then(res => {
      if (res.success) {
        if (parseFloat(res.data[0]?.accumulatedBalance.toString()) < parseFloat(isAccmulatedBalance?.accumulatedBalance) || !res.data[0]?.accumulatedBalance) {
          Swal.fire({
            icon: "error",
            title: "Your account balance is insufficient",
            confirmButtonColor: "#FD7F00",
          });
        } else if (terapistId) {
          if (transactionAmount != prevTransactionAmount) {
            toast.error('The session amount and the amount you entered should be equal for marking as paid.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'foo-bar'
            });
            setDisabledPayBtn(false);
            return;
          }
          const data = {
            userId: terapistId,
            transactionId: transactionId,
          };
          AdminService.markAsPaidAmmount(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: res.message,
                confirmButtonColor: "#FD7F00",
              });
              // setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: "" });
              getUpdateTransactionsByTherapist(terapistId);
              setTransactionId(undefined)
              setTransactionAmount(undefined)
              setPaidStatus("")
              setShowModal(false);
            } else {
              Swal.fire({
                icon: "error",
                title: res.error || "insufficient_capabilities_for_transfer",
                confirmButtonColor: "#FD7F00",
              });
              setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: "" })
            }
          });
        }
      }
      else {
        toast.error('Somthing went wrong!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      }
    });
  };

  const deleteUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.deleteUser(userId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User deleted!",
                confirmButtonColor: "#FD7F00",
              });
              const updatedTherapistList = therapists.filter((therapist: Therapist) => therapist._id !== userId);
              setTherapists(updatedTherapistList);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const seeMore = () => {
    setIsLoading(true);

    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: isSearchableString,
      isSubscription: isSubscription,
      zipCode: isSearchableString,
      role: UserRole.CLIENT
    }

    AdminService.searchAllTherapistsByAdmin(data, 10, updatedSkip).then(res => {
      if (res.success) {
        res.data.userSet.map((therapist: any) => {
          setTherapists(therapists => [...therapists, therapist]);
        });

        setUserCount(res.data.userSet.length);
      }

      setIsLoading(false);
    });
  }

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  }

  const navigateToClientSessions = (therapistId: string | undefined) => {
    if (therapistId) history.push(`/therapist-sessions/${therapistId}`);
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const data = {
        searchableString: isSearchableString,
        gender: isGender,
        status: isSearchableString,
        isSubscription: isSubscription,
        zipCode: isSearchableString,
        role: UserRole.CLIENT
      }

      setIsLoading(true);

      AdminService.searchAllTherapistsByAdmin(data, limit, offset).then(res => {
        if (res.success) {
          setTherapists(res.data.userSet);
          setUserCount(res.data.userSet.length);
        }

        setIsLoading(false);

        if (!onload) {
          setFilterOptions(!isFilterOptions);
        }
      });
    }
  };

  const updatePayRateModal = (therapist: Therapist) => {
    setTherapistPayRate({ ...therapistPayRate, type: therapist?.payRateType?.type, percentage: therapist?.payRateType?.percentage, flatValue: therapist?.payRateType?.flatValue })
    setTherpaistUpdateId(therapist._id)
    setIsOpenPayRateModal(true)
  }

  const updateTherapistPayaRate = () => {
    setError(false)
    const data = {
      therapistId: therpistUpdateId,
      payRateType: therapistPayRate?.type,
      percentage: therapistPayRate?.percentage ? therapistPayRate?.percentage : null,
      flatValue: therapistPayRate?.flatValue ? therapistPayRate?.flatValue : null,
    }

    if (data?.payRateType?.length == 0) {
      setError(true)
    } else if (data?.payRateType == "PERCENTAGE_USER" && !data?.percentage) {
      setError(true)
    } else if (data?.payRateType == "PERCENTAGE_USER" && data?.percentage && data?.percentage > 100) {
      setError(true)
    } else if (data?.payRateType == "FLAT_USER" && !data?.flatValue) {
      setError(true)
    } else {
      AdminService.updatePayRateByAdmin(data).then(res => {
        if (res.success) {
          if (data?.payRateType == "PERCENTAGE_USER") {
            const updateData = {
              type: "PERCENTAGE_USER",
              percentage: therapistPayRate?.percentage,
            }
            const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == therpistUpdateId);
            const previousTherapist = { ...therapists[therapistKey] };
            const updatedTherapist = { ...previousTherapist, payRateType: updateData };
            therapists[therapistKey] = updatedTherapist;
          } else if (data?.payRateType == "FLAT_USER") {
            const updateData = {
              type: "FLAT_USER",
              flatValue: therapistPayRate?.flatValue,
            }
            const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == therpistUpdateId);
            const previousTherapist = { ...therapists[therapistKey] };
            const updatedTherapist = { ...previousTherapist, payRateType: updateData };
            therapists[therapistKey] = updatedTherapist;
          }

          Swal.fire({
            icon: "success",
            title: "Successfully updated!",
            confirmButtonColor: "#FD7F00",
          });
          setIsOpenPayRateModal(false)
        } else {
          Swal.fire({
            icon: "error",
            title: res.error,
            confirmButtonColor: "#FD7F00",
          });
        }

      });
    }
  }

  const togglePayRateModal = () => {
    setIsOpenPayRateModal(!isOpenPayRateModal)
  }

  const handleClickPay = (transaction: Transaction) => {
    setTransactionId(transaction?._id)
    setTransactionAmount(transaction?.transactionAmount)
    setPrevTransactionAmount(transaction?.transactionAmount)
    setPaidStatus(transaction?.paidStatus)
    setShowModal(true)
  }

  const priorityTypes = [
    { value: "mainPriority", label: "Main Priority Number" },
    { value: "blackTherapy", label: "Black Therapy Priority Number" },
    { value: "mensTherapy", label: "Men's Mental Wellness Priority Number" },
    { value: "relationshipTherapy", label: "Relationship Wellness Priority Number" },
  ]

  const getLabelForValue = (value: string): string | undefined => {
    const priorityType = priorityTypes.find(type => type.value === value);
    return priorityType ? priorityType.label : undefined;
  };

  const handleUpcateClaim = (therapistId: string, status: boolean) => {
    if (therapistId) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${status == true ? 'Yes, Open it!' : 'Yes, Close it!'}`
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            therapistId: therapistId,
            claimOpen: status,
          }
          AdminService.updateClaimStatusByAdmin(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: `${status == true ? 'Claim Open Successfully' : 'Claim Close Successfully'}`,
                confirmButtonColor: "#FD7F00",
                timer: 2000
              });
              const therapistKey = therapists.findIndex((therapist: Therapist) => therapist._id == therapistId);
              const previousTherapist = { ...therapists[therapistKey] };
              const updatedTherapist = { ...previousTherapist, claimOpen: status };
              therapists[therapistKey] = updatedTherapist;
              setTherapists(therapists);
            }
            else {
              Swal.fire({
                icon: "error",
                title: res.error,
                confirmButtonColor: "#FD7F00",
                timer: 2000
              })
            }
          });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Invalid Therapist Id",
        confirmButtonColor: "#FD7F00",
        timer: 2000
      })
    }
  }

  const replaceComponent= (therapistId:string,pageComponent:number) =>{
    if(therapistId != undefined && pageComponent != undefined) {
      setSelectedTherapistId(therapistId)
      setCurrentPageComponents(pageComponent)
    }
  }

  const callBackPage = () => {
    setCurrentPageComponents(0)
  }

  const submitTherapistReview = () => {
    const data = {
      therapistId: userId,
      reviewMessage: reviewData?.reviewMessage,
      ratingValue: rating
    };

    AdminService.reviewSubmissionByAdminOnBehalfOfTherapist(data).then(res => {
      if (res && res.success) {
        Swal.fire({
          icon: "success",
          title: "Review Successfully Submitted!",
          confirmButtonColor: "#FD7F00",
        });
        setIsOpenTherapistReviewModal(false);
      } else {
        Swal.fire({
          icon: "error",
          title: res.error,
          confirmButtonColor: "#FD7F00",
        });
      }
    });
  };

  const therapistReviewModal = (therapist: Therapist) => {
    try {
      setIsLoading(true);
      AdminService.getExistingReviewByTherapistId(therapist._id).then(res => {
        if (res && res.success) {
          setReviewData(res.data);
          setRating(Number(res.data?.ratingValue));
          setIsUpdateReviewMode(res.data !== null);
          setIsLoading(false);
        } else {
          toast.error(`Encountered an error while retrieving the review details for this user!`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          setIsLoading(false);
        }
      });
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    setUserId(therapist._id);
    setReviewUser(therapist);
    setIsOpenTherapistReviewModal(true);
  };

  const toggleTherapistReviewModal = () => {
    setIsOpenTherapistReviewModal(!isOpenTherapistReviewModal);
    setRating(0);
  };

  const toggleModalUnmatch = () => {
    setModalOpenUnmatch(!modalOpenUnmatch);
  };

  const handleSubmitClaimPartition = (therapistId: string) => {
    if (therapistId) {
      setSelectedTherapistIdForClaim(therapistId);
      setModalOpenClaimPartition(true);
    }
  };

  const toggleClaimPartitionModal = () => {
    setModalOpenClaimPartition(!modalOpenClaimPartition);
  };
  
  return (
    <>
      {
        currentPageComponents == 0 && (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs title={"Therapists"} breadcrumbItem={"All Therapists"} />
                <Card className="m-h">
                  <CardBody>
                    <Row className="d-flex justify-content-between align-items-center">
                      <div className="d-flex admin-filter">
                        <button className="btn btn-sm mr-2" onClick={toggleFilter}>Filter <i className='bx bx-filter-alt'></i></button>
                      </div>
                      <div className="d-flex ">
                        <div className="d-flex me-2 me-lg-4">
                          <div className="priorityTag-priority" title="Main Priority">
                          </div>
                          <span className="d-none d-sm-block">Main Priority</span>
                        </div>
                        <div className="d-flex me-2 me-lg-4">
                          <div className="black-therapy-dot black-therapy-bg" title="Black Therapy Priority">
                          </div>
                          <span className="d-none d-sm-block">Black Therapy Priority</span>
                        </div>
                        <div className="d-flex me-2 me-lg-4">
                          <div className="black-therapy-dot relationship-therapy-bg" title="Relationship Wellness Priority">
                          </div>
                          <span className="d-none d-sm-block">Relationship Wellness Priority</span>
                        </div>
                        <div className="d-flex me-2 me-lg-4">
                          <div className="black-therapy-dot men-therapy-bg" title="Men's Mental Wellness Priority">
                          </div>
                          <span className="d-none d-sm-block">Men&apos;s Mental Wellness Priority</span>
                        </div>
                      </div>

                    </Row>
                    <Row>
                      <Col sm="12" className="mt10">
                        <div className="table-responsive" style={{ minHeight: "70vh" }}>
                          {therapists !== undefined && therapists.length > 0 ? (
                            <>
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Zipcode</th>
                                    <th scope="col">State</th>
                                    <th scope="col">Verified Status</th>
                                    <th scope="col">Claim</th>
                                    <th scope="col">Admin Approve</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {therapists &&
                                    therapists.map((therapist, index) => (
                                      <tr key={therapist._id}>
                                        <th scope="row">{index + 1}</th>

                                        <td>
                                          <div className="priorityTagContainer">
                                            <div>
                                              {therapist.firstname}&nbsp;{therapist.lastname}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="container-priority">
                                            {/* {
                                        therapist?.mensMentalTherapyPriorityNumber && ( */}
                                            <div className="additional-priority men-therapy-bg" title="Men's Mental Wellness Priority">{therapist?.mensMentalTherapyPriorityNumber ? therapist?.mensMentalTherapyPriorityNumber : '-'}</div>
                                            {/* )
                                      } */}
                                            {/* {
                                        therapist?.relationshipTherapyPriorityNumber && ( */}
                                            <div className="additional-priority relationship-therapy-bg" title="Relationship Wellness Priority">{therapist?.relationshipTherapyPriorityNumber ? therapist?.relationshipTherapyPriorityNumber : '-'}</div>
                                            {/* )
                                      } */}

                                            {/* {
                                        therapist?.blackTherapyPriorityNumber && ( */}
                                            <div className="additional-priority black-therapy-bg" title="Black Therapy Priority">{therapist?.blackTherapyPriorityNumber ? therapist?.blackTherapyPriorityNumber : '-'}</div>
                                            {/* )
                                      } */}
                                            <div className="priorityTag-priority" title="Main Priority">
                                              {therapist?.priorityNumber ? therapist?.priorityNumber : "-"}
                                            </div>
                                          </div>
                                        </td>

                                        <td>{therapist.gender}</td>

                                        <td>{therapist.email}</td>

                                        <td>{therapist.primaryPhone}</td>

                                        <td>{therapist.zipCode}</td>

                                        <td>{therapist.state}</td>

                                        <td>
                                          {
                                            therapist.verifiedStatus == "VERIFIED" ? (
                                              <span className="verified">Verified</span>
                                            ) : (
                                              <span className="pending">Pending</span>
                                            )
                                          }
                                        </td>
                                        <td>{therapist?.claimOpen == true ? <span className="open-1">Open</span> : therapist?.claimOpen == false ? <span className="close-1">Blocked</span> : null}</td>
                                        <td>{therapist?.adminApproved == true ? <span>True</span> : <span>False</span>}</td>

                                        <td>
                                          <div className="flex">
                                            <Dropdown isOpen={moreOptionsdropdownOpen && moreOptionTherapistId == therapist?._id} toggle={() => toggleMoreOptionDropdown(therapist?._id)}>
                                              <DropdownToggle caret className="btn-sm custom-toggle-admin">
                                                <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem>
                                                  <button onClick={()=> replaceComponent(therapist._id,1)} className="btn-sm btn btn-success mr-2 minw-100-drop-item" title="View Profile">
                                                    <i className="fa fa-user"></i> View Profile
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button className="btn-sm btn btn-transaction mr-2 minw-100-drop-item" onClick={() => getMeatingHistoryByTherapist(therapist._id)} title="View Meeting History">
                                                    <i className="fa fa-fax"></i> View Meeting History
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button className="btn-sm btn btn-info mr-2 minw-100-drop-item" onClick={() => getTransactionsByTherapist(therapist)} title="View Earnings">
                                                    <i className="fa fa-dollar-sign"></i> View Earnings
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  {therapist.blockedByAdmin ? (
                                                    <button className="btn-sm btn btn-success mr-2 minw-100-drop-item" onClick={() => unblockUser(therapist._id)} title="Unblock Therapist">
                                                      <i className="fa fa-unlock"></i> Unblock Therapist
                                                    </button>
                                                  ) : (
                                                    <button className="btn-sm btn btn-warning mr-2 minw-100-drop-item" onClick={() => blockUserWithReason(therapist._id)} title="Block Therapist">
                                                      <i className="fa fa-ban"></i> Block Therapist
                                                    </button>
                                                  )}
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button className="btn-sm btn btn-danger mr-2 minw-100-drop-item" onClick={() => deleteUser(therapist._id)} title="Delete Therapist">
                                                    <i className="fa fa-trash"></i> Delete Therapist
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button className="btn-sm btn btn-insurance mr-2 minw-100-drop-item"  onClick={()=> replaceComponent(therapist._id,2)} title="View Client Sessions">
                                                    <i className="fa fa-calendar"></i> View Client Sessions
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button className="btn-sm btn btn-pay-rate mr-2 minw-100-drop-item" onClick={() => updatePayRateModal(therapist)} title="Update Pay Rate Type">
                                                    <i className="fa fa-credit-card"></i> Update Pay Rate Type
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button className="btn-sm btn btn-success mr-2 minw-100-drop-item" onClick={() => prioritizeTherapist(therapist?._id, therapist?.firstname, therapist?.priorityNumber ? therapist?.priorityNumber : null, therapist)} title="Prioritize Therapist">
                                                    <i className="bx bx-sort-down"></i> Prioritize Therapist
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  {
                                                    therapist?.claimOpen == true ? (
                                                      <button className="btn-sm btn btn-danger  mr-2 minw-100-drop-item" title="Close Claim" onClick={() => handleUpcateClaim(therapist?._id, false)}>
                                                        <i className="fa fa-times"></i> Close Claim
                                                      </button>
                                                    ) : (
                                                      <button className="btn-sm btn btn-info mr-2 minw-100-drop-item" title="Open Claim" onClick={() => handleUpcateClaim(therapist?._id, true)}>
                                                        <i className="fa fa-check"></i> Open Claim
                                                      </button>
                                                    )
                                                  }
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-warning mr-2 minw-100-drop-item"
                                                    onClick={() => handleSubmitClaimPartition(therapist._id)}
                                                    title="Submit Claim Partition"
                                                  >
                                                    <i className="fa fa-file-invoice"></i> Submit Claim Partition
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                    onClick={() => therapistReviewModal(therapist)}
                                                    title="Therapist Review"
                                                  >
                                                    <i className="fa fa-sticky-note"></i> Therapist Review
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      toggleModalUnmatch();
                                                      setTherapistData(therapist);
                                                    }}
                                                    title="Unmatch Clients"
                                                  >
                                                    <i className="fa fa-user-friends"></i> Unmatch Clients
                                                  </button>
                                                </DropdownItem>

                                              </DropdownMenu>
                                            </Dropdown>
                                            
                                            {
                                              therapist.lavniTestAccount && (
                                                <span className="test-account ms-2 test-account-card">#Test Account</span>
                                              )
                                            }
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  }

                                </tbody>
                              </table>

                              {
                                userCount >= 10 && (
                                  <>
                                    <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                      {
                                        isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <>See More</>
                                      }
                                    </button>
                                  </>
                                )
                              }

                              {userCount == 0 && <span>No more therapists available...</span>}
                            </>
                          ) : (
                            <>
                              {
                                isLoading ? (
                                  <Spinner />
                                ) : (
                                  <><div>No Therapist</div></>
                                )
                              }
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Container>
            </div>

            <Modal isOpen={isOpenPayRateModal} toggle={togglePayRateModal} centered className="modal-md">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={togglePayRateModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Update Pay Rate Type</span>
                </Row>
                <Row className="mb-4">
                  <Col xl={4}>
                    <label>Pay Rate Type</label>
                  </Col>
                  <Col xl={8}>
                    <Input
                      type="select"
                      name="type"
                      placeholder="Select Rate Type"
                      onChange={(e) => setTherapistPayRate({ ...therapistPayRate, type: e.target.value })}
                      value={therapistPayRate?.type === null ? undefined : therapistPayRate?.type}
                    >
                      <option>Select Type</option>
                      <option value="PERCENTAGE_USER">Percentage User</option>
                      <option value="FLAT_USER">Flat User</option>
                    </Input>
                    {
                      error && therapistPayRate?.type?.length == 0 && (
                        <p className="text-danger fs-10-n">Please select type</p>
                      )
                    }
                  </Col>
                </Row>
                {
                  therapistPayRate?.type == "PERCENTAGE_USER" && (<>
                    <Row className="mb-4">
                      <Col xl={4}>
                        <label>Percentage(%)</label>
                      </Col>
                      <Col xl={8}>
                        <Input
                          type="number"
                          name="percentage"
                          placeholder="Enter Percentage"
                          onChange={(e) => setTherapistPayRate({ ...therapistPayRate, percentage: Number(e.target.value) })}
                          value={therapistPayRate?.percentage === null ? undefined : therapistPayRate?.percentage}
                        >
                        </Input>
                        {
                          error && therapistPayRate?.type == "PERCENTAGE_USER" && !therapistPayRate?.percentage && (
                            <p className="text-danger fs-10-n">Please enter percentage</p>
                          )
                        }
                        {
                          error && therapistPayRate?.type == "PERCENTAGE_USER" && therapistPayRate?.percentage && therapistPayRate?.percentage > 100 && (
                            <p className="text-danger fs-10-n">Percentage should be less than 100</p>
                          )
                        }
                      </Col>
                    </Row>
                  </>)
                }
                {
                  therapistPayRate?.type == "FLAT_USER" && (<>
                    <Row className="mb-4">
                      <Col xl={4}>
                        <label>Value($)</label>
                      </Col>
                      <Col xl={8}>
                        <Input
                          type="number"
                          name="value"
                          placeholder="Enter Value"
                          onChange={(e) => setTherapistPayRate({ ...therapistPayRate, flatValue: Number(e.target.value) })}
                          value={therapistPayRate?.flatValue === null ? undefined : therapistPayRate?.flatValue}
                        >
                        </Input>
                        {
                          error && therapistPayRate?.type == "FLAT_USER" && !therapistPayRate?.flatValue && (
                            <p className="text-danger fs-10-n">Please enter value</p>
                          )
                        }
                      </Col>
                    </Row>
                  </>)
                }

                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2"
                      onClick={() => updateTherapistPayaRate()}
                    >Change Rate Type</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={showModal} centered toggle={eventModalToggle} unmountOnClose={true}>
              <ModalHeader toggle={eventModalToggle}>
                Transfer amount to : {terapistName}
              </ModalHeader>

              <ModalBody className="awsome-area">
                <Row className="mb-4">
                  <Col lg={12}>
                    <Label>Amount</Label>
                    <Input
                      type="number"
                      name="repeat"
                      placeholder="Amount"
                      onChange={e => setTransactionAmount(parseInt(e.target.value))}
                      value={transactionAmount ? Math.round(transactionAmount * 100) / 100 : "0"}
                    // disabled={true}
                    />
                  </Col>

                </Row>
                <Row>
                  <Col >

                    {
                      (paidStatus && paidStatus == "PAID") ? null :
                        <button className="btn btn-success float-right" disabled={disabledPayBtn} onClick={() => transferSessionAmmount(transactionId)} >
                          Pay
                        </button>
                    }
                    <button className="btn btn-warning float-right mr-2" onClick={markAsPaid} >
                      Mark As Paid
                    </button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenTherapistReviewModal} toggle={toggleTherapistReviewModal} centered className="modal-md">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleTherapistReviewModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center"> {isUpdateReviewMode ? "Update Therapist Review" : "Add Therapist Review"}</span>
                </Row>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Name</label>
                    </Col>
                    <Col xl={10}>
                      <Input
                        type="text"
                        className="form-control"
                        value={`${reviewUser?.firstname || 'Therapist'} ${reviewUser?.lastname || 'User'}`.trim()}
                        disabled
                      />
                    </Col>
                  </Row>
                </>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Email</label>
                    </Col>
                    <Col xl={10}>
                      <Input
                        type="text"
                        className="form-control"
                        value={reviewUser?.email}
                        disabled
                      />
                    </Col>
                  </Row>
                </>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Review<span className="text-danger">*</span></label>
                    </Col>
                    <Col xl={10}>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="formrow-firstname-Input"
                        style={{ height: "100%", minHeight: "160px" }}
                        placeholder="Write a review on behalf of therapist..."
                        onChange={handleReviewMessage}
                        value={reviewData?.reviewMessage || ""}
                        name="review"
                        required
                      />
                    </Col>
                  </Row>
                </>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Rate<span className="text-danger">*</span></label>
                    </Col>
                    <Col xl={10}>
                      <Rating initialValue={rating} onClick={(rate: any) => setRating(rate)} size={20} transition fillColor={"#F2C94C"} />
                    </Col>
                  </Row>
                </>
                <h6 className="req-field"><span className="text-danger">*</span> Indicates a required fields.</h6>

                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2"
                      onClick={() => submitTherapistReview()}
                    >{isUpdateReviewMode ? "Update Therapist Review" : "Add Therapist Review"}</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Offcanvas isOpen={isRight1} direction="end" className="offcanvas-ends" toggle={toggleRightCanvas1}>
              <OffcanvasHeader toggle={toggleRightCanvas1}>Meeting History new</OffcanvasHeader>
              <OffcanvasBody>
                <Row className="mt-4">

                  <Col sm="12" className="mt10">
                    {meetingData !== undefined && meetingData?.length > 0 ? (
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Meeting Id</th>
                            <th scope="col">Client</th>
                            <th scope="col">Type</th>
                            <th scope="col">Transcribe Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {meetingData &&
                            meetingData.sort((a, b) =>
                              moment(a.createdAt).format("HH:mm:ss") >
                                moment(b.createdAt).format("HH:mm:ss")
                                ? -1
                                : 1
                            ).map((t, index) => (
                              <tr key={index}>
                                <td>{moment(t.createdAt || null).format("YYYY/MM/DD")}</td>
                                <td>{t.meetingId}</td>
                                <td>{t.clientId.username}</td>
                                <td>{t.meetingDuration}</td>
                                {(t.transcribingInProcess || isCreating) && (
                                  <td>
                                    <span className="bg-warning convert-pending"> Convert Pending </span>
                                  </td>
                                )}
                                {!isCreating && !t.transcribingInProcess && t.transcribeCreated && (
                                  <td>
                                    <span className="bg-success convert-pending"> Transcribe Created </span>
                                  </td>
                                )}
                              </tr>
                            ))}
                          {
                            transcribeCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMore1}>See More</button>
                              </>
                            )
                          }
                        </tbody>
                      </table>

                    ) : (
                      <>
                        {
                          isLoading ? (
                            <Spinner />
                          ) : (
                            <><div>No Meeting History</div></>
                          )
                        }
                      </>
                    )}
                  </Col>
                </Row>
              </OffcanvasBody>
            </Offcanvas>

            <Offcanvas isOpen={isRight} direction="end" className="offcanvas-ends50" toggle={toggleRightCanvas}>
              <OffcanvasHeader toggle={toggleRightCanvas}>Earnings {terapistName && 'of ' + terapistName}</OffcanvasHeader>
              <OffcanvasBody>
                <Row className="mt-3">
                  <Col lg={4}>
                    <h4 className="mt-3">Total Earnings</h4>
                    <h1 className="h1-color">{transactions && transactions.length > 0 ? <>${Math.round(transactions[0].accumulatedTotalEarnings * 100) / 100}</> : "$0"}</h1>
                  </Col>

                  <Col lg={4}>
                    <h4 className="mt-3">Available Balance</h4>
                    <h1 className="h1-color">{transactions && transactions.length > 0 ? <>${Math.round(transactions[0].accumulatedBalance * 100) / 100}</> : "$0"}</h1>
                  </Col>

                  <Col lg={4}>
                    {/* <button className="btn btn-info  mt-4" onClick={() => transferAmmount()} title="Pay">
                <i className="fa fa-dollar-sign"></i> Pay
              </button> */}
                  </Col>
                </Row>

                <Row className="mt-4">
                  <h5 className="mb-3">Transactions</h5>
                  <Col sm="12" className="mt10">
                    {transactions !== undefined && transactions?.length > 0 ? (
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Spent</th>
                            <th scope="col">Amount</th>
                            {/* <th scope="col">Accumulated Balance</th>
                      <th scope="col">Total Earnings</th> */}
                            {/* <th scope="col">Transaction Amount</th> */}
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.map((t, index) => (
                            t.type == TransactionType.EARNING && (
                              <tr key={index}>
                                <td>{format(new Date(t.createdAt).getTime(), "MMMM do, yyyy H:mm a")}</td>
                                <td>{t.type}</td>
                                <td>{t.meetingId && t.meetingId.meetingDuration ? t.meetingId.meetingDuration : 0} mins</td>
                                <td>
                                  <span className="d-inline-flex btn btn-warning rounded-4">{t.meetingId && t.meetingId.spentDuration ? t.meetingId.spentDuration.toFixed(2) : 0} mins</span>
                                </td>
                                <td>
                                  <span className="btn btn-success rounded-4">{t.type == TransactionType.EARNING ? "+" : "-"}${Math.round(t.transactionAmount * 100) / 100}</span>
                                </td>
                                {/* <td>${t.accumulatedBalance}</td>
                          <td>${t.accumulatedTotalEarnings}</td> */}
                                {/* <td>${t.transactionAmount}</td> */}
                                <td>{(t?.paidStatus && t?.paidStatus == "PAID") ? "Paid" : "Pending"}</td>
                                <td>
                                  {
                                    (t?.paidStatus && t?.paidStatus == "PAID") ? null :
                                      <button className="btn btn-info"
                                        // onClick={() => transferSessionAmmount(t._id)} 
                                        onClick={() => handleClickPay(t)}
                                        title="Pay">
                                        <i className="fa fa-dollar-sign"></i> Pay
                                      </button>
                                  }
                                </td>
                              </tr>
                            )

                          ))}
                          {
                            transcribeCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMore2}>See More</button>
                              </>
                            )
                          }
                        </tbody>
                      </table>
                    ) : (
                      <>
                        {
                          isLoading ? (
                            <Spinner />
                          ) : (
                            <><div>No Transactions</div></>
                          )
                        }
                      </>
                    )}
                  </Col>
                </Row>
              </OffcanvasBody>
            </Offcanvas>

            <Offcanvas
              className="offcanvas-end2"
              isOpen={isFilterOptions}
              direction="end"
              toggle={toggleFilter}>
              <OffcanvasHeader toggle={toggleFilter}>
                Filter Therapists
              </OffcanvasHeader>
              <OffcanvasBody>
                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Search by Name or Email</Label>
                      <Input
                        id="search"
                        name="text"
                        placeholder="Search Users (ex: first name, last name or email)"
                        type="text"
                        onKeyPress={handleKeyPress}
                        onChange={e => setIsSearchableString(e.target.value)}
                        value={isSearchableString}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Search by Zip Code</Label>
                      <Input
                        id="search"
                        name="text"
                        placeholder="Search ZipCode"
                        type="text"
                        onKeyPress={handleKeyPress}
                        value={zipCode}
                        onChange={e => setZipCode(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={12}>
                    <Label>Gender</Label>
                    <ButtonGroup className="flex-wrap flex">
                      <Button color="primary" defaultChecked defaultValue={GenderTypes.ALL} outline onClick={() => setIsGender(GenderTypes.ALL)} active={isGender === GenderTypes.ALL}>All</Button>
                      <Button color="primary" outline onClick={() => setIsGender(GenderTypes.MALE)} active={isGender === GenderTypes.MALE}>Male</Button>
                      <Button color="primary" outline onClick={() => setIsGender(GenderTypes.FEMALE)} active={isGender === GenderTypes.FEMALE}>Female</Button>
                      <Button color="primary" outline onClick={() => setIsGender(GenderTypes.NonBinary)} active={isGender === GenderTypes.NonBinary}>Non Binary</Button>
                      <Button color="primary" outline onClick={() => setIsGender(GenderTypes.OTHER)} active={isGender === GenderTypes.OTHER}>Other</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={12}>
                    <Label>Verified Status</Label>
                    <FormGroup>
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onKeyPress={handleKeyPress}
                        onChange={e => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value={UserStatus.ALL} > All </option>
                        <option value={UserStatus.VERIFIED}> Verified </option>
                        <option value={UserStatus.PENDING}> Pending </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="col-xl-12 mb-3 mt-5">
                  <Col lg={12}>
                    <button className="btn btn-success w-100" onClick={() => filterTherapists()}>Search</button>
                  </Col>
                </div>
              </OffcanvasBody>
            </Offcanvas>
            <Modal isOpen={isOpenBlockReasonModal} toggle={toggleReasonModal} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleReasonModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Block Therapist </span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <p className="mb-1">Reason</p>
                    <textarea rows={4} style={{ width: "100%" }} placeholder="Type a reason" onChange={(e: any) => setReason({ ...reason, sendReason: e.target.value })}></textarea>
                    {
                      reasonError && <p className="text-danger f-10">Please enter reason</p>
                    }
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() => blockUser(userId)}>Block</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            <Modal isOpen={isOpenPriorityModal} toggle={togglePriorityModal} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={togglePriorityModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Give {getLabelForValue(selectedPriorityTherapyType)} for {terapistName}  </span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <p className="mb-1"> </p>
                    <select
                      className="form-control mb-4"
                      value={selectedPriorityTherapyType}
                      onChange={e => setSelectedPriorityTherapyType(e.target.value)}
                    >
                      {priorityTypes &&
                        priorityTypes.map((opt: any, index: number) => (
                          <option value={opt?.value} key={index}>
                            {opt?.label}
                          </option>
                        ))}
                    </select>
                  </Col>
                </Row>
                {
                  selectedPriorityTherapyType == "mainPriority" && (
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-1"> </p>
                        <Input
                          type="number"
                          name="type"
                          placeholder="Type a number"
                          onChange={(e) => getSelectedPriorityNumber(e)}
                          value={selectedPnumber}
                        >
                        </Input>
                        {
                          priorityError && selectedPnumber && (selectedPnumber > count || selectedPnumber <= 0) && <p className="text-danger f-10"> Priority number should be from 1 to {count} </p>
                        }
                      </Col>
                    </Row>
                  )
                }
                {
                  selectedPriorityTherapyType == "blackTherapy" && (
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-1"> </p>
                        <Input
                          type="number"
                          name="type"
                          placeholder="Type a number"
                          onChange={(e) => getSelectedBlackTherapyPriorityNumber(e)}
                          value={selectedBlackTherapyPnumber}
                        >
                        </Input>
                        {
                          priorityError && selectedBlackTherapyPnumber && (selectedBlackTherapyPnumber > count || selectedBlackTherapyPnumber <= 0) && <p className="text-danger f-10"> Priority number should be from 1 to {count} </p>
                        }
                      </Col>
                    </Row>
                  )
                }
                {
                  selectedPriorityTherapyType == "mensTherapy" && (
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-1"> </p>
                        <Input
                          type="number"
                          name="type"
                          placeholder="Type a number"
                          onChange={(e) => getSelectedMensTherapyPriorityNumber(e)}
                          value={selectedMensTherapyPnumber}
                        >
                        </Input>
                        {
                          priorityError && selectedMensTherapyPnumber && (selectedMensTherapyPnumber > count || selectedMensTherapyPnumber <= 0) && <p className="text-danger f-10"> Priority number should be from 1 to {count} </p>
                        }
                      </Col>
                    </Row>
                  )
                }
                {
                  selectedPriorityTherapyType == "relationshipTherapy" && (
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-1"> </p>
                        <Input
                          type="number"
                          name="type"
                          placeholder="Type a number"
                          onChange={(e) => getSelectedRelationshipTherapyPriorityNumber(e)}
                          value={selectedRelationshipTherapyPnumber}
                        >
                        </Input>
                        {
                          priorityError && selectedRelationshipTherapyPnumber && (selectedRelationshipTherapyPnumber > count || selectedRelationshipTherapyPnumber <= 0) && <p className="text-danger f-10"> Priority number should be from 1 to {count} </p>
                        }
                      </Col>
                    </Row>
                  )
                }

                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>

                    {(selectedRelationshipTherapyPnumber && selectedRelationshipTherapyPnumber >= 0 || selectedMensTherapyPnumber && selectedMensTherapyPnumber >= 0 || selectedPnumber && selectedPnumber >= 0 || selectedBlackTherapyPnumber && selectedBlackTherapyPnumber >= 0) && (
                      <>
                        <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() => savePriorityNumber(userId)}>Save</button>
                        <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() => resetPriorityNumber(userId)}>Reset</button>
                      </>
                    )}
                  </Col>
                </Row>

              </ModalBody>
            </Modal>

            <UnmatchModal isOpen={modalOpenUnmatch} toggle={toggleModalUnmatch} therapistData={therapistData} />

            <SubmitClaimPartitionModal 
              isOpen={modalOpenClaimPartition} 
              toggle={toggleClaimPartitionModal} 
              therapistId={selectedTherapistIdForClaim} 
            />

          </React.Fragment>
        )
      }
      {
        currentPageComponents == 1 && (
          <ViewTherapistProfile selectedTherapistId={selectedTherapistId} callBackPage={callBackPage}/>
        )
      }

      {
        currentPageComponents == 2 && (
          <ViewTherapistSessions selectedTherapistId={selectedTherapistId} callBackPage={callBackPage}/>
        )
      }

    </>
  );
};

export default ViewAllTherapists;
