import React, { useEffect, useState } from "react";
const classNames = require('classnames');
import { Row, Col, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import ChildTab from "../ChildTabs/ChildTab";
import { Phrase, TabTypes } from "../../../models/Notes";
import { NoteService } from "../../../services/NoteService";
import Spinner from "../../../common/spinner/spinner";

interface SubjectiveTabProps {
  stage: string;
  activeVerticalTab: string;
  toggleVertical: (value: string) => void;
  chiefComplaint: string;
  setChiefComplaint: (value: string) => void;
  element: string;
  historyOfPresenseIllness: string;
  setHistoryOfPresenseIllness: (value: string) => void;
}

const SubjectiveTab: React.FC<SubjectiveTabProps> = ({
  element,
  stage,
  activeVerticalTab,
  toggleVertical,
  chiefComplaint,
  setChiefComplaint,
  historyOfPresenseIllness,
  setHistoryOfPresenseIllness,
}: SubjectiveTabProps) => {
  const [relationalPhrases, setRelationalPhrases] = useState<Phrase[]>([]);
  const [traumaPhrases, setTraumaPhrases] = useState<Phrase[]>([]);
  const [identityPhrases, setIdentityPhrases] = useState<Phrase[]>([]);
  const [processPhrases, setProcessPhrases] = useState<Phrase[]>([]);
  const [mournlossPhrases, setMournlossPhrases] = useState<Phrase[]>([]);
  const [adjustmentPhrases, setAdjustmentPhrases] = useState<Phrase[]>([]);
  const [dailyLifePhrases, setDailyLifePhrases] = useState<Phrase[]>([]);
  const [specificDifficultiesPhrases, setSpecificDifficultiesPhrases] = useState<Phrase[]>([]);
  const [isLoded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    NoteService.getAllPhrases(stage).then(res => {
      if (res.success) {
        setRelationalPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.RELATIONAL));
        setTraumaPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.TRAUMA));
        setIdentityPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.IDENTITY));
        setProcessPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.PROCESS));
        setMournlossPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.MOURNING_LOSS));
        setAdjustmentPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.ADJUSTMENT));
        setDailyLifePhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.DAILY_LIFE));
        setSpecificDifficultiesPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.SPECIFIC_DIFFICULTIES));
        setIsLoaded(true);
      } else {
        setRelationalPhrases([]);
        setTraumaPhrases([]);
        setIdentityPhrases([]);
        setProcessPhrases([]);
        setMournlossPhrases([]);
        setAdjustmentPhrases([]);
        setDailyLifePhrases([]);
        setSpecificDifficultiesPhrases([]);
        setTimeout(() => {
          setIsLoaded(true);
        }, 500);
      }
    });
  }, [stage]);

  return (
    <>
      <Row>
        <Col lg={3}>
          <Nav pills className="flex-column">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "1",
                })}
                onClick={() => {
                  toggleVertical("1");
                }}
                to={""}
              >
                Relational
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "2",
                })}
                onClick={() => {
                  toggleVertical("2");
                }}
                to={""}
              >
                Trauma
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "3",
                })}
                onClick={() => {
                  toggleVertical("3");
                }}
                to={""}
              >
                Identity
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "4",
                })}
                onClick={() => {
                  toggleVertical("4");
                }}
                to={""}
              >
                Process
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "5",
                })}
                onClick={() => {
                  toggleVertical("5");
                }}
                to={""}
              >
                Mourning / Loss
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "6",
                })}
                onClick={() => {
                  toggleVertical("6");
                }}
                to={""}
              >
                Adjustment
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "7",
                })}
                onClick={() => {
                  toggleVertical("7");
                }}
                to={""}
              >
                Daily Life
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "8",
                })}
                onClick={() => {
                  toggleVertical("8");
                }}
                to={""}
              >
                Specific Difficulties
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col lg={9}>
          {!isLoded && <Spinner className="bouncer" />}

          <TabContent activeTab={activeVerticalTab} className="text-muted">
            {relationalPhrases && relationalPhrases.length > 0 && (
              <ChildTab
                tabId={"1"}
                states={relationalPhrases}
                element={element}
                tabType={TabTypes.RELATIONAL}
                message={"The main themes of the session were:"}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {traumaPhrases && traumaPhrases.length > 0 && (
              <ChildTab
                tabId={"2"}
                states={traumaPhrases}
                tabType={TabTypes.TRAUMA}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {identityPhrases && identityPhrases.length > 0 && (
              <ChildTab
                tabId={"3"}
                states={identityPhrases}
                tabType={TabTypes.IDENTITY}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {processPhrases && processPhrases.length > 0 && (
              <ChildTab
                tabId={"4"}
                states={processPhrases}
                tabType={TabTypes.PROCESS}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {mournlossPhrases && mournlossPhrases.length > 0 && (
              <ChildTab
                tabId={"5"}
                states={mournlossPhrases}
                tabType={TabTypes.MOURNING_LOSS}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {adjustmentPhrases && adjustmentPhrases.length > 0 && (
              <ChildTab
                tabId={"6"}
                states={adjustmentPhrases}
                tabType={TabTypes.ADJUSTMENT}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {dailyLifePhrases && dailyLifePhrases.length > 0 && (
              <ChildTab
                tabId={"7"}
                states={dailyLifePhrases}
                tabType={TabTypes.DAILY_LIFE}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}

            {specificDifficultiesPhrases && specificDifficultiesPhrases.length > 0 && (
              <ChildTab
                tabId={"8"}
                states={specificDifficultiesPhrases}
                tabType={TabTypes.SPECIFIC_DIFFICULTIES}
                message={"The main themes of the session were:"}
                element={element}
                chiefComplaint={chiefComplaint}
                setChiefComplaint={setChiefComplaint}
                historyOfPresenseIllness={historyOfPresenseIllness}
                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
              />
            )}
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default SubjectiveTab;
