import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import images from "../../assets/images";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import UserContext from "../../context/UserContext";
import { TherapistService } from "../../services/TherapistService";
import { TreatmentHistory, TreatmentHistoryData } from "../../models/TreatmentHistory";
import { SocketContext } from "../../context/ScoketContext";
import Spinner from "../../common/spinner/spinner";
import { ParameterTypes } from "../../utils/ParameterTypes";
import CallInitializationContext from "../../context/CallInitializationContext";
import { Role } from "../../models/Role";
import defaultImage from "../../assets/images/default_profile.png";
import { PaymentService } from "../../services/PaymentService";
import LocalCallContext from "../../context/LocalCallContext";
import { TranscribeDetails } from "../../models/TranscribeDetails";
import HomeWorksAndGoals from "./HomeWorksAndGoals";
import Picker from "emoji-picker-react";
import Modal4 from "../Popup/Modal4";
import Modal5 from "../Popup/Modal5";
import Modal6ForChatComp from "../Popup/Modal6ForChatComp";
import Modal18 from "../Popup/Modal18";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageModal from "../Popup/MessageModal";
import SessionHistoryModal from "../Popup/SessionHistoryModal";
import ClinicalAssetmentModal from "../Popup/ClinicalAssessmentModal";
import ClinicalAssessmentPdfModal from "../Popup/ClinicalAssessmentPdfModal";
import TherapyPlanModal from "../Popup/TherapyPlanModal";
import { Client, PremiumStatus, testSubscriptionStatus } from "../../models/Client";
import { ClientService } from "../../services/ClientService";
import AppointmentModal from "../Popup/AppointmentModal";
import { ModalType, Repeat } from "../../models/Appointment";
import { Therapist } from "../../models/Therapist";
import ErrorModal from "../Popup/ErrorModal";
import UploadDocuments from "./UploadDocuments";
import { ChatModel } from "../../models/Chat";
import { ChatService } from "../../services/ChatService";
import { MessageModel } from "../../models/MessageModel";
import { User, UserRole } from "../../models/User";
import { DoDecrypt, DoEncrypt } from "./ChatComponents/Aes";
import DisplayMessageComp from "./ChatComponents/DisplayMessageComp";
import ReplyMessageDisplayComp from "./ChatComponents/ReplyMessageDisplayComp";
import ChatContext from "../../context/ChatContext";
import { CallInitializeData } from "../../models/CallInitializeData";
import "react-date-picker/dist/DatePicker.css";
import "./Chat.scss";
import ShowPopupSheduleContext from "src/context/ShowSchedulePopupContext";
import AddNewSessionModal from "../Popup/AddNewSessionModal";
import AvatarModeEnableModal from "../Popup/AvatarModeEnableModal";
import { v4 as uuidv4 } from "uuid";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Util } from "src/Util";
import OngoingCallRefreshModal from "../Popup/OngoingCallRefreshModal";
import Modal29 from "../Popup/Modal29";
import TwilioCall from "./TwilioCall";
import AmeriHealthModal from "../Popup/AmeriHealthModal";
import CarolinaCompleteHealthModal from "../Popup/CarolinaCompleteHealthModal";
import UnitedHealthCareModal from "../Popup/UnitedHealthCareModal";
import HealthBlueModal from "../Popup/HealthBlueModal";
import WellCareModal from "../Popup/WellCareModal";
import AmbetterModal from "../Popup/AmbetterModal";
import TherapyPlanPdfModal from "../Popup/TherapyPlanPdfModal";
import { AuthFormType, AuthorizationFormData } from "src/models/AuthorizationFormData";
import AuthorizationFormPdfModal from "../Popup/AuthorizationFormPdfModal";
import { FormVersionService } from "src/services/FormVersionService";
import Modal31 from "../Popup/Modal31";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import { encodeStringAndStartMeeting } from './vonage/vonage-native-sdk/hooks/useJoinCall';
import NewClinicalAssessmentModal from "../Popup/NewClinicalAssessmentModal";
import NewClinicalAssessmentPdfModal from "../Popup/NewClinicalAssessmentPdfModal";
import Modal32 from "../Popup/Modal32";
import { CAFormType } from "src/models/clinicalAssesment/clinicalAssesment";
import AIGeneratedAssessment from "../NewAssessmentDigitalForm/AIGeneratedAssessment";
import { MeetingData } from "src/models/MeetingData";
import Swal from "sweetalert2";

const classNames = require("classnames");

window.Buffer = window.Buffer || require("buffer").Buffer;

export enum ReceiverType {
  USER = "user",
  GROUP = "group",
}

export enum MessageType {
  textMessage = "TEXT_MESSAGE",
  mediaMessage = "MEDIA_MESSAGE",
}

interface TooltipState {
  [key: string]: boolean;
}

const Chat2: React.FC = () => {
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [messageBox, setMessageBox] = useState<HTMLInputElement | null>(null);
  const [chattingWith, setChattingWith] = useState("");
  const [chattingWithSocketId, setChattingWithSocketId] = useState("");
  const socket = useContext(SocketContext);
  const [unreadChatCount, setUnreadChatCount] = useContext(ChatContext);
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [isTreatmentOpen, setIsTreatmentOpen] = useState(false);
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(true);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [isTranscribeOpen, setTranscribeOpen] = useState(false);
  const [isTranscribeLoading, setIsTranscribeLoading] = useState(false);
  const [isTranscribeError, setIsTranscribeError] = useState(false);
  const [transcribe, setTranscribe] = useState<TranscribeDetails>();
  const [transcribeTextList, setTranscribeTextList] = useState<string[] | []>([]);
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(1);
  const [isTreatmentLazyLoading, setIsTreatmentLazyLoading] = useState(false);
  const [searchTextForTranscribe, setSearchTextForTranscribe] = useState("");
  const [settings_Menu1, setsettings_Menu1] = useState(false);
  const [settings_Menu2, setsettings_Menu2] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [chatBoxUsername, setChatBoxUsername] = useState("");
  const [chatBoxUserStatus, setChatBoxUserStatus] = useState("online");
  const [currentRecipientUser, setCurrentRecipientUser] = useState<User | undefined>(undefined);
  const [onlineUsers, setOnlineUsers] = useState<User[]>([]);
  const [isHomeWorkOpen, setIsHomeWorkOpen] = useState(false);
  const [isUploadDocumentsOpen, setIsUploadDocumentsOpen] = useState(false);
  const [onlineUser, setOnlineUser] = useState<any[] | []>([]);
  const [treatmentHistoryData, setTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [messageList, setMessageList] = useState<MessageModel[]>([]);
  const [conversationList, setConversationList] = useState<ChatModel[]>([]);
  const [modalCenter, setModalCenter] = useState(false);
  const [noteIdAdd, setNoteId] = useState("");
  const [singleTreatmentHistoryData, setSingleTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [uploadedFile, setUploadedFile] = useState({ preview: "", raw: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [messageSenderId, setMessageSenderId] = useState<string>("");
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const [fileType, setFileType] = useState("");
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<MessageModel | undefined>(undefined);
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalForClient, setShowModalForClient] = useState(false);
  const [showWordCountModal, setShowWordCountModal] = useState(false);
  // const [cursorPosition, setCursorPosition] = useState();
  const [isMessageReceived, setIsMessageReceived] = useState(false);
  const [showSessionHistoryModal, setShowSessionHistoryModal] = useState<boolean>(false); //change here
  // const [textAreaHeight, setTextAreaHeight] = useState("36.53px");
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = useState<boolean>(false);
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showTherapyPlanPdfModal, setShowTherapyPlanPdfModal] = useState<boolean>(false);
  const [showTherapyPlanModal, setShowTherapyPlanModal] = useState<boolean>(false);
  const [showAmeriHealthModal, setshowAmeriHealthModal] = useState<boolean>(false);
  const [showCarolinaCompleteHealthModal, setshowCarolinaCompleteHealthModal] = useState<boolean>(false);
  const [showUnitedHealthCareModal, setshowUnitedHealthCareModal] = useState<boolean>(false);
  const [showHealthBlueModal, setshowHealthBlueModal] = useState<boolean>(false);
  const [showWellCareModal, setshowWellCareModal] = useState<boolean>(false);
  const [showAmbetterModal, setshowAmbetterModal] = useState<boolean>(false);
  const [showAuthorizationFormPdfModal, setShowAuthorizationFormPdfModal] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [isRecipientTyping, setIsRecipientTyping] = useState({ isTyping: true, chatId: "" });
  const [currentChat, setCurrentChat] = useState<ChatModel | undefined>(undefined);
  const [socketRecieveMsg, setSocketRecieveMsg] = useState<any>();
  const [messagesOffsetPageNo, setMessagesOffsetPageNo] = useState(1);
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [dropdownAOpen, setDropdownAOpen] = useState(false);
  const [dropdownBOpen, setDropdownBOpen] = useState(false);
  const [dropdownCOpen, setDropdownCOpen] = useState(false);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
  });

  const [userId, setUserId] = useState("");
  const { chatId } = useParams<ParameterTypes>();
  const history = useHistory();
  const [token, setToken] = useState("");
  const messageHistoryRef = useRef([] as any);
  const currentUserRef = useRef("");
  const activeUsersRef = useRef([] as any);
  const conversationRef = useRef([] as any);
  const [showImcompleteCoPayments, setShowIncompleteCopayments] = useState(false);
  const [coPaymentAmount, setCopaymentAmount] = useState(0);
  const toggleDropdownA = () => setDropdownAOpen(!dropdownAOpen);
  const toggleDropdownB = () => setDropdownBOpen(!dropdownBOpen);
  const toggleDropdownC = () => setDropdownCOpen(!dropdownCOpen);
  // const chatInputRef = useRef<any>(null);

  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);

  const SCREEN_SIZE = 991;
  const SUPPORTED_FILE_TYPES = ["doc", "docx", "odt", "pdf", "xls", "xlsx", "ppt", "pptx", "txt"];
  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];
  const WORD_COUNT = 500;
  const [showPopup, setShowPopup] = useState(false);
  const [addNewSessionPopUp, setAddNewSessionPopUp] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  const [
    showSchedulePopup,
    setShowSchedulePopup,
    therapistIdForSchedulePopup,
    setTherapistIdForSchedulePopup,
    clientIdForSchedulePopup,
    setClientIdForSchedulePopup,
    showScheduleInTherapistChat,
    setShowScheduleInTherapistChat,
  ] = useContext(ShowPopupSheduleContext);
  const [showOngoingCallRefreshModal, setShowOngoingCallRefreshModal] = useState(false);

  const editorRef = useRef<any>(null);
  const editorConfiguration = {
    toolbar: ["bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
    placeholder: "Type a message",
  };

  const limitForChatList = 20;

  const [isGettingChatListInitial, setIsGettingChatListInitial] = useState(true);
  const [isPaginatingChatList, setIsPaginatingChatList] = useState(false);

  const [isSearchableString, setIsSearchableString] = useState("");
  const [finalSearchableString, setFinalSearchableString] = useState("");

  const [hasMoreData, setHasMoreData] = useState(true);
  const [modalTwilioCallOpen, setTwilioCallModalOpen] = useState(false);
  const [clientPhoneNumber, setClientPhoneNumber] = useState<string | undefined>();
  const [isTwiliotooltipOpen, setisTwilioTooltipOpen] = useState(false);
  const [isVideocallTooltipOpen, setIsVidecallTooltipOpen] = useState(false);

  const [twilioCalltooltipOpen, setTwilioCallTooltipOpen] = useState<TooltipState>({});
  const [videoCalltooltipOpen, setVideoCallTooltipOpen] = useState<TooltipState>({});
  const [authorizationData, setAuthorizationData] = useState<AuthorizationFormData[]>([]);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState("");
  const [authorizationFormType, setAuthorizationFormType] = useState("");

  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCreatingNewForm, setIsCreatingNewForm] = useState<boolean>(false);
  const [clientIdForForm, setClientIdForForm] = useState("");
  const [therapyPlanInfo, setTherapyPlanInfo] = useState("");
  const [showTherapyPlanWithVersionModal, setShowTherapyPlanWithVersionModal] = useState(false);
  const [therapyPlanId, setTherapyPlanId] = useState("");
  const [therapyPlanType, setTherapyPlanType] = useState("");
  const [clinicalAssessmentInfo, setClinicalAssessmentInfo] = useState("");
  const [showClinicalAssessmentWithVersionModal, setShowClinicalAssessmentWithVersionModal] = useState(false);
  const [clinicalAssessmentId, setClinicalAssessmentId] = useState("");
  const [clinicalAssessmentType, setClinicalAssessmentType] = useState("");
  const [selectedCAFormType, setSelectedCAFormType] = useState("");
  const [showAiGeneratedAssessmentPopup, setShowAiGeneratedAssessmentPopup] = useState<boolean>(false);
  const [firstMeetingData, setFirstMeetingData] = useState([] as MeetingData[]);
  const eventType = "DOWNLOAD";
  const chatRefs = useRef<Record<string, HTMLLIElement | null>>({});

  const scrollRef = useRef<any>(null);

  useEffect(() => {
    if (currentChat?._id && chatRefs.current[currentChat._id]) {
      const currentChatElement = chatRefs.current[currentChat._id];

      if (currentChatElement && scrollRef.current) {
        const elementTop = currentChatElement.offsetTop;
        scrollRef.current._container.scrollTop = elementTop;
      }
    }
  }, [currentChat?._id]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1300);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handleVideoMouseEnter = (userPhone: any) => {
    setVideoCallTooltipOpen(prevState => ({
      ...prevState,
      [userPhone]: true,
    }));
  };

  const handleVideoMouseLeave = (userPhone: any) => {
    setVideoCallTooltipOpen(prevState => ({
      ...prevState,
      [userPhone]: false,
    }));
  };

  const handleMouseEnter = (userPhone: any) => {
    setTwilioCallTooltipOpen(prevState => ({
      ...prevState,
      [userPhone]: true,
    }));
  };

  const handleMouseLeave = (userPhone: any) => {
    setTwilioCallTooltipOpen(prevState => ({
      ...prevState,
      [userPhone]: false,
    }));
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const toggleTooltip = (userPhone: string) => {
    setTwilioCallTooltipOpen(prevState => ({
      ...prevState,
      [userPhone]: !prevState[userPhone],
    }));
  };

  const toggleVideoTooltip = (userPhone: string) => {
    setVideoCallTooltipOpen(prevState => ({
      ...prevState,
      [userPhone]: !prevState[userPhone],
    }));
  };

  useEffect(() => {
    initialLoading();
  }, []);

  useEffect(() => {
    if (loggedUser?.incognitoPopupShow == false) {
      setShowPopup(true);
    }
  }, []);

  useEffect(() => {
    if (showScheduleInTherapistChat) {
      setShowAppointmentModal(true);
      setUserId(clientIdForSchedulePopup);
    }
  }, [showScheduleInTherapistChat]);

  const initialLoading = async () => {
    // setIsLoading(true);
    await getAlluserChats(true, false);
    // setIsLoading(false);
  };

  const getAlluserChats = async (isInitial: boolean, isSearch: boolean) => {
    try {
      let finalOffset = 0;
      if (isInitial) {
        setIsGettingChatListInitial(true);
        setConversationList([]);
        setIsPaginatingChatList(false);
        setIsSearchableString("");
        setFinalSearchableString("");
        setHasMoreData(true);
        finalOffset = 0;
      } else if (isSearch) {
        setIsGettingChatListInitial(true);
        setConversationList([]);
        setIsPaginatingChatList(false);
        setHasMoreData(true);
        finalOffset = 0;
      } else if (conversationList && conversationList.length) {
        setIsPaginatingChatList(true);
        finalOffset = conversationList.length;
      }

      const dataObject = {
        searchableString: isSearch ? isSearchableString : finalSearchableString,
        limit: limitForChatList,
        offset: finalOffset,
      };

      const res = await ChatService.getAllUserChats(dataObject);

      if (res.success && res.data) {
        const newList: ChatModel[] = res.data;
        const prevList: ChatModel[] = conversationList;
        if (newList.length < limitForChatList) {
          setHasMoreData(false);
        }

        if (isInitial || isSearch) {
          setConversationList([...newList]);
        } else {
          setConversationList([...prevList, ...newList]);
        }

        if (isSearch) {
          setFinalSearchableString(isSearchableString);
        }

        setIsGettingChatListInitial(false);
        setIsPaginatingChatList(false);
      } else {
        toast.error("Error loading data !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setIsPaginatingChatList(false);
      }
    } catch (error) {
      toast.error("Error loading data !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setIsPaginatingChatList(false);
    }
  };
  const [isLoadingConversationListLazy, setLoadingConversationListLazy] = useState(false);
  const [isLoadingConversationList, setIsLoadingConversationList] = React.useState<boolean>(true);
  const getMessagesByChatId = async (chatId: string, offset = 1) => {
    try {
      if (offset == 1) {
        setLoadingConversationListLazy(false);
        setIsLoadingConversationList(true);
        setMessageList([]);
      } else {
        if (isLoadingConversationList || isLoadingConversationListLazy) {
          return;
        }
        setLoadingConversationListLazy(true);
      }
      const chatMessages = await ChatService.getAllMessagesByChatId(chatId, 20, offset);

      if (chatMessages.success) {
        if (offset > 1) {
          setMessageList(prevState => [...chatMessages.data.reverse(), ...prevState]);
        } else {
          setMessageList(chatMessages.data.reverse());
        }
        if (offset == 1) {
          setIsLoadingConversationList(false);
        }
        setLoadingConversationListLazy(false);
      } else {
        setLoadingConversationListLazy(false);
        toast.error("Something Wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setLoadingConversationListLazy(false);
      toast.error("Something Wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getInsuranceByClientId = async (clientId: string) => {
    try {
      const res = await TherapistService.getInsuranceByClientIdViaTherapist(clientId);

      if (res && res?.success) {
        if (res?.data) {
          setAuthorizationData(res.data);
        } else {
          setAuthorizationData([]);
        }
      } else {
        toast.error("Something went wrong when retrieving prior authorization data!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error(`An error occurred when retrieving prior authorization data!. Error details: ${error}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
     }
  };

  const sendMessage = async () => {
    try {
      setIsSending(true);
      setShowEmojiPanel(false);

      const curMessage = editorRef.current.getData().toString();
      if (curMessage && curMessage != "" && curMessage.trim() != "") {
        const messageData = {
          senderId: loggedUser?._id,
          chatId: chatId,
          text: DoEncrypt(curMessage),
          unreadMessageObj: { unreadUserId: chatId, msgCount: 1 },
          ...(selectedMessage && { mentionedMessageId: selectedMessage._id }),
        };

        const tempChatId = Math.floor(Math.random() * 1000);

        const message: any = {
          _id: tempChatId,
          senderId: loggedUser,
          chatId: chatId,
          text: DoEncrypt(curMessage),
          createdAt: new Date(),
          AttachmentUploadId: null,
          ...(selectedMessage && { mentionedMessage: selectedMessage }),
        };

        const socketMessage = {
          _id: Math.floor(Math.random() * 100),
          senderId: loggedUser,
          chatId: chatId,
          text: DoEncrypt(curMessage),
          createdAt: new Date(),
          receiverId: currentRecipientUser?._id,
          AttachmentUploadId: null,
        };

        setMessageList([...messageList, message]);

        // setcurMessage("");

        const sentMessage = await ChatService.sendMessage(messageData);

        if (sentMessage.success) {
          setUploadedFile({ preview: "", raw: "" });
          setFileType("");
          setAttachmentFile(null);
          setShowReplyPanel(false);
          setSelectedMessage(undefined);
          if (editorRef.current) {
            editorRef.current.setData("");
            editorRef.current.focus();
          }

          socket.emit("send-message", { ...sentMessage?.data, receiverId: currentRecipientUser?._id });

          setMessageList(prevState => {
            return prevState.map(message => {
              if (message._id == tempChatId.toString()) {
                return { ...sentMessage?.data };
              }
              return message;
            });
          });
        } else {
          toast.error("Something Wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("You can't send empty message!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Something Wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    setIsSending(false);
  };

  const sendMessageWithAttachment = async () => {
    try {
      setShowEmojiPanel(false);
      setIsSending(true);

      const curMessage = editorRef.current.getData().toString();

      if ((curMessage && curMessage != "" && curMessage.trim() != "" && !attachmentFile) || attachmentFile) {
        setIsMessageSending(true);

        const messageData = {
          senderId: loggedUser?._id,
          chatId: chatId,
          text: curMessage ? DoEncrypt(curMessage) : null,
          unreadMessageObj: { unreadUserId: chatId, msgCount: 1 },
          ...(selectedMessage && { mentionedMessageId: selectedMessage._id }),
        };

        const sentMessage = await ChatService.sendMessageWithAttachment(messageData, attachmentFile!);

        if (sentMessage.success) {
          setUploadedFile({ preview: "", raw: "" });
          setFileType("");
          setAttachmentFile(null);
          setShowReplyPanel(false);
          setSelectedMessage(undefined);
          if (editorRef.current) {
            editorRef.current.setData("");
            editorRef.current.focus();
          }

          socket.emit("send-message", { ...sentMessage?.data, receiverId: currentRecipientUser?._id });
          setMessageList([...messageList, sentMessage?.data]);
        } else {
          toast.error("Something Wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        // setcurMessage("");

        setIsMessageSending(false);
      } else {
        toast.error("You can't send empty message!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Something Wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setIsMessageSending(false);
    }
    setIsSending(false);
  };

  const deleteMessage = async (messageId: string) => {
    try {
      const deletedMsg = await ChatService.deleteMessage(messageId);
      if (deletedMsg.success) {
        const tempMsgList = messageList.filter(message => message._id != messageId);
        setMessageList(tempMsgList);
      } else {
        toast.error("Could not delete the message!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Could not delete the message!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const addMessage = () => {
    if (attachmentFile) {
      sendMessageWithAttachment();
    } else {
      sendMessage();
    }
  };

  const currentChatSelect = (chats: ChatModel[]) => {
    if (chatId) {
      const currChatId = chats?.find(function (c) {
        return c.members?.find(function (m) {
          return m._id == chatId;
        });
      });

      if (currChatId) {
        if (currChatId?.unreadMessage?.unreadUserId == loggedUser?._id) {
          markMessagesAsSeen(currChatId?._id, currChatId?.unreadMessage?.unreadUserId);
        }

        setCurrentChat(currChatId);
        const otherUser = currChatId?.members?.find((user: any) => user._id !== loggedUser?._id);
        setAuthorizationData([]);
        setInsuranceCompanyId("");

        if (loggedUser?.role == Role.THERAPIST) {
          getInsuranceByClientId(otherUser?._id || "");
        }

        setCurrentRecipientUser(otherUser);
        setUserId(otherUser?._id || "");
        setChattingWith(otherUser?._id || "");
        setChattingWithSocketId(otherUser?.socketId || "");
        setChatBoxUserStatus(currChatId?.status || "offline");
        setChatBoxUsername(otherUser?.firstname + " " + otherUser?.lastname || "");
      }
    }
  };

  const markMessagesAsSeen = async (chatId: string, unreadMessageId: string | undefined) => {
    try {
      const res = await ChatService.markAsReadMessages(chatId);
      if (unreadMessageId) {
        if (unreadChatCount) {
          setUnreadChatCount(unreadChatCount - 1);
        }
      }
      removeNotificationToChat(chatId);
    } catch (error) { }
  };

  const updateOnlineUserStates = () => {
    const filterOnlineUsers = onlineUsers?.filter((user: any) => user?.userId !== loggedUser?._id);

    const tempChatList = conversationList?.map(chat => {
      if (filterOnlineUsers?.some((user: any) => chat?.members?.some((r: any) => r._id == user?.userId))) {
        return { ...chat, status: "online" };
      }
      return { ...chat, status: "offline" };
    });

    setConversationList(tempChatList);
  };

  useEffect(() => {
    if (conversationList && conversationList.length > 0) {
      updateOnlineUserStates();
    }
  }, [onlineUsers]);

  const addNotificationToChat = (message: MessageModel) => {
    const tempChatList = conversationList
      ?.map(chat => {
        if (chat?._id == message?.chatId) {
          const tempUnreadObj = {
            unreadUserId: loggedUser?._id || undefined,
            msgCount: 1,
            lastMessage: message,
          };
          return { ...chat, unreadMessage: tempUnreadObj, lastActiveTime: new Date().toString() };
        }
        return chat;
      })
      .sort((objA, objB) => Number(new Date(objB.lastActiveTime)) - Number(new Date(objA.lastActiveTime)));

    setConversationList(tempChatList);
  };

  const removeNotificationToChat = (chatId: string) => {
    const tempChatList = conversationList?.map(chat => {
      if (chat?._id == chatId) {
        return { ...chat, unreadMessage: { ...chat.unreadMessage, unreadUserId: undefined } };
      }
      return chat;
    });

    setConversationList(tempChatList);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setToken(token);
    }

    if (loggedUser?.subscriptionId) {
      PaymentService.getActiveSubscription().then((subscription: any) => {
        // setLoggedUser({ ...loggedUser, subscriptionStatus: subscription.data.status });
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(messageList)) {
      scrollToBottom();
    }
  }, [messageList]);

  useEffect(() => {
    try {
      socket?.on("get-online-users", (users: any) => {
        setOnlineUsers(users);
      });
      socket?.on("recipient-is-typing", (data: any) => {
        setIsRecipientTyping(data);
      });
      socket?.on("receive-message", (data: any) => {
        setSocketRecieveMsg(data);
      });

      socket?.emit("fetch-online-users");
    } catch (error) { }
  }, [socket]);

  useEffect(() => {
    addNotificationToChat(socketRecieveMsg);
    if (socketRecieveMsg?.chatId == currentChat?._id) {
      setMessageList([...messageList, socketRecieveMsg]);
    }
  }, [socketRecieveMsg]);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
    const chattingWithUser = filterUsername(onlineUser, userId);
    currentUserRef.current = userId;

    if (chatId == undefined) {
      setShouldDisplay(true);
    } else {
      setShouldDisplay(false);
    }

    setShowEmojiPanel(false);
    setUploadedFile({ preview: "", raw: "" });
    setFileType("");
    setAttachmentFile(null);
    setShowReplyPanel(false);
    setSelectedMessage(undefined);

    if (chatId) {
      setMessagesOffsetPageNo(1);
      getMessagesByChatId(chatId);
      currentChatSelect(conversationList);
      if (editorRef.current) {
        editorRef.current.setData("");
        editorRef.current.focus();
      }
    }

    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, [chatId]);

  useEffect(() => {
    if (conversationList && conversationList.length > 0) {
      currentChatSelect(conversationList);
    }
  }, [conversationList]);

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const filterUsername = (list: any[], id: string) => {
    for (const item of list) {
      if (item.uid == id) {
        return item.name;
      }
    }
    return null;
  };

  const toggleSettings1 = () => {
    setsettings_Menu1(!settings_Menu1);
  };

  const toggleSettings2 = () => {
    setsettings_Menu2(!settings_Menu2);
  };

  const toggleHGCanvas = () => {
    setIsHomeWorkOpen(!isHomeWorkOpen);
  };

  const toggleUDCanvas = () => {
    setIsUploadDocumentsOpen(!isUploadDocumentsOpen);
  };

  const toggleTreatmentCanvas = () => {
    setShowSessionHistoryModal(true);
  };

  const generateClinicalPdf = (planId: string, tpType: TPType) => {
    setClinicalAssessmentId(planId);
    setClinicalAssessmentType(tpType);

    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowClinicalAssessmentPdfModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowNewClinicalAssessmentPdfModal(true);
    }
  };

  const clinicalAssessment = () => {
    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowNewClinicalAssessmentModal(false);
      setShowClinicalAssessmentModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowClinicalAssessmentModal(false);
      setShowNewClinicalAssessmentModal(true);
    }
  };

  // const showNewClinicalAssessment = () => {
  //   setShowNewClinicalAssessmentModal(true);
  // };

  // const generateNewClinicalPdf = () => {
  //   setShowNewClinicalAssessmentPdfModal(true);
  // };

  const generateTherapyPlanPdf = (planId: string, tpType: TPType) => {
    setTherapyPlanId(planId);
    setTherapyPlanType(tpType);
    setShowTherapyPlanPdfModal(true);
  };

  const generateAuthorizationFormPdf = (relatedInsuranceCompanyId: any, formType: string) => {
    if (relatedInsuranceCompanyId && formType) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setAuthorizationFormType(formType);
      setShowAuthorizationFormPdfModal(true);
    }
  };

  const createNewTherapyPlan = () => {
    setTherapyPlanId(userId);
    setShowTherapyPlanModal(true);
    setIsCreatingNewForm(true);
  };

  const therapyPlan = (planId: string) => {
    setTherapyPlanId(planId);
    setShowTherapyPlanModal(true);
    setIsCreatingNewForm(false);
  };

  const showSwalAlertForAuthForm = () => {
    Swal.fire({
      icon: "info",
      title: "Unable to Identify Client Insurance Due to a Device-Level Issue!",
      html: `
        <p>We were unable to identify the client’s insurance due to a device-level issue; therefore, we cannot open the prior authorization request form.</p>
        <p>To resolve this, click the refresh button below. After the page reloads, open the prior authorization request form and complete the details.</p>
      `,
      showCancelButton: true,
      confirmButtonText: "Refresh",
      confirmButtonColor: "#50a5f1",
      cancelButtonColor: "#f46a6a",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
        Swal.fire({
          title: "Refreshing...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      }
    });
  };  

  const ameriHealthAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    if (relatedInsuranceCompanyId) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setshowAmeriHealthModal(true);
    } else {
      showSwalAlertForAuthForm();
    }
  };
  const carolinaCompleteAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    if (relatedInsuranceCompanyId) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setshowCarolinaCompleteHealthModal(true);
    } else {
      showSwalAlertForAuthForm();
    }
  };
  const unitedHealthCareAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    if (relatedInsuranceCompanyId) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setshowUnitedHealthCareModal(true);
    } else {
      showSwalAlertForAuthForm();
    }
  };
  const healthBlueAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    if (relatedInsuranceCompanyId) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setshowHealthBlueModal(true);
    } else {
      showSwalAlertForAuthForm();
    }
  };
  const wellCareAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    if (relatedInsuranceCompanyId) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setshowWellCareModal(true);
    } else {
      showSwalAlertForAuthForm();
    }
  };
  const ambetterAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    if (relatedInsuranceCompanyId) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setshowAmbetterModal(true);
    } else {
      showSwalAlertForAuthForm();
    }
  };

  const formOptions = {
    AmeriHealthAuthForm: {
      baseLabel: "Ameri Health Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => ameriHealthAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    WellcareAuthForm: {
      baseLabel: "Well Care Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => wellCareAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    UnitedHealthCareAuthForm: {
      baseLabel: "United Health Care Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => unitedHealthCareAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    HealthyBlueAuthForm: {
      baseLabel: "Health Blue Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => healthBlueAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    CarolinaCompleteHealthAuthForm: {
      baseLabel: "Carolina Complete Health Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => carolinaCompleteAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    AmbetterAuthForm: {
      baseLabel: "Ambetter Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => ambetterAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
  };

  const availableFormTypes = authorizationData.map(item => ({
    type: item.insuranceCompanyId.authorizationFormType,
    id: item.insuranceCompanyId._id,
    isPrimary: item.insuranceCompanyId.isPrimary,
  }));

  function closeTranscribe() {
    setIsTranscribeError(false);
    setIsTranscribeLoading(false);
    setTranscribeOpen(false);
    setSearchTextForTranscribe("");
  }

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  function togCenter(noteId: string) {
    setNoteId(noteId);
    const selectedHistory = treatmentHistoryDetails.filter(c => c._id === noteId);
    const selectedHistorydata = selectedHistory[0];
    setSingleTreatmentHistoryData(selectedHistorydata);
    setModalCenter(!modalCenter);
    removeBodyCss();
  }

  function togCenterClose() {
    setModalCenter(false);
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const openChatBox = (uID: any) => {
    if (screenSize <= SCREEN_SIZE) {
      setShouldDisplay(false);
    }

    if (uID == messageSenderId) {
      setIsMessageReceived(false);
    }

    // currentChatSelect(conversationList);

    if (location.pathname !== `/chat/${uID}`) {
      history.push(`/chat/${uID}`);
    }
  };

  const toggleModal = () => {
    setTwilioCallModalOpen(!modalTwilioCallOpen);
  };

  const handleConfirm = (userNumber: string | undefined) => {
    console.log("Function called on confirm");
    console.log(userNumber);
    setClientPhoneNumber(userNumber);
    // Place your function logic here
    toggleModal();
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollTop + 100;
    }
  };

  const addTreatmentHistory = () => {
    const createTreatement: TreatmentHistory = {
      clientId: chattingWith,
      note: treatmentHistoryData?.note,
    };

    TherapistService.createTreatmentHistory(createTreatement).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setTreatmentHistoryData({
          note: "",
        });
        setIsTreatmentLoading(true);
        setIsTreatmentError(false);
        getTreatmentHistoryNotes(chattingWith);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const getTreatmentHistoryNotes = (id: string) => {
    // setOffset(1);
    TherapistService.getTreatmentHistory(id, limit * offset, 0).then(res => {
      if (res.success) {
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };
  const getTreatmentHistoryNotesInitial = (id: string) => {
    setOffset(1);
    TherapistService.getTreatmentHistory(id, limit * 1, 0).then(res => {
      if (res.success) {
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };
  const loadMoreTreatmentHistoryNotes = (id: string) => {
    setIsTreatmentLazyLoading(true);
    TherapistService.getTreatmentHistory(id, limit, offset * limit).then(res => {
      if (res.success) {
        setIsTreatmentLazyLoading(false);
        if (res.data.length != 0) {
          setOffset(offset + 1);
          const newData = treatmentHistoryDetails.concat(res.data);
          setTreatmentHistory(newData);
        } else {
          toast.success("No more data found !", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsTreatmentLazyLoading(false);
      }
    });
  };

  const removeTreatmentHistory = (noteId: string) => {
    TherapistService.deleteTreatmentHistory(noteId).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setModalCenter(false);
        setIsTreatmentLoading(true);
        setIsTreatmentError(false);
        getTreatmentHistoryNotes(chattingWith);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const updateTreatmentHistory = () => {
    const updateTreatement: TreatmentHistory = {
      clientId: chattingWith,
      noteId: noteIdAdd,
      note: treatmentHistoryData?.note,
    };
    TherapistService.updateTreatmentHistory(updateTreatement).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setTreatmentHistoryData({
          note: "",
        });
        setIsTreatmentLoading(true);
        setIsTreatmentError(false);
        getTreatmentHistoryNotes(chattingWith);
        setModalCenter(false);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const searchTranscribe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextForTranscribe(event.target.value);
    const textList: string[] = [];
    const combinedArray = textList.concat(transcribe?.transcriptText != null ? transcribe?.transcriptText : []);
    const result = combinedArray.filter(word => word.match(event.target.value));
    setTranscribeTextList(result);
  };

  const onIconClick = () => {
    if (
      (loggedUser?.subscriptionId && loggedUser.subscriptionStatus == "active") ||
      loggedUser?.premiumStatus == PremiumStatus.ACTIVE ||
      loggedUser?.testSubscriptionStatus == testSubscriptionStatus.ACTIVE ||
      loggedUser?.role == Role.THERAPIST
    ) {
      const input = document.getElementById("image-input");
      if (input) {
        input.click();
      }
    }
  };

  const removeFile = () => {
    setUploadedFile({ raw: "", preview: "" });
    setFileType("");
    setAttachmentFile(null);
  };

  const onFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      const extension = event.target.files[0].name.split(".").pop().toLowerCase();
      const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);
      const isAFile = isSupported(SUPPORTED_FILE_TYPES, extension);

      if (isAFile) {
        setUploadedFile({
          raw: event.target.files[0],
          preview: "",
        });
        setAttachmentFile(event.target.files[0]);
        setFileType("FILE");
      } else if (isAnImage) {
        setUploadedFile({
          raw: event.target.files[0],
          preview: URL.createObjectURL(event.target.files[0]),
        });
        setAttachmentFile(event.target.files[0]);
        setFileType("IMAGE");
      } else {
        toast.error("File format is not supported!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return;
      }
    }
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const navigateToProfile = () => {
    history.push(`/profile/details/${chattingWith}`);
  };

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  const copyToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopy = (text: string) => {
    const copiedTextInString = text;

    if (copiedTextInString != undefined || copiedTextInString != null || copiedTextInString != "") {
      copyToClipboard(copiedTextInString).then(() => {
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
    } else {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const openReplyPanel = (messageDetails: MessageModel) => {
    setShowReplyPanel(!showReplyPanel);
    setSelectedMessage(messageDetails);
  };

  const openEmojiPanel = () => {
    try {
      editorRef.current.focus();
      setShowEmojiPanel(!showEmojiPanel);
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onEmojiClick = (event: any, { emoji }: any) => {
    try {
      const editor = editorRef.current;
      const model = editor.model;

      const selection = model.document.selection;

      if (selection.isCollapsed) {
        const position = selection.getFirstPosition();

        model.change((writer: any) => {
          writer.insertText(emoji, position);
        });
      } else {
        const range = selection.getFirstRange();

        model.change((writer: any) => {
          writer.insertText(emoji, range.end);
        });
      }

      editor.focus();
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const back = () => {
    setIsLoadingConversationList(true);
    setMessageList([]);
    history.push("/chat");
  };

  const [showTimeSelectModal, setShowTimeSelectModal] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);

  // const startInstantMeetingInitial = (time: number, showModal: boolean, getCall: boolean, isVideoCall: boolean) => {
  //   setShowTimeSelectModal(showModal);

  //     if (isVideoCall) {
  //       if (getCall) {
  //         startInstantMeeting(chattingWith, isVideoCall, time);
  //       }
  //     } else {
  //       startAudioCallMeeting(chattingWith, time);
  //     }
  // };

  const startInstantMeetingInitial = (time: number, showModal: boolean, getCall: boolean, isVideoCall: boolean, isVonageNativeVideoCall: boolean) => {
    try {
      setShowTimeSelectModal(showModal);
      if (!isVonageNativeVideoCall) {
        if (isVideoCall) {
          if (getCall) {
            startInstantMeeting(chattingWith, isVideoCall, time);
          }
        } else {
          startAudioCallMeeting(chattingWith, time);
        }
      } else {
        encodeStringAndStartMeeting(
          'yes',
          chattingWith,
          loggedUser?.role === Role.THERAPIST ? Role.CLIENT : Role.THERAPIST,
          loggedUser?._id,
          loggedUser?.role === Role.THERAPIST ? Role.THERAPIST : Role.CLIENT
        );
      }
    } catch (error) {
      console.log(error);
    }
    
      
  };

  const startAudioCallMeeting = (userId: any, meetingTime: number) => {
    if (userId == "") {
      alert("No valid user id is provided!");
    } else if (localCallInitialize) {
      setShowOngoingCallRefreshModal(true);
    } else {
      const callInitializeData: CallInitializeData = {
        start: true,
        isInstantMeeting: true,
        isAppointmentBased: false,
        appointmentId: "ddddddd",
        recieverId: userId,
        meetingTime: meetingTime,
        isTranscribeAllowed: true,
        meetingId: "",
        isAudioCall: true,
      };

      setLocalCallInitializeData(callInitializeData);
      setOutGoinCallTimeOutFunction();

      const roomNamePartOne = loggedUser?._id;
      const roomNamePartTwo = userId;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room/${roomName}`;
      history.push(vonageUrl);
    }
  };

  const startInstantMeeting = (userId: any, isVideoCall: boolean, meetingTime: number) => {
    if (loggedUser?.role == Role.THERAPIST || loggedUser?.role == Role.CLIENT) {
      if (userId == "") {
        alert("No valid user id is provided!");
      } else if (localCallInitialize) {
        setShowOngoingCallRefreshModal(true);
      } else {
        const callInitializeData: CallInitializeData = {
          start: true,
          isInstantMeeting: true,
          isAppointmentBased: false,
          appointmentId: "ddddddd",
          recieverId: userId,
          meetingTime: meetingTime,
          isTranscribeAllowed: true,
          meetingId: "",
          isAudioCall: false,
        };

        setLocalCallInitializeData(callInitializeData);
        setOutGoinCallTimeOutFunction();
        const roomNamePartOne = loggedUser?._id;
        const roomNamePartTwo = userId;
        const uniqueId = uuidv4();
        const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
        const vonageUrl = `/room/${roomName}`;
        history.push(vonageUrl);
      }
    }
  };

  const sendTypingStatusTrue = () => {
    socket.emit("recipient-typing-receive", { recipientId: currentRecipientUser?._id, chatId: currentChat?._id });
  };

  const sendTypingStatusFalse = () => {
    socket.emit("recipient-typing-end-receive", { recipientId: currentRecipientUser?._id, chatId: currentChat?._id });
  };
  const clickShowAddNewSessionPopUp = () => {
    // history.push('/add-session');
    setAddNewSessionPopUp(true);
  };

  const joinZoomCall = async () => {
    const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(chattingWith);
    if (meetingRes.success) {
      let totalAmount = 0;
      for (const meeting of meetingRes.data) {
        const amount = parseFloat(meeting?.copayment?.amount) || 0;
        totalAmount += amount;
      }
      if (totalAmount > 0) {
        setCopaymentAmount(totalAmount);
        setShowIncompleteCopayments(true);
        const meetingRes: any = await ClientService.sendSMSAndEmailForClient(chattingWith, totalAmount);
      } else {
        setShowTimeSelectModal(true);
      }
    }
  };

  const setJoinCall = () => {
    setShowIncompleteCopayments(false);
    setShowTimeSelectModal(true);
  };

  const setShowOngoingCallRefreshModalFunc = (showModal: boolean) => {
    setShowOngoingCallRefreshModal(showModal);
  };

  const handleSearch = () => {
    if (!isGettingChatListInitial && !isPaginatingChatList) {
      setIsLoadingConversationList(true);
      setMessageList([]);
      history.push("/chat");
      getAlluserChats(false, true);
    } else {
      toast.error("Please wait", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const twilioCallTooltipToggle = () => setisTwilioTooltipOpen(!isTwiliotooltipOpen);

  const videoCallTooltipToggle = () => setIsVidecallTooltipOpen(!isVideocallTooltipOpen);

  // const handleMouseEnter = () => {
  //   console.log("call handle mouse enter");
  //   setisTwilioTooltipOpen(true);
  // };

  // const handleMouseLeave = () => {
  //   console.log("call handle mouse leave");
  //   setisTwilioTooltipOpen(false);
  // };

  const handleMouseEnterVideoCallButton = () => {
    setIsVidecallTooltipOpen(true);
  };

  const handleMouseLeaveVideoCallButton = () => {
    setIsVidecallTooltipOpen(false);
  };

  const handleTherapyPlansView = async (clientIdForForm: any) => {
    if (clientIdForForm != undefined) {
      setIsLoading(true);
      setClientIdForForm(clientIdForForm);

      const result = await fetchTherapyPlanWithVersions(clientIdForForm);
      if (result.success) {
        setTherapyPlanInfo(result.data);
      }
      setIsLoading(false);
      setShowTherapyPlanWithVersionModal(true);
    }
  };

  const fetchTherapyPlanWithVersions = async (clientIdForForm: string) => {
    try {
      const res = await FormVersionService.getTherapyPlansWithAllVersions(clientIdForForm);
      if (res && res?.success && res?.data) {
        return { success: true, data: res.data };
      } else {
        toast.error("Encountered an error while retrieving the therapy plan data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const refreshModal31 = async (value: boolean) => {
    if (value) {
      const result = await fetchTherapyPlanWithVersions(clientIdForForm);
      if (result.success) {
        setTherapyPlanInfo(result.data);
      }
    }
  };

  const generateUniqueString = () => {
    const timestamp = Date.now().toString(36);
    const uuid = uuidv4();
    return `${timestamp}-${uuid}`;
  };

  // const testVonageCall = () => {
  //   try {
  //     encodeStringAndStartMeeting(
  //       'yes',
  //       chattingWith,
  //       loggedUser?.role === Role.THERAPIST ? Role.CLIENT : Role.THERAPIST,
  //       loggedUser?._id
  //     );
      
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleClinicalAssessmentView = async (clientIdForForm: any) => {
    if (clientIdForForm != undefined) {
      setIsLoading(true);
      setClientIdForForm(clientIdForForm);
      setSelectedCAFormType("");
      setFirstMeetingData([] as MeetingData[]);

      const result = await fetchClinicalAssessmentWithVersions(clientIdForForm);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
        setShowClinicalAssessmentWithVersionModal(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const fetchClinicalAssessmentWithVersions = async (clientIdForForm: string) => {
    try {
      const res = await FormVersionService.getClinicalAssessmentsWithAllVersions(clientIdForForm);

      if (res && res.success) {
        const { relatedCAFormType, caWithVersion, daWithVersion, meetingData } = res.data;

        if (relatedCAFormType == CAFormType.OLD_CA) {
          setSelectedCAFormType(CAFormType.OLD_CA);
          return { success: true, data: caWithVersion };
        } else if (relatedCAFormType == CAFormType.NEW_CA) {
          setSelectedCAFormType(CAFormType.NEW_CA);

          if (meetingData) {
            setFirstMeetingData(meetingData);
          }
          // if (daWithVersion.length == 0 && meetingData) {
          //   setFirstMeetingData(meetingData);
          // }
          return { success: true, data: daWithVersion };
        } else {
          return { success: false };
        }
      } else {
        toast.error("Encountered an error while retrieving the assessment data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const refreshModal32 = async (value: boolean) => {
    if (value) {
      const result = await fetchClinicalAssessmentWithVersions(clientIdForForm);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
      }
    }
  };

  const handleChildFunction = (isCalled: boolean) => {
    setShowAiGeneratedAssessmentPopup(isCalled);
  };

  const generateAiAssessmentFunction = () => {
    setShowAiGeneratedAssessmentPopup(true);
  };

  const leftNavBarComponent = () => (
    <>
      <AvatarModeEnableModal showModal={showPopup} setShowModal={setShowPopup} loggedUser={loggedUser} setLoggedUser={setLoggedUser} />
      <Nav pills justified>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            <i className="bx bx-group font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">Messages</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "2",
            })}
            onClick={() => {
              history.push(`/group-chat`);
            }}
          >
            <i className="bx bx-group font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">Groups</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId="1">
          <div>
            <h5 className="font-size-14 mb-1">Recent Chats</h5>
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-2">
                  <label htmlFor="searchInput" className="visually-hidden">
                    Search
                  </label>
                  <input
                    type="text"
                    id="searchInput"
                    className="form-control search-input-stat rounded-start"
                    onChange={e => setIsSearchableString(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    placeholder="Enter name to search..."
                  />
                  <button className="btn btn-warning d-flex justify-content-center align-items-center rounded-end" type="button" onClick={handleSearch}>
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <ul className="list-unstyled chat-list" id="recent-list">
              <div style={{ height: "410px", overflow: "hidden" }}>
                <PerfectScrollbar
                  ref={scrollRef}
                  style={{ height: "100%" }}
                >
                  {!isGettingChatListInitial ? (
                    conversationList && conversationList?.length > 0 ? (
                      <div>
                        {conversationList?.map((conversation, index: number) => {
                          const otherUser = conversation.members.find(user => user._id !== loggedUser?._id);
                          if (!otherUser || !otherUser.primaryPhone) return null;

                          const userPhone = otherUser.primaryPhone;
                          return (
                            <li
                              ref={(el) => {
                                if (conversation._id) chatRefs.current[conversation._id] = el;
                              }}
                              className={
                                currentChat?._id == conversation._id
                                  ? "active active1"
                                  : conversation?.unreadMessage && conversation?.unreadMessage?.unreadUserId == loggedUser?._id
                                    ? "chat-received"
                                    : ""
                              }
                              key={conversation._id}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  openChatBox(conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?._id);
                                }}
                              >
                                <div className="d-flex">
                                  <div className="align-self-center me-3">
                                    <i
                                      className={
                                        conversation?.status === "online"
                                          ? "mdi mdi-circle text-success font-size-10"
                                          : conversation?.status === "intermediate"
                                            ? "mdi mdi-circle text-warning font-size-10"
                                            : "mdi mdi-circle font-size-10"
                                      }
                                    />
                                  </div>
                                  <div className="align-self-center me-3">
                                    <img
                                      src={
                                        conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?.photoId &&
                                          conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?.photoId?._id
                                          ? Util.fileURL(conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?.photoId?._id)
                                          : defaultImage
                                      }
                                      className="rounded-circle avatar-xs imageFit"
                                    />
                                  </div>

                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1">{`${conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?.firstname
                                      } ${conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?.lastname}`}</h5>
                                    {conversation?.unreadMessage?.lastMessage?.AttachmentUploadId ? (
                                      <p className="text-truncate mb-0">
                                        <i>Attachment</i>
                                      </p>
                                    ) : (
                                      <>
                                        <p
                                          style={{ width: "150px" }}
                                          className={
                                            conversation?.unreadMessage && conversation?.unreadMessage?.unreadUserId == loggedUser?._id
                                              ? "text-truncate mb-0 font-bold"
                                              : "text-truncate mb-0"
                                          }
                                        >
                                          {conversation &&
                                            conversation.unreadMessage &&
                                            conversation.unreadMessage.lastMessage &&
                                            conversation.unreadMessage.lastMessage.text
                                            ? Util.htmlToTextFunction(DoDecrypt(conversation.unreadMessage.lastMessage.text))
                                            : ""}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  {loggedUser?.role == Role.THERAPIST && (
                                    <>
                                      <div>
                                        <Button
                                          style={{
                                            background: twilioCalltooltipOpen[userPhone] ? "#f7f7f7" : "transparent",
                                            border: "none",
                                            boxShadow: twilioCalltooltipOpen[userPhone] ? "0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11)" : "",
                                            marginRight: "10px",
                                          }}
                                          disabled={userPhone == null}
                                          id={`tool-twiliocall-${conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?._id}`}
                                          className="icon-button"
                                          onMouseEnter={() => handleMouseEnter(userPhone)}
                                          onMouseLeave={() => handleMouseLeave(userPhone)}
                                          onClick={() => handleConfirm(conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?.primaryPhone)}
                                        >
                                          <img src={images.chat_call} alt="chat_call" />
                                        </Button>
                                        <Tooltip
                                          toggle={() => toggleTooltip(userPhone)}
                                          isOpen={twilioCalltooltipOpen[userPhone] || false}
                                          target={`tool-twiliocall-${conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?._id}`}
                                          placement="left"
                                        >
                                          Phone Call
                                        </Tooltip>
                                      </div>
                                      <div className="flex ">
                                        <Button
                                          style={{
                                            background: videoCalltooltipOpen[userPhone] ? "#f7f7f7" : "transparent",
                                            border: "none",
                                            boxShadow: videoCalltooltipOpen[userPhone] ? "0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11)" : "",
                                          }}
                                          id={`tool-videocall-${conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?._id}`}
                                          onMouseEnter={() => handleVideoMouseEnter(userPhone)}
                                          onMouseLeave={() => handleVideoMouseLeave(userPhone)}
                                          className="icon-button"
                                          onClick={() => {
                                            joinZoomCall();
                                          }}
                                        >
                                          <img src={images.chat_video} alt="chat_call" />
                                        </Button>
                                        <Tooltip
                                          toggle={() => toggleVideoTooltip(userPhone)}
                                          isOpen={videoCalltooltipOpen[userPhone] || false}
                                          target={`tool-videocall-${conversation?.members?.find((user: any) => user._id !== loggedUser?._id)?._id}`}
                                          placement="right"
                                        >
                                          Video Call
                                        </Tooltip>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Link>
                            </li>
                          );
                        })}

                        {/* {hasMoreData && (
                        <div className="d-flex align-items-center justify-content-end me-4 mb-4">
                          <button
                            className={isPaginatingChatList ? "btn btn-secondary" : "btn btn-warning"}
                            onClick={() => {
                              if (!isGettingChatListInitial && !isPaginatingChatList) {
                                getAlluserChats(false, false);
                              } else {
                                toast.error("Please wait", {
                                  position: toast.POSITION.BOTTOM_RIGHT,
                                  className: "foo-bar",
                                });
                              }
                            }}
                          >
                            {isPaginatingChatList ? "Loading..." : "See More"}
                          </button>
                        </div>
                      )} */}
                      </div>
                    ) : (
                      <div className="no-one-available">No one is available to chat.</div>
                    )
                  ) : (
                    <div className="group-chat-empty-view">
                      <Spinner />
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </ul>
          </div>
        </TabPane>

        <TabPane tabId="2">
          <div></div>
        </TabPane>
      </TabContent>
    </>
  );

  const messageDisplayComponent = () => (
    <div className={screenSize > SCREEN_SIZE ? "w-100 user-chat" : /* "no-panel" */ ""}>
      {addNewSessionPopUp && (
        <AddNewSessionModal addNewSessionPopUp={addNewSessionPopUp} userId={userId} userName={chatBoxUsername} setAddNewSessionPopUp={setAddNewSessionPopUp} />
      )}
      <Card>
        <div className="p-4 border-bottom ">
          <Row>
            <Col md="8.5" xs="6" style={{ paddingRight: "0px" }}>
              <h5 className="font-size-15 mb-1">{chatBoxUsername}</h5>

              <p className="text-muted mb-0">
                <i
                  className={
                    chatBoxUserStatus === "online"
                      ? "mdi mdi-circle text-success align-middle me-1"
                      : chatBoxUserStatus === "intermediate"
                        ? "mdi mdi-circle text-warning align-middle me-1"
                        : "mdi mdi-circle align-middle me-1"
                  }
                />
                {chatBoxUserStatus}
              </p>
            </Col>
            <Col md="3.5" xs="6">
              <div className="d-flex justify-content-end align-items-start chat-btn">
                {/* {loggedUser?.role == "THERAPIST" && (
                  <div className="btn  nav-btn user-note" onClick={testVonageCall} title="Take Test Call">
                    <i className="fa fa-phone"></i>
                  </div>
                )} */}
                {loggedUser?.role == "THERAPIST" && (
                  <div className="btn  nav-btn user-note" onClick={clickShowAddNewSessionPopUp} title="Add New Session">
                    <i className="fa fa-plus"></i>
                  </div>
                )}
                {loggedUser?.role == "THERAPIST" && (
                  <div className="btn  nav-btn user-note" onClick={toggleTreatmentCanvas} title="Patient Session Notes">
                    <i className="far fa-sticky-note"></i>
                  </div>
                )}
                {/* {(loggedUser?.role == "THERAPIST" && isMobile) && (
                  <div className="btn  nav-btn user-note" onClick={clinicalAssessment} title="Clinical Assessment">
                    <i className="far fa-file-alt"></i>
                  </div>
                )}
                {(loggedUser?.role == "THERAPIST" && !isMobile) && (
                  <Dropdown isOpen={dropdownAOpen} toggle={toggleDropdownA} className="nav-btn bg-hover-white">
                    <DropdownToggle
                      className="btn nav-btn user-note"
                      caret
                      tag="div"
                      title="Clinical Assessment"
                    >
                      <i className="far fa-file-alt"></i>
                    </DropdownToggle>
                    <DropdownMenu className="bg-hover-white">
                      <DropdownItem onClick={clinicalAssessment}><i className="fa fa-file"></i> &nbsp;Open Clinical Assessment</DropdownItem>
                      <DropdownItem onClick={generateClinicalPdf}><i className="fa fa-download"></i> &nbsp;Download Clinical Assessment</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )} */}

                {loggedUser?.role == "THERAPIST" && (
                  <div
                    className="btn nav-btn user-note"
                    onClick={() => {
                      handleClinicalAssessmentView(userId);
                    }}
                    title="View Clinical Assessment"
                  >
                    <i className="fas fa-file-alt"></i>
                  </div>
                )}

                {loggedUser?.role == "THERAPIST" && (
                  <div
                    className="btn nav-btn user-note"
                    onClick={() => {
                      handleTherapyPlansView(userId);
                    }}
                    title="View Therapy Plans"
                  >
                    <i className="fas fa-user-md"></i>
                  </div>
                )}

                {loggedUser?.role === "THERAPIST" && availableFormTypes.length > 0 && isMobile && (
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="nav-btn bg-hover-white" title="Prior Authorization Request Form">
                    <DropdownToggle className="btn nav-btn user-note" caret tag="div">
                      <i className="fas fa-stethoscope"></i>
                    </DropdownToggle>
                    <DropdownMenu className="bg-hover-white">
                      {availableFormTypes.map(({ type, id, isPrimary }) => {
                        const options = formOptions[type as keyof typeof formOptions];
                        if (!options) return null;

                        const insuranceLabel = isPrimary ? " (Primary Insurance)" : " (Secondary Insurance)";
                        const viewLabel = `Open ${options.baseLabel}${insuranceLabel}`;

                        return (
                          <DropdownItem key={id} title={viewLabel} onClick={() => options.viewHandler(id)}>
                            <i className="fa fa-file"></i> &nbsp;{viewLabel}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                )}

                {loggedUser?.role === "THERAPIST" && availableFormTypes.length > 0 && !isMobile && (
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="nav-btn bg-hover-white" title="Prior Authorization Request Form">
                    <DropdownToggle className="btn nav-btn user-note" caret tag="div">
                      <i className="fas fa-stethoscope"></i>
                    </DropdownToggle>
                    <DropdownMenu className="bg-hover-white">
                      {availableFormTypes.map(({ type, id, isPrimary }) => {
                        const options = formOptions[type as keyof typeof formOptions];
                        if (!options) return null;

                        const insuranceLabel = isPrimary ? " (Primary Insurance)" : " (Secondary Insurance)";
                        const viewLabel = `Open ${options.baseLabel}${insuranceLabel}`;
                        const downloadLabel = `Download ${options.baseLabel}${insuranceLabel}`;

                        return (
                          <React.Fragment key={id}>
                            <DropdownItem title={viewLabel} onClick={() => options.viewHandler(id)}>
                              <i className="fa fa-file"></i> &nbsp;{viewLabel}
                            </DropdownItem>
                            <DropdownItem title={downloadLabel} onClick={() => options.downloadHandler(id, type)}>
                              <i className="fa fa-download"></i> &nbsp;{downloadLabel}
                            </DropdownItem>
                          </React.Fragment>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                )}

                {/* {(loggedUser?.role == "THERAPIST" && isMobile) && (
                  <div className="btn  nav-btn user-note" onClick={showNewClinicalAssessment} title="Add New Session">
                    <i className="fa fa-plus"></i>
                  </div>
                )}

                {(loggedUser?.role == "THERAPIST" && !isMobile) && (
                  <Dropdown isOpen={dropdownCOpen} toggle={toggleDropdownC} className="nav-btn bg-hover-white">
                    <DropdownToggle
                      className="btn nav-btn user-note"
                      caret
                      tag="div"
                      title="Clinical Assessment"
                    >
                      <i className="fa fa-plus"></i>
                    </DropdownToggle>
                    <DropdownMenu className="bg-hover-white">
                      <DropdownItem onClick={showNewClinicalAssessment}><i className="fa fa-file"></i> &nbsp;Open Clinical Assessment</DropdownItem>
                      <DropdownItem onClick={generateNewClinicalPdf}><i className="fa fa-download"></i> &nbsp;Download Clinical Assessment</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )} */}

                <li className="list-inline-item" style={{ marginRight: "0", paddingRight: "0" }}>
                  <Dropdown isOpen={settings_Menu2} toggle={toggleSettings2}>
                    <DropdownToggle className="btn nav-btn" tag="i">
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu className="bg-hover-white">
                      <DropdownItem onClick={navigateToProfile}>
                        <i className="fa fa-eye mr-1"></i> View Profile
                      </DropdownItem>

                      {loggedUser?.role == "CLIENT" && (
                        <DropdownItem onClick={toggleTreatmentCanvas}>
                          <i className="far fa-sticky-note"></i> &nbsp;My Sessions
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={toggleHGCanvas}>
                        <i className="fas fa-tasks"></i> &nbsp;Homework & Goals
                      </DropdownItem>
                      {/* <DropdownItem
                      onClick={() => {
                        deleteConversationBetweenTwoUsers(chattingWith);
                      }}
                    >
                      <i className="bx bx-trash"></i> &nbsp;Delete Conversation
                    </DropdownItem> */}
                      {loggedUser?.role == "THERAPIST" && (
                        <DropdownItem
                          onClick={() => {
                            setShowAppointmentModal(!showAppointmentModal);
                          }}
                          className="bold-font-hover"
                        >
                          <i className="fa fa-calendar"></i> &nbsp;Schedule Appointment
                        </DropdownItem>
                      )}

                      {loggedUser?.role == "THERAPIST" && (
                        <DropdownItem onClick={toggleUDCanvas}>
                          <i className="fa fa-file"></i> &nbsp;Upload Document
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </div>
            </Col>
          </Row>
        </div>
        {!isLoadingConversationList && (
          <div>
            <div className="chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  id="scrollableDiv"
                  style={{
                    height: 470,
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                  ref={(ref: any) => setMessageBox(ref)}
                >
                  <InfiniteScroll
                    hasMore={true}
                    next={() => /* fetchPreviousMessages(messageRequest) */ {
                      getMessagesByChatId(chatId, messagesOffsetPageNo + 1);
                      setMessagesOffsetPageNo(messagesOffsetPageNo + 1);
                    }}
                    inverse={true}
                    loader={null}
                    dataLength={messageList.length}
                    scrollableTarget="scrollableDiv"
                  >
                    {messageList.length > 0 &&
                      messageList.map((message: any, index: number) => (
                        <DisplayMessageComp
                          currentUser={currentRecipientUser!}
                          message={message}
                          key={index}
                          openReplyPanel={openReplyPanel}
                          handleCopy={handleCopy}
                          deleteMessage={deleteMessage}
                        />
                      ))}
                  </InfiniteScroll>
                </div>
              </ul>
            </div>

            {showEmojiPanel && (
              <div className="emoji-panel1 position-absolute" style={{ zIndex: 9999 }}>
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            )}

            {showReplyPanel && (
              <>
                <div className="reply-container">
                  <ReplyMessageDisplayComp
                    selectedMessage={selectedMessage}
                    setSelectedMessage={setSelectedMessage}
                    setShowReplyPanel={setShowReplyPanel}
                    showReplyPanel={showReplyPanel}
                  />
                </div>
              </>
            )}

            {uploadedFile.preview && fileType == "IMAGE" ? (
              <>
                <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                  <div className="upload-area">
                    <div className="chat-image-remove-button">
                      <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                    </div>
                    <div className="chat-upload-preview-container">
                      <div
                        className="chat-upload-preview imageFit pr-0 form-control"
                        style={{
                          backgroundImage: uploadedFile.preview == null || uploadedFile.preview == undefined ? "" : `url(${uploadedFile.preview})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            ) : uploadedFile.preview == "" && fileType == "FILE" ? (
              <>
                <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                  <div className="upload-area">
                    <div className="chat-image-remove-button">
                      <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                    </div>
                    <div className="chat-upload-preview-container">
                      <i className="icon-copy fa fa-file iconFiles iconEdit p-2" style={{ fontSize: "70px" }}></i>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="p-3 chat-input-section">
              <Row>
                <Col className="col-auto">
                  <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                    <div
                      className="mdi mdi-emoticon-happy-outline pointer1"
                      id="Imagetooltip2"
                      onClick={() => {
                        openEmojiPanel();
                      }}
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      <UncontrolledTooltip placement="bottom" target="Imagetooltip2">
                        Pick an Emoji
                      </UncontrolledTooltip>
                    </div>
                  </li>
                  <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                    <div
                      className="mdi mdi-file-image-outline pointer1"
                      id="Imagetooltip"
                      onClick={onIconClick}
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      <input type="file" id="image-input" style={{ display: "none" }} onChange={onFileChange} />
                      <UncontrolledTooltip placement="bottom" target="Imagetooltip">
                        Attach a file
                      </UncontrolledTooltip>
                    </div>
                  </li>
                </Col>

                <Col className="col" style={{ padding: "0px" }}>
                  <div className="">
                    <div className="chat-input-editor">
                      <CKEditor
                        editor={Editor}
                        onReady={(editor: any) => {
                          editorRef.current = editor;
                          editor.focus();
                        }}
                        data={""}
                        config={editorConfiguration}
                      />
                    </div>
                  </div>
                </Col>

                {fileType == "IMAGE" || fileType == "FILE" ? (
                  <Col className="col-auto m-auto">
                    <Button
                      type="button"
                      color="primary"
                      disabled={isSending}
                      onClick={() => addMessage()}
                      className="btn btn-primary btn-rounded chat-send w-md "
                    >
                      <span className="d-none d-sm-inline-block me-2">Send</span>
                      <i className="mdi mdi-send" />
                    </Button>
                    &nbsp;&nbsp;
                    {/* {loggedUser?.role == Role.THERAPIST && (
                      <Button
                        type="button"
                        color="info"
                        onClick={() => {
                          joinZoomCall();
                        }}
                        className="btn btn-primary btn-rounded chat-send w-md "
                      >
                        <span className="d-none d-sm-inline-block me-2">Start Video Call</span>
                        <i className="mdi mdi-video"></i>
                      </Button>
                    )} */}
                  </Col>
                ) : (
                  <Col className="col-auto">
                    <Button
                      type="button"
                      disabled={isSending}
                      color="primary"
                      onClick={() => addMessage()}
                      className="btn btn-primary btn-rounded chat-send w-md "
                    >
                      <span className="d-none d-sm-inline-block me-2">Send</span>
                      <i className="mdi mdi-send" />
                    </Button>
                    &nbsp;&nbsp;
                    {/* {loggedUser?.role == Role.THERAPIST && (
                      <Button
                        type="button"
                        color="info"
                        onClick={() => {
                          joinZoomCall();
                        }}
                        className="btn btn-primary btn-rounded chat-send w-md "
                      >
                        <span className="d-none d-sm-inline-block me-2">Start Video Call</span>
                        <i className="mdi mdi-video"></i>
                      </Button>
                    )} */}
                  </Col>
                )}
              </Row>
              {isRecipientTyping?.isTyping && isRecipientTyping?.chatId == currentChat?._id ? <p>{currentRecipientUser?.firstname} typing...</p> : null}
            </div>
          </div>
        )}
        {isLoadingConversationList && (
          <div>
            <div className="chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  style={{
                    height: 470,
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                >
                  <div className="group-chat-empty-view">
                    <Spinner />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        )}

        <Offcanvas isOpen={isTreatmentOpen} direction="end" toggle={toggleTreatmentCanvas} className="discover-canvas">
          {!isTranscribeOpen && <OffcanvasHeader toggle={toggleTreatmentCanvas}>Session Notes</OffcanvasHeader>}
          {isTranscribeOpen && transcribe?.meetingStartedTime && transcribe?.createdAt && (
            <OffcanvasHeader toggle={closeTranscribe}>
              Transcribe for meeting from {moment(transcribe?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")} to
              {" " + moment(transcribe?.createdAt || null).format("YYYY-MM-DD h:mm:ss a")}
            </OffcanvasHeader>
          )}
          {isTranscribeOpen && !transcribe?.meetingStartedTime && !transcribe?.createdAt && (
            <OffcanvasHeader toggle={closeTranscribe}>No Transcribe found</OffcanvasHeader>
          )}

          <div>
            <div className="row  pt20 pl10 chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  className="scrollbar-container  ps--active-y"
                  style={{
                    height: "72vh",
                    overflowY: "auto",
                  }}
                >
                  {isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}
                  {isTranscribeLoading && !isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {/* {isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && <h1>Loading ...</h1>} */}
                  {!isTreatmentLoading && isTreatmentError && !isTranscribeOpen && (
                    <div style={{ background: "red", textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}
                  {/* {isTranscribeLoading && !isTranscribeError && isTranscribeOpen && <h1>Loading ...</h1>} */}
                  {!isTranscribeLoading && isTranscribeError && isTranscribeOpen && (
                    <div style={{ background: "red", textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}
                  {!isTranscribeLoading && !isTranscribeError && isTranscribeOpen && !transcribe?.transCribeInProcess && (
                    <div className="search-box chat-search-box chat-sec1">
                      <div className="position-relative">
                        <Input
                          onChange={event => searchTranscribe(event)}
                          //onKeyUp={searchUsers}
                          id="search-transcribe-text"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchTextForTranscribe}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  )}
                  {!isTranscribeLoading &&
                    !isTranscribeError &&
                    isTranscribeOpen &&
                    !transcribe?.transCribeInProcess &&
                    transcribeTextList.map((trans: any, index) => {
                      return (
                        <Card key={index} className="cursor_pointer mb-1">
                          <CardBody>
                            <h5 className="font-size-13 mb-0">{trans}</h5>
                          </CardBody>
                        </Card>
                      );
                    })}
                  {!isTranscribeLoading && !isTranscribeError && isTranscribeOpen && transcribe?.transCribeInProcess && (
                    <h5 className="font-size-13 mb-1">Transcribing on process please try again later</h5>
                  )}
                  {!isTreatmentLoading &&
                    !isTreatmentError &&
                    !isTranscribeOpen &&
                    treatmentHistoryDetails?.map((tr: any, index) => {
                      const pattern = new RegExp(
                        "^(https?:\\/\\/)?" +
                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                        "((\\d{1,3}\\.){3}\\d{1,3}))" +
                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                        "(\\?[;&a-z\\d%_.~+=-]*)?" +
                        "(\\#[-a-z\\d_]*)?$",
                        "i"
                      );
                      return (
                        <Card
                          key={index}
                          onClick={() => {
                            if (!tr.isMeetingTranscribe) {
                              togCenter(tr?._id);
                            }
                          }}
                          className="cursor_pointer"
                        >
                          {!tr.isMeetingTranscribe && (
                            <CardBody>
                              <Row>
                                <Col xl={6}>
                                  <h5 className="font-size-15 mb-1">
                                    {tr?.therapistId?.firstname} {tr?.therapistId?.lastname}
                                  </h5>
                                </Col>
                                <Col xl={6}>
                                  <p
                                    className="text-muted fw-medium "
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    {moment(tr?.createdAt || null).format("YYYY-MM-DD")}
                                  </p>
                                </Col>
                              </Row>
                              {tr.isMeetingTranscribe && (
                                <a
                                  onClick={() => {
                                    // if (tr.isMeetingTranscribe) {
                                    //   showTranscribe(tr?.transcribeId);
                                    // }
                                  }}
                                  className="btn btn-secondary"
                                >
                                  Show Video Call Transcribe
                                </a>
                              )}
                              {!tr.isMeetingTranscribe && <span className="text-muted fw-medium ">{tr?.note}</span>}
                            </CardBody>
                          )}
                          {tr.isMeetingTranscribe && (
                            <CardBody>
                              <h5 className="font-size-15 mb-1">
                                Transcribe for Meeting Started at {moment(tr?.meetingStartedTime || null).format("MM/DD/YYYY hh:mm A")}
                              </h5>
                              {tr.isMeetingTranscribe && (
                                <a onClick={() => { }} className="btn btn-secondary">
                                  Show Video Call Transcribe
                                </a>
                              )}
                            </CardBody>
                          )}
                        </Card>
                      );
                    })}
                  {!isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && (
                    <button
                      type="button"
                      className="btn btn-primary  relative mt-2 mb-2"
                      style={{
                        bottom: "0",
                        float: "left",
                        background: isTreatmentLazyLoading ? "grey" : "",
                        border: "none",
                      }}
                      onClick={() => {
                        if (!isTreatmentLazyLoading) {
                          loadMoreTreatmentHistoryNotes(chattingWith);
                        }
                      }}
                    >
                      {isTreatmentLazyLoading ? "Loading .." : "Show More"}
                    </button>
                  )}
                </div>
              </ul>
            </div>
          </div>
        </Offcanvas>

        {showModal && <MessageModal message="Please subscribe to a provided plan to access this feature." setShowModal={setShowModal} showModal={showModal} />}
        {isHomeWorkOpen && <HomeWorksAndGoals userId={userId} isOpened={isHomeWorkOpen} returnBool={(e: boolean) => setIsHomeWorkOpen(e)} />}
        {isUploadDocumentsOpen && <UploadDocuments userId={userId} isOpened={isUploadDocumentsOpen} returnBool={(e: boolean) => setIsUploadDocumentsOpen(e)} />}
      </Card>
    </div>
  );

  return (
    <React.Fragment>
      {showNewClinicalAssessmentModal && (
        <NewClinicalAssessmentModal
          showModal={showNewClinicalAssessmentModal}
          identifierId={userId}
          setShowModal={setShowNewClinicalAssessmentModal}
          isFromMeeting={false}
          isAdmin={false}
          setModalRefresher={refreshModal32}
          aiGeneratedAssessmentData={null}
          isMeeting={false}
        />
      )}
      {showNewClinicalAssessmentPdfModal && (
        <NewClinicalAssessmentPdfModal
          setShowModal={setShowNewClinicalAssessmentPdfModal}
          showModal={showNewClinicalAssessmentPdfModal}
          identifierId={clinicalAssessmentId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          digitalAssessmentType={clinicalAssessmentType as TPType}
        />
      )}
      {showAiGeneratedAssessmentPopup && (
        <AIGeneratedAssessment
          OpenAiModel={showAiGeneratedAssessmentPopup}
          transcribeData={firstMeetingData}
          closeModelFunction={handleChildFunction}
          refreshModal32={refreshModal32}
          isInMeeting={false}
          clientId={userId}
        />
      )}
      {showOngoingCallRefreshModal && <OngoingCallRefreshModal showModal={showOngoingCallRefreshModal} setShowModal={setShowOngoingCallRefreshModalFunc} />}
      {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
      {/* {showTimeSelectModal && <Modal6 setShowModal={startInstantMeetingInitial} showModal={showTimeSelectModal} />} */}
      {showTimeSelectModal && <Modal6ForChatComp setShowModal={startInstantMeetingInitial} showModal={showTimeSelectModal} />}
      {showSessionHistoryModal && <SessionHistoryModal setShowModal={setShowSessionHistoryModal} showModal={showSessionHistoryModal} userId={userId} />}
      {showClinicalAssessmentModal && (
        <ClinicalAssetmentModal
          setShowModal={setShowClinicalAssessmentModal}
          showModal={showClinicalAssessmentModal}
          identifierId={userId}
          isAdmin={false}
          isFromMeeting={false}
          setModalRefresher={refreshModal32}
        />
      )}
      {showClinicalAssessmentPdfModal && (
        <ClinicalAssessmentPdfModal
          setShowModal={setShowClinicalAssessmentPdfModal}
          showModal={showClinicalAssessmentPdfModal}
          identifierId={clinicalAssessmentId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          clinicalAssessmentType={clinicalAssessmentType as TPType}
        />
      )}
      {showTherapyPlanPdfModal && (
        <TherapyPlanPdfModal
          setShowModal={setShowTherapyPlanPdfModal}
          showModal={showTherapyPlanPdfModal}
          identifierId={therapyPlanId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          therapyPlanType={therapyPlanType as TPType}
        />
      )}
      {showTherapyPlanModal && (
        <TherapyPlanModal
          setShowModal={setShowTherapyPlanModal}
          showModal={showTherapyPlanModal}
          identifierId={therapyPlanId}
          isAdmin={false}
          isFromMeeting={false}
          isCreatingNewForm={isCreatingNewForm}
          setModalRefresher={refreshModal31}
        />
      )}
      {showAmeriHealthModal && (
        <AmeriHealthModal
          setShowModal={setshowAmeriHealthModal}
          showModal={showAmeriHealthModal}
          identifierId={userId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showCarolinaCompleteHealthModal && (
        <CarolinaCompleteHealthModal
          setShowModal={setshowCarolinaCompleteHealthModal}
          showModal={showCarolinaCompleteHealthModal}
          identifierId={userId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showUnitedHealthCareModal && (
        <UnitedHealthCareModal
          setShowModal={setshowUnitedHealthCareModal}
          showModal={showUnitedHealthCareModal}
          identifierId={userId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showHealthBlueModal && (
        <HealthBlueModal
          setShowModal={setshowHealthBlueModal}
          showModal={showHealthBlueModal}
          identifierId={userId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showWellCareModal && (
        <WellCareModal
          setShowModal={setshowWellCareModal}
          showModal={showWellCareModal}
          identifierId={userId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showAmbetterModal && (
        <AmbetterModal
          setShowModal={setshowAmbetterModal}
          showModal={showAmbetterModal}
          identifierId={userId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showAuthorizationFormPdfModal && (
        <AuthorizationFormPdfModal
          setShowModal={setShowAuthorizationFormPdfModal}
          showModal={showAuthorizationFormPdfModal}
          identifierId={userId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          authorizationFormType={authorizationFormType as AuthFormType}
          insuranceCompanyId={insuranceCompanyId}
        />
      )}
      {showTherapyPlanWithVersionModal && (
        <Modal31
          setShowModal={setShowTherapyPlanWithVersionModal}
          showModal={showTherapyPlanWithVersionModal}
          therapyPlanInfo={therapyPlanInfo}
          createNewTherapyPlan={createNewTherapyPlan}
          openTherapyPlan={therapyPlan}
          downloadTherapyPlan={generateTherapyPlanPdf}
          isMobile={isMobile}
          isAdmin={false}
        />
      )}
      {showClinicalAssessmentWithVersionModal && (
        <Modal32
          setShowModal={setShowClinicalAssessmentWithVersionModal}
          showModal={showClinicalAssessmentWithVersionModal}
          clinicalAssessmentInfo={clinicalAssessmentInfo}
          openClinicalAssessment={clinicalAssessment}
          downloadClinicalAssessment={generateClinicalPdf}
          isMobile={isMobile}
          isAdmin={false}
          generateAiAssessmentFunction={generateAiAssessmentFunction}
          firstMeetingData={firstMeetingData}
          isMeeting={false}
        />
      )}
      {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
      {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}
      {showImcompleteCoPayments && (
        <Modal29
          setShowModal={setShowIncompleteCopayments}
          showModal={showImcompleteCoPayments}
          copaymentAmount={coPaymentAmount}
          clientId={chattingWith}
          setCallModal={setJoinCall}
        />
      )}
      {showWordCountModal && (
        <MessageModal
          message="You have exceeded the word limit given to free plan users. Please subscribe to a provided plan to continue chatting with your counselor."
          setShowModal={setShowWordCountModal}
          showModal={showWordCountModal}
        />
      )}

      {showErrorModal && (
        <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
      )}

      <ErrorModal errorModalText={errorModalText} isEventTimeValidationViewModal={false} eventTimeValidationModalToggle={eventTimeValidationModalToggle} />

      <AppointmentModal
        clientId={userId}
        appointment={appointment}
        setAppointment={setAppointment}
        modalType={ModalType.CREATE}
        setShowModal={setShowAppointmentModal}
        showModal={showAppointmentModal}
        errorModalText={errorModalText}
        setErrorModalText={setErrorModalText}
        setShowErrorModal={setShowErrorModal}
        setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
        setShowModalForTherapist={setShowModalForTherapist}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        updateMatchedClient={() => { }}
        hourValue={hourValue}
        setHourValue={setHourValue}
        minuteValue={minuteValue}
        setMinuteValue={setMinuteValue}
        updateAppointmentObject={() => { }}
      />
      {modalTwilioCallOpen && (
        <TwilioCall isOpen={modalTwilioCallOpen} toggle={toggleModal} phoneNumber={clientPhoneNumber} recieverId={userId} recieverName={chatBoxUsername} />
      )}

      <>
        {screenSize < SCREEN_SIZE && location.pathname !== "/chat" && (
          <div className="flex flex-wrap  cursor-pointer pb-2" onClick={() => back()}>
            <i className="mt-1 fas fa-regular fa-arrow-left "></i>
            <CardTitle className="cursor_pointer">&nbsp; Back</CardTitle>
          </div>
        )}

        <Row>
          <Col lg="12">
            <div className="d-lg-flex">
              <div className="chat-leftsidebar me-lg-4">
                <div className="chat-leftsidebar-nav">
                  {(screenSize <= SCREEN_SIZE && shouldDisplay) || screenSize > SCREEN_SIZE ? (
                    leftNavBarComponent()
                  ) : (
                    <>{(userId !== undefined || userId !== null || userId !== "") && messageDisplayComponent()}</>
                  )}
                </div>
              </div>

              {chatId !== undefined && screenSize > SCREEN_SIZE ? (
                messageDisplayComponent()
              ) : onlineUsers.length == 0 ? (
                <div className={screenSize > SCREEN_SIZE ? "bg-white chat-body1" : "no-panel"}>
                  <div className="d-flex justify-content-center h-100 align-items-center" style={{ fontSize: "18px", padding: "10px" }}>
                    <p>No one is available to chat.</p>
                  </div>
                </div>
              ) : (
                <div className={screenSize > SCREEN_SIZE ? "bg-white chat-body1" : "no-panel"}>
                  <div className="card-height1">
                    <div className="d-flex justify-content-center h-100 align-items-center" style={{ fontSize: "18px", padding: "10px" }}>
                      <p>Select someone to chat with.</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </>
    </React.Fragment>
  );
};

Chat2.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

export default Chat2;
