import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import LogoIcon from "../../../assets/images/chat/Intersect.png";
import LogoIconSm from "../../../assets/images/chat/Intersect2 (1).svg";
import { PublicGroupChatModel } from "src/models/PublicGroupChat/PublicGroupChat";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
import images from "src/assets/images";
import Spinner from "src/common/spinner/spinner";
import Swal from "sweetalert2";
import { Util } from "src/Util";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import { NotificationService } from "src/services/NotificationService";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import { SocketContext } from "src/context/ScoketContext";
import UserContext from "src/context/UserContext";

const PublicGroupChat: React.FC = () => {
  const socket = useContext(SocketContext);
  const [user, setUser] = useContext(UserContext);
  const [publicGroupList, setPublicGroupList] = useState([] as PublicGroupChatModel[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestingId, setRequestingId] = useState("");
  const [noMoreData, setNoMoreData] = useState(false);
  const limit = 10;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    initialLoading();
  }, []);

  const initialLoading = async () => {
    console.log("Loading public chat groups");
    setIsLoading(true);
    await getAllMembers();
    setIsLoading(false);
  };

  const getAllMembers = async () => {
    try {
      const dataObject = {
        limit: limit,
        offset: offset * limit,
      };
      console.log(offset);
      const res = await GroupChatService.getAllPublicChatGroupsForClient(dataObject);
      if (res.success && res.data) {
        const newGroups: PublicGroupChatModel[] = res.data;
        if (newGroups != null && newGroups.length != null) {
          if (newGroups.length < limit) {
            setNoMoreData(true);
          }
          const previousGroups: PublicGroupChatModel[] = publicGroupList;
          const finalGroups: PublicGroupChatModel[] = [...previousGroups, ...newGroups];
          setPublicGroupList(finalGroups);
          setOffset(offset + 1);
        }
      } else {
        setIsLoadingError(true);
        toast.error("Retrieving peer support groups failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsLoadingError(true);
      toast.error("Retrieving peer support groups failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  const joinGroup = async (groupId: string, group: PublicGroupChatModel) => {
    try {
      if (!isRequesting) {
        setRequestingId(groupId);
        setIsRequesting(true);
        const data = {
          groupId: groupId,
        };
        const res = await GroupChatService.sendJoinRequestForPublicGroup(data);
        if (res && res.success) {
          try {
            const grpChatventNotification: NotificationModel = {
              senderId: user?._id,
              receiverId: group.createdBy,
              event: NotificationEvent.GRP_CHAT_JOIN_REQUEST_SEND,
              link: `/group-chat/${groupId}`,
              content: `New join request to ${group.title ?? ""} peer support chat group recieved from ${user?.firstname ?? "user"}.`,
              variant: NotificationVarient.INFO,
            };

            NotificationService.sendNotification(grpChatventNotification).then(res => {
              if (res && res.success && res.data && res.data.socketId) {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: grpChatventNotification,
                  receiverId: group.createdBy,
                  senderId: user?._id,
                };

                socket.emit("send-notification", socketData);
              }
            });
          } catch (error) {}

          setIsRequesting(false);

          const finalArray: PublicGroupChatModel[] = publicGroupList.map(group => {
            if (group._id == groupId) {
              return { ...group, requestStatus: "PENDING" };
            }
            return group;
          });
          setPublicGroupList(finalArray);

          toast.success("Request send successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setIsRequesting(false);
          toast.error("Failed to send request!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsRequesting(false);
      } else {
        toast.error("Please wait, action in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsRequesting(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  const cancellRequest = async (groupId: string, group: PublicGroupChatModel) => {
    try {
      if (!isRequesting) {
        setRequestingId(groupId);
        setIsRequesting(true);
        const data = {
          groupId: groupId,
        };
        const res = await GroupChatService.cancellJoinRequestForPublicGroup(data);
        if (res && res.success) {
          try {
            const grpChatventNotification: NotificationModel = {
              senderId: user?._id,
              receiverId: group.createdBy,
              event: NotificationEvent.GRP_CHAT_JOIN_REQUEST_CANCELED,
              link: `/group-chat/${groupId}`,
              content: `Join request to ${group.title ?? ""} peer support chat group canceled by ${user?.firstname ?? "user"}.`,
              variant: NotificationVarient.INFO,
            };

            NotificationService.sendNotification(grpChatventNotification).then(res => {
              if (res && res.success && res.data && res.data.socketId) {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: grpChatventNotification,
                  receiverId: group.createdBy,
                  senderId: user?._id,
                };

                socket.emit("send-notification", socketData);
              }
            });
          } catch (error) {}

          setIsRequesting(false);

          const finalArray: PublicGroupChatModel[] = publicGroupList.map(group => {
            if (group._id == groupId) {
              return { ...group, requestStatus: "CANCELLED" };
            }
            return group;
          });
          setPublicGroupList(finalArray);

          toast.success("Request cancelled successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setIsRequesting(false);
          toast.error("Failed to cancel request!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsRequesting(false);
      } else {
        toast.error("Please wait, action in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsRequesting(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <title>Public Chat | Lavni</title>
        <div className="d-flex justify-content-center align-items-center">
          <div className="custom-fluid-container">
            <Breadcrumbs title="Lavni" breadcrumbItem="Public Chat" />

            <Row className="mt-3">
              {isLoading && (publicGroupList == null || publicGroupList.length == null || publicGroupList.length <= 0) ? (
                <div className="group-chat-empty-view" style={{ width: "100vw", height: "50vh" }}>
                  <Spinner />
                </div>
              ) : !isLoading && isLoadingError && (publicGroupList == null || publicGroupList.length == null || publicGroupList.length <= 0) ? (
                <div className="group-chat-empty-view" style={{ width: "100vw", height: "50vh" }}>
                  <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                  <div className="mt-1">Error getting data</div>
                </div>
              ) : publicGroupList != null && publicGroupList.length != null && publicGroupList.length > 0 ? (
                publicGroupList?.map((group, index: number) => (
                  <Col key={index} lg={6} className="mb-6">
                    <div className="public-group-chat-card d-none d-sm-block">
                      <div className="row public-chat-container">
                        <div className="col-sm-2 public-logo-bg d-flex justify-content-start align-items-center">
                          <img src={LogoIcon} className="pt-2 pb-2 group-chat-c" />
                        </div>
                        {group != null && group.groupIcon != null && group.groupIcon._id != null ? (
                          <div className="col-sm-2 d-flex justify-content-center align-items-center pt-3 pb-3">
                            <img src={Util.fileURL(group.groupIcon._id)} className="public-chat-logo-icon " />
                          </div>
                        ) : (
                          <div className="col-sm-2 d-flex justify-content-center align-items-center pt-3 pb-3">
                            <img src={images.defaultGroupIcon} className="public-chat-logo-icon " />
                          </div>
                        )}
                        <div className="col-sm-5 pt-3 pb-3 group-chat-card-body">
                          <p className="group-chat-title text-center text-sm-start pr-12-public-chat">{group.title ?? ""}</p>
                          <p className="group-chat-description  text-center text-sm-start pr-12-public-chat">{group.description ?? ""}</p>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-center public-chat-p public-chat-pl0 align-items-center pt-3 pb-3">
                          <div className="group-chat-btn-group">
                            <span className="d-flex justify-content-center align-items-center group-chat-member-btn me-3 me-sm-0 mb-sm-3 p-2">{`${
                              group.memberCount ?? ""
                            } ${group.memberCount != null && group.memberCount > 1 ? "Members" : "Member"}`}</span>
                            <button
                              className={
                                isRequesting && requestingId == group._id
                                  ? "btn btn-secondary group-chat-join-group-btn ms-3 ms-sm-0"
                                  : group.requestStatus == "PENDING"
                                  ? "btn btn-danger group-chat-join-group-btn ms-3 ms-sm-0"
                                  : "btn btn-primary group-chat-join-group-btn ms-3 ms-sm-0"
                              }
                              onClick={() => {
                                if (!isRequesting) {
                                  Swal.fire({
                                    icon: "warning",
                                    title: group.requestStatus == "PENDING" ? "Are you sure you want to cancel the request" : "Are you sure you want to join",
                                    showCancelButton: true,
                                    confirmButtonText: group.requestStatus == "PENDING" ? "Cancel Request" : "Join",
                                    confirmButtonColor: "#FD7F00",
                                    cancelButtonColor: "#FD7F00",
                                  }).then(result => {
                                    if (result.isConfirmed) {
                                      if (group.requestStatus == "PENDING") {
                                        cancellRequest(group._id, group);
                                      } else {
                                        joinGroup(group._id, group);
                                      }
                                    }
                                  });
                                } else {
                                  toast.error("Please wait, action in progress!", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    className: "foo-bar",
                                  });
                                }
                              }}
                            >
                              {group.requestStatus == "PENDING"
                                ? isRequesting && requestingId == group._id
                                  ? "Cancelling..."
                                  : "Cancel Request"
                                : isRequesting && requestingId == group._id
                                ? "Requesting..."
                                : "Join Group"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="public-group-chat-card d-sm-none d-block">
                      <div className="row public-chat-container">
                        <div className="col-3 public-logo-bg d-flex justify-content-start align-items-center">
                          <img src={LogoIcon} className="pt-2 pb-2 group-chat-c" />
                          <div className="public-group-chat-overlay"></div>
                          <div className="public-group-chat-text-overlay">
                            {group != null && group.groupIcon != null && group.groupIcon._id != null ? (
                              <div className="d-flex justify-content-center align-items-center pt-3 pb-3">
                                <img src={Util.fileURL(group.groupIcon._id)} className="public-chat-logo-icon " />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center align-items-center pt-3 pb-3">
                                <img src={images.defaultGroupIcon} className="public-chat-logo-icon " />
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-2 d-flex justify-content-center align-items-center pt-3 pb-3">
                                          <img src='https://lavni-api.efito.xyz/api/public/file/65c0669c455eabf9bdac97fe/null' className="public-chat-logo-icon " />
                                      </div> */}
                        <div className="col-9 pt-3 pb-3 group-chat-card-body">
                          <p className="group-chat-title pr-12-public-chat">{group.title ?? ""}</p>
                          <p className="group-chat-description pr-12-public-chat public-chat-mb0">{group.description ?? ""}</p>
                          <div className="d-flex justify-content-center public-chat-pl0 align-items-center pt-2">
                            <div className="group-chat-btn-group">
                              <span className="d-flex justify-content-center align-items-center group-chat-member-btn2 ps-3 pe-3">{`${
                                group.memberCount ?? ""
                              } ${group.memberCount != null && group.memberCount > 1 ? "Members" : "Members"}`}</span>
                              <button
                                className={
                                  isRequesting && requestingId == group._id
                                    ? "btn btn-secondary"
                                    : group.requestStatus == "PENDING"
                                    ? "btn btn-danger"
                                    : "btn btn-primary"
                                }
                                onClick={() => {
                                  if (!isRequesting) {
                                    Swal.fire({
                                      icon: "warning",
                                      title: group.requestStatus == "PENDING" ? "Are you sure you want to cancel the request" : "Are you sure you want to join",
                                      showCancelButton: true,
                                      confirmButtonText: group.requestStatus == "PENDING" ? "Cancel Request" : "Join",
                                      confirmButtonColor: "#FD7F00",
                                      cancelButtonColor: "#FD7F00",
                                    }).then(result => {
                                      if (result.isConfirmed) {
                                        if (group.requestStatus == "PENDING") {
                                          cancellRequest(group._id, group);
                                        } else {
                                          joinGroup(group._id, group);
                                        }
                                      }
                                    });
                                  } else {
                                    toast.error("Please wait, action in progress!", {
                                      position: toast.POSITION.BOTTOM_RIGHT,
                                      className: "foo-bar",
                                    });
                                  }
                                }}
                              >
                                {group.requestStatus == "PENDING"
                                  ? isRequesting && requestingId == group._id
                                    ? "Cancelling..."
                                    : "Cancel Request"
                                  : isRequesting && requestingId == group._id
                                  ? "Requesting..."
                                  : "Join Group"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="group-chat-empty-view" style={{ width: "100vw", height: "50vh" }}>
                  <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                  <div className="mt-1">No available groups</div>
                </div>
              )}
            </Row>
            {!noMoreData && publicGroupList != null && publicGroupList.length != null && publicGroupList.length > 0 && (
              <Col xl="12 mb-4 text-end">
                <button
                  className={isLoading ? "btn btn-secondary" : "btn btn-warning"}
                  onClick={() => {
                    initialLoading();
                  }}
                >
                  {isLoading ? "Loading..." : "See More"}
                </button>
              </Col>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicGroupChat;
