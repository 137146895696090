import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../../assets/css/admin.css";
import { CommonService } from "../../services/CommonService";
import { Ethinicity, EthinicityResponseModel } from "../../models/Ethnicity";
import { AdminService } from "../../services/AdminService";
import Swal from "sweetalert2";
import Spinner from "../../common/spinner/spinner";

const ViewEthnicity: React.FC = () => {
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const LIMIT = 10;
  const [offset, setOffset] = useState<number>(0);
  const [isCount, setCount] = useState<number>(0);

  useEffect(() => {
    getAllEthnicityTypes();
  }, []);

  const getAllEthnicityTypes = () => {
    setIsLoaded(true);
    AdminService.getEthinicityList(LIMIT, offset).then(res => {
      if (res.success) {
        setEthnicityList(res.data.set);
        setCount(res.data.count);
        setIsLoaded(false);
      }
    });
  };

  const deleteEthnicityType = (ethnicityId: string | undefined) => {
    if (ethnicityId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete the ethnicity type?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.deleteEthnicityType(ethnicityId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Ethnicity type deleted!",
                confirmButtonColor: "#FD7F00",
              });
              getAllEthnicityTypes();
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete ethnicity type!.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const updateEthnicity = (ethnicity: Ethinicity | undefined) => {
    if (ethnicity) {
      Swal.fire({
        title: "Update Ethnicity",
        input: "text",
        inputValue: ethnicity.ethnicity,
        inputAttributes: {
          autocapitalize: "off",
          placeholder: 'Update Ethnicity',
        },
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          if (result.value == "" || result.value == undefined || result.value == null) {
            Swal.fire({
              icon: "error",
              title: "Invalid value. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          } else {
            const data = {
              ethnicityId: ethnicity?._id,
              updatedEthnicityName: result.value,
            };
            AdminService.updateEthnicityType(data).then(res => {
              if (res.success) getAllEthnicityTypes();
              else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        }
      });
    }
  };

  const seeMoreFc = () => {
    setIsLoaded(true);
    const updatedOffset = offset + LIMIT;
    setOffset(updatedOffset);
    AdminService.getEthinicityList(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setEthnicityList(prev => {
          return [...new Set([...prev, ...res.data.set])];
        });
        setCount(res.data.count);
        setIsLoaded(false);
      }
    });
}


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"View Ethnicity"} breadcrumbItem={"View Ethnicity"} />
          
          {isLoaded && <Spinner className="bouncer" />}

          <Row>
            <Col xl={12}>
              <NavLink
                to={"/create-ethnicity"}
                className="btn btn-primary relative mb-3"
                style={{
                  bottom: "0",
                  float: "right",
                }}
              >
                Create Ethnicity
              </NavLink>
            </Col>
          </Row>          

          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  <div className="table-responsive">
                    {ethnicityList !== undefined ? (
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">ethnicity</th>
                            <th scope="col">Actions</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {ethnicityList &&
                            ethnicityList.map((ethnicity, index) => (
                              <tr key={ethnicity._id}>
                                <th scope="row">{index + 1}</th>
                                <td>{ethnicity.ethnicity}</td>
                                <td>
                                  <div className="d-flex p-0">
                                    <div className="">
                                      <button className="btn btn-sm btn-success" onClick={() => updateEthnicity(ethnicity)}>
                                        <i className="bx bx-edit"></i> Update
                                      </button>
                                    </div>
                                    <div className="px-2">
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => {
                                          deleteEthnicityType(ethnicity?._id);
                                        }}
                                      >
                                        <i className="bx bx-trash"></i> Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div>No Ethnicity Lists</div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12 mt30 text-center">
                  {
                    isCount > 0 && ethnicityList.length >= LIMIT && (
                        <>
                            <button className="btn btn-warning text-center mt-4" onClick={seeMoreFc}>See More</button>
                        </>
                    )
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewEthnicity;
