import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../../assets/css/admin.css";
import Swal from "sweetalert2";
import { CommonService } from "../../services/CommonService";
import { ExperienceTag } from "../../models/ExperienceTag";
import { toast } from "react-toastify";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";

const ViewExperienceTag: React.FC = () => {
  const [experienceTags, setExperienceTags] = useState<ExperienceTag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const LIMIT = 10;
  const [seeMore, setSeeMore] = useState(false);
  const [isOffset, setOffset] = useState<number>(0);

  useEffect(() => {
    getAllExperienceTags();
  }, []);

  const getAllExperienceTags = () => {
    setIsLoading(true);
    CommonService.getExperienceTags(LIMIT, isOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setExperienceTags(res.data);
        setIsLoading(false);
      } else {
        setExperienceTags([]);
        setIsLoading(false);
        setSeeMore(false);
      }
    });
  };

  const deleteExpTag = (id: string | any) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        CommonService.deleteExperienceTag(id).then(res => {
          if (res.success) {
            getAllExperienceTags();
            toast.success("Experience Tag is Deleted!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Somthing went wrong!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };

  const updateExpTag = (expTag: ExperienceTag | undefined) => {
    if (expTag) {
      Swal.fire({
        title: "Update Experience Tag",
        input: "text",
        inputValue: expTag.experienceTag,
        inputAttributes: {
          autocapitalize: "off",
          placeholder: 'Update Experience Tag',
        },
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          if (result.value == "" || result.value == undefined || result.value == null) {
            Swal.fire({
              icon: "error",
              title: "Invalid value. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          } else {
            const data = {
              expId: expTag._id,
              updatedExpTag: result.value,
            };
            AdminService.updateExperienceTags(data).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: res.message,
                  confirmButtonColor: "#FD7F00",
                });
                getAllExperienceTags();
              }
              else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        }
      });
    }
  };

  const seeMoreFc = () => {
    setIsLoading(true);
    const updatedOffset = isOffset + LIMIT;
    setOffset(updatedOffset);
    CommonService.getExperienceTags(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setExperienceTags(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setIsLoading(false);
      } else {
        setExperienceTags([]);
        setIsLoading(false);
        setSeeMore(false);
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"View Experience Tags"} breadcrumbItem={"View Experience Tags"} />
          
          <Row>
            <Col xl={12}>
              <NavLink
                to={"/create-experience-tags"}
                className="btn btn-primary relative mb-3"
                style={{
                  bottom: "0",
                  float: "right",
                }}
              >
                Create Experience Tag
              </NavLink>
            </Col>
          </Row>
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {!isLoading ? (
                    <div className="table-responsive">
                      {experienceTags !== undefined ? (
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Experience Tag</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {experienceTags &&
                              experienceTags.map((experienceTag, index) => (
                                <tr key={experienceTag._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{experienceTag.experienceTag}</td>
                                  <td>
                                    <button className="btn btn-success btn-sm mr-2" onClick={() => updateExpTag(experienceTag)}>
                                      <i className="bx bx-edit"></i> Update
                                    </button>
                                    <button className="btn btn-danger btn-sm mr-2" onClick={() => deleteExpTag(experienceTag._id)}>
                                      <i className="bx bx-trash"></i> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )
                        : (
                          <>
                            <div>No Experience Tags</div>
                          </>
                        )}
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </Col>
              </Row>
              {/* {experienceTags.length == 0 && <div className="text-muted text-center">No Experience Tags</div>} */}
              <Row>
              <Col xl="12 mt30 text-center">
                  {seeMore && (
                    <button className="btn btn-warning" onClick={() => seeMoreFc()}>
                      See More
                    </button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewExperienceTag;
