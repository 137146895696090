import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardBody, UncontrolledTooltip } from "reactstrap";
import UserContext from "../../../context/UserContext";
import { Role } from "../../../models/Role";
import { VideoChatService } from "../../../services/VideoChatService";
import { toast } from "react-toastify";
import AiIcon from "../../../assets/images/icons/AI_animation_logo.gif";
import AIGeneratedNote from "./AIGenratedNote";
import deleteIcon from "../../../assets/images/icons8-delete.svg";
import Swal from "sweetalert2";
import NoteShareTherapistModal from "src/pages/Popup/NoteShareTherapistModal";
import { TreatmentHistory } from "src/models/TreatmentHistory";
import { AINotesType } from "src/models/Therapist";
import NoteTypeSelectModal from "src/pages/Popup/NoteTypeSelectionModal";
import AIGeneratePieNoteNew from "./AIGeneratePieNoteNew";
import DiagnosisNoteVersionModal from "src/pages/Popup/DiagnosisNoteVersionModal";

interface TranscribeHistoryCardProps {
  transcribeData: any;
  isCreating: boolean;
  selectedTreatmentHistoryForMerge: any;
  setSelectedTreatmentHistoryForMerge: any;
  setSelectedClient: any;
  showDiagnosisFunction: (diagnosisNoteId: string, totalSpentDuration: number, noteTypeFrom: string, isVersion: boolean) => void;
  showAudioFunction: (audioId: string, path: string, showAudio: boolean) => void;
  //  showAudioFunction: (audioId: string, showAudio: boolean) => void;
  showTranscribeFunction: (transcribeId: string) => void;
  updateDiagnosisData: (isUpdate: boolean) => void;
  onShareWithSubTherapy: (value: TreatmentHistory) => void;
  saveNewNote: (transcribeData: any, noteType: string) => void;
}

const TranscribeHistoryCard: React.FC<TranscribeHistoryCardProps> = props => {
  const [user] = useContext(UserContext);
  const [transcribeInProcess, setTranscribeInProcess] = useState(false);
  const mounted = useRef(false);

  const [openShareModal, setOpenShareModal] = useState(false);
  const [showTypeSelectModal, setShowTypeSelectModal] = useState(false);
  const [showPIENote, setShowPIENote] = useState(false);
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);

  const [showTypeSelectModalForViewEdit, setShowTypeSelectModalForViewEdit] = useState(false);
  const [diagnosisNoteWithVersionsData, setDiagnosisNoteWithVersionsData] = useState<any>();
  const [showDiagnosisNoteWithVersionModal, setShowDiagnosisNoteWithVersionModal] = useState(false);

  useEffect(() => {
    props.setSelectedClient(props.transcribeData.clientId);

    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (diagnosisNoteWithVersionsData) {
      setShowDiagnosisNoteWithVersionModal(true);
    }
  }, [diagnosisNoteWithVersionsData]);

  const createTranscriptText = (meetingId: string) => {
    setTranscribeInProcess(true);

    VideoChatService.createTranscriptText(meetingId).then(res => {
      if (!res.success) {
        if (mounted) {
          setTranscribeInProcess(false);

          if (res.error && res.errorCode && res.errorCode == 600) {
            toast.success("Recordings of meeting are still processing.Please try again shortly", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Server error occured", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        }
      }
    });
  };

  const handleCheckBoxChange = (e: any) => {
    const id: string = e.target.name;

    if (props.selectedTreatmentHistoryForMerge.includes(id)) {
      if (!e.target.value) {
        props.setSelectedTreatmentHistoryForMerge((prev: any[]) => {
          return [...prev.filter((item, i) => item !== id)];
        });
      }
    } else {
      props.setSelectedTreatmentHistoryForMerge([...props.selectedTreatmentHistoryForMerge, id]);
    }
  };
  // const handleClickedAiGenerate = (boolean: boolean) => {
  //   setShowAiGeneratedPopup(boolean);
  // };

  const handleChildFunction = (isCalled: boolean) => {
    setShowAiGeneratedPopup(isCalled);
  };

  const saveNote = (transcribeData: any, noteType: string) => {
    console.log("B");
    console.log("transcribeData", transcribeData);
    props.saveNewNote(transcribeData, noteType);
  };

  const deleteDiagnosisNoteCode = (e: any) => {
    const diagnosisNoteId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
      icon: "info",
      title: "Are you sure want to delete this treatment history?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        const data = {
          noteId: diagnosisNoteId,
        };

        VideoChatService.deleteTreatmentHistoryByTherapist(data).then(res => {
          if (res.success) {
            props.updateDiagnosisData(true);
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };
  const handleOpenShareNote = () => {
    setOpenShareModal(true);
  };

  const onShare = (data: TreatmentHistory) => {
    setOpenShareModal(false);
    props?.onShareWithSubTherapy(data);
  };

  const slectTypeFunction = (type: string, showModal: boolean) => {
    setShowTypeSelectModal(showModal);

    if (type && (type == AINotesType.PIE || type == AINotesType.SOAP)) {
      if (type == AINotesType.SOAP) {
        setShowAiGeneratedPopup(true);
      } else {
        setShowPIENote(true);
      }
    }
  };

  const openDiagnosisNoteInitial = () => {
    const noteDetails = props?.transcribeData?.diagnosisNoteDetails;

    if (!noteDetails?.noteType && noteDetails?.updatedByTherapist) {
      openDiagnosisNote(AINotesType.SOAP);
    } else if (!user?.aiNotesType && noteDetails?.noteType) {
      openDiagnosisNote(noteDetails?.noteType);
    } else if (user?.aiNotesType && noteDetails?.noteType) {
      openDiagnosisNote(noteDetails?.noteType);
    } else if (!noteDetails?.noteType && user?.aiNotesType) {
      openDiagnosisNote(user?.aiNotesType);
    } else {
      setShowTypeSelectModalForViewEdit(true);
    }
  };

  const openDiagnosisNote = (noteTypeFrom: string) => {
    if (props.transcribeData.isMeetingTranscribe) {
      let totalSpentDuration = 0;

      if (props.transcribeData.mergedMeetings && props.transcribeData.mergedMeetings.length > 0) {
        props.transcribeData.mergedMeetings.map((v: any) => {
          totalSpentDuration = totalSpentDuration + v.spentDuration;
        });
      } else {
        totalSpentDuration = props.transcribeData.meetingId.spentDuration;
      }

      props.showDiagnosisFunction(props.transcribeData.diagnosisNoteId, totalSpentDuration, noteTypeFrom, false);
    }
  };

  const slectTypeFunctionForViewEdit = (type: string, showModal: boolean) => {
    setShowTypeSelectModalForViewEdit(showModal);

    if (type && (type == AINotesType.PIE || type == AINotesType.SOAP)) {
      openDiagnosisNote(type);
    }
  };

  const genarateAiNoteFunction = () => {
    const noteType = props?.transcribeData?.diagnosisNoteDetails?.noteType;
    const userNoteType = user?.aiNotesType;
    if (noteType) {
      if (noteType === "PIE") {
        setShowPIENote(true);
      } else {
        setShowAiGeneratedPopup(true);
      }
    } else if (!noteType && userNoteType) {
      if (userNoteType === "PIE") {
        setShowPIENote(true);
      } else {
        setShowAiGeneratedPopup(true);
      }
    } else {
      setShowTypeSelectModal(true);
    }
  };

  const closePIENoteFunction = (value: boolean) => {
    setShowPIENote(value);
  };

  const savePIENote = (transcribeData: any) => {
    // props.saveNewNote(transcribeData);
  };

  const handleModalClose = () => {
    setShowDiagnosisNoteWithVersionModal(false);
    setDiagnosisNoteWithVersionsData(null);
  };

  return (
    <div>
      {showTypeSelectModal && <NoteTypeSelectModal setShowModal={slectTypeFunction} showModal={showTypeSelectModal} />}
      {showTypeSelectModalForViewEdit && <NoteTypeSelectModal setShowModal={slectTypeFunctionForViewEdit} showModal={showTypeSelectModalForViewEdit} />}
      {showDiagnosisNoteWithVersionModal && (
        <DiagnosisNoteVersionModal
          setShowModal={setShowDiagnosisNoteWithVersionModal}
          showModal={showDiagnosisNoteWithVersionModal}
          diagnosisNoteDataTherapist={diagnosisNoteWithVersionsData}
          diagnosisNoteDataAdmin={null}
          showDiagnosisFunction={props.showDiagnosisFunction}
          isAdmin={false}
          showDiagnosis={() => {}}
          handleClose={handleModalClose}
        />
      )}
      <CardBody className="custom-card1">
        <div className="note-div-sec1">
          <h5 className="font-size-15">&#9930; Session Treatment History</h5>

          <div className="note-div-sec2">
            {props?.transcribeData?.meetingId?.meetingId?.includes("Regular") && <div className="note-regular-call">Regular Call</div>}

            {props?.transcribeData?.meetingId?.meetingId?.includes("Twilio") && <div className="note-regular-call">Phone Call</div>}

            {props?.transcribeData?.mergedMeetings?.length > 0 && <div className="note-merge-sec">Merged</div>}
          </div>
        </div>

        {props?.transcribeData?.meetingId?.appointmentId && props?.transcribeData?.appointmentDetails ? (
          <h5 className="font-size-15">
            Appointment Start Time{" "}
            <span className="session-h5">{moment(props?.transcribeData.appointmentDetails?.start || null).format("YYYY-MM-DD h:mm:ss a")}</span> / Meeting
            Started At <span className="session-h5">{moment(props.transcribeData.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
          </h5>
        ) : (
          <h5 className="font-size-15">
            Meeting Started At <span className="session-h5">{moment(props.transcribeData.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
          </h5>
        )}

        {props.transcribeData?.meetingId.callingStatus && (
          <h5 className="font-size-15">
            Calling Status&nbsp;
            <span className={props.transcribeData?.meetingId.callingStatus + " session-h5"}>{props.transcribeData?.meetingId.callingStatus}</span>
          </h5>
        )}

        <div className="flex">
          {props?.transcribeData?.isMeetingTranscribe && props?.transcribeData?.meetingId?.callingStatus == "COMPLETED" && user?.role == Role.THERAPIST && (
            <a
              onClick={() => {
                if (
                  props?.transcribeData?.isMeetingTranscribe &&
                  props?.transcribeData?.meetingId?.callingStatus == "COMPLETED" &&
                  props?.transcribeData?.diagnosisNoteVersions &&
                  props?.transcribeData?.diagnosisNoteVersions?.length > 0 &&
                  user?.role == Role.THERAPIST
                ) {
                  setDiagnosisNoteWithVersionsData(props?.transcribeData);
                } else {
                  openDiagnosisNoteInitial();
                }
              }}
              className="btn btn-secondary s-h-btn"
            >
              View / Edit Notes
            </a>
          )}

          {user?.role == Role.THERAPIST && (
            <>
              {((props.transcribeData.meetingId != undefined && props.transcribeData.meetingId?.transcribingInProcess) || props.isCreating) && (
                <a>
                  <span className="btn bg-warning convert-pending-note s-h-btn"> Convert Pending </span>
                </a>
              )}

              {props?.transcribeData?.diagnosisNoteDetails?.updatedByTherapist == false && (
                <button className="btn btn-danger s-h-btn" onClick={deleteDiagnosisNoteCode} data-value1={props?.transcribeData._id}>
                  <img src={deleteIcon} className="ai-icon-size " />
                </button>
              )}

              {((!props?.transcribeData?.meetingId?.clientAllowedTranscribe ||
                !props?.transcribeData?.meetingId?.therapistAllowedTranscribe ||
                !props?.transcribeData?.meetingId?.firstSpeaker ||
                !props?.transcribeData?.meetingId?.transcribeCreated) &&
                (props?.transcribeData?.diagnosisNoteDetails?.updatedByTherapist == false &&
                props?.transcribeData?.meetingId?.callingStatus != "CANCELLED"
                )) && (
                  <div className="text-primary d-flex align-items-center mt-3">
                    <i className="bx bx-info-circle clarity-info" id="ai_info"></i>
                    <UncontrolledTooltip placement="right" target={"ai_info"}>
                      The client has not provided consent for AI-generated notes or transcription.
                    </UncontrolledTooltip>
                  </div>
              )}

              {props?.transcribeData?.meetingId?.clientAllowedTranscribe &&
                props?.transcribeData?.meetingId?.therapistAllowedTranscribe &&
                props?.transcribeData?.diagnosisNoteDetails?.updatedByTherapist == false &&
                props?.transcribeData?.meetingId?.firstSpeaker &&
                props?.transcribeData?.meetingId?.transcribeCreated && (
                  <a>
                    <span className="btn btn-secondary btn-black1 convert-pending-note s-h-btn " onClick={() => genarateAiNoteFunction()}>
                      <img src={AiIcon} className="ai-icon-size me-1" />
                      {/* {(user?.aiNotesType == AINotesType.PIE || user?.aiNotesType == AINotesType.SOAP) ? (user?.aiNotesType == AINotesType.SOAP ? "Generate SOAP note with AI" : "Generate PIE note with AI"): "Generate note with AI"} */}
                      {props?.transcribeData?.diagnosisNoteDetails?.noteType
                        ? props?.transcribeData?.diagnosisNoteDetails?.noteType === AINotesType.PIE
                          ? "Generate PIE note with AI"
                          : "Generate SOAP note with AI"
                        : user?.aiNotesType
                          ? user?.aiNotesType === AINotesType.PIE
                            ? "Generate PIE note with AI"
                            : "Generate SOAP note with AI"
                          : "Generate note with AI"}
                    </span>
                  </a>
                )}
            </>
          )}
          {user?.role == Role.THERAPIST &&
            props?.transcribeData?.meetingId?.callingStatus == "COMPLETED" &&
            !props.transcribeData?.subTherapistId &&
            props?.transcribeData?.diagnosisNoteDetails?.updatedByTherapist == true && (
              <button className="btn btn-success s-h-btn" onClick={() => handleOpenShareNote()}>
                <i className="fa fa-share-alt f-16"></i>
              </button>
            )}

          {/* {user?.role == Role.CLIENT && (
            <>
              { !props.isCreating &&
                props.transcribeData.meetingId != undefined &&
                props.transcribeData.meetingId.recordingSharedWithClient &&
                !props.transcribeData.meetingId.transcribingInProcess &&
                props.transcribeData.meetingId.transcribeCreated &&
                props.transcribeData.meetingId.recordingAllowed &&
                props.transcribeData.meetingId.recordingAllowed == true &&
                props.transcribeData.meetingId.audioFiles &&
                props.transcribeData.meetingId.audioFiles.length != 0 &&
                props.transcribeData.meetingId.audioFiles[0] &&
                props.transcribeData.meetingId.audioFiles[0].url &&
                (
                  <a>
                    {props.transcribeData.meetingId.audioFiles?.map((trs: any, index: any) => {
                      return (
                        <span
                          key={index}
                          onClick={() => {
                           props.showAudioFunction(trs._id, true);
                          }}
                          className="btn bg-success convert-pending-note s-h-btn"
                        >
                          {index == 0 ? "View Recording" : "View Recording" + (index + 1)}
                        </span>
                      );
                    })}
                  </a>
                )}
            </>
          )} */}

          {user?.role == Role.CLIENT && (
            <>
              {!props.isCreating &&
                props.transcribeData.meetingId != undefined &&
                props.transcribeData.meetingId.recordingSharedWithClient &&
                !props.transcribeData.meetingId.transcribingInProcess &&
                props.transcribeData.meetingId.transcribeCreated &&
                props.transcribeData.meetingId.recordingAllowed &&
                props.transcribeData.meetingId.recordingAllowed == true &&
                props.transcribeData.meetingId?.s3AudioPath && (
                  <a>
                    <span
                      onClick={() => {
                        props.showAudioFunction(props.transcribeData.meetingId._id, props.transcribeData.meetingId?.s3AudioPath, true);
                      }}
                      className="btn bg-success convert-pending-note s-h-btn"
                    >
                      view Record
                    </span>
                  </a>
                )}
            </>
          )}
        </div>

        {!props?.transcribeData?.meetingId?.transcribeCreated && props?.transcribeData?.meetingId?.s3AudioPath && (
          <h5 className="font-size-15 pt-2">
            <span className="text-success">Transcribe will be generated within two minutes.</span>
          </h5>
        )}

        {user?.role != Role.CLIENT && (
          <div className="merge-check-box">
            <div className="checkbox-wrapper-13">
              <input
                type="checkbox"
                name={props.transcribeData._id}
                id={props.transcribeData._id}
                onChange={e => {
                  handleCheckBoxChange({ target: { name: e.target.name, value: e.target.checked } });
                }}
              />
            </div>
          </div>
        )}
      </CardBody>

      {showAiGeneratedPopup && (
        <AIGeneratedNote
          OpenAiModel={showAiGeneratedPopup}
          transcribeData={props.transcribeData}
          closeModelFunction={handleChildFunction}
          saveNote={saveNote}
        />
      )}

      {showPIENote && (
        <AIGeneratePieNoteNew OpenAiModel={showPIENote} transcribeData={props.transcribeData} closeModelFunction={closePIENoteFunction} saveNote={saveNote} />
      )}

      {openShareModal && (
        <NoteShareTherapistModal showModal={openShareModal} setShowModal={setOpenShareModal} noteData={props.transcribeData} onShare={onShare} />
      )}
    </div>
  );
};

export default TranscribeHistoryCard;
