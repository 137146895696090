import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import { AdminService } from "src/services/AdminService";
import { AuthFormType } from "src/models/AuthorizationFormData";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import AmeriHealthAuthFormPdf from "./AmeriHealthAuthFormPdf";
import CarolinaCompleteHealthAuthFormPdf from "./CarolinaCompleteHealthAuthFormPdf";
import UnitedHealthCareAuthFormPdf from "./UnitedHealthCareAuthFormPdf";
import HealthyBlueAuthFormPdf from "./HealthyBlueAuthFormPdf";
import WellCareAuthFormPdf from "./WellCareAuthFormPdf";
import { AmeriHealthAuthForm } from "src/models/AuthorizationForm/ameri-health-auth-form-model";
import { CarolinaCompleteHealthAuthForm } from "src/models/AuthorizationForm/carolina-complete-health-auth-form-model";
import { WellCareAuthForm } from "src/models/AuthorizationForm/wellcare-auth-form-model";
import { HealthyBlueAuthForm } from "src/models/AuthorizationForm/healthy-blue-auth-form-model";
import { UnitedHealthCareAuthForm } from "src/models/AuthorizationForm/united-health-care-auth-form-model";
import { UploadCategory } from "src/models/ClientInsuranceDocs";
import { AmbetterAuthForm } from "src/models/AuthorizationForm/ambetter-auth-form-model";
import AmbetterAuthFormPdf from "./AmbetterAuthFormPdf";
toast.configure();

const AuthorizationFormPdfUploadPage: React.FC<{ identifierId: any; insuranceDocApprovalId: any; authorizationFormType: AuthFormType; isAdmin: boolean; onPdfComplete: () => void; onUploadModalRefresher: () => void; }> = props => {
    const [buttonText, setButtonText] = useState('Upload Authorization Form');
    const [isDisabled, setIsDisabled] = useState(false);
    const [authorizationFormData, setAuthorizationFormData] = useState<
        AmeriHealthAuthForm |
        CarolinaCompleteHealthAuthForm |
        UnitedHealthCareAuthForm |
        HealthyBlueAuthForm |
        WellCareAuthForm |
        AmbetterAuthForm | null
    >(null);
    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const authorizationFormType = props.authorizationFormType;
    const insuranceDocApprovalId = props.insuranceDocApprovalId;

    useEffect(() => {
        getAuthorizationFormData();
    }, []);

    const getAuthorizationFormData = async () => {
        try {
            if (isAdmin) {
                const data = {
                    _id: identifierId
                }
                const res = await AuthorizationFormService.getAuthorizationFormDetailsByAdmin(data);
                if (res && res.success && res.data) {
                    setAuthorizationFormData(res.data);
                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            } else {
                toast.error("Not allowed!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const replaceTextareasWithDivs = () => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach((textarea) => {
            const text = textarea.value;
            const div = document.createElement('div');
            
            div.style.whiteSpace = 'pre-wrap';
            div.style.wordWrap = 'break-word';
            div.style.border = textarea.style.border;
            div.style.padding = textarea.style.padding;
            div.style.width = textarea.style.width;
            div.style.height = textarea.style.height;
            div.textContent = text;
    
            const parent = textarea.parentNode;
            if (parent) {
                parent.insertBefore(div, textarea);
                textarea.style.display = 'none';
            }
        });
    };

    const generatePDF = async () => {
        replaceTextareasWithDivs();
    
        Swal.fire({
            icon: "info",
            title: "PDF Preparation In Progress!",
            html: `
            <p>The therapy plan is being prepared. Please wait while we generate the document.</p>
            <p>Once the preparation is complete, it will be automatically upload to the destination folder.</p>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    
        setButtonText('PDF preparation in progress. Please wait...');
        setIsDisabled(true);
    
        try {
            const pagesCollection = document.getElementsByClassName('form-page');
            const pages = Array.from(pagesCollection).map(page => page as HTMLElement);
    
            const now = new Date();
            const formattedDate = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`;
            const formattedTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
            const fileName = `${authorizationFormType}_${formattedDate}_${formattedTime}.pdf`;
    
            const pdf = await html2PDF(pages, {
                jsPDF: {
                    unit: 'pt',
                    format: 'a4',
                    compress: true,
                },
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                autoResize: true,
                imageType: 'image/jpeg',
                output: fileName,
                success: async (pdf) => {
                    try {
                        const pdfArrayBuffer = pdf.output('arraybuffer');
                        const pdfBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
                        const uploadCategory = UploadCategory.AUTHORIZATION_FORM;
                        const res = await AdminService.uploadInsurancePdf(insuranceDocApprovalId, uploadCategory, pdfBlob, fileName);
    
                        if (res && res.success) {
                            props.onUploadModalRefresher();
                            toast.success(res.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar"
                            });
                        } else {
                            toast.error(res.error, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                        }
                    } catch (uploadError) {
                        toast.error("Upload failed! Please try again.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                },
            });
        } catch (error) {
            toast.error("Something went wrong! Try again later.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setButtonText('Upload Therapy Plan');
            setIsDisabled(false);
            props.onPdfComplete();
            Swal.close();
        }
    };

    return (
        <React.Fragment>
            <div className="page-content mt-0" style={{ padding: 0 }}>
                <Container className="containerOnboarding" style={{ padding: 0 }}>
                    <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
                        <Col>
                            <div>
                                <h5 className="font-size-25 profileFont mt-4 text-center">
                                    Authorization Form
                                </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                                <span>Click the button below to create and download the PDF to your device.</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-primary mb-2" onClick={generatePDF} disabled={isDisabled}>
                                    {buttonText}
                                </button>
                            </div>
                            <StepWizard>
                                {authorizationFormType === AuthFormType.AmeriHealthAuthForm ? (
                                        <AmeriHealthAuthFormPdf authorizationFormType={authorizationFormType} authorizationFormData={authorizationFormData as AmeriHealthAuthForm} />
                                    ) : authorizationFormType === AuthFormType.CarolinaCompleteHealthAuthForm ? (
                                        <CarolinaCompleteHealthAuthFormPdf authorizationFormType={authorizationFormType} authorizationFormData={authorizationFormData as CarolinaCompleteHealthAuthForm} />
                                    ) : authorizationFormType === AuthFormType.UnitedHealthCareAuthForm ? (
                                        <UnitedHealthCareAuthFormPdf authorizationFormType={authorizationFormType} authorizationFormData={authorizationFormData as UnitedHealthCareAuthForm} />
                                    ) : authorizationFormType === AuthFormType.HealthyBlueAuthForm ? (
                                        <HealthyBlueAuthFormPdf authorizationFormType={authorizationFormType} authorizationFormData={authorizationFormData as HealthyBlueAuthForm} />
                                    ) : authorizationFormType === AuthFormType.WellcareAuthForm ? (
                                        <WellCareAuthFormPdf authorizationFormType={authorizationFormType} authorizationFormData={authorizationFormData as WellCareAuthForm} />
                                    ) : authorizationFormType === AuthFormType.AmbetterAuthForm ? (
                                        <AmbetterAuthFormPdf authorizationFormType={authorizationFormType} authorizationFormData={authorizationFormData as AmbetterAuthForm} />
                                    ) : (
                                        <></>
                                    )
                                }
                            </StepWizard>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AuthorizationFormPdfUploadPage;