import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { BenefitInformation, Eligibility } from "../../models/Insurance";
import { AdminService } from "../../services/AdminService";

interface ViewEligibilityModalProps {
  insuranceId: string;
  tradingServiceId: string;
  showModal: boolean;
  toggleModal: () => void;
}

const ViewEligibilityModal: React.FC<ViewEligibilityModalProps> = ({ tradingServiceId, insuranceId, showModal, toggleModal }: ViewEligibilityModalProps) => {
  const [insurancePlan, setInsurancePlan] = useState<Eligibility>({} as Eligibility);
  const [isLaoding, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getEligibilityDetails(insuranceId, tradingServiceId);
  }, [insuranceId]);

  const getEligibilityDetails = (insuranceId: string, tradingServiceId: string) => {
    setIsLoading(true);
    AdminService.getEligibilityDetails(insuranceId, tradingServiceId).then(res => {
      if (res.success) {
        setInsurancePlan(res.data);
        setIsLoading(false);
      } else {
        setInsurancePlan({} as Eligibility);
        setIsLoading(false);
      }
    });
  };

  return showModal ? (
    <>
      <Modal isOpen={showModal} centered scrollable fullscreen className="p-5">
        {isLaoding && <Spinner className="bouncer" />}

        {!isLaoding && (
          <>
            
            <ModalHeader>
              <button type="button" onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
                <span>&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="p-5">
              <h2 className="mb-3 text-center text-muted">Insurance Information</h2>
              <Row className="mb-3">
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-20">Eligibility Details</h5>
                  </div>
                </Row>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Eligibility Status</h5>
                  {insurancePlan?.benefitInformation?.length > 0 ? <span className="el-active">ACTIVE</span> : <span className="el-inactive">INACTIVE</span>}
                </Col>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Control Number</h5>
                  <h5 className="font-size-16">{insurancePlan.controlNumber ? insurancePlan.controlNumber : "-"}</h5>
                </Col>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Re-association Key</h5>
                  <h5 className="font-size-16">{insurancePlan.reassociationKey ? insurancePlan.reassociationKey : "-"}</h5>
                </Col>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Payer ID</h5>
                  <h5 className="font-size-16">{insurancePlan.tradingPartnerServiceId ? insurancePlan.tradingPartnerServiceId : "-"}</h5>
                </Col>
                <div className="mt-3 border-bottom"></div>
              </Row>

              <Row className="mb-3">
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-20">Provider Details</h5>
                  </div>
                </Row>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Service Provider Number</h5>
                  <h5 className="font-size-16">{insurancePlan.provider?.serviceProviderNumber ? insurancePlan.provider?.serviceProviderNumber : "-"}</h5>
                </Col>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Entity Identifier</h5>
                  <h5 className="font-size-16">{insurancePlan.provider?.entityIdentifier ? insurancePlan.provider?.entityIdentifier : "-"}</h5>
                </Col>
                <Col lg={4}>
                  <h5 className="text-muted font-size-16 mb-2">Entity Type</h5>
                  <h5 className="font-size-16">{insurancePlan.provider?.entityType ? insurancePlan.provider?.entityType : "-"}</h5>
                </Col>
                <div className="mt-3 border-bottom"></div>
              </Row>

              <Row className="mb-3">
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-20">Subscriber Details</h5>
                  </div>
                </Row>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Firstname</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.firstName ? insurancePlan.subscriber?.firstName : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Lastname</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.lastName ? insurancePlan.subscriber?.lastName : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Date of Birth</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.dateOfBirth ? insurancePlan.subscriber?.dateOfBirth : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Gender</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.gender ? insurancePlan.subscriber?.gender : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Member ID</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.memberId ? insurancePlan.subscriber?.memberId : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Entity Identifier</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.entityIdentifier ? insurancePlan.subscriber?.entityIdentifier : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Entity Type</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.entityType ? insurancePlan.subscriber?.entityType : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Group Number</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.groupNumber ? insurancePlan.subscriber?.groupNumber : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Relation to Subscriber</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.relationToSubscriber ? insurancePlan.subscriber?.relationToSubscriber : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Insured Indicator</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.insuredIndicator ? insurancePlan.subscriber?.insuredIndicator : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Maintenance Type Code</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.maintenanceTypeCode ? insurancePlan.subscriber?.maintenanceTypeCode : "-"}</h5>
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Maintenance Reason Code</h5>
                  <h5 className="font-size-16">{insurancePlan.subscriber?.maintenanceReasonCode ? insurancePlan.subscriber?.maintenanceReasonCode : "-"}</h5>
                </Col>
                <div className="mt-3 border-bottom"></div>
              </Row>

              <Row className="mb-3">
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-20">Benefits Information</h5>
                  </div>
                </Row>

                {insurancePlan.benefitInformation?.map((item: BenefitInformation, index: number) => {
                  return (
                    <Col lg={6} key={index}>
                      <div className="benefits-info">
                        <Col lg={4} className="mb-3 px-3">
                          <h5 className="text-muted font-size-16 mb-2">Code</h5>
                          <h5 className="font-size-16">{item.code ? item.code : "-"}</h5>
                        </Col>
                        <Col lg={4} className="mb-3 px-3">
                          <h5 className="text-muted font-size-16 mb-2">Name</h5>
                          <h5 className="font-size-16">{item.name ? item.name : "-"}</h5>
                        </Col>
                        <Col lg={4} className="mb-3 px-3">
                          <h5 className="text-muted font-size-16 mb-2">Coverage Level</h5>
                          <h5 className="font-size-16">{item.coverageLevel ? item.coverageLevel : "-"}</h5>
                        </Col>
                        <Col lg={4} className="mb-3 px-3">
                          <h5 className="text-muted font-size-16 mb-2">Benefit Amount</h5>
                          <h5 className="font-size-16">{item.benefitAmount ? item.benefitAmount : "-"}</h5>
                        </Col>
                        <Col lg={4} className="mb-3 px-3">
                          <h5 className="text-muted font-size-16 mb-2">Service Types</h5>
                          {item.serviceTypes && item.serviceTypes.length > 0 ? (
                            item.serviceTypes.map((service: string, index: number) => {
                              return (
                                <h5 className="font-size-16" key={index}>
                                  {service}
                                </h5>
                              );
                            })
                          ) : (
                            <h5 className="font-size-16">-</h5>
                          )}
                        </Col>
                      </div>
                    </Col>
                  );
                })}

                <div className="mt-3 border-bottom"></div>
              </Row>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  ) : null;
};

export default ViewEligibilityModal;
