import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table, Input, FormGroup, Label, ModalFooter, Button } from 'reactstrap';
import DiagnosisNoteScreen from '../Chat/DiagnosisNote/DiagnosisNoteView';
import { AuditSessionsPopUpService } from '../../services/AuditSessionsPopUpService';
import moment from 'moment';
import Spinner from '../../common/spinner/spinner';
import { toast } from 'react-toastify';
import './AuditSessionsPopup.css';

interface AuditSessionsPopupProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  clientId: string;
  therapistId: string;
}

interface Note {
  diagnosisNoteId: string;
  meetingStartedTime: string;
  status?: string;
  feedback?: string;
  isMeetingTranscribe?: boolean;
  noteType?: string | null;
  updatedByTherapist?: boolean;
  createdAt?: string;
  diagnosisNoteVersions?: any[];
  lastFeedbackFromAuditor?: string;
}

const STATUS_OPTIONS = [
  { value: 'NEED_REVIEW', label: 'Need Review', color: '#6c757d' },  // gray color
  { value: 'COMPLETED', label: 'Completed', color: '#34c38f' },      // green color
  { value: 'NEED_ATTENTION', label: 'Need Attention', color: '#f46a6a' }  // red color
];

const AuditSessionsPopup: React.FC<AuditSessionsPopupProps> = ({ 
  showModal, 
  setShowModal,
  clientId,
  therapistId
}): React.ReactElement => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number | null>(null);
  const [currentFeedback, setCurrentFeedback] = useState('');
  
  // New states for diagnosis note modal
  const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
  const [selectedDiagnosisId, setSelectedDiagnosisId] = useState('');
  const [totalSpentDuration] = useState(0);

  useEffect(() => {
    if (showModal && clientId && therapistId) {
      fetchNotes();
    }
  }, [showModal, clientId, therapistId]);

  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      const response = await AuditSessionsPopUpService.getNotesForTherapists(therapistId, clientId);
      if (response.success && response.data && response.data[0]?.notes) {
        const notesWithStatus = response.data[0].notes.map(note => ({
          ...note,
          // Use status from API if it exists, otherwise default to NEED_REVIEW
          status: note.status || 'NEED_REVIEW',
          feedback: note.lastFeedbackFromAuditor || ''
        }));
        setNotes(notesWithStatus);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (index: number, value: string) => {
    const note = notes[index];
    const status = value.toUpperCase();

    // For NEED_ATTENTION, just show the feedback dialog
    if (status === 'NEED_ATTENTION') {
      const updatedNotes = [...notes];
      updatedNotes[index] = {
        ...updatedNotes[index],
        status: value
      };
      setNotes(updatedNotes);
      setSelectedNoteIndex(index);
      // setCurrentFeedback(updatedNotes[index].feedback || '');
      setCurrentFeedback('');
      setShowFeedbackDialog(true);
      return;
    }

    // For other statuses (NEED_REVIEW, COMPLETED), call API immediately
    try {
      const response = await AuditSessionsPopUpService.updateDiagnosisNote(
        note.diagnosisNoteId,
        status
      );

      if (response.success) {
        const updatedNotes = [...notes];
        updatedNotes[index] = {
          ...updatedNotes[index],
          status: value
        };
        setNotes(updatedNotes);

        toast.success('Status updated successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        toast.error('Failed to update status', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  const handleFeedbackSubmit = async () => {
    if (selectedNoteIndex === null) return;

    try {
      const note = notes[selectedNoteIndex];
      const response = await AuditSessionsPopUpService.updateDiagnosisNote(
        note.diagnosisNoteId,
        'NEED_ATTENTION',
        currentFeedback
      );

      if (response.success) {
        const updatedNotes = [...notes];
        updatedNotes[selectedNoteIndex] = {
          ...updatedNotes[selectedNoteIndex],
          status: 'NEED_ATTENTION',
          feedback: currentFeedback
        };
        setNotes(updatedNotes);
        setShowFeedbackDialog(false);
        setSelectedNoteIndex(null);
        setCurrentFeedback('');

        toast.success('Feedback submitted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        toast.error('Failed to submit feedback', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit feedback', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  // const handleSave = async () => {
  //   try {
  //     setIsSaving(true);
  //     // Validate that all need_attention status have feedback
  //     const invalidNotes = notes.filter(note => 
  //       note.status === 'need_attention' && (!note.feedback || note.feedback.trim() === '')
  //     );

  //     if (invalidNotes.length > 0) {
  //       toast.error('Please provide feedback for all notes marked as "Need Attention"', {
  //         position: toast.POSITION.BOTTOM_RIGHT
  //       });
  //       return;
  //     }

  //     const updateData = notes.map(note => ({
  //       diagnosisNoteId: note.diagnosisNoteId,
  //       status: note.status || '',
  //       feedback: note.status === 'need_attention' ? note.feedback : undefined
  //     }));

  //     const response = await AuditSessionsPopUpService.updateSessionNotes(
  //       therapistId,
  //       clientId,
  //       updateData
  //     );
      
  //     if (response.success) {
  //       toast.success('Changes saved successfully', {
  //         position: toast.POSITION.BOTTOM_RIGHT
  //       });
  //       setShowModal(false);
  //     } else {
  //       toast.error(response.message || 'Failed to save changes', {
  //         position: toast.POSITION.BOTTOM_RIGHT
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error saving notes:', error);
  //     toast.error('Failed to save changes', {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   } finally {
  //     setIsSaving(false);
  //   }
  // };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={() => setShowModal(false)}>
          Sessions
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Spinner />
          ) : (
            <Table className="table" style={{ tableLayout: 'fixed' }}>
              <thead className="thead-dark">
                <tr>
                  <th style={{ width: '30%' }}>Meeting Started Time</th>
                  <th style={{ width: '45%' }}>Diagnosis Note ID</th>
                  <th style={{ width: '25%' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note, index) => (
                  <tr 
                    key={index} 
                    className={showFeedbackDialog && selectedNoteIndex === index ? 'table-active' : ''}
                  >
                    <td 
                      style={{ 
                        padding: '15px',
                        backgroundColor: showFeedbackDialog && selectedNoteIndex === index ? '#e3f2fd' : 'transparent'
                      }}
                    >
                      {moment(note.meetingStartedTime).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                    <td 
                      style={{ 
                        padding: '15px', 
                        wordBreak: 'break-all',
                        backgroundColor: showFeedbackDialog && selectedNoteIndex === index ? '#e3f2fd' : 'transparent'
                      }}
                    >
                      <Button
                        color="link"
                        className="p-0 text-primary"
                        onClick={() => {
                          setSelectedDiagnosisId(note.diagnosisNoteId);
                          setShowDiagnosisModal(true);
                        }}
                      >
                        {note.diagnosisNoteId}
                      </Button>
                    </td>
                    <td 
                      style={{ 
                        padding: '15px',
                        backgroundColor: showFeedbackDialog && selectedNoteIndex === index ? '#e3f2fd' : 'transparent'
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <Input
                          type="select"
                          value={note.status}
                          onChange={(e) => handleStatusChange(index, e.target.value)}
                          style={{ 
                            width: '100%',
                            color: STATUS_OPTIONS.find(opt => opt.value === note.status)?.color || '#6c757d'
                          }}
                          className="custom-select"
                        >
                          {STATUS_OPTIONS.map(option => (
                            <option 
                              key={option.value} 
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </Input>
                        {note.status === 'need_attention' && note.feedback && (
                          <i 
                            className="bx bx-message-square-detail ms-2" 
                            style={{ cursor: 'pointer', color: '#50a5f1' }}
                            onClick={() => {
                              setSelectedNoteIndex(index);
                              setCurrentFeedback(note.feedback || '');
                              setShowFeedbackDialog(true);
                            }}
                            title="View/Edit Feedback"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ModalBody>
        {/* <ModalFooter>
          <Button 
            color="secondary" 
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            disabled={isLoading || isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </ModalFooter> */}
      </Modal>

      {/* Feedback Dialog */}
      <Modal
        isOpen={showFeedbackDialog}
        toggle={() => setShowFeedbackDialog(false)}
        centered={true}
        size="md"
      >
        <ModalHeader toggle={() => setShowFeedbackDialog(false)}>
          Add Feedback for {selectedNoteIndex !== null ? notes[selectedNoteIndex]?.diagnosisNoteId : ''}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Please provide feedback for this session:</Label>
            <Input
              type="textarea"
              value={currentFeedback}
              onChange={(e) => setCurrentFeedback(e.target.value)}
              placeholder="Enter your feedback here..."
              style={{ minHeight: '120px' }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button 
            color="secondary" 
            onClick={() => {
              if (selectedNoteIndex !== null && !currentFeedback.trim()) {
                // If no feedback is provided, revert the status to 'need_review'
                const updatedNotes = [...notes];
                updatedNotes[selectedNoteIndex].status = 'need_review';
                setNotes(updatedNotes);
              }
              setShowFeedbackDialog(false);
              setSelectedNoteIndex(null);
              setCurrentFeedback('');
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleFeedbackSubmit}
            disabled={!currentFeedback.trim()}
          >
            Submit Feedback
          </Button>
        </ModalFooter>
      </Modal>

      {/* Diagnosis Note Modal */}
      <Modal
        isOpen={showDiagnosisModal}
        toggle={() => setShowDiagnosisModal(false)}
        className="modal-xl"
        centered
      >
        <ModalHeader toggle={() => setShowDiagnosisModal(false)}>
          Diagnosis Note
        </ModalHeader>
        <ModalBody>
          {showDiagnosisModal && (
            <DiagnosisNoteScreen
              diagnosisId={selectedDiagnosisId}
              totalSpentDuration={totalSpentDuration}
              updateDiagnosisData={() => {}}
              isVersion={false}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AuditSessionsPopup;
