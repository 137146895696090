export enum CardType{
    VISA = "visa",
    MASTERCARD = "mastercard",
    DISCOVER = "discover",
    AMEX = "amex",
    JCB = "jcb",
    MAESTRO = "maestro",
    UNIONPAY = "unionpay"
}

export enum SubscriptionStatus {
    ALL = "",
    ACTIVE = "active",
    PAST_DUE = "past_due",
    CANCELED = "canceled",
}