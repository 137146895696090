import React from "react";
import { Modal } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Close from "../../assets/images/icons/u_times-circle.png";
import NewAssessmentOnboardingPage from "../NewAssessmentDigitalForm/NewAssessmentOnboardingPage";

const NewClinicalAssessmentModal: React.FC<{ showModal: boolean; identifierId: any; setShowModal: (value: boolean) => void; isFromMeeting: boolean; isAdmin: boolean; setModalRefresher: ((value: boolean) => void) | null; aiGeneratedAssessmentData: any; isMeeting:boolean }> = props => {
    function closeClinicalAssessmentModal() {
        props.setShowModal(false);
    }

    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const { setModalRefresher } = props;
    const aiGeneratedAssessmentData = props.aiGeneratedAssessmentData;
    const modalClassName = props.isFromMeeting === true ? "assesment-modal2" : "assesment-modal";
    const isMeeting = props.isMeeting

    return props.showModal ? (
        <>
            <Modal isOpen={props.showModal}
                className={modalClassName}
                centered
                fullscreen
                scrollable
            >
                <div
                    className="modal-head"
                    style={{
                        zIndex: 9998,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px"
                    }}
                >
                    <div className="assessment-header"
                        style={{ margin: "5px"}}>
                        <button
                            type="button"
                            onClick={closeClinicalAssessmentModal}
                            className="bg-transparent-color"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={Close} alt="Close" className="close-icon-d" />
                        </button>
                    </div>
                </div>
                <div
                    className="modal-body scrollbar-container ps--active-y"
                    style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
                    <NewAssessmentOnboardingPage identifierId={identifierId} isAdmin={isAdmin} setModalRefresher={setModalRefresher} closeClinicalAssessmentModal={closeClinicalAssessmentModal} aiGeneratedAssessmentData={aiGeneratedAssessmentData} isMeeting={isMeeting}/>
                </div>

                <div className="assessment-footer"
                    style={{ margin: "5px"}}>
                </div>
            </Modal>
        </>
    ) : null;
};

export default NewClinicalAssessmentModal;