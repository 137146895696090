export enum RequestType {
    URGENT = "URGENT",
    NON_URGENT = "NON_URGENT",
    PRE_SERVICE = "PRE_SERVICE",
    POST_SERVICE = "POST_SERVICE",
    CON_CURRENT = "CON_CURRENT",
    EMERGENT = "EMERGENT",
  }

export interface WellCareAuthorizationModel {
    date?:Date;
    requestingProvider?:string;
    npi?:string;
    tin?:string;
    contactName?:string;
    phoneNumber?:string;
    faxNumber?:string;
    typeOfRequest?: RequestType;
}