import React, { useContext } from "react";
import { CardTitle, Col, Row, Modal, CardBody, CardFooter } from "reactstrap";
import UserContext from "src/context/UserContext";
import { FriendRequest } from "src/models/FriendRequest";

const Modal30: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void; matchedTherapists: FriendRequest[]; changePrimaryTherapist: (value: any) => void; clickedTherapist: any }> = props => {
  const [user] = useContext(UserContext);
  
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} size="md" centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>

          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-warning text-danger"></i>
            </div>

            <>
              <CardTitle className="mb-4 text-center font-size-20">Switch Therapist</CardTitle>

              <div className="default-therapist-list">
                {
                  props.matchedTherapists?.map((mt: any, i: any) => {
                    return (
                      <div className="therapist-one" key={i}>
                        <div className="therapist-name">{mt.therapistId.firstname + " " + mt.therapistId.lastname}</div>

                        {
                          user?.primaryTherapist._id == mt.therapistId._id ?
                          <div className="set-default set-default-2">
                            Default
                          </div> :
                          <div className="set-default">

                            <button className="btn btn-primary" disabled={props.clickedTherapist == mt.therapistId._id ? true : false} onClick={() => props.changePrimaryTherapist(mt.therapistId)}>Set Default</button>
                          </div>
                        }
                      </div>
                    );
                  })
                }
              </div>
            </>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal30;
