import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Row, Col, NavLink, Form, Label, Input } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { PasswordDetails } from "src/models/User";
import { CommonService } from "src/services/CommonService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminService } from "src/services/AdminService";

const ViewAdminProfile: React.FC = () => {
  const [changePassword, setChangePassword] = useState<PasswordDetails>({} as PasswordDetails);
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);

  useEffect(() => {
    AdminService.updateFirstTimeLogin();
  }, []);
  
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const setChangePasswordBtn = () => {
    CommonService.changePassword(changePassword).then(res => {
      if (res.success) {
        setChangePassword({ newPassword: "", confirmPassword: "", oldPassword: "" });
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Admin Profile"} breadcrumbItem={"Admin Profile"} />

          <Card className="m-h">
            <CardBody>
              <Row>
                <div className="col-md-12">
                  <div className="card-heading mb-2">
                    <h2 className="font-size-20 mb-2">Change Password:</h2>
                    <hr />
                  </div>

                  <Row>
                    <div className="col-md-4 change-password">
                      <Col>
                        <Form>
                          <div className="mb-2">
                            <Label htmlFor="formrow-firstname-Input">Old password</Label>

                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Old password"
                              name="articleTitle"
                              maxLength={40}
                              onChange={e => setChangePassword({ ...changePassword, oldPassword: e.target.value })}
                              value={changePassword.oldPassword || ""}
                              required
                            />
                          </div>

                          <div className="mb-2">
                            <Label htmlFor="formrow-firstname-Input">New password</Label>

                            <Input
                              type={showPassword ? "password" : "text"}
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="New password"
                              name="articleTitle"
                              maxLength={41}
                              onChange={e => setChangePassword({ ...changePassword, newPassword: e.target.value })}
                              value={changePassword.newPassword || ""}
                              required
                            />
                          </div>

                          <input type="checkbox" onChange={toggleShowPassword} /> Show Password

                          <div className="mt-3"></div>

                          <div className="mb-4">
                            <Label htmlFor="formrow-firstname-Input">Confirm new password</Label>
                            
                            <Input
                              type={showPassword2 ? "password" : "text"}
                              className="form-control"
                              id="formrow-firstname-Input1"
                              placeholder="Confirm new password"
                              name="articleTitle"
                              maxLength={40}
                              onChange={e => setChangePassword({ ...changePassword, confirmPassword: e.target.value })}
                              value={changePassword.confirmPassword || ""}
                              required
                            />

                            <input type="checkbox" onChange={toggleShowPassword2} className="mt-3" /> Show Password
                          </div>

                          <button type="button" className="btn btn-primary w-50" style={{ float: "right" }} onClick={setChangePasswordBtn}>
                            Save
                          </button>
                        </Form>
                      </Col>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default ViewAdminProfile;