import { CarolinaCompleteHealthAuthForm } from "src/models/AuthorizationForm/carolina-complete-health-auth-form-model";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import axios from "axios";
import { AmeriHealthAuthForm } from "src/models/AuthorizationForm/ameri-health-auth-form-model";
import { UnitedHealthCareAuthForm } from "src/models/AuthorizationForm/united-health-care-auth-form-model";
import { HealthyBlueAuthForm } from "src/models/AuthorizationForm/healthy-blue-auth-form-model";
import { WellCareAuthForm } from "src/models/AuthorizationForm/wellcare-auth-form-model";
import { AmbetterAuthForm } from "src/models/AuthorizationForm/ambetter-auth-form-model";
import { AuthorizationForm } from "src/models/AuthorizationFormData";
import { InsuranceCompanyTags } from "src/models/InsuranceCompany";
import { FaxInfo } from "src/models/ClientInsuranceDocs";

export class AuthorizationFormService {  
  public static async createAmeriHealthAuthForm(data: Partial<AmeriHealthAuthForm>): Promise<AppResponse<AmeriHealthAuthForm>> {
    const url = Util.apiAuthUrl("createAmeriHealthAuthForm");
    return await axios.post<Partial<AmeriHealthAuthForm>, AppResponse<AmeriHealthAuthForm>>(url, data);
  }
  public static async createCarolinaCompleteHealthAuthForm(data: Partial<CarolinaCompleteHealthAuthForm>): Promise<AppResponse<CarolinaCompleteHealthAuthForm>> {
    const url = Util.apiAuthUrl("createCarolinaCompleteHealthAuthForm");
    return await axios.post<Partial<CarolinaCompleteHealthAuthForm>, AppResponse<CarolinaCompleteHealthAuthForm>>(url, data);
  }
  public static async createUnitedHealthCareAuthForm(data: Partial<UnitedHealthCareAuthForm>): Promise<AppResponse<UnitedHealthCareAuthForm>> {
    const url = Util.apiAuthUrl("createUnitedHealthCareAuthForm");
    return await axios.post<Partial<UnitedHealthCareAuthForm>, AppResponse<UnitedHealthCareAuthForm>>(url, data);
  }
  public static async createHealthyBlueAuthForm(data: Partial<HealthyBlueAuthForm>): Promise<AppResponse<HealthyBlueAuthForm>> {
    const url = Util.apiAuthUrl("createHealthyBlueAuthForm");
    return await axios.post<Partial<HealthyBlueAuthForm>, AppResponse<HealthyBlueAuthForm>>(url, data);
  }
  public static async createWellCareAuthForm(data: Partial<WellCareAuthForm>): Promise<AppResponse<WellCareAuthForm>> {
    const url = Util.apiAuthUrl("createWellCareAuthForm");
    return await axios.post<Partial<WellCareAuthForm>, AppResponse<WellCareAuthForm>>(url, data);
  }
  public static async createAmbetterAuthForm(data: Partial<AmbetterAuthForm>): Promise<AppResponse<AmbetterAuthForm>> {
    const url = Util.apiAuthUrl("createAmbetterAuthForm");
    return await axios.post<Partial<AmbetterAuthForm>, AppResponse<AmbetterAuthForm>>(url, data);
  }
  public static async getAuthorizationFormDetailsByTherapist(data: Partial<AuthorizationForm>): Promise<AppResponse<AuthorizationForm>> {
    const url = Util.apiAuthUrl("getAuthorizationFormDetailsByTherapist");
    return await axios.post<Partial<AuthorizationForm>, AppResponse<AuthorizationForm>>(url, data);
  }
  public static async updateAuthorizationForm(authFormType: string, data: Partial<AuthorizationForm>): Promise<AppResponse<AuthorizationForm>> {
    const url = Util.apiAuthUrl(`updateAuthorizationForm/${authFormType}`);
    return await axios.post<Partial<AuthorizationForm>, AppResponse<AuthorizationForm>>(url, data);
  }
  public static async getAuthorizationFormDetailsByAdmin(data: Partial<AuthorizationForm>): Promise<AppResponse<AuthorizationForm>> {
    const url = Util.apiAuthUrl("getAuthorizationFormDetailsByAdmin");
    return await axios.post<Partial<AuthorizationForm>, AppResponse<AuthorizationForm>>(url, data);
  }
  public static async getAllInsuranceCompanyList(): Promise<AppResponse<InsuranceCompanyTags[]>> {
    const url = Util.apiAuthUrl(`getAllInsuranceCompanyList`);
    return await axios.get<void, AppResponse<InsuranceCompanyTags[]>>(url);
  }
  public static async getIndividualFaxInfo(insuranceDocApprovalId?: string): Promise<AppResponse<FaxInfo>> {
    const url = Util.apiAuthUrl("getIndividualFaxInfo/" + insuranceDocApprovalId);
    return await axios.get<Partial<FaxInfo>, AppResponse<FaxInfo>>(url);
  }
  public static async authorizationFormSubmissionConfirmation(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("authorizationFormSubmissionConfirmation");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
}
