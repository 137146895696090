import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Modal, Button, ModalBody, Input } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/admin.css";
import { CommonService } from "../../services/CommonService";
import { Ethinicity } from "../../models/Ethnicity";
import { AdminService } from "../../services/AdminService";
import Swal from "sweetalert2";
import Spinner from "../../common/spinner/spinner";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import { InsuranceCompanyTags } from "../../models/InsuranceCompany";
import Close from "../../assets/images/icons/close.png";
import Select from "react-select";
import { Tags } from "../../common/custom-components/Tags";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

const ViewInsuranceCompanies: React.FC = () => {
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const LIMIT = 10;
  const [seeMore, setSeeMore] = useState(false);
  const [isOffset, setOffset] = useState(1);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false)
  const initialState: InsuranceCompanyTags = {
    insuranceCompany: '',
    states: [],
    _id: "",
  }
  const [insuranceData, setInsuranceData] = useState<InsuranceCompanyTags>(initialState)
  const [selectInsuaranceStates, setSelectedSates] = useState<any[]>([] as any[]);
  const [insuaranceList, setInsuarancesStates] = useState<any[]>([] as any[]);

  const TherapyState = [
    "All",
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "Ohio",
    "Montana",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington DC",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  useEffect(() => {
    getAllInsuranceCompanies();
    const option = TherapyState.map((state: any) => ({ value: state, label: state }));
    setInsuarancesStates(option);
  }, []);

  const getAllInsuranceCompanies = () => {
    setIsLoading(true);
    setOffset(1);
    InsuranceCompanyService.getAllInsuranceCompany(LIMIT, 1).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setSearchInsuranceCompanyTag(res.data);
        setIsLoading(false);
      } else {
        setSearchInsuranceCompanyTag([]);
        setIsLoading(false);
        setSeeMore(false);
      }
    });
  };

  const deleteInsuranceCompany = (insuranceCompanyId: string | undefined) => {
    if (insuranceCompanyId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete the insurance company?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          InsuranceCompanyService.deleteInsuranceCompany(insuranceCompanyId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Insurance company deleted!",
                confirmButtonColor: "#FD7F00",
              });
              getAllInsuranceCompanies();
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete insurance company!.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const updatedInsuranceCompany = (insuranceCompany: InsuranceCompanyTags | undefined) => {
    const statesArray = insuranceCompany?.states.map(state => ({
      label: state,
      value: state,
    })) ?? [];

    setSelectedSates(statesArray)
    setInsuranceData({
      ...insuranceData,
      insuranceCompany: insuranceCompany?.insuranceCompany ?? '',
      contractPrice: insuranceCompany?.contractPrice,
      coPayment: insuranceCompany?.coPayment,
      _id: insuranceCompany?._id ?? '',
      states: insuranceCompany?.states ?? [],
      fax: insuranceCompany?.fax,
      tradingPartnerServiceId: insuranceCompany?.tradingPartnerServiceId ?? "",
      organizationName: insuranceCompany?.organizationName ?? "",
      payerName: insuranceCompany?.payerName ?? "",
      link: insuranceCompany?.link ?? "",
    });
    setIsOpenUpdateModal(true)
  };

  const seeMoreFc = () => {
    setIsLoading(true);
    const updatedOffset = isOffset + 1;
    setOffset(updatedOffset);

    InsuranceCompanyService.getAllInsuranceCompany(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setSearchInsuranceCompanyTag(prev => {
          return [...new Set([...prev, ...res.data])];
        });

        setSearchInsuranceCompanyTag(prev => {
          return [...new Set([...prev, ...res.data])];
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSeeMore(false);
      }
    });
  };
  const toggleUpdateModal = () => {
    setInsuranceData({
      ...insuranceData,
      insuranceCompany: '',
      contractPrice: 0,
      coPayment: 0,
      _id: '',
      states: [],
      fax: '',
      tradingPartnerServiceId: '',
      organizationName: '',
      payerName: '',
      link: '',
    });
    setIsOpenUpdateModal(!isOpenUpdateModal)
  }
  const handleUpdateInsuarance = () => {
    if (!insuranceData?._id || insuranceData?._id?.length == 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid insuarance company Id",
        confirmButtonColor: "#FD7F00",
      });
      return false;
    }
    if (isNaN(Number(insuranceData?.coPayment)) || isNaN(Number(insuranceData?.coPayment))) {
      Swal.fire({
        icon: "error",
        title: "Contract Price and Co-Payment must be valid numbers",
        confirmButtonColor: "#FD7F00",
      });
      return false;
    }
    if (insuranceData._id && insuranceData?.insuranceCompany) {
      const stateList = selectInsuaranceStates?.map(states => states?.label) || [];
      setInsuranceData({
        ...insuranceData,
        states: stateList
      });
      const data = {
        insuranceCompanyId: insuranceData._id,
        updatedInsuranceCompany: insuranceData?.insuranceCompany,
        updatedContractPrice: insuranceData?.contractPrice,
        updatedCoPayment: insuranceData?.coPayment,
        states: stateList,
        fax: insuranceData?.fax,
        updatedTradingPartnerServiceId: insuranceData?.tradingPartnerServiceId,
        updatedOrganizationName: insuranceData?.organizationName,
        updatedPayerName: insuranceData?.payerName,
        updatedLink: insuranceData?.link,
      };
      InsuranceCompanyService.updateInsuranceCompany(data).then(res => {
        if (res.success) {
          toggleUpdateModal();
          getAllInsuranceCompanies();
          toast.success("Updated successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
          });
          const option = TherapyState.map((state: any) => ({ value: state, label: state }));
          setInsuarancesStates(option);
        } else {
          Swal.fire({
            icon: "error",
            title: res.error,
            confirmButtonColor: "#FD7F00",
          });
        }
      });
    }
  }
  const handleInsuaranceState = (value: any) => {
    const filteredStates = selectInsuaranceStates?.filter(state => state?.label === 'All');
    if (filteredStates?.length > 0) {
      toast.error("You have already selected all. Please remove it before adding new states.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      return;
    }
    const newFilteredStates = value?.filter((state: { label: string; }) => state?.label === 'All');

    const updatedTags = value?.map((tag: { label: any }) => {
      return { label: tag.label };
    });

    if (newFilteredStates?.length > 0 && updatedTags) {
      const filteredUpdatedTags = updatedTags?.filter((state: { label: string; }) => state?.label === 'All');
      setSelectedSates(filteredUpdatedTags);
    } else {
      if (updatedTags) {
        setSelectedSates(updatedTags);
        setInsuarancesStates(insuaranceList.filter(tagObj => !updatedTags.find((updatedTag: { label: any }) => updatedTag.label === tagObj.label)));
      }
    }
  };
  const removeInsuaranceStates = (removedtag: any) => {
    setSelectedSates(selectInsuaranceStates.filter(tagObj => tagObj.label !== removedtag));

    const addTags = { value: removedtag, label: removedtag };
    if (addTags) {
      setInsuarancesStates([...insuaranceList, addTags]);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"View Insurance Companies"} breadcrumbItem={"View Insurance Companies"} />
          <Row>
            <Col xl={12}>
              <NavLink
                to={"/create-insurance-company"}
                className="btn btn-primary relative mb-3"
                style={{
                  bottom: "0",
                  float: "right",
                }}
              >
                Create Insurance Company
              </NavLink>
            </Col>
          </Row>
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {!isLoading ? (
                    <div className="table-responsive">
                      {searchInsuranceCompanyTag !== undefined ? (
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Insurance Company</th>
                              <th scope="col">Contract price</th>
                              <th scope="col">Co-payment</th>
                              <th scope="col">States</th>
                              <th scope="col">Fax</th>
                              <th scope="col">Trading Partner Service ID</th>
                              <th scope="col">Organization Name</th>
                              <th scope="col">Payer Name</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchInsuranceCompanyTag &&
                              searchInsuranceCompanyTag.map((insuranceCompany, index) => (
                                <tr key={insuranceCompany._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{insuranceCompany.insuranceCompany}</td>
                                  <td>{insuranceCompany.contractPrice != null ? insuranceCompany.contractPrice : "_"}</td>
                                  <td>{insuranceCompany.coPayment != null ? insuranceCompany.coPayment : "_"}</td>
                                  <td>
                                    {insuranceCompany.states?.length === 0
                                      ? "-"
                                      : insuranceCompany.states?.map((state: string, index: number) => (
                                        <span className="badge bg-primary font-size-11 m-1 p-2" key={index}>
                                          {state}
                                        </span>
                                      ))}
                                  </td>
                                  <td>
                                    {(
                                      insuranceCompany?.fax != null && insuranceCompany?.fax != undefined) ? (
                                      <>{insuranceCompany?.fax}</>
                                    ) : (<>-</>)}
                                  </td>
                                  <td>{insuranceCompany.tradingPartnerServiceId != null ? insuranceCompany.tradingPartnerServiceId : "_"}</td>
                                  <td>{insuranceCompany.organizationName != null ? insuranceCompany.organizationName : "_"}</td>
                                  <td>{insuranceCompany.payerName != null ? insuranceCompany.payerName : "_"}</td>
                                  <td>
                                    <div className="d-flex p-0">
                                      <div className="">
                                        <button className="btn btn-sm btn-success" onClick={() => updatedInsuranceCompany(insuranceCompany)}>
                                          <i className="bx bx-edit"></i> Update
                                        </button>
                                      </div>
                                      <div className="px-2">
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => {
                                            deleteInsuranceCompany(insuranceCompany?._id);
                                          }}
                                        >
                                          <i className="bx bx-trash"></i> Delete
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          <div>No Insurance Company Lists</div>
                        </>
                      )}

                      {searchInsuranceCompanyTag.length == 0 && <div className="text-muted text-center">No Insurance Companies</div>}
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xl="12 mt30 text-center">
                  {seeMore && (
                    <button className="btn btn-warning" onClick={() => seeMoreFc()}>
                      See More
                    </button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={isOpenUpdateModal} toggle={toggleUpdateModal} centered className="modal-md">
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={toggleUpdateModal}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>
        <ModalBody className="ps-4 pe-4">
          <Row>
            <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Update Insurance </span>
          </Row>
          <Row>
            <Col xl="12">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Insurance Company</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Insurance Company"
                  name="Insurance Company"
                  value={insuranceData?.insuranceCompany || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, insuranceCompany: e.target.value })}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Contract Price</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Contract Price"
                  name="Contract Price"
                  value={insuranceData?.contractPrice || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, contractPrice: Number(e.target.value) })}
                  required
                />
              </div>
            </Col>
            <Col xl="6">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Co-Payment</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Co-payment"
                  name="Co-payment"
                  value={insuranceData?.coPayment || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, coPayment: Number(e.target.value) })}
                  required
                />
              </div>
            </Col>
            <Col xl="6">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Fax</h5>
              </div>
              <div className="card-heading mb-4">
                <PhoneInput
                  defaultCountry="US"
                  value={insuranceData?.fax}
                  onChange={e => setInsuranceData({ ...insuranceData, fax: e })}
                />
              </div>
            </Col>
            <Col xl="6">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Trading Partner Service ID</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Trading Partner Service ID"
                  name="Trading Partner Service ID"
                  value={insuranceData?.tradingPartnerServiceId || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, tradingPartnerServiceId: e.target.value })}
                  required
                />
              </div>
            </Col>
            <Col xl="6">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Organization Name"
                  name="Organization Name"
                  value={insuranceData?.organizationName || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, organizationName: e.target.value })}
                  required
                />
              </div>
            </Col>
            <Col xl="6">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Payer Name</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Payer Name"
                  name="Payer Name"
                  value={insuranceData?.payerName || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, payerName: e.target.value })}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Link</h5>
              </div>
              <div className="card-heading mb-4">
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Link"
                  name="Link"
                  value={insuranceData?.link || ""}
                  onChange={(e) => setInsuranceData({ ...insuranceData, link: e.target.value })}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">States</h5>
              </div>
              <div className="card-heading mb-4">
                <Select
                  onChange={(newValue: any) => handleInsuaranceState(newValue)}
                  value={selectInsuaranceStates}
                  isMulti
                  options={insuaranceList}
                  className="basic-multi-select"
                  placeholder="Choose a State"
                  display="none"
                  controlShouldRenderValue={false}
                  classNamePrefix="select"
                  isClearable={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="flex flex-wrap flex-row">
                {selectInsuaranceStates?.map((tagObj, id) => (
                  <Tags label={tagObj?.label} id={id} key={id} removeTag={removeInsuaranceStates} />
                ))}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-8"></Col>
            <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
              <button className="btn btn-warning create-client-custom2 float-right mr-2"
                onClick={() => handleUpdateInsuarance()}
              >Update</button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ViewInsuranceCompanies;
