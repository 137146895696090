import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Input, Label, UncontrolledTooltip, Modal, ModalBody, Button } from "reactstrap";
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";
import { toast } from "react-toastify";
import { ClientInsuranceDocs, FaxInfo, SubmissionApprovalStatus } from "src/models/ClientInsuranceDocs";
import ClinicalAssetmentModal from "../Popup/ClinicalAssessmentModal";
import TherapyPlanModal from "../Popup/TherapyPlanModal";
import { TherapistService } from "src/services/TherapistService";
import Swal from "sweetalert2";
import { AuthFormType } from "src/models/AuthorizationFormData";
import AmeriHealthModal from "../Popup/AmeriHealthModal";
import CarolinaCompleteHealthModal from "../Popup/CarolinaCompleteHealthModal";
import UnitedHealthCareModal from "../Popup/UnitedHealthCareModal";
import HealthBlueModal from "../Popup/HealthBlueModal";
import WellCareModal from "../Popup/WellCareModal";
import AmbetterModal from "../Popup/AmbetterModal";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { AppResponse } from "src/models/Response";
import { InsuranceCompanyTags } from "src/models/InsuranceCompany";
import Select from "react-select";
import Close from "../../assets/images/icons/close.png";
import { FiAlertCircle, FiAlertTriangle, FiCheckCircle, FiClock, FiPhone } from "react-icons/fi";
import moment from "moment";
import NewClinicalAssessmentModal from "../Popup/NewClinicalAssessmentModal";

const Approval: React.FC = () => {
  const limit = 10;
  const [insuranceDocs, setInsuranceDocs] = useState<ClientInsuranceDocs[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inProgress, setInProgress] = useState(true);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = useState<boolean>(false);
  const [showTherapyPlanModal, setShowTherapyPlanModal] = useState<boolean>(false);
  const [identifierId, setIdentifierId] = useState("");
  const [clientSearchableString, setClientSearchableString] = useState("");
  const [insuranceCompanyId, setInsuranceCompanyId] = useState("");
  const [showAmeriHealthModal, setshowAmeriHealthModal] = useState<boolean>(false);
  const [showCarolinaCompleteHealthModal, setshowCarolinaCompleteHealthModal] = useState<boolean>(false);
  const [showUnitedHealthCareModal, setshowUnitedHealthCareModal] = useState<boolean>(false);
  const [showHealthBlueModal, setshowHealthBlueModal] = useState<boolean>(false);
  const [showWellCareModal, setshowWellCareModal] = useState<boolean>(false);
  const [showAmbetterModal, setshowAmbetterModal] = useState<boolean>(false);
  const [InsuranceCompanyOptions, setInsuranceCompanyOptions] = useState([{ value: '', label: 'All' },]);
  const [searchableInsuranceCompany, setSearchableInsuranceCompany] = useState('');
  const [faxInfo, setFaxInfo] = useState<FaxInfo>();
  const [isOpenFaxView, setIsOpenFaxView] = useState(false);
  const [docApprovalId, setDocApprovalId] = useState("");
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);

  useEffect(() => {
    loadInitialData();
    getAllInsuranceCompanies();
  }, []);

  const loadInitialData = async () => {
    try {
        setInProgress(true);

        const data = {
          clientSearchableString: clientSearchableString,
          searchableInsuranceCompany: searchableInsuranceCompany
        };

        const res = await TherapistService.searchClientInsuranceDocumentsByTherapist(data, limit, 0);

        if (res && res?.success && res?.data) {
            setInsuranceDocs(res?.data);
            setSeeMore(res?.data.length >= limit);
            setIsLoading(false);
        } else {
            toast.error(res?.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
        setInProgress(false);
    } catch (error) {
        setInProgress(false);
        toast.error("Error loading data!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });
    }
  }

  const filterInsuranceDocument = async () => {
    try {
      setIsLoading(true);

      const data = {
        clientSearchableString: clientSearchableString,
        searchableInsuranceCompany: searchableInsuranceCompany
      };

      const res = await TherapistService.searchClientInsuranceDocumentsByTherapist(data, limit, 0);

      if (res && res?.success && res?.data) {
          setInsuranceDocs(res?.data);
          setSeeMore(res.data?.length >= limit);
      } else {
          toast.error("Failed to fetch insurance documents data!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
      }

      setIsLoading(false);
      toggleFilter();

    } catch (error) {
      setIsLoading(false);
      toast.error(`Error loading data!`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const seeMoreData = async () => {
    try{
      if (!inProgress) {
        setInProgress(true);
        setIsLazyLoading(true);

        const finalOffset = insuranceDocs?.length ?? 0;

        const data = {
          clientSearchableString: clientSearchableString,
          searchableInsuranceCompany: searchableInsuranceCompany
        };

        const res = await TherapistService.searchClientInsuranceDocumentsByTherapist(data, limit, finalOffset);

        if (res && res?.success && res?.data) {
          res?.data?.map((data: any) => {
            setInsuranceDocs(docs => [...docs, data]);
          });
          setSeeMore(res?.data?.length >= limit);
        }else{
          toast.error(`Error loading data!`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }

        setInProgress(false);
        setIsLazyLoading(false);
      } else {
        toast.error("Please wait!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });
      }
    } catch (error) {
      setIsLazyLoading(false);
      setInProgress(false);
      toast.error(`Error loading data`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  }

  const clinicalAssessmentForm = (identifierId: any) => {
    setIdentifierId(identifierId);
    setShowClinicalAssessmentModal(true);
  };

  const digitalAssessmentForm = (identifierId: any) => {
    setIdentifierId(identifierId);
    setShowNewClinicalAssessmentModal(true);
  };

  const therapyPlanForm = (identifierId: any) => {
    setIdentifierId(identifierId);
    setShowTherapyPlanModal(true);
  };

  const handleAuthorizationClick = (insuranceCompanyId: string, identifierId: string, authFormType: AuthFormType) => {
    setInsuranceCompanyId(insuranceCompanyId);
    setIdentifierId(identifierId);

    switch (authFormType) {
      case AuthFormType.AmeriHealthAuthForm:
        setshowAmeriHealthModal(true);
        break;
      case AuthFormType.CarolinaCompleteHealthAuthForm:
        setshowCarolinaCompleteHealthModal(true);
        break;
      case AuthFormType.UnitedHealthCareAuthForm:
        setshowUnitedHealthCareModal(true);
        break;
      case AuthFormType.HealthyBlueAuthForm:
        setshowHealthBlueModal(true);
        break;
      case AuthFormType.WellcareAuthForm:
        setshowWellCareModal(true);
        break;
      case AuthFormType.AmbetterAuthForm:
        setshowAmbetterModal(true);
        break;
      default:
        break;
    }
  };

  const submitForAdminApproval = (e: any) => {
    const clientId = e.currentTarget.getAttribute("data-client-id");
    const insuranceCompanyId = e.currentTarget.getAttribute("data-insurance-company-id");
    const therapistApprovalStatus = e.currentTarget.getAttribute("data-therapist-approval-status");

    const formattedStatus = therapistApprovalStatus === "APPROVED"
    ? "submit"
    : therapistApprovalStatus === "REJECTED"
    ? "reject"
    : '';

    Swal.fire({
        icon: "info",
        title: `Are you sure you want to ${formattedStatus} this?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            clientId: clientId,
            insuranceCompanyId: insuranceCompanyId,
            therapistApprovalStatus: therapistApprovalStatus
          }
          TherapistService.submitDocsForAdminApproval(data).then(res => {
              if (res.success) {
                loadInitialData();
                toast.success(`Successfull ${formattedStatus}!`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
              } else {
                  toast.error(res.error, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: 'foo-bar'
                  });
              }
          });
        }
    });
  }

  const handleChange = (selectedOption: any) => {
    setSearchableInsuranceCompany(selectedOption.value);
  };

  const customStyles = {
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '170px',
      overflowY: 'auto'
    })
  };

  const getAllInsuranceCompanies = () => {
    AuthorizationFormService.getAllInsuranceCompanyList().then((res: AppResponse<InsuranceCompanyTags[]>) => {
      if (res && res.success && res.data) {
        const dataList = res.data?.map((insuranceCompany: any) => ({
          value: insuranceCompany._id,
          label: insuranceCompany.insuranceCompany,
        }));
        InsuranceCompanyOptions.push(...dataList);
      }
    });
  };

  const copyToClipboard = (email: any) => {
    navigator.clipboard.writeText(email)
    .then(() => {
      toast.success("Email copied to clipboard!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    })
    .catch(() => {
      toast.error("Email is not copied. Try again!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    });
  };

  const handleFaxInfoView = async (insuranceDocApprovalId: any) => {
    if (insuranceDocApprovalId != undefined) {
      setIsLoading(true);
      setDocApprovalId(insuranceDocApprovalId);

      const result = await fetchFaxInformation(insuranceDocApprovalId);
      if (result.success) {
        setFaxInfo(result.data);
      }
      setIsLoading(false);
      setIsOpenFaxView(true);
    }
  };

  const fetchFaxInformation = async (insuranceDocApprovalId: string) => {
    try {
      const res = await AuthorizationFormService.getIndividualFaxInfo(insuranceDocApprovalId);
      if (res && res?.success && res?.data) {
        return { success: true, data: res.data };
      } else {
        toast.error("Encountered an error while retrieving the fax details!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const toggleModalForFaxView = () => {
    setDocApprovalId("");
    setFaxInfo(undefined);
    setIsOpenFaxView(!isOpenFaxView);
  }

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };

  const getFaxStatusColor = (status: string | undefined) => {
    switch (status) {
      case 'Queued':
        return 'text-warning';
      case 'Sent':
        return 'text-success';
      case 'SendingFailed':
        return 'text-danger';
      default:
        return 'text-warning';
    }
  };

  const handleAuthorizationConfirmation = (insuranceCompanyId: string, clientId: string, link: string) => {
    if (!insuranceCompanyId || !clientId || typeof link !== "string" || link.trim() === "") {
      toast.error("We detected an issue. Please refresh the page to continue.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }
  
    try {
      Swal.fire({
        icon: "question",
        title: "Prior Authorization Required",
        html: `
          <p>This insurance company does not have a prior authorization form available via mylavni.com. You will need to submit the form manually.</p>
          <p><b>Did you already submit it to the insurance company's website?</b></p>
        `,
        showCancelButton: true,
        confirmButtonColor: "#50a5f1",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            const data: any = {
              insuranceCompanyId: insuranceCompanyId,
              clientId: clientId,
              link: link,
            };
            const res = await AuthorizationFormService.authorizationFormSubmissionConfirmation(data);
            if (res?.success) {
              loadInitialData();
              toast.success(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          } catch (error) {
            toast.error("An error occurred while submitting confirmation. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "Prior Authorization Required",
            html: `
              <p>This insurance company does not have a prior authorization form available via mylavni.com. You will need to submit the form manually.</p>
              <p><b>Would you like to proceed?</b></p>
              <p>Click the "Proceed to Portal" button. You will be redirected to the insurance company's portal or service page.</p>
              <p>Once you have completed the form, click "Proceed" again to continue.</p>
            `,
            showCancelButton: true,
            confirmButtonColor: "#50a5f1",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Proceed to Portal",
          }).then(result => {
            if (result.isConfirmed) {
              try {
                window.open(link, "_blank");
                Swal.close();
              } catch (error) {
                toast.error(`Unable to open the portal link. Please try again. Error Details: ${error}`, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            }
          });
        }
      });
    } catch (error) {
      toast.error(`An unexpected error occurred. Please try again later. Error Details: ${error}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  

  return (
    <>
      <React.Fragment>
        {showClinicalAssessmentModal && <ClinicalAssetmentModal setShowModal={setShowClinicalAssessmentModal} showModal={showClinicalAssessmentModal} identifierId={identifierId} isAdmin={false} isFromMeeting={false} setModalRefresher={null} />}
        {showNewClinicalAssessmentModal && <NewClinicalAssessmentModal setShowModal={setShowNewClinicalAssessmentModal} showModal={showNewClinicalAssessmentModal} identifierId={identifierId} isFromMeeting={false} isAdmin={false} setModalRefresher={null} aiGeneratedAssessmentData={null} isMeeting={false} />}
        {showTherapyPlanModal && <TherapyPlanModal setShowModal={setShowTherapyPlanModal} showModal={showTherapyPlanModal} identifierId={identifierId} isAdmin={false} isFromMeeting={false} isCreatingNewForm={false} setModalRefresher={null} />}
        {showAmeriHealthModal && <AmeriHealthModal setShowModal={setshowAmeriHealthModal} showModal={showAmeriHealthModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={false} />}
        {showCarolinaCompleteHealthModal && <CarolinaCompleteHealthModal setShowModal={setshowCarolinaCompleteHealthModal} showModal={showCarolinaCompleteHealthModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={false} />}
        {showUnitedHealthCareModal && <UnitedHealthCareModal setShowModal={setshowUnitedHealthCareModal} showModal={showUnitedHealthCareModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={false} />}
        {showHealthBlueModal && <HealthBlueModal setShowModal={setshowHealthBlueModal} showModal={showHealthBlueModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={false} />}
        {showWellCareModal && <WellCareModal setShowModal={setshowWellCareModal} showModal={showWellCareModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={false} />}
        {showAmbetterModal && <AmbetterModal setShowModal={setshowAmbetterModal} showModal={showAmbetterModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={false} />}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Insurance Documents"} breadcrumbItem={"Approval Queue"} />
            <Card className="m-h">
              <CardBody>

                <Row className="d-flex justify-content-between align-items-center">
                  <div className="d-flex admin-filter">
                    <button className="btn btn-sm mr-2" onClick={toggleFilter}>Filter <i className='bx bx-filter-alt'></i></button>
                  </div>
                </Row>

                <Row>
                  <Col sm="12" className="mt10">
                    <div className="table-responsive" style={{ minHeight: "70vh" }}>
                    {isLoading ? (
                      <div style={{height: 500}}>
                          <Spinner />
                      </div>
                      ) : (
                      <>
                        {insuranceDocs.length > 0 ? (
                          <>
                            <div className="d-flex justify-content-center p-1">
                              <span>Instructions for status: </span>&nbsp;
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-phone-check-outline doc-submitted submission-common"></i> <i className='bx bx-info-circle' id="w1"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w1"}>
                                  Session is completed.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common"> </i><i className='bx bx-info-circle' id="w2"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w2"}>
                                  Session is not yet completed.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-note-outline doc-submitted submission-common"></i> <i className='bx bx-info-circle' id="w3"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w3"}>
                                  Form is filled by you.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-note-remove-outline doc-no-submitted submission-common"></i> <i className='bx bx-info-circle' id="w4"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w4"}>
                                  Form is not yet filled by you.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-shield-link-variant-outline doc-submitted submission-common"></i> <i className='bx bx-info-circle' id="w3"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w3"}>
                                  Authorization Form is submitted from insurance company portal or service page.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-shield-link-variant-outline doc-no-submitted submission-common"></i> <i className='bx bx-info-circle' id="w4"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w4"}>
                                  Authorization Form is not yet submitted from insurance company portal or service page.
                                </UncontrolledTooltip>
                              </div>
                            </div><br />

                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Client</th>
                                  <th scope="col">Meeting Count</th>
                                  <th scope="col">Clinical Assessment</th>
                                  <th scope="col">Therapy Plan</th>
                                  <th scope="col">Authorization Form</th>
                                  <th scope="col">Insurance Details</th>
                                  <th scope="col">Submission Status</th>
                                  <th scope="col">Submit for Approval</th>
                                  <th scope="col">Admin Approval</th>
                                  <th scope="col">Fax Status</th>
                                  <th scope="col">Fax Info</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  insuranceDocs && insuranceDocs.flatMap((insuranceDoc, docIndex) =>
                                    insuranceDoc.insuranceCompanies.map((insuranceCompany, index) => (
                                      <tr key={`${insuranceDoc.client._id}-${insuranceCompany._id}`} style={{borderBottom: '2px solid #ddd'}}>
                                        {index === 0 && (
                                          <>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {docIndex + 1}
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {insuranceDoc.client.firstname ?? ""}&nbsp;{insuranceDoc.client.lastname ?? ""}<br />
                                              {insuranceDoc.client.email ? insuranceDoc.client.email.substring(0, 10) + "..." : ""}
                                              <button onClick={() => copyToClipboard(insuranceDoc.client.email)} title={`Copy Email Address: ${insuranceDoc?.client?.email ?? ''}`}><i className="mdi mdi-content-copy" title="Copy Email Address"></i></button>
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {insuranceDoc?.meetingCount}
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {
                                                (insuranceDoc?.meetingCount == 0) ? (
                                                  <i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common" title="First session is not yet completed."></i>
                                                ) : (
                                                  <i className="mdi mdi-phone-check-outline doc-submitted submission-common" title="First session is completed."></i>
                                                )
                                              }&nbsp;
                                              {
                                                insuranceDoc?.meetingCount > 0 && (
                                                  (insuranceDoc?.clinicalAssessment === null && insuranceDoc?.digitalAssessment === null) ? (
                                                    <i className="mdi mdi-note-remove-outline doc-no-submitted submission-common" title="Clinical assessment form is not yet submitted."></i>
                                                  ) : (
                                                    (insuranceDoc?.clinicalAssessment !== null) ? (
                                                      <button
                                                        className="insurance-approval-icon-button"
                                                        onClick={() => {
                                                          clinicalAssessmentForm(insuranceDoc.client._id);
                                                        }}
                                                        disabled={showClinicalAssessmentModal}
                                                        title="View Clinical Assessment Form"
                                                      >
                                                        <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="insurance-approval-icon-button"
                                                        onClick={() => {
                                                          digitalAssessmentForm(insuranceDoc.client._id);
                                                        }}
                                                        disabled={showNewClinicalAssessmentModal}
                                                        title="View Clinical Assessment Form"
                                                      >
                                                        <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                      </button>
                                                    )
                                                  )
                                                )
                                              }
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {
                                                (insuranceDoc?.meetingCount >= 2) ? (
                                                  <i className="mdi mdi-phone-check-outline doc-submitted submission-common" title="Second session is completed."></i>
                                                ) : (
                                                  <i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common" title="Second session is not yet completed."></i>
                                                )
                                              }&nbsp;
                                              {
                                                insuranceDoc?.meetingCount > 1 && (
                                                  (insuranceDoc?.therapyPlan === null) ? (
                                                    <i className="mdi mdi-note-remove-outline doc-no-submitted submission-common" title="Therapy plan form is not yet submitted."></i>
                                                  ) : (
                                                    <button
                                                      className="insurance-approval-icon-button"
                                                      onClick={() => {
                                                        therapyPlanForm(insuranceDoc?.therapyPlan);
                                                      }}
                                                      disabled={showTherapyPlanModal}
                                                      title="View Therapy Plan Form"
                                                    >
                                                      <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                    </button>
                                                  )
                                                )
                                              }
                                            </td>
                                          </>
                                        )}
                                        <td valign="middle">
                                          {
                                            (insuranceDoc?.meetingCount >= 2) ? (
                                              <i className="mdi mdi-phone-check-outline doc-submitted submission-common" title="Second session is completed."></i>
                                            ) : (
                                              <i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common" title="Second session is not yet completed."></i>
                                            )
                                          }&nbsp;
                                          {
                                            insuranceDoc?.meetingCount > 1 && (
                                              (!insuranceCompany?.authorizationFormAvailability && insuranceCompany.link && insuranceCompany.link.trim() !== "") ? (
                                                (insuranceCompany?.authorizationForms === null) ? (
                                                  <>
                                                  <button
                                                    className="insurance-approval-icon-button btn btn-info btn-sm"
                                                    title="Submit authorization form from the insurance company's portal."
                                                    onClick={() => handleAuthorizationConfirmation(
                                                      insuranceCompany._id,
                                                      insuranceDoc.client._id,
                                                      insuranceCompany.link
                                                    )}
                                                  >
                                                    Proceed
                                                  </button>
                                                  <i></i>
                                                </>
                                                ) : (
                                                  <>
                                                    <i className="mdi mdi-shield-link-variant-outline doc-submitted submission-common" title="Authorization form is already submitted via insurance company portal."></i>
                                                  </>
                                                )
                                              ) :
                                              (insuranceCompany?.authorizationForms === null) ? (
                                                <i className="mdi mdi-note-remove-outline doc-no-submitted submission-common" title="Authorization form is not yet submitted."></i>
                                              ) : (
                                                <button
                                                  className="insurance-approval-icon-button"
                                                  onClick={() => handleAuthorizationClick(
                                                    insuranceCompany._id,
                                                    insuranceDoc.client._id,
                                                    insuranceCompany.authFormType
                                                  )}
                                                  disabled={showAmeriHealthModal || showCarolinaCompleteHealthModal || showUnitedHealthCareModal || showHealthBlueModal || showWellCareModal || showAmbetterModal}
                                                  title="View Authorization Form"
                                                >
                                                  <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                </button>
                                              )
                                            )
                                          }
                                        </td>
                                        <td valign="middle">
                                          {insuranceCompany.name}<br />
                                          {insuranceCompany.fax ?? <div className="d-flex mb-1"><span className="cancel-2" title="Fax number is NOT FOUND for this insurance company.">No Fax</span></div>}
                                          {insuranceCompany.authorizationFormAvailability
                                            ? <div className="d-flex"><span className="verified-2" title="Prior authorization request form is available for this insurance company.">Available</span></div>
                                            : (insuranceCompany.authorizationFormAvailability === false || insuranceCompany.authorizationFormAvailability === undefined)
                                            ? <div className="d-flex"><span className="cancel-2" title="Prior authorization request form is NOT AVAILABLE for this insurance company.">Unavailable</span></div>
                                            : "-"
                                          }
                                          {insuranceCompany.isPrimary
                                            ? <div className="d-flex"><span className="verified-2" title="This is primary insurance company for this client.">Primary</span></div>
                                            : (!insuranceCompany.isPrimary)
                                            ? <div className="d-flex"><span className="cancel-2" title="This is secondary insurance company for this client.">Secondary</span></div>
                                            : (insuranceCompany.isPrimary === undefined)
                                            ? <>-</> : <></>
                                          }
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceDoc?.meetingCount >= 2 &&
                                            (insuranceDoc?.clinicalAssessment != null || insuranceDoc?.digitalAssessment != null) &&
                                            insuranceDoc?.therapyPlan != null && insuranceCompany?.authorizationForms != null
                                            ) ? (
                                            <>
                                              {
                                                insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.APPROVED && (
                                                  <div className="d-flex">
                                                    <span className="verified-2">Approved by you</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.REJECTED && (
                                                  <div className="d-flex">
                                                    <span className="cancel-2">Rejected by you</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.therapistApprovalStatus === null && (
                                                  <div className="d-flex">
                                                    <span className="pending-2">Pending</span>
                                                  </div>
                                                )
                                              }
                                            </>
                                          ) : (<>-</>)}
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceDoc?.meetingCount >= 2 &&
                                            (insuranceDoc?.clinicalAssessment != null || insuranceDoc?.digitalAssessment != null) &&
                                            insuranceDoc?.therapyPlan != null &&
                                            insuranceCompany?.authorizationForms != null
                                          ) ? (
                                            <>
                                              <button
                                                className={`btn btn-sm mr-2 ${
                                                  insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.APPROVED
                                                    ? "btn-danger"
                                                    : insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.REJECTED
                                                    ? "btn-success"
                                                    : "btn-success"
                                                }`}
                                                onClick={submitForAdminApproval}
                                                data-client-id={insuranceDoc.client._id}
                                                data-insurance-company-id={insuranceCompany._id}
                                                data-therapist-approval-status={
                                                  insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.APPROVED
                                                    ? "REJECTED"
                                                    : insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.REJECTED
                                                    ? "APPROVED"
                                                    : "APPROVED"
                                                }
                                                disabled={insuranceCompany?.adminApprovalStatus === SubmissionApprovalStatus.APPROVED}
                                              >
                                                {insuranceCompany?.insuranceDocApprovalId !== null
                                                  ? insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.APPROVED
                                                    ? "Reject"
                                                    : "Re-Submit"
                                                  : "Submit"
                                                }
                                              </button>
                                            </>
                                          ) : (<button
                                                  className="btn btn-sm btn-secondary mr-2"
                                                  disabled
                                                >
                                                  Not Allowed
                                              </button>)
                                          }
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceDoc?.meetingCount >= 2 &&
                                            (insuranceDoc?.clinicalAssessment != null || insuranceDoc.digitalAssessment != null) &&
                                            insuranceDoc?.therapyPlan != null && insuranceCompany?.authorizationForms != null
                                          ) ? (
                                            <>
                                              {
                                                insuranceCompany?.adminApprovalStatus === SubmissionApprovalStatus.APPROVED && (
                                                  <div className="d-flex">
                                                    <span className="verified-2">Approved</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.adminApprovalStatus === SubmissionApprovalStatus.REJECTED && (
                                                  <div className="d-flex">
                                                    <span className="cancel-2">Rejected</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                (insuranceCompany?.adminApprovalStatus === null || insuranceCompany?.adminApprovalStatus === undefined) && (
                                                  <div className="d-flex">
                                                    <span className="pending-2">Pending</span>
                                                  </div>
                                                )
                                              }
                                            </>
                                          ) : (<>-</>)}
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceCompany?.messageId && insuranceCompany?.messageStatus) ? (
                                            <>
                                              {
                                                insuranceCompany?.messageStatus === "Queued" && (
                                                  <div className="d-flex">
                                                    <span className="pending-2" title="Fax on queued. Check back later for the updated status.">Queued</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.messageStatus === "Sent" && (
                                                  <div className="d-flex">
                                                    <span className="verified-2" title="Fax successfully sent.">Sent</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.messageStatus === "SendingFailed" && (
                                                  <div className="d-flex">
                                                    <span className="cancel-2" title="Fax sending failed. The admin will retry sending the fax.">Failed</span>
                                                  </div>
                                                )
                                              }
                                            </>
                                          ) : (<>-</>)}
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceCompany?.messageId && insuranceCompany?.messageStatus) ? (
                                              <>
                                                <button
                                                  className="btn btn-sm btn-success"
                                                  onClick={() => {
                                                    handleFaxInfoView(insuranceCompany.insuranceDocApprovalId);
                                                  }}
                                                  title="View Fax Information"
                                                >View</button>
                                              </>
                                            ) : (<>-</>)}
                                        </td>
                                      </tr>
                                    ))
                                  )
                                }
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <div>No Client&apos;s Insurance Documents To Show.</div>
                        )}
                      </>
                    )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12 mt30 text-center">
                    {seeMore && (
                      <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => seeMoreData()}>
                        {isLazyLoading ? "Loading..." : "See More"}
                      </button>
                    )}
                  </Col>
              </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        <Offcanvas
          className="offcanvas-end2"
          isOpen={isFilterOptions}
          direction="end"
          toggle={toggleFilter}>
          <OffcanvasHeader toggle={toggleFilter}>
            Filter Insurance Documents Queue
          </OffcanvasHeader>
          <OffcanvasBody>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by client name or email</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search client (ex: first name, last name or email)"
                    type="text"
                    onChange={e => setClientSearchableString(e.target.value)}
                    value={clientSearchableString}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Insurance Company</Label>
                  <Select
                    styles={customStyles}
                    id="insuranceCompanyName"
                    options={InsuranceCompanyOptions}
                    onChange={handleChange}
                    value={InsuranceCompanyOptions.find(option => option.value === searchableInsuranceCompany)}
                    placeholder="Select a Insurance Company"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12}>
                <button className={`btn ${isLoading ? "btn-primary" : "btn-success"} w-100`} onClick={() => {
                    if(!isLoading){
                      filterInsuranceDocument();
                    }else{
                      toast.error(`Please wait !`, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo-bar",
                      });
                    }
                  }}
                >
                  {isLoading ? "Loading..." : "Search"}
                </button>
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>

        <Modal isOpen={isOpenFaxView} toggle={toggleModalForFaxView} centered className="modal-md" backdrop="static" keyboard={false}>
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleModalForFaxView}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="p-4">
            <h2 className="text-center mb-4">Fax Information</h2>
            <div className="bg-light p-4 rounded-3 shadow-sm">
              <Row className="mb-3">
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <FiPhone className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">From</small>
                      <p className="mb-0 fw-bold">Lavni, Inc.</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <FiPhone className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">To</small>
                      <p className="mb-0 fw-bold">{faxInfo?.toPhoneNumber || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <FiClock className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">Created Timestamp</small>
                      <p className="mb-0">{formatDate(faxInfo?.creationTime)}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <FiClock className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">Modified Timestamp</small>
                      <p className="mb-0">{formatDate(faxInfo?.lastModifiedTime)}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    {
                      faxInfo?.messageStatus === 'Sent' ? (
                        <FiCheckCircle className={`me-2 ${getFaxStatusColor(faxInfo?.messageStatus)}`} size={20} />
                      ) : faxInfo?.messageStatus === 'Queued' ? (
                        <FiAlertCircle className={`me-2 ${getFaxStatusColor(faxInfo?.messageStatus)}`} size={20} />
                      ) : (
                        <FiAlertTriangle className={`me-2 ${getFaxStatusColor(faxInfo?.messageStatus)}`} size={20} />
                      )
                    }
                    <div>
                      <small className="text-muted">Status</small>
                      <p className={`mb-0 fw-bold ${getFaxStatusColor(faxInfo?.messageStatus)}`}>
                        {faxInfo?.messageStatus || 'N/A'}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    {faxInfo?.readStatus === 'Read' ? (
                      <FiCheckCircle className="me-2 text-success" size={20} />
                    ) : (
                      <FiAlertCircle className="me-2 text-warning" size={20} />
                    )}
                    <div>
                      <small className="text-muted">Read Status</small>
                      <p className="mb-0 fw-bold">{faxInfo?.readStatus || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>

      </React.Fragment>
    </>
  );
};

export default Approval;