import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import UserContext from "../context/UserContext";
import { Role } from "../models/Role";
import { RouteName } from "../RouteName";
import { useHistory } from "react-router-dom";
import { PremiumStatus } from "src/models/Client";

const VerifyRole: React.FC<{ allow?: Role[]; children: any }> = ({ allow, children }): any => {
  const [user] = useContext(UserContext);
  const role = user && user.role;
  const token = localStorage.getItem("token");
  const insurance = localStorage.getItem("insuranceCompany");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      const currentUser = parseJwt(token);
      if (currentUser && currentUser.exp * 1000 < Date.now()) {
        logout();
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      user?.role == Role.THERAPIST &&
      (user?.email == null ||
        user?.email == undefined ||
        user?.email == "" ||
        user?.firstname == null ||
        user?.firstname == undefined ||
        user?.firstname == "" ||
        user?.lastname == null ||
        user?.lastname == undefined ||
        user?.lastname == "" ||
        user?.gender == null ||
        user?.gender == undefined ||
        user?.gender == "" ||
        user?.username == null ||
        user?.username == undefined ||
        user?.username == "" ||
        user?.dateOfBirth == null ||
        user?.dateOfBirth == undefined ||
        user?.dateOfBirth.toString() == "" ||
        user?.timeZone == null ||
        user?.timeZone == undefined ||
        user?.timeZone == "" ||
        user?.zipCode == null ||
        user?.zipCode == undefined ||
        user?.zipCode == "")
    ) {
      history.push(RouteName.THERAPISTPERSONALINFORMATIONONBOARDINGPAGE);
    }
  }, []);


  useEffect(() => {
    if (
      user?.role == Role.CLIENT &&
      (user?.stripeCustomerId == "" || user?.stripeCustomerId == undefined || user?.stripeCustomerId == null) && (user?.insuranceId?._id == undefined || user?.insuranceId?._id == null) && (insurance !== null) && !user?.freeUser
    ) {
      history.push("/add-insurance");
    } else if (user?.role == Role.CLIENT &&
      (user?.stripeCustomerId == "" || user?.stripeCustomerId == undefined || user?.stripeCustomerId == null) && (user?.insuranceId?._id == undefined || user?.insuranceId?._id == null) && (insurance == null)  && !user?.freeUser) {
      history.push("/choose-plan");
    }
  }, []);


  const parseJwt = (token: string | null) => {
    if (token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  if (!role) {
    return <div></div>;
  } else if (allow && allow.includes(role)) {
    return <>{children}</>;
  } else {
    if (role == Role.SUPER_ADMIN || role == Role.SUB_ADMIN) {
      return <Route exact path={RouteName.NEWDASHBOARD} />;
    } else {
      switch (role) {
        case Role.CLIENT:
          return <Route exact path={RouteName.DASHBOARD} />;
        case Role.THERAPIST:
          return <Route exact path={RouteName.DASHBOARD} />;
      }
    }
  }
};

export default VerifyRole;
