import React from "react";
import Creatable from "react-select/creatable";
import { Tags } from "../../../../common/custom-components/Tags";
import { ExperienceTag, SelectedExperienceTag } from "../../../../models/ExperienceTag";
import { HashTag, SelectedHashTag } from "../../../../models/HashTag";

interface PublicClientFiltersProps {
  experienceTags: ExperienceTag[];
  selectedExperienceTags: SelectedExperienceTag[];
  hashTags: HashTag[];
  selectedHashTags: SelectedHashTag[];
  handleExperienceTags: (value: any) => void;
  removeExperienceTag: (value: string) => void;
  handleHashTags: (value: any) => void;
  removeHashTag: (value: string) => void;
  setQuery: () => void;
}

const PublicClientFilters: React.FC<PublicClientFiltersProps> = ({
  experienceTags,
  selectedExperienceTags,
  hashTags,
  selectedHashTags,
  handleExperienceTags,
  removeExperienceTag,
  handleHashTags,
  removeHashTag,
  setQuery,
}: PublicClientFiltersProps) => {
  const DropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const creatableComponents = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
  };

  return (
    <>
      <div className="text-muted f-18 mb-3">Filter through the Articles</div>
      <div className="search-box">
        <p className="text-muted mb-2">Search by tags</p>
        <div className="position-relative">
          <Creatable
            options={experienceTags}
            isMulti
            components={creatableComponents}
            onChange={(newValue: any) => handleExperienceTags(newValue)}
            value={selectedExperienceTags}
            display="none"
            className="creatableEdit"
            placeholder="Choose an area"
            controlShouldRenderValue={false}
            isClearable={false}
          />
          <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2 mb-3">
            {selectedExperienceTags &&
              selectedExperienceTags.map((tag, id) => {
                return <Tags label={tag.label} id={id} key={id} removeTag={removeExperienceTag} />;
              })}
          </div>
        </div>
      </div>

      <div className="search-box mb-3">
        <p className="text-muted mb-2">Search by hashtags</p>
        <div className="position-relative">
          <Creatable
            options={hashTags}
            isMulti
            components={creatableComponents}
            onChange={(newValue: any) => handleHashTags(newValue)}
            value={selectedHashTags}
            display="none"
            className="creatableEdit"
            placeholder="Choose an area"
            controlShouldRenderValue={false}
            isClearable={false}
          />
          <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2 mb-3">
            {selectedHashTags &&
              selectedHashTags.map((tag, id) => {
                return <Tags label={tag.label} id={id} key={id} removeTag={removeHashTag} />;
              })}
          </div>
        </div>
      </div>

      <div>
        <button className="btn btn-primary .btn.block w-100" onClick={setQuery}>
          Apply
        </button>
      </div>
    </>
  );
};

export default PublicClientFilters;
