import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, CardBody, Row, Col, NavLink } from "reactstrap";
import Swal from "sweetalert2";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { FeedbackList, SessionFeedback } from "../../models/Feedback";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";
import FeedbackContext from "src/context/UserFeedbackCount";

const ViewSessionFeedback: React.FC = () => {
  const [feedback, setFeedback] = useState<FeedbackList[]>([]);
  const [userFeedbackCount, setUserFeedbackCount] = useContext(FeedbackContext);
  const [userCount, setUserCount] = useState(20);
  const [offset, setOffset] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [sessionFeedback, setSessionFeedback] = useState<SessionFeedback[]>([]);

  const LIMIT = 20;

  useEffect(() => {
    getAllFeedbacks();
  }, []);

  const getAllFeedbacks = () => {
    setIsLoading(true)
    AdminService.getAllSessionFeedbacks(LIMIT, offset).then(res => {
      if(res.success){
        setSessionFeedback(res.data);
        setUserCount(res.data.length);
        setIsLoading(false);
      } else {
        setSessionFeedback([]);
        setHasMore(false);
        setIsLoading(false)
      }
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;
    setOffset(updatedSkip);
    setIsLoading(true);
    AdminService.getAllSessionFeedbacks(LIMIT, updatedSkip).then(res => {
      if (res.success) {
        res.data.map((feedback: any) => {
          setSessionFeedback(feedbacks => [...feedbacks, feedback]);
        });
        setUserCount(res.data.length);
      }
      setIsLoading(false);
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Session Feedback"} breadcrumbItem={"Session Feedback"} />
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {sessionFeedback !== undefined && sessionFeedback.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Created By</th>
                              <th scope="col">Created Date</th>
                              <th scope="col" style={{maxWidth: "200px"}}>Feedback</th>
                              <th scope="col">Client</th>
                              <th scope="col">Therapist</th>
                              <th scope="col">Session Start Time</th>
                              <th scope="col">Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sessionFeedback &&
                              sessionFeedback.map((feedback: any, index: number) => (
                                <tr key={feedback?._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                      {feedback?.createdByDetails[0]?.firstname} {feedback?.createdByDetails[0]?.lastname}
                                  </td>
                                  <td>{moment(feedback?.createdAt).format("YYYY-MM-DD hh:mm A")}</td>
                                  <td style={{ maxWidth: "400px"}}>{feedback?.feedback}</td>
                                  <td style={{minWidth: "120px"}}>
                                    {feedback?.clientDetails[0]?.firstname} {feedback?.clientDetails[0]?.lastname} 
                                  </td>
                                  <td style={{minWidth: "120px"}}>
                                    {feedback?.therapistDetails[0]?.firstname} {feedback?.therapistDetails[0]?.lastname} 
                                  </td>
                                  <td style={{minWidth: "120px"}}>
                                    {moment(feedback?.meetingDetails[0]?.createdAt).format("YYYY-MM-DD hh:mm A")} 
                                  </td>
                                  <td style={{minWidth: "120px"}}>
                                    <StarRating rate={feedback?.rate} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {userCount >= LIMIT && (
                        <>
                          <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                            See More
                          </button>
                        </>
                      )}
                      {userCount == 0 && <span>Feedbacks not found....</span>}
                    </>
                  ) : (
                    <>
                      {isLoading ? <Spinner /> : <div>Feedbacks not found.</div>}</>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

interface StarRatingProps {
  rate: number;
}

const StarRating : React.FC<StarRatingProps> = ({ rate }) => {
  const stars = [];

  for (let i = 0; i < rate; i++) {
    stars.push(
      <i
        key={i}
        className="fa fa-star"
        aria-hidden="true"
        style={{
          color: "#F4C542",
          cursor: "pointer",
          fontSize: "15px",
        }}
      ></i>
    );
  }

  return <>{stars}</>;
};

export default ViewSessionFeedback;

