import React, { useCallback, useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardText, Button, CardBody, ModalBody, Modal } from "reactstrap";
import { Ethinicity } from "src/models/Ethnicity";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Color } from "three";
import "../../assets/css/therapistList.css";
import { ClientService } from "src/services/ClientService";
import { DiscoverTherapist, Therapist } from "src/models/Therapist";
import Swal from "sweetalert2";
import { FriendRequest } from "src/models/FriendRequest";
import { Util } from "src/Util";
import { ExperienceTag, SelectedExperienceTag } from "src/models/ExperienceTag";
import { ArticleService } from "src/services/ArticleService";
import { TherapistService } from "src/services/TherapistService";
import { CommonService } from "src/services/CommonService";
import { Profession } from "src/models/Profession";
import Spinner from "../spinner/spinner";
import MainDetailsContext from "src/context/MainDetailsContext";
import { environment } from "src/environment/environment";
import Close from "../../assets/images/icons/close.png";

type StateMap = {
  [key: string]: string;
};

const TherapistList: React.FC = (props: any) => {
  const [selectedName, setSelectedName] = useState("");
  const [gender, setGender] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [clientFriendRequests, setClientFriendRequests] = useState([] as FriendRequest[]);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [therapistList, setTherapistList] = useState([] as Therapist[]);
  const [therapist, setTherapist] = useState({} as Therapist);
  const [therapistVideo, setTherapistVideo] = useState("");
  const [nextEventReady, setNextEventReady] = useState(true);
  const [modalcentervideo, setModalCenterVideo] = useState<boolean>(false);
  const [selectedExpTags, setSelectedExpTags] = useState<any[]>([] as SelectedExperienceTag[]);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [sentTherapists, setSentTherapists] = useState([] as Therapist[]);
  const [seeMoreCount, setSeeMoreCount] = useState(5);
  const [offset, setOffset] = useState(1);
  const LIMIT = 5;
  const [selectedProfession, setSelectedProfession] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const insurance = localStorage.getItem("insuranceCompany");
  // const state = localStorage.getItem("state");
  const [newState,setNewState] = useState(localStorage.getItem("state")?localStorage.getItem("state"):null)
  // const serviceData = localStorage.getItem("service");
  // const therapyType = localStorage.getItem("therapyType");
  const [isView, setIsView] = useState<boolean>(true);
  const [openSelectStateModal, setOpenSelectStateModal] = useState<boolean>(false);
  const history = useHistory();
  const [
    therapistData,
    setTherapistData,
    publicAppoinmentData,
    setPublicAppoinmentData,
    userDataMainContext,
    setUserDataMainContext,
    passwordMainContext,
    setPasswordMainContext,
    clickSkipButton,
    setClickSkipButton] = useContext(MainDetailsContext)
  const experienceTagSymptomsData: any = localStorage.getItem("experienceTag");
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<string>("");
  const [selectedInsuranceName, setSelectedInsuranceName] = useState<string>("");
  const [selectedExperienceTagSymptoms, setSelectedExperienceTagSymptoms] = useState<any>(null);
  const [displayedSymptomsTest, setDisplayedSymptomsTest] = useState<string>("");

  const [stateList, setStateList] = useState<string[]>([]);
  const [selectedState, setSelectState] = useState("");

  const stateMap: StateMap = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    DC: 'Washington DC',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };

  useEffect(() => {
    try {
      // console.log("serviceData ", serviceData);
      
      const splittedURL = window.location.pathname.split("/");
      const userId = splittedURL[splittedURL.length - 1];
      const parameter = splittedURL[splittedURL.length - 1];
      let finalState = newState;

      if(parameter && parameter?.toUpperCase() && stateMap[parameter?.toUpperCase()]){
        finalState = stateMap[parameter?.toUpperCase()];
        localStorage.setItem("state", finalState);
        setNewState(finalState);
      }else{
        viewSelectedUserProfile(userId);
      }

      if(!finalState) {
        setOpenSelectStateModal(true)
      }

      searchTherapists({}, finalState!);

    } catch (error) {
      toast.error('Something went wrong !', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, []);

  const viewSelectedUserProfile = (id: any) => {
    TherapistService.getTherapistDetailsbyIdPublic(id).then(res => {
      if (res.success) {
        // setTherapistData(res.data);
        localStorage.setItem('therapist', JSON.stringify(therapist));
        history.push("/public-appoinments")
      }
    });
  };

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthnicityList(res.data);
    });

    TherapistService.getProfessionsPublic().then(res => {
      setProfessionsDetails(res.data);
    });

    CommonService.getAllStatesPublic().then(res =>{
      console.log(res)
      const stateNames: string[] = res?.data?.map((state: { stateName: string }) => state?.stateName);
      setStateList(stateNames);
      setSelectState(stateNames[0]);
    })
  }, []);

  // useEffect(() => {
  //   searchTherapists({});
  // }, [state]);
  

  const searchTherapists = (data: DiscoverTherapist, stateFrom: string) => {
    let selectedInsuranceCompanyId: string | null;

    if (insurance == null || insurance == "No Insurance"){
      localStorage.setItem("insuranceCompany", "No Insurance");
      selectedInsuranceCompanyId = null;
    } else {  
      const parsedData = JSON.parse(insurance);
      setSelectedInsuranceId(parsedData.insuranceId);
      selectedInsuranceCompanyId = parsedData.insuranceId;
      setSelectedInsuranceName(parsedData.insuranceName);
    }
    const experienceTagSymptoms = experienceTagSymptomsData ? JSON.parse(experienceTagSymptomsData) : null;
    setSelectedExperienceTagSymptoms(experienceTagSymptoms)
    if(experienceTagSymptoms){
      const allSymptomNames = experienceTagSymptoms
      .map((item: any) => item.selectedSymptomName)
      .join(', ');

      const maxLength = 25;
      const displayText = allSymptomNames.length > maxLength
        ? allSymptomNames.substring(0, maxLength) + '...'
        : allSymptomNames;
      
        setDisplayedSymptomsTest(displayText);
    }

    // if (serviceData == "couple") {
    //   discoverObject = {
    //     insuranceCompanies: [selectedInsuranceCompanyId],
    //     ethnicity: data.ethnicity,
    //     experiencedIn: data.experiencedIn,
    //     gender: data.gender,
    //     profession: data.profession,
    //     searchTherapistName: data.searchTherapistName,
    //     service: "couple",
    //     subTagId: "66a1f0d7edb55e14481174f4",
    //     issueType: "",
    //     experienceTagSymptoms: experienceTagSymptoms  ? experienceTagSymptoms : null
    //   };
    // } else if (serviceData == "teen") {
    //   discoverObject = {
    //     insuranceCompanies: [selectedInsuranceCompanyId],
    //     ethnicity: data.ethnicity,
    //     experiencedIn: data.experiencedIn,
    //     gender: data.gender,
    //     profession: data.profession,
    //     searchTherapistName: data.searchTherapistName,
    //     service: "teen",
    //     subTagId: "66a1f0d7edb55e14481174f4",
    //     issueType: ""
    //   };
    // } else {
    //   console.log("selectedInsuranceCompanyId ", selectedInsuranceCompanyId);
    //   console.log("#######################");
      
    //   discoverObject = {
    //     insuranceCompanies: [selectedInsuranceCompanyId],
    //     ethnicity: data.ethnicity,
    //     experiencedIn: data.experiencedIn,
    //     gender: data.gender,
    //     profession: data.profession,
    //     searchTherapistName: data.searchTherapistName,
    //     state: stateFrom,
    //     therapyType,
    //     subTagId: "66a1f0d7edb55e14481174f4",
    //     experienceTagSymptoms: experienceTagSymptoms  ? experienceTagSymptoms : null
    //   };
    // }

    const discoverObject: any = {
      insuranceCompanies: [selectedInsuranceCompanyId],
      ethnicity: data.ethnicity,
      experiencedIn: data.experiencedIn,
      gender: data.gender,
      profession: data.profession,
      searchTherapistName: data.searchTherapistName,
      state: stateFrom,
      // therapyType,
      experienceTagSymptoms: experienceTagSymptoms  ? experienceTagSymptoms : null
    };
    
    setIsLoading(true);
    
    TherapistService.searchTherapistsPublic(discoverObject, LIMIT, offset).then(res => {  
      if (res.success) {
        console.log(res.data);
        
        setSeeMoreCount(res.data.length);
        setSentTherapists(res.data);
      }
      setIsLoading(false);
    });
  };

  const seeMoreFunc = () => {
    
    setIsLoading(true);
    const updatedSkip = offset + 1;
    setOffset(updatedSkip);
    const expTags = selectedExpTags?.map((tag: { label: any; value: any }) => {
      return tag.value;
    });
    const discoverObject: any = {
      insuranceCompanies: [selectedInsuranceId as any],
      ethnicity: selectedEthnicity,
      experiencedIn: expTags,
      gender: gender,
      profession: selectedProfession,
      searchTherapistName: selectedName,
      state: newState,
      // therapyType,
      experienceTagSymptoms: selectedExperienceTagSymptoms ? selectedExperienceTagSymptoms : null
    };
    // if (serviceData == "couple") {
    //   discoverObject = {
    //     insuranceCommpanies: [selectedInsuranceId as any],
    //     service: "couple"
    //   };
    // } else if (serviceData == "teen") {
    //   discoverObject = {
    //     insuranceCompanies: [selectedInsuranceId as any],
    //     service: "teen"
    //   };
    // } else {
    //   const expTags = selectedExpTags?.map((tag: { label: any; value: any }) => {
    //     return tag.value;
    //   });
    //   discoverObject = {
    //     insuranceCompanies: [selectedInsuranceId as any],
    //     ethnicity: selectedEthnicity,
    //     experiencedIn: expTags,
    //     gender: gender,
    //     profession: selectedProfession,
    //     searchTherapistName: selectedName,
    //     state: newState,
    //     therapyType,
    //     experienceTagSymptoms: selectedExperienceTagSymptoms ? selectedExperienceTagSymptoms : null
    //   };
    // }
    
    TherapistService.searchTherapistsPublic(discoverObject, LIMIT, updatedSkip).then(res => {
      if (res.success) {
        console.log(res.data);
        
        res.data.map((data: any) => {
          setSentTherapists(therapist => [...therapist, data]);
        });
        setSeeMoreCount(res.data.length);
      }
      setIsLoading(false);
    });
  };

  const likeTherapist = (therapist: Therapist) => {
    history.push("/public-appoinments/" + therapist._id)
  };

  const watchVideo = (t: Therapist) => {
    setTherapist(t);
    if (t.vimeoId) {
      setTherapistVideo(t?.vimeoId);
      setModalCenterVideo(true);
    } else {
      setTherapistVideo("");
      setModalCenterVideo(true);
    }
  };

  const applyFilters = () => {
    const expTags = selectedExpTags?.map((tag: { label: any; value: any }) => {
      return tag.value;
    });

    const discoverObject: DiscoverTherapist = {
      ethnicity: selectedEthnicity,
      experiencedIn: expTags,
      gender: gender,
      profession: selectedProfession,
      searchTherapistName: selectedName,
    };
    localStorage.setItem('PersonalizeMatchData', JSON.stringify(discoverObject));
    searchTherapists(discoverObject,newState!);
  };
  const hideAndShow = () => {
    setIsView(!isView);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const handleSkipClick = () => {
    setTherapistData(null);
    localStorage.setItem('skip', "true");
    history.push("/public-signup")
  };

  const showPublicProfile = (id: any) => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-user-details/" + id;
    window.location.href = userPublicUrl;
  }

  const checkMatch = (stateList: string[], singleState: any) => {
    if (newState) {
      if (newState == singleState || stateList?.includes(newState)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  const toggleSelectStateModal = () => {
    setOpenSelectStateModal(!openSelectStateModal)
  }

  const closetoggleSelectStateModal = () => {
    setOpenSelectStateModal(false);
  }

  const proceedState = () => {
    if(selectedState?.length > 0 && selectedState){
      localStorage.setItem("state",selectedState);
      setNewState(selectedState);
      searchTherapists({},selectedState);
      toggleSelectStateModal();
    }else{
      toast.error('Please select a state', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }
  return (
    <div className="displaycenter">
      <div className="card cardChange">
        <div className="card-body  text-center">
          <Row>
            <label className=" d-flex j-btwn hidden_content">
              Personalize my match
              {isView ? (
                <i className="bx bxs-down-arrow mr-2 more-icon" onClick={hideAndShow}></i>
              ) : (
                <i className="bx bxs-up-arrow mr-2 more-icon" onClick={hideAndShow}></i>
              )}
            </label>
            <Col xs="12" sm="12" md="3" lg="3" xl="3" className={!isView ? `is-view2 overflow-hidden` : "is-hide2 overflow-hidden"}>
              <p className="colHeadername">Personalize my match</p>
              <hr />

              <div className="formsLayout">
                <input
                  className="form-control textsizes"
                  placeholder="State Not Selected"
                  disabled={true}
                  value={newState ? newState: "State Not Selected"}
                />
              </div>

              <div className="formsLayout">
                <input
                  className="form-control textsizes"
                  placeholder="Insurance Not Selected"
                  disabled={true}
                  value={selectedInsuranceName}
                />
              </div>

              <div className="formsLayout">
                <input
                  className="form-control textsizes"
                  placeholder="Concerns Not Selected"
                  disabled={true}
                  value={displayedSymptomsTest == "" ? "Symptoms Not Selected":  displayedSymptomsTest}
                />
              </div>

              <div className="formsLayout">
                <input
                  className="form-control textsizes"
                  placeholder="Search Therapist Name..."
                  onChange={e => setSelectedName(e.target.value)}
                  onKeyPress={handleKeyPress}
                  value={selectedName}
                />
              </div>

              <div className="formsLayout">
                <select className="form-control textsizes" onChange={e => setGender(e.target.value)} value={gender}>
                  <option value="">Gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="NonBinary">Non Binary</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="formsLayout">
                <select className="form-control" onChange={e => setSelectedEthnicity(e.target.value)} value={selectedEthnicity}>
                  <option value="">Ethnicity</option>
                  {ethnicityList &&
                    ethnicityList.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.ethnicity}
                      </option>
                    ))}
                </select>
              </div>
              <div className="formsLayout">
                <select
                  className="form-control"
                  onChange={e => {
                    setSelectedProfession(e.target.value);
                  }}
                  defaultValue={selectedProfession}
                >
                  <option value="">Select Profession</option>
                  {professionsDetails &&
                    professionsDetails.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.name}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="formsLayout">
                <select className="form-control" onChange={e => setSelectedEthnicity(e.target.value)} value={selectedEthnicity}>
                  <option value="">Availability</option>
                  {ethnicityList &&
                    ethnicityList.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.ethnicity}
                      </option>
                    ))}
                </select>
              </div> */}

              <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-primary btn-apply " onClick={applyFilters}>
                  Apply
                </button>
              </div>
            </Col>

            <Col xl="9" md="9" lg="9" sm="12" style={{ marginTop: "-5" }}>
              <p className="header-topic">Discover Licensed Professionals</p>
              <button className="btn btn-skip kip float-right" onClick={handleSkipClick} style={{ marginTop: "-20px" }}>
                Skip
              </button>
              
              {sentTherapists.length > 0 ? (
                <>
                  {sentTherapists?.map((t: Therapist, i: number) => {
                    const requestAvailable = clientFriendRequests?.some(el => el?.therapistId?._id == t?._id);

                    let request: any;

                    if (requestAvailable) {
                      request = clientFriendRequests?.filter(el => el?.therapistId?._id == t?._id)[0];
                    }

                    return (
                      <div className="sample-container therapistCell" style={{borderColor: "#ff8000"}} key={i}>
                        <Row className="card-container" >
                          <Col xl="3" md="6" sm="6" xs="12" className="d-flex justify-content-center align-items-center" onClick={() => showPublicProfile(t?._id)} style={{ cursor: 'pointer' }} >

                            <div className="card-s2-1">
                              {!t?.photoId ? (
                                <div className="avatar-xl mx-auto my-auto mb-4">
                                  <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>{t?.firstname?.charAt(0)}</span>
                                </div>
                              ) : (
                                <>
                                  {t?.photoId._id && (
                                    <div className="mb-4 text-center">
                                      <div className="avatar-xl mx-auto my-auto ">
                                        <span
                                          className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}
                                          style={{
                                            backgroundImage: `url(${Util.fileURL(t.photoId._id)})`,
                                          }}
                                        >
                                          {!t.photoId._id && t?.firstname?.charAt(0)}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Col>


                          <Col xl="6" md="6" sm="6" xs="12" onClick={() => showPublicProfile(t?._id)} style={{ cursor: 'pointer' }}>
                            <div className="professionLength">
                              <h5 className="font-size-15 mb-1">
                                {!t?.firstname && !t?.lastname ? (
                                  <span className="text-dark text-center">#Therapist</span>
                                ) : (
                                  <span className="text-dark text-center">
                                    {(t.firstname?.length || 0) > 8 ? (
                                      <span>{t?.firstname}...</span>
                                    ) : (
                                      <span>
                                        {t?.firstname} {t?.lastname}
                                      </span>
                                    )}
                                  </span>
                                )}
                              </h5>
                            </div>

                            <div className="professionLengthBlock">
                              <h5 className="font-size-15 mb-1">
                                <span className="text-dark text-center ">
                                  {t?.firstname} {t?.lastname}
                                </span>
                              </h5>
                            </div>

                            {!t?.profession?.name && <p className="text-muted role-text">{t?.role}</p>}

                            {t?.profession?.name == "Counselors, Clinicians, Therapists" && <p className="text-muted role-text">{t?.role}</p>}

                            <div className="professionLength">
                              {t?.profession?.name == "Family Nurse Practitioners" && (
                                <p className="text-muted role-text">
                                  {(t?.profession?.name.length || 0) > 22 ? (
                                    <p className="text-muted role-text">Family Nurse Pr...</p>
                                  ) : (
                                    <p className="text-muted role-text">Family Nurse Practitioner</p>
                                  )}
                                </p>
                              )}
                            </div>

                            <div className="professionLengthBlock">
                              {t?.profession?.name == "Family Nurse Practitioners" && <p className="text-muted role-text">Family Nurse Practitioner</p>}
                            </div>

                            <div className="professionLength">
                              {t?.profession?.name == "Certified Peer Specialists" && (
                                <p className="text-muted role-text">
                                  {(t?.profession?.name.length || 0) > 22 ? (
                                    <p className="text-muted role-text">Certified Peer ...</p>
                                  ) : (
                                    <p className="text-muted role-text">Certified Peer Specialist</p>
                                  )}
                                </p>
                              )}
                            </div>

                            <div className="professionLengthBlock">
                              {t?.profession?.name == "Certified Peer Specialists" && <p className="text-muted role-text">Certified Peer Specialist</p>}
                            </div>

                            {t?.profession?.name == "Psychiatrists" && <p className="text-muted role-text">Psychiatrist</p>}

                            <div className="professionLength">
                              {t?.profession?.name == "Psychiatric Or Mental Health Nurse Practitioners" && (
                                <p className="text-muted role-text">
                                  {(t?.profession?.name.length || 0) > 22 ? (
                                    <p className="text-muted role-text">Mental Health N...</p>
                                  ) : (
                                    <p className="text-muted role-text">Mental Health Nurse Practitioner</p>
                                  )}
                                </p>
                              )}
                            </div>

                            <div className="professionLengthBlock">
                              {t?.profession?.name == "Psychiatric Or Mental Health Nurse Practitioners" && (
                                <p className="text-muted role-text">Mental Health Nurse Practitioner</p>
                              )}
                            </div>

                            <div className="professionLength">
                              {t?.profession?.name == "Life Coach" && (
                                <p className="text-muted role-text">
                                  {(t?.profession?.name.length || 0) > 9 ? (
                                    <p className="text-muted role-text">Qualified Life ...</p>
                                  ) : (
                                    <p className="text-muted role-text">Qualified Life Coach</p>
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="professionLengthBlock">
                              {t?.profession?.name == "Life Coach" && <p className="text-muted role-text">Qualified Life Coach</p>}
                            </div>

                            <div className="professionLength">
                              {t?.profession?.name == "Clinical Social Workers" && (
                                <>
                                  <p className="text-muted role-text">
                                    {(t?.profession?.name.length || 0) > 22 ? (
                                      <span className="text-muted role-text">Clinical Social...</span>
                                    ) : (
                                      <span className="text-muted role-text">Clinical Social Workers</span>
                                    )}
                                  </p>
                                </>
                              )}
                            </div>

                            <div className="professionLengthBlock">
                              {t?.profession?.name == "Clinical Social Workers" && <p className="text-muted role-text">Clinical Social Worker</p>}
                            </div>

                            {t?.profession?.name == "Pastoral counseling" && <p className="text-muted role-text">Pastoral Counselor</p>}

                            {t.experiencedIn?.length == 0 ? (
                              <>
                                <div className="card-s3 tags-sec1-1 mb-2">
                                  <div className="text-center d-flex">{t.experiencedIn?.length == 0 && <p className="col-md-12 no-tags">No Tags</p>}</div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="card-s3 tags-sec1 mb-2">
                                  {t.experiencedIn!.map((ex: ExperienceTag, i: number) => {
                                    return (
                                      <span className="card-hash-tag" key={i}>
                                        {/* {ex.experienceTag == "Attention Deﬁcit Hyperactivity Disorder (ADHD)" ? null : ex.experienceTag} */}
                                        {ex.experienceTag.length > 30 ? ex.experienceTag.slice(0, 30) + "..." : ex.experienceTag}
                                      </span>
                                    );
                                  })}
                                </div>
                              </>
                              // <>
                              //   <div className="card-s3 tags-sec1 mb-2">
                              //     {t.experiencedIn!.map((ex: any, i: number) => {
                              //       return (
                              //         <span className="card-hash-tag" key={i}>
                              //           {ex.symptom.length > 30 ? ex.symptom.slice(0, 30) + "..." : ex.symptom}
                              //         </span>
                              //       );
                              //     })}
                              //   </div>
                              // </>
                            )}
                            <div className="card-s3 tags-sec1 mb-2">
                              {t.therapyState && t.therapyState?.map((state: string, i: number) => {
                                return (
                                  <span className="card-hash-tag2" key={i}>
                                    {state}
                                  </span>
                                );
                              })}
                            </div>
                          </Col>

                          <Col xl="3" md="12" sm="12" xs="12 card-btn-group">
                            <button type="button" className="btn-watch btn btn-primary mb-3 pt-2 pb-2" onClick={() => watchVideo(t)}>
                              Watch Video
                            </button>
                            <button
                              type="button"
                              className="btn-match btn btn-primary mb-3 pt-2 pb-2"
                              onClick={() => {
                                likeTherapist(t);
                              }}
                              disabled={checkMatch(t.therapyState, t.state)}
                            >
                              Match
                            </button>
                          </Col>
                        </Row>

                      </div>
                    );
                  })}
                  {seeMoreCount >= 5 && (
                    <div className="flex justify-content-center">
                      <button className="text-center mt-4 btn-viewMore mt-4" style={{ marginTop: "40px !important" }} onClick={() => seeMoreFunc()}>
                        View More
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div className="col-md-12 text-center mt50 mb50">
                      There are currently no licensed clinicians that match your criteria in your area.
                      <br />
                      Please be patient as we onboard more licensed clinicians in your area.
                    </div>
                  )}
                </>
              )}
            </Col>

            {modalcentervideo && (
              <div
                className="lightbox1"
                onClick={() => {
                  setModalCenterVideo(false);
                }}
              >
                <button className="close-down">X</button>

                <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
                  {therapistVideo ? (
                    <>
                      <div className="modal-body1">
                        <iframe
                          src={"https://player.vimeo.com/video/" + therapistVideo}
                          width="800"
                          className="videoSize"
                          height="400"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          title="Lavni Health"
                        ></iframe>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="transcode-status-profile">
                        No video is added by {therapist.firstname} {therapist.lastname} {!(therapist.firstname && therapist.lastname) && "Setected User"}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Row>
        </div>
      </div>
      <Modal isOpen={openSelectStateModal} toggle={toggleSelectStateModal} centered className="modal-md">
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={closetoggleSelectStateModal}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>
        <ModalBody className="ps-4 pe-4">
          <Row className="pt-4">
            <div className="notify-icon">
              <i className="fa fa-question-circle text-primary"></i>
            </div>
          </Row>
          <Row>
            <span className="font-size-18 profileFont mb-4 cursor-pointer  text-center">Please select a state</span>
          </Row>
          <Row className="mb-2 d-flex justify-content-center align-items-center">
          <select
                className="form-control mb-2 mx-w-selectState"
                value={selectedState}
                onChange={e => setSelectState(e.target.value)}
              >
                {stateList &&
                  stateList.map((opt: any, index: number) => (
                    <option value={opt} key={index}>
                      {opt}
                    </option>
                  ))}
              </select>
          </Row>

          <Row className="mt-3">
            <Col className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "#FFFFFF" }}>
              <button className="btn btn-warning create-client-custom2 float-right mr-2"
                onClick={() => proceedState()}
              >Proceed</button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div >
  );
};

export default TherapistList;
