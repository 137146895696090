import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Input, UncontrolledTooltip } from "reactstrap";
import UserContext from "../../../context/UserContext";
import { Role } from "../../../models/Role";

interface DocSideBarProps {
    searchDocuments: (data: any) => void;
    isOpen: () => void;
}
  
const DocSideBar: React.FC<DocSideBarProps> = ({ searchDocuments, isOpen }: DocSideBarProps) => {
    const [loggedUser] = useContext(UserContext);
    const [isSearchableText, setSearchableText] = useState({searchableText: ''} as any);


    return (
        <React.Fragment>
            <Card className="sidebar-card">
                
                <CardBody className={`p-4 `}>
                    {
                        (loggedUser?.role == Role.THERAPIST) ? (
                            <>
                                <div className="text-muted f-18 mb-3">Looking to add Training document?</div>
                                <button  className="btn btn-primary .btn.block w-100 mb-3" onClick={isOpen}>
                                    Add Training Documents
                                </button>
                            </>
                        ) : (
                            <>
                                <div className="text-muted f-18 mb-3">Search for Training document?</div>
                                <Input placeholder="Search here..." className="mb-3" onChange={(e: any) => setSearchableText({...isSearchableText, searchableText: e.target.value})}/>
                                <button  className="btn btn-primary .btn.block w-100 mb-3" onClick={() => searchDocuments(isSearchableText)}>
                                    Search Documents
                                </button>
                            </>
                        )
                    }

                </CardBody>
            </Card>
            
            </React.Fragment>
    )
}

export default DocSideBar;