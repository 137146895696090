import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import FooterMain from "src/common/main/FooterMain";
import HeaderMain from "src/common/main/HeaderMain";
import { Card, Row } from "reactstrap";
import SignaturePad from "react-signature-canvas";
import { ClientService } from "src/services/ClientService";
import { useHistory } from "react-router-dom";
import { RouteName } from "src/RouteName";
import { TherapyPlan } from "src/models/therapyPlan/therapyPlan";


const ClientTherapyPlanSignaturePage: React.FC = () => {
    const history = useHistory();
    const [isLRP, setIsLRP] = useState(false);
    const [lrpName, setLrpName] = useState("");
    const [relationship, setRelationship] = useState("");
    const [formData, setFormData] = useState<TherapyPlan>({
        diagnosis: [{}],
        treatmentSession: [{}],
        treatmentSession2: [{}],
        treatmentSession3: [{}]
    })
    const [formId,setFormId] =useState<any>()

    useEffect(() => {
        const pathname = location.pathname;
        const parts = pathname.split('/');
        const id = parts[parts.length - 1];
        setFormId(id)

    }, []);

    const signPad = useRef<any>({
        minDistance: 0,
        throttle: 9,
        velocityFilterWeight: 0.7,
    });

    function clearsign(): void {
        if (signPad.current) {
            signPad.current.clear();
            const signatureData = null;
            setFormData({ ...formData, clientSignature: signatureData })
        }
    }

    function saveSign() {
        try {
            if (signPad.current) {
             const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
             setFormData({ ...formData, clientSignature: signatureData });
             updateData(signatureData, isLRP);
            }
        } catch (error) {
            console.error("Error saving signature:", error);
        }
    }

    const updateData = (signatureData:any, isLRP: boolean) => {

        const data: any = {
            signature: signatureData,
            isLRP: isLRP,
            _id: formId
        }
        
        if (isLRP) {
            data.name = lrpName;
            data.relationship = relationship;
        }
        
        try {
            ClientService.updateTherapyPlanSignature(data).then(res => {
                if (res && res.success) {

                    toast.success(`Signature successfully added.`, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo-bar",
                    });

                    history.push(RouteName.DASHBOARD);

                } else {
                    toast.error(res?.error, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo-bar",
                    });
                }
            });
        } catch (error) {
            toast.error(`Signature could not be added successfully. Please try again.`, {
                position: toast.POSITION.TOP_RIGHT,
                className: "foo-bar",
            });
        }
    }

    //canvas resize
    function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
       let timeout: ReturnType<typeof setTimeout>;
       return function (...args: Parameters<T>) {
         clearTimeout(timeout);
         timeout = setTimeout(() => func(...args), wait);
      };
    }

    const resizeCanvas = () => {
        if (signPad.current) {
            const canvas = signPad.current.getCanvas();
            const container = canvas.parentNode;
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = container.offsetWidth * ratio;
            canvas.height = container.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            signPad.current.clear();
        }
    };

    useEffect(() => {
        if (signPad.current) {
        resizeCanvas();
        }

        const debouncedResize = debounce(resizeCanvas, 200);
        window.addEventListener("resize", debouncedResize);

        // Using ResizeObserver to handle changes in container size
        const observer = new ResizeObserver(() => {
            resizeCanvas();
        });
        const container = signPad.current.getCanvas().parentNode;
        observer.observe(container);

        return () => {
            window.removeEventListener("resize", debouncedResize);
            observer.disconnect();
        };
    }, []);

    const handleCheckboxChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsLRP(event.target.checked);
    };

    return (
        <div className="content-wrapper">
            <HeaderMain />
            <section
                className="bg-image-set wrapper image-wrapper bg-image bg-overlays bg-overlay-light-600 text-white"
                data-image-src="./../static/assets/img/photos/bg3.jpg"
            >
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center"></div>
            </section>

            <section className="wrapper bg-light2 angled upper-end" id="recoverform">
                <div className="container1 pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
                            <div className="displaycenter">
                                <div className="card cardChange">
                                    <div className="card-body  text-center">
                                        <section className="wrapper">
                                            <div className="container">
                                                <div className="d-flex justify-content-center">

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-12 col-xl-12  mx-auto text-center">
                                                        <Card className="match-card-body-second p-3">
                                                            <Row>
                                                                <h6 className="fw-600 mb-5">Signature of client acknowledging participation in therapy plan :</h6>
                                                            </Row>
                                                            <Row>
                                                                <div className="mt-3 mb-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={isLRP}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                    <label className="ms-2 fst-italic">Check if the Legally Responsible Person (LRP) is not the client.</label>
                                                                </div>
                                                            </Row>
                                                            
                                                            {isLRP && (
                                                                <>
                                                                    <Row className="mb-3">
                                                                        <div className="col-md-6 mx-auto">
                                                                            <label className="form-label">Name of Legally Responsible Person</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter full name"
                                                                                value={lrpName}
                                                                                onChange={(e) => setLrpName(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <div className="col-md-6 mx-auto">
                                                                            <label className="form-label">Relationship to Client</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="E.g. Parent, Guardian, etc."
                                                                                value={relationship}
                                                                                onChange={(e) => setRelationship(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                </>
                                                            )}
                                                            
                                                            <Row>
                                                                <div className="row justify-content-center" style={{ textAlign: "center" }}>
                                                                    <div className="col-sm-5 m-2">
                                                                        <div className="d-flex flex-column justify-content-between">
                                                                            <div className="sign">

                                                                                <div style={{
                                                                                    display:"flex",
                                                                                    flexDirection: "row",
                                                                                    justifyContent:"center"
                                                                                }}>
                                                                                     <div className='sigCanvasClientTherapyPlan'>

                                                                                        <SignaturePad backgroundColor="rgb(245,242,241)" ref={signPad} penColor="black" canvasProps={{className:"sigCanvasClientTherapyPlan"}}></SignaturePad>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div className="d-flex justify-content-between mt-3">
                                                                                <div>
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            clearsign();
                                                                                        }}
                                                                                        className="btn btn-primary btn-sm"
                                                                                    >
                                                                                        Clear
                                                                                    </a>
                                                                                </div>
                                                                                <div>
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            saveSign();
                                                                                        }}
                                                                                        className="btn btn-primary btn-sm"
                                                                                    >
                                                                                        Save
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto mt-3">
                                                                            <h6 className="fw-500">Signature of {isLRP ? "LRP" : "Client"}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="overflow-hidden">
                <div className="divider text-navy mx-n2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" style={{ color: "#343f52" }}>
                        <path fill="currentColor"
                            d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z" />
                    </svg>
                </div>
            </div>
            <FooterMain />
        </div>
    );
};

export default ClientTherapyPlanSignaturePage;
