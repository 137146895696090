import React,{ useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as VideoExpress from "@vonage/video-express";
import MuteAudioButton from "../MuteAudioButton";
import MuteVideoButton from "../MuteVideoButton";
// import SpeakerButton from 'components/SpeakerButton';
// import SpeakerSelector from "../SpeakerSelector";
// import RecordingButton from "../RecordingButton";
import LayoutButton from "../LayoutButton";
// import MuteAll from "../MuteAllButton";
// import ReactionsButton from "../ReactionsButton";
import ScreenSharingButton from "../ScreenSharingButton";
import EndCallButton from "../EndCallButton";
// import VideoFilterButton from "../VideoFilterButton";
import styles from "./styles";
import { useParams } from "react-router";
import { useTheme } from "@material-ui/core";

import MoreOptionsButton from "../MoreOptionsButton";
import PropTypes from "prop-types";
import LocalCallContext from "src/context/LocalCallContext";
import CallInitializationContext from "src/context/CallInitializationContext";
import { VideoChatService } from "src/services/VideoChatService";
import LocalCallTimeOutContext from "src/context/LocalCallTimeOutlContext";
import Modal10 from "src/pages/Popup/Modal10";
import UserContext from "src/context/UserContext";
import Modal9 from "src/pages/Popup/Modal9";
import { toast } from "react-toastify";
import { VonageServices } from "../../../vonageServices";
import { SocketContext } from "src/context/ScoketContext";
import RefreshButton from "../RefreshButton";
import RefreshPagePopUp from "src/pages/Popup/RefreshPage";

export default function ToolBar({
  room,
  connected,
  cameraPublishing,
  isScreenSharing,
  startScreenSharing,
  stopScreenSharing,
  participants,
  localParticipant,
}) {
  const { roomName } = useParams();
  const theme = useTheme();
  const history = useHistory();
  const [hasAudio, setHasAudio] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [areAllMuted, setAllMuted] = useState(false);
  const classes = styles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));
  const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [callTimeOut, setCallTimeOut, setShowPopUp] = useContext(LocalCallTimeOutContext);
  const [showLeavePopUp, setShowLeavePopUp] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isBothUsersJoined, setIsBothUsersJoined] = useState(false); 
  const [loggedUser] = useContext(UserContext);
  const [startArchieve, setStartArchieve] = useState(false);
  const [archiveId, setArchiveId] = useState("")
  const [isFirstSpeakerUpdated, setIsFirstSpeakerUpdated] = useState(false);
  const socket = useContext(SocketContext);
  const [showRefreshPagePopup, setShowRefreshPagePopup] = useState(false);
  
  useEffect(() => {
    initFunction();
  }, []);

  function initFunction() {
    if (history && history.action && history.action != "PUSH") {
      history.push(`/room-group/${roomName}`)
    }
  }

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    console.log(localUserOnCall);
  }, []);


  useEffect(()=>{
    if(participants.length >= 1 && loggedUser.role == 'THERAPIST'){

      setIsBothUsersJoined(true);
      const delayedFunction = () => {
        delayedFunc();
      };
      const convertMeetingDurationToMiliSeconds = 90 * 60 * 1000;
      const timeoutId = setTimeout(delayedFunction, convertMeetingDurationToMiliSeconds);
      return () => clearTimeout(timeoutId);
    }
  },[participants])

  useEffect(()=> {
    if(participants.length >= 1 && !startArchieve && loggedUser.role == 'THERAPIST' ){
      const timeoutId = setTimeout(() => {
        setStartArchieve(true);
        startArchiveFunc();
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [participants])

  const startArchiveFunc = async () => {
    if(room.roomId){
      const archieveRes = await VonageServices.startArchive(room.roomId);
      if(archieveRes.success){
        setArchiveId(archieveRes.data);
        localStorage.setItem('archiveId', archieveRes.data);
      }
    }
  }
  
  const delayedFunc = () => {
    toast.success(`Call is ended after 1 hour and 30 minutes!`, {
      position: toast.POSITION.TOP_RIGHT,
      className: "foo-bar",
    });
    endCall();
  }

  const handleMuteAll = () => {
    if (!areAllMuted) {
      participants.map((participant) => participant.camera.disableAudio());

      setAllMuted(true);
    } else {
      participants.map((participant) => participant.camera.enableAudio());
      setAllMuted(false);
    }
  };
  
  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };
  
  const toggleAudio = () => {
    if (room && room.camera) {
      const camera = room.camera;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
        if(!isFirstSpeakerUpdated){
          setIsFirstSpeakerUpdated(true);
        }
      }
    }
  };

  const getVideoSource = () => {
    if (room && room.camera) {
      return room.camera.getVideoDevice();
    }
  };

  const changeVideoSource = (videoId) => {
    room.camera.setVideoDevice(videoId);
  };
  const changeAudioSource = (audioId) => {
    room.camera.setAudioDevice(audioId);
  };

  const changeAudioOutput = async (audioOutputDeviceId) => {
    await VideoExpress.setAudioOutputDevice(audioOutputDeviceId);
  };

  const getCurrentAudioOutput = async () => {
    try {
      const currentAudioOutput = await VideoExpress.getActiveAudioOutputDevice();
      return currentAudioOutput.deviceId;
    } catch (e) {
      return e;
    }
  };

  const getAudioSource = async () => {
    if (room && room.camera) {
      const audioDevice = await room.camera.getAudioDevice();
      return audioDevice.deviceId;
    }
  };

  useEffect(()=>{
    console.log(localUserOnCall);
    if(!localUserOnCall.onOngoingCall){
      if(loggedUser.role == 'THERAPIST'){
        stopArchive();
      } 

      if (room) {
        room.leave();
        history.push(`/video-room-group/${roomName}/${room.roomId}/end`)
      }
      
    }
  }, [localUserOnCall.onOngoingCall])

  const endCall = async () => {
    try {
      if(loggedUser.role == 'THERAPIST'){
        const res = await VonageServices.cancelVonageGroupCall(roomName);
        console.log(res);
        if(res.success){
          const dataForsocket = {
            groupMembers: res.data,
            therapistName: loggedUser?.firstname + " " + loggedUser?.lastname
          };
          socket.emit('end-call-for-all-vonage', dataForsocket)
        }
      }
      if (room) {
        room.leave();
        history.push(`/video-room-group/${roomName}/${room.roomId}/end`)
      }
      
    } catch (error) {
      console.log(error);
    }
    
  };

  const stopArchive = async () => {
    console.log("Archive id ", localStorage.getItem('archiveId'));
    const stopArchiveRes = await VonageServices.stopArchive(localStorage.getItem('archiveId'));
    console.log(stopArchiveRes);
    if(stopArchiveRes.success){
      if (room) {
            // window.location.href = "/dashboard"
            room.leave();
      }
    }
    return stopArchiveRes;
  }

  const leaveBtnClick = () => {
    setShowLeavePopUp(true);
  }

  const leaveMeetingPopUpFunction = (showModal, leave) => {
    setShowLeavePopUp(showModal);
    if (leave) {
      endCall();
    }
  };

  const refreshButtonClick = () => {
    setShowRefreshPagePopup(true);
  }

  const showRefreshPagePopupFunction = (showModal, refresh) => {
    setShowRefreshPagePopup(showModal);
    console.log(showModal, " ", refresh);
    if(refresh){
      window.location.reload();
    }
  }


  const resetContextData = () => {
    const timeoutData = {
      isTimeOutRunning: true,
      userOnMeetingScreen: false,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };

    setCallTimeOut(timeoutData);

    const callInitializeDataForReset = {
      start: false,
      isInstantMeeting: false,
      isAppointmentBased: false,
      isTranscribeAllowed: false,
      appointmentId: "",
      recieverId: "",
      meetingTime: 30,
      meetingId: "",
      isAudioCall: false
    };

    setLocalCallInitialize(false);
    setLocalCallInitializeData(callInitializeDataForReset);

    const resetDataForCall = {
      callId: "",
      onOngoingCall: false,
      recieversUseDefaultAvatarValue: true,
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: true,
      meetingMainTherapistId: "",
      isVideoCall: false,
      meetingDuration: 30,
      recieversUserId: "",
      recieversName: "",
      recieverCallRecordingAllowed: false,
      recieversSocketId: "",
      callEndedForBothUsers: false,
      videoSdkToken: "",
      isAudioCall: false
    };
    setLocalUserOnCall(false, resetDataForCall, true, false);
  };

  useEffect(() => {
    if (connected) {
      const isAudioEnabled = room && room.camera && room.camera.isAudioEnabled() ? true : false;
      const isVideoEnabled = room && room.camera && room.camera.isVideoEnabled() ? true : false;
      setHasAudio(isAudioEnabled);
      setHasVideo(isVideoEnabled);
    }
    // if (room) console.log(getParticipantsList());
  }, [connected, room]);

  return <div>{screenWidth < 530 ? (
    <div className={classes.toolbarMobileContainer}>
      {!localCallInitializeData.isAudioCall && (
        <MuteVideoButton screenWidth={screenWidth} toggleVideo={toggleVideo} hasVideo={hasVideo} classes={classes} changeVideoSource={changeVideoSource} />
      )}
      
      <MuteAudioButton screenWidth={screenWidth} toggleAudio={toggleAudio} hasAudio={hasAudio} classes={classes} changeAudioSource={changeAudioSource} />
      <MoreOptionsButton screenWidth={screenWidth} classes={classes} participants={participants} room={room} localParticipant={localParticipant} />
      <LayoutButton screenWidth={screenWidth} classes={classes} room={room} />
      <RefreshButton screenWidth={screenWidth} classes={classes} handleRefreshPage={refreshButtonClick} />
      <EndCallButton screenWidth={screenWidth} classes={classes} handleEndCall={leaveBtnClick} />
    </div>
  ) : (
    <div className={classes.toolbarContainer}>
      <MoreOptionsButton classes={classes} participants={participants} room={room} localParticipant={localParticipant} />
      <MuteAudioButton
        toggleAudio={toggleAudio}
        hasAudio={hasAudio}
        classes={classes}
        changeAudioSource={changeAudioSource}
        getAudioSource={getAudioSource}
        cameraPublishing={cameraPublishing}
        screenWidth={screenWidth}
      />
      {!localCallInitializeData.isAudioCall && (
        <MuteVideoButton
          toggleVideo={toggleVideo}
          hasVideo={hasVideo}
          classes={classes}
          getVideoSource={getVideoSource}
          cameraPublishing={cameraPublishing}
          changeVideoSource={changeVideoSource}
          screenWidth={screenWidth}
        />
      )}
      
      {/* {VideoExpress.hasMediaProcessorSupport() && <VideoFilterButton classes={classes} room={room} />} */}
      {/* <SpeakerButton
        cameraPublishing={cameraPublishing}
        changeAudioOutput={changeAudioOutput}
        getCurrentAudioOutput={getCurrentAudioOutput}
        classes={classes}
      /> */}
      {/* <SpeakerSelector room={room} changeAudioOutput={changeAudioOutput} getCurrentAudioOutput={getCurrentAudioOutput} classes={classes} /> */}

      {/* <RecordingButton room={room} classes={classes} /> */}
      <ScreenSharingButton
        isScreenSharing={isScreenSharing}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        classes={classes}
      />
      {/* <MuteAll handleMuteAll={handleMuteAll} areAllMuted={areAllMuted} classes={classes} /> */}
      {/* <ReactionsButton handleMuteAll={handleMuteAll} areAllMuted={areAllMuted} classes={classes} room={room} /> */}
      <LayoutButton screenWidth={screenWidth} classes={classes} room={room} />
      <RefreshButton screenWidth={screenWidth} classes={classes} handleRefreshPage={refreshButtonClick} />
      <EndCallButton classes={classes} handleEndCall={leaveBtnClick} screenWidth={screenWidth}/>
    </div>
  )}
  {showLeavePopUp && <Modal10 showModal={showLeavePopUp} setShowModal={leaveMeetingPopUpFunction}/>}
  {showRefreshPagePopup && <RefreshPagePopUp showModal={showRefreshPagePopup} setShowModal={showRefreshPagePopupFunction}/>}
  </div>
}
ToolBar.propTypes = {
  room: PropTypes.object, // Add prop types for each prop
  connected: PropTypes.bool,
  cameraPublishing: PropTypes.bool,
  isScreenSharing: PropTypes.bool,
  startScreenSharing: PropTypes.func,
  stopScreenSharing: PropTypes.func,
  participants: PropTypes.array,
  localParticipant: PropTypes.object,
};