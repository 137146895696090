import React, { useState, useEffect } from "react";
import { Col, Container, Row} from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import AssessmentForm from "./AssessmentForm";
import html2PDF from "jspdf-html2canvas";
import Swal from "sweetalert2";
import { AdminService } from "src/services/AdminService";
import { UploadCategory } from "src/models/ClientInsuranceDocs";
import { DigitalForm } from "src/models/AssessmentDigitalForm/AssessmentDigitalForms";
import { CommonService } from "src/services/CommonService";

toast.configure();

const PdfUploadPage: React.FC<{ identifierId: any, isAdmin: boolean, insuranceDocApprovalId: any, onPdfComplete: () => void, onUploadModalRefresher: () => void }> = props => {
    const [buttonText, setButtonText] = useState('Upload Clinical Assessment');
    const [isDisabled, setIsDisabled] = useState(false);
    const [assesmenData, setAssesmenData] = useState<DigitalForm>();
    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const insuranceDocApprovalId = props.insuranceDocApprovalId;

    useEffect(() => {
        getAssesmentData();
    }, []);

    const getAssesmentData = async () => {
        try {
            if (isAdmin) {
                const data = {
                    assesmentId: identifierId
                }

                const res = await CommonService.getNewTypeOfClinicalAssessmentDetailsForDownload(data);
                if (res && res?.success && res?.data) {
                  setAssesmenData(res?.data);
                }  else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            } else {
                toast.error("Not allowed!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const replaceTextareasWithDivs = () => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach((textarea) => {
            const text = textarea.value;
            const div = document.createElement('div');
            
            div.style.whiteSpace = 'pre-wrap';
            div.style.wordWrap = 'break-word';
            div.style.border = textarea.style.border;
            div.style.padding = textarea.style.padding;
            div.style.width = textarea.style.width;
            div.style.height = textarea.style.height;
            div.textContent = text;
    
            const parent = textarea.parentNode;
            if (parent) {
                parent.insertBefore(div, textarea);
                textarea.style.display = 'none';
            }
        });
    };

    const generatePDF = async () => {
        replaceTextareasWithDivs();
    
        Swal.fire({
            icon: "info",
            title: "PDF Preparation In Progress!",
            html: `
            <p>The clinical assessment is being prepared. Please wait while we generate it.</p>
            <p>Once the preparation is complete, it will be automatically upload to the destination folder.</p>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    
        setButtonText('PDF preparation in progress. Please wait...');
        setIsDisabled(true);
    
        try {
            const pagesCollection = document.getElementsByClassName('form-page');
            const pages = Array.from(pagesCollection).map(page => page as HTMLElement);
    
            const now = new Date();
            const formattedDate = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`;
            const formattedTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
            const fileName = `ClinicalAssessment_${formattedDate}_${formattedTime}.pdf`;
    
            const pdf = await html2PDF(pages, {
                jsPDF: {
                    unit: 'pt',
                    format: 'a4',
                    compress: true,
                },
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                autoResize: true,
                imageType: 'image/jpeg',
                output: fileName,
                success: async (pdf) => {
                    try {
                        const pdfArrayBuffer = pdf.output('arraybuffer');
                        const pdfBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
                        const uploadCategory = UploadCategory.CLINICAL_ASSESSMENT;
                        const res = await AdminService.uploadInsurancePdf(insuranceDocApprovalId, uploadCategory, pdfBlob, fileName);
    
                        if (res && res.success) {
                            props.onUploadModalRefresher();
                            toast.success(res.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar"
                            });
                        } else {
                            toast.error(res.error, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                        }
                    } catch (uploadError) {
                        toast.error("Upload failed! Please try again.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                },
            });
        } catch (error) {
            toast.error("Something went wrong! Try again later.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setButtonText('Upload Clinical Assessment');
            setIsDisabled(false);
            props.onPdfComplete();
            Swal.close();
        }
    };

    return (
        <React.Fragment>
            <div className="page-content mt-0" style={{ padding: 0 }}>
                <Container className="containerOnboarding" style={{ padding: 0 }}>
                    <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
                        <Col>
                            <div>
                                <h5 className="font-size-25 profileFont mt-4 text-center">
                                    COMPREHENSIVE CLINICAL ASSESSMENT
                                </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                                <span>Click the button below to prepare and upload the PDF for fax submission.</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-primary mb-2" onClick={generatePDF} disabled={isDisabled}>
                                    {buttonText}
                                </button>
                            </div>
                            <StepWizard>
                                <AssessmentForm assesmenData={assesmenData} />
                            </StepWizard>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PdfUploadPage;