import * as React from "react";
import { TimeOut } from "../models/TimeOut";
import { UpcomingAppointment } from "src/models/UpcomingAppointment";

type ShowSchedulePopupData = [
    showSchedulePopup: boolean, 
    setShowSchedulePopup: (value: boolean) => void,
    therapistIdForSchedulePopup: string,
    setTherapistIdForSchedulePopup: (value: string) => void,
    clientIdForSchedulePopup: string,
    setClientIdForSchedulePopup: (value: string) => void,
    showScheduleInTherapistChat: boolean,
    setShowScheduleInTherapistChat: (value: boolean) => void,
    meetingEndedProperly: boolean,
    setMeetingEndedProperly: (value: boolean) => void,
    upcomingAppointments: UpcomingAppointment[],
    setUpcomingAppointments: (value: any) => void,
];

const ShowSchedulePopupContext = React.createContext<ShowSchedulePopupData>([false, () => ({}),"",() => ({}),"",() => ({}),false,() => ({}),false,() => ({}),[],() => ({})]);

export default ShowSchedulePopupContext;

