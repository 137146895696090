import React from 'react';
import { IconButton } from '@material-ui/core';
import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';

const ITEM_HEIGHT = 48;

export default function RefreshButton({ classes , screenWidth, handleRefreshPage}) {
  

  return (
    <div>
      <Tooltip title="Refresh Page">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={screenWidth < 530 ?classes.toolbarButtons2:classes.toolbarButtons}
          onClick={handleRefreshPage}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
RefreshButton.propTypes = {
  classes: PropTypes.object.isRequired,
  screenWidth: PropTypes.any,
  handleRefreshPage: PropTypes.func.isRequired
};