import React, { useEffect, useState } from "react";
import { SaasTherapistService } from "../../../services/SaasTherapistService";
import { Util } from "../../../Util";
import { Link } from "react-router-dom";
import Spinner from "../../../common/spinner/spinner";

interface InsuranceSaasProps {
  clientId: string;
}

interface Insurance {
  _id: string;
  clientId: string;
  insuranceCompanyId: {
    _id: string;
    insuranceCompany: string;
    coPayment: number;
  };
  subscriber: {
    memberId: string;
    firstName: string;
    lastName: string;
    address: {
      state: string;
    };
  };
  insuranceCardId: string;
  insuranceCardBackId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  controlNumber: string;
  insuranceAccessToken: string;
}

const InsuranceSaas: React.FC<InsuranceSaasProps> = ({ clientId }) => {
  const [insuranceProviderList, setInsuranceProviderList] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("primary");
  const [primaryInsurance, setPrimaryInsurance] = useState<any>(null);
  const [secondaryInsurance, setSecondaryInsurance] = useState<any>(null);
  const [clientDetails, setClientDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchInsuranceProviderList = async () => {
    setIsLoading(true);
    try {
      const res = await SaasTherapistService.getInsuranceDetailsViaClientId(clientId);
      if (res.success && res.data.insurance.length > 0) {
        setInsuranceProviderList(res.data.insurance);
        setClientDetails(res.data.client);

        const primary = res.data.insurance.find((insurance: Insurance) => insurance._id === res.data.client.insuranceId);
        setPrimaryInsurance(primary || null);

        if (res.data.insurance.length > 1) {
          const secondary = res.data.insurance.filter((insurance: Insurance) => insurance !== primary);
          setSecondaryInsurance(secondary.length > 0 ? secondary[0] : null);
        }
        setIsLoading(false);
      } else {
        setPrimaryInsurance(null);
        setSecondaryInsurance(null);
        setIsLoading(false);
      }
    } catch (error) {
      setPrimaryInsurance(null);
      setSecondaryInsurance(null);
    }finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInsuranceProviderList().then(r => r);
  }, [activeTab]);

  useEffect(() => {
    setActiveTab("primary");
    fetchInsuranceProviderList().then(r => r);
  }, []);

  const renderTabContent = () => {
    const insuranceData = activeTab === "primary" ? primaryInsurance : secondaryInsurance;
    if (!insuranceData) {
      return <div>No insurance details found for {activeTab} tab.</div>;
    }
    return (
      <form style={{ fontSize: "12.5px" }}>
        <div>
          <div className="card-heading mb-2">
            <h5 className="font-size-14 profileFont mb-2">Insurance card picture</h5>
          </div>

          <div className={"d-flex flex-row"}>
            {insuranceData?.insuranceCardId != null && (
              <div className="flex  mb-2">
                <div className="mr-5">
                  <Link to={{ pathname: Util.fileURL(insuranceData?.insuranceCardId) }} target="_blank" className="events-icon2" style={{ fontSize: "62px" }}>
                    {/* <FileThumbnail file={insuranceDetails?.insuranceCardId as any} /> */}
                    <div
                      className="img-thumbnail imageFit  imageFit avatar-2xl profile-user-wid-pro items-center margin_center mt-3 profileImageShow"
                      style={{
                        backgroundImage: `url("${Util.fileURL(insuranceData?.insuranceCardId)}")`,
                        borderRadius: "0px",
                        //   position: "relative",
                        width: "120px",
                        height: "130px",
                      }}
                    ></div>
                  </Link>
                </div>
              </div>
            )}

            {insuranceData?.insuranceCardBackId != null && (
              <div className="flex  mb-2">
                <div className="mr-2">
                  <Link
                    to={{ pathname: Util.fileURL(insuranceData?.insuranceCardBackId) }}
                    target="_blank"
                    className="events-icon2"
                    style={{ fontSize: "62px" }}
                  >
                    {/* <FileThumbnail file={insuranceDetails?.insuranceCardBackId as any} /> */}
                    <div
                      className="img-thumbnail imageFit  imageFit avatar-2xl profile-user-wid-pro items-center margin_center mt-3 profileImageShow"
                      style={{
                        backgroundImage: `url("${Util.fileURL(insuranceData?.insuranceCardBackId)}")`,
                        borderRadius: "0px",
                        width: "120px",
                        height: "130px",
                        //   position: "relative",
                      }}
                    ></div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", marginTop: "20px", fontWeight: "bold" }}>Insurance Provider</label>
          <input
            type="text"
            value={insuranceData.insuranceCompanyId?.insuranceCompany || "-"}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f9fafb",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Policy Number</label>
          <input
            type="text"
            value={insuranceData.subscriber?.policyNumber || "-"}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f9fafb",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Copay Amount</label>
          <input
            type="text"
            value={insuranceData.insuranceCompanyId?.coPayment || "-"}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f9fafb",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Billing Address</label>
          <input
            type="text"
            value={insuranceData.subscriber?.address?.state || "-"}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f9fafb",
            }}
          />
        </div>
      </form>
    );
  };

  return (
    <div style={{ maxWidth: "100%", padding: "10px" }}>
      <h3 style={{ color: "#f27e0c", marginTop: "3px" }}>Insurance and Billing</h3>
      {isLoading ? (
        <div className={"d-flex justify-content-center align-items-center"} style={{minHeight:"25vh",width:"100%",position:"relative"}}>
          <Spinner className={"bouncer"} />
        </div>
      ) : (
        <div>
          {insuranceProviderList.length > 0 ? (
            <div className="card">
              <ul className="nav nav-tabs">
                <li key="primary" className="saas-nav-item">
                  <button className={`saas-nav-link ${activeTab === "primary" ? "active" : ""}`}
                          onClick={() => setActiveTab("primary")}>
                    Primary Insurance
                  </button>
                </li>
                <li key="secondary" className="saas-nav-item">
                  <button className={`saas-nav-link ${activeTab === "secondary" ? "active" : ""}`}
                          onClick={() => setActiveTab("secondary")}>
                    Secondary Insurance
                  </button>
                </li>
              </ul>
              <div className="client-tab-content">{renderTabContent()}</div>
            </div>
          ) : (
            <p className="text-center text-muted font-italic">No insurance details found.</p>
          )}
        </div>)}
    </div>
  );
};

export default InsuranceSaas;
