import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import RightBar from "../ArticleList/RightBar";

import ArticleGrid from "./ArticleGrid";

const Index: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState();

  return (
    <React.Fragment>
      <div className="page-content">
        <title>Article Grid | Lavni</title>
        <Container fluid>
          <Breadcrumbs title="Article" breadcrumbItem="Article Grid" />
          <Row>
            <RightBar setSearchQuery={setSearchQuery} />
            <ArticleGrid searchQuery={searchQuery} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
