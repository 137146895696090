import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import { ExperienceTag, SelectedExperienceTag } from "../../../models/ExperienceTag";
import { HashTag, SelectedHashTag } from "../../../models/HashTag";
import { ArticleService } from "../../../services/ArticleService";
import { PopularPostResponse } from "../../../models/Article";
import defaultImage from "../../../assets/images/profile-img.png";
import moment from "moment";
import ClientFilters from "./ArticleFilters/ClientFilters";
import TherapistFilters from "./ArticleFilters/TherapistFilters";
import FilterModal from "./ArticleFilters/FilterModal";
import { toast } from "react-toastify";
import { Util } from "../../../Util";

toast.configure();

interface RightBarProps {
  setSearchQuery?: any;
}

const RightBar: React.FC<RightBarProps> = ({ setSearchQuery }: RightBarProps) => {
  const [userDetails] = useContext(UserContext);
  const [experienceTags, setExperienceTags] = useState<ExperienceTag[]>([]);
  const [hashTags, setHashTags] = useState<HashTag[]>([]);
  const [selectedExperienceTags, setSelectedExperienceTags] = useState<SelectedExperienceTag[]>([]);
  const [selectedHashTags, setSelectedHashTags] = useState<SelectedHashTag[]>([]);
  const [selectedOwnership, setSelectedOwnership] = useState("all");
  const [popularPosts, setPopularPosts] = useState<PopularPostResponse[]>([]);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [isView, setIsView] = useState<boolean>(true);

  const SCREEN_SIZE = 991;

  useEffect(() => {
    getAllExperienceTags();
    getAllHashTags();
    getPopularPosts();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags()
      .then((res: any) => {
        if (res.success) {
          setExperienceTags(
            res.data.map((tag: any) => {
              return { value: tag._id, label: tag.experienceTag };
            })
          );
        } else {
          setExperienceTags([]);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getAllHashTags = () => {
    ArticleService.getAllHashTags()
      .then((res: any) => {
        if (res.success) {
          setHashTags(
            res.data.map((tag: any) => {
              return { value: tag._id, label: tag.name };
            })
          );
        } else {
          setHashTags([]);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getPopularPosts = () => {
    ArticleService.getPopularPosts()
      .then((res: any) => {
        if (res.success) {
          setPopularPosts(res.data);
        } else {
          setPopularPosts([]);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const removeExperienceTag = (removedTag: string) => {
    setSelectedExperienceTags(selectedExperienceTags.filter(tag => tag.label !== removedTag));
  };

  const removeHashTag = (removedtag: string) => {
    setSelectedHashTags(selectedHashTags.filter(tag => tag.label !== removedtag));
  };

  const handleExperienceTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label, value: tag.value };
      });

      if (updatedTags) {
        setSelectedExperienceTags(updatedTags);
      }
    }
  };

  const handleHashTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any; value: any }) => {
      return { label: tag.label, value: tag.value };
    });
    if (updatedTags) {
      setSelectedHashTags(updatedTags);
    }
  };

  const setQuery = () => {
    const searchQuery = {
      experienceTags: selectedExperienceTags.map(item => {
        return item.value;
      }),
      hashTags: selectedHashTags.map(item => {
        return item.label;
      }),
      role: "CLIENT",
    };
    setSearchQuery(searchQuery);

    if (screenSize < SCREEN_SIZE) {
      setShowModal(!showModal);
    }
  };

  const setTherapistQuery = () => {
    const query = {
      searchTags: selectedExperienceTags.map(item => {
        return item.value;
      }),
      type: selectedOwnership,
      role: "THERAPIST",
    };
    setSearchQuery(query);

    if (screenSize < SCREEN_SIZE) {
      setShowModal(!showModal);
    }
  };

  const toggleFilters = () => {
    setShowModal(!showModal);
  };

  const hideAndShow = () => {
    setIsView(!isView);
  };

  return (
    <React.Fragment>
      <Col xl={3} lg={4}>
        <Card>
          <div className="cd-anim d-flex">
            <span className=" more-icon">More</span> &nbsp;
            {isView ? (
              <i className="bx bxs-down-arrow mr-2 more-icon" id="ico" onClick={hideAndShow}></i>
            ) : (
              <i className="bx bxs-up-arrow mr-2 more-icon" id="ico" onClick={hideAndShow}></i>
            )}
            <UncontrolledTooltip placement="left" target="ico">
              Article Filter & Popular Post
            </UncontrolledTooltip>
          </div>

          <CardBody className={`p-4 ${!isView ? "is-view" : "is-hide"}`}>
            {userDetails && userDetails?.role == "THERAPIST" ? (
              <>
                <div className="text-muted f-18 mb-3">Looking to create an article?</div>
                <Link to="/create-article" className="btn btn-primary .btn.block w-100 mb-3">
                  Create New Article
                </Link>
              </>
            ) : (
              <></>
            )}

            {screenSize > SCREEN_SIZE ? (
              <>
                {userDetails && userDetails?.role == "CLIENT" ? (
                  <ClientFilters
                    experienceTags={experienceTags}
                    handleExperienceTags={handleExperienceTags}
                    handleHashTags={handleHashTags}
                    hashTags={hashTags}
                    selectedExperienceTags={selectedExperienceTags}
                    removeExperienceTag={removeExperienceTag}
                    removeHashTag={removeHashTag}
                    selectedHashTags={selectedHashTags}
                    setQuery={setQuery}
                  />
                ) : (
                  <TherapistFilters
                    experienceTags={experienceTags}
                    handleExperienceTags={handleExperienceTags}
                    removeExperienceTag={removeExperienceTag}
                    selectedExperienceTags={selectedExperienceTags}
                    selectedOwnership={selectedOwnership}
                    setSelectedOwnership={setSelectedOwnership}
                    setTherapistQuery={setTherapistQuery}
                  />
                )}
              </>
            ) : (
              <button className="btn btn-primary .btn.block w-100" onClick={toggleFilters}>
                View Filters
              </button>
            )}

            {showModal && (
              <FilterModal
                showModal={showModal}
                setShowModal={setShowModal}
                userRole={userDetails?.role}
                experienceTags={experienceTags}
                handleExperienceTags={handleExperienceTags}
                removeExperienceTag={removeExperienceTag}
                selectedExperienceTags={selectedExperienceTags}
                selectedOwnership={selectedOwnership}
                setSelectedOwnership={setSelectedOwnership}
                setTherapistQuery={setTherapistQuery}
                handleHashTags={handleHashTags}
                hashTags={hashTags}
                removeHashTag={removeHashTag}
                selectedHashTags={selectedHashTags}
                setQuery={setQuery}
              />
            )}

            <hr className="my-4" />

            <div>
              <p className="text-muted mb-2 f-18">Popular Posts</p>

              {popularPosts &&
                popularPosts.map((item: any, index: number) => {
                  return (
                    <div className="list-group list-group-flush" key={index}>
                      <Link to={`/article-details/${item._id._id}`} className="list-group-item text-muted py-3 px-2">
                        <div className="d-flex align-items-center">
                          <div className="me-3">
                            {item._id.uploadId && (
                              <>
                                {item._id.uploadId.type.indexOf("video") !== -1 ? (
                                  <img src={defaultImage} className="avatar-md h-auto d-block rounded" />
                                ) : (
                                  <img src={Util.fileURL(item._id.uploadId._id)} className="avatar-md h-auto d-block rounded" />
                                )}
                              </>
                            )}
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-13 text-truncate">{item._id.articleTitle}</h5>
                            <p className="mb-0 text-truncate">{moment(item._id.createdAt).format("YYYY/MM/DD")}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightBar;
