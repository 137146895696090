import React, { useContext, useEffect, useState } from "react";
import UserContext from "src/context/UserContext";
import { ClientService } from "src/services/ClientService";
import { Button, Row, Col, CardTitle, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Spinner from "src/common/spinner/spinner";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CardContent, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
const classNames = require("classnames");
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontWeight: 600,
      fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":focus": {
        color: "#424770",
      },

      "::placeholder": {
        color: "#9BACC8",
      },

      ":focus::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#fb1c50",
      ":focus": {
        color: "#FA755A",
      },
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  },
};


const CopaymentDetails = () => {
  const [user, setUser] = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const [coPaymentAmount, setCopaymentAmount] = useState(0);
  const [showImcompleteCoPayments, setShowIncompleteCopayments] = useState(false);
  const [isLoading, isSetLoading] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [totalCopaymentAmounts, setTotalCopaymentAmounts] = useState<any[]>([] as any[]);
  const [totalPaidCopaymentAmounts, setTotalPaidCopaymentAmounts] = useState<any[]>([] as any[]);
  const [isUpating, setIsUpating] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const history = useHistory();

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    unpaidAmount();
    getPaidAmount();
  }, []);

  const handleCreatedCustomer = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe.js has not yet loaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return;
    }

    setIsSubscribing(true);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
      billing_details: {
        email: user?.email,
      },
    });

    if (result.error) {
      toast.error(result.error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setIsSubscribing(false);
    } else {
      const subscriptionDetails = {
        paymentMethod: result.paymentMethod.id,
      };

      ClientService.createStripeAccountAndPay(subscriptionDetails).then(res => {
        if (res.success) {
          toast.success("Congratulations! Your card details has been added successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setIsSubscribing(false);
          setCopaymentAmount(0)
          setTotalCopaymentAmounts([])
          elements.getElement(CardElement)?.clear();
        } else {
          toast.error(res.error ? res.error : "Failed to add your card details. Please contact support.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setIsSubscribing(false);

          return;
        }
      });
    }
  };


  const unpaidAmount = async () => {
    isSetLoading(true)
    const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
    if (meetingRes.success) {
      let totalAmount = 0;
      for (const meeting of meetingRes.data) {
        const amount = parseFloat(meeting?.copayment?.amount) || 0;
        totalAmount += amount;
      }

      if (totalAmount > 0) {
        setCopaymentAmount(totalAmount)
        setTotalCopaymentAmounts(meetingRes.data)
        setShowIncompleteCopayments(true)
        isSetLoading(false)
      }
    }
  };

  const getPaidAmount = async () => {
    isSetLoading(true)
    const meetingRes: any = await ClientService.getAllPaidCopaymentAmounts(user?._id);
    
    if (meetingRes.success) {
      setTotalPaidCopaymentAmounts(meetingRes.data)
      setShowIncompleteCopayments(true)
      isSetLoading(false)
    }
  };


  const setPaidCoAmount = () => {
    setIsUpating(true);
    if (coPaymentAmount > 0) {
      ClientService.payAllPendingCopaymentAmounts().then(res => {
        if (res.success) {
          toast.success("Co-payment is successfully made!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setIsUpating(false);
          setCopaymentAmount(0)
          setTotalCopaymentAmounts([])
          getPaidAmount();
        } else {
          setIsUpating(false);

          Swal.fire({
            icon: "warning",
            title: "Something is wrong with your card details. Please check your card details or add new card & try again.",
            showCancelButton: true,
            confirmButtonText: "Navigate to payment option page2",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#d33",
          }).then(result => {
            if (result.isConfirmed) {
              history.push(`/payment-history/2`);
            }
          });
        }
      })
    }
  }

  return (
    <div>
      <Row>
        <div className="row">
          <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({ active: customActiveTab === "1" })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="mdi mdi-view-grid-outline"></i>
                </span>

                <span className="d-none d-sm-block">Pending Co-payments</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({ active: customActiveTab === "2" })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="mdi mdi-format-list-bulleted"></i>
                </span>

                <span className="d-none d-sm-block">Paid Co-payments</span>
              </NavLink>
            </NavItem>
          </Nav>
          <div className="table-responsive">
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="1">

                <div className="my-sub-sec withdraw-money">
                  {!isLoading ? (
                    <>
                      {coPaymentAmount > 0 ?
                        <>
                          <CardTitle className="mb-4 text-center font-size-20">You need to pay a ${coPaymentAmount} co-payment before joining the call.</CardTitle>
                          <div className="table-responsive">
                            {totalCopaymentAmounts !== undefined ? (
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Therapist</th>
                                    <th scope="col">Meeting Status</th>
                                    <th scope="col">Co payment</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    totalCopaymentAmounts && totalCopaymentAmounts.map((t, index) => (
                                      <tr key={index}>
                                        <td>{t.createdAt}</td>
                                        <td> {t.therapistId.firstname} {t.therapistId.lastname}</td>
                                        <td>{t.callingStatus}</td>
                                        <td>{t.copayment.amount}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            ) : (
                              <div>No Profession License</div>
                            )
                            }

                          </div>

                          {user?.stripeCustomerId ?
                            <div className="flex float-right">
                              <button type="button" className="btn btn-info mr-2" onClick={setPaidCoAmount} disabled={isUpating}>
                                Pay
                              </button>
                            </div>
                            :
                            <div>
                              <CardContent className="pay-content pay-content-border mb-4">
                                <TextField
                                  label="Email"
                                  id="outlined-email-input"
                                  helperText={`Email you'll recive updates and receipts on`}
                                  margin="normal"
                                  variant="outlined"
                                  type="email"
                                  required
                                  value={user?.email}
                                  disabled={true}
                                  fullWidth
                                />

                                <CardElement options={CARD_ELEMENT_OPTIONS} className="card-element" />

                                <div className="pay-div">
                                  <Button
                                    className="pay-btn btn btn-success"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreatedCustomer}
                                    disabled={isSubscribing}
                                  >
                                    {isSubscribing ? "Please Wait..." : "Pay"}
                                  </Button>
                                </div>
                              </CardContent>
                            </div>
                          }
                        </>
                        :
                        <CardTitle className="mb-4 text-center font-size-20">You don&apos;t have to pay any co-payment at this time.</CardTitle>
                      }
                    </>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </TabPane>

              <TabPane tabId="2">
                <Row>
                  <Col sm="12" className="mt10">
                    <div className="my-sub-sec withdraw-money">

                      {!isLoading ? (
                        <>
                          <div className="table-responsive">
                            {totalPaidCopaymentAmounts !== undefined ? (
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Therapist</th>
                                    <th scope="col">Meeting Status</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Co payment</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    totalPaidCopaymentAmounts && totalPaidCopaymentAmounts.map((t, index) => (
                                      <tr key={index}>
                                        <td>{t.createdAt}</td>
                                        <td> {t.therapistId.firstname} {t.therapistId.lastname}</td>
                                        <td>{t.callingStatus}</td>
                                        <td>{t.copayment.status}</td>
                                        <td>{t.copayment.amount}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            ) : (
                              <CardTitle className="mb-4 text-center font-size-20">No Paid Co-payments</CardTitle>
                            )
                            }

                          </div>
                        </>
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default CopaymentDetails