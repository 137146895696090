import React from 'react';
import moment from 'moment';
import { StepProps } from './types';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PatientInfo: React.FC<StepProps> = ({ onNext, onBack, currentStep, searchDocumentDownloadsByAdminSelected }) => {
  const [patientInfo, setPatientInfo] = React.useState({
    name: searchDocumentDownloadsByAdminSelected?.client?.fullName || '',
    gender: searchDocumentDownloadsByAdminSelected?.client?.gender || '',
    dateOfBirth: searchDocumentDownloadsByAdminSelected?.client?.dateOfBirth ? moment.utc(searchDocumentDownloadsByAdminSelected.client.dateOfBirth).format('MM/DD/YYYY') :'',
    memberId: searchDocumentDownloadsByAdminSelected?.client?.memberId || ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPatientInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="step-container">
      <h4 className="mb-4">Patient Information</h4>
      <Form>
        <FormGroup>
          <Label>Patient Name:</Label>
          <Input
            type="text"
            name="name"
            value={patientInfo.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Gender:</Label>
          <Input
            type="text"
            name="gender"
            value={patientInfo.gender}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Date of Birth: (MM/DD/YYYY)</Label>
          <DatePicker
            selected={patientInfo.dateOfBirth ? moment(patientInfo.dateOfBirth, 'MM/DD/YYYY').toDate() : null}
            onChange={(date: Date) => {
              setPatientInfo(prev => ({
                ...prev,
                dateOfBirth: date ? moment(date).format('MM/DD/YYYY') : ''
              }));
            }}
            dateFormat="MM/dd/yyyy"
            className="form-control"
            placeholderText="MM/DD/YYYY"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <Label>Member ID:</Label>
          <Input
            type="text"
            name="memberId"
            value={patientInfo.memberId}
            onChange={handleChange}
          />
        </FormGroup>
        <div className="d-flex justify-content-end mt-4" style={{ gap: '10px' }}>
          <Button color="primary" onClick={() => onNext([
            patientInfo.name,
            patientInfo.gender,
            patientInfo.dateOfBirth,
            patientInfo.memberId
          ])}>
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PatientInfo;
