import React, { useEffect, useState } from "react";
import FeedbackContext from "src/context/UserFeedbackCount";
import { AdminService } from "src/services/AdminService";

const FeedbackUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadFeedbackCount, setUnreadFeedbackCount] = useState<number>(0);
  const [offset] = useState(0);

  const LIMIT = 0;

  useEffect(() => {
    AdminService.getAllFeedbacks(LIMIT, offset).then(res => {
      if (res.success) {
        setUnreadFeedbackCount(res.data.count)
      }
    });
  }, []);

  return <FeedbackContext.Provider value={[unreadFeedbackCount, setUnreadFeedbackCount]}>{children}</FeedbackContext.Provider>;
};

export default FeedbackUtils;