import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { Card, Col, Row, Table } from "reactstrap";
import moment from "moment";
import { DigitalForm } from "src/models/AssessmentDigitalForm/AssessmentDigitalForms";
import {
  AlcoholAndDrugUseHistoryStatus,
  AnxietySymptoms,
  AttentionSymptoms,
  ConductLegalProblem,
  ConsumerPhone,
  DDsCare,
  DepressiveSymptoms,
  Diagnose,
  DiagnosesRecommendations,
  DisabilityStatus,
  ExperiencedSymptoms,
  GenderBirth,
  GuardianPhone,
  InpatientTreatementHistoryApplicableEnum,
  InPatienttreatementHistoryDetails,
  LastVisitPrimaryCarePhysician,
  ManicSymptoms,
  NameOfSubstance,
  NutritionalStatus,
  OtherBiologicalFunction,
  OtherSupports,
  PhysicianDiagnosedConditions,
  ProblemWithUse,
  Psychosis,
  ReceivingTreatementPainStatus,
  ReceivingTreatementStatus,
  SexualActivityWith,
  Sleep,
  TreatementDetails,
  treatementHistoryDetailsType,
} from "src/models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";
import { htmlToText } from "html-to-text";
import "./index.css";
import Select from "react-select";
import { CPTCode } from "./../../../models/DiagnosisNote";

const AssessmentForm = (props: any, ref: any) => {
  const { assesmenData } = props;
  const [formData, setFormData] = useState<DigitalForm>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    generalInformation: {
      name: "",
      dob: "",
      knownAs: "",
      age: "",
      primaryLanguage: {
        primaryLanguageReadablityStatus: null,
        english: null,
        other: null,
        otherLanguage: "",
      },
      consumerPhone: {
        consumerPhone: {
          home: null,
          work: null,
          cellPhone: null,
          other: null,
        },
        otherConsumerPhoneType: "",
        otherConsumerPhoneNumber: "",
      },
      legalGuarianship: {
        legalGuarianship: {
          self: null,
          other: null,
        },
        otherLegalGuarianship: "",
      },
      guardianPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
        guardianPhoneNumber: "",
      },
      primaryInformant: {
        primaryInformant: {
          self: null,
          other: null,
        },
        other: "",
      },
      informantPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
      },
      genderAtBirth: {
        male: null,
        female: null,
        femalePregnant: null,
      },
      genderIdentity: {
        male: null,
        female: null,
        nonBinary: null,
      },
      sexualOrientation: {
        sexualOrientations: "",
        isInterestingLGBTService: null,
      },
      ethnicity: {
        notHispanicOrigin: null,
        hispanicCuban: null,
        hispanicMexicanAmerican: null,
        hispanicPuertoRican: null,
        hispanicOther: null,
      },
      raceType: {
        race: {
          blackAfricanAmerican: null,
          alaskaNative: null,
          whiteCaucasian: null,
          asian: null,
          americanIndianNativeAmerican: null,
          pacificIslander: null,
          multiracial: null,
          other: null,
        },
        otherRace: "",
      },
      livingArrangement: {
        livingArrangement: {
          privateResidence: null,
          institution: null,
          ownResidence: null,
          other: null,
          roomHouseDorm: null,
          adultCareHome: null,
          alternativeFamilyLiving: null,
          nursingHome: null,
          communityICFMR: null,
          correctionalFacility: null,
          homeless: null,
          residentialFacility: null,
        },
        other: "",
      },
      maritalStatus: {
        married: null,
        widowed: null,
        divorced: null,
        singleNeverMarried: null,
        separated: null,
        domesticPartnership: null,
      },
      familySize: "",
      currentEmploymentStatus: {
        unemployed: null,
        notavailableforwork: null,
        employedFullTime: null,
        homemaker: null,
        armedForcesNationalGuard: null,
        employedPartTime: null,
        retired: null,
        student: null,
        disabilityIncome: null,
      },
      employmentHistory: "",
      education: {
        highestGradeCompleted: null,
        highSchoolgraduate: null,
        ged: null,
        someCollege: null,
        associateDegree: null,
        bachelordegree: null,
        graduateSchool: null,
        specialEdClasses: null,
        technicalTradeSchool: null,
      },
    },
    presentingProblem: {
      description: "",
      historyOfProblem: "",
    },
    historyOfProblem: "",
    symptomChicklist: {
      depressiveSymptoms: { symtoms: {}, comment: "" },
      manicSymptoms: { symtoms: {}, comment: "" },
      conductLegalProblem: { symtoms: {}, comment: "" },
      psychosis: { symtoms: {}, comment: "" },
      anxietySymptoms: { symtoms: {}, comment: "" },
      attentionSymptoms: { symtoms: {}, comment: "" },
    },
    biologicalFunction: {
      sleep: { sleepStatus: {}, comment: "" },
      nutritionalStatus: { nutritionalStatus: {}, comment: "" },
      otherBiologicalFunction: {
        amenorrhea: "",
        encopresis: "",
        increased: "",
        decreased: "",
        other: "",
        enuresis: "",
      },
      sexualActivity: {
        sexuallyActive: { sexuallyActiveness: null, activeWith: "" },
        protectionAgainstHepatitisHiv: { protection: null, how: "" },
        protectionAgainstPregnancy: { protection: null, how: "" },
        atRiskBehavior: { risk: null, describe: "" },
        otherSymtoms: "",
        comments: "",
      },
    },
    alcoholAndDrugUseHistory: {
      historyStatus: "",
    },
    nameOfSubstance: [
      {
        ageOfFirstUse: "",
        regularUse: "",
        dateLastUse: "",
        route: "",
        amount: 0,
        frequency: 0,
        problemWithUse: {},
        comment: "",
      },
    ],
    tobaccoUseTypeDetails: {
      tobaccoUse: null,
      tobaccoType: "",
      howOften: "",
      howMuch: "",
      howLong: "",
      interestEndUse: null,
      interestReducingUse: null,
      endingUse: "",
      longestAbstinence: "",
      whenWas: "",
      relapse: "",
      comment: "",
      experiencedSymptoms: {
        agitationRestlessness: "",
        confusion: "",
        nauseaVomiting: "",
        muscleTwitching: "",
        chills: "",
        deliriumTremens: "",
        tremorsShaking: "",
        anxiety: "",
        racingPulse: "",
        rapidBreathing: "",
        sweats: "",
        hallucinations: "",
        backJointPain: "",
        other: "",
        difficultySleeping: "",
        runnyNose: "",
        tearyEyes: "",
        craving: "",
        cramps: "",
        seizures: "",
        none: "",
      },
    },
    releventMedicalInformation: {
      physicianDiagnosedConditions: {},
      cancerType: "",
      otherType: "",
      lastVisitPrimaryCarePhysician: "",
      comment: "",
      anyAllergies: "",
      hypersensitivities: "",
      patientPainLevel: {
        currentLevelOfPhysicalPain: 0,
        isCurrentReceivingTreatementPain: "",
        isReceivingTreatement: "",
        treatementDetails: [],
        disablityStatus: {
          hearingImpairment: "",
          sightImpairment: "",
          intellectualDevelopmentalDisability: "",
          other: "",
          none: "",
          comment: "",
        },
        adjustDisability: "",
        requireEquipmentOrServices: "",
      },
    },
    outPatientTreatementHistory: {
      applicableState: null,
      outPatienttreatementHistoryDetails: [],
    },
    inpatientTreatementHistory: {
      applicableState: "",
      inPatientTreatementHistory: [],
      pastTreatement: "",
      additionalMedicalInformation: "",
    },
    bioPsychosocialDevelopemntHistory: {
      developmentHistory: "",
      childhoodHistory: "",
      fosterCare: "",
      siblings: "",
      familyHistoryOfMHOrSAIssues: "",
      currentSpouse: "",
      childrenStepChildren: "",
      relationsIssues: "",
      otherSupports: {
        family: "",
        church: "",
        employer: "",
        friends: "",
        other: "",
      },
    },
    bioPsychosocialEducation: {
      isApplicable: null,
      schoolGradeName: "",
      lastIep: "",
      teacher: "",
      learningAbility: "",
      disabilityArea: "",
      educationalProblems: "",
      behaviorProblem: "",
      repetedGrades: "",
      socialInteraction: "",
      suspension: "",
      expulsion: "",
      isPsychologicalTestingCompleted: null,
      date: "",
      expulsionTestResults: "",
    },
    traumaHistory: {
      isBadAccident: null,
      badAccidentDescribe: "",
      isNaturalDisaster: null,
      naturalDisasterdDescribe: "",
      isMilitaryCombat: null,
      militoryCombatDescribe: "",
      isSeriousAttack: null,
      seriousAttackDescribe: "",
      isSexualCoercion: null,
      ageOfSexualCoercion: 0,
      describeSexualCoercion: "",
      isTraumaticExperience: null,
      describeTraumaticExperience: "",
      intrusiveThoughts: null,
      avoidanceBehavior: null,
      hypervigilance: null,
      emotionalDetachment: null,
      selfBlame: null,
    },
    riskToSelfAndOthers: {
      currentRiskToSelf: {
        isSelfInjured: null,
        describeSelfInjured: "",
        isSuicidalThreats: null,
        describeSuicidalThreats: "",
        repetitiveIdeation: null,
        isSuicidePlan: null,
        isLethalIntent: null,
        isCommandHallucinations: null,
        describeCommandHallucinations: "",
      },
      currentRiskToOthers: {
        isHomicidalThreats: null,
        isPersistentIdeation: null,
        isSpecificPlan: null,
        isLethalIntent: null,
        isHallucinationCommands: null,
        describeHallucination: "",
      },
      historyOfSuicidalBehavior: {
        isHistoryOfSuicidalBehavior: null,
        describeHistoryOfSuicidalBehavior: "",
        isHomicidalBehavior: null,
        describeHomicidalBehavior: "",
        isSeriousHarmSelfOthers: null,
        describeSeriousPhysicalHarm: "",
        isAggressionViolenceOthers: null,
        describeAggression: "",
      },
      evaluationOfRisk: {
        selfHarming: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        assaultive: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        actionEvaluation: "",
        beliefSystem: "",
        roleOfBeliefinlife: "",
        roleOfBeliefRecovery: "",
      },
    },
    mentalStatus: {
      physicalStature: {
        small: null,
        average: null,
        tall: null,
      },
      weight: {
        thin: null,
        average: null,
        overweight: null,
        obese: null,
      },
      grooming: {
        wellGroomed: null,
        normal: null,
        neglected: null,
        bizarre: null,
      },
      clothing: {
        neatClean: null,
        inappropriate: null,
        dirty: null,
        seductive: null,
        disheveled: null,
        bizarre: null,
      },
      posture: {
        normal: null,
        tense: null,
        stooped: null,
        rigid: null,
        slumped: null,
        bizarre: null,
      },
      attitude: {
        cooperative: null,
        passive: null,
        guarded: null,
        irritable: null,
        manipulative: null,
        seductive: null,
        suspicious: null,
        defensive: null,
        dramatic: null,
        silly: null,
        hostile: null,
        critical: null,
        resistant: null,
        sarcastic: null,
        uninterested: null,
        argumentative: null,
      },
      motor: {
        nonremarkable: null,
        tremor: null,
        slowed: null,
        tics: null,
        restless: null,
        agitated: null,
      },
      speech: {
        normal: null,
        rapid: null,
        slurred: null,
        loud: null,
        paucity: null,
        pressured: null,
        mute: null,
      },
      affect: {
        appropriate: null,
        inappropriate: null,
        flat: null,
        restricted: null,
        blunted: null,
        labile: null,
      },
      mood: {
        euthymic: null,
        confused: null,
        pessimistic: null,
        depressed: null,
        anxious: null,
        euphoric: null,
        apathetic: null,
        angry: null,
      },
      thoughtForm: {
        goaldirected: null,
        appropriate: null,
        logical: null,
        tangentialthinking: null,
        circumstantial: null,
        looseassociations: null,
        confused: null,
        incoherent: null,
        perseverations: null,
        flightofidea: null,
        slownessofthought: null,
      },
      thoughtContent: {
        appropriate: null,
        paranoid: null,
        suspicions: null,
        persecutions: null,
        paucity: null,
        delusions: null,
        bizarre: null,
        hypochondriac: null,
        ideasofreference: null,
      },
      preoccupations: {
        phobias: null,
        guilt: null,
        other: null,
        somatic: null,
        religion: null,
        suicide: null,
        homicidal: null,
      },
      hallucinations: {
        auditory: null,
        other: null,
        visual: null,
        sensory: null,
      },
      orientation: {
        person: null,
        place: null,
        time: null,
        situation: null,
      },
      levelOfIntellectualFunctioning: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      fundofKnowledge: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      judgment: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      insightIntoProblems: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      clinicalImpressionSummary: "",
    },
    releventDDSInformation: {
      ddsInvolvement: null,
      ddsCare: "",
      ddsCareDescription: "",
      ddsSocialWorker: "",
      phone: "",
    },
    relevantLegalInformation: {
      legalInvolvement: null,
      countOfArrestet: 0,
      currentCharges: "",
      attorneyName: "",
      currentProbationRequirement: "",
      probationOffice: "",
      previousChargesJailOrPrison: "",
    },
    diagnoseRecommendationDetails: {
      diagnosis: [],
      recommendation: {},
      printedName: "",
      signature: "",
      date: "",
    },
  });

  const [cptCodeList] = useState([
    { cptCode: "-1", cptCodeDescription: "Select" },
    { cptCode: "90791", cptCodeDescription: "Psychiatric diagnostic evaluation" },
    { cptCode: "90792", cptCodeDescription: "Psychiatric diagnostic evaluation with medical services" },
    { cptCode: "90832", cptCodeDescription: "Psychotherapy, 16-37 minutes" },
    { cptCode: "90833", cptCodeDescription: "Psychotherapy, 16-37 minutes with E/M service, listed seperately" },
    { cptCode: "90834", cptCodeDescription: "Psychotherapy, 38-52 minutes" },
    { cptCode: "90836", cptCodeDescription: "Psychotherapy, 38-52 minutes with E/M service, listed seperately" },
    { cptCode: "90837", cptCodeDescription: "Psychotherapy, 53+ minutes" },
    { cptCode: "90838", cptCodeDescription: "Psychotherapy, 53+ minutes with E/M service, listed seperately" },
    { cptCode: "90839", cptCodeDescription: "Psychotherapy for crisis, 30-74 minutes" },
    { cptCode: "90840", cptCodeDescription: "Psychotherapy for crisis, each additional 30 minutes beyond initial 74min, upto two add-ons per 90839" },
    { cptCode: "90846", cptCodeDescription: "Family psytx w/o patient 50 minutes" },
    { cptCode: "90847", cptCodeDescription: "Family psytx w/patient 50 minutes" },
    { cptCode: "90853", cptCodeDescription: "Group psychotherapy" },
    { cptCode: "00001", cptCodeDescription: "Non Billable" },
  ]);

  useEffect(() => {
    if (assesmenData) {
      const updateValues = {
        formHeader: assesmenData?.formHeader,
        generalInformation: assesmenData?.generalInformation,
        presentingProblem: assesmenData?.presentingProblem,
        historyOfProblem: assesmenData?.historyOfProblem,
        symptomChicklist: assesmenData?.symptomChicklist,
        biologicalFunction: assesmenData?.biologicalFunction,
        alcoholAndDrugUseHistory: assesmenData?.alcoholAndDrugUseHistory,
        nameOfSubstance: assesmenData?.nameOfSubstance,
        tobaccoUseTypeDetails: assesmenData?.tobaccoUseTypeDetails,
        releventMedicalInformation: assesmenData?.releventMedicalInformation,
        outPatientTreatementHistory: assesmenData?.outPatientTreatementHistory,
        inpatientTreatementHistory: assesmenData?.inpatientTreatementHistory,
        bioPsychosocialDevelopemntHistory: assesmenData?.bioPsychosocialDevelopemntHistory,
        bioPsychosocialEducation: assesmenData?.bioPsychosocialEducation,
        traumaHistory: assesmenData?.traumaHistory,
        riskToSelfAndOthers: assesmenData?.riskToSelfAndOthers,
        mentalStatus: assesmenData?.mentalStatus,
        releventDDSInformation: assesmenData?.releventDDSInformation,
        relevantLegalInformation: assesmenData?.relevantLegalInformation,
        diagnoseRecommendationDetails: assesmenData?.diagnoseRecommendationDetails,
      };
      setFormData(updateValues);
    }
  }, [assesmenData]);

  const customStylesForICD10Codes = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any) => ({
      ...styles,
      borderColor: "#ced4da",
    }),
  };

  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);
  console.log(rowRefs);
  // const addRowRef = (index: number, el: HTMLDivElement | null) => {
  //   rowRefs.current[index] = el;
  // };
  let index = 0;

  const addRowRef = (el: HTMLDivElement | null) => {
    if (el !== null) {
      rowRefs.current[index] = el;
      index++;
    }
  };

  // const getRowHeights = () => {
  //   console.log("call get heights");
  //   return rowRefs.current.map(row => row?.offsetHeight || 0);
  // };

  // Use useImperativeHandle to expose `getRowHeights` to the parent component
  useImperativeHandle(
    ref,
    () => ({
      getRowHeights: () =>
        rowRefs.current
          .filter((rowRef): rowRef is HTMLDivElement => rowRef !== null) // Filters out nulls
          .map(rowRef => ({
            element: rowRef,
            height: rowRef.offsetHeight || 0,
          })),
    }),
    [rowRefs]
  );

  function htmlToTextFunction(htmlTextMessage: string) {
    try {
      if (htmlTextMessage) {
        const finalText = htmlToText(htmlTextMessage);
        return finalText;
      }
      return "";
    } catch (error) {
      return "";
    }
  }

  const sharedStyle: React.CSSProperties = {
    fontSize: "11px",
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    resize: "none",
    lineHeight: "1",
  };

  const formatDate = (date: any) => {
    if (!date) return "";
    return moment.utc(date).format("YYYY-MM-DD");
  };

  const sharedStyleForTable: React.CSSProperties = {
    fontSize: "0.70rem",
    width: "100%",
    padding: "2px",
    border: "none",
    resize: "none",
  };

  return (
    <>
      {/* Form-1 */}
      <div className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page page-break scrollable-section">
        <div className="mt-4"></div>

        <div ref={el => addRowRef(el)} className="card-bodyDiv">
          <Row>
            <Col lg={6}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Date:
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="date"
                      readOnly
                      value={moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD")}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Insurance ID :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly
                      value={formData?.formHeader?.insuranceId ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={5}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Last Name :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly
                      value={formData?.formHeader?.lastName ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={5}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      First Name :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly
                      value={formData?.formHeader?.firstname ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={2}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      MI :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly
                      value={formData?.formHeader?.mi ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row >
            <Col lg={4}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Street Address :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly={true}
                      value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={4}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Unit :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly={true}
                      value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={4}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      City :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly={true}
                      value={formData?.formHeader?.currentAddress?.city ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={6}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      State :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly={true}
                      value={formData?.formHeader?.currentAddress?.state ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Zip Code :
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      type="text"
                      readOnly={true}
                      value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <div className="">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                General Information
              </h5>
            </Card>

            <Card className="match-card-body p-3">
              <div style={{ overflowX: "auto" }}>
                <Row>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Name:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            readOnly
                            value={formData?.generalInformation?.name ? formData?.generalInformation?.name : ""}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Also Known As:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            readOnly
                            value={formData?.generalInformation?.knownAs ? formData?.generalInformation?.knownAs : ""}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col lg={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            DOB:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                            id="date"
                            value={moment.utc(formData?.generalInformation?.dob).format("YYYY-MM-DD")}
                            type="date"
                            readOnly
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Age:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            readOnly
                            value={formData?.generalInformation?.age ? formData?.generalInformation?.age : ""}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left" }}>
                  <tbody style={{ verticalAlign: "middle" }}>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Primary Language
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "50%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.primaryLanguage?.primaryLanguageReadablityStatus === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Difficulty reading in Primary Language
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <div className="col">
                                  <div className="col-auto">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      checked={formData?.generalInformation?.primaryLanguage?.english === true}
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                      English:{" "}
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td style={{ width: "20%" }}>
                                <div className="col">
                                  <div className="col-auto">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      checked={formData?.generalInformation?.primaryLanguage?.spanish === true}
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                      Spanish:{" "}
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      checked={formData?.generalInformation?.primaryLanguage?.other === true}
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                      Other:{" "}
                                    </label>
                                  </div>
                                  <div className="col">
                                    <div style={{ display: "flex" }}>
                                      <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                          <p>Other Language:</p>
                                        </h6>
                                      </div>
                                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <input
                                          style={{
                                            // width: "100%",

                                            marginTop: "0.1rem",
                                            backgroundColor: "unset",
                                            border: "1px dotted  #ccc",
                                            padding: "2px",
                                            outline: "none",
                                            borderRadius: "8px",
                                          }}
                                          type="text"
                                          readOnly
                                          value={formData?.generalInformation?.primaryLanguage?.otherLanguage || ""}
                                        />
                                      </Card>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Consumer Phone
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ tableLayout: "fixed", width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td colSpan={1}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.home === true}
                                />

                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Home
                                </label>
                              </td>
                              <td colSpan={1}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.work === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Work
                                </label>
                              </td>
                              <td colSpan={2}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.cellPhone === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cell Phone
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.other === true}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other (specify):{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          // border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                          border: "1px dotted  #ccc",
                                          padding: "2px",
                                          outline: "none",
                                          borderRadius: "8px",
                                        }}
                                        type="text"
                                        value={formData?.generalInformation?.consumerPhone?.otherConsumerPhoneType || ""}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>

                              <td colSpan={3}>
                                <div style={{ display: "flex" }}>
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                      <p>Consumer&apos;s Phone:</p>
                                    </h6>
                                  </div>
                                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                    <input
                                      style={{
                                        width: "100%",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                        border: "1px dotted  #ccc",
                                        padding: "2px",
                                        outline: "none",
                                        borderRadius: "8px",
                                      }}
                                      type="text"
                                      value={formData?.generalInformation?.consumerPhone?.otherConsumerPhoneNumber || ""}
                                    />
                                  </Card>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Legal Guardianship
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.legalGuarianship?.legalGuarianship?.self === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Self
                                </label>
                              </td>
                              <td style={{ width: "60%" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData?.generalInformation?.legalGuarianship?.legalGuarianship?.other === true}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other (specify):{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <textarea
                                        style={sharedStyle}
                                        readOnly
                                        value={formData?.generalInformation?.legalGuarianship?.otherLegalGuarianship ?? ""}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Guardian Phone
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ tableLayout: "fixed", width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.guardianPhone?.sameasAbove === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Same as above
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  value={GuardianPhone.home}
                                  checked={formData?.generalInformation?.guardianPhone?.home === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Home
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.guardianPhone?.work === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Work
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.guardianPhone?.cell === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cell
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={8}>
                                <div style={{ display: "flex" }}>
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                      guardian phone number:
                                    </h6>
                                  </div>
                                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                    <input
                                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                      type="text"
                                      value={formData?.generalInformation?.guardianPhone?.guardianPhoneNumber ?? ""}
                                      readOnly
                                    />
                                  </Card>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Primary Informant
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.primaryInformant?.primaryInformant?.self === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Self
                                </label>
                              </td>
                              <td style={{ width: "60%" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData?.generalInformation?.primaryInformant?.primaryInformant?.other === true}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other (specify):{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <textarea style={sharedStyle} readOnly value={formData?.generalInformation?.primaryInformant?.other} />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Informant Phone
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ tableLayout: "fixed", width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.sameasAbove === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Same as above
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.home === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Home
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.work === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Work
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.cell === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cell
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={8}>
                                <div style={{ display: "flex" }}>
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                      <p>Informant&apos;s Phone Number:</p>
                                    </h6>
                                  </div>
                                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                    <input
                                      style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                      type="text"
                                      value={formData?.generalInformation?.informantPhone?.informantPhoneNumber ?? ""}
                                      readOnly
                                    />
                                  </Card>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Gender at Birth
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  value={GenderBirth.male}
                                  readOnly
                                  checked={formData?.generalInformation?.genderAtBirth?.male === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Male
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.genderAtBirth?.female === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Female
                                </label>
                              </td>
                              <td style={{ width: "40%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.genderAtBirth?.femalePregnant === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  If Female, Pregnant ?
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Gender Identity
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.genderIdentity?.male === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Male
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.genderIdentity?.female === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Female
                                </label>
                              </td>
                              <td style={{ width: "40%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.genderIdentity?.nonBinary === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Non-Binary
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Sexual Orientation *{" "}
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <colgroup>
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                        </colgroup>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td colSpan={10}>
                                <div className="col">
                                  <div style={{ display: "flex", width: "60%" }}>
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        <td style={{ width: "40%" }}>
                                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                            Sexual orientation:
                                          </label>
                                        </td>
                                      </h6>
                                    </div>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", width: "60%" }}>
                                      <textarea
                                        style={sharedStyle}
                                        value={formData?.generalInformation?.sexualOrientation?.sexualOrientations ?? ""}
                                        // type="text"
                                        readOnly
                                      />
                                    </Card>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={11}>
                                <div className="col">
                                  <div style={{ display: "flex", width: "60%" }}>
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        <td style={{ width: "60%" }}>
                                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                            Are you interested in LGBTQ specific services?
                                          </label>
                                        </td>
                                      </h6>
                                    </div>
                                    <td style={{ width: "20%", display: "flex", flexDirection: "row" }}>
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData?.generalInformation?.sexualOrientation?.isInterestingLGBTService === true}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Yes
                                      </label>
                                    </td>
                                    <td style={{ width: "20%", display: "flex", flexDirection: "row" }}>
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData?.generalInformation?.sexualOrientation?.isInterestingLGBTService === false}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        No
                                      </label>
                                    </td>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Ethnicity
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.ethnicity?.notHispanicOrigin === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Not Hispanic Origin
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.ethnicity?.hispanicCuban === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Cuban
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.ethnicity?.hispanicMexicanAmerican === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Mexican American
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.ethnicity?.hispanicPuertoRican === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Puerto Rican
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.ethnicity?.hispanicOther === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Other
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr></tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Race
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.blackAfricanAmerican === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Black/African American
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.alaskaNative === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Alaska Native
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.whiteCaucasian === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  White/Caucasian
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.asian === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Asian
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.americanIndianNativeAmerican === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  American Indian/Native American
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.pacificIslander === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Pacific Islander
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.raceType?.race?.multiracial === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Multiracial
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData?.generalInformation?.raceType?.race?.other === true}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other :{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <textarea style={sharedStyle} readOnly value={formData?.generalInformation?.raceType?.otherRace ?? ""} />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Living Arrangement
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.privateResidence === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Private Residence
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.institution === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Institution
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.ownResidence === true}
                                  className="form-check-input"
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Own Residence
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input style={{ fontSize: "larger" }} type="radio" className="form-check-input" readOnly />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other :{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <textarea style={sharedStyle} readOnly value={formData?.generalInformation?.livingArrangement?.other || ""} />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.roomHouseDorm === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Room,House,Dorm,etc.
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.adultCareHome === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Adult Care Home
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.alternativeFamilyLiving === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Alternative Family Living
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.nursingHome === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Nursing Home
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.communityICFMR === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Community ICF-MR
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.correctionalFacility === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Correctional Facility
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.homeless === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Homeless (or at risk)
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.residentialFacility === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Residential Facility
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        {" "}
                        Marital Status
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.maritalStatus?.married === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Married
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.maritalStatus?.widowed === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Widowed
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.maritalStatus?.divorced === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Divorced
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.maritalStatus?.singleNeverMarried === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Single,Never Married
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.maritalStatus?.separated === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Separated
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.maritalStatus?.domesticPartnership === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Domestic Partnership
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Family Size
                        <br />
                        (Including self)
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <textarea style={sharedStyle} readOnly value={formData?.generalInformation?.familySize || ""} />
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} rowSpan={2} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Current Employment Status/
                        <br />
                        History
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.unemployed === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  {/* Highest Grade <br /> */}
                                  Unemployed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.notavailableforwork === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Not available for work
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.employedFullTime === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Employed Full Time
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.homemaker === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Homemaker
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.armedForcesNationalGuard === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Armed Forces/National Guard
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.employedPartTime === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Employed Part Time
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.retired === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Retired
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.student === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Student
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.disabilityIncome === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Disability Income
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11} className="fw-500" style={{ padding: "0.2rem" }}>
                        <div className="flex flex-col">
                          <p>
                            Employment History <b>(REQUIRED)</b> :
                          </p>
                          <textarea style={sharedStyle} readOnly value={formData?.generalInformation?.employmentHistory || ""} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Education
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.highestGradeCompleted === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Highest Grade Completed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.highSchoolgraduate === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  High School graduate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.ged === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  GED
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.someCollege === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Some College
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.associateDegree === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Associate Degree
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.bachelordegree === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bachelor’s degree
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.graduateSchool === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Graduate School
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.specialEdClasses === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Special Ed/Classes
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  readOnly
                                  checked={formData?.generalInformation?.education?.technicalTradeSchool === true}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Technical Trade School
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {/* Form-2 */}
      <div className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page page-break scrollable-section">
        <Row>
          <div className="card-bodyDiv small-content">
            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  PRESENTING PROBLEM:
                </h5>
              </Card>
              <Card className="match-card-body p-3">
                <Row>
                  <Col xl={12}>
                    <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      Description
                    </label>
                    <textarea className="avoid-break" style={sharedStyle} value={formData?.presentingProblem?.description || ""} readOnly />
                  </Col>
                  {/* <Col>
                    <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      History of Problem
                    </label>
                    <textarea className="avoid-break" style={sharedStyle} value={formData?.presentingProblem?.historyOfProblem || ""} readOnly />
                  </Col> */}
                </Row>
              </Card>
            </div>

            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  HISTORY OF PROBLEM :
                </h5>
              </Card>
              <Card className="match-card-body p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.historyOfProblem || "")} />
                  </Card>
                </Row>
              </Card>
            </div>

            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ margin: "auto" }}>
                    SYMPTOM CHECKLIST : (past and present)
                  </h5>
                </Card>
                <Card className="match-card-body-second p-3">
                  <Row>
                    <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      <u>Depressive Symptoms:</u>
                    </label>
                  </Row>
                  <Row>
                    <Col xl={3} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        readOnly
                        value={DepressiveSymptoms.sadnessFeelsEmpty}
                        checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.sadnessFeelsEmpty === DepressiveSymptoms.sadnessFeelsEmpty || false}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Sadness/Feels Empty
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.InsomniaHypersomnia}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.insomniaHypersomnia === DepressiveSymptoms.InsomniaHypersomnia || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Insomnia/Hypersomnia
                      </label>
                    </Col>
                    <Col xl={5} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.FatigueOrLossOfEnergy}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.fatigueOrLossOfEnergy === DepressiveSymptoms.FatigueOrLossOfEnergy || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Fatigue or loss of energy
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.Crying}
                        checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.crying === DepressiveSymptoms.Crying || false}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Crying
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.PsychomotorAgitationOrRetardation}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.psychomotorAgitationOrRetardation ===
                            DepressiveSymptoms.PsychomotorAgitationOrRetardation || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Psychomotor agitation <br />
                        or retardation
                      </label>
                    </Col>
                    <Col xl={5} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.DiminishedAbilityToThinkOrConcentrateORIndecisiveness}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedAbilityToThinkOrConcentrate ===
                            DepressiveSymptoms.DiminishedAbilityToThinkOrConcentrateORIndecisiveness || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Diminished ability to think or concentrate <br />
                        –OR- indecisiveness
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.DiminishedInterestInUsualActivities}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedInterest ===
                            DepressiveSymptoms.DiminishedInterestInUsualActivities || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Diminished interest in <br />
                        usual activities
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.significantWeightLossorGainORDecrease ===
                            DepressiveSymptoms.SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Significant weight lossor weight <br /> gain OR decrease/increase <br />
                        in appetite
                      </label>
                    </Col>
                    <Col xl={5} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.recurrentThoughtsOfDeath ===
                            DepressiveSymptoms.RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Recurrent thoughts of death (not <br />
                        just fear of dying), recurrent SI w/o plan, <br /> suicide attempt or plan for committing suicide
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DepressiveSymptoms.FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt}
                        checked={
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.feelingsOfWorthlessness ===
                            DepressiveSymptoms.FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt || false
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Feelings of worthlessness
                        <br />
                        or excessive/inappropriate guilt
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        value={DepressiveSymptoms.None}
                        checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.none === DepressiveSymptoms.None || false}
                        readOnly
                        className="form-check-input"
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        None
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      COMMENTS: REQUIRED
                    </label>
                  </Row>
                  <Row>
                    <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                      <textarea style={sharedStyle} value={htmlToTextFunction(formData?.symptomChicklist?.depressiveSymptoms?.comment || "")} />
                    </Card>
                  </Row>
                </Card>
              </Card>
            </div>
            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Manic Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.irrationalAnger}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.irrationalAnger === ManicSymptoms.irrationalAnger || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Irrational anger
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.distractibility}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.distractibility === ManicSymptoms.distractibility || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Distractibility
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.inflatedselfEsteem}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.inflatedselfEsteem === ManicSymptoms.inflatedselfEsteem || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Inflated self-esteem or grandiosity
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.decreasedSleep}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.decreasedSleep === ManicSymptoms.decreasedSleep || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Decreased need for sleep
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.moreTalkative}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.moreTalkative === ManicSymptoms.moreTalkative || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      More talkative than usual or
                      <br />
                      pressure to keep talking
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.increaseInGoalDirectedActivity}
                      checked={
                        formData?.symptomChicklist?.manicSymptoms?.symtoms?.increaseInGoalDirectedActivity === ManicSymptoms.increaseInGoalDirectedActivity ||
                        false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Increase in goal-directed <br />
                      activity or psychomotor agitation
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.irritability}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.irritability === ManicSymptoms.irritability || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Irritability
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.flightOfIdeas}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.flightOfIdeas === ManicSymptoms.flightOfIdeas || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Flight of ideas or feeling like
                      <br /> thoughts are racing
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.excessiveInvolvement}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.excessiveInvolvement === ManicSymptoms.excessiveInvolvement || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Excessive involvement in pleasurable
                      <br /> activities that have a high potential <br />
                      for painful consequence
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.none}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.none === ManicSymptoms.none || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.symptomChicklist?.manicSymptoms?.comment || "")} />
                  </Card>
                </Row>
              </Card>
            </div>
            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Conduct/Legal Problems:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.fireSetting}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fireSetting === ConductLegalProblem.fireSetting || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fire Setting
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.oppositionalDefiant}
                      checked={
                        formData?.symptomChicklist?.conductLegalProblem?.symtoms?.oppositionalDefiant === ConductLegalProblem.oppositionalDefiant || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Oppositional Defiant
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.exhibitionism}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.exhibitionism === ConductLegalProblem.exhibitionism || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Exhibitionism
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.lying}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.lying === ConductLegalProblem.lying || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Lying
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.gangInvolvement}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.gangInvolvement === ConductLegalProblem.gangInvolvement || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Gang Involvement
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.sexualActingOut}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.sexualActingOut === ConductLegalProblem.sexualActingOut || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sexual Acting Out
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.stealing}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.stealing === ConductLegalProblem.stealing || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Stealing
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.arrestsConviction}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.arrestsConviction === ConductLegalProblem.arrestsConviction || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Arrests/Convictions
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.consistentIrresponsibility}
                      checked={
                        formData?.symptomChicklist?.conductLegalProblem?.symtoms?.consistentIrresponsibility ===
                          ConductLegalProblem.consistentIrresponsibility || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Consistent Irresponsibility
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.fighting}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fighting === ConductLegalProblem.fighting || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fighting
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.impulsivity}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.impulsivity === ConductLegalProblem.impulsivity || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Impulsivity
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.propertyDestruction}
                      checked={
                        formData?.symptomChicklist?.conductLegalProblem?.symtoms?.propertyDestruction === ConductLegalProblem.propertyDestruction || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Property Destruction
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.substanceAbuse}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.substanceAbuse === ConductLegalProblem.substanceAbuse || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Substance Abuse
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.familyDesertion}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.familyDesertion === ConductLegalProblem.familyDesertion || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Family Desertion
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.Other}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.other === ConductLegalProblem.Other || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Other:
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.none}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.none === ConductLegalProblem.none || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.symptomChicklist?.conductLegalProblem?.comment || "")} />
                  </Card>
                </Row>
              </Card>
            </div>
            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Psychosis:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.delusions}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.delusions === Psychosis.delusions || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Delusions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.AuditoryHallucinations}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.auditoryHallucinations === Psychosis.AuditoryHallucinations || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Auditory hallucinations
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.ideasOfReference}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.ideasOfReference === Psychosis.ideasOfReference || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Ideas of reference
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.paranoia}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.paranoia === Psychosis.paranoia || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Paranoia
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.visualHallucinations}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.visualHallucinations === Psychosis.visualHallucinations || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Visual hallucinations
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.disorganizedSpeech}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.disorganizedSpeech === Psychosis.disorganizedSpeech || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Disorganized speech and/or behavior
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.sensoryHallucinations}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.sensoryHallucinations === Psychosis.sensoryHallucinations || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sensory hallucinations
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.none}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.none === Psychosis.none || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.other}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.other === Psychosis.other || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Other:
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.symptomChicklist?.psychosis?.comment || "")} />
                  </Card>
                </Row>
              </Card>
            </div>

            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u> Anxiety Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.anxietyWorry}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.anxietyWorry === AnxietySymptoms.anxietyWorry || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Anxiety/worry
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.somaticComplaints}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.somaticComplaints === AnxietySymptoms.somaticComplaints || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Somatic complaints
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.hypervigilance}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.hypervigilance === AnxietySymptoms.hypervigilance || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Hypervigilance
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.avoidance}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidance === AnxietySymptoms.avoidance || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Avoidance
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.dissociativeEpisodes}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.dissociativeEpisodes === AnxietySymptoms.dissociativeEpisodes || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Dissociative episodes
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.exaggeratedStartleResponse}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.exaggeratedStartleResponse === AnxietySymptoms.exaggeratedStartleResponse || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Exaggerated startle response
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.panicAttacks}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.panicAttacks === AnxietySymptoms.panicAttacks || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Panic Attacks
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.restrictedAffect}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.restrictedAffect === AnxietySymptoms.restrictedAffect || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Restricted affect
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.nightmaresReoccurringDreams}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.nightmaresReoccurringDreams === AnxietySymptoms.nightmaresReoccurringDreams ||
                        false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Nightmares, reoccurring dreams
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.obsessions}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.obsessions === AnxietySymptoms.obsessions || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Obsessions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.intrusivereOccurringThoughts}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intrusivereOccurringThoughts === AnxietySymptoms.intrusivereOccurringThoughts ||
                        false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Intrusivere occurring thoughts
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.irritabilityOrOutburstsOfAnger}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.irritabilityOrOutburstsOfAnger ===
                          AnxietySymptoms.irritabilityOrOutburstsOfAnger || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Irritability or outbursts of anger
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.compulsions}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.compulsions === AnxietySymptoms.compulsions || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Compulsions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.difficultyConcentrating}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.difficultyConcentrating === AnxietySymptoms.difficultyConcentrating || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty concentrating
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.senseOfForeshortenedFuture}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.senseOfForeshortenedFuture === AnxietySymptoms.senseOfForeshortenedFuture || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sense of foreshortened future
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.markedlyDiminished}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.markedlyDiminished === AnxietySymptoms.markedlyDiminished || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Markedly diminished interest/participation in
                      <br /> usual activities
                    </label>
                  </Col>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.avoidanceOfActivities}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidanceOfActivities === AnxietySymptoms.avoidanceOfActivities || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Avoidance of activities or situations that remind of <br />a stressful experience
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.intensePsychological}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intensePsychological === AnxietySymptoms.intensePsychological || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Intense psychological or physical reactivity to <br />
                      exposure to cues that represent traumatic event
                    </label>
                  </Col>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.unwantedMemories}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.unwantedMemories === AnxietySymptoms.unwantedMemories || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Unwanted memories,thoughts,or images of a <br />
                      stressful experience/flashbacks
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.feelingOFDetachment}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.feelingOFDetachment === AnxietySymptoms.feelingOFDetachment || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Feeling of detachment or estrangement from others
                    </label>
                  </Col>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.none}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.none === AnxietySymptoms.none || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.symptomChicklist?.anxietySymptoms?.comment || "")} />
                  </Card>
                </Row>
              </Card>
            </div>
            <div ref={el => addRowRef(el)} className=" p-1  avoid-break">
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Attention Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.failsToFinishTasks}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToFinishTasks === AttentionSymptoms.failsToFinishTasks || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fails to finish tasks
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.difficultyOrganizingThoughts}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyOrganizingThoughts ===
                          AttentionSymptoms.difficultyOrganizingThoughts || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty organizing thoughts
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.FeelsAlways}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.feelsAlways === AttentionSymptoms.FeelsAlways || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Feels always “on the go”,run like a motor
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.inattentive}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.inattentive === AttentionSymptoms.inattentive || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Inattentive
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.difficultyAwaitingTurn}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyAwaitingTurn === AttentionSymptoms.difficultyAwaitingTurn || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty awaiting turn
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.FailsToGiveAttention}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToGiveAttention === AttentionSymptoms.FailsToGiveAttention || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fails to give attention to detail
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.fidgety}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.fidgety === AttentionSymptoms.fidgety || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fidgety
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.poorAttentionSpan}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.poorAttentionSpan === AttentionSymptoms.poorAttentionSpan || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Poor attention span
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.AvoidsDislikesTasks}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.avoidsDislikesTasks === AttentionSymptoms.AvoidsDislikesTasks || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Avoids,dislikes tasks that require sustained mental effort
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.forgetful}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.forgetful === AttentionSymptoms.forgetful || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Forgetful
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.talksExcessively}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.talksExcessively === AttentionSymptoms.talksExcessively || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Talks excessively
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.EasilyDistracted}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.easilyDistracted === AttentionSymptoms.EasilyDistracted || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Easily distracted by extraneous stimuli
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.difficultyFollowingDirections}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyFollowingDirections ===
                          AttentionSymptoms.difficultyFollowingDirections || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty following directions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.interruptsOrIntrudesOnOthers}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.interruptsOrIntrudesOnOthers ===
                          AttentionSymptoms.interruptsOrIntrudesOnOthers || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Interrupts or intrudes on others
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.BlurtsOutAnswersBeforeQuestions}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.blurtsOutAnswersBeforeQuestions ===
                          AttentionSymptoms.BlurtsOutAnswersBeforeQuestions || false
                      }
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Blurts out answers before questions have been completed
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.none}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.none === AttentionSymptoms.none || false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.symptomChicklist?.attentionSymptoms?.comment || "")} />
                  </Card>
                </Row>
              </Card>
            </div>
          </div>
        </Row>
      </div>

      {/* <div className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page page-break">
        <Row>
          <div className="card-bodyDiv massive-content">
            <Card className="match-card-body p-3">

            </Card>
          </div>
        </Row>
      </div> */}

      {/* Form-3 */}
      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <Row>
          <div className="card-bodyDiv">
            <div ref={el => addRowRef(el)} className=" avoid-break">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  BIOLOGICAL FUNCTIONS
                </h5>
              </Card>
              <Card className="match-card-body p-3">
                <Card className="match-card-body-second p-3">
                  <label className="fw-bold">
                    <u>Sleep:</u>
                  </label>

                  <Row style={{ marginBottom: "0.5rem" }}>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.increased}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.increased === Sleep.increased || false}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Increased{" "}
                      </label>
                    </div>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.decreased}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.decreased === Sleep.decreased || false}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Decreased{" "}
                      </label>
                    </div>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.restless}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.restless === Sleep.restless || false}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Restless{" "}
                      </label>
                    </div>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.difficultyFallingaSleep}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyFallingaSleep === Sleep.difficultyFallingaSleep}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Difficulty falling asleep{" "}
                      </label>
                    </div>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.difficultyRemainingAsleep}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyRemainingAsleep === Sleep.difficultyRemainingAsleep}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Difficulty remaining asleep{" "}
                      </label>
                    </div>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.earlyMorningAwakening}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.earlyMorningAwakening === Sleep.earlyMorningAwakening}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Early morning awakening{" "}
                      </label>
                    </div>
                    <div className="col-lg-4 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={Sleep.noDifficulties}
                        checked={formData?.biologicalFunction?.sleep?.sleepStatus?.noDifficulties === Sleep.noDifficulties}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No difficulties{" "}
                      </label>
                    </div>
                    <div className="col-lg-12 d-flex flex-col">
                      <div style={{ margin: "0.2rem" }}>
                        <h6 style={{ fontWeight: "500", display: "inline" }}>COMMENTS *:</h6>
                      </div>
                      <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                        <textarea style={sharedStyle} value={htmlToTextFunction(formData?.biologicalFunction?.sleep?.comment || "")} />
                      </Card>
                    </div>
                  </Row>
                </Card>
              </Card>
            </div>

            <div ref={el => addRowRef(el)} className="">
              <Card className="match-card-body p-3">
                <Card className="match-card-body-second p-3">
                  <Row style={{ marginBottom: "0.5rem" }}>
                    <label className="fw-bold">
                      <u>Nutritional Status:</u>
                    </label>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.increasedAppetite}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.increasedAppetite === NutritionalStatus.increasedAppetite}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Increased Appetite/Food Intake{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.weightloss}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightloss === NutritionalStatus.weightloss}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Weight loss of 10lbs or more in the last 3 months{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.bingeing}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.bingeing === NutritionalStatus.bingeing}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Bingeing{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.dentalProblems}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.dentalProblems === NutritionalStatus.dentalProblems}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Dental Problems{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.decreasedAppetite}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.decreasedAppetite === NutritionalStatus.decreasedAppetite}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Decreased Appetite/Food Intake{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.eatingdisorder}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.eatingdisorder === NutritionalStatus.eatingdisorder}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Eating disorder-related habits and/or behaviors{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.foodAllergies}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.foodAllergies === NutritionalStatus.foodAllergies}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Food Allergies{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.weightgain}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightgain === NutritionalStatus.weightgain}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Weight gain of 10lbs or more in the last 3 months
                      </label>
                    </div>

                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={NutritionalStatus.noDifficulties}
                        checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.noDifficulties === NutritionalStatus.noDifficulties}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No Difficulties{" "}
                      </label>
                    </div>
                    <div className="col-lg-12 d-flex flex-col">
                      <div style={{ margin: "0.2rem" }}>
                        <h6 style={{ fontWeight: "500", display: "inline" }}>COMMENTS *:</h6>
                      </div>
                      <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                        <textarea style={sharedStyle} value={htmlToTextFunction(formData?.biologicalFunction?.nutritionalStatus?.comment || "")} />
                      </Card>
                    </div>
                  </Row>
                </Card>
              </Card>
            </div>

            <div ref={el => addRowRef(el)} className="">
              <Card className="match-card-body p-3">
                <Card className="match-card-body-second p-3">
                  <Row style={{ marginBottom: "0.5rem" }}>
                    <label className="fw-bold">
                      {" "}
                      <u>Other Biological Functions:</u>
                    </label>

                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={OtherBiologicalFunction.amenorrhea}
                        checked={formData?.biologicalFunction?.otherBiologicalFunction?.amenorrhea === OtherBiologicalFunction.amenorrhea}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Amenorrhea (cessation of the period){" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={OtherBiologicalFunction.encopresis}
                        checked={formData?.biologicalFunction?.otherBiologicalFunction?.encopresis === OtherBiologicalFunction.encopresis}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Encopresis (fecal incontinence)
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={OtherBiologicalFunction.increased}
                        checked={formData?.biologicalFunction?.otherBiologicalFunction?.increased === OtherBiologicalFunction.increased}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Increased libido
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={OtherBiologicalFunction.decreased}
                        checked={formData?.biologicalFunction?.otherBiologicalFunction?.decreased === OtherBiologicalFunction.decreased}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Decreased libido
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={OtherBiologicalFunction.other}
                        checked={formData?.biologicalFunction?.otherBiologicalFunction?.other === OtherBiologicalFunction.other}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Other:
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={OtherBiologicalFunction.enuresis}
                        checked={formData?.biologicalFunction?.otherBiologicalFunction?.enuresis === OtherBiologicalFunction.enuresis}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Enuresis (urinary incontinence)
                      </label>
                    </div>
                  </Row>
                </Card>
              </Card>
            </div>

            {/*activitiesss */}

            <div ref={el => addRowRef(el)} className="">
              <Card className="match-card-body p-3">
                <Card className="match-card-body-second p-3">
                  <label className="fw-bold">
                    {" "}
                    <u>Sexual Activity:</u>
                  </label>
                  <Row>
                    <Col xl={6}>
                      <div className="col-auto  d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness === false}
                          readOnly
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Not Sexually Active
                        </label>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="row">
                        <div className="col-auto d-flex" style={{ paddingInlineEnd: "inherit" }}>
                          <input
                            style={{ fontSize: "larger" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Sexually active
                          </label>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xl={1}></Col> */}
                    <Col xl={6}>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div>
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If active, with
                              </h6>
                            </div>

                            <div className="d-flex ">
                              <div className="col-4">
                                <div className="d-flex flex-row justify-content-around align-items-center">
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={SexualActivityWith.men}
                                    checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.men}
                                    readOnly
                                  />
                                  <span>men</span>
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="d-flex flex-row justify-content-around align-items-center">
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={SexualActivityWith.women}
                                    checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.women}
                                    readOnly
                                  />
                                  <span>women</span>
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="d-flex flex-row justify-content-around align-items-center">
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={SexualActivityWith.both}
                                    checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.both}
                                    readOnly
                                  />
                                  <span>both</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                        Do you protect yourself against the risk of hepatitis, STD’s and HIV/Aids?
                      </h6>
                    </Col>
                    <Col xl={6}>
                      <div className="col-auto  d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection === true}
                          readOnly
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="row">
                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                          <input
                            style={{ fontSize: "larger" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection === false}
                            readOnly
                          />
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                NO
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12}>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, how:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                value={formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.how ?? ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                        Do you protect yourself against unwanted pregnancy?
                      </h6>
                    </Col>
                    <Col xl={6}>
                      <div className="col-auto  d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection === true}
                          readOnly
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="row">
                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                          <input
                            style={{ fontSize: "larger" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection === false}
                            readOnly
                          />
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                No
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12}>
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                              If yes, how:
                            </h6>
                          </div>
                          <div className="col">
                            <textarea
                              style={sharedStyle}
                              value={formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.how ?? ""}
                              readOnly
                            />
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                        Do you have concerns that your present or past behavior has placed you at risk?
                      </h6>
                    </Col>
                    <Col xl={6}>
                      <div className="col-auto d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          checked={formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk === true}
                          readOnly
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Yes
                        </label>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="row">
                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                          <input
                            style={{ fontSize: "larger" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk === false}
                            readOnly
                          />
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                NO
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12}>
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                              NO; If yes, please describe:
                            </h6>
                          </div>
                          <div className="col">
                            <textarea style={sharedStyle} value={formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.describe ?? ""} readOnly />
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ margin: "0.2rem" }}>
                      <h6 style={{ fontWeight: "500", display: "inline" }}>Other Symptoms:</h6>
                    </div>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.biologicalFunction?.sleep?.comment || "")} />
                  </Row>
                </Card>
              </Card>
            </div>
          </div>
        </Row>
      </div>

      {/* Form-4 */}
      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <Card className="">
          <Row>
            <div ref={el => addRowRef(el)} className="card-bodyDiv">
              <Card className="match-card-header p-2 mb-2">
                <div style={{ display: "flex" }}>
                  <h5 className="fw-500" style={{ margin: "auto" }}>
                    ALCOHOL AND DRUG USE HISTORY
                  </h5>
                </div>
              </Card>
              <Card className="match-card-body p-3">
                <Row>
                  <Col xl={3}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        value={AlcoholAndDrugUseHistoryStatus.currentUse}
                        checked={formData?.alcoholAndDrugUseHistory?.historyStatus === AlcoholAndDrugUseHistoryStatus.currentUse}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Current Use
                      </label>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        value={AlcoholAndDrugUseHistoryStatus.denies}
                        checked={formData?.alcoholAndDrugUseHistory?.historyStatus === AlcoholAndDrugUseHistoryStatus.denies}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Denies (skip this section)
                      </label>
                    </div>
                  </Col>
                  <Col xl={5}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        value={AlcoholAndDrugUseHistoryStatus.unknown}
                        checked={formData?.alcoholAndDrugUseHistory?.historyStatus === AlcoholAndDrugUseHistoryStatus.unknown}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Unknown (skip this section)
                      </label>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </Row>

          <Row>
            <div className="">
              {Array.isArray(formData?.nameOfSubstance) &&
                formData?.nameOfSubstance?.map((sub: NameOfSubstance, index: number) => (
                  <>
                    {" "}
                    <Row>
                      <div ref={el => addRowRef(el)} className="">
                        <Card className="match-card-body p-4">
                          <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: "flex" }}>
                              <h5 className="fw-500" style={{ margin: "auto" }}>
                                Name of Substance:
                              </h5>
                            </div>
                          </Card>
                          <Card key={index} className="match-card-body p-3">
                            <Row>
                              <Col xl={6}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        Age of First Use:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        value={sub?.ageOfFirstUse}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xl={6}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        Regular Use:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        value={sub?.regularUse}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xl={6}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        Date last used:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                        type="date"
                                        id="date"
                                        value={moment.utc(sub?.dateLastUse).format("YYYY-MM-DD")}
                                        readOnly
                                        max={moment().format("YYYY-MM-DD")}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xl={6}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        Route:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        value={sub?.route}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xl={6}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        Amount:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        value={sub?.amount}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xl={6}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        Frequency:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        value={sub?.frequency}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>

                            <Row>
                              <div style={{ display: "flex", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                                <h6 className="fw-500" style={{ margin: "auto" }}>
                                  Problems with use:
                                </h6>
                              </div>

                              <Row>
                                <div className="col-4">
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Tolerance}
                                        checked={sub?.problemWithUse?.tolerance === ProblemWithUse.Tolerance}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Tolerance
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Withdrawal}
                                        checked={sub?.problemWithUse?.withdrawal === ProblemWithUse.Withdrawal}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Withdrawal
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Social}
                                        checked={sub?.problemWithUse?.social === ProblemWithUse.Social}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Social
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Legal}
                                        checked={sub?.problemWithUse?.legal === ProblemWithUse.Legal}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Legal
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Financial}
                                        checked={sub?.problemWithUse?.financial === ProblemWithUse.Financial}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Financial
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.IncreasedAmount}
                                        checked={sub?.problemWithUse?.increasedAmount === ProblemWithUse.IncreasedAmount}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Increased amount
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.DesireToCutDown}
                                        checked={sub?.problemWithUse?.desireToCutDown === ProblemWithUse.DesireToCutDown}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Desire to cut down
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Usewhilehazardous}
                                        checked={sub?.problemWithUse?.usewhilehazardous === ProblemWithUse.Usewhilehazardous}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Use whilehazardous
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Other}
                                        checked={sub?.problemWithUse?.other === ProblemWithUse.Other}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Other:
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.Occupational}
                                        checked={sub?.problemWithUse?.occupational === ProblemWithUse.Occupational}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Occupational
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.TimeDevoted}
                                        checked={sub?.problemWithUse?.timeDevoted === ProblemWithUse.TimeDevoted}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Time devoted to using & seeking
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                    <div className="col-auto pr-0">
                                      <input
                                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        value={ProblemWithUse.PhysicalAndOrPsychological}
                                        checked={sub?.problemWithUse?.physicalAndOrPsychological === ProblemWithUse.PhysicalAndOrPsychological}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-8 p-0">
                                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                        Physical and/or psychological problems with continued use
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                  <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                                    Comments:
                                  </h6>

                                  <textarea style={sharedStyle} value={htmlToTextFunction(formData?.nameOfSubstance?.[index]?.comment || "")} />
                                </Card>
                              </Row>
                            </Row>
                          </Card>
                        </Card>
                      </div>
                    </Row>
                  </>
                ))}
            </div>
          </Row>
        </Card>
      </div>
      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <div ref={el => addRowRef(el)} className="match-card-body p-3">
          <Row>
            <Col xl={4}>
              <h6>Tobacco Use:</h6>
            </Col>
            <Col xl={2}>
              <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                <div className="col-auto pr-0">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.tobaccoUseTypeDetails?.tobaccoUse === true}
                    readOnly
                  />
                </div>
                <div className="col-8 p-0">
                  <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                    Yes
                  </label>
                </div>
              </div>
            </Col>
            <Col xl={2}>
              <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                <div className="col-auto pr-0">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.tobaccoUseTypeDetails?.tobaccoUse === false}
                    readOnly
                  />
                </div>
                <div className="col-8 p-0">
                  <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                    No
                  </label>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="col-6">
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="d-flex">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    If yes, type of tobacco:
                  </h6>
                  <div className="col-8">
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                      }}
                      type="text"
                      value={formData?.tobaccoUseTypeDetails?.tobaccoType}
                      readOnly
                    />
                  </div>
                </div>
              </Card>
            </div>

            <div className="col-6">
              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                <div className="d-flex">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      How much?
                    </h6>
                  </div>
                  <div className="col-8">
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                      }}
                      type="text"
                      value={formData.tobaccoUseTypeDetails?.howMuch}
                      readOnly
                    />
                  </div>
                </div>
              </Card>
            </div>
          </Row>

          <Row>
            <div className="col">
              <div className="row">
                <div className="col-6">
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="d-flex">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            How often:
                          </h6>
                        </div>
                        <div className="col-8">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            value={formData?.tobaccoUseTypeDetails?.howOften}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="col-6">
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="d-flex">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          How long:
                        </h6>
                      </div>
                      <div className="col-8">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            value={formData?.tobaccoUseTypeDetails?.howLong}
                            readOnly
                          />
                        </Card>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Row>
          <Card className="match-card-body p-3">
            <Row>
              <div className="col-6">
                <div className="d-flex jus align-items-center gap-2">
                  <div className="col-6">
                    <h6>Interest in ending use:</h6>
                  </div>

                  <div className="col-4">
                    <div className="d-flex gap-3 justify-content-around">
                      <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                        <div className="col-auto pr-0">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.tobaccoUseTypeDetails?.interestEndUse === true}
                            readOnly
                          />
                        </div>
                        <div className="col-8 p-0">
                          <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                        <div className="col-auto pr-0">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.tobaccoUseTypeDetails?.interestEndUse === false}
                            readOnly
                          />
                        </div>
                        <div className="col-8 p-0">
                          <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex gap-3 align-items-center">
                  <h6>Interest in reducing use:</h6>
                  <div className="d-flex gap-3 justify-content-around">
                    <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          checked={formData?.tobaccoUseTypeDetails?.interestReducingUse === true}
                          readOnly
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          checked={formData?.tobaccoUseTypeDetails?.interestReducingUse === false}
                          readOnly
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <Row>
              <Col xl={6}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-4">
                      <h6>Ending use:</h6>
                    </div>
                    <div className="col-8">
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <textarea style={sharedStyle} value={formData.tobaccoUseTypeDetails?.endingUse || ""} readOnly />
                      </Card>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xl={6}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="d-flex">
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      What was your longest period of abstinence?
                    </label>

                    <textarea style={sharedStyle} value={formData.tobaccoUseTypeDetails?.longestAbstinence || ""} readOnly />
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="d-flex">
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", flexGrow: 1 }}>
                      When was it?
                    </label>

                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", flexGrow: 1 }}>
                      <textarea style={sharedStyle} value={formData.tobaccoUseTypeDetails?.whenWas || ""} readOnly />
                    </Card>
                  </div>
                </Card>
              </Col>
              <Col xl={6}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="d-flex">
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", flexGrow: 1 }}>
                      What caused your relapse?
                    </label>

                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", flexGrow: 1 }}>
                      <textarea style={sharedStyle} value={formData.tobaccoUseTypeDetails?.relapse || ""} readOnly />
                    </Card>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                  Comments:
                </h6>
                <textarea style={sharedStyle} value={htmlToTextFunction(formData?.tobaccoUseTypeDetails?.comment || "")} />
              </Card>
            </Row>

            <Row>
              <h6>Have you experienced any of the following symptoms after stopping your use of alcohol or other drugs?</h6>
              <div className="col-4">
                <div className="row d-flex " style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.agitationRestlessness}
                      checked={
                        formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.agitationRestlessness === ExperiencedSymptoms.agitationRestlessness || false
                      }
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Agitation/Restlessness
                      <br />
                      /Irritability
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.confusion}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.confusion === ExperiencedSymptoms.confusion || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Confusion
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.nauseaVomiting}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.nauseaVomiting === ExperiencedSymptoms.nauseaVomiting || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Nausea/Vomiting
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.muscleTwitching}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.muscleTwitching === ExperiencedSymptoms.muscleTwitching || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Muscle Twitching
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.chills}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.chills === ExperiencedSymptoms.chills || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Chills
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.deliriumTremens}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.deliriumTremens === ExperiencedSymptoms.deliriumTremens || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Delirium Tremens
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.tremorsShaking}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tremorsShaking === ExperiencedSymptoms.tremorsShaking || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Tremors/Shaking
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.anxiety}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.anxiety === ExperiencedSymptoms.anxiety || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Anxiety
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.racingPulse}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.racingPulse === ExperiencedSymptoms.racingPulse || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Racing Pulse
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.rapidBreathing}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.rapidBreathing === ExperiencedSymptoms.rapidBreathing || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Rapid Breathing
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.sweats}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.sweats === ExperiencedSymptoms.sweats || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Sweats
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.hallucinations}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.hallucinations === ExperiencedSymptoms.hallucinations || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Hallucinations
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.backJointPain}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.backJointPain === ExperiencedSymptoms.backJointPain || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Back/Joint Pain
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.other}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.other === ExperiencedSymptoms.other || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Other:
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.difficultySleeping}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.difficultySleeping === ExperiencedSymptoms.difficultySleeping || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Difficulty Sleeping
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.runnyNose}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.runnyNose === ExperiencedSymptoms.runnyNose || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Runny Nose
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.tearyEyes}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tearyEyes === ExperiencedSymptoms.tearyEyes || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Teary Eyes
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.craving}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.craving === ExperiencedSymptoms.craving || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Craving
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.cramps}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.cramps === ExperiencedSymptoms.cramps || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Cramps
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.seizures}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.seizures === ExperiencedSymptoms.seizures || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      Seizures
                    </label>
                  </div>
                </div>
                <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                  <div className="col-auto pr-0">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ExperiencedSymptoms.none}
                      checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.none === ExperiencedSymptoms.none || false}
                      readOnly
                    />
                  </div>
                  <div className="col-8 p-0">
                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                      None
                    </label>
                  </div>
                </div>
              </div>
            </Row>
          </Card>
        </div>
      </div>

      {/* Form-5 */}
      <div className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page page-break">
        <Card className="">
          <Row>
            <div className=" p-3" ref={el => addRowRef(el)}>
              <Card className="match-card-header p-2 mb-2">
                <div style={{ display: "flex" }}>
                  <h5 className="fw-500" style={{ margin: "auto" }}>
                    RELEVANT MEDICAL INFORMATION
                  </h5>
                </div>
              </Card>

              <Card className="match-card-body p-3">
                <Card className="match-card-body-second p-3">
                  <h6>Do you have or have you ever had any of the following conditions diagnosed by a physician:</h6>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.allergies}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.allergies === PhysicianDiagnosedConditions?.allergies}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Allergies*
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.gynecological}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.gynecological === PhysicianDiagnosedConditions?.gynecological
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Gynecological
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.pancreatitis}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.pancreatitis === PhysicianDiagnosedConditions?.pancreatitis
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Pancreatitis
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.anemia}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.anemia === PhysicianDiagnosedConditions?.anemia}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Anemia
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.headInjury}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.headInjury === PhysicianDiagnosedConditions?.headInjury}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Head Injury
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.respiratory}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.respiratory === PhysicianDiagnosedConditions?.respiratory}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Respiratory
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.arthritis}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.arthritis === PhysicianDiagnosedConditions?.arthritis}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Arthritis
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.heartDisease}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.heartDisease === PhysicianDiagnosedConditions?.heartDisease
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Heart Disease
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.seizureDisorder}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.seizureDisorder === PhysicianDiagnosedConditions?.seizureDisorder
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Seizure Disorder
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.asthma}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.asthma === PhysicianDiagnosedConditions?.asthma}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Asthma
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.hepatitis}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hepatitis === PhysicianDiagnosedConditions?.hepatitis}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Hepatitis
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.std}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.std === PhysicianDiagnosedConditions?.std}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        STD
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.brainDisorder}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.brainDisorder === PhysicianDiagnosedConditions?.brainDisorder
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Brain Disorder
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.highBloodPressure}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.highBloodPressure ===
                          PhysicianDiagnosedConditions?.highBloodPressure
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        High Blood Pressure
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.stroke}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.stroke === PhysicianDiagnosedConditions?.stroke}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Stroke
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{
                          fontSize: "larger",
                          zIndex: 1,
                          position: "relative",
                        }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions.cancer}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cancer === PhysicianDiagnosedConditions.cancer}
                        readOnly
                      />
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <label className="fw-500" style={{ margin: "0.3rem" }}>
                                Cancer <br />
                                (Type):
                              </label>
                            </div>
                            <div className="col">
                              <textarea style={sharedStyle} readOnly value={formData?.releventMedicalInformation?.cancerType ?? ""} />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.lowBloodPressure}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.lowBloodPressure ===
                          PhysicianDiagnosedConditions?.lowBloodPressure
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Low Blood Pressure
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.thyroidDisease}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.thyroidDisease === PhysicianDiagnosedConditions?.thyroidDisease
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Thyroid Disease
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.chronicPain}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.chronicPain === PhysicianDiagnosedConditions?.chronicPain}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Chronic Pain
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.cirrhosisoftheliver}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cirrhosisoftheliver ===
                          PhysicianDiagnosedConditions?.cirrhosisoftheliver
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Cirrhosis of the liver
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.immuneDisease}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.immuneDisease === PhysicianDiagnosedConditions?.immuneDisease
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Immune Disease
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.tuberculosis}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.tuberculosis === PhysicianDiagnosedConditions?.tuberculosis
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Tuberculosis
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.diabetes}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.diabetes === PhysicianDiagnosedConditions?.diabetes}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Diabetes
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.kidneyDisease}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.kidneyDisease === PhysicianDiagnosedConditions?.kidneyDisease
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Kidney Disease
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.ulcer}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.ulcer === PhysicianDiagnosedConditions?.ulcer}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Ulcer
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.eatingDisorder}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.eatingDisorder === PhysicianDiagnosedConditions?.eatingDisorder
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Eating Disorder
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.muscleDisorder}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.muscleDisorder === PhysicianDiagnosedConditions?.muscleDisorder
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Muscle Disorder
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.mensHealthProblems}
                        checked={
                          formData?.releventMedicalInformation?.physicianDiagnosedConditions?.mensHealthProblems ===
                          PhysicianDiagnosedConditions?.mensHealthProblems
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Men’s Health Problems
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.hivAids}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hivAids === PhysicianDiagnosedConditions?.hivAids}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        HIV/AIDS
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.none}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.none === PhysicianDiagnosedConditions?.none}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        None
                      </label>
                    </Col>
                    <Col xl={4} className="d-flex">
                      <input
                        style={{ fontSize: "larger", zIndex: 1 /* Make sure it's on top */, position: "relative" }}
                        type="checkbox"
                        className="form-check-input"
                        value={PhysicianDiagnosedConditions?.other}
                        checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.other === PhysicianDiagnosedConditions?.other}
                        readOnly
                      />
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Other
                                <br />
                                (specify):
                              </h6>
                            </div>
                            <div className="col">
                              <textarea style={sharedStyle} value={formData?.releventMedicalInformation?.otherType || ""} readOnly />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <label className="fw-500 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem" }}>
                      When was your last visit with your Primary Care Physician?
                    </label>
                  </Row>
                  <Row>
                    <Col xl={5} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={LastVisitPrimaryCarePhysician?.unableToRecall}
                        checked={formData?.releventMedicalInformation?.lastVisitPrimaryCarePhysician === LastVisitPrimaryCarePhysician?.unableToRecall}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Client unable to recall date of last visit
                      </label>
                    </Col>
                    <Col xl={7} className="d-flex">
                      <input
                        style={{ fontSize: "larger" }}
                        type="checkbox"
                        className="form-check-input"
                        value={LastVisitPrimaryCarePhysician?.greaterThanYear}
                        checked={formData?.releventMedicalInformation?.lastVisitPrimaryCarePhysician === LastVisitPrimaryCarePhysician?.greaterThanYear}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Greater than 1 year,patient advised to see primary care doctor.
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      COMMENTS
                    </label>
                  </Row>
                  <Row>
                    <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                      <textarea style={sharedStyle} value={htmlToTextFunction(formData?.releventMedicalInformation?.comment || "")} />
                    </Card>
                  </Row>
                </Card>
                <Row>
                  <Col>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                            Specify any allergies :
                          </h6>
                        </div>
                        <div className="col">
                          <textarea style={sharedStyle} value={formData?.releventMedicalInformation?.anyAllergies ?? ""} readOnly />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.7rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                            Hypersensitivities (Specify) :
                          </h6>
                        </div>
                        <div className="col">
                          <textarea style={sharedStyle} value={formData?.releventMedicalInformation?.hypersensitivities ?? ""} readOnly />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </div>
            <div ref={el => addRowRef(el)}>
              <Card className="match-card-body p-3">
                <Card className="match-card-body-second p-3">
                  <Row>
                    <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      <u>PATIENT PAIN LEVEL:</u>
                    </label>
                  </Row>
                  <Row>
                    <Col>
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                              On a scale from 1 to 10, with <b>1=NO PAIN</b> and <b>10=WORST PAIN IMAGINABLE</b>, please rate your current level of physical
                              pain :
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                              type="number"
                              value={formData?.releventMedicalInformation?.patientPainLevel?.currentLevelOfPhysicalPain}
                              readOnly
                            />
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={7}>
                      <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                        If applicable, are you currently receiving treatment for your pain ?
                      </h6>
                    </Col>
                    <Col lg={3} md={3}>
                      <div className="col-auto d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          value={ReceivingTreatementPainStatus.na}
                          checked={formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementPainStatus.na}
                          readOnly
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          N/A
                        </label>
                      </div>
                    </Col>
                    <Col lg={2} md={3}>
                      <div className="col-auto d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="radio"
                          className="form-check-input"
                          value={ReceivingTreatementPainStatus.no}
                          checked={formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementPainStatus.no}
                          readOnly
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          No
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={3}>
                      <div className="row">
                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                          <input
                            style={{ fontSize: "larger" }}
                            type="radio"
                            className="form-check-input"
                            value={ReceivingTreatementStatus.yes}
                            checked={formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementStatus.yes}
                            readOnly
                          />
                        </div>
                        <div className="col">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            RECEIVING TREATMENT, in treatment with
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table responsive className="clinical-table-bordered table-md ">
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                              <b> Medications </b>
                            </div>
                            <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                              (Including OTC)
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                              Dose
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                              Frequency
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                              Last Use
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails) &&
                          formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails.map((trdetails: TreatementDetails, index: number) => (
                            <tr key={index}>
                              <td>
                                <div className="col-auto">
                                  <textarea
                                    style={sharedStyleForTable}
                                    readOnly
                                    value={formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.medication}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-auto">
                                  <textarea
                                    style={sharedStyleForTable}
                                    value={formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.does}
                                    readOnly
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-auto">
                                  <textarea
                                    style={sharedStyleForTable}
                                    value={formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.frequency}
                                    readOnly
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-auto">
                                  <input
                                    style={{
                                      fontSize: "0.70rem",
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "unset",
                                      textAlign: "left",
                                      paddingLeft: "0.2rem",
                                    }}
                                    type="date"
                                    value={formatDate(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.lastUse)}
                                    readOnly
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    <Table className="clinical-table-bordered " style={{ textAlign: "left" }}>
                      <tbody>
                        <tr>
                          <td className="fw-500" colSpan={3} style={{ padding: "0.2rem", width: "25%", fontWeight: "bold" }}>
                            Disability Status
                          </td>
                          <td colSpan={3} style={{ padding: "0.2rem", width: "25%" }}>
                            <Table className="tableTwo">
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%", padding: "0.1rem" }}>
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      value={DisabilityStatus.hearingImpairment}
                                      checked={
                                        formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.hearingImpairment ===
                                        DisabilityStatus.hearingImpairment
                                      }
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                      Hearing Impairment
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "25%", padding: "0.1rem" }}>
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      value={DisabilityStatus.sightImpairment}
                                      checked={
                                        formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.sightImpairment ===
                                        DisabilityStatus.sightImpairment
                                      }
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                      Sight Impairment
                                    </label>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td colSpan={3} style={{ padding: "0.2rem", width: "35%" }}>
                            <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%", padding: "0.1rem" }}>
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      value={DisabilityStatus.intellectualDevelopmentalDisability}
                                      checked={
                                        formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.intellectualDevelopmentalDisability ===
                                        DisabilityStatus.intellectualDevelopmentalDisability
                                      }
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                      Intellectual/ <br />
                                      Developmental Disability
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "25%", padding: "0.1rem" }}>
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      value={DisabilityStatus.other}
                                      checked={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.other === DisabilityStatus.other}
                                      readOnly
                                    />
                                    <div className="col">
                                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                          <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                              Other (specify):
                                            </h6>
                                          </div>
                                          <div className="col">
                                            <textarea
                                              style={sharedStyle}
                                              readOnly
                                              value={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.comment}
                                            />
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td colSpan={3} style={{ padding: "0.2rem", width: "15%" }}>
                            <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%", padding: "0.1rem" }}>
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      value={DisabilityStatus.none}
                                      checked={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.none === DisabilityStatus.none}
                                      readOnly
                                    />
                                    <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                      None
                                    </label>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <label className="fw-500" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                      If a disability exists :
                    </label>
                  </Row>
                  <Row>
                    <label className="fw-500" style={{ margin: "0.1rem", marginInlineStart: "0.3rem" }}>
                      Describe :
                    </label>
                  </Row>
                  <Row>
                    <Col>
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem", marginInlineStart: "0.3rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                              How are you adjusting to your disability ?
                            </h6>
                          </div>
                          <div className="col">
                            <textarea style={sharedStyle} value={formData?.releventMedicalInformation?.patientPainLevel?.adjustDisability} />
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem", marginInlineStart: "0.3rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                              Do you require any special services or assistive equipment ?
                            </h6>
                          </div>
                          <div className="col">
                            <textarea style={sharedStyle} value={formData?.releventMedicalInformation?.patientPainLevel?.requireEquipmentOrServices} />
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Card>
            </div>
          </Row>
        </Card>
      </div>

      {/* Form-6 */}
      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <div className="" ref={el => addRowRef(el)}>
          <Card className="match-card-header p-2 mb-2">
            <div style={{ display: "flex" }}>
              <h5 className="fw-500" style={{ margin: "auto", marginRight: "10%" }}>
                OUTPATIENT MH/DD/SA TREATMENT HISTORY
              </h5>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  style={{ fontSize: "larger" }}
                  checked={formData?.outPatientTreatementHistory?.applicableState === true}
                  readOnly
                />
                <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                  Mark if not applicable
                </label>
              </div>
            </div>
          </Card>
          <Card className="match-card-body p-3">
            <div className="table-responsive">
              <Table responsive className="clinical-table-bordered table-md ">
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Agency / Practitioner
                      </div>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        or Service
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Dates of Treatment
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Diagnosis
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Medication(s)
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails) &&
                    formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.map(
                      (outDetails: treatementHistoryDetailsType, index: number) => (
                        <tr key={index}>
                          <td>
                            <div className="col-auto">
                              <textarea
                                style={sharedStyleForTable}
                                value={formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.agencyOrService || ""}
                                readOnly
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <input
                                style={{
                                  fontSize: "0.70rem",
                                  width: "100%",
                                  border: "none",
                                  backgroundColor: "unset",
                                  textAlign: "left",
                                  paddingLeft: "0.2rem",
                                }}
                                type="date"
                                value={formatDate(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.dateOfTreatement)}
                                readOnly
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <textarea
                                style={sharedStyleForTable}
                                value={formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.diagnosis || ""}
                                readOnly
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <textarea
                                style={sharedStyleForTable}
                                value={formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.medication || ""}
                                readOnly
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
            </div>
          </Card>

          <Card className="match-card-header p-2 mb-2">
            <div style={{ display: "flex" }}>
              <h5 className="fw-500" style={{ margin: "auto", marginRight: "10%" }}>
                INPATIENT MH/DD/SA TREATMENT HISTORY
              </h5>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  style={{ fontSize: "larger" }}
                  value={InpatientTreatementHistoryApplicableEnum.yes}
                  checked={formData?.inpatientTreatementHistory?.applicableState === InpatientTreatementHistoryApplicableEnum.yes}
                  readOnly
                />
                <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                  Mark if not applicable
                </label>
              </div>
            </div>
          </Card>
          <Card className="match-card-body p-3">
            <div className="table-responsive">
              <Table responsive className="clinical-table-bordered table-md ">
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Hospital / Treatment
                      </div>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Program
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Dates of Treatment
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Reason for hospital or
                      </div>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        treatment
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Diagnosis
                      </div>
                    </th>
                    <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                        Medication(s)
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(formData?.inpatientTreatementHistory?.inPatientTreatementHistory) &&
                    formData?.inpatientTreatementHistory?.inPatientTreatementHistory.map(
                      (inPatientDetails: InPatienttreatementHistoryDetails, index: number) => (
                        <tr key={index}>
                          <td>
                            <div className="col-auto">
                              <textarea style={sharedStyleForTable} value={inPatientDetails?.treatementProgram} readOnly />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <input
                                style={{
                                  fontSize: "0.70rem",
                                  width: "100%",
                                  border: "none",
                                  backgroundColor: "unset",
                                  textAlign: "left",
                                  paddingLeft: "0.2rem",
                                }}
                                type="date"
                                value={formatDate(inPatientDetails?.dateOfTreatements)}
                                readOnly
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <textarea style={sharedStyleForTable} value={inPatientDetails?.reasonForTreatement} readOnly />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <textarea style={sharedStyleForTable} value={inPatientDetails?.diagnosis} readOnly />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <textarea style={sharedStyleForTable} value={inPatientDetails?.medication} readOnly />
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>

              <Card>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    Adherence to past treatment (Meds, support groups, etc): REQUIRED
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                    <textarea style={sharedStyle} value={htmlToTextFunction(formData?.inpatientTreatementHistory?.pastTreatement || "")} />
                  </Card>
                </Row>
              </Card>
            </div>
          </Card>
        </div>

        <div className="" ref={el => addRowRef(el)}>
          <Card className="match-card-body p-3">
            <Row>
              <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                Additional medical information: REQUIRED
              </label>
            </Row>
            <Row>
              <Card className="bg-light-gray" style={{ marginBottom: "0.1rem" }}>
                <textarea style={sharedStyle} value={htmlToTextFunction(formData?.inpatientTreatementHistory?.additionalMedicalInformation || "")} />
              </Card>
            </Row>
          </Card>

          <Card className="match-card-header p-2 mb-2">
            <div style={{ display: "flex" }}>
              <h5 className="fw-500" style={{ margin: "auto" }}>
                RELEVANT DSS INFORMATION:
              </h5>
            </div>
          </Card>
          <div className="card-bodyDiv">
            <Row>
              <Card className="match-card-body p-3">
                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      DSS Involvement?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.releventDDSInformation?.ddsInvolvement === true}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.releventDDSInformation?.ddsInvolvement === false}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <Row>
                          <Col xl={1}></Col>

                          <Col xl={3}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              DSS Case
                            </h6>
                          </Col>
                          <Col xl={3}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                value={DDsCare.substantiated}
                                checked={formData?.releventDDSInformation?.ddsCare === DDsCare.substantiated}
                                readOnly
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Substantiated
                              </label>
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                value={DDsCare.investigation}
                                checked={formData?.releventDDSInformation?.ddsCare === DDsCare.investigation}
                                readOnly
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Investigation
                              </label>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                value={DDsCare.closed}
                                checked={formData?.releventDDSInformation?.ddsCare === DDsCare.closed}
                                readOnly
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Closed
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              DSS Basis for Involvement: (APS, CPS & brief description):
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <textarea style={sharedStyle} value={formData?.releventDDSInformation?.ddsCareDescription || ""} readOnly />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              DSS Social Worker Name:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    value={formData?.releventDDSInformation?.ddsSocialWorker || ""}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Phone #:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    value={formData?.releventDDSInformation?.phone || ""}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>
            </Row>
          </div>

          <Card className="match-card-header p-2 mb-2">
            <div style={{ display: "flex" }}>
              <h5 className="fw-500" style={{ margin: "auto" }}>
                RELEVANT LEGAL INFORMATION:
              </h5>
            </div>
          </Card>
          <div className="card-bodyDiv">
            <Row>
              <Card className="match-card-body p-3">
                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      LEGAL Involvement?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.relevantLegalInformation?.legalInvolvement === true}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.relevantLegalInformation?.legalInvolvement === false}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Number of Arrests in past 30 days:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    value={formData?.relevantLegalInformation?.countOfArrestet}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Current Charges:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    value={formData?.relevantLegalInformation?.currentCharges}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Attorney Name:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    value={formData?.relevantLegalInformation?.attorneyName}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Current Probation Requirements:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <textarea style={sharedStyle} value={formData?.relevantLegalInformation?.currentProbationRequirement} readOnly />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Probation Officer/Court Counselor:
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <textarea style={sharedStyle} value={formData?.relevantLegalInformation?.probationOffice} readOnly />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={4}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Previous Charge(s)/Convictions/Incarceration(s)/JAIL or PRISON):
                            </h6>
                          </Col>
                          <Col xl={7}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <textarea style={sharedStyle} value={formData?.relevantLegalInformation?.previousChargesJailOrPrison} readOnly />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>
            </Row>
          </div>
        </div>
      </div>

      {/* Form-7 */}
      <div className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page page-break page-break">
        <Card>
          <div className=" " ref={el => addRowRef(el)}>
            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  BIOPSYCHOSOCIAL DEVELOPMENTAL HISTORY:
                </h5>
              </div>
            </Card>
          </div>
          <Card className="match-card-body p-3">
            <div ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Developmental History: <span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea style={sharedStyle} value={formData?.bioPsychosocialDevelopemntHistory?.developmentHistory || ""} readOnly />
              </Col>
            </div>

            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Relevant childhood history:<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.childhoodHistory || ""} style={sharedStyle} readOnly />
              </Col>
            </div>

            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Foster Care:<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.fosterCare || ""} style={sharedStyle} readOnly />
              </Col>
            </div>

            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Siblings:<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.siblings || ""} style={sharedStyle} readOnly />
              </Col>
            </div>

            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Family history of MH or SA issues:<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.familyHistoryOfMHOrSAIssues || ""} style={sharedStyle} readOnly />
              </Col>
            </div>
            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Current Spouse/Significant Other:<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.currentSpouse || ""} style={sharedStyle} readOnly />
              </Col>
            </div>
            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Children/Step-Children:<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.childrenStepChildren || ""} style={sharedStyle} readOnly />
              </Col>
            </div>
            <div className="" ref={el => addRowRef(el)}>
              <Col className="p-2">
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Current Relational Issues w/ Family (potential involvement w/treatment):<span className="fw-bold">(REQUIRED)</span>
                </label>
                <textarea value={formData?.bioPsychosocialDevelopemntHistory?.relationsIssues || ""} style={sharedStyle} readOnly />
              </Col>
              <Col className="p-2">
                <div className="d-flex">
                  <div className="d-flex">
                    <Row style={{ marginBottom: "0.5rem" }}>
                      <h6>What other supports do you have?</h6>
                      <div className="col-lg-3 d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.family || ""}
                          checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.family === OtherSupports.family}
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Family{" "}
                        </label>
                      </div>
                      <div className="col-lg-3 d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.church || ""}
                          checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.church === OtherSupports.church}
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Church{" "}
                        </label>
                      </div>
                      <div className="col-lg-3 d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.employer || ""}
                          checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.employer === OtherSupports.employer}
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Employer{" "}
                        </label>
                      </div>
                      <div className="col-lg-3 d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.friends || ""}
                          checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.friends === OtherSupports.friends}
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Friends{" "}
                        </label>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.other || ""}
                          checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.other === OtherSupports.other}
                        />
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          Other:{" "}
                        </label>

                        <input
                          style={{
                            width: "100%",
                            //  border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          id="name"
                          value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.otherSupport || ""}
                        />
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </div>
          </Card>
          <div className="" ref={el => addRowRef(el)}>
            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto", marginRight: "25%" }}>
                  EDUCATION
                </h5>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ fontSize: "larger" }}
                    checked={formData?.bioPsychosocialEducation?.isApplicable === true}
                    readOnly
                  />
                  <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                    Mark if not applicable
                  </label>
                </div>
              </div>
            </Card>
            <Card className="match-card-body p-3">
              <Row>
                <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                  For age 18 and under or for clients till in school complete the following questions :
                </label>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          {" "}
                          If currently in school <br />- Name/grade :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          id="name"
                          value={formData?.bioPsychosocialEducation?.schoolGradeName || ""}
                          readOnly
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Last IEP :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.lastIep || ""}
                          readOnly
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Teacher/Counselor :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.teacher || ""}
                          readOnly
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Learning Abilities/Strengths :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea style={sharedStyle} value={formData?.bioPsychosocialEducation?.learningAbility || ""} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          If enrolled in “exceptional children’s programs”,specify disability area :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.disabilityArea || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Educational Problems :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.educationalProblems || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Behavior Problems in school :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.behaviorProblem || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Repeated Grades? If so,which? :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.repetedGrades || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Social Interactions (peers/teachers) :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.socialInteraction || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Suspensions :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.suspension || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Expulsions :
                        </h6>
                      </div>
                      <div className="col">
                        <textarea value={formData?.bioPsychosocialEducation?.expulsion || ""} style={sharedStyle} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-0">
                <Col lg={4}>
                  <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                    Current Psychological Testing Completed?
                  </h6>
                </Col>
                <Col lg={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      checked={formData?.bioPsychosocialEducation?.isPsychologicalTestingCompleted === true}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Yes
                    </label>
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      checked={formData?.bioPsychosocialEducation?.isPsychologicalTestingCompleted === false}
                      readOnly
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      No
                    </label>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="col-auto d-flex">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Date :
                    </h6>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="col d-flex">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                          }}
                          type="date"
                          value={formatDate(formData?.bioPsychosocialEducation?.date)}
                          readOnly
                        />
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Expulsions : If so, specify tests and include results :
                        </h6>
                      </div>
                      <div className="col">
                        <input style={sharedStyle} value={formData?.bioPsychosocialEducation?.expulsionTestResults} readOnly />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
        </Card>
      </div>

      {/* Form-8 */}
      <div ref={el => addRowRef(el)} className="BorderRadiusLeftRight m-0 pt-1 pb-1  form-page page-break">
        <div className="card-bodyDiv">
          <Card className="match-card-header p-2 mb-2">
            <h5 className="fw-500" style={{ margin: "auto" }}>
              TRAUMA HISTORY : Share only what you feel comfortable sharing
            </h5>
          </Card>
          <Card className="match-card-body p-3">
            <Row className="mb-0">
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Have you ever been in a really bad accident (e.g.,Car,Work,etc.) ?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isBadAccident === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isBadAccident === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={12}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, describe :
                      </h6>
                    </div>
                    <div className="col">
                      <textarea style={sharedStyle} value={formData?.traumaHistory?.badAccidentDescribe || ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Have you ever been in a natural disaster ?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isNaturalDisaster === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isNaturalDisaster === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={12}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, describe :
                      </h6>
                    </div>
                    <div className="col">
                      <textarea style={sharedStyle} value={formData?.traumaHistory?.naturalDisasterdDescribe || ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Have you ever been in military combat ?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isMilitaryCombat === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isMilitaryCombat === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={12}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, describe :
                      </h6>
                    </div>
                    <div className="col">
                      <textarea style={sharedStyle} value={formData?.traumaHistory?.militoryCombatDescribe || ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Has anyone (including a family member or friend) ever attacked you with a weapon OR with the intent to seriously injure or kill you?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isSeriousAttack === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isSeriousAttack === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={12}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, describe :
                      </h6>
                    </div>
                    <div className="col">
                      <textarea style={sharedStyle} value={formData?.traumaHistory?.seriousAttackDescribe || ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Has anyone (including a family member or friend) ever used physical force, threats, or coercion to make you have unwanted sexual contact?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isSexualCoercion === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isSexualCoercion === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={6}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, at what age:
                      </h6>
                    </div>
                    <div className="col">
                      <textarea style={sharedStyle} value={formData?.traumaHistory?.ageOfSexualCoercion ?? ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
              <Col lg={6}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        Describe :
                      </h6>
                    </div>
                    <div className="col">
                      <input style={sharedStyle} value={formData?.traumaHistory?.describeSexualCoercion || ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Have you had any other traumatic experiences?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isTraumaticExperience === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.isTraumaticExperience === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={12}>
                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, describe :
                      </h6>
                    </div>
                    <div className="col">
                      <textarea style={sharedStyle} value={formData?.traumaHistory?.describeTraumaticExperience || ""} readOnly />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={12}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  If yes to any of the above, <b>IN THE LAST MONTH</b>, have you :
                </h6>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                  Had thoughts about any of the events when you did not want to
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.intrusiveThoughts === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.intrusiveThoughts === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                  Tried to avoid any thoughts or reminders of the event.
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.avoidanceBehavior === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.avoidanceBehavior === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                  Been constantly on guard, watchful, or easily startled ?
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.hypervigilance === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.hypervigilance === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                  Felt numb or detached from people/activities/surroundings.
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.emotionalDetachment === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.emotionalDetachment === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <h6 className="fw-500" style={{ margin: "0.2rem", listStyleType: "disc", display: "list-item", marginLeft: "2rem" }}>
                  {" "}
                  Felt guilty or unable to stop blaming yourself/others for the events
                </h6>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.selfBlame === false}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    No
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    checked={formData?.traumaHistory?.selfBlame === true}
                    readOnly
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Yes
                  </label>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>

      {/* Form-9 */}
      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <div className="card-bodyDiv">
          <div className="card-bodyDiv p-1" ref={el => addRowRef(el)}>
            <Row>
              <Card className="match-card-header p-2 mb-2">
                <div style={{ display: "flex" }}>
                  <h5 className="fw-500" style={{ margin: "auto" }}>
                    RISK TO SELF AND OTHERS
                  </h5>
                </div>
              </Card>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Row>
                  <Card className="match-card-body p-3">
                    <h6 className="fw-500" style={{}}>
                      <u>Current Risk to Self</u>
                    </h6>

                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Has the person self-injured in the past month?:
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <Col xl={1}></Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea style={sharedStyle} value={formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? ""} readOnly />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>

                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is person having suicidal ideation or making suicidal threats?:
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <Col xl={1}></Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                disabled={false}
                                value={formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>

                    <Row>
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          If YES to either question above:
                        </h6>
                      </Card>

                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is the ideation/self-injuring repetitive or persistent?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Does the patient have a specific suicide plan?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Does the ideation/self-injury involve serious/lethal intent?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Does the ideation involve command hallucinations?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>

                      <Col xl={1}></Col>

                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                value={formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>
                  </Card>
                </Row>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Row>
                  <Card className="match-card-body p-3">
                    <h6 className="fw-500" style={{}}>
                      <u>Current Risk to Others</u>
                    </h6>

                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is the person having homicidal ideation or making homicidal threats?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If YES:
                              </h6>
                            </div>

                            <Row>
                              <Col xl={1}></Col>

                              <Col xl={7}>
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                                  Is the ideation/self-injuring repetitive or persistent?
                                </h6>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation === true}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    Yes
                                  </label>
                                </div>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation === false}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    No
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl={1}></Col>
                              <Col xl={7}>
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                                  Does the patient have a specific plan?
                                </h6>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan === true}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    Yes
                                  </label>
                                </div>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan === false}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    No
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl={1}></Col>
                              <Col xl={7}>
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                                  Does the ideation involve serious/lethal intent?
                                </h6>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent === true}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    Yes
                                  </label>
                                </div>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent === false}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    No
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={1}></Col>
                              <Col xl={7}>
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                                  Does the ideation involve command hallucinations?
                                </h6>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands === true}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    Yes
                                  </label>
                                </div>
                              </Col>
                              <Col xl={2}>
                                <div className="col-auto d-flex">
                                  <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    checked={formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands === false}
                                    readOnly
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                    No
                                  </label>
                                </div>
                              </Col>
                              <Col xl={1}></Col>
                              <div className="col">
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        If yes, describe:
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <textarea
                                        style={sharedStyle}
                                        value={formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? ""}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </Row>
                          </div>
                        </Card>
                      </div>
                    </Row>
                  </Card>
                </Row>
              </div>
            </Row>
          </div>

          <Row>
            <div className="card-bodyDiv">
              <div ref={el => addRowRef(el)} className="card-bodyDiv p-1">
                <Row>
                  <Card className="match-card-body p-3">
                    <h6 className="fw-500" style={{}}>
                      <u>History of Suicidal / Homicidal Ideation / Behavior</u>{" "}
                    </h6>

                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is there a history of suicidal / self-injuring ideation / behavior?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <Col xl={1}></Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                value={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeHistoryOfSuicidalBehavior || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>
                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is there a history of homicidal / assaultive ideation / behavior?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <Col xl={1}></Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                value={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeHomicidalBehavior || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>
                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is there a history of serious physical harm to self / others while in a treatment setting
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <Col xl={1}></Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                value={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeSeriousPhysicalHarm ?? ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>
                    <Row>
                      <Col xl={8}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Is there a history of aggression or violence towards others?
                        </h6>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers === false}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            No
                          </label>
                        </div>
                      </Col>
                      <Col xl={1}></Col>
                      <div className="col">
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, describe:
                              </h6>
                            </div>
                            <div className="col">
                              <textarea
                                style={sharedStyle}
                                value={formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeAggression ?? ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Row>
                  </Card>
                </Row>
                <Row>
                  <Card className="match-card-body p-3">
                    <h6 className="fw-500" style={{}}>
                      <u>Evaluation of Risk</u>
                    </h6>

                    <Row>
                      <Col xl={3}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Suicidal /Self-Harming:
                        </h6>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.lowToNoRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Low to no risk
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.moderateRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Moderate risk
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.highRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            High risk
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.imminentRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Imminent risk
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={3}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Homicidal / Assaultive:
                        </h6>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.lowToNoRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Low to no risk
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.moderateRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Moderate risk
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.highRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            High risk
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            checked={formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.imminentRisk === true}
                            readOnly
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Imminent risk
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              </div>
            </div>
          </Row>

          <Row>
            <div className="card-bodyDiv">
              <Card className="match-card-body p-3">
                <div>
                  <Row>
                    <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                      <div className="match-card-body p-3" ref={el => addRowRef(el)}>
                        <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                          ACTIONS TAKEN BASED ON EVALUATION:
                        </h6>
                        <textarea style={sharedStyle} value={htmlToTextFunction(formData?.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "")} />
                      </div>
                    </Card>
                  </Row>

                  <Row>
                    <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                      <div className="match-card-body p-3" ref={el => addRowRef(el)}>
                        <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                          If applicable, please describe your belief system:(REQUIRED)
                        </h6>
                        <textarea style={sharedStyle} value={htmlToTextFunction(formData?.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "")} />
                      </div>
                    </Card>
                  </Row>
                  <Row>
                    <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                      <div className="match-card-body p-3" ref={el => addRowRef(el)}>
                        <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                          What has been the role of your beliefs/religion/spirituality in your life?(REQUIRED)
                        </h6>
                        <textarea style={sharedStyle} value={htmlToTextFunction(formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "")} />
                      </div>
                    </Card>
                  </Row>
                  <Row>
                    <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                      <div className="match-card-body p-3" ref={el => addRowRef(el)}>
                        <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                          What has been the role of your beliefs/religion/spirituality in your wellness/recovery?(REQUIRED)
                        </h6>
                        <textarea style={sharedStyle} value={htmlToTextFunction(formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "")} />
                      </div>
                    </Card>
                  </Row>
                </div>
              </Card>
            </div>
          </Row>
        </div>
      </div>

      {/* Form-10 */}
      <div className=" BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <Row>
          <div ref={el => addRowRef(el)} className="">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                MENTAL STATUS:
              </h5>
            </Card>

            <Card className="match-card-body p-3">
              <div style={{ overflowX: "auto" }}>
                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left" }}>
                  <tbody style={{ verticalAlign: "middle" }}>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Physical Stature:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.physicalStature?.small === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Small
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.physicalStature?.average === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Average
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.physicalStature?.tall === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Tall
                            </label>
                          </td>
                          <td style={{ width: "20%" }}></td>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Weight:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.weight?.thin === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Thin
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.weight?.average === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Average
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.weight?.overweight === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Overweight
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.weight?.obese === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Obese
                            </label>
                          </td>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Grooming:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.grooming?.wellGroomed === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Well Groomed
                            </label>
                          </td>
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.grooming?.normal === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Normal
                            </label>
                          </td>
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.grooming?.neglected === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Neglected
                            </label>
                          </td>
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              checked={formData?.mentalStatus?.grooming?.bizarre === true}
                              readOnly
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Bizarre
                            </label>
                          </td>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Clothing:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.clothing?.neatClean === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Neat/Clean
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.clothing?.inappropriate === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Inappropriate
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.clothing?.dirty === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Dirty
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.clothing?.seductive === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Seductive
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.clothing?.disheveled === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Disheveled
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.clothing?.bizarre === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bizarre
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Posture:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.posture?.normal === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Normal
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.posture?.tense === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tense
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.posture?.stooped === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Stooped
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.posture?.rigid === true}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Rigid
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.posture?.slumped === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slumped
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.posture?.bizarre === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bizarre
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Attitude:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.cooperative === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cooperative
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.passive === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Passive
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.guarded === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Guarded
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.irritable === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Irritable
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.manipulative === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Manipulative
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.seductive === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Seductive
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.suspicious === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Suspicious
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.defensive === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Defensive
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.dramatic === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Dramatic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.silly === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Silly
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.hostile === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hostile
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.critical === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Critical
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.resistant === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Resistant
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.sarcastic === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Sarcastic
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.uninterested === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Uninterested
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.attitude?.argumentative === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Argumentative
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Motor:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.motor?.nonremarkable === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Non-remarkable
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.motor?.tremor === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tremor
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.motor?.slowed === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slowed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.motor?.tics === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tics
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.motor?.restless === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Restless
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.motor?.agitated === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Agitated
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Speech:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.normal === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Normal
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.rapid === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Rapid
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.slurred === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slurred
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.loud === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Loud
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.paucity === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Paucity
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.pressured === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Pressured
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.speech?.mute === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Mute
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Affect:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.affect?.appropriate === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Appropriate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.affect?.inappropriate === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Inappropriate
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.affect?.flat === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Flat
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.affect?.restricted === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Restricted
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.affect?.blunted === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Blunted
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.affect?.labile === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Labile
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Mood:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.euthymic === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Euthymic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.confused === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Confused
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.pessimistic === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Pessimistic
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.depressed === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Depressed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.anxious === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Anxious
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.euphoric === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Euphoric
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.apathetic === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Apathetic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.mood?.angry === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Angry
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Thought Form:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.goaldirected === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Goal directed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.appropriate === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Appropriate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.logical === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Logical
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.tangentialthinking === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tangential thinking
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.circumstantial === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Circumstantial
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.looseassociations === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Loose associations
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.confused === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Confused
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.incoherent === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Incoherent
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.perseverations === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Perseverations
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.flightofidea === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Flight of idea
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtForm?.slownessofthought === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slowness of thought association
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Thought Content:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.appropriate === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Appropriate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.paranoid === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Paranoid
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.suspicions === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Suspicions
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.persecutions === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Persecutions
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.paucity === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Paucity
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.delusions === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Delusions
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.bizarre === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bizarre
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.hypochondriac === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hypochondriac
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.thoughtContent?.ideasofreference === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Ideas of reference
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Preoccupations:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.phobias === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Phobias
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.guilt === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Guilt
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.other === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Other
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.somatic === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Somatic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.religion === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Religion
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.suicide === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Suicide
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.preoccupations?.homicidal === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Homicidal
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Hallucinations:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.hallucinations?.auditory === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Auditory
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.hallucinations?.other === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Other:
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.hallucinations?.visual === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Visual
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.hallucinations?.sensory === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Sensory
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Orientation:
                      </td>

                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td colSpan={2} style={{ width: "25.18%" }}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Person
                                </label>
                              </td>

                              <td colSpan={5} style={{ width: "25.45%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.person === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4} style={{ width: "30.36%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.person === false}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Place
                                </label>
                              </td>

                              <td colSpan={5}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.place === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.place === false}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Time
                                </label>
                              </td>

                              <td colSpan={5}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.time === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.time === false}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Situation
                                </label>
                              </td>

                              <td colSpan={5}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.situation === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.orientation?.situation === false}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Level of intellectual functioning
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.levelOfIntellectualFunctioning?.belowAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.levelOfIntellectualFunctioning?.average === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.levelOfIntellectualFunctioning?.aboveAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Fund of Knowledge:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.fundofKnowledge?.belowAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.fundofKnowledge?.average === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.fundofKnowledge?.aboveAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Judgment:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.judgment?.belowAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.judgment?.average === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.judgment?.aboveAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Insight into problems:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.insightIntoProblems?.belowAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.insightIntoProblems?.average === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.mentalStatus?.insightIntoProblems?.aboveAverage === true}
                                  readOnly
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </div>

      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <div>
          <Row>
            <div className="" ref={el => addRowRef(el)}>
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  CLINICAL IMPRESSION/INTERPRETIVE SUMMARY:
                </h5>
              </Card>
              <Card className="bg-light-gray match-card-body p-3" style={{ marginBottom: "0.1rem" }}>
                <textarea style={sharedStyle} value={htmlToTextFunction(formData?.mentalStatus?.clinicalImpressionSummary ?? "")} />
              </Card>
            </div>
          </Row>
        </div>
      </div>

      {/* Form-11 */}
      <div className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page page-break">
        <div>
          <Row>
            <div className="">
              <div ref={el => addRowRef(el)}>
                <Card className="match-card-header p-2 ">
                  <div style={{ display: "flex" }}>
                    <h5 className="fw-500" style={{ margin: "auto" }}>
                      DIAGNOSES
                    </h5>
                  </div>
                </Card>
              </div>

              <Card className="match-card-body-second p-3">
                {formData?.diagnoseRecommendationDetails?.diagnosis?.map((item, index) => (
                  <div key={index} ref={el => addRowRef(el)} className="">
                    <Col className="p-1">
                      <div className="ai-border  text-start">
                        <div className="ps-3 pe-3 pt-3 pb-3">
                          <div className="mb-3 ">
                            <p className="text-muted font-size-10 flex flex-row justify-content-between">
                              <strong>Code:</strong>
                            </p>

                            <Select
                              // className={cptCodeError ? "form-control is-invalid" : "form-control"}
                              // key={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code ?? ""}
                              // value={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code}
                              // // onChange={event => handleCPTCodeChange(event, index)}
                              // disabled={true}
                              // styles={customStylesForICD10Codes}
                              key={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code ?? ""}
                              value={
                                cptCodeList?.find(code => code.cptCode === formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code)
                                  ? {
                                      label: cptCodeList?.find(code => code.cptCode === formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code)
                                        ?.cptCodeDescription,
                                      value: cptCodeList?.find(code => code.cptCode === formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code)
                                        ?.cptCode,
                                    }
                                  : null
                              }
                              isDisabled={true} // Prevent user interaction
                              isClearable={false} // Prevent clearing the selected value
                              isSearchable={false} // Disable searching
                              styles={customStylesForICD10Codes}
                              options={cptCodeList?.map(code => ({
                                label: code.cptCodeDescription,
                                value: code.cptCode,
                              }))}
                            >
                              {/* {cptCodeList &&
                        cptCodeList.map((code: CPTCode, index: number) => {
                          return (
                            <option key={index} value={code.cptCode}>
                              {code.cptCodeDescription}
                            </option>
                          );
                        })} */}
                            </Select>
                          </div>

                          <div className="mb-3">
                            <p className="text-muted font-size-10">
                              <strong> Diagnosis :</strong>
                            </p>

                            <Select
                              key={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.diagnosis?.length}
                              // closeMenuOnSelect={false}
                              value={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.diagnosis ?? []}
                              isMulti
                              // options={options}
                              disabled={true}
                              styles={customStylesForICD10Codes}
                              // onChange={(e: any) => handleDiagnosisChange(e, index)}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                ))}
              </Card>
            </div>
            <div className="" ref={el => addRowRef(el)}>
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <div style={{ display: "flex" }}>
                      <h5 className="fw-500" style={{ margin: "auto" }}>
                        RECOMMENDATIONS
                      </h5>
                    </div>
                  </Card>
                </div>
              </Row>
              <div className="match-card-body-second">
                <Card className="match-card-body-second p-3">
                  <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                    ASAM Level of Care Recommended:
                  </h6>
                  <Row>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.notApplicable}
                        checked={formData?.diagnoseRecommendationDetails?.recommendation?.notApplicable === DiagnosesRecommendations?.notApplicable}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Not applicable (no substance abuse){" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.levelSAIOP}
                        checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelSAIOP === DiagnosesRecommendations?.levelSAIOP}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Level 2.1 (SAIOP){" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.levelResidential}
                        checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelResidential === DiagnosesRecommendations?.levelResidential}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Level 3.1, 3.3, 3.5 Residential{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.levelMedicalDetox}
                        checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelMedicalDetox === DiagnosesRecommendations?.levelMedicalDetox}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Level 4 Medical Detox{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.levelOutpatienttreatment}
                        checked={
                          formData?.diagnoseRecommendationDetails?.recommendation?.levelOutpatienttreatment ===
                          DiagnosesRecommendations?.levelOutpatienttreatment
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Level 1 Outpatient treatment{" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.levelComprehensiveoutpatient}
                        checked={
                          formData?.diagnoseRecommendationDetails?.recommendation?.levelComprehensiveoutpatient ===
                          DiagnosesRecommendations?.levelComprehensiveoutpatient
                        }
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Level 2.5 Comprehensive outpatient (SACOT){" "}
                      </label>
                    </div>
                    <div className="col-lg-6 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={DiagnosesRecommendations?.levelNonhospital}
                        checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelNonhospital === DiagnosesRecommendations?.levelNonhospital}
                        readOnly
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Level 3.7 Non-hospital medical detox{" "}
                      </label>
                    </div>
                  </Row>
                </Card>

                <Card className="match-card-body-second p-3">
                  <div className="column">
                    <Row>
                      <div className="col-6">
                        <p className="fw-500" style={{ margin: "0.3rem" }}>
                          Clinician Signature and Credentials:
                        </p>
                        <div className="sign w-full">
                          <div className="sigCanvasNewDiv w-full">
                            {formData?.diagnoseRecommendationDetails?.signature && (
                              <img className="sigCanvasNew w-full" src={formData?.diagnoseRecommendationDetails?.signature} />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-2"></div>
                      <div className="col-5">
                        <p className="fw-500" style={{ margin: "0.3rem" }}>
                          Client Signature and Credentials:
                        </p>
                        <div className="sign w-full">
                          <div className="sigCanvasNewDiv w-full">
                            {formData?.diagnoseRecommendationDetails?.clientSignature && (
                              <img className="sigCanvasNew w-full" src={formData?.diagnoseRecommendationDetails?.clientSignature} />
                            )}
                          </div>
                        </div>
                      </div> */}
                    </Row>

                    <Row>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-4">
                            <p className="fw-500" style={{ margin: "0.3rem" }}>
                              Printed Name:
                            </p>
                          </div>
                          <div className="col-8">
                            <input
                              style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "#F1F1F1" }}
                              type="text"
                              id="name"
                              value={formData?.diagnoseRecommendationDetails?.printedName ?? ""}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="row">
                          <div className="col-4">
                            <p className="fw-500" style={{ margin: "0.3rem" }}>
                              Date:
                            </p>
                          </div>
                          <div className="col-8">
                            <input
                              style={{
                                width: "100%",
                                border: "none",
                                borderBottom: "1px solid gray",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                                textAlign: "center",
                              }}
                              readOnly
                              type="date"
                              value={formatDate(formData?.diagnoseRecommendationDetails?.date)}
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Card>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default forwardRef(AssessmentForm);
