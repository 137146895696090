import React, { useContext, useEffect, useState } from "react";
import { CardTitle, Col, Row, Modal, CardBody, Button } from "reactstrap";
import UserContext from "src/context/UserContext";
import { ClientService } from "src/services/ClientService";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { CardContent, TextField } from "@mui/material";


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontWeight: 600,
      fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":focus": {
        color: "#424770",
      },

      "::placeholder": {
        color: "#9BACC8",
      },

      ":focus::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#fb1c50",
      ":focus": {
        color: "#FA755A",
      },
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  },
};


const Modal28: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void; copaymentAmount: any; setPayAmount: (paidAmount: any) => void; setAfterPayCoAmount: (paidAmount: any) => void; }> = props => {
  const [user] = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleCreatedCustomer = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe.js has not yet loaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return;
    }

    setIsSubscribing(true);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
      billing_details: {
        email: user?.email,
      },
    });

    if (result.error) {
      toast.error(result.error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setIsSubscribing(false);
    } else {
      const subscriptionDetails = {
        paymentMethod: result.paymentMethod.id,
      };

      ClientService.createStripeAccountAndPay(subscriptionDetails).then(res => {
        if (res.success) {
          props.setAfterPayCoAmount(props.copaymentAmount);
          toast.success("Congratulations! Your card details has been added successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setIsSubscribing(false);
          elements.getElement(CardElement)?.clear();


        } else {
          toast.error(res.error ? res.error : "Failed to add your card details. Please contact support.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setIsSubscribing(false);

          return;
        }
      });
    }
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = () => {
    setIsProcessing(true);
    props.setPayAmount(props.copaymentAmount);
    setTimeout(() => {
      setIsProcessing(false);
    }, 2000);
  };

  return props.showModal ? (
    <>

      <Modal isOpen={props.showModal} size="lg" centered>

        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-warning text-danger"></i>
            </div>

            <CardTitle className="mb-4 text-center font-size-20">
              Hi {user?.firstname} Gentile reminder you have an outstanding copayment of ${props.copaymentAmount}  from your last session. Kindly settle this balance before you join your session. We will direct you to your session once this balance is paid. Thank you for trusting us with your care.</CardTitle>

            {user?.stripeCustomerId ?

              <div className="flex justify-content-center">
                <button type="button" onClick={handleClick} disabled={isProcessing} className="btn btn-info mr-2">
                  {isProcessing ? "Please Wait..." : "Pay"}
                </button>
                <button type="button" onClick={() => props.setShowModal(false)} className="btn btn-primary">
                  Close
                </button>
              </div>
              :
              <div>
                <CardContent className="pay-content pay-content-border mb-4">
                  <TextField
                    label="Email"
                    id="outlined-email-input"
                    helperText={`Email you'll receive updates and receipts on`}
                    margin="normal"
                    variant="outlined"
                    type="email"
                    required
                    value={user?.email}
                    disabled={true}
                    fullWidth
                  />

                  <CardElement options={CARD_ELEMENT_OPTIONS} className="card-element" />

                  <div className="pay-div">
                    <Button
                      className="pay-btn btn btn-success"
                      variant="contained"
                      color="primary"
                      onClick={handleCreatedCustomer}
                      disabled={isSubscribing}
                    >
                      {isSubscribing ? "Please Wait..." : "Pay"}
                    </Button>
                  </div>
                </CardContent>
              </div>
            }
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>

      </Modal>
    </>
  ) : null;
};

export default Modal28;