import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { FaPlus } from "react-icons/fa6";
import { AiOutlineCloudUpload } from "react-icons/ai";
import "./style.css";

interface DocumentButtonPropTypes {
  toggleCreateDropDown: () => void;
  toggleCreateFolderModal: () => void;
  toggleFileFolderModal: () => void;
  toggle: () => void;
  createDropdownOpen: boolean;
  uploadDropdownOpen: boolean;
}

const DocumentButtonList: React.FC<DocumentButtonPropTypes> = ({
  toggleCreateDropDown,
  toggleCreateFolderModal,
  toggleFileFolderModal,
  createDropdownOpen,
  uploadDropdownOpen,
  toggle,
}) => {
  return (
    <div className="d-flex flex-row align-items-center gap-2">
      <Dropdown isOpen={createDropdownOpen} toggle={toggleCreateDropDown}>
        <DropdownToggle className="doc-btn-dropdown" caret outline>
          <FaPlus />
          Create
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={toggleCreateFolderModal}>Folder</DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Dropdown isOpen={uploadDropdownOpen} toggle={toggle}>
        <DropdownToggle className="doc-btn-dropdown" caret outline gap-1 style={{ border: "1px dashed #cbd5e0", color: "black" }}>
          <AiOutlineCloudUpload /> {"  "} Upload or Drop
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={toggleFileFolderModal}>File</DropdownItem>
          {/* <DropdownItem>Folder</DropdownItem> */}
        </DropdownMenu>
      </Dropdown>

      {/* <Button outline color="secondary" onClick={toggleCreateFolderModal}>
        Create folder
      </Button> */}
    </div>
  );
};

export default DocumentButtonList;
