import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SidebarContentSaas from "./SidebarContentSaas";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import { useLocation } from "react-router-dom";
import { RouteName } from "../../RouteName";

const SidebarSaas = props => {
  const location = useLocation();
  const collapseState=useState((document.body.classList.contains("sidebar-enable") ? true : false));

  useEffect(() => {
    if (location.pathname.includes("video-room")) {
      document.body.classList.add("meeting-screen");
    } else {
      document.body.classList.remove("meeting-screen");
    }
  }, [location.pathname]);  

  return (
    <React.Fragment>
      {location.pathname != RouteName.MEETING_SCREEN_NEW && !location.pathname.includes("vonage-session") && !location.pathname.includes("video-room") && !location.pathname.includes("room") && (
        <div className="vertical-menu">
          <div className="navbar-brand-box" style={{paddingLeft:(collapseState?12:"24px")}}>
            <Link to="/" className="logo-light">
              <span className="logo-lg">
                <img src={logoLight} height="50" style={{ height: "50px", marginTop: "20px"}} />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContentSaas /> : <SidebarContentSaas />}
          </div>
          <div className="sidebar-background"></div>
        </div>
      )}
    </React.Fragment>
  );
};

SidebarSaas.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(SidebarSaas)));
