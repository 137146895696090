import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { WorkingHour } from "../../../models/WorkingHour";
import UserContext from "../../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { UserDetails } from "../../../models/User";
import { ProgressBar } from "react-bootstrap";
import videoIcon from "../../../assets/images/icon_video.svg";
import { TherapistService } from "../../../services/TherapistService";
import { MediaUploaderVimeo } from "../../../utils/MediaUploaderVimeo";
import { useHistory } from "react-router-dom";
import { RouteName } from "src/RouteName";

const TherapistIntroductoryVideoOnboardingPage: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const history = useHistory();
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([] as WorkingHour[]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const uploader = new MediaUploaderVimeo();
  const [modalcentervideo, setModalCenterVideo] = React.useState(false);
  const [fileType, setFileType] = useState("");
  const SUPPORTED_VIDEO_FORMATS = ["mp4", "mkv", "webm", "mov", "ogg", "mpeg", "mpg", "flv"];

  useEffect(() => {
    const workingHours = user?.workingHours;

    if (workingHours) {
      setWorkingHours(workingHours);
    }
  }, []);

  function togCenterVideo() {
    setModalCenterVideo(!modalcentervideo);
  }

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const selectVideoFile = async (e: any) => {
    const extension = e.target.files[0].name.split(".").pop().toLowerCase();
    const isAVideo = isSupported(SUPPORTED_VIDEO_FORMATS, extension);

    if (isAVideo) {
      setFileType("VIDEO");
    } else {
      toast.error("File type is not supported!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
    if (e.target.files.length) {
      setShowProgress(true);

      const videoMeta = await uploader.upload(e.target.files[0], (bytesUploaded: number, bytesTotal: number) => {
        setProgress(Math.floor((bytesUploaded * 100) / bytesTotal));
      });

      const updatedUser: UserDetails = {
        vimeoId: videoMeta.id,
      };

      TherapistService.updateProfileVideo(updatedUser).then(res => {
        if (res.success) {
          setUser(res.data);

          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setShowProgress(false);

          setProgress(0);

          setShowProgress(false);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      toast.error("Please select video file!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Container className="containerOnboarding">
          <div className="card-heading mb-2">
            <h5 className="font-size-25 profileFont mb-4 text-center">Welcome to Lavni</h5>
          </div>
          <Row className="p-4">
            <Col xl={12}>
              <Card className="BorderRadiusLeftRight p-3">
                <div className="card-bodyDiv">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-20  profileFont mb-4 mb-2">Introductory video</h5>
                  </div>
                </div>

                <Row>
                  <Col xl={12}>
                    <div style={{ display: "flex" }}>
                      {user?.vimeoId && (
                        <div
                          className="btn-video relative mt-3 pl-3 ml-3 pr-3 pt-2 pb-2 mb-4 cursor-pointer"
                          style={{ bottom: "0" }}
                          onClick={() => {
                            togCenterVideo();
                          }}
                        >
                          <img src={videoIcon} className="mr-2" />
                          Watch Video
                        </div>
                      )}

                      <label
                        htmlFor="file-upload-video"
                        className="file-upload relative flex flex-col text-gray-400 z-10 ml-3 rounded cursor-pointer"
                        style={{
                          borderRadius: "0.25rem",
                          cursor: "pointer",
                          display: "flex",
                          zIndex: "10",
                          position: "relative",
                        }}
                      >
                        {!user?.vimeoId && (
                          <div
                            className={user?.vimeoId ? "btn-video relative p-1 mt-3 mb-4 " : "btn-video relative mt-3 mb-4 p-1"}
                            style={{
                              bottom: "0",
                            }}
                          >
                            <img src={videoIcon} className="mr-1" />
                            &nbsp; Upload Video
                          </div>
                        )}

                        <input
                          id="file-upload-video"
                          type="file"
                          className=" btn-video relative  mt-3 mb-4"
                          style={{
                            bottom: "0",
                            display: "none",
                          }}
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={selectVideoFile}
                        />
                      </label>
                    </div>

                    <div className="progress-sec-onboarding">
                      {progress != 0 && <ProgressBar className="progress-bar-style" now={progress} label={`${progress}%`} />}
                    </div>

                    {modalcentervideo && (
                      <div
                        className="lightbox1"
                        onClick={() => {
                          setModalCenterVideo(false);
                        }}
                      >
                        <div
                          id="videoModal1"
                          className="modal1 hide1 fade1 in1"
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby="videoModalLabel"
                          aria-hidden="false"
                        >
                          <div className="modal-body1">
                            <iframe
                              src={"https://player.vimeo.com/video/" + user?.vimeoId}
                              className="videoSize"
                              width="800"
                              height="400"
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowFullScreen
                              title="Lavni Health"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <button
                      type="button"
                      className="btn btn-skip  relative mt-3 mr-2 mb-4"
                      style={{
                        bottom: "0",
                      }}
                      onClick={() => {
                        history.push(RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE);
                      }}
                    >
                      <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                      Back
                    </button>
                  </Col>

                  <Col xl={6}>
                    <Button
                      className="btn btn-success mr-2  ml-4  relative mt-3 mb-2"
                      style={{
                        bottom: "0",
                        float: "right",
                      }}
                      {...(showProgress && { disabled: true })}
                      href="./profile"
                    >
                      {showProgress ? "Uploading Video. Please Wait!" : "Get Started"}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TherapistIntroductoryVideoOnboardingPage;
