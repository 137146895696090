import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, CardTitle, NavLink, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledTooltip } from "reactstrap";
import images from "src/assets/images";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import UserContext from "src/context/UserContext";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import Spinner from "src/common/spinner/spinner";
import GroupChatContext from "src/context/GroupChatContext";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateGroupChatSessionModal from "src/pages/Popup/GroupChatModals/CreateGroupChatSessionModal";
import moment from "moment";
import { Util } from "src/Util";
import { SessionsInSelectedChatGroupModel } from "src/models/GroupChat/SessionsInSelectedChatGroup";
import UpdateGroupChatModal from "src/pages/Popup/GroupChatModals/UpdateGroupChatModal";
import UpdateGroupChatSessionModal from "src/pages/Popup/GroupChatModals/UpdateGroupChatSessionModal";
import chat from "../../../assets/images/chat/chat.png";
import sessions from "../../../assets/images/chat/sessions.png";
import schedule_session from "../../../assets/images/chat/Schedule Session.png";
import { NotificationModel } from "src/models/Notification";
import Swal from "sweetalert2";
import { VonageServices } from "../vonage/vonageServices";
import { CallInitializeData } from "src/models/CallInitializeData";
import { Role } from "src/models/Role";
import CallInitializationContext from "src/context/CallInitializationContext";
import LocalCallContext from "src/context/LocalCallContext";
import { v4 as uuidv4 } from "uuid";
import Modal6 from "src/pages/Popup/Modal6";
import SelectedGroupChatSessionView from "./SelectedGroupChatSessionView";
import Picker from "emoji-picker-react";
import { isEmpty } from "lodash";
import DisplayGroupMessageComp from "./Common/DisplayGroupMessageComp";
import { GroupChatMessageModel } from "src/models/GroupChat/GroupChatMessage";
import { encryptGroupChatMessage } from "./Common/GroupChatEncryption";
import { SocketContext } from "src/context/ScoketContext";
import { GroupChatMessageModelForSendSocket } from "src/models/GroupChat/GroupChatMessageForSendSocket";
import GroupChatReplyMessageDisplayComp from "./Common/GroupChatReplyMessageDisplayComp";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";

const SelectedGroupChatView: React.FC<{ allChatGroups: GroupChatModel[]; setAllChatGroups: (newGroups: GroupChatModel[]) => void }> = ({
  allChatGroups,
  setAllChatGroups,
}) => {
  const socket = useContext(SocketContext);
  const [user, setUser] = useContext(UserContext);
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
    reloadSessionList,
    setReloadSessionList,
  ] = useContext(GroupChatContext);

  const [showCreateGroupChatSessionModal, setShowCreateGroupChatSessionModal] = useState<boolean>(false);
  const [showUpdateGroupChatModal, setShowUpdateGroupChatModal] = useState<boolean>(false);
  const limit = 20;
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [menu, setMenu] = useState(false);
  const [selectedTabNo, setSelectedTabNo] = useState("1");
  const SCREEN_SIZE = 991;
  const initialSelectedGroup: GroupChatModel = { _id: "", title: "", description: "", type: "", createdAt: "", createdBy: "" };
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);
  const [messageBox, setMessageBox] = useState<HTMLInputElement | null>(null);
  const [groupMessageList, setGroupMessageList] = useState<GroupChatMessageModel[]>([]);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isLoadingChatGroupsInitial, setIsLoadingChatGroupsInitial] = useState(true);
  const [isLoadingChatGroupsLazy, setIsLoadingChatGroupsLazy] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [newSocketRecieveMsg, setNewSocketRecieveMsg] = useState<any>();
  const editorRef = useRef<any>(null);

  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];
  const SUPPORTED_FILE_TYPES = ["doc", "docx", "odt", "pdf", "xls", "xlsx", "ppt", "pptx", "txt"];
  const [uploadedFile, setUploadedFile] = useState({ preview: "", raw: "" });
  const [fileType, setFileType] = useState("");
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<GroupChatMessageModel | undefined>(undefined);

  const editorConfiguration = {
    toolbar: ["bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
    placeholder: "Type a message",
  };

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    if (selectedGroupData == null || selectedGroupData._id == null || selectedGroupData._id == "") {
      setIsLoadingChatGroupsInitial(true);
      setSelectedTabNo("1");
      setSelectedGroupIdForShowMembers("");
      setSelectedGroupIdForShowRequests("");
      setSelectedGroupId("");

      if (editorRef.current) {
        editorRef.current.setData("");
      }

      setShowEmojiPanel(false);
      setUploadedFile({ preview: "", raw: "" });
      setFileType("");
      setAttachmentFile(null);
      setShowReplyPanel(false);
      setSelectedMessage(undefined);
      setGroupMessageList([]);
      setIsSendingMessage(false);
      setIsLoadingChatGroupsLazy(false);
      setHasMoreData(true);
    }
    if (selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "" && selectedGroupId != selectedGroupData._id) {
      setIsLoadingChatGroupsInitial(true);
      setSelectedTabNo("1");
      setSelectedGroupIdForShowMembers("");
      setSelectedGroupIdForShowRequests("");

      if (editorRef.current) {
        editorRef.current.setData("");
      }

      setShowEmojiPanel(false);
      setUploadedFile({ preview: "", raw: "" });
      setFileType("");
      setAttachmentFile(null);
      setShowReplyPanel(false);
      setSelectedMessage(undefined);
      setGroupMessageList([]);
      setIsSendingMessage(false);
      setIsLoadingChatGroupsLazy(false);
      setSelectedGroupId(selectedGroupData._id);
      setHasMoreData(true);
      getGroupMessages(true, selectedGroupData._id);
    }
  }, [selectedGroupData]);

  useEffect(() => {
    if (!isEmpty(groupMessageList)) {
      scrollToBottom();
    }
  }, [groupMessageList]);

  useEffect(() => {
    if (
      newSocketRecieveMsg &&
      newSocketRecieveMsg.groupId &&
      newSocketRecieveMsg.groupId == selectedGroupData._id &&
      newSocketRecieveMsg.senderId &&
      newSocketRecieveMsg.senderId != user?._id &&
      newSocketRecieveMsg._id &&
      newSocketRecieveMsg.firstname != null &&
      newSocketRecieveMsg.lastname != null &&
      newSocketRecieveMsg.messageText != null &&
      newSocketRecieveMsg.createdBy &&
      newSocketRecieveMsg.createdAt
    ) {
      const newMessage: GroupChatMessageModel = {
        _id: newSocketRecieveMsg._id,
        firstname: newSocketRecieveMsg.firstname,
        lastname: newSocketRecieveMsg.lastname,
        messageText: newSocketRecieveMsg.messageText,
        createdBy: newSocketRecieveMsg.createdBy,
        createdAt: newSocketRecieveMsg.createdAt,
        messageStatus: newSocketRecieveMsg.messageStatus,
        preMessageId: newSocketRecieveMsg.preMessageId,
        mediaFileId: newSocketRecieveMsg.mediaFileId,
      };

      setGroupMessageList([...groupMessageList, newMessage]);
    }
  }, [newSocketRecieveMsg]);

  useEffect(() => {
    try {
      if (selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "") {
        socket.emit("join-group-chat-room", selectedGroupData._id);
        socket.on(`new-group-chat-message-${selectedGroupData._id}`, message => {
          setNewSocketRecieveMsg(message);
        });
      }

      socket.on("connect", () => {
        if (selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "") {
          socket.emit("join-group-chat-room", selectedGroupData._id);
        }
      });

      return () => {
        socket.off(`new-group-chat-message-${selectedGroupData._id}`);
        socket.off("connect");
      };
    } catch (error) {}
  }, [socket, selectedGroupData]);

  const jointToChatRoom = (groupId: string) => {
    try {
      socket.emit("join-group-chat-room", groupId);
    } catch (error) {}
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollTop + 100;
    }
  };

  const closeCreateGroupChatSessionModal = (value: boolean) => {
    try {
      setShowCreateGroupChatSessionModal(false);
      if (value && selectedTabNo == "2") {
        setReloadSessionList(true);
      }
    } catch (error) {}
  };

  const openEmojiPanel = () => {
    try {
      if (!isSendingMessage) {
        editorRef.current.focus();
        setShowEmojiPanel(!showEmojiPanel);
      } else {
        toast.error("Please wait, message sending in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onEmojiClick = (event: any, { emoji }: any) => {
    try {
      const editor = editorRef.current;
      const model = editor.model;

      const selection = model.document.selection;

      if (selection.isCollapsed) {
        const position = selection.getFirstPosition();

        model.change((writer: any) => {
          writer.insertText(emoji, position);
        });
      } else {
        const range = selection.getFirstRange();

        model.change((writer: any) => {
          writer.insertText(emoji, range.end);
        });
      }

      editor.focus();
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onIconClick = () => {
    const input = document.getElementById("image-input");
    if (input) {
      input.click();
    }
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const removeFile = () => {
    if (!isSendingMessage) {
      setUploadedFile({ raw: "", preview: "" });
      setFileType("");
      setAttachmentFile(null);
    } else {
      toast.error("Please wait, message sending in progress!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onFileChange = (event: any) => {
    if (!isSendingMessage) {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);
        const isAFile = isSupported(SUPPORTED_FILE_TYPES, extension);

        if (isAFile) {
          setUploadedFile({
            raw: event.target.files[0],
            preview: "",
          });
          setAttachmentFile(event.target.files[0]);
          setFileType("FILE");
        } else if (isAnImage) {
          setUploadedFile({
            raw: event.target.files[0],
            preview: URL.createObjectURL(event.target.files[0]),
          });
          setAttachmentFile(event.target.files[0]);
          setFileType("IMAGE");
        } else {
          toast.error("File format is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return;
        }
      }
    } else {
      toast.error("Please wait, message sending in progress!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const openReplyPanel = (messageDetails: GroupChatMessageModel) => {
    if (!isSendingMessage) {
      setShowReplyPanel(true);
      setSelectedMessage(messageDetails);
    } else {
      toast.error("Please wait, message sending in progress!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getGroupMessages = async (isInitial: boolean, groupId: string) => {
    try {
      const groupIdFrom = groupId;
      if (!isInitial && (isLoadingChatGroupsLazy || isLoadingChatGroupsInitial)) {
        return;
      }
      if (!isInitial && groupMessageList.length <= 0) {
        return;
      }
      const dataObject = {
        groupId: groupIdFrom,
        limit: limit,
        offset: isInitial ? 0 : groupMessageList.length,
      };
      if (isInitial) {
        setIsLoadingChatGroupsInitial(true);
      } else {
        setIsLoadingChatGroupsLazy(true);
      }
      const res = await GroupChatService.getAllMessagesInChatGroup(dataObject);
      if (groupIdFrom == selectedGroupData._id) {
        if (res.success && res.data) {
          jointToChatRoom(groupIdFrom);
          if (isInitial) {
            setIsLoadingChatGroupsInitial(false);
          } else {
            setIsLoadingChatGroupsLazy(false);
          }

          const newMessages: GroupChatMessageModel[] = res.data;
          const prevMessages: GroupChatMessageModel[] = groupMessageList;
          if (newMessages.length < limit) {
            setHasMoreData(false);
          }
          if (isInitial) {
            setGroupMessageList([...newMessages.reverse()]);
          } else {
            setGroupMessageList([...newMessages.reverse(), ...prevMessages]);
          }
        } else {
          if (!isInitial) {
            setIsLoadingChatGroupsLazy(false);
          }
          toast.error("Error retrieving messages!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      if (!isInitial) {
        setIsLoadingChatGroupsLazy(false);
      }
      toast.error("Error retrieving messages!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const sendGroupMessage = async (groupId: string) => {
    try {
      setShowEmojiPanel(false);
      const groupIdFrom = groupId;

      const messageText = editorRef.current.getData().toString();

      if (!isSendingMessage) {
        if ((messageText && messageText != "" && messageText.trim() != "" && !attachmentFile) || attachmentFile) {
          setIsSendingMessage(true);

          const formData = new FormData();

          const groupMessageData = {
            groupId: groupIdFrom,
            messageText: encryptGroupChatMessage(messageText ?? ""),
            preMessageId: selectedMessage != null && selectedMessage._id != null && selectedMessage._id != "" ? selectedMessage._id : null,
          };

          const data = JSON.stringify(groupMessageData);
          formData.append("messageDetails", data);
          if (attachmentFile) {
            formData.append("messageMedia", attachmentFile);
          }

          const res = await GroupChatService.sendMessage(formData);
          if (groupIdFrom == selectedGroupData._id) {
            if (res && res.success) {
              setShowReplyPanel(false);
              setSelectedMessage(undefined);
              setIsSendingMessage(false);

              if (editorRef.current) {
                editorRef.current.setData("");
                editorRef.current.focus();
              }
              setAttachmentFile(null);
              setUploadedFile({ raw: "", preview: "" });
              setFileType("");
              setAttachmentFile(null);
              const newMessage: GroupChatMessageModel = res.data;
              setGroupMessageList([...groupMessageList, newMessage]);

              const newMessageSocketData: GroupChatMessageModelForSendSocket = {
                _id: newMessage._id,
                firstname: newMessage.firstname,
                lastname: newMessage.lastname,
                messageText: newMessage.messageText,
                createdBy: newMessage.createdBy,
                createdAt: newMessage.createdAt,
                groupId: groupIdFrom,
                senderId: user?._id ?? "",
                messageStatus: newMessage.messageStatus,
                preMessageId: selectedMessage != null && selectedMessage._id != null && selectedMessage._id != "" ? selectedMessage : undefined,
                mediaFileId: newMessage.mediaFileId,
              };

              socket.emit("send-group-chat-message", groupIdFrom, newMessageSocketData);
            } else {
              setIsSendingMessage(false);
              toast.error("Can't send message, please try again!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
            setIsSendingMessage(false);
          }
        } else {
          toast.error("You can't send empty message!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Please wait, message sending in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsSendingMessage(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const messageDisplayComponent = () => (
    <div>
      {!isLoadingChatGroupsInitial && (
        <div>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <div
                id="scrollableDiv"
                style={{
                  height: 470,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                ref={(ref: any) => setMessageBox(ref)}
              >
                <InfiniteScroll
                  hasMore={hasMoreData}
                  next={() => {
                    getGroupMessages(false, selectedGroupData._id);
                  }}
                  inverse={true}
                  loader={null}
                  dataLength={groupMessageList.length}
                  scrollableTarget="scrollableDiv"
                >
                  {groupMessageList &&
                    groupMessageList.length > 0 &&
                    groupMessageList.map((groupMessage, index: number) => (
                      <DisplayGroupMessageComp key={index} groupMessageFrom={groupMessage} openReplyPanel={openReplyPanel} />
                    ))}
                </InfiniteScroll>
              </div>
            </ul>
          </div>

          {showEmojiPanel && (
            <div className="emoji-panel1 position-absolute" style={{ zIndex: 9999 }}>
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}

          {showReplyPanel && (
            <>
              <div className="reply-container">
                <GroupChatReplyMessageDisplayComp
                  selectedMessage={selectedMessage}
                  closeModal={() => {
                    if (!isSendingMessage) {
                      setShowReplyPanel(false);
                      setSelectedMessage(undefined);
                    } else {
                      toast.error("Please wait, message sending in progress!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                      });
                    }
                  }}
                />
              </div>
            </>
          )}

          {uploadedFile.preview && fileType == "IMAGE" ? (
            <>
              <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                <div className="upload-area">
                  <div className="chat-image-remove-button">
                    <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                  </div>
                  <div className="chat-upload-preview-container">
                    <div
                      className="chat-upload-preview imageFit pr-0 form-control"
                      style={{
                        backgroundImage: uploadedFile.preview == null || uploadedFile.preview == undefined ? "" : `url(${uploadedFile.preview})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          ) : uploadedFile.preview == "" && fileType == "FILE" ? (
            <>
              <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                <div className="upload-area">
                  <div className="chat-image-remove-button">
                    <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                  </div>
                  <div className="chat-upload-preview-container">
                    <i className="icon-copy fa fa-file iconFiles iconEdit p-2" style={{ fontSize: "70px" }}></i>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="p-3 chat-input-section">
            <Row>
              <Col className="col-auto">
                <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                  <div
                    className="mdi mdi-emoticon-happy-outline pointer1"
                    id="Imagetooltip2"
                    onClick={() => {
                      openEmojiPanel();
                    }}
                    style={{
                      fontSize: "25px",
                    }}
                  >
                    <UncontrolledTooltip placement="bottom" target="Imagetooltip2">
                      Pick an Emoji
                    </UncontrolledTooltip>
                  </div>
                </li>
                <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                  <div
                    className="mdi mdi-file-image-outline pointer1"
                    id="Imagetooltip"
                    onClick={onIconClick}
                    style={{
                      fontSize: "25px",
                    }}
                  >
                    <input type="file" id="image-input" style={{ display: "none" }} onChange={onFileChange} />
                    <UncontrolledTooltip placement="bottom" target="Imagetooltip">
                      Attach a file
                    </UncontrolledTooltip>
                  </div>
                </li>
              </Col>

              <Col className="col" style={{ padding: "0px" }}>
                <div className="">
                  <div className="chat-input-editor">
                    <CKEditor
                      editor={Editor}
                      onReady={(editor: any) => {
                        editorRef.current = editor;
                        editor.focus();
                      }}
                      data={""}
                      config={editorConfiguration}
                    />
                  </div>
                </div>
              </Col>

              <Col className="col-auto">
                <Button
                  type="button"
                  color={`${isSendingMessage ? "secondary" : "primary"}`}
                  onClick={() => sendGroupMessage(selectedGroupData._id)}
                  className="btn btn-primary btn-rounded chat-send w-md "
                >
                  <span className="d-none d-sm-inline-block me-2">{isSendingMessage ? "Sending" : "Send"}</span>
                  <i className="mdi mdi-send" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {isLoadingChatGroupsInitial && (
        <div>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <div
                id="scrollableDivForLoading"
                style={{
                  height: 470,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <div className="group-chat-empty-view">
                  <Spinner />
                </div>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && showCreateGroupChatSessionModal && (
        <CreateGroupChatSessionModal setShowModal={closeCreateGroupChatSessionModal} showModal={showCreateGroupChatSessionModal} />
      )}
      {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && showUpdateGroupChatModal && (
        <UpdateGroupChatModal
          setShowModal={setShowUpdateGroupChatModal}
          showModal={showUpdateGroupChatModal}
          allChatGroups={allChatGroups}
          setAllChatGroups={setAllChatGroups}
        />
      )}
      {screenSize < SCREEN_SIZE && selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "" && (
        <div className="flex flex-wrap mb-4 mt-1 ">
          <div
            className="flex d-inline-block cursor-pointer align-items-center justify-content-center"
            onClick={() => {
              setSelectedGroupData(initialSelectedGroup);
            }}
          >
            <i className="fas fa-regular fa-arrow-left "></i>
            <CardTitle className="align-items-center justify-content-center m-0">&nbsp; Back to Group</CardTitle>
          </div>
        </div>
      )}
      {selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "" && (
        <div className={screenSize > SCREEN_SIZE ? "w-100 user-chat" : ""}>
          <Card>
            <div className="pt-2 pb-2 ps-3 pe-3 chat-header-bg">
              <div className="row">
                <div className="col-12 col-sm-8 g-header-items-l">
                  <div className="d-flex align-items-center">
                    {selectedGroupData != null && selectedGroupData.groupIcon != null && selectedGroupData.groupIcon._id != null ? (
                      <div className="mb-0 d-inline-block me-2">
                        <img src={Util.fileURL(selectedGroupData.groupIcon._id)} className="rounded-circle avatar-sm imageFit" />
                      </div>
                    ) : (
                      <div className="mb-0 d-inline-block me-2">
                        <img src={images.defaultGroupIcon} className="rounded-circle avatar-sm imageFit" />
                      </div>
                    )}
                    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                      <DropdownToggle className="btn border-0 pl-pr-profile-header d-flex align-items-center" id="page-header-user-dropdown" tag="button">
                        <div className="truncate-text-top-bar">
                          {selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "" ? selectedGroupData.title : ""}
                        </div>
                        <i className="mdi mdi-chevron-down d-xl-inline-block" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-start">
                        <DropdownItem
                          onClick={() => {
                            setSelectedGroupIdForShowMembers(selectedGroupData._id);
                          }}
                        >
                          Members
                        </DropdownItem>
                        {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && selectedGroupData.type == "PUBLIC" && (
                          <DropdownItem
                            onClick={() => {
                              setSelectedGroupIdForShowRequests(selectedGroupData._id);
                            }}
                          >
                            Requests
                          </DropdownItem>
                        )}
                        {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && (
                          <DropdownItem
                            onClick={() => {
                              setShowUpdateGroupChatModal(true);
                            }}
                          >
                            Edit Group
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="g-icon-list d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className={`btn btn-primary ${selectedTabNo == "1" ? "btn-chat-g-h-active" : "btn-chat-g-h"} btn-rounded me-3`}
                      onClick={() => {
                        setSelectedTabNo("1");
                      }}
                    >
                      <span className="ms-2 me-2 fc-black d-none d-md-block">Chat</span>
                      <img src={chat} className="d-md-none" />
                    </button>
                    <button
                      type="button"
                      className={`btn btn-primary ${selectedTabNo == "2" ? "btn-chat-g-h-active" : "btn-chat-g-h"} btn-rounded me-3`}
                      onClick={() => {
                        setSelectedTabNo("2");
                      }}
                    >
                      <span className="ms-2 me-2 fc-black d-none d-md-block">Sessions</span>
                      <img src={sessions} className="d-md-none" />
                    </button>
                    {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && (
                      <button
                        type="button"
                        className="btn btn-primary btn-chat-g-h btn-rounded me-3"
                        onClick={() => {
                          setShowCreateGroupChatSessionModal(true);
                        }}
                      >
                        <img src={schedule_session} className="d-md-none" />
                      </button>
                    )}
                  </div>
                </div>
                {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && (
                  <div className="d-none d-md-block col-sm-4">
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        className="btn d-flex align-items-center justify-content-center schedule-btn"
                        onClick={() => {
                          setShowCreateGroupChatSessionModal(true);
                        }}
                      >
                        <img className="me-2" src={images.calendarIcon} />
                        <span className="fc-black ">Schedule Session</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={selectedTabNo == "1" ? "" : "d-none"}>{messageDisplayComponent()}</div>
            {selectedTabNo == "2" && <SelectedGroupChatSessionView></SelectedGroupChatSessionView>}
          </Card>
        </div>
      )}

      {(selectedGroupData == null || selectedGroupData._id == null || selectedGroupData._id == "") && screenSize > SCREEN_SIZE && (
        <div className="bg-white chat-body1">
          <div className="group-chat-empty-view">
            <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
            <div className="mt-1">Select some group to chat</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectedGroupChatView;
