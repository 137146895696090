import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { AuthFormType } from "src/models/AuthorizationFormData";
import PhoneInput from "react-phone-number-input";
import { AmeriHealthAuthForm } from "src/models/AuthorizationForm/ameri-health-auth-form-model";
import moment from "moment";

const AmeriHealthAuthFormPdf: React.FC<{ authorizationFormType: AuthFormType; authorizationFormData: AmeriHealthAuthForm; }> = props => {
  const authorizationFormType = props.authorizationFormType;
  const authorizationFormData = props.authorizationFormData;
  const [formData, setFormData] = useState<AmeriHealthAuthForm>();

  useEffect(() => {
    const updateAmeriHealthData: AmeriHealthAuthForm = {
        generalInformation: authorizationFormData?.generalInformation,
        serviceInformation: authorizationFormData?.serviceInformation,
        providerInformation: authorizationFormData?.providerInformation,
        prescibingProviderInformation: authorizationFormData?.prescibingProviderInformation,
    };
    setFormData(updateAmeriHealthData);
  }, [authorizationFormData]);

  // const formatDate = (date: any) => {
  //   if (!date) return '';
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0');
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment.utc(date).format('YYYY-MM-DD');
  };

  const sharedStyle: React.CSSProperties = {
    fontSize: "11px",
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    lineHeight: '1'
  };

  const sharedStyleForTable: React.CSSProperties = {
    fontSize: "0.70rem",
    width: '100%',
    padding: '2px',
    border: 'none',
    resize: 'none',
    lineHeight: '1'
  };

  return (
    <>
      {authorizationFormType === AuthFormType.AmeriHealthAuthForm && (
        <>
          <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
            <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
              <Row className="align-items-center pt-2 pb-2">
                <Col xs="12" sm="5" className="text-center text-sm-start">
                  <div>
                    <img
                      src="/static/assets/img/authorization/ameri_health_nc.png"
                      alt="Ameri Health Insurance Company Logo"
                      className="img-fluid"
                      style={{ maxWidth: '350px' }}
                    />
                  </div>
                </Col>
                <Col xs="12" sm="7" className="text-center text-sm-end">
                  <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                    <span style={{ fontSize: "20px", color: "#333", fontWeight: "500", marginBottom: "0.75rem" }}>Prior Authorization Request Form</span><br />
                    For prior authorization, fax to <strong> 1-833-893-2262</strong><br />
                    For inpatient admission notifications and<br />
                    concurrent review, fax to<strong> 1-833-894-2262</strong>
                  </p>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #B2B2B2", margin: "0" }} />
              <Row>
                <div className="card-bodyDiv" style={{ width: '100%' }}>

                  <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                    <p style={{ margin: "0" }}>
                      Authorization approves the medical necessity of the requested service only. It does not guarantee payment, nor does it guarantee that the
                      amount billed will be the amount reimbursed. The beneficiary must be eligible for NC Medicaid on the date of service or the date the equipment
                      or prosthesis is received by the beneficiary.
                    </p>
                  </div>

                </div>
              </Row>
            </Card>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>General Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">

                        <Col xs="12" sm="6" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} disabled />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>Name (last, first, M.I.) :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.generalInformation?.memberName ?? ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Date of birth :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formatDate(formData?.generalInformation?.dateOfBirth)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Address (street, city, state, ZIP code) :</label>
                        </Col>
                        <Col xs="12" sm="9">
                        
                          <div className="d-flex align-items-center">
                          <textarea
                            readOnly
                            value={formData?.generalInformation?.memberAddress ?? ''}
                            style={sharedStyle}
                          />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <label>NC Medicaid ID number :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.generalInformation?.medicaidID ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Diagnosis code :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.generalInformation?.diagnosisCode ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Diagnosis description :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.generalInformation?.diagnosisDescription ?? ''}
                              style={sharedStyle}
                          />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="7">
                          <label>Name and address of facility where services are to be rendered, if other than home or office :</label>
                        </Col>
                        <Col xs="12" sm="5">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.generalInformation?.facilityName ?? ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Inpatient</label>
                          <Input type="radio" style={{ marginLeft: "10px" }}
                            name="treatmentType"
                            readOnly
                            checked={formData?.generalInformation?.treatmentType?.inpatient === true}
                          />
                        </Col>
                        <Col xs="12" sm="3">
                          <label>Outpatient</label>
                          <Input type="radio" style={{ marginLeft: "10px" }}
                            name="treatmentType"
                            readOnly
                            checked={formData?.generalInformation?.treatmentType?.outpatient === true}
                          />
                        </Col>
                        <Col xs="12" sm="3">

                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Service Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <Table responsive className="clinical-table-bordered table-md ">
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Ref. #
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Procedure code
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              From
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Through
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}><div className="d-flex align-items-center justify-content-center">
                            Description of service/item
                          </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}> <div className="d-flex align-items-center justify-content-center">
                            Qty. or units
                          </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(10)].map((_, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid #B2B2B2", position: 'relative' }}>
                              <div style={{ position: 'absolute', top: '0', left: '0', padding: '4px', textAlign: 'center' }}>
                                ({index + 1})
                              </div>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1", paddingLeft: '30px' }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.referenceNumber ?? ''}
                              />
                            </td>
                            <td>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.procedureCode ?? ''}
                              />
                            </td>
                            <td>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.startDate)}
                              />
                            </td>
                            <td>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.endDate)}
                              />
                            </td>
                            <td>
                              <textarea
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.serviceDescription ?? ''}
                                style={sharedStyleForTable}
                              />
                            </td>
                            <td>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.itemQuantity ?? ''}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row className="align-items-center mb-2">
                      <Col xs="12" sm="7" className="mt-2 mt-sm-0">
                        <label>Detailed explanation of medical necessity for services, equipment, procedures or prostheses (attach additional pages if necessary) :</label>
                      </Col>
                      <Col xs="12" sm="5">
                        <div className="d-flex align-items-center">
                          <textarea
                            readOnly
                            value={formData?.serviceInformation?.necessityExplanation ?? ''}
                            style={sharedStyle}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Provider</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Provider name :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.providerName ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Address  :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.providerInformation?.providerAddress ?? ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>NPI :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.npi ?? ''}
                            />
                          </div>
                        </Col>
                        
                      </Row>

                      <Row className="align-items-center mb-2">
                      <Col xs="12" sm="2">
                          <label>Provider taxonomy :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.providerTaxonomy ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Fax number :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.providerInformation?.faxNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prescribing or performing provider</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Name :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.prescibingProviderInformation?.providerName ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.prescibingProviderInformation?.phoneNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  prescibingProviderInformation: {
                                    ...formData?.prescibingProviderInformation,
                                    phoneNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Address :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.prescibingProviderInformation?.providerAddress ?? ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>NPI :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.prescibingProviderInformation?.npi ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                      <Col xs="12" sm="2">
                          <label>Provider taxonomy :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.prescibingProviderInformation?.providerTaxonomy ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Fax number :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.prescibingProviderInformation?.faxNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  prescibingProviderInformation: {
                                    ...formData?.prescibingProviderInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>
                          <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                              <p style={{ margin: "0" }}>
                                By submitting this form, the provider identified in this Section IV certifies
                                that the information given in Sections I through III of this form are true,
                                accurate and complete.
                              </p>
                            </div>
                          </Card>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default AmeriHealthAuthFormPdf;
