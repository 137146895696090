import axios from "axios";
import { environment } from "../environment/environment";

const VIMEO_ACCESS_TOKEN = environment.vimeo_access_token;
const VIMEO_API_URL = environment.vimeo_base_url+'/me/videos';

const axiosInstance = axios.create({
    baseURL: environment.vimeo_base_url,
})

type VimeoPresignUploadingResponse = {
    upload: {
      upload_link: string;
    };
    uri: string;
};

export class VidemoService {
    public static async getPresignLink(data: any): Promise<VimeoPresignUploadingResponse> {
        const size = data.size;
        const body = {
            upload: {
                approach: 'tus',
                size,
            },
            privacy: {
                view: 'anybody',
                embed: 'public',
            },
            embed: {
                color: '#4338CA',
            },
        };

        const headers = {
            Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.vimeo.*+json;version=3.4'
        };

        const response = await axiosInstance.post(VIMEO_API_URL, body, {
            headers: headers
        });

        return response.data;
    }

    public static async checkTranscodeStatus(vimeoId: string): Promise<string> {
        const headers = {
            Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.vimeo.*+json;version=3.4'
        };

        const response = await axiosInstance.get(VIMEO_API_URL + "/"+vimeoId+"?fields=transcode.status", {
            headers: headers
        });

        return response.data;
    }

    public static async deleteVideo(vimeoId: string): Promise<string> {
        const headers = {
            Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.vimeo.*+json;version=3.4'
        };

        const response = await axiosInstance.delete(environment.vimeo_base_url + "/videos/"+vimeoId, {
            headers: headers
        });

        return response.data;
    }
}   