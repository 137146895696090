export interface StepProps {
  searchDocumentDownloadsByAdminSelected: any;
  onNext: (items?: string[]) => void;
  onBack?: () => void;
  currentStep: number;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
}

export interface SelectedItemsState {
  assessments: string[];
  documents: string[];
  sessionNotes: string[];
}

export enum StepEnum {
  PATIENT_INFO = 0,
  SELECT_ASSESSMENTS = 1,
  SELECT_DOCUMENTS = 2,
  SELECT_SESSION_NOTES = 3,
  PREVIEW_PDF = 4,
  SEND_FAX = 5,
  COMPLETED = 6
}
