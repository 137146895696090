import React, { useState, useEffect } from "react";
import { Col, Container, Row} from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CarolinaCompleteHealthForm from "./CarolinaCompleteHealthForm";
import { CarolinaCompleteHealthAuthForm } from "src/models/AuthorizationForm/carolina-complete-health-auth-form-model";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";

toast.configure();

const CarolinaCompleteHealthPage: React.FC<{ identifierId: any; closePopup: ()=>void; insuranceCompanyId: any; isAdmin: boolean; }> = props => {
    const [formId, setFormId] = useState<string>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [carolinaCompleteHealthData, setCarolinaCompleteHealthData] = useState<CarolinaCompleteHealthAuthForm>();
    const [clientId, setClientId] = useState<string>();
    const identifierId = props.identifierId;
    const insuranceCompanyId = props.insuranceCompanyId;
    const isAdmin = props.isAdmin;

    function getUpdateFormId(formId: string) {
        setFormId(formId);
    }

    useEffect(() => {
        setFormId(carolinaCompleteHealthData?._id);
    }, [carolinaCompleteHealthData]);

    useEffect(() => {
        getAuthorizationFormData();
    }, []);

    const getAuthorizationFormData = async () => {
        try {
            if (isAdmin) {
                const data = {
                    _id: identifierId
                }
                const res = await AuthorizationFormService.getAuthorizationFormDetailsByAdmin(data);
                if (res && res.success && res.data) {
                    setIsUpdate(true);
                    setCarolinaCompleteHealthData(res.data);
                    setFormId(carolinaCompleteHealthData?._id);
                    setClientId(res.data.clientId);
                } else {
                    setIsUpdate(false);
                }
            } else {
                const data = {
                    clientId: identifierId,
                    insuranceCompanyId: insuranceCompanyId
                }
                setClientId(identifierId);
                const res = await AuthorizationFormService.getAuthorizationFormDetailsByTherapist(data);
                if (res && res.success && res.data) {
                    setIsUpdate(true);
                    setCarolinaCompleteHealthData(res.data);
                    setFormId(carolinaCompleteHealthData?._id);
                } else {
                    setIsUpdate(false);
                }
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    return (
        <React.Fragment>
            <div className="page-content mt-0" style={{ padding: 0 }}>
                <Container className="containerOnboarding" style={{ padding: 0 }}>
                    <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
                        <Col>
                            <CarolinaCompleteHealthForm formId={formId} getUpdateFormId={getUpdateFormId} clientId={clientId} isUpdate={isUpdate} carolinaCompleteHealthData={carolinaCompleteHealthData} closePopup={() => props.closePopup()} insuranceCompanyId={insuranceCompanyId} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CarolinaCompleteHealthPage;