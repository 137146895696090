import React, { useEffect, useState } from "react";
import { Button, Collapse } from "reactstrap";
import { TPType } from "../../../models/therapyPlan/therapyPlan";
import { FormVersionService } from "../../../services/FormVersionService";
import { toast } from "react-toastify";
import moment from "moment/moment";
import TherapyPlanModal from "../../Popup/TherapyPlanModal";
import TherapyPlanPdfModal from "../../Popup/TherapyPlanPdfModal";
import Spinner from "../../../common/spinner/spinner";
import TherapyPlanPage from "src/pages/TherapyPlan/TherapyPlanPage";

const TherapyPlanSaas = (props: any) => {
    const [activeTab, setActiveTab] = useState("initial");
    const [therapyPlanInfo, setTherapyPlanInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [therapyPlanId, setTherapyPlanId] = useState("");
    const [showTherapyPlanModal, setShowTherapyPlanModal] = useState(false);
    const isAdmin = false;
    const [therapyPlanType, setTherapyPlanType] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [showTherapyPlanPdfModal, setShowTherapyPlanPdfModal] = useState<boolean>(false);
    const eventType = "DOWNLOAD";
    const [isCreatingNewForm, setIsCreatingNewForm] = useState(false);
    const [expandedPlans, setExpandedPlans] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchTherapyPlans = async () => {
            setIsLoading(true);
            try {
                const res = await FormVersionService.getTherapyPlansWithAllVersions(props.clientId);
                if (res?.success && res?.data) {
                    setTherapyPlanInfo(res.data);
                } else {
                    toast.error("Error fetching therapy plan data.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                toast.error("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchTherapyPlans();
    }, [props.clientId]);

    const fetchTherapyPlanWithVersions = async (clientIdForForm: string) => {
        try {
            const res = await FormVersionService.getTherapyPlansWithAllVersions(clientIdForForm);
            if (res && res?.success && res?.data) {
                return { success: true, data: res.data };
            } else {
                toast.error("Encountered an error while retrieving the treatment plan data with versions!", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "foo-bar",
                });
                return { success: false };
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return { success: false };
        }
    };

    const formatDate = (date: any) => (date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "");

    const createNewTherapyPlan = () => {
        setActiveTab("newForm");
    };

    const openTherapyPlan = (planId: string) => {
        setTherapyPlanId(planId);
        setShowTherapyPlanModal(true);
    };

    const downloadTherapyPlan = (planId: string, tpType: TPType) => {
        setTherapyPlanId(planId);
        setTherapyPlanType(tpType);
        setShowTherapyPlanPdfModal(true);
    };

    const refreshModal31 = async (value: boolean) => {
        if (value) {
            const result = await fetchTherapyPlanWithVersions(props.clientId);
            if (result.success) {
                setTherapyPlanInfo(result.data);
            }
        }
    };

    const toggleVersions = (planId: string) => {
        setExpandedPlans((prev) => ({
            ...prev,
            [planId]: !prev[planId],
        }));
    };

    switch (activeTab) {
        case "initial":
            return (
                <div className="p-2">
                    {showTherapyPlanModal && (
                        <TherapyPlanModal
                            setShowModal={setShowTherapyPlanModal}
                            showModal={showTherapyPlanModal}
                            identifierId={therapyPlanId}
                            isAdmin={false}
                            isFromMeeting={false}
                            isCreatingNewForm={isCreatingNewForm}
                            setModalRefresher={refreshModal31}
                        />
                    )}
                    {showTherapyPlanPdfModal && (
                        <TherapyPlanPdfModal
                            setShowModal={setShowTherapyPlanPdfModal}
                            showModal={showTherapyPlanPdfModal}
                            identifierId={therapyPlanId}
                            isAdmin={false}
                            eventType={eventType}
                            insuranceDocApprovalId={null}
                            setModalRefresher={null}
                            therapyPlanType={therapyPlanType as TPType}
                        />
                    )}
                    <div>
                        <h3 className="mb-4" style={{ color: "#f27e0c", marginTop: "3px" }}>Therapy Plans With Amendments</h3>
                        <div className="d-flex justify-content-center">
                            <Button color="primary" className="mb-4" onClick={createNewTherapyPlan}>
                                <i className="fa fa-plus mr-2"></i> Create New Therapy Plan
                            </Button>
                        </div>
                    </div>

                    {isMobile ? (
                        <span className="text-muted small">
                            <mark>Note: To view or download the amendment, please use a laptop or desktop computer.</mark>
                        </span>
                    ) : null}

                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "25vh", width: "100%", position: "relative" }}>
                            <Spinner className={"bouncer"} />
                        </div>
                    ) : (
                        <>
                            {therapyPlanInfo.length > 0 ? (
                                therapyPlanInfo.map((plan: any, planIndex: number) => (
                                    <div key={plan?._id} className="therapy-plan-item mb-4 bg-light rounded p-3 shadow-sm">
                                        <h5 className="font-weight-bold text-dark">Therapy Plan {planIndex + 1}</h5>
                                        <p className="text-muted small mb-3">Created date: {formatDate(plan?.createdAt)}</p>
                                        <div className="therapy-plan-buttons mb-3">
                                            {!isAdmin && (
                                                <Button color="success" onClick={() => openTherapyPlan(plan?._id)} className="mr-2 btn-sm shadow-sm">
                                                    <i className="fa fa-file mr-1"></i> Open / Edit
                                                </Button>
                                            )}
                                            {!isMobile && (
                                                <Button
                                                    color="info"
                                                    onClick={() => downloadTherapyPlan(plan?._id, TPType.ORIGINAL)}
                                                    className="btn-sm shadow-sm"
                                                >
                                                    <i className="fa fa-download mr-1"></i> Download
                                                </Button>
                                            )}
                                        </div>
                                        {plan?.versions && plan?.versions.length > 0 ? (
                                            <div className="therapy-plan-versions mt-3">
                                                <Button
                                                    color="link"
                                                    onClick={() => toggleVersions(plan?._id)}
                                                    className="p-0 text-decoration-none"
                                                >
                                                    <h6 className="font-weight-bold mb-0">
                                                        {expandedPlans[plan?._id] ? "Hide" : "View"} amendments{" "}
                                                        <i className={`fa fa-chevron-${expandedPlans[plan?._id] ? "up" : "down"}`} />
                                                    </h6>
                                                </Button>
                                                <Collapse isOpen={expandedPlans[plan?._id]}>
                                                    <ul className="list-unstyled mt-2">
                                                        {plan?.versions.map((version: any, versionIndex: number) => (
                                                            <li key={version?._id} className="mb-2 bg-white p-2 rounded shadow-sm">
                                                                <span className="font-weight-medium">Amendment {versionIndex + 1}</span>
                                                                <br />
                                                                <span className="text-muted small">
                                                                    Created date: <b>{formatDate(version?.versionCreatedAt)}</b>
                                                                </span>
                                                                <br />
                                                                <span className="text-muted small">
                                                                    Amendment modified due to: <b>{version?.reasonForEdit}</b>
                                                                </span>
                                                                <br />
                                                                {!props.isMobile && (
                                                                    <Button
                                                                        color="secondary"
                                                                        size="sm"
                                                                        onClick={() => downloadTherapyPlan(version?._id, TPType.VERSION)}
                                                                        className="mt-2 shadow-sm"
                                                                    >
                                                                        View / Download
                                                                    </Button>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Collapse>
                                            </div>
                                        ) : (
                                            <p className="text-muted font-italic">No amendments available.</p>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted font-italic text-center">No therapy plans created yet.</p>
                            )}
                        </>
                    )}
                </div>
            );

        case "newForm":
            return (
                <TherapyPlanPage
                    identifierId={props.clientId}
                    isAdmin={false}
                    isCreatingNewForm={true}
                    closePopup={() => setActiveTab("initial")}
                    setModalRefresher={refreshModal31}
                />
            );

        default:
            return null;
    }
};

export default TherapyPlanSaas;
