import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DiagnosisNotePage from "./pages/DiagnosisNotePage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { adminRoutes, clientRoutes, saasRoutes, therapistRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import HorizontalLayout from "./components/HorizontalLayout";
import VerticalLayout from "./components/VerticalLayout";
import Auth from "./common/Auth";
import VerifyRole from "./common/VerifyRole";
import { Role } from "./models/Role";
import { environment } from "./environment/environment";
import SocketConnection2 from "./common/SocketConnection2";
import SystemNotifications from "./common/SystemNotifications";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga";
import PublicArticleView from "./pages/Article/PublicArticleView/PublicArticleView";
import ClinicalAssessmentPdf from "./pages/ClinicalAssessmentPdf";
import OldClinicalAssessmentPdf from "./pages/OldClinicalAssessmentPdf";
import "./App.css";
import "./assets/scss/theme.scss";
import "./assets/css/style.css";
import "./assets/css/mediaQueary.css";
import "./assets/css/dashboard.css";
import "./assets/css/spaces.css";
import "../node_modules/video-react/dist/video-react.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useIdleTimer } from "react-idle-timer";
import ChatUtils from "./common/ChatUtils";
import FeedbackUtils from "./common/FeedbackUtils";
import Main from "./common/main/Main";
import AssesmentOnboardingPage from "./pages/Assesment/AssesmentOnboardingPage";
import TherapyPlanPage from "./pages/TherapyPlan/TherapyPlanPage";
import CardInfo from "./pages/visitPages/cardInfo";
import PageNotFound from "./pages/Popup/AdminStatModals/pageNotFound";
import PublicArticles from "./pages/Article/ArticleList/PublicAricles";
import PublicArticleGrid from "./pages/Article/ArticleGrid/PublicArticleGrid";
import PublicArticalIndex from "./pages/Article/ArticleGrid/PublicArticalIndex";
import PublicArticleDetails from "./pages/Article/PublicArticleDetails";
import PublicUserDetails from "./pages/Dashboard/common/PublicUserDetails";
import { RouteName } from "./RouteName";
import MainDetailsContext from "./context/MainDetailsContext";
import { Therapist } from "./models/Therapist";
import PublicAppointments from "./common/main/PublicAppointments";
import PublicVerifySignup from "./common/main/PublicVerifySignup";
import PublicSignup from "./common/main/PublicSignup";
import PublicTherapistShare from "./pages/Dashboard/common/PublicTherapistShare";
import PublicClientShare from "./pages/Dashboard/common/PublicClientShare";
import clientVerify from "./common/client-verify/VerifyClient";
import StripeCoPayment from "./common/stripe-payment/StripeCoPayment";
import PayementSuccessPage from "./common/stripe-payment/PaymentSuccessPage";
import ContactUtils from "./common/ContactRequestUtils";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";
import { UserContextForVonage } from "./pages/Chat/vonage/vonage-express/context/UserContextForVonage";
import { UserContextForVonageGroup } from "./pages/Chat/vonage/vonage-express-group/context/UserContextForVonageGroup";
import ClientSignaturePage from "./pages/Assesment/clientSignaturePage";
import ReviewUtils from "./common/ReviewUtils";
import AdminRoutePermission from "src/helpers/permissions/admin-route-permission";
import TechTicketUtils from "./common/TechTicketUtils";
import TherapistRewardUtils from "./common/TherapistRewardUtils";
import ClientRewardUtils from "./common/ClientRewardUtils";
import ClientTherapyPlanSignaturePage from "./pages/TherapyPlan/ClientTherapyPlanSignaturePage";
import TomorrowSchedulePageForTherapist from "./pages/visitPages/TomorrowSchedulePageForTherapist";
import VonageNativeSDKWaitingRoom from "src/pages/Chat/vonage/vonage-native-sdk/WaitingRoom/index";
import VonageNativeSDKVideoRoom from "src/pages/Chat/vonage/vonage-native-sdk/VideoRoom/index";
import DigitalClientAssessmentSignaturePage from "./pages/NewAssessmentDigitalForm/DigitalClientSignaturePage";
import VonageNativeEndCall from "./pages/Chat/vonage/vonage-native-sdk/EndCall";
import VerticalLayoutSaas from "./components/VerticalLayoutSaas";
import VerifyNewDashboard from "./common/VerifyNewDashboard";
import PublicUserDetailsSlug from "./pages/Dashboard/common/PublicUserDetailsSlug";

const primary = process.env.REACT_APP_PALETTE_PRIMARY || "#ff8000";
const secondary = process.env.REACT_APP_PALETTE_SECONDARY || "#d96d00";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((err) => {
      console.error('Service Worker registration failed:', err);
    });
}

interface CustomPaletteOptions extends PaletteOptions {
  bodyBackground?: {
    default: string;
    black: string;
  };
  callBackground?: {
    main: string;
  };
  toolbarBackground?: {
    main: string;
  };
  activeButtons?: {
    green: string;
    red: string;
  };
}

const theme = () => {
  return createTheme({
    palette: {
      type: "light",
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      bodyBackground: {
        default: "#131415", // Use 'default' instead of 'black'
        black: "#131415",
      },
      callBackground: {
        main: "#000000",
      },
      toolbarBackground: {
        main: "#41464D",
      },
      activeButtons: {
        green: "#1C8731",
        red: "#d96d00",
      },
    } as CustomPaletteOptions, // Cast the object to your custom type
  });
};

const stripePromise = loadStripe(environment.stripe_publishable_key);
const TRACKING_ID = environment.google_analytics_tracking_code;

ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  const timeout = 1000 * 60 * 60 * 24;
  const [remaining, setRemaining] = useState(timeout);
  const [therapistDetailsForMainContext, setTherapistDetailsForMainContext] = useState<Therapist | null>(null);
  const [publicAppoinmentDetailsForMainContext, setPublicAppoinmentDetailsForMainContext] = useState<Therapist | null>(null);
  const [userDetailsForMainContext, setUserDetailsForMainContext] = useState<any>(null);
  const [passwordForMainContext, setPasswordForMainContext] = useState<any>(null);
  const [skipButtonClickForMainContext, setSkipButtonClickForMainContext] = useState<any>(null);
  const [user, setUser] = useState({
    videoFilter: {
      filterName: false,
      filterPayload: false,
    },
    defaultSettings: {
      publishAudio: false,
      publishVideo: false,
      audioSource: undefined,
      videoSource: undefined,
      audioOutput: undefined,
    },
  });
  const [hasAccess, setHasAccess] = useState<boolean>(false);

  const changeUserContext = (val: any) => {
    setUser(val);
  };

  const handleOnIdle = () => {
    logout();
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  useEffect(() => {
    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function getLayout() {
    return HorizontalLayout;
  }

  function getLayoutVertical() {
    return VerticalLayout;
  }

  function getLayoutVerticalSaas() {
    return VerticalLayoutSaas;
  }

  const Layout = getLayout();
  const LayoutVertical = getLayoutVertical();
  const LayoutVerticalSaas = getLayoutVerticalSaas();

  const setTherapistDetailsForMainContextFunc = (data: Therapist | null) => {
    setTherapistDetailsForMainContext(data);
  };

  const handleAccessChange = (access: boolean) => {
    setHasAccess(access);
  };

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MainDetailsContext.Provider
          value={[
            therapistDetailsForMainContext,
            setTherapistDetailsForMainContextFunc,
            publicAppoinmentDetailsForMainContext,
            setPublicAppoinmentDetailsForMainContext,
            userDetailsForMainContext,
            setUserDetailsForMainContext,
            passwordForMainContext,
            setPasswordForMainContext,
            skipButtonClickForMainContext,
            setSkipButtonClickForMainContext,
          ]}
        >
          <Switch>
            <Route path="/articles/:articleId" component={PublicArticleView}></Route>
            <Route path="/new-clinical-assessment-pdf" component={ClinicalAssessmentPdf}></Route>
            <Route path="/old-clinical-assessment-pdf" component={OldClinicalAssessmentPdf}></Route>
            <Route path="/stripe-copayment/:meetingId" component={StripeCoPayment}></Route>
            <Route path="/payment-success/:meetingId/:coValue" component={PayementSuccessPage}></Route>
            <Route path="/main" component={Main}></Route>
            <Route path="/assesmentonboarding-page" component={AssesmentOnboardingPage}></Route>
            <Route path="/therapyplan-page" component={TherapyPlanPage}></Route>
            <Route path="/client-verify/:userId" component={clientVerify}></Route>
            <Route path="/public-appoinments" component={PublicAppointments}></Route>
            <Route path="/public-verify-signup" component={PublicVerifySignup}></Route>
            <Route path="/public-signup" component={PublicSignup}></Route>
            <Route path="/blogs" component={PublicArticles}></Route>
            <Route path={RouteName.MAIN_THERAPIST} component={PublicAppointments}></Route>
            <Route path="/public-articles-grid" component={PublicArticalIndex}></Route>
            <Route path="/public-article-details/:articleId/" component={PublicArticleDetails}></Route>
            <Route path="/public-user-details/:userId/" component={PublicUserDetails}></Route>
            <Route path="/therapist-profile/:userId/" component={PublicUserDetailsSlug}></Route>
            <Route path="/public-therapist-share/:userId/" component={PublicTherapistShare}></Route>
            <Route path="/public-client-share/:userId/" component={PublicClientShare}></Route>
            <Route path="/assesment-client-signature/:clientId/" component={ClientSignaturePage}></Route>
            <Route path="/therapy-plan-client-signature/:clientId/" component={ClientTherapyPlanSignaturePage}></Route>
            <Route path={RouteName.VONAGE_NATIVE_SDK_WAITING_ROOM} component={VonageNativeSDKWaitingRoom}></Route>
            <Route path={RouteName.VONAGE_NATIVE_SDK_VIDEO_ROOM} component={VonageNativeSDKVideoRoom}></Route>
            <Route path="/session/end-room" component={VonageNativeEndCall}></Route>
            <Route path="/digital-assesment-client-signature/:formId/" component={DigitalClientAssessmentSignaturePage}></Route>
            {/* <Route path="/cardInfo" component={CardInfo}></Route> */}
            <Route path="/tomorrow-schedule/:scheduleId/" component={TomorrowSchedulePageForTherapist}></Route>
            <Route path="/diagnosis-note-screen" component={DiagnosisNotePage}></Route>
            <Auth>
              {/* <Route path="/diagnosis-note-screen" component={DiagnosisNotePage}></Route> */}
              <SystemNotifications>
                <SocketConnection2>
                  <ChatUtils>
                    <Switch>
                      <VerifyRole allow={[Role.CLIENT]}>
                        <ThemeProvider theme={theme()}>
                          <UserContextForVonageGroup.Provider value={[user, changeUserContext]}>
                            <UserContextForVonage.Provider value={[user, changeUserContext]}>
                              <Layout>
                                <Elements stripe={stripePromise}>
                                  <Switch>
                                    {clientRoutes.map((route, idx) => (
                                      <Authmiddleware
                                        path={route.path}
                                        //layout={Layout}
                                        key={idx}
                                        component={route.component}
                                        isAuthProtected={true}
                                        user={Role.CLIENT}
                                        exact
                                      ></Authmiddleware>
                                    ))}
                                    {!window.location.pathname.includes("video-room") && <Route path="/*" component={PageNotFound} />}
                                  </Switch>
                                </Elements>
                              </Layout>
                            </UserContextForVonage.Provider>
                          </UserContextForVonageGroup.Provider>
                        </ThemeProvider>
                      </VerifyRole>
                    </Switch>

                    <Switch>
                      <VerifyRole allow={[Role.THERAPIST]}>
                        <ThemeProvider theme={theme()}>
                          <UserContextForVonageGroup.Provider value={[user, changeUserContext]}>
                            <UserContextForVonage.Provider value={[user, changeUserContext]}>
                              
                              {!hasAccess && (
                                <Layout>
                                  <Switch>
                                    {therapistRoutes.map((route, idx) => (
                                      <Authmiddleware
                                        path={route.path}
                                        //layout={Layout}
                                        component={route.component}
                                        key={idx}
                                        isAuthProtected={true}
                                        user={Role.THERAPIST}
                                        exact
                                      ></Authmiddleware>
                                    ))}
                                    {/* {!window.location.pathname.includes("video-room") && <Route path="/*" component={PageNotFound} />} */}
                                
                                  </Switch>

                                  {/* <Route path="*" component={TherapistDashboard} /> */}
                                </Layout>
                              )}
                            </UserContextForVonage.Provider>
                          </UserContextForVonageGroup.Provider>
                        </ThemeProvider>
                      </VerifyRole>
                    </Switch>

                    <Switch>
                      <VerifyRole allow={[Role.SUPER_ADMIN, Role.SUB_ADMIN]}>
                        <FeedbackUtils>
                          <ContactUtils>
                            <TherapistRewardUtils>
                              <ClientRewardUtils>
                                <TechTicketUtils>
                                  <ReviewUtils>
                                    <LayoutVertical>
                                      <Switch>
                                        {adminRoutes.map((route, idx) => (
                                          <AdminRoutePermission path={route.path} component={route.component} key={idx} isAuthProtected={true} exact>
                                            <Authmiddleware
                                              path={route.path}
                                              component={route.component}
                                              key={idx}
                                              isAuthProtected={true}
                                              user={[Role.SUPER_ADMIN, Role.SUB_ADMIN]}
                                              exact
                                            ></Authmiddleware>
                                          </AdminRoutePermission>
                                        ))}
                                        <Route path="/*" component={PageNotFound} />
                                      </Switch>
                                      {/* <Route path="*" component={AdminDashboard} /> */}
                                    </LayoutVertical>
                                  </ReviewUtils>
                                </TechTicketUtils>
                              </ClientRewardUtils>
                            </TherapistRewardUtils>
                          </ContactUtils>
                        </FeedbackUtils>
                      </VerifyRole>
                    </Switch>

                    <Switch>
                      <VerifyRole allow={[Role.THERAPIST]}>
                        <ThemeProvider theme={theme()}>
                          <UserContextForVonageGroup.Provider value={[user, changeUserContext]}>
                            <UserContextForVonage.Provider value={[user, changeUserContext]}>
                                <VerifyNewDashboard onAccessChange={handleAccessChange}>
                                  {hasAccess && (
                                    <LayoutVerticalSaas>
                                      <Switch>
                                        {therapistRoutes.map((route, idx) => (
                                          <Authmiddleware
                                            path={route.path}
                                            //layout={Layout}
                                            component={route.component}
                                            key={idx}
                                            isAuthProtected={true}
                                            user={Role.THERAPIST}
                                            exact
                                          ></Authmiddleware>
                                        ))}
                                        {saasRoutes.map((route, idx) => (
                                          <Authmiddleware
                                            path={route.path}
                                            component={route.component}
                                            key={idx}
                                            isAuthProtected={true}
                                            user={Role.THERAPIST}
                                            exact
                                          ></Authmiddleware>
                                        ))}
                                        {/* <Route path="/*" component={PageNotFound} /> */}
                                      </Switch>
                                    </LayoutVerticalSaas>
                                  )}
                                </VerifyNewDashboard>
                            </UserContextForVonage.Provider>
                          </UserContextForVonageGroup.Provider>
                        </ThemeProvider>
                      </VerifyRole>
                    </Switch>
                  </ChatUtils>
                </SocketConnection2>
              </SystemNotifications>
            </Auth>
          </Switch>
        </MainDetailsContext.Provider>
      </LocalizationProvider>
    </Router>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state: any) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
