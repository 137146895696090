// ModalComponent.js

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { Color } from "three";
import Close from "../../../assets/images/icons/close.png";
import { toast } from "react-toastify";
import { AdminService } from "src/services/AdminService";
type ModalComponentProps = {
  isOpen: boolean;
  toggle: () => void;
  clientId: string;
};

const ReminderModal: React.FC<ModalComponentProps> = ({ isOpen, toggle, clientId }) => {
  const initialDate = new Date();

  const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);
  const [message, setMessage] = useState({ sendMessage: "" });
  const sendReminderSms = () => {
    const dataId = {
      clientId: clientId,
      message: message?.sendMessage,
    }
    AdminService.sendReminderClient(dataId).then(res => {
      if (res.success) {
        toast.success("Reminder is sended.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        toggle();
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  }


  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggle}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
          className="btn-s-20"
        >
          <img src={Close} alt="Close" className="mt-1" />
        </Button>
      </div>
      <ModalBody className="ps-4 pe-4">
        <Row>
          <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Send Reminder </span>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <p className="mb-1">Message</p>
            <textarea rows={4} style={{ width: "100%" }} placeholder="Type a message" onChange={(e: any) => setMessage({ ...message, sendMessage: e.target.value })}></textarea>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="md-8"></Col>
          <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
            <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() => sendReminderSms()}>Send</button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ReminderModal;
