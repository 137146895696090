import React, { useEffect, useState  } from "react";
import { Card, Col, Input, Row, Table } from "reactstrap";
import moment from "moment";
import { auto } from "@popperjs/core";
import { ClinicalAssessmentGeneratePdf, currentLivingSituation } from "src/models/clinicalAssesment/clinicalAssessmentGeneratePdf";
import { addtionalHistory, CurrentMedicationsModel, generalInforEthnicity, genralInfoGender, HistoryofPsychiatricDiagnosesModel, HouseHoldMemberModel, ImpairmentLevel, LegalImpairment, LeisureActivityModel, lethalityAssessment, level, MedicalHistoryTableModel, mortivationEngageInServices, PSAServiceHistoryModel, recoveryEnvironment, SeverityScore, score, likertScale } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import PhoneInput from "react-phone-number-input";
import { htmlToText } from "html-to-text";

interface SelectedCell {
    [key: number]: number | undefined;
}

const AssessmentForm = (props: any) => {
    const { assesmenData } = props;
    const [edit, setEdit] = useState<boolean>(false);
    const [selectedCell, setSelectedCell] = useState<SelectedCell>({});
    const [aceScore, setAceScore] = useState(0);
    const [selectedCell2, setSelectedCell2] = useState<SelectedCell>({});
    const [formData, setFormData] = useState<ClinicalAssessmentGeneratePdf>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [] },
        wnl: false,
        noPSASserviceHistoryReported: false,
        noHistrotypsyDiagnosesReported: false,
        noReportHistoryOfAbuse: false,
        summaryofNeeds: {
            financial: false,
            housing: false,
            spiritual: false,
            sexuality: false,
            safetyCrisis: false,
            transportation: false,
            employment: false,
            medicalHealth: false,
            substanceUse: false,
            parentingSkills: false,
            physicalNeeds: false,
            adaptive: false,
            educationalVocational: false,
            emotionalPsychological: false,
            legalCourtInvolvement: false,
            familyAttachment: false,
            culturalImmigration: false,
            socialSkillsRecreational: false,
            communicationLanguage: false,
            behaviorManagement: false,
            problemSolvingSkills: false,
            developmentalHistory: false,
        },
    });

    useEffect(() => {
        if (assesmenData) {
        const updateFormOneData: ClinicalAssessmentGeneratePdf = {
            assesmentHeader: assesmenData?.assesmentHeader,
            lengthOfAssestment: assesmenData?.lengthOfAssestment,
            dateOfAssesment: assesmenData?.dateOfAssesment,
            comprehensiveAssessment: assesmenData?.comprehensiveAssessment,
            other: assesmenData?.other,
            generalInfor: assesmenData?.generalInfor,
            chiefComplaint: assesmenData?.chiefComplaint,
            transportSource: assesmenData.transportSource,
            currentServices: assesmenData.currentServices,
            religiousCulturalLanguagePref: assesmenData.religiousCulturalLanguagePref,
            leisureActivity: assesmenData.leisureActivity.map((activity: LeisureActivityModel) => ({
                description: activity.description || "",
                frequency: activity.frequency || "",
                barriers: activity.barriers || "",
            })),
            identifiedStrengths: assesmenData.identifiedStrengths,
            identifiedNeeds: assesmenData.identifiedNeeds,
            currentLivingSituation: assesmenData.currentLivingSituation,
            currentAddressDuration: assesmenData.currentAddressDuration,
            frequentMoves: assesmenData.frequentMoves,
            strengths: assesmenData.strengths,
            needs: assesmenData.needs,
            houseHoldMember: assesmenData.houseHoldMember.map((member: HouseHoldMemberModel) => ({
                firstName: member.firstName,
                lastName: member.lastName,
                age: member.age,
                gender: member.gender,
                consumerRelation: member.consumerRelation,
                relationDescription: member.relationDescription,
            })),
            immediateFamilyOutside: assesmenData.immediateFamilyOutside,
            feelingUnsafe: assesmenData.feelingUnsafe,
            incomeSource: assesmenData.incomeSource,
            adultDevelopmentalAbnormalities: assesmenData.adultDevelopmentalAbnormalities || "",
            historyofAbuse: assesmenData.historyofAbuse || [],
            wnl: assesmenData.wnl,
            pSAServiceHistory: (assesmenData.pSAServiceHistory || []).map((servicesHistory: PSAServiceHistoryModel) => ({
                type: servicesHistory.type || '',
                providerName: servicesHistory.providerName || '',
                datesofService: servicesHistory.datesofService || '',
                outcome: servicesHistory.outcome || '',
            })),
            historyofPsychiatricDiagnoses: (assesmenData.historyofPsychiatricDiagnoses || []).map((diagnose: HistoryofPsychiatricDiagnosesModel) => ({
                diagnosis: diagnose.diagnosis || '',
                providerName: diagnose.providerName || ''
            })),
            historyofSymptoms: assesmenData.historyofSymptoms,
            currentModications: (assesmenData.currentModications || []).map((medication: CurrentMedicationsModel) => ({
                Name: medication.Name || '',
                Dosage: medication.Dosage || '',
                Prescriber: medication.Prescriber || '',
                Effects: medication.Effects || '',
            })),
            medicalHistory: {
                medicalHistoryTableSchema: assesmenData.medicalHistory?.medicalHistoryTableSchema?.map((medicalhistory: MedicalHistoryTableModel) => ({
                    illness: medicalhistory.illness,
                    treatmentandResponse: medicalhistory.treatmentandResponse,
                    barrierstoRecovery: medicalhistory.barrierstoRecovery,
                })) || [],
                baselineMammogram: assesmenData.medicalHistory?.baselineMammogram || "",
                strengths: assesmenData.medicalHistory?.strengths || "",
                needs: assesmenData.medicalHistory?.needs || ""
            },
            healthNotes: assesmenData.healthNotes,
            legalHistory: assesmenData.legalHistory,
            substanceAbuse: assesmenData.substanceAbuse,
            asamDimensions: assesmenData.asamDimensions,
            schoolName: assesmenData.schoolName,
            highestEducation: assesmenData.highestEducation,
            employmentVocational: assesmenData.employmentVocational,
            rank: assesmenData.rank,
            yearsServed: assesmenData.yearsServed,
            reasonforDischarge: assesmenData.reasonforDischarge,
            serviceConnectedDisability: assesmenData.serviceConnectedDisability,
            independentLiving: assesmenData.independentLiving,
            mentalStatusExam: assesmenData.mentalStatusExam,
            suicideRiskPotential: assesmenData.suicideRiskPotential,
            summaryofNeeds: assesmenData.summaryofNeeds,
            recoveryHistoryandEnvironment: assesmenData.recoveryHistoryandEnvironment,
            mortivationEngageInServices: assesmenData.mortivationEngageInServices,
            currentImpairments: assesmenData.currentImpairments,
            psychiatricSymptom: assesmenData.psychiatricSymptom,
            summeryOfFindings: assesmenData.summeryOfFindings,
            eligibilityRecommendations: assesmenData.eligibilityRecommendations,
            treatmentRecommendations: assesmenData.treatmentRecommendations,
            recommendationNotes: assesmenData.recommendationNotes,
            saftyPlanNotNeeded: assesmenData.saftyPlanNotNeeded,
            clientSaftyPlanCompleted: assesmenData.clientSaftyPlanCompleted,
            clientSaftyPlan: assesmenData.clientSaftyPlan,
            findingAceScore: assesmenData.findingAceScore,
            clientSignature: assesmenData.clientSignature,
            therapistSignature: assesmenData.therapistSignature?.trim() ? assesmenData.therapistSignature : {},
            signatureDetails: assesmenData.signatureDetails,
            gad7anxiety: assesmenData.gad7anxiety,
            aceScore: assesmenData.aceScore,
            patientHealthQuestionaire: assesmenData.patientHealthQuestionaire,
            recoveryAssesmentScale: assesmenData.recoveryAssesmentScale,
            updatedAt: assesmenData?.updatedAt,
        }

        if (assesmenData && assesmenData?.gad7anxiety) {
            const selectedCells: SelectedCell = {};
            Object.entries(assesmenData?.gad7anxiety).forEach(([key, value]) => {
                const score = value as score; // Cast value to 'score' type
                if (score && Object.values(score).some(val => val === true)) {
                    // Find the index of the true value in 'score'
                    const selectedId = Object.values(score).findIndex(val => val === true) + 1;
                    selectedCells[getRowNumber(key)] = selectedId;
                }
            });
            setSelectedCell(selectedCells);
        }
        if (assesmenData && assesmenData?.patientHealthQuestionaire) {
            const selectedCells: SelectedCell = {};
            Object.entries(assesmenData?.patientHealthQuestionaire).forEach(([key, value]) => {
                const score = value as score; // Cast value to 'score' type
                if (score && Object.values(score).some(val => val === true)) {
                    // Find the index of the true value in 'score'
                    const selectedId = Object.keys(score as { [key: string]: boolean }).findIndex(scoreKey => (score as { [key: string]: boolean })[scoreKey]) + 1;
                    selectedCells[getRowNumber2(key)] = selectedId;
                }
            });
            setSelectedCell2(selectedCells);
        }
        if(assesmenData?.aceScore != undefined || assesmenData?.aceScore){
            const aceCount = Object.values(assesmenData?.aceScore).filter(value => value === true).length;
            setAceScore(aceCount);
        }

        setFormData(updateFormOneData);
        }
    }, [assesmenData]);

    const getRowNumber2 = (fieldName: string): number => {
        switch (fieldName) {
            case 'littleInteres':
                return 1;
            case 'feelingDown':
                return 2;
            case 'troubleFalling':
                return 3;
            case 'feelingTired':
                return 4;
            case 'poorAppetite':
                return 5;
            case 'feelingBad':
                return 6;
            case 'troubleConcentrating':
                return 7;
            case 'speakingSlowly':
                return 8;
            case 'thoughtsDead':
                return 9;
            default:
                return 0;
        }
    };
    const getRowNumber = (fieldName: string): number => {
        switch (fieldName) {
            case 'feelingNervous':
                return 1;
            case 'cantControllWorrying':
                return 2;
            case 'worryingMuch':
                return 3;
            case 'troubleRelaxing':
                return 4;
            case 'beingRestless':
                return 5;
            case 'easilyAnnoyed':
                return 6;
            case 'feelingAfraid':
                return 7;
            default:
                return 0;
        }
    };
    const columnTotals = Array.from({ length: 4 }, () => 0);
    Object.values(selectedCell).forEach((id) => {
        if (id !== undefined) {
            const columnIndex = id - 1;
            columnTotals[columnIndex] += columnIndex;
            // columnTotals[columnIndex]++;
        }
    });
    const cellStyle = (row: number, id: number) => {
        return selectedCell[row] === id ? { backgroundColor: 'rgb(255, 236, 220)' } : {};
    };
    const cellStyle2 = (row: number, id: number) => {
        return selectedCell2[row] === id ? { backgroundColor: 'rgb(255, 236, 220)' } : {};
    };

    function htmlToTextFunction(htmlTextMessage: string) {
        try {
          if (htmlTextMessage) {
            const finalText = htmlToText(htmlTextMessage);
            return finalText;
          }
          return "";
        } catch (error) {
          return "";
        }
    }

    return (
        <>
            {/* Form-1 */}
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-dashboard position-relative p-2">
                            <Row>
                                <Col xl={7} lg={12}>
                                    <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <img loading="lazy" src="./../static/assets/img/logo-dark.png"
                                                    style={{ height: "50px" }} />
                                            </div>
                                            <div className="col">
                                                <h5 className="font-size-20 mb-4">Lavni,Inc.<br />
                                                    COMPREHENSIVE CLINICAL ASSESSMENT</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={5} lg={12}>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Client Name :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="text"
                                                        id="name"
                                                        value={formData?.assesmentHeader?.clientName || ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of Birth :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="date"
                                                        id="date"
                                                        readOnly
                                                        value={moment.utc(formData.assesmentHeader?.dateOfBirth).format("YYYY-MM-DD")}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Insurance Number :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="text"
                                                        readOnly
                                                        value={formData.assesmentHeader?.insuranceNumber || ""}
                                                        id="name"
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of Session :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="date"
                                                        id="date"
                                                        readOnly
                                                        value={moment.utc(formData.assesmentHeader?.dateOfSession).format("YYYY-MM-DD")}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Therapist :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.assesmentHeader?.TherapistName || ""}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xxl={8} xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                                <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of Assessment :</h6>
                                </div>
                                <div className="col">
                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                        type="date"
                                        id="date"
                                        readOnly
                                        value={moment.utc(formData.dateOfAssesment).format("YYYY-MM-DD")}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xxl={4} xl={6}>
                        <div className="row">
                            <div className="col-lg-6 col-5 d-flex">
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Length of Assessment:</h6>
                            </div>
                            <div className="col-lg-6 col-4">
                                <div className="input-group input-group-sm">
                                    <input type="number" className="form-control p-1"
                                        value={formData.lengthOfAssestment || ""}
                                        readOnly
                                    />
                                    <span className="input-group-text p-1">min</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xxl={5} xl={6}>
                        <div className="row">
                            <div className="col-1">
                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    name="comprehensiveAssessment"
                                    className="form-check-input"
                                    readOnly
                                    checked={formData.comprehensiveAssessment === true}
                                />
                            </div>
                            <div className="col-10">
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Comprehensive Clinical Assessment </label>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={3} xl={6}>
                        <div className="row">
                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    name="comprehensiveAssessment"
                                    className="form-check-input"
                                    readOnly
                                    checked={formData.comprehensiveAssessment === false}
                                />
                            </div>
                            <div className="col">
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "1rem" }}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                Other :
                                            </h6>
                                        </div>
                                        <div className="col">
                                            <textarea
                                                style={{
                                                    width: "100%",
                                                    border: "none",
                                                    marginTop: "0.1rem",
                                                    backgroundColor: "unset",
                                                }}
                                                readOnly
                                                value={formData.other || ''}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>General Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>PCP Referral :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.generalInfor?.pcpReferal || ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>NPI :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.generalInfor?.npi || ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Consumer DOB :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date"
                                                    readOnly
                                                    value={moment.utc(formData.generalInfor?.consumerDob).format("YYYY-MM-DD")}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Consumer Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    defaultCountry="US"
                                                    value={formData.generalInfor?.consumerphone}
                                                    readOnly
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, consumerphone: e } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={6}>
                                    <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Sex :</h6>
                                        </div>
                                        <div className="col-auto form-check form-check-inline">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                checked={formData.generalInfor?.gender === genralInfoGender.male}
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Male</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                checked={formData.generalInfor?.gender === genralInfoGender.female}
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Female</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Age :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="number"
                                                    id="name"
                                                    readOnly
                                                    value={formData.generalInfor?.age}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={auto} md={12}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Race/Ethnicity :</h6>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            readOnly
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.Hispanic}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hispanic</label>
                                    </div>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            readOnly
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.Caucasian}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Caucasian</label>
                                    </div>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            readOnly
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.AfricanAmerican}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>African-American</label>
                                    </div>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            readOnly
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.AsianAmerican}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Asian-American</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="ethnicity"
                                                readOnly
                                                checked={formData.generalInfor?.ethnicity === generalInforEthnicity.Other}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Other :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly
                                                            value={formData.generalInfor?.other || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Emergency Contact Name :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly
                                                    value={formData.generalInfor?.emergancyContactName || ""}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, emergancyContactName: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    defaultCountry="US"
                                                    readOnly
                                                    value={formData.generalInfor?.phone}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, phone: e } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Individuals participating in the assessment :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.generalInfor?.individualParticipants}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Does the client have any other insurance besides BCBS?</h6>
                                </Col>
                                <Col lg={2} md={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            name="insuranceBesideBCBS"
                                            className="form-check-input"
                                            readOnly
                                            checked={formData.generalInfor?.insuranceBesideBCBS?.noOtherInusrnace === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                    </div>
                                </Col>
                                <Col lg={6} md={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                name="insuranceBesideBCBS"
                                                className="form-check-input"
                                                readOnly
                                                checked={formData.generalInfor?.insuranceBesideBCBS?.noOtherInusrnace === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            <span style={{ color: "red" }}>*</span> Yes: Name of Insurance :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly
                                                            value={formData.generalInfor?.insuranceBesideBCBS?.nameOfInsurance || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is the client receiving any other therapy services?</h6>
                                </Col>
                                <Col lg={2} md={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="otherTherapyService"
                                            readOnly
                                            checked={formData.generalInfor?.otherTherapyService?.noService === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}> No</label>
                                    </div>
                                </Col>
                                <Col lg={6} md={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="otherTherapyService"
                                                readOnly
                                                checked={formData.generalInfor?.otherTherapyService?.noService === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            <span style={{ color: "red" }}>*</span> Yes: Name of other Service :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly
                                                            value={formData.generalInfor?.otherTherapyService?.nameOfService || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}><span style={{ color: "red" }}>*</span> If yes, Therapist needs to call office to check on eligibility.</h6>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Chief Complaint (as stated by consumer and guardian (if applicable) in quotes)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}>Precipitating Problem(s) :</h6>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.chiefComplaint?.precipitatingProblem || "")}
                                />
                            </Card>
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                <div style={{ margin: "0.2rem" }}>
                                    <h6 style={{ fontWeight: "600", display: "inline" }}>History of Present Illness </h6>
                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                        (Include referral source, onset/precipitating events, symptoms associated with the problem, duration, frequency; previous episodes and recent progressions, if applicable) :
                                    </span>
                                </div>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.chiefComplaint?.HistoryofIllness || "")}
                                />
                            </Card>
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                <div style={{ margin: "0.2rem" }}>
                                    <h6 style={{ fontWeight: "600", display: "inline" }}>Family and Social History </h6>
                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                        (Include marital status, current familial stressors, family history of mental illness, quality of peer interactions, and gender issues, if applicable, strengths and deficits in this domain) :
                                    </span>
                                </div>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.chiefComplaint?.familySocialHistory || "")}
                                />
                            </Card>
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}>Consumer’s Short Term Goals :</h6>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.chiefComplaint?.shortTermGoal || "")}
                                />
                            </Card>
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}>Consumer’s Long Term Goals :</h6>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.chiefComplaint?.longTermGoal || "")}
                                />
                            </Card>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-2 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Current Services</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Mental Health:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="mentalHealth"
                                            readOnly
                                            checked={formData.currentServices?.mentalHealth?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="mentalHealth"
                                                readOnly
                                                checked={formData.currentServices?.mentalHealth?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.currentServices?.mentalHealth?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Substance Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="substanceAbuse"
                                            readOnly
                                            checked={formData.currentServices?.substanceAbuse?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuse"
                                                readOnly
                                                checked={formData.currentServices?.substanceAbuse?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.currentServices?.substanceAbuse?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Medical:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="medical"
                                            readOnly
                                            checked={formData.currentServices?.medical?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medical"
                                                readOnly
                                                checked={formData.currentServices?.medical?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.currentServices?.medical?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Vocational/ Ed:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="vocational"
                                            readOnly
                                            checked={formData.currentServices?.vocational?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="vocational"
                                                readOnly
                                                checked={formData.currentServices?.vocational?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.currentServices?.vocational?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Developmental:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="developmental"
                                            readOnly
                                            checked={formData.currentServices?.developmental?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="developmental"
                                                readOnly
                                                checked={formData.currentServices?.developmental?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.currentServices?.developmental?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Other:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="other"
                                            readOnly
                                            checked={formData.currentServices?.other?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="other"
                                                readOnly
                                                checked={formData.currentServices?.other?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.currentServices?.other?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Attitude about treatment over time :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.currentServices?.treatmentAttitudeOverTime || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Factors that have contributed to or inhibited previous recovery efforts :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.currentServices?.previousRecoveryFactors || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Notes :</h6>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.currentServices?.notes || "")}
                                />
                            </Card>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Religious / Cultural / Language Preferences</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={4}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="religion"
                                            readOnly
                                            checked={formData.religiousCulturalLanguagePref?.christian === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Christian</label>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <Row>
                                        <div className="col-auto pr-0">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="religion"
                                                readOnly
                                                checked={formData.religiousCulturalLanguagePref?.doesntKnow === true}
                                            />
                                        </div>
                                        <div className="col-5 p-0">
                                            <label className="fw-500" style={{ margin: "0.2rem" }}>Doesn’t Know</label>
                                        </div>
                                    </Row>
                                </Col>
                                <Col xl={4}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="religion"
                                                readOnly
                                                checked={formData.religiousCulturalLanguagePref?.otherReligion?.isOtherReligion === true}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            Other :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly
                                                            value={formData.religiousCulturalLanguagePref?.otherReligion?.otherReligionName || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="language"
                                            readOnly
                                            checked={formData.religiousCulturalLanguagePref?.english === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>English</label>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="language"
                                            readOnly
                                            checked={formData.religiousCulturalLanguagePref?.spanish === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Spanish</label>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <div className="row">
                                        <div className="col-auto d-flex" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="language"
                                                readOnly
                                                checked={formData.religiousCulturalLanguagePref?.otherlanguage?.isOtherLanguage === true}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            Other :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly
                                                            value={formData.religiousCulturalLanguagePref?.otherlanguage?.otherLanguageName || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Leisure Activities / Interests</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }}>
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "33%" }}>Description of Activity</td>
                                            <td className="fw-500" style={{ width: "34%" }}>Frequency</td>
                                            <td className="fw-500" style={{ width: "33%" }}>Barriers</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.leisureActivity?.map((activity, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={formData.leisureActivity[index]?.description || ""}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={formData.leisureActivity[index]?.frequency}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={formData.leisureActivity[index]?.barriers || ""}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="col-auto">
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", margin: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        id="strengths_1"
                                                                        readOnly
                                                                        value={formData.identifiedStrengths || ""}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginRight: "0.5rem", marginLeft: "0.5rem", marginBottom: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified needs :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        id="needs_1"
                                                                        readOnly
                                                                        value={formData.identifiedNeeds || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </td>
                                    </tr>
                                </Table>
                            </div>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Current Living Situation :</h6>
                                        </div>

                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.RENTING || ''}
                                                name="currentLivingSituation"
                                                readOnly
                                                checked={formData.currentLivingSituation === currentLivingSituation.RENTING}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Renting</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.OWN}
                                                name="currentLivingSituation"
                                                readOnly
                                                checked={formData.currentLivingSituation === currentLivingSituation.OWN}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Own</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.HOUSE}
                                                name="currentLivingSituation"
                                                readOnly
                                                checked={formData.currentLivingSituation === currentLivingSituation.HOUSE}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>House</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.APT}
                                                name="currentLivingSituation"
                                                readOnly
                                                checked={formData.currentLivingSituation === currentLivingSituation.APT}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Apartment</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.MOBILEHOME}
                                                name="currentLivingSituation"
                                                readOnly
                                                checked={formData.currentLivingSituation === currentLivingSituation.MOBILEHOME}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Mobile Home</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="col-xl-7">
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>How long at current address?</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly
                                                    value={formData.currentAddressDuration || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                        <div className="col-auto pr-0">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                readOnly
                                                checked={formData.frequentMoves}
                                            />
                                        </div>
                                        <div className="col-10 p-0">
                                            <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Frequent moves?</label>
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    value={formData.strengths || ""}
                                                    readOnly
                                                    id="strengths_2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>dentified needs :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    readOnly
                                                    id="needs_2"
                                                    value={formData.needs || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }}>
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "21%" }}>Household members<br />(First name & last initial)</td>
                                            <td className="fw-500" style={{ width: "8%" }}>Age</td>
                                            <td className="fw-500" style={{ width: "13%" }}>Gender</td>
                                            <td className="fw-500" style={{ width: "18%" }}>Relationship to Consumer</td>
                                            <td className="fw-500" style={{ width: "40%" }}>Describe relationship</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.houseHoldMember?.map((member, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={formData.houseHoldMember[index]?.firstName}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="number"
                                                            id="name"
                                                            readOnly
                                                            value={formData.houseHoldMember[index]?.age}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={formData.houseHoldMember[index]?.gender}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={member.consumerRelation}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>{member.relationDescription}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>

                            <Row>
                                <Col xl={"auto"}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Are there other members of immediate family living outside of household?</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="immediateFamilyOutside"
                                            readOnly
                                            checked={formData.immediateFamilyOutside?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="immediateFamilyOutside"
                                                readOnly
                                                checked={formData.immediateFamilyOutside?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.immediateFamilyOutside?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={"auto"}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Does consumer feel threatened or unsafe in current living environment?</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="feelingUnsafe"
                                            readOnly
                                            checked={formData.feelingUnsafe?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}> None reported</label>
                                    </div>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="feelingUnsafe"
                                                readOnly
                                                checked={formData.feelingUnsafe?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.feelingUnsafe?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={5}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Source of income for consumer or guardians :</h6>
                                </Col>
                                <Col xl={5}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="incomeSource"
                                                readOnly
                                                checked={formData.incomeSource?.employed || false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Employed : Where?</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.incomeSource?.where || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={2}>
                                    <div className="col-auto pb-1 d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="incomeSource"
                                            readOnly
                                            checked={formData.incomeSource?.SSDI || false}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>SSDI</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={5}>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="incomeSource"
                                                readOnly
                                                checked={formData.incomeSource?.unemployed || false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Unemployed : Yes; Explain:</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.incomeSource?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={5}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Source of transportation for consumer or guardians :</h6>
                                </Col>
                                <Col xl={"auto"}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            readOnly
                                            checked={formData.transportSource?.hasaCar === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Has car</label>
                                    </div>
                                </Col>
                                <Col xl={"auto"}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            readOnly
                                            checked={formData.transportSource?.publicTransport === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Public Transportation</label>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-3 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Developmental Milestones</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Col>
                                <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                                    <div style={{ margin: "0.2rem" }}>
                                        <h6 style={{ fontWeight: "500", display: "inline" }}>
                                            If consumer is an adult note any developmental abnormalities in box below or check here if WNL
                                            <input style={{ fontSize: "larger", marginLeft: "0.5rem", marginRight: "0.5rem", marginTop: "0.1rem" }}
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={formData.wnl}
                                                readOnly
                                            />
                                            :
                                        </h6>

                                    </div>
                                    <textarea
                                        style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                        value={htmlToTextFunction(formData?.adultDevelopmentalAbnormalities || "")}
                                    />
                                </Card>
                            </Col>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex' }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '10%' }}>History of Abuse / Neglect / Trauma / Stressors</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                        checked={formData.noReportHistoryOfAbuse}
                                        readOnly
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Physical Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="physicalAbuse"
                                            checked={formData.historyofAbuse?.physicalAbuse?.nonReported === true}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="physicalAbuse"
                                                checked={formData.historyofAbuse?.physicalAbuse?.nonReported === false}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            value={formData.historyofAbuse?.physicalAbuse?.explanation || ''}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Sexual Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="sexualAbuse"
                                            readOnly
                                            checked={formData.historyofAbuse?.sexualAbuse?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="sexualAbuse"
                                                readOnly
                                                checked={formData.historyofAbuse?.sexualAbuse?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            value={formData.historyofAbuse?.sexualAbuse?.explanation || ''}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Emotional Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input
                                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="emotionalAbuse"
                                            readOnly
                                            checked={formData.historyofAbuse?.emotionalAbuse?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="emotionalAbuse"
                                                readOnly
                                                checked={formData.historyofAbuse?.emotionalAbuse?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.historyofAbuse?.emotionalAbuse?.explanation || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of Neglect:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="historyofNeglect"
                                            readOnly
                                            checked={formData.historyofAbuse?.historyofNeglect?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="historyofNeglect"
                                                readOnly
                                                checked={formData.historyofAbuse?.historyofNeglect?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.historyofAbuse?.historyofNeglect?.explanation || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Other trauma:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="otherTrauma"
                                            readOnly
                                            checked={formData.historyofAbuse?.otherTrauma?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="otherTrauma"
                                                readOnly
                                                checked={formData.historyofAbuse?.otherTrauma?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.historyofAbuse?.otherTrauma?.explanation || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is there current CPS, DSS, or APS involvement?</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="currentInvolvementCPSDSSAPS"
                                            readOnly
                                            checked={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={5}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="currentInvolvementCPSDSSAPS"
                                                readOnly
                                                checked={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.explanation || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is there history of CPS, DSS, or APS involvement?</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="historyInvolvementCPSDSSAPS"
                                            readOnly
                                            checked={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={5}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="historyInvolvementCPSDSSAPS"
                                                readOnly
                                                checked={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.explanation || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex' }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '7%' }}>Psychiatric and Substance Abuse Services / History</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                        checked={formData.noPSASserviceHistoryReported}
                                        readOnly
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ minWidth: "600px" }}>
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "25%" }}>Type</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Provider Name</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Dates of Service (years)</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Outcome / Compliance</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.pSAServiceHistory?.map((servicesHistory, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={servicesHistory.type || ""}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={servicesHistory.providerName || ""}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="number"
                                                                id="name"
                                                                readOnly
                                                                value={servicesHistory.datesofService || ""}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={servicesHistory.outcome || ""}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex' }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '15%' }}>History of Psychiatric Diagnoses</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                        checked={formData.noHistrotypsyDiagnosesReported}
                                        readOnly
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ minWidth: "600px" }}>
                                    <thead>
                                        <tr>
                                            <td className="fw-500" style={{ width: "75%" }}>Diagnosis/Description</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Provider Name</td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.historyofPsychiatricDiagnoses?.map((diagnose, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                value={diagnose.diagnosis || ""}
                                                                readOnly
                                                                id="diagnonis" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                readOnly
                                                                value={diagnose.providerName || ""}
                                                                id="providerName"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>
                                History of Symptoms / Current Symptoms
                                <span style={{ fontSize: "0.8em" }}>
                                    (H = by history; C = current)
                                </span>
                            </h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ textAlign: "left", minWidth: "600px" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.impulsiveness || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Impulsiveness
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.irritability || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Irritability
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.changeinHealthStatus || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Change in health status
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.InvoluntaryCommitment || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Involuntary commitment
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.angerManagement || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Anger management
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.legalInvolvement || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Legal involvement
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.appetiteDisturbance || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Appetite disturbance
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.impairedJudgment || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Impaired judgment
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.substanceAbuse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Substance Abuse
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.truancy || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Truancy
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.sleepDisturbance || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Sleep disturbance
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.traumaticBrainInjury || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Traumatic Brain Injury
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.behaviorProblems || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Behavior problems
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.missedWork || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Missed work
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.delusions || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Delusions
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.memoryLoss || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Profound memory loss
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.historyofSymptoms?.moodSwings || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Mood swings
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        readOnly
                                                        id="name"
                                                        value={formData.historyofSymptoms?.otherHC || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td colSpan={5} style={{ width: "70%", paddingLeft: "0.2rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        Other :
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly
                                                            id="name"
                                                            value={formData.historyofSymptoms?.other || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>Current Medications</h5>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        style={{ fontSize: "larger" }}
                                        readOnly
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if none
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ minWidth: "600px" }}>
                                    <thead>
                                        <tr>
                                            <td className="fw-500" style={{ width: "25%" }}>Name</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Dosage/Frequency</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Prescriber</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Effects</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.currentModications?.map((medication, index) => (
                                            <><tr key={index}>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            value={medication.Name || ""}
                                                            readOnly
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={medication.Dosage || ""}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={medication.Prescriber || ""}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={medication.Effects || ""}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>


                                            </>
                                        ))}
                                    </tbody>

                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-4 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '15%' }}>Medical History / Reported Complications and Symptoms</h5>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        style={{ fontSize: "larger" }}
                                        readOnly
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if none
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "33%" }}>illness</td>
                                            <td className="fw-500" style={{ width: "34%" }}>Treatment and Response</td>
                                            <td className="fw-500" style={{ width: "33%" }}>Barriers to Recovery</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData?.medicalHistory?.medicalHistoryTableSchema?.map((medical, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={medical.illness}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={medical.treatmentandResponse}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly
                                                                value={medical.barrierstoRecovery}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                        <tr>
                                            <td colSpan={3}>
                                                <div className="col-auto">
                                                    <Row>
                                                        <Col>
                                                            <div className="row" style={{ alignItems: "center", marginTop: "0.5rem" }}>
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>If female and older than 40, baseline mammogram?</h6>
                                                                </div>

                                                                <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                                                    <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                        value={'yes'}
                                                                        name="olderthan40"
                                                                        readOnly
                                                                        checked={formData.medicalHistory?.baselineMammogram === 'yes'}
                                                                    />
                                                                    <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                                                </div>
                                                                <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                                                    <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                        value={'no'}
                                                                        name="olderthan40"
                                                                        readOnly
                                                                        checked={formData.medicalHistory?.baselineMammogram === 'no'}
                                                                    />
                                                                    <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Card style={{ backgroundColor: "#F1F1F1", margin: "0.5rem" }}>
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                                                    </div>
                                                                    <div className="col">
                                                                        <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                            id="name"
                                                                            readOnly
                                                                            value={formData.medicalHistory?.strengths || ""}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Card style={{ backgroundColor: "#F1F1F1", marginRight: "0.5rem", marginLeft: "0.5rem", marginBottom: "0.5rem" }}>
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified needs :</h6>
                                                                    </div>
                                                                    <div className="col">
                                                                        <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                            id="name"
                                                                            readOnly
                                                                            value={formData.medicalHistory?.needs || ""}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                            <Card className="match-card-body p-3">
                                <Row>
                                    <Col>
                                        <h6 className="fw-500" style={{ margin: "0.2rem", marginBottom: "0.5rem" }}>Notes :</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Allergies (any time):</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto  d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="allergies"
                                                readOnly
                                                checked={formData.healthNotes?.allergies?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="allergies"
                                                    readOnly
                                                    checked={formData.healthNotes?.allergies?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.allergies?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Enuresis or Encopresis:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto  d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="enuresisOrEncopresis"
                                                readOnly
                                                checked={formData.healthNotes?.enuresisOrEncopresis?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="enuresisOrEncopresis"
                                                    readOnly
                                                    checked={formData.healthNotes?.enuresisOrEncopresis?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.enuresisOrEncopresis?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Vision Complications:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="visionComplications"
                                                readOnly
                                                checked={formData.healthNotes?.visionComplications?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="visionComplications"
                                                    readOnly
                                                    checked={formData.healthNotes?.visionComplications?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.visionComplications?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Hearing Complications:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="hearingComplications"
                                                readOnly
                                                checked={formData.healthNotes?.hearingComplications?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="hearingComplications"
                                                    readOnly
                                                    checked={formData.healthNotes?.hearingComplications?.isReported === false || !edit}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.hearingComplications?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Dental Complications:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="dentalComplications"
                                                readOnly
                                                checked={formData.healthNotes?.dentalComplications?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="dentalComplications"
                                                    readOnly
                                                    checked={formData.healthNotes?.dentalComplications?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.dentalComplications?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Diet Restrictions:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="dietRestrictions"
                                                readOnly
                                                checked={formData.healthNotes?.dietRestrictions?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="dietRestrictions"
                                                    readOnly
                                                    checked={formData.healthNotes?.dietRestrictions?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.dietRestrictions?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Seizures:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="seizures"
                                                readOnly
                                                checked={formData.healthNotes?.seizures?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="seizures"
                                                    readOnly
                                                    checked={formData.healthNotes?.seizures?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.seizures?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Somatic Complaints:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="somaticComplaints"
                                                readOnly
                                                checked={formData.healthNotes?.somaticComplaints?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="somaticComplaints"
                                                    readOnly
                                                    checked={formData.healthNotes?.somaticComplaints?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.somaticComplaints?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Heart/ Blood Pressure:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="heartBloodPressure"
                                                readOnly
                                                checked={formData.healthNotes?.heartBloodPressure?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="heartBloodPressure"
                                                    readOnly
                                                    checked={formData.healthNotes?.heartBloodPressure?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.heartBloodPressure?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Diabetes:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="diabetes"
                                                readOnly
                                                checked={formData.healthNotes?.diabetes?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="diabetes"
                                                    readOnly
                                                    checked={formData.healthNotes?.diabetes?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.diabetes?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Hx of eating disorder:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="hxofEatingDisorder"
                                                readOnly
                                                checked={formData.healthNotes?.hxofEatingDisorder?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="hxofEatingDisorder"
                                                    readOnly
                                                    checked={formData.healthNotes?.hxofEatingDisorder?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.hxofEatingDisorder?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Problems Sleeping:</h6>
                                            </div>

                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    readOnly
                                                    checked={formData.healthNotes?.problemsSleeping?.isReported === true}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                            </div>
                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    readOnly
                                                    checked={formData.healthNotes?.problemsSleeping?.goingtoSleep === true}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Going to sleep</label>
                                            </div>
                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    readOnly
                                                    checked={formData.healthNotes?.problemsSleeping?.stayingAsleep === true}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Staying asleep</label>
                                            </div>
                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    readOnly
                                                    checked={formData.healthNotes?.problemsSleeping?.Nightmares === true}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Nightmares</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of head injury:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="historyofHeadInjury"
                                                readOnly
                                                checked={formData.healthNotes?.historyofHeadInjury?.isReported === true}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="historyofHeadInjury"
                                                    readOnly
                                                    checked={formData.healthNotes?.historyofHeadInjury?.isReported === false}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                readOnly
                                                                value={formData.healthNotes?.historyofHeadInjury?.explanation || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of last physical:</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={moment.utc(formData.healthNotes?.dateofLastPhysical || "").format("YYYY-MM-DD")}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Legal History</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row style={{ marginBottom: "0.5rem" }}>
                                <div className="row">
                                    <div className="col-auto pr-0 d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            readOnly
                                            checked={formData.legalHistory?.ifNoCurrent}
                                        />
                                    </div>
                                    <div className="col-10 p-0">
                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Check if no current / previous legal history reported</label>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Current legal charges?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="name"
                                                            readOnly
                                                            value={formData.legalHistory?.currentlegalCharges?.explanation || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Probation officer?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="name"
                                                            readOnly
                                                            value={formData.legalHistory?.probationOfficer?.name || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Currently on probation?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="name"
                                                            readOnly
                                                            value={formData.legalHistory?.currentlyonProbation?.name || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of incarcerations?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Dates/Charges :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="name"
                                                            readOnly
                                                            value={formData.legalHistory?.historyofIncarcerations?.name || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row" style={{ marginBottom: "0.5rem" }}>
                                        <div className="col-xl-3" style={{ marginBottom: "0.5rem" }}>
                                        </div>

                                        <div className="col-xl-9 d-flex">
                                            <div className="row">
                                                <div className="col-auto pr-0">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="saftyPlanNotNeeded"
                                                        readOnly
                                                        checked={formData.legalHistory?.historyofIncarcerations?.additionalInformation}
                                                    />
                                                </div>
                                                <div className="col-10 p-0">
                                                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Additional Information on Supplemental Pages</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of legal involvement?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="name"
                                                            readOnly
                                                            value={formData.legalHistory?.historyoflegalInvolvement?.name || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Family legal involvement?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            id="name"
                                                            value={formData.legalHistory?.familylegalInvolvement?.name || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Specific terms of probation / other : </h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.legalHistory?.specificTermsofProbation?.name || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-xl-5" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Overall degree of impairment related to legal issues :</h6>
                                        </div>

                                        <div className="col-xl-2 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legalImpairment"
                                                readOnly
                                                value={LegalImpairment.LOW || ''}
                                                checked={formData.legalHistory?.legalImpairment?.name === LegalImpairment.LOW}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low</label>
                                        </div>
                                        <div className="col-xl-2 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legalImpairment"
                                                readOnly
                                                value={LegalImpairment.MEDIUM || ''}
                                                checked={formData.legalHistory?.legalImpairment?.name === LegalImpairment.MEDIUM}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium</label>
                                        </div>
                                        <div className="col-xl-2 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }} type="radio"
                                                className="form-check-input"
                                                name="legalImpairment"
                                                readOnly
                                                value={LegalImpairment.HIGH || ''}
                                                checked={formData.legalHistory?.legalImpairment?.name === LegalImpairment.HIGH}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-5 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '10%' }}>Substance Abuse and Addiction Information</h5>
                                <div className="form-check">
                                    <input className="form-check-input" 
                                    type="checkbox" 
                                    style={{ fontSize: "larger" }} 
                                    readOnly
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no SA history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "15%" }}>Drug</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Age at 1st Use</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Age at Regular Use</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Current Frequency & Average Use</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Method of Administration</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Last  Date Used</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Primary, Secondary, Or Tertiary</td>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Nicotine
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.nicotine?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.nicotine?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.nicotine?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.nicotine?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.nicotine?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.nicotine?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Alcohol
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.alcohol?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.alcohol?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.alcohol?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.alcohol?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.alcohol?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.alcohol?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Marijuana
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.marijuana?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.marijuana?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.marijuana?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.marijuana?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.marijuana?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.marijuana?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Cocaine/Crack
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.cocaineCrack?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.cocaineCrack?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.cocaineCrack?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.cocaineCrack?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.cocaineCrack?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.cocaineCrack?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Amphetamines
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.amphetamines?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.amphetamines?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.amphetamines?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.amphetamines?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.amphetamines?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.amphetamines?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Ecstasy/Other
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.ecstasyOther?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.ecstasyOther?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.ecstasyOther?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.ecstasyOther?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.ecstasyOther?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.ecstasyOther?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Inhalants
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.inhalants?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.inhalants?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.inhalants?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.inhalants?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.inhalants?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.inhalants?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Heroin
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.heroin?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.heroin?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.heroin?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.heroin?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.heroin?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.heroin?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Barbiturates
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.barbiturates?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.barbiturates?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.barbiturates?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.barbiturates?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.barbiturates?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.barbiturates?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Other
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.other?.ageAtFirstUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.other?.ageAtRegularUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.other?.currentFrequencyAverageUse || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.other?.methodOfAdministration || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.other?.lastDateUsed || ""}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly
                                                        value={formData.substanceAbuse?.other?.primarySecondaryOrTertiary || ""}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row className="mt-2">
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Means of Obtaining Substance :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.substanceAbuse?.substanceMeans || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Motivation for use :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.substanceAbuse?.mortivationForUse || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Interest in reducing use and attaining abstinence :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.substanceAbuse?.useReductionInterest || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Past periods of Abstinence (when, how, previous admin. to detox, recovery history, hospitalizations, AA/NA attendance) :</h6>
                                        </div>
                                        <div className="col">
                                            <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                id="name"
                                                readOnly
                                                value={formData.substanceAbuse?.pastAbstinence || ""}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Problems usage may have caused: (job loss, family dysfunction, family violence, arrests, loss of friends, etc) :</h6>
                                        </div>
                                        <div className="col">
                                            <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                id="name"
                                                readOnly
                                                value={formData.substanceAbuse?.problemUsageCaused || ""}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-xl-auto col-md-12" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Tex Additional History :</h6>
                                        </div>

                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                readOnly
                                                value={addtionalHistory.DWI}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.DWI}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>DWI</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                readOnly
                                                value={addtionalHistory.BLACKOUTS}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.BLACKOUTS}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Blackouts</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                readOnly
                                                value={addtionalHistory.ABSENTEEISM}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.ABSENTEEISM}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Absenteeism</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                readOnly
                                                value={addtionalHistory.SEIZURES}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.SEIZURES}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Seizures</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                readOnly
                                                value={addtionalHistory.JOBLOSS}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.JOBLOSS}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Job loss</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                readOnly
                                                value={addtionalHistory.IVDRUGUSE}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.IVDRUGUSE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>IV Drug Use</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>If history of DWI/DUI charges, provide details here (dates, outcome, etc.) :</h6>
                                        </div>
                                        <div className="col">
                                            <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                id="name"
                                                readOnly
                                                value={formData.substanceAbuse?.chargesHistory || ""}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>SA Related Withdrawal Symptoms (sweating, cravings, tremors, rapid heart rate, hallucinations, nausea, paranoia) :</h6>
                                        </div>
                                        <div>
                                            <Row>
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Past :</h6>
                                                </div>
                                                <div className="col">
                                                    <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        id="past"
                                                        name="past"
                                                        readOnly
                                                        value={formData.substanceAbuse?.sARelatedWithdrawalSymptoms?.past || ""}
                                                    />
                                                </div>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Present :</h6>
                                                </div>
                                                <div className="col">
                                                    <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        id="present"
                                                        name="present"
                                                        readOnly
                                                        value={formData.substanceAbuse?.sARelatedWithdrawalSymptoms?.present || ""}
                                                    />
                                                </div>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Substance use signs of tolerance (consumer requires increasing amount of substance to obtain same effect) :</h6>
                                        </div>
                                        <div className="col">
                                            <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                id="name"
                                                readOnly
                                                value={formData.substanceAbuse?.signsOfTolerance || ""}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                readOnly
                                                checked={formData.substanceAbuse?.isRecommended}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}> Check here if more extensive substance-use evaluation is recommended :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="name"
                                                            readOnly
                                                            value={formData.substanceAbuse?.recommend || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-6 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>ASAM Dimensions</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left", minWidth: "600px" }}>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td colSpan={2}>
                                                <h6 style={{ margin: "0.3rem" }}>ASAM Dimension Information completed for all consumers with a diagnosis of substance abuse or dependency.</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-600" style={{ width: "17%", padding: "0.2rem" }}>Information</td>
                                            <td className="fw-600" style={{ width: "83%", padding: "0.2rem" }}>Level</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Intoxicated/<br />Withdrawal Symptoms
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.LOW}
                                                        name="intoxicationWithdrawal"
                                                        readOnly
                                                        checked={formData.asamDimensions?.intoxicationWithdrawal === level.LOW}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low -
                                                        <span className="fw-500">
                                                            Not under the influence; no withdrawal potential
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="intoxicationWithdrawal"
                                                        value={level.MEDIUM}
                                                        readOnly
                                                        checked={formData.asamDimensions?.intoxicationWithdrawal === level.MEDIUM}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium -
                                                        <span className="fw-500">
                                                            Recent use, potential for intoxication; presenting with initial withdrawal symptoms
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.HIGH}
                                                        name="intoxicationWithdrawal"
                                                        readOnly
                                                        checked={formData.asamDimensions?.intoxicationWithdrawal === level.HIGH}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High -
                                                        <span className="fw-500">
                                                            Severe withdrawal history; presenting with seizures, CIWA score greater than 10
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Medical Conditions
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.LOW}
                                                        name="medicalConditions"
                                                        readOnly
                                                        checked={formData.asamDimensions?.medicalConditions === level.LOW}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low -
                                                        <span className="fw-500">
                                                            No current medical problems; no diagnosed medical condition; no care from PCP or prescribed meds
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.MEDIUM}
                                                        name="medicalConditions"
                                                        readOnly
                                                        checked={formData.asamDimensions?.medicalConditions === level.MEDIUM}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium -
                                                        <span className="fw-500">
                                                            Diagnosed medical condition; care from PCP; problematic response to conditions and/or care
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.HIGH}
                                                        name="medicalConditions"
                                                        readOnly
                                                        checked={formData.asamDimensions?.medicalConditions === level.HIGH}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High -
                                                        <span className="fw-500">
                                                            Life threatening medical condition; medical problems interfering with treatment; hospitalization needed
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Psychiatric<br />Co-Morbidity
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="psychiatricCoMorbidities"
                                                            readOnly
                                                            checked={formData.asamDimensions?.psychiatricCoMorbidities === level.LOW}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>No current cognitive/emotional/behavioral conditions</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="psychiatricCoMorbidities"
                                                            readOnly
                                                            checked={formData.asamDimensions?.psychiatricCoMorbidities === level.MEDIUM}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Psychiatric Symptoms, including cognitive, emotional, behavioral; complications interfering with recovery efforts</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="psychiatricCoMorbidities"
                                                            readOnly
                                                            checked={formData.asamDimensions?.psychiatricCoMorbidities === level.HIGH}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Active DTO/s, S/HI; destructive, violent, or threatening behaviors, refusing to attend program schedule</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Motivation for Treatment
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="mortivationforTreatment"
                                                            readOnly
                                                            checked={formData.asamDimensions?.mortivationforTreatment === level.LOW}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Accepting need for treatment; attending, participating, and can ID future goals, plans</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="mortivationforTreatment"
                                                            readOnly
                                                            checked={formData.asamDimensions?.mortivationforTreatment === level.MEDIUM}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Ambivalent about treatment; seeking help to appease others; avoiding consequences</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="mortivationforTreatment"
                                                            readOnly
                                                            checked={formData.asamDimensions?.mortivationforTreatment === level.HIGH}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Denial of need for treatment despite severe consequences; refusing or is unable to engage due to DIM3, DIM5 symptoms interfering</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Relapse Prevention
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="relapsePrevention"
                                                            readOnly
                                                            checked={formData.asamDimensions?.relapsePrevention === level.LOW}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Recognizes onset signs; uses coping skills with CD or psychiatric problems</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="relapsePrevention"
                                                            readOnly
                                                            checked={formData.asamDimensions?.relapsePrevention === level.MEDIUM}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Limited awareness of relapse triggers or onset signs</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="relapsePrevention"
                                                            readOnly
                                                            checked={formData.asamDimensions?.relapsePrevention === level.HIGH}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Beliefs problematic re: continued CD use despite attendance; revisions in treatment plan; unable to recognize relapse triggers or onset signs, or recognize and employ coping skills</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Recovery Environment
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="recoveryEviornment"
                                                            readOnly
                                                            checked={formData.asamDimensions?.recoveryEviornment === level.LOW}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Supportive Recovery environment, with accessible MH, CD Support</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="recoveryEviornment"
                                                            readOnly
                                                            checked={formData.asamDimensions?.recoveryEviornment === level.MEDIUM}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Moderately supportive with problematic access to MH, CD support</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="recoveryEviornment"
                                                            readOnly
                                                            checked={formData.asamDimensions?.recoveryEviornment === level.HIGH}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Environment does not support recovery behaviors or efforts; resides with active substance users or abusive individuals</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Education</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Name of school :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="schoolName"
                                                    readOnly
                                                    value={formData.schoolName || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Highest level of education :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="educationLevel"
                                                    readOnly
                                                    value={formData.highestEducation || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>Employment / Vocational</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" readOnly style={{ fontSize: "larger" }} />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if N/A
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={6}>
                                    <div className="row">
                                        <div className="col-5" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Currently Employed:</h6>
                                        </div>

                                        <div className="col-3 d-flex">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                checked={formData.employmentVocational?.currentlyEmployed === true}
                                                name="currentlyEmployed"
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                        </div>
                                        <div className="col-3 d-flex">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                checked={formData.employmentVocational?.currentlyEmployed === false}
                                                name="currentlyEmployed"
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Current position :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="position1"
                                                    readOnly
                                                    value={formData.employmentVocational?.currentPosition || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Employed since :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    id="employedSince"
                                                    readOnly
                                                    value={formData.employmentVocational?.employedSince || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}># of jobs within last five years :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="number"
                                                    id="numOfJobs"
                                                    readOnly
                                                    value={formData.employmentVocational?.numJobsLastFiveYears || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} md={12}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>SSDI Claim Status (if applied) :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="ssid"
                                                    readOnly
                                                    value={formData.employmentVocational?.ssdiClaimStatus || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={6} md={12}>
                                    <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>SSDI Eligible?</h6>
                                        </div>

                                        <div className="col-2 form-check form-check-inline">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                checked={formData.employmentVocational?.ssdiEligible === true}
                                                name="ssdiEligible"
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                        </div>
                                        <div className="col-2 form-check form-check-inline">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                checked={formData.employmentVocational?.ssdiEligible === false}
                                                name="ssdiEligible"
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Satisfaction with current employment situation. (If not employed, is it by choice? Explain)</h6>
                                        </div>
                                        <div className="col">
                                            <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                id="satisfaction"
                                                readOnly
                                                value={formData.employmentVocational?.employSatisfaction || ""}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Employment History, including reasons for job changes :</h6>
                                        </div>
                                        <div className="col">
                                            <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                id="employmentHistory"
                                                readOnly
                                                value={formData.employmentVocational?.employmentHistory || ""}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Barriers / Challenges to improving current situation, if desired :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="barriers"
                                                    readOnly
                                                    value={formData.employmentVocational?.barriersChallenges || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="strengths"
                                                    readOnly
                                                    value={formData.employmentVocational?.identifiedStrengths || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified needs :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="needs"
                                                    readOnly
                                                    value={formData.employmentVocational?.identifiedNeeds || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '35%' }}>Military</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" readOnly style={{ fontSize: "larger" }} />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if N/A
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={4}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Branch / Rank:</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="position2"
                                                    readOnly
                                                    value={formData.rank || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={3}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Years Served :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="number"
                                                    id="servedYears"
                                                    readOnly
                                                    value={formData.yearsServed}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={5}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Reason for Discharge :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="reason"
                                                    readOnly
                                                    value={formData.reasonforDischarge}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Service connected Disability?</h6>
                                </Col>
                                <Col xl={2}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="yesOrNo"
                                            readOnly
                                            checked={formData.serviceConnectedDisability?.yesOrNo === false}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="yesOrNo"
                                                readOnly
                                                checked={formData.serviceConnectedDisability?.yesOrNo === true}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly
                                                            value={formData.serviceConnectedDisability?.explaination || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-600 mt-2">Independent Living and Self-Care Skills</h6>
                            </Row>

                            <Row className="justify-content-between">
                                <div className="col-lg-auto col">
                                    <h6>Consumer needs assistance with the following skills (check all that apply) :</h6>
                                </div>
                                <div className="col-lg-auto col d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        readOnly
                                        className="form-check-input"
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Check if no needs</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.feedingSelf}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Feeding self</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.financialAssistance}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Financial assistance</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.applyingForBenefits}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Applying for benefits</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.mealPreparation}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Meal preparation</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.legalAssistance}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Legal assistance</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.academicEnrollment}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Academic enrollment</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.groceryShopping}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Grocery shopping</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.vocationalAssistance}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Vocational assistance</label>
                                </div>
                                <div className="col-xl-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.attendingCourtMandatedEvents}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Attending court mandated events</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.nutrition}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Nutrition</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.accessingSpecializedServices}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Accessing specialized services</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.basicHygiene}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Basic age-appropriate hygiene</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.accessingSupportSystems}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Accessing support systems</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.toiletTraining}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Toilet training</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.independentLiving?.assistanceWithSkills?.transportation}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Transportation</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                    Other :
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <textarea
                                                    style={{
                                                        width: "100%",
                                                        border: "none",
                                                        marginTop: "0.1rem",
                                                        backgroundColor: "unset",
                                                    }}
                                                    id="name"
                                                    readOnly
                                                    value={formData.independentLiving?.assistanceWithSkills?.otherSkills || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-7 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Mental Status Exam</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left", minWidth: "600px" }}>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ width: "30%", padding: "0.2rem" }}>APPEARANCE / DRESS</td>
                                            <td style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.neat}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Neat</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.relaxed}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Relaxed</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.disheveled}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Disheveled</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.eccentric}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Eccentric</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.ageAppropriate}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Age appropriate</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hygiene :</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="hygiene"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.hygiene?.normal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="hygiene"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.hygiene?.bodyOdor}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Body Odor</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="hygiene"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.hygiene?.badBreath}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Bad Breath</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherDress"
                                                                                readOnly
                                                                                value={formData.mentalStatusExam?.appearanceDress?.other || ""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>BEHAVIOR</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.passive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Passive</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.guarded}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Guarded</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.attentive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Attentive</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.demanding}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Demanding</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.preoccupied}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Preoccupied</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.defensive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Defensive</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.hostile}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hostile</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.cooperative}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Cooperative</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.oppositional}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Oppositional</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.selfDestructive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Self-Destructive</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Eye Contact :</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.intense}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Intense</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.appropriate}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Appropriate</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.sporadic}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Sporadic</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.avoidant}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Avoidant</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>MOTOR</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.relaxed}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Relaxed</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.restless}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Restless</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.pacing}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Pacing</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.threatening}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Threatening</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.appearsSedated}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Appears sedated</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.catatonic}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Catatonic</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.tremors}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Tremors</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.tics}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Tics</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.mannerisms}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mannerisms</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.psychomotorRet}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Psychomotor Ret.</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.posturing}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Posturing</label>
                                                            </td>
                                                            <td colSpan={4}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherMotor"
                                                                                readOnly
                                                                                value={formData.mentalStatusExam?.motor?.other || ""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" rowSpan={3} style={{ width: "20%", padding: "0.2rem" }}>LANGUAGE</td>
                                            <td className="fw-500" style={{ width: "10%", padding: "0.2rem", textAlign: "right" }}>Rate :</td>
                                            <td className="fw-500" style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="rate"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.rate?.normal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="rate"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.rate?.pressured}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Pressured</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="rate"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.rate?.slow}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Slow</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" style={{ width: "10%", padding: "0.2rem", textAlign: "right" }}>Quality :</td>
                                            <td className="fw-500" style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.normal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.apraxic}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Apraxic</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.dysarthric}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Dysarthric</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.paraphasia}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Paraphasia</label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.clanging}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Clanging</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.echolalia}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Echolalia</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.incoherent}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Incoherent</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.neologisms}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Neologisms</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" style={{ width: "10%", padding: "0.2rem", textAlign: "right" }}>Volume:</td>
                                            <td className="fw-500" style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="volume"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.volume?.normal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="volume"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.volume?.loud}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loud</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="volume"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.language?.volume?.soft}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Soft</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>MOOD</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.normal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.elevated}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Elevated</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.depress}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Depressed</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.anxious}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Anxious</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.irritable}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Irritable</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.euphoric}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Euphoric</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.angry}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Angry</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherMood"
                                                                                readOnly
                                                                                value={formData.mentalStatusExam?.mood?.other}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>AFFECT</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.broad}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Broad</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.reactive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Reactive</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.congruent}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Congruent</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.restricted}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Restricted</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.inappropriate}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Inappropriate</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.blunted}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Blunted</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.flat}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Flat</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.labile}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Labile</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherAffect"
                                                                                readOnly
                                                                                value={formData.mentalStatusExam?.affect?.other || ""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>
                                                THOUGHT FORMATION<br />
                                                <span>
                                                    <input style={{ fontSize: "larger" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        readOnly
                                                    />
                                                    <label style={{ fontWeight: "400", margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unremarkable</label>
                                                </span>
                                            </td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.logical}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Logical</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.illogical}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Illogical</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.sequential}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Sequential</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.indecisive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Indecisive</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.goalDirected}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Goal Directed</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.concrete}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Concrete</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.relevant}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Relevant</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.irrelevant}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Irrelevant</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.distractible}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Distractible</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.flightofIdeas}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Flight of Ideas.</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.obsessive}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Obsessive</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.blocking}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Blocking</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.circumstantial}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Circumstantial</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.tangential}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Tangential</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.looseAssociations}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loose Association</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.unexplainedDizziness}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unexplained dizziness</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherThought"
                                                                                readOnly
                                                                                value={formData.mentalStatusExam?.thoughtFormation?.other}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>
                                                THOUGHT CONTENT<br />
                                                <span>
                                                    <input style={{ fontSize: "larger" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        readOnly
                                                    />
                                                    <label style={{ fontWeight: "400", margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unremarkable</label>
                                                </span>
                                            </td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.delusions}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Delusions</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.suicidal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Suicidal</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.homicidal}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Homicidal</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.obsessions}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Obsessions</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.hopelessness}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hopelessness</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.somatic}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Somatic</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.guilt}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Guilt</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.phobias}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Phobias</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.grandiose}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Grandiose</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.futureOriented}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Future Oriented</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.separationLossDwelling}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Dwells on issues of separation / loss</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.suspicious}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Suspicious</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.hallucinations}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hallucinations</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>ORIENTATION</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.person}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Person</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.place}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Place</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.time}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Time</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.situation}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Situation</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>ESTIMATED INTELLECT</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.average}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Average</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.upAverage}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                                                    <span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:arrow-up"></span>
                                                                    Average
                                                                </label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.downAverage}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                                                    <span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:arrow-down"></span>
                                                                    Average
                                                                </label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.borderline}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Borderline</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.mentalRetardation}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mental Retardation</label>
                                                            </td>

                                                            <td colSpan={3}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Known Disability:
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                readOnly
                                                                                id="knownDisability"
                                                                                value={formData.mentalStatusExam?.estimateIntellect?.knownDisability || ""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>MEMORY</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.adequate}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Adequate</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.impaired}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Impaired</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.recent}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Recent</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.remote}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Remote</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.selective}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Selective</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>
                                                PERCEPTIONS<br />
                                                <span>
                                                    <input style={{ fontSize: "larger" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        readOnly
                                                    />
                                                    <label style={{ fontWeight: "400", margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unremarkable</label>
                                                </span>
                                            </td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: "38%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.auditoryHallucinations}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Auditory Hallucinations</label>
                                                            </td>
                                                            <td colSpan={2} style={{ width: "40%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.depersonalization}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Depersonalization</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.ideasofReference}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Ideas of Ref.</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: "38%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.visualHallucinations}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Visual Hallucinations</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.traumaticFlashbacks}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Traumatic Flashbacks</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>JUDGMENT / INSIGHT</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="judgment"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.judgment?.poor}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="judgment"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.judgment?.fair}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                                            </td>
                                                            <td colSpan={3} style={{ width: "62%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="judgment"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.judgment?.good}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Good</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Impaired (explain) :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <textarea
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                id="impaired"
                                                                                readOnly
                                                                                value={formData.mentalStatusExam?.judgment?.impaired}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>IMPULSE CONTROL ATTENTION/CONCENTRATION</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.fair}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.poor}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.severe}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Severe</label>
                                                            </td>
                                                            <td colSpan={2} style={{ width: "42%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.adequate}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Adequate / Age Appropriate</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.wNL}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>WNL</label>
                                                            </td>
                                                            <td colSpan={2} style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.mildlydistractible}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mildly distractible</label>
                                                            </td>

                                                            <td colSpan={2} style={{ width: "42%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    readOnly
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.moderately}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Moderately Distractible</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '10%' }}>Suicide / Homicide Risk Potential</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" readOnly style={{ fontSize: "larger" }} />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no risk factors identified
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }}>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td colSpan={3} className="fw-600" style={{ padding: "0.2rem" }}>
                                                Suicide Attempts
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Action / Method / Date (year)</td>
                                            <td className="fw-600" style={{ width: "34%", padding: "0.2rem" }}>Ideation</td>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Plan</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="sYear"
                                                        readOnly
                                                        value={formData.suicideRiskPotential?.suicideAttempts?.actionMethod}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="sIdeation"
                                                        readOnly
                                                        value={formData.suicideRiskPotential?.suicideAttempts?.Ideation}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="sPalne"
                                                        readOnly
                                                        value={formData.suicideRiskPotential?.suicideAttempts?.plan}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="fw-600" style={{ padding: "0.2rem" }}>
                                                Homicide Attempts
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Action / Method / Date (year)</td>
                                            <td className="fw-600" style={{ width: "34%", padding: "0.2rem" }}>Ideation</td>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Plan</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="hYear"
                                                        readOnly
                                                        value={formData.suicideRiskPotential?.homicideAttempts?.actionMethod}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="hIdeation"
                                                        readOnly
                                                        value={formData.suicideRiskPotential?.homicideAttempts?.Ideation}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="hPalne"
                                                        readOnly
                                                        value={formData.suicideRiskPotential?.homicideAttempts?.plan}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ textAlign: "left" }}>
                                            <td colSpan={3} className="fw-600" style={{ padding: "0.2rem" }}>
                                                <Row>
                                                    <Col>
                                                        <div className="row">
                                                            <div className="col-md-7 col-xs-12">
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is there a history of impulsive behaviors that present a danger to self/ others?</h6>
                                                            </div>

                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="historyOfImpulsiveBehaviors"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.historyOfImpulsiveBehaviors === true}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    name="historyOfImpulsiveBehaviors"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.historyOfImpulsiveBehaviors === false}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="row">
                                                            <div className="col-md-7 col-xs-12">
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is consumer in a safe environment?</h6>
                                                            </div>

                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="safeEnviornment"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.safeEnviornment === true}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="safeEnviornment"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.safeEnviornment === false}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="row">
                                                            <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of cruelty to animals?</h6>
                                                            </div>

                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input"
                                                                    type="radio" style={{ fontSize: "larger" }}
                                                                    name="crueltytoAnimalsHistory"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.crueltytoAnimalsHistory === true}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                    name="crueltytoAnimalsHistory"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.crueltytoAnimalsHistory === false}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="row">
                                                            <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of fire-setting?</h6>
                                                            </div>

                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                    name="fireSetting"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.fireSetting === true}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                    name="fireSetting"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.fireSetting === false}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Other risk factors (i.e. alcohol or drug use, self-injury, etc) :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        className="fw-500"
                                                                        id="otherRisk"
                                                                        readOnly
                                                                        value={formData.suicideRiskPotential?.otherRiskFactors}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="row">
                                                            <div className="col-xl-auto col-md-12">
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Lethality Assessment :</h6>
                                                            </div>

                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.NONE}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.LOW}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.MODERATE}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Moderate</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.HIGH}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    readOnly
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.IMMINENT}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Imminent -  If Moderate, High, or Imminent, attach signed Safety Plan.</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Additional Comments :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        id="comments"
                                                                        className="fw-500"
                                                                        readOnly
                                                                        value={formData.suicideRiskPotential?.addtionalComments}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-8 */}
            <Card className="BorderRadiusLeftRight  m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Summary of Needs</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.financial}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Financial</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.employment}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Employment</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.educationalVocational}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Educational/ Vocational</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.socialSkillsRecreational}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Social skills/ Recreational</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.housing}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Housing</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.medicalHealth}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medical/ Health</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.emotionalPsychological}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Emotional/ Psychological</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.communicationLanguage}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Communication/ Language</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.spiritual}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Spiritual</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.substanceUse}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Substance use</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.legalCourtInvolvement}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Legal/ Court involvement</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.behaviorManagement}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavior management</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.sexuality}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Sexuality</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.parentingSkills}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Parenting skills</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.familyAttachment}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Family/ Attachment</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.problemSolvingSkills}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Problem solving skills</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.safetyCrisis}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Safety/ Crisis</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.physicalNeeds}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Physical needs</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.culturalImmigration}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Cultural/ Immigration</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.developmentalHistory}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Developmental history</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        readOnly
                                        checked={formData.summaryofNeeds?.transportation}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Transportation</label>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col-auto pr-0">
                                    <input style={{ fontSize: "larger"}}
                                        type="checkbox"
                                        className="form-check-input"
                                        name="saftyPlanNotNeeded"
                                        readOnly
                                        checked={formData.summaryofNeeds?.adaptive}
                                    />
                                </div>
                                <div className="col-10 p-0">
                                    <label className="fw-500" style={{ margin: "0.2rem" }}>Adaptive (daily living skills, self-care skills, home living skills)</label>
                                </div>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Recovery History and Environment (mental health/substance abuse)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="row">
                                <div className="col-md-auto col-xs-12">
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Check one - </h6>
                                </div>

                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousMentalHealthTreatment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousMentalHealthTreatment === false}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No previous mental health treatment</label>
                                </div>
                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousMentalHealthTreatment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousMentalHealthTreatment === true}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>previous mental health treatment</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-auto col-xs-12">
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Check one - </h6>
                                </div>

                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousSubstanceAbuseTreatment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousSubstanceAbuseTreatment === false}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No previous substance abuse treatment</label>
                                </div>
                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousSubstanceAbuseTreatment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousSubstanceAbuseTreatment === true}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>previous substance abuse treatment</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-auto col-xs-12">
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Check one - Recovery Environment :</h6>
                                </div>

                                <div className="col-lg-auto">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="recoveryEnvironment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.recoveryEnvironment === recoveryEnvironment.POOR}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                </div>
                                <div className="col-lg-auto">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="recoveryEnvironment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.recoveryEnvironment === recoveryEnvironment.FAIR}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                </div>
                                <div className="col-lg-auto">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="recoveryEnvironment"
                                        readOnly
                                        checked={formData.recoveryHistoryandEnvironment?.recoveryEnvironment === recoveryEnvironment.SUPPORTIVE}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Supportive</label>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>
                                Motivation to Engage in Services
                                <span style={{ fontSize: "0.8em" }}>
                                    (Indicate High, Medium, Low)
                                </span>
                            </h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={2} md={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="mortivationEngageInServices"
                                        readOnly
                                        checked={formData.mortivationEngageInServices === mortivationEngageInServices.LOW}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low</label>
                                </Col>
                                <Col lg={2} md={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="mortivationEngageInServices"
                                        readOnly
                                        checked={formData.mortivationEngageInServices === mortivationEngageInServices.HIGH}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High</label>
                                </Col>
                                <Col lg={3} md={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="mortivationEngageInServices"
                                        readOnly
                                        checked={formData.mortivationEngageInServices === mortivationEngageInServices.MEDIUM}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium</label>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Current Impairments</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <h6 className="fw-500 m1-2">Scale:  0=none  1=mild  2=moderate  3=severe</h6>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Mood Disturbance (depression or mania)</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                readOnly
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                readOnly
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                readOnly
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                readOnly
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                readOnly
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Mood Disturbance (depression or mania)</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Anxiety</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                readOnly
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                readOnly
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                readOnly
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                readOnly
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                readOnly
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Anxiety</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Psychosis</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                readOnly
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                readOnly
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                readOnly
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                readOnly
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                readOnly
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Psychosis</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Thinking/ Cognition/ Memory</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                readOnly
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                readOnly
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                readOnly
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                readOnly
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                readOnly
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Thinking/ Cognition/ Memory</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col xl={7} className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Impulsive/ Reckless/ Aggressive</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                readOnly
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                readOnly
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                readOnly
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                readOnly
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                readOnly
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Impulsive/ Reckless/ Aggressive</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Activities of Daily Living</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                readOnly
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                readOnly
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                readOnly
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                readOnly
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                readOnly
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Activities of Daily Living</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none">
                                    <div className="row">
                                        <div className="col-lg-auto col-sm-12">
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Weight Loss Assoc. w/Eating D/O-</h6>
                                        </div>
                                        <div className="col-lg-auto col-sm-4 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                readOnly
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Gain</label>
                                        </div>
                                        <div className="col-lg-auto col-sm-8 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                readOnly
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loss of Text pounds in last 3 months.</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                readOnly
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                readOnly
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                readOnly
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                readOnly
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                readOnly
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block">
                                    <div className="row">
                                        <div className="col-md-auto col-xs-12 p-0">
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Weight Loss Assoc. w/Eating D/O-</h6>
                                        </div>
                                        <div className="col-xl-auto p-0 d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                readOnly
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Gain</label>
                                        </div>
                                        <div className="col-xl-auto p-0 d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                readOnly
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loss of Text pounds in last 3 months.</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Medical/ Physical Condition(s)</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                readOnly
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                readOnly
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                readOnly
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                readOnly
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                readOnly
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Medical/ Physical Condition(s)</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col xl={7} className="col-xl d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Substance Abuse/ Dependence</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                readOnly
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                readOnly
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                readOnly
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                readOnly
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                readOnly
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Substance Abuse/ Dependence</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>School (Job) Performance</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                readOnly
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                readOnly
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                readOnly
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                readOnly
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                readOnly
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-lg-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>School (Job) Performance</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Social/ Marital/ Family Problems</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                readOnly
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                readOnly
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                readOnly
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                readOnly
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                readOnly
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Social/ Marital/ Family Problems</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Legal</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                readOnly
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.NONE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                readOnly
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.MILD}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                readOnly
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.MODERATE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                readOnly
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.SEVERE}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                readOnly
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.NA}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Legal</label>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Psychiatric Symptom Assessment Scale (PSAS-B)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left", minWidth: "600px", verticalAlign: "middle" }}>
                                    <thead>
                                        <tr>
                                            <td className="fw-600" style={{ width: "5%", paddingLeft: "0.2rem" }}></td>
                                            <td className="fw-600" style={{ width: "30%", paddingLeft: "0.2rem" }}>Statement (S) / Behavior (B)</td>
                                            <td className="fw-600" style={{ width: "55%", paddingLeft: "0.2rem" }}>Mild 1-2 / Moderate 3-4 / Severe 5-6</td>
                                            <td className="fw-600" style={{ width: "10%", paddingLeft: "0.2rem" }}>Score</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                1
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Anxiety statements
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                worried/fearful/panicked talk
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.anxiety}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                2
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Tension (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                seems tense/quite nervous/agitated
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.tension}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                3
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Depressive mood (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                blue/depressed/despairing
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.depressiveMood}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    depressiveMood: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                4
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Helplessness/hopelessness (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                doubtful/gloomy/sure of failure
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.helplessnessHopelessness}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    helplessnessHopelessness: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                5
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Guilt feelings (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                regret/remorse/delusional guilt
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.guiltFeelings}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                6
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Somatic concern (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                present/preoccupied with/delusional
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.somaticConcern}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                7
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Hostility (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                grumpy/angry/assaultive
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.hostility}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                8
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Suspiciousness (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                guarded/mistrustful/paranoid delusions
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.suspiciousness}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                9
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Uncooperativeness (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                gripes/resists/refuses
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.uncooperativeness}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                10
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Distractibility (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                trouble focusing/excess responses to environment/activity not pursed more than moment
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.distractibility}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                11
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Elated mood (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                unaccountably happy/seems high/euphoric
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.elatedMood}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                12
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Motor hyperactivity (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                energetic/pressure/frenetic
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.motorHyperactivity}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                13
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Disorientation (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                bewildered/confused/disoriented
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.disorientation}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                14
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Disorganized speech (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                rambling/loose/fragmented
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.disorganizedSpeech}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                15
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Grandiose statements
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                vague/specific/delusional
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.grandioseStatements}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                16
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Unusual ideas (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                odd/bizarre/delusional
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.unusualIdeas}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                17
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Hallucinatory statements
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                acknowledges/describes/involved in
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.hallucinatoryStatements}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                18
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Hallucinatory behavior
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                subtle/clear evidence/acts upon
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.hallucinatoryBehavior}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                19
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Social withdrawal (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                distant/avoids/no contact
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.socialWithdrawal}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                20
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Blunted affect (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                decreased/consistently reduced/toneless
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.bluntedAffect}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                21
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Motor retardation (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                sluggish/necessary movement only/catatonic
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.motorRetardation}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                22
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Mannerisms and posturing (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                odd/bizarre/dominates behavior
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.mannerismsPosturing}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                23
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Loss of function (global item)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                needs minimal supervision/requires some supervision/needs assistance or protection of hospital
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        disabled
                                                        value={formData.psychiatricSymptom?.lossOfFunction}
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Card >

            {/* Form-9 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Summary of Findings</h5>
                            <h6 className="fw-500" style={{ margin: "auto" }}>(Include justification for diagnoses according to DSM-V, strengths, needs, supports, recommendations for services)</h6>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Card className="bg-light-gray" style={{ marginBottom: "0.5rem", border: "1px solid #848484", borderRadius: "initial" }}>
                                <div style={{ margin: "0.2rem" }}>
                                    <h6 style={{ fontWeight: "600", display: "inline" }}>Summary of Findings Narrative :</h6>
                                </div>
                                <textarea
                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                    value={htmlToTextFunction(formData?.summeryOfFindings?.summeryOfFindingNarrative || "")}
                                />
                            </Card>
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px", verticalAlign: "middle" }}>
                                    <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                            <td className="fw-600" style={{ width: "10%", paddingLeft: "0.2rem" }}>Axis</td>
                                            <td className="fw-600" style={{ width: "30%", paddingLeft: "0.2rem" }}>Code</td>
                                            <td className="fw-600" style={{ width: "60%", paddingLeft: "0.2rem" }}>Description</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    l
                                                </div>
                                                {/* <div className="col-auto">
                                                    l
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code11"
                                                        value={formData.summeryOfFindings?.I1?.code}
                                                        readOnly
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code12"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.I2?.code}
                                                    />
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description11"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.I1?.description}
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description12"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.I2?.description}
                                                    />
                                                </div> */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    ll
                                                </div>
                                                {/* <div className="col-auto">
                                                    ll
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code21"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.II1?.code}
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code22"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.II2?.code}
                                                    />
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description21"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.II1?.description}
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description22"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.II2?.description}
                                                    />
                                                </div> */}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>
                                                lll
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    V 71.09
                                                </div>
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    No diagnosis
                                                </div>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td>
                                                lll
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code4"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.III?.code}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description4"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.III?.description}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                lV
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code4"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.IV?.code}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description4"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.IV?.description}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                V
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code5"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.V?.code}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description5"
                                                        readOnly
                                                        value={formData.summeryOfFindings?.V?.description}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <Row>
                                <h6 className="fw-600 mt-2">If immediate safety risks, describe safety planning, identified supports and coordination of care :</h6>
                            </Row>
                            <Row>
                                <Col xl={3} md={12}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Advanced Directives :</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="nonReported"
                                            readOnly
                                            checked={formData.summeryOfFindings?.advanceDirectives?.nonReported === true}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="nonReported"
                                                readOnly
                                                checked={formData.summeryOfFindings?.advanceDirectives?.nonReported === false}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Describe Safety Plan :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <textarea
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            id="advancedDirectives"
                                                            readOnly={formData.summeryOfFindings?.advanceDirectives?.nonReported !== false || !edit}
                                                            value={formData.summeryOfFindings?.advanceDirectives?.describePlan || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Recommendations</h5>
                        </Card>
                        <Card className="match-card-body p-3" style={{ marginBottom: "0.5rem" }}>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Eligibility Recommendations :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="eligibility"
                                                    readOnly
                                                    value={formData.eligibilityRecommendations || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Treatment Recommendations :</h6>
                                            </div>
                                            <div className="col">
                                                <textarea style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    id="treatment"
                                                    readOnly
                                                    value={formData.treatmentRecommendations || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="bg-light-gray" style={{ marginBottom: "0.5rem", border: "2px solid #848484", borderRadius: "initial" }}>
                            <div style={{ margin: "0.2rem" }}>
                                <h6 style={{ fontWeight: "600", display: "inline" }}>Notes :</h6>
                            </div>
                            <textarea
                                style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                value={htmlToTextFunction(formData?.recommendationNotes || "")}
                            />
                        </Card>

                        <div className="row">
                            <div className="col-auto pr-0">
                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="checkbox"
                                    className="form-check-input"
                                    name="saftyPlanNotNeeded"
                                    readOnly
                                    checked={formData.saftyPlanNotNeeded}
                                />
                            </div>
                            <div className="col-10 p-0">
                                <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Check if Safety Plan not needed at this time.</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto pr-0">
                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="checkbox"
                                    className="form-check-input"
                                    name="saftyPlanNotNeeded"
                                    readOnly
                                    checked={formData.clientSaftyPlanCompleted}
                                />
                            </div>
                            <div className="col-10 p-0">
                                <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Check if Client Safety Plan completed - Therapist will need to follow Clients at Risk Policy (in P&P Manual).</label>
                            </div>
                        </div>
                    </div>
                </Row>
            </Card>

            {/* Form-10 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Client Safety Plan</h5>
                        </Card>
                        <Card className="match-card-body">
                            <Row>
                                <h6 className="fw-600">Step 1 : People whom I can ask for help :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name1"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.people?.name1?.name || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone1"
                                                    defaultCountry="US"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.people?.name1?.phone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name1: {
                                                                        ...prevState.clientSaftyPlan?.people?.name1,
                                                                        phone: e
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name2"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.people?.name2?.name || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    type="text"
                                                    id="phone2"
                                                    readOnly
                                                    defaultCountry="US"
                                                    value={formData.clientSaftyPlan?.people?.name2?.phone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name2: {
                                                                        ...prevState.clientSaftyPlan?.people?.name2,
                                                                        phone: e
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name3"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.people?.name3?.name || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone3"
                                                    defaultCountry="US"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.people?.name3?.phone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name3: {
                                                                        ...prevState.clientSaftyPlan?.people?.name3,
                                                                        phone: e
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 2 : Professionals or agencies I can contact during a crisis :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Clinician Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly
                                                    id="clinicianName"
                                                    value={formData.clientSaftyPlan?.professionals?.clinicianName || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Clinicianphone"
                                                    defaultCountry="US"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.professionals?.clinicianPhone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals, clinicianPhone: e
                                                                }
                                                            }
                                                        }))
                                                    }
                                                // onChange={(e) =>
                                                //     setFormData((prevState) => ({
                                                //         ...prevState,
                                                //         clientSaftyPlan: {
                                                //             ...prevState.clientSaftyPlan,
                                                //             professionals: {
                                                //                 ...prevState.clientSaftyPlan?.professionals, clinicianPhone: e.target.value
                                                //             }
                                                //         }
                                                //     }))
                                                // }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">At-Home Counseling Services Emergency Contact # : 919-906-7042</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Clinician/Doctor’s Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="DoctorsName"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.professionals?.docotorName || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Doctorsphone"
                                                    defaultCountry="US"
                                                    readOnly
                                                    value={formData.clientSaftyPlan?.professionals?.docotorPhone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals, docotorPhone: e
                                                                }
                                                            }
                                                        }))
                                                    }
                                                // onChange={(e) =>
                                                //     setFormData((prevState) => ({
                                                //         ...prevState,
                                                //         clientSaftyPlan: {
                                                //             ...prevState.clientSaftyPlan,
                                                //             professionals: {
                                                //                 ...prevState.clientSaftyPlan?.professionals, docotorPhone: e.target.value
                                                //             }
                                                //         }
                                                //     }))
                                                // }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Local Urgent Care Services Address : (call 911)</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly
                                                    id="care services address"
                                                    value={formData.clientSaftyPlan?.professionals?.careServiceAddress || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">4. Suicide Prevention Lifeline Phone : 1-800-273-TALK (8255)</h6>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 3 : Making the environment safe :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly
                                                    id="environment1"
                                                    value={formData.clientSaftyPlan?.professionals?.enviornmentSafe?.[1] || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly
                                                    id="environment2"
                                                    value={formData.clientSaftyPlan?.professionals?.enviornmentSafe?.[2] || ""}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            I,
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                            value={formData.clientSaftyPlan?.professionals?.agreement?.agree || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-lg-auto col-sm-12" style={{ paddingInlineEnd: "inherit" }}>
                                            agree to call one of the above numbers if I feel like harming myself.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Client Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly
                                                    className="form-control"
                                                    id="clientSignature1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date1"
                                                    value={formData.clientSaftyPlan?.professionals?.agreement?.clientDate || ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Therapist Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date2"
                                                    value={formData.clientSaftyPlan?.professionals?.agreement?.therapistDate || ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-500" style={{ fontStyle: "italic", textAlign: "center" }}>[Fill out both of these copies – tear on dotted line and give 2nd copy below to client.]</h6>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <hr style={{ marginTop: "unset", borderStyle: "dotted", borderColor: "gray", borderWidth: "1px" }} />

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Client Safety Plan</h5>
                        </Card>
                        <Card className="match-card-body">
                            <Row>
                                <h6 className="fw-600">Step 1 : People whom I can ask for help :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone1"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name2"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone2"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name3"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone3"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 2 : : Professionals or agencies I can contact during a crisis :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Clinician Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="clinicianName"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Clinicianphone"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">At-Home Counseling Services Emergency Contact # : 919-906-7042</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Clinician/Doctor’s Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="DoctorsName"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Doctorsphone"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Local Urgent Care Services Address : (call 911)</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="Doctorsphone"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">4. Suicide Prevention Lifeline Phone : 1-800-273-TALK (8255)</h6>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 3 : Making the environment safe :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="environment1"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="environment2"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            I,
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-lg-auto col-sm-12" style={{ paddingInlineEnd: "inherit" }}>
                                            agree to call one of the above numbers if I feel like harming myself.
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Client Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature1"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                     type="date"
                                                     className="form-control"
                                                     id="date1"
                                                     readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Therapist Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature2"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date2"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-11 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                <div className="card-bodyDiv">
                    <Card className="match-card-header-secondary p-2 mb-2">
                    <h5 className="fw-500" style={{ margin: "auto" }}>
                        Signature Page
                    </h5>
                    </Card>
                    <Card className="match-card-body-second p-3">
                    <Row>
                        <h6 className="fw-600 mb-5">For Comprehensive Clinical Assessment</h6>
                    </Row>
                    <Row>
                        <div className="row justify-content-between" style={{ textAlign: "center" }}>
                        <div className="col-lg-3 col-md-12 m-2">
                            <div className="d-flex flex-column justify-content-between">
                            <div className="sign">
                                <div
                                className='sigCanvasNewDiv'
                                >
                                    {formData.therapistSignature && (
                                        <img className="sigCanvasNew" src={formData.therapistSignature} />
                                    )}
                                </div>
                            </div>
                            </div>
                            <h6 className="fw-500">Signature</h6>
                        </div>
                        <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                            <input
                            style={{
                                width: "100%",
                                border: "none",
                                borderBottom: "1px solid gray",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                                textAlign: "center",
                            }}
                            type="text"
                            id="clientName"
                            readOnly={!edit}
                            value={formData.signatureDetails?.comprehensiveCliniCalAssesment?.nameOfClinician || ""}
                            
                            />
                            <h6 className="fw-500">Name of Clinician (Print)</h6>
                        </div>
                        <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                            <input
                            style={{
                                width: "100%",
                                border: "none",
                                borderBottom: "1px solid gray",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                                textAlign: "center",
                            }}
                            type="date"
                            id="date"
                            value={moment(formData?.updatedAt).format("YYYY-MM-DD")}
                            max={moment().format("YYYY-MM-DD")}
                            />
                            <h6 className="fw-500">Date</h6>
                        </div>
                        </div>
                    </Row>
                    </Card>
                </div>
                </Row>

                <Row>
                <div className="card-bodyDiv">
                    <Card className="match-card-body-second p-3">
                    <Row>
                        <h6 className="fw-600 mb-5">Signature of client acknowledging participation in assessment :</h6>
                    </Row>
                    <Row>
                        <div className="row justify-content-between" style={{ textAlign: "center" }}>
                        <div className="col-lg-3 md-12 m-2">
                            <div className="d-flex flex-column justify-content-between">
                                <div className="sign">
                                        {formData?.clientSignature && (
                                        <img className="sigCanvasNew" src={formData?.clientSignature} />
                                    )}
                                </div>
                            </div>
                            <div className="col-auto mt-3">
                                <h6 className="fw-500">Signature of Client</h6>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                            <input
                            style={{
                                width: "100%",
                                border: "none",
                                borderBottom: "1px solid gray",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                                textAlign: "center",
                            }}
                            type="date"
                            id="Clinicianphone"
                            // max={moment().format("YYYY-MM-DD")}
                            // value={formData.signatureDetails?.clientAcknowledgingParticipation?.date}
                            />
                            <h6 className="fw-500">Date</h6>
                        </div>
                        <div className="col-sm-3 m-2"></div>
                        </div>
                    </Row>
                    </Card>
                </div>
                </Row>
                <Row>
                </Row>
            </Card>

            {/* Form-12 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>GAD-7 Anxiety</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "40%", textAlign: "left", paddingLeft: "0.5rem" }}>Over the last two weeks, how often have you  been bothered by the following problems?</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Not at all</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Several  days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>More  than half  the days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Nearly every day</td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-500" style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    1. Feeling nervous, anxious, or on edge
                                                </div>
                                            </td>
                                            <td style={cellStyle(1, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle(1, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(1, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(1, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    2. Not being able to stop or control worrying
                                                </div>
                                            </td>
                                            <td style={cellStyle(2, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle(2, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(2, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(2, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    3. Worrying too much about different things
                                                </div>
                                            </td>
                                            <td style={cellStyle(3, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle(3, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(3, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(3, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    4. Trouble relaxing
                                                </div>
                                            </td>
                                            <td style={cellStyle(4, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle(4, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(4, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(4, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    5. Being so restless that it is hard to sit still
                                                </div>
                                            </td>
                                            <td style={cellStyle(5, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle(5, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(5, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(5, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    6. Becoming easily annoyed or irritable
                                                </div>
                                            </td>
                                            <td style={cellStyle(6, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td >
                                            <td style={cellStyle(6, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(6, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(6, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    7. Feeling afraid, as if something awful  might happen
                                                </div>
                                            </td>
                                            <td style={cellStyle(7, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle(7, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle(7, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle(7, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row style={{ justifyContent: "end", margin: "0.5rem" }}>
                                <Col lg={9} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Column totals
                                        </div>

                                        {[1, 2, 3, 4].map((index) => (
                                            <React.Fragment key={index}>
                                                <div className="col" style={{ paddingInline: "inherit" }}>
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    id={`value${index}`}
                                                                    value={columnTotals[index - 1]}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                                {index !== 4 && (
                                                    <div className="col-auto fw-500" style={{ paddingInline: "inherit" }}>
                                                        +
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        <div className="col-auto fw-500">
                                            =
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "end", margin: "0.5rem" }}>
                                <Col md={3} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Total score :
                                        </div>
                                        <div className="col" style={{ paddingInline: "inherit" }}>
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="total"
                                                            value={columnTotals.reduce((acc, curr) => acc + (curr || 0), 0)}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Card className="match-card-body-second p-3">
                                <Row>
                                    <h6 className="fw-500 mb-2">If you checked any problems, how difficult have they made it for you to do your work, take care of  things at home, or get along with other people?</h6>
                                </Row>
                                <Row>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.notDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Not difficult at all</label>
                                    </Col>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.someWhatDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Somewhat difficult</label>
                                    </Col>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.veryDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Very difficult</label>
                                    </Col>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.extremlyDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Extremely difficult</label>
                                    </Col>
                                </Row>
                            </Card>
                            <Row>
                                <h6 className="fw-500" style={{ fontSize: "smaller" }}>Source: Primary Care Evaluation of Mental Disorders Patient Health Questionnaire (PRIME-MD-PHQ). The PHQ was  developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke, and colleagues. For research information, contact Dr.  Spitzer at <a href="#" className="primary-link">ris8@columbia.edu.</a>  PRIME-MD® is a trademark of Pfizer Inc. Copyright© 1999 Pfizer Inc. All rights reserved.  Reproduced with permission</h6>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Scoring GAD-7 Anxiety Severity</h5>
                        </Card>
                        <Card className="match-card-body p-2">
                            <div style={{ margin: "0.2rem" }}>
                                <h6 style={{ fontWeight: "500", display: "inline" }}>This is calculated by assigning scores of 0, 1, 2, and 3 to the response categories, respectively,  </h6>
                                <span style={{ fontWeight: "600" }}>
                                    of “not at all,” “several days,” “more than half the days,” and “nearly every day.”
                                </span>
                                <h6 style={{ fontWeight: "500", display: "inline" }}> GAD-7 total score for the seven items ranges from 0 to 21.</h6>
                            </div>
                            <div style={{ margin: "0.2rem" }}>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>0–4 : minimal anxiety</h6>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>5-9 : mild anxiety</h6>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>10-14 : moderate anxiety</h6>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>15-12 : severe anxiety</h6>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-13 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Row>
                            <h5 className="fw-600">Finding Your ACE Score</h5>
                        </Row>
                        <hr style={{ marginTop: "unset", border: "solid #FD7A08", borderWidth: "3px" }} />
                        <Card className="match-card-body p-3">
                            <h6 className="fw-500 mb-4">While you were growing up, during your first 18 years of life:</h6>
                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">1. Did a parent or other adult in the household o often or very often…</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Swear at you, insult you, put you down, or humiliate you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Act in a way that made you afraid that you might be physically hurt?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} className="align-self-end">
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.humiliateOrPhysicallyHurt}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">2. Did a parent or other adult in the household o often or very often…</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Push, grab, slap, or throw something at you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Ever hit you so hard that you had marks or were injured?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        disabled
                                        defaultChecked={formData.aceScore?.pushGrabSlap}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">3. Did an adult or person at least 5 years older than you e ever…</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Touch or fondle you or have you touch their body in a sexual way?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Attempt or actually have oral, anal, or vaginal intercourse with you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.intercourse}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">4. Did you o often or very often feel that …</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">No one in your family loved you or thought you were important or special?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Your family didn’t look out for each other, feel close to each other, or support each other?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.familySupport}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">5. Did you o often or very often feel that …</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2"> You didn’t have enough to eat, had to wear dirty clothes, and had no one to protect you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Your parents were too drunk or high to take care of you or take you to the doctor if you needed it?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.noOneToProtectYou}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">6. Were your parents e ever separated or divorced?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.parentSeperateDivorce}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">7. Was your mother or stepmother:</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Often or very often pushed, grabbed, slapped, or had something thrown at her?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Sometimes, often, or very often kicked, bitten, hit with a fist, or hit with something hard?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Ever repeatedly hit at least a few minutes or threatened with a gun or knife?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        disabled
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.threatenedWithGunOrKnife}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">8. Did you live with anyone who was a problem drinker or alcoholic or who used street drugs?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.liveWithDrugUser}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">9. Was a household member depressed or mentally ill, or did a household member attempt suicide?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.isHouseHoldMemberattemptSuicide}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">10. Did a household member go to prison?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.isHouseHOldMemberGoToPrison}
                                        disabled
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <h6 className="fw-600" style={{ margin: "0.3rem", marginLeft: "9px" }}>Now add up your “Yes” answers :</h6>
                                        </div>
                                        <div className="col-auto">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="ACEScore"
                                                            value={aceScore}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <h6 className="fw-600" style={{ margin: "0.3rem" }}>This is your ACE Score.</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <h6 className="fw-500 mb-4"
                                style={{
                                    textAlign: "end",
                                    fontSize: "xx-small"
                                }}><i>Adapted from: http://www.acestudy.org/files/ACE_Score_Calculator.pdf,   092406RA4CR</i></h6>
                        </Card>

                        <hr style={{ margin: "unset", border: "solid #FD7A08", borderWidth: "1px" }} />
                        <h6 className="fw-500 mt-1 mb-4"
                            style={{
                                textAlign: "center",
                                fontSize: "smaller",
                                color: "#FD7A08"
                            }}><i>Think Trauma: A Training for Staff in Juvenile Justice Residential Settings:  Module Four – Finding Your ACE Score</i></h6>
                    </div>
                </Row>
            </Card>

            {/* Form-14 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Patient Health Questionnaire (PHQ -9)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "40%", textAlign: "left", paddingLeft: "0.5rem" }}>Over the last two weeks, how often have you  been bothered by the following problems?</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Not at all</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Several days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>More than half the days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Nearly every day</td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-500" style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    1. Little interest or pleasure in doing things
                                                </div>
                                            </td>
                                            <td style={cellStyle2(1, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(1, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(1, 3)}>
                                                <div className="col-auto ">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(1, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    2. Feeling down, depressed, or hopeless
                                                </div>
                                            </td>
                                            <td style={cellStyle2(2, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(2, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(2, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(2, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    3. Trouble falling or staying asleep, or sleeping too much
                                                </div>
                                            </td>
                                            <td style={cellStyle2(3, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(3, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(3, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(3, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    4. Feeling tired or having little energy
                                                </div>
                                            </td>
                                            <td style={cellStyle2(4, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(4, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(4, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(4, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    5. Poor appetite or overeating
                                                </div>
                                            </td>
                                            <td style={cellStyle2(5, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(5, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(5, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(5, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    6. Feeling bad about yourself   or that you are a failure or  have let yourself or your family down
                                                </div>
                                            </td>
                                            <td style={cellStyle2(6, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(6, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(6, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(6, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    7. Trouble concentrating on things, such as reading the newspaper or watching television
                                                </div>
                                            </td>
                                            <td style={cellStyle2(7, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(7, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(7, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(7, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    8. Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or  restless that you have been moving around a lot more  than usual
                                                </div>
                                            </td>
                                            <td style={cellStyle2(8, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(8, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(8, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(8, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    9. Thoughts that you would be better off dead, or of hurting yourself
                                                </div>
                                            </td>
                                            <td style={cellStyle2(9, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td style={cellStyle2(9, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td style={cellStyle2(9, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td style={cellStyle2(9, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row style={{ justifyContent: "end", margin: "0.5rem" }}>
                                <Col lg={8} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Column totals
                                        </div>

                                        {[1, 2, 3, 4].map((index) => (
                                            <React.Fragment key={index}>
                                                <div className="col" style={{ paddingInline: "inherit" }}>
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    id={`value${index}`}
                                                                    value={columnTotals[index - 1]}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                                {index !== 4 && (
                                                    <div className="col-auto fw-500" style={{ paddingInline: "inherit" }}>
                                                        +
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        <div className="col-auto fw-500">
                                            =
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", margin: "0.5rem" }}>
                                <Col md={auto}>
                                    <div className="col-auto fw-500" style={{ fontSize: "smaller" }}><i>
                                        (Healthcare professional : For interpretation of TOTAL,  please refer to accompanying scoring card).
                                    </i></div>
                                </Col>
                                <Col md={auto} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Total :
                                        </div>
                                        <div className="col" style={{ paddingInline: "inherit" }}>
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="total"
                                                            disabled
                                                            value={columnTotals.reduce((acc, curr) => acc + (curr || 0), 0)}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Card className="match-card-body-second p-3">
                                <Row>
                                    <h6 className="fw-500 mb-2">If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?</h6>
                                </Row>
                                <Row>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.notDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Not difficult at all</label>
                                    </Col>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.someWhatDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Somewhat difficult</label>
                                    </Col>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.veryDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Very difficult</label>
                                    </Col>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.extremlyDifficult || false}
                                            readOnly
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Extremely difficult</label>
                                    </Col>
                                </Row>
                            </Card>

                            <Row style={{ justifyContent: "center", marginTop: "8rem" }}>
                                <div className="col-auto fw-500" style={{ fontSize: "smaller", textAlign: "center" }}><i>
                                    Copyright © 1999 Pfizer Inc. All rights reserved. Reproduced with permission. PRIME-MD© is a trademark of Pfizer Inc.  A2663B 10-04-2005
                                </i></div>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-15 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>PHQ-9 Patient Depression Questionnaire</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">For initial diagnosis :</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ol>
                                                <li className="mb-2">
                                                    Patient completes PHQ-9 Quick Depression Assessment.
                                                </li>
                                                <li className="mb-2">
                                                    If there are at least 4<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s in the shaded section (including Questions #1 and #2), consider a depressive  disorder. Add score to determine severity.
                                                </li>
                                            </ol>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3"> Consider Major Depressive Disorder</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ul>
                                                <li>if there are at least 5<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s in the shaded section (one of which corresponds to Question #1 or #2)</li>
                                            </ul>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3"> Consider Other Depressive Disorder</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ul>
                                                <li>if there are 2-4<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s in the shaded section (one of which corresponds to Question #1 or #2)</li>
                                            </ul>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Card className="match-card-body-second p-3">
                                <h6 className="fw-500 mb-2" style={{ textAlign: "justify" }}><b>Note :</b>  Since the questionnaire relies on patient self-report, all responses should be verified by the clinician,  and a definitive diagnosis
                                    is made on clinical grounds taking into account how well the patient understood  the questionnaire, as well as other relevant information
                                    from the patient.   Diagnoses of Major Depressive Disorder or Other Depressive Disorder also require impairment of social,  occupational,
                                    or other important areas of functioning (Question #10) and ruling out normal bereavement, a  history of a Manic Episode (Bipolar Disorder),  and a physical disorder, medication, or other drug as the  biological cause of the depressive symptoms.</h6>
                            </Card>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">To monitor severity over time for newly diagnosed patients or patients in current treatment for  depression:</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ol>
                                                <li className="mb-2">
                                                    Patients may complete questionnaires at baseline and at regular intervals (eg, every 2 weeks) at home and bring them in at their next appointment for scoring or they may complete the questionnaire during each scheduled appointment.
                                                </li>
                                                <li className="mb-2">
                                                    Add up<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s by column. For every<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>: Several days = 1 More than half the days = 2 Nearly every day = 3
                                                </li>
                                                <li className="mb-2">
                                                    Add together column scores to get a TOTAL score.
                                                </li>
                                                <li className="mb-2">
                                                    Refer to the accompanying <b>PHQ-9 Scoring Box</b> to interpret the TOTAL score.
                                                </li>
                                                <li className="mb-2">
                                                    Results may be included in patient files to assist you in setting up a treatment goal, determining degree of  response, as well as guiding treatment intervention.
                                                </li>
                                            </ol>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">Scoring:  add up all checked boxes on PHQ-9</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ul style={{ listStyleType: "none" }}>
                                                <li className="mb-2">
                                                    <b>For every</b><span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>Not at all = 0; Several days = 1
                                                </li>
                                                <li className="mb-2">
                                                    More than half the days = 2; Nearly every day = 3
                                                </li>
                                            </ul>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">Interpretation of Total Score</h6>
                                    </div>
                                    <div>
                                        <Table className="clinical-table-bordered table-sm" style={{ verticalAlign: "middle" }}>
                                            <tbody>
                                                <tr style={{ textAlign: "center" }}>
                                                    <td className="fw-600" style={{ width: "30%", paddingLeft: "0.2rem" }}>Total Score</td>
                                                    <td className="fw-600" style={{ width: "70%", paddingLeft: "0.2rem" }}>Depression Severity</td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        1-4
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Minimal depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        5-9
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Mild depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        10-14
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Moderate depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        15-19
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Moderately severe depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        20-27
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Severe depression
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "center", marginTop: "4rem" }}>
                                <div className="col-auto fw-500" style={{ fontSize: "smaller", textAlign: "center" }}><i>
                                    PHQ9 Copyright © Pfizer Inc. All rights reserved. Reproduced with permission. PRIME-MD ® is a trademark of Pfizer Inc.  A2663B 10-04-2005
                                </i></div>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Card>

            {/* Form-16 */}
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1 form-page">
                <div className="mt-4"></div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>RECOVERY ASSESSMENT SCALE (24-ITEM)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div>
                                <h6 className="fw-500 mb-4" style={{ textAlign: "justify" }}><b>INSTRUCTIONS :</b>  Below is a list of statements that describe how people sometimes feel about  themselves and their lives. Please read each one carefully and circle the number to the right that  best describes the extent to which you agree or disagree with the statement. Circle only one  number for each statement and do not skip any items.</h6>
                            </div>
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-600" style={{ width: "70%", textAlign: "left", paddingLeft: "0.5rem" }}>Recovery Assessment Scale Items</td>
                                            <td className="fw-600" style={{ width: "30%" }}>Likert Scale</td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-500" style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    1. I have a desire to succeed.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        value={formData.recoveryAssesmentScale?.desireToSucceed || ''}
                                                        disabled
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    2. I have my own plan for how to stay or become  well.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.ownPlanForWellness}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, ownPlanForWellness: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    3. I have goals in life that I want to reach.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.lifeGoals}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, lifeGoals: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    4. I believe I can meet my current personal goals.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.beliefInMeetingGoals}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, beliefInMeetingGoals: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    5. I have a purpose in life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.senseOfPurpose}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, senseOfPurpose: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    6. Even when I don’t care about myself, other  people do.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.othersCaringmySelf}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, othersCaringmySelf: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    7. Fear doesn’t stop me from living the way I want  to.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.fearlessLiving}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, fearlessLiving: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    8. I can handle what happens in my life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.handleLifeSituations}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, handleLifeSituations: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    9. I like myself.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.selfLove}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, selfLove: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    10. If people really knew me, they would like me.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.peopleLikeMe}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, peopleLikeMe: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    11. I have an idea of who I want to become
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.ideaWhoWantToBe}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, ideaWhoWantToBe: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    12. Something good will eventually happen.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.somethingGoodHappen}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, somethingGoodHappen: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    13. I’m hopeful about my future
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.hopefulness}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, hopefulness: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    14. I continue to have new interests.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.continueInterests}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, continueInterests: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    15. Coping with my mental illness is no longer the  main focus of my life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.mentalIllnessCoping}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, mentalIllnessCoping: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    16. My symptoms interfere less and less with my life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.symptomInterference}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, symptomInterference: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    17. My symptoms seem to be a problem for shorter  periods of time each time they occur.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.symptomDuration}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, symptomDuration: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    18. I know when to ask for help.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.helpSeekingAwareness}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, helpSeekingAwareness: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    19. I am willing to ask for help.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.willingnessToAskForHelp}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, willingnessToAskForHelp: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    20. I ask for help when I need it.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.askingHelpWhenNeed}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, askingHelpWhenNeed: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    21. I can handle stress.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.stressHandling}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, stressHandling: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    22. I have people I can count on.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.peaopleCanCounton}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, peaopleCanCounton: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    23. Even when I don’t believe in myself, other people  do.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.otherPeopleBelive}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, otherPeopleBelive: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    24. It is important to have a variety of friends.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.importanceOfFriendship}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, importanceOfFriendship: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div >
                </Row >
            </Card >
        </>
    );

};

export default AssessmentForm;