import { Upload } from "./Upload";
import { Ethinicity } from "./Ethnicity";
import { ExperienceTag } from "./ExperienceTag";
import { Payment } from "./Payment";
import { Therapist } from "./Therapist";
import { User } from "./User";

export enum PremiumStatus {
  ACTIVE = "active",
  REVOKED = "revoked",
  NOT_SET = "not_set",
}

export enum testSubscriptionStatus {
  ACTIVE = "active",
  REVOKED = "revoked",
}

export interface Client extends User {
  client?: any;
  clientId?: any;
  firstname: string;
  lastname: string;
  gender?: string;
  dateOfBirth: Date;
  username: string;
  incognito: boolean;
  dislikedTherapists: Therapist[];
  payment?: Payment[];
  ethnicityId: Ethinicity;
  premiumStatus: string;
  premiumMembershipDate?: Date;
  isPremiumUserNotificationRead?: boolean;
  coverPhotoId?: Upload;
  description: string;
  clientActiveStatus?: boolean;
  copaymentAmount?: number;
  unreadTwilioMessageCount?: number;
  primaryPhone?: string;
}

export interface ClientDob {
  dateOfBirth: Date;
  userId: string;
}

export interface DiscoverClient {
  name?: string;
  gender?: string;
  ethnicity?: string;
}

export interface ClientPreferences {
  gender?: string;
  ethnicityId?: string;
  professionId?: string;
  experiencedIn?: ExperienceTag[];
}
