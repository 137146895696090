import axios from "axios";
import { AppResponse } from "../models/Response";

import { Util } from "../Util";

export class ChatService {
  private static readonly TOKEN_KEY = "token";

  public static async getUserList(): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/getUsers");

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async getAllUserChats(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chat/allUserChats`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async searchUserChats(searchText: string): Promise<AppResponse<any>> {
    const reqData = {
      searchTag: searchText,
    };
    const ep = Util.apiAuthUrl("search/chat");

    const res = await axios.post<void, AppResponse<any>>(ep, reqData);
    return res;
  }

  public static async getAllMessagesByChatId(chatId: string, limit = 100, currentPage = 1): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`chat/getMessages/${chatId}/${limit}/${currentPage}`);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async markAsReadMessages(chatId: string): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/markAsReadChatMessages/" + chatId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async sendMessage(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/createMessage");

    const res = await axios.post<void, AppResponse<any>>(ep, data);
    return res;
  }

  public static async sendMessageWithAttachment(data: any, file: File): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/createMessageWithAttachment");
    const formData: FormData = new FormData();
    formData.append("attachFile", file);
    formData.append("messageData", JSON.stringify(data));
    const res = await axios.post<void, AppResponse<any>>(ep, formData);
    return res;
  }

  // public static async createChat(data: any): Promise<AppResponse<any>> {
  //   const ep = Util.apiAuthUrl("chat/createChat");

  //   const res = await axios.post<void, AppResponse<any>>(ep, data);
  //   return res;
  // }

  public static async deleteMessage(msgId: string): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/deleteMessage/" + msgId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }
  public static async getUnreadChatCount(userId: string): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/getUserUnreadChatCount/" + userId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async getAllUnreadMessagesFromUserIdForDashboard(userId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/getAllUnreadMessagesFromUserIdForDashboard/" + userId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async getAllUnreadChatsFromUserIdForDashboard(userId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/getAllUnreadChatsUserIdForDashboard/" + userId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async getChatByChatIdForDashboard(userId: any, chatId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/getChatByChatIdForDashboard/" + userId + "/" + chatId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async getUnreadMessageCountByChatId(userId: any, chatId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/getUnreadMessageCountByChatId/" + userId + "/" + chatId);

    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async updateMeetingTherapistTranscribeAllowed(value: boolean): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("chat/updateMeetingTherapistTranscribeAllowed");

    const obj = {
      therapistTranscribeAllowed: value,
    };

    const res = await axios.post<void, AppResponse<any>>(ep, obj);
    return res;
  }
}
