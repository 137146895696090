import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyATgp5jbOG_MzQkGFdefJCqUmEiv3qciHY",
  authDomain: "lavni-push-notifications.firebaseapp.com",
  projectId: "lavni-push-notifications",
  storageBucket: "lavni-push-notifications.firebasestorage.app",
  messagingSenderId: "808286361207",
  appId: "1:808286361207:web:6234a385f7595fd6565872",
  measurementId: "G-Y7E1BL2X2R"
};

const vapidkey = "BNudsiN6OAde8BA2zgmmbMt9AiaV5U40hdRhQiNaq1aM21E2tGee4uYQHbsJZ-jS1ADK_GtPK7UJqnUM5Coe_XE";

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

type PermissionState = 'granted' | 'denied' | 'default';

export const requestNotificationPermission = (onPermissionChange: (permission: PermissionState) => void) => {
  return Notification.requestPermission()
    .then((permission) => {
      console.log("permission new ",permission);
      if (onPermissionChange) {
        onPermissionChange(permission); // Notify the component of the change
      }
    })
}

export const requestFCMToken = async (permission: string) => {
  console.log("requestFCMToken");
  
  if (permission == 'granted'){
    return getToken(messaging, {
      vapidKey: vapidkey
    })
  } else {
    console.log("Notification not granted");   
  }
}

export const onMessageListner = () => {
  return new Promise ((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    })
  })
}
