import axios from "axios";
import { ClientReward } from "src/models/ClientReward";
import { ReferralEarning } from "src/models/ReferralEarning";
import { AppResponse } from "src/models/Response";
import { Util } from "src/Util";

export class ReferralService {
    public static async getSingleTherapistRewardDetails(): Promise<AppResponse<ReferralEarning[]>> {
        const url = Util.apiAuthUrl(`singleTherapistRewardDetails`);
        return await axios.get<void, AppResponse<ReferralEarning[]>>(url);
    }

    public static async getSingleClientRewards(): Promise<AppResponse<ClientReward[]>> {
        const url = Util.apiAuthUrl(`singleClientRewards`);
        return await axios.get<void, AppResponse<ClientReward[]>>(url);
    }
}