import React from "react";
import moment from "moment";

interface availableSlot {
  startTime: Date;
}
interface Props {
  slotData: availableSlot;
  addEvent: (selectedDetails: any) => void;
}
const PublicAppointmentAvailableSlots = ({ slotData, addEvent }: Props) => {
  return (
    <div className="flex flex-col items-center ">
      <button
        onClick={() => {
          const selectedTime = moment(slotData.startTime);

          const endT = moment(selectedTime).add(60, "minutes");
          const sDate = moment(selectedTime).toISOString();

          addEvent({ start: selectedTime, end: endT, startStr: sDate });
        }}
        type="button"
        className="w-full-mobile w-full-desktop btn btn-outline-primary mb-2"
      >
        {moment(slotData.startTime).format("hh : mm A")}
      </button>
    </div>
  );
};

export default PublicAppointmentAvailableSlots;
