import React, { useState } from "react";
import { Modal, Button, ModalBody, Collapse } from "reactstrap";
import Close from "../../assets/images/icons/close.png";
import moment from "moment";
import { TPType } from "src/models/therapyPlan/therapyPlan";

const Modal31: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void; therapyPlanInfo: any; createNewTherapyPlan: () => void; openTherapyPlan: (planId: string) => void; downloadTherapyPlan: (planId: string, tpType: TPType) => void; isMobile: boolean; isAdmin: boolean; }> = props => {
  const { therapyPlanInfo, createNewTherapyPlan, openTherapyPlan, downloadTherapyPlan, isAdmin } = props;
  
  const [expandedPlans, setExpandedPlans] = useState<{ [key: string]: boolean }>({});

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };

  const toggleVersions = (planId: string) => {
    setExpandedPlans(prev => ({
      ...prev,
      [planId]: !prev[planId]
    }));
  };
  
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered className="modal-md">
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={() => props.setShowModal(false)}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>
        <ModalBody className="p-4">
          <h2 className="text-center mb-4">Therapy Plans With Amendments</h2>

          {!isAdmin && (
            <div className="d-flex justify-content-center mb-4">
              <Button color="primary" className="py-2 shadow-sm" onClick={createNewTherapyPlan}>
                <i className="fa fa-plus mr-2"></i> Create New Therapy Plan
              </Button>
            </div>
          )}

          {(props.isMobile) ? (<span className="text-muted small"><mark>Note: To view or download the amendment, please use a laptop or desktop computer.</mark></span>) : (<></>)}

          {therapyPlanInfo.length > 0 ? (
            therapyPlanInfo.map((plan: any, planIndex: number) => (
              <div key={plan?._id} className="therapy-plan-item mb-4 bg-light rounded p-3 shadow-sm">
                <h5 className="font-weight-bold text-dark">Therapy Plan {planIndex + 1}</h5>
                <p className="text-muted small mb-3">Created date: {formatDate(plan?.createdAt)}</p>

                <div className="therapy-plan-buttons mb-3">
                  {!isAdmin && (
                    <Button color="success" onClick={() => openTherapyPlan(plan?._id)} className="mr-2 btn-sm shadow-sm">
                      <i className="fa fa-file mr-1"></i> Open / Edit
                    </Button>
                  )}
                  {(!props.isMobile) && (
                  <Button color="info" onClick={() => downloadTherapyPlan(plan?._id, TPType.ORIGINAL)} className="btn-sm shadow-sm">
                    <i className="fa fa-download mr-1"></i> Download
                  </Button>
                  )}
                </div>

                {plan?.versions && plan?.versions.length > 0 ? (
                  <div className="therapy-plan-versions mt-3">
                    <Button
                      color="link"
                      onClick={() => toggleVersions(plan?._id)}
                      className="p-0 text-decoration-none"
                    >
                      <h6 className="font-weight-bold mb-0">
                        {expandedPlans[plan?._id] ? 'Hide' : 'View'} amendments {' '}
                        <i className={`fa fa-chevron-${expandedPlans[plan?._id] ? 'up' : 'down'}`} />
                      </h6>
                    </Button>
                    <Collapse isOpen={expandedPlans[plan?._id]}>
                      <ul className="list-unstyled mt-2">
                        {plan?.versions.map((version: any, versionIndex: number) => (
                          <li key={version?._id} className="mb-2 bg-white p-2 rounded shadow-sm">
                            <span className="font-weight-medium">Amendment {versionIndex + 1}</span><br />
                            <span className="text-muted small">Created date: <b>{formatDate(version?.versionCreatedAt)}</b></span><br />
                            <span className="text-muted small">Amendment modified due to: <b>{version?.reasonForEdit}</b></span><br />
                            {(!props.isMobile) && (
                              <Button
                                color="secondary"
                                size="sm"
                                onClick={() => downloadTherapyPlan(version?._id, TPType.VERSION)}
                                className="mt-2 shadow-sm"
                              >
                                View / Download
                              </Button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </div>
                ) : (
                  <p className="text-muted font-italic">No amendments available.</p>
                )}
              </div>
            ))
          ) : (
            <p className="text-center text-muted font-italic">No therapy plans available.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  ) : null;
};

export default Modal31;
