import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Input, CardTitle } from "reactstrap";
import "../../assets/css/admin.css";
import { CommonService } from "../../services/CommonService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Profession } from "../../models/Profession";
import { useParams } from "react-router-dom";
import { ParameterTypes } from "../../utils/ParameterTypes";

const CreateProfession: React.FC = () => {
    const { professionId } = useParams<ParameterTypes>();
    const [profession, setProfession] = useState<Profession>({} as Profession);

    const back = () => {
        history.back()
    }

    useEffect(() => {
        CommonService.getProfessionById(professionId).then(res => {
            if (res.success) {
                setProfession(res.data);
            } 
        });
    }, [])
    


    const AddProfession = () => {
        if (!profession?.name) {
            toast.error("Profession is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        } else {
            const updatedProfession: any = {
                profession: profession?.name,
                disabled: false,
            };
            CommonService.createProfession(updatedProfession).then((res) => {
                if (res.success) {
                    setProfession(res.data);
                    toast.error(res.success, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                    window.history.back();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        }
    }

    const UpdateProfession = () => {
        const updatedProfession: any = {
            profession: profession?.name,
            disabled: false,
        };
        CommonService.updateProfession(profession?._id, updatedProfession).then(res => {
            if (res.success) {
                toast.success("Profession is updated!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                back();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        })
    }


    return (
        <React.Fragment>
            <div className="page-content">
            <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
                    <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                    <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
                </div>
                <Container className="containerOnboarding mt-5">
                    {/* <Breadcrumbs title={"Create Ethnicity"} breadcrumbItem={"Create Ethnicity"} /> */}
                    <Card className="BorderRadiusLeftRight pt-4 pl-3 pb-2">
                        <div className="card-bodyDiv">
                            <Row>
                                <Col xl="6">
                                    <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Profession</h5>
                                    </div>
                                    <div className="card-heading mb-4">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Profession"
                                            name="Profession"
                                            value={profession?.name || ""}
                                            onChange={(e) => setProfession({ ...profession, name: e.target.value })}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}></Col>
                                <Col xl={6}>
                                    {
                                        !professionId ? (
                                            <button
                                            type="button"
                                                className="btn btn-primary relative mt-3 mb-2" style={{
                                                    bottom: "0",
                                                    float: "right",
                                                }}
                                                onClick={() => {
                                                    AddProfession();
                                                }}>
                                                Save
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-primary relative mt-3 mb-2" style={{
                                                    bottom: "0",
                                                    float: "right",
                                                }}
                                                onClick={() => {
                                                    UpdateProfession();
                                                }}>
                                                Update
                                            </button>
                                        )
                                    }



                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateProfession;
