import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Row, Table, Input, UncontrolledTooltip } from "reactstrap";
import { CarolinaCompleteHealthAuthForm } from "src/models/AuthorizationForm/carolina-complete-health-auth-form-model";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { CarolinaCompleteHealthServiceInformationArray } from "src/models/AuthorizationForm/subModels/CarolinaCompleteHealthAuthFormSubModels/carolina-complete-health-service-information-model";
import { AuthFormType } from "src/models/AuthorizationFormData";
import SignaturePad from "react-signature-canvas";
import PhoneInput from "react-phone-number-input";
import moment from "moment";


const CarolinaCompleteHealthForm = (props: any) => {
    const { clientId, insuranceCompanyId, formId, getUpdateFormId, carolinaCompleteHealthData } = props;
    const [isUpdate, setIsUpdate] = useState(props.isUpdate);
    const [formData, setFormData] = useState<CarolinaCompleteHealthAuthForm>();
    const [isSaving, setIsSaving] = useState(false);
    const [clinicianSign, setClinicianSign] = useState<boolean>(false);

    useEffect(() => {
        setIsUpdate(props.isUpdate);
    }, [props.isUpdate]);

    useEffect(() => {
        const updateCarolinaCompleteHealthData: CarolinaCompleteHealthAuthForm = {
          generalInformation: carolinaCompleteHealthData?.generalInformation,
          serviceInformation: carolinaCompleteHealthData?.serviceInformation,
          providerInformation: carolinaCompleteHealthData?.providerInformation,
          prescibingProviderInformation: carolinaCompleteHealthData?.prescibingProviderInformation,
          denialReason: carolinaCompleteHealthData?.denialReason,
          date: carolinaCompleteHealthData?.date,
          signature: carolinaCompleteHealthData?.signature,
        };
        setFormData(updateCarolinaCompleteHealthData);
    }, [carolinaCompleteHealthData]);

    function autoResizeTextarea(e: any) {
        const textarea = e.target;
        textarea.style.height = 'auto';

        const newHeight = Math.min(textarea.scrollHeight, 3 * 16);
        textarea.style.height = `${newHeight}px`;

        textarea.style.overflowY = textarea.scrollHeight > newHeight ? 'scroll' : 'hidden';
    }

    const saveCarolinaCompleteHealthAuthForm = async () => {
        setIsSaving(true);
        const carolinaCompleteHealthAuthFormDetails: CarolinaCompleteHealthAuthForm = {
            _id: formId,
            clientId: clientId,
            insuranceCompanyId: insuranceCompanyId,
            authFormType: AuthFormType.CarolinaCompleteHealthAuthForm,
            generalInformation: formData?.generalInformation,
            serviceInformation: formData?.serviceInformation,
            providerInformation: formData?.providerInformation,
            prescibingProviderInformation: formData?.prescibingProviderInformation,
            denialReason: formData?.denialReason,
            date: formData?.date,
            signature: formData?.signature
        }
        try {
            let res;
            if (isUpdate === false) {
                res = await AuthorizationFormService.createCarolinaCompleteHealthAuthForm(carolinaCompleteHealthAuthFormDetails);
                if (res && res.success && res.data) {
                    getUpdateFormId(res.data._id);
                    setIsUpdate(true);
                    toast.success("Carolina Complete Health form data has been successfully saved!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    props.closePopup();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            } else {
                res = await AuthorizationFormService.updateAuthorizationForm(AuthFormType.CarolinaCompleteHealthAuthForm, carolinaCompleteHealthAuthFormDetails);
                if (res && res.success && res.data) {
                    getUpdateFormId(res.data._id);
                    toast.success("Carolina Complete Health form has been successfully updated!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    props.closePopup();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error(`Error occurred! Error details: ${error}.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setIsSaving(false); 
        }
    }

    useEffect(() => {
        const therapySign = formData?.signature;
        if (therapySign === undefined || !therapySign || therapySign === "") {
          setClinicianSign(false);
        } else {
          setClinicianSign(true);
        }
    }, [carolinaCompleteHealthData, formData]);

    const signPad = useRef<any>({
        minDistance: 0,
        throttle: 9,
        velocityFilterWeight: 0.7,
    });
    
    function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
        let timeout: ReturnType<typeof setTimeout>;
        return function (...args: Parameters<T>) {
          clearTimeout(timeout);
          timeout = setTimeout(() => func(...args), wait);
        };
    }
    
    const resizeCanvas = () => {
        if (signPad?.current) {
          const canvas = signPad.current.getCanvas();
          if (canvas) {
            const container = canvas.parentNode as HTMLElement;
            if (container) {
              const ratio = Math.max(window.devicePixelRatio || 1, 1);
              canvas.width = container.offsetWidth * ratio;
              canvas.height = container.offsetHeight * ratio;
              const context = canvas.getContext("2d");
              if (context) {
                context.scale(ratio, ratio);
              }
              signPad.current.clear();
            }
          }
        }
    };
    
    useEffect(() => {
        try {
          if (signPad?.current) {
              setTimeout(() => {
                resizeCanvas();
            }, 0);
          }
    
          const debouncedResize = debounce(resizeCanvas, 200);
          window.addEventListener("resize", debouncedResize);
    
          // Using ResizeObserver to handle changes in container size
          const observer = new ResizeObserver(() => {
            resizeCanvas();
          });
    
          if(signPad?.current) {
            const container = signPad.current.getCanvas().parentNode as HTMLElement;
            if(container) {
              observer.observe(container);
            }
          }
    
          return () => {
           window.removeEventListener("resize", debouncedResize);
           observer.disconnect();
          };
        } catch (error) {
    
        }
    
    }, []);

    function clearsign(): void {
        if(signPad?.current) {
          console.log("clear!");
          signPad.current.clear();
          const signatureData = null;
        }
    }
    
    function saveSign() {
        if(signPad?.current) {
           const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
           setFormData({ ...formData, signature: signatureData });
        }
    }
    
    function editSign() {
        setClinicianSign(false);
    }

    // const formatDate = (date: any) => {
    //     if (!date) return '';
    //     const d = new Date(date);
    //     const year = d.getFullYear();
    //     const month = String(d.getMonth() + 1).padStart(2, '0');
    //     const day = String(d.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    const formatDate = (date: any) => {
        if (!date) return '';
        return moment.utc(date).format('YYYY-MM-DD');
    };

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
                <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
                    <Row className="align-items-center pt-2">
                        <Col xs="12" sm="5" className="text-center text-sm-start">
                            <div>
                                <img
                                    src="/static/assets/img/authorization/carolina_complete_health.png"
                                    alt="Carolina Complete Health Insurance Company Logo"
                                    className="img-fluid"
                                    style={{ maxWidth: '250px' }}
                                />
                            </div>
                        </Col>
                        <Col xs="12" sm="7" className="text-center text-sm-end">
                            <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6"}}>
                                <span style={{ fontSize: "20px", color: "#333", fontWeight:"500" }}>Prior Authorization Request Form</span><br />
                            </p>
                        </Col>
                    </Row>
                    <hr style={{ border: "1px solid #B2B2B2", margin: "0" }} />
                    <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>

                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                <p style={{ margin: "0" }}>
                                Authorization approves the medical necessity of the requested service only. It does not
                                    guarantee payment, nor does it guarantee that the amount billed will be the amount
                                    reimbursed. The beneficiary must be Medicaid or NC Health Choice eligible and a Carolina
                                    Complete Health member on the date of service.
                                </p>
                            </div>

                        </div>
                    </Row>
                </Card>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>General Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3" >
                                            <label  style={{ fontSize: "12px" }}>Name (last, first, M.I.) :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.memberName || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            memberName: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="nameInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="nameInfo">
                                                        Enter the beneficiary’s name as it appears on the NC Medicaid Identification Card.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Date of birth :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                    value={formatDate(formData?.generalInformation?.dateOfBirth)}
                                                    onChange={(e) => {
                                                        const date = new Date(e.target.value); 
                                                        setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            dateOfBirth: date 
                                                        }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="dobInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="dobInfo">
                                                        Enter the beneficiary’s date of birth.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>NC Medicaid ID Number :</label>
                                        </Col>
                                        <Col xs="12" sm="3">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                 value={formData?.generalInformation?.medicaidID || ''}
                                                 onChange={(e) => setFormData({
                                                    ...formData,
                                                    generalInformation: {
                                                        ...formData?.generalInformation,
                                                        medicaidID: e.target.value
                                                    }
                                                })}/><span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="medicaidInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="medicaidInfo">
                                                        Enter the beneficiary’s NC Medicaid Identification number as shown on the NC Medicaid Identification card
                                                        or county letter of eligibility.
                                                    </UncontrolledTooltip>
                                                </span></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Address (street, city, state, ZIP code) :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.memberAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            memberAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="addressInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="addressInfo">
                                                        Enter the beneficiary’s address, city, state, and zip.
                                                    </UncontrolledTooltip>
                                                </span></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <label style={{ fontSize: "12px" }}>Diagnosis code :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                value={formData?.generalInformation?.diagnosisCode || ''}
                                                 onChange={(e) => setFormData({
                                                    ...formData,
                                                    generalInformation: {
                                                        ...formData?.generalInformation,
                                                       diagnosisCode: e.target.value
                                                    }
                                                })} />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="NCIDInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="NCIDInfo">
                                                        Enter the diagnosis code(s).
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Diagnosis description :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.diagnosisDescription || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            diagnosisDescription: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="DDInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="DDInfo">
                                                        Enter the diagnosis description. if there is more than one diagnosis, enter all descriptions appropriate to the
                                                        services being requested.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="7">
                                            <label style={{ fontSize: "12px" }}>Name and address of facility where services are to be rendered, if other than home or office :</label>
                                        </Col>
                                        <Col xs="12" sm="5">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.facilityName || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            facilityName: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="DDInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="DDInfo">
                                                        Name and address of the facility where services are to
                                                        be rendered, if service is to be provided at a location
                                                        other than the home or office.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Service Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <Table responsive className="clinical-table-bordered table-md">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                                                <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                                                    Ref. #
                                                    <i className='bx bx-info-circle ms-2' id="refInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="refInfo">
                                                        Enter the unique designator
                                                        (1 – 10) identifying each separate line on the request.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                                                    Procedure code
                                                    <i className='bx bx-info-circle ms-2' id="procedureCodeInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="procedureCodeInfo">
                                                        Enter the procedure codes for the
                                                        services being requested.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                                                    From
                                                    <i className='bx bx-info-circle ms-2' id="fromInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="fromInfo">
                                                        Enter the date that services will begin
                                                        if authorization is approved (MM/DD/YY format).
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                                                    Through
                                                    <i className='bx bx-info-circle ms-2' id="throughInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="throughInfo">
                                                        Enter the date services will terminate
                                                        if authorization is approved (MM/DD/YY format).
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2} >
                                                <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                                                    Description of service/item
                                                    <i className='bx bx-info-circle ms-2' id="descriptionInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="descriptionInfo">
                                                        Enter a specific description
                                                        of the service or item being requested.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                                                    Qty. or units
                                                    <i className='bx bx-info-circle ms-2' id="qtyInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="qtyInfo">
                                                        Enter the quantity or units of the
                                                        service or item being requested.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th colSpan={3} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.3",fontSize: "12px" }}>
                                                For Plan Use Only
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.7", fontSize: "11px" }}>
                                                APPR.
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.7", fontSize: "11px" }}>
                                                Denied
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "1", fontSize: "11px" }}>
                                                Amount Allowed if
                                                Priced by Report
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(10)].map((_, index) => (
                                            <tr key={index}>
                                                <td style={{ border: "1px solid #B2B2B2", position: 'relative' }}>
                                                    <div style={{ position: 'absolute', top: '0', left: '0', padding: '4px', textAlign: 'center' }}>
                                                        ({index + 1})
                                                    </div>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1", paddingLeft: '30px' }} 
                                                    value={formData?.serviceInformation?.serviceInformationArray?.[index]?.referenceNumber || ''}
                                                    onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                          ? [...formData.serviceInformation.serviceInformationArray]
                                                          : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                          ...updatedServiceInfoArray[index],
                                                          referenceNumber: e.target.value,
                                                        };
                                                        setFormData({
                                                          ...formData,
                                                          serviceInformation: {
                                                            ...formData?.serviceInformation,
                                                            serviceInformationArray: updatedServiceInfoArray,
                                                          },
                                                        });
                                                      }}/>
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }} 
                                                        value={formData?.serviceInformation?.serviceInformationArray?.[index]?.procedureCode || ''}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            procedureCode: e.target.value,
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                        value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.startDate)}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            startDate: new Date(e.target.value),
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                        value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.endDate)}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            endDate: new Date(e.target.value),
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <textarea
                                                        style={{
                                                            marginTop: "7px",
                                                            fontSize: "0.70rem",
                                                            border: "none",
                                                            lineHeight: "1.2",
                                                            overflowY: "auto",
                                                            resize: "none",
                                                            width: "100%",
                                                        }}
                                                        rows={1}
                                                        value={formData?.serviceInformation?.serviceInformationArray?.[index]?.serviceDescription || ''}
                                                        onInput={(e) => {
                                                            const target = e.target as HTMLTextAreaElement;
                                                            const lineHeight = parseFloat(getComputedStyle(target).lineHeight);
                                                            const maxRows = 3;
                                                            target.style.height = 'auto';
                                                            const newHeight = Math.min(target.scrollHeight, lineHeight * maxRows);
                                                            target.style.height = `${newHeight}px`;
                                                        }}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                              ? [...formData.serviceInformation.serviceInformationArray]
                                                              : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                              ...updatedServiceInfoArray[index],
                                                              serviceDescription: e.target.value,
                                                            };
                                                            setFormData({
                                                              ...formData,
                                                              serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                              },
                                                            });
                                                          }}
                                                    />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }} 
                                                        value={formData?.serviceInformation?.serviceInformationArray?.[index]?.itemQuantity || ''}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            itemQuantity: e.target.value,
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }} 
                                                        value={formData?.serviceInformation?.serviceInformationArray?.[index]?.approved || ''}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            approved: e.target.value,
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }} 
                                                        value={formData?.serviceInformation?.serviceInformationArray?.[index]?.denied || ''}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            denied: e.target.value,
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }} 
                                                        value={formData?.serviceInformation?.serviceInformationArray?.[index]?.allowedAmount || ''}
                                                        onChange={(e) => {
                                                            const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as CarolinaCompleteHealthServiceInformationArray);
                                                            updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            allowedAmount: e.target.value,
                                                            };
                                                            setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                            });
                                                        }}
                                                      />
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Row className="align-items-center mb-2">
                                    <Col xs="12" sm="7" className="mt-2 mt-sm-2">
                                        <label style={{ fontSize: "12px" }}>Detailed explanation of medical necessity for services, equipment, procedures or prostheses (attach additional pages if necessary) :</label>
                                    </Col>
                                    <Col xs="12" sm="5">
                                        <div className="d-flex align-items-center">
                                            <textarea
                                                id="customTextarea"
                                                className="form-control"
                                                rows={1}
                                                style={{
                                                    overflow: 'hidden',
                                                    resize: 'none',
                                                    maxHeight: '7.5em',
                                                    lineHeight: '1',
                                                    fontSize: "0.70rem",
                                                    width: '100%'
                                                }}
                                                value={formData?.serviceInformation?.necessityExplanation || ''}
                                                onInput={(e) => autoResizeTextarea(e)}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    serviceInformation: {
                                                        ...formData?.serviceInformation,
                                                        necessityExplanation: e.target.value
                                                    }
                                                })}
                                            ></textarea>
                                            <span className="ms-2 d-flex align-items-center">
                                                <i className='bx bx-info-circle' id="medicalInfo" style={{ cursor: 'pointer' }}></i>
                                                <UncontrolledTooltip placement="bottom" target="medicalInfo">
                                                    Detailed explanation of medical necessity of the
                                                    services, equipment, procedures, or prostheses.
                                                    Attach additional pages as necessary
                                                </UncontrolledTooltip>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Provider</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Provider name :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                    value={formData?.providerInformation?.providerName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            providerName: e.target.value
                                                        }
                                                    })}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="providerInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="providerInfo">
                                                        Enter the requested provider’s information.
                                                        If a clinic or group practice, also complete section IV.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Address  :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.providerInformation?.providerAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            providerAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="mailInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="mailInfo">
                                                        Enter the complete mailing address in this field.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>NPI and TAX ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.npi || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            npi: e.target.value
                                                        }
                                                    })}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="NPIInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="NPIInfo">
                                                        Enter the Provider’s and taxonomy code (if applicable) Enter the National Provider Identifier.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Fax number :</label>
                                        </Col>
                                        <Col xs="12" sm="3">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.providerInformation?.faxNumber ?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                faxNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="faxInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="faxInfo">
                                                        Enter the requested provider’s fax number,
                                                        including the area code.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">

                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prescribing or performing provider</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Name :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescibingProviderInformation?.providerName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescibingProviderInformation: {
                                                            ...formData?.prescibingProviderInformation,
                                                            providerName: e.target.value
                                                        }
                                                    })}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="pnameInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="pnameInfo">
                                                        Enter the name of the prescribing
                                                        or performing provider.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="1">
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.prescibingProviderInformation?.phone ?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            prescibingProviderInformation: {
                                                                ...formData?.prescibingProviderInformation,
                                                                phone: value
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="pphoneInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="pphoneInfo">
                                                        Enter the prescribing or performing
                                                        provider’s phone number, including area code.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Address :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.prescibingProviderInformation?.providerAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescibingProviderInformation: {
                                                            ...formData?.prescibingProviderInformation,
                                                            providerAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="addressPInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="addressPInfo">
                                                        Enter the address, city, state, and ZIP code.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>NPI and TAX ID :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescibingProviderInformation?.npi || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescibingProviderInformation: {
                                                            ...formData?.prescibingProviderInformation,
                                                        npi: e.target.value
                                                        }
                                                    })}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="NPInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="NPInfo">
                                                        Enter the Provider’s and taxonomy code (if applicable).
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card-bodyDiv" style={{ width: '100%' }}>
                                            <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
                                                <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                                    <p style={{ margin: "0" }}>
                                                        By submitting this form, the provider identified in this Section IV certifies
                                                        that the information given in Sections I through III of this form are true,
                                                        accurate and complete.
                                                    </p>
                                                </div>
                                            </Card>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>For Plan Use Only</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="5">
                                            <label style={{ fontSize: "12px" }}>Denial Reason(s): Refer to table above by reference numbers (REF NO.) :</label>
                                        </Col>
                                        <Col xs="12" sm="7">
                                            <textarea
                                                id="customTextarea"
                                                className="form-control"
                                                rows={1}
                                                style={{
                                                    overflow: 'hidden',
                                                    resize: 'none',
                                                    maxHeight: '7.5em',
                                                    lineHeight: '1',
                                                    fontSize: "0.70rem",
                                                    width: '100%'
                                                }}
                                                value={formData?.denialReason || ''}
                                                onInput={(e) => autoResizeTextarea(e)}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    denialReason: e.target.value 
                                                })}
                                            ></textarea>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2" style={{ backgroundColor: "#F1F1F1", border: "none", borderRadius: "15px", padding: "10px" }}>
                                        <Col xs="12" sm="2" className="d-flex align-items-center">
                                            <label style={{ fontSize: "12px" }}>IF APRROVED :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="d-flex align-items-center">
                                            <label style={{ fontSize: "12px" }}>Services Authorized to Begin</label>
                                        </Col>
                                        <Col xs="12" sm="7">
                                            <div className="row justify-content-between" style={{ textAlign: "center" }}>
                                                <div className="col-lg-5 col-md-12 m-2">
                                                    <div className="d-flex flex-column justify-content-between">
                                                    <div className="sign">
                                                        <div
                                                        className='sigCanvasNewDiv'
                                                        >
                                                        {clinicianSign ? (
                                                            <img className="sigCanvasNew" src={formData?.signature} />
                                                        ) : (
                                                            <SignaturePad
                                                            backgroundColor="rgb(245,242,241)"
                                                            ref={signPad}
                                                            penColor="black"
                                                            canvasProps={{ className: "sigCanvasNew" }}
                                                            ></SignaturePad>
                                                        )}
                                                        </div>
                                                    </div>
                                                    {!clinicianSign ? (
                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div>
                                                                <a onClick={() => { clearsign(); }} className="btn btn-primary btn-sm">Clear</a>
                                                            </div>
                                                            <div>
                                                                <a onClick={() => { saveSign(); }} className="btn btn-primary btn-sm">Save</a>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <a onClick={() => editSign()} className="btn btn-primary btn-sm mt-2">Edit</a>
                                                        </div>
                                                    )}
                                                    </div>
                                                    <h6 className="fw-500 mt-2" style={{fontSize: "12px"}}>Reviewed by Signature</h6>
                                                </div>
                                                <div className="col-lg-4 col-md-12 m-2 signatureAssesment1">
                                                    <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                        value={formatDate(formData?.date)}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            date: new Date(e.target.value) 
                                                        })}
                                                    />
                                                    <h6 className="fw-500 mt-2" style={{fontSize: "12px"}}>Date</h6>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={saveCarolinaCompleteHealthAuthForm}
                            disabled={isSaving}
                        >
                            {isSaving ? "Saving..." : "Save"}
                        </button>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default CarolinaCompleteHealthForm;