const aes256 = require("aes256");
import { environment } from "src/environment/environment";

const key = environment.chat_encryption_key;

export const DoEncrypt = (text: string) => {
  const encrypted = aes256.encrypt(key, text);
  return encrypted;
};

export const DoDecrypt = (cipher: string) => {
  try {
    if (cipher && cipher?.length > 0) {
      const decrypted = aes256.decrypt(key, cipher);
      return decrypted;
    }
    
    return cipher;
  } catch (error) {
    console.log('Error');
    console.log(error);

    return "";
  }
};
