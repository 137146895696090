import React from 'react';
import './styles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Modal } from 'reactstrap';
import { StepEnum } from './types';
import StepProgress from './StepProgress';
import PatientInfo from './PatientInfo';
import SelectAssessments from './SelectAssessments';
import SelectDocuments from './SelectDocuments';
import SelectSessionNotes from './SelectSessionNotes';
import PreviewPdf from './PreviewPdf';
import SendFax from './SendFax';
import Completed from './Completed';

interface SelectedItems {
  assessments: string[];
  documents: string[];
  sessionNotes: string[];
}

interface GenerateAuditPdfPopUpProps {
  isOpen: boolean;
  toggle: () => void;
  searchDocumentDownloadsByAdminSelected: any;
}

const GenerateAuditPdfPopUp: React.FC<GenerateAuditPdfPopUpProps> = ({
  isOpen,
  toggle,
  searchDocumentDownloadsByAdminSelected
}) => {
  const [currentStep, setCurrentStep] = React.useState(StepEnum.PATIENT_INFO);
  const [selectedItems, setSelectedItems] = React.useState<SelectedItems>({
    assessments: [],
    documents: [],
    sessionNotes: []
  });
  const [valueStep0, setValueStep0] = React.useState<string[]>([]);
  const [valueStep1, setValueStep1] = React.useState<string[]>([]);
  const [valueStep2, setValueStep2] = React.useState<string[]>([]);
  const [valueStep3, setValueStep3] = React.useState<string[]>([]);

  const handleNext = (items?: string[]) => {
    if (items) {
      console.log({currentStep})
      console.log({items});
      if (currentStep == 0) {
        setValueStep0(items);
      }
      if (currentStep == 1) {
        setValueStep1(items);
      } else if (currentStep == 2) {
        setValueStep2(items);
      } else if (currentStep == 3) {
        setValueStep3(items);
      }
    }
    setCurrentStep(prev => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleClose = () => {
    setCurrentStep(StepEnum.PATIENT_INFO);
    setSelectedItems({
      assessments: [],
      documents: [],
      sessionNotes: []
    });
    toggle();
  };

  const renderStep = () => {
    const commonProps = {
      searchDocumentDownloadsByAdminSelected,
      onNext: handleNext,
      onBack: handleBack,
      currentStep,
      selectedItems: [],
      setSelectedItems: () => {}
    };

    switch (currentStep) {
      case StepEnum.PATIENT_INFO:
        return <PatientInfo {...commonProps} />;
      case StepEnum.SELECT_ASSESSMENTS:
        return <SelectAssessments {...commonProps} />;
      case StepEnum.SELECT_DOCUMENTS:
        return <SelectDocuments {...commonProps} />;
      case StepEnum.SELECT_SESSION_NOTES:
        return <SelectSessionNotes {...commonProps} />;
      case StepEnum.PREVIEW_PDF:
        return <PreviewPdf {...commonProps} valueStep0={valueStep0} valueStep1={valueStep1} valueStep2={valueStep2} valueStep3={valueStep3}/>;
      case StepEnum.SEND_FAX:
        return <SendFax {...commonProps} />;
      case StepEnum.COMPLETED:
        return <Completed {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      toggle={handleClose} 
      size="xl" 
      centered
      style={{ maxWidth: '1200px', width: '95%' }}
      contentClassName="generate-audit-modal"
    >
      <div className="modal-header border-bottom-0 pb-0">
        <h4 className="modal-title fw-bold ms-2">Generate Audit PDF</h4>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body px-4 pb-4">
        <StepProgress currentStep={currentStep} />
        <div className="step-content p-3">
          {renderStep()}
        </div>
      </div>
    </Modal>
  );
};

export default GenerateAuditPdfPopUp;
