import axios from "axios";
import { MeetingData } from "../models/MeetingData";
import { AppResponse } from "../models/Response";
import { Transaction, TransactionMeeting } from "../models/Transaction";
import { Util } from "../Util";

export class TransactionService {
  public static async getRecentBalance(): Promise<AppResponse<Transaction>> {
    const url = Util.apiAuthUrl(`recentBalance`);
    return await axios.get<void, AppResponse<Transaction>>(url);
  }
  
  public static async getAllTransactions(limit?: number, offset?: number): Promise<AppResponse<Transaction[]>> {
    const queryParams = limit !== undefined && offset !== undefined
      ? `?limit=${limit}&offset=${offset}`
        : "";
    const url = Util.apiAuthUrl(`allTransactions${queryParams}`);
    return await axios.get<void, AppResponse<Transaction[]>>(url);
  }

  public static async getRecentMonthlyPayment(): Promise<AppResponse<Transaction[]>> {
    const url = Util.apiAuthUrl(`getRecentMonthlyAmounts`);
    return await axios.get<void, AppResponse<Transaction[]>>(url);
  }

  public static async getAllTransactionsByTherapist(therapistId: string, limit?: number, offset?: number): Promise<AppResponse<Transaction[]>> {
    const url = Util.apiAuthUrl(`allTransactions/` + therapistId + "/" + limit + "/" + offset);
    const sss = axios.get<void, AppResponse<Transaction[]>>(url);
    return sss;
  }

  public static async getAllMeetingsForTherapistForTranscribe(id: string, limit?: number, offset?: number): Promise<AppResponse<MeetingData[]>> {
    const url = Util.apiAuthUrl(`getAllMeetingsForTherapistForTranscribeByAdmin/` + id + "/" + limit + "/" + offset);
    return await axios.get<void, AppResponse<MeetingData[]>>(url);
  }

  public static async getMonthlyMeetings(): Promise<AppResponse<TransactionMeeting[]>> {
    const url = Util.apiAuthUrl(`getMonthlyMeetings`);
    return await axios.get<void, AppResponse<TransactionMeeting[]>>(url);
  }

  public static async createStripeConnectedAccount(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createStripeConnectedAccount`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async verifyStripeAccount(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`verifyStripeAccount`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async createStripeLoginLink(): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`createStripeLoginLink`);
    return await axios.post<void, AppResponse<string>>(url);
  }

  public static async withdrawBalance(): Promise<AppResponse<number>> {
    const url = Util.apiAuthUrl(`withdrawBalance`);
    return await axios.post<void, AppResponse<number>>(url);
  }
}