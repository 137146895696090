import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Modal, Button, ModalBody, Input, CardTitle } from "reactstrap";
import "../../../assets/css/admin.css";
import { AdminService } from "../../../services/AdminService";
import Spinner from "../../../common/spinner/spinner";
import Close from "../../../assets/images/icons/close.png";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";
import { ReminderSms } from "src/models/ReminderSms";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CustomeSeeMoreText from "src/components/CustomeSeeMoreText";

type ViewSingleClientReminderSmsProps = {
    callBackPage: () => void;
    selectedClientId: string;
};

const ViewSingleClientReminderSms: React.FC<ViewSingleClientReminderSmsProps> = ({ callBackPage, selectedClientId }) => {
    const [reminderSms, SetReminderSms] = useState<ReminderSms[]>([])
    const history = useHistory();
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [offSet, setOffSet] = useState(0);
    const [hasMore, setMore] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const [message, setMessage] = useState('')
    const [reminderSmsId, setReminderSmsId] = useState('')
    const [error, setError] = useState<boolean>(false)
    const splittedURL = window.location.pathname.split("/");
    // const clientId = splittedURL[splittedURL.length - 1];
    const clientId = selectedClientId

    const LIMIT = 10;


    useEffect(() => {
        if (clientId) {
            getAllReminderSms();
        }

    }, [clientId]);

    const getAllReminderSms = () => {
        setIsloading(true);

        AdminService.getReminderSmsByClient(clientId, LIMIT, offSet).then(res => {
            if (res.success) {
                SetReminderSms(res.data);
                setMore(res.data.length > 0 && res.data.length == LIMIT);
                setIsloading(false);
            } else {
                SetReminderSms([]);
                setMore(false);
                setIsloading(false);
            }
        });
    };


    const seeMoreReminderSms = () => {
        setIsloading(true);

        const updatedOffset = offSet + 1;

        setOffSet(updatedOffset);

        AdminService.getReminderSmsByClient(clientId, LIMIT, updatedOffset).then(res => {
            if (res.success) {
                SetReminderSms(reminderSms => {
                    return [...new Set([...reminderSms, ...res.data])];
                });
                setMore(res.data.length > 0 && res.data.length == LIMIT);
                setIsloading(false);
            } else {
                setMore(false);
                setIsloading(false);
            }
        });
    };

    const toggleModal = () => {
        setIsOpenModal(!isOpenModal)
        setReminderSmsId('')
        setMessage('')
    }
    const sendCustomSms = (smsData: any) => {
        setReminderSmsId(smsData?._id)
        setIsOpenModal(true)
    }
    const sendSms = () => {
        setError(false)
        if (!message) {
            setError(true)
            return;
        }
        const data = {
            reminderId: reminderSmsId,
            message: message
        }
        AdminService.sendCustomSmsToClient(data).then(res => {
            if (res.success) {
                const reminderKey = reminderSms.findIndex((data: ReminderSms) => data._id == reminderSmsId);
                const previousReminder = { ...reminderSms[reminderKey] };
                const updatedReminder = {
                    ...previousReminder, replySMS: res?.data?.replySMS
                };
                
                reminderSms[reminderKey] = updatedReminder;
                SetReminderSms(reminderSms);
                toggleModal()
            } else {
                toast.error(res?.error || 'Sending Failed', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        });
    }
    const back = () => {
        // history.goBack();
        callBackPage()
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Reminder sms"} breadcrumbItem={"Reminder sms"} />
                    <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
                        <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                        <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
                    </div>
                    {isLoading && <Spinner className="bouncer" />}

                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl="12">
                                    {!isLoading && reminderSms?.length == 0 && <div className="text-muted text-center">No Reminder SMS</div>}
                                    {reminderSms && reminderSms.length > 0 && (
                                        <div className="table-responsive">
                                            <Row>
                                                <Col sm="12" className="mt10">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">SMS</th>
                                                                    {/* <th scope="col">Reminder SMS</th>
                                                                    <th scope="col">Reply SMS</th>
                                                                    <th scope="col">Reply</th> */}
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {reminderSms &&
                                                                    reminderSms.map((reminderSms, index) => (
                                                                        <tr key={index}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{reminderSms.createdAt ? moment(reminderSms.createdAt).format("MM/DD/YYYY hh:mm:ss A") : <span>-</span>}</td>
                                                                            <td>
                                                                                {reminderSms?.clientId?.firstname} {reminderSms?.clientId?.lastname}
                                                                            </td>
                                                                            <td>{reminderSms?.clientId?.email}</td>
                                                                            <td>
                                                                                <div className="sms-reminder-container">
                                                                                    <div className="firstSmsContainer mb-2 sms-reminder-bubble left-bubble">
                                                                                        <CustomeSeeMoreText content={reminderSms?.sentSMS || ''} />
                                                                                    </div>
                                                                                    {
                                                                                        reminderSms?.reply && (
                                                                                            <div className="d-flex justify-content-end">
                                                                                                <div className="secondSmsContainer mb-2 sms-reminder-bubble right-bubble">
                                                                                                    <CustomeSeeMoreText content={reminderSms?.reply ? reminderSms?.reply : '-' || ''} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                    <div className="">
                                                                                        {
                                                                                            reminderSms?.replySMS?.map((item: any, index: number) => (
                                                                                                <div key={index} className="thirdSmsContainer mb-2 sms-reminder-bubble third-bubble">
                                                                                                    <CustomeSeeMoreText content={item || ''} />
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            {/* <td>
                                                                                {reminderSms?.sentSMS}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    reminderSms?.replySMS?.map((item: any, index: number) => (
                                                                                        <span className="mb-1" key={index}>{item} <br /><br /></span>
                                                                                    ))
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {reminderSms?.reply ? reminderSms?.reply : '-'}
                                                                            </td> */}
                                                                            <td>
                                                                                <button
                                                                                    className=" btn btn-primary  mr-2 "
                                                                                    onClick={() => sendCustomSms(reminderSms)}
                                                                                    title="Send Reply SMS"
                                                                                >
                                                                                    <i className="fa fa-reply"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12 mt30 mb-2 text-center">
                                                    {hasMore && (
                                                        <button className="btn btn-warning" onClick={() => seeMoreReminderSms()}>
                                                            See More
                                                        </button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={isOpenModal} toggle={toggleModal} centered className="modal-md">
                <div style={{ textAlign: "center", paddingRight: "0px" }}>
                    <span></span>
                    <Button
                        close
                        onClick={toggleModal}
                        style={{
                            position: "absolute",
                            right: "15px",
                            background: "none",
                            border: "none",
                            padding: "0",
                            zIndex: "10",
                        }}
                        className="btn-s-20"
                    >
                        <img src={Close} alt="Close" className="mt-1" />
                    </Button>
                </div>
                <ModalBody className="ps-4 pe-4">
                    <Row>
                        <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Send Reply SMS</span>
                    </Row>

                    <>
                        <Row className="mb-4">
                            <Col xl={12}>
                                <label>Message</label>
                                <textarea
                                    rows={4}
                                    name="value"
                                    className="form-control"
                                    placeholder="Enter Message..."
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                >
                                </textarea>
                                {
                                    error && (!message || message?.length == 0) && (
                                        <span className="text-danger fs-10-n">Please enter message</span>
                                    )
                                }
                            </Col>
                        </Row>
                    </>

                    <Row className="mt-3">
                        <Col className="md-8"></Col>
                        <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                            <button className="btn btn-warning create-client-custom2 float-right mr-2"
                                onClick={() => sendSms()}
                            >Send</button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ViewSingleClientReminderSms;
