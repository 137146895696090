
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, TabContent, TabPane, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { NavLink, Link } from 'react-router-dom';
import "../../assets/css/admin.css";
import { CommonService } from "../../services/CommonService";
import { TherapistService } from "../../services/TherapistService";
import { Profession } from "../../models/Profession";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { AdminService } from "../../services/AdminService";
import { Therapist } from '../../models/Therapist';
import Spinner from "../../common/spinner/spinner";

const ViewProfessions: React.FC = () => {
    const [professions, setProfessions] = useState<Profession[]>([]);
    const [offset, setOffset] = useState(0);
    const [profession, setProfession] = useState<Profession>({});
    const limit = 10;
    const [isTherapistSidebar, setIsTherapistSidebar] = useState(false);
    const [selectedProfessionTherapists, setSelectedProfessionTherapists] = useState<Therapist[]>([]);
    const [userCount, setUserCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);

    const LIMIT = 10;
    const [seeMoreMain, setSeeMoreMain] = useState(false);
    const [isOffsetMain, setOffsetMain] = useState(1);

    useEffect(() => {
        getAllProfession();
    }, []);

    const getAllProfession = () => {
        setIsLoading(true);
        TherapistService.getProfessions(LIMIT, isOffsetMain).then(res => {
            if (res.success) {
                setSeeMoreMain(res.data.length > 0 && res.data.length == LIMIT);
                setProfessions(res.data);
                setIsLoading(false);
            } else {
                setSeeMoreMain(false);
                setProfessions([]);
                setIsLoading(false);
            }
        });
    }

    const getTherapistByProfessionId = (profession: Profession) => {
        setProfession(profession);
        setIsLoading(true);
        if (profession) {
            AdminService.getAllTherapistsByProfessionId(profession._id!, limit, offset).then(res => {
                if (res.success) {
                    setSelectedProfessionTherapists(res.data.therapistSet);
                    setIsTherapistSidebar(!isTherapistSidebar);
                    setUserCount(res.data.count);
                    setIsLoading(false);
                }
            });
        }
    }

    const deleteProfession = (id: string | any) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
        }).then(result => {
            if (result.isConfirmed) {
                CommonService.deleteProfession(id).then(res => {
                    if (res.success) {
                        getAllProfession();
                        toast.success("Profession is Deleted!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error('Somthing went wrong!', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    }

    const toggleTherapistSidebar = () => {
        setIsTherapistSidebar(!isTherapistSidebar);
    }


    const seeMore = () => {
        const updatedSkip = offset + 10;
        setOffset(updatedSkip);

        if (profession) {
            AdminService.getAllTherapistsByProfessionId(profession._id!, limit, updatedSkip).then(res => {
                if (res.success) {
                    res.data.therapistSet.map((therapist: Therapist) => {
                        setSelectedProfessionTherapists(therapists => [...therapists, therapist]);
                    });
                    setUserCount(res.data.count);
                }
            });
        }
    }

    const seeMoreFc = () => {
        setIsLoading(true);
        const updatedOffset = isOffsetMain + 1;
        setOffsetMain(updatedOffset);
        TherapistService.getProfessions(LIMIT, updatedOffset).then(res => {
            if (res.success) {
                setSeeMoreMain(res.data.length > 0 && res.data.length == LIMIT);
                setProfessions(prev => {
                    return [...new Set([...prev, ...res.data])];
                });
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setSeeMoreMain(false);
            }
        });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"View Professions"} breadcrumbItem={"View Professions"} />
                    <Row>
                        <Col xl={12}>
                            <NavLink
                                to={"/create-profession"}
                                className="btn btn-primary relative mb-3" style={{
                                    bottom: "0",
                                    float: "right",
                                }}>
                                Create Professions
                            </NavLink>

                        </Col>
                    </Row>
                    {!isLoading ? (
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="12" className="mt10">
                                        <div className="table-responsive">
                                            {professions !== undefined ? (
                                                <table className="table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Profession</th>
                                                            <th scope="col">Profession License</th>
                                                            <th scope="col">Therapists by Profession</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {professions && professions.map((profession, index) => (
                                                            <tr key={profession._id}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{profession.name}</td>
                                                                <td>
                                                                    <NavLink to={"/view-profession-license/" + profession._id} className="btn btn-success btn-sm">
                                                                        View
                                                                    </NavLink>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-success btn-sm" onClick={() => getTherapistByProfessionId(profession)}>View All</button>
                                                                </td>
                                                                <td>
                                                                    <Link className="btn btn-warning  btn-sm  mr-2" to={"update-profession/" + profession?._id}  ><i className='bx bx-edit' ></i></Link>

                                                                    <button className="btn btn-danger btn-sm mr-2" onClick={() => deleteProfession(profession._id)}><i className='bx bx-trash' ></i></button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }

                                                    </tbody>
                                                </table>
                                            )
                                                : (
                                                    <div>No Professions</div>
                                                )
                                            }
                                        </div>

                                    </Col>
                                </Row>
                                {professions.length == 0 && <div className="text-muted text-center">No Professions Tags</div>}
                                <Row>
                                    <Col xl="12 mt30 text-center">
                                        {seeMoreMain && (
                                            <button className="btn btn-warning" onClick={() => seeMoreFc()}>
                                                See More More
                                            </button>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    ) : (
                        <Spinner />
                    )}
                </Container>
            </div>

            <Offcanvas
                className="admin-canvas-scroll"
                isOpen={isTherapistSidebar}
                direction="end"
                toggle={toggleTherapistSidebar}>
                <OffcanvasHeader toggle={toggleTherapistSidebar}>
                    Therapists Of Profession: <b>{profession.name}</b>
                </OffcanvasHeader>

                <OffcanvasBody>
                    {!isLoading ? (
                        <div className="table-responsive">
                            {
                                selectedProfessionTherapists !== undefined && selectedProfessionTherapists.length > 0 ? (
                                    <>
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">email</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selectedProfessionTherapists.map((therapist: Therapist, i: number) => {
                                                        return (
                                                            <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>{therapist.firstname} {therapist.lastname}</td>
                                                                <td>
                                                                    <a href={`mailto:${therapist.email}`} >
                                                                        {therapist.email}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <NavLink to={"/profile/" + therapist._id} className="btn btn-success btn-sm">
                                                                        View Profile
                                                                    </NavLink>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        {
                                            userCount > 0 && selectedProfessionTherapists.length >= limit && (
                                                <>
                                                    <button className="btn btn-warning text-center mt-4" onClick={seeMore}>See More</button>
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        <p className="pt-5 text-center">No therapsists found with this profession.</p>
                                    </>
                                )
                            }


                        </div>
                    ) : (
                        <Spinner />
                    )}
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    );
};

export default ViewProfessions;
