import React, { useState, useEffect } from "react";
import { Col, Row, Card, CardBody, Button, ButtonGroup, FormGroup, Input, Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form } from "reactstrap";
import Spinner from "../../../common/spinner/spinner";
import { AdminService } from "../../../services/AdminService";
import { GenderTypes, UserStatus } from "../../../models/User";
import { SubscriptionStatus } from "../../../models/CardTypes";
import { Therapist, TherapistGrantedStatus } from "../../../models/Therapist";
import { toast } from "react-toastify";
import { ClientService } from "./../../../services/ClientService";
import { FriendRequest } from "src/models/FriendRequest";
import DatePicker from "react-date-picker";
import CustomTimePickerForPublicAppointment from "./../../../common/main/public-appointment/CustomTimePickerForPublicAppointment";
import CustomTimePickerForTherapistFiltering from "./../../../common/main/public-appointment/CustomTimePickerForTherapistFiltering";
import { Locations } from "../../../models/Locations";
import GrantAccessModal from "./GrantAccessModal";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const DocumentAccessManagement = () => {
  const [therapists, setTherapist] = useState<Therapist[]>([]);
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isSearchableString, setIsSearchableString] = useState("");

  const [status, setStatus] = useState("");

  const [userCount, setUserCount] = useState(10);

  const [selectTherapist, setSelectedTherapist] = useState<Therapist>();
  const [filterBtnClicked, setFilterBtnClicked] = useState(false);
  const [isSeeMoreBtnDissabled, setIsSeeMoreBtnDissabled] = useState(false);

  const [isSelectAdvanceFiltering, setIsSelectAdvanceFiltering] = useState(true);
  const [therapistGrantedStatus, setTherapistGrantedStatus] = useState(`${TherapistGrantedStatus.ALL}`);
  const [grantAccessModalOpen, setGrantAccessModalOpen] = useState(false);

  useEffect(() => {
    searchTherapists();
  }, []);

  const grantPermissionTherapist = async (isGrantAccess: boolean) => {
    try {
      const therapistId = (selectTherapist && selectTherapist._id) || "";
      const grantedAccessFileFolderPermission = isGrantAccess === true ? "TRUE" : "FALSE";

      const data = {
        therapistId: therapistId,
        grantedAccessFileFolderPermission: grantedAccessFileFolderPermission,
      };
      setIsLoading(true);
      const result = await AdminService.editTherapistFolderDocumentPermissionByAdmin(data);
      if (result.success) {
        const id = result.data._id;

        setTherapist(prevTherapists =>
          prevTherapists.map(therapist =>
            therapist._id === id ? { ...therapist, grantedAccessFileFolderPermission: result.data.grantedAccessFileFolderPermission } : therapist
          )
        );
        if (result.data.grantedAccessFileFolderPermission) {
          toast.success("Access Granting is successfully .", { position: toast.POSITION.BOTTOM_RIGHT, className: "foo-bar" });
        } else if (!result.data.grantedAccessFileFolderPermission) {
          toast.success("Access Granting is revoked successfully .", { position: toast.POSITION.BOTTOM_RIGHT, className: "foo-bar" });
        }
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Faild to grant Permission to Therapist", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  //   useEffect(() => {}, [selectTherapist]);

  const toggleGrantAccessModalOpen = () => setGrantAccessModalOpen(prev => !prev);

  const searchTherapists = () => {
    const data = {
      searchableString: isSearchableString,
      status: status,
      grantedStatus: therapistGrantedStatus,
    };

    setIsLoading(true);

    AdminService.searchTherapistsDocumentAccessByAdmin(data, limit, offset).then((res: any) => {
      if (res.success) {
        console.log(res.data.userSet);
        setTherapist(res.data.userSet);
        setUserCount(res.data.userSet.length);
      }

      setIsLoading(false);
    });
  };

  const filterTherapists = () => {
    const data = {
      searchableString: isSearchableString,

      status: status,
      grantedStatus: therapistGrantedStatus,
    };

    setIsLoading(true);

    const updatedOfsetSkip = 1;
    setOffset(updatedOfsetSkip);

    //duplicate here
    AdminService.searchTherapistsDocumentAccessByAdmin(data, limit, 1).then((res: any) => {
      if (res.success) {
        const newList = res.data.userSet;

        setTherapist([...newList]);
        setUserCount(res.data.userSet.length);
        setFilterOptions(!isFilterOptions);
        setFilterBtnClicked(true);
      }

      setIsLoading(false);
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;
    setIsSeeMoreBtnDissabled(true);

    setOffset(updatedSkip);

    const data = {
      searchableString: isSearchableString,
      status: status,
      grantedStatus: therapistGrantedStatus,
    };

    AdminService.searchTherapistsDocumentAccessByAdmin(data, 10, updatedSkip).then((res: any) => {
      setIsLoading(true);

      if (res.success) {
        setIsSeeMoreBtnDissabled(false);
        // res.data.userSet.map((therapist: any) => {
        //   setTherapist(therapists => [...therapists, therapist]);
        // });

        setTherapist(prev => {
          return [...new Set([...prev, ...res.data.userSet])]
        })

        setUserCount(res.data.userSet.length);
      }

      setIsLoading(false);
    });
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  };

  const selectedTherapist = (therapist: Therapist) => {
    setSelectedTherapist(therapist);

    toggleGrantAccessModalOpen();
  };

  const removeAllFilters = () => {
    setFilterOptions(!isFilterOptions);

    setOffset(1);
    setFilterBtnClicked(false);
    setIsSearchableString("");
    setStatus("");

    setTherapistGrantedStatus(`${TherapistGrantedStatus.ALL}`);

    const data = {
      searchableString: "",
      status: "",
      grantedStatus: `${TherapistGrantedStatus.ALL}`,
    };

    setIsLoading(true);
    AdminService.searchTherapistsDocumentAccessByAdmin(data, limit, 1).then((res: any) => {
      console.log(res);
      if (res.success) {
        console.log(res.data.userSet[0].grantedAccessFileFolderPermission);
        setTherapist([]);
        setTherapist(res.data.userSet);
        setUserCount(res.data.userSet.length);
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      <Col xs="12" className="mt10 ">
        <div className="d-flex  justify-content-between align-items-center">
          <div className="more-text-admin cursor-pointer">
            <label className="d-flex j-btwn cursor-pointer">
              <div className="card-heading mb-2 cursor-pointer">
                <h5 className="font-size-18 profileFont mb-2 cursor-pointer">Please select a therapist and proceed to the next step.</h5>
              </div>
            </label>
          </div>
        </div>
      </Col>

      <div>
        <Card>
          <CardBody>
            <Row>
              <Col className="d-flex admin-filter">
                <button className="btn btn-sm mr-2" onClick={toggleFilter}>
                  Filter <i className="bx bx-filter-alt"></i>
                </button>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mt10">
                <div className="table-responsive">
                  {!isLoading ? (
                    therapists !== undefined && therapists.length > 0 ? (
                      <>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Email</th>
                              {/* <th scope="col">Zipcode</th>
                            <th scope="col">State</th> */}
                              <th scope="col">Verified Status</th>
                              <th scope="col">Document Granted Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {therapists &&
                              therapists.map((therapist, index) => (
                                <tr
                                  key={therapist?._id}
                                  onClick={() => selectedTherapist(therapist)}
                                  style={{ cursor: "pointer" }}
                                  className={selectTherapist?._id == therapist?._id ? "table-secondary" : "transparent"}
                                >
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    {therapist?.firstname} {therapist?.lastname}
                                  </td>
                                  <td>{therapist?.gender}</td>
                                  <td>{therapist?.email}</td>
                                  {/* <td>{therapist?.zipCode}</td>

                                <td>{therapist?.state}</td> */}
                                  <td>
                                    {therapist?.verifiedStatus == "VERIFIED" ? (
                                      <span className="verified">Verified</span>
                                    ) : (
                                      <span className="pending">Pending</span>
                                    )}
                                  </td>
                                  <td>
                                    {therapist?.grantedAccessFileFolderPermission === undefined || therapist?.grantedAccessFileFolderPermission === false ? (
                                      <span className="pending">Not Granted</span>
                                    ) : (
                                      <span className="verified">Granted</span>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {
                          <div
                            style={{
                              display: "flex",
                              justifyContent: userCount >= 10 ? "space-between" : "flex-end",
                            }}
                          >
                            {userCount >= 10 && (
                              <>
                                {!isSeeMoreBtnDissabled ? (
                                  <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                    See More
                                  </button>
                                ) : (
                                  <button className="btn btn-warning text-center mt-4" disabled>
                                    Loading...
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        }

                        {userCount == 0 && <span>No more therapists available...</span>}
                      </>
                    ) : (
                      <>
                        <div>No Therapist</div>
                      </>
                    )
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      {selectTherapist && (
        <GrantAccessModal
          toggle={toggleGrantAccessModalOpen}
          modal={grantAccessModalOpen}
          therapist={selectTherapist}
          grantPermissionTherapist={grantPermissionTherapist}
        />
      )}
      <Offcanvas className="offcanvas-end2" isOpen={isFilterOptions} direction="end" toggle={toggleFilter}>
        <OffcanvasHeader toggle={toggleFilter}>Filter Therapists</OffcanvasHeader>
        <OffcanvasBody>
          <div className="row  pt20 pl10">
            <div className="filter-option">
              <div className="mb-3">Search by Name or Email</div>
              <div className="col-xl-12 mb-3">
                <input
                  className="form-control"
                  placeholder="Search Users (ex: first name, last name or email)"
                  value={isSearchableString}
                  onChange={e => setIsSearchableString(e.target.value)}
                />
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Verified Status</div>

              <div className="col-xl-12 mb-3">
                <FormGroup>
                  <Input id="exampleSelect" name="select" type="select" onChange={e => setStatus(e.target.value)} value={status}>
                    <option value={UserStatus.ALL}> All </option>
                    <option value={UserStatus.VERIFIED}> Verified </option>
                    <option value={UserStatus.PENDING}> Pending </option>
                  </Input>
                </FormGroup>
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Granted Status</div>

              <div className="col-xl-12 mb-3">
                <FormGroup>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={e => setTherapistGrantedStatus(e.target.value)}
                    value={therapistGrantedStatus}
                  >
                    <option value={TherapistGrantedStatus.ALL}>All </option>
                    <option value={TherapistGrantedStatus.GRANTED}>Granted </option>
                    <option value={TherapistGrantedStatus.NOT_GRANTED}> Not Granted </option>
                  </Input>
                </FormGroup>
              </div>
            </div>

            <div className="col-xl-12 mb-3 mt-5">
              <Col lg={12}>
                {isLoading ? (
                  <button className="btn btn-success w-100" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="btn btn-success w-100" onClick={() => filterTherapists()}>
                    Search
                  </button>
                )}
              </Col>
            </div>

            <div className="col-xl-12 mb-3">
              <Col lg={12}>
                <button className="btn btn-success w-100" onClick={() => removeAllFilters()}>
                  Remove All Filters
                </button>
              </Col>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default DocumentAccessManagement;
