import React, { useContext, useEffect, useState } from "react";
import { SaasTherapistService } from "src/services/SaasTherapistService";
import { toast } from "react-toastify";
import UserContext from "src/context/UserContext";
import Spinner from "src/common/spinner/spinner";
import { UserDetails } from "src/models/User";

interface JotformSaasProps {
  clientId: string;
}

interface JotformData {
  formName: string;
  resultLink: string;
  submissionDate: string;
  score_GAD_7: number;
  score_PHQ_9: number;
}

interface EnabledForm {
  _id: string;
  form_id: string;
  title_form: string;
  status: string;
}

const JotformSaas: React.FC<JotformSaasProps> = ({ clientId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jotformData, setJotformData] = useState<JotformData[]>([]);
  const [enabledForms, setEnabledForms] = useState<EnabledForm[]>([]);
  const [selectedFormId, setSelectedFormId] = useState<string>('');
  const [generatedLink, setGeneratedLink] = useState<string>('');
  const [user] = useContext(UserContext);

  useEffect(() => {
    if (user?._id) {
      fetchJotformData();
      fetchEnabledForms();
    } else {
      toast.error("User information not available", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      setIsLoading(false);
    }
  }, [clientId, user]);

  const fetchJotformData = async () => {
    if (!user?._id) return;
    
    setIsLoading(true);
    try {
      const response = await SaasTherapistService.getJotFormByClientId(user._id, clientId);
      if (response.success) {
        setJotformData(response.data);
      } else {
        toast.error(response.error || "Error loading Jotform data", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Failed to fetch Jotform data", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEnabledForms = async () => {
    try {
      const response = await SaasTherapistService.getEnabledForms();
      if (response.success) {
        setEnabledForms(response.data);
      } else {
        toast.error(response.error || "Error loading enabled forms", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Failed to fetch enabled forms", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const generateFormLink = () => {
    if (!selectedFormId || !user?._id) return;
    const link = `https://form.jotform.com/${selectedFormId}?hash_therapist=${user._id}&hash_clientid=${clientId}`;
    setGeneratedLink(link);
  };

  const copyToClipboard = () => {
    if (generatedLink) {
      navigator.clipboard.writeText(generatedLink).then(() => {
        toast.success("Link copied to clipboard!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      });
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
        <Spinner className="bouncer" />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-3">Select Form</h5>
        <div className="row g-3 align-items-end">
          <div className="col-md-8">
            <select
              className="form-select form-select-lg"
              value={selectedFormId}
              onChange={(e) => setSelectedFormId(e.target.value)}
              style={{ height: '50px', fontSize: '16px' }}
            >
              <option value="">Select a form...</option>
              {enabledForms.map((form) => (
                <option key={form._id} value={form.form_id}>
                  {form.title_form}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <button
              className="btn btn-primary w-100"
              onClick={generateFormLink}
              disabled={!selectedFormId}
              style={{
                height: '50px',
                background: '#ff6634',
                border: 'none',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: '500'
              }}
            >
              Generate Link
            </button>
          </div>
        </div>
        {generatedLink && (
          <div className="mt-3">
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg"
                value={generatedLink}
                readOnly
                style={{ height: '50px', fontSize: '16px' }}
              />
              <button
                className="btn btn-outline-primary"
                onClick={copyToClipboard}
                style={{
                  height: '50px',
                  borderColor: '#ff6634',
                  color: '#ff6634',
                  fontSize: '16px',
                  fontWeight: '500'
                }}
              >
                Copy
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="table-responsive">
        <table className="table table-bordered mb-0">
          <thead>
            <tr>
              <th>Form Name</th>
              <th>Submission Date</th>
              <th>Score PHQ 9</th>
              <th>Score GAD 7</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jotformData.length === 0 ? (
              <tr>
                <td colSpan={5} className="text-center">No forms available</td>
              </tr>
            ) : (
              jotformData.map((form, index) => (
                <tr key={index}>
                  <td>{form.formName}</td>
                  <td>{formatDate(form.submissionDate)}</td>
                  <td>{form.score_PHQ_9}</td>
                  <td>{form.score_GAD_7}</td>
                  
                  <td>
                    <a 
                      href={form.resultLink} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-primary"
                    >
                      View Result
                    </a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JotformSaas;
