import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, CardTitle, Col, Container, Row } from "reactstrap";
import { Divider } from "@mui/material";
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from "three";
import { Canvas, useFrame, useGraph } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import "../../../src/pages/Avatar/LavniAvatar.css";
import avatar1 from "../../assets/images/avatars/male_avatar.jpg";
import avatar2 from "../../assets/images/avatars/female_avatar.jpg";
import MessageModal from "../Popup/MessageModal";
import { NavLink, useHistory } from "react-router-dom";
import { VideoChatService } from "src/services/VideoChatService";
import images from "../../assets/images";
import { toast } from "react-toastify";
import UserContext from "src/context/UserContext";

let video: HTMLVideoElement;
let faceLandmarker: FaceLandmarker;
let lastVideoTime = -1;
let blendshapes: any[] = [];
let rotation: Euler;
let isAvatarLoading = false;
const headMesh: any[] = [];
const MALE_AVATAR = "https://models.readyplayer.me/64be67bc4e1697f144f113c1.glb?morphTargets=ARKit&textureAtlas=1024";
const FEMALE_AVATAR = "https://models.readyplayer.me/64be760d3e25313d23fe1f3f.glb?morphTargets=ARKit&textureAtlas=1024";

const options: FaceLandmarkerOptions = {
  baseOptions: {
    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
    delegate: "GPU",
  },
  numFaces: 1,
  runningMode: "VIDEO",
  outputFaceBlendshapes: true,
  outputFacialTransformationMatrixes: true,
};


const Avatar = ({ url }: { url: string }) => {
  const { scene } = useGLTF(url);
  const { nodes } = useGraph(scene.children[0]);
  
  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);
    
    isAvatarLoading = false;
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          const index = mesh.morphTargetDictionary[element.categoryName];

          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.7, 3]} />;
};

const LavniAvatar: React.FC = () => {
  const history = useHistory();
  const subdomain = "lavnihealth";
  const [user, setUser] = useContext(UserContext);
  const [url, setUrl] = useState<string>(MALE_AVATAR);
  const [selectedAvatar, setSelectedAvatar] = useState<string>("avatarOne");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isAvatarCreating, setIsAvatarCreating] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataLoadingError, setDataLoadingError] = useState(false);
  const [dataLoadingInitial, setDataLoadingInitial] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarId, setAvatarId] = useState("");
  const [initialAvatarId, setInitialAvatarId] = useState("avatarOne");
  const [savingData, setSavingData] = useState(false);
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const [avatarBackground, setAvatarBackground] = useState(images.avatarBackgroundOne);
  const [isHidden, setIsHidden] = useState(false);
  let videoStream: MediaStream | null;
  const [videoStream2, setVideoStream2]= useState<MediaStream|null>(null);
  const [isCameraAccess, setIsCameraAccess] = useState(false)
  const [userCameraAccess, setUserCameraAccess] = useState(false)
  const [loading, setLoading] = useState(false)
  let cameraListener: any;


  useEffect(() => {
    
    if (user?.role == "CLIENT") {
      VideoChatService.getAvatarDetailsOfOwnUser().then(res => {
        if (res.success) {
          if (res.data.useDefaultAvatar) {
            switch (res.data.avatarBackgroundId) {
              case "backgroundOne":
                setAvatarBackground(images.avatarBackgroundOne);
                break;
              case "backgroundTwo":
                setAvatarBackground(images.avatarBackgroundTwo);
                break;
              default:
                setAvatarBackground(images.avatarBackgroundOne);
            }

            switch (res.data.avatarId) {
              case "avatarOne":
                setAvatarUrl(images.avatarOne);
                break;

              case "avatarTwo":
                setAvatarUrl(images.avatarTwo);

                break;
              default:
                setAvatarUrl(images.avatarOne);
            }
          }

          if (!res.data.useDefaultAvatar) {
            switch (res.data.avatarBackgroundId) {
              case "backgroundOne":
                setAvatarBackground(images.avatarBackgroundOne);

                break;
              case "backgroundTwo":
                setAvatarBackground(images.avatarBackgroundTwo);

                break;
              default:
                setAvatarBackground(images.avatarBackgroundOne);
            }
            
            setAvatarUrl(res.data.avatarId);
          }
        } else {
          setAvatarBackground(images.avatarBackgroundOne);
          setAvatarUrl(images.avatarOne);
        }
      });
    }
  }, [])


  useEffect(() => {

    setDataLoadingInitial(false);

    if (dataLoadingInitial) {
      getAvatarDetails()
    }

  });

  useEffect(() => {

    if (isAvatarCreating) {

      if (isAvatarCreating) {
        if (iFrameRef.current) {
          iFrameRef.current.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
        }

      }
    }
  }, [isAvatarCreating]);

  useEffect(() => {
    if (isAvatarCreating) {

      window.addEventListener('message', subscribe)
      document.addEventListener('message', subscribe)

      return () => {

        window.removeEventListener('message', subscribe)
        document.removeEventListener('message', subscribe)

      }
    }
  }, [isAvatarCreating]);

  useEffect(() => {

    setAvatarBackground(avatarBackground);

  }, [avatarBackground]);


  const setup = async () => {
    const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
    faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, options);

    video = document.getElementById("video") as HTMLVideoElement;
    if (video != null){
      navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then(function (stream) {
        setVideoStream2(stream);
        video.srcObject = stream;
        cameraListener = predict
        video.addEventListener("loadeddata", predict);
      })
      .catch(function (error) {
        console.log("Error");
        console.log(error);
        setShowErrorModal(true);
      });
    }
    
  };

  window.addEventListener("beforeunload", () => {
    if (videoStream2) {
      videoStream2.getTracks().forEach((track) => {
        track.stop();
      });
    }
  
    if (video && cameraListener) {
      video.removeEventListener("loadeddata", cameraListener);
    }
  });

  const predict = () => {
    try {
      const nowInMs = Date.now();
      video = document.getElementById("video") as HTMLVideoElement;
      if (video != null){
        if (lastVideoTime !== video.currentTime) {
          lastVideoTime = video.currentTime;
          const faceLandmarkerResult = faceLandmarker.detectForVideo(video, nowInMs);
  
          if (faceLandmarkerResult.faceBlendshapes && faceLandmarkerResult.faceBlendshapes.length > 0 && faceLandmarkerResult.faceBlendshapes[0].categories) {
            blendshapes = faceLandmarkerResult.faceBlendshapes[0].categories;
  
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const matrix = new Matrix4().fromArray(faceLandmarkerResult.facialTransformationMatrixes![0].data);
  
            rotation = new Euler().setFromRotationMatrix(matrix);
          }
        }
        window.requestAnimationFrame(predict);
      }
      

    } catch (error) {
      console.log("Error");
      console.log(error);
    }
  };

  const changeAvatar = async (event: any) => {
    const id = event.currentTarget.id;
    setLoading(true)
      const res = await VideoChatService.saveDefaultAvatar(id)
      if (res.success){
        setAvatarId(id)
        if (id == 'avatarOne'){
          setAvatarUrl(images.avatarOne)
        } else {
          setAvatarUrl(images.avatarTwo)
        }
        setLoading(false)
        toast.success("Avatar updated", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    
  };

  useEffect(() => {
    // setIsCameraAccess(true)
    // setup();

    return () => {
      setUserCameraAccess(false)
      setIsCameraAccess(false)
      
      if (videoStream2) {
        videoStream2.getTracks().forEach((track) => {
          track.stop(); // Stop each track in the video stream
        });
      }
      if (video && cameraListener) {
        video.removeEventListener("loadeddata", predict);
      }
    };
  }, []);



  const createNewAvatar = () => {
    setIsHidden(true);
    setIsAvatarCreating(true);
  }

  async function getAvatarDetails() {
    setDataLoading(true);
    const res = await VideoChatService.getAvatarDetails();
    if (res.success) {
      if (res.data.useDefaultAvatar) {
        switch (res.data.avatarId) {
          case "avatarOne":
            setAvatarUrl(images.avatarOne)
            setAvatarId(res.data.avatarId)
            setInitialAvatarId(res.data.avatarId)
            break;

          case "avatarTwo":
            setAvatarUrl(images.avatarTwo)
            setAvatarId(res.data.avatarId)
            setInitialAvatarId(res.data.avatarId)
            break;

          default:
            setAvatarUrl(images.avatarOne)
        }
      }

      if (!res.data.useDefaultAvatar) {
        const num = Math.floor(Math.random() * (10000000 - 100000 + 1)) + 100000;
        setAvatarId(res.data.avatarId + "&num=" + num)
        setUrl(res.data.avatarId)
      }
      setDataLoading(false);
    } else {
      setDataLoading(false);

      setDataLoadingError(true);
    }
  }

  async function saveNewAvatar(avatarUrl: any) {
    isAvatarLoading = true;
    setLoading(true)
    const res = await VideoChatService.saveNewAvatar(avatarUrl);
    if (res.success) {
      setLoading(false)
      setAvatarUrl(res.data.avatarId)
      setDataLoading(false)
      toast.success("Avatar updated", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

    } else {

      setDataLoading(false);
      toast.error(res.error ? res.error : "Server error occurred", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

    }
  }

  function subscribe(event: any) {
    const json = parse(event)
    if (json?.source !== 'readyplayerme') {
      return;
    }
    if (json.eventName === 'v1.frame.ready') {
      if (iFrameRef.current && iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**'
          }),
          '*'
        );
      }
    }

    if (json.eventName === 'v1.avatar.exported') {
      setIsAvatarCreating(false);
      setDataLoading(true)
      setUser({...user, avatarImage: json.data.url, useDefaultAvatar: false})
      saveNewAvatar(json.data.url);
    }
  }

  function parse(event: any) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  const accessUserCamera = (value: boolean) => {
    setUserCameraAccess(value)
    if (value){
      setup()
      setIsCameraAccess(true)
    } else {
      setIsCameraAccess(false) 
      if (videoStream2) {
        
        videoStream2.getTracks().forEach((track) => {
          track.stop(); // Stop each track in the video stream
        });
      }
      if (video && cameraListener) {
        video.removeEventListener("loadeddata", predict);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content main-page-style">
      
        <Container fluid>
          <div className="flex flex-wrap  cursor-pointer">
            <CardTitle className="mb-4 cursor_pointer">Create an Avatar for comfortable and anonymous sessions.</CardTitle>
          </div>


          <button className="avatar_btn1 avatarRes" type="button"
            onClick={() => {
              createNewAvatar()
            }}
          >
            Create an Avatar
          </button>
          <div className="d-flex">
            <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
              Access camera
            </h5>

            <div className="form-check form-switch form-switch-lg">
              <label className="form-check-label" htmlFor="newsletter">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="newsletter"
                  checked={userCameraAccess}
                  onChange={e => {}}
                  onClick={() => {
                    accessUserCamera(!userCameraAccess);
                  }}
                />
              </label>
            </div>
          </div>

          <Row>
            <Col xl="9">
              {loading ? (
                <Card className="avatar_sec1">
                  <div className="a-sec1">
                    <h1 className="loading-msg">Generating your avatar, Please wait..!</h1>
                  </div>
                </Card>): <div>
                {!isAvatarCreating ?( avatarUrl == '' ? (
                  <Card className="avatar_sec1">
                    <div className="a-sec1">
                      <h1 className="loading-msg">Loading Avatar ...</h1>
                    </div>
                  </Card>
                ) :(
                <Card className="avatar_sec1">
                  {isAvatarLoading && <div className="loading-avatar">Loading Avatar...</div>}
                  <div className="a-sec1">
                    { isCameraAccess && <video className="camera-feed" id="video" autoPlay playsInline></video>}

                    <Canvas camera={{ fov: 25 }} shadows>
                      <ambientLight intensity={0.5} />
                      <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
                      <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
                      <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
                      <Avatar url={avatarUrl} />

                    </Canvas>
                  </div>

                </Card>))
                :
                <div>
                  {!dataLoadingError && !dataLoading && isAvatarCreating && <p>
                    <iframe
                      allow="camera *; microphone *"
                      className="iFrame"
                      id="frame"
                      ref={iFrameRef}
                      style={{
                        display: 'block'
                      }}
                      title={"Ready Player Me"}
                    />
                  </p>}
                  {
                    !dataLoadingError && dataLoading && <div className="Loading-view-Avatar-View-For-Avatar-Tab">
                      <div >
                        <img src={images.cubicLoader} style={{ width: "90px" }} />
                      </div>
                    </div>
                  }

                  {
                    dataLoadingError && !dataLoading && <div className="loading-text">Server error occurred !</div>
                  }
                </div>
              }</div>
            }
            </Col>

            <Col xl="3" className="bg-avatar">
              <div className={isHidden ? 'hiddenDefaultAvatr' : 'avatar_library_container'} >
                <Row>
                  <Col xl="12">
                    <div>
                      <div className="avatar_text">Avatar Library</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" className="avatar_sec2">
                    <img
                      src={avatar1}
                      className={"avatar_icon " + (avatarId == "avatarOne" ? "active-avatar" : "")}
                      onClick={changeAvatar}
                      id="avatarOne"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" className="avatar_sec2">
                    <img
                      src={avatar2}
                      className={"avatar_icon " + (avatarId == "avatarTwo" ? "active-avatar" : "")}
                      onClick={changeAvatar}
                      id="avatarTwo"
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            <Row >
              <Col xl="4" md="4" sm="12">
                <button className="avatar_btn1 avatarRes2" type="button"
                  onClick={() => {
                    if (!isAvatarCreating && !dataLoading && !savingData) {
                      createNewAvatar()
                    }
                  }}
                >
                  Create an Avatar
                </button>
              </Col>
              {/* <Col xl="8" md="8" sm="12">
                <div className="changeSkipBtn">
                  <button className="avatar_btn3" onClick={() => {
                    skipBtn();
                  }}>
                    Skip for now
                  </button>

                  <button
                    onClick={() => {
                      saveDefaultAvatar();
                    }}
                    className="avatar_btn4"
                  >
                    Done
                  </button>
                </div>
                <Divider style={{ marginTop: 35 }}></Divider>
              </Col> */}
            </Row>
          </Row>
        </Container>
      </div>

      {showErrorModal && <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Unable to detect your camera!"} />}
    </React.Fragment>
  );
};

export default LavniAvatar;
