import DOMPurify from "dompurify";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CommentSection from "../ArticleComment";
import likeButton from "../../../assets/images/article/like2.svg";
import likedButton from "../../../assets/images/article/like.svg";
import moment from "moment";
import { Upload } from "../../../models/Upload";
import play from "../../../assets/images/play.png";
import { Util } from "../../../Util";

interface ListViewProps {
  userId: string;
  articleId: string | undefined;
  articleTitle: string;
  publishedDate: string;
  articleFile: Upload;
  vimeoId?: string;
  likedBy: any;
  comments: any[];
  articleBody: string;
  likePost: (postId: any) => void;
  addComment: (postId: any, comment: string, fn: any) => void;
  addReply: (postId: any, commentId: any, reply: string, setReply: any) => void;
}

export const PublicArticleListView: React.FC<ListViewProps> = ({
  userId,
  articleId,
  articleTitle,
  publishedDate,
  articleFile,
  vimeoId,
  likedBy,
  comments,
  articleBody,
  likePost,
  addComment,
  addReply,
}: ListViewProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [comment, setComment] = useState("");
  const nav = useHistory();
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const [scrollActive, setScrollActive] = useState(true);
  const Listview = "list";

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    const position = sessionStorage.getItem("position");

    if (scrollActive) {
      if (position) {
        const positionid = JSON.parse(position);
        if (positionid.position > scrollPosition || (scrollPosition == 0 && positionid.position != 0)) {
          window.scrollTo({
            top: positionid.position,
          });
        }
      }

      setScrollActive(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const navi = (articleId: any) => {
    const scrolldata = { position: scrollPosition.toString(), setPosition: true };
    sessionStorage.setItem("position", JSON.stringify(scrolldata));

    if (articleId) {
      nav.push({ pathname: "/public-article-details/" + articleId + "/" + Listview });
    }
  };

  const handleComments = (event: any, articleId: string | undefined, comment: string, setComment: any) => {
    if (event.keyCode == 13) {
      addComment(articleId, comment, setComment);
    }
  };

  return (
    <>
      <hr className="mb-4" />

      <div>
        <h5>{articleTitle}</h5>
        <p className="text-muted">{publishedDate}</p>

        {articleFile && articleFile.type.indexOf("image") !== -1 ? (
          <div className="position-relative mb-3">
            <div
              style={{
                backgroundImage: `url(${Util.fileURL(articleFile._id)})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "400px",
                textAlign: "right",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => navi(articleId as any)}
            >
              <i className="fa fa-picture-o video-icon"></i>
            </div>
          </div>
        ) : (
          <div className="position-relative mb-3">
            <div
              style={{
                backgroundImage: `url(https://vumbnail.com/${vimeoId}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "400px",
                padding: "10px",
                cursor: "pointer",
              }}
              className="video-item"
              onClick={() => navi(articleId as any)}
            >
              <img src={play} />
            </div>
          </div>
        )}

        {/* <ul className="list-inline">
          {likedBy.includes(userId) ? (
            <li className="list-inline-item mr-3 pointer1">
              <img
                src={likedButton}
                height={"15px"}
                width={"15px"}
                onClick={() => {
                  likePost(articleId);
                }}
              />
              &nbsp;
              {likedBy.length} Likes
            </li>
          ) : (
            <li className="list-inline-item mr-3 pointer1">
              <img
                src={likeButton}
                height={"15px"}
                width={"15px"}
                onClick={() => {
                  likePost(articleId);
                }}
              />
              &nbsp;
              {likedBy.length} Likes
            </li>
          )}
          <li
            className="list-inline-item mr-3 pointer1"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <i className="bx bx-comment-dots align-middle text-muted me-1 pointer1"></i>
            &nbsp;
            {comments.length} Comments
          </li>
        </ul> */}

        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(articleBody.substring(0, 300) + `${articleBody.length > 300 ? "..." : ""}`, { FORBID_TAGS: ["h1", "h2", "h3", "h4"] }),
            }}
          ></div>
          <button onClick={() => navi(articleId as any)} style={{ color: "#FD7F00" }}>
            Read more <i className="mdi mdi-arrow-right"></i>
          </button>
        </div>
      </div>

      {isVisible && comments.length > 0 ? (
        <div className="mt-3">
          <div className="flex justify-content-between w-100 border-top" style={{ flexDirection: "row" }}>
            <div className="px-2 w-100">
              <input
                type="text"
                className="form-control shadow-sm bg-white rounded"
                placeholder="Add a comment..."
                name="comment"
                value={comment}
                onChange={event => {
                  setComment(event?.target.value);
                }}
                onKeyDown={event => {
                  handleComments(event, articleId, comment, setComment);
                }}
              />
            </div>
            <button
              className="btn btn-primary px-2"
              onClick={() => {
                addComment(articleId, comment, function () {
                  setComment("");
                });
              }}
            >
              Comment
            </button>
          </div>
          {comments
            .sort((a, b) => (moment(a.date).format("HH:mm:ss") > moment(b.date).format("HH:mm:ss") ? -1 : 1))
            .slice(0, 3)
            .map((item, index) => {
              return (
                <CommentSection
                  key={index}
                  firstname={
                    item.userId.firstname == null || item.userId.firstname == undefined || item.userId.firstname == ""
                      ? item.userId.role
                      : item.userId.firstname
                  }
                  lastname={item.userId.lastname == null || item.userId.lastname == undefined || item.userId.lastname == "" ? undefined : item.userId.lastname}
                  imageUrl={Util.fileURL(item?.userId?.photoId?._id)}
                  date={moment(item.createdAt?.toString()).format("YYYY/MM/DD")}
                  comment={item.comment}
                  replies={item.replies}
                  commentId={item._id}
                  articleId={articleId}
                  addReply={addReply}
                />
              );
            })}
        </div>
      ) : isVisible ? (
        <div className="border-top mt-3">
          <div className="flex justify-content-between w-100 mt-3" style={{ flexDirection: "row" }}>
            <div className="px-2 w-100">
              <input
                type="text"
                className="form-control shadow-sm bg-white rounded"
                placeholder="Add a comment..."
                name="comment"
                value={comment}
                onChange={event => {
                  setComment(event?.target.value);
                }}
              />
            </div>
            <button
              className="btn btn-primary px-2"
              onClick={() => {
                addComment(articleId, comment, function () {
                  setComment("");
                });
              }}
            >
              Comment
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
