import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CardTitle, Col, Row, Modal, CardBody, Input, Label, Button, ModalBody } from "reactstrap";
import GroupChatContext from "src/context/GroupChatContext";
import UserContext from "src/context/UserContext";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import { GroupChatService } from "src/services/GroupChatService";
import Close from "../../../assets/images/icons/close.png";
import { Util } from "src/Util";
import { MatchedClientForChatGroupModel } from "src/models/GroupChat/MatchedClientForChatGroup";
import images from "src/assets/images";
import Spinner from "src/common/spinner/spinner";
import Swal from "sweetalert2";
import { MemberInSelectedChatGroupModel } from "src/models/GroupChat/MemberInSelectedChatGroup";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import { NotificationService } from "src/services/NotificationService";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import { SocketContext } from "src/context/ScoketContext";

toast.configure();

interface AddGroupChatMemberModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const AddGroupChatMemberModal: React.FC<AddGroupChatMemberModalProps> = ({ showModal, setShowModal }: AddGroupChatMemberModalProps) => {
  const socket = useContext(SocketContext);
  const [matchedClients, setMatchedClients] = useState([] as MatchedClientForChatGroupModel[]);
  const [isGettingMatches, setIsGettingMatches] = useState(true);
  const [isGettingMatchesError, setIsGettingMatchesError] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [selectedGroupData, setSelectedGroupData, selectedGroupIdForShowMembers] = useContext(GroupChatContext);
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [addingMemberId, setAddingMemberId] = useState("");
  const [isMemberListChanged, setIsMemberListChanged] = useState(false);

  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [totalDataCountInDb, setTotalDataCountInDb] = useState(0);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [finalSearchableString, setFinalSearchableString] = useState("");

  useEffect(() => {
    if (
      selectedGroupData != null &&
      selectedGroupData._id != null &&
      selectedGroupData._id != "" &&
      selectedGroupIdForShowMembers != null &&
      selectedGroupIdForShowMembers != "" &&
      selectedGroupIdForShowMembers == selectedGroupData._id &&
      user?.role == "THERAPIST" &&
      user._id == selectedGroupData.createdBy
    ) {
      setOffset(0);
      setTotalDataCountInDb(0);
      setIsSearchableString("");
      setFinalSearchableString("");

      setMatchedClients([]);
      initialLoading(true, false, false, true, false);
    }
  }, []);

  const initialLoading = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    console.log("initialLoading matching members");
    setIsGettingMatches(true);
    await getAllMatchedClientsForChatGroup(next, isSearch, isRemove, isInitial, isAdd);
    setIsGettingMatches(false);
  };

  const getAllMatchedClientsForChatGroup = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    try {
      let previousCount = 0;
      let finalOffset = offset;
      if (isInitial || isSearch) {
        finalOffset = 0;
      } else if (isRemove) {
        previousCount = matchedClients.length;
        if (isRemove && previousCount > 1) {
          if (finalOffset - 1 >= 0) {
            finalOffset = finalOffset - 1;
          }
        } else {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      } else if (isAdd) {
        if (finalOffset - 1 >= 0) {
          finalOffset = finalOffset - 1;
        }
      } else {
        if (!next) {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      }
      const dataObject = {
        groupId: selectedGroupData._id,
        searchableString: isSearch ? isSearchableString : finalSearchableString,
        limit: limit,
        offset: finalOffset * limit,
      };
      const res = await GroupChatService.getAllMatchedClientsForChatGroup(dataObject);
      if (res.success && res.data) {
        if (res.data != null && res.data.members != null && res.data.count != null) {
          setTotalDataCountInDb(res.data.count);
          setMatchedClients(res?.data.members);
          if (isSearch) {
            setFinalSearchableString(isSearchableString);
          }
          if (isSearch || isSearch) {
            setOffset(1);
          } else {
            if (isRemove) {
              if (isRemove && previousCount == 1) {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            } else if (isAdd) {
            } else {
              if (next) {
                setOffset(offset + 1);
              } else {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            }
          }
        }
      } else {
        setIsGettingMatchesError(true);
        toast.error("Retrieving matched clients failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsGettingMatchesError(true);
      toast.error("Retrieving matched clients failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const addMember = async (memberId: string, groupId: string) => {
    try {
      if (
        !isAddingMember &&
        user?.role == "THERAPIST" &&
        user._id == selectedGroupData.createdBy &&
        user?._id != memberId &&
        memberId != selectedGroupData.createdBy &&
        selectedGroupData._id == groupId
      ) {
        setAddingMemberId(memberId);
        setIsAddingMember(true);
        const userData = {
          groupId: groupId,
          memberId: memberId,
        };
        const res = await GroupChatService.addGroupMember(userData);
        if (res && res.success) {
          try {
            const grpChatventNotification: NotificationModel = {
              senderId: user?._id,
              receiverId: memberId,
              event: NotificationEvent.GRP_CHAT_MEMBER_ADDED,
              link: `/group-chat/${groupId}`,
              content: `You're Added to ${selectedGroupData.title ?? ""} ${selectedGroupData.type == "PUBLIC" ? "peer support" : "private"} chat group.`,
              variant: NotificationVarient.INFO,
            };

            NotificationService.sendNotification(grpChatventNotification).then(res => {
              if (res && res.success && res.data && res.data.socketId) {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: grpChatventNotification,
                  receiverId: memberId,
                  senderId: user?._id,
                };

                socket.emit("send-notification", socketData);
              }
            });
          } catch (error) {}

          setIsAddingMember(false);

          setIsMemberListChanged(true);

          toast.success("Member added successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          initialLoading(true, false, true, false, false);
        } else {
          setIsAddingMember(false);
          toast.error("Failed to add member!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsAddingMember(false);
      } else {
        toast.error("Please wait, adding member in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsAddingMember(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <Modal isOpen={showModal} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={() => {
            if (!isAddingMember) {
              setShowModal(isMemberListChanged);
            } else {
              toast.error("Please wait, adding member in progress!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
        >
          <img src={Close} alt="Close" className="me-2 mt-2" />
        </Button>
      </div>
      <ModalBody className="ps-4 pe-4">
        <Row>
          <span className="font-size-18 profileFont mb-2 cursor-pointer pt-4 text-center">Add Group Members</span>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex admin-filter">
            <div className="d-flex justify-content-center pr-2 ">
              <div className="d-flex justify-content-center align-items-center pr-2">
                <span className="text-center mb-0 single-line-text mr-2">Search : </span>
              </div>
              <div onClick={event => event.stopPropagation()}>
                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString(e.target.value)} />
              </div>
            </div>
            <span
              className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
              onClick={() => {
                if (!isGettingMatches) {
                  initialLoading(true, true, false, false, false);
                } else {
                  toast.error("Please wait", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                  });
                }
              }}
            >
              <i className="bx bx-search"></i>
            </span>
          </Col>
        </Row>
        <div className="pb-4 pt-2" style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }}>
          {isGettingMatches ? (
            <div className="group-chat-empty-view pb-4 pt-2">
              <Spinner className="grp-chat-spinner" />
            </div>
          ) : !isGettingMatches && isGettingMatchesError && (matchedClients == null || matchedClients.length == null || matchedClients.length <= 0) ? (
            <div className="group-chat-empty-view pb-4 pt-2">
              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
              <div className="mt-1">Error getting data</div>
            </div>
          ) : !isGettingMatches && matchedClients != null && matchedClients.length != null && matchedClients.length > 0 ? (
            matchedClients?.map((matchedClient, index) => (
              <Row key={index} className="border-top border-bottom p-2 me-2">
                <div className="d-flex justify-content-between p-2">
                  <div className="d-flex">
                    {matchedClient != null && matchedClient.photoId != null && matchedClient.photoId._id != null ? (
                      <div className="d-flex d-inline-block align-self-center ">
                        <img src={Util.fileURL(matchedClient.photoId._id)} className="rounded-circle avatar-sm imageFit" />
                      </div>
                    ) : (
                      <div className="d-flex d-inline-block align-self-center ">
                        <img src={images.defaultGroupIcon} className="rounded-circle avatar-sm imageFit" />
                      </div>
                    )}

                    <div className="d-flex flex-column ps-4 justify-content-center">
                      <h5 className="font-size-15 mb-1 text-left">
                        {(matchedClient?.firstname && matchedClient?.firstname.charAt(0)) ?? ""} {matchedClient?.lastname ?? ""}
                      </h5>
                    </div>
                  </div>

                  {!(isAddingMember && addingMemberId == matchedClient._id) &&
                    user?.role == "THERAPIST" &&
                    user?._id == selectedGroupData.createdBy &&
                    user?._id != matchedClient._id &&
                    matchedClient._id != selectedGroupData.createdBy && (
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="btn-match btn btn-primary  pt-2 pb-2"
                          onClick={() => {
                            Swal.fire({
                              icon: "warning",
                              title: "Are you sure you want to add this member to group",
                              showCancelButton: true,
                              confirmButtonText: "Add",
                              confirmButtonColor: "#FD7F00",
                              cancelButtonColor: "#FD7F00",
                            }).then(result => {
                              if (result.isConfirmed) {
                                addMember(matchedClient._id, selectedGroupData._id);
                              }
                            });
                          }}
                        >
                          Add
                        </button>
                      </div>
                    )}
                </div>
              </Row>
            ))
          ) : (
            <div className="group-chat-empty-view pb-4 pt-2">
              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
              <div className="mt-1">No matching members who are not already in members</div>
            </div>
          )}
        </div>
        <Row className="mt-2 mb-4">
          {!isGettingMatches && matchedClients != null && matchedClients.length != null && matchedClients.length > 0 && (
            <div className={offset > 1 ? "d-flex justify-content-between " : "d-flex justify-content-end "}>
              <div>
                {offset > 1 && (
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      if (!isGettingMatches) {
                        initialLoading(false, false, false, false, false);
                      } else {
                        toast.error("Please wait", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          className: "foo-bar",
                        });
                      }
                    }}
                  >
                    <i className="bx bx-left-arrow-alt me-2"></i> Back
                  </button>
                )}
              </div>
              <div>
                {offset > 0 && totalDataCountInDb > limit * offset && (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      if (!isGettingMatches) {
                        initialLoading(true, false, false, false, false);
                      } else {
                        toast.error("Please wait", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          className: "foo-bar",
                        });
                      }
                    }}
                  >
                    Next <i className="bx bx-right-arrow-alt ms-2"></i>
                  </button>
                )}
              </div>
            </div>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AddGroupChatMemberModal;
