import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import "../../assets/css/block_section.css";
import Close from "../../assets/images/icons/u_times-circle.png";

const AvailableDatesModal: React.FC<{
    availableDatesList: any[];
    availableHours12HourList: any[];
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    deleteAvailableTimeRange: (availableDateObj: any) => void;
    isSavingAvailableTimes: boolean;
    currentSavingObj: any;
}> = props => {
    const [sundayList, setSundayList] = useState([] as any[]);
    const [mondayList, setMondayList] = useState([] as any[]);
    const [tuesdayList, setTuesdayList] = useState([] as any[]);
    const [wednesdayList, setWednesdayList] = useState([] as any[]);
    const [thursdayList, setThursdayList] = useState([] as any[]);
    const [fridayList, setFridayList] = useState([] as any[]);
    const [saturdayList, setSaturdayList] = useState([] as any[]);
    
    useEffect(() => {
        setSundayList([]);
        setMondayList([]);
        setTuesdayList([]);
        setWednesdayList([]);
        setThursdayList([]);
        setFridayList([]);
        setSaturdayList([]);

        props.availableHours12HourList.forEach((item) => {
            if(item.day == "Sunday") {
                setSundayList(sundayList => [...sundayList, item]);
            } else if(item.day == "Monday") {
                setMondayList(mondayList => [...mondayList, item]);
            } else if(item.day == "Tuesday") {
                setTuesdayList(tuesdayList => [...tuesdayList, item]);
            } else if(item.day == "Wednesday") {
                setWednesdayList(tuesdayList => [...tuesdayList, item]);
            } else if(item.day == "Thursday") {
                setThursdayList(tuesdayList => [...tuesdayList, item]);
            } else if(item.day == "Friday") {
                setFridayList(tuesdayList => [...tuesdayList, item]);
            } else if(item.day == "Saturday") {
                setSaturdayList(tuesdayList => [...tuesdayList, item]);
            }
        });
    }, [props.availableHours12HourList]);

    function closeBlockDates() {
        props.setShowModal(false);
    }

    return props.showModal ? (
        <>
            <Modal isOpen={props.showModal}
                centered
                fullscreen
                scrollable
                style={{
                    padding: "20px",
                    borderRadius: "10px",
                }}
            >
                <div className="modal-head" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }} >
                    <div className="diagnosis-header">
                        <h5 className="mb-0 font-size-15">All Available Times</h5>
                        
                        <button type="button" onClick={closeBlockDates} className="bg-transparent-color" data-dismiss="modal" aria-label="Close" >
                            <img src={Close} alt="Close" className="close-icon-d" />
                        </button>
                    </div>
                </div>

                {
                    props.availableHours12HourList.length > 0 &&
                    <div className="main_block_section">
                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Sundays
                            </div>

                            {sundayList && sundayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Mondays
                            </div>

                            {mondayList && mondayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Tuesdays
                            </div>

                            {tuesdayList && tuesdayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Wednesdays
                            </div>

                            {wednesdayList && wednesdayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Thursdays
                            </div>

                            {thursdayList && thursdayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Fridays
                            </div>

                            {fridayList && fridayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="block_sec_sub">
                            <div className="block_date_header">
                                Saturdays
                            </div>

                            {saturdayList && saturdayList.map((opt, index) => (
                                <div className="block-date-div" key={index}>
                                    <div className="block-date-div-inside">
                                        <div className="block-date-sec1">
                                            <div className="block-date-text1">{opt.day}</div>

                                            <div className="block-date-text1">{opt.startTime + " - " + opt.endTime}</div>
                                        </div>
                                        
                                        <div className="block-date-sec2">
                                            {
                                                !props.isSavingAvailableTimes || (opt.startTime != props.currentSavingObj.startTime || opt.endTime != props.currentSavingObj.endTime || opt.day != props.currentSavingObj.day) ? 
                                                <div className="block-date-text2" onClick={() => {
                                                    props.deleteAvailableTimeRange(opt);
                                                    }}>
                                                    <i className="bx bxs-trash me-2"></i>
                                                </div>
                                                : <div className="spinner-grow spinner-grow-sm spinner-loading" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }

                {
                    props.availableHours12HourList.length == 0 && <div className="no-blocked">
                        No available times are added yet!
                    </div>
                }
            </Modal>
        </>
    ) : null;
};

export default AvailableDatesModal;