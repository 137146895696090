import * as React from "react";
import { NotificationDetails } from "../models/Notification";

type NotificationData = [
  NotificationDetails | undefined,
  (notificationDetails: NotificationDetails) => void,
  string,
  (messageSenderId: string) => void,
  string,
  (messageSenderDetails: string) => void
];

const NotificationContext = React.createContext<NotificationData>([undefined, () => ({}), "", () => ({}), "", () => ({})]);

export default NotificationContext;
