import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, UncontrolledTooltip } from "reactstrap";
import SkeltonClientRequest from "src/common/skeleton/SkeltonClientRequest";



const ShowTherapistScore: React.FC<any> = ({score, isLoading}) => {
    const history = useHistory();

    const viewMoreDetails = () => {
        history.push('/show-therapist-score')
      }
  
  return (
    <React.Fragment>
        {!isLoading  && (
            <Card>
                <CardBody className="pt-0 align-items-center">
                    <h5 className="mb-0 fs-14 fc-black mb-2" style={{marginTop: '20px', marginBottom: '0px'}}>Your Score</h5>
                    <Card >
                    <CardBody className="pt-0 align-items-center" style={{backgroundColor: '#FFDFC3', borderRadius: '10px', paddingBottom: '0'}}>
                        {score ? (
                            <Row >
                            {/* <div className="row pt-3 pb-3">
                                <div className="col-4 d-inline-block text-nowrap">
                                    <p className="mb-0 fs-14 fs-black2">Your Score: {score}</p>
                                    <button className="btn btn-primary" style={{marginTop: '10px'}} onClick={viewMoreDetails}>Show more</button>
                                </div>
                            </div> */}
                            <div className="score-page-sec1-dashboard">
                                <h5>Your Score </h5>
                                <div className="earning-sec2" style={{ borderRight: '1px solid black', width: '1px', height: '80px'}}></div>
                                <span className="sub-item1-text" style={{ color: score > 50 ? '#34c38f' : 'red' }}>
                                    {Number(score).toFixed(2)}%
                                </span>
                                <div className="earning-sec2" style={{ borderRight: '1px solid black', width: '1px', height: '80px'}}></div>
                                    <button className="btn btn-primary" style={{marginTop: '10px'}} onClick={viewMoreDetails}>Show more</button>
                                </div>
                            </Row>
                        ): (
                            <Row >
                            <div className="score-page-sec1-dashboard">
                                <h5>Score Not Found!</h5>
                                </div>
                            </Row>
                        )}
                        
                    </CardBody>
                    </Card>
                </CardBody>
            </Card>
        )}
        
    </React.Fragment>
  );
};

export default ShowTherapistScore;
