import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { CommonService } from "../../services/CommonService";
import { Ethinicity } from "../../models/Ethnicity";
import { TherapistService } from "../../services/TherapistService";
import { Profession } from "../../models/Profession";
import Creatable from "react-select/creatable";
import { ExperienceTag } from "../../models/ExperienceTag";
import { Tags } from "../../common/custom-components/Tags";
import { ClientService } from "../../services/ClientService";
import { RouteName } from "../../RouteName";
import { ArticleService } from "../../services/ArticleService";
import { toast } from "react-toastify";

toast.configure()


const FormFour = (props: any) => {
  const [gender, setGender] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [experienceYears, setExperienceYears] = React.useState<number[]>([0, 20]);
  const [selectedProfession, setSelectedProfession] = useState("");
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [selectedExpTags, setSelectedExpTags] = useState<any[]>([]);
  const [selectedHashTags, setSelectedHashTags] = useState<any[]>([]);

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthnicityList(res.data);
    });

    TherapistService.getProfessions().then(res => {
      setProfessionsDetails(res.data);
    });

    getAllExperienceTags();
  }, []);

  const changeGender = useCallback(
    (gender: string) => () => {
      setGender(gender);
    },
    []
  );

  const changeExperienceYears = (event: Event, newValue: number | number[]) => {
    setExperienceYears(newValue as number[]);
  };

  const DropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const creatableComponents = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
  };

  const handlExpTags = (value: any) => {
    if(value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
  
      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label, value: tag.value };
      });
      
      if (updatedTags) {
        setSelectedExpTags(updatedTags);
      }
    }
  };

  const removeHashTag = (removedtag: any) => {
    setSelectedHashTags(selectedHashTags.filter(tag => tag.label != removedtag));
  };

  const removeExpTag = (removedtag: any) => {
    setSelectedExpTags(selectedExpTags.filter(tag => tag.label != removedtag));
  };

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });

        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };
  // ClientPreferences
  const updateTherapistDetails = () => {
    const updatedUser: any = {
      gender: gender,
      ethnicityId: selectedEthnicity.length > 0 ? selectedEthnicity : null,
      professionId: selectedProfession.length > 0 ? selectedProfession : null,
      experiencedIn: selectedExpTags.map(tag => {
        return tag.value;
      }),
      yearsOfExperienceMin: experienceYears[0],
      yearsOfExperienceMax: experienceYears[1],
    };

    ClientService.addPreferences(updatedUser).then(res => {
      if (res.success) {
        window.location.href = RouteName.PROFILE;
      }
    });
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight p-3">
        <div className="card-bodyDiv">
          <div className="card-heading mb-2">
            <h5 className="font-size-20 profileFont mb-4">What are you looking for in a mental health professional?</h5>
          </div>
        </div>

        <Row>
          <Col xl="6">
            <div className="card-heading mb-2">
              <h5 className="font-size-14 profileFont mb-2">
                Select Therapist Gender
                <span style={{ color: "#990000" }}></span>
              </h5>
            </div>

            <div className="col-xl-12 pr-3 mb-3">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="mb-3" style={{ marginRight: "1rem" }}>
                  <span className={gender == "" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("")}>
                    All
                  </span>
                </div>

                <div className="mb-3 " style={{ marginRight: "1rem" }}>
                  <span className={gender == "Female" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Female")}>
                    Female
                  </span>
                </div>

                <div className="mb-3 " style={{ marginRight: "1rem" }}>
                  <span className={gender == "Male" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Male")}>
                    Male
                  </span>
                </div>

                <div className="mb-3 " style={{ marginRight: "1rem" }}>
                  <span className={gender == "NonBinary" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("NonBinary")}>
                    Non Binary
                  </span>
                </div>

                <div className="mb-3">
                  <span className={gender == "Other" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Other")}>
                    Other
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl="6">
            <div className="card-heading mb-2">
              <h5 className="font-size-14 profileFont mb-2">
                Select Therapist Ethnicity
                <span style={{ color: "#990000" }}></span>
              </h5>
            </div>

            <div className="col-xl-12 mb-3">
              <select className="form-control" onChange={e => setSelectedEthnicity(e.target.value)} value={selectedEthnicity}>
                <option value="">Select Ethnicity</option>
                {ethnicityList &&
                  ethnicityList.map(opt => (
                    <option value={opt._id} key={opt._id}>
                      {opt.ethnicity}
                    </option>
                  ))}
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl="6">
            <div className="card-heading mb-2">
              <h5 className="font-size-14 profileFont mb-2">
                Select Therapist Profession
                <span style={{ color: "#990000" }}></span>
              </h5>
            </div>

            <div className="col-xl-12 mb-3">
              <select className="form-control" onChange={e => setSelectedProfession(e.target.value)} value={selectedProfession}>
                <option value="">Select Profession</option>
                {professionsDetails &&
                  professionsDetails.map(opt => (
                    <option value={opt._id} key={opt._id}>
                      {opt.name}
                    </option>
                  ))}
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl="6">
            <div className="card-heading mb-2">
              <h5 className="font-size-14 profileFont mb-2">
                Select Therapist Areas Of Specialization
                <span style={{ color: "#990000" }}></span>
              </h5>
            </div>

            <Creatable
              options={searchExpTag}
              isMulti
              components={creatableComponents}
              onChange={(newValue: any) => handlExpTags(newValue)}
              value={selectedExpTags}
              display="none"
              className="creatableEdit"
              placeholder="Choose an area"
              controlShouldRenderValue={false}
              isClearable={false}
            />

            <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
              {selectedExpTags.map((tag, id) => {
                return <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
              })}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={6}></Col>
          <Col xl={6}>
            <button
              type="button"
              className="btn btn-success   mr-2  ml-4  relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                updateTherapistDetails();
              }}
            >
              Get Started
            </button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FormFour;
