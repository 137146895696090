import React from "react";
import "../../assets/css/skeleton.css";

const SkeltonDiscoverArticles: React.FC = () => {
  return (
    <React.Fragment>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-column align-items-start justify-content-center">
                  <div className="line mt-1 h8 w75"></div>
                  <div className="line mt-1 h8 w75"></div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-row align-items-center  justify-content-end">
                  <div className="line mt-1 h25 w75"></div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-column align-items-center justify-content-start">
                  <div className="line mt-1 h60 w75"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-column align-items-start justify-content-center">
                  <div className="line mt-1 h8 w75"></div>
                  <div className="line mt-1 h8 w75"></div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-row align-items-center  justify-content-end">
                  <div className="line mt-1 h25 w75"></div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-column align-items-center justify-content-start">
                  <div className="line mt-1 h60 w75"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-column align-items-start justify-content-center">
                  <div className="line mt-1 h8 w75"></div>
                  <div className="line mt-1 h8 w75"></div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-row align-items-center  justify-content-end">
                  <div className="line mt-1 h25 w75"></div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4 col-4">
                <div className="flex flex-column align-items-center justify-content-start">
                  <div className="line mt-1 h60 w75"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SkeltonDiscoverArticles;
