import moment from "moment";
import React, { useEffect } from "react";
import { Input } from "reactstrap";
import '../../../../src/assets/css/time_picker.css';

const CustomTimePickerForAdmin: React.FC<{
    timeValue: string;
    setTimeValue: (value: string) => void;
    appointment: any;
    setAppointment: (value: any) => void;
    setHourValue: (value: number) => void;
    setMinuteValue: (value: number) => void;
}> = props => {
    const [hourDataValue, setHourDataValue] = React.useState("00");
    const [minuteDataValue, setMinuteDataValue] = React.useState("00");
    const [meridiemDataValue, setMeridiemDataValue] = React.useState("AM");

    useEffect(() => {
        if(props.timeValue) {
            if(moment(props.timeValue).hour() == 12) {
                setHourDataValue((moment(props.timeValue).hour()).toString());
                setMeridiemDataValue("PM");
            } else if(moment(props.timeValue).hour() > 12) {
                if(moment(props.timeValue).hour() - 12 >= 10) {
                    setHourDataValue((moment(props.timeValue).hour() - 12).toString());
                } else {
                    setHourDataValue("0" + (moment(props.timeValue).hour() - 12).toString());
                }
                
                setMeridiemDataValue("PM");
            } else {
                if(moment(props.timeValue).hour() >= 10) {
                    setHourDataValue((moment(props.timeValue).hour()).toString());
                } else {
                    setHourDataValue("0" + (moment(props.timeValue).hour()).toString());
                }

                setMeridiemDataValue("AM");
            }

            setMinuteDataValue(moment(props.timeValue).minute().toString());
        }
    }, []);

    const updateAppointment = (hourVal: string, minuteVal: string, meridiemVal: string) => {
        const selectedTime = moment(props.appointment.date);

        if(meridiemVal == "AM") {
            if(parseInt(hourVal) == 12) {
                selectedTime.hour(0).minute(parseInt(minuteVal));
                props.setHourValue(0);
            } else {
                selectedTime.hour(parseInt(hourVal)).minute(parseInt(minuteVal));
                props.setHourValue(parseInt(hourVal));
            }
        } else {
            if(parseInt(hourVal) == 12) {  
                selectedTime.hour(parseInt(hourVal)).minute(parseInt(minuteVal));
                props.setHourValue(parseInt(hourVal));
            } else {
                const hour = parseInt(hourVal) + 12;
    
                selectedTime.hour(hour).minute(parseInt(minuteVal));
                props.setHourValue(hour);
            }
        }

        props.setMinuteValue(parseInt(minuteVal));

        const stateT = moment(selectedTime).format("H:mm A");
        const endT = moment(selectedTime).add(60, "minutes").format("H:mm A");
        const sDate = moment(selectedTime).toISOString();

        props.setAppointment({ ...props.appointment, startTime: stateT, endTime: endT, selectedDate: sDate, date: selectedTime.toString()});
    }

    return (
        <React.Fragment>
            <div className="custom_picker">
                <Input
                    name="sessionHours1"
                    type="select"
                    value={hourDataValue}
                    onChange={e => {
                        const time = e.target.value + ":" + minuteDataValue + " " + meridiemDataValue;

                        setHourDataValue(e.target.value);
                        props.setTimeValue(time);
                        updateAppointment(e.target.value, minuteDataValue, meridiemDataValue);
                    }}
                    >
                    <option value={"00"}>00</option>
                    <option value={"01"}>01</option>
                    <option value={"02"}>02</option>
                    <option value={"03"}>03</option>
                    <option value={"04"}>04</option>
                    <option value={"05"}>05</option>
                    <option value={"06"}>06</option>
                    <option value={"07"}>07</option>
                    <option value={"08"}>08</option>
                    <option value={"09"}>09</option>
                    <option value={"10"}>10</option>
                    <option value={"11"}>11</option>
                    <option value={"12"}>12</option>
                </Input>

                <Input
                    name="sessionMinutes1"
                    type="select"
                    value={minuteDataValue}
                    onChange={e => {
                        const time = hourDataValue + ":" + e.target.value + " " + meridiemDataValue;

                        setMinuteDataValue(e.target.value);
                        props.setTimeValue(time);
                        updateAppointment(hourDataValue, e.target.value, meridiemDataValue);
                    }}
                    >
                    <option value={"00"}>00</option>
                    <option value={"30"}>30</option>
                </Input>

                <Input
                    name="sessionMeridiem1"
                    type="select"
                    value={meridiemDataValue}
                    onChange={e => {
                        const time = hourDataValue + ":" + minuteDataValue + " " + e.target.value;

                        setMeridiemDataValue(e.target.value);
                        props.setTimeValue(time);
                        updateAppointment(hourDataValue, minuteDataValue, e.target.value);
                    }}
                    >
                    <option value={"AM"}>AM</option>
                    <option value={"PM"}>PM</option>
                </Input>
            </div>
        </React.Fragment>
    );
};

export default CustomTimePickerForAdmin;
