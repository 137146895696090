import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserDetails } from "src/models/User";
import { SaasTherapistService } from "src/services/SaasTherapistService";
import Spinner from "../../../common/spinner/spinner";

interface PersonalInfoProps {
  clientId: string;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ clientId }) => {
  const [clientPersonalDetails, setClientPersonalDetails] = useState<UserDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchClientData = async () => {
      if (!clientId) return;

      try {
        setIsLoading(true);
        const data = await fetchClientDetails(clientId); // Await the function correctly
        setClientPersonalDetails(data);
        setIsLoading(false);
      } catch (error) {
        toast.error("Failed to fetch client details.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]); // Dependency ensures the effect re-runs when clientId changes

  const fetchClientDetails = async (clientId: string) => {
    try {
      const res = await SaasTherapistService.getPersonalDetails(clientId);
      if (res.success) {
        return res.data; // Return the data
      } else {
        toast.error("Error loading data", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return null; // Return null to handle the error
      }
    } catch (error) {
      toast.error("Error fetching client details.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      return null; // Return null in case of failure
    }
  };

  if (!clientPersonalDetails) {
    return <p>Loading client data...</p>;
  }
  const formatDateToString = (date: Date | undefined): string => {
    if (!date) return ""; // Handle undefined dates
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(date).toLocaleDateString("en-US", options); // Format the date as MM/DD/YYYY
  };
  return (
    <div style={{ maxWidth: "100%", padding: "10px" }}>
      <h3 style={{ color: "#f27e0c", marginTop: "3px" }}>Personal Information</h3>

      {isLoading ? (
        <div className={"d-flex justify-content-center align-items-center"} style={{minHeight:"25vh",width:"100%",position:"relative"}}>
          <Spinner className={"bouncer"} />
        </div>
      ) : (
        <form style={{ fontSize: "12.5px" }}>
          <div style={{ marginBottom: "15px" }}>
            <label style={{ display: "block", marginBottom: "5px", marginTop: "20px", fontWeight: "bold" }}>Full Name</label>
            <input
              type="text"
              value={(clientPersonalDetails?.firstname || "") + " " + (clientPersonalDetails?.lastname || "")}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9fafb",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px", display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Birthday</label>
              <input
                type="text"
                value={formatDateToString(clientPersonalDetails?.dateOfBirth)}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  backgroundColor: "#f9fafb",
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Gender</label>
              <input
                type="text"
                value={clientPersonalDetails?.gender}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  backgroundColor: "#f9fafb",
                }}
              />
            </div>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Contact Number</label>
            <input
              type="text"
              value={clientPersonalDetails?.primaryPhone}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9fafb",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Address</label>
            <input
              type="text"
              value={`${clientPersonalDetails?.streetAddress || ''}, ${clientPersonalDetails?.unit || ''}, ${clientPersonalDetails?.city || ''}, ${clientPersonalDetails?.state || ''} ${clientPersonalDetails?.zipCode || ''}`.replace(/, ,| ,/g, '').trim()}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9fafb",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Emergency Contact</label>
            <input
              type="text"
              value={clientPersonalDetails?.homePhone}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9fafb",
              }}
            />
          </div>
        </form>
      )}

    </div>
  );
};

export default PersonalInfo;
