import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import { RouteName } from "../../RouteName";
import { useLocation } from "react-router-dom";
import UserContext from "../../context/UserContext";
import SwitchDashboard from "../CommonForBoth/TopbarDropdown/SwitchDashboard";
import { Role } from "../../models/Role";

const Header = props => {
  const location = useLocation();
  const [currentTabName, setCurrentTab] = useState(tabName(location.pathname));
  const [user] = useContext(UserContext);

  useEffect(() => {
    setCurrentTab(tabName(location.pathname));
  }, [location.pathname]);

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else if (window.screen.width <= 425 && body.classList.contains("sidebar-enable")) {
      body.classList.toggle("sidebar-enable");
      body.classList.remove("vertical-collpsed");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  function tabName(path) {
    switch (path) {
      case RouteName.DASHBOARD:
        return "Dashboard";
      case RouteName.SAAS_ALL_CLIENTS:
        return "View Clients";
      case RouteName.SAAS_CLIENTS_CHART:
        return "Clients Chart";
      case (path.includes(RouteName.CHAT_MENU) && path):
        return "Chat";
      case RouteName.GROUP_CHAT_MENU:
        return "Chat";
      case RouteName.SAAS_CLINICAL_DOCUMENTS:
        return "Clinical Documents";
      case RouteName.APPOINTMENTS:
        return "Appointments";
      case RouteName.PROFILE:
        return "Profile Details";
      case RouteName.SAAS_TODO:
        return "Therapist's To-do List";
      case RouteName.SAAS_PAYMENT:
        return "Payment";
      case RouteName.SAAS_CREDENTIALING:
        return "Credentialling";
      case RouteName.SAAS_CLAIMS:
        return "Claims";
      case RouteName.NOTIFICATIONS:
        return "Notifications";
      case RouteName.APPROVALS:
        return "Document Submission";
      case RouteName.TECH:
        return "Help";
      case RouteName.SUPPORT:
        return "Support";
      case RouteName.DOCUMENTS:
        return "Training Documents";
      case RouteName.EARNINGS_MENU:
        return "Total Earnings";
      case RouteName.ARTICLE_LIST:
        return "Discover";
      default:
        if (path.includes('therapist-referral')) {
          return "Rewards";
        }
        return "Dashboard";
    }
  }

  return (
    <React.Fragment>
      {location.pathname != RouteName.MEETING_SCREEN_NEW && !location.pathname.includes("vonage-session") && !location.pathname.includes("video-room") && !location.pathname.includes("room") &&
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className={"d-flex flex-row align-items-center"}>
                <button
                  type="button"
                  onClick={() => {
                    tToggle();
                  }}
                  className="btn btn-sm px-3 px-sm-4 px-md-5 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
                <div>
                  <h4 className="font-size-18 d-inline-block mb-0 ms-2">{currentTabName}</h4>
                </div>
              </div>
              <div></div>
            </div>
            {user.role == Role.THERAPIST && <SwitchDashboard />}
          </div>
        </header>}
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
