import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import DOMPurify from "dompurify";
import { Rating } from "react-simple-star-rating";
import { ParameterTypes } from "../../../utils/ParameterTypes";
import { ViewArticleModel } from "../../../models/Article";
import { ArticleService } from "../../../services/ArticleService";
import { VidemoService } from "../../../services/VimeoService";
import defaultImage from "../../../assets/images/avatar.jpeg";
import ShareModal from "../Popup/ShareModal";
import Spinner from "../../../common/spinner/spinner";
import CustomHeader from "./CustomHeader";
import { Util } from "../../../Util";

const PublicArticleView: React.FC = () => {
  const { articleId } = useParams<ParameterTypes>();
  const [articleDetails, setArticleDetails] = useState<ViewArticleModel>();
  const [showShareModal, setShowShareModal] = useState(false);
  const [rating1] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [transcodeStatus, setTranscodeStatus] = useState("");
  const [vimeoId, setVimeoId] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isTokenFound, setIsTokenFound] = useState(false);

  useEffect(() => {
    getArticleDetails(articleId);

    const token = localStorage.getItem("token");

    if (token) {
      setIsTokenFound(true);
    }
  }, []);

  const getArticleDetails = (id: string) => {
    setIsLoading(true);

    ArticleService.getArticleByIdForPublic(id).then(res => {
      if (res.success) {
        setArticleDetails(res.data);
        setIsLoading(false);

        if (!res.data.uploadId) {
          try {
            setVimeoId(res.data.vimeoId!);
            refreshTranscodeStatus(res.data.vimeoId!);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        setArticleDetails(undefined);
        setIsLoading(false);
      }
    });
  };

  const refreshTranscodeStatus = (vimeoId: string) => {
    setIsRefreshing(true);

    VidemoService.checkTranscodeStatus(vimeoId).then((res: any) => {
      setTranscodeStatus(res.transcode.status);
      setIsRefreshing(false);
    });
  };

  return (
    <React.Fragment>
      <CustomHeader isTokenFound={isTokenFound} />
      <div className="page-content">
        <title>Article Details | Lavni</title>
        <Container fluid>
          {!isLoading ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          <div>
                            <div className="text-center">
                              <div className="mb-4">
                                <Link to="#" className="badge bg-light font-size-12"></Link>
                              </div>
                              <h4>{articleDetails?.articleTitle}</h4>
                              <p className="text-muted mb-4">
                                <i className="mdi mdi-calendar me-1"></i>
                                {moment(articleDetails?.createdAt.toString()).format("YYYY/MM/DD")}
                              </p>
                            </div>

                            <hr />

                            <div className="text-center">
                              <Row>
                                <Col sm={4}>
                                  <div>
                                    <p className="text-muted mb-2">Areas Of Specialization</p>
                                    <h5 className="font-size-15">
                                      {articleDetails?.articleTags.map((item, index) => {
                                        return (
                                          <span key={index}>
                                            {index > 0 && <span>,&nbsp;</span>}

                                            {item.experienceTag}
                                          </span>
                                        );
                                      })}
                                    </h5>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">Post By</p>
                                    <div className="d-flex justify-content-center align-items-center">
                                      {articleDetails?.createdBy?.photoId ? (
                                        <img src={Util.fileURL(articleDetails?.createdBy?.photoId?._id)} className="block avatar-img imageFit" />
                                      ) : (
                                        <img src={defaultImage} className="block avatar-img" />
                                      )}
                                      <h5 className="font-size-15 px-2 mb-0">
                                        {articleDetails?.createdBy?.firstname || ""}
                                        &nbsp;
                                        {articleDetails?.createdBy?.lastname || ""}
                                      </h5>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">Ratings</p>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <Rating initialValue={4} size={20} transition fillColor={"#F2C94C"} readonly={true} />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <hr />

                            {articleDetails?.uploadId && articleDetails?.uploadId.type.indexOf("image") !== -1 ? (
                              <div className="player-wrapper">
                                <div className="my-5">
                                  <img src={Util.fileURL(articleDetails?.uploadId._id)} className="img-thumbnail mx-auto d-block" />
                                </div>
                              </div>
                            ) : (
                              <div className="player-wrapper">
                                {articleDetails?.vimeoId && (
                                  <>
                                    {transcodeStatus == "" ? (
                                      <div className="pending-transcoding">Loading...</div>
                                    ) : (
                                      <>
                                        {transcodeStatus == "in_progress" ? (
                                          <div className="pending-transcoding">
                                            {isRefreshing ? (
                                              "Checking Status... "
                                            ) : (
                                              <div>
                                                Video is still transcoding! Will available in a minute! &nbsp;&nbsp;
                                                <i
                                                  className="fa fa-refresh text-primary refresh-icon"
                                                  onClick={() => {
                                                    refreshTranscodeStatus(vimeoId);
                                                  }}
                                                ></i>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="video-responsive" style={{ width: "640px" }}>
                                            <iframe
                                              src={"https://player.vimeo.com/video/" + vimeoId}
                                              className="video-responsive-item"
                                              frameBorder="0"
                                              allow="autoplay; fullscreen; picture-in-picture"
                                              allowFullScreen
                                              title="Lavni Health"
                                            ></iframe>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            )}

                            <div className="flex flex-row flex-wrap font-size-15">
                              {articleDetails?.hashTags.map((item, index) => {
                                return (
                                  <div className="py-2" key={index} style={{ wordBreak: "break-word" }}>
                                    <span className="hash-tag">{item as any}</span>
                                  </div>
                                );
                              })}
                            </div>

                            <hr />

                            <div className="mt-4">
                              <div className="text-muted font-size-14 d-flex">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(articleDetails?.articleBody),
                                  }}
                                ></div>
                              </div>

                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Spinner />
          )}
        </Container>
      </div>
      {showShareModal ? <ShareModal title={articleDetails?.articleTitle} show={showShareModal} close={() => setShowShareModal(!showShareModal)} /> : <></>}
    </React.Fragment>
  );
};

export default PublicArticleView;
