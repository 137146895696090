import React from "react";
import { CardTitle, Modal, CardBody } from "reactstrap";
import Lottie from "lottie-react";
import logingOut from "../../assets/images/animations/logingOut.json";
interface LoaderModalProps {
  showModal: boolean;
  text: string;
}

const LoaderModal: React.FC<LoaderModalProps> = ({ showModal, text }: LoaderModalProps) => {
  return showModal ? (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-body">
          <CardBody>
            <CardTitle className="mb-3 mt-3 text-center font-size-20">
              {text}
              <Lottie animationData={logingOut} loop={true} style={{ height: "150px" }} />
              {/* <img src={cubicLoader} width={"30px"} height={"30px"} /> */}
            </CardTitle>
          </CardBody>
        </div>
      </Modal>
    </>
  ) : null;
};

export default LoaderModal;
