
import React from "react";
import { Container, CardTitle } from "reactstrap";
import ProfileInsuranceDetails from "../Profile/InsuranceProfile";

const AddInsuranceDetails: React.FC = () => {
    const back = () => {
        history.back()
    }

    const handleCopaymentAmount = (copaymentData: any) => {
        console.log("copaymentData", copaymentData)
      };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="flex flex-wrap  cursor-pointer ml-3" onClick={() => back()}>
                    <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                    <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
                </div>
                <Container className="containerInsurance">
                    <div className="card-heading mb-2">
                        <h5 className="font-size-25 profileFont mb-4 mt-5 text-center">
                            Insurance Information
                        </h5>
                    </div>
                    <div className="profileInsuranceContainer1">
                        <ProfileInsuranceDetails onCopaymentAmount={handleCopaymentAmount}/>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddInsuranceDetails;
