import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Therapist } from "src/models/Therapist";

interface GrantAccessModalProps {
  toggle: () => void;
  modal: boolean;
  therapist: Therapist;
  grantPermissionTherapist: (param: boolean) => void;
}

const GrantAccessModal: React.FC<GrantAccessModalProps> = ({ toggle, modal, therapist, grantPermissionTherapist }) => {
  const handleAccessGrantPermission = (data: boolean) => {
    grantPermissionTherapist(data);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{therapist.grantedAccessFileFolderPermission === undefined || therapist.grantedAccessFileFolderPermission === false ? "Grant access" : "Revoke access"} documents/Folders</ModalHeader>
        <ModalBody>
          <p className="px-2">
            {therapist.grantedAccessFileFolderPermission === undefined || therapist.grantedAccessFileFolderPermission === false ? (
              <p>Do you want to give access to documents and folders to {therapist.firstname} {" "} {therapist.lastname} ?.</p>
            ) : (
              <p>Do you want to revoke access to documents and folders to {therapist.firstname} {" "} {therapist.lastname} ?.</p>
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              therapist.grantedAccessFileFolderPermission === false || therapist.grantedAccessFileFolderPermission === undefined
                ? handleAccessGrantPermission(true)
                : handleAccessGrantPermission(false);
              toggle();
            }}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              toggle();

            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GrantAccessModal;
