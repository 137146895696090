import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, Input, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/services/AdminService";
import { AdminPermissions, User } from "src/models/User";
import Swal from "sweetalert2";
import "src/assets/css/admin.css";
import Spinner from "src/common/spinner/spinner";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import ViewUserPublicProfile from "../ViewUserPublicProfile";
import ProfileModal from "src/pages/Popup/AdminStatModals/profileModal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import generator from "generate-password";
import { SubscriberModel } from "src/models/Insurance";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";
import { InsuranceCompanyTags } from "src/models/InsuranceCompany";
import uploadIcon from "src/assets/images/uploadIcon.svg";
import { Client, PremiumStatus } from "src/models/Client";
import MatchModal from "src/pages/Popup/AdminStatModals/matchModal";

const UserManagementTable: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>({} as User);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [userCount, setUserCount] = useState(10);
  const [userRole, setUserRole] = useState({ role: "" });
  const [showModal, setShowModal] = useState(false);
  const [isView2, setIsView2] = useState<boolean>(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [modalOpenRemove, setModalOpenRemove] = useState(false);
  const [modalOpenNotes, setModalOpenNotes] = useState(false);
  const [editUser2, setEditUser2] = useState<boolean>(false);
  const [changeIsuaranceAdd, setChangeIsuaranceAdd] = useState<boolean>(false);
  const [subscriberDetails, setSubscriberDetails] = useState({ firstname: "", lastname: "", state: "", city: "", address1: "" });
  const [subscriberData, setSubscriberData] = useState<SubscriberModel>({} as SubscriberModel);
  const [selectedInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState("");
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [insuranceCard, selectInsuranceCard] = useState({ preview: "", preview2: "", raw: "" });
  const insuranceCardRef = useRef<any>();
  insuranceCardRef.current = insuranceCard;
  const insuranceCardBackRef = useRef<any>();
  const [insuranceCardBack, selectInsuranceCardBack] = useState({ preview: "", preview2: "", raw: "" });
  insuranceCardBackRef.current = insuranceCardBack;
  const [modalOpenMatch, setModalOpenMatch] = useState(false);
  const [clientData, setClientData] = useState<any>({} as Client);
  const [selectedAdminPermissionList, setSelectedAdminPermissionList] = useState<any[]>([]);
  const [permissionVlue, setPermissionVlue] = useState<AdminPermissions>({
    statistics: false,
    adminDashboard: false,
    viewEthnicity: false,
    viewExperienceTags: false,
    viewInsuranceCompanies: false,
    createAppointmentAdmin: false,
    viewProfessions: false,
    documents: false,
    accessManagement: false,
    viewHashTags: false,
    viewThemeImage: false,
    reportReviews: false,
    reviews: false,
    contactUs: false,
    articles: false,
    feedback: false,
    newsLetterEmails: false,
    marketingEmails: false,
    viewMeetingsAndRecordings: false,
    viewAllClients: false,
    manageClients: false,
    premiumClients: false,
    reminderSms: false,
    viewAllTherapists: false,
    manageTherapists: false,
    viewTherapistReviews: false,
    viewTherapistsSoapReviews: false,
    educationalDetails: false,
    licenseDetails: false,
    therapistRequests: false,
    availableBalances: false,
    adminApprovePayment: false,
    referralEarnings: false,
    clientRewards: false,
    notifications: false,
    sessionFeedback: false,
    approvalQueue: false,
    techTickets: false,
  });

  const statesList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "Ohio",
    "Montana",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington DC",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const handleStateChange = async (e: any) => {
    try {

      const stateValue = e?.target?.value;

      if(stateValue && statesList.includes(stateValue)){
        setUser({ ...user, state: stateValue});
        setSelectedInsuranceCompanyTag("");
        setSubscriberDetails({ ...subscriberDetails, state: stateValue});

        const data: any = {
          state: stateValue,
        }

        const res = await InsuranceCompanyService.getInsuaranceCompanyByState(data);

        if (res?.success && res?.data) {
          setSearchInsuranceCompanyTag(res?.data);
        } else {
          toast.error("Error retrieving insurance companies for state!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setSearchInsuranceCompanyTag([]);
        }

      } else {
        setUser({ ...user, state: ""});
        setSubscriberDetails({ ...subscriberDetails, state: ""});
        setSelectedInsuranceCompanyTag("");
        setSearchInsuranceCompanyTag([]);
      }

      
    } catch (error) {
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    
  };

  useEffect(() => {
    searchAdminUsers();
    // getAllInsuranceCompanies();
    setSelectedAdminPermissionList([]);
  }, []);

  const updatePermission = (key: keyof AdminPermissions, value: boolean) => {
    setPermissionVlue(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const pageInit = () =>{
    setPermissionVlue(prevState => ({
      ...prevState,
    statistics: false,
    adminDashboard: false,
    viewEthnicity: false,
    viewExperienceTags: false,
    viewInsuranceCompanies: false,
    createAppointmentAdmin: false,
    viewProfessions: false,
    documents: false,
    accessManagement: false,
    viewHashTags: false,
    viewThemeImage: false,
    reportReviews: false,
    reviews: false,
    contactUs: false,
    articles: false,
    feedback: false,
    newsLetterEmails: false,
    marketingEmails: false,
    viewMeetingsAndRecordings: false,
    viewAllClients: false,
    manageClients: false,
    premiumClients: false,
    reminderSms: false,
    viewAllTherapists: false,
    manageTherapists: false,
    viewTherapistReviews: false,
    viewTherapistsSoapReviews: false,
    educationalDetails: false,
    licenseDetails: false,
    therapistRequests: false,
    availableBalances: false,
    adminApprovePayment: false,
    referralEarnings: false,
    clientRewards: false,
    notifications: false,
    sessionFeedback: false,
    approvalQueue: false,
    techTickets: false,
    }));
  }

  const eventModalToggle = () => {
    setUser({} as User);
    setEditUser2(false);
    setChangeIsuaranceAdd(false);
    setSubscriberDetails({ firstname: "", lastname: "", state: "", city: "", address1: "" });
    setUserRole({ role: "" });
    setSubscriberData({} as SubscriberModel);
    setSelectedInsuranceCompanyTag("");
    setSearchInsuranceCompanyTag([]);
    setClientData({} as Client);
    setSelectedAdminPermissionList([]);
    setPermissionVlue({
      statistics: false,
      adminDashboard: false,
      viewEthnicity: false,
      viewExperienceTags: false,
      viewInsuranceCompanies: false,
      createAppointmentAdmin: false,
      viewProfessions: false,
      documents: false,
      accessManagement: false,
      viewHashTags: false,
      viewThemeImage: false,
      reportReviews: false,
      reviews: false,
      contactUs: false,
      articles: false,
      feedback: false,
      newsLetterEmails: false,
      marketingEmails: false,
      viewMeetingsAndRecordings: false,
      viewAllClients: false,
      manageClients: false,
      premiumClients: false,
      reminderSms: false,
      viewAllTherapists: false,
      manageTherapists: false,
      viewTherapistReviews: false,
      viewTherapistsSoapReviews: false,
      educationalDetails: false,
      licenseDetails: false,
      therapistRequests: false,
      availableBalances: false,
      adminApprovePayment: false,
      referralEarnings: false,
      clientRewards: false,
      notifications: false,
      sessionFeedback: false,
      approvalQueue: false,
      techTickets: false,
    });

    selectInsuranceCard({ preview: "", preview2: "", raw: "" });
    insuranceCardRef.current = { preview: "", preview2: "", raw: "" };

    selectInsuranceCardBack({ preview: "", preview2: "", raw: "" });
    insuranceCardBackRef.current = { preview: "", preview2: "", raw: "" };

    setShowModal(!showModal);
  };

  const editUser = (adminUser: any) => {
    setEditUserPermission(adminUser);
    setUserRole({ ...userRole, role: adminUser?.role ?? "" });
    setUser(adminUser);
    setEditUser2(true);
    setShowModal(!showModal);
  };

  const searchAdminUsers = () => {
    setIsLoading(true);

    AdminService.getAllAdminUsers(limit, 0).then(res => {
      if (res.success) {
        setAdminUsers(res.data);
        setUserCount(res.data.length);
        setOffset(0);
      }

      setIsLoading(false);
    });
  };

  const deleteUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.deleteUser(userId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Admin user deleted!",
                confirmButtonColor: "#FD7F00",
              });
              const updatedUserList = adminUsers.filter((user: User) => user._id !== userId);
              setAdminUsers(updatedUserList);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete the adminuser.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const seeMore = () => {
    const updatedSkip = offset + 10;

    setOffset(updatedSkip);

    AdminService.getAllAdminUsers(10, updatedSkip).then(res => {
      setIsLoading(true);

      if (res.success) {
        res.data.map((user: any) => {
          setAdminUsers(adminUsers => [...adminUsers, user]);
        });
      }

      setIsLoading(false);
    });
  };

  const hideAndShow2 = () => {
    setIsView2(!isView2);
  };

  const autoGenarate = () => {
    const password = generator.generate({
      length: 6,
      numbers: true,
    });

    setUser({ ...user, password: password });
  };

  const toggleModalMatch = () => {
    setModalOpenMatch(!modalOpenMatch);
  };

  const handleCreateUser = async () => {
    const phoneno = /^\+1[0-9]{10}$/;
    if (!user?.email) {
      toast.error("Email is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.firstname) {
      toast.error("First name is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.lastname) {
      toast.error("Last name is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.primaryPhone) {
      toast.error("Primary Phone is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userRole?.role) {
      toast.error("User role is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userRole?.role == "CLIENT" && (!user?.state || !statesList.includes(user?.state))) {
      toast.error("Please select valid State.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.password) {
      toast.error("Password is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!subscriberData?.memberId && changeIsuaranceAdd) {
      toast.error("Member id is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!selectedInsuranceCompanyTag && changeIsuaranceAdd) {
      toast.error("Organization Name id is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (changeIsuaranceAdd && (!subscriberDetails?.state || !statesList.includes(subscriberDetails?.state))) {
      toast.error("Please add state for insurance.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else {
      const data: any = {
        email: user.email?.toLowerCase().trim(),
        primaryPhone: user.primaryPhone,
        firstname: user.firstname,
        lastname: user.lastname,
        gender: user.gender,
        username: user.username,
        password: user.password,
        role: userRole.role,
        message: user.message,
        premiumUser: changeIsuaranceAdd,
        adminPermissions: selectedAdminPermissionList,
      };

      if(userRole?.role == "CLIENT"){
        data.state = user?.state;
      }

      setIsLoading(true);

      const res = await AdminService.adminCreateUser(data);

      if (res?.success && res?.data) {
        toast.success("User created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        searchAdminUsers();

        if (changeIsuaranceAdd) {
          setClientData(res.data);
          const userId = res.data._id;
          updateTherapistDetails(userId);
        } else {
          setShowModal(false);
          setEditUser2(false);
          setChangeIsuaranceAdd(false);
          setUser({ ...user, email: "", _id: "", primaryPhone: "", password: "", message: "", firstname: "", lastname: "", state: "" });
          setUserRole({ ...userRole, role: "" });
          setSubscriberData({} as SubscriberModel);
          setSelectedInsuranceCompanyTag("");
        }
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setEditUser2(false);
      }
    }
  };
  //------------------
  const updateTherapistDetails = (userId: any) => {
    if (!subscriberData?.memberId) {
      toast.error("Member id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!selectedInsuranceCompanyTag) {
      toast.error("Organization name id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const address: any = {
        state: subscriberDetails?.state,
      };

      const resive: any = {
        organizationName: selectedInsuranceCompanyTag,
      };

      const subcribersData: any = {
        memberId: subscriberData?.memberId,
        firstName: user?.firstname || subscriberDetails?.firstname,
        lastName: user?.lastname || subscriberDetails?.lastname,
        address: address,
      };

      const data: any = {
        clientId: userId,
        insuranceCompanyId: resive?.organizationName,
        subscriber: subcribersData,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: "none",
      };
      AdminService.AddInsurancePlanByAdmin(userId, data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setUser({ ...user, email: "", _id: "", primaryPhone: "", password: "", message: "", firstname: "", lastname: "", state: "" });
          setUserRole({ ...userRole, role: "" });
          setShowModal(false);
          setEditUser2(false);
          setChangeIsuaranceAdd(false);
          setSubscriberData({} as SubscriberModel);
          setSelectedInsuranceCompanyTag("");
          toggleModalMatch();
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };
  const handleUpdateUser = () => {
    const phoneno = /^\+1[0-9]{10}$/;
    if (!user?.email) {
      toast.error("Email is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userRole?.role || (userRole?.role != "SUB_ADMIN" && userRole?.role != "SUPER_ADMIN")) {
      toast.error("User role is required as Sub admin or Super admin", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!user?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else {
      const data = {
        userId: user._id,
        email: user.email,
        primaryPhone: user.primaryPhone,
        role: userRole.role,
        message: user.message,
        adminPermissions: selectedAdminPermissionList,
      };

      setIsLoading(true);

      AdminService.adminUpdateUser(data).then(res => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          
          searchAdminUsers();
          setUser({ ...user, email: "", _id: "", primaryPhone: "", password: "", message: "", state: "" });
          setShowModal(false);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleModalProfile = () => {
    setModalOpenProfile(!modalOpenProfile);
  };

  const toggleModalRemove = () => {
    setModalOpenRemove(!modalOpenRemove);
  };

  const toggleModalPassDueNOtes = () => {
    setModalOpenNotes(!modalOpenNotes);
  };

  function changeIsuaranceAddStatus(value: boolean) {
    setChangeIsuaranceAdd(!changeIsuaranceAdd);
  }

  // const getAllInsuranceCompanies = () => {
  //   InsuranceCompanyService.getAllInsuranceCompanyPublic().then((res: any) => {
  //     if (res.success) {
  //       setSearchInsuranceCompanyTag(res.data);
  //     } else {
  //       setSearchInsuranceCompanyTag([]);
  //     }
  //   });
  // };

  const handlePermissionsCheckBox = (pageName: any, e: any) => {
    if (selectedAdminPermissionList.includes(pageName)) {
      updatePermission(pageName, false);
      const filteredList = selectedAdminPermissionList.filter(item => item !== pageName);
      setSelectedAdminPermissionList(filteredList);
    } else {
      updatePermission(pageName, true);
      selectedAdminPermissionList.push(pageName);
    }
  };

  const setEditUserPermission = (adminUser: any) => {
    const permissionList = [];
    pageInit();
    setSelectedAdminPermissionList([]);
    if (adminUser.adminPermission.statistics) {
      updatePermission("statistics", true);
      permissionList.push("statistics");
    }
    if (adminUser.adminPermission.adminDashboard) {
      updatePermission("adminDashboard", true);
      permissionList.push("adminDashboard");
    }
    if (adminUser.adminPermission.viewEthnicity) {
      updatePermission("viewEthnicity", true);
      permissionList.push("viewEthnicity");
    }
    if (adminUser.adminPermission.viewExperienceTags) {
      updatePermission("viewExperienceTags", true);
      permissionList.push("viewExperienceTags");
    }
    if (adminUser.adminPermission.viewInsuranceCompanies) {
      updatePermission("viewInsuranceCompanies", true);
      permissionList.push("viewInsuranceCompanies");
    }
    if (adminUser.adminPermission.createAppointmentAdmin) {
      updatePermission("createAppointmentAdmin", true);
      permissionList.push("createAppointmentAdmin");
    }
    if (adminUser.adminPermission.viewProfessions) {
      updatePermission("viewProfessions", true);
      permissionList.push("viewProfessions");
    }
    if (adminUser.adminPermission.documents) {
      updatePermission("documents", true);
      permissionList.push("documents");
    }
    if (adminUser.adminPermission.accessManagement) {
      updatePermission("accessManagement", true);
      permissionList.push("accessManagement");
    }
    if (adminUser.adminPermission.viewHashTags) {
      updatePermission("viewHashTags", true);
      permissionList.push("viewHashTags");
    }
    if (adminUser.adminPermission.viewThemeImage) {
      updatePermission("viewThemeImage", true);
      permissionList.push("viewThemeImage");
    }
    if (adminUser.adminPermission.reportReviews) {
      updatePermission("reportReviews", true);
      permissionList.push("reportReviews");
    }
    if (adminUser.adminPermission.reviews) {
      updatePermission("reviews", true);
      permissionList.push("reviews");
    }
    if (adminUser.adminPermission.contactUs) {
      updatePermission("contactUs", true);
      permissionList.push("contactUs");
    }
    if (adminUser.adminPermission.articles) {
      updatePermission("articles", true);
      permissionList.push("articles");
    }
    if (adminUser.adminPermission.feedback) {
      updatePermission("feedback", true);
      permissionList.push("feedback");
    }
    if (adminUser.adminPermission.newsLetterEmails) {
      updatePermission("newsLetterEmails", true);
      permissionList.push("newsLetterEmails");
    }
    if (adminUser.adminPermission.marketingEmails) {
      updatePermission("marketingEmails", true);
      permissionList.push("marketingEmails");
    }
    if (adminUser.adminPermission.viewMeetingsAndRecordings) {
      updatePermission("viewMeetingsAndRecordings", true);
      permissionList.push("viewMeetingsAndRecordings");
    }
    if (adminUser.adminPermission.viewAllClients) {
      updatePermission("viewAllClients", true);
      permissionList.push("viewAllClients");
    }
    if (adminUser.adminPermission.manageClients) {
      updatePermission("manageClients", true);
      permissionList.push("manageClients");
    }
    if (adminUser.adminPermission.premiumClients) {
      updatePermission("premiumClients", true);
      permissionList.push("premiumClients");
    }
    if (adminUser.adminPermission.reminderSms) {
      updatePermission("reminderSms", true);
      permissionList.push("reminderSms");
    }
    if (adminUser.adminPermission.viewAllTherapists) {
      updatePermission("viewAllTherapists", true);
      permissionList.push("viewAllTherapists");
    }
    if (adminUser.adminPermission.manageTherapists) {
      updatePermission("manageTherapists", true);
      permissionList.push("manageTherapists");
    }
    if (adminUser.adminPermission.viewTherapistReviews) {
      updatePermission("viewTherapistReviews", true);
      permissionList.push("viewTherapistReviews");
    }
    if (adminUser.adminPermission.viewTherapistsSoapReviews) {
      updatePermission("viewTherapistsSoapReviews", true);
      permissionList.push("viewTherapistsSoapReviews");
    }
    if (adminUser.adminPermission.educationalDetails) {
      updatePermission("educationalDetails", true);
      permissionList.push("educationalDetails");
    }
    if (adminUser.adminPermission.licenseDetails) {
      updatePermission("licenseDetails", true);
      permissionList.push("licenseDetails");
    }
    if (adminUser.adminPermission.therapistRequests) {
      updatePermission("therapistRequests", true);
      permissionList.push("therapistRequests");
    }
    if (adminUser.adminPermission.availableBalances) {
      updatePermission("availableBalances", true);
      permissionList.push("availableBalances");
    }
    if (adminUser.adminPermission.referralEarnings) {
      updatePermission("referralEarnings", true);
      permissionList.push("referralEarnings");
    }
    if (adminUser.adminPermission.clientRewards) {
      updatePermission("clientRewards", true);
      permissionList.push("clientRewards");
    }
    if (adminUser.adminPermission.adminApprovePayment) {
      updatePermission("adminApprovePayment", true);
      permissionList.push("adminApprovePayment");
    }
    if (adminUser.adminPermission.notifications) {
      updatePermission("notifications", true);
      permissionList.push("notifications");
    }
    if (adminUser.adminPermission.sessionFeedback) {
      updatePermission("sessionFeedback", true);
      permissionList.push("sessionFeedback");
    }
    if (adminUser.adminPermission.approvalQueue) {
      updatePermission("approvalQueue", true);
      permissionList.push("approvalQueue");
    }
    if (adminUser.adminPermission.techTickets) {
      updatePermission("techTickets", true);
      permissionList.push("techTickets");
    }
    setSelectedAdminPermissionList(permissionList);
  };

  const selectInsuranceFile = (e: any) => {
    if (e.target.files.length) {
      selectInsuranceCard({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };
  const InsuranceCardFrontFiles = () => {
    switch (insuranceCardRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCard({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const selectInsuranceBackFile = (e: any) => {
    if (e.target.files.length) {
      selectInsuranceCardBack({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const InsuranceCardBackFiles = () => {
    switch (insuranceCardBackRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCardBack({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Card className="overflow-auto card">
          <CardBody>
            <Row onClick={isView2 ? hideAndShow2 : hideAndShow2} className={`${isView2 ? "cursor-p" : "cursor-p"}`}>
              <Col sm={8} md={8} lg={8} xl={9} xxl={10} className="d-flex gap-2 col-4">
                <Col className="d-flex justify-content-center align-items-center " md={12} lg={12} xl={12} xxl={12}>
                  <h4 className="card-title " style={{ flex: 1 }}>
                    User Management
                  </h4>
                </Col>
              </Col>
              <Col sm={4} md={4} lg={4} xl={3} xxl={2} className="d-flex justify-content-end align-items-center gap-md-4 col-8">
                <div className="d-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow2}>
                  {!isView2 ? (
                    <Button
                      className="create-client-custom2 ml-sm-2 me-sm-3 me-1"
                      onClick={event => {
                        pageInit();
                        event.stopPropagation();
                        setShowModal(true);
                      }}
                    >
                      Create User
                    </Button>
                  ) : (
                    ""
                  )}
                  {isView2 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                </div>
              </Col>
            </Row>
            <Row>
              <div className={!isView2 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                <Col sm="12" className="mt10">
                  <div className="table-responsive" style={{ maxHeight: "235px", overflowY: "auto" }}>
                    {adminUsers !== undefined && adminUsers.length > 0 ? (
                      <>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Email</th>
                              <th scope="col">User Role</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {adminUsers &&
                              adminUsers.map((adminUser, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{adminUser?.email}</td>

                                  <td>{adminUser?.role == "SUPER_ADMIN" ? "Super Admin" : adminUser?.role == "SUB_ADMIN" ? "Sub Admin" : "-"}</td>
                                  <td>
                                    <div className="flex">
                                      <button className="btn btn-sm btn-warning m-2" onClick={() => editUser(adminUser)}>
                                        <i className="bx bx-edit"></i>
                                      </button>

                                      <button
                                        className="btn btn-sm btn-danger m-2"
                                        onClick={() => {
                                          deleteUser(adminUser?._id);
                                        }}
                                        title="Delete Admin User"
                                      >
                                        <i className="bx bx-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {userCount >= 10 && (
                          <>
                            <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                              See More
                            </button>
                          </>
                        )}

                        {userCount == 0 && <span>No more admin users available...</span>}
                      </>
                    ) : (
                      <>
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          <>
                            <div>No Admin Users</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <MatchModal isOpen={modalOpenMatch} toggle={toggleModalMatch} clientData={clientData} />

      <Modal isOpen={showModal} centered toggle={eventModalToggle} unmountOnClose={true} className="modal-lg" backdrop="static" keyboard={false}>
        {editUser2 ? <ModalHeader toggle={eventModalToggle}>Update User</ModalHeader> : <ModalHeader toggle={eventModalToggle}>Create User</ModalHeader>}

        <ModalBody className="awsome-area">
          <Row className="mb-4">
            <Col lg={2}>
              <label>Email</label>
            </Col>
            <Col lg={10}>
              <Input type="text" name="repeat" placeholder="Email" onChange={e => setUser({ ...user, email: e.target.value })} value={user.email || ""} />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg={2}>
              <label>First Name</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={e => setUser({ ...user, firstname: e.target.value })}
                value={user.firstname || ""}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg={2}>
              <label>Last Name</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={e => setUser({ ...user, lastname: e.target.value })}
                value={user.lastname || ""}
              />
            </Col>
          </Row>

          {userRole.role == "THERAPIST" && (
            <Row className="mb-4">
              <Col lg={2}>
                <label>User Name</label>
              </Col>
              <Col lg={10}>
                <Input
                  type="text"
                  name="username"
                  placeholder="User Name"
                  onChange={e => setUser({ ...user, username: e.target.value })}
                  value={user.username || ""}
                />
              </Col>
            </Row>
          )}

          <Row className="mb-4">
            <Col lg={2}>
              <label>Primary Phone</label>
            </Col>
            <Col lg={10}>
              <PhoneInput
                placeholder="Primary Phone "
                value={user?.primaryPhone}
                defaultCountry="US"
                onChange={(e: string) => setUser({ ...user, primaryPhone: e })}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg={2}>
              <label>Gender</label>
            </Col>
            <Col lg={10}>
              <Row>
                <Col style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadioss"
                      id="exampleRadios1"
                      value="Female"
                      defaultChecked={user?.gender == "Female"}
                      onChange={e => setUser({ ...user, gender: e.target.value })}
                    />

                    <label className="form-check-label" htmlFor="exampleRadios1">
                      Female
                    </label>
                  </div>

                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadioss"
                      id="exampleRadios2"
                      value="Male"
                      defaultChecked={user?.gender == "Male"}
                      onChange={e => setUser({ ...user, gender: e.target.value })}
                    />

                    <label className="form-check-label" htmlFor="exampleRadios2">
                      Male
                    </label>
                  </div>

                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadioss"
                      id="exampleRadios3"
                      value="NonBinary"
                      defaultChecked={user?.gender == "NonBinary"}
                      onChange={e => setUser({ ...user, gender: e.target.value })}
                    />

                    <label className="form-check-label" htmlFor="exampleRadios3">
                      Non Binary
                    </label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadioss"
                      id="exampleRadios4"
                      value="Other"
                      defaultChecked={user?.gender == "Other"}
                      onChange={e => setUser({ ...user, gender: e.target.value })}
                    />

                    <label className="form-check-label" htmlFor="exampleRadios4">
                      Other
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg={2}>
              <label>Role</label>
            </Col>
            <Col lg={10}>
              <Input
                type="select"
                name="select"
                placeholder="Select Role"
                onChange={e => setUserRole({ ...userRole, role: e.target.value })}
                value={userRole.role || user?.role || ""}
              >
                <option>Select Role</option>
                <option value="SUPER_ADMIN">Admin User</option>
                <option value="SUB_ADMIN">Sub Admin User</option>
                {!editUser2 && <option value="THERAPIST">Therapist</option>}
                {!editUser2 && <option value="CLIENT">Client</option>}
              </Input>
            </Col>
          </Row>

          {
            !editUser2 && userRole?.role == "CLIENT" && 
            <Row className="mb-4">
              <Col lg={2}>
                <label>State</label>
              </Col>
              <Col lg={10}>
                <Input
                  type="select"
                  name="state-select"
                  placeholder="Please Select Role"
                  value={user?.state ?? ""}
                  onChange={e => handleStateChange(e)}
                >
                  <option value={"Default Value"} key={"default"}>
                    Select State
                  </option>
                  {statesList.map((opt, index) => (
                    <option value={opt} key={index}>
                      {opt}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          }
          
          {userRole.role == "SUB_ADMIN" ? (
            <>
              <Row className="mb-4">
                <Col lg={2}>
                  <label>Set Permissions</label>
                </Col>
                <Col lg={10}>
                  <div key="admin-permissions" className="col-md-12 col-md-6">
                    <ul className="no-bullets">
                      <li>
                        <input type="checkbox" checked={permissionVlue.statistics} onClick={e => handlePermissionsCheckBox("statistics", e)} /> Statistic
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.adminDashboard} onClick={e => handlePermissionsCheckBox("adminDashboard", e)} /> Dashboard
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.viewEthnicity} onClick={e => handlePermissionsCheckBox("viewEthnicity", e)} /> View
                        Ethnicity Tags
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.viewExperienceTags} onClick={e => handlePermissionsCheckBox("viewExperienceTags", e)} /> View Experience Tags
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          checked={permissionVlue.viewInsuranceCompanies}
                          onClick={e => handlePermissionsCheckBox("viewInsuranceCompanies", e)}
                        /> Insurance Companies
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          checked={permissionVlue.createAppointmentAdmin}
                          onClick={e => handlePermissionsCheckBox("createAppointmentAdmin", e)}
                        /> Add Appointments
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.viewProfessions} onClick={e => handlePermissionsCheckBox("viewProfessions", e)} /> View Professions
                      </li>
                      <li>Documents</li>
                      <ul className="no-bullets">
                        <li>
                          <input type="checkbox" checked={permissionVlue.documents} onClick={e => handlePermissionsCheckBox("documents", e)} /> Documents
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.accessManagement}
                            onClick={e => handlePermissionsCheckBox("accessManagement", e)}
                          /> Document Access
                        </li>
                      </ul>
                      <li>
                        <input type="checkbox" checked={permissionVlue.viewHashTags} onClick={e => handlePermissionsCheckBox("viewHashTags", e)} /> View Hash Tags
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.viewThemeImage} onClick={e => handlePermissionsCheckBox("viewThemeImage", e)} /> View Theme Images
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.reportReviews} onClick={e => handlePermissionsCheckBox("reportReviews", e)} /> Report Reviews
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.reviews} onClick={e => handlePermissionsCheckBox("reviews", e)} /> Customer Reviews
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.contactUs} onClick={e => handlePermissionsCheckBox("contactUs", e)} /> Contact Requests
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.articles} onClick={e => handlePermissionsCheckBox("articles", e)} /> Articles
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.feedback} onClick={e => handlePermissionsCheckBox("feedback", e)} /> Customer Feedback
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.newsLetterEmails} onClick={e => handlePermissionsCheckBox("newsLetterEmails", e)} /> Newsletter Emails
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.marketingEmails} onClick={e => handlePermissionsCheckBox("marketingEmails", e)} /> Marketing Email
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          checked={permissionVlue.viewMeetingsAndRecordings}
                          onClick={e => handlePermissionsCheckBox("viewMeetingsAndRecordings", e)}
                        /> Meetings & Recordings
                      </li>
                      <li>Client</li>
                      <ul className="no-bullets">
                        <li>
                          <input type="checkbox" checked={permissionVlue.viewAllClients} onClick={e => handlePermissionsCheckBox("viewAllClients", e)} /> View Clients
                        </li>
                        <li>
                          <input type="checkbox" checked={permissionVlue.manageClients} onClick={e => handlePermissionsCheckBox("manageClients", e)} /> Manage Clients
                        </li>
                        <li>
                          <input type="checkbox" checked={permissionVlue.premiumClients} onClick={e => handlePermissionsCheckBox("premiumClients", e)} /> Premium Clients
                        </li>
                        {/* <li>
                          <input type="checkbox" checked={permissionVlue.reminderSms} onClick={e => handlePermissionsCheckBox("reminderSms", e)} /> Reminder
                          SMS
                        </li> */}
                      </ul>
                      <li>Therapist</li>
                      <ul className="no-bullets">
                        <li>
                          <input type="checkbox" checked={permissionVlue.viewAllTherapists} onClick={e => handlePermissionsCheckBox("viewAllTherapists", e)} /> View Therapists
                        </li>
                        <li>
                          <input type="checkbox" checked={permissionVlue.manageTherapists} onClick={e => handlePermissionsCheckBox("manageTherapists", e)} /> Manage Therapists
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.viewTherapistReviews}
                            onClick={e => handlePermissionsCheckBox("viewTherapistReviews", e)}
                          /> Manage Reviews
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.viewTherapistsSoapReviews}
                            onClick={e => handlePermissionsCheckBox("viewTherapistsSoapReviews", e)}
                          /> AI SOAP Reviews
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.educationalDetails}
                            onClick={e => handlePermissionsCheckBox("educationalDetails", e)}
                          /> Educational Details
                        </li>
                        <li>
                          <input type="checkbox" checked={permissionVlue.licenseDetails} onClick={e => handlePermissionsCheckBox("licenseDetails", e)} /> Licenses / Permits
                        </li>
                        <li>
                          <input type="checkbox" checked={permissionVlue.therapistRequests} onClick={e => handlePermissionsCheckBox("therapistRequests", e)} /> Therapist Requests
                        </li>
                      </ul>
                      <li>Payment</li>
                      <ul className="no-bullets">
                        <li>
                          <input type="checkbox" checked={permissionVlue.availableBalances} onClick={e => handlePermissionsCheckBox("availableBalances", e)} /> View Available Balances
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.adminApprovePayment}
                            onClick={e => handlePermissionsCheckBox("adminApprovePayment", e)}
                          /> Admin Approval
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.referralEarnings}
                            onClick={e => handlePermissionsCheckBox("referralEarnings", e)}
                          /> Referral Earnings
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.clientRewards}
                            onClick={e => handlePermissionsCheckBox("clientRewards", e)}
                          /> Client Rewards
                        </li>
                      </ul>
                      <li>Insurance Documents</li>
                      <ul className="no-bullets">
                        <li>
                          <input
                            type="checkbox"
                            checked={permissionVlue.approvalQueue}
                            onClick={e => handlePermissionsCheckBox("approvalQueue", e)}
                          /> Approval Queue
                        </li>
                      </ul>
                      <li>
                        <input type="checkbox" checked={permissionVlue.notifications} onClick={e => handlePermissionsCheckBox("notifications", e)} /> Notifications
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.sessionFeedback} onClick={e => handlePermissionsCheckBox("sessionFeedback", e)} /> Session-Feedback
                      </li>
                      <li>
                        <input type="checkbox" checked={permissionVlue.techTickets} onClick={e => handlePermissionsCheckBox("techTickets", e)} /> Tech Tickets
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <p></p>
          )}

          {/* ----------------------------------------------------------------------------------------------------------------------- */}

          {editUser2 ? (
            <></>
          ) : (
            <Row className="mb-4">
              <Col lg={2}>
                <label>Password</label>
              </Col>
              <Col lg={8}>
                <Input
                  type="text"
                  name="repeat"
                  placeholder="Password"
                  onChange={e => setUser({ ...user, password: e.target.value })}
                  value={user.password || ""}
                />
              </Col>
              <Col lg={2}>
                <button
                  className="btn-md btn btn-info mr-2"
                  style={{ background: "#2bdfbb", borderColor: "#2bdfbb" }}
                  onClick={() => autoGenarate()}
                  title="Make Regular User"
                >
                  <i className="fa fa-undo"></i>
                </button>
              </Col>
            </Row>
          )}

          <Row className="mb-4">
            <Col lg={2}>
              <label>Message</label>
            </Col>
            <Col lg={10}>
              <Input type="text" name="repeat" placeholder="Message" onChange={e => setUser({ ...user, message: e.target.value })} value={user.message || ""} />
            </Col>
          </Row>
          {!editUser2 && userRole.role == "CLIENT" && (
            <>
              <Row className="mb-4">
                <Col lg={2}>
                  <label>Add Insuarance</label>
                </Col>
                <Col lg={10}>
                  <div className="form-check form-switch form-switch-lg">
                    <label className="form-check-label" htmlFor="newsletter">
                      <input
                        type="checkbox"
                        className="form-check-input cursor-pointer"
                        id="newsletter"
                        checked={changeIsuaranceAdd == true ? true : false}
                        onChange={e => {}}
                        onClick={() => {
                          changeIsuaranceAddStatus(changeIsuaranceAdd != null ? !changeIsuaranceAdd : true);
                        }}
                      />
                    </label>
                  </div>
                </Col>
              </Row>

              {changeIsuaranceAdd && (
                <>
                  <Row className="mb-4">
                    <Col lg={2}>
                      <label>Member Id</label>
                    </Col>
                    <Col lg={10}>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Member Id "
                        name="Member Id "
                        value={subscriberData?.memberId || ""}
                        onChange={e => setSubscriberData({ ...subscriberData, memberId: e.target.value })}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={2}>
                      <label>Organization Name</label>
                    </Col>
                    <Col lg={10}>
                      <select className="form-control " value={selectedInsuranceCompanyTag} onChange={e => setSelectedInsuranceCompanyTag(e.target.value)}>
                        <option>Select</option>
                        {searchInsuranceCompanyTag &&
                          searchInsuranceCompanyTag.map(opt => (
                            <option value={opt?._id} key={opt._id}>
                              {opt?.insuranceCompany}
                            </option>
                          ))}
                      </select>
                      {(!user?.state || !statesList.includes(user?.state)) && <p className="text-danger mt-2 mb-0">( Error : Please select state to see Organization Names )</p>}
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col lg={2}>
                      <label>State</label>
                    </Col>
                    <Col lg={10}>
                      <Input
                        disabled = {true}
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Please Select State"
                        name="State"
                        // defaultValue={user?.state || ""}
                        value={subscriberDetails?.state ?? ""}
                        // onChange={e => setSubscriberDetails({ ...subscriberDetails, state: e.target.value })}
                        required
                      />
                      {(!subscriberDetails?.state || !statesList.includes(subscriberDetails?.state)) && <p className="text-danger mt-2 mb-0">( Error : Please select state)</p>}
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="6">
                      {insuranceCardRef.current.preview ? (
                        <div>
                          {insuranceCardRef.current.preview && (
                            <label htmlFor="file-upload1" className="file-upload m-auto">
                              <span className="text-primary mt-5 m-auto cursor-pointer">Change Your insurance card front picture</span>

                              <input
                                id="file-upload1"
                                type="file"
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                onChange={selectInsuranceFile}
                                multiple
                              />
                            </label>
                          )}
                          <div>
                            <div className="flex">
                              <div style={{ fontSize: "62px" }}>{InsuranceCardFrontFiles()}</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {!insuranceCardRef.current.preview && (
                        <label
                          htmlFor="file-upload1"
                          style={{ border: "1px dashed" }}
                          className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                        >
                          <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                            <img src={uploadIcon} />
                            <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card front picture</p>
                          </div>

                          <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                          <input
                            id="file-upload1"
                            type="file"
                            style={{ display: "none" }}
                            className="file_upload_input mb-4"
                            accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                            onChange={selectInsuranceFile}
                            multiple
                          />
                        </label>
                      )}
                    </Col>

                    <Col xl="6">
                      {insuranceCardBackRef.current.preview ? (
                        <div>
                          {insuranceCardBackRef.current.preview && (
                            <label htmlFor="file-upload2" className="file-upload m-auto">
                              <span className="text-primary mt-5 m-auto cursor-pointer">Change Your insurance card back picture</span>

                              <input
                                id="file-upload2"
                                type="file"
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                onChange={selectInsuranceBackFile}
                                multiple
                              />
                            </label>
                          )}
                          <div>
                            <div className="flex">
                              <div style={{ fontSize: "62px" }}>{InsuranceCardBackFiles()}</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {!insuranceCardBackRef.current.preview && (
                        <label
                          htmlFor="file-upload3"
                          style={{ border: "1px dashed" }}
                          className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                        >
                          <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                            <img src={uploadIcon} />
                            <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card back picture </p>
                          </div>
                          <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                          <input
                            id="file-upload3"
                            type="file"
                            style={{ display: "none" }}
                            className="file_upload_input mb-4"
                            accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                            onChange={selectInsuranceBackFile}
                            multiple
                          />
                        </label>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          <Row>
            <Col>
              {editUser2 ? (
                <button className="btn btn-warning float-right mr-2" onClick={handleUpdateUser}>
                  Update
                </button>
              ) : (
                <button className="btn btn-warning float-right mr-2" onClick={handleCreateUser}>
                  Create
                </button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {modalIsOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <ViewUserPublicProfile clientId={""} />
          </div>
        </div>
      )}
      <ProfileModal isOpen={modalOpenProfile} toggle={toggleModalProfile} clientId={""} />
      {/* <RemoveModal isOpen={modalOpenRemove} toggle={toggleModalRemove} /> */}
      {/* <PassDueNotesModal isOpen={modalOpenNotes} toggle={toggleModalPassDueNOtes} /> */}
    </React.Fragment>
  );
};

export default UserManagementTable;
