import React, { useState, useEffect, useMemo } from "react";
import { Card, Col, Row } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  Sleep,
  NutritionalStatus,
  OtherBiologicalFunction,
  SexualActivityWith,
  DepressiveSymptoms,
} from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";
import moment from "moment";
import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";

const NewFormThree = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;
  const [sleepComment, setSleepComment] = useState("");
  const [ntritionalStatusComment, setNtritionalStatusComment] = useState("");
  const [sexualActivityComment, setsSxualActivityComment] = useState("");
  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress: {
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    biologicalFunction: {
      sleep: { sleepStatus: {}, comment: "" },
      nutritionalStatus: { nutritionalStatus: {}, comment: "" },
      otherBiologicalFunction: {
        amenorrhea: "",
        encopresis: "",
        increased: "",
        decreased: "",
        other: "",
        enuresis: "",
      },
      sexualActivity: {
        sexuallyActive: { sexuallyActiveness: null, activeWith: "" },
        protectionAgainstHepatitisHiv: { protection: null, how: "" },
        protectionAgainstPregnancy: { protection: null, how: "" },
        atRiskBehavior: { risk: null, describe: "" },
        otherSymtoms: "",
        comments: "",
      },
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        biologicalFunction: data?.biologicalFunction,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   biologicalFunction: data?.biologicalFunction,
      // };
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));

      setsSxualActivityComment(data?.biologicalFunction.sexualActivity?.otherSymtoms);
      setSleepComment(data?.biologicalFunction?.sleep?.comment);
      setNtritionalStatusComment(data?.biologicalFunction.nutritionalStatus?.comment);
    }
  }, [memoizedUpdateValues]);

  const handleUpdateData = () => {
    const updateDetails = {
      biologicalFunction: {
        ...formData?.biologicalFunction,
        sleep: {
          ...formData?.biologicalFunction?.sleep,
          comment: sleepComment,
        },
        nutritionalStatus: {
          ...formData?.biologicalFunction?.nutritionalStatus,
          comment: ntritionalStatusComment,
        },
        sexualActivity: {
          ...formData?.biologicalFunction?.sexualActivity,
          otherSymtoms: sexualActivityComment,
        },
      },
      formHeader: {
        ...formData?.formHeader,
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  const updateSleepStatus = (field: string, value: boolean | string) => {
    if (field !== "noDifficulties") {
      setFormData({
        ...formData,
        biologicalFunction: {
          ...formData.biologicalFunction,
          sleep: {
            ...formData?.biologicalFunction?.sleep,
            sleepStatus: {
              ...formData?.biologicalFunction?.sleep?.sleepStatus,
              noDifficulties: "",
            },
          },
        },
      });
    }
    setFormData({
      ...formData,
      biologicalFunction: {
        ...formData.biologicalFunction,
        sleep: {
          ...formData?.biologicalFunction?.sleep,
          sleepStatus: {
            ...formData?.biologicalFunction?.sleep?.sleepStatus,
            increased: field === "increased" ? (value ? Sleep.increased : "") : formData?.biologicalFunction?.sleep?.sleepStatus?.increased,
            decreased: field === "decreased" ? (value ? Sleep.decreased : "") : formData?.biologicalFunction?.sleep?.sleepStatus?.decreased,
            restless: field === "restless" ? (value ? Sleep.restless : "") : formData?.biologicalFunction?.sleep?.sleepStatus?.restless,
            difficultyFallingaSleep:
              field === "difficultyFallingaSleep"
                ? value
                  ? Sleep.difficultyFallingaSleep
                  : ""
                : formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyFallingaSleep,
            difficultyRemainingAsleep:
              field === "difficultyRemainingAsleep"
                ? value
                  ? Sleep.difficultyRemainingAsleep
                  : ""
                : formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyRemainingAsleep,
            earlyMorningAwakening:
              field === "earlyMorningAwakening"
                ? value
                  ? Sleep.earlyMorningAwakening
                  : ""
                : formData?.biologicalFunction?.sleep?.sleepStatus?.earlyMorningAwakening,
          },
        },
      },
    });
  };
  const updateNutritionalStatus = (field: string, value: boolean | string) => {
    if (field !== "noDifficulties") {
      setFormData({
        ...formData,
        biologicalFunction: {
          ...formData.biologicalFunction,
          nutritionalStatus: {
            ...formData?.biologicalFunction?.nutritionalStatus,
            nutritionalStatus: {
              ...formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus,
              noDifficulties: value ? NutritionalStatus.noDifficulties : "",
            },
          },
        },
      });
    }
    setFormData({
      ...formData,
      biologicalFunction: {
        ...formData.biologicalFunction,
        nutritionalStatus: {
          ...formData?.biologicalFunction?.nutritionalStatus,
          nutritionalStatus: {
            ...formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus,
            increasedAppetite:
              field === "increasedAppetite"
                ? value
                  ? NutritionalStatus.increasedAppetite
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.increasedAppetite,
            weightloss:
              field === "weightloss"
                ? value
                  ? NutritionalStatus.weightloss
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightloss,
            bingeing:
              field === "bingeing" ? (value ? NutritionalStatus.bingeing : "") : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.bingeing,
            dentalProblems:
              field === "dentalProblems"
                ? value
                  ? NutritionalStatus.dentalProblems
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.dentalProblems,
            decreasedAppetite:
              field === "decreasedAppetite"
                ? value
                  ? NutritionalStatus.decreasedAppetite
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.decreasedAppetite,
            eatingdisorder:
              field === "eatingdisorder"
                ? value
                  ? NutritionalStatus.eatingdisorder
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.eatingdisorder,
            foodAllergies:
              field === "foodAllergies"
                ? value
                  ? NutritionalStatus.foodAllergies
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.foodAllergies,
            weightgain:
              field === "weightgain"
                ? value
                  ? NutritionalStatus.weightgain
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightgain,
            noDifficulties:
              field === "noDifficulties"
                ? value
                  ? NutritionalStatus.noDifficulties
                  : ""
                : formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.noDifficulties,
          },
        },
      },
    });
  };
  const updateOtherBiologicalFunction = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      biologicalFunction: {
        ...formData.biologicalFunction,
        otherBiologicalFunction: {
          ...formData?.biologicalFunction?.otherBiologicalFunction,
          amenorrhea:
            field === "amenorrhea" ? (value ? OtherBiologicalFunction.amenorrhea : "") : formData?.biologicalFunction?.otherBiologicalFunction?.amenorrhea,
          encopresis:
            field === "encopresis" ? (value ? OtherBiologicalFunction.encopresis : "") : formData?.biologicalFunction?.otherBiologicalFunction?.encopresis,
          increased:
            field === "increased" ? (value ? OtherBiologicalFunction.increased : "") : formData?.biologicalFunction?.otherBiologicalFunction?.increased,
          decreased:
            field === "decreased" ? (value ? OtherBiologicalFunction.decreased : "") : formData?.biologicalFunction?.otherBiologicalFunction?.decreased,
          other: field === "other" ? (value ? OtherBiologicalFunction.other : "") : formData?.biologicalFunction?.otherBiologicalFunction?.other,
          enuresis: field === "enuresis" ? (value ? OtherBiologicalFunction.enuresis : "") : formData?.biologicalFunction?.otherBiologicalFunction?.enuresis,
        },
      },
    });
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress: {
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress: {
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress: {
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress: {
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress: {
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                BIOLOGICAL FUNCTIONS
              </h5>
            </Card>
            <Card className="match-card-body p-4">
              <Card className="match-card-body p-3">
                <label className="fw-bold">
                  <u>Sleep:</u>
                </label>

                <Row style={{ marginBottom: "0.5rem" }}>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.increased}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.increased === Sleep.increased || false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.increased === Sleep.increased;
                        updateSleepStatus("increased", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Increased{" "}
                    </label>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.decreased}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.decreased === Sleep.decreased || false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.decreased === Sleep.decreased;
                        updateSleepStatus("decreased", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Decreased{" "}
                    </label>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.restless}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.restless === Sleep.restless || false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.restless === Sleep.restless;
                        updateSleepStatus("restless", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Restless{" "}
                    </label>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.difficultyFallingaSleep}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyFallingaSleep === Sleep.difficultyFallingaSleep ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyFallingaSleep === Sleep.difficultyFallingaSleep;
                        updateSleepStatus("difficultyFallingaSleep", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty falling asleep{" "}
                    </label>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.difficultyRemainingAsleep}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyRemainingAsleep === Sleep.difficultyRemainingAsleep ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.difficultyRemainingAsleep === Sleep.difficultyRemainingAsleep;
                        updateSleepStatus("difficultyRemainingAsleep", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty remaining asleep{" "}
                    </label>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.earlyMorningAwakening}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.earlyMorningAwakening === Sleep.earlyMorningAwakening ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.earlyMorningAwakening === Sleep.earlyMorningAwakening;
                        updateSleepStatus("earlyMorningAwakening", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Early morning awakening{" "}
                    </label>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Sleep.noDifficulties}
                      checked={formData?.biologicalFunction?.sleep?.sleepStatus?.noDifficulties === Sleep.noDifficulties ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.sleep?.sleepStatus?.noDifficulties === Sleep.noDifficulties;
                        setFormData({
                          ...formData,
                          biologicalFunction: {
                            ...formData.biologicalFunction,
                            sleep: {
                              ...formData?.biologicalFunction?.sleep,
                              sleepStatus: {
                                ...formData?.biologicalFunction?.sleep?.sleepStatus,
                                increased: "",
                                decreased: "",
                                restless: "",
                                difficultyFallingaSleep: "",
                                difficultyRemainingAsleep: "",
                                earlyMorningAwakening: "",
                                noDifficulties: !result ? Sleep.noDifficulties : "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      No difficulties{" "}
                    </label>
                  </div>
                  <div className="col-lg-12 d-flex flex-col">
                    <div style={{ margin: "0.2rem" }}>
                      <h6 style={{ fontWeight: "500", display: "inline" }}>COMMENTS *:</h6>
                    </div>
                    <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                      <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                        <CKEditor
                          editor={Editor}
                          data={sleepComment ?? ""}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            setSleepComment(data);
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>

              <Card className="match-card-body p-3">
                <Row style={{ marginBottom: "0.5rem" }}>
                  <label className="fw-bold">
                    <u>Nutritional Status:</u>
                  </label>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.increasedAppetite}
                      checked={
                        formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.increasedAppetite === NutritionalStatus.increasedAppetite ?? false
                      }
                      onClick={() => {
                        const result =
                          formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.increasedAppetite === NutritionalStatus.increasedAppetite;
                        updateNutritionalStatus("increasedAppetite", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Increased Appetite/Food Intake{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.weightloss}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightloss === NutritionalStatus.weightloss ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightloss === NutritionalStatus.weightloss;
                        updateNutritionalStatus("weightloss", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Weight loss of 10lbs or more in the last 3 months{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.bingeing}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.bingeing === NutritionalStatus.bingeing ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.bingeing === NutritionalStatus.bingeing;
                        updateNutritionalStatus("bingeing", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Bingeing{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.dentalProblems}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.dentalProblems === NutritionalStatus.dentalProblems ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.dentalProblems === NutritionalStatus.dentalProblems;
                        updateNutritionalStatus("dentalProblems", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Dental Problems{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.decreasedAppetite}
                      checked={
                        formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.decreasedAppetite === NutritionalStatus.decreasedAppetite ?? false
                      }
                      onClick={() => {
                        const result =
                          formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.decreasedAppetite === NutritionalStatus.decreasedAppetite;
                        updateNutritionalStatus("decreasedAppetite", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Decreased Appetite/Food Intake{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.eatingdisorder}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.eatingdisorder === NutritionalStatus.eatingdisorder ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.eatingdisorder === NutritionalStatus.eatingdisorder;
                        updateNutritionalStatus("eatingdisorder", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Eating disorder-related habits and/or behaviors{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.foodAllergies}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.foodAllergies === NutritionalStatus.foodAllergies ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.foodAllergies === NutritionalStatus.foodAllergies;
                        updateNutritionalStatus("foodAllergies", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Food Allergies{" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.weightgain}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightgain === NutritionalStatus.weightgain ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.weightgain === NutritionalStatus.weightgain;
                        updateNutritionalStatus("weightgain", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Weight gain of 10lbs or more in the last 3 months
                    </label>
                  </div>

                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={NutritionalStatus.noDifficulties}
                      checked={formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.noDifficulties === NutritionalStatus.noDifficulties ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus?.noDifficulties === NutritionalStatus.noDifficulties;
                        setFormData({
                          ...formData,
                          biologicalFunction: {
                            ...formData.biologicalFunction,
                            nutritionalStatus: {
                              ...formData?.biologicalFunction?.nutritionalStatus,
                              nutritionalStatus: {
                                ...formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus,
                                increasedAppetite: "",
                                weightloss: "",
                                bingeing: "",
                                dentalProblems: "",
                                decreasedAppetite: "",
                                eatingdisorder: "",
                                foodAllergies: "",
                                weightgain: "",
                                noDifficulties: !result ? NutritionalStatus.noDifficulties : "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      No Difficulties{" "}
                    </label>
                  </div>
                  <div className="col-lg-12 d-flex flex-col">
                    <div style={{ margin: "0.2rem" }}>
                      <h6 style={{ fontWeight: "500", display: "inline" }}>COMMENTS *:</h6>
                    </div>
                    <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                      <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                        <CKEditor
                          editor={Editor}
                          data={ntritionalStatusComment ?? ""}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            // setFormData({
                            //   ...formData,
                            //   biologicalFunction: {
                            //     ...formData.biologicalFunction,
                            //     nutritionalStatus: {
                            //       ...formData?.biologicalFunction?.nutritionalStatus,
                            //       nutritionalStatus: formData?.biologicalFunction?.nutritionalStatus?.nutritionalStatus,
                            //       comment: data,
                            //     },
                            //   },
                            // });
                            setNtritionalStatusComment(data);
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>

              <Card className="match-card-body p-3">
                <Row style={{ marginBottom: "0.5rem" }}>
                  <label className="fw-bold">
                    {" "}
                    <u>Other Biological Functions:</u>
                  </label>

                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={OtherBiologicalFunction.amenorrhea}
                      checked={formData?.biologicalFunction?.otherBiologicalFunction?.amenorrhea === OtherBiologicalFunction.amenorrhea ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.otherBiologicalFunction?.amenorrhea === OtherBiologicalFunction.amenorrhea;
                        updateOtherBiologicalFunction("amenorrhea", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Amenorrhea (cessation of the period){" "}
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={OtherBiologicalFunction.encopresis}
                      checked={formData?.biologicalFunction?.otherBiologicalFunction?.encopresis === OtherBiologicalFunction.encopresis ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.otherBiologicalFunction?.encopresis === OtherBiologicalFunction.encopresis;
                        updateOtherBiologicalFunction("encopresis", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Encopresis (fecal incontinence)
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={OtherBiologicalFunction.increased}
                      checked={formData?.biologicalFunction?.otherBiologicalFunction?.increased === OtherBiologicalFunction.increased ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.otherBiologicalFunction?.increased === OtherBiologicalFunction.increased;
                        updateOtherBiologicalFunction("increased", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Increased libido
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={OtherBiologicalFunction.decreased}
                      checked={formData?.biologicalFunction?.otherBiologicalFunction?.decreased === OtherBiologicalFunction.decreased ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.otherBiologicalFunction?.decreased === OtherBiologicalFunction.decreased;
                        updateOtherBiologicalFunction("decreased", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Decreased libido
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={OtherBiologicalFunction.other}
                      checked={formData?.biologicalFunction?.otherBiologicalFunction?.other === OtherBiologicalFunction.other ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.otherBiologicalFunction?.other === OtherBiologicalFunction.other;
                        updateOtherBiologicalFunction("other", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Other:
                    </label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem", minWidth: "1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={OtherBiologicalFunction.enuresis}
                      checked={formData?.biologicalFunction?.otherBiologicalFunction?.enuresis === OtherBiologicalFunction.enuresis ?? false}
                      onClick={() => {
                        const result = formData?.biologicalFunction?.otherBiologicalFunction?.enuresis === OtherBiologicalFunction.enuresis;
                        updateOtherBiologicalFunction("enuresis", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Enuresis (urinary incontinence)
                    </label>
                  </div>
                </Row>
              </Card>

              {/*activitiesss */}
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-body p-3">
                    <label className="fw-bold">
                      {" "}
                      <u>Sexual Activity:</u>
                    </label>
                    <Row>
                      <Col xl={6}>
                        <div className="col-auto  d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            // name="sexualAbuse"

                            checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness === false}
                            onClick={() => {
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                biologicalFunction: {
                                  ...formData.biologicalFunction,
                                  sexualActivity: {
                                    ...formData?.biologicalFunction?.sexualActivity,
                                    sexuallyActive: {
                                      ...formData?.biologicalFunction?.sexualActivity?.sexuallyActive,

                                      sexuallyActiveness:
                                        formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness === false ? null : false,
                                      activeWith: "",
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Not Sexually Active
                          </label>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="row">
                          <div className="col-auto d-flex" style={{ paddingInlineEnd: "inherit" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              // name="sexualAbuse"

                              checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness === true}
                              onClick={() => {
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  biologicalFunction: {
                                    ...formData.biologicalFunction,
                                    sexualActivity: {
                                      ...formData?.biologicalFunction?.sexualActivity,
                                      sexuallyActive: {
                                        ...formData?.biologicalFunction?.sexualActivity?.sexuallyActive,

                                        sexuallyActiveness:
                                          formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness === true ? null : true,
                                      },
                                    },
                                  },
                                }));
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Sexually active
                            </label>
                          </div>
                        </div>
                      </Col>
                      {/* <Col xl={1}></Col> */}
                      <Col xl={6}>
                        <div className="col">
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div>
                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                  If active, with
                                </h6>
                              </div>

                              <div className="d-flex ">
                                <div className="col-4">
                                  <div className="d-flex flex-row justify-content-around align-items-center">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      disabled={!formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness}
                                      value={SexualActivityWith.men}
                                      checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.men ?? false}
                                      onClick={() => {
                                        setFormData((formData: Partial<DigitalForm>) => ({
                                          ...formData,
                                          biologicalFunction: {
                                            ...formData.biologicalFunction,
                                            sexualActivity: {
                                              ...formData?.biologicalFunction?.sexualActivity,
                                              sexuallyActive: {
                                                ...formData?.biologicalFunction?.sexualActivity?.sexuallyActive,
                                                activeWith:
                                                  formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.men
                                                    ? ""
                                                    : SexualActivityWith.men,
                                              },
                                            },
                                          },
                                        }));
                                      }}
                                    />
                                    <span>men</span>
                                  </div>
                                </div>

                                <div className="col-4">
                                  <div className="d-flex flex-row justify-content-around align-items-center">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      // name="sexualAbuse"
                                      value={SexualActivityWith.women}
                                      disabled={!formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness}
                                      checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.women ?? false}
                                      onClick={e => {
                                        setFormData((formData: Partial<DigitalForm>) => ({
                                          ...formData,
                                          biologicalFunction: {
                                            ...formData.biologicalFunction,
                                            sexualActivity: {
                                              ...formData?.biologicalFunction?.sexualActivity,
                                              sexuallyActive: {
                                                ...formData?.biologicalFunction?.sexualActivity?.sexuallyActive,
                                                activeWith:
                                                  formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.women
                                                    ? ""
                                                    : SexualActivityWith.women,
                                              },
                                            },
                                          },
                                        }));
                                      }}
                                    />
                                    <span>women</span>
                                  </div>
                                </div>

                                <div className="col-4">
                                  <div className="d-flex flex-row justify-content-around align-items-center">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      disabled={!formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.sexuallyActiveness}
                                      // name="sexualAbuse"
                                      value={SexualActivityWith.both}
                                      checked={formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.both ?? false}
                                      onClick={e => {
                                        setFormData((formData: Partial<DigitalForm>) => ({
                                          ...formData,
                                          biologicalFunction: {
                                            ...formData.biologicalFunction,
                                            sexualActivity: {
                                              ...formData?.biologicalFunction?.sexualActivity,
                                              sexuallyActive: {
                                                ...formData?.biologicalFunction?.sexualActivity?.sexuallyActive,
                                                activeWith:
                                                  formData?.biologicalFunction?.sexualActivity?.sexuallyActive?.activeWith === SexualActivityWith.both
                                                    ? ""
                                                    : SexualActivityWith.both,
                                              },
                                            },
                                          },
                                        }));
                                      }}
                                    />
                                    <span>both</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Do you protect yourself against the risk of hepatitis, STD’s and HIV/Aids?
                        </h6>
                      </Col>
                      <Col xl={6}>
                        <div className="col-auto  d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            // name="physicalAbuse"
                            // value={ProtectionAgainstHepatitisHiv.yes}
                            checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection === true ?? false}
                            onClick={e => {
                              const result = formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection === true;
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                biologicalFunction: {
                                  ...formData.biologicalFunction,
                                  sexualActivity: {
                                    ...formData?.biologicalFunction?.sexualActivity,
                                    protectionAgainstHepatitisHiv: {
                                      ...formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv,
                                      protection: result ? null : true,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="row">
                          <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              // name="physicalAbuse"
                              // value={ProtectionAgainstHepatitisHiv.no}
                              checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection === false}
                              onClick={e => {
                                const result = formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection === false;
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  biologicalFunction: {
                                    ...formData.biologicalFunction,
                                    sexualActivity: {
                                      ...formData?.biologicalFunction?.sexualActivity,
                                      protectionAgainstHepatitisHiv: {
                                        ...formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv,
                                        protection: result ? null : false,
                                        how: "",
                                      },
                                    },
                                  },
                                }));
                              }}
                            />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col-auto">
                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                  NO
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={12}>
                        <div className="col">
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col-auto">
                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                  If yes, how:
                                </h6>
                              </div>
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  disabled={!formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.protection}
                                  value={formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv?.how ?? ""}
                                  type="text"
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      biologicalFunction: {
                                        ...formData.biologicalFunction,
                                        sexualActivity: {
                                          ...formData?.biologicalFunction?.sexualActivity,
                                          protectionAgainstHepatitisHiv: {
                                            ...formData?.biologicalFunction?.sexualActivity?.protectionAgainstHepatitisHiv,
                                            how: e.target.value,
                                          },
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Do you protect yourself against unwanted pregnancy?
                        </h6>
                      </Col>
                      <Col xl={6}>
                        <div className="col-auto  d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            // name="sexualAbuse"
                            // value={UnwantedPregnancy.yes}
                            checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection === true}
                            onClick={e => {
                              const result = formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection === true;
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                biologicalFunction: {
                                  ...formData.biologicalFunction,
                                  sexualActivity: {
                                    ...formData?.biologicalFunction?.sexualActivity,
                                    protectionAgainstPregnancy: {
                                      ...formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy,
                                      protection: result ? null : true,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="row">
                          <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              // name="sexualAbuse"
                              // value={UnwantedPregnancy.no}
                              checked={formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection === false}
                              onClick={e => {
                                const result = formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection === false;
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  biologicalFunction: {
                                    ...formData.biologicalFunction,
                                    sexualActivity: {
                                      ...formData?.biologicalFunction?.sexualActivity,
                                      protectionAgainstPregnancy: {
                                        ...formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy,
                                        protection: result ? null : false,
                                        how: "",
                                      },
                                    },
                                  },
                                }));
                              }}
                            />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col-auto">
                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                  No
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={12}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, how:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  // border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                  border: "1px dotted  #ccc",
                                  padding: "2px",
                                  outline: "none",
                                  borderRadius: "8px",
                                }}
                                disabled={!formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.protection}
                                value={formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy?.how ?? ""}
                                type="text"
                                onChange={e => {
                                  setFormData((formData: Partial<DigitalForm>) => ({
                                    ...formData,
                                    biologicalFunction: {
                                      ...formData.biologicalFunction,
                                      sexualActivity: {
                                        ...formData?.biologicalFunction?.sexualActivity,
                                        protectionAgainstPregnancy: {
                                          ...formData?.biologicalFunction?.sexualActivity?.protectionAgainstPregnancy,
                                          how: e.target.value,
                                        },
                                      },
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12}>
                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                          Do you have concerns that your present or past behavior has placed you at risk?
                        </h6>
                      </Col>
                      <Col xl={6}>
                        <div className="col-auto d-flex">
                          <input
                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                            type="radio"
                            className="form-check-input"
                            // name="emotionalAbuse"
                            // value={AtRisk.yes}
                            checked={formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk === true}
                            onClick={e => {
                              const result = formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk === true;
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                biologicalFunction: {
                                  ...formData.biologicalFunction,
                                  sexualActivity: {
                                    ...formData?.biologicalFunction?.sexualActivity,
                                    atRiskBehavior: {
                                      ...formData?.biologicalFunction?.sexualActivity?.atRiskBehavior,
                                      risk: result ? null : true,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                            Yes
                          </label>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="row">
                          <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              // name="emotionalAbuse"
                              // value={AtRisk.no}
                              checked={formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk === false}
                              onClick={e => {
                                const result = formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk === false;
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  biologicalFunction: {
                                    ...formData.biologicalFunction,
                                    sexualActivity: {
                                      ...formData?.biologicalFunction?.sexualActivity,
                                      atRiskBehavior: {
                                        ...formData?.biologicalFunction?.sexualActivity?.atRiskBehavior,
                                        risk: result ? null : false,
                                        describe: "",
                                      },
                                    },
                                  },
                                }));
                              }}
                            />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col-auto">
                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                  NO
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={12}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                If yes, please describe:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  // border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                  border: "1px dotted  #ccc",
                                  padding: "2px",
                                  outline: "none",
                                  borderRadius: "8px",
                                }}
                                type="text"
                                disabled={!formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.risk}
                                value={formData?.biologicalFunction?.sexualActivity?.atRiskBehavior?.describe ?? ""}
                                onChange={e => {
                                  setFormData((formData: Partial<DigitalForm>) => ({
                                    ...formData,
                                    biologicalFunction: {
                                      ...formData.biologicalFunction,
                                      sexualActivity: {
                                        ...formData?.biologicalFunction?.sexualActivity,
                                        atRiskBehavior: {
                                          ...formData?.biologicalFunction?.sexualActivity?.atRiskBehavior,
                                          describe: e.target.value,
                                        },
                                      },
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <div style={{ margin: "0.2rem" }}>
                        <h6 style={{ fontWeight: "500", display: "inline" }}>Other Symptoms:</h6>
                      </div>
                      <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                        <CKEditor
                          editor={Editor}
                          data={sexualActivityComment ?? ""}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            // setFormData((formData: Partial<DigitalForm>) => ({
                            //   ...formData,
                            //   biologicalFunction: {
                            //     ...formData.biologicalFunction,
                            //     sexualActivity: {
                            //       ...formData?.biologicalFunction?.sexualActivity,
                            //       otherSymtoms: data,
                            //     },
                            //   },
                            // }));
                            setsSxualActivityComment(data);
                          }}
                        />
                      </div>
                    </Row>
                  </Card>
                </div>
              </Row>
            </Card>
          </div>
        </Row>
        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 3 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormThree;
