import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Spinner from "src/common/spinner/spinner";

const Modal23: React.FC<{
  audioURL: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-info-circle text-info"></i>
            </div>

            <CardTitle className="mb-4 text-center font-size-20">View Recording</CardTitle>

            <div className="d-flex justify-center flexColumn">
              {/* <audio controls controlsList="nodownload">
                <source data-src={props.audioURL} src={props.audioURL} type="audio/mp3" />
              </audio> */}
              {
                props?.audioURL ?
                  <AudioPlayer
                    src={props.audioURL}
                    preload="none"
                    // autoPlayAfterSrcChange={false}
                  />
                  :
                <Spinner />
              }

            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal23;