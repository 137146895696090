import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import images from "src/assets/images";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import UserContext from "src/context/UserContext";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import Spinner from "src/common/spinner/spinner";
import GroupChatContext from "src/context/GroupChatContext";
import CreateGroupChatModal from "src/pages/Popup/GroupChatModals/CreateGroupChatModal";
import { Util } from "src/Util";
import { decryptGroupChatMessage } from "./Common/GroupChatEncryption";
import moment from "moment";
import GroupChatCardCom from "./Common/GroupChatCardCom";
import InfiniteScroll from "react-infinite-scroll-component";
const classNames = require("classnames");

const GroupChatList: React.FC<{
  allChatGroups: GroupChatModel[];
  setAllChatGroups: (newGroups: GroupChatModel[]) => void;
  isGettingChatGroupsFrom: boolean;
  isGettingChatGroupsErrorFrom: boolean;
  prevSelectedTabNoFrom: string;
}> = ({ allChatGroups, setAllChatGroups, isGettingChatGroupsFrom, isGettingChatGroupsErrorFrom, prevSelectedTabNoFrom }) => {
  const history = useHistory();
  const initialSelectedGroup: GroupChatModel = { _id: "", title: "", description: "", type: "", createdAt: "", createdBy: "", unreadMessageCount: 0 };
  const [user, setUser] = useContext(UserContext);
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);
  const [showCreateGroupChatModal, setShowCreateGroupChatModal] = useState<boolean>(false);
  const [tabNo, setTabNo] = useState<string>("");

  const [isGettingChatGroups, setIsGettingChatGroups] = useState(true);
  const [isGettingChatGroupsError, setIsGettingChatGroupsError] = useState(false);
  const [prevSelectedTabNo, setPrevSelectedTabNo] = useState("");

  const [isPaginatingChatGroups, setIsPaginatingChatGroups] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [messageBox, setMessageBox] = useState<HTMLInputElement | null>(null);

  const limit = 20;

  useEffect(() => {
    if (allChatGroups != null && allChatGroups.length != null && allChatGroups.length > 0) {
      setIsGettingChatGroups(false);
    } else {
      initialLoading();
    }
  }, []);

  useEffect(() => {
    if (prevSelectedTabNo == "1" || prevSelectedTabNo == "2") {
      setTabNo(prevSelectedTabNo);
      setIsGettingChatGroups(false);
    }
  }, [prevSelectedTabNo]);

  const initialLoading = async () => {
    setAllChatGroups([]);
    setSelectedGroupData(initialSelectedGroup);
    setSelectedGroupIdForShowMembers("");
    setSelectedGroupIdForShowRequests("");
    setHasMoreData(true);
    setIsPaginatingChatGroups(false);
    setIsGettingChatGroups(true);
    setIsGettingChatGroupsError(false);
    await getAllChatGroupsInitial(true, "");
  };

  const getAllChatGroupsInitial = async (isInitial: boolean, tabNumber: string) => {
    try {
      const pathname = location.pathname;
      const splited = pathname.split("/");
      const groupIdFromUrl = splited[2];
      const dataObject = {
        type: isInitial ? null : tabNumber == "1" ? "PRIVATE" : "PUBLIC",
        prevGroupId: isInitial ? groupIdFromUrl : null,
        limit: limit,
        offset: 0,
      };
      const res = await GroupChatService.getAllChatGroups(dataObject);
      if (res.success && res.data) {
        setIsGettingChatGroupsError(false);

        const newGroups: GroupChatModel[] = res?.data;
        setAllChatGroups(newGroups);

        if (newGroups.length < limit) {
          setHasMoreData(false);
        }

        if (isInitial) {
          if (res.data != null) {
            try {
              const groupChatFromResponse: GroupChatModel[] = res?.data;
              if (groupIdFromUrl != null && groupChatFromResponse != null && groupChatFromResponse.length != null && groupChatFromResponse.length > 0) {
                const chatGroupData: GroupChatModel | undefined = groupChatFromResponse.find(grp => grp._id == groupIdFromUrl);
                if (chatGroupData != null && chatGroupData._id != null && chatGroupData._id == groupIdFromUrl) {
                  setSelectedGroupData(chatGroupData);
                  if (chatGroupData.type == "PUBLIC") {
                    setPrevSelectedTabNo("2");
                  } else {
                    setPrevSelectedTabNo("1");
                  }
                } else {
                  history.push(`/group-chat`);
                  setPrevSelectedTabNo("1");
                }
              } else {
                history.push(`/group-chat`);
                setPrevSelectedTabNo("1");
              }
            } catch (error) {
              history.push(`/group-chat`);
              setPrevSelectedTabNo("1");
            }
          } else {
            history.push(`/group-chat`);
            setPrevSelectedTabNo("1");
          }
        }
      } else {
        if (isInitial) {
          history.push(`/group-chat`);
          setPrevSelectedTabNo("1");
        }
        setIsGettingChatGroupsError(true);
        toast.error("Retrieving chat groups failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      if (isInitial) {
        history.push(`/group-chat`);
        setPrevSelectedTabNo("1");
      }
      setIsGettingChatGroupsError(true);
      toast.error("Retrieving chat groups failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const paginateChatGroups = async (typeFrom: string) => {
    try {
      if (isGettingChatGroups || isPaginatingChatGroups) {
        return;
      }
      const dataObject = {
        type: typeFrom,
        prevGroupId: null,
        limit: limit,
        offset: allChatGroups.length,
      };
      setIsPaginatingChatGroups(true);
      const res = await GroupChatService.getAllChatGroups(dataObject);
      if (res.success && res.data) {
        setIsPaginatingChatGroups(false);

        const newGroups: GroupChatModel[] = res.data;
        const prevGroups: GroupChatModel[] = allChatGroups;
        if (newGroups.length < limit) {
          setHasMoreData(false);
        }
        setAllChatGroups([...prevGroups, ...newGroups]);
      } else {
        toast.error("Error loading data !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setIsPaginatingChatGroups(false);
      }
    } catch (error) {
      toast.error("Error loading data !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setIsPaginatingChatGroups(false);
    }
  };

  const toggleTab = async (tab: string) => {
    if (tab != tabNo && !isGettingChatGroups && !isPaginatingChatGroups) {
      setIsGettingChatGroups(true);
      setHasMoreData(true);
      setAllChatGroups([]);
      setSelectedGroupIdForShowRequests("");
      setSelectedGroupIdForShowMembers("");
      setSelectedGroupData(initialSelectedGroup);
      setTabNo(tab);
      history.push(`/group-chat`);
      setIsPaginatingChatGroups(false);
      setIsGettingChatGroupsError(false);
      await getAllChatGroupsInitial(false, tab);
      setIsGettingChatGroups(false);
    }
    if (isGettingChatGroups || isPaginatingChatGroups) {
      toast.error("Please wait !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const scrollToTop = () => {
    if (messageBox && messageBox.scrollTop) {
      messageBox.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (prevSelectedTabNo != "") {
      setTabNo(prevSelectedTabNo);
    }
  }, [prevSelectedTabNo]);

  return (
    <React.Fragment>
      {tabNo != "" && showCreateGroupChatModal && (
        <CreateGroupChatModal
          setShowModal={setShowCreateGroupChatModal}
          showModal={showCreateGroupChatModal}
          allChatGroups={allChatGroups}
          setAllChatGroups={setAllChatGroups}
          selectedChatType={tabNo == "1" ? "PRIVATE" : "PUBLIC"}
        />
      )}
      <div>
        <div className="d-flex justify-content-between">
          <h5 className="font-size-14 mb-3">Recent Groups</h5>
          {user?.role == "THERAPIST" && (
            <NavLink
              className="flex font-size-14 p-0"
              style={{ display: "none" }}
              onClick={() => {
                setShowCreateGroupChatModal(!showCreateGroupChatModal);
              }}
            >
              <img src={images.addSignIcon} className="ai-icon-size me-2" />
              Create a group
            </NavLink>
          )}
        </div>
        {/* {!isGettingChatGroups && !isGettingChatGroupsError && tabNo != "" && ( */}

        <Nav tabs className="nav-tabs-custom align-items-center justify-content-center pt-1 pb-1">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={tabNo === "1" ? "active" : ""}
              onClick={() => {
                toggleTab("1");
              }}
            >
              <span className=" ">Private Groups</span>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={tabNo === "2" ? "active" : ""}
              onClick={() => {
                toggleTab("2");
              }}
            >
              <span className="">Peer Support Groups</span>
            </NavLink>
          </NavItem>
        </Nav>

        <ul className="list-unstyled chat-list" id="recent-group-list">
          <PerfectScrollbar style={{ height: "410px" }} containerRef={(ref: any) => setMessageBox(ref)}>
            {isGettingChatGroups || tabNo == "" ? (
              <div className="group-chat-empty-view">
                <Spinner />
              </div>
            ) : !isGettingChatGroups && isGettingChatGroupsError ? (
              <div className="group-chat-empty-view">
                <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                <div className="mt-1">Error getting data</div>
              </div>
            ) : !isGettingChatGroups && !isGettingChatGroupsError && allChatGroups != null && allChatGroups.length != null && allChatGroups.length > 0 ? (
              <div>
                {allChatGroups.map((group, index: number) => (
                  <GroupChatCardCom
                    key={group._id}
                    groupFrom={group}
                    allChatGroups={allChatGroups}
                    setAllChatGroups={setAllChatGroups}
                    scrollToTop={scrollToTop}
                  ></GroupChatCardCom>
                ))}
                {hasMoreData && (
                  <div className="d-flex align-items-center justify-content-end me-4 mb-4">
                    <button
                      className={isPaginatingChatGroups ? "btn btn-secondary" : "btn btn-warning"}
                      onClick={() => {
                        if (
                          allChatGroups != null &&
                          allChatGroups.length != null &&
                          allChatGroups.length > 0 &&
                          allChatGroups[0] != null &&
                          allChatGroups[0].type != null &&
                          (allChatGroups[0].type == "PRIVATE" || allChatGroups[0].type == "PUBLIC")
                        ) {
                          paginateChatGroups(allChatGroups[0].type);
                        }
                      }}
                    >
                      {isPaginatingChatGroups ? "Loading..." : "See More"}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="group-chat-empty-view">
                <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                <div className="mt-1">No any Groups yet</div>
              </div>
            )}
          </PerfectScrollbar>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default GroupChatList;
