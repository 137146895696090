import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter, useLocation } from "react-router-dom";
import { toggleLeftmenu } from "../../store/actions";
import { withTranslation } from "react-i18next";
import UserContext from "../../context/UserContext";
import { connect } from "react-redux";
import { Role } from "../../models/Role";
import ChatContext from "../../context/ChatContext";
import { RouteName } from "../../RouteName";

const Navbar = props => {
  const [support, setSupport] = useState(false);
  const [mobileSize, setMobileSize] = useState(window.innerWidth);
  const [user] = useContext(UserContext);
  const [unreadChatCount, setUnreadChatCount] = useContext(ChatContext);
  const location = useLocation();
  const [pageName, setPageName] = useState("dashboard");

  let isNewDashboard;
  if (user) {
    if (user.role === Role.THERAPIST) {
      isNewDashboard = user?.isNewDashboard ?? true;
    } else {
      isNewDashboard = false;
    }
  }

  // const splittedURL = window.location.pathname.split("/");
  // const path = splittedURL[splittedURL.length - 1];
  //   console.log("path",path)

  useEffect(() => {
    if (
      location.pathname != RouteName.MEETING_SCREEN_NEW &&
      !location.pathname.includes("vonage-session") &&
      !location.pathname.includes("room/video-room") &&
      !location.pathname.includes("room") &&
      isNewDashboard !== true
    ) {
      var matchingMenuItem = null;
      var ul = document.getElementById("navigation");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname != RouteName.MEETING_SCREEN_NEW &&
      !location.pathname.includes("vonage-session") &&
      !location.pathname.includes("video-room") &&
      !location.pathname.includes("room") &&
      isNewDashboard !== true
    ) {
      window.addEventListener("resize", setRespoonsive);
    }
  }, []);
  useEffect(() => {
    // const splittedURL = window.location.pathname.split("/");
    // const path = splittedURL[splittedURL.length - 1];
    // setPageName(path)
  }, []);

  function setRespoonsive() {
    setMobileSize(window.innerWidth);
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  function setSupportOpen() {
    setSupport(true);

    props.toggleLeftmenu(!props.leftMenu);
  }

  return (
    <React.Fragment>
      {location.pathname != RouteName.MEETING_SCREEN_NEW &&
        !location.pathname.includes("vonage-session") &&
        !location.pathname.includes("video-room") &&
        !location.pathname.includes("room") &&
        isNewDashboard !== true && (
          <div>
            {mobileSize <= 992 ? (
              <div className="topnav">
                <div className="container-fluid">
                  <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                    <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link
                            className="nav-link navigation-item nav-menu-item  "
                            to="/dashboard"
                            id="dashboard"
                            onClick={() => {
                              props.toggleLeftmenu(!props.leftMenu);
                            }}
                          >
                            <i className="bx bx-home-circle me-2"></i>
                            {props.t("Dashboard")} {props.menuOpen}
                          </Link>
                        </li>

                        <li className="nav-item" id="dasharticles">
                          <Link
                            className="nav-link navigation-item nav-menu-item"
                            to="/articles"
                            onClick={() => {
                              props.toggleLeftmenu(!props.leftMenu);
                            }}
                          >
                            <i className="bx bx-world me-2"></i>
                            {props.t("Discover")}
                          </Link>
                        </li>

                        {user.role == Role.THERAPIST && (
                          <li className="nav-item">
                            <Link
                              className="nav-link navigation-item nav-menu-item"
                              to="/documents"
                              onClick={() => {
                                props.toggleLeftmenu(!props.leftMenu);
                              }}
                            >
                              <i className="bx bxs-file-doc me-2"></i>
                              {props.t("Training Documents")}
                            </Link>
                          </li>
                        )}

                        <li className="nav-item" id="dashchat">
                          <Link
                            to="/chat"
                            className="nav-link navigation-item nav-menu-item"
                            onClick={() => {
                              props.toggleLeftmenu(!props.leftMenu);
                            }}
                          >
                            <i className="bx bx-chat me-2"></i>
                            {props.t("Chat")}
                            {unreadChatCount > 0 ? (
                              <span style={{ position: "absolute", background: "red", marginLeft: "2px" }} className="badge  rounded-pill">
                                {unreadChatCount}
                              </span>
                            ) : null}
                          </Link>
                        </li>

                        {user.role === Role.CLIENT && (
                          <li className="nav-item" id="dashchat">
                            <Link
                              to="/public-group-chat"
                              className="nav-link navigation-item nav-menu-item"
                              onClick={() => {
                                props.toggleLeftmenu(!props.leftMenu);
                              }}
                            >
                              <i className="bx bx-chat me-2"></i>
                              {props.t("Peer Support Groups")}
                            </Link>
                          </li>
                        )}

                        <li className="nav-item" id="step2">
                          <Link
                            to="/appointments"
                            className="nav-link navigation-item nav-menu-item"
                            onClick={() => {
                              props.toggleLeftmenu(!props.leftMenu);
                            }}
                          >
                            <i className="bx bx-calendar-alt me-2"></i>
                            {props.t("Appointments")}
                          </Link>
                        </li>

                        <li className="nav-item" id="support">
                          <Link
                            to="/support"
                            onClick={() => {
                              setSupportOpen();
                            }}
                            className="nav-link navigation-item nav-menu-item"
                          >
                            <i className="bx bx-support me-2"></i>
                            {props.t("Support")}
                          </Link>
                        </li>

                        <li className="nav-item" id="tech">
                          <Link
                            to="/tech"
                            onClick={() => {
                              setSupportOpen();
                            }}
                            className="nav-link navigation-item nav-menu-item"
                          >
                            <i className="bx bx-support me-2"></i>
                            {props.t("Help")}
                          </Link>
                        </li>
                      </ul>
                    </Collapse>
                  </nav>
                </div>
              </div>
            ) : (
              <div className="topnav">
                <div className="container-fluid">
                  <nav
                    className={
                      user.role == Role.SUPER_ADMIN || user.role == Role.SUB_ADMIN ? "style-action2  " : "navbar navbar-light navbar-expand-lg topnav-menu"
                    }
                    id="navigation"
                  >
                    <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
                      <ul className="navbar-nav">
                        <li className="nav-item main-menu-item">
                          <Link
                            to="/dashboard"
                            onClick={() => {
                              setSupport(!support);
                              setPageName("dashboard");
                            }}
                            className={`nav-link navigation-item nav-menu-item ${pageName == "dashboard" ? "selected-tab" : "select-tab"}`}
                          >
                            <i className="bx bx-home-circle me-2"></i>
                            {props.t("Dashboard")} {props.menuOpen} {user.role}
                          </Link>
                        </li>

                        <li className="nav-item main-menu-item" id="dasharticles">
                          <Link
                            to="/articles"
                            onClick={() => {
                              setSupport(!support);
                              setPageName("articles");
                            }}
                            className={`nav-link navigation-item nav-menu-item ${pageName == "articles" ? "selected-tab" : "select-tab"}`}
                          >
                            <i className="bx bx-world me-2"></i>
                            {props.t("Discover")}
                          </Link>
                        </li>
                        {user.role == Role.THERAPIST && (
                          <li className="nav-item main-menu-item">
                            <Link
                              to="/documents"
                              onClick={() => {
                                setSupport(!support);
                                setPageName("documents");
                              }}
                              className={`nav-link navigation-item nav-menu-item ${pageName == "documents" ? "selected-tab" : "select-tab"}`}
                            >
                              <i className="bx bxs-file-doc me-2"></i>
                              {props.t("Training Documents")}
                            </Link>
                          </li>
                        )}

                        <li className="nav-item main-menu-item" id="dashchat">
                          <Link
                            to="/chat"
                            onClick={() => {
                              setSupport(!support);
                              setPageName("chat");
                            }}
                            className={`nav-link navigation-item nav-menu-item ${pageName == "chat" ? "selected-tab" : "select-tab"}`}
                          >
                            <i className="bx bx-chat me-2"></i>
                            {props.t("Chat")}
                          </Link>
                        </li>

                        {/* {user.role == Role.THERAPIST && (
                          <li className="nav-item main-menu-item">
                            <Link
                              to="/training-documents"
                              onClick={() => {
                                setSupport(!support);
                                setPageName("training-docs");
                              }}
                              className={`nav-link navigation-item nav-menu-item ${pageName == "training-docs" ? "selected-tab" : "select-tab"}`}
                            >
                              <i className="bx bxs-file-doc me-2"></i>
                              {props.t("Doc Inventory")}
                            </Link>
                          </li>
                        )} */}

                        {user.role === Role.CLIENT && (
                          <li className="nav-item main-menu-item" id="dashchat">
                            <Link
                              to="/public-group-chat"
                              onClick={() => {
                                setSupport(!support);
                                setPageName("public-group-chat");
                              }}
                              className={`nav-link navigation-item nav-menu-item ${pageName == "public-group-chat" ? "selected-tab" : "select-tab"}`}
                            >
                              <i className="bx bx-chat me-2"></i>
                              {props.t("Peer Support Groups")}
                            </Link>
                          </li>
                        )}

                        <li className="nav-item main-menu-item" id="step2">
                          <Link
                            to="/appointments"
                            onClick={() => {
                              setSupport(!support);
                              setPageName("appointments");
                            }}
                            className={`nav-link navigation-item nav-menu-item ${pageName == "appointments" ? "selected-tab" : "select-tab"}`}
                          >
                            <i className="bx bx-calendar-alt me-2"></i>
                            {props.t("Appointments")}
                          </Link>
                        </li>

                        <li className="nav-item main-menu-item" id="support">
                          <Link
                            to="/support"
                            onClick={() => {
                              setSupport(!support);
                              setPageName("support");
                            }}
                            className={`nav-link navigation-item nav-menu-item ${pageName == "support" ? "selected-tab" : "select-tab"}`}
                          >
                            <i className="bx bx-support me-2"></i>
                            {props.t("Support")}
                          </Link>
                        </li>

                        <li className="nav-item main-menu-item" id="tech">
                          <Link
                            to="/tech"
                            onClick={() => {
                              setSupport(!support);
                              setPageName("tech");
                            }}
                            className={`nav-link navigation-item nav-menu-item ${pageName == "tech" ? "selected-tab" : "select-tab"}`}
                          >
                            <i className="bx bx-comment-dots me-2"></i>
                            {props.t("Help")}
                          </Link>
                        </li>
                      </ul>
                    </Collapse>
                  </nav>
                </div>
              </div>
            )}
          </div>
        )}
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { leftMenu, toggleLeftmenu } = state.Layout;
  return { leftMenu, toggleLeftmenu };
};

export default withRouter(connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar)));
