import React from 'react';
import ChatInput from '../ChatInput';
import styles from './styles';
import useSignal from '../../hooks/useSignal';
import ChatMessages from '../ChatMessages';
import PropTypes from 'prop-types';

const Chat = ({ room, listOfMessages }) => {
  const { sendSignal } = useSignal({ room });

  const sendMessage = (text) => {
    if (room) sendSignal(text, 'text');
  };

  const classes = styles();

  return (
    <div className={classes.chatContainer}>
      <ChatMessages
        chatClass={classes.chatMessages}
        chatMessages={listOfMessages}
      ></ChatMessages>
      <div className={classes.chatInput}>
        <ChatInput sendMessage={sendMessage}></ChatInput>
      </div>
    </div>
  );
};
Chat.propTypes = {
  room: PropTypes.string, // Adjust the type accordingly
  listOfMessages: PropTypes.array, // Adjust the type accordingly
};
export default Chat;
