import React from 'react';
import { useLocation } from 'react-router-dom';
import DiagnosisNoteScreen from './Chat/DiagnosisNote/DiagnosisNoteView';

const DiagnosisNotePage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const diagnosisId = searchParams.get('diagnosisId');

  if (!diagnosisId) {
    return <div>Diagnosis ID is required</div>;
  }

  return (
    <DiagnosisNoteScreen
      diagnosisId={diagnosisId}
      totalSpentDuration={0}
      updateDiagnosisData={() => {}}
      isVersion={false}
      canDownloadFromPdfService={true}
    />
  );
};

export default DiagnosisNotePage;
