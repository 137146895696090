import React from "react";
import { MessageModel } from "../../../models/MessageModel";
import { DoDecrypt } from "./Aes";
import parse from "html-react-parser";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  selectedMessage: MessageModel | undefined;
  setShowReplyPanel: (value: React.SetStateAction<boolean>) => void;
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageModel | undefined>>;
  showReplyPanel: boolean;
}

const ReplyMessageDisplayComp = ({ selectedMessage, setShowReplyPanel, showReplyPanel, setSelectedMessage }: Props) => {
  const getPreviewByExtension = (extension: string | undefined, url: string) => {
    switch (extension) {
      case ".jpg":
      case ".JPG":
      case ".JPEG":
      case ".jpeg":
      case ".tiff":
      case ".TIFF":
      case ".gif":
      case ".GIF":
      case ".PNG":
      case ".png":
        return <img src={`${url}/${localStorage.getItem("token")}`} style={{ maxHeight: "250px", maxWidth: "250px" }} />;
      case ".mp3":
      case ".MP3":
      case ".WMA":
      case ".wma":
        return <i className=" fa fa-file-audio text-info " style={{ fontSize: "90px" }}></i>;
      case ".mp4":
      case ".MP4":
      case ".AVI":
      case ".avi":
        return <i className=" fa fa-file-video text-warning " style={{ fontSize: "90px" }}></i>;
      case ".txt":
      case ".TXT":
        return <i className="fa fa-file-word  " style={{ fontSize: "90px" }}></i>;
      case ".pdf":
      case ".PDF":
        return <i className=" fa fa-file-pdf text-danger " style={{ fontSize: "90px" }}></i>;
      default:
        return <i className=" fa fa-file" style={{ fontSize: "90px" }}></i>;
    }
  };
  return (
    <div className="content-wrapper1 break-word-show-msg">
      <div className="reply-content">
        <span
          style={{
            backgroundColor: "#FD7F00",
            height: "100%",
            width: "1px",
          }}
        ></span>
        <div className="receiver1">{selectedMessage?.senderId?.firstname}</div>
        <i
          className="fa fa-times pointer1"
          style={{ padding: "5px 0px 0px 10px" }}
          onClick={() => {
            setShowReplyPanel(!showReplyPanel);
            setSelectedMessage(undefined);
          }}
        ></i>
      </div>
      <div className="replying-message">
        {selectedMessage?.AttachmentUploadId ? (
          <div className="mb-2 cursor-pointer" onClick={() => window.open(`${selectedMessage?.AttachmentUploadId?.url}/${localStorage.getItem("token")}`)}>
            {getPreviewByExtension(selectedMessage?.AttachmentUploadId?.extension, selectedMessage?.AttachmentUploadId?.url)}
          </div>
        ) : null}
        <p className="m-0">{selectedMessage?.text ? parse(DoDecrypt(selectedMessage?.text)) : null}</p>
      </div>
    </div>
  );
};

export default ReplyMessageDisplayComp;
