import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../assets/css/onboarding.css";
import "./../../assets/css/publicMedia.css";
import successful from "../../assets/images/successful.png";
import { useParams } from "react-router";
import { ParameterTypes } from "src/utils/ParameterTypes";
import { ClientService } from "src/services/ClientService";
import HeaderMain from "src/common/main/HeaderMain";
import FooterMain from "src/common/main/FooterMain";
import { AppointmentService } from "src/services/AppointmentService";
import { Card, CardBody, Col, Container } from "reactstrap";
import Spinner from "src/common/spinner/spinner";
import { Appointment, Repeat } from "src/models/Appointment";
import images from "src/assets/images";
import { Util } from "src/Util";


const TomorrowSchedulePageForTherapist: React.FC = () => {
  const { scheduleId } = useParams<ParameterTypes>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [therapistName, setTherapistName] = useState("");
  const [approvedSchedule, setApprovedSchedule] = useState<any[]>([]);
  const [notApprovedSchedule, setNotApprovedSchedule] = useState<any[]>([]);
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  useEffect(() => {
    getSchedule();
  }, []);

  
  const getSchedule = async () => {
    try {
        setIsLoading(true);
        if(scheduleId){
            const response = await AppointmentService.getTomorrowAppointmentScheduleForTherapist(scheduleId);
            if(response && response?.success && response?.data){

              const data = response?.data;
              const approvedList = data.filter((schedule: any) => (schedule?.approvedStatus === "APPROVED"));
              const notApprovedList = data.filter((schedule: any) => (schedule?.approvedStatus === "PENDING"));

              if(data && data?.length && data?.length > 0){
                const tName = `${data[0]?.therapistDetails?.firstname ?? ""} ${data[0]?.therapistDetails?.lastname ?? ""}`;
                setTherapistName(tName);
              }

              if(approvedList){
                setApprovedSchedule(approvedList);
              }

              if(notApprovedList){
                setNotApprovedSchedule(notApprovedList);
              }


              
            }else{
                setIsError(true);
            }
        }else{
            setIsError(true);
        }
        setIsLoading(false);

    } catch (error) {
        setIsError(true);
        setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="t-appointment-page-main">
        <Col sm={12} md={12} lg={6} xl={6} className="t-appointment-page-body">
          {!isLoading && !isError && therapistName && therapistName != "" && (<h4 className="mb-0 font-size-16 mb-4" style={{ color: "black" }}>
              {`Hey ${therapistName}, Your appointment schedule for tomorrow.`}
            </h4>)
          }
          {(!isLoading && !isError && approvedSchedule && approvedSchedule?.length && approvedSchedule?.length > 0 ) ? (
            <div>
                <h4 className="font-size-16 mb-2" style={{ color: "black" }}>
                  Approved Appointments
                </h4>
                {approvedSchedule.map((session: any, index: number) => {
                  const startDate = new Date(session?.start);
                  return (
                    <div className="t-appointments-main-list-item" key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center" >
                          
                          {session?.repeatInfo?.repeatType == Repeat.WEEKLY && <h4 className="mb-0 font-size-12 t-appointment-weekly">Weekly</h4>}

                          {session?.repeatInfo?.repeatType == Repeat.BI_WEEKLY && <h4 className="mb-0 font-size-12 t-appointment-bi-weekly">Bi Weekly</h4>}

                        </div>
                      </div>

                      <div className="mt-2 t-appointment-sub-list-item">

                        <h4 className="mb-0 font-size-16 t-appointment-time mt-2 mb-2" style={{ color: "black"}}>
                          {session?.title} -&nbsp;
                          {session?.clientDetails?.firstname + " " + session?.clientDetails?.lastname}
                        </h4>

                        <div className="d-flex mt-2 mb-2">
                          <img src={images.clockIcon} className="clock-icon" />
                          <h4 className="mb-0 font-size-16" style={{ color: "#575151" }}>
                            {Util.formatAMPM(startDate)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}

            </div>
          ) : (<div></div>)}

          {(!isLoading && !isError && notApprovedSchedule && notApprovedSchedule?.length && notApprovedSchedule?.length > 0) ? (
            <div>
                <h4 className="font-size-16 mb-2 mt-4" style={{ color: "black" }}>
                  Approval Pending Appointments
                </h4>
                {notApprovedSchedule.map((session: any, index: number) => {
                  const startDate = new Date(session?.start);
                  return (
                    <div className="t-appointments-main-list-item" key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center" >
                          
                          {session?.repeatInfo?.repeatType == Repeat.WEEKLY && <h4 className="mb-0 font-size-12 t-appointment-weekly">Weekly</h4>}

                          {session?.repeatInfo?.repeatType == Repeat.BI_WEEKLY && <h4 className="mb-0 font-size-12 t-appointment-bi-weekly">Bi Weekly</h4>}

                        </div>
                      </div>

                      <div className="mt-2 t-appointment-sub-list-item">

                        <h4 className="mb-0 font-size-16 t-appointment-time mt-2 mb-2" style={{ color: "black"}}>
                          {session?.title} -&nbsp;
                          {session?.clientDetails?.firstname + " " + session?.clientDetails?.lastname}
                        </h4>

                        <div className="d-flex mt-2 mb-2">
                          <img src={images.clockIcon} className="clock-icon" />
                          <h4 className="mb-0 font-size-16" style={{ color: "#575151" }}>
                            {Util.formatAMPM(startDate)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (<div></div>)}
          {!isLoading && !isError && (!approvedSchedule || !approvedSchedule?.length || approvedSchedule?.length <= 0) && (!notApprovedSchedule || !notApprovedSchedule?.length || notApprovedSchedule?.length <= 0) && (
            <div className="d-flex align-items-center justify-content-center" style={{minHeight: "20vh"}}>
              <h4 className="mb-0 font-size-16 mb-4" style={{ color: "black" }}>
                No schedule for tomorrow
              </h4>
            </div>
          )}
          {!isLoading && isError && (
            <div className="d-flex align-items-center justify-content-center" style={{minHeight: "20vh"}}>
              <h4 className="mb-0 font-size-16 mb-4" style={{ color: "black" }}>
                Something went wrong !
              </h4>
            </div>
          )}
          {isLoading && (
            <div className="d-flex align-items-center justify-content-center" style={{minHeight: "20vh"}}>
              <Spinner />
            </div>
          )}
        </Col>
      </div>
    </React.Fragment>
  );
};

export default TomorrowSchedulePageForTherapist;
