import React from "react";
import logo from "../../../assets/images/logo.svg";
import logoDark from "../../../assets/images/logo-dark.png";
import { NavLink } from "react-router-dom";

interface CustomHeaderProps {
  isTokenFound: boolean;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({ isTokenFound }: CustomHeaderProps) => {
  const onSignInClick = () => {
    location.href = "/signin";
  };

  const onSignUpClick = () => {
    location.href = "/main";
  };

  const onDashboardClick = () => {
    location.href = "/dashboard";
  };

  return (
    <header id="page-topbar" style={{ left: 0 }}>
      <div className="navbar-header">
        <div className="d-flex logo-sec">
          <div className="navbar-brand-box">
            <NavLink to={"/home/"} className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} height="22" style={{ height: "22px" }} />
              </span>
              <span className="logo-lg">
                <img src={logoDark} height="50" style={{ height: "50px" }} />
              </span>
            </NavLink>
          </div>
        </div>
        {isTokenFound ? (
          <>
            <div className=".navbar-other11 ms-lg-4">
              <ul className="navbar-nav11 flex-row align-items-center ms-auto">
                <li className="d-none d-md-block px-2">
                  <button className="btn btn btn-sm btn-outline-primary rounded-pill" onClick={onDashboardClick}>
                    Dashboard
                  </button>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className=".navbar-other11 ms-lg-4">
              <ul className="navbar-nav11 flex-row align-items-center ms-auto">
                <li className="d-none d-md-block px-2">
                  <button className="btn btn btn-sm btn-outline-primary rounded-pill" onClick={onSignInClick}>
                    Sign In
                  </button>
                </li>
                <li className="d-none d-md-block px-2">
                  <button className="btn btn-sm btn-primary rounded-pill" style={{ color: "#fff" }} onClick={onSignUpClick}>
                    Sign Up
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default CustomHeader;
