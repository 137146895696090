import moment from "moment";
import Linkify from "react-linkify";
import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { MessageModel } from "../../../models/MessageModel";
import { User } from "../../../models/User";
import { DoDecrypt } from "./Aes";
import parse from "html-react-parser";
import { toast } from "react-toastify";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  message: MessageModel;
  currentUser: User;
  openReplyPanel: (messageDetails: MessageModel) => void;
  handleCopy: (text: string) => void;
  deleteMessage: (messageId: string) => Promise<void>;
}

const DisplayMessageComp = ({ message, currentUser, openReplyPanel, handleCopy, deleteMessage }: Props) => {
  const getPreviewByExtension = (extension: string | undefined, url: string) => {
    switch (extension) {
      case ".jpg":
      case ".JPG":
      case ".JPEG":
      case ".jpeg":
      case ".tiff":
      case ".TIFF":
      case ".gif":
      case ".GIF":
      case ".PNG":
      case ".png":
        return <img src={`${url}/${localStorage.getItem("token")}`} style={{ maxHeight: "250px", maxWidth: "250px" }} />;
      case ".mp3":
      case ".MP3":
      case ".WMA":
      case ".wma":
        return <i className=" fa fa-file-audio text-info " style={{ fontSize: "90px" }}></i>;
      case ".mp4":
      case ".MP4":
      case ".AVI":
      case ".avi":
        return <i className=" fa fa-file-video text-warning " style={{ fontSize: "90px" }}></i>;
      case ".txt":
      case ".TXT":
        return <i className="fa fa-file-word  " style={{ fontSize: "90px" }}></i>;
      case ".pdf":
      case ".PDF":
        return <i className=" fa fa-file-pdf text-danger " style={{ fontSize: "90px" }}></i>;
      default:
        return <i className=" fa fa-file" style={{ fontSize: "90px" }}></i>;
    }
  };

  const copyToClipboard = async (textFrom: string) => {
    try {
      const linkifyElement = document.getElementById(`text-component-single-chat-${message?._id}`);

      if (linkifyElement) {
        const plainText = linkifyElement.innerText;

        if ("clipboard" in navigator) {
          return await navigator.clipboard.writeText(plainText);
        } else {
          return document.execCommand("copy", true, plainText);
        }
      }
    } catch (error) {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }
  };

  const handleCopyInside = (text: string) => {
    const copiedTextInString = text;

    if (copiedTextInString != undefined || copiedTextInString != null || copiedTextInString != "") {
      copyToClipboard(copiedTextInString).then(() => {
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
    } else {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <li key={message?._id} className={message?.senderId?._id == currentUser?._id ? "break-word-show-msg" : "right bg-light break-word-show-msg"}>
      <div className="conversation-list">
        <UncontrolledDropdown>
          <DropdownToggle href="#" className="btn nav-btn" tag="i">
            <i className="bx bx-dots-vertical-rounded" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                handleCopyInside(message?.text ? DoDecrypt(message?.text) : "");
              }}
            >
              Copy
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                openReplyPanel(message);
                null;
              }}
            >
              Reply
            </DropdownItem>

            {message?.senderId?._id != currentUser?._id && (
              <DropdownItem
                onClick={() => {
                  deleteMessage(message._id);
                }}
              >
                Delete
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div className={`ctext-wrap ${message?.senderId?._id === currentUser?._id ? "" : "bg-secondary bg-soft"} text-start`}>
          <div className="conversation-name">{message?.senderId?._id === currentUser?._id ? message?.senderId?.firstname : "You"}</div>

          {message?.AttachmentUploadId ? (
            <div className="mb-2 cursor-pointer" onClick={() => window.open(`${message?.AttachmentUploadId?.url}/${localStorage.getItem("token")}`)}>
              {getPreviewByExtension(message?.AttachmentUploadId?.extension, message?.AttachmentUploadId?.url)}
            </div>
          ) : null}
          <div id={`text-component-single-chat-${message?._id}`}>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a className="chat-link text-info" target="blank" style={{ textDecoration: "underline" }} rel="noopener" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {message?.text ? parse(DoDecrypt(message?.text)) : null}
            </Linkify>
          </div>
          {message?.mentionedMessage ? (
            <>
              <div className={`border-start border-5 border-primary ps-2`}>
                <div className="conversation-name text-success">{message?.mentionedMessage?.senderId?.firstname}</div>
                <p>{message?.text ? parse(String(DoDecrypt(message?.mentionedMessage?.text))) : null}</p>
                {message?.mentionedMessage?.AttachmentUploadId ? (
                  <div
                    className="mb-2 cursor-pointer"
                    onClick={() => window.open(`${message?.mentionedMessage?.AttachmentUploadId?.url}/${localStorage.getItem("token")}`)}
                  >
                    {getPreviewByExtension(message?.mentionedMessage?.AttachmentUploadId?.extension, message?.mentionedMessage?.AttachmentUploadId?.url)}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          <p className="chat-time mb-0 align-items-center d-flex mt-0">
            {message?.senderId?._id != currentUser?._id ? (
              <>{message?.isMsgSeen ? <i className="bx bx-check-double text-info me-1 font-size-18"></i> : <i className="bx bx-check me-1 font-size-18"></i>}</>
            ) : null}

            {moment(message?.createdAt).fromNow()}
          </p>
        </div>
      </div>
    </li>
  );
};

export default DisplayMessageComp;
