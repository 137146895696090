import React from "react";
import { toast } from "react-toastify";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";
import { CommonService } from "src/services/CommonService";


const AvatarModeEnableModal: React.FC<{
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  loggedUser: any;
  setLoggedUser: (user: any) => void
}> = props => {

  const avatarModeEnable = () => {
    props.setShowModal(false);
    CommonService.updateIncognitoMode(true).then(res => {
      if (res.success) {
          props.setLoggedUser({ ...props.loggedUser, incognito: true, incognitoPopupShow: true });
          toast.success("You have enabled incognito mode.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
      } else {
        toast.error("Incognito mode update failed", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };
  const avatarModeDisable = () => {
    props.setShowModal(false);
    CommonService.updateIncognitoMode(false).then(res => {
      if (res.success) {
          props.setLoggedUser({ ...props.loggedUser, incognito: false, incognitoPopupShow: true });
          toast.success("You have disabled incognito mode.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
      } else {
        toast.error("Incognito mode update failed", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };
  
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
          <div
            className="modal-body"
            style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}
          >
              <div className="call-title" style={{ paddingBottom: "2vh" }}>
                <i className="fa fa-user"></i>&nbsp;Do you wish to enable Avatar Mode when taking calls ?
              </div>
            <div className="call-action-btns" style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}>
              <div
                className="btn btn-info accept-btn"
                onClick={avatarModeEnable}
              >
                Yes
              </div>
              <div className="btn btn-danger reject-btn" onClick={avatarModeDisable}>
                No
              </div>
            </div>
          </div>
      </Modal>
    </>
  ) : null;
};

export default AvatarModeEnableModal;
