import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import axios from "axios";
import { Appointment, AppointmentDetailsResponseModel } from "../models/Appointment";
import { SameDayAppointments } from "../models/SameDayAppointments";

export class AppointmentService {
  public static async viewAllAppointmentsByUser(customActiveTab?: string, limit?: number, offset?: number): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl(`viewAllAppointmentsByUser/` + customActiveTab + "/" + limit + "/" + offset);
    return await axios.get<void, AppResponse<Appointment[]>>(url);
  }

  public static async getAllUpcomingAppointments(limit?: number): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl(`viewAllUpcomingAppointments/` + limit);
    return await axios.get<void, AppResponse<Appointment[]>>(url);
  }

  public static async getAppointmentsByTherapist(limit?: number): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl(`viewAppointmentsByTherapist/` + limit);
    return await axios.get<void, AppResponse<Appointment[]>>(url);
  }

  public static async getAppointmentsByTherapistAdmin(therapistId?: string, limit?: number): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl(`viewAppointmentsByTherapistAdmin/` + therapistId + "/" + limit);
    return await axios.get<void, AppResponse<Appointment[]>>(url);
  }

  public static async createAppointmentByClient(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createAppointmentByClient`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, { appointment: data });
  }

  public static async createAppointmentByClientWithMatch(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createAppointmentByClientWithMatch`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, { appointment: data });
  }

  public static async createAppointmentByClientPublic(data: any): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl(`createAppointmentByClient`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, { appointment: data });
  }

  public static async createAppointmentByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createAppointmentByAdmin`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, { appointment: data });
  }

  public static async createAppointmentByTherapist(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createAppointmentByTherapist`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, { appointment: data });
  }

  public static async viewSingleAppointment(id: string): Promise<AppResponse<Appointment>> {
    const url = Util.apiAuthUrl(`viewSingleAppointment/${id}`);
    return await axios.get<void, AppResponse<Appointment>>(url);
  }

  public static async viewSingleAppointmentPublic(id: string): Promise<AppResponse<Appointment>> {
    const url = Util.apiPublicUrl(`viewSingleAppointment/${id}`);
    return await axios.get<void, AppResponse<Appointment>>(url);
  }

  public static async viewSingleAppointmentAdmin(id: string): Promise<AppResponse<Appointment>> {
    const url = Util.apiAuthUrl(`viewSingleAppointmentAdmin/${id}`);
    return await axios.get<void, AppResponse<Appointment>>(url);
  }

  public static async updateAppointment(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`updateAppointment`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async deleteAppointment(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteAppointment`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async sendReminderSms(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendReminderSms`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async deleteAppointmentPublic(data: any): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl(`deleteAppointment`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async deleteAppointmentAdmin(id: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteAppointmentAdmin/${id}`);
    return await axios.delete<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAppointmentByUserId(limit?: number, offset?: number): Promise<AppResponse<SameDayAppointments[]>> {
    const url = Util.apiAuthUrl(`viewAppointmentsByUserId/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<SameDayAppointments[]>>(url);
  }

  public static async getAllAppointmentByTherapistId(id: string): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl(`viewAllAppointmentsByTherapistId/${id}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllAppointmentByTherapistIdPublic(id: string): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiPublicUrl(`viewAllAppointmentsByTherapistId/${id}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllAppointmentByTherapistIdPublicForMonth(id: string, month: Date): Promise<AppResponse<Appointment[]>> {
    const data = { therapistId: id, selectedMonth: month };
    const url = Util.apiPublicUrl(`viewAllAppointmentsByTherapistIdForMonth`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAllAppointmentByTherapistIdAdmin(id: string): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl(`viewAllAppointmentsByTherapistIdAdmin/${id}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllAppointmentAndDetailsByUserId(data: any): Promise<AppResponse<AppointmentDetailsResponseModel>> {
    const url = Util.apiAuthUrl(`getAllAppointmentsAndDetailsByUserId`);
    return await axios.post<Partial<AppointmentDetailsResponseModel>, AppResponse<AppointmentDetailsResponseModel>>(url, data);
  }

  public static async getAllAppointmentAndDetailsByUserIdPublic(data: any): Promise<AppResponse<AppointmentDetailsResponseModel>> {
    const url = Util.apiPublicUrl(`getAllAppointmentsAndDetailsByUserId`);
    return await axios.post<Partial<AppointmentDetailsResponseModel>, AppResponse<AppointmentDetailsResponseModel>>(url, data);
  }

  public static async getAllAppointmentAndDetailsByUserIdAdmin(data: any): Promise<AppResponse<AppointmentDetailsResponseModel>> {
    const url = Util.apiAuthUrl(`getAllAppointmentsAndDetailsByUserIdAdmin`);
    return await axios.post<Partial<AppointmentDetailsResponseModel>, AppResponse<AppointmentDetailsResponseModel>>(url, data);
  }

  public static async getAllPendingAndCompletedAppointments(id: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllPendingAndCompletedAppointmentsByUser/${id}`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async updateApprovalStatus(data: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`updateApprovalStatus`);
    return await axios.post<void, AppResponse<string>>(url, data);
  }

  public static async updateApprovalStatusAutoCreatedAppointment(data: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`updateApprovalStatusByClientForClienAppointment`);
    return await axios.post<void, AppResponse<string>>(url, data);
  }

  public static async getAppointmentMeetingEndSessionData(data: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`bothJoinedTime`);
    return await axios.post<void, AppResponse<string>>(url, data);
  }

  public static async getTomorrowAppointmentScheduleForTherapist(id: any): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl(`tomorrowSchedule/${id}`);
    return await axios.get<void, AppResponse<any>>(url);
  }
}
