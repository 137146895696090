import React, { useState, useEffect } from "react";
import Spinner from "src/common/spinner/spinner";
import { MeetingData } from "src/models/MeetingData";
import { FormVersionService } from "src/services/FormVersionService";
import { CAFormType } from "src/models/clinicalAssesment/clinicalAssesment";
import { toast } from "react-toastify";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import AIGeneratedAssessment from "src/pages/NewAssessmentDigitalForm/AIGeneratedAssessment";
import NewClinicalAssessmentModal from "src/pages/Popup/NewClinicalAssessmentModal";
import ClinicalAssetmentModal from "src/pages/Popup/ClinicalAssessmentModal";
import Modal32 from "src/pages/Popup/Modal32";

interface PendingClinicalAppointmentsProps {
  pendingClinicalDetails: any[];
}

const PendingClinicalAppointments: React.FC<PendingClinicalAppointmentsProps> = ({ pendingClinicalDetails }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [clientDetails, setClientDetails] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const [selectedCAFormType, setSelectedCAFormType] = useState("");
  const [firstMeetingData, setFirstMeetingData] = useState([] as MeetingData[]);
  const [clinicalAssessmentInfo, setClinicalAssessmentInfo] = useState("");
  const [showClinicalAssessmentWithVersionModal, setShowClinicalAssessmentWithVersionModal] = useState(false);
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = useState<boolean>(false);
  const [clinicalAssessmentId, setClinicalAssessmentId] = useState("");
  const [clinicalAssessmentType, setClinicalAssessmentType] = useState("");
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showAiGeneratedAssessmentPopup, setShowAiGeneratedAssessmentPopup] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setClientDetails(pendingClinicalDetails);
    setIsLoading(false);
  }, [pendingClinicalDetails]);

  const handleNoteClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleClinicalAssessmentView = async (clientId: string) => {
    setSelectedClientId(clientId);
    setSelectedClientId(clientId);
    if (clientId != undefined) {
      setIsLoading(true);
      setSelectedCAFormType("");
      setFirstMeetingData([] as MeetingData[]);

      const result = await fetchClinicalAssessmentWithVersions(clientId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
        setShowClinicalAssessmentWithVersionModal(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };
  const fetchClinicalAssessmentWithVersions = async (clientIdForForm: string) => {
    try {
      const res = await FormVersionService.getClinicalAssessmentsWithAllVersions(clientIdForForm);

      if (res && res.success) {
        const { relatedCAFormType, caWithVersion, daWithVersion, meetingData } = res.data;

        if (relatedCAFormType == CAFormType.OLD_CA) {
          setSelectedCAFormType(CAFormType.OLD_CA);
          return { success: true, data: caWithVersion };
        } else if (relatedCAFormType == CAFormType.NEW_CA) {
          setSelectedCAFormType(CAFormType.NEW_CA);

          if (meetingData) {
            setFirstMeetingData(meetingData);
          }
          return { success: true, data: daWithVersion };
        } else {
          return { success: false };
        }
      } else {
        toast.error("Encountered an error while retrieving the assessment data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };
  const clinicalAssessment = () => {
    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowNewClinicalAssessmentModal(false);
      setShowClinicalAssessmentModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowClinicalAssessmentModal(false);
      setShowNewClinicalAssessmentModal(true);
    }
  };
  const generateClinicalPdf = (planId: string, tpType: TPType) => {
    setClinicalAssessmentId(planId);
    setClinicalAssessmentType(tpType);

    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowClinicalAssessmentPdfModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowNewClinicalAssessmentPdfModal(true);
    }
  };

  const generateAiAssessmentFunction = () => {
    setShowAiGeneratedAssessmentPopup(true);
  };
  const refreshModal32 = async (value: boolean) => {
    if (value) {
      const result = await fetchClinicalAssessmentWithVersions(selectedClientId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
      }
    }
  };
  const handleChildFunction = (isCalled: boolean) => {
    setShowAiGeneratedAssessmentPopup(isCalled);
  };

  const toggleSorting = () => {
    if (sortType === 'asc') {
      sortingAZ();
      setSortType('desc');
    } else {
      sortingZA();
      setSortType('asc');
    }
  };

  const sortingAZ = () => {
    const sortedData = [...clientDetails].sort((a: any, b: any) => {
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const sortingZA = () => {
    const sortedData = [...clientDetails].sort((a: any, b: any) => {
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredClients = clientDetails.filter((client) => {
    const fullName = `${client.clientDetails?.firstname} ${client.clientDetails?.lastname}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <React.Fragment>
      {showClinicalAssessmentWithVersionModal && (
        <Modal32
          setShowModal={setShowClinicalAssessmentWithVersionModal}
          showModal={showClinicalAssessmentWithVersionModal}
          clinicalAssessmentInfo={clinicalAssessmentInfo}
          openClinicalAssessment={clinicalAssessment}
          downloadClinicalAssessment={generateClinicalPdf}
          isMobile={isMobile}
          isAdmin={false}
          generateAiAssessmentFunction={generateAiAssessmentFunction}
          firstMeetingData={firstMeetingData}
          isMeeting={false}
        />
      )}
      {showClinicalAssessmentModal && (
        <ClinicalAssetmentModal
          setShowModal={setShowClinicalAssessmentModal}
          showModal={showClinicalAssessmentModal}
          identifierId={selectedClientId}
          isAdmin={false}
          isFromMeeting={false}
          setModalRefresher={refreshModal32}
        />
      )}
      {showNewClinicalAssessmentModal && (
        <NewClinicalAssessmentModal
          showModal={showNewClinicalAssessmentModal}
          identifierId={selectedClientId}
          setShowModal={setShowNewClinicalAssessmentModal}
          isFromMeeting={false}
          isAdmin={false}
          setModalRefresher={refreshModal32}
          aiGeneratedAssessmentData={null}
          isMeeting={false}
        />
      )}
      {showAiGeneratedAssessmentPopup && (
        <AIGeneratedAssessment
          OpenAiModel={showAiGeneratedAssessmentPopup}
          transcribeData={firstMeetingData}
          closeModelFunction={handleChildFunction}
          refreshModal32={refreshModal32}
          isInMeeting={false}
          clientId={selectedClientId}
        />
      )}
      <div className="clinical-notes-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="clinical-notes-header-container d-flex flex-lg-row flex-md-row flex-sm-column flex-column justify-content-between align-items-center">
              <div className={"d-flex flex-row align-items-center fs-5 fw-bold"}>
                Pending Clinical Assessments: Clients List
                <span className={"ms-2"}>
                  <i onClick={toggleSorting} className={"bx bx-sort"}></i>
                </span>
              </div>
              <div className="search-container d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search clients..."
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ width: "250px", height: "35px" }}
                />
              </div>
            </div>
            <div className="clinical-notes-list">
              {filteredClients.length > 0 ? (
                filteredClients.map((client, index) => {
                  return (
                    <div
                      key={index}
                      className={`clinical-note-item ${index === activeIndex ? "active" : ""}`}
                      onClick={() => handleNoteClick(index)}
                    >
                      <div className="clinical-note-icon"></div>
                      <span className="clinical-note-name">
                        {client.clientDetails?.firstname} {client.clientDetails?.lastname}
                      </span>
                      <button
                        className="clinical-note-button"
                        onClick={() => {
                          console.log("clientDetails._id:", client.clientDetails?._id); // Log clientDetails._id
                          handleClinicalAssessmentView(client.clientDetails?._id); // Use _id for client ID
                        }}
                      >
                        View
                      </button>
                    </div>
                  );
                })
              ) : (
                <p>No pending appointments available</p>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default PendingClinicalAppointments;
