import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Input } from "reactstrap";
import { auto } from "@popperjs/core";
import PhoneInput from "react-phone-number-input";
import { WellCareAuthForm } from "src/models/AuthorizationForm/wellcare-auth-form-model";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { toast } from "react-toastify";
import { WellCareClinicalInformationArray } from "src/models/AuthorizationForm/subModels/WellcareAuthFormSubModels/wellcare-clinical-information-model ";
import { RequestType } from "src/models/AuthorizationForm/subModels/WellcareAuthFormSubModels/wellcare-authorization-model";
import { AuthFormType } from "src/models/AuthorizationFormData";
import moment from "moment";

const WellCareForm = (props: any) => {
    const { clientId, insuranceCompanyId, formId, getUpdateFormId, wellCareData } = props;
    const [isUpdate, setIsUpdate] = useState(props.isUpdate);
    const [formData, setFormData] = useState<WellCareAuthForm>({
        authorizationInformation: {
            typeOfRequest: undefined,
        },
    });
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setIsUpdate(props.isUpdate);
    }, [props.isUpdate]);

    useEffect(() => {
        const updatewellCareData: WellCareAuthForm = {
            authorizationInformation: wellCareData?.authorizationInformation,
            memberInformation: wellCareData?.memberInformation,
            providerInformation: wellCareData?.providerInformation,
            facilityInformation: wellCareData?.facilityInformation,
            serviceTypeInformation: wellCareData?.serviceTypeInformation,
            placeTypeInformation: wellCareData?.placeTypeInformation,
            clinicalInformation: wellCareData?.clinicalInformation,
        };
        setFormData(updatewellCareData);
    }, [wellCareData]);

    function autoResizeTextarea(e: any) {
        const textarea = e.target;
        textarea.style.height = 'auto';

        const newHeight = Math.min(textarea.scrollHeight, 3 * 16);
        textarea.style.height = `${newHeight}px`;

        textarea.style.overflowY = textarea.scrollHeight > newHeight ? 'scroll' : 'hidden';
    }

    const saveWellCareAuthForm = async () => {
        setIsSaving(true);
        const wellCareAuthFormDetails: WellCareAuthForm = {
            _id: formId,
            clientId: clientId,
            insuranceCompanyId: insuranceCompanyId,
            authFormType: AuthFormType.WellcareAuthForm,
            authorizationInformation: formData?.authorizationInformation,
            memberInformation: formData?.memberInformation,
            providerInformation: formData?.providerInformation,
            facilityInformation: formData?.facilityInformation,
            serviceTypeInformation: formData?.serviceTypeInformation,
            placeTypeInformation: formData?.placeTypeInformation,
            clinicalInformation: formData?.clinicalInformation,
        }
        try {
            let res;
            if (isUpdate === false) {
                res = await AuthorizationFormService.createWellCareAuthForm(wellCareAuthFormDetails).then(res => {
                    if (res.success) {
                        toast.success("Data has been successfully saved!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        setIsUpdate(true);
                        props.closePopup();
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
            } else {
                res = await AuthorizationFormService.updateAuthorizationForm(AuthFormType.WellcareAuthForm, wellCareAuthFormDetails);
                if (res && res.success && res.data) {
                    getUpdateFormId(res.data._id);
                    toast.success("Well Care form has been successfully updated!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    props.closePopup();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error(`Error occurred! Error details: ${error}.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setIsSaving(false);
        }
    }

    // const formatDate = (date: any) => {
    //     if (!date) return '';
    //     const d = new Date(date);
    //     const year = d.getFullYear();
    //     const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    //     const day = String(d.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    const formatDate = (date: any) => {
        if (!date) return '';
        return moment.utc(date).format('YYYY-MM-DD');
    };

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
                <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
                    <Row className="align-items-center">
                        <Col xs="12" sm="5" className="text-center text-sm-start">
                            <div>
                                <img
                                    src="/static/assets/img/authorization/wellcare.png"
                                    alt="Wellcare Insurance Company Logo"
                                    className="img-fluid"
                                    style={{ maxWidth: '200px' }}
                                />
                            </div>
                        </Col>
                        <Col xs="12" sm="7" className="text-center text-sm-end">
                            <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                                <span style={{ fontSize: "15px", color: "#333", fontWeight: "500" }}>For Faster, Self-Service: <a href="https://provider.wellcare.com" target="_blank" rel="noreferrer noopener">provider.wellcare.com</a></span><br />
                                <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request</span><br />
                            </p>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ backgroundColor: "#E6E6E6", border: "1px solid #B2B2B2", padding: "0.5rem", marginBottom: "0.60rem", marginTop: "0.15rem" }}>
                    <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>

                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                <p style={{ margin: "0" }}>
                                    Clinical information and supportive documentation should consist of current physician order, notes and recent
                                    diagnostics.<strong> Notification is required for any date of service change.</strong>
                                </p>
                            </div>

                        </div>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                                <div className="row">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Date :</h6>
                                    </div>
                                    <div className="col">
                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                            type="date"
                                            value={formatDate(formData?.authorizationInformation?.date)}
                                            onChange={(e) => {
                                                const date = new Date(e.target.value);
                                                setFormData({
                                                    ...formData,
                                                    authorizationInformation: {
                                                        ...formData?.authorizationInformation,
                                                        date: date
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                                <div className="row">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Requesting Provider :</h6>
                                    </div>
                                    <div className="col">
                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                            type="text"
                                            value={formData?.authorizationInformation?.requestingProvider || ''}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                authorizationInformation: {
                                                    ...formData?.authorizationInformation,
                                                    requestingProvider: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                                <div className="row">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Contact Name  :</h6>
                                    </div>
                                    <div className="col">
                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                            type="text"
                                            value={formData?.authorizationInformation?.contactName || ''}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                authorizationInformation: {
                                                    ...formData?.authorizationInformation,
                                                    contactName: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Card style={{ marginLeft: "0.85rem", marginBottom: "0.5rem", marginRight: "0.85rem" }}>
                                <div className="row">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>NPI :</h6>
                                    </div>
                                    <div className="col">
                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                            type="text"
                                            value={formData?.authorizationInformation?.npi || ''}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                authorizationInformation: {
                                                    ...formData?.authorizationInformation,
                                                    npi: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                                <div className="row">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>TIN :</h6>
                                    </div>
                                    <div className="col">
                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                            type="text"
                                            value={formData?.authorizationInformation?.tin || ''}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                authorizationInformation: {
                                                    ...formData?.authorizationInformation,
                                                    tin: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col lg={6}>
                            <Card style={{ marginLeft: "0.85rem", marginBottom: "0.5rem", marginRight: "0.85rem" }}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Consumer Phone :</h6>
                                    </div>
                                    <div className="col">
                                        <div className="col">
                                            <PhoneInput
                                                defaultCountry="US"
                                                value={formData?.authorizationInformation?.phoneNumber || ''}
                                                onChange={(value) => {
                                                    setFormData({
                                                        ...formData,
                                                        authorizationInformation: {
                                                            ...formData?.authorizationInformation,
                                                            phoneNumber: value
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Fax Number :</h6>
                                    </div>
                                    <div className="col">
                                        <PhoneInput
                                            defaultCountry="US"
                                            value={formData?.authorizationInformation?.faxNumber || ''}
                                            onChange={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    authorizationInformation: {
                                                        ...formData?.authorizationInformation,
                                                        faxNumber: value
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xl={auto} md={12}>
                            <h6 className="fw-500" style={{ margin: "0.2rem", fontSize: "12px" }}><strong><u>Type of Request :</u></strong></h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="row">
                                <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="typeOfRequest"
                                        checked={formData?.authorizationInformation?.typeOfRequest === RequestType.URGENT}
                                        onClick={() => setFormData({
                                            ...formData, authorizationInformation: { ...formData.authorizationInformation, typeOfRequest: RequestType.URGENT }
                                        })}
                                    />
                                </div>
                                <div className="col">
                                    <Card style={{ backgroundColor: "#FFFFFF", marginBottom: "0.25rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Urgent <i>(Urgent is defined as ‘significant impact to health of member’)</i></h6>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={auto} lg={6}>
                            <div className="col-auto">
                                <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    name="typeOfRequest"
                                    checked={formData?.authorizationInformation?.typeOfRequest === RequestType.NON_URGENT}
                                    onClick={() => setFormData({
                                        ...formData, authorizationInformation: { ...formData.authorizationInformation, typeOfRequest: RequestType.NON_URGENT }
                                    })}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Non-Urgent</label>
                            </div>
                        </Col>
                        <Col xl={auto} lg={6}>
                            <div className="col-auto">
                                <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    name="typeOfRequest"
                                    checked={formData?.authorizationInformation?.typeOfRequest === RequestType.PRE_SERVICE}
                                    onClick={() => setFormData({
                                        ...formData, authorizationInformation: { ...formData.authorizationInformation, typeOfRequest: RequestType.PRE_SERVICE }
                                    })}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Pre-Service </label>
                            </div>
                        </Col>
                        <Col xl={auto} lg={6}>
                            <div className="col-auto">
                                <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    name="typeOfRequest"
                                    checked={formData?.authorizationInformation?.typeOfRequest === RequestType.POST_SERVICE}
                                    onClick={() => setFormData({
                                        ...formData, authorizationInformation: { ...formData.authorizationInformation, typeOfRequest: RequestType.POST_SERVICE }
                                    })}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Post-Service (Retro) </label>
                            </div>
                        </Col>
                        <Col xl={auto} lg={6}>
                            <div className="col-auto">
                                <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    name="typeOfRequest"
                                    checked={formData?.authorizationInformation?.typeOfRequest === RequestType.CON_CURRENT}
                                    onClick={() => setFormData({
                                        ...formData, authorizationInformation: { ...formData.authorizationInformation, typeOfRequest: RequestType.CON_CURRENT }
                                    })}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Concurrent</label>
                            </div>
                        </Col>
                        <Col xl={auto} lg={6}>
                            <div className="col-auto">
                                <input
                                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    className="form-check-input"
                                    name="typeOfRequest"
                                    checked={formData?.authorizationInformation?.typeOfRequest === RequestType.EMERGENT}
                                    onClick={() => setFormData({
                                        ...formData, authorizationInformation: { ...formData.authorizationInformation, typeOfRequest: RequestType.EMERGENT }
                                    })}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Emergent</label>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Row className="mt-2">
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Member Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Member Name :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '0.75',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.memberInformation?.memberName || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            memberName: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Date of birth :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Medicaid ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '0.75',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.memberInformation?.medicaidId || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            medicaidId: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>WellCare ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '0.75',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.memberInformation?.wellcareId || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            wellcareId: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="4">
                                            <label style={{ fontSize: "12px" }}>Address (street, city, state, ZIP code) :</label>
                                        </Col>
                                        <Col xs="12" sm="8">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.memberInformation?.memberAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            memberAddress: e.target.value
                                                        }
                                                    })}
                                                >
                                                </textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xl={4}><h6 className="fw-500" style={{ fontSize: "12px" }}>Is this member pregnant ?</h6></Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData?.memberInformation?.isPregnant === true}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: true } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Yes</label>
                                            </div>
                                        </Col>
                                        <Col xl={5}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData?.memberInformation?.isPregnant === false}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: false } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>No</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Member’s PCP :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.memberInformation?.memberPcp || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            memberPcp: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.memberInformation?.phoneNumber || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            memberInformation: {
                                                                ...formData?.memberInformation,
                                                                phoneNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>NPI :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.memberInformation?.npi || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            npi: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>Treating / Servicing Provider Information</h5>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        name="sameAsRequest"
                                        style={{ fontSize: "larger" }}
                                        checked={formData?.providerInformation?.sameAsRequest}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                providerInformation: {
                                                    ...prevState?.providerInformation,
                                                    sameAsRequest: !formData.providerInformation?.sameAsRequest
                                                }
                                            }));
                                        }}
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Same a Requesting
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Provider name :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.providerName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            providerName: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>WellCare ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.wellcareId || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            wellcareId: e.target.value
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>NPI :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.npi || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            npi: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Tax ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.taxId || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            taxId: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Address  :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.providerInformation?.providerAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            providerAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="1" >
                                            <label style={{ fontSize: "12px" }}>City :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.city || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            city: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="1">
                                            <label style={{ fontSize: "12px" }}>State :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.state || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            state: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Zip code :</label>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.zipCode || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            zipCode: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.providerInformation?.phoneNumber || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                phoneNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <label style={{ fontSize: "12px" }}>Fax Number :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.providerInformation?.faxNumber || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                faxNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xl={4}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" , fontSize:"12px" }}>Are any supporting documents included? </h6>
                                        </Col>
                                        <Col xl={2}>
                                            <div className="col-auto d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isDocumentsIncluded"
                                                    checked={formData?.providerInformation?.isDocumentsIncluded === true}
                                                    onClick={() => setFormData({ ...formData, providerInformation: { ...formData?.providerInformation, isDocumentsIncluded: true } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Yes</label>
                                            </div>

                                        </Col>
                                        <Col xl={2}>
                                            <div className="col-auto d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isDocumentsIncluded"
                                                    checked={formData?.providerInformation?.isDocumentsIncluded === false}
                                                    onClick={e => setFormData({ ...formData, providerInformation: { ...formData?.providerInformation, isDocumentsIncluded: false, documentsAmount: "" } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>No</label>
                                            </div>

                                        </Col>
                                        <Col xl={4}>
                                            <div className="row">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px"  }}>Number of documents :</h6>
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    disabled={formData?.providerInformation?.isDocumentsIncluded !== true}
                                                                    value={formData?.providerInformation?.documentsAmount || ''}
                                                                    onChange={e => setFormData({ ...formData, providerInformation: { ...formData?.providerInformation, documentsAmount: e.target.value } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Facility / Ancillary Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Facility Name :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.facilityName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            facilityName: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>WellCare ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.wellcareId || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            wellcareId: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>NPI :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.npi || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            npi: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Tax ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.taxId || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            taxId: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Address  :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.facilityInformation?.address || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            address: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="1">
                                            <label style={{ fontSize: "12px" }}>City :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.city || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            city: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="1">
                                            <label style={{ fontSize: "12px" }}>State :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.state || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            state: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Zip code :</label>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.zipCode || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            zipCode: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.facilityInformation?.phoneNumber || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            facilityInformation: {
                                                                ...formData?.facilityInformation,
                                                                phoneNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <label style={{ fontSize: "12px" }}>Fax Number :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.facilityInformation?.faxNumber || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            facilityInformation: {
                                                                ...formData?.facilityInformation,
                                                                faxNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xl={4}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem", fontSize:"12px"  }}>Are any supporting documents included? </h6>
                                        </Col>
                                        <Col xl={2}>
                                            <div className="col-auto d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isFacilityDocumentsIncluded"
                                                    checked={formData?.facilityInformation?.isDocumentsIncluded === true}
                                                    onClick={() => setFormData({ ...formData, facilityInformation: { ...formData?.facilityInformation, isDocumentsIncluded: true } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Yes</label>
                                            </div>

                                        </Col>
                                        <Col xl={2}>
                                            <div className="col-auto d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isFacilityDocumentsIncluded"
                                                    checked={formData?.facilityInformation?.isDocumentsIncluded === false}
                                                    onClick={() => setFormData({ ...formData, facilityInformation: { ...formData?.facilityInformation, isDocumentsIncluded: false, documentsAmount: "" } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px" }}>No</label>
                                            </div>

                                        </Col>
                                        <Col xl={4}>
                                            <div className="row">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>Number of documents :</h6>
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    disabled={formData?.facilityInformation?.isDocumentsIncluded !== true}
                                                                    value={formData?.facilityInformation?.documentsAmount || ''}
                                                                    onChange={e => setFormData({ ...formData, facilityInformation: { ...formData?.facilityInformation, documentsAmount: e.target.value } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Type of Service</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row><Card className="match-card-header-secondary p-1 mb-2">
                                        <div className="fw-500" style={{ margin: "auto", fontSize: "14px" }}>BEHAVIORAL HEALTH SERVICES</div>
                                    </Card>
                                    </Row>
                                    <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                                        <Row className="mt-2">
                                            <Col xl={6} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.intensiveOutpatient}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    intensiveOutpatient: !formData.serviceTypeInformation?.behaviourService?.intensiveOutpatient
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Intensive Outpatient</label>
                                            </Col>
                                            <Col xl={6} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.routineOutpatient}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    routineOutpatient: !formData.serviceTypeInformation?.behaviourService?.routineOutpatient
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Routine Outpatient</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.caseManagement}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    caseManagement: !formData.serviceTypeInformation?.behaviourService?.caseManagement
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Case Management</label>
                                            </Col>
                                            <Col xl={6} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.ect}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    ect: !formData.serviceTypeInformation?.behaviourService?.ect
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – ECT</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.subAcute}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    subAcute: !formData.serviceTypeInformation?.behaviourService?.subAcute
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Sub Acute</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.csu}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    csu: !formData.serviceTypeInformation?.behaviourService?.csu
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – CSU</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.inpatient}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    inpatient: !formData.serviceTypeInformation?.behaviourService?.inpatient
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Inpatient </label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.detox}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    detox: !formData.serviceTypeInformation?.behaviourService?.detox
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" , fontSize:"12px" }}>Behavioral Health – Detox</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.rehabilitation}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    rehabilitation: !formData.serviceTypeInformation?.behaviourService?.rehabilitation
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Rehabilitation</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.residential}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    residential: !formData.serviceTypeInformation?.behaviourService?.residential
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Behavioral Health – Residential </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.behaviourService?.other}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                behaviourService: {
                                                                    ...prevState?.serviceTypeInformation?.behaviourService,
                                                                    other: !formData.serviceTypeInformation?.behaviourService?.other, detailsForOther: ""
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Other : </label>

                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    value={formData?.serviceTypeInformation?.behaviourService?.detailsForOther || ""}
                                                                    disabled={formData?.serviceTypeInformation?.behaviourService?.other !== true}
                                                                    onChange={e => setFormData({
                                                                        ...formData,
                                                                        serviceTypeInformation: {
                                                                            ...formData?.serviceTypeInformation,
                                                                            behaviourService: {
                                                                                ...formData?.serviceTypeInformation?.behaviourService,
                                                                                detailsForOther: e.target.value
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row></Card>
                                    <Row><Card className="match-card-header-secondary p-1 mb-2">
                                        <div className="fw-500" style={{ margin: "auto", fontSize: "14px" }}>MEDICAL SERVICES</div>
                                    </Card>
                                    </Row>
                                    <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                                        <Row className="mt-2">
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.dmePurchase}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    dmePurchase: !formData.serviceTypeInformation?.medicalService?.dmePurchase
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>DME Purchase </label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.dmeRental}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    dmeRental: !formData.serviceTypeInformation?.medicalService?.dmeRental
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>DME Rental</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.homeHealth}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    homeHealth: !formData.serviceTypeInformation?.medicalService?.homeHealth
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Home Health</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.inpatientAdmission}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    inpatientAdmission: !formData.serviceTypeInformation?.medicalService?.inpatientAdmission
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Inpatient Admission </label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.inpatientRehab}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    inpatientRehab: !formData.serviceTypeInformation?.medicalService?.inpatientRehab
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Inpatient Rehab</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.snf}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    snf: !formData.serviceTypeInformation?.medicalService?.snf
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>SNF</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.surgeryInpatient}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    surgeryInpatient: !formData.serviceTypeInformation?.medicalService?.surgeryInpatient
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Surgery – Pre-Planned Inpatient</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.skilledTherapy}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    skilledTherapy: !formData.serviceTypeInformation?.medicalService?.skilledTherapy
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Skilled Therapy (PT/OT/ST)</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.surgeryOutpatient}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    surgeryOutpatient: !formData.serviceTypeInformation?.medicalService?.surgeryOutpatient
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Surgery – Outpatient</label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xl={4} className="d-flex">
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.ltach}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    ltach: !formData.serviceTypeInformation?.medicalService?.ltach
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>LTACH</label>
                                            </Col>
                                            <Col xl={8} className="d-flex">
                                                <input style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.medicalService?.other}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                medicalService: {
                                                                    ...prevState?.serviceTypeInformation?.medicalService,
                                                                    other: !formData.serviceTypeInformation?.medicalService?.other, detailsForOther: ""
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Other :  </label>
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    value={formData?.serviceTypeInformation?.medicalService?.detailsForOther || ""}
                                                                    disabled={formData?.serviceTypeInformation?.medicalService?.other !== true}
                                                                    onChange={e => setFormData({
                                                                        ...formData,
                                                                        serviceTypeInformation: {
                                                                            ...formData?.serviceTypeInformation,
                                                                            medicalService: {
                                                                                ...formData?.serviceTypeInformation?.medicalService,
                                                                                detailsForOther: e.target.value
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Row><Card className="match-card-header-secondary p-1 mb-2">
                                        <div className="fw-500" style={{ margin: "auto", fontSize: "14px" }}>TRANSPOTATION</div>
                                    </Card>
                                    </Row>
                                    <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                                        <Row className="mt-2">
                                            <Col xl={3} className="d-flex">
                                                <input style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.transpotation?.air}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                transpotation: {
                                                                    ...prevState?.serviceTypeInformation?.transpotation,
                                                                    air: !formData.serviceTypeInformation?.transpotation?.air
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Air</label>
                                            </Col>
                                            <Col xl={3} className="d-flex">
                                                <input style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData.serviceTypeInformation?.transpotation?.land}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            serviceTypeInformation: {
                                                                ...prevState?.serviceTypeInformation,
                                                                transpotation: {
                                                                    ...prevState?.serviceTypeInformation?.transpotation,
                                                                    land: !formData.serviceTypeInformation?.transpotation?.land
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Land</label>
                                            </Col>
                                            <Col xl={3} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Mileage : </label>
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    value={formData.serviceTypeInformation?.transpotation?.mileage || ''}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        serviceTypeInformation: {
                                                                            ...formData?.serviceTypeInformation,
                                                                            transpotation: {
                                                                                ...formData?.serviceTypeInformation?.transpotation,
                                                                                mileage: e.target.value
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col xl={3} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Trips : </label>
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    value={formData.serviceTypeInformation?.transpotation?.trip || ''}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        serviceTypeInformation: {
                                                                            ...formData?.serviceTypeInformation,
                                                                            transpotation: {
                                                                                ...formData?.serviceTypeInformation?.transpotation,
                                                                                trip: e.target.value
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mb-1">
                                            <Col xl={5}>
                                                <h6 className="fw-500" style={{ margin: "0.2rem", fontSize:"12px" }}>O2 Needed </h6>
                                            </Col>
                                            <Col xl={4}>
                                                <div className="col-auto d-flex">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="isO2Needed"
                                                        checked={formData.serviceTypeInformation?.transpotation?.isO2Needed === true}
                                                        onClick={() => {
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                serviceTypeInformation: {
                                                                    ...prevState.serviceTypeInformation,
                                                                    transpotation: {
                                                                        ...prevState.serviceTypeInformation?.transpotation,
                                                                        isO2Needed: true,
                                                                    },
                                                                },
                                                            }));
                                                        }}
                                                    />
                                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Yes</label>
                                                </div>

                                            </Col>
                                            <Col xl={3}>
                                                <div className="col-auto d-flex">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="isO2Needed"
                                                        checked={formData.serviceTypeInformation?.transpotation?.isO2Needed === false}
                                                        onClick={() => {
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                serviceTypeInformation: {
                                                                    ...prevState.serviceTypeInformation,
                                                                    transpotation: {
                                                                        ...prevState.serviceTypeInformation?.transpotation,
                                                                        isO2Needed: false,
                                                                    },
                                                                },
                                                            }));
                                                        }}
                                                    />
                                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>No</label>
                                                </div>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={5} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Pick Up Address (Street, City, State, Zip Code)   : </label>
                                            </Col><Col xl={7} className="d-flex">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    value={formData.serviceTypeInformation?.transpotation?.pickUpAddress || ''}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        serviceTypeInformation: {
                                                                            ...formData?.serviceTypeInformation,
                                                                            transpotation: {
                                                                                ...formData?.serviceTypeInformation?.transpotation,
                                                                                pickUpAddress: e.target.value
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={5} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Drop off Address (Street, City, State, Zip Code) : </label>
                                            </Col>
                                            <Col xl={7} className="d-flex">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    value={formData.serviceTypeInformation?.transpotation?.dropOffAddress || ''}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        serviceTypeInformation: {
                                                                            ...formData?.serviceTypeInformation,
                                                                            transpotation: {
                                                                                ...formData?.serviceTypeInformation?.transpotation,
                                                                                dropOffAddress: e.target.value
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Card>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Place of Service</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row >
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.office}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            office: !formData.placeTypeInformation?.office
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>11 – Office</label>
                                        </Col>
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.home}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            home: !formData.placeTypeInformation?.home
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>12 – Home</label>
                                        </Col>
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.inpatient}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            inpatient: !formData.placeTypeInformation?.inpatient
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>21 – Inpatient</label>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.outpatient}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            outpatient: !formData.placeTypeInformation?.outpatient
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>22 – Outpatient</label>
                                        </Col>
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.ambulatorySurgery}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            ambulatorySurgery: !formData.placeTypeInformation?.ambulatorySurgery
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>24 – Ambulatory Surgery Center</label>
                                        </Col>
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.landAmbulance}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            landAmbulance: !formData.placeTypeInformation?.landAmbulance
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>41 – Land Ambulance</label>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.airAmbulance}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            airAmbulance: !formData.placeTypeInformation?.airAmbulance
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>41 - Air Ambulance</label>
                                        </Col>
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.inpatientPsychiatric}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            inpatientPsychiatric: !formData.placeTypeInformation?.inpatientPsychiatric
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>51 – Inpatient Psychiatric Hospital</label>
                                        </Col>
                                        <Col xl={4} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.communityMentalHealth}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            communityMentalHealth: !formData.placeTypeInformation?.communityMentalHealth
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>53 – Community Mental Health Center</label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xl={12} className="d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.placeTypeInformation?.other}
                                                onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        placeTypeInformation: {
                                                            ...prevState.placeTypeInformation,
                                                            other: !formData.placeTypeInformation?.other, detailsForOther: ""
                                                        }
                                                    }));
                                                }}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Other : </label>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                value={formData?.placeTypeInformation?.detailsForOther || ""}
                                                                disabled={formData?.placeTypeInformation?.other !== true}
                                                                onChange={e => setFormData({
                                                                    ...formData,
                                                                    placeTypeInformation: {
                                                                        ...formData?.placeTypeInformation,
                                                                        detailsForOther: e.target.value
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Clinical Information</h5>
                            <h6 className="fw-500" style={{ margin: "auto" }}>(Request MUST include medical documentation to be reviewed for medical necessity)</h6>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <Table responsive className="clinical-table-bordered table-md">
                                    <thead>
                                        <tr>
                                            <th colSpan={5} style={{ border: "1px solid #B2B2B2", textAlign: "left", verticalAlign: "middle", lineHeight: "0.3" }}>
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                                                    <div className="row">
                                                        <div className="col" style={{ borderRight: "1px solid #B2B2B2" }}>
                                                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                value={formData?.clinicalInformation?.icdCode1 || ''}
                                                                onChange={(e) => setFormData({
                                                                    ...formData,
                                                                    clinicalInformation: {
                                                                        ...formData?.clinicalInformation,
                                                                        icdCode1: e.target.value
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="col" style={{ borderRight: "1px solid #B2B2B2" }}>
                                                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                value={formData?.clinicalInformation?.icdCode2 || ''}
                                                                onChange={(e) => setFormData({
                                                                    ...formData,
                                                                    clinicalInformation: {
                                                                        ...formData?.clinicalInformation,
                                                                        icdCode2: e.target.value
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="col" style={{ borderRight: "1px solid #B2B2B2" }}>
                                                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                value={formData?.clinicalInformation?.icdCode3 || ''}
                                                                onChange={(e) => setFormData({
                                                                    ...formData,
                                                                    clinicalInformation: {
                                                                        ...formData?.clinicalInformation,
                                                                        icdCode3: e.target.value
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                value={formData?.clinicalInformation?.icdCode4 || ''}
                                                                onChange={(e) => setFormData({
                                                                    ...formData,
                                                                    clinicalInformation: {
                                                                        ...formData?.clinicalInformation,
                                                                        icdCode4: e.target.value
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </th>
                                        </tr>

                                        <tr>
                                            <th colSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.3" }}>
                                                Dates of Service
                                            </th>
                                            <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    Procedure /
                                                    Service Codes
                                                </div>
                                            </th>
                                            <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    Description
                                                </div>
                                            </th>
                                            <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    Requested
                                                    Start Units/Visits/Days (please specify)
                                                </div>
                                            </th>

                                        </tr>
                                        <tr>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.8", fontSize: "11px" }}>
                                                Start Date
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.8", fontSize: "11px" }}>
                                                Projected End Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(4)].map((_, index) => (
                                            <tr key={index}>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                                        value={formatDate(formData?.clinicalInformation?.clinicalInformationArray?.[index]?.startDate)}
                                                        onChange={(e) => {
                                                            const updatedClinicalInfoArray = formData?.clinicalInformation?.clinicalInformationArray
                                                                ? [...formData.clinicalInformation.clinicalInformationArray]
                                                                : Array(4).fill({} as WellCareClinicalInformationArray);
                                                            updatedClinicalInfoArray[index] = {
                                                                ...updatedClinicalInfoArray[index],
                                                                startDate: new Date(e.target.value),
                                                            };
                                                            setFormData({
                                                                ...formData,
                                                                clinicalInformation: {
                                                                    ...formData?.clinicalInformation,
                                                                    clinicalInformationArray: updatedClinicalInfoArray,
                                                                },
                                                            });
                                                        }} />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                                        value={formatDate(formData?.clinicalInformation?.clinicalInformationArray?.[index]?.endDate)}
                                                        onChange={(e) => {
                                                            const updatedClinicalInfoArray = formData?.clinicalInformation?.clinicalInformationArray
                                                                ? [...formData.clinicalInformation.clinicalInformationArray]
                                                                : Array(4).fill({} as WellCareClinicalInformationArray);
                                                            updatedClinicalInfoArray[index] = {
                                                                ...updatedClinicalInfoArray[index],
                                                                endDate: new Date(e.target.value),
                                                            };
                                                            setFormData({
                                                                ...formData,
                                                                clinicalInformation: {
                                                                    ...formData?.clinicalInformation,
                                                                    clinicalInformationArray: updatedClinicalInfoArray,
                                                                },
                                                            });
                                                        }} />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                                        value={formData?.clinicalInformation?.clinicalInformationArray?.[index]?.procedureCode || ''}
                                                        onChange={(e) => {
                                                            const updatedClinicalInfoArray = formData?.clinicalInformation?.clinicalInformationArray
                                                                ? [...formData.clinicalInformation.clinicalInformationArray]
                                                                : Array(4).fill({} as WellCareClinicalInformationArray);
                                                            updatedClinicalInfoArray[index] = {
                                                                ...updatedClinicalInfoArray[index],
                                                                procedureCode: e.target.value,
                                                            };
                                                            setFormData({
                                                                ...formData,
                                                                clinicalInformation: {
                                                                    ...formData?.clinicalInformation,
                                                                    clinicalInformationArray: updatedClinicalInfoArray,
                                                                },
                                                            });
                                                        }} />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <textarea
                                                        style={{
                                                            marginTop: "7px",
                                                            fontSize: "0.70rem",
                                                            border: "none",
                                                            lineHeight: "1.2",
                                                            overflowY: "auto",
                                                            resize: "none",
                                                            width: "100%",
                                                        }}
                                                        rows={1}
                                                        value={formData?.clinicalInformation?.clinicalInformationArray?.[index]?.description || ''}
                                                        onInput={(e) => {
                                                            const target = e.target as HTMLTextAreaElement;
                                                            const lineHeight = parseFloat(getComputedStyle(target).lineHeight);
                                                            const maxRows = 3;
                                                            target.style.height = 'auto';
                                                            const newHeight = Math.min(target.scrollHeight, lineHeight * maxRows);
                                                            target.style.height = `${newHeight}px`;
                                                        }}
                                                        onChange={(e) => {
                                                            const updatedClinicalInfoArray = formData?.clinicalInformation?.clinicalInformationArray
                                                                ? [...formData.clinicalInformation.clinicalInformationArray]
                                                                : Array(10).fill({} as WellCareClinicalInformationArray);
                                                            updatedClinicalInfoArray[index] = {
                                                                ...updatedClinicalInfoArray[index],
                                                                description: e.target.value,
                                                            };
                                                            setFormData({
                                                                ...formData,
                                                                clinicalInformation: {
                                                                    ...formData?.clinicalInformation,
                                                                    clinicalInformationArray: updatedClinicalInfoArray,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                                        value={formData?.clinicalInformation?.clinicalInformationArray?.[index]?.requestedUnit || ''}
                                                        onChange={(e) => {
                                                            const updatedClinicalInfoArray = formData?.clinicalInformation?.clinicalInformationArray
                                                                ? [...formData.clinicalInformation.clinicalInformationArray]
                                                                : Array(10).fill({} as WellCareClinicalInformationArray);
                                                            updatedClinicalInfoArray[index] = {
                                                                ...updatedClinicalInfoArray[index],
                                                                requestedUnit: e.target.value,
                                                            };
                                                            setFormData({
                                                                ...formData,
                                                                clinicalInformation: {
                                                                    ...formData?.clinicalInformation,
                                                                    clinicalInformationArray: updatedClinicalInfoArray,
                                                                },
                                                            });
                                                        }} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-600 mb-1" style={{ margin: "auto" }}>North Carolina Medicaid
                            </h5>
                            <div className="fw-500 mb-1" style={{ margin: "auto", fontSize: "14px" }}>Prior Authorization Phone Numbers
                            </div>
                            <div className="fw-500" style={{ margin: "auto", fontSize: "12px" }}><strong><i><u>Expedited Requests:</u></i></strong> If the standard time for making a determination could seriously jeopardize the life and/or
                                health of the member or the member’s ability to regain maximum function, please call <strong>1-866-799-5318</strong>.
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <Table responsive className="clinical-table-bordered table-md">
                                    <thead>
                                        <tr>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", padding: "1px", lineHeight: "2" }}  >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    DEPARTMENT
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", padding: "1px", lineHeight: "2" }}  >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    PHONE
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", padding: "1px", lineHeight: "2" }}  >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    FAX
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                                                All Medical
                                            </td>
                                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                                                <strong>1-866-799-5318 </strong>
                                            </td>
                                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                                                Inpatient – <strong>1-800-678-3170</strong><br />
                                                Outpatient – <strong>1-866-319-2691</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                                                Behavior Health
                                            </td>
                                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                                                <strong>1-866-799-5318 </strong>
                                            </td>
                                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                                                Outpatient – <strong> 1-866-319-2691</strong><br />
                                                Inpatient – <strong> 1-800-551-0325</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={saveWellCareAuthForm}
                            disabled={isSaving}
                        >
                            {isSaving ? "Saving..." : "Save"}
                        </button>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default WellCareForm;