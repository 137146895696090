import { SET_BUTTON_VIBRATE } from './actions';

// Initial State
const initialState = {
  isButtonVibrate: false
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUTTON_VIBRATE:
      return {
        ...state,
        isButtonVibrate: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
