import React, { useState } from 'react';
import { StepProps } from './types';
import { environment } from '../../../environment/environment';
import moment from 'moment';
import NewClinicalAssessmentPdfModal from '../../../pages/Popup/NewClinicalAssessmentPdfModal';
import ClinicalAssessmentPdfModal from '../../../pages/Popup/ClinicalAssessmentPdfModal';
import { TPType } from '../../../models/therapyPlan/therapyPlan';
import { CAFormType } from '../../../models/clinicalAssesment/clinicalAssesment';

interface Version {
  _id: string;
  reasonForEdit: string;
  versionCreatedAt: string;
}

interface Assessment {
  _id: string;
  createdAt: string;
  versions?: Version[];
}

interface AssessmentWithType extends Assessment {
  type: AssessmentType;
}

type AssessmentType = 'Digital Assessment' | 'Clinical Assessment';

const SelectAssessments: React.FC<StepProps> = ({
  searchDocumentDownloadsByAdminSelected,
  onNext,
  onBack
}) => {
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState(false);
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState('');
  const [selectedCheckboxId, setSelectedCheckboxId] = useState<string[]>([]);
  const [selectedAssessmentType, setSelectedAssessmentType] = useState<AssessmentType>('Digital Assessment');

  const digitalAssessments = searchDocumentDownloadsByAdminSelected?.docs?.digitalassessment || [];
  const clinicalAssessments = searchDocumentDownloadsByAdminSelected?.docs?.clinicalassessment || [];

  const handleAssessmentClick = (assessment: Assessment, type: AssessmentType) => {
    const hasVersions = assessment.versions && assessment.versions.length > 0;
    const assessmentId = hasVersions && assessment.versions 
      ? assessment.versions[assessment.versions.length - 1]._id 
      : assessment._id;
    
    setSelectedAssessmentId(assessmentId);
    setSelectedAssessmentType(type);
    
    if (type === 'Digital Assessment') {
      setShowNewClinicalAssessmentPdfModal(true);
      setShowClinicalAssessmentPdfModal(false);
    } else {
      setShowClinicalAssessmentPdfModal(true);
      setShowNewClinicalAssessmentPdfModal(false);
    }
  };

  const closeAssessmentModal = () => {
    setShowNewClinicalAssessmentPdfModal(false);
    setShowClinicalAssessmentPdfModal(false);
    setSelectedAssessmentId('');
  };
  const handleNextClick = () => {
    // console.log('Sending selected URLs to parent:', selectedCheckboxId);
    onNext(selectedCheckboxId);
  };

  return (
    <div>
      <h6 className="mb-4">Select Assessments</h6>
      <div className="table-responsive mb-4">
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: '40px' }}></th>
              <th>Assessment</th>
              <th>Date</th>
              <th>Assessment Type</th>
              <th>Assessment History</th>
            </tr>
          </thead>
          <tbody>
            {[...digitalAssessments.map((assessment: Assessment) => ({ ...assessment, type: 'Digital Assessment' as AssessmentType })),
              ...clinicalAssessments.map((assessment: Assessment) => ({ ...assessment, type: 'Clinical Assessment' as AssessmentType }))
            ].sort((a: AssessmentWithType, b: AssessmentWithType) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
            .map((assessment: AssessmentWithType) => (
              <tr key={`${assessment.type}-${assessment._id}`}>
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) => setSelectedCheckboxId(preValue => {
                      const currentId = assessment.versions && assessment.versions.length > 0
                        ? assessment.versions[assessment.versions.length - 1]._id
                        : assessment._id;
                      const type = assessment.versions && assessment.versions.length > 0 ? "VERSION" : "ORIGINAL";
                      
                      const url = `${environment.app_url}/new-clinical-assessment-pdf?identifierId=${currentId}&digitalAssessmentType=${type}`;
                      if(preValue.includes(url)) {
                        return preValue.filter(id => id !== url);
                      } else {
                        return [...preValue, url];
                      }
                    })}
                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                  />
                </td>
                <td>
                  <span 
                    className="text-primary" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleAssessmentClick(assessment, assessment.type)}
                  >
                    {assessment.versions && assessment.versions.length > 0 
                      ? assessment.versions[assessment.versions.length - 1]._id 
                      : assessment._id}
                  </span>
                </td>
                <td>{moment(assessment.createdAt).format('MMM D, YYYY, h:mm A')}</td>
                <td>{assessment.type}</td>
                <td>{assessment.versions && assessment.versions.length > 0 ? "Version" : "Orginal"}</td>
              </tr>
            ))}
            {digitalAssessments.length === 0 && clinicalAssessments.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center py-3 text-muted">
                  No assessments found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="alert alert-info" role="alert">
        <i className="fas fa-info-circle me-2"></i>
        Select the assessments you want to include in the audit PDF.
      </div>

      {showNewClinicalAssessmentPdfModal && (
        <NewClinicalAssessmentPdfModal 
          setShowModal={closeAssessmentModal} 
          showModal={showNewClinicalAssessmentPdfModal} 
          identifierId={selectedAssessmentId} 
          isAdmin={false} 
          eventType="DOWNLOAD" 
          insuranceDocApprovalId={null} 
          setModalRefresher={null} 
          digitalAssessmentType={selectedAssessmentId && digitalAssessments.some((a: Assessment) => 
            a.versions?.some((v: Version) => v._id === selectedAssessmentId)
          ) ? TPType.VERSION : TPType.ORIGINAL}
          // digitalAssessmentType={TPType.VERSION}
        />
      )}
      {showClinicalAssessmentPdfModal && (
        <ClinicalAssessmentPdfModal 
          setShowModal={closeAssessmentModal} 
          showModal={showClinicalAssessmentPdfModal} 
          identifierId={selectedAssessmentId} 
          isAdmin={true} 
          eventType="DOWNLOAD" 
          insuranceDocApprovalId={null} 
          setModalRefresher={null} 
          clinicalAssessmentType={selectedAssessmentId && clinicalAssessments.some((a: Assessment) => 
            a.versions?.some((v: Version) => v._id === selectedAssessmentId)
          ) ? TPType.VERSION : TPType.ORIGINAL}
        />
      )}
      <div className="d-flex justify-content-end mt-4" style={{ gap: '10px' }}>
        <button
          className="btn btn-secondary"
          onClick={onBack}
        >
          Back
        </button>
        <button
          className="btn btn-primary"
          onClick={handleNextClick}
          // disabled={selectedCheckboxId.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectAssessments;
