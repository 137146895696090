import { CardContent, TextField } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button, FormGroup, Input, Label, ModalBody, Modal } from "reactstrap";
import "./../../assets/css/therapistList.css";
import "./../../assets/css/subscription.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentService } from "src/services/PaymentService";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import UserContext from "../../context/UserContext";
import { CommonService } from "src/services/CommonService";
import axios from "axios";
import { Util } from "src/Util";
import Swal from "sweetalert2";
import { environment } from "src/environment/environment";

// Insurance data table with isCommercialInsurance flag
const insuranceMap: Record<string, { isCommercialInsurance: boolean, isMedicaid: boolean }> = {
    "BCBS of NC": { isCommercialInsurance: true, isMedicaid: false },
    "Cigna Behavioral": { isCommercialInsurance: true, isMedicaid: false },
    "AETNA": { isCommercialInsurance: true, isMedicaid: false },
    "Healthy Blue Medicaid": { isCommercialInsurance: false, isMedicaid: true },
    "Carolina complete health": { isCommercialInsurance: false, isMedicaid: true },
    "United Healthcare": { isCommercialInsurance: true, isMedicaid: false },
    "Blue Cross Blue Shield Federal": { isCommercialInsurance: true, isMedicaid: false },
    "Anthem": { isCommercialInsurance: true, isMedicaid: false },
    "Wellcare Medicaid": { isCommercialInsurance: false, isMedicaid: true },
    "Village heart beat project": { isCommercialInsurance: false, isMedicaid: false },
    "United Health Care Community Plan": { isCommercialInsurance: false, isMedicaid: true },
    "AmeriHealth": { isCommercialInsurance: true, isMedicaid: true }, // both
    "My Cigna": { isCommercialInsurance: true, isMedicaid: false },
    "Blue Cross Blue Sheild": { isCommercialInsurance: true, isMedicaid: false },
    "NC Medicare Part B": { isCommercialInsurance: false, isMedicaid: false },
    "First Health Network": { isCommercialInsurance: true, isMedicaid: false },
    "First Choice by Select Health": { isCommercialInsurance: false, isMedicaid: true }, // corrected
    "Absolute Total Care": { isCommercialInsurance: false, isMedicaid: true },
    "Healthy Blue by Blue Choice of SC": { isCommercialInsurance: false, isMedicaid: true },
    "Molina Healthcare of South Carolina": { isCommercialInsurance: false, isMedicaid: true },
    "Medical Home Network South Carolina Solutions": { isCommercialInsurance: false, isMedicaid: true },
    "Blue Cross Blue Shield South Carolina ": { isCommercialInsurance: true, isMedicaid: false },
    "Peach State Health Plan": { isCommercialInsurance: false, isMedicaid: true },
    "Amerigroup": { isCommercialInsurance: true, isMedicaid: false },
    "CareSource": { isCommercialInsurance: false, isMedicaid: true },
    "NC Medicaid": { isCommercialInsurance: false, isMedicaid: true },
    "South Carolina Healthy Connections": { isCommercialInsurance: false, isMedicaid: true },
    "Alliance Health": { isCommercialInsurance: false, isMedicaid: true }, // corrected
    "Tricare": { isCommercialInsurance: true, isMedicaid: false }, // per Marcus
    "Trillium": { isCommercialInsurance: false, isMedicaid: true },
    "Open Path": { isCommercialInsurance: false, isMedicaid: false },
    "United UMR": { isCommercialInsurance: true, isMedicaid: false },
    "Partners": { isCommercialInsurance: false, isMedicaid: true }, // corrected
    "HopeShield": { isCommercialInsurance: false, isMedicaid: false }
  };

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontWeight: 600,
            fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            ":focus": {
                color: "#424770",
            },

            "::placeholder": {
                color: "#9BACC8",
            },

            ":focus::placeholder": {
                color: "#CFD7DF",
            },
        },
        invalid: {
            color: "#fb1c50",
            ":focus": {
                color: "#FA755A",
            },
            "::placeholder": {
                color: "#FFCCA5",
            },
        },
    },
};

const CardPayment: React.FC = () => {
    const [user, setUser] = useContext(UserContext);
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [isSubscribingTrue, setIsSubscribingTrue] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const insurance = localStorage.getItem("insuranceCompany");
    // const [isEnable, setIsEnable] = useState(false);
    const [haveAgreedPolicy, setHaveAgreedPolicy] = useState(false);
    const [isOpenGooglebtnModal, setIsOpenGooglebtnModal] = useState(false);
    const [showGoogleBtn, setShowGoogleBtn] = useState(false);
    const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
    const [clientInsurance, setClientInsurance] = useState("");
    const [isCardRequired, setIsCardRequired] = useState(false);
    const [coPayment, setCoPayment] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        // Get client's insurance information
        if (user && user._id) {
            getClientInsurance();
        }
    }, [user]);

    // Function to call API to get client's insurance information
    const getClientInsurance = async () => {
        try {
            if (!user || !user._id) return;
            
            const response = await axios.post(
                `${environment.api_url}/api/public/get-insurance-company`,
                { clientId: user._id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data) {
                const insuranceCompany = response.data.insuranceCompany;
                const coPaymentValue = response.data.coPayment || 0;
                setClientInsurance(insuranceCompany);
                setCoPayment(coPaymentValue);
                
                if (insuranceCompany && insuranceMap[insuranceCompany] && insuranceMap[insuranceCompany].isCommercialInsurance) {
                    setIsCardRequired(true);
                } else {
                    setIsCardRequired(false);
                }
            }
        } catch (error) {
            console.error("Error when retrieving insurance information:", error);
        }
    };

    const handleCreatedCustomer = async () => {
        if (!haveAgreedPolicy) {
            toast.error("You must agree to the No Show and Cancellation Policy of Lavni Inc before updating card information.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
        else {
            if (!stripe || !elements) {
                toast.error("Stripe.js has not yet loaded.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });

                return;
            }

            setIsSubscribing(true);

            const result = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement)!,
                billing_details: {
                    email: user?.email,
                },
            });

            if (result.error) {
                toast.error(result.error.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });

                setIsSubscribing(false);
            } else {
                const subscriptionDetails = {
                    paymentMethod: result.paymentMethod.id,
                };

                PaymentService.clientCreate(subscriptionDetails).then(res => {
                    if (res.success) {
                        toast.success("Congratulations! Your card details has been added successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        toggleGooglebtnModal()
                        setIsSubscribingTrue(true);
                        setIsSubscribing(false);

                        elements.getElement(CardElement)?.clear();
                    } else {
                        toast.error(res.error ? res.error : "Failed to add your card details. Please contact support.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });

                        setIsSubscribing(false);

                        return;
                    }
                });
            }
        }
    };

    const handleSubmitSubscription = async () => {
        if (!stripe || !elements) {
            toast.error("Stripe.js has not yet loaded.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });

            return;
        }

        setIsSubscribing(true);

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)!,
            billing_details: {
                email: user?.email,
            },
        });

        if (result.error) {
            toast.error(result.error.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });

            setIsSubscribing(false);
        } else {
            const subscriptionDetails = {
                paymentMethod: result.paymentMethod.id,
            };

            PaymentService.clientSubscribe(subscriptionDetails).then(res => {
                if (res.success) {
                    const { clientSecret, status, subscriptionId, subscriptionStatus } = res.data;

                    if (status === "requires_action") {
                        stripe.confirmCardPayment(clientSecret).then(function (result) {
                            if (result.error) {
                                toast.error(result.error.message, {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    className: "foo-bar",
                                });
                                setIsSubscribingTrue(true);
                                setIsSubscribing(false);
                            } else {
                                setUser({ ...user, subscriptionId: subscriptionId, subscriptionStatus: subscriptionStatus });

                                toast.success("Congratulations! Your subscription is successful.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    className: "foo-bar",
                                });

                                toggleGooglebtnModal()
                                setIsSubscribing(false);

                                elements.getElement(CardElement)?.clear();
                            }
                        });
                    } else {
                        setUser({ ...user, subscriptionId: subscriptionId, subscriptionStatus: subscriptionStatus });

                        toast.success("Congratulations! Your subscription is successful.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        // history.push("/lavni_avatar");
                        clickNextBtn()
                        setIsSubscribingTrue(true);
                        setIsSubscribing(false);

                        elements.getElement(CardElement)?.clear();
                    }
                } else {
                    toast.error(res.error ? res.error : "Failed to subscribe. Please contact support.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });

                    setIsSubscribing(false);

                    return;
                }
            });
        }
    };

    const clickNextBtn = async () => {
        // Fetch lại thông tin user mới nhất
        try {
            // Lấy token từ localStorage
            const token = localStorage.getItem("token");
            
            // Gọi API lấy thông tin user mới nhất
            const response = await axios.get(
                `${environment.api_url}/api/user/profile`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response && (response as any).success) {
                // Cập nhật context user với thông tin mới nhất
                const updatedUser = response.data;
                setUser(updatedUser);
                
                // Kiểm tra điều kiện với thông tin user mới nhất
                if (isCardRequired && !(updatedUser?.stripeCustomerId)) {
                    toast.error(`The insurance you selected ${clientInsurance}, has a co-payment of $${coPayment}. So you need to add a payment card before continuing.`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    
                    // Scroll to card element to make it visible
                    const cardElement = document.querySelector('.card-element');
                    if (cardElement) {
                        cardElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                    return;
                }

                // Điều hướng nếu đã thỏa mãn điều kiện
                if(updatedUser?.skip == true){
                    navigateToDashboard("/dashboard")
                } else {
                    navigateToDashboard("/welcome-page")
                }
            } else {
                // Xử lý lỗi từ API nếu có
                toast.error("Unable to retrieve latest user information. Please try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            console.error("Error fetching updated user information:", error);
            toast.error("An error occurred while checking your information. Please try again.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    };

    const back = () => {
        history.goBack()
    }
    const handleNoGoogleSync = () => {
        setIsOpenGooglebtnModal(false)
        if (user?.insuranceId?._id || isSubscribingTrue) {
            // history.push("/lavni_avatar");
            navigateToDashboard("/dashboard")
        } else {
            toast.error("You can't access to the next step without subscribe.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }
    const handleYesGoogleSync = () => {
        setShowGoogleBtn(true)
    }
    const toggleGooglebtnModal = () => {
        setIsOpenGooglebtnModal(!isOpenGooglebtnModal)
        setShowGoogleBtn(false)
    }

    const responseGoogle = (response: any) => {
        if (response) {
            CommonService.getGoogleCalendarRefreshToken(response)
                .then(res => {
                    if (res) {
                        toast.success("Google calendar sign in success.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        handleNoGoogleSync()
                    } else {
                        toast.error("Google calendar sign in fail.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
                .catch(err => {
                    toast.error("Google calendar sign in fail.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                });
        }
    };
    const responseError = (error: any) => {
        toast.error("Google calendar sign in fail.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });
    };

    const toggleConfirmationModal = () => {
        setIsOpenConfirmationPopup(false);
    }

    const navigateToDashboard = (defaultRoute: string) => {
        if (user?.skip) {
            history.push(`${defaultRoute}`);
        } else {
            history.push("/welcome-page");
        }
    };
    return (
        <div className="content-wrapper mt-22rem">
            <section className="wrapper bg-light2 angled upper-end" id="recoverform">
                <div className="container1 pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
                            <div className="card">
                                <div className="card-body padding-13 text-center">
                                    <div className="PriceContainer">
                                        <div className="row">
                                            <div className="col-xl-12 mb-3 d-flex float-left jestifyStart">
                                                <span><button className="btn btn-skip float-left" onClick={() => back()} ><i className='bx bx-left-arrow-alt' ></i> &nbsp; Back</button></span>
                                            </div>
                                        </div>

                                        {(user?.insuranceId?._id || insurance !== null) ? (
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="creditCardContainer">
                                                        <p className="bold mt-3">
                                                            Please enter a credit/debit card to cover your copayment
                                                        </p>

                                                        {(user?.copaymentAmount && user?.copaymentAmount > 0) ? (
                                                            <p className="privacy-text">
                                                                You have a ${user?.copaymentAmount} copayment
                                                            </p>
                                                        ) : (<></>)}

                                                        <p className="privacy-text">
                                                            If you are required to make a copayment the amount will be charged to the card provided.
                                                            If you do not have a copayment <strong><u>you will not be charged</u></strong>
                                                        </p>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "left", marginTop: "10px" }}>
                                                        <FormGroup check inline >
                                                            <Input type="checkbox" onChange={(e: any) => setHaveAgreedPolicy(e.target.checked)} />
                                                            <Label check>
                                                                <p className="privacy-text">
                                                                    I agree to&nbsp;
                                                                    <a href="/noshow-policy" className="hover" target="_blank">
                                                                        No Show and Cancellation Policy&nbsp;
                                                                    </a>
                                                                    of Lavni Inc.
                                                                </p>
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="creditCardContainer">
                                                        <h3>Credit / Debit Information</h3>
                                                        <p>
                                                            Please enter a credit/debit card
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}

                                        <div className="pricing-wrapper">
                                            <Row className="mr-m-0">
                                                <Row className="mr-m-0">
                                                    <Col lg={6}>
                                                        <CardContent className="pay-content pay-content-border mb-4">
                                                            {/* <TextField
                                                                label="Email"
                                                                id="outlined-email-input"
                                                                helperText={`Email you'll recive updates and receipts on`}
                                                                margin="normal"
                                                                variant="outlined"
                                                                type="email"
                                                                required
                                                                value={user?.email}
                                                                disabled={true}
                                                                fullWidth
                                                            /> */}

                                                            <CardElement options={CARD_ELEMENT_OPTIONS} className="card-element" />

                                                            <div className="pay-div">
                                                                {insurance == null ? (
                                                                    <Button
                                                                        className="pay-btn btn btn-info"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleSubmitSubscription}
                                                                        disabled={isSubscribing}
                                                                    >
                                                                        {isSubscribing ? "Please Wait..." : "Subscribe"}&nbsp;&nbsp;<i className="fa fa-star"></i>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className="pay-btn btn btn-success"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleCreatedCustomer}
                                                                        disabled={isSubscribing}
                                                                    >
                                                                        {isSubscribing ? "Please Wait..." : "Save"}
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </CardContent>


                                                    </Col>
                                                </Row>
                                            </Row>
                                            <div className="price-row4 bottom">
                                                <div className="bottom-col mt-md-1 mb-md5 mt-1 mb-1 justify-center-btn">
                                                    <button type="button" className="next-btn" onClick={() => clickNextBtn()}>
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default CardPayment;
