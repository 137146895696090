import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import { Link } from "react-router-dom";
import { Card, CardBody, Container, Row } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { UserCountResponseModel } from "../../models/User";
import { AdminService } from "../../services/AdminService";
import AllAppointments from "./AdminDashboardComponents/AllAppointments";
import WelcomeComp from "./AdminDashboardComponents/WelcomeComp";
import { TransactionPaymemt } from "src/models/Transaction";
import AuthTokenSettings from "./AdminDashboardComponents/AuthTokenSettings";
import UserContext from "src/context/UserContext";
import { Role } from "src/models/Role";


const AdminDashboard: React.FC = () => {
    const [isStatistics, setStatistics] = useState([]);
    const [userCount, setUserCount] = useState({} as UserCountResponseModel);
    const [subscriptionsCount, setsubscriptionsCount] = useState();
    const [therapistRevenueCount, setTherapistRevenueCount] = useState();
    const [periodType, setPeriodType] = useState("yearly");
    const [allMeetingsByTherapistStatistic, setAllMeetingsByTherapistStatistic] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isView, setIsView] = useState<boolean>(true);
    const [isView2, setIsView2] = useState<boolean>(true);
    const [therapistsPayments, setTherapistsPayments] = useState<TransactionPaymemt[]>([]);
    const [loggedUser] = useContext(UserContext);
    
    useEffect(() => {
        getAllUserCount();
        getAllAppointmentsStatistics();
        getAllSubscriptionCount();
        getAllTherapistRevenue();
        getAllInsuranceCompanies();
        searchTherapists();
    }, [])

    useEffect(() => {
        getAllInsuranceCompanies();
    }, [periodType])

    const getAllInsuranceCompanies = () => {
        setIsLoading(true)
        let data;
        if (periodType == "yearly") {
            data = 365
        } else if (periodType == "6month") {
            data = 180
        } else if (periodType == "3month") {
            data = 90
        }

        const dataValue: any = {
            date: data
        }
        AdminService.getAllMeetingsByTherapistStatistic(dataValue).then(res => {
            if (res.success) {
                setAllMeetingsByTherapistStatistic(res.data);
                setIsLoading(false)
            } else {
                setAllMeetingsByTherapistStatistic([]);
                setIsLoading(false)
            }
        });
    };

    const searchTherapists = () => {
        AdminService.getAllAccumulatedBalance().then(res => {
            if (res.success) {
                setTherapistsPayments(res.data);
            }
        });
    }
    const getAllUserCount = () => {
        AdminService.getAllUserCounts().then(res => {
            if (res.success) setUserCount(res.data)
        })
    }

    const getAllSubscriptionCount = () => {
        AdminService.getAllSubscriptionCounts().then(res => {
            if (res.success) setsubscriptionsCount(res.data)
        })
    }

    const getAllTherapistRevenue = () => {
        AdminService.getAllTotalRevenue().then(res => {
            if (res.success) setTherapistRevenueCount(res.data)
        })
    }


    const getAllAppointmentsStatistics = () => {
        AdminService.getAllAppointmentsStatistics().then(res => {
            if (res.success) setStatistics(res.data)
        });
    }

    const onChangeChartPeriod = (pType: any) => {
        setPeriodType(pType);
    };

    const hideAndShow = () => {
        setIsView(!isView);
        setIsView2(true);
    };

    const hideAndShow2 = () => {
        setIsView2(!isView2);
        setIsView(true);
      };
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* <Breadcrumbs title={"Admin"} breadcrumbItem={"Dashboard"} /> */}
                        <Row>
                            <Col xl="4">
                                <WelcomeComp subscriptionsCount={subscriptionsCount} therapistRevenueCount={therapistRevenueCount} />
                                {/* <MonthlyEarning /> */}
                                <AllAppointments statistics={isStatistics} />

                                {loggedUser?.role == Role.SUPER_ADMIN && (
                                    <AuthTokenSettings />
                                )}
                                
                            </Col>
                            <Col xl="8">
                                <Row>
                                    <Col md="4" >
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            Clients
                                                        </p>
                                                        <h4 className="mb-0">{userCount.clientCount}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={
                                                                "bx bxs-user font-size-24"
                                                            }
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4" >
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            Therapists
                                                        </p>
                                                        <h4 className="mb-0">{userCount.therapistCount}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={
                                                                "bx bx-plus-medical font-size-24"
                                                            }
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4" >
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            Pending Clients
                                                        </p>
                                                        <h4 className="mb-0">{userCount.pendingClientCount}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={
                                                                "bx bx-stopwatch font-size-24"
                                                            }
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Card className="overflow-auto">
                                    <CardBody>
                                        <div className="d-sm-flex flex-wrap cursor-pointer" onClick={hideAndShow}>
                                            <h4 className="card-title " style={{flex: 1}}>Meeting Records</h4>
                                            {isView ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}

                                        </div>
                                        <div className={!isView ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                                            <div className="d-sm-flex flex-wrap mt-4 mb-4">
                                                <div className="ms-auto">
                                                    <ul className="nav nav-pills">
                                                        <li className="nav-item">
                                                            <Link
                                                                to="#"
                                                                className={classNames(
                                                                    { active: periodType === "3month" },
                                                                    "nav-link"
                                                                )}
                                                                onClick={() => {
                                                                    onChangeChartPeriod("3month");
                                                                }}
                                                                id="one_month"
                                                            >
                                                                3 Month
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to="#"
                                                                className={classNames(
                                                                    { active: periodType === "6month" },
                                                                    "nav-link"
                                                                )}
                                                                onClick={() => {
                                                                    onChangeChartPeriod("6month");
                                                                }}
                                                                id="one_month"
                                                            >
                                                                6 Month
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to="#"
                                                                className={classNames(
                                                                    { active: periodType === "yearly" },
                                                                    "nav-link"
                                                                )}
                                                                onClick={() => {
                                                                    onChangeChartPeriod("yearly");
                                                                }}
                                                                id="one_month"
                                                            >
                                                                Year
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {allMeetingsByTherapistStatistic !== undefined && allMeetingsByTherapistStatistic.length > 0 ? (
                                                <>
                                                    <div className="table-responsive" >
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Total Meeting Duration</th>
                                                                    <th scope="col">Total Spent Duration</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {allMeetingsByTherapistStatistic &&
                                                                    allMeetingsByTherapistStatistic.map((statistic, index) => (
                                                                        <tr key={index}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{statistic?.therapistId?.firstname + " " + statistic?.therapistId?.firstname}</td>
                                                                            <td>{statistic?.therapistId?.email}</td>
                                                                            <td>{statistic?.totalMeetingDuration?.toFixed(2)}</td>
                                                                            <td>{statistic?.totalSpentDuration?.toFixed(2)}</td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {isLoading ? <Spinner /> : <div>No Meetings Lists</div>}</>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="overflow-auto">
                                    <CardBody>
                                        <div className="d-sm-flex flex-wrap cursor-pointer" onClick={hideAndShow2}>
                                            <h4 className="card-title " style={{flex: 1}}>Payment Records</h4>
                                            {isView2 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                                        </div>
                                        
                                        <div className={!isView2 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                                            {therapistsPayments !== undefined && therapistsPayments.length > 0 ? (
                                                <>
                                                    <div className="table-responsive" >
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Stripe Account</th>
                                                                    <th scope="col">Total Earnings</th>
                                                                    <th scope="col">Total Withdrawals</th>
                                                                    <th scope="col">Available Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {therapistsPayments &&
                                                                    therapistsPayments.map((therapist, index) => (
                                                                        <tr key={index}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>
                                                                                {therapist?.firstname + " " + therapist?.lastname}
                                                                            </td>
                                                                            <td>{therapist?.email}</td>

                                                                            <td>{therapist?.stripeConnectedAccountId}</td>

                                                                            <td>{Math.round(therapist?.recentTransaction?.accumulatedTotalEarnings * 100) / 100}</td>

                                                                            <td>{Math.round(therapist?.recentTransaction?.accumulatedWithdrawals * 100) / 100}</td>

                                                                            <td>{Math.round(therapist?.recentTransaction?.accumulatedBalance * 100) / 100}</td>
                                                                            
                                                                        </tr>
                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {isLoading ? <Spinner /> : <div>No Available Payments</div>}</>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>


                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default AdminDashboard;