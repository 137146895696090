import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import axios from "axios";
import { UserDetails, UserResponseModel } from "../models/User";
import { SaasTherapistTask } from "src/models/SaasTherapistTask";

export class SaasTherapistService {
  static viewProfileByUserId(userId: string) {
    const url = Util.apiAuthUrl("saasTherapist/viewProfileByUserId/" + userId);
    return axios.get<Partial<UserDetails>, AppResponse<UserDetails>>(url);
  }

  public static async getAllClients(limit?: number, offset?: number) {
    const queryParams = limit !== undefined && offset !== undefined
      ? `?limit=${limit}&offset=${offset}`
      : "";
    const url = Util.apiAuthUrl(`saasTherapist/getAllClients${queryParams}`);
    return await axios.get<Partial<any>, AppResponse<any[]>>(url);
  }

  public static async getPersonalDetails(clientId: string): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("saasTherapist/getPersonalDetails/" + clientId);
    return await axios.get<Partial<UserDetails>, AppResponse<UserDetails>>(url);
  }

  public static async getTodoDetails(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("saasTherapist/getTodoDetails");
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getTaskDetails(limit: number , offset: number): Promise<AppResponse<SaasTherapistTask[]>> {
    const url = Util.apiAuthUrl(`saasTherapist/getTaskDetails?limit=${limit}&offset=${offset}`);
    return await axios.get<Partial<SaasTherapistTask>, AppResponse<SaasTherapistTask[]>>(url);
  }

  public static async createTask(data: Partial<SaasTherapistTask>): Promise<AppResponse<SaasTherapistTask>> {
    const url = Util.apiAuthUrl("saasTherapist/createTask");
    return await axios.post<Partial<SaasTherapistTask>, AppResponse<SaasTherapistTask>>(url, data);
  }

  public static async updateTaskById(data: Partial<SaasTherapistTask>): Promise<AppResponse<SaasTherapistTask>> {
    const url = Util.apiAuthUrl(`saasTherapist/updateTask`);
    return await axios.post<Partial<SaasTherapistTask>, AppResponse<SaasTherapistTask>>(url, data);
  }

  public static async deleteTaskById(data: string): Promise<AppResponse<SaasTherapistTask>> {
    const url = Util.apiAuthUrl(`saasTherapist/deleteTask/${data}`);
    return await axios.delete<Partial<SaasTherapistTask>, AppResponse<SaasTherapistTask>>(url);
  }

  public static async getJotFormByClientId(userId: string, clientId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getJotFormByClientIdViaTherapist/" + userId + "/" + clientId);
    return await axios.get<any, AppResponse<any>>(url);
  }

  public static async getInsuranceDetailsViaClientId(clientId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("saasTherapist/getInsuranceDetailsViaClientId/" + clientId);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }
  
  public static async searchClients(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`saasTherapist/searchClientsByTherapist/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async switchDashboardVersion(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`switchDashboardVersion`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async getEnabledForms(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("jotform/enabled-forms");
    return await axios.get<any, AppResponse<any>>(url);
  }
}
