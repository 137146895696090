import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import moment from "moment";
import { Util } from "src/Util";
import CustomAppointmentCalendarDateComponent from "./CustomAppointmentCalendarDateComponent";
import Spinner from "src/common/spinner/spinner";
import { toast } from "react-toastify";

interface dayObject {
  isDate: boolean;
  date: Date;
  isValid: boolean;
}

interface availableSlot {
  startTime: Date;
}

interface availableSlotFrom {
  startTime: string;
  endTime: string;
  daysOfWeek: number[];
}

interface Props {
  availableSlotsFromNew: availableSlotFrom[];
  prevAppointmentsNew: any[];
  prevBlockedDatesListNew: any[];
  selectedMonthFrom: Date;
  selectMonth: (selectedMonthIn: Date) => void;
  isLoadingNextMonth: boolean;
  onDatePickFrom: (
    value: Date | null,
    isInitial: boolean,
    prevAppointmentsListInFunc: any[],
    prevBlockedDatesListInFunc: any[],
    availableSlotsInFunc: any
  ) => void;
  selectedDate: Date | null;
}

const CustomAppointmentCalendar = ({
  availableSlotsFromNew,
  prevAppointmentsNew,
  prevBlockedDatesListNew,
  selectedMonthFrom,
  selectMonth,
  isLoadingNextMonth,
  onDatePickFrom,
  selectedDate,
}: Props) => {
  const todayDate: Date = new Date();
  const tomorrowDate: Date = moment(todayDate).add(1, "day").startOf("day").toDate();
  const [sundayDateArray, setSundayDateArray] = useState<dayObject[]>([]);
  const [mondayDateArray, setMondayDateArray] = useState<dayObject[]>([]);
  const [tuesdayDateArray, setTuesdayDateArray] = useState<dayObject[]>([]);
  const [wednesdayDateArray, setWednesdayDateArray] = useState<dayObject[]>([]);
  const [thursdayDateArray, setThursdayDateArray] = useState<dayObject[]>([]);
  const [fridayDateArray, setFridayDateArray] = useState<dayObject[]>([]);
  const [saturdayDateArray, setSaturdayDateArray] = useState<dayObject[]>([]);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  useEffect(() => {
    setDaysInMonth(selectedMonthFrom, prevAppointmentsNew, prevBlockedDatesListNew, availableSlotsFromNew);
  }, [selectedMonthFrom, prevAppointmentsNew, prevBlockedDatesListNew]);

  function setDaysInMonth(firstDateOfMonth: Date | null, prevAppointmentsListIn: any[], prevBlockedDatesListIn: any[], availableSlotsIn: any) {
    try {
      if (prevAppointmentsListIn && prevBlockedDatesListIn && availableSlotsIn && availableSlotsIn.length && availableSlotsIn.length > 0 && firstDateOfMonth) {
        setIsLoadingData(true);
        let firstIsValidDate: Date | null = null;
        const daysInMonth = moment(firstDateOfMonth).daysInMonth();

        const sundayArray: dayObject[] = [];
        const mondayArray: dayObject[] = [];
        const tuesdayArray: dayObject[] = [];
        const wednesdayArray: dayObject[] = [];
        const thursdayArray: dayObject[] = [];
        const fridayArray: dayObject[] = [];
        const saturdayArray: dayObject[] = [];

        for (let i = 1; i <= daysInMonth; i++) {
          const date = moment(firstDateOfMonth).date(i);
          const dayOfWeek = date.day();
          const isAvailable: boolean = checkForAvailableDateFunction(date.toDate(), prevAppointmentsListIn, prevBlockedDatesListIn, availableSlotsIn);

          if (isAvailable && firstIsValidDate == null) {
            firstIsValidDate = date.toDate();
          }

          const dayObject: dayObject = { isDate: true, date: date.toDate(), isValid: isAvailable };
          const emptyDayObject: dayObject = { isDate: false, date: tomorrowDate, isValid: false };

          switch (dayOfWeek) {
            case 0:
              sundayArray.push(dayObject);
              break;
            case 1:
              if (i == 1) {
                sundayArray.push(emptyDayObject);
              }
              mondayArray.push(dayObject);
              break;
            case 2:
              if (i == 1) {
                sundayArray.push(emptyDayObject);
                mondayArray.push(emptyDayObject);
              }
              tuesdayArray.push(dayObject);
              break;
            case 3:
              if (i == 1) {
                sundayArray.push(emptyDayObject);
                mondayArray.push(emptyDayObject);
                tuesdayArray.push(emptyDayObject);
              }
              wednesdayArray.push(dayObject);
              break;
            case 4:
              if (i == 1) {
                sundayArray.push(emptyDayObject);
                mondayArray.push(emptyDayObject);
                tuesdayArray.push(emptyDayObject);
                wednesdayArray.push(emptyDayObject);
              }
              thursdayArray.push(dayObject);
              break;
            case 5:
              if (i == 1) {
                sundayArray.push(emptyDayObject);
                mondayArray.push(emptyDayObject);
                tuesdayArray.push(emptyDayObject);
                wednesdayArray.push(emptyDayObject);
                thursdayArray.push(emptyDayObject);
              }
              fridayArray.push(dayObject);
              break;
            case 6:
              if (i == 1) {
                sundayArray.push(emptyDayObject);
                mondayArray.push(emptyDayObject);
                tuesdayArray.push(emptyDayObject);
                wednesdayArray.push(emptyDayObject);
                thursdayArray.push(emptyDayObject);
                fridayArray.push(emptyDayObject);
              }
              saturdayArray.push(dayObject);
              break;
          }
        }

        setSundayDateArray(sundayArray);
        setMondayDateArray(mondayArray);
        setTuesdayDateArray(tuesdayArray);
        setWednesdayDateArray(wednesdayArray);
        setThursdayDateArray(thursdayArray);
        setFridayDateArray(fridayArray);
        setSaturdayDateArray(saturdayArray);

        onDatePickFrom(firstIsValidDate, true, prevAppointmentsListIn, prevBlockedDatesListIn, availableSlotsIn);

        setIsLoadingData(false);
      } else {
      }
    } catch (error) {}
  }

  function checkForAvailableDateFunction(
    calendarDate: Date,
    prevAppointmentsInFu: any[],
    prevBlockedDatesListInFu: any[],
    availableSlotsFromInFu: any[]
  ): boolean {
    try {
      if (
        prevAppointmentsInFu &&
        prevBlockedDatesListInFu &&
        availableSlotsFromInFu &&
        availableSlotsFromInFu.length &&
        availableSlotsFromInFu.length > 0 &&
        calendarDate
      ) {
        const selectedDyOfWeek = calendarDate.getDay();

        const availableHours: string[][] = [];

        availableSlotsFromInFu
          .filter((obj: any) => obj.daysOfWeek[0] === selectedDyOfWeek)
          .map((obj: any) => {
            const availableHourSingle: string[] = [];

            for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
              if (hour == parseInt(obj.startTime)) {
                const mST = moment(obj.startTime, "HH:mm").minute();

                if (mST == 0) {
                  availableHourSingle.push(moment({ hour }).format("H:mm A"));
                }
              } else {
                availableHourSingle.push(moment({ hour }).format("H:mm A"));
              }

              if (hour != parseInt(obj.endTime)) {
                availableHourSingle.push(
                  moment({
                    hour,
                    minute: 30,
                  }).format("H:mm A")
                );
              } else {
                const mET = moment(obj.endTime, "HH:mm").minute();

                if (mET == 30) {
                  availableHourSingle.push(
                    moment({
                      hour,
                      minute: 30,
                    }).format("H:mm A")
                  );
                }
              }
            }

            availableHours.push(availableHourSingle);
          });

        let allAvailableSlotsForSelectedDate: availableSlot[] = [];

        availableHours.map((slotArray: string[], i: number) => {
          slotArray.map((slot: string, i: number) => {
            const startTimeString = slot;
            const startTimeStringTime = moment(startTimeString, "HH:mm A");
            const selectedStartDateTime = moment(calendarDate)
              .hours(startTimeStringTime.hours())
              .minutes(startTimeStringTime.minutes())
              .seconds(0)
              .format("YYYY-MM-DD HH:mm:ss");
            const convertedStartDate = moment(selectedStartDateTime, "YYYY-MM-DD HH:mm:ss").toDate();

            const startTForCheck = moment(convertedStartDate);

            const endTForCheck = moment(startTForCheck).add(60, "minutes");

            if (
              moment(tomorrowDate).isSameOrBefore(moment(convertedStartDate)) &&
              slotArray.includes(moment(startTForCheck).format("H:mm A")) &&
              slotArray.includes(moment(endTForCheck).format("H:mm A"))
            ) {
              allAvailableSlotsForSelectedDate.push({ startTime: convertedStartDate });
            }
          });
        });

        allAvailableSlotsForSelectedDate = allAvailableSlotsForSelectedDate.filter(
          (slot, index, self) => index === self.findIndex(s => moment(s.startTime).valueOf() === moment(slot.startTime).valueOf())
        );

        allAvailableSlotsForSelectedDate = allAvailableSlotsForSelectedDate.filter(slot => {
          const isInBlockedRange = prevBlockedDatesListInFu.some((range: any) => {
            return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(slot.startTime));
          });

          if (isInBlockedRange) {
            return false;
          }

          const appointmentAlreadyExists = prevAppointmentsInFu.some((range: any) => {
            return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(slot.startTime));
          });
          return !isInBlockedRange && !appointmentAlreadyExists;
        });

        allAvailableSlotsForSelectedDate.sort((slot1, slot2) => {
          return moment(slot1.startTime).valueOf() - moment(slot2.startTime).valueOf();
        });

        if (allAvailableSlotsForSelectedDate && allAvailableSlotsForSelectedDate.length && allAvailableSlotsForSelectedDate.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  function onDatePick(date: Date) {
    try {
      onDatePickFrom(date, false, prevAppointmentsNew, prevBlockedDatesListNew, availableSlotsFromNew);
    } catch (error) {}
  }

  return (
    <Col className="custom-appointment-calender-main-comp">
      <Row className="custom-appointment-calender-top-section">
        <Col xs={3} className="d-flex justify-content-end align-items-center">
          <IconButton
            disabled={
              isLoadingNextMonth ||
              (selectedMonthFrom && moment(selectedMonthFrom).isSame(tomorrowDate, "month") && moment(selectedMonthFrom).isSame(tomorrowDate, "year"))
            }
            onClick={() => {
              try {
                if (isLoadingNextMonth) {
                  toast.error("Please wait !", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                  });
                } else {
                  if (selectedMonthFrom) {
                    const isSameMonth: boolean =
                      moment(selectedMonthFrom).isSame(tomorrowDate, "month") && moment(selectedMonthFrom).isSame(tomorrowDate, "year");
                    if (!isSameMonth && selectedMonthFrom) {
                      const prevMonth: Date = moment(selectedMonthFrom).subtract(1, "months").toDate();
                      setIsLoadingData(true);
                      setSundayDateArray([]);
                      setMondayDateArray([]);
                      setTuesdayDateArray([]);
                      setWednesdayDateArray([]);
                      setThursdayDateArray([]);
                      setFridayDateArray([]);
                      setSaturdayDateArray([]);
                      selectMonth(prevMonth);
                    }
                  }
                }
              } catch (error) {
                toast.error("Something went wrong !", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            }}
            size="small"
            className="d-flex justify-content-center align-items-center p-2"
          >
            <ArrowBackIos className="custom-appointment-calender-arrow-icon" />
          </IconButton>
        </Col>
        <Col xs={6} className="d-flex justify-content-center align-items-center">
          <div>{selectedMonthFrom ? moment(selectedMonthFrom).format("MMMM YYYY").toString() : "Invalid Date"}</div>
        </Col>
        <Col xs={3} className="d-flex justify-content-start align-items-center">
          <IconButton
            disabled={isLoadingNextMonth}
            onClick={() => {
              try {
                if (isLoadingNextMonth) {
                  toast.error("Please wait !", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                  });
                } else {
                  if (selectedMonthFrom) {
                    const nextMonth: Date = moment(selectedMonthFrom).add(1, "months").toDate();
                    setIsLoadingData(true);
                    setSundayDateArray([]);
                    setMondayDateArray([]);
                    setTuesdayDateArray([]);
                    setWednesdayDateArray([]);
                    setThursdayDateArray([]);
                    setFridayDateArray([]);
                    setSaturdayDateArray([]);
                    selectMonth(nextMonth);
                  }
                }
              } catch (error) {
                toast.error("Something went wrong !", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            }}
            size="small"
            className="d-flex justify-content-center align-items-center p-2"
          >
            <ArrowForwardIos className="custom-appointment-calender-arrow-icon" />
          </IconButton>
        </Col>
      </Row>
      {!isLoadingNextMonth &&
      !isLoadingData &&
      sundayDateArray &&
      mondayDateArray &&
      tuesdayDateArray &&
      wednesdayDateArray &&
      thursdayDateArray &&
      fridayDateArray &&
      saturdayDateArray ? (
        <Row className="custom-appointment-calender-bottom-section">
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">Su</div>
              <div>
                {sundayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">Mo</div>
              <div>
                {mondayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">Tu</div>
              <div>
                {tuesdayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">We</div>
              <div>
                {wednesdayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">Th</div>
              <div>
                {thursdayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">Fr</div>
              <div>
                {fridayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-start p-0">
            <div className="m-0 flex-column">
              <div className="d-flex justify-content-center align-items-center mt-1 custom-appointment-calender-day-comp">Sa</div>
              <div>
                {saturdayDateArray.map((day: dayObject, index: any) => {
                  return <CustomAppointmentCalendarDateComponent key={index} data={day} selectedDate={selectedDate} onDateClick={onDatePick} />;
                })}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="custom-appointment-calender-bottom-section-loading">
          <Spinner />
        </Row>
      )}
    </Col>
  );
};

export default CustomAppointmentCalendar;
