import React, { useEffect, useState, useContext, useRef, memo } from "react";
import { Card, CardBody, Col, Input, Row, Table } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";
import moment from "moment";
import { SelfHarming } from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

const NewFormNine = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;

  const [selfInjuredPastMonth, setSelfInjuredPastMonth] = useState("");
  const [suicidalIdeation, setSuicidalIdeation] = useState("");
  const [selfcommandHallucination, setSelfCommandHallucination] = useState("");
  const [othercommandHallucination, setOtherCommandHallucination] = useState("");
  const [historyOfSuicidalBehavior, sethistoryOfSuicidalBehavior] = useState("");
  const [historyOfHomicidalBehavior, setHistoryOfHomicidalBehavior] = useState("");
  const [physicalHarmtoSelf, setPhysicalHarmToSelf] = useState("");
  const [historyOfAggression, setHistoryOfAggression] = useState("");

  // const [formData, setFormData] = useState<Partial<DigitalForm>>({
  //   formHeader: {
  //     date: "",
  //     insuranceId: "",
  //     lastName: "",
  //     firstname: "",
  //     mi: "",
  //   },

  //   riskToSelfAndOthers: {
  //     currentRiskToSelf: {
  //       isSelfInjured: null,
  //       describeSelfInjured: "",
  //       isSuicidalThreats: null,
  //       describeSuicidalThreats: "",
  //       repetitiveIdeation: null,
  //       isSuicidePlan: null,
  //       isLethalIntent: null,
  //       isCommandHallucinations: null,
  //       describeCommandHallucinations: "",
  //     },
  //     currentRiskToOthers: {
  //       isHomicidalThreats: null,
  //       isPersistentIdeation: null,
  //       isSpecificPlan: null,
  //       isLethalIntent: null,
  //       isHallucinationCommands: null,
  //       describeHallucination: "",
  //     },
  //     historyOfSuicidalBehavior: {
  //       isHistoryOfSuicidalBehavior: null,
  //       describeHistoryOfSuicidalBehavior: "",
  //       isHomicidalBehavior: null,
  //       describeHomicidalBehavior: "",
  //       isSeriousHarmSelfOthers: null,
  //       describeSeriousPhysicalHarm: "",
  //       isAggressionViolenceOthers: null,
  //       describeAggression: "",
  //     },
  //     evaluationOfRisk: {
  //       selfHarming: {
  //         lowToNoRisk: null,
  //         moderateRisk: null,
  //         highRisk: null,
  //         imminentRisk: null,
  //       },
  //       assaultive: {
  //         lowToNoRisk: null,
  //         moderateRisk: null,
  //         highRisk: null,
  //         imminentRisk: null,
  //       },
  //       actionEvaluation: "",
  //       beliefSystem: "",
  //       roleOfBeliefinlife: "",
  //       roleOfBeliefRecovery: "",
  //     },
  //   },
  // });

  const formData = useRef<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    riskToSelfAndOthers: {
      currentRiskToSelf: {
        isSelfInjured: null,
        describeSelfInjured: "",
        isSuicidalThreats: null,
        describeSuicidalThreats: "",
        repetitiveIdeation: null,
        isSuicidePlan: null,
        isLethalIntent: null,
        isCommandHallucinations: null,
        describeCommandHallucinations: "",
      },
      currentRiskToOthers: {
        isHomicidalThreats: null,
        isPersistentIdeation: null,
        isSpecificPlan: null,
        isLethalIntent: null,
        isHallucinationCommands: null,
        describeHallucination: "",
      },
      historyOfSuicidalBehavior: {
        isHistoryOfSuicidalBehavior: null,
        describeHistoryOfSuicidalBehavior: "",
        isHomicidalBehavior: null,
        describeHomicidalBehavior: "",
        isSeriousHarmSelfOthers: null,
        describeSeriousPhysicalHarm: "",
        isAggressionViolenceOthers: null,
        describeAggression: "",
      },
      evaluationOfRisk: {
        selfHarming: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        assaultive: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        actionEvaluation: "",
        beliefSystem: "",
        roleOfBeliefinlife: "",
        roleOfBeliefRecovery: "",
      },
    },
  });

  // Use a dummy state to trigger re-renders

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   riskToSelfAndOthers: data?.riskToSelfAndOthers,
      // };
      // Store initial data
      // setFormData(formData => ({
      //   ...formData,
      //   ...updateValues,
      // }));

      formData.current = {
        formHeader: data?.formHeader,
        riskToSelfAndOthers: data?.riskToSelfAndOthers,
      };

      setSelfInjuredPastMonth(data?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured);
      setSuicidalIdeation(data?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats);
      setSelfCommandHallucination(data?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations);
      setOtherCommandHallucination(data?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination);

      sethistoryOfSuicidalBehavior(data?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeHistoryOfSuicidalBehavior);
      setHistoryOfHomicidalBehavior(data?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeHomicidalBehavior);
      setPhysicalHarmToSelf(data?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeSeriousPhysicalHarm);
      setHistoryOfAggression(data?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.describeAggression);
    }
  }, [data]);

  const handleUpdateData = () => {
    const updateDetails = {
      formHeader: {
        ...formData?.current.formHeader,
      },
      riskToSelfAndOthers: {
        ...formData?.current.riskToSelfAndOthers,
        currentRiskToSelf: {
          ...formData?.current.riskToSelfAndOthers?.currentRiskToSelf,
          describeSelfInjured: selfInjuredPastMonth,
          describeSuicidalThreats: suicidalIdeation,
          describeCommandHallucinations: selfcommandHallucination,
        },
        currentRiskToOthers: {
          ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
          describeHallucination: othercommandHallucination,
        },
        historyOfSuicidalBehavior: {
          ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
          describeHistoryOfSuicidalBehavior: historyOfSuicidalBehavior,
          describeHomicidalBehavior: historyOfHomicidalBehavior,
          describeSeriousPhysicalHarm: physicalHarmtoSelf,
          describeAggression: historyOfAggression,
        },
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData.current.formHeader?.date ? moment.utc(formData.current.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   formHeader: {
                      //     ...formData?.formHeader,
                      //     date: e.target.value,
                      //   },
                      // }));
                      formData.current.formHeader = {
                        ...formData.current?.formHeader,
                        date: e.target.value,
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData.current.formHeader?.insuranceId}
                    onChange={e => {
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   formHeader: {
                      //     ...formData?.formHeader,
                      //     insuranceId: e.target.value,
                      //   },
                      // }));
                      formData.current.formHeader = {
                        ...formData.current?.formHeader,
                        insuranceId: e.target.value,
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData.current.formHeader?.lastName}
                    onChange={e => {
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   formHeader: {
                      //     ...formData?.formHeader,
                      //     lastName: e.target.value,
                      //   },
                      // }));
                      formData.current.formHeader = {
                        ...formData.current?.formHeader,
                        lastName: e.target.value,
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData.current.formHeader?.firstname}
                    onChange={e => {
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   formHeader: {
                      //     ...formData?.formHeader,
                      //     firstname: e.target.value,
                      //   },
                      // }));
                      formData.current.formHeader = {
                        ...formData.current?.formHeader,
                        firstname: e.target.value,
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.current.formHeader?.mi}
                    onChange={e => {
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   formHeader: {
                      //     ...formData?.formHeader,
                      //     mi: e.target.value,
                      //   },
                      // }));

                      formData.current.formHeader = {
                        ...formData.current.formHeader,

                        mi: e.target.value,
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.current?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      formData.current.formHeader = {
                        ...formData.current.formHeader,
                        currentAddress:{
                          ...formData?.current?.formHeader?.currentAddress,
                          streetAddress: e.target.value,
                        }
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.current?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      formData.current.formHeader = {
                        ...formData.current.formHeader,
                        currentAddress:{
                          ...formData?.current?.formHeader?.currentAddress,
                          unit: e.target.value,
                        }
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.current?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      formData.current.formHeader = {
                        ...formData.current.formHeader,
                        currentAddress:{
                          ...formData?.current?.formHeader?.currentAddress,
                          city: e.target.value,
                        }
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.current?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      formData.current.formHeader = {
                        ...formData.current.formHeader,
                        currentAddress:{
                          ...formData?.current?.formHeader?.currentAddress,
                          state: e.target.value,
                        }
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.current?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      formData.current.formHeader = {
                        ...formData.current.formHeader,
                        currentAddress:{
                          ...formData?.current?.formHeader?.currentAddress,
                          zipCode: e.target.value,
                        }
                      };
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Card className="match-card-header p-2 mb-2">
            <div style={{ display: "flex" }}>
              <h5 className="fw-500" style={{ margin: "auto" }}>
                RISK TO SELF AND OTHERS
              </h5>
            </div>
          </Card>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Row>
              <Card className="match-card-body p-3">
                <h6 className="fw-500" style={{}}>
                  <u>Current Risk to Self</u>
                </h6>

                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Has the person self-injured in the past month?:
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured === true}
                        onClick={e => {
                          const result = formData.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isSelfInjured: result ? null : true,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //     },
                          //   },
                          // }));
                          //ll
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isSelfInjured: result ? null : true,
                              repetitiveIdeation: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSuicidePlan: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              isSuicidalThreats: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isSelfInjured: result ? null : false,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isSelfInjured: result ? null : false,
                              repetitiveIdeation: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSuicidePlan: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              isSuicidalThreats: formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                            },
                          };
                          setSelfInjuredPastMonth("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <Col xl={1}></Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",

                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured}
                            value={selfInjuredPastMonth ?? ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     currentRiskToSelf: {
                              //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                              //       describeSelfInjured: e.target.value,
                              //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              //       describeSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              //       describeCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              //     },
                              //   },
                              // }));
                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   currentRiskToSelf: {
                              //     ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              //     describeSelfInjured: e.target.value,
                              //     isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              //     isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              //     repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              //     isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              //     isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              //     isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              //     describeSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              //     describeCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              //   },
                              // };
                              setSelfInjuredPastMonth(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>

                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is person having suicidal ideation or making suicidal threats?:
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isSuicidalThreats: result ? null : true,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isSuicidalThreats: result ? null : true,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isSuicidalThreats: result ? null : false,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isSuicidalThreats: result ? null : false,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                            },
                          };
                          setSuicidalIdeation("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <Col xl={1}></Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",

                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                            value={suicidalIdeation ?? ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     currentRiskToSelf: {
                              //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                              //       describeSuicidalThreats: e.target.value,
                              //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              //       describeCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                              //     },
                              //   },
                              // }));

                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   currentRiskToSelf: {
                              //     ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              //     describeSuicidalThreats: e.target.value,
                              //     isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              //     isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              //     repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              //     isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              //     isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              //     isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              //     describeCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              //     describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                              //   },
                              // };
                              setSuicidalIdeation(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>

                <Row>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      If YES to either question above:
                    </h6>
                  </Card>

                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is the ideation/self-injuring repetitive or persistent?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation === true}
                        disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       repetitiveIdeation: result ? null : true,
                          //       describeSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,

                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       describeCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                          //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              repetitiveIdeation: result ? null : true,
                              describeSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,

                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              describeCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation === false}
                        disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       repetitiveIdeation: result ? null : false,
                          //       describeSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       describeCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                          //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              repetitiveIdeation: result ? null : false,
                              describeSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,

                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              describeCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Does the patient have a specific suicide plan?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan === true}
                        disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isSuicidePlan: result ? null : true,
                          //       describeSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,

                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       describeCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                          //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isSuicidePlan: result ? null : true,
                              describeSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,

                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              describeCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeCommandHallucinations ?? "",
                              describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan === false}
                        disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isSuicidePlan: result ? null : false,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isSuicidePlan: result ? null : false,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Does the ideation/self-injury involve serious/lethal intent?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent === true}
                        disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isLethalIntent: result ? null : true,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isLethalIntent: result ? null : true,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent === false}
                        disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isLethalIntent: result ? null : false,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isLethalIntent: result ? null : false,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Does the ideation involve command hallucinations?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isCommandHallucinations: result ? null : true,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isCommandHallucinations: result ? null : true,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToSelf: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                          //       isCommandHallucinations: result ? null : false,
                          //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                          //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                          //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                          //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToSelf: {
                              ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              isCommandHallucinations: result ? null : false,
                              isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                            },
                          };
                          setSelfCommandHallucination("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>

                  <Col xl={1}></Col>

                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",

                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations}
                            value={selfcommandHallucination ?? ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     currentRiskToSelf: {
                              //       ...formData?.riskToSelfAndOthers?.currentRiskToSelf,
                              //       describeCommandHallucinations: e.target.value,
                              //       isSuicidePlan: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              //       describeSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              //       isSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              //       isSuicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,

                              //       repetitiveIdeation: formData?.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              //       isCommandHallucinations: formData?.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              //       describeSelfInjured: formData?.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                              //     },
                              //   },
                              // }));

                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   currentRiskToSelf: {
                              //     ...formData?.current?.riskToSelfAndOthers?.currentRiskToSelf,
                              //     describeCommandHallucinations: e.target.value,
                              //     isSuicidePlan: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidePlan ?? null,
                              //     describeSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSuicidalThreats ?? "",
                              //     isSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSelfInjured ?? null,
                              //     isSuicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isSuicidalThreats ?? null,
                              //     repetitiveIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.repetitiveIdeation ?? null,
                              //     isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isLethalIntent ?? null,
                              //     isCommandHallucinations: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.isCommandHallucinations ?? null,
                              //     describeSelfInjured: formData?.current.riskToSelfAndOthers?.currentRiskToSelf?.describeSelfInjured ?? "",
                              //   },
                              // };
                              setSelfCommandHallucination(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>
            </Row>
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Row>
              <Card className="match-card-body p-3">
                <h6 className="fw-500" style={{}}>
                  <u>Current Risk to Others</u>
                </h6>

                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is the person having homicidal ideation or making homicidal threats?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToOthers: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                          //       isHomicidalThreats: result ? null : true,
                          //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                          //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                          //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToOthers: {
                              ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                              isHomicidalThreats: result ? null : true,
                              isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                              isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                              isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     currentRiskToOthers: {
                          //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                          //       isHomicidalThreats: result ? null : false,
                          //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                          //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                          //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                          //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            currentRiskToOthers: {
                              ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                              isHomicidalThreats: result ? null : false,
                              isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                              isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                              isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                              isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If YES:
                          </h6>
                        </div>

                        <Row>
                          <Col xl={1}></Col>

                          <Col xl={7}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Is the ideation/self-injuring repetitive or persistent?
                            </h6>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation === true}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation === true;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isPersistentIdeation: result ? null : true,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                  //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                  //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));

                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isPersistentIdeation: result ? null : true,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Yes
                              </label>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation === false}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation === false;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isPersistentIdeation: result ? null : false,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                  //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                  //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));
                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isPersistentIdeation: result ? null : false,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                No
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={7}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Does the patient have a specific plan?
                            </h6>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan === true}
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan === true;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isSpecificPlan: result ? null : true,
                                  //       isHomicidalThreats: formData.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isPersistentIdeation: formData.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                  //       isLethalIntent: formData.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                  //       isHallucinationCommands: formData.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                  //       describeHallucination: formData.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));

                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isSpecificPlan: result ? null : true,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Yes
                              </label>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan === false}
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan === false;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isSpecificPlan: result ? null : false,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                  //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                  //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));

                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isSpecificPlan: result ? null : false,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                No
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={7}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Does the ideation involve serious/lethal intent?
                            </h6>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent === true}
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent === true;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isLethalIntent: result ? null : true,
                                  //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                  //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));
                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isLethalIntent: result ? null : true,
                                      isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Yes
                              </label>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent === false}
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent === false;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isLethalIntent: result ? null : false,
                                  //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                  //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));
                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isLethalIntent: result ? null : false,
                                      isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                No
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={1}></Col>
                          <Col xl={7}>
                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                              Does the ideation involve command hallucinations?
                            </h6>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands === true}
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands === true;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isHallucinationCommands: result ? null : true,
                                  //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                  //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));
                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isHallucinationCommands: result ? null : true,
                                      isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                Yes
                              </label>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="col-auto d-flex">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands === false}
                                disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats}
                                onClick={e => {
                                  const result = formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands === false;
                                  // setFormData((formData: Partial<DigitalForm>) => ({
                                  //   ...formData,
                                  //   riskToSelfAndOthers: {
                                  //     ...formData?.riskToSelfAndOthers,
                                  //     currentRiskToOthers: {
                                  //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                  //       isHallucinationCommands: result ? null : false,
                                  //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                  //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                  //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                  //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                  //       describeHallucination: formData?.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                  //     },
                                  //   },
                                  // }));

                                  formData.current.riskToSelfAndOthers = {
                                    ...formData.current.riskToSelfAndOthers,
                                    currentRiskToOthers: {
                                      ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      isHallucinationCommands: result ? null : false,
                                      isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      describeHallucination: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.describeHallucination ?? "",
                                    },
                                  };

                                  setOtherCommandHallucination("");
                                }}
                              />
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                No
                              </label>
                            </div>
                          </Col>
                          <Col xl={1}></Col>
                          <div className="col">
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col-auto">
                                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                    If yes, describe:
                                  </h6>
                                </div>
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      // border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                      border: "1px dotted  #ccc",
                                      padding: "2px",
                                      outline: "none",
                                      borderRadius: "8px",
                                    }}
                                    type="text"
                                    disabled={!formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands}
                                    value={othercommandHallucination ?? ""}
                                    onChange={e => {
                                      // setFormData((formData: Partial<DigitalForm>) => ({
                                      //   ...formData,
                                      //   riskToSelfAndOthers: {
                                      //     ...formData?.riskToSelfAndOthers,
                                      //     currentRiskToOthers: {
                                      //       ...formData?.riskToSelfAndOthers?.currentRiskToOthers,
                                      //       describeHallucination: e.target.value,
                                      //       isHomicidalThreats: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      //       isPersistentIdeation: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      //       isSpecificPlan: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      //       isLethalIntent: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      //       isHallucinationCommands: formData?.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      //     },
                                      //   },
                                      // }));
                                      // formData.current.riskToSelfAndOthers = {
                                      //   ...formData.current.riskToSelfAndOthers,
                                      //   currentRiskToOthers: {
                                      //     ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                                      //     describeHallucination: e.target.value,
                                      //     isHomicidalThreats: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHomicidalThreats ?? null,
                                      //     isPersistentIdeation: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isPersistentIdeation ?? null,
                                      //     isSpecificPlan: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isSpecificPlan ?? null,
                                      //     isLethalIntent: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isLethalIntent ?? null,
                                      //     isHallucinationCommands: formData?.current.riskToSelfAndOthers?.currentRiskToOthers?.isHallucinationCommands ?? null,
                                      //   },
                                      // };
                                      setOtherCommandHallucination(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>
            </Row>
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Row>
              <Card className="match-card-body p-3">
                <h6 className="fw-500" style={{}}>
                  <u>History of Suicidal / Homicidal Ideation / Behavior</u>{" "}
                </h6>

                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is there a history of suicidal / self-injuring ideation / behavior?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isHistoryOfSuicidalBehavior: result ? null : true,
                          //       isSeriousHarmSelfOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                          //       isHomicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                          //       isAggressionViolenceOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isHistoryOfSuicidalBehavior: result ? null : true,
                              isSeriousHarmSelfOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                              isHomicidalBehavior: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                              isAggressionViolenceOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isHistoryOfSuicidalBehavior: result ? null : false,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isHistoryOfSuicidalBehavior: result ? null : false,
                            },
                          };
                          sethistoryOfSuicidalBehavior("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <Col xl={1}></Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",

                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior}
                            value={historyOfSuicidalBehavior || ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     historyOfSuicidalBehavior: {
                              //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //       describeHistoryOfSuicidalBehavior: e.target.value,
                              //     },
                              //   },
                              // }));
                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   historyOfSuicidalBehavior: {
                              //     ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //     describeHistoryOfSuicidalBehavior: e.target.value,
                              //   },
                              // };
                              sethistoryOfSuicidalBehavior(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is there a history of homicidal / assaultive ideation / behavior?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isHomicidalBehavior: result ? null : true,
                          //       isSeriousHarmSelfOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                          //       isHistoryOfSuicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                          //       isAggressionViolenceOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isHomicidalBehavior: result ? null : true,
                              isSeriousHarmSelfOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                              isHistoryOfSuicidalBehavior:
                                formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                              isAggressionViolenceOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isHomicidalBehavior: result ? null : false,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isHomicidalBehavior: result ? null : false,
                            },
                          };
                          setHistoryOfHomicidalBehavior("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <Col xl={1}></Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              // border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior}
                            value={historyOfHomicidalBehavior || ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     historyOfSuicidalBehavior: {
                              //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //       describeHomicidalBehavior: e.target.value,
                              //     },
                              //   },
                              // }));
                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   historyOfSuicidalBehavior: {
                              //     ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //     describeHomicidalBehavior: e.target.value,
                              //   },
                              // };
                              setHistoryOfHomicidalBehavior(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is there a history of serious physical harm to self / others while in a treatment setting
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isSeriousHarmSelfOthers: result ? null : true,
                          //       isHistoryOfSuicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                          //       isHomicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                          //       isAggressionViolenceOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isSeriousHarmSelfOthers: result ? null : true,
                              isHistoryOfSuicidalBehavior:
                                formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                              isHomicidalBehavior: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                              isAggressionViolenceOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isSeriousHarmSelfOthers: result ? null : false,
                          //       isHistoryOfSuicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                          //       isHomicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                          //       isAggressionViolenceOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                          //     },
                          //   },
                          // }));

                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isSeriousHarmSelfOthers: result ? null : false,
                              isHistoryOfSuicidalBehavior:
                                formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                              isHomicidalBehavior: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                              isAggressionViolenceOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers ?? null,
                            },
                          };
                          setPhysicalHarmToSelf("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <Col xl={1}></Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              // border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers}
                            value={physicalHarmtoSelf ?? ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     historyOfSuicidalBehavior: {
                              //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //       describeSeriousPhysicalHarm: e.target.value,
                              //     },
                              //   },
                              // }));
                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   historyOfSuicidalBehavior: {
                              //     ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //     describeSeriousPhysicalHarm: e.target.value,
                              //   },
                              // };
                              setPhysicalHarmToSelf(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <Col xl={8}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      Is there a history of aggression or violence towards others?
                    </h6>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers === true}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers === true;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isAggressionViolenceOthers: result ? null : true,
                          //       isHomicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                          //       isSeriousHarmSelfOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                          //       isHistoryOfSuicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isAggressionViolenceOthers: result ? null : true,
                              isHomicidalBehavior: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                              isSeriousHarmSelfOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                              isHistoryOfSuicidalBehavior:
                                formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                            },
                          };
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        checked={formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers === false}
                        onClick={e => {
                          const result = formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers === false;
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   riskToSelfAndOthers: {
                          //     ...formData?.riskToSelfAndOthers,
                          //     historyOfSuicidalBehavior: {
                          //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                          //       isAggressionViolenceOthers: result ? null : false,
                          //       isHomicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                          //       isSeriousHarmSelfOthers: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                          //       isHistoryOfSuicidalBehavior: formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                          //     },
                          //   },
                          // }));
                          formData.current.riskToSelfAndOthers = {
                            ...formData.current.riskToSelfAndOthers,
                            historyOfSuicidalBehavior: {
                              ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              isAggressionViolenceOthers: result ? null : false,
                              isHomicidalBehavior: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHomicidalBehavior ?? null,
                              isSeriousHarmSelfOthers: formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isSeriousHarmSelfOthers ?? null,
                              isHistoryOfSuicidalBehavior:
                                formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isHistoryOfSuicidalBehavior ?? null,
                            },
                          };
                          setHistoryOfAggression("");
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                  <Col xl={1}></Col>
                  <div className="col">
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            If yes, describe:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              // border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior?.isAggressionViolenceOthers}
                            value={historyOfAggression ?? ""}
                            onChange={e => {
                              // setFormData((formData: Partial<DigitalForm>) => ({
                              //   ...formData,
                              //   riskToSelfAndOthers: {
                              //     ...formData?.riskToSelfAndOthers,
                              //     historyOfSuicidalBehavior: {
                              //       ...formData?.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //       describeAggression: e.target.value,
                              //     },
                              //   },
                              // }));
                              // formData.current.riskToSelfAndOthers = {
                              //   ...formData.current.riskToSelfAndOthers,
                              //   historyOfSuicidalBehavior: {
                              //     ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                              //     describeAggression: e.target.value,
                              //   },
                              // };
                              setHistoryOfAggression(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Row>
              </Card>
            </Row>
          </div>
        </Row>

        <Row>
          <Card className="match-card-body p-3">
            <h6 className="fw-500" style={{}}>
              <u>Evaluation of Risk</u>
            </h6>

            <Row>
              <Col xl={3}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Suicidal /Self-Harming:
                </h6>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.lowtoNorisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.lowToNoRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.lowToNoRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       selfHarming: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                      //         lowToNoRisk: result ? null : true,
                      //         moderateRisk: false,
                      //         highRisk: false,
                      //         imminentRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          selfHarming: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                            lowToNoRisk: result ? null : true,
                            moderateRisk: false,
                            highRisk: false,
                            imminentRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Low to no risk
                  </label>
                </div>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.moderateRisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.moderateRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.moderateRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       selfHarming: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                      //         moderateRisk: result ? null : true,
                      //         lowToNoRisk: false,
                      //         highRisk: false,
                      //         imminentRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          selfHarming: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                            moderateRisk: result ? null : true,
                            lowToNoRisk: false,
                            highRisk: false,
                            imminentRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Moderate risk
                  </label>
                </div>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.highRisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.highRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.highRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       selfHarming: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                      //         highRisk: result ? null : true,
                      //         moderateRisk: false,
                      //         lowToNoRisk: false,
                      //         imminentRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          selfHarming: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                            highRisk: result ? null : true,
                            moderateRisk: false,
                            lowToNoRisk: false,
                            imminentRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    High risk
                  </label>
                </div>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.imminentRisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.imminentRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming?.imminentRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       selfHarming: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                      //         imminentRisk: result ? null : true,
                      //         highRisk: false,
                      //         moderateRisk: false,
                      //         lowToNoRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          selfHarming: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming,
                            imminentRisk: result ? null : true,
                            highRisk: false,
                            moderateRisk: false,
                            lowToNoRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Imminent risk
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                  Homicidal / Assaultive:
                </h6>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.lowtoNorisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.lowToNoRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.lowToNoRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       assaultive: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                      //         lowToNoRisk: result ? null : true,
                      //         highRisk: false,
                      //         moderateRisk: false,
                      //         imminentRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          assaultive: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                            lowToNoRisk: result ? null : true,
                            highRisk: false,
                            moderateRisk: false,
                            imminentRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Low to no risk
                  </label>
                </div>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.moderateRisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.moderateRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.moderateRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       assaultive: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                      //         moderateRisk: result ? null : true,
                      //         lowToNoRisk: false,
                      //         highRisk: false,
                      //         imminentRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          assaultive: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                            moderateRisk: result ? null : true,
                            lowToNoRisk: false,
                            highRisk: false,
                            imminentRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Moderate risk
                  </label>
                </div>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.highRisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.highRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.highRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       assaultive: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                      //         highRisk: result ? null : true,
                      //         moderateRisk: false,
                      //         lowToNoRisk: false,
                      //         imminentRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          assaultive: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                            highRisk: result ? null : true,
                            moderateRisk: false,
                            lowToNoRisk: false,
                            imminentRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    High risk
                  </label>
                </div>
              </Col>
              <Col xl={3}>
                <div className="col-auto d-flex">
                  <input
                    style={{ fontSize: "larger", marginRight: "0.5rem" }}
                    type="radio"
                    className="form-check-input"
                    // value={SelfHarming.imminentRisk}
                    checked={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.imminentRisk === true}
                    onClick={e => {
                      const result = formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive?.imminentRisk === true;
                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       assaultive: {
                      //         ...formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                      //         imminentRisk: result ? null : true,
                      //         highRisk: false,
                      //         moderateRisk: false,
                      //         lowToNoRisk: false,
                      //       },

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          assaultive: {
                            ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.assaultive,
                            imminentRisk: result ? null : true,
                            highRisk: false,
                            moderateRisk: false,
                            lowToNoRisk: false,
                          },
                        },
                      };
                    }}
                  />
                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                    Imminent risk
                  </label>
                </div>
              </Col>
            </Row>

            <Row>
              <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                  ACTIONS TAKEN BASED ON EVALUATION:
                </h6>
                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                  <CKEditor
                    editor={Editor}
                    data={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation || ""}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      // setFormData(() => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       actionEvaluation: data,
                      //       beliefSystem: formData?.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "",
                      //       roleOfBeliefinlife: formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "",
                      //       roleOfBeliefRecovery: formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "",
                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          actionEvaluation: data,
                          beliefSystem: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "",
                          roleOfBeliefinlife: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "",
                          roleOfBeliefRecovery: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "",
                        },
                      };
                    }}
                  />
                </div>
              </Card>
            </Row>
          </Card>
        </Row>

        <Row>
          <Card className="match-card-body p-3">
            <Row>
              <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                  If applicable, please describe your belief system: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                </h6>
                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                  <CKEditor
                    editor={Editor}
                    data={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? ""}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       beliefSystem: data,

                      //       // selfHarming: formData?.riskToSelfAndOthers?.evaluationOfRisk?.selfHarming ?? "",
                      //       // assaultive: formData?.riskToSelfAndOthers?.evaluationOfRisk?.assaultive ?? "",
                      //       actionEvaluation: formData?.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "",
                      //       roleOfBeliefinlife: formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "",
                      //       roleOfBeliefRecovery: formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "",
                      //     },
                      //   },
                      // }));

                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          beliefSystem: data,
                          actionEvaluation: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "",
                          roleOfBeliefinlife: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "",
                          roleOfBeliefRecovery: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "",
                        },
                      };
                    }}
                  />
                </div>
              </Card>
            </Row>
            <Row>
              <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                  What has been the role of your beliefs/religion/spirituality in your life? ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                </h6>
                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                  <CKEditor
                    editor={Editor}
                    data={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? ""}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       roleOfBeliefinlife: data,

                      //       actionEvaluation: formData?.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "",
                      //       beliefSystem: formData?.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "",
                      //       roleOfBeliefRecovery: formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "",

                      //     },
                      //   },
                      // }));

                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          roleOfBeliefinlife: data,

                          actionEvaluation: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "",
                          beliefSystem: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "",
                          roleOfBeliefRecovery: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? "",
                        },
                      };
                    }}
                  />
                </div>
              </Card>
            </Row>
            <Row>
              <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                  What has been the role of your beliefs/religion/spirituality in your wellness/recovery? ({" "}
                  <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                </h6>
                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                  <CKEditor
                    editor={Editor}
                    data={formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery ?? ""}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      // setFormData((formData: Partial<DigitalForm>) => ({
                      //   ...formData,
                      //   riskToSelfAndOthers: {
                      //     ...formData?.riskToSelfAndOthers,
                      //     evaluationOfRisk: {
                      //       ...formData?.riskToSelfAndOthers?.evaluationOfRisk,
                      //       roleOfBeliefRecovery: data,
                      //       roleOfBeliefinlife: formData?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "",

                      //       actionEvaluation: formData?.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "",
                      //       beliefSystem: formData?.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "",

                      //     },
                      //   },
                      // }));
                      formData.current.riskToSelfAndOthers = {
                        ...formData.current.riskToSelfAndOthers,
                        evaluationOfRisk: {
                          ...formData?.current.riskToSelfAndOthers?.evaluationOfRisk,
                          roleOfBeliefRecovery: data,
                          roleOfBeliefinlife: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife ?? "",
                          actionEvaluation: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.actionEvaluation ?? "",
                          beliefSystem: formData?.current.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem ?? "",
                        },
                      };
                    }}
                  />
                </div>
              </Card>
            </Row>
          </Card>
        </Row>

        {/* */}

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                // const updateDetails = {
                //   formHeader: {
                //     ...formData?.current.formHeader,
                //   },
                //   riskToSelfAndOthers: {
                //     ...formData?.current.riskToSelfAndOthers,
                //     currentRiskToSelf: {
                //       ...formData?.current.riskToSelfAndOthers?.currentRiskToSelf,
                //       describeSelfInjured: selfInjuredPastMonth,
                //       describeSuicidalThreats: suicidalIdeation,
                //       describeCommandHallucinations: selfcommandHallucination,
                //     },
                //     currentRiskToOthers: {
                //       ...formData?.current.riskToSelfAndOthers?.currentRiskToOthers,
                //       describeHallucination: othercommandHallucination,
                //     },
                //     historyOfSuicidalBehavior: {
                //       ...formData?.current.riskToSelfAndOthers?.historyOfSuicidalBehavior,
                //       describeHistoryOfSuicidalBehavior: historyOfSuicidalBehavior,
                //       describeHomicidalBehavior: historyOfHomicidalBehavior,
                //       describeSeriousPhysicalHarm: physicalHarmtoSelf,
                //       describeAggression: historyOfAggression,
                //     },
                //   },
                // };
                // handleBulkFormDataUpdate(updateDetails);
                // nextStep();
                handleUpdateData();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 9 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default React.memo(NewFormNine);
