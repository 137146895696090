import { CardContent, TextField } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button, Container } from "reactstrap";
import "./../../assets/css/therapistList.css";
import "./../../assets/css/subscription.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../context/UserContext";
import { CommonService } from "src/services/CommonService";
import { UserDetails } from "src/models/User";
import CoverImage from './../../assets/images/end-meeting.png'
import Animation from './../../assets/images/end-meeting-animation.gif'
import CorrectMark from './../../assets/images/right mark.png'
import { ClientService } from "src/services/ClientService";
import moment from "moment";
import { Appointment } from "src/models/Appointment";

const WelcomePage: React.FC = () => {
    const [user, setUser] = useContext(UserContext);
    const history = useHistory();
    const [appointment, setAppointment] = useState<Appointment>();

    useEffect(() => {
        ClientService.getUpcommingAppointment().then(res => {
            if (res.success) {
                if (res?.data?.length > 0) {
                    setAppointment(res.data[0])
                } else {
                    history.push("/dashboard");
                }
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    }, [user]);


    return (
        <div className="page-content">
            <Container fluid>
                <div className="p-2 p-md-4 d-flex justify-content-center align-items-center ">
                    <div className="card">
                        <div className="welcomeInfoNew">
                            <div className="d-flex justify-content-center align-items-center flex-col">
                                <div className='welcomeInfoNew'>
                                    <img src={CoverImage} className='coverWelcomeImg' />
                                    <div className='overlaySuccess'>
                                        <img src={Animation} />
                                    </div>
                                    <div className='overlaySuccess'>
                                        <img src={CorrectMark} className='correctWelcomeImg' />
                                    </div>
                                </div>

                                <div className="p-4 d-flex justify-content-center align-items-center flex-col">
                                    <h1 className="mb-3 mt-4">Congratulations!</h1>
                                    <p className="mb-3 lead fw-bold text-center">Your appointment for {moment(appointment?.start).format("MMM Do")} at {moment(appointment?.start).format("LT")} with our therapist {appointment?.therapistId?.firstname} is confirmed</p>
                                    {
                                        user?.copaymentAmount ? (
                                            <p className="mb-3 text-center">You have a copayment of <span className="fw-bold">${user?.copaymentAmount}</span>.</p>
                                        ) : (<p className="mb-3 text-center">You haven&apos;t a copayment.</p>

                                        )
                                    }

                                    <p className="mb-3 text-center">You will get a reminder 24hrs and 15mins before your appointment. All you have to do is sign in on our website and click the join button on the pop-up screen to join your session.
                                        Your therapist will be there waiting.</p>
                                    <p className="mb-3 text-center">Please don’t hesitate to reach out if you have any questions.</p>
                                    <p className="mb-3 lead text-center">We genuinely excited about your upcoming appointment and are here to ensure it’s a positive experience for you.</p>
                                    <div>
                                        <a href="/dashboard" className="btn btn-primary" >Go to Dashboard</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default WelcomePage;
