import React from "react";
import "../../assets/css/skeleton.css";

const SkeltonClientRequest: React.FC = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-6 col-xl-6 col-6">
                <div className="flex1">
                  <div className="flex flex-row justify-content-around align-items-center">
                    <div className="circle "></div>
                    <div className="line h8 w25 mt-2 mr-3"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-6 col-6">
                <div className="flex justify-content-end">
                <div className="close-circle mttclose"></div>
                </div>
                <div className="flex flex-column align-items-center justify-content-center">
                  <div className="line mt-1 h8 w50"></div>
                  <div className="line mt-1 h8 w50"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-6 col-xl-6 col-6">
                <div className="flex1">
                  <div className="flex flex-row justify-content-around align-items-center">
                    <div className="circle "></div>
                    <div className="line h8 w25 mt-2 mr-3"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-6 col-6">
                <div className="flex justify-content-end">
                <div className="close-circle mttclose"></div>
                </div>
                <div className="flex flex-column align-items-center justify-content-center">
                  <div className="line mt-1 h8 w50"></div>
                  <div className="line mt-1 h8 w50"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-6 col-xl-6 col-6">
                <div className="flex1">
                  <div className="flex flex-row justify-content-around align-items-center">
                    <div className="circle "></div>
                    <div className="line h8 w25 mt-2 mr-3"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-6 col-6">
                <div className="flex justify-content-end">
                <div className="close-circle mttclose"></div>
                </div>
                <div className="flex flex-column align-items-center justify-content-center">
                  <div className="line mt-1 h8 w50"></div>
                  <div className="line mt-1 h8 w50"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SkeltonClientRequest;
