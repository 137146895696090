import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, Col, Container, Input, Row } from "reactstrap";
import UserContext from "../../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { WorkingHour } from "../../../models/WorkingHour";
import { UserDetails2 } from "../../../models/User";
import { CommonService } from "../../../services/CommonService";
import { ExperienceTag, SelectedExperienceTag } from "../../../models/ExperienceTag";
import Creatable from "react-select/creatable";
import Select from "react-select";
import { Tags } from "../../../common/custom-components/Tags";
import { Profession, ProfessionLicense } from "../../../models/Profession";
import { Link } from "react-router-dom";
import { ArticleService } from "../../../services/ArticleService";
import { TherapistService } from "../../../services/TherapistService";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { Upload } from "../../../models/Upload";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "../../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../../services/InsuranceCompanyService";
import { Util } from "../../../Util";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { RouteName } from "src/RouteName";

const TherapistProfessionalExperienceOnboardingPage: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const history = useHistory();
  const [newWorkingHour, setNewWorkingHour] = useState({} as WorkingHour);
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([] as WorkingHour[]);
  const [modalCenterWorkingHours, setModalCenterWorkingHours] = useState(false);
  const [selectedExpTags, setSelectedExpTags] = useState<SelectedExperienceTag[]>([] as SelectedExperienceTag[]);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [selecteInsuranceCompanyTags, setSelectedInsuranceCompanyTags] = useState<SelectedInsuranceCompanyTag[]>([] as SelectedInsuranceCompanyTag[]);
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [profession, setProfession] = useState({} as Profession);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [professionsLicensDetails, setProfessionsLicensDetails] = useState<ProfessionLicense[]>([] as ProfessionLicense[]);
  const [selectedProfessionsLicensDetails, setSelectedProfessionsLicensDetails] = useState({} as ProfessionLicense);
  const [expereinceYears, setExpereinceYears] = useState(0);
  const [userData, setUserData] = useState<any>();
  const [statementFile, setStatementFile] = useState({ preview: "", preview2: "", raw: "" });
  const statementFileRef = useRef<any>();
  statementFileRef.current = statementFile;
  const [selectedProfessions, setSelectedProfessions] = useState<Profession[]>([] as Profession[]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showMediPassword, setShowMediPassword] = useState(false);
  const [showPsyPassword, setShowPsyPassword] = useState(false);

  useEffect(() => {
    getAllExperienceTags();

    getAllInsuranceCompanies();

    TherapistService.getProfessions().then(res => {
      setProfessionsDetails(res.data);
    });

    TherapistService.getProfessionLicense().then(res => {
      setProfessionsLicensDetails(res.data);
    });

    const updateAdd = user?.experiencedIn?.map((tag: { experienceTag: any; _id: any }) => {
      return { label: tag.experienceTag, value: tag._id };
    });

    if (updateAdd) {
      setSelectedExpTags(updateAdd);
    }

    const updateInsurance = user?.insuranceCompanies?.map((tag: { insuranceCompany: any; _id: any }) => {
      return { label: tag?.insuranceCompany, value: tag?._id };
    });

    if (updateInsurance) {
      setSelectedInsuranceCompanyTags(updateInsurance);
    }
    const workingHours = user?.workingHours;

    if (workingHours) {
      setWorkingHours(workingHours);
    }
  }, []);

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });

        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };

  const getAllInsuranceCompanies = () => {
    InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.insuranceCompany };
        });
        setSearchInsuranceCompanyTag(tagList);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };

  const dropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const creatableComponents = {
    DropdownIndicator: dropdownIndicator,
    IndicatorSeparator: null,
  };

  const handlExpTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label, value: tag.value };
      });

      if (updatedTags) {
        setSelectedExpTags(updatedTags);
      }
    }
  };

  const removeExpTag = (removedtag: any) => {
    setSelectedExpTags(selectedExpTags.filter(tag => tag.label !== removedtag));
  };

  const handlInsuranceCompanyTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any; value: any }) => {
      return { label: tag.label, value: tag.value };
    });

    if (updatedTags) {
      setSelectedInsuranceCompanyTags(updatedTags);
    }
  };

  const removeInsuranceCompanyTag = (removedtag: any) => {
    setSelectedInsuranceCompanyTags(selecteInsuranceCompanyTags.filter(tag => tag.label !== removedtag));
  };

  const changeExpereinceYears = (event: any, newValue: any) => {
    setExpereinceYears(newValue);
  };

  const selectProfessionalData = (e: any) => {
    const proId = e.substring(0, e.lastIndexOf("/"));
    const proName = e.substring(e.lastIndexOf("/") + 1, e.length);
    setProfession({ ...profession, _id: proId, name: proName });
    const proLicenseData = professionsLicensDetails?.filter(c => c.professionId === proId);
    setSelectedProfessions(proLicenseData);
  };

  const updateTherapistDetails = () => {
    if (!profession?._id) {
      toast.error("profession is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (profession?.name == "Counselors, Clinicians, Therapists" && !statementFileRef.current.raw) {
      toast.error("Professional disclosure statement is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const updatedUser: UserDetails2 = {
        firstname: user?.firstname,
        lastname: user?.lastname,
        username: user?.username,
        gender: user?.gender,
        dateOfBirth: user?.dateOfBirth,
        email: user?.email,
        zipCode: user?.zipCode,
        state: user?.state,
        primaryPhone: user?.primaryPhone,
        deletingStatementId: "none",
        ethnicityId: user?.ethnicityId?._id as any,
        description: user?.description,
        profession: profession?._id,
        professionLicense: selectedProfessionsLicensDetails?._id,
        yearsOfExperience: expereinceYears || user?.yearsOfExperience,
        experiencedIn: selectedExpTags.map(tag => {
          return tag.label;
        }),
        insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
          return tag.label;
        }),
        workingHours: user?.workingHours,
        timeZone: user?.timeZone,
        caqhUserName: user?.caqhUserName,
        caqhPassword: user?.caqhPassword,
        medicaidUsername: user?.medicaidUsername,
        MedicaidPassword: user?.MedicaidPassword,
        psychologyTodayUsername: user?.psychologyTodayUsername,
        psychologyTodayPassword: user?.psychologyTodayPassword,
      };
      CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
        if (res.success) {
          setUser(res.data);
          history.push(RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  const selectStatementFile = (e: any) => {
    if (e.target.files.length) {
      setStatementFile({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const statementFiles = () => {
    switch (statementFileRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        setStatementFile({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  return (
    <>
      <div className="page-content">
        <Container className="containerOnboarding">
          <div className="card-heading mb-2">
            <h5 className="font-size-25 profileFont mb-4 text-center">Welcome to Lavni</h5>
          </div>
          <Row className="p-4">
            <Col xl={12}>
              <Card className="BorderRadiusLeftRight p-5">
                <div className="card-bodyDiv">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-20 profileFont mb-4">Your Professional Experience</h5>
                  </div>
                  <Row>
                    <Col xl={8}>
                      {user?.role == "THERAPIST" && (
                        <div>
                          <div className="card-heading mb-2">
                            <h5 className="font-size-14 mb-4">Please see all areas of specialization:</h5>
                          </div>

                          <Creatable
                            options={searchExpTag}
                            isMulti
                            components={creatableComponents}
                            onChange={(newValue: any) => handlExpTags(newValue)}
                            value={selectedExpTags}
                            display="none"
                            className="creatableEdit"
                            placeholder="Choose an area"
                            controlShouldRenderValue={false}
                            isClearable={false}
                          />

                          <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                            {selectedExpTags.map((tag, id) => {
                              return <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
                            })}
                          </div>
                          <div className="card-heading mb-4 mt-4">
                            <h5 className="font-size-14 mb-2">Your profession and experience:</h5>
                          </div>

                          <div className="mb-2">
                            <div className="col-xl-12">
                              <select className="form-control" onChange={e => selectProfessionalData(e.target.value)} defaultValue={profession?._id}>
                                <option>Select</option>
                                {professionsDetails &&
                                  professionsDetails.map(opt => (
                                    <option value={opt._id + "/" + opt.name} key={opt._id}>
                                      {opt.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          {selectedProfessions !== undefined && selectedProfessions.length > 0 ? (
                            <div className="mb-2">
                              <div className="col-xl-12">
                                <select
                                  className="form-control"
                                  onChange={e => setSelectedProfessionsLicensDetails({ ...selectedProfessionsLicensDetails, _id: e.target.value })}
                                  value={(selectedProfessionsLicensDetails?._id as string) || user?.professionLicense?._id}
                                >
                                  <option>Select License</option>
                                  {selectedProfessions &&
                                    selectedProfessions.map(opt => (
                                      <option value={opt._id} key={opt._id}>
                                        {opt.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          ) : null}

                          {profession?.name === "Counselors, Clinicians, Therapists" && (
                            <>
                              <div className="card-heading mb-4 mt-4">
                                <h5 className="font-size-14 mb-2">Your professional disclosure statement:</h5>
                              </div>

                              {user?.disclosureStatementId !== null && (
                                <div className="flex  mb-2">
                                  <div className="mr-2">
                                    <Link
                                      to={{ pathname: Util.fileURL(user?.disclosureStatementId?._id) }}
                                      target="_blank"
                                      className="events-icon2"
                                      style={{ fontSize: "62px" }}
                                    >
                                      <FileThumbnail file={user?.disclosureStatementId as Upload} />
                                    </Link>
                                  </div>
                                </div>
                              )}

                              {statementFileRef.current.preview ? (
                                <div>
                                  {statementFileRef.current.preview && (
                                    <label htmlFor="file-upload1" className="file-upload m-auto">
                                      <span className="text-primary pt-5 m-auto cursor-pointer">Change disclosure statement</span>

                                      <input
                                        id="file-upload1"
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                        onChange={selectStatementFile}
                                        multiple
                                      />
                                    </label>
                                  )}
                                  <div>
                                    <div className="flex">
                                      <div style={{ fontSize: "62px" }}>{statementFiles()}</div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {!statementFileRef.current.preview && (
                                <label
                                  htmlFor="file-upload1"
                                  style={{ border: "1px dashed" }}
                                  className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                                >
                                  <div className="flex flex-wrap items-center pl-5 pt-4 pb-1 file_upload_content">
                                    <img src={uploadIcon} />
                                    <p className="m-0 text-black">&nbsp;&nbsp;Upload your disclosure statement</p>
                                  </div>
                                  <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                  <input
                                    id="file-upload1"
                                    type="file"
                                    style={{ display: "none" }}
                                    className="file_upload_input mb-4"
                                    accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                    onChange={selectStatementFile}
                                    multiple
                                  />
                                </label>
                              )}
                            </>
                          )}

                          {/* <Slider value={expereinceYears || user?.yearsOfExperience} onChange={changeExpereinceYears} aria-label="Disabled slider" className="primary" valueLabelDisplay="auto" max={10} /> */}
                        </div>
                      )}
                    </Col>
                  </Row>
                  {user?.role == "THERAPIST" && (
                    <Row>
                      <Col xl="8">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 profileFont mb-4">
                            Please select all the insurance companies you are credentialed with:
                            <span style={{ color: "#990000" }}></span>
                          </h5>
                        </div>

                        <Select
                          onChange={(newValue: any) => handlInsuranceCompanyTags(newValue)}
                          value={selecteInsuranceCompanyTags}
                          isMulti
                          options={searchInsuranceCompanyTag}
                          className="basic-multi-select"
                          placeholder="Choose a company"
                          display="none"
                          controlShouldRenderValue={false}
                          classNamePrefix="select"
                          isClearable={false}
                        />

                        <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                          {selecteInsuranceCompanyTags.map((tag, id) => {
                            return <Tags label={tag.label} id={id} key={id} removeTag={removeInsuranceCompanyTag} />;
                          })}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {user?.role == "THERAPIST" && (
                    <Row>
                      <Col xl="6">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 mb-2">CAQH User name:</h5>
                        </div>
                        <div>
                          <Input
                            id="formrow-username-Input"
                            placeholder="user name"
                            name="user name"
                            type="text"
                            class="form-control form-control"
                            value={user?.caqhUserName || ""}
                            onChange={e => setUser({ ...user, caqhUserName: e.target.value })}
                          ></Input>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 mb-2">CAQH Password:</h5>
                        </div>
                        <div>
                          <Input
                            id="formrow-username-Input"
                            placeholder="password"
                            name="password"
                            type={showPassword ? "password" : "text"}
                            class="form-control form-control"
                            value={user?.caqhPassword || ""}
                            onChange={e => setUser({ ...user, caqhPassword: e.target.value })}
                          />
                          <div className="eyeIcon" style={{ top: "84px", right: "24px" }} onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </div>
                        </div>
                      </Col>

                      <Col xl="6">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 profileFont mb-2">Medicaid User Name</h5>
                        </div>
                        <div className="card-heading mb-3">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Medicaid Username"
                            name="medicaidUsername"
                            value={user?.medicaidUsername}
                            onChange={e => setUser({ ...user, medicaidUsername: e.target.value })}
                            required
                          />
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 profileFont mb-2">Medicaid Password</h5>
                        </div>
                        <div className="card-heading mb-3" style={{ position: "relative" }}>
                          <Input
                            type={showMediPassword ? "text" : "password"}
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Medicaid Password"
                            name="MedicaidPassword"
                            value={user?.MedicaidPassword}
                            onChange={e => setUser({ ...user, MedicaidPassword: e.target.value })}
                          />
                          <div className="eyeIcon" onClick={() => setShowMediPassword(!showMediPassword)}>
                            {showMediPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 profileFont mb-2">Psychology Today User Name</h5>
                        </div>
                        <div className="card-heading mb-3">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Psychology Today Username"
                            name="Stapsychology Today Username"
                            value={user?.psychologyTodayUsername}
                            onChange={e => setUser({ ...user, psychologyTodayUsername: e.target.value })}
                          />
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="card-heading mb-4 mt-4">
                          <h5 className="font-size-14 profileFont mb-2">Psychology Today Password</h5>
                        </div>
                        <div className="card-heading mb-3" style={{ position: "relative" }}>
                          <Input
                            type={showPsyPassword ? "text" : "password"}
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="psychology Today Password"
                            name="psychology To dayPassword"
                            value={user?.psychologyTodayPassword}
                            onChange={e => setUser({ ...user, psychologyTodayPassword: e.target.value })}
                          />
                          <div className="eyeIcon" onClick={() => setShowPsyPassword(!showPsyPassword)}>
                            {showPsyPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>

                <Row>
                  {/* <div className="d-flex"> */}
                  <Col xl={6}>
                    <button
                      type="button"
                      className="btn btn-skip  relative mt-3 mr-2 mb-4"
                      style={{
                        bottom: "0",
                      }}
                      onClick={() => {
                        history.push(RouteName.THERAPISTPERSONALINFORMATIONONBOARDINGPAGE);
                      }}
                    >
                      <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                      Back
                    </button>
                  </Col>
                  <Col xl={6}>
                    <button
                      type="button"
                      className="btn btn-skip   mr-2  ml-4  relative mt-3 mb-2"
                      style={{
                        bottom: "0",
                        float: "right",
                      }}
                      onClick={() => {
                        history.push(RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE);
                      }}
                    >
                      Skip
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary   mr-2 relative mt-3 mb-2"
                      style={{
                        bottom: "0",
                        float: "right",
                      }}
                      onClick={() => {
                        updateTherapistDetails();
                      }}
                    >
                      Save & Continue
                    </button>
                  </Col>
                  {/* </div> */}
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TherapistProfessionalExperienceOnboardingPage;
