import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { AuthFormType } from "src/models/AuthorizationFormData";
import PhoneInput from "react-phone-number-input";
import { auto } from "@popperjs/core";
import { RequestType } from "src/models/AuthorizationForm/subModels/WellcareAuthFormSubModels/wellcare-authorization-model";
import { WellCareAuthForm } from "src/models/AuthorizationForm/wellcare-auth-form-model";
import moment from "moment";

const WellCareAuthFormPdf: React.FC<{ authorizationFormType: AuthFormType; authorizationFormData: WellCareAuthForm; }> = props => {
  const authorizationFormType = props.authorizationFormType;
  const authorizationFormData = props.authorizationFormData;
  const [formData, setFormData] = useState<WellCareAuthForm>();

  useEffect(() => {
    const updateUnitedHealthCareData: WellCareAuthForm = {
      authorizationInformation: authorizationFormData?.authorizationInformation,
      memberInformation: authorizationFormData?.memberInformation,
      providerInformation: authorizationFormData?.providerInformation,
      facilityInformation: authorizationFormData?.facilityInformation,
      serviceTypeInformation: authorizationFormData?.serviceTypeInformation,
      placeTypeInformation: authorizationFormData?.placeTypeInformation,
      clinicalInformation: authorizationFormData?.clinicalInformation,
    };
    setFormData(updateUnitedHealthCareData);
  }, [authorizationFormData]);

  const sharedStyle: React.CSSProperties = {
    fontSize: "11px",
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    lineHeight: '1'
  };

  const sharedStyleForTable: React.CSSProperties = {
    fontSize: "0.70rem",
    width: '100%',
    padding: '2px',
    border: 'none',
    resize: 'none',
  };

  // const formatDate = (date: any) => {
  //   if (!date) return '';
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment.utc(date).format('YYYY-MM-DD');
  };

  return (
    <>
      {authorizationFormType === AuthFormType.WellcareAuthForm && (
        <>
          <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
            <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
              <Row className="align-items-center">
                <Col xs="12" sm="5" className="text-center text-sm-start">
                  <div>
                    <img
                        src="/static/assets/img/authorization/wellcare.png"
                        alt="Wellcare Insurance Company Logo"
                        className="img-fluid"
                        style={{ maxWidth: '200px' }}
                    />
                  </div>
                </Col>
                <Col xs="12" sm="7" className="text-center text-sm-end">
                  <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                    <span style={{ fontSize: "15px", color: "#333", fontWeight: "500" }}>For Faster, Self-Service: <a href="https://provider.wellcare.com" target="_blank" rel="noreferrer noopener">provider.wellcare.com</a></span><br />
                    <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request Form</span><br />
                  </p>
                </Col>
              </Row>
            </Card>
            <Card style={{ backgroundColor: "#E6E6E6", border: "1px solid #B2B2B2", padding: "0.5rem", marginBottom: "0.60rem", marginTop: "0.15rem" }}>
              <Row>
                <div className="card-bodyDiv" style={{ width: '100%' }}>

                  <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                    <p style={{ margin: "0" }}>
                      Clinical information and supportive documentation should consist of current physician order, notes and recent
                      diagnostics.<strong> Notification is required for any date of service change.</strong>
                    </p>
                  </div>

                </div>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Date :</h6>
                      </div>
                      <div className="col">
                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="date"
                          readOnly
                          value={formatDate(formData?.authorizationInformation?.date)}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Requesting Provider :</h6>
                      </div>
                      <div className="col">
                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          readOnly
                          value={formData?.authorizationInformation?.requestingProvider || ''}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Contact Name  :</h6>
                      </div>
                      <div className="col">
                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          readOnly
                          value={formData?.authorizationInformation?.contactName || ''}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card style={{ marginLeft: "0.85rem", marginBottom: "0.5rem", marginRight: "0.85rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>NPI :</h6>
                      </div>
                      <div className="col">
                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          readOnly
                          value={formData?.authorizationInformation?.npi || ''}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>TIN :</h6>
                      </div>
                      <div className="col">
                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="text"
                          readOnly
                          value={formData?.authorizationInformation?.tin || ''}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={6}>
                  <Card style={{ marginLeft: "0.85rem", marginBottom: "0.5rem", marginRight: "0.85rem" }}>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Consumer Phone :</h6>
                      </div>
                      <div className="col">
                        <div className="col">
                          <PhoneInput
                            defaultCountry="US"
                            readOnly
                            value={formData?.authorizationInformation?.phoneNumber || ''}
                            onChange={(value) => {
                              setFormData({
                                ...formData,
                                authorizationInformation: {
                                  ...formData?.authorizationInformation,
                                  phoneNumber: value
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ marginBottom: "0.5rem", marginLeft: "0.85rem", marginRight: "0.85rem" }}>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Fax Number :</h6>
                      </div>
                      <div className="col">
                        <PhoneInput
                          defaultCountry="US"
                          readOnly
                          value={formData?.authorizationInformation?.faxNumber || ''}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              authorizationInformation: {
                                ...formData?.authorizationInformation,
                                faxNumber: value
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xl={auto} md={12}>
                  <h6 className="fw-500" style={{ margin: "0.2rem", fontSize: "12px" }}><strong><u>Type of Request :</u></strong></h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="row">
                    <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                      <input
                        style={{ fontSize: "larger" }}
                        type="radio"
                        className="form-check-input"
                        name="typeOfRequest"
                        readOnly
                        checked={formData?.authorizationInformation?.typeOfRequest === RequestType.URGENT}
                      />
                    </div>
                    <div className="col">
                      <Card style={{ backgroundColor: "#FFFFFF", marginBottom: "0.25rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Urgent <i>(Urgent is defined as ‘significant impact to health of member’)</i></h6>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={auto} lg={6}>
                  <div className="col-auto">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      name="typeOfRequest"
                      readOnly
                      checked={formData?.authorizationInformation?.typeOfRequest === RequestType.NON_URGENT}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Non-Urgent</label>
                  </div>
                </Col>
                <Col xl={auto} lg={6}>
                  <div className="col-auto">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      name="typeOfRequest"
                      readOnly
                      checked={formData?.authorizationInformation?.typeOfRequest === RequestType.PRE_SERVICE}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Pre-Service </label>
                  </div>
                </Col>
                <Col xl={auto} lg={6}>
                  <div className="col-auto">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      name="typeOfRequest"
                      readOnly
                      checked={formData?.authorizationInformation?.typeOfRequest === RequestType.POST_SERVICE}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Post-Service (Retro) </label>
                  </div>
                </Col>
                <Col xl={auto} lg={6}>
                  <div className="col-auto">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      name="typeOfRequest"
                      readOnly
                      checked={formData?.authorizationInformation?.typeOfRequest === RequestType.CON_CURRENT}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Concurrent</label>
                  </div>
                </Col>
                <Col xl={auto} lg={6}>
                  <div className="col-auto">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      name="typeOfRequest"
                      readOnly
                      checked={formData?.authorizationInformation?.typeOfRequest === RequestType.EMERGENT}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Emergent</label>
                  </div>
                </Col>
              </Row>
            </Card>
            <Row className="mt-2">
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Member Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Member Name :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                          <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.memberInformation?.memberName || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>Date of birth :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Medicaid ID :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                          <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.memberInformation?.medicaidId || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>WellCare ID :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                          <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.memberInformation?.wellcareId || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="4">
                          <label>Address (street, city, state, ZIP code) :</label>
                        </Col>
                        <Col xs="12" sm="8">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.memberInformation?.memberAddress || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xl={4}><h6 className="fw-500">Is this member pregnant ?</h6></Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.memberInformation?.isPregnant === true}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: true } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.memberInformation?.isPregnant === false}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: false } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Member’s PCP :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.memberInformation?.memberPcp || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.memberInformation?.phoneNumber || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  memberInformation: {
                                    ...formData?.memberInformation,
                                    phoneNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>NPI :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.memberInformation?.npi || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <div style={{ display: 'flex', }}>
                    <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>Treating / Servicing Provider Information</h5>
                    <div className="form-check">
                      <input className="form-check-input"
                        type="checkbox"
                        name="sameAsRequest"
                        style={{ fontSize: "larger" }}
                        readOnly
                        checked={formData?.providerInformation?.sameAsRequest}
                      />
                      <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                        Same a Requesting
                      </label>
                    </div>
                  </div>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Provider name :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.providerName || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>WellCare ID :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.wellcareId || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>NPI :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.npi || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Tax ID :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.taxId || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Address  :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.providerInformation?.providerAddress || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="1" >
                          <label>City :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.city || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>State :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.state || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>Zip code :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.zipCode || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.providerInformation?.phoneNumber || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    phoneNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                          <label>Fax Number :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.providerInformation?.faxNumber || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>Are any supporting documents included? </h6>
                        </Col>
                        <Col xl={2}>
                          <div className="col-auto d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isDocumentsIncluded"
                              readOnly
                              checked={formData?.providerInformation?.isDocumentsIncluded === true}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                          </div>

                        </Col>
                        <Col xl={2}>
                          <div className="col-auto d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isDocumentsIncluded"
                              readOnly
                              checked={formData?.providerInformation?.isDocumentsIncluded === false}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                          </div>

                        </Col>
                        <Col xl={4}>
                          <div className="row">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Number of documents :</h6>
                                  </div>
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      disabled={formData?.providerInformation?.isDocumentsIncluded !== true}
                                      value={formData?.providerInformation?.documentsAmount || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Facility / Ancillary Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Facility/Ancillary Name :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.facilityName || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>WellCare ID :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.wellcareId || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>NPI :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.npi || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Tax ID :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.taxId || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Address  :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.facilityInformation?.address || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="1">
                          <label>City :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.city || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>State :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.state || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>Zip code :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.facilityInformation?.zipCode || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.facilityInformation?.phoneNumber || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  facilityInformation: {
                                    ...formData?.facilityInformation,
                                    phoneNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                          <label>Fax Number :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.facilityInformation?.faxNumber || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  facilityInformation: {
                                    ...formData?.facilityInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>Are any supporting documents included? </h6>
                        </Col>
                        <Col xl={2}>
                          <div className="col-auto d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isFacilityDocumentsIncluded"
                              readOnly
                              checked={formData?.facilityInformation?.isDocumentsIncluded === true}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                          </div>

                        </Col>
                        <Col xl={2}>
                          <div className="col-auto d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isFacilityDocumentsIncluded"
                              readOnly
                              checked={formData?.facilityInformation?.isDocumentsIncluded === false}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                          </div>

                        </Col>
                        <Col xl={4}>
                          <div className="row">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Number of documents :</h6>
                                  </div>
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      value={formData?.facilityInformation?.documentsAmount || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Type of Service</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row><Card className="match-card-header-secondary p-1 mb-2">
                        <div className="fw-500" style={{ margin: "auto", fontSize: "14px" }}>BEHAVIORAL HEALTH SERVICES</div>
                      </Card>
                      </Row>
                      <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                        <Row className="mt-2">
                          <Col xl={6} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.intensiveOutpatient}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Intensive Outpatient</label>
                          </Col>
                          <Col xl={6} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.routineOutpatient}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Routine Outpatient</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.caseManagement}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Case Management</label>
                          </Col>
                          <Col xl={6} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.ect}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – ECT</label>
                          </Col>
                        </Row>
                        <Row >
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.subAcute}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Sub Acute</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.csu}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – CSU</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.inpatient}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Inpatient </label>
                          </Col>
                        </Row>
                        <Row >
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.detox}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Detox</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.rehabilitation}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Rehabilitation</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.residential}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavioral Health – Residential </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.behaviourService?.other}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Other : </label>

                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      value={formData?.serviceTypeInformation?.behaviourService?.detailsForOther || ""}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        </Row></Card>
                      <Row><Card className="match-card-header-secondary p-1 mb-2">
                        <div className="fw-500" style={{ margin: "auto", fontSize: "14px" }}>MEDICAL SERVICES</div>
                      </Card>
                      </Row>
                      <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                        <Row className="mt-2">
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.dmePurchase}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>DME Purchase </label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.dmeRental}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>DME Rental</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.homeHealth}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Home Health</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.inpatientAdmission}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Inpatient Admission </label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.inpatientRehab}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Inpatient Rehab</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.snf}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>SNF</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.surgeryInpatient}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Surgery – Pre-Planned Inpatient</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.skilledTherapy}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Skilled Therapy (PT/OT/ST)</label>
                          </Col>
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.surgeryOutpatient}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Surgery – Outpatient</label>
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col xl={4} className="d-flex">
                            <input
                              style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.ltach}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>LTACH</label>
                          </Col>
                          <Col xl={8} className="d-flex">
                            <input style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.medicalService?.other}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Other :  </label>
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      value={formData?.serviceTypeInformation?.medicalService?.detailsForOther || ""}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      <Row><Card className="match-card-header-secondary p-1 mb-2">
                        <div className="fw-500" style={{ margin: "auto", fontSize: "14px" }}>TRANSPOTATION</div>
                      </Card>
                      </Row>
                      <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                        <Row className="mt-2">
                          <Col xl={3} className="d-flex">
                            <input style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.transpotation?.air}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Air</label>
                          </Col>
                          <Col xl={3} className="d-flex">
                            <input style={{ fontSize: "larger" }}
                              type="checkbox"
                              className="form-check-input"
                              readOnly
                              checked={formData?.serviceTypeInformation?.transpotation?.land}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Land</label>
                          </Col>
                          <Col xl={3} className="d-flex">
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mileage : </label>
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      value={formData?.serviceTypeInformation?.transpotation?.mileage || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                          <Col xl={3} className="d-flex">
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Trips : </label>
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      value={formData?.serviceTypeInformation?.transpotation?.trip || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-1">
                          <Col xl={5}>
                              <h6 className="fw-500" style={{ margin: "0.2rem", fontSize:"12px" }}>O2 Needed </h6>
                          </Col>
                          <Col xl={4}>
                              <div className="col-auto d-flex">
                                  <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                      type="radio"
                                      className="form-check-input"
                                      name="isO2Needed"
                                      readOnly
                                      checked={formData?.serviceTypeInformation?.transpotation?.isO2Needed === true}
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>Yes</label>
                              </div>

                          </Col>
                          <Col xl={3}>
                              <div className="col-auto d-flex">
                                  <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                      type="radio"
                                      className="form-check-input"
                                      name="isO2Needed"
                                      readOnly
                                      checked={formData?.serviceTypeInformation?.transpotation?.isO2Needed === false}
                                  />
                                  <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize:"12px"  }}>No</label>
                              </div>

                          </Col>
                      </Row>
                        <Row>
                          <Col xl={5} className="d-flex">
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Pick Up Address (Street, City, State, Zip Code)   : </label>
                          </Col><Col xl={7} className="d-flex">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <textarea
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      readOnly
                                      value={formData?.serviceTypeInformation?.transpotation?.pickUpAddress || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={5} className="d-flex">
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Drop off Address (Street, City, State, Zip Code) : </label>
                          </Col>
                          <Col xl={7} className="d-flex">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <textarea
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      readOnly
                                      value={formData?.serviceTypeInformation?.transpotation?.dropOffAddress || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        </Row>

                      </Card>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Place of Service</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row >
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.office}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>11 – Office</label>
                        </Col>
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.home}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>12 – Home</label>
                        </Col>
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.inpatient}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>21 – Inpatient</label>
                        </Col>
                      </Row>
                      <Row >
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.outpatient}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>22 – Outpatient</label>
                        </Col>
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.ambulatorySurgery}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>24 – Ambulatory Surgery Center</label>
                        </Col>
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.landAmbulance}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>41 – Land Ambulance</label>
                        </Col>
                      </Row>
                      <Row >
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.airAmbulance}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>41 - Air Ambulance</label>
                        </Col>
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.inpatientPsychiatric}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>51 – Inpatient Psychiatric Hospital</label>
                        </Col>
                        <Col xl={4} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.communityMentalHealth}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>53 – Community Mental Health Center</label>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl={12} className="d-flex">
                          <input style={{ fontSize: "larger" }}
                            type="checkbox"
                            className="form-check-input"
                            readOnly
                            checked={formData?.placeTypeInformation?.other}
                          />
                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Other : </label>
                          <div className="col">
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <div className="row">
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    readOnly
                                    value={formData?.placeTypeInformation?.detailsForOther || ""}
                                  />
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ margin: "auto" }}>Clinical Information</h5>
                  <h6 className="fw-500" style={{ margin: "auto" }}>(Request MUST include medical documentation to be reviewed for medical necessity)</h6>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <Table responsive className="clinical-table-bordered table-md">
                      <thead>
                        {/* <tr>
                          <th colSpan={5} style={{ border: "1px solid #B2B2B2", textAlign: "left", verticalAlign: "middle", lineHeight: "0.3" }}>
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                              <div className="row">
                                <div className="col-auto">
                                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>ICD CODE :</h6>
                                </div>
                                <div className="col">
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      marginTop: "0.1rem",
                                      backgroundColor: "unset",
                                    }}
                                    type="text"
                                    readOnly
                                    value={formData?.clinicalInformation?.icdCode1 || ''}
                                  />
                                </div>
                              </div>
                            </Card>
                          </th>
                        </tr> */}
                        <tr>
                          <th colSpan={5} style={{ border: "1px solid #B2B2B2", textAlign: "left", verticalAlign: "middle", lineHeight: "0.3" }}>
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                                  <div className="row">
                                      <div className="col" style={{ borderRight: "1px solid #B2B2B2" }}>
                                          <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                          <input
                                              style={{
                                                  width: "100%",
                                                  border: "none",
                                                  marginTop: "0.1rem",
                                                  backgroundColor: "unset",
                                              }}
                                              type="text"
                                              value={formData?.clinicalInformation?.icdCode1 || ''}
                                              onChange={(e) => setFormData({
                                                  ...formData,
                                                  clinicalInformation: {
                                                      ...formData?.clinicalInformation,
                                                      icdCode1: e.target.value
                                                  }
                                              })}
                                          />
                                      </div>
                                      <div className="col" style={{ borderRight: "1px solid #B2B2B2" }}>
                                          <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                          <input
                                              style={{
                                                  width: "100%",
                                                  border: "none",
                                                  marginTop: "0.1rem",
                                                  backgroundColor: "unset",
                                              }}
                                              type="text"
                                              value={formData?.clinicalInformation?.icdCode2 || ''}
                                              onChange={(e) => setFormData({
                                                  ...formData,
                                                  clinicalInformation: {
                                                      ...formData?.clinicalInformation,
                                                      icdCode2: e.target.value
                                                  }
                                              })}
                                          />
                                      </div>
                                      <div className="col" style={{ borderRight: "1px solid #B2B2B2" }}>
                                          <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                          <input
                                              style={{
                                                  width: "100%",
                                                  border: "none",
                                                  marginTop: "0.1rem",
                                                  backgroundColor: "unset",
                                              }}
                                              type="text"
                                              value={formData?.clinicalInformation?.icdCode3 || ''}
                                              onChange={(e) => setFormData({
                                                  ...formData,
                                                  clinicalInformation: {
                                                      ...formData?.clinicalInformation,
                                                      icdCode3: e.target.value
                                                  }
                                              })}
                                          />
                                      </div>
                                      <div className="col">
                                          <h6 className="fw-500" style={{ margin: "0.3rem", fontSize:"12px" }}>ICD - 10 :</h6>
                                          <input
                                              style={{
                                                  width: "100%",
                                                  border: "none",
                                                  marginTop: "0.1rem",
                                                  backgroundColor: "unset",
                                              }}
                                              type="text"
                                              value={formData?.clinicalInformation?.icdCode4 || ''}
                                              onChange={(e) => setFormData({
                                                  ...formData,
                                                  clinicalInformation: {
                                                      ...formData?.clinicalInformation,
                                                      icdCode4: e.target.value
                                                  }
                                              })}
                                          />
                                      </div>
                                  </div>
                              </Card>
                          </th>
                      </tr>
                        <tr>
                          <th colSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.3" }}>
                            Dates of Service
                          </th>
                          <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              Procedure /
                              Service Codes
                            </div>
                          </th>
                          <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              Description
                            </div>
                          </th>
                          <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              Requested
                              Start Units/Visits/Days (please specify)
                            </div>
                          </th>

                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.8", fontSize: "11px" }}>
                            Start Date
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.8", fontSize: "11px" }}>
                            Projected End Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(4)].map((_, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formatDate(formData?.clinicalInformation?.clinicalInformationArray?.[index].startDate)}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formatDate(formData?.clinicalInformation?.clinicalInformationArray?.[index].endDate)}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                readOnly
                                value={formData?.clinicalInformation?.clinicalInformationArray?.[index]?.procedureCode || ''}
                              />
                            </td>
                            <td>
                              <textarea
                                style={sharedStyleForTable}
                                readOnly
                                value={formData?.clinicalInformation?.clinicalInformationArray?.[index]?.description || ''}
                              />
                            </td>
                            <td>
                              <textarea
                                style={sharedStyleForTable}
                                readOnly
                                value={formData?.clinicalInformation?.clinicalInformationArray?.[index]?.requestedUnit || ''}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header-secondary p-2 mb-2">
                  <h5 className="fw-600 mb-1" style={{ margin: "auto" }}>North Carolina Medicaid
                  </h5>
                  <div className="fw-500 mb-1" style={{ margin: "auto", fontSize: "14px" }}>Prior Authorization Phone Numbers
                  </div>
                  <div className="fw-500" style={{ margin: "auto", fontSize: "12px" }}><strong><i><u>Expedited Requests:</u></i></strong> If the standard time for making a determination could seriously jeopardize the life and/or
                    health of the member or the member’s ability to regain maximum function, please call <strong>1-866-799-5318</strong>.
                  </div>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <Table responsive className="clinical-table-bordered table-md">
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", padding: "1px", lineHeight: "2" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              DEPARTMENT
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", padding: "1px", lineHeight: "2" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              PHONE
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", padding: "1px", lineHeight: "2" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              FAX
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                            All Medical
                          </td>
                          <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                            <strong>1-866-799-5318 </strong>
                          </td>
                          <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                            Inpatient – <strong>1-800-678-3170</strong><br />
                            Outpatient – <strong>1-866-319-2691</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                            Behavior Health
                          </td>
                          <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                            <strong>1-866-799-5318 </strong>
                          </td>
                          <td style={{ border: "1px solid #B2B2B2", textAlign: 'left', padding: "1px 1px 1px 5px", lineHeight: "1.5", fontSize: "12px" }}>
                            Outpatient – <strong> 1-866-319-2691</strong><br />
                            Inpatient – <strong> 1-800-551-0325</strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </div>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default WellCareAuthFormPdf;
