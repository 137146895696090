import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from "reactstrap";

interface RenameFolderModalProps {
  toggle: () => void;
  isOpen: boolean;
  renameFolderName: () => Promise<void>;
  renameFolder: string;
  changeFolderName: (folder: string) => void;
}

const RenameFolderModal: React.FC<RenameFolderModalProps> = ({ toggle, isOpen, renameFolderName, renameFolder, changeFolderName }) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Rename Folder Name</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Folder Name</Label>
              <Input
                id="exampleEmail"
                name="folderName"
                value={renameFolder}
                onChange={e => changeFolderName(e.target.value)}
                placeholder="FolderName"
                type="text"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={renameFolderName}>
            Submit
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RenameFolderModal;
