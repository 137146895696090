import React, { useEffect, useState } from "react";
import { AdminService } from "src/services/AdminService";
import TherapistRewardContext from "src/context/TherapistRewardCount";

const TherapistRewardUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadTherapistRewardCount, setUnreadTherapistRewardCount] = useState<number>(0);
  const [offset] = useState(0);

  const LIMIT = 0;

  useEffect(() => {
    AdminService.getAllReferralEarnings(LIMIT, offset).then(res => {
      if (res.success) {
        setUnreadTherapistRewardCount(res.data.count)
      }
    });
  }, []);

  return <TherapistRewardContext.Provider value={[unreadTherapistRewardCount, setUnreadTherapistRewardCount]}>{children}</TherapistRewardContext.Provider>;
};

export default TherapistRewardUtils;