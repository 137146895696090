import React, { useState, useEffect, useMemo } from "react";
import { Button, Row, Col, Card } from "reactstrap";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import {
  NameOfSubstance,
  AlcoholAndDrugUseHistoryStatus,
  ProblemWithUse,
  problemWithUseType,
  ExperiencedSymptoms, Sleep
} from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";
interface NameOfSubstanceComments {
  [key: number]: string;
}
const NewFormFour = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;
  const [substance, setSubstance] = useState([{}]);
  const [nameOfSubstanceComments, setNameOfSubstanceComments] = useState<NameOfSubstanceComments>({});
  const [tobaccoComment, setTobaccoComment] = useState("");

  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    alcoholAndDrugUseHistory: {
      historyStatus: "",
    },
    nameOfSubstance: [
      {
        ageOfFirstUse: "",
        regularUse: "",
        dateLastUse: "",
        route: "",
        amount: 0,
        frequency: 0,
        problemWithUse: {},
        comment: "",
      },
    ],
    tobaccoUseTypeDetails: {
      tobaccoUse: null,
      tobaccoType: "",
      howOften: "",
      howMuch: "",
      howLong: "",
      interestEndUse: null,
      interestReducingUse: null,
      endingUse: "",
      longestAbstinence: "",
      whenWas: "",
      relapse: "",
      comment: "",
      experiencedSymptoms: {
        agitationRestlessness: "",
        confusion: "",
        nauseaVomiting: "",
        muscleTwitching: "",
        chills: "",
        deliriumTremens: "",
        tremorsShaking: "",
        anxiety: "",
        racingPulse: "",
        rapidBreathing: "",
        sweats: "",
        hallucinations: "",
        backJointPain: "",
        other: "",
        difficultySleeping: "",
        runnyNose: "",
        tearyEyes: "",
        craving: "",
        cramps: "",
        seizures: "",
        none: "",
      },
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        alcoholAndDrugUseHistory: data?.alcoholAndDrugUseHistory,
        nameOfSubstance: data?.nameOfSubstance,
        tobaccoUseTypeDetails: data?.tobaccoUseTypeDetails,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   alcoholAndDrugUseHistory: data?.alcoholAndDrugUseHistory,
      //   nameOfSubstance: data?.nameOfSubstance,
      //   tobaccoUseTypeDetails: data?.tobaccoUseTypeDetails,
      // };
      // setFormData(updateValues);

      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));

      const newComments: NameOfSubstanceComments = {};
      memoizedUpdateValues?.nameOfSubstance?.forEach((substance: NameOfSubstance, index: number) => {
        newComments[index] = substance?.comment;
      }) ?? [];
      setNameOfSubstanceComments(newComments);
      setTobaccoComment(memoizedUpdateValues?.tobaccoUseTypeDetails?.comment);
    }
  }, [memoizedUpdateValues]);

  useEffect(() => {
    if (!formData?.nameOfSubstance || formData?.nameOfSubstance?.length === 0) {
      setFormData((prevFormData: Partial<DigitalForm>) => ({
        ...prevFormData,
        nameOfSubstance: [
          ...(prevFormData?.nameOfSubstance ?? []),
          {
            ageOfFirstUse: "",
            regularUse: "",
            dateLastUse: "",
            route: "",
            amount: 0,
            frequency: 0,
            problemWithUse: {},
            comment: "",
          },
        ],
      }));
    }
  }, [formData]);

  const addSubstance = () => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      nameOfSubstance: [
        ...(formData?.nameOfSubstance ?? []),
        {
          ageOfFirstUse: "",
          regularUse: "",
          dateLastUse: "",
          route: "",
          amount: 0,
          frequency: 0,
          problemWithUse: {},
          comment: "",
        },
      ],
    }));

    // setSubstance([...substance, {}]);
  };

  const handleSubstanceChange = (index: number, field: keyof NameOfSubstance, value: string | number) => {
    const updatedSubstances = formData?.nameOfSubstance?.map((substance: NameOfSubstance, i: number) => {
      if (i === index) {
        return {
          ...substance,
          [field]: value, // Update the specific field
        };
      }
      return substance; // Return other substances unchanged
    });

    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      nameOfSubstance: updatedSubstances,
    }));
  };

  const handleProblemWithUseChange = (index: number, key: keyof problemWithUseType, value: string) => {
    setFormData((prevFormData: Partial<DigitalForm>) => ({
      ...prevFormData,
      nameOfSubstance: prevFormData?.nameOfSubstance?.map(
        (substance, i) =>
          i === index // Identify the specific substance by index
            ? {
                ...substance,
                problemWithUse: {
                  ...substance?.problemWithUse,
                  [key]:
                    substance.problemWithUse[key] === value
                      ? "" // Clear the value if already set
                      : value, // Set the value otherwise
                },
              }
            : substance // Leave the other substances unchanged
      ),
    }));
  };

  const removeSubstance = (index: number) => {
    if (substance.length > 1) {
      setSubstance(substance.filter((_, i) => i !== index));
    }
  };

  const handleUpdateData = () => {
    const updateDetails = {
      formHeader: {
        ...formData?.formHeader,
      },
      alcoholAndDrugUseHistory: {
        ...formData?.alcoholAndDrugUseHistory,
      },
      nameOfSubstance: [
        ...(formData?.nameOfSubstance?.map((sub: NameOfSubstance, index: number) => ({
          ...sub,
          comment: nameOfSubstanceComments[index],
        })) ?? []),
      ],

      tobaccoUseTypeDetails: {
        ...formData?.tobaccoUseTypeDetails,
        comment: tobaccoComment,
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  const updateExperiencedSymptoms = (field: string, value: boolean|string) => {
    if (field!=="none"){
      setFormData((prevFormData: Partial<DigitalForm>) => ({
        ...prevFormData,
        tobaccoUseTypeDetails: {
          ...prevFormData?.tobaccoUseTypeDetails,
          experiencedSymptoms: {
            ...prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms,
            none: "",
          },
        },
      }));
    }
    setFormData((prevFormData: Partial<DigitalForm>) => ({
      ...prevFormData,
      tobaccoUseTypeDetails: {
        ...prevFormData?.tobaccoUseTypeDetails,
        experiencedSymptoms: {
          ...prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms,
          agitationRestlessness:field==="agitationRestlessness"?value?ExperiencedSymptoms.agitationRestlessness:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.agitationRestlessness,
          confusion:field==="confusion"?value?ExperiencedSymptoms.confusion:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.confusion,
          nauseaVomiting:field==="nauseaVomiting"?value?ExperiencedSymptoms.nauseaVomiting:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.nauseaVomiting,
          muscleTwitching:field==="muscleTwitching"?value?ExperiencedSymptoms.muscleTwitching:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.muscleTwitching,
          chills:field==="chills"?value?ExperiencedSymptoms.chills:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.chills,
          deliriumTremens:field==="deliriumTremens"?value?ExperiencedSymptoms.deliriumTremens:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.deliriumTremens,
          tremorsShaking:field==="tremorsShaking"?value?ExperiencedSymptoms.tremorsShaking:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tremorsShaking,
          anxiety:field==="anxiety"?value?ExperiencedSymptoms.anxiety:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.anxiety,
          racingPulse:field==="racingPulse"?value?ExperiencedSymptoms.racingPulse:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.racingPulse,
          rapidBreathing:field==="rapidBreathing"?value?ExperiencedSymptoms.rapidBreathing:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.rapidBreathing,
          sweats:field==="sweats"?value?ExperiencedSymptoms.sweats:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.sweats,
          hallucinations:field==="hallucinations"?value?ExperiencedSymptoms.hallucinations:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.hallucinations,
          backJointPain:field==="backJointPain"?value?ExperiencedSymptoms.backJointPain:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.backJointPain,
          other:field==="other"?value?ExperiencedSymptoms.other:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.other,
          difficultySleeping:field==="difficultySleeping"?value?ExperiencedSymptoms.difficultySleeping:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.difficultySleeping,
          runnyNose:field==="runnyNose"?value?ExperiencedSymptoms.runnyNose:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.runnyNose,
          tearyEyes:field==="tearyEyes"?value?ExperiencedSymptoms.tearyEyes:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tearyEyes,
          craving:field==="craving"?value?ExperiencedSymptoms.craving:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.craving,
          cramps:field==="cramps"?value?ExperiencedSymptoms.cramps:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.cramps,
          seizures:field==="seizures"?value?ExperiencedSymptoms.seizures:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.seizures,
          none:field==="none"?value?ExperiencedSymptoms.none:"":prevFormData?.tobaccoUseTypeDetails?.experiencedSymptoms?.none,
        },
      },
    }));
  }

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  ALCOHOL AND DRUG USE HISTORY
                </h5>
              </div>
            </Card>
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-body p-3">
              <Row>
                <Col xl={3}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      value={AlcoholAndDrugUseHistoryStatus.currentUse}
                      checked={formData?.alcoholAndDrugUseHistory?.historyStatus === AlcoholAndDrugUseHistoryStatus.currentUse}
                      onClick={() => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          alcoholAndDrugUseHistory: {
                            ...formData?.alcoholAndDrugUseHistory,
                            historyStatus: AlcoholAndDrugUseHistoryStatus.currentUse,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Current Use
                    </label>
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      value={AlcoholAndDrugUseHistoryStatus.denies}
                      checked={formData?.alcoholAndDrugUseHistory?.historyStatus === AlcoholAndDrugUseHistoryStatus.denies}
                      onClick={() => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          alcoholAndDrugUseHistory: {
                            ...formData?.alcoholAndDrugUseHistory,
                            historyStatus: AlcoholAndDrugUseHistoryStatus.denies,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Denies (skip this section)
                    </label>
                  </div>
                </Col>
                <Col xl={5}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      value={AlcoholAndDrugUseHistoryStatus.unknown}
                      checked={formData?.alcoholAndDrugUseHistory?.historyStatus === AlcoholAndDrugUseHistoryStatus.unknown}
                      onClick={() => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          alcoholAndDrugUseHistory: {
                            ...formData?.alcoholAndDrugUseHistory,
                            historyStatus: AlcoholAndDrugUseHistoryStatus.unknown,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Unknown (skip this section)
                    </label>
                  </div>
                </Col>
              </Row>
            </Card>

            {Array.isArray(formData?.nameOfSubstance) &&
              formData?.nameOfSubstance?.map((sub: NameOfSubstance, index: number) => (
                <>
                  {" "}
                  <Row>
                    <div className="card-bodyDiv">
                      <Card className="match-card-header p-2 mb-2">
                        <div style={{ display: "flex" }}>
                          <h5 className="fw-500" style={{ margin: "auto" }}>
                            Name of Substance:
                          </h5>
                        </div>
                      </Card>
                    </div>
                  </Row>
                  <Card key={index} className="match-card-body p-3">
                    <Row>
                      <Col xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Age of First Use:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                }}
                                type="number"
                                disabled={false}
                                value={sub?.ageOfFirstUse}
                                onChange={e => {
                                  handleSubstanceChange(index, "ageOfFirstUse", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Regular Use:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                }}
                                type="text"
                                disabled={false}
                                value={sub?.regularUse}
                                onChange={e => {
                                  handleSubstanceChange(index, "regularUse", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Date last used:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                type="date"
                                id="date"
                                value={sub?.dateLastUse ? moment.utc(sub?.dateLastUse).format("YYYY-MM-DD") : ""}
                                onChange={e => {
                                  handleSubstanceChange(index, "dateLastUse", e.target.value);
                                }}
                                max={moment().format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Route:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                }}
                                type="text"
                                disabled={false}
                                value={sub?.route}
                                onChange={e => {
                                  handleSubstanceChange(index, "route", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Amount:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                }}
                                type="number"
                                disabled={false}
                                value={sub?.amount}
                                onChange={e => {
                                  handleSubstanceChange(index, "amount", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                          <div className="row">
                            <div className="col-auto">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                Frequency:
                              </h6>
                            </div>
                            <div className="col">
                              <input
                                style={{
                                  width: "100%",
                                  border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                  appearance: "textfield",
                                }}
                                type="number"
                                disabled={false}
                                value={sub?.frequency}
                                onChange={e => {
                                  handleSubstanceChange(index, "frequency", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <div style={{ display: "flex", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                        <h6 className="fw-500" style={{ margin: "auto" }}>
                          Problems with use:
                        </h6>
                      </div>

                      <Row>
                        <div className="col-4">
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Tolerance}
                                checked={sub?.problemWithUse?.tolerance === ProblemWithUse.Tolerance}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "tolerance", ProblemWithUse.Tolerance);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Tolerance
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Withdrawal}
                                checked={sub?.problemWithUse?.withdrawal === ProblemWithUse.Withdrawal}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "withdrawal", ProblemWithUse.Withdrawal);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Withdrawal
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Social}
                                checked={sub?.problemWithUse?.social === ProblemWithUse.Social}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "social", ProblemWithUse.Social);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Social
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Legal}
                                checked={sub?.problemWithUse?.legal === ProblemWithUse.Legal}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "legal", ProblemWithUse.Legal);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Legal
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Financial}
                                checked={sub?.problemWithUse?.financial === ProblemWithUse.Financial}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "financial", ProblemWithUse.Financial);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Financial
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.IncreasedAmount}
                                checked={sub?.problemWithUse?.increasedAmount === ProblemWithUse.IncreasedAmount}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "increasedAmount", ProblemWithUse.IncreasedAmount);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Increased amount
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.DesireToCutDown}
                                checked={sub?.problemWithUse?.desireToCutDown === ProblemWithUse.DesireToCutDown}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "desireToCutDown", ProblemWithUse.DesireToCutDown);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Desire to cut down
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Usewhilehazardous}
                                checked={sub?.problemWithUse?.usewhilehazardous === ProblemWithUse.Usewhilehazardous}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "usewhilehazardous", ProblemWithUse.Usewhilehazardous);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Use while hazardous
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Other}
                                checked={sub?.problemWithUse?.other === ProblemWithUse.Other}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "other", ProblemWithUse.Other);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Other:
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.Occupational}
                                checked={sub?.problemWithUse?.occupational === ProblemWithUse.Occupational}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "occupational", ProblemWithUse.Occupational);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Occupational
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.TimeDevoted}
                                checked={sub?.problemWithUse?.timeDevoted === ProblemWithUse.TimeDevoted}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "timeDevoted", ProblemWithUse.TimeDevoted);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Time devoted to using & seeking
                              </label>
                            </div>
                          </div>
                          <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="checkbox"
                                className="form-check-input"
                                value={ProblemWithUse.PhysicalAndOrPsychological}
                                checked={sub?.problemWithUse?.physicalAndOrPsychological === ProblemWithUse.PhysicalAndOrPsychological}
                                onClick={() => {
                                  handleProblemWithUseChange(index, "physicalAndOrPsychological", ProblemWithUse.PhysicalAndOrPsychological);
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Physical and/or psychological problems with continued use
                              </label>
                            </div>
                          </div>
                        </div>

                        <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                          <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                            Comments:
                          </h6>
                          <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                            <CKEditor
                              editor={Editor}
                              data={nameOfSubstanceComments[index] || ""}
                              onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                const details = [...(formData?.nameOfSubstance ?? [])];
                                details[index] = { ...details[index], comment: data };

                                // setFormData({
                                //   ...formData,
                                //   nameOfSubstance: details,
                                // });

                                setNameOfSubstanceComments((prev: NameOfSubstanceComments) => ({
                                  ...prev,
                                  [index]: data,
                                }));
                              }}
                            />
                          </div>
                          {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, precipitatingProblem: e.target.value } })}
                                /> */}
                        </Card>
                        <div className="d-flex justify-content-end">
                          {substance.length > 1 && (
                            <Button color="danger" onClick={() => removeSubstance(index)} style={{ marginBottom: "1rem" }}>
                              Remove
                            </Button>
                          )}
                        </div>
                      </Row>
                    </Row>
                  </Card>
                </>
              ))}

            <div className="col-3 py-2">
              <Button color="primary" onClick={addSubstance}>
                Add Substance
              </Button>
            </div>

            <Card className="match-card-body p-3">
              <Row>
                <Col xl={4}>
                  <h6>Tobacco Use:</h6>
                </Col>
                <Col xl={2}>
                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                    <div className="col-auto pr-0">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        // value={TobaccoUseTypeStatus.yes}
                        checked={formData?.tobaccoUseTypeDetails?.tobaccoUse === true}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                          const result = formData?.tobaccoUseTypeDetails?.tobaccoUse === true;
                          setFormData({
                            ...formData,
                            tobaccoUseTypeDetails: {
                              ...formData.tobaccoUseTypeDetails,
                              tobaccoUse: result ? null : true,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-8 p-0">
                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                        Yes
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                    <div className="col-auto pr-0">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="radio"
                        className="form-check-input"
                        // value={TobaccoUseTypeStatus.no}
                        checked={formData?.tobaccoUseTypeDetails?.tobaccoUse === false}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                          const result = formData?.tobaccoUseTypeDetails?.tobaccoUse == false;
                          setFormData({
                            ...formData,
                            tobaccoUseTypeDetails: {
                              ...formData.tobaccoUseTypeDetails,
                              tobaccoUse: result ? null : false,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-8 p-0">
                      <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                        No
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="col-6">
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="d-flex">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        If yes, type of tobacco:
                      </h6>
                      <div className="col-8">
                        <input
                          style={{
                            width: "100%",
                            // border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          disabled={!formData?.tobaccoUseTypeDetails?.tobaccoUse}
                          value={formData?.tobaccoUseTypeDetails?.tobaccoType}
                          onChange={e => {
                            setFormData({
                              ...formData,
                              tobaccoUseTypeDetails: {
                                ...formData.tobaccoUseTypeDetails,
                                tobaccoType: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="col-6">
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="d-flex">
                      <div className="col-4">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          How much?
                        </h6>
                      </div>
                      <div className="col-8">
                        <input
                          style={{
                            width: "100%",
                            // border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          disabled={!formData?.tobaccoUseTypeDetails?.tobaccoUse}
                          value={formData.tobaccoUseTypeDetails?.howMuch}
                          onChange={e => {
                            setFormData({
                              ...formData,
                              tobaccoUseTypeDetails: {
                                ...formData.tobaccoUseTypeDetails,
                                howMuch: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </Row>

              <Row>
                <div className="col">
                  <div className="row">
                    <div className="col-6">
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <div className="row">
                          <div className="d-flex">
                            <div className="col-4">
                              <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                How often:
                              </h6>
                            </div>
                            <div className="col-8">
                              <input
                                style={{
                                  width: "100%",
                                  // border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                  border: "1px dotted  #ccc",
                                  padding: "2px",
                                  outline: "none",
                                  borderRadius: "8px",
                                }}
                                type="text"
                                disabled={!formData?.tobaccoUseTypeDetails?.tobaccoUse}
                                value={formData?.tobaccoUseTypeDetails?.howOften}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    tobaccoUseTypeDetails: {
                                      ...formData.tobaccoUseTypeDetails,
                                      howOften: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div className="col-6">
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <div className="d-flex">
                          <div className="col-4">
                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                              How long:
                            </h6>
                          </div>
                          <div className="col-8">
                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                              <input
                                style={{
                                  width: "100%",
                                  // border: "none",
                                  marginTop: "0.1rem",
                                  backgroundColor: "unset",
                                  border: "1px dotted  #ccc",
                                  padding: "2px",
                                  outline: "none",
                                  borderRadius: "8px",
                                }}
                                type="text"
                                disabled={!formData?.tobaccoUseTypeDetails?.tobaccoUse}
                                value={formData?.tobaccoUseTypeDetails?.howLong}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    tobaccoUseTypeDetails: {
                                      ...formData.tobaccoUseTypeDetails,
                                      howLong: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </Card>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </Row>

              <Card className="match-card-body p-3">
                <Row>
                  <div className="col-6">
                    <div className="d-flex jus align-items-center gap-2">
                      <div className="col-6">
                        <h6>Interest in ending use:</h6>
                      </div>

                      <div className="col-4">
                        <div className="d-flex gap-3 justify-content-around">
                          <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                // value={TobaccoUseTypeStatus.yes}
                                checked={formData?.tobaccoUseTypeDetails?.interestEndUse === true}
                                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                  const result = formData?.tobaccoUseTypeDetails?.interestEndUse === true;
                                  setFormData({
                                    ...formData,
                                    tobaccoUseTypeDetails: {
                                      ...formData.tobaccoUseTypeDetails,
                                      interestEndUse: result ? null : true,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                Yes
                              </label>
                            </div>
                          </div>
                          <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                            <div className="col-auto pr-0">
                              <input
                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                type="radio"
                                className="form-check-input"
                                checked={formData?.tobaccoUseTypeDetails?.interestEndUse === false}
                                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                  const result = formData?.tobaccoUseTypeDetails?.interestEndUse === false;
                                  setFormData({
                                    ...formData,
                                    tobaccoUseTypeDetails: {
                                      ...formData.tobaccoUseTypeDetails,
                                      interestEndUse: result ? null : false,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="col-8 p-0">
                              <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex gap-3 align-items-center">
                      <h6>Interest in reducing use:</h6>
                      <div className="d-flex gap-3 justify-content-around">
                        <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                          <div className="col-auto pr-0">
                            <input
                              style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              // value={TobaccoUseTypeStatus.yes}
                              checked={formData?.tobaccoUseTypeDetails?.interestReducingUse === true}
                              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                const result = formData?.tobaccoUseTypeDetails?.interestReducingUse === true;
                                setFormData({
                                  ...formData,
                                  tobaccoUseTypeDetails: {
                                    ...formData.tobaccoUseTypeDetails,
                                    interestReducingUse: result ? null : true,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="col-8 p-0">
                            <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                              Yes
                            </label>
                          </div>
                        </div>
                        <div className=" d-flex" style={{ marginBottom: "0.5rem" }}>
                          <div className="col-auto pr-0">
                            <input
                              style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              // value={TobaccoUseTypeStatus.no}
                              checked={formData?.tobaccoUseTypeDetails?.interestReducingUse === false}
                              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                const result = formData?.tobaccoUseTypeDetails?.interestReducingUse === false;
                                setFormData({
                                  ...formData,
                                  tobaccoUseTypeDetails: {
                                    ...formData.tobaccoUseTypeDetails,
                                    interestReducingUse: result ? null : false,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="col-8 p-0">
                            <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="col-4">
                          <h6>Ending use:</h6>
                        </div>
                        <div className="col-8">
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <input
                              style={{
                                width: "100%",
                                // border: "none",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                                border: "1px dotted  #ccc",
                                padding: "2px",
                                outline: "none",
                                borderRadius: "8px",
                              }}
                              type="text"
                              disabled={false}
                              value={formData.tobaccoUseTypeDetails?.endingUse || ""}
                              onChange={e => {
                                setFormData({
                                  ...formData,
                                  tobaccoUseTypeDetails: {
                                    ...formData.tobaccoUseTypeDetails,
                                    endingUse: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Card>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="d-flex">
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                          What was your longest period of abstinence?
                        </label>

                        <input
                          style={{
                            width: "100%",
                            // border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          disabled={false}
                          value={formData.tobaccoUseTypeDetails?.longestAbstinence || ""}
                          onChange={e => {
                            setFormData({
                              ...formData,
                              tobaccoUseTypeDetails: {
                                ...formData.tobaccoUseTypeDetails,
                                longestAbstinence: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="d-flex">
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", flexGrow: 1 }}>
                          When was it?
                        </label>

                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", flexGrow: 1 }}>
                          <input
                            style={{
                              width: "100%",
                              // border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={false}
                            value={formData.tobaccoUseTypeDetails?.whenWas || ""}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                tobaccoUseTypeDetails: {
                                  ...formData.tobaccoUseTypeDetails,
                                  whenWas: e.target.value,
                                },
                              });
                            }}
                          />
                        </Card>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="d-flex">
                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", flexGrow: 1 }}>
                          What caused your relapse?
                        </label>

                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", flexGrow: 1 }}>
                          <input
                            style={{
                              width: "100%",
                              // border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={false}
                            value={formData.tobaccoUseTypeDetails?.relapse || ""}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                tobaccoUseTypeDetails: {
                                  ...formData.tobaccoUseTypeDetails,
                                  relapse: e.target.value,
                                },
                              });
                            }}
                          />
                        </Card>
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                    <h6 className="fw-600" style={{ margin: "0.2rem" }}>
                      Comments:
                    </h6>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        data={tobaccoComment || ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   tobaccoUseTypeDetails: {
                          //     ...formData.tobaccoUseTypeDetails,
                          //     comment: data,
                          //   },
                          // });
                          setTobaccoComment(data);
                        }}
                      />
                    </div>
                    {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, precipitatingProblem: e.target.value } })}
                                /> */}
                  </Card>
                </Row>

                <Row>
                  <h6>Have you experienced any of the following symptoms after stopping your use of alcohol or other drugs?</h6>
                  <div className="col-4">
                    <div className="row d-flex " style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.agitationRestlessness}
                          checked={
                            formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.agitationRestlessness === ExperiencedSymptoms.agitationRestlessness || false
                          }
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.agitationRestlessness === ExperiencedSymptoms.agitationRestlessness;
                            updateExperiencedSymptoms("agitationRestlessness", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Agitation/Restlessness
                          <br />
                          /Irritability
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.confusion}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.confusion === ExperiencedSymptoms.confusion || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.confusion === ExperiencedSymptoms.confusion;
                            updateExperiencedSymptoms("confusion", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Confusion
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.nauseaVomiting}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.nauseaVomiting === ExperiencedSymptoms.nauseaVomiting || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.nauseaVomiting === ExperiencedSymptoms.nauseaVomiting;
                            updateExperiencedSymptoms("nauseaVomiting", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Nausea/Vomiting
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.muscleTwitching}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.muscleTwitching === ExperiencedSymptoms.muscleTwitching || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.muscleTwitching === ExperiencedSymptoms.muscleTwitching;
                            updateExperiencedSymptoms("muscleTwitching", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Muscle Twitching
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.chills}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.chills === ExperiencedSymptoms.chills || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.chills === ExperiencedSymptoms.chills;
                            updateExperiencedSymptoms("chills", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Chills
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.deliriumTremens}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.deliriumTremens === ExperiencedSymptoms.deliriumTremens || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.deliriumTremens === ExperiencedSymptoms.deliriumTremens;
                            updateExperiencedSymptoms("deliriumTremens", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Delirium Tremens
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.tremorsShaking}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tremorsShaking === ExperiencedSymptoms.tremorsShaking || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tremorsShaking === ExperiencedSymptoms.tremorsShaking;
                            updateExperiencedSymptoms("tremorsShaking", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Tremors/Shaking
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.anxiety}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.anxiety === ExperiencedSymptoms.anxiety || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.anxiety === ExperiencedSymptoms.anxiety;
                            updateExperiencedSymptoms("anxiety", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Anxiety
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.racingPulse}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.racingPulse === ExperiencedSymptoms.racingPulse || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.racingPulse === ExperiencedSymptoms.racingPulse;
                            updateExperiencedSymptoms("racingPulse", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Racing Pulse
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.rapidBreathing}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.rapidBreathing === ExperiencedSymptoms.rapidBreathing || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.rapidBreathing === ExperiencedSymptoms.rapidBreathing;
                            updateExperiencedSymptoms("rapidBreathing", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Rapid Breathing
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.sweats}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.sweats === ExperiencedSymptoms.sweats || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.sweats === ExperiencedSymptoms.sweats;
                            updateExperiencedSymptoms("sweats", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Sweats
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.hallucinations}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.hallucinations === ExperiencedSymptoms.hallucinations || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.hallucinations === ExperiencedSymptoms.hallucinations;
                            updateExperiencedSymptoms("hallucinations", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Hallucinations
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.backJointPain}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.backJointPain === ExperiencedSymptoms.backJointPain || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.backJointPain === ExperiencedSymptoms.backJointPain;
                            updateExperiencedSymptoms("backJointPain", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Back/Joint Pain
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.other}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.other === ExperiencedSymptoms.other || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.other === ExperiencedSymptoms.other;
                            updateExperiencedSymptoms("other", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Other:
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.difficultySleeping}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.difficultySleeping === ExperiencedSymptoms.difficultySleeping || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.difficultySleeping === ExperiencedSymptoms.difficultySleeping;
                            updateExperiencedSymptoms("difficultySleeping", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Difficulty Sleeping
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.runnyNose}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.runnyNose === ExperiencedSymptoms.runnyNose || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.runnyNose === ExperiencedSymptoms.runnyNose;
                            updateExperiencedSymptoms("runnyNose", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Runny Nose
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.tearyEyes}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tearyEyes === ExperiencedSymptoms.tearyEyes || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.tearyEyes === ExperiencedSymptoms.tearyEyes;
                            updateExperiencedSymptoms("tearyEyes", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Teary Eyes
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.craving}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.craving === ExperiencedSymptoms.craving || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.craving === ExperiencedSymptoms.craving;
                            updateExperiencedSymptoms("craving", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Craving
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.cramps}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.cramps === ExperiencedSymptoms.cramps || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.cramps === ExperiencedSymptoms.cramps;
                            updateExperiencedSymptoms("cramps", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Cramps
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.seizures}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.seizures === ExperiencedSymptoms.seizures || false}
                          onClick={() => {
                            const result= formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.seizures === ExperiencedSymptoms.seizures;
                            updateExperiencedSymptoms("seizures", !result);
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          Seizures
                        </label>
                      </div>
                    </div>
                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                      <div className="col-auto pr-0">
                        <input
                          style={{ fontSize: "larger", marginRight: "0.5rem" }}
                          type="checkbox"
                          className="form-check-input"
                          value={ExperiencedSymptoms.none}
                          checked={formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.none === ExperiencedSymptoms.none || false}
                          onClick={() => {
                            setFormData({
                              ...formData,
                              tobaccoUseTypeDetails: {
                                ...formData.tobaccoUseTypeDetails,
                                experiencedSymptoms: {
                                  ...formData?.tobaccoUseTypeDetails?.experiencedSymptoms,
                                  agitationRestlessness:"",
                                  confusion:"",
                                  nauseaVomiting:"",
                                  muscleTwitching:"",
                                  chills:"",
                                  deliriumTremens:"",
                                  tremorsShaking:"",
                                  anxiety:"",
                                  racingPulse:"",
                                  rapidBreathing:"",
                                  sweats:"",
                                  hallucinations:"",
                                  backJointPain:"",
                                  other:"",
                                  difficultySleeping:"",
                                  runnyNose:"",
                                  tearyEyes:"",
                                  craving:"",
                                  cramps:"",
                                  seizures:"",
                                  none: formData?.tobaccoUseTypeDetails?.experiencedSymptoms?.none === ExperiencedSymptoms.none ? "" : ExperiencedSymptoms.none
                                },
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>
                          None
                        </label>
                      </div>
                    </div>
                  </div>
                </Row>
              </Card>
            </Card>
          </div>
        </Row>
        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 4 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormFour;
