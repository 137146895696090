import React, { useContext, useState, useEffect } from "react";
import { Card, CardTitle, Col, Container, Row } from "reactstrap";
import UserContext from "../../context/UserContext";
import user1 from "../../assets/images/default_profile.png";
import profileBackground from "../../assets/images/default_cover.png";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import Modal1 from "../Popup/Modal1";
import Modal2 from "../Popup/Modal2";
import { ClientService } from "../../services/ClientService";
import { Upload } from "../../models/Upload";
import { environment } from "../../environment/environment";
import { Role } from "../../models/Role";
import { Client } from "../../models/Client";
import { Util } from "../../Util";

const ClientProfileDetails: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [isOpenMessage, setIsOpenMessage] = React.useState(false);
  const [isOpenSuccessMessage, setIsOpenSuccessMessage] = React.useState(false);
  const [isOpenAllReview, showAllReviews] = React.useState(false);
  const [isOpenAllProfile, showAllProfile] = React.useState(true);
  const [modal_center, setModalCenter] = React.useState(false);
  const [modalcentervideo, setModalCenterVideo] = React.useState(false);
  const { userProfileId } = useParams<ParameterTypes>();
  const [userProfileData, setUserProfileData] = useState<Client>({} as Client);
  const [loadingProfileData, setLoadingProfileData] = useState(false);

  useEffect(() => {
    setLoadingProfileData(true);

    getClientDetails();
  }, []);

  const getClientDetails = () => {
    if(user?.role == Role.THERAPIST){
      ClientService.getSingleClientByClientId(userProfileId).then(res => {
        setUserProfileData(res.data);
  
        setLoadingProfileData(false);
      });
    }else{
      ClientService.getClientByClientId(userProfileId).then(res => {
        setUserProfileData(res.data);
  
        setLoadingProfileData(false);
      });
    }
    
  };

  function togCenterReview() {
    setModalCenter(!modal_center);
  }

  function togCenterVideo() {
    setModalCenterVideo(!modalcentervideo);
  }

  function tog_center() {
    setIsOpenMessage(!isOpenMessage);
  }

  const sendMessage = () => {
    setIsOpenMessage(!isOpenMessage);
    setIsOpenSuccessMessage(!isOpenSuccessMessage);
  };

  const showReviews = () => {
    showAllProfile(!isOpenAllProfile);
    showAllReviews(!isOpenAllReview);
  };

  const back = () => {
    history.back();
  };

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {user?.role == "CLIENT" && (
            <div className="flex flex-wrap  ">
              {isOpenAllReview == true ? (
                <div className="flex flex-wrap  cursor-pointer" onClick={() => showReviews()}>
                  <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                  <CardTitle className="mb-4 cursor_pointer">&nbsp; Back to profile</CardTitle>
                </div>
              ) : null}
            </div>
          )}

          {!isOpenAllReview ? (
            <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
              <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
              <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
            </div>
          ) : null}

          <Breadcrumbs title="Lavni" breadcrumbItem="Client Details" />

          <Row>
            <Col className="col-xl-10 offset-xl-1">
              {userProfileData && !loadingProfileData ? (
                <Card style={{ minHeight: "67vh" }} className="BorderRadiusLeftRight">
                  <div className="card-bodyDiv">
                    <Row>
                      <Col xl="12">
                        <Row>
                          <div
                            className="profileImage imageFit BorderRadiusLeftRight"
                            style={{
                              backgroundImage:
                                userProfileData?.coverPhotoId == null || userProfileData?.coverPhotoId == undefined
                                  ? `url(${profileBackground})`
                                  : `url("${Util.fileURL(userProfileData?.coverPhotoId?._id)}")`,
                            }}
                          ></div>

                          <Row>
                            <Col xl={6}>
                              <div
                                className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl profile-user-wid-pro items-center margin_center mb-4 profileImageShow"
                                style={{
                                  backgroundImage:
                                    userProfileData?.photoId == null || userProfileData?.photoId == undefined
                                      ? `url(${user1})`
                                      : `url("${Util.fileURL(userProfileData?.photoId?._id)}")`,
                                  borderRadius: "10px",
                                  position: "relative",
                                }}
                              ></div>
                            </Col>
                          </Row>
                        </Row>
                        <Row className=" pl-5 pr-5 mb-4">
                          {isOpenAllProfile && (
                            <Col xl="12">
                              <Row>
                                {user?.role == "THERAPIST" && (
                                  <Col xl={12}>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-17 profileFont mb-3">About Them</h5>
                                    </div>

                                    <div className="card-heading mb-2 pr-5">
                                    
                                      <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userProfileData?.description || "-" }} />
                                    </div>
                                  </Col>
                                )}
                              </Row>

                              <Row>
                                <Col xl="12 mt50">
                                  <hr />
                                </Col>
                                <Col xl="6"></Col>
                                <Col xl="6" className="text-right"></Col>
                              </Row>

                              <Row>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-4">Personal Information</h5>
                                </div>

                                <Row>
                                  <Col xl="4">
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Name</h5>
                                    </div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 mb-3">
                                        {userProfileData?.firstname || "-"} {userProfileData?.lastname}
                                      </h5>
                                    </div>
                                  </Col>

                                  <Col xl="4">
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                    </div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 mb-3">{userProfileData?.gender || "-"}</h5>
                                    </div>
                                  </Col>

                                  <Col xl="4">
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Age</h5>
                                    </div>
                                    <div className="card-heading mb-2">
                                      {userProfileData?.dateOfBirth ? (
                                        <h5 className="font-size-14 mb-2">{moment().diff(moment(userProfileData?.dateOfBirth), "years")} years</h5>
                                      ) : (
                                        <h5 className="font-size-14 mb-2">{!userProfileData?.dateOfBirth && "-"}</h5>
                                      )}
                                    </div>
                                  </Col>

                                  <Col xl="4">
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Ethnicity</h5>
                                    </div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 mb-3">{userProfileData?.ethnicityId?.ethnicity || "-"}</h5>
                                    </div>
                                  </Col>

                                  <Col xl="4">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">User Name</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{userProfileData?.username || "-"}</h5>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xl="4">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Telephone Number</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{userProfileData?.primaryPhone || "-"}</h5>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                {user?.role == Role.CLIENT && (
                                  <Row>
                                    <Col xl="12">
                                      <Row>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-4">Credentialing Information </h5>
                                        </div>
                                      </Row>

                                      <Row>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Social Security</h5>
                                          </div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{userProfileData?.socialSecurity || "-"}</h5>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">CAQH</h5>
                                          </div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{userProfileData?.cAQH || "-"}</h5>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">NPI 1</h5>
                                          </div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{userProfileData?.nPI1 || "-"}</h5>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">NPI 2</h5>
                                          </div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{environment.NPI2}</h5>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Tax Identification </h5>
                                          </div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{userProfileData?.taxIdentification || "-"}</h5>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">License </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.license || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Original Issue Date </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              {userProfileData?.issueDate ? (
                                                <h5 className="font-size-14 mb-2">{moment.utc(userProfileData?.issueDate || null).format("YYYY-MM-DD")}</h5>
                                              ) : (
                                                <h5 className="font-size-14 mb-3">{!userProfileData?.issueDate && "-"}</h5>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Expiration Date </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              {userProfileData?.expirationDate ? (
                                                <h5 className="font-size-14 mb-2">{moment.utc(userProfileData?.expirationDate || null).format("YYYY-MM-DD")}</h5>
                                              ) : (
                                                <h5 className="font-size-14 mb-3">{!userProfileData?.expirationDate && "-"}</h5>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Graduate School Name </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.schoolName || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Date of Graduation </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{!userProfileData?.dateOfGraduation && "-"}</h5>
                                              {userProfileData?.dateOfGraduation && (
                                                <h5 className="font-size-14 mb-2">{moment.utc(userProfileData?.dateOfGraduation || null).format("YYYY-MM-DD")}</h5>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">School’s Street Address </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.schoolStreetAddress || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">School’s City </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.schoolCity || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">School’s State </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.schoolState || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">School’s Zip Code </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.schoolZipCode || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Taxonomy Code </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.taxonomyCode || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Malpractice Policy </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{userProfileData?.malpracticePolicy || "-"}</h5>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-1">
                                              <h5 className="font-size-14 profileFont mb-1">Malpractice Expiration Date </h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              {userProfileData?.malpracticeExpirationDate ? (
                                                <h5 className="font-size-14 mb-3">
                                                  {moment.utc(userProfileData?.malpracticeExpirationDate || null).format("YYYY-MM-DD")}
                                                </h5>
                                              ) : (
                                                <h5 className="font-size-14 mb-3">{!userProfileData?.malpracticeExpirationDate && "-"}</h5>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                )}
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </Col>

                      <Modal1
                        trigger={isOpenMessage}
                        setTrigger={setIsOpenMessage}
                        header={"You can write your message to the therapist below"}
                        primaryBtn={"Apply"}
                        secondaryBtn={"Cancel"}
                        setOnchange={() => sendMessage()}
                      />

                      <Modal2 trigger={isOpenSuccessMessage} setTrigger={setIsOpenSuccessMessage} header={"Your message has been successfully sent!"} />
                    </Row>
                  </div>
                </Card>
              ) : (
                <>
                  {loadingProfileData ? (
                    <div className="no-therapist">Loading...</div>
                  ) : (
                    <div className="no-therapist">No therapist is found with given id.</div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClientProfileDetails;
