import * as React from "react";
import { CallInitializeData } from "../models/CallInitializeData";

const initialData: CallInitializeData = {
  start: false,
  isInstantMeeting: false,
  isAppointmentBased: false,
  isTranscribeAllowed: false,
  appointmentId: "",
  recieverId: "",
  meetingTime: 30,
  meetingId: "",
  isAudioCall: false,
  transcribeAllowedForLoggedUser: false,
  isGroupCall: false,
  groupCallId: ""
};

type LocalCallInitialzeData = [
  localUserOnCall: boolean,
  setLocalUserOnCall: (value: boolean) => void,
  callInitializeData: CallInitializeData,
  setCallInitializeData: (initializeData: CallInitializeData) => void
];

const CallInitializationContext = React.createContext<LocalCallInitialzeData>([false, () => ({}), initialData, () => ({})]);

export default CallInitializationContext;
