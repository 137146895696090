import React from "react";

interface TagProps {
  label: string;
  id: number;
  removeTag: any;
}

export const Tags: React.FC<TagProps> = ({ label, id, removeTag, }: TagProps) => {
  return (
    <span className="tag" key={id}>
      {label}&nbsp;
      <span>
        <i className="fas fa-times-circle  cursor_pointer" style={{ borderColor: "#414141" }} onClick={() => removeTag(label)}></i>
      </span>
    </span>
  );
};
