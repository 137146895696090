import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import CoverImage from 'src/assets/images/end-meeting.png'
import Animation from 'src/assets/images/end-meeting-animation.gif'
import CorrectMark from 'src/assets/images/right mark.png'
import styles from './styles';
import SessionFeedbackModal from 'src/pages/Popup/SessionFeedBackModal';
import UserContext from "src/context/UserContext";
import { toast } from "react-toastify";

export default function EndCall() {
  const history = useHistory();
  const [recordings, setRecordings] = useState(null);
  const classes = styles();
  const [loggedUser] = useContext(UserContext);

  const removeCallContextFromlocalStorage = () => {
    console.log("XXX");
    localStorage.removeItem('callContext');
  }

  useEffect(() => {
    removeCallContextFromlocalStorage();
    }, []);

  const closeTab = () => {
    window.close();
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.meetingInfo}>
          <div className={classes.infoContainer}>
            <div className={classes.meetingInfoNew}>
              <img src={CoverImage} className={classes.coverImg} />
              <div className={classes.overlay}>
                <img src={Animation} />
              </div>
              <div className={classes.overlay}>
                <img src={CorrectMark} className={classes.correctImg} />
              </div>
            </div>
            <p className={classes.overlayText}>Meeting Successful</p>
            <p className={classes.overlayText2}>Thank you for taking this journey with us for your personal growth and well-being.</p>
            <div className={classes.btnContainer}>
              <button
                className="btn btn-primary mb-4"
                onClick={closeTab}
              >
                Close The Tab
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
