import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { SaasTherapistService } from "src/services/SaasTherapistService";
import { SaasTherapistTask } from "src/models/SaasTherapistTask";
import Spinner from "src/common/spinner/spinner";
import Swal from "sweetalert2";
import "../css/ClientDetails.css";
import moment from "moment";

interface MyTasksProps {
  clientId: string;
}

const MyTasks: React.FC<MyTasksProps> = () => {
  const [myTasks, setMyTasks] = useState<SaasTherapistTask[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskDetail, setNewTaskDetail] = useState("");
  const [newTaskStatus, setNewTaskStatus] = useState("Pending");
  const [editTaskId, setEditTaskId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterMode, setFilterMode] = useState("Pending");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [filteredMyTasks, setFilteredMyTasks] = useState<SaasTherapistTask[]>([]);

  useEffect(() => {
    fetchTaskDetails();
  }, [page]);

  useEffect(() => {
    filterTasks();
  }, [filterMode]);

  const fetchTaskDetails = async () => {
    try {
      setIsLoading(true);
      const res = await SaasTherapistService.getTaskDetails(limit, offset);
      if (res && res.success) {
        const fetchedTasks = res?.data;
        setMyTasks((prevTasks) => (page === 1 ? fetchedTasks : [...prevTasks, ...fetchedTasks]));
        setHasMore(fetchedTasks.length === limit);
        setFilterMode("All");
        setIsLoading(false);
      } else {
        setFilterMode("All");
        setIsLoading(false);
        toast.error("An error occurred while loading tasks.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(`An error occurred while loading tasks. Error: ${error}.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const filterTasks = () => {
    if (filterMode === "All") {
      setFilteredMyTasks(myTasks);
    } else {
      const filteredTasks = myTasks.filter((task) => task.status === filterMode);
      setFilteredMyTasks(filteredTasks);
    }
  }

  const filterTasks2 = (data: SaasTherapistTask[]) => {
    if (filterMode === "All") {
      setFilteredMyTasks(data);
    } else {
      const filteredTasks = data.filter((task) => task.status === filterMode);
      setFilteredMyTasks(filteredTasks);
    }
  }

  const saveTask = async () => {
    if (newTaskName.trim() === "") {
      toast.error("Task name cannot be empty.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }

    if (newTaskDetail.trim() === "") {
      toast.error("Task description cannot be empty.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }

    const newTask: SaasTherapistTask = {
      taskTitle: newTaskName,
      taskDetail: newTaskDetail,
      status: newTaskStatus,
    };

    try {
      if (editTaskId === "") {
        const res = await SaasTherapistService.createTask(newTask);
        if (res && res.success) {
          const data = [...myTasks, res.data];
          setMyTasks(data);
          filterTasks2(data);
          toast.success("Task created successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("An error occurred while saving the task.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        const data: SaasTherapistTask = {
          _id: editTaskId,
          taskTitle: newTaskName,
          taskDetail: newTaskDetail,
        };
        const res = await SaasTherapistService.updateTaskById(data);
        if (res && res.success) {
          const data = myTasks.map((task) => {
            if (task._id === res?.data._id) {
              return {
                ...task,
                taskTitle: res.data.taskTitle,
                taskDetail: res.data.taskDetail,
              };
            }
            return task;

          });
          setMyTasks(data);
          filterTasks2(data);
          toast.success("Task updated successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("An error occurred while updating the task.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      toast.error(`An error occurred while processing the task. Error: ${error}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    setNewTaskName("");
    setNewTaskDetail("");
    setNewTaskStatus("Pending")
    setEditTaskId("");
    toggleModal();
  };

  const editTask = (task: SaasTherapistTask) => {
    setEditTaskId(task._id || "");
    setNewTaskName(task.taskTitle);
    setNewTaskDetail(task.taskDetail || "");
    setNewTaskStatus(task.status || "Pending");
    toggleModal();
  };

  const updateTaskStatus = (task: SaasTherapistTask) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to make this task as completed?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedStatus = task.status === "Pending" ? "Completed" : "Pending";
        setMyTasks((prevTasks) =>
          prevTasks.map((t) =>
            t._id === task._id ? { ...t, status: updatedStatus } : t
          )
        );
  
        const updatedTask = { ...task, status: updatedStatus };
        try {
          const res = await SaasTherapistService.updateTaskById(updatedTask);
          if (res && res.success) {
            const data = myTasks.map((task) => {
              if (task._id === res?.data._id) {
                return {
                  ...task,
                  status: res.data.status,
                };
              }
              return task;
            });
            setMyTasks(data);
            filterTasks2(data);
            toast.success("Task status updated successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            setMyTasks((prevTasks) =>
              prevTasks.map((t) =>
                t._id === task._id ? { ...t, status: task.status } : t
              )
            );
            toast.error("An error occurred while updating the task status.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } catch (error) {
          setMyTasks((prevTasks) =>
            prevTasks.map((t) =>
              t._id === task._id ? { ...t, status: task.status } : t
            )
          );
          toast.error(`An error occurred while updating the task status. Error: ${error}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    });
  };  

  const handleTaskDelete = (taskId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete this task?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await SaasTherapistService.deleteTaskById(taskId);
          if (res && res.success) {
            const filteredData = myTasks.filter((task) => task._id !== taskId);
            setMyTasks(filteredData);
            filterTasks2(filteredData);
            toast.success("Task deleted successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("An error occurred while deleting the task.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } catch (error) {
          toast.error(`An error occurred while deleting the task. Error: ${error}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    });
  };

  const loadMoreTasks = () => {
    setPage((prev) => prev + 1);
    setOffset((prev) => prev + limit);
  };

  const renderMyTasks = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (filteredMyTasks.length === 0) {
      return <div className="text-center text-muted p-3">No tasks available</div>;
    }

    return filteredMyTasks.map((task) => (
      <Card key={task._id} className="mb-2 p-2" style={{ backgroundColor: "#f4f4f5" }}>
        <FormGroup className="task-container">
          <div className="task-details">
            <Label check className="clinical-note-name">{task.taskTitle}</Label><br />
            <small className="text-muted mb-3">Created date: {moment.utc(task.createdAt).format("YYYY-MM-DD HH:mm:ss A")}</small>
            <p className="text-muted task-description mt-2">{task.taskDetail}</p>
          </div>
          <div className="task-buttons">
            <Button
              size="sm"
              onClick={() => updateTaskStatus(task)}
              style={{ backgroundColor: task.status === "Completed" ? "green" : "" }}
              disabled={task.status === "Completed"}
            >
              {task.status === "Completed" ? "Completed" : "Mark as Completed"}
            </Button>
            <Button
              size="sm"
              color="warning"
              onClick={() => editTask(task)}
            >
              <EditIcon fontSize="small" />
            </Button>
            <Button
              size="sm"
              color="danger"
              onClick={() => handleTaskDelete(task._id || "")}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </div>
        </FormGroup>
      </Card>
    ));
  };

  return (
    <div className="clinical-notes-container">
      <div className="clinical-notes-header-container d-flex justify-content-between align-items-center">
        <Card className="mb-4 w-100">
          <div className="d-flex justify-content-between">
            <h4 className="mb-3 fs-5 bold">My Tasks</h4>
            <div className="d-flex align-items-center ">
              <Dropdown className={"mb-3"} isOpen={dropdownOpen} toggle={toggleDropdown} direction={"down"}>
                <DropdownToggle className={"select-filter-option"} color={"#f27e0c"}>
                  <i className="bx bx-filter-alt me-2" style={{ color: "#f27e0c" }}></i>
                  Filter:{filterMode}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setFilterMode("All")}>All</DropdownItem>
                  <DropdownItem onClick={() => setFilterMode("Pending")}>Pending</DropdownItem>
                  <DropdownItem onClick={() => setFilterMode("Completed")}>Completed</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button className="add-task-button mb-3" color="success" onClick={toggleModal}>
                Add Task
              </Button>
            </div>

          </div>
          {renderMyTasks()}

          {hasMore && (
            <div className="text-center">
              <Button className="btn btn-warning text-center mt-4" onClick={loadMoreTasks}>
                See More
              </Button>
            </div>
          )}
        </Card>
      </div>

      <Modal isOpen={modalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>{editTaskId ? "Edit Task" : "Add New Task"}</ModalHeader>
        <ModalBody>
          <Input type="text" placeholder="Task name" value={newTaskName} onChange={e => setNewTaskName(e.target.value)}
            className="mb-3" />
          <Input type="textarea" placeholder="Task description" value={newTaskDetail}
            onChange={e => setNewTaskDetail(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveTask}>
            {editTaskId ? "Update" : "Save"}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MyTasks;
