import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import "./../../assets/css/onboarding.css";
import "./../../assets/css/publicMedia.css";
import CoverImage from "./../../assets/images/icons/sharing box redraw.png";
import ReferralBonusIcon from "./../../assets/images/icons/fi_referral_bonus.svg";
import ReferralCompletionBonusIcon from "./../../assets/images/icons/referral_completed_bonus.svg";
import ListIcon from "./../../assets/images/icons/referal_nav.png";
import Close from "./../../assets/images/icons/fi_x-circle.png";
import Cover from "./../../assets/images/icons/modal cover.svg";
import RewardEarningsIcon from "./../../assets/images/icons/reward_earnings.svg";
import Coppy from "./../../assets/images/icons/fi_copy.png";
import Share from "./../../assets/images/icons/fi_share-2.png";
import Email from "./../../assets/images/icons/fi_mail.png";
import Message from "./../../assets/images/icons/message.png";
import { Input, Modal } from "reactstrap";
import { environment } from "src/environment/environment";
import PhoneInput from "react-phone-number-input";
import UserContext from "src/context/UserContext";
import TextArea from "antd/lib/input/TextArea";
import { ClientService } from "src/services/ClientService";
import { ClientReward } from "src/models/ClientReward";
import { ReferralService } from "src/services/ReferralService";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Spinner from "src/common/spinner/spinner";

const Main: React.FC = () => {
  const [togleModalReferal, setTogleModalReferal] = useState(false);
  const [toggleModalRewards, setToggleModalRewards] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [rewards, setRewards] = useState([] as ClientReward[]);
  const [rewardCount, setRewardCount] = useState(10);
  const [offset2, setOffset2] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [referralClientId, setReferralClientId] = useState(user?._id);
  const [messageContent,setMessageContent] = useState('')

  useEffect(() => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-client-share/" + referralClientId;
    setUrl(userPublicUrl);
    const smsContent = `Hey There!\n\nHope you're doing well. This is ${user?.firstname} ${user?.lastname} I wanted to share something valuable with you I've recently started using Lavni, a fantastic mental health platform for connect with a licensed therapist. \n\nThey're offering a special $10 bonus for new joiners, and I thought you might be interested! It's a great platform for running solo or group therapy practices, and it's completely free. \n\nClick this link to get started:\n\n ${userPublicUrl}.\n\nTake care!`;
    setMessageContent(smsContent);
    rewardsCheck();
  }, []);

  const referralModal = () => {
    setTogleModalReferal(!togleModalReferal);
  };

  const rewardsModal = () => {
    setToggleModalRewards(!toggleModalRewards);
  };

  const rewardsCheck = () => {
    setIsLoading(true);
    ReferralService.getSingleClientRewards().then(res => {
      if (res.success) {
        setRewards(res.data);
        setRewardCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const seeMoreRewards = () => {
    const updatedSkip2 = offset2 + 1;

    setOffset2(updatedSkip2);
    setIsLoading(true);
    ReferralService.getSingleClientRewards().then(res => {
      if (res.success) {
        res.data.map((data: any) => {
          setRewards(rewards => [...rewards, data]);
        });
        setRewardCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const handleCopyURL = () => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-client-share/" + referralClientId;
    navigator.clipboard
      .writeText(userPublicUrl)
      .then(() => {
        toast.success("Link copied to clipboard! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      })
      .catch(() => {
        toast.error("Link is not copied try again! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
  };

  const handleShare = () => {
    setShowSharePopup(!showSharePopup);
  };

  const sendSMS = () => {
    const data = { messageContent, phoneNumber };
    const phoneno = /^\+1[0-9]{10}$/;

    if (!messageContent) {
      toast.error(`Please enter your message!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      if (!phoneNumber.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        ClientService.sendReferralLinkViaSms(data).then(res => {
          if (res.success) {
            toast.success(`You have sent SMS successfully!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setPhoneNumber("");
            setShowSharePopup(!showSharePopup);
          } else {
            toast.error(` SMS sending failed!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setPhoneNumber("");
          }
        });
      }
    }
  };

  const sendEmail = () => {
    const data = { messageContent, emailAddress };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!messageContent) {
      toast.error(`Please enter your message!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      if (!emailAddress.match(emailRegex)) {
        toast.error("Please enter valid Email Address", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        ClientService.sendReferralLinkViaEmail(data).then(res => {
          if (res.success) {
            toast.success(`You have sent email successfully!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setEmailAddress("");
            setShowSharePopup(!showSharePopup);
          } else {
            toast.error(` Email sending failed!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setEmailAddress("");
          }
        });
      }
    }
  };
  return (
    <div>
      <div className="content-wrapper mt-refferal-page">
        <section className="bg-refferal ">
          <div className="container ">
            <h1 className="refferal-title text-center text-white-referral pt-4 pt-md-12 mt-referral-title">Welcome to the Lavni Referral Rewards Program!</h1>
            <p className="text-center text-white-referral fs-15">
              We value the support and trust of our clients, and we want to reward you for helping us grow our community. By referring friends, family, or colleagues to Lavni, you and the person you refer can each earn $10 when they complete their fourth session.
            </p>
            <div className="d-flex align items-center flex-column">
              <button className="btn btn-primary mt-4 referral-btn-d fs-18" onClick={referralModal}>
                Send a referral <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
              <button className="btn btn-sm btn-primary mt-4 fs-18" onClick={rewardsModal}>
                Check Your Reward Earnings <br /><i className="bx bx-down-arrow-alt ms-2"></i>
              </button>
              <img src={CoverImage} className="max-100-referral cover-img-referral" />
            </div>

            <h1 className="text-center text-white-referral mb-5">How It Works</h1>

            <div className="row mb-8 mb-md-12">
              <h4 className="text-low-referral text-center">Share Your Unique Referral Link</h4>
              <div className="col-md-4 d-flex flex-column align-items-md-start align-items-center mt-4">
                <p className="text-white-referral text-center fs-20 mt-2">Step 1:</p>
                <p className="text-white-referral text-md-start text-center fs-15">
                  Log in to your Lavni account.
                </p>
              </div>
              <div className="col-md-4  d-flex flex-column align-items-md-start align-items-center mt-4">
                <p className="text-white-referral text-center fs-20 mt-2">Step 2:</p>
                <p className="text-white-referral text-md-start text-center fs-15">
                  Find your unique referral link under the “Rewards” section.
                </p>
              </div>
              <div className="col-md-4  d-flex flex-column align-items-md-start align-items-center mt-4">
                <p className="text-white-referral text-center fs-20 mt-2">Step 3:</p>
                <p className="text-white-referral text-md-start text-center fs-15">
                  Share this link with your friends, family, or anyone who might benefit from Lavni’s services.
                </p>
              </div>
            </div>

            <div className="row mb-8 mb-md-12">
              <h4 className="text-low-referral text-center">New Client Signs Up</h4>
              <div className="d-flex flex-column align-items-center mt-4">
                <p className="text-white-referral text-center fs-15">
                  The referred person uses your unique referral link when they sign up for Lavni.
                </p>
              </div>
            </div>

            <div className="row mb-8 mb-md-12">
              <h4 className="text-low-referral text-center">Complete the Fourth Session</h4>
              <div className="d-flex flex-column align-items-center mt-4">
                <p className="text-white-referral text-center fs-15">
                  Once the referred person completes their fourth therapy session, both of you will receive a $10 reward.
                </p>
              </div>
            </div>

            <br/>
          </div>
        </section>

        <section className="">
          <div className="container pt-4 pt-md-12">
            <h1 className="text-black-referral text-center mb-3">Program Details</h1>
            <div className=" mt-4">
              <div className="row justify-content-center">

                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <img src={ReferralBonusIcon} alt="Eligibility Icon" />
                    <h4 className="text-white-referral text-center mt-3 mb-1">Eligibility</h4>
                    <p className="text-white-referral text-center fs-15">
                      Both existing clients and new clients must have active Lavni accounts to participate in the program.
                    </p>
                    <p className="text-white-referral text-center fs-15">
                      The referred client must use the unique referral link during the sign-up process.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <img src={ReferralCompletionBonusIcon} alt="Reward Distribution Icon" />
                    <h4 className="text-white-referral text-center mt-3 mb-1">Reward Distribution</h4>
                    <p className="text-white-referral text-center fs-15">
                      Rewards will be credited to your Lavni account within 7 business days after the referred client completes their fourth session.
                    </p>
                    <p className="text-white-referral text-center fs-15">
                      The $10 reward can be used towards future sessions on the Lavni platform.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <img src={ReferralBonusIcon} alt="Referral Limit Icon" />
                    <h4 className="text-white-referral text-center mt-3 mb-1">Referral Limit</h4>
                    <p className="text-white-referral text-center fs-15">
                      There is no limit to the number of people you can refer. The more you refer, the more you can earn!
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="m-terms-referral">
          <div className="container">
            <h1 className="text-black-referral text-center mb-3">Terms and Conditions</h1>
            <div className="d-flex align-items-center mb-2 mb-md-4">
              <img src={ListIcon} className="me-3 me-md-5" />
              <p className="mb-0 text-black-referral fs-15">
                The referred client must be new to Lavni and not an existing or previous user.
              </p>
            </div>
            <div className="d-flex align-items-center mb-2 mb-md-4">
              <img src={ListIcon} className="me-3 me-md-5" />
              <p className="mb-0 text-black-referral fs-15">
                Lavni reserves the right to modify or terminate the referral program at any time.
              </p>
            </div>
            <div className="d-flex align-items-center mb-2 mb-md-4">
              <img src={ListIcon} className="me-3 me-md-5" />
              <p className="mb-0 text-black-referral fs-15">
                Any fraudulent activity or misuse of the referral program may result in disqualification from the program and forfeiture of any earned rewards.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-5">
          <div className="container">
            <p className="text-center text-black-referral fs-20">
            Start sharing your referral link and help others discover the benefits of Lavni’s mental health services. Thank you for being a valued member of our community and for your continued support!
            If you have any questions or need assistance, please contact our support team at <a href="mailto:info@mylavni.com">info@mylavni.com</a>.
            </p>
          </div>
        </section>

        <section className="bg-refferal ">
          <div className="container pt-pb-join-container-referral">
            <h1 className="text-white-referral text-center mb-3">
              The more friends you refer, the more you earn! Join
              <br /> us in building a thriving community on Lavni.
            </h1>
            <div className="d-flex align items-center flex-column">
              <button className="btn btn-primary mt-4 referral-btn-d fs-18" onClick={referralModal}>
                Send a referral <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={togleModalReferal} toggle={referralModal} centered className="modal-lg">
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}>
          <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "2vh" }}>
            <div className="image-container2">
              <img src={Cover} className="meeting-ended-image-size" alt="Meeting Cover" />

              <div className="d-flex justify-content-center pt-5 referal-link-btn">
                <input type="url" value={url} className="form-control form-control-sm mr-2" placeholder="Enter URL" />
                <button className="btn btn-primary referal-option-btn mr-2" onClick={handleCopyURL}>
                  <img src={Coppy} alt="coppy" />
                </button>
                <button className="btn btn-primary referal-option-btn" onClick={handleShare}>
                  
                  <img src={Share} alt="share" />
                </button>
              </div>

              <div className="button-container btn-container-position-top">
                <button type="button" onClick={referralModal} className="bg-transparent-color" data-dismiss="modal" aria-label="Close">
                  <img src={Close} alt="Close" className="" />
                </button>
              </div>
            </div>
          </div>
          {showSharePopup && (
            <>
              <div className="d-flex  py-2 referal-link-btn row">
                <div className="d-flex  col-12 justify-content-md-end justify-content-center">
                  <TextArea
                    value={messageContent}
                    onChange={e => setMessageContent(e.target.value)}
                    placeholder="Enter Your Message"
                    required
                    className=""
                    rows={13}
                    maxLength={600}
                  />
                </div>
              </div>
              <div className="d-flex  py-2 referal-link-btn row mb-4">
                <div className="d-flex justify-content-center align-items-center col-md-6 col-12 mb-2 mb-md-0">
                  <PhoneInput
                    value={phoneNumber}
                    placeholder="Enter phone number"
                    className="mr-2 sms-referral-input-mob"
                    defaultCountry="US"
                    onChange={(value: string) => setPhoneNumber(value)}
                  />
                  <button className="btn btn-primary d-flex justify-content-center align-items-center btn-sm via-message-btn" onClick={sendSMS}>
                    <span className="d-none d-md-block">Via Message</span>
                    <img src={Message} className="mx-2" />
                  </button>
                </div>

                <div className="d-flex col-md-6 col-12 justify-content-md-end justify-content-center">
                  <Input
                    type="email"
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    placeholder="Enter Email Address"
                    required
                    className="email-referral-input mr-2"
                  />
                  <button className="btn btn-primary d-flex justify-content-center align-items-center btn-sm" onClick={sendEmail}>
                    <span className="d-none d-md-block">Via Email</span>
                    <img src={Email} alt="email-icon" className="mx-2" />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>

      <Modal isOpen={toggleModalRewards} toggle={rewardsModal} centered className="modal-lg">
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}>
          <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "2vh" }}>
            <div className="image-container2">
              <img src={RewardEarningsIcon} className="meeting-ended-image-size" alt="Earned Rewards" />

              <div className="d-flex justify-content-center referal-link-btn" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Reward Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Paid Status</th>
                      <th scope="col">From User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewards.length > 0 ? (
                      <>
                        {rewards.map((t, index) => (
                          <tr key={index}>
                            <td>{format(new Date(t.createdAt).getTime(), "MMMM do, yyyy H:mm a")}</td>
                            <td>{t.rewardType}</td>
                            <td>${Math.round(t.transactionAmount * 100) / 100}</td>
                            <td>
                              {t.paidStatus === undefined ? (
                                <div>
                                  <span className="pending-2">Pending</span>
                                </div>
                              ) : t.paidStatus === "PAID" ? (
                                <div>
                                  <span className="verified-2">Paid</span>
                                </div>
                              ) : null}
                            </td>
                            <td>
                              { (t?.rewardReferralId && t?.rewardReferralId?.referredUserId) ? (
                                <>
                                  <Link to={"/profile/details/" + t?.rewardReferralId?.referredUserId?._id} className="btn btn-info btn-sm">
                                    {t?.rewardReferralId?.referredUserId?.firstname} {t?.rewardReferralId?.referredUserId?.lastname}
                                  </Link>
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          </tr>
                        ))}
                        {rewardCount >= 10 && (
                          <>
                            <button className="btn btn-warning text-center mt-4 button_center" onClick={seeMoreRewards}>
                              See More
                            </button>
                          </>
                        )}
                        </>
                    ) : (
                      isLoading ? (
                        <Spinner className="bouncer2" />
                      ) : (
                        <span>No reward found</span>
                      )
                    )}
                  </tbody>
                </table>
              </div>

              <div className="button-container btn-container-position-top">
                <button type="button" onClick={rewardsModal} className="bg-transparent-color" data-dismiss="modal" aria-label="Close">
                  <img src={Close} alt="Close" className="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
