import React, { useState, useEffect } from "react";
import Spinner from '../../../common/spinner/spinner';
import moment from "moment";
import { Therapist } from "src/models/Therapist";
import { Client } from "src/models/Client";
import { ModalType, Repeat } from "src/models/Appointment";
import AppointmentModal from "src/pages/Popup/AppointmentModal";

interface PendingAppointmentsProps {
  pendingAppointments: any[];
}

const PendingAppointments: React.FC<PendingAppointmentsProps> = ({ pendingAppointments }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const [chattingWith, setChattingWith] = useState("");
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
  });

  useEffect(() => {
    setIsLoading(true);
    setAppointments(pendingAppointments);
    setIsLoading(false);
  }, [pendingAppointments]);

  const handleNoteClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const toggleSorting = () => {
    if (sortType === "asc") {
      sortingAZ();
      setSortType("desc");
    } else {
      sortingZA();
      setSortType("asc");
    }
  };

  const sortingAZ = () => {
    const sortedData = [...appointments].sort((a: any, b: any) => {
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setAppointments(sortedData);
  };

  const sortingZA = () => {
    const sortedData = [...appointments].sort((a: any, b: any) => {
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setAppointments(sortedData);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredAppointments = appointments.filter((client) => {
    const fullName = `${client.clientDetails?.firstname} ${client.clientDetails?.lastname}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <div className="clinical-notes-container">
      <AppointmentModal
        clientId={chattingWith}
        appointment={appointment}
        setAppointment={setAppointment}
        modalType={ModalType.CREATE}
        setShowModal={setShowAppointmentModal}
        showModal={showAppointmentModal}
        errorModalText={errorModalText}
        setErrorModalText={setErrorModalText}
        setShowErrorModal={setShowErrorModal}
        setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
        setShowModalForTherapist={setShowModalForTherapist}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        updateMatchedClient={() => { }}
        hourValue={hourValue}
        setHourValue={setHourValue}
        minuteValue={minuteValue}
        setMinuteValue={setMinuteValue}
        updateAppointmentObject={() => { }}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="clinical-notes-header-container d-flex flex-lg-row flex-md-row flex-sm-column flex-column justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center fs-5 fw-bold">
              Pending Follow-Up Appointments: Clients List
              <span className="ms-2">
                <i onClick={toggleSorting} className="bx bx-sort"></i>
              </span>
            </div>
            <div className="search-container d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Search clients..."
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: '250px', height: "35px" }}
              />
            </div>
          </div>
          <div className="clinical-notes-list">
            {filteredAppointments.length > 0 ? (
              filteredAppointments.map((client, index) => {
                return (
                  <div
                    key={index}
                    className={`clinical-note-item ${index === activeIndex ? "active" : ""}`}
                    onClick={() => handleNoteClick(index)}
                  >
                    <div className="clinical-note-icon"></div>
                    <span className="clinical-note-name">
                      {client.clientDetails?.firstname} {client.clientDetails?.lastname}
                    </span>
                    <button
                      className="clinical-note-button"
                      onClick={() => {
                        setShowAppointmentModal(!showAppointmentModal);
                        setChattingWith(client.clientDetails._id);
                      }}
                    >
                      Create
                    </button>
                  </div>
                );
              })
            ) : (
              <p>No follow-Up appointments available</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PendingAppointments;
