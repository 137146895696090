import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, Container } from "reactstrap";
import Spinner from "../../../common/spinner/spinner";
import { NoteList, TherapistSession } from "../../../models/DiagnosisNote";
import { AdminService } from "../../../services/AdminService";
import { ParameterTypes } from "../../../utils/ParameterTypes";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import SessionHistoryModalForAdmin from "../../Popup/SessionHistoryModalForAdmin";
import SessionHistoryPIEModalForAdmin from './../../Popup/SessionHistoryPIEModalForAdmin';
import { VideoChatService } from 'src/services/VideoChatService';
import DiagnosisNoteVersionModal from "src/pages/Popup/DiagnosisNoteVersionModal";

type ViewTherapistSessionsProps = {
    callBackPage: () => void;
    selectedTherapistId: string;
  };

const ViewTherapistSessions:  React.FC<ViewTherapistSessionsProps> = ({ callBackPage ,selectedTherapistId}) => {
  const history = useHistory();
//   const { userId } = useParams<ParameterTypes>();
  const userId = selectedTherapistId;
  const [isLoaded, setIsLoaded] = useState(true);
  const [showNote, setShowNote] = useState(false);
  const [noteId, setNoteId] = useState<string>("");
  const [sessionList, setSessionList] = useState<TherapistSession[]>([]);
  const [noteType, setNoteType] = useState<string | undefined | null>();
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const [showPIENote, setShowPIENote] = useState(false);
  const [updateByTherapist, setUpdateByTherapist] = useState(false);
  const [diagnosisNoteWithVersionsData, setDiagnosisNoteWithVersionsData] = useState<any>();
  const [showDiagnosisNoteWithVersionModal, setShowDiagnosisNoteWithVersionModal] = useState(false);
  const [isVersion, setIsVersion] = useState<boolean>(false);

  useEffect(() => {
    if (noteId != "") {
      setShowNote(true);
    }
  }, [noteId]);

  useEffect(() => {
    if (diagnosisNoteWithVersionsData) {
      setShowDiagnosisNoteWithVersionModal(true);
    }
  }, [diagnosisNoteWithVersionsData]);

  useEffect(() => {
    if (userId) getTherapistSessionListByTherapistId(userId);
  }, []);

  useEffect(() => {
    if (showNote && (noteType)) {

    } else {

    }
  }, [showNote, noteType, showPIENote, showAiGeneratedPopup, isVersion]);

  const getTherapistSessionListByTherapistId = (therpaistId: string) => {
    setIsLoaded(false);

    AdminService.getAllTherapistSessions(therpaistId).then(res => {
      if (res.success) {
        setSessionList(res.data);
        setIsLoaded(true);
      }
    });
  };

  const back = () => {
    callBackPage()
    // history.goBack();
  };

  const closseNote = () => {
    setNoteId("")
    setShowNote(false);
    setIsVersion(false);
  };

  const openNote =  (note: any, isVersion: boolean) => {

        if(note?.diagnosisNoteId) {

          setNoteId(note?.diagnosisNoteId);
          setIsVersion(isVersion);
          //  setShowNote(true);

         if(!note?.noteType && note?.updateByTherapist) {

            setNoteType('SOAP');
            setShowAiGeneratedPopup(true);
            setShowPIENote(false);

         } else if(note?.noteType) {

            console.log("condition 02")

            setNoteType(note?.noteType);
            if(noteType == 'SOAP') {
              setShowAiGeneratedPopup(true);
              setShowPIENote(false);
            } else {
                 setShowPIENote(true);
                 setShowAiGeneratedPopup(false);
              }
         } else {
            setNoteType(undefined)

       }

     }
  };

  const handleModalClose = () => {
    setShowDiagnosisNoteWithVersionModal(false);
    setDiagnosisNoteWithVersionsData(null);
  };

  return (
    <React.Fragment>
      {/* <SessionHistoryModalForAdmin setShowModal={closseNote} showModal={showNote} userId={noteId} /> */}
      {showDiagnosisNoteWithVersionModal && <DiagnosisNoteVersionModal setShowModal={setShowDiagnosisNoteWithVersionModal} showModal={showDiagnosisNoteWithVersionModal} diagnosisNoteDataTherapist={null} diagnosisNoteDataAdmin={diagnosisNoteWithVersionsData} showDiagnosisFunction={ () => {} } isAdmin={true} showDiagnosis={openNote} handleClose={handleModalClose} />}

      {
          showNote && (noteType) ? (
             (noteType == 'PIE' ) ? (
                     showPIENote &&
                     <SessionHistoryPIEModalForAdmin
                       setShowModal={closseNote}
                       showModal={showNote}
                       userId={noteId}
                       isVersion={isVersion} />) :
                       (
                  showAiGeneratedPopup &&
                     <SessionHistoryModalForAdmin
                         setShowModal={closseNote}
                          showModal={showNote}
                          userId={noteId}
                          isVersion={isVersion} />
                                             )
                                        )
                                        : (
                     <SessionHistoryModalForAdmin
                        setShowModal={closseNote}
                        showModal={showNote}
                        userId={noteId}
                        isVersion={isVersion}
                                         />
                                       )

         }

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Lavni" breadcrumbItem="Sessions" />
          <div className="flex flex-wrap justify-content-between">
            <div className="flex cursor-pointer" onClick={() => back()}>
              <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
              <CardTitle className="mb-4  cursor-pointer">&nbsp; Back</CardTitle>
            </div>
          </div>

          {!isLoaded && <Spinner className="bouncer" />}

          {isLoaded && sessionList.length == 0 && <div className="flex flex-wrap"><h5 className="text-center">No session notes so far!</h5></div>}

          {sessionList &&
            sessionList.length > 0 &&
            sessionList.map((session: TherapistSession) => {
              return (
                <>
                  <h5 className="font-size-18">
                    {"Notes created for "}
                    {session._id.fullName}
                  </h5>
                  {session.notes &&
                    session.notes.length > 0 &&
                    session.notes.map((note: NoteList, index: number) => {
                      return (
                        <Card key={index} className="cursor_pointer mt-1">
                          <CardBody>
                            {note?.appointmentId ? (
                              <>
                                <h5 className="font-size-15 mb-1">
                                  Appointment Start Time <span className="session-h5">{moment(note?.appointmentStart || null).format("YYYY-MM-DD h:mm:ss a")}</span> / Meeting Started at <span className="session-h5">{moment(note?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                                </h5>

                              </>
                            ) : (
                              <>
                                <h5 className="font-size-15 mb-1">
                                  Meeting Started At <span className="session-h5">{moment(note?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                                </h5>
                              </>
                            )}

                            {note.isMeetingTranscribe && (
                              <a
                                // onClick={() => {
                                //   if (note.isMeetingTranscribe) {
                                //     // setNoteType(note.noteType);
                                //     // setUpdateByTherapist(!note.updatedByTherapist ? false: note.updatedByTherapist)
                                //     // openNote(note.diagnosisNoteId);
                                //     openNote(note)
                                //   }
                                // }}

                                onClick={() => {
                                  if (note.isMeetingTranscribe) {
                                    if (note?.diagnosisNoteVersions && note?.diagnosisNoteVersions?.length > 0) {
                                      const combinedData = {
                                        diagnosisnote: {
                                          diagnosisNoteId: note?.diagnosisNoteId,
                                          createdAt: note?.createdAt,
                                          updateByTherapist: note?.updatedByTherapist,
                                          noteType: note?.noteType
                                        },
                                        diagnosisNoteVersions: note?.diagnosisNoteVersions.map(version => ({
                                          _id: version._id,
                                          diagnosisNoteId: version._id,
                                          versionCreatedAt: version.versionCreatedAt,
                                          updateByTherapist: version.updatedByTherapist,
                                          noteType: version.noteType
                                        }))
                                      };
                                      setDiagnosisNoteWithVersionsData(combinedData);
                                    } else {
                                      openNote(note, false);
                                    }
                                  }
                                }}
                                className="btn btn-secondary "
                                style={{ marginTop: "10px" }}
                              >
                                View / Edit Notes
                              </a>
                            )}
                          </CardBody>
                        </Card>
                      );
                    })}
                </>
              );
            })}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ViewTherapistSessions;
