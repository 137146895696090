import React, { useState, useContext, useEffect, useRef } from "react";
import { Col, Row, Card, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../context/UserContext";
import { InsuranceCompanyTags } from "../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import { Upload } from "../../models/Upload";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { SubscriberModel } from "src/models/Insurance";
import moment from "moment";
import { ClientService } from "src/services/ClientService";
import Swal from "sweetalert2";
import { Slider } from "@material-ui/core";
import Cropper from "react-easy-crop";
import getCroppedInsuranceImgAsFile from "../../pages/Profile/cropInsuranceImage";
import compressPdf from "../../pages/Profile/compressPdf";
import pdfIcon from "../../assets/images/pdf_file_icon.png";
import { fileTypeFromBlob } from 'file-type';

interface InsuranceCardState {
  preview: string;
  preview2: File | string;
  raw: File[] | string;
}

const PublicInsuranceInfo: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [organization, setOrganization] = useState("");
  const [insuranceCard, selectInsuranceCard] = useState<InsuranceCardState>({ preview: "", preview2: "", raw: "", });
  const insuranceCardRef = useRef<any>();
  insuranceCardRef.current = insuranceCard;
  const [insuranceCardBack, selectInsuranceCardBack] = useState<InsuranceCardState>({ preview: "", preview2: "", raw: "", });
  const insuranceCardBackRef = useRef<any>();
  insuranceCardBackRef.current = insuranceCardBack;
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [selectedInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState("");
  const [subscriberData, setSubscriberData] = useState<SubscriberModel>({} as SubscriberModel);
  const [dateValue, setDateValue] = useState(null);
  const insurance = localStorage.getItem("insuranceCompany");
  const state = localStorage.getItem("state");
  const [saveDisabled, setDisabled] = useState(false)
  const setOrganizationData = (organizationName: string) => {
    setOrganization(organizationName);
  };
  const [subscriberDetails, setSubscriberDetails] = useState({ firstname: "", lastname: "", state: "", city: "", address1: "" });
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image2, setImage2] = useState<File | null>(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const thirdData = useRef<any>();
  thirdData.current = image;
  const [insuranceModalType, setInsuranceModalType] = useState<"front" | "back">("front");

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }
  const db = moment.utc(dateValue || user?.dateOfBirth).format("YYYY-MM-DD");

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllInsuranceCompanies();

    if (insurance && insurance !== "insurance" && selectedInsuranceCompanyTag !== "No Insurance") {
      setSelectedInsuranceCompanyTag(insurance);
    }
  }, []);

  const getAllInsuranceCompanies = () => {
    const data: any = {
      state: state || user?.state
    }
    InsuranceCompanyService.getInsuaranceCompanyByState(data).then((res: any) => {
      if (res.success) {
        setSearchInsuranceCompanyTag(res.data);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  const InsuranceCardFrontFiles = () => {
    switch (insuranceCardRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCard({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const InsuranceCardBackFiles = () => {
    switch (insuranceCardBackRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCardBack({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const updateTherapistDetails = () => {
    localStorage.setItem("insuranceCompany", selectedInsuranceCompanyTag);
    if (!subscriberData?.memberId) {
      toast.error("Member id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!selectedInsuranceCompanyTag || selectedInsuranceCompanyTag == "No Insurance" || selectedInsuranceCompanyTag === "Select") {
      toast.error("Organization Name id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setDisabled(true);
      const address: any = {
        state: user?.state || subscriberDetails?.state,
      };

      const resive: any = {
        organizationName: selectedInsuranceCompanyTag,
      };
      const subcribersData: any = {
        memberId: subscriberData?.memberId,
        firstName: user?.firstname || subscriberDetails?.firstname,
        lastName: user?.lastname || subscriberDetails?.lastname,
        address: address,
      };
      const data: any = {
        clientId: user?._id,
        insuranceCompanyId: resive.organizationName,
        subscriber: subcribersData,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: "none",
      };
      setDisabled(true);
      ClientService.addInsurancePlan(data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setDisabled(false);
          toast.success(
            res.success ? "Your insurance has been successfully updated." : "Your insurance has been successfully updated.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            }
          );
          location.href = "/add-payment";
        } else {
          setDisabled(false);
          Swal.fire({
            icon: "warning",
            title: "Hey Sorry!, we can't verify your insurance. One of our agents will reach out to you.",
            confirmButtonText: "Proceed",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
          }).then(result => {
            if (result.isConfirmed) {
              setDisabled(false)
              location.href = "/add-payment";
            }
          });
        }
      });
    }
  };

  const haveNotInsuranceFunc = () => {
    console.log("haveNotInsuranceFunc");
    localStorage.removeItem("insuranceCompany");
    location.href = "/choose-plan";

  }

  const back = () => {
    localStorage.removeItem("insuranceCompany");
    history.back();
  };

  const handleFrontFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload(e.target.files[0], "front");
    }
  };

  const handleBackFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload(e.target.files[0], "back");
    }
  };

  const toggleInsuranceModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCrop = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      const croppedImageNew = await getCroppedInsuranceImgAsFile(image2, croppedArea, croppedAreaPixels);
      setCroppedImage(croppedImageNew as File | null);
    } catch (error) {
      toast.error(`Error capturing cropped image. Error details: ${error}.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleZoomChange = (event: any, newZoom: any) => {
    setZoom(newZoom);
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const handleFileUpload = async (file: File, type: "front" | "back") => {
    const fileType = await fileTypeFromBlob(file);

    if (fileType?.mime === "application/pdf") {
      const compressedPdf = await compressPdf(file);
      if (type === "front") {
        selectInsuranceCard({
          preview: URL.createObjectURL(compressedPdf),
          preview2: compressedPdf,
          raw: [compressedPdf],
        });
      } else {
        selectInsuranceCardBack({
          preview: URL.createObjectURL(compressedPdf),
          preview2: compressedPdf,
          raw: [compressedPdf],
        });
      }
    } else if (fileType?.mime === "image/jpeg" || fileType?.mime === "image/png") {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && typeof event.target.result === "string") {
          setImage({ preview: event.target.result, raw: "" });
          setImage2(file);
          setInsuranceModalType(type);
          toggleInsuranceModal();
        }
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("You have selected an unsupported file. Please select an image or PDF file.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleSave = () => {
    if (croppedImage) {
      if (insuranceModalType === "front") {
        selectInsuranceCard({
          preview: URL.createObjectURL(croppedImage),
          preview2: croppedImage,
          raw: [croppedImage],
        });
      } else {
        selectInsuranceCardBack({
          preview: URL.createObjectURL(croppedImage),
          preview2: croppedImage,
          raw: [croppedImage],
        });
      }
      toggleInsuranceModal();
    }
  };

  return (
    <>
      <div id="targetSection" className="content-wrapper mt-22rem">
        <section className="wrapper bg-light2 angled upper-end" id="recoverform">
          <div className="container1 pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
                <div className="card">
                  <div className="card-body padding-13 text-center">
                    <Row>
                      <Col xl={6}></Col>
                      <Col xl={6}>
                        <button
                          type="button"
                          className={
                            saveDisabled ? "btn btn-primary next-btn relative mb-4 ml-10 updating" : "btn btn-primary next-btn relative mb-4 ml-10"
                          }
                          style={{
                            bottom: "0",
                            float: "right",
                          }}
                          disabled={saveDisabled}
                          onClick={() => {
                            haveNotInsuranceFunc();
                          }}
                        >
                          I don&apos;t have insurance
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12" sm="12" className="pading-signupInsurance">
                        <div className="row">
                          {insurance == "insurance" && (
                            <div className="col-xl-12 mb-3 d-flex float-left jestifyStart">
                              <span>
                                <button className="btn btn-skip float-left" onClick={() => back()}>
                                  <i className="bx bx-left-arrow-alt"></i> &nbsp; Back
                                </button>
                              </span>
                            </div>
                          )}
                        </div>
                        <h1 className="mb-3 text-start">Insurance Information</h1>
                        <p className="lead mb-6 text-start">Enter your insurance information to confirm your appointment</p>
                        <form className="text-start mb-3 needs-validation">
                          <Col xl={12}>
                            <Card className="BorderRadiusLeftRight paddingInsurance">
                              <div className="card-bodyDiv">
                                <Row>
                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="First name"
                                          name="First name"
                                          defaultValue={user?.firstname || ""}
                                          onChange={e => setSubscriberDetails({ ...subscriberDetails, firstname: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Last name"
                                          name="Last name"
                                          defaultValue={user?.lastname || ""}
                                          onChange={e => setSubscriberDetails({ ...subscriberDetails, lastname: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Member Id "
                                          name="Member Id "
                                          value={subscriberData?.memberId || ""}
                                          onChange={e => setSubscriberData({ ...subscriberData, memberId: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                                      </div>

                                      <select
                                        className="form-control mb-4"
                                        value={selectedInsuranceCompanyTag}
                                        onChange={e => {
                                          setSelectedInsuranceCompanyTag(e.target.value);
                                        }}
                                      >
                                        <option>Select</option>
                                        {searchInsuranceCompanyTag &&
                                          searchInsuranceCompanyTag.map(opt => (
                                            <option value={opt?._id} key={opt._id}>
                                              {opt?.insuranceCompany}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">State</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="State"
                                          name="State"
                                          defaultValue={user?.state || ""}
                                          onChange={e => setSubscriberDetails({ ...subscriberDetails, state: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>

                                  <Col xl="6">
                                    {insuranceCardRef.current.preview ? (
                                      <div>
                                        {insuranceCardRef.current.preview2?.type === "application/pdf" ? (
                                          <div>
                                            <a
                                              href={insuranceCardRef.current.preview}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ display: "block", marginBottom: "10px" }}
                                            >
                                              <img
                                                src={pdfIcon}
                                                alt="PDF Icon"
                                                style={{ width: "30px", height: "40px" }}
                                              />
                                              <span>&nbsp;View selected PDF</span>
                                            </a>
                                          </div>
                                        ) : (
                                          <img
                                            src={insuranceCardRef.current.preview}
                                            alt="Selected insurance card front"
                                            style={{ maxWidth: "50%", height: "auto", marginBottom: "10px" }}
                                          />
                                        )}

                                        <br /><label htmlFor="file-upload-front" className="file-upload m-auto">
                                          <span className="text-primary mt-5 m-auto cursor-pointer">
                                            Change your insurance card front side
                                          </span>
                                          <input
                                            id="file-upload-front"
                                            type="file"
                                            style={{ display: "none" }}
                                            accept="image/png, image/jpeg, application/pdf"
                                            onChange={handleFrontFileChange}
                                          />
                                        </label>

                                        <div>
                                          <div className="flex">
                                            <div style={{ fontSize: "62px" }}>{InsuranceCardFrontFiles()}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <label
                                        htmlFor="file-upload-front"
                                        style={{ border: "1px dashed" }}
                                        className="mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 rounded cursor-pointer"
                                      >
                                        <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                          <img src={uploadIcon} alt="Upload Icon" />
                                          <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card front side</p>
                                        </div>
                                        <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>
                                        <input
                                          id="file-upload-front"
                                          type="file"
                                          style={{ display: "none" }}
                                          className="file_upload_input mb-4"
                                          accept="image/png, image/jpeg, application/pdf"
                                          onChange={handleFrontFileChange}
                                        />
                                      </label>
                                    )}
                                  </Col>

                                  <Col xl="6">
                                    {insuranceCardBackRef.current.preview ? (
                                      <div>
                                        {insuranceCardBackRef.current.preview2?.type === "application/pdf" ? (
                                          <div>
                                            <a
                                              href={insuranceCardBackRef.current.preview}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ display: "block", marginBottom: "10px" }}
                                            >
                                              <img
                                                src={pdfIcon}
                                                alt="PDF Icon"
                                                style={{ width: "30px", height: "40px" }}
                                              />
                                              <span>&nbsp;View selected PDF</span>
                                            </a>
                                          </div>
                                        ) : (
                                          <img
                                            src={insuranceCardBackRef.current.preview}
                                            alt="Selected insurance card back"
                                            style={{ maxWidth: "50%", height: "auto", marginBottom: "10px" }}
                                          />
                                        )}
                                        <br /><label htmlFor="file-upload2" className="file-upload m-auto">
                                          <span className="text-primary mt-5 m-auto cursor-pointer">Change your insurance card back side</span>
                                          <input
                                            id="file-upload2"
                                            type="file"
                                            style={{ display: "none" }}
                                            accept="image/png, image/jpeg, application/pdf"
                                            onChange={handleBackFileChange}
                                          />
                                        </label>

                                        <div>
                                          <div className="flex">
                                            <div style={{ fontSize: "62px" }}>{InsuranceCardBackFiles()}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <label
                                        htmlFor="file-upload2"
                                        style={{ border: "1px dashed" }}
                                        className="mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 rounded cursor-pointer"
                                      >
                                        <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                          <img src={uploadIcon} alt="Upload Icon" />
                                          <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card back side</p>
                                        </div>
                                        <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>
                                        <input
                                          id="file-upload2"
                                          type="file"
                                          style={{ display: "none" }}
                                          className="file_upload_input mb-4"
                                          accept="image/png, image/jpeg, application/pdf"
                                          onChange={handleBackFileChange}
                                        />
                                      </label>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                              <Row>
                                <Col xl={6}></Col>
                                <Col xl={6}>
                                  <button
                                    type="button"
                                    className={
                                      saveDisabled ? "btn btn-primary next-btn relative mb-4 ml-10 updating" : "btn btn-primary next-btn relative mb-4 ml-10"
                                    }
                                    style={{
                                      bottom: "0",
                                      float: "right",
                                    }}
                                    disabled={saveDisabled}
                                    onClick={() => {
                                      updateTherapistDetails();
                                    }}
                                  >
                                    {saveDisabled ? "Saving..." : "Next"}
                                  </button>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modalOpen}
        toggle={toggleInsuranceModal}
        centered
        style={{
          margin: "auto",
        }}
      >
        <ModalHeader toggle={toggleInsuranceModal}>Crop insurance {insuranceModalType === "front" ? "front" : "back"} image</ModalHeader>
        <ModalBody>
          <div style={{ width: "100%", height: "250px" }}>
            <Cropper
              image={image.preview}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              cropShape="rect"
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={handleCrop}
              initialCroppedAreaPercentages={initialCroppedArea}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
            <Slider value={zoom} min={1} max={3} step={0.1} onChange={handleZoomChange} aria-labelledby="Zoom" />
          </div>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px", width: "100%" }}>
            <Button color="primary" onClick={handleSave} style={{ flex: 1, maxWidth: "100px" }}>
              Save
            </Button>
            <Button color="secondary" onClick={toggleInsuranceModal} onClose={onClose} style={{ flex: 1, maxWidth: "100px" }}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default PublicInsuranceInfo;
