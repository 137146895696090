import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import "../../assets/css/admin.css";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { Client } from "../../models/Client";
import { CopaymentStatus, GenderTypes, InsuranceActiveStatus, UserRole, UserStatus } from "../../models/User";
import { SubscriptionStatus } from "../../models/CardTypes";
import moment from "moment";
import { SocketContext } from "../../context/ScoketContext";
import UserContext from "../../context/UserContext";
import Spinner from "../../common/spinner/spinner";
import { toast } from "react-toastify";
import { MeetingData } from "src/models/MeetingData";
import { SaasTherapistService } from "src/services/SaasTherapistService";
import { ModalType, Repeat } from "src/models/Appointment";
import { Therapist } from "src/models/Therapist";
import AppointmentModal from "../Popup/AppointmentModal";
import UploadDocuments from "../Chat/UploadDocuments";
import { FormVersionService } from "../../services/FormVersionService";
import { CAFormType } from "../../models/clinicalAssesment/clinicalAssesment";
import Modal32 from "../Popup/Modal32";
import { TPType } from "../../models/therapyPlan/therapyPlan";
import ClinicalAssetmentModal from "../Popup/ClinicalAssessmentModal";
import NewClinicalAssessmentModal from "../Popup/NewClinicalAssessmentModal";
import AIGeneratedAssessment from "../NewAssessmentDigitalForm/AIGeneratedAssessment";
import Modal31 from "../Popup/Modal31";
import TherapyPlanModal from "../Popup/TherapyPlanModal";
import TherapyPlanPdfModal from "../Popup/TherapyPlanPdfModal";
import { TherapistService } from "../../services/TherapistService";
import { NotificationEvent, NotificationModel, NotificationVarient } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";
import ProfileModalSaas from "./ViewProfile/ProfileModalSaas";
import PreAuthorizationSaas from "./ClientsChartTabs/PreAuthorizationSaas";
import Close from "../../assets/images/icons/u_times-circle.png";
import SessionHistoryModal from "../Popup/SessionHistoryModal";

interface ClientDetails {
  firstname: string;
  lastname: string;
  primaryPhone: string;
  _id: string;
}

interface ClientData {
  clientDetails: ClientDetails;
  completedNotesCount: number;
  incompletedNotesCount: number;
  meetingCount: number;
  previousSession: string;
  nextSession: string;
  remainingSessions: number;
  _id: string;
}

const AllClients: React.FC = () => {
  const [clientOffset, setClientOffset] = useState(1);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isGender, setIsGender] = useState(GenderTypes.ALL as GenderTypes);
  const [isSubscription, setSubscription] = useState(SubscriptionStatus.ALL as SubscriptionStatus);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [status, setStatus] = useState("");
  const socket = useContext(SocketContext);
  const [user] = useContext(UserContext);
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [activeClient, setActiveClient] = useState("");
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(0);
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const [insuranceActiveStatus, setInsuranceActiveStatus] = useState("");
  const [copaymentStatus, setCopaymentStatus] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState<boolean[]>([]);
  const [clientDetails, setClientDetails] = useState<any[]>([]);
  const [chattingWith, setChattingWith] = useState("");
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
  });
  const [errorModalText, setErrorModalText] = useState("");
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [isUploadDocumentsOpen, setIsUploadDocumentsOpen] = useState(false);
  const [selectedCAFormType, setSelectedCAFormType] = useState("");
  const [firstMeetingData, setFirstMeetingData] = useState([] as MeetingData[]);
  const [clinicalAssessmentInfo, setClinicalAssessmentInfo] = useState("");
  const [showClinicalAssessmentWithVersionModal, setShowClinicalAssessmentWithVersionModal] = useState(false);
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = useState<boolean>(false);
  const [clinicalAssessmentId, setClinicalAssessmentId] = useState("");
  const [clinicalAssessmentType, setClinicalAssessmentType] = useState("");
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showAiGeneratedAssessmentPopup, setShowAiGeneratedAssessmentPopup] = useState<boolean>(false);
  const [clientIdForForm, setClientIdForForm] = useState("");
  const [therapyPlanInfo, setTherapyPlanInfo] = useState("");
  const [showTherapyPlanWithVersionModal, setShowTherapyPlanWithVersionModal] = useState(false);
  const [therapyPlanId, setTherapyPlanId] = useState("");
  const [showTherapyPlanModal, setShowTherapyPlanModal] = useState<boolean>(false);
  const [isCreatingNewForm, setIsCreatingNewForm] = useState<boolean>(false);
  const [therapyPlanType, setTherapyPlanType] = useState("");
  const [showTherapyPlanPdfModal, setShowTherapyPlanPdfModal] = useState<boolean>(false);
  const [seeMore, setSeeMore] = useState(false);
  const eventType = "DOWNLOAD";

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1300);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  useEffect(() => {
    fetchAllClients();
  }, [clientOffset]);

  useEffect(() => {
    setDropdownOpen(Array(clientDetails.length).fill(false));
  }, [clientDetails]);

  const fetchAllClients = async () => {
    try {
      setIsLoading(true);
      const res = await SaasTherapistService.getAllClients(limit, clientOffset);
      if (res && res?.success && res?.data) {
        if (res.data.length > 0) {
          setSeeMore(res.data.length > 0 && res.data.length == limit);
          setClientDetails(prev => {
            return [...new Set([...prev, ...res.data])];
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSeeMore(false);
          Swal.fire({
            icon: "info",
            title: "You have reached to the end. Wanna go back!",
            confirmButtonText: "Yes",
            confirmButtonColor: "#4ee9ef",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
            showCancelButton: true,
          }).then(result => {
            if (result.isConfirmed) {
              setClientDetails([]);
              setClientOffset(1);
              setSeeMore(res.data.length > 0 && res.data.length == limit);
            } else {
              setIsLoading(false);
              setSeeMore(false);
            }
          });
        }
      } else {
        toast.error(`Error loading data`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(`Something went wrong: ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      setIsLoading(false);
    }
  };

  const filterClients = () => {
    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: status,
      isSubscription: isSubscription,
      zipCode: zipCode,
      role: UserRole.CLIENT,
      appointmentStatus: appointmentStatus,
      clientActiveStatus: activeClient,
      insuranceActiveStatus: insuranceActiveStatus,
      copaymentStatus: copaymentStatus,
    };

    setIsLoading(true);

    const updatedOfsetSkip = 1;
    setClientOffset(updatedOfsetSkip);

    SaasTherapistService.searchClients(data).then(res => {
      if (res.success) {
        if (res.data.length > 0) {
          setSeeMore(false);
          setClientDetails(res.data);
          setIsLoading(false);
        } else {
          setClientDetails(res.data);
          setIsLoading(false);
          setSeeMore(false);
        }
      } else {
        toast.error(`Error loading data`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }

      setIsLoading(false);
      toggleFilter();
    });
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  };

  const toggle = (index: any) => {
    setDropdownOpen((prevState) =>
      prevState.map((item, i) => (i === index ? !item : false))
    );
  };

  const [sortType, setSortType] = useState("asc");
  const toggleSorting = () => {
    if (sortType === "asc") {
      sortingAZ();
      setSortType("desc");
    } else {
      sortingZA();
      setSortType("asc");
    }
  };

  const sortingAZ = () => {
    const sortedData = clientDetails.sort((a: any, b: any) => {
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const sortingZA = () => {
    const sortedData = clientDetails.sort((a: any, b: any) => {
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const toggleUDCanvas = () => {
    setIsUploadDocumentsOpen(!isUploadDocumentsOpen);
  };

  /*Rusiru - Open Action Button modals handler methods start here*/

  /*handle Clinical assessment view and generate here -start*/
  const handleClinicalAssessmentView = async (clientId: string) => {
    setSelectedClientId(clientId);
    if (clientId != undefined) {
      setIsLoading(true);
      setSelectedCAFormType("");
      setFirstMeetingData([] as MeetingData[]);

      const result = await fetchClinicalAssessmentWithVersions(clientId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
        setShowClinicalAssessmentWithVersionModal(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };
  const fetchClinicalAssessmentWithVersions = async (clientIdForForm: string) => {
    try {
      const res = await FormVersionService.getClinicalAssessmentsWithAllVersions(clientIdForForm);

      if (res && res.success) {
        const { relatedCAFormType, caWithVersion, daWithVersion, meetingData } = res.data;

        if (relatedCAFormType == CAFormType.OLD_CA) {
          setSelectedCAFormType(CAFormType.OLD_CA);
          return { success: true, data: caWithVersion };
        } else if (relatedCAFormType == CAFormType.NEW_CA) {
          setSelectedCAFormType(CAFormType.NEW_CA);

          if (meetingData) {
            setFirstMeetingData(meetingData);
          }
          return { success: true, data: daWithVersion };
        } else {
          return { success: false };
        }
      } else {
        toast.error("Encountered an error while retrieving the assessment data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };
  const clinicalAssessment = () => {
    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowNewClinicalAssessmentModal(false);
      setShowClinicalAssessmentModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowClinicalAssessmentModal(false);
      setShowNewClinicalAssessmentModal(true);
    }
  };
  const generateClinicalPdf = (planId: string, tpType: TPType) => {
    setClinicalAssessmentId(planId);
    setClinicalAssessmentType(tpType);

    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowClinicalAssessmentPdfModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowNewClinicalAssessmentPdfModal(true);
    }
  };

  const generateAiAssessmentFunction = () => {
    setShowAiGeneratedAssessmentPopup(true);
  };
  const refreshModal32 = async (value: boolean) => {
    if (value) {
      const result = await fetchClinicalAssessmentWithVersions(selectedClientId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
      }
    }
  };
  const handleChildFunction = (isCalled: boolean) => {
    setShowAiGeneratedAssessmentPopup(isCalled);
  };
  /*handle Clinical assessment view and generate here -end*/

  /*handle Therapy plans view and generate here -start*/

  const handleTherapyPlansView = async (clientId: string) => {
    setSelectedClientId(clientId);
    if (clientId != undefined) {
      setIsLoading(true);
      setClientIdForForm(clientId);

      const result = await fetchTherapyPlanWithVersions(clientId);
      if (result.success) {
        setTherapyPlanInfo(result.data);
      }
      setIsLoading(false);
      setShowTherapyPlanWithVersionModal(true);
    }
  };

  const fetchTherapyPlanWithVersions = async (clientIdForForm: string) => {
    try {
      const res = await FormVersionService.getTherapyPlansWithAllVersions(clientIdForForm);
      if (res && res?.success && res?.data) {
        return { success: true, data: res.data };
      } else {
        toast.error("Encountered an error while retrieving the therapy plan data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const createNewTherapyPlan = () => {
    setTherapyPlanId(selectedClientId);
    setShowTherapyPlanModal(true);
    setIsCreatingNewForm(true);
  };

  const therapyPlan = (planId: string) => {
    setTherapyPlanId(planId);
    setShowTherapyPlanModal(true);
    setIsCreatingNewForm(false);
  };

  const generateTherapyPlanPdf = (planId: string, tpType: TPType) => {
    setTherapyPlanId(planId);
    setTherapyPlanType(tpType);
    setShowTherapyPlanPdfModal(true);
  };
  const refreshModal31 = async (value: boolean) => {
    if (value) {
      const result = await fetchTherapyPlanWithVersions(clientIdForForm);
      if (result.success) {
        setTherapyPlanInfo(result.data);
      }
    }
  };
  /*handle Therapy plans view and generate here -end*/

  // Unmatched user logic start here
  const unmatchedUser = (clientId: string) => {
    Swal.fire({
      icon: "warning",
      title:
        "Are you sure want to stop seeing this client? (Please note this will remove all your scheduled appointments with this client.) Do you want to proceed?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "Later",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        clientDetails.forEach((data: ClientData) => {
          if (data.clientDetails._id === clientId) {
            TherapistService.unfriendClient(data._id).then(res => {
              if (res && res.success) {
                fetchAllClients();
                const notificationData: NotificationModel = {
                  senderId: user?._id,
                  receiverId: data?.clientDetails?._id,
                  event: NotificationEvent.REQUEST_REMOVED,
                  link: "/dashboard",
                  content: "You have been removed from the friend list by " + user?.firstname + " " + user?.lastname,
                  variant: NotificationVarient.WARNING,
                };

                NotificationService.sendNotification(notificationData).then((res: any) => {
                  const socketData: sendNotificationSocketData = {
                    socketId: res.data.socketId,
                    notifyData: notificationData,
                    receiverId: data?.clientDetails?._id,
                    senderId: user?._id,
                  };

                  socket.emit("send-notification", socketData);
                });
              } else {
                toast.error(`Something went wrong!`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
                });
              }
            });
          }
        });
      }
    });
  };
  // Unmatched user logic end here

  // Inactive user logic start here
  const deactivateUser = (clientId: string) => {
    const client = clientDetails.filter(c => c.clientDetails._id == clientId)[0];
    if (client.clientDetails.clientActiveStatus && client.clientDetails.clientActiveStatus == true) {
      Swal.fire({
        icon: "info",
        title: "Are you sure want to disable this client?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          TherapistService.makeClientInactive({ clientId: clientId }).then(res => {
            if (res.success) {
              toast.success(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              fetchAllClients();
            } else {
              toast.error(res.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          });
        }
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "This client is already disabled. To activate again you can create new appointment.",
        showCancelButton: false,
        confirmButtonText: "Sure",
        confirmButtonColor: "#50a5f1",
      });
    }
  };
  // Inactive user logic end here

  // Refer to a therapist logic start here
  // const handleUrlCopy = (clientId: string) => {
  //   const splitURL = environment.app_url;
  //   const userPublicUrl = splitURL + "/public-user-details/" + user?._id;
  //   navigator.clipboard
  //     .writeText(userPublicUrl)
  //     .then(() => {
  //       toast.success("Link copied to clipboard! ", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         className: "foo-bar",
  //       });
  //     })
  //     .catch(() => {
  //       toast.error("Link is not copied try again! ", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         className: "foo-bar",
  //       });
  //     });
  // };
  // Refer to a therapist logic end here

  // handle view client profile logic start here
  /*
   * <ProfileModal isOpen={modalOpenProfile} toggle={toggleModalProfile} clientId={clientId} />*/
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const toggleModalProfile = () => {
    setModalOpenProfile(!modalOpenProfile);
  };
  const handleViewClientProfile = (clientId: string) => {
    setModalOpenProfile(true);
    setSelectedClientId(clientId);
  };
  // handle view client profile logic ends here

  // handle prior authorization logic start here
  const [modalOpenPriorAuthorization, setModalOpenPriorAuthorization] = useState(false);
  const handlePriorAuthorization = (clientId: string) => {
    setSelectedClientId(clientId);
    setModalOpenPriorAuthorization(true);
  };

  function closePriorAuthorization() {
    setModalOpenPriorAuthorization(false);
  }

  // handle prior authorization logic ends here

  // handle session note logic start here
  const [showSessionHistoryModal, setShowSessionHistoryModal] = useState<boolean>(false); //change here
  const handleSessionNoteView = (clientId: string) => {
    setSelectedClientId(clientId);
    setShowSessionHistoryModal(true);
  };
  // handle session note logic ends here

  const renderDropdown = (index: any, clientId: string) => (
    <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggle(index)} direction="start" popperConfig={{
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: 'viewport', 
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    }}>
      <DropdownToggle caret className="btn-sm custom-toggle-admin">
        <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Actions</DropdownItem>
        <DropdownItem>
          <NavLink to={`/chat/${clientId}`} className="dropdown-item" style={{ all: "unset", display: "block", cursor: "pointer" }}>
            <i className="fas fa-comment-alt me-2"></i> Text
          </NavLink>
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setShowAppointmentModal(!showAppointmentModal);
            setChattingWith(clientId);
          }}
        >
          <i className="fas fa-calendar-alt me-2"></i> Schedule Appointment
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            toggleUDCanvas();
            setChattingWith(clientId);
          }}
        >
          <i className="fas fa-upload me-2"></i> Upload File
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            handleTherapyPlansView(clientId);
          }}
        >
          <i className="fas fa-file-alt me-2"></i> Complete Therapy Plan
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            handleClinicalAssessmentView(clientId);
          }}
        >
          <i className="fas fa-clipboard-check me-2"></i> Complete Assessments
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            handlePriorAuthorization(clientId);
          }}
        >
          <i className="fas fa-sync-alt me-2"></i> Complete Prior Authorization
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            handleSessionNoteView(clientId);
          }}
        >
          <i className="fas fa-tasks me-2"></i> Complete Session Notes
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            handleViewClientProfile(clientId);
          }}
        >
          <i className="fas fa-user me-2"></i> View Profile
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            deactivateUser(clientId);
          }}
        >
          <i className="fas fa-user-slash me-2"></i> Make Inactive
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            unmatchedUser(clientId);
          }}
        >
          <i className="fas fa-user-times me-2"></i> Unmatch
        </DropdownItem>
        {/* <DropdownItem
          onClick={() => {
            handleUrlCopy(clientId);
          }}
        >
          <i className="fas fa-user-friends me-2"></i> Refer to another therapist
        </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );

  const sortingNumberAsc = (field: string) => {
    let sortedData;
    switch (field) {
      case "com_ses":
        sortedData = clientDetails.sort((a: any, b: any) => a.meetingCount - b.meetingCount);
        setClientDetails(sortedData);
        break;
      case "inc_ses":
        sortedData = clientDetails.sort((a: any, b: any) => a.incompletedNotesCount - b.incompletedNotesCount);
        setClientDetails(sortedData);
        break;
      case "rem_ses":
        sortedData = clientDetails.sort((a: any, b: any) => a.remainingSessions - b.remainingSessions);
        setClientDetails(sortedData);
        break;
      default:
        break;
    }
  };

  const sortingNumberDesc = (field: string) => {
    let sortedData;
    switch (field) {
      case "com_ses":
        sortedData = clientDetails.sort((a: any, b: any) => b.meetingCount - a.meetingCount);
        setClientDetails(sortedData);
        break;
      case "inc_ses":
        sortedData = clientDetails.sort((a: any, b: any) => b.incompletedNotesCount - a.incompletedNotesCount);
        setClientDetails(sortedData);
        break;
      case "rem_ses":
        sortedData = clientDetails.sort((a: any, b: any) => b.remainingSessions - a.remainingSessions);
        setClientDetails(sortedData);
        break;
      default:
        break;
    }
  };

  function toggleSortingNumber(field: string) {
    return () => {
      if (sortType === "asc") {
        sortingNumberAsc(field);
        setSortType("desc");
      } else {
        sortingNumberDesc(field);
        setSortType("asc");
      }
    };
  }

  const sortingDateAsc = (field: string) => {
    let sortedData;
    switch (field) {
      case "prev_ses":
        sortedData = clientDetails.sort((a: any, b: any) => new Date(a.previousSession).getDate() - new Date(b.previousSession).getDate());
        setClientDetails(sortedData);
        break;
      case "next_ses":
        sortedData = clientDetails.sort((a: any, b: any) => new Date(a.nextSession).getDate() - new Date(b.nextSession).getDate());
        setClientDetails(sortedData);
        break;
      default:
        break;
    }
  };

  const sortingDateDesc = (field: string) => {
    let sortedData;
    switch (field) {
      case "prev_ses":
        sortedData = clientDetails.sort((a: any, b: any) => new Date(b.previousSession).getDate() - new Date(a.previousSession).getDate());
        setClientDetails(sortedData);
        break;
      case "next_ses":
        sortedData = clientDetails.sort((a: any, b: any) => new Date(b.nextSession).getDate() - new Date(a.nextSession).getDate());
        setClientDetails(sortedData);
        break;
      default:
        break;
    }
  };

  function toggleSortingDate(field: string) {
    return () => {
      if (sortType === "asc") {
        sortingDateAsc(field);
        setSortType("desc");
      } else {
        sortingDateDesc(field);
        setSortType("asc");
      }
    };
  }

  const seeMoreButton = () => {
    const updatedOffsetSkip = clientOffset + 1;
    setClientOffset(updatedOffsetSkip);
  };

  return (
    <>
      <AppointmentModal
        clientId={chattingWith}
        appointment={appointment}
        setAppointment={setAppointment}
        modalType={ModalType.CREATE}
        setShowModal={setShowAppointmentModal}
        showModal={showAppointmentModal}
        errorModalText={errorModalText}
        setErrorModalText={setErrorModalText}
        setShowErrorModal={setShowErrorModal}
        setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
        setShowModalForTherapist={setShowModalForTherapist}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        updateMatchedClient={() => { }}
        hourValue={hourValue}
        setHourValue={setHourValue}
        minuteValue={minuteValue}
        setMinuteValue={setMinuteValue}
        updateAppointmentObject={() => { }}
      />
      {isUploadDocumentsOpen && (
        <UploadDocuments userId={chattingWith} isOpened={isUploadDocumentsOpen} returnBool={(e: boolean) => setIsUploadDocumentsOpen(e)} />
      )}
      {currentPageComponents == 0 && (
        <React.Fragment>
          {showClinicalAssessmentWithVersionModal && (
            <Modal32
              setShowModal={setShowClinicalAssessmentWithVersionModal}
              showModal={showClinicalAssessmentWithVersionModal}
              clinicalAssessmentInfo={clinicalAssessmentInfo}
              openClinicalAssessment={clinicalAssessment}
              downloadClinicalAssessment={generateClinicalPdf}
              isMobile={isMobile}
              isAdmin={false}
              generateAiAssessmentFunction={generateAiAssessmentFunction}
              firstMeetingData={firstMeetingData}
              isMeeting={false}
            />
          )}
          {showClinicalAssessmentModal && (
            <ClinicalAssetmentModal
              setShowModal={setShowClinicalAssessmentModal}
              showModal={showClinicalAssessmentModal}
              identifierId={selectedClientId}
              isAdmin={false}
              isFromMeeting={false}
              setModalRefresher={refreshModal32}
            />
          )}
          {showNewClinicalAssessmentModal && (
            <NewClinicalAssessmentModal
              showModal={showNewClinicalAssessmentModal}
              identifierId={selectedClientId}
              setShowModal={setShowNewClinicalAssessmentModal}
              isFromMeeting={false}
              isAdmin={false}
              setModalRefresher={refreshModal32}
              aiGeneratedAssessmentData={null}
              isMeeting={false}
            />
          )}
          {showAiGeneratedAssessmentPopup && (
            <AIGeneratedAssessment
              OpenAiModel={showAiGeneratedAssessmentPopup}
              transcribeData={firstMeetingData}
              closeModelFunction={handleChildFunction}
              refreshModal32={refreshModal32}
              isInMeeting={false}
              clientId={selectedClientId}
            />
          )}
          {showTherapyPlanWithVersionModal && (
            <Modal31
              setShowModal={setShowTherapyPlanWithVersionModal}
              showModal={showTherapyPlanWithVersionModal}
              therapyPlanInfo={therapyPlanInfo}
              createNewTherapyPlan={createNewTherapyPlan}
              openTherapyPlan={therapyPlan}
              downloadTherapyPlan={generateTherapyPlanPdf}
              isMobile={isMobile}
              isAdmin={false}
            />
          )}
          {showTherapyPlanModal && (
            <TherapyPlanModal
              setShowModal={setShowTherapyPlanModal}
              showModal={showTherapyPlanModal}
              identifierId={therapyPlanId}
              isAdmin={false}
              isFromMeeting={false}
              isCreatingNewForm={isCreatingNewForm}
              setModalRefresher={refreshModal31}
            />
          )}
          {showTherapyPlanPdfModal && (
            <TherapyPlanPdfModal
              setShowModal={setShowTherapyPlanPdfModal}
              showModal={showTherapyPlanPdfModal}
              identifierId={therapyPlanId}
              isAdmin={false}
              eventType={eventType}
              insuranceDocApprovalId={null}
              setModalRefresher={null}
              therapyPlanType={therapyPlanType as TPType}
            />
          )}

          {modalOpenPriorAuthorization && (
            <Modal
              isOpen={modalOpenPriorAuthorization}
              toggle={() => setModalOpenPriorAuthorization(!modalOpenPriorAuthorization)}
              centered
              fullscreen
              style={{
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div
                className="modal-head"
                style={{
                  zIndex: 9998,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <div className="pre-auth-saas-header">
                  <h5 className="mb-0 font-size-25" style={{ color: "#f27e0c" }}>
                    Pre-Authorization
                  </h5>
                  <button type="button" onClick={closePriorAuthorization} className="bg-transparent-color" data-dismiss="modal" aria-label="Close">
                    <img src={Close} alt="Close" className="close-icon-d" />
                  </button>
                </div>
              </div>
              <ModalBody className={"px-2"}>
                <PreAuthorizationSaas clientId={selectedClientId} />
              </ModalBody>
            </Modal>
          )}

          {<ProfileModalSaas isOpen={modalOpenProfile} toggle={toggleModalProfile} clientId={selectedClientId} />}
          {showSessionHistoryModal && (
            <SessionHistoryModal setShowModal={setShowSessionHistoryModal} showModal={showSessionHistoryModal} userId={selectedClientId} />
          )}
          <div className="page-content">
            <Container fluid>
              <Card className="m-h">
                <CardBody>
                  <Row>
                    <Col className="d-flex admin-filter">
                      <button className="btn btn-sm mr-2" onClick={toggleFilter}>
                        Filter <i className="bx bx-filter-alt"></i>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    {isLoading ? (
                      <Col sm="12" className="mt10 d-flex justify-content-center align-items-center" style={{ minHeight: "25vh" }}>
                        <Spinner />
                      </Col>
                    ) : (
                      <Col sm="12" className="mt10">
                        <div className="table-responsive" style={{ minHeight: "70vh" }}>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                  <div className={"w-100 d-flex flex-row justify-content-start align-item-center"}>
                                    Name
                                    <span className={"ms-2"}>
                                      <i onClick={toggleSorting} className={"bx bx-sort"}></i>
                                    </span>
                                  </div>
                                </th>
                                <th scope="col" className="text-center">
                                  <div className={"w-100 d-flex flex-row justify-content-start align-item-center"}>
                                    Completed Sessions
                                    <span className={"ms-2"}>
                                      <i onClick={toggleSortingNumber("com_ses")} className={"bx bx-sort"}></i>
                                    </span>
                                  </div>
                                </th>
                                <th scope="col" className="text-center">
                                  <div className={"w-100 d-flex flex-row justify-content-start align-item-center"}>
                                    Incomplete Notes
                                    <span className={"ms-2"}>
                                      <i onClick={toggleSortingNumber("inc_ses")} className={"bx bx-sort"}></i>
                                    </span>
                                  </div>
                                </th>
                                <th scope="col" className="text-center">
                                  <div className={"w-100 d-flex flex-row justify-content-start align-item-center"}>
                                    Remaining Sessions
                                    <span className={"ms-2"}>
                                      <i onClick={toggleSortingNumber("rem_ses")} className={"bx bx-sort"}></i>
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className={"w-100 d-flex flex-row justify-content-start align-item-center"}>
                                    Previous Session
                                    <span className={"ms-2"}>
                                      <i onClick={toggleSortingDate("prev_ses")} className={"bx bx-sort"}></i>
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className={"w-100 d-flex flex-row justify-content-start align-item-center"}>
                                    Next Session
                                    <span className={"ms-2"}>
                                      <i onClick={toggleSortingDate("next_ses")} className={"bx bx-sort"}></i>
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>

                            {clientDetails.length === 0 ? (
                              <tbody>
                                <tr className={"d-flex justify-content-center align-items-center"}>No Clients Found</tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {clientDetails.map((data, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      {data?.clientDetails?.firstname} {data?.clientDetails?.lastname}
                                    </td>
                                    <td className="text-center">{data?.meetingCount}</td>
                                    <td className="text-center">{data?.incompletedNotesCount}</td>
                                    <td className="text-center">{data?.remainingSessions}</td>
                                    <td>{data?.previousSession ? new Date(data.previousSession).toLocaleDateString("en-CA") : "N/A"}</td>
                                    <td>{data?.nextSession ? new Date(data.nextSession).toLocaleDateString("en-CA") : "N/A"}</td>
                                    <td>{renderDropdown(index, data.clientDetails._id)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            )}
                          </table>
                          {seeMore && (
                            <div className="text-center">
                              <Button className="btn btn-warning text-center mt-4" onClick={seeMoreButton}>
                                See More
                              </Button>
                            </div>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
              {/* <AppointmentModal isOpen={modalOpenSession} toggle={toggleModalSession} setIsOpen={setModalOpenSession} clientData={clientData} /> */}
              {/* <MatchModal isOpen={modalOpenMatch} toggle={toggleModalMatch} clientData={clientData} /> */}
              <Offcanvas className="offcanvas-end2" isOpen={isFilterOptions} direction="end" toggle={toggleFilter}>
                <OffcanvasHeader toggle={toggleFilter}>Filter Clients</OffcanvasHeader>

                <OffcanvasBody>
                  <Row className="mt-4">
                    <Col lg={12}>
                      <FormGroup>
                        <Label>Search by Name, Email or Phone</Label>
                        <Input
                          id="search"
                          name="text"
                          placeholder="Search Users (ex: first/last name, email or phone)"
                          type="text"
                          onChange={e => setIsSearchableString(e.target.value)}
                          value={isSearchableString}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <Label>Appointment</Label>
                      <FormGroup>
                        <Input id="exampleSelect" name="select" type="select" onChange={e => setAppointmentStatus(e.target.value)} value={appointmentStatus}>
                          <option value="ALL"> All</option>
                          <option value="UPCOMMING_APPOINTMENT"> Upcoming Appointments</option>
                          <option value="NO_UPCOMMING_APPOINTMENT"> No Upcoming Appointments</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <Label>Active Status</Label>
                      <FormGroup>
                        <Input id="exampleSelect" name="select" type="select" onChange={e => setActiveClient(e.target.value)} value={activeClient}>
                          <option value=""> All</option>
                          <option value="ACTIVE"> Active Clients</option>
                          <option value="INACTIVE"> Inactive Clients</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <FormGroup>
                        <Label>Search by Zip Code</Label>
                        <Input id="search" name="text" placeholder="Search ZipCode" type="text" value={zipCode} onChange={e => setZipCode(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <Label>Gender</Label>
                      <ButtonGroup className="flex-wrap flex w-100">
                        <Button
                          color="primary"
                          defaultChecked
                          defaultValue={GenderTypes.ALL}
                          outline
                          onClick={() => setIsGender(GenderTypes.ALL)}
                          active={isGender === GenderTypes.ALL}
                        >
                          All
                        </Button>
                        <Button color="primary" outline onClick={() => setIsGender(GenderTypes.MALE)} active={isGender === GenderTypes.MALE}>
                          Male
                        </Button>
                        <Button color="primary" outline onClick={() => setIsGender(GenderTypes.FEMALE)} active={isGender === GenderTypes.FEMALE}>
                          Female
                        </Button>
                        <Button color="primary" outline onClick={() => setIsGender(GenderTypes.NonBinary)} active={isGender === GenderTypes.NonBinary}>
                          Non Binary
                        </Button>
                        <Button color="primary" outline onClick={() => setIsGender(GenderTypes.OTHER)} active={isGender === GenderTypes.OTHER}>
                          Other
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col lg={12}>
                      <Label>Verified Status</Label>
                      <FormGroup>
                        <Input id="exampleSelect" name="select" type="select" onChange={e => setStatus(e.target.value)} value={status}>
                          <option value={UserStatus.ALL}> All</option>
                          <option value={UserStatus.VERIFIED}> Verified</option>
                          <option value={UserStatus.PENDING}> Pending</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <Label>Subscription Status</Label>
                      <ButtonGroup className="w-100">
                        <Button
                          color="primary"
                          defaultChecked
                          defaultValue={SubscriptionStatus.ALL}
                          outline
                          onClick={() => setSubscription(SubscriptionStatus.ALL)}
                          active={isSubscription === SubscriptionStatus.ALL}
                        >
                          ALL
                        </Button>
                        <Button
                          color="primary"
                          outline
                          onClick={() => setSubscription(SubscriptionStatus.ACTIVE)}
                          active={isSubscription === SubscriptionStatus.ACTIVE}
                        >
                          Active
                        </Button>
                        <Button
                          color="primary"
                          outline
                          onClick={() => setSubscription(SubscriptionStatus.PAST_DUE)}
                          active={isSubscription === SubscriptionStatus.PAST_DUE}
                        >
                          Past due
                        </Button>
                        <Button
                          color="primary"
                          outline
                          onClick={() => setSubscription(SubscriptionStatus.CANCELED)}
                          active={isSubscription === SubscriptionStatus.CANCELED}
                        >
                          cancelled
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col lg={12}>
                      <Label>Insurance Availability</Label>
                      <FormGroup>
                        <Input
                          id="exampleSelect"
                          name="select"
                          type="select"
                          onChange={e => setInsuranceActiveStatus(e.target.value)}
                          value={insuranceActiveStatus}
                        >
                          <option value={InsuranceActiveStatus.ALL}> All</option>
                          <option value={InsuranceActiveStatus.WITH_INSURANCE}> With Insurance</option>
                          <option value={InsuranceActiveStatus.WITHOUT_INSURANCE}> Without Insurance</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <Label>Copayment Status</Label>
                      <FormGroup>
                        <Input id="exampleSelect" name="select" type="select" onChange={e => setCopaymentStatus(e.target.value)} value={copaymentStatus}>
                          <option value={CopaymentStatus.ALL}> All</option>
                          <option value={CopaymentStatus.EXISTING}> With Copayment</option>
                          <option value={CopaymentStatus.GREATER}> {`With Copayment (> 0)`} </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col lg={12}>
                      <button
                        className={`btn ${isLoading ? "btn-primary" : "btn-success"} w-100`}
                        onClick={() => {
                          if (!isLoading) {
                            filterClients();
                          } else {
                            toast.error(`Please wait !`, {
                              position: toast.POSITION.TOP_RIGHT,
                              className: "foo-bar",
                            });
                          }
                        }}
                      >
                        {isLoading ? "Loading..." : "Search"}
                      </button>
                    </Col>
                  </Row>
                </OffcanvasBody>
              </Offcanvas>
            </Container>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default AllClients;
