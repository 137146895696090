import React, { useState, useReducer, useMemo, useEffect, useContext } from "react";
import "antd/dist/antd.min.css";
import produce from "immer";
import ZoomContext from "./context/zoom-context";
import ZoomMediaContext from "./context/media-context";
import LiveTranscriptionContext from "./context/live-transcription";
import RecordingContext from "./context/recording-context";
import { LiveTranscriptionClient, MediaStream, RecordingClient, ZoomClient } from "./index-types";
import SupportGalleryViewContext from "./context/support-gallery-view-context";
import SessionContext from "./context/session-context";
import ZoomWebClient from "./ZoomWebClient";
import "./index.css";
import VideoButtonContext from "./context/video-button-context";
import { useHistory } from "react-router-dom";
import UserContext from "src/context/UserContext";
import { VideoChatService } from "src/services/VideoChatService";
import { IncompletedCall } from "src/models/IncompletedCall";
import { CallInitializeData } from "src/models/CallInitializeData";
import LocalCallContext from "src/context/LocalCallContext";
import CallInitializationContext from "src/context/CallInitializationContext";
import LoadingLayer from "./component/loading-layer";

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
  }
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case "audio-encode": {
      draft.audio.encode = action.payload;
      break;
    }
    case "audio-decode": {
      draft.audio.decode = action.payload;
      break;
    }
    case "video-encode": {
      draft.video.encode = action.payload;
      break;
    }
    case "video-decode": {
      draft.video.decode = action.payload;
      break;
    }
    case "share-encode": {
      draft.share.encode = action.payload;
      break;
    }
    case "share-decode": {
      draft.share.decode = action.payload;
      break;
    }
    case "reset-media": {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

const ZoomWeb: React.FC = () => {
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null);
  const [liveTranscriptionClient, setLiveTranscriptionClient] = useState<LiveTranscriptionClient | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true);
  const [zoomClient, setZoomClient] = useState<ZoomClient>();
  const [sessionName, setSessionName] = useState<string>();
  const [sessionPassword, setSessionPassword] = useState<string>();
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream, setMediaStream }), [mediaState, mediaStream, setMediaStream]);
  const [cameraButtonClick, setCameraButtonClick] = useState(false);

  // rejoin
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [loggedUser] = useContext(UserContext);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  useEffect(() => {
    initFunction();
  }, []);

  async function initFunction() {
    if (!localCallInitializeData) {
      history.push("/dashboard");
    }

    if (history && history.action && history.action != "PUSH") {
      try {
        if (loggedUser && (loggedUser?.role == "THERAPIST" || loggedUser?.role == "CLIENT")) {
          const res: any = await VideoChatService.checkForNearestAppointment();
          if (res && res.success && res.data && res.data.incompleteCallData && res.data.incompleteCallData.length && res.data.incompleteCallData.length > 0) {
            const callData: IncompletedCall = res.data.incompleteCallData[0];
            // if ((loggedUser != null && callData.createdBy == loggedUser?._id) || (loggedUser != null && callData.accepted)) {
            // }
            if (loggedUser?._id == callData.clientId._id || loggedUser?._id == callData.therapistId._id) {
              const callInitializeData: CallInitializeData = {
                start: false,
                isInstantMeeting: false,
                isAppointmentBased: callData.isAppointmentBased,
                appointmentId: callData.appointmentId ?? "",
                recieverId: loggedUser?.role == "THERAPIST" ? callData.clientId._id : callData.therapistId._id,
                meetingTime: callData.meetingDuration,
                isTranscribeAllowed: true,
                meetingId: callData._id,
                isAudioCall: false,
              };
              // setLocalCallInitialize(true);
              setLocalCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              setLoading(false);
            } else {
              history.push("/dashboard");
              setLoading(false);
            }
          } else {
            history.push("/dashboard");
            setLoading(false);
          }
        } else {
          history.push("/dashboard");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        history.push("/dashboard");
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <VideoButtonContext.Provider value={[cameraButtonClick, setCameraButtonClick]}>
        <ZoomContext.Provider value={{ zoomClient, setZoomClient }}>
          <SessionContext.Provider value={{ sessionName, setSessionName, sessionPassword, setSessionPassword }}>
            <ZoomMediaContext.Provider value={mediaContext}>
              <SupportGalleryViewContext.Provider value={{ isSupportGalleryView, setIsSupportGalleryView }}>
                <RecordingContext.Provider value={{ recordingClient, setRecordingClient }}>
                  <LiveTranscriptionContext.Provider value={{ liveTranscriptionClient, setLiveTranscriptionClient }}>
                    {loading && <LoadingLayer content={"Joining"} />}
                    {!loading && <ZoomWebClient dispatch={dispatch} />}
                  </LiveTranscriptionContext.Provider>
                </RecordingContext.Provider>
              </SupportGalleryViewContext.Provider>
            </ZoomMediaContext.Provider>
          </SessionContext.Provider>
        </ZoomContext.Provider>
      </VideoButtonContext.Provider>
    </React.Fragment>
  );
};

export default ZoomWeb;
