import DOMPurify from "dompurify";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import UserContext from "../../../context/UserContext";
import { ViewArticleModel } from "../../../models/Article";
import likeButton from "../../../assets/images/article/like2.svg";
import likedButton from "../../../assets/images/article/like.svg";
import { Util } from "../../../Util";

interface ArticleGridProps {
  articleDetails: ViewArticleModel[];
}

const ArticleGridPane: React.FC<ArticleGridProps> = ({ articleDetails }: ArticleGridProps) => {
  const [userDetails] = useContext(UserContext);
  const nav = useHistory();
  const Listview = "grid";
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const [scrollActive, setScrollActive] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    const position = sessionStorage.getItem("position");

    if (scrollActive) {
      if (position) {
        const positionid = JSON.parse(position);
        if (positionid.position > scrollPosition || (scrollPosition == 0 && positionid.position != 0)) {
          window.scrollTo({
            top: positionid.position,
          });
        }
      }
      setScrollActive(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const navi = (articleId: any) => {
    const scrolldata = { position: scrollPosition.toString(), setPosition: true };
    sessionStorage.setItem("position", JSON.stringify(scrolldata));
    nav.push({ pathname: "/article-details/" + articleId + "/" + Listview });
  };

  return (
    <Row>
      <hr className="mb-4 mt-2" />
      {articleDetails.map((item, index) => {
        return (
          <Col sn={6} className="flex-list odd-child" key={index}>
            <Card className="p-1 border shadow-none" style={{ display: "flex", flex: "50% 1" }}>
              <div className="card-content">
                <div className="p-3">
                  <h5 className="article-title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.articleTitle.substring(0, 30) + `${item.articleTitle.length > 30 ? "..." : ""}`, {
                          FORBID_TAGS: ["h2"],
                        }),
                      }}
                    ></div>
                  </h5>
                  <p className="text-muted mb-0">{moment(item.createdAt.toString()).format("YYYY/MM/DD")}</p>
                </div>

                {item.uploadId && item.uploadId.type.indexOf("image") !== -1 ? (
                  <div className="position-relative">
                    <div
                      style={{
                        backgroundImage: `url(${Util.fileURL(item.uploadId._id)})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "225px",
                        textAlign: "right",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => navi(item._id as any)}
                    >
                      <i className="fa fa-picture-o video-icon2"></i>
                    </div>
                  </div>
                ) : (
                  <div className="position-relative">
                    <div
                      style={{
                        backgroundImage: `url(https://vumbnail.com/${item.vimeoId}.jpg)`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "225px",
                        textAlign: "right",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => navi(item._id as any)}
                    >
                      <i className="fa fa-video-camera video-icon2"></i>
                    </div>
                  </div>
                )}

                <div className="p-3">
                  <ul className="list-inline">
                    {item.likedBy?.includes(userDetails?._id as any) ? (
                      <li className="list-inline-item me-3">
                        <img src={likedButton} height={"15px"} width={"15px"} />
                        &nbsp;
                        {item.likedBy?.length} Likes
                      </li>
                    ) : (
                      <li className="list-inline-item me-3">
                        <img src={likeButton} height={"15px"} width={"15px"} />
                        &nbsp;
                        {item.likedBy?.length} Likes
                      </li>
                    )}
                    <li className="list-inline-item me-3">
                      <i className="bx bx-comment-dots align-middle text-muted me-1"></i>
                      &nbsp;
                      {item.comments.length} Comments
                    </li>
                  </ul>
                  <p></p>
                  <div
                    className="card-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.articleBody.substring(0, 300) + `${item.articleBody.length > 300 ? "..." : ""}`, {
                        FORBID_TAGS: ["h1", "h2", "h3", "h4"],
                      }),
                    }}
                  ></div>

                  <button onClick={() => navi(item._id as any)} style={{ color: "#FD7F00" }}>
                    Read more <i className="mdi mdi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default ArticleGridPane;
