import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { AuthFormType } from "src/models/AuthorizationFormData";
import PhoneInput from "react-phone-number-input";
import { CarolinaCompleteHealthAuthForm } from "src/models/AuthorizationForm/carolina-complete-health-auth-form-model";
import moment from "moment";

const CarolinaCompleteHealthAuthFormPdf: React.FC<{ authorizationFormType: AuthFormType; authorizationFormData: CarolinaCompleteHealthAuthForm; }> = props => {
  const authorizationFormType = props.authorizationFormType;
  const authorizationFormData = props.authorizationFormData;
  const [formData, setFormData] = useState<CarolinaCompleteHealthAuthForm>();

  useEffect(() => {
    const updateCarolinaCompleteHealthData: CarolinaCompleteHealthAuthForm = {
      generalInformation: authorizationFormData?.generalInformation,
      serviceInformation: authorizationFormData?.serviceInformation,
      providerInformation: authorizationFormData?.providerInformation,
      prescibingProviderInformation: authorizationFormData?.prescibingProviderInformation,
      denialReason: authorizationFormData?.denialReason,
      date: authorizationFormData?.date,
      signature: authorizationFormData?.signature,
    };
    setFormData(updateCarolinaCompleteHealthData);
  }, [authorizationFormData]);

  // const formatDate = (date: any) => {
  //   if (!date) return '';
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0');
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment.utc(date).format('YYYY-MM-DD');
  };

  const sharedStyle: React.CSSProperties = {
    fontSize: "11px",
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    lineHeight: '1'
  };

  const sharedStyleForTable: React.CSSProperties = {
    fontSize: "0.70rem",
    width: '100%',
    padding: '2px',
    border: 'none',
    resize: 'none',
  };

  return (
    <>
      {authorizationFormType === AuthFormType.CarolinaCompleteHealthAuthForm && (
        <>
          <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
            <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
              <Row className="align-items-center pt-2">
                <Col xs="12" sm="5" className="text-center text-sm-start">
                  <div>
                    <img
                      src="/static/assets/img/authorization/carolina_complete_health.png"
                      alt="Carolina Complete Health Insurance Company Logo"
                      className="img-fluid"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                </Col>
                <Col xs="12" sm="7" className="text-center text-sm-end">
                  <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                    <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request Form</span><br />
                  </p>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #B2B2B2", margin: "0" }} />
              <Row>
                <div className="card-bodyDiv" style={{ width: '100%' }}>

                  <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                    <p style={{ margin: "0" }}>
                      Authorization approves the medical necessity of the requested service only. It does not
                      guarantee payment, nor does it guarantee that the amount billed will be the amount
                      reimbursed. The beneficiary must be Medicaid or NC Health Choice eligible and a Carolina
                      Complete Health member on the date of service.
                    </p>
                  </div>

                </div>
              </Row>
            </Card>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>General Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3" >
                          <label>Name (last, first, M.I.) :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <textarea
                              readOnly
                              value={formData?.generalInformation?.memberName || ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>Date of birth :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formatDate(formData?.generalInformation?.dateOfBirth)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>NC Medicaid ID Number :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.generalInformation?.medicaidID || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Address (street, city, state, ZIP code) :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.generalInformation?.memberAddress || ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                          <label>Diagnosis code :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.generalInformation?.diagnosisCode || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Diagnosis description :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.generalInformation?.diagnosisDescription || ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="7">
                          <label>Name and address of facility where services are to be rendered, if other than home or office :</label>
                        </Col>
                        <Col xs="12" sm="5">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.generalInformation?.facilityName || ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Service Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <Table responsive className="clinical-table-bordered table-md">
                      <thead>
                        <tr>
                          <th rowSpan={2} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}  >
                            <div className="d-flex align-items-center justify-content-center">
                              Ref. #
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                            <div className="d-flex align-items-center justify-content-center">
                              Procedure code
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                            <div className="d-flex align-items-center justify-content-center">
                              From
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                            <div className="d-flex align-items-center justify-content-center">
                              Through
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2} >
                            <div className="d-flex align-items-center justify-content-center">
                              Description of service/item
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                            <div className="d-flex align-items-center justify-content-center">
                              Qty. or units
                            </div>
                          </th>
                          <th colSpan={3} style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.3" }}>
                            For Plan Use Only
                          </th>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.7", fontSize: "11px" }}>
                            APPR.
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "0.7", fontSize: "11px" }}>
                            Denied
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle", lineHeight: "1", fontSize: "11px" }}>
                            Amount Allowed if
                            Priced by Report
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(10)].map((_, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid #B2B2B2", position: 'relative' }}>
                              <div style={{ position: 'absolute', top: '0', left: '0', padding: '4px', textAlign: 'center' }}>
                                ({index + 1})
                              </div>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1", paddingLeft: '30px' }}
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.referenceNumber || ''}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.procedureCode || ''}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.startDate)}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.endDate)}
                              />
                            </td>
                            <td>
                            <textarea
                              readOnly
                              value={formData?.serviceInformation?.serviceInformationArray?.[index]?.serviceDescription || ''}
                              style={sharedStyleForTable}
                            />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.itemQuantity || ''}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.approved || ''}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.denied || ''}
                              />
                            </td>
                            <td style={{ border: "1px solid #B2B2B2", textAlign: 'center', padding: "1px", lineHeight: "0.3" }}>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "0.5" }}
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.allowedAmount || ''}
                              />
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row className="align-items-center mb-2">
                      <Col xs="12" sm="7" className="mt-2 mt-sm-2">
                        <label>Detailed explanation of medical necessity for services, equipment, procedures or prostheses (attach additional pages if necessary) :</label>
                      </Col>
                      <Col xs="12" sm="5">
                        <div className="d-flex align-items-center">
                        <textarea
                              readOnly
                              value={formData?.serviceInformation?.necessityExplanation || ''}
                              style={sharedStyle}
                            />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Provider</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Provider name :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.providerInformation?.providerName || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Address  :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.providerInformation?.providerAddress || ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>NPI and TAX ID :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.providerInformation?.npi || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Fax number :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.providerInformation?.faxNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">

                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prescribing or performing provider</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Name :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescibingProviderInformation?.providerName || ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.prescibingProviderInformation?.phone ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  prescibingProviderInformation: {
                                    ...formData?.prescibingProviderInformation,
                                    phone: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Address :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                          <textarea
                              readOnly
                              value={formData?.prescibingProviderInformation?.providerAddress || ''}
                              style={sharedStyle}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>NPI and TAX ID :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescibingProviderInformation?.npi || ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>
                          <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                              <p style={{ margin: "0" }}>
                                By submitting this form, the provider identified in this Section IV certifies
                                that the information given in Sections I through III of this form are true,
                                accurate and complete.
                              </p>
                            </div>
                          </Card>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>For Plan Use Only</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="5">
                          <label>Denial Reason(s): Refer to table above by reference numbers (REF NO.) :</label>
                        </Col>
                        <Col xs="12" sm="7">
                        <textarea
                            readOnly
                            value={formData?.denialReason || ''}
                            style={sharedStyle}
                            />
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2" style={{ backgroundColor: "#F1F1F1", border: "none", borderRadius: "15px", padding: "10px" }}>
                        <Col xs="12" sm="2" className="d-flex align-items-center">
                          <label>IF APRROVED :</label>
                        </Col>
                        <Col xs="12" sm="3" className="d-flex align-items-center">
                          <label>Services Authorized to Begin</label>
                        </Col>
                        <Col xs="12" sm="7">
                          <div className="row justify-content-between" style={{ textAlign: "center" }}>
                            <div className="col-lg-5 col-md-12 m-2">
                              <div className="d-flex flex-column justify-content-between">
                                <div className="sign">
                                  {formData?.signature && (
                                    <img className="sigCanvasNew" src={formData?.signature} />
                                  )}
                                </div>
                              </div>
                              <h6 className="fw-500">Reviewed by Signature</h6>
                            </div>
                            <div className="col-lg-4 col-md-12 m-2 signatureAssesment1">
                              <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                value={formatDate(formData?.date)}
                              />
                              <h6 className="fw-500">Date</h6>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default CarolinaCompleteHealthAuthFormPdf;
