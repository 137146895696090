import React, { useState,useContext,useEffect } from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";
import UserContext from "src/context/UserContext";
import './Modal6.scss'

const Modal6ForChatComp: React.FC<{
  showModal: boolean;
  setShowModal: (time: number, showModal: boolean, getCall: boolean, isVideoCall: boolean, isVonageNativeVideoCall: boolean) => void;
}> = props => {
  const [user] = useContext(UserContext);

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(0, false, false, true, false)} style={{fontSize: '12px',width: '20px',height: '20px',padding: '2px',margin: '2px'}} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">Select Meeting Time</CardTitle>
            
            <div className="d-flex justify-content-center flexColumn">
              <button type="button" onClick={() => props.setShowModal(60, false, true, true, false)} className="btn btn-primary mr-20 btnMargin callBtn">
                Express video call
              </button>
              
              {/* <button type="button" onClick={() => props.setShowModal(60, false, true, false, true)} className="btn btn-primary mr-20  btnMargin  callBtn">
                Native video call
              </button> */}

              <button type="button" onClick={() => props.setShowModal(60, false, true, false, false)} className="btn btn-primary btnMargin callBtn">
                Start audio call
              </button>
            </div>
            {/* <div className="d-flex justify-content-between flexColumn mt-3">
              <div className="d-flex">
                <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
                  Allow Call Transcript
                </h5>

                <div className="form-check form-switch form-switch-lg">
                  <label className="form-check-label" htmlFor="newsletter">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="newsletter"
                      checked={allowTranscribe}
                      // checked={user?.callRecordingAllowed != null && user?.callRecordingAllowed == true ? true : false}
                      onChange={e => {}}
                      onClick={() => {
                        setAllowTranscribe(!allowTranscribe)
                        // changeAllowRecording(user?.callRecordingAllowed != null ? !user?.callRecordingAllowed : true);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div> */}
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal6ForChatComp;

