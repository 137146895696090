import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap"
import SoonTag from "../../../common/tags/Soon"
import ApexRadial from "../../../components/Common/ApexRadial"

interface AppointmentsProps {
  statistics: any
}

const AllAppointments: React.FC<AppointmentsProps> = ({statistics}) => {

  const [isAppointmentStatistics, setAppointmentStatistics] = useState([] as any);

  useEffect(() => {
    setAppointmentStatistics(statistics)
  }, [statistics])
  
  const titleCase = (str: string) => {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()).replace(/_/g, " ");
  }

    return (
      <React.Fragment>
        
        <Card className="overflow-auto">
          <CardBody>
            <CardTitle className="mb-4">Appointment Details</CardTitle>
            <Row>
              {
                isAppointmentStatistics.map((obj: any, i: number) => {
                  return (
                    <Col sm="3 text-center" key={i}>
                      <h3>{obj.count}</h3>
                      <p className="text-muted">{titleCase(obj.status)} </p>
                  </Col>
                  )
                })
              }
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
  
  export default AllAppointments