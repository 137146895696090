import React from "react";
import { CardTitle, Modal } from "reactstrap";

const Modal2: React.FC<{ trigger?: boolean; setTrigger?: any; header?: string }> = (props) => {
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">

                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <CardTitle className="mb-4 text-center">{props.header}</CardTitle>
                </div>
            </Modal>
        </>
    ) : null;
}

export default Modal2;