import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Input, Label, UncontrolledTooltip, Modal, Button, ModalBody } from "reactstrap";
import { AdminService } from "../../services/AdminService";
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ClientInsuranceDocs, FaxInfo, SubmissionApprovalStatus, UploadCategory, UploadedInsuranceDocument } from "src/models/ClientInsuranceDocs";
import ClinicalAssetmentModal from "../Popup/ClinicalAssessmentModal";
import TherapyPlanModal from "../Popup/TherapyPlanModal";
import ClinicalAssessmentPdfModal from "../Popup/ClinicalAssessmentPdfModal";
import Close from "../../assets/images/icons/close.png";
import TherapyPlanPdfModal from "../Popup/TherapyPlanPdfModal";
import AmeriHealthModal from "../Popup/AmeriHealthModal";
import CarolinaCompleteHealthModal from "../Popup/CarolinaCompleteHealthModal";
import UnitedHealthCareModal from "../Popup/UnitedHealthCareModal";
import HealthBlueModal from "../Popup/HealthBlueModal";
import WellCareModal from "../Popup/WellCareModal";
import AmbetterModal from "../Popup/AmbetterModal";
import { AuthFormType } from "src/models/AuthorizationFormData";
import AuthorizationFormPdfModal from "../Popup/AuthorizationFormPdfModal";
import Select from "react-select";
import { AppResponse } from "src/models/Response";
import { InsuranceCompanyTags } from "src/models/InsuranceCompany";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { FiAlertCircle, FiAlertTriangle, FiCheckCircle, FiClock, FiFile, FiMessageSquare, FiPhone } from "react-icons/fi";
import moment from "moment";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import NewClinicalAssessmentModal from "../Popup/NewClinicalAssessmentModal";
import NewClinicalAssessmentPdfModal from "../Popup/NewClinicalAssessmentPdfModal";
import { CAFormType } from "src/models/clinicalAssesment/clinicalAssesment";

const ApprovalQueue: React.FC = () => {
  const limit = 10;
  const [insuranceDocs, setInsuranceDocs] = useState<ClientInsuranceDocs[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [clientSearchableString, setClientSearchableString] = useState("");
  const [therapistSearchableString, setTherapistSearchableString] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = useState<boolean>(false);
  const [showTherapyPlanModal, setShowTherapyPlanModal] = useState<boolean>(false);
  const [identifierId, setIdentifierId] = useState("");
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showTherapyPlanPdfModal, setShowTherapyPlanPdfModal] = useState<boolean>(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState("");
  const [selectedTherapyPlanId, setSelectedTherapyPlanId] = useState("");
  const [selectedAuthorizationFormId, setSelectedAuthorizationFormId] = useState("");
  const [insuranceDocApprovalId, setInsuranceDocApprovalId] = useState("");
  const [isOpenView, setIsOpenView] = useState(false);
  const [uploadedDocInfo, setUploadedDocInfo] = useState<UploadedInsuranceDocument>();
  const [clinicalAssessmentId, setClinicalAssessmentId] = useState("");
  const [therapyPlanId, setTherapyPlanId] = useState("");
  const [authorizationFormId, setAuthorizationFormId] = useState("");
  const [docApprovalId, setDocApprovalId] = useState("");
  const [showAmeriHealthModal, setshowAmeriHealthModal] = useState<boolean>(false);
  const [showCarolinaCompleteHealthModal, setshowCarolinaCompleteHealthModal] = useState<boolean>(false);
  const [showUnitedHealthCareModal, setshowUnitedHealthCareModal] = useState<boolean>(false);
  const [showHealthBlueModal, setshowHealthBlueModal] = useState<boolean>(false);
  const [showWellCareModal, setshowWellCareModal] = useState<boolean>(false);
  const [showAmbetterModal, setshowAmbetterModal] = useState<boolean>(false);
  const [showAuthorizationFormPdfModal, setShowAuthorizationFormPdfModal] = useState<boolean>(false);
  const [authorizationFormType, setAuthorizationFormType] = useState("");
  const [InsuranceCompanyOptions, setInsuranceCompanyOptions] = useState([{ value: '', label: 'All' },]);
  const [searchableInsuranceCompany, setSearchableInsuranceCompany] = useState('');
  const [faxInfo, setFaxInfo] = useState<FaxInfo>();
  const [isOpenFaxView, setIsOpenFaxView] = useState(false);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState("");
  const [therapyPlanType, setTherapyPlanType] = useState("");
  const [clinicalAssessmentType, setClinicalAssessmentType] = useState("");
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [selectedCAFormType, setSelectedCAFormType] = useState("");
  const eventType = "UPLOAD";

  useEffect(() => {
    loadInitialData();
    getAllInsuranceCompanies();
  }, []);

  useEffect(() => {
    if (selectedAssessmentId && selectedAssessmentId != "" && insuranceDocApprovalId && insuranceDocApprovalId!= null) {
      setShowTherapyPlanPdfModal(false);
      setShowAuthorizationFormPdfModal(false);

      if (selectedCAFormType === CAFormType.OLD_CA) {
        setShowNewClinicalAssessmentPdfModal(false);
        setShowClinicalAssessmentPdfModal(true);
      } else if (selectedCAFormType === CAFormType.NEW_CA) {
        setShowClinicalAssessmentPdfModal(false);
        setShowNewClinicalAssessmentPdfModal(true);
      }

    }
  }, [selectedAssessmentId, insuranceDocApprovalId]);

  useEffect(() => {
    if (selectedTherapyPlanId && selectedTherapyPlanId != "" && insuranceDocApprovalId && insuranceDocApprovalId!= null) {
      setShowTherapyPlanPdfModal(true);
      setShowClinicalAssessmentPdfModal(false);
      setShowAuthorizationFormPdfModal(false);
    }
  }, [selectedTherapyPlanId, insuranceDocApprovalId]);

  useEffect(() => {
    if (selectedAuthorizationFormId && selectedAuthorizationFormId != "" && insuranceDocApprovalId && insuranceDocApprovalId!= null) {
      setShowAuthorizationFormPdfModal(true);
      setShowClinicalAssessmentPdfModal(false);
      setShowTherapyPlanPdfModal(false);
    }
  }, [selectedAuthorizationFormId, insuranceDocApprovalId]);

  const closeAssessmentModal = (value: boolean) => {
    setSelectedAssessmentId("");
    setInsuranceDocApprovalId("");
    setSelectedCAFormType("");
    setShowClinicalAssessmentPdfModal(value);
  };

  const closeNewAssessmentModal = (value: boolean) => {
    setSelectedAssessmentId("");
    setInsuranceDocApprovalId("");
    setSelectedCAFormType("");
    setShowNewClinicalAssessmentPdfModal(value);
  };

  const closeTherapyPlanModal = (value: boolean) => {
    setSelectedTherapyPlanId("");
    setInsuranceDocApprovalId("");
    setShowTherapyPlanPdfModal(value);
  };

  const closeAuthorizationFormModal = (value: boolean) => {
    setSelectedAuthorizationFormId("");
    setInsuranceDocApprovalId("");
    setShowAuthorizationFormPdfModal(value);
  };

  const setUploadedModal = async (value: boolean) => {
    if (value) {
      const result = await fetchInsuranceDocStatus(docApprovalId);
      if (result.success) {
        setUploadedDocInfo(result.data);
      }
    }
  };

  const loadInitialData = async () => {
    try {
        setInProgress(true);

        const data = {
          clientSearchableString: clientSearchableString,
          therapistSearchableString: therapistSearchableString,
          searchableInsuranceCompany: searchableInsuranceCompany
        };

        const res = await AdminService.searchInsuranceDocumentsByCondition(data, limit, 0);

        if (res && res?.success) {
            setInsuranceDocs(res?.data);
            setSeeMore(res?.data.length >= limit);
            setIsLoading(false);
        } else {
            toast.error(res?.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
        setInProgress(false);
    } catch (error) {
        setInProgress(false);
        toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });
    }
  }

  const filterInsuranceDocument = async () => {
    try {
      setIsLoading(true);

      const data = {
        clientSearchableString: clientSearchableString,
        therapistSearchableString: therapistSearchableString,
        searchableInsuranceCompany: searchableInsuranceCompany
      };

      const res = await AdminService.searchInsuranceDocumentsByCondition(data, limit, 0);

      if (res && res?.success && res?.data) {
          setInsuranceDocs(res?.data);
          setSeeMore(res?.data.length >= limit);
      } else {
          toast.error("Failed to fetch insurance documents data!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
      }
      setIsLoading(false);
      toggleFilter();

    } catch (error) {
        setIsLoading(false);
        toast.error("Error fetching insurance documents data!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const seeMoreData = async () => {
    try {
        if(!inProgress){
            setInProgress(true);
            setIsLazyLoading(true);

            const finalOffset = insuranceDocs?.length ?? 0;

            const data = {
              clientSearchableString: clientSearchableString,
              therapistSearchableString: therapistSearchableString,
              searchableInsuranceCompany: searchableInsuranceCompany
            };

            const res = await AdminService.searchInsuranceDocumentsByCondition(data, limit, finalOffset);
            if (res && res?.success && res?.data) {
              setInsuranceDocs(prev => {
                  return [...new Set([...prev, ...res.data])];
              });
              setSeeMore(res?.data.length >= limit);
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
            setInProgress(false);
            setIsLazyLoading(false);

        } else {
            toast.error("Please wait!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    } catch (error) {
        setInProgress(false);
        setIsLazyLoading(false);
        toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });
    }
  }

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  }

  const handleClinicalAssessmentClick = (identifierId: any, selectedCAFormType: CAFormType) => {
    setIdentifierId(identifierId);
    setSelectedCAFormType(selectedCAFormType);
    setShowNewClinicalAssessmentModal(false);
    setShowClinicalAssessmentModal(true);
  };

  const handleDigitalAssessmentClick = (identifierId: any, selectedCAFormType: CAFormType) => {
    setIdentifierId(identifierId);
    setSelectedCAFormType(selectedCAFormType);
    setShowClinicalAssessmentModal(false);
    setShowNewClinicalAssessmentModal(true);
  };

  const handleClinicalUploadClick = (formId: string, docId: string) => {
    setSelectedAssessmentId(formId);
    setInsuranceDocApprovalId(docId);
  };

  const therapyPlanForm = (identifierId: any) => {
    setIdentifierId(identifierId);
    setShowTherapyPlanModal(true);
  };

  const handleAuthorizationFormUploadClick = (authorizationFormId: string, docApprovalId: string, authorizationFormType: AuthFormType) => {
    setSelectedAuthorizationFormId(authorizationFormId);
    setInsuranceDocApprovalId(docApprovalId);
    setAuthorizationFormType(authorizationFormType);
  };

  const handleAuthorizationClick = (identifierId: string, authFormType: AuthFormType, relatedInsuranceCompanyId: string) => {
    setIdentifierId(identifierId);
    setInsuranceCompanyId(relatedInsuranceCompanyId);

    switch (authFormType) {
      case AuthFormType.AmeriHealthAuthForm:
        setshowAmeriHealthModal(true);
        break;
      case AuthFormType.CarolinaCompleteHealthAuthForm:
        setshowCarolinaCompleteHealthModal(true);
        break;
      case AuthFormType.UnitedHealthCareAuthForm:
        setshowUnitedHealthCareModal(true);
        break;
      case AuthFormType.HealthyBlueAuthForm:
        setshowHealthBlueModal(true);
        break;
      case AuthFormType.WellcareAuthForm:
        setshowWellCareModal(true);
        break;
      case AuthFormType.AmbetterAuthForm:
        setshowAmbetterModal(true);
        break;
      default:
        break;
    }
  };

  const sendReminderNotice = (e: any) => {
    const therapistId = e.currentTarget.getAttribute("data-therapist-id");
    const clientId = e.currentTarget.getAttribute("data-client-id");
    const formType = e.currentTarget.getAttribute("data-form-type");

    const capitalizeWords = (str: string) => {
      return str
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const formattedFormType = formType ? capitalizeWords(formType) : '';

    Swal.fire({
        icon: "info",
        title: "Are you sure you want to send a reminder email to the therapist?",
        html: formattedFormType ? `<p>Form Type: ${formattedFormType}</p>` : '',
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            therapistId: therapistId,
            clientId: clientId,
            formType: formType,
            formName: formattedFormType
          }
          AdminService.sendReminderEmailToTherapistRegardingIncompletedForm(data).then(res => {
              if (res.success) {
                  toast.success(res.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: 'foo-bar'
                  });
              } else {
                  toast.error(res.error, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: 'foo-bar'
                  });
              }
          });
        }
    });
  }

  const toggleModalForView = () => {
    setClinicalAssessmentId("");
    setTherapyPlanId("");
    setAuthorizationFormId("");
    setDocApprovalId("");
    setAuthorizationFormType("");
    setSelectedCAFormType("");
    setIsOpenView(!isOpenView);
  }

  const proceedForApproval = async (insuranceDocApprovalId: any, clinicalAssessment: any, therapyPlan: any, authorizationForm: any, authFormType: AuthFormType, relatedCAFormType: CAFormType) => {
    if (insuranceDocApprovalId != undefined) {
      setIsLoading(true);
      setClinicalAssessmentId(clinicalAssessment);
      setTherapyPlanId(therapyPlan);
      setAuthorizationFormId(authorizationForm);
      setDocApprovalId(insuranceDocApprovalId);
      setAuthorizationFormType(authFormType);
      setSelectedCAFormType(relatedCAFormType);

      const result = await fetchInsuranceDocStatus(insuranceDocApprovalId);
      if (result.success) {
        setUploadedDocInfo(result.data);
      }

      setIsLoading(false);
      setIsOpenView(true);
    }
  };

  const fetchInsuranceDocStatus = async (insuranceDocApprovalId: string) => {
    try {
      const res = await AdminService.getUploadedInsuranceDocStatus(insuranceDocApprovalId);
      if (res && res?.success && res?.data) {
        return { success: true, data: res.data };
      } else {
        toast.error("Encountered an error while retrieving the document approval details!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const finalSubmission = (e: any) => {
    const insuranceDocApprovalId = e.currentTarget.getAttribute("target-data-id");
    Swal.fire({
        icon: "info",
        title: "Are you sure want to approve and submit this?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            insuranceDocApprovalId: insuranceDocApprovalId,
            adminApprovalStatus: SubmissionApprovalStatus.APPROVED
          }
          AdminService.adminApprovalForInsuranceDocumentsFaxing(data).then(res => {
            if (res && res.success && res.data) {
              setUploadedDocInfo(res.data);
              loadInitialData();
              toggleModalForView();
              toast.success("Successfully approved!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            } else {
              toast.error(res.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            }
          });
        }
    });
  }

  const handleChange = (selectedOption: any) => {
    setSearchableInsuranceCompany(selectedOption.value);
  };

  const customStyles = {
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '170px',
      overflowY: 'auto'
    })
  };

  const getAllInsuranceCompanies = () => {
    AuthorizationFormService.getAllInsuranceCompanyList().then((res: AppResponse<InsuranceCompanyTags[]>) => {
      if (res && res.success && res.data) {
        const dataList = res.data?.map((insuranceCompany: any) => ({
          value: insuranceCompany._id,
          label: insuranceCompany.insuranceCompany,
        }));
        InsuranceCompanyOptions.push(...dataList);
      }
    });
  };

  const copyToClipboard = (email: any) => {
    navigator.clipboard.writeText(email)
    .then(() => {
      toast.success("Email copied to clipboard!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    })
    .catch(() => {
      toast.error("Email is not copied. Try again!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    });
  };

  const handleFaxInfoView = async (insuranceDocApprovalId: any) => {
    if (insuranceDocApprovalId != undefined) {
      setIsLoading(true);
      setDocApprovalId(insuranceDocApprovalId);

      const result = await fetchFaxInformation(insuranceDocApprovalId);
      if (result.success) {
        setFaxInfo(result.data);
      }
      setIsLoading(false);
      setIsOpenFaxView(true);
    }
  };

  const fetchFaxInformation = async (insuranceDocApprovalId: string) => {
    try {
      const res = await AuthorizationFormService.getIndividualFaxInfo(insuranceDocApprovalId);
      if (res && res?.success && res?.data) {
        return { success: true, data: res.data };
      } else {
        toast.error("Encountered an error while retrieving the fax details!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const toggleModalForFaxView = () => {
    setDocApprovalId("");
    setFaxInfo(undefined);
    setIsOpenFaxView(!isOpenFaxView);
  }

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };

  const getFaxStatusColor = (status: string | undefined) => {
    switch (status) {
      case 'Queued':
        return 'text-warning';
      case 'Sent':
        return 'text-success';
      case 'SendingFailed':
        return 'text-danger';
      default:
        return 'text-warning';
    }
  };

  const showReasonMessage = () => {
    Swal.fire({
      title: "Submission Not Allowed",
      text: "This insurance company does not provide an authorization form or a fax number, making it impossible to submit the request",
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  return (
    <>
      <React.Fragment>
        {showClinicalAssessmentModal && <ClinicalAssetmentModal setShowModal={setShowClinicalAssessmentModal} showModal={showClinicalAssessmentModal} identifierId={identifierId} isAdmin={true} isFromMeeting={false} setModalRefresher={null} />}
        {showClinicalAssessmentPdfModal && <ClinicalAssessmentPdfModal setShowModal={closeAssessmentModal} showModal={showClinicalAssessmentPdfModal} identifierId={selectedAssessmentId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={insuranceDocApprovalId} setModalRefresher={setUploadedModal} clinicalAssessmentType={clinicalAssessmentType as TPType} />}
        {showNewClinicalAssessmentModal && <NewClinicalAssessmentModal setShowModal={setShowNewClinicalAssessmentModal} showModal={showNewClinicalAssessmentModal} identifierId={identifierId} isFromMeeting={false} isAdmin={true} setModalRefresher={null} aiGeneratedAssessmentData={null} isMeeting={false}/>}
        {showNewClinicalAssessmentPdfModal && <NewClinicalAssessmentPdfModal setShowModal={closeNewAssessmentModal} showModal={showNewClinicalAssessmentPdfModal} identifierId={clinicalAssessmentId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={insuranceDocApprovalId} setModalRefresher={setUploadedModal} digitalAssessmentType={clinicalAssessmentType as TPType} />}
        {showTherapyPlanModal && <TherapyPlanModal setShowModal={setShowTherapyPlanModal} showModal={showTherapyPlanModal} identifierId={identifierId} isAdmin={true} isFromMeeting={false} isCreatingNewForm={false} setModalRefresher={null} />}
        {showTherapyPlanPdfModal && <TherapyPlanPdfModal setShowModal={closeTherapyPlanModal} showModal={showTherapyPlanPdfModal} identifierId={selectedTherapyPlanId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={insuranceDocApprovalId} setModalRefresher={setUploadedModal} therapyPlanType={therapyPlanType as TPType} />}
        {showAmeriHealthModal && <AmeriHealthModal setShowModal={setshowAmeriHealthModal} showModal={showAmeriHealthModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={true} />}
        {showCarolinaCompleteHealthModal && <CarolinaCompleteHealthModal setShowModal={setshowCarolinaCompleteHealthModal} showModal={showCarolinaCompleteHealthModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={true} />}
        {showUnitedHealthCareModal && <UnitedHealthCareModal setShowModal={setshowUnitedHealthCareModal} showModal={showUnitedHealthCareModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={true} />}
        {showHealthBlueModal && <HealthBlueModal setShowModal={setshowHealthBlueModal} showModal={showHealthBlueModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={true} />}
        {showWellCareModal && <WellCareModal setShowModal={setshowWellCareModal} showModal={showWellCareModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={true} />}
        {showAmbetterModal && <AmbetterModal setShowModal={setshowAmbetterModal} showModal={showAmbetterModal} identifierId={identifierId} isFromMeeting={false} insuranceCompanyId={insuranceCompanyId} isAdmin={true} />}
        {showAuthorizationFormPdfModal && <AuthorizationFormPdfModal setShowModal={closeAuthorizationFormModal} showModal={showAuthorizationFormPdfModal} identifierId={selectedAuthorizationFormId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={insuranceDocApprovalId} setModalRefresher={setUploadedModal} authorizationFormType={authorizationFormType as AuthFormType} insuranceCompanyId={null} />}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Insurance Documents"} breadcrumbItem={"Approval Queue"} />
            <Card className="m-h">
              <CardBody>

                <Row className="d-flex justify-content-between align-items-center">
                  <div className="d-flex admin-filter">
                    <button className="btn btn-sm mr-2" onClick={toggleFilter}>Filter <i className='bx bx-filter-alt'></i></button>
                  </div>
                </Row>

                <Row>
                  <Col sm="12" className="mt10">
                    <div className="table-responsive" style={{ minHeight: "70vh" }}>
                    {isLoading ? (
                      <div style={{height: 500}}>
                          <Spinner />
                      </div>
                      ) : (
                      <>
                        {insuranceDocs.length > 0 ? (
                          <>
                            <div className="d-flex justify-content-center p-1">
                              <span>Instructions for status: </span>&nbsp;
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-phone-check-outline doc-submitted submission-common"></i> <i className='bx bx-info-circle' id="w1"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w1"}>
                                  Session is completed.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common"> </i><i className='bx bx-info-circle' id="w2"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w2"}>
                                  Session is not yet completed.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-note-outline doc-submitted submission-common"></i> <i className='bx bx-info-circle' id="w3"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w3"}>
                                  Form is submitted.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-note-remove-outline doc-no-submitted submission-common"></i> <i className='bx bx-info-circle' id="w4"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w4"}>
                                  Form is not yet submitted.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-shield-link-variant-outline doc-submitted submission-common"></i> <i className='bx bx-info-circle' id="w3"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w3"}>
                                  Authorization Form is submitted from insurance company portal or service page.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-shield-link-variant-outline doc-no-submitted submission-common"></i> <i className='bx bx-info-circle' id="w4"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w4"}>
                                  Authorization Form is not yet submitted from insurance company portal or service page.
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex me-2 me-lg-4">
                                <span className="d-none d-sm-block"><i className="mdi mdi-bell-alert-outline doc-no-submitted submission-common"></i> <i className='bx bx-info-circle' id="w5"></i></span>
                                <UncontrolledTooltip placement="bottom" target={"w5"}>
                                  Send a reminder email to therapist.
                                </UncontrolledTooltip>
                              </div>
                            </div><br />

                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Client</th>
                                  <th scope="col">Therapist</th>
                                  <th scope="col">Meeting Count</th>
                                  <th scope="col">Clinical Assessment</th>
                                  <th scope="col">Therapy Plan</th>
                                  <th scope="col">Authorization Form</th>
                                  <th scope="col">Insurance Details</th>
                                  <th scope="col">Therapist Approval</th>
                                  <th scope="col">Fax Status</th>
                                  <th scope="col">Fax Info</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  insuranceDocs && insuranceDocs.flatMap((insuranceDoc, docIndex) =>
                                    insuranceDoc.insuranceCompanies.map((insuranceCompany, index) => (
                                      <tr key={`${insuranceDoc.client._id}-${insuranceCompany._id}`} style={{borderBottom: '2px solid #ddd'}}>
                                        {index === 0 && (
                                          <>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {docIndex + 1}
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {insuranceDoc.client.firstname ?? ""}&nbsp;{insuranceDoc.client.lastname ?? ""}<br />
                                              {insuranceDoc.client.email ? insuranceDoc.client.email.substring(0, 10) + "..." : ""}
                                              <button onClick={() => copyToClipboard(insuranceDoc.client.email)} title={`Copy Email Address: ${insuranceDoc?.client?.email ?? ''}`}><i className="mdi mdi-content-copy"></i></button>
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {insuranceDoc?.therapist?.firstname ?? ""}&nbsp;{insuranceDoc?.therapist?.lastname ?? ""}<br />
                                              {insuranceDoc?.therapist?.email ? insuranceDoc.therapist.email.substring(0, 10) + "..." : ""}
                                              <button onClick={() => copyToClipboard(insuranceDoc?.therapist?.email)} title={`Copy Email Address: ${insuranceDoc?.therapist?.email ?? ''}`}><i className="mdi mdi-content-copy"></i></button>
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {insuranceDoc?.meetingCount}
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {
                                                (insuranceDoc?.meetingCount == 0) ? (
                                                  <i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common" title="First session is not yet completed."></i>
                                                ) : (
                                                  <i className="mdi mdi-phone-check-outline doc-submitted submission-common" title="First session is completed."></i>
                                                )
                                              }&nbsp;
                                              {
                                                insuranceDoc?.meetingCount > 0 && (
                                                  (insuranceDoc?.clinicalAssessment === null && insuranceDoc?.digitalAssessment === null) ? (
                                                    <i className="mdi mdi-note-remove-outline doc-no-submitted submission-common" title="Clinical assessment is not yet submitted."></i>
                                                  ) : (
                                                    (insuranceDoc?.clinicalAssessment !== null) ? (
                                                      <button
                                                        className="insurance-approval-icon-button"
                                                        onClick={() => {
                                                          handleClinicalAssessmentClick(insuranceDoc?.clinicalAssessment, CAFormType.OLD_CA);
                                                        }}
                                                        disabled={showClinicalAssessmentModal}
                                                        title="View Clinical Assessment"
                                                      >
                                                        <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="insurance-approval-icon-button"
                                                        onClick={() => {
                                                          handleDigitalAssessmentClick(insuranceDoc?.digitalAssessment, CAFormType.NEW_CA);
                                                        }}
                                                        disabled={showNewClinicalAssessmentModal}
                                                        title="View Clinical Assessment"
                                                      >
                                                        <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                      </button>
                                                    )
                                                  )
                                                )
                                              }&nbsp;
                                              {
                                                (insuranceDoc?.meetingCount != 0 && insuranceDoc?.clinicalAssessment === null && insuranceDoc?.digitalAssessment === null) ? (
                                                  <button
                                                    className="insurance-approval-icon-button"
                                                    onClick={sendReminderNotice}
                                                    data-therapist-id={insuranceDoc?.therapist?._id}
                                                    data-client-id={insuranceDoc?.client?._id}
                                                    data-form-type={UploadCategory.CLINICAL_ASSESSMENT}
                                                    title="Click here to send a reminder email therapist to ensure clinical assessment form are finished."
                                                  >
                                                    <i className="mdi mdi-bell-alert-outline doc-no-submitted submission-common"></i>
                                                  </button>
                                                ) : (
                                                  <></>
                                                )
                                              }
                                            </td>
                                            <td rowSpan={insuranceDoc.insuranceCompanies.length} valign="middle">
                                              {
                                                (insuranceDoc?.meetingCount >= 2) ? (
                                                  <i className="mdi mdi-phone-check-outline doc-submitted submission-common" title="Second session is completed."></i>
                                                ) : (
                                                  <i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common" title="Second session is not yet completed."></i>
                                                )
                                              }&nbsp;
                                              {
                                                insuranceDoc?.meetingCount > 1 && (
                                                  (insuranceDoc?.therapyPlan === null) ? (
                                                    <i className="mdi mdi-note-remove-outline doc-no-submitted submission-common" title="Therapy plan form is not yet submitted."></i>
                                                  ) : (
                                                    <button
                                                      className="insurance-approval-icon-button"
                                                      onClick={() => {
                                                        therapyPlanForm(insuranceDoc.therapyPlan);
                                                      }}
                                                      disabled={showTherapyPlanModal}
                                                      title="View Therapy Plan Form"
                                                    >
                                                      <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                    </button>
                                                  )
                                                )
                                              }&nbsp;
                                              {
                                                (insuranceDoc?.meetingCount >= 2 && insuranceDoc?.therapyPlan === null) ? (
                                                  <button
                                                    className="insurance-approval-icon-button"
                                                    onClick={sendReminderNotice}
                                                    data-therapist-id={insuranceDoc?.therapist?._id}
                                                    data-client-id={insuranceDoc?.client?._id}
                                                    data-form-type={UploadCategory.THERAPY_PLAN}
                                                    title="Click here to send a reminder email therapist to ensure therapy plan form are finished."
                                                  >
                                                    <i className="mdi mdi-bell-alert-outline doc-no-submitted submission-common"></i>
                                                  </button>
                                                ) : (
                                                  <></>
                                                )
                                              }
                                            </td>
                                          </>
                                        )}
                                        <td valign="middle">
                                          {
                                            (insuranceDoc?.meetingCount >= 2) ? (
                                              <i className="mdi mdi-phone-check-outline doc-submitted submission-common" title="Second session is completed."></i>
                                            ) : (
                                              <i className="mdi mdi-phone-remove-outline doc-no-submitted submission-common" title="Second session is not yet completed."></i>
                                            )
                                          }&nbsp;
                                          {
                                            insuranceDoc?.meetingCount > 1 && (
                                              (!insuranceCompany?.authorizationFormAvailability && insuranceCompany.link && insuranceCompany.link.trim() !== "") ? (
                                                (insuranceCompany?.authorizationForms === null) ? (
                                                  <>
                                                    <i className="mdi mdi-shield-link-variant-outline doc-no-submitted submission-common" title="Authorization form is not yet submitted."></i>
                                                  </>
                                                ) : (
                                                  <>
                                                    <i className="mdi mdi-shield-link-variant-outline doc-submitted submission-common" title="Authorization form is already submitted via insurance company portal."></i>
                                                  </>
                                                )
                                              ) :
                                              (insuranceCompany?.authorizationForms === null) ? (
                                                <i className="mdi mdi-note-remove-outline doc-no-submitted submission-common" title="Authorization form is not yet submitted."></i>
                                              ) : (
                                                <button
                                                  className="insurance-approval-icon-button"
                                                  onClick={() => handleAuthorizationClick(
                                                    insuranceCompany.authorizationForms,
                                                    insuranceCompany.authFormType,
                                                    insuranceCompany._id
                                                  )}
                                                  disabled={showAmeriHealthModal || showCarolinaCompleteHealthModal || showUnitedHealthCareModal || showHealthBlueModal || showWellCareModal || showAmbetterModal}
                                                  title="View Authorization Form"
                                                >
                                                  <i className="mdi mdi-note-outline doc-submitted submission-common"></i>
                                                </button>
                                              )
                                            )
                                          }&nbsp;
                                          {
                                            (insuranceDoc?.meetingCount >= 2 && insuranceCompany?.authorizationForms === null) ? (
                                              <button
                                                className="insurance-approval-icon-button"
                                                onClick={sendReminderNotice}
                                                data-therapist-id={insuranceDoc?.therapist?._id}
                                                data-client-id={insuranceDoc?.client?._id}
                                                data-form-type={UploadCategory.AUTHORIZATION_FORM}
                                                title="Click here to send a reminder email therapist to ensure prior authorization request form are finished."
                                              >
                                                <i className="mdi mdi-bell-alert-outline doc-no-submitted submission-common"></i>
                                              </button>
                                            ) : (
                                              <></>
                                            )
                                          }
                                        </td>
                                        <td valign="middle">
                                          {insuranceCompany.name}<br />
                                          {insuranceCompany.fax ?? <div className="d-flex mb-1"><span className="cancel-2" title="Fax number is NOT FOUND for this insurance company.">No Fax</span></div>}
                                          {insuranceCompany.authorizationFormAvailability
                                            ? <div className="d-flex"><span className="verified-2" title="Prior authorization request form is available for this insurance company.">Available</span></div>
                                            : (insuranceCompany.authorizationFormAvailability === false || insuranceCompany.authorizationFormAvailability === undefined)
                                            ? <div className="d-flex"><span className="cancel-2" title="Prior authorization request form is NOT AVAILABLE for this insurance company.">Unavailable</span></div>
                                            : "-"
                                          }
                                          {insuranceCompany.isPrimary
                                            ? <div className="d-flex"><span className="verified-2" title="This is primary insurance company for this client.">Primary</span></div>
                                            : (!insuranceCompany.isPrimary)
                                            ? <div className="d-flex"><span className="cancel-2" title="This is secondary insurance company for this client.">Secondary</span></div>
                                            : (insuranceCompany.isPrimary === undefined)
                                            ? <>-</> : <></>
                                          }
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceDoc?.meetingCount >= 2 &&
                                            (insuranceDoc?.clinicalAssessment != null || insuranceDoc?.digitalAssessment != null) &&
                                            insuranceDoc?.therapyPlan != null && insuranceCompany?.authorizationForms != null
                                          ) ? (
                                            <>
                                              {
                                                insuranceCompany?.therapistApprovalStatus === "APPROVED" && (
                                                  <div className="d-flex">
                                                    <span className="verified-2" title="Approved by therapist">Approved</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.therapistApprovalStatus === "REJECTED" && (
                                                  <div className="d-flex">
                                                    <span className="cancel-2" title="Rejected by therapist">Rejected</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.therapistApprovalStatus === null && (
                                                  <div className="d-flex">
                                                    <span className="pending-2" title="Therapist approval is still pending">Pending</span>
                                                  </div>
                                                )
                                              }
                                            </>
                                          ) : (<>-</>)}
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceCompany?.messageId && insuranceCompany?.messageStatus) ? (
                                            <>
                                              {
                                                insuranceCompany?.messageStatus === "Queued" && (
                                                  <div className="d-flex">
                                                    <span className="pending-2" title="Fax on queued. Check back later for the updated status.">Queued</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.messageStatus === "Sent" && (
                                                  <div className="d-flex">
                                                    <span className="verified-2" title="Fax successfully sent.">Sent</span>
                                                  </div>
                                                )
                                              }
                                              {
                                                insuranceCompany?.messageStatus === "SendingFailed" && (
                                                  <div className="d-flex">
                                                    <span className="cancel-2" title="Fax sending faild. The system will retry sending the fax.">Failed</span>
                                                  </div>
                                                )
                                              }
                                            </>
                                          ) : (<>-</>)}
                                        </td>
                                        <td valign="middle">
                                        {(
                                          insuranceCompany?.messageId && insuranceCompany?.messageStatus) ? (
                                            <>
                                              <button
                                                className="btn btn-sm btn-success"
                                                onClick={() => {
                                                  handleFaxInfoView(insuranceCompany.insuranceDocApprovalId);
                                                }}
                                                title="View Fax Information"
                                              >View</button>
                                            </>
                                          ) : (<>-</>)}
                                        </td>
                                        <td valign="middle">
                                          {(
                                            insuranceDoc?.meetingCount >= 2 &&
                                            (insuranceDoc?.clinicalAssessment != null || insuranceDoc?.digitalAssessment != null) &&
                                            insuranceDoc?.therapyPlan != null &&
                                            insuranceCompany?.authorizationForms != null &&
                                            insuranceCompany?.insuranceDocApprovalId != null &&
                                            insuranceCompany?.therapistApprovalStatus === SubmissionApprovalStatus.APPROVED &&
                                            insuranceCompany?.fax != null &&
                                            insuranceCompany?.authorizationFormAvailability
                                          ) ? (
                                            
                                              <>
                                                <button className="btn btn-sm btn-success"
                                                  onClick={() => {
                                                    const assessmentId = insuranceDoc?.clinicalAssessment != null ? insuranceDoc?.clinicalAssessment : insuranceDoc?.digitalAssessment;
                                                    const relatedCAFormType = insuranceDoc?.clinicalAssessment != null ? CAFormType.OLD_CA : CAFormType.NEW_CA;
                                                    proceedForApproval(insuranceCompany.insuranceDocApprovalId, assessmentId, insuranceDoc.therapyPlan, insuranceCompany.authorizationForms, insuranceCompany.authFormType, relatedCAFormType);
                                                  }}
                                                  disabled={insuranceCompany?.adminApprovalStatus === SubmissionApprovalStatus.APPROVED}
                                                >
                                                {insuranceCompany?.adminApprovalStatus === SubmissionApprovalStatus.APPROVED ? "Approved" : "Approve"}
                                                </button>
                                              </>
                                            ) : (
                                              (!insuranceCompany?.authorizationFormAvailability) ? (
                                              <>
                                                <span
                                                  className="btn btn-sm btn-warning"
                                                  onClick={showReasonMessage}
                                                >
                                                  Action Not Allowed
                                                </span>
                                              </>
                                              ) : (
                                                <>
                                                  <button className="btn btn-sm btn-warning" disabled>Waiting</button>
                                                </>
                                              )
                                              
                                            )
                                          }
                                        </td>
                                      </tr>
                                    ))
                                  )
                                }
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <div>No Client&apos;s Insurance Documents To Show.</div>
                        )}
                      </>
                    )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12 mt30 text-center">
                    {seeMore && (
                      <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => seeMoreData()}>
                        {isLazyLoading ? "Loading..." : "See More"}
                      </button>
                    )}
                  </Col>
              </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        <Offcanvas
          className="offcanvas-end2"
          isOpen={isFilterOptions}
          direction="end"
          toggle={toggleFilter}>
          <OffcanvasHeader toggle={toggleFilter}>
            Filter Insurance Documents Queue
          </OffcanvasHeader>
          <OffcanvasBody>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Client Name or Email</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search client (ex: first name, last name or email)"
                    type="text"
                    onChange={e => setClientSearchableString(e.target.value)}
                    value={clientSearchableString}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Therapist Name or Email</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search therapist (ex: first name, last name or email)"
                    type="text"
                    onChange={e => setTherapistSearchableString(e.target.value)}
                    value={therapistSearchableString}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Insurance Company</Label>
                  <Select
                    styles={customStyles}
                    id="insuranceCompanyName"
                    options={InsuranceCompanyOptions}
                    onChange={handleChange}
                    value={InsuranceCompanyOptions.find(option => option.value === searchableInsuranceCompany)}
                    placeholder="Select a Insurance Company"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={12}>
                <button className={`btn ${isLoading ? "btn-primary" : "btn-success"} w-100`} onClick={() => {
                    if(!isLoading){
                      filterInsuranceDocument();
                    }else{
                      toast.error(`Please wait !`, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo-bar",
                      });
                    }
                  }}
                >
                  {isLoading ? "Loading..." : "Search"}
                </button>
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>

        <Modal isOpen={isOpenView} toggle={toggleModalForView} centered className="modal-lg" backdrop="static" keyboard={false}>
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleModalForView}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="ps-4 pe-4">
            <Row>
              <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center"></span>
            </Row>
            <Row>
              <Col md={4} className="text-center">
                <div>
                  <span className="fs-18 fw-500">Clinical Assessment</span>
                </div>
                <div className="pt-4">
                  <span>Upload Status:</span>
                </div>
                <div>
                  <span>
                    {
                      (uploadedDocInfo && uploadedDocInfo?.clinicalAssessmentUploadId) ? (
                        <button className="btn btn-secondary" disabled>Already Uploaded</button>
                      ) : (
                        <>
                          <button
                          className="btn btn-success"
                          onClick={() => {
                            // setSelectedAssessmentId(clinicalAssessmentId);
                            // setInsuranceDocApprovalId(docApprovalId);
                            handleClinicalUploadClick(clinicalAssessmentId, docApprovalId);
                          }}
                            title="Upload Clinical Assessment PDF"
                          >
                          Upload Now
                        </button>
                        </>
                      )
                    }
                  </span>
                </div>
              </Col>
              <Col md={4} className="text-center">
                <div>
                  <span className="fs-18 fw-500">Therapy Plan</span>
                </div>
                <div className="pt-4">
                  <span>Upload Status:</span>
                </div>
                <div>
                  <span>
                    {
                      (uploadedDocInfo && uploadedDocInfo?.therapyPlanUploadId) ? (
                        <button className="btn btn-secondary" disabled>Already Uploaded</button>
                      ) : (
                        <>
                          <button
                          className="btn btn-success"
                            onClick={() => {
                              setSelectedTherapyPlanId(therapyPlanId);
                              setInsuranceDocApprovalId(docApprovalId);
                            }}
                            title="Upload Therapy Plan PDF"
                          >
                          Upload Now
                        </button>
                        </>
                      )
                    }
                  </span>
                </div>
              </Col>
              <Col md={4} className="text-center">
                <div>
                  <span className="fs-18 fw-500">Authorization Form</span>
                </div>
                <div className="pt-4">
                  <span>Upload Status:</span>
                </div>
                <div>
                  <span>
                    {
                      (uploadedDocInfo && uploadedDocInfo?.authorizationFormUploadId) ? (
                        <button className="btn btn-secondary" disabled>Already Uploaded</button>
                      ) : (
                        <>
                          <button
                          className="btn btn-success"
                            onClick={() => handleAuthorizationFormUploadClick(
                              authorizationFormId,
                              docApprovalId,
                              authorizationFormType as AuthFormType
                            )}
                            title="Upload Authorization Form PDF"
                          >
                          Upload Now
                        </button>
                        </>
                      )
                    }
                  </span>
                </div>
              </Col>
            </Row>

            {
              !(
                uploadedDocInfo?.clinicalAssessmentUploadId &&
                uploadedDocInfo?.therapyPlanUploadId &&
                uploadedDocInfo?.authorizationFormUploadId
              ) && (
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className="pt-5">
                    <div className="card-bodyDiv">
                      <Card className="match-card-body-second p-2">
                        <h6 className="fw-200 fc-red">
                          Note: The &quot;Submit For Insurance Company&quot; button will not be enabled until all required files are uploaded.
                          Please click the corresponding &quot;Upload Now&quot; button to upload any missing documents.
                        </h6>
                      </Card>
                    </div>
                  </Col>
                </Row>
              )
            }

            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="text-center pt-5">
                <button
                  className="btn btn-success"
                    onClick={finalSubmission}
                    target-data-id={docApprovalId}
                    title="Submit"
                    disabled={!(
                        uploadedDocInfo &&
                        uploadedDocInfo?.clinicalAssessmentUploadId &&
                        uploadedDocInfo?.therapyPlanUploadId &&
                        uploadedDocInfo?.authorizationFormUploadId
                      )
                    }
                  >
                  Submit For Insurance Company
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={isOpenFaxView} toggle={toggleModalForFaxView} centered className="modal-md" backdrop="static" keyboard={false}>
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleModalForFaxView}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="p-4">
            <h2 className="text-center mb-4">Fax Information</h2>
            <div className="bg-light p-4 rounded-3 shadow-sm">
              <Row className="mb-3">
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <FiPhone className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">From</small>
                      <p className="mb-0 fw-bold">{faxInfo?.fromPhoneNumber || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <FiPhone className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">To</small>
                      <p className="mb-0 fw-bold">{faxInfo?.toPhoneNumber || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <FiClock className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">Created Timestamp</small>
                      <p className="mb-0">{formatDate(faxInfo?.creationTime)}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <FiClock className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">Modified Timestamp</small>
                      <p className="mb-0">{formatDate(faxInfo?.lastModifiedTime)}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <FiFile className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">Pages</small>
                      <p className="mb-0 fw-bold">{faxInfo?.faxPageCount || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <FiMessageSquare className="me-2 text-primary" size={20} />
                    <div>
                      <small className="text-muted">Message ID</small>
                      <p className="mb-0">{faxInfo?.messageId || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    {
                      faxInfo?.messageStatus === 'Sent' ? (
                        <FiCheckCircle className={`me-2 ${getFaxStatusColor(faxInfo?.messageStatus)}`} size={20} />
                      ) : faxInfo?.messageStatus === 'Queued' ? (
                        <FiAlertCircle className={`me-2 ${getFaxStatusColor(faxInfo?.messageStatus)}`} size={20} />
                      ) : (
                        <FiAlertTriangle className={`me-2 ${getFaxStatusColor(faxInfo?.messageStatus)}`} size={20} />
                      )
                    }
                    <div>
                      <small className="text-muted">Status</small>
                      <p className={`mb-0 fw-bold ${getFaxStatusColor(faxInfo?.messageStatus)}`}>
                        {faxInfo?.messageStatus || 'N/A'}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    {faxInfo?.readStatus === 'Read' ? (
                      <FiCheckCircle className="me-2 text-success" size={20} />
                    ) : (
                      <FiAlertCircle className="me-2 text-warning" size={20} />
                    )}
                    <div>
                      <small className="text-muted">Read Status</small>
                      <p className="mb-0 fw-bold">{faxInfo?.readStatus || 'N/A'}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ApprovalQueue;