import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Input, InputGroup, InputGroupText, Button } from "reactstrap";
import { AdminService } from "../../services/AdminService";
const classNames = require('classnames');
import { LicenseDetails } from "../../models/License";
import { ReviewStatus } from "../../models/Report";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
import { Upload } from '../../models/Upload';
import { Util } from "../../Util";
import Spinner from "../../common/spinner/spinner";
import { toast } from "react-toastify";
import { Search } from "react-feather";

const ViewLicenseDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [pendingLicenseDetails, setPendingLicenseDetails] = useState<LicenseDetails[]>([]);
  const [approvedLicenseDetails, setApprovedLicenseDetails] = useState<LicenseDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const LIMIT_P_L = 10;
  const LIMIT_A_L = 10;

  const [seeMore_p_l, setSeeMore_p_l] = useState(false);
  const [seeMore_a_l, setSeeMore_a_l] = useState(false);

  useEffect(() => {
    getAllPendingLicenseDetails();
  }, []);

  const getAllPendingLicenseDetails = () => {
    try{
      setIsLoading(true);
      AdminService.getAllPendingLicenseDetails(LIMIT_P_L, 0, searchTerm).then(res => {
        if (res.success) {
          setPendingLicenseDetails(res.data);
          setSeeMore_p_l(res.data.length >= LIMIT_P_L);
          setIsLoading(false)
        } else {
          setIsLoading(false);
          setSeeMore_p_l(false);
          toast.error("Something went wrong !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } catch (error) {
      setIsLoading(false);
      setSeeMore_p_l(false);
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const seeMorePendingLicense = () => {
    try {
      setIsLazyLoading(true);
      const finalOffset = pendingLicenseDetails?.length ?? 0;
      AdminService.getAllPendingLicenseDetails(LIMIT_P_L, finalOffset, searchTerm).then(res => {
        if (res.success) {
          setPendingLicenseDetails(prev => {
            return [...new Set([...prev, ...res.data])];
          });
          setSeeMore_p_l(res.data.length >= LIMIT_P_L);
          setIsLazyLoading(false);
        } else {
          setIsLazyLoading(false);
          toast.error("Something went wrong !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } catch (error) {
      setIsLazyLoading(false);
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }

  const getAllApprovedLicenseDetails = () => {
    try {
      setIsLoading(true);
      AdminService.getAllApprovedLicenseDetails(LIMIT_A_L, 0, searchTerm).then(res => {
        if (res.success) {
          setApprovedLicenseDetails(res.data);
          setSeeMore_a_l(res.data.length >= LIMIT_A_L);
          setIsLoading(false)
        } else {
          setSeeMore_a_l(false);
          setIsLoading(false);
          toast.error("Something went wrong !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } catch (error) {
      setSeeMore_a_l(false);
      setIsLoading(false);
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const seeMoreApprovedLicense = () => {
    try {
      setIsLazyLoading(true);
      const finalOffset = approvedLicenseDetails?.length ?? 0;
      AdminService.getAllApprovedLicenseDetails(LIMIT_A_L, finalOffset, searchTerm).then(res => {
        if (res.success) {
          setApprovedLicenseDetails(prev => {
            return [...new Set([...prev, ...res.data])];
          });
          setSeeMore_a_l(res.data.length >= LIMIT_A_L);
          setIsLoading(false);
          setIsLazyLoading(false);
        } else {
          setIsLoading(false);
          setIsLazyLoading(false);
          toast.error("Something went wrong !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } catch (error) {
      setIsLazyLoading(false);
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }

  const toggleTab = (tab: string) => {
    try{
      if (activeTab !== tab) {
        setIsLoading(true);
        setPendingLicenseDetails([]);
        setApprovedLicenseDetails([]);
        setSeeMore_p_l(false);
        setSeeMore_a_l(false);
        setIsLazyLoading(false);
        setActiveTab(tab);
        if(tab == "1"){
          getAllPendingLicenseDetails();
        }else if( tab == "2"){
          getAllApprovedLicenseDetails();
        }
      }
    } catch (error) {
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const toggleReviewStatus = (licenseId: string) => {
    try{
      AdminService.toggleLicenseDetailsReviewStatus(licenseId).then(res => {
        if (res.success) {
          if (res.data.reviewStatus == ReviewStatus.APPROVED) {
            setPendingLicenseDetails(pendingLicenseDetails.filter((data: LicenseDetails) => data._id !== licenseId));
            Swal.fire({
              icon: "success",
              title: "License details approved!",
              confirmButtonColor: "#FD7F00",
            });
          }
          if (res.data.reviewStatus == ReviewStatus.PENDING) {
            setApprovedLicenseDetails(approvedLicenseDetails.filter((data: LicenseDetails) => data._id !== licenseId));
            Swal.fire({
              icon: "success",
              title: "License details set to pending!",
              confirmButtonColor: "#FD7F00",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to update the details.",
            confirmButtonColor: "#FD7F00",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const downloadImage = (data: Upload) => {
    const fileUrl = Util.fileURL(data?._id);
    window.open(fileUrl, '_blank');
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    console.log('Searching for:', searchTerm);
    
    // Force re-fetch data with new search term
    if (activeTab === "1") {
      getAllPendingLicenseDetails();
    } else if (activeTab === "2") {
      getAllApprovedLicenseDetails();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"License Details"} breadcrumbItem={"License Details"} />

          <Card className="m-h">
            <CardBody>
              <Row className="mb-4">
                <Col md={6} className="mb-3 mb-md-0 d-flex">
                  <InputGroup>
                    <InputGroupText>
                      <Search size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Search by name..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeyPress}
                    />
                    <Button color="primary" onClick={handleSearchSubmit}>
                      Search
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              
              <Row>
                <Col xl="12">
                  <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-view-grid-outline"></i>
                        </span>

                        <span className="d-none d-sm-block">Pending License Details</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-format-list-bulleted"></i>
                        </span>

                        <span className="d-none d-sm-block">Approved License Details</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="table-responsive">
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          {(!isLoading) ? (
                            <Col sm="12" className="mt10">
                              <div className="table-responsive">
                                {pendingLicenseDetails.length > 0 ? (
                                  <table className="table">
                                    <thead className="thead-dark">
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Document</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pendingLicenseDetails &&
                                        pendingLicenseDetails.map((data, index) => (
                                          <tr key={data._id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>
                                              {data?.userId?.firstname} {data?.userId?.lastname}
                                            </td>
                                            <td>{data?.title}</td>
                                            <td>
                                              {Array.isArray(data?.uploadId) ? (
                                                <img 
                                                  src={Util.fileType(data?.uploadId[0]?.extension, data?.uploadId[0]?._id)} 
                                                  width={40} 
                                                  height={40} 
                                                  onClick={() => downloadImage(data?.uploadId[0])} 
                                                  className="cursor-pointer" 
                                                  alt={data?.uploadId[0]?.originalName || "Document"}
                                                />
                                              ) : (
                                                <img 
                                                  src={Util.fileType((data?.uploadId as unknown as Upload)?.extension, (data?.uploadId as unknown as Upload)?._id)} 
                                                  width={40} 
                                                  height={40} 
                                                  onClick={() => downloadImage(data?.uploadId as unknown as Upload)} 
                                                  className="cursor-pointer" 
                                                  alt={(data?.uploadId as unknown as Upload)?.originalName || "Document"}
                                                />
                                              )}
                                            </td>
                                            <td>
                                              <button
                                                className="btn btn-success btn-sm"
                                                onClick={() => {
                                                  toggleReviewStatus(data?._id);
                                                }}
                                              >
                                                Approve
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div>No Pending Documents</div>
                                )}

                              </div>
                            </Col>
                          ) : (
                            <div style={{height: 500}}>
                              <Spinner />
                            </div>
                          )}
                        </Row>
                        <Row>
                            <Col xl="12 mt30 text-center">
                                {seeMore_p_l && (
                                    <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => {
                                      if(!isLazyLoading){
                                        seeMorePendingLicense();
                                      }else{
                                        toast.error("Please wait!", {
                                          position: toast.POSITION.BOTTOM_RIGHT,
                                          className: "foo-bar",
                                        });
                                      }
                                    }}>
                                      {isLazyLoading ? "Loading..." : "See More"}
                                    </button>
                                )}
                            </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          {!isLoading ? (
                            <Col sm="12" className="mt10">
                              <div className="table-responsive">
                                {approvedLicenseDetails.length > 0 ? (
                                  <table className="table">
                                    <thead className="thead-dark">
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Document</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {approvedLicenseDetails &&
                                        approvedLicenseDetails.map((data, index) => (
                                          <tr key={data._id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>
                                              {data?.userId?.firstname} {data?.userId?.lastname}
                                            </td>
                                            <td>{data?.title}</td>
                                            <td>
                                              {Array.isArray(data?.uploadId) ? (
                                                <img 
                                                  src={Util.fileType(data?.uploadId[0]?.extension, data?.uploadId[0]?._id)} 
                                                  width={40} 
                                                  height={40} 
                                                  onClick={() => downloadImage(data?.uploadId[0])} 
                                                  className="cursor-pointer" 
                                                  alt={data?.uploadId[0]?.originalName || "Document"}
                                                />
                                              ) : (
                                                <img 
                                                  src={Util.fileType((data?.uploadId as unknown as Upload)?.extension, (data?.uploadId as unknown as Upload)?._id)} 
                                                  width={40} 
                                                  height={40} 
                                                  onClick={() => downloadImage(data?.uploadId as unknown as Upload)} 
                                                  className="cursor-pointer" 
                                                  alt={(data?.uploadId as unknown as Upload)?.originalName || "Document"}
                                                />
                                              )}
                                            </td>
                                            <td>
                                              <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => {
                                                  toggleReviewStatus(data?._id);
                                                }}
                                              >
                                                Reject
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div>No Approved Documents</div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            <div style={{height: 500}}>
                              <Spinner />
                            </div>
                          )}
                        </Row>
                        <Row>
                            <Col xl="12 mt30 text-center">
                                {seeMore_a_l && (
                                    <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => {
                                      if(!isLazyLoading){
                                        seeMoreApprovedLicense();
                                      }else{
                                        toast.error("Please wait!", {
                                          position: toast.POSITION.BOTTOM_RIGHT,
                                          className: "foo-bar",
                                      });
                                      }
                                    }}>
                                      {isLazyLoading ? "Loading..." : "See More"}
                                    </button>
                                )}
                            </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewLicenseDetails;
