import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { addtionalHistory } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

const FormFive = (props: any) => {
    const { formId, assesmenData, getAssesmentByClientId,customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    });
    const [edit, setEdit] = useState<boolean>(true)

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 5,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                substanceAbuse: assesmenData.substanceAbuse
            }
            setFormData(updateAssesment);
        }
    }, [customActiveTab,assesmenData]);

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 5,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            substanceAbuse: formData.substanceAbuse,
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });

                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [nextStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '10%' }}>Substance Abuse and Addiction Information</h5>
                                <div className="form-check">
                                    <input className="form-check-input" 
                                    type="checkbox" 
                                    style={{ fontSize: "larger" }} 
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no SA history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "920px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "15%" }}>Drug</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Age at 1st Use</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Age at Regular Use</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Current Frequency & Average Use</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Method of Administration</td>
                                            <td className="fw-500" style={{ width: "14%" }}>Last  Date Used</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Primary, Secondary, Or Tertiary</td>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Nicotine
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.nicotine?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, nicotine: {
                                                                    ...formData.substanceAbuse?.nicotine, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.nicotine?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, nicotine: {
                                                                    ...formData.substanceAbuse?.nicotine, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.nicotine?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, nicotine: {
                                                                    ...formData.substanceAbuse?.nicotine, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.nicotine?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, nicotine: {
                                                                    ...formData.substanceAbuse?.nicotine, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.nicotine?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, nicotine: {
                                                                    ...formData.substanceAbuse?.nicotine, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.nicotine?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, nicotine: {
                                                                    ...formData.substanceAbuse?.nicotine, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Alcohol
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.alcohol?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, alcohol: {
                                                                    ...formData.substanceAbuse?.alcohol, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.alcohol?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, alcohol: {
                                                                    ...formData.substanceAbuse?.alcohol, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.alcohol?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, alcohol: {
                                                                    ...formData.substanceAbuse?.alcohol, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.alcohol?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, alcohol: {
                                                                    ...formData.substanceAbuse?.alcohol, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.alcohol?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, alcohol: {
                                                                    ...formData.substanceAbuse?.alcohol, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.alcohol?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, alcohol: {
                                                                    ...formData.substanceAbuse?.alcohol, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Marijuana
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.marijuana?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, marijuana: {
                                                                    ...formData.substanceAbuse?.marijuana, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.marijuana?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, marijuana: {
                                                                    ...formData.substanceAbuse?.marijuana, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.marijuana?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, marijuana: {
                                                                    ...formData.substanceAbuse?.marijuana, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.marijuana?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, marijuana: {
                                                                    ...formData.substanceAbuse?.marijuana, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.marijuana?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, marijuana: {
                                                                    ...formData.substanceAbuse?.marijuana, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.marijuana?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, marijuana: {
                                                                    ...formData.substanceAbuse?.marijuana, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Cocaine/Crack
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.cocaineCrack?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, cocaineCrack: {
                                                                    ...formData.substanceAbuse?.cocaineCrack, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.cocaineCrack?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, cocaineCrack: {
                                                                    ...formData.substanceAbuse?.cocaineCrack, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.cocaineCrack?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, cocaineCrack: {
                                                                    ...formData.substanceAbuse?.cocaineCrack, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.cocaineCrack?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, cocaineCrack: {
                                                                    ...formData.substanceAbuse?.cocaineCrack, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.cocaineCrack?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, cocaineCrack: {
                                                                    ...formData.substanceAbuse?.cocaineCrack, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.cocaineCrack?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, cocaineCrack: {
                                                                    ...formData.substanceAbuse?.cocaineCrack, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Amphetamines
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.amphetamines?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, amphetamines: {
                                                                    ...formData.substanceAbuse?.amphetamines, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.amphetamines?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, amphetamines: {
                                                                    ...formData.substanceAbuse?.amphetamines, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.amphetamines?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, amphetamines: {
                                                                    ...formData.substanceAbuse?.amphetamines, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.amphetamines?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, amphetamines: {
                                                                    ...formData.substanceAbuse?.amphetamines, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.amphetamines?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, amphetamines: {
                                                                    ...formData.substanceAbuse?.amphetamines, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.amphetamines?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, amphetamines: {
                                                                    ...formData.substanceAbuse?.amphetamines, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Ecstasy/Other
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.ecstasyOther?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, ecstasyOther: {
                                                                    ...formData.substanceAbuse?.ecstasyOther, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.ecstasyOther?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, ecstasyOther: {
                                                                    ...formData.substanceAbuse?.ecstasyOther, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.ecstasyOther?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, ecstasyOther: {
                                                                    ...formData.substanceAbuse?.ecstasyOther, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.ecstasyOther?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, ecstasyOther: {
                                                                    ...formData.substanceAbuse?.ecstasyOther, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.ecstasyOther?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, ecstasyOther: {
                                                                    ...formData.substanceAbuse?.ecstasyOther, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.ecstasyOther?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, ecstasyOther: {
                                                                    ...formData.substanceAbuse?.ecstasyOther, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Inhalants
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.inhalants?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, inhalants: {
                                                                    ...formData.substanceAbuse?.inhalants, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.inhalants?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, inhalants: {
                                                                    ...formData.substanceAbuse?.inhalants, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.inhalants?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, inhalants: {
                                                                    ...formData.substanceAbuse?.inhalants, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.inhalants?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, inhalants: {
                                                                    ...formData.substanceAbuse?.inhalants, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.inhalants?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, inhalants: {
                                                                    ...formData.substanceAbuse?.inhalants, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.inhalants?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, inhalants: {
                                                                    ...formData.substanceAbuse?.inhalants, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Heroin
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.heroin?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, heroin: {
                                                                    ...formData.substanceAbuse?.heroin, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.heroin?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, heroin: {
                                                                    ...formData.substanceAbuse?.heroin, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.heroin?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, heroin: {
                                                                    ...formData.substanceAbuse?.heroin, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.heroin?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, heroin: {
                                                                    ...formData.substanceAbuse?.heroin, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.heroin?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, heroin: {
                                                                    ...formData.substanceAbuse?.heroin, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.heroin?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, heroin: {
                                                                    ...formData.substanceAbuse?.heroin, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Barbiturates
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.barbiturates?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, barbiturates: {
                                                                    ...formData.substanceAbuse?.barbiturates, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.barbiturates?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, barbiturates: {
                                                                    ...formData.substanceAbuse?.barbiturates, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.barbiturates?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, barbiturates: {
                                                                    ...formData.substanceAbuse?.barbiturates, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.barbiturates?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, barbiturates: {
                                                                    ...formData.substanceAbuse?.barbiturates, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.barbiturates?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, barbiturates: {
                                                                    ...formData.substanceAbuse?.barbiturates, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.barbiturates?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, barbiturates: {
                                                                    ...formData.substanceAbuse?.barbiturates, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td>
                                                <div className="col-auto">
                                                    Other
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.other?.ageAtFirstUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, other: {
                                                                    ...formData.substanceAbuse?.other, ageAtFirstUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="number"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.other?.ageAtRegularUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, other: {
                                                                    ...formData.substanceAbuse?.other, ageAtRegularUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.other?.currentFrequencyAverageUse || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, other: {
                                                                    ...formData.substanceAbuse?.other, currentFrequencyAverageUse: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.other?.methodOfAdministration || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, other: {
                                                                    ...formData.substanceAbuse?.other, methodOfAdministration: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="date"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.other?.lastDateUsed || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, other: {
                                                                    ...formData.substanceAbuse?.other, lastDateUsed: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.other?.primarySecondaryOrTertiary || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, other: {
                                                                    ...formData.substanceAbuse?.other, primarySecondaryOrTertiary: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row className="mt-2">
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Means of Obtaining Substance :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.substanceAbuse?.substanceMeans || ""}
                                                    onChange={e => setFormData({ ...formData, substanceAbuse: { ...formData.substanceAbuse, substanceMeans: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Motivation for use :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.substanceAbuse?.mortivationForUse || ""}
                                                    onChange={e => setFormData({ ...formData, substanceAbuse: { ...formData.substanceAbuse, mortivationForUse: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Interest in reducing use and attaining abstinence :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.substanceAbuse?.useReductionInterest || ""}
                                                    onChange={e => setFormData({ ...formData, substanceAbuse: { ...formData.substanceAbuse, useReductionInterest: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Past periods of Abstinence (when, how, previous admin. to detox, recovery history, hospitalizations, AA/NA attendance) :</h6>
                                        </div>
                                        <div className="col">
                                            <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                type="text"
                                                id="name"
                                                readOnly={!edit}
                                                value={formData.substanceAbuse?.pastAbstinence || ""}
                                                onChange={e => setFormData({ ...formData, substanceAbuse: { ...formData.substanceAbuse, pastAbstinence: e.target.value } })}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Problems usage may have caused: (job loss, family dysfunction, family violence, arrests, loss of friends, etc) :</h6>
                                        </div>
                                        <div className="col">
                                            <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                type="text"
                                                id="name"
                                                readOnly={!edit}
                                                value={formData.substanceAbuse?.problemUsageCaused || ""}
                                                onChange={e => setFormData({ ...formData, substanceAbuse: { ...formData.substanceAbuse, problemUsageCaused: e.target.value } })}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-xl-auto col-md-12" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Tex Additional History :</h6>
                                        </div>

                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                value={addtionalHistory.DWI}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.DWI}
                                                onClick={() => setFormData({
                                                    ...formData, substanceAbuse: {
                                                        ...formData.substanceAbuse,
                                                        additionalHistory: addtionalHistory.DWI
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>DWI</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                value={addtionalHistory.BLACKOUTS}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.BLACKOUTS}
                                                onClick={() => setFormData({
                                                    ...formData, substanceAbuse: {
                                                        ...formData.substanceAbuse,
                                                        additionalHistory: addtionalHistory.BLACKOUTS
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Blackouts</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                value={addtionalHistory.ABSENTEEISM}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.ABSENTEEISM}
                                                onClick={() => setFormData({
                                                    ...formData, substanceAbuse: {
                                                        ...formData.substanceAbuse,
                                                        additionalHistory: addtionalHistory.ABSENTEEISM
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Absenteeism</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                value={addtionalHistory.SEIZURES}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.SEIZURES}
                                                onClick={() => setFormData({
                                                    ...formData, substanceAbuse: {
                                                        ...formData.substanceAbuse,
                                                        additionalHistory: addtionalHistory.SEIZURES
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Seizures</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                value={addtionalHistory.JOBLOSS}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.JOBLOSS}
                                                onClick={e => setFormData({
                                                    ...formData, substanceAbuse: {
                                                        ...formData.substanceAbuse,
                                                        additionalHistory: addtionalHistory.JOBLOSS
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Job loss</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="additionalHistory"
                                                value={addtionalHistory.IVDRUGUSE}
                                                checked={formData.substanceAbuse?.additionalHistory === addtionalHistory.IVDRUGUSE}
                                                onClick={e => setFormData({
                                                    ...formData, substanceAbuse: {
                                                        ...formData.substanceAbuse,
                                                        additionalHistory: addtionalHistory.IVDRUGUSE
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>IV Drug Use</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>If history of DWI/DUI charges, provide details here (dates, outcome, etc.) :</h6>
                                        </div>
                                        <div className="col">
                                            <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                type="text"
                                                id="name"
                                                readOnly={!edit}
                                                value={formData.substanceAbuse?.chargesHistory || ""}
                                                onChange={e => setFormData({ ...formData, substanceAbuse: { ...formData.substanceAbuse, chargesHistory: e.target.value } })}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>SA Related Withdrawal Symptoms (sweating, cravings, tremors, rapid heart rate, hallucinations, nausea, paranoia) :</h6>
                                        </div>
                                        <div>
                                            <Row>
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Past :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        type="text"
                                                        id="past"
                                                        name="past"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.sARelatedWithdrawalSymptoms?.past || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, sARelatedWithdrawalSymptoms: {
                                                                    ...formData.substanceAbuse?.sARelatedWithdrawalSymptoms,
                                                                    [e.target.name]: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Present :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        type="text"
                                                        id="present"
                                                        name="present"
                                                        readOnly={!edit}
                                                        value={formData.substanceAbuse?.sARelatedWithdrawalSymptoms?.present || ""}
                                                        onChange={e => setFormData({
                                                            ...formData, substanceAbuse: {
                                                                ...formData.substanceAbuse, sARelatedWithdrawalSymptoms: {
                                                                    ...formData.substanceAbuse?.sARelatedWithdrawalSymptoms,
                                                                    [e.target.name]: e.target.value
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Substance use signs of tolerance (consumer requires increasing amount of substance to obtain same effect) :</h6>
                                        </div>
                                        <div className="col">
                                            <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                type="text"
                                                id="name"
                                                readOnly={!edit}
                                                value={formData.substanceAbuse?.signsOfTolerance || ""}
                                                onChange={e => setFormData(prevState => ({
                                                    ...prevState,
                                                    substanceAbuse: {
                                                        ...prevState.substanceAbuse,
                                                        signsOfTolerance: e.target.value
                                                    }
                                                }))}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.substanceAbuse?.isRecommended}
                                                onClick={() => setFormData(prevState => ({
                                                    ...prevState,
                                                    substanceAbuse: {
                                                        ...prevState.substanceAbuse,
                                                        isRecommended: !prevState.substanceAbuse?.isRecommended
                                                    }
                                                }))}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}> Check here if more extensive substance-use evaluation is recommended :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.substanceAbuse?.recommend || ""}
                                                            onChange={e => setFormData(prevState => ({
                                                                ...prevState,
                                                                substanceAbuse: {
                                                                    ...prevState.substanceAbuse,
                                                                    recommend: e.target.value
                                                                }
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary mr-2 relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={() => {
                                updateAssesmentDetails();
                            }}
                        >
                            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                        </button>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 5 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default FormFive;