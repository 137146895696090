import React, { useEffect, useState } from "react";

import { Card, Col, Input, Row } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";

import {
  AnxietySymptoms,
  AttentionSymptoms,
  ConductLegalProblem,
  DepressiveSymptoms,
  ManicSymptoms,
  Psychosis,
} from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";

const NewFormTwo = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;

  const [isUpdate, setIsUpdate] = useState(props.isUpdate);
  const [edit, setEdit] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [historyOfProblem, setHistoryOfProblem] = useState("");
  const [depressiveSymptomsComment, setdepressiveSymptomsComment] = useState("");
  const [manicSymptomsComment, setmanicSymptomsComment] = useState("");
  const [conductLegalProblemComment, setconductLegalProblemComment] = useState("");
  const [psychosisComment, setPsychosisComment] = useState("");
  const [anxietySymptomsComment, setAnxietySymptomsComment] = useState("");
  const [attentionSymptomsComment, setAttentionSymptomsComment] = useState("");

  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    presentingProblem: {
      description: "",
      historyOfProblem: "",
    },
    historyOfProblem: "",
    symptomChicklist: {
      depressiveSymptoms: { symtoms: {}, comment: "" },
      manicSymptoms: { symtoms: {}, comment: "" },
      conductLegalProblem: { symtoms: {}, comment: "" },
      psychosis: { symtoms: {}, comment: "" },
      anxietySymptoms: { symtoms: {}, comment: "" },
      attentionSymptoms: { symtoms: {}, comment: "" },
    },
  });

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...data,
      }));

      setHistoryOfProblem(data?.historyOfProblem);
      setdepressiveSymptomsComment(data?.symptomChicklist?.depressiveSymptoms?.comment);
      setmanicSymptomsComment(data?.symptomChicklist?.manicSymptoms?.comment);
      setconductLegalProblemComment(data?.symptomChicklist?.conductLegalProblem?.comment);
      setPsychosisComment(data?.symptomChicklist?.psychosis?.comment);
      setAnxietySymptomsComment(data?.symptomChicklist?.anxietySymptoms?.comment);
      setAttentionSymptomsComment(data?.symptomChicklist?.attentionSymptoms?.comment);
      setLoading(false);
    }
  }, [data]);

  const handleUpdateData = () => {
    const updateDetails = {
      formHeader: {
        ...formData?.formHeader,
      },
      presentingProblem: {
        ...formData?.presentingProblem,
      },
      historyOfProblem: historyOfProblem,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        depressiveSymptoms: {
          ...formData?.symptomChicklist?.depressiveSymptoms,
          comment: depressiveSymptomsComment,
        },
        manicSymptoms: { ...formData?.symptomChicklist?.manicSymptoms, comment: manicSymptomsComment },
        conductLegalProblem: {
          ...formData?.symptomChicklist?.conductLegalProblem,
          comment: conductLegalProblemComment,
        },
        psychosis: { ...formData?.symptomChicklist?.psychosis, comment: psychosisComment },
        anxietySymptoms: { ...formData?.symptomChicklist?.anxietySymptoms, comment: anxietySymptomsComment },
        attentionSymptoms: {
          ...formData?.symptomChicklist?.attentionSymptoms,
          comment: attentionSymptomsComment,
        },
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  const updateCheckBoxValueOfDepressiveSymptoms = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        depressiveSymptoms: {
          ...formData?.symptomChicklist?.depressiveSymptoms,
          symtoms: {
            ...formData?.symptomChicklist?.depressiveSymptoms?.symtoms,
            sadnessFeelsEmpty: field === "sadnessFeelsEmpty" ? value ? DepressiveSymptoms.sadnessFeelsEmpty : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.sadnessFeelsEmpty,
            insomniaHypersomnia: field === "insomniaHypersomnia" ? value ? DepressiveSymptoms.InsomniaHypersomnia : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.insomniaHypersomnia,
            fatigueOrLossOfEnergy: field === "fatigueOrLossOfEnergy" ? value ? DepressiveSymptoms.FatigueOrLossOfEnergy : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.fatigueOrLossOfEnergy,
            crying: field === "crying" ? (value ? DepressiveSymptoms.Crying : "") : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.crying,
            psychomotorAgitationOrRetardation: field === "psychomotorAgitationOrRetardation" ? value ? DepressiveSymptoms.PsychomotorAgitationOrRetardation : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.psychomotorAgitationOrRetardation,
            diminishedAbilityToThinkOrConcentrate: field === "diminishedAbilityToThinkOrConcentrate" ? value ? DepressiveSymptoms.DiminishedAbilityToThinkOrConcentrateORIndecisiveness : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedAbilityToThinkOrConcentrate,
            diminishedInterest: field === "diminishedInterest" ? value ? DepressiveSymptoms.DiminishedInterestInUsualActivities : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedInterest,
            significantWeightLossorGainORDecrease: field === "significantWeightLossorGainORDecrease" ? value ? DepressiveSymptoms.SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.significantWeightLossorGainORDecrease,
            recurrentThoughtsOfDeath: field === "recurrentThoughtsOfDeath" ? value ? DepressiveSymptoms.RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.recurrentThoughtsOfDeath,
            feelingsOfWorthlessness: field === "feelingsOfWorthlessness" ? value ? DepressiveSymptoms.FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt : "" : formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.feelingsOfWorthlessness,
            none: field !== "none" ? "" : value ? DepressiveSymptoms.None : "",
          },
        },
      },
    });
  };

  const updateManicSymptoms = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        manicSymptoms: {
          ...formData?.symptomChicklist?.manicSymptoms,
          symtoms: {
            ...formData?.symptomChicklist?.manicSymptoms?.symtoms,
            irrationalAnger: field === "irrationalAnger" ? (value ? ManicSymptoms.irrationalAnger : "") : formData?.symptomChicklist?.manicSymptoms?.symtoms?.irrationalAnger,
            decreasedSleep: field === "decreasedSleep" ? (value ? ManicSymptoms.decreasedSleep : "") : formData?.symptomChicklist?.manicSymptoms?.symtoms?.decreasedSleep,
            irritability:field === "irritability" ? (value ? ManicSymptoms.irritability : "") : formData?.symptomChicklist?.manicSymptoms?.symtoms?.irritability,
            distractibility: field === "distractibility" ? (value ? ManicSymptoms.distractibility : "") : formData?.symptomChicklist?.manicSymptoms?.symtoms?.distractibility,
            moreTalkative: field === "moreTalkative" ? (value ? ManicSymptoms.moreTalkative : "") : formData?.symptomChicklist?.manicSymptoms?.symtoms?.moreTalkative,
            flightOfIdeas: field === "flightOfIdeas" ? (value ? ManicSymptoms.flightOfIdeas : "") : formData?.symptomChicklist?.manicSymptoms?.symtoms?.flightOfIdeas,
            inflatedselfEsteem: field === "inflatedselfEsteem" ? value ? ManicSymptoms.inflatedselfEsteem : "" : formData?.symptomChicklist?.manicSymptoms?.symtoms?.inflatedselfEsteem,
            increaseInGoalDirectedActivity: field === "increaseInGoalDirectedActivity" ? value ? ManicSymptoms.increaseInGoalDirectedActivity : "" : formData?.symptomChicklist?.manicSymptoms?.symtoms?.increaseInGoalDirectedActivity,
            excessiveInvolvement: field === "excessiveInvolvement" ? value ? ManicSymptoms.excessiveInvolvement : "" : formData?.symptomChicklist?.manicSymptoms?.symtoms?.excessiveInvolvement,
            none: field !== "none" ? "" : value ? ManicSymptoms.none : "",
          },
        },
      },
    });
  };

  const updateConductLegalProblem = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        conductLegalProblem: {
          ...formData?.symptomChicklist?.conductLegalProblem,
          symtoms: {
            ...formData?.symptomChicklist?.conductLegalProblem?.symtoms,
            fireSetting:field === "fireSetting" ? (value ? ConductLegalProblem.fireSetting : "") : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fireSetting,
            lying: field === "lying" ? (value ? ConductLegalProblem.lying : "") : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.lying,
            stealing: field === "stealing" ? (value ? ConductLegalProblem.stealing : "") : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.stealing,
            fighting: field === "fighting" ? (value ? ConductLegalProblem.fighting : "") : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fighting,
            substanceAbuse: field === "substanceAbuse" ? value ? ConductLegalProblem.substanceAbuse : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.substanceAbuse,
            none: field !== "none" ? "" : value ? ConductLegalProblem.none : "",
            oppositionalDefiant: field === "oppositionalDefiant" ? value ? ConductLegalProblem.oppositionalDefiant : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.oppositionalDefiant,
            gangInvolvement: field === "gangInvolvement" ? value ? ConductLegalProblem.gangInvolvement : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.gangInvolvement,
            arrestsConviction: field === "arrestsConviction" ? value ? ConductLegalProblem.arrestsConviction : "": formData?.symptomChicklist?.conductLegalProblem?.symtoms?.arrestsConviction,
            impulsivity:field === "impulsivity" ? (value ? ConductLegalProblem.impulsivity : "") : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.impulsivity,
            familyDesertion: field === "familyDesertion" ? value ? ConductLegalProblem.familyDesertion : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.familyDesertion,
            exhibitionism: field === "exhibitionism" ? value ? ConductLegalProblem.exhibitionism : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.exhibitionism,
            sexualActingOut: field === "sexualActingOut" ? value ? ConductLegalProblem.sexualActingOut : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.sexualActingOut,
            consistentIrresponsibility: field === "consistentIrresponsibility" ? value ? ConductLegalProblem.consistentIrresponsibility : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.consistentIrresponsibility,
            propertyDestruction: field === "propertyDestruction" ? value ? ConductLegalProblem.propertyDestruction : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.propertyDestruction,
            other: field === "Other" ? value ? ConductLegalProblem.Other : "" : formData?.symptomChicklist?.conductLegalProblem?.symtoms?.other,
          },
        },
      },
    });
  };
  const updatePsychosis = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        psychosis: {
          ...formData?.symptomChicklist?.psychosis,
          symtoms: {
            ...formData?.symptomChicklist?.psychosis?.symtoms,
            delusions:field==="delusions"? (value ? Psychosis.delusions : "") : formData?.symptomChicklist?.psychosis?.symtoms?.delusions,
            paranoia: field === "paranoia" ? (value ? Psychosis.paranoia : "") : formData?.symptomChicklist?.psychosis?.symtoms?.paranoia,
            sensoryHallucinations:field==="sensoryHallucinations"?(value ? Psychosis.sensoryHallucinations : "") : formData?.symptomChicklist?.psychosis?.symtoms?.sensoryHallucinations,
            auditoryHallucinations:field==="auditoryHallucinations"?(value ? Psychosis.AuditoryHallucinations : "") : formData?.symptomChicklist?.psychosis?.symtoms?.auditoryHallucinations,
            visualHallucinations:field==="visualHallucinations"?(value ? Psychosis.visualHallucinations : "") : formData?.symptomChicklist?.psychosis?.symtoms?.visualHallucinations,
            none:field!=="none"? "" : value ? Psychosis.none : "",
            ideasOfReference:field==="ideasOfReference"?(value ? Psychosis.ideasOfReference : "") : formData?.symptomChicklist?.psychosis?.symtoms?.ideasOfReference,
            disorganizedSpeech:field==="disorganizedSpeech"?(value ? Psychosis.disorganizedSpeech : "") : formData?.symptomChicklist?.psychosis?.symtoms?.disorganizedSpeech,
            other:field!=="other"?"":value ? Psychosis.other : "",
          },
        },
      },
    });
  }
  const updateAnxietySymptoms=(field: string, value: boolean | string)=>{
    setFormData({
      ...formData,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        anxietySymptoms: {
          ...formData?.symptomChicklist?.anxietySymptoms,
          symtoms: {
            ...formData?.symptomChicklist?.anxietySymptoms?.symtoms,
            anxietyWorry:field==="anxietyWorry"?(value ? AnxietySymptoms.anxietyWorry : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.anxietyWorry,
            avoidance:field==="avoidance"?(value ? AnxietySymptoms.avoidance : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidance,
            panicAttacks:field==="panicAttacks"?(value ? AnxietySymptoms.panicAttacks : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.panicAttacks,
            obsessions:field==="obsessions"?(value ? AnxietySymptoms.obsessions : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.obsessions,
            compulsions:field==="compulsions"?(value ? AnxietySymptoms.compulsions : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.compulsions,
            markedlyDiminished:field==="markedlyDiminished"?(value ? AnxietySymptoms.markedlyDiminished : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.markedlyDiminished,
            intensePsychological:field==="intensePsychological"?(value ? AnxietySymptoms.intensePsychological : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intensePsychological,
            feelingOFDetachment:field==="feelingOFDetachment"?(value ? AnxietySymptoms.feelingOFDetachment : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.feelingOFDetachment,
            somaticComplaints:field==="somaticComplaints"?(value ? AnxietySymptoms.somaticComplaints : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.somaticComplaints,
            dissociativeEpisodes:field==="dissociativeEpisodes"?(value ? AnxietySymptoms.dissociativeEpisodes : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.dissociativeEpisodes,
            restrictedAffect:field==="restrictedAffect"?(value ? AnxietySymptoms.restrictedAffect : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.restrictedAffect,
            intrusivereOccurringThoughts:field==="intrusivereOccurringThoughts"?(value ? AnxietySymptoms.intrusivereOccurringThoughts : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intrusivereOccurringThoughts,
            difficultyConcentrating:field==="difficultyConcentrating"?(value ? AnxietySymptoms.difficultyConcentrating : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.difficultyConcentrating,
            hypervigilance:field==="hypervigilance"?(value ? AnxietySymptoms.hypervigilance : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.hypervigilance,
            exaggeratedStartleResponse:field==="exaggeratedStartleResponse"?(value ? AnxietySymptoms.exaggeratedStartleResponse : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.exaggeratedStartleResponse,
            nightmaresReoccurringDreams:field==="nightmaresReoccurringDreams"?(value ? AnxietySymptoms.nightmaresReoccurringDreams : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.nightmaresReoccurringDreams,
            irritabilityOrOutburstsOfAnger:field==="irritabilityOrOutburstsOfAnger"?(value ? AnxietySymptoms.irritabilityOrOutburstsOfAnger : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.irritabilityOrOutburstsOfAnger,
            senseOfForeshortenedFuture:field==="senseOfForeshortenedFuture"?(value ? AnxietySymptoms.senseOfForeshortenedFuture : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.senseOfForeshortenedFuture,
            avoidanceOfActivities:field==="avoidanceOfActivities"?(value ? AnxietySymptoms.avoidanceOfActivities : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidanceOfActivities,
            unwantedMemories:field==="unwantedMemories"?(value ? AnxietySymptoms.unwantedMemories : "") : formData?.symptomChicklist?.anxietySymptoms?.symtoms?.unwantedMemories,
            none:field!=="none"?"":value ? AnxietySymptoms.none : "",
          },
        },
      },
    });
  }
  const updateAttentionSymptoms=(field:string,value:boolean|string)=>{
    setFormData({
      ...formData,
      symptomChicklist: {
        ...formData?.symptomChicklist,
        attentionSymptoms: {
          ...formData?.symptomChicklist?.attentionSymptoms,
          symtoms: {
            ...formData?.symptomChicklist?.attentionSymptoms?.symtoms,
            failsToFinishTasks:field==="failsToFinishTasks"?(value ? AttentionSymptoms.failsToFinishTasks : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToFinishTasks,
            inattentive:field==="inattentive"?(value ? AttentionSymptoms.inattentive : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.inattentive,
            fidgety:field==="fidgety"?(value ? AttentionSymptoms.fidgety : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.fidgety,
            forgetful:field==="forgetful"?(value ? AttentionSymptoms.forgetful : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.forgetful,
            difficultyFollowingDirections:field==="difficultyFollowingDirections"?(value ? AttentionSymptoms.difficultyFollowingDirections : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyFollowingDirections,
            difficultyOrganizingThoughts:field==="difficultyOrganizingThoughts"?(value ? AttentionSymptoms.difficultyOrganizingThoughts : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyOrganizingThoughts,
            difficultyAwaitingTurn:field==="difficultyAwaitingTurn"?(value ? AttentionSymptoms.difficultyAwaitingTurn : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyAwaitingTurn,
            poorAttentionSpan:field==="poorAttentionSpan"?(value ? AttentionSymptoms.poorAttentionSpan : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.poorAttentionSpan,
            talksExcessively:field==="talksExcessively"?(value ? AttentionSymptoms.talksExcessively : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.talksExcessively,
            interruptsOrIntrudesOnOthers:field==="interruptsOrIntrudesOnOthers"?(value ? AttentionSymptoms.interruptsOrIntrudesOnOthers : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.interruptsOrIntrudesOnOthers,
            feelsAlways:field==="feelsAlways"?(value ? AttentionSymptoms.FeelsAlways : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.feelsAlways,
            failsToGiveAttention:field==="failsToGiveAttention"?(value ? AttentionSymptoms.FailsToGiveAttention : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToGiveAttention,
            avoidsDislikesTasks:field==="avoidsDislikesTasks"?(value ? AttentionSymptoms.AvoidsDislikesTasks : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.avoidsDislikesTasks,
            easilyDistracted:field==="easilyDistracted"?(value ? AttentionSymptoms.EasilyDistracted : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.easilyDistracted,
            blurtsOutAnswersBeforeQuestions:field==="blurtsOutAnswersBeforeQuestions"?(value ? AttentionSymptoms.BlurtsOutAnswersBeforeQuestions : "") : formData?.symptomChicklist?.attentionSymptoms?.symtoms?.blurtsOutAnswersBeforeQuestions,
            none:field!=="none"?"":value ? AttentionSymptoms.none : "",
          },
        },
      },
    });
  }

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>
        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                PRESENTING PROBLEM:
              </h5>
            </Card>
            <Card className="match-card-body p-3">
              <Row>
                <Col xl={12}>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    Description
                  </label>
                  <Input
                    // style={{ fontSize: "larger" }}
                    value={formData?.presentingProblem?.description || ""}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        presentingProblem: { ...formData.presentingProblem, description: e.target.value },
                      })
                    }
                    type="textarea"
                  />
                </Col>
                {/* <Col>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    History of Problem
                  </label>
                  <Input
                    // style={{ fontSize: "larger" }}
                    value={formData?.presentingProblem?.historyOfProblem || ""}
                    onChange={e => setFormData({ ...formData, presentingProblem: { ...formData.presentingProblem, historyOfProblem: e.target.value } })}
                    type="textarea"
                  />
                </Col> */}
              </Row>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                HISTORY OF PROBLEM :
              </h5>
            </Card>
            <Card className="match-card-body p-3">
              <Row>
                <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                  ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                </label>
              </Row>
              <Row>
                <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                  <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                    <CKEditor
                      editor={Editor}
                      id="floatingTextarea"
                      disabled={!edit}
                      data={historyOfProblem ?? ""}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setHistoryOfProblem(data);
                        // setFormData({ ...formData, historyOfProblem: data });
                      }}
                    />
                  </div>
                </Card>
              </Row>
            </Card>
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                SYMPTOM CHECKLIST : (past and present)
              </h5>
            </Card>
            <Card className="match-card-body p-3">
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Depressive Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.sadnessFeelsEmpty}
                      checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.sadnessFeelsEmpty == DepressiveSymptoms.sadnessFeelsEmpty || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.sadnessFeelsEmpty == DepressiveSymptoms.sadnessFeelsEmpty;
                        updateCheckBoxValueOfDepressiveSymptoms("sadnessFeelsEmpty", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sadness/Feels Empty
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.InsomniaHypersomnia}
                      checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.insomniaHypersomnia === DepressiveSymptoms.InsomniaHypersomnia || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.insomniaHypersomnia === DepressiveSymptoms.InsomniaHypersomnia;
                        updateCheckBoxValueOfDepressiveSymptoms("insomniaHypersomnia", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Insomnia/Hypersomnia
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.FatigueOrLossOfEnergy}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.fatigueOrLossOfEnergy === DepressiveSymptoms.FatigueOrLossOfEnergy || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.fatigueOrLossOfEnergy === DepressiveSymptoms.FatigueOrLossOfEnergy;
                        updateCheckBoxValueOfDepressiveSymptoms("fatigueOrLossOfEnergy", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fatigue or loss of energy
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.Crying}
                      checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.crying === DepressiveSymptoms.Crying || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.crying === DepressiveSymptoms.Crying;
                        updateCheckBoxValueOfDepressiveSymptoms("crying", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Crying
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.PsychomotorAgitationOrRetardation}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.psychomotorAgitationOrRetardation ===
                          DepressiveSymptoms.PsychomotorAgitationOrRetardation || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.psychomotorAgitationOrRetardation ===
                          DepressiveSymptoms.PsychomotorAgitationOrRetardation;
                        updateCheckBoxValueOfDepressiveSymptoms("psychomotorAgitationOrRetardation", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Psychomotor agitation <br />
                      or retardation
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.DiminishedAbilityToThinkOrConcentrateORIndecisiveness}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedAbilityToThinkOrConcentrate ===
                          DepressiveSymptoms.DiminishedAbilityToThinkOrConcentrateORIndecisiveness || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedAbilityToThinkOrConcentrate ===
                          DepressiveSymptoms.DiminishedAbilityToThinkOrConcentrateORIndecisiveness;
                        updateCheckBoxValueOfDepressiveSymptoms("diminishedAbilityToThinkOrConcentrate", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Diminished ability to think or concentrate <br />
                      –OR- indecisiveness
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.DiminishedInterestInUsualActivities}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedInterest ===
                          DepressiveSymptoms.DiminishedInterestInUsualActivities || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.diminishedInterest ===
                          DepressiveSymptoms.DiminishedInterestInUsualActivities;
                        updateCheckBoxValueOfDepressiveSymptoms("diminishedInterest", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Diminished interest in <br />
                      usual activities
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.significantWeightLossorGainORDecrease ===
                          DepressiveSymptoms.SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.significantWeightLossorGainORDecrease ===
                          DepressiveSymptoms.SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite;
                        updateCheckBoxValueOfDepressiveSymptoms("significantWeightLossorGainORDecrease", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Significant weight lossor weight <br /> gain OR decrease/increase <br />
                      in appetite
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.recurrentThoughtsOfDeath ===
                          DepressiveSymptoms.RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.recurrentThoughtsOfDeath ===
                          DepressiveSymptoms.RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan;
                        updateCheckBoxValueOfDepressiveSymptoms("recurrentThoughtsOfDeath", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Recurrent thoughts of death (not <br />
                      just fear of dying), recurrent SI w/o plan, <br /> suicide attempt or plan for committing suicide
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={DepressiveSymptoms.FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt}
                      checked={
                        formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.feelingsOfWorthlessness ===
                          DepressiveSymptoms.FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.feelingsOfWorthlessness ===
                          DepressiveSymptoms.FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt;
                        updateCheckBoxValueOfDepressiveSymptoms("feelingsOfWorthlessness", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Feelings of worthlessness
                      <br />
                      or excessive/inappropriate guilt
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      value={DepressiveSymptoms.None}
                      checked={formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.none === DepressiveSymptoms.None || false}
                      onClick={e => {
                        const result = formData?.symptomChicklist?.depressiveSymptoms?.symtoms?.none === DepressiveSymptoms.None;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            depressiveSymptoms: {
                              ...formData?.symptomChicklist?.depressiveSymptoms,
                              symtoms: {
                                ...formData?.symptomChicklist?.depressiveSymptoms?.symtoms,

                                none: result ? "" : DepressiveSymptoms.None,
                                sadnessFeelsEmpty: "",
                                insomniaHypersomnia: "",
                                fatigueOrLossOfEnergy: "",
                                crying: "",
                                psychomotorAgitationOrRetardation: "",
                                diminishedAbilityToThinkOrConcentrate: "",
                                diminishedInterest: "",
                                significantWeightLossorGainORDecrease: "",
                                recurrentThoughtsOfDeath: "",
                                feelingsOfWorthlessness: "",
                              },
                            },
                          },
                        });
                      }}
                      className="form-check-input"
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        id="floatingTextarea"
                        editor={Editor}
                        disabled={!edit}
                        data={depressiveSymptomsComment || ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   symptomChicklist: {
                          //     ...formData?.symptomChicklist,
                          //     depressiveSymptoms: { ...formData?.symptomChicklist?.depressiveSymptoms, comment: data },
                          //   },
                          // });
                          setdepressiveSymptomsComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Manic Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.irrationalAnger}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.irrationalAnger === ManicSymptoms.irrationalAnger || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.irrationalAnger === ManicSymptoms.irrationalAnger;
                        updateManicSymptoms("irrationalAnger", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Irrational anger
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.distractibility}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.distractibility === ManicSymptoms.distractibility || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.distractibility === ManicSymptoms.distractibility;
                        updateManicSymptoms("distractibility", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Distractibility
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.inflatedselfEsteem}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.inflatedselfEsteem === ManicSymptoms.inflatedselfEsteem || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.inflatedselfEsteem === ManicSymptoms.inflatedselfEsteem;
                        updateManicSymptoms("inflatedselfEsteem", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Inflated self-esteem or grandiosity
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.decreasedSleep}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.decreasedSleep === ManicSymptoms.decreasedSleep || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.decreasedSleep === ManicSymptoms.decreasedSleep;
                        updateManicSymptoms("decreasedSleep", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Decreased need for sleep
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.moreTalkative}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.moreTalkative === ManicSymptoms.moreTalkative || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.moreTalkative === ManicSymptoms.moreTalkative;
                        updateManicSymptoms("moreTalkative", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      More talkative than usual or
                      <br />
                      pressure to keep talking
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.increaseInGoalDirectedActivity}
                      checked={
                        formData?.symptomChicklist?.manicSymptoms?.symtoms?.increaseInGoalDirectedActivity === ManicSymptoms.increaseInGoalDirectedActivity ||
                        false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.manicSymptoms?.symtoms?.increaseInGoalDirectedActivity === ManicSymptoms.increaseInGoalDirectedActivity;
                        updateManicSymptoms("increaseInGoalDirectedActivity", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Increase in goal-directed <br />
                      activity or psychomotor agitation
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.irritability}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.irritability === ManicSymptoms.irritability || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.irritability === ManicSymptoms.irritability;
                        updateManicSymptoms("irritability", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Irritability
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.flightOfIdeas}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.flightOfIdeas === ManicSymptoms.flightOfIdeas || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.flightOfIdeas === ManicSymptoms.flightOfIdeas;
                        updateManicSymptoms("flightOfIdeas", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Flight of ideas or feeling like
                      <br /> thoughts are racing
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.excessiveInvolvement}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.excessiveInvolvement === ManicSymptoms.excessiveInvolvement || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.excessiveInvolvement === ManicSymptoms.excessiveInvolvement;
                        updateManicSymptoms("excessiveInvolvement", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Excessive involvement in pleasurable
                      <br /> activities that have a high potential <br />
                      for painful consequence
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ManicSymptoms.none}
                      checked={formData?.symptomChicklist?.manicSymptoms?.symtoms?.none === ManicSymptoms.none || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.manicSymptoms?.symtoms?.none === ManicSymptoms.none;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            manicSymptoms: {
                              ...formData?.symptomChicklist?.manicSymptoms,
                              symtoms: {
                                ...formData?.symptomChicklist?.manicSymptoms?.symtoms,
                                none: result ? "" : ManicSymptoms.none,
                                irrationalAnger: "",
                                decreasedSleep: "",
                                irritability: "",
                                distractibility: "",
                                moreTalkative: "",
                                flightOfIdeas: "",
                                inflatedselfEsteem: "",
                                increaseInGoalDirectedActivity: "",
                                excessiveInvolvement: "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        disabled={!edit}
                        data={manicSymptomsComment || ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   symptomChicklist: {
                          //     ...formData?.symptomChicklist,
                          //     manicSymptoms: { ...formData?.symptomChicklist?.manicSymptoms, comment: data },
                          //   },
                          // });

                          setmanicSymptomsComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Conduct/Legal Problems:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.fireSetting}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fireSetting === ConductLegalProblem.fireSetting || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fireSetting === ConductLegalProblem.fireSetting;
                        updateConductLegalProblem("fireSetting", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fire Setting
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.oppositionalDefiant}
                      checked={
                        formData?.symptomChicklist?.conductLegalProblem?.symtoms?.oppositionalDefiant === ConductLegalProblem.oppositionalDefiant || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.conductLegalProblem?.symtoms?.oppositionalDefiant === ConductLegalProblem.oppositionalDefiant;
                        updateConductLegalProblem("oppositionalDefiant", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Oppositional Defiant
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.exhibitionism}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.exhibitionism === ConductLegalProblem.exhibitionism || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.exhibitionism === ConductLegalProblem.exhibitionism;
                        updateConductLegalProblem("exhibitionism", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Exhibitionism
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.lying}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.lying === ConductLegalProblem.lying || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.lying === ConductLegalProblem.lying;
                        updateConductLegalProblem("lying", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Lying
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.gangInvolvement}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.gangInvolvement === ConductLegalProblem.gangInvolvement || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.gangInvolvement === ConductLegalProblem.gangInvolvement;
                        updateConductLegalProblem("gangInvolvement", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Gang Involvement
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.sexualActingOut}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.sexualActingOut === ConductLegalProblem.sexualActingOut || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.sexualActingOut === ConductLegalProblem.sexualActingOut;
                        updateConductLegalProblem("sexualActingOut", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sexual Acting Out
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.stealing}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.stealing === ConductLegalProblem.stealing || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.stealing === ConductLegalProblem.stealing;
                        updateConductLegalProblem("stealing", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Stealing
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.arrestsConviction}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.arrestsConviction === ConductLegalProblem.arrestsConviction || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.arrestsConviction === ConductLegalProblem.arrestsConviction;
                        updateConductLegalProblem("arrestsConviction", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Arrests/Convictions
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.consistentIrresponsibility}
                      checked={
                        formData?.symptomChicklist?.conductLegalProblem?.symtoms?.consistentIrresponsibility ===
                          ConductLegalProblem.consistentIrresponsibility || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.conductLegalProblem?.symtoms?.consistentIrresponsibility ===
                          ConductLegalProblem.consistentIrresponsibility;
                        updateConductLegalProblem("consistentIrresponsibility", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Consistent Irresponsibility
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.fighting}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fighting === ConductLegalProblem.fighting || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.fighting === ConductLegalProblem.fighting;
                        updateConductLegalProblem("fighting", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fighting
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.impulsivity}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.impulsivity === ConductLegalProblem.impulsivity || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.impulsivity === ConductLegalProblem.impulsivity;
                        updateConductLegalProblem("impulsivity", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Impulsivity
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.propertyDestruction}
                      checked={
                        formData?.symptomChicklist?.conductLegalProblem?.symtoms?.propertyDestruction === ConductLegalProblem.propertyDestruction || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.conductLegalProblem?.symtoms?.propertyDestruction === ConductLegalProblem.propertyDestruction;
                        updateConductLegalProblem("propertyDestruction", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Property Destruction
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.substanceAbuse}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.substanceAbuse === ConductLegalProblem.substanceAbuse || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.substanceAbuse === ConductLegalProblem.substanceAbuse;
                        updateConductLegalProblem("substanceAbuse", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Substance Abuse
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.familyDesertion}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.familyDesertion === ConductLegalProblem.familyDesertion || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.familyDesertion === ConductLegalProblem.familyDesertion;
                        updateConductLegalProblem("familyDesertion", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Family Desertion
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.Other}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.other === ConductLegalProblem.Other || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.other === ConductLegalProblem.Other;
                        updateConductLegalProblem("Other", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Other:
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={ConductLegalProblem.none}
                      checked={formData?.symptomChicklist?.conductLegalProblem?.symtoms?.none === ConductLegalProblem.none || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.conductLegalProblem?.symtoms?.none === ConductLegalProblem.none;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            conductLegalProblem: {
                              ...formData?.symptomChicklist?.conductLegalProblem,
                              symtoms: {
                                ...formData?.symptomChicklist?.conductLegalProblem?.symtoms,
                                none: result ? "" : ConductLegalProblem.none,
                                fireSetting: "",
                                lying: "",
                                stealing: "",
                                fighting: "",
                                substanceAbuse: "",
                                oppositionalDefiant: "",
                                gangInvolvement: "",
                                arrestsConviction: "",
                                impulsivity: "",
                                familyDesertion: "",
                                exhibitionism: "",
                                sexualActingOut: "",
                                consistentIrresponsibility: "",
                                propertyDestruction: "",
                                other: "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        disabled={!edit}
                        data={conductLegalProblemComment || ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   symptomChicklist: {
                          //     ...formData?.symptomChicklist,
                          //     conductLegalProblem: { ...formData?.symptomChicklist?.conductLegalProblem, comment: data },
                          //   },
                          // });
                          setconductLegalProblemComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Psychosis:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.delusions}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.delusions === Psychosis.delusions || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.delusions === Psychosis.delusions;
                        updatePsychosis("delusions", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Delusions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.AuditoryHallucinations}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.auditoryHallucinations === Psychosis.AuditoryHallucinations || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.auditoryHallucinations === Psychosis.AuditoryHallucinations;
                        updatePsychosis("auditoryHallucinations", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Auditory hallucinations
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.ideasOfReference}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.ideasOfReference === Psychosis.ideasOfReference || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.ideasOfReference === Psychosis.ideasOfReference;
                        updatePsychosis("ideasOfReference", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Ideas of reference
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.paranoia}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.paranoia === Psychosis.paranoia || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.paranoia === Psychosis.paranoia;
                        updatePsychosis("paranoia", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Paranoia
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.visualHallucinations}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.visualHallucinations === Psychosis.visualHallucinations || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.visualHallucinations === Psychosis.visualHallucinations;
                        updatePsychosis("visualHallucinations", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Visual hallucinations
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.disorganizedSpeech}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.disorganizedSpeech === Psychosis.disorganizedSpeech || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.disorganizedSpeech === Psychosis.disorganizedSpeech;
                        updatePsychosis("disorganizedSpeech", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Disorganized speech and/or behavior
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.sensoryHallucinations}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.sensoryHallucinations === Psychosis.sensoryHallucinations || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.sensoryHallucinations === Psychosis.sensoryHallucinations;
                        updatePsychosis("sensoryHallucinations", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sensory hallucinations
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.none}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.none === Psychosis.none || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.none === Psychosis.none;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            psychosis: {
                              ...formData?.symptomChicklist?.psychosis,
                              symtoms: {
                                ...formData?.symptomChicklist?.psychosis?.symtoms,
                                none: result ? "" : Psychosis.none,
                                delusions: "",
                                paranoia: "",
                                sensoryHallucinations: "",
                                auditoryHallucinations: "",
                                visualHallucinations: "",
                                ideasOfReference: "",
                                disorganizedSpeech: "",
                                other: "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={Psychosis.other}
                      checked={formData?.symptomChicklist?.psychosis?.symtoms?.other === Psychosis.other || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.psychosis?.symtoms?.other === Psychosis.other;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            psychosis: {
                              ...formData?.symptomChicklist?.psychosis,
                              symtoms: {
                                ...formData?.symptomChicklist?.psychosis?.symtoms,
                                other: result ? "" : Psychosis.other,
                                none: "",
                                delusions: "",
                                paranoia: "",
                                sensoryHallucinations: "",
                                auditoryHallucinations: "",
                                visualHallucinations: "",
                                ideasOfReference: "",
                                disorganizedSpeech: "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Other:
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        disabled={!edit}
                        data={psychosisComment || ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   symptomChicklist: {
                          //     ...formData?.symptomChicklist,
                          //     psychosis: { ...formData?.symptomChicklist?.psychosis, comment: data },
                          //   },
                          // });
                          setPsychosisComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>

              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u> Anxiety Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.anxietyWorry}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.anxietyWorry == AnxietySymptoms.anxietyWorry || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.anxietyWorry == AnxietySymptoms.anxietyWorry;
                        updateAnxietySymptoms("anxietyWorry", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Anxiety/worry
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.somaticComplaints}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.somaticComplaints === AnxietySymptoms.somaticComplaints || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.somaticComplaints === AnxietySymptoms.somaticComplaints;
                        updateAnxietySymptoms("somaticComplaints", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Somatic complaints
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.hypervigilance}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.hypervigilance === AnxietySymptoms.hypervigilance || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.hypervigilance === AnxietySymptoms.hypervigilance;
                        updateAnxietySymptoms("hypervigilance", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Hypervigilance
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.avoidance}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidance === AnxietySymptoms.avoidance || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidance === AnxietySymptoms.avoidance;
                        updateAnxietySymptoms("avoidance", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Avoidance
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.dissociativeEpisodes}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.dissociativeEpisodes === AnxietySymptoms.dissociativeEpisodes || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.dissociativeEpisodes === AnxietySymptoms.dissociativeEpisodes;
                        updateAnxietySymptoms("dissociativeEpisodes", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Dissociative episodes
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.exaggeratedStartleResponse}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.exaggeratedStartleResponse === AnxietySymptoms.exaggeratedStartleResponse || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.anxietySymptoms?.symtoms?.exaggeratedStartleResponse === AnxietySymptoms.exaggeratedStartleResponse;
                        updateAnxietySymptoms("exaggeratedStartleResponse", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Exaggerated startle response
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.panicAttacks}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.panicAttacks === AnxietySymptoms.panicAttacks || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.panicAttacks === AnxietySymptoms.panicAttacks;
                        updateAnxietySymptoms("panicAttacks", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Panic Attacks
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.restrictedAffect}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.restrictedAffect === AnxietySymptoms.restrictedAffect || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.restrictedAffect === AnxietySymptoms.restrictedAffect;
                        updateAnxietySymptoms("restrictedAffect",!result)
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Restricted affect
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.nightmaresReoccurringDreams}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.nightmaresReoccurringDreams === AnxietySymptoms.nightmaresReoccurringDreams ||
                        false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.anxietySymptoms?.symtoms?.nightmaresReoccurringDreams === AnxietySymptoms.nightmaresReoccurringDreams;
                        updateAnxietySymptoms("nightmaresReoccurringDreams", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Nightmares, reoccurring dreams
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.obsessions}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.obsessions === AnxietySymptoms.obsessions || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.obsessions === AnxietySymptoms.obsessions;
                        updateAnxietySymptoms("obsessions", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Obsessions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.intrusivereOccurringThoughts}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intrusivereOccurringThoughts === AnxietySymptoms.intrusivereOccurringThoughts ||
                        false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intrusivereOccurringThoughts === AnxietySymptoms.intrusivereOccurringThoughts;
                        updateAnxietySymptoms("intrusivereOccurringThoughts", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Intrusivere occurring thoughts
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.irritabilityOrOutburstsOfAnger}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.irritabilityOrOutburstsOfAnger ===
                          AnxietySymptoms.irritabilityOrOutburstsOfAnger || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.anxietySymptoms?.symtoms?.irritabilityOrOutburstsOfAnger ===
                          AnxietySymptoms.irritabilityOrOutburstsOfAnger;
                        updateAnxietySymptoms("irritabilityOrOutburstsOfAnger", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Irritability or outbursts of anger
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.compulsions}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.compulsions === AnxietySymptoms.compulsions || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.compulsions === AnxietySymptoms.compulsions;
                        updateAnxietySymptoms("compulsions", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Compulsions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.difficultyConcentrating}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.difficultyConcentrating === AnxietySymptoms.difficultyConcentrating || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.anxietySymptoms?.symtoms?.difficultyConcentrating === AnxietySymptoms.difficultyConcentrating;
                        updateAnxietySymptoms("difficultyConcentrating", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty concentrating
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.senseOfForeshortenedFuture}
                      checked={
                        formData?.symptomChicklist?.anxietySymptoms?.symtoms?.senseOfForeshortenedFuture === AnxietySymptoms.senseOfForeshortenedFuture || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.anxietySymptoms?.symtoms?.senseOfForeshortenedFuture === AnxietySymptoms.senseOfForeshortenedFuture;
                        updateAnxietySymptoms("senseOfForeshortenedFuture", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Sense of foreshortened future
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.markedlyDiminished}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.markedlyDiminished === AnxietySymptoms.markedlyDiminished || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.markedlyDiminished === AnxietySymptoms.markedlyDiminished;
                        updateAnxietySymptoms("markedlyDiminished", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Markedly diminished interest/participation in
                      <br /> usual activities
                    </label>
                  </Col>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.avoidanceOfActivities}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidanceOfActivities === AnxietySymptoms.avoidanceOfActivities || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.avoidanceOfActivities === AnxietySymptoms.avoidanceOfActivities;
                        updateAnxietySymptoms("avoidanceOfActivities", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Avoidance of activities or situations that remind of <br />a stressful experience
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.intensePsychological}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intensePsychological === AnxietySymptoms.intensePsychological || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.intensePsychological === AnxietySymptoms.intensePsychological;
                        updateAnxietySymptoms("intensePsychological", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Intense psychological or physical reactivity to <br />
                      exposure to cues that represent traumatic event
                    </label>
                  </Col>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.unwantedMemories}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.unwantedMemories === AnxietySymptoms.unwantedMemories || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.unwantedMemories === AnxietySymptoms.unwantedMemories;
                        updateAnxietySymptoms("unwantedMemories", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Unwanted memories,thoughts,or images of a <br />
                      stressful experience/flashbacks
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.feelingOFDetachment}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.feelingOFDetachment === AnxietySymptoms.feelingOFDetachment || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.feelingOFDetachment === AnxietySymptoms.feelingOFDetachment;
                        updateAnxietySymptoms("feelingOFDetachment", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Feeling of detachment or estrangement from others
                    </label>
                  </Col>
                  <Col xl={6} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AnxietySymptoms.none}
                      checked={formData?.symptomChicklist?.anxietySymptoms?.symtoms?.none === AnxietySymptoms.none || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.anxietySymptoms?.symtoms?.none === AnxietySymptoms.none;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            anxietySymptoms: {
                              ...formData?.symptomChicklist?.anxietySymptoms,
                              symtoms: {
                                ...formData?.symptomChicklist?.anxietySymptoms?.symtoms,
                                none: result ? "" : AnxietySymptoms.none,
                                anxietyWorry: "",
                                avoidance: "",
                                panicAttacks: "",
                                obsessions: "",
                                compulsions: "",
                                markedlyDiminished: "",
                                intensePsychological: "",
                                feelingOFDetachment: "",
                                somaticComplaints: "",
                                dissociativeEpisodes: "",
                                restrictedAffect: "",
                                intrusivereOccurringThoughts: "",
                                difficultyConcentrating: "",
                                hypervigilance: "",
                                exaggeratedStartleResponse: "",
                                nightmaresReoccurringDreams: "",
                                irritabilityOrOutburstsOfAnger: "",
                                senseOfForeshortenedFuture: "",
                                avoidanceOfActivities: "",
                                unwantedMemories: "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        disabled={!edit}
                        data={anxietySymptomsComment || ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   symptomChicklist: {
                          //     ...formData?.symptomChicklist,
                          //     anxietySymptoms: { ...formData?.symptomChicklist?.anxietySymptoms, comment: data },
                          //   },
                          // });
                          setAnxietySymptomsComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>Attention Symptoms:</u>
                  </label>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.failsToFinishTasks}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToFinishTasks === AttentionSymptoms.failsToFinishTasks || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToFinishTasks === AttentionSymptoms.failsToFinishTasks;
                        updateAttentionSymptoms("failsToFinishTasks", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fails to finish tasks
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.difficultyOrganizingThoughts}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyOrganizingThoughts ===
                          AttentionSymptoms.difficultyOrganizingThoughts || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyOrganizingThoughts ===
                          AttentionSymptoms.difficultyOrganizingThoughts;
                        updateAttentionSymptoms("difficultyOrganizingThoughts", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty organizing thoughts
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.FeelsAlways}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.feelsAlways === AttentionSymptoms.FeelsAlways || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.feelsAlways === AttentionSymptoms.FeelsAlways;
                        updateAttentionSymptoms("feelsAlways", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Feels always “on the go”,run like a motor
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.inattentive}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.inattentive === AttentionSymptoms.inattentive || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.inattentive === AttentionSymptoms.inattentive;
                        updateAttentionSymptoms("inattentive", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Inattentive
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.difficultyAwaitingTurn}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyAwaitingTurn === AttentionSymptoms.difficultyAwaitingTurn || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyAwaitingTurn === AttentionSymptoms.difficultyAwaitingTurn;
                        updateAttentionSymptoms("difficultyAwaitingTurn", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty awaiting turn
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.FailsToGiveAttention}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToGiveAttention === AttentionSymptoms.FailsToGiveAttention || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.failsToGiveAttention === AttentionSymptoms.FailsToGiveAttention;
                        updateAttentionSymptoms("failsToGiveAttention", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fails to give attention to detail
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.fidgety}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.fidgety === AttentionSymptoms.fidgety || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.fidgety === AttentionSymptoms.fidgety;
                        updateAttentionSymptoms("fidgety", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Fidgety
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.poorAttentionSpan}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.poorAttentionSpan === AttentionSymptoms.poorAttentionSpan || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.poorAttentionSpan === AttentionSymptoms.poorAttentionSpan;
                        updateAttentionSymptoms("poorAttentionSpan", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Poor attention span
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.AvoidsDislikesTasks}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.avoidsDislikesTasks === AttentionSymptoms.AvoidsDislikesTasks || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.avoidsDislikesTasks === AttentionSymptoms.AvoidsDislikesTasks;
                        updateAttentionSymptoms("avoidsDislikesTasks", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Avoids,dislikes tasks that require sustained mental effort
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.forgetful}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.forgetful === AttentionSymptoms.forgetful || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.forgetful === AttentionSymptoms.forgetful;
                        updateAttentionSymptoms("forgetful", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Forgetful
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger" ,minWidth:"1rem"}}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.talksExcessively}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.talksExcessively === AttentionSymptoms.talksExcessively || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.talksExcessively === AttentionSymptoms.talksExcessively;
                        updateAttentionSymptoms("talksExcessively", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Talks excessively
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.EasilyDistracted}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.easilyDistracted === AttentionSymptoms.EasilyDistracted || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.easilyDistracted === AttentionSymptoms.EasilyDistracted;
                        updateAttentionSymptoms("easilyDistracted", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Easily distracted by extraneous stimuli
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.difficultyFollowingDirections}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyFollowingDirections ===
                          AttentionSymptoms.difficultyFollowingDirections || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.attentionSymptoms?.symtoms?.difficultyFollowingDirections ===
                          AttentionSymptoms.difficultyFollowingDirections;
                        updateAttentionSymptoms("difficultyFollowingDirections", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Difficulty following directions
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.interruptsOrIntrudesOnOthers}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.interruptsOrIntrudesOnOthers ===
                          AttentionSymptoms.interruptsOrIntrudesOnOthers || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.attentionSymptoms?.symtoms?.interruptsOrIntrudesOnOthers ===
                          AttentionSymptoms.interruptsOrIntrudesOnOthers;
                        updateAttentionSymptoms("interruptsOrIntrudesOnOthers", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Interrupts or intrudes on others
                    </label>
                  </Col>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.BlurtsOutAnswersBeforeQuestions}
                      checked={
                        formData?.symptomChicklist?.attentionSymptoms?.symtoms?.blurtsOutAnswersBeforeQuestions ===
                          AttentionSymptoms.BlurtsOutAnswersBeforeQuestions || false
                      }
                      onClick={() => {
                        const result =
                          formData?.symptomChicklist?.attentionSymptoms?.symtoms?.blurtsOutAnswersBeforeQuestions ===
                          AttentionSymptoms.BlurtsOutAnswersBeforeQuestions;
                        updateAttentionSymptoms("blurtsOutAnswersBeforeQuestions", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Blurts out answers before questions have been completed
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={AttentionSymptoms.none}
                      checked={formData?.symptomChicklist?.attentionSymptoms?.symtoms?.none === AttentionSymptoms.none || false}
                      onClick={() => {
                        const result = formData?.symptomChicklist?.attentionSymptoms?.symtoms?.none === AttentionSymptoms.none;
                        setFormData({
                          ...formData,
                          symptomChicklist: {
                            ...formData?.symptomChicklist,
                            attentionSymptoms: {
                              ...formData?.symptomChicklist?.attentionSymptoms,
                              symtoms: {
                                ...formData?.symptomChicklist?.attentionSymptoms?.symtoms,
                                none: result ? "" : AttentionSymptoms.none,
                                failsToFinishTasks: "",
                                inattentive: "",
                                fidgety: "",
                                forgetful: "",
                                difficultyFollowingDirections: "",
                                difficultyOrganizingThoughts: "",
                                difficultyAwaitingTurn: "",
                                poorAttentionSpan: "",
                                talksExcessively: "",
                                interruptsOrIntrudesOnOthers: "",
                                feelsAlways: "",
                                failsToGiveAttention: "",
                                avoidsDislikesTasks: "",
                                easilyDistracted: "",
                                blurtsOutAnswersBeforeQuestions: "",
                              },
                            },
                          },
                        });
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS: ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        disabled={!edit}
                        data={attentionSymptomsComment ?? ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData({
                          //   ...formData,
                          //   symptomChicklist: {
                          //     ...formData?.symptomChicklist,
                          //     attentionSymptoms: {
                          //       ...formData?.symptomChicklist?.attentionSymptoms,
                          //       comment: data,
                          //     },
                          //   },
                          // });
                          setAttentionSymptomsComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>
            </Card>
          </div>
        </Row>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                nextStep();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 2 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormTwo;
