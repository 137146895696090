import React, { useContext, useEffect, useState } from "react";
import warning from "../../../assets/images/icons/warning.png";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  ButtonGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import "src/assets/css/admin.css";
import { AdminService } from "src/services/AdminService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Client, PremiumStatus } from "src/models/Client";
import { UserRole, GenderTypes, UserStatus } from "src/models/User";
import { SubscriptionStatus } from "src/models/CardTypes";
import { SocketContext } from "src/context/ScoketContext";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import UserContext from "src/context/UserContext";
import { NotificationService } from "src/services/NotificationService";
import Spinner from "src/common/spinner/spinner";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProfileModal from "src/pages/Popup/AdminStatModals/profileModal";
import DeleteModal from "src/components/Common/DeleteModal";
import ReminderModal from "src/pages/Popup/AdminStatModals/reminderModal";
import MatchModal from "src/pages/Popup/AdminStatModals/matchModal";
import ResetModal from "src/pages/Popup/AdminStatModals/resetModal";
import AppointmentModal from "src/pages/Popup/AdminStatModals/ScheduleAppointmentModal";
import User from "../../../assets/images/icons/user.png";
import Trash from "../../../assets/images/icons/trash.png";
import File from "../../../assets/images/icons/file.png";
import Calendar from "../../../assets/images/icons/calendar.png";
import UserTick from "../../../assets/images/icons/user-tick.png";
import Lock from "../../../assets/images/icons/lock.png";
import moment from 'moment';
import Close from "../../../assets/images/icons/close.png";

const ClientTable: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [userCount, setUserCount] = useState(10);
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isGender, setIsGender] = useState(GenderTypes.ALL as GenderTypes);
  const [isSubscription, setSubscription] = useState(SubscriptionStatus.ALL as SubscriptionStatus);
  const [isSearchableString, setIsSearchableString] = useState({
    searchableString: null,
    status: null,
    zipCode: null,
  } as any);
  const socket = useContext(SocketContext);
  const [user] = useContext(UserContext);
  const [isView2, setIsView2] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [modalOpenRemove, setModalOpenRemove] = useState(false);
  const [modalOpenReminder, setModalOpenReminder] = useState(false);
  const [modalOpenSession, setModalOpenSession] = useState(false);
  const [modalOpenMatch, setModalOpenMatch] = useState(false);
  const [modalOpenReset, setModalOpenReset] = useState(false);
  const [clientId, setClientId] = useState<string>("");
  const [clientData, setClientData] = useState<Client>({} as Client);
  const [openClientSearchModal, setOpenClientSearchModal] = useState(false)
  const [isOpenBlockReasonModal, setIsOpenBlockReasonModal] = useState(false)
  const [reason, setReason] = useState({ sendReason: "" });
  const [reasonError, setReasonError] = useState(false)

  useEffect(() => {
    searchClients();
  }, []);

  const toggleClientSearchModal = () => {
    setOpenClientSearchModal(!openClientSearchModal);
  };

  const toggleModalProfile = () => {
    setModalOpenProfile(!modalOpenProfile);
  };
  const toggleModalRemove = () => {
    setModalOpenRemove(!modalOpenRemove);
  };
  const toggleModalReminder = () => {
    setModalOpenReminder(!modalOpenReminder);
  };
  const toggleModalSession = () => {
    setModalOpenSession(!modalOpenSession);
  };
  const toggleModalMatch = () => {
    setModalOpenMatch(!modalOpenMatch);
  };
  const toggleModalReset = () => {
    setModalOpenReset(!modalOpenReset);
  };

  const searchClients = () => {
    const data = {
      searchableString: isSearchableString.searchableString,
      monthAndYear: moment(selectedMonth).format("MM/YYYY"),
    };

    setIsLoading(true);

    AdminService.searchClientStatistics(data, limit, 0).then(res => {
      if (res.success) {
        setClients(res.data?.userSet);
        setUserCount(res.data?.userSet.length);
        setOffset(0);
        setOpenClientSearchModal(false)
      }

      setIsLoading(false);
    });
  };

  const navigateToProfile = (userId: string | undefined) => {
    if (userId != undefined) history.push(`/public-profile/${userId}`);
  };

  const navigateToInsurance = (userId: string | undefined) => {
    if (userId != undefined) history.push(`/insurance/${userId}`);
  };

  const blockUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to block?",
        showCancelButton: true,
        confirmButtonText: "Block",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            userId: userId,
          };
          AdminService.blockUserByAdmin(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User blocked!",
                confirmButtonColor: "#FD7F00",
              });
              const clientKey = clients.findIndex((client: Client) => client._id == userId);
              const previousClient = { ...clients[clientKey] };
              const updatedClient = { ...previousClient, blockedByAdmin: true };
              clients[clientKey] = updatedClient;
              setClients(clients);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to block the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const unblockUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to unblock?",
        showCancelButton: true,
        confirmButtonText: "Unblock",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            userId: userId,
          };
          AdminService.unblockUserByAdmin(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User unblocked!",
                confirmButtonColor: "#FD7F00",
              });
              const clientKey = clients.findIndex((data: any) => data?.client?._id === userId);

              if (clientKey !== -1) {
                const previousClient = { ...clients[clientKey] };
                const updatedClient = {
                  ...previousClient,
                  client: { ...previousClient.client, blockedByAdmin: false }
                };
                clients[clientKey] = updatedClient;
              }
              setClients(clients);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to unblock the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const deleteUser = (clientId: string | undefined) => {
    if (clientId) {
      const data = {
        userId: clientId,
      };

      AdminService.blockUserByAdmin(data).then(res => {
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "User Blocked!",
            confirmButtonColor: "#FD7F00",
          });

          searchClients();

          toggleReasonModal();
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to delete the user.",
            confirmButtonColor: "#FD7F00",
          });
        }
      });
    }
  };

  const togglePremiumMembership = (userId: string | undefined) => {
    if (userId) {
      const clientKey = clients.findIndex((client: Client) => client._id == userId);
      const previousClient = { ...clients[clientKey] };

      if (
        (previousClient.premiumStatus == null ||
          previousClient.premiumStatus == PremiumStatus.REVOKED ||
          previousClient.premiumStatus == PremiumStatus.NOT_SET) &&
        previousClient.insuranceId !== undefined
      ) {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to set this client a premium user?",
          showCancelButton: true,
          confirmButtonText: "Sure",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
        }).then(result => {
          if (result.isConfirmed) {
            AdminService.togglePremiumMembership(userId).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "Membership upgraded!",
                  confirmButtonColor: "#FD7F00",
                });

                const updatedClient = { ...previousClient, premiumStatus: PremiumStatus.ACTIVE };
                clients[clientKey] = updatedClient;
                setClients(clients);

                const PremiumUserNotification: NotificationModel = {
                  senderId: user?._id,
                  receiverId: userId,
                  event: NotificationEvent.BECAME_PREMIUM,
                  link: "/payment-history/" + userId,
                  content: "Congratulations! You have been granted premium user membership.",
                  variant: NotificationVarient.SUCCESS,
                };

                NotificationService.sendNotification(PremiumUserNotification).then(res => {
                  const socketData: sendNotificationSocketData = {
                    socketId: res.data.socketId,
                    notifyData: PremiumUserNotification,
                    senderId: user?._id,
                    receiverId: userId,
                  };

                  socket.emit("send-notification", socketData);
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Failed upgrade the membership",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to set this client a generic user?",
          showCancelButton: true,
          confirmButtonText: "Sure",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
        }).then(result => {
          if (result.isConfirmed) {
            AdminService.togglePremiumMembership(userId).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "Membership downgraded!",
                  confirmButtonColor: "#FD7F00",
                });

                const updatedClient = { ...previousClient, premiumStatus: PremiumStatus.REVOKED };
                clients[clientKey] = updatedClient;
                setClients(clients);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Failed downgrade the membership",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      }
    }
  };

  const seeMore = () => {
    const updatedSkip = offset + 10;

    setOffset(updatedSkip);

    const data = {
      searchableString: isSearchableString.searchableString,
      gender: isGender,
      status: isSearchableString.status,
      isSubscription: isSubscription,
      zipCode: isSearchableString.zipCode,
      role: UserRole.CLIENT,
    };

    setIsLoading(true);

    AdminService.searchClientStatistics(data, 10, updatedSkip).then(res => {
      if (res.success) {
        res.data.userSet.map((client: any) => {
          setClients(clients => [...clients, client]);
        });

        setUserCount(res.data?.userSet.length);
      }

      setIsLoading(false);
    });
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  };

  const navigateToClientSessions = (clientId: string | undefined) => {
    if (clientId) history.push(`/client-sessions/${clientId}`);
  };

  const handleDateChange = (date: any) => {
    setSelectedMonth(date);
  };

  const hideAndShow2 = () => {
    setIsView2(!isView2);
  };

  const eventModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleCreateClient = () => {
    setShowModal(false);
  };

  const handleButtonClick = () => {
    Swal.fire({
      title: "",
      text: "implementation in process..",
      icon: "info",
      confirmButtonText: "OK",
    });
  };
  const toggleReasonModal = () => {
    setIsOpenBlockReasonModal(!isOpenBlockReasonModal)
    setReasonError(false)
    setReason({ ...reason, sendReason: "" })
  }
  return (
    <React.Fragment>
      <Container fluid>
        <Card className="card">
          <CardBody>
            <Row onClick={isView2 ? hideAndShow2 : hideAndShow2} className={`${isView2 ? "cursor-p" : "cursor-p"}`}>
              <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="d-flex gap-4 col-6">
                <Col className="d-flex justify-content-center align-items-center" md={12} lg={12} xl={12} xxl={12}>
                  <h4 className="card-title " style={{ flex: 1 }}>
                    Client Profile
                  </h4>
                </Col>
              </Col>
              <Col sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex justify-content-end align-items-center gap-md-2 col-6">
                {!isView2 && (
                  <>
                    <div className="d-sm-flex justify-content-center pr-2 d-none d-sm-block">
                      <div className="d-flex justify-content-center align-items-center pr-2">
                        <span className="text-center mb-0 single-line-text mr-2">Search : </span>
                      </div>
                      <div onClick={(event) => event.stopPropagation()}>
                        <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                      </div>

                    </div>
                    <div onClick={(event) => event.stopPropagation()}>
                      <DatePicker
                        selected={selectedMonth}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="Select Month"
                        className="form-control d-none d-sm-block"
                      />
                    </div>

                    <span className="btn btn-warning  btn-sm  mx-2 d-none d-sm-block"
                      onClick={(event) => {
                        event.stopPropagation();
                        searchClients();
                      }}
                    >
                      <i className="bx bx-search"></i>
                    </span>

                    <span className="btn btn-warning  btn-sm  mx-2 d-sm-none" onClick={(event) => {
                      event.stopPropagation(); toggleClientSearchModal();
                    }}>
                      <i className="bx bx-search"></i>
                    </span>

                    <Modal isOpen={openClientSearchModal} centered style={{ overflow: "visible" }}>
                      <div className="modal-body" style={{ overflow: "visible" }}>
                        <button type="button" onClick={() => toggleClientSearchModal()} className="close" data-dismiss="modal" aria-label="Close">
                          <span>&times;</span>
                        </button>
                        <CardBody style={{ overflow: "visible" }}>
                          <CardTitle className="mb-4 text-center font-size-15">
                            Search Clients
                          </CardTitle>
                          <Row>
                            <div className="d-flex justify-content-center">
                              <div className="d-flex justify-content-center align-items-center pr-2">
                                <span className="text-center mb-0 single-line-text mr-2">Search: </span>
                              </div>
                              <input className="search-input-stat form-control" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                            </div>
                          </Row>
                          <Row className="mt-2 mb-2">
                            <DatePicker
                              selected={selectedMonth}
                              onChange={handleDateChange}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              placeholderText="Select Month"
                              className="form-control"
                            />
                          </Row>
                          <div className="d-flex justify-content-center flexColumn">
                            <button type="button" onClick={() => searchClients()} className="btn btn-primary btnMargin">
                              Search
                            </button>
                          </div>
                        </CardBody>
                      </div>
                    </Modal>

                  </>
                )}

                <div className="d-sm-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow2}>
                  {isView2 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                </div>
              </Col>
            </Row>
            {!isView2 && (
              <>
                <Row>
                  <div className={!isView2 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                    <Col sm="12" className="mt10">
                      {clients !== undefined && clients.length > 0 ? (
                        <>
                          <div className="table-responsive" style={{ maxHeight: "235px", overflowY: "auto" }}>
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Gender</th>
                                  <th scope="col">State</th>
                                  <th scope="col">User Life Time Value</th>
                                  <th scope="col">Number of Missed Appointments</th>
                                  <th scope="col">Verified Status</th>
                                  <th scope="col">Avatar Status</th>
                                  <th scope="col" style={{ textAlign: "center" }}>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {clients &&
                                  clients.map((data: any, index: number) => (
                                    <tr key={data?._id}>
                                      <th scope="row">{index + 1}</th>

                                      <td>
                                        {data?.firstname && data?.firstname.charAt(0) + ". "}{data?.lastname && data?.lastname}
                                      </td>

                                      <td>{data?.gender}</td>

                                      <td>
                                        <>{data?.state}</>
                                      </td>

                                      <td>
                                        <span className="d-flex justify-content-center align-item-center"> ${(data?.completedMeetingCount * data?.insuranceCompany.contractPrice).toFixed(2)}</span>
                                      </td>

                                      <td>
                                        <span className="d-flex justify-content-center align-item-center"> {data?.missedAppointmentCount}</span>
                                      </td>

                                      <td>
                                        {data?.verifiedStatus == "VERIFIED" ? (
                                          <span className="verified-2">Verified</span>
                                        ) : (
                                          <span className="pending-2">Pending</span>
                                        )}
                                      </td>

                                      <td>
                                        <>{data?.avatarId ? <span className="enable-2">Enable</span> : <span className="disable-2">Disable</span>}</>
                                      </td>


                                      <td>
                                        <div className="flex">
                                          <button
                                            className="btn btn-sm btn-success mr-2"
                                            onClick={() => {
                                              toggleModalProfile();
                                              setClientId(data?._id);
                                            }}
                                            title="View Profile"
                                          >
                                            <img src={User} />
                                          </button>
                                          {data?.blockedByAdmin ? (
                                            <button className="btn-sm btn btn-success mr-2" onClick={() => unblockUser(data?._id)} title="Unblock Client">
                                              <i className="fa fa-unlock"></i>{data?.blockedByAdmin}
                                            </button>
                                          ) : (
                                            <button
                                              className="btn-sm btn btn-danger mr-2"
                                              onClick={() => {
                                                toggleReasonModal()
                                                setClientId(data?._id);
                                              }}
                                              title="Block Client"
                                            >
                                              <i className="fa fa-ban"></i>
                                            </button>
                                          )}

                                          <button className="btn-sm btn btn-primary btn-rose mr-2" title="Send me a reminder" onClick={() => { toggleModalReminder(); setClientId(data?._id) }}>
                                            <img src={File} />
                                          </button>
                                          <button
                                            className="btn-sm btn btn-insurance mr-2"
                                            onClick={() => {
                                              toggleModalSession();
                                              setClientData(data);
                                            }}
                                            title="Schedule Appointment"
                                          >
                                            <img src={Calendar} />
                                          </button>
                                          <button
                                            className="btn-sm btn btn-danger mr-2 btn-match"
                                            onClick={() => {
                                              toggleModalMatch();
                                              setClientData(data);
                                            }}
                                            title="Match with clinician"
                                          >
                                            <img src={UserTick} />
                                          </button>
                                          <button className="btn-sm btn btn-danger mr-2" onClick={() => { toggleModalReset(); setClientId(data?._id) }} title="Reset password">
                                            <img src={Lock} />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          
                          {userCount >= 10 && (
                            <>
                              <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                See More
                              </button>
                            </>
                          )}
                          {userCount == 0 && <span>No more clients available...</span>}
                        </>
                      ) : (
                        <>{isLoading ? <Spinner /> : <div>No Clients</div>}</>
                      )}
                    </Col>
                  </div>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
        <Modal isOpen={isOpenBlockReasonModal} toggle={toggleReasonModal} centered className="modal-lg">
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleReasonModal}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="ps-4 pe-4">
            <Row>
              <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Block Client </span>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p className="mb-1">Reason</p>
                <textarea rows={4} style={{ width: "100%" }} placeholder="Type a reason" onChange={(e: any) => setReason({ ...reason, sendReason: e.target.value })}></textarea>
                {
                  reasonError && <p className="text-danger f-10">Please enter reason</p>
                }
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="md-8"></Col>
              <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() => deleteUser(clientId)}>Block</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Offcanvas className="offcanvas-end2" isOpen={isFilterOptions} direction="end" toggle={toggleFilter}>
          <OffcanvasHeader toggle={toggleFilter}>Filter Clients</OffcanvasHeader>

          <OffcanvasBody>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Name or Email</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search Users (ex: first name, last name or email)"
                    type="text"
                    onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Zip Code</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search ZipCode"
                    type="text"
                    onChange={(e: any) => setIsSearchableString({ ...isSearchableString, zipCode: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={12}>
                <Label>Gender</Label>
                <ButtonGroup className="flex-wrap flex w-100">
                  <Button
                    color="primary"
                    defaultChecked
                    defaultValue={GenderTypes.ALL}
                    outline
                    onClick={() => setIsGender(GenderTypes.ALL)}
                    active={isGender === GenderTypes.ALL}
                  >
                    All
                  </Button>
                  <Button color="primary" outline onClick={() => setIsGender(GenderTypes.MALE)} active={isGender === GenderTypes.MALE}>
                    Male
                  </Button>
                  <Button color="primary" outline onClick={() => setIsGender(GenderTypes.FEMALE)} active={isGender === GenderTypes.FEMALE}>
                    Female
                  </Button>
                  <Button color="primary" outline onClick={() => setIsGender(GenderTypes.NonBinary)} active={isGender === GenderTypes.NonBinary}>
                    Non Binary
                  </Button>
                  <Button color="primary" outline onClick={() => setIsGender(GenderTypes.OTHER)} active={isGender === GenderTypes.OTHER}>
                    Other
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={12}>
                <Label>Verified Status</Label>
                <FormGroup>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e: any) => setIsSearchableString({ ...isSearchableString, status: e.target.value })}
                  >
                    <option value={UserStatus.ALL}> All </option>
                    <option value={UserStatus.VERIFIED}> Verified </option>
                    <option value={UserStatus.PENDING}> Pending </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={12}>
                <Label>Subscription Status</Label>
                <ButtonGroup className="w-100">
                  <Button
                    color="primary"
                    defaultChecked
                    defaultValue={SubscriptionStatus.ALL}
                    outline
                    onClick={() => setSubscription(SubscriptionStatus.ALL)}
                    active={isSubscription === SubscriptionStatus.ALL}
                  >
                    ALL
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setSubscription(SubscriptionStatus.ACTIVE)}
                    active={isSubscription === SubscriptionStatus.ACTIVE}
                  >
                    Active
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setSubscription(SubscriptionStatus.PAST_DUE)}
                    active={isSubscription === SubscriptionStatus.PAST_DUE}
                  >
                    Past due
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setSubscription(SubscriptionStatus.CANCELED)}
                    active={isSubscription === SubscriptionStatus.CANCELED}
                  >
                    cancelled
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col lg={12}>
                <button className="btn btn-success w-100" onClick={() => searchClients()}>
                  Search
                </button>
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      </Container>
      <Modal isOpen={showModal} centered toggle={eventModalToggle} unmountOnClose={true}>
        <ModalHeader toggle={eventModalToggle}>Create Client</ModalHeader>

        <ModalBody className="awsome-area">
          <Row className="mb-4">
            <Col lg={2}>
              <label>Name</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Name"
              // onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              // value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row>
          {/* <Row className="mb-4">
            <Col lg={2}>
              <label>Last name</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Last Name"
              onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row> */}
          <Row className="mb-4">
            <Col lg={2}>
              <label>Email</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Email"
              // onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              // value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={2}>
              <label>Password</label>
            </Col>
            <Col lg={10}>
              <Input type="password" name="repeat" placeholder="Password" />
            </Col>
          </Row>
          <Row>
            <Col>
              <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={handleCreateClient}>
                Create
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <ProfileModal isOpen={modalOpenProfile} toggle={toggleModalProfile} clientId={clientId} />

      <ReminderModal isOpen={modalOpenReminder} toggle={toggleModalReminder} clientId={clientId} />
      <AppointmentModal isOpen={modalOpenSession} toggle={toggleModalSession} setIsOpen={setModalOpenSession} clientData={clientData} />
      <MatchModal isOpen={modalOpenMatch} toggle={toggleModalMatch} clientData={clientData} />
      <ResetModal isOpen={modalOpenReset} toggle={toggleModalReset} clientId={clientId} />
      {/* delete modal */}
      <Modal isOpen={modalOpenRemove} toggle={toggleModalRemove} centered>
        <ModalBody>
          <div className="text-center">
            
            <div className="d-flex justify-content-center align-items-center">
              <span className="bg-light-red mb-3 btn-48 d-flex justify-content-center align-items-center">
                <img src={warning} className="btn-s-24" />
              </span>
            </div>
            <div>
              <h5 className="mb-2">Delete Client</h5>
            </div>
            <div>
              <p className="alertText mt-2">Are you sure you want to delete this Client ?</p>
            </div>
            <div className="mt-2">
              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  deleteUser(clientId);
                }}
              >
                Yes
              </button>
              <button className="btn btn-secondary ms-2" style={{ background: "#34C38F", borderBlock: "34C38F" }} onClick={toggleModalRemove}>
                No
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ClientTable;
