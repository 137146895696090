import React, { useState } from "react";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import Close from "../../../assets/images/icons/close.png";
import { AdminService } from "src/services/AdminService";
import { toast } from "react-toastify";
import Spinner from "src/common/spinner/spinner";
import moment from "moment";

interface Props {
  treatmentId: any;
}

const EraListViewComp = ({ treatmentId }: Props) => {
  const [isOpenView, setIsOpenView] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const [eraList, setEraList] = useState<any[]>([]);

  const toggleModalForView = () => {
    if (!isOpenView) {
      setIsLoadingData(true);
      setIsLoadingError(false);
      setEraList([]);
      setIsOpenView(!isOpenView);
      getEraList();
    } else {
      setIsOpenView(!isOpenView);
      setIsLoadingData(true);
      setIsLoadingError(false);
      setEraList([]);
    }
  };

  const getEraList = async () => {
    setIsLoadingData(true);
    setIsLoadingError(false);
    setEraList([]);

    const data = {
      treatmentId: treatmentId,
    };

    const res = await AdminService.getEraListForClaim(data);

    if (res?.success && res?.data) {
      setEraList(res?.data);
    } else {
      setIsLoadingError(true);
      toast.error(`Error loading data`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }

    setIsLoadingData(false);
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-sm btn-success mr-2"
        onClick={() => {
          toggleModalForView();
        }}
        title="View Insurance"
      >
        View
      </button>
      <Modal
        isOpen={isOpenView}
        toggle={toggleModalForView}
        centered
        backdrop="static"
        keyboard={false}
        style={{ maxWidth: "none", paddingLeft: "5vw", paddingRight: "5vw", width: "auto" }}
      >
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <Button
            close
            onClick={toggleModalForView}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>
        <Row>
          <span className="font-size-18 profileFont mb-2 mt-2 cursor-pointer pt-2 text-center">ERA Data List</span>
        </Row>
        <ModalBody style={{ maxHeight: "75vh", overflowY: "auto"}}>
          {isLoadingData ? (
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: "20vh"}}>
              <Spinner></Spinner>
            </div>
          ) : isLoadingError ? (
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: "20vh"}}>
              <span className="font-size-16 profileFont mb-2 cursor-pointer pt-2 text-center">Error Loading Data</span>
            </div>
          ) : (
            <div>
              {eraList && eraList?.length && eraList?.length > 0 ? (
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className="table table-bordered" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">PCN</th>
                        <th scope="col">Date of Service</th>
                        <th scope="col">Claim Recieved Date</th>
                        <th scope="col">Total Charge</th>
                        <th scope="col">Total Paid</th>
                        <th scope="col">Charge Details</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eraList.map((eraData: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{`${eraData?.pat_name_f ? eraData?.pat_name_f.charAt(0) : ""}. ${eraData?.pat_name_l ?? ""}`}</td>
                            <td>{eraData?.pcn ?? "-"}</td>
                            <td>{eraData?.from_dos ? moment(eraData?.from_dos).format("YYYY/MM/DD hh:mm A") : "-"}</td>
                            <td>{eraData?.claim_received_date ? moment(eraData?.claim_received_date).format("YYYY/MM/DD hh:mm A") : "-"}</td>
                            <td>{eraData?.total_charge ?? "-"}</td>
                            <td>{eraData?.total_paid ?? "-"}</td>
                            <td>
                              {eraData?.charge && eraData?.charge?.length && eraData?.charge?.length > 0 ? (
                                <div>
                                  {eraData?.charge.map((chargeData: any, index: any) => (
                                    <div key={index} className="bg-success text-white d-flex flex-column rounded mb-2 p-2">
                                      <div>{`chgid: ${chargeData?.chgid ?? "-"}`}</div>
                                      <div>{`paid: ${chargeData?.paid ?? "-"}`}</div>
                                      <div>{`allowed: ${chargeData?.allowed ?? "-"}`}</div>
                                      <div>{`adjustment:`}</div>
                                      {chargeData?.adjustment && chargeData?.adjustment?.length && chargeData?.adjustment?.length > 0
                                        ? chargeData?.adjustment.map((adjustmentData: any, index: any) => (
                                            <div key={index} className="bg-success text-white d-flex flex-column rounded mb-1 ps-2">
                                              <div>{`code: ${adjustmentData?.code ?? "-"}`}</div>
                                            </div>
                                          ))
                                        : "-"}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                            {eraData?.status == "COMPLETED" ? (
                              <td>
                                <span className="verified-2">SUCCESS</span>
                              </td>
                            ) : eraData?.status == "FAILED" ? (
                              <td>
                                <span className="inactive-2">FAILED</span>
                              </td>
                            ) : (
                              <td>
                                <span className="notSubmitted-2">PENDING</span>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div></div>
              )}

              {!eraList || !eraList?.length || eraList?.length <= 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{minHeight: "20vh"}}>
                  <span className="font-size-16 profileFont mb-2 cursor-pointer pt-2 text-center">No Era Data found</span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EraListViewComp;
