import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Creatable from "react-select/creatable";
import { ArticleService } from "../../../services/ArticleService";
import { ExperienceTag, SelectedExperienceTag } from "../../../models/ExperienceTag";
import { Tags } from "../../../common/custom-components/Tags";
import { HashTag } from "../../../models/HashTag";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import Spinner from "../../../common/spinner/spinner";
import { ArticleDetails } from "../../../models/Article";
import { ProgressBar } from "react-bootstrap";
import { Util } from "../../../Util";
import { MediaUploaderVimeo } from "../../../utils/MediaUploaderVimeo";
import "react-toastify/dist/ReactToastify.css";
import { VidemoService } from "../../../services/VimeoService";

toast.configure();

const CreateArticle: React.FC = () => {
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [searchHashTag, setSearchHashTag] = useState<HashTag[]>([]);
  const [selectedExpTags, setSelectedExpTags] = useState<SelectedExperienceTag[]>([]);
  const [selectedHashTags, setSelectedHashTags] = useState<any[]>([]);
  const [articleTitle, setArticleTitle] = useState("");
  const [articleFile, setArticleFile] = useState({ preview: "", raw: "" });
  const [articleBody, setArticleBody] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [uploadId, setUploadId] = useState("");
  const [vimeoId, setVimeoId] = useState("");
  const [deletingVimeoId, setDeletingVimeoId] = useState("");
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [transcodeStatus, setTranscodeStatus] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);

  const history = useHistory();
  const location: any = useLocation();
  const articleId = location.state?.articleId;
  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];
  const SUPPORTED_VIDEO_FORMATS = ["mp4", "mkv", "webm", "mov", "ogg", "mpeg", "mpg", "flv"];

  const [file, setFile] = useState<File | null>(null);
  const uploader = new MediaUploaderVimeo();

  useEffect(() => {
    getAllExperienceTags();

    getAllHashTags();

    if (articleId) {
      getArticleDetails(articleId);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const getArticleDetails = (articleId: string) => {
    setIsLoading(true);

    ArticleService.getArticleById(articleId).then(res => {
      if (res.success) {
        setArticleTitle(res.data.articleTitle);

        setSelectedExpTags(
          res.data.articleTags.map(item => {
            return { label: item.experienceTag, value: item._id };
          })
        );

        setSelectedHashTags(
          res.data.hashTags.map(item => {
            return { value: item, label: item };
          })
        );

        if (res.data.uploadId) {
          setArticleFile({
            preview: res.data.uploadId.url,
            raw: "",
          });

          setUploadId(res.data.uploadId._id);

          setFileType("IMAGE");
        }

        if (res.data.vimeoId) {
          setVimeoId(res.data.vimeoId);
          refreshTranscodeStatus(res.data.vimeoId);

          setFileType("VIDEO");
        }

        setIsDisabled(true);
        setArticleBody(res.data.articleBody);

        setIsLoading(false);
      }
    });
  };

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });
        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };

  const getAllHashTags = () => {
    ArticleService.getAllHashTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.name };
        });
        setSearchHashTag(tagList);
      } else {
        setSearchHashTag([]);
      }
    });
  };

  const DropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const creatableComponents = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
  };

  const editorConfiguration = {
    removePlugins: ["MediaEmbed"],
  };

  const handleHashTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any; value: any }) => {
      return { label: tag.label, value: tag.value };
    });
    if (updatedTags) {
      setSelectedHashTags(updatedTags);
    }
  };

  const handlExpTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label, value: tag.value };
      });

      if (updatedTags) {
        setSelectedExpTags(updatedTags);
      }
    }
  };

  const removeExpTag = (removedtag: any) => {
    setSelectedExpTags(selectedExpTags.filter(tag => tag.label !== removedtag));
  };

  const removeHashTag = (removedtag: any) => {
    setSelectedHashTags(selectedHashTags.filter(tag => tag.label !== removedtag));
  };

  const handleFileChange = (event: any) => {
    const extension = event.target.files[0].name.split(".").pop().toLowerCase();
    const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);
    const isAVideo = isSupported(SUPPORTED_VIDEO_FORMATS, extension);

    if (isAnImage) {
      setFileType("IMAGE");
    } else if (isAVideo) {
      setFileType("VIDEO");
      setFile(event.target.files[0]);
    } else {
      toast.error("File type is not supported!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (event.target.files.length > 0) {
      setArticleFile({
        preview: URL.createObjectURL(event.target.files[0]),
        raw: event.target.files[0],
      });

      setIsDisabled(true);
    } else {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    URL.revokeObjectURL(event.target.files[0]);
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const deleteFile = () => {
    setArticleFile({ raw: "", preview: "" });
    setFileType("");
    setFile(null);
    setIsDisabled(false);
  };

  const deleteVimeoFile = () => {
    setFileType("");
    setFile(null);
    setDeletingVimeoId(vimeoId);
    setVimeoId("");
    setIsDisabled(false);
    setArticleFile({ raw: "", preview: "" });
  };

  const createArticle = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (articleTitle == "" || articleTitle == undefined || articleTitle == null) {
      toast.error("Article title is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    if (articleFile.raw == "" || articleFile.raw == undefined || articleFile.raw == null) {
      toast.error("Article image/video is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    if (selectedExpTags.length == 0 || selectedExpTags == undefined || selectedExpTags == null) {
      toast.error("Article tags are required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    if (selectedHashTags.length == 0 || selectedHashTags == undefined || selectedHashTags == null) {
      toast.error("Hash tags are required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    if (articleBody == "" || articleBody == undefined || articleBody == null) {
      toast.error("Article content is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    const formData = new FormData();

    setDisabledButton(true);

    if (fileType == "IMAGE") {
      const articleDetails = {
        articleTitle: articleTitle,
        articleTags: selectedExpTags.map(tag => {
          return tag.label;
        }),
        hashTags: selectedHashTags.map(tag => {
          return tag.label;
        }),
        articleBody: articleBody,
      };

      const data = JSON.stringify(articleDetails);

      formData.append("articleDetails", data);
      formData.append("fileType", fileType);
      formData.append("articleFile", articleFile.raw);

      axios
        .post(Util.apiAuthUrl(`addArticle`), formData, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            toast.success("Article Created!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            history.push("/articles");
          } else {
            setDisabledButton(false);

            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            setDisabledButton(false);
            toast.error("API Error! Failed to create the article!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
    } else {
      if (file) {
        const videoMeta = await uploader.upload(file, (bytesUploaded, bytesTotal) => {
          setProgress(Math.floor((bytesUploaded * 100) / bytesTotal));
        });

        const articleDetails = {
          articleTitle: articleTitle,
          articleTags: selectedExpTags.map(tag => {
            return tag.label;
          }),
          hashTags: selectedHashTags.map(tag => {
            return tag.label;
          }),
          articleBody: articleBody,
          vimeoId: videoMeta.id,
        };

        const data = JSON.stringify(articleDetails);

        formData.append("articleDetails", data);
        formData.append("fileType", fileType);

        axios
          .post(Util.apiAuthUrl(`addArticle`), formData)

          .then((res: any) => {
            if (res.success) {
              toast.success("Article Created!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              history.push("/articles");
            } else {
              setDisabledButton(false);

              toast.error(res.data.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              return false;
            }
          })
          .catch(error => {
            if (error) {
              setDisabledButton(false);

              toast.error("API Error! Failed to create the article!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          });
      } else {
        setDisabledButton(false);

        toast.error("Article video is required!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }

    return false;
  };

  const updateArticle = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (articleTitle == "" || articleTitle == undefined || articleTitle == null) {
      toast.error("Article title is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (vimeoId == "" && (articleFile.preview == "" || articleFile.preview == undefined || articleFile.preview == null)) {
      toast.error("Article image/video is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (selectedExpTags.length == 0 || selectedExpTags == undefined || selectedExpTags == null) {
      toast.error("Article tags are required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (selectedHashTags.length == 0 || selectedHashTags == undefined || selectedHashTags == null) {
      toast.error("Hash tags are required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (articleBody == "" || articleBody == undefined || articleBody == null) {
      toast.error("Article content is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    let articleDetails: ArticleDetails;

    if (articleFile.raw === "" && vimeoId === "") {
      articleDetails = {
        articleTitle: articleTitle,
        articleTags: selectedExpTags.map(tag => {
          return tag.label;
        }),
        hashTags: selectedHashTags.map(tag => {
          return tag.label;
        }),
        articleBody: articleBody,
      };

      ArticleService.updateArticleWithoutFile(articleId, articleDetails).then(res => {
        if (res.success) {
          toast.success("Article is updated successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          history.push("/articles");
        } else {
          toast.error("Failed to update the article!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return false;
        }
      });
    } else if (articleFile.raw !== "" || vimeoId !== "") {
      const formData = new FormData();

      setDisabledButton(true);

      if (fileType == "IMAGE") {
        if (deletingVimeoId) {
          await VidemoService.deleteVideo(deletingVimeoId);
        }

        articleDetails = {
          articleTitle: articleTitle,
          articleTags: selectedExpTags.map(tag => {
            return tag.label;
          }),
          hashTags: selectedHashTags.map(tag => {
            return tag.label;
          }),
          articleBody: articleBody,
          deletingUploadId: uploadId,
          fileChange: true,
        };

        const data = JSON.stringify(articleDetails);

        formData.append("articleDetails", data);
        formData.append("fileType", fileType);
        formData.append("articleFile", articleFile.raw);

        axios
          .post(Util.apiAuthUrl(`updateArticle/` + articleId), formData, {
            onUploadProgress: data => {
              setProgress(Math.round((100 * data.loaded) / data.total));
            },
          })
          .then((res: any) => {
            if (res.success) {
              toast.success("Article is updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              history.push("/articles");
            } else {
              toast.error(res.data.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              return false;
            }
          })
          .catch(error => {
            if (error) {
              toast.error("API Error! Failed to create the article!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          });
      } else {
        if (file) {
          if (deletingVimeoId) {
            await VidemoService.deleteVideo(deletingVimeoId);
          }

          const videoMeta = await uploader.upload(file, (bytesUploaded, bytesTotal) => {
            setProgress(Math.floor((bytesUploaded * 100) / bytesTotal));
          });

          const articleDetails = {
            articleTitle: articleTitle,
            articleTags: selectedExpTags.map(tag => {
              return tag.label;
            }),
            hashTags: selectedHashTags.map(tag => {
              return tag.label;
            }),
            articleBody: articleBody,
            vimeoId: videoMeta.id,
            deletingUploadId: uploadId,
          };

          const data = JSON.stringify(articleDetails);

          formData.append("articleDetails", data);
          formData.append("fileType", fileType);

          axios
            .post(Util.apiAuthUrl(`updateArticle/` + articleId), formData)
            .then((res: any) => {
              if (res.success) {
                toast.success("Article is updated successfully!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                history.push("/articles");
              } else {
                setDisabledButton(false);

                toast.error(res.data.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                return false;
              }
            })
            .catch(error => {
              if (error) {
                setDisabledButton(false);

                toast.error("API Error! Failed to create the article!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            });
        } else if (file == null && vimeoId !== "") {
          articleDetails = {
            articleTitle: articleTitle,
            articleTags: selectedExpTags.map(tag => {
              return tag.label;
            }),
            hashTags: selectedHashTags.map(tag => {
              return tag.label;
            }),
            articleBody: articleBody,
          };

          ArticleService.updateArticleWithoutFile(articleId, articleDetails).then(res => {
            if (res.success) {
              toast.success("Article is updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              history.push("/articles");
            } else {
              toast.error("Failed to update the article!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              return false;
            }
          });
        } else {
          setDisabledButton(false);

          toast.error("Article video is required!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } else {
      toast.error("Server Error!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const refreshTranscodeStatus = (vimeoId: string) => {
    setIsRefreshing(true);

    VidemoService.checkTranscodeStatus(vimeoId).then((res: any) => {
      setTranscodeStatus(res.transcode.status);
      setIsRefreshing(false);
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <title>Article Details</title>
        <Container fluid>
          {articleId ? <Breadcrumbs title="Article" breadcrumbItem="Edit Article" /> : <Breadcrumbs title="Article" breadcrumbItem="Create Article" />}
          {!isLoading ? (
            <Row>
              <Col lg={12}>
                <Card style={{ minHeight: "650px" }}>
                  <CardBody>
                    <Row>
                      <div className="card-heading mb-4">{articleId ? <h1>Edit Article</h1> : <h1>Article Creation</h1>}</div>
                      <Col lg={4}>
                        <Form>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">Title of Article</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Title of Article"
                              name="articleTitle"
                              value={articleTitle}
                              onChange={event => {
                                setArticleTitle(event.target.value);
                              }}
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <Label
                              htmlFor="form-upload-file"
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                color: "#FD7F00",
                                marginBottom: "0px",
                              }}
                            >
                              + Upload Article Image/Video
                            </Label>

                            {isDisabled ? (
                              <Input
                                type="file"
                                className="form-control"
                                id="form-upload-file"
                                placeholder="Article File"
                                name="articleFile"
                                style={{ display: "none", cursor: "unset" }}
                                required
                                disabled
                              />
                            ) : (
                              <Input
                                type="file"
                                className="form-control"
                                id="form-upload-file"
                                placeholder="Article File"
                                name="articleFile"
                                onChange={event => {
                                  handleFileChange(event);
                                }}
                                style={{ display: "none" }}
                                required
                              />
                            )}
                          </div>

                          {articleFile.preview ? (
                            fileType == "IMAGE" ? (
                              <>
                                <div className="pull-right">
                                  <i
                                    className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                    onClick={() => {
                                      deleteFile();
                                    }}
                                  ></i>
                                </div>
                                <div className="mb-3 upload-img">
                                  <img src={articleFile.preview} className="upload-img-preview" />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="pull-right">
                                  <i
                                    className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                    onClick={() => {
                                      deleteFile();
                                    }}
                                  ></i>
                                </div>
                                <div className="mb-1"></div>
                                <div className="mb-3 upload-img">
                                  <ReactPlayer
                                    url={articleFile.preview}
                                    playing={false}
                                    volume={1}
                                    controls={true}
                                    playIcon={<button>Play</button>}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </>
                            )
                          ) : vimeoId && fileType == "VIDEO" ? (
                            <>
                              <div className="pull-right mb5">
                                <i
                                  className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                  onClick={() => {
                                    deleteVimeoFile();
                                  }}
                                ></i>
                              </div>

                              <div className="mb-1"></div>

                              <div className="mb-3">
                                {transcodeStatus == "" ? (
                                  <div className="pending-transcoding">Loading...</div>
                                ) : (
                                  <>
                                    {transcodeStatus == "in_progress" ? (
                                      <div className="pending-transcoding">
                                        {isRefreshing ? (
                                          "Checking Status... "
                                        ) : (
                                          <div>
                                            Video is still transcoding! Will available in a minute! &nbsp;
                                            <i
                                              className="fa fa-refresh text-primary refresh-icon"
                                              onClick={() => {
                                                refreshTranscodeStatus(vimeoId);
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="video-responsive" style={{ width: "100%" }}>
                                        <iframe
                                          src={"https://player.vimeo.com/video/" + vimeoId}
                                          className="video-responsive-item"
                                          frameBorder="0"
                                          allow="autoplay; fullscreen; picture-in-picture"
                                          allowFullScreen
                                          title="Lavni Health"
                                        ></iframe>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">Area of Specialization</Label>
                            <Creatable
                              options={searchExpTag}
                              isMulti
                              components={creatableComponents}
                              onChange={(newValue: any) => handlExpTags(newValue)}
                              value={selectedExpTags}
                              display="none"
                              className="creatableEdit"
                              placeholder="Choose an area"
                              controlShouldRenderValue={false}
                              isClearable={false}
                            />

                            <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                              {selectedExpTags.map((tag, id) => {
                                return <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
                              })}
                            </div>
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">Hash Tags</Label>
                            <Creatable
                              options={searchHashTag}
                              isMulti
                              components={creatableComponents}
                              onChange={(newValue: any) => handleHashTags(newValue)}
                              value={selectedHashTags}
                              display="none"
                              className="creatableEdit"
                              placeholder="Choose an area"
                              controlShouldRenderValue={false}
                              isClearable={false}
                            />

                            <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                              {selectedHashTags.map((tag, id) => {
                                return <Tags label={tag.label} id={id} key={id} removeTag={removeHashTag} />;
                              })}
                            </div>
                          </div>

                          <div>
                            <div className="progress-sec">
                              {progress != 0 && <ProgressBar className="progress-bar-style" now={progress} label={`${progress}%`} />}
                            </div>

                            {articleId ? (
                              <button type="button" className="btn btn-primary w-100" onClick={updateArticle} {...(disabledButton && { disabled: true })}>
                                {disabledButton ? <span>Uploading Files & Updating Article...</span> : <span>Update Article</span>}
                              </button>
                            ) : (
                              <button type="button" className="btn btn-primary w-100" onClick={createArticle} {...(disabledButton && { disabled: true })}>
                                {disabledButton ? <span>Uploading Files & Creating Article...</span> : <span>Create New Article</span>}
                              </button>
                            )}
                          </div>
                        </Form>
                      </Col>
                      <Col lg={8}>
                        <div className="editor-area ck-content">
                          <CKEditor
                            editor={Editor}
                            stlye={{ minHeight: "500px" }}
                            onChange={(event: any, editor: { getData: () => any }) => {
                              const data = editor.getData();
                              setArticleBody(data);
                            }}
                            data={articleBody}
                            config={editorConfiguration}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Spinner />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateArticle;
