import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { ContactUs } from "../../models/ContactUs";
import Spinner from "../../common/spinner/spinner";
import moment from "moment";
import Swal from "sweetalert2";
import ContactContext from "src/context/UserContactRequest";

const ViewContactUs: React.FC = () => {
  const [contactRequests, setContactRequests] = useState<ContactUs[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const LIMIT = 20;
  const [seeMore, setSeeMore] = useState(false);
  const [offset, setOffset] = useState(1);
  const [userCount, setUserCount] = useState(20);
  const [userContactCount, getAllContactRequestsCount] = useContext(ContactContext);
  
  useEffect(() => {
    getAllContactRequests();
  }, []);

  const getAllContactRequests = () => {
    setIsLoading(true)

    AdminService.getAllContactUsRequests(LIMIT, offset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setContactRequests(res.data.contactList);
        setUserCount(res.data.contactList.length);
        setIsLoading(false)
      } else {
        setContactRequests([]);
        setIsLoading(false);
        setSeeMore(false);
      }
    });
  };


  const seeMoreFc = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    setIsLoading(true);

    AdminService.getAllContactUsRequests(20, updatedSkip).then(res => {
      if (res.success) {
        res.data.contactList.map((feedback: any) => {
          setContactRequests(feedbacks => [...feedbacks, feedback]);
        });

        setUserCount(res.data.contactList.length);
      }

      setIsLoading(false);
    });
  };


  const markAsRead = (contactRequestId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to mark as read?",
      showCancelButton: true,
      confirmButtonText: "Mark as Read",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.updateContactRequestStatus(contactRequestId).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Successfully Updated!",
              confirmButtonColor: "#FD7F00",
            });
            getAllContactRequests();
            getAllContactRequestsCount(userContactCount - 1);
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed update. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Contact Requests"} breadcrumbItem={"Contact Requests"} />
          
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {contactRequests !== undefined && contactRequests.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone Number</th>
                              <th scope="col">Message</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contactRequests &&
                              contactRequests.map((request, index) => (
                                <tr key={request._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{request.name}</td>
                                  <td>{moment(request?.createdAt).format("YYYY-MM-DD hh:mm A")}</td>
                                  <td>{request.email}</td>
                                  <td>{request.phoneNumber}</td>
                                  <td>{request.problem}</td>
                                  <td>
                                    {request?.isRead === false &&
                                      <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                          markAsRead(request?._id);
                                        }}
                                      >
                                        Mark as Read
                                      </button>
                                    }
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      {userCount >= 20 && (
                        <>
                          <button className="btn btn-warning text-center mt-4" onClick={seeMoreFc}>
                            See More
                          </button>
                        </>
                      )}
                      
                      {userCount == 0 && <span>Feedbacks not found....</span>}
                    </>
                  ) : (
                    <>
                      {isLoading ? <Spinner /> : <div>Feedbacks not found.</div>}</>
                  )}
                </Col>
              </Row>
              <Row>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default ViewContactUs;
