import moment from "moment";
import React, { useEffect } from "react";
import { Input } from "reactstrap";
import "../../../../../src/assets/css/custom_time_picker_group_chat_session.css";

const CustomTimePickerGroupChatSession: React.FC<{
  selectedTimeValue: string;
  setSelectedTimeValue: (value: string) => void;
}> = props => {
  const [hourDataValue, setHourDataValue] = React.useState("00");
  const [minuteDataValue, setMinuteDataValue] = React.useState("00");
  const [meridiemDataValue, setMeridiemDataValue] = React.useState("AM");

  useEffect(() => {
    if (props.selectedTimeValue) {
      if (moment(props.selectedTimeValue).hour() == 12) {
        setHourDataValue(moment(props.selectedTimeValue).hour().toString());
        setMeridiemDataValue("PM");
      } else if (moment(props.selectedTimeValue).hour() > 12) {
        if (moment(props.selectedTimeValue).hour() - 12 >= 10) {
          setHourDataValue((moment(props.selectedTimeValue).hour() - 12).toString());
        } else {
          setHourDataValue("0" + (moment(props.selectedTimeValue).hour() - 12).toString());
        }

        setMeridiemDataValue("PM");
      } else {
        if (moment(props.selectedTimeValue).hour() >= 10) {
          setHourDataValue(moment(props.selectedTimeValue).hour().toString());
        } else {
          setHourDataValue("0" + moment(props.selectedTimeValue).hour().toString());
        }

        setMeridiemDataValue("AM");
      }

      setMinuteDataValue(moment(props.selectedTimeValue).minute().toString());
    }
  }, []);

  const updateSelectedTime = (hourVal: string, minuteVal: string, meridiemVal: string) => {
    const selectedTime = moment();

    if (meridiemVal == "AM") {
      if (parseInt(hourVal) == 12) {
        selectedTime.hour(0).minute(parseInt(minuteVal));
      } else {
        selectedTime.hour(parseInt(hourVal)).minute(parseInt(minuteVal));
      }
    } else {
      if (parseInt(hourVal) == 12) {
        selectedTime.hour(parseInt(hourVal)).minute(parseInt(minuteVal));
      } else {
        const hour = parseInt(hourVal) + 12;
        selectedTime.hour(hour).minute(parseInt(minuteVal));
      }
    }

    const finalSelectedTime: string = selectedTime.format();
    props.setSelectedTimeValue(finalSelectedTime);
  };

  return (
    <React.Fragment>
      <div className="custom_picker">
        <Input
          name="sessionHours1"
          type="select"
          value={hourDataValue}
          onChange={e => {
            setHourDataValue(e.target.value);
            updateSelectedTime(e.target.value, minuteDataValue, meridiemDataValue);
          }}
        >
          <option value={"00"}>00</option>
          <option value={"01"}>01</option>
          <option value={"02"}>02</option>
          <option value={"03"}>03</option>
          <option value={"04"}>04</option>
          <option value={"05"}>05</option>
          <option value={"06"}>06</option>
          <option value={"07"}>07</option>
          <option value={"08"}>08</option>
          <option value={"09"}>09</option>
          <option value={"10"}>10</option>
          <option value={"11"}>11</option>
          <option value={"12"}>12</option>
        </Input>

        <Input
          className="ms-1 me-1"
          name="sessionMinutes1"
          type="select"
          value={minuteDataValue}
          onChange={e => {
            setMinuteDataValue(e.target.value);
            updateSelectedTime(hourDataValue, e.target.value, meridiemDataValue);
          }}
        >
          <option value={"00"}>00</option>
          <option value={"30"}>30</option>
        </Input>

        <Input
          name="sessionMeridiem1"
          type="select"
          value={meridiemDataValue}
          onChange={e => {
            setMeridiemDataValue(e.target.value);
            updateSelectedTime(hourDataValue, minuteDataValue, e.target.value);
          }}
        >
          <option value={"AM"}>AM</option>
          <option value={"PM"}>PM</option>
        </Input>
      </div>
    </React.Fragment>
  );
};

export default CustomTimePickerGroupChatSession;
