import React, { useState } from 'react';

const HeaderMain : React.FC = () => {
    const [isOffcanvasVisible, setIsOffcanvasVisible] = useState(false);
    const toggleOffcanvas = () => {
        setIsOffcanvasVisible(!isOffcanvasVisible);
    };
    

    return(
        <div>
            {/* <section className="top-bar">
                <div className="container">
                <div className="row rowWidth">
                    <div className="col-6 col-md-8 ">
                    <div className="contact-info">
                        <p className="contact-item2 mainText2">Phone: +1 (980) 890 7522</p>
                        <p className="contact-item2 mainText md-hide">Email: info@mylavni.com</p>
                    </div>
                    </div>
                    <div className="col-6 col-md-4  text-md-end social-icons-container01">
                    <a href="https://www.instagram.com/mylavni/">
                        <span className="iconify" data-icon="uil:instagram"></span>
                    </a>
                    <a href="https://www.facebook.com/lavnihealth">
                        <span className="iconify" data-icon="uil:facebook-f"></span>
                    </a>
                    <a href="https://www.linkedin.com/company/lavni/">
                        <span className="iconify" data-icon="uil:linkedin"></span>
                    </a>
                    </div>
                </div>
                </div>
            </section> */}
            <header className="wrapper bg-light2">
                <nav className="navbar navbar-expand-lg classNameic transparent navbar-light">
                <div className="container flex-lg-row flex-nowrap align-items-center">
                    <div className="navbar-brand w-100">
                    <a href="/home">
                        <img
                        src="./../static/assets/img/logo-dark.png"
                        style={{ height: "50px" }}
                        alt="Lavni helps you find a mental health professional
                        that understands your unique experience"
                        />
                    </a>
                    </div>
                    <div
                    className="navbar-collapse offcanvas offcanvas12 offcanvas-nav offcanvas-start"
                    onClick={() => setIsOffcanvasVisible(false)}
                    style={isOffcanvasVisible ? { visibility: "visible", transform: "none" } : {}}
                    >
                    <div className="offcanvas-header d-lg-none">
                        <h3 className="text-white fs-30 mb-0">Lavni</h3>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                        <ul className="navbar-nav mainText navbar-nav123 fontHead mb-2">
                        {/* <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/home">
                            Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/about">
                            About
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/service">
                            Service
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/faq">
                            FAQ
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/blogs/">
                            Blogs
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/pricing/">
                            Pricing
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/contact">
                            Contact
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navlin001 mainText" href="/therapist-onboarding">
                            Therapist
                            </a>
                        </li> */}
                        <li className="nav-item d-lg-none d-md-none">
                            <a className="nav-link navlin001 mainText" href="/signin">
                            Sign In
                            </a>
                        </li>
                        <li className="nav-item d-lg-none d-md-none">
                            <a className="nav-link navlin001 mainText" href="/main">
                            Sign Up
                            </a>
                        </li>
                        </ul>

                        <div className="offcanvas-footer d-lg-none">
                        <div>
                            <div className="mb-2">
                                <a href="tel:+19808907522" className="link-inverse">+1 (980) 890 7522</a>
                            </div>
                            <div className="mb-2">
                                <a href="mailto:info@mylavni.com" className="link-inverse">info@mylavni.com</a>
                            </div>
                            <nav className="nav social social-white mt-3">
                            <a href="https://www.instagram.com/mylavni/">
                                <span className="iconify" data-icon="uil:instagram"></span>
                            </a>
                            <a href="https://www.facebook.com/lavnihealth">
                                <span className="iconify" data-icon="uil:facebook-f"></span>
                            </a>
                            <a href="https://www.linkedin.com/company/lavni/">
                                <span className="iconify" data-icon="uil:linkedin"></span>
                            </a>
                            </nav>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="navbar-other ms-lg-4">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <li className="nav-item d-none d-md-block">
                        <a href="/signin" className="btn btn-sm btn-outline-primary mainText rounded-pill3">
                            Sign In
                        </a>
                        </li>
                        <li className="nav-item d-none d-md-block">
                        <a href="/home/#client-signup" className="btn btn-sm btn-primary rounded-pill3 mainText ml-B2 ">
                            Sign Up
                        </a>
                        </li>
                        <li className="nav-item d-lg-none">
                        <button className="hamburger offcanvas-nav-btn" onClick={toggleOffcanvas}>
                            <span></span>
                        </button>
                        </li>
                    </ul>
                    </div>
                </div>
                </nav>
            </header>
        </div>
    )
} 

export default HeaderMain;
