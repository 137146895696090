import { CardContent, TextField } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import "./../../assets/css/therapistList.css";
import "./../../assets/css/subscription.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../context/UserContext";
import { CommonService } from "src/services/CommonService";
import { UserDetails } from "src/models/User";

const CardInfo: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [showAdditionalItemsColumn1, setShowAdditionalItemsColumn1] = useState(false);
  const [showAdditionalItemsColumn2, setShowAdditionalItemsColumn2] = useState(false);
  const history = useHistory();
  const insurance = localStorage.getItem("insuranceCompany");
  const [clickedCard, setClickedCard] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clickNextBtn = () => {
    if (clickedCard == 0) {
      const updatedUser: UserDetails = {
        freeUser: true,
      };

      CommonService.updateClientPackage(updatedUser).then(res => {
        if (res.success) {
          setUser(res.data);
          // history.push("/lavni_avatar");
          navigateToDashboard("/dashboard")
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });

    }
    else if (clickedCard == 1) {
      localStorage.setItem("insuranceCompany", "insurance");
      history.push("/add-insurance");
    } else {
      history.push("/add-payment");
    }
  };
  const navigateToDashboard = (defaultRoute: string) => {
    if(user?.skip){
      history.push(`${defaultRoute}`);
    }else{
      history.push("/welcome-page");
    }
  };
  const clickInsuranceBtn = () => {
    localStorage.setItem("insuranceCompany", "insurance");
    history.push("/add-insurance");
  };

  const back = () => {
    history.goBack()
  }
  return (
    <div className="content-wrapper mt-22rem">
      <section className="wrapper bg-light2 angled upper-end" id="recoverform">
        <div className="container1 pb-sm-n">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
              <div className="card">
                <div className="card-body padding-13 text-center">
                  <div className="row">
                    {user?.insuranceId?._id &&
                      <div className="col-xl-12 mb-2 d-flex float-left jestifyStart">
                        <span><button className="btn btn-skip float-left" onClick={() => back()}><i className='bx bx-left-arrow-alt' ></i> &nbsp; Back</button></span>
                      </div>
                    }
                  </div>

                  <h1 className="mb-3 text-start">Choose Your Plan</h1>

                  <div className="PriceContainer">

                    <Row className="mr-m-0">
                      <Col lg={4}>
                        {insurance !== null && (
                          <>
                            <div style={insurance !== null ? { borderColor: "#FD7A08" } : {}} className="price-col">
                              <p>Pay With Insurance</p>
                              <h3>AS LITTLE AS $0</h3>
                              <button onClick={() => clickNextBtn()} style={insurance !== null ? { backgroundColor: "#FD7A08" } : {}}>
                                Choose Plan
                              </button>
                              <ul>
                                <li className="uil uil-check">Everything in free plan</li>
                                <li>
                                  Weekly video/phone <br /> <span>sessions with a</span> <br /> <span>licensed therapist</span>
                                </li>

                                {showAdditionalItemsColumn2 && (
                                  <React.Fragment>
                                    <li>
                                      Chat securely with your <br /> <span>therapist anytime</span>
                                    </li>
                                    <li>
                                      Regular goal tracking by you and <span>your therapist</span>
                                    </li>
                                    <li>Incognito mode</li>
                                  </React.Fragment>
                                )}
                              </ul>
                              <button className="viewMore-btn" onClick={() => setShowAdditionalItemsColumn2(!showAdditionalItemsColumn2)}>
                                {showAdditionalItemsColumn2 ? "View Less" : "View More"}
                              </button>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>

                    <div className="price-row">

                      {insurance == null && (
                        <>
                          <div style={clickedCard === 0 ? { borderColor: "#FD7A08" } : {}} className="price-col" onClick={() => setClickedCard(0)}>
                            <p>Free Plan</p>
                            <h3>FREE</h3>
                            <button style={clickedCard === 0 ? { backgroundColor: "#FD7A08" } : {}} className="price-col" onClick={() => setClickedCard(0)}>
                              Choose Plan
                            </button>
                            <ul>
                              <li>Curated content</li>
                              <li>Access to mentors </li>
                              <li>Create a community</li>
                            </ul>
                          </div>
                          {
                            insurance == null && (
                              <div style={clickedCard === 1 ? { borderColor: "#FD7A08" } : {}} className="price-col" onClick={() => setClickedCard(1)}>
                                <p>Do you have insurance?</p>
                                <div className="choose-container">
                                <button style={clickedCard === 1 ? { backgroundColor: "#FD7A08" } : {}} className="price-col choose-btn-max" onClick={() => clickInsuranceBtn()}>
                                  I have Insurance
                                </button>
                                </div>
                              </div>
                            )
                          }
                          <div style={clickedCard === 2 ? { borderColor: "#FD7A08" } : {}} className="price-col" onClick={() => setClickedCard(2)}>
                            <p>Therapy Plan</p>
                            <h3>
                              $75 PER WEEK BILLED <br /> MONTHLY
                            </h3>
                            <button style={clickedCard === 2 ? { backgroundColor: "#FD7A08" } : {}} className="price-col" onClick={() => setClickedCard(2)}>
                              Choose Plan
                            </button>
                            <ul>
                              <li>Everything in free plan</li>
                              <li>
                                Weekly video/phone <br />
                                <span></span>sessions with a licensed
                                <br /> <span>therapist</span>
                              </li>

                              {/* ... Initial list items ... */}
                              {showAdditionalItemsColumn1 && (
                                <React.Fragment>
                                  <li>
                                    Chat securely with your <br /> <span>therapist anytime</span>
                                  </li>
                                  <li>
                                    Regular goal tracking by you and <span>your therapist</span>
                                  </li>
                                  <li>Incognito mode</li>
                                </React.Fragment>
                              )}
                            </ul>
                            <button className="viewMore-btn" onClick={() => setShowAdditionalItemsColumn1(!showAdditionalItemsColumn1)}>
                              {showAdditionalItemsColumn1 ? "View Less" : "View More"}
                            </button>
                          </div>
                          

                        </>
                      )}

                    </div>
                    <div className={`${insurance == null ? "price-row4" : "price-row2 mt-5 mb-10 mt-md-1"} bottom`}>
                      {/* {insurance == null && (
                        <div className="bottom-col  mt-md-1 mb-md5 mt-1 mb-1 justify-center-btn">
                          <button className="inurance-btn" onClick={() => clickInsuranceBtn()}>
                            I have Insurance
                          </button>
                        </div>
                      )} */}
                      <div className={`${insurance == null ? 'mt-1' : 'mt-5'} bottom-col mt-md-1 mb-md5  mb-10 mb-1 justify-center-btn`}>
                        <button className="next-btn" onClick={() => clickNextBtn()}>
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CardInfo;
