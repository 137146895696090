import React, { useState } from "react";
import { useContext } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import Close from "../../../assets/images/icons/close.png";

interface Props {
  diagnosisCodeForClient: any[];
  secondaryDiagnosisCodeForClient: any[];
}

const ClientDiagnosisCodeViewComp = ({ diagnosisCodeForClient, secondaryDiagnosisCodeForClient }: Props) => {
  const [isOpenCodeView, setIsOpenCodeView] = useState(false);


  const toggleModalForViewCodes = () => {
    setIsOpenCodeView(!isOpenCodeView)
  }

  return (
    <React.Fragment>
      <button
          className="btn btn-sm btn-success mr-2"
          onClick={() => {
            toggleModalForViewCodes();
          }}
          title="View Insurance"
        >
          View Codes
      </button>
      <Modal isOpen={isOpenCodeView} toggle={toggleModalForViewCodes} centered className="modal-lg">
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={toggleModalForViewCodes}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>
        <ModalBody className="ps-4 pe-4">
          <Row>
            <span className="font-size-18 profileFont mb-2 cursor-pointer pt-2 text-center">Diagnosis ICD 10 codes</span>
          </Row>

          {diagnosisCodeForClient && diagnosisCodeForClient?.length && diagnosisCodeForClient.length > 0 ? <Row>
            <span className="font-size-16 profileFont mb-2 cursor-pointer pt-2 text-center">Primary Diagnosis ICD 10 codes</span>
          </Row> : <div></div>}
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              {diagnosisCodeForClient && diagnosisCodeForClient?.length && diagnosisCodeForClient.length > 0 ? (
                <div className="d-flex justify-content-center referal-link-btn">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Code</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {diagnosisCodeForClient.map((code: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{code?.value ?? ""}</td>
                              <td>{code?.label ?? ""}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              ) : <div></div>}
            </Col>
          </Row>
          {secondaryDiagnosisCodeForClient && secondaryDiagnosisCodeForClient?.length && secondaryDiagnosisCodeForClient.length > 0 ? <Row>
            <span className="font-size-16 profileFont mb-2 cursor-pointer pt-2 text-center">Secondary Diagnosis ICD 10 codes</span>
          </Row> : <div></div>}
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              {secondaryDiagnosisCodeForClient && secondaryDiagnosisCodeForClient?.length && secondaryDiagnosisCodeForClient.length > 0 ? (
                <div className="d-flex justify-content-center referal-link-btn">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Code</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {secondaryDiagnosisCodeForClient.map((code: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{code?.value ?? ""}</td>
                              <td>{code?.label ?? ""}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              ) : <div></div>}
            </Col>
          </Row>

          {((!diagnosisCodeForClient || !diagnosisCodeForClient?.length || diagnosisCodeForClient.length <= 0) && (!secondaryDiagnosisCodeForClient || !secondaryDiagnosisCodeForClient?.length || secondaryDiagnosisCodeForClient.length <= 0)) ? <Row>
            <span className="font-size-16 profileFont mb-2 cursor-pointer pt-2 text-center">No Diagnosis ICD 10 codes found</span>
          </Row> : <div></div>}
        </ModalBody>
      </Modal>
    </React.Fragment>

  );
};

export default ClientDiagnosisCodeViewComp;
