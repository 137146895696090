import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreIcon from '@material-ui/icons/More';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import HouseIcon from '@material-ui/icons/House';
import CloseIcon from '@material-ui/icons/Close';

import ChatIcon from '@material-ui/icons/Chat';

import React from 'react';
import styles from './styles';

import Drawer from '@material-ui/core/Drawer';

import SideMenu from '../SideMenu';

import useSignal from '../../hooks/useSignal';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Divider } from 'rsuite';

export default function MoreOptionsButton({
  classes,
  participants,
  room,
  localParticipant,
  screenWidth
}) {
  const { listOfMessages } = useSignal({ room });
  const titleToolTip = 'Chat';
  const localClasses = styles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = () => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(!state);
  };

  const onCloseFunc = () => {
    setState(false);
  }

  return (
    <>
      {
        screenWidth < 530 ?
          <>
            {/* <ButtonGroup className={classes.toolbarButtons} disableElevation variant="contained" aria-label="split button">
              <Tooltip title={titleToolTip} aria-label="add">
                <IconButton
                  onClick={toggleDrawer()}
                  edge="start"
                  color="inherit"
                  aria-label="mic"
                  className={`${classes.arrowButton} `}
                >
                  <ChatIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </ButtonGroup> */}
            <ButtonGroup className={classes.groupButton2} disableElevation variant="contained" aria-label="split button">
              <Tooltip title={titleToolTip} aria-label="add">
                <IconButton
                  onClick={toggleDrawer()}
                  edge="start"
                  aria-label="videoCamera"
                  size="small"
                  className={`${classes.arrowButton2} `}
                >
                  <ChatIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
            <Drawer
              open={state}
              onClose={toggleDrawer(false)}
              classes={{ paper: localClasses.paper }}
            >
              {/* <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleDrawer(false)}
                  style={{ position: 'absolute', top: 0, right: 0, zIndex: 1300, marginLeft: "50px" }}
                >
                  <CloseIcon style={{ zIndex: 1400 }} />
                </IconButton> */}
              <SideMenu
                className={localClasses.root}
                room={room}
                participants={participants}
                localParticipant={localParticipant}
                listOfMessages={listOfMessages}
                onCloseFun={onCloseFunc}
              ></SideMenu>
            </Drawer>
          </>
          :
          <div>
            <Tooltip title={titleToolTip} aria-label="add">
              <IconButton
                onClick={toggleDrawer()}
                edge="start"
                color="inherit"
                aria-label="mic"
                className={localClasses.infoButton}
              >
                <ChatIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Drawer
              open={state}
              onClose={toggleDrawer(false)}
              classes={{ paper: localClasses.paper }}
            >
               {/* <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleDrawer(false)}
                  style={{ position: 'absolute', top: 0, right: 0, zIndex: 1300}}
                >
                  <CloseIcon style={{ zIndex: 1400 }} />
                </IconButton> */}
              <SideMenu
                className={localClasses.root}
                room={room}
                participants={participants}
                localParticipant={localParticipant}
                listOfMessages={listOfMessages}
                onCloseFun={onCloseFunc}
              ></SideMenu>
            </Drawer>
          </div>
      }
    </>
    // <div>
    //   <Tooltip title={titleToolTip} aria-label="add">
    //     <IconButton
    //       onClick={toggleDrawer()}
    //       edge="start"
    //       color="inherit"
    //       aria-label="mic"
    //       className={localClasses.infoButton}
    //     >
    //       <ChatIcon fontSize="inherit" />
    //     </IconButton>
    //   </Tooltip>
    //   <Drawer
    //     open={state}
    //     onClose={toggleDrawer(false)}
    //     classes={{ paper: localClasses.paper }}
    //   >
    //     <SideMenu
    //       className={localClasses.root}
    //       room={room}
    //       participants={participants}
    //       localParticipant={localParticipant}
    //       listOfMessages={listOfMessages}
    //     ></SideMenu>
    //   </Drawer>
    // </div>
  );
}
MoreOptionsButton.propTypes = {
  classes: PropTypes.object,
  participants: PropTypes.array,
  room: PropTypes.object,
  localParticipant: PropTypes.object,
  screenWidth: PropTypes.any,
};
