import React, { useContext, useState, useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
// import Tabs, { TabPane } from "rc-tabs";
import UserContext from "../../context/UserContext";
import AddInsuranceByClient from "./AddInsuranceByClient";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";
import ViewInsuranceByClient from "./ViewInsuranceByClient";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Insurance } from "src/models/Insurance";
import { ClientService } from "src/services/ClientService";
import { Client } from "src/models/Client";
import Spinner from "src/common/spinner/spinner";
const classNames = require("classnames");

interface ProfileInsuranceDetailsProps {
    clientData?: Client;
    onCopaymentAmount: (copaymentInfo: any) => void;
}

const ProfileInsuranceDetails: React.FC<ProfileInsuranceDetailsProps> = ({ clientData, onCopaymentAmount }) => {
    const [activeKey, setActiveKey] = useState("1");
    const [user, setUser] = useContext(UserContext);
    const [clientIsuranceList, setClientIsuranceList] = useState<any[]>([]);
    const [selectedInsurance, setSelectedInsurance] = useState<any>();
    const [updateAddedInsurance, setUpdateAddedInsurance] = useState<boolean>(false);
    const [clickedClientData, setClickedClientData] = useState<any>();
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user?.role == "CLIENT" && !user?.insuranceId) {
            ClientService.getUserById().then(res => {
                if (res.success) {
                    setUser({ ...user, insuranceId: res?.data?.insuranceId });
                }
            });
        }
    }, []);

    useEffect(() => {
        if (user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") {
            setClickedClientData(clientData)
        }
    }, [clientData]);

    useEffect(() => {
        getInsuranceList()
    }, [updateAddedInsurance]);

    const getInsuranceList = () => {
        if (user?.role == "CLIENT") {
            InsuranceCompanyService.getInsuranceByClient().then(res => {
                if (res.success) {
                    setClientIsuranceList(res.data)
                    if (res?.data?.length > 0) {
                        setSelectedInsurance(res.data[0]);
                    }
                    setIsLoading(false)
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    setIsLoading(false)
                }
            });
        } else if (user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") {
            const data = {
                clientId: clientData?._id
            }
            InsuranceCompanyService.getInsuranceByAdmin(data).then(res => {
                if (res.success) {
                    setClientIsuranceList(res.data)
                    if (res?.data?.length > 0) {
                        setSelectedInsurance(res.data[0]);
                    }
                    setIsLoading(false)
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    setIsLoading(false)
                }
            });
        }

    }

    function onTabsChange(e: any) {
        setActiveKey(e);
    }

    const handleInsuranceAdded = (insuranceInfo: any) => {

        // const secondaryInsurance = clientIsuranceList?.filter(obj => obj.id != insuranceInfo?.insuaranceId);
        // if(secondaryInsurance){
        //     makeDefaultInsurance(insuranceInfo?.clientId, insuranceInfo?.insuaranceId,secondaryInsurance[0]?._id)
        // }else{
        //     makeDefaultInsurance(insuranceInfo?.clientId, insuranceInfo?.insuaranceId)
        // }
        // if (user?.role == "CLIENT") {
        //     setUser({
        //         ...user,
        //         insuranceId: insuranceInfo
        //     });
        // } else if (user?.role == "SUPER_ADMIN") {
        //     setClickedClientData((clickedClientData: any) => ({
        //         ...clickedClientData,
        //         insuranceId: insuranceInfo?._id,
        //     }));
        // }
        // const data = {
        //     clientId: user?.role == "CLIENT" ? user?._id : clickedClientData?._id,
        //     secondaryInsuranceId: insuranceInfo?.insuaranceId ?? null,
        //   }
        //   ClientService.addSecondaryInsurance(data).then((res: any) => {
        //     if (res?.success) {
        //         setcustomActiveTab("1");
        //         setUpdateAddedInsurance(!updateAddedInsurance)

        //       toast.success(`Added successfully!`, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: "foo-bar",
        //       });
        //     } else {
        //       toast.error(res?.error, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         className: "foo-bar",
        //       });
        //     }

        //   });
        if ((user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN" ) && clientIsuranceList?.length == 0) {
            setClickedClientData((clickedClientData: any) => ({
                ...clickedClientData,
                insuranceId: insuranceInfo?.insuaranceId,
            }));
        }
        setcustomActiveTab("1");
        setUpdateAddedInsurance(!updateAddedInsurance)

    };
    const handleInsuranceUpdated = (insuranceInfo: any) => {
        setUpdateAddedInsurance(!updateAddedInsurance)
    }
    const makeDefaultInsurance = (clientId: string, insuranceId: string, secondaryInsuranceId?: string) => {
        const data = {
            clientId: clientId,
            insuranceId: insuranceId,
            secondaryInsuranceId: secondaryInsuranceId ?? null,
        }
        ClientService.updateDefaultInsurance(data).then((res: any) => {
            if (res?.success) {
                const insuranceNewData: Insurance = {
                    clientId: res?.data?.insuranceData?.clientId ?? null,
                    insuranceCardBackId: res?.data?.insuranceData?.insuranceCardBackId ?? null,
                    insuranceCardId: res?.data?.insuranceData?.insuranceCardId ?? null,
                    insuranceCompanyId: res?.data?.insuranceData?.insuranceCompanyId?._id ?? null,
                    subscriber: res?.data?.insuranceData?.subscriber ?? null,
                    _id: res?.data?.insuranceData?._id ?? null,
                }
                if (user?.role == "CLIENT") {
                    setUser({
                        ...user,
                        insuranceId: insuranceNewData
                    });
                } else if (user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") {
                    setClickedClientData((clickedClientData: any) => ({
                        ...clickedClientData,
                        insuranceId: res?.data?.insuranceData?._id,
                    }));
                }
                setcustomActiveTab("1");
                setUpdateAddedInsurance(!updateAddedInsurance)
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }

        });
    }

    const handleClickInsuranceCard = (insurance: any) => {
        setSelectedInsurance(insurance)
    }
    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };
    const onClientUpdated = (insuranceId: any) => {
        setClickedClientData((clickedClientData: any) => ({
            ...clickedClientData,
            insuranceId: insuranceId,
        }));
    }

    const handleCopaymentAmount1 = (copaymentInfo:any) => {
        onCopaymentAmount(copaymentInfo);
      };

    return (
        <div>
            <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items2">
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: customActiveTab === "1" })}
                        onClick={() => {
                            toggleCustom("1");
                        }}
                    >

                        <span className="d-sm-block">View Insurance</span>
                    </NavLink>
                </NavItem>
                {
                    clientIsuranceList && clientIsuranceList?.length < 2 && (
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classNames({ active: customActiveTab === "2" })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >

                                <span className="d-sm-block">
                                    {
                                        clientIsuranceList?.length == 0 ? (
                                            "Add Primary Insurance"
                                        ) : (
                                            "Add Secondary Insurance"
                                        )
                                    }
                                </span>
                            </NavLink>
                        </NavItem>
                    )}
            </Nav>

            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                <TabPane tabId="1">
                    <div className="flex flex-wrap mt-4 gap-2" >
                        {
                            user?.role === "CLIENT" && (
                                clientIsuranceList?.map((insurance, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleClickInsuranceCard(insurance)}
                                        className={`btn ${insurance?._id === selectedInsurance?._id ? 'btn-primary' : 'btn-secondary'}`}
                                    >
                                        {insurance?._id === user?.insuranceId?._id ? 'Primary Insurance' : 'Secondary Insurance'}
                                    </button>
                                ))
                            )
                        }
                        {
                           (user?.role === "SUPER_ADMIN" || user?.role == "SUB_ADMIN") && (
                                clientIsuranceList?.map((insurance, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleClickInsuranceCard(insurance)}
                                        className={`btn ${insurance?._id === selectedInsurance?._id ? 'btn-primary' : 'btn-secondary'}`}
                                    >
                                        {insurance?._id === clickedClientData?.insuranceId ? 'Primary Insurance' : 'Secondary Insurance'}
                                    </button>
                                ))
                            )
                        }
                    </div>
                    {
                        selectedInsurance && ((user?.role === "CLIENT" ? user?._id : clickedClientData?._id) !== undefined) && (
                            <>
                                <Row>
                                    <Col xl="12 mt-4">
                                        <hr />
                                    </Col>
                                    <Col xl="6"></Col>
                                    <Col xl="6" className="text-right"></Col>
                                </Row>
                                <ViewInsuranceByClient userId={(user?.role === "CLIENT" ? user?._id : clickedClientData?._id) || ''} clientIsuranceList={clientIsuranceList} clientSingleInsuranceData={selectedInsurance} onInsuranceUpdated={handleInsuranceUpdated} onCopaymentAmount={handleCopaymentAmount1} clientData={clickedClientData} onClientUpdated={onClientUpdated} />

                            </>

                        )
                    }
                    {
                        (clientIsuranceList?.length == 0) && !isLoading && (
                            <p className="text-center">No insurance found.</p>
                        )
                    }
                    {
                        isLoading && (
                            <Spinner className="bouncer" />
                        )
                    }
                </TabPane>
                {
                    clientIsuranceList && clientIsuranceList?.length < 2 && (
                        <TabPane tabId="2">
                            <AddInsuranceByClient userId={(user?.role === "CLIENT" ? user?._id : clickedClientData?._id) || ''} onInsuranceAdded={handleInsuranceAdded} onCopaymentAmount={handleCopaymentAmount1} clientData={clickedClientData} insuranceList={clientIsuranceList} />
                        </TabPane>
                    )
                }
            </TabContent>
        </div>
    );
};

export default ProfileInsuranceDetails;
