import React, { useState, useEffect, Suspense } from "react";
// import SelectTherapist from "./SelectTherapist";
// import SelectClient from "./SelectClient";

import { Therapist } from "../../../models/Therapist";
import { Client } from "../../../models/Client";
import { FriendRequest } from "../../../models/FriendRequest";
import CreateAppoinmentAdmin from "./CreateAppoinment";

//add lazy loading
const SelectTherapist = React.lazy(() => import("./SelectTherapist"));
const SelectClient = React.lazy(() => import("./SelectClient"));
import { toast } from "react-toastify";

const AddAppoinmentAdmin = () => {
  const [selectTherapist, setSelectedTherapist] = useState<Therapist | null>(null);
  const [selectClient, setSelectedClient] = useState<Client | null>(null);
  const [step, setStep] = useState(1);
  const [friendClients, setFriendClients] = useState<FriendRequest[]>([]);

  const nextStep = () => {
    if (step === 1) {
      // Load clients for the selected therapist
      // Simulating the load

      if (selectTherapist) {
        setStep(2);
      } else {
        return toast.error(`Please select therapist.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } else if (step === 2) {
      if (selectClient) {
        setStep(3);
      } else {
        return toast.error(`Please select client.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };

  const prevStep = () => {
    if (step > 1 && step <= 3) {
      setStep(prevStep => prevStep - 1);
    }
  };

  const selectTherapists = (therapist: Therapist) => {
    setSelectedTherapist(therapist);
  };

  const selectClients = (client: Client) => {
    setSelectedClient(client);
  };

  const selectFriendClients = (friend: FriendRequest[]) => {
    setFriendClients(prev => [...friend]);
  };

  return (
    <div className="page-content">
      {step === 1 && (
        <Suspense fallback={<div> Please Wait... </div>}>
          {" "}
          <SelectTherapist selectTherapists={selectTherapists} selectFriendClients={selectFriendClients} nextStep={nextStep} />
        </Suspense>
      )}
      {step === 2 && selectTherapist && (
        <Suspense fallback={<div> Please Wait... </div>}>
          <SelectClient selectTherapist={selectTherapist} friendClients={friendClients} selectClients={selectClients} nextStep={nextStep} prevStep={prevStep} />
        </Suspense>
      )}
      {step === 3 && selectTherapist && selectClient && (
        <CreateAppoinmentAdmin selectTherapist={selectTherapist} selectClient={selectClient} prevStep={prevStep} />
      )}
    </div>
  );
};

export default AddAppoinmentAdmin;
