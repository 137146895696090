import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, TabContent, TabPane, Button, Modal, ModalBody, Input } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { NavLink } from "react-router-dom";
import { AdminService } from 'src/services/AdminService';
import { toast } from 'react-toastify';
import Close from "../../assets/images/icons/close.png";
import Swal from "sweetalert2";
import { Locations } from 'src/models/Locations';

const AddStates: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [stateList, setStateList] = useState<any[]>([]);
  const [addNewStateModalOpen, setAddNewStateModalOpen] = useState<boolean>(false);
  const [newState, setnewState] = useState<string>("")

  useEffect(() => {
    getAllStates();
  }, []);
  
  const getAllStates = () => {
    setIsLoaded(true);
    AdminService.getAllStates()
      .then(res => {
        console.log(res);
        
        if (res.success) {
          setStateList(res.data);
        } else {
          toast.error(`Failed to fetch states`);
        }
      })
      .catch(err => {
        toast.error('Error fetching states');
        console.error('Error fetching states', err);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  function deleteStateType(_id: any) {
    if(_id){
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete the state?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.deleteState({stateId: _id}).then(res => {
            if (res.success) {
              setStateList((prevState) => prevState.filter(state => state._id !== _id));
              Swal.fire({
                icon: "success",
                title: "State deleted!",
                confirmButtonColor: "#FD7F00",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete state!.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  }

  const toggleModal = () => {
    setAddNewStateModalOpen(!addNewStateModalOpen);
  };

  const createState = async () => {
    if (!Object.values(Locations).includes(newState as Locations)) {
      toast.error("Invalid state name", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }
    const addState = await AdminService.addState({state: newState});
    if(addState.success){
      setStateList((prevState) => [...prevState, addState.data]);
      setAddNewStateModalOpen(false);
      setnewState("");
      toast.success("State added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    } else {
      toast.error(addState.error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
    });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"View States"} breadcrumbItem={"View States"} />
          
          {isLoaded && <Spinner className="bouncer" />}

          <Row>
            <Col xl={12}>
              <Button
                className="btn btn-primary relative mb-3"
                style={{
                  bottom: "0",
                  float: "left",
                }}
                onClick={toggleModal} // Toggle modal on button click
              >
                Add New State
              </Button>
            </Col>
          </Row>          

          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  <div className="table-responsive">
                    {(stateList && stateList.length > 0) ? (
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">State</th>
                            <th scope="col">Actions</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {stateList &&
                            stateList.map((state: any, index: number) => (
                              <tr key={state._id}>
                                <th scope="row">{index + 1}</th>
                                <td>{state.stateName}</td>
                                <td>
                                  <div className="d-flex p-0">
                                    <div className="px-2">
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => {
                                          deleteStateType(state?._id);
                                        }}
                                      >
                                        <i className="bx bx-trash"></i> Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div>No states Added</div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={addNewStateModalOpen} toggle={toggleModal} centered className="modal-lg" style={{transform: "translateY(-10%)"}}>
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleModal}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="ps-4 pe-4">
          <Row>
            <Col xl="3">
                <div className="card-heading mb-2">
                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                </div>
            </Col>
            <Col xl="6">
              <div className="card-heading mb-4">
                <select
                  className="form-control"
                  id="formrow-firstname-Input"
                  name="State"
                  value={newState}
                  onChange={(e) => setnewState(e.target.value)}
                  required
                >
                  <option value="" disabled>Select a State</option>
                  {Object.values(Locations).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
        </Row>
        <Row>
            <Col xl={6}></Col>
            <Col xl={6}>
                <button
                    type="button"
                    className="btn btn-primary relative mt-3 mb-2" style={{
                        bottom: "0",
                        float: "right",
                    }}
                    onClick={() => {
                      createState();
                    }}>
                    Save
                </button>


            </Col>
        </Row>
          </ModalBody>
        </Modal>
    </React.Fragment>
  )
}

export default AddStates