import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../assets/css/admin.css";
const classNames = require('classnames');
import { Client, PremiumStatus } from "../../models/Client";
import { AdminService } from "../../services/AdminService";
import Swal from "sweetalert2";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";
import UserContext from "../../context/UserContext";
import { SocketContext } from "../../context/ScoketContext";
import Spinner from "../../common/spinner/spinner";
import { ClientCountResponseModel } from "../../models/User";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";

const ManagePremiumClients: React.FC = () => {
  const [user] = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [isPremiumLoading, setIsPremiumLoading] = useState<boolean>(false);
  const [isRevokedLoading, setIsRevokedLoading] = useState<boolean>(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [premiumClients, setPremiumClients] = useState([] as Client[]);
  const [revokedClients, setRevokedClients] = useState([] as Client[]);
  const [premiumClientOffset, setPremiumClientOffset] = useState(1);
  const [revokedClientOffset, setRevokedClientOffset] = useState(1);
  const [hasMorePremiumClients, setMorePremiumClients] = useState(false);
  const [hasMoreRevokedClients, setMoreRevokedClients] = useState(false);
  const [clientsCount, setClinetsCount] = useState({} as ClientCountResponseModel);

  const LIMIT = 10;

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    getAllPremiumClients();
    getAllPremiumMembershipRevokedClients();
    getAllPremAndRevokedClientsCount();
  }, [customActiveTab]);

  const getAllPremAndRevokedClientsCount = () => {
    AdminService.getAllPremiumAndRevokedClients().then(res => {
      res.success && setClinetsCount(res.data)
    })
  }

  const getAllPremiumClients = () => {
    setIsPremiumLoading(true);

    AdminService.getAllPremiumClients(LIMIT, premiumClientOffset).then(res => {
      if (res.success) {
        setPremiumClients(res.data);
        setMorePremiumClients(res.data.length > 0 && res.data.length == LIMIT);
        setIsPremiumLoading(false);
      } else {
        setPremiumClients([] as Client[]);
        setMorePremiumClients(false);
        setIsPremiumLoading(false);
      }
    });
  };


  const seeMorePremiumClients = () => {
    setIsPremiumLoading(true);

    const updatedOffset = premiumClientOffset + 1;

    setPremiumClientOffset(updatedOffset);

    AdminService.getAllPremiumClients(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setPremiumClients(previousClients => {
          return [...new Set([...previousClients, ...res.data])];
        });
        setMorePremiumClients(res.data.length > 0 && res.data.length == LIMIT);
        setIsPremiumLoading(false);
      } else {
        setPremiumClients([] as Client[]);
        setMorePremiumClients(false);
        setIsPremiumLoading(false);
      }
    });
  };


  const getAllPremiumMembershipRevokedClients = () => {
    setIsRevokedLoading(true);

    AdminService.getAllPremiumMembershipRevokedClients(LIMIT, revokedClientOffset).then(res => {
      if (res.success) {
        setRevokedClients(res.data);
        setMoreRevokedClients(res.data.length > 0 && res.data.length == LIMIT);
        setIsRevokedLoading(false);
      } else {
        setRevokedClients([] as Client[]);
        setMoreRevokedClients(false);
        setIsRevokedLoading(false);
      }
    });
  };

  const seeMorePremiumMembershipRevokedClients = () => {

    const updatedOffset = revokedClientOffset + 1;

    setRevokedClientOffset(updatedOffset);

    AdminService.getAllPremiumMembershipRevokedClients(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setRevokedClients(previousClients => {
          return [...new Set([...previousClients, ...res.data])];
        });
        setMoreRevokedClients(res.data.length > 0 && res.data.length == LIMIT);
      } else {
        setPremiumClients([] as Client[]);
        setMorePremiumClients(false);
      }
    });
  };

  const authorizePremiumMembership = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to set this client a premium user?",
        showCancelButton: true,
        confirmButtonText: "Sure",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.togglePremiumMembership(userId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Membership upgraded!",
                confirmButtonColor: "#FD7F00",
              });

              setRevokedClients(revokedClients.filter((client: Client) => client._id !== userId));
              setMoreRevokedClients(revokedClients.length > 1);

              const PremiumUserNotification: NotificationModel = {
                senderId: user?._id,
                receiverId: userId,
                event: NotificationEvent.BECAME_PREMIUM,
                link: "/payment-history",
                content: "Congratulations! You have been granted premium user membership.",
                variant: NotificationVarient.SUCCESS,
              };

              NotificationService.sendNotification(PremiumUserNotification).then(res => {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: PremiumUserNotification,
                  senderId: user?._id,
                  receiverId: userId,
                };

                socket.emit("send-notification", socketData);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed upgrade the membership",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const revokePremiumMembership = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to set this client a generic user??",
        showCancelButton: true,
        confirmButtonText: "Sure",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.togglePremiumMembership(userId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Membership downgraded!",
                confirmButtonColor: "#FD7F00",
              });

              setPremiumClients(premiumClients.filter((client: Client) => client._id !== userId));
              setMorePremiumClients(premiumClients.length > 1);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed upgrade the membership",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Premium Clients"} breadcrumbItem={"Premium Clients"} />

          {isPremiumLoading || isRevokedLoading && <Spinner className="bouncer" />}

          <Row>
            <Col md="3" >
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        Premium Clients
                      </p>
                      <h4 className="mb-0">{clientsCount.premClientCount ? clientsCount.premClientCount : '-'}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i className={
                          "bx bxs-user font-size-24"
                        }
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" >
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        Revoked Clients
                      </p>
                      <h4 className="mb-0">{clientsCount.revokedClientCount ? clientsCount.revokedClientCount : '-'}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i className={
                          "bx bxs-user font-size-24"
                        }
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row>
                <Col xl="12">
                  <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: customActiveTab === "1" })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-view-grid-outline"></i>
                        </span>

                        <span className="d-none d-sm-block">Premium Clients</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: customActiveTab === "2" })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-format-list-bulleted"></i>
                        </span>

                        <span className="d-none d-sm-block">Revoked Clients</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="table-responsive">
                    <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12" className="mt10">
                            {!isPremiumLoading && premiumClients.length == 0 && <div className="text-muted text-center">No premium clients</div>}

                            {premiumClients && premiumClients.length > 0 && (
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Membership Status</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {premiumClients &&
                                    premiumClients.map((client, index) => (
                                      <tr key={client._id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {client.firstname}
                                          {client.lastname}
                                        </td>
                                        <td>{client.email}</td>
                                        <td>
                                          {client.premiumStatus == PremiumStatus.ACTIVE ? (
                                            <span className="premium">Premium</span>
                                          ) : (
                                            <>
                                              {(client.subscriptionId != null && client.subscriptionId != "") || client.testSubscriptionStatus == "active" ? (
                                                <>
                                                  {client.subscriptionStatus == "active" || client.testSubscriptionStatus == "active" ? (
                                                    <span className="subscribed">Subscribed</span>
                                                  ) : (
                                                    <span className="subscribed-not-active">Inactive</span>
                                                  )}
                                                </>
                                              ) : (
                                                <span className="normal">Normal</span>
                                              )}
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-success btn-sm"
                                            onClick={() => {
                                              revokePremiumMembership(client._id);
                                            }}
                                          >
                                            <i className="fa fa-undo"></i> Revoke
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12 mt30 text-center">
                            {hasMorePremiumClients && (
                              <button className="btn btn-warning" onClick={() => seeMorePremiumClients()}>
                                See More
                              </button>
                            )}
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12" className="mt10">
                            {!isRevokedLoading && revokedClients.length == 0 && <div className="text-muted text-center">No revoked clients</div>}

                            {revokedClients && revokedClients.length > 0 && (
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {revokedClients &&
                                    revokedClients.map((client, index) => (
                                      <tr key={client._id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {client.firstname} {client.lastname}
                                        </td>
                                        <td>{client.email}</td>
                                        <td>
                                          {client.premiumStatus == PremiumStatus.ACTIVE ? (
                                            <span className="premium">Premium</span>
                                          ) : (
                                            <>
                                              {(client.subscriptionId != null && client.subscriptionId != "") || client.testSubscriptionStatus == "active" ? (
                                                <>
                                                  {client.subscriptionStatus == "active" || client.testSubscriptionStatus == "active" ? (
                                                    <span className="subscribed">Subscribed</span>
                                                  ) : (
                                                    <span className="subscribed-not-active">Inactive</span>
                                                  )}
                                                </>
                                              ) : (
                                                <span className="normal">Normal</span>
                                              )}
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-info btn-sm"
                                            onClick={() => {
                                              authorizePremiumMembership(client._id);
                                            }}
                                          >
                                            <i className="fa fa-star"></i> Authorize
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12 mt30 text-center">
                            {hasMoreRevokedClients && (
                              <button className="btn btn-warning" onClick={() => seeMorePremiumMembershipRevokedClients()}>
                                See More
                              </button>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManagePremiumClients;
