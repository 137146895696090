import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../../context/UserContext";
import "../../../assets/css/chat_screen.css";
import { Card, CardBody, CardTitle, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CallSettingTab from "../CallSettingTab";
import CallEndContext from "../../../context/CallEndContext";
import AvatarTab2 from "../AvatarTab2";
import GroupChatView from "./GroupChatView";
import GroupChatContext from "src/context/GroupChatContext";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import { SessionsInSelectedChatGroupModel } from "src/models/GroupChat/SessionsInSelectedChatGroup";
import images from "src/assets/images";
import GroupMembersView from "./GroupMembersView";
import { MemberInSelectedChatGroupModel } from "src/models/GroupChat/MemberInSelectedChatGroup";
import PublicGroupRequestView from "./PublicGroupRequestView";
import { Role } from "src/models/Role";
const classNames = require("classnames");

const GroupChatChatMainScreen: React.FC = (props: any) => {
  const [tabNumber, setTab] = useState<number>(1);
  const [userOnCall, setUserOnCall] = useState<boolean>(false);
  const [userOnNewCall] = useState<boolean>(false);
  const [videoCallId, setVideoCallId] = useState<string>("");
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [user] = useContext(UserContext);
  // const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend] = useContext(LocalCallContext);
  const [endCall] = useContext(CallEndContext);
  const [showModal, setShowModal] = useState(false);
  const SCREEN_SIZE = 991;

  // useEffect(() => {
  //   setUserOnCall(localUserOnCall.onOngoingCall);

  //   if (props.location.state && props.location.state.currentTab) {
  //     setTab(props.location.state && props.location.state.currentTab);
  //   }

  //   if (videoCallId === "") {
  //     if (localUserOnCall.onOngoingCall && localUserOnCall.callId !== "") {
  //       setUserOnCall(true);
  //       setVideoCallId(localUserOnCall.callId);
  //     }
  //   } else {
  //     if (localUserOnCall.onOngoingCall && localUserOnCall.callId !== "") {
  //       setVideoCallId(localUserOnCall.callId);
  //       setUserOnCall(false);
  //     }
  //   }
  // }, [localUserOnCall]);

  // useEffect(() => {
  //   if (videoCallId !== "") {
  //     setVideoCallId(videoCallId);
  //     setUserOnCall(true);
  //   }
  // }, [videoCallId]);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
    const avatarClick = localStorage.getItem("avatar");
    if (avatarClick) {
      setActiveTab(3);
      localStorage.removeItem("avatar");
    }
    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  // function cancellCallAfterCallSendFunction() {
  //   if (!userOnNewCall) {
  //     setUserOnCall(false);
  //     setVideoCallId("");
  //     const dataForCall: LocalCall = {
  //       callId: "",
  //       onOngoingCall: false,
  //       recieversUseDefaultAvatarValue: true,
  //       recieversAvatarId: "",
  //       recieversAvatarBackgroundId: "",
  //       recieversIncogniteModeOn: true,
  //       meetingMainTherapistId: "",
  //       isVideoCall: false,
  //       meetingDuration: 30,
  //       recieversUserId: "",
  //       recieversName: "",
  //       recieverCallRecordingAllowed: false,
  //       recieversSocketId: "",
  //       callEndedForBothUsers: false,
  //       videoSdkToken: "",
  //     };
  //     cancellCallAfterCallSend(false, dataForCall);
  //   }
  // }

  // function endVideoCall() {
  //   if (!userOnNewCall) {
  //     setUserOnCall(false);
  //     setVideoCallId("");
  //     const dataForCall: LocalCall = {
  //       callId: "",
  //       onOngoingCall: false,
  //       recieversUseDefaultAvatarValue: true,
  //       recieversAvatarId: "",
  //       recieversAvatarBackgroundId: "",
  //       recieversIncogniteModeOn: true,
  //       meetingMainTherapistId: "",
  //       isVideoCall: false,
  //       meetingDuration: 30,
  //       recieversUserId: "",
  //       recieversName: "",
  //       recieverCallRecordingAllowed: false,
  //       recieversSocketId: "",
  //       callEndedForBothUsers: false,
  //       videoSdkToken: "",
  //     };

  //     setLocalUserOnCall(false, dataForCall, endCall ? false : true);
  //   } else {
  //     setUserOnCall(true);
  //     setVideoCallId(localUserOnCall.callId);
  //   }
  // }

  function setActiveTab(tabNmber: React.SetStateAction<number>) {
    setTab(tabNmber);
  }

  function teabNavigationDetect(location: any): boolean {
    if (location.pathname != "/chat") {
      setShowModal(true);
    }
    return false;
  }

  // group chat handling
  const initialSelectedChatGroup: GroupChatModel = { _id: "", title: "", description: "", type: "", createdAt: "", createdBy: "" };

  const [selectedChatGroup, setSelectedChatGroup] = useState<GroupChatModel>(initialSelectedChatGroup);
  function setSelectedChatGroupFunction(selectedGroup: GroupChatModel) {
    setSelectedChatGroup(selectedGroup);
  }

  const [selectedGroupIdForShowMembers, setSelectedGroupIdForShowMembers] = useState<string>("");
  function setSelectedGroupIdForShowMembersFunction(groupId: string) {
    setSelectedGroupIdForShowMembers(groupId);
  }

  const [selectedGroupIdForShowRequests, setSelectedGroupIdForShowRequests] = useState<string>("");
  function setSelectedGroupIdForShowRequestsFunction(groupId: string) {
    setSelectedGroupIdForShowRequests(groupId);
  }

  const [reloadSessionList, setReloadSessionList] = useState<boolean>(false);
  function setReloadSessionListFunction(value: boolean) {
    setReloadSessionList(value);
  }

  return (
    <GroupChatContext.Provider
      value={[
        selectedChatGroup,
        setSelectedChatGroupFunction,
        selectedGroupIdForShowMembers,
        setSelectedGroupIdForShowMembersFunction,
        selectedGroupIdForShowRequests,
        setSelectedGroupIdForShowRequestsFunction,
        reloadSessionList,
        setReloadSessionListFunction,
      ]}
    >
      <React.Fragment>
        <div
          className={`${
            (selectedChatGroup != null &&
              selectedChatGroup._id != null &&
              selectedChatGroup._id != "" &&
              (selectedGroupIdForShowRequests == null || selectedGroupIdForShowRequests == "") &&
              selectedGroupIdForShowMembers != null &&
              selectedGroupIdForShowMembers == selectedChatGroup._id) ||
            (selectedChatGroup != null &&
              selectedChatGroup._id != null &&
              selectedChatGroup._id != "" &&
              (selectedGroupIdForShowMembers == null || selectedGroupIdForShowMembers == "") &&
              selectedGroupIdForShowRequests != null &&
              selectedGroupIdForShowRequests == selectedChatGroup._id)
              ? "page-content d-none"
              : user?.role == Role.CLIENT
              ? "page-content pt-20"
              : user?.role == Role.THERAPIST
              ? "page-content pt-20"
              : "page-content"
          }`}
        >
          <title>Chat </title>

          {!userOnCall && (
            <Container fluid>
              <Col lg={12} className={"mb-4"}>
                {user?.role == "THERAPIST" && (
                  <>
                    <Nav tabs className="nav-tabs-custom">
                      <NavItem className="nav-i">
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: tabNumber === 1 })}
                          onClick={() => {
                            setActiveTab(1);
                          }}
                        >
                          <i className="bx bxs-message"></i> &nbsp;
                          <span className=" ">Chat</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="nav-i">
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: tabNumber === 4 })}
                          onClick={() => {
                            setActiveTab(4);
                          }}
                        >
                          <i className="bx bxs-cog"></i> &nbsp;
                          <span className="">Chat Settings</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </>
                )}

                {user?.role == "CLIENT" && (
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem className="nav-i">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: tabNumber === 1 })}
                        onClick={() => {
                          setActiveTab(1);
                        }}
                      >
                        <i className="bx bxs-message"></i> &nbsp;
                        <span className=" ">Chat</span>
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav-i">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: tabNumber === 3 })}
                        onClick={() => {
                          setActiveTab(3);
                        }}
                      >
                        <i className="bx bxs-user"></i> &nbsp;
                        <span className="">Avatar</span>
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav-i">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: tabNumber === 4 })}
                        onClick={() => {
                          setActiveTab(4);
                        }}
                      >
                        <i className="bx bxs-cog"></i> &nbsp;
                        <span className="">Chat Settings</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
              </Col>

              {tabNumber == 1 && screenSize > SCREEN_SIZE && <Breadcrumbs title="Lavni" breadcrumbItem="Group Chat" />}
              {tabNumber == 2 && <h4 className="mb-0 font-size-16 text-uppercase fw-bold">Appointments</h4>}
              {tabNumber == 3 && <h4 className="mb-0 font-size-16 text-uppercase fw-bold">Create Your Avatar</h4>}
              <div>
                {tabNumber == 1 && <GroupChatView></GroupChatView>}
                {tabNumber == 3 && <AvatarTab2></AvatarTab2>}
                {tabNumber == 4 && <CallSettingTab></CallSettingTab>}
              </div>
            </Container>
          )}
        </div>

        {selectedChatGroup != null &&
          selectedChatGroup._id != null &&
          selectedChatGroup._id != "" &&
          (selectedGroupIdForShowRequests == null || selectedGroupIdForShowRequests == "") &&
          selectedGroupIdForShowMembers != null &&
          selectedGroupIdForShowMembers == selectedChatGroup._id && <GroupMembersView></GroupMembersView>}
        {selectedChatGroup != null &&
          selectedChatGroup._id != null &&
          selectedChatGroup._id != "" &&
          (selectedGroupIdForShowMembers == null || selectedGroupIdForShowMembers == "") &&
          selectedGroupIdForShowRequests != null &&
          selectedGroupIdForShowRequests == selectedChatGroup._id && <PublicGroupRequestView></PublicGroupRequestView>}
      </React.Fragment>
    </GroupChatContext.Provider>
  );
};
export default GroupChatChatMainScreen;
