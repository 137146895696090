import React, { useContext, useState, useEffect, useRef } from "react";
import { CardTitle, Col, Row, Modal, CardBody, Input, CardFooter } from "reactstrap";
import UserContext from "../../context/UserContext";
import { ClientService } from "../../services/ClientService";
import { AddressModel, DependentModel, Insurance, ReceiverModel, SubscriberModel } from "../../models/Insurance";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";

const Modal21: React.FC<{
    showModal21: boolean;
    setShowModal21: (value: boolean) => void;
    addOrganizationData: any;
    insuranceCardImage: any;
    insuranceCardImageBack: any;
}> = props => {
    const [user, setUser] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [addressData, setAddressData] = useState<AddressModel>({} as AddressModel);
    const [dependentAddressData, setDependentAddressData] = useState<AddressModel>({} as AddressModel);
    const [subscriberData, setSubscriberData] = useState<SubscriberModel>({} as SubscriberModel);
    const [dependentData, setDependentData] = useState<DependentModel>({} as DependentModel);
    const [dateValue, setDateValue] = useState(null);
    const [dependentDateValue, setDependentDateValue] = useState(null);
    const [insuranceMainData, setInsuranceMainData] = useState<Insurance>({} as Insurance);
    const [haveAgree, setHaveAgree] = useState("No");
    const [saveDisabled, setDisabled] = useState(false)
    let state;

    function getStateByZipCode(zipString: string) {
        if (typeof zipString != 'string') {
            toast.error("Must pass the zipcode as a string.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            return false;
        }

        if (zipString.length != 5) {
            console.log("Must pass a 5-digit zipcode.");
            
            return false;
        }

        const zipcode = parseInt(zipString, 10);

        if (zipcode >= 35000 && zipcode <= 36999) {
            state = 'Alabama';
        } else if (zipcode >= 99500 && zipcode <= 99999) {
            state = 'Alaska';
        } else if (zipcode >= 85000 && zipcode <= 86999) {
            state = 'Arizona';
        } else if (zipcode >= 71600 && zipcode <= 72999) {
            state = 'Arkansas';
        } else if (zipcode >= 90000 && zipcode <= 96699) {
            state = 'California';
        } else if (zipcode >= 80000 && zipcode <= 81999) {
            state = 'Colorado';
        } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
            state = 'Connecticut';
        } else if (zipcode >= 19700 && zipcode <= 19999) {
            state = 'Delaware';
        } else if (zipcode >= 32000 && zipcode <= 34999) {
            state = 'Florida';
        } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
            state = 'Georgia';
        } else if (zipcode >= 96700 && zipcode <= 96999) {
            state = 'Hawaii';
        } else if (zipcode >= 83200 && zipcode <= 83999) {
            state = 'Idaho';
        } else if (zipcode >= 60000 && zipcode <= 62999) {
            state = 'Illinois';
        } else if (zipcode >= 46000 && zipcode <= 47999) {
            state = 'Indiana';
        } else if (zipcode >= 50000 && zipcode <= 52999) {
            state = 'Iowa';
        } else if (zipcode >= 66000 && zipcode <= 67999) {
            state = 'Kansas';
        } else if (zipcode >= 40000 && zipcode <= 42999) {
            state = 'Kentucky';
        } else if (zipcode >= 70000 && zipcode <= 71599) {
            state = 'Louisiana';
        } else if (zipcode >= 3900 && zipcode <= 4999) {
            state = 'Maine';
        } else if (zipcode >= 20600 && zipcode <= 21999) {
            state = 'Maryland';
        } else if ((zipcode >= 1000 && zipcode <= 2799) || (zipcode == 5501) || (zipcode == 5544)) {
            state = 'Massachusetts';
        } else if (zipcode >= 48000 && zipcode <= 49999) {
            state = 'Michigan';
        } else if (zipcode >= 55000 && zipcode <= 56899) {
            state = 'Minnesota';
        } else if (zipcode >= 38600 && zipcode <= 39999) {
            state = 'Mississippi';
        } else if (zipcode >= 63000 && zipcode <= 65999) {
            state = 'Missouri';
        } else if (zipcode >= 59000 && zipcode <= 59999) {
            state = 'Montana';
        } else if (zipcode >= 27000 && zipcode <= 28999) {
            state = 'North Carolina';
        } else if (zipcode >= 58000 && zipcode <= 58999) {
            state = 'North Dakota';
        } else if (zipcode >= 68000 && zipcode <= 69999) {
            state = 'Nebraska';
        } else if (zipcode >= 88900 && zipcode <= 89999) {
            state = 'Nevada';
        } else if (zipcode >= 3000 && zipcode <= 3899) {
            state = 'New Hampshire';
        } else if (zipcode >= 7000 && zipcode <= 8999) {
            state = 'New Jersey';
        } else if (zipcode >= 87000 && zipcode <= 88499) {
            state = 'New Mexico';
        } else if ((zipcode >= 10000 && zipcode <= 14999) || (zipcode == 6390) || (zipcode == 501) || (zipcode == 544)) {
            state = 'New York';
        } else if (zipcode >= 43000 && zipcode <= 45999) {
            state = 'Ohio';
        } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
            state = 'Oklahoma';
        } else if (zipcode >= 97000 && zipcode <= 97999) {
            state = 'Oregon';
        } else if (zipcode >= 15000 && zipcode <= 19699) {
            state = 'Pennsylvania';
        } else if (zipcode >= 300 && zipcode <= 999) {
            state = 'Puerto Rico';
        } else if (zipcode >= 2800 && zipcode <= 2999) {
            state = 'Rhode Island';
        } else if (zipcode >= 29000 && zipcode <= 29999) {
            state = 'South Carolina';
        } else if (zipcode >= 57000 && zipcode <= 57999) {
            state = 'South Dakota';
        } else if (zipcode >= 37000 && zipcode <= 38599) {
            state = 'Tennessee';
        } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
            state = 'Texas';
        } else if (zipcode >= 84000 && zipcode <= 84999) {
            state = 'Utah';
        } else if (zipcode >= 5000 && zipcode <= 5999) {
            state = 'Vermont';
        } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode == 20598)) {
            state = 'Virginia';
        } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
            state = 'Washington DC';
        } else if (zipcode >= 98000 && zipcode <= 99499) {
            state = 'Washington';
        } else if (zipcode >= 24700 && zipcode <= 26999) {
            state = 'West Virginia';
        } else if (zipcode >= 53000 && zipcode <= 54999) {
            state = 'Wisconsin';
        } else if (zipcode >= 82000 && zipcode <= 83199) {
            state = 'Wyoming';
        } else {
            state = '';
            toast.error("No state found matching.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        return state;

    }


    function handleDependentDateUpdate(e: any) {
        const dateValue = e.target.value;
        setDependentDateValue(dateValue);
    }
    const db = moment.utc(user?.dateOfBirth).format('YYYY-MM-DD');
    function handlePostalCode(e: any) {
        const selectedState = getStateByZipCode(e);
        setAddressData({ ...addressData, postalCode: e, state: selectedState ? selectedState : "" })
    }

    function handleDependentPostalCode(e: any) {
        const selectedState = getStateByZipCode(e);
        setDependentAddressData({ ...dependentAddressData, postalCode: e, state: selectedState ? selectedState : "" })
    }



    function handleDateUpdate(e: any) {
        const dateValue = e.target.value;
        setDateValue(dateValue);
    }

    const addInsuranceDetails = () => {
        if (!subscriberData?.memberId) {
            toast.error("Member id is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        } else {
            setDisabled(true)

            const address: any = {
                address1: user?.streetAddress,
                city: user?.city,
                state: user?.state || addressData?.state,
                postalCode: addressData?.postalCode || user?.zipCode,
            }
            const dependentAddress: any = {
                address1: dependentAddressData?.address1 || insuranceMainData?.dependent?.address?.address1,
                city: dependentAddressData?.city || insuranceMainData?.dependent?.address?.city,
                state: dependentAddressData?.state || insuranceMainData?.dependent?.address?.state,
                postalCode: dependentAddressData?.postalCode || insuranceMainData?.dependent?.address?.postalCode,
            }
            const subcribersData: any = {
                memberId: subscriberData?.memberId,
                policyNumber: subscriberData?.policyNumber,
                firstName: user?.firstname,
                lastName: user?.lastname,
                gender: user?.gender,
                dateOfBirth: dateValue as any || db,
                paymentResponsibilityLevelCode: subscriberData?.paymentResponsibilityLevelCode,
                address: address,

            }

            const dependent: any = {
                memberId: dependentData?.memberId || insuranceMainData?.dependent?.memberId,
                policyNumber: dependentData?.policyNumber || insuranceMainData?.dependent?.policyNumber,
                firstName: dependentData?.firstName || insuranceMainData?.dependent?.firstName,
                lastName: dependentData?.lastName || insuranceMainData?.dependent?.lastName,
                gender: dependentData?.gender || insuranceMainData?.dependent?.gender,
                dateOfBirth: dependentDateValue as any || insuranceMainData?.dependent?.dateOfBirth,
                paymentResponsibilityLevelCode: dependentData?.paymentResponsibilityLevelCode || insuranceMainData?.dependent?.paymentResponsibilityLevelCode,
                relationshipToSubscriberCode: dependentData?.relationshipToSubscriberCode || insuranceMainData?.dependent?.relationshipToSubscriberCode,
                address: dependentAddress,
            }


            if (props.insuranceCardImage && props.insuranceCardImageBack) {
                const data: any = {
                    insuranceId: insuranceMainData?._id,
                    clientId: user?._id,
                    insuranceCompanyId: props.addOrganizationData,
                    subscriber: subcribersData,
                    insuranceCardId: insuranceMainData?.insuranceCardId,
                    insuranceCardBackId: insuranceMainData?.insuranceCardBackId,
                    dependent: dependent,
                    deletingInsuranceCardFrontId: insuranceMainData?.insuranceCardId?._id || "none",
                    deletingInsuranceCardBackId: insuranceMainData?.insuranceCardBackId?._id || "none",
                }

                setDisabled(true)
                ClientService.addInsurancePlan(data, props.insuranceCardImage, props.insuranceCardImageBack).then((res) => {
                    if (res.success) {
                        setUser({ ...user, insuranceId: res.data });
                        setInsuranceMainData(res.data)
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });

                    } else {
                        Swal.fire({
                            icon: "warning",
                            title: "Do you still want to proceed?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            confirmButtonColor: "#FD7F00",
                            cancelButtonColor: "#FD7F00",
                        }).then(result => {
                            if (result.isConfirmed) {
                                setDisabled(false)
                                setUser({ ...user, insuranceId: res.errorData });
                                setInsuranceMainData(res?.errorData)
                            } else {
                                setDisabled(false)
                            }
                        });
                        toast.error(res.errorCode, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            } else if (props.insuranceCardImage && !props.insuranceCardImageBack) {
                const data: any = {
                    insuranceId: insuranceMainData?._id,
                    clientId: user?._id,
                    insuranceCompanyId: props.addOrganizationData,
                    subscriber: subcribersData,
                    insuranceCardId: insuranceMainData?.insuranceCardId,
                    insuranceCardBackId: insuranceMainData?.insuranceCardBackId,
                    dependent: dependent,
                    deletingInsuranceCardFrontId: insuranceMainData?.insuranceCardId?._id || "none",
                    deletingInsuranceCardBackId: "none",
                }
                setDisabled(true)
                ClientService.addInsurancePlan(data, props.insuranceCardImage, props.insuranceCardImageBack).then((res) => {
                    if (res.success) {
                        setUser({ ...user, insuranceId: res.data });
                        setInsuranceMainData(res.data)
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });

                    } else {
                        Swal.fire({
                            icon: "warning",
                            title: "Do you still want to proceed?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            confirmButtonColor: "#FD7F00",
                            cancelButtonColor: "#FD7F00",
                        }).then(result => {
                            if (result.isConfirmed) {
                                setDisabled(false)
                                setUser({ ...user, insuranceId: res.errorData });
                                setInsuranceMainData(res?.errorData)
                            } else {
                                setDisabled(false)
                            }
                        });
                        toast.error(res.errorCode, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            } else if (props.insuranceCardImageBack && !props.insuranceCardImage) {
                const data: any = {
                    insuranceId: insuranceMainData?._id,
                    clientId: user?._id,
                    insuranceCompanyId: props.addOrganizationData,
                    subscriber: subcribersData,
                    insuranceCardId: insuranceMainData?.insuranceCardId,
                    insuranceCardBackId: insuranceMainData?.insuranceCardBackId,
                    dependent: dependent,
                    deletingInsuranceCardFrontId: "none",
                    deletingInsuranceCardBackId: insuranceMainData?.insuranceCardBackId?._id || "none",
                }

                ClientService.addInsurancePlan(data, props.insuranceCardImage, props.insuranceCardImageBack).then((res) => {
                    if (res.success) {
                        setInsuranceMainData(res.data)
                        setUser({ ...user, insuranceId: res.data });
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });

                    } else {
                        Swal.fire({
                            icon: "warning",
                            title: "Do you still want to proceed?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            confirmButtonColor: "#FD7F00",
                            cancelButtonColor: "#FD7F00",
                        }).then(result => {
                            if (result.isConfirmed) {
                                setDisabled(false)
                                setUser({ ...user, insuranceId: res.errorData });
                                setInsuranceMainData(res?.errorData)
                            } else {
                                setDisabled(false)
                            }
                        });
                        toast.error(res.errorCode, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            } else {

                const data: any = {
                    insuranceId: insuranceMainData?._id,
                    clientId: user?._id,
                    insuranceCompanyId: props.addOrganizationData,
                    subscriber: subcribersData,
                    insuranceCardId: insuranceMainData?.insuranceCardId?._id,
                    insuranceCardBackId: insuranceMainData?.insuranceCardBackId?._id,
                    dependent: dependent,
                    deletingInsuranceCardFrontId: "none",
                    deletingInsuranceCardBackId: "none",
                }

                ClientService.addInsurancePlan(data, props.insuranceCardImage, props.insuranceCardImageBack).then((res) => {
                    if (res.success) {
                        setInsuranceMainData(res.data)
                        setUser({ ...user, insuranceId: res.data });
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });

                    } else {
                        Swal.fire({
                            icon: "warning",
                            title: "Do you still want to proceed?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            confirmButtonColor: "#FD7F00",
                            cancelButtonColor: "#FD7F00",
                        }).then(result => {
                            if (result.isConfirmed) {
                                setDisabled(false)
                                setUser({ ...user, insuranceId: res.errorData });
                                setInsuranceMainData(res?.errorData)
                            } else {
                                setDisabled(false)
                            }
                        });
                        toast.error(res.errorCode, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }

        }
    }


    return props.showModal21 ? (
        <>

            <CardBody>

                {
                    user?.role == "CLIENT" ? (
                        <>
                            <CardTitle className="padding-l-r font-size-18">How would you like to pay for the appointment?</CardTitle>
                            <p className="mb-5 padding-l-r">/ My Insurance Provider</p>

                            <div className="padding-l-r">

                                <div className="card-heading mb-2">
                                    <h5 className="font-size-20 profileFont mb-4">Subscriber</h5>
                                </div>
                                <Row>
                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Member Id "
                                                    name="Member Id "
                                                    // defaultValue={insuranceMainData?.subscriber?.memberId || ""}
                                                    onChange={(e) => setSubscriberData({ ...subscriberData, memberId: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Payment Responsibility Level Code"
                                                    name="Payment Responsibility Level Code"
                                                    defaultValue={insuranceMainData?.subscriber?.paymentResponsibilityLevelCode || ""}
                                                    onChange={(e) => setSubscriberData({ ...subscriberData, paymentResponsibilityLevelCode: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="First name"
                                                    name="First name"
                                                    value={user?.firstname || ""}
                                                    onChange={(e) => setUser({ ...user, firstname: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Last name"
                                                    name="Last name"
                                                    value={user?.lastname || ""}
                                                    onChange={(e) => setUser({ ...user, lastname: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                        </div>

                                        <div className="card-heading mb-4 pr-5">
                                            <Row>
                                                <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadioss"
                                                            id="exampleRadios1"
                                                            value="Female"
                                                            defaultChecked={user?.gender == "Female"}
                                                            onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios1"
                                                        >
                                                            Female
                                                        </label>
                                                    </div>

                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadioss"
                                                            id="exampleRadios2"
                                                            value="Male"
                                                            defaultChecked={user?.gender == "Male"}
                                                            onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios2"
                                                        >
                                                            Male
                                                        </label>
                                                    </div>

                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadioss"
                                                            id="exampleRadios3"
                                                            value="NonBinary"
                                                            defaultChecked={user?.gender == "NonBinary"}
                                                            onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios3"
                                                        >
                                                            Non Binary
                                                        </label>
                                                    </div>

                                                    <div className="form-check mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadioss"
                                                            id="exampleRadios4"
                                                            value="Other"
                                                            defaultChecked={user?.gender == "Other"}
                                                            onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios4"
                                                        >
                                                            Other
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Date of birth"
                                                    value={db || ""}
                                                    onChange={(e) => handleDateUpdate(e)}
                                                    disabled
                                                    max={moment().format("YYYY-MM-DD")}
                                                    required
                                                />
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="policy Number"
                                                    name="policy Number"
                                                    defaultValue={insuranceMainData?.subscriber?.policyNumber || ""}
                                                    onChange={(e) => setSubscriberData({ ...subscriberData, policyNumber: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Address"
                                                    name="Address"
                                                    value={user?.streetAddress || ""}
                                                    onChange={(e) => setUser({ ...user, streetAddress: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">City</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="City"
                                                    name="City"
                                                    value={user?.city || ""}
                                                    onChange={(e) => setUser({ ...user, city: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl="6">
                                        <div>
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                            </div>

                                            <div className="card-heading mb-4 pr-5">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Postal Code"
                                                    name="Postal Code"
                                                    defaultValue={user?.zipCode}
                                                    
                                                    onChange={(e) => handlePostalCode(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    {addressData?.state &&
                                        <Col xl="6">
                                            <div>
                                                <div className="card-heading mb-2">
                                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                                </div>
                                                <div className="card-heading mb-4 pr-5">
                                                    <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceMainData?.subscriber?.address?.state}</h5>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>

                                {insuranceMainData?.dependent?.memberId ?
                                    <>
                                        <div className="card-heading mb-2">
                                            <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                                        </div>

                                        <Row>
                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Member Id "
                                                            name="Member Id "
                                                            defaultValue={insuranceMainData?.dependent?.memberId || ""}
                                                            onChange={(e) => setDependentData({ ...dependentData, memberId: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Payment Responsibility Level Code"
                                                            name="Payment Responsibility Level Code"
                                                            defaultValue={insuranceMainData?.dependent?.paymentResponsibilityLevelCode || ""}
                                                            onChange={(e) => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="First name"
                                                            name="First name"
                                                            defaultValue={insuranceMainData?.dependent?.firstName || ""}
                                                            onChange={(e) => setDependentData({ ...dependentData, firstName: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Last name"
                                                            name="Last name"
                                                            defaultValue={insuranceMainData?.dependent?.lastName || ""}
                                                            onChange={(e) => setDependentData({ ...dependentData, lastName: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                            </Col>


                                            <Col xl="6">
                                                <div className="card-heading mb-2">
                                                    <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                                </div>

                                                <div className="card-heading mb-4 pr-5">
                                                    <Row>
                                                        <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadiosss"
                                                                    id="exampleRadios11"
                                                                    value="Female"
                                                                    defaultChecked={insuranceMainData?.dependent?.gender == "Female"}
                                                                    onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                />
                                                                <label className="form-check-label" htmlFor="exampleRadios11" > Female </label>
                                                            </div>

                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadiosss"
                                                                    id="exampleRadios22"
                                                                    value="Male"
                                                                    defaultChecked={insuranceMainData?.dependent?.gender == "Male"}
                                                                    onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                />

                                                                <label className="form-check-label" htmlFor="exampleRadios22" > Male </label>
                                                            </div>

                                                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadiosss"
                                                                    id="exampleRadios33"
                                                                    value="NonBinary"
                                                                    defaultChecked={insuranceMainData?.dependent?.gender == "NonBinary"}
                                                                    onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                />

                                                                <label className="form-check-label" htmlFor="exampleRadios33" > Non Binary </label>
                                                            </div>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="exampleRadiosss"
                                                                    id="exampleRadios44"
                                                                    value="Other"
                                                                    defaultChecked={insuranceMainData?.dependent?.gender == "Other"}
                                                                    onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                />

                                                                <label className="form-check-label" htmlFor="exampleRadios44" > Other </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="date"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Date of birth"
                                                            value={moment.utc(user?.dateOfBirth).format("YYYY-MM-DD")}
                                                            disabled
                                                            max={moment().format("YYYY-MM-DD")}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="policy Number"
                                                            name="policy Number"
                                                            defaultValue={insuranceMainData?.dependent?.policyNumber || ""}
                                                            onChange={(e) => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Relationship To Subscriber Code"
                                                            name="Relationship To Subscriber Code"
                                                            defaultValue={insuranceMainData?.dependent?.relationshipToSubscriberCode || ""}
                                                            onChange={(e) => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Address"
                                                            name="Address"
                                                            defaultValue={insuranceMainData?.dependent?.address?.address1 || ""}
                                                            onChange={(e) => setDependentAddressData({ ...dependentAddressData, address1: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">City</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="City"
                                                            name="City"
                                                            defaultValue={insuranceMainData?.dependent?.address?.city || ""}
                                                            onChange={(e) => setDependentAddressData({ ...dependentAddressData, city: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl="6">
                                                <div>
                                                    <div className="card-heading mb-2">
                                                        <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                                    </div>

                                                    <div className="card-heading mb-4 pr-5">
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Postal Code"
                                                            name="Postal Code"
                                                            defaultValue={insuranceMainData?.dependent?.address?.postalCode}
                                                            
                                                            onChange={(e) => handleDependentPostalCode(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            {dependentAddressData?.state &&
                                                <Col xl="6">
                                                    <div>
                                                        <div className="card-heading mb-2">
                                                            <h5 className="font-size-14 profileFont mb-2">State</h5>
                                                        </div>

                                                        <div className="card-heading mb-4 flex">
                                                            <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceMainData?.subscriber?.address?.state}</h5>

                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col xl="12">
                                                <div className="card-heading mb-2">
                                                    <h5 className="font-size-14 profileFont mb-2">Do you wish to add any dependents?</h5>
                                                </div>
                                            </Col>

                                            <Col xl="4">
                                                <div className="card-heading mt-2 mb-3">
                                                    <select className="form-control" onChange={(e) => setHaveAgree(e.target.value)}>
                                                        <option selected>Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No" selected>No</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>

                                        {haveAgree == "Yes" && <>
                                            <>
                                                <div className="card-heading mb-2">
                                                    <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                                                </div>
                                                <Row>
                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Member Id "
                                                                    name="Member Id "
                                                                    defaultValue={insuranceMainData?.dependent?.memberId || ""}
                                                                    onChange={(e) => setDependentData({ ...dependentData, memberId: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Payment Responsibility Level Code"
                                                                    name="Payment Responsibility Level Code"
                                                                    defaultValue={insuranceMainData?.dependent?.paymentResponsibilityLevelCode || ""}
                                                                    onChange={(e) => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="First name"
                                                                    name="First name"
                                                                    defaultValue={insuranceMainData?.dependent?.firstName || ""}
                                                                    onChange={(e) => setDependentData({ ...dependentData, firstName: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Last name"
                                                                    name="Last name"
                                                                    defaultValue={insuranceMainData?.dependent?.lastName || ""}
                                                                    onChange={(e) => setDependentData({ ...dependentData, lastName: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div className="card-heading mb-2">
                                                            <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                                        </div>

                                                        <div className="card-heading mb-4 pr-5">
                                                            <Row>
                                                                <Col style={{ display: "flex", flexWrap: "wrap" }}>

                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="exampleRadiosss"
                                                                            id="exampleRadios11"
                                                                            value="Female"
                                                                            defaultChecked={insuranceMainData?.dependent?.gender == "Female"}
                                                                            onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios11"
                                                                        >
                                                                            Female
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="exampleRadiosss"
                                                                            id="exampleRadios22"
                                                                            value="Male"
                                                                            defaultChecked={insuranceMainData?.dependent?.gender == "Male"}
                                                                            onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios22"
                                                                        >
                                                                            Male
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="exampleRadiosss"
                                                                            id="exampleRadios33"
                                                                            value="NonBinary"
                                                                            defaultChecked={insuranceMainData?.dependent?.gender == "NonBinary"}
                                                                            onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios33"
                                                                        >
                                                                            Non Binary
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="exampleRadiosss"
                                                                            id="exampleRadios44"
                                                                            value="Other"
                                                                            defaultChecked={insuranceMainData?.dependent?.gender == "Other"}
                                                                            onChange={(e) => setDependentData({ ...dependentData, gender: e.target.value })}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios44"
                                                                        >
                                                                            Other
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">

                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Date of birth"
                                                                    onChange={(e) => handleDependentDateUpdate(e)}
                                                                    max={moment().format("YYYY-MM-DD")}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="policy Number"
                                                                    name="policy Number"
                                                                    defaultValue={insuranceMainData?.dependent?.policyNumber || ""}
                                                                    onChange={(e) => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Relationship To Subscriber Code"
                                                                    name="Relationship To Subscriber Code"
                                                                    defaultValue={insuranceMainData?.dependent?.relationshipToSubscriberCode || ""}
                                                                    onChange={(e) => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Address"
                                                                    name="Address"
                                                                    defaultValue={insuranceMainData?.dependent?.address?.address1 || ""}
                                                                    onChange={(e) => setDependentAddressData({ ...dependentAddressData, address1: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">City</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="City"
                                                                    name="City"
                                                                    defaultValue={insuranceMainData?.dependent?.address?.city || ""}
                                                                    onChange={(e) => setDependentAddressData({ ...dependentAddressData, city: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl="6">
                                                        <div>
                                                            <div className="card-heading mb-2">
                                                                <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                                            </div>

                                                            <div className="card-heading mb-4 pr-5">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="formrow-firstname-Input"
                                                                    placeholder="Postal Code"
                                                                    name="Postal Code"
                                                                    defaultValue={insuranceMainData?.dependent?.address?.postalCode}
                                                                    
                                                                    onChange={(e) => handleDependentPostalCode(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    {dependentAddressData?.state &&
                                                        <Col xl="6">
                                                            <div>
                                                                <div className="card-heading mb-2">
                                                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                                                </div>

                                                                <div className="card-heading mb-4 flex">
                                                                    <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceMainData?.subscriber?.address?.state}</h5>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                            </>
                                        </>
                                        }
                                    </>
                                }

                            </div>
                            <div className="padding-l-r mb-5">
                                <button
                                    type="button"
                                    className={saveDisabled ? "btn btn-primary next-btn relative mb-4 ml-10 updating" : "btn btn-primary next-btn relative mb-4 ml-10"}
                                    style={{
                                        bottom: "0",
                                        float: "right",
                                    }}
                                    disabled={saveDisabled}
                                    onClick={() => {
                                        addInsuranceDetails();
                                    }}
                                >
                                    {saveDisabled ? "Saving..." : "Next"}
                                </button>

                            </div>
                        </>

                    ) : (
                        <CardTitle className="mb-4 text-center font-size-20">This client doesn&lsquo;t have an active subscription.</CardTitle>
                    )
                }

            </CardBody>
        </>
    ) : null;
};

export default Modal21;
