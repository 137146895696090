import React, { useContext, useState } from 'react';
import styles from './styles';
import { useEffect } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import RouterIcon from '@material-ui/icons/Router';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import useStyles from "./styles";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSelector, useDispatch } from 'react-redux';
import CustomModal from '../NewCustomButton/CustomModal';
import { setButtonVibrate } from 'src/store/ui/actions';
import { AppointmentService } from 'src/services/AppointmentService';
import { ClientService } from 'src/services/ClientService';
import { Util } from 'src/Util';
import moment from 'moment';
import UserContext from 'src/context/UserContext';
import LocalCallContext from 'src/context/LocalCallContext';
import { toast } from 'react-toastify';
// import UserContext from "src/context/UserContext";
// const [loggedUser] = useContext(UserContext);

function WaitingToast() {
  const [open, setOpen] = React.useState(true);
  const [openFollowUp, setOpenFollowUp] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [initialTime] = React.useState(moment()); // Store initial time when component mounts
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user] = useContext(UserContext);
  const [localUserOnCall] = useContext(LocalCallContext);
  const [clientDetails, setClientDetails] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenFollowUp(true);
    }, 3000000);
    // }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (openFollowUp && user?._id) {
      console.log('user?.role:', user?.role);
      if(user?.role == 'THERAPIST') {
        getClientDetails();
      }
    }
  }, [openFollowUp]);

  const getClientDetails = async () => {
    try {
      const res = await ClientService.getClientByClientId(localUserOnCall.recieversUserId);
      if (res && res.success && res.data && res.data._id && res.data.role === "CLIENT") {
        setClientDetails(res.data);
      } else {
        console.error('Invalid client response:', res);
        toast.error('Error getting client data');
      }
    } catch (error) {
      console.error('Error in getClientDetails:', error);
      toast.error('Failed to get client details');
    }
  };

  const handleClose = reason => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFollowUpClose = async (response) => {
    if (response === 'yes') {
      try {
        if (!user?._id || !clientDetails?._id) {
          toast.error('Missing user or client details');
          return;
        }

        // Use the initial time and round to nearest 30 minutes in the past
        const minutes = initialTime.minutes();
        const roundedMinutes = minutes >= 30 ? 30 : 0;
        const roundedDate = moment(initialTime).minutes(roundedMinutes).seconds(0).milliseconds(0);
        
        // Calculate next week's time
        const nextWeekDate = moment(roundedDate).add(1, 'week');
        const startTime = nextWeekDate;
        const endTime = moment(nextWeekDate).add(1, 'hour');

        const appointmentObj = {
          therapistId: user._id,
          clientId: clientDetails._id,
          title: "Lavni Therapy session",
          type: "Video - 1 Hour Session",
          start: startTime.toDate(),
          end: endTime.toDate(),
          reminders: [30],
          repeatInfo: {
            repeatType: "DOES NOT REPEAT",
            interval: "",
            repeatDays: {
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
            endingDate: Util.monthsNextFrom(startTime.toDate(), 1),
            endingAfter: 10,
            endingType: "",
          },
          color: "#FF6900",
          groupId: user._id + "_" + startTime.valueOf(),
          eventOnHolidays: false,
        };

        const res = await AppointmentService.createAppointmentByTherapist(appointmentObj);
        console.log({res});
        if (res?.success) {
          toast.success('Follow-up appointment scheduled successfully');
        } else {
          toast.error(res?.error || 'Failed to schedule follow-up appointment');
        }
      } catch (error) {
        console.error('Error creating follow-up appointment:', error);
        toast.error('Failed to schedule follow-up appointment');
      }
    } else {
      dispatch(setButtonVibrate(true));
    }
    setOpenFollowUp(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Snackbar
        className={classes.anchorOriginTopCenter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        ContentProps={{
          classes: {
            root: classes.root,
            action: classes.action
          }
        }}
        open={open}
        message={
          <div className={classes.snackBarContent}>
            Waiting for other party to join...
          </div>
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      
      {user.role == 'THERAPIST' &&
      <Snackbar
        // autoHideDuration={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={openFollowUp}
        onClose={() => setOpenFollowUp(false)}
      >
        <div className={classes.popupContent}>
          <div className={classes.modalText}>
            Schedule a follow-up appointment for the same time next week?
          </div>
          <div className={classes.buttonContainer}>
            <button
              className={`${classes.button} ${classes.confirmButton}`}
              onClick={() => handleFollowUpClose('yes')}
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${classes.cancelButton}`}
              onClick={() => handleFollowUpClose('no')}
            >
              Choose different time
            </button>
          </div>
        </div>
        </Snackbar>
      }
      

      {user.role == 'THERAPIST' && <CustomModal 
        open={openModal}
        handleClose={handleModalClose}
        clientId={localUserOnCall.recieversUserId}
        therapistId={user._id}
        role={user.role}
      />}

    </div>
  );
}

export default WaitingToast;