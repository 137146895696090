import React, { useContext, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, FormControlLabel, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, CircularProgress } from "@material-ui/core";
import * as VideoExpress from "@vonage/video-express";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import useStyles from "./styles";
import usePreviewPublisher from "../../hooks/usePreviewPublisher";
import AudioSettings from "../AudioSetting";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import VideoSettings from "../VideoSetting";
import DeviceAccessAlert from "../DeviceAccessAlert";
import { UserContextForVonage } from "../../context/UserContextForVonage";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DEVICE_ACCESS_STATUS } from "./../constants";
import VideoFilter from "../VideoFilter";
import VideoRoom from "../VideoRoom";
import { ThemeProvider } from "@material-ui/styles";
import CallInitializationContext from "src/context/CallInitializationContext";
import UserContext from "src/context/UserContext";
import { VideoChatService } from "src/services/VideoChatService";
import { VonageServices } from "../../../vonageServices";
import { SocketContext } from "src/context/ScoketContext";
import { toast } from "react-toastify";
import LocalCallTimeOutContext from "src/context/LocalCallTimeOutlContext";
import LocalCallContext from "src/context/LocalCallContext";
import { Role } from "src/models/Role";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import OngoingCallRefreshModal from "src/pages/Popup/OngoingCallRefreshModal";
import CheckBox from '@material-ui/icons/CheckBox';
import Error from '@material-ui/icons/Error';
import QualityTestDialog from '../QualityTestDialog';
import { useNetworkTest } from '../../hooks/useNetworkTest';
import OT from "@opentok/client";
import NetworkTest from "opentok-network-test-js";
import axios from "axios";
import { CopaymentService } from "../../../../../../services/CopaymentService";
import PaymentDialog from "src/pages/Popup/PaymentDialog";

const networkTestVideoTimeout = 25000; // To Edit to 30s

export default function WaitingRoom() {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useContext(UserContextForVonage);
  const waitingRoomVideoContainer = useRef();
  // const [roomName, setRoomName] = useState(roomToJoin);
  const [userName, setUserName] = useState("");
  const [isUserNameInvalid, setIsUserNameInvalid] = useState(false);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const defaultPublishVideo = localCallInitializeData.isAudioCall ? false : user.defaultSettings.publishVideo;
  const [localAudio, setLocalAudio] = useState(user.defaultSettings.publishAudio);
  const [localVideo, setLocalVideo] = useState(defaultPublishVideo);
  const [localVideoSource, setLocalVideoSource] = useState(undefined);
  const [localAudioSource, setLocalAudioSource] = useState(undefined);
  const [localAudioOutput, setLocalAudioOutput] = useState(undefined);
  /* const [devices, setDevices] = useState(null); */
  let [audioDevice, setAudioDevice] = useState("");
  let [videoDevice, setVideoDevice] = useState("");
  let [audioOutputDevice, setAudioOutputDevice] = useState("");
  // const [backgroundBlur, setBackgroundBlur] = useState(user.videoEffects.backgroundBlur);
  const [videoFilter, setVideoFilter] = useState({ filterName: "", filterPayload: "" });
  const { createPreview, destroyPreview, previewPublisher, logLevel, previewMediaCreated, deviceInfo, accessAllowed } = usePreviewPublisher();
  const [loggedUser] = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [callTimeOut, setCallTimeOut, setShowPopUp] = useContext(LocalCallTimeOutContext);
  const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { roomName } = useParams();
  const [allowTranscribe, setAllowTranscribe] = useState(true);
  const [showOngoingCallRefreshModal, setShowOngoingCallRefreshModal] = useState(false);
  const [showNetworkTestModal, setShowNetworkTestModal] = useState(false);
  const [networkTest, setNetworkTest] = useState(false);
  const [showQualityDialog, setShowQualityDialog] = useState(false);
  const [networkTestCredentials, setNetworkTestCredentials] = useState({
    apikey: "",
    sessionId: "",
    token: ""
  });
  const [enableNetworkTestBtn, setEnableNetworkTestBtn] = useState(true);

  // State cho copayment
  const [mentalHealthCopayment, setMentalHealthCopayment] = useState(0);
  const [copaymentLoading, setCopaymentLoading] = useState(false);
  const [copaymentError, setCopaymentError] = useState(false);
  const [copaymentAccepted, setCopaymentAccepted] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [statusResponse, setStatusResponse] = useState(null);

  // Thêm state cho hằng số trạng thái
  const PAYMENT_STATUS = {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    UNKNOWN: 'UNKNOWN'
  };

  // Network test parts.
  const otNetworkTest = useRef();
  const [runTest, setRunTest] = useState(true);
  const [connectivityTest, setConnectivityTest] = useState({
    data: null,
    loading: true,
  });
  const [qualityTest, setQualityTest] = useState({ data: null, loading: true });

  // Thêm states cho payment dialog
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  useEffect(() => {
    setUserName(loggedUser?.firstname);
    
    // Gọi API lấy thông tin copayment khi component mount nếu là client
    if (loggedUser?.role === Role.CLIENT) {
      fetchCopaymentData();
    }
  }, []);

  const handleVideoSource = React.useCallback(
    e => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      previewPublisher.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource]
  );

  const handleAudioSource = React.useCallback(
    e => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      previewPublisher.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource]
  );

  const handleAudioOutput = React.useCallback(
    e => {
      const audioOutputId = e.target.value;
      setAudioOutputDevice(audioOutputId);
      // await VideoExpress.setAudioOutputDevice(audioOutputId);
      setLocalAudioOutput(audioOutputId);
    },
    [setLocalAudioOutput, setAudioOutputDevice]
  );

  const [isJoinedTheVideoRoom, setIsJoinedTheVideoRoom] = useState(false);
  const handleJoinClick = async () => {
    console.log("WR 01");
    localStorage.setItem("username", userName);
    
    // Nếu là client và có copayment > 0, xử lý thanh toán trước
    if (loggedUser?.role === Role.CLIENT && mentalHealthCopayment > 0) {
      const paymentSuccessful = await processCopayment();
      if (!paymentSuccessful) {
        return; // Nếu thanh toán không thành công, dừng lại
      }
    }
    
    // Tiếp tục luồng tham gia cuộc gọi
    setIsButtonDisabled(true);
    proceedWithCall();
  };

  const proceedWithCall = async () => {
    if (localCallInitializeData.start) {
      console.log("********* Call started **********");
      const isAudioCall = localCallInitializeData.isAudioCall;
      const recieverId = localCallInitializeData.recieverId;
      const meetingTime = localCallInitializeData.meetingTime;
      const appointmentId = localCallInitializeData.appointmentId;
      const isAppointmentBased = localCallInitializeData.isAppointmentBased;
      initializeInstantCall(isAudioCall, recieverId, meetingTime, appointmentId, isAppointmentBased, allowTranscribe);
    } else {
      const res = await VonageServices.getMeetingDataUsingSessionName(roomName);
      console.log(res);
      if (res.success) {
        const meetingData = res.data;
        if (meetingData?.callingStatus == "STARTED") {
          console.log("********* Joined to call **********");
          if (meetingData?.isAppointmentBased) {
            JoinVonageCall(meetingData?._id, meetingData?.isAppointmentBased, meetingData?.appointmentId, allowTranscribe);
          } else {
            JoinVonageCall(meetingData?._id, meetingData?.isAppointmentBased, "ddddddd", allowTranscribe);
          }
        } else {
          console.log("********* Joined after refreshed **********");
          joinedMeetingAfterRefreshed(meetingData?._id, meetingData?.isAudioCall, meetingData?.meetingDuration, loggedUser?.role == Role.CLIENT ? meetingData?.therapistId : meetingData?.clientId);
        }
      } else {
        toast.error(`Call Initialization Error Occured. ${res.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        })
      }
    }
  };

  const joinedMeetingAfterRefreshed = (meetingIdFromResponseInitial, isAudioCall, meetingDuration, clientId) => {
    console.log("jc 04");
    const dataForCall = {
      callId: meetingIdFromResponseInitial,
      onOngoingCall: true,
      recieversUseDefaultAvatarValue: "",
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: "",
      // meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
      meetingMainTherapistId: "",
      isVideoCall: !isAudioCall,
      meetingDuration: meetingDuration,
      recieversUserId: clientId,
      recieversName: "test",
      recieverCallRecordingAllowed: true,
      recieversSocketId: "test",
      callEndedForBothUsers: false,
      isAudioCall: isAudioCall,
    };
    setLocalCallInitialize(true);
    console.log("jc 05");
    console.log("VWC 03");
    setLocalUserOnCall(true, dataForCall, false, false);
    history.push(`/video-room/${roomName}`);
  };

  const JoinVonageCall = async (meetingId, isAppointmentBased, appointmentId, transcribeAllowedForLoggedUser) => {
    try {
      //!Add optional api call to here.
      console.log("VWC 02");
      const res = await VideoChatService.joinZoomMeeting(meetingId, isAppointmentBased, appointmentId, transcribeAllowedForLoggedUser);

      console.log("jc 01");
      const isVideoCall = true;
      console.log(res);
      console.log("jc 02");
      if (res.success) {
        console.log("jc 03");
        const remainingTime = res.data.meetingData.remainingMeetingTimeForCurrentMeeting;
        const userId = res.data.recieverData.userId;
        const meetingIdFromResponseInitial = res.data.meetingData.meetingId;
        const isAudio = res.data.meetingData?.isAudioCall;

        console.log("jc 04");
        const dataForCall = {
          callId: meetingIdFromResponseInitial,
          onOngoingCall: true,
          recieversUseDefaultAvatarValue: res.data.recieverData.useDefaultAvatar,
          recieversAvatarId: res.data.recieverData.avatarId,
          recieversAvatarBackgroundId: res.data.recieverData.avatarBackgroundId,
          recieversIncogniteModeOn: res.data.recieverData.incognito,
          // meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
          meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
          isVideoCall: isVideoCall,
          meetingDuration: remainingTime,
          recieversUserId: userId,
          recieversName: res.data.recieverData.callerName,
          recieverCallRecordingAllowed: res.data.recieverData.callRecordingAllowed,
          recieversSocketId: res.data.recieverData.socketId,
          callEndedForBothUsers: false,
          isAudioCall: isAudio,
        };
        setLocalCallInitialize(true);
        console.log("jc 05");
        console.log("VWC 03");
        setLocalUserOnCall(true, dataForCall, false, false);
        history.push(`/video-room/${roomName}`);
      } else {
        toast.error(`Call Initialization Error Occured. ${res.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      console.log(error);

      toast.error("Start Call Error Occured B", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      resetContextData();
    }
  };

  const initializeInstantCall = async (isAudio, recieverId, meetingTime, appointmentId, isAppointmentBased, transcribeAllowedForUser) => {
    try {
      console.log("WR 03");
      const initializeResponse = await VonageServices.initializeVonageCall(
        recieverId,
        appointmentId,
        meetingTime,
        true,
        isAppointmentBased,
        isAudio,
        transcribeAllowedForUser
      );
      if (initializeResponse.success) {
        const meetingIdFromResponseInitial = initializeResponse.data.meetingId;

        if (initializeResponse.data.alreadyStarted == false) {
          startInstantCall(roomName, meetingIdFromResponseInitial, recieverId, appointmentId, meetingTime, isAppointmentBased, isAudio);
        } else {
          toast.error("Call Already Started", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          resetContextData();
        }
      } else {
        console.log(initializeResponse)
        toast.error(initializeResponse.error, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Call Initialization Error Occured.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      resetContextData();
    }
  };

  const resetContextData = () => {
    const timeoutData = {
      isTimeOutRunning: true,
      userOnMeetingScreen: false,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };

    setCallTimeOut(timeoutData);

    const callInitializeDataForReset = {
      start: false,
      isInstantMeeting: false,
      isAppointmentBased: false,
      isTranscribeAllowed: false,
      appointmentId: "",
      recieverId: "",
      meetingTime: 30,
      meetingId: "",
      isAudioCall: false,
    };

    setLocalCallInitialize(false);
    setLocalCallInitializeData(callInitializeDataForReset);

    const resetDataForCall = {
      callId: "",
      onOngoingCall: false,
      recieversUseDefaultAvatarValue: true,
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: true,
      meetingMainTherapistId: "",
      isVideoCall: false,
      meetingDuration: 30,
      recieversUserId: "",
      recieversName: "",
      recieverCallRecordingAllowed: false,
      recieversSocketId: "",
      callEndedForBothUsers: false,
      videoSdkToken: "",
      isAudioCall: false,
    };
    setLocalUserOnCall(false, resetDataForCall, true, false);
  };

  const startInstantCall = async (sessionId, meetingId, recieverId, appointmentId, meetingTime, isAppointmentBased, isAudioCall) => {
    try {
      console.log("WR 04");
      const res = await VonageServices.startVonageMeeting(sessionId, meetingId, recieverId, appointmentId, meetingTime, true, isAppointmentBased);
      if (res.success) {
        const userId = recieverId;

        const dataForCall = {
          callId: meetingId,
          onOngoingCall: true,
          recieversUseDefaultAvatarValue: res.data.recieverData.useDefaultAvatar,
          recieversAvatarId: res.data.recieverData.avatarId,
          recieversAvatarBackgroundId: res.data.recieverData.avatarBackgroundId,
          recieversIncogniteModeOn: res.data.recieverData.incognito,
          meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
          isVideoCall: !isAudioCall,
          meetingDuration: meetingTime,
          recieversUserId: userId,
          recieversName: res.data.recieverData.callerName,
          recieverCallRecordingAllowed: res.data.recieverData.callRecordingAllowed,
          recieversSocketId: res.data.recieverData.socketId,
          callEndedForBothUsers: false,
          isAudioCall: res.data.meetingData?.isAudioCall,
        };

        setLocalUserOnCall(true, dataForCall, false, true);
        // setOutGoinCallTimeOutFunction();

        const data = {
          socketId: res.data.recieverData.socketId,
          senderSocketId: socket.id,
          callId: meetingId,
          myUserId: loggedUser?._id,
          therapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id,
          meetingDuration: meetingTime,
          useDefaultAvatar: res.data.ownData.useDefaultAvatar,
          avatarId: res.data.ownData.avatarId,
          avatarBackgroundId: res.data.ownData.avatarBackgroundId,
          incognito: loggedUser?.incognito,
          callerName: loggedUser?.firstname,
          isVideoCall: !isAudioCall,
          recieverRcordingAllowed: loggedUser?.callRecordingAllowed,
          receiverId: userId,
          senderId: loggedUser?._id,
          vonageSessionId: sessionId,
        };
        const localCallInitializeDataa = {
          start: true,
          isInstantMeeting: true,
          isAppointmentBased: false,
          isTranscribeAllowed: false,
          appointmentId: "ddddddd",
          recieverId: recieverId,
          meetingTime: meetingTime,
          meetingId,
          isAudioCall: isAudioCall,
          vonageSessionId: sessionId,
        };
        // history.push("/room")
        setLocalCallInitialize(true);
        setLocalCallInitializeData(localCallInitializeDataa);
        socket.emit("vonage-call-user", data);
        console.log("WR 05");
        history.push(`/video-room/${sessionId}`);
      } else {
        toast.error(res.errorCode == 456 ? "Sorry! This client doesn't have a valid subscription plan." : "Start Call Error Occured A", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      console.log(error);

      toast.error(res.errorCode == 456 ? "Sorry! This client doesn't have a valid subscription plan." : "Start Call Error Occured A", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      resetContextData();
    }
  };

  const handleAudioChange = React.useCallback(e => {
    setLocalAudio(e.target.checked);
  }, []);

  const handleVideoChange = React.useCallback(e => {
    setLocalVideo(e.target.checked);
  }, []);

  const handleChangeVideoFilter = React.useCallback(
    async (filter, filterPayload) => {
      if (previewPublisher && filter) {
        switch (filter) {
          case "reset":
            await previewPublisher.clearVideoFilter();
            setVideoFilter({ filterName: "", filterPayload: "" });
            break;
          case "blur":
            await previewPublisher.setVideoFilter({ type: "backgroundBlur", blurStrength: filterPayload });
            setVideoFilter({ filterName: filter, filterPayload });
            break;
          case "backgroundImage":
            await previewPublisher.setVideoFilter({ type: "backgroundReplacement", backgroundImgUrl: filterPayload });
            setVideoFilter({ filterName: filter, filterPayload });
            break;
          default:
          // do nothing
        }
      }
    },
    [previewPublisher]
  );

  const redirectHttps = React.useCallback(() => {
    const url = window.location.href;
    if (url.toString().indexOf("http://") === 0 && url.toString().indexOf("http://localhost") !== 0) {
      window.location.href = window.location.href.toString().replace("http://", "https://");
    } else {
      return;
    }
  }, []);

  const onChangeParticipantName = e => {
    const userName = e.target.value;
    if (userName === "" || userName.trim() === "") {
      // Space detected
      setUserName("");
      return;
    }
    setIsUserNameInvalid(false);
    setUserName(userName);
  };

  useEffect(() => {
    if (
      localAudio !== user.defaultSettings.publishAudio ||
      localVideo !== user.defaultSettings.publishVideo ||
      localAudioSource !== user.defaultSettings.audioSource ||
      localVideoSource !== user.defaultSettings.videoSource ||
      videoFilter.filterName !== user.videoFilter.filterName ||
      videoFilter.filterPayload !== user.videoFilter.filterPayload ||
      localAudioOutput !== user.defaultSettings.audioOutput
    ) {
      setUser({
        ...user,
        videoFilter: {
          filterName: videoFilter.filterName,
          filterPayload: videoFilter.filterPayload,
        },
        defaultSettings: {
          publishAudio: localAudio,
          publishVideo: localVideo,
          audioSource: localAudioSource,
          videoSource: localVideoSource,
          audioOutput: localAudioOutput,
        },
      });
    }
  }, [localAudio, localVideo, user, setUser, localAudioSource, localVideoSource, videoFilter, localAudioOutput]);

  useEffect(() => {
    if (userName !== user.userName) {
      setUser({ ...user, userName: userName });
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher.getAudioDevice().then(currentAudioDevice => {
        setAudioDevice(currentAudioDevice.deviceId);
      });
      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);

      VideoExpress.getActiveAudioOutputDevice().then(currentAudioOutputDevice => {
        setAudioOutputDevice(currentAudioOutputDevice.deviceId);
      });
    }
  }, [deviceInfo, previewPublisher, setAudioDevice, setVideoDevice, previewMediaCreated, setAudioOutputDevice]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }

    // return () => {
    //   // stopEffect();
    //   destroyPreview();
    // };
  }, [createPreview, destroyPreview]);

  useEffect(() => {
    setShowOngoingCallRefreshModal(localCallInitialize);
  }, [localCallInitialize]);

  useEffect(() => {
    redirectHttps();
    if (localStorage.getItem("username")) {
      setUserName(localStorage.getItem("username"));
    }
  }, [redirectHttps]);

  const setShowOngoingCallRefreshModalFunc = showModal => {
    setShowOngoingCallRefreshModal(showModal);
  };

  useEffect(() => {
    if (!qualityTest.loading) {
      setShowQualityDialog(true);
    }
  }, [qualityTest]);

  const stopNetworkTest = useCallback(() => {
    if (otNetworkTest.current && !runTest) {
      otNetworkTest.current.stop();
      otNetworkTest.current = null;
      setRunTest(true);
    }
  }, [otNetworkTest, runTest]);

  const runNetworkTest = useCallback(() => {
    try {
      setConnectivityTest((state) => ({ data: state.data, loading: true }));
      setQualityTest((state) => ({ data: state.data, loading: true }));
      if (otNetworkTest.current && runTest) {
        setRunTest(false);
        otNetworkTest.current
          .testConnectivity()
          .then((results) => {
            if (otNetworkTest.current) {
              setConnectivityTest({ loading: false, data: results });
            }
            if (otNetworkTest.current) {
              otNetworkTest.current
                .testQuality(function updateCallback(stats) {
                  console.log("intermediate testQuality stats", stats);
                })
                .then((results) => {
                  // This function is called when the quality test is completed.
                  console.log("OpenTok quality results", results);
                  if (otNetworkTest.current) {
                    setQualityTest((state) => ({
                      data: results,
                      loading: false,
                    }));
                    setRunTest(true);
                  }
                })
                .catch((error) => {
                  console.log("OpenTok quality test error", error);
                  setQualityTest({
                    data: null,
                    loading: false
                  })
                });
            }
          })
          .catch(function (error) {
            console.log("OpenTok connectivity test error", error);
          });
      }
    } catch (error) {
      console.log(error);
    }

  }, [otNetworkTest, runTest]);

  const toggleNetworkTest = async () => {
    if (networkTest) {
      stopNetworkTest();
      setNetworkTest(false);
    } else {
      try {
        const data = await VonageServices.generateSessionForNetworkTest()
        if (data.success) {
          const obj = {
            apikey: data?.data?.apiKey,
            sessionId: data?.data?.sessionId,
            token: data?.data?.token
          }
          setNetworkTestCredentials(obj);
          otNetworkTest.current = new NetworkTest(
            OT,
            {
              apiKey: data?.data?.apiKey,
              sessionId: data?.data?.sessionId,
              token: data?.data?.token,
            },
            { timeout: networkTestVideoTimeout }
          );
          runNetworkTest();
          setNetworkTest(true);
        } else {
          setEnableNetworkTestBtn(false);
        }
      } catch (error) {
        console.log(error);
        setEnableNetworkTestBtn(false);
      }

    }
  };

  const handleQualityTestDialogClose = () => {
    console.log('handleQualityTestDialogClose', networkTest);
    setShowQualityDialog(false);
    setNetworkTest(false);
  };

  // Hàm để gọi API copayment
  const fetchCopaymentData = async () => {
    if (!loggedUser || !loggedUser._id || !roomName) return;
    
    // Don't call API again if already loading
    if (copaymentLoading) return;
    
    setCopaymentLoading(true);
    setCopaymentError(false);
    
    try {
      // Kiểm tra trạng thái thanh toán trước
      const response = await CopaymentService.getMeetingCopaymentStatus(roomName);
      console.log("Payment status response:", response);
      
      // Nếu đã thanh toán (status === 'PAID'), bỏ qua việc lấy thông tin copayment
      if (response?.data?.copayment?.status === PAYMENT_STATUS.PAID) {
        // Đã thanh toán, đánh dấu là đã chấp nhận để hiển thị nút Join Call
        setCopaymentAccepted(true);
        setMentalHealthCopayment(0); // Đặt giá trị = 0 để không hiển thị thông báo
        setCopaymentLoading(false);
        return;
      }
      
      // Nếu chưa thanh toán, tiếp tục lấy thông tin copayment
      const copaymentResponse = await CopaymentService.getClientCopayment(loggedUser._id);
      
      // Check if response has the expected copayment data structure
      if (copaymentResponse.data && copaymentResponse.data.copayment && Array.isArray(copaymentResponse.data.copayment)) {
        // Find Mental Health copayment - add more robust error handling
        const mentalHealth = copaymentResponse.data.copayment.find(
          item => item.Code && item.Code.trim() === "MH"
        );
        console.log("mentalHealth", mentalHealth);
        
        if (mentalHealth) {
          // Nếu tìm thấy code MH, sử dụng giá trị này
          const amount = parseInt(mentalHealth.amount || "0", 10);
          setMentalHealthCopayment(amount);
        } else {
          // Nếu không tìm thấy MH, tìm code "98"
          const altCopayment = copaymentResponse.data.copayment.find(
            item => item.Code && item.Code.trim() === "98"
          );
          console.log("altCopayment (98)", altCopayment);
          
          if (altCopayment) {
            // Nếu tìm thấy code 98, sử dụng giá trị này
            const amount = parseInt(altCopayment.amount || "0", 10);
            setMentalHealthCopayment(amount);
          } else {
            // Nếu không tìm thấy cả MH và 98, đặt copayment = 0
            console.warn("Mental Health copayment not found in data (neither MH nor 98)");
            setMentalHealthCopayment(0);
            // Không đặt lỗi vì đây là trường hợp hợp lệ theo yêu cầu mới
            // setCopaymentError(false);
          }
        }
      } else {
        console.error("Invalid copayment data structure:", copaymentResponse.data);
        setCopaymentError(true);
        toast.error("Unable to retrieve copayment information", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error fetching copayment:", error);
      setCopaymentError(true);
      toast.error("Server connection error. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    } finally {
      setCopaymentLoading(false);
    }
  };

  // Tìm hàm processCopayment
  const processCopayment = async () => {
    if (!loggedUser || !loggedUser._id) return false;
    
    setIsProcessingPayment(true);
    
    try {
      // Sử dụng PaymentService thay vì gọi API trực tiếp
      const response = await CopaymentService.processClientCopayment(
        loggedUser._id,
        mentalHealthCopayment,
        roomName
      );
      
      // Điều kiện kiểm tra mới - kiểm tra response.success thay vì response.data.success
      if (response && response.success) {
        toast.success(response.message || "Payment processed successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        return true;
      } else {
        const errorMessage = response.error || response.message || response.data?.message || "Payment processing failed";
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        // Hiển thị popup thanh toán khi có lỗi
        setPaymentError(errorMessage);
        setShowPaymentDialog(true);
        return false;
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      const errorMessage = error.response?.data?.message || "Payment processing failed. Please try again.";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      // Hiển thị popup thanh toán khi có lỗi
      setPaymentError(errorMessage);
      setShowPaymentDialog(true);
      return false;
    } finally {
      setIsProcessingPayment(false);
    }
  };

  // Thêm handlers cho payment dialog
  const handlePaymentSuccess = async () => {
    setShowPaymentDialog(false);
    const paymentSuccessful = await processCopayment();
    if (paymentSuccessful) {
      proceedWithCall();
    }
  };

  const handlePaymentSkip = () => {
    setShowPaymentDialog(false);
  }

  // Thêm handler debug để in ra log khi checkbox thay đổi
  const handleCopaymentCheckboxChange = (event) => {
    setCopaymentAccepted(!copaymentAccepted);
  };

  return (
    <>
      {showOngoingCallRefreshModal && <OngoingCallRefreshModal showModal={showOngoingCallRefreshModal} setShowModal={setShowOngoingCallRefreshModalFunc} />}
      
      <PaymentDialog
        open={showPaymentDialog}
        onClose={handlePaymentSkip}
        onSuccess={handlePaymentSuccess}
        amount={mentalHealthCopayment}
        error={paymentError}
      />

      <div className={classes.waitingRoomContainer} style={{ width: "auto" }}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="publisher-name"
              label="Name"
              name="name"
              error={isUserNameInvalid}
              required
              autoComplete="Name"
              helperText={userName === "" ? "Empty Field" : " "}
              value={userName}
              onChange={onChangeParticipantName}
            />
            <div className={classes.mediaSources}>
              {deviceInfo && previewMediaCreated && (
                <>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select Audio Source</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={audioDevice} onChange={handleAudioSource}>
                      {deviceInfo.audioInputDevices.map(device => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* <FormControl>
                      <InputLabel id="video">Select Audio Output</InputLabel>
                      {deviceInfo.audioOutputDevices && (
                        <Select labelId="video" id="demo-simple-select" value={audioOutputDevice} onChange={handleAudioOutput}>
                          {deviceInfo.audioOutputDevices.map((device) => (
                            <MenuItem key={device.deviceId} value={device.deviceId}>
                              {device.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl> */}
                </>
              )}

              {deviceInfo && previewMediaCreated && (
                <FormControl>
                  <InputLabel id="video">Select Video Source</InputLabel>
                  {deviceInfo.videoInputDevices && (
                    <Select labelId="video" id="demo-simple-select" value={videoDevice} onChange={handleVideoSource}>
                      {deviceInfo.videoInputDevices.map(device => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            </div>
          </form>
          <div id="waiting-room-video-container" className={classes.waitingRoomVideoPreview} ref={waitingRoomVideoContainer}></div>
          <div className={classes.deviceContainer}>
            {/* <AudioSettings className={classes.deviceSettings} hasAudio={localAudio} onAudioChange={handleAudioChange} /> */}
            <LinearProgress variant="determinate" value={logLevel} />
            {/* <VideoSettings className={classes.deviceSettings} hasVideo={localVideo} onVideoChange={handleVideoChange} /> */}
          </div>
          <VideoFilter handleChangeVideoFilter={handleChangeVideoFilter} />
          <div className="d-flex justify-content-between flexColumn mt-3">
            <div className="d-flex">
              <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
                Allow Call Transcript
              </h5>

              <div className="form-check form-switch form-switch-lg">
                <label className="form-check-label" htmlFor="newsletter">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="newsletter"
                    checked={allowTranscribe}
                    onChange={e => { }}
                    onClick={() => {
                      setAllowTranscribe(!allowTranscribe);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        </Grid>
        
        {/* Display copayment information for client */}
        {loggedUser?.role === Role.CLIENT && mentalHealthCopayment > 0 && (
          <Grid container direction="column" alignItems="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
            {copaymentLoading ? (
              <div className="d-flex align-items-center">
                <div className="spinner-border spinner-border-sm mr-2" role="status"></div>
                <span>Loading copayment information...</span>
              </div>
            ) : copaymentError ? (
              <div className="text-danger">Copayment information not found</div>
            ) : (
              // Use plain HTML/CSS for checkbox instead of Material UI
              <div style={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%', 
                maxWidth: '400px', 
                margin: '10px 0',
                cursor: 'pointer',
                padding: '8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#f9f9f9'
              }} 
              onClick={handleCopaymentCheckboxChange}
              >
                <input
                  type="checkbox"
                  id="native-copay-checkbox"
                  checked={copaymentAccepted}
                  onChange={handleCopaymentCheckboxChange}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '12px',
                    cursor: 'pointer'
                  }}
                />
                <span className="text-danger font-weight-bold">
                  This session requires a co-payment of ${mentalHealthCopayment}
                </span>
              </div>
            )}
          </Grid>
        )}
        
        <Grid container direction="column" justifyContent="center" alignItems="center">
          {/* Different button variants based on copayment status */}
          {loggedUser?.role === Role.CLIENT ? (
            <>
              {copaymentError ? (
                // No button if copayment not found
                null
              ) : copaymentLoading ? (
                // Loading button if fetching copayment
                <Button variant="contained" className={classes.joinBtn} color="primary" disabled>
                  Loading...
                </Button>
              // ) : mentalHealthCopayment > 0 && !copaymentAccepted ? (
              ) : mentalHealthCopayment > 0? (
                // Pay and Join button if copayment > 0 and not accepted
                <Button
                  variant="contained"
                  className={classes.joinBtn}
                  color="primary"
                  onClick={handleJoinClick}
                  disabled={isButtonDisabled || !userName || !copaymentAccepted || isProcessingPayment}
                  style={{ backgroundColor: '#e57834' }}
                >
                  {isProcessingPayment ? 'Processing Payment...' : 'Pay and Join Now'}
                </Button>
              ) : (
                // Normal Join button if copayment = 0 or already accepted
                <Button
                  variant="contained"
                  className={classes.joinBtn}
                  color="primary"
                  onClick={handleJoinClick}
                  disabled={isButtonDisabled || !userName}
                >
                  Join Call
                </Button>
              )}
            </>
          ) : (
            // Normal Join button for therapist
            <>
              <Button
                variant="contained"
                className={classes.joinBtn}
                color="primary"
                onClick={handleJoinClick}
                disabled={isButtonDisabled || !userName}
              >
                Join Call
              </Button>
            </>
          )}
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
          <Button variant="contained" className={classes.networkTestBtn} color="primary" onClick={toggleNetworkTest} disabled={!enableNetworkTestBtn}>
            {networkTest ? 'Stop Network Test' : 'Start Network Test'}
          </Button>
          {networkTest && (
            <div className={classes.networkTestContainer}>
              <div className={classes.flex}>
                <div>Connectivity Test:</div>
                <div>
                  {connectivityTest.loading ? (
                    <CircularProgress size={20} />
                  ) : connectivityTest.data && connectivityTest.data.success ? (
                    <CheckBox className={classes.green}></CheckBox>
                  ) : (
                    <Error className={classes.red} />
                  )}
                </div>
              </div>
              <div className={classes.flex}>
                <div>Quality Test:</div>
                <div>
                  {qualityTest.loading ? (
                    <CircularProgress size={20} />
                  ) : qualityTest.data ? (
                    <CheckBox className={classes.green}></CheckBox>
                  ) : (
                    <Error className={classes.red} />
                  )}
                </div>
              </div>
              {qualityTest.data && (
                <QualityTestDialog
                  selectedValue={qualityTest}
                  open={showQualityDialog}
                  onClose={handleQualityTestDialogClose}
                ></QualityTestDialog>
              )}

            </div>
          )}
        </Grid>
      </div>
      {accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED && <DeviceAccessAlert accessStatus={accessAllowed}></DeviceAccessAlert>}
    </>
  );
}
