import moment from "moment";
import Linkify from "react-linkify";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import parse from "html-react-parser";
import { GroupChatMessageModel } from "src/models/GroupChat/GroupChatMessage";
import { decryptGroupChatMessage } from "./GroupChatEncryption";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "src/context/UserContext";
import { Util } from "src/Util";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import images from "src/assets/images";
import { Link, useHistory } from "react-router-dom";
import GroupChatContext from "src/context/GroupChatContext";
import { SocketContext } from "src/context/ScoketContext";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  groupFrom: GroupChatModel;
  allChatGroups: GroupChatModel[];
  setAllChatGroups: (newGroups: GroupChatModel[]) => void;
  scrollToTop: () => void;
  // openReplyPanel: (messageDetails: MessageModel) => void;
  // handleCopy: (text: string) => void;
  // deleteMessage: (messageId: string) => Promise<void>;
}

// message, currentUser, openReplyPanel, handleCopy, deleteMessage

const GroupChatCardCom = ({ groupFrom, allChatGroups, setAllChatGroups, scrollToTop }: Props) => {
  const history = useHistory();
  const socket = useContext(SocketContext);
  const [user, setUser] = useContext(UserContext);
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);

  const [newSocketRecieveMsg, setNewSocketRecieveMsg] = useState<any>();
  const [deletedMessageId, setDeletedMessageId] = useState<string>("");

  const [group, setGroup] = useState<GroupChatModel>(groupFrom);
  const [latestMessage, setLatestMessage] = useState<GroupChatMessageModel | undefined>(groupFrom.latestMessage);

  useEffect(() => {
    setGroup(groupFrom);
  }, [groupFrom]);

  useEffect(() => {
    try {
      if (
        newSocketRecieveMsg &&
        newSocketRecieveMsg.groupId &&
        newSocketRecieveMsg.groupId == group._id &&
        newSocketRecieveMsg._id &&
        newSocketRecieveMsg.firstname != null &&
        newSocketRecieveMsg.lastname != null &&
        newSocketRecieveMsg.messageText != null &&
        newSocketRecieveMsg.createdBy &&
        newSocketRecieveMsg.createdAt
      ) {
        const newMessage: GroupChatMessageModel = {
          _id: newSocketRecieveMsg._id,
          firstname: newSocketRecieveMsg.firstname,
          lastname: newSocketRecieveMsg.lastname,
          messageText: newSocketRecieveMsg.messageText,
          createdBy: newSocketRecieveMsg.createdBy,
          createdAt: newSocketRecieveMsg.createdAt,
          messageStatus: newSocketRecieveMsg.messageStatus,
          mediaFileId: newSocketRecieveMsg.mediaFileId,
        };

        setAllChatGroups([
          ...[
            {
              ...group,
              unreadMessageCount: selectedGroupData._id == newSocketRecieveMsg.groupId ? 0 : (group.unreadMessageCount ?? 0) + 1,
              lastActive: moment().toString(),
              latestMessage: newMessage,
            },
          ],
          ...allChatGroups.filter((item: any) => item._id != group._id),
        ]);

        setLatestMessage(newMessage);

        if (selectedGroupData._id == newSocketRecieveMsg.groupId) {
          scrollToTop();
        }
      }
    } catch (error) {}
  }, [newSocketRecieveMsg]);

  useEffect(() => {
    if (
      group._id != null &&
      group._id != "" &&
      group.latestMessage != null &&
      group.latestMessage._id != null &&
      group.latestMessage._id != "" &&
      deletedMessageId != null &&
      deletedMessageId != "" &&
      deletedMessageId == group.latestMessage._id
    ) {
      const prevLatestMessage: GroupChatMessageModel = {
        ...group.latestMessage,
        messageText: "",
        messageStatus: "DELETED",
        preMessageId: undefined,
        mediaFileId: undefined,
      };
      setGroup({ ...group, latestMessage: prevLatestMessage });
    }
  }, [deletedMessageId]);

  useEffect(() => {
    try {
      socket.emit("join-group-chat-room", group._id);

      socket.on(`new-group-chat-message-${group._id}`, message => {
        setNewSocketRecieveMsg(message);
      });

      socket.on("connect", () => {
        socket.emit("join-group-chat-room", group._id);
      });

      return () => {
        socket.off(`new-group-chat-message-${group._id}`);
        socket.off("connect");
      };
    } catch (error) {}
  }, [socket, selectedGroupData]);

  useEffect(() => {
    try {
      if (latestMessage != null && latestMessage._id != null && latestMessage._id != "") {
        const messageId = latestMessage._id;
        socket.once(`group-chat-message-delete-in-${messageId}`, message => {
          setDeletedMessageId(message);
        });

        return () => {
          socket.off(`group-chat-message-delete-in-${messageId}`);
        };
      }
    } catch (error) {}
  }, [latestMessage]);

  const onGroupClick = async (groupId: string) => {
    try {
      const groupIdFrom = groupId;

      if (selectedGroupData._id != groupIdFrom) {
        setSelectedGroupIdForShowRequests("");
        setSelectedGroupIdForShowMembers("");
        setSelectedGroupData(group);
        history.push(`/group-chat/${groupIdFrom}`);

        setGroup({ ...group, unreadMessageCount: 0, lastActive: moment().toString() });

        const dataObject = {
          groupId: groupIdFrom,
        };
        const res = await GroupChatService.updateLastActiveOfMember(dataObject);
        if (res.success) {
        } else {
          toast.error("Last active update failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      toast.error("Last active update failed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function formatDate(dateString: string): string {
    try {
      const date = moment(dateString);
      const today = moment();
      const yesterday = moment().subtract(1, "days");

      const isToday = date.isSame(today, "day");
      const isYesterday = date.isSame(yesterday, "day");

      if (isToday) {
        return date.format("HH:mm");
      } else if (isYesterday) {
        return "Yesterday";
      } else {
        return date.format("DD/MM/YYYY");
      }
    } catch (error) {
      return "";
    }
  }

  return (
    <li
      className={
        selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "" && group._id != null && selectedGroupData._id == group._id
          ? "active active1"
          : ""
      }
    >
      <Link
        to="#"
        onClick={() => {
          // if (selectedGroupData._id != group._id) {
          //   setSelectedGroupIdForShowRequests("");
          //   setSelectedGroupIdForShowMembers("");
          //   setSelectedGroupData(group);
          //   history.push(`/group-chat/${group._id}`);
          // }
          onGroupClick(group._id);
        }}
      >
        <div className="w-100 d-flex">
          {group != null && group.groupIcon != null && group.groupIcon._id != null ? (
            <div className="align-self-center me-3">
              <img src={Util.fileURL(group.groupIcon._id)} className="rounded-circle avatar-sm imageFit" />
            </div>
          ) : (
            <div className="align-self-center me-3">
              <img src={images.defaultGroupIcon} className="rounded-circle avatar-sm imageFit" />
            </div>
          )}
          <div style={{ width: "60px" }} className="flex-grow-1 overflow-hidden">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h5 style={{ width: "120px" }} className="text-truncate font-size-14 mb-0 me-2">{`${group?.title != null ? group?.title : ""}`}</h5>
              {group.latestMessage != null &&
                group.latestMessage._id != null &&
                group.latestMessage._id != "" &&
                group.latestMessage.createdAt != null &&
                group.latestMessage.createdAt != "" && <p className="text-truncate mb-0 ">{formatDate(group.latestMessage.createdAt)}</p>}
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center mt-1">
              <p className={"text-truncate mb-0 me-2"}>
                {group.latestMessage != null && group.latestMessage._id != null && group.latestMessage._id != ""
                  ? `${
                      group.latestMessage.createdBy != null && group.latestMessage.createdBy == user?._id ? "You" : group.latestMessage.firstname ?? "Member"
                    }: ${
                      group.latestMessage.messageStatus != null && group.latestMessage.messageStatus == "DELETED"
                        ? "deleted this message"
                        : group.latestMessage.messageText != null
                        ? decryptGroupChatMessage(group.latestMessage.messageText) != ""
                          ? Util.htmlToTextFunction(decryptGroupChatMessage(group.latestMessage.messageText))
                          : group.latestMessage.mediaFileId != null
                          ? "sent attachment"
                          : "empty message"
                        : "latest message"
                    }`
                  : "No messages yet"}
              </p>
              {group.unreadMessageCount != null && group.unreadMessageCount != 0 && (
                <div className="chat-message-count">
                  <span className="badge bg-danger rounded-pill group-chat-notify-dot d-flex justify-content-center align-items-center">
                    {group.unreadMessageCount}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default GroupChatCardCom;
