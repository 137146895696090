import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Card, Row, Col, CardBody } from "reactstrap"
import UserContext from "../../../context/UserContext";
import user1 from "../../../assets/images/default_profile.png";
import { Util } from "../../../Util";
import SoonTag from "../../../common/tags/Soon";

interface WelcomeCompProps {
  subscriptionsCount: any,
  therapistRevenueCount: any
}

const AdminWelcome: React.FC<WelcomeCompProps> = ({subscriptionsCount, therapistRevenueCount}) => {
    const [user, setUser] = useContext(UserContext);
    
    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="12">
                <div className="text-primary p-3">
                {/* <SoonTag text={"Soon"} /> */}
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p className="radio-btn-text">Lavni Admin Dashboard</p>
                </div>
              </Col>
              {/* <Col xs="5" className="align-self-end">
                <img src={''} className="img-fluid" />
              </Col> */}
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="12">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={(user && user.photoId?._id) ? Util.fileURL(user.photoId?._id) : user1}
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                {
                  (user && user.role != "SUPER_ADMIN") || (user && user.role == "SUB_ADMIN") && 
                  <h5 className="font-size-15 text-truncate">{user && user.firstname} {user && user.lastname}</h5>
                }
                
                <p className="text-muted mb-0 text-truncate">{user && user.role == "SUPER_ADMIN"? "SUPER ADMIN":  user && user.role == "SUB_ADMIN" ? "SUB ADMIN" : user && user.role}</p>
             
              </Col>
  
              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      {/* <h5 className="font-size-15">{subscriptionsCount}</h5> */}
                      {/* <p className="text-muted mb-0">Total Subscriptions</p> */}
                    </Col>
                    <Col xs="6">
                      {/* <h5 className="font-size-15">${therapistRevenueCount}</h5>
                      <p className="text-muted mb-0">Total Earnings</p> */}
                    </Col>
                  </Row>
                  {/* <div className="mt-4">
                    <Link
                      to=""
                      className="btn btn-primary  btn-sm"
                    >
                      View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
  export default AdminWelcome