import React, { useEffect } from "react";
import { Input } from "reactstrap";
import "../../src/assets/css/time_picker.css";
import moment from "moment";

const CustomTimePicker2: React.FC<{
  timeValue: string;
  setTimeValue: (value: string) => void;
}> = props => {
  const [hourDataValue, setHourDataValue] = React.useState("00");
  const [minuteDataValue, setMinuteDataValue] = React.useState("00");
  const [meridiemDataValue, setMeridiemDataValue] = React.useState("AM");

  useEffect(() => {
    if (props.timeValue) {
      setHourDataValue(moment(props.timeValue, "hh:mm A").format("hh"));
      setMinuteDataValue(moment(props.timeValue, "hh:mm A").format("mm"));
      setMeridiemDataValue(moment(props.timeValue, "hh:mm A").format("A"));
    }
  }, [props.timeValue]);

  return (
    <React.Fragment>
      <div className="custom_picker">
        <Input
          name="sessionHours1"
          type="select"
          value={hourDataValue}
          onChange={e => {
            const time = e.target.value + ":" + minuteDataValue + " " + meridiemDataValue;

            setHourDataValue(e.target.value);
            props.setTimeValue(time);
          }}
        >
          {Array.from(Array(13).keys()).map(i => (
            <option key={i} value={i === 0 ? "00" : i < 10 ? "0" + i : i}>
              {i === 0 ? "00" : i < 10 ? "0" + i : i}
            </option>
          ))}
        </Input>

        <Input
          name="sessionMinutes1"
          type="select"
          value={minuteDataValue}
          onChange={e => {
            const time = hourDataValue + ":" + e.target.value + " " + meridiemDataValue;

            setMinuteDataValue(e.target.value);
            props.setTimeValue(time);
          }}
        >
          <option value={"00"}>00</option>
          <option value={"30"}>30</option>
        </Input>

        <Input
          name="sessionMeridiem1"
          type="select"
          value={meridiemDataValue}
          onChange={e => {
            const time = hourDataValue + ":" + minuteDataValue + " " + e.target.value;

            setMeridiemDataValue(e.target.value);
            props.setTimeValue(time);
          }}
        >
          <option value={"AM"}>AM</option>
          <option value={"PM"}>PM</option>
        </Input>
      </div>
    </React.Fragment>
  );
};

export default CustomTimePicker2;
