import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, Col, Input, Row } from "reactstrap";
import UserContext from "../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AddressModel, DependentModel, Insurance, ReceiverModel, SubscriberModel } from "../../models/Insurance";
import moment from "moment";
import { ClientService } from "../../services/ClientService";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import Spinner from "../../common/spinner/spinner";
import { Link } from "react-router-dom";
import { Upload } from "../../models/Upload";
import { Util } from "../../Util";
import Select from "react-select";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";

const ViewInsuranceForm: React.FC<{
  removeFormTwoHandler: any;
  lastData: any;
}> = (props: any) => {
  const [user, setUser] = useContext(UserContext);
  const [insuranceMainData, setInsuranceMainData] = useState<Insurance>({} as Insurance);
  const [isLoading, setIsLoading] = useState(true);
  const [editProfile, setShowEditProfile] = useState(false);
  const [insuranceData, setInsuranceData] = useState<ReceiverModel>({} as ReceiverModel);
  const [addressData, setAddressData] = useState<AddressModel>({} as AddressModel);
  const [dependentAddressData, setDependentAddressData] = useState<AddressModel>({} as AddressModel);
  const [subscriberData, setSubscriberData] = useState<SubscriberModel>({} as SubscriberModel);
  const [dependentData, setDependentData] = useState<DependentModel>({} as DependentModel);
  const [dateValue, setDateValue] = useState(null);
  const [dependentDateValue, setDependentDateValue] = useState(null);
  const [insuranceCard, selectInsuranceCard] = useState({ preview: "", preview2: "", raw: "" });
  const insuranceCardRef = useRef<any>();
  insuranceCardRef.current = insuranceCard;

  const [insuranceCardBack, selectInsuranceCardBack] = useState({ preview: "", preview2: "", raw: "" });
  const insuranceCardBackRef = useRef<any>();
  insuranceCardBackRef.current = insuranceCardBack;

  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [selecteInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag>({} as SelectedInsuranceCompanyTag);
  const [haveAgree, setHaveAgree] = useState("No");

  useEffect(() => {
    viewInsuranceCompanyById();
    getAllInsuranceCompanies();
  }, []);

  const viewInsuranceCompanyById = () => {
    ClientService.ViewInsuranceByClientId(user?._id).then(res => {
      setIsLoading(false);
      setInsuranceMainData(res.data);
      setSelectedInsuranceCompanyTag({
        ...selecteInsuranceCompanyTag,
        ...{ value: res?.data?.insuranceCompanyId?._id, label: res?.data?.insuranceCompanyId?.insuranceCompany.toLowerCase() },
      });
    });
  };

  function setEditProfile() {
    setShowEditProfile(true);
  }

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }

  const db = moment.utc(dateValue || insuranceMainData?.subscriber?.dateOfBirth).format("YYYY-MM-DD");

  function handleDependentDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDependentDateValue(dateValue);
  }

  const dependentDb = moment.utc(insuranceMainData?.dependent?.dateOfBirth).format("YYYY-MM-DD");

  function handlePostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setAddressData({ ...addressData, postalCode: e, state: selectedState ? selectedState : "" });
  }

  function handleDependentPostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setDependentAddressData({ ...dependentAddressData, postalCode: e, state: selectedState ? selectedState : "" });
  }

  const selectInsuranceFile = (e: any) => {
    if (e.target.files.length) {
      selectInsuranceCard({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const getAllInsuranceCompanies = () => {
    const data: any = {
      state: user?.state
    }
    InsuranceCompanyService.getInsuaranceCompanyByState(data).then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.insuranceCompany };
        });
        setSearchInsuranceCompanyTag(tagList);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };

  const selectInsuranceBackFile = (e: any) => {
    if (e.target.files.length) {
      selectInsuranceCardBack({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const updateTherapistDetails = () => {
    const receiverData: any = {
      organizationName: selecteInsuranceCompanyTag.value,
    };
    const address: any = {
      address1: addressData?.address1 || insuranceMainData?.subscriber?.address?.address1,
      city: addressData?.city || insuranceMainData?.subscriber?.address?.city,
      state: addressData?.state || insuranceMainData?.subscriber?.address?.state,
      postalCode: addressData?.postalCode || insuranceMainData?.subscriber?.address?.postalCode,
    };
    const dependentAddress: any = {
      address1: dependentAddressData?.address1 || insuranceMainData?.dependent?.address?.address1,
      city: dependentAddressData?.city || insuranceMainData?.dependent?.address?.city,
      state: dependentAddressData?.state || insuranceMainData?.dependent?.address?.state,
      postalCode: dependentAddressData?.postalCode || insuranceMainData?.dependent?.address?.postalCode,
    };
    const subcribersData: any = {
      memberId: subscriberData?.memberId || insuranceMainData?.subscriber?.memberId,
      policyNumber: subscriberData?.policyNumber || insuranceMainData?.subscriber?.policyNumber,
      firstName: subscriberData?.firstName || insuranceMainData?.subscriber?.firstName,
      lastName: subscriberData?.lastName || insuranceMainData?.subscriber?.lastName,
      gender: subscriberData?.gender || insuranceMainData?.subscriber?.gender,
      dateOfBirth: (dateValue as any) || insuranceMainData?.subscriber?.dateOfBirth,
      paymentResponsibilityLevelCode: subscriberData?.paymentResponsibilityLevelCode || insuranceMainData?.subscriber?.paymentResponsibilityLevelCode,
      address: address,
    };

    const dependent: any = {
      memberId: dependentData?.memberId || insuranceMainData?.dependent?.memberId,
      policyNumber: dependentData?.policyNumber || insuranceMainData?.dependent?.policyNumber,
      firstName: dependentData?.firstName || insuranceMainData?.dependent?.firstName,
      lastName: dependentData?.lastName || insuranceMainData?.dependent?.lastName,
      gender: dependentData?.gender || insuranceMainData?.dependent?.gender,
      dateOfBirth: (dependentDateValue as any) || insuranceMainData?.dependent?.dateOfBirth,
      paymentResponsibilityLevelCode: dependentData?.paymentResponsibilityLevelCode || insuranceMainData?.dependent?.paymentResponsibilityLevelCode,
      relationshipToSubscriberCode: dependentData?.relationshipToSubscriberCode || insuranceMainData?.dependent?.relationshipToSubscriberCode,
      address: dependentAddress,
    };

    if (insuranceCardRef.current.raw && insuranceCardBackRef.current.raw) {
      const data: any = {
        insuranceId: insuranceMainData?._id,
        clientId: user?._id,
        insuranceCompanyId: selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceMainData?.insuranceCardId,
        insuranceCardBackId: insuranceMainData?.insuranceCardBackId,
        dependent: dependent,
        deletingInsuranceCardFrontId: insuranceMainData?.insuranceCardId?._id || "none",
        deletingInsuranceCardBackId: insuranceMainData?.insuranceCardBackId?._id || "none",
      };

      ClientService.UpdateInsurancePlan(data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setInsuranceMainData(res.data);
          setShowEditProfile(false);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else if (insuranceCardRef.current.raw && !insuranceCardBackRef.current.raw) {
      const data: any = {
        insuranceId: insuranceMainData?._id,
        clientId: user?._id,
        insuranceCompanyId: selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceMainData?.insuranceCardId,
        insuranceCardBackId: insuranceMainData?.insuranceCardBackId,
        dependent: dependent,
        deletingInsuranceCardFrontId: insuranceMainData?.insuranceCardId?._id || "none",
        deletingInsuranceCardBackId: "none",
      };

      ClientService.UpdateInsurancePlan(data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setInsuranceMainData(res.data);
          setShowEditProfile(false);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else if (insuranceCardBackRef.current.raw && !insuranceCardRef.current.raw) {
      const data: any = {
        insuranceId: insuranceMainData?._id,
        clientId: user?._id,
        insuranceCompanyId: selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceMainData?.insuranceCardId,
        insuranceCardBackId: insuranceMainData?.insuranceCardBackId,
        dependent: dependent,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: insuranceMainData?.insuranceCardBackId?._id || "none",
      };

      ClientService.UpdateInsurancePlan(data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setInsuranceMainData(res.data);
          setShowEditProfile(false);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      const data: any = {
        insuranceId: insuranceMainData?._id,
        clientId: user?._id,
        insuranceCompanyId: selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceMainData?.insuranceCardId?._id,
        insuranceCardBackId: insuranceMainData?.insuranceCardBackId?._id,
        dependent: dependent,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: "none",
      };

      ClientService.UpdateInsurancePlan(data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setInsuranceMainData(res.data);
          setShowEditProfile(false);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  let state;
  function getStateByZipCode(zipString: string) {
    if (typeof zipString != "string") {
      toast.error("Must pass the zipcode as a string.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }

    if (zipString.length != 5) {
      return;
    }

    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      state = "Colorado";
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      state = "Florida";
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      state = "Maryland";
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode == 5501 || zipcode == 5544) {
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      state = "New Mexico";
    } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      state = "Ohio";
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      state = "Tennessee";
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      state = "Vermont";
    } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode == 20598) {
      state = "Virginia";
    } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      state = "Wyoming";
    } else {
      state = "";
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    return state;
  }

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  const statementFiles = () => {
    switch (insuranceCardRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCard({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const insuranceCardBackFiles = () => {
    switch (insuranceCardBackRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCardBack({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };
  const createOption = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  const handleCreate = (inputValue: string) => {
    if (inputValue.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(inputValue[inputValue.length - 1])) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const newOption = createOption(inputValue);
      const tagList: any = newOption;
      searchInsuranceCompanyTag.push(tagList);
    }
  };

  return (
    <>
      {!isLoading ? (
        <Card className="BorderRadiusLeftRight paddingInsurance">
          <Row>
            <Col xl={12}>
              {!editProfile && (
                <button
                  type="button"
                  className="btn btn-primary relative mb-4"
                  style={{
                    bottom: "0",
                    float: "right",
                  }}
                  onClick={() => {
                    setEditProfile();
                  }}
                >
                  <i className="fa fa-pencil-square-o mr-1"></i>
                  Edit Profile
                </button>
              )}
              {editProfile && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary relative mb-4"
                    style={{
                      bottom: "0",
                      float: "right",
                    }}
                    onClick={() => {
                      updateTherapistDetails();
                    }}
                  >
                    Save
                  </button>

                  <button
                    type="button"
                    className="btn btn-light  relative mr-2 mb-4"
                    style={{
                      bottom: "0",
                      float: "right",
                    }}
                    onClick={() => {
                      setShowEditProfile(false);
                    }}
                  >
                    <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                    Back
                  </button>
                </div>
              )}
            </Col>
          </Row>
          {!editProfile && (
            <div className="card-bodyDiv">
              <div className="card-heading mb-2">
                <h5 className="font-size-20 profileFont mb-4">Receiver</h5>
              </div>
              <Row>
                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.insuranceCompanyId?.insuranceCompany || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.insuranceCompanyId?.insuranceCompany || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-14 profileFont mb-2">Insurance card picture client</h5>
                  </div>

                  <div className="d-sm-flex justify-content-around">
                    {insuranceMainData?.insuranceCardId != null && (
                      <div className="flex  mb-2">
                        <div className="mr-5">
                          <Link
                            to={{ pathname: Util.fileURL(insuranceMainData?.insuranceCardId?._id) }}
                            target="_blank"
                            className="events-icon2"
                            style={{ fontSize: "62px" }}
                          >
                            {/* <FileThumbnail file={insuranceMainData?.insuranceCardId as any} /> */}
                            <div
                              className="img-thumbnail imageFit  imageFit avatar-2xl profile-user-wid-pro items-center margin_center mt-3 profileImageShow"
                              style={{
                                backgroundImage: `url("${Util.fileURL(insuranceMainData?.insuranceCardId?._id)}")`,
                                borderRadius: "0px",
                                //   position: "relative",
                                width: "120px",
                                height: "130px",
                              }}
                            ></div>
                          </Link>
                        </div>
                      </div>
                    )}

                    {insuranceMainData?.insuranceCardBackId != null && (
                      <div className="flex  mb-2">
                        <div className="mr-2">
                          <Link
                            to={{ pathname: Util.fileURL(insuranceMainData?.insuranceCardBackId?._id) }}
                            target="_blank"
                            className="events-icon2"
                            style={{ fontSize: "62px" }}
                          >
                            {/* <FileThumbnail file={insuranceMainData?.insuranceCardBackId as any} /> */}
                            <div
                              className="img-thumbnail imageFit  imageFit avatar-2xl profile-user-wid-pro items-center margin_center mt-3 profileImageShow"
                              style={{
                                backgroundImage: `url("${Util.fileURL(insuranceMainData?.insuranceCardBackId?._id)}")`,
                                borderRadius: "0px",
                                width: "120px",
                                height: "130px",
                                //   position: "relative",
                              }}
                            ></div>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="card-heading mb-2">
                <h5 className="font-size-20 profileFont mb-4">Subscriber</h5>
              </div>
              <Row>
                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.memberId || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.memberId || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.paymentResponsibilityLevelCode || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.paymentResponsibilityLevelCode || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.firstName || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.firstName || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.lastName || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.lastName || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                  </div>
                  <div className="card-heading mb-4 pr-5">
                    {props.lastData._id ? (
                      <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.gender || "-"}</h5>
                    ) : (
                      <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.gender || "-"}</h5>
                    )}
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.dateOfBirth || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.dateOfBirth || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.policyNumber || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.policyNumber || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Address</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.address?.address1 || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.address?.address1 || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">City</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.address?.city || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.address?.city || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">State</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.address?.state || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.address?.state || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.subscriber?.address?.postalCode || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.subscriber?.address?.postalCode || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="card-heading mb-2">
                <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
              </div>
              <Row>
                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.memberId || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.memberId || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.paymentResponsibilityLevelCode || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.paymentResponsibilityLevelCode || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.firstName || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.firstName || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.lastName || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.lastName || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                  </div>
                  <div className="card-heading mb-4 pr-5">
                    {props.lastData._id ? (
                      <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.gender || "-"}</h5>
                    ) : (
                      <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.gender || "-"}</h5>
                    )}
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.dateOfBirth || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.dateOfBirth || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.policyNumber || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.policyNumber || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.relationshipToSubscriberCode || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.relationshipToSubscriberCode || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Address</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.address?.address1 || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.address?.address1 || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">City</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.address?.city || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.address?.city || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">State</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.address?.state || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.address?.state || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      {props.lastData._id ? (
                        <h5 className="font-size-14 mb-4">{props.lastData?.dependent?.address?.postalCode || "-"}</h5>
                      ) : (
                        <h5 className="font-size-14 mb-4">{insuranceMainData?.dependent?.address?.postalCode || "-"}</h5>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {editProfile && (
            <div className="card-bodyDiv">
              <div className="card-heading mb-2">
                <h5 className="font-size-20 profileFont mb-4">Receiver</h5>
              </div>

              <Row>
                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Select
                        placeholder="Choose or create company"
                        onChange={(newValue: any) => setSelectedInsuranceCompanyTag(newValue)}
                        options={searchInsuranceCompanyTag}
                        value={selecteInsuranceCompanyTag || ""}
                        className="mb-4"
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="6">
                  {insuranceMainData?.insuranceCardId != null && (
                    <div className="flex  mb-2">
                      <div className="mr-2">
                        <Link
                          to={{ pathname: Util.fileURL(insuranceMainData?.insuranceCardId?._id) }}
                          target="_blank"
                          className="events-icon2"
                          style={{ fontSize: "62px" }}
                        >
                          <FileThumbnail file={insuranceMainData?.insuranceCardId as any} />
                        </Link>
                      </div>
                    </div>
                  )}

                  {insuranceCardRef.current.preview ? (
                    <div>
                      {insuranceCardRef.current.preview && (
                        <label htmlFor="file-upload1" className="file-upload m-auto">
                          <span className="text-primary pt-5 m-auto cursor-pointer">Change Your insurance card front picture</span>

                          <input
                            id="file-upload1"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                            onChange={selectInsuranceFile}
                            multiple
                          />
                        </label>
                      )}
                      <div>
                        <div className="flex">
                          <div style={{ fontSize: "62px" }}>{statementFiles()}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {!insuranceCardRef.current.preview && (
                    <label
                      htmlFor="file-upload1"
                      style={{ border: "1px dashed" }}
                      className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                    >
                      <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                        <img src={uploadIcon} />
                        <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card front picture</p>
                      </div>
                      <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                      <input
                        id="file-upload1"
                        type="file"
                        style={{ display: "none" }}
                        className="file_upload_input mb-4"
                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                        onChange={selectInsuranceFile}
                        multiple
                      />
                    </label>
                  )}
                </Col>

                <Col xl="6">
                  {insuranceMainData?.insuranceCardBackId != null && (
                    <div className="flex  mb-2">
                      <div className="mr-2">
                        <Link
                          to={{ pathname: Util.fileURL(insuranceMainData?.insuranceCardBackId?._id) }}
                          target="_blank"
                          className="events-icon2"
                          style={{ fontSize: "62px" }}
                        >
                          <FileThumbnail file={insuranceMainData?.insuranceCardBackId as any} />
                        </Link>
                      </div>
                    </div>
                  )}

                  {insuranceCardBackRef.current.preview ? (
                    <div>
                      {insuranceCardBackRef.current.preview && (
                        <label htmlFor="file-upload4" className="file-upload m-auto">
                          <span className="text-primary pt-2 m-auto cursor-pointer">Change Your insurance card back picture</span>

                          <input
                            id="file-upload4"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                            onChange={selectInsuranceBackFile}
                            multiple
                          />
                        </label>
                      )}
                      <div>
                        <div className="flex">
                          <div style={{ fontSize: "62px" }}>{insuranceCardBackFiles()}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {!insuranceCardBackRef.current.preview && (
                    <label
                      htmlFor="file-upload15"
                      style={{ border: "1px dashed" }}
                      className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                    >
                      <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                        <img src={uploadIcon} />
                        <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card back picture</p>
                      </div>
                      <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                      <input
                        id="file-upload15"
                        type="file"
                        style={{ display: "none" }}
                        className="file_upload_input mb-4"
                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                        onChange={selectInsuranceBackFile}
                        multiple
                      />
                    </label>
                  )}
                </Col>
              </Row>

              <div className="card-heading mb-2">
                <h5 className="font-size-20 profileFont mb-4">Subscriber</h5>
              </div>
              <Row>
                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Member Id "
                        name="Member Id "
                        defaultValue={insuranceMainData?.subscriber?.memberId || ""}
                        onChange={e => setSubscriberData({ ...subscriberData, memberId: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Payment Responsibility Level Code"
                        name="Payment Responsibility Level Code"
                        defaultValue={insuranceMainData?.subscriber?.paymentResponsibilityLevelCode || ""}
                        onChange={e => setSubscriberData({ ...subscriberData, paymentResponsibilityLevelCode: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="First name"
                        name="First name"
                        defaultValue={insuranceMainData?.subscriber?.firstName || ""}
                        onChange={e => setSubscriberData({ ...subscriberData, firstName: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Last name"
                        name="Last name"
                        defaultValue={insuranceMainData?.subscriber?.lastName || ""}
                        onChange={e => setSubscriberData({ ...subscriberData, lastName: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                  </div>
                  <div className="card-heading mb-4 pr-5">
                    <Row>
                      <Col style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios1"
                            value="Female"
                            defaultChecked={insuranceMainData?.subscriber?.gender == "Female"}
                            onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                            Female
                          </label>
                        </div>
                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios2"
                            value="Male"
                            defaultChecked={insuranceMainData?.subscriber?.gender == "Male"}
                            onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios2">
                            Male
                          </label>
                        </div>
                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios3"
                            value="NonBinary"
                            defaultChecked={insuranceMainData?.subscriber?.gender == "NonBinary"}
                            onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios3">
                            Non Binary
                          </label>
                        </div>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios4"
                            value="Other"
                            defaultChecked={insuranceMainData?.subscriber?.gender == "Other"}
                            onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios4">
                            Other
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Date of birth"
                        defaultValue={db || ""}
                        onChange={e => handleDateUpdate(e)}
                        max={moment().format("YYYY-MM-DD")}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="policy Number"
                        name="policy Number"
                        defaultValue={insuranceMainData?.subscriber?.policyNumber || ""}
                        onChange={e => setSubscriberData({ ...subscriberData, policyNumber: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Address</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Address"
                        name="Address"
                        defaultValue={insuranceMainData?.subscriber?.address?.address1 || ""}
                        onChange={e => setAddressData({ ...addressData, address1: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">City</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="City"
                        name="City"
                        defaultValue={insuranceMainData?.subscriber?.address?.city || ""}
                        onChange={e => setAddressData({ ...addressData, city: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                    </div>
                    <div className="card-heading mb-4 pr-5">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Postal Code"
                        name="Postal Code"
                        defaultValue={insuranceMainData?.subscriber?.address?.postalCode}
                        onChange={e => handlePostalCode(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>

                {addressData?.state && (
                  <Col xl="6">
                    <div>
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">State</h5>
                      </div>
                      <div className="card-heading mb-4 pr-5">
                        <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceMainData?.subscriber?.address?.state}</h5>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>

              {insuranceMainData?.dependent?.memberId ? (
                <>
                  <div className="card-heading mb-2">
                    <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                  </div>
                  <Row>
                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Member Id "
                            name="Member Id "
                            defaultValue={insuranceMainData?.dependent?.memberId || ""}
                            onChange={e => setDependentData({ ...dependentData, memberId: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Payment Responsibility Level Code"
                            name="Payment Responsibility Level Code"
                            defaultValue={insuranceMainData?.dependent?.paymentResponsibilityLevelCode || ""}
                            onChange={e => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="First name"
                            name="First name"
                            defaultValue={insuranceMainData?.dependent?.firstName || ""}
                            onChange={e => setDependentData({ ...dependentData, firstName: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Last name"
                            name="Last name"
                            defaultValue={insuranceMainData?.dependent?.lastName || ""}
                            onChange={e => setDependentData({ ...dependentData, lastName: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                      </div>
                      <div className="card-heading mb-4 pr-5">
                        <Row>
                          <Col style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadiosss"
                                id="exampleRadios11"
                                value="Female"
                                defaultChecked={insuranceMainData?.dependent?.gender == "Female"}
                                onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios11">
                                Female
                              </label>
                            </div>
                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadiosss"
                                id="exampleRadios22"
                                value="Male"
                                defaultChecked={insuranceMainData?.dependent?.gender == "Male"}
                                onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios22">
                                Male
                              </label>
                            </div>

                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadiosss"
                                id="exampleRadios33"
                                value="NonBinary"
                                defaultChecked={insuranceMainData?.dependent?.gender == "NonBinary"}
                                onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios33">
                                Non Binary
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadiosss"
                                id="exampleRadios44"
                                value="Other"
                                defaultChecked={insuranceMainData?.dependent?.gender == "Other"}
                                onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios44">
                                Other
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="date"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Date of birth"
                            value={dependentDb || ""}
                            onChange={e => handleDependentDateUpdate(e)}
                            max={moment().format("YYYY-MM-DD")}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="policy Number"
                            name="policy Number"
                            defaultValue={insuranceMainData?.dependent?.policyNumber || ""}
                            onChange={e => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Relationship To Subscriber Code"
                            name="Relationship To Subscriber Code"
                            defaultValue={insuranceMainData?.dependent?.relationshipToSubscriberCode || ""}
                            onChange={e => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Address</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Address"
                            name="Address"
                            defaultValue={insuranceMainData?.dependent?.address?.address1 || ""}
                            onChange={e => setDependentAddressData({ ...dependentAddressData, address1: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">City</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="City"
                            name="City"
                            defaultValue={insuranceMainData?.dependent?.address?.city || ""}
                            onChange={e => setDependentAddressData({ ...dependentAddressData, city: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                        </div>
                        <div className="card-heading mb-4 pr-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Postal Code"
                            name="Postal Code"
                            defaultValue={insuranceMainData?.dependent?.address?.postalCode}
                            onChange={e => handleDependentPostalCode(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    {dependentAddressData?.state && (
                      <Col xl="6">
                        <div>
                          <div className="card-heading mb-2">
                            <h5 className="font-size-14 profileFont mb-2">State</h5>
                          </div>
                          <div className="card-heading mb-4 flex">
                            <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceMainData?.subscriber?.address?.state}</h5>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col xl="12">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">Do you wish to add any dependents?</h5>
                      </div>
                    </Col>

                    <Col xl="4">
                      <div className="card-heading mt-2 mb-3">
                        <select className="form-control" onChange={e => setHaveAgree(e.target.value)}>
                          <option selected>Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No" selected>
                            No
                          </option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  {haveAgree == "Yes" && (
                    <>
                      <>
                        <div className="card-heading mb-2">
                          <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                        </div>
                        <Row>
                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Member Id "
                                  name="Member Id "
                                  defaultValue={insuranceMainData?.dependent?.memberId || ""}
                                  onChange={e => setDependentData({ ...dependentData, memberId: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Payment Responsibility Level Code"
                                  name="Payment Responsibility Level Code"
                                  defaultValue={insuranceMainData?.dependent?.paymentResponsibilityLevelCode || ""}
                                  onChange={e => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="First name"
                                  name="First name"
                                  defaultValue={insuranceMainData?.dependent?.firstName || ""}
                                  onChange={e => setDependentData({ ...dependentData, firstName: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Last name"
                                  name="Last name"
                                  defaultValue={insuranceMainData?.dependent?.lastName || ""}
                                  onChange={e => setDependentData({ ...dependentData, lastName: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                            </div>
                            <div className="card-heading mb-4 pr-5">
                              <Row>
                                <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadiosss"
                                      id="exampleRadios11"
                                      value="Female"
                                      defaultChecked={insuranceMainData?.dependent?.gender == "Female"}
                                      onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                    />
                                    <label className="form-check-label" htmlFor="exampleRadios11">
                                      Female
                                    </label>
                                  </div>
                                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadiosss"
                                      id="exampleRadios22"
                                      value="Male"
                                      defaultChecked={insuranceMainData?.dependent?.gender == "Male"}
                                      onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                    />
                                    <label className="form-check-label" htmlFor="exampleRadios22">
                                      Male
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadiosss"
                                      id="exampleRadios33"
                                      value="NonBinary"
                                      defaultChecked={insuranceMainData?.dependent?.gender == "NonBinary"}
                                      onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                    />
                                    <label className="form-check-label" htmlFor="exampleRadios33">
                                      Non Binary
                                    </label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadiosss"
                                      id="exampleRadios44"
                                      value="Other"
                                      defaultChecked={insuranceMainData?.dependent?.gender == "Other"}
                                      onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                    />
                                    <label className="form-check-label" htmlFor="exampleRadios44">
                                      Other
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Date of birth"
                                  value={dependentDb || ""}
                                  onChange={e => handleDependentDateUpdate(e)}
                                  max={moment().format("YYYY-MM-DD")}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="policy Number"
                                  name="policy Number"
                                  defaultValue={insuranceMainData?.dependent?.policyNumber || ""}
                                  onChange={e => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Relationship To Subscriber Code"
                                  name="Relationship To Subscriber Code"
                                  defaultValue={insuranceMainData?.dependent?.relationshipToSubscriberCode || ""}
                                  onChange={e => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Address</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Address"
                                  name="Address"
                                  defaultValue={insuranceMainData?.dependent?.address?.address1 || ""}
                                  onChange={e => setDependentAddressData({ ...dependentAddressData, address1: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">City</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="City"
                                  name="City"
                                  defaultValue={insuranceMainData?.dependent?.address?.city || ""}
                                  onChange={e => setDependentAddressData({ ...dependentAddressData, city: e.target.value })}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Postal Code"
                                  name="Postal Code"
                                  defaultValue={insuranceMainData?.dependent?.address?.postalCode}
                                  onChange={e => handleDependentPostalCode(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          {dependentAddressData?.state && (
                            <Col xl="6">
                              <div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">State</h5>
                                </div>
                                <div className="card-heading mb-4 flex">
                                  <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceMainData?.subscriber?.address?.state}</h5>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </Card>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ViewInsuranceForm;
