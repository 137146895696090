import axios from 'axios';
import * as tus from 'tus-js-client';
import { VidemoService } from '../services/VimeoService';

import {
  Uploader,
  MediaVimeoItem,
  MediaProvider,
} from './MediaUploaderTypes';

type VimeoPresignUploadingResponse = {
  upload: {
    upload_link: string;
  };
  uri: string;
};

export class MediaUploaderVimeo implements Uploader {
  async upload(
    file: File,
    onProgress?: (bytesUploaded: number, bytesTotal: number) => void
  ): Promise<MediaVimeoItem> {
    const presignedLinkResponse = await VidemoService.getPresignLink({ size: file.size, });

    const uploadURI = presignedLinkResponse.upload.upload_link;
    const vimeoVideoLink = presignedLinkResponse.uri;
    const vimeoId = vimeoVideoLink.split('/').slice(-1)[0];

    return new Promise<MediaVimeoItem>((resolve, reject) => {
      const uploader = new tus.Upload(file, {
        uploadUrl: uploadURI,
        endpoint: uploadURI,
        onError: (error: any) => {
          reject(error);
        },
        onProgress: (bytesUploaded: any, bytesTotal: any) => {
          onProgress?.(bytesUploaded, bytesTotal);
        },
        onSuccess: () => {
          resolve({
            provider: MediaProvider.VIMEO,
            id: vimeoId,
          });
        },
      });
      uploader.start();
    });
  }

}
