import React, { useContext, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import UserContext from "src/context/UserContext";
import { toast } from "react-toastify";
import { TherapistService } from "src/services/TherapistService";


const NotesSettings = () => {
  const [user, setUser] = useContext(UserContext);
  const [selectedType, setSelectedType] = useState(user?.aiNotesType);
  const [isInProgress, setIsInProgress] = useState(false);

  const updateNoteType = async () => {
    try {
      if(isInProgress){
        return toast.error("Please wait !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      if(!selectedType || (selectedType != "SOAP" && selectedType != "PIE")) {
        return toast.error("Please select AI note type !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      setIsInProgress(true);
      
      const data ={
        noteType: selectedType
      }

      const res = await TherapistService.updateAINoteType(data);

      if(res?.success){

        setUser({...user,aiNotesType:selectedType});

        toast.success("AI Note Type Updated successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }else{

        toast.error("Something went wrong !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      setIsInProgress(false);
      
    } catch (error) {
      setIsInProgress(false);
      return toast.error("Please select AI note type !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <div>
      <Row>
        <div className="card-heading mb-2">
          <h5 className="font-size-14 mb-2">Notes Settings:</h5>
        </div>
        <Col xl="4">
          <Label htmlFor="formrow-firstname-Input" className="font-size-14 mb-2 ms-2">
            Select AI Notes Type
          </Label>
          {/* <div className="form-check ms-4">
            <Input
              type="radio"
              className="form-check-input"
              name="notesType"
              value="SOAP"
              defaultChecked={selectedType == "SOAP"}
              onChange={e =>
                {
                  setSelectedType(e?.target?.value);
                }
              }
            />
            <label className="form-check-label">
              SOAP Notes
            </label>
          </div> */}
          <div className="form-check ms-4">
            <Input
              type="radio"
              className="form-check-input"
              name="notesType"
              value="PIE"
              defaultChecked={selectedType == "PIE"}
              onChange={e =>
                {
                  setSelectedType(e?.target?.value);
                }
              }
            />
            <label className="form-check-label">
              PIE Notes
            </label>
          </div>
          <Row className="mt-2 mb-2">
            <button type="button" className={isInProgress ? "btn btn-secondary w-50" : "btn btn-primary w-50"} style={{ float: "right" }} 
              onClick={()=>{
                updateNoteType();
              }
            } >
              {isInProgress ? "Saving..." : "Save"}
            </button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NotesSettings;
