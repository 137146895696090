import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, Container } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { NoteList, TherapistSession } from "../../models/DiagnosisNote";
import { AdminService } from "../../services/AdminService";
import { ParameterTypes } from "../../utils/ParameterTypes";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import SessionHistoryModalForAdmin from "../Popup/SessionHistoryModalForAdmin";

const TherapistSessions: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<ParameterTypes>();
  const [isLoaded, setIsLoaded] = useState(true);
  const [showNote, setShowNote] = useState(false);
  const [noteId, setNoteId] = useState<string>("");
  const [sessionList, setSessionList] = useState<TherapistSession[]>([]);

  useEffect(() => {
    if (userId) getTherapistSessionListByTherapistId(userId);
  }, []);

  const getTherapistSessionListByTherapistId = (therpaistId: string) => {
    setIsLoaded(false);

    AdminService.getAllTherapistSessions(therpaistId).then(res => {
      if (res.success) {
        setSessionList(res.data);
        setIsLoaded(true);
      }
    });
  };

  const back = () => {
    history.goBack();
  };

  const closseNote = () => {
    setShowNote(!showNote);
  };

  const openNote = (noteId: string) => {
    setNoteId(noteId);
    setShowNote(!showNote);
  };

  return (
    <React.Fragment>
      <SessionHistoryModalForAdmin setShowModal={closseNote} showModal={showNote} userId={noteId} isVersion={false} />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Lavni" breadcrumbItem="Sessions" />
          <div className="flex flex-wrap justify-content-between">
            <div className="flex cursor-pointer" onClick={() => back()}>
              <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
              <CardTitle className="mb-4  cursor-pointer">&nbsp; Back</CardTitle>
            </div>
          </div>

          {!isLoaded && <Spinner className="bouncer" />}

          {isLoaded && sessionList.length == 0 && <div className="flex flex-wrap"><h5 className="text-center">No session notes so far!</h5></div>}

          {sessionList &&
            sessionList.length > 0 &&
            sessionList.map((session: TherapistSession) => {
              return (
                <>
                  <h5 className="font-size-18">
                    {"Notes created for "}
                    {session._id.fullName}
                  </h5>
                  {session.notes &&
                    session.notes.length > 0 &&
                    session.notes.map((note: NoteList, index: number) => {
                      return (
                        <Card key={index} className="cursor_pointer mt-1">
                          <CardBody>
                            {note?.appointmentId ? (
                              <>
                                <h5 className="font-size-15 mb-1">
                                  Appointment Start Time <span className="session-h5">{moment(note?.appointmentStart || null).format("YYYY-MM-DD h:mm:ss a")}</span> / Meeting Started at <span className="session-h5">{moment(note?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                                </h5>

                              </>
                            ) : (
                              <>
                                <h5 className="font-size-15 mb-1">
                                  Meeting Started At <span className="session-h5">{moment(note?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                                </h5>
                              </>
                            )}

                            {note.isMeetingTranscribe && (
                              <a
                                onClick={() => {
                                  if (note.isMeetingTranscribe) {
                                    openNote(note.diagnosisNoteId);
                                  }
                                }}
                                className="btn btn-secondary "
                                style={{ marginTop: "10px" }}
                              >
                                View / Edit Notes
                              </a>
                            )}
                          </CardBody>
                        </Card>
                      );
                    })}
                </>
              );
            })}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default TherapistSessions;
