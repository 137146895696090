import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Table, Collapse } from "reactstrap";
import Close from "../../../assets/images/icons/close.png";
import { AdminService } from "../../../services/AdminService";
import { AppResponse } from "../../../models/Response";
import { groupBy } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

interface ClaimResponse {
  _id: string;
  clientId: {
    role: string;
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
    insuranceId: {
      _id: string;
      insuranceCompanyId: {
        _id: string;
        insuranceCompany: string;
      };
    };
  };
  therapistId: {
    role: string;
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
  };
  note: string;
  isMeetingTranscribe: boolean;
  meetingStartedTime: string;
  meetingId: string;
  diagnosisNoteId: string;
  createdAt: string;
  updatedAt: string;
  claimStatus?: string;
  flag?: boolean;
}

interface SubmitClaimPartitionModalProps {
  isOpen: boolean;
  toggle: () => void;
  therapistId: string;
}

const SubmitClaimPartitionModal: React.FC<SubmitClaimPartitionModalProps> = ({
  isOpen,
  toggle,
  therapistId
}) => {
  const [openRows, setOpenRows] = useState<string[]>([]);
  const [claimsData, setClaimsData] = useState<ClaimResponse[]>([]);
  const [groupedData, setGroupedData] = useState<{[key: string]: ClaimResponse[]}>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchClaimsData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await AdminService.getDetailClaimsStatusOfTherapist(therapistId);
      const claims = response.data;
      console.log(claims);
      setClaimsData(claims);
      
      // Group by insurance company
      const grouped = groupBy(claims, (claim) => 
        claim.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany || 'Unknown Insurance'
      );
      setGroupedData(grouped);
    } catch (error) {
      console.error('Error fetching claims:', error);
      setError('Failed to fetch claims data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && therapistId) {
      fetchClaimsData();
    }
  }, [isOpen, therapistId]);

  const toggleRow = (insuranceId: string) => {
    setOpenRows(prevOpenRows =>
      prevOpenRows.includes(insuranceId)
        ? prevOpenRows.filter(id => id !== insuranceId)
        : [...prevOpenRows, insuranceId]
    );
  };

  const handleSubmit = async (insuranceCompany: string) => {
    try {
      setIsLoading(true);
      const clientIds = groupedData[insuranceCompany]
        .filter(claim => claim.claimStatus === 'PENDING_SUBMISSION')
        .map(claim => claim.clientId._id);

      const response = await AdminService.submitClaimPartition({
        therapistId: therapistId,
        clientIds: clientIds
      });

      if (response.success) {
        toast.success(response.message || 'Claims submitted successfully');
        // Refresh the claims data
        await fetchClaimsData();
      } else {
        toast.error(response.message || 'Failed to submit claims');
      }
    } catch (error) {
      console.error(`Error submitting claims for ${insuranceCompany}:`, error);
      toast.error('An error occurred while submitting claims');
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusBadgeColor = (status?: string) => {
    if (!status) return 'bg-secondary';
    switch (status) {
      case 'SUBMITTED':
        return 'bg-success';
      case 'PENDING_SUBMISSION':
        return 'bg-warning';
      default:
        return 'bg-secondary';
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggle}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
          className="btn-s-20"
        >
          <img src={Close} alt="Close" className="mt-1" />
        </Button>
      </div>
      <div className="ps-4 pe-4 pt-4 pb-4">
        <Row>
          <span className="font-size-18 profileFont mb-4 cursor-pointer text-center">
            Therapist: {claimsData ? claimsData[0]?.therapistId?.firstname:''} {claimsData?claimsData[0]?.therapistId?.lastname:''}
          </span>
        </Row>
        <div className="table-responsive">
          {isLoading ? (
            <div className="text-center p-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : error ? (
            <div className="text-center p-5 text-danger">{error}</div>
          ) : Object.keys(groupedData).length === 0 ? (
            <div className="text-center p-5 text-muted">No claims data available</div>
          ) : (
            <Table className="table table-hover">
              <thead>
                <tr>
                  <th>Insurance Provider</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedData).map(([insuranceCompany, claims]) => (
                  <React.Fragment key={insuranceCompany}>
                    <tr 
                      onClick={() => toggleRow(insuranceCompany)} 
                      style={{ cursor: 'pointer' }}
                      className={openRows.includes(insuranceCompany) ? 'table-active' : ''}
                    >
                      <td>
                        <i 
                          className={`fa fa-chevron-${openRows.includes(insuranceCompany) ? 'down' : 'right'} me-2`}
                        />
                        {insuranceCompany}
                      </td>
                      <td>
                        <Button 
                          color="primary" 
                          size="sm" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSubmit(insuranceCompany);
                          }}
                        >
                          Submit For PENDING_SUBMISSION
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="p-0">
                        <Collapse isOpen={openRows.includes(insuranceCompany)}>
                          <div className="p-3 bg-light">
                            <h6 className="mb-3">Patients with {insuranceCompany}</h6>
                            <Table className="table table-sm mb-0">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Meeting Time</th>
                                  <th>Claim Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {claims.map((claim) => (
                                  <tr key={claim._id}>
                                    <td>{claim.clientId?.firstname} {claim.clientId?.lastname}</td>
                                    <td>{claim.clientId?.email}</td>
                                    <td>{moment(claim.meetingStartedTime).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                    <td>
                                      <span className={`badge ${getStatusBadgeColor(claim.claimStatus)}`}>
                                        {claim.claimStatus || 'Not Found'}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SubmitClaimPartitionModal;
