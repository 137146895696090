import React, {useContext} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import UserContext from "src/context/UserContext";

const Breadcrumb = props => {
  const [userDetails] = useContext(UserContext);
  return (
    <>
      {props.breadcrumbItem == "Article View" ||
     
      props.breadcrumbItem == "Create Article" ||
      props.breadcrumbItem == "Edit Article" ? (
        <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-14">
                <Link to={userDetails? '/articles':'/blogs/'} style={{ color: "inherit" }}>
                  <i className="bx bx-arrow-back"></i>&nbsp;
                  {userDetails ? "Back to Discover": "Back"}
                </Link>
              </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="#">{props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
      ) :  props.breadcrumbItem == "Article Grid" ?(
        <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-14">
              <Link to={"/articles-grid"} style={{ color: "inherit" }}>
                <i className="bx bx-arrow-back"></i>&nbsp;
                Back to Discover
              </Link>
            </h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to="#">{props.title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{props.breadcrumbItem}</Link>
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
      ):
      (
        <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="#">{props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
