import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import "../../assets/css/chat_screen.css";

const CountDownTimer: React.FC<{ hoursMinSecs: any; hideTimer: () => void }> = (props: any) => {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    setTime(props.hoursMinSecs);
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return `00 : 00 : 00`;
    } else {
      // Render a countdown
      return `${hours.toString().padStart(2, "0")} : ${minutes.toString().padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
    }
  };

  return (
    <div>
      {time != "" && (
        <div className="timer-div" style={{ zIndex: 9998 }}>
          <div className="timer-title">
            {"Your Meeting Ends In "}
            <Countdown date={time} renderer={renderer} />
          </div>

          <i className="fa fa-times" onClick={() => props.hideTimer()} style={{marginLeft: '15px', fontSize: '15px'}}></i>
        </div>
      )}
    </div>
  );
};

export default CountDownTimer;
