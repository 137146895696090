import React, { useState } from 'react';
import { StepProps } from './types';

const SendFax: React.FC<StepProps> = ({
  searchDocumentDownloadsByAdminSelected,
  onNext,
  onBack
}) => {
  const [faxNumber, setFaxNumber] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [coverNote, setCoverNote] = useState('');
  const [sendStatus, setSendStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSend = () => {
    setSendStatus('sending');
    // Simulate sending
    setTimeout(() => {
      setSendStatus('success');
      onNext();
    }, 2000);
  };

  const isValid = faxNumber.length >= 10 && recipientName.trim() !== '';

  return (
    <div>
      <h6 className="mb-4">Send Fax</h6>

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title mb-4">Recipient Information</h6>
              
              <div className="mb-3">
                <label className="form-label">Fax Number</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fas fa-fax"></i>
                  </span>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Enter fax number"
                    value={faxNumber}
                    onChange={(e) => setFaxNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Recipient Name</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter recipient name"
                    value={recipientName}
                    onChange={(e) => setRecipientName(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Cover Note (Optional)</label>
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder="Enter any additional notes"
                  value={coverNote}
                  onChange={(e) => setCoverNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title mb-4">Summary</h6>
              
              <table className="table table-sm">
                <tbody>
                  <tr>
                    <td>Total Pages:</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>File Size:</td>
                    <td>2.5 MB</td>
                  </tr>
                  <tr>
                    <td>Resolution:</td>
                    <td>High Quality</td>
                  </tr>
                </tbody>
              </table>

              <div className="alert alert-warning mt-3" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>
                Please verify the fax number before sending.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="alert alert-info" role="alert">
        <i className="fas fa-info-circle me-2"></i>
        The audit PDF will be sent via fax to the specified recipient.
      </div>

      <div className="text-end mt-3">
        <button 
          className="btn btn-secondary me-2" 
          onClick={onBack}
          disabled={sendStatus === 'sending'}
        >
          Back
        </button>
        <button 
          className="btn btn-primary" 
          onClick={handleSend}
          // disabled={!isValid || sendStatus === 'sending'}
          disabled={sendStatus === 'sending'}
        >
          {sendStatus === 'sending' ? (
            <>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              Sending...
            </>
          ) : 'Send Fax'}
        </button>
      </div>
    </div>
  );
};

export default SendFax;
