import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NewClinicalAssessmentPdfModal from './Popup/NewClinicalAssessmentPdfModal';
import { TPType } from 'src/models/therapyPlan/therapyPlan';

const ClinicalAssessmentPdf: React.FC = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);

  const params = new URLSearchParams(location.search);
  const identifierId = params.get('identifierId');
  const digitalAssessmentType = (params.get('digitalAssessmentType') as TPType) || TPType.VERSION;

  if (!identifierId) {
    return <div>Missing identifierId parameter</div>;
  }

  return (
    <div>
      <NewClinicalAssessmentPdfModal
        setShowModal={setShowModal}
        showModal={showModal}
        identifierId={identifierId}
        isAdmin={false}
        eventType="DOWNLOAD"
        insuranceDocApprovalId={null}
        setModalRefresher={null}
        digitalAssessmentType={digitalAssessmentType}
      />
    </div>
  );
};

export default ClinicalAssessmentPdf;
