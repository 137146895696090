import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import * as VideoExpress from "@vonage/video-express";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import useStyles from "./styles";
import usePreviewPublisher from "../../hooks/usePreviewPublisher";
import AudioSettings from "../AudioSetting";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import VideoSettings from "../VideoSetting";
import DeviceAccessAlert from "../DeviceAccessAlert";
import { UserContextForVonageGroup } from "../../context/UserContextForVonageGroup";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DEVICE_ACCESS_STATUS } from "./../constants";
import VideoFilter from "../VideoFilter";
import VideoRoom from "../VideoRoom";
import { ThemeProvider } from "@material-ui/styles";
import CallInitializationContext from "src/context/CallInitializationContext";
import UserContext from "src/context/UserContext";
import { VideoChatService } from "src/services/VideoChatService";
import { VonageServices } from "../../../vonageServices";
import { SocketContext } from "src/context/ScoketContext";
import { toast } from "react-toastify";
import LocalCallTimeOutContext from "src/context/LocalCallTimeOutlContext";
import LocalCallContext from "src/context/LocalCallContext";
import { Role } from "src/models/Role";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import OngoingCallRefreshModal from "src/pages/Popup/OngoingCallRefreshModal";

export default function WaitingRoom() {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useContext(UserContextForVonageGroup);
  const waitingRoomVideoContainer = useRef();
  // const [roomName, setRoomName] = useState(roomToJoin);
  const [userName, setUserName] = useState("");
  const [isUserNameInvalid, setIsUserNameInvalid] = useState(false);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const defaultPublishVideo = localCallInitializeData.isAudioCall ? false : user.defaultSettings.publishVideo;
  const [localAudio, setLocalAudio] = useState(user.defaultSettings.publishAudio);
  const [localVideo, setLocalVideo] = useState(defaultPublishVideo);
  const [localVideoSource, setLocalVideoSource] = useState(undefined);
  const [localAudioSource, setLocalAudioSource] = useState(undefined);
  const [localAudioOutput, setLocalAudioOutput] = useState(undefined);
  /* const [devices, setDevices] = useState(null); */
  let [audioDevice, setAudioDevice] = useState("");
  let [videoDevice, setVideoDevice] = useState("");
  let [audioOutputDevice, setAudioOutputDevice] = useState("");
  // const [backgroundBlur, setBackgroundBlur] = useState(user.videoEffects.backgroundBlur);
  const [videoFilter, setVideoFilter] = useState({ filterName: "", filterPayload: "" });
  const { createPreview, destroyPreview, previewPublisher, logLevel, previewMediaCreated, deviceInfo, accessAllowed } = usePreviewPublisher();
  const [loggedUser] = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [callTimeOut, setCallTimeOut, setShowPopUp] = useContext(LocalCallTimeOutContext);
  const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { roomName } = useParams();
  const [allowTranscribe, setAllowTranscribe] = useState(false);
  const [showOngoingCallRefreshModal, setShowOngoingCallRefreshModal] = useState(false);

  useEffect(() => {
    setUserName(loggedUser?.firstname);
  }, []);

  const handleVideoSource = React.useCallback(
    e => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      previewPublisher.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource]
  );

  const handleAudioSource = React.useCallback(
    e => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      previewPublisher.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource]
  );

  const handleAudioOutput = React.useCallback(
    e => {
      const audioOutputId = e.target.value;
      setAudioOutputDevice(audioOutputId);
      // await VideoExpress.setAudioOutputDevice(audioOutputId);
      setLocalAudioOutput(audioOutputId);
    },
    [setLocalAudioOutput, setAudioOutputDevice]
  );

  const [isJoinedTheVideoRoom, setIsJoinedTheVideoRoom] = useState(false);
  const handleJoinClick = async () => {
    setIsButtonDisabled(true);
    console.log("WR 01");
    console.log(localCallInitializeData);
    // localStorage.setItem("username", userName);
    if (localCallInitializeData.start) {
      console.log("*********group Call started **********");
      const isAudioCall = localCallInitializeData.isAudioCall;
      const meetingTime = localCallInitializeData.meetingTime;
      const isGroupCall = localCallInitializeData.isGroupCall;
      const groupCallId = localCallInitializeData.groupCallId;
      await initializeInstantCall(isAudioCall, meetingTime, allowTranscribe, isGroupCall, groupCallId);
    } else {
      const res = await VonageServices.getMeetingDataUsingSessionName(roomName);
      console.log(res);
      if (res.success) {
        const meetingData = res.data;
        if (meetingData?.callingStatus == "STARTED") {
          console.log("********* Joined to group call **********");
          JoinVonageCall(meetingData?._id, meetingData?.isAppointmentBased, "ddddddd", allowTranscribe);
        } else {
          console.log("********* Joined to group call after refreshed **********");
          joinedMeetingAfterRefreshed(meetingData?._id, meetingData?.isAudioCall, meetingData?.meetingDuration, meetingData?.clientId);
        }
      }
    }
  };

  const joinedMeetingAfterRefreshed = (meetingIdFromResponseInitial, isAudioCall, meetingDuration, clientId) => {
    console.log("jc 04");
    const dataForCall = {
      callId: meetingIdFromResponseInitial,
      onOngoingCall: true,
      recieversUseDefaultAvatarValue: "",
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: "",
      // meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
      meetingMainTherapistId: "",
      isVideoCall: !isAudioCall,
      meetingDuration: meetingDuration,
      recieversUserId: clientId,
      recieversName: "test",
      recieverCallRecordingAllowed: true,
      recieversSocketId: "test",
      callEndedForBothUsers: false,
      isAudioCall: isAudioCall,
    };
    setLocalCallInitialize(true);
    console.log("jc 05");
    console.log("VWC 03");
    setLocalUserOnCall(true, dataForCall, false, false);
    history.push(`/video-room-group/${roomName}`);
  };

  const JoinVonageCall = async (meetingId, isAppointmentBased, appointmentId, transcribeAllowedForLoggedUser) => {
    try {
      //!Add optional api call to here.
      console.log("VWC 02");
      const res = await VonageServices.joinVonageGroupMeeting(meetingId);
      console.log(res);
      console.log("jc 01");
      const isVideoCall = true;
      console.log("jc 02");
      if (res.success) {
        console.log("jc 03");
        const res2 = await VonageServices.acceptVonageGroupCall(meetingId);
        const remainingTime = res.data.meetingData.remainingMeetingTimeForCurrentMeeting;
        // const userId = res.data.recieverData.userId;
        const meetingIdFromResponseInitial = res.data.meetingData.meetingId;
        const isAudio = res.data.meetingData?.isAudioCall;

        console.log("jc 04");
        const dataForCall = {
          callId: meetingIdFromResponseInitial,
          onOngoingCall: true,
          recieversUseDefaultAvatarValue: "",
          recieversAvatarId: "",
          recieversAvatarBackgroundId: "",
          recieversIncogniteModeOn: "",
          // meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
          meetingMainTherapistId: "",
          isVideoCall: isVideoCall,
          meetingDuration: res.data.meetingData.meetingDuration,
          recieversUserId: "",
          recieversName: "",
          recieverCallRecordingAllowed: "",
          recieversSocketId: "",
          callEndedForBothUsers: false,
          isAudioCall: isAudio,
        };
        setLocalCallInitialize(true);
        console.log("jc 05");
        console.log("VWC 03");
        setLocalUserOnCall(true, dataForCall, false, false);
        history.push(`/video-room-group/${roomName}`);
      } else {
        toast.error("Call Initialization Error Occured.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      console.log(error);

      toast.error("Start Call Error Occured B", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      resetContextData();
    }
  };

  const initializeInstantCall = async (isAudio, meetingTime, transcribeAllowedForUser, isGroupCall, groupCallId) => {
    try {
      console.log("WR 03");
      const initializeResponse = await VonageServices.initializeVonageGroupCall(meetingTime, true, isAudio, transcribeAllowedForUser, isGroupCall, groupCallId);
      console.log(initializeResponse);
      if (initializeResponse.success) {
        const meetingIdFromResponseInitial = initializeResponse.data.meetingId;

        if (initializeResponse.data.alreadyStarted == false) {
          await startInstantCall(roomName, meetingIdFromResponseInitial, meetingTime, isAudio, groupCallId);
        } else {
          toast.error("Call Already Started", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          resetContextData();
        }
      }
    } catch (error) {
      toast.error("Call Initialization Error Occured.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      resetContextData();
    }
  };

  const resetContextData = () => {
    const timeoutData = {
      isTimeOutRunning: true,
      userOnMeetingScreen: false,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };

    setCallTimeOut(timeoutData);

    const callInitializeDataForReset = {
      start: false,
      isInstantMeeting: false,
      isAppointmentBased: false,
      isTranscribeAllowed: false,
      appointmentId: "",
      recieverId: "",
      meetingTime: 30,
      meetingId: "",
      isAudioCall: false,
    };

    setLocalCallInitialize(false);
    setLocalCallInitializeData(callInitializeDataForReset);

    const resetDataForCall = {
      callId: "",
      onOngoingCall: false,
      recieversUseDefaultAvatarValue: true,
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: true,
      meetingMainTherapistId: "",
      isVideoCall: false,
      meetingDuration: 30,
      recieversUserId: "",
      recieversName: "",
      recieverCallRecordingAllowed: false,
      recieversSocketId: "",
      callEndedForBothUsers: false,
      videoSdkToken: "",
      isAudioCall: false,
    };
    setLocalUserOnCall(false, resetDataForCall, true, false);
  };

  const startInstantCall = async (sessionId, meetingId, meetingTime, isAudioCall, groupCallId) => {
    try {
      console.log("WR 04");
      const res = await VonageServices.startVonageMeetingGroup(sessionId, meetingId, groupCallId);
      console.log(res);

      const dataForCall = {
        callId: meetingId,
        onOngoingCall: true,
        recieversUseDefaultAvatarValue: "",
        recieversAvatarId: "",
        recieversAvatarBackgroundId: "",
        recieversIncogniteModeOn: "",
        meetingMainTherapistId: "",
        isVideoCall: !isAudioCall,
        meetingDuration: meetingTime,
        recieversUserId: "",
        recieversName: "",
        recieverCallRecordingAllowed: "",
        recieversSocketId: "",
        callEndedForBothUsers: false,
        isAudioCall: isAudioCall,
      };

      setLocalUserOnCall(true, dataForCall, false, true);
      // setOutGoinCallTimeOutFunction();

      // const data = {
      //   socketId: res.data.recieverData.socketId,
      //   senderSocketId: socket.id,
      //   callId: meetingId,
      //   myUserId: loggedUser?._id,
      //   therapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id,
      //   meetingDuration: meetingTime,
      //   useDefaultAvatar: res.data.ownData.useDefaultAvatar,
      //   avatarId: res.data.ownData.avatarId,
      //   avatarBackgroundId: res.data.ownData.avatarBackgroundId,
      //   incognito: loggedUser?.incognito,
      //   callerName: loggedUser?.firstname,
      //   isVideoCall: !isAudioCall,
      //   recieverRcordingAllowed: loggedUser?.callRecordingAllowed,
      //   receiverId: userId,
      //   senderId: loggedUser?._id,
      //   vonageSessionId: sessionId
      // };
      const localCallInitializeDataa = {
        start: true,
        isInstantMeeting: true,
        isAppointmentBased: false,
        isTranscribeAllowed: false,
        appointmentId: "ddddddd",
        recieverId: "",
        meetingTime: meetingTime,
        meetingId,
        isAudioCall: isAudioCall,
        vonageSessionId: sessionId,
        isGroupCall: true,
        groupCallId: groupCallId,
      };
      // history.push("/room")
      setLocalCallInitialize(true);
      setLocalCallInitializeData(localCallInitializeDataa);
      // socket.emit("vonage-call-user", data);
      console.log("WR 05");
      history.push(`/video-room-group/${sessionId}`);
    } catch (error) {
      console.log(error);

      toast.error(res.errorCode == 456 ? "Sorry! This client doesn't have a valid subscription plan." : "Start Call Error Occured A", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      resetContextData();
    }
  };

  const handleAudioChange = React.useCallback(e => {
    setLocalAudio(e.target.checked);
  }, []);

  const handleVideoChange = React.useCallback(e => {
    setLocalVideo(e.target.checked);
  }, []);

  const handleChangeVideoFilter = React.useCallback(
    async (filter, filterPayload) => {
      if (previewPublisher && filter) {
        switch (filter) {
          case "reset":
            await previewPublisher.clearVideoFilter();
            setVideoFilter({ filterName: "", filterPayload: "" });
            break;
          case "blur":
            await previewPublisher.setVideoFilter({ type: "backgroundBlur", blurStrength: filterPayload });
            setVideoFilter({ filterName: filter, filterPayload });
            break;
          case "backgroundImage":
            await previewPublisher.setVideoFilter({ type: "backgroundReplacement", backgroundImgUrl: filterPayload });
            setVideoFilter({ filterName: filter, filterPayload });
            break;
          default:
          // do nothing
        }
      }
    },
    [previewPublisher]
  );

  // const handleChangeBackgroundBlur = React.useCallback(async () => {
  //   try {
  //     if (backgroundBlur) {
  //       setBackgroundBlur(false);
  //       destroyPreview();
  //       stopEffect();
  //       createPreview(waitingRoomVideoContainer.current, {
  //         videoSource: localVideoSource,
  //       });
  //     } else {
  //       setBackgroundBlur(true);
  //       destroyPreview();
  //       const outputVideoStream = await startBackgroundBlur(videoDevice);
  //       console.log(outputVideoStream);
  //       createPreview(waitingRoomVideoContainer.current, {
  //         videoSource: outputVideoStream.getVideoTracks()[0],
  //         mirror: true,
  //       });
  //     }
  //   } catch (e) {
  //     console.log(`Could not send background blurring - ${e}`);
  //   }
  // }, [
  //   backgroundBlur,
  //   destroyPreview,
  //   stopEffect,
  //   createPreview,
  //   localVideoSource,
  //   videoDevice,
  //   startBackgroundBlur,
  // ]);

  const onChangeParticipantName = e => {
    const userName = e.target.value;
    if (userName === "" || userName.trim() === "") {
      // Space detected
      setUserName("");
      return;
    }
    setIsUserNameInvalid(false);
    setUserName(userName);
  };

  useEffect(() => {
    if (
      localAudio !== user.defaultSettings.publishAudio ||
      localVideo !== user.defaultSettings.publishVideo ||
      localAudioSource !== user.defaultSettings.audioSource ||
      localVideoSource !== user.defaultSettings.videoSource ||
      videoFilter.filterName !== user.videoFilter.filterName ||
      videoFilter.filterPayload !== user.videoFilter.filterPayload ||
      localAudioOutput !== user.defaultSettings.audioOutput
    ) {
      setUser({
        ...user,
        videoFilter: {
          filterName: videoFilter.filterName,
          filterPayload: videoFilter.filterPayload,
        },
        defaultSettings: {
          publishAudio: localAudio,
          publishVideo: localVideo,
          audioSource: localAudioSource,
          videoSource: localVideoSource,
          audioOutput: localAudioOutput,
        },
      });
    }
  }, [localAudio, localVideo, user, setUser, localAudioSource, localVideoSource, videoFilter, localAudioOutput]);

  useEffect(() => {
    if (userName !== user.userName) {
      setUser({ ...user, userName: userName });
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher.getAudioDevice().then(currentAudioDevice => {
        setAudioDevice(currentAudioDevice.deviceId);
      });
      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);

      VideoExpress.getActiveAudioOutputDevice().then(currentAudioOutputDevice => {
        setAudioOutputDevice(currentAudioOutputDevice.deviceId);
      });
    }
  }, [deviceInfo, previewPublisher, setAudioDevice, setVideoDevice, previewMediaCreated, setAudioOutputDevice]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }

    // return () => {
    //   // stopEffect();
    //   destroyPreview();
    // };
  }, [createPreview, destroyPreview]);

  useEffect(() => {
    setShowOngoingCallRefreshModal(localCallInitialize);
  }, [localCallInitialize]);

  const setShowOngoingCallRefreshModalFunc = showModal => {
    setShowOngoingCallRefreshModal(showModal);
  };

  return (
    <>
      {showOngoingCallRefreshModal && <OngoingCallRefreshModal showModal={showOngoingCallRefreshModal} setShowModal={setShowOngoingCallRefreshModalFunc} />}
      <div className={classes.waitingRoomContainer} style={{ width: "auto" }}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="publisher-name"
              label="Name"
              name="name"
              error={isUserNameInvalid}
              required
              autoComplete="Name"
              helperText={userName === "" ? "Empty Field" : " "}
              value={userName}
              onChange={onChangeParticipantName}
            />
            <div className={classes.mediaSources}>
              {deviceInfo && previewMediaCreated && (
                <>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select Audio Source</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={audioDevice} onChange={handleAudioSource}>
                      {deviceInfo.audioInputDevices.map(device => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* <FormControl>
                      <InputLabel id="video">Select Audio Output</InputLabel>
                      {deviceInfo.audioOutputDevices && (
                        <Select labelId="video" id="demo-simple-select" value={audioOutputDevice} onChange={handleAudioOutput}>
                          {deviceInfo.audioOutputDevices.map((device) => (
                            <MenuItem key={device.deviceId} value={device.deviceId}>
                              {device.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl> */}
                </>
              )}

              {deviceInfo && previewMediaCreated && (
                <FormControl>
                  <InputLabel id="video">Select Video Source</InputLabel>
                  {deviceInfo.videoInputDevices && (
                    <Select labelId="video" id="demo-simple-select" value={videoDevice} onChange={handleVideoSource}>
                      {deviceInfo.videoInputDevices.map(device => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            </div>
          </form>
          <div id="waiting-room-video-container" className={classes.waitingRoomVideoPreview} ref={waitingRoomVideoContainer}></div>
          <div className={classes.deviceContainer}>
            {/* <AudioSettings className={classes.deviceSettings} hasAudio={localAudio} onAudioChange={handleAudioChange} /> */}
            <LinearProgress variant="determinate" value={logLevel} />
            {/* <VideoSettings className={classes.deviceSettings} hasVideo={localVideo} onVideoChange={handleVideoChange} /> */}
          </div>
          <VideoFilter handleChangeVideoFilter={handleChangeVideoFilter} />
          <div className="d-flex justify-content-between flexColumn mt-3">
            <div className="d-flex">
              <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
                Allow Call Transcript
              </h5>

              <div className="form-check form-switch form-switch-lg">
                <label className="form-check-label" htmlFor="newsletter">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="newsletter"
                    checked={allowTranscribe}
                    onChange={e => { }}
                    onClick={() => {
                      setAllowTranscribe(!allowTranscribe);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Button variant="contained" className={classes.joinBtn} color="primary" onClick={handleJoinClick} disabled={isButtonDisabled || !userName}>
            Join Call
          </Button>
        </Grid>
      </div>
      {accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED && <DeviceAccessAlert accessStatus={accessAllowed}></DeviceAccessAlert>}
    </>
  );
}
