import React from "react";
import "../../assets/css/skeleton.css";

const SketetonGroup3: React.FC = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="skeleton skeleton3">
                <div className="flex1">
                  <div className="line"></div>
                  <div className="line h8 w50"></div>

                  <div className="flex1">
                    <div className="square h-170"></div>
                  </div>
                  <div className="flex">
                    <div className="line h8 w50 mt-2 mr-3"></div>
                    <div className="line h8 w50 mt-2 ml-3"></div>
                  </div>

                  <div className="line mt-2 h10 w60"></div>
                  <div className="line mt-3 h8 w50"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SketetonGroup3;
