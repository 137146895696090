import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { EducationDetails } from "../../models/EducationalDetails";
import { AdminService } from "../../services/AdminService";
const classNames = require('classnames');
import { ReviewStatus } from "../../models/Report";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
import { Upload } from "../../models/Upload";
import { Util } from "../../Util";
import Spinner from "../../common/spinner/spinner";

const ViewEducationalDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [pendingEducationalDetails, setPendingEducationalDetails] = useState<EducationDetails[]>([]);
  const [approvedEducationalDetails, setApprovedEducationalDetails] = useState<EducationDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const LIMIT_P_EDUCATIONS = 10;
  const LIMIT_A_EDUCATIONS = 10;

  const [isOffset_pending_educations, setOffset_pending_educations] = useState<number>(0);
  const [seeMore_pending_educations, setSeeMore_pending_educations] = useState(false);

  const [isOffset_approved_educations, setOffset_approved_educations] = useState<number>(0);
  const [seeMore_approved_educations, setSeeMore_approved_educations] = useState(false);

  useEffect(() => {
    getAllApprovedEducationalDetails();
    getAllPendingEducationalDetails();
  }, [activeTab]);

  const getAllPendingEducationalDetails = () => {
    setIsLoading(true);
    AdminService.getAllPendingEducationalDetails(LIMIT_P_EDUCATIONS, isOffset_pending_educations).then(res => {
      if (res.success) {
        setPendingEducationalDetails(res.data);
        setSeeMore_pending_educations(res.data.length > 0 && res.data.length == LIMIT_P_EDUCATIONS);
        setIsLoading(false);
      }
      else {
        setPendingEducationalDetails([]);
        setIsLoading(false);
      }
    });
  };

  const pendingSeeMore = () => {
    setIsLoading(true);
    const updatedSkip = isOffset_pending_educations + LIMIT_P_EDUCATIONS;
    setOffset_pending_educations(updatedSkip);
    AdminService.getAllPendingEducationalDetails(LIMIT_P_EDUCATIONS, updatedSkip).then(res => {
      if (res.success) {
        setPendingEducationalDetails(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore_pending_educations(res.data.length > 0 && res.data.length == LIMIT_P_EDUCATIONS);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        setSeeMore_pending_educations(false);
      }
    });
  }

  const getAllApprovedEducationalDetails = () => {
    setIsLoading(true);
    AdminService.getAllApprovedEducationalDetails(LIMIT_A_EDUCATIONS, isOffset_approved_educations).then(res => {
      if (res.success) {
        setApprovedEducationalDetails(res.data);
        setSeeMore_approved_educations(res.data.length > 0 && res.data.length == LIMIT_A_EDUCATIONS);
        setIsLoading(false);
      } else {
        setApprovedEducationalDetails([]);
        setIsLoading(false);
      }
    });
  };

  const approvedSeeMore = () => {
    setIsLoading(true);
    const updatedSkip = isOffset_approved_educations + LIMIT_A_EDUCATIONS;
    setOffset_approved_educations(updatedSkip);
    AdminService.getAllApprovedEducationalDetails(LIMIT_A_EDUCATIONS, updatedSkip).then(res => {
      if (res.success) {
        setApprovedEducationalDetails(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore_approved_educations(res.data.length > 0 && res.data.length == LIMIT_A_EDUCATIONS);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSeeMore_approved_educations(false);
      }
    });
  }

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleReviewStatus = (educationId: string) => {
    AdminService.toggleEducationalDetailsReviewStatus(educationId).then(res => {
      if (res.success) {
        if (res.data.reviewStatus == ReviewStatus.APPROVED) {
          setPendingEducationalDetails(pendingEducationalDetails.filter((data: EducationDetails) => data._id !== educationId));
          Swal.fire({
            icon: "success",
            title: "Eductional details approved!",
            confirmButtonColor: "#FD7F00",
          });
        }
        if (res.data.reviewStatus == ReviewStatus.PENDING) {
          setApprovedEducationalDetails(approvedEducationalDetails.filter((data: EducationDetails) => data._id !== educationId));
          Swal.fire({
            icon: "success",
            title: "Eductional details set to pending!",
            confirmButtonColor: "#FD7F00",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to update the details.",
          confirmButtonColor: "#FD7F00",
        });
      }
    });
  };

  const downloadImage = (data: Upload) => {
    saveAs(Util.fileURL(data?._id), data?.originalName);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Educational Details"} breadcrumbItem={"Educational Details"} />

          <Card className="m-h">
            <CardBody>
              <Row>
                <Col xl="12">
                  <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-view-grid-outline"></i>
                        </span>

                        <span className="d-none d-sm-block">Pending Educational Details</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-format-list-bulleted"></i>
                        </span>

                        <span className="d-none d-sm-block">Approved Educational Details</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="table-responsive">
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                        {!isLoading ? (
                          <Col sm="12" className="mt10">
                            {pendingEducationalDetails && pendingEducationalDetails.length > 0 ? (
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">University</th>
                                    <th scope="col">Field of Study</th>
                                    <th scope="col">Start Year</th>
                                    <th scope="col">End Year</th>
                                    <th scope="col">Documents</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pendingEducationalDetails &&
                                    pendingEducationalDetails.map((data, index) => (
                                      <tr key={data?._id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {data?.userId?.firstname} {data?.userId?.lastname}
                                        </td>
                                        <td>{data?.university}</td>
                                        <td>{data?.fieldOfStudy}</td>
                                        <td>{data?.startYear}</td>
                                        <td>{data?.endYear}</td>
                                        <td>
                                          <img src={Util.fileType(data?.uploadId[0]?.extension, data?.uploadId[0]?._id)} width={40} height={40} onClick={() => downloadImage(data?.uploadId[0])} className="cursor-pointer" />
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-success btn-sm"
                                            onClick={() => {
                                              toggleReviewStatus(data?._id);
                                            }}
                                          >
                                            Approve
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>

                            )
                              : (
                                <>
                                  {
                                    isLoading ? (
                                      <Spinner />
                                    ) : (
                                      <div>No Pending Documents</div>
                                    )
                                  }
                                </>
                              )}
                          </Col>
                              ) : (
                                <Spinner />
                              )}
                        </Row>
                        <Row>
                          <Col xl="12 mt30 text-center">
                              {seeMore_pending_educations && (
                                  <button className="btn btn-warning" onClick={() => pendingSeeMore()}>
                                  See More
                                  </button>
                              )}
                          </Col>
                      </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                        {!isLoading ? (
                          <Col sm="12" className="mt10">
                            {approvedEducationalDetails.length > 0 ? (
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">University</th>
                                    <th scope="col">Field of Study</th>
                                    <th scope="col">Start Year</th>
                                    <th scope="col">End Year</th>
                                    <th scope="col">Documents</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {approvedEducationalDetails &&
                                    approvedEducationalDetails.map((data, index) => (
                                      <tr key={data._id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {data?.userId?.firstname} {data?.userId?.lastname}
                                        </td>
                                        <td>{data?.university}</td>
                                        <td>{data?.fieldOfStudy}</td>
                                        <td>{data?.startYear}</td>
                                        <td>{data?.endYear}</td>
                                        <td>
                                          <img src={Util.fileType(data?.uploadId[0]?.extension, data?.uploadId[0]?._id)} width={40} height={40} onClick={() => downloadImage(data?.uploadId[0])} className="cursor-pointer" />
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => {
                                              toggleReviewStatus(data?._id);
                                            }}
                                          >
                                            Reject
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            )
                              : (
                                <>
                                      <div>No Approved Documents</div>
                                </>
                              )}
                          </Col>
                              ) : (
                                <Spinner />
                              )}
                        </Row>
                        <Row>
                          <Col xl="12 mt30 text-center">
                              {seeMore_approved_educations && (
                                  <button className="btn btn-warning" onClick={() => approvedSeeMore()}>
                                  See More
                                  </button>
                              )}
                          </Col>
                      </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default ViewEducationalDetails;
