import { set } from "lodash";
import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { likertScale } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";
import { handleAmendment } from "./VersionPage";

const FormSixteen = (props: any) => {
    const { formId, assesmenData,customActiveTab, isVersionCreated, isVersionCreatedToday } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    });

    const [edit, setEdit] = useState<boolean>(true);

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 16,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                recoveryAssesmentScale: assesmenData.recoveryAssesmentScale
            }
            setFormData(updateAssesment);
        }
    }, [assesmenData,customActiveTab]);

    function closePopup() {
        props.closePopup();
    }

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 16,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            recoveryAssesmentScale: formData.recoveryAssesmentScale

        }

        if (isVersionCreated === false && isVersionCreatedToday === false) {
            handleAmendment({
                formId,
                updateAssesment,
                getAssesmentByClientId: () => {},
                nextStep: () => {},
                onModalRefresher: props.onModalRefresher,
                setIsVersionCreated: props.setIsVersionCreated,
                closePopup: closePopup,
            });
        } else {
            CommonService.updateClinicalAssesment(updateAssesment).then(res => {
                if (res.success) {
                    CommonService.updateClinicalAssesment(updateAssesment).then(res => {
                        if (res.success) {
                            toast.success("Assessment details are updated.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                            props.closePopup();
                            
                        } else {
                            toast.error(res.error, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                        }
                    });
                    
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            });
        }

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
            <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        Submit
                    </button>
                </div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>RECOVERY ASSESSMENT SCALE (24-ITEM)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div>
                                <h6 className="fw-500 mb-4" style={{ textAlign: "justify" }}><b>INSTRUCTIONS :</b>  Below is a list of statements that describe how people sometimes feel about  themselves and their lives. Please read each one carefully and circle the number to the right that  best describes the extent to which you agree or disagree with the statement. Circle only one  number for each statement and do not skip any items.</h6>
                            </div>
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-600" style={{ width: "70%", textAlign: "left", paddingLeft: "0.5rem" }}>Recovery Assessment Scale Items</td>
                                            <td className="fw-600" style={{ width: "30%" }}>Likert Scale</td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-500" style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    1. I have a desire to succeed.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        value={formData.recoveryAssesmentScale?.desireToSucceed || ''}
                                                        disabled={!edit}
                                                        onChange={e =>
                                                            setFormData({
                                                                ...formData, recoveryAssesmentScale: {
                                                                    ...formData.recoveryAssesmentScale, desireToSucceed: e.target.value as likertScale
                                                                }
                                                            })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    2. I have my own plan for how to stay or become  well.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.ownPlanForWellness}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, ownPlanForWellness: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    3. I have goals in life that I want to reach.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.lifeGoals}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, lifeGoals: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    4. I believe I can meet my current personal goals.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.beliefInMeetingGoals}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, beliefInMeetingGoals: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    5. I have a purpose in life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.senseOfPurpose}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, senseOfPurpose: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    6. Even when I don’t care about myself, other  people do.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.othersCaringmySelf}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, othersCaringmySelf: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    7. Fear doesn’t stop me from living the way I want  to.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.fearlessLiving}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, fearlessLiving: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    8. I can handle what happens in my life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.handleLifeSituations}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, handleLifeSituations: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    9. I like myself.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.selfLove}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, selfLove: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    10. If people really knew me, they would like me.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.peopleLikeMe}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, peopleLikeMe: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    11. I have an idea of who I want to become
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.ideaWhoWantToBe}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, ideaWhoWantToBe: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    12. Something good will eventually happen.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.somethingGoodHappen}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, somethingGoodHappen: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    13. I’m hopeful about my future
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.hopefulness}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, hopefulness: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    14. I continue to have new interests.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.continueInterests}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, continueInterests: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    15. Coping with my mental illness is no longer the  main focus of my life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.mentalIllnessCoping}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, mentalIllnessCoping: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    16. My symptoms interfere less and less with my life.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.symptomInterference}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, symptomInterference: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    17. My symptoms seem to be a problem for shorter  periods of time each time they occur.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.symptomDuration}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, symptomDuration: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    18. I know when to ask for help.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.helpSeekingAwareness}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, helpSeekingAwareness: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    19. I am willing to ask for help.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.willingnessToAskForHelp}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, willingnessToAskForHelp: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    20. I ask for help when I need it.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.askingHelpWhenNeed}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, askingHelpWhenNeed: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    21. I can handle stress.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.stressHandling}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, stressHandling: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    22. I have people I can count on.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.peaopleCanCounton}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, peaopleCanCounton: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    23. Even when I don’t believe in myself, other people  do.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.otherPeopleBelive}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, otherPeopleBelive: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    24. It is important to have a variety of friends.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <select className="submissionForm"
                                                        disabled={!edit}
                                                        value={formData.recoveryAssesmentScale?.importanceOfFriendship}
                                                        onChange={e => setFormData({ ...formData, recoveryAssesmentScale: { ...formData.recoveryAssesmentScale, importanceOfFriendship: e.target.value as likertScale } })}
                                                    >
                                                        <option value={likertScale.STRONGLYDISAGREE}>Strongly Disagree</option>
                                                        <option value={likertScale.DISAGREE}>Disagree</option>
                                                        <option value={likertScale.NOTSURE}>Not Sure</option>
                                                        <option value={likertScale.AGREE}>Agree</option>
                                                        <option value={likertScale.STRONGLYAGREE}>Strongly Agree</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div >
                </Row >

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        {/* <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            {props.totalSteps > 17 ? "Next" : "Skip"}
                        </button> */}
                        {/* {edit &&  */}
                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();

                                }}
                            >
                                Submit
                            </button>
                        {/* } */}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 16 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card >
        </>
    );
}

export default FormSixteen;