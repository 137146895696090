import React, { useState, useRef, useCallback, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Device } from "@twilio/voice-sdk";
import { TwilioCallService } from "./../../services/TwilioCallService";
import AddNewSessionTwilioCallModal from "./../Popup/AddNewSessionTwilioCall";
import images from "../../assets/images";
import CloseIcon from "@material-ui/icons/Close";

interface CustomModalProps {
  isOpen: boolean;
  toggle: () => void;
  phoneNumber: string | undefined;
  recieverId: string;
  recieverName: string;
}

const TwilioCall: React.FC<CustomModalProps> = ({ isOpen, toggle, phoneNumber, recieverId, recieverName }) => {
  const [device, setDevice] = useState<Device | null>(null);
  const [connection, setConnection] = useState<any | null>(null);
  const [userState, setUserState] = useState<string>("READY");
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const callStartTimeRef = useRef<number | null>(null);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [isCalling, setIsCalling] = useState<boolean>(false);
  const [callDuration, setCallDuration] = useState<number>(0);
  const [isCallOver, setIsCallOver] = useState(false);
  const [isOpenTwilioSessionPopup, setIsOpenTwilioSessionPopup] = useState(false);
  const [isCallAcceped, setIsCallAcceped] = useState(false);
  const [callStartTime, setCallStartTime] = useState(new Date());
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    console.log("call use effect internet connections");
    const handleOnline = () => {
      console.log("Internet connection restored");
      setIsOffline(false);
      // Additional logic to handle reconnection
    };

    const handleOffline = () => {
      console.log("Internet connection lost");
      setIsOffline(true);
      // Additional logic to handle disconnection
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const getToken = async () => {
    try {
      const response = await TwilioCallService.getTwilioToken(recieverName);
      const token = response.data;
      return token;
    } catch (error) {
      return "get token failed";
    }
  };

  const setupDevice = useCallback(async () => {
    const token = await getToken();
    if (!token) {
      console.error("Failed to setup device: No token available");
      return;
    }

    setToken(token as string);
    const device = new Device(token as string, { logLevel: 1, edge: "singapore", tokenRefreshMs: 6000000 });

    device.on("connect", (conn: any) => {
      setConnection(conn);
      if (callStartTimeRef.current) {
        timerIntervalRef.current = setInterval(() => {
          setCallDuration(Math.floor((Date.now() - callStartTimeRef.current!) / 1000));
        }, 1000);
      }
      setUserState("ON_CALL");
      setIsCallAcceped(true);
      console.log("Connected");
    });
    device.on("tokenWillExpire", async () => {
      console.log("Token will expire soon. Fetching new token...");
      try {
        const newToken = await getToken();
        setToken(newToken as string);
        device.updateToken(newToken as string);
        console.log("Token updated successfully.");
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    });

    device.on("tokenExpired", async () => {
      console.log("token  expired");
      const newToken = await getToken();
      device.updateToken(newToken as string);
    });

    setDevice(device);

    return device;
  }, [setToken, setDevice, setConnection, setCallDuration, setUserState, setIsCallAcceped, setIsCalling, callStartTimeRef, timerIntervalRef]);

  const makeCall = async () => {
    try {
      const device = await setupDevice();

      setCallStartTime(new Date());
      if (!phoneNumber || !device) {
        setError("Device or phone number not found");
        console.log("device not found");
        return;
      }

      setIsCalling(true);
      setCallDuration(0);
      callStartTimeRef.current = Date.now();

      const call = await device.connect({
        params: { To: phoneNumber },
        rtcConstraints: { audio: true },
      });

      setConnection(call);

      call.on("accept", () => {
        if (callStartTimeRef.current) {
          timerIntervalRef.current = setInterval(() => {
            setCallDuration(Math.floor((Date.now() - callStartTimeRef.current!) / 1000));
          }, 1000);
        }
        setUserState("ON_CALL");
        setIsCallAcceped(true);
        console.log("Connected");
      });

      call.on("disconnect", () => {
        console.log("The call has been disconnected.");
        setConnection(null);
        setIsCalling(false);
        setUserState("READY");
        setIsCallOver(true);
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }
      });

      call.on("ringing", () => {
        console.log("call is ringing");
        setUserState("RINGING");
      });
      call.on("reject", () => {
        console.log("The call was rejected.");
      });
      call.on("error", error => {
        console.error("Call error:", typeof error);
        console.error("Call error:", error.message);
        setError(error.message);
        setIsCalling(false);
        setCallDuration(0);
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }
      });
    } catch (error) {
      setIsCalling(false);
      console.error("Error making call:", error);
    }
  };

  const hangUpCall = () => {
    if (connection) {
      connection.disconnect();
      setConnection(null);
      setIsCalling(false);
      setCallDuration(0);
      setIsCallOver(true);
    } else {
      console.error("No active call to hang up");
    }
  };

  const muteCall = () => {
    if (connection) {
      console.log("calls muted");
      connection.mute(true);
      setIsMuted(true);
    }
  };

  const unmuteCall = () => {
    if (connection) {
      console.log("calls unmuted");
      connection.mute(false);
      setIsMuted(false);
    }
  };

  const closePopup = () => {
    if (connection) {
      connection.disconnect();
      setConnection(null);
      setIsCalling(false);
      setCallDuration(0);
      setIsCallOver(true);
    }

    toggle();
  };

  const closeBtn = (
    <button style={{ background: "none", border: "none", color: "white", fontSize: "15px", cursor: "pointer" }} onClick={closePopup} type="button">
      <CloseIcon />
    </button>
  );

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <div style={{ height: "100%" }}>
          <ModalHeader
            style={{
              background: "linear-gradient(90deg, #F69439 0%, #F582E3 100%)",
            }}
            toggle={toggle}
            close={closeBtn}
          ></ModalHeader>
          <ModalBody
            style={{
              height: "50%",
              width: "100%",
              padding: 0,
              marginTop: "-5px",
              boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
              paddingBottom: "20px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex", width: "100%", alignItems: "center", background: "linear-gradient(90deg, #F69439 0%, #F582E3 100%)" }}>
              <img
                src={images.twilio_call} // replace with the path to your icon
                alt="Call Icon"
                style={{ marginLeft: "10px", marginBottom: "5px" }}
              />
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "white",
                  textAlign: "left",
                }}
              >
                {" "}
                Phone Call : {recieverName}
              </p>
            </div>
            <p style={{ paddingLeft: "25px", paddingTop: "10px" }}>Call Status: {userState}</p>
            {isCalling && isCallAcceped && (
              <div>
                <p style={{ paddingLeft: "25px" }} className="text-[20px]">
                  Call Duration: {callDuration} seconds
                </p>
              </div>
            )}

            {error && (
              <div>
                <p style={{ paddingLeft: "25px", color: "red" }} className="text-[20px]">
                  Error: {error}
                </p>
              </div>
            )}

            {isCallOver && isCallAcceped && (
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: "25px" }}>
                <span style={{ fontWeight: "bold", fontSize: "15px", textDecoration: "underline", paddingBottom: "5px" }}>Call Summary</span>
                <p style={{ fontSize: "13px" }}>Do you wish to add this call as a Session call?</p>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Button
                    outline
                    onClick={() => {
                      // toggle();
                      setIsOpenTwilioSessionPopup(true);
                    }}
                    style={{ color: "primary" }}
                  >
                    Session Call
                  </Button>
                  <span style={{ paddingLeft: "10px", paddingRight: "10px", fontWeight: "bold", fontSize: "15px" }}>Or</span>
                  <Button
                    outline
                    onClick={() => {
                      toggle();
                    }}
                    style={{ marginRight: "10px", color: "primary" }}
                  >
                    Normal Call
                  </Button>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {!isCalling && (
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
                disabled={isOffline}
                color="primary"
                onClick={() => makeCall()}
              >
                {isCallOver && isCallAcceped ? "Call Again" : "Call"}
                {/* <i style={{ color: "white", marginLeft: "5px" }} className="mdi mdi-phone font-size-20"></i> */}
                <img style={{ paddingLeft: "5px" }} src={images.call} alt="call" />
              </Button>
            )}{" "}
            {connection && (
              <Button
                style={{
                  backgroundColor: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
                // color="white"
                onClick={() => hangUpCall()}
              >
                Decline
                {/* <i className="mdi mdi-phone-hangup font-size-20" style={{ color: "white", marginLeft: "5px" }}></i> */}
                <img style={{ paddingLeft: "5px" }} src={images.decline_call} alt="decline" />
              </Button>
            )}
            {/* <Button color="secondary" onClick={toggle}>
          Cancel
        </Button> */}
            {isCallAcceped && connection && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={isMuted ? unmuteCall : muteCall}
                disabled={!connection}
              >
                {/* <i style={{ color: "gray" }} className={`mdi ${isMuted ? "mdi-microphone-off" : "mdi-microphone"} font-size-24`}></i> */}
                {isMuted ? (
                  <img style={{ paddingLeft: "5px", color: "gray" }} src={images.chat_mute} alt="decline" />
                ) : (
                  <img style={{ paddingLeft: "5px", color: "gray" }} src={images.mic} alt="decline" />
                )}{" "}
              </Button>
            )}
          </ModalFooter>
        </div>
      </Modal>
      {isOpenTwilioSessionPopup && (
        <AddNewSessionTwilioCallModal
          addNewSessionPopUp={isOpenTwilioSessionPopup}
          userId={recieverId}
          userName={recieverName}
          setAddNewSessionPopUp={() => setIsOpenTwilioSessionPopup(!isOpenTwilioSessionPopup)}
          callStartTime={callStartTime}
        />
      )}
    </>
  );
};

export default TwilioCall;
