import React, { useEffect, useState } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import FormOne from "./FormOne";
import { TherapyPlan } from "src/models/therapyPlan/therapyPlan";
import { CommonService } from "src/services/CommonService";
import classNames from "classnames";
import { AdminService } from "src/services/AdminService";

toast.configure();

const TherapyPlanPage: React.FC<{
  identifierId: any;
  isAdmin: boolean;
  isCreatingNewForm: boolean;
  closePopup: () => void;
  setModalRefresher: ((value: boolean) => void) | null;
}> = props => {
  const [customActiveTab, setcustomActiveTab] = useState(0);
  const [formId, setFormId] = useState<string>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [therapyPlanData, setTherapyPlanData] = useState<TherapyPlan>();
  const [clientId, setClientId] = useState<string>();
  const [isVersionCreated, setIsVersionCreated] = useState(false);
  const identifierId = props.identifierId;
  const isAdmin = props.isAdmin;
  const isCreatingNewForm = props.isCreatingNewForm;

  const toggleCustom = (tab: number) => {
    setcustomActiveTab(tab);
  };

  function getUpdateFormId(formId: string) {
    setFormId(formId);
  }

  useEffect(() => {
    getTherapyPlanData();
  }, [identifierId, isAdmin]);

  useEffect(() => {
    setFormId(therapyPlanData?._id);
  }, [therapyPlanData]);

/*  const previousStep = () => {
    setcustomActiveTab(customActiveTab - 1);
  };

  const nextStep = () => {
    setcustomActiveTab(customActiveTab + 1);
  };*/

  const getTherapyPlanData = async () => {
    try {
      const data = { therapyPlanId: identifierId };
      let res;

      if (isAdmin) {
        res = await AdminService.getTherapyPlanDetailsByAdmin(data);
      } else if (!isCreatingNewForm) {
        res = await CommonService.getTherapyPlan(data);
      }

      if (res?.success && res.data) {
        setIsUpdate(true);
        setTherapyPlanData(res.data);
        setFormId(therapyPlanData?._id);
        setClientId(res?.data?.clientId);
      } else {
        setClientId(identifierId);
        setIsUpdate(false);
        if (!isCreatingNewForm) {
          toast.success("No matching therapy plan found. This is a fresh form!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success("This is a fresh form!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };


  function modalRefresher() {
    props.setModalRefresher?.(true);
  }

  return (
    <React.Fragment>
      <div className="page-content mt-0" style={{ padding: 0 }}>
        <Container className="containerOnboarding" style={{ padding: 0 }}>
          <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
            <Nav pills style={{ marginTop: "30px", justifyContent: "center", paddingLeft: "12px" }} className="col-12 d-none d-xl-flex">
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 0 })}
                  onClick={() => {
                    toggleCustom(0);
                  }}
                >
                  Page 1
                </NavLink>
              </NavItem>
              {/* <NavItem className="border">
                  <NavLink
                    className={classNames("my-pagination", { active: customActiveTab === 1 })}
                    onClick={() => {
                      toggleCustom(1);
                    }}
                  >
                    Page 2
                  </NavLink>
                </NavItem> */}
            </Nav>

            <Nav pills style={{ marginTop: "30px", justifyContent: "center", paddingLeft: "12px" }} className="col-12 d-xl-none">
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 0 })}
                  onClick={() => {
                    toggleCustom(0);
                  }}
                >
                  1
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1);
                  }}
                >
                  2
                </NavLink>
              </NavItem>
            </Nav>

            <Col>
              <div>
                <h5 className="font-size-25 profileFont mt-4 text-center">THERAPY PLAN</h5>
              </div>
              <StepWizard>
                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId={0}>
                    <FormOne
                      formId={formId}
                      getUpdateFormId={getUpdateFormId}
                      clientId={clientId}
                      isUpdate={isUpdate}
                      therapyPlanData={therapyPlanData}
                      isVersionCreated={isVersionCreated}
                      setIsVersionCreated={setIsVersionCreated}
                      onModalRefresher={modalRefresher}
                      closePopup={() => props.closePopup()}
                    />
                  </TabPane>
                  {/* <TabPane tabId={1}>
                      <FormTwo
                        formId={formId}
                        clientId={clientId}
                        therapyPlanData={therapyPlanData}
                        getTherapyPlanByClientId={getTherapyPlanData}
                        previousStep={previousStep}
                        isVersionCreated={isVersionCreated}
                        setIsVersionCreated={setIsVersionCreated}
                        closePopup={() => props.closePopup()}
                        onModalRefresher={modalRefresher}
                      />
                    </TabPane> */}
                </TabContent>
              </StepWizard>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TherapyPlanPage;
