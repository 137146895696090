import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import store from "./store";

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(app);

serviceWorker.unregister();