import React, { useContext, useState, useEffect, useRef } from "react";
import { CardTitle, Col, Row, Modal, CardBody, CardFooter } from "reactstrap";
import UserContext from "../../context/UserContext";
import { UserRole } from "../../models/User";
import Select from "react-select";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "../../models/InsuranceCompany";
import { ClientService } from "../../services/ClientService";
import { Insurance } from "../../models/Insurance";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import "react-toastify/dist/ReactToastify.css";
import { Upload } from "../../models/Upload";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Util } from "../../Util";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import Modal21 from "./Modal21";

const Modal20: React.FC<{ showModal20: boolean; setShowModal20: (value: boolean) => void }> = props => {
    const [user] = useContext(UserContext);
    const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
    const [selecteInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag>({} as SelectedInsuranceCompanyTag);
    const [isLoading, setIsLoading] = useState(true);
    const [insuranceMainData, setInsuranceMainData] = useState<Insurance>({} as Insurance);
    const [insuranceCard, selectInsuranceCard] = useState({ preview: "", preview2: "", raw: "" });
    const insuranceCardRef = useRef<any>();
    insuranceCardRef.current = insuranceCard;

    const [insuranceCardBack, selectInsuranceCardBack] = useState({ preview: "", preview2: "", raw: "" });
    const insuranceCardBackRef = useRef<any>();
    insuranceCardBackRef.current = insuranceCardBack;
    const [showModal21, setShowModal21] = useState(false);
    const [showMainModal, setShowMainModal] = useState(true);
    const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];

    useEffect(() => {
        viewInsuranceCompanyById();
        getAllInsuranceCompanies();
    }, []);

    const viewInsuranceCompanyById = () => {
        ClientService.ViewInsuranceByClientId(user?._id).then((res) => {
            setIsLoading(false);
            setInsuranceMainData(res.data)
            setSelectedInsuranceCompanyTag({ ...selecteInsuranceCompanyTag, ...{ value: res?.data?.insuranceCompanyId.insuranceCompany, label: res?.data?.insuranceCompanyId?.insuranceCompany.toLowerCase() } })
        });
    }

    const getAllInsuranceCompanies = () => {
        const data: any = {
            state: user?.state
        }
        InsuranceCompanyService.getInsuaranceCompanyByState(data).then((res: any) => {
            if (res.success) {
                const tagList = res.data.map((tag: any) => {
                    return { value: tag._id, label: tag.insuranceCompany };
                });
                setSearchInsuranceCompanyTag(tagList);
            } else {
                setSearchInsuranceCompanyTag([]);
            }
        });
    };

    const isSupported = (array: string[], extension: string) => {
        let isFound = false;

        array.map((item: string) => {
            if (item === extension) {
                isFound = true;
            }
            return item;
        });

        return isFound;
    };

    const selectInsuranceFile = (e: any) => {
        const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, e.target.files[0].name.split(".").pop().toLowerCase());
        if (isAnImage) {
            if (e.target.files.length) {
                selectInsuranceCard({
                    preview: URL.createObjectURL(e.target.files[0]) as any,
                    preview2: e.target.files[0],
                    raw: e.target.files
                });
            }
        } else {
            toast.error("File type is not supported!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });

            return false;
        }
    };

    const selectInsuranceBackFile = (e: any) => {
        const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, e.target.files[0].name.split(".").pop().toLowerCase());
        if (isAnImage) {
            if (e.target.files.length) {
                selectInsuranceCardBack({
                    preview: URL.createObjectURL(e.target.files[0]) as any,
                    preview2: e.target.files[0],
                    raw: e.target.files
                });
            }
        } else {
            toast.error("File type is not supported!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });

            return false;
        }
    };



    const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
        if (file) {
            switch (file.extension || undefined || null) {
                case ".PNG":
                case ".png":
                    return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
                case ".docx":
                case ".DOCX":
                    return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
                case ".pdf":
                case ".PDF":
                    return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
                case ".txt":
                case ".TXT":
                    return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
                case ".mp4":
                    return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
                case ".mkv":
                    return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
                case ".jpg":
                case ".JPG":
                case ".JPEG":
                case ".jpeg":
                case ".tiff":
                case ".TIFF":
                case ".gif":
                case ".GIF":
                    return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
                default:
                    return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
            }
        } else {
            return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
        }
    }

    const createOption = (label: string) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const insuranceNext = () => {
        if (!selecteInsuranceCompanyTag.label) {
            toast.error("Organization Name id is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        } else {
            setShowMainModal(false);
            setShowModal21(true);
        }


    };

    const handleCreate = (inputValue: string) => {
        if (inputValue.length > 0) {
            if (!/^[A-Za-z\s- ]*$/.test(inputValue[inputValue.length - 1])) {
                return toast.error("Only letters are allowed!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }

            const newOption = createOption(inputValue);
            const tagList: any = newOption
            searchInsuranceCompanyTag.push(tagList);
        }
    };
    return props.showModal20 ? (
        <>
            {showMainModal &&
                <CardBody>

                    {
                        user?.role == "CLIENT" ? (
                            <>
                                <CardTitle className="padding-l-r font-size-18">How would you like to pay for the appointment?</CardTitle>
                                <p className="mb-5 padding-l-r">/ My Insurance Provider</p>

                                <div className="padding-l-r">
                                    <Row>
                                        <Col xl="10">

                                            <div>
                                                <div className="card-heading mb-2">
                                                    <h5 className="font-size-14 profileFont mb-2">Select Insurance Provider</h5>
                                                </div>
                                                <div className="card-heading mb-4">
                                                    <Select
                                                        placeholder="Select Insurance"
                                                        onChange={(newValue: any) => setSelectedInsuranceCompanyTag(newValue)}
                                                        options={searchInsuranceCompanyTag}
                                                        className="creatableEdit mb-4"
                                                        required

                                                    />
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Upload Picture of Insurance Card</h5>
                                        </div>
                                        <Col xl="3">
                                            {insuranceMainData?.insuranceCardId != null &&
                                                <div className="flex  mb-2">
                                                    <div className="mr-2">
                                                        <Link to={{ pathname: Util.fileURL(insuranceMainData?.insuranceCardId?._id) }} target="_blank" className="events-icon2" style={{ fontSize: "62px" }}>
                                                            <FileThumbnail file={insuranceMainData?.insuranceCardId as any} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            }

                                            {insuranceCardRef.current.preview ? (
                                                <div>
                                                    {(insuranceCardRef.current.preview) && (
                                                        <label htmlFor="file-upload1" style={{ border: '1px dashed' }} className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">

                                                            <div>
                                                                <div className="flex flex-wrap items-center p-4t p-4b">
                                                                    <div style={{ fontSize: "80px" }}>
                                                                        <div className="upload-img">
                                                                            <img src={insuranceCardRef.current.preview} className="upload-img-preview" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <input id="file-upload1"
                                                                type="file"
                                                                style={{ display: "none" }}
                                                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                                                onChange={selectInsuranceFile}
                                                                multiple />

                                                        </label>
                                                    )}

                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {!insuranceCardRef.current.preview && (

                                                <label htmlFor="file-upload1" style={{ border: '1px dashed' }} className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                                    <div className="p-4t mb-2">
                                                        <p className="m-auto text-center ">Front Side</p>
                                                    </div>
                                                    <div className="p-4b text-center">
                                                        <span className="btn btn-sm text-center chooseBtn">Choose</span>
                                                    </div>


                                                    <input id="file-upload1"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        className="file_upload_input mb-4"
                                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                                        onChange={selectInsuranceFile}
                                                        multiple />
                                                </label>
                                            )}
                                        </Col>

                                        <Col xl="3">
                                            {insuranceMainData?.insuranceCardBackId != null &&
                                                <div className="flex  mb-2">
                                                    <div className="mr-2">
                                                        <Link to={{ pathname: Util.fileURL(insuranceMainData?.insuranceCardBackId?._id) }} target="_blank" className="events-icon2" style={{ fontSize: "62px" }}>
                                                            <FileThumbnail file={insuranceMainData?.insuranceCardBackId as any} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            }

                                            {insuranceCardBackRef.current.preview ? (
                                                <div>
                                                    {(insuranceCardBackRef.current.preview) && (
                                                        <label htmlFor="file-upload4" style={{ border: '1px dashed' }} className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                                            <div>
                                                                <div className="flex flex-wrap items-center p-4t p-4b">
                                                                    <div style={{ fontSize: "80px" }}>
                                                                        <div className="upload-img">
                                                                            <img src={insuranceCardBackRef.current.preview} className="upload-img-preview" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <input id="file-upload4"
                                                                type="file"
                                                                style={{ display: "none" }}
                                                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                                                onClick={selectInsuranceBackFile}
                                                                multiple />
                                                        </label>
                                                    )}

                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {!insuranceCardBackRef.current.preview && (

                                                <label htmlFor="file-upload15" style={{ border: '1px dashed' }} className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">

                                                    <div className="p-4t mb-2">
                                                        <p className="m-auto text-center ">Back Side</p>
                                                    </div>
                                                    <div className="p-4b text-center">
                                                        <span className="btn btn-sm text-center chooseBtn">Choose</span>
                                                    </div>

                                                    <input id="file-upload15"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        className="file_upload_input mb-4"
                                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                                        onChange={selectInsuranceBackFile}
                                                        multiple />
                                                </label>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="padding-l-r mb-5">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => insuranceNext()}>
                                        Continue &nbsp;
                                        <i className="fas fa-regular fa-arrow-right "></i>
                                    </button>
                                </div>
                            </>

                        ) : (
                            <CardTitle className="mb-4 text-center font-size-20">This client doesn&lsquo;t have an active subscription.</CardTitle>
                        )
                    }

                </CardBody>
            }
            {showModal21 && <Modal21 setShowModal21={setShowModal21} showModal21={showModal21} addOrganizationData={selecteInsuranceCompanyTag.value} insuranceCardImage={insuranceCardRef.current.raw} insuranceCardImageBack={insuranceCardBackRef.current.raw} />}
        </>
    ) : null;
};

export default Modal20;
