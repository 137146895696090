import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import profileBackground from "../../../assets/images/default_cover.png";
import user1 from "../../../assets/images/default_profile.png";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import videoIcon from "../../../assets/images/icon_video.svg";
import { Ethinicity } from "src/models/Ethnicity";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { CommonService } from "src/services/CommonService";
import { ExperienceTag, SelectedExperienceTag } from "src/models/ExperienceTag";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "src/models/InsuranceCompany";
import { WorkingHour } from "src/models/WorkingHour";
import axios from "axios";
import CustomTimePicker2 from "src/components/CustomTimePicker2";
import { Qualification } from "src/models/Qualification";
import { Tags } from "src/common/custom-components/Tags";
import { License } from "src/models/License";
import Creatable from "react-select/creatable";
import Select from "react-select";
import { TherapistService } from "src/services/TherapistService";
import { ArticleService } from "src/services/ArticleService";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import UserContext from "../../../context/UserContext";
import { ParameterTypes } from "../../../utils/ParameterTypes";
import { Profession, ProfessionLicense } from "../../../models/Profession";
import { UserDetails, UserDetails2 } from "../../../models/User";
import { Upload } from "../../../models/Upload";
import { SaasTherapistService } from "../../../services/SaasTherapistService";
import getCroppedImgAsFile from "../../Profile/cropImage";
import { Util } from "../../../Util";
import { environment } from "../../../environment/environment";

type viewUserProps = {
  clientId: string;
};

const ViewProfileSaas: React.FC<viewUserProps> = ({ clientId }) => {
  const [user, setUser] = useContext(UserContext);
  const [userDetails, setUserDetails] = useState<UserDetails>({});
  const [modalcentercovertheme, setModalCenterCoverTheme] = React.useState(false);
  const { userId } = useParams<ParameterTypes>();
  const history = useHistory();
  const [editProfile, setShowEditProfile] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [setEthenicity, setUpdateEthenacity] = useState("");
  const [ethinicityList, setEthinicityList] = useState<Ethinicity[]>([]);
  const [isUpating, setIsUpating] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("Eastern Daylight Time");
  const [selectedExpTags, setSelectedExpTags] = useState<SelectedExperienceTag[]>([] as SelectedExperienceTag[]);
  const [selecteInsuranceCompanyTags, setSelectedInsuranceCompanyTags] = useState<SelectedInsuranceCompanyTag[]>([] as SelectedInsuranceCompanyTag[]);
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([] as WorkingHour[]);
  const [selectedProfessionsLicensDetails, setSelectedProfessionsLicensDetails] = useState({} as ProfessionLicense);
  const [profession, setProfession] = useState({} as Profession);
  const [expereinceYears, setExpereinceYears] = useState(0);
  const [idDateValue, setIdDateValue] = useState(null);
  const [dogDateValue, setDogDateValue] = useState(null);
  const [edDateValue, setEdDateValue] = useState(null);
  const [medDateValue, setMedDateValue] = useState(null);
  const [isgoogleCalendarAccess, setgoogleCalendarAccess] = useState(user?.googleCalendarAccess);
  const [progress, setProgress] = useState(0);
  const [modalcentervideo, setModalCenterVideo] = React.useState(false);
  const [modalcentercoverimage, setModalCenterCoverImage] = React.useState(false);
  const [themeImages, setThemeImages] = useState<Upload[]>([] as Upload[]);
  const [statementFile, setStatementFile] = useState({ preview: "", preview2: "", raw: "" });
  const statementFileRef = useRef<any>();
  statementFileRef.current = statementFile;
  const [newWorkingHour, setNewWorkingHour] = useState({
    startTime: "0:00 AM",
    endTime: "0:00 AM",
    day: "",
  });
  type Value = Date | string | null;
  const [startTimeValue, setStartTimeValue] = useState<string | null>(null);
  const [endTimeValue, setEndTimeValue] = useState<string | null>(null);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [selectedDates, setSelectedDates] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
  const [educationId, setEducationId] = useState("");
  const educationPopupId = useRef<any>();
  educationPopupId.current = educationId;
  const [educationData, setEducationData] = useState<Qualification>({} as Qualification);
  const [uploads, setUploads] = useState<Upload[]>([] as Upload[]);
  const uploadsRef = useRef<any>();
  uploadsRef.current = uploads;
  const [licensesDetailsList, setLicensesDetailsList] = useState<License[]>([] as License[]);
  const licensesDetailsRef = useRef<any>();
  licensesDetailsRef.current = licensesDetailsList;
  const [licensesId, setLicensesId] = useState("");
  const licensesPopupId = useRef<any>();
  licensesPopupId.current = licensesId;
  const [licensesData, setLicensesData] = useState<License>({} as License);
  const licensesRef = useRef<any>();
  licensesRef.current = licensesData;
  const [modalCenterWorkingHours, setModalCenterWorkingHours] = useState(false);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [professionsLicensDetails, setProfessionsLicensDetails] = useState<ProfessionLicense[]>([] as ProfessionLicense[]);
  const selectedProfessions = professionsLicensDetails?.filter(c => c.professionId === profession?._id);
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [educationsDetailsList, setEducationsDetailsList] = useState<Qualification[]>([] as Qualification[]);
  const [selectTherapyTags, setSelectedTherapyTags] = useState<any[]>([] as any[]);
  const [searchTherapyTags, setSearchTherapyTags] = useState<any[]>([] as any[]);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [image2, setImage2] = useState<File | null>(null);
  const thirdData = useRef<any>();
  thirdData.current = image;

  useEffect(() => {
    viewProfileByUserId(clientId);
  }, [clientId]);

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthinicityList(res.data);
    });

    TherapistService.getProfessions().then(res => {
      setProfessionsDetails(res.data);
    });

    TherapistService.getProfessionLicense().then(res => {
      setProfessionsLicensDetails(res.data);
    });

    viewAllEducations();
    viewAllLicenses();
    getAllExperienceTags();
    viewAllThemeImages();
    getAllInsuranceCompanies();

    const workingHours = userDetails?.workingHours;
    if (workingHours) {
      setWorkingHours(workingHours);
    }

    const insuranceArr = userDetails?.insuranceCompanies?.map((tag: { insuranceCompany: any; _id: any }) => {
      return { label: tag.insuranceCompany, value: tag._id };
    });

    if (insuranceArr) {
      setSelectedInsuranceCompanyTags(insuranceArr);
    }

    const experienceTagArr = userDetails?.experiencedIn?.map((tag: { experienceTag: any; _id: any }) => {
      return { label: tag.experienceTag.trim(), value: tag._id.trim() };
    });

    if (experienceTagArr) {
      setSelectedExpTags(experienceTagArr);
    }

    if (userDetails && userDetails.therapyState) {
      const updatedTags = userDetails.therapyState.map((tag: string) => {
        return { label: tag };
      });

      setSelectedTherapyTags(updatedTags);
      setSearchTherapyTags(searchTherapyTags.filter(tagObj => !updatedTags.find((updatedTag: { label: any }) => updatedTag.label === tagObj.label)));
    }
  }, [userDetails]);

  const TherapyState = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "Ohio",
    "Montana",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington DC",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    const option = TherapyState.map((state: any) => ({ value: state, label: state }));
    setSearchTherapyTags(option);
  }, []);

  const handleTherapyStateTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any }) => {
      return { label: tag.label };
    });

    if (updatedTags) {
      setSelectedTherapyTags(updatedTags);
      setSearchTherapyTags(searchTherapyTags.filter(tagObj => !updatedTags.find((updatedTag: { label: any }) => updatedTag.label === tagObj.label)));
    }
  };

  const editorConfiguration = {
    toolbar: {
      items: ["heading", "|", "bold", "italic", "|", "bulletedList", "numberedList", "|", "imageUpload", "blockQuote", "|", "undo", "redo"],
    },
  };

  const removeTherapyStateTag = (removedtag: any) => {
    setSelectedTherapyTags(selectTherapyTags.filter(tagObj => tagObj.label !== removedtag));

    const addTags = { value: removedtag, label: removedtag };
    if (addTags) {
      setSearchTherapyTags([...searchTherapyTags, addTags]);
    }
  };

  function togCenterVideo() {
    setModalCenterVideo(!modalcentervideo);
  }

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }

  const db = moment.utc(dateValue || userDetails?.dateOfBirth).format("YYYY-MM-DD");
  const id = moment(idDateValue || user?.issueDate).format("YYYY-MM-DD");
  const dog = moment(dogDateValue || user?.dateOfGraduation).format("YYYY-MM-DD");
  const ed = moment(edDateValue || user?.expirationDate).format("YYYY-MM-DD");
  const med = moment(medDateValue || user?.malpracticeExpirationDate).format("YYYY-MM-DD");

  function handlePostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setUserDetails({ ...userDetails, state: selectedState ? selectedState : "", zipCode: e });
  }

  let state;

  function viewProfileByUserId(userId: string) {
    SaasTherapistService.viewProfileByUserId(userId).then(res => {
      if (res.success) {
        setUserDetails(res.data);
        setProfession({ _id: res.data?.profession?._id, name: res.data?.profession?.name });
      } else {
        setUserDetails({});
      }
    });
  }

  function getStateByZipCode(zipString: string) {
    if (typeof zipString !== "string") {
      toast.error("Must pass the zipcode as a string.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (zipString.length !== 5) {
      return false;
    }

    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      state = "Colorado";
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      state = "Florida";
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      state = "Maryland";
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode == 5501 || zipcode == 5544) {
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      state = "New Mexico";
    } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      state = "Ohio";
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      state = "Tennessee";
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      state = "Vermont";
    } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode == 20598) {
      state = "Virginia";
    } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      state = "Wyoming";
    } else {
      state = "";
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    return state;
  }

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  function togCenterCoverImage() {
    setModalCenterCoverImage(!modalcentercoverimage);
    setModalCenterCoverTheme(false);
  }

  function togCenterCoverTheme() {
    setModalCenterCoverTheme(!modalcentercovertheme);
  }

  const imageTypeRegex = /image\/(png|jpg|jpeg|gif|bmp|tif|tiff|eps)/gm;

  const handleZoomChange = (event: any, newZoom: any) => {
    setZoom(newZoom);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCrop = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      const croppedImageNew = await getCroppedImgAsFile(image2, croppedArea, croppedAreaPixels);
      setCroppedImage(croppedImageNew as File | null);
    } catch (error) {
      console.error("Error capturing cropped image:", error);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && typeof event.target.result === "string") {
          setImage({ preview: event.target.result, raw: "" });
          setImage2(e.target.files?.[0] ?? null);
          toggleModal();
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const selectFile = (e: any) => {
    if (croppedImage && croppedImage.type.match(imageTypeRegex)) {
      const formData: FormData = new FormData();
      formData.append("profileImage", croppedImage);

      axios
        .post(Util.apiAuthUrl(`updateProfileImageByAdmin/${clientId}`), formData, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            setUserDetails(res.data);
            toast.success("Profile Image Uploaded!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            toggleModal();
            setProgress(0);
          } else {
            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            toast.error("API Error! Failed to upload the profile image!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });

      return false;
    } else {
      toast.error("Only images can be uploaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function setEditProfile() {
    setShowEditProfile(true);
  }

  const updateClientDetails = () => {
    const phoneno = /^\+1[0-9]{10}$/;
    const zipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if ((!dateValue as any) && !userDetails?.dateOfBirth) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (
      userDetails?.zipCode !== null &&
      userDetails?.zipCode !== undefined &&
      userDetails?.zipCode.trim() !== "" &&
      !userDetails?.zipCode.match(zipcode)
    ) {
      toast.error("Please enter valid zipcode!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (userDetails?.zipCode !== null && userDetails?.zipCode !== undefined && userDetails?.zipCode.trim() !== "" && !userDetails?.state) {
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!userDetails?.firstname) {
      toast.error("first name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!userDetails?.lastname) {
      toast.error("last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!userDetails?.gender) {
      toast.error("gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!userDetails?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!userDetails?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (userDetails?.homePhone && !userDetails?.homePhone?.match(phoneno)) {
      toast.error("Please enter valid home phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (userDetails?.workPhone && !userDetails?.workPhone?.match(phoneno)) {
      toast.error("Please enter valid work phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else {
      setIsUpating(true);

      const updatedUser: UserDetails = {
        userId: clientId,
        firstname: userDetails?.firstname,
        lastname: userDetails?.lastname,
        username: userDetails?.username,
        gender: userDetails?.gender,
        dateOfBirth: (dateValue as any) || userDetails?.dateOfBirth,
        email: userDetails?.email,
        ethnicityId: (setEthenicity as any) || userDetails?.ethnicityId?._id,
        description: userDetails?.description,
        incognito: userDetails?.incognito as any,
        middleInitials: userDetails?.middleInitials,
        maritalStatus: userDetails?.maritalStatus,
        streetAddress: userDetails?.streetAddress,
        unit: userDetails?.unit,
        city: userDetails?.city,
        state: userDetails?.state,
        zipCode: userDetails?.zipCode,
        primaryPhone: userDetails?.primaryPhone,
        homePhone: userDetails?.homePhone,
        workPhone: userDetails?.workPhone,
        voiceMail: userDetails?.voiceMail,
      };

      CommonService.updateClientProfileByAdmin(updatedUser).then(res => {
        if (res.success) {
          setUserDetails(res.data);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setShowEditProfile(false);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        setIsUpating(false);
      });
    }
  };

  const updateTherapistDetails = () => {
    const phoneno = /^\+1[0-9]{10}$/;
    const zipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    const digit = /^[0-9\b]+$/;
    const updateTherapyStateList = selectTherapyTags?.map(obj => obj?.label);

    if ((!dateValue as any) && !userDetails?.dateOfBirth) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!userDetails?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!userDetails?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (
      userDetails?.zipCode !== null &&
      userDetails?.zipCode !== undefined &&
      userDetails?.zipCode.trim() !== "" &&
      !userDetails?.zipCode.match(zipcode)
    ) {
      toast.error("Please enter valid zipcode!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (userDetails?.zipCode !== null && userDetails?.zipCode !== undefined && userDetails?.zipCode.trim() !== "" && !userDetails?.state) {
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (userDetails?.socialSecurity && userDetails?.socialSecurity?.length != 9) {
      toast.error("Social security number should be 9 characters long.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (userDetails?.socialSecurity && !userDetails?.socialSecurity?.match(digit)) {
      toast.error("Please enter only numbers for social security number.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!selectedTimezone || selectedTimezone == "-1") {
      toast.error("Please select a timezone!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (statementFileRef.current.raw) {
      setIsUpating(true);

      const updatedUser: UserDetails2 = {
        userId: clientId,
        firstname: userDetails?.firstname,
        lastname: userDetails?.lastname,
        middleInitials: userDetails?.middleInitials,
        username: userDetails?.username,
        gender: userDetails?.gender,
        dateOfBirth: (dateValue as any) || userDetails?.dateOfBirth,
        email: userDetails?.email,
        ethnicityId: (setEthenicity as any) || userDetails?.ethnicityId?._id,
        description: userDetails?.description,
        deletingStatementId: userDetails?.disclosureStatementId?._id || "none",
        profession: profession?._id || userDetails?.profession?._id,
        professionLicense: userDetails?.professionLicense?._id,
        experiencedIn: selectedExpTags.map(tag => {
          return tag.label;
        }),
        insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
          return tag.label;
        }),
        workingHours: workingHours,
        socialSecurity: userDetails?.socialSecurity,
        cAQH: userDetails?.cAQH,
        nPI1: userDetails?.nPI1,
        taxIdentification: userDetails?.taxIdentification,
        license: userDetails?.license,
        // issueDate: (idDateValue as any) || userDetails?.issueDate,
        // expirationDate: (edDateValue as any) || userDetails?.expirationDate,
        schoolName: userDetails?.schoolName,
        // dateOfGraduation: (dogDateValue as any) || userDetails?.dateOfGraduation,
        schoolStreetAddress: userDetails?.schoolStreetAddress,
        schoolCity: userDetails?.schoolCity,
        schoolState: userDetails?.schoolState,
        schoolZipCode: userDetails?.schoolZipCode,
        taxonomyCode: userDetails?.taxonomyCode,
        malpracticePolicy: userDetails?.malpracticePolicy,
        // malpracticeExpirationDate: (medDateValue as any) || userDetails?.malpracticeExpirationDate,
        primaryPhone: userDetails?.primaryPhone,
        streetAddress: userDetails?.streetAddress,
        city: userDetails?.city,
        state: userDetails?.state,
        zipCode: userDetails?.zipCode,
        timeZone: selectedTimezone,
        caqhUserName: userDetails?.caqhUserName,
        caqhPassword: userDetails?.caqhPassword,
        // googleCalendarAccess: isgoogleCalendarAccess,
        medicaidUsername: userDetails?.medicaidUsername,
        MedicaidPassword: userDetails?.MedicaidPassword,
        psychologyTodayUsername: userDetails?.psychologyTodayUsername,
        psychologyTodayPassword: userDetails?.psychologyTodayPassword,
        therapyState: updateTherapyStateList,
        medicaidId: userDetails?.medicaidId,
      };

      CommonService.updateTherapistProfileByAdmin(updatedUser, statementFileRef.current.raw).then(res => {
        if (res.success) {
          setUserDetails(res.data);
          setStatementFile({
            preview: "",
            preview2: "",
            raw: "",
          });
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setShowEditProfile(false);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        setIsUpating(false);
      });
    } else if (selectedProfessionsLicensDetails?._id && profession?._id) {
      if ((!dateValue as any) && !userDetails?.dateOfBirth) {
        toast.error("Date of birth is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else if (!userDetails?.primaryPhone) {
        toast.error("Primary Phone is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!userDetails?.primaryPhone.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (
        userDetails?.zipCode !== null &&
        userDetails?.zipCode !== undefined &&
        userDetails?.zipCode.trim() !== "" &&
        !userDetails?.zipCode.match(zipcode)
      ) {
        toast.error("Please enter valid zipcode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.zipCode !== null && userDetails?.zipCode !== undefined && userDetails?.zipCode.trim() !== "" && !userDetails?.state) {
        toast.error("No state found matching.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.socialSecurity && userDetails?.socialSecurity?.length !== 9) {
        toast.error("Incomplete social security number", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.socialSecurity && !userDetails?.socialSecurity?.match(digit)) {
        toast.error("Please enter only numbers for social security number.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!selectedTimezone || selectedTimezone == "-1") {
        toast.error("Please select a timezone!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else {
        setIsUpating(true);

        const updatedUser: UserDetails2 = {
          userId: clientId,
          firstname: userDetails?.firstname,
          lastname: userDetails?.lastname,
          middleInitials: userDetails?.middleInitials,
          username: userDetails?.username,
          gender: userDetails?.gender,
          dateOfBirth: (dateValue as any) || userDetails?.dateOfBirth,
          email: userDetails?.email,
          ethnicityId: (setEthenicity as any) || userDetails?.ethnicityId?._id,
          description: userDetails?.description,
          deletingPhotoId: "none",
          deletingVideoId: "none",
          deletingStatementId: userDetails?.disclosureStatementId?._id || "none",
          profession: profession?._id || userDetails?.profession?._id,
          professionLicense: selectedProfessionsLicensDetails?._id,
          // yearsOfExperience: expereinceYears || userDetails?.yearsOfExperience,
          experiencedIn: selectedExpTags.map(tag => {
            return tag.label;
          }),
          insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
            return tag.label;
          }),
          workingHours: workingHours,
          socialSecurity: userDetails?.socialSecurity,
          cAQH: userDetails?.cAQH,
          nPI1: userDetails?.nPI1,
          taxIdentification: userDetails?.taxIdentification,
          license: userDetails?.license,
          // issueDate: (idDateValue as any) || userDetails?.issueDate,
          // expirationDate: (edDateValue as any) || userDetails?.expirationDate,
          schoolName: userDetails?.schoolName,
          // dateOfGraduation: (dogDateValue as any) || userDetails?.dateOfGraduation,
          schoolStreetAddress: userDetails?.schoolStreetAddress,
          schoolCity: userDetails?.schoolCity,
          schoolState: userDetails?.schoolState,
          schoolZipCode: userDetails?.schoolZipCode,
          taxonomyCode: userDetails?.taxonomyCode,
          malpracticePolicy: userDetails?.malpracticePolicy,
          // malpracticeExpirationDate: (medDateValue as any) || userDetails?.malpracticeExpirationDate,
          primaryPhone: userDetails?.primaryPhone,
          streetAddress: userDetails?.streetAddress,
          city: userDetails?.city,
          state: userDetails?.state,
          zipCode: userDetails?.zipCode,
          timeZone: selectedTimezone,
          caqhUserName: userDetails?.caqhUserName,
          caqhPassword: userDetails?.caqhPassword,
          // googleCalendarAccess: isgoogleCalendarAccess,
          medicaidUsername: userDetails?.medicaidUsername,
          MedicaidPassword: userDetails?.MedicaidPassword,
          psychologyTodayUsername: userDetails?.psychologyTodayUsername,
          psychologyTodayPassword: userDetails?.psychologyTodayPassword,
          therapyState: updateTherapyStateList,
          medicaidId: userDetails?.medicaidId,
        };

        CommonService.updateTherapistProfileByAdmin(updatedUser, statementFileRef.current.raw).then(res => {
          if (res.success) {
            setUserDetails(res.data);
            setStatementFile({
              preview: "",
              preview2: "",
              raw: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setShowEditProfile(false);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          setIsUpating(false);
        });
      }
    } else if (selectedProfessionsLicensDetails?._id == undefined && profession?._id) {
      if ((!dateValue as any) && !userDetails?.dateOfBirth) {
        toast.error("Date of birth is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else if (!userDetails?.primaryPhone) {
        toast.error("Primary Phone is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!userDetails?.primaryPhone.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (
        userDetails?.zipCode !== null &&
        userDetails?.zipCode !== undefined &&
        userDetails?.zipCode.trim() !== "" &&
        !userDetails?.zipCode.match(zipcode)
      ) {
        toast.error("Please enter valid zipcode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.zipCode !== null && userDetails?.zipCode !== undefined && userDetails?.zipCode.trim() !== "" && !userDetails?.state) {
        toast.error("No state found matching.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.socialSecurity && userDetails?.socialSecurity?.length !== 9) {
        toast.error("Incomplete social security number", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.socialSecurity && !userDetails?.socialSecurity?.match(digit)) {
        toast.error("Please enter only numbers for social security number.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!selectedTimezone || selectedTimezone == "-1") {
        toast.error("Please select a timezone!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else {
        setIsUpating(true);

        const updatedUser: UserDetails2 = {
          userId: clientId,
          firstname: userDetails?.firstname,
          lastname: userDetails?.lastname,
          middleInitials: userDetails?.middleInitials,
          username: userDetails?.username,
          gender: userDetails?.gender,
          dateOfBirth: (dateValue as any) || userDetails?.dateOfBirth,
          email: userDetails?.email,
          ethnicityId: (setEthenicity as any) || userDetails?.ethnicityId?._id,
          description: userDetails?.description,
          deletingPhotoId: "none",
          deletingVideoId: "none",
          deletingStatementId: userDetails?.disclosureStatementId?._id || "none",
          profession: profession?._id || userDetails?.profession?._id,
          yearsOfExperience: expereinceYears || userDetails?.yearsOfExperience,
          experiencedIn: selectedExpTags.map(tag => {
            return tag.label;
          }),
          insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
            return tag.label;
          }),
          workingHours: workingHours,
          socialSecurity: userDetails?.socialSecurity,
          cAQH: userDetails?.cAQH,
          nPI1: userDetails?.nPI1,
          taxIdentification: userDetails?.taxIdentification,
          license: userDetails?.license,
          issueDate: (idDateValue as any) || userDetails?.issueDate,
          expirationDate: (edDateValue as any) || userDetails?.expirationDate,
          schoolName: userDetails?.schoolName,
          dateOfGraduation: (dogDateValue as any) || userDetails?.dateOfGraduation,
          schoolStreetAddress: userDetails?.schoolStreetAddress,
          schoolCity: userDetails?.schoolCity,
          schoolState: userDetails?.schoolState,
          schoolZipCode: userDetails?.schoolZipCode,
          taxonomyCode: userDetails?.taxonomyCode,
          malpracticePolicy: userDetails?.malpracticePolicy,
          malpracticeExpirationDate: (medDateValue as any) || userDetails?.malpracticeExpirationDate,
          primaryPhone: userDetails?.primaryPhone,
          streetAddress: userDetails?.streetAddress,
          city: userDetails?.city,
          state: userDetails?.state,
          zipCode: userDetails?.zipCode,
          timeZone: selectedTimezone,
          googleCalendarAccess: isgoogleCalendarAccess,
          reminderTime: userDetails?.reminderTime,
          reminderType: userDetails?.reminderType,
          caqhUserName: userDetails?.caqhUserName,
          caqhPassword: userDetails?.caqhPassword,
          medicaidUsername: userDetails?.medicaidUsername,
          MedicaidPassword: userDetails?.MedicaidPassword,
          psychologyTodayUsername: userDetails?.psychologyTodayUsername,
          psychologyTodayPassword: userDetails?.psychologyTodayPassword,
          therapyState: updateTherapyStateList,
          medicaidId: userDetails?.medicaidId,
        };

        CommonService.updateTherapistProfileByAdmin(updatedUser, statementFileRef.current.raw).then(res => {
          if (res.success) {
            setUserDetails(res.data);
            setStatementFile({
              preview: "",
              preview2: "",
              raw: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setShowEditProfile(false);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          setIsUpating(false);
        });
      }
    } else {
      if ((!dateValue as any) && !userDetails?.dateOfBirth) {
        toast.error("Date of birth is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else if (!userDetails?.primaryPhone) {
        toast.error("Primary Phone is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!userDetails?.primaryPhone.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
        // } else if (!userDetails?.streetAddress) {
        //         //   toast.error("Street Address is required", {
        //         //     position: toast.POSITION.BOTTOM_RIGHT,
        //         //     className: "foo-bar",
        //         //   });
        //         //   return false;
        //         // } else if (!userDetails?.city) {
        //         //   toast.error("city is required", {
        //         //     position: toast.POSITION.BOTTOM_RIGHT,
        //         //     className: "foo-bar",
        //         //   });
        //         //   return false;
        //         // } else if (!userDetails?.zipCode) {
        //         //   toast.error("Zipcode is required!", {
        //         //     position: toast.POSITION.BOTTOM_RIGHT,
        //         //     className: "foo-bar",
        //         //   });
        //         //   return false;
        //         // } else if (!userDetails?.zipCode.match(zipcode)) {
        //         //   toast.error("Please enter valid zipcode!", {
        //         //     position: toast.POSITION.BOTTOM_RIGHT,
        //         //     className: "foo-bar",
        //         //   });
        //         //   return false;
        //         // } else if (!userDetails?.state) {
        //         //   toast.error("No state found matching.", {
        //         //     position: toast.POSITION.BOTTOM_RIGHT,
        //         //     className: "foo-bar",
        //         //   });
        //         //   return false;
      } else if (
        userDetails?.zipCode !== null &&
        userDetails?.zipCode !== undefined &&
        userDetails?.zipCode.trim() !== "" &&
        !userDetails?.zipCode.match(zipcode)
      ) {
        toast.error("Please enter valid zipcode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.zipCode !== null && userDetails?.zipCode !== undefined && userDetails?.zipCode.trim() !== "" && !userDetails?.state) {
        toast.error("No state found matching.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.socialSecurity && userDetails?.socialSecurity?.length !== 9) {
        toast.error("Social security number should be 9 characters long. ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (userDetails?.socialSecurity && !userDetails?.socialSecurity?.match(digit)) {
        toast.error("Please enter only numbers for social security number.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!selectedTimezone || selectedTimezone == "-1") {
        toast.error("Please select a timezone!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else {
        setIsUpating(true);

        const updatedUser: UserDetails2 = {
          userId: clientId,
          firstname: userDetails?.firstname,
          lastname: userDetails?.lastname,
          middleInitials: userDetails?.middleInitials,
          username: userDetails?.username,
          gender: userDetails?.gender,
          dateOfBirth: (dateValue as any) || userDetails?.dateOfBirth,
          email: userDetails?.email,
          ethnicityId: (setEthenicity as any) || userDetails?.ethnicityId?._id,
          description: userDetails?.description,
          deletingPhotoId: "none",
          deletingVideoId: "none",
          deletingStatementId: "none",
          profession: profession?._id || userDetails?.profession?._id,
          professionLicense: userDetails?.professionLicense?._id,
          yearsOfExperience: expereinceYears || userDetails?.yearsOfExperience,
          experiencedIn: selectedExpTags.map(tag => {
            return tag.label;
          }),
          insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
            return tag.label;
          }),
          workingHours: workingHours,
          socialSecurity: userDetails?.socialSecurity,
          cAQH: userDetails?.cAQH,
          nPI1: userDetails?.nPI1,
          taxIdentification: userDetails?.taxIdentification,
          license: userDetails?.license,
          issueDate: (idDateValue as any) || userDetails?.issueDate,
          expirationDate: (edDateValue as any) || userDetails?.expirationDate,
          schoolName: userDetails?.schoolName,
          dateOfGraduation: (dogDateValue as any) || userDetails?.dateOfGraduation,
          schoolStreetAddress: userDetails?.schoolStreetAddress,
          schoolCity: userDetails?.schoolCity,
          schoolState: userDetails?.schoolState,
          schoolZipCode: userDetails?.schoolZipCode,
          taxonomyCode: userDetails?.taxonomyCode,
          malpracticePolicy: userDetails?.malpracticePolicy,
          malpracticeExpirationDate: (medDateValue as any) || userDetails?.malpracticeExpirationDate,
          primaryPhone: userDetails?.primaryPhone,
          streetAddress: userDetails?.streetAddress,
          city: userDetails?.city,
          state: userDetails?.state,
          zipCode: userDetails?.zipCode,
          timeZone: selectedTimezone,
          googleCalendarAccess: isgoogleCalendarAccess,
          reminderTime: userDetails?.reminderTime,
          reminderType: userDetails?.reminderType,
          caqhUserName: userDetails?.caqhUserName,
          caqhPassword: userDetails?.caqhPassword,
          medicaidUsername: userDetails?.medicaidUsername,
          MedicaidPassword: userDetails?.MedicaidPassword,
          psychologyTodayUsername: userDetails?.psychologyTodayUsername,
          psychologyTodayPassword: userDetails?.psychologyTodayPassword,
          therapyState: updateTherapyStateList,
          medicaidId: userDetails?.medicaidId,
        };

        CommonService.updateTherapistProfileByAdmin(updatedUser, statementFileRef.current.raw).then(res => {
          if (res.success) {
            setUserDetails(res.data);
            setStatementFile({
              preview: "",
              preview2: "",
              raw: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setShowEditProfile(false);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          setIsUpating(false);
        });
      }
    }
  };

  const selectCoverTherapistFile = (e: any) => {
    if (e.length) {
      const requestData = {
        userId: { userId: clientId },
        themeId: e,
      };
      axios
        .post(Util.apiAuthUrl(`updateProfileCoverImageByAdmin/${clientId}`), requestData, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            setUserDetails(res.data);
            toast.success("Profile Cover Banner Uploaded!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setProgress(0);
            setModalCenterCoverImage(false);
            setModalCenterCoverTheme(false);
          } else {
            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            toast.error("API Error! Failed to upload the profile cover banner!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      return false;
    }
  };

  const selectCoverFile = (e: any) => {
    if (e.target.files.length && e.target.files[0].type.match(imageTypeRegex)) {
      const formData: FormData = new FormData();
      for (const file of e.target.files) {
        if (e.target.files) {
          formData.append("profileCoverImage", file);
        }
      }
      axios
        .post(Util.apiAuthUrl(`updateProfileCoverImageByAdmin/${clientId}`), formData, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            setUserDetails(res.data);

            toast.success("Profile Cover Banner Uploaded!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            // setProgress(0);
            setModalCenterCoverImage(false);
          } else {
            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            toast.error("API Error! Failed to upload the profile cover banner!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });

      return false;
    } else {
      toast.error("Only images can be uploaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function togCenterWorkingHours() {
    setModalCenterWorkingHours(!modalCenterWorkingHours);
    removeBodyCss();
  }

  function togCenterWorkingHoursClose() {
    setModalCenterWorkingHours(false);
  }

  const updateTimeSlots = () => {
    if (newWorkingHour.day == "" || newWorkingHour.startTime == "" || newWorkingHour.endTime == "") {
      toast.error("Please fill all working hours details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (Util.convertUTCDateToLocalDate(newWorkingHour.startTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) {
      toast.error("Your end time should be after the start time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const overlap = workingHours.some(existingHour => {
        return (
          existingHour.day === newWorkingHour.day &&
          ((Util.convertUTCDateToLocalDate(existingHour.startTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
            Util.convertUTCDateToLocalDate(existingHour.startTime) < Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) ||
            (Util.convertUTCDateToLocalDate(existingHour.endTime) > Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
              Util.convertUTCDateToLocalDate(existingHour.endTime) <= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) ||
            (Util.convertUTCDateToLocalDate(existingHour.startTime) <= Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
              Util.convertUTCDateToLocalDate(existingHour.endTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)))
        );
      });

      if (overlap) {
        toast.error("Working hours overlap with existing hours.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        setWorkingHours([...workingHours, newWorkingHour]);
        setNewWorkingHour({ ...newWorkingHour, day: "" });
        togCenterWorkingHoursClose();
      }
    }
  };

  const saveFilterWorkingHours = (value: any) => {
    setWorkingHours(workingHours?.filter(deleteValue => value !== (deleteValue as any)));
    setSelectedDates([...selectedDates, value.day]);
  };

  const dropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const removeExpTag = (removedtag: any) => {
    setSelectedExpTags(selectedExpTags.filter(tag => tag.label != removedtag));
  };

  const handlInsuranceCompanyTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any; value: any }) => {
      return { label: tag.label, value: tag.value };
    });

    if (updatedTags) {
      setSelectedInsuranceCompanyTags(updatedTags);
    }
  };

  const handleTimezone = (event: any) => {
    const value = event.target.value;
    if (value) {
      setSelectedTimezone(value);
    }
  };

  const handlExpTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label.trim(), value: tag.value.trim() };
      });

      if (updatedTags) {
        setSelectedExpTags(updatedTags);
      }
    }
  };

  const removeInsuranceCompanyTag = (removedtag: any) => {
    setSelectedInsuranceCompanyTags(selecteInsuranceCompanyTags.filter(tag => tag.label != removedtag));
  };

  const viewAllEducations = () => {
    setIsLoading(true);
    TherapistService.getAllEducations(userDetails?._id as any).then(res => {
      setEducationsDetailsList(res.data);
      setIsLoading(false);
      if (educationPopupId.current) {
        const selectedCategory = res.data?.filter(c => c._id === educationPopupId.current);
        const selectedCategorydata = selectedCategory[0];
        setEducationData(selectedCategorydata);
        setUploads(selectedCategorydata.uploadId as []);
      }
    });
  };

  const viewAllLicenses = () => {
    setIsLoading(true);
    TherapistService.getAllLicenses(userDetails?._id as any).then(res => {
      setLicensesDetailsList(res.data);
      setIsLoading(false);
      if (licensesPopupId.current) {
        const selectedCategory = res.data?.filter(c => c._id === licensesPopupId.current);
        const selectedCategorydata = selectedCategory[0];
        setLicensesData(selectedCategorydata);
        setUploads(selectedCategorydata?.uploadId as []);
      }
    });
  };

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });
        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };

  const viewAllThemeImages = () => {
    CommonService.getThemesImages().then(res => {
      setThemeImages(res.data);
    });
  };

  const getAllInsuranceCompanies = () => {
    InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.insuranceCompany };
        });
        setSearchInsuranceCompanyTag(tagList);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };

  const setNewWorkingDay = (e: any) => {
    setNewWorkingHour({ ...newWorkingHour, day: e });
  };

  const creatableComponents = {
    DropdownIndicator: dropdownIndicator,
    IndicatorSeparator: null,
  };

  const selectStatementFile = (e: any) => {
    if (e.target.files.length) {
      setStatementFile({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const statementFiles = () => {
    switch (statementFileRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        setStatementFile({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const [timezones] = useState([
    { value: "-1", label: "Select" },
    { value: "Hawaii Standard Time", label: "Hawaii Standard Time" },
    { value: "Hawaii-Aleutian Daylight Time", label: "Hawaii-Aleutian Daylight Time" },
    { value: "Alaska Daylight Time", label: "Alaska Daylight Time" },
    { value: "Pacific Daylight Time", label: "Pacific Daylight Time" },
    { value: "Mountain Standard Time", label: "Mountain Standard Time" },
    { value: "Mountain Daylight Time", label: "Mountain Daylight Time" },
    { value: "Central Daylight Time", label: "Central Daylight Time" },
    { value: "Eastern Daylight Time", label: "Eastern Daylight Time" },
  ]);

  const beforeToday = (date: Date) => {
    return moment(date).isBefore(new Date());
  };

  function handleEdDateUpdate(e: any) {
    const dateValue3 = e.target.value;
    setEdDateValue(dateValue3);
  }

  function handleIdDateUpdate(e: any) {
    const dateValue1 = e.target.value;
    setIdDateValue(dateValue1);
  }

  function handleDogDateUpdate(e: any) {
    const dateValue2 = e.target.value;
    setDogDateValue(dateValue2);
  }

  function handleMedDateUpdate(e: any) {
    const dateValue4 = e.target.value;
    setMedDateValue(dateValue4);
  }

  return (
    <React.Fragment>
      <div>
        <Col className="col-xl-12">
          <Card style={{ minHeight: "67vh" }} className="BorderRadiusLeftRight">
            <div className="card-bodyDiv">
              <Row>
                <Col xl="12">
                  <Row>
                    <div
                      className="profileImage imageFit BorderRadiusLeftRight"
                      style={{
                        backgroundImage:
                          userDetails?.coverPhotoId == null || userDetails?.coverPhotoId == undefined
                            ? `url(${profileBackground})`
                            : `url("${Util.fileURL(userDetails?.coverPhotoId?._id)}")`,
                      }}
                    >
                      <div
                        onClick={() => {
                          togCenterCoverImage();
                        }}
                        className=" imageFit cursor-pointer w-10 h-10 rounded-full absolute bottom-2 right-2 justify-center items-center text-center flex"
                        style={{
                          borderRadius: "9999px",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                          width: "2.5rem",
                          height: "2.5rem",
                          display: "flex",
                          position: "absolute",
                          textAlign: "center",
                          right: "1rem",
                          top: "1rem",
                        }}
                      >
                        <svg
                          width="24"
                          height="18"
                          viewBox="0 0 24 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="content-center items-center justify-center relative"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <path
                            d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <div
                          className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl profile-user-wid-pro items-center margin_center mb-4 profileImageShow"
                          style={{
                            backgroundImage:
                              userDetails?.photoId == null || userDetails?.photoId == undefined
                                ? `url(${user1})`
                                : `url("${Util.fileURL(userDetails?.photoId?._id)}")`,
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <div
                            className="cursor-pointer imageFit w-10 h-10 rounded-full absolute bottom-2 right-2 justify-center items-center text-center flex"
                            style={{
                              borderRadius: "9999px",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#DDDDDD",
                              cursor: "pointer",
                              width: "2.5rem",
                              height: "2.5rem",
                              display: "flex",
                              position: "absolute",
                              textAlign: "center",
                              right: "0rem",
                            }}
                          >
                            <label
                              htmlFor="file-upload-profile"
                              className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                              style={{
                                margin: "auto",
                                borderRadius: "0.25rem",
                                cursor: "pointer",
                                display: "flex",
                                zIndex: "10",
                                position: "relative",
                              }}
                            >
                              <svg
                                width="24"
                                height="18"
                                viewBox="0 0 24 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="content-center items-center justify-center relative"
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <path
                                  d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                                  fill="#FFFFFF"
                                />
                                <path
                                  d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                                  fill="#FFFFFF"
                                />
                              </svg>

                              <input
                                id="file-upload-profile"
                                type="file"
                                style={{ display: "none" }}
                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                text/plain, image/doc"
                                onChange={handleFileChange}
                                multiple
                              />
                            </label>
                          </div>
                        </div>

                        <div>
                          <Modal
                            isOpen={modalOpen}
                            toggle={toggleModal}
                            centered
                            style={{
                              margin: "auto",
                            }}
                          >
                            <ModalHeader toggle={toggleModal}>Profile Image</ModalHeader>
                            <ModalBody>
                              <div style={{ width: "100%", height: "250px" }}>
                                <Cropper
                                  image={image.preview}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1 / 1}
                                  cropShape="round"
                                  showGrid={false}
                                  onCropChange={setCrop}
                                  onCropComplete={handleCrop}
                                  initialCroppedAreaPercentages={initialCroppedArea}
                                />
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <Slider value={zoom} min={1} max={3} step={0.1} onChange={handleZoomChange} aria-labelledby="Zoom" />
                              </div>
                              <div style={{ display: "flex", justifyContent: "center", gap: "10px", width: "100%" }}>
                                <Button color="primary" onClick={selectFile} style={{ flex: 1, maxWidth: "100px" }}>
                                  Save
                                </Button>
                                <Button color="secondary" onClick={toggleModal} onClose={onClose} style={{ flex: 1, maxWidth: "100px" }}>
                                  Cancel
                                </Button>
                              </div>
                            </ModalFooter>
                          </Modal>
                        </div>
                      </Col>

                      {userDetails.role == "THERAPIST" && userDetails.videoId && (
                        <Col xl={6}>
                          <div>
                            <div
                              className="btn-video relative mt-3 pl-3 pr-3 pt-1 pb-1 mr-2 mb-4 cursor-pointer"
                              style={{
                                bottom: "0",
                                float: "right",
                              }}
                              onClick={() => {
                                togCenterVideo();
                              }}
                            >
                              <img src={videoIcon} className="mr-2" />
                              Watch Video
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Row>
                  <Row className=" pl-5 pr-5 mb-4">
                    <Col xl="12">
                      <Row>
                        <Col xl={12}>
                          {/*{!editProfile && (
                            <button
                              type="button"
                              className="btn btn-primary relative mb-4 ms-2"
                              style={{
                                bottom: "0",
                                float: "right",
                              }}
                              onClick={() => {
                                setEditProfile();
                              }}
                            >
                              <i className="fa fa-pencil-square-o mr-1 "></i>
                              Edit Profile
                            </button>
                          )}*/}

                          {/* {user?.role == "THERAPIST" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary relative mb-4 ms-2"
                                    style={{
                                      bottom: "0",
                                      float: "right",
                                    }}
                                    onClick={handleCopyURL}
                                  >
                                    <i className="fa fa-pencil-square-o mr-1"></i>
                                    Copy Referral Link
                                  </button>
                                )} */}

                          {editProfile && (
                            <div>
                              {userDetails?.role == "THERAPIST" && (
                                <button
                                  type="button"
                                  className={isUpating ? "btn btn-primary relative mb-4 ml-10 updating" : "btn btn-primary relative mb-4 ml-10"}
                                  style={{
                                    bottom: "0",
                                    float: "right",
                                  }}
                                  disabled={isUpating}
                                  onClick={() => {
                                    updateTherapistDetails();
                                  }}
                                >
                                  {isUpating ? "Saving..." : "Save Changes"}
                                </button>
                              )}

                              {userDetails.role == "CLIENT" && (
                                <button
                                  type="button"
                                  className={isUpating ? "btn btn-primary relative mb-4 ml-10 updating" : "btn btn-primary relative mb-4 ml-10"}
                                  style={{
                                    bottom: "0",
                                    float: "right",
                                  }}
                                  disabled={isUpating}
                                  onClick={() => {
                                    updateClientDetails();
                                  }}
                                >
                                  {isUpating ? "Saving..." : "Save Changes"}
                                </button>
                              )}

                              <button
                                type="button"
                                className="btn btn-light  relative mr-2 mb-4"
                                style={{
                                  bottom: "0",
                                  float: "right",
                                }}
                                onClick={() => {
                                  setShowEditProfile(false);
                                }}
                              >
                                <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                                Back
                              </button>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {/* {editProfile && ( */}
                      <Modal
                        isOpen={modalcentercoverimage}
                        toggle={() => {
                          togCenterCoverImage();
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">
                            <br />
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setModalCenterCoverImage(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span>&times;</span>
                          </button>
                        </div>

                        {modalcentercovertheme && (
                          <Row>
                            {themeImages.length == 0 && (
                              <div className="font-size-14 mb-5 mt-1" style={{ textAlign: "center" }}>
                                No theme images to display.
                              </div>
                            )}
                            {themeImages?.map((img: any, i: any) => {
                              return (
                                <Col xl={6} key={i} className="p-4">
                                  <div className="themeImgBorder">
                                    <div
                                      className="profileThemeImage  imageFit cursor-pointer"
                                      style={{
                                        backgroundImage: img == null || img == undefined ? `url(${profileBackground})` : `url(${Util.fileURL(img?._id)})`,
                                      }}
                                      onClick={() => selectCoverTherapistFile(img?._id)}
                                    ></div>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        )}

                        {!modalcentercovertheme && (
                          <div>
                            <Row>
                              <Col xl={3}></Col>
                              <Col xl={6}>
                                <div
                                  className="btn-video relative mt-3 pl-3 pr-3 pt-2 pb-2 mb-2 cursor-pointer"
                                  style={{
                                    bottom: "0",
                                    textAlign: "center",
                                  }}
                                  onClick={() => {
                                    togCenterCoverTheme();
                                  }}
                                >
                                  Select Theme
                                </div>
                              </Col>
                              <Col xl={3}></Col>
                            </Row>
                            <h5 className="text-center">Or</h5>

                            <Row>
                              <Col xl={3}></Col>
                              <Col xl={6}>
                                <label
                                  htmlFor="file-upload"
                                  className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                                  style={{
                                    margin: "auto",
                                    borderRadius: "0.25rem",
                                    cursor: "pointer",
                                    display: "flex",
                                    zIndex: "10",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    className="btn-video relative mt-2 pl-3 pr-3 pt-2 pb-2 mb-4 cursor-pointer"
                                    style={{
                                      bottom: "0",
                                      textAlign: "center",
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="18"
                                      viewBox="0 0 24 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mr-2 content-center items-center justify-center relative"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <path
                                        d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                                        fill="#fd7f00"
                                      />
                                      <path
                                        d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                                        fill="#fd7f00"
                                      />
                                    </svg>
                                    Upload Image
                                  </div>
                                  <input
                                    id="file-upload"
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                text/plain, image/doc"
                                    onChange={selectCoverFile}
                                    multiple
                                  />
                                </label>
                              </Col>
                              <Col xl={3}></Col>
                            </Row>
                          </div>
                        )}
                      </Modal>
                      {!editProfile && (
                        <Row>
                          {userDetails?.role == "CLIENT" && (
                            <Col xl={12}>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-17 profileFont mb-3">About Me</h5>
                              </div>
                              <div className="card-heading mb-2 pr-5">
                                <h5 className="font-size-14 mb-4">{userDetails?.description || "-"}</h5>
                              </div>
                            </Col>
                          )}
                          {userDetails?.role == "THERAPIST" && (
                            <Col xl={12}>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-17 profileFont mb-3">About Me</h5>
                              </div>
                              <div className="card-heading mb-4 pr-5">
                                <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userDetails?.description || "-" }} />
                              </div>
                            </Col>
                          )}
                          <Col xl={4}>
                            {userDetails?.role == "THERAPIST" && (
                              <div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-3">Working days and hours</h5>
                                </div>
                                <div className="card-heading mb-4">
                                  {userDetails?.workingHours?.length == 0 && "-"}
                                  {workingHours &&
                                    workingHours?.map((opt, index) => (
                                      <h5 className="font-size-14 mb-2" key={index}>
                                        {opt.day} | {Util.convertUTCDateToLocalDate12Hour(opt.startTime)} - {Util.convertUTCDateToLocalDate12Hour(opt.endTime)}
                                      </h5>
                                    ))}
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col xl={4}>
                            {userDetails?.role == "THERAPIST" && (
                              <div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-3">Professional experience</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  {userDetails?.experiencedIn?.length == 0 && "-"}
                                  {userDetails?.experiencedIn?.map((ex: any) => {
                                    return (
                                      <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                        {ex.experienceTag}
                                      </Link>
                                    );
                                  })}
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Profession</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.profession?.name || "-"}</h5>
                                </div>

                                {userDetails?.professionLicense?._id && (
                                  <div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Profession License</h5>
                                    </div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 mb-3">{userDetails?.professionLicense?.name || "-"}</h5>
                                    </div>
                                  </div>
                                )}

                                {userDetails?.disclosureStatementId !== null && (
                                  <div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Your professional disclosure statement:</h5>
                                    </div>

                                    <div className="flex  mb-2">
                                      <div className="mr-2">
                                        <a
                                          // to={{ pathname: Util.fileURL(userDetails?.disclosureStatementId?.url) }}
                                          href={userDetails?.disclosureStatementId?.url}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="events-icon2"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {/* <FileThumbnail file={userDetails?.disclosureStatementId} /> */}
                                          {userDetails?.disclosureStatementId?.originalName}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Col>
                          <Col xl={4}>
                            {userDetails?.role == "THERAPIST" && (
                              <>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-3">Time Zone</h5>
                                </div>

                                <h5 className="font-size-14 mb-2"> {userDetails?.timeZone ? userDetails?.timeZone : "Eastern Daylight Timezone"}</h5>
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                      {!editProfile && (
                        <Row>
                          <Col xl={4}></Col>

                          <Col xl={4}>
                            {userDetails?.role == "THERAPIST" && (
                              <>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Selected Insurance Companies</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  {userDetails?.insuranceCompanies?.length == 0 && "-"}
                                  {userDetails?.insuranceCompanies?.map((ex: any) => {
                                    return (
                                      <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                        {ex.insuranceCompany}
                                      </Link>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col xl="12 mt50">
                          <hr />
                        </Col>
                        <Col xl="6"></Col>
                        <Col xl="6" className="text-right"></Col>
                      </Row>

                      <div className="card-heading mb-2">
                        <h5 className="font-size-17 profileFont mb-4">Personal Information</h5>
                      </div>

                      {!editProfile && (
                        <Row>
                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">Name</h5>
                            </div>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 mb-3">
                                {userDetails?.firstname || "-"} {userDetails?.middleInitials}
                                {userDetails?.lastname}
                              </h5>
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                            </div>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 mb-3">{userDetails?.gender || "-"}</h5>
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">Age</h5>
                            </div>
                            <div className="card-heading mb-2">
                              {userDetails?.dateOfBirth ? (
                                <h5 className="font-size-14 mb-2">{moment().diff(moment(userDetails?.dateOfBirth), "years")} years</h5>
                              ) : (
                                <h5 className="font-size-14 mb-2">{!userDetails?.dateOfBirth && "-"}</h5>
                              )}
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">Email</h5>
                            </div>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 mb-3">{userDetails?.email || "-"}</h5>
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">Ethnicity</h5>
                            </div>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 mb-3">{userDetails?.ethnicityId?.ethnicity || "-"}</h5>
                            </div>
                          </Col>

                          <Col xl="4">
                            <div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Username</h5>
                              </div>
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 mb-3">{userDetails?.username || "-"}</h5>
                              </div>
                            </div>
                          </Col>

                          {userDetails?.role == "THERAPIST" && (
                            <>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Primary Phone/Cell</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.primaryPhone?.slice(userDetails?.primaryPhone?.length - 10) || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Street Address</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.streetAddress || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">City</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.city || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">State</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.state || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Zip Code</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.zipCode || "-"}</h5>
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Selected Therapy States</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  {userDetails?.therapyState?.length === 0
                                    ? "-"
                                    : userDetails?.therapyState?.map((state: string, index: number) => (
                                        <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={index}>
                                          {state}
                                        </Link>
                                      ))}
                                </div>
                              </Col>
                            </>
                          )}

                          {userDetails.role == "CLIENT" && (
                            <>
                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Marital Status</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.maritalStatus || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Street Address</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.streetAddress || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Apt./Unit</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.unit || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">City</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.city || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.state || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Zip Code</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.zipCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Secondary/Home Phone</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.homePhone?.slice(userDetails?.homePhone?.length - 10) || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Work Phone</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.workPhone?.slice(userDetails?.workPhone?.length - 10) || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">May we leave a voicemail?</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.voiceMail || "-"}</h5>
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}

                      {editProfile && (
                        <>
                          <Row className="mb-2">
                            {/* {user?.role == "CLIENT" && (
                                    <Col xl={12}>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-17 profileFont mb-3">
                                          About Me
                                          {userDetails?.description?.length !== undefined && userDetails?.description?.length !== 0 ? null : (
                                            <div className="tagError">Missing</div>
                                          )}
                                        </h5>
                                      </div>

                                      <Input
                                        type="textarea"
                                        className="form-control mb-2"
                                        id="formrow-firstname-Input"
                                        style={{ minHeight: "200px" }}
                                        placeholder="Write about your self"
                                        name="articleTitle"
                                        value={userDetails?.description || ""}
                                        onChange={e => setUserDetails({ ...userDetails, description: e.target.value })}
                                        required
                                      />
                                    </Col>
                                  )} */}

                            <Col xl={4}>
                              {userDetails?.role == "THERAPIST" && (
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">
                                      About Me
                                      {userDetails?.description?.length !== undefined && userDetails?.description?.length !== 0 ? null : (
                                        <div className="tagError">Missing</div>
                                      )}
                                    </h5>
                                  </div>
                                  <CKEditor
                                    editor={Editor}
                                    style={{
                                      maxHeight: "300px",
                                    }}
                                    onChange={(event: any, editor: { getData: () => any }) => {
                                      const data = editor.getData();
                                      setUserDetails({ ...userDetails, description: data });
                                    }}
                                    data={userDetails?.description || ""}
                                    config={editorConfiguration}
                                  />
                                </div>
                              )}
                            </Col>

                            <Col xl={4}>
                              {userDetails?.role == "THERAPIST" && (
                                <>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">
                                      Working days and hours
                                      {workingHours?.length !== undefined && workingHours?.length !== 0 ? null : <div className="tagError">Missing</div>}
                                    </h5>
                                  </div>

                                  <div className="py-0 w-full mb-4" style={{ width: "100%" }}>
                                    {workingHours &&
                                      workingHours.map((opt, index) => (
                                        <Row className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2 timing" key={index}>
                                          <Col className="col-10">
                                            {opt.day} | {Util.convertUTCDateToLocalDate(opt.startTime)} - {Util.convertUTCDateToLocalDate(opt.endTime)}
                                          </Col>
                                          <Col className="col-2">
                                            <i
                                              onClick={() => saveFilterWorkingHours(opt as any)}
                                              style={{ borderColor: "#414141" }}
                                              className="fas fa-times-circle  cursor_pointer"
                                            ></i>
                                          </Col>
                                        </Row>
                                      ))}
                                  </div>

                                  <div className="py-0 w-full mb-4" style={{ width: "100%" }}>
                                    <Row className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2 timing">
                                      <Col className="col-12">
                                        <button
                                          className="btn btn-sm dotted "
                                          onClick={() => {
                                            togCenterWorkingHours();
                                          }}
                                        >
                                          Add New
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>

                                  <Modal
                                    isOpen={modalCenterWorkingHours}
                                    toggle={() => {
                                      togCenterWorkingHours();
                                    }}
                                    centered
                                  >
                                    <div className="modal-header">
                                      <h5 className="font-size-14 mb-2">Working days and hours:</h5>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          togCenterWorkingHoursClose();
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span>&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="py-0 w-full" style={{ width: "100%" }}>
                                        <div className=" flex-wrap -mx-2 md:space-y-0 mt-4 px-2" style={{ display: "flex" }}>
                                          <select className="form-control" defaultValue={newWorkingHour.day} onChange={e => setNewWorkingDay(e.target.value)}>
                                            <option>Select Day</option>
                                            {selectedDates.map((day: string, i: number) => {
                                              return (
                                                <option key={i} value={day}>
                                                  {day}
                                                </option>
                                              );
                                            })}
                                          </select>

                                          <Row className="mt-3">
                                            <Col className="col-6">
                                              <Label className="form-label">Start Time</Label>
                                              <CustomTimePicker2
                                                timeValue={startTimeValue || ""}
                                                setTimeValue={(value: string) => {
                                                  setStartTimeValue(value);
                                                  if (value) {
                                                    const utcDate = moment(value, "hh:mm A").utc().format("H:mm A");
                                                    setNewWorkingHour({ ...newWorkingHour, startTime: utcDate });
                                                  }
                                                }}
                                              />
                                            </Col>

                                            <Col className="col-6">
                                              <Label className="form-label">End Time</Label>
                                              <CustomTimePicker2
                                                timeValue={endTimeValue || ""}
                                                setTimeValue={(value: string) => {
                                                  setEndTimeValue(value);
                                                  if (value) {
                                                    const utcDate = moment(value, "hh:mm A").utc().format("H:mm A");
                                                    setNewWorkingHour({ ...newWorkingHour, endTime: utcDate });
                                                  }
                                                }}
                                              />
                                            </Col>

                                            <Col className="col-12 mt-3">
                                              <button className="btn btn-primary btn-sm font-size-14" onClick={updateTimeSlots}>
                                                Add Time Slot
                                              </button>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </>
                              )}
                            </Col>

                            <Col xl={4}>
                              {userDetails?.role == "THERAPIST" && (
                                <>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">Professional experience</h5>
                                  </div>

                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-4">
                                      Experienced in:
                                      {selectedExpTags?.length !== undefined && selectedExpTags?.length !== 0 ? null : <div className="tagError">Missing</div>}
                                    </h5>
                                  </div>

                                  <Creatable
                                    options={searchExpTag}
                                    isMulti
                                    components={creatableComponents}
                                    onChange={(newValue: any) => handlExpTags(newValue)}
                                    value={selectedExpTags}
                                    display="none"
                                    className="creatableEdit"
                                    placeholder="Choose an area"
                                    controlShouldRenderValue={false}
                                    isClearable={false}
                                  />

                                  <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                                    {selectedExpTags.map((tag, id) => {
                                      return <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
                                    })}
                                  </div>

                                  <div className="card-heading mb-4 mt-4">
                                    <h5 className="font-size-14 mb-2">Your profession:</h5>
                                  </div>

                                  <div className="mb-2">
                                    <div className="col-xl-12">
                                      <select
                                        className="form-control"
                                        onChange={e => {
                                          const selectedLabel = e.target.options[e.target.selectedIndex].text;
                                          setProfession({ _id: e.target.value, name: selectedLabel });
                                        }}
                                        value={(profession?._id as any) || userDetails?.profession?._id}
                                      >
                                        <option>Select Profession</option>
                                        {professionsDetails &&
                                          professionsDetails.map(opt => (
                                            <option value={opt._id} key={opt._id}>
                                              {opt.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>

                                  {selectedProfessions != undefined && selectedProfessions.length != 0 ? (
                                    <div className="mb-2">
                                      <div className="col-xl-12">
                                        <select
                                          className="form-control"
                                          onChange={e =>
                                            setSelectedProfessionsLicensDetails({
                                              ...selectedProfessionsLicensDetails,
                                              _id: e.target.value,
                                            })
                                          }
                                          value={(selectedProfessionsLicensDetails?._id as string) || userDetails?.professionLicense?._id}
                                        >
                                          <option>Select License</option>
                                          {selectedProfessions &&
                                            selectedProfessions.map(opt => (
                                              <option value={opt._id} key={opt._id}>
                                                {opt.name}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}

                                  {profession?.name === "Counselors, Clinicians, Therapists" && (
                                    <>
                                      <div className="card-heading mb-4 mt-4">
                                        <h5 className="font-size-14 mb-2">Your professional disclosure statement:</h5>
                                      </div>

                                      {userDetails?.disclosureStatementId != null && (
                                        <div className="flex  mb-2">
                                          <div className="mr-2">
                                            <a
                                              // to={{ pathname: Util.fileURL(user?.disclosureStatementId?.url) }}
                                              href={userDetails?.disclosureStatementId?.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="events-icon2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {/* <FileThumbnail file={user?.disclosureStatementId} /> */}
                                              {userDetails?.disclosureStatementId?.originalName}
                                            </a>
                                          </div>
                                        </div>
                                      )}

                                      {statementFileRef.current.preview ? (
                                        <div>
                                          {statementFileRef.current.preview && (
                                            <label htmlFor="file-upload1" className="file-upload m-auto">
                                              <span className="text-primary pt-5 m-auto cursor-pointer">Change disclosure statement</span>

                                              <input
                                                id="file-upload1"
                                                type="file"
                                                style={{ display: "none" }}
                                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                    text/plain, image/doc"
                                                onChange={selectStatementFile}
                                                multiple
                                              />
                                            </label>
                                          )}
                                          <div>
                                            <div className="flex">
                                              <div style={{ fontSize: "62px" }}>{statementFiles()}</div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      {!statementFileRef.current.preview && (
                                        <label
                                          htmlFor="file-upload1"
                                          style={{ border: "1px dashed" }}
                                          className="mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 ounded cursor-pointer upload-box"
                                        >
                                          <div className="flex flex-wrap items-center file_upload_content">
                                            <span className="text-black">
                                              <i className="fa fa-upload cloud-upload"></i>&nbsp;&nbsp;Upload your disclosure statement
                                            </span>
                                          </div>

                                          <span className="text-sm file_upload_p">.PDF, .JPG, .PNG</span>

                                          <input
                                            id="file-upload1"
                                            type="file"
                                            style={{ display: "none" }}
                                            className="file_upload_input mb-4"
                                            accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                            onChange={selectStatementFile}
                                            multiple
                                          />
                                        </label>
                                      )}
                                    </>
                                  )}

                                  {/* <div className="card-heading mb-4 mt-4">
                                        <h5 className="font-size-14 mb-2">Sync appointments with google calendar</h5>
                                      </div>

                                      {isgoogleCalendarAccess == true ? (
                                        <div>
                                          <div className="d-flex justify-content-start ml-3">
                                            <a
                                              id="btn-id"
                                              onClick={() => {
                                                logoutCalendar();
                                              }}
                                              className="btnFbnCalendar"
                                            >
                                              <img src={googleImage} style={{ height: "15px", marginRight: "5px", marginLeft: "3px" }}></img>Sign out Google
                                              Calendar
                                            </a>
                                          </div>
                                          <p>{googleUserEmail}</p>
                                        </div>
                                      ) : (
                                        <div id="btn-id" className="btnFbnCalendar">
                                          <GoogleLogin
                                            clientId={environment.google_calendar_client_id}
                                            buttonText="Sign in with Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseError}
                                            responseType="code"
                                            accessType="offline"
                                            cookiePolicy={"single_host_origin"}
                                            scope="https://www.googleapis.com/auth/calendar openid email profile"
                                          />
                                        </div>
                                      )} */}
                                </>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col xl={4}>
                              {userDetails?.role == "THERAPIST" && (
                                <>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">
                                      Timezone
                                      {userDetails?.timeZone ? null : <div className="tagError">Missing</div>}
                                    </h5>
                                  </div>

                                  <select className="form-control" value={selectedTimezone} onChange={event => handleTimezone(event)}>
                                    {timezones &&
                                      timezones.map((zone: { label: string; value: string }, index: number) => {
                                        return (
                                          <option key={index} value={zone.value}>
                                            {zone.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </>
                              )}
                            </Col>

                            <Col xl={4}>
                              {userDetails?.role == "THERAPIST" && (
                                <>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">
                                      Are you credential with the following insurance companies?:
                                      {selecteInsuranceCompanyTags?.length !== undefined && selecteInsuranceCompanyTags?.length !== 0 ? null : (
                                        <div className="tagError">Missing</div>
                                      )}
                                    </h5>
                                  </div>

                                  <Select
                                    onChange={(newValue: any) => handlInsuranceCompanyTags(newValue)}
                                    value={selecteInsuranceCompanyTags}
                                    isMulti
                                    options={searchInsuranceCompanyTag}
                                    className="basic-multi-select"
                                    placeholder="Choose a company"
                                    display="none"
                                    controlShouldRenderValue={false}
                                    classNamePrefix="select"
                                    isClearable={false}
                                  />

                                  <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                                    {selecteInsuranceCompanyTags.map((tag, id) => {
                                      return <Tags label={tag.label} id={id} key={id} removeTag={removeInsuranceCompanyTag} />;
                                    })}
                                  </div>
                                </>
                              )}
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row>
                        <Col xl="12 mt20">
                          <hr />
                        </Col>
                        <Col xl="6"></Col>
                        <Col xl="6" className="text-right"></Col>
                      </Row>

                      {editProfile && (
                        <Row>
                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">
                                Name<span style={{ color: "#990000" }}>*</span>
                              </h5>
                            </div>

                            <div className="card-heading mb-2 flex">
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="First name"
                                name="articleTitle"
                                value={userDetails?.firstname || ""}
                                onChange={e => setUserDetails({ ...userDetails, firstname: e.target.value })}
                                required
                              />

                              <Input
                                type="text"
                                className="form-control ml-3"
                                id="formrow-firstname-Input"
                                placeholder="Middle Initials"
                                name="Middle Initials"
                                value={userDetails?.middleInitials || ""}
                                onChange={e => setUserDetails({ ...userDetails, middleInitials: e.target.value })}
                                required
                              />

                              <Input
                                type="text"
                                className="form-control ml-3"
                                id="formrow-firstname-Input"
                                placeholder="Last name"
                                name="articleTitle"
                                value={userDetails?.lastname || ""}
                                onChange={e => setUserDetails({ ...userDetails, lastname: e.target.value })}
                                required
                              />
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">
                                Gender<span style={{ color: "#990000" }}>*</span>
                              </h5>
                            </div>

                            <div className="card-heading mb-2">
                              <Row>
                                <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadioss"
                                      id="exampleRadios1"
                                      value="Female"
                                      defaultChecked={userDetails?.gender == "Female"}
                                      onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                                    />

                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      Female
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadioss"
                                      id="exampleRadios2"
                                      value="Male"
                                      defaultChecked={userDetails?.gender == "Male"}
                                      onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                                    />

                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                      Male
                                    </label>
                                  </div>

                                  <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadioss"
                                      id="exampleRadios4"
                                      value="NonBinary"
                                      defaultChecked={userDetails?.gender == "NonBinary"}
                                      onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                                    />

                                    <label className="form-check-label" htmlFor="exampleRadios4">
                                      Non Binary
                                    </label>
                                  </div>

                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="exampleRadioss"
                                      id="exampleRadios3"
                                      value="Other"
                                      defaultChecked={userDetails?.gender == "Other"}
                                      onChange={e => setUserDetails({ ...userDetails, gender: e.target.value })}
                                    />

                                    <label className="form-check-label" htmlFor="exampleRadios3">
                                      Other
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">
                                Date Of BirthDay<span style={{ color: "#990000" }}>*</span>
                              </h5>
                            </div>

                            <div className="card-heading mb-3">
                              <Input
                                type="date"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Date of birth"
                                value={db || ""}
                                onChange={e => handleDateUpdate(e)}
                                max={moment().format("YYYY-MM-DD")}
                                required
                              />
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">
                                Email<span style={{ color: "#990000" }}>*</span>
                              </h5>
                            </div>

                            <div className="card-heading mb-3">
                              <Input
                                type="email"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Email"
                                disabled
                                name="articleTitle"
                                value={userDetails?.email || ""}
                                onChange={e => {}}
                                required
                              />
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">
                                Select as ethnicity
                                {userDetails?.ethnicityId?._id?.length !== undefined && userDetails?.ethnicityId?._id?.length !== 0 ? null : (
                                  <div className="tagError">Missing</div>
                                )}
                              </h5>
                            </div>

                            <div className="col-xl-12 mb-3">
                              <select
                                className="form-control"
                                value={(setEthenicity as any) || user?.ethnicityId?._id}
                                onChange={e => setUpdateEthenacity(e.target.value)}
                              >
                                <option>Select</option>
                                {ethinicityList &&
                                  ethinicityList.map(opt => (
                                    <option value={opt._id} key={opt._id}>
                                      {opt.ethnicity}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Col>

                          <Col xl="4">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 profileFont mb-2">
                                User Name<span style={{ color: "#990000" }}>*</span>
                              </h5>
                            </div>

                            <div className="card-heading mb-3">
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="User name"
                                name="articleTitle"
                                value={userDetails?.username || ""}
                                onChange={e => setUserDetails({ ...userDetails, username: e.target.value })}
                                required
                              />
                            </div>
                          </Col>

                          {userDetails?.role == "THERAPIST" && (
                            <>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Primary Phone/Cell<span style={{ color: "#990000" }}>*</span>
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <PhoneInput
                                    placeholder="Primary Phone "
                                    value={userDetails?.primaryPhone}
                                    defaultCountry="US"
                                    // containerClass="form-control"
                                    // inputClass="form-control"
                                    // containerStyle={{
                                    //     border: "10px solid black",
                                    //     background: "lightblue"
                                    // }}
                                    // inputStyle={{
                                    //     background: "lightblue"
                                    // }}
                                    onChange={(e: string) => setUserDetails({ ...userDetails, primaryPhone: e })}
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    {/* Street Address<span style={{ color: "#990000" }}>*</span> */}
                                    Street Address
                                    {userDetails?.streetAddress?.length !== undefined && userDetails?.streetAddress?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Street Address"
                                    name="Street Address"
                                    value={userDetails?.streetAddress}
                                    onChange={e => setUserDetails({ ...userDetails, streetAddress: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    {/* City<span style={{ color: "#990000" }}>*</span> */}
                                    City
                                    {userDetails?.city?.length !== undefined && userDetails?.city?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="City"
                                    name="City"
                                    value={userDetails?.city}
                                    onChange={e => setUserDetails({ ...userDetails, city: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">
                                      {/* Zip Code<span style={{ color: "#990000" }}>*</span> */}
                                      Zip Code
                                    </h5>
                                  </div>

                                  <div className="card-heading mb-3">
                                    <Input
                                      // type="number"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Zip Code"
                                      name="Zip Code"
                                      value={userDetails?.zipCode}
                                      onChange={e => handlePostalCode(e.target.value)}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              {userDetails?.state && (
                                <Col xl="4">
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                  </div>

                                  <div className="card-heading mb-4 flex">
                                    <h5 className="font-size-14 profileFont mb-2">{userDetails?.state}</h5>
                                  </div>
                                </Col>
                              )}
                              <Col xl={4}>
                                <>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">
                                      Select States That You Prefer:
                                      {selectTherapyTags?.length !== undefined && selectTherapyTags?.length !== 0 ? null : (
                                        <div className="tagError">Missing</div>
                                      )}
                                    </h5>
                                  </div>

                                  <Select
                                    onChange={(newValue: any) => handleTherapyStateTags(newValue)}
                                    value={selectTherapyTags}
                                    isMulti
                                    options={searchTherapyTags}
                                    className="basic-multi-select"
                                    placeholder="Choose a State"
                                    display="none"
                                    controlShouldRenderValue={false}
                                    classNamePrefix="select"
                                    isClearable={false}
                                  />

                                  <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                                    {selectTherapyTags.map((tagObj, id) => (
                                      <Tags label={tagObj.label} id={id} key={id} removeTag={removeTherapyStateTag} />
                                    ))}
                                  </div>
                                </>
                              </Col>
                            </>
                          )}

                          {userDetails?.role == "CLIENT" && (
                            <>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    {/* Marital Status<span style={{ color: "#990000" }}>*</span> */}
                                    Marital Status
                                    {userDetails?.maritalStatus?.length !== undefined && userDetails?.maritalStatus?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Row>
                                    <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios9"
                                          value="Single"
                                          defaultChecked={userDetails?.maritalStatus == "Single"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios9">
                                          Single
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios21"
                                          value="Engaged"
                                          defaultChecked={userDetails?.maritalStatus == "Engaged"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios21">
                                          Engaged
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios31"
                                          value="Married"
                                          defaultChecked={userDetails?.maritalStatus == "Married"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios31">
                                          Married
                                        </label>
                                      </div>

                                      <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios4"
                                          value="Partner"
                                          defaultChecked={userDetails?.maritalStatus == "Partner"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios4">
                                          Partner
                                        </label>
                                      </div>

                                      <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios5"
                                          value="Separated"
                                          defaultChecked={userDetails?.maritalStatus == "Separated"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios5">
                                          Separated
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios6"
                                          value="Divorced"
                                          defaultChecked={userDetails?.maritalStatus == "Divorced"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios6">
                                          Divorced
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios7"
                                          value="Re-married"
                                          defaultChecked={userDetails?.maritalStatus == "Re-married"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios7">
                                          Re-married
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id="exampleRadios8"
                                          value="Widowed"
                                          defaultChecked={userDetails?.maritalStatus == "Widowed"}
                                          onChange={e =>
                                            setUserDetails({
                                              ...userDetails,
                                              maritalStatus: e.target.value,
                                            })
                                          }
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios8">
                                          Widowed
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    {/* Street Address<span style={{ color: "#990000" }}>*</span> */}
                                    Street Address
                                    {userDetails?.streetAddress?.length !== undefined && userDetails?.streetAddress?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Street Address"
                                    name="Street Address"
                                    value={userDetails?.streetAddress}
                                    onChange={e => setUserDetails({ ...userDetails, streetAddress: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Apt./Unit</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Apt./Unit"
                                    name="Apt./Unit"
                                    value={userDetails?.unit}
                                    onChange={e => setUserDetails({ ...userDetails, unit: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    {/* City<span style={{ color: "#990000" }}>*</span> */}
                                    City
                                    {userDetails?.city?.length !== undefined && userDetails?.city?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="City"
                                    name="City"
                                    value={userDetails?.city}
                                    onChange={e => setUserDetails({ ...userDetails, city: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    {/* Zip Code<span style={{ color: "#990000" }}>*</span> */}
                                    Zip Code
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Zip Code"
                                    name="Zip Code"
                                    value={userDetails?.zipCode}
                                    onChange={e => handlePostalCode(e.target.value)}
                                    required
                                  />
                                </div>
                              </Col>

                              {userDetails?.state && (
                                <Col xl="4">
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                  </div>

                                  <div className="card-heading mb-4 flex">
                                    <h5 className="font-size-14 profileFont mb-2">{userDetails?.state}</h5>
                                  </div>
                                </Col>
                              )}

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Primary Phone/Cell<span style={{ color: "#990000" }}>*</span>
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <PhoneInput
                                    placeholder="Primary Phone "
                                    value={userDetails?.primaryPhone}
                                    defaultCountry="US"
                                    onChange={(e: string) => setUserDetails({ ...userDetails, primaryPhone: e })}
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Secondary/Home Phone</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <PhoneInput
                                    placeholder="Home Phone"
                                    value={userDetails?.homePhone}
                                    defaultCountry="US"
                                    onChange={(e: string) => setUserDetails({ ...userDetails, homePhone: e })}
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Work Phone</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <PhoneInput
                                    placeholder="WorkPhone Phone"
                                    value={userDetails?.workPhone}
                                    defaultCountry="US"
                                    onChange={(e: string) => setUserDetails({ ...userDetails, workPhone: e })}
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    May we leave a voicemail?
                                    {userDetails?.voiceMail?.length !== undefined && userDetails?.voiceMail?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <select
                                    className="form-control"
                                    value={userDetails?.voiceMail}
                                    onChange={e => setUserDetails({ ...userDetails, voiceMail: e.target.value })}
                                  >
                                    <option selected>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}

                      {userDetails.role == "THERAPIST" && (
                        <Col xl="12">
                          <Row>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-17 profileFont mb-4">Credentialing Information </h5>
                            </div>
                          </Row>

                          {!editProfile && (
                            <Row>
                              {(user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") && (
                                <Col xl="4">
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Social Security</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.socialSecurity || "-"}</h5>
                                  </div>
                                </Col>
                              )}

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">CAQH</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.cAQH || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">CAQH User name</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.caqhUserName || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">CAQH Pasword</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.caqhPassword || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">NPI 1</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.nPI1 || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">NPI 2</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{environment.NPI2}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Tax Identification </h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.taxIdentification || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">License </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.license || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Original Issue Date </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    {userDetails?.issueDate ? (
                                      <h5 className="font-size-14 mb-2">{moment.utc(userDetails?.issueDate || null).format("YYYY-MM-DD")}</h5>
                                    ) : (
                                      <h5 className="font-size-14 mb-3">{!userDetails?.issueDate && "-"}</h5>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Expiration Date </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    {userDetails?.expirationDate ? (
                                      <h5 className="font-size-14 mb-2">{moment.utc(userDetails?.expirationDate || null).format("YYYY-MM-DD")}</h5>
                                    ) : (
                                      <h5 className="font-size-14 mb-3">{!userDetails?.expirationDate && "-"}</h5>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Graduate School Name </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.schoolName || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Date of Graduation </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{!userDetails?.dateOfGraduation && "-"}</h5>
                                    {userDetails?.dateOfGraduation && (
                                      <h5 className="font-size-14 mb-2">{moment.utc(userDetails?.dateOfGraduation || null).format("YYYY-MM-DD")}</h5>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">School’s Street Address </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.schoolStreetAddress || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">School’s City </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.schoolCity || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">School’s State </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.schoolState || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">School’s Zip Code </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.schoolZipCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Taxonomy Code </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.taxonomyCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Malpractice Policy </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userDetails?.malpracticePolicy || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-1">
                                    <h5 className="font-size-14 profileFont mb-1">Malpractice Expiration Date </h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    {userDetails?.malpracticeExpirationDate ? (
                                      <h5 className="font-size-14 mb-3">{moment.utc(userDetails?.malpracticeExpirationDate || null).format("YYYY-MM-DD")}</h5>
                                    ) : (
                                      <h5 className="font-size-14 mb-3">{!userDetails?.malpracticeExpirationDate && "-"}</h5>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Medicaid User Name</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.medicaidUsername || "-"}</h5>
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Medicaid Password</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.MedicaidPassword || "-"}</h5>
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Medicaid ID</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.medicaidId || "-"}</h5>
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Psychology Today User name</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.psychologyTodayUsername || "-"}</h5>
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Psychology Today Password</h5>
                                </div>

                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userDetails?.psychologyTodayPassword || "-"}</h5>
                                </div>
                              </Col>
                            </Row>
                          )}

                          {editProfile && (
                            <Row>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Social Security
                                    {userDetails?.socialSecurity?.length !== undefined && userDetails?.socialSecurity?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Social Security"
                                    name="Social Security"
                                    value={userDetails?.socialSecurity || ""}
                                    maxLength={9}
                                    minLength={9}
                                    onChange={e => setUserDetails({ ...userDetails, socialSecurity: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    CAQH
                                    {userDetails?.cAQH?.length !== undefined && userDetails?.cAQH?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="CAQH"
                                    maxLength={8}
                                    name="CAQH"
                                    value={userDetails?.cAQH || ""}
                                    onChange={e => setUserDetails({ ...userDetails, cAQH: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    CAQH User Name
                                    {userDetails?.caqhUserName?.length !== undefined && userDetails?.caqhUserName?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-caqhname-Input"
                                    placeholder=""
                                    name="CAQH Name"
                                    value={userDetails?.caqhUserName || ""}
                                    onChange={e => setUserDetails({ ...userDetails, caqhUserName: e.target.value })}
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    CAQH Password
                                    {userDetails?.caqhPassword?.length !== undefined && userDetails?.caqhPassword?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-caqhpsd-Input"
                                    placeholder=""
                                    name="CAQH psd"
                                    value={userDetails?.caqhPassword || ""}
                                    onChange={e => setUserDetails({ ...userDetails, caqhPassword: e.target.value })}
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    NPI 1
                                    {userDetails?.nPI1?.length !== undefined && userDetails?.nPI1?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>
                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="NPI 1"
                                    name="NPI 1"
                                    maxLength={10}
                                    value={userDetails?.nPI1 || ""}
                                    onChange={e => setUserDetails({ ...userDetails, nPI1: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">NPI 2</h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <h5 className="font-size-14 mb-3"> {environment.NPI2}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Tax Identification
                                    {userDetails?.taxIdentification?.length !== undefined && userDetails?.taxIdentification?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Tax Identification"
                                    name="Tax Identification"
                                    value={userDetails?.taxIdentification || ""}
                                    onChange={e =>
                                      setUserDetails({
                                        ...userDetails,
                                        taxIdentification: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    License
                                    {userDetails?.license?.length !== undefined && userDetails?.license?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="License"
                                    name="License"
                                    value={userDetails?.license || ""}
                                    onChange={e => setUserDetails({ ...userDetails, license: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Original Issue Date {idDateValue || userDetails?.issueDate?.length !== 0 ? null : <div className="tagError">Missing</div>}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Original Issue Date"
                                    value={id || ""}
                                    onChange={e => handleIdDateUpdate(e)}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Expiration Date
                                    {edDateValue || (userDetails?.expirationDate?.length !== undefined && userDetails?.expirationDate?.length !== 0) ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Expiration Date"
                                    value={ed || ""}
                                    onChange={e => handleEdDateUpdate(e)}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Graduate School Name
                                    {userDetails?.schoolName?.length !== undefined && userDetails?.schoolName?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Graduate School Name "
                                    name="Graduate School Name "
                                    value={userDetails?.schoolName || ""}
                                    onChange={e => setUserDetails({ ...userDetails, schoolName: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Date of Graduation
                                    {dogDateValue ||
                                    (userDetails?.dateOfGraduation?.length !== undefined && userDetails?.dateOfGraduation?.length !== 0) ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Date of Graduation"
                                    value={dog || ""}
                                    onChange={e => handleDogDateUpdate(e)}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    School’s Street Address
                                    {userDetails?.schoolStreetAddress?.length !== undefined && userDetails?.schoolStreetAddress?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="School’s Street Address"
                                    name="School’s Street Address"
                                    value={userDetails?.schoolStreetAddress || ""}
                                    onChange={e =>
                                      setUserDetails({
                                        ...userDetails,
                                        schoolStreetAddress: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    School’s City
                                    {userDetails?.schoolCity?.length !== undefined && userDetails?.schoolCity?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="School’s City"
                                    name="School’s City"
                                    value={userDetails?.schoolCity || ""}
                                    onChange={e => setUserDetails({ ...userDetails, schoolCity: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    School’s State
                                    {userDetails?.schoolState?.length !== undefined && userDetails?.schoolState?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="School’s State"
                                    name="School’s State"
                                    value={userDetails?.schoolState || ""}
                                    onChange={e => setUserDetails({ ...userDetails, schoolState: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    School’s Zip Code
                                    {userDetails?.schoolZipCode?.length !== undefined && userDetails?.schoolZipCode?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="School’s Zip Code"
                                    name="School’s Zip Code"
                                    value={userDetails?.schoolZipCode || ""}
                                    onChange={e => setUserDetails({ ...userDetails, schoolZipCode: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Taxonomy Code
                                    {userDetails?.taxonomyCode?.length !== undefined && userDetails?.taxonomyCode?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Taxonomy Code"
                                    maxLength={10}
                                    name="Taxonomy Code"
                                    value={userDetails?.taxonomyCode || ""}
                                    onChange={e => setUserDetails({ ...userDetails, taxonomyCode: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Malpractice Policy
                                    {userDetails?.malpracticePolicy?.length !== undefined && userDetails?.malpracticePolicy?.length !== 0 ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Malpractice Policy"
                                    name="Malpractice Policy"
                                    value={userDetails?.malpracticePolicy || ""}
                                    onChange={e =>
                                      setUserDetails({
                                        ...userDetails,
                                        malpracticePolicy: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">
                                    Malpractice Expiration Date
                                    {medDateValue ||
                                    (userDetails?.malpracticeExpirationDate?.length !== undefined &&
                                      userDetails?.malpracticeExpirationDate?.length !== 0) ? null : (
                                      <div className="tagError">Missing</div>
                                    )}
                                  </h5>
                                </div>

                                <div className="card-heading mb-2 flex">
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Malpractice Expiration Date"
                                    value={med || ""}
                                    onChange={e => handleMedDateUpdate(e)}
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Medicaid User Name</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    // type="number"
                                    className="form-control"
                                    id="Medicaid User Name"
                                    placeholder="Medicaid username"
                                    name="Medicaid username"
                                    value={userDetails?.medicaidUsername}
                                    onChange={e => setUserDetails({ ...userDetails, medicaidUsername: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Medicaid Password</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    // type="number"
                                    className="form-control"
                                    id="fMedicaid password"
                                    placeholder="Medicaid password"
                                    name="Medicaid password"
                                    value={userDetails?.MedicaidPassword}
                                    onChange={e => setUserDetails({ ...userDetails, MedicaidPassword: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Medicaid ID</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    // type="number"
                                    className="form-control"
                                    id="fMedicaid ID"
                                    placeholder="Medicaid ID"
                                    name="Medicaid ID"
                                    value={userDetails?.medicaidId}
                                    onChange={e => setUserDetails({ ...userDetails, medicaidId: e.target.value })}
                                    required
                                  />
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Psychology Today User Name</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    // type="number"
                                    className="form-control"
                                    id="psychology today username"
                                    placeholder="psychology today username"
                                    name="psychology today username"
                                    value={userDetails?.psychologyTodayUsername}
                                    onChange={e =>
                                      setUserDetails({
                                        ...userDetails,
                                        psychologyTodayUsername: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Psychology Today Password</h5>
                                </div>

                                <div className="card-heading mb-3">
                                  <Input
                                    className="form-control"
                                    id="psychology today password"
                                    placeholder="psychology today password"
                                    name="psychology today password"
                                    value={userDetails?.psychologyTodayPassword}
                                    onChange={e =>
                                      setUserDetails({
                                        ...userDetails,
                                        psychologyTodayPassword: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </div>

      {modalcentervideo && (
        <div
          className="lightbox1"
          onClick={() => {
            setModalCenterVideo(false);
          }}
        >
          <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
            <div className="modal-body1">
              <iframe
                src={"https://player.vimeo.com/video/" + userDetails?.vimeoId}
                width="800"
                className="videoSize"
                height="400"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Lavni Health"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default ViewProfileSaas;
