
import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Nav, NavLink, NavItem } from "reactstrap";
import star from "../../assets/images/icons/fi_star.png";
import iconRewards from "../../assets/images/icons/fi_gift.svg";
import LineChart from "./LineChart";
import dollarSign from "../../assets/images/icons/fi_dollar-sign.svg";
import UserContext from "src/context/UserContext";
import { Link, useHistory } from "react-router-dom";
import { TherapistService } from "src/services/TherapistService";
import image0 from "../../assets/images/clientCharts/1.svg";
import image1 from "../../assets/images/clientCharts/2.svg";
import image2 from "../../assets/images/clientCharts/3.svg";
import image3 from "../../assets/images/clientCharts/4.svg";
import image4 from "../../assets/images/clientCharts/5.svg";
import image5 from "../../assets/images/clientCharts/6.svg";
import image6 from "../../assets/images/clientCharts/7.svg";
import image7 from "../../assets/images/clientCharts/8.svg";
import image8 from "../../assets/images/clientCharts/9.svg";
import image9 from "../../assets/images/clientCharts/10.svg";
import image10 from "../../assets/images/clientCharts/11.svg";
import image11 from "../../assets/images/clientCharts/12.svg";
import image12 from "../../assets/images/clientCharts/13.svg";
import SkeltonGraph from "src/common/skeleton/SkeltonGraph";
import default_profile_img from "../../assets/images/default_profile.png";
import Modal30 from "../Popup/Modal30";
import { ClientService } from "src/services/ClientService";
import { toast } from "react-toastify";
import { Therapist } from "src/models/Therapist";
import { FriendRequest } from "src/models/FriendRequest";

const classNames = require("classnames");

interface appointmentsStatsProps {
  therapistAppointmentStats?: any;
  clientStats: number;
  therapistEarningsStats?: any;
  isLoading: boolean;
  therapistTotalEarningsStats?: any;
  therapistMeetingStats?: any;
}

const WelcomeCompNew: React.FC<appointmentsStatsProps> = ({
  therapistAppointmentStats,
  clientStats,
  therapistEarningsStats,
  isLoading,
  therapistTotalEarningsStats,
  therapistMeetingStats
}) => {
  const [user, setUser] = useContext(UserContext);
  const [periodType, setPeriodType] = useState("YEAR");
  const [avatarImage, setAvatarImage] = useState("");
  const history = useHistory();
  const [pendingAppointmentCount, setPendingAppointmentCount] = useState<number>();
  const [completedAppointmentCount, setCompletedAppointmentCount] = useState<number>();
  const [graphLabels, setGraphLabels] = useState<any>();
  const [graphValues, setGraphValues] = useState<any>();
  const [therapistTotalEarningsStat, setTherapistTotalEarningsStat] = useState<any>();
  const [profilePictureOfTherapist, setProfilePictureOfTherapist] = useState("");
  const [enableDefaultTherapistPopup, setEnableDefaultTherapistPopup] = useState(false);
  const [clickedTherapist, setClickedTherapist] = useState<string>();
  const [matchedTherapists, setMatchedTherapists] = useState([] as FriendRequest[]);
  const [meetingStats, setMeetingStats] = useState<any>();
  const isNewDashboard = user && (user.isNewDashboard ?? true);

  const chartData = {
    labels: graphLabels,
    datasets: [
      {
        label: "Earning",
        data: graphValues,
        fill: false,
        borderColor: "#FF8F2D",
      },
    ],
  };

  const imagePaths = [image0, image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12];

  useEffect(() => {
    const imagePath = imagePaths[clientStats];
  }, [clientStats]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
    getStatData(pType);
  };

  const getStatData = (pType: any) => {
    const data = {
      filterValue: pType,
    };

    TherapistService.getDashboardEarningsStat(data).then(res => {
      setGraphLabels(res.data?.labels);
      setGraphValues(res.data?.value);
    });

    TherapistService.getDashboardSessionStat(data).then(res => {
      setCompletedAppointmentCount(res.data?.completedAppointments);
      setPendingAppointmentCount(res.data?.pendingAppointments);
    });
  };

  useEffect(() => {
    if (!user?.useDefaultAvatar) {
      if (user?.avatarImage) {
        const avatarImageUrl = user?.avatarImage;
        let leftPart;
        const lastIndexOfGlb = avatarImageUrl.lastIndexOf(".glb");

        if (lastIndexOfGlb !== -1) {
          leftPart = avatarImageUrl.substring(0, lastIndexOfGlb);
          const finalAvatarImageUrl = leftPart + ".png?blendShapes%5BmouthSmile%5D=0.8";
          setAvatarImage(finalAvatarImageUrl);
        } else {
          console.log("The string does not contain '.glb'.");
        }
      }
    }

    if (user?.photoId) {
      setProfilePictureOfTherapist(user?.photoId?.url);
    }
  }, []);

  useEffect(() => {
    setCompletedAppointmentCount(therapistAppointmentStats?.completedAppointments);
    setPendingAppointmentCount(therapistAppointmentStats?.pendingAppointments);
    setGraphLabels(therapistEarningsStats?.labels);
    setGraphValues(therapistEarningsStats?.value);
    setTherapistTotalEarningsStat(therapistTotalEarningsStats);
    setMeetingStats(therapistMeetingStats);
  }, [therapistAppointmentStats, therapistEarningsStats]);

  const changePrimaryTherapist = (therapist: Therapist) => {
    setClickedTherapist(therapist._id);

    ClientService.changePrimaryTherapist({ "therapistId": therapist._id }).then((res: any) => {
      if (res.success) {
        setUser({ ...user, primaryTherapist: therapist });

        toast.success(res.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setClickedTherapist("");
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setClickedTherapist("");
      }
    });
  }

  const loadMatchedTherapists = () => {
    if (matchedTherapists.length > 0) {
      setEnableDefaultTherapistPopup(true);
    } else {
      ClientService.getAllMatchedTherapists().then((res: any) => {
        if (res.success) {
          if (res.data.length) {
            setMatchedTherapists(res.data);
            setEnableDefaultTherapistPopup(true);
          } else {
            toast.error("You haven't matched with any therapist yet!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Card className="welcome-card-footer">
        <div className="welcome-card-header overflow-hidden-welcome">
          <Row className="w-100 d-flex justify-content-between align-items-center">
            <Col xs="8" className="d-flex align-items-center">
              <div className="text-primary p-3">
                <h5>
                  Hi &nbsp;
                  <span className="text-primary">
                    {user?.firstname}&nbsp;{user?.lastname}
                  </span>
                </h5>

                <div>
                  {clientStats > 12 && (<p className="role mb-sm-2 mb-0">Total Sessions {clientStats} </p>)}
                </div>

                {user?.role == "CLIENT" && (
                  <div className="default-therapist">
                    <p className="role mb-sm-2 mb-0">Default Therapist - <span className="dt-name">{user?.primaryTherapist?.firstname + " " + user?.primaryTherapist?.lastname}</span> </p>

                    <button className="btn btn-default btn-sm dt-btn" onClick={() => loadMatchedTherapists()}>
                      <i className="bx bx-edit dt-icon"></i>
                    </button>
                  </div>
                )}

                {(user?.role == "THERAPIST" && user?.isNewDashboard === false) && (
                  <>
                    <a href={`therapist-referral/${user?._id}`} className="btn btn-primary  video-btn-bg-welcome">
                      <img src={iconRewards} className="icon-referel me-2" /> Rewards
                    </a>
                  </>
                )}

                {user?.role == "CLIENT" && (
                  <>
                    <a href={`client-referral/${user?._id}`} className="btn btn-primary video-btn-bg-welcome">
                      <img src={iconRewards} className="icon-referel me-2" /> Rewards
                    </a>
                  </>
                )}
              </div>
            </Col>

            <Col
              xs="4"
              className={`${user?.role == "CLIENT" && "align-content-around"} ${user?.role == "THERAPIST" && "d-flex justify-content-center align-items-center"}`}
            >
              {user?.role == "CLIENT" ? (
                avatarImage == "" ? (

                  <div className="img-thumbnail imageFit rounded-circle imageFit  profile-user-wid-pro items-center margin_center profileImageShow"
                    style={{
                      backgroundImage: `url(${user?.photoId?.url || default_profile_img})`, backgroundSize: 'cover',
                      borderRadius: "10px",
                      position: "relative",
                      height: "95px",
                      width: "95px",
                      marginTop: "10px"
                    }} ></div>

                ) : (
                  <div style={{ height: "200px", overflow: "hidden", position: "relative" }}>
                    <img src={avatarImage} className="img-fluid" />
                  </div>
                )
              ) : profilePictureOfTherapist == "" ? (
                <img src={default_profile_img} className="img-avater-dp-lg2" />
              ) : (
                <img src={profilePictureOfTherapist} className="img-avater-dp-lg2" />
              )}
            </Col>
          </Row>
        </div>
        {(isNewDashboard !== undefined && isNewDashboard === false) && (
          <div className="overflow-hidden-welcome pt-3">
            <Row>
              {(user?.role == "THERAPIST") && (meetingStats?.allMeetingCount && meetingStats.allMeetingCount > 0) && (
                <>
                  <Row className="welcome-subscribe-card justify-content-center align-items-center pe-1">
                    <div className="row pt-3 pb-3 pe-1 text-center">
                      <div className="col-4">
                        <p className="mb-0 fs-12 fs-black2">Uncompleted Assessment Form</p>
                        <p className="mb-0 mt-0 fs-16 fc-black circle-container">{meetingStats?.uncompletedAssessmentsCount ?? 0}</p>
                      </div>
                      <div className="col-4 lb-welcome-card">
                        <p className="mb-0 fs-12 fs-black2">Uncompleted Therapy Plan Form</p>
                        <p className="mb-0 mt-0 fs-16 fc-black circle-container">{meetingStats?.uncompletedTherapyPlanCount ?? 0}</p>
                      </div>
                      <div className="col-4 lb-welcome-card">
                        <p className="mb-0 fs-12 fs-black2">Uncompleted Authorization Form</p>
                        <p className="mb-0 mt-0 fs-16 fc-black circle-container">{meetingStats?.uncompletedAuthorizationFormsCount ?? 0}</p>
                      </div>
                    </div><hr />
                    <div className="d-flex justify-content-center align-items-center pe-1">
                      <a href="/approvals" className="btn btn-primary view-more-btn mb-3">View Full Details</a>
                    </div>
                  </Row>
                </>
              )}
            </Row>
          </div>
        )}

        <CardBody className="pt-0 ">
          {user?.role == "THERAPIST" && (
            <>
              {isNewDashboard && (
                <Row className="welcome-subscribe-card-new">
                  <div className="row pt-3 pb-3">
                    <div className="col-4 d-flex justify-content-center align-items-center">
                      <div>
                        <p className="mb-0 mt-0 fs-15 fc-black">Total Earnings </p>
                        <a href="/earnings" className="btn btn-primary view-more-btn mt-1">View more</a>
                      </div>
                      <img src={dollarSign} className="d-none d-md-block d-lg-none" />
                    </div>
                    <div className="col-4 lb-welcome-card">
                      <p className="mb-0 fs-14 fs-black2">Total Revenue</p>
                      <p className={`mb-0 mt-0 fc-black ${isNewDashboard ? "fs-20" : "fs-24"}`}>
                        $ {therapistTotalEarningsStat?.totalRevenue ? Math.round(therapistTotalEarningsStat?.totalRevenue * 100) / 100 : "0"}
                      </p>
                    </div>
                    <div className="col-4 lb-welcome-card">
                      <p className="mb-0 fs-14 fs-black2">Current Month</p>
                      <p className={`mb-0 mt-0 fc-black ${isNewDashboard ? "fs-20" : "fs-24"}`}>
                        $ {therapistTotalEarningsStat?.totalRevenueCurrentMonth ? Math.round(therapistTotalEarningsStat?.totalRevenueCurrentMonth * 100) / 100 : "0"}
                      </p>
                    </div>
                  </div>
                </Row>
              )}
              <Row>
                <div className="ms-auto mt-3 p-0">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames({ active: periodType === "WEEK" }, "nav-link")}
                        onClick={() => {
                          onChangeChartPeriod("WEEK");
                        }}
                        id="WEEK"
                      >
                        Week
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames({ active: periodType === "MONTH" }, "nav-link")}
                        onClick={() => {
                          onChangeChartPeriod("MONTH");
                        }}
                        id="MONTH"
                      >
                        Month
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames({ active: periodType === "4MONTH" }, "nav-link")}
                        onClick={() => {
                          onChangeChartPeriod("4MONTH");
                        }}
                        id="4MONTH"
                      >
                        Quater
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames({ active: periodType === "YEAR" }, "nav-link")}
                        onClick={() => {
                          onChangeChartPeriod("YEAR");
                        }}
                        id="YEAR"
                      >
                        Year
                      </Link>
                    </li>
                  </ul>
                </div>
              </Row>
              <Row className="mt-4 mb-2 gap-2">
                <Col className="text-center welcome-card-item fc-black pt-1">
                  <h6>New Sessions</h6>
                  <h4 className="fc-black">{pendingAppointmentCount ? pendingAppointmentCount : "-"}</h4>
                </Col>
                <Col className="text-center welcome-card-item fc-black pt-1">
                  <h6>Completed Appointments</h6>
                  <h4 className="fc-black">{completedAppointmentCount ? completedAppointmentCount : "-"}</h4>
                </Col>
              </Row>
            </>
          )}

          {user?.role == "CLIENT" && (
            <>
              <Row className=" d-flex justify-content-center  mb-4 mt-5 align-items-center">
                <Col className=" d-flex justify-content-between">
                  <img src={clientStats <= 12 ? imagePaths[clientStats] : image12} className="min-width-100 tagertBoard-img" alt={`Image for clientStats ${clientStats}`} />
                </Col>
              </Row>

              <Row className="welcome-subscribe-card mt-4 d-flex justify-content-between ps-4 pe-4">
                <Col className="pt-2 pb-2 mt-2 mb-2">
                  <h5 className="mb-0 fs-15 fc-black pt-1 pb-1">Subscription</h5>
                </Col>
                <Col className="pt-2 pb-2 mt-2 mb-2">
                  <div
                    className="primium-card d-flex justify-content-center align-items-center pt-1 pb-1 ps-1 pe-1 cursor-pointer"
                    onClick={() => {
                      history.push(`/subscription`);
                    }}
                  >
                    {user?.premiumStatus == "active" ? (
                      <h5 className="mb-0 fs-15 fc-black">Premium</h5>
                    ) : user?.subscriptionStatus == "active" || user?.testSubscriptionStatus == "active" ? (
                      <>
                        <h5 className="mb-0 fs-15 fc-black">Active</h5>
                      </>
                    ) : user?.subscriptionStatus == "past_due" ? (
                      <h5 className="mb-0 fs-15 fc-black">Past Due</h5>
                    ) : user?.subscriptionStatus == "canceled" ? (
                      <h5 className="mb-0 fs-15 fc-black">Cancelled</h5>
                    ) : user?.subscriptionStatus == "incomplete" ? (
                      <h5 className="mb-0 fs-15 fc-black">Incomplete</h5>
                    ) : user?.subscriptionStatus == "incomplete_expired" ? (
                      <h5 className="mb-0 fs-15 fc-black">Expired</h5>
                    ) : (
                      <></>
                    )}
                    {/* <h5 className="mb-0 fs-15 fc-black">Premium</h5> */}
                    <img src={star} className="ms-2" />
                  </div>
                </Col>
              </Row>

              {enableDefaultTherapistPopup && <Modal30 showModal={enableDefaultTherapistPopup} setShowModal={setEnableDefaultTherapistPopup} matchedTherapists={matchedTherapists} changePrimaryTherapist={changePrimaryTherapist} clickedTherapist={clickedTherapist} />}
            </>
          )}

          {user?.role == "THERAPIST" && (
            <>
              <Row>
                <Col className="p-4 ">
                  {
                    isLoading && <SkeltonGraph />
                  }
                  {
                    !isLoading && <LineChart data={chartData} />
                  }
                </Col>
              </Row>
              {!isNewDashboard && (
                <Row className="welcome-subscribe-card">
                  <div className="row pt-3 pb-3">
                    <div className="col-4 d-flex justify-content-center align-items-center">
                      <div>
                        <p className="mb-0 mt-0 fs-15 fc-black">Total Earnings </p>
                        <a href="/earnings" className="btn btn-primary view-more-btn mt-1">View more</a>
                      </div>
                      <img src={dollarSign} className="d-none d-md-block d-lg-none" />
                    </div>
                    <div className="col-4 lb-welcome-card">
                      <p className="mb-0 fs-14 fs-black2">Total Revenue</p>
                      <p className={`mb-0 mt-0 fc-black ${isNewDashboard ? "fs-20" : "fs-24"}`}>
                        $ {therapistTotalEarningsStat?.totalRevenue ? Math.round(therapistTotalEarningsStat?.totalRevenue * 100) / 100 : "0"}
                      </p>
                    </div>
                    <div className="col-4 lb-welcome-card">
                      <p className="mb-0 fs-14 fs-black2">Current Month</p>
                      <p className={`mb-0 mt-0 fc-black ${isNewDashboard ? "fs-20" : "fs-24"}`}>
                        $ {therapistTotalEarningsStat?.totalRevenueCurrentMonth ? Math.round(therapistTotalEarningsStat?.totalRevenueCurrentMonth * 100) / 100 : "0"}
                      </p>
                    </div>
                  </div>
                </Row>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WelcomeCompNew;