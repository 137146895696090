import React, { useContext, useEffect, useState } from "react";
import { CardTitle, Row } from "reactstrap";
import UserContext from "src/context/UserContext";
import { Link, useParams } from "react-router-dom";
import { TransactionService } from "src/services/TransactionService";
import stripeConnect from "../../assets/images/stripe_connect.png";
import { toast } from "react-toastify";
// import { Transaction } from "src/models/Transaction";
import { ParameterTypes } from "../../utils/ParameterTypes";

const StripeDetails = () => {
  const [user, setUser] = useContext(UserContext);
  const [registerLink, setRegisterLink] = useState("");
  // const [transactions, setTransactions] = useState([] as Transaction[]);
  // const [firstDayOfNextMonth, setFirstDayOfNextMonth] = useState("");
  const { stripeConnected } = useParams<ParameterTypes>();
  const [isMobile, setIsMobile] = useState(false);
  // const [tranceCount, setTranceCount] = useState(10);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   transactionsCheck();
  //   const date = new Date();
  //   const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  //   setFirstDayOfNextMonth(firstDayOfMonth.getMonth() + 1 + "/" + firstDayOfMonth.getDate() + "/" + firstDayOfMonth.getFullYear());
  // }, []);

  useEffect(() => {
    if (stripeConnected && stripeConnected == "stripe-connected") {
      TransactionService.verifyStripeAccount().then((res: any) => {
        if (res.success) {
          if (res.data.enabledForPayments) {
            setUser({ ...user, stripeChargesEnabled: true, stripeDetailsSubmitted: true });
          }
        } else {
          toast.error("Verify Stripe Failed.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  }, [stripeConnected]);

  useEffect(() => {
    window.screen.width <= 768 ? setIsMobile(true) : setIsMobile(false);
  }, [window.screen.width]);

  const createStripeDashboardLink = () => {
    TransactionService.createStripeLoginLink().then(res => {
      if (res.success) {
        setUser({ ...user, stripeConnectedLoginLink: res.data });
      }
    });
  };

  const createStripeConnectedAccount = () => {
    TransactionService.createStripeConnectedAccount().then((res: any) => {
      if (res.success) {
        setRegisterLink(res.data.url);
      } else {
        toast.error("Failed to create stripe connected account.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const verifyStripeAccount = () => {
    TransactionService.verifyStripeAccount().then((res: any) => {
      if (res.success) {
        console.log(res);

        if (res.data.enabledForPayments) {
          setUser({ ...user, stripeChargesEnabled: true, stripeDetailsSubmitted: true });
        } else {
          setRegisterLink(res.data.accountLink.url);
        }
      } else {
        toast.error("Verify Stripe Failed.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  // const transactionsCheck = () => {
  //   setIsLoading(true);
  //   TransactionService.getAllTransactions().then(res => {
  //     if (res.success) {
  //       setTransactions(res.data);
  //       setTranceCount(res.data?.length);
  //     }
  //     setIsLoading(false);
  //   });
  // };

  return (
    <div>
      <Row>
        {/* <div className="card-heading mb-2">
          <h5 className="font-size-14 mb-2">Stripe Settings:</h5>
        </div> */}
        <div className="row">
          <div className="my-sub-sec withdraw-money">
            {user?.stripeConnectedAccountId && user.stripeChargesEnabled && user.stripeDetailsSubmitted && (
              <>
                {user?.stripeConnectedLoginLink ? (
                  <Link to={{ pathname: user?.stripeConnectedLoginLink }} target="_blank" className="btn btn-info">
                    Stripe Dashboard&nbsp;&nbsp;<i className="fa fa-bookmark"></i>
                  </Link>
                ) : (
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      createStripeDashboardLink();
                    }}
                  >
                    Create Stripe Dashboard Link To Check Balance!
                  </button>
                )}
              </>
            )}
            {!user?.stripeConnectedAccountId ? (
              registerLink == "" ? (
                <div>
                  <CardTitle className="mb-4 font-size-20">First you need to register with Lavni Stripe Connect.</CardTitle>

                  <div className="flex">
                    <img
                      src={stripeConnect}
                      className="stripe-connect"
                      onClick={() => {
                        createStripeConnectedAccount();
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <CardTitle className="mb-4 font-size-20">You need to complete stripe details in order to get paid on Lavni Platform.</CardTitle>

                  <div className="flex">
                    <Link to={{ pathname: registerLink }} target="_blank" className="btn btn-info">
                      Complete Stripe Account&nbsp;&nbsp;<i className="fa fa-address-card"></i>
                    </Link>
                  </div>
                </div>
              )
            ) : !user.stripeChargesEnabled || !user.stripeDetailsSubmitted ? (
              <>
                {registerLink == "" ? (
                  <div>
                    <CardTitle className="mb-4 font-size-20">You need to verify Lavni Stripe Connect.</CardTitle>

                    <div className="flex">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          verifyStripeAccount();
                        }}
                      >
                        Verify Stripe Account!
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <CardTitle className="mb-4 font-size-20">You need to complete stripe details in order to get paid on Lavni Platform.</CardTitle>

                    <div className="flex">
                      <Link to={{ pathname: registerLink }} target="_blank" className="btn btn-info">
                        Complete Stripe Account&nbsp;&nbsp;<i className="fa fa-address-card"></i>
                      </Link>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {/* {transactions && transactions.length > 0 && (
                  <>
                    {transactions[0].accumulatedBalance > 0 ? (
                      <div className="mt30">
                        <h4 className="mb-3">
                          Your available balance <span className="sub-item4-text">${Math.round(transactions[0].accumulatedBalance * 100) / 100}</span> will be
                          withdrwan automatically at the end of the month. ({firstDayOfNextMonth.toString()})
                        </h4>
                      </div>
                    ) : (
                      <div className="mt30">
                        <h4 className="mb-3">You should have positive balance to withdraw funds!</h4>
                      </div>
                    )}
                  </>
                )} */}
              </>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default StripeDetails;
