import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Col } from "reactstrap";
import Close from "../../assets/images/icons/u_times-circle.png";

interface FilePreviewModalPropTypes {
  isOpen: boolean;
  toggle: () => void;
  fileType: string;
  fileData: string;
  title: string;
  description: string;
}

const FilePreviewModal: React.FC<FilePreviewModalPropTypes> = ({ isOpen, toggle, fileType, fileData, title, description }) => {
  const previewStyle = {
    width: "100%",
    height: "90vh",
    objectFit: "contain" as const,
    border: "1px solid #ddd",
    padding: "10px",
  };

  const renderContent = () => {
    if (fileType.startsWith("image/")) {
      return <img src={fileData} alt="Image preview" style={previewStyle} />;
    } else if (fileType.startsWith("video/")) {
      return (
        <video controls style={previewStyle}>
          <source src={fileData} type={fileType} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (fileType === "application/pdf") {
      return <embed src={fileData} type="application/pdf" style={previewStyle} />;
    } else {
      return <p>Unsupported file type</p>;
    }
  };
  return (

    <Modal
       isOpen={isOpen}
       toggle={toggle}
       className= "assesment-modal"
       centered
       fullscreen
       scrollable
    >
      <div className="d-flex flex-row justify-content-between align-items-center px-4 py-3">
          <h2>{title}</h2>
          <button
            onClick={toggle}
            style={{ background: 'none', border: 'none', position: 'absolute', right: '1rem', top: '1rem' }}

         >
            <img src={Close} alt="Close" className="close-icon-d" />
          </button>
      </div>

       <ModalBody>

        {renderContent()}
        <p className="py-1 text-secondary p-wrap">{description}</p>
       </ModalBody>

  </Modal>

  );
};

export default FilePreviewModal;
