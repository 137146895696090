import React from 'react';

import Mic from '@material-ui/icons/Mic';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

const AudioSettings = ({ hasAudio, onAudioChange, className }) => {
  return (
    <div className={className}>
      <Mic />
      <div>Microphone</div>
      <Switch checked={hasAudio} onChange={onAudioChange} name="AudioToggle" />
    </div>
  );
};
AudioSettings.propTypes = {
  hasAudio: PropTypes.bool.isRequired,
  onAudioChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
export default AudioSettings;
