import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Input, Modal, Row } from "reactstrap";
import { Upload } from "tus-js-client";
import UserContext from "../../../context/UserContext";
import user1 from "../../../assets/images/default_profile.png";
import profileBackground from "../../../assets/images/default_cover.png";
import videoIcon from "../../../assets/images/icon_video.svg";
import { Review } from "../../../models/Review";
import Modal1 from "../../Popup/Modal1";
import Modal2 from "../../Popup/Modal2";
import { Util } from "../../../Util";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { ClientService } from "../../../services/ClientService";
import Spinner from "../../../common/spinner/spinner";
import { array } from "prop-types";

interface UserDetailsProps {
  viewDetails: (e: any) => void;
  profileDetails: any;
  reviewTherapist?: any;
}

const UserDetails: React.FC<UserDetailsProps> = ({ viewDetails, profileDetails, reviewTherapist }: UserDetailsProps) => {
  const [user, setUser] = useContext(UserContext);
  const [isOpenMessage, setIsOpenMessage] = React.useState(false);
  const [isOpenSuccessMessage, setIsOpenSuccessMessage] = React.useState(false);
  const [loadingProfileData, setLoadingProfileData] = useState(false);
  const [isOpenAllProfile, showAllProfile] = React.useState(true);
  const [modal_center, setModalCenter] = React.useState(false);
  const [rating1, setRating1] = useState(0);
  const [modalcentervideo, setModalCenterVideo] = React.useState(false);
  const [addReview, setReview] = useState<Review>({} as Review);
  const [isOpenAllReview, showAllReviews] = React.useState(false);
  const [reviewTherapists, setReviewTherapist] = React.useState([] as any);
  const [userProfileDetails, setUserProfileDetails] = React.useState({} as any);

  const addReviewRef = useRef<any>();
  addReviewRef.current = addReview;

  useEffect(() => {
    setLoadingProfileData(true);
    // setReviewTherapist(reviewTherapist);
    setUserProfileDetails(profileDetails);
    getReviewByTherapistId(profileDetails);
  }, [profileDetails, reviewTherapist]);

  const getReviewByTherapistId = (profiledata: any) => {
    const data = {
      therapistId: profiledata?._id,
    };
    ClientService.getAllTherapistApprovedReviews(data, 1000, 0).then(res => {
      if (res.success) {
        setReviewTherapist(res.data);
      }
    });
  };

  function togCenterReview() {
    setModalCenter(!modal_center);
    setReview({ review: "" });
    setRating1(0);
  }

  function togCenterVideo() {
    setModalCenterVideo(!modalcentervideo);
  }

  const FileThumbnail: React.FC<{ file: Upload | any }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  const sendMessage = () => {
    setIsOpenMessage(!isOpenMessage);
    setIsOpenSuccessMessage(!isOpenSuccessMessage);
  };

  const starsArray =
    reviewTherapists &&
    reviewTherapists.map((val: any) => {
      return val.stars;
    });
  const result = starsArray && starsArray.reduce((total: any, currentValue: any) => (total = total + Number(currentValue)), 0);
  const final = result / (reviewTherapists && reviewTherapists.length);
  const finalData = Math.round(final);

  const colordStars: any[] = [];
  const uncolordStars: any[] = [];

  for (let i = 0; i < finalData; i++) {
    colordStars.push(
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
        <path
          d="M19.9478 8.05776C19.8169 7.63517 19.4577 7.33502 19.0327 7.29506L13.2602 6.74811L10.9776 1.17306C10.8093 0.764478 10.426 0.5 10.0001 0.5C9.5742 0.5 9.19089 0.764478 9.02258 1.17401L6.73996 6.74811L0.966512 7.29506C0.542308 7.33598 0.184023 7.63517 0.0523364 8.05776C-0.0793501 8.48035 0.0422653 8.94386 0.363165 9.23605L4.72652 13.2292L3.43987 19.1434C3.34572 19.5782 3.50747 20.0277 3.85324 20.2885C4.03909 20.4287 4.25654 20.5 4.47581 20.5C4.66487 20.5 4.85241 20.4468 5.02072 20.3417L10.0001 17.2363L14.9776 20.3417C15.3419 20.5704 15.801 20.5495 16.146 20.2885C16.4919 20.0269 16.6535 19.5773 16.5594 19.1434L15.2727 13.2292L19.6361 9.23684C19.957 8.94386 20.0795 8.48115 19.9478 8.05776Z"
          fill="#F2C94C"
        />
      </svg>
    );
  }

  for (let i = finalData; i < 5; i++) {
    uncolordStars.push(
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
        <path
          d="M19.9478 8.05776C19.8169 7.63517 19.4577 7.33502 19.0327 7.29506L13.2602 6.74811L10.9776 1.17306C10.8093 0.764478 10.426 0.5 10.0001 0.5C9.5742 0.5 9.19089 0.764478 9.02258 1.17401L6.73996 6.74811L0.966512 7.29506C0.542308 7.33598 0.184023 7.63517 0.0523364 8.05776C-0.0793501 8.48035 0.0422653 8.94386 0.363165 9.23605L4.72652 13.2292L3.43987 19.1434C3.34572 19.5782 3.50747 20.0277 3.85324 20.2885C4.03909 20.4287 4.25654 20.5 4.47581 20.5C4.66487 20.5 4.85241 20.4468 5.02072 20.3417L10.0001 17.2363L14.9776 20.3417C15.3419 20.5704 15.801 20.5495 16.146 20.2885C16.4919 20.0269 16.6535 19.5773 16.5594 19.1434L15.2727 13.2292L19.6361 9.23684C19.957 8.94386 20.0795 8.48115 19.9478 8.05776Z"
          stroke="#F2C94C"
        />
      </svg>
    );
  }

  const showReviews = () => {
    showAllProfile(!isOpenAllProfile);
    showAllReviews(!isOpenAllReview);
  };

  const AddReviewData = () => {
    const reviewData = { ...addReviewRef.current, therapistId: userProfileDetails._id, noOfStars: rating1 };
    ClientService.addClientReview(reviewData).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setReview({ review: "" });
        setRating1(0);
        setModalCenter(false);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl="6">
          <div
            className="flex flex-wrap  cursor-pointer"
            onClick={() => {
              viewDetails(false);
              setUserProfileDetails(null);
            }}
          >
            <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
            <CardTitle className="mb-4 cursor_pointer">&nbsp; Back to dashboard</CardTitle>
          </div>
        </Col>
        <Col xl="6">
          {user?.role == "CLIENT" && userProfileDetails && (
            <Row style={{ float: "right" }}>
              <Col xl="12" style={{ display: "flex" }}>
                {colordStars}
                {uncolordStars}

                <p className="text-muted star_margin">({reviewTherapists?.length == 0 ? 0 : finalData}/ 5)</p>
                <p className="star_margin_text font-size-15 cursor_pointer" style={{ color: "#BF6000" }} onClick={() => showReviews()}>
                  Reviews ({reviewTherapists?.length})
                </p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Card>
        <Row>
          <Col className="col-xl-12 p-5">
            {userProfileDetails ? (
              <div className="card-bodyDiv1">
                <Row>
                  <Col xl="12">
                    <Row>
                      <div
                        className="profileImage imageFit BorderRadiusLeftRight"
                        style={{
                          backgroundImage:
                            userProfileDetails?.coverPhotoId == null || userProfileDetails?.coverPhotoId == undefined || !userProfileDetails?.coverPhotoId
                              ? `url(${profileBackground})`
                              : `url(${Util.fileURL(userProfileDetails?.coverPhotoId?._id)})`,
                        }}
                      ></div>

                      <Row>
                        <Col xl={6}>
                          {userProfileDetails?.photoId && (
                            <div
                              className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl profile-user-wid-pro items-center margin_center mb-4 profileImageShow"
                              style={{
                                backgroundImage:
                                  userProfileDetails?.photoId == null || userProfileDetails?.photoId == undefined
                                    ? `url(${user1})`
                                    : `url(${Util.fileURL(userProfileDetails?.photoId?._id)})`,
                                borderRadius: "10px",
                                position: "relative",
                              }}
                            ></div>
                          )}
                        </Col>

                        <Col xl={6}>
                          <div>
                            {userProfileDetails?.role == "THERAPIST" && (
                              <button
                                type="button"
                                className="btn btn-primary pl-3 pr-3 pt-2 pb-2 relative mt-3 mb-4"
                                style={{
                                  bottom: "0",
                                  float: "right",
                                }}
                                onClick={() => {
                                  togCenterReview();
                                }}
                              >
                                Leave a review
                              </button>
                            )}
                          </div>

                          {userProfileDetails?.role == "THERAPIST" && (
                            <div>
                              {userProfileDetails?.vimeoId && (
                                <div
                                  className="btn-video relative mt-3 pl-3 pr-3 pt-1 pb-1 mr-2 mb-4 cursor-pointer"
                                  style={{
                                    bottom: "0",
                                    float: "right",
                                  }}
                                  onClick={() => {
                                    togCenterVideo();
                                  }}
                                >
                                  <img src={videoIcon} className="mr-2" />
                                  Watch Video
                                </div>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Row>
                    <Row className=" pl-5 pr-5 mb-4">
                      {isOpenAllProfile && (
                        <Col xl="12">
                          <Row>
                            {user?.role == "THERAPIST" && (
                              <Col xl={12}>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-3">About Them</h5>
                                </div>
                                <div className="card-heading mb-2 pr-5">
                                 
                                  <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userProfileDetails?.description || "-" }} />
                                </div>
                              </Col>
                            )}

                            {user?.role == "CLIENT" && (
                              <Col xl={4}>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-3">About Them</h5>
                                </div>
                                <div className="card-heading mb-2 pr-5">
                                <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userProfileDetails?.description || "-" }} />
                                </div>
                              </Col>
                            )}

                            <Col xl={4}>
                              {userProfileDetails?.role == "THERAPIST" && (
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">Working days and hours</h5>
                                  </div>
                                  <div className="card-heading mb-4">
                                    {userProfileDetails?.workingHours?.length == 0 && "-"}
                                    {userProfileDetails?.workingHours &&
                                      userProfileDetails?.workingHours.map((opt: any, index: any) => (
                                        <h5 className="font-size-14 mb-2" key={index}>

                                          {opt.day} | {Util.convertUTCDateToLocalDate12Hour(opt.startTime)} - {Util.convertUTCDateToLocalDate12Hour(opt.endTime)}
                                        </h5>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </Col>

                            <Col xl={4}>
                              {userProfileDetails?.role == "THERAPIST" && (
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">Professional experience</h5>
                                  </div>

                                  <div className="card-heading mb-2">
                                    {userProfileDetails?.experiencedIn?.length == 0 && "-"}

                                    {userProfileDetails?.experiencedIn?.map((ex: any) => {
                                      return (
                                        <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                          {ex.experienceTag}
                                        </Link>
                                      );
                                    })}
                                  </div>

                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Profession</h5>
                                  </div>

                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userProfileDetails?.profession?.name || "-"}</h5>
                                  </div>

                                  {userProfileDetails?.professionLicense?._id && (
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Profession License</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{userProfileDetails?.professionLicense?.name || "-"}</h5>
                                      </div>
                                    </div>
                                  )}

                                  {userProfileDetails?.disclosureStatementId !== null && (
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Your professional disclosure statement:</h5>
                                      </div>

                                      <div className="flex  mb-2">
                                        <div className="mr-2">
                                          <Link
                                            to={{ pathname: Util.fileURL(userProfileDetails?.disclosureStatementId?._id) }}
                                            target="_blank"
                                            className="events-icon2"
                                            style={{ fontSize: "62px" }}
                                          >
                                            <FileThumbnail file={userProfileDetails?.disclosureStatementId as Upload} />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12 mt50">
                              <hr />
                            </Col>
                            <Col xl="6"></Col>
                            <Col xl="6" className="text-right"></Col>
                          </Row>

                          <Row>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-17 profileFont mb-4">Personal Information</h5>
                            </div>

                            <Row>
                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Name</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">
                                    {userProfileDetails?.firstname || "-"} {userProfileDetails?.lastname}
                                  </h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userProfileDetails?.gender || "-"}</h5>
                                </div>
                              </Col>

                              {user?.role != "CLIENT" && (
                                <Col xl="4">
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Age</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    {userProfileDetails?.dateOfBirth ? (
                                      <h5 className="font-size-14 mb-2">{moment().diff(moment(userProfileDetails?.dateOfBirth), "years")} years</h5>
                                    ) : (
                                      // <h5 className="font-size-14 mb-2">{birthYear} years {birthMonth} months {birthDays} days</h5>
                                      <h5 className="font-size-14 mb-2">{!userProfileDetails?.dateOfBirth && "-"}</h5>
                                    )}
                                  </div>
                                </Col>
                              )}

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Ethnicity</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-3">{userProfileDetails?.ethnicityId?.ethnicity || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="4">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">User Name</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userProfileDetails?.username || "-"}</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      )}

                      {isOpenAllReview && (
                        <Col xl={12}>
                          <Row>
                            {user?.role == "CLIENT" && reviewTherapists.length == 0 && <div className="font-size-14 mb-5 mt-1">No Reviews to display.</div>}
                            {user?.role == "CLIENT" &&
                              reviewTherapists.map((reviewDate: any, _id: any) => {
                                const result: any[] = [];
                                const results: any[] = [];
                                for (let i = 0; i < reviewDate.stars; i++) {
                                  result.push(
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
                                      <path
                                        d="M19.9478 8.05776C19.8169 7.63517 19.4577 7.33502 19.0327 7.29506L13.2602 6.74811L10.9776 1.17306C10.8093 0.764478 10.426 0.5 10.0001 0.5C9.5742 0.5 9.19089 0.764478 9.02258 1.17401L6.73996 6.74811L0.966512 7.29506C0.542308 7.33598 0.184023 7.63517 0.0523364 8.05776C-0.0793501 8.48035 0.0422653 8.94386 0.363165 9.23605L4.72652 13.2292L3.43987 19.1434C3.34572 19.5782 3.50747 20.0277 3.85324 20.2885C4.03909 20.4287 4.25654 20.5 4.47581 20.5C4.66487 20.5 4.85241 20.4468 5.02072 20.3417L10.0001 17.2363L14.9776 20.3417C15.3419 20.5704 15.801 20.5495 16.146 20.2885C16.4919 20.0269 16.6535 19.5773 16.5594 19.1434L15.2727 13.2292L19.6361 9.23684C19.957 8.94386 20.0795 8.48115 19.9478 8.05776Z"
                                        fill="#F2C94C"
                                      />
                                    </svg>
                                  );
                                }
                                for (let i = reviewDate?.stars; i < 5; i++) {
                                  results.push(
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
                                      <path
                                        d="M19.9478 8.05776C19.8169 7.63517 19.4577 7.33502 19.0327 7.29506L13.2602 6.74811L10.9776 1.17306C10.8093 0.764478 10.426 0.5 10.0001 0.5C9.5742 0.5 9.19089 0.764478 9.02258 1.17401L6.73996 6.74811L0.966512 7.29506C0.542308 7.33598 0.184023 7.63517 0.0523364 8.05776C-0.0793501 8.48035 0.0422653 8.94386 0.363165 9.23605L4.72652 13.2292L3.43987 19.1434C3.34572 19.5782 3.50747 20.0277 3.85324 20.2885C4.03909 20.4287 4.25654 20.5 4.47581 20.5C4.66487 20.5 4.85241 20.4468 5.02072 20.3417L10.0001 17.2363L14.9776 20.3417C15.3419 20.5704 15.801 20.5495 16.146 20.2885C16.4919 20.0269 16.6535 19.5773 16.5594 19.1434L15.2727 13.2292L19.6361 9.23684C19.957 8.94386 20.0795 8.48115 19.9478 8.05776Z"
                                        stroke="#F2C94C"
                                      />
                                    </svg>
                                  );
                                }
                                return (
                                  <Col xl={6} key={_id} className="flex-list odd-child col">
                                    <Card className="reviewCard discover-cardp-1 border shadow-none card" style={{ display: "flex", flex: "1 1 50%" }}>
                                      <CardBody className="card-main-sec">
                                        <div className="card-s1">
                                          <div className="card-s2">
                                            <div className="card-s3-1">
                                              {!reviewDate?.clientId?.photoId ? (
                                                <div className="avatar-md mx-auto mb-4">
                                                  <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>
                                                    {reviewDate?.clientId?.firstname?.charAt(0)}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="mb-4">
                                                  <img className="rounded-circle avatar-md imageFit" src={Util.fileURL(reviewDate?.clientId?.photoId?._id)} />
                                                </div>
                                              )}
                                            </div>

                                            <div className="card-s2-2">
                                              <h5 className="font-size-15 mb-1">
                                                {!reviewDate?.clientId?.firstname && !reviewDate?.clientId?.lastname ? (
                                                  <span className="text-dark">#Client</span>
                                                ) : (
                                                  <span className="text-dark">
                                                    <span>
                                                      {reviewDate?.clientId?.firstname}
                                                      {reviewDate?.clientId?.lastname}
                                                    </span>
                                                  </span>
                                                )}
                                              </h5>

                                              <Col xl="12" style={{ display: "flex" }}>
                                                {result}
                                                {results}
                                              </Col>
                                            </div>
                                          </div>
                                        </div>
                                        <Row className="mb-2">
                                          <Col xl={8}>
                                            <span className="text-muted">{reviewDate?.review}</span>
                                          </Col>
                                          <Col xl={4}>
                                            <span style={{ display: "flex", float: "right" }}>{moment(reviewDate?.createdAt).fromNow()}</span>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                );
                              })}
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Modal1
                    trigger={isOpenMessage}
                    setTrigger={setIsOpenMessage}
                    header={"You can write your message to the therapist below"}
                    primaryBtn={"Apply"}
                    secondaryBtn={"Cancel"}
                    setOnchange={() => sendMessage()}
                  />

                  <Modal2 trigger={isOpenSuccessMessage} setTrigger={setIsOpenSuccessMessage} header={"Your message has been successfully sent!"} />
                </Row>
              </div>
            ) : (
              <Spinner />
            )}
          </Col>
        </Row>
      </Card>

      <Modal
        isOpen={modal_center}
        toggle={() => {
          togCenterReview();
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Leave a review</h5>
          <button
            type="button"
            onClick={() => {
              setModalCenter(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="mb-2">
            <Input
              type="textarea"
              className="form-control"
              id="formrow-firstname-Input"
              style={{ height: "100%", minHeight: "160px" }}
              placeholder="Your review..."
              onChange={e => setReview({ ...addReview, review: e.target.value })}
              value={addReview.review}
              name="articleTitle"
              required
            />
          </div>

          <Row className="mb-4">
            <Col xl="12" style={{ display: "flex" }}>
              <CardTitle>Rate the therapist:</CardTitle>
              <Rating initialValue={rating1} onClick={(rate: any) => setRating1(rate)} size={20} transition fillColor={"#F2C94C"} />
            </Col>
          </Row>

          <Row>
            <Col xl="3"></Col>
            <Col xl="6">

              <button type="button" className="btn btn-primary w-45 " onClick={() => AddReviewData()}>
                Save
              </button>
              <button
                type="button"
                className="btn  w-45 ml-3  card_shadow"
                onClick={() => {
                  togCenterReview();
                }}
              >
                Cancel
              </button>
            </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>

      {modalcentervideo && (
        <div
          className="lightbox1"
          onClick={() => {
            setModalCenterVideo(false);
          }}
        >
          <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
            <div className="modal-body1">
              <iframe
                src={"https://player.vimeo.com/video/" + profileDetails?.vimeoId}
                width="800"
                className="videoSize"
                height="400"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Lavni Health"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserDetails;
