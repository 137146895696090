import React, { useState } from 'react';
import { StepProps } from './types';
import moment from 'moment';
import { environment } from '../../../environment/environment';

interface UploadDocument {
  uploadDocumentId: {
    _id: string;
    originalName: string;
    createdAt: string;
  };
}

const SelectDocuments: React.FC<StepProps> = ({
  searchDocumentDownloadsByAdminSelected,
  onNext,
  onBack
}) => {
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const uploadDocuments = searchDocumentDownloadsByAdminSelected?.uploadDocuments || [];

  return (
    <div>
      <h6 className="mb-4">Select Documents</h6>
      <div className="table-responsive mb-4">
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: '40px' }}></th>
              <th>Document Name</th>
              <th>Document ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {uploadDocuments.map((doc: UploadDocument) => (
              <tr key={doc.uploadDocumentId._id}>
                <td>
                  <input
                    type="checkbox"
                    // checked={selectedDocuments.includes(doc.uploadDocumentId._id)}
                    onChange={(e) => setSelectedDocuments(prevDocs => {
                      const docID = doc.uploadDocumentId._id
                      const token = localStorage.getItem('token') || ''
                      const url = `${environment.api_url}/api/public/file/${docID}/${token}`
                      if (prevDocs.includes(url)) {
                        return prevDocs.filter(id => id !== url);
                      } else {
                        return [...prevDocs, url];
                      }
                    })}
                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                  />
                </td>
                <td>
                  <a
                    href={`${environment.api_url}/api/public/file/${doc.uploadDocumentId._id}/${localStorage.getItem('token') || ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    {doc.uploadDocumentId.originalName}
                  </a>
                </td>
                <td>{doc.uploadDocumentId._id}</td>
                <td>{moment(doc.uploadDocumentId.createdAt).format('MMM D, YYYY, h:mm A')}</td>
              </tr>
            ))}
            {uploadDocuments.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center py-3 text-muted">
                  No documents found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="alert alert-info" role="alert">
        <i className="fas fa-info-circle me-2"></i>
        Select the documents you want to include in the audit PDF. All selected documents will be merged into the final PDF.
      </div>
      <div className="text-end mt-3">
        <button className="btn btn-secondary me-2" onClick={onBack}>
          Back
        </button>
        <button 
          className="btn btn-primary" 
          onClick={() => onNext(selectedDocuments)}
          // disabled={selectedDocuments.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectDocuments;
