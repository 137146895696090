export enum PayStatus {
    ALL = "",
    PAID = "PAID",
    UNPAID = "UNPAID"
}

export enum TransactionType {
    REFERRAL = "REFERRAL"
}

export enum RewardType {
    ALL = "",
    OWNFIRST = "OWNFIRST",
    OWNSECOND = "OWNSECOND",
    REFERFIRST = "REFERFIRST",
    REFERSECOND = "REFERSECOND"
}

export interface ReferralEarning {
    _id: string;
    therapistId: string;
    type: TransactionType;
    transactionAmount: number;
    accumulatedBalance: number;
    accumulatedTotalEarnings: number;
    accumulatedWithdrawals: number;
    paidStatus?: string;
    rewardType?: RewardType;
    rewardReferralId?: any;
    createdAt: Date;
    updatedAt: Date;
    firstname: string;
    lastname: string;
    email: string;
    verifiedStatus: string;
}
