import React, { useContext, useEffect, useState } from 'react';
import { CAFormType } from 'src/models/clinicalAssesment/clinicalAssesment';
import { MeetingData } from 'src/models/MeetingData';
import { FormVersionService } from 'src/services/FormVersionService';
import { toast } from "react-toastify";
import AiIcon from "../../../assets/images/icons/AI_animation_logo.gif";
import { Button, Collapse } from 'reactstrap';
import moment from 'moment';
import { TPType } from 'src/models/therapyPlan/therapyPlan';
import ClinicalAssessmentPdfModal from 'src/pages/Popup/ClinicalAssessmentPdfModal';
import NewClinicalAssessmentPdfModal from 'src/pages/Popup/NewClinicalAssessmentPdfModal';
import ClinicalAssetmentModal from 'src/pages/Popup/ClinicalAssessmentModal';
import NewClinicalAssessmentModal from 'src/pages/Popup/NewClinicalAssessmentModal';
import Spinner from "../../../common/spinner/spinner";
import UserContext from 'src/context/UserContext';
import AIGeneratedAssessment from 'src/pages/NewAssessmentDigitalForm/AIGeneratedAssessment';

interface AssessmentClientSaasProps {
  clientId: string;
}
const AssessmentClientSaas: React.FC<AssessmentClientSaasProps> = ({ clientId }) => {
  const [selectedCAFormType, setSelectedCAFormType] = useState("");
  const [firstMeetingData, setFirstMeetingData] = useState<any>([]);
  const [clinicalAssessmentInfo, setClinicalAssessmentInfo] = useState<any>();
  const [showClinicalAssessmentWithVersionModal, setShowClinicalAssessmentWithVersionModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);
  const [showAiGeneratedAssessmentPopup, setShowAiGeneratedAssessmentPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [clinicalAssessmentId, setClinicalAssessmentId] = useState("");
  const [clinicalAssessmentType, setClinicalAssessmentType] = useState("");
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [expandedPlans, setExpandedPlans] = useState<{ [key: string]: boolean }>({});
  const eventType = "DOWNLOAD";
  const [loggedUser] = useContext(UserContext);

  useEffect(() => {
    if (clientId) {
      handleClinicalAssessmentView();
    }
  }, [])

  const handleClinicalAssessmentView = async () => {
    if (clientId != undefined) {
      setIsLoading(true);
      setSelectedCAFormType("");
      setFirstMeetingData([] as MeetingData[]);

      const result = await fetchClinicalAssessmentWithVersions(clientId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
        setShowClinicalAssessmentWithVersionModal(true);
        setIsLoading(false);
      }else {
       /* toast("Error loading data", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });*/
        setIsLoading(false);
      }
    }
  };

  const fetchClinicalAssessmentWithVersions = async (clientId: string) => {
    try {
      const res = await FormVersionService.getClinicalAssessmentsWithAllVersions(clientId);

      if (res && res.success) {
        const { relatedCAFormType, caWithVersion, daWithVersion, meetingData } = res.data;

        if (relatedCAFormType == CAFormType.OLD_CA) {

          setSelectedCAFormType(CAFormType.OLD_CA);
          return { success: true, data: caWithVersion };

        } else if (relatedCAFormType == CAFormType.NEW_CA) {

          setSelectedCAFormType(CAFormType.NEW_CA);
          if (daWithVersion.length == 0 && meetingData) {
            setFirstMeetingData(meetingData);
          }
          return { success: true, data: daWithVersion };

        } else {
          return { success: false };
        }
      } else {
        toast.error("Encountered an error while retrieving the assessment data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }

    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };
  const openClinicalAssessment = () => {
    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowNewClinicalAssessmentModal(false);
      setShowClinicalAssessmentModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowClinicalAssessmentModal(false);
      setShowNewClinicalAssessmentModal(true);
    }
  };
  const generateAiAssessmentFunction = () => {
    setShowAiGeneratedAssessmentPopup(true);
  }

  const handleChildFunction = (isCalled: boolean) => {
    setShowAiGeneratedAssessmentPopup(isCalled);
  };

  const handleAIGenerateClick = () => {
    setIsLoading(true);
    generateAiAssessmentFunction();
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };
  const formatDate = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };
  const generateClinicalPdf = (planId: string, tpType: TPType) => {
    setClinicalAssessmentId(planId);
    setClinicalAssessmentType(tpType);
    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowClinicalAssessmentPdfModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowNewClinicalAssessmentPdfModal(true);
    }
  };
  const toggleVersions = (planId: string) => {
    setExpandedPlans(prev => ({
      ...prev,
      [planId]: !prev[planId]
    }));
  };
  const refreshModal32 = async (value: boolean) => {
    if (value) {
      const result = await fetchClinicalAssessmentWithVersions(clientId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
      }
    }
  };
  return (
    <React.Fragment>
      {showNewClinicalAssessmentModal && <NewClinicalAssessmentModal showModal={showNewClinicalAssessmentModal} identifierId={clientId} setShowModal={setShowNewClinicalAssessmentModal} isFromMeeting={false} isAdmin={false} setModalRefresher={refreshModal32} aiGeneratedAssessmentData={null} isMeeting={false} />}
      {showClinicalAssessmentModal && <ClinicalAssetmentModal setShowModal={setShowClinicalAssessmentModal} showModal={showClinicalAssessmentModal} identifierId={clientId} isAdmin={false} isFromMeeting={false} setModalRefresher={refreshModal32} />}
      {showClinicalAssessmentPdfModal && <ClinicalAssessmentPdfModal setShowModal={setShowClinicalAssessmentPdfModal} showModal={showClinicalAssessmentPdfModal} identifierId={clinicalAssessmentId} isAdmin={false} eventType={eventType} insuranceDocApprovalId={null} setModalRefresher={null} clinicalAssessmentType={clinicalAssessmentType as TPType} />}
      {showNewClinicalAssessmentPdfModal && <NewClinicalAssessmentPdfModal setShowModal={setShowNewClinicalAssessmentPdfModal} showModal={showNewClinicalAssessmentPdfModal} identifierId={clinicalAssessmentId} isAdmin={false} eventType={eventType} insuranceDocApprovalId={null} setModalRefresher={null} digitalAssessmentType={clinicalAssessmentType as TPType} />}
      {showAiGeneratedAssessmentPopup && (
        <AIGeneratedAssessment
          OpenAiModel={showAiGeneratedAssessmentPopup}
          transcribeData={firstMeetingData}
          closeModelFunction={handleChildFunction}
          refreshModal32={refreshModal32}
          isInMeeting={false}
          clientId={clientId}
        />
      )}
      <div style={{ padding: '10px', maxWidth: '100%' }}>
        <h3 className="mb-4" style={{ color: "#f27e0c", marginTop: "3px" }}>Clinical Assessment With Amendments</h3>
        {(!isAdmin && clinicalAssessmentInfo?.length === 0) && (
          <div className="d-flex justify-content-center mb-4">
            <Button color="primary" className="py-2 shadow-sm s-h-btn" onClick={openClinicalAssessment}>
              <i className="fa fa-plus mr-2"></i> Create Clinical Assessment
            </Button>
            {/* This part modified for show and generate AI assessment for marcus king therapist. */}
            {/* loggedUser?._id == "62f17eb1b81f31a68505d9ad" - This line added for the above reason. */}
            {((firstMeetingData &&
              firstMeetingData?.clientAllowedTranscribe &&
              firstMeetingData?.therapistAllowedTranscribe &&
              firstMeetingData?.firstSpeaker &&
              firstMeetingData?.transcribeCreated) ||
              (loggedUser?._id == "62f17eb1b81f31a68505d9ad")) && (
                <Button
                  color="secondary"
                  className="ml-3 btn-black1 convert-pending-note s-h-btn"
                  onClick={handleAIGenerateClick}
                  disabled={isLoading}
                >
                  <img src={AiIcon} className="ai-icon-size me-1" />
                  Generate Clinical Assessment With AI
                </Button>
              )}
          </div>
        )}

        {(isMobile && clinicalAssessmentInfo?.length > 0) ? (<span className="text-muted small"><mark>Note: To view or download the amendment, please use a laptop or desktop computer.</mark></span>) : (<></>)}
        {isLoading ? (
          <div className={"d-flex justify-content-center align-items-center"} style={{minHeight:"25vh",width:"100%",position:"relative"}}>
            <Spinner className={"bouncer"} />
          </div>
        ) : (
          <>
            {clinicalAssessmentInfo?.length > 0 ? (
              clinicalAssessmentInfo.map((plan: any) => (
                <div key={plan?._id} className="therapy-plan-item mb-4 bg-light rounded p-3 shadow-sm">
                  <h5 className="font-weight-bold text-dark">Clinical Assessment</h5>
                  <p className="text-muted small mb-3">Created date: {formatDate(plan?.createdAt)}</p>

                  <div className="therapy-plan-buttons mb-3">
                    {!isAdmin && (
                      <Button color="success" onClick={openClinicalAssessment} className="mr-2 btn-sm shadow-sm">
                        <i className="fa fa-file mr-1"></i> Open / Edit
                      </Button>
                    )}
                    {(!isMobile) && (
                      <Button color="info" onClick={() => generateClinicalPdf(plan?._id, TPType.ORIGINAL)} className="btn-sm shadow-sm">
                        <i className="fa fa-download mr-1"></i> Download
                      </Button>
                    )}
                  </div>

                  {plan?.versions && plan?.versions.length > 0 ? (
                    <div className="therapy-plan-versions mt-3">
                      <Button
                        color="link"
                        onClick={() => toggleVersions(plan?._id)}
                        className="p-0 text-decoration-none"
                      >
                        <h6 className="font-weight-bold mb-0">
                          {expandedPlans[plan?._id] ? 'Hide' : 'View'} amendments {' '}
                          <i className={`fa fa-chevron-${expandedPlans[plan?._id] ? 'up' : 'down'}`} />
                        </h6>
                      </Button>
                      <Collapse isOpen={expandedPlans[plan?._id]}>
                        <ul className="list-unstyled mt-2">
                          {plan?.versions.map((version: any, versionIndex: number) => (
                            <li key={version?._id} className="mb-2 bg-white p-2 rounded shadow-sm">
                              <span className="font-weight-medium">Amendment {versionIndex + 1}</span><br />
                              <span className="text-muted small">Created date: <b>{formatDate(version?.versionCreatedAt)}</b></span><br />
                              <span className="text-muted small">Amendment modified due to: <b>{version?.reasonForEdit}</b></span><br />
                              {(!isMobile) && (
                                <Button
                                  color="secondary"
                                  size="sm"
                                  onClick={() => generateClinicalPdf(version?._id, TPType.VERSION)}
                                  className="mt-2 shadow-sm"
                                >
                                  View / Download
                                </Button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </div>
                  ) : (
                    <p className="text-muted font-italic">No amendments available.</p>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center text-muted font-italic">No clinical assessment available.</p>
            )}
          </>
          )}

      </div>
    </React.Fragment>
  );
};

export default AssessmentClientSaas;
