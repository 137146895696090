import { auto } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { CommonService } from "src/services/CommonService";

const FormNine = (props: any) => {
    const { formId, assesmenData , getAssesmentByClientId,customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })

    const [edit, setEdit] = useState<boolean>(true);
    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 9,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                summeryOfFindings: assesmenData.summeryOfFindings,
                eligibilityRecommendations: assesmenData.eligibilityRecommendations,
                treatmentRecommendations: assesmenData.treatmentRecommendations,
                recommendationNotes: assesmenData.recommendationNotes,
                saftyPlanNotNeeded: assesmenData.saftyPlanNotNeeded,
                clientSaftyPlanCompleted: assesmenData.clientSaftyPlanCompleted,
            }
            setFormData(updateAssesment);
        }
    }, [assesmenData,customActiveTab])

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 9,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            summeryOfFindings: formData.summeryOfFindings,
            eligibilityRecommendations: formData.eligibilityRecommendations,
            treatmentRecommendations: formData.treatmentRecommendations,
            recommendationNotes: formData.recommendationNotes,
            saftyPlanNotNeeded: formData.saftyPlanNotNeeded,
            clientSaftyPlanCompleted: formData.clientSaftyPlanCompleted,
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                
                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Summary of Findings</h5>
                            <h6 className="fw-500" style={{ margin: "auto" }}>(Include justification for diagnoses according to DSM-V, strengths, needs, supports, recommendations for services)</h6>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Card className="bg-light-gray" style={{ height: "350px", marginBottom: "0.5rem", border: "1px solid #848484", borderRadius: "initial" }}>
                                <div style={{ margin: "0.2rem" }}>
                                    <h6 style={{ fontWeight: "600", display: "inline" }}>Summary of Findings Narrative :</h6>
                                </div>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        id="summeryOfFindingNarrativeEditor"
                                        data={formData.summeryOfFindings?.summeryOfFindingNarrative || ""}
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData({
                                                ...formData,
                                                summeryOfFindings: {
                                                    ...formData.summeryOfFindings,
                                                    summeryOfFindingNarrative: data
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </Card>
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px", verticalAlign: "middle" }}>
                                    <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                            <td className="fw-600" style={{ width: "10%", paddingLeft: "0.2rem" }}>Axis</td>
                                            <td className="fw-600" style={{ width: "30%", paddingLeft: "0.2rem" }}>Code</td>
                                            <td className="fw-600" style={{ width: "60%", paddingLeft: "0.2rem" }}>Description</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    l
                                                </div>
                                                {/* <div className="col-auto">
                                                    l
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code11"
                                                        value={formData.summeryOfFindings?.I1?.code}
                                                        readOnly={!edit}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                summeryOfFindings: {
                                                                    ...prevState.summeryOfFindings,
                                                                    I1: {
                                                                        ...prevState.summeryOfFindings?.I1,
                                                                        code: e.target.value
                                                                    }
                                                                }
                                                            }))
                                                        }
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code12"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.I2?.code}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, I2: {
                                                                    ...prevState.summeryOfFindings?.I2, code: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description11"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.I1?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, I1: {
                                                                    ...prevState.summeryOfFindings?.I1, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description12"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.I2?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, I2: {
                                                                    ...prevState.summeryOfFindings?.I2, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div> */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    ll
                                                </div>
                                                {/* <div className="col-auto">
                                                    ll
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code21"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.II1?.code}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, II1: {
                                                                    ...prevState.summeryOfFindings?.II1, code: e.target.value
                                                                }
                                                            }
                                                        }))}

                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code22"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.II2?.code}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, II2: {
                                                                    ...prevState.summeryOfFindings?.II2, code: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description21"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.II1?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, II1: {
                                                                    ...prevState.summeryOfFindings?.II1, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                                {/* <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description22"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.II2?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, II2: {
                                                                    ...prevState.summeryOfFindings?.II2, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div> */}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>
                                                lll
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    V 71.09
                                                </div>
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    No diagnosis
                                                </div>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    lll
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code21"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.III?.code}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, III: {
                                                                    ...prevState.summeryOfFindings?.III, code: e.target.value
                                                                }
                                                            }
                                                        }))}

                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description21"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.III?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, III: {
                                                                    ...prevState.summeryOfFindings?.III, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                lV
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code4"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.IV?.code}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, IV: {
                                                                    ...prevState.summeryOfFindings?.IV, code: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description4"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.IV?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, IV: {
                                                                    ...prevState.summeryOfFindings?.IV, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                V
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="code5"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.V?.code}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, V: {
                                                                    ...prevState.summeryOfFindings?.V, code: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "left", paddingLeft: "0.2rem" }}
                                                        type="text"
                                                        id="description5"
                                                        readOnly={!edit}
                                                        value={formData.summeryOfFindings?.V?.description}
                                                        onChange={e => setFormData((prevState) => ({
                                                            ...prevState, summeryOfFindings: {
                                                                ...prevState.summeryOfFindings, V: {
                                                                    ...prevState.summeryOfFindings?.V, description: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <Row>
                                <h6 className="fw-600 mt-2">If immediate safety risks, describe safety planning, identified supports and coordination of care :</h6>
                            </Row>
                            <Row>
                                <Col xl={3} md={12}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Advanced Directives :</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="nonReported"
                                            checked={formData.summeryOfFindings?.advanceDirectives?.nonReported === true}
                                            onClick={() => setFormData({ ...formData, summeryOfFindings: { ...formData.summeryOfFindings, advanceDirectives: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="nonReported"
                                                checked={formData.summeryOfFindings?.advanceDirectives?.nonReported === false}
                                                onClick={() => setFormData({ ...formData, summeryOfFindings: { ...formData.summeryOfFindings, advanceDirectives: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Describe Safety Plan :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="advancedDirectives"
                                                            readOnly={formData.summeryOfFindings?.advanceDirectives?.nonReported !== false || !edit}
                                                            value={formData.summeryOfFindings?.advanceDirectives?.describePlan || ""}
                                                            onChange={e => setFormData({ ...formData, summeryOfFindings: { ...formData.summeryOfFindings, advanceDirectives: { describePlan: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Recommendations</h5>
                        </Card>
                        <Card className="match-card-body p-3" style={{ marginBottom: "0.5rem" }}>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Eligibility Recommendations :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="eligibility"
                                                    readOnly={!edit}
                                                    value={formData.eligibilityRecommendations || ""}
                                                    onChange={e => setFormData({ ...formData, eligibilityRecommendations: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Treatment Recommendations :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="treatment"
                                                    readOnly={!edit}
                                                    value={formData.treatmentRecommendations || ""}
                                                    onChange={e => setFormData({ ...formData, treatmentRecommendations: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.5rem", border: "2px solid #848484", borderRadius: "initial" }}>
                            <div style={{ margin: "0.2rem" }}>
                                <h6 style={{ fontWeight: "600", display: "inline" }}>Notes :</h6>
                            </div>
                            <div className="editor-area ck-content"
                                style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                <CKEditor
                                    editor={Editor}
                                    id="floatingTextarea"
                                    data={formData.recommendationNotes || ""}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData();
                                        setFormData({ ...formData, recommendationNotes: data });
                                    }}
                                />
                            </div>
                        </Card>

                        <div className="row">
                            <div className="col-auto pr-0">
                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="checkbox"
                                    className="form-check-input"
                                    name="saftyPlanNotNeeded"
                                    checked={formData.saftyPlanNotNeeded}
                                    onClick={() => setFormData({ ...formData, saftyPlanNotNeeded: !formData.saftyPlanNotNeeded })}
                                />
                            </div>
                            <div className="col-10 p-0">
                                <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Check if Safety Plan not needed at this time.</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto pr-0">
                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="checkbox"
                                    className="form-check-input"
                                    name="saftyPlanNotNeeded"
                                    checked={formData.clientSaftyPlanCompleted}
                                    onClick={() => setFormData({ ...formData, clientSaftyPlanCompleted: !formData.clientSaftyPlanCompleted })}
                                />
                            </div>
                            <div className="col-10 p-0">
                                <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Check if Client Safety Plan completed - Therapist will need to follow Clients at Risk Policy (in P&P Manual).</label>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&
                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 9 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormNine;