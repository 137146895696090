import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "src/common/spinner/spinner";
import AmbetterModal from "src/pages/Popup/AmbetterModal";
import AmeriHealthModal from "src/pages/Popup/AmeriHealthModal";
import CarolinaCompleteHealthModal from "src/pages/Popup/CarolinaCompleteHealthModal";
import HealthBlueModal from "src/pages/Popup/HealthBlueModal";
import UnitedHealthCareModal from "src/pages/Popup/UnitedHealthCareModal";
import WellCareModal from "src/pages/Popup/WellCareModal";

interface PendingAuthorizationFormsProps {
  pendingAuthorizationForms: any[];
}

const PendingAuthorizationForms: React.FC<PendingAuthorizationFormsProps> = ({ pendingAuthorizationForms }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [clientDetails, setClientDetails] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState("");
  const [showAmeriHealthModal, setshowAmeriHealthModal] = useState<boolean>(false);
  const [showCarolinaCompleteHealthModal, setshowCarolinaCompleteHealthModal] = useState<boolean>(false);
  const [showUnitedHealthCareModal, setshowUnitedHealthCareModal] = useState<boolean>(false);
  const [showHealthBlueModal, setshowHealthBlueModal] = useState<boolean>(false);
  const [showWellCareModal, setshowWellCareModal] = useState<boolean>(false);
  const [showAmbetterModal, setshowAmbetterModal] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>();

  useEffect(() => {
    setIsLoading(true);

    const filteredClients = pendingAuthorizationForms.filter((form) => {
      const hasAuthorizationForm = form.insuranceCompanies?.some(
        (company: { authorizationFormAvailability: any; }) => company.authorizationFormAvailability
      );
      return hasAuthorizationForm;
    });

    setClientDetails(filteredClients);
    setIsLoading(false);
  }, [pendingAuthorizationForms]);

  const handleNoteClick = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const toggleSorting = () => {
    if (sortType === 'asc') {
      sortingAZ();
      setSortType('desc');
    } else {
      sortingZA();
      setSortType('asc');
    }
  };

  const sortingAZ = () => {
    const sortedData = [...clientDetails].sort((a: any, b: any) => {
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const sortingZA = () => {
    const sortedData = [...clientDetails].sort((a: any, b: any) => {
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredClients = clientDetails.filter((client) => {
    const fullName = `${client.clientDetails?.firstname} ${client.clientDetails?.lastname}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handleAuthFormClick = (insuranceCompanyId: string, authFormType: string, clientId: string) => {
    try {
      setClientId(clientId);

      switch (authFormType) {
        case 'AmeriHealthAuthForm':
          return ameriHealthAuthorizationRequestForm(insuranceCompanyId);

        case 'WellcareAuthForm':
          return wellCareAuthorizationRequestForm(insuranceCompanyId);

        case 'UnitedHealthCareAuthForm':
          return unitedHealthCareAuthorizationRequestForm(insuranceCompanyId);

        case 'HealthyBlueAuthForm':
          return healthBlueAuthorizationRequestForm(insuranceCompanyId);

        case 'CarolinaCompleteHealthAuthForm':
          return carolinaCompleteAuthorizationRequestForm(insuranceCompanyId);

        case 'AmbetterAuthForm':
          return ambetterAuthorizationRequestForm(insuranceCompanyId);

        default:
          throw new Error('Unsupported Authorization Form Type');
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }

  const ameriHealthAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowAmeriHealthModal(true);
  };
  const carolinaCompleteAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowCarolinaCompleteHealthModal(true);
  };
  const unitedHealthCareAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowUnitedHealthCareModal(true);
  };
  const healthBlueAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowHealthBlueModal(true);
  };
  const wellCareAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowWellCareModal(true);
  };
  const ambetterAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowAmbetterModal(true);
  };

  return (
    <React.Fragment>
      {showAmeriHealthModal && (
        <AmeriHealthModal
          setShowModal={setshowAmeriHealthModal}
          showModal={showAmeriHealthModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showCarolinaCompleteHealthModal && (
        <CarolinaCompleteHealthModal
          setShowModal={setshowCarolinaCompleteHealthModal}
          showModal={showCarolinaCompleteHealthModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showUnitedHealthCareModal && (
        <UnitedHealthCareModal
          setShowModal={setshowUnitedHealthCareModal}
          showModal={showUnitedHealthCareModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showHealthBlueModal && (
        <HealthBlueModal
          setShowModal={setshowHealthBlueModal}
          showModal={showHealthBlueModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showWellCareModal && (
        <WellCareModal
          setShowModal={setshowWellCareModal}
          showModal={showWellCareModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showAmbetterModal && (
        <AmbetterModal
          setShowModal={setshowAmbetterModal}
          showModal={showAmbetterModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      <div className="clinical-notes-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="clinical-notes-header-container d-flex flex-lg-row flex-md-row flex-sm-column flex-column justify-content-between align-items-center">
              <div className={"w-100 d-flex flex-row justify-content-start align-item-center fs-5 bold"}>
                Pending Authorization Forms : Clients List
                <span className={"ms-2"}>
                  <i onClick={toggleSorting} className={"bx bx-sort"}></i>
                </span>
              </div>
              <div className="search-container">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search clients..."
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ width: "250px", height: "35px" }}
                />
              </div>
            </div>
            <div className="clinical-notes-list d-flex">
              {filteredClients.length > 0 ? (
                filteredClients.map((client, index) => (
                  <div
                    key={index}
                    className={`clinical-note-item ${index === activeIndex ? "active" : ""}`}
                    onClick={() => handleNoteClick(index)}
                  >
                    <div className="clinical-note-icon"></div>
                    <span className="clinical-note-name">
                      {client?.clientDetails?.firstname} {client?.clientDetails?.lastname}
                    </span>
                    {client?.insuranceCompanies?.map((company: { _id: any; authorizationFormAvailability: any; authFormType: any; isPrimary: boolean; }, companyIndex: React.Key | null | undefined) => {
                      if (company.authorizationFormAvailability) {
                        return (
                          <button
                            key={companyIndex}
                            className="clinical-note-button"
                            onClick={() => handleAuthFormClick(company._id, company.authFormType, client?.clientDetails?._id)}
                            title={company.authFormType}
                          >
                            {company.isPrimary ? "Primary" : "Secondary"}
                          </button>
                        );
                      }
                      return null;
                    })}
                  </div>
                ))
              ) : (
                <p>No clients available</p>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default PendingAuthorizationForms;
