import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, CardTitle, Card, Row, Col, Input } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { AdminService } from "../../services/AdminService";
import MonthYearPicker from "../../components/Common/MonthPicker";
import { environment } from "../../environment/environment";
import Spinner from "../../common/spinner/spinner";

const ManageInvoices: React.FC = () => {
  const [payAmount, setPayAmount] = useState("");
  const [duePayment, setDuePayment] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("Click here to select the payment month");
  const { userId } = useParams<ParameterTypes>();
  const [chargingPending, setChargingPending] = useState(false);

  const history = useHistory();

  const back = () => {
    history.goBack();
  };

  const chargePayment = () => {
    if (payAmount == "" || payAmount == undefined || payAmount == null) {
      toast.error("Pay amount is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (duePayment == "" || duePayment == undefined || duePayment == null) {
      toast.error("Due payment amount is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (selectedPeriod == "Click here to select the payment month") {
      toast.error("Payment month is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if(Number(payAmount) > environment.product1Pricing) {
      toast.error("Payment amount should not be more than " + environment.product1Pricing + "!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    setChargingPending(true);

    const email = {
      clientId: userId,
      payAmount: payAmount,
      duePayment: duePayment,
      paymentMonth: selectedPeriod
    };

    AdminService.chargePaymentAndSendEmail(email).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setPayAmount("");
        setDuePayment("");
        setSelectedPeriod("Click here to select the payment month");

        setChargingPending(false);

        history.push("/insurance/" + userId + "/2");
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setPayAmount("");
        setDuePayment("");
        setSelectedPeriod("Click here to select the payment month");
        setChargingPending(false);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Insurance Payment" breadcrumbItem="Insurance Payment" />
          <div className="flex flex-wrap justify-content-between">
            <div className="flex cursor-pointer" onClick={() => back()}>
              <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
              <CardTitle className="mb-4  cursor-pointer">&nbsp; Back</CardTitle>
            </div>
          </div>

          <Card className="BorderRadiusLeftRight p-5">
            <div className="flex flex-wrap justify-content-between">
              <div className="card-heading mb-3">
                <h5 className="font-size-20 profileFont mb-3">Organization Payment Details</h5>
              </div>
              <div className="card-heading mb-3">
                <h5 className="font-size-20 profileFont mb-3">
                  <i className="fa fa-star"></i> Subscription Fee: $299
                </h5>
              </div>
            </div>
            <div className="card-bodyDiv mb-3">
              <Row>
                <Col xl="4">
                  <div className="mb-3">
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Pay Amount</h5>
                    </div>
                    <Input
                      type="number"
                      placeholder="Pay Amount"
                      value={payAmount}
                      onChange={event => setPayAmount(event.target.value)}
                      onBlur={event => setDuePayment((299 - parseInt(payAmount)).toString())}
                    ></Input>
                  </div>
                </Col>

                <Col xl="4">
                  <div className="mb-3">
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Month of Payment</h5>
                    </div>

                    {/* <MonthYearPicker selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} /> */}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="card-heading mb-3">
              <h5 className="font-size-20 profileFont mb-3">Customer Payment Details</h5>
            </div>

            <div className="card-bodyDiv">
              <Row>
                <Col xl="4">
                  <div className="mb-3">
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Due Amount</h5>
                    </div>
                    <Input type="number" placeholder="Due Amount" value={duePayment} onChange={event => setDuePayment(event?.target.value)}></Input>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="flex flex-row-reverse payment-line">
              {
                chargingPending ? (
                  <Spinner className="bouncer2" />
                ) : (
                  <button className="btn btn-primary mt15" onClick={chargePayment} disabled={chargingPending ? true : false}>
                    Charge Payment & Send Invoice
                  </button>
                )
              }
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageInvoices;
