import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, Container, Input, Label } from "reactstrap";
import { TechSupportList } from "src/models/TechSupport";
import { toast } from "react-toastify";
import axios from "axios";
import { Util } from "src/Util";
import { ProgressBar } from "react-bootstrap";

const Tech: React.FC = () => {
    const [techSupportMessage, setTechSupportMessage] = useState({} as TechSupportList);
    const [isDisabled, setIsDisabled] = useState(false);
    const [fileType, setFileType] = useState("");
    const [techTicketFile, setTechTicketFile] = useState({ preview: "", raw: "" });
    const [progress, setProgress] = useState(0);
    
    const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];

    const submitTicket = () => {
        if (techSupportMessage.title && techSupportMessage.message) {
            const formData = new FormData();

            if (techTicketFile.raw) {
                const techSupportDetails = {
                  title: techSupportMessage.title,
                  message: techSupportMessage.message
                };
          
                const data = JSON.stringify(techSupportDetails);
          
                formData.append("techSupportDetails", data);
                formData.append("fileType", fileType);
                formData.append("techTicketFile", techTicketFile.raw);
          
                axios.post(Util.apiAuthUrl(`createTechTicket`), formData, {
                    onUploadProgress: data => {
                        setProgress(Math.round((100 * data.loaded) / data.total));
                    },
                }).then((res: any) => {                    
                    if (res.success) {
                        toast.success("Tech support request is created & you will be contacted via email!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
            
                        deleteFile();
                        setTechSupportMessage({ ...techSupportMessage, title: "", message: "" });
                        setProgress(0);
                    } else {          
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        
                        deleteFile();
                        setProgress(0);
                    }
                }).catch(error => {
                    if (error) {
                        toast.error(error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    } else {
                        toast.error("API Error! Failed to submit tech support request!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }

                    deleteFile();
                    setProgress(0);
                });
            } else {
                toast.error("Please upload your issue image!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar"
                });
            }
            
            return false;
        } else {
            toast.error('Please enter your title & message.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const isSupported = (array: string[], extension: string) => {
        let isFound = false;

        array.map((item: string) => {
            if (item === extension) {
                isFound = true;
            }

            return item;
        });

        return isFound;
    };
      
    const handleFileChange = (event: any) => {
        const extension = event.target.files[0]?.name.split(".").pop().toLowerCase();
        const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);
        
        if (isAnImage) {
            setFileType("IMAGE");
        } else {
            toast.error("File type is not supported!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        
            return false;
        }
    
        if (event.target.files.length > 0) {
            setTechTicketFile({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0],
            });
        
            setIsDisabled(true);
        } else {
            toast.error("No file is selected!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        
            return false;
        }
    
        URL.revokeObjectURL(event.target.files[0]);
    };

    const deleteFile = () => {
        setTechTicketFile({ raw: "", preview: "" });
        setFileType("");
        setIsDisabled(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Lavni" breadcrumbItem="Technical Support" />
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <Card className="details">
                                <div className="row">
                                    <div className="col">
                                        <h4>Title:</h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mt-3">
                                            <div className="col">
                                                <div className="form-group">
                                                    <input onChange={(e: any) => setTechSupportMessage({ ...techSupportMessage, title: e.target.value })} value={techSupportMessage.title} className="form-control" placeholder="Enter title here..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div className="row">
                                    <div className="col">
                                        <h4>Your Issue:</h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mt-3">
                                            <div className="col">
                                                <div className="form-group">
                                                    <textarea onChange={(e: any) => setTechSupportMessage({ ...techSupportMessage, message: e.target.value })} value={techSupportMessage.message} className="form-control feedback-message" placeholder="Enter your issue here..."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div className="row">
                                    <div className="col">
                                        <h4>Attachments:</h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="form-upload-file"
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: "16px",
                                                    color: "#FD7F00",
                                                    marginBottom: "0px",
                                                }}
                                                >
                                                + Upload File Image
                                            </Label>

                                            {isDisabled ? (
                                                <Input
                                                    type="file"
                                                    className="form-control"
                                                    id="form-upload-file"
                                                    placeholder="Attachment File"
                                                    name="techTicketFile"
                                                    style={{ display: "none", cursor: "unset" }}
                                                    required
                                                    disabled
                                                />
                                                ) : (
                                                <Input
                                                    type="file"
                                                    className="form-control"
                                                    id="form-upload-file"
                                                    placeholder="Attachment File"
                                                    name="techTicketFile"
                                                    onChange={event => {
                                                        handleFileChange(event);
                                                    }}
                                                    style={{ display: "none" }}
                                                    required
                                                />
                                            )}
                                        </div>

                                        {
                                            fileType == "IMAGE" && (
                                                <>
                                                    <div className="pull-right">
                                                    <i
                                                        className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                                        onClick={() => {
                                                            deleteFile();
                                                        }}
                                                    ></i>
                                                    </div>
                                                    <div className="mb-3 upload-img">
                                                    <img src={techTicketFile.preview} className="upload-img-preview" />
                                                    </div>
                                                </>
                                            )
                                        }
                                    
                                    </div>
                                </div>

                                <div className="progress-sec">
                                    {progress != 0 && <ProgressBar className="progress-bar-style" now={progress} label={`${progress}%`} />}
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <button className="btn btn-success" onClick={() => submitTicket()}>Submit Ticket</button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Tech;