import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import CheckBox from "@material-ui/icons/CheckBox";
import Error from "@material-ui/icons/Error";
import { green, red } from "@material-ui/core/colors";
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  dialogToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  green: {
    color: green[600],
  },
  red: {
    color: red[600],
  },
});

export const SimpleDialog = ({ selectedValue, onClose, open }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <div className={classes.dialogToolbar}>
          <Typography variant="h6">Quality Test Result</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          Video Supported:
          {selectedValue.data && selectedValue.data.video.supported ? (
            <CheckBox className={classes.green}></CheckBox>
          ) : (
            <Error className={classes.red} />
          )}
        </div>
        {selectedValue.data && selectedValue.data.video.supported && (
            <div>
                <div>
                Video Suggested Resolution:
                {selectedValue.data &&
                    selectedValue.data.video.supported &&
                    selectedValue.data.video.recommendedResolution}
                </div>
                <div>
                Video packet Loss Ratio:
                {selectedValue.data &&
                    selectedValue.data.video.supported &&
                    selectedValue.data.video.packetLossRatio}
                </div>
                <div>
                Video Mos:
                {selectedValue.data &&
                    selectedValue.data.video.supported &&
                    selectedValue.data.video.mos}
                </div>
            </div>
        )}
        {selectedValue.data && !selectedValue.data.video.supported && (
          <div>
            Reason: 
            {selectedValue.data &&
              !selectedValue.data.video.supported &&
              selectedValue.data.video.reason}
          </div>
        )}
        
        <br/>
        <div>
          Audio Supported:
          {selectedValue.data && selectedValue.data.audio.supported ? (
            <CheckBox className={classes.green}></CheckBox>
          ) : (
            <Error className={classes.red} />
          )}
        </div>
        {selectedValue.data && selectedValue.data.audio.supported && (
            <div>
                <div>
                Audio packet Loss Ratio:
                {selectedValue.data &&
                    selectedValue.data.audio.supported &&
                    selectedValue.data.audio.packetLossRatio}
                </div>
                <div>
                Audio Mos:
                {selectedValue.data &&
                    selectedValue.data.audio.supported &&
                    selectedValue.data.audio.mos}
                </div>
            </div>
        )}
        {selectedValue.data && !selectedValue.data.audio.supported && (
          <div>
            Reason: 
            {selectedValue.data &&
              !selectedValue.data.audio.supported &&
              selectedValue.data.audio.reason}
          </div>
        )}
        
      </DialogContent>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  selectedValue: PropTypes.object, 
  onClose: PropTypes.func, 
  open: PropTypes.bool,
};

export default SimpleDialog;
