import React, { useState, useEffect, useRef, useMemo } from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import PublicArticleList from "./PublicArticleList";
import PublicRightBar from "./PublicRightBar";
import './publicArticleStateInsuranceSelectModal.css'
import { ArticleService } from "src/services/ArticleService";
import { toast } from "react-toastify";
import { AuthService } from "src/services/AuthService";
import { CommonService } from "src/services/CommonService";

toast.configure();

type Issue = {
  selectedSymptomId: string;
  selectedSymptomName: string;
};

type Insurance = {
  insuranceId: string;
  insuranceName: string;
};

function useOutsideClick(ref: React.RefObject<HTMLElement>, callback: () => void) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const PublicArticles: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [selectedConcernAsString, setSelectedConcernAsString] = useState('');
  const [selectedInsurance, setSelectedInsurance] = useState<Insurance | null>(null);
  const [isStateSelectDropdownOpen, setIsStateSelectDropdownOpen] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState<any>([]);
  const [isConcernSelectDropdownOpen, setIsConcernSelectDropdownOpen] = useState(false);
  const [insuranceList, setInsuranceList] = useState<any>([]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [isInsuranceSelectDropdownOpen, setIsInsuranceSelectDropdownOpen] = useState(false);
  const [symptomsList, setSymptomsList] = useState<any>([]);
  const [searchQueryForState, setSearchQueryForState] = useState<string>("");
  const [searchQueryForSymptoms, setSearchQueryForSymptoms] = useState<string>("");
  const [searchQueryForInsurance, setSearchQueryForInsurance] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dashboardLink, setDashboardLink] = useState("/signin");

  const dropdownStateRef = useRef<HTMLDivElement>(null);
  const dropdownConcernRef = useRef<HTMLDivElement>(null);
  const dropdownInsuranceRef = useRef<HTMLDivElement>(null);

  const [stateList, setStateList] = useState<string[]>([]);
  const [isRetrieveStateList, setIsRetrieveStateList] = useState<boolean>(false);


  useEffect(() => {
    getSymptomsList();
  }, []);

  useEffect(() => {
    const token = AuthService.getToken();
    if (token) {
      AuthService
        .getMe()
        .then((data) => {
          if (data.success) {
            setIsLoggedIn(true);
            const userRole = data.data.role;
            if (userRole === "CLIENT") {
              setDashboardLink("/dashboard");
            } else if (userRole === "SUPER_ADMIN" || userRole === "SUB_ADMIN") {
              setDashboardLink("/statistics");
            } else {
              setDashboardLink("/dashboard");
            }
          } else {
            setIsLoggedIn(false);
            setDashboardLink("/signin");
          }
        })
        .catch(() => {
          setIsLoggedIn(false);
          setDashboardLink("/signin");
        });
    } else {
      setIsLoggedIn(false);
      setDashboardLink("/signin");
    }
  }, []);

  const getStateList = async () => {
    console.log("get states");
    
    const res = await CommonService.getAllStatesPublic();
    if (res.success){
      const stateNames: string[] = res?.data?.map((state: { stateName: string }) => state?.stateName);
      console.log(stateNames);
      setStateList(stateNames);
      setIsRetrieveStateList(true);
    }
  } 

  const getInsuranceList = async () => {
    if (selectedState) {
      if (!isInsuranceSelectDropdownOpen) {
        try {
          const res = await ArticleService.getAllInsuranceCompanies(selectedState);
          if (res.success) {
            const transformedData = res.data.map((item: { _id: string; insuranceCompany: string }) => ({
              insuranceId: item._id,
              insuranceName: item.insuranceCompany
            }));
            setInsuranceList(transformedData);
          } else {
            toast.error("Insurance companies not found!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } catch (error) {
          console.error("Failed to fetch insurance companies:", error);
          toast.error("Error fetching insurance companies.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
      setIsInsuranceSelectDropdownOpen(!isInsuranceSelectDropdownOpen);
    } else {
      setShowErrorMessage(true);
    }
  };

  const handleSearchChangeForState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryForState(event.target.value);
  };

  const handleSearchChangeForSymptoms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryForSymptoms(event.target.value);
  };

  const handleSearchChangeForInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryForInsurance(event.target.value);
  };

  const getSymptomsList = async () => {
    try {
      const res = await ArticleService.getAllSymptoms();
      if (res.success) {
        const transformedData = res.data.map((item: { _id: string; symptomName: string }) => ({
          selectedSymptomId: item._id,
          selectedSymptomName: item.symptomName
        }));
        setSymptomsList(transformedData);
      }
    } catch (error) {
      toast.error("Error fetching symptoms.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }

  const handleIssueSelect = (id: any, name: any) => {
    setSelectedIssues((prevSelectedIssues: any) => {
      if (prevSelectedIssues && prevSelectedIssues.some((issue: Issue) => issue?.selectedSymptomId === id)) {
        return prevSelectedIssues.filter((issue: Issue) => issue.selectedSymptomId !== id);
      } else {
        if (prevSelectedIssues) {
          return [...prevSelectedIssues, { selectedSymptomId: id, selectedSymptomName: name }]
        } else {
          return [{ selectedSymptomId: id, selectedSymptomName: name }]
        }
      }
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsStateSelectDropdownOpen(false);
    setIsConcernSelectDropdownOpen(false);
    setSelectedIssues([]);
    setSelectedState(null);
    setShowErrorMessage(false);
    setIsInsuranceSelectDropdownOpen(false);
    setSelectedConcernAsString('');
    setSelectedInsurance(null);
    setSearchQueryForState('');
    setSearchQueryForSymptoms('');
    setSearchQueryForInsurance('');
    removeSelectedValuesFromLocalStorage();
  };

  const removeSelectedValuesFromLocalStorage = () => {
    localStorage.removeItem("insuranceCompany");
    localStorage.removeItem("state");
    localStorage.removeItem("experienceTag");
  }

  const openModal = async () => {
    setIsStateSelectDropdownOpen(false);
    setIsConcernSelectDropdownOpen(false);
    setSelectedIssues([]);
    setSelectedState(null);
    setShowErrorMessage(false);
    setIsInsuranceSelectDropdownOpen(false);
    setSelectedConcernAsString('');
    setSelectedInsurance(null);
    setSearchQueryForState('');
    setSearchQueryForSymptoms('');
    setSearchQueryForInsurance('');
    removeSelectedValuesFromLocalStorage();
    setIsModalOpen(true);
    console.log(isRetrieveStateList);
    if (!isRetrieveStateList) {
      await getStateList();
    }
  };

  const handleStateSelect = (state: string) => {
    setIsStateSelectDropdownOpen(false);
    setSearchQueryForState(state);
    setSelectedState(state);
    localStorage.setItem("state", state);
    setSearchQueryForInsurance('');
    setSelectedInsurance(null);
    localStorage.removeItem("insuranceCompany");
  };

  const handleInsuranceSelect = (insurance: Insurance) => {
    setSearchQueryForInsurance(insurance.insuranceName);
    setIsInsuranceSelectDropdownOpen(false);
    setSelectedInsurance(insurance);
    localStorage.setItem("insuranceCompany", JSON.stringify(insurance));
  };

  const handleGetStarted = () => {
    if (!selectedState) {
      setShowErrorMessage(true);
    } else {
      // Proceed with selected data
      location.href = '/main'
    }
  };

  const toggleStateSelectDropdown = () => {
    // setSearchQueryForState('');
    setIsStateSelectDropdownOpen(!isStateSelectDropdownOpen);
  };

  const toggleConcernSelectDropdown = () => {
    if (!isConcernSelectDropdownOpen) {
      const storedData = localStorage.getItem("experienceTag");
      const parsedData: Issue[] = JSON.parse(storedData as string);
      setSelectedIssues(parsedData);
    }
    setIsConcernSelectDropdownOpen(!isConcernSelectDropdownOpen);
  };

  const handleDoneClick = () => {
    const selectedText = selectedIssues.map((item: Issue) => item.selectedSymptomName).join(', ');
    localStorage.setItem('experienceTag', JSON.stringify(selectedIssues));
    const maxLength = 20;
    if (selectedText.length > maxLength) {
      setSelectedConcernAsString(`${selectedText.substring(0, maxLength)}...`);
      setSearchQueryForSymptoms(`${selectedText.substring(0, maxLength)}...`)
    } else {
      setSearchQueryForSymptoms(selectedText)
      setSelectedConcernAsString(selectedText);
    }
    setIsConcernSelectDropdownOpen(false);
  };

  const filteredStateList = useMemo(() => {
    if (stateList.some((state: string) => state.toLowerCase() === searchQueryForState.toLowerCase())) {
      return stateList;
    } else {
      return stateList.filter((item: string) =>
        item.toLowerCase().includes(searchQueryForState.toLowerCase())
      );
    }

  }, [searchQueryForState, stateList]);

  const filteredSymptomsList = useMemo(() => {
    if (selectedIssues && selectedIssues.length > 0 && symptomsList.some((simp: Issue) => simp.selectedSymptomName.toLowerCase() === selectedIssues[0]?.selectedSymptomName.toLowerCase())) {
      return symptomsList;
    } else {
      return symptomsList.filter((item: Issue) =>
        item.selectedSymptomName.toLowerCase().includes(searchQueryForSymptoms.toLowerCase())
      );
    }
    // return symptomsList.filter((item: Issue) =>
    //   item.selectedSymptomName.toLowerCase().includes(searchQueryForSymptoms.toLowerCase())
    // );
  }, [symptomsList, searchQueryForSymptoms]);

  const filteredInsuranceList = useMemo(() => {
    if (insuranceList.some((insu: Insurance) => insu.insuranceName.toLowerCase() === searchQueryForInsurance.toLowerCase())) {
      return insuranceList;
    } else {
      return insuranceList.filter((item: Insurance) =>
        item.insuranceName.toLowerCase().includes(searchQueryForInsurance.toLowerCase())
      );
    }
  }, [insuranceList, searchQueryForInsurance]);

  useOutsideClick(dropdownStateRef, () => setIsStateSelectDropdownOpen(false));
  useOutsideClick(dropdownConcernRef, () => setIsConcernSelectDropdownOpen(false));
  useOutsideClick(dropdownInsuranceRef, () => setIsInsuranceSelectDropdownOpen(false));

  return (
    <React.Fragment>
      {/* <section className="top-bar">
        <div className="container">
          <div className="row rowWidth">
            <div className="col-6 col-md-8 ">
              <div className="contact-info">
                <p className="contact-item2 mainText2">Phone: +1 (980) 890 7522</p>
                <p className="contact-item2 mainText md-hide">Email: info@mylavni.com</p>
              </div>
            </div>
            <div className="col-6 col-md-4  text-md-end social-icons-container01">
              <a href="https://www.instagram.com/mylavni/">
                <span className="iconify" data-icon="uil:instagram"></span>
              </a>
              <a href="https://www.facebook.com/lavnihealth">
                <span className="iconify" data-icon="uil:facebook-f"></span>
              </a>
              <a href="https://www.linkedin.com/company/lavni/">
                <span className="iconify" data-icon="uil:linkedin"></span>
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <header className="wrapper bg-light2">
        <nav className="navbar navbar-expand-lg classNameic transparent navbar-light">
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-100">
              <a href="/home">
                <img
                  src="./../static/assets/img/logo-dark.png"
                  style={{ height: "50px" }}
                  alt="Lavni helps you find a mental health professional
                that understands your unique experience"
                />
              </a>
            </div>
            <div className="navbar-collapse offcanvas offcanvas12 offcanvas-nav offcanvas-start">
              <div className="offcanvas-header d-lg-none">
                <h3 className="text-white fs-30 mb-0">Lavni</h3>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                <ul className="navbar-nav mainText navbar-nav123 fontHead mb-2">
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/service">
                      Service
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/faq">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/blogs/">
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/pricing/">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/contact">
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link navlin001 mainText" href="/therapist-onboarding">
                      Therapist
                    </a>
                  </li>
                  <li className="nav-item d-lg-none d-md-none">
                    <a className="nav-link navlin001 mainText" href="/signin">
                      Sign In
                    </a>
                  </li>
                  <li className="nav-item d-lg-none d-md-none">
                    <a className="nav-link navlin001 mainText" onClick={openModal}>
                      Sign Up
                    </a>
                  </li>
                </ul>

                <div className="offcanvas-footer d-lg-none">
                  <div>
                    <div className="mb-2">
                      <a href="tel:+19808907522" className="link-inverse">+1 (980) 890 7522</a>
                    </div>
                    <div className="mb-2">
                      <a href="mailto:info@mylavni.com" className="link-inverse">info@mylavni.com</a>
                    </div>
                    <nav className="nav social social-white mt-3">
                      <a href="https://www.instagram.com/mylavni/">
                        <span className="iconify" data-icon="uil:instagram"></span>
                      </a>
                      <a href="https://www.facebook.com/lavnihealth">
                        <span className="iconify" data-icon="uil:facebook-f"></span>
                      </a>
                      <a href="https://www.linkedin.com/company/lavni/">
                        <span className="iconify" data-icon="uil:linkedin"></span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="navbar-other ms-lg-4">
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                {/* <li className="nav-item d-none d-md-block">
                  <a href="/signin" className="btn btn-sm btn-outline-primary mainText rounded-pill3">
                    Sign In
                  </a>
                </li>
                <li className="nav-item d-none d-md-block">
                  <a onClick={openModal} className="btn btn-sm btn-primary rounded-pill3 mainText ml-B2 ">
                    Sign Up
                  </a>
                </li> */}
                {!isLoggedIn && (
                  <>
                    <li className="nav-item">
                      <a href="/signin" className="btn btn-sm btn-outline-primary mainText rounded-pill3">
                        Sign In
                      </a>
                    </li>
                    <li className="nav-item d-none d-md-block">
                      <a onClick={openModal} className="btn btn-sm btn-primary rounded-pill3 mainText ml-B2 ">
                        Sign Up
                      </a>
                    </li>
                  </>
                )}
                {isLoggedIn && (
                  <li className="nav-item d-none d-md-block">
                    <a
                      href={dashboardLink}
                      className="btn btn-sm btn-primary w-900"
                      style={{
                        borderRadius: "50rem",
                        padding: "12px 13px",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}>
                      Dashboard
                    </a>
                  </li>
                )}
                <li className="nav-item d-lg-none">{/* <button className="hamburger offcanvas-nav-btn" onClick={toggleOffcanvas}><span></span></button> */}</li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="container1 pt-3 pb-14 pb-md-16">
        {isModalOpen && (<div id="myModal" className="modalPopup">
          <div className="modal-contentPopup">
            <div className="d-flex justify-content-end align-items-center">
              <span className="close2" onClick={closeModal}>&times;</span>
            </div>
            <div className="notify-icon">
              <i className="fa fa-question-circle text-primary"></i>
            </div>

            <div className="creative-ui-mymodal">
              <div className="dropdown-container-mymodal" style={{ cursor: 'pointer' }} ref={dropdownStateRef}>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt"
                  focusable="false" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon" onClick={toggleStateSelectDropdown}>
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>

                <input
                  type="text"
                  className="dropdown-input-mymodal dropdown-in"
                  style={{ cursor: 'pointer' }}
                  id="selected-state-mymodal"
                  placeholder="Location"
                  // value={selectedState ? selectedState : ''}
                  value={searchQueryForState}
                  onChange={handleSearchChangeForState}
                  onClick={toggleStateSelectDropdown}
                />

                {isStateSelectDropdownOpen && (
                  <ul className="dropdown-list-mymodal" >
                    {filteredStateList.length > 0 && filteredStateList.map(state => (
                      <li
                        key={state}
                        className="dropdown-list-item-mymodal"
                        onClick={() => handleStateSelect(state)}
                      >
                        {state}
                      </li>
                    ))}
                  </ul>
                )}
                <p className="dropdown-texts" id="locationLiveMyModal" onClick={toggleStateSelectDropdown}>Where do you live?</p>
              </div>

              <div className="divider-mymodal"></div>
              <div className="divider-top-mymodal"></div>

              <div className="dropdown-container-issue-mymodal" style={{ cursor: 'pointer' }} ref={dropdownConcernRef}>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt"
                  focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon" onClick={toggleConcernSelectDropdown}>
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>
                <input
                  type="text"
                  className="dropdown-input dropdown-in"
                  style={{ cursor: 'pointer' }}
                  id="dropdown-input-issue-mymodal"
                  placeholder="Concerns"
                  // value={selectedConcernAsString}
                  value={searchQueryForSymptoms}
                  // readOnly
                  onClick={toggleConcernSelectDropdown}
                  onChange={handleSearchChangeForSymptoms}
                />
                {isConcernSelectDropdownOpen && (
                  <ul className="dropdown-list ml-md-13" id="dropdown-list-issue-mymodal">
                    <>
                      <button
                        className="submit-button btn btn-primary shared"
                        type="button"
                        onClick={handleDoneClick}
                      >
                        Done
                      </button>
                      {filteredSymptomsList.length > 0 && filteredSymptomsList.map((item: Issue) => (
                        <li
                          key={item.selectedSymptomId}
                          className={`dropdown-list-item-mymodal ${selectedIssues && selectedIssues.some((issue: Issue) => issue.selectedSymptomId === item.selectedSymptomId) ? 'selected-option' : ''
                            }`}
                          onClick={() => handleIssueSelect(item.selectedSymptomId, item.selectedSymptomName)}
                          style={{ display: 'flex' }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedIssues && selectedIssues.includes(item.selectedSymptomId)}
                            onChange={() => handleIssueSelect(item.selectedSymptomId, item.selectedSymptomName)}
                            style={{ marginRight: '8px', display: 'none' }}
                          />
                          {item.selectedSymptomName}
                        </li>
                      ))}
                    </>
                  </ul>
                )}
                <p className="dropdown-texts" id="issueDetailsMyModal" onClick={toggleConcernSelectDropdown}>What can we help with?</p>
              </div>

              <div className="divider-mymodal"></div>
              <div className="divider-top-mymodal"></div>

              <div className="dropdown-container-insurance-mymodal" style={{ cursor: 'pointer' }} onClick={getInsuranceList} ref={dropdownInsuranceRef}>
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt"
                  focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon">
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>
                <input
                  type="text"
                  className="dropdown-input dropdown-in"
                  style={{ cursor: 'pointer' }}
                  id="dropdown-input-insurance-mymodal"
                  placeholder="Insurance carriers"
                  // value={selectedInsurance?.insuranceName}
                  // readOnly
                  value={searchQueryForInsurance}
                  onChange={handleSearchChangeForInsurance}
                />
                {isInsuranceSelectDropdownOpen && (<ul className="dropdown-list ml-md-13" id="dropdown-list-insurance-mymodal">
                  {filteredInsuranceList.length > 0 && filteredInsuranceList.map((carrier: Insurance) => (
                    <li
                      key={carrier.insuranceId}
                      className="dropdown-list-item-mymodal"
                      onClick={() => handleInsuranceSelect(carrier)}
                    >
                      {carrier?.insuranceName}
                    </li>
                  ))}
                </ul>)}
                <p className="dropdown-texts" id="insuranceDetailsMyModal">Not sure? Skip for now.</p>
              </div>

              <div className="divider-mymodal"></div>
              <div className="divider-top-mymodal"></div>

              <div className="button-box-container-mymodal">
                <button
                  className="get-start-btn-mymodal"
                  id="getStatedBtnMyModal"
                  onClick={handleGetStarted}
                >
                  Get Started
                </button>
              </div>
            </div>

            <p className="error-msg" style={{ visibility: (!selectedState && showErrorMessage) ? 'visible' : 'hidden' }}>
              Error: Please select your state
            </p>
          </div>
        </div>)}
        <title>Article List | Lavni</title>
        <Container fluid>
          <Breadcrumbs title="Article" breadcrumbItem="Article List" />

          <Row>
            <PublicRightBar setSearchQuery={setSearchQuery} />
            <PublicArticleList searchQuery={searchQuery} />
          </Row>
        </Container>

      </div>
    </React.Fragment >
  );
};

export default PublicArticles;
