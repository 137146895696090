import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class VonageNativeSdkService {
    public static async initializeVonageNativeVideoCall(data: any){
        const url = Util.apiAuthUrl(`initiate-vonage-native-call`);
        return await axios.post<void, AppResponse<any>>(url, data);
    }

    public static async joinVonageNativeVideoCall(data: any){
        const url = Util.apiPublicUrl(`join-vonage-native-call`);
        return await axios.post<void, AppResponse<any>>(url, data);
    }

    public static async createVonageSession(name: any){
        const url = Util.apiPublicUrl(`generate-vonage-session/${name}`);
        return await axios.get(url);
    }

    public static async updateSessionIdInMeeting(roomName: string, sessionId: string): Promise<AppResponse<any>> {
        const url = Util.apiPublicUrl(`update-session-id/${roomName}`);
        const data = {
            sessionId 
        }
    
        return await axios.put<Partial<any>, AppResponse<any>>(url, data);
    }

    public static async cancelVonageNativeCall(meetingId: any){
        const url = Util.apiPublicUrl("cancel-vonage-native-call");

        const data = {
          sessionName: meetingId,
        };
        return await axios.post(url, data);
    }

    public static async updateUserFCMToken(token: string){
        const url = Util.apiAuthUrl("update-user-FCM-token");

        const data = {
          userFCMToken: token
        };
        return await axios.post(url, data);
    }

    public static async getOngoingCallsByUserId(){
        const url = Util.apiAuthUrl("get-all-ongoing-meetings-by-user-id");
        return await axios.get(url);
    }

    public static async startCaptions(data: any){
        const url = Util.apiAuthUrl("captions/start");
        return await axios.post(url, data);
    }

    public static async stopCaptions(captionsId: string){
        const url = Util.apiAuthUrl(`captions/${captionsId}/stop`);
        return await axios.get(url);
    }

    public static async updateTranscribes(vonageSessionName: string | null, transcripts: any, loggedUserRole: string | null){
        const data = {
            vonageSessionName,
            transcripts,
            loggedUserRole
        }
        const url = Util.apiPublicUrl(`update-transcribes`);
        return await axios.post(url, data);
    }

    public static async startArchiveVonageNative(sessionId: any){
        const url = Util.apiAuthUrl(`startArchiveVonageNative/${sessionId}`)
        const res= await axios.get(url);
        return res;
        
    }

    public static async stopArchiveVonageNative(vonageSessionName: any){
        const url = Util.apiAuthUrl(`stopArchiveVonageNative/${vonageSessionName}`)
        return await axios.get(url);
    }
}