import dayjs from "dayjs";
import React, { useState, useEffect, useContext } from "react";
import { VideoChatService } from "src/services/VideoChatService";
import { toast } from "react-toastify";
import { Card, Col, CardBody, Modal, Input, Row, Container } from "reactstrap";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import TimePicker from "react-time-picker";
import "./AudioRecorder2.scss";
import moment from "moment";
import MicRecorder from "mic-recorder-to-mp3";
import CustomTimePicker2 from "src/components/CustomTimePicker2";
import UserContext from './../../context/UserContext';
import { Util } from "src/Util";
import { AppResponse } from "src/models/Response";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const AddNewSessionModal: React.FC<{
  addNewSessionPopUp: boolean;
  userId: string;
  userName: string;
  setAddNewSessionPopUp: (value: boolean) => void;
}> = props => {
  type Value = Date | string | null;
  const [selectedStartTime, setSelectedStartTime] = useState<Value>(null);
  const [selectedCallType, setSelectedCallType] = useState(60);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedVoiceRecordFile, setSelectedVoiceRecordFile] = useState<any>();
  const [selectedSpendDuration, setSelectedSpendDuration] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [uploadAudioFromComputer, setUploadAudioFromComputer] = useState(false);
  const [showUploadAudio, setShowUploadAudio] = useState(false);

  const userId = props.userId;
  const chatBoxUsername = props.userName;

  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [recordArray, setRecordArray] = useState<any>([]);
  const [mp3AudioURL, setMp3AudioURL] = useState("");
  const [pauseTime, setPauseTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [user] = useContext(UserContext);

  const [firstSpeaker, setFirstSpeker] = useState(user?._id || "");

  const [uploadProgress, setUploadProgress] = useState(0);


  useEffect(() => {
    if (props.addNewSessionPopUp) {
      getUserMedia();
    }
    return () => {};
  }, [props.addNewSessionPopUp]);

  useEffect(() => {
    console.log("isLoading changed:", isLoading);
  }, [isLoading]);


  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: true } });
      console.log("Permission Granted");
      setIsBlocked(false);
    } catch (error) {
      console.log("Permission Denied");
      setIsBlocked(true);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isRecording && isPlaying) {
      setRecordingTime(pauseTime); // Reset the timer when recording starts
      timer = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      setPauseTime(recordingTime);
      if (timer) {
        clearInterval(timer);
      }
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isRecording, isPlaying]);

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
          setIsPlaying(true);
        })
        .catch((e: any) => console.error(e));
    }
  };

  const play = () => {
    if (blobURL) {
      Mp3Recorder.play()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e: any) => console.log(e));
      setIsPlaying(true);
    }
  };

  const pause = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([_, blob]: [Uint8Array, Blob]) => {
        setRecordArray([...recordArray, blob]);
        setIsPlaying(false);
      })
      .catch((e: any) => console.log(e));
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([_, blob]: [Uint8Array, Blob]) => {
        const blobURL = URL.createObjectURL(blob);
        const min = 1;
        const max = 99999;
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        const filee = new File([...recordArray, blob], `regular_meeting_${randomNumber}.mp3`, { type: "audio/mpeg" });
        const audioObjectURL = URL.createObjectURL(filee);
        setMp3AudioURL(audioObjectURL);
        setSelectedVoiceRecordFile(filee);
        setIsRecording(false);
        setRecordingTime(0);
        setPauseTime(0);
      })
      .catch((e: any) => console.log(e));
  };

  const clickCloseAddNewSessionPopUp = () => {
    props.setAddNewSessionPopUp(false);
    setSelectedVoiceRecordFile(null);
    setSelectedSpendDuration(0);
    setSelectedStartTime(null);
    setSelectedDate(new Date());
    setShowUploadAudio(false);
    setIsOpen(false);
    setSelectedValue("");
    setRecordArray([]);
    setRecordingTime(0);
    setPauseTime(0);
    setUploadProgress(0);
  };

  const addNewSession =async () => {
    try {
      if(!selectedDate){
        toast.error("Please select valid Date !", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return;
      }

      if(!selectedStartTime){
        toast.error("Please select valid Start Time !", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return;
      }

      if(!selectedSpendDuration || selectedSpendDuration < 15 || selectedSpendDuration > 60){
        toast.error("Spent Duration should be between 15 and 60 minutes !", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return;
      }

      setIsLoading(true);

      const selectedDateTo = moment(selectedDate);

      const selectedHour = moment(selectedStartTime, ["h:mm A"]).hour();
      const selectedMinute = moment(selectedStartTime, ["h:mm A"]).minute();

      selectedDateTo.hour(selectedHour).minute(selectedMinute).second(0);

      const finalDateWithTime = moment(selectedDateTo).toDate();

      const formData = new FormData();
      formData.append("file", selectedVoiceRecordFile);
      formData.append("recieverId", userId);
      formData.append("callDuration", selectedCallType.toString());

      formData.append("regularMeetingDate", finalDateWithTime.toString());

      formData.append("regularMeetingStartTime", selectedStartTime.toString());
      formData.append("spendDuration", selectedSpendDuration.toString());
      formData.append("meetingType", "Regular");
      formData.append("firstSpeaker", firstSpeaker);

      // const response = await VideoChatService.createRegularMeeting(formData);

      const url = Util.apiAuthUrl("createRegularMeeting");
      
      const response = await axios.post<Partial<any>, AppResponse<any>>(url, formData, {
        onUploadProgress: (progressEvent) => {
          try {
            const percentCompleted = Math.round((progressEvent?.loaded * 100) / progressEvent?.total);
            setUploadProgress(percentCompleted);
          } catch (error) {
            console.log("Upload Progress error");
          }
        },
      });

      if(response?.success) {
        toast.success("Meeting uploaded successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });

        props.setAddNewSessionPopUp(false);
        setSelectedSpendDuration(0);
        setSelectedVoiceRecordFile(null);
        setSelectedStartTime(null);
        setSelectedDate(new Date());
        setShowUploadAudio(false);
        setIsOpen(false);
        setSelectedValue("");
        setRecordArray([]);
        setRecordingTime(0);
        setPauseTime(0);
        setUploadProgress(0);

      } else {
        toast.error("Meeting upload fails!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleSelectedCallType = (dura: any) => {
    if (dura == "30") {
      setSelectedCallType(30);
    } else {
      setSelectedCallType(60);
    }
  };

  const handleSpendDuration = (e: any) => {
    const value = e.target.value;

    // Check if the input is a number, or empty string
    if (value === "" || !isNaN(value)) {
      setSelectedSpendDuration(value);
    }
  };

  const handleVoiceRecordFileChange = (e: any) => {
    try {
      const selectedFile = e.target.files && e.target.files[0]; // Check if e.target.files exists
      if (selectedFile) {
        const fileName = selectedFile.name.toLowerCase();

        if (fileName.endsWith(".mp3")) {
          setSelectedVoiceRecordFile(selectedFile);
        } else {
          toast.error("Please select an MP3 file!", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          e.target.value = "";
        }
      }
    } catch (error) {
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleItemClickInDropDown = (value: any) => {
    setShowUploadAudio(true);
    if (value == "upload_from_computer") {
      setUploadAudioFromComputer(true);
    } else {
      setUploadAudioFromComputer(false);
    }

    setSelectedValue(value); 
    setIsOpen(false); 

    setMp3AudioURL("");
    setSelectedVoiceRecordFile(null);
    setIsRecording(false);
    setRecordingTime(0);
    setPauseTime(0);
  };

  const handleFirstSpeker = (value: string) => {
    setFirstSpeker(value)
  }

  return props.addNewSessionPopUp ? (
    <Modal isOpen={props.addNewSessionPopUp} centered className="main-model">
      <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}>
        <Container fluid>
          <Card style={{ minHeight: "57vh" }}>
            <CardBody>
              <Row>
                <div className="card-heading mb-2">
                  <h5 className="font-size-17 profileFont mb-4">Session Information</h5>
                </div>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Client Name<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    <Input type="text" id="readOnlyField" readOnly value={chatBoxUsername} />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Date<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    <DatePicker value={selectedDate} onChange={handleDateChange} placeholderText="Select a date" />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Start Time<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    {/* <TimePicker
                      amPmAriaLabel="Select AM/PM"
                      clearAriaLabel="Clear value"
                      clockAriaLabel="Toggle clock"
                      hourAriaLabel="Hour"
                      minuteAriaLabel="Minute"
                      nativeInputAriaLabel="Time"
                      className="form-control"
                      disableClock={true}
                      onChange={newValue => {
                        if (newValue) {
                          const utcDate = moment(newValue, "h:mm A").utc().format('H:mm A');
                          setSelectedStartTime(newValue)
                        }
                      }}
                      value={selectedStartTime as any}
                      /> */}
                    <CustomTimePicker2
                      timeValue={selectedStartTime instanceof Date ? moment(selectedStartTime).format("hh:mm A") : selectedStartTime || ""}
                      setTimeValue={(value: string) => {
                        setSelectedStartTime(value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Call Type<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="3">
                  <div className="form-check mb-3 ">
                    <input className="form-check-input" type="radio" name="callTypeRadio" id="callTypeRadio60" value="60" onChange={handleSelectedCallType} />
                    <label className="form-check-label" htmlFor="callTypeRadio60">
                      60 Min
                    </label>
                  </div>
                </Col>
              </Row> */}
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Spent Duration (min)<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    <Input type="text" value={selectedSpendDuration} onChange={handleSpendDuration} />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">Audio Type</h5>
                  </div>
                </Col>
                <Col xl="6">
                  <Input type="select" name="repeat" onChange={e => handleItemClickInDropDown(e.target.value)} value={selectedValue}>
                    <option value={"record_the_audio"} defaultChecked>
                      Record the Audio
                    </option>
                    <option value={"upload_from_computer"}>Upload from Computer</option>
                  </Input>
                </Col>
              </Row>

              {/*add first speker */}

               <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">First Speaker</h5>
                  </div>
                </Col>
                <Col xl="6">
                  <Input type="select" name="repeat" onChange={e => handleFirstSpeker(e.target.value)} value={firstSpeaker}>
                    <option value={userId} >
                      {chatBoxUsername}
                    </option>
                    <option value={user?._id} defaultChecked>
                      {user?.username}
                    </option>
                  </Input>
                </Col>
              </Row>

              {/*end */}

              {uploadAudioFromComputer ? (
                <Row style={{ marginTop: "15px" }}>
                  <Col xl="6">
                    <div className="card-heading mb-2 add-session-popup">
                      <h5 className="font-size-14 profileFont mb-2">Uplod Audio</h5>
                    </div>
                  </Col>
                  <Col xl="4">
                    <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                      <Input
                        style={{ width: "100px" }}
                        className="form-control"
                        type="file"
                        name="audioFile"
                        id="audioFileInput"
                        onChange={handleVoiceRecordFileChange}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: "15px" }}>
                  <Row>
                    <h4>Audio Recorder</h4>
                  </Row>
                  <Row style={{ display: "flex" }} className="d-flex flex-column flex-xl-row">
                    <Col xs="12" className="d-flex flex-row justify-content-between align-items-center">
                      <button type="button" className="btn btn-outline-success" onClick={start} disabled={isRecording}>
                        <i className="fa fa-microphone fa-2x" aria-hidden="true"></i>
                      </button>
                      {isRecording && (
                        <div className="d-flex justify-content-center align-items-center">
                          {isPlaying ? (
                            <button className="btn btn-outline-danger" onClick={pause}>
                              <i className="fa fa-pause fa-2x" aria-hidden="true"></i>
                            </button>
                          ) : (
                            <button className="btn btn-outline-success" onClick={start}>
                              <i className="fa fa-play fa-2x" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      )}
                      <button type="button" className="btn btn-outline-danger" onClick={stop} disabled={!isRecording}>
                        <i className="fa fa-stop fa-2x" aria-hidden="true"></i>
                      </button>
                    </Col>
                  </Row>
                  <Row>{isRecording && <p className="recording-time">Recording Time: {recordingTime} seconds</p>}</Row>
                  <Row>{mp3AudioURL && !isRecording && <audio className="player" src={mp3AudioURL} controls></audio>}</Row>
                </Row>
              )}
              
              {uploadAudioFromComputer && selectedVoiceRecordFile && 
                <Col className="d-flex align-items-center justify-content-center mb-4 mt-3">
                  <span>{selectedVoiceRecordFile?.name ?? "uploaded-file"}</span>
                  <i
                    className="fa fa-trash fa-lg remove-icon text-danger ml-3"
                    onClick={() => {
                      setSelectedVoiceRecordFile(null);
                    }}
                  ></i>
                </Col>
              }

              {isLoading && uploadProgress && selectedVoiceRecordFile && <Row style={{ marginTop: "15px" }}>
                <div className="progress-sec">
                  <ProgressBar className="progress-bar-style" now={uploadProgress} label={`${uploadProgress}%`} />
                </div>
              </Row>}

              {isLoading && uploadProgress && selectedVoiceRecordFile && uploadProgress >= 100 && <Row style={{ marginTop: "15px" }}>
                <div className="">
                  <span className='text-success'>Upload complete! Please wait while we process your files.</span>
                </div>
              </Row>}

              <Row style={{ marginTop: "15px" }}>
                <Col xs="6" className="mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    disabled={isRecording || isLoading }
                    onClick={() => {
                      addNewSession();
                    }}
                  >
                    { isLoading ? "Creating Session..." : "Add New Session"}
                  </button>
                </Col>
                <Col xs="6" className="mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      clickCloseAddNewSessionPopUp();
                    }}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Container>
      </div>
    </Modal>
  ) : null;
};

export default AddNewSessionModal;
