import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import { Center } from "@react-three/drei";
import { auto } from "@popperjs/core";
import { ClinicalAssetment, ClinicalAssetmentFormOne } from "src/models/clinicalAssesment/clinicalAssesment";
import { CommonService } from "src/services/CommonService";
import { generalInforEthnicity, genralInfoGender } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { GenderTypes } from "src/models/User";
import PhoneInput from "react-phone-number-input";
import UserContext from './../../context/UserContext';

const FormOne = (props: any) => {
    const { formId, getUpdateFormId, clientId, assesmenData, client, dateOfSessionFrom } = props;
    const [dateValue, setDateValue] = useState(null);
    const [dateofSessionValue, setDosValue] = useState(null);
    const [dateofAssestmentValue, setDoaValue] = useState(null);
    const [consumerDateValue, setConsumerDateValue] = useState(null);
    const [isUpdate, setIsUpdate] = useState(props.isUpdate);
    const [edit, setEdit] = useState<boolean>(true);
    const [formData, setFormData] = useState<ClinicalAssetmentFormOne>({});
    const [user] = useContext(UserContext);

    const todayDate = moment.utc().startOf('day').toDate();

    useEffect(() => {
        try {
            if (dateOfSessionFrom) {
                const formattedDate = moment.utc(dateOfSessionFrom).format("YYYY-MM-DD");
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    assesmentHeader: {
                        ...prevFormData?.assesmentHeader,
                        dateOfSession: prevFormData?.assesmentHeader?.dateOfSession ?? formattedDate
                    },
                }));
            }
        } catch (error) {
                
        }
    }, [dateOfSessionFrom]);

    useEffect(() => {
        if (client) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                assesmentHeader: {
                    ...prevFormData?.assesmentHeader,
                    clientName: prevFormData?.assesmentHeader?.clientName || client?.username || "",
                    dateOfBirth: prevFormData?.assesmentHeader?.dateOfBirth ? moment.utc(prevFormData.assesmentHeader?.dateOfBirth).toDate() : moment.utc(client?.dateOfBirth).toDate(),
                    TherapistName:  prevFormData?.assesmentHeader?.TherapistName ? prevFormData?.assesmentHeader?.TherapistName :  user?.username,
                },
                generalInfor: {
                    ...prevFormData.generalInfor,
                    consumerDob: prevFormData?.generalInfor?.consumerDob ?  moment.utc(prevFormData?.generalInfor?.consumerDob).format("YYYY-MM-DD") : moment.utc(client?.dateOfBirth).format("YYYY-MM-DD") ,
                    consumerphone: prevFormData?.generalInfor?.consumerphone || client?.primaryPhone || "",
                    gender: prevFormData?.generalInfor?.gender || client?.gender?.toLowerCase(),
                    age: prevFormData?.generalInfor?.age || moment().diff(moment.utc(client?.dateOfBirth), 'years'),
                    ethnicity: prevFormData?.generalInfor?.ethnicity ? prevFormData?.generalInfor?.ethnicity : generalInforEthnicity.Other
                },
            }));
        }
    }, [client]);

    useEffect(() => {
        setIsUpdate(props.isUpdate);
    }, [props.isUpdate]);

    useEffect(() => {
        const updateFormOneData: ClinicalAssetmentFormOne = {
            assesmentHeader: assesmenData?.assesmentHeader,
            lengthOfAssestment: assesmenData?.lengthOfAssestment,
            dateOfAssesment: assesmenData?.dateOfAssesment ?? todayDate,
            comprehensiveAssessment: assesmenData?.comprehensiveAssessment,
            other: assesmenData?.other,
            generalInfor: assesmenData?.generalInfor,
            chiefComplaint: assesmenData?.chiefComplaint,
        }
        setFormData(updateFormOneData);
    }, [assesmenData]);


    function handleDateOfBirthUpdate(e: any) {
        const dob = e.target.value;
        setDateValue(dob);
        setFormData(prevState => ({
            ...prevState,
            assesmentHeader: {
                ...prevState.assesmentHeader,
                dateOfBirth: dob
            }
        }));
    }


    function handleDateOfSessionUpdate(e: any) {
        const dos = e.target.value;
        setDosValue(dos);
        setFormData(prevState => ({
            ...prevState,
            assesmentHeader: {
                ...prevState.assesmentHeader,
                dateOfSession: dos
            }
        }));
    }
    function handleDateOfAssesment(e: any) {
        const doa = e.target.value;
        setDoaValue(doa);
        setFormData(prevState => ({
            ...prevState,
            dateOfAssesment: doa
        }));
    }

    const updateAssesmentDetails = async () => {
        const updateAssesment: ClinicalAssetmentFormOne = {
            stepCount: 1,
            _id: formId,
            clientId: clientId,
            assesmentHeader: formData.assesmentHeader,
            lengthOfAssestment: formData.lengthOfAssestment,
            dateOfAssesment: formData.dateOfAssesment,
            comprehensiveAssessment: formData.comprehensiveAssessment,
            other: formData.other,
            generalInfor: formData.generalInfor,
            chiefComplaint: formData.chiefComplaint,
        }
        try {
            let res;
            if (isUpdate === false) {

                res = await CommonService.addClinicalAssesment(updateAssesment).then(res => {
                    if (res.success) {
                        props.onModalRefresher();
                        toast.success(res.success, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        const updatedFormId = res.data._id;
                        getUpdateFormId(updatedFormId);
                        setIsUpdate(true);
                        props.setIsVersionCreated(true);
                        props.nextStep();
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
            } else {
                res = await CommonService.updateClinicalAssesment(updateAssesment).then(res => {
                    if (res.success) {
                        toast.success(res.success, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        const updatedFormId = res.data._id;
                        getUpdateFormId(updatedFormId);
                        props.nextStep();
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                });
            }
        } catch (error) {
            toast.error("Error adding clinical assessment", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    return (
        <>

            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">

                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-dashboard position-relative p-2">
                            <Row>
                                <Col xl={7} lg={12}>
                                    <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <img loading="lazy" src="./../static/assets/img/logo-dark.png"
                                                    style={{ height: "50px" }} />
                                            </div>
                                            <div className="col">
                                                <h5 className="font-size-20 mb-4">Lavni,Inc.<br />
                                                    COMPREHENSIVE CLINICAL ASSESSMENT</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={5} lg={12}>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Client Name :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="text"
                                                        id="name"
                                                        value={formData?.assesmentHeader?.clientName || ""}
                                                        onChange={(e) => setFormData({ ...formData, assesmentHeader: { ...formData.assesmentHeader, clientName: e.target.value } })}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of Birth :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="date"
                                                        id="date"
                                                        value={moment.utc(formData.assesmentHeader?.dateOfBirth).format("YYYY-MM-DD")}
                                                        onChange={e => handleDateOfBirthUpdate(e)}
                                                        max={moment().format("YYYY-MM-DD")}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Insurance Number :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="text"

                                                        value={formData.assesmentHeader?.insuranceNumber || ""}
                                                        id="name"
                                                        onChange={(e) => setFormData({ ...formData, assesmentHeader: { ...formData.assesmentHeader, insuranceNumber: e.target.value } })}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of Session :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="date"
                                                        id="date"

                                                        value={moment.utc(formData.assesmentHeader?.dateOfSession).format("YYYY-MM-DD")}
                                                        onChange={e => handleDateOfSessionUpdate(e)}
                                                        max={moment().format("YYYY-MM-DD")}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    <Row style={{ justifyContent: "center" }}>
                                        <Card style={{ padding: "inherit", width: "95%", marginBottom: "5px" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Therapist :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem" }}
                                                        type="text"
                                                        id="name"

                                                        value={formData.assesmentHeader?.TherapistName || ""}
                                                        onChange={e => setFormData({ ...formData, assesmentHeader: { ...formData.assesmentHeader, TherapistName: e.target.value } })}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xxl={8} xl={6}>
                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                                <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of Assessment :</h6>
                                </div>
                                <div className="col">
                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                        type="date"
                                        id="date"

                                        value={moment.utc(formData.dateOfAssesment).format("YYYY-MM-DD")}
                                        onChange={e => handleDateOfAssesment(e)}
                                        max={moment().format("YYYY-MM-DD")}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xxl={4} xl={6}>
                        <div className="row">
                            <div className="col-lg-6 col-5 d-flex">
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Length of Assessment:</h6>
                            </div>
                            <div className="col-lg-6 col-4">
                                <div className="input-group input-group-sm">
                                    <input type="number" className="form-control p-1"
                                        value={formData.lengthOfAssestment || ""}

                                        onChange={e => setFormData({ ...formData, lengthOfAssestment: e.target.value })}
                                    />
                                    <span className="input-group-text p-1">min</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xxl={5} xl={6}>
                        <div className="row">
                            <div className="col-1">
                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                    type="radio"
                                    name="comprehensiveAssessment"
                                    className="form-check-input"
                                    // value={formData?.comprehensiveAssessment ? "true" : ""}
                                    checked={formData.comprehensiveAssessment === true}
                                    // defaultChecked={formData.comprehensiveAssessment === true}
                                    onClick={e => setFormData({ ...formData, comprehensiveAssessment: true, other: "" })}
                                />
                            </div>
                            <div className="col-10">
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Comprehensive Clinical Assessment </label>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={3} xl={6}>
                        <div className="row">
                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    name="comprehensiveAssessment"
                                    className="form-check-input"
                                    // value={formData?.other !== undefined ? formData.other.toString() : ''}
                                    checked={formData.comprehensiveAssessment === false}
                                    // checked={isEdit ? formData.comprehensiveAssessment === false : undefined}
                                    // defaultChecked={formData.comprehensiveAssessment === false}
                                    onClick={e => setFormData({ ...formData, comprehensiveAssessment: false })}
                                />
                            </div>
                            <div className="col">
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "1rem" }}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                Other :
                                            </h6>
                                        </div>
                                        <div className="col">
                                            <input
                                                style={{
                                                    width: "100%",
                                                    border: "none",
                                                    marginTop: "0.1rem",
                                                    backgroundColor: "unset",
                                                }}
                                                type="text"
                                                disabled={formData.comprehensiveAssessment !== false || !edit}
                                                value={formData.other || ''}
                                                onChange={e => setFormData({ ...formData, other: e.target.value, comprehensiveAssessment: false })}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>General Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>PCP Referral :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.generalInfor?.pcpReferal || ""}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, pcpReferal: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>NPI :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.generalInfor?.npi || ""}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, npi: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Consumer DOB :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date"
                                                    value={moment.utc(formData.generalInfor?.consumerDob).format("YYYY-MM-DD")}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, consumerDob: e.target.value } })}
                                                    max={moment().format("YYYY-MM-DD")}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Consumer Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    defaultCountry="US"
                                                    value={formData.generalInfor?.consumerphone}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, consumerphone: e } })}
                                                />
                                                {/* <input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, consumerphone: e.target.value} })}
                                                /> */}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={6}>
                                    <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Sex :</h6>
                                        </div>
                                        <div className="col-auto form-check form-check-inline">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                checked={formData.generalInfor?.gender === genralInfoGender.male}
                                                onClick={() =>
                                                    setFormData({
                                                        ...formData, generalInfor: {
                                                            ...formData.generalInfor, gender: genralInfoGender.male
                                                        }
                                                    })}

                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Male</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                checked={formData.generalInfor?.gender === genralInfoGender.female}
                                                onClick={() =>
                                                    setFormData({
                                                        ...formData, generalInfor: {
                                                            ...formData.generalInfor, gender: genralInfoGender.female
                                                        }
                                                    })}

                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Female</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Age :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="number"
                                                    id="name"

                                                    value={formData.generalInfor?.age}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, age: parseInt(e.target.value) } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={auto} md={12}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Race/Ethnicity :</h6>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.Hispanic }
                                            onClick={e => setFormData({
                                                ...formData, generalInfor: { ...formData.generalInfor, ethnicity: generalInforEthnicity.Hispanic, other: "" }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hispanic</label>
                                    </div>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.Caucasian}
                                            onClick={e => setFormData({
                                                ...formData, generalInfor: { ...formData.generalInfor, ethnicity: generalInforEthnicity.Caucasian, other: "" }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Caucasian</label>
                                    </div>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.AfricanAmerican}
                                            onClick={e => setFormData({
                                                ...formData, generalInfor: { ...formData.generalInfor, ethnicity: generalInforEthnicity.AfricanAmerican, other: "" }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>African-American</label>
                                    </div>
                                </Col>
                                <Col xl={auto} lg={6}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="ethnicity"
                                            checked={formData.generalInfor?.ethnicity === generalInforEthnicity.AsianAmerican}
                                            onClick={e => setFormData({
                                                ...formData, generalInfor: { ...formData.generalInfor, ethnicity: generalInforEthnicity.AsianAmerican, other: "" }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Asian-American</label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="ethnicity"
                                                checked={formData.generalInfor?.ethnicity === generalInforEthnicity.Other}
                                                onClick={() => setFormData({
                                                    ...formData, generalInfor: { ...formData.generalInfor, ethnicity: generalInforEthnicity.Other }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Other :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.generalInfor?.ethnicity !== generalInforEthnicity.Other}
                                                            value={formData.generalInfor?.other || ""}
                                                            onChange={e => (setFormData({
                                                                ...formData, generalInfor: { ...formData.generalInfor, other: e.target.value, ethnicity: generalInforEthnicity.Other }
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Emergency Contact Name :</h6>
                                            </div>
                                            <div className="col">
                                                {/* <PhoneInput
                                                    defaultCountry="US"
                                                    value={formData.generalInfor?.emergancyContactName}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, emergancyContactName: e } })}
                                                /> */}
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.generalInfor?.emergancyContactName || ""}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, emergancyContactName: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    defaultCountry="US"
                                                    value={formData.generalInfor?.phone}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, phone: e } })}
                                                />
                                                {/* <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, phone: e.target.value } })}
                                                /> */}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Individuals participating in the assessment :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.generalInfor?.individualParticipants}
                                                    onChange={e => setFormData({ ...formData, generalInfor: { ...formData.generalInfor, individualParticipants: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Does the client have any other insurance besides BCBS?</h6>
                                </Col>
                                <Col lg={2} md={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            name="insuranceBesideBCBS"
                                            className="form-check-input"
                                            checked={formData.generalInfor?.insuranceBesideBCBS?.noOtherInusrnace === true}
                                            onClick={e =>
                                                setFormData({
                                                    ...formData, generalInfor: {
                                                        ...formData.generalInfor, insuranceBesideBCBS: { noOtherInusrnace: true }
                                                    }
                                                })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                    </div>
                                </Col>
                                <Col lg={6} md={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                name="insuranceBesideBCBS"
                                                className="form-check-input"
                                                checked={formData.generalInfor?.insuranceBesideBCBS?.noOtherInusrnace === false}
                                                onClick={e =>
                                                    setFormData({
                                                        ...formData, generalInfor: {
                                                            ...formData.generalInfor, insuranceBesideBCBS: { noOtherInusrnace: false }
                                                        }
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            <span style={{ color: "red" }}>*</span> Yes: Name of Insurance :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"

                                                            disabled={formData.generalInfor?.insuranceBesideBCBS?.noOtherInusrnace !== false}
                                                            value={formData.generalInfor?.insuranceBesideBCBS?.nameOfInsurance || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, generalInfor: {
                                                                    ...formData.generalInfor, insuranceBesideBCBS: { nameOfInsurance: e.target.value, noOtherInusrnace: false }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is the client receiving any other therapy services?</h6>
                                </Col>
                                <Col lg={2} md={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="otherTherapyService"
                                            checked={formData.generalInfor?.otherTherapyService?.noService === true}
                                            onClick={e => setFormData({
                                                ...formData, generalInfor: {
                                                    ...formData.generalInfor, otherTherapyService: { noService: true }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}> No</label>
                                    </div>
                                </Col>
                                <Col lg={6} md={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="otherTherapyService"
                                                checked={formData.generalInfor?.otherTherapyService?.noService === false}
                                                onClick={e => setFormData({
                                                    ...formData, generalInfor: {
                                                        ...formData.generalInfor, otherTherapyService: { noService: false }
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            <span style={{ color: "red" }}>*</span> Yes: Name of other Service :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.generalInfor?.otherTherapyService?.noService !== false || !edit}
                                                            value={formData.generalInfor?.otherTherapyService?.nameOfService || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, generalInfor: {
                                                                    ...formData.generalInfor, otherTherapyService: { nameOfService: e.target.value, noService: false }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}><span style={{ color: "red" }}>*</span> If yes, Therapist needs to call office to check on eligibility.</h6>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Chief Complaint (as stated by consumer and guardian (if applicable) in quotes)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}>Precipitating Problem(s) :</h6>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={formData?.chiefComplaint?.precipitatingProblem}
                                        id="floatingTextarea"
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                chiefComplaint: {
                                                    ...prevState?.chiefComplaint,
                                                    precipitatingProblem: data
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, precipitatingProblem: e.target.value } })}
                                /> */}
                            </Card>
                            <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.5rem" }}>
                                <div style={{ margin: "0.2rem" }}>
                                    <h6 style={{ fontWeight: "600", display: "inline" }}>History of Present Illness </h6>
                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                        (Include referral source, onset/precipitating events, symptoms associated with the problem, duration, frequency; previous episodes and recent progressions, if applicable) :
                                    </span>
                                </div>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        id="floatingTextarea"
                                        data={formData?.chiefComplaint?.HistoryofIllness}
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                chiefComplaint: {
                                                    ...prevState?.chiefComplaint,
                                                    HistoryofIllness: data
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, HistoryofIllness: e.target.value } })}
                                /> */}
                            </Card>
                            <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.5rem" }}>
                                <div style={{ margin: "0.2rem" }}>
                                    <h6 style={{ fontWeight: "600", display: "inline" }}>Family and Social History </h6>
                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                        (Include marital status, current familial stressors, family history of mental illness, quality of peer interactions, and gender issues, if applicable, strengths and deficits in this domain) :
                                    </span>
                                </div>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        id="floatingTextarea"
                                        data={formData?.chiefComplaint?.familySocialHistory}
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                chiefComplaint: {
                                                    ...prevState?.chiefComplaint,
                                                    familySocialHistory: data
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, familySocialHistory: e.target.value } })}
                                /> */}
                            </Card>
                            <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}>Consumer’s Short Term Goals :</h6>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        id="floatingTextarea"
                                        data={formData?.chiefComplaint?.shortTermGoal}
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                chiefComplaint: {
                                                    ...prevState?.chiefComplaint,
                                                    shortTermGoal: data
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, shortTermGoal: e.target.value } })}
                                /> */}
                            </Card>
                            <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                                <h6 className="fw-600" style={{ margin: "0.2rem" }}>Consumer’s Long Term Goals :</h6>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        id="floatingTextarea"
                                        data={formData?.chiefComplaint?.longTermGoal}
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                chiefComplaint: {
                                                    ...prevState?.chiefComplaint,
                                                    longTermGoal: data
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, chiefComplaint: { ...formData?.chiefComplaint, longTermGoal: e.target.value } })}
                                /> */}
                            </Card>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={() => {
                                updateAssesmentDetails();
                            }}
                        >
                            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                        </button>
                        {isUpdate == true ? (
                            <button
                                type="button"
                                className="btn btn-skip  relative mt-3 mr-2 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                    paddingBottom: "7.5px !important"
                                }}

                                onClick={props.nextStep}
                            >
                                Next
                            </button>) : (
                            <> </>
                        )
                        }

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 1 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default FormOne;