// src/Tabs/Forms.tsx
import React from 'react';
interface FormsClientSaasProps {
  clientId: string;
}
const FormsClientSaas: React.FC<FormsClientSaasProps> = ({ clientId }) => {
  return (
    <div>
      <h3>Forms</h3>
      <p>Content for forms goes here.</p>
    </div>
  );
};

export default FormsClientSaas;
