import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row } from "reactstrap";
import { AuthFormType } from "src/models/AuthorizationFormData";
import PhoneInput from "react-phone-number-input";
import { AmbetterAuthForm } from "src/models/AuthorizationForm/ambetter-auth-form-model";
import moment from "moment";

const AmbetterAuthFormPdf: React.FC<{ authorizationFormType: AuthFormType; authorizationFormData: AmbetterAuthForm; }> = props => {
  const authorizationFormType = props.authorizationFormType;
  const authorizationFormData = props.authorizationFormData;
  const [formData, setFormData] = useState<AmbetterAuthForm>();

  useEffect(() => {
    const updateAmbetterData: AmbetterAuthForm = {
      basicInformation: authorizationFormData?.basicInformation,
      memberInformation: authorizationFormData?.memberInformation,
      providerInformation: authorizationFormData?.providerInformation,
      facilityInformation: authorizationFormData?.facilityInformation,
      authorizationRequest: authorizationFormData?.authorizationRequest,
    };
    setFormData(updateAmbetterData);
  }, [authorizationFormData]);

  function autoResizeTextarea(e: any) {
    const textarea = e.target;
    textarea.style.height = 'auto';

    const newHeight = Math.min(textarea.scrollHeight, 3 * 16);
    textarea.style.height = `${newHeight}px`;

    textarea.style.overflowY = textarea.scrollHeight > newHeight ? 'scroll' : 'hidden';
  }

//   const formatDate = (date: any) => {
//     if (!date) return '';
//     const d = new Date(date);
//     const year = d.getFullYear();
//     const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//     const day = String(d.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
// };

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment.utc(date).format('YYYY-MM-DD');
  };

  return (
    <>
      {authorizationFormType === AuthFormType.AmbetterAuthForm && (
        <>
          <div>
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
              <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
                <Row className="align-items-center">
                  <Col xs="12" sm="5" className="text-center text-sm-start">
                    <div>
                      <img
                        src="/static/assets/img/authorization/ambetter_nc.png"
                        alt="Ambetter Insurance Company Logo"
                        className="img-fluid"
                        style={{ maxWidth: '200px' }}
                      />
                    </div>
                  </Col>
                  <Col xs="12" sm="7" className="text-center text-sm-end">
                    <p className="p-3" style={{ margin: "0", fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                      <span style={{ fontSize: "20px", color: "#333", fontWeight: "500", marginBottom: "0.75rem" }}>Outpatient Authorization Form</span><br />
                      Complete and Fax to <strong> 1-844-536-2412</strong><br />
                      Transplant Request Fax to <strong> 1-833-783-0877</strong><br />
                      Buy & Bill Drugs <strong> 833-893-1490</strong>
                    </p>
                  </Col>
                </Row>
              </Card>
              <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px", padding: "0.7rem 0.7rem 0rem 0.7rem" }}>
                <Row>
                  <div className="card-bodyDiv" style={{ width: '100%' }}>
                    <div style={{ fontSize: "12px", color: "#333", lineHeight: "1.6", padding: "1px 1px 1px 1px" }}>
                      <Row>
                        <Col lg={5}>
                          <div className="row">
                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                              <input
                                style={{ fontSize: "larger" }}
                                type="checkbox"
                                className="form-check-input"
                                readOnly
                                checked={formData?.basicInformation?.additionalRequest}
                              />
                            </div>
                            <div className="col">
                              <div className="row">
                                <div className="col-auto">
                                  <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Request for additional units.</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <Card style={{ marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col-auto">
                                <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Existing Authorization :</h6>
                              </div>
                              <div className="col">
                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                  type="text"
                                  id="name"
                                  readOnly
                                  value={formData?.basicInformation?.existingAuthorization || ''}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col lg={2}>
                          <Card style={{ marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col-auto">
                                <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Units :</h6>
                              </div>
                              <div className="col">
                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                  type="text"
                                  readOnly
                                  value={formData?.basicInformation?.unit || ''}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row></div>
                  </div>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="row">
                      <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                        <input
                          style={{ fontSize: "larger" }}
                          type="checkbox"
                          className="form-check-input"
                          readOnly
                          checked={formData?.basicInformation?.standardRequest}
                        />
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}><strong>Standard requests</strong> - Determination within 3 business days of receiving all necessary information.</h6>
                          </div>
                        </div>

                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="row">
                      <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                        <input
                          style={{ fontSize: "larger" }}
                          type="checkbox"
                          className="form-check-input"
                          readOnly
                          checked={formData?.basicInformation?.urgentRequest}
                        />
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px", marginBottom: "0.7rem" }}><strong> Urgent requests</strong> - I certify this request is urgent and medically necessary to treat an injury, illness or condition (not life threatening) within 24 hours to
                              avoid complications and unnecessary suffering or severe pain.</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>

              </Card>
              <Row className="mt-2">
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>MEMBER INFORMATION</h5>
                  </Card>
                  <Card className="match-card-body p-3">
                    <div className="table-responsive">
                      <div className="clinical-form">
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Member Name :</label>
                          </Col>
                          <Col xs="12" sm="10">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.memberInformation?.memberName || ''}
                                onInput={(e) => autoResizeTextarea(e)}
                              />
                            </div>
                          </Col>

                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}>Date of birth :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input
                                type="date"
                                style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                readOnly
                                value={formatDate(formData?.memberInformation?.dateOfBirth)}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Medicaid ID :</label>
                          </Col>
                          <Col xs="12" sm="4">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.memberInformation?.medicaidId || ''}
                                onInput={(e) => autoResizeTextarea(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </Row>
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <div style={{ display: 'flex', }}>
                      <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>REQUESTING PROVIDER INFORMATION</h5>
                    </div>
                  </Card>
                  <Card className="match-card-body p-3">
                    <div className="table-responsive">
                      <div className="clinical-form">
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="3">
                            <label style={{ fontSize: "12px" }}>Requesting Provider Contact Name :</label>
                          </Col>
                          <Col xs="12" sm="9">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.providerInformation?.requestingProviderContactName || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="3">
                            <label style={{ fontSize: "12px" }}>Requesting Provider Name :</label>
                          </Col>
                          <Col xs="12" sm="9">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.providerInformation?.requestingProviderName || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Requesting NPI :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.providerInformation?.requestingNpi || ''}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Requesting TIN :</label>
                          </Col>
                          <Col xs="12" sm="4">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.providerInformation?.requestingTin || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}>Phone :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <PhoneInput
                                style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                defaultCountry="US"
                                readOnly
                                value={formData?.providerInformation?.phone || ''}
                                onChange={(value) => setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    phone: value,
                                    requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName !== undefined ? formData.providerInformation.requestingProviderContactName : '',
                                    requestingNpi: formData?.providerInformation?.requestingNpi !== undefined ? formData.providerInformation.requestingNpi : '',
                                    requestingTin: formData?.providerInformation?.requestingTin !== undefined ? formData.providerInformation.requestingTin : '',
                                    requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                    faxNumber: formData?.providerInformation?.faxNumber !== undefined ? formData.providerInformation.faxNumber : '',
                                  }
                                })}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Fax Number :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <PhoneInput
                                style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                defaultCountry="US"
                                readOnly
                                value={formData?.providerInformation?.faxNumber || ''}
                                onChange={(value) => {
                                  setFormData({
                                    ...formData,
                                    providerInformation: {
                                      ...formData?.providerInformation,
                                      faxNumber: value || '',
                                      requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName !== undefined ? formData.providerInformation.requestingProviderContactName : '',
                                      requestingNpi: formData?.providerInformation?.requestingNpi !== undefined ? formData.providerInformation.requestingNpi : '',
                                      requestingTin: formData?.providerInformation?.requestingTin !== undefined ? formData.providerInformation.requestingTin : '',
                                      requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                      phone: formData?.providerInformation?.phone !== undefined ? formData.providerInformation.phone : '',
                                    }
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </Row>
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <div style={{ display: 'flex', }}>
                      <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>SERVICING PROVIDER / FACILITY INFORMATION</h5>
                      <div className="form-check">
                        <input className="form-check-input"
                          type="checkbox"
                          style={{ fontSize: "larger" }}
                        />
                        <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem", fontSize: "12px" }}>
                          Same as Requesting Provider
                        </label>
                      </div>
                    </div>
                  </Card>
                  <Card className="match-card-body p-3">
                    <div className="table-responsive">
                      <div className="clinical-form">
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="3">
                            <label style={{ fontSize: "12px" }}>Servicing Provider Contact Name :</label>
                          </Col>
                          <Col xs="12" sm="9">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.facilityInformation?.servicingProviderContactName || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="3">
                            <label style={{ fontSize: "12px" }}>Servicing Provider / Facility Name:</label>
                          </Col>
                          <Col xs="12" sm="9">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.facilityInformation?.servicingFacilityName || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Servicing NPI :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.facilityInformation?.servicingNpi || ''}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Servicing TIN :</label>
                          </Col>
                          <Col xs="12" sm="4">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                readOnly
                                value={formData?.facilityInformation?.servicingTin || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}>Phone :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <PhoneInput
                                style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                defaultCountry="US"
                                readOnly
                                value={formData?.facilityInformation?.phone || ''}
                                onChange={(value) => setFormData({
                                  ...formData,
                                  facilityInformation: {
                                    ...formData?.facilityInformation,
                                    phone: value,
                                    servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                    servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                    servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                    servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                    faxNumber: formData?.facilityInformation?.faxNumber !== undefined ? formData.facilityInformation.faxNumber : '',
                                  }
                                })}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}>Fax Number :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <PhoneInput
                                style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                defaultCountry="US"
                                readOnly
                                value={formData?.facilityInformation?.faxNumber || ''}
                                onChange={(value) => setFormData({
                                  ...formData,
                                  facilityInformation: {
                                    ...formData?.facilityInformation,
                                    faxNumber: value,
                                    servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                    servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                    servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                    servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                    phone: formData?.facilityInformation?.phone !== undefined ? formData.facilityInformation.phone : '',
                                  }
                                })} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </Row>
            </Card>

            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>AUTHORIZATION REQUEST</h5>
                  </Card>
                  <Card className="match-card-body p-3">
                    <div className="table-responsive">
                      <div className="clinical-form">
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}><strong>Primary</strong> Procedure Code :</label>
                          </Col>
                          <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                placeholder="(CPT/HCPCS)"
                                readOnly
                                value={formData?.authorizationRequest?.primaryProcedureCode || ''}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                placeholder="(Modifier)"
                                readOnly
                                value={formData?.authorizationRequest?.primaryProcedureCodeModifier || ''}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}><strong>Additional</strong> Procedure Code :</label>
                          </Col>
                          <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                placeholder="(CPT/HCPCS)"
                                readOnly
                                value={formData?.authorizationRequest?.additionalProcedureCode || ''}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                placeholder="(Modifier)"
                                readOnly
                                value={formData?.authorizationRequest?.additionalProcedureCodeModifier || ''}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}><strong>Start Date</strong> OR Admission Date :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                readOnly
                                value={formatDate(formData?.authorizationRequest?.startDate)}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}><strong>End Date</strong> OR Discharge Date :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                readOnly
                                value={formatDate(formData?.authorizationRequest?.endDate)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="12" sm="2" >
                            <label style={{ fontSize: "12px" }}>Total Units/Visits/Days :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                readOnly
                                value={formData?.authorizationRequest?.totalUnit || ''}
                              />
                            </div>
                          </Col>
                          <Col xs="12" sm="2">
                            <label style={{ fontSize: "12px" }}>Diagnosis Code :</label>
                          </Col>
                          <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }} placeholder="(ICD-10)"
                                readOnly
                                value={formData?.authorizationRequest?.diagnosisCode || ''}
                              />
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Card>
                </div>
              </Row>
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <h5 className="fw-500" style={{ margin: "auto" }}>OUTPATIENT SERVICE TYPE
                    </h5>
                  </Card>
                  <Card className="match-card-body p-3">
                    <div className="table-responsive">
                      <div className="clinical-form">
                        <Row className="mb-2">
                          <Col xl={5} className="d-flex">
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Enter the Service type number in the boxes : </label></Col>
                          <Col xl={7} className="d-flex">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      maxLength={3}
                                      type="text"
                                      readOnly
                                      value={formData?.authorizationRequest?.serviceTypeNumber || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                        <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                          <Row className="mt-2">
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>422</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Biopharmacy</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>997</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Office Visit/Consult</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>712</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Cochlear Implants & Surgery</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>210</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Orthotics</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>299</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Drug Testing</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>794</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Outpatient Services</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>205</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Genetic Testing & Counseling</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>249</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Home Health</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>147</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Prosthetics</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>410</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Observation</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>171</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Outpatient Surgery</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>202</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Pain Management</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>993</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Transplant Evaluation</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>290</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> Hyperbaric Oxygen Therapy</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>201</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Sleep Study</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>390</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> Hospice Services</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>209 </strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Transplant Surgery</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>211</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>OB Ultrasound</label>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>922</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Experimental and Investigational
                                Services</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>724 </strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Transportation</label>
                            </Col>
                          </Row>
                          <Row >
                            <h6><u><strong>Behavioral Health</strong></u></h6>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>533</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Applied Behavioral Analysis</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>512</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>BH Community Based Services</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>515</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Electroconvulsive Therapy</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>516</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Intensive Outpatient Therapy</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>510</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>BH Medical Management</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>520</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>  BH Professional Fees</label>
                            </Col>
                          </Row>
                          <Row >
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>519</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Outpatient Therapy</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>530</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH PHP</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>522</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>  BH Psychiatric Evaluation
                              </label>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>518</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>  BH Mental Health /Chemical Dependency Observation</label>
                            </Col>
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>521</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Psychological Testing</label>
                            </Col>
                          </Row>
                          <Row >
                            <h6><u><strong>DMA</strong></u></h6>
                          </Row>
                          <Row className="mb-2">
                            <Col xl={4} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>417</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Rental</label>
                            </Col>
                            <Col xl={8} className="d-flex">
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>120</strong></label>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> Purchase</label>
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", marginLeft: "0.5rem" }}>
                                <div className="row">
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      readOnly
                                      value={formData?.authorizationRequest?.purchasePrice || ''}
                                    />
                                  </div>
                                </div>
                              </Card>
                              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "11px", marginLeft: "0.5rem" }}>
                                <i>(Purchase Price)</i></label>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </Card>
                </div >
              </Row >
              <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px", padding: "0.7rem 0.7rem 0rem 0.7rem" }}>
                <Row>
                  <Col lg={7}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}><strong>URGENT REQUESTS MUST BE SIGNED BY THE
                          REQUESTING PHYSICIAN TO RECEIVE PRIORITY</strong></h6>
                      </div>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className="row justify-content-between" style={{ textAlign: "center" }}>
                      <div className="col-lg-5 col-md-12 m-2">
                        <div className="d-flex flex-column align-items-center">
                          <div className="sign" style={{ width: '100%', maxWidth: '350px' }}>
                            {formData?.basicInformation?.signature && (
                              <img className="sigCanvasNew" src={formData?.basicInformation?.signature} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px", padding: "0.7rem 0.7rem 0rem 0.7rem" }}>
                <Row className="mb-2">
                  <Col lg={12}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px", textAlign: "center" }}><strong>ALL REQUIRED FIELDS MUST BE FILLED IN AS INCOMPLETE FORMS WILL BE REJECTED.
                          COPIES OF ALL SUPPORTING CLINICAL INFORMATION ARE REQUIRED. LACK OF CLINICAL INFORMATION MAY RESULT IN DELAYED DETERMINATION</strong></h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "10px" }}><strong>Disclaimer:</strong> An authorization is not a guarantee of payment. Member must be eligible at the time services are rendered. Services must be a covered Health Plan Benefit and medically necessary with prior
                          authorization as per Plan policy and procedures.</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={12}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "10px" }}><strong>Confidentiality:</strong> The information contained in this transmission is confidential and may be protected under the Health Insurance Portability and Accountability Act of 1996. If you are not the
                          intended recipient any use, distribution, or copying is strictly prohibited. If you have received this facsimile in error, please notify us immediately and destroy this document.</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default AmbetterAuthFormPdf;
