import { AppResponse } from "./../models/Response";
import { TwilioToken } from "./../models/TwilioCall";
import axios from "axios";
import { Util } from "./../Util";
export class TwilioCallService {
  public static async getTwilioToken(identity: string): Promise<AppResponse<TwilioToken>> {
    const url = Util.apiAuthUrl(`twilioToken`);
    return await axios.post<void, AppResponse<TwilioToken>>(url, identity);
  }
}
