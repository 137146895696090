import { set } from "lodash";
import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { ColumnScore, GAD7AnxietyModel, score } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

interface SelectedCell {
    [key: number]: number | undefined;
}

const FormTwelve = (props: any) => {
    const { formId, assesmenData } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })

    const [edit, setEdit] = useState<boolean>(true);
    const [selectedCell, setSelectedCell] = useState<SelectedCell>({});

    useEffect(() => {
        if (formData && formData.gad7anxiety) {
            const selectedCells: SelectedCell = {};
            Object.entries(formData.gad7anxiety).forEach(([key, value]) => {
                const score = value as score; // Cast value to 'score' type
                if (score && Object.values(score).some(val => val === true)) {
                    // Find the index of the true value in 'score'
                    const selectedId = Object.values(score).findIndex(val => val === true) + 1;
                    selectedCells[getRowNumber(key)] = selectedId;
                }
            });
            setSelectedCell(selectedCells);
        }
    }, [formData]);
    
    const getRowNumber = (fieldName: string): number => {
        switch (fieldName) {
            case 'feelingNervous':
                return 1;
            case 'cantControllWorrying':
                return 2;
            case 'worryingMuch':
                return 3;
            case 'troubleRelaxing':
                return 4;
            case 'beingRestless':
                return 5;
            case 'easilyAnnoyed':
                return 6;
            case 'feelingAfraid':
                return 7;
            default:
                return 0;
        }
    };
    
    const getScorePropertyName = (id: number): keyof score => {
        switch (id) {
            case 1:
                return 'notAtAll';
            case 2:
                return 'severalDays';
            case 3:
                return 'moreThanHalfTheDays';
            case 4:
                return 'NearlyEveryDay';
            default:
                return 'notAtAll';
        }
    };

    const handleCellClick = (row: number, id: number) => {

        setSelectedCell(prevSelectedCell => ({ ...prevSelectedCell, [row]: id }));


        // setFormData(prevFormData => ({
        //     ...prevFormData,
        //     gad7anxiety: {
        //         ...prevFormData.gad7anxiety,
        //         [getScorePropertyName(id)]: {
        //             notAtAll: id === 1,
        //             severalDays: id === 2,
        //             moreThanHalfTheDays: id === 3,
        //             NearlyEveryDay: id === 4,
        //         }
        //     }
        // }));
    };

    const cellStyle = (row: number, id: number) => {
        return selectedCell[row] === id ? { backgroundColor: 'rgb(255, 236, 220)' } : {};
    };

    const columnTotals = Array.from({ length: 4 }, () => 0);

    Object.values(selectedCell).forEach((id) => {
        if (id !== undefined) {
            const columnIndex = id - 1;
            columnTotals[columnIndex] += columnIndex;
            // columnTotals[columnIndex]++;
        }
    });

    const setCellValue = (row: number, id: number) => {
        setFormData(prevFormData => {
            // if (!prevFormData || !prevFormData.gad7anxiety) return prevFormData;

            const fieldName = getRowFieldName(row);
            if (!fieldName) return prevFormData;

            const updatedScore: score = {
                notAtAll: false,
                severalDays: false,
                moreThanHalfTheDays: false,
                NearlyEveryDay: false,
            };

            switch (id) {
                case 1:
                    updatedScore.notAtAll = true;
                    break;
                case 2:
                    updatedScore.severalDays = true;
                    break;
                case 3:
                    updatedScore.moreThanHalfTheDays = true;
                    break;
                case 4:
                    updatedScore.NearlyEveryDay = true;
                    break;
                default:
                    break;
            }

            const updatedGAD7Anxiety: GAD7AnxietyModel = {
                ...prevFormData.gad7anxiety,
                [fieldName]: updatedScore
            };

            const columnTotalsCopy = [...columnTotals];
            const columnIndex = id - 1;
            columnTotalsCopy[columnIndex] += columnIndex;

            const updatedColumnCal: ColumnScore = {
                // column1: columnTotalsCopy[0],
                // column2: columnTotalsCopy[1],
                // column3: columnTotalsCopy[2],
                // column4: columnTotalsCopy[3],
                // total: columnTotalsCopy.reduce((acc, curr) => acc + curr, 0)
            };

            return {
                ...prevFormData,
                gad7anxiety: {
                    ...updatedGAD7Anxiety,
                    // columnCal: updatedColumnCal
                }
            };
        });
    };


    const getRowFieldName = (row: number) => {
        switch (row) {
            case 1: return 'feelingNervous';
            case 2: return 'cantControllWorrying';
            case 3: return 'worryingMuch';
            case 4: return 'troubleRelaxing';
            case 5: return 'beingRestless';
            case 6: return 'easilyAnnoyed';
            case 7: return 'feelingAfraid';
            default: return '';
        }
    };


    const getColumnFieldName = (id: number) => {
        switch (id) {
            case 1: return 'notAtAll';
            case 2: return 'severalDays';
            case 3: return 'moreThanHalfTheDays';
            case 4: return 'NearlyEveryDay';
            default: return '';
        }
    };

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 12,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                gad7anxiety: assesmenData.gad7anxiety
            }
            setFormData(updateAssesment);

        }
    }, [assesmenData])

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 12,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            gad7anxiety: formData.gad7anxiety
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
            <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>GAD-7 Anxiety</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "920px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "40%", textAlign: "left", paddingLeft: "0.5rem" }}>Over the last two weeks, how often have you  been bothered by the following problems?</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Not at all</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Several  days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>More  than half  the days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Nearly every day</td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-500" style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    1. Feeling nervous, anxious, or on edge
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 1); setCellValue(1, 1) }} style={cellStyle(1, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 2); setCellValue(1, 2) }} style={cellStyle(1, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 3); setCellValue(1, 3) }} style={cellStyle(1, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 4); setCellValue(1, 4) }} style={cellStyle(1, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    2. Not being able to stop or control worrying
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 1); setCellValue(2, 1) }} style={cellStyle(2, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 2); setCellValue(2, 2) }} style={cellStyle(2, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 3); setCellValue(2, 3) }} style={cellStyle(2, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 4); setCellValue(2, 4) }} style={cellStyle(2, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    3. Worrying too much about different things
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 1); setCellValue(3, 1) }} style={cellStyle(3, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 2); setCellValue(3, 2) }} style={cellStyle(3, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 3); setCellValue(3, 3) }} style={cellStyle(3, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 4); setCellValue(3, 4) }} style={cellStyle(3, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    4. Trouble relaxing
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 1); setCellValue(4, 1) }} style={cellStyle(4, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 2); setCellValue(4, 2) }} style={cellStyle(4, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 3); setCellValue(4, 3) }} style={cellStyle(4, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 4); setCellValue(4, 4) }} style={cellStyle(4, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    5. Being so restless that it is hard to sit still
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 1); setCellValue(5, 1) }} style={cellStyle(5, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 2); setCellValue(5, 2) }} style={cellStyle(5, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 3); setCellValue(5, 3) }} style={cellStyle(5, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 4); setCellValue(5, 4) }} style={cellStyle(5, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    6. Becoming easily annoyed or irritable
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 1); setCellValue(6, 1) }} style={cellStyle(6, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td >
                                            <td onClick={() => { handleCellClick(6, 2); setCellValue(6, 2) }} style={cellStyle(6, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 3); setCellValue(6, 3) }} style={cellStyle(6, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 4); setCellValue(6, 4) }} style={cellStyle(6, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    7. Feeling afraid, as if something awful  might happen
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 1); setCellValue(7, 1) }} style={cellStyle(7, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 2); setCellValue(7, 2) }} style={cellStyle(7, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 3); setCellValue(7, 3) }} style={cellStyle(7, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 4); setCellValue(7, 4) }} style={cellStyle(7, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row style={{ justifyContent: "end", margin: "0.5rem" }}>
                                <Col lg={9} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Column totals
                                        </div>

                                        {[1, 2, 3, 4].map((index) => (
                                            <React.Fragment key={index}>
                                                <div className="col" style={{ paddingInline: "inherit" }}>
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    id={`value${index}`}
                                                                    value={columnTotals[index - 1]}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                                {index !== 4 && (
                                                    <div className="col-auto fw-500" style={{ paddingInline: "inherit" }}>
                                                        +
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        <div className="col-auto fw-500">
                                            =
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "end", margin: "0.5rem" }}>
                                <Col md={3} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Total score :
                                        </div>
                                        <div className="col" style={{ paddingInline: "inherit" }}>
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="total"
                                                            value={columnTotals.reduce((acc, curr) => acc + (curr || 0), 0)}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Card className="match-card-body-second p-3">
                                <Row>
                                    <h6 className="fw-500 mb-2">If you checked any problems, how difficult have they made it for you to do your work, take care of  things at home, or get along with other people?</h6>
                                </Row>
                                <Row>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.notDifficult || false}
                                            onClick={() =>
                                                setFormData({
                                                    ...formData,
                                                    gad7anxiety: {
                                                        ...formData.gad7anxiety,
                                                        difficultLevel: { notDifficult: true }
                                                    }
                                                })
                                            }
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Not difficult at all</label>
                                    </Col>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.someWhatDifficult || false}
                                            onClick={() =>
                                                setFormData({
                                                    ...formData,
                                                    gad7anxiety: {
                                                        ...formData.gad7anxiety,
                                                        difficultLevel: {
                                                            someWhatDifficult: true
                                                        }
                                                    }
                                                })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Somewhat difficult</label>
                                    </Col>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.veryDifficult || false}
                                            onClick={() => setFormData({
                                                ...formData, gad7anxiety: {
                                                    ...formData.gad7anxiety, difficultLevel: {
                                                        veryDifficult: true
                                                    }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Very difficult</label>
                                    </Col>
                                    <Col xl={3}  className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel"
                                            checked={formData.gad7anxiety?.difficultLevel?.extremlyDifficult || false}
                                            onClick={() => setFormData({
                                                ...formData, gad7anxiety: {
                                                    ...formData.gad7anxiety, difficultLevel: {
                                                        extremlyDifficult: true
                                                    }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Extremely difficult</label>
                                    </Col>
                                </Row>
                            </Card>
                            <Row>
                                <h6 className="fw-500" style={{ fontSize: "smaller" }}>Source: Primary Care Evaluation of Mental Disorders Patient Health Questionnaire (PRIME-MD-PHQ). The PHQ was  developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke, and colleagues. For research information, contact Dr.  Spitzer at <a href="#" className="primary-link">ris8@columbia.edu.</a>  PRIME-MD® is a trademark of Pfizer Inc. Copyright© 1999 Pfizer Inc. All rights reserved.  Reproduced with permission</h6>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Scoring GAD-7 Anxiety Severity</h5>
                        </Card>
                        <Card className="match-card-body p-2">
                            <div style={{ margin: "0.2rem" }}>
                                <h6 style={{ fontWeight: "500", display: "inline" }}>This is calculated by assigning scores of 0, 1, 2, and 3 to the response categories, respectively,  </h6>
                                <span style={{ fontWeight: "600" }}>
                                    of “not at all,” “several days,” “more than half the days,” and “nearly every day.”
                                </span>
                                <h6 style={{ fontWeight: "500", display: "inline" }}> GAD-7 total score for the seven items ranges from 0 to 21.</h6>
                            </div>
                            <div style={{ margin: "0.2rem" }}>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>0–4 : minimal anxiety</h6>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>5-9 : mild anxiety</h6>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>10-14 : moderate anxiety</h6>
                                <h6 style={{ fontWeight: "500", margin: "1rem" }}>15-12 : severe anxiety</h6>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                           Next
                        </button>
                        {edit &&

                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 12 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default FormTwelve;