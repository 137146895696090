import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { CardElement, useStripe, useElements, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PremiumBankCard from 'src/pages/Subscription/PremiumBankCard';
import { PaymentService } from 'src/services/PaymentService';
import { environment } from 'src/environment/environment';
import { toast } from 'react-toastify';

// Lấy Stripe public key từ environment 
const stripePromise = loadStripe(environment.stripe_publishable_key);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontWeight: 600,
      fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#424770',
      },

      '::placeholder': {
        color: '#9BACC8',
      },

      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#fb1c50',
      ':focus': {
        color: '#FA755A',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  }
};

// PaymentDialogContent component sử dụng useStripe và useElements hooks
const PaymentDialogContent = ({ onClose, onSuccess, amount, error }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('');
  const [showAddCard, setShowAddCard] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  const loadPaymentMethods = async () => {
    try {
      const response = await PaymentService.getPaymentMethods();
      if (response.success) {
        setPaymentMethods(response?.data?.paymentMethods?.data);
        setDefaultPaymentMethodId(response?.data?.stripeCustomer?.invoice_settings?.default_payment_method);
      }
    } catch (err) {
      console.error('Error loading payment methods:', err);
    }
  };

  const handleAddCard = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsProcessingPayment(true);

    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (result.error) {
        toast.error(result.error.message || 'Failed to add card', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
        return;
      }

      const paymentMethod = {
        paymentMethod: result.paymentMethod.id,
      };

      const response = await PaymentService.attachNewPaymentMethod(paymentMethod);
      
      if (response.success) {
        setPaymentMethods([response.data, ...paymentMethods]);
        setDefaultPaymentMethodId(response.data.id);
        elements.getElement(CardElement)?.clear();
        setShowAddCard(false);
        toast.success('Card added successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
        onSuccess();
      } else {
        throw new Error('Failed to add card');
      }
    } catch (err) {
      console.error('Error adding card:', err);
      toast.error(err.message || 'Failed to add card. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
    } finally {
      setIsProcessingPayment(false);
    }
  };

  const changeDefaultPaymentMethod = async (methodId) => {
    try {
      const response = await PaymentService.setDefaultPaymentMethod({ paymentMethod: methodId });
      if (response.success) {
        setDefaultPaymentMethodId(methodId);
      }
    } catch (err) {
      console.error('Error changing default payment method:', err);
    }
  };

  return (
    <>
      <DialogTitle>
        Payment Required
      </DialogTitle>
      <DialogContent>
        {error && (
          <div style={{ color: 'red', marginBottom: '1rem' }}>
            {error}
          </div>
        )}
        <div style={{ marginBottom: '1rem' }}>
          This session requires a co-payment of ${amount}
        </div>
        
        {/* Existing Cards */}
        <div style={{ marginBottom: '2rem' }}>
          <PremiumBankCard 
            paymentData={paymentMethods}
            defaultPaymentMethodId={defaultPaymentMethodId}
            changeDefaultPaymentMethod={changeDefaultPaymentMethod}
          />
        </div>

        {/* Add New Card */}
        {!showAddCard ? (
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => setShowAddCard(true)}
          >
            Add New Card
          </Button>
        ) : (
          <div>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddCard}
              disabled={isProcessingPayment}
              style={{ marginTop: '1rem' }}
            >
              {isProcessingPayment ? 'Processing...' : 'Add Card'}
            </Button>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Skip
        </Button>
      </DialogActions>
    </>
  );
};

// Component chính bọc PaymentDialogContent trong Elements provider
const PaymentDialog = ({ open, onClose, onSuccess, amount, error }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Elements stripe={stripePromise}>
        <PaymentDialogContent 
          onClose={onClose}
          onSuccess={onSuccess}
          amount={amount}
          error={error}
        />
      </Elements>
    </Dialog>
  );
};

export default PaymentDialog; 