import React, { useState, useEffect } from 'react';
import { StepProps } from './types';
import Spinner from '../../../common/spinner/spinner';
import { environment } from '../../../environment/environment';

interface PreviewPdfProps extends StepProps {
  valueStep0?: string[];
  valueStep1?: string[];
  valueStep2?: string[];
  valueStep3?: string[];
}

const PreviewPdf: React.FC<PreviewPdfProps> = ({
  searchDocumentDownloadsByAdminSelected,
  onNext,
  onBack,
  valueStep0,
  valueStep1,
  valueStep2,
  valueStep3
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [mergedPdfFilename, setMergedPdfFilename] = useState<string>('');
  const totalPages = 5; // This would come from your actual PDF

  const generateCombinedPdfArray = () => {
    const result = [];

    // Add NEW-CLINICAL-ASSESSMENT-PDF item (1 item)
    if (valueStep1?.length) {
      result.push({
        url: valueStep1[0],
        pdf_type: 'NEW-CLINICAL-ASSESSMENT-PDF'
      });
    }

    // Add DIRECT_PDF items (2 items)
    if (valueStep2?.length) {
      valueStep2.slice(0, 2).forEach(url => {
        result.push({
          url,
          pdf_type: 'DIRECT_PDF'
        });
      });
    }

    // Add DIAGNOSIS-NOTE-SCREEN items (5 items)
    if (valueStep3?.length) {
      valueStep3.slice(0, 5).forEach(url => {
        result.push({
          url,
          pdf_type: 'DIAGNOSIS-NOTE-SCREEN'
        });
      });
    }

    return result;
  };

  const mergePdfs = async (pdfs: string[]) => {
    try {
      const response = await fetch(`${environment.pdf_service}/merge-file-pdf`, {
        method: 'POST',
        headers: {
          'X-API-Key': 'your-secret-api-key-123',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pdfs })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMergedPdfFilename(data.filename);
      return data.filename;
    } catch (error) {
      console.error('Error merging PDFs:', error);
      throw error;
    }
  };

  const downloadPdf = async (item: { url: string; pdf_type: string }) => {
    try {
      const response = await fetch(`${environment.pdf_service}/download`, {
        method: 'POST',
        headers: {
          'X-API-Key': 'your-secret-api-key-123',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.filename;
    } catch (error) {
      console.error('Error downloading PDF:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchPdfs = async () => {
      try {
        setIsLoading(true);
        const combinedArray = generateCombinedPdfArray();
        console.log('Combined PDF array:', combinedArray);

        const filenames = await Promise.all(
          combinedArray.map(item => downloadPdf(item))
        );

        console.log('Downloaded PDF filenames:', filenames);

        // Generate first page with patient info
        if (valueStep0?.length === 4) {
          const [name, gender, dateOfBirth, memberId] = valueStep0;
          const response = await fetch(`${environment.pdf_service}/gen-first-pdf-page`, {
            method: 'POST',
            headers: {
              'X-API-Key': 'your-secret-api-key-123',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name,
              gender,
              dateOfBirth,
              memberId
            })
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          filenames.unshift(data.filename); // Add to beginning of array
        }

        // Merge all PDFs into one file
        const mergedPdfFilename = await mergePdfs(filenames);
        console.log('Merged PDF filename:', mergedPdfFilename);

        setIsLoading(false);
      } catch (error) {
        console.error('Error in PDF processing:', error);
        setIsLoading(false);
      }
    };

    fetchPdfs();
  }, [valueStep1, valueStep2, valueStep3, valueStep0]);

  return (
    <div>
      <h6 className="mb-4">Preview PDF</h6>
      
      {/* <div className="text-center mb-3">
        <div className="btn-group">
          <button 
            className="btn btn-outline-primary" 
            onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
            disabled={currentPage === 1}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <button className="btn btn-outline-primary" disabled>
            Page {currentPage} of {totalPages}
          </button>
          <button 
            className="btn btn-outline-primary"
            onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div> */}

      <div className="pdf-preview-container" style={{ 
        height: '85vh', 
        margin: '-2rem -1.5rem 1rem -1.5rem',
        backgroundColor: '#f8f9fa',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {isLoading ? (
          <div className="text-center">
            <Spinner />
            <p className="mt-3 mb-0">Generating PDF preview...</p>
          </div>
        ) : (
          <div className="text-center" style={{ height: '100%', width: '100%' }}>
            <iframe
              src={`${environment.pdf_service}/view-pdf/${mergedPdfFilename}`}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                borderRadius: '0.5rem'
              }}
              title="PDF Preview"
            />
          </div>
        )}
      </div>

      <div className="alert alert-info" role="alert">
        <i className="fas fa-info-circle me-2"></i>
        Review the generated PDF before proceeding to send it via fax.
      </div>

      <div className="text-end mt-3">
        <button className="btn btn-secondary me-2" onClick={(e) => onBack?.()}>
          Back
        </button>
        <button className="btn btn-primary" onClick={(e) => onNext()}>
          Proceed to Send
        </button>
      </div>
    </div>
  );
};

export default PreviewPdf;
