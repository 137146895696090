import React from 'react';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import ScheduleAppointmentIcon from '@material-ui/icons/CalendarToday';

export default function ScheduleAppointmentButton({ classes, screenWidth, handleScheduleAppointment }) {

  return (
    <div>
      <Tooltip title="Schedule Appointment">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={screenWidth < 530 ? classes.toolbarButtons2 : classes.toolbarButtons}
          onClick={handleScheduleAppointment}
        >
          <ScheduleAppointmentIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
ScheduleAppointmentButton.propTypes = {
  classes: PropTypes.object.isRequired,
  screenWidth: PropTypes.any,
  handleScheduleAppointment: PropTypes.func.isRequired
};