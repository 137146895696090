import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Row, Col, CardTitle, OffcanvasHeader, Card } from "reactstrap";
import Close from "../../../assets/images/icons/u_times-circle.png";
import AddNotesIcon from "../../../assets/images/icons/fi_plus-circle.png";
import SignaturePad from "react-signature-canvas";
import { Input, Label } from "reactstrap";
import CloseIcon from "../../../assets/images/icons/close_plan.svg";
import EditIcon from "../../../assets/images/icons/edit.svg";
import EditIconGoal from "../../../assets/images/icons/edit_goal.svg";
import AddSignIcon from "../../../assets/images/icons/add_sign.svg";
import { CPTCode, DiagnosisNoteForUpdate, DiagnosisNoteFromResponse, NoteType } from "../../../models/DiagnosisNote";
import { Rating } from "react-simple-star-rating";
import Select from "react-select";
import options from "./Dataset/options";
import randomstring from "randomstring";
import UserContext from "src/context/UserContext";
import { VideoChatService } from "src/services/VideoChatService";
import { Role } from "src/models/Role";
import { toast } from "react-toastify";
import AiIcon from "../../../assets/images/icons/AI_animation_logo.gif";
import TypewriterParagraph from "./TypewriterParagraph";
import { TherapistService } from "src/services/TherapistService";
import SubjectiveTab from "src/pages/Notes/Tabs/SubjectiveTab";
import { RefNames } from "src/models/Notes";
import ObjectiveTab from "src/pages/Notes/Tabs/ObjectiveTab";
import AssessmentTab from "src/pages/Notes/Tabs/AssessmentTab";
import PlanTab from "src/pages/Notes/Tabs/PlanTab";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor"
import DOMPurify from "dompurify";
import Spinner from "./../../../common/spinner/spinner"


interface NoteAiGeneratedProps {
  closeModelFunction: (isCalled: boolean) => void;
  transcribeData: any;
  OpenAiModel: boolean;
  saveNote: (transcribeData: any, noteType:string) => void;
}

const AIGeneratedNote: React.FC<NoteAiGeneratedProps> = ({ transcribeData, OpenAiModel, closeModelFunction, saveNote }: NoteAiGeneratedProps) => {
  const [user, setUser] = useContext(UserContext);
  const [transcribeInProcess, setTranscribeInProcess] = useState(false);
  const mounted = useRef(false);
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState(OpenAiModel);
  const [showSignaturePopup, setShowSignaturePopup] = useState(false);
  const [signature, setSignature] = useState<any>();
  const [editsignature, seteditSignature] = useState<boolean>(false);
  const [planGoalText, setPlanGoalText] = useState<string>("");
  const [goals, setGoals] = useState<string[]>([]);
  const [editGoalArrIndex, setEditGoalArrIndex] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editOpenSubjective, setEditOpenSubjective] = useState<boolean>(false);
  const [editOpenObjective, setEditOpenObjective] = useState<boolean>(false);
  const [editOpenAssement, setEditOpenAssement] = useState<boolean>(false);
  const [editOpenPlan, setEditOpenPlan] = useState<boolean>(false);
  const [editOpenDiagnosisNotes, setEditOpenDiagnosisNotes] = useState<boolean>(false);
  const [editOpenGoal, setEditOpenGoal] = useState<boolean>(false);
  const [primaryDiagnosisICD10Codes, setPrimaryDiagnosisICD10Codes] = useState<any[]>([]);
  const [primaryDiagnosisICD10CodesError, setprimaryDiagnosisICD10CodesError] = useState<boolean>(false);
  const [secondaryDiagnosisICD10Codes, setSecondaryDiagnosisICD10Codes] = useState<any[]>([]);
  const [cptCode, setCPTCode] = useState<string>("");
  const [cptCodeError, setCPTCodeError] = useState<boolean>(false);
  const [historyOfPresenseIllnessFileArray, setHistoryOfPresenseIllnessFileArray] = useState<any[]>([]);
  const [assessmentFileArray, setAssessmentFileArray] = useState<any[]>([]);
  const [mentalBehaviouralStatusFileArray, setMentalBehaviouralStatusFileArray] = useState<any[]>([]);
  const SUPPORTED_IMAGE_FORMATS_FOR_HOPI = ["pdf"];
  const SUPPORTED_VIDEO_FORMATS_FOR_ANY = ["pdf", "txt", "docx", "doc", "jpg", "png", "jpeg"];
  const SUPPORTED_VIDEO_FORMATS_FOR_ASSIGNMENT = ["pdf", "txt", "docx", "doc"];
  const [historyOfPresentIllnessAttachmentsFromResponse, setHistoryOfPresentIllnessAttachmentsFromResponse] = useState<any[]>([]);
  const [carePlanFileArray, setCarePlanFileArray] = useState<any[]>([]);
  const [assessmentAttachmentsFromResponse, setAssessmentAttachmentsFromResponse] = useState<any[]>([]);
  const [carePlanAttachmentsFromResponse, setCarePlanAttachmentsFromResponse] = useState<any[]>([]);
  const [mentalBehavioralStatusAttachmentsFromResponse, setMentalBehavioralStatusAttachmentsFromResponse] = useState<any[]>([]);
  const [historyOfPresentIllnessAttachmentsIdsForDelete, setHistoryOfPresentIllnessAttachmentsIdsForDelete] = useState<any[]>([]);
  const [mentalBehavioralStatusAttachmentsIdsForDelete, setMentalBehavioralStatusAttachmentsIdsForDelete] = useState<any[]>([]);
  const [assessmentAttachmentsIdsForDelete, setAssessmentAttachmentsIdsForDelete] = useState<any[]>([]);
  const [carePlanAttachmentsIdsForDelete, setCarePlanAttachmentsIdsForDelete] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isDataLoadingError, setIsDataLoadingError] = useState<boolean>(false);
  const [firstTimeUpdatedByTherapist, setFirstTimeUpdatedByTherapist] = useState<boolean>(false);
  const [previousNote, setPreviousNote] = useState<DiagnosisNoteFromResponse>({} as DiagnosisNoteFromResponse);
  const [editNoteClicked, setEditNoteClicked] = useState<boolean>(false);
  const [chiefComplaint, setChiefComplaint] = useState<string>("");
  const [chiefComplaintError, setChiefComplaintError] = useState<boolean>(false);
  const [historyOfPresenseIllness, setHistoryOfPresenseIllness] = useState<string>("");
  const [historyOfPresenseIllnessError, setHistoryOfPresenseIllnessError] = useState<boolean>(false);
  const [mentalBehaviouralStatus, setMentalBehaviouralStatus] = useState<string>("");
  const [mentalBehaviouralStatusError, setMentalBehaviouralStatusError] = useState<boolean>(false);
  const [assessments, setAssessments] = useState<string>("");
  const [assessmentsError, setAssessmentsError] = useState<boolean>(false);
  const [carePlan, setCarePlan] = useState<string>("");
  const [carePlanError, setCarePlanError] = useState<boolean>(false);
  const [intervention, setIntervention] = useState<string>("");
  const [procedureNotes, setProcedureNotes] = useState<string>("");
  const [modal_center, setModalCenter] = React.useState(false);
  const [addReview, setReview] = useState<any>({} as any);
  const [rating1, setRating1] = useState(0);
  const imageUrl = "https://mylavni.com/static/assets/img/logo-dark.png";
  const [cptCodeList] = useState([
    { cptCode: "-1", cptCodeDescription: "Select" },
    { cptCode: "90791", cptCodeDescription: "Psychiatric diagnostic evaluation" },
    { cptCode: "90792", cptCodeDescription: "Psychiatric diagnostic evaluation with medical services" },
    { cptCode: "90832", cptCodeDescription: "Psychotherapy, 16-37 minutes" },
    { cptCode: "90833", cptCodeDescription: "Psychotherapy, 16-37 minutes with E/M service, listed seperately" },
    { cptCode: "90834", cptCodeDescription: "Psychotherapy, 38-52 minutes" },
    { cptCode: "90836", cptCodeDescription: "Psychotherapy, 38-52 minutes with E/M service, listed seperately" },
    { cptCode: "90837", cptCodeDescription: "Psychotherapy, 53+ minutes" },
    { cptCode: "90838", cptCodeDescription: "Psychotherapy, 53+ minutes with E/M service, listed seperately" },
    { cptCode: "90839", cptCodeDescription: "Psychotherapy for crisis, 30-74 minutes" },
    { cptCode: "90840", cptCodeDescription: "Psychotherapy for crisis, each additional 30 minutes beyond initial 74min, upto two add-ons per 90839" },
    { cptCode: "90846", cptCodeDescription: "Family psytx w/o patient 50 minutes" },
    { cptCode: "90847", cptCodeDescription: "Family psytx w/patient 50 minutes" },
    { cptCode: "90853", cptCodeDescription: "Group psychotherapy" },
    { cptCode: "00001", cptCodeDescription: "Non Billable" },
  ]);
  const [updateModal, openUpdateModal] = useState(false)
  const [currentLoadingNumber, setCurrentLoadingNumber] = useState(0);
  const [currentStage, setCurrentStage] = useState<string>("subjective");
  const [activeVerticalTab, setactiveVerticalTab] = useState("1");
  const [element, setElement] = useState<string>("");
  const [cursorPosition, setCursorPosition] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [saveNoateDisabled, setSaveNoateDisabled] = useState(false)
  const [selectedType, setSelectedType] = useState(user?.aiNotesType);
  const [isInProgress, setIsInProgress] = useState(false);

  // useEffect(() => {
  //    updateNoteType('SOAP')
  // },[])

  // const updateNoteType = async (noteType:any) => {
  //   try {


  //     if(!selectedType || (selectedType != "SOAP" && selectedType != "PIE") && (noteType == 'PIE' || noteType == 'SOAP')) {

  //       const data ={
  //         noteType: selectedType
  //       }
  //       if(user?.aiNotesType) {
  //         return
  //       }


  //        const res = await TherapistService.updateAINoteType(data);

  //       if(res?.success){

  //        setUser({...user,aiNotesType:selectedType});

  //        toast.success("AI Note Type Updated successfully !", {
  //          position: toast.POSITION.BOTTOM_RIGHT,
  //          className: "foo-bar",
  //        });
  //       }else{

  //         toast.error("Something went wrong !", {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //           className: "foo-bar",
  //        });
  //      }
  //   }
  //   } catch (error) {

  //     return toast.error("Please select AI note type !", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });
  //   }
  // };

  const handleUpdateSubjectiveCurrentIndex = (index: any) => {
    if (chiefComplaint?.length == index) {
      setCurrentLoadingNumber(1);
    }
  };
  const handleUpdateObjectiveCurrentIndex = (index: any) => {
    if (mentalBehaviouralStatus?.length == index) {
      setCurrentLoadingNumber(2);
    }
  };
  const handleUpdateAssessmentCurrentIndex = (index: any) => {
    if (assessments?.length == index) {
      setCurrentLoadingNumber(3);
    }
  };
  const handleUpdatePlanCurrentIndex = (index: any) => {
    if (carePlan?.length == index) {
      setCurrentLoadingNumber(4);
    }
  };

  useEffect(() => {
    getDiagnosisNotById();
  }, []);



  const getDiagnosisNotById = () => {
    setIsDataLoading(true);
    setIsDataLoadingError(false);
    VideoChatService.getDiagnosisNoteDetails(transcribeData.diagnosisNoteId).then(res => {
      if (res.success) {
        if (res.data.updatedByTherapist && res.data.updatedByTherapist == true) {
          setFirstTimeUpdatedByTherapist(true);
        } else {
          setEditNoteClicked(true);
          setFirstTimeUpdatedByTherapist(false);
          if (user?.role == Role.THERAPIST) {
            toast.success("Previous Note Data Loaded", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        }

        setPreviousNote(res.data);

        setChiefComplaint(res.data.chiefComplaint);

        setHistoryOfPresenseIllness(res.data.historyOfPresentIllness);

        setMentalBehaviouralStatus(res.data.mentalBehavioralStatus);

        setAssessments(res.data.asssessments);

        setCarePlan(res.data.carePlan);

        setPrimaryDiagnosisICD10Codes(res.data.diagnosisICDcodes);
        setPrimaryDiagnosisICD10Codes;
        setSecondaryDiagnosisICD10Codes(res.data.secondaryDiagnosisICDcodes);

        setIntervention(res.data.intervention);

        setCPTCode(res.data.cptCode);

        setProcedureNotes(res.data.procedureNotes);

        if (user?.signature && user?.role == "THERAPIST" && res?.data?.therapistId?._id && user?._id && res?.data?.therapistId?._id == user?._id) {
          setSignature(user?.signature);
        } else if (res?.data?.signature) {
          setSignature(res?.data?.signature);
        }
        
        // if (user?.role == "THERAPIST" && user?.signature) {
        //   setSignature(user?.signature)
        // } else {
        //   setSignature(res.data.signature)
        // }

        setGoals(res.data.selectedGoals);

        setHistoryOfPresentIllnessAttachmentsFromResponse(res.data.historyOfPresentIllnessAttachments);

        setMentalBehavioralStatusAttachmentsFromResponse(res.data.mentalBehavioralStatusAttachments);

        setAssessmentAttachmentsFromResponse(res.data.assessmentAttachments);

        setCarePlanAttachmentsFromResponse(res.data.carePlanAttachments);

        setIsDataLoadingError(false);
        seteditSignature(false);
        setIsDataLoading(false);

        // generateRandomTexts()
        getOpenAIResponse();
      } else {
        setIsDataLoadingError(true);
        setIsDataLoading(false);
      }
    });
  };

  const historyOfPresenceRef = useRef<HTMLTextAreaElement>(null);
  const chiefComplaintRef = useRef<HTMLTextAreaElement>(null);
  const procedureNotesRef = useRef<HTMLTextAreaElement>(null);
  const carePlanRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    switch (cursorPosition) {
      case "1":
        setElement('chief_complaint')
        break;
      case "2":
        setElement('history_of_present_illness')
        break;
      case "3":
        setElement('procedure_notes')
        break;
      case "4":
        setElement('care_plan')
        break;
      default:
        break;
    }
  }, [cursorPosition]);

  const saveNewNote = async () => {
    setSaveNoateDisabled(true);

    if (!chiefComplaint) {
      toast.error("Subjective is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (!mentalBehaviouralStatus) {
      toast.error("Objective is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (!assessments) {
      toast.error("Assessments is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (!carePlan) {
      toast.error("Care Plan is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (!cptCode || cptCode?.length <= 0 || cptCode == "-1") {
      toast.error("CPT Code code is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (cptCode !== "00001" && (primaryDiagnosisICD10Codes?.length <= 0 || !primaryDiagnosisICD10Codes)) {
      toast.error("Primary Diagnosis ICD 10 codes are required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (cptCode !== "00001" && (!goals || goals?.length <= 0)) {
      toast.error("Goals are required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return;
    }

    if (!signature || signature?.length == undefined || signature?.length == 0) {
      toast.error("Signature is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setSaveNoateDisabled(false);

      return false;
    } else {
      const noteData: any = {
        _id: transcribeData.diagnosisNoteId,
        chiefComplaint: chiefComplaint,
        historyOfPresentIllness: historyOfPresenseIllness,
        historyOfPresentIllnessAttachments: historyOfPresenseIllnessFileArray,
        diagnosisICDcodes: primaryDiagnosisICD10Codes,
        secondaryDiagnosisICDcodes: secondaryDiagnosisICD10Codes,
        cptCode: cptCode,
        mentalBehavioralStatus: mentalBehaviouralStatus,
        mentalBehavioralStatusAttachments: mentalBehaviouralStatusFileArray,
        asssessments: assessments,
        assessmentAttachments: assessmentFileArray,
        procedureNotes: procedureNotes,
        carePlan: carePlan,
        signature: signature,
        carePlanAttachments: carePlanFileArray,
        selectedGoals: goals,
        historyOfPresentIllnessAttachmentsIdsForDelete: historyOfPresentIllnessAttachmentsIdsForDelete,
        mentalBehavioralStatusAttachmentsIdsForDelete: mentalBehavioralStatusAttachmentsIdsForDelete,
        assessmentAttachmentsIdsForDelete: assessmentAttachmentsIdsForDelete,
        carePlanAttachmentsIdsForDelete: carePlanAttachmentsIdsForDelete,
        intervention: intervention,
        noteType: NoteType.SOAP

      };

      saveSignTherapist(noteData.signature);

      const res = await VideoChatService.updateDiagnosisNote(noteData);

      if (res.success) {
        if (res.data.updatedByTherapist && res.data.updatedByTherapist == true) {
          setFirstTimeUpdatedByTherapist(true);
        } else {
          setEditNoteClicked(true);
          setFirstTimeUpdatedByTherapist(false);
        }

        setIsDataLoading(true);
        setEditNoteClicked(false);
        setPreviousNote(res.data);
        setHistoryOfPresenseIllnessFileArray([]);
        setMentalBehaviouralStatusFileArray([]);
        setAssessmentFileArray([]);
        setCarePlanFileArray([]);
        setHistoryOfPresentIllnessAttachmentsIdsForDelete([]);
        setMentalBehavioralStatusAttachmentsIdsForDelete([]);
        setAssessmentAttachmentsIdsForDelete([]);
        setCarePlanAttachmentsIdsForDelete([]);

        setChiefComplaint(res.data.chiefComplaint);

        setHistoryOfPresenseIllness(res.data.historyOfPresentIllness);

        setMentalBehaviouralStatus(res.data.mentalBehavioralStatus);

        setAssessments(res.data.asssessments);

        setCarePlan(res.data.carePlan);

        setPrimaryDiagnosisICD10Codes(res.data.diagnosisICDcodes);

        setSecondaryDiagnosisICD10Codes(res.data.secondaryDiagnosisICDcodes);

        setIntervention(res.data.intervention);

        setProcedureNotes(res.data.procedureNotes);

        setSignature(res.data.signature);

        setGoals(res.data.selectedGoals);

        setHistoryOfPresentIllnessAttachmentsFromResponse(res.data.historyOfPresentIllnessAttachments);

        setMentalBehavioralStatusAttachmentsFromResponse(res.data.mentalBehavioralStatusAttachments);

        setAssessmentAttachmentsFromResponse(res.data.assessmentAttachments);

        setCarePlanAttachmentsFromResponse(res.data.carePlanAttachments);

        setIsDataLoading(false);

        toast.success("Diagnosis Report Is Ready", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        // updateNoteType(NoteType.SOAP);

        setShowAiGeneratedPopup(false);
        closeModelFunction(false);
        setSaveNoateDisabled(false);
        saveNote(transcribeData, NoteType.SOAP);


      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setSaveNoateDisabled(false);
      }
    }
  };

  const saveSignTherapist = async (signature: any) => {
    const data = {
      signature: signature
    }

    if (user?.role == Role.THERAPIST) {
      const res = await TherapistService.updateTherapistSignature(data);
      if (res.success) {
        setUser({ ...user, signature: res.data.signature });
      }
    }
  }

  const getOpenAIResponse = async () => {
    const meetingIdd = transcribeData.meetingId.meetingId;
    const noteType = 'SOAP'
    await VideoChatService.generateOpenAISoapPieResponse(meetingIdd, noteType).then((res:any) => {
      if (res?.success) {
        console.log(res.data)
        setChiefComplaint(res.data?.subjective);
        setMentalBehaviouralStatus(res.data?.objective);
        setAssessments(res.data?.assessment);
        setCarePlan(res.data?.plan);
        console.log(res?.data?.user)
        setUser({...user, aiNotesType: res?.data.user?.aiNotesType})

        TherapistService.getAiGenerateCount().then(res => {
          if (res.success) {
            const currentCount = res.data || 0
            const Testdata = {
              aiGenerateCount: currentCount + 1
            }

            TherapistService.updateAiGenerateCount(Testdata as any).then(res => {
              if (res?.data?.aiGenerateCount == 5 && res?.data?.aiReviewSubmitted !== true) {
                setModalCenter(true);
              } else if (res?.data?.aiGenerateCount == 10 && res?.data?.aiReviewSubmitted !== true) {
                setModalCenter(true);
              } else if (res?.data?.aiGenerateCount == 15 && res?.data?.aiReviewSubmitted !== true) {
                setModalCenter(true);
              } else if (res?.data?.aiGenerateCount == 20 && res?.data?.aiReviewSubmitted !== true) {
                setModalCenter(true);
              }
            });
          }
        });

        toast.success("SOAP is generated!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        if (res.errorCode == 404) {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("Unable to generate AI SOAP at this time!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }

      setIsLoading(false);
    });
  };

  function togCenterReview() {
    setModalCenter(!modal_center);
  }

  const AddReviewData = () => {
    const reviewData = { review: addReview.review, noOfStars: rating1 };
    TherapistService.addTherapistReview(reviewData).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setReview({ review: "" });
        setRating1(0);
        setModalCenter(false);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };


  // const generateRandomTexts = () => {
  //   const newRandomData = {
  //     subjective: generateRandomParagraph(15),
  //     objective: generateRandomParagraph(12),
  //     assessment: generateRandomParagraph(18),
  //     plan: generateRandomParagraph(16),
  //   };
  //   if (!chiefComplaint) {
  //     setChiefComplaint(newRandomData?.subjective);
  //   }
  //   if (!mentalBehaviouralStatus) {
  //     setMentalBehaviouralStatus(newRandomData?.objective);
  //   }
  //   if (!assessments) {
  //     setAssessments(newRandomData?.assessment);
  //   }
  //   if (!carePlan) {
  //     setCarePlan(newRandomData?.plan);
  //   }
  //   setIsLoading(false);
  // };

  const handleCPTCodeChange = (event: any) => {
    if (event.target.value == "-1" || event.target.value == undefined || event.target.value == null) {
      setCPTCodeError(true);
    } else {
      setCPTCodeError(false);
    }

    if (event.target.value !== "00001" && primaryDiagnosisICD10Codes.length <= 0) {
      setprimaryDiagnosisICD10CodesError(true);
    }
    else {
      setprimaryDiagnosisICD10CodesError(false);
    }

    setCPTCode(event.target.value);
  };

  const generateRandomParagraph = (wordCount: number) => {
    const words = [];

    for (let i = 0; i < wordCount; i++) {
      const word = randomstring.generate({ length: Math.floor(Math.random() * 10) + 3, charset: "alphabetic" });
      words.push(word);
    }

    const paragraph = words.join(" ");
    return paragraph;
  };

  const customStylesForICD10Codes = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any) => ({
      ...styles,
      borderColor: primaryDiagnosisICD10CodesError ? "red" : "#ced4da",
    }),
  };
  const handlePrimaryDiagnosisChange = (newValue: any) => {
    if (newValue.length == 0) {
      setprimaryDiagnosisICD10CodesError(true);
    } else {
      setprimaryDiagnosisICD10CodesError(false);
    }
    // const finalArray = [...newValue];
    // setPrimaryDiagnosisICD10Codes(finalArray);

    if (newValue.length > 0) {
      const finalArray = [...newValue];
      setPrimaryDiagnosisICD10Codes(finalArray);
    } else {
      setPrimaryDiagnosisICD10Codes([]);
    }
  };

  const handleSecondaryDiagnosisChange = (newValue: any) => {
    if (newValue.length > 0) {
      const finalArray = [...newValue];
      setSecondaryDiagnosisICD10Codes(finalArray);
    } else {
      setSecondaryDiagnosisICD10Codes([]);
    }
  };

  const signPad = useRef<SignaturePad>(null);


   //canvas
  function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const resizeCanvas = () => {
    if (signPad?.current) {
      const canvas = signPad.current.getCanvas();
      if (canvas) {
        const container = canvas.parentNode as HTMLElement;
        if (container) {
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          canvas.width = container.offsetWidth * ratio;
          canvas.height = container.offsetHeight * ratio;
          const context = canvas.getContext("2d");
          if (context) {
            context.scale(ratio, ratio);
          }
          signPad.current.clear();
        }
      }
    }
  };

  useEffect(() => {
    try {
        if (signPad?.current) {
          // Delay the initial resize to ensure SignaturePad is fully initialized
          setTimeout(() => {
           resizeCanvas();
          }, 0);
        }

        const debouncedResize = debounce(resizeCanvas, 200);
        window.addEventListener("resize", debouncedResize);

        // Using ResizeObserver to handle changes in container size
        const observer = new ResizeObserver(() => {
         resizeCanvas();
        });

        if (signPad.current) {
          const container = signPad.current.getCanvas().parentNode as HTMLElement;
          if (container) {
            observer.observe(container);
          }
        }

        return () => {
         window.removeEventListener("resize", debouncedResize);
         observer.disconnect();
        };
    } catch (error) {

    }

  }, []);

  function savesign() {
    if(signPad.current !== null) {
      setSignature( signPad.current.getTrimmedCanvas().toDataURL("image/png"));
      seteditSignature(false);
      setShowSignaturePopup(false);
    }

  }

  const addPlanGoalToArr = () => {
    if (planGoalText?.length > 0) {
      if (editGoalArrIndex) {
        goals[editGoalArrIndex] = planGoalText;
        setEditGoalArrIndex(null);
      } else {
        goals.push(planGoalText);
      }
      setPlanGoalText("");
    }
  };

  const editePlanByIndex = (index: number) => {
    const editPlanGoalText = goals[index];
    setPlanGoalText(editPlanGoalText);
    setEditGoalArrIndex(index);
  };

  const handlePresenseIllnessFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_IMAGE_FORMATS_FOR_HOPI, extension);
        if (supported) {
          const exists = historyOfPresenseIllnessFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          } else {
            const finalArray = [...historyOfPresenseIllnessFileArray, event.target.files[0]];
            setHistoryOfPresenseIllnessFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };
  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };
  const deletePresenseIllnessFile = (index: number) => {
    historyOfPresenseIllnessFileArray.splice(index, 1);

    const finalArray = [...historyOfPresenseIllnessFileArray];

    setHistoryOfPresenseIllnessFileArray(finalArray);
  };
  const handleMentalBehaviourFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_VIDEO_FORMATS_FOR_ANY, extension);
        if (supported) {
          const exists = mentalBehaviouralStatusFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...mentalBehaviouralStatusFileArray, event.target.files[0]];
            setMentalBehaviouralStatusFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };
  const deleteMentalBehaviourFile = (index: number) => {
    mentalBehaviouralStatusFileArray.splice(index, 1);

    const finalArray = [...mentalBehaviouralStatusFileArray];

    setMentalBehaviouralStatusFileArray(finalArray);
  };
  const handleAssessmentFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_VIDEO_FORMATS_FOR_ASSIGNMENT, extension);
        if (supported) {
          const exists = assessmentFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...assessmentFileArray, event.target.files[0]];
            setAssessmentFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };
  const deleteAssessmentFile = (index: number) => {
    assessmentFileArray.splice(index, 1);

    const finalArray = [...assessmentFileArray];

    setAssessmentFileArray(finalArray);
  };
  const handleCarePlanFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_VIDEO_FORMATS_FOR_ANY, extension);
        if (supported) {
          const exists = carePlanFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...carePlanFileArray, event.target.files[0]];
            setCarePlanFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };
  const deleteCarePlanFile = (index: number) => {
    carePlanFileArray.splice(index, 1);

    const finalArray = [...carePlanFileArray];

    setCarePlanFileArray(finalArray);
  };
  const chacksignature = (sign: any) => {
    if (Object.keys(sign)?.length == 0) {
      return false;
    } else {
      return true;
    }
  };
  const editorConfiguration = {
    // removePlugins: ["MediaEmbed"],
    toolbar: {
      items: ['heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'fontSize',
        'fontFamily',
        '|',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'imageUpload',
        'blockQuote',
        '|',
        'undo',
        'redo']
    },
  };
  const insuaranceCompanyName = () => {
    const insuaranceCName = previousNote?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany
    if (insuaranceCName) {
      return `<div className="text-wrap text-break">${insuaranceCName}</div>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }
  const insuaranceMemberId = () => {
    const insuaranceMemeberId = previousNote?.clientId?.insuranceId?.subscriber?.memberId
    if (insuaranceMemeberId) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuaranceMemeberId}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const insuarancePolicyNumber = () => {
    const insuarancePolicyNumber = previousNote?.clientId?.insuranceId?.subscriber?.policyNumber
    if (insuarancePolicyNumber) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuarancePolicyNumber}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const insuaranceLevelCode = () => {
    const insuaranceLevelCode = previousNote?.clientId?.insuranceId?.subscriber?.paymentResponsibilityLevelCode
    if (insuaranceLevelCode) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuaranceLevelCode}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const insuaranceInsuaranceSLastName = () => {
    const insuaranceSubscriberLName = previousNote?.clientId?.insuranceId?.subscriber?.lastName;
    if (insuaranceSubscriberLName) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuaranceSubscriberLName}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }
  const insuaranceInsuaranceSFirstName = () => {
    const insuaranceSubscriberFName = previousNote?.clientId?.insuranceId?.subscriber?.firstName;
    if (insuaranceSubscriberFName) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuaranceSubscriberFName}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }

  }

  const insuaranceInsuaranceSGender = () => {
    const insuaranceSubscriberGender = previousNote?.clientId?.insuranceId?.subscriber?.gender;
    if (insuaranceSubscriberGender) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuaranceSubscriberGender}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const insuaranceInsuaranceSDOB = () => {
    const insuaranceSubscriberDOB = previousNote?.clientId?.insuranceId?.subscriber?.dateOfBirth;
    if (insuaranceSubscriberDOB) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${insuaranceSubscriberDOB}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const insuaranceSubscriberAddress = () => {
    const insuaranceSAddress = previousNote?.clientId?.insuranceId?.subscriber?.address;
    if (insuaranceSAddress) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">
      ${previousNote.clientId.insuranceId.subscriber?.address?.address1 + ", "}
      ${previousNote.clientId.insuranceId.subscriber?.address?.city + ", "}
      ${previousNote.clientId.insuranceId.subscriber?.address?.state + ", "}
      ${previousNote.clientId.insuranceId.subscriber?.address?.postalCode + ", "}
    </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const insuaranceImage = () => {
    const insuaranceUrl = previousNote?.clientId?.insuranceId?.insuranceCardId?.url
    if (insuaranceUrl) {
      return `<div class="link-diagnosis-btn mt-3">
      <p class="hw-attach  text-wrap text-break mb-0" key={1} id="1">
        <i class="bx bx-paperclip"></i>
        <a href=${previousNote?.clientId?.insuranceId?.insuranceCardId?.url} class="file-link" target = "_blank" rel = "noopener noreferrer">
          ${previousNote?.clientId?.insuranceId?.insuranceCardId?.originalName
          ? previousNote?.clientId?.insuranceId?.insuranceCardId?.originalName
          : "File"}
        </a>
      </p>
      </div>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const clientName = () => {
    const firstName = previousNote?.clientId?.firstname
    const lastName = previousNote?.clientId?.lastname
    if (firstName && lastName) {

      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">${firstName} ${firstName}</p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const clientPatientId = () => {
    const clientPatientID = previousNote.patientID
    if (clientPatientID) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${clientPatientID} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const clientPatientAddress = () => {
    const streetAddress = previousNote.clientId.streetAddress
    const city = previousNote.clientId.city
    const state = previousNote.clientId.state
    if (streetAddress && city && state) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">  ${streetAddress + ","} ${city + ","} ${state} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }
  const clientPatientDOB = () => {
    const clientDOB = previousNote.clientId.dateOfBirth
    if (clientDOB) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${moment(clientDOB || null).format("YYYY-MM-DD")} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const clientPatientGender = () => {
    const clientPatientGender = previousNote.clientId.gender
    if (clientPatientGender) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${clientPatientGender} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const priviousNotePAcountNo = () => {
    const patientAcountNo = previousNote.patientAcountNo
    if (patientAcountNo) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${patientAcountNo} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const priviousNotePEncounterID = () => {
    const encounterID = previousNote.encounterID
    if (encounterID) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${encounterID} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const priviousNotePEncounterType = () => {
    const encounterType = previousNote.encounterType
    if (encounterType) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${encounterType} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const priviousNotePChiefComplaint = () => {
    const chiefComplaint = previousNote.chiefComplaint
    if (chiefComplaint) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${chiefComplaint} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const priviousNotePHistoryOfPresentIllness = () => {
    const historyOfPresentIllness = previousNote.historyOfPresentIllness
    if (historyOfPresentIllness) {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> ${historyOfPresentIllness} </p>`
    } else {
      return `<p class="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"></p>`
    }
  }

  const priviousNoteHistoryOfPresentIllnessAttachments = () => {
    const historyOfPresentIllnessAttachments = previousNote.historyOfPresentIllnessAttachments
    if (historyOfPresentIllnessAttachments && historyOfPresentIllnessAttachments?.length > 0) {
      return `
      <p class="mb-0 pt-3 pb-1 title-file">
      Attachments
    </p>
      <div class="">
        <div class="link-diagnosis-btn" >
          ${historyOfPresentIllnessAttachments &&
        historyOfPresentIllnessAttachments.length > 0 &&
        historyOfPresentIllnessAttachments.map((file, index) => (
          `<div key={${index}} class=" flex ">
               <p class="hw-attach  text-wrap text-break mb-0" key={${index}} id={${index.toString()}}>
                 <i class="bx bx-paperclip"></i>
                 <a href=${file.url} class="file-link" target = "_blank" rel = "noopener noreferrer">
                 ${file.originalName ? file.originalName : "File"}
                 </a>
                 </p>
             </div>`
        ))}
        </div>
      </div>`
    } else {
      return `<p class=""></p>`
    }
  }
  const priviousNoteMentalBehavioralStatusAttachments = () => {
    const mentalBehavioralStatusAttachments = previousNote.mentalBehavioralStatusAttachments
    if (mentalBehavioralStatusAttachments && mentalBehavioralStatusAttachments?.length > 0) {
      return `
      <p class="mb-0 pt-3 pb-1 title-file">
      Attachments
    </p>
      <div class="">
        <div class="link-diagnosis-btn" >
          ${mentalBehavioralStatusAttachments &&
        mentalBehavioralStatusAttachments.length > 0 &&
        mentalBehavioralStatusAttachments.map((file, index) => (
          `<div key={${index}} class=" flex ">
               <p class="hw-attach  text-wrap text-break mb-0" key={${index}} id={${index.toString()}}>
                 <i class="bx bx-paperclip"></i>
                 <a href=${file.url} class="file-link" target = "_blank" rel = "noopener noreferrer">
                 ${file.originalName ? file.originalName : "File"}
                 </a>
                 </p>
             </div>`
        ))}
        </div>
      </div>`
    } else {
      return `<p class=""></p>`
    }
  }

  const priviousNoteAssessmentAttachments = () => {
    const assessmentAttachments = previousNote.assessmentAttachments
    if (assessmentAttachments && assessmentAttachments?.length > 0) {
      return `
      <p
      class="mb-0 pt-3 pb-1 "
    >
      Attachments
    </p>
      <div class="">
        <div class="link-diagnosis-btn" >
          ${assessmentAttachments &&
        assessmentAttachments.length > 0 &&
        assessmentAttachments.map((file, index) => (
          `<div key={${index}} class=" flex ">
               <p class="hw-attach  text-wrap text-break mb-0" key={${index}} id={${index.toString()}}>
                 <i class="bx bx-paperclip"></i>
                 <a href=${file.url} class="file-link" target = "_blank" rel = "noopener noreferrer">
                 ${file.originalName ? file.originalName : "File"}
                 </a>
                 </p>
             </div>`
        ))}
        </div>
      </div>`
    } else {
      return `<p class=""></p>`
    }
  }

  const priviousNoteCarePlanAttachments = () => {
    const carePlanAttachments = previousNote.carePlanAttachments
    if (carePlanAttachments && carePlanAttachments?.length > 0) {
      return `
      <p
      class="mb-0 pt-3 pb-1 title-file"
    >
      Attachments
    </p>
      <div class="">
        <div class="link-diagnosis-btn" >
          ${carePlanAttachments &&
        carePlanAttachments.length > 0 &&
        carePlanAttachments.map((file, index) => (
          `<div key={${index}} class=" flex ">
               <p class="hw-attach  text-wrap text-break mb-0" key={${index}} id={${index.toString()}}>
                 <i class="bx bx-paperclip"></i>
                 <a href=${file.url} class="file-link" target = "_blank" rel = "noopener noreferrer">
                 ${file.originalName ? file.originalName : "File"}
                 </a>
                 </p>
             </div>`
        ))}
        </div>
      </div>`
    } else {
      return `<p class=""></p>`
    }
  }


  const previousNoteMentalBS = () => {
    const mentalBehavioralStatus = previousNote.mentalBehavioralStatus
    if (mentalBehavioralStatus) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${mentalBehavioralStatus}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"></p>`
    }
  }

  const previousNotecptCode = () => {
    const cptCode = previousNote.cptCode
    if (cptCode) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${previousNote.cptCode && cptCodeList.filter((code: CPTCode) => code.cptCode == previousNote.cptCode)[0].cptCodeDescription}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"></p>`
    }
  }

  const previousNoteDiagnosisICDcodes = () => {
    const diagnosisICDcodes = previousNote.diagnosisICDcodes;
    if (diagnosisICDcodes && diagnosisICDcodes.length > 0) {
      const diagnosisList = previousNote.diagnosisICDcodes.map((text) => text.label);
      const diagnosisHTML = diagnosisList.join("<br>");
      return `
        <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">
          ${diagnosisHTML}
        </p>`;
    } else {
      return `<p class=""></p>`;
    }
  };

  const previousNoteSDiagnosisICDcodes = () => {
    const secondaryDiagnosisICDcodes = previousNote.secondaryDiagnosisICDcodes;
    if (secondaryDiagnosisICDcodes && secondaryDiagnosisICDcodes.length > 0) {
      const diagnosisList = previousNote.secondaryDiagnosisICDcodes.map((text) => text.label);
      const diagnosisHTML = diagnosisList.join("<br>");
      return `
        <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">
          ${diagnosisHTML}
        </p>`;
    } else {
      return `<p class=""></p>`;
    }
  };


  const previousNoteAsssessments = () => {
    const asssessments = previousNote.asssessments
    if (asssessments) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${asssessments}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"></p>`
    }
  }

  const previousNoteProcedureNotes = () => {
    const procedureNotes = previousNote.procedureNotes
    if (procedureNotes) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${procedureNotes}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"></p>`
    }
  }

  const previousNoteCarePlan = () => {
    const carePlan = previousNote.carePlan
    if (carePlan) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${carePlan}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"></p>`
    }
  }

  const previousNoteSelectedGoals = () => {
    const selectedGoals = previousNote.selectedGoals
    if (selectedGoals && selectedGoals.length > 0) {
      return `<div class="flex plr-12">
      ${selectedGoals && selectedGoals.length > 0 && (
          `<p  class="mb-0  pt-1 pb-1 diagnosis-title4 ms-1 me-1">
          ${selectedGoals.length > 0 &&
          selectedGoals.map((text, index) => (
            `
              <span key={${index}}>
              ${text}
              <span>
              `
          ))}
            </p>
        `
        )}
    </div>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${previousNote.asssessments}</p>`
    }
  }


  const previousNoteTherapistName = () => {
    const firstName = previousNote.therapistId.firstname
    const lastname = previousNote.therapistId.lastname
    if (firstName && lastname) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">
      ${firstName} ${lastname}
    </p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{"-"}</p>`
    }
  }

  const previousNoteNPI1 = () => {
    const nPI1 = previousNote.therapistId.nPI1
    if (nPI1) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> ${nPI1} </p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>`
    }
  }

  const previousNoteNPI2 = () => {
    const nPI2 = previousNote.therapistId.nPI2
    if (nPI2) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> ${nPI2} </p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>`
    }
  }

  const previousNoteLicense = () => {
    const license = previousNote.therapistId.license
    if (license) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${license}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>`
    }
  }

  const previousNoteTaxonomyCode = () => {
    const taxonomyCode = previousNote.therapistId.taxonomyCode
    if (taxonomyCode) {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${taxonomyCode}</p>`
    } else {
      return `<p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>`
    }
  }

  const previousNoteDiagnosisAddress = () => {
    const streetAddress = previousNote.therapistId.streetAddress
    const city = previousNote.therapistId.city
    const state = previousNote.therapistId.state
    if (streetAddress && city && state) {
      return `<span>
      ${streetAddress} ${city} ${state}
    </span>`
    } else {
      return `<span class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">-</span>`
    }
  }

  const generatePdfContent = () => {
    // return `
    //     <html>
    //     <style>
    //     .min-w {
    //         max-width: 992px;
    //     }
    //     .custom-padding {
    //         padding-left: 100px;
    //         padding-right: 100px;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //       }
    //       .custom-padding-footer {
    //         padding-left: 100px;
    //         padding-right: 100px;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //       }

    //       @media (max-width: 600px) {
    //         .custom-padding {
    //             padding-left: 10px;
    //             padding-right: 10px;
    //         }
    //         .custom-padding-footer {
    //             padding-left: 10px;
    //             padding-right: 10px;
    //           }
    //       }
    //     </style>
    //     <body>
    //       <div style="position: absolute; top: 0; left: 0; right: 0;height: 15; background-color: #ff8000;"></div>
    //       <div class="custom-padding">
    //       <div style="padding: 40 ; min-height: 90vh;" class="min-w">
    //         <p style="text-align: center; font-weight: bold; font-size: 24;">Daily SOAP Notes</p>
    //         <div>
    //           <p style="text-align: left; margin-top: 8; margin-bottom: 8;">${transcribeData?.clientId?.streetAddress}</p>
    //           <p style="text-align: left; margin-top: 8; margin-bottom: 8;">${transcribeData?.clientId?.state}</p>
    //           <p style="text-align: left; margin-top: 8; margin-bottom: 8;">${transcribeData?.clientId?.primaryPhone}</p>
    //         </div>
    //         <div style="display: flex; flex-direction: row; justify-content: space-between;margin-top: 8;margin-bottom: 8">
    //           <p style="text-align: left; margin-top: 8; margin-bottom: 8"">${transcribeData?.clientId?.firstname} ${transcribeData?.clientId?.lastname
    //   } DOB:${moment(transcribeData?.clientId?.dateOfBirth).format("L")}</p>
    //           <p style="text-align: right; margin-top: 8; margin-bottom: 8"">${moment(transcribeData?.meetingStartedTime).format("dddd, MMMM D, YYYY")}</p>
    //           <p style="text-align: right; margin-top: 8; margin-bottom: 8""> </p>
    //         </div>
    //         <div>
    //           <p style="text-align: center; text-decoration: underline; font-size: 18;">SUBJECTIVE:</p>
    //           <p style=" margin-top: 8; margin-bottom: 8">${chiefComplaint}</p>
    //         </div>
    //         <div>
    //           <p style="text-align: center; text-decoration: underline; font-size: 18;">OBJECTIVE:</p>
    //           <p style=" margin-top: 8; margin-bottom: 8">${mentalBehaviouralStatus}</p>
    //         </div>
    //         <div>
    //           <p style="text-align: center; text-decoration: underline; font-size: 18;">ASSESSMENT:</p>
    //           <p style=" margin-top: 8; margin-bottom: 8">${assessments}</p>
    //         </div>
    //         <div>
    //           <p style="text-align: center; text-decoration: underline; font-size: 18;">PROCEDURE/PLAN:</p>
    //           <p style=" margin-top: 8; margin-bottom: 8">${carePlan}</p>
    //         </div>
    //         <div>
    //           <img src="${signature}" style="height: 40; width:auto; margin-top: 50" alt="Signature" />
    //           <p style="font-weight: 700">${transcribeData?.therapistId?.firstname} ${transcribeData?.therapistId?.lastname}</p>
    //         </div>
    //       </div>
    //       </div>
    //       <div class="custom-padding-footer" style="  position: absolute;  left: 0; right:
    //         0;height: 50; background-color: #343F52; ">
    //         <div class="min-w" style="display: flex; flex-direction: row; justify-content: space-between;align-items: center;width: 100%">
    //             <div>
    //             <img src="${imageUrl}" style="height: 40; width:auto" alt="Lavni Logo" />
    //             </div>
    //             <p style="color: #ff8000; font-size: 14;margin-top: 8; margin-bottom: 8"">Powered by Lavni</P>
    //         </div>
    //       </div>
    //     </body>

    //     </html>
    //     `;
    return `
    <html>

<head>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/boxicons/2.0.7/css/boxicons.min.css">
  <style>
    .min-w {
      max-width: 992px;
    }

    .custom-padding {
      padding-left: 100px;
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .custom-padding-footer {
      padding-left: 100px;
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 600px) {
      .custom-padding {
        padding-left: 10px;
        padding-right: 10px;
      }

      .custom-padding-footer {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .main-title {
      margin-bottom: 0px;
      padding: 10px;
    }

    .diagnosis-card {
      border: 2px solid #D9D9D95E;
      border-radius: 10px;
    }

    .title1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      margin-bottom: 0px;
    }

    .title2 {
      font-size: 17px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
    }

    .underline-d {
      height: 2px;
      background-color: #FD7A08;
      width: 100px;
    }

    .diagnosis-data-container {
      margin: 0px;
      border-radius: 5px;
      background: #D9D9D95E;
    }

    .diagnosis-title3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .diagnosis-title4 {
      font-size: 13px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .plr-12 {
      padding-left: 12px;
      padding-right: 12px;
    }

    .min-h-100Card {
      min-height: 100%;
    }

    .header-card {
      background-color: #FED9B8;
      border-radius: 8px 8px 0px 0px;
    }

    .diagnosis-title2 {
      font-size: 17px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }

    .diagnosis-title5 {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
    }
    .title-file {
      color: "#FD7F00",
      font-size: 14px;
    }
    .link-diagnosis-btn {
      display: inline-block;
      border: 1px solid #FD7A08;
      border-radius: 6px;
      padding: 0.5em 1em;
    }
    .mb-0 {
      marging-bottom: 0px;
    }
    .file-link {
      color: #FD7A08;
      text-decoration: none;
      background-color: transparent;
    }
    .file-link:hover {
      color: #FD7A08;
      text-decoration: none;
      background-color: transparent;
    }
  </style>

</head>

<body>

  <div class="p-4 ">
    <div class="row">
      <div class="col-lg-12">
        <div class="header-card">
          <p class="main-title">Diagnosis Report</p>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-6 min-h-100Card">
        <div class="diagnosis-card p-3">
          <p class="title1">Insurance Details</p>
          <div class="underline-d"></div>
          <p class="title2 mt-3">Receiver</p>
          <div class="row diagnosis-data-container">
            <div class="col-3 pt-1 pb-1 ms-2 me-2 diagnosis-title3">Organization</div>
            <div class="col-6 pt-1 pb-1 diagnosis-title4">
            ${insuaranceCompanyName()}
            </div>
          </div>
          <p class="title2 mt-3">Subscriber</p>

          <div class="flex row ">
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 ms-2 me-2 diagnosis-title3">Member Id</p>
                  </div>
                  <div class="col-6 pt-1 pb-1 ms-2 me-2">
                    ${insuaranceMemberId()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 ms-2 me-2 diagnosis-title3">Policy Number</p>
                  </div>
                  <div class="col-6 pt-1 pb-1 ms-2 me-2">
                    ${insuarancePolicyNumber()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex row diagnosis-data-container min-h-100 mt-3">
            <div class="col-6 pt-1 pb-1 diagnosis-title3">Payment Responsibility Level Code</div>
            ${insuaranceLevelCode()}
          </div>

          <div class="flex row mt-3">
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">First Name</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${insuaranceInsuaranceSFirstName()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Last Name</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${insuaranceInsuaranceSLastName()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row mt-3">
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Gender</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${insuaranceInsuaranceSGender()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">DOB</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${insuaranceInsuaranceSDOB()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row diagnosis-data-container min-h-100 mt-3">
            <div class="col-3 pt-1 pb-1 diagnosis-title3">Address</div>
            <div class="col-9 pt-1 pb-1 diagnosis-title4">
              ${insuaranceSubscriberAddress()}
            </div>
          </div>
          ${insuaranceImage()}
        </div>
      </div>
      <div class="col-lg-6 ">
        <div class="diagnosis-card min-h-100Card p-3 ">
          <p class="title1">Client Details</p>
          <div class="underline-d"></div>

          <div class="flex row mt-5">
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Name</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${clientName()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Patient Id</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${clientPatientId()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row diagnosis-data-container min-h-100 mt-3">
            <div class="col-3 pt-1 pb-1 diagnosis-title3">Address</div>
            <div class="col-9 pt-1 pb-1 ">
              ${clientPatientAddress()}
            </div>
          </div>

          <div class="flex row mt-3">
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">DOB</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${clientPatientDOB()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Gender</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${clientPatientGender()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row mt-3">
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Account Number</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                    ${priviousNotePAcountNo()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <div class="diagnosis-data-container min-h-100">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Encounter Id</p>
                  </div>
                  <div class="col-6 pt-1 pb-1">
                  ${priviousNotePEncounterID()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row diagnosis-data-container min-h-100 mt-3">
            <div class="col-3 pt-1 pb-1 diagnosis-title3">Encounter Type</div>
            <div class="col-9 pt-1 pb-1">
              ${priviousNotePEncounterType()}
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 ">
        <div class="diagnosis-card p-3">
          <p class="diagnosis-title2 mb-0 pb-2">Subjective /<span class="diagnosis-title5">What the client tells
              you</span></p>


          <div class="flex row ">
            <div class="col-6  ">
              <p class="diagnosis-title5 mb-0 pb-1">Chief Complaint :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${priviousNotePChiefComplaint()}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6  ">
              <p class="diagnosis-title5 mb-0 pb-1">History of Present Illness :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">${priviousNotePHistoryOfPresentIllness()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ${priviousNoteHistoryOfPresentIllnessAttachments()}

          <p class="diagnosis-title2 mb-0 pb-2 pt-4">Objective /<span class="diagnosis-title5">Observable or measurable
              data</span></p>


          <div class="flex row ">
            <div class="col-12  ">
              <p class="diagnosis-title5 mb-0 pb-1">Mental / Behavioral Status :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteMentalBS()}
                  </div>
                </div>
              </div>
            </div>
          </div>

         ${priviousNoteMentalBehavioralStatusAttachments()}

          <p class="diagnosis-title2 mb-0 pb-2 pt-4">Assessment </p>


          <div class="flex row ">
            <div class="col-4  ">
              <p class="diagnosis-title5 mb-0 pb-1">CTP Code :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNotecptCode()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4  ">
              <p class="diagnosis-title5 mb-0 pb-1">Diagnosis ICD 10 Codes :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteDiagnosisICDcodes()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4  ">
              <p class="diagnosis-title5 mb-0 pb-1">Secondary Diagnosis ICD 10 Codes : </p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteSDiagnosisICDcodes()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row pt-2">
            <div class="col-12  ">
              <p class="diagnosis-title5 mb-0 pb-1">Assessment :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteAsssessments()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          ${priviousNoteAssessmentAttachments()}

          <p class="diagnosis-title2 mb-0 pb-2 pt-4">Plan</p>


          <div class="flex row ">
            <div class="col-12  ">
              <p class="diagnosis-title5 mb-0 pb-1">Procedure / Intervention Notes :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteProcedureNotes()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row pt-2">
            <div class="col-4  ">
              <p class="diagnosis-title5 mb-0 pb-1">Care Plan :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteCarePlan()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8  ">
              <p class="diagnosis-title5 mb-0 pb-1">Goals :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-6">
                    ${previousNoteSelectedGoals()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          ${priviousNoteCarePlanAttachments()}

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-4 ">
        <div class="diagnosis-card p-3  min-h-100 ">

          <p class="diagnosis-title2 mb-0 pb-2 pt-4">Signature</p>
          <div class="flex row min-h-100">
          <img src="${signature}" style="height: auto; width:100%; " alt="Signature" />
          </div>

        </div>
      </div>
      <div class="col-lg-8 ">
        <div class="diagnosis-card min-h-100 p-3 ">
          <p class="diagnosis-title2 mb-0 pb-2 pt-4">Provider </p>


          <div class="flex row ">
            <div class="col-4 min-h-100 ">
              <p class="diagnosis-title5 mb-0 pb-1">Therapist Name :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteTherapistName()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 min-h-100 ">
              <p class="diagnosis-title5 mb-0 pb-1">NPI :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-6">
                  ${previousNoteNPI1()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 min-h-100 ">
              <p class="diagnosis-title5 mb-0 pb-1">NPI 2 :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-6">
                    ${previousNoteNPI2()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex row pt-2">
            <div class="col-4 min-h-100 ">
              <p class="diagnosis-title5 mb-0 pb-1">License :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-12">
                    ${previousNoteLicense()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 min-h-100 ">
              <p class="diagnosis-title5 mb-0 pb-1">Taxonomy Code :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-6">
                    ${previousNoteTaxonomyCode()}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 min-h-100 ">
              <p class="diagnosis-title5 mb-0 pb-1">Address :</p>
              <div class="diagnosis-data-container ">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 plr-12 pt-1 pb-1 diagnosis-title4 min-h-100">
                      ${previousNoteDiagnosisAddress()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</body>

</html>
    `
  };
  const handleOpenPdfInNewTab = () => {
    const newTab = window.open();
    if (newTab) {
      const pdfContent = generatePdfContent();
      newTab.document.write(pdfContent);
    }
    // setOpenPdfInNewTab(true);
  };

  function closeDiagnosis() {
    setEditOpenSubjective(false)
    setEditOpenObjective(false)
    setOpenEditModal(false)
    setEditOpenAssement(false)
    setEditOpenPlan(false)
    setCurrentStage("subjective")
    setactiveVerticalTab("1")
  }
  const toggleVertical = (tab: React.SetStateAction<string>) => {
    if (activeVerticalTab !== tab) {
      setactiveVerticalTab(tab);
    }
  };

  const handleChiefComplaint = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value == "" || event.target.value == undefined || (event.target.value == null && chiefComplaint == "")) {
      setChiefComplaintError(true);
    } else {
      setChiefComplaintError(false);
    }
    setChiefComplaint(event.target.value);
  };
  const handleChiefComplaintData = (event: any) => {
    if (!event) {
      setChiefComplaintError(true);
    } else {
      setChiefComplaintError(false);
    }
    setChiefComplaint(event);
  };

  const handleBehaviouralStatusData = (event: any) => {
    if (!event) {
      setMentalBehaviouralStatusError(true);
    } else {
      setMentalBehaviouralStatusError(false);
    }
    setMentalBehaviouralStatus(event);
  };

  const handleAssessmentsData = (event: any) => {
    if (!event) {
      setAssessmentsError(true);
    } else {
      setAssessmentsError(false);
    }
    setAssessments(event);
  };

  const handleCarePlanData = (event: any) => {
    if (!event) {
      setCarePlanError(true);
    } else {
      setCarePlanError(false);
    }
    setCarePlan(event);
  };

  const handleEditeSubjective = (state: boolean) => {
    setEditOpenSubjective(state)
    setOpenEditModal(true)
    setCurrentStage("subjective")
    setactiveVerticalTab("1")
  }

  const handleEditeObjective = (state: boolean) => {
    setEditOpenObjective(state)
    setOpenEditModal(true)
    setCurrentStage("objective")
    setactiveVerticalTab("2")
  }
  const handleEditeAssement = (state: boolean) => {
    setEditOpenAssement(state)
    setOpenEditModal(true)
    setCurrentStage("assessment")
    setactiveVerticalTab("3")
  }
  const handleEditePlan = (state: boolean) => {
    setEditOpenPlan(state)
    setOpenEditModal(true)
    setCurrentStage("plan")
    setactiveVerticalTab("4")
  }



  return (
    <>
      <Modal isOpen={showAiGeneratedPopup} centered className="modal-lg">
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "15px" }}>
          <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "15px" }}>
            <div className="image-container2">
              <div className="button-container btn-container-position-top">
                <button
                  type="button"
                  onClick={() => {
                    setShowAiGeneratedPopup(false);
                    closeModelFunction(false);
                  }}
                  className="bg-transparent-color"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={Close} alt="Close" className="" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <img src={AiIcon} className="ai-icon-size-popup" />
            <h5 className="font-size-15 ai-titile mb-3 mt-2">{isLoading ? "Generating your SOAP notes using JSI": "Generating your SOAP notes using JSI"}</h5>
          </div>
          <div className="">
            {isLoading && (
              // <>
              //   <div className="ai-border mb-3 ">
              //     <div className="ps-3 pe-5 pt-3 pb-3">
              //       <p className="text-start card-text placeholder-glow">
              //         <span className="placeholder col-2 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //       </p>
              //     </div>
              //   </div>
              //   <div className="ai-border mb-3 ">
              //     <div className="ps-3 pe-5 pt-3 pb-3">
              //       <p className="text-start card-text placeholder-glow">
              //         <span className="placeholder col-2 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //       </p>
              //     </div>
              //   </div>
              //   <div className="ai-border mb-3 ">
              //     <div className="ps-3 pe-5 pt-3 pb-3">
              //       <p className="text-start card-text placeholder-glow">
              //         <span className="placeholder col-2 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //       </p>
              //     </div>
              //   </div>
              //   <div className="ai-border mb-3 ">
              //     <div className="ps-3 pe-5 pt-3 pb-3">
              //       <p className="text-start card-text placeholder-glow">
              //         <span className="placeholder col-2 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //         <span className="placeholder col-12 ai-skelton1"></span>
              //       </p>
              //     </div>
              //   </div>
              // </>
              <div  className={`h-100`}  // Add class for custom styles
                  style={{
                   minHeight: isLoading ? "300px" : "auto",  // Ensure spinner container height is sufficient
                   display: "flex",
                   justifyContent: "center",
                 alignItems: "center",
            }}>

                <Spinner />

              </div>
            )}
            {!isLoading && (
              <>
                <div className="ai-border mb-3">
                  <div className="d-flex justify-content-end">
                    {editOpenSubjective ? (
                      <span className="me-3 mt-2 cursor-pointer " onClick={() => setEditOpenSubjective(false)}>
                        <img src={CloseIcon} />
                      </span>
                    ) : (
                      <span className=" me-3 mt-2 cursor-pointer " onClick={() => handleEditeSubjective(true)}>
                        <img src={EditIcon} />
                      </span>
                    )}
                  </div>
                  <div className="ps-3   pb-3">
                    <p className="text-start mb-ai-text pe-3">Subjective :</p>
                    {/* {editOpenSubjective ? (
                      <div className="pe-3">
                        <textarea className="form-control " rows={2} onChange={e => setChiefComplaint(e.target.value)} value={chiefComplaint}></textarea>
                        <div className="mb-3 ml-3 text-start">
                          <div className="mb-3 mt-3">
                            <Label
                              htmlFor="handle-presense-illness-fileChange"
                              style={{
                                cursor: "pointer",
                                fontSize: "14px",
                                color: "#FD7F00",
                                marginBottom: "0px",
                              }}
                            >
                              + Add Attachments
                            </Label>

                            <Input
                              type="file"
                              className="form-control"
                              id="handle-presense-illness-fileChange"
                              accept="application/pdf"
                              onChange={event => {
                                handlePresenseIllnessFileChange(event);
                              }}
                              style={{ display: "none" }}
                              required
                            />
                          </div>

                          {historyOfPresenseIllnessFileArray.length > 0 &&
                            historyOfPresenseIllnessFileArray.map((file, index) => (
                              <div key={index} className="mt-1 mb-2 flex ">
                                <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                  {file.name}
                                </div>
                                <div className="pull-left">
                                  <i
                                    className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                    onClick={() => {
                                      deletePresenseIllnessFile(index);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}

                          {historyOfPresentIllnessAttachmentsFromResponse.length > 0 && (
                            <div className="mx-3">
                              {historyOfPresentIllnessAttachmentsFromResponse.length > 0 &&
                                historyOfPresentIllnessAttachmentsFromResponse.map((file, index) => (
                                  <div key={index} className="mt-1 mb-2 flex ">
                                    <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                      <i className="bx bx-paperclip"></i>&nbsp;
                                      <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                    </p>
                                    <div className="pull-left">
                                      <i
                                        className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                        onClick={() => {
                                          const finalArray = [...historyOfPresentIllnessAttachmentsIdsForDelete, file._id];
                                          setHistoryOfPresentIllnessAttachmentsIdsForDelete(finalArray);

                                          historyOfPresentIllnessAttachmentsFromResponse.splice(index, 1);

                                          const finalArray2 = [...historyOfPresentIllnessAttachmentsFromResponse];

                                          setHistoryOfPresentIllnessAttachmentsFromResponse(finalArray2);
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : ( */}
                    <span className="typewriter text-start mb-ai-text pe-5 typewriter-container">
                      {/* {currentLoadingNumber == 0 ? (
                          <TypewriterParagraph text={chiefComplaint} updateCurrentIndex={handleUpdateSubjectiveCurrentIndex} />
                        ) : ( */}
                      <p className="text-start mb-ai-text pe-5"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chiefComplaint) }}
                      ></p>
                      {/* )} */}
                    </span>
                    {/* )} */}
                  </div>
                </div>
                <div className="ai-border mb-3">
                  <div className="d-flex justify-content-end">
                    {editOpenObjective ? (
                      <span className="me-3 mt-2 cursor-pointer " onClick={() => setEditOpenObjective(false)}>
                        <img src={CloseIcon} />
                      </span>
                    ) : (
                      <span className=" me-3 mt-2 cursor-pointer " onClick={() => handleEditeObjective(true)}>
                        <img src={EditIcon} />
                      </span>
                    )}
                  </div>
                  <div className="ps-3   pb-3">
                    <p className="text-start mb-ai-text pe-3">Objective :</p>
                    {/* {editOpenObjective ? (
                      <div className="pe-3">
                        <textarea
                          className="form-control "
                          rows={2}
                          onChange={e => setMentalBehaviouralStatus(e.target.value)}
                          value={mentalBehaviouralStatus}
                        ></textarea>
                        <div className="mb-3 ml-3 mt-3 text-start">
                          <Label
                            htmlFor="handle-mental-be-fileChange"
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              color: "#FD7F00",
                              marginBottom: "0px",
                            }}
                          >
                            + Add Attachments
                          </Label>

                          <Input
                            type="file"
                            className="form-control"
                            id="handle-mental-be-fileChange"
                            placeholder="Article File"
                            name="articleFile"
                            onChange={event => {
                              handleMentalBehaviourFileChange(event);
                            }}
                            style={{ display: "none" }}
                            required
                          />

                          {mentalBehaviouralStatusFileArray.length > 0 &&
                            mentalBehaviouralStatusFileArray.map((file, index) => (
                              <div key={index} className="mt-1 mb-2 flex ">
                                <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                  {file.name}
                                </div>
                                <div className="pull-left">
                                  <i
                                    className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                    onClick={() => {
                                      deleteMentalBehaviourFile(index);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}
                          {mentalBehavioralStatusAttachmentsFromResponse.length > 0 && (
                            <div className="mb-3 ml-3">
                              {mentalBehavioralStatusAttachmentsFromResponse.length > 0 &&
                                mentalBehavioralStatusAttachmentsFromResponse.map((file, index) => (
                                  <div key={index} className="mt-1 mb-2 flex ">
                                    <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                      <i className="bx bx-paperclip"></i>&nbsp;
                                      <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                    </p>
                                    <div className="pull-left">
                                      <i
                                        className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                        onClick={() => {
                                          const finalArray = [...mentalBehavioralStatusAttachmentsIdsForDelete, file._id];
                                          setMentalBehavioralStatusAttachmentsIdsForDelete(finalArray);
                                          mentalBehavioralStatusAttachmentsFromResponse.splice(index, 1);

                                          const finalArray2 = [...mentalBehavioralStatusAttachmentsFromResponse];

                                          setMentalBehavioralStatusAttachmentsFromResponse(finalArray2);
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : ( */}
                    <span className="typewriter text-start mb-ai-text pe-5 typewriter-container">
                      {/* {currentLoadingNumber == 1 ? (
                          <TypewriterParagraph text={mentalBehaviouralStatus} updateCurrentIndex={handleUpdateObjectiveCurrentIndex} />
                        ) : currentLoadingNumber > 1 ? ( */}
                      <p className="text-start mb-ai-text pe-5"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mentalBehaviouralStatus) }}
                      ></p>
                      {/* ) : null} */}
                    </span>
                    {/* )} */}
                  </div>
                </div>
                <div className="ai-border mb-3">
                  <div className="d-flex justify-content-end">
                    {editOpenAssement ? (
                      <span className="me-3 mt-2 cursor-pointer " onClick={() => setEditOpenAssement(false)}>
                        <img src={CloseIcon} />
                      </span>
                    ) : (
                      <span className=" me-3 mt-2 cursor-pointer " onClick={() => handleEditeAssement(true)}>
                        <img src={EditIcon} />
                      </span>
                    )}
                  </div>
                  <div className="ps-3   pb-3">
                    <p className="text-start mb-ai-text pe-3">Assessment :</p>
                    {/* {editOpenAssement ? (
                      <div className="pe-3">
                        <textarea className="form-control " rows={2} onChange={e => setAssessments(e.target.value)} value={assessments}></textarea>
                        <div className="mb-3 ml-3 mt-3 text-start">
                          <Label
                            htmlFor="handle-assessment-fileChange"
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              color: "#FD7F00",
                              marginBottom: "0px",
                            }}
                          >
                            + Add Attachments
                          </Label>

                          <Input
                            type="file"
                            className="form-control"
                            id="handle-assessment-fileChange"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            placeholder="Article File"
                            name="articleFile"
                            onChange={event => {
                              handleAssessmentFileChange(event);
                            }}
                            style={{ display: "none" }}
                            required
                          />

                          {assessmentFileArray.length > 0 &&
                            assessmentFileArray.map((file, index) => (
                              <div key={index} className="mt-1 mb-2 flex ">
                                <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                  {file.name}
                                </div>
                                <div className="pull-left">
                                  <i
                                    className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                    onClick={() => {
                                      deleteAssessmentFile(index);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}
                        </div>

                        {assessmentAttachmentsFromResponse.length > 0 && (
                          <div className="mb-3 ml-3">
                            {assessmentAttachmentsFromResponse.length > 0 &&
                              assessmentAttachmentsFromResponse.map((file, index) => (
                                <div key={index} className="mt-1 mb-2 flex ">
                                  <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                    <i className="bx bx-paperclip"></i>&nbsp;
                                    <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                  </p>
                                  <div className="pull-left">
                                    <i
                                      className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                      onClick={() => {
                                        const finalArray = [...assessmentAttachmentsIdsForDelete, file._id];
                                        setAssessmentAttachmentsIdsForDelete(finalArray);
                                        assessmentAttachmentsFromResponse.splice(index, 1);

                                        const finalArray2 = [...assessmentAttachmentsFromResponse];

                                        setAssessmentAttachmentsFromResponse(finalArray2);
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ) : ( */}
                    <span className="typewriter text-start mb-ai-text pe-5 typewriter-container">
                      {/* {currentLoadingNumber == 2 ? (
                          <TypewriterParagraph text={assessments} updateCurrentIndex={handleUpdateAssessmentCurrentIndex} />
                        ) : currentLoadingNumber > 2 ? ( */}
                      <p className="text-start mb-ai-text pe-5"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assessments) }}
                      ></p>
                      {/* ) : null} */}
                    </span>
                    {/* )} */}
                  </div>
                </div>
                <div className="ai-border mb-3">
                  <div className="d-flex justify-content-end">
                    {editOpenPlan ? (
                      <span className="me-3 mt-2 cursor-pointer " onClick={() => setEditOpenPlan(false)}>
                        <img src={CloseIcon} />
                      </span>
                    ) : (
                      <span className=" me-3 mt-2 cursor-pointer " onClick={() => handleEditePlan(true)}>
                        <img src={EditIcon} />
                      </span>
                    )}
                  </div>
                  <div className="ps-3   pb-3">
                    <p className="text-start mb-ai-text pe-3">Plan :</p>
                    {/* {editOpenPlan ? (
                      <div className="pe-3">
                        <textarea className="form-control " rows={2} onChange={e => setCarePlan(e.target.value)} value={carePlan}></textarea>
                        <div className="mb-3 mt-3 ml-3 text-start">
                          <Label
                            htmlFor="handle-care-plan-fileChange"
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              color: "#FD7F00",
                              marginBottom: "0px",
                            }}
                          >
                            + Add Attachments
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="handle-care-plan-fileChange"
                            placeholder="Article File"
                            name="articleFile"
                            onChange={event => {
                              handleCarePlanFileChange(event);
                            }}
                            style={{ display: "none" }}
                            required
                          />
                          {carePlanFileArray.length > 0 &&
                            carePlanFileArray.map((file, index) => (
                              <div key={index} className="mt-1 mb-2 flex ">
                                <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                  {file.name}
                                </div>
                                <div className="pull-left">
                                  <i
                                    className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                    onClick={() => {
                                      deleteCarePlanFile(index);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}
                          &nbsp;
                        </div>

                        {carePlanAttachmentsFromResponse.length > 0 && (
                          <div className="mb-3 ml-3">
                            {carePlanAttachmentsFromResponse.length > 0 &&
                              carePlanAttachmentsFromResponse.map((file, index) => (
                                <div key={index} className="mt-1 mb-2 flex ">
                                  <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                    <i className="bx bx-paperclip"></i>&nbsp;
                                    <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                  </p>
                                  <div className="pull-left">
                                    <i
                                      className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                      onClick={() => {
                                        const finalArray = [...carePlanAttachmentsIdsForDelete, file._id];
                                        setCarePlanAttachmentsIdsForDelete(finalArray);
                                        carePlanAttachmentsFromResponse.splice(index, 1);

                                        const finalArray2 = [...carePlanAttachmentsFromResponse];

                                        setCarePlanAttachmentsFromResponse(finalArray2);
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ) : ( */}
                    <span className="typewriter text-start mb-ai-text pe-5 typewriter-container">
                      {/* {currentLoadingNumber == 3 ? (
                          <TypewriterParagraph text={carePlan} updateCurrentIndex={handleUpdatePlanCurrentIndex} />
                        ) : currentLoadingNumber > 3 ? ( */}
                      <p className="text-start mb-ai-text pe-5"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(carePlan) }}
                      ></p>
                      {/* ) : null} */}
                    </span>
                    {/* )} */}
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <Label
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#FD7F00",
                      marginBottom: "0px",
                    }}
                    onClick={() => setEditOpenDiagnosisNotes(!editOpenDiagnosisNotes)}
                  >
                    <span>
                      {/* <img src={AddAttachmentIcon} className="me-1" /> */}
                      {editOpenDiagnosisNotes ? <i className="bx bx-caret-up mr-2 more-iconAi"></i> : <i className="bx bx-caret-down mr-2 more-iconAi"></i>}
                    </span>
                    Diagnosis notes
                  </Label>
                </div>
                {editOpenDiagnosisNotes && (
                  <div className="ai-border mb-3 text-start">
                    <div className="ps-3 pe-3 pt-3 pb-3">
                      <div className="mb-3 ">
                        <p className="text-muted font-size-15">
                          <strong>CPT Code:</strong>
                        </p>

                        <select
                          className={cptCodeError ? "form-control is-invalid" : "form-control"}
                          value={cptCode}
                          onChange={event => handleCPTCodeChange(event)}
                        >
                          {cptCodeList &&
                            cptCodeList.map((code: CPTCode, index: number) => {
                              return (
                                <option key={index} value={code.cptCode}>
                                  {code.cptCodeDescription}
                                </option>
                              );
                            })}
                        </select>

                        {cptCodeError && <div className="invalid-feedback">This can not be empty</div>}
                      </div>

                      <div className="mb-3">
                        <p className="text-muted font-size-15">
                          <strong>Primary Diagnosis ICD 10 codes:</strong>
                        </p>

                        <Select
                          closeMenuOnSelect={false}
                          defaultValue={primaryDiagnosisICD10Codes}
                          isMulti
                          options={options}
                          styles={customStylesForICD10Codes}
                          onChange={handlePrimaryDiagnosisChange}
                        />
                      </div>

                      {primaryDiagnosisICD10CodesError && (
                        <div style={{ width: "100%", marginTop: " 0.25rem", fontSize: "80%", color: " #f46a6a" }}>This can not be empty</div>
                      )}

                      <div className="mb-3">
                        <p className="text-muted font-size-15">
                          <strong>Secondary Diagnosis ICD 10 codes:</strong>
                        </p>

                        <Select
                          closeMenuOnSelect={false}
                          defaultValue={secondaryDiagnosisICD10Codes}
                          isMulti
                          options={options}
                          styles={customStylesForICD10Codes}
                          onChange={handleSecondaryDiagnosisChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex mb-3">
                  <Label
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#FD7F00",
                      marginBottom: "0px",
                    }}
                    onClick={() => setEditOpenGoal(!editOpenGoal)}
                  >
                    <span>{editOpenGoal ? <i className="bx bx-caret-up mr-2 more-iconAi"></i> : <i className="bx bx-caret-down mr-2 more-iconAi"></i>}</span>
                    Goals
                  </Label>
                </div>
                {editOpenGoal && (
                  <div className="ai-border mb-3">
                    <div className="ps-3 pt-3  pb-3">
                      <div className="pe-3">
                        {/* <div className="d-flex mb-1 mt-3">
                              <Label
                                htmlFor="handle-care-plan-fileChange"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  color: "#FD7F00",
                                  marginBottom: "0px",
                                }}
                              >
                                <span><img src={AddAttachmentIcon} className="me-1" /></span>Add Attachments
                              </Label>
                              <Input
                                type="file"
                                className="form-control"
                                id="handle-care-plan-fileChange"
                                placeholder="Article File"
                                name="articleFile"
                                onChange={event => {
                                  handleCarePlanFileChange(event);
                                }}
                                style={{ display: "none" }}
                                required
                              />
                              {carePlanFileArray.length > 0 &&
                                      carePlanFileArray.map((file, index) => (
                                        <div key={index} className="mt-1 mb-2 flex ">
                                          <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                            {file.name}
                                          </div>
                                          <div className="pull-left">
                                            <i
                                              className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                              onClick={() => {
                                                deleteCarePlanFile(index);
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      ))}
                                    &nbsp;
                            </div> */}
                        <p className="text-start mb-ai-text pe-3">Goals :</p>
                        <div className="d-flex">
                          <input type="text" className="form-control" onChange={e => setPlanGoalText(e.target.value)} value={planGoalText} />
                          <button className="btn btn-secondary ms-2 btn-black1 d-flex" onClick={() => addPlanGoalToArr()}>
                            <img src={AddNotesIcon} className="ai-icon-size me-2" />
                            Add
                          </button>
                        </div>
                        {goals?.length > 0 && (
                          <div className="goal-container-ai mt-1">
                            {goals?.map((goal: string, index: number) => (
                              <span key={index} className="d-flex justify-content-between p-1">
                                <p className="mb-ai-text">{goal}</p>
                                <span className="icon-btn-ai me-1" onClick={() => editePlanByIndex(index)}>
                                  <img src={EditIconGoal} />
                                </span>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {signature?.length > 0 ? (
                  <div className="">
                    <p className="text-start mb-ai-text ps-3 pe-3">Signature :</p>
                    <div className="d-flex">
                      <img style={{ width: "auto", height: "70px" }} src={signature} />
                    </div>
                  </div>
                ) : null}
              </>
            )}
           {!isLoading && (
              <div className="d-sm-flex justify-content-between">
              <a>
                <span
                  onClick={() => setShowSignaturePopup(true)}
                  className="btn btn-secondary btn-black1 convert-pending-note s-h-btn d-flex justify-content-center align-items-center"
                >
                  <img src={AddSignIcon} className="ai-icon-size me-2" />
                  Add Signature
                </span>
              </a>

              <a className="d-sm-flex">
                <button onClick={handleOpenPdfInNewTab} className="btn btn-primary convert-pending-note s-h-btn d-flex justify-content-center align-items-center">
                  <i className="bx bx-file mr-2 ai-icon-size"></i> View PDF
                </button>

                <button disabled={saveNoateDisabled} className="btn btn-primary convert-pending-note s-h-btn d-flex justify-content-center align-items-center" onClick={() => saveNewNote()}>
                  <img src={AddNotesIcon} className="ai-icon-size me-2" />
                  Save Note
                </button>
              </a>
            </div>
           )}

          </div>
        </div>
      </Modal>
      <Modal isOpen={showSignaturePopup} centered className="modal-md">
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "15px" }}>
          <div className="call-title flex flex-column  justify-content-center align-items-center" style={{ paddingBottom: "15px" }}>
            <div className="image-container2">
              <div className="button-container btn-container-position-top">
                <button
                  type="button"
                  onClick={() => {
                    setShowSignaturePopup(false);
                  }}
                  className="bg-transparent-color"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={Close} alt="Close" className="close-sm2" />
                </button>
              </div>
            </div>
          </div>
          <div className="ps-sm-4 pe-sm-4">
            <p className="fs-12-signature ai-titile text-start mb-ai-text pb-1 pt-2">Add Your Signature Here</p>
            <div className="sigCanvasGenerateAINotesNewDiv" >
            <SignaturePad canvasProps={{ className: "sigCanvasGenerateAINotesNew" }} backgroundColor="rgb(255, 255, 255)" ref={signPad} penColor="black"></SignaturePad>
            </div>
            <div className="d-flex justify-content-end">
              <a
                onClick={() => {
                  savesign();
                }}
                className="btn btn-primary mt-2"
              >
                Add
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal_center}
        toggle={() => {
          togCenterReview();
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">How was your AI session notes experience? </h5>
          <button
            type="button"
            onClick={() => {
              setModalCenter(false);
              localStorage.removeItem("review");
            }}
            className="review-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row className="mb-4">
            <Col xl="12" style={{ display: "flex" }}>
              <CardTitle> Share your experience with us</CardTitle>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl="12" style={{ display: "flex", alignItems: "center" }}>
              <CardTitle className="mr-2">Rate:</CardTitle>
              <Rating initialValue={rating1} onClick={(rate: any) => setRating1(rate)} size={30} transition fillColor={"#F2C94C"} />
            </Col>
          </Row>
          <div className="mb-2">
            <Input
              type="textarea"
              className="form-control"
              id="formrow-firstname-Input"
              style={{ height: "100%", minHeight: "100px" }}
              placeholder="Your review..."
              onChange={e => setReview({ ...addReview, review: e.target.value })}
              value={addReview.review}
              name="articleTitle"
              required
            />
          </div>

          <Row>
            <Col xl="3"></Col>

            <Col xl="6">
              <button type="button" className="btn btn-primary w-45 " onClick={() => AddReviewData()}>
                Save
              </button>
              <button
                type="button"
                className="btn  w-45 ml-3  card_shadow"
                onClick={() => {
                  setModalCenter(false);
                }}
              >
                Cancel
              </button>
            </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        isOpen={openEditModal}
        centered
        fullscreen
        scrollable
        style={{
          padding: "20px",
        }}
      >
        <div className="modal-content" style={{
          width: "100%",
        }}
        >

          <OffcanvasHeader
            toggle={closeDiagnosis}
          >
            Update {editOpenSubjective ? 'Subjective' : editOpenObjective ? 'Objective' : editOpenAssement ? 'Assement' : editOpenPlan ? 'Plan' : 'Subjective'}
          </OffcanvasHeader>

          <div>
            <div className="row pt20 pl10 chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  className="scrollbar-container ps--active-y"
                  style={{
                    height: "83vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                > {
                    editOpenSubjective && (
                      <Row>
                        <Col lg={6}>
                          <Card>
                            <div className="content-list-body">
                              <SubjectiveTab
                                stage={currentStage}
                                activeVerticalTab={activeVerticalTab}
                                toggleVertical={toggleVertical}
                                chiefComplaint={chiefComplaint}
                                setChiefComplaint={setChiefComplaint}
                                element={element}
                                historyOfPresenseIllness={historyOfPresenseIllness}
                                setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
                              />
                            </div>
                          </Card>
                        </Col>
                        <Col lg={6}>
                          <Card>
                            <div className="p-3">
                              <CardTitle className="font-size-20 mb-3 text-center">
                                SUBJECTIVE
                              </CardTitle>

                              <div className="note-tab">
                                <div className="mb-3">

                                  {/* <textarea
                                    className={!chiefComplaintError ? "form-control " : "form-control is-invalid"}
                                    style={{ resize: "none" }}
                                    rows={8}
                                    name={RefNames.CHIEF_COMPLAINT}
                                    ref={chiefComplaintRef}
                                    value={chiefComplaint}
                                    onClick={() => setCursorPosition("1")}
                                    onChange={event => {
                                      handleChiefComplaint(event);
                                    }}
                                  ></textarea> */}
                                  <div className="editor-area ck-content">
                                    <CKEditor
                                      editor={Editor}
                                      style={{
                                        maxHeight: "300px",
                                        border: chiefComplaint ? "1px solid black" : "2px solid red",
                                        backgroundColor: "red"
                                      }}
                                      onChange={(event: any, editor: { getData: () => any }) => {
                                        const data = editor.getData();
                                        handleChiefComplaintData(data);
                                      }}
                                      onFocus={(event: any, editor: any) => {
                                        setCursorPosition("1");
                                      }}
                                      data={`${chiefComplaint}`}
                                      config={editorConfiguration}
                                    />
                                  </div>

                                  {chiefComplaintError && <div className="invalid-feedback">This field is required.</div>}
                                </div>

                                <div className="mb-3 ml-3">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="handle-presense-illness-fileChange"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        color: "#FD7F00",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      + Add Attachments
                                    </Label>

                                    <Input
                                      type="file"
                                      className="form-control"
                                      id="handle-presense-illness-fileChange"
                                      accept="application/pdf"
                                      onChange={event => {
                                        handlePresenseIllnessFileChange(event);
                                      }}
                                      style={{ display: "none" }}
                                      required
                                    />
                                  </div>

                                  {historyOfPresenseIllnessFileArray.length > 0 &&
                                    historyOfPresenseIllnessFileArray.map((file, index) => (
                                      <div key={index} className="mt-1 mb-2 flex ">
                                        <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                          {file.name}
                                        </div>
                                        <div className="pull-left">
                                          <i
                                            className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                            onClick={() => {
                                              deletePresenseIllnessFile(index);
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    ))}

                                  {historyOfPresentIllnessAttachmentsFromResponse.length > 0 && (
                                    <div className="mx-3">
                                      {historyOfPresentIllnessAttachmentsFromResponse.length > 0 &&
                                        historyOfPresentIllnessAttachmentsFromResponse.map((file, index) => (
                                          <div key={index} className="mt-1 mb-2 flex ">
                                            <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                              <i className="bx bx-paperclip"></i>&nbsp;
                                              <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                            </p>
                                            <div className="pull-left">
                                              <i
                                                className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                                onClick={() => {
                                                  const finalArray = [...historyOfPresentIllnessAttachmentsIdsForDelete, file._id];
                                                  setHistoryOfPresentIllnessAttachmentsIdsForDelete(finalArray);

                                                  historyOfPresentIllnessAttachmentsFromResponse.splice(index, 1);

                                                  const finalArray2 = [...historyOfPresentIllnessAttachmentsFromResponse];

                                                  setHistoryOfPresentIllnessAttachmentsFromResponse(finalArray2);
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    editOpenObjective && (
                      <Row>
                        <Col lg={6}>
                          <Card>
                            <div className="content-list-body">
                              <ObjectiveTab
                                stage={currentStage}
                                activeVerticalTab={activeVerticalTab}
                                toggleVertical={toggleVertical}
                                mentalBehaviouralStatus={mentalBehaviouralStatus}
                                setMentalBehaviouralStatus={setMentalBehaviouralStatus}
                              />
                            </div>

                            {historyOfPresenseIllnessError && <div className="invalid-feedback">This can not be empty</div>}
                          </Card>
                        </Col>
                        <Col lg={6}>
                          <Card>
                            <div className="p-3">
                              <CardTitle className="font-size-20 mb-3 text-center">
                                OBJECTIVE
                              </CardTitle>

                              <div className="note-tab">
                                <div className="mb-3">

                                  {/* <textarea
                                    className={mentalBehaviouralStatusError ? "form-control is-invalid" : "form-control"}
                                    style={{ height: "20vh" }}
                                    value={mentalBehaviouralStatus}
                                    onChange={event => {
                                      if (event.target.value.trim() == "") {
                                        setMentalBehaviouralStatusError(true);
                                      } else {
                                        setMentalBehaviouralStatusError(false);
                                      }
                                      setMentalBehaviouralStatus(event.target.value);
                                    }}
                                  ></textarea> */}
                                  <div className="editor-area ck-content">
                                    <CKEditor
                                      editor={Editor}
                                      style={{
                                        maxHeight: "300px",
                                        border: mentalBehaviouralStatus ? "1px solid black" : "2px solid red",
                                        backgroundColor: "red"
                                      }}
                                      onChange={(event: any, editor: { getData: () => any }) => {
                                        const data = editor.getData();
                                        handleBehaviouralStatusData(data);
                                      }}
                                      onFocus={(event: any, editor: any) => {
                                        setCursorPosition("2");
                                      }}
                                      data={`${mentalBehaviouralStatus}`}
                                      config={editorConfiguration}
                                    />
                                  </div>
                                </div>

                                {mentalBehaviouralStatusError && <div className="invalid-feedback">This can not be empty</div>}

                                <div className="mb-3 ml-3">
                                  <Label
                                    htmlFor="handle-mental-be-fileChange"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "14px",
                                      color: "#FD7F00",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    + Add Attachments
                                  </Label>

                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="handle-mental-be-fileChange"
                                    placeholder="Article File"
                                    name="articleFile"
                                    onChange={event => {
                                      handleMentalBehaviourFileChange(event);
                                    }}
                                    style={{ display: "none" }}
                                    required
                                  />

                                  {mentalBehaviouralStatusFileArray.length > 0 &&
                                    mentalBehaviouralStatusFileArray.map((file, index) => (
                                      <div key={index} className="mt-1 mb-2 flex ">
                                        <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                          {file.name}
                                        </div>
                                        <div className="pull-left">
                                          <i
                                            className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                            onClick={() => {
                                              deleteMentalBehaviourFile(index);
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    ))}
                                </div>

                                {mentalBehavioralStatusAttachmentsFromResponse.length > 0 && (
                                  <div className="mb-3 ml-3">
                                    {mentalBehavioralStatusAttachmentsFromResponse.length > 0 &&
                                      mentalBehavioralStatusAttachmentsFromResponse.map((file, index) => (
                                        <div key={index} className="mt-1 mb-2 flex ">
                                          <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                            <i className="bx bx-paperclip"></i>&nbsp;
                                            <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                          </p>
                                          <div className="pull-left">
                                            <i
                                              className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                              onClick={() => {
                                                const finalArray = [...mentalBehavioralStatusAttachmentsIdsForDelete, file._id];
                                                setMentalBehavioralStatusAttachmentsIdsForDelete(finalArray);
                                                mentalBehavioralStatusAttachmentsFromResponse.splice(index, 1);

                                                const finalArray2 = [...mentalBehavioralStatusAttachmentsFromResponse];

                                                setMentalBehavioralStatusAttachmentsFromResponse(finalArray2);
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    editOpenAssement && (
                      <Row>
                        <Col lg={6}>
                          <Card>
                            <div className="content-list-body">
                              <AssessmentTab
                                stage={currentStage}
                                activeVerticalTab={activeVerticalTab}
                                toggleVertical={toggleVertical}
                                assessments={assessments}
                                setAssessments={setAssessments}
                              />
                            </div>
                          </Card>
                        </Col>
                        <Col lg={6}>
                          <Card>
                            <div className="p-3">
                              <CardTitle className="font-size-20 mb-3 text-center">ASSESSMENT</CardTitle>
                              <div className="note-tab">
                                <div className="mb-3">
                                  {/* <textarea
                                    className={assessmentsError ? "form-control is-invalid" : "form-control"}
                                    style={{ height: "20vh" }}
                                    value={assessments}
                                    onChange={event => {
                                      if (event.target.value.trim() == "") {
                                        setAssessmentsError(true);
                                      } else {
                                        setAssessmentsError(false);
                                      }
                                      setAssessments(event.target.value);
                                    }}
                                  ></textarea> */}
                                  <div className="editor-area ck-content">
                                    <CKEditor
                                      editor={Editor}
                                      style={{
                                        maxHeight: "300px",
                                        border: assessments ? "1px solid black" : "2px solid red",
                                        backgroundColor: "red"
                                      }}
                                      onChange={(event: any, editor: { getData: () => any }) => {
                                        const data = editor.getData();
                                        handleAssessmentsData(data);
                                      }}
                                      onFocus={(event: any, editor: any) => {
                                        setCursorPosition("2");
                                      }}
                                      data={`${assessments}`}
                                      config={editorConfiguration}
                                    />
                                  </div>
                                </div>

                                {assessmentsError && <div className="invalid-feedback">This can not be empty</div>}

                                <div className="mb-3 ml-3">
                                  <Label
                                    htmlFor="handle-assessment-fileChange"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "14px",
                                      color: "#FD7F00",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    + Add Attachments
                                  </Label>

                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="handle-assessment-fileChange"
                                    accept="application/pdf,application/msword,
                                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    placeholder="Article File"
                                    name="articleFile"
                                    onChange={event => {
                                      handleAssessmentFileChange(event);
                                    }}
                                    style={{ display: "none" }}
                                    required
                                  />

                                  {assessmentFileArray.length > 0 &&
                                    assessmentFileArray.map((file, index) => (
                                      <div key={index} className="mt-1 mb-2 flex ">
                                        <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                          {file.name}
                                        </div>
                                        <div className="pull-left">
                                          <i
                                            className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                            onClick={() => {
                                              deleteAssessmentFile(index);
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    ))}
                                </div>

                                {assessmentAttachmentsFromResponse.length > 0 && (
                                  <div className="mb-3 ml-3">
                                    {assessmentAttachmentsFromResponse.length > 0 &&
                                      assessmentAttachmentsFromResponse.map((file, index) => (
                                        <div key={index} className="mt-1 mb-2 flex ">
                                          <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                            <i className="bx bx-paperclip"></i>&nbsp;
                                            <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                          </p>
                                          <div className="pull-left">
                                            <i
                                              className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                              onClick={() => {
                                                const finalArray = [...assessmentAttachmentsIdsForDelete, file._id];
                                                setAssessmentAttachmentsIdsForDelete(finalArray);
                                                assessmentAttachmentsFromResponse.splice(index, 1);

                                                const finalArray2 = [...assessmentAttachmentsFromResponse];

                                                setAssessmentAttachmentsFromResponse(finalArray2);
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    editOpenPlan && (
                      <Row>
                        <Col lg={6}>
                          <Card>
                            <div className="content-list-body">
                              <PlanTab
                                stage={currentStage}
                                activeVerticalTab={activeVerticalTab}
                                toggleVertical={toggleVertical}
                                carePlan={carePlan}
                                setCarePlan={setCarePlan}
                                procedureNotes={procedureNotes}
                                element={element}
                                setProcedureNotes={setProcedureNotes}
                              />
                            </div>
                          </Card>
                        </Col>
                        <Col lg={6}>
                          <Card>
                            <div className="p-3">
                              <CardTitle className="font-size-20 mb-3 text-center">PLAN</CardTitle>
                              <div className="note-tab">
                                <div className="mb-3">
                                  {/* <textarea
                                    className={carePlanError ? "form-control is-invalid" : "form-control"}
                                    style={{ height: "20vh" }}
                                    value={carePlan}
                                    name={RefNames.CARE_PLAN}
                                    ref={carePlanRef}
                                    onClick={() => setCursorPosition("4")}
                                    onChange={event => {
                                      if (event.target.value.trim() == "") {
                                        setCarePlanError(true);
                                      } else {
                                        setCarePlanError(false);
                                      }
                                      setCarePlan(event.target.value);
                                    }}
                                  ></textarea> */}
                                  <div className="editor-area ck-content">
                                    <CKEditor
                                      editor={Editor}
                                      style={{
                                        maxHeight: "300px",
                                        border: assessments ? "1px solid black" : "2px solid red",
                                        backgroundColor: "red"
                                      }}
                                      onChange={(event: any, editor: { getData: () => any }) => {
                                        const data = editor.getData();
                                        handleCarePlanData(data);
                                      }}
                                      onFocus={(event: any, editor: any) => {
                                        setCursorPosition("4");
                                      }}
                                      data={`${carePlan}`}
                                      config={editorConfiguration}
                                    />
                                  </div>
                                </div>

                                {carePlanError && <div className="invalid-feedback">This can not be empty</div>}

                                <div className="mb-3 ml-3">
                                  <Label
                                    htmlFor="handle-care-plan-fileChange"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "14px",
                                      color: "#FD7F00",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    + Add Attachments
                                  </Label>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="handle-care-plan-fileChange"
                                    placeholder="Article File"
                                    name="articleFile"
                                    onChange={event => {
                                      handleCarePlanFileChange(event);
                                    }}
                                    style={{ display: "none" }}
                                    required
                                  />
                                  {carePlanFileArray.length > 0 &&
                                    carePlanFileArray.map((file, index) => (
                                      <div key={index} className="mt-1 mb-2 flex ">
                                        <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                          {file.name}
                                        </div>
                                        <div className="pull-left">
                                          <i
                                            className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                            onClick={() => {
                                              deleteCarePlanFile(index);
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    ))}
                                  &nbsp;
                                </div>

                                {carePlanAttachmentsFromResponse.length > 0 && (
                                  <div className="mb-3 ml-3">
                                    {carePlanAttachmentsFromResponse.length > 0 &&
                                      carePlanAttachmentsFromResponse.map((file, index) => (
                                        <div key={index} className="mt-1 mb-2 flex ">
                                          <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                            <i className="bx bx-paperclip"></i>&nbsp;
                                            <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                          </p>
                                          <div className="pull-left">
                                            <i
                                              className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                              onClick={() => {
                                                const finalArray = [...carePlanAttachmentsIdsForDelete, file._id];
                                                setCarePlanAttachmentsIdsForDelete(finalArray);
                                                carePlanAttachmentsFromResponse.splice(index, 1);

                                                const finalArray2 = [...carePlanAttachmentsFromResponse];

                                                setCarePlanAttachmentsFromResponse(finalArray2);
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    )
                  }
                  <div className="flex justify-content-end">
                    <div className="">
                      <a
                        onClick={closeDiagnosis}
                        className="btn btn-primary me-3"
                      >
                        Ok
                      </a>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AIGeneratedNote;
