import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { CommonService } from "src/services/CommonService";
import SignaturePad from "react-signature-canvas";
import moment from "moment";

const FormEleven = (props: any) => {
  const [formData, setFormData] = useState<ClinicalAssetment>({
    leisureActivity: [{}],
    houseHoldMember: [{}],
    pSAServiceHistory: [{}],
    historyofPsychiatricDiagnoses: [{}],
    currentModications: [{}],
    medicalHistory: { medicalHistoryTableSchema: [{}] },
  });
  // const [signature, setSignature] = useState<ClinicalAssetment>();
  const [clientSignature, setClientSignature] = useState<boolean>();
  const [editsignature, seteditSignature] = useState<boolean>(false);
  const { formId, clientId, assesmenData, customActiveTab } = props;
  const [clinicianSign, setClinicianSign] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(true);
  const [therapistSign, setTherapistSign] = useState<boolean>(true);

  const todayDate = moment.utc().startOf('day').toDate();

  useEffect(() => {
    if (assesmenData) {
      const updateAssesment: ClinicalAssetment = {
        signatureDetails: assesmenData.signatureDetails,
        therapistSignature: assesmenData.therapistSignature,
        clientSignature: assesmenData.clientSignature,
        leisureActivity: [],
        houseHoldMember: [],
        pSAServiceHistory: [],
        historyofPsychiatricDiagnoses: [],
        currentModications: [],
        medicalHistory: { medicalHistoryTableSchema: [] },
        dateOfAssesment: assesmenData?.dateOfAssesment ?? todayDate,
        updatedAt: assesmenData?.updatedAt ?? todayDate,
      };
      setFormData(updateAssesment);
    }
  }, [assesmenData]);

  useEffect(() => {
    const clientSign = formData.clientSignature;
    if (clientSign === undefined || !clientSign || clientSign.trim() === "") {
      setClientSignature(false);
    } else {
      setClientSignature(true);
    }

    const therapySign = formData.therapistSignature;
    if (therapySign === undefined || !therapySign || therapySign === "") {
      setClinicianSign(false);
    } else {
      setClinicianSign(true);
    }
  }, [assesmenData, formData]);

  const signPad = useRef<any>({
    minDistance: 0,
    throttle: 9,
    velocityFilterWeight: 0.7,
  });

  function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const resizeCanvas = () => {
    if (signPad?.current) {
      const canvas = signPad.current.getCanvas();
      if (canvas) {
        const container = canvas.parentNode as HTMLElement;
        if (container) {
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          canvas.width = container.offsetWidth * ratio;
          canvas.height = container.offsetHeight * ratio;
          const context = canvas.getContext("2d");
          if (context) {
            context.scale(ratio, ratio);
          }
          signPad.current.clear();
        }
      }
    }
  };

  useEffect(() => {
    try {
      if (signPad?.current) {
          setTimeout(() => {
            resizeCanvas();
        }, 0);
      }

      const debouncedResize = debounce(resizeCanvas, 200);
      window.addEventListener("resize", debouncedResize);

      // Using ResizeObserver to handle changes in container size
      const observer = new ResizeObserver(() => {
        resizeCanvas();
      });

      if(signPad?.current) {
        const container = signPad.current.getCanvas().parentNode as HTMLElement;
        if(container) {
          observer.observe(container);
        }
      }

      return () => {
       window.removeEventListener("resize", debouncedResize);
       observer.disconnect();
      };
    } catch (error) {

    }

  }, []);

  // const clientSignPad = useRef<any>({
  //     minDistance: 0,
  //     throttle: 9,
  //     velocityFilterWeight: 0.7,
  // });

  function clearsign(): void {
    if(signPad?.current) {
      signPad.current.clear();
      const signatureData = null;
    }

  }

  function saveSign() {

    if(signPad?.current) {
       const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
       setFormData({ ...formData, therapistSignature: signatureData });
       seteditSignature(false);
    }

  }

  function editSign() {
    setClinicianSign(false);
  }

  // function clearClientsign(): void {
  //     clientSignPad.current.clear();
  //     const signatureData = null;
  //     setFormData(prev => ({
  //         ...prev,
  //         signature: {
  //             ...prev.signature,
  //             clientAcknowledgingParticipation: {
  //                 ...prev.signature?.clientAcknowledgingParticipation,
  //                 clientSignature: signatureData
  //             }
  //         }
  //     }));
  // }

  // function saveClientsign() {
  //     setClientSignature(clientSignPad.current.getTrimmedCanvas().toDataURL("image/png"));
  //     const signatureData = clientSignPad.current.getTrimmedCanvas().toDataURL("image/png")
  //     setFormData({
  //         ...formData, signature: {
  //             ...formData.signature, clientAcknowledgingParticipation: {
  //                 ...formData.signature?.clientAcknowledgingParticipation, clientSignature: signatureData
  //             }
  //         }
  //     })
  //     seteditSignature(false);
  // }

  const updateAssesmentDetails = () => {
    try {
      const signatureDetailsForSend =  formData?.signatureDetails;

      if(signatureDetailsForSend?.comprehensiveCliniCalAssesment) {
        signatureDetailsForSend.comprehensiveCliniCalAssesment.date = signatureDetailsForSend?.comprehensiveCliniCalAssesment?.date ?? todayDate.toString();

      }

      const updateAssesment: ClinicalAssetment = {
        _id: formId,
        stepCount: 11,
        clientId: clientId,
        isSignature: clientSignature,
        leisureActivity: [],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [] },
        signatureDetails: signatureDetailsForSend,
        therapistSignature: formData.therapistSignature,
      };

      CommonService.updateClinicalAssesment(updateAssesment).then(res => {
        if (res.success) {
          toast.success("Assessment details are updated.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          props.nextStep();
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  // useEffect(() => {
  //     const GetEditvalue = localStorage.getItem("isEdit");
  //     if (GetEditvalue !== null) {
  //         const isEdit = GetEditvalue === "true";
  //         setEdit(isEdit);
  //     }
  // }, [props.currentStep]);


  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              updateAssesmentDetails();
            }}
          >
            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
          </button>
        </div>
        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header-secondary p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                Signature Page
              </h5>
            </Card>
            <Card className="match-card-body-second p-3">
              <Row>
                <h6 className="fw-600 mb-5">For Comprehensive Clinical Assessment</h6>
              </Row>
              <Row>
                <div className="row justify-content-between" style={{ textAlign: "center" }}>
                  <div className="col-lg-3 col-md-12 m-2">
                    <div className="d-flex flex-column justify-content-between">
                      <div className="sign">
                        <div
                         className='sigCanvasNewDiv'
                        >
                          {clinicianSign ? (
                            <img className="sigCanvasNew" src={formData.therapistSignature} />
                          ) : (
                            <SignaturePad
                              backgroundColor="rgb(245,242,241)"
                              ref={signPad}
                              penColor="black"
                              canvasProps={{ className: "sigCanvasNew" }}
                            ></SignaturePad>
                          )}
                        </div>
                      </div>
                      {!clinicianSign ? (
                        <div className="d-flex justify-content-between mt-3">
                          <div>
                            <a
                              onClick={() => {
                                clearsign();
                              }}
                              className="btn btn-primary btn-sm"
                            >
                              Clear
                            </a>
                          </div>
                          <div>
                            <a
                              onClick={() => {
                                saveSign();
                              }}
                              className="btn btn-primary btn-sm"
                            >
                              Save
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <a onClick={() => editSign()} className="btn btn-primary btn-sm mt-2">
                            Edit
                          </a>
                        </div>
                      )}
                    </div>
                    <h6 className="fw-500">Signature</h6>
                  </div>
                  <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid gray",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        textAlign: "center",
                      }}
                      type="text"
                      id="clientName"
                      readOnly={!edit}
                      value={formData.signatureDetails?.comprehensiveCliniCalAssesment?.nameOfClinician || ""}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          signatureDetails: {
                            ...formData.signatureDetails,
                            comprehensiveCliniCalAssesment: {
                              ...formData.signatureDetails?.comprehensiveCliniCalAssesment,
                              nameOfClinician: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <h6 className="fw-500">Name of Clinician (Print)</h6>
                  </div>
                  <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid gray",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        textAlign: "center",
                      }}
                      type="date"
                      id="date"
                      value={moment(formData?.updatedAt).format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      readOnly={!edit}
                    />
                    <h6 className="fw-500">Date</h6>
                  </div>
                </div>
              </Row>
            </Card>
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-body-second p-3">
              <Row>
                <h6 className="fw-600 mb-5">Signature of client acknowledging participation in assessment :</h6>
              </Row>
              <Row>
                <div className="row justify-content-between" style={{ textAlign: "center" }}>
                  <div className="col-lg-3 md-12 m-2">
                    <div className="d-flex flex-column justify-content-between">
                      <div className="sign">{clientSignature ? <img className="sigCanvas" src={formData.clientSignature} alt="clientSign" /> : <></>}</div>
                      {/* <div className="d-flex justify-content-between mt-3">
                                                <div>
                                                    <a
                                                        onClick={() => {
                                                            clearClientsign();
                                                        }}
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        Clear
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        onClick={() => {
                                                            saveClientsign();
                                                        }}
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        Save
                                                    </a>
                                                </div>
                                            </div> */}
                    </div>
                    <div className="col-auto mt-3">
                      <h6 className="fw-500">Signature of Client</h6>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid gray",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        textAlign: "center",
                      }}
                      type="date"
                      id="Clinicianphone"
                      max={moment().format("YYYY-MM-DD")}
                      // value={formData.signatureDetails?.clientAcknowledgingParticipation?.date}
                      // onChange={e => setFormData({
                      //     ...formData, signatureDetails: {
                      //         ...formData.signatureDetails, clientAcknowledgingParticipation: {
                      //             ...formData.signatureDetails?.clientAcknowledgingParticipation, date: e.target.value
                      //         }
                      //     }
                      // })}
                    />
                    <h6 className="fw-500">Date</h6>
                  </div>
                  <div className="col-sm-3 m-2"></div>
                </div>
              </Row>
            </Card>
          </div>
        </Row>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                props.previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={props.nextStep}
            >
              Next
            </button>
            {edit && (
              <button
                type="button"
                className="btn btn-primary mr-2 relative mt-3 mb-2"
                style={{
                  bottom: "0",
                  float: "right",
                }}
                onClick={() => {
                  updateAssesmentDetails();
                }}
              >
                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
              </button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 11 of 16
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FormEleven;
