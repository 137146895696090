import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Visa from "../../../assets/images/icons/visa.png";
import Master from "../../../assets/images/icons/master.png";

const Billing: React.FC = () => {
  const [isView2, setIsView2] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);

  const hideAndShow2 = () => {
    setIsView2(!isView2);
  };
  return (
    <Card className="overflow-auto">
      <CardBody>
        <Row onClick={isView2 ? hideAndShow2 : undefined} className={`${isView2 ? "cursor-p" : ""}`}>
          <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="d-flex gap-4 col-6">
            <Col className="d-flex justify-content-center align-items-center" md={12} lg={12} xl={12} xxl={12}>
              <h4 className="card-title " style={{ flex: 1 }}>
                Billing
              </h4>
            </Col>
          </Col>
          <Col sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex justify-content-end align-items-center gap-md-2 col-6">

            <div className="d-sm-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow2}>
              {isView2 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
            </div>
          </Col>
        </Row>

        <div className={!isView2 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Business Name</th>
                    <th scope="col">Address in the Card</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Card Type</th>
                    <th scope="col">Subscription</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>J. Haiyas (Hard Coded)</td>
                    <td>No 12A, Califonia</td>

                    <td>jhong@email.com</td>

                    <td><img src={Master} className="me-2" />Master</td>

                    <td><span className="cancel-2">Cancel</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>

          <Row>
            <div className="d-flex justify-content-start align-item-start">
              <button className="btn btn-warning create-client-custom2 text-center mt-4" >
                See More
              </button>
            </div>
          </Row>
        </div>

      </CardBody>
    </Card>
  );
};

export default Billing;
