import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardText,
  Modal,
  Container,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { ClaimStatus, UserCountResponseModel, UserStatus } from "../../models/User";
import { AdminService } from "../../services/AdminService";
import WelcomeComp from "./AdminDashboardComponents/WelcomeComp";
import { TransactionPaymemt } from "src/models/Transaction";
import TherapistTable from "./AdminStatisticsComponents/therapistTable";
import ClientTable from "./AdminStatisticsComponents/ClientTable";
import AdminWelcome from "./AdminStatisticsComponents/AdminWelcome";
import Swal from "sweetalert2";
import Billing from "./AdminStatisticsComponents/Billing";
import Calendar2 from "../../assets/images/icons/calendar-2.png";
import File1 from "../../assets/images/icons/file1.png";
import File2 from "../../assets/images/icons/file2.png";
import File3 from "../../assets/images/icons/file3.png";
import File4 from "../../assets/images/icons/file4.png";
import moment from "moment";
import File6 from "../../assets/images/icons/file6.png";
import File7 from "../../assets/images/icons/file7.png";
import File8 from "../../assets/images/icons/file8.png";
import File9 from "../../assets/images/icons/file9.png";
import File10 from "../../assets/images/icons/file10.png";
import pdf from "../../assets/images/pdf_file_icon.png";
import { toast } from "react-toastify";
import UserManagementTable from "./AdminStatisticsComponents/UserManagement";
import { ClaimERAHistoryData, ClaimHistoryData, TreatmentClaimHistoryData, TreatmentHistory } from "src/models/TreatmentHistory";
import InsuranceClaimModal from "../Popup/InsuranceClaimModal";
import { ClientService } from "src/services/ClientService";
import { Insurance } from "src/models/Insurance";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";
import { SelectedInsuranceCompanyTag } from "../../models/InsuranceCompany";
import DiagnosisNoteScreen from "../Chat/DiagnosisNote/DiagnosisNoteView";
import { set } from "lodash";
import Close from "../../assets/images/icons/u_times-circle.png";
import EraListViewComp from "./ERAView/EraListViewCom";

const AdminNewDashboard: React.FC = () => {
  const [subscriptionsCount, setsubscriptionsCount] = useState();
  const [therapistRevenueCount, setTherapistRevenueCount] = useState();
  const [periodType, setPeriodType] = useState("yearly");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(true);
  const [isLoadingClaims, setIsLoadingClaims] = useState(false);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [isView, setIsView] = useState<boolean>(true);
  const [isView2, setIsView2] = useState<boolean>(true);
  const [isView3, setIsView3] = useState<boolean>(true);
  const [isView4, setIsView4] = useState<boolean>(true);
  const [therapistsPayments, setTherapistsPayments] = useState<TransactionPaymemt[]>([]);
  const [claimData, setClaimsData] = useState<ClaimHistoryData[]>([]);
  const [claimERAData, setClaimsERAData] = useState<ClaimERAHistoryData[]>([]);
  const [claimStatusId, setClaimStatusId] = useState<any>()
  const [noteData, setNotesData] = useState<TreatmentClaimHistoryData[]>([]);
  const [showLaz, updateDiagnosisData] = useState(true);
  const [pastMissedAppointmentCount, setPastMissedAppointmentCount] = useState();
  const [scheduleAppointmentsCount, setscheduleAppointmentsCount] = useState();
  const [completedSessions, setcompletedSessionsCount] = useState();
  const [monthlyRecurringRevenueCount, setMonthlyRecurringRevenueCount] = useState(0);
  const [yearlyRecurringRevenueCount, setYearlyRecurringRevenueCount] = useState(0);
  const [lifeTimeSalesCount, setLifeTimeSalesCount] = useState(0);
  const [lifeTimeSales, setLifeTimeSales] = useState(0);
  const [seeMoreCount, setSeeMoreCount] = useState(10);
  const [seeMoreERACount, setSeeMoreERACount] = useState(10);
  const [seeMoreCountNotes, setSeeMoreCountNotes] = useState(10);
  const LIMIT = 10;
  const [offset, setOffset] = useState<number>(0);
  const [offsetNote, setOffsetNote] = useState<number>(0);
  const [noteId, setNoteId] = useState<string>("");
  const [showClaimModal, setShowClaimModal] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [insuranceDetails, setInsuranceDetails] = useState<Insurance>({} as Insurance);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isFilterOptionsNotes, setFilterOptionsNotes] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isDiagnosisNoteOpen, setIsDiagnosisNoteOpen] = useState(false);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [totalSpentDuration, setTotalSpentDuration] = useState(0);
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag[]>([]);
  const [modalCenter, setModalCenter] = useState(false);
  const [isOpenClaimModal, setIsOpenClaimModal] = useState(false);
  const [claimStatusType, setClaimStatusType] = useState({ type: '' });
  const [error, setError] = useState(false)
  const [eraOffset, setERAOffset] = useState(1);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [isSearchableString, setIsSearchableString] = useState({
    searchableClient: null,
    searchableTherapist: null,
    claimStatus: null,
    insuranceCompany: null,
  } as any);

  const [isClaimSearchableString, setIsClaimSearchableString] = useState("ALL");

  const [isSearchableNotesString, setIsSearchableNotesString] = useState({
    searchableClient: null,
    searchableTherapist: null,
  } as any);
  useEffect(() => {
    getAllSubscriptionCount();
    getAllTherapistRevenue();
    searchTherapists();
    allClaims();
    allNotes();
    getAllInsuranceCompanies();
  }, []);

  useEffect(() => {
    allClaimsERA(isClaimSearchableString);
  }, [isClaimSearchableString]);

  useEffect(() => {
    getNumberOfMissedAppoinments();
  }, [periodType]);

  const searchTherapists = () => {
    AdminService.getAllAccumulatedBalance().then(res => {
      if (res.success) {
        setTherapistsPayments(res.data);
      }
    });
  };

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const allNotes = () => {
    const data = {
      searchableClient: isSearchableNotesString.searchableClient,
      searchableTherapist: isSearchableNotesString.searchableTherapist,
      claimDate: selectedDate
    };
    setIsLoadingNotes(true);
    AdminService.getAllClinicalNotesByAdmin(data, LIMIT, 0).then(res => {
      if (res.success) {
        setNotesData(res.data);
        setIsLoadingNotes(false);
        setSeeMoreCountNotes(res.data.length);
        setOffsetNote(0);
      }
      setIsLoadingNotes(false);
    });
  };

  const clearNotes = () => {
    setIsSearchableNotesString({ ...isSearchableNotesString, searchableClient: "", searchableTherapist: "" });
    setSelectedDate("");
    const data = {
      searchableClient: "",
      searchableTherapist: "",
      claimDate: ""
    };
    setIsLoadingNotes(true);
    AdminService.getAllClinicalNotesByAdmin(data, LIMIT, 0).then(res => {
      if (res.success) {
        setNotesData(res.data);
        setIsLoadingNotes(false);
        setSeeMoreCountNotes(res.data.length);
        setOffsetNote(0);
      }
      setIsLoadingNotes(false);
    });
  }

  const seeMoreNotes = () => {
    setIsLoadingNotes(true);
    const updatedOffset = offsetNote + 10;
    setOffsetNote(updatedOffset);
    const data = {
      searchableClient: isSearchableNotesString.searchableClient,
      searchableTherapist: isSearchableNotesString.searchableTherapist,
      claimDate: selectedDate
    };
    AdminService.getAllClinicalNotesByAdmin(data, LIMIT, updatedOffset).then(res => {
      setIsLoadingNotes(true);

      if (res.success) {
        setNotesData(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMoreCountNotes(res.data.length);
        setIsLoadingNotes(false);
      }
    });
  };

  const allClaims = () => {
    const data = {
      searchableClient: isSearchableString.searchableClient,
      searchableTherapist: isSearchableString.searchableTherapist,
      claimStatus: isSearchableString.claimStatus,
      insuranceCompany: isSearchableString.insuranceCompany,
      claimDate: selectedDate
    };
    setIsLoadingClaims(true);
    AdminService.getAllClaimsByAdmin(data, LIMIT, 0).then(res => {
      if (res.success) {
        console.log("res.data", res.data)
        setClaimsData(res.data);
        setSeeMoreCount(res.data.length);
        setIsLoadingClaims(false);
        setOffset(0);
        setFilterOptions(false);
      } else {
        setFilterOptions(false);
        setIsLoadingClaims(false);
      }

    });
  };

  const allClaimsERA = (isClaimSearchableString: any) => {
    const data = {
      claimStatus: isClaimSearchableString,
    };
    setIsLoading(true);
    AdminService.getAllClaimsERAByAdmin(data, LIMIT, 1).then(res => {
      if (res.success) {
        setClaimsERAData(res.data);
        setIsLoading(false);
        setERAOffset(1);
        setSeeMoreERACount(res.data.length);
      } else {
        setIsLoading(false);
      }
    });
  };

  const filterERALIST = (isClaimSearchableString:any) => {
    setIsClaimSearchableString(isClaimSearchableString);
    const data = {
      claimStatus: isClaimSearchableString,
    };
    
    AdminService.getAllClaimsERAByAdmin(data, LIMIT, 1).then(res => {
      if (res.success) {
        setClaimsERAData(res.data);
        setIsLoading(false);
        setERAOffset(1);
        setSeeMoreERACount(res.data.length);
      } else {
        setIsLoading(false);
      }
    });
  };

  const seeMoreERA = () => {
    setIsLoading(true);
    const updatedOffset = eraOffset + 1;
    setERAOffset(updatedOffset);
    const data = {
      claimStatus: isClaimSearchableString,
    };
    AdminService.getAllClaimsERAByAdmin(data, LIMIT, updatedOffset).then(res => {
      setIsLoading(true);

      if (res.success) {
        setClaimsERAData(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMoreERACount(res.data.length);
        setIsLoading(false);
      }
    });
  };

  const clearClaims = () => {
    setIsSearchableString({ ...isSearchableString, searchableClient: "", searchableTherapist: "", insuranceCompany: "", claimStatus: "", });
    setSelectedDate("");
    const data = {
      searchableClient: "",
      searchableTherapist: "",
      claimStatus: "",
      insuranceCompany: "",
      claimDate: ""
    };
    setIsLoadingClaims(true);
    AdminService.getAllClaimsByAdmin(data, LIMIT, 0).then(res => {
      if (res.success) {
        setClaimsData(res.data);
        setIsLoadingClaims(false);
        setSeeMoreCount(res.data.length);
        setOffset(0);
      } else{
        setIsLoadingClaims(false);
      }


    });
  }

  const seeMore1 = () => {
    setIsLoadingClaims(true);
    const updatedOffset = offset + 10;
    setOffset(updatedOffset);
    const data = {
      searchableClient: isSearchableString.searchableClient,
      searchableTherapist: isSearchableString.searchableTherapist,
      claimStatus: isSearchableString.claimStatus,
      insuranceCompany: isSearchableString.insuranceCompany,
      claimDate: selectedDate
    };
    AdminService.getAllClaimsByAdmin(data, LIMIT, updatedOffset).then(res => {
      setIsLoadingClaims(true);

      if (res.success) {
        setClaimsData(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMoreCount(res.data.length);
        setIsLoadingClaims(false);
      }
    });
  };

  const getAllSubscriptionCount = () => {
    AdminService.getAllSubscriptionCounts().then(res => {
      if (res.success) setsubscriptionsCount(res.data);
    });
  };

  const getAllInsuranceCompanies = () => {
    InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.insuranceCompany };
        });
        setSearchInsuranceCompanyTag(tagList);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };

  const getAllTherapistRevenue = () => {
    AdminService.getAllTotalRevenue().then(res => {
      if (res.success) setTherapistRevenueCount(res.data);
    });
  };

  const getNumberOfMissedAppoinments = () => {
    let data;
    if (periodType == "yearly") {
      data = 365;
    } else if (periodType == "month") {
      data = 30;
    } else if (periodType == "week") {
      data = 7;
    }

    const dataValue: any = {
      date: data,
    };

    setIsLoadingCount(true);

    AdminService.getAllAdminStatistics(dataValue).then(res => {
      if (res.success) {
        setIsLoadingCount(false);
        setPastMissedAppointmentCount(res.data.pastMissedAppointmentsCount);
        setscheduleAppointmentsCount(res.data.scheduledAppointmentsCount);
        setcompletedSessionsCount(res.data.completedSessions);
        setMonthlyRecurringRevenueCount(res.data.monthlyRecurringRevenueCount);
        setYearlyRecurringRevenueCount(res.data.yearlyRecurringRevenueCount);
        setLifeTimeSalesCount(res.data.lifeTimeSales);
        setLifeTimeSales(res.data.averageCustomerLifetimeValue);
      }
    });
  };

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
  };

  const hideAndShow = () => {
    setIsView(!isView);
    setIsView2(true);
  };

  const hideAndShow2 = () => {
    setIsView2(!isView2);
    setIsView(true);
  };
  const hideAndShow3 = () => {
    setIsView3(!isView3);
    // setIsView(true);
  };

  const hideAndShow4 = () => {
    setIsView4(!isView4);
    // setIsView(true);
  };
  const toggleClaimModal = () => {
    setShowClaimModal(true);
  };


  const toggleClaimModalFalse = (noteId: any, claimStatus: any, errorMsg: any, coAmount: any, coPaymentStatus: any, link: any) => {
    setShowClaimModal(false);
    const claimKey = claimData.findIndex((claim: ClaimHistoryData) => claim?.diagnosisNoteId?._id == noteId);
    const updatedClaim = {
      ...claimData[claimKey],
      claimStatus: claimStatus,
      errorMsg: errorMsg,
      meetingId: {
        ...claimData[claimKey]?.meetingId,
        copayment: {
          ...claimData[claimKey]?.meetingId?.copayment,
          amount: coAmount,
          status: coPaymentStatus,
          details: link
        },
      },
    };
    claimData[claimKey] = updatedClaim;
    setClaimsData(claimData);
  };

  const displayClaimModal = (noteId: any, clientId: any) => {
    setNoteId(noteId);
    viewInsuranceByUserId(clientId);
    toggleClaimModal();
  };

  const viewInsuranceByUserId = (userId: string) => {
    setIsLoaded(false);
    ClientService.ViewInsuranceByClientId(userId).then(res => {
      if (res.success) {
        setInsuranceDetails(res.data);
        setIsLoaded(true);
      } else {
        setInsuranceDetails({} as Insurance);
        setIsLoaded(true);
      }
    });
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  };

  const toggleFilterNotes = () => {
    setFilterOptionsNotes(!isFilterOptionsNotes);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  function togCenter() {
    setModalCenter(!modalCenter);
  }

  const displayDiagnosisNotesModal = (noteId: any) => {
    setModalCenter(!modalCenter);
    setIsDiagnosisNoteOpen(true);
    setDiagnosisId(noteId);
    setTotalSpentDuration(0);
    getTotalSpentDuration(noteId)
    // Need to get spent duration if sessions are merged - To Do - Required
  }
  const getTotalSpentDuration = (noteId: any) => {
    const dataId = {
      noteId: noteId
    }
    AdminService.getTotalSpentDuration(dataId).then(res => {
      if (res.success) {
        if (res.data > 0) {
          setTotalSpentDuration(res.data)
        }
      }
    });
  }
  const sendReminderSms = (noteId: any) => {
    if (noteId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to send reminder?",
        showCancelButton: true,
        confirmButtonText: "Send",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const dataId = {
            noteId: noteId
          }
          AdminService.sendReminderNote(dataId).then(res => {
            if (res.success) {
              toast.success("Reminder is sended.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: res.error ? res.error : "Something went wrong. Please try again later.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  }
  const runClaim = (noteId: any, insuaranceId: any, serviceId: any) => {

    if (noteId && insuaranceId && serviceId) {
      const data = {
        insuranceId: insuaranceId,
        serviceId: serviceId,
        noteId: noteId,
      }
      AdminService.sendClaim(data).then(res => {
        if (res.success) {
          toast.success("Successfully sent!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  }

  const updateClaimModal = (noteId: any) => {
    setClaimStatusId(noteId)
    setIsOpenClaimModal(true)
  }


  const toggleClaimStatusModal = () => {
    setIsOpenClaimModal(!isOpenClaimModal)
  }


  const updateTherapistPayaRate = () => {
    setError(false)
    const data = {
      noteId: claimStatusId,
      claimStatus: claimStatusType?.type,
    }

    if (data?.claimStatus?.length == 0) {
      setError(true)
    } else {
      AdminService.updateTreatmentHistoryStatusByAdmin(data).then(res => {
        if (res.success) {
          const claimKey = claimData.findIndex((claim: ClaimHistoryData) => claim?._id == res?.data?._id);
          const updatedClaim = {
            ...claimData[claimKey],
            claimStatus: res.data.claimStatus,
            errorMsg: res.data.errorMsg,
          };
          claimData[claimKey] = updatedClaim;
          setClaimsData(claimData);
          if (customActiveTab == "2") {
            const updatedClaimData = claimData.filter(claim => claim._id !== res?.data?._id);
            setClaimsData(updatedClaimData);
          }
          Swal.fire({
            icon: "success",
            title: "Successfully updated!",
            confirmButtonColor: "#FD7F00",
          });
          setIsOpenClaimModal(false)
        } else {
          Swal.fire({
            icon: "error",
            title: res.error,
            confirmButtonColor: "#FD7F00",
          });
        }
      });
    }
  }

  const DeleteClaim = (noteId: any) => {
    if (noteId) {
      const data = { noteId: noteId }
      Swal.fire({
        icon: "info",
        title: "Are you sure want to delete this Claim Status?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.deleteTreatmentHistoryByAdmin(data).then(res => {
            if (res.success) {
              toast.success("Successfully Deleted!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              const updatedClaimData = claimData.filter(claim => claim._id !== noteId);
              setClaimsData(updatedClaimData);

            } else {
              toast.error(res.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          })
        }
      })
    } else {
      console.log("Invalid NoteId")
    }
  }

  const removeFlag = (noteId: any) => {
    if (noteId) {
      const data = { noteId }
      Swal.fire({
        icon: "info",
        title: "Are you sure want to remove this flag?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.removeTreatmentHistoryFlagByAdmin(data).then(res => {
            if (res.success) {
              toast.success("Flag is Successfully Removed", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              const claimKey = claimData.findIndex((claim: ClaimHistoryData) => claim._id == noteId);
              const previousClaims = { ...claimData[claimKey] };
              const updatedClaims = { ...previousClaims, flag: false };
              claimData[claimKey] = updatedClaims;
              setClaimsData(claimData);
            } else {
              toast.error(res.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          })
        }
      })
    } else {
      console.log("Invalid NoteId")
    }
  }

  const customStylesNotesModal = {
    content: {
      width: '100%',
    },
  };

  const viewErrorMessage = (errorMsg: any) => {
    Swal.fire(errorMsg);
  }

  const handleClickPendingClaimList = () => {
    setIsSearchableString({ ...isSearchableString, searchableClient: "", searchableTherapist: "", insuranceCompany: "", claimStatus: "PENDING_SUBMISSION", });
    getClaims("PENDING_SUBMISSION");
    toggleCustom("2");
  }
  const handleClickAllClaimList = () => {
    setIsSearchableString({ ...isSearchableString, searchableClient: "", searchableTherapist: "", insuranceCompany: "", claimStatus: "", });
    getClaims("");
    toggleCustom("1");
  }

  const getClaims = (claimStatus: string) => {
    setClaimsData([]);
    setIsLoadingClaims(true);
    const data = {
      searchableClient: "",
      searchableTherapist: "",
      claimStatus: claimStatus,
      insuranceCompany: "",
      claimDate: ""
    };
    setIsLoadingClaims(true);
    AdminService.getAllClaimsByAdmin(data, LIMIT, 0).then(res => {
      if (res.success) {
        setClaimsData(res.data);
        setIsLoadingClaims(false);
        setSeeMoreCount(res.data.length);
        setOffset(0);
      }


      setIsLoadingClaims(false);
    });
  };

  const refreshStatisticOnDemand = () => {
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to update statistics manually?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoadingCount(true);
        AdminService.getAllAdminStatisticsOnDemand().then(res => {
          if (res.success) {
            getNumberOfMissedAppoinments();
            setIsLoadingCount(false);
            toast.success("Statistics data successfully updated.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            setIsLoadingCount(false);
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };  

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {showClaimModal && (
              <InsuranceClaimModal
                diagnosisNoteId={noteId}
                insuranceId={insuranceDetails?._id}
                showModal={showClaimModal}
                toggleClaimModal={toggleClaimModalFalse}
              />
            )}
            <Row>
              <Col xl="2" className="cell">
                <AdminWelcome subscriptionsCount={subscriptionsCount} therapistRevenueCount={therapistRevenueCount} />
              </Col>
              <Col xl="8">
                <Col md="5">
                  <CardBody>
                    <div className="ms-auto mb-3 d-flex justify-content-between">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames({ active: periodType === "week" }, "nav-link")}
                            onClick={() => {
                              onChangeChartPeriod("week");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames({ active: periodType === "month" }, "nav-link")}
                            onClick={() => {
                              onChangeChartPeriod("month");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames({ active: periodType === "yearly" }, "nav-link")}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                      <ul className="nav nav-pills d-flex align-items-center" style={{ gap: "10px" }}>
                        <li className="nav-item">
                          <Button
                            className="btn btn-sm"
                            onClick={() => {
                              refreshStatisticOnDemand();
                            }}
                            id="refresh"
                          >
                            Update Statistic
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Col>
                <Row>
                <Col md="4" className="cell">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="">
                            <p className="text-muted fw-medium">Number of completed sessions</p>
                            <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : completedSessions}</h4>
                          </div>
                          <div className="d-flex justify-content-center align-item-center v-lg-size">
                            <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                              <img src={File1} className="btn-s-24" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="4" className="cell">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Number of scheduled appointments</p>
                            <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : scheduleAppointmentsCount}</h4>
                          </div>
                          <div className="d-flex justify-content-center align-item-center v-lg-size">
                            <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                              <img src={File2} className="btn-s-24" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="4" className="cell">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">ARR</p>
                            <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : `$${yearlyRecurringRevenueCount}`}</h4>
                          </div>
                          <div className="d-flex justify-content-center align-item-center v-lg-size">
                            <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                              <img src={File4} className="btn-s-24" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="4" className="cell">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Number of missed appointments</p>
                            <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : pastMissedAppointmentCount}</h4>
                          </div>
                          <div className="d-flex justify-content-center align-item-center v-lg-size">
                            <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                              <img src={File6} className="btn-s-24" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="4" className="cell">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">MRR</p>
                            <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : `$${monthlyRecurringRevenueCount}`}</h4>
                          </div>
                          <div className="d-flex justify-content-center align-item-center v-lg-size">
                            <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                              <img src={File8} className="btn-s-24" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="4" className="cell">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Gross merchandise value</p>
                            <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : `$${lifeTimeSalesCount}`}</h4>
                          </div>
                          <div className="d-flex justify-content-center align-item-center v-lg-size">
                            <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                              <img src={File9} className="btn-s-24" />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col md="12" xl="2" className="cell mt-mb-n-51 ">
                <Card className="mini-stats-wid" style={{ paddingTop: "23px" }}>
                  <div className="d-none d-xl-block d-xl-flex justify-content-center align-item-center btn-new-icon v-lg-size d-md-none">
                    <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                      <img src={File10} className="btn-s-24" alt="Icon" />
                    </span>
                  </div>

                  <CardBody className="d-flex flex-column justify-content-center align-item-center">
                    <div className="d-flex justify-content-md-between justify-content-lg-start">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Average customer lifetime value</p>
                        <h4 className="mb-0">{isLoadingCount ? <div className="loadingCount">Loading...</div> : `$${lifeTimeSales}`}</h4>
                      </div>

                      <div className="d-flex justify-content-center align-item-center d-xl-none">
                        <span className="bg-primary btn-48 d-flex justify-content-center align-items-center">
                          <img src={File10} className="btn-s-24" alt="Icon" />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <UserManagementTable />
                <ClientTable />
                <TherapistTable />
              </Col>

              <Col xl="12">
                <Card className="overflow-auto">
                  <CardBody>
                    <Row onClick={isView2 ? hideAndShow2 : hideAndShow2} className={`${isView2 ? "cursor-p" : "cursor-p"}`}>
                      <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="d-flex gap-4 col-6">
                        <Col className="d-flex justify-content-center align-items-center" md={12} lg={12} xl={12} xxl={12}>
                          <h4 className="card-title " style={{ flex: 1 }}>
                            Clinical Notes
                          </h4>
                        </Col>
                      </Col>

                      <Col sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex justify-content-end align-items-center gap-md-2 col-6">
                        {!isView2 && (
                          <>
                            <Col className="d-flex admin-filter">
                              <button className="btn btn-sm mr-2"
                                onClick={(event) => {
                                  event.stopPropagation(); toggleFilterNotes()
                                }}
                              >
                                Filter <i className="bx bx-filter-alt"></i>
                              </button>
                            </Col>
                          </>
                        )}

                        <div className="d-sm-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow2}>
                          {isView2 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                        </div>
                      </Col>
                    </Row>

                    <div className={!isView2 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                      {noteData !== undefined && noteData.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Client Name</th>
                                  <th scope="col">Therapist Name</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Note</th>
                                  <th scope="col">Both Join</th>
                                  <th scope="col" className="min-w-300">Reminder</th>
                                </tr>
                              </thead>
                              <tbody>
                                {noteData &&
                                  noteData.map((note, index) => (
                                    <tr key={index}>
                                      <th scope="row">
                                        {index + 1} {note?.clientId?.lavniTestAccount && <i className="fas fa-user-alt-slash"></i>}
                                      </th>
                                      <td>

                                        {note?.clientId?.firstname && note?.clientId.firstname.charAt(0) + ". "}
                                        {note?.clientId?.lastname && note?.clientId.lastname}
                                      </td>

                                      <td>
                                        {note?.therapistId?.firstname && note?.therapistId.firstname.charAt(0) + ". "}
                                        {note?.therapistId?.lastname && note?.therapistId.lastname}
                                      </td>
                                      <td>{moment(note?.updatedAt.toString()).format("YYYY/MM/DD")}</td>
                                      <td>{note?.updatedByTherapist == true ? <span className="verified-2">COMPLETED</span> : <span className="notSubmitted-2">PENDING</span>}</td>
                                      <td> {note?.updatedByTherapist == true && <img src={pdf} className="btn-s-24 cursor-pointer" alt="Icon" onClick={() => displayDiagnosisNotesModal(note?._id)} />}</td>
                                      <td>{note?.meetingId?.bothJoinedAt ? <span>True</span> : <span>False</span>} </td>
                                      <td>
                                        {note?.updatedByTherapist !== true && note?.meetingId?.bothJoinedAt &&
                                          < button
                                            className="btn-sm btn btn-insurance mr-2 btn-style2"
                                            onClick={() => sendReminderSms(note?._id)}
                                          >
                                            Send Reminder
                                          </button>
                                        }
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>

                          {seeMoreCountNotes >= 10 && (
                            <>
                              <button className="btn btn-warning text-center mt-4" onClick={seeMoreNotes}>
                                See More
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        <>{isLoadingNotes ? <Spinner /> : <div>No Available Notes</div>}</>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Modal isOpen={modalCenter} toggle={togCenter}
                centered
                fullscreen
                scrollable
                style={{
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="modal-head"
                  style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}
                >
                  <div className="diagnosis-header">
                    <h5 className="mb-0 font-size-15">Diagnosis Note</h5>
                    <button
                      type="button"
                      onClick={togCenter}
                      className="bg-transparent-color"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <img src={Close} alt="Close" className="close-icon-d" />
                    </button>
                  </div>
                </div>
                <div className="modal-content" style={{
                  width: "100%",
                }}>
                  <div>
                    <div className="row pt20 pl10 chat-conversation p-3">
                      <ul className="list-unstyled">
                        <div
                          className="scrollbar-container ps--active-y"
                          style={{
                            height: isDiagnosisNoteOpen ? "80vh" : "83vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {isDiagnosisNoteOpen && <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData} isVersion={false}></DiagnosisNoteScreen>}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal>

              <Col xl="12">
                <Card className="overflow-auto">
                  <CardBody>
                    <Row onClick={isView3 ? hideAndShow3 : hideAndShow3} className={`${isView3 ? "cursor-p" : "cursor-p"}`}>
                      <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="d-flex gap-4 col-6">
                        <Col className="d-flex justify-content-center align-items-center" md={12} lg={12} xl={12} xxl={12}>
                          <h4 className="card-title " style={{ flex: 1 }}>
                            Claim Status
                          </h4>
                        </Col>
                      </Col>

                      <Col sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex justify-content-end align-items-center gap-md-2 col-6">
                        {!isView3 && (
                          <>
                            <Col className="d-flex admin-filter">
                              <button className="btn btn-sm mr-2 " onClick={(event) => {
                                event.stopPropagation(); toggleFilter()
                              }} >
                                Filter <i className="bx bx-filter-alt"></i>
                              </button>
                            </Col>
                          </>
                        )}

                        <div className="d-sm-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow3}>
                          {isView3 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                        </div>
                      </Col>
                    </Row>

                    <div className={!isView3 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                      {claimData !== undefined ? (
                        <>
                          <Row>
                            <Col xl="12">
                              <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                                <NavItem className="min-nav-w150">
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classNames({ active: customActiveTab === "1" })}
                                    onClick={() => {
                                      handleClickAllClaimList();
                                    }}
                                  >
                                    <span className="">All Claims</span>
                                  </NavLink>
                                </NavItem>

                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classNames({ active: customActiveTab === "2" })}
                                    onClick={() => {
                                      handleClickPendingClaimList()
                                    }}
                                  >
                                    <span className="">Pending Claims</span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </Col>
                          </Row>

                          {
                            customActiveTab === "1" && (
                              <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                <TabPane tabId="1">
                                  {claimData !== undefined ? (
                                    <>
                                      {claimData !== undefined && claimData.length > 0 ? (
                                        <>
                                          <div className="table-responsive">
                                            <table className="table">
                                              <thead className="thead-dark">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col"></th>
                                                  <th scope="col">Client Name</th>
                                                  <th scope="col" >Therapist Name</th>
                                                  <th scope="col">Insurance Company</th>
                                                  <th scope="col">Claim Status</th>
                                                  <th scope="col">ERA List</th>
                                                  <th scope="col">Paid Amount</th>
                                                  <th scope="col">Claim Submission Date</th>
                                                  <th scope="col">Meeting Date</th>
                                                  <th scope="col">Copayment Amount</th>
                                                  <th scope="col">Copayment Status</th>
                                                  <th scope="col">Payment Link</th>
                                                  <th scope="col">Error Msg</th>
                                                  <th scope="col">Submit Claim</th>
                                                  <th scope="col">Update Claim</th>
                                                  <th scope="col">Delete Claim</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {claimData &&
                                                  claimData.map((claimData, index) => (
                                                    <tr key={index}>
                                                      <th scope="row">
                                                        {index + 1} {claimData?.clientDetails?.lavniTestAccount && <i className="fas fa-user-alt-slash"></i>}
                                                      </th>
                                                      <td>

                                                        {claimData?.flag ? <Button style={{ background: "none", border: "none" }} onClick={() => removeFlag(claimData?._id)}> <i className="fa fa-flag text-primary flagbtn "></i></Button> : null}

                                                      </td>
                                                      <td>

                                                        {claimData?.clientDetails?.firstname && claimData?.clientDetails.firstname.charAt(0) + ". "}
                                                        {claimData?.clientDetails?.lastname && claimData?.clientDetails.lastname}
                                                      </td>

                                                      <td>
                                                        {claimData?.therapistDetails?.firstname && claimData?.therapistDetails?.firstname.charAt(0) + ". "}
                                                        {claimData?.therapistDetails?.lastname && claimData?.therapistDetails?.lastname}
                                                      </td>

                                                      <td>{claimData?.insuranceCompanyDetails?.insuranceCompany}</td>
                                                      {claimData?.claimStatus == "ACTIVE" && !claimData?.errorMsg ? (
                                                        <td>
                                                          <span className="verified-2">SUCCESS</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "ACTIVEMD" && !claimData?.errorMsg ? (
                                                        <td>
                                                          <span className="verified-2">SUCCESS&nbsp;MD</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "PAID" && !claimData?.errorMsg ? (
                                                        <td>
                                                          <span className="verified-2">PAID</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "PAIDMD" && !claimData?.errorMsg ? (
                                                        <td>
                                                          <span className="verified-2">PAID&nbsp;MD</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "UNPAID" && !claimData?.errorMsg ? (
                                                        <td>
                                                          <span className="inactive-2">UNPAID</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "UNPAIDMD" ? (
                                                        <td>
                                                          <span className="inactive-2">UNPAID&nbsp;MD</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "INACTIVE" || claimData?.errorMsg ? (
                                                        <td>
                                                          <span className="inactive-2">FAILED</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "PENDING_SUBMISSION" ? (
                                                        <td>
                                                          <span className="inactive-2">PENDING&nbsp;SUBMITTED</span>
                                                        </td>
                                                      ) : claimData?.claimStatus == "IN_PROGRESS" ? (
                                                        <td>
                                                          <span className="inprogress-2">IN&nbsp;PROGRESS</span>
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <span className="notSubmitted-2">NOT&nbsp;SUBMITTED</span>
                                                        </td>
                                                      )}

                                                      {
                                                        (claimData?._id && (claimData?.claimStatus == "UNPAIDMD" || claimData?.claimStatus == "PAIDMD")) ? (
                                                          <td>
                                                            <EraListViewComp treatmentId={claimData?._id}></EraListViewComp>
                                                          </td>
                                                        ):(
                                                          <td> - </td>
                                                        )
                                                      }

                                                      <td>{claimData?.paidAmount ? claimData?.paidAmount : "-"}</td>

                                                      <td>{moment(claimData?.createdAt).format("YYYY/MM/DD hh:mm A")}</td>

                                                      <td>{moment(claimData?.meetingStartedTime).format("YYYY/MM/DD hh:mm A")}</td>

                                                      <td>{claimData?.meetingId?.copayment?.amount ? claimData?.meetingId?.copayment?.amount : "-"}</td>

                                                      {claimData?.meetingId?.copayment?.status == "PAID" ? (
                                                        <td>
                                                          <span className="verified-2">PAID</span>
                                                        </td>
                                                      ) : claimData?.meetingId?.copayment?.status == "UNPAID" ? (
                                                        <td>
                                                          <span className="inactive-2">UNPAID</span>
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <span className="notSubmitted-2">NOT&nbsp;SUBMITTED</span>
                                                        </td>
                                                      )}
                                                      {claimData?.meetingId?.copayment?.status == "UNPAID" && claimData?.meetingId?.copayment?.details ?
                                                        <td>
                                                          <a className="verified-2" href={claimData?.meetingId?.copayment?.details} target="_blank" rel="noopener noreferrer">Click&nbsp;Here</a>
                                                        </td>
                                                        :
                                                        <td>
                                                          <span >-</span>
                                                        </td>
                                                      }
                                                      <td>{claimData?.errorMsg && claimData?.errorMsg ? <button className="btn btn-danger btn-sm" onClick={() => viewErrorMessage(claimData?.errorMsg)}>View Error </button> : <span>-</span>}</td>
                                                      <td>
                                                        {
                                                          claimData?.claimStatus === "ACTIVE" ||
                                                            claimData?.claimStatus === "ACTIVEMD" ||
                                                            claimData?.claimStatus === "PAID" ||
                                                            claimData?.claimStatus === "PAIDMD" ||
                                                            claimData?.claimStatus === "UNPAID" ||
                                                            claimData?.claimStatus === "UNPAIDMD" ||
                                                            claimData?.claimStatus === "IN_PROGRESS" ||
                                                            claimData?.claimStatus === "PENDING_SUBMISSION" ? null : (
                                                            <button
                                                              className="btn btn-success btn-sm btn-style4 mr-3"
                                                              onClick={() => displayClaimModal(claimData?.diagnosisNoteId?._id, claimData?.clientDetails?._id)}
                                                            >
                                                              Submit Claim
                                                            </button>
                                                          )}
                                                        {
                                                          claimData?.claimStatus === "UNPAID" && claimData?.crownJobRunCount && claimData?.crownJobRunCount > 2 ? (
                                                            <button
                                                              className="btn btn-primary btn-sm btn-style4 mr-3"
                                                              onClick={() => runClaim(claimData?.diagnosisNoteId?._id, claimData?.clientDetails.insuranceId, claimData?.clientInsuranceDetails.insuranceCompanyId)}
                                                            >
                                                              Run Claim
                                                            </button>
                                                          ) : null
                                                        }
                                                      </td>
                                                      <td>
                                                        <button
                                                          className="btn btn-primary btn-sm btn-style4 mr-3"
                                                          onClick={() => updateClaimModal(claimData?._id)}
                                                        >
                                                          Update Claim Status
                                                        </button>
                                                      </td>

                                                      <td>
                                                        <button className="btn-sm btn btn-danger mr-2"
                                                          onClick={() => DeleteClaim(claimData?._id)}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </td>

                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </div>

                                          {seeMoreCount >= 10 && isLoadingClaims ? (
                                            <>
                                              <button className="btn btn-warning text-center mt-4" disabled>
                                                Loading..
                                              </button>
                                            </>
                                          )
                                            : seeMoreCount % 10 === 0 && seeMoreCount != 0 && !isLoadingClaims ? (
                                              <>
                                                <button className="btn btn-warning text-center mt-4" onClick={seeMore1}>
                                                  See More
                                                </button>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                        </>
                                      ) : (
                                        <>{isLoadingClaims ? <Spinner /> : <div>No Available Claims</div>}</>
                                      )}
                                    </>
                                  ) : (
                                    null
                                  )}
                                </TabPane>
                              </TabContent>
                            )
                          }

                          {
                            customActiveTab === "2" && (
                              <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                <TabPane tabId="2">
                                  {claimData !== undefined && claimData.length > 0 ? (
                                    <>
                                      <div className="table-responsive">
                                        <table className="table">
                                          <thead className="thead-dark">
                                            <tr>
                                              <th scope="col">#</th>
                                              <th scope="col"></th>
                                              <th scope="col">Client Name</th>
                                              <th scope="col" >Therapist Name</th>
                                              <th scope="col">Insurance Company</th>
                                              <th scope="col">Claim Status</th>
                                              <th scope="col">ERA List</th>
                                              <th scope="col">Claim Submission Date</th>
                                              <th scope="col">Copayment Amount</th>
                                              <th scope="col">Copayment Status</th>
                                              <th scope="col">Payment Link</th>
                                              <th scope="col">Error Msg</th>
                                              <th scope="col">Submit Claim</th>
                                              <th scope="col">Update Claim</th>
                                              <th scope="col">Delete Claim</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {claimData &&
                                              claimData.map((claimData, index) => (
                                                <tr key={index}>
                                                  <th scope="row">
                                                    {index + 1} {claimData?.clientDetails?.lavniTestAccount && <i className="fas fa-user-alt-slash"></i>}
                                                  </th>
                                                  <td>

                                                    {claimData?.flag ? <Button style={{ background: "none", border: "none" }} onClick={() => removeFlag(claimData?._id)}> <i className="fa fa-flag text-primary flagbtn "></i></Button> : null}

                                                  </td>
                                                  <td>

                                                    {claimData?.clientDetails?.firstname && claimData?.clientDetails.firstname.charAt(0) + ". "}
                                                    {claimData?.clientDetails?.lastname && claimData?.clientDetails.lastname}
                                                  </td>

                                                  <td>
                                                    {claimData?.therapistDetails?.firstname && claimData?.therapistDetails?.firstname.charAt(0) + ". "}
                                                    {claimData?.therapistDetails?.lastname && claimData?.therapistDetails?.lastname}
                                                  </td>

                                                  <td>{claimData?.insuranceCompanyDetails?.insuranceCompany}</td>
                                                  {claimData?.claimStatus == "ACTIVE" && !claimData?.errorMsg ? (
                                                    <td>
                                                      <span className="verified-2">SUCCESS</span>
                                                    </td>
                                                  ) : claimData?.claimStatus == "PAID" && !claimData?.errorMsg ? (
                                                    <td>
                                                      <span className="verified-2">PAID</span>
                                                    </td>
                                                  ) : claimData?.claimStatus == "UNPAID" && !claimData?.errorMsg ? (
                                                    <td>
                                                      <span className="inactive-2">UNPAID</span>
                                                    </td>
                                                  ) : claimData?.claimStatus == "INACTIVE" || claimData?.errorMsg ? (
                                                    <td>
                                                      <span className="inactive-2">FAILED</span>
                                                    </td>
                                                  ) : claimData?.claimStatus == "PENDING_SUBMISSION" ? (
                                                    <td>
                                                      <span className="inactive-2">PENDING&nbsp;SUBMITTED</span>
                                                    </td>
                                                  ) : (
                                                    <td>
                                                      <span className="notSubmitted-2">NOT&nbsp;SUBMITTED</span>
                                                    </td>
                                                  )}

                                                  {
                                                    (claimData?._id && (claimData?.claimStatus == "UNPAIDMD" || claimData?.claimStatus == "PAIDMD")) ? (
                                                      <td>
                                                        <EraListViewComp treatmentId={claimData?._id}></EraListViewComp>
                                                      </td>
                                                    ):(
                                                      <td> - </td>
                                                    )
                                                  }

                                                  <td>{moment(claimData?.createdAt).format("YYYY/MM/DD hh:mm A")}</td>

                                                  <td>{claimData?.meetingId?.copayment?.amount ? claimData?.meetingId?.copayment?.amount : "-"}</td>

                                                  {claimData?.meetingId?.copayment?.status == "PAID" ? (
                                                    <td>
                                                      <span className="verified-2">PAID</span>
                                                    </td>
                                                  ) : claimData?.meetingId?.copayment?.status == "UNPAID" ? (
                                                    <td>
                                                      <span className="inactive-2">UNPAID</span>
                                                    </td>
                                                  ) : (
                                                    <td>
                                                      <span className="notSubmitted-2">NOT&nbsp;SUBMITTED</span>
                                                    </td>
                                                  )}
                                                  {claimData?.meetingId?.copayment?.status == "UNPAID" && claimData?.meetingId?.copayment?.details ?
                                                    <td>
                                                      <a className="verified-2" href={claimData?.meetingId?.copayment?.details} target="_blank" rel="noopener noreferrer">Click&nbsp;Here</a>
                                                    </td>
                                                    :
                                                    <td>
                                                      <span >-</span>
                                                    </td>
                                                  }
                                                  <td>{claimData?.errorMsg && claimData?.errorMsg ? <button className="btn btn-danger btn-sm" onClick={() => viewErrorMessage(claimData?.errorMsg)}>View Error </button> : <span>-</span>}</td>
                                                  <td>
                                                    {claimData?.claimStatus === "ACTIVE" || claimData?.claimStatus === "PAID" || claimData?.claimStatus === "UNPAID" ? null : (
                                                      <button
                                                        className="btn btn-success btn-sm btn-style4 mr-3"
                                                        onClick={() => displayClaimModal(claimData?.diagnosisNoteId?._id, claimData?.clientDetails?._id)}
                                                      >
                                                        Submit Claim
                                                      </button>
                                                    )}
                                                    {
                                                      claimData?.claimStatus === "UNPAID" && claimData?.crownJobRunCount && claimData?.crownJobRunCount > 2 ? (
                                                        <button
                                                          className="btn btn-primary btn-sm btn-style4 mr-3"
                                                          onClick={() => runClaim(claimData?.diagnosisNoteId?._id, claimData?.clientDetails.insuranceId, claimData?.clientInsuranceDetails.insuranceCompanyId)}
                                                        >
                                                          Run Claim
                                                        </button>
                                                      ) : null
                                                    }
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-primary btn-sm btn-style4 mr-3"
                                                      onClick={() => updateClaimModal(claimData?._id)}
                                                    >
                                                      Update Claim Status
                                                    </button>
                                                  </td>

                                                  <td>
                                                    <button className="btn-sm btn btn-danger mr-2"
                                                      onClick={() => DeleteClaim(claimData?._id)}
                                                    >
                                                      <i className="fa fa-trash"></i>
                                                    </button>
                                                  </td>

                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>

                                      {seeMoreCount >= 10 && isLoadingClaims ? (
                                        <>
                                          <button className="btn btn-warning text-center mt-4" disabled>
                                            Loading..
                                          </button>
                                        </>
                                      )
                                        : seeMoreCount % 10 === 0 && seeMoreCount != 0 && !isLoadingClaims ? (
                                          <>
                                            <button className="btn btn-warning text-center mt-4" onClick={seeMore1}>
                                              See More
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                    </>
                                  ) : (
                                    <>{isLoadingClaims ? <Spinner /> : <div>No Available Pending Claims</div>}</>
                                  )}
                                </TabPane>
                              </TabContent>
                            )
                          }

                        </>
                      ) : (
                        null
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="overflow-auto">
                  <CardBody>
                    <Row onClick={isView4 ? hideAndShow4 : hideAndShow4} className={`${isView4 ? "cursor-p" : "cursor-p"}`}>
                      <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="d-flex gap-4">
                        <Col className="d-flex justify-content-center align-items-center" md={12} lg={12} xl={12} xxl={12}>
                          <h4 className="card-title " style={{ flex: 1 }}>
                            Claim ERA Status
                          </h4>
                        </Col>
                      </Col>
                      <Col sm={5} md={5} lg={5} xl={5} xxl={5} className="d-flex justify-content-end align-items-center gap-md-2">
                      </Col>
                      <Col sm={3} md={3} lg={3} xl={3} xxl={3} className="d-flex justify-content-end align-items-center gap-md-2 mb-2">
                        {!isView4 && (
                          <>
                            <Col className="d-flex admin-filter" onClick={(event) => event.stopPropagation()}>
                              <Input
                                type="select"
                                name="type"
                                placeholder="Select Claim Type"
                                onChange={(e) => filterERALIST(e.target.value)}
                              >
                                <option>Select Type</option>
                                <option value="COMPLETED">SUCCESS</option>
                                <option value="FAILED">FAILED</option>
                                <option value="PENDING">PENDING</option>
                              </Input>
                            </Col>
                          </>
                        )}

                        <div className="d-sm-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow4}>
                          {isView4 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                        </div>
                      </Col>

                    </Row>

                    <div className={!isView4 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                      {claimERAData !== undefined ? (
                        <>
                          {claimERAData !== undefined && claimERAData.length > 0 ? (
                            <>
                              <div className="table-responsive">
                                <table className="table">
                                  <thead className="thead-dark">
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Client Name</th>
                                      <th scope="col">From Date</th>
                                      <th scope="col">Claim Received Date</th>
                                      <th scope="col">Total Paid</th>
                                      <th scope="col">PCN</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {claimERAData &&
                                      claimERAData.map((claimData, index) => (
                                        <tr key={index}>
                                          <td> {index + 1}</td>
                                          <td>

                                            {claimData?.pat_name_f && claimData.pat_name_f.charAt(0) + ". "}
                                            {claimData?.pat_name_l && claimData.pat_name_l}
                                          </td>
                                          <td>{claimData?.from_dos ? moment(claimData?.from_dos).format("YYYY/MM/DD hh:mm A") : "-"}</td>

                                          <td>{claimData?.claim_received_date ? moment(claimData?.claim_received_date).format("YYYY/MM/DD hh:mm A") : "-"}</td>

                                          <td>{claimData?.total_paid ? claimData?.total_paid : "-"}</td>

                                          <td>{claimData?.pcn}</td>



                                          {claimData?.status == "COMPLETED" ? (
                                            <td>
                                              <span className="verified-2">SUCCESS</span>
                                            </td>
                                          ) : claimData?.status == "FAILED" ? (
                                            <td>
                                              <span className="inactive-2">FAILED</span>
                                            </td>
                                          ) : (
                                            <td>
                                              <span className="notSubmitted-2">PENDING</span>
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>

                              {seeMoreERACount >= 10 && isLoading ? (
                                <>
                                  <button className="btn btn-warning text-center mt-4" disabled>
                                    Loading..
                                  </button>
                                </>
                              )
                                : seeMoreERACount % 10 === 0 && seeMoreERACount != 0 && !isLoading ? (
                                  <>
                                    <button className="btn btn-warning text-center mt-4" onClick={seeMoreERA}>
                                      See More
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                            </>
                          ) : (
                            <>{isLoading ? <Spinner /> : <div>No Available ERA Claim list.</div>}</>
                          )}
                        </>
                      ) : (
                        null
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="12">
                <Billing />
              </Col>
            </Row>

            <Modal isOpen={isOpenClaimModal} toggle={toggleClaimStatusModal} centered className="modal-md">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleClaimStatusModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Update Claim Status</span>
                </Row>
                <Row className="mb-4">
                  <Col xl={4}>
                    <label>Claim Status Type</label>
                  </Col>
                  <Col xl={8}>
                    <Input
                      type="select"
                      name="type"
                      placeholder="Select Claim Type"
                      onChange={(e) => setClaimStatusType({ ...claimStatusType, type: e.target.value })}
                      value={claimStatusType?.type === null ? undefined : claimStatusType?.type}
                    >
                      <option>Select Type</option>
                      <option value="ACTIVE">SUCCESS</option>
                      <option value="PAID">PAID</option>
                      <option value="PAIDMD">PAID MD</option>
                      <option value="UNPAID">UNPAID</option>
                      <option value="UNPAIDMD">UNPAID MD</option>
                      <option value="NOTSUBMITTED">NOT SUBMITTED</option>
                      <option value="INACTIVE">FAILED</option>
                      <option value="PENDING_SUBMISSION">PENDING SUBMISSION</option>

                    </Input>
                    {
                      error && claimStatusType?.type?.length == 0 && (
                        <p className="text-danger fs-10-n">Please select type</p>
                      )
                    }
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2"
                      onClick={() => updateTherapistPayaRate()}
                    >Update Claim Status</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Offcanvas className="offcanvas-end2" isOpen={isFilterOptions} direction="end" toggle={toggleFilter}>
              <OffcanvasHeader toggle={toggleFilter}>Filter Claims</OffcanvasHeader>

              <OffcanvasBody>
                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Filter by Client Name</Label>
                      <Input id="search"
                        onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableClient: e.target.value })}
                        value={isSearchableString?.searchableClient}
                        name="text" placeholder="Search Client (ex: first name, last name or email)" type="text" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Filter by Therapist Name</Label>
                      <Input id="search"
                        onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableTherapist: e.target.value })}
                        value={isSearchableString?.searchableTherapist}
                        name="text" placeholder="Search Therapist (ex: first name, last name or email)" type="text" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <Label>Filter by Insurance Company</Label>
                    <FormGroup>
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(e: any) => setIsSearchableString({ ...isSearchableString, insuranceCompany: e.target.value })}
                        value={isSearchableString?.insuranceCompany}
                      >
                        <option value={ClaimStatus.ALL}>Select Insurance Company </option>
                        {searchInsuranceCompanyTag &&
                          searchInsuranceCompanyTag.map(opt => (
                            <option value={opt.label} key={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <Label>Claim Status</Label>
                    <FormGroup>
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(e: any) => setIsSearchableString({ ...isSearchableString, claimStatus: e.target.value })}
                        value={isSearchableString?.claimStatus}
                      >
                        <option value={ClaimStatus.ALL}> All </option>
                        <option value={ClaimStatus.SUCCESS}>SUCCESS </option>
                        <option value={ClaimStatus.SUCCESSMD}>SUCCESS-MD </option>
                        <option value={ClaimStatus.PAID}>PAID </option>
                        <option value={ClaimStatus.PAIDMD}>PAID-MD </option>
                        <option value={ClaimStatus.IN_PROGRESS}>IN-PROGRESS </option>
                        <option value={ClaimStatus.PENDING_SUBMISSION}>PENDING-SUBMISSION </option>
                        <option value={ClaimStatus.UNPAID}>UNPAID </option>
                        <option value={ClaimStatus.UNPAIDMD}>UNPAID-MD</option>
                        <option value={ClaimStatus.NOTSUBMITTED}>NOT SUBMITTED </option>
                        <option value={ClaimStatus.FAILED}>FAILED </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <Label>Filter by Claim Date</Label>
                    <FormGroup>
                      <Input type="date" value={selectedDate} onChange={handleDateChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg={12}>
                    {isLoadingClaims ? (
                      <button className="btn btn-success w-100" style={{ width: "unset", margin: "auto" }}>
                        Loading...
                      </button>
                    ) : (
                      <>
                        <button className="btn btn-success w-100" onClick={() => allClaims()}>
                          Search
                        </button>
                      </>
                    )
                    }
                  </Col>

                  <Col lg={12} className="mt-4">
                    <button className="btn btn-info w-100" onClick={() => clearClaims()}>
                      Clear
                    </button>
                  </Col>
                </Row>
              </OffcanvasBody>
            </Offcanvas>



            <Offcanvas className="offcanvas-end2" isOpen={isFilterOptionsNotes} direction="end" toggle={toggleFilterNotes}>
              <OffcanvasHeader toggle={toggleFilterNotes}>Filter Clinical Notes</OffcanvasHeader>

              <OffcanvasBody>
                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Filter by Client Name</Label>
                      <Input id="search"
                        onChange={(e: any) => setIsSearchableNotesString({ ...isSearchableNotesString, searchableClient: e.target.value })}
                        value={isSearchableNotesString?.searchableClient}
                        name="text" placeholder="Search Client (ex: first name, last name or email)" type="text" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Filter by Therapist Name</Label>
                      <Input id="search"
                        onChange={(e: any) => setIsSearchableNotesString({ ...isSearchableNotesString, searchableTherapist: e.target.value })}
                        value={isSearchableNotesString?.searchableTherapist}
                        name="text" placeholder="Search Therapist (ex: first name, last name or email)" type="text" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <Label>Filter by Date</Label>
                    <FormGroup>
                      <Input type="date" value={selectedDate} onChange={handleDateChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg={12}>
                    {isLoadingNotes ? (
                      <button className="btn btn-success w-100" style={{ width: "unset", margin: "auto" }}>
                        Loading...
                      </button>
                    ) : (
                      <>
                        <button className="btn btn-success w-100" onClick={() => allNotes()}>
                          Search
                        </button>
                      </>
                    )
                    }
                  </Col>

                  <Col lg={12} className="mt-4">
                    <button className="btn btn-info w-100" onClick={() => clearNotes()}>
                      Clear
                    </button>
                  </Col>
                </Row>
              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default AdminNewDashboard;
