import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Container, Card, CardTitle, Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { AdminService } from "../../services/AdminService";
import Papa from "papaparse";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor"
import saveAs from "file-saver";

import CSV from "../../assets/images/csv/Sample_csv.png";

const MarketingEmail: React.FC = () => {
  const [emails, setEmails] = useState<any[]>([]);
  const [customEmailBody, setCustomEmailBody] = useState<any>("");
  const [emailSubject, setEmailSubject] = useState<any>("Welcome to Lavni!");
  const [file, setFile] = useState<string>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const back = () => {
    history.goBack();
  };


  const handleFileChange = (event: any) => {
    const extension = event.target.files[0].name.split(".").pop().toLowerCase();

    if (extension !== "csv") {
      setFile("");
      return toast.error("Invalid file format. Please upload a CSV file to continue.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    } else {
      setFile(URL.createObjectURL(event.target.files[0]));
      setIsDisabled(true);
      setIsLoading(true);
      csvToJson(event.target.files[0]);
    }
  };

  const csvToJson = (file: any) => {

    Papa.parse(file, {
      header: true,
      complete: (results: any) => {
        const emailList = results.data;
        const updatedEmailList: any[] = [];

        for (const obj of emailList) {
          if (obj) {
            updatedEmailList.push(obj);
          }
        }

        setEmails(updatedEmailList);
        setIsLoading(false);
      },
    });
  };

  const sendMarketingEmails = (emailList: any[]) => {
    if (emailSubject == '' || emailSubject == null || emailSubject === undefined) {
      return toast.error("Email subject is required!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }

    if (customEmailBody == '' || customEmailBody == null || customEmailBody === undefined) {
      return toast.error("Email Content is required!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }

    if (emailList && emailList.length > 0) {
      const data = {
        emailList: emailList,
        emailSubject: emailSubject,
        emailBody: customEmailBody,
      };

      AdminService.sendMarketingEmails(data).then(res => {
        if (res.success) {
          setFile("");
          setIsDisabled(false);
          return toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        } else {
          setFile("");
          setIsDisabled(false);
          return toast.error(res.error, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      setFile("");
      setIsDisabled(false);
      return toast.error("Please select a CSV file containing emails to continue.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const removeFile = () => {
    setFile("");
    setIsDisabled(false);
  };

  const downloadCSV = () => {
    saveAs(CSV, 'sample.csv');
  }

  const loadDefaultTemplate = () => {
    const defaultMail = `
      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        My name is Laura Valentine, and I am a Licensed Clinical Mental Health Counselor. I would love to introduce you to Lavni; a behavioral and mental health online platform for underserved communities. We are making a difference in breaking the stigma against seeking mental health care.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        People in our communities are suffering in silence because they are afraid to say that they are not ok. Undiagnosed and untreated mental illness continues to be a major concern in our community. Underserved communities are twice as likely not to receive care for their mental condition.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        We are looking for licensed mental health clinicians like yourself to help us make a difference in our communities.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        Our application helps clinicians complete session notes easily using our AI-enabled notes feature. We offer competitive pay, referrals, flexibility, and the convenience of working from the comfort of your home.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        I would love to share more with you. Check out our website for information about joining our community of healers. We need your help to impact the broken-hearted as they journey towards overcoming their pain.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        Together, we can transform lives.
      </p>

      <p style="margin:25px 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Warm Regards,</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Laura Valentine, LCMHC</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Co-founder, Lavni Inc.</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">https://mylavni.com</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Direct: 919.358.3012 | laura@mylavni.com</p>

      <br>

      <p style="margin:0 0 0px 0;font-size:12px;line-height:18px;font-family:Arial,sans-serif;">
        HIPAA CONFIDENTIALITY STATEMENT: This document and/or its attachments may contain sensitive information that requires protection under Federal or State law. Authorized recipients of such information are required to protect it in a safe, secure and confidential manner. If you are not the intended recipient, you are hereby notified that any disclosure, copying, distribution or action taken in reliance on the contents of those documents is strictly prohibited. You are requested to notify the sender immediately, delete the email with any accompanying attachments, and destroy any copies you may have made.
      </p>
    `

    if (customEmailBody === '') {
      setCustomEmailBody(defaultMail);
    } else {
      setCustomEmailBody('');
    }


  }

  const editorConfiguration = {
    removePlugins: ['EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'Table'],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
          <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
          <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
        </div>
        <Container className="containerOnboarding mt-5">
          <Card className="BorderRadiusLeftRight p-3">
            <div className="card-bodyDiv">
              <div className="row mb-4">
                <div className="col">
                  <Input
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Marketing Email Subject"
                    name="articleTitle"
                    value={emailSubject}
                    onChange={event => {
                      setEmailSubject(event.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label
                  htmlFor="form-upload-file"
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#FD7F00",
                    marginBottom: "0px",
                  }}
                >
                  + Upload CSV
                </Label>

                {isDisabled ? (
                  <Input type="file" className="form-control" id="form-upload-file" style={{ display: "none", cursor: "unset" }} required disabled />
                ) : (
                  <Input
                    type="file"
                    className="form-control"
                    id="form-upload-file"
                    onChange={event => {
                      handleFileChange(event);
                    }}
                    style={{ display: "none" }}
                    required
                  />
                )}
              </div>
              <div className="px-3 font-size-50">{file && <i className="fa fa-file" style={{ fontSize: "60px" }}></i>}</div>
              <div className="row mb-4">
                <div className="col">
                  <CKEditor
                    editor={Editor}
                    stlye={{ minHeight: "300px" }}
                    onChange={(event: any, editor: { getData: () => any }) => {
                      const data = editor.getData();
                      setCustomEmailBody(data);
                    }}
                    data={customEmailBody}
                    config={editorConfiguration}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <button onClick={() => downloadCSV()} className="btn btn-sm btn-success mr-2">Download Sample CSV</button>
                  <button onClick={() => loadDefaultTemplate()} className="btn btn-sm btn-primary ml-2">{customEmailBody === '' ? 'Load Default Email' : 'Clear Default Email'}</button>
                </div>
              </div>
              <div className="flex flex-row-reverse p-0">
                <button className="btn btn-success" onClick={() => sendMarketingEmails(emails)}>
                  Upload
                </button>
                <button
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    removeFile();
                  }}

                >
                  Remove
                </button>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarketingEmail;
