import moment from "moment";
import React, { useEffect, useState } from "react";
import { CardBody, Card, CardTitle, Container } from "reactstrap";
import { TreatmentHistoryData } from "../../../models/TreatmentHistory";
import { VideoChatService } from "../../../services/VideoChatService";
import SessionHistoryModalForAdmin from "../../Popup/SessionHistoryModalForAdmin";
import { useHistory, useParams } from "react-router-dom";
import { ParameterTypes } from "../../../utils/ParameterTypes";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Spinner from "../../../common/spinner/spinner";
import NoteShareTherapistModal from "src/pages/Popup/NoteShareTherapistModal";
import SessionHistoryPIEModalForAdmin from './../../Popup/SessionHistoryPIEModalForAdmin';
import DiagnosisNoteVersionModal from "src/pages/Popup/DiagnosisNoteVersionModal";

interface ClientSessionsViewProps {
  userId: string;
  callBackPage: () => void;
}

const ClientSessionsView: React.FC<ClientSessionsViewProps> = ({ userId, callBackPage }: ClientSessionsViewProps) => {
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(false);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [offset, setOffset] = useState<number>(1);
  const [isDiagnosisNoteOpen, setIsDiagnosisNoteOpen] = useState(false);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [openShareModal, setOpenShareModal] = useState(false)
  const [transcribeData, setTranscribeData] = useState();
  const history = useHistory();
  const [noteType, setNoteType] = useState<string | undefined>();
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const [showPIENote, setShowPIENote] = useState(false);
  const [diagnosisNoteWithVersionsData, setDiagnosisNoteWithVersionsData] = useState<any>();
  const [showDiagnosisNoteWithVersionModal, setShowDiagnosisNoteWithVersionModal] = useState(false);
  const [isVersion, setIsVersion] = useState<boolean>(false);

  useEffect(() => {
    if (diagnosisId != "") {
      setIsDiagnosisNoteOpen(true);
    }
  }, [diagnosisId]);

  useEffect(() => {
    if (diagnosisNoteWithVersionsData) {
      setShowDiagnosisNoteWithVersionModal(true);
    }
  }, [diagnosisNoteWithVersionsData]);

  useEffect(() => {
    setIsTreatmentError(false);
    if (userId) getTreatmentHistoryNotesInitial(userId);
  }, []);

  const getTreatmentHistoryNotesInitial = (id: string) => {
    setIsTreatmentLoading(true);
    setOffset(1);
    VideoChatService.getTreatmentHistoryForClientByAdmin(id).then(res => {
      if (res.success) {
        setIsTreatmentError(false);

        setTreatmentHistory(res.data);
        setIsTreatmentLoading(false);
      } else {
        setTimeout(() => {
          setIsTreatmentLoading(false);
        }, 500);
        setIsTreatmentError(true);
      }
    });
  };

  useEffect(() => {
    if (isDiagnosisNoteOpen && (noteType)) {

    } else {

    }
  }, [isDiagnosisNoteOpen, noteType, showPIENote, showAiGeneratedPopup]);

  const showDiagnosis =  (note: any, isVersion: boolean) => {
    try {
       if (note?._id) {
          setDiagnosisId(note?._id);
          setIsVersion(isVersion);

           const filteredTreatmentDetail = treatmentHistoryDetails?.filter((tr) => tr?.diagnosisNoteId === note?._id);

           const noteType = filteredTreatmentDetail[0]?.noteType

       if(!noteType && filteredTreatmentDetail[0]?.updatedByTherapist) {
            console.log("condition 01")
            setNoteType('SOAP');
            setShowAiGeneratedPopup(true);
            setShowPIENote(false);

       } else if(noteType) {
          console.log("condition 02")
          setNoteType(noteType);
          if(noteType == 'SOAP') {
              setShowAiGeneratedPopup(true);
              setShowPIENote(false);
            } else {
                 setShowPIENote(true);
                 setShowAiGeneratedPopup(false);
              }
       } else {
          console.log("condition 04")
          setNoteType(undefined)

      }
    }
    } catch (error) {
      console.log(error)
    }

  };

  const closeDiagnosis = () => {
    setDiagnosisId("");
    setIsDiagnosisNoteOpen(false);
  };

  const back = () => {
    // history.goBack();
    callBackPage()
  };

  const handleOpenShareNote = (transcribeData: any) => {
    setTranscribeData(transcribeData)
    setOpenShareModal(true)
  }

  const onShare = (data: any) => {
    const historyKey = treatmentHistoryDetails?.findIndex((treatmentHistoryData: TreatmentHistoryData) => treatmentHistoryData._id == data?._id);
    const previousHistory = { ...treatmentHistoryDetails[historyKey] };
    const updatedHistory = { ...previousHistory, subTherapistId: data?.subTherapistId };
    treatmentHistoryDetails[historyKey] = updatedHistory;
    setTreatmentHistory(treatmentHistoryDetails);
    setOpenShareModal(false)
  }

  const handleModalClose = () => {
    setShowDiagnosisNoteWithVersionModal(false);
    setDiagnosisNoteWithVersionsData(null);
  };

  return (
    <React.Fragment>
      {/* {isDiagnosisNoteOpen && <SessionHistoryModalForAdmin setShowModal={closeDiagnosis} showModal={isDiagnosisNoteOpen} userId={diagnosisId} />} */}
      {showDiagnosisNoteWithVersionModal && <DiagnosisNoteVersionModal setShowModal={setShowDiagnosisNoteWithVersionModal} showModal={showDiagnosisNoteWithVersionModal} diagnosisNoteDataTherapist={null} diagnosisNoteDataAdmin={diagnosisNoteWithVersionsData} showDiagnosisFunction={ () => {} } isAdmin={true} showDiagnosis={showDiagnosis} handleClose={handleModalClose} />}
        {
          isDiagnosisNoteOpen && (noteType) ? (
             (noteType == 'PIE' ) ? (
                     showPIENote && <SessionHistoryPIEModalForAdmin
                       setShowModal={closeDiagnosis}
                       showModal={isDiagnosisNoteOpen}
                       userId={diagnosisId}
                       isVersion={isVersion} />) :
                       (
                  showAiGeneratedPopup &&  <SessionHistoryModalForAdmin
                         setShowModal={closeDiagnosis}
                          showModal={isDiagnosisNoteOpen}
                          userId={diagnosisId}
                          isVersion={isVersion} />
                                             )
                                        )
                                        : (
                     <SessionHistoryModalForAdmin
                        setShowModal={closeDiagnosis}
                        showModal={isDiagnosisNoteOpen}
                        userId={diagnosisId}
                        isVersion={isVersion}
                                         />
                                       )

         }
      {isTreatmentLoading ? (
        <Spinner className="bouncer"></Spinner>
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Lavni" breadcrumbItem="Completed Sessions" />
            <div className="flex flex-wrap justify-content-between">
              <div className="flex cursor-pointer" onClick={() => back()}>
                <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                <CardTitle className="mb-4  cursor-pointer">&nbsp; Back</CardTitle>
              </div>
            </div>

            {!isTreatmentLoading && isTreatmentError && (
              <div style={{ textAlign: "center" }}>
                <h5>Server error occurred</h5>
              </div>
            )}

            {!isTreatmentLoading && !isTreatmentError && treatmentHistoryDetails && treatmentHistoryDetails.length == 0 && (
              <div style={{ textAlign: "center" }}>
                <h5>No sessions created yet</h5>
              </div>
            )}

            {!isTreatmentLoading &&
              !isTreatmentError &&
              treatmentHistoryDetails?.map((tr: any, index) => {
                return (
                  <Card key={index} onClick={() => { }} className="cursor_pointer">
                    {tr.isMeetingTranscribe && (
                      <CardBody>
                        {tr?.meetingId?.appointmentId ? (
                          <>
                            <h5 className="font-size-15 mb-1">
                              Appointment Start Time <span className="session-h5">{moment(tr?.appointmentDetails?.start || null).format("YYYY-MM-DD h:mm:ss a")}</span> / Meeting Started at <span className="session-h5">{moment(tr?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                            </h5>

                          </>
                        ) : (
                          <>
                            <h5 className="font-size-15 mb-1">
                              Meeting Started At <span className="session-h5">{moment(tr?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                            </h5>
                          </>
                        )}

                        {tr?.meetingId?.callingStatus == "COMPLETED" &&  tr.isMeetingTranscribe && (
                          <a
                            // onClick={() => {
                            //   if (tr.isMeetingTranscribe) {
                            //     showDiagnosis(tr?.diagnosisnote?._id);
                            //   }
                            // }}

                            onClick={() => {
                              if (tr?.isMeetingTranscribe) {
                                if (tr?.diagnosisNoteVersions && tr?.diagnosisNoteVersions?.length > 0) {
                                  setDiagnosisNoteWithVersionsData(tr);
                                } else {
                                  showDiagnosis(tr?.diagnosisnote, false);
                                }
                              }
                            }}
                            className="btn btn-secondary "
                            style={{ marginTop: "10px" }}
                          >
                            View / Edit Notes
                          </a>
                        )}

                        {!tr?.subTherapistId && tr?.meetingId?.callingStatus == "COMPLETED" && tr?.diagnosisnote?.updatedByTherapist == true && (
                          <button
                            className="btn btn-success s-h-btn ml-10"
                            onClick={() => handleOpenShareNote(tr)}
                          >
                            <i className="fa fa-share-alt f-16"></i>
                          </button>
                        )}

                      </CardBody>
                    )}
                  </Card>
                );
              })}
          </Container>
          {
            openShareModal && (
              <NoteShareTherapistModal showModal={openShareModal} setShowModal={setOpenShareModal} noteData={transcribeData} onShare={onShare} />
            )
          }
        </div>
      )}
    </React.Fragment>
  );
};

export default ClientSessionsView;
