import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { AuthFormType } from "src/models/AuthorizationFormData";
import PhoneInput from "react-phone-number-input";
import { HealthyBlueAuthForm } from "src/models/AuthorizationForm/healthy-blue-auth-form-model";
import { htmlToText } from "html-to-text";
import moment from "moment";

const HealthyBlueAuthFormPdf: React.FC<{ authorizationFormType: AuthFormType; authorizationFormData: HealthyBlueAuthForm; }> = props => {
  const authorizationFormType = props.authorizationFormType;
  const authorizationFormData = props.authorizationFormData;
  const [formData, setFormData] = useState<HealthyBlueAuthForm>();

  useEffect(() => {
    const updateHealthBlueData: HealthyBlueAuthForm = {
      generalInformation: authorizationFormData?.generalInformation,
      serviceInformation: authorizationFormData?.serviceInformation,
      providerInformation: authorizationFormData?.providerInformation,
      practitionerInformation: authorizationFormData?.practitionerInformation,
      authorization: authorizationFormData?.authorization,
      referenceNumber: authorizationFormData?.referenceNumber,
      signature: authorizationFormData?.signature,
    };
    setFormData(updateHealthBlueData);
  }, [authorizationFormData]);

  // const formatDate = (date: any) => {
  //   if (!date) return '';
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment.utc(date).format('YYYY-MM-DD');
  };

  const sharedStyle: React.CSSProperties = {
    fontSize: "11px",
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    lineHeight: '1'
  };

  const sharedStyleForTable: React.CSSProperties = {
    fontSize: "0.70rem",
    width: '100%',
    padding: '2px',
    border: 'none',
    resize: 'none',
  };

  function htmlToTextFunction(htmlTextMessage: string) {
    try {
      if (htmlTextMessage) {
        const finalText = htmlToText(htmlTextMessage);
        return finalText;
      }
      return "";
    } catch (error) {
      return "";
    }
  }

  return (
    <>
      {authorizationFormType === AuthFormType.HealthyBlueAuthForm && (
        <>
          <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
            <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
              <Row className="align-items-center pt-2">
                <Col xs="12" sm="5" className="text-center text-sm-start">
                  <div>
                    <img
                      src="/static/assets/img/authorization/healthy_blue_nc.png"
                      alt="Healthy Blue Insurance Company Logo"
                      className="img-fluid"
                      style={{ maxWidth: '300px' }}
                    />
                  </div>
                </Col>
                <Col xs="12" sm="7" className="text-center text-sm-end">
                  <p className="p-2" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                    <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request Form</span><br />
                  </p>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px" }}>
              <Row>
                <div className="card-bodyDiv" style={{ width: '100%' }}>

                  <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                    <p style={{ margin: "0 0 1rem 0" }}>
                      Prior authorization is the approval of medically necessary services for Healthy Blue members.
                      Prior authorization applies to the requested service(s) only and does not guarantee payment,
                      nor does it guarantee the amount billed will be the amount reimbursed. The member must be
                      Medicaid eligible and a Healthy Blue member on the date of service or date the equipment or
                      prosthesis is received by the member.
                    </p>
                    <p style={{ margin: "0" }}>
                      <span style={{ display: "block", marginBottom: "0.5rem" }}>
                        <strong>Return To :</strong>
                      </span>
                      <span style={{ marginLeft: "1rem" }}> • Inpatient: <strong>855-817-5788</strong></span><br />
                      <span style={{ marginLeft: "1rem" }}> • Outpatient: <strong>855-817-5788</strong></span><br />
                      <span style={{ marginLeft: "1rem" }}> • Behavioral Health Inpatient: <strong>844-439-3574</strong></span><br />
                      <span style={{ marginLeft: "1rem" }}> • Behavioral Health Outpatient:<strong>844-429-9636</strong></span><br />
                      <span style={{ marginLeft: "1rem" }}> • Nursing Facility, IP Rehab, LTACH Services: <strong>844-451-2694</strong></span><br />
                    </p>
                  </div>
                </div>
              </Row>
            </Card>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>General Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label> Member Name :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.generalInformation?.memberName ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="3" >
                          <label>Date of birth :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formatDate(formData?.generalInformation?.dateOfBirth)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Address (street, city, state, ZIP code) :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.generalInformation?.memberAddress ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.generalInformation?.phone ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  generalInformation: {
                                    ...formData?.generalInformation,
                                    phone: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <label>Medicaid ID/subscriber ID :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.generalInformation?.medicaidId ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">

                        <Col xs="12" sm="2">
                          <label>Diagnosis code :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.generalInformation?.diagnosisCode ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Diagnosis description :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                          <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.generalInformation?.diagnosisDescription ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="7">
                          <label>Name and address of facility where services are to be rendered, if other than home or office :</label>
                        </Col>
                        <Col xs="12" sm="5">
                          <div className="d-flex align-items-center">
                          <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.generalInformation?.facilityName ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Service Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <Table responsive className="clinical-table-bordered table-md ">
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Ref. NO
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Procedure code
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Place of Service
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              From
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                            <div className="d-flex align-items-center justify-content-center">
                              Through
                            </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}><div className="d-flex align-items-center justify-content-center">
                            Description of service/item
                          </div>
                          </th>
                          <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}> <div className="d-flex align-items-center justify-content-center">
                            Qty. or units
                          </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(10)].map((_, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid #B2B2B2", position: 'relative' }}>
                              <div style={{ position: 'absolute', top: '0', left: '0', padding: '4px', textAlign: 'center' }}>
                                ({index + 1})
                              </div>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1", paddingLeft: '30px' }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.referenceNumber ?? ''}
                              />
                            </td>
                            <td>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.procedureCode ?? ''}
                              />
                            </td>
                            <td>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.servicePlace ?? ''}
                              />
                            </td>
                            <td>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.startDate)}
                              />
                            </td>
                            <td>
                              <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.endDate)}
                              />
                            </td>
                            <td>
                              <textarea
                                style={sharedStyleForTable}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.serviceDescription ?? ''}
                              />
                            </td>
                            <td>
                              <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                readOnly
                                value={formData?.serviceInformation?.serviceInformationArray?.[index]?.itemQuantity ?? ''}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                      <div style={{ margin: "0.2rem" }}>
                        <h6 style={{ fontWeight: "600", display: "inline" }}>Detailed explanation of medical necessity for services, equipment, procedures or prostheses  </h6>
                        <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                          (Attach additional pages if necessary)
                        </span>
                      </div>
                      <textarea
                        style={sharedStyle}
                        value={htmlToTextFunction(formData?.serviceInformation?.necessityExplanation || "")}
                      />
                    </Card>

                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Provider</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Provider name :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.providerName ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Telephone :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.providerInformation?.phoneNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    phoneNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Address  :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.providerInformation?.providerAddress ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>NPI :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.providerInformation?.npi ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Fax number :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.providerInformation?.faxNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  providerInformation: {
                                    ...formData?.providerInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Practitioner</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Name :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.practitionerInformation?.providerName ?? ''}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Telephone :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.practitionerInformation?.phone ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  practitionerInformation: {
                                    ...formData?.practitionerInformation,
                                    phone: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Address  :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                          <textarea
                              style={sharedStyle}
                              readOnly
                              value={formData?.practitionerInformation?.providerAddress ?? ''}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Fax number :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.practitionerInformation?.faxNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  practitionerInformation: {
                                    ...formData?.practitionerInformation,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prior Authorization Request Form Completed By</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Contact Name :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              readOnly
                              value={formData?.authorization?.contactName ?? ''}
                            />
                          </div>
                        </Col>

                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label>Phone number :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.authorization?.phoneNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  authorization: {
                                    ...formData?.authorization,
                                    phoneNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Fax number :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              readOnly
                              value={formData?.authorization?.faxNumber ?? ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  authorization: {
                                    ...formData?.authorization,
                                    faxNumber: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>For Plan Use Only</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label>Denial Reason(s): Refer to table above by reference numbers (REF NO.) :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <textarea
                            style={sharedStyle}
                            readOnly
                            value={formData?.referenceNumber ?? ''}
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2" style={{ backgroundColor: "#F1F1F1", border: "none", borderRadius: "15px", padding: "10px" }}>
                        <Col xs="12" sm="3">
                          <label>IF APRROVED :<br />
                            Services Authorized to Begin</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex flex-column align-items-center">
                            <div className="sign">
                              {formData?.signature && (
                                <img className="sigCanvasNew" src={formData?.signature} />
                              )}
                            </div>
                            <label style={{ marginTop: "0.5rem" }}>Signature</label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default HealthyBlueAuthFormPdf;
