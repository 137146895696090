import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
const classNames = require("classnames");

import "react-perfect-scrollbar/dist/css/styles.css";
import UserContext from "../../../context/UserContext";

import Spinner from "../../../common/spinner/spinner";

import "react-date-picker/dist/DatePicker.css";
import "../Chat.scss";

import AvatarModeEnableModal from "../../Popup/AvatarModeEnableModal";
import GroupChatList from "./GroupChatListView";
import SelectedGroupChatView from "./SelectedGroupChatView";
import GroupChatContext from "src/context/GroupChatContext";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import { SessionsInSelectedChatGroupModel } from "src/models/GroupChat/SessionsInSelectedChatGroup";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";

window.Buffer = window.Buffer || require("buffer").Buffer;

export enum ReceiverType {
  USER = "user",
  GROUP = "group",
}

export enum MessageType {
  textMessage = "TEXT_MESSAGE",
  mediaMessage = "MEDIA_MESSAGE",
}

const GroupChatView: React.FC = () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [activeTab, setactiveTab] = useState("2");

  const [isLoading, setIsLoading] = useState(false);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const history = useHistory();

  const SCREEN_SIZE = 991;
  const limit = 20;

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (loggedUser?.incognitoPopupShow == false) {
      setShowPopup(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);
  const initialSelectedGroup: GroupChatModel = { _id: "", title: "", description: "", type: "", createdAt: "", createdBy: "" };
  const [allChatGroups, setAllChatGroups] = useState<GroupChatModel[]>([]);

  const [isGettingChatGroups, setIsGettingChatGroups] = useState(true);
  const [isGettingChatGroupsError, setIsGettingChatGroupsError] = useState(false);
  const [prevSelectedTabNo, setPrevSelectedTabNo] = useState("");

  // useEffect(() => {
  //   if (allChatGroups != null && allChatGroups.length != null && allChatGroups.length > 0) {
  //     setIsGettingChatGroups(false);
  //   } else {
  //     initialLoading();
  //   }
  // }, []);

  // useEffect(() => {
  //   setIsGettingChatGroups(false);
  // }, [prevSelectedTabNo]);

  // const initialLoading = async () => {
  //   console.log("initialLoading chat groups");
  //   setAllChatGroups([]);
  //   setSelectedGroupData(initialSelectedGroup);
  //   setSelectedGroupIdForShowMembers("");
  //   setSelectedGroupIdForShowRequests("");
  //   setIsGettingChatGroups(true);
  //   await getAllChatGroupsInitial();
  // };

  // const getAllChatGroupsInitial = async () => {
  //   console.log("getAllChatGroupsInitial");
  //   try {
  //     const pathname = location.pathname;
  //     const splited = pathname.split("/");
  //     const groupIdFromUrl = splited[2];
  //     const dataObject = { type: null, prevGroupId: groupIdFromUrl, limit: limit, offset: 0 };
  //     const res = await GroupChatService.getAllChatGroups(dataObject);
  //     if (res.success && res.data) {
  //       if (res.data != null) {
  //         setAllChatGroups(res?.data);
  //         try {
  //           const groupChatFromResponse: GroupChatModel[] = res?.data;
  //           if (groupIdFromUrl != null && groupChatFromResponse != null && groupChatFromResponse.length != null && groupChatFromResponse.length > 0) {
  //             const chatGroupData: GroupChatModel | undefined = groupChatFromResponse.find(grp => grp._id == groupIdFromUrl);
  //             if (chatGroupData != null && chatGroupData._id != null && chatGroupData._id == groupIdFromUrl) {
  //               setSelectedGroupData(chatGroupData);
  //               console.log("setSelectedGroupData");
  //               if (chatGroupData.type == "PUBLIC") {
  //                 setPrevSelectedTabNo("2");
  //               } else {
  //                 setPrevSelectedTabNo("1");
  //               }
  //             } else {
  //               history.push(`/group-chat`);
  //               setPrevSelectedTabNo("1");
  //             }
  //           } else {
  //             history.push(`/group-chat`);
  //             setPrevSelectedTabNo("1");
  //           }
  //         } catch (error) {
  //           history.push(`/group-chat`);
  //           setPrevSelectedTabNo("1");
  //         }
  //       } else {
  //         history.push(`/group-chat`);
  //         setPrevSelectedTabNo("1");
  //       }
  //     } else {
  //       history.push(`/group-chat`);
  //       setPrevSelectedTabNo("1");
  //       setIsGettingChatGroupsError(true);
  //       toast.error("Retrieving chat groups failed!", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         className: "foo-bar",
  //       });
  //     }
  //   } catch (error) {
  //     history.push(`/group-chat`);
  //     setPrevSelectedTabNo("1");
  //     setIsGettingChatGroupsError(true);
  //     toast.error("Retrieving chat groups failed!", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });
  //   }
  // };

  const leftNavBarComponent = () => (
    <>
      <AvatarModeEnableModal showModal={showPopup} setShowModal={setShowPopup} loggedUser={loggedUser} setLoggedUser={setLoggedUser} />
      <Nav pills justified>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "1",
            })}
            onClick={() => {
              history.push(`/chat`);
            }}
          >
            <i className="bx bx-group font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">Messages</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "2",
            })}
            onClick={() => {}}
          >
            <i className="bx bx-group font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">Groups</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId="1">
          <div></div>
        </TabPane>

        <TabPane tabId="2">
          {activeTab == "2" ? (
            <GroupChatList
              allChatGroups={allChatGroups}
              setAllChatGroups={setAllChatGroups}
              isGettingChatGroupsFrom={isGettingChatGroups}
              isGettingChatGroupsErrorFrom={isGettingChatGroupsError}
              prevSelectedTabNoFrom={prevSelectedTabNo}
            ></GroupChatList>
          ) : (
            <div></div>
          )}
        </TabPane>
      </TabContent>
    </>
  );

  return (
    <React.Fragment>
      {!isLoading ? (
        <>
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div className="">
                    <div className="chat-leftsidebar-nav">
                      <div
                        className={
                          screenSize > SCREEN_SIZE ||
                          (screenSize <= SCREEN_SIZE && (selectedGroupData == null || selectedGroupData._id == null || selectedGroupData._id == ""))
                            ? ""
                            : "d-none"
                        }
                      >
                        {leftNavBarComponent()}
                      </div>
                    </div>
                  </div>
                </div>

                {(screenSize > SCREEN_SIZE ||
                  (screenSize <= SCREEN_SIZE && selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "")) && (
                  <SelectedGroupChatView allChatGroups={allChatGroups} setAllChatGroups={setAllChatGroups}></SelectedGroupChatView>
                )}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Spinner />
      )}
    </React.Fragment>
  );
};

GroupChatView.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

export default GroupChatView;
