import React, { useContext } from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";
import UserContext from "../../context/UserContext";

const Modal4: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void }> = props => {
  const [user] = useContext(UserContext);

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>

          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-warning text-danger"></i>
            </div>

            {
              user?.role == "CLIENT" ? (
                <CardTitle className="mb-4 text-center font-size-20">Please subscribe to access this feature.</CardTitle>
              ) : (
                <CardTitle className="mb-4 text-center font-size-20">This client doesn&lsquo;t have an active subscription.</CardTitle>
              )
            }
            
            <div className="flex justify-content-center">
              <button type="button" onClick={() => {
                props.setShowModal(false);
              }} className="btn btn-primary">
                Close
              </button>
            </div>
          </CardBody>

          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal4;
