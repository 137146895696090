import React, { useEffect, useState } from "react";
import { TherapistService } from "../../../services/TherapistService";
import { AuthFormType, AuthorizationFormData } from "../../../models/AuthorizationFormData";
import { toast } from "react-toastify";
import AmeriHealthModal from "../../Popup/AmeriHealthModal";
import CarolinaCompleteHealthModal from "../../Popup/CarolinaCompleteHealthModal";
import UnitedHealthCareModal from "../../Popup/UnitedHealthCareModal";
import HealthBlueModal from "../../Popup/HealthBlueModal";
import WellCareModal from "../../Popup/WellCareModal";
import AmbetterModal from "../../Popup/AmbetterModal";
import AuthorizationFormPdfModal from "../../Popup/AuthorizationFormPdfModal";
import Spinner from "../../../common/spinner/spinner";

interface PreAuthorizationSaasProps {
  clientId: string;
}

const PreAuthorizationSaas: React.FC<PreAuthorizationSaasProps> = ({ clientId }) => {
  const [insuranceCompanyId, setInsuranceCompanyId] = useState("");

  const [showAmeriHealthModal, setshowAmeriHealthModal] = useState<boolean>(false);
  const [showCarolinaCompleteHealthModal, setshowCarolinaCompleteHealthModal] = useState<boolean>(false);
  const [showUnitedHealthCareModal, setshowUnitedHealthCareModal] = useState<boolean>(false);
  const [showHealthBlueModal, setshowHealthBlueModal] = useState<boolean>(false);
  const [showWellCareModal, setshowWellCareModal] = useState<boolean>(false);
  const [showAmbetterModal, setshowAmbetterModal] = useState<boolean>(false);
  const eventType = "DOWNLOAD";
  const [isLoading, setIsLoading] = useState(false);

  const [isAvailableInsurance, setIsAvailableInsurance] = useState(false);
  const [authorizationFormData, setAuthorizationFormData] = useState<AuthorizationFormData[]>([]);
  const [isMobile, setIsMobile] = useState(false);

  const [authorizationFormType, setAuthorizationFormType] = useState("");
  const [showAuthorizationFormPdfModal, setShowAuthorizationFormPdfModal] = useState<boolean>(false);

  const generateAuthorizationFormPdf = (relatedInsuranceCompanyId: any, formType: string) => {
    if (relatedInsuranceCompanyId && formType) {
      setInsuranceCompanyId(relatedInsuranceCompanyId);
      setAuthorizationFormType(formType);
      setShowAuthorizationFormPdfModal(true);
    }
  };

  const ameriHealthAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowAmeriHealthModal(true);
  };
  const carolinaCompleteAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowCarolinaCompleteHealthModal(true);
  };
  const unitedHealthCareAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowUnitedHealthCareModal(true);
  };
  const healthBlueAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowHealthBlueModal(true);
  };
  const wellCareAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowWellCareModal(true);
  };
  const ambetterAuthorizationRequestForm = (relatedInsuranceCompanyId: any) => {
    setInsuranceCompanyId(relatedInsuranceCompanyId);
    setshowAmbetterModal(true);
  };
  const formOptions = {
    AmeriHealthAuthForm: {
      baseLabel: "Ameri Health Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => ameriHealthAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    WellcareAuthForm: {
      baseLabel: "Well Care Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => wellCareAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    UnitedHealthCareAuthForm: {
      baseLabel: "United Health Care Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => unitedHealthCareAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    HealthyBlueAuthForm: {
      baseLabel: "Health Blue Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => healthBlueAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    CarolinaCompleteHealthAuthForm: {
      baseLabel: "Carolina Complete Health Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => carolinaCompleteAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
    AmbetterAuthForm: {
      baseLabel: "Ambetter Authorization Form",
      viewHandler: (relatedInsuranceCompanyId: any) => ambetterAuthorizationRequestForm(relatedInsuranceCompanyId),
      downloadHandler: (relatedInsuranceCompanyId: any, formType: string) => generateAuthorizationFormPdf(relatedInsuranceCompanyId, formType),
    },
  };

  const getInsuranceByClientId = async (clientId: string) => {
    setIsLoading(true);
    try {
      const res = await TherapistService.getInsuranceByClientIdViaTherapist(clientId);

      if (res && res?.success) {
        if (res.data.length>0) {
          setIsAvailableInsurance(true);
          setAuthorizationFormData(res?.data);
          setIsLoading(false);
        } else {
          setIsAvailableInsurance(false);
          setAuthorizationFormData([]);
          setIsLoading(false);
        }
      } else {
        toast.error("Something went wrong when retrieving prior authorization data!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false);
    }
  };

  const availableFormTypes = authorizationFormData.map(item => ({
    type: item.insuranceCompanyId.authorizationFormType,
    id: item.insuranceCompanyId._id,
    isPrimary: item.insuranceCompanyId.isPrimary,
  }));

  useEffect(() => {
    getInsuranceByClientId(clientId);
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);
  return (
    <div style={{ maxWidth: "100%", padding: "10px" }}>
      {showAuthorizationFormPdfModal && (
        <AuthorizationFormPdfModal
          setShowModal={setShowAuthorizationFormPdfModal}
          showModal={showAuthorizationFormPdfModal}
          identifierId={clientId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          authorizationFormType={authorizationFormType as AuthFormType}
          insuranceCompanyId={insuranceCompanyId}
        />
      )}
      {showAuthorizationFormPdfModal && (
        <AuthorizationFormPdfModal
          setShowModal={setShowAuthorizationFormPdfModal}
          showModal={showAuthorizationFormPdfModal}
          identifierId={clientId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          authorizationFormType={authorizationFormType as AuthFormType}
          insuranceCompanyId={insuranceCompanyId}
        />
      )}

      {showAmeriHealthModal && (
        <AmeriHealthModal
          setShowModal={setshowAmeriHealthModal}
          showModal={showAmeriHealthModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showCarolinaCompleteHealthModal && (
        <CarolinaCompleteHealthModal
          setShowModal={setshowCarolinaCompleteHealthModal}
          showModal={showCarolinaCompleteHealthModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showUnitedHealthCareModal && (
        <UnitedHealthCareModal
          setShowModal={setshowUnitedHealthCareModal}
          showModal={showUnitedHealthCareModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showHealthBlueModal && (
        <HealthBlueModal
          setShowModal={setshowHealthBlueModal}
          showModal={showHealthBlueModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showWellCareModal && (
        <WellCareModal
          setShowModal={setshowWellCareModal}
          showModal={showWellCareModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showAmbetterModal && (
        <AmbetterModal
          setShowModal={setshowAmbetterModal}
          showModal={showAmbetterModal}
          identifierId={clientId}
          isFromMeeting={false}
          insuranceCompanyId={insuranceCompanyId}
          isAdmin={false}
        />
      )}
      {showAuthorizationFormPdfModal && (
        <AuthorizationFormPdfModal
          setShowModal={setShowAuthorizationFormPdfModal}
          showModal={showAuthorizationFormPdfModal}
          identifierId={clientId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          authorizationFormType={authorizationFormType as AuthFormType}
          insuranceCompanyId={insuranceCompanyId}
        />
      )}
      {
        (window.location.pathname !== "/saas-view-all-clients") && (
          <h3 style={{ color: "#f27e0c", marginTop: "3px" }}>Prior Authorization</h3>
        )
      }
      {isLoading ? (
        <div className={"d-flex justify-content-center align-items-center"} style={{minHeight:"25vh",width:"100%",position:"relative"}}>
          <Spinner className={"bouncer"} />
        </div>
      ) : (
        <div>
          {isAvailableInsurance ? (
            <div>
              {availableFormTypes.map(({ type, id, isPrimary }) => {
                const options = formOptions[type as keyof typeof formOptions];
                if (!options) return null;

                const insuranceLabel = isPrimary ? " (Primary Insurance)" : " (Secondary Insurance)";
                const viewLabel = `Open ${options.baseLabel}${insuranceLabel}`;
                const downloadLabel = `Download ${options.baseLabel}${insuranceLabel}`;

                return (
                  <div key={id} className={"card"}>
                    <div className={"card-body"}>
                      <div className={"row"}>
                        <p className={"fs-5"} style={{ color: "grey" }}>
                          {isPrimary ? "Primary Insurance" : "Secondary Insurance"}
                        </p>
                      </div>
                      <div className={"row"}>
                        <div className={"col-8"}>
                          <p className={"fs-3"} style={{ color: "primary" }}>
                            {type.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
                              return str.toUpperCase();
                            })}
                          </p>
                        </div>
                        <div className="col-4 d-flex flex-row justify-content-start">
                          <button className="btn" style={{ backgroundColor: "rgb(242, 126, 12)", color: "white" }}
                                  onClick={() => options.viewHandler(id)}>
                            <i className="fa fa-file"></i> &nbsp;View
                          </button>
                          {!isMobile && (
                            <button className="btn btn-secondary ms-1"
                                    onClick={() => options.downloadHandler(id, type)}>
                              <i className="fa fa-download"></i> &nbsp;Download
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={"mt-3"}>
              <p className="text-center text-muted font-italic">No prior authorization available.</p>
            </div>
          )}
        </div>)}

    </div>
  );
};

export default PreAuthorizationSaas;