import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { toast } from "react-toastify";
import { AdminService } from "src/services/AdminService";
import Swal from "sweetalert2";
import moment from "moment";
interface TokenData {
  appointmentAuthToken: string;
  appointmentAuthTokenExpiredAt: string;
}

const AuthTokenSettings: React.FC = () => {
  const [authToken, setAuthToken] = useState<TokenData>({
    appointmentAuthToken: "Not available",
    appointmentAuthTokenExpiredAt: "Not available",
  });

  useEffect(() => {
    getAppointmentAuthTokenByAdmin();
  }, []);

  const getAppointmentAuthTokenByAdmin = () => {
    AdminService.getAppointmentAuthTokenByAdmin().then(res => {
      if (res && res?.success && res?.data) {
        setAuthToken(res.data);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      }
    });
  }

  const generateToken = () => {
    Swal.fire({
      icon: "info",
      title: "Select Token Validity Period.",
      text: "Note that: Generating a new token will deactivate the previously generated token.",
      input: "select",
      inputOptions: {
        "1": "1 Day",
        "7": "7 Days",
        "30": "30 Days",
      },
      inputPlaceholder: "Select a validity period",
      showCancelButton: true,
      confirmButtonText: "Generate Token",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#f46a6a",
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const data = {
          validityPeriod: result.value
        };

        AdminService.generateAppointmentAuthTokenByAdmin(data).then((res) => {
          if (res && res?.success && res?.data) {
            setAuthToken(res.data);
            toast.success("Auth token successfully generated!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };

  const copyToClipboard = (authToken: string) => {
    const token = authToken;
    navigator.clipboard
      .writeText(token)
      .then(() => {
        toast.success("Token copied to clipboard! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      })
      .catch(() => {
        toast.error("Token is not copied try again! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
  };

  return (
    <React.Fragment>
      <Card className="overflow-auto">
        <CardBody>
          <CardTitle className="mb-4">Appointment Auth Token Management</CardTitle>
          <div className="auth-token-section">
            <div style={{ marginBottom: "15px" }}>
              <p>
              <strong>Current Token:</strong>{" "}{authToken?.appointmentAuthToken ? `${authToken.appointmentAuthToken.slice(0, 25)}....` : "Not yet generated"}&nbsp;
                {authToken?.appointmentAuthToken && (
                  <button
                    onClick={() => copyToClipboard(authToken.appointmentAuthToken)}
                    title="Copy token to clipboard"
                  >
                    <i className="mdi mdi-content-copy"></i>
                  </button>
                )}
              </p>
            </div>

            <div style={{ marginBottom: "15px" }}>
              <p>
                <strong>Token Expiration Date:</strong>{" "}{authToken?.appointmentAuthTokenExpiredAt ? moment.utc(authToken.appointmentAuthTokenExpiredAt).format("YYYY-MM-DD HH:mm:ss A") : "Not yet generated"}
              </p>
            </div>

            <Button className="btn-sm" color="primary" onClick={generateToken}>
              Generate New Auth Token
            </Button>
          </div>
        </CardBody>
      </Card>

    </React.Fragment>
  );
};

export default AuthTokenSettings;