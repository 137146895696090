import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, Table } from "reactstrap";
import moment from "moment";
import { TherapyPlanSinglePage } from "src/models/therapyPlan/therapyPlan";
import { DiagnosisModel, treatmentGoalStatusCode, TreatmentSessionModel } from "src/models/therapyPlan/therapyPlanSubModel";
import SignaturePad from "react-signature-canvas";
import { CommonService } from "src/services/CommonService";
import { FormVersionService } from "src/services/FormVersionService";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Close from "../../assets/images/icons/u_times-circle.png";
import Select from "react-select";
import options from "./../Chat/DiagnosisNote/Dataset/options";
import { da } from "date-fns/locale";

const FormOne = (props: any) => {
  const { formId, getUpdateFormId, clientId, therapyPlanData, isVersionCreated, setIsVersionCreated } = props;
  const [isUpdate, setIsUpdate] = useState(props.isUpdate);
  const [isClientSigned, setIsClientSigned] = useState<boolean>();
  const [sendSignatureEmail, setSendSignatureEmail] = useState(false);
  const [clientSignature, setClientSignature] = useState<boolean>();
  const [lrpSignature, setLrpSignature] = useState<boolean>();
  const [clinicianSignature, setClinicianSignature] = useState<boolean>();
  const [clinicianTwoSignature, setClinicianTwoSignature] = useState<boolean>();
  const [editsignature, seteditSignature] = useState<boolean>(false);
  const [editsignature2, seteditSignature2] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(true);
  const [formData, setFormData] = useState<TherapyPlanSinglePage>({
    diagnosis: [{}],
    treatmentSession: [{}],
    treatmentSession2: [{}],
    treatmentSession3: [{}],
  });
  const [clientName, setClientName] = useState("Client");
  const [clientName2, setClientName2] = useState("Client2");
  const [clientName3, setClientName3] = useState("Client3");
  const [therapistName, setTherapistName] = useState("Therapist");
  const [therapistCategorizationByType, setTherapistCategorizationByType] = useState("");

  const [serviceInterventionFrequency, setServiceInterventionFrequency] = useState("");
  const [serviceInterventionFrequency2, setServiceInterventionFrequency2] = useState("");
  const [serviceInterventionFrequency3, setServiceInterventionFrequency3] = useState("");

  const [progressIdentifier1, setProgressIdentifier1] = useState("");
  const [progressIdentifier2, setProgressIdentifier2] = useState("");
  const [progressIdentifier3, setProgressIdentifier3] = useState("");

  const [primaryDiagnosisICD10CodesError, setprimaryDiagnosisICD10CodesError] = useState<boolean>(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);

  const [dropdownTherapyTypeOpen, setDropdownTherapyTypeOpen] = useState(false);
  const [dropdownTherapyTypeOpen2, setDropdownTherapyTypeOpen2] = useState(false);
  const [dropdownTherapyTypeOpen3, setDropdownTherapyTypeOpen3] = useState(false);

  const [nestedDropdownOpen, setNestedDropdownOpen] = useState<string>("");
  const [nestedDropdownOpen2, setNestedDropdownOpen2] = useState<string>("");
  const [nestedDropdownOpen3, setNestedDropdownOpen3] = useState<string>("");

  const toggleMain = () => setDropdownOpen(!dropdownOpen);
  const toggleMain2 = () => setDropdownOpen2(!dropdownOpen2);
  const toggleMain3 = () => setDropdownOpen3(!dropdownOpen3);

  const toggledropdownTherapyType = () => setDropdownTherapyTypeOpen(!dropdownTherapyTypeOpen);
  const toggledropdownTherapyType2 = () => setDropdownTherapyTypeOpen2(!dropdownTherapyTypeOpen2);
  const toggledropdownTherapyType3 = () => setDropdownTherapyTypeOpen3(!dropdownTherapyTypeOpen3);

  type GoalInformationType = {
    [key: string]: { name: string; items: string[] };
  };
  const goalInformation: GoalInformationType = {
    AbuseNeglect: {
      name: "Abuse & Neglect",
      items: [
        "Share details of the abuse/neglect with therapist as able to do so",
        "Learn about typical long term/residual effects of traumatic life experiences",
        "Develop at least two strategies to help cope with stressful reminders/memories",
      ],
    },
    AlcoholDrugsAndAddictions: {
      name: "Alcohol, Drugs, and Addictions",
      items: [
        "Learn/Identify at least 5 triggers for alcohol and/or drug use",
        "Identify one alternative to use/coping skill for each trigger",
        "Avoid people, places and situations where temptation might be overwhelming",
        "Explore dynamics relating to being the [child/husband/wife] of a person with SUD and discuss them weekly at support group meetings",
        "Reach ____ days/months/years substance-free status/time",
        "Reduction in AUDIT score from __ to ___ (ALCOHOL)",
        "Reduction in DAST-10 score from __ to __ (DRUGS)",
        "Reduced ASAM score",
        "Attend at least 2 AA/NA meeting per week",
        "(ALL OUD) Will receive psycho education related to overdose and medication assisted treatment",
        "Achieve a 30-day span of negative urine drug screens",
      ],
    },
    Anger: {
      name: "Anger",
      items: [
        "Learn to identify at least 2 occasions during which alternative strong emotions other than anger are being experienced",
        "Verbally express strong emotions",
        "When unable to verbally express strong emotions, walk away from situations",
        "Be free of tantrums/explosive episodes",
        "Learn two positive emotional regulation/anger management skills",
        "Learn assertive communication skills",
        "Learn to use the 'I feel ____, when ______' assertive communication technique",
        "Be able to express anger without destroying property or personal belongings",
        "Be able to express anger without yelling and using foul language",
        "Identify at least 3 triggers for angry response",
        "On a personal scale of 1-10, identify physical bodily reactions that occur at each point on the scale",
      ],
    },
    Anxiety: {
      name: "Anxiety",
      items: [
        "Be free of panic episodes/attacks for 4 consecutive weeks",
        "Panic attacks reduced from ___ to ___ times per week",
        "Identify the top five anxiety-provoking situations",
        "Create and rehearse a coping-skills plan for the top 5 anxiety provoking situations",
        "Learn two new ways of coping with routine stressors ",
        "Report feeling more positive about self and abilities during therapy sessions",
        "Develop strategies for thought distraction when fixating on the future",
        "Identify physical symptoms of anxiety",
        "Identify ways to resolve physical effects of anxiety",
        "Learn 3 mindfulness skills",
        "Decrease GAD-7 from ___ to ___ ",
      ],
    },
    BehaviorProblems: {
      name: "Behavior Problems",
      items: [
        "Be free of _____ behavior",
        "Learn two ways to manage frustration in a positive manner",
        "Share two positive experiences each week",
        "Stay free of fights",
        "Stay free of drug & alcohol use and abuse (100%)",
        "Be free of violent behavior",
        "Be able to express anger in a productive manner without destroying property or personal belongings",
        "Be free of threats to self and others",
        "Complete daily tasks (e.g. chores, pet care, self-care, etc.)",
        "Identify behavior that would result in a loss of custody",
        "Be free of behaviors that could result in custody loss",
        "Come home each day by ______ (time)",
        "Be in bed by _____ each night",
        "Identify behaviors that would result in job loss",
        "Be free of any behavior that could result in loss of job",
        "Remain free of behaviors which would lead to arrest",
        "Comply with all aspects of probation/parole and avoid behavior that could violate",
      ],
    },
    CommunicationSkills: {
      name: "Communication Skills",
      items: [
        "Learn three ways to communicate verbally when angry",
        "Avoid property destruction",
        "Avoid using foul language/cursing",
        "Express wants and needs through spoken language",
        "Speak assertively as evidenced by use of non-aggressive language and 'I-statements'",
        "Express feelings verbally without acting out ",
      ],
    },
    Crime: {
      name: "Crime",
      items: [
        "Keep working and comply with all aspects of probation",
        "Be able to express anger in a productive manner without destroying property or personal belongings",
        "Be free of threats to self and others",
        "Comply with all aspects of probation/parole and avoid behavior that could violate",
        "Be free of violent behavior",
        "Stay free of drug & alcohol use and abuse (100%)",
      ],
    },
    DecisionMaking: {
      name: "Decision Making",
      items: [
        "Make short and simple 'to do' lists and complete three tasks each day",
        "Celebrate little successes each day using positive self-talk and/or journaling",
        "Be able to weigh options and make simple decisions within 5 minutes",
        "List three options for any major decisions and then discuss with team or family",
      ],
    },
    Depression: {
      name: "Depression",
      items: [
        "Be free of suicidal thoughts",
        "Call crisis hotline if having suicidal thoughts",
        "Report feeling more positive about self and abilities",
        "Patient will identify two coping skills related to (specific stressor)",
        "Patient will report at least six hours of restful sleep each night",
        "Patient will eat at least two out of three meals a day to gain weight",
        "Avoid napping/sleeping to escape other people and activities",
        "Shower, dress, and then do something every day",
        "Report feeling happy/better overall mood",
        "Make short and simple 'to do' lists and complete three tasks each day",
        "Celebrate little successes each day using positive self-talk and/or journaling",
        "Get through a day/week without a crying spell",
        "Develop strategies for thought distraction when ruminating on the past",
        "Exercise at least once per week",
        "Decrease PHQ-9 from ____ to _____",
        "Healthy meal at least once per day",
        "Score 20 or below on the Beck for 5 consecutive trials",
        "Identify 3 coping skills for depression management",
        "At least 2 social contacts per week per month",
      ],
    },
    EatingDisorders: {
      name: "Eating Disorders",
      items: [
        "Eat a balanced diet of foods and maintain good overall health",
        "Gain ____ pounds ",
        "Lose ____ pounds",
        "Be free of binge eating/purging",
        "Remove junk foods from home and limit future purchases",
        "Recognize/list environmental and situational triggers and develop alternative behaviors for coping with them",
        "Recognize emotional triggers and develop alternative ways of strategies for meeting emotional needs",
      ],
    },
    ExpressionOfFeelingsWantsAndNeeds: {
      name: "Expression of Feelings, Wants, and Needs",
      items: [
        "Share at least two positive experiences each week in which client is proud of how he/she has behaved",
        "Gain knowledge of different feelings",
        "Express feelings verbally without acting out",
      ],
    },
    FamilyConflict: {
      name: "Family Conflict",
      items: [
        "Recognize patterns of family conflict and discuss weekly in therapy",
        "Avoid angry outbursts by walking away from stressful situations",
        "Get through X days out of 7 without fighting with spouse",
        "Be able to live together peacefully, free of all angry physical contact",
        "Learn three ways to communicate verbally when angry",
        "Be able to express anger without yelling and using foul language",
        "Explore and resolve conflict with ____",
        "Be able to stick up for self assertively, not aggressively",
        "Get through a whole week without fighting with ____",
        "Speak in a clear and concise manner so others fully understand him/her",
        "Learn to express feelings verbally without acting out",
      ],
    },
    GriefAndLoss: {
      name: "Grief and Loss",
      items: [
        "Give sorrow words - discuss issues of grief weekly with therapist",
        "Continue to explore and resolve issues of grief/loss as they arise",
        "Get through a week without a crying spell",
        "Learn about the typical 2-7 year process of grieving the loss of a loved one",
        "Explore spirituality and the role it plays in redefining views about the meaning and purpose of life ",
        "Create (write/draw) a soul sketch of the deceased loved one",
        "Plan a memorial service for the anniversary of the loss",
        "Develop appropriate rituals to remember and honor _____ ",
      ],
    },
    HarmToSelfOrOthers: {
      name: "Harm to Self or Others",
      items: [
        "Learn two ways to manage frustration in a positive manner",
        "Explore triggers of thoughts to harm self or others",
        "Call crisis hotline when needed",
        "Report feeling more positive about self and abilities",
        "Explore and resolve stress from ____",
        "Develop a crisis plan and share it with key people",
        "Remove weapons from the home [and other means]",
        "List three emergency contacts who will be able to stay with you till a crisis passes",
      ],
    },
    HealthIssues: {
      name: "Health Issues",
      items: [
        "Learn at least 3 facts about the condition(s)",
        "Learn at least 1 evidenced-based treatment for condition",
        "Take medications/treatments as prescribed on a daily basis",
        "Attend all scheduled appointments with the doctor",
        "Maintain good overall physical health and healthcare practices",
        "Report any medication concerns to the prescribing doctor ASAP",
        "Seek additional advocacy services from _____",
        "Seek additional support from _____",
      ],
    },
    MedicationManagement: {
      name: "Medication Management",
      items: [
        "Take medications as prescribed on a daily basis",
        "Attend all scheduled appointments with the psychiatrist",
        "Maintain good overall physical health and healthcare practices",
        "Report any medication concerns to the prescribing doctor ASAP",
      ],
    },
    MoodManagement: {
      name: "Mood Management",
      items: [
        "Learn two ways to manage frustration in a positive manner",
        "Be free of suicidal thoughts; ",
        "Call crisis hotline if having suicidal thoughts",
        "Report feeling more positive about self and abilities",
        "Report feeling happy/better mood (4 days out of 7)",
        "Get 7-8 hours of restful sleep every night",
        "Get through a week without a crying spell",
      ],
    },
    Parenting: {
      name: "Parenting",
      items: [
        "Set two limits and stick with a plan that will require more responsible behavior",
        "Focus on positive behavior and give attention then, rather than focus on negative things",
        "Learn and be able to effectively use transactional analysis to stay in 'adult' mode ",
        "Use 'I' statements rather than You' when communicating with _____",
        "Develop and consistently use a behavior modification plan, to increase/eliminate _____ ",
      ],
    },
    PersonalHygieneAndSelfCare: {
      name: "Personal Hygiene and Self-Care",
      items: [
        "Brush teeth ____ times each day and floss _____",
        "Shower (take a bath) every day",
        "Use antiperspirant / deodorant every day after showering",
        "Brush/comb hair every morning",
      ],
    },
    PhysicalHealthIssues: {
      name: "Physical Health Issues",
      items: [
        "Explore and resolve thoughts and feelings that arise as a result of medical conditions and medications",
        "Learn two new strategies for coping with the above thoughts and feelings",
        "Reduce weight by _____  pounds (5%)",
        "Exercise for 20 minutes __ days per week",
        "Learn strategies to advocate for him/herself with medical personnel",
        "Quit smoking (or drinking)",
        "Take medications as prescribed on a daily basis",
        "Attend all scheduled appointments with physicians",
        "Maintain good overall physical health and healthcare practices",
        "Report any medication concerns to the doctor ASAP",
        "Make and keep an appointment with _____ (dentist) for needed diagnosis and treatment",
      ],
    },
    Relationships: {
      name: "Relationships",
      items: [
        "Avoid angry outbursts by walking away from stressful situations",
        "Be free of affairs ",
        "Be able to live together peacefully, free of all angry physical contact",
        "Learn three ways to communicate verbally when angry",
        "Explore peer and dating relationships to improve X's chance of staying safe and legal",
        "Be able to keep hands to self",
        "Be able to express anger without yelling and using foul language",
        "Explore and resolve conflict with ____",
        "Be able to stick up for self assertively",
        "Be respectful of parents/don't talk back",
        "Get through a whole week without fighting with ____",
        "Speak in a clear and concise manner so others fully understand him/her",
        "Learn to express feelings verbally without acting out",
        "Associate with healthy people and continue to make new friends",
        "Continue to explore relationship issues and slowly see new opportunities for dating",
        "Figure out why relationships fail and better plan for finding next partner",
        "Associate with people outside of work and make one or two new friends",
      ],
    },
    SelfImage: {
      name: "Self-Image",
      items: [
        "Discuss life events that led to and/or reinforce a negative self-image during weekly therapy",
        "Use positive self-talk daily",
        "Exercise daily (or _____ times per week)",
        "Drop _____ pounds (5%)",
        "Report feeling more positive about self and abilities",
        "Return to school and work on getting _____ (degree/diploma/GED)",
        "Change jobs to one that…(offers more pay and/or better suits skill set)",
        "Openly discuss issues relating to sexuality and become comfortable with sexual identity ",
        "Explore spirituality and the role it plays in the meaning and purpose of life",
        "Engage in volunteer work and/or other meaningful activity at least three hours each week",
      ],
    },
    SleepProblems: {
      name: "Sleep Problems",
      items: [
        "Limit consumption of food and drinks before bed",
        "Limit intake of caffeine (coffee, tea, soda) and chocolate after _____ (noon meal)",
        "Cut back on things that may impede normal sleep patterns (e.g., alcohol and some medications)",
        "Be in bed by _____ each night",
        "Have 30 minutes of quiet time before going to bed each night (e.g., read, meditate)",
        "Avoid overly stimulating shows/movies/video games before bedtime",
        "Avoid watching TV and chatting on the phone while in bed ",
        "If not asleep in 20 minutes, get up and do something for a bit, rather than try to force sleep",
        "Leave a paper and pen to write worries down instead of ruminating on them",
        "Learn best practices for sleep (cooler room, limit caffeine, calming time before bed)",
        "Listen to relaxation/meditation music to aid falling asleep",
      ],
    },
    SocialSkills: {
      name: "Social Skills",
      items: [
        "Speak in a clear and concise way so others fully understand him/her",
        "Learn to express feelings verbally without acting out",
        "Spend two hours engaging with peers each week",
        "Attend social functions at least once ______ (weekly, monthly)",
      ],
    },
    Stress: {
      name: "Stress",
      items: [
        "Assess personal risk traits and resiliency traits and discuss the role each plays in coping with daily stresses during the time between therapy sessions",
        "Learn two ways to manage frustration in a positive manner",
        "Get 7-8 hours of restful sleep every night",
        "Talk out routine stress events during weekly therapy sessions",
        "Explore and resolve residual stress from ____ (e.g., years as a first responder)",
        "Foster two new activities/interests that will help mitigate stress",
        "Exercise 20-30 minutes per day",
        "Learn and use meditation and relaxation techniques daily",
      ],
    },
    Suicide: {
      name: "Suicide",
      items: [
        "Explore and resolve stress from ____",
        "Call crisis hotline if having suicidal thoughts",
        "Develop a crisis plan and share it with key people",
        "Remove weapons from the home [and other means]",
        "List three emergency contacts who will be able to stay with you till a crisis passes",
      ],
    },
    ThoughtDisorder: {
      name: "Thought Disorder",
      items: [
        "Be free of false perceptions and [see/hear/smell/feel] things as others do",
        "Be free of false beliefs ",
        "Be free of thoughts that others are out to get you",
        "Spend 2-3 hours each week visiting with others",
        "Visit the clubhouse and/or the consumer drop-in-center each week",
        "Report feeling comfortable spending time with others",
        "Decrease BPRS score from ___ to ____",
      ],
    },
    Trauma: {
      name: "Trauma",
      items: [
        "Explore and resolve residual stress from ____ (e.g., years as a first responder)",
        "Share details of the trauma with therapist, as able to do so",
        "Reframe negative perceptions, when possible, and focus on finding meaning and drawing strength from the event",
        "Learn about typical long term/residual effects of traumatic life experiences",
        "Explore spirituality and the role it plays in life after traumatic events",
        "Learn about the typical 2-7 year process of rebuilding life after trauma",
        "Decrease PCL-5 score from ____ to _____",
      ],
    },
    VocationalEducational: {
      name: "Vocational / Educational",
      items: [
        "Earn G.E.D. ",
        "Explore options for returning to school/training",
        "Become an active member of a local clubhouse ",
        "Complete college/technical school",
        "Develop a resume",
        "Identify two people who will serve as references",
        "Be free of any behavior that could result in loss of job/educational grants",
        "Find and settle into a new job ",
      ],
    },
  };

  useEffect(() => {
    setIsUpdate(props.isUpdate);
  }, [props.isUpdate]);

  useEffect(() => {
    if (therapyPlanData) {
      const updateFormOneData: TherapyPlanSinglePage = {
        planCreationDate: therapyPlanData?.planCreationDate,
        creationDate: therapyPlanData?.creationDate,
        diagnosis: therapyPlanData?.diagnosis.map((diagnosisModel: DiagnosisModel) => ({
          description: diagnosisModel?.description,
        })),
        goalDate: therapyPlanData?.goalDate,
        goalInformation: therapyPlanData?.goalInformation,
        treatmentSession: therapyPlanData?.treatmentSession.map((treatmentSessionModel: TreatmentSessionModel) => ({
          targetDate: treatmentSessionModel?.targetDate,
          reviewDate: treatmentSessionModel?.reviewDate,
          statusCode: treatmentSessionModel?.statusCode,
          notes: treatmentSessionModel?.notes,
        })),
        goalDate2: therapyPlanData?.goalDate2,
        goalInformation2: therapyPlanData?.goalInformation2,
        treatmentSession2: therapyPlanData?.treatmentSession2.map((treatmentSessionModel: TreatmentSessionModel) => ({
          targetDate: treatmentSessionModel?.targetDate,
          reviewDate: treatmentSessionModel?.reviewDate,
          statusCode: treatmentSessionModel?.statusCode,
          notes: treatmentSessionModel?.notes,
        })),
        goalDate3: therapyPlanData?.goalDate3,
        goalInformation3: therapyPlanData?.goalInformation3,
        treatmentSession3: therapyPlanData?.treatmentSession3.map((treatmentSessionModel: TreatmentSessionModel) => ({
          targetDate: treatmentSessionModel?.targetDate,
          reviewDate: treatmentSessionModel?.reviewDate,
          statusCode: treatmentSessionModel?.statusCode,
          notes: treatmentSessionModel?.notes,
        })),
        clientSignatureDetails: therapyPlanData?.clientSignatureDetails,
        clientSignature: therapyPlanData?.clientSignature,
        lrpSignatureDetails: therapyPlanData?.lrpSignatureDetails,
        lrpSignature: therapyPlanData?.lrpSignature,
        clinicianSignatureDetails: therapyPlanData?.clinicianSignatureDetails,
        clinicianTwoSignatureDetails: therapyPlanData?.clinicianTwoSignatureDetails,
        clinicianSignature: therapyPlanData?.clinicianSignature,
        clinicianTwoSignature: therapyPlanData?.clinicianTwoSignature,
      };
      setFormData(updateFormOneData);
      setProgressIdentifier1(therapyPlanData.goalInformation?.progressIdentifier);
      setProgressIdentifier2(therapyPlanData.goalInformation2?.progressIdentifier);
      setProgressIdentifier3(therapyPlanData.goalInformation3?.progressIdentifier);
      setServiceInterventionFrequency(therapyPlanData.goalInformation?.serviceInterventionFrequency);
      setServiceInterventionFrequency2(therapyPlanData.goalInformation2?.serviceInterventionFrequency);
      setServiceInterventionFrequency3(therapyPlanData.goalInformation3?.serviceInterventionFrequency);

      if (therapyPlanData?.goalInformation2?.goalObjective) {
        setGoalCount(2);
      }
      if (therapyPlanData?.goalInformation3?.goalObjective) {
        setGoalCount(3);
      }
    }
  }, [therapyPlanData]);

  useEffect(() => {
    if (props.therapyPlanData?.goalInformation?.responsibleParties) {
      if (!formData.goalInformation?.responsibleParties?.clientName) {
        setClientName(props.therapyPlanData.goalInformation.responsibleParties.clientName || "");
      }
      if (!formData.goalInformation?.responsibleParties?.therapistNameCredentials) {
        setTherapistName(props.therapyPlanData.goalInformation.responsibleParties.therapistNameCredentials || "");
      }
    }

    if (props.therapyPlanData?.goalInformation2?.responsibleParties) {
      if (!formData.goalInformation2?.responsibleParties?.clientName) {
        setClientName2(props.therapyPlanData.goalInformation2.responsibleParties.clientName || "");
      }
    }

    if (props.therapyPlanData?.goalInformation3?.responsibleParties) {
      if (!formData.goalInformation3?.responsibleParties?.clientName) {
        setClientName3(props.therapyPlanData.goalInformation3.responsibleParties.clientName || "");
      }
    }
  }, [clientId, formData.goalInformation]);

  function handlePlanCreationDate(e: any) {
    const hpcd = e.target.value;
    const creationDate=new Date(hpcd);
    creationDate.setFullYear(creationDate.getFullYear() + 1);
    setFormData(prevState => ({
      ...prevState,
      planCreationDate: hpcd,
      creationDate: creationDate,
    }));

  }

  const addRow = () => {
    setFormData({
      ...formData,
      diagnosis: [...(formData.diagnosis ?? []), {}],
    });
  };

  const addRowMember = () => {
    setFormData({
      ...formData,
      treatmentSession: [...(formData.treatmentSession ?? []), {}],
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSendSignatureEmail(!sendSignatureEmail);
  };

  function handleGoalDate(e: any) {
    const hgd = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      goalDate: hgd,
    }));
  }

  function handleGoalDate2(e: any) {
    const hgd2 = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      goalDate2: hgd2,
    }));
  }

  function handleGoalDate3(e: any) {
    const hgd3 = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      goalDate3: hgd3,
    }));
  }

  const addRowMember2 = () => {
    setFormData({
      ...formData,
      treatmentSession2: [...(formData.treatmentSession2 ?? []), {}],
    });
  };

  const addRowMember3 = () => {
    setFormData({
      ...formData,
      treatmentSession3: [...(formData.treatmentSession3 ?? []), {}],
    });
  };
  useEffect(() => {
    const clientData = formData.clientSignature;
    const lrpData = formData.lrpSignature;
    if ((clientData === undefined || !clientData || clientData.trim() === "") && (lrpData === undefined || !lrpData || lrpData.trim() === "")) {
      setIsClientSigned(false);
    } else {
      setIsClientSigned(true);
    }

    const clientSign = formData.clientSignature;
    if (clientSign === undefined || !clientSign || clientSign.trim() === "") {
      setClientSignature(false);
    } else {
      setClientSignature(true);
    }

    const lrpSign = formData.lrpSignature;
    if (lrpSign === undefined || !lrpSign || lrpSign === "") {
      setLrpSignature(false);
    } else {
      setLrpSignature(true);
    }

    const clinicianSign = formData.clinicianSignature;
    if (clinicianSign === undefined || !clinicianSign || clinicianSign === "") {
      setClinicianSignature(false);
    } else {
      setClinicianSignature(true);
    }

    const clinicianTwoSign = formData.clinicianTwoSignature;
    if (clinicianTwoSign === undefined || !clinicianTwoSign || clinicianTwoSign === "") {
      setClinicianTwoSignature(false);
    } else {
      setClinicianTwoSignature(true);
    }
  }, [therapyPlanData, formData]);

  function clearSign(): void {
    if (signPad.current) {
      signPad.current.clear();
    }
  }

  function clearSign2(): void {
    if (signPad2.current) {
      signPad2.current.clear();
    }
  }

  function saveClinicianSign() {
    if (signPad.current) {
      const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
      setFormData({ ...formData, clinicianSignature: signatureData });
      seteditSignature(false);
    }
  }

  function saveClinicianTwoSign() {
    if (signPad2.current) {
      const signatureData = signPad2.current.getTrimmedCanvas().toDataURL("image/png");
      setFormData({ ...formData, clinicianTwoSignature: signatureData });
      seteditSignature2(false);
    }
  }

  function editClinicianSign() {
    setClinicianSignature(false);
  }

  function editClinicianTwoSign() {
    setClinicianTwoSignature(false);
  }

  const signPad = useRef<SignaturePad | null>(null);

  const signPad2 = useRef<SignaturePad | null>(null);

  const cleanDiagnosisData = (diagnosis: any[]) => {
    if (!diagnosis || !Array.isArray(diagnosis)) return [];

    return diagnosis.map(item => {
      const cleanedItem = { ...item };
      if (Array.isArray(item.description)) {
        cleanedItem.description = item.description.filter((desc: string | object) => 
          desc !== ""
        );
      }

      return cleanedItem;
    });
  };

  const updateTherapyPlanDetails = async () => {
    console.log("before cleanDiagnosisData");
    console.log(formData?.diagnosis);
    console.log({isUpdate});
    console.log({isVersionCreated});
    const updateTherapyPlan: TherapyPlanSinglePage = {
      stepCount: 1,
      _id: formId,
      clientId: clientId,
      isSignature: isClientSigned,
      isSendSignatureEmail: sendSignatureEmail,
      planCreationDate: formData?.planCreationDate,
      creationDate: formData?.creationDate,
      // diagnosis: formData?.diagnosis?.map(diagnosisModel => ({
      //   description: diagnosisModel?.description,
      // })),
      diagnosis: cleanDiagnosisData(formData?.diagnosis || []),
      goalDate: formData?.goalDate,
      goalInformation: formData?.goalInformation,
      treatmentSession: formData?.treatmentSession?.map(treatmentSessionModel => ({
        targetDate: treatmentSessionModel?.targetDate,
        reviewDate: treatmentSessionModel?.reviewDate,
        statusCode: treatmentSessionModel?.statusCode,
        notes: treatmentSessionModel?.notes,
      })),
      goalDate2: formData?.goalDate2,
      goalInformation2: formData?.goalInformation2,
      treatmentSession2: formData?.treatmentSession2?.map(treatmentSessionModel => ({
        targetDate: treatmentSessionModel?.targetDate,
        reviewDate: treatmentSessionModel?.reviewDate,
        statusCode: treatmentSessionModel?.statusCode,
        notes: treatmentSessionModel?.notes,
      })),
      goalDate3: formData?.goalDate3,
      goalInformation3: formData?.goalInformation3,
      treatmentSession3: formData?.treatmentSession3?.map(treatmentSessionModel => ({
        targetDate: treatmentSessionModel?.targetDate,
        reviewDate: treatmentSessionModel?.reviewDate,
        statusCode: treatmentSessionModel?.statusCode,
        notes: treatmentSessionModel?.notes,
      })),
      clientSignature: formData?.clientSignature,
      clientSignatureDetails: formData?.clientSignatureDetails,
      lrpSignature: formData?.lrpSignature,
      lrpSignatureDetails: formData?.lrpSignatureDetails,
      clinicianSignature: formData?.clinicianSignature,
      clinicianTwoSignature: formData?.clinicianTwoSignature,
      clinicianSignatureDetails: formData?.clinicianSignatureDetails,
      clinicianTwoSignatureDetails: formData?.clinicianTwoSignatureDetails,
    };
    console.log({updateTherapyPlan});

    try {
      let res;
      if (isUpdate === false) {
        console.log("add therapy plan");
        res = await CommonService.addTherapyPlan(updateTherapyPlan);
        if (res && res?.success && res?.data) {
          props.onModalRefresher();
          toast.success("Data has been successfully saved!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          const updatedFormId = res?.data?._id;
          getUpdateFormId(updatedFormId);
          setIsUpdate(true);
          setIsVersionCreated(true);
          props.closePopup();
        } else {
          toast.error(res?.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        let data = {
          therapistCategorizationByType: therapistCategorizationByType,
        };
        console.log(data);
        let response = await CommonService.updateTherapistCategorizationByType(data);
      } else {
        if (isVersionCreated === false) {
          const result = await Swal.fire({
            title: "Reason for Amendment",
            input: "text",
            inputLabel: "Please provide a reason for amending this therapy plan.",
            inputPlaceholder: "Enter reason for amending...",
            inputValidator: value => {
              if (!value) {
                return "You need to provide a reason!";
              }
              return null;
            },
            showCancelButton: true,
            confirmButtonText: "Save",
          });

          if (result?.isConfirmed && result?.value) {
            const currentTherapyPlan = await FormVersionService.getCurrentTherapyPlan(formId);
            console.log(currentTherapyPlan);
            console.log("currentTherapyPlan");
            if (currentTherapyPlan && currentTherapyPlan.success && currentTherapyPlan.data) {
              const versioningData = {
                ...currentTherapyPlan.data,
                therapyPlanId: currentTherapyPlan.data._id,
                reasonForEdit: result?.value,
              };
              console.log("before cleanDiagnosisData");
              console.log({versioningData});

              versioningData.diagnosis = cleanDiagnosisData(versioningData?.diagnosis || []);
              console.log("after cleanDiagnosisData");
              console.log({versioningData});


              const versionRes = await FormVersionService.createTherapyPlanVersion(versioningData);
              console.log(versionRes);
              console.log("versionRes");
              if (!versionRes || !versionRes.success) {
                console.log("not success");
                toast.error("Error saving the current version before updating", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
                return;
              }

              console.log("response");

              const response = await CommonService.updateTherapyPlan(updateTherapyPlan);
              console.log(response);
              console.log("response");
              if (response && response?.success && response?.data) {
                props.onModalRefresher();
                toast.success("Therapy plan has been successfully updated!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
                const updatedFormId = response.data._id;
                getUpdateFormId(updatedFormId);
                props.closePopup();
              } else {
                toast.error(response.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
              setIsVersionCreated(true);
            } else {
              toast.error("Error retrieving the current therapy plan", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              return;
            }
          }
        } else {
          console.log("update therapy plan");
          const res = await CommonService.updateTherapyPlan(updateTherapyPlan);
          if (res && res.success && res.data) {
            toast.success("Therapy plan has been successfully updated!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            const updatedFormId = res.data._id;
            getUpdateFormId(updatedFormId);
            props.nextStep();
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        }
      }
    } catch (error) {
      toast.error("Error adding therapy plan", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const resizeCanvas = () => {
    if (signPad.current) {
      const canvas = signPad.current.getTrimmedCanvas();
      if (!canvas) return;

      const container = canvas.parentNode as HTMLElement;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = container.offsetWidth * ratio;
      canvas.height = container.offsetHeight * ratio;
      canvas.getContext("2d")?.scale(ratio, ratio);
      signPad.current.clear();
    }
    if (signPad2.current) {
      const canvas = signPad2.current.getTrimmedCanvas();
      if (!canvas) return;

      const container = canvas.parentNode as HTMLElement;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = container.offsetWidth * ratio;
      canvas.height = container.offsetHeight * ratio;
      canvas.getContext("2d")?.scale(ratio, ratio);
      signPad2.current.clear();
    }
  };

  useEffect(() => {
    if (!signPad.current) return;

    resizeCanvas();

    const debouncedResize = debounce(resizeCanvas, 200);
    window.addEventListener("resize", debouncedResize);

    const observer = new ResizeObserver(() => {
      resizeCanvas();
    });

    const canvas = signPad.current.getTrimmedCanvas();
    if (canvas && canvas.parentNode) {
      observer.observe(canvas.parentNode as HTMLElement);
    }

    return () => {
      window.removeEventListener("resize", debouncedResize);
      observer.disconnect();
    };
  }, []);

  /* get client name and therapist name logic start here*/
  const isFreshForm = !isUpdate && !therapyPlanData;
  const getClientNameAndTherapistName = async () => {
    try {
      if (isFreshForm && clientId) {
        const data = { clientId };
        console.log(data)
        const res = await CommonService.getClientNameAndTherapistName(data);

        if (res?.success) {
          setClientName(res.data.clientName);
          setClientName2(res.data.clientName);
          setClientName3(res.data.clientName);
          setTherapistName(res.data.therapistName);
          setTherapistCategorizationByType(res.data.therapistCategorizationByType);
          setFormData(prevFormData => ({
            ...prevFormData,
            goalInformation: {
              ...prevFormData.goalInformation,
              responsibleParties: {
                clientName: res.data.clientName,
                therapistNameCredentials: res.data.therapistName,
              },
            },
            goalInformation2: {
              ...prevFormData.goalInformation2,
              responsibleParties: {
                clientName: res.data.clientName,
                therapistNameCredentials: res.data.therapistName,
              },
            },
            goalInformation3: {
              ...prevFormData.goalInformation3,
              responsibleParties: {
                clientName: res.data.clientName,
                therapistNameCredentials: res.data.therapistName,
              },
            },
          }));
        } else {
          console.warn("Using identifierId as clientId");
        }
      }
    } catch (error) {
      toast.error("Error retrieving client and therapist name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  useEffect(() => {
    if (isFreshForm) {
      getClientNameAndTherapistName();
    }
  }, [clientId, isFreshForm]);
  /* get client name and therapist name logic ends here*/
  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      goalInformation: {
        ...prevFormData.goalInformation,
        serviceInterventionFrequency: serviceInterventionFrequency,
      },
      goalInformation2: {
        ...prevFormData.goalInformation2,
        serviceInterventionFrequency: serviceInterventionFrequency2,
      },
      goalInformation3: {
        ...prevFormData.goalInformation3,
        serviceInterventionFrequency: serviceInterventionFrequency3,
      },
    }));
  }, [serviceInterventionFrequency, serviceInterventionFrequency2, serviceInterventionFrequency3]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      goalInformation: {
        ...prevFormData.goalInformation,
        progressIdentifier: progressIdentifier1,
      },
      goalInformation2: {
        ...prevFormData.goalInformation2,
        progressIdentifier: progressIdentifier2,
      },
      goalInformation3: {
        ...prevFormData.goalInformation3,
        progressIdentifier: progressIdentifier3,
      },
    }));
  }, [progressIdentifier1, progressIdentifier2, progressIdentifier3]);

  const handleProgressIdentifierChange = (goalObjective: string, data: string, editor: any) => {
    const listItems = [...data.matchAll(/<li>(.*?)<\/li>/g)];
    const maxBullets = 5;

    if (listItems.length > maxBullets) {
      toast.warn("You can add a maximum of 5 bullet points.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      const trimmedBullets = listItems
        .slice(0, maxBullets)
        .map(match => match[0])
        .join("");

      const updatedData = `<ul>${trimmedBullets}</ul>`;

      editor.setData(updatedData);

      setTimeout(() => {
        editor.editing.view.focus();
      }, 100);

      return;
    }

    switch (goalObjective) {
      case "progressIdentifier1":
        setProgressIdentifier1(data);
        break;
      case "progressIdentifier2":
        setProgressIdentifier2(data);
        break;
      case "progressIdentifier3":
        setProgressIdentifier3(data);
        break;
      default:
        return;
    }
  };
  const addItemToList = (identifier: string, item: string) => {
    const maxItemCount = 5;
    let itemCount = 0;
    switch (identifier) {
      case "progressIdentifier1":
        itemCount = progressIdentifier1.match(/<li>/g)?.length ?? 0;
        if (itemCount <= maxItemCount) {
          setProgressIdentifier1(prevState => prevState + `<li>${item}</li>`);
        } else {
          toast.warn("You can add a maximum of 5 bullet points.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        break;
      case "progressIdentifier2":
        itemCount = progressIdentifier2.match(/<li>/g)?.length ?? 0;
        if (itemCount <= maxItemCount) {
          setProgressIdentifier2(prevState => prevState + `<li>${item}</li>`);
        } else {
          toast.warn("You can add a maximum of 5 bullet points.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        break;
      case "progressIdentifier3":
        itemCount = progressIdentifier3.match(/<li>/g)?.length ?? 0;
        if (itemCount <= maxItemCount) {
          setProgressIdentifier3(prevState => prevState + `<li>${item}</li>`);
        } else {
          toast.warn("You can add a maximum of 5 bullet points.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        break;
      default:
        return;
    }
  };
  /* Diagnoses select section logic start */
  const removeDiagnosis = (id: number) => {
    if ((formData?.diagnosis ?? [])?.length > 1) {
      setFormData((formData: Partial<TherapyPlanSinglePage>) => {
        const diagnosisArray = formData?.diagnosis;

        const updateDiagnosisArray = diagnosisArray?.filter((diagnosis, i) => {
          return i != id;
        });

        return {
          ...formData,
          diagnosis: updateDiagnosisArray,
        };
      });
    }
  };

  const customStylesForICD10Codes = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any) => ({
      ...styles,
      borderColor: primaryDiagnosisICD10CodesError ? "red" : "#ced4da",
    }),
  };

  const handleDiagnosisChange = (newValue: any, index: number) => {
    if (newValue.length == 0) {
      setprimaryDiagnosisICD10CodesError(true);
    } else {
      setprimaryDiagnosisICD10CodesError(false);
    }

    // setPrimaryDiagnosisICD10Codes(finalArray);

    const updatedDiagnose = formData?.diagnosis?.map((diagnose: DiagnosisModel, i: number) => {
      if (i === index) {
        return {
          ...diagnose,
          ["description"]: newValue, // Update the specific field
        };
      }
      return diagnose; // Return other substances unchanged
    });

    setFormData((formData: Partial<TherapyPlanSinglePage>) => ({
      ...formData,
      diagnosis: updatedDiagnose,
    }));
  };
  const addDiagnosis = () => {
    setFormData((formData: Partial<TherapyPlanSinglePage>) => ({
      ...formData,
      diagnosis: [
        ...(formData?.diagnosis ?? []),
        {
          description: [],
        },
      ],
    }));
  };
  /* Diagnoses select section logic end */

  /* add new goal logic starts here*/
  const [goalCount, setGoalCount] = useState(1);

  const addNewGoal = () => {
    if (goalCount < 3) {
      setGoalCount(goalCount + 1);
      if (goalCount == 2) {
      }
    }
  };
  /* add new goal logic ends here*/
  /* Add date to fields */
  if (!formData) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              updateTherapyPlanDetails();
            }}
          >
            Submit
          </button>
        </div>

        <Row>
          <Col xxl={5} xl={6}>
            <Card style={{ marginBottom: "0.3rem", backgroundColor: "#F1F1F1" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    PLAN CREATION DATE:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    id="planCreationDate"
                    value={formData.planCreationDate ? moment.utc(formData.planCreationDate).format("YYYY-MM-DD") : ""}
                    onChange={e => handlePlanCreationDate(e)}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col xxl={12} xl={6}>
            <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1" }}>
              <div className="row">
                <div className="col" style={{ fontSize: "12px" }}>
                  (Plan will be reviewed AS NEEDED, at minimum ANNUALLY, and expires ONE YEAR from creation date, on:&nbsp;
                  <input
                    style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    id="creationDate"
                    value={formData.creationDate ? moment.utc(formData.creationDate).format("YYYY-MM-DD") : ""}
                    onChange={(e)=>{
                      const creationDate=new Date(e.target.value);
                      setFormData(prevState => ({
                        ...prevState,
                        creationDate: creationDate,
                      }));
                    }}
                  />
                  )
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                DIAGNOSIS
              </h5>
            </Card>
            <Card className="match-card-body-second p-3">
              {formData.diagnosis?.map((diagnosisModel, index) => (
                <div key={index} className="ai-border mb-3 text-start">
                  <div className="ps-3 pe-3 pt-3 pb-3">
                    <div className="mb-3">
                      <p className="text-muted font-size-15 flex flex-row justify-content-between">
                        <strong> Diagnosis :</strong>
                        <button
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            fontSize: "20px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => removeDiagnosis(index)}
                        >
                          <img src={Close} alt="diagnoseCloseBtn" />
                        </button>
                      </p>

                      <Select
                        key={formData?.diagnosis?.[index]?.description?.length}
                        closeMenuOnSelect={false}
                        defaultValue={formData?.diagnosis?.[index]?.description ?? []}
                        isMulti
                        options={options}
                        styles={customStylesForICD10Codes}
                        onChange={(ee: any) => handleDiagnosisChange(ee, index)}
                      />
                    </div>
                  </div>
                </div>
              ))}

              <div className="d-flex flex-row justify-content-end">
                <button className='btn btn-primary mb-2" type="button' onClick={addDiagnosis}>
                  Add DIAGNOSES
                </button>
              </div>
            </Card>
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                TREATMENT GOALS
              </h5>
            </Card>
            <Card className="match-card-body p-3">
              <div style={{ overflowX: "auto" }}>
                <Card style={{ marginBottom: "0.5rem" }}>
                  <div className="row info-in-mobile">
                    <div>
                      <h6 className="fw-500 text-center" style={{ margin: "0.3rem" }}>
                        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>S</span>pecific&nbsp;
                        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>M</span>easurable&nbsp;
                        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>A</span>ttainable&nbsp;
                        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>R</span>elevant&nbsp;
                        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>T</span>ime-limited
                      </h6>
                    </div>
                  </div>
                </Card>

                <Card style={{ marginBottom: "0.5rem" }}>
                  <div className="row">
                    <div className="col-auto">
                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                        Goal Date:
                      </h6>
                    </div>
                    <div className="col">
                      <input
                        style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                        type="date"
                        id="goalDate"
                        value={formData.goalDate ? moment.utc(formData.goalDate).format("YYYY-MM-DD") : ""}
                        onChange={e => handleGoalDate(e)}
                      />
                    </div>
                  </div>
                </Card>

                <Table className="clinical-table-bordered table-md">
                  <thead style={{ verticalAlign: "middle" }}>
                    <tr>
                      <td className="fw-500" style={{ width: "55%" }}>
                        Goal/Objectives
                      </td>
                      <td className="fw-500" style={{ width: "30%" }}>
                        Service/Intervention/Frequency
                      </td>
                      <td className="fw-500" style={{ width: "15%" }}>
                        Responsible Parties
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "top" }}>
                    <tr style={{ height: "2rem" }}>
                      <td>
                        <div className="col-auto text-left">
                          <div className="d-flex flex-col m-1 ckeditor-text-container">
                            <div>
                              {formData.goalInformation?.responsibleParties?.clientName ? formData.goalInformation?.responsibleParties?.clientName : clientName}
                              s&apos; goal (in their words) is:{" "}
                            </div>

                            <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                              <div className="editor-area ck-content-therapy-plan" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                <textarea
                                  id="txtGoalObjective"
                                  disabled={!edit}
                                  style={{ width: "100%", border: "none", backgroundColor: "#F1F1F1", height: "150px" }}
                                  value={formData.goalInformation?.goalObjective || ""}
                                  onChange={e => {
                                    const data = e.target.value;
                                    setFormData({
                                      ...formData,
                                      goalInformation: {
                                        ...formData.goalInformation,
                                        goalObjective: data,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </Card>
                          </div>
                          <div className="d-flex flex-col m-1">
                            <div>How will we know progress is being made?</div>
                            <div className="col-auto">
                              <Dropdown isOpen={dropdownOpen} toggle={toggleMain} className="goal-dropdown" style={{ width: "100%" }} direction="down">
                                <DropdownToggle caret>Select a goal from list...</DropdownToggle>
                                <DropdownMenu>
                                  {Object.keys(goalInformation).map(key => (
                                    <DropdownItem key={key} onMouseEnter={() => setNestedDropdownOpen(key)} onMouseLeave={() => setNestedDropdownOpen("")}>
                                      <Dropdown isOpen={nestedDropdownOpen === key} toggle={() => {}} direction={"end"}>
                                        <DropdownToggle className="subDropdownItem" caret>
                                          {goalInformation[key].name}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {goalInformation[key].items.map(item => (
                                            <DropdownItem
                                              key={item}
                                              onClick={() => {
                                                addItemToList("progressIdentifier1", item);
                                              }}
                                            >
                                              {item}
                                            </DropdownItem>
                                          ))}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </div>

                            <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.1rem" }}>
                              <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                <CKEditor
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    backgroundColor: "#f1f1f1",
                                  }}
                                  id="txtProgressIdentifier1"
                                  editor={Editor}
                                  disabled={!edit}
                                  config={{
                                    toolbar: ["bulletedList"],
                                    list: {
                                      properties: {
                                        styles: true,
                                        startIndex: true,
                                        reversed: true,
                                      },
                                    },
                                  }}
                                  data={progressIdentifier1 || ""}
                                  onChange={(event: Event, editor: any) => {
                                    const data = editor.getData();
                                    handleProgressIdentifierChange("progressIdentifier1", data, editor);
                                  }}
                                />
                              </div>
                            </Card>
                          </div>
                          <div className="d-flex flex-col m-1">
                            {formData.goalInformation?.responsibleParties?.clientName ? formData.goalInformation?.responsibleParties?.clientName : clientName}{" "}
                            wants to achieve this goal
                            <select
                              className="goal-selector rounded me-1"
                              onChange={e => {
                                setFormData({
                                  ...formData,
                                  goalInformation: {
                                    ...formData.goalInformation,
                                    timeframe: e.target.value,
                                  },
                                });
                              }}
                              value={formData.goalInformation?.timeframe || ""}
                            >
                              <option value="">Select timeframe</option>
                              <option value="within 30 days">within 30 days</option>
                              <option value="within 60 days">within 60 days</option>
                              <option value="within 90 days">within 90 days</option>
                              <option value="within 6 months">within 6 months</option>
                              <option value="within one year">within one year</option>
                              <option value="by the end of the year">by the end of the year</option>
                              <option value="by the end of the school year">by the end of the school year</option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="col-auto align-content-center evidence-based-check-container">
                          <div className="d-flex flex-start m-1 flex-column">
                            <Dropdown
                              isOpen={dropdownTherapyTypeOpen}
                              toggle={toggledropdownTherapyType}
                              className="goal-dropdown"
                              style={{ width: "100%" }}
                              direction="down"
                            >
                              <DropdownToggle caret>Add from the list...</DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setServiceInterventionFrequency(
                                      (prevState: string) =>
                                        prevState + "<ul><li>Evidence-based individual therapy, provided via telehealth, at least monthly</li></ul>"
                                    );
                                  }}
                                >
                                  Evidence-based individual therapy, provided via telehealth, at least monthly
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() => {
                                    setServiceInterventionFrequency(
                                      (prevState: string) =>
                                        prevState + "<ul><li>Evidence-based family therapy, provided via telehealth, at least monthly</ul></li>"
                                    );
                                  }}
                                >
                                  Evidence-based family therapy, provided via telehealth, at least monthly
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setServiceInterventionFrequency(
                                      (prevState: string) =>
                                        prevState + "<ul><li>Evidence-based couple therapy, provided via telehealth, at least monthly</ul></li>"
                                    );
                                  }}
                                >
                                  Evidence-based couple therapy, provided via telehealth, at least monthly
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                            <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.1rem" }}>
                              <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                <CKEditor
                                  style={{ width: "100%", border: "none", backgroundColor: "#f1f1f1" }}
                                  id="txtServiceFrequency"
                                  editor={Editor}
                                  disabled={!edit}
                                  config={{
                                    toolbar: ["bulletedList"],
                                    list: {
                                      properties: {
                                        styles: true,
                                        startIndex: true,
                                        reversed: true,
                                      },
                                    },
                                  }}
                                  data={serviceInterventionFrequency || ""}
                                  onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setServiceInterventionFrequency(data);
                                  }}
                                />
                              </div>
                            </Card>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="col-auto align-content-center">
                          <div className="d-flex flex-col m-1">
                            <textarea
                              style={{ width: "100%", border: "none", backgroundColor: "#f1f1f1", height: "50px" }}
                              value={formData.goalInformation?.responsibleParties?.clientName || clientName || ""}
                              onChange={e => {
                                setFormData(prev => ({
                                  ...prev,
                                  goalInformation: {
                                    ...prev.goalInformation,
                                    responsibleParties: {
                                      ...prev.goalInformation?.responsibleParties,
                                      clientName: e.target.value,
                                    },
                                  },
                                }));
                                setClientName(e.target.value);
                              }}
                            />
                          </div>
                          <div className="d-flex flex-col m-1">
                            <textarea
                              style={{ width: "100%", border: "none", backgroundColor: "unset", height: "100px" }}
                              value={
                                formData.goalInformation?.responsibleParties?.therapistNameCredentials || therapistName
                                  ? `${formData.goalInformation?.responsibleParties?.therapistNameCredentials || therapistName},\nLavni Therapist`
                                  : ""
                              }
                              // readOnly
                              onChange={e => {
                                setFormData(prev => ({
                                  ...prev,
                                  goalInformation: {
                                    ...prev.goalInformation,
                                    responsibleParties: {
                                      ...prev.goalInformation?.responsibleParties,
                                      therapistNameCredentials: e.target.value,
                                    },
                                  },
                                }));
                                setTherapistName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Table className="clinical-table-bordered table-md">
                  <thead style={{ verticalAlign: "middle" }}>
                    <tr>
                      <td className="fw-500" style={{ width: "10%" }}>
                        Target Date
                      </td>
                      <td className="fw-500" style={{ width: "10%" }}>
                        Review Date
                      </td>
                      <td className="fw-500" style={{ width: "10%" }}>
                        Status
                      </td>
                      <td className="fw-500" style={{ width: "70%" }}>
                        Notes(Details)
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.treatmentSession?.map((treatmentSessionModel, index) => (
                      <>
                        <tr key={index}>
                          <td>
                            <div className="col-auto">
                              <input
                                style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                type="date"
                                id="name"
                                readOnly={!edit}
                                value={formData.treatmentSession?.[index]?.targetDate || ""}
                                onChange={e => {
                                  const updatedActivities = [...(formData.treatmentSession || [])];
                                  updatedActivities[index].targetDate = e.target.value;
                                  setFormData({ ...formData, treatmentSession: updatedActivities });
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <input
                                style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                type="date"
                                id="name"
                                readOnly={!edit}
                                value={formData.treatmentSession?.[index]?.reviewDate}
                                onChange={e => {
                                  const updatedActivities = [...(formData.treatmentSession || [])];
                                  updatedActivities[index].reviewDate = e.target.value;
                                  setFormData({ ...formData, treatmentSession: updatedActivities });
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <select
                                className="submissionForm"
                                id="4"
                                value={formData.treatmentSession?.[index]?.statusCode || ""}
                                onChange={e => {
                                  const updatedActivities = [...(formData.treatmentSession || [])];
                                  updatedActivities[index].statusCode = e.target.value as treatmentGoalStatusCode;
                                  setFormData({ ...formData, treatmentSession: updatedActivities });
                                }}
                              >
                                <option value={treatmentGoalStatusCode.A}>A</option>
                                <option value={treatmentGoalStatusCode.O}>O</option>
                                <option value={treatmentGoalStatusCode.R}>R</option>
                                <option value={treatmentGoalStatusCode.D}>D</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="col-auto">
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset" }}
                                id="name"
                                readOnly={!edit}
                                value={formData.treatmentSession?.[index]?.notes}
                                onChange={e => {
                                  const updatedActivities = [...(formData.treatmentSession || [])];
                                  updatedActivities[index].notes = e.target.value;
                                  setFormData({ ...formData, treatmentSession: updatedActivities });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                  <div className="py-3 d-flex justify-content-start">
                    {edit && (
                      <Button color="primary" onClick={addRowMember}>
                        Add Details
                      </Button>
                    )}
                  </div>
                </Table>
                <Card style={{ marginBottom: "0.5rem", width: "auto" }}>
                  <div className="row info-in-mobile">
                    <div>
                      <h6 className="fw-500 text-center">
                        <span className="me-3">A=Achieved</span>
                        <span className="me-3">O=Ongoing</span>
                        <span className="me-3">R=Revised</span>
                        <span>D=Discontinued</span>
                      </h6>
                    </div>
                  </div>
                </Card>
              </div>
            </Card>
          </div>
        </Row>
        {goalCount >= 2 && (
          <Row>
            <div className="card-bodyDiv">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  TREATMENT GOALS
                </h5>
              </Card>
              <Card className="match-card-body p-3">
                <div style={{ overflowX: "auto" }}>
                  <Card style={{ marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Goal Date:
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="date"
                          id="goalDate2"
                          value={formData.goalDate2 ? moment.utc(formData.goalDate2).format("YYYY-MM-DD") : ""}
                          onChange={e => handleGoalDate2(e)}
                        />
                      </div>
                    </div>
                  </Card>

                  <Table className="clinical-table-bordered table-md">
                    <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "55%" }}>
                          Goal/Objectives
                        </td>
                        <td className="fw-500" style={{ width: "30%" }}>
                          Service/Intervention/Frequency
                        </td>
                        <td className="fw-500" style={{ width: "15%" }}>
                          Responsible Parties
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "top" }}>
                      <tr style={{ height: "2rem" }}>
                        <td>
                          <div className="col-auto text-left">
                            <div className="d-flex flex-col m-1">
                              <div>
                                {formData.goalInformation2?.responsibleParties?.clientName
                                  ? formData.goalInformation2?.responsibleParties?.clientName
                                  : clientName2}
                                s&apos; goal (in their words) is:{" "}
                              </div>

                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <textarea
                                    id="txtGoalObjective2"
                                    disabled={!edit}
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      height: "150px",
                                    }}
                                    value={formData.goalInformation2?.goalObjective || ""}
                                    onChange={e => {
                                      const data = e.target.value;
                                      setFormData({
                                        ...formData,
                                        goalInformation2: {
                                          ...formData.goalInformation2,
                                          goalObjective: data,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              <div>How will we know progress is being made?</div>
                              <div className="col-auto">
                                <Dropdown isOpen={dropdownOpen2} toggle={toggleMain2} className="goal-dropdown" style={{ width: "100%" }} direction="down">
                                  <DropdownToggle caret>Select a goal from list...</DropdownToggle>
                                  <DropdownMenu>
                                    {Object.keys(goalInformation).map(key => (
                                      <DropdownItem key={key} onMouseEnter={() => setNestedDropdownOpen2(key)} onMouseLeave={() => setNestedDropdownOpen2("")}>
                                        <Dropdown isOpen={nestedDropdownOpen2 === key} toggle={() => {}} direction={"end"}>
                                          <DropdownToggle className="subDropdownItem" caret>
                                            {goalInformation[key].name}
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {goalInformation[key].items.map(item => (
                                              <DropdownItem
                                                key={item}
                                                onClick={() => {
                                                  addItemToList("progressIdentifier2", item);
                                                }}
                                              >
                                                {item}
                                              </DropdownItem>
                                            ))}
                                          </DropdownMenu>
                                        </Dropdown>
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>

                              <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <CKEditor
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "#f1f1f1",
                                    }}
                                    id="txtProgressIdentifier2"
                                    editor={Editor}
                                    disabled={!edit}
                                    config={{
                                      toolbar: ["bulletedList"],
                                      list: {
                                        properties: {
                                          styles: true,
                                          startIndex: true,
                                          reversed: true,
                                        },
                                      },
                                    }}
                                    data={progressIdentifier2 || ""}
                                    onChange={(event: Event, editor: any) => {
                                      const data = editor.getData();
                                      handleProgressIdentifierChange("progressIdentifier2", data, editor);
                                    }}
                                  />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              {formData.goalInformation2?.responsibleParties?.clientName
                                ? formData.goalInformation2?.responsibleParties?.clientName
                                : clientName2}{" "}
                              wants to achieve this goal
                              <select
                                className="goal-selector rounded me-1"
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    goalInformation2: {
                                      ...formData.goalInformation2,
                                      timeframe: e.target.value,
                                    },
                                  });
                                }}
                                value={formData.goalInformation2?.timeframe || ""}
                              >
                                <option value="">Select timeframe</option>
                                <option value="within 30 days">within 30 days</option>
                                <option value="within 60 days">within 60 days</option>
                                <option value="within 90 days">within 90 days</option>
                                <option value="within 6 months">within 6 months</option>
                                <option value="within one year">within one year</option>
                                <option value="by the end of the year">by the end of the year</option>
                                <option value="by the end of the school year">by the end of the school year</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center evidence-based-check-container">
                            <div className="d-flex flex-start m-1 flex-column">
                              <Dropdown
                                isOpen={dropdownTherapyTypeOpen2}
                                toggle={toggledropdownTherapyType2}
                                className="goal-dropdown"
                                style={{ width: "100%" }}
                                direction="down"
                              >
                                <DropdownToggle caret>Add from the list...</DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setServiceInterventionFrequency2(
                                        (prevState: string) =>
                                          prevState + "<ul><li>Evidence-based individual therapy, provided via telehealth, at least monthly</li></ul>"
                                      );
                                    }}
                                  >
                                    Evidence-based individual therapy, provided via telehealth, at least monthly
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => {
                                      setServiceInterventionFrequency2(
                                        (prevState: string) =>
                                          prevState + "<ul><li>Evidence-based family therapy, provided via telehealth, at least monthly</li></ul>"
                                      );
                                    }}
                                  >
                                    Evidence-based family therapy, provided via telehealth, at least monthly
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setServiceInterventionFrequency2(
                                        (prevState: string) =>
                                          prevState + "<ul><li>Evidence-based couple therapy, provided via telehealth, at least monthly</li></ul>"
                                      );
                                    }}
                                  >
                                    Evidence-based couple therapy, provided via telehealth, at least monthly
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>

                              <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <CKEditor
                                    style={{ width: "100%", border: "none", backgroundColor: "#f1f1f1" }}
                                    id="txtServiceFrequency2"
                                    editor={Editor}
                                    disabled={!edit}
                                    config={{
                                      toolbar: ["bulletedList"],
                                      list: {
                                        properties: {
                                          styles: true,
                                          startIndex: true,
                                          reversed: true,
                                        },
                                      },
                                    }}
                                    data={serviceInterventionFrequency2 || ""}
                                    onChange={(event: any, editor: any) => {
                                      const data = editor.getData();
                                      setServiceInterventionFrequency2(data);
                                    }}
                                  />
                                </div>
                              </Card>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center">
                            <div className="d-flex flex-col m-1">
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "#f1f1f1", height: "50px" }}
                                value={formData.goalInformation2?.responsibleParties?.clientName || clientName2 || ""}
                                onChange={e => {
                                  setFormData(prev => ({
                                    ...prev,
                                    goalInformation2: {
                                      ...prev.goalInformation2,
                                      responsibleParties: {
                                        ...prev.goalInformation2?.responsibleParties,
                                        clientName: e.target.value,
                                      },
                                    },
                                  }));
                                  setClientName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="d-flex flex-col m-1">
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset", height: "100px" }}
                                value={
                                  formData.goalInformation2?.responsibleParties?.therapistNameCredentials || therapistName
                                    ? `${formData.goalInformation2?.responsibleParties?.therapistNameCredentials || therapistName},\nLavni Therapist`
                                    : ""
                                }
                                // readOnly
                                onChange={e => {
                                  setFormData(prev => ({
                                    ...prev,
                                    goalInformation2: {
                                      ...prev.goalInformation2,
                                      responsibleParties: {
                                        ...prev.goalInformation2?.responsibleParties,
                                        therapistNameCredentials: e.target.value,
                                      },
                                    },
                                  }));
                                  setTherapistName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="clinical-table-bordered table-md">
                    <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Target Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Review Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Status Code
                        </td>
                        <td className="fw-500" style={{ width: "70%" }}>
                          Notes(Details)
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.treatmentSession2?.map((treatmentSessionModel, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly={!edit}
                                  value={formData.treatmentSession2?.[index]?.targetDate || ""}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession2 || [])];
                                    updatedActivities[index].targetDate = e.target.value;
                                    setFormData({ ...formData, treatmentSession2: updatedActivities });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly={!edit}
                                  value={formData.treatmentSession2?.[index]?.reviewDate}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession2 || [])];
                                    updatedActivities[index].reviewDate = e.target.value;
                                    setFormData({ ...formData, treatmentSession2: updatedActivities });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <select
                                  className="submissionForm"
                                  id="4"
                                  value={formData.treatmentSession2?.[index]?.statusCode || ""}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession2 || [])];
                                    updatedActivities[index].statusCode = e.target.value as treatmentGoalStatusCode;
                                    setFormData({ ...formData, treatmentSession2: updatedActivities });
                                  }}
                                >
                                  <option value={treatmentGoalStatusCode.A}>A</option>
                                  <option value={treatmentGoalStatusCode.O}>O</option>
                                  <option value={treatmentGoalStatusCode.R}>R</option>
                                  <option value={treatmentGoalStatusCode.D}>D</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset" }}
                                  id="name"
                                  readOnly={!edit}
                                  value={formData.treatmentSession2?.[index]?.notes}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession2 || [])];
                                    updatedActivities[index].notes = e.target.value;
                                    setFormData({ ...formData, treatmentSession2: updatedActivities });
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                    <div className="py-3 d-flex justify-content-start">
                      {edit && (
                        <Button color="primary" onClick={addRowMember2}>
                          Add Details
                        </Button>
                      )}
                    </div>
                  </Table>
                  <Card style={{ marginBottom: "0.5rem" }}>
                    <div className="row info-in-mobile">
                      <div>
                        <h6 className="fw-500 text-center">
                          <span className="me-3">A=Achieved</span>
                          <span className="me-3">O=Ongoing</span>
                          <span className="me-3">R=Revised</span>
                          <span>D=Discontinued</span>
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
          </Row>
        )}
        {goalCount >= 3 && (
          <Row>
            <div className="card-bodyDiv">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  TREATMENT GOALS
                </h5>
              </Card>
              <Card className="match-card-body p-3">
                <div style={{ overflowX: "auto" }}>
                  <Card style={{ marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Goal Date:
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="date"
                          id="goalDate3"
                          value={formData.goalDate3 ? moment.utc(formData.goalDate3).format("YYYY-MM-DD") : ""}
                          onChange={e => handleGoalDate3(e)}
                        />
                      </div>
                    </div>
                  </Card>

                  <Table className="clinical-table-bordered table-md">
                    <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "55%" }}>
                          Goal/Objectives
                        </td>
                        <td className="fw-500" style={{ width: "30%" }}>
                          Service/Intervention/Frequency
                        </td>
                        <td className="fw-500" style={{ width: "15%" }}>
                          Responsible Parties
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "top" }}>
                      <tr style={{ height: "2rem" }}>
                        <td>
                          <div className="col-auto text-left">
                            <div className="d-flex flex-col m-1">
                              <div>
                                {formData.goalInformation3?.responsibleParties?.clientName
                                  ? formData.goalInformation3?.responsibleParties?.clientName
                                  : clientName3}
                                s&apos; goal (in their words) is:{" "}
                              </div>

                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <textarea
                                    id="txtGoalObjective3"
                                    disabled={!edit}
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      height: "150px",
                                    }}
                                    value={formData.goalInformation3?.goalObjective || ""}
                                    onChange={e => {
                                      const data = e.target.value;
                                      setFormData({
                                        ...formData,
                                        goalInformation3: {
                                          ...formData.goalInformation3,
                                          goalObjective: data,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              <div>How will we know progress is being made?</div>
                              <div className="col-auto">
                                <Dropdown isOpen={dropdownOpen3} toggle={toggleMain3} className="goal-dropdown" style={{ width: "100%" }} direction="down">
                                  <DropdownToggle caret>Select a goal from list...</DropdownToggle>
                                  <DropdownMenu>
                                    {Object.keys(goalInformation).map(key => (
                                      <DropdownItem key={key} onMouseEnter={() => setNestedDropdownOpen3(key)} onMouseLeave={() => setNestedDropdownOpen3("")}>
                                        <Dropdown isOpen={nestedDropdownOpen3 === key} toggle={() => {}} direction={"end"}>
                                          <DropdownToggle className="subDropdownItem" caret>
                                            {goalInformation[key].name}
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {goalInformation[key].items.map(item => (
                                              <DropdownItem
                                                key={item}
                                                onClick={() => {
                                                  addItemToList("progressIdentifier3", item);
                                                }}
                                              >
                                                {item}
                                              </DropdownItem>
                                            ))}
                                          </DropdownMenu>
                                        </Dropdown>
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>

                              <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <CKEditor
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "#f1f1f1",
                                    }}
                                    id="txtProgressIdentifier3"
                                    editor={Editor}
                                    disabled={!edit}
                                    config={{
                                      toolbar: ["bulletedList"],
                                      list: {
                                        properties: {
                                          styles: true,
                                          startIndex: true,
                                          reversed: true,
                                        },
                                      },
                                    }}
                                    data={progressIdentifier3 || ""}
                                    onChange={(event: Event, editor: any) => {
                                      const data = editor.getData();
                                      handleProgressIdentifierChange("progressIdentifier3", data, editor);
                                    }}
                                  />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              {formData.goalInformation3?.responsibleParties?.clientName
                                ? formData.goalInformation3?.responsibleParties?.clientName
                                : clientName3}{" "}
                              wants to achieve this goal
                              <select
                                className="goal-selector rounded me-1"
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    goalInformation3: {
                                      ...formData.goalInformation3,
                                      timeframe: e.target.value,
                                    },
                                  });
                                }}
                                value={formData.goalInformation3?.timeframe || ""}
                              >
                                <option value="">Select timeframe</option>
                                <option value="within 30 days">within 30 days</option>
                                <option value="within 60 days">within 60 days</option>
                                <option value="within 90 days">within 90 days</option>
                                <option value="within 6 months">within 6 months</option>
                                <option value="within one year">within one year</option>
                                <option value="by the end of the year">by the end of the year</option>
                                <option value="by the end of the school year">by the end of the school year</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center evidence-based-check-container">
                            <div className="d-flex flex-start m-1 flex-column">
                              <Dropdown
                                isOpen={dropdownTherapyTypeOpen3}
                                toggle={toggledropdownTherapyType3}
                                className="goal-dropdown"
                                style={{ width: "100%" }}
                                direction="down"
                              >
                                <DropdownToggle caret>Add from the list...</DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setServiceInterventionFrequency3(
                                        (prevState: string) =>
                                          prevState + "<ul><li>Evidence-based individual therapy, provided via telehealth, at least monthly</li></ul>"
                                      );
                                    }}
                                  >
                                    Evidence-based individual therapy, provided via telehealth, at least monthly
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => {
                                      setServiceInterventionFrequency3(
                                        (prevState: string) =>
                                          prevState + "<ul><li>Evidence-based family therapy, provided via telehealth, at least monthly</li></ul>"
                                      );
                                    }}
                                  >
                                    Evidence-based family therapy, provided via telehealth, at least monthly
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setServiceInterventionFrequency3(
                                        (prevState: string) =>
                                          prevState + "<ul><li>Evidence-based couple therapy, provided via telehealth, at least monthly</li></ul>"
                                      );
                                    }}
                                  >
                                    Evidence-based couple therapy, provided via telehealth, at least monthly
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>

                              <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <CKEditor
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "#f1f1f1",
                                    }}
                                    id="txtServiceFrequency2"
                                    editor={Editor}
                                    disabled={!edit}
                                    config={{
                                      toolbar: ["bulletedList"],
                                      list: {
                                        properties: {
                                          styles: true,
                                          startIndex: true,
                                          reversed: true,
                                        },
                                      },
                                    }}
                                    data={serviceInterventionFrequency3 || ""}
                                    onChange={(event: Event, editor: any) => {
                                      const data = editor.getData();
                                      setServiceInterventionFrequency3(data);
                                    }}
                                  />
                                </div>
                              </Card>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center">
                            <div className="d-flex flex-col m-1">
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "#f1f1f1", height: "50px" }}
                                value={formData.goalInformation3?.responsibleParties?.clientName || clientName3 || ""}
                                onChange={e => {
                                  setFormData(prev => ({
                                    ...prev,
                                    goalInformation3: {
                                      ...prev.goalInformation3,
                                      responsibleParties: {
                                        ...prev.goalInformation3?.responsibleParties,
                                        clientName: e.target.value,
                                      },
                                    },
                                  }));
                                  setClientName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="d-flex flex-col m-1">
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset", height: "100px" }}
                                value={
                                  formData.goalInformation3?.responsibleParties?.therapistNameCredentials || therapistName
                                    ? `${formData.goalInformation3?.responsibleParties?.therapistNameCredentials || therapistName},\nLavni Therapist`
                                    : ""
                                }
                                // readOnly
                                onChange={e => {
                                  setFormData(prev => ({
                                    ...prev,
                                    goalInformation3: {
                                      ...prev.goalInformation3,
                                      responsibleParties: {
                                        ...prev.goalInformation3?.responsibleParties,
                                        therapistNameCredentials: e.target.value,
                                      },
                                    },
                                  }));
                                  setTherapistName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="clinical-table-bordered table-md">
                    <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Target Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Review Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Status Code
                        </td>
                        <td className="fw-500" style={{ width: "70%" }}>
                          Notes(Details)
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.treatmentSession3?.map((treatmentSessionModel, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly={!edit}
                                  value={formData.treatmentSession3?.[index]?.targetDate || ""}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession3 || [])];
                                    updatedActivities[index].targetDate = e.target.value;
                                    setFormData({ ...formData, treatmentSession3: updatedActivities });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly={!edit}
                                  value={formData.treatmentSession3?.[index]?.reviewDate}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession3 || [])];
                                    updatedActivities[index].reviewDate = e.target.value;
                                    setFormData({ ...formData, treatmentSession3: updatedActivities });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <select
                                  className="submissionForm"
                                  id="4"
                                  value={formData.treatmentSession3?.[index]?.statusCode || ""}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession3 || [])];
                                    updatedActivities[index].statusCode = e.target.value as treatmentGoalStatusCode;
                                    setFormData({ ...formData, treatmentSession3: updatedActivities });
                                  }}
                                >
                                  <option value={treatmentGoalStatusCode.A}>A</option>
                                  <option value={treatmentGoalStatusCode.O}>O</option>
                                  <option value={treatmentGoalStatusCode.R}>R</option>
                                  <option value={treatmentGoalStatusCode.D}>D</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset" }}
                                  id="name"
                                  readOnly={!edit}
                                  value={formData.treatmentSession3?.[index]?.notes}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession3 || [])];
                                    updatedActivities[index].notes = e.target.value;
                                    setFormData({ ...formData, treatmentSession3: updatedActivities });
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                    <div className="py-3 d-flex justify-content-start">
                      {edit && (
                        <Button color="primary" onClick={addRowMember3}>
                          Add Details
                        </Button>
                      )}
                    </div>
                  </Table>
                  <Card style={{ marginBottom: "0.5rem" }}>
                    <div className="row info-in-mobile">
                      <div>
                        <h6 className="fw-500 text-center">
                          <span className="me-3">A=Achieved</span>
                          <span className="me-3">O=Ongoing</span>
                          <span className="me-3">R=Revised</span>
                          <span>D=Discontinued</span>
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
          </Row>
        )}
        <Row>
          <div className="py-3 d-flex justify-content-start">
            {goalCount < 3 && (
              <Button color="primary" onClick={addNewGoal}>
                Add New Goal
              </Button>
            )}
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                SIGNATURES
              </h5>
            </Card>

            <Card className="match-card-body p-3">
              <Row>
                <h6 className="fw-600">
                  I confirm my involvement in the development of this Treatment Plan. My signature means that I agree with the goals developed and services to
                  be provided.
                </h6>
              </Row>

              {!isClientSigned && (
                <Row>
                  <div className="card-bodyDiv mt-3">
                    <Card className="match-card-body-second p-3">
                      <h6 className="fw-200">
                        Note: The client signature will appear here after it has been signed by the client or the legally responsible person(LRP).
                      </h6>
                    </Card>
                  </div>
                </Row>
              )}

              {clientSignature && formData.clientSignature != undefined && (
                <Row>
                  <div className="row justify-content-between" style={{ textAlign: "center" }}>
                    <div className="col-lg-3 md-12 m-2">
                      <div className="d-flex flex-column justify-content-between">
                        <div className="sign">{clientSignature ? <img className="sigCanvas" src={formData.clientSignature} alt="clientSign" /> : <></>}</div>
                      </div>
                      <div className="col-auto mt-3">
                        <h6 className="fw-500">Signature of Client</h6>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        type="text"
                        id="clientName"
                        readOnly={!edit}
                        value={formData.clientSignatureDetails?.name || ""}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            clientSignatureDetails: {
                              ...formData.clientSignatureDetails,
                              name: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">Client Name</h6>
                    </div>

                    <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        type="date"
                        readOnly={!edit}
                        value={formData.clientSignatureDetails?.date ? moment(formData.clientSignatureDetails?.date).format("YYYY-MM-DD") : ""}
                        max={moment().format("YYYY-MM-DD")}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            clientSignatureDetails: {
                              ...formData.clientSignatureDetails,
                              date: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">Date</h6>
                    </div>
                    <div className="col-sm-3 m-2"></div>
                  </div>
                </Row>
              )}

              {lrpSignature && formData.lrpSignature != undefined && (
                <Row>
                  <div className="row justify-content-between" style={{ textAlign: "center" }}>
                    <div className="col-lg-3 md-12 m-2">
                      <div className="d-flex flex-column justify-content-between">
                        <div className="sign">{lrpSignature ? <img className="sigCanvas" src={formData.lrpSignature} alt="lrpSign" /> : <></>}</div>
                      </div>
                      <div className="col-auto mt-3">
                        <h6 className="fw-500">Signature of LRP</h6>
                        <div className="col-auto fw-500 mb-1" style={{ fontSize: "smaller" }}>
                          <i>(Legally Responsible Person, if not client)</i>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        readOnly={!edit}
                        value={formData.lrpSignatureDetails?.name || ""}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            lrpSignatureDetails: {
                              ...formData.lrpSignatureDetails,
                              name: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">LRP Name</h6>
                    </div>

                    <div className="col-lg-2 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        type="text"
                        readOnly={!edit}
                        value={formData.lrpSignatureDetails?.relationship || ""}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            lrpSignatureDetails: {
                              ...formData.lrpSignatureDetails,
                              relationship: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">LRP Relationship</h6>
                    </div>

                    <div className="col-lg-2 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        type="date"
                        readOnly={!edit}
                        value={formData.lrpSignatureDetails?.date ? moment(formData.lrpSignatureDetails?.date).format("YYYY-MM-DD") : ""}
                        max={moment().format("YYYY-MM-DD")}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            lrpSignatureDetails: {
                              ...formData.lrpSignatureDetails,
                              date: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">Date</h6>
                    </div>
                    <div className="col-lg-2 col-md-12 m-2"></div>
                  </div>
                </Row>
              )}

              <Row>
<h6 className="fw-600"></h6>
              </Row>
              {(therapistCategorizationByType === "None" || therapistCategorizationByType === "" || therapistCategorizationByType === undefined) && (
                <Row>
                  <div className="d-flex flex-row">
                    <label htmlFor="therapistCategorizationByType">I am a </label>
                    <div id="therapistCategorizationByType" className="d-flex flex-row ms-2">
                      <div>
                        <input
                          type="radio"
                          name="therapistCategorization"
                          id="associateTherapist"
                          className="form-check-input"
                          value="ASSOCIATE"
                          onChange={e => {
                            setTherapistCategorizationByType(e.target.value);
                          }}
                        />
                        <label className={"ms-2"} htmlFor="associateTherapist">
                          Associate therapist
                        </label>
                      </div>
                      <div className="ms-3">
                        <input
                          type="radio"
                          name="therapistCategorization"
                          id="licensedTherapist"
                          className="form-check-input"
                          value="LICENSED"
                          onChange={e => {
                            setTherapistCategorizationByType(e.target.value);
                          }}
                        />
                        <label className={"ms-2"} htmlFor="licensedTherapist">
                          Fully licensed therapist
                        </label>
                      </div>
                    </div>
                  </div>
                </Row>
              )}

              {therapistCategorizationByType === "ASSOCIATE" && (
                <Row>
                  <Row>
                    <div className="row justify-content-between" style={{ textAlign: "center" }}>
                      <div className="col-lg-3 col-md-12 m-2">
                        <div className="d-flex flex-column justify-content-between">
                          <div className="sign">
                            <div className="sigCanvasNewDiv">
                              {clinicianSignature ? (
                                <img className="sigCanvasNew" src={formData.clinicianSignature} alt={"signature of the associate clinician"} />
                              ) : (
                                <SignaturePad ref={signPad} backgroundColor="rgb(245,242,241)" penColor="black" canvasProps={{ className: "sigCanvasNew" }} />
                              )}
                            </div>
                          </div>
                          {!clinicianSignature ? (
                            <div className="d-flex justify-content-between mt-3">
                              <div>
                                <a
                                  onClick={() => {
                                    clearSign();
                                  }}
                                  className="btn btn-primary btn-sm"
                                >
                                  Clear
                                </a>
                              </div>
                              <div>
                                <a
                                  onClick={() => {
                                    saveClinicianSign();
                                  }}
                                  className="btn btn-primary btn-sm"
                                >
                                  Save
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <a onClick={() => editClinicianSign()} className="btn btn-primary btn-sm mt-2">
                                Edit
                              </a>
                            </div>
                          )}
                        </div>
                        <h6 className="fw-500">Associate Clinician Signature</h6>
                        {/* <h6 className="fw-600">My signature below confirms Medical Necessity for services requested is present and constitutes the Service Order.</h6> */}
                      </div>
                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          id="clinicianName"
                          readOnly={!edit}
                          value={formData.clinicianSignatureDetails?.name || ""}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              clinicianSignatureDetails: {
                                ...formData.clinicianSignatureDetails,
                                name: e.target.value,
                              },
                            })
                          }
                        />
                        <h6 className="fw-500">Clinician Name</h6>
                      </div>
                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="date"
                          id="date"
                          readOnly={!edit}
                          value={formData.clinicianSignatureDetails?.date ? moment.utc(formData.clinicianSignatureDetails?.date).format("YYYY-MM-DD") : ""}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              clinicianSignatureDetails: {
                                ...formData.clinicianSignatureDetails,
                                date: e.target.value,
                              },
                            })
                          }
                        />
                        <h6 className="fw-500">Date</h6>
                      </div>
                      <div className="col-auto fw-500 mb-1" style={{ fontSize: "smaller", marginTop: "-0.5rem" }}>
<i></i>
                      </div>

                      <h6 className="fw-600">My signature below confirms Medical Necessity for services requested is present and constitutes the Service Order.</h6>
                    </div>
                  </Row>
                </Row>
              )}
              {(therapistCategorizationByType === "LICENSED" || therapistCategorizationByType == "ASSOCIATE") && (
                <Row>
                  <div className="row justify-content-between" style={{ textAlign: "center" }}>
                    <div className="col-lg-3 col-md-12 m-2">
                      <div className="d-flex flex-column justify-content-between">
                        <div className="sign">
                          <div className="sigCanvasNewDiv">
                            {clinicianTwoSignature ? (
                              <img className="sigCanvasNew" src={formData.clinicianTwoSignature} alt={"signature of the fully licensed therapist"} />
                            ) : (
                              <SignaturePad ref={signPad2} backgroundColor="rgb(245,242,241)" penColor="black" canvasProps={{ className: "sigCanvasNew" }} />
                            )}
                          </div>
                        </div>
                        {!clinicianTwoSignature ? (
                          <div className="d-flex justify-content-between mt-3">
                            <div>
                              <a
                                onClick={() => {
                                  clearSign2();
                                }}
                                className="btn btn-primary btn-sm"
                              >
                                Clear
                              </a>
                            </div>
                            <div>
                              <a
                                onClick={() => {
                                  saveClinicianTwoSign();
                                }}
                                className="btn btn-primary btn-sm"
                              >
                                Save
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <a onClick={() => editClinicianTwoSign()} className="btn btn-primary btn-sm mt-2">
                              Edit
                            </a>
                          </div>
                        )}
                      </div>
                      <h6 className="fw-500">Fully Licensed Professional Signature</h6>
                    </div>
                    <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        type="text"
                        id="clinicianName"
                        readOnly={!edit}
                        value={formData.clinicianTwoSignatureDetails?.name || ""}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            clinicianTwoSignatureDetails: {
                              ...formData.clinicianTwoSignatureDetails,
                              name: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">Clinician Name</h6>
                    </div>
                    <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                      <input
                        style={{
                          width: "100%",
                          border: "none",
                          borderBottom: "1px solid gray",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          textAlign: "center",
                        }}
                        type="date"
                        id="date"
                        readOnly={!edit}
                        value={formData.clinicianTwoSignatureDetails?.date ? moment.utc(formData.clinicianTwoSignatureDetails?.date).format("YYYY-MM-DD") : ""}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            clinicianTwoSignatureDetails: {
                              ...formData.clinicianTwoSignatureDetails,
                              date: e.target.value,
                            },
                          })
                        }
                      />
                      <h6 className="fw-500">Date</h6>
                    </div>
                    <div className="col-auto fw-500 mb-1" style={{ fontSize: "smaller", marginTop: "-0.5rem" }}>
                        <i>(MD/DO/NP/PA/PhD signature REQUIRED for Associate Level Professionals)</i>
                    </div>
                  </div>
                </Row>
              )}
              <br />

              {!isClientSigned && (
                <Row>
                  <div className="card-bodyDiv mt-3">
                    <Input
                      type="switch"
                      role="switch"
                      defaultChecked={sendSignatureEmail}
                      onChange={(event: any) => {
                        handleCheckboxChange(event);
                      }}
                      className="custom-control-input"
                    />
                    <label className="ms-2 fst-italic">Check here to send an email to your client for request form signature.</label>
                  </div>
                  <div className="col-auto fw-500 mb-1" style={{ fontSize: "smaller", marginTop: "-0.5rem" }}>
                    <i>(The email will be sent only if checked here.)</i>
                  </div>
                </Row>
              )}
            </Card>
          </div>
        </Row>

        <Row>
          <Col xl={6}></Col>
          <Col xl={6}>
            <button
              type="button"
              className="btn btn-primary relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                updateTherapyPlanDetails();
              }}
            >
              Submit
            </button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FormOne;
