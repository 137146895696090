import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreIcon from "@material-ui/icons/More";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import HouseIcon from "@material-ui/icons/House";
import CloseIcon from "@material-ui/icons/Close";

import ChatIcon from "@material-ui/icons/Chat";

import React from "react";
import styles from "./styles";

import Drawer from "@material-ui/core/Drawer";

import SideMenu from "../SideMenu";

import useSignal from "../../hooks/useSignal";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Divider } from "rsuite";

import SessionHistoryModal from "../../../../../../../src/pages/Popup/SessionHistoryModal";
import ClinicalAssetmentModal from "../../../../../Popup/ClinicalAssessmentModal";
import UserContext from "../../../../../../context/UserContext";
import LocalCallContext from "../../../../../../context/LocalCallContext";
import NewClinicalAssessmentModal from "./../../../../../Popup/NewClinicalAssessmentModal";
import { CommonService } from "./../../../../../../services/CommonService";
import { truncate } from "lodash";
import Modal32 from './../../../../../Popup/Modal32';
import { CAFormType } from 'src/models/clinicalAssesment/clinicalAssesment';
import { FormVersionService } from './../../../../../../services/FormVersionService';
import { MeetingData } from './../../../../../../models/MeetingData';
import AIGeneratedAssessment from './../../../../../NewAssessmentDigitalForm/AIGeneratedAssessment';
import { Role } from "./../../../../../../models/Role";


export default function MoreOptionsButton({ classes, participants, room, localParticipant, screenWidth }) {
  const { listOfMessages } = useSignal({ room });
  const titleToolTip = "Chat";
  const localClasses = styles();
  const [state, setState] = React.useState(false);
  const [showSessionHistoryModal, setShowSessionHistoryModal] = React.useState(false);
  const [showClinicalAssessmentModal, setShowClinicalAssessmentModal] = React.useState(false);
  const [loggedUser] = React.useContext(UserContext);
  const [localUserOnCall, setLocalUserOnCall] = React.useContext(LocalCallContext);
  const [isNewDigitalAssessemntExist, setIsNewDigitalAssessmentExist] = React.useState(false);
  const [isDigitalAssessmentDataExist, setIsDigitalAssessmentDataExist] = React.useState(false)
  const [isClinicalAssessmentExit, setIsClinicalAssessmentExist] = React.useState(false)
  const [showClinicalAssessmentWithVersionModal, setShowClinicalAssessmentWithVersionModal] = React.useState(false);
  const [clinicalAssessmentInfo, setClinicalAssessmentInfo] = React.useState("");
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = React.useState(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [showAiGeneratedAssessmentPopup, setShowAiGeneratedAssessmentPopup] = React.useState(false);
  const [firstMeetingData, setFirstMeetingData] = React.useState([]);
  const [selectedCAFormType, setSelectedCAFormType] = React.useState("");

  React.useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1300);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  React.useEffect( () => {
   initializeAssesmentData()
  }, []);

  const initializeAssesmentData = async () => {
     try {
      if (loggedUser?.role == "THERAPIST" && loggedUser?._id != localUserOnCall?.recieversUserId) {
        const data = {
          clientId: localUserOnCall?.recieversUserId,
        };
        const result = await CommonService.getClinicalAssesment(data);

        if (result && result?.success) {
          setIsClinicalAssessmentExist(true)
          setIsNewDigitalAssessmentExist(false);
        } else {
           const digitalAssessmentData =  await CommonService.getDigitalAssessmentDataByTherapist({ clientId:  localUserOnCall?.recieversUserId });

           if(digitalAssessmentData && digitalAssessmentData?.success && digitalAssessmentData?.data !== null) {
                 setIsNewDigitalAssessmentExist(true);
                 setIsDigitalAssessmentDataExist(true)
                 setIsClinicalAssessmentExist(false)
           }else {

                setIsNewDigitalAssessmentExist(true);
                setIsDigitalAssessmentDataExist(false);
                // setShowClinicalAssessmentWithVersionModal(true)
           }
        }
      }
    } catch (error) {
      console.log("more option error");
      console.log(error);
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchClinicalAssessmentWithVersions(localUserOnCall?.recieversUserId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
        // setShowClinicalAssessmentWithVersionModal(true);
      }
    };
  
    if (localUserOnCall?.recieversUserId && loggedUser?.role == Role.THERAPIST) {
      fetchData();
    }
  }, [localUserOnCall?.recieversUserId]);  

   const generateClinicalPdf = (planId, tpType) => {
   };

   const clinicalAssessment = () => {

   if (selectedCAFormType === CAFormType.NEW_CA) {

      setShowNewClinicalAssessmentModal(true);
      setShowClinicalAssessmentWithVersionModal(false)

    }
  };

   const fetchClinicalAssessmentWithVersions = async (clientIdForForm) => {

    try {
      const res = await FormVersionService.getClinicalAssessmentsWithAllVersions(clientIdForForm);

      if (res && res.success) {
        const { relatedCAFormType, caWithVersion, daWithVersion, meetingData } = res.data;

        if (relatedCAFormType == CAFormType.OLD_CA) {

          setSelectedCAFormType(CAFormType.OLD_CA);
          return { success: true, data: caWithVersion };

        } else if (relatedCAFormType == CAFormType.NEW_CA) {

          setSelectedCAFormType(CAFormType.NEW_CA);
          if (daWithVersion.length == 0 && meetingData) {

            setFirstMeetingData(meetingData);
          }
          // if (meetingData) {
          //   console.log("meet data")
          //   setFirstMeetingData(meetingData);
          // }
          return { success: true, data: daWithVersion };

        } else {
          return { success: false };
        }
      } else {

        return { success: false };
      }

    } catch (error) {

      return { success: false };
    }
  };

  const openClinicalAssessment = () => {

    if (selectedCAFormType == CAFormType.OLD_CA) {
        setShowClinicalAssessmentModal(true);

    } else if (selectedCAFormType == CAFormType.NEW_CA) {
        setShowClinicalAssessmentWithVersionModal(true)
        if(isDigitalAssessmentDataExist) {
          setShowNewClinicalAssessmentModal(true)
          setShowClinicalAssessmentWithVersionModal(false)
        }
    }

  };

  const generateAiAssessmentFunction = () => {
    setShowAiGeneratedAssessmentPopup(true);
    setShowClinicalAssessmentWithVersionModal(false)

  }

  const toggleDrawer = () => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(!state);
  };

  const onCloseFunc = () => {
    setState(false);
  };

  const openDiagnosisNote = () => {
    setShowSessionHistoryModal(true);
  };

  const checkExistAssessment = () => {};



  const handleChildFunction = (isCalled) => {
    setShowAiGeneratedAssessmentPopup(isCalled);
  };

  const refreshModal32 = async (value) => {

    if (value) {
      initializeAssesmentData()
      const result = await fetchClinicalAssessmentWithVersions(localUserOnCall?.recieversUserId);
      if (result.success) {
        setClinicalAssessmentInfo(result.data);
      }
    }
  };

  //node refersh new assessment
  const refreshModal =  (value) => {
      if(value) {
        initializeAssesmentData()
      }
  }



  return (
    <>
      {screenWidth < 530 ? (
        <>
          {/* <ButtonGroup className={classes.toolbarButtons} disableElevation variant="contained" aria-label="split button">
              <Tooltip title={titleToolTip} aria-label="add">
                <IconButton
                  onClick={toggleDrawer()}
                  edge="start"
                  color="inherit"
                  aria-label="mic"
                  className={`${classes.arrowButton} `}
                >
                  <ChatIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </ButtonGroup> */}
          <ButtonGroup className={classes.groupButton2} disableElevation variant="contained" aria-label="split button">
            <Tooltip title={titleToolTip} aria-label="add">
              <IconButton onClick={toggleDrawer()} edge="start" aria-label="videoCamera" size="small" className={`${classes.arrowButton2} `}>
                <ChatIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
          <Drawer open={state} onClose={toggleDrawer(false)} classes={{ paper: localClasses.paper }}>
            {/* <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleDrawer(false)}
                  style={{ position: 'absolute', top: 0, right: 0, zIndex: 1300, marginLeft: "50px" }}
                >
                  <CloseIcon style={{ zIndex: 1400 }} />
                </IconButton> */}
            <SideMenu
              className={localClasses.root}
              room={room}
              participants={participants}
              localParticipant={localParticipant}
              listOfMessages={listOfMessages}
              onCloseFun={onCloseFunc}
              openDiagnosisNote={openDiagnosisNote}
              openClinicalAssessment={openClinicalAssessment}
            ></SideMenu>
          </Drawer>
        </>
      ) : (
        <div>
          <Tooltip title={titleToolTip} aria-label="add">
            <IconButton onClick={toggleDrawer()} edge="start" color="inherit" aria-label="mic" className={localClasses.infoButton}>
              <ChatIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Drawer open={state} onClose={toggleDrawer(false)} classes={{ paper: localClasses.paper }}>
            {/* <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleDrawer(false)}
                  style={{ position: 'absolute', top: 0, right: 0, zIndex: 1300}}
                >
                  <CloseIcon style={{ zIndex: 1400 }} />
                </IconButton> */}
            <SideMenu
              className={localClasses.root}
              room={room}
              participants={participants}
              localParticipant={localParticipant}
              listOfMessages={listOfMessages}
              onCloseFun={onCloseFunc}
              openDiagnosisNote={openDiagnosisNote}
              openClinicalAssessment={openClinicalAssessment}
            ></SideMenu>
          </Drawer>
        </div>
      )}
      {loggedUser?.role == "THERAPIST" &&
        showSessionHistoryModal &&
        loggedUser?._id != localUserOnCall?.recieversUserId &&
        localUserOnCall?.recieversUserId && (
          <SessionHistoryModal
            setShowModal={setShowSessionHistoryModal}
            showModal={showSessionHistoryModal}
            userId={loggedUser?.role == "THERAPIST" && loggedUser?._id == localUserOnCall?.recieversUserId ? null : localUserOnCall?.recieversUserId}
          />
        )}
      {loggedUser?.role == "THERAPIST" &&
        showClinicalAssessmentModal &&
        loggedUser?._id != localUserOnCall?.recieversUserId &&
        localUserOnCall?.recieversUserId &&
        (
          <ClinicalAssetmentModal
            setShowModal={setShowClinicalAssessmentModal}
            showModal={showClinicalAssessmentModal}
            identifierId={loggedUser?.role == "THERAPIST" && loggedUser?._id == localUserOnCall?.recieversUserId ? null : localUserOnCall?.recieversUserId}
            isAdmin={false}
            isFromMeeting={true}
            setModalRefresher={null}
          />
        )}

      {loggedUser?.role == "THERAPIST" &&

        loggedUser?._id != localUserOnCall?.recieversUserId &&
        localUserOnCall?.recieversUserId &&
        isNewDigitalAssessemntExist &&

          (
          <NewClinicalAssessmentModal
            setShowModal={setShowNewClinicalAssessmentModal}
            showModal={showNewClinicalAssessmentModal}
            identifierId={loggedUser?.role == "THERAPIST" && loggedUser?._id == localUserOnCall?.recieversUserId ? null : localUserOnCall?.recieversUserId}
            isAdmin={false}
            isFromMeeting={true}
            setModalRefresher={refreshModal}
            aiGeneratedAssessmentData={null}
            isMeeting={true}

          />
      )}

      {loggedUser?.role == "THERAPIST" &&

          loggedUser?._id != localUserOnCall?.recieversUserId &&
          localUserOnCall?.recieversUserId &&
          isNewDigitalAssessemntExist &&
          !isDigitalAssessmentDataExist &&
          showClinicalAssessmentWithVersionModal &&
          (
          <Modal32
            setShowModal={setShowClinicalAssessmentWithVersionModal}
            showModal={showClinicalAssessmentWithVersionModal}
            clinicalAssessmentInfo={clinicalAssessmentInfo}
            openClinicalAssessment={clinicalAssessment}
            downloadClinicalAssessment={generateClinicalPdf}
            isMobile={true} isAdmin={false}
            generateAiAssessmentFunction={generateAiAssessmentFunction}
            firstMeetingData={firstMeetingData}
            isMeeting={true}

            />
      )}

        {/* {showAiGeneratedAssessmentPopup && <AIGeneratedAssessment
                    OpenAiModel={showAiGeneratedAssessmentPopup}
                    transcribeData={firstMeetingData}
                    closeModelFunction={handleChildFunction}
                    refreshModal32={refreshModal32}
                    isInMeeting={true}
                    />
        } */}
    </>
    // <div>
    //   <Tooltip title={titleToolTip} aria-label="add">
    //     <IconButton
    //       onClick={toggleDrawer()}
    //       edge="start"
    //       color="inherit"
    //       aria-label="mic"
    //       className={localClasses.infoButton}
    //     >
    //       <ChatIcon fontSize="inherit" />
    //     </IconButton>
    //   </Tooltip>
    //   <Drawer
    //     open={state}
    //     onClose={toggleDrawer(false)}
    //     classes={{ paper: localClasses.paper }}
    //   >
    //     <SideMenu
    //       className={localClasses.root}
    //       room={room}
    //       participants={participants}
    //       localParticipant={localParticipant}
    //       listOfMessages={listOfMessages}
    //     ></SideMenu>
    //   </Drawer>
    // </div>
  );
}
MoreOptionsButton.propTypes = {
  classes: PropTypes.object,
  participants: PropTypes.array,
  room: PropTypes.object,
  localParticipant: PropTypes.object,
  screenWidth: PropTypes.any,
};
