import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../../assets/css/admin.css";
import { CommonService } from "../../services/CommonService";
import profileBackground from "../../assets/images/default_cover.png";
import { Upload } from "../../models/Upload";
import Swal from "sweetalert2";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";
import { Util } from "../../Util";

const ViewThemeImages: React.FC = () => {
  const [themeImages, setThemeImages] = useState<Upload[]>([] as Upload[]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    CommonService.getThemesImages().then(res => {
      setIsLoading(true);
      if (res.success) {
        setThemeImages(res.data);
        setIsLoading(false);
      } else {
        setThemeImages([]);
        setIsLoading(false);
      }
    });
  }, []);

  const deleteThemeImage = (imageId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.deleteThemeImageById(imageId).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Image deleted!",
              confirmButtonColor: "#FD7F00",
            });
            setThemeImages(themeImages.filter((image: Upload) => image._id !== imageId));
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to delete the image. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"View Theme Images"} breadcrumbItem={"View Theme Images"} />
          <Row>
            <Col xl={12}>
              <NavLink
                to={"/create-theme-image"}
                className="btn btn-primary relative mb-3"
                style={{
                  bottom: "0",
                  float: "right",
                }}
              >
                Create Theme Image
              </NavLink>
            </Col>
          </Row>
          <Card className="m-h">
            <CardBody>
              {/* <Row>
                <div className="d-flex just flex-row-reverse">
                  <button className="btn btn-sm btn-danger">
                    <i className="bx bx-trash"></i> Delete Themes
                  </button>
                </div>
              </Row> */}
              {!isLoading ? (
                <Row>
                  <Col sm="12" className="mt10" style={{ display: "flex", flexWrap: "wrap" }}>
                    {themeImages.length == 0 && (
                      <div className="font-size-14 mb-5 mt-1" style={{ textAlign: "center" }}>
                        No theme images to display.
                      </div>
                    )}
                    {themeImages?.map((img: Upload, i: number) => {
                      return (
                        <Col xl={6} md={6} sm={12} xs={12} key={i} className="p-4">
                          <div className="themeImgBorder position-relative">
                            <div className="theme-delete-icon">
                              <i
                                className="bx bx-trash"
                                onClick={() => {
                                  deleteThemeImage(img._id);
                                }}
                              ></i>
                            </div>
                            <div
                              className="profileThemeImageAdmin  imageFit cursor-pointer"
                              style={{
                                backgroundImage: img == null || img == undefined ? `url(${profileBackground})` : `url(${Util.fileURL(img?._id)})`,
                              }}
                            ></div>
                          </div>
                        </Col>
                      );
                    })}
                  </Col>
                </Row>
              ) : (
                <Spinner />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default ViewThemeImages;
