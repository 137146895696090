import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CardTitle, Col, Row, Modal, CardBody, Input, Label, Button } from "reactstrap";
import { GroupChatService } from "../../../services/GroupChatService";
import images from "src/assets/images";
import GroupChatContext from "src/context/GroupChatContext";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import Close from "../../../assets/images/icons/close.png";
import { Util } from "src/Util";

toast.configure();

interface UpdateGroupChatModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  allChatGroups: GroupChatModel[];
  setAllChatGroups: (newGroups: GroupChatModel[]) => void;
}

const UpdateGroupChatModal: React.FC<UpdateGroupChatModalProps> = ({ showModal, setShowModal, allChatGroups, setAllChatGroups }: UpdateGroupChatModalProps) => {
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);
  const [isUpdatingGroup, setIsUpdatingGroup] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupType, setGroupType] = useState("default");
  const [uploadedImage, setUploadedImage] = useState({ raw: "", preview: "" });
  const [removeAlreadyUploadedIcon, setRemoveAlreadyUploadedIcon] = useState(false);

  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];

  useEffect(() => {
    if (selectedGroupData != null) {
      if (selectedGroupData.title != null) {
        setGroupTitle(selectedGroupData.title);
      }
      if (selectedGroupData.description != null) {
        setGroupDescription(selectedGroupData.description);
      }
      if (selectedGroupData.type != null && selectedGroupData.type == "PUBLIC") {
        setGroupType("PUBLIC");
      }
      if (selectedGroupData.type != null && selectedGroupData.type == "PRIVATE") {
        setGroupType("PRIVATE");
      }
    }
  }, []);

  const handleImageChange = (event: any) => {
    if (event.target.files.length > 0) {
      const extension = event.target.files[0].name.split(".").pop().toLowerCase();
      const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);

      if (isAnImage) {
        setUploadedImage({
          raw: event.target.files[0],
          preview: URL.createObjectURL(event.target.files[0]),
        });
        setRemoveAlreadyUploadedIcon(true);
      } else {
        toast.error("Unsupported image format!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return;
      }
    }
    URL.revokeObjectURL(event.target.files[0]);
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const deleteImage = () => {
    setUploadedImage({ raw: "", preview: "" });
  };

  const updateGroupChat = async () => {
    try {
      if (!isUpdatingGroup) {
        if (groupTitle == undefined || groupTitle == null || groupTitle == "" || groupTitle.trim() == "") {
          toast.error("Group title is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }
        if (groupDescription == undefined || groupDescription == null || groupDescription == "" || groupDescription.trim() == "") {
          toast.error("Group description is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }
        if (groupType == undefined || groupType == null || groupType == "") {
          toast.error("Group type is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }

        if (groupType != "PUBLIC" && groupType != "PRIVATE") {
          toast.error("Group type is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }

        const formData = new FormData();

        const groupChat = {
          groupId: selectedGroupData._id,
          groupTitle: groupTitle,
          groupType: groupType,
          groupDescription: groupDescription,
          removeAlreadyUploadedIcon: removeAlreadyUploadedIcon,
        };

        const data = JSON.stringify(groupChat);

        formData.append("groupDetails", data);
        formData.append("groupIcon", uploadedImage.raw);
        setIsUpdatingGroup(true);
        const res = await GroupChatService.updateGroupChat(formData);
        if (res && res.success) {
          setIsUpdatingGroup(false);
          try {
            if (allChatGroups != null && allChatGroups.length != null && allChatGroups.length >= 0 && res.data != null) {
              const updatedGroup: GroupChatModel = res.data;
              if (updatedGroup != null && updatedGroup._id != null && updatedGroup._id != "") {
                const indexOfObject = allChatGroups.findIndex((group: GroupChatModel) => group._id == updatedGroup._id);

                if (indexOfObject != -1) {
                  const previousChatGroups: GroupChatModel[] = allChatGroups;

                  const previousGroupIn: GroupChatModel = previousChatGroups[indexOfObject];
                  previousChatGroups[indexOfObject] = {
                    ...updatedGroup,
                    unreadMessageCount: previousGroupIn.unreadMessageCount,
                    lastActive: previousGroupIn.lastActive,
                    latestMessage: previousGroupIn.latestMessage,
                  };
                  setAllChatGroups(previousChatGroups);
                  setSelectedGroupData(updatedGroup);
                }
              }
            }
          } catch (error) {}
          toast.success("Chat group updated successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setShowModal(!showModal);
        } else {
          setIsUpdatingGroup(false);
          toast.error("Failed to update the chat group!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsUpdatingGroup(false);
      } else {
        toast.error("Please wait, group update in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsUpdatingGroup(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return showModal ? (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-body pt-1">
          {/* <button type="button" onClick={() => setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button> */}

          <Button
            close
            onClick={() => {
              if (!isUpdatingGroup) {
                setShowModal(false);
              } else {
                toast.error("Please wait, group update in progress!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            }}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
          >
            <img src={Close} alt="Close" className="" />
          </Button>

          <CardBody>
            <CardTitle className="mb-3 mt-3 text-left font-size-16">Update group chat</CardTitle>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Input
                    value={groupTitle}
                    type="text"
                    className="form-control"
                    placeholder="Title of Group"
                    name="articleTitle"
                    onChange={event => setGroupTitle(event.target.value)}
                    required
                  />
                </div>
                <div className="mb-2">
                  <Label
                    htmlFor="form-upload-file"
                    style={{
                      cursor: "pointer",
                      fontSize: "13px",
                      color: "#FD7F00",
                      marginBottom: "0px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <img src={images.addSignIcon} className="ai-icon-size me-2" />
                      Upload group icon
                    </div>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="form-upload-file"
                    style={{ display: "none" }}
                    onChange={event => handleImageChange(event)}
                    required
                  />
                </div>
                {uploadedImage.preview != "" && (
                  <>
                    <div className="pull-right">
                      <i className="fa fa-trash fa-lg remove-icon text-danger pl10" onClick={deleteImage}></i>
                    </div>
                    <div className="mb-3 upload-img">
                      <img src={uploadedImage.preview} className="upload-img-preview" />
                    </div>
                  </>
                )}
                {!removeAlreadyUploadedIcon &&
                  uploadedImage.preview == "" &&
                  selectedGroupData != null &&
                  selectedGroupData.groupIcon != null &&
                  selectedGroupData.groupIcon._id != null && (
                    <>
                      <div className="pull-right">
                        <i
                          className="fa fa-trash fa-lg remove-icon text-danger pl10"
                          onClick={() => {
                            setRemoveAlreadyUploadedIcon(true);
                          }}
                        ></i>
                      </div>
                      <div className="mb-3 upload-img">
                        <img src={Util.fileURL(selectedGroupData.groupIcon._id)} className="upload-img-preview" />
                      </div>
                    </>
                  )}
                <div className="mb-3">
                  <Input
                    value={groupDescription}
                    type="textarea"
                    className="form-control"
                    rows={3}
                    placeholder="Description"
                    onChange={event => setGroupDescription(event.target.value)}
                    style={{ resize: "none" }}
                    required
                  />
                </div>
                {/* <div className="mb-3">
                  <select
                    className="form-control"
                    value={groupType}
                    onChange={event => {
                      setGroupType(event.target.value);
                    }}
                  >
                    <option value="default">Select group type</option>
                    <option value="PUBLIC">Public</option>
                    <option value="PRIVATE">Private</option>
                  </select>
                </div> */}
                <div className="mb-3">
                  <button className={isUpdatingGroup ? "btn btn-secondary w-100" : "btn btn-primary w-100"} onClick={updateGroupChat}>
                    {isUpdatingGroup ? "Loading..." : "Update"}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Modal>
    </>
  ) : null;
};

export default UpdateGroupChatModal;
