import React, { useContext, useEffect, useState } from "react";
import GenerateAuditPdfPopUp from "../../components/Modals/GenerateAuditPdfPopUpSteps";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Modal,
  Button,
  ModalBody,
} from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";
import { toast } from "react-toastify";
import TherapyPlanPdfModal from "../Popup/TherapyPlanPdfModal";
import ClinicalAssessmentPdfModal from "../Popup/ClinicalAssessmentPdfModal";
import AuthorizationFormPdfModal from "../Popup/AuthorizationFormPdfModal";
import { AuthFormType } from "src/models/AuthorizationFormData";
import Select from "react-select";
import { AppResponse } from "src/models/Response";
import { InsuranceCompanyTags } from "src/models/InsuranceCompany";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import Close from "../../assets/images/icons/close.png";
import { UploadDocument } from "src/models/UploadDocument";
import { Util } from "../../Util";
import moment from "moment";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import Modal31 from "../Popup/Modal31";
import Modal32 from "../Popup/Modal32";
import NewClinicalAssessmentPdfModal from "../Popup/NewClinicalAssessmentPdfModal";
import { CAFormType } from "src/models/clinicalAssesment/clinicalAssesment";
import Swal from "sweetalert2";
import pending from "../../assets/images/icons/pending.svg";
import AuditSessionsPopup from "../Popup/AuditSessionsPopup";

const DocDownload: React.FC = () => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [therapistSearchableString, setTherapistSearchableString] = useState("");
  const [clientSearchableString, setClientSearchableString] = useState("");
  const [showTherapyPlanPdfModal, setShowTherapyPlanPdfModal] = useState<boolean>(false);
  const [showClinicalAssessmentPdfModal, setShowClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [showAuthorizationFormPdfModal, setShowAuthorizationFormPdfModal] = useState<boolean>(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState("");
  const [selectedTherapyPlanId, setSelectedTherapyPlanId] = useState("");
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [selectedAuthorizationFormId, setSelectedAuthorizationFormId] = useState("");
  const [authorizationFormType, setAuthorizationFormType] = useState("");
  const [InsuranceCompanyOptions, setInsuranceCompanyOptions] = useState([{ value: '', label: 'All' },]);
  const [searchableInsuranceCompany, setSearchableInsuranceCompany] = useState('');
  const [uploadDocsData, setUploadDocsData] = useState([] as UploadDocument[]);
  const [isUploadDocsView, setIsUploadDocsView] = useState(false);
  const [therapyPlanType, setTherapyPlanType] = useState("");
  const [therapyPlanData, setTherapyPlanData] = useState("");
  const [showTherapyPlanWithVersionModal, setShowTherapyPlanWithVersionModal] = useState(false);
  const [clinicalAssessmentType, setClinicalAssessmentType] = useState("");
  const [assessmentData, setAssessmentData] = useState("");
  const [showClinicalAssessmentWithVersionModal, setShowClinicalAssessmentWithVersionModal] = useState(false);
  const [showNewClinicalAssessmentPdfModal, setShowNewClinicalAssessmentPdfModal] = useState<boolean>(false);
  const [selectedCAFormType, setSelectedCAFormType] = useState("");
  const [showAuditSessionsModal, setShowAuditSessionsModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [selectedTherapistId, setSelectedTherapistId] = useState("");
  const [searchDocumentDownloadsByAdminSelected, setSearchDocumentDownloadsByAdminSelected] = useState<any[]>([]);  
  const limit = 20;
  const eventType = "DOWNLOAD";

  useEffect(() => {
    getInitialData();
    getAllInsuranceCompanies();
  }, []);

  useEffect(() => {
    if (selectedAssessmentId && selectedAssessmentId != "") {
      setShowTherapyPlanPdfModal(false);
      setShowAuthorizationFormPdfModal(false);
      if (selectedCAFormType === CAFormType.OLD_CA) {
        setShowNewClinicalAssessmentPdfModal(false);
        setShowClinicalAssessmentPdfModal(true);
      } else if (selectedCAFormType === CAFormType.NEW_CA) {
        setShowClinicalAssessmentPdfModal(false);
        setShowNewClinicalAssessmentPdfModal(true);
      }
    }
  }, [selectedAssessmentId]);

  useEffect(() => {
    if (selectedTherapyPlanId && selectedTherapyPlanId != "") {
      setShowClinicalAssessmentPdfModal(false);
      setShowNewClinicalAssessmentPdfModal(false);
      setShowAuthorizationFormPdfModal(false);
      setShowTherapyPlanPdfModal(true);
    }
  }, [selectedTherapyPlanId]);

  useEffect(() => {
    if (selectedAuthorizationFormId && selectedAuthorizationFormId != "") {
      setShowClinicalAssessmentPdfModal(false);
      setShowNewClinicalAssessmentPdfModal(false);
      setShowTherapyPlanPdfModal(false);
      setShowAuthorizationFormPdfModal(true);
    }
  }, [selectedAuthorizationFormId]);

  useEffect(() => {
    if (uploadDocsData && uploadDocsData.length > 0) {
      setIsUploadDocsView(true);
    }
  }, [uploadDocsData]);

  useEffect(() => {
    if (therapyPlanData && therapyPlanData.length > 0) {
      setShowTherapyPlanWithVersionModal(true);
    }
  }, [therapyPlanData]);

  const generateTherapyPlanPdf = (planId: string, tpType: TPType) => {
    setSelectedTherapyPlanId(planId);
    setTherapyPlanType(tpType);
    setShowTherapyPlanPdfModal(true);
  };

  const handleAssessmentClick = (data: any, selectedCAFormType: CAFormType) => {
    setAssessmentData(data);
    setSelectedCAFormType(selectedCAFormType);
  };

  useEffect(() => {
    if (assessmentData && assessmentData.length > 0) {
      setShowClinicalAssessmentWithVersionModal(true);
    }
  }, [assessmentData]);

  const generateClinicalPdf = (planId: string, tpType: TPType) => {
    setSelectedAssessmentId(planId);
    setClinicalAssessmentType(tpType);

    if (selectedCAFormType === CAFormType.OLD_CA) {
      setShowClinicalAssessmentPdfModal(true);
    } else if (selectedCAFormType === CAFormType.NEW_CA) {
      setShowNewClinicalAssessmentPdfModal(true);
    }

  };

  const getInitialData = async () => {
    try {
      setIsLoading(true);

      const data = {
        therapistSearchableString: therapistSearchableString,
        clientSearchableString: clientSearchableString,
        searchableInsuranceCompany: searchableInsuranceCompany
      };

      const res = await AdminService.searchDocumentDownloads(data, limit, 0);

      if (res && res?.success && res?.data) {
        setDocuments(res?.data);
        setIsSeeMore(res?.data?.length >= limit);
      }else{
        toast.error(`Error loading data`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }

      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      toast.error(`Error loading data`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const filterDocuments = async () => {
    try{
      setIsLoading(true);

      const data = {
        therapistSearchableString: therapistSearchableString,
        clientSearchableString: clientSearchableString,
        searchableInsuranceCompany: searchableInsuranceCompany
      };

      const res = await AdminService.searchDocumentDownloads(data, limit, 0);

      if (res && res?.success && res?.data) {
        setDocuments(res?.data);
        setIsSeeMore(res?.data?.length >= limit);
      }else{
        toast.error(`Error loading data`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }

      setIsLoading(false);
      toggleFilter();

    } catch (error) {
      setIsLoading(false);
      toast.error(`Error loading data`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const seeMore = async () => {
    try{
      if(isLoading){
        toast.error(`Please wait !`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }else{

        setIsLoading(true);

        const finalOffset = documents?.length ?? 0;

        const data = {
          therapistSearchableString: therapistSearchableString,
          clientSearchableString: clientSearchableString,
          searchableInsuranceCompany: searchableInsuranceCompany
        };

        const res = await AdminService.searchDocumentDownloads(data, limit, finalOffset);

        if (res && res?.success && res?.data) {
          res?.data?.map((data: any) => {
            setDocuments(docs => [...docs, data]);
          });
          setIsSeeMore(res?.data?.length >= limit);
        }else{
          toast.error(`Error loading data`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }

        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      toast.error(`Error loading data`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  };

  const closeAssessmentModal = (value: boolean) => {
    setSelectedAssessmentId("");
    setShowClinicalAssessmentPdfModal(value);
    setShowNewClinicalAssessmentPdfModal(value);
  };

  const closeClinicalAssessmentWithVersionModal = (value: boolean) => {
    setShowClinicalAssessmentWithVersionModal(value);
    setAssessmentData("");
    setSelectedCAFormType("");
  };

  const closeTherapyPlanWithVersionModal = (value: boolean) => {
    setShowTherapyPlanWithVersionModal(value);
    setTherapyPlanData("");
  };

  const closeTherapyPlanModal = (value: boolean) => {
    setSelectedTherapyPlanId("");
    setShowTherapyPlanPdfModal(value);
  };

  const closeAuthorizationFormModal = (value: boolean) => {
    setSelectedAuthorizationFormId("");
    setShowAuthorizationFormPdfModal(value);
  };

  const handleAuthorizationFormDownloadClick = (authorizationFormId: string, authorizationFormType: AuthFormType) => {
    setSelectedAuthorizationFormId(authorizationFormId);
    setAuthorizationFormType(authorizationFormType);
  };

  const handleChange = (selectedOption: any) => {
    setSearchableInsuranceCompany(selectedOption.value);
  };

  const customStyles = {
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '170px',
      overflowY: 'auto'
    })
  };

  const getAllInsuranceCompanies = () => {
    AuthorizationFormService.getAllInsuranceCompanyList().then((res: AppResponse<InsuranceCompanyTags[]>) => {
      if (res && res.success && res.data) {
        const dataList = res.data?.map((insuranceCompany: any) => ({
          value: insuranceCompany._id,
          label: insuranceCompany.insuranceCompany,
        }));
        InsuranceCompanyOptions.push(...dataList);
      }
    });
  };

  const toggleModalForUploadDocsView = () => {
    setUploadDocsData([]);
    setIsUploadDocsView(!isUploadDocsView);
  }

  const releaseOrHoldPayment = (e: any) => {
    const clientId = e.currentTarget.getAttribute("data-client-id");
    const therapistId = e.currentTarget.getAttribute("data-therapist-id");
    const actionType = e.currentTarget.getAttribute("data-action-type");
    Swal.fire({
        icon: "info",
        title: `Are you sure you want to <b>${actionType}</b> the payment for this therapist and client? This decision is based on the quality of the submitted assessment.`,
        html: `<p style="color: red;">This action will <b>${actionType.toUpperCase()}</b> all unpaid payments for this therapist. ${actionType == "hold" ? "Payments will remain on hold until you choose to release them again." : ""}</p>`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            clientId: clientId,
            therapistId: therapistId,
            actionType: actionType
          }
          AdminService.releaseOrHoldPaymentForSpecificTherapist(data).then(res => {
            if (res && res.success && res.data) {
              getInitialData();
              toast.success(`Payment ${actionType} successfully processed for the therapist.`, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            } else {
              toast.error(res.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            }
          });
        }
    });
  }

  return (
    <>
      <React.Fragment>
        {showClinicalAssessmentPdfModal && <ClinicalAssessmentPdfModal setShowModal={closeAssessmentModal} showModal={showClinicalAssessmentPdfModal} identifierId={selectedAssessmentId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={null} setModalRefresher={null} clinicalAssessmentType={clinicalAssessmentType as TPType} />}
        {showNewClinicalAssessmentPdfModal && <NewClinicalAssessmentPdfModal setShowModal={closeAssessmentModal} showModal={showNewClinicalAssessmentPdfModal} identifierId={selectedAssessmentId} isAdmin={false} eventType={eventType} insuranceDocApprovalId={null} setModalRefresher={null} digitalAssessmentType={clinicalAssessmentType as TPType} />}
        {showTherapyPlanPdfModal && <TherapyPlanPdfModal setShowModal={closeTherapyPlanModal} showModal={showTherapyPlanPdfModal} identifierId={selectedTherapyPlanId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={null} setModalRefresher={null} therapyPlanType={therapyPlanType as TPType} />}
        {showAuthorizationFormPdfModal && <AuthorizationFormPdfModal setShowModal={closeAuthorizationFormModal} showModal={showAuthorizationFormPdfModal} identifierId={selectedAuthorizationFormId} isAdmin={true} eventType={eventType} insuranceDocApprovalId={null} setModalRefresher={null} authorizationFormType={authorizationFormType as AuthFormType} insuranceCompanyId={null} />}
        {showTherapyPlanWithVersionModal && <Modal31 setShowModal={closeTherapyPlanWithVersionModal} showModal={showTherapyPlanWithVersionModal} therapyPlanInfo={therapyPlanData} createNewTherapyPlan={() => {}} openTherapyPlan={() => {}} downloadTherapyPlan={generateTherapyPlanPdf} isMobile={false} isAdmin={true} />}
        {showClinicalAssessmentWithVersionModal && <Modal32 setShowModal={closeClinicalAssessmentWithVersionModal} showModal={showClinicalAssessmentWithVersionModal} clinicalAssessmentInfo={assessmentData} openClinicalAssessment={() => {}} downloadClinicalAssessment={generateClinicalPdf} isMobile={false} isAdmin={true} generateAiAssessmentFunction={ () => {} } firstMeetingData={null}  isMeeting={false}/>}
        {showAuditSessionsModal && 
          <AuditSessionsPopup 
            showModal={showAuditSessionsModal} 
            setShowModal={setShowAuditSessionsModal}
            clientId={selectedClientId}
            therapistId={selectedTherapistId}
          />
        }
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"View Documents"} breadcrumbItem={"View Documents"} />

            <Card className="m-h">
              <CardBody>
                <Row>
                  <Col className="d-flex admin-filter">
                    <button className="btn btn-sm mr-2" onClick={toggleFilter}>
                      Filter <i className="bx bx-filter-alt"></i>
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="mt10">
                    {documents && documents.length > 0 ? (
                      <>
                        <div className="table-responsive" style={{ minHeight: "70vh" }}>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Client Name</th>
                                <th scope="col">Therapist Name</th>
                                <th scope="col">Meeting Count</th>
                                <th scope="col">Clinical Assessment</th>
                                <th scope="col">Sessions</th>
                                <th scope="col">Release/ Hold Payment</th>
                                <th scope="col">Therapy Plan</th>
                                <th scope="col">Authorization Form</th>
                                <th scope="col">Uploaded Documents</th>
                                <th scope="col">Audit Action</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {documents &&
                                documents.map((data: any, index: number) => (
                                  <tr key={index}>
                                    <th scope="row" style={{ textAlign: "center", verticalAlign: "middle" }}>{index + 1}</th>

                                    <td valign="middle">
                                      {data?.client?.firstname}&nbsp;{data?.client?.lastname}<br />
                                      {data?.client?.email}
                                    </td>

                                    <td valign="middle">
                                      {data?.therapist?.firstname}&nbsp;{data?.therapist?.lastname}<br />
                                      {data?.therapist?.email}
                                    </td>

                                    <td valign="middle">
                                      {data?.meetingCount ?? 0}
                                    </td>

                                    <td valign="middle">
                                      {(data?.docs?.clinicalassessment && data?.docs?.clinicalassessment.length > 0) ? (
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() => {
                                            handleAssessmentClick(data?.docs?.clinicalassessment, CAFormType.OLD_CA);
                                          }}
                                          title="View Assessment"
                                        >
                                          View
                                        </button>
                                      ) : (data?.docs?.digitalassessment && data?.docs?.digitalassessment.length > 0) ? (
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() => {
                                            handleAssessmentClick(data?.docs?.digitalassessment, CAFormType.NEW_CA);
                                          }}
                                          title="View Assessment"
                                        >
                                          View
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-secondary mr-2"
                                          title="Not found"
                                          disabled
                                        >
                                          Not found
                                        </button>
                                      )}
                                    </td>

                                    <td valign="middle">
                                      <button
                                        className="btn btn-sm btn-success"
                                        title="View Sessions"
                                        onClick={() => {
                                          setSelectedClientId(data?.client?._id || "");
                                          setSelectedTherapistId(data?.therapist?._id || "");
                                          setShowAuditSessionsModal(true);
                                        }}
                                      >
                                        {data?.sessionsCount ??0}<br />
                                      </button>
                                    </td>

                                    <td valign="middle">
                                      {
                                        data?.hasHoldTransactions === true ? (
                                          <>
                                            <button
                                              className="btn btn-sm btn-warning"
                                              onClick={releaseOrHoldPayment}
                                              data-client-id={data?.client?._id}
                                              data-therapist-id={data?.finalTherapistId}
                                              data-action-type="release"
                                              title="Release Payment"
                                            >
                                              Release
                                            </button>&nbsp;
                                            <img
                                              src={pending}
                                              className="img-fluid imageupcomming"
                                              title="Click release if the assessment quality is good enough to proceed with the payment."
                                            />
                                          </>
                                        ) : (data?.hasTransactions === true) ? (
                                          <>
                                            <button
                                              className="btn btn-sm btn-success"
                                              onClick={releaseOrHoldPayment}
                                              data-client-id={data?.client?._id}
                                              data-therapist-id={data?.finalTherapistId}
                                              data-action-type="hold"
                                              title="Hold Payment"
                                            >
                                              Hold
                                            </button>&nbsp;
                                            <img
                                              src={pending}
                                              className="img-fluid imageupcomming"
                                              title="Click hold if the assessment quality is not good enough to release the payment."
                                            />
                                          </>
                                        ) : (
                                          <>-</>
                                        )}
                                    </td>

                                    <td valign="middle">
                                      {(
                                        data?.docs?.therapyplans && data.docs.therapyplans.length > 0) ? (
                                          <>
                                            <button
                                              className="btn btn-sm btn-success"
                                              onClick={() => {
                                                setTherapyPlanData(data?.docs?.therapyplans);
                                              }}
                                              title="View Therapy Plans"
                                            >View</button>
                                          </>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-secondary mr-2"
                                          title="Not found"
                                          disabled
                                        >
                                          Not found
                                        </button>
                                      )}
                                    </td>

                                    <td valign="middle">
                                      {data?.insuranceCompanies && data.insuranceCompanies.length > 0 ? (
                                        data.insuranceCompanies.map((company: any, index: any) => (
                                          <React.Fragment key={index}>
                                            {company.authorizationForms && company.authorizationForms._id ? (
                                              <button
                                                className={`btn btn-sm mr-2 ${company.isPrimary ? 'btn-primary' : 'btn-success'}`}
                                                onClick={() => handleAuthorizationFormDownloadClick(
                                                  company.authorizationForms._id,
                                                  company.authorizationForms.authFormType as AuthFormType
                                                )}
                                                title={`Download Authorization Form: ${company.authorizationForms.authFormType}`}
                                              >
                                                {company.isPrimary ? 'Primary' : `Secondary`}
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-sm btn-secondary mr-2"
                                                title="Not found"
                                                disabled
                                              >
                                                Not found
                                              </button>
                                            )}
                                          </React.Fragment>
                                        ))
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-secondary mr-2"
                                          title="Not found"
                                          disabled
                                        >
                                          Not found
                                        </button>
                                      )}
                                    </td>

                                    <td valign="middle">
                                      {(
                                        data?.uploadDocuments && data.uploadDocuments.length > 0) ? (
                                          <>
                                            <button
                                              className="btn btn-sm btn-success"
                                              onClick={() => {
                                                setUploadDocsData(data?.uploadDocuments);
                                              }}
                                              title="View Uploaded Documents"
                                            >View</button>
                                          </>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-secondary mr-2"
                                          title="Not found"
                                          disabled
                                        >
                                          Not found
                                        </button>
                                      )}
                                    </td>

                                    {/* <td>
                                      <div className="flex">
                                        {data?.client?.lavniTestAccount && <span className="test-account ms-2 test-account-card">#Test Account</span>}
                                      </div>
                                    </td> */}

                                    <td valign="middle">
                                      <button
                                        className="btn btn-sm btn-success"
                                        title="View Generate"
                                        onClick={() => {
                                          setSearchDocumentDownloadsByAdminSelected(data);
                                          setIsGenerateModalOpen(true);
                                        }}
                                      >
                                        Generate
                                      </button>
                                    </td>



                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          <GenerateAuditPdfPopUp
                            isOpen={isGenerateModalOpen}
                            toggle={() => setIsGenerateModalOpen(!isGenerateModalOpen)}
                            searchDocumentDownloadsByAdminSelected={searchDocumentDownloadsByAdminSelected}
                          />
                        </div>
                        {isSeeMore && (
                          <>
                            <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                              {
                                isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  : <>See More</>
                              }
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>{isLoading ? <Spinner /> : <div>No Documents</div>}</>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Offcanvas className="offcanvas-end2" isOpen={isFilterOptions} direction="end" toggle={toggleFilter}>
              <OffcanvasHeader toggle={toggleFilter}>Filter Documents</OffcanvasHeader>

              <OffcanvasBody>
                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Search by Client Name or Email</Label>
                      <Input
                        id="search"
                        name="text"
                        placeholder="Search Users (ex: first name, last name or email)"
                        type="text"
                        onChange={e => setClientSearchableString(e.target.value)}
                        value={clientSearchableString}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Search by Therapist Name or Email</Label>
                      <Input
                        id="search"
                        name="text"
                        placeholder="Search Users (ex: first name, last name or email)"
                        type="text"
                        onChange={e => setTherapistSearchableString(e.target.value)}
                        value={therapistSearchableString}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12}>
                    <FormGroup>
                      <Label>Search by Insurance Company</Label>
                      <Select
                        styles={customStyles}
                        id="insuranceCompanyName"
                        options={InsuranceCompanyOptions}
                        onChange={handleChange}
                        value={InsuranceCompanyOptions.find(option => option.value === searchableInsuranceCompany)}
                        placeholder="Select a Insurance Company"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col lg={12}>
                    <button className={`btn ${isLoading ? "btn-primary" : "btn-success"} w-100`} onClick={() => {
                        if(!isLoading){
                          filterDocuments();
                        }else{
                          toast.error(`Please wait !`, {
                            position: toast.POSITION.TOP_RIGHT,
                            className: "foo-bar",
                          });
                        }
                      }}
                    >
                      {isLoading ? "Loading..." : "Search"}
                    </button>
                  </Col>
                </Row>

              </OffcanvasBody>
            </Offcanvas>

            <Modal isOpen={isUploadDocsView} toggle={toggleModalForUploadDocsView} centered className="modal-md" backdrop="static" keyboard={false}>
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleModalForUploadDocsView}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="p-4">
                <h2 className="text-center mb-4">View Uploaded Documents</h2>
                <div className="bg-light p-4 rounded-3 shadow-sm">
                  {uploadDocsData ? (
                    <Row>
                      {uploadDocsData.map((uploadDoc: UploadDocument, index: number) => {
                        return (
                          <Col lg={12} key={index}>
                            <Card>
                              <CardBody>
                                <div className="attachment">
                                  <p className="hw-attach" id="up">
                                    <i className="bx bx-paperclip"></i>&nbsp;
                                    <span onClick={() => window.open(Util.fileURL(uploadDoc?.uploadDocumentId?._id), "_blank")}>
                                      {uploadDoc?.uploadDocumentId?.originalName}
                                    </span>
                                    <div>
                                      <small className="mb-0 text-muted">{moment.utc(uploadDoc?.uploadDocumentId?.createdAt).format("YYYY-MM-DD HH:mm:ss A")}</small>
                                    </div>
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (<p className="mb-0">No documents available</p>)}
                 </div>
              </ModalBody>
            </Modal>

          </Container>
        </div>
      </React.Fragment>
    </>

  );
};

export default DocDownload;
