import { Client } from "./Client";

export enum TransactionType {
    EARNING = "EARNING",
    WITHDRAWAL = "WITHDRAWAL"
}

export enum MeetingType {
    THIRTYMINUTUES = "30",
    SIXTYMINUTUES = "60"
}

export interface TransactionMeeting {
    _id: string;
    transcribeAllowed: boolean;
    transcribingInProcess: boolean;
    accepted: boolean;
    clientId: Client;
    meetingId: string;
    meetingType: string;
    meetingDuration: number;
    spentDuration: number;
}

export interface Transaction {
    _id: string;
    therapistId: string;
    type: TransactionType;
    transactionAmount: number;
    accumulatedBalance: number;
    accumulatedTotalEarnings: number;
    accumulatedWithdrawals: number;
    meetingId: TransactionMeeting;
    createdAt: Date;
    updatedAt: Date;
    paidStatus?: string;
    eligibleForPayment? : boolean;
}

export interface TransactionPaymemt {
    paidStatus: string;
    _id: string;
    role: string;
    email: string;
    firstname: string;
    lastname: string;
    stripeConnectedAccountId: string;
    recentTransaction: any;
}

export interface TransactionTherapistPaymemt {
    timePeriodEndAt?: Date;
    timePeriodStartAt?: Date;
    _id: string;
    therapistTransaction: TransactionPaymemt[];
    verifiedStatus: string;
    createdAt: Date;
}

