export enum HStatus {
    NEW = "NEW",
    PENDING = "PENDING",
    FINISHED = "FINISHED",
    REJECT = "REJECT",
    APPROVED = "APPROVED",
    ONGOING = "ONGOING",
    COMPLETED = "COMPLETED",
}

export interface HomeWork {
    _id?: string;
    title: string,
    description: string,
    uploads?: any ,
    dueDate: string;
    isComplete?: boolean;
    createdBy?: string;
    assignedFor?: string;
    status?: string;
    deletingUploadIds?: any[];
}
