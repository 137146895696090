import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { CommonService } from "src/services/CommonService";
import PhoneInput from "react-phone-number-input";
import { set } from "lodash";

const FormTen = (props: any) => {
    const { formId, assesmenData, getAssesmentByClientId, customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })

    const [edit, setEdit] = useState<boolean>(true)

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 10,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                clientSaftyPlan: assesmenData.clientSaftyPlan
            }
            setFormData(updateAssesment);
        }
    }, [assesmenData, customActiveTab])

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 10,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            clientSaftyPlan: formData.clientSaftyPlan
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });

                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Client Safety Plan</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <h6 className="fw-600">Step 1 : People whom I can ask for help :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name1"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.people?.name1?.name || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name1: {
                                                                        ...prevState.clientSaftyPlan?.people?.name1,
                                                                        name: e.target.value
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone1"
                                                    defaultCountry="US"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.people?.name1?.phone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name1: {
                                                                        ...prevState.clientSaftyPlan?.people?.name1,
                                                                        phone: e
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name2"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.people?.name2?.name || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name2: {
                                                                        ...prevState.clientSaftyPlan?.people?.name2,
                                                                        name: e.target.value
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    type="text"
                                                    id="phone2"
                                                    readOnly={!edit}
                                                    defaultCountry="US"
                                                    value={formData.clientSaftyPlan?.people?.name2?.phone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name2: {
                                                                        ...prevState.clientSaftyPlan?.people?.name2,
                                                                        phone: e
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name3"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.people?.name3?.name || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name3: {
                                                                        ...prevState.clientSaftyPlan?.people?.name3,
                                                                        name: e.target.value
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone3"
                                                    defaultCountry="US"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.people?.name3?.phone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                people: {
                                                                    ...prevState.clientSaftyPlan?.people,
                                                                    name3: {
                                                                        ...prevState.clientSaftyPlan?.people?.name3,
                                                                        phone: e
                                                                    }
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 2 : Professionals or agencies I can contact during a crisis :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Clinician Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly={!edit}
                                                    id="clinicianName"
                                                    value={formData.clientSaftyPlan?.professionals?.clinicianName || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals,
                                                                    clinicianName: e.target.value
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Clinicianphone"
                                                    defaultCountry="US"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.professionals?.clinicianPhone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals, clinicianPhone: e
                                                                }
                                                            }
                                                        }))
                                                    }
                                                // onChange={(e) =>
                                                //     setFormData((prevState) => ({
                                                //         ...prevState,
                                                //         clientSaftyPlan: {
                                                //             ...prevState.clientSaftyPlan,
                                                //             professionals: {
                                                //                 ...prevState.clientSaftyPlan?.professionals, clinicianPhone: e.target.value
                                                //             }
                                                //         }
                                                //     }))
                                                // }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">At-Home Counseling Services Emergency Contact # : 919-906-7042</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Clinician/Doctor’s Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input
                                                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="DoctorsName"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.professionals?.docotorName || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals, docotorName: e.target.value
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Doctorsphone"
                                                    defaultCountry="US"
                                                    readOnly={!edit}
                                                    value={formData.clientSaftyPlan?.professionals?.docotorPhone || ""}
                                                    onChange={(e: string) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals, docotorPhone: e
                                                                }
                                                            }
                                                        }))
                                                    }
                                                // onChange={(e) =>
                                                //     setFormData((prevState) => ({
                                                //         ...prevState,
                                                //         clientSaftyPlan: {
                                                //             ...prevState.clientSaftyPlan,
                                                //             professionals: {
                                                //                 ...prevState.clientSaftyPlan?.professionals, docotorPhone: e.target.value
                                                //             }
                                                //         }
                                                //     }))
                                                // }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Local Urgent Care Services Address : (call 911)</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly={!edit}
                                                    id="care services address"
                                                    value={formData.clientSaftyPlan?.professionals?.careServiceAddress || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals,
                                                                    careServiceAddress: e.target.value
                                                                }
                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">4. Suicide Prevention Lifeline Phone : 1-800-273-TALK (8255)</h6>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 3 : Making the environment safe :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly={!edit}
                                                    id="environment1"
                                                    value={formData.clientSaftyPlan?.professionals?.enviornmentSafe?.[1] || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals,
                                                                    enviornmentSafe: {
                                                                        ...prevState.clientSaftyPlan?.professionals?.enviornmentSafe,
                                                                        1: e.target.value
                                                                    }
                                                                }

                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly={!edit}
                                                    id="environment2"
                                                    value={formData.clientSaftyPlan?.professionals?.enviornmentSafe?.[2] || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals,
                                                                    enviornmentSafe: {
                                                                        ...prevState.clientSaftyPlan?.professionals?.enviornmentSafe,
                                                                        2: e.target.value
                                                                    }
                                                                }

                                                            }
                                                        }))
                                                    }

                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            I,
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.clientSaftyPlan?.professionals?.agreement?.agree || ""}
                                                            onChange={(e) =>
                                                                setFormData((prevState) => ({
                                                                    ...prevState,
                                                                    clientSaftyPlan: {
                                                                        ...prevState.clientSaftyPlan,
                                                                        professionals: {
                                                                            ...prevState.clientSaftyPlan?.professionals,
                                                                            agreement: {
                                                                                ...prevState.clientSaftyPlan?.professionals?.agreement,
                                                                                agree: e.target.value
                                                                            }
                                                                        }

                                                                    }
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-lg-auto col-sm-12" style={{ paddingInlineEnd: "inherit" }}>
                                            agree to call one of the above numbers if I feel like harming myself.
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col xl={6}>
                                    <div className="align-items-center">
                                        <div className="row">
                                            <div className="col-sm-7 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Client signature:
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="clientSignature1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-5 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Date :
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input style={{ width: "90%" }}
                                                        type="date"
                                                        className="form-control"
                                                        id="date1"
                                                        value={formData.clientSaftyPlan?.professionals?.agreement?.clientDate || ""}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                clientSaftyPlan: {
                                                                    ...prevState.clientSaftyPlan,
                                                                    professionals: {
                                                                        ...prevState.clientSaftyPlan?.professionals,
                                                                        agreement: {
                                                                            ...prevState.clientSaftyPlan?.professionals?.agreement,
                                                                            clientDate: e.target.value
                                                                        }
                                                                    }

                                                                }
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div className="align-items-center">
                                        <div className="row">
                                            <div className="col-sm-7 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Therapist:
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="clientSignature2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-5 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Date :
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input style={{ width: "90%" }}
                                                        type="date"
                                                        className="form-control"
                                                        id="date2"
                                                        value={formData.clientSaftyPlan?.professionals?.agreement?.therapistDate || ""}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                clientSaftyPlan: {
                                                                    ...prevState.clientSaftyPlan,
                                                                    professionals: {
                                                                        ...prevState.clientSaftyPlan?.professionals,
                                                                        agreement: {
                                                                            ...prevState.clientSaftyPlan?.professionals?.agreement,
                                                                            therapistDate: e.target.value
                                                                        }
                                                                    }

                                                                }
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Client Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date1"
                                                    value={formData.clientSaftyPlan?.professionals?.agreement?.clientDate || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals,
                                                                    agreement: {
                                                                        ...prevState.clientSaftyPlan?.professionals?.agreement,
                                                                        clientDate: e.target.value
                                                                    }
                                                                }

                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Therapist Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date2"
                                                    value={formData.clientSaftyPlan?.professionals?.agreement?.therapistDate || ""}
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            clientSaftyPlan: {
                                                                ...prevState.clientSaftyPlan,
                                                                professionals: {
                                                                    ...prevState.clientSaftyPlan?.professionals,
                                                                    agreement: {
                                                                        ...prevState.clientSaftyPlan?.professionals?.agreement,
                                                                        therapistDate: e.target.value
                                                                    }
                                                                }

                                                            }
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-500" style={{ fontStyle: "italic", textAlign: "center" }}>[Fill out both of these copies – tear on dotted line and give 2nd copy below to client.]</h6>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <hr style={{ marginTop: "unset", borderStyle: "dotted", borderColor: "gray", borderWidth: "1px" }} />

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Client Safety Plan</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <h6 className="fw-600">Step 1 : People whom I can ask for help :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone1"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone2"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name3"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="phone3"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 2 : : Professionals or agencies I can contact during a crisis :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1. Clinician Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="clinicianName"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Clinicianphone"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">At-Home Counseling Services Emergency Contact # : 919-906-7042</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2. Clinician/Doctor’s Name :</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="DoctorsName"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Phone :</h6>
                                            </div>
                                            <div className="col">
                                                <PhoneInput
                                                    id="Doctorsphone"
                                                    defaultCountry="US"
                                                    onChange={(e) => console.log(e)}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>3. Local Urgent Care Services Address : (call 911)</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="Doctorsphone"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <h6 className="fw-500">4. Suicide Prevention Lifeline Phone : 1-800-273-TALK (8255)</h6>
                            </Row>

                            <Row>
                                <h6 className="fw-600">Step 3 : Making the environment safe :</h6>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>1.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="environment1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>2.</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="environment2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            I,
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-lg-auto col-sm-12" style={{ paddingInlineEnd: "inherit" }}>
                                            agree to call one of the above numbers if I feel like harming myself.
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col xl={6}>
                                    <div className="align-items-center">
                                        <div className="row">
                                            <div className="col-sm-7 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Client signature:
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="clientSignature1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-5 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Date :
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input style={{ width: "90%" }}
                                                        type="date"
                                                        className="form-control"
                                                        id="date1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div className="align-items-center">
                                        <div className="row">
                                            <div className="col-sm-7 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Therapist:
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="clientSignature2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-5 d-flex align-items-center">
                                                <div className="col-auto mt-1">
                                                    <h6>
                                                        Date :
                                                    </h6>
                                                </div>
                                                <div style={{ margin: "0.5rem" }}>
                                                    <Input style={{ width: "90%" }}
                                                        type="date"
                                                        className="form-control"
                                                        id="date2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Client Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                     type="date"
                                                     className="form-control"
                                                     id="date1"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Therapist Signature:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    className="form-control"
                                                    id="clientSignature2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={3} md={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date:</h6>
                                            </div>
                                            <div className="col">
                                                <Input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    className="form-control"
                                                    id="date2"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&

                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 10 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormTen;