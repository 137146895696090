import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import axios from "axios";
import { AppointmentsCount, BlockedDate, DiscoverTherapist, SearchTherapistName, Therapist } from "../models/Therapist";
import { Qualification } from "../models/Qualification";
import { License } from "../models/License";
import { Profession, ProfessionLicense } from "../models/Profession";
import { PinDetails, UserDetails } from "../models/User";
import { TreatmentHistory, TreatmentHistoryData } from "../models/TreatmentHistory";
import { FriendRequest } from "../models/FriendRequest";
import { TherapistDetails } from "src/models/DiagnosisNote";
import { Appointment } from "src/models/Appointment";
import { Insurance } from "src/models/Insurance";

export class TherapistService {
  public static async searchTherapists(data: Partial<DiscoverTherapist>, limit: number, offset: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("searchTherapists/" + limit + "/" + offset);

    return await axios.post<Partial<DiscoverTherapist>, AppResponse<any>>(url, data);
  }

  public static async searchTherapistsPublic(data: Partial<DiscoverTherapist>, limit: number, offset: number): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("searchTherapists/" + limit + "/" + offset);

    return await axios.post<Partial<DiscoverTherapist>, AppResponse<any>>(url, data);
  }
  public static async getAllEducations(data: string): Promise<AppResponse<Qualification[]>> {
    const url = Util.apiAuthUrl("getEducationalDetailsByUserId/" + data);
    return await axios.get<Partial<Qualification>, AppResponse<Qualification[]>>(url);
  }

  public static async getAllLicenses(data: string): Promise<AppResponse<License[]>> {
    const url = Util.apiAuthUrl("getLisenceDetailsByUserId/" + data);
    return await axios.get<Partial<License>, AppResponse<License[]>>(url);
  }

  public static async getProfessions(limit?: number, offset?: number): Promise<AppResponse<Profession[]>> {
    const url = Util.apiAuthUrl(`getProfessions/${limit}/${offset}`);
    return await axios.get<Partial<Profession>, AppResponse<Profession[]>>(url);
  }

  public static async getProfessionsPublic(): Promise<AppResponse<Profession[]>> {
    const url = Util.apiPublicUrl(`getProfessions`);
    return await axios.get<Partial<Profession>, AppResponse<Profession[]>>(url);
  }

  public static async getProfessionLicense(): Promise<AppResponse<ProfessionLicense[]>> {
    const url = Util.apiAuthUrl("getProfessionLicense");
    return await axios.get<Partial<ProfessionLicense>, AppResponse<ProfessionLicense[]>>(url);
  }

  public static async getTherapistDetailsbyId(id: string): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl("getUserByUserId/" + id);
    return await axios.get<Partial<Therapist>, AppResponse<Therapist>>(url);
  }

  public static async getTherapistDetailsbyIdPublic(id: string): Promise<AppResponse<Therapist>> {
    const url = Util.apiPublicUrl("getUserByUserId/" + id);
    return await axios.get<Partial<Therapist>, AppResponse<Therapist>>(url);
  }

  public static async getTherapistDetailsByFullName(fullName: string): Promise<AppResponse<Therapist>> {
    const url = Util.apiPublicUrl("getUserByFirstNameLastName/" + fullName);
    return await axios.get<Partial<Therapist>, AppResponse<Therapist>>(url);
  }

  public static async getAllStatisticsOfAppointments(id: any): Promise<AppResponse<AppointmentsCount>> {
    const url = Util.apiAuthUrl("getAllPendingAndCompletedAppointmentsByUser/" + id);
    return await axios.get<Partial<AppointmentsCount>, AppResponse<AppointmentsCount>>(url);
  }

  // admin

  public static async getTherapistDetailsbyIdAdmin(therapistId: any, clientId: any): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl("getUserByUserIdAdmin/" + therapistId + "/" + clientId);
    return await axios.get<Partial<Therapist>, AppResponse<Therapist>>(url);
  }

  public static async createTreatmentHistory(data: Partial<TreatmentHistory>): Promise<AppResponse<TreatmentHistory>> {
    const url = Util.apiAuthUrl("treatmentHistory");

    return await axios.post<Partial<TreatmentHistory>, AppResponse<TreatmentHistory>>(url, data);
  }

  public static async getTreatmentHistory(clientId: string, limit: number, offset: number): Promise<AppResponse<TreatmentHistoryData[]>> {
    const url = Util.apiAuthUrl("treatmentHistory/" + clientId + "/" + limit + "/" + offset);
    return await axios.get<Partial<TreatmentHistory>, AppResponse<TreatmentHistoryData[]>>(url);
  }

  public static async getTreatmentHistoryByTherapistId(therapistId: string, limit: number, offset: number): Promise<AppResponse<TreatmentHistoryData[]>> {
    const url = Util.apiAuthUrl("treatmentHistoryBy/" + therapistId + "/" + limit + "/" + offset);
    return await axios.get<Partial<TreatmentHistory>, AppResponse<TreatmentHistoryData[]>>(url);
  }

  public static async deleteTreatmentHistory(id: string): Promise<AppResponse<TreatmentHistory>> {
    const url = Util.apiAuthUrl("deleteTreatmentHistory/" + id);
    return await axios.delete<Partial<TreatmentHistory>, AppResponse<TreatmentHistory>>(url);
  }

  public static async updateTreatmentHistory(data: Partial<TreatmentHistory>): Promise<AppResponse<TreatmentHistory>> {
    const url = Util.apiAuthUrl("updateTreatmentHistory");

    return await axios.post<Partial<TreatmentHistory>, AppResponse<TreatmentHistory>>(url, data);
  }

  public static async updateClientRequestState(data: any): Promise<AppResponse<Request>> {
    const url = Util.apiAuthUrl("updateRequestByTherapist");
    return await axios.post<Partial<Request>, AppResponse<Request>>(url, data);
  }

  public static async updateProfileVideo(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateProfileVideo");
    return await axios.post<Partial<Request>, AppResponse<UserDetails>>(url, data);
  }

  public static async unfriendClient(requestId: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("unfriendUser/" + requestId);
    return await axios.post<Partial<Therapist>, AppResponse<string>>(url);
  }

  public static async getAllAddedTherapists(limit: number, offset: number): Promise<AppResponse<FriendRequest[]>> {
    const url = Util.apiAuthUrl(`viewAllRequestsByClient/${limit}/${offset}`);
    return await axios.get<Partial<FriendRequest>, AppResponse<FriendRequest[]>>(url);
  }

  public static async createPinNumber(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("create-pin");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async updatePinNumber(data: Partial<PinDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("update-pin");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async updateAiGenerateCount(data: any): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl("update-aiGenerateCount");

    return await axios.post<Partial<Therapist>, AppResponse<Therapist>>(url, data);
  }

  public static async getAiGenerateCount(): Promise<AppResponse<number>> {
    const url = Util.apiAuthUrl("get-aiGenerateCount");
    return await axios.get<Partial<number>, AppResponse<number>>(url);
  }

  public static async addTherapistReview(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("therapist-review");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getDashboardStats(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("therapist-dashboard-stats");
    return await axios.get<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getDashboardEarningsStat(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("therapist-earnings");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getDashboardSessionStat(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("therapist-sessions");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async sendReferrealEmail(data: object): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("send-referral-linkByMail");
    return await axios.post<Partial<Therapist>, AppResponse<string>>(url, data);
  }

  public static async sendReferrealMessage(data: object): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("send-referral-linkBySMS");
    return await axios.post<Partial<Therapist>, AppResponse<string>>(url, data);
  }

  public static async getSessions(data: any, therapistId: any, limit: number, offset: number): Promise<AppResponse<TreatmentHistoryData[]>> {
    const url = Util.apiAuthUrl(`treatmentHistoryBy/${therapistId}/${limit}/${offset}`);
    return await axios.post<Partial<TreatmentHistoryData[]>, AppResponse<TreatmentHistoryData[]>>(url, data);
  }

  public static async makeClientInactive(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("make-client-inactive");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async validateAppoimentsAvailable(data: any): Promise<AppResponse<Appointment[]>> {
    const url = Util.apiAuthUrl("validate-appoiments-available");

    return await axios.post<Partial<BlockedDate[]>, AppResponse<Appointment[]>>(url, data);
  }

  public static async updateBlockedDates(data: any): Promise<AppResponse<BlockedDate[]>> {
    const url = Util.apiAuthUrl("update-blocked-dates");

    return await axios.post<Partial<BlockedDate[]>, AppResponse<BlockedDate[]>>(url, data);
  }

  public static async updateTherapistAvailableTimes(data: Partial<Therapist>): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl("updateTherapistAvailableTimes");

    return await axios.post<Partial<Therapist>, AppResponse<Therapist>>(url, data);
  }

  public static async updateTherapistSignature(data: any): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl("updateTherapistSignature");

    return await axios.post<Partial<Therapist>, AppResponse<Therapist>>(url, data);
  }

  public static async getTherapistSignaturebyId(id: string): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl("getUserSignatureByUserId/" + id);
    return await axios.get<Partial<Therapist>, AppResponse<Therapist>>(url);
  }

  public static async searchTherapistsByTherapist(data: Partial<any>, limit: number, offset: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("searchMatchedTherapistsWithoutOwnTherapistByClientId/" + limit + "/" + offset);

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateSubTherapistTreatmentHistory(data: Partial<any>): Promise<AppResponse<TreatmentHistory>> {
    const url = Util.apiAuthUrl("addSubTherapistTreatmentHistory");

    return await axios.post<Partial<TreatmentHistory>, AppResponse<TreatmentHistory>>(url, data);
  }
  public static async getTherapistDetailsbyIdPublicLimited(id: string): Promise<AppResponse<Therapist>> {
    const url = Util.apiPublicUrl("getTherapistByUserIdLimited/" + id);
    return await axios.get<Partial<Therapist>, AppResponse<Therapist>>(url);
  }

  public static async createRegularMeetingForTWilio(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("createRegularMeetingForTWilio");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateAINoteType(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("updateAINoteType");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchClientInsuranceDocumentsByTherapist(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchClientInsuranceDocumentsByTherapist/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async submitDocsForAdminApproval(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`submitDocsForAdminApproval`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getInsuranceByClientIdViaTherapist(clientId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getInsuranceByClientIdViaTherapist/" + clientId);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }
  
  public static async getAudioPresignUrlForSessionHistory(data: {meetingId: string, path: string}): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getPresignUrlForAudioMeeting");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  
  public static async getTherapistScoreByTherapistId(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getTherapistScoreByTherapistId`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  // public static async getFirstMeetingByClientId(clientId: string): Promise<AppResponse<any>> {
  //   const url = Util.apiAuthUrl("getFirstMeetingByClientId/" + clientId);
  //   return await axios.get<Partial<any>, AppResponse<any>>(url);
  // }

  public static async getPreviousAIGeneratedAssessment(data: {meetingId: string, clientId: string}): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getPreviousAIGeneratedAssessment/");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async generateOpenAIAssessment(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("generateOpenAIAssessment");
    const data = { meetingId: meetingId };
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

}
