import React, { useState, useContext } from 'react';
import { FormGroup, Input, Row, Col } from 'reactstrap';
import moment from "moment";
import { Repeat } from "src/models/Appointment";
import { Util } from "src/Util";
import UserContext from "src/context/UserContext";

export default function AppointmentForm({ onSubmit, selectedDate, isSubmitting }) {
  const [user] = useContext(UserContext);
  const [appointment, setAppointment] = useState({
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("MM/DD/YYYY"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    selectedDate: "",
  });

  // Update form when selectedDate changes
  React.useEffect(() => {
    if (selectedDate?.start) {
      const startMoment = moment(selectedDate.start);
      setAppointment(prev => ({
        ...prev,
        title: "Lavni Therapy session",
        startTime: startMoment.format('hh:mm A'),
        endTime: moment(startMoment).add(1, 'hour').format('hh:mm A'),
        date: startMoment.format('MM/DD/YYYY'),
        selectedDate: startMoment.toISOString()
      }));
    }
  }, [selectedDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(appointment);
  };

  return (
    <form onSubmit={handleSubmit} className="awsome-area">
      <div className="title-input mb-4">
        <FormGroup>
          <Input
            className="modal-title new-input-v"
            name="title"
            type="text"
            placeholder="Add title - Optional"
            onChange={e => setAppointment({ ...appointment, title: e.target.value })}
            value={appointment.title}
            disabled={isSubmitting}
          />
          <span className="awsome_input_border" />
        </FormGroup>
      </div>

      <Row className="mb-4">
        <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
          <i className="bx bx-time-five"></i>
        </Col>
        <Col lg={11} md={11} sm={11} xs={12}>
          <Row>
            <Col lg={4} md={4} sm={4} xs={6} className="sm-mb5">
              <Input
                type="text"
                value={appointment.date}
                disabled={true}
              />
            </Col>
            <Col lg={8} md={8} sm={8} xs={6}>
              <Input
                type="text"
                value={appointment.startTime}
                disabled={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
          <i className="bx bx-video"></i>
        </Col>
        <Col lg={11} md={11} sm={11} xs={12}>
          Video - 1 Hour Session
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
          <i className={Util.meetingRepeatTypeIcon(appointment.repeat)}></i>
        </Col>
        <Col lg={11} md={11} sm={11} xs={12}>
          <Input
            type="select"
            name="repeat"
            value={appointment.repeat}
            onChange={e => setAppointment({ ...appointment, repeat: e.target.value })}
            disabled={isSubmitting}
          >
            <option value={Repeat.DOES_NOT_REPEAT}>Does not repeat</option>
          </Input>
        </Col>
      </Row>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={isSubmitting}
        >
          Create Appointment
        </button>
      </div>
    </form>
  );
}
