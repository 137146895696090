import { toast } from "react-toastify";
import { environment } from "src/environment/environment";
import { v4 as uuidv4 } from "uuid";

const WAITING_ROOM_PATH = 'session/waiting-room';

export const encodeStringAndStartMeeting = (text: string, receiverId: string, receiverRole: string, loggedUserId?: string, loggedUserRole?: string) => {
  try {

    const storedData = localStorage.getItem('callContext');
    
    let callContext = null;
    if (storedData) {
      try {
        callContext = JSON.parse(storedData);
      } catch (error) {
        console.error("Error parsing callContext from localStorage:", error);
        callContext = null;
      }
    }
    if (callContext && typeof callContext === 'object') {
      console.log("YYY");
      
      if (callContext.alreadyInCall) {
        toast.error('A call window is open. Please close the call window tab and try again.');
      } else {
        joinToMeetingCallInitiator(text, receiverId, loggedUserId, loggedUserRole);
      }
    } else {
      joinToMeetingCallInitiator(text, receiverId, loggedUserId, loggedUserRole);
    }
    
  } catch (error) {
    console.error("Error occured while joining to Vonage video Sdk call: "+ error);
  }
};

const joinToMeetingCallInitiator = (text: string, receiverId: string, loggedUserId?: string, loggedUserRole?: string) => {
  const uniqueUrl = generateUniqueString();
  const encodedString = encodeURIComponent(text);
  const encodeValue = btoa(encodedString); 
  const encodedString2 = encodeURIComponent('no');
  const encodeValue2 = btoa(encodedString2); 
  console.log(encodeValue2);
  
  const callData = {
    callInitiatorId: loggedUserId,
    receiverId: receiverId,  // use parameter value
    isCallInitiator: true,
    secretKeyForJoinMeeting: encodeValue2,
    alreadyInCall: true,
    loggedUserRole: loggedUserRole
  };
  localStorage.setItem("callContext", JSON.stringify(callData));
  window.open(`${environment.app_url}/${WAITING_ROOM_PATH}/${uniqueUrl}/${encodeValue}`, "_blank");
}

export const decodeString = (encodedText: string) => {
  try {
    const decodedString = atob(encodedText); // using atob for base64 decoding
    return decodeURIComponent(decodedString);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

const generateUniqueString = () => {
  const timestamp = Date.now().toString(36);
  const uuid = uuidv4();
  return `${timestamp}-${uuid}`;
};

export const joinToVonageNativeVideoCall = (vonageSessionName: string) => {
  const middlePart = WAITING_ROOM_PATH;
  const encodedString = encodeURIComponent('no');
  const encodeValue = btoa(encodedString); 
  window.open(`${environment.app_url}/${middlePart}/${vonageSessionName}/${encodeValue}`, "_blank");
}
