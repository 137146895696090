import { makeStyles } from '@material-ui/core/styles';
export default makeStyles({
  root: {
    top: '0',
    background: ({ networkStatus }) => {
      if (networkStatus === 'disconnected') return '#e25141';
      if (networkStatus === 'reconnecting') return '#f19c38';
      if (networkStatus === 'reconnected') return '#4caf50';
    },
    justifyContent: 'center',
  },
  info: {
    top: '0',
    background: '#418be9',
    justifyContent: 'center',
  },
  action: {
    position: 'absolute',
    right: '0',
    paddingRight: '10px',
  },
  networkStatusIcons: {
    paddingRight: '10px',
  },
  snackBarContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  popupContent: {
    background: 'white',
    borderRadius: '8px',
    padding: '24px',
    minWidth: '320px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  modalText: {
    fontSize: '15px',
    color: '#1a1a1a',
    marginBottom: '20px',
    fontWeight: 500,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
  },
  button: {
    fontSize: '14px',
    padding: '8px 16px',
    minWidth: '120px',
    height: '36px',
    borderRadius: '18px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 500,
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
  },
  confirmButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    '&:hover': {
      backgroundColor: '#43a047',
    },
  },
  cancelButton: {
    backgroundColor: '#f5f5f5',
    color: '#666',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
});
