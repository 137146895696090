import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { CardTitle, Col, Row, Modal, CardBody, Input, Label, Button } from "reactstrap";
import { GroupChatService } from "../../../services/GroupChatService";
import images from "src/assets/images";
import GroupChatContext from "src/context/GroupChatContext";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import Close from "../../../assets/images/icons/close.png";
import moment from "moment";

toast.configure();

interface CreateGroupChatModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  allChatGroups: GroupChatModel[];
  setAllChatGroups: (newGroups: GroupChatModel[]) => void;
  selectedChatType: string;
}

const CreateGroupChatModal: React.FC<CreateGroupChatModalProps> = ({
  showModal,
  setShowModal,
  allChatGroups,
  setAllChatGroups,
  selectedChatType,
}: CreateGroupChatModalProps) => {
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupType, setGroupType] = useState("default");
  const [uploadedImage, setUploadedImage] = useState({ raw: "", preview: "" });

  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];

  const handleImageChange = (event: any) => {
    if (event.target.files.length > 0) {
      const extension = event.target.files[0].name.split(".").pop().toLowerCase();
      const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);

      if (isAnImage) {
        setUploadedImage({
          raw: event.target.files[0],
          preview: URL.createObjectURL(event.target.files[0]),
        });
      } else {
        toast.error("Unsupported image format!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return;
      }
    }
    URL.revokeObjectURL(event.target.files[0]);
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const deleteImage = () => {
    setUploadedImage({ raw: "", preview: "" });
  };

  const createGroupChat = async () => {
    try {
      if (!isCreatingGroup) {
        if (groupTitle == undefined || groupTitle == null || groupTitle == "" || groupTitle.trim() == "") {
          toast.error("Group title is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }
        if (groupDescription == undefined || groupDescription == null || groupDescription == "" || groupDescription.trim() == "") {
          toast.error("Group description is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }
        if (groupType == undefined || groupType == null || groupType == "") {
          toast.error("Group type is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }

        if (groupType != "PUBLIC" && groupType != "PRIVATE") {
          toast.error("Group type is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          return;
        }

        const formData = new FormData();

        const groupChat = {
          groupTitle: groupTitle,
          groupType: groupType,
          groupDescription: groupDescription,
        };

        const data = JSON.stringify(groupChat);

        formData.append("groupDetails", data);
        formData.append("groupIcon", uploadedImage.raw);
        setIsCreatingGroup(true);
        const res = await GroupChatService.createGroupChat(formData);
        if (res && res.success) {
          setIsCreatingGroup(false);
          try {
            if (allChatGroups != null && allChatGroups.length != null && allChatGroups.length >= 0 && res.data != null) {
              const newGroup: GroupChatModel = res.data;
              if (
                newGroup != null &&
                newGroup._id != null &&
                newGroup._id != "" &&
                newGroup.type != null &&
                newGroup.type != "" &&
                newGroup.type == selectedChatType
              ) {
                const previousChatGroups: GroupChatModel[] = allChatGroups;
                const newChatGroups: GroupChatModel[] = [{ ...newGroup, unreadMessageCount: 0, lastActive: moment().toString() }];
                const finalGroupArray: GroupChatModel[] = [...newChatGroups, ...previousChatGroups];
                setAllChatGroups(finalGroupArray);
              }
            }
          } catch (error) {}
          toast.success("Chat group created successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setShowModal(!showModal);
        } else {
          setIsCreatingGroup(false);
          toast.error("Failed to create the chat group!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsCreatingGroup(false);
      } else {
        toast.error("Please wait, group creation in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsCreatingGroup(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return showModal ? (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-body pt-1">
          {/* <button type="button" onClick={() => setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button> */}

          <Button
            close
            onClick={() => {
              if (!isCreatingGroup) {
                setShowModal(false);
              } else {
                toast.error("Please wait, group creation in progress!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            }}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
          >
            <img src={Close} alt="Close" className="" />
          </Button>

          <CardBody>
            <CardTitle className="mb-3 mt-3 text-left font-size-16">Create group chat</CardTitle>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Title of Group"
                    name="articleTitle"
                    onChange={event => setGroupTitle(event.target.value)}
                    required
                  />
                </div>
                <div className="mb-2">
                  <Label
                    htmlFor="form-upload-file"
                    style={{
                      cursor: "pointer",
                      fontSize: "13px",
                      color: "#FD7F00",
                      marginBottom: "0px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <img src={images.addSignIcon} className="ai-icon-size me-2" />
                      Upload group icon
                    </div>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="form-upload-file"
                    style={{ display: "none" }}
                    onChange={event => handleImageChange(event)}
                    required
                  />
                </div>
                {uploadedImage.preview != "" && (
                  <>
                    <div className="pull-right">
                      <i className="fa fa-trash fa-lg remove-icon text-danger pl10" onClick={deleteImage}></i>
                    </div>
                    <div className="mb-3 upload-img">
                      <img src={uploadedImage.preview} className="upload-img-preview" />
                    </div>
                  </>
                )}
                <div className="mb-3">
                  <Input
                    type="textarea"
                    className="form-control"
                    rows={3}
                    placeholder="Description"
                    onChange={event => setGroupDescription(event.target.value)}
                    style={{ resize: "none" }}
                    required
                  />
                </div>
                <div className="mb-3">
                  <select
                    className="form-control"
                    value={groupType}
                    onChange={event => {
                      setGroupType(event.target.value);
                    }}
                  >
                    <option value="default">Select group type</option>
                    <option value="PUBLIC">Public</option>
                    <option value="PRIVATE">Private</option>
                  </select>
                </div>
                <div className="mb-3">
                  <button className={isCreatingGroup ? "btn btn-secondary w-100" : "btn btn-primary w-100"} onClick={createGroupChat}>
                    {isCreatingGroup ? "Loading..." : "Create"}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Modal>
    </>
  ) : null;
};

export default CreateGroupChatModal;
