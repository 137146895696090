
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Input, CardTitle } from "reactstrap";
import "../../assets/css/admin.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { InsuranceCompanyTags } from "../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import { Tags } from "../../common/custom-components/Tags";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";

const CreateInsuranceCompany: React.FC = () => {
    const [insuaranceList, setInsuarancesStates] = useState<any[]>([] as any[]);
    const TherapyState = [
        "All",
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "North Carolina",
        "North Dakota",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "Ohio",
        "Montana",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington DC",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ];

    useEffect(() => {
        const option = TherapyState.map((state: any) => ({ value: state, label: state }));
        setInsuarancesStates(option);
    }, []);

    const [insuranceCompanyList, setInsuranceCompanyList] = useState<InsuranceCompanyTags>({} as InsuranceCompanyTags);
    const [selectInsuaranceStates, setSelectedSates] = useState<any[]>([] as any[]);

    const back = () => {
        history.back()
    }


    const AddInsuranceCompany = () => {
        if (!insuranceCompanyList?.insuranceCompany) {
            toast.error("Insurance Company is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        } else {
            const stateList = selectInsuaranceStates?.map(states => states?.label);
            const updatedInsuranceCompany: any = {
                insuranceCompany: insuranceCompanyList?.insuranceCompany,
                contractPrice: insuranceCompanyList?.contractPrice ? insuranceCompanyList?.contractPrice : 0,
                coPayment: insuranceCompanyList?.coPayment ? insuranceCompanyList?.coPayment : 0,
                states: stateList,
                fax: insuranceCompanyList?.fax,
                tradingPartnerServiceId: insuranceCompanyList?.tradingPartnerServiceId,
                organizationName: insuranceCompanyList?.organizationName,
                payerName: insuranceCompanyList?.payerName,
                link: insuranceCompanyList?.link
            };
            InsuranceCompanyService.createInsuranceCompany(updatedInsuranceCompany).then((res) => {
                if (res.success) {
                    setInsuranceCompanyList(res.data);
                    toast.success(res.success, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                    window.history.back();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        }
    }

    const handleCoPaymentInputValue = (e: any) => {
        const inputValue = e.target.value;
        if (!isNaN(inputValue)) {
            // It's a number, update the state
            setInsuranceCompanyList({ ...insuranceCompanyList, coPayment: inputValue });
        } else {
            // It's not a number, you can handle this case as needed (e.g., show an error message)
            toast.error("Co-payment must be a number!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }

    const handleContractPriceInputValue = (e: any) => {
        const inputValue = e.target.value;
        if (!isNaN(inputValue)) {
            // It's a number, update the state
            setInsuranceCompanyList({ ...insuranceCompanyList, contractPrice: inputValue });
        } else {
            // It's not a number, you can handle this case as needed (e.g., show an error message)
            toast.error("Contract Price must be a number!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }

    const handleInsuaranceState = (value: any) => {
        const filteredStates = selectInsuaranceStates?.filter(state => state?.label === 'All');
        if (filteredStates?.length > 0) {
            toast.error("You have already selected all. Please remove it before adding new states.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }
        const newFilteredStates = value?.filter((state: { label: string; }) => state?.label === 'All');

        const updatedTags = value?.map((tag: { label: any }) => {
            return { label: tag.label };
        });

        if (newFilteredStates?.length > 0 && updatedTags) {
            const filteredUpdatedTags = updatedTags?.filter((state: { label: string; }) => state?.label === 'All');
            setSelectedSates(filteredUpdatedTags);
        } else {
            if (updatedTags) {
                setSelectedSates(updatedTags);
                setInsuarancesStates(insuaranceList.filter(tagObj => !updatedTags.find((updatedTag: { label: any }) => updatedTag.label === tagObj.label)));
            }
        }
    };

    const removeInsuaranceStates = (removedtag: any) => {
        setSelectedSates(selectInsuaranceStates.filter(tagObj => tagObj.label !== removedtag));

        const addTags = { value: removedtag, label: removedtag };
        if (addTags) {
            setInsuarancesStates([...insuaranceList, addTags]);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content min-vh-100">
                <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
                    <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                    <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
                </div>
                <Container className="containerOnboarding mt-5">
                    {/* <Breadcrumbs title={"Create Ethnicity"} breadcrumbItem={"Create Ethnicity"} /> */}

                    <Card className="BorderRadiusLeftRight pt-4 pl-3 pb-2">
                        <div className="card-bodyDiv">
                            <Row>
                                <Col xl="6">
                                    <Row>
                                        <Col xl="12">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Insurance Company</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Insurance Company"
                                                    name="Insurance Company"
                                                    value={insuranceCompanyList?.insuranceCompany || ""}
                                                    onChange={(e) => setInsuranceCompanyList({ ...insuranceCompanyList, insuranceCompany: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="6">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Contract Price</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Contract Price"
                                                    name="Contract Price"
                                                    value={insuranceCompanyList?.contractPrice || ""}
                                                    onChange={handleContractPriceInputValue}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Co-Payment</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Co-payment"
                                                    name="Co-payment"
                                                    value={insuranceCompanyList?.coPayment || ""}
                                                    onChange={handleCoPaymentInputValue}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Fax</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <PhoneInput
                                                    defaultCountry="US"
                                                    value={insuranceCompanyList?.fax}
                                                    onChange={e => setInsuranceCompanyList({ ...insuranceCompanyList, fax: e })}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Trading Partner Service ID</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="trading-partner-service-id-input"
                                                    placeholder="Trading Partner Service ID"
                                                    name="Trading Partner Service ID"
                                                    value={insuranceCompanyList?.tradingPartnerServiceId || ""}
                                                    onChange={(e) => setInsuranceCompanyList({ ...insuranceCompanyList, tradingPartnerServiceId: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Organization Name"
                                                    name="Organization Name"
                                                    value={insuranceCompanyList?.organizationName || ""}
                                                    onChange={(e) => setInsuranceCompanyList({ ...insuranceCompanyList, organizationName: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Payer Name</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Payer Name"
                                                    name="Payer Name"
                                                    value={insuranceCompanyList?.payerName || ""}
                                                    onChange={(e) => setInsuranceCompanyList({ ...insuranceCompanyList, payerName: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Link</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-firstname-Input"
                                                    placeholder="Link"
                                                    name="Link"
                                                    value={insuranceCompanyList?.link || ""}
                                                    onChange={(e) => setInsuranceCompanyList({ ...insuranceCompanyList, link: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl="6">
                                    <Row>
                                        <Col xl="12">
                                            <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">States</h5>
                                            </div>
                                            <div className="card-heading mb-4">
                                                <Select
                                                    onChange={(newValue: any) => handleInsuaranceState(newValue)}
                                                    value={selectInsuaranceStates}
                                                    isMulti
                                                    options={insuaranceList}
                                                    className="basic-multi-select"
                                                    placeholder="Choose a State"
                                                    display="none"
                                                    controlShouldRenderValue={false}
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12">
                                            <div className="flex flex-wrap flex-row">
                                                {selectInsuaranceStates.map((tagObj, id) => (
                                                    <Tags label={tagObj.label} id={id} key={id} removeTag={removeInsuaranceStates} />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>

                                </Col>
                                <Col xl={6}>
                                    <button
                                        type="button"
                                        className="btn btn-primary relative mt-3 mb-2" style={{
                                            bottom: "0",
                                            float: "right",
                                        }}
                                        onClick={() => {
                                            AddInsuranceCompany();
                                        }}>
                                        Save
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateInsuranceCompany;
