const options = [
    { value: "F40.241", label: "F40.241 Acrophobia" },
    {
      value: "F41.0",
      label: "F41.0 Panic Disorder (episodic paroxysmal anxiety)",
    },
    { value: "F43.0", label: "F43.0 Acute stress reaction" },
    { value: "F43.22", label: "F43.22 Adjustment disorder with anxiety" },
    { value: "F43.21", label: "F43.21 Adjustment disorder with depressed mood" },
    {
      value: "F43.24",
      label: "F43.24 Adjustment disorder with disturbance of conduct",
    },
    {
      value: "F43.23",
      label: "F43.23 Adjustment disorder with mixed anxiety and depressed mood",
    },
    {
      value: "F43.25",
      label: "F43.25 Adjustment disorder with mixed disturbance of emotions and conduct",
    },
    { value: "F43.29", label: "F43.29 Adjustment disorder with other symptoms" },
    { value: "F43.20", label: "F43.20 Adjustment disorder, unspecified" },
    {
      value: "F50.82",
      label: "F50.82 Avoidant/restrictive food intake disorder",
    },
    { value: "F51.02", label: "F51.02 Adjustment insomnia" },
    { value: "F98.5", label: "F98.5 Adult onset fluency disorder" },
    { value: "F40.01", label: "F40.01 Agoraphobia with panic disorder" },
    { value: "F40.02", label: "F40.02 Agoraphobia without panic disorder" },
    { value: "F40.00", label: "F40.00 Agoraphobia, unspecified" },
    {
      value: "F10.180 ",
      label: "F10.180 Alcohol abuse with alcohol-induced anxiety disorder",
    },
    {
      value: "F10.14",
      label: "F10.14 Alcohol abuse with alcohol-induced mood disorder",
    },
    {
      value: "F10.150",
      label: "F10.150 Alcohol abuse with alcohol-induced psychotic disorder with delusions",
    },
    {
      value: "F10.151",
      label: "F10.151 Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
    },
    {
      value: "F10.159",
      label: "F10.159 Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
    },
    {
      value: "F10.181",
      label: "F10.181 Alcohol abuse with alcohol-induced sexual dysfunction",
    },
    {
      value: "F10.182",
      label: "F10.182 Alcohol abuse with alcohol-induced sleep disorder",
    },
    {
      value: "F10.121",
      label: "F10.121 Alcohol abuse with intoxication delirium",
    },
    {
      value: "F10.188",
      label: "F10.188 Alcohol abuse with other alcohol-induced disorder",
    },
    {
      value: "F10.19 ",
      label: "F10.19 Alcohol abuse with unspecified alcohol-induced disorder",
    },
    {
      value: "F10.280",
      label: "F10.280 Alcohol dependence with alcohol-induced anxiety disorder",
    },
    {
      value: "F10.24",
      label: "F10.24 Alcohol dependence with alcohol-induced mood disorder",
    },
    {
      value: "F10.26",
      label: "F10.26 Alcohol dependence with alcohol-induced persisting amnestic disorder",
    },
    {
      value: "F10.27",
      label: "F10.27 Alcohol dependence with alcohol-induced persisting dementia",
    },
    {
      value: "F10.250",
      label: "Alcohol dependence with alcohol-induced psychotic disorder with delusions",
    },
    {
      value: "F10.251 ",
      label: "F10.250 Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
    },
    {
      value: "F10.259",
      label: "F10.259 Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
    },
    {
      value: "F10.281",
      label: "F10.281 Alcohol dependence with alcohol-induced sexual dysfunction",
    },
    {
      value: "F10.282",
      label: "F10.282 Alcohol dependence with alcohol-induced sleep disorder",
    },
    {
      value: "F10.221",
      label: "F10.221 Alcohol dependence with intoxication delirium",
    },
    {
      value: "F10.288 ",
      label: "F10.288 Alcohol dependence with other alcohol-induced disorder",
    },
    {
      value: "F10.29",
      label: "F10.29 Alcohol dependence with unspecified alcohol-induced disorder",
    },
    {
      value: "F10.231",
      label: "F10.231 Alcohol dependence with withdrawal delirium",
    },
    {
      value: "F10.232 ",
      label: "F10.232 Alcohol dependence with withdrawal with perceptual disturbance",
    },
    {
      value: "F10.239",
      label: "F10.239 Alcohol dependence with withdrawal, unspecified",
    },
    {
      value: "F10.980",
      label: "F10.980 Alcohol use, unspecified with alcohol-induced anxiety disorder",
    },
    {
      value: "F10.94",
      label: "F10.94 Alcohol use, unspecified with alcohol-induced mood disorder",
    },
    {
      value: "F10.96",
      label: "F10.96 Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
    },
    {
      value: "F10.97",
      label: "F10.97 Alcohol use, unspecified with alcohol-induced persisting dementia",
    },
    {
      value: "F10.950 ",
      label: "F10.950 Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
    },
    {
      value: "F10.951",
      label: "F10.951 Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
    },
    {
      value: "F10.959",
      label: "F10.959 Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
    },
    {
      value: "F10.981",
      label: "F10.981 Alcohol use, unspecified with alcohol-induced sexual dysfunction",
    },
    {
      value: "F10.982",
      label: "F10.982 Alcohol use, unspecified with alcohol-induced sleep disorder",
    },
    {
      value: "F10.921 ",
      label: "F10.921 Alcohol use, unspecified with intoxication delirium",
    },
    {
      value: "F10.988",
      label: "F10.988 Alcohol use, unspecified with other alcohol-induced disorder",
    },
    {
      value: "F04",
      label: "F04 Amnestic disorder due to known physiological condition",
    },
    { value: "F40.290", label: "F40.290 Androphobia" },
    {
      value: "F50.02",
      label: "F50.02 Anorexia nervosa, binge eating/purging type",
    },
    { value: "F50.01", label: "F50.01 Anorexia nervosa, restricting type" },
    { value: "F50.00", label: "F50.00 Anorexia nervosa, unspecified" },
    { value: "F60.2", label: "F60.2 Antisocial personality disorder" },
    {
      value: "F06.4 ",
      label: "F06.4 Anxiety disorder due to known physiological condition",
    },
    { value: "F41.9", label: "F41.9 Anxiety disorder, unspecified" },
    { value: "F40.210 ", label: "F40.210 Arachnophobia" },
    { value: "F84.5 ", label: "F84.5 Asperger's syndrome" },
    {
      value: "F90.2",
      label: "F90.2 Attention-deficit hyperactivity disorder, combined type",
    },
    {
      value: "F90.8",
      label: "F90.8 Attention-deficit hyperactivity disorder, other type",
    },
    {
      value: "F90.1",
      label: "F90.1 Attention-deficit hyperactivity disorder, predominantly hyperactive type",
    },
    {
      value: "F90.0",
      label: "F90.0 Attention-deficit hyperactivity disorder, predominantly inattentive type",
    },
    {
      value: "F90.9",
      label: "F90.9 Attention-deficit hyperactivity disorder, unspecified type",
    },
    { value: "F84.0 ", label: "F84.0 Autistic disorder" },
    { value: "F60.6 ", label: "F60.6 Avoidant personality disorder" },
    { value: "F50.81", label: "F50.81 Binge eating disorder" },
    { value: "F31.81", label: "F31.81 Bipolar II disorder" },
    {
      value: "F31.31",
      label: "F31.31 Bipolar disorder, current episode depressed, mild",
    },
    {
      value: "F31.30",
      label: "F31.30 Bipolar disorder, current episode depressed, mild or moderate severity,unspecified",
    },
    {
      value: "F31.32 ",
      label: "F31.32 Bipolar disorder, current episode depressed, moderate",
    },
    {
      value: "F31.5",
      label: "F31.5 Bipolar disorder, current episode depressed, severe, with psychotic features",
    },
    {
      value: "F31.4",
      label: "F31.4 Bipolar disorder, current episode depressed, severe, without psychotic features",
    },
    {
      value: "F31.0",
      label: "F31.0 Bipolar disorder, current episode hypomanic",
    },
    {
      value: "F31.2",
      label: "F31.2 Bipolar disorder, current episode manic severe with psychotic features",
    },
    {
      value: "F31.11",
      label: "F31.11 Bipolar disorder, current episode manic without psychotic features, mild",
    },
    {
      value: "F31.12",
      label: "F31.12 Bipolar disorder, current episode manic without psychotic features, moderate",
    },
    {
      value: "F31.13 ",
      label: "F31.13 Bipolar disorder, current episode manic without psychotic features, severe",
    },
    {
      value: "F31.10",
      label: "F31.10 Bipolar disorder, current episode manic without psychotic features, unspecified",
    },
    {
      value: "F31.61 ",
      label: "F31.61 Bipolar disorder, current episode mixed, mild",
    },
    {
      value: "F31.62 ",
      label: "F31.62 Bipolar disorder, current episode mixed, moderate",
    },
    {
      value: "F31.64 ",
      label: "F31.64 Bipolar disorder, current episode mixed, severe, with psychotic features",
    },
    {
      value: "F31.63 ",
      label: "F31.63 Bipolar disorder, current episode mixed, severe, without psychotic features",
    },
    {
      value: "F31.60",
      label: "F31.60 Bipolar disorder, current episode mixed, unspecified",
    },
    {
      value: "F31.70",
      label: "F31.70 Bipolar disorder, currently in remission, most recent episode unspecified",
    },
    {
      value: "F31.76",
      label: "31.76 Bipolar disorder, in full remission, most recent episode depressed",
    },
    {
      value: "F31.72",
      label: "F31.72 Bipolar disorder, in full remission, most recent episode hypomanic",
    },
    {
      value: "F31.74 ",
      label: "F31.74 Bipolar disorder, in full remission, most recent episode manic",
    },
    {
      value: "F31.78 ",
      label: "F31.78 Bipolar disorder, in full remission, most recent episode mixed",
    },
    {
      value: "F31.75 ",
      label: "F31.75 Bipolar disorder, in partial remission, most recent episode depressed",
    },
    {
      value: "F31.71",
      label: "F31.71 Bipolar disorder, in partial remission, most recent episode hypomanic",
    },
    {
      value: "F31.73",
      label: "F31.73 Bipolar disorder, in partial remission, most recent episode manic",
    },
    {
      value: "F31.77",
      label: "F31.73 Bipolar disorder, in partial remission, most recent episode mixed",
    },
    { value: "F31.9 ", label: "F31.9 Bipolar disorder, unspecified" },
    { value: "F45.22 ", label: "F45.22 Body dysmorphic disorder" },
    { value: "F60.3", label: "F60.3 Borderline personality disorder" },
    { value: "F23", label: "F23 Brief psychotic disorder" },
    { value: "F50.2", label: "F50.2 Bulimia nervosa" },
    {
      value: "F12.180",
      label: "F12.180 Cannabis abuse with cannabis-induced anxiety disorder",
    },
    {
      value: "F12.121",
      label: "F12.121 Cannabis abuse with intoxication delirium",
    },
    {
      value: "F12.122",
      label: "F12.122 Cannabis abuse with intoxication with perceptual disturbance",
    },
    {
      value: "F12.188",
      label: "F12.188 Cannabis abuse with other cannabis-induced disorder",
    },
    {
      value: "F12.150",
      label: "F12.150 Cannabis abuse with psychotic disorder with delusions",
    },
    {
      value: "F12.151 ",
      label: "F12.151 Cannabis abuse with psychotic disorder with hallucinations",
    },
    {
      value: "F12.159",
      label: "F12.159 Cannabis abuse with psychotic disorder, unspecified",
    },
    {
      value: "F12.19",
      label: "F12.19 Cannabis abuse with unspecified cannabis-induced disorder",
    },
    {
      value: "F12.280",
      label: "F12.280 Cannabis dependence with cannabis-induced anxiety disorder",
    },
    {
      value: "F12.221 ",
      label: "F12.221 Cannabis dependence with intoxication delirium",
    },
    {
      value: "F12.222",
      label: "F12.222 Cannabis dependence with intoxication with perceptual disturbance",
    },
    {
      value: "F12.288",
      label: "F12.288 Cannabis dependence with other cannabis-induced disorder",
    },
    {
      value: "F12.250",
      label: "F12.250 Cannabis dependence with psychotic disorder with delusions",
    },
    {
      value: "F12.251",
      label: "F12.251 Cannabis dependence with psychotic disorder with hallucinations",
    },
    {
      value: "F12.259",
      label: "F12.259 Cannabis dependence with psychotic disorder, unspecified",
    },
    {
      value: "F12.29",
      label: "F12.29 Cannabis dependence with unspecified cannabis-induced disorder",
    },
    {
      value: "F12.980",
      label: "F12.980 Cannabis use, unspecified with anxiety disorder",
    },
    {
      value: "F12.921",
      label: "F12.921 Cannabis use, unspecified with intoxication delirium",
    },
    {
      value: "F12.922",
      label: "F12.922 Cannabis use, unspecified with intoxication with perceptual disturbance",
    },
    {
      value: "F12.988",
      label: "F12.988 Cannabis use, unspecified with other cannabis-induced disorder",
    },
    {
      value: "F12.950 ",
      label: "F12.950 Cannabis use, unspecified with psychotic disorder with delusions",
    },
    {
      value: "F12.951",
      label: "F12.951 Cannabis use, unspecified with psychotic disorder with hallucinations",
    },
    {
      value: "F12.959",
      label: "F12.959 Cannabis use, unspecified with psychotic disorder, unspecified",
    },
    {
      value: "F06.1",
      label: "F06.1 Catatonic disorder due to known physiological condition",
    },
    { value: "F20.2 ", label: "F20.2 Catatonic schizophrenia" },
    { value: "F93.9", label: "F93.9 Childhood emotional disorder, unspecified" },
    { value: "F80.81", label: "F80.81 Childhood onset fluency disorder" },
    { value: "F95.1", label: "F95.1 Chronic motor or vocal tic disorder" },
    { value: "F40.240", label: "F40.240 Claustrophobia" },
    {
      value: "F14.180",
      label: "F14.180 Cocaine abuse with cocaine-induced anxiety disorder",
    },
    {
      value: "F14.14",
      label: "F14.14 Cocaine abuse with cocaine-induced mood disorder",
    },
    {
      value: "F14.150",
      label: "F14.150 Cocaine abuse with cocaine-induced psychotic disorder with delusions",
    },
    {
      value: "F14.151",
      label: "F14.151 Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
    },
    {
      value: "F14.159",
      label: "F14.159 Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
    },
    {
      value: "F14.181",
      label: "F14.181 Cocaine abuse with cocaine-induced sexual dysfunction",
    },
    {
      value: "F14.182",
      label: "F14.182 Cocaine abuse with cocaine-induced sleep disorder",
    },
    {
      value: "F14.121",
      label: "F14.121 Cocaine abuse with intoxication with delirium",
    },
    {
      value: "F14.122",
      label: "F14.122 Cocaine abuse with intoxication with perceptual disturbance",
    },
    {
      value: "F14.188",
      label: "F14.188 Cocaine abuse with other cocaine-induced disorder",
    },
    {
      value: "F14.19",
      label: "F14.19 Cocaine abuse with unspecified cocaine-induced disorder",
    },
    {
      value: "F14.280 ",
      label: "F14.280 Cocaine dependence with cocaine-induced anxiety disorder",
    },
    {
      value: "F14.24",
      label: "F14.24 Cocaine dependence with cocaine-induced mood disorder",
    },
    {
      value: "F14.250",
      label: "F14.250 Cocaine dependence with cocaine-induced psychotic disorder with delusions",
    },
    {
      value: "F14.251",
      label: "F14.251 Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
    },
    {
      value: "F14.259",
      label: "F14.259 Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
    },
    {
      value: "F14.281 ",
      label: "F14.281 Cocaine dependence with cocaine-induced sexual dysfunction",
    },
    {
      value: "F14.282",
      label: "F14.282 Cocaine dependence with cocaine-induced sleep disorder",
    },
    {
      value: "F14.221",
      label: "F14.221 Cocaine dependence with intoxication delirium",
    },
    {
      value: "F14.222",
      label: "F14.222 Cocaine dependence with intoxication with perceptual disturbance",
    },
    {
      value: "F14.288",
      label: "F14.288 Cocaine dependence with other cocaine-induced disorder",
    },
    {
      value: "F14.29",
      label: "F14.29 Cocaine dependence with unspecified cocaine-induced disorder",
    },
    {
      value: "F14.980",
      label: "F14.980 Cocaine use, unspecified with cocaine-induced anxiety disorder",
    },
    {
      value: "F14.94",
      label: "F14.94 Cocaine use, unspecified with cocaine-induced mood disorder",
    },
    {
      value: "F14.950 ",
      label: "F14.950 Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
    },
    {
      value: "F14.951",
      label: "F14.951 Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
    },
    {
      value: "F14.959",
      label: "F14.959 Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
    },
    {
      value: "F14.981 ",
      label: "F14.981 Cocaine use, unspecified with cocaine-induced sexual dysfunction",
    },
    {
      value: "F14.982",
      label: "F14.982 Cocaine use, unspecified with cocaine-induced sleep disorder",
    },
    {
      value: "F14.921",
      label: "F14.921 Cocaine use, unspecified with intoxication delirium",
    },
    {
      value: "F14.922",
      label: "F14.922 Cocaine use, unspecified with intoxication with perceptual disturbance",
    },
    {
      value: "F14.988",
      label: "F14.988 Cocaine use, unspecified with other cocaine-induced disorder",
    },
    {
      value: "F91.0",
      label: "F91.0 Conduct disorder confined to family context",
    },
    { value: "F91.2 ", label: "F91.2 Conduct disorder, adolescent-onset type" },
    { value: "F91.1", label: "F91.1 Conduct disorder, childhood-onset type" },
    { value: "F91.9", label: "F91.9 Conduct disorder, unspecified" },
    {
      value: "F44.7",
      label: "F44.7 Conversion disorder with mixed symptom presentation",
    },
    {
      value: "F44.4",
      label: "F44.4 Conversion disorder with motor symptom or deficit",
    },
    {
      value: "F44.5",
      label: "F44.5 Conversion disorder with seizures or convulsions",
    },
    {
      value: "F44.6",
      label: "F44.6 Conversion disorder with sensory symptom or deficit",
    },
    { value: "F34.0", label: "F34.0 Cyclothymic disorder" },
    { value: "F05", label: "F05 Delirium due to known physiological condition" },
    { value: "F22", label: "F22 Delusional disorders" },
    {
      value: "F02.81",
      label: "F02.81 Dementia in other diseases classified elsewhere with behavioral disturbance",
    },
    {
      value: "F02.80",
      label: "F02.80 Dementia in other diseases classified elsewhere without behavioral disturbance",
    },
    { value: "F60.7", label: "F60.7 Dependent personality disorder" },
    { value: "F48.1", label: "F48.1 Depersonalization-derealization syndrome" },
    {
      value: "F94.2",
      label: "F94.2 Disinhibited attachment disorder of childhood",
    },
    { value: "F20.1", label: "F20.1 Disorganized schizophrenia" },
    { value: "F34.81", label: "F34.81 Dispruptive mood dysregulation disorder" },
    { value: "F44.0", label: "F44.0 Dissociative amnesia" },
    {
      value: "F44.9",
      label: "F44.9 Dissociative and conversion disorder, unspecified",
    },
    { value: "F44.1", label: "F44.1 Dissociative fugue" },
    { value: "F44.81", label: "F44.81 Dissociative identity disorder" },
    { value: "F44.2 ", label: "F44.2 Dissociative stupor" },
    {
      value: "F52.6",
      label: "F52.6 Dyspareunia not due to a substance or known physiological condition",
    },
    { value: "F34.1", label: "F34.1 Dysthymic disorder" },
    { value: "F50.9", label: "F50.9 Eating disorder, unspecified" },
    {
      value: "F98.1",
      label: "F98.1 Encopresis not due to a substance or known physiological condition",
    },
    {
      value: "F98.0",
      label: "F98.0 Enuresis not due to a substance or known physiological condition",
    },
    { value: "F42.4 ", label: "F42.4 Excoriation (skin-picking) disorder" },
    { value: "F65.2", label: "F65.2 Exhibitionism" },
    {
      value: "F68.13",
      label: "F68.13 Factitious disorder with combined psychological and physical signs and symptoms",
    },
    {
      value: "F68.12",
      label: "F68.12 Factitious disorder with predominantly physical signs and symptoms",
    },
    {
      value: "F68.11",
      label: "F68.11 Factitious disorder with predominantly psychological signs and symptoms",
    },
    { value: "F68.10", label: "F68.10 Factitious disorder, unspecified" },
    { value: "F40.230", label: "F40.230 Fear of blood" },
    { value: "F40.242", label: "F40.242 Fear of bridges" },
    { value: "F40.243", label: "F40.243 Fear of flying" },
    { value: "F40.231", label: "F40.231 Fear of injections and transfusions" },
    { value: "F40.233", label: "F40.233 Fear of injury" },
    { value: "F40.232", label: "F40.232 Fear of other medical care" },
    { value: "F40.220", label: "F40.220 Fear of thunderstorms" },
    { value: "F52.31", label: "F52.31 Female orgasmic disorder" },
    { value: "F52.22", label: "F52.22 Female sexual arousal disorder" },
    { value: "F65.0", label: "F65.0 Fetishism" },
    { value: "F65.81", label: "F65.81 Frotteurism" },
    {
      value: "F64.1",
      label: "F64.1 Gender identity disorder in adolescence and adulthood",
    },
    { value: "F64.2", label: "F64.2 Gender identity disorder of childhood" },
    { value: "F64.9", label: "F64.9 Gender identity disorder, unspecified" },
    { value: "F41.1", label: "F41.1 Generalized anxiety disorder" },
    { value: "F40.291", label: "F40.291 Gynephobia" },
    {
      value: "F16.183",
      label: "F16.183 Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
    },
    {
      value: "F16.180",
      label: "F16.180 Hallucinogen abuse with hallucinogen-induced anxiety disorder",
    },
    {
      value: "F16.14",
      label: "F16.14 Hallucinogen abuse with hallucinogen-induced mood disorder",
    },
    {
      value: "F16.150",
      label: "F16.150 Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
    },
    {
      value: "F16.151",
      label: "F16.151 Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
    },
    {
      value: "F16.159 ",
      label: "F16.159 Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
    },
    {
      value: "F16.121",
      label: "F16.121 Hallucinogen abuse with intoxication with delirium",
    },
    {
      value: "F16.122",
      label: "F16.122 Hallucinogen abuse with intoxication with perceptual disturbance",
    },
    {
      value: "F16.188",
      label: "F16.188 Hallucinogen abuse with other hallucinogen-induced disorder",
    },
    {
      value: "F16.19",
      label: "F16.19 Hallucinogen abuse with unspecified hallucinogen-induced disorder",
    },
    {
      value: "F16.283",
      label: "F16.283 Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
    },
    {
      value: "F16.280",
      label: "F16.280 Hallucinogen dependence with hallucinogen-induced anxiety disorder",
    },
    {
      value: "F16.24",
      label: "F16.24 Hallucinogen dependence with hallucinogen-induced mood disorder",
    },
    {
      value: "F16.250",
      label: "F16.250 Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
    },
    {
      value: "F16.251",
      label: "F16.251 Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
    },
    {
      value: "F16.259",
      label: "F16.259 Hallucinogen dependence with hallucinogen-induced psychotic disorder,unspecified",
    },
    {
      value: "F16.221",
      label: "F16.221 Hallucinogen dependence with intoxication with delirium",
    },
    {
      value: "F16.288",
      label: "F16.288 Hallucinogen dependence with other hallucinogen-induced disorder",
    },
    {
      value: "F16.29",
      label: "F16.29 Hallucinogen dependence with unspecified hallucinogen-induced disorder",
    },
    {
      value: "F16.983",
      label: "F16.983 Hallucinogen use, unspecified with hallucinogen persisting perception disorder(flashbacks)",
    },
    {
      value: "F16.980",
      label: "F16.980 Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
    },
    {
      value: "F16.94",
      label: "F16.94 Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
    },
    {
      value: "F16.950",
      label: "F16.950 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder withdelusions",
    },
    {
      value: "F16.951",
      label: "F16.951 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder withhallucinations",
    },
    {
      value: "F16.959",
      label: "F16.959 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder,unspecified",
    },
    {
      value: "F16.921",
      label: "F16.921 Hallucinogen use, unspecified with intoxication with delirium",
    },
    {
      value: "F16.988",
      label: "F16.988 Hallucinogen use, unspecified with other hallucinogen-induced disorder",
    },
    { value: "F60.4", label: "F60.4 Histrionic personality disorder" },
    { value: "F42.3", label: "F42.3 Hoarding disorder" },
    { value: "F52.0", label: "F52.0 Hypoactive sexual desire disorder" },
    { value: "F45.20", label: "F45.20 Hypochondriacal disorder, unspecified" },
    { value: "F45.21", label: "F45.21 Hypochondriasis" },
    { value: "F63.9", label: "F63.9 Impulse disorder, unspecified" },
    {
      value: "F18.180",
      label: "F18.180 Inhalant abuse with inhalant-induced anxiety disorder",
    },
    {
      value: "F18.17",
      label: "F18.17 Inhalant abuse with inhalant-induced dementia",
    },
    {
      value: "F18.14 ",
      label: "F18.14 Inhalant abuse with inhalant-induced mood disorder",
    },
    {
      value: "F18.150",
      label: "F18.150 Inhalant abuse with inhalant-induced psychotic disorder with delusions",
    },
    {
      value: "F18.151",
      label: "F18.151 Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
    },
    {
      value: "F18.159",
      label: "18.159 Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
    },
    {
      value: "F18.121",
      label: "F18.121 Inhalant abuse with intoxication delirium",
    },
    {
      value: "F18.188",
      label: "F18.188 Inhalant abuse with other inhalant-induced disorder",
    },
    {
      value: "F18.19",
      label: "F18.19 Inhalant abuse with unspecified inhalant-induced disorder",
    },
    {
      value: "F18.280",
      label: "F18.280 Inhalant dependence with inhalant-induced anxiety disorder",
    },
    {
      value: "F18.27",
      label: "F18.27 Inhalant dependence with inhalant-induced dementia",
    },
    {
      value: "F18.24",
      label: "F18.24 Inhalant dependence with inhalant-induced mood disorder",
    },
    {
      value: "F18.250",
      label: "F18.250 Inhalant dependence with inhalant-induced psychotic disorder with delusions",
    },
    {
      value: "F18.251",
      label: "F18.251 Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
    },
    {
      value: "F18.259",
      label: "F18.259 Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
    },
    {
      value: "F18.221",
      label: "F18.221 Inhalant dependence with intoxication delirium",
    },
    {
      value: "F18.980",
      label: "F18.980Inhalant use, unspecified with inhalant-induced anxiety disorder",
    },
    {
      value: "F18.94",
      labe: "F18.94 Inhalant use, unspecified with inhalant-induced mood disorder",
    },
    {
      value: "F18.97",
      label: "F18.97 Inhalant use, unspecified with inhalant-induced persisting dementia",
    },
    {
      value: "F18.950",
      label: "F18.950 Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
    },
    {
      value: "F18.951",
      label: "F18.951 Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
    },
    {
      value: "F18.959",
      label: "F18.959 Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
    },
    {
      value: "F18.921",
      label: "F18.921 Inhalant use, unspecified with intoxication with delirium",
    },
    { value: "F51.05", label: "F51.05 Insomnia due to other mental disorder" },
    { value: "F51.12", label: "F51.12 Insufficient sleep syndrome" },
    { value: "F63.81 ", label: "F63.81 Intermittent explosive disorder" },
    { value: "F63.2", label: "F63.2 Kleptomania" },
    {
      value: "F33.2",
      label: "F33.2 Major depressive disorder, recurrent severe without psychotic features",
    },
    {
      value: "F33.42",
      label: "F33.42 Major depressive disorder, recurrent, in full remission",
    },
    {
      value: "F33.41",
      label: "F33.41 Major depressive disorder, recurrent, in partial remission",
    },
    {
      value: "F33.40",
      label: "F33.40 Major depressive disorder, recurrent, in remission, unspecified",
    },
    { value: "F33.0", label: "F33.0 Major depressive disorder, recurrent, mild" },
    {
      value: "F33.1",
      label: "F33.1 Major depressive disorder, recurrent, moderate",
    },
    {
      value: "F33.3",
      label: "F33.3 Major depressive disorder, recurrent, severe with psychotic symptoms",
    },
    {
      value: "F33.9",
      label: "F33.9 Major depressive disorder, recurrent, unspecified",
    },
    {
      value: "F32.5 ",
      label: "F32.5 Major depressive disorder, single episode, in full remission",
    },
    {
      value: "F32.4",
      label: "F32.4 Major depressive disorder, single episode, in partial remission",
    },
    {
      value: "F32.0",
      label: "F32.0 Major depressive disorder, single episode, mild",
    },
    {
      value: "F32.1",
      label: "F32.1 Major depressive disorder, single episode, moderate",
    },
    {
      value: "F32.3",
      label: "F32.3 Major depressive disorder, single episode, severe with psychotic features",
    },
    {
      value: "F32.2",
      label: "F32.2 Major depressive disorder, single episode, severe without psychotic features",
    },
    {
      value: "F32.9",
      label: "F32.9 Major depressive disorder, single episode, unspecified",
    },
    { value: "F52.21", label: "F52.21 Male erectile disorder" },
    { value: "F52.32", label: "F52.32 Male orgasmic disorder" },
    { value: "F30.4", label: "F30.4 Manic episode in full remission" },
    { value: "F30.3", label: "F30.3 Manic episode in partial remission" },
    {
      value: "F30.11",
      label: "F30.11 Manic episode without psychotic symptoms, mild",
    },
    {
      value: "F30.12",
      label: "F30.12 Manic episode without psychotic symptoms, moderate",
    },
    {
      value: "F30.10",
      label: "F30.10 Manic episode without psychotic symptoms, unspecified",
    },
    {
      value: "F30.2",
      label: "F30.2 Manic episode, severe with psychotic symptoms",
    },
    {
      value: "F30.13",
      label: "F30.13 Manic episode, severe, without psychotic symptoms",
    },
    { value: "F30.9", label: "F30.9 Manic episode, unspecified" },
    { value: "F42.2", label: "F42.2 Mixed obsessional thoughts and acts" },
    {
      value: "F06.31",
      label: "F06.31 Mood disorder due to known physiological condition with depressive features",
    },
    {
      value: "F06.32",
      label: "F06.32 Mood disorder due to known physiological condition with major depressive-like episode",
    },
    {
      value: "F06.33",
      label: "F06.33 Mood disorder due to known physiological condition with manic features",
    },
    {
      value: "F06.34",
      label: "F06.34 Mood disorder due to known physiological condition with mixed features",
    },
    {
      value: "F06.30",
      label: "F06.30 Mood disorder due to known physiological condition, unspecified",
    },
    { value: "F60.81", label: "F60.81 Narcissistic personality disorder" },
    { value: "F51.5", label: "F51.5 Nightmare disorder" },
    { value: "F48.9", label: "F48.9 Nonpsychotic mental disorder, unspecified" },
    { value: "F42", label: "F42 Obsessive-compulsive disorder" },
    { value: "F60.5", label: "F60.5 Obsessive-compulsive personality disorder" },
    {
      value: "F11.121",
      label: "F11.121 Opioid abuse with intoxication delirium",
    },
    {
      value: "F11.122",
      label: "F11.122 Opioid abuse with intoxication with perceptual disturbance",
    },
    {
      value: "F11.14",
      label: "F11.14 Opioid abuse with opioid-induced mood disorder",
    },
    {
      value: "F11.150",
      label: "11.150 Opioid abuse with opioid-induced psychotic disorder with delusions",
    },
    {
      value: "F11.151",
      label: "F11.151 Opioid abuse with opioid-induced psychotic disorder with hallucinations",
    },
    {
      value: "F11.159",
      label: "F11.159 Opioid abuse with opioid-induced psychotic disorder, unspecified",
    },
    {
      value: "F11.181",
      label: "F11.181 Opioid abuse with opioid-induced sexual dysfunction",
    },
    {
      value: "F11.182",
      label: "F11.182 Opioid abuse with opioid-induced sleep disorder",
    },
    {
      value: "F11.188",
      label: "F11.188 Opioid abuse with other opioid-induced disorder",
    },
    {
      value: "F11.19",
      label: "F11.19 Opioid abuse with unspecified opioid-induced disorder",
    },
    {
      value: "F11.221",
      label: "11.221 Opioid dependence with intoxication delirium",
    },
    {
      value: "F11.24",
      label: "F11.24 Opioid dependence with opioid-induced mood disorder",
    },
    {
      value: "F11.250",
      label: "F11.250 Opioid dependence with opioid-induced psychotic disorder with delusions",
    },
    {
      value: "F11.251",
      label: "F11.251 Opioid dependence with opioid-induced psychotic disorder with hallucinations",
    },
    {
      value: "F11.259",
      label: "F11.259 Opioid dependence with opioid-induced psychotic disorder, unspecified",
    },
    {
      value: "F11.281",
      label: "F11.281 Opioid dependence with opioid-induced sexual dysfunction",
    },
    {
      value: "F11.282",
      label: "F11.282 Opioid dependence with opioid-induced sleep disorder",
    },
    {
      value: "F11.288",
      label: "F11.288 Opioid dependence with other opioid-induced disorder",
    },
    {
      value: "F11.29",
      label: "F11.29 Opioid dependence with unspecified opioid-induced disorder",
    },
    {
      value: "F11.921",
      label: "F11.921 Opioid use, unspecified with intoxication delirium",
    },
    {
      value: "F11.922",
      label: "F11.922 Opioid use, unspecified with intoxication with perceptual disturbance",
    },
    {
      value: "F11.94",
      label: "F11.94 Opioid use, unspecified with opioid-induced mood disorder",
    },
    {
      value: "F11.950",
      label: "F11.950 Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
    },
    {
      value: "F11.951",
      label: "F11.951 Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
    },
    {
      value: "F11.959",
      label: "F11.959 Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
    },
    {
      value: "F11.981",
      label: "11.981 Opioid use, unspecified with opioid-induced sexual dysfunction",
    },
    {
      value: "F11.982",
      label: "F11.982 Opioid use, unspecified with opioid-induced sleep disorder",
    },
    {
      value: "F11.988",
      label: "F11.988 Opioid use, unspecified with other opioid-induced disorder",
    },
    { value: "F91.3", label: "F91.3 Oppositional defiant disorder" },
    { value: "F40.218", label: "F40.218 Other animal type phobia" },
    { value: "F31.89 ", label: "F31.89 Other bipolar disorder" },
    { value: "F84.3", label: "F84.3 Other childhood disintegrative disorder" },
    { value: "F94.8", label: "Other childhood disorders of social functioning" },
    { value: "F93.8", label: "F93.8 Other childhood emotional disorders" },
    { value: "F91.8", label: "F91.8 Other conduct disorders" },
    { value: "F32.8", label: "F32.8 Other depressive episodes" },
    {
      value: "F80.89",
      label: "F80.89 Other developmental disorders of speech and language",
    },
    { value: "F88", label: "F88 Other disorders of psychological development" },
    { value: "F50.8", label: "F50.8 Other eating disorders" },
    {
      value: "F98.29",
      label: "F98.29 Other feeding disorders of infancy and early childhood",
    },
    { value: "F64.8", label: "F64.8 Other gender identity disorders" },
    { value: "F45.29", label: "F45.29 Other hypochondriacal disorders" },
    { value: "F63.8", label: "F63.8 Other impulse disorders" },
    {
      value: "F51.09",
      label: "F51.09 Other insomnia not due to a substance or known physiological condition",
    },
    { value: "F42.8", label: "F42.8 Other obsessive compulsive disorder" },
    { value: "F30.8", label: "F30.8 Other manic episodes" },
    { value: "F41.3", label: "F41.3 Other mixed anxiety disorders" },
    { value: "F40.228", label: "F40.228 Other natural environment type phobia" },
    { value: "F65.89", label: "F65.89 Other paraphilias" },
    {
      value: "F34.8",
      label: "F34.8 Other persistent mood ºaffective» disorders",
    },
    {
      value: "F07.89",
      label: "F07.89 Other personality and behavioral disorders due to known physiological condition",
    },
    { value: "F84.8", label: "F84.8 Other pervasive developmental disorders" },
    { value: "F40.8", label: "F40.8 Other phobic anxiety disorders" },
    {
      value: "F19.121",
      label: "F19.121 Other psychoactive substance abuse with intoxication delirium",
    },
    {
      value: "F19.122",
      label: "F19.122 Other psychoactive substance abuse with intoxication with perceptual disturbances",
    },
    {
      value: "F19.188",
      label: "F19.188 Other psychoactive substance abuse with other psychoactive substance-induced disorder",
    },
    {
      value: "F19.180",
      label: "F19.180 Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
    },
    {
      value: "F19.14",
      label: "F19.14 Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
    },
    {
      value: "F19.16",
      label: "F19.16 Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
    },
    {
      value: "F19.17 ",
      label: "F19.17 Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
    },
    {
      value: "F19.150",
      label: "F19.150 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delus",
    },
    {
      value: "F19.151",
      label: "F19.151 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallu",
    },
    {
      value: "F19.159 ",
      label: "F19.159 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecific",
    },
    {
      value: "F19.181",
      label: "F19.181 Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
    },
    {
      value: "F19.182",
      label: "F19.182 Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
    },
    {
      value: "F19.19",
      label: "F19.19 Other psychoactive substance abuse with unspecified psychoactive substanceinduced disorder",
    },
    {
      value: "F19.221",
      label: "F19.221 Other psychoactive substance dependence with intoxication delirium",
    },
    {
      value: "F19.222",
      label: "F19.222 Other psychoactive substance dependence with intoxication with perceptual disturbance",
    },
    {
      value: "F19.288",
      label: "F19.288 Other psychoactive substance dependence with other psychoactive substanceinduced disorder",
    },
    {
      value: "F19.280 ",
      label: "F19.280 Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
    },
    {
      value: "F19.24",
      label: "F19.24 Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
    },
    {
      value: "F19.26",
      label: "F19.26 Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic diso",
    },
    {
      value: "F19.27",
      label: "F19.27 Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
    },
    {
      value: "F19.250",
      label: "F19.250 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with",
    },
    {
      value: "F19.251",
      label: "F19.251 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with",
    },
    {
      value: "F19.259 ",
      label: "F19.259 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unsp",
    },
    {
      value: "F19.281 ",
      label: "F19.281  Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
    },
    {
      value: "F19.282",
      label: "F19.282 Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
    },
    {
      value: "F19.29",
      label: "F19.29 Other psychoactive substance dependence with unspecified psychoactivesubstance-induced disorder",
    },
    {
      value: "F19.231",
      label: "F19.231 Other psychoactive substance dependence with withdrawal delirium",
    },
    {
      value: "F19.232",
      label: "F19.232 Other psychoactive substance dependence with withdrawal with perceptual disturbance",
    },
    {
      value: "F19.239",
      label: "F19.239 Other psychoactive substance dependence with withdrawal, unspecified",
    },
    {
      value: "F19.921",
      label: "F19.921 Other psychoactive substance use, unspecified with intoxication with delirium",
    },
    {
      value: "F19.922",
      label: "F19.922 Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
    },
    {
      value: "F19.988",
      label: "F19.988 Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
    },
    {
      value: "F19.980",
      label: "F19.980 Other psychoactive substance use, unspecified with psychoactive substanceinduced anxiety disorder",
    },
    {
      value: "F19.94",
      label: "F19.94 Other psychoactive substance use, unspecified with psychoactive substanceinduced mood disorder",
    },
    {
      value: "F19.96 ",
      label: "F19.96 Other psychoactive substance use, unspecified with psychoactive substanceinduced persisting amnesti",
    },
    {
      value: "F19.97",
      label: "F19.97 Other psychoactive substance use, unspecified with psychoactive substanceinduced persisting dementi",
    },
    {
      value: "F19.950",
      label: "F19.950 Other psychoactive substance use, unspecified with psychoactive substanceinduced psychotic disorder",
    },
    {
      value: "F19.951 ",
      label: "F19.951 Other psychoactive substance use, unspecified with psychoactive substanceinduced psychotic disorder",
    },
    {
      value: "F19.959",
      label: "F19.959 Other psychoactive substance use, unspecified with psychoactive substanceinduced psychotic disorder",
    },
    {
      value: "F19.981 ",
      label: "F19.981 Other psychoactive substance use, unspecified with psychoactive substanceinduced sexual dysfunction",
    },
    {
      value: "F19.982 ",
      label: "F19.982 Other psychoactive substance use, unspecified with psychoactive substanceinduced sleep disorder",
    },
    {
      value: "F19.931",
      label: "F19.931 Other psychoactive substance use, unspecified with withdrawal delirium",
    },
    {
      value: "F19.932",
      label: "F19.932 Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
    },
    {
      value: "F28 ",
      label: "F28  Other psychotic disorder not due to a substance or known physiological condition",
    },
    { value: "F43.8", label: "F43.8 Other reactions to severe stress" },
    { value: "F33.8", label: "F33.8 Other recurrent depressive disorders" },
    { value: "F25.8 ", label: "F25.8 Other schizoaffective disorders" },
    { value: "F20.89", label: "F20.89 Other schizophrenia" },
    { value: "F66", label: "F66 Other sexual disorders" },
    {
      value: "F52.8",
      label: "F52.8 Other sexual dysfunction not due to a substance or known physiological condition",
    },
    { value: "F40.248", label: "F40.248 Other situational type phobia" },
    {
      value: "F51.8",
      label: "F51.8 Other sleep disorders not due to a substance or known physiological condition",
    },
    { value: "F45.8", label: "F45.8 Other somatoform disorders" },
    { value: "F32.89", label: "F32.89 Other specified depressive episodes" },
    { value: "F50.89", label: "F50.89 Other specified eating disorder" },
    {
      value: "F34.89",
      label: "F34.89 Other specified persistent mood disorders",
    },
    { value: "F60.89", label: "F60.89 Other specific personality disorders" },
    { value: "F41.8", label: "F41.8 Other specified anxiety disorders" },
    {
      value: "F98.8",
      label: "F98.8 Other specified behavioral and emotional disorders with onset usually occurring in childhood and ado",
    },
    {
      value: "F68.8",
      label: "F68.8 Other specified disorders of adult personality and behavior",
    },
    {
      value: "F06.8",
      label: "F06.8 Other specified mental disorders due to known physiological condition",
    },
    { value: "F40.298", label: "F40.298 Other specified phobia" },
    {
      value: "F15.121",
      label: "F15.121 Other stimulant abuse with intoxication delirium",
    },
    {
      value: "F15.122",
      label: "F15.122 Other stimulant abuse with intoxication with perceptual disturbance",
    },
    {
      value: "F15.188",
      label: "F15.188 Other stimulant abuse with other stimulant-induced disorder",
    },
    {
      value: "F15.180",
      label: "F15.180 Other stimulant abuse with stimulant-induced anxiety disorder",
    },
    {
      value: "F15.14 ",
      label: "F15.14 Other stimulant abuse with stimulant-induced mood disorder",
    },
    {
      value: "F15.150",
      label: "F15.150 Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
    },
    {
      value: "F15.151",
      label: "F15.151 Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
    },
    {
      value: "F15.159",
      label: "F15.159 Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
    },
    {
      value: "F15.181",
      label: "F15.181 Other stimulant abuse with stimulant-induced sexual dysfunction",
    },
    {
      value: "F15.182",
      label: "F15.182 Other stimulant abuse with stimulant-induced sleep disorder",
    },
    {
      value: "F15.19",
      label: "F15.19 Other stimulant abuse with unspecified stimulant-induced disorder",
    },
    {
      value: "F15.221",
      label: "F15.221 Other stimulant dependence with intoxication delirium",
    },
    {
      value: "F15.222",
      label: "F15.222 Other stimulant dependence with intoxication with perceptual disturbance",
    },
    {
      value: "F15.288 ",
      label: "F15.288 Other stimulant dependence with other stimulant-induced disorder",
    },
    {
      value: "F15.280",
      label: "F15.280 Other stimulant dependence with stimulant-induced anxiety disorder",
    },
    {
      value: "F15.24",
      label: "F15.24 Other stimulant dependence with stimulant-induced mood disorder",
    },
    {
      value: "F15.250 ",
      label: "F15.250 Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
    },
    {
      value: "F15.251",
      label: "F15.251 Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
    },
    {
      value: "F15.259 ",
      label: "F15.259 Other stimulant dependence with stimulant-induced psychotic disorder,unspecified",
    },
    {
      value: "F15.281",
      label: "F15.281 Other stimulant dependence with stimulant-induced sexual dysfunction",
    },
    {
      value: "F15.282",
      label: "F15.282 Other stimulant dependence with stimulant-induced sleep disorder",
    },
    {
      value: "F15.29",
      label: "F15.29 Other stimulant dependence with unspecified stimulant-induced disorder",
    },
    {
      value: "F15.921",
      label: "F15.921 Other stimulant use, unspecified with intoxication delirium",
    },
    {
      value: "F15.922",
      label: "F15.922 Other stimulant use, unspecified with intoxication with perceptual disturbance",
    },
    {
      value: "F15.988",
      label: "F15.988 Other stimulant use, unspecified with other stimulant-induced disorder",
    },
    {
      value: "F15.980",
      label: "F15.980 Other stimulant use, unspecified with stimulant-induced anxiety disorder",
    },
    {
      value: "F15.94 ",
      label: "F15.94 Other stimulant use, unspecified with stimulant-induced mood disorder",
    },
    {
      value: "F15.950",
      label: "F15.950 Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
    },
    {
      value: "F15.951",
      label: "F15.951 Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
    },
    {
      value: "F15.959 ",
      label: "F15.959 Other stimulant use, unspecified with stimulant-induced psychotic disorder,  unspecified",
    },
    {
      value: "F15.981",
      label: "F15.981 Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
    },
    {
      value: "F15.982",
      label: "F15.982 Other stimulant use, unspecified with stimulant-induced sleep disorder",
    },
    { value: "F95.8", label: "F95.8 Other tic disorders" },
    {
      value: "F45.41",
      label: "F45.41 Pain disorder exclusively related to psychological factors",
    },
    {
      value: "F45.42",
      label: "F45.42 Pain disorder with related psychological factors",
    },
    {
      value: "F41.0",
      label: "F41.0 Panic disorder ºepisodic paroxysmal anxiety» without agoraphobia",
    },
    { value: "F51.03", label: "F51.03 Paradoxical insomnia" },
    { value: "F60.0", label: "F60.0 Paranoid personality disorder" },
    { value: "F20.0", label: "F20.0 Paranoid schizophrenia" },
    { value: "F65.9", label: "F65.9 Paraphilia, unspecified" },
    { value: "F63.0", label: "F63.0 Pathological gambling" },
    { value: "F65.4", label: "F65.4 Pedophilia" },
    {
      value: "F34.9",
      label: "F34.9 Persistent mood ºaffective» disorder, unspecified",
    },
    {
      value: "F07.0",
      label: "F07.0 Personality change due to known physiological condition",
    },
    { value: "F60.9", label: "F60.9 Personality disorder, unspecified" },
    {
      value: "F84.9",
      label: "F84.9 Pervasive developmental disorder, unspecified",
    },
    { value: "F40.9", label: "F40.9 Phobic anxiety disorder, unspecified" },
    { value: "F98.3", label: "F98.3 Pica of infancy and childhood" },
    { value: "F43.11", label: "F43.11 Post-traumatic stress disorder, acute" },
    { value: "F43.12", label: "F43.12 Post-traumatic stress disorder, chronic" },
    {
      value: "F43.10",
      label: "F43.10 Post-traumatic stress disorder, unspecified",
    },
    { value: "F07.81", label: "F07.81 Postconcussional syndrome" },
    { value: "F52.4", label: "F52.4 Premature ejaculation" },
    { value: "F32.81", label: "F32.81 Premenstrual dysphonic disorder" },
    { value: "F51.11", label: "F51.11 Primary hypersomnia" },
    { value: "F51.01", label: "F51.01 Primary insomnia" },
    { value: "F48.2", label: "F48.2 Pseudobulbar affect" },
    {
      value: "F06.2",
      label: "F06.2 Psychotic disorder with delusions due to known physiological condition",
    },
    {
      value: "F06.0",
      label: "F06.0 Psychotic disorder with hallucinations due to known physiological condition",
    },
    { value: "F53", label: "F53 Puerperal psychosis" },
    { value: "F63.1", label: "F63.1 Pyromania" },
    { value: "F43.9", label: "F43.9 Reaction to severe stress, unspecified" },
    { value: "F94.1", label: "F94.1 Reactive attachment disorder of childhood" },
    { value: "F20.5", label: "F20.5 Residual schizophrenia" },
    { value: "F98.21", label: "F98.21 umination disorder of infancy" },
    { value: "F65.50", label: "F65.50 Sadomasochism, unspecified" },
    { value: "F25.0", label: "F25.0 Schizoaffective disorder, bipolar type" },
    { value: "F25.1", label: "F25.1 Schizoaffective disorder, depressive type" },
    { value: "F25.9", label: "F25.9 Schizoaffective disorder, unspecified" },
    { value: "F60.1 ", label: "F60.1 Schizoid personality disorder" },
    { value: "F20.9", label: "F20.9 Schizophrenia, unspecified" },
    { value: "F20.81", label: "F20.81 Schizophreniform disorder" },
    { value: "F21", label: "F21 Schizotypal disorder" },
    {
      value: "F13.121",
      label: "F13.121 Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
    },
    {
      value: "F13.188",
      label: "F13.188 Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolyticinduced disorder",
    },
    {
      value: "F13.180",
      label: "F13.180 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced anxiety disorde",
    },
    {
      value: "F13.14 ",
      label: "F13.14 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced mood disorder",
    },
    {
      value: "F13.151",
      label: "F13.151 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced psychotic disor",
    },
    {
      value: "F13.159",
      label: "F13.159 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced psychotic disor",
    },
    {
      value: "F13.150 ",
      label: "F13.150 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced psychotic disor",
    },
    {
      value: "F13.181",
      label: "F13.181 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced sexual dysfunct",
    },
    {
      value: "F13.182",
      label: "F13.182 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolyticinduced sleep disorder",
    },
    {
      value: "F13.19",
      label: "F13.19 Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic oranxiolytic-induced dis",
    },
    {
      value: "F13.221",
      label: "F13.221 Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
    },
    {
      value: "F13.288 ",
      label: "F13.288 Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic oranxiolytic-induced diso",
    },
    {
      value: "F13.280",
      label: "F13.280 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced anxiety di",
    },
    {
      value: "F13.24",
      label: "F13.24 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced mood disor",
    },
    {
      value: "F13.27",
      label: "F13.27 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced persisting",
    },
    {
      value: "F13.26",
      label: "F13.26 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced persisting",
    },
    {
      value: "F13.259",
      label: "F13.259 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced psychotic",
    },
    {
      value: "F13.251",
      label: "F13.251 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced psychotic",
    },
    {
      value: "F13.250",
      label: "F13.250 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced psychotic",
    },
    {
      value: "F13.281",
      label: "F13.281 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced sexual dys",
    },
    {
      value: "F13.282",
      label: "F13.282 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolyticinduced sleep diso",
    },
    {
      value: "F13.29 ",
      label: "F13.29 Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induce",
    },
    {
      value: "F13.231",
      label: "F13.231 Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
    },
    {
      value: "F13.232",
      label: "F13.232 Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
    },
    {
      value: "F13.239",
      label: "F13.239 Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
    },
    {
      value: "F13.921",
      label: "F13.921 Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
    },
    {
      value: "F13.988",
      label: "F13.988 Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic oranxiolytic-induce",
    },
    {
      value: "F13.980",
      label: "F13.980 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxi",
    },
    {
      value: "F13.94 ",
      label: "F13.94 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood",
    },
    {
      value: "F13.97",
      label: "F13.97 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced pers",
    },
    {
      value: "F13.96",
      label: "F13.96 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced pers",
    },
    {
      value: "F13.950 ",
      label: "F13.950 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psyc",
    },
    {
      value: "F13.959",
      label: "F13.959 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psyc",
    },
    {
      value: "F13.951",
      label: "F13.951 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psyc",
    },
    {
      value: "F13.981",
      label: "F13.981 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexu",
    },
    {
      value: "F13.982",
      label: "F13.982 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced slee",
    },
    {
      value: "F13.931",
      label: "F13.931 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
    },
    {
      value: "F13.932",
      label: "F13.932 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
    },
    { value: "F94.0", label: "F94.0 Selective mutism" },
    { value: "F93.0", label: "F93.0 Separation anxiety disorder of childhood" },
    { value: "F52.1", label: "F52.1 Sexual aversion disorder" },
    { value: "F65.51", label: "F65.51 Sexual masochism" },
    { value: "F65.52 ", label: "F65.52  Sexual sadism" },
    { value: "F24", label: "F24 Shared psychotic disorder" },
    {
      value: "F51.9",
      label: "F51.9 Sleep disorder not due to a substance or known physiological condition,unspecified",
    },
    { value: "F51.4", label: "F51.4 Sleep terrors [night terrors]" },
    { value: "F51.3", label: "F51.3 Sleepwalking [somnambulism]" },
    { value: "F40.11", label: "F40.11 Social phobia, generalized" },
    { value: "F40.10 ", label: "F40.10 Social phobia, unspecified" },
    { value: "F80.82", label: "F80.82 Social pragmatic communication disorder" },
    { value: "F45.0", label: "F45.0 Somatization disorder" },
    { value: "F45.9", label: "F45.9 Somatoform disorder, unspecified" },
    {
      value: "R45.7",
      label: "R45.7 State of emotional shock and stress, unspecified",
    },
    { value: "F98.4", label: "F98.4 Stereotyped movement disorders" },
    { value: "F95.9", label: "F95.9 Tic disorder, unspecified" },
    { value: "F95.2", label: "F95.2 Tourette's disorder" },
    { value: "F95.0 ", label: "F95.0 Transient tic disorder" },
    { value: "F64.0", label: "F64.0 Transsexualism" },
    { value: "F65.1 ", label: "F65.1 Transvestic fetishism" },
    { value: "F63.3 ", label: "F63.3 Trichotillomania" },
    { value: "F20.3", label: "F20.3 Undifferentiated schizophrenia" },
    { value: "F45.1", label: "F45.1 Undifferentiated somatoform disorder" },
    {
      value: "F98.9 ",
      label: "F98.9 Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolesc",
    },
    {
      value: "F59",
      label: "F59 Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
    },
    {
      value: "F03.91",
      label: "F03.91 Unspecified dementia with behavioral disturbance",
    },
    {
      value: "F03.90",
      label: "F03.90 Unspecified dementia without behavioral disturbance",
    },
    {
      value: "F89 ",
      label: "F89 Unspecified disorder of psychological development",
    },
    {
      value: "F09",
      label: "F09 Unspecified mental disorder due to known physiological condition",
    },
    { value: "F39", label: "F39 Unspecified mood ºaffective» disorder" },
    {
      value: "F07.9 ",
      label: "F07.9 Unspecified personality and behavioral disorder due to known physiological condition",
    },
    {
      value: "F29",
      label: "F29 Unspecified psychosis not due to a substance or known physiological condition",
    },
    {
      value: "F52.9",
      label: "F52.9 Unspecified sexual dysfunction not due to a substance or known physiological condition",
    },
    {
      value: "F52.5",
      label: "F52.5 Vaginismus not due to a substance or known physiological condition",
    },
    {
      value: "F01.51 ",
      label: "F01.51 Vascular dementia with behavioral disturbance",
    },
    {
      value: "F01.50",
      label: "F01.50 Vascular dementia without behavioral disturbance",
    },
    { value: "F65.3 ", label: "F65.3 Voyeurism" },
  ];

  export default options;