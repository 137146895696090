import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";
import UserContext from "../../context/UserContext";
import { CommonService } from "../../services/CommonService";
import profileImg from "../../assets/images/profile-img.png";
import incognito from "../../assets/images/icons/incognito-icon-512x487-o2l6p9u6 1.png";
import { Util } from "../../Util";

const AvatarDashboard: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [incogniteModeChangingStatus, setIncogniteModeChangingStatus] = useState<boolean>(false);
  const history = useHistory();
  const mounted = useRef(false);

  function changeIncognitoMode(value: boolean) {
    if (incogniteModeChangingStatus) {
      toast.success("Already changing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setIncogniteModeChangingStatus(true);
      setUser({ ...user, incognito: value });
      CommonService.updateIncognitoMode(value).then(res => {
        if (res.success) {
          if (mounted) {
            setIncogniteModeChangingStatus(false);
          }
          if (res.success && value) {
            toast.success("You have enabled incognito mode.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          if (res.success && !value) {
            toast.success("You have disabled incognito mode.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

        } else {
          setUser({ ...user, incognito: !value });
          if (mounted) {
            setIncogniteModeChangingStatus(false);
          }
          toast.error("Incognito mode update failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }

    // setUser({ ...user, incognito: value });
  }

  const handleAvatarClick =()=>{
    localStorage.setItem("avatar","true")
  }
  
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody>
          <div className="d-flex j-btwn">
            <h5 className="mb-0 fs-14 fc-black mb-2">My Avatar</h5>
            <Link to={{ pathname: "/chat", state: { currentTab: 3 } }} className="btn btn-primary  btn-sm" onClick={handleAvatarClick}>
              New Avatar&nbsp;<i className="bx bx-plus"></i>
            </Link>
          </div>
          <Row className="mt-2">
            <Col className="d-flex align-items-center">
              <p className="mb-0 me-2">
                Enable Incognito Mode&nbsp;<i className="bx bx-info-circle" id="bx-info-circle"></i>
              </p>
              <UncontrolledTooltip placement="bottom" target={"bx-info-circle"}>
                You can hide your privacy using Incognito mode.
              </UncontrolledTooltip>

              <div className="incognito-btn-new ">
                <div
                  className={`real-img-new  ${!user?.incognito ? "mode-active " : "mode-deactive"}`}
                  id="normal-mode"
                  style={{
                    backgroundImage: user?.photoId == null || user?.photoId == undefined ? `url(${profileImg})` : `url("${Util.fileURL(user?.photoId?._id)}")`,
                  }}
                  onClick={() => {
                    if (user?.incognito) {
                      changeIncognitoMode(!user?.incognito);
                    }
                  }}
                ></div>
                <UncontrolledTooltip placement="bottom" target={"normal-mode"}>
                  Active normal mode
                </UncontrolledTooltip>

                <div
                  className={`inco-img-new d-flex justify-content-center align-items-center  ${user?.incognito ? "mode-active " : "mode-deactive"}`}
                  id="incog-mode"
                  // style={{
                  //   backgroundImage: `url(${incognito})`,
                  // }}
                  onClick={() => {
                    if (!user?.incognito) {
                      changeIncognitoMode(!user?.incognito);
                    }
                  }}
                ><img src={incognito} className="incog-icon-size"/></div>
                <UncontrolledTooltip placement="right" target={"incog-mode"}>
                  Active incognito mode
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AvatarDashboard;
