import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Modal, Button, ModalBody, Input } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";
import Close from "../../assets/images/icons/close.png";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";
import { ReminderSms } from "src/models/ReminderSms";

const ViewTherapistScore: React.FC = () => {
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [offSet, setOffSet] = useState(1);
    const [hasMore, setMore] = useState(false);
    const [therapistScoreList, setTherapistScoreList] = useState<any>([]);

    const LIMIT = 15;

    useEffect(() => {
        getTherapistScores();
    }, []);

    const getTherapistScores = () => {
        setIsloading(true);

        AdminService.getTherapistScores(LIMIT, offSet).then(res => {
            if (res.success) {
                console.log(res);
                if(res.data.length < 15){
                    setMore(false);
                } else {
                    setMore(true);
                }
                setTherapistScoreList(res?.data);
                setIsloading(false);
            } else {
                setTherapistScoreList([]);
                setMore(false);
                setIsloading(false);
            }
        });
    };


    const seeMoreScores = () => {
        const updatedOffset = offSet + 1;
        AdminService.getTherapistScores(LIMIT, updatedOffset).then(res => {
            if (res.success) {
                const scores = res?.data;
                setOffSet(updatedOffset);
                console.log(res);
                if(res.data.length < 15){
                    setMore(false);
                } else {
                    setMore(true);
                }
                setTherapistScoreList([...therapistScoreList, ...scores]);
                setIsloading(false);
            } else {
                setTherapistScoreList([]);
                setMore(false);
                setIsloading(false);
            }
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Therapist Score"} breadcrumbItem={"Therapist Score"} />

                    {isLoading && <Spinner className="bouncer" />}

                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl="12">

                                    <div className="table-responsive">
                                        <Row>
                                            <Col sm="12" className="mt10">
                                                {!isLoading && therapistScoreList?.length == 0 && <div className="text-muted text-center">No any therapist score</div>}

                                                {therapistScoreList && therapistScoreList.length > 0 && (
                                                    <div className="table-responsive">
                                                    <table className="table">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                              <th scope="col">#</th>
                                                              <th scope="col">Email</th>
                                                              <th scope="col">Name</th>
                                                              <th scope="col">Score</th>
                                                              <th scope="col">Priority Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {therapistScoreList?.map((score: any, index: any) => (
                                                              <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>
                                                                  {score?.therapists?.email}
                                                                </td>
                                                                <td>
                                                                  {score?.therapists?.firstname} {score?.therapists?.lastname} 
                                                                </td>
                                                                <td>
                                                                  {score?.score} 
                                                                </td>
                                                                <td>
                                                                  {score?.therapists?.priorityNumber} 
                                                                </td>
                                                              </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="12 mt30 mb-3 text-center">
                                                {hasMore && (
                                                    <button className="btn btn-warning" onClick={() => seeMoreScores()}>
                                                        See More
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewTherapistScore;
