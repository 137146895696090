import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { RouteName } from "../../RouteName";
import user1 from "../../assets/images/default_profile.png";
import UserContext from "../../context/UserContext";

const SidebarContentSaas = props => {
  const ref = useRef();
  const wrapperRef = useRef(null);
  const location = props.location;
  const [menu, setMenu] = useState(false);
  const [user] = useContext(UserContext);
  const collapseState = useState(document.body.classList.contains("sidebar-enable"));
  const verticalEnable = useState(document.body.classList.contains("vertical-collpsed"));
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && window.screen.width <= 425 && document.body.classList.contains("sidebar-enable")) {
      document.body.classList.toggle("sidebar-enable");
    }
  };

  useEffect(() => {
    const initMenu = () => {
      if (ref.current) {
        const menuInstance = ref.current.metisMenu;
        if (menuInstance) {
          menuInstance.dispose();
        }
        ref.current.metisMenu = new MetisMenu("#side-menu");
      }
    };

    initMenu();

    return () => {
      if (ref.current && ref.current.metisMenu) {
        ref.current.metisMenu.dispose();
      }
    };
  }, [props.location.pathname]);

  useEffect(() => {
    if (ref.current) {
      ref.current.recalculate();
    }
  }, [menu]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateSidebarState = () => {
      const isCollapsed = document.body.classList.contains("vertical-collpsed");
      verticalEnable[1](isCollapsed);
    };

    const observer = new MutationObserver(updateSidebarState);
    observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

    return () => observer.disconnect();
  }, []);

  const hideSidebarAfterClick = () => {
    if (window.screen.width <= 425) {
      document.body.classList.toggle("sidebar-enable");
    }
  };
  return (
    <div ref={wrapperRef} style={{ position: "relative", height: "100%", overflow: "hidden",margin: "0", // Ensure no margin
      padding: "0", }}>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled mm-show mm-active" id="side-menu">
            {/* Sidebar Items */}
            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.DASHBOARD} className="" style={{marginTop:"20px"}}>
                <i className="bx bxs-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </NavLink>
            </li>

            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.SAAS_ALL_CLIENTS} className="">
                <i className="bx bxs-group"></i>
                <span>{props.t("View Clients")}</span>
              </NavLink>
            </li>

            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.SAAS_CLIENTS_CHART} className="">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Clients Chart")}</span>
              </NavLink>
            </li>

            <li onClick={hideSidebarAfterClick}>
              <NavLink
                to={RouteName.CHAT_MENU}
                className={({ isActive }) =>
                  isActive || window.location.pathname === RouteName.GROUP_CHAT_MENU ? "active" : ""
                }
              >
                <i className="bx bx-chat"></i>
                <span>{props.t("Chat")}</span>
              </NavLink>
            </li>

            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.APPOINTMENTS} className="">
                <i className="fa fa-calendar"></i>
                <span>{props.t("Appointments")}</span>
              </NavLink>
            </li>

            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.SAAS_TODO} className="">
                <i className="bx bx-line-chart"></i>
                <span>{props.t("To-do")}</span>
              </NavLink>
            </li>

            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.APPROVALS} className="">
                <i className="bx bx-file"></i>
                <span>{props.t("Document Submission")}</span>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to={RouteName.SAAS_PAYMENT} className="">
                <i className="bx bx-credit-card"></i>
                <span>{props.t("Payment")}</span>
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink to={RouteName.SAAS_CLAIMS} className="">
                <i className="fas fa-file-invoice-dollar"></i>
                <span>{props.t("Claims")}</span>
              </NavLink>
            </li> */}

            {/* <li>
                    <NavLink to={RouteName.SAAS_CREDENTIALING} className="">
                    <i className="fas fa-id-badge"></i>
                    <span>{props.t("Credentialing")}</span>
                    </NavLink>
                  </li> */}
            <li>
              <NavLink to={`${RouteName.THERAPIST_REFERRAL_NEW}${user?._id}`} className="">
                <i className="bx bx-gift"></i>
                <span>{props.t("Rewards")}</span>
              </NavLink>
            </li>
            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.EARNINGS_MENU} className="">
                <i className="bx bx-dollar-circle"></i>
                <span>{props.t("Total Earnings")}</span>
              </NavLink>
            </li>
            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.ARTICLE_LIST} className="">
                <i className="bx bx-world"></i>
                <span>{props.t("Discover")}</span>
              </NavLink>
            </li>
            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.DOCUMENTS} className="">
                <i className="bx bx-book-content"></i>
                <span>{props.t("Training Documents")}</span>
              </NavLink>
            </li>
            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.SUPPORT} className="">
                <i className="bx bx-headphone"></i>
                <span> {props.t("Support")}</span>
              </NavLink>
            </li>
            <li onClick={hideSidebarAfterClick}>
              <NavLink to={RouteName.TECH} className="">
                <i className="bx bx-help-circle"></i>
                <span> {props.t("Help")}</span>
              </NavLink>
            </li>
            <li onClick={hideSidebarAfterClick} style={{marginBottom:"120px"}}>
              {location.pathname != RouteName.ONBOARDING &&
                location.pathname != RouteName.THERAPISTPERSONALINFORMATIONONBOARDINGPAGE &&
                location.pathname != RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE &&
                location.pathname != RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE &&
                location.pathname != RouteName.THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE && (
                  <NavLink to="/notifications">
                    <i className="fas fa-bell"></i>
                    <span>{props.t("Notifications")}</span>
                  </NavLink>
                )}
            </li>
            <li
              style={{
                position: "sticky",
                bottom: "0",
                width: "100%",
                padding: "1rem",
                borderTop: "1px solid #4b5563",
                display: "flex",
                alignItems: collapseState[0] ? "center" : "flex-start",
                flexDirection: "column",
                background: "rgb(42 48 66)",
                zIndex: 1,
                marginTop: "auto",
              }}
            >
              <a href="#sidebarProfileMenu" className="has-arrow p-0 d-flex align-items-center">
                <img
                  src={user?.photoId ? user.photoId.url : user1}
                  alt="Profile"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                    marginRight: "10px",
                  }}
                >
                  {user !== null && user !== undefined ? `${user.firstname} ${user.lastname}` : "-"}
                </span>
              </a>
              {!verticalEnable[0] && (
                <ul className="sub-menu mm-collapse" id="sidebarProfileMenu">
                  <li style={{ padding: "6px 0" }} onClick={hideSidebarAfterClick}>
                    <NavLink to={RouteName.PROFILE} className="dropdown-item">
                      <i className="bx bxs-user"></i>
                      <span>{props.t("Profile")}</span>
                    </NavLink>
                  </li>
                  {/* <li style={{ padding: "6px 0" }}>
                      <NavLink to={RouteName.SAAS_CREDENTIALING} className="dropdown-item">
                        <i className="bx bx-cog font-size-16 align-middle me-1"></i>
                        <span>{props.t("Settings")}</span>
                      </NavLink>
                      </li> */}
                  <li style={{ padding: "6px 0" }}></li>
                  <li style={{ padding: "6px 0" }}>
                    <a onClick={logout} className="dropdown-item">
                      <i className="bx bx-power-off font-size-16 align-middle" />
                      <span>{props.t("Logout")}</span>
                    </a>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </div>
  );
};

SidebarContentSaas.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContentSaas));
