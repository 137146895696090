import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Button } from "reactstrap";
import Close from "../../../assets/images/icons/close.png";
import ViewProfileSaas from "./ViewProfileSaas";

type ModalComponentProps = {
  isOpen: boolean;
  toggle: () => void;
  clientId: string;
};

const ProfileModalSaas: React.FC<ModalComponentProps> = ({ isOpen, toggle, clientId }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggle}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
          className="btn-s-20"
        >
          <img src={Close} alt="Close" className="mt-1" />
        </Button>
      </div>
      <ModalBody className="mt-3">
        <Row>
          <ViewProfileSaas clientId={clientId} />
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ProfileModalSaas;
