import React, { useState, useCallback, useContext, useEffect, MutableRefObject } from "react";
import classNames from "classnames";
import { Modal, message } from "antd";
// import ZoomContext from "../../../context/zoom-context";
import RecordingContext from "../../../context/recording-context";
import CameraButton from "./camera";
import MicrophoneButton from "./microphone";
import { ScreenShareButton } from "./screen-share";
import AudioVideoStatisticModal from "./audio-video-statistic";
import ZoomMediaContext from "../../../context/media-context";
import LiveTranscriptionContext from "../../../context/live-transcription";
import { useUnmount, useMount } from "../../../hooks";
import { MediaDevice } from "../video-types";
import "./video-footer.scss";
import { isAndroidOrIOSBrowser } from "../../../utils/platform";
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from "../video-constants";
import { getRecordingButtons, RecordButtonProps, RecordingButton } from "./recording";
import ZoomVideo, {
  DialoutState,
  RecordingStatus,
  MutedSource,
  AudioChangeAction,
  DialOutOption,
  VideoCapturingState,
  SharePrivilege,
  MobileVideoFacingMode,
  Participant,
} from "@zoom/videosdk";
import { LiveTranscriptionButton } from "./live-transcription";
import { LeaveButton } from "./leave";
import { TranscriptionSubtitle } from "./transcription-subtitle";
import IsoRecordingModal from "./recording-ask-modal";
import { useHistory } from "react-router-dom";
import { InviteButton } from "./invite";
import SessionContext from "../../../context/session-context";
import LocalCallContext from "src/context/LocalCallContext";
import ZoomContext from "src/pages/Chat/VideoCallNew/context/ZoomContext";
import { VideoChatService } from "src/services/VideoChatService";
import { useParticipantsChange } from "../hooks/useParticipantsChange";
import UserContext from "src/context/UserContext";
import CallInitializationContext from "src/context/CallInitializationContext";
import { toast } from "react-toastify";
import StartAudioModal from "src/pages/Popup/StartAudioModal";
import Modal10 from "src/pages/Popup/Modal10";
import VideoButtonContext from "../../../context/video-button-context";

interface VideoFooterProps {
  className?: string;
  shareRef?: MutableRefObject<HTMLCanvasElement | null>;
  sharing?: boolean;
  leaveMeeting?: any;
  setTranscribeList? : any;
}

const isAudioEnable = typeof AudioWorklet === "function";

const VideoFooter = (props: VideoFooterProps) => {
  const { className, shareRef, sharing } = props;
  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isStartedVideo, setIsStartedVideo] = useState(false);
  const [audio, setAudio] = useState("");
  const [isSupportPhone, setIsSupportPhone] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [isStartedScreenShare, setIsStartedScreenShare] = useState(false);
  const [isStartedLiveTranscription, setIsStartedLiveTranscription] = useState(false);
  const [isMirrored, setIsMirrored] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [activeCamera, setActiveCamera] = useState("");
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [statisticVisible, setStatisticVisible] = useState(false);
  const [selecetedStatisticTab, setSelectedStatisticTab] = useState("audio");
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
  const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
  const [caption, setCaption] = useState({ text: "", isOver: false });
  const [activePlaybackUrl, setActivePlaybackUrl] = useState("");
  const { sessionName, sessionPassword } = useContext(SessionContext);
  const [transcribeListInFooter, setTranscribeListInFooter] = useState<any>([])

  const { mediaStream } = useContext(ZoomMediaContext);
  const { liveTranscriptionClient } = useContext(LiveTranscriptionContext);
  const { recordingClient } = useContext(RecordingContext);
  const [recordingStatus, setRecordingStatus] = useState<"" | RecordingStatus>(recordingClient?.getCloudRecordingStatus() || "");
  const [recordingIsoStatus, setRecordingIsoStatus] = useState<"" | RecordingStatus>("");
  const zoomClient = useContext(ZoomContext);
  const history = useHistory();

  // /////

  const [
    localUserOnCall,
    setLocalUserOnCall,
    cancellCallAfterCallSend,
    setOutGoinCallTimeOutFunction,
    setNearestAppointmentFunction,
    startTimerWhenBothJoined,
    resetTimerWhenSecondTimeJoined,
  ] = useContext(LocalCallContext);

  // const [localParticipant, setLocalParticipant] = useState<Participant>();
  // const [remoteParticipant, setRemoteParticipant] = useState<Participant>();
  const [bothJoined, setBothJoined] = useState(false);
  // const [audioInitializedWithoutMic, setAudioInitializedWithoutMic] = useState(false);
  // const [audioInitializedWithMic, setAudioInitializedWithMic] = useState(false);
  const [audioInitializedFirstTime, setAudioInitializedFirstTime] = useState(false);

  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  // const [participants, setParticipants] = useState<Participant[]>([]);

  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [cameraButtonClick, setCameraButtonClick] = useContext(VideoButtonContext);


  useEffect(() => {
    const enableLiveTranscribe = async () => {
        await liveTranscriptionClient?.startLiveTranscription()

    }
    enableLiveTranscribe()
  }, [])

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
      ZoomVideo.destroyClient();
    };
  }, []);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useParticipantsChange(zoomClient, (payload: any) => {
    try {
      const currentUser = zoomClient.getCurrentUserInfo();
      let hasTwoUsers = false;

      payload.forEach((element: any) => {
        if (currentUser.userIdentity != element.userIdentity) {
          hasTwoUsers = true;
        }
      });

      if (hasTwoUsers && !bothJoined) {
        VideoChatService.acceptZoomCall(localCallInitializeData.meetingId);
        setBothJoined(true);
        startTimerWhenBothJoined();
        startRecording();
      }

      // setParticipants(payload);
      // const currentUser = zoomClient.getCurrentUserInfo();
      // setLocalParticipant(currentUser);
      // if (payload.length > 1) {
      //   const romoteUser = zoomClient.getUser(payload[1].userId);
      //   if (romoteUser && romoteUser.userId != currentUser.userId) {
      //     setRemoteParticipant(romoteUser);
      //   } else {
      //     const romoteUser = zoomClient.getUser(payload[0].userId);
      //     if (romoteUser && romoteUser.userId != currentUser.userId) {
      //       setRemoteParticipant(romoteUser);
      //     }
      //   }
      //   setIsMuted;
      // }

      // if (payload && payload.length > 1 && !bothJoined) {
      //   VideoChatService.acceptZoomCall(localCallInitializeData.meetingId);
      //   setBothJoined(true);
      //   startTimerWhenBothJoined();
      //   startRecording();
      // }
    } catch (error) {
      console.log(error);
    }
  });

  const startRecording = async () => {
    // try {
    //   if (localUserOnCall.recieverCallRecordingAllowed && loggedUser?.callRecordingAllowed) {
    //     if (recordingClient?.canStartRecording() && zoomClient?.isHost()) {
    //       const recordingStatus = recordingClient?.getCloudRecordingStatus();
    //       if (recordingStatus != "Recording") {
    //         console.log("start cloud recording");
    //         await recordingClient?.startCloudRecording();
    //       }
    //     } else {
    //       console.log("can't start recording");
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const onInitializeAudioClick = async () => {
    try {
      await mediaStream?.startAudio();
      setAudioInitializedFirstTime(true);
      setIsStartedAudio(true);
    } catch (error) {
      console.log("Microphone Error");
      console.log(error);
      toast.error(`Please allow microphone access`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const [showLeavePopUp, setShowLeavePopUp] = useState(false);
  const [leaveMeetingInProcess, setLeaveMeetingInProcess] = useState(false);
  const onLeaveButtonClick = useCallback(async () => {
    try {
      const transcribeLis = await liveTranscriptionClient?.getFullTranscriptionHistory()
      setTranscribeListInFooter(transcribeLis)
      if (!leaveMeetingInProcess) {
        if (navigator.onLine) {
          setShowLeavePopUp(true);
        } else {
          toast.error(`No internet . Please check your internet connection`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      toast.error(`error occured ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  }, [zoomClient]);

  const leaveMeetingPopUpFunction = (showModal: boolean, leave: boolean) => {
    setShowLeavePopUp(showModal);
    if (leave) {
      setLeaveMeetingInProcess(true);
      props.setTranscribeList(transcribeListInFooter)
      props.leaveMeeting();
    } else {
      setLeaveMeetingInProcess(false);
    }
  };
  // /////

  const onCameraClick = useCallback(async () => {
    try {
      if (isStartedVideo) {
        await mediaStream?.stopVideo();
        setCameraButtonClick(false);
        setIsStartedVideo(false);
      } else {
        if (mediaStream?.isRenderSelfViewWithVideoElement()) {
          const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;

          if (videoElement) {
            await mediaStream?.startVideo({ videoElement });
          }
        } else {
          const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };

          if (mediaStream?.isSupportVirtualBackground() && isBlur) {
            Object.assign(startVideoOptions, { virtualBackground: { imageUrl: "blur" } });
          }

          await mediaStream?.startVideo(startVideoOptions);

          if (!mediaStream?.isSupportMultipleVideos()) {
            const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;

            mediaStream?.renderVideo(canvasElement, zoomClient.getSessionInfo().userId, canvasElement.width, canvasElement.height, 0, 0, 3);
          }
        }
        setCameraButtonClick(true);
        setIsStartedVideo(true);
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [mediaStream, isStartedVideo, zoomClient, isBlur]);

  const onMicrophoneClick = useCallback(async () => {
    try {
      if (isStartedAudio) {
        if (isMuted) {
          setIsMuted(false);
          await mediaStream?.unmuteAudio();
        } else {
          setIsMuted(true);
          await mediaStream?.muteAudio();
        }
      } else {
        setIsStartedAudio(true);
        // await mediaStream?.startAudio({ speakerOnly: true });
        await mediaStream?.startAudio();
      }
    } catch (error: any) {
      console.log(error);
      try {
        await mediaStream?.stopAudio();
        await mediaStream?.startAudio({ speakerOnly: true });
        if (error && error.reason == "USER_FORBIDDEN_MICROPHONE") {
          toast.error(`Please allow microphone access`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(`Please allow microphone access + ${error && error.reason ? error.reason : ""}`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } catch (error) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    }
  }, [mediaStream, isStartedAudio, isMuted]);

  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split("|");
      if (type === "microphone") {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === "speaker") {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === "leave audio") {
        if (audio === "computer") {
          await mediaStream.stopAudio();
        } else if (audio === "phone") {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
        setIsStartedAudio(false);
      } else if (type === "statistic") {
        setSelectedStatisticTab("audio");
        setStatisticVisible(true);
      }
    }
  };

  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
        setActivePlaybackUrl("");
      }
    }
  };

  const onMirrorVideo = async () => {
    await mediaStream?.mirrorVideo(!isMirrored);
    setIsMirrored(!isMirrored);
  };

  const onBlurBackground = async () => {
    const vbStatus = mediaStream?.getVirtualbackgroundStatus();
    if (vbStatus?.isVBPreloadReady) {
      if (!isBlur) {
        await mediaStream?.updateVirtualBackgroundImage("blur");
      } else {
        await mediaStream?.updateVirtualBackgroundImage(undefined);
      }

      setIsBlur(!isBlur);
    }
  };

  const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
    await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
  };

  const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
    if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
      await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
      await new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 3000);
      });
    }
    return Promise.resolve();
  };

  const onHostAudioMuted = useCallback((payload: any) => {
    const { action, source, type } = payload;
    if (action === AudioChangeAction.Join) {
      setIsStartedAudio(true);
      setAudio(type);
    } else if (action === AudioChangeAction.Leave) {
      setIsStartedAudio(false);
    } else if (action === AudioChangeAction.Muted) {
      setIsMuted(true);
      if (source === MutedSource.PassiveByMuteOne) {
        message.info("Host muted you");
      }
    } else if (action === AudioChangeAction.Unmuted) {
      setIsMuted(false);
      if (source === "passive") {
        message.info("Host unmuted you");
      }
    }
  }, []);

  const onScreenShareClick = useCallback(async () => {
    try {
      if (!isStartedScreenShare && shareRef && shareRef.current) {
        await mediaStream?.startShareScreen(shareRef.current, { requestReadReceipt: true });
        setIsStartedScreenShare(true);
      } else if (isStartedScreenShare) {
        await mediaStream?.stopShareScreen();
        setIsStartedScreenShare(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [mediaStream, isStartedScreenShare, shareRef]);

  const onLiveTranscriptionClick = useCallback(async () => {
    if (!isStartedLiveTranscription) {
      await liveTranscriptionClient?.startLiveTranscription();
      setIsStartedLiveTranscription(true);
      message.info("Auto live transcription enabled!");
    } else {
      setIsStartedLiveTranscription(false);
      message.info("Live transcription turned off!");
    }
  }, [isStartedLiveTranscription, liveTranscriptionClient]);

  const onPassivelyStopShare = useCallback(({ reason }: any) => {
    setIsStartedScreenShare(false);
  }, []);

  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      if (!isAndroidOrIOSBrowser()) {
        setCameraList(mediaStream.getCameraList());
      }
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onRecordingChange = useCallback(() => {
    setRecordingStatus(recordingClient?.getCloudRecordingStatus() || "");
  }, [recordingClient]);

  const onRecordingISOChange = useCallback(
    (payload: any) => {
      if (payload?.userId === zoomClient?.getSessionInfo().userId || payload?.status === RecordingStatus.Ask) {
        setRecordingIsoStatus(payload?.status);
      }
    },
    [zoomClient]
  );

  const onDialOutChange = useCallback((payload: any) => {
    setPhoneCallStatus(payload.code);
  }, []);

  const onRecordingClick = async (key: string) => {
    switch (key) {
      case "Record": {
        await recordingClient?.startCloudRecording();
        break;
      }
      case "Resume": {
        await recordingClient?.resumeCloudRecording();
        break;
      }
      case "Stop": {
        await recordingClient?.stopCloudRecording();
        break;
      }
      case "Pause": {
        await recordingClient?.pauseCloudRecording();
        break;
      }
      case "Status": {
        break;
      }
      default: {
        await recordingClient?.startCloudRecording();
      }
    }
  };

  const onVideoCaptureChange = useCallback((payload: any) => {
    if (payload.state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, []);

  const onShareAudioChange = useCallback(
    (payload: any) => {
      const { state } = payload;
      if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
        if (state === "on") {
          setIsComputerAudioDisabled(true);
        } else if (state === "off") {
          setIsComputerAudioDisabled(false);
        }
      }
    },
    [mediaStream]
  );

  const onHostAskToUnmute = useCallback((payload: any) => {
    const { reason } = payload;
  }, []);

  const onCaptionStatusChange = useCallback((payload: any) => {
    const { autoCaption } = payload;

    if (autoCaption) {
      // message.info('Auto live transcription enabled!');
    }
  }, []);

  const onCaptionMessage = useCallback((payload: any) => {
    const { text, done } = payload;
    setCaption({
      text,
      isOver: done,
    });
  }, []);

  const onCanSeeMyScreen = useCallback(() => {
    message.info("Users can now see your screen", 1);
  }, []);

  const onSelectVideoPlayback = useCallback(
    async (url: string) => {
      if (activePlaybackUrl !== url) {
        await mediaStream?.switchCamera({ url, loop: true });
        if (isStartedAudio) {
          await mediaStream?.switchMicrophone({ url, loop: true });
        } else {
          await mediaStream?.startAudio({ mediaFile: { url, loop: true } });
        }
        setActivePlaybackUrl(url);
      }
    },
    [isStartedAudio, activePlaybackUrl, mediaStream]
  );

  useEffect(() => {
    zoomClient?.on("current-audio-change", onHostAudioMuted);
    zoomClient?.on("passively-stop-share", onPassivelyStopShare);
    zoomClient?.on("device-change", onDeviceChange);
    zoomClient?.on("recording-change", onRecordingChange);
    zoomClient?.on("individual-recording-change", onRecordingISOChange);
    zoomClient?.on("dialout-state-change", onDialOutChange);
    zoomClient?.on("video-capturing-change", onVideoCaptureChange);
    zoomClient?.on("share-audio-change", onShareAudioChange);
    zoomClient?.on("host-ask-unmute-audio", onHostAskToUnmute);
    zoomClient?.on("caption-status", onCaptionStatusChange);
    zoomClient?.on("caption-message", onCaptionMessage);
    zoomClient?.on("share-can-see-screen", onCanSeeMyScreen);

    return () => {
      zoomClient?.off("current-audio-change", onHostAudioMuted);
      zoomClient?.off("passively-stop-share", onPassivelyStopShare);
      zoomClient?.off("device-change", onDeviceChange);
      zoomClient?.off("recording-change", onRecordingChange);
      zoomClient?.off("individual-recording-change", onRecordingISOChange);
      zoomClient?.off("dialout-state-change", onDialOutChange);
      zoomClient?.off("video-capturing-change", onVideoCaptureChange);
      zoomClient?.off("share-audio-change", onShareAudioChange);
      zoomClient?.off("host-ask-unmute-audio", onHostAskToUnmute);
      zoomClient?.off("caption-status", onCaptionStatusChange);
      zoomClient?.off("caption-message", onCaptionMessage);
      zoomClient?.off("share-can-see-screen", onCanSeeMyScreen);
    };
  }, [
    zoomClient,
    onHostAudioMuted,
    onPassivelyStopShare,
    onDeviceChange,
    onRecordingChange,
    onDialOutChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onHostAskToUnmute,
    onCaptionStatusChange,
    onCaptionMessage,
    onCanSeeMyScreen,
    onRecordingISOChange,
  ]);

  // useEffect(() => {
  //   onRecordingClick("Record");
  // }, [recordingClient]);

  // useUnmount(() => {
  //   if (isStartedAudio) {
  //     mediaStream?.stopAudio();
  //   }

  //   if (isStartedVideo) {
  //     mediaStream?.stopVideo();
  //   }

  //   if (isStartedScreenShare) {
  //     mediaStream?.stopShareScreen();
  //   }
  // });

  useUnmount(() => {
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
  });

  useMount(() => {
    if (mediaStream) {
      setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
      setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
      setSharePrivileg(mediaStream.getSharePrivilege());
      if (isAndroidOrIOSBrowser()) {
        setCameraList([
          { deviceId: MobileVideoFacingMode.User, label: "Front-facing" },
          { deviceId: MobileVideoFacingMode.Environment, label: "Rear-facing" },
        ]);
      }
    }
  });

  useEffect(() => {
    if (mediaStream && zoomClient?.getSessionInfo().isInMeeting) {
      mediaStream.subscribeAudioStatisticData();
      mediaStream.subscribeVideoStatisticData();
      mediaStream.subscribeShareStatisticData();
    }
    return () => {
      if (zoomClient?.getSessionInfo().isInMeeting) {
        mediaStream?.unsubscribeAudioStatisticData();
        mediaStream?.unsubscribeVideoStatisticData();
        mediaStream?.unsubscribeShareStatisticData();
      }
    };
  }, [mediaStream, zoomClient]);

  const recordingButtons: RecordButtonProps[] = getRecordingButtons(recordingStatus, zoomClient?.isHost());

  const onCopyInvite = () => {

    navigator.clipboard.writeText("https://lavni-zoom.efito.xyz/video/" + sessionName + "/pwd/" + sessionPassword + "/guest/");

    Modal.info({
      title: "Invite Meeting",
      content: "Invite copied to clipboard!",
    });
  };

  return (
    <React.Fragment>
      {!audioInitializedFirstTime && <StartAudioModal setShowModal={onInitializeAudioClick} showModal={!audioInitializedFirstTime} />}
      {showLeavePopUp && <Modal10 setShowModal={leaveMeetingPopUpFunction} showModal={showLeavePopUp} />}

      <div className={classNames("video-footer", className)}>
        {isAudioEnable && (
          <MicrophoneButton
            isStartedAudio={isStartedAudio}
            isMuted={isMuted}
            isSupportPhone={isSupportPhone}
            audio={audio}
            phoneCountryList={phoneCountryList}
            onPhoneCallClick={onPhoneCall}
            onPhoneCallCancel={onPhoneCallCancel}
            phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
            onMicrophoneClick={onMicrophoneClick}
            onMicrophoneMenuClick={onMicrophoneMenuClick}
            microphoneList={micList}
            speakerList={speakerList}
            activeMicrophone={activeMicrophone}
            activeSpeaker={activeSpeaker}
            disabled={isComputerAudioDisabled}
          />
        )}
        {
          !localUserOnCall.isAudioCall &&(
            <CameraButton
              isStartedVideo={isStartedVideo}
              onCameraClick={onCameraClick}
              onSwitchCamera={onSwitchCamera}
              onMirrorVideo={onMirrorVideo}
              onVideoStatistic={() => {
                setSelectedStatisticTab("video");
                setStatisticVisible(true);
              }}
              onBlurBackground={onBlurBackground}
              onSelectVideoPlayback={onSelectVideoPlayback}
              activePlaybackUrl={activePlaybackUrl}
              cameraList={cameraList}
              activeCamera={activeCamera}
              isMirrored={isMirrored}
              isBlur={isBlur}
            />
          )
        }
        

        {sharing && !isAndroidOrIOSBrowser() && !localUserOnCall.isAudioCall && (
          <ScreenShareButton
            sharePrivilege={sharePrivilege}
            isHostOrManager={zoomClient?.isHost() || zoomClient?.isManager()}
            isStartedScreenShare={isStartedScreenShare}
            onScreenShareClick={onScreenShareClick}
            onSharePrivilegeClick={async privilege => {
              await mediaStream?.setSharePrivilege(privilege);
              setSharePrivileg(privilege);
            }}
          />
        )}

        {/* {recordingButtons.map((button: RecordButtonProps) => {
          return (
            <RecordingButton
              key={button.text}
              onClick={() => {
                // onRecordingClick(button.text);
              }}
              {...button}
            />
          );
        })} */}

        {/* {liveTranscriptionClient?.getLiveTranscriptionStatus().isLiveTranscriptionEnabled && (
          <>
            <LiveTranscriptionButton isStartedLiveTranscription={isStartedLiveTranscription} onLiveTranscriptionClick={onLiveTranscriptionClick} />
            <TranscriptionSubtitle text={caption.text} />
          </>
        )} */}

        {/* <InviteButton onCopyInvite={onCopyInvite} /> */}

        {/* <LeaveButton onLeaveClick={onLeaveClick} isHost={zoomClient?.isHost()} onEndClick={onEndClick} /> */}
        <LeaveButton onLeaveClick={onLeaveButtonClick} isHost={zoomClient?.isHost()} onEndClick={onLeaveButtonClick} />

        <AudioVideoStatisticModal
          visible={statisticVisible}
          setVisible={setStatisticVisible}
          defaultTab={selecetedStatisticTab}
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          isStartedVideo={isStartedVideo}
        />

        {/* {recordingIsoStatus === RecordingStatus.Ask && (
          <IsoRecordingModal
            onClick={() => {
              recordingClient?.acceptIndividualRecording();
            }}
            onCancel={() => {
              recordingClient?.declineIndividualRecording();
            }}
          />
        )} */}
      </div>
    </React.Fragment>
  );
};

export default VideoFooter;
