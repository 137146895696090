export enum treatmentGoalStatusCode {
    A = "A",
    O = "O",
    R = "R",
    D = "D"
}

export interface DiagnosisModel {
    description?: DescriptionType[];
}

export interface DescriptionType{
  value?: string;
  label?: string;
}

export interface ResponsiblePartiesModel {
    clientName?: string;
    therapistNameCredentials?: string;
}

export interface GoalInformationModel {
  goalObjective?: string;
  progressIdentifier?: string;
  timeframe?: string;
  serviceInterventionFrequency?: string;
  responsibleParties?: ResponsiblePartiesModel;
}

export interface TreatmentSessionModel {
    targetDate?: string;
    reviewDate?: string;
    statusCode?: treatmentGoalStatusCode;
    notes?: string;
}

export interface SignatureDetailsModel {
    name?: string;
    date?: string;
    relationship?: string;
}