import React, { useEffect, useContext, useState } from "react";
import { Modal, Button, ModalBody, Collapse } from "reactstrap";
import Close from "../../assets/images/icons/close.png";
import moment from "moment";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import AiIcon from "../../assets/images/icons/AI_animation_logo.gif";
import UserContext from "src/context/UserContext";

const Modal32: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void; clinicalAssessmentInfo: any; openClinicalAssessment: () => void; downloadClinicalAssessment: (planId: string, tpType: TPType) => void; isMobile: boolean; isAdmin: boolean; generateAiAssessmentFunction: () => void; firstMeetingData: any, isMeeting: boolean }> = props => {
  const { clinicalAssessmentInfo, openClinicalAssessment, downloadClinicalAssessment, isAdmin, generateAiAssessmentFunction, firstMeetingData, isMeeting } = props;

  const [expandedPlans, setExpandedPlans] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [loggedUser] = useContext(UserContext);
  
  const formatDate = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };

  const toggleVersions = (planId: string) => {
    setExpandedPlans(prev => ({
      ...prev,
      [planId]: !prev[planId]
    }));
  };

  const handleAIGenerateClick = () => {
    setIsLoading(true);

    generateAiAssessmentFunction();
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered className={clinicalAssessmentInfo.length === 0 ? "modal-lg" : "modal-md"}>
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={() => props.setShowModal(false)}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>
        <ModalBody className="p-4">
          <h2 className="text-center mb-4">Clinical Assessment With Amendments</h2>

          {(!isAdmin && clinicalAssessmentInfo.length === 0) && (
            <div className="d-flex justify-content-center mb-4">
              <Button color="primary" className="py-2 shadow-sm s-h-btn" onClick={
                openClinicalAssessment}>
                <i className="fa fa-plus mr-2"></i> Create Clinical Assessment
              </Button>
              {/* This part modified for show and generate AI assessment for marcus king therapist. */}
              {/* loggedUser?._id == "62f17eb1b81f31a68505d9ad" - This line added for the above reason. */}
              {((firstMeetingData &&
              firstMeetingData?.clientAllowedTranscribe &&
              firstMeetingData?.therapistAllowedTranscribe &&
              firstMeetingData?.firstSpeaker &&
              firstMeetingData?.transcribeCreated && !isMeeting) ||
              (loggedUser?._id == "62f17eb1b81f31a68505d9ad" && !isMeeting)) && (
                <Button
                  color="secondary"
                  className="ml-3 btn-black1 convert-pending-note s-h-btn"
                  onClick={handleAIGenerateClick}
                  disabled={isLoading}
                >
                  <img src={AiIcon} className="ai-icon-size me-1" />
                  Generate Clinical Assessment With AI
                </Button>
              )}
            </div>
          )}

          {(props.isMobile && clinicalAssessmentInfo.length > 0) ? (<span className="text-muted small"><mark>Note: To view or download the amendment, please use a laptop or desktop computer.</mark></span>) : (<></>)}

          {clinicalAssessmentInfo.length > 0 ? (
            clinicalAssessmentInfo.map((plan: any) => (
              <div key={plan?._id} className="therapy-plan-item mb-4 bg-light rounded p-3 shadow-sm">
                <h5 className="font-weight-bold text-dark">Clinical Assessment</h5>
                <p className="text-muted small mb-3">Created date: {formatDate(plan?.createdAt)}</p>

                <div className="therapy-plan-buttons mb-3">
                  {!isAdmin && (
                    <Button color="success" onClick={openClinicalAssessment} className="mr-2 btn-sm shadow-sm">
                      <i className="fa fa-file mr-1"></i> Open / Edit
                    </Button>
                  )}
                  {(!props.isMobile) && (
                  <Button color="info" onClick={() => downloadClinicalAssessment(plan?._id, TPType.ORIGINAL)} className="btn-sm shadow-sm">
                    <i className="fa fa-download mr-1"></i> Download
                  </Button>
                  )}

                  {firstMeetingData &&
                   firstMeetingData?.clientAllowedTranscribe &&
                   firstMeetingData?.therapistAllowedTranscribe &&
                   firstMeetingData?.firstSpeaker &&
                   firstMeetingData?.transcribeCreated &&
                   !isAdmin &&
                   plan?.versions && plan?.versions.length < 1 && (
                     <Button
                       color="secondary"
                       className="ml-3 btn-black1 convert-pending-note btn-sm"
                       onClick={handleAIGenerateClick}
                       disabled={isLoading}
                      >
                         <img src={AiIcon} className="ai-icon-size me-1" />
                           Generate With AI
                      </Button>
                  )}
                </div>

                {plan?.versions && plan?.versions.length > 0 ? (
                  <div className="therapy-plan-versions mt-3">
                    <Button
                      color="link"
                      onClick={() => toggleVersions(plan?._id)}
                      className="p-0 text-decoration-none"
                    >
                      <h6 className="font-weight-bold mb-0">
                        {expandedPlans[plan?._id] ? 'Hide' : 'View'} amendments {' '}
                        <i className={`fa fa-chevron-${expandedPlans[plan?._id] ? 'up' : 'down'}`} />
                      </h6>
                    </Button>
                    <Collapse isOpen={expandedPlans[plan?._id]}>
                      <ul className="list-unstyled mt-2">
                        {plan?.versions.map((version: any, versionIndex: number) => (
                          <li key={version?._id} className="mb-2 bg-white p-2 rounded shadow-sm">
                            <span className="font-weight-medium">Amendment {versionIndex + 1}</span><br />
                            <span className="text-muted small">Created date: <b>{formatDate(version?.versionCreatedAt)}</b></span><br />
                            <span className="text-muted small">Amendment modified due to: <b>{version?.reasonForEdit}</b></span><br />
                            {(!props.isMobile) && (
                              <Button
                                color="secondary"
                                size="sm"
                                onClick={() => downloadClinicalAssessment(version?._id, TPType.VERSION)}
                                className="mt-2 shadow-sm"
                              >
                                View / Download
                              </Button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </div>
                ) : (
                  <p className="text-muted font-italic">No amendments available.</p>
                )}
              </div>
            ))
          ) : (
            <p className="text-center text-muted font-italic">No clinical assessments available.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  ) : null;
};

export default Modal32;
