import React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import FolderListComponent from "./FolderListComponent";
import FolderComponent from "./FolderComponent";
import TrainingDocuments from "./TrainingDocuments";
const TherapistDocuments = () => {
  const { path } = useRouteMatch();
  return (
    <div>
      {/* <TrainingDocuments /> */}
      <Switch>
        <Route exact path={`${path}`} component={TrainingDocuments} />
        <Route exact path={`${path}/folder/:folderId`} component={FolderComponent} />
        {/* <Route exact path={`${path}/file/:fileId`} component={FileComponent} /> */}
      </Switch>
    </div>
  );
};

export default TherapistDocuments;
