import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { ImpairmentLevel, SeverityScore, mortivationEngageInServices, recoveryEnvironment } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

const FormEight = (props: any) => {
    const { formId, assesmenData, getAssesmentByClientId, customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] },
        summaryofNeeds: {
            financial: false,
            housing: false,
            spiritual: false,
            sexuality: false,
            safetyCrisis: false,
            transportation: false,
            employment: false,
            medicalHealth: false,
            substanceUse: false,
            parentingSkills: false,
            physicalNeeds: false,
            adaptive: false,
            educationalVocational: false,
            emotionalPsychological: false,
            legalCourtInvolvement: false,
            familyAttachment: false,
            culturalImmigration: false,
            socialSkillsRecreational: false,
            communicationLanguage: false,
            behaviorManagement: false,
            problemSolvingSkills: false,
            developmentalHistory: false,
        },
    })

    const [edit, setEdit] = useState<boolean>(true);

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                stepCount: 8,
                _id: formId,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                mentalStatusExam: assesmenData.mentalStatusExam,
                suicideRiskPotential: assesmenData.suicideRiskPotential,
                summaryofNeeds: assesmenData.summaryofNeeds,
                recoveryHistoryandEnvironment: assesmenData.recoveryHistoryandEnvironment,
                mortivationEngageInServices: assesmenData.mortivationEngageInServices,
                currentImpairments: assesmenData.currentImpairments,
                psychiatricSymptom: assesmenData.psychiatricSymptom,
            }
            setFormData(updateAssesment)
        }
    }, [assesmenData, customActiveTab])

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            stepCount: 8,
            _id: formId,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            mentalStatusExam: formData.mentalStatusExam,
            suicideRiskPotential: formData.suicideRiskPotential,
            summaryofNeeds: formData.summaryofNeeds,
            recoveryHistoryandEnvironment: formData.recoveryHistoryandEnvironment,
            mortivationEngageInServices: formData.mortivationEngageInServices,
            currentImpairments: formData.currentImpairments,
            psychiatricSymptom: formData.psychiatricSymptom,
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });

                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight  m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Summary of Needs</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.financial}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                summaryofNeeds: {
                                                    ...prevState.summaryofNeeds,
                                                    financial: !formData.summaryofNeeds?.financial
                                                },
                                            }));
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Financial</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.employment}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                summaryofNeeds: {
                                                    ...prevState.summaryofNeeds,
                                                    employment: !formData.summaryofNeeds?.employment
                                                },
                                            }));
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Employment</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.educationalVocational}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                educationalVocational: !formData.summaryofNeeds?.educationalVocational
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Educational/ Vocational</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.socialSkillsRecreational}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                socialSkillsRecreational: !formData.summaryofNeeds?.socialSkillsRecreational
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Social skills/ Recreational</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.housing}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                housing: !formData.summaryofNeeds?.housing
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Housing</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.medicalHealth}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                medicalHealth: !formData.summaryofNeeds?.medicalHealth
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medical/ Health</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.emotionalPsychological}
                                        onClick={e => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                emotionalPsychological: !formData.summaryofNeeds?.emotionalPsychological
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Emotional/ Psychological</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.communicationLanguage}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                communicationLanguage: !formData.summaryofNeeds?.communicationLanguage
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Communication/ Language</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.spiritual}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                spiritual: !formData.summaryofNeeds?.spiritual
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Spiritual</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.substanceUse}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                substanceUse: !formData.summaryofNeeds?.substanceUse
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Substance use</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.legalCourtInvolvement}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                legalCourtInvolvement: !formData.summaryofNeeds?.legalCourtInvolvement
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Legal/ Court involvement</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.behaviorManagement}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                behaviorManagement: !formData.summaryofNeeds?.behaviorManagement
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Behavior management</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.sexuality}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                sexuality: !formData.summaryofNeeds?.sexuality
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Sexuality</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.parentingSkills}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                parentingSkills: !formData.summaryofNeeds?.parentingSkills
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Parenting skills</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.familyAttachment}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                familyAttachment: !formData.summaryofNeeds?.familyAttachment
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Family/ Attachment</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.problemSolvingSkills}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                problemSolvingSkills: !formData.summaryofNeeds?.problemSolvingSkills
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Problem solving skills</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.safetyCrisis}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                safetyCrisis: !formData.summaryofNeeds?.safetyCrisis
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Safety/ Crisis</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.physicalNeeds}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                physicalNeeds: !formData.summaryofNeeds?.physicalNeeds
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Physical needs</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.culturalImmigration}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                culturalImmigration: !formData.summaryofNeeds?.culturalImmigration
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Cultural/ Immigration</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.developmentalHistory}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                developmentalHistory: !formData.summaryofNeeds?.developmentalHistory
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Developmental history</label>
                                </Col>
                                <Col xl={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.transportation}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                transportation: !formData.summaryofNeeds?.transportation
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Transportation</label>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col-auto pr-0">
                                    <input style={{ fontSize: "larger"}}
                                        type="checkbox"
                                        className="form-check-input"
                                        name="saftyPlanNotNeeded"
                                        checked={formData.summaryofNeeds?.adaptive}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                adaptive: !formData.summaryofNeeds?.adaptive
                                            }
                                        }))}
                                    />
                                </div>
                                <div className="col-10 p-0">
                                    <label className="fw-500" style={{ margin: "0.2rem" }}>Adaptive (daily living skills, self-care skills, home living skills)</label>
                                </div>
                            </Row>
                            {/* <Row>
                                <Col xl={9} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.summaryofNeeds?.adaptive}
                                        onClick={() => setFormData(prevState => ({
                                            ...prevState,
                                            summaryofNeeds: {
                                                ...prevState.summaryofNeeds,
                                                adaptive: !formData.summaryofNeeds?.adaptive
                                            }
                                        }))}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Adaptive (daily living skills, self-care skills, home living skills)</label>
                                </Col>
                            </Row> */}
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Recovery History and Environment (mental health/substance abuse)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="row">
                                <div className="col-md-auto col-xs-12">
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Check one - </h6>
                                </div>

                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousMentalHealthTreatment"
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousMentalHealthTreatment === false}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, isPreviousMentalHealthTreatment: false
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No previous mental health treatment</label>
                                </div>
                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousMentalHealthTreatment"
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousMentalHealthTreatment === true}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, isPreviousMentalHealthTreatment: true
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>previous mental health treatment</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-auto col-xs-12">
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Check one - </h6>
                                </div>

                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousSubstanceAbuseTreatment"
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousSubstanceAbuseTreatment === false}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, isPreviousSubstanceAbuseTreatment: false
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No previous substance abuse treatment</label>
                                </div>
                                <div className="col-xl-5 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="isPreviousSubstanceAbuseTreatment"
                                        checked={formData.recoveryHistoryandEnvironment?.isPreviousSubstanceAbuseTreatment === true}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, isPreviousSubstanceAbuseTreatment: true
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>previous substance abuse treatment</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-auto col-xs-12">
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Check one - Recovery Environment :</h6>
                                </div>

                                <div className="col-lg-auto">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="recoveryEnvironment"
                                        checked={formData.recoveryHistoryandEnvironment?.recoveryEnvironment === recoveryEnvironment.POOR}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, recoveryEnvironment: recoveryEnvironment.POOR as recoveryEnvironment
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                </div>
                                <div className="col-lg-auto">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="recoveryEnvironment"
                                        checked={formData.recoveryHistoryandEnvironment?.recoveryEnvironment === recoveryEnvironment.FAIR}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, recoveryEnvironment: recoveryEnvironment.FAIR as recoveryEnvironment
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                </div>
                                <div className="col-lg-auto">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="recoveryEnvironment"
                                        checked={formData.recoveryHistoryandEnvironment?.recoveryEnvironment === recoveryEnvironment.SUPPORTIVE}
                                        onClick={() => setFormData({
                                            ...formData, recoveryHistoryandEnvironment: {
                                                ...formData.recoveryHistoryandEnvironment, recoveryEnvironment: recoveryEnvironment.SUPPORTIVE as recoveryEnvironment
                                            }
                                        })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Supportive</label>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>
                                Motivation to Engage in Services
                                <span style={{ fontSize: "0.8em" }}>
                                    (Indicate High, Medium, Low)
                                </span>
                            </h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={2} md={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="mortivationEngageInServices"
                                        checked={formData.mortivationEngageInServices === mortivationEngageInServices.LOW}
                                        onClick={() => setFormData({ ...formData, mortivationEngageInServices: mortivationEngageInServices.LOW as mortivationEngageInServices })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low</label>
                                </Col>
                                <Col lg={2} md={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="mortivationEngageInServices"
                                        checked={formData.mortivationEngageInServices === mortivationEngageInServices.HIGH}
                                        onClick={() => setFormData({ ...formData, mortivationEngageInServices: mortivationEngageInServices.HIGH as mortivationEngageInServices })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High</label>
                                </Col>
                                <Col lg={3} md={4} className="d-flex">
                                    <input style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        name="mortivationEngageInServices"
                                        checked={formData.mortivationEngageInServices === mortivationEngageInServices.MEDIUM}
                                        onClick={() => setFormData({ ...formData, mortivationEngageInServices: mortivationEngageInServices.MEDIUM as mortivationEngageInServices })}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium</label>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Current Impairments</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <h6 className="fw-500 m1-2">Scale:  0=none  1=mild  2=moderate  3=severe</h6>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Mood Disturbance (depression or mania)</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, moodDisturbance: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, moodDisturbance: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, moodDisturbance: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, moodDisturbance: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="moodDisturbance"
                                                checked={formData.currentImpairments?.moodDisturbance === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, moodDisturbance: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Mood Disturbance (depression or mania)</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Anxiety</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, anxiety: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, anxiety: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, anxiety: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, anxiety: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="anxiety"
                                                checked={formData.currentImpairments?.anxiety === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, anxiety: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Anxiety</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Psychosis</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, psychosis: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, psychosis: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, psychosis: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, psychosis: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="psychosis"
                                                checked={formData.currentImpairments?.psychosis === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, psychosis: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Psychosis</label>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Thinking/ Cognition/ Memory</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, thinkingCognitionMemory: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, thinkingCognitionMemory: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, thinkingCognitionMemory: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, thinkingCognitionMemory: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="thinkingCognitionMemory"
                                                checked={formData.currentImpairments?.thinkingCognitionMemory === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, thinkingCognitionMemory: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Thinking/ Cognition/ Memory</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col xl={7} className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Impulsive/ Reckless/ Aggressive</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, impulsiveRecklessAggressive: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, impulsiveRecklessAggressive: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, impulsiveRecklessAggressive: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, impulsiveRecklessAggressive: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="impulsiveRecklessAggressive"
                                                checked={formData.currentImpairments?.impulsiveRecklessAggressive === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, impulsiveRecklessAggressive: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Impulsive/ Reckless/ Aggressive</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Activities of Daily Living</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, activitiesOfDailyLiving: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, activitiesOfDailyLiving: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, activitiesOfDailyLiving: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, activitiesOfDailyLiving: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="activitiesOfDailyLiving"
                                                checked={formData.currentImpairments?.activitiesOfDailyLiving === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, activitiesOfDailyLiving: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Activities of Daily Living</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none">
                                    <div className="row">
                                        <div className="col-lg-auto col-sm-12">
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Weight Loss Assoc. w/Eating D/O-</h6>
                                        </div>
                                        <div className="col-lg-auto col-sm-4 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Gain</label>
                                        </div>
                                        <div className="col-lg-auto col-sm-8 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loss of Text pounds in last 3 months.</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, weightLossAssocWithEatingDO: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, weightLossAssocWithEatingDO: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, weightLossAssocWithEatingDO: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, weightLossAssocWithEatingDO: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="weightLossAssocWithEatingDO"
                                                checked={formData.currentImpairments?.weightLossAssocWithEatingDO === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, weightLossAssocWithEatingDO: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block">
                                    <div className="row">
                                        <div className="col-md-auto col-xs-12 p-0">
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Weight Loss Assoc. w/Eating D/O-</h6>
                                        </div>
                                        <div className="col-xl-auto p-0 d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Gain</label>
                                        </div>
                                        <div className="col-xl-auto p-0 d-flex">
                                            <input style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loss of Text pounds in last 3 months.</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Medical/ Physical Condition(s)</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, medicalPhysicalConditions: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, medicalPhysicalConditions: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, medicalPhysicalConditions: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, medicalPhysicalConditions: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medicalPhysicalConditions"
                                                checked={formData.currentImpairments?.medicalPhysicalConditions === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, medicalPhysicalConditions: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Medical/ Physical Condition(s)</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col xl={7} className="col-xl d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Substance Abuse/ Dependence</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, substanceAbuseDependence: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, substanceAbuseDependence: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, substanceAbuseDependence: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, substanceAbuseDependence: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuseDependence"
                                                checked={formData.currentImpairments?.substanceAbuseDependence === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, substanceAbuseDependence: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Substance Abuse/ Dependence</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>School (Job) Performance</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, schoolJobPerformance: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, schoolJobPerformance: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, schoolJobPerformance: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, schoolJobPerformance: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="schoolJobPerformance"
                                                checked={formData.currentImpairments?.schoolJobPerformance === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, schoolJobPerformance: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-lg-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>School (Job) Performance</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Social/ Marital/ Family Problems</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, socialMaritalFamilyProblems: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, socialMaritalFamilyProblems: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, socialMaritalFamilyProblems: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, socialMaritalFamilyProblems: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="socialMaritalFamilyProblems"
                                                checked={formData.currentImpairments?.socialMaritalFamilyProblems === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, socialMaritalFamilyProblems: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Social/ Marital/ Family Problems</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* Label for mobile view */}
                                <Col className="col-12 d-xl-none d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Legal</label>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.NONE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, legal: ImpairmentLevel.NONE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>0</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.MILD}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, legal: ImpairmentLevel.MILD as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>1</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.MODERATE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, legal: ImpairmentLevel.MODERATE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>2</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.SEVERE}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, legal: ImpairmentLevel.SEVERE as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>3</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legal"
                                                checked={formData.currentImpairments?.legal === ImpairmentLevel.NA}
                                                onClick={() => setFormData({ ...formData, currentImpairments: { ...formData.currentImpairments, legal: ImpairmentLevel.NA as ImpairmentLevel } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>N/A</label>
                                        </div>
                                    </Row>
                                </Col>
                                {/* Label for desktop view */}
                                <Col xl={6} className="d-none d-xl-block p-0 d-flex">
                                    <div className="col-auto">
                                        <label className="fw-500" style={{ margin: "0.2rem" }}>Legal</label>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Psychiatric Symptom Assessment Scale (PSAS-B)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left", minWidth: "930px", verticalAlign: "middle" }}>
                                    <thead>
                                        <tr>
                                            <td className="fw-600" style={{ width: "5%", paddingLeft: "0.2rem" }}></td>
                                            <td className="fw-600" style={{ width: "30%", paddingLeft: "0.2rem" }}>Statement (S) / Behavior (B)</td>
                                            <td className="fw-600" style={{ width: "55%", paddingLeft: "0.2rem" }}>Mild 1-2 / Moderate 3-4 / Severe 5-6</td>
                                            <td className="fw-600" style={{ width: "10%", paddingLeft: "0.2rem" }}>Score</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                1
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Anxiety statements
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                worried/fearful/panicked talk
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.anxiety ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    anxiety: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                2
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Tension (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                seems tense/quite nervous/agitated
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.tension ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    tension: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                3
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Depressive mood (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                blue/depressed/despairing
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.depressiveMood ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    depressiveMood: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                4
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Helplessness/hopelessness (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                doubtful/gloomy/sure of failure
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.helplessnessHopelessness ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    helplessnessHopelessness: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                5
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Guilt feelings (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                regret/remorse/delusional guilt
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.guiltFeelings ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    guiltFeelings: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                6
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Somatic concern (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                present/preoccupied with/delusional
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"

                                                        value={formData.psychiatricSymptom?.somaticConcern ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    somaticConcern: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                7
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Hostility (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                grumpy/angry/assaultive
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.hostility ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    hostility: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                8
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Suspiciousness (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                guarded/mistrustful/paranoid delusions
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.suspiciousness ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    suspiciousness: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                9
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Uncooperativeness (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                gripes/resists/refuses
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.uncooperativeness ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    uncooperativeness: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                10
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Distractibility (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                trouble focusing/excess responses to environment/activity not pursed more than moment
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.distractibility ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    distractibility: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                11
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Elated mood (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                unaccountably happy/seems high/euphoric
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.elatedMood ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    elatedMood: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                12
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Motor hyperactivity (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                energetic/pressure/frenetic
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.motorHyperactivity ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    motorHyperactivity: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                13
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Disorientation (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                bewildered/confused/disoriented
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.disorientation ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    disorientation: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                14
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Disorganized speech (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                rambling/loose/fragmented
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.disorganizedSpeech ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    disorganizedSpeech: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                15
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Grandiose statements
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                vague/specific/delusional
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.grandioseStatements ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    grandioseStatements: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                16
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Unusual ideas (S)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                odd/bizarre/delusional
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.unusualIdeas ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    unusualIdeas: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                17
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Hallucinatory statements
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                acknowledges/describes/involved in
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.hallucinatoryStatements ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    hallucinatoryStatements: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                18
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Hallucinatory behavior
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                subtle/clear evidence/acts upon
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.hallucinatoryBehavior ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    hallucinatoryBehavior: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                19
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Social withdrawal (S&B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                distant/avoids/no contact
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.socialWithdrawal ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    socialWithdrawal: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                20
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Blunted affect (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                decreased/consistently reduced/toneless
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.bluntedAffect ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    bluntedAffect: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                21
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Motor retardation (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                sluggish/necessary movement only/catatonic
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.motorRetardation ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    motorRetardation: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                22
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Mannerisms and posturing (B)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                odd/bizarre/dominates behavior
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.mannerismsPosturing ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    mannerismsPosturing: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-500" style={{ textAlign: "center" }}>
                                                23
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                Loss of function (global item)
                                            </td>
                                            <td className="fw-500" style={{ paddingLeft: "0.2rem" }}>
                                                needs minimal supervision/requires some supervision/needs assistance or protection of hospital
                                            </td>
                                            <td className="fw-500">
                                                <div className="col-auto">
                                                    <select
                                                        className="submissionForm"
                                                        id="4"
                                                        value={formData.psychiatricSymptom?.lossOfFunction ?? SeverityScore.NotPresent}
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                psychiatricSymptom: {
                                                                    ...prevState.psychiatricSymptom,
                                                                    lossOfFunction: e.target.value as SeverityScore
                                                                }
                                                            }))
                                                        }
                                                    >
                                                        <option value={SeverityScore.NotPresent}> 0 </option>
                                                        <option value={SeverityScore.VeryMild}> 1 </option>
                                                        <option value={SeverityScore.Mild}> 2 </option>
                                                        <option value={SeverityScore.Moderate}> 3 </option>
                                                        <option value={SeverityScore.ModeratelySevere}> 4 </option>
                                                        <option value={SeverityScore.Severe}> 5 </option>
                                                        <option value={SeverityScore.ExtremelySevere}> 6  </option>
                                                        <option value={SeverityScore.NR}> NR </option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>

                        < button
                            type="button"
                            className="btn btn-primary mr-2 relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={() => {
                                updateAssesmentDetails();
                            }}
                        >
                            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                        </button>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 8 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card >
        </>
    );
};

export default FormEight;