import React, { useState, useContext, useEffect, useCallback, useReducer } from "react";
import _ from "lodash";
import { Prompt, RouteComponentProps, useHistory, useParams } from "react-router-dom";
import ZoomVideo, { ConnectionState, ReconnectReason } from "@zoom/videosdk";
import MediaContext from "./context/media-context";
import { Button, Input, Modal, message } from "antd";
import { isAndroidBrowser } from "./utils/platform";
import SupportGalleryViewContext from "./context/support-gallery-view-context";
import VideoContainer1 from "./feature/video/video";
import VideoContainer2 from "./feature/video/video-non-sab";
import VideoContainer3 from "./feature/video/video-single";
import { ParameterTypes } from "./utils/ParameterTypes";
import LoadingLayer from "./component/loading-layer";
import { TranslationOutlined } from "@ant-design/icons";
import { generateMeetingArgs } from "./utils/util";
import LiveTranscriptionContext from "./context/live-transcription";
import RecordingContext from "./context/recording-context";
import SessionContext from "./context/session-context";
import UserContext from "src/context/UserContext";
import LocalCallContext from "src/context/LocalCallContext";
import CallInitializationContext from "src/context/CallInitializationContext";
import CallEndContext from "src/context/CallEndContext";
import LocalCallTimeOutContext from "src/context/LocalCallTimeOutlContext";
import { VideoChatService } from "src/services/VideoChatService";
import { toast } from "react-toastify";
import { LocalCall } from "src/models/LocalCall";
import { CallInitializeData } from "src/models/CallInitializeData";
import { TimeOut } from "src/models/TimeOut";
import { Role } from "src/models/Role";
import { StartCallSocketData } from "src/models/startCallSocketData";
import { AppResponse } from "src/models/Response";
import { SocketContext } from "src/context/ScoketContext";
import ZoomContext from "../VideoCallNew/context/ZoomContext";
import Modal18 from "src/pages/Popup/Modal18";
import Modal4 from "src/pages/Popup/Modal4";
import Modal5 from "src/pages/Popup/Modal5";
import Modal7 from "src/pages/Popup/Modal7";
import Modal9 from "src/pages/Popup/Modal9";
import ShowPopupSheduleContext from "src/context/ShowSchedulePopupContext";

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
  }
}

interface ZoomWebClientProps {
  dispatch: React.Dispatch<any>;
}

const ZoomWebClient: React.FC<ZoomWebClientProps> = (props: any) => {
  const history = useHistory();

  let start: boolean;
  let isAppointmentBased: boolean;
  let appointmentId: string;
  let recieverId: string;
  let meetingTime: number;
  const sessionIdleTimeoutMins = 15;

  const [loggedUser] = useContext(UserContext);

  const socket = useContext(SocketContext);

  // const { zoomClient, setZoomClient } = useContext(ZoomContext);
  const zoomClient = useContext(ZoomContext);
  const { setLiveTranscriptionClient } = useContext(LiveTranscriptionContext);
  const { mediaStream, setMediaStream } = useContext(MediaContext);
  const { recordingClient, setRecordingClient } = useContext(RecordingContext);

  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);
  const [endCallValue] = useContext(CallEndContext);

  const [showModalForNavigationDetect, setShowModalForNavigationDetect] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const [isConnecting, setIsConnecting] = useState(true);
  const [loadingText, setLoadingText] = useState("Joining");
  const [errorHappened, setErrorHappened] = useState(true);
  const [navigateToChat, setNavigateToChat] = useState(false);
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("closed");
  const [meetingIdFromResponse, setMeetingIdFromResponse] = useState<string>("");
  const [signatureFromResponse, setSignatureFromResponse] = useState<string>("");
  const [passwordFromResponse, setPasswordFromResponse] = useState<string>("");
  const [nameFromResponse, setNameFromResponse] = useState<string>("");
  const [startAudioCalled, setStartAudioCalled] = useState(false);

  const { dispatch } = props;
  const { meetingId, meetingPassword } = useParams<ParameterTypes>();
  const { setSessionName, setSessionPassword } = useContext(SessionContext);
  const { isSupportGalleryView, setIsSupportGalleryView } = useContext(SupportGalleryViewContext);
  // const [showMeeting, setShowMeeting] = useState(false);
  // const [loading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [galleryViewWithoutSAB, setGalleryViewWithoutSAB] = useState<any>();
  const [
    showSchedulePopup,
    setShowSchedulePopup,
    therapistIdForSchedulePopup,
    setTherapistIdForSchedulePopup,
    clientIdForSchedulePopup,
    setClientIdForSchedulePopup,
    showScheduleInTherapistChat,
    setShowScheduleInTherapistChat,
  ] = useContext(ShowPopupSheduleContext);

  let webEndpoint: any;

  if (!webEndpoint) {
    webEndpoint = window?.webEndpoint ?? "zoom.us";
  }

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };
  // useEffect(() => {
  //   if (!zoomClient) {
  //     const zmClient = ZoomVideo.createClient();
  //     setZoomClient(zmClient);
  //   }
  // }, [zoomClient]);

  useEffect(() => {
    initFunction();

    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);

    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
      ZoomVideo.destroyClient();
    };
  }, []);

  useEffect(() => {
    if (isOnline && status == "connecting" && isFailover) {
      reJoinTosession();
    }
  }, [isOnline]);

  const [isInitial, setIsInitial] = useState(true);
  const [callTimeOut, setCallTimeOut, setShowPopUp] = useContext(LocalCallTimeOutContext);
  const [showExtendTime, setShowExtendTime] = useState(false);

  useEffect(() => {
    if (!callTimeOut.isTimeOutRunning && !isInitial) {
      leaveMeeting();
    }

    if (callTimeOut.isTimeOutRunning && callTimeOut.showExtendPopup && loggedUser?.role == Role.THERAPIST && !isInitial) {
      setShowExtendTime(true);
    }
  }, [callTimeOut]);

  const extendMeeting = (time: number, showModal: boolean, extend: boolean) => {
    setShowExtendTime(showModal);

    if (extend) {
      if (callTimeOut.isTimeOutRunning && callTimeOut.showExtendPopup && loggedUser?.role == Role.THERAPIST) {
        setShowPopUp(time);
      }
    }
  };

  useEffect(() => {
    if (localUserOnCall.onOngoingCall && endCallValue && !isInitial) {
      setTherapistIdForSchedulePopup(localUserOnCall.meetingMainTherapistId);
      setClientIdForSchedulePopup(loggedUser?.role == "THERAPIST" ? localUserOnCall.recieversUserId : loggedUser?._id ?? "");
      resetContextData();
      closeAllPopupsAndGoBack(false);
      setShowSchedulePopup(true);
    }
  }, [endCallValue]);

  const leaveMeeting = () => {
    try {
      VideoChatService.cancellCall(meetingIdFromResponse, localCallInitializeData.isAppointmentBased, localCallInitializeData.appointmentId);
      setClientIdForSchedulePopup(loggedUser?.role == "THERAPIST" ? localUserOnCall.recieversUserId : loggedUser?._id ?? "");
      setTherapistIdForSchedulePopup(localUserOnCall.meetingMainTherapistId);
      resetContextData();
      closeAllPopupsAndGoBack(false);
      setShowSchedulePopup(true);
    } catch (error) {
      console.log(error);
    }
  };

  const reJoinTosession = async () => {
    try {
      await zoomClient?.join(meetingIdFromResponse, signatureFromResponse, nameFromResponse, passwordFromResponse, sessionIdleTimeoutMins);
    } catch (error) {
      console.log(error);
    }
  };

  const onConnectionChange = useCallback(
    (payload: any) => {
      try {
        if (payload.state === ConnectionState.Fail) {
          const { reason, subsessionName } = payload;
          if (reason === "connection error") {
            setLoadingText("Network connection lost, Please reload to connect!");
          }
        }

        if (payload.state === ConnectionState.Reconnecting) {
          setIsConnecting(true);

          setIsFailover(true);

          setStatus("connecting");

          const { reason, subsessionName } = payload;
          if (reason === ReconnectReason.Failover) {
            setLoadingText("Network connection lost, Please reload to connect!");
          } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
            setLoadingText(`Joining ${subsessionName}...`);
          } else if (reason === ReconnectReason.BackToMainSession) {
            setLoadingText("Returning to Main Session...");
          }
        } else if (payload.state === ConnectionState.Connected) {
          setStatus("connected");
          if (isFailover) {
            setIsConnecting(false);
            setIsFailover(false);
          }
          window.zmClient = zoomClient;
          window.mediaStream = zoomClient?.getMediaStream();
        } else if (payload.state === ConnectionState.Closed) {
          setStatus("closed");
          dispatch({ type: "reset-media" });
          if (payload.reason === "ended by host") {
            toast.error("This meeting has been ended by host", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
          resetContextData();
          closeAllPopupsAndGoBack(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [isFailover, zoomClient]
  );

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;

    dispatch({ type: `${type}-${action}`, payload: result === "success" });
  }, []);

  const onDialoutChange = useCallback((payload: any) => {
    console.log("onDialoutChange", payload);
  }, []);

  const onAudioMerged = useCallback((payload: any) => {
    console.log("onAudioMerged", payload);
  }, []);

  useEffect(() => {
    zoomClient?.on("connection-change", onConnectionChange);
    zoomClient?.on("media-sdk-change", onMediaSDKChange);
    zoomClient?.on("dialout-state-change", onDialoutChange);
    zoomClient?.on("merged-audio", onAudioMerged);

    return () => {
      zoomClient?.off("connection-change", onConnectionChange);
      zoomClient?.off("media-sdk-change", onMediaSDKChange);
      zoomClient?.off("dialout-state-change", onDialoutChange);
      zoomClient?.off("merged-audio", onAudioMerged);
    };
  }, [zoomClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

  async function initFunction() {
    try {
      console.log(history.action);
      // if (!history || !history.action || history.action != "PUSH" || !localCallInitializeData) {
      //   history.push("/dashboard");
      // }
      //  else {

      setErrorHappened(false);
      setNavigateToChat(false);

      await zoomClient?.init("en-US", "CDN", {
        // webEndpoint: "zoom.us",
        // webEndpoint,
        // enforceMultipleVideos: meetingArgsNew.galleryViewWithoutSAB,
        stayAwake: true,
      });

      start = localCallInitializeData.start;
      isAppointmentBased = localCallInitializeData.isAppointmentBased;
      appointmentId = localCallInitializeData.appointmentId;
      recieverId = localCallInitializeData.recieverId;
      meetingTime = localCallInitializeData.meetingTime;
      const isAudioCall = localCallInitializeData.isAudioCall;

      if (start) {
        initializeInstantCall(isAudioCall);
      } else {
        joinZoomCall();
      }
      // }
    } catch (error) {
      console.log(error);
      toast.error("Please check your internet connection !", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      resetContextData();

      closeAllPopupsAndGoBack(true);
    }
  }

  function tabNavigationDetect(location: any): boolean {
    setShowModalForNavigationDetect(true);
    return false;
  }

  const resetContextData = () => {
    const timeoutData: TimeOut = {
      isTimeOutRunning: true,
      userOnMeetingScreen: false,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };

    setCallTimeOut(timeoutData);

    const callInitializeDataForReset: CallInitializeData = {
      start: false,
      isInstantMeeting: false,
      isAppointmentBased: false,
      isTranscribeAllowed: false,
      appointmentId: "",
      recieverId: "",
      meetingTime: 30,
      meetingId: "",
      isAudioCall: false
    };

    setLocalCallInitialize(false);
    setLocalCallInitializeData(callInitializeDataForReset);

    const resetDataForCall: LocalCall = {
      callId: "",
      onOngoingCall: false,
      recieversUseDefaultAvatarValue: true,
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: true,
      meetingMainTherapistId: "",
      isVideoCall: false,
      meetingDuration: 30,
      recieversUserId: "",
      recieversName: "",
      recieverCallRecordingAllowed: false,
      recieversSocketId: "",
      callEndedForBothUsers: false,
      videoSdkToken: "",
      isAudioCall: false
    };
    setLocalUserOnCall(false, resetDataForCall, true, false);
  };

  const closeAllPopupsAndGoBack = async (backToDashboard: boolean) => {
    try {
      try {
        const sessionInfo = zoomClient?.getSessionInfo();

        if (sessionInfo && sessionInfo.isInMeeting) {
          // stop recording
          const userInfo = zoomClient?.getCurrentUserInfo();

          try {
            if (userInfo && userInfo.isHost && recordingClient) {
              if (recordingClient?.canStartRecording()) {
                const recordingStatus = recordingClient?.getCloudRecordingStatus();
                if (recordingStatus == "Recording") {
                  console.log("stop cloud recording");
                  await recordingClient?.stopCloudRecording();
                }
              } else {
                console.log("can't stop recording");
              }
            }
          } catch (error) {
            console.log(error);
          }

          if (userInfo && userInfo.isHost) {
            await zoomClient?.leave(true);
          } else if (userInfo && !userInfo.isHost) {
            await zoomClient?.leave();
          }

          return true;
        }
      } catch (error) {
        toast.error("SDK error occured when leaving", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });

        return false;
      }

      // leave if joined
      setShowExtendTime(false);
      setErrorHappened(true);
      setShowModalForTherapist(false);
      setShowModalExceeded(false);
      setShowNotAfriend(false);
      setNavigateToChat(true);

      // history.push("/chat");

      return true;
    } catch (error) {
      toast.error("SDK error occured when leaving", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };

  useEffect(() => {
    if (errorHappened && navigateToChat) {
      history.push("/chat");
    }
  }, [navigateToChat]);

  const handleHttpError = (res: AppResponse<any>, type: string) => {
    resetContextData();

    console.log('Start Call Error Occured C');
    console.log(res);
    console.log(res.error);

    if (res.error && res.errorCode && res.errorCode == 456) {
      setShowModalForTherapist(true);
    } else if (res.error && res.errorCode && res.errorCode == 457) {
      setShowModalExceeded(true);
    } else if (res.error && res.errorCode && res.errorCode == 453) {
      setShowNotAfriend(true);
    } else {
      toast.error(type == "INITIALIZE" ? "Call Initialization Error Occured." : "Start Call Error Occured C", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      closeAllPopupsAndGoBack(false);
    }
  };

  const initializeInstantCall = async (isAudio: boolean) => {
    try {
      const transcribeAllowedForUser = localCallInitializeData?.transcribeAllowedForLoggedUser;
      
      const initializeResponse = await VideoChatService.initializeZoomCall(recieverId, appointmentId, meetingTime, true, isAppointmentBased, isAudio, transcribeAllowedForUser);

      if (initializeResponse.success) {
        const meetingIdFromResponseInitial: string = initializeResponse.data.meetingId;
        const signatureInitial: string = initializeResponse.data.sdkToken;
        const passwordInitial: string = initializeResponse.data.password;

        // const nameInitial: string = loggedUser?.username ? loggedUser?.username : loggedUser?.role ? loggedUser?.role : "User";
        const nameInitial: string = loggedUser
          ? loggedUser.firstname && loggedUser.lastname
            ? loggedUser.firstname.charAt(0).toUpperCase() +
              loggedUser.firstname.slice(1) +
              " " +
              loggedUser.lastname.charAt(0).toUpperCase() +
              loggedUser.lastname.slice(1)
            : loggedUser.username
            ? loggedUser.username.charAt(0).toUpperCase() + loggedUser.username.slice(1)
            : loggedUser.role
            ? loggedUser.role
            : "User"
          : "User";

        if (loggedUser) {
        } else {
        }

        setMeetingIdFromResponse(meetingIdFromResponseInitial);
        setSignatureFromResponse(signatureInitial);
        setPasswordFromResponse(passwordInitial);
        setNameFromResponse(nameInitial);

        if (initializeResponse.data.alreadyStarted == false) {
          await zoomClient?.join(meetingIdFromResponseInitial, signatureInitial, nameInitial, passwordInitial, sessionIdleTimeoutMins);
          const sessionId = zoomClient?.getSessionInfo().sessionId;
          if (sessionId) {
            startInstantCall(sessionId, meetingIdFromResponseInitial, signatureInitial);
          }
        } else {
          toast.error("Call Already Started", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          resetContextData();
          closeAllPopupsAndGoBack(false);
        }
      } else {
        handleHttpError(initializeResponse, "INITIALIZE");
      }
    } catch (error) {
      toast.error("Call Initialization Error Occured.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      resetContextData();
      closeAllPopupsAndGoBack(false);
    }
  };

  const startInstantCall = async (sessionId: string, meetingId: string, signature: string) => {
    try {
      
      const res = await VideoChatService.startZoomMeeting(sessionId, meetingId, recieverId, appointmentId, meetingTime, true, isAppointmentBased);
      
      const userId = recieverId;
      const isVideoCall = true;
      const token = signature;
      if (res.success) {
        const stream = zoomClient?.getMediaStream();
        if (stream) {
          setMediaStream(stream);
          setIsSupportGalleryView(stream.isSupportMultipleVideos() && !isAndroidBrowser());
        }

        const recordingClient = zoomClient?.getRecordingClient();
        const ltClient = zoomClient?.getLiveTranscriptionClient();

        if (recordingClient && ltClient) {
          setRecordingClient(recordingClient);
          setLiveTranscriptionClient(ltClient);
        }

        const dataForCall: LocalCall = {
          callId: meetingId,
          onOngoingCall: true,
          recieversUseDefaultAvatarValue: res.data.recieverData.useDefaultAvatar,
          recieversAvatarId: res.data.recieverData.avatarId,
          recieversAvatarBackgroundId: res.data.recieverData.avatarBackgroundId,
          recieversIncogniteModeOn: res.data.recieverData.incognito,
          meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
          isVideoCall: isVideoCall,
          meetingDuration: meetingTime,
          recieversUserId: userId,
          recieversName: res.data.recieverData.callerName,
          recieverCallRecordingAllowed: res.data.recieverData.callRecordingAllowed,
          recieversSocketId: res.data.recieverData.socketId,
          callEndedForBothUsers: false,
          videoSdkToken: token,
          isAudioCall: res.data.meetingData?.isAudioCall
        };

        setLocalUserOnCall(true, dataForCall, false, true);
        // setOutGoinCallTimeOutFunction();
        setIsConnecting(false);
        setIsInitial(false);

        const data: StartCallSocketData = {
          socketId: res.data.recieverData.socketId,
          senderSocketId: socket.id,
          callId: meetingId,
          myUserId: loggedUser?._id,
          therapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id,
          meetingDuration: meetingTime,
          useDefaultAvatar: res.data.ownData.useDefaultAvatar,
          avatarId: res.data.ownData.avatarId,
          avatarBackgroundId: res.data.ownData.avatarBackgroundId,
          incognito: loggedUser?.incognito,
          callerName: loggedUser?.firstname,
          isVideoCall: isVideoCall,
          recieverRcordingAllowed: loggedUser?.callRecordingAllowed,
          videoSdkToken: token,
          receiverId: userId,
          senderId: loggedUser?._id,
        };

        socket.emit("call-user", data);
      } else {
        handleHttpError(res, "START_CALL");
      }
    } catch (error) {
      console.log('Zoom Call Error A');
      console.log(error);

      toast.error("Start Call Error Occured A", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      resetContextData();
      closeAllPopupsAndGoBack(false);
    }
  };

  // start instant call
  // join call
  const joinZoomCall = async () => {
    try {
      
      const res = await VideoChatService.joinZoomMeeting(
        localCallInitializeData.meetingId,
        localCallInitializeData.isAppointmentBased,
        localCallInitializeData.appointmentId,
        localCallInitializeData.transcribeAllowedForLoggedUser
      );
      
      const isVideoCall = true;

      if (res.success) {
        const remainingTime = res.data.meetingData.remainingMeetingTimeForCurrentMeeting;
        const userId = res.data.recieverData.userId;
        const token: string = res.data.meetingData.sdkToken;
        const sessionIdFromResponse = res.data.meetingData.sessionId;
        const meetingIdFromResponseInitial: string = res.data.meetingData.meetingId;
        const passwordInitial: string = res.data.meetingData.password;
        const isAudio: boolean = res.data.meetingData?.isAudioCall;
        // const nameInitial: string = loggedUser?.username ? loggedUser?.username : loggedUser?.role ? loggedUser?.role : "User";
        const nameInitial: string = loggedUser
          ? loggedUser.firstname && loggedUser.lastname
            ? loggedUser.firstname.charAt(0).toUpperCase() +
              loggedUser.firstname.slice(1) +
              " " +
              loggedUser.lastname.charAt(0).toUpperCase() +
              loggedUser.lastname.slice(1)
            : loggedUser.username
            ? loggedUser.username.charAt(0).toUpperCase() + loggedUser.username.slice(1)
            : loggedUser.role
            ? loggedUser.role
            : "User"
          : "User";

        setMeetingIdFromResponse(meetingIdFromResponseInitial);
        setSignatureFromResponse(token);
        setPasswordFromResponse(passwordInitial);
        setNameFromResponse(nameInitial);

        await zoomClient?.join(meetingIdFromResponseInitial, token, nameInitial, passwordInitial, sessionIdleTimeoutMins);

        const sessionId = zoomClient?.getSessionInfo().sessionId;

        if (sessionIdFromResponse == sessionId) {
          const stream = zoomClient?.getMediaStream();

          if (stream) {
            setMediaStream(stream);
            setIsSupportGalleryView(stream.isSupportMultipleVideos() && !isAndroidBrowser());
          }

          const recordingClient = zoomClient?.getRecordingClient();
          const ltClient = zoomClient?.getLiveTranscriptionClient();

          if (recordingClient && ltClient) {
            setRecordingClient(recordingClient);
            setLiveTranscriptionClient(ltClient);
          }

          const dataForCall: LocalCall = {
            callId: meetingIdFromResponseInitial,
            onOngoingCall: true,
            recieversUseDefaultAvatarValue: res.data.recieverData.useDefaultAvatar,
            recieversAvatarId: res.data.recieverData.avatarId,
            recieversAvatarBackgroundId: res.data.recieverData.avatarBackgroundId,
            recieversIncogniteModeOn: res.data.recieverData.incognito,
            meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
            isVideoCall: isVideoCall,
            meetingDuration: remainingTime,
            recieversUserId: userId,
            recieversName: res.data.recieverData.callerName,
            recieverCallRecordingAllowed: res.data.recieverData.callRecordingAllowed,
            recieversSocketId: res.data.recieverData.socketId,
            callEndedForBothUsers: false,
            videoSdkToken: token,
            isAudioCall: isAudio
          };

          setLocalUserOnCall(true, dataForCall, false, false);
          setIsConnecting(false);
          setIsInitial(false);
        } else {
          VideoChatService.cancellCall(meetingIdFromResponseInitial, localCallInitializeData.isAppointmentBased, localCallInitializeData.appointmentId);
          toast.error("Session was ended automatically !", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          resetContextData();
          closeAllPopupsAndGoBack(false);
        }
      } else {
        handleHttpError(res, "START_CALL");
      }
    } catch (error) {
      console.log('Zoom Call Error B');
      console.log(error);

      toast.error("Start Call Error Occured B", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      resetContextData();
      closeAllPopupsAndGoBack(false);
    }
  };

  return (
    <>
      <Prompt message={tabNavigationDetect} when={!errorHappened} />
      <React.Fragment>
        {showExtendTime && <Modal9 setShowModal={extendMeeting} showModal={showExtendTime} meetingTime={30} />}
        {!showModalForTherapist && showModalForNavigationDetect && (
          <Modal7 setShowModal={setShowModalForNavigationDetect} showModal={showModalForNavigationDetect} />
        )}
        {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
        {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
        {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}
        {/* {isConnecting && <LoadingLayer content="Loading..." />} */}
        {isConnecting && <LoadingLayer content={loadingText} />}
        {!isConnecting && <VideoContainer3 leaveMeeting={leaveMeeting}></VideoContainer3>}
      </React.Fragment>
    </>
  );
};

export default ZoomWebClient;
