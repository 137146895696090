import React from "react";
import { CardType } from "../../models/CardTypes";
import { Button, Card, Col } from "reactstrap";
import visa from "../../assets/images/cardtypes/visa.svg";
import mastercard from "../../assets/images/cardtypes/mastercard.svg";
import amex from "../../assets/images/cardtypes/amex.svg";
import discover from "../../assets/images/cardtypes/discover.svg";
import jcb from "../../assets/images/cardtypes/jcb.svg";
import maestro from "../../assets/images/cardtypes/maestro.svg";
import unionpay from "../../assets/images/cardtypes/unionpay.svg";
import anyCard from "../../assets/images/cardtypes/any_card.png";

const PremiumBankCard: React.FC<{
    paymentData: any;
    defaultPaymentMethodId: string;
    changeDefaultPaymentMethod: (value: any) => void
}> = (props) => {
    return (
        <>
            {
                props?.paymentData.length > 0 ? (
                    <>
                        {props?.paymentData.map((data: any, idx: any) => {
                            return (
                                <Col xxxl="6" key={idx}>
                                    <Card outline color="primary" className="payCard max-w-md mx-auto border rounded-lg overflow-hidden md:max-w-sm shadow border border-slate-300 hover:border-slate-400" >
                                        <div className="md:flex" style={{ display: "flex" }}>
                                            <div className="w-full p-2" style={{ width: "100%" }}>
                                                <div className="flex mt-2" style={{ display: 'flex' }}>
                                                    <div className="flex flex-row mr-2" style={{ display: 'flex' }}>
                                                        <span className="text-2xl  font-bold">*</span>
                                                        <span className=" text-2xl font-bold">*</span>
                                                        <span className=" text-2xl  font-bold">*</span>
                                                        <span className=" text-2xl mr-2 font-bold">*</span>
                                                    </div>

                                                    <div className="flex flex-row mr-2" style={{ display: 'flex' }}>
                                                        <span className="text-2xl  font-bold">*</span>
                                                        <span className=" text-2xl font-bold">*</span>
                                                        <span className=" text-2xl font-bold">*</span>
                                                        <span className=" text-2xl mr-2 font-bold">*</span>
                                                    </div>

                                                    <div className="flex flex-row mr-2" style={{ display: 'flex' }}>
                                                        <span className="text-2xl  font-bold">*</span>
                                                        <span className=" text-2xl font-bold">*</span>
                                                        <span className=" text-2xl  font-bold">*</span>
                                                        <span className=" text-2xl mr-2 font-bold">*</span>
                                                    </div>

                                                    <div className="flex flex-row fontGiloryBold gray-800" style={{ display: 'flex' }}>
                                                        <span className="text_bank font-bold" >{data.card.last4}</span>
                                                    </div>
                                                </div>

                                                <div className="check  mb-2 mt-2">
                                                    <div className="flex items-center" style={{ display: 'flex' }}>
                                                        {
                                                            props?.defaultPaymentMethodId == data.id ? (
                                                                <span className="sub-item1">Default Payment Method</span>
                                                            ) : (
                                                                <Button className="btn btn-default makeDefault"  variant="contained" color="primary" onClick={() => props.changeDefaultPaymentMethod(data.id)}>
                                                                    Make Default
                                                                </Button>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className="mt-2 flex justify-between items-center text-white" style={{ display: 'flex' }}>
                                                    <div className="flex flex-col" style={{ display: 'flex' }}> <span className="font-bold text-gray-300 text-sm"></span> <span className="font-bold"></span> </div>
                                                    <div className="flex flex-col" style={{ display: 'flex' }}>
                                                        <span className="font-bold text-gray-300 text-sm">
                                                            {
                                                                data.card.brand == CardType.VISA ? (
                                                                    <img src={visa} className="credit-card" />
                                                                ) : data.card.brand === CardType.MASTERCARD ? (
                                                                    <img src={mastercard} className="credit-card" />
                                                                ) : data.card.brand === CardType.DISCOVER ? (
                                                                    <img src={discover} className="credit-card" />
                                                                ) : data.card.brand === CardType.AMEX ? (
                                                                    <img src={amex} className="credit-card" />
                                                                ) : data.card.brand === CardType.JCB ? (
                                                                    <img src={jcb} className="credit-card" />
                                                                ) : data.card.brand === CardType.MAESTRO ? (
                                                                    <img src={maestro} className="credit-card" />
                                                                ) : data.card.brand === CardType.UNIONPAY ? (
                                                                    <img src={unionpay} className="credit-card" />
                                                                ) : (
                                                                    <img src={anyCard} className="credit-card" />
                                                                )
                                                            }
                                                        </span>

                                                        <span className="font-bold"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}
                    </>
                ) : (
                    <>
                        <div className="text-center mt10 mb20">
                            No payment methods created yet.
                        </div>
                    </>
                )
            }
        </>
    )
}

export default PremiumBankCard;