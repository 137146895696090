import axios from "axios";
import { Ethinicity } from "../models/Ethnicity";
import { ExperienceTag } from "../models/ExperienceTag";
import { HashTag } from "../models/HashTag";
import { License } from "../models/License";
import { Payment } from "../models/Payment";
import { Profession, ProfessionLicense } from "../models/Profession";
import { Qualification } from "../models/Qualification";
import { AppResponse } from "../models/Response";
import { Upload } from "../models/Upload";
import { PasswordDetails, UserDetails, UserDetails2 } from "../models/User";
import { Util } from "../Util";
import { FeedbackList } from "../models/Feedback";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { TherapyPlan, TherapyPlanSinglePage } from "src/models/therapyPlan/therapyPlan";
import { DigitalForm } from "./../models/AssessmentDigitalForm/AssessmentDigitalForms";

export class CommonService {
  public static async getEthinicityList(): Promise<AppResponse<Ethinicity[]>> {
    const url = Util.apiPublicUrl(`getAllEthnicityTypes`);

    return await axios.get<Partial<Ethinicity[]>, AppResponse<Ethinicity[]>>(url);
  }

  public static async createEthnicity(data: Partial<Ethinicity>): Promise<AppResponse<Ethinicity>> {
    const url = Util.apiAuthUrl("create/ethnicity");

    return await axios.post<Partial<Ethinicity>, AppResponse<Ethinicity>>(url, data);
  }

  public static async deleteEthnicity(id: string): Promise<AppResponse<Ethinicity>> {
    const url = Util.apiAuthUrl("deleteEthnicity/" + id);
    return await axios.delete<Partial<Ethinicity>, AppResponse<Ethinicity>>(url);
  }

  public static async getExperienceTags(limit?: number, offset?: number): Promise<AppResponse<ExperienceTag[]>> {
    const url = Util.apiAuthUrl(`getExperienceTags/${limit}/${offset}`);

    return await axios.get<Partial<ExperienceTag[]>, AppResponse<ExperienceTag[]>>(url);
  }

  public static async createExperienceTag(data: Partial<ExperienceTag>): Promise<AppResponse<ExperienceTag>> {
    const url = Util.apiAuthUrl("create/experienceTag");

    return await axios.post<Partial<ExperienceTag>, AppResponse<ExperienceTag>>(url, data);
  }

  public static async deleteExperienceTag(id: string): Promise<AppResponse<ExperienceTag>> {
    const url = Util.apiAuthUrl("deleteExperienceTag/" + id);
    return await axios.delete<Partial<ExperienceTag>, AppResponse<ExperienceTag>>(url);
  }

  public static async createProfession(data: Partial<Profession>): Promise<AppResponse<Profession>> {
    const url = Util.apiAuthUrl("create/profession");

    return await axios.post<Partial<Profession>, AppResponse<Profession>>(url, data);
  }

  public static async createProfessionLicense(data: Partial<ProfessionLicense>): Promise<AppResponse<ProfessionLicense>> {
    const url = Util.apiAuthUrl("create/professionLicense");

    return await axios.post<Partial<ProfessionLicense>, AppResponse<ProfessionLicense>>(url, data);
  }

  public static async createHashTag(data: Partial<HashTag>): Promise<AppResponse<HashTag>> {
    const url = Util.apiAuthUrl("create/hashTag");

    return await axios.post<Partial<HashTag>, AppResponse<HashTag>>(url, data);
  }

  public static async deleteHashTag(id: string): Promise<AppResponse<HashTag>> {
    const url = Util.apiAuthUrl("deleteHashTag/" + id);

    return await axios.delete<Partial<HashTag>, AppResponse<HashTag>>(url);
  }

  public static async educationDetails(data: Partial<Qualification>, fileToUpload: FileList): Promise<AppResponse<Qualification>> {
    const ep = Util.apiAuthUrl("education");
    const formData: FormData = new FormData();

    formData.append("educationalDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("uploads", file);
    }

    return await axios.post<FormData, AppResponse<Qualification>>(ep, formData);
  }

  public static async updateEducationDetails(data: Partial<Qualification>, fileToUpload: FileList): Promise<AppResponse<Qualification>> {
    const ep = Util.apiAuthUrl("editEducationalDetails");
    const formData: FormData = new FormData();
    formData.append("educationalDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("uploads", file);
    }

    return await axios.post<FormData, AppResponse<Qualification>>(ep, formData);
  }

  public static async deleteEducationData(data: string): Promise<AppResponse<Qualification[]>> {
    const url = Util.apiAuthUrl("deleteEducationDetails/" + data);

    return await axios.delete<Partial<Qualification>, AppResponse<Qualification[]>>(url);
  }

  public static async deleteLicensesData(data: string): Promise<AppResponse<License[]>> {
    const url = Util.apiAuthUrl("deleteLisenceDetails/" + data);

    return await axios.delete<Partial<License>, AppResponse<License[]>>(url);
  }

  public static async licensesDetails(data: Partial<License>, fileToUpload: FileList): Promise<AppResponse<License>> {
    const ep = Util.apiAuthUrl("lisence");
    const formData: FormData = new FormData();
    formData.append("licenseDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("uploads", file);
    }

    return await axios.post<FormData, AppResponse<License>>(ep, formData);
  }

  public static async updatelicensesDetails(data: Partial<License>, fileToUpload: FileList): Promise<AppResponse<License>> {
    const ep = Util.apiAuthUrl("editLicenseDetails");
    const formData: FormData = new FormData();
    formData.append("licenseDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("uploads", file);
    }

    return await axios.post<FormData, AppResponse<License>>(ep, formData);
  }

  public static async forgotPassword(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("forgotPassword");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async recoverPassword(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("resetPassword");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async changePassword(data: Partial<PasswordDetails>): Promise<AppResponse<PasswordDetails>> {
    const url = Util.apiAuthUrl("changePassword");

    return await axios.post<Partial<PasswordDetails>, AppResponse<PasswordDetails>>(url, data);
  }

  public static async addPayment(data: Partial<Payment>): Promise<AppResponse<Payment>> {
    const url = Util.apiAuthUrl("addPaymentMethod");

    return await axios.post<Partial<Payment>, AppResponse<Payment>>(url, data);
  }

  public static async updateTherapistProfile(data: Partial<UserDetails2 | UserDetails>, fileToUpload: FileList): Promise<AppResponse<Upload>> {
    const ep = Util.apiAuthUrl("updateTherapistProfile");
    const formData: FormData = new FormData();
    formData.append("profileDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("disclosureStatement", file);
    }

    return await axios.post<FormData, AppResponse<Upload>>(ep, formData);
  }

  public static async updateTherapistProfileByAdmin(data: Partial<UserDetails2 | UserDetails>, fileToUpload: FileList): Promise<AppResponse<Upload>> {
    const ep = Util.apiAuthUrl("updateTherapistProfileByAdmin");
    const formData: FormData = new FormData();
    formData.append("profileDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("disclosureStatement", file);
    }

    return await axios.post<FormData, AppResponse<Upload>>(ep, formData);
  }

  public static async updateUserNotification(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateUserNotification");
    return await axios.post<Partial<Request>, AppResponse<UserDetails>>(url, data);
  }

  public static async updateClientProfile(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateClientProfile");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async updateClientProfileByAdmin(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateClientProfileByAdmin");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async updateClientPackage(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateClientPackage");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }
  public static async updateIncognitoMode(mode: boolean): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateIncognitoMode");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, { mode: mode });
  }

  public static async updateAllowRecording(mode: boolean): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateCallRecordingAllowed");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, { mode: mode });
  }

  public static async updateHideCallTimer(mode: boolean): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateHideCallTimer");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, { mode: mode });
  }

  public static async getThemesImages(): Promise<AppResponse<Upload[]>> {
    const url = Util.apiAuthUrl("getAllThemeImages");

    return await axios.get<Partial<Upload[]>, AppResponse<Upload[]>>(url);
  }

  public static async addCompleteGuide(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("addCompleteGuide");
    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async deleteProfession(id: string): Promise<AppResponse<Profession>> {
    const url = Util.apiAuthUrl("deleteProfession/" + id);
    return await axios.delete<Partial<Profession>, AppResponse<Profession>>(url);
  }

  public static async deleteProfessionLicense(id: string): Promise<AppResponse<ProfessionLicense>> {
    const url = Util.apiAuthUrl("deleteProfessionLicense/" + id);
    return await axios.delete<Partial<ProfessionLicense>, AppResponse<ProfessionLicense>>(url);
  }

  public static async updateProfession(id: string | any, data: Partial<Profession>): Promise<AppResponse<Profession>> {
    const url = Util.apiAuthUrl("update/profession/" + id);

    return await axios.post<Partial<Profession>, AppResponse<Profession>>(url, data);
  }

  public static async getProfessionById(professionId: string): Promise<AppResponse<Profession>> {
    const url = Util.apiAuthUrl("get_profession/" + professionId);
    return await axios.get<Partial<Profession>, AppResponse<Profession>>(url);
  }

  public static async getProfessionLicenseById(professionLicenseId: string): Promise<AppResponse<ProfessionLicense>> {
    const url = Util.apiAuthUrl("get_profession_license/" + professionLicenseId);
    return await axios.get<Partial<ProfessionLicense>, AppResponse<ProfessionLicense>>(url);
  }

  public static async sendFeedBack(data: Partial<FeedbackList>): Promise<AppResponse<FeedbackList>> {
    const url = Util.apiAuthUrl("addFeedback");

    return await axios.post<Partial<FeedbackList>, AppResponse<FeedbackList>>(url, data);
  }

  public static async getGoogleCalendarRefreshToken(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("getGoogleCalendarRefreshToken");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async createGoogleCalendarEvent(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("createGoogleCalendarEvent");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async getGoogleCalendarActiveStatus(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("getGoogleCalendarActiveStatus");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async googleCalendarLogout(): Promise<AppResponse<Profession>> {
    const url = Util.apiAuthUrl("googleCalendarLogout");
    return await axios.get<Partial<Profession>, AppResponse<Profession>>(url);
  }

  public static async updateGoogleCalendarEvent(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("updateGoogleCalendarEvent");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async deleteGoogleCalendarEvent(data: Partial<UserDetails>): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl("deleteGoogleCalendarEvent");

    return await axios.post<Partial<UserDetails>, AppResponse<UserDetails>>(url, data);
  }

  public static async checkoutPaymentLink(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("checkout-link");

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async UpdatePaymentWithStripeLink(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("update-copayment");

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async checkCoPaymentStatus(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("check-copayment-status");

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async addClinicalAssesment(data: Partial<ClinicalAssetment>): Promise<AppResponse<ClinicalAssetment>> {
    const url = Util.apiAuthUrl("addClinicalAssestmentDetails");

    return await axios.post<Partial<ClinicalAssetment>, AppResponse<ClinicalAssetment>>(url, data);
  }

  public static async updateClinicalAssesment(data: Partial<ClinicalAssetment>): Promise<AppResponse<ClinicalAssetment>> {
    const url = Util.apiAuthUrl("updateClinicalAssestmentDetails");

    return await axios.post<Partial<ClinicalAssetment>, AppResponse<ClinicalAssetment>>(url, data);
  }

  public static async getClinicalAssesment(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClinicalAssestmentDetails");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async addTherapyPlan(data: Partial<TherapyPlanSinglePage>): Promise<AppResponse<TherapyPlanSinglePage>> {
    const url = Util.apiAuthUrl("addTherapyPlanDetails");
    return await axios.post<Partial<TherapyPlanSinglePage>, AppResponse<TherapyPlanSinglePage>>(url, data);
  }

  public static async updateTherapyPlan(data: Partial<TherapyPlanSinglePage>): Promise<AppResponse<TherapyPlanSinglePage>> {
    const url = Util.apiAuthUrl("updateTherapyPlanDetails");
    return await axios.post<Partial<TherapyPlanSinglePage>, AppResponse<TherapyPlanSinglePage>>(url, data);
  }

  public static async getTherapyPlan(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getTherapyPlanDetails");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClinicalAssestmentDetailsWithSessionData(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClinicalAssestmentDetailsWithSessionData");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClinicalAssestmentDetailsForDownload(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClinicalAssestmentDetailsForDownload");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async addDigitalAssessmentDetails(data: { data: Partial<DigitalForm>; isAdmin: boolean; _id: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("addDigitalAssesmentFormsDetails");
    return await axios.post<Partial<DigitalForm>, AppResponse<any>>(url, data);
  }

  public static async getDigitalAssessmentDataByClientAndTherapistId(clientId: string, therapistId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getDigitalAssesmentFormsDetails/${clientId}/${therapistId}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getDigitalAssessmentDataByTherapist(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getDigitalAssessmentDataByTherapist");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getDigitalAssessmentDetailsByTherapist(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getDigitalAssessmentDetailsByTherapist");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateDigitalAssessmentDetails(data: { data: Partial<DigitalForm>; isAdmin: boolean; _id: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("updateDigitalAssesmentFormsDetails");
    return await axios.post<Partial<DigitalForm>, AppResponse<any>>(url, data);
  }

  public static async getNewTypeOfClinicalAssessmentDetailsForDownload(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getNewTypeOfClinicalAssessmentDetailsForDownload");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getDigitalAssestmentSessionData(data: { clientId: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getDigitalAssestmentSessionData");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAllStatesPublic(): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("getAllStatesPublic");
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getClientNameAndTherapistName(data: Partial<any>) {
    const url = Util.apiAuthUrl("getClientNameAndTherapistName");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getTherapistCategorizationByType() {
    const url = Util.apiAuthUrl("getTherapistCategorizationByType");
    return await axios.post<Partial<any>, AppResponse<any>>(url);
  }
  public static async updateTherapistCategorizationByType(therapistCategorizationByType: Partial<any>) {
    const url = Util.apiAuthUrl("updateTherapistCategorizationByType");
    return await axios.post<Partial<any>, AppResponse<any>>(url, therapistCategorizationByType);
  }

}
