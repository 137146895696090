import React from "react";
import { Modal, ModalBody } from "reactstrap";
import closeIcon from "../../../assets/images/article/closeIcon.svg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { toast } from "react-toastify";
import { environment } from "../../../environment/environment";

interface ShareModalProps {
  title: string | undefined;
  show: boolean;
  close: () => void;
  copyToClipBoard?: (value: string) => Promise<boolean | void>;
  handleCopy?: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ title, show, close, copyToClipBoard, handleCopy }: ShareModalProps) => {
  const URL = window.location.href;

  const copyToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const copyUrl = (url: string) => {
    const articleId = url.split("/")[4];
    const updatedUrl = environment.app_url + "/articles/" + articleId;
    copyToClipboard(updatedUrl)
      .then(() => {
        toast.success("Url copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      })
      .catch(error => {
        toast.success("Failed to copy.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
      close();
  };

  return (
    <Modal isOpen={show} centered={true}>
      <ModalBody className="py-3 px-3">
        <div className="ml-4 modal-header flex flex-shrink-0 align-items-center  p-2">
          <h5 className="text-muted font-size-18">Share on other platforms</h5>
          <button className=" justify-end col-span-2 border-0" onClick={close}>
            <img className=" mr-4  pl-12 justify-end pt-0 h-16 w-16 " src={closeIcon} />
          </button>
        </div>
        <div className="w-full justify-center  modal-body relative p-2 text-xs semi-bold">
          <FacebookShareButton
            url={URL}
            quote={title}
            //hashtag={"#hashtag"}
            className="Demo__some-network__share-button w-100"
          >
            <div className="shadow-sm bg-white rounded-lg d-flex flex-row mb-3 align-items-center p-1">
              {/* <img className=" block mt-1 mb-1 avatar-img p-1" src={image1} /> */}

              <FacebookIcon size={40} round />

              <p className="px-3 mb-0 font-size-16">Share on Facebook</p>
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            title={title}
            url={URL}
            //hashtags={["hashtag1", "hashtag2"]}
            className="w-100"
          >
            <div className="shadow-sm bg-white rounded-lg d-flex flex-row mb-3 align-items-center p-1">
              <TwitterIcon size={40} round />
              <p className="px-3 mb-0 font-size-16">Share on Twitter</p>
            </div>
          </TwitterShareButton>
          <WhatsappShareButton title={title} separator=":: " className="w-100" url={URL}>
            <div className="shadow-sm bg-white rounded-lg d-flex flex-row mb-3 align-items-center p-1">
              <WhatsappIcon size={40} round />
              <p className="px-3 mb-0 font-size-16">Share on WhatsApp</p>
            </div>
          </WhatsappShareButton>
          <LinkedinShareButton title={title} windowWidth={750} windowHeight={600} url={URL} className="w-100">
            <div className="shadow-sm bg-white rounded-lg d-flex flex-row mb-3 align-items-center p-1">
              <LinkedinIcon size={40} round />
              <p className="px-3 mb-0 font-size-16 font-weight-bold">Share on LinkedIn</p>
            </div>
          </LinkedinShareButton>
          <EmailShareButton url={URL} title={title} body="body" className="network__share-button w-100">
            <div className="shadow-sm bg-white rounded-lg d-flex flex-row mb-3 align-items-center p-1">
              <EmailIcon size={40} round />
              <p className="px-3 mb-0 font-size-16 font-weight-bold">Share via Email</p>
            </div>
          </EmailShareButton>
          <button className="btn btn-primary w-100" onClick={() => copyUrl(URL)}>
            Copy article link
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
