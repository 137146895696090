import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "reactstrap";
import moment from "moment";
import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";
import { ConsumerPhone, GenderBirth, GuardianPhone } from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

const NewFormOne = (props: any) => {
  const { handleBulkFormDataUpdate, data } = props;
  const [edit, setEdit] = useState<boolean>(true);

  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    generalInformation: {
      name: "",
      dob: "",
      knownAs: "",
      age: "",
      primaryLanguage: {
        primaryLanguageReadablityStatus: null,
        english: null,
        spanish: null,
        other: null,
        otherLanguage: "",
      },
      consumerPhone: {
        consumerPhone: {
          home: null,
          work: null,
          cellPhone: null,
          other: null,
        },
        otherConsumerPhoneType: "",
        otherConsumerPhoneNumber: "",
      },
      legalGuarianship: {
        legalGuarianship: {
          self: null,
          other: null,
        },
        otherLegalGuarianship: "",
      },
      guardianPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
        guardianPhoneNumber: "",
      },
      primaryInformant: {
        primaryInformant: {
          self: null,
          other: null,
        },
        other: "",
      },
      informantPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
        informantPhoneNumber: "",
      },
      genderAtBirth: {
        male: null,
        female: null,
        femalePregnant: null,
      },
      genderIdentity: {
        male: null,
        female: null,
        nonBinary: null,
      },
      sexualOrientation: {
        sexualOrientations: "",
        isInterestingLGBTService: null,
      },
      ethnicity: {
        notHispanicOrigin: null,
        hispanicCuban: null,
        hispanicMexicanAmerican: null,
        hispanicPuertoRican: null,
        hispanicOther: null,
      },
      raceType: {
        race: {
          blackAfricanAmerican: null,
          alaskaNative: null,
          whiteCaucasian: null,
          asian: null,
          americanIndianNativeAmerican: null,
          pacificIslander: null,
          multiracial: null,
          other: null,
        },
        otherRace: "",
      },
      livingArrangement: {
        livingArrangement: {
          privateResidence: null,
          institution: null,
          ownResidence: null,
          other: null,
          roomHouseDorm: null,
          adultCareHome: null,
          alternativeFamilyLiving: null,
          nursingHome: null,
          communityICFMR: null,
          correctionalFacility: null,
          homeless: null,
          residentialFacility: null,
        },
        other: "",
      },
      maritalStatus: {
        married: null,
        widowed: null,
        divorced: null,
        singleNeverMarried: null,
        separated: null,
        domesticPartnership: null,
      },
      familySize: "",
      currentEmploymentStatus: {
        unemployed: null,
        notavailableforwork: null,
        employedFullTime: null,
        homemaker: null,
        armedForcesNationalGuard: null,
        employedPartTime: null,
        retired: null,
        student: null,
        disabilityIncome: null,
      },
      employmentHistory: "",
      education: {
        highestGradeCompleted: null,
        highSchoolgraduate: null,
        ged: null,
        someCollege: null,
        associateDegree: null,
        bachelordegree: null,
        graduateSchool: null,
        specialEdClasses: null,
        technicalTradeSchool: null,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setFormData((fData: Partial<DigitalForm>) => ({
        ...fData,
        formHeader: data?.formHeader,
        generalInformation: data?.generalInformation,
      }));
    }
  }, []);

  useEffect(() => {
    if (data !== null || data !== undefined) {
      const updateValues = {
        formHeader: data?.formHeader,
        generalInformation: data?.generalInformation,
      };
      console.log(updateValues);
      setFormData(updateValues);
    }
  }, [data]);

  const handleDataUpdate = () => {
    const updateData = {
      formHeader: {
        ...formData?.formHeader,
      },
      generalInformation: {
        ...formData?.generalInformation,
      },
    };

    handleBulkFormDataUpdate(updateData);
  };

  const updatePrimaryLanguage = (field: string, value: boolean | string) => {
    setFormData((prevData: Partial<DigitalForm>) => ({
      ...prevData,
      generalInformation: {
        ...prevData?.generalInformation,
        primaryLanguage: {
          ...prevData?.generalInformation?.primaryLanguage,
          [field]: value,
          ...(field !== "otherLanguage" && {
            english: field === "english" ? (value as boolean) : false,
            spanish: field === "spanish" ? (value as boolean) : false,
            other: field === "other" ? (value as boolean) : false,
            primaryLanguageReadablityStatus: field === "primaryLanguageReadablityStatus" ? (value as boolean) : formData?.generalInformation?.primaryLanguage?.primaryLanguageReadablityStatus,
          }),
          otherLanguage: field === "otherLanguage" ? (value as string) : "",
        },
      },
    }));
  };

  const updateConsumerPhone = (field: string, value: boolean | string) => {
    formData?.generalInformation?.consumerPhone?.otherConsumerPhoneType
    if (field !== "other") {
      setFormData((prevData: Partial<DigitalForm>) => ({
        ...prevData,
        generalInformation: {
          ...prevData?.generalInformation,
          consumerPhone: {
            ...prevData?.generalInformation?.consumerPhone,
            otherConsumerPhoneType: "",
        },},
      }));
    }
    setFormData((prevData: Partial<DigitalForm>) => ({
      ...prevData,
      generalInformation: {
        ...prevData?.generalInformation,
        consumerPhone: {
          ...prevData?.generalInformation?.consumerPhone,
          consumerPhone: {
            home: field === "home" ? (value as boolean) : false,
            work: field === "work" ? (value as boolean) : false,
            cellPhone: field === "cellPhone" ? (value as boolean) : false,
            other: field === "other" ? (value as boolean) : false,
          },
        },
      },
    }));
  };

  const updateLegalGuarianship = (field: string, value: boolean | string) => {
    setFormData((prevData: Partial<DigitalForm>) => ({
      ...prevData,
      generalInformation: {
        ...prevData?.generalInformation,
        legalGuarianship: {
          ...prevData?.generalInformation?.legalGuarianship,
          legalGuarianship: {
            self: field === "self" ? (value as boolean) : false,
            other: field === "other" || field === "otherLegalGuarianship",
          },
          otherLegalGuarianship: field === "otherLegalGuarianship" ? (value as string) : "",
        },
      },
    }));
  };

  const updateGuardianPhone = (field: string, value: boolean | string) => {
    setFormData((prevData: Partial<DigitalForm>) => ({
      ...prevData,
      generalInformation: {
        ...prevData?.generalInformation,
        guardianPhone: {
          ...prevData?.generalInformation?.guardianPhone,
          sameasAbove: field === "sameasAbove" ? (value as boolean) : false,
          home: field === "home" ? (value as boolean) : false,
          work: field === "work" ? (value as boolean) : false,
          cell: field === "cell" ? (value as boolean) : false,
        },
      },
    }));
  };

  const updatePrimaryInformant = (field: string, value: boolean | string) => {
    setFormData((prevData: Partial<DigitalForm>) => ({
      ...prevData,
      generalInformation: {
        ...prevData?.generalInformation,
        primaryInformant: {
          ...prevData?.generalInformation?.primaryInformant,
          primaryInformant: {
            self: field === "self" ? (value as boolean) : false,
            other: field === "other" || field === "other",
          },
          other: field === "other" && typeof value === "string" ? value : "",
        },
      },
    }));
  };

  const updateInformantPhone = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        informantPhone: {
          ...formData?.generalInformation?.informantPhone,
          sameasAbove: field === "sameasAbove" ? (value as boolean) : false,
          home: field === "home" ? (value as boolean) : false,
          work: field === "work" ? (value as boolean) : false,
          cell: field === "cell" ? (value as boolean) : false,
        },
      },
    });
  };

  const updateGenderAtBirth = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        genderAtBirth: {
          ...formData?.generalInformation?.genderAtBirth,
          male: field === "male" ? (value as boolean) : false,
          female: field === "female" ? (value as boolean) : false,
          femalePregnant: field === "femalePregnant" ? (value as boolean) : false,
        },
      },
    });
  };

  const updateGenderIdentity = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        genderIdentity: {
          ...formData?.generalInformation?.genderIdentity,
          male: field === "male" ? (value as boolean) : false,
          female: field === "female" ? (value as boolean) : false,
          nonBinary: field === "nonBinary" ? (value as boolean) : false,
        },
      },
    });
  };

  const updateEthnicity = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        ethnicity: {
          ...formData?.generalInformation?.ethnicity,
          notHispanicOrigin: field === "notHispanicOrigin" ? (value as boolean) : false,
          hispanicCuban: field === "hispanicCuban" ? (value as boolean) : false,
          hispanicMexicanAmerican: field === "hispanicMexicanAmerican" ? (value as boolean) : false,
          hispanicPuertoRican: field === "hispanicPuertoRican" ? (value as boolean) : false,
          hispanicOther: field === "hispanicOther" ? (value as boolean) : false,
        },
      },
    });
  };

  const updateRaceType = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        raceType: {
          ...formData?.generalInformation?.raceType,
          otherRace: field === "other" && typeof value === "string" ? value : "",
          race: {
            ...formData?.generalInformation?.raceType?.race,
            blackAfricanAmerican: field === "blackAfricanAmerican" ? (value as boolean) : false,
            alaskaNative: field === "alaskaNative" ? (value as boolean) : false,
            whiteCaucasian: field === "whiteCaucasian" ? (value as boolean) : false,
            asian: field === "asian" ? (value as boolean) : false,
            americanIndianNativeAmerican: field === "americanIndianNativeAmerican" ? (value as boolean) : false,
            pacificIslander: field === "pacificIslander" ? (value as boolean) : false,
            multiracial: field === "multiracial" ? (value as boolean) : false,
            other: field === "other" ? (value as boolean) : false,
          },
        },
      },
    });
  };

  const updateLivingArrangement = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        livingArrangement: {
          ...formData?.generalInformation?.livingArrangement,
          other: field === "other" && typeof value === "string" ? value : "",
          livingArrangement: {
            ...formData?.generalInformation?.livingArrangement?.livingArrangement,
            institution: field === "institution" ? (value as boolean) : false,
            privateResidence: field === "privateResidence" ? (value as boolean) : false,
            ownResidence: field === "ownResidence" ? (value as boolean) : false,
            other: field === "other" ? (value as boolean) : false,
            roomHouseDorm: field === "roomHouseDorm" ? (value as boolean) : false,
            adultCareHome: field === "adultCareHome" ? (value as boolean) : false,
            alternativeFamilyLiving: field === "alternativeFamilyLiving" ? (value as boolean) : false,
            nursingHome: field === "nursingHome" ? (value as boolean) : false,
            communityICFMR: field === "communityICFMR" ? (value as boolean) : false,
            correctionalFacility: field === "correctionalFacility" ? (value as boolean) : false,
            homeless: field === "homeless" ? (value as boolean) : false,
            residentialFacility: field === "residentialFacility" ? (value as boolean) : false,
          },
        },
      },
    });
  };

  const updateMaritalStatus = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        maritalStatus: {
          ...formData?.generalInformation?.maritalStatus,
          married: field === "married" ? (value as boolean) : false,
          widowed: field === "widowed" ? (value as boolean) : false,
          divorced: field === "divorced" ? (value as boolean) : false,
          singleNeverMarried: field === "singleNeverMarried" ? (value as boolean) : false,
          separated: field === "separated" ? (value as boolean) : false,
          domesticPartnership: field === "domesticPartnership" ? (value as boolean) : false,
        },
      },
    });
  };

  const updateCurrentEmploymentStatus = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        currentEmploymentStatus: {
          ...formData?.generalInformation?.currentEmploymentStatus,
          unemployed: field === "unemployed" ? (value as boolean) : false,
          notavailableforwork: field === "notavailableforwork" ? (value as boolean) : false,
          employedFullTime: field === "employedFullTime" ? (value as boolean) : false,
          homemaker: field === "homemaker" ? (value as boolean) : false,
          armedForcesNationalGuard: field === "armedForcesNationalGuard" ? (value as boolean) : false,
          employedPartTime: field === "employedPartTime" ? (value as boolean) : false,
          retired: field === "retired" ? (value as boolean) : false,
          student: field === "student" ? (value as boolean) : false,
          disabilityIncome: field === "disabilityIncome" ? (value as boolean) : false,
        },
      },
    });
  };

  const updateEducation = (field: string, value: boolean | string) => {
    setFormData({
      ...formData,
      generalInformation: {
        ...formData?.generalInformation,
        education: {
          ...formData?.generalInformation?.education,
          highestGradeCompleted: field === "highestGradeCompleted" ? (value as boolean) : false,
          highSchoolgraduate: field === "highSchoolgraduate" ? (value as boolean) : false,
          ged: field === "ged" ? (value as boolean) : false,
          someCollege: field === "someCollege" ? (value as boolean) : false,
          associateDegree: field === "associateDegree" ? (value as boolean) : false,
          bachelordegree: field === "bachelordegree" ? (value as boolean) : false,
          graduateSchool: field === "graduateSchool" ? (value as boolean) : false,
          specialEdClasses: field === "specialEdClasses" ? (value as boolean) : false,
          technicalTradeSchool: field === "technicalTradeSchool" ? (value as boolean) : false,
        },
      },
    });
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            // onClick={() => {
            //     updateAssesmentDetails();
            // }}

            onClick={() => {
              handleDataUpdate();
              // nextStep();
            }}
          >
            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
          </button>
        </div>
        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                General Information
              </h5>
            </Card>

            <Card className="match-card-body p-3">
              <div style={{ overflowX: "auto" }}>
                <Row>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Name:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            value={formData?.generalInformation?.name ? formData?.generalInformation?.name : ""}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                generalInformation: { ...formData?.generalInformation, name: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Also Known As:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="text"
                            value={formData?.generalInformation?.knownAs ? formData?.generalInformation?.knownAs : ""}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                generalInformation: { ...formData?.generalInformation, knownAs: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col lg={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            DOB:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                            id="date"
                            value={moment.utc(formData?.generalInformation?.dob).format("YYYY-MM-DD")}
                            type="date"
                            max={moment().format("YYYY-MM-DD")}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                generalInformation: {
                                  ...formData?.generalInformation,
                                  dob: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col xl={6}>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Age:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                            }}
                            type="number"
                            value={formData?.generalInformation?.age ? formData?.generalInformation?.age : ""}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                generalInformation: { ...formData.generalInformation, age: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left" }}>
                  <tbody style={{ verticalAlign: "middle" }}>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Primary Language
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "50%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.primaryLanguage?.primaryLanguageReadablityStatus === true}
                                  onClick={() =>
                                    updatePrimaryLanguage(
                                      "primaryLanguageReadablityStatus",
                                      !formData?.generalInformation?.primaryLanguage?.primaryLanguageReadablityStatus
                                    )
                                  }
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Difficulty reading in Primary Language
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <div className="col">
                                  <div className="col-auto">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      checked={formData?.generalInformation?.primaryLanguage?.english === true}
                                      onClick={() => updatePrimaryLanguage("english", !formData?.generalInformation?.primaryLanguage?.english)}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                      English
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td style={{ width: "20%" }}>
                                <div className="col">
                                  <div className="col-auto">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      checked={formData?.generalInformation?.primaryLanguage?.spanish === true}
                                      onClick={() => updatePrimaryLanguage("spanish", !formData?.generalInformation?.primaryLanguage?.spanish)}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                      Spanish
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <input
                                      style={{ fontSize: "larger" }}
                                      type="radio"
                                      className="form-check-input"
                                      checked={formData?.generalInformation?.primaryLanguage?.other === true}
                                      onClick={() => updatePrimaryLanguage("other", !formData?.generalInformation?.primaryLanguage?.other)}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                      Other
                                    </label>
                                  </div>
                                  <div className="col">
                                    <div style={{ display: "flex" }}>
                                      <div className="col-auto">
                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                          <p>Other Language:</p>
                                        </h6>
                                      </div>
                                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <input
                                          style={{
                                            width: "100%",
                                            marginTop: "0.1rem",
                                            backgroundColor: "unset",
                                            border: "1px dotted #ccc",
                                            padding: "2px",
                                            outline: "none",
                                            borderRadius: "8px",
                                          }}
                                          type="text"
                                          disabled={!formData?.generalInformation?.primaryLanguage?.other}
                                          value={formData?.generalInformation?.primaryLanguage?.otherLanguage || ""}
                                          onChange={e => updatePrimaryLanguage("otherLanguage", e.target.value)}
                                        />
                                      </Card>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Consumer Phone
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ tableLayout: "fixed", width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td colSpan={1}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.home === true}
                                  onClick={() => updateConsumerPhone("home", !formData?.generalInformation?.consumerPhone?.consumerPhone?.home)}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Home
                                </label>
                              </td>
                              <td colSpan={1}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.work === true}
                                  onClick={() => updateConsumerPhone("work", !formData?.generalInformation?.consumerPhone?.consumerPhone?.work)}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Work
                                </label>
                              </td>
                              <td colSpan={2}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  value={ConsumerPhone.cellPhone}
                                  checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.cellPhone === true}
                                  onClick={() => updateConsumerPhone("cellPhone", !formData?.generalInformation?.consumerPhone?.consumerPhone?.cellPhone)}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cell Phone
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        value={ConsumerPhone.other}
                                        checked={formData?.generalInformation?.consumerPhone?.consumerPhone?.other === true}
                                        onClick={() => updateConsumerPhone("other", !formData?.generalInformation?.consumerPhone?.consumerPhone?.other)}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other (specify):{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          // border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                          border: "1px dotted  #ccc",
                                          padding: "2px",
                                          outline: "none",
                                          borderRadius: "8px",
                                        }}
                                        type="text"
                                        disabled={!formData?.generalInformation?.consumerPhone?.consumerPhone?.other}
                                        value={formData?.generalInformation?.consumerPhone?.otherConsumerPhoneType || ""}
                                        onChange={e => {
                                          setFormData((formData: Partial<DigitalForm>) => ({
                                            ...formData,
                                            generalInformation: {
                                              ...formData?.generalInformation,
                                              consumerPhone: {
                                                ...formData?.generalInformation?.consumerPhone,
                                                otherConsumerPhoneType: e.target.value,
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>

                              <td colSpan={3}>
                                <div style={{ display: "flex" }}>
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                      <p>Consumer&apos;s Phone:</p>
                                    </h6>
                                  </div>
                                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                    <input
                                      style={{
                                        width: "100%",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                        border: "1px dotted  #ccc",
                                        padding: "2px",
                                        outline: "none",
                                        borderRadius: "8px",
                                      }}
                                      type="text"
                                      // disabled={!formData?.generalInformation?.consumerPhone?.consumerPhone?.other}
                                      value={formData?.generalInformation?.consumerPhone?.otherConsumerPhoneNumber || ""}
                                      onChange={e => {
                                        setFormData((formData: Partial<DigitalForm>) => ({
                                          ...formData,
                                          generalInformation: {
                                            ...formData?.generalInformation,
                                            consumerPhone: {
                                              ...formData?.generalInformation?.consumerPhone,
                                              otherConsumerPhoneNumber: e.target.value,
                                            },
                                          },
                                        }));
                                      }}
                                    />
                                  </Card>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Legal Guardianship
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  // value={LegalGuarianship.self}
                                  checked={formData?.generalInformation?.legalGuarianship?.legalGuarianship?.self === true}
                                  onClick={() => {
                                    updateLegalGuarianship("self", !formData?.generalInformation?.legalGuarianship?.legalGuarianship?.self);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Self
                                </label>
                              </td>
                              <td style={{ width: "60%" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        checked={formData?.generalInformation?.legalGuarianship?.legalGuarianship?.other === true}
                                        onClick={() => {
                                          updateLegalGuarianship("other", !formData?.generalInformation?.legalGuarianship?.legalGuarianship?.other);
                                        }}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other (specify):{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          // border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                          border: "1px dotted  #ccc",
                                          padding: "2px",
                                          outline: "none",
                                          borderRadius: "8px",
                                        }}
                                        type="text"
                                        disabled={!formData?.generalInformation?.legalGuarianship?.legalGuarianship?.other}
                                        value={formData?.generalInformation?.legalGuarianship?.otherLegalGuarianship ?? ""}
                                        onChange={e => {
                                          updateLegalGuarianship("otherLegalGuarianship", e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Guardian Phone
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ tableLayout: "fixed", width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.guardianPhone?.sameasAbove === true}
                                  onClick={() => {
                                    updateGuardianPhone("sameasAbove", !formData?.generalInformation?.guardianPhone?.sameasAbove);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Same as above
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  value={GuardianPhone.home}
                                  checked={formData?.generalInformation?.guardianPhone?.home === true}
                                  onClick={() => {
                                    updateGuardianPhone("home", !formData?.generalInformation?.guardianPhone?.home);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Home
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.guardianPhone?.work === true}
                                  onClick={() => {
                                    updateGuardianPhone("work", !formData?.generalInformation?.guardianPhone?.work);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Work
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.guardianPhone?.cell === true}
                                  onClick={() => {
                                    updateGuardianPhone("cell", !formData?.generalInformation?.guardianPhone?.cell);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cell
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={8}>
                                <div style={{ display: "flex" }}>
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                      Guardian&apos; phone number:
                                    </h6>
                                  </div>
                                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      value={formData?.generalInformation?.guardianPhone?.guardianPhoneNumber ?? ""}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          generalInformation: {
                                            ...formData?.generalInformation,
                                            guardianPhone: {
                                              ...formData?.generalInformation?.guardianPhone,
                                              guardianPhoneNumber: e.target.value,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Card>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Primary Informant
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.primaryInformant?.primaryInformant?.self === true}
                                  onClick={() => updatePrimaryInformant("self", !formData?.generalInformation?.primaryInformant?.primaryInformant?.self)}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Self
                                </label>
                              </td>
                              <td style={{ width: "60%" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        checked={formData?.generalInformation?.primaryInformant?.primaryInformant?.other === true}
                                        onClick={() => {
                                          updatePrimaryInformant("other", !formData?.generalInformation?.primaryInformant?.primaryInformant?.other);
                                        }}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other (specify):{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          // border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                          border: "1px dotted  #ccc",
                                          padding: "2px",
                                          outline: "none",
                                          borderRadius: "8px",
                                        }}
                                        type="text"
                                        // readOnly={!edit}
                                        value={formData?.generalInformation?.primaryInformant?.other}
                                        disabled={!formData?.generalInformation?.primaryInformant?.primaryInformant?.other}
                                        onChange={e => {
                                          updatePrimaryInformant("other", e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Informant Phone
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ tableLayout: "fixed", width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.sameasAbove === true}
                                  onClick={() => {
                                    updateInformantPhone("sameasAbove", !formData?.generalInformation?.informantPhone?.sameasAbove);
                                  }}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Same as above
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.home === true}
                                  onClick={() => updateInformantPhone("home", !formData?.generalInformation?.informantPhone?.home)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Home
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.work === true}
                                  onClick={() => updateInformantPhone("work", !formData?.generalInformation?.informantPhone?.work)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Work
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.informantPhone?.cell === true}
                                  onClick={() => updateInformantPhone("cell", !formData?.generalInformation?.informantPhone?.cell)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cell
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={8}>
                                <div style={{ display: "flex" }}>
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                      <p>Informant&apos;s Phone Number:</p>
                                    </h6>
                                  </div>
                                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="text"
                                      value={formData?.generalInformation?.informantPhone?.informantPhoneNumber ?? ""}
                                      onChange={e => {
                                        setFormData({
                                          ...formData,
                                          generalInformation: {
                                            ...formData?.generalInformation,
                                            informantPhone: {
                                              ...formData?.generalInformation?.informantPhone,
                                              informantPhoneNumber: e.target.value,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Card>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Gender at Birth
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  value={GenderBirth.male}
                                  checked={formData?.generalInformation?.genderAtBirth?.male === true}
                                  onClick={() => updateGenderAtBirth("male", !formData?.generalInformation?.genderAtBirth?.male)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Male
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.genderAtBirth?.female === true}
                                  onClick={() => updateGenderAtBirth("female", !formData?.generalInformation?.genderAtBirth?.female)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Female
                                </label>
                              </td>
                              <td style={{ width: "40%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.genderAtBirth?.femalePregnant === true}
                                  onClick={() => updateGenderAtBirth("femalePregnant", !formData?.generalInformation?.genderAtBirth?.femalePregnant)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  If Female, Pregnant ?
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Gender Identity
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.genderIdentity?.male === true}
                                  onClick={() => updateGenderIdentity("male", !formData?.generalInformation?.genderIdentity?.male)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Male
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.genderIdentity?.female === true}
                                  onClick={() => updateGenderIdentity("female", !formData?.generalInformation?.genderIdentity?.female)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Female
                                </label>
                              </td>
                              <td style={{ width: "40%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.genderIdentity?.nonBinary === true}
                                  onClick={() => updateGenderIdentity("nonBinary", !formData?.generalInformation?.genderIdentity?.nonBinary)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Non-Binary
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Sexual Orientation *{" "}
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <colgroup>
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                        </colgroup>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td colSpan={8}>
                                <div className="col">
                                  <div style={{ display: "flex" }}>
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                        <td style={{ width: "40%" }}>
                                          <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                            Sexual orientation:
                                          </label>
                                        </td>
                                      </h6>
                                    </div>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", width: "100%" }}>
                                      <input
                                        style={{
                                          width: "100%",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                          border: "1px dotted  #ccc",
                                          padding: "2px",
                                          outline: "none",
                                          borderRadius: "8px",
                                        }}
                                        value={formData?.generalInformation?.sexualOrientation?.sexualOrientations ?? ""}
                                        type="text"
                                        onChange={e =>
                                          setFormData({
                                            ...formData,
                                            generalInformation: {
                                              ...formData?.generalInformation,
                                              sexualOrientation: {
                                                ...formData?.generalInformation?.sexualOrientation,
                                                sexualOrientations: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                      />
                                    </Card>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "40%" }}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Are you interested in LGBTQ specific services?
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.sexualOrientation?.isInterestingLGBTService === true}
                                  onClick={e =>
                                    setFormData({
                                      ...formData,
                                      generalInformation: {
                                        ...formData?.generalInformation,
                                        sexualOrientation: {
                                          ...formData?.generalInformation?.sexualOrientation,
                                          isInterestingLGBTService:
                                            formData?.generalInformation?.sexualOrientation?.isInterestingLGBTService === true ? null : true,
                                        },
                                      },
                                    })
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.sexualOrientation?.isInterestingLGBTService === false}
                                  onClick={e =>
                                    setFormData({
                                      ...formData,
                                      generalInformation: {
                                        ...formData?.generalInformation,
                                        sexualOrientation: {
                                          ...formData?.generalInformation?.sexualOrientation,
                                          isInterestingLGBTService:
                                            formData?.generalInformation?.sexualOrientation?.isInterestingLGBTService === false ? null : false,
                                        },
                                      },
                                    })
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Ethnicity
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.ethnicity?.notHispanicOrigin === true}
                                  onClick={() => updateEthnicity("notHispanicOrigin", !formData?.generalInformation?.ethnicity?.notHispanicOrigin)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Not Hispanic Origin
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.ethnicity?.hispanicCuban === true}
                                  onClick={() => updateEthnicity("hispanicCuban", !formData?.generalInformation?.ethnicity?.hispanicCuban)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Cuban
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.ethnicity?.hispanicMexicanAmerican === true}
                                  onClick={() => updateEthnicity("hispanicMexicanAmerican", !formData?.generalInformation?.ethnicity?.hispanicMexicanAmerican)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Mexican American
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.ethnicity?.hispanicPuertoRican === true}
                                  onClick={() => updateEthnicity("hispanicPuertoRican", !formData?.generalInformation?.ethnicity?.hispanicPuertoRican)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Puerto Rican
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.ethnicity?.hispanicOther === true}
                                  onClick={() => updateEthnicity("hispanicOther", !formData?.generalInformation?.ethnicity?.hispanicOther)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hispanic,Other
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr></tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Race
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.blackAfricanAmerican === true}
                                  onClick={() => updateRaceType("blackAfricanAmerican", !formData?.generalInformation?.raceType?.race?.blackAfricanAmerican)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Black/African American
                                </label>
                              </td>
                            </tr>
                            {/* <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input style={{ fontSize: "larger" }} type="radio" className="form-check-input" disabled={!edit} />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  American
                                </label>
                              </td>
                            </tr> */}
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.alaskaNative === true}
                                  onClick={() => updateRaceType("alaskaNative", !formData?.generalInformation?.raceType?.race?.alaskaNative)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Alaska Native
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.whiteCaucasian === true}
                                  onClick={() => updateRaceType("whiteCaucasian", !formData?.generalInformation?.raceType?.race?.whiteCaucasian)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  White/Caucasian
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.asian === true}
                                  onClick={() => updateRaceType("asian", !formData?.generalInformation?.raceType?.race?.asian)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Asian
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.americanIndianNativeAmerican === true}
                                  onClick={() =>
                                    updateRaceType("americanIndianNativeAmerican", !formData?.generalInformation?.raceType?.race?.americanIndianNativeAmerican)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  American Indian/Native American
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.pacificIslander === true}
                                  onClick={() => updateRaceType("pacificIslander", !formData?.generalInformation?.raceType?.race?.pacificIslander)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Pacific Islander
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.raceType?.race?.multiracial === true}
                                  onClick={() => updateRaceType("multiracial", !formData?.generalInformation?.raceType?.race?.multiracial)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Multiracial
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="col">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        checked={formData?.generalInformation?.raceType?.race?.other === true}
                                        onClick={() => updateRaceType("other", !formData?.generalInformation?.raceType?.race?.other)}
                                        disabled={!edit}
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other :{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",

                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                          border: "1px dotted  #ccc",
                                          padding: "2px",
                                          outline: "none",
                                          borderRadius: "8px",
                                        }}
                                        type="text"
                                        disabled={!formData?.generalInformation?.raceType?.race?.other}
                                        value={formData?.generalInformation?.raceType?.otherRace ?? ""}
                                        onChange={e => {
                                          setFormData({
                                            ...formData,
                                            generalInformation: {
                                              ...formData?.generalInformation,
                                              raceType: {
                                                ...formData?.generalInformation?.raceType,
                                                otherRace: e.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Living Arrangement
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.privateResidence === true}
                                  onClick={() =>
                                    updateLivingArrangement(
                                      "privateResidence",
                                      !formData?.generalInformation?.livingArrangement?.livingArrangement?.privateResidence
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Private Residence
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.institution === true}
                                  onClick={() =>
                                    updateLivingArrangement("institution", !formData?.generalInformation?.livingArrangement?.livingArrangement?.institution)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Institution
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.ownResidence === true}
                                  onClick={() =>
                                    updateLivingArrangement("ownResidence", !formData?.generalInformation?.livingArrangement?.livingArrangement?.ownResidence)
                                  }
                                  className="form-check-input"
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Own Residence
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <input
                                        style={{ fontSize: "larger" }}
                                        type="radio"
                                        className="form-check-input"
                                        disabled={!edit}
                                        checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.other === true}
                                        onClick={() =>
                                          updateLivingArrangement("other", !formData?.generalInformation?.livingArrangement?.livingArrangement?.other)
                                        }
                                      />
                                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                        Other :{" "}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        disabled={!formData?.generalInformation?.livingArrangement?.livingArrangement?.other}
                                        value={formData?.generalInformation?.livingArrangement?.other || ""}
                                        onChange={e => {
                                          setFormData((formData: Partial<DigitalForm>) => ({
                                            ...formData,
                                            generalInformation: {
                                              ...formData?.generalInformation,
                                              livingArrangement: {
                                                ...formData?.generalInformation?.livingArrangement,
                                                other: e.target.value,
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.roomHouseDorm === true}
                                  onClick={() =>
                                    updateLivingArrangement("roomHouseDorm", !formData?.generalInformation?.livingArrangement?.livingArrangement?.roomHouseDorm)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Room,House,Dorm,etc.
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.adultCareHome === true}
                                  onClick={() =>
                                    updateLivingArrangement("adultCareHome", !formData?.generalInformation?.livingArrangement?.livingArrangement?.adultCareHome)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Adult Care Home
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.alternativeFamilyLiving === true}
                                  onClick={() =>
                                    updateLivingArrangement(
                                      "alternativeFamilyLiving",
                                      !formData?.generalInformation?.livingArrangement?.livingArrangement?.alternativeFamilyLiving
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Alternative Family Living
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.nursingHome === true}
                                  onClick={() =>
                                    updateLivingArrangement("nursingHome", !formData?.generalInformation?.livingArrangement?.livingArrangement?.nursingHome)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Nursing Home
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.communityICFMR === true}
                                  onClick={() =>
                                    updateLivingArrangement(
                                      "communityICFMR",
                                      !formData?.generalInformation?.livingArrangement?.livingArrangement?.communityICFMR
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Community ICF-MR
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.correctionalFacility === true}
                                  onClick={() =>
                                    updateLivingArrangement(
                                      "correctionalFacility",
                                      !formData?.generalInformation?.livingArrangement?.livingArrangement?.correctionalFacility
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Correctional Facility
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.homeless === true}
                                  onClick={() =>
                                    updateLivingArrangement("homeless", !formData?.generalInformation?.livingArrangement?.livingArrangement?.homeless)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Homeless (or at risk)
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.livingArrangement?.livingArrangement?.residentialFacility === true}
                                  onClick={() =>
                                    updateLivingArrangement(
                                      "residentialFacility",
                                      !formData?.generalInformation?.livingArrangement?.livingArrangement?.residentialFacility
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Residential Facility
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        {" "}
                        Marital Status
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.maritalStatus?.married === true}
                                  onClick={() => updateMaritalStatus("married", !formData?.generalInformation?.maritalStatus?.married)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Married
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.maritalStatus?.widowed === true}
                                  onClick={() => updateMaritalStatus("widowed", !formData?.generalInformation?.maritalStatus?.widowed)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Widowed
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.maritalStatus?.divorced === true}
                                  onClick={() => updateMaritalStatus("divorced", !formData?.generalInformation?.maritalStatus?.divorced)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Divorced
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.maritalStatus?.singleNeverMarried === true}
                                  onClick={() => updateMaritalStatus("singleNeverMarried", !formData?.generalInformation?.maritalStatus?.singleNeverMarried)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Single,Never Married
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.maritalStatus?.separated === true}
                                  onClick={() => updateMaritalStatus("separated", !formData?.generalInformation?.maritalStatus?.separated)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Separated
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.maritalStatus?.domesticPartnership === true}
                                  onClick={() => updateMaritalStatus("domesticPartnership", !formData?.generalInformation?.maritalStatus?.domesticPartnership)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Domestic Partnership
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Family Size
                        <br />
                        (Including self)
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                {/* <input
                                  style={{ fontSize: "medium", border: "none", width: "100%" }}
                                  type="text"
                                  value={formData?.generalInformation?.familySize ?? ""}
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      generalInformation: {
                                        ...formData?.generalInformation,
                                        familySize: e.target.value,
                                      },
                                    })
                                  }
                                  disabled={!edit}
                                /> */}
                                <textarea
                                  rows={5}
                                  style={{
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                    width: "100%",
                                  }}
                                  value={formData?.generalInformation?.familySize ?? ""}
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      generalInformation: {
                                        ...formData?.generalInformation,
                                        familySize: e.target.value,
                                      },
                                    })
                                  }
                                  disabled={!edit}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} rowSpan={2} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Current Employment Status/
                        <br />
                        History
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.unemployed === true}
                                  onClick={() =>
                                    updateCurrentEmploymentStatus("unemployed", !formData?.generalInformation?.currentEmploymentStatus?.unemployed)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  {/* Highest Grade <br /> */}
                                  Unemployed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.notavailableforwork === true}
                                  onClick={() =>
                                    updateCurrentEmploymentStatus(
                                      "notavailableforwork",
                                      !formData?.generalInformation?.currentEmploymentStatus?.notavailableforwork
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Not available for work
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.employedFullTime === true}
                                  onClick={() =>
                                    updateCurrentEmploymentStatus("employedFullTime", !formData?.generalInformation?.currentEmploymentStatus?.employedFullTime)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Employed Full Time
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.homemaker === true}
                                  onClick={() => updateCurrentEmploymentStatus("homemaker", !formData?.generalInformation?.currentEmploymentStatus?.homemaker)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Homemaker
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.armedForcesNationalGuard === true}
                                  onClick={() =>
                                    updateCurrentEmploymentStatus(
                                      "armedForcesNationalGuard",
                                      !formData?.generalInformation?.currentEmploymentStatus?.armedForcesNationalGuard
                                    )
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Armed Forces/National Guard
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.employedPartTime === true}
                                  onClick={() =>
                                    updateCurrentEmploymentStatus("employedPartTime", !formData?.generalInformation?.currentEmploymentStatus?.employedPartTime)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Employed Part Time
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.retired === true}
                                  onClick={() => updateCurrentEmploymentStatus("retired", !formData?.generalInformation?.currentEmploymentStatus?.retired)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Retired
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.student === true}
                                  onClick={() => updateCurrentEmploymentStatus("student", !formData?.generalInformation?.currentEmploymentStatus?.student)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Student
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.currentEmploymentStatus?.disabilityIncome === true}
                                  onClick={() =>
                                    updateCurrentEmploymentStatus("disabilityIncome", !formData?.generalInformation?.currentEmploymentStatus?.disabilityIncome)
                                  }
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Disability Income
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={10} style={{ padding: "0.2rem" }}>
                        Employment History{" "}
                        <b>
                          ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                        </b>{" "}
                        :
                        <textarea
                          rows={5}
                          style={{
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                            width: "100%",
                          }}
                          value={formData?.generalInformation?.employmentHistory ?? ""}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              generalInformation: {
                                ...formData?.generalInformation,
                                employmentHistory: e.target.value,
                              },
                            })
                          }
                          // type="text"
                          disabled={!edit}
                        />
                      </td>
                      {/* <td colSpan={10} style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td> */}
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Education
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.highestGradeCompleted === true}
                                  onClick={() => updateEducation("highestGradeCompleted", !formData?.generalInformation?.education?.highestGradeCompleted)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Highest Grade Completed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.highSchoolgraduate === true}
                                  onClick={() => updateEducation("highSchoolgraduate", !formData?.generalInformation?.education?.highSchoolgraduate)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  High School graduate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.ged === true}
                                  onClick={() => updateEducation("ged", !formData?.generalInformation?.education?.ged)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  GED
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.someCollege === true}
                                  onClick={() => updateEducation("someCollege", !formData?.generalInformation?.education?.someCollege)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Some College
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.associateDegree === true}
                                  onClick={() => updateEducation("associateDegree", !formData?.generalInformation?.education?.associateDegree)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Associate Degree
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.bachelordegree === true}
                                  onClick={() => updateEducation("bachelordegree", !formData?.generalInformation?.education?.bachelordegree)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bachelor’s degree
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.graduateSchool === true}
                                  onClick={() => updateEducation("graduateSchool", !formData?.generalInformation?.education?.graduateSchool)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Graduate School
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.specialEdClasses === true}
                                  onClick={() => updateEducation("specialEdClasses", !formData?.generalInformation?.education?.specialEdClasses)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Special Ed/Classes
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "25%", padding: "0.1rem" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  checked={formData?.generalInformation?.education?.technicalTradeSchool === true}
                                  onClick={() => updateEducation("technicalTradeSchool", !formData?.generalInformation?.education?.technicalTradeSchool)}
                                  disabled={!edit}
                                />
                                <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                  Technical Trade School
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>

        <Row>
          <Col xs={4}></Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                handleDataUpdate();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 1 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormOne;
