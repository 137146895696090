import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { Phrase } from "../models/Notes";

export class NoteService {
  public static async getAllPhrases(stage: string): Promise<AppResponse<Phrase[]>> {
    const url = Util.apiAuthUrl(`getAllPhrases/${stage}`);
    return await axios.get<void, AppResponse<Phrase[]>>(url);
  }

  public static async createPhrase(phrase: any): Promise<AppResponse<Phrase>> {
    const url = Util.apiAuthUrl(`createPhrase`);
    return await axios.post<void, AppResponse<Phrase>>(url, phrase);
  }

  public static async editPhrase(phrase: any): Promise<AppResponse<Phrase>> {
    const url = Util.apiAuthUrl(`editPhrase`);
    return await axios.post<void, AppResponse<Phrase>>(url, phrase);
  }
}
