import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";

const FollowUpMessagePopUp: React.FC<{
  showModal: boolean;
  setShowModal: (closeOnly: boolean, showFollowUp: boolean) => void;
}> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button
            type="button"
            onClick={() => props.setShowModal(true, false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ fontSize: "12px", width: "20px", height: "20px", padding: "2px", margin: "2px" }}
          >
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">Please schedule follow-up appointment before leaving</CardTitle>
            <div className="d-flex justify-content-center">
              <button type="button" onClick={() => props.setShowModal(false, true)} className="btn btn-primary mr-2">
                Schedule
              </button>
              <button type="button" onClick={() => props.setShowModal(false, false)} className="btn btn-danger ml-3">
                Leave
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default FollowUpMessagePopUp;
