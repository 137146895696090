import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Button, Modal, ModalBody } from "reactstrap";
import "../../assets/css/admin.css";
const classNames = require("classnames");
import { Client } from "../../models/Client";
import { AdminService } from "../../services/AdminService";
import { ApproveReject, GenderTypes, UserRole } from "../../models/User";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Therapist } from "src/models/Therapist";
import { SubscriptionStatus } from "src/models/CardTypes";
import Close from "../../assets/images/icons/close.png";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-toastify";
import Spinner from "../../common/spinner/spinner";
import { CodeSandboxOutlined } from "@ant-design/icons";

const NewsletterMails: React.FC = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchableString, setIsSearchableString] = useState({ searchableString: null, status: null, zipCode: null });
  const limit = 52;
  const C_Limit = 52;
  const [therapistCount, setTherapistCount] = useState(10);
  const [userClientCount, setClientUserCount] = useState(10);
  const [offset, setOffset] = useState(1);
  const [clientOffset, setClientOffset] = useState(1);
  const [clients, setClients] = useState<Client[]>([]);
  const [modalOpenEmail, setModalOpenEmail] = useState(false);
  const [modalOpenMessage, setModalOpenMessage] = useState(false);
  const [ReceiverList, setReceiverList] = useState<any[]>([]);
  const [clientReceiverList, setClientReceiverList] = useState<any[]>([]);
  const [message, setMessage] = useState({ sendMessage: "" });
  const [subject, setSubject] = useState({ subject: "" });
  const [emailBody, setEmailBody] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectAllChecked2, setSelectAllChecked2] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  
  const [seeMore, setSeeMore] = useState(false);
  const [seeMore2, setSeeMore2] = useState(false);
  useEffect(() => {
    searchTherapists();
    searchClients();
  }, []);

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const searchTherapists = () => {
    setIsLoading(true);
    const data = {
      searchableString: isSearchableString.searchableString,
    };
    AdminService.searchTherapistsByAdmin(data, limit, offset).then(res => {
      if (res.success) {
        setSeeMore(res.data.userSet.length > 0 && res.data.userSet.length == limit);
        setTherapists(res.data.userSet);
        setIsLoading(false);
      } else {
        setTherapists([] as Therapist[]);
        setSeeMore(false);
        setIsLoading(false);
      }
    });
  };

  const seeMoreTherapists = () => {
    setIsLoading(true);
    const updatedOffset = offset + 1;
    setOffset(updatedOffset);
    const data = {
      searchableString: isSearchableString.searchableString,
    };
    AdminService.searchTherapistsByAdmin(data, limit, updatedOffset).then(res => {
      if (res.success) {
        setTherapists(previousmeeting => {
          return [...new Set([...previousmeeting, ...res.data.userSet])];
        });
        setSeeMore(res.data.userSet.length > 0 && res.data.userSet.length == limit);
        setIsLoading(false);
      } else {
        setTherapists([] as Therapist[]);
        setSeeMore(false);
        setIsLoading(false);
      }
    });
  };

  const searchClients = () => {
    setIsLoading(true);
    const data = {
      searchableString: isSearchableString.searchableString,
    };
    AdminService.searchClients(data, C_Limit, clientOffset).then(res => {
      if (res.success) {
        setSeeMore2(res.data.userSet.length > 0 && res.data.userSet.length == C_Limit);
        setClients(res.data.userSet);
        setIsLoading(false);
      } else {
        setClients([] as Client[]);
        setSeeMore2(false);
        setIsLoading(false);
      }
    });
  };

  const seeMoreClients = () => {
    setIsLoading(true);
    const updatedOffset = clientOffset + 1;
    setClientOffset(updatedOffset);
    const data = {
      searchableString: isSearchableString.searchableString,
    };
    AdminService.searchClients(data, C_Limit, updatedOffset).then(res => {
      if (res.success) {
        setClients(previousmeeting => {
          return [...new Set([...previousmeeting, ...res.data.userSet])];
        });

        setSeeMore2(res.data.userSet.length > 0 && res.data.userSet.length == C_Limit);

        setIsLoading(false);
      } else {
        setClients([] as Client[]);
        setSeeMore2(false);
        setIsLoading(false);
      }
    });
  };

  const toggleModalEmail = () => {
    setModalOpenEmail(!modalOpenEmail);
  };

  const toggleModalMessage = () => {
    setModalOpenMessage(!modalOpenMessage);
  };

  const handleCheckboxChange = (userId: any, e: any) => {
    if (e.target.checked == false) {
      if (customActiveTab == "2") {
        setClientReceiverList(prevMailReceiverList => {
          const updatedReceiverList = [...prevMailReceiverList, userId];
          return updatedReceiverList;
        });
      } else {
        setReceiverList(prevMailReceiverList => {
          const updatedReceiverList = [...prevMailReceiverList, userId];
          return updatedReceiverList;
        });
      }
    } else {
      setReceiverList(prevMailReceiverList => prevMailReceiverList.filter(id => id !== userId));
      setClientReceiverList(prevMailReceiverList => prevMailReceiverList.filter(id => id !== userId));
    }
  };

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setReceiverList([]);
    } else {
      const userList = therapists.map(therapist => therapist._id);
      setReceiverList(userList);
      // setSelectAllChecked(!selectAllChecked);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleSelectAllClients = () => {
    if (selectAllChecked2) {
      setClientReceiverList([]);
    } else {
      const userList = clients.map((data:any) => data?.client?._id);
      // setSelectAllChecked2(!selectAllChecked2);
      setClientReceiverList(userList);
    }
    setSelectAllChecked2(!selectAllChecked2);
  };

  const editorConfiguration = {
    removePlugins: ["MediaEmbed"],
  };

  const sendEmail = () => {
    let data;
    if (customActiveTab == "2") {
      data = {
        usersList: clientReceiverList,
        subject: subject?.subject,
        content: emailBody,
      };
    } else {
      data = {
        usersList: ReceiverList,
        subject: subject?.subject,
        content: emailBody,
      };
    }

    if (data?.usersList?.length < 1) {
      toast.error("Please select users first.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }
    if (!data.subject) {
      toast.error("Please Type Subject.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }
    if (!data.content) {
      toast.error("Please Type Your Content.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    AdminService.sendEmailToMultipleUsers(data).then(res => {
      if (res.success) {
        toast.success("Email is sent.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        if (customActiveTab === "2") {
          setClientReceiverList([]);
          setSelectAllChecked2(true);
          setEmailBody("");
          setSubject({ ...subject, subject: "" });
          setModalOpenEmail(!modalOpenEmail);
          const timeout = setTimeout(() => {
            setSelectAllChecked2(false);
          }, 2000);
          return () => clearTimeout(timeout);
        } else {
          setReceiverList([]);
          setEmailBody("");
          setSubject({ ...subject, subject: "" });
          setModalOpenEmail(!modalOpenEmail);
          setSelectAllChecked(true);
          const timeout = setTimeout(() => {
            setSelectAllChecked(false);
          }, 2000);
          return () => clearTimeout(timeout);
        }
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const sendMessage = () => {
    let data;
    if (customActiveTab == "2") {
      data = {
        usersList: clientReceiverList,

        content: message?.sendMessage,
      };
    } else {
      data = {
        usersList: ReceiverList,

        content: message?.sendMessage,
      };
    }

    if (data.usersList.length == 0) {
      toast.error("Please Select Users First.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!data.content) {
      toast.error("Please Enter Message.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setMessageLoading(true);

      AdminService.sendSMSToMultipleUsers(data).then(res => {
        if (res.success) {
          setMessageLoading(false);
          setMessage({ ...message, sendMessage: "" });
          toast.success("SMS is Sent.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          if (customActiveTab === "2") {
            setClientReceiverList([]);
            setSelectAllChecked2(true);
            setModalOpenMessage(!modalOpenMessage);
            const timeout = setTimeout(() => {
              setSelectAllChecked2(false);
            }, 2000);
            return () => clearTimeout(timeout);
          } else {
            setReceiverList([]);
            setModalOpenMessage(!modalOpenMessage);
            setSelectAllChecked(true);
            const timeout = setTimeout(() => {
              setSelectAllChecked(false);
            }, 2000);
            return () => clearTimeout(timeout);
          }
        } else {
          toast.error("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  const searchUsers = () => {
    if (customActiveTab == "1") {
      setIsLoading(true);
      const data = {
        searchableString: isSearchableString.searchableString,
      };
      AdminService.searchTherapistsByAdmin(data, limit, offset).then(res => {
        if (res.success) {
          setSeeMore(res.data.userSet.length > 0 && res.data.userSet.length == limit);
          setTherapists(res.data.userSet);
          setIsLoading(false);
        } else {
          setTherapists([] as Therapist[]);
          setSeeMore(false);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(true);
      const data = {
        searchableString: isSearchableString.searchableString,
      };
      
      AdminService.searchClients(data, C_Limit, clientOffset).then(res => {
        if (res.success) {
          setSeeMore2(res.data.userSet.length > 0 && res.data.userSet.length == C_Limit);
          setClients(res.data.userSet);
          setIsLoading(false);
        } else {
          setClients([] as Client[]);
          setSeeMore2(false);
          setIsLoading(false);
        }
      });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Newsletter Emails & SMS"} breadcrumbItem={"Newsletter Emails & SMS"} />

          <Card>
            <CardBody>
              <Row>
                <Col xl="12">
                  <Row>
                    <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: customActiveTab === "1" })}
                          onClick={() => {
                            toggleCustom("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="mdi mdi-view-grid-outline"></i>
                          </span>

                          <span className="d-none d-sm-block">Therapists</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: customActiveTab === "2" })}
                          onClick={() => {
                            toggleCustom("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="mdi mdi-format-list-bulleted"></i>
                          </span>

                          <span className="d-none d-sm-block">Clients</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Col className="d-flex admin-filter">
                      <div className="d-flex justify-content-center pr-2 ">
                        <div className="d-flex justify-content-center align-items-center pr-2">
                          <span className="text-center mb-0 single-line-text mr-2">Search : </span>
                        </div>
                        <div onClick={event => event.stopPropagation()}>
                          <input
                            className="search-input-stat form-control me-2"
                            onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })}
                          />
                        </div>
                      </div>
                      <span className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center" onClick={searchUsers}>
                        <i className="bx bx-search"></i>
                      </span>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12" className="mt10">
                            <div className="pt-4 flex flex-row-reverse flex-wrap mb-3">
                              <Button color="primary" className="ml-3" onClick={toggleModalEmail}>
                                Send Email
                              </Button>

                              <Button color="primary" className="ml-3" onClick={toggleModalMessage}>
                                Send Message
                              </Button>

                              <Button color="info" className="ml-3" onClick={handleSelectAll}>
                                Select All
                              </Button>
                            </div>
                            <div className="pt-4">
                              <div className="row">
                                {isLoading ? (
                                  <div className="col-md-12 text-center">
                                    <Spinner />
                                  </div>
                                ) : (
                                  <>
                                    {therapists.map((therapist, index) => (
                                      <div key={therapist._id} className="col-md-3 col-sm-6 emailList">
                                        <input
                                          type="checkbox"
                                          name={`therapist-${therapist._id}`}
                                          onClick={e => handleCheckboxChange(therapist._id, e)}
                                          checked={ReceiverList.includes(therapist._id)}
                                          className="form-check-input"
                                        />
                                        <span className="EmailusersList"></span>
                                        {therapist.firstname} {therapist.lastname}
                                        <span />
                                      </div>
                                    ))}
                                    {seeMore && (
                                      <div className="col-12 text-center">
                                        <button className="btn btn-warning mt-2" onClick={() => seeMoreTherapists()}>
                                          See More
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12" className="mt-10">
                            <div className="pt-4 flex flex-row-reverse flex-wrap mb-3">
                              <Button color="primary" className="ml-3" onClick={toggleModalEmail}>
                                Send Email
                              </Button>

                              <Button color="primary" className="ml-3" onClick={toggleModalMessage}>
                                Send Message
                              </Button>

                              <Button color="info" className="ml-3" onClick={handleSelectAllClients}>
                                Select All
                              </Button>
                            </div>
                            <div className="pt-4">
                              <div className="row">
                                {isLoading ? (
                                  <div className="col-md-12 text-center">
                                    <Spinner />
                                  </div>
                                ) : (
                                  <>
                                    {clients.map((data: any, index) => (
                                      <div key={data?.client?._id} className="col-md-3 col-sm-6 emailList">
                                        <input
                                          type="checkbox"
                                          name={`therapist-${data?.client?._id}`}
                                          checked={clientReceiverList.includes(data?.client?._id)}
                                          onClick={e => handleCheckboxChange(data?.client?._id, e)}
                                          className="form-check-input"
                                        />
                                        <span className="EmailusersList">
                                          {data?.client?.firstname} {data?.client?.lastname}
                                        </span>
                                      </div>
                                    ))}
                                    {seeMore2 && (
                                      <div className="col-12 text-center">
                                        <button className="btn btn-warning mt-2" onClick={() => seeMoreClients()}>
                                          See More
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>

        <Modal isOpen={modalOpenEmail} toggle={toggleModalEmail} centered className="modal-lg">
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleModalEmail}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="ps-4 pe-4">
            <Row>
              <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Create Your Email </span>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="pb-3">
                <p className="mb-1"></p>
                <textarea
                  rows={2}
                  style={{ width: "100%" }}
                  placeholder=" subject:"
                  onChange={(e: any) => setSubject({ ...subject, subject: e.target.value })}
                ></textarea>
              </Col>
            </Row>
            <div className="editor-area ck-content">
              <CKEditor
                editor={Editor}
                stlye={{ minHeight: "500px" }}
                onChange={(event: any, editor: { getData: () => any }) => {
                  const data = editor.getData();
                  setEmailBody(data);
                }}
                data={emailBody}
                config={editorConfiguration}
              />
            </div>
            <Row className="mt-3">
              <Col className="md-8"></Col>
              <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={sendEmail}>
                  Send
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalOpenMessage} toggle={toggleModalMessage} centered className="modal-lg">
          <div style={{ textAlign: "center", paddingRight: "0px" }}>
            <span></span>
            <Button
              close
              onClick={toggleModalMessage}
              style={{
                position: "absolute",
                right: "15px",
                background: "none",
                border: "none",
                padding: "0",
                zIndex: "10",
              }}
              className="btn-s-20"
            >
              <img src={Close} alt="Close" className="mt-1" />
            </Button>
          </div>
          <ModalBody className="ps-4 pe-4">
            <Row>
              <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Send Message </span>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p className="mb-1">Message</p>
                <textarea
                  rows={4}
                  style={{ width: "100%" }}
                  placeholder="Type a message"
                  value={message?.sendMessage}
                  onChange={(e: any) => setMessage({ ...message, sendMessage: e.target.value })}
                ></textarea>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="md-8"></Col>
              <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={sendMessage}>
                  Send
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default NewsletterMails;
