import React, { useState, useEffect } from 'react';
import { StepProps } from './types';
import { AuditSessionsPopUpService } from '../../../services/AuditSessionsPopUpService';
import { environment } from '../../../environment/environment';
import moment from 'moment';
import Spinner from '../../../common/spinner/spinner';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import DiagnosisNoteScreen from '../../../pages/Chat/DiagnosisNote/DiagnosisNoteView';

interface Note {
  diagnosisNoteId: string;
  meetingStartedTime: string;
  status?: string;
  feedback?: string;
  isMeetingTranscribe?: boolean;
  noteType?: string | null;
  updatedByTherapist?: boolean;
  createdAt?: string;
  diagnosisNoteVersions?: any[];
  lastFeedbackFromAuditor?: string;
}

const SelectSessionNotes: React.FC<StepProps> = ({
  searchDocumentDownloadsByAdminSelected,
  onNext,
  onBack
}) => {
  const clientId = searchDocumentDownloadsByAdminSelected?.client?._id;
  const therapistId = searchDocumentDownloadsByAdminSelected?.therapist?._id;
  const [selectedNotes, setSelectedNotes] = useState<string[]>([]);

  const [notes, setNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDiagnosisId, setSelectedDiagnosisId] = useState<string>('');
  const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);

  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      const response = await AuditSessionsPopUpService.getNotesForTherapists(therapistId, clientId);
      if (response.success && response.data && response.data[0]?.notes) {
        const notesWithStatus = response.data[0].notes
          .map(note => ({
            ...note,
            status: note.status || 'NEED_REVIEW',
            feedback: note.lastFeedbackFromAuditor || ''
          }))
          .filter(note => note.status === 'COMPLETED');
        setNotes(notesWithStatus);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clientId && therapistId) {
      fetchNotes();
    }
  }, [clientId, therapistId]);



  return (
    <div>
      <h6 className="mb-4">Select Session Notes</h6>
      

      {isLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : notes.length === 0 ? (
        <div className="alert alert-info" role="alert">
          Currently there are no sessions marked as completed, therefore no results are displayed here.
        </div>
      ) : (
        <div className="table-responsive mb-4">
          <table className="table table-hover" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th 
                  style={{ width: '80px', cursor: 'pointer' }}
                  onClick={() => {
                    console.log("Header cell clicked");
                    const newCheckedState = !(selectedNotes.length === notes.length);
                    if (newCheckedState) {
                      const allUrls = notes.map(note => 
                        `${environment.app_url}/diagnosis-note-screen?diagnosisId=${note.diagnosisNoteId}`
                      );
                      setSelectedNotes(allUrls);
                    } else {
                      setSelectedNotes([]);
                    }
                  }}
                >
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedNotes.length === notes.length && notes.length > 0}
                      readOnly
                    />
                  </div>
                </th>
                <th style={{ width: '40%' }}>Date</th>
                <th style={{ width: '40%' }}>Diagnosis Note Id</th>
                <th style={{ width: '60%', textAlign: 'right', paddingRight: '40px' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {notes.map(note => (
                <tr key={note.diagnosisNoteId}>
                  <td 
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      console.log("Row cell clicked");
                      const url = `${environment.app_url}/diagnosis-note-screen?diagnosisId=${note.diagnosisNoteId}`;
                      setSelectedNotes(prev => {
                        if (prev.includes(url)) {
                          return prev.filter(id => id !== url);
                        } else {
                          return [...prev, url];
                        }
                      });
                    }}
                  >
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedNotes.includes(`${environment.app_url}/diagnosis-note-screen?diagnosisId=${note.diagnosisNoteId}`)}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>{moment(note.meetingStartedTime).format('YYYY-MM-DD HH:mm')}</td>
                  <td>
                    <Button
                      color="link"
                      className="p-0 text-primary"
                      onClick={() => {
                        setSelectedDiagnosisId(note.diagnosisNoteId);
                        setShowDiagnosisModal(true);
                      }}
                    >
                      {note.diagnosisNoteId}
                    </Button>
                  </td>
                  <td style={{ textAlign: 'right', paddingRight: '40px' }}>
                    <span className={`badge bg-${note.status === 'COMPLETED' ? 'success' : note.status === 'NEED_ATTENTION' ? 'danger' : 'secondary'}`}>
                      {note.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="alert alert-info" role="alert">
        <i className="fas fa-info-circle me-2"></i>
        Select the session notes to include in the audit PDF.
      </div>

      <div className="text-end mt-3">
        <button className="btn btn-secondary me-2" onClick={onBack}>
          Back
        </button>
        <button 
          className="btn btn-primary" 
          onClick={() => onNext(selectedNotes)}
          disabled={selectedNotes.length === 0}
        >
          Next
        </button>
      </div>

      {showDiagnosisModal && (
        <Modal isOpen={showDiagnosisModal} toggle={() => setShowDiagnosisModal(false)} size="xl">
          <ModalHeader toggle={() => setShowDiagnosisModal(false)}>Diagnosis Note</ModalHeader>
          <ModalBody>
            <DiagnosisNoteScreen
              diagnosisId={selectedDiagnosisId}
              totalSpentDuration={0}
              updateDiagnosisData={() => {}}
              isVersion={false}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default SelectSessionNotes;
