import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/onboarding.css";
import "../../../assets/css/publicMedia.css";
import HeaderMain from "../../../common/main/PublicShareHeader";
import FooterMain from "../../../common/main/FooterMain";
import { ClientService } from "src/services/ClientService";

const PublicClientShare: React.FC = () => {
    const [userId, setUserId] = useState('');
    const [isReferralValid, setIsReferralValid] = useState(true);
    useEffect(() => {
        const splittedURL = window.location.pathname.split("/");
        const userId = splittedURL[splittedURL.length - 1];
        setUserId(userId)
        if(userId){
            getClientData(userId);
        }
    }, []);

    const getClientData = (id: any) => {
      ClientService.getClientDetailsByIdPublic(id).then(res => {
          const data = res.data;

          if (data && data.role === "CLIENT") {
            localStorage.setItem('referralCode', id);
          } else {
            setIsReferralValid(false);
            toast.error("The referral link you provided is invalid. Please verify the link and try again.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
      });
  };

  if (!isReferralValid) {
    return (
      <div>
        <div className="content-wrapper">
          <HeaderMain />
          <section className="mt-12 mt-md-12 mb-4 mb-md-12">
            <div className="container pt-4 pt-md-12">
              <div className="row justify-content-center">
                <div className="col-lg-7 text-center">
                  <h3 className="text-danger" style={{ marginTop: '180px', marginBottom: '180px' }}>
                    The referral link you provided is invalid. Please verify the link and try again.
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <FooterMain />
        </div>
      </div>
    );
  }
    
  return (
    <div>
      <div className="content-wrapper ">
        <HeaderMain />
        <section className="mt-4 mt-md-12 mb-4 mb-md-12">
          <div className="container pt-4 d-flex justify-content-center align-items-center flex-column">
            <h1 className="refferal-title text-center pt-md-12">Welcome to Lavni!</h1>
            <p className="text-center fs-15">
              At Lavni, we make finding quality mental health care easy and accessible, especially for Medicaid clients.
              We&apos;re thrilled to have you here! Whether you&apos;ve been referred by your doctor, a friend, or found us on your own,
              our goal is to help you connect with a therapist who understands your needs and accepts your insurance.
            </p>

            <p className="text-center fs-15">
              With Lavni, you can find a therapist who fits your preferences, schedule an appointment online,
              and start your journey to better mental health—completely hassle-free.
            </p>
          </div>
        </section>

        <section className="">
          <div className="container pt-4 pt-md-12">
            <h1 className="text-center mb-3">Why Choose Lavni?</h1>
            <div className=" mt-4">
              <div className="row justify-content-center">

                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <i className="mdi mdi-emoticon-happy fs-30 text-white"></i>
                    <h4 className="text-white-referral text-center mt-3 mb-1">Medicaid-Friendly</h4>
                    <p className="text-white-referral text-center fs-15">
                      We specialize in connecting Medicaid clients with licensed therapists who accept your insurance.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <i className="mdi mdi-hand-heart fs-30 text-white"></i>
                    <h4 className="text-white-referral text-center mt-3 mb-1">Personalized Care</h4>
                    <p className="text-white-referral text-center fs-15">
                      We&apos;ll match you with a therapist who suits your unique needs, preferences, and circumstances.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <i className="mdi mdi-safe-square fs-30 text-white"></i>
                    <h4 className="text-white-referral text-center mt-3 mb-1">Convenient and Secure</h4>
                    <p className="text-white-referral text-center fs-15">
                      Schedule and attend your sessions from the comfort of your home, with the peace of mind that your privacy is protected.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <h4 className="text-center fs-30 mb-5">Take the first step towards feeling better today.</h4>
        </section>

        <section className="mt-4 mt-md-12 mb-12 mb-md-12">
          <h1 className="text-center mb-3">Ready to Get Started?</h1>
          <p className="text-center fs-15">Click the button below, answer a few simple questions, and we&apos;ll guide you to the right therapist for your needs.</p>
          <div className="container pt-md-10 pb-md-10">
            <div className="d-flex align-items-center justify-content-center">
              <a href="/home/#client-signup" className="btn btn-primary btn-start-journey mt-3 mb-3">Get Started 
              </a>
            </div>
          </div>
        </section>
        <FooterMain />
      </div>
    </div>
  );
};

export default PublicClientShare;
