export enum PaymentStatus {
  PENDING = "pending",
  PAID = "paid"
} 

export interface Invoice {
  _id: string;
  clientId: string;
  insuranceId: string;
  paidAmountByInsurance: string;
  dueAmount: string;
  paymentStatus: string;
  paymentMonth:string
  createdAt:Date;
}
