import moment from "moment";
import Linkify from "react-linkify";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import parse from "html-react-parser";
import { GroupChatMessageModel } from "src/models/GroupChat/GroupChatMessage";
import { decryptGroupChatMessage } from "./GroupChatEncryption";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "src/context/UserContext";
import { toast } from "react-toastify";
import { GroupChatService } from "src/services/GroupChatService";
import { SocketContext } from "src/context/ScoketContext";
import GroupChatContext from "src/context/GroupChatContext";
import { Util } from "src/Util";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  groupMessageFrom: GroupChatMessageModel;
  openReplyPanel: (messageDetails: GroupChatMessageModel) => void;
  // handleCopy: (text: string) => void;
  // deleteMessage: (messageId: string) => Promise<void>;
}

// message, currentUser, openReplyPanel, handleCopy, deleteMessage

const DisplayGroupMessageComp = ({ groupMessageFrom, openReplyPanel }: Props) => {
  const socket = useContext(SocketContext);
  const [user, setUser] = useContext(UserContext);
  const [isDeleting, setIsDeleting] = useState(false);
  const [groupMessage, setGroupMessage] = useState<GroupChatMessageModel>(groupMessageFrom);
  const [deletedMessageId, setDeletedMessageId] = useState<string>("");
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);

  const getPreviewByExtension = (extension: string, id: string) => {
    switch (extension) {
      case ".jpg":
      case ".JPG":
      case ".JPEG":
      case ".jpeg":
      case ".tiff":
      case ".TIFF":
      case ".gif":
      case ".GIF":
      case ".PNG":
      case ".png":
        return <img src={Util.fileURL(id)} style={{ maxHeight: "250px", maxWidth: "250px" }} />;
      case ".mp3":
      case ".MP3":
      case ".WMA":
      case ".wma":
        return <i className=" fa fa-file-audio text-info " style={{ fontSize: "90px" }}></i>;
      case ".mp4":
      case ".MP4":
      case ".AVI":
      case ".avi":
        return <i className=" fa fa-file-video text-warning " style={{ fontSize: "90px" }}></i>;
      case ".txt":
      case ".TXT":
        return <i className="fa fa-file-word  " style={{ fontSize: "90px" }}></i>;
      case ".pdf":
      case ".PDF":
        return <i className=" fa fa-file-pdf text-danger " style={{ fontSize: "90px" }}></i>;
      default:
        return <i className=" fa fa-file" style={{ fontSize: "90px" }}></i>;
    }
  };

  useEffect(() => {
    if (groupMessage._id != null && groupMessage._id != "" && deletedMessageId != null && deletedMessageId != "" && deletedMessageId == groupMessage._id) {
      setGroupMessage({ ...groupMessage, messageText: "", messageStatus: "DELETED", preMessageId: undefined, mediaFileId: undefined });
    }
  }, [deletedMessageId]);

  useEffect(() => {
    try {
      const messageId = groupMessage._id;
      socket.once(`group-chat-message-delete-${messageId}`, message => {
        setDeletedMessageId(message);
      });

      return () => {
        socket.off(`group-chat-message-delete-${messageId}`);
      };
    } catch (error) {}
  }, [socket]);

  const deleteMessage = async (messageId: string) => {
    try {
      if (!isDeleting && groupMessage.createdBy == user?._id) {
        setIsDeleting(true);
        const dataObject = {
          messageId: messageId,
        };
        const res = await GroupChatService.deleteMessage(dataObject);
        if (res && res.success) {
          setGroupMessage({ ...groupMessage, messageText: "", messageStatus: "DELETED", preMessageId: undefined, mediaFileId: undefined });
          setIsDeleting(false);
          if (socket && selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "") {
            socket.emit("send-group-chat-message-delete", selectedGroupData._id, messageId);
            socket.emit("send-group-chat-message-delete-in", selectedGroupData._id, messageId);
          }
        } else {
          setIsDeleting(false);
          toast.error("Failed to delete message!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsDeleting(false);
      } else {
        toast.error("Please wait, deleting message in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsDeleting(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const copyToClipboard = async (textFrom: string) => {
    try {
      const linkifyElement = document.getElementById(`text-component-${groupMessage._id}`);

      if (linkifyElement) {
        const plainText = linkifyElement.innerText;

        if ("clipboard" in navigator) {
          return await navigator.clipboard.writeText(plainText);
        } else {
          return document.execCommand("copy", true, plainText);
        }
      }
    } catch (error) {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }
  };

  const handleCopy = (text: string) => {
    const copiedTextInString = text;

    if (copiedTextInString != undefined || copiedTextInString != null || copiedTextInString != "") {
      copyToClipboard(copiedTextInString).then(() => {
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
    } else {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  return (
    <li key={groupMessage._id} className={groupMessage.createdBy == user?._id ? "right bg-light break-word-show-msg" : "break-word-show-msg"}>
      {groupMessage.messageStatus != null && groupMessage.messageStatus == "DELETED" ? (
        <div className="conversation-list">
          <div className={`ctext-wrap ${groupMessage.createdBy == user?._id ? "bg-secondary bg-soft" : ""} text-start`}>
            <div className="">
              {groupMessage.createdBy == user?._id ? "You" : groupMessage.firstname ?? "Member"}
              {" deleted this message"}
            </div>
            <p className="chat-time mb-0 align-items-center d-flex mt-0">{moment(groupMessage.createdAt).fromNow()}</p>
          </div>
        </div>
      ) : (
        <div className="conversation-list">
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="btn nav-btn" tag="i">
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  handleCopy(groupMessage.messageText ? decryptGroupChatMessage(groupMessage.messageText) : "");
                }}
              >
                Copy
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  openReplyPanel(groupMessage);
                  null;
                }}
              >
                Reply
              </DropdownItem>

              {groupMessage.createdBy == user?._id && (
                <DropdownItem
                  onClick={() => {
                    deleteMessage(groupMessage._id);
                  }}
                >
                  Delete
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className={`ctext-wrap ${groupMessage.createdBy == user?._id ? "bg-secondary bg-soft" : ""} text-start`}>
            <div className="conversation-name">{groupMessage.createdBy == user?._id ? "You" : groupMessage.firstname ?? "Member"}</div>

            {groupMessage.mediaFileId != null &&
            groupMessage.mediaFileId._id != null &&
            groupMessage.mediaFileId._id != "" &&
            groupMessage.mediaFileId.extension != null &&
            groupMessage.mediaFileId.extension != "" ? (
              <div className="mb-2 cursor-pointer" onClick={() => window.open(Util.fileURL(groupMessage.mediaFileId?._id))}>
                {getPreviewByExtension(groupMessage.mediaFileId.extension, groupMessage.mediaFileId._id)}
              </div>
            ) : null}
            <div id={`text-component-${groupMessage._id}`}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a className="chat-link text-info" target="blank" style={{ textDecoration: "underline" }} rel="noopener" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {groupMessage.messageText ? parse(decryptGroupChatMessage(groupMessage.messageText)) : null}
              </Linkify>
            </div>
            {groupMessage.preMessageId != null && groupMessage.preMessageId._id != null && groupMessage.preMessageId._id != "" ? (
              groupMessage.preMessageId.messageStatus != null && groupMessage.preMessageId.messageStatus == "DELETED" ? (
                <div className={`border-start border-5 border-primary ps-2`}>
                  <div className="">
                    {groupMessage.preMessageId.createdBy == user?._id ? "You" : groupMessage.preMessageId.firstname ?? "Member"}
                    {" deleted this message"}
                  </div>
                </div>
              ) : (
                <div className={`border-start border-5 border-primary ps-2`}>
                  <div className="conversation-name text-success">
                    {groupMessage.preMessageId.createdBy == user?._id ? "You" : groupMessage.preMessageId.firstname ?? "Member"}
                  </div>
                  <p>{groupMessage.preMessageId.messageText ? parse(decryptGroupChatMessage(groupMessage.preMessageId.messageText)) : ""}</p>

                  {groupMessage.preMessageId.mediaFileId != null &&
                  groupMessage.preMessageId.mediaFileId._id != null &&
                  groupMessage.preMessageId.mediaFileId._id != "" &&
                  groupMessage.preMessageId.mediaFileId.extension != null &&
                  groupMessage.preMessageId.mediaFileId.extension != "" ? (
                    <div className="mb-2 cursor-pointer" onClick={() => window.open(Util.fileURL(groupMessage.preMessageId?.mediaFileId?._id))}>
                      {getPreviewByExtension(groupMessage.preMessageId.mediaFileId.extension, groupMessage.preMessageId.mediaFileId._id)}
                    </div>
                  ) : null}
                </div>
              )
            ) : null}
            <p className="chat-time mb-0 align-items-center d-flex mt-0">
              {/* {message?.senderId?._id != currentUser?._id ? (
              <>{message?.isMsgSeen ? <i className="bx bx-check-double text-info me-1 font-size-18"></i> : <i className="bx bx-check me-1 font-size-18"></i>}</>
            ) : null} */}

              {moment(groupMessage.createdAt).fromNow()}
            </p>
          </div>
        </div>
      )}
    </li>
  );
};

export default DisplayGroupMessageComp;
