import React from "react";
import { InputGroup, Input, InputGroupText, Row, Col, Button } from "reactstrap";

interface SearchComponentProps {
  searchFilesAndFolder: () => Promise<void>;
  handleSearchString: (name: string) => void;
  searchString: string
}

const SearchComponent: React.FC<SearchComponentProps> = ({ searchFilesAndFolder, handleSearchString, searchString }) => {
  return (
    <div className="d-flex flex-row align-items-end justify-content-between gap-2 py-2 col-md-5">
      <Col>
        <Row>
          <InputGroup>
            <Input placeholder="Search" value={searchString} onChange={e => handleSearchString(e.target.value)} className="p-3 " />
            <Button className="p-3" onClick={searchFilesAndFolder}>
              🔍
            </Button>
          </InputGroup>
        </Row>
      </Col>
      {/* <Button color="primary" className="p-3">
        Share
      </Button> */}
    </div>
  );
};

export default SearchComponent;
