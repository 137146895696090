import React, { useContext, useState } from "react";
import { Card, Col, Row, CardBody, CardFooter, CardHeader, Label, Modal, Input, UncontrolledTooltip } from 'reactstrap';
import { HomeWork, HStatus } from '../../../models/HomeWork';
import moment from 'moment';
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import ico_file from "../../../assets/images/ico-file.png";
import ico_img from "../../../assets/images/ico-img.png";
import UserContext from "../../../context/UserContext";
import { Role } from "../../../models/Role";
import { HomeworkService } from '../../../services/HomeworkService';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../../models/Notification";
import { NotificationService } from "../../../services/NotificationService";
import { SocketContext } from "../../../context/ScoketContext";
import { useParams } from "react-router-dom";
import { ParameterTypes } from "../../../utils/ParameterTypes";
import { Util } from '../../../Util';
import { sendNotificationSocketData } from "../../../models/sendNotificationCallSocketData";
import Spinner from "../../../common/spinner/spinner";

interface HProps {
    homeworks: HomeWork[];
    homework?: any;
    editModal?: any;
    refreshList?: any;
    activeTab?: any;
    seeMoreBtn?: any,
    seeMoreBtn2?: any,
    homeworkCounts?: any,
    isLoadings?: any
}

const HomeWorks: React.FC<HProps> = ({
    homeworks,
    homework,
    editModal,
    refreshList,
    activeTab,
    seeMoreBtn,
    seeMoreBtn2,
    homeworkCounts,
    isLoadings
}) => {
    const { userId } = useParams<ParameterTypes>();
    const [modal_center, setModalCenter] = React.useState(false);
    const [modal_attachments, setModalAttachments] = React.useState(false);
    const [loggedUser] = useContext(UserContext);
    const [homeworkFile, setHomeworkFile] = useState({ preview: "", preview2: "", raw: [] as any });
    const [isHomeworkDetails, setIsHomeworkDetails] = useState({} as HomeWork); // to get homework details
    const [isDelete, setIsDelete] = useState([] as any);
    const socket = useContext(SocketContext);

    function togCenterHomeWorkUploads() {
        setModalCenter(!modal_center);
    }

    function togUploadAttachments() {
        const data: HomeWork = {
            _id: isHomeworkDetails._id,
            title: isHomeworkDetails.title,
            description: isHomeworkDetails.description,
            uploads: isHomeworkDetails.uploads,
            dueDate: isHomeworkDetails.dueDate,
            isComplete: true,
            createdBy: isHomeworkDetails.createdBy,
            assignedFor: isHomeworkDetails.assignedFor,
            status: HStatus.FINISHED,
            deletingUploadIds: isHomeworkDetails.deletingUploadIds || []
        }

        HomeworkService.updateHomework(data, homeworkFile.raw).then(res => {
            if (res.success) {
                toast.success('Updated Successfully', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                refreshList(res.success);
                setModalCenter(!modal_center);
                setIsHomeworkDetails({} as HomeWork);
                const homeworkNotification: NotificationModel = {
                    senderId: loggedUser?._id,
                    receiverId: userId,
                    event: NotificationEvent.HOMEWORK_APPROVED,
                    link: "/chat/" + userId,
                    content: `Homework ${HStatus.FINISHED} by ` + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                    variant: NotificationVarient.INFO
                }

                NotificationService.sendNotification(homeworkNotification).then(res => {
                    const socketData: sendNotificationSocketData = {
                        socketId: res.data.socketId,
                        notifyData: homeworkNotification,
                        senderId: loggedUser?._id,
                        receiverId: userId,
                    };

                    socket.emit("send-notification", socketData);
                });
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        })
    }

    const remove_underscore = (text: string) => {
        return text.replaceAll('_', ' ');
    }

    const checkOverDueDate = (dueDate: Date) => {
        const toDay = new Date().getTime();
        
        if (dueDate.getTime() > toDay)
            return <span>DUE DATE:</span>;
        else
            return <span>OVER DUE:</span>;
    }

    const editHomework = (id: any) => {
        HomeworkService.getHomeworkById(id).then(res => {
            if (res.success) {
                homework(res.data);
                editModal(true);
            }
        });
    }

    const deleteHomework = (id: any) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
        }).then(result => {
            if (result.isConfirmed) {
                HomeworkService.deleteHomework(id).then(res => {
                    if (res.success) {
                        refreshList(res.success);

                        toast.success('Deleted!', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });

                        const homeworkNotification: NotificationModel = {
                            senderId: loggedUser?._id,
                            receiverId: userId,
                            event: NotificationEvent.HOMEWORK_UPDATED,
                            link: "/chat/" + userId,
                            content: "Homework deleted by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                            variant: NotificationVarient.WARNING
                        }

                        NotificationService.sendNotification(homeworkNotification).then(res => {
                            const socketData: sendNotificationSocketData = {
                                socketId: res.data.socketId,
                                notifyData: homeworkNotification,
                                senderId: loggedUser?._id,
                                receiverId: userId,
                            };

                            socket.emit("send-notification", socketData);
                        });

                    } else {
                        Swal.fire({
                            icon: "error",
                            title: res.error ? res.error : "Something went wrong. Please try again later.",
                            confirmButtonColor: "#FD7F00",
                        });
                    }
                });
            }
        });

    }

    const submitHomeworkFile = (e: any) => {
        if (e.target.files.length && e.target.files.length <= 3) {
            setHomeworkFile({
                preview: URL.createObjectURL(e.target.files[0]) as any,
                preview2: e.target.files[0],
                raw: [...e.target.files]
            });
        } else {
            toast.error('You can select maximum 3 files!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const deletedItemsChecked = (id: string) => {
        if (isDelete.includes(id))
            return 'delete-rw';
        else
            return ' '
    }

    const addDeleteArr = (id: any) => {
        if (!isDelete.includes(id))
            isDelete.push(id);
        else
            isDelete.pop(id);
        setIsHomeworkDetails({ ...isHomeworkDetails, deletingUploadIds: isDelete });
    }
    // befor save
    const removeSelectedFile = (i: number) => {
        homeworkFile.raw.pop(i);
    }


    return (
        <React.Fragment>
            <>
                {homeworks.length > 0 ? (
                        <Row >
                            {homeworks.map((homework: HomeWork, index: number) => {
                                    return (
                                        <Col lg={`${loggedUser?.role == Role.CLIENT && 12} ${loggedUser?.role == Role.THERAPIST && 12}`} key={index}>
                                            <Card>
                                                <CardHeader>
                                                    <div className="header-space">
                                                        <h6>{homework.title}</h6>
                                                        <span className={` staus-bg`} style={{ backgroundColor: `${Util.statusColor(homework.status)}`, color: `${Util.statusFontColor(homework.status)}` }}>{homework.status}</span>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="description">
                                                        <Label>Description of Homework:</Label>
                                                        <p>{homework.description}</p>
                                                    </div>
                                                    <div className="attachment">
                                                        {
                                                            homework.uploads.map((upload: any, i: number) => {
                                                                return (
                                                                    <p className="hw-attach" key={i} id="up" >
                                                                        <i className='bx bx-paperclip' ></i>&nbsp;<span onClick={() => window.open(Util.fileURL(upload._id), "_blank")}>{upload.originalName}</span>
                                                                    </p>
                                                                );
                                                            })
                                                        }

                                                    </div>
                                                </CardBody>
                                                <CardFooter>
                                                    <div className="hw-footer-item">
                                                        <p className="hw-footer"> {checkOverDueDate(new Date(homework.dueDate))}&nbsp;{moment(homework.dueDate).format('L')}</p>
                                                        <div>
                                                            {
                                                                loggedUser?.role == Role.CLIENT &&
                                                                <button disabled={homework.status === HStatus.APPROVED} className={`${homework.isComplete && 'footer-active-btn'} btn-tick green-38a mr-5`} onClick={() => { loggedUser?.role == Role.CLIENT && togCenterHomeWorkUploads(); setIsHomeworkDetails(homework) }}><i className='bx bx-check'></i></button>
                                                            }
                                                            {
                                                                loggedUser?.role == Role.THERAPIST &&
                                                                <>
                                                                    <button className="btn-tick green-38a mr-5" onClick={() => deleteHomework(homework?._id)}><i className='bx bx-trash'></i></button>
                                                                    <button className="btn-tick green-38a " onClick={() => { editHomework(homework?._id); activeTab('2') }}><i className='bx bx-edit'></i></button>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }

                            {loggedUser?.role == Role.CLIENT &&
                                homeworkCounts >= 9 && (
                                    <>
                                        <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMoreBtn}>
                                            See More
                                        </button>
                                    </>
                                )
                            }

                            {loggedUser?.role == Role.THERAPIST &&
                                homeworkCounts >= 9 && (
                                    <>
                                        <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMoreBtn2}>
                                            See More
                                        </button>
                                    </>
                                )
                            }
                        </Row>
                    ) : (
                        <>
                            {isLoadings ? (
                                    <Spinner className="bouncer2" />
                                ) : (
                                    <div className="text-reset notification-item no-notifications">
                                        Homework not found !
                                    </div>
                                )
                            }
                        </>
                    )
                }

            </>

            <Modal
                isOpen={modal_center}
                toggle={() => {
                    togCenterHomeWorkUploads();
                }}
                centered
            >
                <div className="modal-header">
                    <h4 className="modal-title mt-0">Finished homework</h4>
                    <button
                        type="button"
                        onClick={() => {
                            setModalCenter(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>Do you want to hand in this homework?
                                Add the required files.</p>

                            <label htmlFor="file-upload1" style={{ border: '1px dashed' }} className="text-left mb-4 file_upload file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                <div className="flex flex-wrap items-center pl-5 pt-4 pb-1 file_upload_content">
                                    <img src={uploadIcon} />
                                    <p className="m-0 text-black">&nbsp;&nbsp;Upload Homework Files</p>
                                </div>
                                <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                <input id="file-upload1"
                                    type="file"
                                    style={{ display: "none" }}
                                    className="file_upload_input mb-4"
                                    accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                    onChange={submitHomeworkFile}
                                    multiple />
                            </label>

                            <div className="uploads">
                                {
                                    isHomeworkDetails?._id && isHomeworkDetails?.uploads.map((upload: any, i: number) => {
                                        if (upload.category !== 'THERAPIST_HOMEWORK_DOCUMENTS') {
                                            return (
                                                <p className={`hw-attach ${deletedItemsChecked(upload._id)}`} key={i} id="up" >
                                                    <i className='bx bx-trash delete-icon' id="ico" onClick={() => addDeleteArr(upload._id)} ></i>&nbsp;<span onClick={() => window.open(Util.fileURL(upload._id), "_blank")}>{upload.originalName}</span>
                                                    <UncontrolledTooltip target="ico">
                                                        Delete File
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip target="up">
                                                        {remove_underscore(upload.category)}
                                                    </UncontrolledTooltip>
                                                </p>
                                            );
                                        }
                                    })
                                }
                                <br />
                                <div className="uploads">
                                    {
                                        homeworkFile.raw.map((file: any, i: number) => {
                                            return (
                                                <p className={`hw-attach`} key={i} >
                                                    <i className='bx bx-paperclip'></i>&nbsp;<span onClick={() => { window.open(URL.createObjectURL(file) as any, "_blank"); }}>{file.name}</span>
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-md-12">
                            <button
                                type="button"
                                className="btn btn-primary  relative mt-3 mb-4 m-2" style={{
                                    bottom: "0",
                                }}
                                onClick={() => togUploadAttachments()}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-light relative mt-3 mb-4 m-2" style={{
                                    bottom: "0",
                                }}
                                onClick={() => togCenterHomeWorkUploads()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    )
}

export default HomeWorks;