import React from "react";
import { Modal, Button, ModalBody } from "reactstrap";
import Close from "../../assets/images/icons/close.png";
import moment from "moment";
import { AINotesType } from "src/models/Therapist";

const DiagnosisNoteVersionModal: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void; diagnosisNoteDataTherapist: any; diagnosisNoteDataAdmin: any; showDiagnosisFunction: (diagnosisNoteId: string, totalSpentDuration: number, noteTypeFrom: string, isVersion: boolean ) => void; isAdmin: boolean; showDiagnosis: (diagnosis: any, isVersion: boolean) => void; handleClose: () => void; }> = props => {
  
  const { showDiagnosisFunction, isAdmin, showDiagnosis, handleClose } = props;

  const diagnosisNoteData = isAdmin ? props.diagnosisNoteDataAdmin : props.diagnosisNoteDataTherapist;

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };
  
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered className="modal-md">
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={() => {
              props.setShowModal(false);
              handleClose();
            }}  
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
            className="btn-s-20"
          >
            <img src={Close} alt="Close" className="mt-1" />
          </Button>
        </div>

        {!isAdmin ? (
          <ModalBody className="p-4">
            <h2 className="text-center mb-4">Diagnosis Note With Amendments</h2>

            <div className="mb-4 bg-warning rounded p-3 shadow-sm">
              <h5 className="font-weight-bold text-dark">Main Diagnosis Note</h5>
              <span className="text-muted small">Created date: <b>{formatDate(diagnosisNoteData?.createdAt)}</b></span><br />

              <div className="mb-1 mt-2">
                <Button
                  color="success"
                  onClick={() => {
                    let totalSpentDuration = 0;
                    if (diagnosisNoteData?.mergedMeetings && diagnosisNoteData?.mergedMeetings?.length > 0) {
                      diagnosisNoteData?.mergedMeetings.map((v: any) => {
                        totalSpentDuration = totalSpentDuration + v?.spentDuration;
                      });
                    } else {
                      totalSpentDuration = diagnosisNoteData?.meetingId?.spentDuration;
                    }
                    showDiagnosisFunction(diagnosisNoteData?.diagnosisNoteId, totalSpentDuration, diagnosisNoteData?.noteType ?? AINotesType.SOAP, false);
                    }
                  }
                  className="mr-2 btn-sm shadow-sm">
                  <i className="fa fa-file mr-1"></i> View / Edit Note
                </Button>
              </div>
            </div>

            {diagnosisNoteData?.diagnosisNoteVersions && diagnosisNoteData?.diagnosisNoteVersions?.length && diagnosisNoteData?.diagnosisNoteVersions?.length > 0 ? (
              diagnosisNoteData?.diagnosisNoteVersions.map((plan: any, planIndex: number) => (
                <div key={plan?._id} className="mb-4 bg-light rounded p-3 shadow-sm">
                  <h5 className="font-weight-bold text-dark">Amendments {planIndex + 1}</h5>
                  <span className="text-muted small">Created date: <b>{formatDate(plan?.versionCreatedAt)}</b></span><br />
                  <span className="text-muted small">Amendment modified due to: <b>{plan?.reasonForEdit}</b></span><br />

                  <div className="therapy-plan-buttons mb-1 mt-2">
                    <Button color="success" onClick={() => {
                          let totalSpentDuration = 0;

                          if (diagnosisNoteData?.mergedMeetings && diagnosisNoteData?.mergedMeetings?.length > 0) {
                            diagnosisNoteData?.mergedMeetings.map((v: any) => {
                              totalSpentDuration = totalSpentDuration + v?.spentDuration;
                            });
                          } else {
                            totalSpentDuration = diagnosisNoteData?.meetingId?.spentDuration;
                          }
                          showDiagnosisFunction(plan?._id, 0, plan?.noteType, true);
                      
                      } } className="mr-2 btn-sm shadow-sm">
                      <i className="fa fa-file mr-1"></i> View
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-muted font-italic">No diagnosis note available.</p>
            )}
          </ModalBody>
        ) :
          <ModalBody className="p-4">
            <h2 className="text-center mb-4">Diagnosis Note With Amendments</h2>

            <div className="mb-4 bg-warning rounded p-3 shadow-sm">
              <h5 className="font-weight-bold text-dark">Main Diagnosis Note</h5>
              <span className="text-muted small">Created date: <b>{ formatDate(diagnosisNoteData?.diagnosisnote?.createdAt) }</b></span><br />

              <div className="mb-1 mt-2">
                <Button
                  color="success"
                  onClick={() => {
                    showDiagnosis(diagnosisNoteData?.diagnosisnote, false);
                  }}
                  className="mr-2 btn-sm shadow-sm">
                  <i className="fa fa-file mr-1"></i> View / Edit Note
                </Button>
              </div>
            </div>

            {diagnosisNoteData?.diagnosisNoteVersions && diagnosisNoteData?.diagnosisNoteVersions?.length && diagnosisNoteData?.diagnosisNoteVersions?.length > 0 ? (
              diagnosisNoteData?.diagnosisNoteVersions.map((plan: any, planIndex: number) => (
                <div key={plan?._id} className="mb-4 bg-light rounded p-3 shadow-sm">
                  <h5 className="font-weight-bold text-dark">Amendments {planIndex + 1}</h5>
                  <span className="text-muted small">Created date: <b>{formatDate(plan?.versionCreatedAt)}</b></span><br />
                  <span className="text-muted small">Amendment modified due to: <b>{plan?.reasonForEdit}</b></span><br />

                  <div className="therapy-plan-buttons mb-1 mt-2">
                    <Button
                      color="success"
                      onClick={() => {
                        showDiagnosis(plan, true);
                      }}
                      className="mr-2 btn-sm shadow-sm">
                      <i className="fa fa-file mr-1"></i> View
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-muted font-italic">No diagnosis note available.</p>
            )}
          </ModalBody>
        }
      </Modal>
    </>
  ) : null;
};

export default DiagnosisNoteVersionModal;
