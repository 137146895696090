import React, { useContext, useEffect, useState } from "react";
import { Input, Label, Modal, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import UserContext from "../../context/UserContext";
import { Goal } from "../../models/Goal";
import { HomeWork, HStatus } from '../../models/HomeWork';
import { Role } from "../../models/Role";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { HomeworkService } from '../../services/HomeworkService';
import { useParams } from "react-router-dom";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { toast } from "react-toastify";
import { DueDate } from '../../models/subModel/DueDate';
import { GoalService } from "../../services/GoalService";
import { NotificationModel, NotificationEvent, NotificationVarient } from '../../models/Notification';
import { NotificationService } from '../../services/NotificationService';
import { SocketContext } from "../../context/ScoketContext";
const classNames = require('classnames');
import moment from "moment";
import HomeWorks from "./homeworkandgoals/Homework";
import Goals from "./homeworkandgoals/Goals";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";

interface HGProps {
    isOpened: boolean;
    returnBool: any;
    userId:string
}

const HomeWorksAndGoals: React.FC<HGProps> = ({
    isOpened,
    returnBool,
    userId
}) => {
    const [loggedUser] = useContext(UserContext);
    const [activeTab, setactiveTab] = useState("1");
    const [activeModalTab, setActiveModalTab] = useState("1");
    const [isHGModal, setHGModal] = React.useState(false);
    const [isUpdate, setUpdate] = React.useState(false);
    const [isHomeworkDetails, setIsHomeworkDetails] = useState({} as HomeWork); // to get homework details
    const [isGoalDetails, setIsGoalDetails] = useState({} as Goal); // to get goal detaisl
    const [homeworkFile, setHomeworkFile] = useState({ preview: "", preview2: "", raw: [] as any });
    const [isAllHomeworks, setIsAllHomeworks] = useState([] as HomeWork[]);
    const [isAllGoals, setIsAllGoals] = useState([] as Goal[]);
    const [homeWorkCount, setHomeWorkCount] = useState(9);
    const [goalCount, setGoalCount] = useState(8);
    const [offsetGoal, setOffsetGoal] = useState(1);
    const limitGoal = 8;
    const limit = 9;
    const [offset, setOffset] = useState(1);
    const [isDelete] = useState([] as any);
    const socket = useContext(SocketContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkLoggedUser();
    }, []);

    const checkLoggedUser = () => {
        switch (loggedUser!.role) {
            case Role.THERAPIST:
                getAllHomeworksByTherapis();
                getAllGoalsByTherapist();
                break;
            case Role.CLIENT:
                getAllHomeworksByClient();
                getAllGoalsByClient();
                break;
        }
    }

    const getAllGoalsByTherapist = () => {
        setIsLoading(true);
        GoalService.getAllGoalById(loggedUser?._id as string, userId, limitGoal, 1).then(res => {
            if (res.success) {
                setIsAllGoals(res.data);
                setGoalCount(res.data?.length);
            }
            setIsLoading(false);
        });
    }

    const getAllGoalsByClient = () => {
        setIsLoading(true);
        GoalService.getAllGoalById(userId, loggedUser?._id as string, limitGoal, 1).then(res => {
            if (res.success) {
                setIsAllGoals(res.data);
                setGoalCount(res.data?.length);
            }
            setIsLoading(false);
        });
    }

    const getAllHomeworksByTherapis = () => {
        setIsLoading(true);
        HomeworkService.getAllHomeworksByTherapist(userId, limit, 1).then(res => {
            if (res.success) {
                setIsAllHomeworks(res.data)
                setHomeWorkCount(res.data?.length);
            }
            setIsLoading(false);
        });
    }

    const getAllHomeworksByClient = () => {
        setIsLoading(true);
        HomeworkService.getAllHomeworksByClient(userId, limit, 1).then(res => {
            if (res.success) {
                setIsAllHomeworks(res.data)
                setHomeWorkCount(res.data?.length);
            }
            setIsLoading(false);
        });
    }

    const seeMore = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);

        setIsLoading(true);

        HomeworkService.getAllHomeworksByClient(userId, 9, updatedSkip).then(res => {
            if (res.success) {
                res.data.map((homework: any) => {
                    setIsAllHomeworks(isAllHomeworks => [...isAllHomeworks, homework]);
                });
                setHomeWorkCount(res.data?.length);
            }
            setIsLoading(false);
        });
    };

    const seeMore2 = () => {
        const updatedSkip = offset + 1;

        setOffset(updatedSkip);

        setIsLoading(true);
        HomeworkService.getAllHomeworksByTherapist(userId, 9, updatedSkip).then(res => {
            if (res.success) {
                res.data.map((homework: any) => {
                    setIsAllHomeworks(isAllHomeworks => [...isAllHomeworks, homework]);
                });
                setHomeWorkCount(res.data?.length);
            }
            setIsLoading(false);
        });
    };

    const seeMoreGoal = () => {
        const updatedSkip = offsetGoal + 1;

        setOffsetGoal(updatedSkip);

        setIsLoading(true);
        GoalService.getAllGoalById(userId, loggedUser?._id as string, 8, updatedSkip).then(res => {
            if (res.success) {
                res.data.map((goal: any) => {
                    setIsAllGoals(isAllGoals => [...isAllGoals, goal]);
                });
                setGoalCount(res.data?.length);
            }
            setIsLoading(false);
        });
    };

    const seeMoreGoal2 = () => {
        const updatedSkip = offsetGoal + 1;

        setOffsetGoal(updatedSkip);

        setIsLoading(true);
        GoalService.getAllGoalById(loggedUser?._id as string, userId, 8, updatedSkip).then(res => {
            if (res.success) {
                res.data.map((goal: any) => {
                    setIsAllGoals(isAllGoals => [...isAllGoals, goal]);
                });
                setGoalCount(res.data?.length);
            }
            setIsLoading(false);
        });
    };


    const toggleHGCanvas = () => {
        setUpdate(false);
        setIsHomeworkDetails({} as HomeWork);
        setIsGoalDetails({} as Goal);
        return returnBool(false);
    }

    const HGModalOpen = () => {
        setHGModal(!isHGModal);
        setIsHomeworkDetails({} as HomeWork);
        setIsGoalDetails({} as Goal);
        setUpdate(false);
    }

    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    const modaleTabView = (tab: any) => {
        if (activeModalTab !== tab) {
            setActiveModalTab(tab);
        }
    };

    const selectHomeworkFile = (e: any) => {
        if (e.target.files.length && e.target.files.length <= 3) {
            setHomeworkFile({
                preview: URL.createObjectURL(e.target.files[0]) as any,
                preview2: e.target.files[0],
                raw: [...e.target.files]
            });
        } else {
            toast.error('You can select maximum 3 files!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const homeworkSubmit = () => {
        const data = {
            title: isHomeworkDetails.title,
            description: isHomeworkDetails.description,
            dueDate: isHomeworkDetails.dueDate,
            isComplete: false,
            createdBy: loggedUser?._id,
            assignedFor: userId,
            status: isHomeworkDetails.status,
        }

        HomeworkService.createHomework(data, homeworkFile.raw).then(res => {
            if (res?.success) {
                toast.success('Created Successfully', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                checkLoggedUser();
                HGModalOpen();
                setIsHomeworkDetails({} as HomeWork);
                setHomeworkFile({ preview: "", preview2: "", raw: [] as any });
                const homeworkNotification: NotificationModel = {
                    senderId: loggedUser?._id,
                    receiverId: userId,
                    event: NotificationEvent.GOAL_ASSIGNED,
                    link: "/chat/" + userId,
                    content: "Homework is Created by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                    variant: NotificationVarient.INFO
                }

                NotificationService.sendNotification(homeworkNotification).then(res => {
                    const socketData: sendNotificationSocketData = {
                        socketId: res.data.socketId,
                        notifyData: homeworkNotification,
                        senderId: loggedUser?._id,
                        receiverId: userId,
                        
                    };

                    socket.emit("send-notification", socketData);
                });
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    }

    const goalSubmit = () => {
        try {
            if (loggedUser?.role == Role.THERAPIST) {
                const data: Goal = {
                    title: isGoalDetails.title,
                    description: isGoalDetails.description,
                    dueDate: isGoalDetails.dueDate,
                    prograss: 0,
                    completedDates: GetDiffDateUsingDueDate(isGoalDetails.dueDate),
                    isComplete: false,
                    createdBy: loggedUser?._id,
                    assignedFor: userId
                }

                GoalService.createGoal(data).then(res => {
                    if (res.success) {
                        setIsGoalDetails({} as Goal);
                        checkLoggedUser();
                        HGModalOpen();
                        toast.error(res.success, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        const goalNotification: NotificationModel = {
                            senderId: loggedUser?._id,
                            receiverId: userId,
                            event: NotificationEvent.GOAL_ASSIGNED,
                            link: "/chat/" + userId,
                            content: "Goal is Created by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                            variant: NotificationVarient.INFO
                        }

                        NotificationService.sendNotification(goalNotification).then(res => {
                            const socketData: sendNotificationSocketData = {
                                socketId: res.data.socketId,
                                notifyData: goalNotification,
                                senderId: loggedUser?._id,
                                receiverId: userId,
                            };

                            socket.emit("send-notification", socketData);
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                });
            }

            if (loggedUser?.role == Role.CLIENT) {
                const data: Goal = {
                    title: isGoalDetails.title,
                    description: isGoalDetails.description,
                    dueDate: isGoalDetails.dueDate,
                    prograss: 0,
                    completedDates: GetDiffDateUsingDueDate(isGoalDetails.dueDate),
                    isComplete: false,
                    createdBy: userId,
                    assignedFor: loggedUser?._id
                }
                GoalService.createGoal(data).then(res => {
                    if (res.success) {
                        setIsGoalDetails({} as Goal);
                        checkLoggedUser();
                        HGModalOpen();
                        toast.error(res.success, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        const goalNotification: NotificationModel = {
                            senderId: loggedUser?._id,
                            receiverId: userId,
                            event: NotificationEvent.GOAL_ASSIGNED,
                            link: "/chat/" + userId,
                            content: "Goal is Created by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                            variant: NotificationVarient.INFO
                        }

                        NotificationService.sendNotification(goalNotification).then(res => {
                            const socketData: sendNotificationSocketData = {
                                socketId: res.data.socketId,
                                notifyData: goalNotification,
                                senderId: loggedUser?._id,
                                receiverId: userId,
                            };

                            socket.emit("send-notification", socketData);
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                });
            }
        } catch (error) {
            toast.error(`Something went wrong!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    // to get dates between created date and due date...
    const GetDiffDateUsingDueDate = (dueDate: string) => {
        let start = new Date();
        let end = new Date(dueDate);
        const newend = end.setDate(end.getDate());
        end = new Date(newend);

        const dateRanges: DueDate[] = [];

        while (start < end) {
            const newDate = start.setDate(start.getDate() + 1);
            start = new Date(newDate);
            const obj: DueDate = {
                date: start.toDateString(),
                isComplete: false
            }
            dateRanges.push(obj);
        }

        return dateRanges;
    }

    // to update homework
    const homeworkUpdate = () => {
        const data = {
            _id: isHomeworkDetails._id,
            title: isHomeworkDetails.title,
            description: isHomeworkDetails.description,
            dueDate: isHomeworkDetails.dueDate,
            isComplete: false,
            createdBy: loggedUser?._id,
            assignedFor: userId,
            status: isHomeworkDetails.status,
            uploads: isHomeworkDetails.uploads,
            deletingUploadIds: isHomeworkDetails.deletingUploadIds || []
        }
        HomeworkService.updateHomework(data, homeworkFile.raw).then(res => {
            if (res.success) {
                toast.success('Updated Successfully', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                checkLoggedUser();
                HGModalOpen();
                setIsHomeworkDetails({} as HomeWork);
                setHomeworkFile({ preview: "", preview2: "", raw: [] as any });

                const homeworkNotification: NotificationModel = {
                    senderId: loggedUser?._id,
                    receiverId: userId,
                    event: NotificationEvent.GOAL_UPDATED,
                    link: "/chat/" + userId,
                    content: "Homework Updated by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                    variant: NotificationVarient.INFO
                }

                NotificationService.sendNotification(homeworkNotification).then(res => {
                    const socketData: sendNotificationSocketData = {
                        socketId: res.data.socketId,
                        notifyData: homeworkNotification,
                        senderId: loggedUser?._id,
                        receiverId: userId,
                    };

                    socket.emit("send-notification", socketData);
                });

            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        })
    }

    // to update goal
    const goalUpdate = () => {
        const data = {
            _id: isGoalDetails._id,
            title: isGoalDetails.title,
            description: isGoalDetails.description,
            dueDate: isGoalDetails.dueDate,
            prograss: 0,
            completedDates: GetDiffDateUsingDueDate(isGoalDetails.dueDate),
            isComplete: false,
            createdBy: isGoalDetails.createdBy,
            assignedFor: isGoalDetails.assignedFor,
        }

        GoalService.updateGoal(isGoalDetails._id as string, data).then(res => {
            if (res.success) {
                toast.success('Updated Successfully', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                checkLoggedUser();
                HGModalOpen();
                setIsGoalDetails({} as Goal);

                const goalNotification: NotificationModel = {
                    senderId: loggedUser?._id,
                    receiverId: userId,
                    event: NotificationEvent.GOAL_UPDATED,
                    link: "/chat/" + userId,
                    content: "Goal Updated by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                    variant: NotificationVarient.INFO
                }

                NotificationService.sendNotification(goalNotification).then(res => {
                    const socketData: sendNotificationSocketData = {
                        socketId: res.data.socketId,
                        notifyData: goalNotification,
                        senderId: loggedUser?._id,
                        receiverId: userId,
                    };

                    socket.emit("send-notification", socketData);
                });
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    }

    const remove_underscore = (text: string) => {
        return text.replaceAll('_', ' ');
    }

    const addDeleteArr = (id: any) => {
        if (!isDelete.includes(id))
            isDelete.push(id);
        else
            isDelete.pop(id);
        setIsHomeworkDetails({ ...isHomeworkDetails, deletingUploadIds: isDelete });
    }

    const deletedItemsChecked = (id: string) => {
        if (isDelete.includes(id))
            return 'delete-rw';
        else
            return ' '
    }

    const removeSelectedFile = (i: number) => {
        homeworkFile.raw.pop(i);
    }

    return (
        <>
            <Offcanvas isOpen={isOpened} direction="end" toggle={toggleHGCanvas} className={`${loggedUser?.role == Role.CLIENT && " goals-and-homework-canvas-client offcanvas-ends"} ${loggedUser?.role == Role.THERAPIST && " goals-and-homework-canvas-therapist offcanvas-ends"} `}>
                <OffcanvasHeader toggle={toggleHGCanvas}>
                    Homework and Goals
                </OffcanvasHeader>

                <OffcanvasBody>
                    <div className="row pt20 pl10" id="style-1">
                        <div className="col-md-12 col-sm-12">
                            <Nav pills justified>
                                <NavItem>
                                    <NavLink
                                        className={classNames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleTab("1");
                                            setActiveModalTab('2'); // should validate
                                        }}
                                    >
                                        <i className='bx bx-home  font-size-20 d-sm-none'></i>
                                        <span className="d-none d-sm-block">Homework</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classNames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleTab("2");
                                            setActiveModalTab('1'); // should validate
                                        }}
                                    >
                                        <i className='bx bx-football  font-size-20 d-sm-none' ></i>
                                        <span className="d-none d-sm-block">Goals</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                        <div className="col-md-6 right-side-option col-sm-12 create-goal">
                            <div className="create-goal-or-homework sm-mb5">
                                <a href="#" onClick={HGModalOpen}>{(loggedUser?.role == Role.CLIENT && activeTab === '2') && 'Create Goal'} {loggedUser?.role == Role.THERAPIST && 'Create Goal/ Homework'} {(loggedUser?.role == Role.CLIENT && activeTab === '2') && <i className='bx bx-plus-circle'></i>} {(loggedUser?.role == Role.THERAPIST) && <i className='bx bx-plus-circle'></i>}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            {(isAllHomeworks && isAllGoals) ? (
                                <TabContent activeTab={activeTab} className="py-4 px-3">
                                    <TabPane tabId="1">
                                        <HomeWorks homeworks={isAllHomeworks} refreshList={(e: boolean) => e && checkLoggedUser()} homework={(e: any) => setIsHomeworkDetails(e)} editModal={(e: any) => { setHGModal(e); setUpdate(e) }} activeTab={(e: any) => { setActiveModalTab(e) }} homeworkCounts={homeWorkCount} seeMoreBtn={seeMore} seeMoreBtn2={seeMore2} isLoadings={isLoading} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Goals goals={isAllGoals} refreshList={(e: boolean) => e && checkLoggedUser()} goal={(e: any) => setIsGoalDetails(e)} editModal={(e: any) => { setHGModal(e); setUpdate(e) }} activeTab={(e: any) => { setActiveModalTab(e) }} goalCounts={goalCount} seeMoreBtn={seeMoreGoal} seeMoreBtn2={seeMoreGoal2} isLoadings={isLoading}/>
                                    </TabPane>
                                </TabContent>
                            ) : null
                            }
                        </div>
                    </div>
                </OffcanvasBody>
            </Offcanvas>

            <Modal
                isOpen={isHGModal}
                toggle={() => {
                    HGModalOpen();
                }}
                centered>
                <div className="modal-header">
                    {
                        loggedUser?.role == Role.CLIENT ? (
                            <h4 className="modal-title mt-0">{isGoalDetails._id ? 'Update' : 'Create'} goal</h4>
                        ) : (
                            <h4 className="modal-title mt-0">{isHomeworkDetails._id ? 'Update' : 'Create'} goal or homework</h4>
                        )
                    }

                    <button
                        type="button"
                        onClick={() => {
                            setHGModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        loggedUser?.role == Role.THERAPIST && (
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex">
                                        <Nav pills justified>
                                            <NavItem>
                                                <NavLink
                                                    className={classNames({
                                                        active: activeModalTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        modaleTabView("1");
                                                    }}
                                                >
                                                    <i className="bx bx-chat font-size-20 d-sm-none" />
                                                    <span className="d-none d-sm-block">Goals</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classNames({
                                                        active: activeModalTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        modaleTabView("2");
                                                    }}
                                                >
                                                    <i className="bx bx-chat font-size-20 d-sm-none" />
                                                    <span className="d-none d-sm-block">Homework</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="row">
                        <div className="col">
                            <div className="tab-pane">
                                <TabContent activeTab={activeModalTab} className="py-4">
                                    <TabPane tabId="1">
                                        <div className="row mb-4">
                                            <div className="col">
                                                <Input type="text" placeholder="Title of Goal"
                                                    defaultValue={isGoalDetails.title}
                                                    onChange={(e) => setIsGoalDetails({ ...isGoalDetails, title: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <Input type="textarea" style={{ minHeight: "200px" }}
                                                    defaultValue={isGoalDetails.description}
                                                    onChange={(e) => setIsGoalDetails({ ...isGoalDetails, description: e.target.value })}
                                                    placeholder="Description of Goal" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-md-4"><Label>Due date</Label></div>
                                            <div className="col-md-8">
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="formrow-duedate-Input"
                                                    placeholder="Due Date"
                                                    value={isGoalDetails.dueDate}
                                                    onChange={(e) => setIsGoalDetails({ ...isGoalDetails, dueDate: e.target.value })}
                                                    required
                                                    min={moment().format("YYYY-MM-DD")}
                                                />
                                                {
                                                    isUpdate &&
                                                    <small>* If you update due date again current value will reset.</small>
                                                }
                                            </div>
                                        </div>

                                        <div className="row text-center">
                                            <div className="col-md-6">

                                                {
                                                    !isUpdate ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary  relative m-2 w100-full" style={{
                                                                bottom: "0",
                                                            }}
                                                            onClick={() => goalSubmit()}
                                                        > Save
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary  relative m-2 w100-full" style={{
                                                                bottom: "0",
                                                            }}
                                                            onClick={() => goalUpdate()}
                                                        > Update
                                                        </button>
                                                    )
                                                }

                                            </div>
                                            <div className="col-md-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-light relative m-2 w100-full" style={{
                                                        bottom: "0",
                                                    }}
                                                    onClick={HGModalOpen}
                                                > Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="row mb-4">
                                            <div className="col">
                                                <Input type="text" placeholder="Title of homework" defaultValue={isHomeworkDetails.title} onChange={(e) => setIsHomeworkDetails({ ...isHomeworkDetails, title: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <Input type="textarea" style={{ minHeight: "200px" }} defaultValue={isHomeworkDetails.description} onChange={(e) => setIsHomeworkDetails({ ...isHomeworkDetails, description: e.target.value })} placeholder="Description of homework" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <Input id="exampleSelect" name="select" type="select" defaultValue={isHomeworkDetails.status} onChange={(e) => setIsHomeworkDetails({ ...isHomeworkDetails, status: e.target.value })}>
                                                    <option value={HStatus.NEW}>NEW</option>
                                                    {
                                                        isHomeworkDetails._id && (
                                                            <>
                                                                <option value={HStatus.PENDING}>PENDING</option>
                                                                <option value={HStatus.FINISHED}>FINISHED</option>
                                                                <option value={HStatus.APPROVED}>APPROVED</option>
                                                                <option value={HStatus.REJECT}>REJECT</option>
                                                            </>
                                                        )
                                                    }

                                                </Input>
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="formrow-duedate-Input"
                                                    placeholder="Due Date"
                                                    defaultValue={isHomeworkDetails.dueDate}
                                                    onChange={(e) => setIsHomeworkDetails({ ...isHomeworkDetails, dueDate: e.target.value })}
                                                    required
                                                    min={moment().format("YYYY-MM-DD")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <label htmlFor="file-upload1" style={{ border: '1px dashed' }} className="text-left mb-4 file_upload file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                                    <div className="flex flex-wrap items-center pl-5 pt-4 pb-1 file_upload_content">
                                                        <img src={uploadIcon} />
                                                        <p className="m-0 text-black">&nbsp;&nbsp;Upload Homework Files</p>
                                                    </div>
                                                    <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                                    <input id="file-upload1"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        className="file_upload_input mb-4"
                                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                                        onChange={selectHomeworkFile}
                                                        multiple />
                                                </label>
                                                <div className="uploads">
                                                    {
                                                        homeworkFile.raw.map((file: any, i: number) => {
                                                            return (
                                                                <p className={`hw-attach`} key={i} >
                                                                    <i className='bx bx-paperclip'></i>&nbsp;<span onClick={() => { window.open(URL.createObjectURL(file) as any, "_blank"); }}>{file.name}</span>
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                <div className="uploads">
                                                    {
                                                        isHomeworkDetails?._id && isHomeworkDetails?.uploads.map((upload: any, i: number) => {
                                                            return (
                                                                <p className={`hw-attach ${deletedItemsChecked(upload._id)}`} key={i} id="up" >
                                                                    <i className='bx bx-trash delete-icon' id="ico" onClick={() => addDeleteArr(upload._id)} ></i>&nbsp;{upload.originalName}
                                                                    <UncontrolledTooltip target="ico">
                                                                        Delete File
                                                                    </UncontrolledTooltip>
                                                                </p>
                                                            );
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-md-6">
                                                {
                                                    !isUpdate ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary  relative m-2 w100-full" style={{
                                                                bottom: "0",
                                                            }}
                                                            onClick={homeworkSubmit}
                                                        >
                                                            Save
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary  relative m-2 w100-full" style={{
                                                                bottom: "0",
                                                            }}
                                                            onClick={homeworkUpdate}
                                                        >
                                                            Update
                                                        </button>
                                                    )
                                                }

                                            </div>
                                            <div className="col-md-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-light relative m-2 w100-full" style={{
                                                        bottom: "0",
                                                    }}
                                                    onClick={HGModalOpen}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>

                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HomeWorksAndGoals;