import React from 'react';
import { StepProps } from './types';

const Completed: React.FC<StepProps> = ({
  searchDocumentDownloadsByAdminSelected,
  onBack
}) => {
  return (
    <div className="text-center py-4">
      <div className="mb-4">
        <div className="success-checkmark">
          <i className="fas fa-check-circle fa-5x text-success"></i>
        </div>
      </div>

      <h5 className="mb-3">Successfully Completed!</h5>
      <p className="text-muted mb-4">
        Your audit PDF has been generated and sent successfully to the recipient.
      </p>

      <div className="card mb-4 mx-auto" style={{ maxWidth: '400px' }}>
        <div className="card-body">
          <h6 className="card-title mb-3">Transaction Summary</h6>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>Transaction ID:</td>
                <td className="text-end">#FAX-2024-001</td>
              </tr>
              <tr>
                <td>Sent Date:</td>
                <td className="text-end">{new Date().toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>Total Pages:</td>
                <td className="text-end">12</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td className="text-end">
                  <span className="badge bg-success">Completed</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="d-flex justify-content-center gap-2">
        <button className="btn btn-primary" onClick={onBack}>
          <i className="fas fa-plus me-2"></i>
          Generate Another
        </button>
        <button className="btn btn-outline-primary">
          <i className="fas fa-download me-2"></i>
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default Completed;
