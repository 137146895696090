import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Modal, CardBody, Input, Card, OffcanvasHeader } from "reactstrap";
import { TranscribeDetails } from "../../models/TranscribeDetails";
import { TreatmentHistory, TreatmentHistoryData } from "../../models/TreatmentHistory";
import { TherapistService } from "../../services/TherapistService";
import { toast } from "react-toastify";
import { VideoChatService } from "../../services/VideoChatService";
import images from "../../assets/images";
import DiagnosisNoteScreen from "../Chat/DiagnosisNote/DiagnosisNoteView";
import "../../assets/css/session_history.css";
import { Util } from "../../Util";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import Modal23 from "./Modal23";
import TranscribeHistoryCard from "../Chat/DiagnosisNote/TranscribeHistoryCard";
import { element } from "prop-types";
import Swal from "sweetalert2";
import Close from "../../assets/images/icons/u_times-circle.png";
import PIEDiagnosisNoteViewNew from "./../Chat/DiagnosisNote/PIEDiagnosisNoteViewNew";
import { NoteType } from "../../models/DiagnosisNote";
import NoteTypeSelectModal from "./NoteTypeSelectionModal";
import { useLocation } from "react-router-dom";

const SessionHistoryModal: React.FC<{
  showModal: boolean;
  userId: any;
  setShowModal: (value: boolean) => void;
}> = props => {
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(true);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [isTranscribeOpen, setTranscribeOpen] = useState(false);
  const [isTreatmentLazyLoading, setIsTreatmentLazyLoading] = useState(false);
  const [noteIdAdd, setNoteId] = useState("");
  const [showLazyLoadingButton, setShowLazyLoadingButton] = useState(true);
  const [isTranscribeLoading, setIsTranscribeLoading] = useState(false);
  const [isTranscribeError, setIsTranscribeError] = useState(false);
  const [transcribe, setTranscribe] = useState<TranscribeDetails>();
  const [transcribeTextList, setTranscribeTextList] = useState<string[] | []>([]);
  const [limit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(1);
  const [searchTextForTranscribe, setSearchTextForTranscribe] = useState("");
  const [modalCenter, setModalCenter] = useState(false);
  const [isCreating] = useState(false);
  const [singleTreatmentHistoryData, setSingleTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [treatmentHistoryData, setTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [user, setUser] = useContext(UserContext);
  const [showRecording, setShowRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [isDiagnosisNoteOpen, setIsDiagnosisNoteOpen] = useState(false);
  const [isDiagnosisLoading, setIsDiagnosisLoading] = useState(false);
  const [isDiagnosisError, setIsDiagnosisError] = useState(false);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [totalSpentDuration, setTotalSpentDuration] = useState(0);
  const [selectedTreatmentHistoryForMerge, setSelectedTreatmentHistoryForMerge] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [noteType, setNoteType] = useState<string | undefined>();
  const [isNoteUpdateByTherapist, setIsNoteUpdateTherapist] = useState(false);
  const [showTypeSelectModal, setShowTypeSelectModal] = useState(false);
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const [showPIENote, setShowPIENote] = useState(false);
  const [noteTypeModalOpen, setNoteTypeModalOpen] = useState(false);
  const [isVersion, setIsVersion] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsTreatmentError(false);
    setIsTreatmentLoading(true);

    {
      user?.role == Role.THERAPIST && getTreatmentHistoryNotesInitial(props.userId);
    }

    {
      user?.role == Role.CLIENT && getTreatmentHistoryNotesInitialByTherapistId(props.userId);
    }
  }, []);

  useEffect(() => {
    if (diagnosisId != "") {
      setIsDiagnosisNoteOpen(true);
    }
  }, [diagnosisId]);


  function showDiagnosis(diagnosisId: string, totalSpentDuration: number, noteTypeFrom: string, isVersion: boolean) {
    if (diagnosisId) {
      setNoteType(noteTypeFrom);
      setTotalSpentDuration(totalSpentDuration);
      setDiagnosisId(diagnosisId);
      setIsVersion(isVersion);
      setIsDiagnosisNoteOpen(true);

      // const filteredTreatmentDetails = treatmentHistoryDetails.filter(tr => tr?.diagnosisNoteDetails?._id === diagnosisId);

      // console.log(filteredTreatmentDetails?.updatedByTherapist)

      // if (filteredTreatmentDetails.length > 0) {
      //       // Assuming you want the first match from the filtered results
      //       console.log(filteredTreatmentDetails[0])
      //       const diagnosisNoteDetails = filteredTreatmentDetails[0]?.diagnosisNoteDetails;

      //       if (diagnosisNoteDetails) {

      //         // setNoteType(diagnosisNoteDetails.noteType);
      //         setIsNoteUpdateTherapist(diagnosisNoteDetails.updatedByTherapist);
      //       }

      // } else {
      //   //  setNoteType(undefined);
      // }
      // console.log("user propeties")

      // if (!user?.aiNotesType && !filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType && noteTypeFrom) {
      //   setNoteType(noteTypeFrom);
      //       if(noteTypeFrom ==='SOAP') {
      //            setShowAiGeneratedPopup(true);
      //            setShowPIENote(false);
      //        } else if(noteTypeFrom === 'PIE') {
      //           setShowAiGeneratedPopup(false);
      //           setShowPIENote(true);
      //        }

      // } else if (!user?.aiNotesType && filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType) {
      //   console.log("condition 02");
      //   setNoteType(filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType);
      //   if (filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType == "SOAP") {
      //     setShowAiGeneratedPopup(true);
      //     setShowPIENote(false);
      //   } else {
      //     setShowPIENote(true);
      //     setShowAiGeneratedPopup(false);
      //   }
      // } else if (user?.aiNotesType && filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType) {

      //   console.log("condition 03");
      //   if(user?.aiNotesType !== filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType) {
      //       setNoteType(filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType);
      //       if (filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType === "SOAP") {
      //          setShowAiGeneratedPopup(true);
      //          setShowPIENote(false);
      //       } else {
      //          setShowPIENote(true);
      //          setShowAiGeneratedPopup(false);
      //      }
      //   } else {
      //       setNoteType(user?.aiNotesType);
      //       if (user?.aiNotesType === "SOAP") {
      //          setShowAiGeneratedPopup(true);
      //          setShowPIENote(false);
      //     } else {
      //          setShowPIENote(true);
      //          setShowAiGeneratedPopup(false);
      //     }
      //   }


      // }
      //  else {
      //   console.log("condition 04");
      //   console.log(user?.aiNotesType)
      //   console.log(noteTypeFrom)
      //   setNoteType(noteTypeFrom);

      // }

      if (noteTypeFrom) {
        if (noteTypeFrom === 'PIE') {
          setShowPIENote(true);
          setShowAiGeneratedPopup(false);
        } else {
          setShowPIENote(false);
          setShowAiGeneratedPopup(true);
        }
      }

    }
  }


  function closeDiagnosis() {
    setDiagnosisId("");
    setIsDiagnosisError(false);
    setIsDiagnosisLoading(false);
    setIsDiagnosisNoteOpen(false);
  }

  const getTreatmentHistoryNotesInitial = (id: string) => {
    setOffset(1);

    TherapistService.getTreatmentHistory(id, limit * 1, 0).then(res => {
      if (res.success) {
        console.log("1",res.data);
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        if (location.pathname == "/saas-todo") {
          const filteredData = res.data.filter((item) => item.diagnosisNoteDetails?.updatedByTherapist == false && item.diagnosisNoteDetails?.updated == false);
          setTreatmentHistory(filteredData);
        } else {
          setTreatmentHistory(res.data);
        }
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };

  const getTreatmentHistoryNotesInitialByTherapistId = (id: string) => {
    setOffset(1);
    TherapistService.getTreatmentHistoryByTherapistId(id, limit * 1, 0).then(res => {
      if (res.success) {
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };

  function togCenter(noteId: string) {
    setNoteId(noteId);
    const selectedHistory = treatmentHistoryDetails.filter(c => c._id === noteId);
    const selectedHistorydata = selectedHistory[0];
    setSingleTreatmentHistoryData(selectedHistorydata);
    setModalCenter(!modalCenter);
  }

  function togCenterClose() {
    setModalCenter(false);
  }

  const loadMoreTreatmentHistoryNotesByTherapistID = (id: string) => {
    setIsTreatmentLazyLoading(true);

    TherapistService.getTreatmentHistoryByTherapistId(id, limit, offset * limit).then(res => {
      if (res.success) {
        setIsTreatmentLazyLoading(false);
        if (res.data.length != 0) {
          const newData = treatmentHistoryDetails.concat(res.data);
          setTreatmentHistory(newData);
          if (newData.length < limit * (offset + 1)) {
            setShowLazyLoadingButton(false);
          }
          setOffset(offset + 1);
        } else {
          setShowLazyLoadingButton(false);
        }
      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsTreatmentLazyLoading(false);
      }
    });
  };

  const loadMoreTreatmentHistoryNotes = (id: string) => {
    setIsTreatmentLazyLoading(true);
    TherapistService.getTreatmentHistory(id, limit, offset * limit).then(res => {
      if (res.success) {
        setIsTreatmentLazyLoading(false);
        if (res.data.length != 0) {
          const newData = treatmentHistoryDetails.concat(res.data);
          setTreatmentHistory(newData);
          if (newData.length < limit * (offset + 1)) {
            setShowLazyLoadingButton(false);
          }
          setOffset(offset + 1);
        } else {
          setShowLazyLoadingButton(false);
        }
      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsTreatmentLazyLoading(false);
      }
    });
  };

  const removeTreatmentHistory = (noteId: string) => {
    TherapistService.deleteTreatmentHistory(noteId).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setModalCenter(false);
        setIsTreatmentLoading(true);
        setIsTreatmentError(false);
        getTreatmentHistoryNotes(props.userId);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const getTreatmentHistoryNotes = (id: string) => {
    TherapistService.getTreatmentHistory(id, limit * offset, 0).then(res => {
      if (res.success) {
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
        if (res.data.length < limit * offset) {
          setShowLazyLoadingButton(false);
        } else {
          setShowLazyLoadingButton(true);
        }
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };

  const updateTreatmentHistory = () => {
    const updateTreatement: TreatmentHistory = {
      clientId: props.userId,
      noteId: noteIdAdd,
      note: treatmentHistoryData?.note,
    };
    TherapistService.updateTreatmentHistory(updateTreatement).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setTreatmentHistoryData({
          note: "",
        });
        setIsTreatmentLoading(true);
        setIsTreatmentError(false);
        getTreatmentHistoryNotes(props.userId);
        setModalCenter(false);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const addTreatmentHistory = () => {
    const createTreatement: TreatmentHistory = {
      clientId: props.userId,
      note: treatmentHistoryData?.note,
    };
    TherapistService.createTreatmentHistory(createTreatement).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setTreatmentHistoryData({
          note: "",
        });
        setIsTreatmentLoading(true);
        setIsTreatmentError(false);
        getTreatmentHistoryNotes(props.userId);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  // function showAudio(audioUrl: string, showAudio: boolean) {

  //   setAudioURL(Util.audioURL(audioUrl));
  //   setShowRecording(showAudio);
  // }

  const showAudio = async (meetingId: string, path: string, showAudio: boolean) => {
    const data = {
      meetingId,
      path,
    };

    const result = await TherapistService.getAudioPresignUrlForSessionHistory(data);

    if (result) {
      setAudioURL(result.data);
      setShowRecording(showAudio);
    }
  };

  async function showTranscribe(transcribeId: string) {
    setIsTranscribeError(false);
    setIsTranscribeLoading(true);
    setTranscribeOpen(true);
    const response = await VideoChatService.getTranscribeDetails(transcribeId);

    if (response.success) {
      setIsTranscribeError(false);
      setIsTranscribeLoading(false);
      setTranscribe(response.data);
      setTranscribeTextList(response.data.transcriptText);
    } else {
      setIsTranscribeError(true);
      setIsTranscribeLoading(false);
    }
  }

  const searchTranscribe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextForTranscribe(event.target.value);
    const textList: string[] = [];
    const combinedArray = textList.concat(transcribe?.transcriptText != null ? transcribe?.transcriptText : []);
    const result = combinedArray.filter(word => word.match(event.target.value));
    setTranscribeTextList(result);
  };

  function closeTranscribe() {
    setIsTranscribeError(false);
    setIsTranscribeLoading(false);
    setTranscribeOpen(false);
    setSearchTextForTranscribe("");
  }

  const mergeList = () => {
    if (selectedTreatmentHistoryForMerge.length > 1) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure want to merge these sessions?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await VideoChatService.mergeMeetings(selectedTreatmentHistoryForMerge, selectedClient);

          if (response.success) {
            setSelectedTreatmentHistoryForMerge([]);

            getTreatmentHistoryNotesInitial(props.userId);

            selectedTreatmentHistoryForMerge.forEach(val => {
              (document.getElementById(val) as HTMLInputElement).checked = false;
            });

            toast.success("Meetings are merged successfully!", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else {
            if (response.errorCode == 406) {
              toast.error(response.error, {
                position: toast.POSITION.TOP_CENTER,
                className: "foo-bar",
              });
            } else {
              setSelectedTreatmentHistoryForMerge([]);

              selectedTreatmentHistoryForMerge.forEach(val => {
                (document.getElementById(val) as HTMLInputElement).checked = false;
              });
            }
          }
        }
      });
    } else {
      toast.error("You should atleast select 2 sessions to merge!", {
        position: toast.POSITION.TOP_CENTER,
        className: "foo-bar",
      });
    }
  };

  function updateDiagnosisData(isUpdate: boolean) {
    if (isUpdate == true) {
      getTreatmentHistoryNotesInitial(props.userId);
    }
  }

  function onShareWithSubTherapy(data: TreatmentHistory) {
    const historyKey = treatmentHistoryDetails?.findIndex((treatmentHistoryData: TreatmentHistoryData) => treatmentHistoryData._id == data?._id);
    const previousHistory = { ...treatmentHistoryDetails[historyKey] };
    const updatedHistory = { ...previousHistory, subTherapistId: data?.subTherapistId };
    treatmentHistoryDetails[historyKey] = updatedHistory;
    setTreatmentHistory(treatmentHistoryDetails);
  }

  function saveNewNote(transcribeData: any, noteType: string) {
    const selectedHistory = treatmentHistoryDetails.filter(c => c._id === transcribeData._id);

    (selectedHistory[0] as any).diagnosisNoteDetails.updatedByTherapist = true;
    (selectedHistory[0] as any).diagnosisNoteDetails.noteType = noteType;
  }

  const slectTypeFunction = (type: string, showModal: boolean) => {
    setShowTypeSelectModal(showModal);
    setNoteTypeModalOpen(true);
    if (type && (type == NoteType.PIE || type == NoteType.SOAP)) {
      setNoteType(type);
      if (type == NoteType.SOAP) {
        setShowAiGeneratedPopup(true);
      } else {
        setShowPIENote(true);
      }
    }
  };

  return props.showModal ? (
    <>
      {/* {showTypeSelectModal && <NoteTypeSelectModal setShowModal={slectTypeFunction} showModal={showTypeSelectModal} />} */}
      <Modal
        isOpen={props.showModal}
        centered
        fullscreen
        scrollable
        style={{
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div className="modal-head" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}>
          {!isTranscribeOpen && isDiagnosisNoteOpen && (
            <div className="diagnosis-header">
              <h5 className="mb-0 font-size-15">Diagnosis Note</h5>
              <button type="button" onClick={closeDiagnosis} className="bg-transparent-color" data-dismiss="modal" aria-label="Close">
                <img src={Close} alt="Close" className="close-icon-d" />
              </button>
            </div>
          )}
        </div>
        <div>
          {isTranscribeOpen && !isDiagnosisNoteOpen && (
            <OffcanvasHeader toggle={closeTranscribe}>
              Transcribe for meeting from {moment(transcribe?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")} to
              {" " + moment(transcribe?.createdAt || null).format("YYYY-MM-DD h:mm:ss a")}
            </OffcanvasHeader>
          )}

          {!isTranscribeOpen && !isDiagnosisNoteOpen && (
            <OffcanvasHeader
              toggle={() => props.setShowModal(false)}
              style={{
                padding: "20px",
              }}
            >
              Session History &nbsp;&nbsp;{" "}
              {selectedTreatmentHistoryForMerge.length > 0 && user?.role == Role.THERAPIST && (
                <button className="btn btn-success" onClick={mergeList}>
                  Merge Sessions
                </button>
              )}
            </OffcanvasHeader>
          )}

          <div>
            <div className="row pt20 pl10 chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  className="scrollbar-container ps--active-y"
                  style={{
                    height: isTranscribeOpen || isDiagnosisNoteOpen ? "80vh" : "83vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {isDiagnosisNoteOpen && (noteType || user?.aiNotesType)
                    ? noteType == "PIE"
                      ? showPIENote && (
                        <PIEDiagnosisNoteViewNew
                          diagnosisId={diagnosisId}
                          totalSpentDuration={totalSpentDuration}
                          updateDiagnosisData={updateDiagnosisData}
                          isVersion={isVersion}
                        />
                      )
                      : showAiGeneratedPopup && (
                        <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData} isVersion={isVersion} />
                      )
                    : isDiagnosisNoteOpen && (
                      <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData} isVersion={isVersion} />
                    )}

                  {/* {isDiagnosisNoteOpen && <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData}></DiagnosisNoteScreen>} */}
                  {isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {isTranscribeLoading && !isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {isDiagnosisLoading && !isDiagnosisError && isDiagnosisNoteOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {!isTreatmentLoading && isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isTranscribeLoading && isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isDiagnosisLoading && isDiagnosisError && isDiagnosisNoteOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isDiagnosisNoteOpen &&
                    !isTreatmentLoading &&
                    !isTreatmentError &&
                    !isTranscribeOpen &&
                    treatmentHistoryDetails &&
                    treatmentHistoryDetails.length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        <h5>No treatment history notes are created yet</h5>
                      </div>
                    )}

                  {!isDiagnosisNoteOpen && !isTranscribeLoading && !isTranscribeError && isTranscribeOpen && !transcribe?.transCribeInProcess && (
                    <div className="search-box chat-search-box chat-sec1">
                      <div className="position-relative">
                        <Input
                          onChange={event => searchTranscribe(event)}
                          id="search-transcribe-text"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchTextForTranscribe}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  )}
                  {!isDiagnosisNoteOpen &&
                    !isTranscribeLoading &&
                    !isTranscribeError &&
                    isTranscribeOpen &&
                    !transcribe?.transCribeInProcess &&
                    transcribeTextList.map((trans: any, index) => {
                      return (
                        <Card key={index} className="cursor_pointer mb-1">
                          <CardBody>
                            <h5 className="font-size-13 mb-0">{trans}</h5>
                          </CardBody>
                        </Card>
                      );
                    })}
                  {!isDiagnosisNoteOpen && !isTranscribeLoading && !isTranscribeError && isTranscribeOpen && transcribe?.transCribeInProcess && (
                    <h5 className="font-size-13 mb-1">Transcribing on process please try again later</h5>
                  )}

                  {user?.role == Role.THERAPIST && (
                    <>
                      <div className="row">
                        <div className="col-xl-10">
                          {!isDiagnosisNoteOpen &&
                            !isTreatmentLoading &&
                            !isTreatmentError &&
                            !isTranscribeOpen &&
                            treatmentHistoryDetails
                              ?.sort((a, b) => (a.meetingStartedTime > b.meetingStartedTime ? -1 : 1))
                              .map((tr: any, index) => {
                                const pattern = new RegExp(
                                  "^(https?:\\/\\/)?" +
                                  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                                  "((\\d{1,3}\\.){3}\\d{1,3}))" +
                                  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                                  "(\\?[;&a-z\\d%_.~+=-]*)?" +
                                  "(\\#[-a-z\\d_]*)?$",
                                  "i"
                                );
                                return (
                                  <Card
                                    key={index}
                                    onClick={() => {
                                      if (!tr.isMeetingTranscribe) {
                                        togCenter(tr?._id);
                                      }
                                    }}
                                    className="cursor_pointer"
                                  >
                                    {!tr.isMeetingTranscribe && (
                                      <CardBody className="custom-card1">
                                        <Col xl={12}>
                                          <h5 className="font-size-15 title-line">&#9929; Normal Treatment History</h5>
                                        </Col>

                                        <Row>
                                          <Col xl={6}>
                                            <h5 className="font-size-15">
                                              {tr?.therapistId?.firstname} {tr?.therapistId?.lastname}
                                            </h5>
                                          </Col>

                                          <Col xl={6}>
                                            <span className="text-muted fw-medium " style={{ float: "right" }}>
                                              {moment(tr?.createdAt || null).format("YYYY-MM-DD")}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Col xl={12}>{!tr.isMeetingTranscribe && <span className="text-muted fw-medium ">{tr?.note}</span>}</Col>
                                      </CardBody>
                                    )}

                                    {tr.isMeetingTranscribe && (
                                      <TranscribeHistoryCard
                                        transcribeData={tr}
                                        isCreating={isCreating}
                                        showAudioFunction={showAudio}
                                        showDiagnosisFunction={showDiagnosis}
                                        showTranscribeFunction={showTranscribe}
                                        selectedTreatmentHistoryForMerge={selectedTreatmentHistoryForMerge}
                                        setSelectedTreatmentHistoryForMerge={setSelectedTreatmentHistoryForMerge}
                                        setSelectedClient={setSelectedClient}
                                        updateDiagnosisData={updateDiagnosisData}
                                        onShareWithSubTherapy={onShareWithSubTherapy}
                                        saveNewNote={saveNewNote}

                                      ></TranscribeHistoryCard>
                                    )}
                                  </Card>
                                );
                              })}
                        </div>

                        {/* <div className="col-xl-6">
                          {!isDiagnosisNoteOpen && !isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && (
                            <div className="row  pt20 pl10 chat-conversation p-3">
                              <div
                                className="scrollbar-container ps ps--active-y"
                                style={{ height: "15vh" }}
                              >
                                <Input
                                  type="textarea"
                                  className="form-control mb-2"
                                  id="formrow-firstname-Input"
                                  style={{ minHeight: "30px" }}
                                  placeholder="Type a note..."
                                  name="articleTitle"
                                  value={treatmentHistoryData?.note || ""}
                                  onChange={e =>
                                    setTreatmentHistoryData({
                                      ...treatmentHistoryData,
                                      note: e.target.value,
                                    })
                                  }
                                  maxLength={430}
                                  required
                                />

                                <Row>
                                  <Col xl={6}></Col>
                                  <Col xl={6}>
                                    <button
                                      type="button"
                                      className="btn btn-primary  relative mt-2 mb-2"
                                      style={{
                                        bottom: "0",
                                        float: "right",
                                      }}
                                      onClick={addTreatmentHistory}
                                    >
                                      Save
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </>
                  )}

                  <div className="row">
                    {user?.role == Role.CLIENT && (
                      <>
                        {!isDiagnosisNoteOpen &&
                          !isTreatmentLoading &&
                          !isTreatmentError &&
                          !isTranscribeOpen &&
                          treatmentHistoryDetails
                            ?.sort((a, b) => (a.meetingStartedTime > b.meetingStartedTime ? -1 : 1))
                            .map((tr: any, index) => {
                              const pattern = new RegExp(
                                "^(https?:\\/\\/)?" +
                                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                                "(\\#[-a-z\\d_]*)?$",
                                "i"
                              );

                              return (
                                <div key={index} className="col-6">
                                  <Card className="cursor_pointer">
                                    {!tr.isMeetingTranscribe && (
                                      <CardBody className="custom-card1">
                                        <Col xl={12}>
                                          <h5 className="font-size-15 title-line">&#9929; Normal Treatment History</h5>
                                        </Col>

                                        <Row>
                                          <Col xl={6}>
                                            <h5 className="font-size-15">
                                              {tr?.therapistId?.firstname} {tr?.therapistId?.lastname}
                                            </h5>
                                          </Col>

                                          <Col xl={6}>
                                            <span className="text-muted fw-medium " style={{ float: "right" }}>
                                              {moment(tr?.createdAt || null).format("YYYY-MM-DD")}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Col xl={12}>{!tr.isMeetingTranscribe && <span className="text-muted fw-medium ">{tr?.note}</span>}</Col>
                                      </CardBody>
                                    )}

                                    {tr.isMeetingTranscribe && (
                                      <TranscribeHistoryCard
                                        transcribeData={tr}
                                        isCreating={isCreating}
                                        showAudioFunction={showAudio}
                                        showDiagnosisFunction={showDiagnosis}
                                        showTranscribeFunction={showTranscribe}
                                        selectedTreatmentHistoryForMerge={selectedTreatmentHistoryForMerge}
                                        setSelectedTreatmentHistoryForMerge={setSelectedTreatmentHistoryForMerge}
                                        setSelectedClient={setSelectedClient}
                                        updateDiagnosisData={updateDiagnosisData}
                                        onShareWithSubTherapy={onShareWithSubTherapy}
                                        saveNewNote={saveNewNote}

                                      ></TranscribeHistoryCard>
                                    )}
                                  </Card>
                                </div>
                              );
                            })}
                      </>
                    )}
                  </div>

                  {user?.role == Role.THERAPIST && (
                    <>
                      {!isDiagnosisNoteOpen &&
                        !isTreatmentLoading &&
                        !isTreatmentError &&
                        !isTranscribeOpen &&
                        showLazyLoadingButton &&
                        treatmentHistoryDetails &&
                        treatmentHistoryDetails.length >= 10 && (
                          <button
                            type="button"
                            className="btn btn-primary  relative mt-2 mb-2"
                            style={{
                              bottom: "0",
                              float: "left",
                              background: isTreatmentLazyLoading ? "grey" : "",
                              border: "none",
                            }}
                            onClick={() => {
                              if (!isTreatmentLazyLoading) {
                                loadMoreTreatmentHistoryNotes(props.userId);
                              }
                            }}
                          >
                            {isTreatmentLazyLoading ? "Loading .." : "Show More"}
                          </button>
                        )}
                    </>
                  )}

                  {user?.role == Role.CLIENT && (
                    <>
                      {!isDiagnosisNoteOpen &&
                        !isTreatmentLoading &&
                        !isTreatmentError &&
                        !isTranscribeOpen &&
                        showLazyLoadingButton &&
                        treatmentHistoryDetails &&
                        treatmentHistoryDetails.length >= 10 && (
                          <button
                            type="button"
                            className="btn btn-primary  relative mt-2 mb-2"
                            style={{
                              bottom: "0",
                              float: "left",
                              background: isTreatmentLazyLoading ? "grey" : "",
                              border: "none",
                            }}
                            onClick={() => {
                              if (!isTreatmentLazyLoading) {
                                loadMoreTreatmentHistoryNotesByTherapistID(props.userId);
                              }
                            }}
                          >
                            {isTreatmentLazyLoading ? "Loading .." : "Show More"}
                          </button>
                        )}
                    </>
                  )}

                  {showRecording && <Modal23 setShowModal={setShowRecording} showModal={showRecording} audioURL={audioURL} />}

                  {/* {!isDiagnosisNoteOpen && !isTreatmentError && !isTreatmentError && !isTranscribeOpen && (
                    <Modal
                      isOpen={modalCenter}
                      toggle={() => {
                        togCenter(noteIdAdd);
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit Treatment History</h5>
                        <button
                          type="button"
                          onClick={() => {
                            togCenterClose();
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-2">
                          <Input
                            type="textarea"
                            className="form-control mb-2"
                            id="formrow-firstname-Input"
                            style={{ minHeight: "30px" }}
                            placeholder="Type a note..."
                            name="articleTitle"
                            defaultValue={singleTreatmentHistoryData?.note || ""}
                            onChange={e =>
                              setTreatmentHistoryData({
                                ...treatmentHistoryData,
                                note: e.target.value,
                              })
                            }
                            maxLength={430}
                            required
                          />

                          <Row>
                            <Col xl={6}></Col>
                            <Col xl={6}>
                              <button
                                type="button"
                                className="btn btn-danger ml-4  relative mt-2 mb-2"
                                style={{
                                  bottom: "0",
                                  float: "right",
                                }}
                                onClick={() => {
                                  removeTreatmentHistory(noteIdAdd);
                                }}
                              >
                                Delete
                              </button>

                              <button
                                type="button"
                                className="btn btn-primary mr-2   relative mt-2 mb-2"
                                style={{
                                  bottom: "0",
                                  float: "right",
                                }}
                                onClick={updateTreatmentHistory}
                              >
                                Update
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Modal>
                  )} */}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </>
  ) : null;
};

export default SessionHistoryModal;
