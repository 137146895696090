
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, TabContent, TabPane, CardTitle } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/admin.css";
import { TherapistService } from "../../services/TherapistService";
import { Profession, ProfessionLicense } from "../../models/Profession";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CommonService } from "../../services/CommonService";
import Spinner from "../../common/spinner/spinner";
const ViewProfessionLicense: React.FC = () => {
    const { professionId } = useParams<ParameterTypes>();
    const [professionsLicense, setProfessionLicense] = useState<ProfessionLicense[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const back = () => {
        history.back()
    }

    useEffect(() => {
        getAllProfessionLIcense();
    }, []);

    const getAllProfessionLIcense = () => {
        setIsLoading(true)
        TherapistService.getProfessionLicense().then(res => {
            if (res.success) {
                setProfessionLicense(res.data);
                setIsLoading(false)
            } else {
                setProfessionLicense([]);
                setIsLoading(false)
            }
        });
    }

    const deleteProfessionLicense = (id: any) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
        }).then(result => {
            if (result.isConfirmed) {
                CommonService.deleteProfessionLicense(id).then(res => {
                    if (res.success) {
                        getAllProfessionLIcense();
                        toast.success("Profession License is Deleted!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error('Somthing went wrong!', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    }

    const selectedProfessions = professionsLicense?.filter((c) => c.professionId === professionId);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
                        <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                        <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
                    </div>
                    <Breadcrumbs title={"View Profession Licenses"} breadcrumbItem={"View Profession Licenses"} />
                    <Row>
                        <Col xl={12}>
                            <NavLink
                                to={"/create-profession-license/" + professionId}
                                className="btn btn-primary relative mb-3" style={{
                                    bottom: "0",
                                    float: "right",
                                }}>
                                Create Profession License
                            </NavLink>

                        </Col>
                    </Row>
                    <Card className="m-h">
                        <CardBody>
                            <Row>
                                <Col sm="12" className="mt10">
                                    {!isLoading ? (
                                        <div className="table-responsive">
                                            {selectedProfessions !== undefined ? (
                                                <table className="table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Profession License</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            selectedProfessions && selectedProfessions.map((professions, index) => (
                                                                <tr key={professions._id}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{professions.name}</td>
                                                                    <td>
                                                                        {/* <Link className="btn btn-warning btn-sm m-2" to={"create_profession_license/"+professions._id}  ><i className='bx bx-edit' ></i></Link> */}
                                                                        <button className="btn btn-danger btn-sm mr-2" onClick={() => deleteProfessionLicense(professions._id)}><i className='bx bx-trash' ></i></button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div>No Profession License</div>
                                            )
                                            }

                                        </div>
                                    ) : (
                                        <Spinner />
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewProfessionLicense;
