import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AdminService } from "../../services/AdminService";
import { map } from "lodash";
import { MeetingData } from "../../models/MeetingData";
import Swal from "sweetalert2";
import { Util } from "../../Util";
import AudioPlayer from "react-h5-audio-player";
import moment from "moment";
import { TherapistService } from './../../services/TherapistService';

const ViewMeetingsAndRecordingsDev: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [meetingsAndRecordings, setMeetingsAndRecordings] = useState([] as MeetingData[]);
  const [meetingOffset, setMeetingOffset] = useState(1);
  const [isSearchableText, setSearchableText] = useState({ searchableText: "" } as any);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedMeetingAudios, setSelectedMeetingAudios] = useState([] as any);
  const [selectedMeetingVideos, setSelectedMeetingVidios] = useState([] as any);

  const LIMIT = 10;

  useEffect(() => {
    getAllMeetingsAndRecords();
  }, []);

  const getAllMeetingsAndRecords = () => {
    setIsLoading(true);
    AdminService.getAllRecordsAndMeetings(LIMIT, meetingOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setMeetingsAndRecordings(res.data);
        setIsLoading(false);
      } else {
        setMeetingsAndRecordings([] as MeetingData[]);
        setSeeMore(false);
        setIsLoading(false);
      }
    });
  };

  const seeMoreMeetingsAndRecordings = () => {
    setIsLoading(true);
    const updatedOffset = meetingOffset + 1;
    setMeetingOffset(updatedOffset);
    AdminService.getAllRecordsAndMeetings(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setMeetingsAndRecordings(previousmeeting => {
          return [...new Set([...previousmeeting, ...res.data])];
        });
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setIsLoading(false);
      } else {
        setMeetingsAndRecordings([] as MeetingData[]);
        setSeeMore(false);
        setIsLoading(false);
      }
    });
  };

  const deleteMeetingsAndRecordings = (id: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.deleteMeetingsAndRecordingById(id).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Meeting and Recordings deleted!",
              confirmButtonColor: "#FD7F00",
            });
            const refreshedArr = meetingsAndRecordings.filter(item => item._id !== id);
            if (refreshedArr) {
              setMeetingsAndRecordings(refreshedArr);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to delete.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  const searchMeetingId = () => {
    if (isSearchableText.searchableText) {
      setIsLoading(true);

      AdminService.searchMeetingsAndRecordingsByAdmin(isSearchableText).then(res => {
        if (res.success) {
          setMeetingsAndRecordings(res.data);
          setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
          setIsLoading(false);
        } else {
          setSeeMore(false);
          setIsLoading(false);
        }
      });
    } else {
      getAllMeetingsAndRecords();
    }
  };


  // const isOpenModal = (data: any) => {
  //   setSelectedMeetingAudios(data);
  //   setIsOpen(!isOpen);
  //   setIsOpen2(false);
  // };

  const isOpenModal =async (data: any) => {

    setIsOpen(!isOpen);
    setIsOpen2(false);

    const meetingData = {
      meetingId: data._id,
      path: data.s3AudioPath
    }

    if(data?._id && data?.s3AudioPath) {
       const result = await TherapistService.getAudioPresignUrlForSessionHistory(meetingData);

      if(result) {
       setSelectedMeetingAudios(result.data);
      }

    }
  };



  const isOpenModal2 = (data: any) => {
    setSelectedMeetingVidios(data);
    setIsOpen2(!isOpen2);
    setIsOpen(false);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={`Lavni`} breadcrumbItem={"Recordings & Meetings"} />

            <Card>
              <CardBody>
                {isLoading && <Spinner className="bouncer" />}
                <Row>
                  <Col xl="6"></Col>
                  <Col xl="6 d-flex">
                    <Input
                      placeholder="Search from Meeting Id (eg : abcr-cdrr-edfr)"
                      className="mb-3 mr-4"
                      onChange={(e: any) => setSearchableText({ ...isSearchableText, searchableText: e.target.value })}
                    />
                    <button className="btn btn-primary mb-3" onClick={searchMeetingId}>
                      Search
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Meeting Id</th>
                          <th scope="col">Meeting Date</th>
                          <th scope="col">Meeting Duration</th>
                          <th scope="col">Spent Duration</th>
                          <th scope="col">Both join</th>
                          <th scope="col">Client</th>
                          <th scope="col">Therapist</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {meetingsAndRecordings.length > 0 &&
                          meetingsAndRecordings.map((data: MeetingData, i: number) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data.meetingId}</td>
                                <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/YYYY hh:mm:ss A") : <span>-</span>}</td>
                                <td>{data.meetingDuration}</td>
                                <td>{data?.spentDuration != null ? data?.spentDuration.toFixed(2) : "--"}</td>
                                <td>{data.bothJoinedAt ? <span>True</span> : <span>False</span>} </td>
                                <td>
                                  <Link to={"/profile/" + data.clientId?._id}>
                                    {data?.clientId?.firstname} {data?.clientId?.lastname}
                                  </Link>
                                </td>
                                <td>
                                  <Link to={"/profile/" + data.therapistId?._id}>
                                    {data?.therapistId?.firstname} {data?.therapistId?.lastname}
                                  </Link>
                                </td>

                                <td>
                                  {/* {data.audioFiles && data.audioFiles.length != 0 && data.audioFiles[0] && (
                                    <button className="btn btn-success btn-sm mr-2" onClick={() => isOpenModal(data.audioFiles)}>
                                      <i className="bx bx-music"></i>
                                    </button>
                                  )} */}

                                  {data?.s3AudioPath && (
                                    <button className="btn btn-success btn-sm mr-2" onClick={() => isOpenModal(data)}>
                                      <i className="bx bx-music"></i>
                                    </button>
                                  )}



                                  <button className="btn btn-warning btn-sm" onClick={() => deleteMeetingsAndRecordings(data._id)}>
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Col>
                </Row>

                {meetingsAndRecordings.length == 0 && <div className="text-muted text-center">No meetings and recordings</div>}

                <Row>
                  <Col xl="12 mt30 text-center">
                    {seeMore && (
                      <button className="btn btn-warning" onClick={() => seeMoreMeetingsAndRecordings()}>
                        See More
                      </button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        <Modal isOpen={isOpen} centered toggle={isOpenModal} className="p-c-details">
          {/* <ModalHeader className="text-center">
                        <h4 className="mt-2">Meetings and Recordings</h4>
                    </ModalHeader> */}
          <ModalBody>
            <Row>
              <Col>
                <div className="m-tile">
                  {/* <AudioPlayer src={selectedMeetingAudios[0]?._id ? Util.audioURL(selectedMeetingAudios[0]?._id) : Util.audioURL(selectedMeetingAudios[0])} /> */}
                  <AudioPlayer src={selectedMeetingAudios} />
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={isOpen2} centered toggle={isOpenModal2} className="p-c-details">
          <ModalHeader className="text-center">
            <h4 className="mt-2">Meetings and Recordings</h4>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <div className="m-tile">
                  <i className="bx bx-video"></i>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ViewMeetingsAndRecordingsDev;
