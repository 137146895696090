import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { Card, Col, Row } from "reactstrap";
import moment from "moment";
import Close from "../../assets/images/icons/u_times-circle.png";
import SignaturePad from "react-signature-canvas";
import {
  DiagnosesRecommendations,
  Diagnose,
  PhysicianDiagnosedConditions
} from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";
import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TherapistService } from "./../../services/TherapistService";
import UserContext from "../../context/UserContext";
import options from "./../Chat/DiagnosisNote/Dataset/options";
import Select from "react-select";
import { CPTCode } from "./../../models/DiagnosisNote";

toast.configure();

const NewFormEleven = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, data } = props;
  const [clinicianSign, setClinicianSign] = useState<boolean>(false);
  const [clientSignature, setClientSignature] = useState<boolean>(false);
  const [editSignature, setEditSignature] = useState<boolean>(false);
  const [user, setUser] = useContext(UserContext);

  const [primaryDiagnosisICD10CodesError, setprimaryDiagnosisICD10CodesError] = useState<boolean>(false);
  const [cptCodeError, setCPTCodeError] = useState<boolean>(false);
  const [cptCodeList] = useState([
    { cptCode: "-1", cptCodeDescription: "Select" },
    { cptCode: "90791", cptCodeDescription: "Psychiatric diagnostic evaluation" },
    { cptCode: "90792", cptCodeDescription: "Psychiatric diagnostic evaluation with medical services" },
    { cptCode: "90832", cptCodeDescription: "Psychotherapy, 16-37 minutes" },
    { cptCode: "90833", cptCodeDescription: "Psychotherapy, 16-37 minutes with E/M service, listed seperately" },
    { cptCode: "90834", cptCodeDescription: "Psychotherapy, 38-52 minutes" },
    { cptCode: "90836", cptCodeDescription: "Psychotherapy, 38-52 minutes with E/M service, listed seperately" },
    { cptCode: "90837", cptCodeDescription: "Psychotherapy, 53+ minutes" },
    { cptCode: "90838", cptCodeDescription: "Psychotherapy, 53+ minutes with E/M service, listed seperately" },
    { cptCode: "90839", cptCodeDescription: "Psychotherapy for crisis, 30-74 minutes" },
    { cptCode: "90840", cptCodeDescription: "Psychotherapy for crisis, each additional 30 minutes beyond initial 74min, upto two add-ons per 90839" },
    { cptCode: "90846", cptCodeDescription: "Family psytx w/o patient 50 minutes" },
    { cptCode: "90847", cptCodeDescription: "Family psytx w/patient 50 minutes" },
    { cptCode: "90853", cptCodeDescription: "Group psychotherapy" },
    { cptCode: "00001", cptCodeDescription: "Non Billable" },
  ]);

  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    diagnoseRecommendationDetails: {
      diagnosis: [],
      recommendation: {},
      printedName: "",
      signature: "",
      clientSignature: "",
      dateOfClinicianSignature: "",
      date: "",
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        diagnoseRecommendationDetails: data?.diagnoseRecommendationDetails,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));

      if (!data?.diagnoseRecommendationDetails?.signature) {
        setClinicianSign(false);
      } else {
        setClinicianSign(true);
      }

      if (!data?.diagnoseRecommendationDetails?.clientSignature) {
        setClientSignature(false);
      } else {
        setClientSignature(true);
      }
    }
  }, [memoizedUpdateValues]);

  useEffect(() => {
    if (!formData?.diagnoseRecommendationDetails?.diagnosis || formData?.diagnoseRecommendationDetails?.diagnosis?.length === 0) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        diagnoseRecommendationDetails: {
          ...formData?.diagnoseRecommendationDetails,
          diagnosis: [
            ...(formData?.diagnoseRecommendationDetails?.diagnosis ?? []),
            {
              code: "",
              diagnosis: [],
            },
          ],
        },
      }));
    }
  }, [formData]);

  const addDiagnosis = () => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      diagnoseRecommendationDetails: {
        ...formData?.diagnoseRecommendationDetails,
        diagnosis: [
          ...(formData?.diagnoseRecommendationDetails?.diagnosis ?? []),
          {
            code: "",
            diagnosis: [],
          },
        ],
      },
    }));
  };

  const removeDiagnosis = (id: number) => {
    if ((formData?.diagnoseRecommendationDetails?.diagnosis ?? [])?.length > 1) {
      setFormData((formData: Partial<DigitalForm>) => {
        const diagnosisArray = formData?.diagnoseRecommendationDetails?.diagnosis;

        const updateDiagnosisArray = diagnosisArray?.filter((diagnosis, i) => {
          return i != id;
        });

        return {
          ...formData,
          diagnoseRecommendationDetails: {
            ...formData?.diagnoseRecommendationDetails,
            diagnosis: updateDiagnosisArray,
          },
        };
      });
    }
  };

  const handleCPTCodeChange = (event: any, index: number) => {
    if (event.target.value == "-1" || event.target.value == undefined || event.target.value == null) {
      setCPTCodeError(true);
    } else {
      setCPTCodeError(false);
    }
    // setFormData((formData: Partial<DigitalForm>) => ({
    //   ...formData,
    //   diagnoseRecommendationDetails: {
    //     ...formData?.diagnoseRecommendationDetails,
    //     diagnosis: {
    //       ...formData?.diagnoseRecommendationDetails?.diagnosis,
    //       code: event.target.value,
    //     },
    //   },
    // }));
    const updatedDiagnose = formData?.diagnoseRecommendationDetails?.diagnosis?.map((diagnose: Diagnose, i: number) => {
      if (i === index) {
        return {
          ...diagnose,
          ["code"]: event.target.value, // Update the specific field
        };
      }
      return diagnose; // Return other substances unchanged
    });

    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      diagnoseRecommendationDetails: {
        ...formData?.diagnoseRecommendationDetails,
        diagnosis: updatedDiagnose,
      },
    }));
  };

  // const handleSubstanceChange = (index: number, field: keyof NameOfSubstance, value: string | number) => {
  //   const updatedSubstances = formData?.nameOfSubstance?.map((substance: NameOfSubstance, i: number) => {
  //     if (i === index) {
  //       return {
  //         ...substance,
  //         [field]: value, // Update the specific field
  //       };
  //     }
  //     return substance; // Return other substances unchanged
  //   });

  //   setFormData((formData: Partial<DigitalForm>) => ({
  //     ...formData,
  //     nameOfSubstance: updatedSubstances,
  //   }));
  // };

  const handleDiagnosisChange = (newValue: any, index: number) => {

    if (newValue.length == 0) {
      setprimaryDiagnosisICD10CodesError(true);
    } else {
      setprimaryDiagnosisICD10CodesError(false);
    }

    // setPrimaryDiagnosisICD10Codes(finalArray);

    const updatedDiagnose = formData?.diagnoseRecommendationDetails?.diagnosis?.map((diagnose: Diagnose, i: number) => {
      if (i === index) {
        return {
          ...diagnose,
          ["diagnosis"]: newValue, // Update the specific field
        };
      }
      return diagnose; // Return other substances unchanged
    });

    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      diagnoseRecommendationDetails: {
        ...formData?.diagnoseRecommendationDetails,
        diagnosis: updatedDiagnose,
      },
    }));
  };

  const customStylesForICD10Codes = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any) => ({
      ...styles,
      borderColor: primaryDiagnosisICD10CodesError ? "red" : "#ced4da",
    }),
  };

  const signPad = useRef<any>(null);

  function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const resizeCanvas = () => {
    if (signPad?.current) {
      const canvas = signPad?.current?.getCanvas();
      if (canvas) {
        const container = canvas.parentNode as HTMLElement;
        if (container) {
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          canvas.width = container.offsetWidth * ratio;
          canvas.height = container.offsetHeight * ratio;
          const context = canvas.getContext("2d");
          if (context) {
            context.scale(ratio, ratio);
          }
          signPad.current.clear();
        }
      }
    }
  };

  useEffect(() => {
    try {
      const handleResize = () => {
        if (signPad?.current) {
          // Resize the canvas on initial render and when the screen resizes
          requestAnimationFrame(() => {
            resizeCanvas();
          });
        }
      };

      handleResize();

      const debouncedResize = debounce(resizeCanvas, 200);
      window.addEventListener("resize", debouncedResize);

      // Using ResizeObserver to handle changes in container size
      const observer = new ResizeObserver(() => {
        resizeCanvas();
      });

      if (signPad?.current) {
        const container = signPad.current.getCanvas().parentNode as HTMLElement;
        if (container) {
          observer.observe(container);
        }
      }

      return () => {
        window.removeEventListener("resize", debouncedResize);
        observer.disconnect();
      };
    } catch (error) {
      console.log("Error initializing the signature pad:", error);
    }
  }, []);

  function clearsign(): void {
    if (signPad?.current) {
      signPad.current.clear();
      const signatureData = null;
    }
  }

  async function saveSign() {
    if (signPad?.current) {
      const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
      // setFormData({ ...formData, therapistSignature: signatureData });
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        diagnoseRecommendationDetails: {
          ...formData?.diagnoseRecommendationDetails,
          signature: signatureData,
          dateOfClinicianSignature: formData?.diagnoseRecommendationDetails?.dateOfClinicianSignature
            ? moment.utc(formData?.diagnoseRecommendationDetails?.dateOfClinicianSignature).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        },
      }));

      try {
        const signature = {
          signature: signatureData,
        };
        const result = await TherapistService.updateTherapistSignature(signature);
        if (result && result.success) {
          toast.success(`Successfully added therapist signature.`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          setUser({ ...user, signature: result?.data?.signature });
        }
      } catch (error) {
        toast.error("Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      setEditSignature(false);
    }
  }

  function editSign() {
    setClinicianSign(false);
  }

  const handleUpdateData = () => {
    const updateDetails = {
      diagnoseRecommendationDetails: {
        ...formData?.diagnoseRecommendationDetails,
      },
      formHeader: {
        ...formData?.formHeader,
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };
  const updateRecommendation=(field:string,value:boolean|string)=>{
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      diagnoseRecommendationDetails: {
        ...formData?.diagnoseRecommendationDetails,
        recommendation: {
          ...formData?.diagnoseRecommendationDetails?.recommendation,
          notApplicable:field==="notApplicable"?value?DiagnosesRecommendations.notApplicable:"":formData?.diagnoseRecommendationDetails?.recommendation?.notApplicable,
          levelSAIOP:field==="levelSAIOP"?value?DiagnosesRecommendations.levelSAIOP:"":formData?.diagnoseRecommendationDetails?.recommendation?.levelSAIOP,
          levelResidential:field==="levelResidential"?value?DiagnosesRecommendations.levelResidential:"":formData?.diagnoseRecommendationDetails?.recommendation?.levelResidential,
          levelMedicalDetox:field==="levelMedicalDetox"?value?DiagnosesRecommendations.levelMedicalDetox:"":formData?.diagnoseRecommendationDetails?.recommendation?.levelMedicalDetox,
          levelOutpatienttreatment:field==="levelOutpatienttreatment"?value?DiagnosesRecommendations.levelOutpatienttreatment:"":formData?.diagnoseRecommendationDetails?.recommendation?.levelOutpatienttreatment,
          levelComprehensiveoutpatient:field==="levelComprehensiveoutpatient"?value?DiagnosesRecommendations.levelComprehensiveoutpatient:"":formData?.diagnoseRecommendationDetails?.recommendation?.levelComprehensiveoutpatient,
          levelNonhospital:field==="levelNonhospital"?value?DiagnosesRecommendations.levelNonhospital:"":formData?.diagnoseRecommendationDetails?.recommendation?.levelNonhospital,

        },
      },
    }));
  }

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        <Card>
          <div className="text-end">
            <button
              className="btn btn-primary mb-2"
              type="button"
              onClick={() => {
                handleUpdateData();
              }}
            >
              {"Submit"}
            </button>
          </div>
        </Card>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  DIAGNOSES
                </h5>
              </div>
            </Card>
          </div>
        </Row>

        <Card className="match-card-body-second p-3">
          {formData?.diagnoseRecommendationDetails?.diagnosis?.map((item, index) => (
            <div key={index} className="ai-border mb-3 text-start">
              <div className="ps-3 pe-3 pt-3 pb-3">
                <div className="mb-3 ">
                  <p className="text-muted font-size-15 flex flex-row justify-content-between">
                    <strong>Code:</strong>

                    <button
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        fontSize: "20px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => removeDiagnosis(index)}
                    >
                      <img src={Close} alt="diagnoseCloseBtn" />
                    </button>
                  </p>

                  <select
                    className={cptCodeError ? "form-control is-invalid" : "form-control"}
                    value={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.code}
                    onChange={event => handleCPTCodeChange(event, index)}
                  >
                    {cptCodeList &&
                      cptCodeList.map((code: CPTCode, index: number) => {
                        return (
                          <option key={index} value={code.cptCode}>
                            {code.cptCodeDescription}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="mb-3">
                  <p className="text-muted font-size-15">
                    <strong> Diagnosis :</strong>
                  </p>

                  <Select
                    key={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.diagnosis?.length}
                    closeMenuOnSelect={false}
                    defaultValue={formData?.diagnoseRecommendationDetails?.diagnosis?.[index]?.diagnosis ?? []}
                    isMulti
                    options={options}
                    styles={customStylesForICD10Codes}
                    onChange={(e: any) => handleDiagnosisChange(e, index)}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className="d-flex flex-row justify-content-end">
            <button className='btn btn-primary mb-2" type="button' onClick={addDiagnosis}>
              Add DIAGNOSES
            </button>
          </div>
        </Card>

        {/* <Row>
          <Col xl={4}>
            <button className="btn btn-primary mb-2" type="button" onClick={addDiagnosis}>
              Add Diagnosis
            </button>
          </Col>
        </Row> */}

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  RECOMMENDATIONS
                </h5>
              </div>
            </Card>
          </div>
        </Row>
        <Card className="match-card-body-second p-3">
          <h6 className="fw-600" style={{ margin: "0.2rem" }}>
            ASAM Level of Care Recommended:
          </h6>
          <Row>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.notApplicable}
                checked={formData?.diagnoseRecommendationDetails?.recommendation?.notApplicable === DiagnosesRecommendations?.notApplicable}
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.notApplicable === DiagnosesRecommendations?.notApplicable;
                  updateRecommendation("notApplicable",!result);
                }}
                //  checked={formData.independentLiving?.assistanceWithSkills?.feedingSelf}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Not applicable (no substance abuse){" "}
              </label>
            </div>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.levelSAIOP}
                checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelSAIOP === DiagnosesRecommendations?.levelSAIOP}
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.levelSAIOP === DiagnosesRecommendations?.levelSAIOP;
                  updateRecommendation("levelSAIOP",!result);
                }}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Level 2.1 (SAIOP){" "}
              </label>
            </div>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.levelResidential}
                checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelResidential === DiagnosesRecommendations?.levelResidential}
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.levelResidential === DiagnosesRecommendations?.levelResidential;
                  updateRecommendation("levelResidential",!result);
                }}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Level 3.1, 3.3, 3.5 Residential{" "}
              </label>
            </div>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.levelMedicalDetox}
                checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelMedicalDetox === DiagnosesRecommendations?.levelMedicalDetox}
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.levelMedicalDetox === DiagnosesRecommendations?.levelMedicalDetox;
                  updateRecommendation("levelMedicalDetox",!result);
                }}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Level 4 Medical Detox{" "}
              </label>
            </div>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.levelOutpatienttreatment}
                checked={
                  formData?.diagnoseRecommendationDetails?.recommendation?.levelOutpatienttreatment === DiagnosesRecommendations?.levelOutpatienttreatment
                }
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.levelOutpatienttreatment === DiagnosesRecommendations?.levelOutpatienttreatment;
                  updateRecommendation("levelOutpatienttreatment",!result);
                }}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Level 1 Outpatient treatment{" "}
              </label>
            </div>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.levelComprehensiveoutpatient}
                checked={
                  formData?.diagnoseRecommendationDetails?.recommendation?.levelComprehensiveoutpatient ===
                  DiagnosesRecommendations?.levelComprehensiveoutpatient
                }
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.levelComprehensiveoutpatient === DiagnosesRecommendations?.levelComprehensiveoutpatient;
                  updateRecommendation("levelComprehensiveoutpatient",!result);
                }}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Level 2.5 Comprehensive outpatient (SACOT){" "}
              </label>
            </div>
            <div className="col-lg-6 d-flex">
              <input
                style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                type="checkbox"
                className="form-check-input"
                value={DiagnosesRecommendations?.levelNonhospital}
                checked={formData?.diagnoseRecommendationDetails?.recommendation?.levelNonhospital === DiagnosesRecommendations?.levelNonhospital}
                onClick={() => {
                  const result = formData?.diagnoseRecommendationDetails?.recommendation?.levelNonhospital === DiagnosesRecommendations?.levelNonhospital;
                  updateRecommendation("levelNonhospital",!result);
                }}
              />
              <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                Level 3.7 Non-hospital medical detox{" "}
              </label>
            </div>
          </Row>
        </Card>

        <Card>
          <div className="column">
            <div className="row">
              <div className="col-6">
                <p className="fw-500" style={{ margin: "0.3rem" }}>
                  Clinician Signature and Credentials:
                </p>
                <div className="sign w-full">
                  <div className="sigCanvasNewDiv w-full">
                    {clinicianSign ? (
                      <img className="sigCanvasNew w-full" src={formData?.diagnoseRecommendationDetails?.signature} />
                    ) : (
                      <SignaturePad
                        backgroundColor="rgb(245,242,241)"
                        penColor="black"
                        ref={signPad}
                        canvasProps={{ className: "sigCanvasNew w-full" }}
                      ></SignaturePad>
                    )}
                  </div>
                </div>
                {!clinicianSign ? (
                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <a
                        onClick={() => {
                          clearsign();
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        Clear
                      </a>
                    </div>
                    <div>
                      <a
                        onClick={() => {
                          saveSign();
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        Save
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    <a onClick={() => editSign()} className="btn btn-primary btn-sm mt-2">
                      Edit
                    </a>
                  </div>
                  // <></>
                )}

                <div className="d-flex">
                  <p className="fw-500" style={{ margin: "0.3rem" }}>
                    Date Of Clinician Signature :
                  </p>
                  <input
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "1px solid gray",
                      marginTop: "0.1rem",
                      backgroundColor: "unset",
                      textAlign: "center",
                    }}
                    type="date"
                    disabled={clinicianSign}
                    // max={moment().format("YYYY-MM-DD")}
                    value={
                      formData?.diagnoseRecommendationDetails?.dateOfClinicianSignature
                        ? moment.utc(formData?.diagnoseRecommendationDetails?.dateOfClinicianSignature).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        diagnoseRecommendationDetails: {
                          ...formData?.diagnoseRecommendationDetails,
                          dateOfClinicianSignature: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>

              {/* <div className="col-2"></div> */}

              {/* <div className="col-5">
                <p className="fw-500" style={{ margin: "0.3rem" }}>
                  Client Signature and Credentials:
                </p>
                <div className="sign w-full">
                  <div className="sigCanvasNewDiv w-full">
                    {clientSignature ? (
                      <img className="sigCanvasNew w-full" src={formData?.diagnoseRecommendationDetails?.clientSignature} />
                    ) : (
                      <SignaturePad
                        backgroundColor="rgb(245,242,241)"
                        penColor="black"
                        ref={signPad}
                        canvasProps={{ className: "sigCanvasNew w-full" }}
                      ></SignaturePad>
                    )}
                  </div>
                </div>
              </div> */}
            </div>

            <div className="row mt-3">
              <div className="col-6">
                <div className="d-flex flex-row justify-around">
                  <div className="">
                    <p className="fw-500" style={{ margin: "0.3rem" }}>
                      Printed Name:
                    </p>
                  </div>
                  <div className="">
                    <input
                      style={{ border: "none", marginTop: "0.1rem", marginLeft: "0.1rem", marginRight: "0.1rem", backgroundColor: "#F1F1F1" }}
                      type="text"
                      value={formData?.diagnoseRecommendationDetails?.printedName ?? ""}
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          diagnoseRecommendationDetails: {
                            ...formData?.diagnoseRecommendationDetails,
                            printedName: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-4">
                    <p className="fw-500" style={{ margin: "0.3rem" }}>
                      Date:
                    </p>
                  </div>
                  <div className="col-8">
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid gray",
                        marginTop: "0.1rem",
                        backgroundColor: "unset",
                        textAlign: "center",
                      }}
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      value={
                        formData?.diagnoseRecommendationDetails?.date ? moment.utc(formData?.diagnoseRecommendationDetails?.date).format("YYYY-MM-DD") : ""
                      }
                      onChange={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          diagnoseRecommendationDetails: {
                            ...formData?.diagnoseRecommendationDetails,
                            date: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-primary relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Submit
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 11 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormEleven;
