import React, { MouseEventHandler, useContext, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import bgIcon from "../../../assets/images/layouts/Intersect.png";
// import closeIcon from "../../../assets/images/icons/close_icon.png";
// import scheduleIcon from "../../../assets/images/icons/schedule_calendar.svg";
// import inactiveIcon from "../../../assets/images/icons/inactive_icon.svg";
// import goalIcon from "../../../assets/images/icons/goal.svg";
// import uploadIcon from "../../../assets/images/icons/upload.svg";
import { FriendRequest } from "src/models/FriendRequest";
import { TherapistService } from "src/services/TherapistService";
import { Ethinicity } from "src/models/Ethnicity";
import { SelectedExperienceTag } from "src/models/ExperienceTag";
import { Therapist } from "src/models/Therapist";
import { Util } from "src/Util";
import Spinner from "src/common/spinner/spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { TreatmentHistory } from "src/models/TreatmentHistory";
// import { useHistory } from "react-router-dom";
import UserContext from "src/context/UserContext";
import { UserRole } from "src/models/User";
// import AppointmentModal from "../Popup/AppointmentModal";
// import moment from "moment";
// import { ModalType, Repeat } from "src/models/Appointment";
// import { Therapist } from "src/models/Therapist";
// import { Client } from "src/models/Client";
// import ErrorModal from "../Popup/ErrorModal";
// import Modal4 from "../Popup/Modal4";
// import dayjs, { Dayjs } from "dayjs";
// import HomeWorksAndGoals from "../Chat/HomeWorksAndGoals";
// import UploadDocuments from "../Chat/UploadDocuments";
// import SkeltonClientRequest from "src/common/skeleton/SkeltonClientRequest";
// import Swal from "sweetalert2";
// import { TherapistService } from "src/services/TherapistService";
// import { toast } from "react-toastify";
// import { ClientService } from "src/services/ClientService";

interface props {
    noteData?: any;
    onShare: (value: TreatmentHistory) => void;
}

const TherapistCard: React.FC<props> = ({
    noteData,
    onShare,
}) => {
    const [user] = useContext(UserContext);
    const [selectedName, setSelectedName] = useState("");
    const [gender, setGender] = useState("");
    const [selectedEthnicity, setSelectedEthnicity] = useState("");
    const [therapistList, setTherapistList] = useState<Therapist[]>([]);
    const [selectedProfession, setSelectedProfession] = useState("");
    const [selectedExpTags, setSelectedExpTags] = useState<any[]>([] as SelectedExperienceTag[]);
    const [offset, setOffset] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const LIMIT = 3

    useEffect(() => {
        getSearchedTherapistList()
    }, [noteData]);


    const getSearchedTherapistList = () => {
        setIsLoading(true);
        let discoverObject;
        if (user?.role == "THERAPIST") {
            discoverObject = {
                clientId: noteData?.clientId?._id,
                therapistId: user?._id,
            };
        } else {
            discoverObject = {
                clientId: noteData?.clientId,
                therapistId: noteData?.therapistId,
            };
        }

        TherapistService.searchTherapistsByTherapist(discoverObject, LIMIT, offset).then(res => {
            if (res.success) {
                setTherapistList(res?.data)
            }
            setIsLoading(false);
        });
    }

    const onNext = () => {
        setIsLoading(true);
        let discoverObject;
        if (user?.role == "THERAPIST") {
            discoverObject = {
                clientId: noteData?.clientId?._id,
                therapistId: user?._id,
            };
        } else {
            discoverObject = {
                clientId: noteData?.clientId,
                therapistId: noteData?.therapistId,
            };
        }
        TherapistService.searchTherapistsByTherapist(discoverObject, LIMIT, offset + 1).then(res => {
            if (res.success) {
                setTherapistList(res?.data)
                setOffset(offset + 1)
            }
            setIsLoading(false);
        });
    }
    const onBack = () => {
        setIsLoading(true);
        let discoverObject;
        if (user?.role == "THERAPIST") {
            discoverObject = {
                clientId: noteData?.clientId?._id,
                therapistId: user?._id,
            };
        } else {
            discoverObject = {
                clientId: noteData?.clientId,
                therapistId: noteData?.therapistId,
            };
        }
        TherapistService.searchTherapistsByTherapist(discoverObject, LIMIT, offset - 1).then(res => {
            if (res.success) {
                setTherapistList(res?.data)
                setOffset(offset - 1)
            }
            setIsLoading(false);
        });
    }

    const handleClickedTherapist = (therapist: Therapist) => {
        if (!noteData?._id) {
            toast.error('Invalid treatment history Id', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }
        if (!therapist?._id) {
            toast.error('Invalid sub therapist Id', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }
        Swal.fire({
            icon: "question",
            title: `Are you sure you want to share with ${therapist?.firstname} ${therapist?.lastname}?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                const data = {
                    treatmentHistoryId: noteData?._id,
                    subTherapistId: therapist?._id
                }
                TherapistService.updateSubTherapistTreatmentHistory(data).then(res => {
                    if (res.success) {
                        if (res?.data) {
                            onShare(res?.data)
                        }
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    }

    return (
        <React.Fragment>


            <Row className="pt-4">
                {isLoading && <Spinner />}
                {therapistList !== undefined && therapistList.length > 0 ? (
                    <div className="custom-vertical-scroll">
                        {therapistList &&
                            therapistList?.map((therapist: Therapist, index: number) => (
                                <Col
                                    key={index}
                                    md={12} className="d-flex justify-content-center mb-1 mt-1">
                                    <Card className="match-card-dashboard position-relative min-w-100">

                                        <div className="d-flex justify-content-between">
                                            <img
                                                src={bgIcon} alt="Icon" className="card-img-top " style={{ width: "123.68px", height: "85.72px" }} />
                                        </div>

                                        <CardBody className="d-sm-flex flex-sm-row mtt-86">
                                            <div
                                                className="col-12 col-sm-7 d-sm-flex  justify-content-sm-between"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleClickedTherapist(therapist)}
                                            >
                                                <div className="col-sm-6 col-12">
                                                    {!therapist?.photoId ? (
                                                        <div className="avatar-md mx-auto ">
                                                            <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>{therapist?.firstname?.charAt(0)}</span>
                                                        </div>
                                                    ) : (
                                                        <div className="col-12 d-flex justify-content-center align-items-center">
                                                            <img src={Util.fileURL(therapist?.photoId?._id)} className="avatar-md rounded-circle" />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-11 col-12 d-flex flex-column justify-content-center align-items-center">
                                                    <h5 className="mb-0 d-flex mt-2 justify-content-start fs-14 align-items-start fc-black">
                                                        {therapist?.firstname} {therapist?.lastname}
                                                    </h5>
                                                    <p className="mb-0 fc-primary">THERAPIST</p>
                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                    </div>
                ) : (
                    <>
                        {!isLoading && therapistList.length == 0 && <div className="no-appointments">No matched therapists found.</div>}
                    </>
                )}
                <div>
                </div>
            </Row>
            <Row>
                <div className="d-flex justify-content-between mt-2">
                    <div>
                        {offset > 1 && !isLoading && (
                            <button className="btn btn-secondary btn-sm" onClick={onBack}>
                                <i className="bx bx-left-arrow-alt me-2"></i> Back
                            </button>
                        )}
                    </div>
                    <div>
                        {therapistList?.length % 3 === 0 && !isLoading && therapistList?.length > 2 && (
                            <button className="btn btn-primary btn-sm" onClick={onNext}>
                                Next <i className="bx bx-right-arrow-alt ms-2"></i>
                            </button>
                        )}
                    </div>
                </div>
            </Row>


        </React.Fragment>
    );
};

export default TherapistCard;
