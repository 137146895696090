import React, { useEffect, useState } from "react";
// import { AudioContext } from "../context/AudioContext";
import UserContext from "../context/UserContext";
import { environment } from "../environment/environment";
import { RequestState } from "../RequestState";
import { AuthService } from "../services/AuthService";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";

const Auth: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [token, setToken] = useState<string>();
  const [userRequestState, setUserRequestState] = useState<RequestState>(RequestState.INITIAL);

  if (!token) {
    const token = AuthService.getToken();

    if (token) {
      setToken(token);
    } else {
      location.href = environment.app_url + "/signin";
    }
  }

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  useEffect(() => {
    if (token && !user && userRequestState === RequestState.INITIAL) {
      setUserRequestState(RequestState.LOADING);

      AuthService.getMe()
        .then(res => {
          if (res.success) {
            const advancedMatching: AdvancedMatching = {
              em: res.data.email,
              ct: "",
              country: "",
              db: "",
              fn: "",
              ge: "",
              ln: "",
              ph: "",
              st: "",
              zp: ""
            };

            const options = {
              autoConfig: true,
              debug: false,
            };

            ReactPixel.init(environment.facebook_pixel_code, advancedMatching, options);

            ReactPixel.pageView();

            setUser(res.data);

            setUserRequestState(RequestState.SUCCESS);
          } else {
            setUserRequestState(RequestState.FAILED);
          }
        }).catch(() => {
          setUserRequestState(RequestState.FAILED);
        });
    }
  }, [token]);

  switch (userRequestState) {
    case RequestState.FAILED:
      logout();
      return <br />;
    case RequestState.SUCCESS:
      return (
        <div>
          <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>
        </div>
      );
    default:
      return <div className="pre-loader min-h-screen ">{/* <img className="h14 w14 m-auto mt-72 block " src={logo} /> */}</div>;
  }
};

export default Auth;
