import axios from "axios";
import { FriendRequest, FriendRequestSearchData } from "../models/FriendRequest";
import { Client } from "../models/Client";
import { AppResponse } from "../models/Response";
import { CustomerReviewData, Review, ReviewData } from "../models/Review";
import { Util } from "../Util";
import { Upload } from "tus-js-client";
import { User, UserDetails } from "../models/User";
import { Insurance, InsuranceCompanyModel } from "../models/Insurance";

export class ClientService {
  static getAllAddedTherapists(arg0: number, updatedSkip: number) {
    throw new Error("Method not implemented.");
  }
  public static async addReview(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("addReview");

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async addClientReview(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("client-review");

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  // get reveiws
  public static async getAllTherapistApprovedReviews(data?: any, limit?: number, offset?: number): Promise<AppResponse<CustomerReviewData[]>> {
    const url = Util.apiAuthUrl(`get-all-approved-reviews-by-therapist/` + limit + "/" + offset);
    return await axios.post<void, AppResponse<CustomerReviewData[]>>(url, data);
  }

  public static async getTherapistApprovedReviewByTherapistId(data?: any, limit?: number, offset?: number): Promise<AppResponse<CustomerReviewData[]>> {
    const url = Util.apiAuthUrl(`get-all-approved-reviews-by-therapistId/` + limit + "/" + offset);
    return await axios.post<void, AppResponse<CustomerReviewData[]>>(url, data);
  }

  // public static async getAllTherapistApprovedReviews(data?:any,limit?: number, offset?: number): Promise<AppResponse<CustomerReviewData[]>> {
  //   const url = Util.apiAuthUrl(`get-all-approved-reviews-by-therapist/` + limit + "/" + offset);
  //   return await axios.get<void, AppResponse<CustomerReviewData[]>>(url);
  // }

  public static async viewReviewsByTherapistId(data: any): Promise<AppResponse<ReviewData[]>> {
    const url = Util.apiAuthUrl("viewReviewsByTherapistId/" + data);
    return await axios.get<Partial<Review>, AppResponse<ReviewData[]>>(url);
  }

  public static async searchClients(id: any): Promise<AppResponse<Client[]>> {
    const url = Util.apiAuthUrl("getClientRequests/" + id);
    return await axios.get<Partial<Client>, AppResponse<Client[]>>(url);
  }

  public static async likeTherapist(therapistId: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("createRequestByClient/" + therapistId);
    return await axios.post<Partial<Client>, AppResponse<string>>(url);
  }

  public static async likeTherapistPublic(therapistId: any, clientId: any): Promise<AppResponse<string>> {
    const url = Util.apiPublicUrl("createRequestByClient/" + therapistId + "/" + clientId);
    return await axios.post<Partial<Client>, AppResponse<string>>(url);
  }

  public static async dislikeTherapist(therapistId: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("dislikeTherapist/" + therapistId);
    return await axios.get<Partial<Client>, AppResponse<string>>(url);
  }

  public static async addPreferences(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("addPreferences");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchAddedClients(id: any): Promise<AppResponse<Client[]>> {
    const url = Util.apiAuthUrl("getAddedClients/" + id);
    return await axios.get<Partial<Client>, AppResponse<Client[]>>(url);
  }

  public static async getRequestsByTherapist(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("viewAllRequestsByTherapist" + "/" + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getRequestsByClient(): Promise<AppResponse<FriendRequest[]>> {
    const url = Util.apiAuthUrl("viewAllSentRequestsByClient");
    return await axios.get<Partial<FriendRequest>, AppResponse<FriendRequest[]>>(url);
  }

  public static async unfriendTherapist(requestId: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("unfriendUser/" + requestId);
    return await axios.post<Partial<Client>, AppResponse<string>>(url);
  }

  public static async addInsurancePlan(data: any, fileToUpload: FileList, fileToUploadBack: FileList): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("create/insurance");
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }
    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }

    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }

  public static async UpdateInsurancePlan(data: any, fileToUpload: FileList, fileToUploadBack: FileList): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("update/insurance");
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }

    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }

    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }

  public static async UpdateInsurancePlanByAdmin(
    userId: string,
    data: any,
    fileToUpload: FileList,
    fileToUploadBack: FileList
  ): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("update/insuranceByAdmin/" + userId);
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }

    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }

    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }

  public static async ViewInsuranceById(data: any): Promise<AppResponse<InsuranceCompanyModel>> {
    const url = Util.apiAuthUrl("getInsuranceById/" + data);
    return await axios.get<Partial<InsuranceCompanyModel>, AppResponse<InsuranceCompanyModel>>(url);
  }

  public static async ViewInsuranceByClientId(data: any): Promise<AppResponse<Insurance>> {
    const url = Util.apiAuthUrl("viewSingleInsurance/" + data);
    return await axios.get<Partial<Insurance>, AppResponse<Insurance>>(url);
  }

  public static async updateWordCount(data: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("updateWordCount");
    return await axios.post<void, AppResponse<string>>(url, data);
  }

  public static async getClientByClientId(clientId: string): Promise<AppResponse<Client>> {
    const url = Util.apiAuthUrl("getClienByClientId/" + clientId);
    return await axios.get<Partial<Client>, AppResponse<Client>>(url);
  }

  public static async getSingleClientByClientId(clientId: string): Promise<AppResponse<Client>> {
    const url = Util.apiAuthUrl("getSingleClienByClientId/" + clientId);
    return await axios.get<Partial<Client>, AppResponse<Client>>(url);
  }

  public static async checkIfUserIsFriend(userId: string): Promise<AppResponse<FriendRequest>> {
    const url = Util.apiAuthUrl("checkIfUserIsFriend/" + userId);
    return await axios.get<Partial<FriendRequest>, AppResponse<FriendRequest>>(url);
  }

  public static async checkIfUserIsFriendPublic(userId: string, clientId: string): Promise<AppResponse<FriendRequest>> {
    const url = Util.apiPublicUrl("checkIfUserIsFriend/" + userId + "/" + clientId);
    return await axios.get<Partial<FriendRequest>, AppResponse<FriendRequest>>(url);
  }

  public static async checkIfUserIsFriendAdmin(clientId: string, therapistId: string): Promise<AppResponse<FriendRequest>> {
    const url = Util.apiAuthUrl("checkIfUserIsFriendAdmin/" + clientId + "/" + therapistId);
    return await axios.get<Partial<FriendRequest>, AppResponse<FriendRequest>>(url);
  }

  public static async viewAllRequestsByTherapistAdmin(therapistId: string): Promise<AppResponse<FriendRequest[]>> {
    const url = Util.apiAuthUrl("viewAllFriendsByTherapistAdmin/" + therapistId);
    return await axios.get<Partial<FriendRequest[]>, AppResponse<FriendRequest[]>>(url);
  }

  public static async signupClient(data: UserDetails, likeTherapist: any, appointmentObj: any, referralInfo: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("registerWithEmail");

    const requestData = {
      userData: data,
      likeTherapist: likeTherapist,
      appointmentObj: appointmentObj,
      referralInfo: referralInfo,
    };

    return await axios.post<Partial<Client>, AppResponse<UserDetails>>(url, requestData);
  }

  public static async verifyUser(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("verifyByCode");
    return await axios.post<Partial<Client>, AppResponse<UserDetails>>(url, data);
  }

  public static async sendReferrealEmail(data: object): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("sendReferrelMail");
    return await axios.post<Partial<Client>, AppResponse<string>>(url, data);
  }

  public static async sendReferrealMessage(data: object): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("sendReferralSms");
    return await axios.post<Partial<Client>, AppResponse<string>>(url, data);
  }

  public static async getClientDashboardStats(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("client-dashboard-stats");
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async verifyUserLink(data: any): Promise<AppResponse<string>> {
    const url = Util.apiPublicUrl("verifyByLink");
    return await axios.post<Partial<Client>, AppResponse<string>>(url, data);
  }

  public static async resendVerifyCode(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("resendVerificationCode");
    return await axios.post<Partial<Client>, AppResponse<UserDetails>>(url, data);
  }

  public static async updateAssesmentSignature(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("update-assesment-signature");
    return await axios.post<Partial<Client>, AppResponse<UserDetails>>(url, data);
  }

  public static async updateTherapyPlanSignature(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiPublicUrl("update-therapy-plan-signature");
    return await axios.post<Partial<Client>, AppResponse<UserDetails>>(url, data);
  }

  public static async getAllPendingCopaymentAmounts(userId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getPendingCopaymentsByClientId/" + userId);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllPaidCopaymentAmounts(userId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getPaidCopaymentsByClientId/" + userId);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async payAllPendingCopaymentAmounts(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("payPendingCopaymentsByClientId");
    return await axios.post<Partial<any>, AppResponse<any>>(url);
  }

  public static async sendSMSAndEmailForClient(userId: any, copaymentAmount: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("sendSMSAndEmailForClient/" + userId + "/" + copaymentAmount);
    return await axios.post<Partial<any>, AppResponse<any>>(url);
  }

  public static async createStripeAccountAndPay(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createClientStripeAccountAndPayCopaymnt`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async setGoogleCalendarAccessToFalse(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("setGoogleCalendarAccessToFalse");
    return await axios.get(url);
  }

  public static async getUpcommingAppointment(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("client-appointment");
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async UpdateInsurancePlanByUser(
    userId: string,
    data: any,
    fileToUpload: FileList,
    fileToUploadBack: FileList
  ): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("update/insuranceByUser/" + userId);
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);
    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }
    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }
    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }

  public static async updateDefaultInsurance(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("updateUserInsurance");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async addSecondaryInsurance(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("addSecondaryUserInsurance");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getUserById(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("userDetailsById");
    return await axios.post<Partial<Client>, AppResponse<any>>(url);
  }

  public static async changePrimaryTherapist(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("changePrimaryTherapist");
    return await axios.post<Partial<Client>, AppResponse<any>>(url, data);
  }

  public static async getAllMatchedTherapists(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getAllMatchedTherapists");
    return await axios.get<Partial<Client>, AppResponse<any>>(url);
  }

  public static async checkClientHasCompletedSessionsOnThisWeek(
    therapistId: string,
    selectedDate: string,
    clientId: string | undefined
  ): Promise<AppResponse<any>> {
    const data = {
      clientId,
      therapistId,
      selectedDate,
    };
    const url = Util.apiAuthUrl("checkClientHasCompletedSessionsOnThisWeek");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async sendReferralLinkViaEmail(data: object): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("send-referral-linkViaMail");
    return await axios.post<Partial<Client>, AppResponse<string>>(url, data);
  }

  public static async sendReferralLinkViaSms(data: object): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("send-referral-linkViaSMS");
    return await axios.post<Partial<Client>, AppResponse<string>>(url, data);
  }

  public static async getClientDetailsByIdPublic(id: string): Promise<AppResponse<Client>> {
    const url = Util.apiPublicUrl("getUserByUserId/" + id);
    return await axios.get<Partial<Client>, AppResponse<Client>>(url);
  }

  public static async updateDigitalAssessmentClientSignature(data: { id: string; signature: string }): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("updateClientDigitalAssessmentSignature");
    return await axios.post<Partial<Client>, AppResponse<Client>>(url, data);
  }

}
