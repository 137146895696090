import React, { useContext, useEffect, useState } from "react";
import { Card, CardTitle, NavLink, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import images from "src/assets/images";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import UserContext from "src/context/UserContext";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import Spinner from "src/common/spinner/spinner";
import GroupChatContext from "src/context/GroupChatContext";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateGroupChatSessionModal from "src/pages/Popup/GroupChatModals/CreateGroupChatSessionModal";
import moment from "moment";
import { Util } from "src/Util";
import { SessionsInSelectedChatGroupModel } from "src/models/GroupChat/SessionsInSelectedChatGroup";
import UpdateGroupChatModal from "src/pages/Popup/GroupChatModals/UpdateGroupChatModal";
import UpdateGroupChatSessionModal from "src/pages/Popup/GroupChatModals/UpdateGroupChatSessionModal";
import chat from "../../../assets/images/chat/chat.png";
import sessions from "../../../assets/images/chat/sessions.png";
import schedule_session from "../../../assets/images/chat/Schedule Session.png";
import { NotificationModel } from "src/models/Notification";
import Swal from "sweetalert2";
import { VonageServices } from "../vonage/vonageServices";
import { CallInitializeData } from "src/models/CallInitializeData";
import { Role } from "src/models/Role";
import CallInitializationContext from "src/context/CallInitializationContext";
import LocalCallContext from "src/context/LocalCallContext";
import { v4 as uuidv4 } from "uuid";
import Modal6 from "src/pages/Popup/Modal6";

const SelectedGroupChatSessionView: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
    reloadSessionList,
    setReloadSessionList,
  ] = useContext(GroupChatContext);
  const [isGettingSessions, setIsGettingSessions] = useState(true);
  const [isGettingSessionsError, setIsGettingSessionsError] = useState(false);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [showTimeSelectModal, setShowTimeSelectModal] = useState(false);
  const history = useHistory();
  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [totalDataCountInDb, setTotalDataCountInDb] = useState(0);
  const [sessionsInSelectedChatGroup, setSessionsInSelectedChatGroup] = useState([] as SessionsInSelectedChatGroupModel[]);
  const [showUpdateGroupChatSessionModal, setShowUpdateGroupChatSessionModal] = useState<boolean>(false);
  const initialSelectedSession: SessionsInSelectedChatGroupModel = { _id: "", groupId: "", start: "", callingStatus: "", createdAt: "", createdBy: "" };
  const [selectedSessionForUpdate, setSelectedSessionForUpdate] = useState<SessionsInSelectedChatGroupModel>(initialSelectedSession);
  const [isRemovingSession, setIsRemovingSession] = useState(false);
  const [removingSessionId, setRemovingSessionId] = useState("");

  // const [selectedGroupId, setSelectedGroupId] = useState("");
  // useEffect(() => {
  //   if (selectedGroupData == null || selectedGroupData._id == null || selectedGroupData._id == "") {
  //     setIsGettingSessions(true);
  //     setIsGettingSessionsError(false);
  //     setShowCreateGroupChatSessionModal(false);
  //     setShowUpdateGroupChatSessionModal(false);
  //     setSelectedSessionForUpdate(initialSelectedSession);
  //     setOffset(0);
  //     setTotalDataCountInDb(0);
  //     setFinalSearchableString("");
  //     setSessionsInSelectedChatGroup([]);
  //     setSelectedGroupIdForShowMembers("");
  //     setSelectedGroupIdForShowRequests("");
  //     setSelectedGroupId("");
  //   }
  //   if (selectedGroupData != null && selectedGroupData._id != null && selectedGroupData._id != "" && selectedGroupId != selectedGroupData._id) {
  //     setIsGettingSessions(true);
  //     setIsGettingSessionsError(false);
  //     setShowCreateGroupChatSessionModal(false);
  //     setShowUpdateGroupChatSessionModal(false);
  //     setSelectedSessionForUpdate(initialSelectedSession);
  //     setOffset(0);
  //     setTotalDataCountInDb(0);
  //     setFinalSearchableString("");
  //     setSessionsInSelectedChatGroup([]);
  //     setSelectedGroupIdForShowMembers("");
  //     setSelectedGroupIdForShowRequests("");

  //     setSelectedGroupId(selectedGroupData._id);
  //     initialLoading(true, false, false, true, false);
  //   }
  // }, [selectedGroupData]);

  useEffect(() => {
    initialLoading(true, false, false, true, false);
  }, []);

  useEffect(() => {
    if (reloadSessionList) {
      setReloadSessionList(false);
      initialLoading(true, false, false, true, false);
    }
  }, [reloadSessionList]);

  const initialLoading = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    console.log("initialLoading chat sessions");
    setIsGettingSessions(true);
    await getAllSessions(next, isSearch, isRemove, isInitial, isAdd);
    setIsGettingSessions(false);
  };

  const getAllSessions = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    try {
      let previousCount = 0;
      let finalOffset = offset;
      if (isInitial || isSearch) {
        finalOffset = 0;
      } else if (isRemove) {
        previousCount = sessionsInSelectedChatGroup.length;
        if (isRemove && previousCount > 1) {
          if (finalOffset - 1 >= 0) {
            finalOffset = finalOffset - 1;
          }
        } else {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      } else if (isAdd) {
        if (finalOffset - 1 >= 0) {
          finalOffset = finalOffset - 1;
        }
      } else {
        if (!next) {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      }
      const dataObject = {
        groupId: selectedGroupData._id,
        limit: limit,
        offset: finalOffset * limit,
      };
      console.log(offset);
      const res = await GroupChatService.getAllChatGroupsSessions(dataObject);
      if (res.success && res.data) {
        if (res.data != null && res.data.sessions != null && res.data.count != null) {
          setTotalDataCountInDb(res.data.count);
          setSessionsInSelectedChatGroup(res?.data.sessions);

          if (isSearch || isInitial) {
            setOffset(1);
          } else {
            if (isRemove) {
              if (isRemove && previousCount == 1) {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            } else if (isAdd) {
            } else {
              if (next) {
                setOffset(offset + 1);
              } else {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            }
          }
        }
      } else {
        setIsGettingSessionsError(true);
        toast.error("Retrieving chat groups sessions failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsGettingSessionsError(true);
      toast.error("Retrieving chat groups sessions failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const updateSession = (session: SessionsInSelectedChatGroupModel) => {
    setSelectedSessionForUpdate(session);
    setShowUpdateGroupChatSessionModal(true);
  };

  const deleteSession = async (session: SessionsInSelectedChatGroupModel) => {
    try {
      if (
        !isRemovingSession &&
        user?.role == "THERAPIST" &&
        session.callingStatus == "PENDING" &&
        user._id == selectedGroupData.createdBy &&
        selectedGroupData._id == session.groupId &&
        user._id == session.createdBy
      ) {
        setRemovingSessionId(session._id);
        setIsRemovingSession(true);
        const dataObject = {
          sessionId: session._id,
        };
        const res = await GroupChatService.deleteGroupChatSession(dataObject);
        if (res && res.success) {
          setIsRemovingSession(false);

          toast.success("Session deleted successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          initialLoading(true, false, false, true, false);
        } else {
          setIsRemovingSession(false);
          toast.error("Failed to delete session!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsRemovingSession(false);
      } else {
        toast.error("Please wait, deleting session in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsRemovingSession(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const closeUpdateModal = (value: boolean) => {
    try {
      setSelectedSessionForUpdate(initialSelectedSession);
      setShowUpdateGroupChatSessionModal(false);
      if (value) {
        initialLoading(true, false, false, true, false);
      }
    } catch (error) {}
  };

  const startNewMeeting = (callingStatus: string, sessionId: string) => {
    if (callingStatus == "ENDED") {
      console.log("Call is ended!");
    } else if (callingStatus == "PENDING") {
      setShowTimeSelectModal(true);
      setSelectedSessionId(sessionId);
    } else {
      joinToMeeting(callingStatus, sessionId);
    }
  };
  const startInstantMeetingInitial = (time: number, showModal: boolean, getCall: boolean, isVideoCall: boolean) => {
    setShowTimeSelectModal(showModal);
    if (isVideoCall) {
      if (getCall) {
        startInstantMeeting(isVideoCall, time);
      }
    } else {
      startAudioCallMeeting(time);
    }
  };
  const startInstantMeeting = (isVideoCall: boolean, meetingTime: number) => {
    console.log(selectedGroupData);
    if (user?.role == Role.THERAPIST) {
      const callInitializeData: CallInitializeData = {
        start: true,
        isInstantMeeting: true,
        isAppointmentBased: false,
        appointmentId: "ddddddd",
        recieverId: user?._id || "",
        meetingTime: meetingTime,
        isTranscribeAllowed: true,
        meetingId: "",
        isAudioCall: !isVideoCall,
        isGroupCall: true,
        groupCallId: selectedSessionId,
      };
      // setLocalCallInitialize(true);
      setLocalCallInitializeData(callInitializeData);
      // setOutGoinCallTimeOutFunction();
      const roomNamePartOne = user?._id;
      const roomNamePartTwo = selectedGroupData._id;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room-group/${roomName}`;
      history.push(vonageUrl);
    }
  };
  const startAudioCallMeeting = (meetingTime: number) => {
    const callInitializeData: CallInitializeData = {
      start: true,
      isInstantMeeting: true,
      isAppointmentBased: false,
      appointmentId: "ddddddd",
      recieverId: "xxxxxxx",
      meetingTime: meetingTime,
      isTranscribeAllowed: true,
      meetingId: "",
      isAudioCall: true,
    };
    // setLocalCallInitialize(true);
    setLocalCallInitializeData(callInitializeData);
    setOutGoinCallTimeOutFunction();
    const roomNamePartOne = user?._id;
    const uniqueId = uuidv4();
    const roomName = roomNamePartOne + uniqueId;
    const vonageUrl = `/room-group/${roomName}`;
    history.push(vonageUrl);
  };
  const joinToMeeting = async (callingStatus: string, sessionId: string) => {
    console.log(callingStatus);
    if (callingStatus == "PENDING") {
      console.log("Still not started!");
    } else if (callingStatus == "ENDED") {
      console.log("Call is ended!");
    } else {
      const meetingRes = await VonageServices.getMeetingDetailsByGroupCallId(sessionId);
      if (meetingRes.success) {
        const meetingData = meetingRes.data;
        const callInitializeData: CallInitializeData = {
          start: false,
          isInstantMeeting: true,
          isAppointmentBased: false,
          appointmentId: "ddddddd",
          recieverId: "xxxxxxx",
          meetingTime: meetingData.meetingDuration,
          isTranscribeAllowed: true,
          meetingId: "",
          isAudioCall: meetingData.isAudioCall,
        };
        // setLocalCallInitialize(true);
        setLocalCallInitializeData(callInitializeData);
        setOutGoinCallTimeOutFunction();
        const vonageUrl = `/room-group/${meetingData.vonageSessionName}`;
        history.push(vonageUrl);
      }
    }
  };

  return (
    <React.Fragment>
      {showTimeSelectModal && <Modal6 setShowModal={startInstantMeetingInitial} showModal={showTimeSelectModal} />}
      {user?.role == "THERAPIST" &&
        user._id == selectedGroupData.createdBy &&
        user._id == selectedSessionForUpdate.createdBy &&
        selectedGroupData._id == selectedSessionForUpdate.groupId &&
        showUpdateGroupChatSessionModal && (
          <UpdateGroupChatSessionModal setShowModal={closeUpdateModal} showModal={showUpdateGroupChatSessionModal} selectedSession={selectedSessionForUpdate} />
        )}

      <div className="">
        {isGettingSessions ? (
          <div
            style={{
              height: "410px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <div className="group-chat-empty-view">
              <Spinner />
            </div>
          </div>
        ) : !isGettingSessions &&
          isGettingSessionsError &&
          (sessionsInSelectedChatGroup == null || sessionsInSelectedChatGroup.length == null || sessionsInSelectedChatGroup.length <= 0) ? (
          <div
            style={{
              height: "410px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <div className="group-chat-empty-view">
              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
              <div className="mt-1">Error getting data</div>
            </div>
          </div>
        ) : !isGettingSessions &&
          sessionsInSelectedChatGroup != null &&
          sessionsInSelectedChatGroup.length != null &&
          sessionsInSelectedChatGroup.length > 0 ? (
          <div className="chat-conversation p-3 pb-1">
            <ul className="list-unstyled ">
              <div
                className="pe-2 pb-2"
                id="scrollableDiv"
                style={{
                  height: "410px",
                  overflow: "auto",
                  // display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <InfiniteScroll
                  hasMore={true}
                  next={() => {
                    // getMessagesByChatId(chatId, messagesOffsetPageNo + 1);
                    // setMessagesOffsetPageNo(messagesOffsetPageNo + 1);
                  }}
                  inverse={true}
                  loader={null}
                  dataLength={sessionsInSelectedChatGroup.length}
                  scrollableTarget="scrollableDiv"
                  ref={(ref: any) => {
                    // setMessageBox(ref)
                  }}
                >
                  {sessionsInSelectedChatGroup.length > 0 &&
                    sessionsInSelectedChatGroup.map((session, index: number) => {
                      const startDateMoment = moment(session?.start);
                      return (
                        <Card key={index} className=" group-chat-card-item">
                          <CardBody className="g-custom-card-body">
                            <div className="row">
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="d-flex justify-content-between align-items-center g-min-h100">
                                  <div className="d-flex justify-content-center align-items-center ms-2">
                                    <span className="g-time">{startDateMoment.format("YYYY-MM-DD h:mm A")}</span>
                                  </div>
                                </div>
                              </div>
                              {isRemovingSession && removingSessionId == session._id ? (
                                <div className="g-mt-0 col-12 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-center justify-content-sm-end align-item-center">
                                  <span className="g-completed-card">
                                    <p className="g-session-card-text">Loading...</p>
                                  </span>
                                </div>
                              ) : (
                                <div className="g-mt-0 col-12 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-center justify-content-sm-end align-item-center">
                                  {session.callingStatus != "ENDED" ? (
                                    user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy ? (
                                      <span
                                        className={
                                          session.callingStatus == "PENDING"
                                            ? "g-start-card cursor-pointer"
                                            : session.callingStatus == "ONGOING"
                                            ? "g-session-card cursor-pointer"
                                            : ""
                                        }
                                        onClick={() => {
                                          startNewMeeting(session.callingStatus, session._id);
                                        }}
                                      >
                                        <p className="g-session-card-text ">
                                          {session.callingStatus == "PENDING" ? "Start" : session.callingStatus == "ONGOING" ? "Join to Session" : ""}
                                        </p>
                                      </span>
                                    ) : (
                                      <span
                                        className={session.callingStatus == "ONGOING" ? "g-session-card cursor-pointer" : "g-start-card cursor-pointer"}
                                        onClick={() => {
                                          joinToMeeting(session.callingStatus, session._id);
                                        }}
                                      >
                                        <p className="g-session-card-text ">{session.callingStatus == "ONGOING" ? "Join to Session" : "Pending"}</p>
                                      </span>
                                    )
                                  ) : (
                                    <span className="g-completed-card">
                                      <p className="g-session-card-text">Completed</p>
                                    </span>
                                  )}

                                  {session.callingStatus == "PENDING" && user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && (
                                    <span className="ms-4 me-3 mt-1 mt-sm-2 ">
                                      <i
                                        className="fa fa-edit cursor-pointer"
                                        onClick={() => {
                                          updateSession(session);
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                  {session.callingStatus == "PENDING" && user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && (
                                    <span className="ms-3 me-3 mt-1 mt-sm-2 ">
                                      <i
                                        className="fa fa-trash cursor-pointer"
                                        onClick={() => {
                                          Swal.fire({
                                            icon: "warning",
                                            title: "Are you sure you want to delete this session from group",
                                            showCancelButton: true,
                                            confirmButtonText: "Remove",
                                            confirmButtonColor: "#FD7F00",
                                            cancelButtonColor: "#FD7F00",
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              deleteSession(session);
                                            }
                                          });
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      );
                    })}
                </InfiniteScroll>
              </div>
            </ul>
          </div>
        ) : (
          <div
            style={{
              height: "410px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <div className="group-chat-empty-view">
              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
              <div className="mt-1">No any scheduled sessions yet</div>
            </div>
          </div>
        )}

        {!isGettingSessions && sessionsInSelectedChatGroup != null && sessionsInSelectedChatGroup.length != null && sessionsInSelectedChatGroup.length > 0 && (
          <Col lg={12}>
            <Row className="ms-3 me-3 mb-4">
              <div className={offset > 1 ? "d-flex justify-content-between " : "d-flex justify-content-end "}>
                <div>
                  {offset > 1 && (
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => {
                        if (!isGettingSessions) {
                          initialLoading(false, false, false, false, false);
                        } else {
                          toast.error("Please wait", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                          });
                        }
                      }}
                    >
                      <i className="bx bx-left-arrow-alt me-2"></i> Back
                    </button>
                  )}
                </div>
                <div>
                  {offset > 0 && totalDataCountInDb > limit * offset && (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        if (!isGettingSessions) {
                          initialLoading(true, false, false, false, false);
                        } else {
                          toast.error("Please wait", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                          });
                        }
                      }}
                    >
                      Next <i className="bx bx-right-arrow-alt ms-2"></i>
                    </button>
                  )}
                </div>
              </div>
            </Row>
          </Col>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectedGroupChatSessionView;
