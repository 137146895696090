import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";
import { toast } from "react-toastify";

const Modal9: React.FC<{
  showModal: boolean;
  meetingTime: number;
  setShowModal: (time: number, showModal: boolean, extend: boolean) => void;
}> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(0, false, false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">Do you want to extend your call by {props.meetingTime} minutes.</CardTitle>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                onClick={() => {
                  try {
                    if (navigator.onLine) {
                      props.setShowModal(30, false, true);
                    } else {
                      toast.error(`No internet . Please check your internet connection`, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo-bar",
                      });
                    }
                  } catch (error) {
                    toast.error(`error occured ${error}`, {
                      position: toast.POSITION.TOP_RIGHT,
                      className: "foo-bar",
                    });
                  }
                }}
                className="btn btn-primary mr-2"
              >
                Extend
              </button>
              <button type="button" onClick={() => props.setShowModal(0, false, false)} className="btn btn-danger ml-3">
                Cancel
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal9;
