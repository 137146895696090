import React, { useEffect, useState } from "react";
import { CardTitle, Input, Modal } from "reactstrap";
import { Phrase } from "../../models/Notes";
import { NoteService } from "../../services/NoteService";
import { toast } from "react-toastify";
import { EditProps } from "../Notes/ChildTabs/ChildTab";

interface CreateEditPhraseProps {
  editProps?: EditProps;
  showModal: boolean;
  tabType?: string;
  lastItem?: Phrase;
  content: Phrase[];
  modalType: string;
  setContent: (value: Phrase[]) => void;
  findCurrentStage?: (value: string) => string;
  setShowModal: (value: boolean) => void;
}

const CreateEditPhrase: React.FC<CreateEditPhraseProps> = ({
  editProps,
  tabType,
  lastItem,
  showModal,
  content,
  modalType,
  setContent,
  findCurrentStage,
  setShowModal,
}: CreateEditPhraseProps) => {
  const [newPhrase, setNewPhrase] = useState<string>("");

  useEffect(() => {
    if (modalType == "edit") {
      if (editProps) {
        setNewPhrase(editProps.state?.value);
      }
    }
  }, [showModal]);

  const savePhrase = (event: any) => {
    event.preventDefault();

    let currentStage = " ";

    if (findCurrentStage && tabType) currentStage = findCurrentStage(tabType);

    const phrase = {
      value: newPhrase,
      tabType: tabType,
      lastItemId: lastItem?._id,
      stage: currentStage,
    };

    NoteService.createPhrase(phrase).then(res => {
      if (res.success) {
        setContent([...content, res.data]);
        setShowModal(!showModal);
        setNewPhrase("");
      } else {
        setShowModal(!showModal);
        return toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const editPhrase = (event: any) => {
    event.preventDefault();

    const editedPhrase = {
      phraseId: editProps?.state?._id,
      newValue: newPhrase,
    };

    NoteService.editPhrase(editedPhrase).then(res => {
      if (res.success) {
        if (editProps?.currentPosition) {
          content[editProps?.currentPosition] = res.data;
          setContent(content);
          setShowModal(!showModal);
          return toast.success("Phrase successfully edited", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        setShowModal(!showModal);
        return toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  return (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-header" style={{ border: "none" }}>
          <button type="button" onClick={() => setShowModal(!showModal)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <CardTitle className="mb-3 text-left">{modalType == "edit" ? "Edit Phrase" : "Create new phrase"}</CardTitle>

          <div className="mb-3">
            <Input
              type="textarea"
              style={{ resize: "none", borderRadius: "10px" }}
              value={newPhrase}
              onChange={event => setNewPhrase(event?.target.value)}
            ></Input>
          </div>

          <div className="flex justify-content-center mb-3">
            {modalType && modalType == "create" && (
              <button className="btn btn-primary mr-3" onClick={event => savePhrase(event)}>
                Save
              </button>
            )}
            {modalType && modalType == "edit" && (
              <button className="btn btn-primary mr-3" onClick={event => editPhrase(event)}>
                Save
              </button>
            )}
            <button className="btn btn-primary ml-3" onClick={() => setShowModal(!showModal)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateEditPhrase;
