import avatar1 from "./users/avatar-1.jpg";
import avatar2 from "./users/avatar-2.jpg";
import avatar3 from "./users/avatar-3.jpg";
import avatar4 from "./users/avatar-4.jpg";
import avatar5 from "./users/avatar-5.jpg";
import avatar6 from "./users/avatar-6.jpg";
import avatar7 from "./users/avatar-7.jpg";
import avatar8 from "./users/avatar-8.jpg";
import img1 from "./product/img-1.png";
import img2 from "./product/img-2.png";
import img3 from "./product/img-3.png";
import img4 from "./product/img-4.png";
import img5 from "./product/img-5.png";
import img6 from "./product/img-6.png";
import img7 from "./product/img-7.png";
import appointmentIcon from "./appointment-icon.svg";
import appointmentIconWhite from "./appointment-icon-white.svg";
import solidAvatarIcon from "./solid-avatar-icon-black.svg";
import solidAvatarIconWhite from "./solid-avatar-icon-white.svg";
import clockIcon from "./clock-icon.svg";
import sampleAvatarOne from "./sample-avatar-one.svg";
import sampleAvatarTwo from "./sample-avatar-two.svg";
import sampleAvatarThree from "./sample-avatar-three.svg";
import sampleAvatarFour from "./sample-avatar-four.svg";
import sampleBackgroundOne from "./sample-background-one.svg";
import sampleBackgroundTwo from "./sample-background-two.svg";
import micOn from "./mic-fill.svg";
import micStatus from "./mic-status.png";
import micOff from "./mic-mute-fill.svg";
import webcamOn from "./camera-video-fill.svg";
import webcamOff from "./camera-video-off-fill.svg";
import screnShareOn from "./arrow-up-square-fill.svg";
import screnShareOff from "./arrow-up-square.svg";
import recordOn from "./record-circle-fill.svg";
import recordOff from "./record-circle.svg";
import leaveMeeting from "./x-square.svg";
import avatarBackgroundOne from "./avatarbackgrounds/background1.hdr";
import avatarBackgroundTwo from "./avatarbackgrounds/background2.hdr";
import avatarBackgroundOneThumbnail from "./avatarbackgrounds/background1.png";
import avatarBackgroundTwoThumbnail from "./avatarbackgrounds/background2.png";
import avatarOne from "./avatars/a-one.glb";
import avatarTwo from "./avatars/a-two.glb";
import avatarOneThumbnail from "./avatars/male_avatar.jpg";
import avatarTwoThumbnail from "./avatars/female_avatar.jpg";
import cubicLoader from "./loader.gif";
import cam_disabled from "../videoCallIcons/cam_disabled.svg";
import take_call from "../videoCallIcons/take_call.svg";
import mic_disabled from "../videoCallIcons/mic_disabled.svg";
import share_screen from "../videoCallIcons/share_screen.svg";
import mic_enabled from "../videoCallIcons/mic_enabled.svg";
import stop_share_screen from "../videoCallIcons/stop_share_screen.svg";
import end_call from "../videoCallIcons/end_call.svg";
import cam_enabled from "../videoCallIcons/cam_enabled.svg";
import mic_off from "../videoCallIcons/mic_off.svg";
import online from "../videoCallIcons/online.svg";
import offline from "../videoCallIcons/offline.svg";
import lavni_logo from "../videoCallIcons/lavni_logo.svg";
import addSignIcon from "./chat/add_icon.png";
import usersIcon from "./chat/3_user.png";
import voiceCallIcon from "./chat/voice_call.png";
import videoCallIcon from "./chat/video_call.png";
import calendarIcon from "./chat/u_calender.png";
import defaultGroupIcon from "./default_profile.png";
import close from "../images/icons/fi_x-circle.png";
import defaultUser from "./default_profile.png";
import twilio_call from "./twilio_img.svg";
import decline_call from "./decline.svg";
import call from "./call.svg";
import mic from "./mic.svg";
import chat_call from "./chat_call.svg";
import chat_mute from "./chat_mute.svg";
import chat_video from "./chat_video.svg";

export default {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  appointmentIcon,
  appointmentIconWhite,
  clockIcon,
  sampleAvatarOne,
  sampleAvatarTwo,
  sampleAvatarThree,
  sampleAvatarFour,
  sampleBackgroundOne,
  sampleBackgroundTwo,
  solidAvatarIcon,
  solidAvatarIconWhite,
  micOn,
  micOff,
  webcamOn,
  webcamOff,
  screnShareOn,
  screnShareOff,
  recordOn,
  recordOff,
  avatarBackgroundOne,
  avatarBackgroundTwo,
  leaveMeeting,
  avatarOne,
  avatarBackgroundOneThumbnail,
  avatarOneThumbnail,
  avatarTwo,
  avatarTwoThumbnail,
  avatarBackgroundTwoThumbnail,
  cubicLoader,
  micStatus,
  cam_disabled,
  mic_disabled,
  share_screen,
  take_call,
  mic_enabled,
  cam_enabled,
  stop_share_screen,
  end_call,
  mic_off,
  online,
  offline,
  lavni_logo,
  addSignIcon,
  usersIcon,
  voiceCallIcon,
  videoCallIcon,
  calendarIcon,
  defaultGroupIcon,
  close,
  defaultUser,
  twilio_call,
  decline_call,
  call,
  mic,
  chat_call,
  chat_mute,
  chat_video,
};
