import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Input,Row } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AdminService } from "../../services/AdminService";
import { MeetingData } from "../../models/MeetingData";
import Swal from "sweetalert2";
import moment from "moment";

const ViewMeetingsAndRecordings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [meetingCount, setMeetingCount] = useState(10);
  const [meetingsAndRecordings, setMeetingsAndRecordings] = useState([] as MeetingData[]);
  const [meetingOffset, setMeetingOffset] = useState(1);
  const [isSearchableText, setSearchableText] = useState({ searchableText: "" } as any);

  const LIMIT = 10;

  useEffect(() => {
    getAllMeetingsAndRecords();
  }, []);

  const getAllMeetingsAndRecords = () => {
    setIsLoading(true);
    AdminService.getAllRecordsAndMeetings(LIMIT, meetingOffset).then(res => {
      if (res.success) {
        setMeetingsAndRecordings(res.data);
        setMeetingCount(res.data?.length);
        setIsLoading(false);
      } else {
        setMeetingsAndRecordings([] as MeetingData[]);
        setIsLoading(false);
      }
    });
  };

  const seeMoreMeetingsAndRecordings = () => {
    setIsLoading(true);
    const updatedOffset = meetingOffset + 1;
    setMeetingOffset(updatedOffset);
    AdminService.getAllRecordsAndMeetings(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setMeetingsAndRecordings(previousmeeting => {
          return [...new Set([...previousmeeting, ...res.data])];
        });
        setMeetingCount(res.data?.length);
        setIsLoading(false);
      } else {
        setMeetingsAndRecordings([] as MeetingData[]);
        setIsLoading(false);
      }
    });
  };

  const deleteMeetingsAndRecordings = (id: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.deleteMeetingsAndRecordingById(id).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Meeting and Recordings deleted!",
              confirmButtonColor: "#FD7F00",
            });
            const refreshedArr = meetingsAndRecordings.filter(item => item._id !== id);
            if (refreshedArr) {
              setMeetingsAndRecordings(refreshedArr);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to delete.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  const seachMeetingId = () => {
    if (isSearchableText.searchableText) {
      setIsLoading(true);

      AdminService.searchMeetingsAndRecordingsByAdmin(isSearchableText).then(res => {
        if (res.success) {
          setMeetingsAndRecordings(res.data);
          setMeetingCount(res.data?.length);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } else {
      getAllMeetingsAndRecords();
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={`Lavni`} breadcrumbItem={"Recordings & Meetings"} />

            <Card>
              <CardBody>
                    <Row>
                      <Col xl="6"></Col>
                      <Col xl="6 d-flex">
                        <Input
                          placeholder="Search from Meeting Id (eg : abcr-cdrr-edfr)"
                          className="mb-3 mr-4"
                          onChange={(e: any) => setSearchableText({ ...isSearchableText, searchableText: e.target.value })}
                        />
                        <button className="btn btn-primary mb-3" onClick={seachMeetingId}>
                          Search
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        {meetingsAndRecordings !== undefined && meetingsAndRecordings.length > 0 ? (
                          <>
                            <div className="table-responsive" style={{ minHeight: "70vh" }}>
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Meeting Id</th>
                                    <th scope="col">Meeting Date</th>
                                    <th scope="col">Meeting Duration</th>
                                    <th scope="col">Spent Duration</th>
                                    <th scope="col">Participant Count</th>
                                    {/* <th scope="col">Both join</th> */}
                                    <th scope="col">Client</th>
                                    <th scope="col">Therapist</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {meetingsAndRecordings.length > 0 &&
                                    meetingsAndRecordings.map((data: MeetingData, i: number) => {
                                      return (
                                        <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>{data.meetingId}</td>
                                          <td>{data.createdAt ? moment(data.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                          <td>{data.meetingDuration}</td>
                                          <td>{data?.spentDuration != null ? data?.spentDuration.toFixed(2) : "--"}</td>
                                          <td>{data?.participantCount}</td>
                                          <td>
                                            <Link to={"/profile/" + data.clientId?._id}>
                                              {data?.clientId?.firstname} {data?.clientId?.lastname}
                                            </Link>
                                          </td>
                                          <td>
                                            <Link to={"/profile/" + data.therapistId?._id}>
                                              {data?.therapistId?.firstname} {data?.therapistId?.lastname}
                                            </Link>
                                          </td>
                                          <td>
                                            <span className={data?.callingStatus + " session-h5"}>
                                              {data?.callingStatus}
                                            </span>
                                          </td>

                                          <td>
                                            <button className="btn btn-warning btn-sm" onClick={() => deleteMeetingsAndRecordings(data._id)}>
                                              Delete
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                            {meetingCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4" onClick={seeMoreMeetingsAndRecordings}>
                                  {
                                    isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      : <>See More</>
                                  }
                                </button>
                              </>
                            )}
                            {meetingCount == 0 && <span>No more meetings and recordings...</span>}
                          </>
                        ) : (
                          <>{isLoading ? <Spinner /> : <div>No meetings and recordings</div>}</>
                        )}
                        </Col>
                    </Row>
                  </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default ViewMeetingsAndRecordings;
