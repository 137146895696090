import React, { useContext } from "react";
import { GroupChatMessageModel } from "src/models/GroupChat/GroupChatMessage";
import { decryptGroupChatMessage } from "./GroupChatEncryption";
import UserContext from "src/context/UserContext";
import { Util } from "src/Util";
import parse from "html-react-parser";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  selectedMessage: GroupChatMessageModel | undefined;
  closeModal: () => void;
}

const GroupChatReplyMessageDisplayComp = ({ selectedMessage, closeModal }: Props) => {
  const [user, setUser] = useContext(UserContext);
  const getPreviewByExtension = (extension: string, id: string) => {
    switch (extension) {
      case ".jpg":
      case ".JPG":
      case ".JPEG":
      case ".jpeg":
      case ".tiff":
      case ".TIFF":
      case ".gif":
      case ".GIF":
      case ".PNG":
      case ".png":
        return <img src={Util.fileURL(id)} style={{ maxHeight: "250px", maxWidth: "250px" }} />;
      case ".mp3":
      case ".MP3":
      case ".WMA":
      case ".wma":
        return <i className=" fa fa-file-audio text-info " style={{ fontSize: "90px" }}></i>;
      case ".mp4":
      case ".MP4":
      case ".AVI":
      case ".avi":
        return <i className=" fa fa-file-video text-warning " style={{ fontSize: "90px" }}></i>;
      case ".txt":
      case ".TXT":
        return <i className="fa fa-file-word  " style={{ fontSize: "90px" }}></i>;
      case ".pdf":
      case ".PDF":
        return <i className=" fa fa-file-pdf text-danger " style={{ fontSize: "90px" }}></i>;
      default:
        return <i className=" fa fa-file" style={{ fontSize: "90px" }}></i>;
    }
  };
  return (
    <div className="content-wrapper1 break-word-show-msg">
      <div className="reply-content">
        <span
          style={{
            backgroundColor: "#FD7F00",
            height: "100%",
            width: "1px",
          }}
        ></span>
        <div className="receiver1">
          {selectedMessage && selectedMessage.createdBy == user?._id
            ? "You"
            : selectedMessage && selectedMessage.firstname
            ? selectedMessage.firstname
            : "Member"}
        </div>
        <i
          className="fa fa-times pointer1"
          style={{ padding: "5px 0px 0px 10px" }}
          onClick={() => {
            closeModal();
          }}
        ></i>
      </div>
      <div className="replying-message">
        {selectedMessage?.mediaFileId != null &&
        selectedMessage?.mediaFileId._id != null &&
        selectedMessage?.mediaFileId._id != "" &&
        selectedMessage?.mediaFileId.extension != null &&
        selectedMessage?.mediaFileId.extension != "" ? (
          <div className="mb-2 cursor-pointer" onClick={() => window.open(Util.fileURL(selectedMessage?.mediaFileId?._id))}>
            {getPreviewByExtension(selectedMessage?.mediaFileId.extension, selectedMessage?.mediaFileId._id)}
          </div>
        ) : null}
        <p className="m-0">{selectedMessage && selectedMessage.messageText ? parse(decryptGroupChatMessage(selectedMessage.messageText)) : ""}</p>
      </div>
    </div>
  );
};

export default GroupChatReplyMessageDisplayComp;
