import React, { useState, useEffect, useContext } from "react";
import { Container, Row } from "reactstrap";
import UserContext from "src/context/UserContext";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory } from 'react-router-dom';
import ArticleList from "./ArticleList";
import RightBar from "./RightBar";

const Index: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState();
  const [user] = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout;
    if (user?.createdAt) {
      const currentTime = new Date();
      const createdAtTime = new Date(user?.createdAt);
      const timeDifference = currentTime.getTime() - createdAtTime.getTime();
      const thirtyMinutes = 30 * 60 * 1000;
      if (timeDifference <= thirtyMinutes) {
        redirectTimeout = setTimeout(() => {
          history.push('/dashboard');
        }, 20000); // 20 seconds in milliseconds
      }
    }

    return () => clearTimeout(redirectTimeout);
  }, [user?.createdAt]);

  return (
    <React.Fragment>
      <div className="page-content">
        <title>Article List | Lavni</title>
        <Container fluid>
          <Breadcrumbs title="Article" breadcrumbItem="Article List" />

          <Row>
            <RightBar setSearchQuery={setSearchQuery} />
            <ArticleList searchQuery={searchQuery} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
