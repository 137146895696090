import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { AceScoreModel } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

const FormThirteen = (props: any) => {
    const { formId, customActiveTab, assesmenData } = props;
    const [edit, setEdit] = useState<boolean>(true)
    const [aceScore, setAceScore] = useState(0);
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 13,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                aceScore: assesmenData.aceScore,
                findingAceScore:assesmenData.findingAceScore


            }
            setFormData(updateAssesment);
            setAceScore(updateAssesment?.findingAceScore ?? 0);
            

        }
        if (!formData || !formData.aceScore) {
            console.error("formData or aceScore is undefined");
            return;
        }

        const trueCount = Object.values(formData.aceScore).filter(value => value === true).length;
        
        setAceScore(trueCount)
    }, [assesmenData])

    const handleCheckboxChange = (event: any) => {
        const isChecked = event.target.checked;

        const updatedAceScore = isChecked ? aceScore + 1 : aceScore - 1;
        setAceScore(updatedAceScore);
    };

    

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 13,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            findingAceScore: aceScore,
            aceScore: formData.aceScore

        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>
                <Row>
                    <div className="card-bodyDiv">
                        <Row>
                            <h5 className="fw-600">Finding Your ACE Score</h5>
                        </Row>
                        <hr style={{ marginTop: "unset", border: "solid #FD7A08", borderWidth: "3px" }} />
                        <Card className="match-card-body p-3">
                            <h6 className="fw-500 mb-4">While you were growing up, during your first 18 years of life:</h6>
                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">1. Did a parent or other adult in the household o often or very often…</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Swear at you, insult you, put you down, or humiliate you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Act in a way that made you afraid that you might be physically hurt?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} className="align-self-end">
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.humiliateOrPhysicallyHurt}
                                        onClick={(event) => {
                                            // const isChecked = event.target.checked;
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    humiliateOrPhysicallyHurt:!prevState.aceScore?.humiliateOrPhysicallyHurt,
                                                }
                                            }));
                                            handleCheckboxChange(event);
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">2. Did a parent or other adult in the household o often or very often…</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Push, grab, slap, or throw something at you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Ever hit you so hard that you had marks or were injured?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        disabled={!edit}
                                        defaultChecked={formData.aceScore?.pushGrabSlap}
                                        onClick={(event) => {

                                            setFormData((prevState) => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    pushGrabSlap: !prevState.aceScore?.pushGrabSlap,
                                                },
                                            }));

                                            handleCheckboxChange(event);
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">3. Did an adult or person at least 5 years older than you e ever…</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Touch or fondle you or have you touch their body in a sexual way?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Attempt or actually have oral, anal, or vaginal intercourse with you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.intercourse}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    intercourse: !prevState.aceScore?.intercourse
                                                }
                                            }));
                                            handleCheckboxChange(event);
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">4. Did you o often or very often feel that …</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">No one in your family loved you or thought you were important or special?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Your family didn’t look out for each other, feel close to each other, or support each other?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.familySupport}
                                        // onChange={(event) =>{ handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    familySupport: !prevState.aceScore?.familySupport
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">5. Did you o often or very often feel that …</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2"> You didn’t have enough to eat, had to wear dirty clothes, and had no one to protect you?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Your parents were too drunk or high to take care of you or take you to the doctor if you needed it?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.noOneToProtectYou}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    noOneToProtectYou: !prevState.aceScore?.noOneToProtectYou
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">6. Were your parents e ever separated or divorced?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.parentSeperateDivorce}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    parentSeperateDivorce: !prevState.aceScore?.parentSeperateDivorce
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">7. Was your mother or stepmother:</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">Often or very often pushed, grabbed, slapped, or had something thrown at her?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Sometimes, often, or very often kicked, bitten, hit with a fist, or hit with something hard?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2" style={{ marginInlineStart: "26%" }}>or</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">Ever repeatedly hit at least a few minutes or threatened with a gun or knife?</h6>
                                        </div>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        disabled={!edit}
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.threatenedWithGunOrKnife}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    threatenedWithGunOrKnife: !prevState.aceScore?.threatenedWithGunOrKnife
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">8. Did you live with anyone who was a problem drinker or alcoholic or who used street drugs?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.liveWithDrugUser}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    liveWithDrugUser: !prevState.aceScore?.liveWithDrugUser
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">9. Was a household member depressed or mentally ill, or did a household member attempt suicide?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.isHouseHoldMemberattemptSuicide}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    isHouseHoldMemberattemptSuicide: !prevState.aceScore?.isHouseHoldMemberattemptSuicide
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", marginBottom: "3%" }}>
                                <Col className="col-8 col-lg-8">
                                    <div>
                                        <h6 className="fw-500 mb-2">10. Did a household member go to prison?</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "5%" }}>
                                        <div>
                                            <h6 className="fw-500 mb-2">
                                                <div className="row">
                                                    <div className="col-3">
                                                        Yes
                                                    </div>
                                                    <div className="col-3">
                                                        No
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-4 col-lg-3 align-self-end">
                                    <input style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={formData.aceScore?.isHouseHOldMemberGoToPrison}
                                        // onChange={(event) => handleCheckboxChange(event)}
                                        onClick={(event) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                aceScore: {
                                                    ...prevState.aceScore,
                                                    isHouseHOldMemberGoToPrison: !prevState.aceScore?.isHouseHOldMemberGoToPrison
                                                }
                                            }));
                                            handleCheckboxChange(event)
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>If yes</label>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <h6 className="fw-600" style={{ margin: "0.3rem", marginLeft: "9px" }}>Now add up your “Yes” answers :</h6>
                                        </div>
                                        <div className="col-auto">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="ACEScore"
                                                            value={aceScore}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <h6 className="fw-600" style={{ margin: "0.3rem" }}>This is your ACE Score.</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <h6 className="fw-500 mb-4"
                                style={{
                                    textAlign: "end",
                                    fontSize: "xx-small"
                                }}><i>Adapted from: http://www.acestudy.org/files/ACE_Score_Calculator.pdf,   092406RA4CR</i></h6>
                        </Card>

                        <hr style={{ margin: "unset", border: "solid #FD7A08", borderWidth: "1px" }} />
                        <h6 className="fw-500 mt-1 mb-4"
                            style={{
                                textAlign: "center",
                                fontSize: "smaller",
                                color: "#FD7A08"
                            }}><i>Think Trauma: A Training for Staff in Juvenile Justice Residential Settings:  Module Four – Finding Your ACE Score</i></h6>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&

                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 13 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormThirteen;