import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem, DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import { AppointmentService } from "../../services/AppointmentService";
import { Appointment, AppointmentStatus, AppointmentType, ApprovalStatus, ModalType, Repeat } from "../../models/Appointment";
import moment from "moment";
import { toast } from "react-toastify";
import { VideoChatService } from "../../services/VideoChatService";
import CallInitializationContext from "../../context/CallInitializationContext";
import LocalCallContext from "../../context/LocalCallContext";
import Spinner from "../../common/spinner/spinner";
import Modal4 from "../Popup/Modal4";
import Modal5 from "../Popup/Modal5";
import Modal11 from "../Popup/Modal11";
import Modal12 from "../Popup/Modal12";
import Modal13 from "../Popup/Modal13";
import Modal17 from "../Popup/Modal17";
import Modal18 from "../Popup/Modal18";
import MessageModal from "../Popup/MessageModal";
import { useHistory } from "react-router";
import { Client } from "../../models/Client";
const classNames = require("classnames");
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Util } from "../../Util";
import images from "../../assets/images";
import Swal from "sweetalert2";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";
import { Therapist } from "../../models/Therapist";
import AppointmentModal from "../Popup/AppointmentModal";
import { ClientService } from "../../services/ClientService";
import dayjs, { Dayjs } from "dayjs";
import ApiCalendar from "react-google-calendar-api";
import { environment } from "./../../environment/environment";
import { CommonService } from "../../services/CommonService";
import { CallInitializeData } from "../../models/CallInitializeData";
import AvatarModeEnableModal from "../Popup/AvatarModeEnableModal";
import AppointmentCalendarTherapist from "./AppointmentsCalendarTherapist";
import { v4 as uuidv4 } from "uuid";
import { VonageServices } from "../Chat/vonage/vonageServices";
import Modal29 from "src/pages/Popup/Modal29";
import Modal28 from "src/pages/Popup/Modal28";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../../assets/css/admin.css";

const stripePromise = loadStripe(environment.stripe_publishable_key);


const config = {
  clientId: environment.google_calendar_client_id,
  apiKey: environment.google_api_key,
  scope: environment.google_calendar_scope,
  discoveryDocs: environment.google_calendar_discovery_docs,
};

const apiCalendar = new ApiCalendar(config);

const AllAppointments: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [appointments, setAppointments] = useState([] as Appointment[]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  const [showModal, setShowModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showTooEarly, setShowTooEarly] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState(user?.role == Role.THERAPIST ? "5" : "2");
  const history = useHistory();
  const [errorModalText, setErrorModalText] = useState("");
  const [sessionTime, setSessionTime] = useState(60 as number);
  const [offset, setOffset] = useState(1);
  const [appointmentsCount, setAppointmentsCount] = useState(0);
  const limit = 50;
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: "",
  });

  const [showErrorModal, setShowErrorModal] = useState(false);

  const [isEventModal, setIsEventModal] = useState(false);

  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [showAvatarModeEnablePopup, setShowAvatarModeEnablePopup] = useState(false);
  const [calendar, setCalendar] = useState<boolean>(false);
  const [clientIdForSchedule, setClientIdForSchedule] = useState("");
  const [showImcompleteCoPayments, setShowIncompleteCopayments] = useState(false);
  const [showPayImcompleteCoPayments, setShowPayIncompleteCopayments] = useState(false);
  const [coPaymentAmount, setCopaymentAmount] = useState(0);
  const [showMeetingData, setMeetingData] = useState({ startNew: true, userId: "", meetingTime: "" as any, appointmentId: "", meetingId: "", isAppointmentBased: true, allowTranscribe: true || undefined, callType: "", });


  useEffect(() => {
    if (user?.incognitoPopupShow == false) {
      setShowAvatarModeEnablePopup(true);
    }
  }, []);

  useEffect(() => {
    VideoChatService.checkForNearestAppointment().then((res: any) => {
      if (res.success && res.data) {
        setNearestAppointmentFunction(res);
      }
    });
  }, []);

  useEffect(() => {
    getAllAppointmentByuser();
  }, [customActiveTab, isEventModal]);

  const getWorkingDaysOfWeek = (workingHours: any) => {
    const workingDays: string[] = [];

    workingHours?.map((session: any) => {
      if (!workingDays.includes(session.day)) workingDays.push(session.day);
    });
  };

  const getAllAppointmentByuser = () => {
    switch (user?.role) {
      case Role.CLIENT:
        getClientAppointmentById();
        break;
      case Role.THERAPIST:
        getTherapistAppointmentById();
        break;
      default:
        break;
    }
  };

  const getClientAppointmentById = () => {
    setIsLoading(true);

    AppointmentService.viewAllAppointmentsByUser(customActiveTab, limit, offset).then(res => {
      if (res.success) {
        setAppointments(res.data);
        setAppointmentsCount(res.data.length);
        setIsLoading(false);
        setIsError(false);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        setIsError(true);
      }
    });
  };

  const getTherapistAppointmentById = () => {
    setIsLoading(true);
    AppointmentService.viewAllAppointmentsByUser(customActiveTab, limit, offset).then(res => {
      if (res.success) {
        setAppointments(res.data);
        setAppointmentsCount(res.data.length);
        setIsLoading(false);
        setIsError(false);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        setIsError(true);
      }
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    setIsLoading(true);

    AppointmentService.viewAllAppointmentsByUser(customActiveTab, limit, updatedSkip).then(res => {
      if (res.success) {
        res.data.map((appointment: Appointment) => {
          setAppointments(appointments => [...appointments, appointment]);
        });
        setAppointmentsCount(res.data.length);
        setIsLoading(false);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        setIsError(true);
      }
    });
  };
  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);

  const startMeetingNew2 = async (userId: any, isVideoCall: boolean, appointmentId: string | undefined, startTime: Date, endTime: Date, status?: string, approvedStatus?: string) => {
    if (user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN) {
      if (userId == "") {
        alert("No user id is provided.");
      } else if (localCallInitialize) {
        toast.success("There is an ongoing call.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      } else {
        const now = moment(new Date());
        const timeDifferenceWithNow = moment.duration(moment(startTime).diff(now));
        const timeDifferenceWithNowAsMinutes = timeDifferenceWithNow.asMinutes();

        const timeDifferenceWithStartAndEnd = moment.duration(moment(endTime).diff(moment(startTime)));
        const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();

        // if (approvedStatusForMeeting != ApprovalStatus.APPROVED) {
        //   setShowNotApproved(true);
        // } else
        // if (statusForMeeting != AppointmentStatus.PENDING) {
        //   setShowAlreadyCompleted(true);
        // } else
        if (timeDifferenceWithNowAsMinutes > 31) {
          if (user?.role == "CLIENT") {
            const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
            if (meetingRes.success) {
              let totalAmount = 0;
              for (const meeting of meetingRes.data) {
                const amount = parseFloat(meeting?.copayment?.amount) || 0;
                totalAmount += amount;
              }
              if (totalAmount > 0) {
                setCopaymentAmount(totalAmount)
                setShowPayIncompleteCopayments(true)
              } else {
                setShowTooEarly(true);
              }
            }
          } else {
            const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
            startZoomCall(true, userId, finalMeetingTime, appointmentId, "");
          }
        } else if (timeDifferenceWithNowAsMinutes < -1441) {
          setShowExpired(true);
        } else {
          const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
          startZoomCall(true, userId, finalMeetingTime, appointmentId, "");
        }
      }
    }
  };


  const startZoomCall = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string) => {
    if (startNew) {
      if (user?.role == "CLIENT") {
        const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
        if (meetingRes.success) {
          let totalAmount = 0;
          for (const meeting of meetingRes.data) {
            const amount = parseFloat(meeting?.copayment?.amount) || 0;
            totalAmount += amount;
          }
          if (totalAmount > 0) {
            setCopaymentAmount(totalAmount)
            setShowPayIncompleteCopayments(true)
            setClientIdForSchedule(userId)
            setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, callType: "START" })
          } else {
            const todayDate = moment().format('YYYY-MM-DD');
            const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(userId, todayDate, user._id)
            if (checkClientHasCompletedSessionsOnThisWeek.success) {
              console.log(checkClientHasCompletedSessionsOnThisWeek);
              if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting) {
                toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
                });
              } else {
                const callInitializeData: CallInitializeData = {
                  start: startNew,
                  isInstantMeeting: false,
                  isAppointmentBased: true,
                  appointmentId: appointmentId,
                  recieverId: userId,
                  meetingTime: meetingTime,
                  isTranscribeAllowed: true,
                  meetingId: meetingId,
                  isAudioCall: false,
                };
                // setLocalCallInitialize(true);
                setLocalCallInitializeData(callInitializeData);
                setOutGoinCallTimeOutFunction();
                const roomNamePartOne = user?._id;
                const roomNamePartTwo = userId;
                const uniqueId = uuidv4();
                const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                const vonageUrl = `/room/${roomName}`;

                history.push(vonageUrl);
              }
            }
          }
        }
      } else {
        const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(userId);
        if (meetingRes.success) {
          let totalAmount = 0;
          for (const meeting of meetingRes.data) {
            const amount = parseFloat(meeting?.copayment?.amount) || 0;
            totalAmount += amount;
          }
          if (totalAmount > 0) {
            setCopaymentAmount(totalAmount)
            setClientIdForSchedule(userId)
            setShowIncompleteCopayments(true)
            setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, callType: "START" })
            const meetingRes: any = await ClientService.sendSMSAndEmailForClient(userId, totalAmount);
          } else {
            const callInitializeData: CallInitializeData = {
              start: startNew,
              isInstantMeeting: false,
              isAppointmentBased: true,
              appointmentId: appointmentId,
              recieverId: userId,
              meetingTime: meetingTime,
              isTranscribeAllowed: true,
              meetingId: meetingId,
              isAudioCall: false,
            };
            // setLocalCallInitialize(true);
            setLocalCallInitializeData(callInitializeData);
            setOutGoinCallTimeOutFunction();
            const roomNamePartOne = user?._id;
            const roomNamePartTwo = userId;
            const uniqueId = uuidv4();
            const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
            const vonageUrl = `/room/${roomName}`;

            history.push(vonageUrl);
            // history.push("/meeting_screen");
          }
        }
      }
    } else {
      toast.error(
        `If you'd like to join the session, please check your inbox for the meeting URL.`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      })
    }

  };


  const setPaidCoAmount = async (paidAmount: any) => {
    if (paidAmount > 0) {
      await ClientService.payAllPendingCopaymentAmounts().then(async res => {
        if (res.success) {
          setShowPayIncompleteCopayments(false)
          if (user?.role == "CLIENT") {
            if (showMeetingData?.callType == "START") {
              const todayDate = moment().format('YYYY-MM-DD');
              const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(showMeetingData?.userId, todayDate, user._id)
              if (checkClientHasCompletedSessionsOnThisWeek.success) {
                if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting) {
                  toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "foo-bar",
                  });
                } else {
                  const callInitializeData: CallInitializeData = {
                    start: showMeetingData?.startNew,
                    isInstantMeeting: false,
                    isAppointmentBased: true,
                    appointmentId: showMeetingData?.appointmentId,
                    recieverId: showMeetingData?.userId,
                    meetingTime: showMeetingData?.meetingTime,
                    isTranscribeAllowed: true,
                    meetingId: showMeetingData?.meetingId,
                    isAudioCall: false,
                  };
                  // setLocalCallInitialize(true);
                  setLocalCallInitializeData(callInitializeData);
                  setOutGoinCallTimeOutFunction();
                  const roomNamePartOne = user?._id;
                  const roomNamePartTwo = showMeetingData?.userId;
                  const uniqueId = uuidv4();
                  const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                  const vonageUrl = `/room/${roomName}`;
                  history.push(vonageUrl);
                }
              }
            }
            if (showMeetingData?.callType == "JOIN") {
              const meetingRes: any = await VonageServices.getMeetingByMeetingId(showMeetingData?.meetingId);
              if (meetingRes.success) {
                const vonageSessionName = meetingRes.data.vonageSessionName;
                const callInitializeData: CallInitializeData = {
                  start: showMeetingData?.startNew,
                  isInstantMeeting: false,
                  isAppointmentBased: true,
                  appointmentId: showMeetingData?.appointmentId,
                  recieverId: showMeetingData?.userId,
                  meetingTime: showMeetingData?.meetingTime,
                  isTranscribeAllowed: true,
                  meetingId: showMeetingData?.meetingId,
                  isAudioCall: false,
                };
                // setLocalCallInitialize(true);
                setLocalCallInitializeData(callInitializeData);
                setOutGoinCallTimeOutFunction();
                const roomNamePartOne = user?._id;
                const roomNamePartTwo = showMeetingData?.userId;
                const uniqueId = uuidv4();
                const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                const vonageUrl = `/room/${roomName}`;
                history.push(vonageUrl);
              }
            }
          } else {
            toast.error(`Already On Progress`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          toast.error(`Something went wrong. Please try again later.`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      })
    }
  }

  const setAfterPaidCoAmount = async (paidAmount: any) => {
    if (paidAmount > 0) {
      setShowPayIncompleteCopayments(false)
      if (user?.role == "CLIENT") {
        if (showMeetingData?.callType == "START") {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(showMeetingData?.userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek.success) {
            if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting) {
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                position: toast.POSITION.TOP_RIGHT,
                className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: showMeetingData?.startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: showMeetingData?.appointmentId,
                recieverId: showMeetingData?.userId,
                meetingTime: showMeetingData?.meetingTime,
                isTranscribeAllowed: true,
                meetingId: showMeetingData?.meetingId,
                isAudioCall: false,
              };
              // setLocalCallInitialize(true);
              setLocalCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = showMeetingData?.userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }

          // history.push("/meeting_screen");
        }
        if (showMeetingData?.callType == "JOIN") {
          const meetingRes: any = await VonageServices.getMeetingByMeetingId(showMeetingData?.meetingId);
          if (meetingRes.success) {
            const vonageSessionName = meetingRes.data.vonageSessionName;
            const callInitializeData: CallInitializeData = {
              start: showMeetingData?.startNew,
              isInstantMeeting: false,
              isAppointmentBased: true,
              appointmentId: showMeetingData?.appointmentId,
              recieverId: showMeetingData?.userId,
              meetingTime: showMeetingData?.meetingTime,
              isTranscribeAllowed: true,
              meetingId: showMeetingData?.meetingId,
              isAudioCall: false,
            };
            // setLocalCallInitialize(true);
            setLocalCallInitializeData(callInitializeData);
            setOutGoinCallTimeOutFunction();
            const roomNamePartOne = user?._id;
            const roomNamePartTwo = showMeetingData?.userId;
            const uniqueId = uuidv4();
            const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
            const vonageUrl = `/room/${roomName}`;
            history.push(vonageUrl);
          }
        }
      } else {
        toast.error(`Already On Progress`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };

  const setJoinCall = () => {
    setShowIncompleteCopayments(false)
    startZoomCallCopayment(true, showMeetingData?.userId, showMeetingData?.meetingTime, showMeetingData?.appointmentId, "");
  }
  const startZoomCallCopayment = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string) => {
    if (user?.role == "CLIENT") {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setShowPayIncompleteCopayments(true)
          setClientIdForSchedule(userId)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, callType: "START" })
        } else {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek.success) {
            if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting) {
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                position: toast.POSITION.TOP_RIGHT,
                className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: appointmentId,
                recieverId: userId,
                meetingTime: meetingTime,
                isTranscribeAllowed: true,
                meetingId: meetingId,
                isAudioCall: false,
              };
              // setLocalCallInitialize(true);
              setLocalCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }
        }
      }
    } else {
      const callInitializeData: CallInitializeData = {
        start: startNew,
        isInstantMeeting: false,
        isAppointmentBased: true,
        appointmentId: appointmentId,
        recieverId: userId,
        meetingTime: meetingTime,
        isTranscribeAllowed: true,
        meetingId: meetingId,
        isAudioCall: false,
      };
      // setLocalCallInitialize(true);
      setLocalCallInitializeData(callInitializeData);
      setOutGoinCallTimeOutFunction();
      const roomNamePartOne = user?._id;
      const roomNamePartTwo = userId;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room/${roomName}`;
      history.push(vonageUrl);
      // history.push("/meeting_screen");
    }
  };

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      setOffset(1);
      setAppointments({} as any);
    }
    if (tab == "5") {
      setCalendar(true);
    } else {
      setCalendar(false);
    }
  };

  const deleteAppointment = (data: Appointment) => {
    if (user && user.role == Role.CLIENT) {
      if (Util.skipCheckingForPremiumUsers(user)) {
        if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
          return setShowModalForTherapist(true);
        }
      }
    }
    const GoogleCalendarEventId = data.GoogleCalendarEventId;
    if (GoogleCalendarEventId) {
      if (data?.repeatInfo?.repeatType === Repeat.WEEKLY) {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to delete ?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
          input: "checkbox"
        }).then(result => {
          if (result.isConfirmed) {
            let deletingAppointment = null;

            if (result.value == 1) {
              deletingAppointment = {
                appointmentId: data?._id
              };
            } else {
              deletingAppointment = {
                appointmentId: data?._id
              };
            }

            AppointmentService.deleteAppointment(deletingAppointment).then(res => {
              if (res.success) {
                toast.success("Appointment is cancelled successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                const appointmentNotification: NotificationModel = {
                  senderId: user?._id,
                  receiverId: data?.therapistId._id,
                  event: NotificationEvent.APPOINMENT_DELETED,
                  link: "/appointments",
                  content: "Appointment deleted by " + user?.firstname + " " + user?.lastname,
                  variant: NotificationVarient.WARNING,
                };

                NotificationService.sendNotification(appointmentNotification);

                getAllAppointmentByuser();
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.error ? res.error : "Something went wrong. Please try again later.",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to delete ?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
        }).then(result => {
          if (result.isConfirmed) {
            const deletingAppointment = {
              appointmentId: data?._id
            };

            AppointmentService.deleteAppointment(deletingAppointment).then(res => {
              if (res.success) {
                CommonService.deleteGoogleCalendarEvent(data)
                  .then(res => {
                    if (res) {
                      toast.success("Appointment is cancelled successfully.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                      });

                      const appointmentNotification: NotificationModel = {
                        senderId: user?._id,
                        receiverId: data?.therapistId._id,
                        event: NotificationEvent.APPOINMENT_DELETED,
                        link: "/appointments",
                        content: "Appointment deleted by " + user?.firstname + " " + user?.lastname,
                        variant: NotificationVarient.WARNING,
                      };

                      NotificationService.sendNotification(appointmentNotification);

                      getAllAppointmentByuser();
                    }
                  })
                  .catch((err: any) => {
                    toast.success("Appointment is cancelled successfully without google calendar update.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      className: "foo-bar",
                    });

                    const appointmentNotification: NotificationModel = {
                      senderId: user?._id,
                      receiverId: data?.therapistId._id,
                      event: NotificationEvent.APPOINMENT_DELETED,
                      link: "/appointments",
                      content: "Appointment deleted by " + user?.firstname + " " + user?.lastname,
                      variant: NotificationVarient.WARNING,
                    };

                    NotificationService.sendNotification(appointmentNotification);

                    getAllAppointmentByuser();
                  });
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.error ? res.error : "Something went wrong. Please try again later.",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      }
    } else {
      if (data?.repeatInfo?.repeatType === Repeat.WEEKLY) {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to delete?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
          input: "checkbox",
        }).then(result => {
          if (result.isConfirmed) {
            let deletingAppointment = null;

            if (result.value == 1) {
              deletingAppointment = {
                appointmentId: data?._id
              };
            } else {
              deletingAppointment = {
                appointmentId: data?._id
              };
            }

            AppointmentService.deleteAppointment(deletingAppointment).then(res => {
              if (res.success) {
                toast.success("Appointment is cancelled successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                const appointmentNotification: NotificationModel = {
                  senderId: user?._id,
                  receiverId: data?.therapistId._id,
                  event: NotificationEvent.APPOINMENT_DELETED,
                  link: "/appointments",
                  content: "Appointment deleted by " + user?.firstname + " " + user?.lastname,
                  variant: NotificationVarient.WARNING,
                };

                NotificationService.sendNotification(appointmentNotification);

                getAllAppointmentByuser();
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.error ? res.error : "Something went wrong. Please try again later.",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to delete?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
        }).then(result => {
          if (result.isConfirmed) {
            const deletingAppointment = {
              appointmentId: data?._id
            };

            AppointmentService.deleteAppointment(deletingAppointment).then(res => {
              if (res.success) {
                toast.success("Appointment is cancelled successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                const appointmentNotification: NotificationModel = {
                  senderId: user?._id,
                  receiverId: data?.therapistId._id,
                  event: NotificationEvent.APPOINMENT_DELETED,
                  link: "/appointments",
                  content: "Appointment deleted by " + user?.firstname + " " + user?.lastname,
                  variant: NotificationVarient.WARNING,
                };

                NotificationService.sendNotification(appointmentNotification);

                getAllAppointmentByuser();
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.error ? res.error : "Something went wrong. Please try again later.",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      }
    }
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const editAppointment = async (data: Appointment) => {
    if (user && user.role == Role.CLIENT) {
      if (Util.skipCheckingForPremiumUsers(user)) {
        if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
          return setShowModalForTherapist(true);
        }
      }
    }

    if (user && user.role == Role.THERAPIST) {
      const res = await ClientService.getClientByClientId(data.clientId._id!);

      if (res.success) {
        if (
          res.data.premiumStatus != "active" &&
          (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
          res.data.testSubscriptionStatus != "active"
        ) {
          return setShowModalForTherapist(true);
        }
      } else {
        return toast.error("Invalid client id." + data.clientId._id!, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }

    if (data) {
      getWorkingDaysOfWeek(data?.therapistId.workingHours);
      setAppointment({
        ...appointment,
        id: data._id || "",
        title: data.title,
        startTime: moment(data.start).format("H:mm A"),
        endTime: moment(data.end).format("H:mm A"),
        date: moment(data.start).format("YYYY-MM-DD HH:mm"),
        repeat: (data.repeatInfo?.repeatType as Repeat) || Repeat.DOES_NOT_REPEAT,
        color: data.color,
        meetingType: data.typeOfMeeting,
        therapist: data?.therapistId,
        client: data.clientId,
        selectedDate: moment(data.start).toISOString(),
      });

      setTimeValue(data.start.toString());
      setHourValue(moment(data.start).hour());
      setMinuteValue(moment(data.start).minute());

      setIsEventModal(!isEventModal);
    }
  };

  const sendReminderSms = (data: Appointment) => {
    if (data) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to send reminder sms?",
        showCancelButton: true,
        confirmButtonText: "Send",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const dataId = {
            appointmentId: data._id,
          };
          AppointmentService.sendReminderSms(dataId).then(res => {
            if (res.success) {
              toast.success("Reminder sms is sended.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: res.error ? res.error : "Something went wrong. Please try again later.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  // const updateApprovalStatus = async (appointmentId: string | undefined, approvedStatus: string, clientId: string, googleCalendardata: any) => {
  //   if (appointmentId) {
  //     const data = {
  //       appointmentId: appointmentId,
  //       approvedStatus: approvedStatus,
  //     };

  //     AppointmentService.updateApprovalStatus(data).then((res: any) => {
  //       if (res) {
  //         setAppointments(appointments.filter((appointment: Appointment) => appointment._id !== appointmentId));

  //         return toast.success("Appointment status is changed successfully.", {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //           className: "foo-bar",
  //         });
  //       } else {
  //         return toast.error(res.error ? res.error : "Failed to change appointment status.", {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //           className: "foo-bar",
  //         });
  //       }
  //     });

  //     if (approvedStatus == "APPROVED" && user && user.googleCalendarAccess == true) {
  //       try {
  //         if (googleCalendardata.GoogleCalendarEventId) {
  //           CommonService.updateGoogleCalendarEvent(googleCalendardata).then((res: any) => {
  //             if (res) {
  //               const googleCalendarEventId = res.data;

  //               const data = {
  //                 appointmentId: appointmentId,
  //                 approvedStatus: approvedStatus,
  //                 GoogleCalendarEventId: googleCalendarEventId,
  //               };

  //               AppointmentService.updateApprovalStatus(data);
  //             }
  //           });
  //         } else {
  //           CommonService.createGoogleCalendarEvent(googleCalendardata).then((res: any) => {
  //             if (res.success) {
  //               const googleCalendarEventId = res.data;

  //               const data = {
  //                 appointmentId: appointmentId,
  //                 approvedStatus: approvedStatus,
  //                 GoogleCalendarEventId: googleCalendarEventId,
  //               };

  //               AppointmentService.updateApprovalStatus(data);
  //             }
  //           });
  //         }
  //       } catch (error) {
  //         console.log("Google Calendar Sync Error", error);
  //       }
  //     }

  //     if (user && user.role == Role.CLIENT) {
  //       if (Util.skipCheckingForPremiumUsers(user)) {
  //         if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
  //           return setShowModalForTherapist(true);
  //         }
  //       }
  //     }

  //     if (approvedStatus == ApprovalStatus.APPROVED) {
  //       if (user && user.role == Role.THERAPIST) {
  //         try {
  //           const res = await ClientService.getClientByClientId(clientId);
  //           if (res.success) {
  //             if (
  //               res.data.premiumStatus != "active" &&
  //               (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
  //               res.data.testSubscriptionStatus != "active"
  //             ) {
  //               return setShowModalForTherapist(true);
  //             }
  //           } else {
  //             return toast.error("Invalid client id." + clientId, {
  //               position: toast.POSITION.BOTTOM_RIGHT,
  //               className: "foo-bar",
  //             });
  //           }
  //         } catch (error: any) {
  //           return toast.error(error.toString(), {
  //             position: toast.POSITION.BOTTOM_RIGHT,
  //             className: "foo-bar",
  //           });
  //         }
  //       }
  //     }
  //   }
  // };

  const updateApprovalStatus = async (appointmentIdFrom: string | undefined, approvedStatusFrom: string, clientId: string, googleCalendardataFrom: any) => {
    try {
      if (appointmentIdFrom) {

        const finalGoogleCalendardata = googleCalendardataFrom;
        const finalAppointmentId = appointmentIdFrom;
        const finalApprovedStatus = approvedStatusFrom;
        let finalGoogleCalendarEventId;

        if (user?.googleCalendarAccess == true && finalApprovedStatus == "APPROVED") {
          if (finalGoogleCalendardata?.GoogleCalendarEventId) {
            const res = await CommonService.updateGoogleCalendarEvent(finalGoogleCalendardata);

            if (res?.success && res?.data) {

              finalGoogleCalendarEventId = res?.data;

            } else {
              toast.error("Unable to update google calendar event", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

          } else {

            const res = await CommonService.createGoogleCalendarEvent(finalGoogleCalendardata);

            if (res?.success && res?.data) {

              finalGoogleCalendarEventId = res?.data;

            } else {
              toast.error("Unable to create google calendar event", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }
        }

        const finalDataForSend: any = {
          appointmentId: finalAppointmentId,
          approvedStatus: finalApprovedStatus,
        };

        if (finalGoogleCalendarEventId) {

          finalDataForSend.GoogleCalendarEventId = finalGoogleCalendarEventId;

        }

        const res = await AppointmentService.updateApprovalStatus(finalDataForSend);

        if (res?.success) {

          setAppointments(appointments.filter((appointment: Appointment) => appointment._id !== finalAppointmentId));

          toast.success("Appointment status is changed successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("Failed to change appointment status.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        if (user && user.role == Role.CLIENT) {
          if (Util.skipCheckingForPremiumUsers(user)) {
            if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
              return setShowModalForTherapist(true);
            }
          }
        }

        if (finalApprovedStatus == ApprovalStatus.APPROVED) {
          if (user && user.role == Role.THERAPIST) {
            try {
              const res = await ClientService.getClientByClientId(clientId);
              if (res.success) {
                if (
                  res.data.premiumStatus != "active" &&
                  (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
                  res.data.testSubscriptionStatus != "active"
                ) {
                  return setShowModalForTherapist(true);
                }
              } else {
                return toast.error("Invalid client id." + clientId, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            } catch (error: any) {
              return toast.error(error.toString(), {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }
        }

      } else {
        return toast.error("Invalid appointment id !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      return toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  /*drop down - Rusiru start here */
  const [moreOptionsdropdownOpen, setMoreOptionsDropdownOpen] = useState(false);
  const [moreOptionSessionId, setMoreOptionSessionId] = useState('')

  const toggleMoreOptionDropdown = (sessionId: any) => {
    setMoreOptionSessionId(sessionId);
    setMoreOptionsDropdownOpen(prevState => !prevState);
  };

  const [moreOptionsdropdownOpen2, setMoreOptionsDropdownOpen2] = useState(false);
  const [moreOptionSessionId2, setMoreOptionSessionId2] = useState('')

  const toggleMoreOptionDropdown2 = (sessionId: any) => {
    setMoreOptionSessionId2(sessionId);
    setMoreOptionsDropdownOpen2(prevState => !prevState);
  };

  const [moreOptionsdropdownOpen3, setMoreOptionsDropdownOpen3] = useState(false);
  const [moreOptionSessionId3, setMoreOptionSessionId3] = useState('')

  const toggleMoreOptionDropdown3 = (sessionId: any) => {
    setMoreOptionSessionId3(sessionId);
    setMoreOptionsDropdownOpen3(prevState => !prevState);
  };

  const [moreOptionsdropdownOpen4, setMoreOptionsDropdownOpen4] = useState(false);
  const [moreOptionSessionId4, setMoreOptionSessionId4] = useState('')

  const toggleMoreOptionDropdown4 = (sessionId: any) => {
    setMoreOptionSessionId4(sessionId);
    setMoreOptionsDropdownOpen4(prevState => !prevState);
  };

  /*drop down - Rusiru end here */

  const handleStartMeeting = (session: Appointment) => {
    const todayDate = moment().startOf("day");
    const userType = user?.role === Role.CLIENT ? "Therapist" : "Client";
  
    const olderSession = appointments
      ?.filter((appt) => 
        moment(appt.start).isSameOrAfter(todayDate) &&
        moment(appt.start).isBefore(session.start) &&
        moment(appt.start).format("YYYY-MM-DD") !== moment(session.start).format("YYYY-MM-DD") &&
        (appt.therapistId._id === session.therapistId._id || appt.clientId._id === session.clientId._id)
      )
      .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())[0];
  
    if (olderSession) {
      Swal.fire({
        icon: "warning",
        title: "Incorrect Session Date",
        html: `You are about to start the session scheduled for <b>${moment(session.start).format(
          "dddd, MMMM D, YYYY"
        )}</b>, but you have an earlier scheduled session on <b>${moment(olderSession.start).format(
          "dddd, MMMM D, YYYY"
        )}</b> with this ${userType}. Are you sure you want to proceed?`,
        showCancelButton: true,
        confirmButtonText: "Proceed Anyway",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          startMeeting(session);
        }
      });
  
      return;
    }
  
    startMeeting(session);
  };
  
  const startMeeting = (session: Appointment) => {
    user?.role === Role.CLIENT
      ? startMeetingNew2(
          session.therapistId._id,
          true,
          session._id,
          session.start,
          session.end,
          session.status,
          session.approvedStatus
        )
      : startMeetingNew2(
          session.clientId._id,
          true,
          session._id,
          session.start,
          session.end,
          session.status,
          session.approvedStatus
        );
  };

  return (
    <React.Fragment>
      <AvatarModeEnableModal showModal={showAvatarModeEnablePopup} setShowModal={setShowAvatarModeEnablePopup} loggedUser={user} setLoggedUser={setUser} />
      {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
      {showNotApproved && <Modal17 setShowModal={setShowNotApproved} showModal={showNotApproved} />}
      {showAlreadyCompleted && <Modal13 setShowModal={setShowAlreadyCompleted} showModal={showAlreadyCompleted} />}
      {showExpired && <Modal12 setShowModal={setShowExpired} showModal={showExpired} />}
      {showTooEarly && <Modal11 setShowModal={setShowTooEarly} showModal={showTooEarly} />}
      {showModal && <MessageModal setShowModal={setShowModal} showModal={showModal} message={"Please subscribe to a provided plan to access this feature."} />}
      {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
      {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}
      {showErrorModal && (
        <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
      )}
      <Elements stripe={stripePromise}>
        {showPayImcompleteCoPayments && (
          <Modal28
            setShowModal={setShowPayIncompleteCopayments}
            showModal={showPayImcompleteCoPayments}
            copaymentAmount={coPaymentAmount}
            setPayAmount={setPaidCoAmount}
            setAfterPayCoAmount={setAfterPaidCoAmount}
          />
        )}
      </Elements>
      {showImcompleteCoPayments && (
        <Modal29
          setShowModal={setShowIncompleteCopayments}
          showModal={showImcompleteCoPayments}
          copaymentAmount={coPaymentAmount}
          clientId={clientIdForSchedule}
          setCallModal={setJoinCall}
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Appointments" breadcrumbItem="Appointments" />*/}
          <Row>
            <Col lg={12} className="">
              <Card style={{ minHeight: "90vh" }}>
                <CardBody>
                  <Row>
                    <Col sm={12} md={12} lg={3} xl={2}>
                      <Nav className="flex-column vertical-icon mt-2 ">
                        {user?.role == Role.THERAPIST && (
                          <NavItem className="">
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({ active: customActiveTab === "5" })}
                              onClick={() => {
                                toggleCustom("5");
                              }}
                              to={""}
                            >
                              <i className="bx bx-calendar"></i> Calendar
                            </NavLink>
                          </NavItem>
                        )}
                        {user?.role == "THERAPIST" && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({ active: customActiveTab === "1" })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                              to={""}
                            >
                              <i className="bx bx-sync"></i> Pending Appointments
                            </NavLink>
                          </NavItem>
                        )}

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: customActiveTab === "2" })}
                            onClick={() => {
                              toggleCustom("2");
                            }}
                            to={""}
                          >
                            <i className="bx bx-time"></i> Upcoming Appointments
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: customActiveTab === "3" })}
                            onClick={() => {
                              toggleCustom("3");
                            }}
                            to={""}
                          >
                            <i className="bx bx-calendar-minus"></i> Past Appointments
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: customActiveTab === "4" })}
                            onClick={() => {
                              toggleCustom("4");
                            }}
                            to={""}
                          >
                            <i className="bx bx-calendar-x"></i> Rejected Appointments
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>

                    <Col sm={12} md={12} lg={9} xl={10}>
                      {user?.role == Role.THERAPIST && (
                        <TabContent activeTab={customActiveTab} className="text-muted">
                          <TabPane tabId="1">
                            <Row>
                              <div className="card-heading pb15">
                                <div className="mb30"></div>
                                {appointments !== undefined && appointments.length > 0 ? (
                                  <>
                                    {appointments &&
                                      appointments.map((session: Appointment) => {
                                        const staratDate = new Date(session?.start);
                                        const createdDate = new Date(session?.createdAt);
                                        return (
                                          <div className="upcoming-appointment-main-list-item" key={session._id}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                              <div style={{ display: "flex" }}>
                                                <img src={images.appointmentIcon} className="appontment-icon" />
                                                <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                  {weekday[staratDate.getDay()] +
                                                    ", " +
                                                    monthNames[staratDate.getMonth()] +
                                                    " " +
                                                    staratDate.getDate() +
                                                    " " +
                                                    staratDate.getUTCFullYear()}
                                                </h4>
                                              </div>

                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                {session.repeatInfo?.repeatType == Repeat.WEEKLY && <h4 className="mb-0 font-size-12 weekly">Weekly</h4>}
                                                {session.repeatInfo?.repeatType == Repeat.BI_WEEKLY && (
                                                  <h4 className="mb-0 font-size-12 bi-weekly">Bi Weekly</h4>
                                                )}
                                                <h4 className="mb-0 font-size-12 created-at-label" style={{ color: "black", paddingRight: "10px" }}>
                                                  Created At:
                                                </h4>
                                                <img src={images.appointmentIcon} className="appontment-icon" />
                                                <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                  {weekday[createdDate.getDay()] +
                                                    ", " +
                                                    monthNames[createdDate.getMonth()] +
                                                    " " +
                                                    createdDate.getDate() +
                                                    " " +
                                                    createdDate.getUTCFullYear()}
                                                </h4>
                                              </div>
                                            </div>

                                            <div className="mt-2 upcoming-appointment-sub-list-item">
                                              <div className="upcoming-appointment-sub-list-item-front-box">
                                                <h4 className="mb-0 font-size-16" style={{ color: "black" }}>
                                                  {session.title} -&nbsp;
                                                  {user?.role == "CLIENT"
                                                    ? session.therapistId.firstname + " " + session.therapistId.lastname
                                                    : session.clientId.firstname + " " + session.clientId.lastname}
                                                </h4>
                                                <div style={{ display: "flex", marginTop: 5 }}>
                                                  <img src={images.clockIcon} className="clock-icon" />
                                                  <h4 className="mb-0 font-size-12" style={{ color: "#575151" }}>
                                                    {Util.formatAMPM(staratDate)}
                                                  </h4>
                                                </div>
                                              </div>

                                              <div className="upcoming-appointment-sub-list-button">
                                                <div className="flex">
                                                  <Dropdown
                                                    isOpen={moreOptionsdropdownOpen && moreOptionSessionId == session._id}
                                                    toggle={() => toggleMoreOptionDropdown(session._id)}
                                                  >
                                                    <DropdownToggle caret className="btn-sm custom-toggle-admin">
                                                      <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      <DropdownItem>
                                                        <button className="btn btn-sm btn-info mr-2 minw-100-drop-item d-none">View</button>
                                                      </DropdownItem>
                                                      {(session.status == AppointmentStatus.PENDING ||
                                                        session.status == AppointmentStatus.WAITING_FOR_APPROVAL) &&
                                                        session.typeOfMeeting == AppointmentType.VIDEO &&
                                                        (session.meetingStatus == null || session.meetingStatus != "STARTED") && (
                                                          <DropdownItem>
                                                            <button
                                                              className="btn btn-sm btn-primary mr-2 minw-100-drop-item text-start"
                                                              onClick={() =>
                                                                user?.role == Role.CLIENT
                                                                  ? startMeetingNew2(
                                                                    session.therapistId._id,
                                                                    true,
                                                                    session._id,
                                                                    session.start,
                                                                    session.end,
                                                                    session.status,
                                                                    session.approvedStatus
                                                                  )
                                                                  : startMeetingNew2(
                                                                    session.clientId._id,
                                                                    true,
                                                                    session._id,
                                                                    session.start,
                                                                    session.end,
                                                                    session.status,
                                                                    session.approvedStatus
                                                                  )
                                                              }
                                                            >
                                                              Start
                                                            </button>
                                                          </DropdownItem>
                                                        )}

                                                      {(session.status == AppointmentStatus.PENDING ||
                                                        session.status == AppointmentStatus.WAITING_FOR_APPROVAL) &&
                                                        session.typeOfMeeting == AppointmentType.VIDEO &&
                                                        session.meetingStatus != null &&
                                                        session.meetingStatus == "STARTED" &&
                                                        session.meetingId != null &&
                                                        session.meetingStartedBy != null && (
                                                          <DropdownItem>
                                                            <button
                                                              className="btn btn-sm btn-primary mr-2 minw-100-drop-item text-start"
                                                              onClick={() => {
                                                                const timeDifferenceWithStartAndEnd = moment.duration(
                                                                  moment(session.end).diff(moment(session.start))
                                                                );
                                                                const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();
                                                                const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
                                                                startZoomCall(
                                                                  false,
                                                                  user?.role == Role.CLIENT ? session.therapistId._id : session.clientId._id,
                                                                  finalMeetingTime,
                                                                  session._id,
                                                                  ""
                                                                );
                                                              }}
                                                            >
                                                              Join
                                                            </button>
                                                          </DropdownItem>
                                                        )}
                                                      <DropdownItem>
                                                        <button
                                                          className="btn btn-sm btn-warning mr-2 minw-100-drop-item text-start"
                                                          onClick={() => {
                                                            editAppointment(session);
                                                          }}
                                                        >
                                                          Reschedule
                                                        </button>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </Dropdown>
                                                </div>

                                                {/* {session.createdBy?._id !== user?._id && (
                                                  <>
                                                    <button
                                                      className="btn btn-sm btn-success mr-2 btn-style2"
                                                      onClick={() => {
                                                        updateApprovalStatus(session?._id, ApprovalStatus.APPROVED, session.clientId._id!, session);
                                                      }}
                                                    >
                                                      Accept
                                                    </button>

                                                    <button
                                                      className="btn btn-sm btn-danger mr-2 btn-style2"
                                                      onClick={() => {
                                                        updateApprovalStatus(session?._id, ApprovalStatus.REJECTED, session.clientId._id!, session);
                                                      }}
                                                    >
                                                      Reject
                                                    </button>
                                                  </>
                                                )} */}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}

                                    {isLoading ? (
                                      <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }}>
                                        Loading...
                                      </button>
                                    ) : appointmentsCount >= 50 ? (
                                      <>
                                        <button
                                          className="btn btn-warning text-center mt-4 button_center"
                                          style={{ width: "unset", margin: "auto" }}
                                          onClick={seeMore}
                                        >
                                          See More
                                        </button>
                                      </>
                                    ) : appointments?.length > 50 ? (
                                      <span className="text-center mt-4 button_center">No more appointments available...</span>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isLoading && <Spinner />}
                                    {!isLoading && !isError && appointments.length == 0 && <div className="no-appointments">No pending appointments.</div>}
                                  </>
                                )}
                              </div>
                            </Row>
                          </TabPane>
                        </TabContent>
                      )}

                      <TabContent activeTab={customActiveTab} className="text-muted">
                        <TabPane tabId="2">
                          <Row>
                            <div className="card-heading pb15">
                              <div className="mb30"></div>
                              {appointments !== undefined && appointments.length > 0 ? (
                                <>
                                  {appointments &&
                                    [...appointments].reverse().map((session: Appointment) => {
                                      const staratDate = new Date(session?.start);
                                      const createdDate = new Date(session?.createdAt);

                                      return (
                                        <div className="upcoming-appointment-main-list-item" key={session._id}>
                                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex" }}>
                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[staratDate.getDay()] +
                                                  ", " +
                                                  monthNames[staratDate.getMonth()] +
                                                  " " +
                                                  staratDate.getDate() +
                                                  " " +
                                                  staratDate.getFullYear()}
                                              </h4>
                                            </div>

                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              {session.repeatInfo?.repeatType == Repeat.WEEKLY && <h4 className="mb-0 font-size-12 weekly">Weekly</h4>}

                                              {session.repeatInfo?.repeatType == Repeat.BI_WEEKLY && <h4 className="mb-0 font-size-12 bi-weekly">Bi Weekly</h4>}

                                              <h4 className="mb-0 font-size-12 created-at-label" style={{ color: "black", paddingRight: "10px" }}>
                                                Created At:
                                              </h4>

                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[createdDate.getDay()] +
                                                  ", " +
                                                  monthNames[createdDate.getMonth()] +
                                                  " " +
                                                  createdDate.getDate() +
                                                  " " +
                                                  createdDate.getFullYear()}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="mt-2 upcoming-appointment-sub-list-item ">
                                            <div className="upcoming-appointment-sub-list-item-front-box">
                                              <h4 className="mb-0 font-size-16" style={{ color: "black" }}>
                                                {session.title} -&nbsp;
                                                {user?.role == "CLIENT"
                                                  ? session.therapistId.firstname + " " + session.therapistId.lastname
                                                  : session.clientId.firstname + " " + session.clientId.lastname}
                                              </h4>
                                              <div style={{ display: "flex", marginTop: 5 }}>
                                                <img src={images.clockIcon} className="clock-icon" />
                                                <h4 className="mb-0 font-size-12" style={{ color: "#575151" }}>
                                                  {Util.formatAMPM(staratDate)} | &nbsp;
                                                  
                                                  { session.status !=="PENDING" &&
                                                  <span
                                                    style={{ backgroundColor: Util.statusColor(session.status), color: Util.statusFontColor(session.status) }}
                                                    className={"p-1 font-size-10 status-bg"}
                                                  >
                                                    {session.status}
                                                  </span>
                                                  }

                                                </h4>
                                              </div>
                                            </div>

                                            {moment.duration(moment(session.start).diff(moment(new Date()))).asMinutes() < 31 &&
                                              moment.duration(moment(session.start).diff(moment(new Date()))).asMinutes() > -1441 && (
                                                <div className="upcoming-appointment-sub-list-item-front-box ml-3">
                                                  {user?.role == Role.CLIENT && session.noOfCallingTriesByTherapist != null && (
                                                    <h4 className=" font-size-14" style={{ color: "black" }}>
                                                      {session.noOfCallingTriesByTherapist}&nbsp;missed calls
                                                    </h4>
                                                  )}
                                                  {user?.role == Role.THERAPIST && session.noOfCallingTriesByClient != null && (
                                                    <h4 className=" font-size-14" style={{ color: "black" }}>
                                                      {session.noOfCallingTriesByClient}&nbsp;missed calls
                                                    </h4>
                                                  )}
                                                  {user?.role == Role.CLIENT && session.noOfCallingTriesByClient != null && (
                                                    <h4 className=" font-size-14" style={{ color: "black" }}>
                                                      {session.noOfCallingTriesByClient}&nbsp;tries
                                                    </h4>
                                                  )}
                                                  {user?.role == Role.THERAPIST && session.noOfCallingTriesByTherapist != null && (
                                                    <h4 className="font-size-14" style={{ color: "black" }}>
                                                      {session.noOfCallingTriesByTherapist}&nbsp;tries
                                                    </h4>
                                                  )}
                                                </div>
                                              )}

                                            <div className="upcoming-appointment-sub-list-button">
                                              <div className="flex">
                                                <Dropdown
                                                  isOpen={moreOptionsdropdownOpen2 && moreOptionSessionId2 == session._id}
                                                  toggle={() => toggleMoreOptionDropdown2(session._id)}
                                                >
                                                  <DropdownToggle caret className="btn-sm custom-toggle-admin">
                                                    <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {(session.status == AppointmentStatus.PENDING ||
                                                      session.status == AppointmentStatus.WAITING_FOR_APPROVAL) &&
                                                      session.typeOfMeeting == AppointmentType.VIDEO &&
                                                      (session.meetingStatus == null || session.meetingStatus != "STARTED") && (
                                                        <DropdownItem>
                                                          <button
                                                            className="btn btn-sm mb-0 btn-primary mr-2 minw-100-drop-item text-start"
                                                            onClick={() => handleStartMeeting(session)}
                                                            // onClick={() =>
                                                            //   user?.role == Role.CLIENT
                                                            //     ? startMeetingNew2(
                                                            //       session.therapistId._id,
                                                            //       true,
                                                            //       session._id,
                                                            //       session.start,
                                                            //       session.end,
                                                            //       session.status,
                                                            //       session.approvedStatus
                                                            //     )
                                                            //     : startMeetingNew2(
                                                            //       session.clientId._id,
                                                            //       true,
                                                            //       session._id,
                                                            //       session.start,
                                                            //       session.end,
                                                            //       session.status,
                                                            //       session.approvedStatus
                                                            //     )
                                                            // }
                                                          >
                                                            Start
                                                          </button>
                                                        </DropdownItem>
                                                      )}
                                                    {(session.status == AppointmentStatus.PENDING ||
                                                      session.status == AppointmentStatus.WAITING_FOR_APPROVAL) &&
                                                      session.typeOfMeeting == AppointmentType.VIDEO &&
                                                      session.meetingStatus != null &&
                                                      session.meetingStatus == "STARTED" &&
                                                      session.meetingId != null &&
                                                      session.meetingStartedBy != null && (
                                                        <DropdownItem>
                                                          <button
                                                            className="btn btn-sm btn-success mr-2 minw-100-drop-item text-start"
                                                            onClick={() => {
                                                              const timeDifferenceWithStartAndEnd = moment.duration(
                                                                moment(session.end).diff(moment(session.start))
                                                              );
                                                              const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();
                                                              const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
                                                              // startZoomCallNew(
                                                              //   false,
                                                              //   user?.role == Role.CLIENT ? session.therapistId._id : session.clientId._id,
                                                              //   finalMeetingTime,
                                                              //   session._id,
                                                              //   ""
                                                              // );
                                                              startZoomCall(
                                                                false,
                                                                user?.role == Role.CLIENT ? session.therapistId._id : session.clientId._id,
                                                                finalMeetingTime,
                                                                session._id,
                                                                ""
                                                              );
                                                            }}
                                                          >
                                                            Join
                                                          </button>
                                                        </DropdownItem>
                                                      )}
                                                    {user?.role == "THERAPIST" && (
                                                      <DropdownItem>
                                                        <button
                                                          className="btn btn-sm btn-danger mr-2 minw-100-drop-item text-start"
                                                          onClick={() => deleteAppointment(session)}
                                                        >
                                                          Delete
                                                        </button>
                                                      </DropdownItem>
                                                    )}
                                                    <DropdownItem>
                                                      <button
                                                        className="btn btn-sm btn-warning mr-2 minw-100-drop-item text-start"
                                                        disabled={session.status === AppointmentStatus.COMPLETED}
                                                        onClick={() => editAppointment(session)}
                                                      >
                                                        Reschedule
                                                      </button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                      {user?.role == "THERAPIST" && (
                                                        <button
                                                          className="btn-sm btn btn-insurance mr-2 minw-100-drop-item text-start"
                                                          disabled={session.status === AppointmentStatus.COMPLETED}
                                                          onClick={() => sendReminderSms(session)}
                                                        >
                                                          Send Reminder
                                                        </button>
                                                      )}
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {isLoading ? (
                                    <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }}>
                                      Loading...
                                    </button>
                                  ) : appointmentsCount >= 50 ? (
                                    <>
                                      <button
                                        className="btn btn-warning text-center mt-4 button_center"
                                        style={{ width: "unset", margin: "auto" }}
                                        onClick={seeMore}
                                      >
                                        See More
                                      </button>
                                    </>
                                  ) : appointments?.length > 50 ? (
                                    <span className="text-center mt-4 button_center">No more appointments available...</span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {isLoading && <Spinner />}
                                  {!isLoading && !isError && appointments.length == 0 && <div className="no-appointments">No upcoming appointments.</div>}
                                </>
                              )}
                            </div>
                          </Row>
                        </TabPane>
                      </TabContent>

                      <TabContent activeTab={customActiveTab} className="text-muted">
                        <TabPane tabId="3">
                          <Row>
                            <div className="card-heading pb15">
                              <div className="mb30"></div>
                              {appointments !== undefined && appointments.length > 0 ? (
                                <>
                                  {appointments &&
                                    appointments.map((session: Appointment) => {
                                      const staratDate = new Date(session?.start);
                                      const createdDate = new Date(session?.createdAt);

                                      return (
                                        <div className="upcoming-appointment-main-list-item" key={session._id}>
                                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex" }}>
                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[staratDate.getDay()] +
                                                  ", " +
                                                  monthNames[staratDate.getMonth()] +
                                                  " " +
                                                  staratDate.getDate() +
                                                  " " +
                                                  staratDate.getFullYear()}
                                              </h4>
                                            </div>

                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              {session.repeatInfo?.repeatType == Repeat.WEEKLY && <h4 className="mb-0 font-size-12 weekly">Weekly</h4>}

                                              {session.repeatInfo?.repeatType == Repeat.BI_WEEKLY && <h4 className="mb-0 font-size-12 bi-weekly">Bi Weekly</h4>}

                                              <h4 className="mb-0 font-size-12 created-at-label" style={{ color: "black", paddingRight: "10px" }}>
                                                Created At:
                                              </h4>

                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[createdDate.getDay()] +
                                                  ", " +
                                                  monthNames[createdDate.getMonth()] +
                                                  " " +
                                                  createdDate.getDate() +
                                                  " " +
                                                  createdDate.getFullYear()}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="mt-2 upcoming-appointment-sub-list-item">
                                            <div className="upcoming-appointment-sub-list-item-front-box">
                                              <h4 className="mb-0 font-size-16" style={{ color: "black" }}>
                                                {session.title} -&nbsp;
                                                {user?.role == "CLIENT"
                                                  ? session.therapistId.firstname + " " + session.therapistId.lastname
                                                  : session.clientId.firstname + " " + session.clientId.lastname}
                                              </h4>
                                              <div style={{ display: "flex", marginTop: 5 }}>
                                                <img src={images.clockIcon} className="clock-icon" />
                                                <h4 className="mb-0 font-size-12" style={{ color: "#575151" }}>
                                                  {Util.formatAMPM(staratDate)} | &nbsp;
                                                  <span
                                                    style={{ backgroundColor: Util.statusColor(session.status), color: Util.statusFontColor(session.status) }}
                                                    className={"p-1 font-size-10 status-bg"}
                                                  >
                                                    {session.status}
                                                  </span>
                                                </h4>
                                              </div>
                                            </div>

                                            <div className="upcoming-appointment-sub-list-button">
                                              <div className="flex">
                                                <Dropdown
                                                  isOpen={moreOptionsdropdownOpen3 && moreOptionSessionId3 == session._id}
                                                  toggle={() => toggleMoreOptionDropdown3(session._id)}
                                                >
                                                  <DropdownToggle caret className="btn-sm custom-toggle-admin">
                                                    <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {(session.status == AppointmentStatus.PENDING ||
                                                      (session.status == AppointmentStatus.WAITING_FOR_APPROVAL &&
                                                        session.approvedStatus == ApprovalStatus.PENDING)) &&
                                                      session.typeOfMeeting == AppointmentType.VIDEO &&
                                                      (session.meetingStatus == null || session.meetingStatus != "STARTED") && (
                                                        <DropdownItem>
                                                          <button
                                                            className="btn btn-sm btn-primary mr-2 minw-100-drop-item text-start"
                                                            onClick={() =>
                                                              user?.role == Role.CLIENT
                                                                ? startMeetingNew2(
                                                                  session.therapistId._id,
                                                                  true,
                                                                  session._id,
                                                                  session.start,
                                                                  session.end,
                                                                  session.status,
                                                                  session.approvedStatus
                                                                )
                                                                : startMeetingNew2(
                                                                  session.clientId._id,
                                                                  true,
                                                                  session._id,
                                                                  session.start,
                                                                  session.end,
                                                                  session.status,
                                                                  session.approvedStatus
                                                                )
                                                            }
                                                          >
                                                            Start
                                                          </button>
                                                        </DropdownItem>
                                                      )}
                                                    {(session.status == AppointmentStatus.PENDING ||
                                                      (session.status == AppointmentStatus.WAITING_FOR_APPROVAL &&
                                                        session.approvedStatus == ApprovalStatus.PENDING)) &&
                                                      session.typeOfMeeting == AppointmentType.VIDEO &&
                                                      session.meetingStatus != null &&
                                                      session.meetingStatus == "STARTED" &&
                                                      session.meetingId != null &&
                                                      session.meetingStartedBy != null && (
                                                        <DropdownItem>
                                                          <button
                                                            className="btn btn-sm btn-primary mr-2 minw-100-drop-item text-start"
                                                            onClick={() => {
                                                              const timeDifferenceWithStartAndEnd = moment.duration(
                                                                moment(session.end).diff(moment(session.start))
                                                              );
                                                              const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();
                                                              const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
                                                              // joinMeeting(
                                                              //   session.meetingId,
                                                              //   user?.role == Role.CLIENT ? session.therapistId._id : session.clientId._id,
                                                              //   session.therapistId._id,
                                                              //   finalMeetingTime,
                                                              //   true,
                                                              //   true,
                                                              //   true,
                                                              //   true,
                                                              //   session._id
                                                              // );
                                                              // startZoomCallNew(
                                                              //   false,
                                                              //   user?.role == Role.CLIENT ? session.therapistId._id : session.clientId._id,
                                                              //   finalMeetingTime,
                                                              //   session._id,
                                                              //   ""
                                                              // );
                                                              startZoomCall(
                                                                false,
                                                                user?.role == Role.CLIENT ? session.therapistId._id : session.clientId._id,
                                                                finalMeetingTime,
                                                                session._id,
                                                                ""
                                                              );
                                                            }}
                                                          >
                                                            Join
                                                          </button>
                                                        </DropdownItem>
                                                      )}
                                                    {user?.role == "THERAPIST" && (
                                                      <DropdownItem>
                                                        <button className="btn btn-sm btn-danger mr-2 minw-100-drop-item text-start" onClick={() => deleteAppointment(session)}>
                                                          Delete
                                                        </button>
                                                      </DropdownItem>
                                                    )}
                                                    <DropdownItem>
                                                      <button
                                                        className="btn btn-sm btn-warning mr-2 minw-100-drop-item text-start"
                                                        onClick={() => {
                                                          editAppointment(session);
                                                        }}
                                                      >
                                                        Reschedule
                                                      </button>
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {isLoading ? (
                                    <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }}>
                                      Loading...
                                    </button>
                                  ) : appointmentsCount >= 50 ? (
                                    <>
                                      <button
                                        className="btn btn-warning text-center mt-4 button_center"
                                        style={{ width: "unset", margin: "auto" }}
                                        onClick={seeMore}
                                      >
                                        See More
                                      </button>
                                    </>
                                  ) : appointments?.length > 50 ? (
                                    <span className="text-center mt-4 button_center">No more appointments available...</span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {isLoading && <Spinner />}
                                  {!isLoading && !isError && appointments.length == 0 && <div className="no-appointments">No past appointments.</div>}
                                </>
                              )}
                            </div>
                          </Row>
                        </TabPane>
                      </TabContent>

                      <TabContent activeTab={customActiveTab} className="pt10 text-muted">
                        <TabPane tabId="4">
                          <Row>
                            <div className="card-heading">
                              {appointments !== undefined && appointments.length > 0 ? (
                                <>
                                  {appointments &&
                                    appointments.map((session: Appointment) => {
                                      const staratDate = new Date(session?.start);
                                      const createdDate = new Date(session?.createdAt);

                                      return (
                                        <div className="upcoming-appointment-main-list-item" key={session._id}>
                                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex" }}>
                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[staratDate.getDay()] +
                                                  ", " +
                                                  monthNames[staratDate.getMonth()] +
                                                  " " +
                                                  staratDate.getDate() +
                                                  " " +
                                                  staratDate.getUTCFullYear()}
                                              </h4>
                                            </div>

                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              {session.repeatInfo?.repeatType == Repeat.WEEKLY && <h4 className="mb-0 font-size-12 weekly">Weekly</h4>}

                                              {session.repeatInfo?.repeatType == Repeat.BI_WEEKLY && <h4 className="mb-0 font-size-12 bi-weekly">Bi Weekly</h4>}

                                              <h4 className="mb-0 font-size-12 created-at-label" style={{ color: "black", paddingRight: "10px" }}>
                                                Created At:
                                              </h4>

                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[createdDate.getDay()] +
                                                  ", " +
                                                  monthNames[createdDate.getMonth()] +
                                                  " " +
                                                  createdDate.getDate() +
                                                  " " +
                                                  createdDate.getUTCFullYear()}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="mt-2 upcoming-appointment-sub-list-item">
                                            <div className="upcoming-appointment-sub-list-item-front-box">
                                              <h4 className="mb-0 font-size-16" style={{ color: "black" }}>
                                                {session.title} -&nbsp;
                                                {user?.role == "CLIENT"
                                                  ? session.therapistId.firstname + " " + session.therapistId.lastname
                                                  : session.clientId.firstname + " " + session.clientId.lastname}
                                              </h4>
                                              <div style={{ display: "flex", marginTop: 5 }}>
                                                <img src={images.clockIcon} className="clock-icon" />
                                                <h4 className="mb-0 font-size-12" style={{ color: "#575151" }}>
                                                  {Util.formatAMPM(staratDate)}
                                                </h4>
                                              </div>
                                            </div>

                                            <div className="upcoming-appointment-sub-list-button">
                                              <div className="flex">
                                                <Dropdown
                                                  isOpen={moreOptionsdropdownOpen4 && moreOptionSessionId4 == session._id}
                                                  toggle={() => toggleMoreOptionDropdown4(session._id)}
                                                >
                                                  <DropdownToggle caret className="btn-sm custom-toggle-admin">
                                                    <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {user?.role == "THERAPIST" && (
                                                      <DropdownItem>
                                                        <button className="btn btn-sm btn-danger mr-2 minw-100-drop-item text-start" onClick={() => deleteAppointment(session)}>
                                                          Delete
                                                        </button>
                                                      </DropdownItem>
                                                    )}
                                                    <DropdownItem>
                                                      <button
                                                        className="btn btn-sm btn-warning mr-2 minw-100-drop-item text-start"
                                                        onClick={() => {
                                                          editAppointment(session);
                                                        }}
                                                      >
                                                        Reschedule
                                                      </button>
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {isLoading ? (
                                    <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }}>
                                      Loading...
                                    </button>
                                  ) : appointmentsCount >= 50 ? (
                                    <>
                                      <button
                                        className="btn btn-warning text-center mt-4 button_center"
                                        style={{ width: "unset", margin: "auto" }}
                                        onClick={seeMore}
                                      >
                                        See More
                                      </button>
                                    </>
                                  ) : appointments?.length > 50 ? (
                                    <span className="text-center mt-4 button_center">No more appointments available...</span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {isLoading && <Spinner />}
                                  {!isLoading && !isError && appointments.length == 0 && <div className="no-appointments">No Rejected appointments.</div>}
                                </>
                              )}
                            </div>
                          </Row>
                        </TabPane>
                      </TabContent>
                      {user?.role == Role.THERAPIST && (
                        <TabContent activeTab={customActiveTab} className="text-muted">
                          <TabPane tabId="5">
                            <Row>
                              <AppointmentCalendarTherapist calendar={calendar} />
                            </Row>
                          </TabPane>
                        </TabContent>
                      )}
                    </Col>
                  </Row>
                  <Col xl={12}></Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {showErrorModal && (
        <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
      )}

      <AppointmentModal
        clientId={appointment.client._id}
        appointment={appointment}
        modalType={ModalType.EDIT}
        showModal={isEventModal}
        setShowModal={setIsEventModal}
        setAppointment={setAppointment}
        errorModalText={errorModalText}
        setErrorModalText={setErrorModalText}
        setShowErrorModal={setShowErrorModal}
        setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
        setShowModalForTherapist={setShowModalForTherapist}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        updateMatchedClient={() => { }}
        hourValue={hourValue}
        setHourValue={setHourValue}
        minuteValue={minuteValue}
        setMinuteValue={setMinuteValue}
        updateAppointmentObject={() => { }}
      />

      <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
        <ModalBody>
          <div className="modal-val">
            <h5 className="model-error">{errorModalText}</h5>
            <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
              Got It
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AllAppointments;
