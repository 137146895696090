import React, { MouseEventHandler, useContext, useState } from "react";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import bgIcon from "../../assets/images/layouts/Intersect.png";
import closeIcon from "../../assets/images/icons/close_icon.png";
import scheduleIcon from "../../assets/images/icons/schedule_calendar.svg";
import inactiveIcon from "../../assets/images/icons/inactive_icon.svg";
import goalIcon from "../../assets/images/icons/goal.svg";
import uploadIcon from "../../assets/images/icons/upload.svg";
import { FriendRequest } from "src/models/FriendRequest";
import { Util } from "src/Util";
import { useHistory } from "react-router-dom";
import UserContext from "src/context/UserContext";
import AppointmentModal from "../Popup/AppointmentModal";
import moment from "moment";
import { ModalType, Repeat } from "src/models/Appointment";
import { Therapist } from "src/models/Therapist";
import { Client } from "src/models/Client";
import ErrorModal from "../Popup/ErrorModal";
import Modal4 from "../Popup/Modal4";
import HomeWorksAndGoals from "../Chat/HomeWorksAndGoals";
import UploadDocuments from "../Chat/UploadDocuments";
import SkeltonClientRequest from "src/common/skeleton/SkeltonClientRequest";
import Swal from "sweetalert2";
import { TherapistService } from "src/services/TherapistService";
import { toast } from "react-toastify";
import { ClientService } from "src/services/ClientService";

interface props {
  matchedClients: FriendRequest[];
  setMatchedClients: (data: FriendRequest[]) => void;
  onNext: MouseEventHandler<HTMLButtonElement>;
  onBack: MouseEventHandler<HTMLButtonElement>;
  offset: number;
  removeFriend: (friendData: FriendRequest) => void;
  isLoading: boolean;
}

const ClientRequests: React.FC<props> = ({
  matchedClients,
  setMatchedClients,
  onNext,
  onBack,
  offset,
  removeFriend,
  isLoading,
}) => {
  const history = useHistory();
  const [user] = useContext(UserContext);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [clientId, setClientId] = useState<any>();
  const [userId, setUserId] = useState<any>();
  const [isHomeWorkOpen, setIsHomeWorkOpen] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [isUploadDocumentsOpen, setIsUploadDocumentsOpen] = useState(false);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: ""
  });

  const handleAppointmentDetails = (clientId: any) => {
    setShowAppointmentModal(!showAppointmentModal);
    setClientId(clientId);
  };

  const toggleHGCanvas = (clientId: any) => {
    setIsHomeWorkOpen(!isHomeWorkOpen);
    setClientId(clientId);
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const toggleUDCanvas = (clientId: any) => {
    setIsUploadDocumentsOpen(!isUploadDocumentsOpen);
    setClientId(clientId);
  };

  const makeClientInactive = async (clientId: any) => {
    const client = await ClientService.getClientByClientId(clientId._id);

    if (client.data.clientActiveStatus && client.data.clientActiveStatus == true) {
      Swal.fire({
        icon: "info",
        title: "Are you sure want to disable this client?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          TherapistService.makeClientInactive({ clientId: clientId._id }).then(res => {
            if (res.success) {
              toast.success(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
              });

              const mClient = matchedClients.filter(c => c.clientId._id == clientId._id)[0];

              mClient.clientId.clientActiveStatus = false;

              setMatchedClients(matchedClients);
            } else {
              toast.error(res.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
              });
            }
          });
        }
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "This client is already disabled. To activate again you can create new appointment.",
        showCancelButton: false,
        confirmButtonText: "Sure",
        confirmButtonColor: "#50a5f1"
      });
    }
  }

  const updateMatchedClient = (clientId: string) => {
    const mClient = matchedClients.filter(c => c.clientId._id == clientId)[0];

    mClient.clientId.clientActiveStatus = true;

    setMatchedClients(matchedClients);
  }

  return (
    <React.Fragment>
      {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
      {isHomeWorkOpen && <HomeWorksAndGoals userId={clientId} isOpened={isHomeWorkOpen} returnBool={(e: boolean) => setIsHomeWorkOpen(e)} />}
      {isUploadDocumentsOpen && <UploadDocuments userId={clientId} isOpened={isUploadDocumentsOpen} returnBool={(e: boolean) => setIsUploadDocumentsOpen(e)} />}
      <Row className="pt-4">
        <div className="custom-vertical-scroll">
          {matchedClients?.map((statDetails: FriendRequest, index: number) => (
            <Col key={index} md={12} className="d-flex justify-content-center mb-1 mt-1">
              <Card className="match-card-dashboard position-relative min-w-100">
                <div className="d-flex justify-content-between">
                  <img src={bgIcon} alt="Icon" className="card-img-top " style={{ width: "123.68px", height: "85.72px" }} />
                  <img
                    src={closeIcon}
                    alt="Icon"
                    className="closeIcon-sizes mt-1 me-1 cursor-pointer z-index-100"
                    onClick={() => {
                      removeFriend(statDetails);
                    }}
                  />
                </div>

                <CardBody className="d-sm-flex flex-sm-row mtt-86">
                  <div
                    className="col-12 col-sm-7 d-sm-flex  justify-content-sm-between"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/client-profile/" + statDetails?.clientId?._id);
                    }}
                  >
                    <div className="col-sm-6 col-12">
                      {!statDetails?.clientId?.photoId ? (
                        <div className="avatar-md mx-auto ">
                          <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>{statDetails?.clientId?.firstname?.charAt(0)}</span>
                        </div>
                      ) : (
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <img src={Util.fileURL(statDetails?.clientId?.photoId?._id)} className="avatar-md rounded-circle" />
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-12 d-flex flex-column justify-content-center align-items-center">
                      <h5 className="mb-0 d-flex mt-2 justify-content-start fs-14 align-items-start fc-black">
                        {statDetails?.clientId?.firstname} {statDetails?.clientId?.lastname}
                      </h5>
                      <p className="mb-0 fc-primary">CLIENT</p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-5 d-flex mt-2 mt-sm-0  flex-row justify-content-center align-items-center left-border-discover2  gap-4 gap-xl-1">
                    <button className="btn btn-primary btn-size-40 call-btn-bg ms-sm-1 me-sm-1">
                      <img
                        src={scheduleIcon}
                        className="btn-icon-2"
                        onClick={() => {
                          handleAppointmentDetails(statDetails?.clientId?._id);
                        }}
                        title="Schedule Appointment"
                      />
                    </button>

                    <button
                      className="btn btn-primary btn-size-40 calendar-btn-bg me-sm-1"
                      onClick={() => {
                        toggleHGCanvas(statDetails?.clientId?._id);
                      }}
                    >
                      <img src={goalIcon} className="btn-icon-2" title="Homework and Goals" />
                    </button>

                    <button className="btn btn-primary btn-size-40 calendar-btn-bg me-sm-1">
                      <img
                        src={uploadIcon}
                        className="btn-icon-2"
                        title="Upload Documents"
                        onClick={() => {
                          toggleUDCanvas(statDetails?.clientId?._id);
                        }}
                      />
                    </button>

                    <button className="btn btn-primary btn-size-40 calendar-btn-bg me-sm-1">
                      <img
                        src={inactiveIcon}
                        className="btn-icon-2"
                        title="Make client inactive"
                        onClick={() => {
                          makeClientInactive(statDetails?.clientId);
                        }}
                      />
                    </button>
                  </div>
                </CardBody>
                {statDetails != null &&
                  statDetails?.clientId != null &&
                  statDetails?.clientId?.clientActiveStatus != null &&
                  statDetails?.clientId?.clientActiveStatus == false && <div className="overlay-match-card-dashboard"></div>}
              </Card>
            </Col>
          ))}
        </div>

        <div>
          <ErrorModal errorModalText={errorModalText} isEventTimeValidationViewModal={false} eventTimeValidationModalToggle={eventTimeValidationModalToggle} />

          <AppointmentModal
            clientId={clientId}
            appointment={appointment}
            setAppointment={setAppointment}
            modalType={ModalType.CREATE}
            setShowModal={setShowAppointmentModal}
            showModal={showAppointmentModal}
            errorModalText={errorModalText}
            setErrorModalText={setErrorModalText}
            setShowErrorModal={setShowErrorModal}
            setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
            setShowModalForTherapist={setShowModalForTherapist}
            timeValue={timeValue}
            setTimeValue={setTimeValue}
            updateMatchedClient={updateMatchedClient}
            hourValue={hourValue}
            setHourValue={setHourValue}
            minuteValue={minuteValue}
            setMinuteValue={setMinuteValue}
            updateAppointmentObject={() => {}}
          />
        </div>
      </Row>
      {matchedClients?.length <= 0 && !isLoading && <div className="text-upcoming">No Matching Clients!</div>}
      {isLoading && <SkeltonClientRequest />}
      <Row>
        <div className="d-flex justify-content-between mt-2">
          <div>
            {offset > 1 && (
              <button className="btn btn-secondary btn-sm" onClick={onBack}>
                <i className="bx bx-left-arrow-alt me-2"></i> Back
              </button>
            )}
          </div>
          <div>
            {(matchedClients && matchedClients?.length >= 3) && (
              <button className="btn btn-primary btn-sm" onClick={onNext}>
                Next <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
            )}
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default ClientRequests;
