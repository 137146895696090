import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class GroupChatService {
  public static async createGroupChat(formData: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/createChatGroup`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, formData);
  }

  public static async updateGroupChat(formData: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/updateChatGroup`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, formData);
  }

  public static async getAllChatGroups(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllChatGroupsForUser`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllChatGroupsSessions(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllChatGroupSessions`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async getAllChatGroupsMembers(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllMembersInChatGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async removeGroupMember(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/removeMember`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllMatchedClientsForChatGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllMatchedClientsForChatGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async addGroupMember(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/addMember`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async createGroupChatSession(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/createChatGroupCall`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updateGroupChatSession(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/updateChatGroupCall`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllPublicChatGroupsForClient(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllPublicChatGroupsForClient`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async sendJoinRequestForPublicGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/sendJoinRequestForPublicGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async cancellJoinRequestForPublicGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/cancellJoinRequestForPublicGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllJoinRequestForPublicChatGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllJoinRequestForPublicChatGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async acceptJoinRequestForPublicGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/acceptJoinRequestForPublicGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async rejectJoinRequestForPublicGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/rejectJoinRequestForPublicGroup`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async deleteGroupChatSession(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/deleteChatGroupCall`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  // chat api's

  public static async sendMessage(formData: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/sendMessage`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, formData);
  }

  public static async deleteMessage(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/deleteMessage`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllMessagesInChatGroup(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/getAllMessages`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updateLastActiveOfMember(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/updateLastActiveOfMember`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updateMemberMainStatus(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`chatGroup/updateMainMember`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
}
