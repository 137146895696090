import { environment } from "./environment/environment";
import axios from "axios";
import { HStatus } from "./models/HomeWork";
import pdfIcon from "./assets/images/file/PDF_file_icon.svg";
import doc from "./assets/images/file/google-docs.png";
import docx from "./assets/images/file/docx-file.png";
import txt from "./assets/images/file/txt.png";
import { AppointmentType, Repeat } from "./models/Appointment";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { UserDetails } from "./models/User";
import { PremiumStatus } from "./models/Client";
import { AudienceType } from "./models/Document";
import { htmlToText } from "html-to-text";

export class Util {
  public static apiPublicUrl(path: string): string {
    return environment.api_url + "/api/public/" + path;
  }

  public static apiAuthUrl(path: string): string {
    return environment.api_url + "/api/auth/" + path;
  }

  // public static videoSdkBaseUrl(path: string): string {
  //   return environment.video_sdk_api_base_url + path;
  // }

  public static initAxios(): void {
    axios.interceptors.request.use(req => {
      req.headers.authorization = "Bearer " + localStorage.getItem("token");
      return req;
    });

    axios.interceptors.response.use(
      function (response) {
        return response.data;
      },
      function (error) {
        return { success: false, data: undefined, error: error };
      }
    );
  }

  public static formatAMPM(utcDate: Date) {
    const offset = momentTimezone(utcDate).tz('America/New_York').isDST() ? -4 * 60 : -5 * 60;
    const estTime = momentTimezone(utcDate).tz('America/New_York').utcOffset(offset).format('hh:mm A');
    return estTime;
  }

  // public static formatAMPM(date: Date) {
  //   let hours: any = date.getHours();
  //   let minutes: any = date.getMinutes();
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12;
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   const strTime = hours + ":" + minutes + " " + ampm;

  //   if (strTime.length >= 8) {
  //     return strTime;
  //   } else {
  //     return "0" + strTime;
  //   }
  // }

  public static statusColor(status: HStatus | any) {
    switch (status) {
      case HStatus.NEW:
        return "#ff8000";
      case HStatus.PENDING:
        return "#fdc841";
      case HStatus.REJECT:
        return "#ff1e00";
      case HStatus.FINISHED:
        return "#00beff";
      case HStatus.APPROVED:
        return "#16cd16";
      case HStatus.ONGOING:
        return "#ff8000";
      case HStatus.COMPLETED:
        return "#16cd16";
      default:
        break;
    }
  }

  public static statusFontColor(status: HStatus | any) {
    switch (status) {
      case HStatus.NEW:
        return "#fff";
      case HStatus.PENDING:
        return "#575151";
      case HStatus.REJECT:
        return "#fff";
      case HStatus.FINISHED:
        return "#fff";
      case HStatus.APPROVED:
        return "#fff";
      case HStatus.ONGOING:
        return "#fff";
      case HStatus.COMPLETED:
        return "#fff";
      default:
        break;
    }
  }

  public static fileType(type: any, uploadId?: string) {
    switch (type) {
      case ".pdf":
        return pdfIcon;
      case ".doc":
        return doc;
      case ".docx":
        return docx;
      case ".txt":
        return txt;
      case ".jpg":
      case ".png":
      case ".gif":
      case ".jpeg":
        return Util.fileURL(uploadId);
      default:
        return Util.fileURL(uploadId);
    }
  }

  public static getHoursAndMinutes(time: number) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;

    const hourText = hours == 1 ? " hour" : " hours";
    const minuteText = minutes == 1 ? " minute" : " minutes";

    if (hours > 0) {
      if (minutes > 0) {
        return hours + hourText + " & " + minutes + minuteText;
      } else {
        return hours + hourText;
      }
    } else {
      return minutes + minuteText;
    }
  }

  public static meetingTypeIcon = (type?: AppointmentType | undefined | string) => {
    switch (type) {
      case AppointmentType.TEXT:
        return "bx bx-message-dots";
        break;

      case AppointmentType.VIDEO:
        return "bx bx-video";
        break;

      case AppointmentType.VOICE:
        return "bx bx-user-voice";
        break;

      default:
        return "bx bxs-devices";
        break;
    }
  };

  public static meetingRepeatTypeIcon = (type?: Repeat | undefined | string) => {
    switch (type) {
      case Repeat.DOES_NOT_REPEAT:
        return "bx bx-block";
        break;

      case Repeat.WEEKLY:
        return "bx bx-calendar-minus";
        break;

      default:
        return "bx bx-repost";
        break;
    }
  };

  public static audienceType = (type?: AudienceType) => {
    switch (type) {
      case AudienceType.PUBLIC:
        return "bx bx-world";
        break;

      case AudienceType.CLIENTS:
        return "bx bx-user";
        break;

      case AudienceType.THERAPISTS:
        return "bx bx-plus-medical";
        break;

      default:
        return "bx bx-repost";
        break;
    }
  };

  public static calculateWeekNumberAndDates = (date: string, clientId: string, therapistId: string, appointmentId?: string) => {
    const startOfWeek = moment(date).startOf("week").toDate();
    const endOfWeek = moment(date).endOf("week").toDate();

    const result = {
      clientId: clientId,
      therapistId: therapistId,
      appointmentId: appointmentId,
      selectedDate: date,
      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    };

    return result;
  };

  public static calculateWeekNumberAndDatesPublic = (date: string, therapistId: string, appointmentId?: string) => {
    const startOfWeek = moment(date).startOf("week").toDate();
    const endOfWeek = moment(date).endOf("week").toDate();

    const result = {
      therapistId: therapistId,
      appointmentId: appointmentId,
      selectedDate: date,
      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    };

    return result;
  };

  public static convertH2M = (timeInHour: any) => {
    const timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  };

  public static monthsNextFrom = (date: Date, n: number): Date => {
    const result = new Date(date);

    result.setMonth(result.getMonth() + n);

    return result;
  };

  public static dateConvertToMilisecond = (date: Date) => {
    return moment(date).format("x");
  };

  public static skipCheckingForPremiumUsers(user?: UserDetails) {
    if (user && user.premiumStatus != PremiumStatus.ACTIVE) {
      return true;
    } else {
      return false;
    }
  }

  public static convertUTCDateToLocalDate(dateString: string) {
    if (dateString) {
      const getHour = dateString.split(":")[0];
      const getMinute = dateString.split(":")[1];

      const UTC = moment.utc();

      UTC.set("hour", parseInt(getHour));
      UTC.set("minute", parseInt(getMinute));
      UTC.set("second", 0);

      const local = moment(UTC).local();

      return local.format("HH:mm A");
    } else {
      return "";
    }
  }

  public static convertLocalDateToUTC(dateString: string) {
    if (dateString) {
      const [getHour, getMinute] = dateString.split(":");
  
      const local = moment();
  
      local.set("hour", parseInt(getHour, 10));
      local.set("minute", parseInt(getMinute, 10));
      local.set("second", 0);
  
      const utc = moment(local).utc();
  
      return utc.format("HH:mm A");
    } else {
      return "";
    }
  }
  // Convert time string to minutes for merging logic
  private static timeToMinutes = (time: string): number => {
    const [hoursStr, minutesStr] = time.split(/[:\s]/);
    let hours: number = parseInt(hoursStr, 10);
    const minutes: number = parseInt(minutesStr, 10) || 0;
    const period = time.slice(-2).toUpperCase();
    if (period.toUpperCase() === "PM" && hours < 12) hours += 12;
    if (period.toUpperCase() === "AM" && hours === 12) hours = 0;
    return hours * 60 + minutes;
  };
  // Convert minutes back to time string in **12-hour format with AM/PM**
  private static minutesToTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? "PM" : "AM";
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')} ${period}`;
  };
  private static mergeWorkingHours = (workingHours: { day: string; startTime: string; endTime: string }[]) => {
    
    const groupedByDay = workingHours.reduce((acc, wh) => {
        if (!acc[wh.day]) acc[wh.day] = [];
        acc[wh.day].push({
            startTime: Util.timeToMinutes(wh.startTime),
            endTime: Util.timeToMinutes(wh.endTime)
        });
        return acc;
    }, {} as Record<string, { startTime: number; endTime: number }[]>);
  
    const mergedHours: { day: string; startTime: string; endTime: string }[] = [];
    
    for (const day in groupedByDay) {
        const intervals = groupedByDay[day];
  
        intervals.sort((a, b) => a.startTime - b.startTime);
  
        const merged: { startTime: number; endTime: number }[] = [];
        let prev = intervals[0];
  
        for (let i = 1; i < intervals.length; i++) {
            const current = intervals[i];
  
            if (prev.endTime >= current.startTime) {
                prev.endTime = Math.max(prev.endTime, current.endTime);
            } else {
                merged.push(prev);
                prev = current;
            }
        }
        merged.push(prev);
  
        merged.forEach(interval => {
            mergedHours.push({
                day,
                startTime: Util.minutesToTime(interval.startTime),
                endTime: Util.minutesToTime(interval.endTime),
            });
        });
    }
  
    return mergedHours;
  };
  public static mergeOverlappedWorkingHours(availableHours: any) {
    if (!availableHours || !Array.isArray(availableHours)) {
      return;
    }
    // Step 1: Convert UTC times to Local time before merging
    const convertedHours = availableHours.map(({ day, startTime, endTime }) => ({
      day,
      startTime: Util.convertUTCDateToLocalDate(startTime),
      endTime: Util.convertUTCDateToLocalDate(endTime),
    }));
    // Step 2: Merge overlapping working hours in Local time
    const mergedWorkingHours = Util.mergeWorkingHours(convertedHours);
    // Step 3: Convert merged working hours back to UTC
    const finalUpdatedHours = mergedWorkingHours.map(({ day, startTime, endTime }) => ({
      day,
      startTime: Util.convertLocalDateToUTC(startTime),
      endTime: Util.convertLocalDateToUTC(endTime),
    }));
    
    return finalUpdatedHours;
  }

  public static convertUTCDateToLocalDate12Hour(dateString: string) {
    if (dateString) {
      const getHour = dateString.split(":")[0];
      const getMinute = dateString.split(":")[1];

      const UTC = moment.utc();

      UTC.set("hour", parseInt(getHour));
      UTC.set("minute", parseInt(getMinute));
      UTC.set("second", 0);

      const local = moment(UTC).local();

      return local.format("hh:mm A");
    } else {
      return "";
    }
  }

  public static fileURL(photoId?: string) {
    if (photoId) {
      const url = environment.api_url + environment.file_access_url + "/" + photoId + "/" + localStorage.getItem("token");
      return url;
    } else {
      return "";
    }
  }

  public static downloadDiagnosisNoteDetail(pdfName?: any) {
    if (pdfName) {
      const url = environment.api_url + "/downloadDiagonosisNoteDetail/" + pdfName;
      return url;
    } else {
      return "";
    }
  }

  public static audioURL(audioId?: string) {
    if (audioId) {
      const url = environment.api_url + environment.recorded_files_access_url + "/" + audioId + "/" + localStorage.getItem("token");
      return url;
    } else {
      return "";
    }
  }

  public static isDateInBlockedRange(startDate: moment.Moment, endDate: moment.Moment, checkDate: moment.Moment) {
    startDate = moment(startDate.add(-0.5, "hours").format("YYYY/MM/DD hh:mm A"));
    endDate = moment(endDate.format("YYYY/MM/DD hh:mm A"));
    checkDate = moment(checkDate.format("YYYY/MM/DD hh:mm A"));

    return startDate <= checkDate && checkDate < endDate;
  }

  public static isDateInBlockedRangeTherapistAppointments(startDate: moment.Moment, endDate: moment.Moment, checkDate: moment.Moment) {
    startDate = moment(startDate.format("YYYY/MM/DD hh:mm A"));
    endDate = moment(endDate.format("YYYY/MM/DD hh:mm A"));
    checkDate = moment(checkDate.format("YYYY/MM/DD hh:mm A"));

    return startDate <= checkDate && checkDate < endDate;
  }

  public static htmlToTextFunction(htmlTextMessage: string) {
    try {
      if (htmlTextMessage) {
        const finalText = htmlToText(htmlTextMessage, {
          wordwrap: 160,
        });
        return finalText;
      }
      return "Empty message";
    } catch (error) {
      return "Empty message";
    }
  }
}

Util.initAxios();
