// ModalComponent.js
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { AdminService } from "src/services/AdminService";
import Lock2 from "../../../assets/images/icons/lock2.png"
import { toast } from "react-toastify";

type ModalComponentProps = {
  isOpen: boolean;
  toggle: () => void;
  clientId: string;
};

const ResetModal: React.FC<ModalComponentProps> = ({ isOpen, toggle, clientId }) => {
  const [newPassword, setChangePassword] = useState({ changePassword: "" });
  const resetPassword = () => {
    const password = {
      newPassword: newPassword?.changePassword
    }
    AdminService.changeUserPasswordByAdmin(password, clientId).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        toggle();

      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody>
        <div className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <span className="bg-light-red mb-3 btn-48 d-flex justify-content-center align-items-center">
              <img src={Lock2} className="btn-s-24" />
            </span>
          </div>
          <div>
            <h5>Reset Password</h5>
          </div>
          <div>
            <p className="alertText">Do you want to reset password type new password below</p>
          </div>

          <div className="d-flex justify-content-evenly mb-3">
            <div></div>
            <input className="form-control w-50" placeholder="New password" type="password" onChange={e => setChangePassword({ ...newPassword, changePassword: e.target.value })} />
            <div></div>
          </div>
          <div>
            <button className="btn btn-danger mr-2 mr-md-4" onClick={toggle}>
              Cancel
            </button>
            <button className="btn btn-secondary " style={{ background: "#34C38F", borderBlock: "34C38F" }} onClick={resetPassword}>
              Reset
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ResetModal;
