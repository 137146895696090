// ModalComponent.js
import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, Row, Col } from "reactstrap";
import Close from "../../../assets/images/icons/close.png";
import user1 from "../../../assets/images/default_profile.png";
import { Client } from "src/models/Client";
import { DiscoverTherapist, Therapist } from "src/models/Therapist";
import { TherapistService } from "src/services/TherapistService";
import { AdminService } from "src/services/AdminService";
import { ClientService } from "src/services/ClientService";
import { Util } from "../../../Util";
import Swal from "sweetalert2";

type ModalComponentProps = {
  isOpen: boolean;
  toggle: () => void;
  clientData: Client;
};

const MatchModal: React.FC<ModalComponentProps> = ({ isOpen, toggle, clientData }) => {
  const insurance = localStorage.getItem("insuranceCompany");
  const [sentTherapists, setSentTherapists] = useState([] as Therapist[]);
  const LIMIT = 4;
  const [offset, setOffSet] = useState(1);
  const [userCount, setUserCount] = useState(4);
  const [isLoading, setIsLoading] = useState(true);
  // const [showSeeMore, setShowSeeMore] = useState(false)
  const [isSearchableString, setIsSearchableString] = useState({
    searchableString: '',
    // status: null,
    // zipCode: null,
  } as any);

  useEffect(() => {
    setIsSearchableString({ ...isSearchableString, searchableString: '' })
    getTherapists();
  }, [clientData, isOpen]);

  const confirmMatch = (therapist: Therapist) => {
    Swal.fire({
      title: "Confirmation",
      text: `Do you want to match with this therapist?.`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes, match",
      cancelButtonText: "No, cancel",
      confirmButtonColor: "#34C38F",
      cancelButtonColor: "#F24C4C",
    }).then(result => {
      if (result.isConfirmed) {
        ClientService.likeTherapistPublic(therapist?._id, clientData?._id).then((res: any) => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "You have successfully matched with the therapist.",
            });
            {
              toggle();
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "failed!",
              text: "there is a issue when matching with the therapist.",
            });
          }
        });
      }
    });
  };

  const searchTherapists = () => {
    const discoverObject = {
      clientId: clientData?._id,
      searchableString: isSearchableString?.searchableString
    };

    setIsLoading(true);
    AdminService.searchMatchTherapistsByClientId(discoverObject, LIMIT, 1).then(res => {
      if (res.success) {
        setUserCount(res.data?.count)
        if (res.data.userSet?.length > 0) {
          setSentTherapists(res.data.userSet);

        }
        setOffSet(1)
      }
      setIsLoading(false);
    });
  };
  const getTherapists = () => {
    // const newOfset = 0
    // setOffSet(newOfset)
    const discoverObject = {
      clientId: clientData?._id,
      searchableString: isSearchableString?.searchableString
    };

    setIsLoading(true);
    AdminService.searchMatchTherapistsByClientId(discoverObject, LIMIT, offset).then(res => {
      if (res.success) {
        setSentTherapists(res.data.userSet);
        setUserCount(res.data?.count)
        setOffSet(1)
      }
      setIsLoading(false);
    });
  };

  const seeMoreFunc = () => {
    setIsLoading(true);
    const updatedSkip = offset + 1;

    setOffSet(updatedSkip);

    const discoverObject = {
      clientId: clientData?._id,
      searchableString: isSearchableString?.searchableString
    };

    AdminService.searchMatchTherapistsByClientId(discoverObject, LIMIT, updatedSkip).then(res => {
      if (res.success) {
        setUserCount(res.data?.count)
        if (res.data.userSet?.length > 0) {
          setSentTherapists([...sentTherapists, ...res.data.userSet]);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggle}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
        >
          <img src={Close} alt="Close" className="me-2 mt-2" />
        </Button>
      </div>
      <ModalBody className="ps-4 pe-4">
        <Row>
          <span className="font-size-18 profileFont mb-2 cursor-pointer pt-4 text-center">Match with clinicians </span>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex admin-filter">
            <div className="d-flex justify-content-center pr-2 ">
              <div className="d-flex justify-content-center align-items-center pr-2">
                <span className="text-center mb-0 single-line-text mr-2">Search : </span>
              </div>
              <div onClick={(event) => event.stopPropagation()}>
                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
              </div>

            </div>
            <span className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
              onClick={() => {
                searchTherapists()
              }}
            >
              <i className="bx bx-search"></i>
            </span>
          </Col>
        </Row>
        {sentTherapists &&
          sentTherapists?.map((therapist, index) => (
            <Row key={therapist?._id} className="border-top border-bottom p-2">
              <div className="d-flex justify-content-between border-top border-bottom p-2">
                <div className="d-flex">
                  <div
                    className="img-thumbnail imageFit rounded-circle imageFit avatar-md items-center  profileImageShow"
                    style={{
                      backgroundImage: `url(${Util.fileURL(therapist.photoId?._id)} )`,
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  ></div>

                  <div className="d-flex flex-column ps-4 justify-content-center">
                    <h5 className="font-size-15 mb-1 text-left">
                      {therapist?.firstname && therapist?.firstname.charAt(0)} {therapist?.lastname}
                    </h5>
                    <p className="text-muted role-text text-left">{therapist?.profession?.name}</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <button type="button" className="btn-match btn btn-primary mb-3 pt-2 pb-2" onClick={() => confirmMatch(therapist)}>
                    Match
                  </button>
                </div>
              </div>
            </Row>
          ))}
        {sentTherapists?.length > 1 && (
          <>
            <button className="btn btn-warning text-center mt-4" onClick={seeMoreFunc}>
              See More
            </button>
          </>
        )}

        {sentTherapists?.length == 0 && !isLoading && <div>No more therapists available...</div>}
      </ModalBody>
    </Modal>
  );
};

export default MatchModal;
