import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Table } from "reactstrap";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";

const NewFormTen = (props: any) => {
  const { handleBulkFormDataUpdate, data, nextStep, previousStep } = props;
  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    mentalStatus: {
      physicalStature: {
        small: null,
        average: null,
        tall: null,
      },
      weight: {
        thin: null,
        average: null,
        overweight: null,
        obese: null,
      },
      grooming: {
        wellGroomed: null,
        normal: null,
        neglected: null,
        bizarre: null,
      },
      clothing: {
        neatClean: null,
        inappropriate: null,
        dirty: null,
        seductive: null,
        disheveled: null,
        bizarre: null,
      },
      posture: {
        normal: null,
        tense: null,
        stooped: null,
        rigid: null,
        slumped: null,
        bizarre: null,
      },
      attitude: {
        cooperative: null,
        passive: null,
        guarded: null,
        irritable: null,
        manipulative: null,
        seductive: null,
        suspicious: null,
        defensive: null,
        dramatic: null,
        silly: null,
        hostile: null,
        critical: null,
        resistant: null,
        sarcastic: null,
        uninterested: null,
        argumentative: null,
      },
      motor: {
        nonremarkable: null,
        tremor: null,
        slowed: null,
        tics: null,
        restless: null,
        agitated: null,
      },
      speech: {
        normal: null,
        rapid: null,
        slurred: null,
        loud: null,
        paucity: null,
        pressured: null,
        mute: null,
      },
      affect: {
        appropriate: null,
        inappropriate: null,
        flat: null,
        restricted: null,
        blunted: null,
        labile: null,
      },
      mood: {
        euthymic: null,
        confused: null,
        pessimistic: null,
        depressed: null,
        anxious: null,
        euphoric: null,
        apathetic: null,
        angry: null,
      },
      thoughtForm: {
        goaldirected: null,
        appropriate: null,
        logical: null,
        tangentialthinking: null,
        circumstantial: null,
        looseassociations: null,
        confused: null,
        incoherent: null,
        perseverations: null,
        flightofidea: null,
        slownessofthought: null,
      },
      thoughtContent: {
        appropriate: null,
        paranoid: null,
        suspicions: null,
        persecutions: null,
        paucity: null,
        delusions: null,
        bizarre: null,
        hypochondriac: null,
        ideasofreference: null,
      },
      preoccupations: {
        phobias: null,
        guilt: null,
        other: null,
        somatic: null,
        religion: null,
        suicide: null,
        homicidal: null,
      },
      hallucinations: {
        auditory: null,
        other: null,
        visual: null,
        sensory: null,
      },
      orientation: {
        person: null,
        place: null,
        time: null,
        situation: null,
      },
      levelOfIntellectualFunctioning: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      fundofKnowledge: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      judgment: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      insightIntoProblems: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      clinicalImpressionSummary: "",
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        mentalStatus: data?.mentalStatus,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   mentalStatus: data?.mentalStatus,
      // };
      // setFormData(updateValues);

      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));
    }
  }, [memoizedUpdateValues]);

  const handleUpdateData = () => {
    const updateDetails = {
      mentalStatus: {
        ...formData?.mentalStatus,
      },
      formHeader: {
        ...formData?.formHeader,
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  const updatePhysicalStature = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        physicalStature: {
          ...formData?.mentalStatus?.physicalStature,
          small: field === "small" ? (value as boolean) : false,
          average: field === "average" ? (value as boolean) : false,
          tall: field === "tall" ? (value as boolean) : false,
        },
      },
    }));
  };

  const updateWeight = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        weight: {
          ...formData?.mentalStatus?.weight,
          thin: field === "thin" ? (value as boolean) : false,
          average: field === "average" ? (value as boolean) : false,
          overweight: field === "overweight" ? (value as boolean) : false,
          obese: field === "obese" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateGrooming = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        grooming: {
          ...formData?.mentalStatus?.grooming,
          wellGroomed: field === "wellGroomed" ? (value as boolean) : false,
          normal: field === "normal" ? (value as boolean) : false,
          neglected: field === "neglected" ? (value as boolean) : false,
          bizarre: field === "bizarre" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateClothing = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        clothing: {
          ...formData?.mentalStatus?.clothing,
          neatClean: field === "neatClean" ? (value as boolean) : false,
          inappropriate: field === "inappropriate" ? (value as boolean) : false,
          dirty: field === "dirty" ? (value as boolean) : false,
          seductive: field === "seductive" ? (value as boolean) : false,
          disheveled: field === "disheveled" ? (value as boolean) : false,
          bizarre: field === "bizarre" ? (value as boolean) : false,
        },
      },
    }));
  };

  const updatePosture = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        posture: {
          ...formData?.mentalStatus?.posture,
          normal: field === "normal" ? (value as boolean) : false,
          tense: field === "tense" ? (value as boolean) : false,
          stooped: field === "stooped" ? (value as boolean) : false,
          rigid: field === "rigid" ? (value as boolean) : false,
          slumped: field === "slumped" ? (value as boolean) : false,
          bizarre: field === "bizarre" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateAttitude = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        attitude: {
          ...formData?.mentalStatus?.attitude,
          cooperative: field === "cooperative" ? (value as boolean) : false,
          passive: field === "passive" ? (value as boolean) : false,
          guarded: field === "guarded" ? (value as boolean) : false,
          irritable: field === "irritable" ? (value as boolean) : false,
          manipulative: field === "manipulative" ? (value as boolean) : false,
          seductive: field === "seductive" ? (value as boolean) : false,
          suspicious: field === "suspicious" ? (value as boolean) : false,
          defensive: field === "defensive" ? (value as boolean) : false,
          dramatic: field === "dramatic" ? (value as boolean) : false,
          silly: field === "silly" ? (value as boolean) : false,
          hostile: field === "hostile" ? (value as boolean) : false,
          critical: field === "critical" ? (value as boolean) : false,
          resistant: field === "resistant" ? (value as boolean) : false,
          sarcastic: field === "sarcastic" ? (value as boolean) : false,
          uninterested: field === "uninterested" ? (value as boolean) : false,
          argumentative: field === "argumentative" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateMotor = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        motor: {
          ...formData?.mentalStatus?.motor,
          nonremarkable: field === "nonremarkable" ? (value as boolean) : false,
          tremor: field === "tremor" ? (value as boolean) : false,
          slowed: field === "slowed" ? (value as boolean) : false,
          tics: field === "tics" ? (value as boolean) : false,
          restless: field === "restless" ? (value as boolean) : false,
          agitated: field === "agitated" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateSpeech = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        speech: {
          ...formData?.mentalStatus?.speech,
          normal: field === "normal" ? (value as boolean) : false,
          rapid: field === "rapid" ? (value as boolean) : false,
          slurred: field === "slurred" ? (value as boolean) : false,
          loud: field === "loud" ? (value as boolean) : false,
          paucity: field === "paucity" ? (value as boolean) : false,
          pressured: field === "pressured" ? (value as boolean) : false,
          mute: field === "mute" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateAffect = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        affect: {
          ...formData?.mentalStatus?.affect,
          appropriate: field === "appropriate" ? (value as boolean) : false,
          inappropriate: field === "inappropriate" ? (value as boolean) : false,
          flat: field === "flat" ? (value as boolean) : false,
          restricted: field === "restricted" ? (value as boolean) : false,
          blunted: field === "blunted" ? (value as boolean) : false,
          labile: field === "labile" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateMood = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        mood: {
          ...formData?.mentalStatus?.mood,
          euthymic: field === "euthymic" ? (value as boolean) : false,
          confused: field === "confused" ? (value as boolean) : false,
          pessimistic: field === "pessimistic" ? (value as boolean) : false,
          depressed: field === "depressed" ? (value as boolean) : false,
          anxious: field === "anxious" ? (value as boolean) : false,
          euphoric: field === "euphoric" ? (value as boolean) : false,
          apathetic: field === "apathetic" ? (value as boolean) : false,
          angry: field === "angry" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateThoughtForm = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        thoughtForm: {
          ...formData?.mentalStatus?.thoughtForm,
          goaldirected: field === "goaldirected" ? (value as boolean) : false,
          appropriate: field === "appropriate" ? (value as boolean) : false,
          logical: field === "logical" ? (value as boolean) : false,
          tangentialthinking: field === "tangentialthinking" ? (value as boolean) : false,
          circumstantial: field === "circumstantial" ? (value as boolean) : false,
          looseassociations: field === "looseassociations" ? (value as boolean) : false,
          confused: field === "confused" ? (value as boolean) : false,
          incoherent: field === "incoherent" ? (value as boolean) : false,
          perseverations: field === "perseverations" ? (value as boolean) : false,
          flightofidea: field === "flightofidea" ? (value as boolean) : false,
          slownessofthought: field === "slownessofthought" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateThoughtContent = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        thoughtContent: {
          ...formData?.mentalStatus?.thoughtContent,
          appropriate: field === "appropriate" ? (value as boolean) : false,
          paranoid: field === "paranoid" ? (value as boolean) : false,
          suspicions: field === "suspicions" ? (value as boolean) : false,
          persecutions: field === "persecutions" ? (value as boolean) : false,
          paucity: field === "paucity" ? (value as boolean) : false,
          delusions: field === "delusions" ? (value as boolean) : false,
          bizarre: field === "bizarre" ? (value as boolean) : false,
          hypochondriac: field === "hypochondriac" ? (value as boolean) : false,
          ideasofreference: field === "ideasofreference" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updatePreOccupation = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        preoccupations: {
          ...formData?.mentalStatus?.preoccupations,
          phobias: field === "phobias" ? (value as boolean) : false,
          guilt: field === "guilt" ? (value as boolean) : false,
          other: field === "other" ? (value as boolean) : false,
          somatic: field === "somatic" ? (value as boolean) : false,
          religion: field === "religion" ? (value as boolean) : false,
          suicide: field === "suicide" ? (value as boolean) : false,
          homicidal: field === "homicidal" ? (value as boolean) : false,
        },
      },
    }));
  };
  const updateHallucination = (field: string, value: boolean | string) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        hallucinations: {
          ...formData?.mentalStatus?.hallucinations,
          auditory: field === "auditory" ? (value as boolean) : false,
          other: field === "other" ? (value as boolean) : false,
          visual: field === "visual" ? (value as boolean) : false,
          sensory: field === "sensory" ? (value as boolean) : false,
        },
      },
    }));
  };
  // four before last field of this form is handled by below
  const updateMentalStatusField = (
    category: "levelOfIntellectualFunctioning" | "fundofKnowledge" | "judgment" | "insightIntoProblems",
    field: "belowAverage" | "average" | "aboveAverage",
    value: boolean|string
  ) => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      mentalStatus: {
        ...formData?.mentalStatus,
        [category]: {
          belowAverage: field === "belowAverage" ? (value as boolean) : false,
          average: field === "average" ? (value as boolean) : false,
          aboveAverage: field === "aboveAverage" ? (value as boolean) : false,
        },
      },
    }));
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                MENTAL STATUS:
              </h5>
            </Card>

            <Card className="match-card-body p-3">
              <div style={{ overflowX: "auto" }}>
                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left" }}>
                  <tbody style={{ verticalAlign: "middle" }}>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Physical Stature:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.physicalStature?.small === true}
                              onClick={() => {
                                updatePhysicalStature("small", !formData?.mentalStatus?.physicalStature?.small);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Small
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.physicalStature?.average === true}
                              onClick={() => {
                                updatePhysicalStature("average", !formData?.mentalStatus?.physicalStature?.average);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Average
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.physicalStature?.tall === true}
                              onClick={() => {
                                updatePhysicalStature("tall", !formData?.mentalStatus?.physicalStature?.tall);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Tall
                            </label>
                          </td>
                          <td style={{ width: "20%" }}></td>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Weight:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.weight?.thin === true}
                              onClick={() => {
                                updateWeight("thin", !formData?.mentalStatus?.weight?.thin);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Thin
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.weight?.average === true}
                              onClick={() => {
                                updateWeight("average", !formData?.mentalStatus?.weight?.average);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Average
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.weight?.overweight === true}
                              onClick={() => {
                                updateWeight("overweight", !formData?.mentalStatus?.weight?.overweight);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Overweight
                            </label>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.weight?.obese === true}
                              onClick={() => {
                                updateWeight("obese", !formData?.mentalStatus?.weight?.obese);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Obese
                            </label>
                          </td>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Grooming:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.grooming?.wellGroomed === true}
                              onClick={() => {
                                updateGrooming("wellGroomed", !formData?.mentalStatus?.grooming?.wellGroomed);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Well Groomed
                            </label>
                          </td>
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.grooming?.normal === true}
                              onClick={() => {
                                updateGrooming("normal", !formData?.mentalStatus?.grooming?.normal);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Normal
                            </label>
                          </td>
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.grooming?.neglected === true}
                              onClick={() => {
                                updateGrooming("neglected", !formData?.mentalStatus?.grooming?.neglected);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Neglected
                            </label>
                          </td>
                          <td>
                            <input
                              style={{ fontSize: "larger" }}
                              type="radio"
                              className="form-check-input"
                              disabled={false}
                              checked={formData?.mentalStatus?.grooming?.bizarre === true}
                              onClick={() => {
                                updateGrooming("bizarre", !formData?.mentalStatus?.grooming?.bizarre);
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Bizarre
                            </label>
                          </td>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Clothing:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.clothing?.neatClean === true}
                                  onClick={() => {
                                    updateClothing("neatClean", !formData?.mentalStatus?.clothing?.neatClean);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Neat/Clean
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.clothing?.inappropriate === true}
                                  onClick={() => {
                                    updateClothing("inappropriate", !formData?.mentalStatus?.clothing?.inappropriate);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Inappropriate
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.clothing?.dirty === true}
                                  onClick={() => {
                                    updateClothing("dirty", !formData?.mentalStatus?.clothing?.dirty);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Dirty
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.clothing?.seductive === true}
                                  onClick={() => {
                                    updateClothing("seductive", !formData?.mentalStatus?.clothing?.seductive);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Seductive
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.clothing?.disheveled === true}
                                  onClick={() => {
                                    updateClothing("disheveled", !formData?.mentalStatus?.clothing?.disheveled);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Disheveled
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.clothing?.bizarre === true}
                                  onClick={() => {
                                    updateClothing("bizarre", !formData?.mentalStatus?.clothing?.bizarre);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bizarre
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Posture:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.posture?.normal === true}
                                  onClick={() => {
                                    updatePosture("normal", !formData?.mentalStatus?.posture?.normal);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Normal
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.posture?.tense === true}
                                  onClick={() => {
                                    updatePosture("tense", !formData?.mentalStatus?.posture?.tense);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tense
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.posture?.stooped === true}
                                  onClick={() => {
                                    updatePosture("stooped", !formData?.mentalStatus?.posture?.stooped);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Stooped
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.posture?.rigid === true}
                                  onClick={() => {
                                    updatePosture("rigid", !formData?.mentalStatus?.posture?.rigid);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Rigid
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.posture?.slumped === true}
                                  onClick={() => {
                                    updatePosture("slumped", !formData?.mentalStatus?.posture?.slumped);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slumped
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.posture?.bizarre === true}
                                  onClick={() => {
                                    updatePosture("bizarre", !formData?.mentalStatus?.posture?.bizarre);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bizarre
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Attitude:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.cooperative === true}
                                  onClick={() => {
                                    updateAttitude("cooperative", !formData?.mentalStatus?.attitude?.cooperative);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Cooperative
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.passive === true}
                                  onClick={() => {
                                    updateAttitude("passive", !formData?.mentalStatus?.attitude?.passive);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Passive
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.guarded === true}
                                  onClick={() => {
                                    updateAttitude("guarded", !formData?.mentalStatus?.attitude?.guarded);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Guarded
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.irritable === true}
                                  onClick={() => {
                                    updateAttitude("irritable", !formData?.mentalStatus?.attitude?.irritable);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Irritable
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.manipulative === true}
                                  onClick={() => {
                                    updateAttitude("manipulative", !formData?.mentalStatus?.attitude?.manipulative);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Manipulative
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.seductive === true}
                                  onClick={() => {
                                    updateAttitude("seductive", !formData?.mentalStatus?.attitude?.seductive);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Seductive
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.suspicious === true}
                                  onClick={() => {
                                    updateAttitude("suspicious", !formData?.mentalStatus?.attitude?.suspicious);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Suspicious
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.defensive === true}
                                  onClick={() => {
                                    updateAttitude("defensive", !formData?.mentalStatus?.attitude?.defensive);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Defensive
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.dramatic === true}
                                  onClick={() => {
                                    updateAttitude("dramatic", !formData?.mentalStatus?.attitude?.dramatic);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Dramatic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.silly === true}
                                  onClick={() => {
                                    updateAttitude("silly", !formData?.mentalStatus?.attitude?.silly);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Silly
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.hostile === true}
                                  onClick={() => {
                                    updateAttitude("hostile", !formData?.mentalStatus?.attitude?.hostile);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hostile
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.critical === true}
                                  onClick={() => {
                                    updateAttitude("critical", !formData?.mentalStatus?.attitude?.critical);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Critical
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.resistant === true}
                                  onClick={() => {
                                    updateAttitude("resistant", !formData?.mentalStatus?.attitude?.resistant);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Resistant
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.sarcastic === true}
                                  onClick={() => {
                                    updateAttitude("sarcastic", !formData?.mentalStatus?.attitude?.sarcastic);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Sarcastic
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.uninterested === true}
                                  onClick={() => {
                                    updateAttitude("uninterested", !formData?.mentalStatus?.attitude?.uninterested);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Uninterested
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.attitude?.argumentative === true}
                                  onClick={() => {
                                    updateAttitude("argumentative", !formData?.mentalStatus?.attitude?.argumentative);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Argumentative
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Motor:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.motor?.nonremarkable === true}
                                  onClick={() => {
                                    updateMotor("nonremarkable", !formData?.mentalStatus?.motor?.nonremarkable);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Non-remarkable
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.motor?.tremor === true}
                                  onClick={() => {
                                    updateMotor("tremor", !formData?.mentalStatus?.motor?.tremor);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tremor
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.motor?.slowed === true}
                                  onClick={() => {
                                    updateMotor("slowed", !formData?.mentalStatus?.motor?.slowed);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slowed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.motor?.tics === true}
                                  onClick={() => {
                                    updateMotor("tics", !formData?.mentalStatus?.motor?.tics);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tics
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.motor?.restless === true}
                                  onClick={() => {
                                    updateMotor("restless", !formData?.mentalStatus?.motor?.restless);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Restless
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.motor?.agitated === true}
                                  onClick={() => {
                                    updateMotor("agitated", !formData?.mentalStatus?.motor?.agitated);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Agitated
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Speech:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.normal === true}
                                  onClick={() => {
                                    updateSpeech("normal", !formData?.mentalStatus?.speech?.normal);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Normal
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.rapid === true}
                                  onClick={() => {
                                    updateSpeech("rapid", !formData?.mentalStatus?.speech?.rapid);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Rapid
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.slurred === true}
                                  onClick={() => {
                                    updateSpeech("slurred", !formData?.mentalStatus?.speech?.slurred);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slurred
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.loud === true}
                                  onClick={() => {
                                    updateSpeech("loud", !formData?.mentalStatus?.speech?.loud);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Loud
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.paucity === true}
                                  onClick={() => {
                                    updateSpeech("paucity", !formData?.mentalStatus?.speech?.paucity);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Paucity
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.pressured === true}
                                  onClick={() => {
                                    updateSpeech("pressured", !formData?.mentalStatus?.speech?.pressured);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Pressured
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.speech?.mute === true}
                                  onClick={() => {
                                    updateSpeech("mute", !formData?.mentalStatus?.speech?.mute);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Mute
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Affect:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.affect?.appropriate === true}
                                  onClick={() => {
                                    updateAffect("appropriate", !formData?.mentalStatus?.affect?.appropriate);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Appropriate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.affect?.inappropriate === true}
                                  onClick={() => {
                                    updateAffect("inappropriate", !formData?.mentalStatus?.affect?.inappropriate);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Inappropriate
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.affect?.flat === true}
                                  onClick={() => {
                                    updateAffect("flat", !formData?.mentalStatus?.affect?.flat);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Flat
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.affect?.restricted === true}
                                  onClick={() => {
                                    updateAffect("restricted", !formData?.mentalStatus?.affect?.restricted);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Restricted
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.affect?.blunted === true}
                                  onClick={() => {
                                    updateAffect("blunted", !formData?.mentalStatus?.affect?.blunted);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Blunted
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.affect?.labile === true}
                                  onClick={() => {
                                    updateAffect("labile", !formData?.mentalStatus?.affect?.labile);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Labile
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Mood:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.euthymic === true}
                                  onClick={() => {
                                    updateMood("euthymic", !formData?.mentalStatus?.mood?.euthymic);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Euthymic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.confused === true}
                                  onClick={() => {
                                    updateMood("confused", !formData?.mentalStatus?.mood?.confused);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Confused
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.pessimistic === true}
                                  onClick={() => {
                                    updateMood("pessimistic", !formData?.mentalStatus?.mood?.pessimistic);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Pessimistic
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.depressed === true}
                                  onClick={() => {
                                    updateMood("depressed", !formData?.mentalStatus?.mood?.depressed);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Depressed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.anxious === true}
                                  onClick={() => {
                                    updateMood("anxious", !formData?.mentalStatus?.mood?.anxious);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Anxious
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.euphoric === true}
                                  onClick={() => {
                                    updateMood("euphoric", !formData?.mentalStatus?.mood?.euphoric);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Euphoric
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.apathetic === true}
                                  onClick={() => {
                                    updateMood("apathetic", !formData?.mentalStatus?.mood?.apathetic);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Apathetic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.mood?.angry === true}
                                  onClick={() => {
                                    updateMood("angry", !formData?.mentalStatus?.mood?.angry);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Angry
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Thought Form:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.goaldirected === true}
                                  onClick={() => {
                                    updateThoughtForm("goaldirected", !formData?.mentalStatus?.thoughtForm?.goaldirected);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Goal directed
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.appropriate === true}
                                  onClick={() => {
                                    updateThoughtForm("appropriate", !formData?.mentalStatus?.thoughtForm?.appropriate);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Appropriate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.logical === true}
                                  onClick={() => {
                                    updateThoughtForm("logical", !formData?.mentalStatus?.thoughtForm?.logical);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Logical
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.tangentialthinking === true}
                                  onClick={() => {
                                    updateThoughtForm("tangentialthinking", !formData?.mentalStatus?.thoughtForm?.tangentialthinking);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Tangential thinking
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.circumstantial === true}
                                  onClick={() => {
                                    updateThoughtForm("circumstantial", !formData?.mentalStatus?.thoughtForm?.circumstantial);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Circumstantial
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.looseassociations === true}
                                  onClick={() => {
                                    updateThoughtForm("looseassociations", !formData?.mentalStatus?.thoughtForm?.looseassociations);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Loose associations
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.confused === true}
                                  onClick={() => {
                                    updateThoughtForm("confused", !formData?.mentalStatus?.thoughtForm?.confused);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Confused
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.incoherent === true}
                                  onClick={() => {
                                    updateThoughtForm("incoherent", !formData?.mentalStatus?.thoughtForm?.incoherent);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Incoherent
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.perseverations === true}
                                  onClick={() => {
                                    updateThoughtForm("perseverations", !formData?.mentalStatus?.thoughtForm?.perseverations);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Perseverations
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.flightofidea === true}
                                  onClick={() => {
                                    updateThoughtForm("flightofidea", !formData?.mentalStatus?.thoughtForm?.flightofidea);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Flight of idea
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtForm?.slownessofthought === true}
                                  onClick={() => {
                                    updateThoughtForm("slownessofthought", !formData?.mentalStatus?.thoughtForm?.slownessofthought);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Slowness of thought association
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Thought Content:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.appropriate === true}
                                  onClick={() => {
                                    updateThoughtContent("appropriate", !formData?.mentalStatus?.thoughtContent?.appropriate);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Appropriate
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.paranoid === true}
                                  onClick={() => {
                                    updateThoughtContent("paranoid", !formData?.mentalStatus?.thoughtContent?.paranoid);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Paranoid
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.suspicions === true}
                                  onClick={() => {
                                    updateThoughtContent("suspicions", !formData?.mentalStatus?.thoughtContent?.suspicions);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Suspicions
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.persecutions === true}
                                  onClick={() => {
                                    updateThoughtContent("persecutions", !formData?.mentalStatus?.thoughtContent?.persecutions);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Persecutions
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.paucity === true}
                                  onClick={() => {
                                    updateThoughtContent("paucity", !formData?.mentalStatus?.thoughtContent?.paucity);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Paucity
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.delusions === true}
                                  onClick={() => {
                                    updateThoughtContent("delusions", !formData?.mentalStatus?.thoughtContent?.delusions);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Delusions
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.bizarre === true}
                                  onClick={() => {
                                    updateThoughtContent("bizarre", !formData?.mentalStatus?.thoughtContent?.bizarre);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Bizarre
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.hypochondriac === true}
                                  onClick={() => {
                                    updateThoughtContent("hypochondriac", !formData?.mentalStatus?.thoughtContent?.hypochondriac);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Hypochondriac
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.thoughtContent?.ideasofreference === true}
                                  onClick={() => {
                                    updateThoughtContent("ideasofreference", !formData?.mentalStatus?.thoughtContent?.ideasofreference);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Ideas of reference
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Preoccupations:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.phobias === true}
                                  onClick={() => {
                                    updatePreOccupation("phobias", !formData?.mentalStatus?.preoccupations?.phobias);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Phobias
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.guilt === true}
                                  onClick={() => {
                                    updatePreOccupation("guilt", !formData?.mentalStatus?.preoccupations?.guilt);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Guilt
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.other === true}
                                  onClick={() => {
                                    updatePreOccupation("other", !formData?.mentalStatus?.preoccupations?.other);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Other
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.somatic === true}
                                  onClick={() => {
                                    updatePreOccupation("somatic", !formData?.mentalStatus?.preoccupations?.somatic);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Somatic
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.religion === true}
                                  onClick={() => {
                                    updatePreOccupation("religion", !formData?.mentalStatus?.preoccupations?.religion);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Religion
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.suicide === true}
                                  onClick={() => {
                                    updatePreOccupation("suicide", !formData?.mentalStatus?.preoccupations?.suicide);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Suicide
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.preoccupations?.homicidal === true}
                                  onClick={() => {
                                    updatePreOccupation("homicidal", !formData?.mentalStatus?.preoccupations?.homicidal);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Homicidal
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Hallucinations:
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.hallucinations?.auditory === true}
                                  onClick={() => {
                                    updateHallucination("auditory", !formData?.mentalStatus?.hallucinations?.auditory);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Auditory
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.hallucinations?.other === true}
                                  onClick={() => {
                                    updateHallucination("other", !formData?.mentalStatus?.hallucinations?.other);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Other:
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={4} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.hallucinations?.visual === true}
                                  onClick={() => {
                                    updateHallucination("visual", !formData?.mentalStatus?.hallucinations?.visual);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Visual
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={3} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.hallucinations?.sensory === true}
                                  onClick={() => {
                                    updateHallucination("sensory", !formData?.mentalStatus?.hallucinations?.sensory);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Sensory
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Orientation:
                      </td>

                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td colSpan={2} style={{ width: "25.18%" }}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Person
                                </label>
                              </td>

                              <td colSpan={5} style={{ width: "25.45%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.person === true}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.person === true;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          person: result ? null : true,
                                          // place: false,
                                          // time: false,
                                          // situation: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4} style={{ width: "30.36%" }}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.person === false}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.person === false;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          person: result ? null : false,
                                          // place: false,
                                          // time: false,
                                          // situation: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Place
                                </label>
                              </td>

                              <td colSpan={5}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.place === true}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.place === true;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          place: result ? null : true,
                                          // person: false,
                                          // time: false,
                                          // situation: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.place === false}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.place === false;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          place: result ? null : false,
                                          // person: false,
                                          // time: false,
                                          // situation: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Time
                                </label>
                              </td>

                              <td colSpan={5}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.time === true}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.time === true;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          time: result ? null : true,
                                          // person: false,
                                          // place: false,
                                          // situation: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.time === false}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.time === false;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          time: result ? null : false,
                                          // person: false,
                                          // place: false,
                                          // situation: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Situation
                                </label>
                              </td>

                              <td colSpan={5}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.situation === true}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.situation === true;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          situation: result ? null : true,
                                          // person: false,
                                          // place: false,
                                          // time: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Yes
                                </label>
                              </td>

                              <td colSpan={4}>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.orientation?.situation === false}
                                  onClick={e => {
                                    const result = formData?.mentalStatus?.orientation?.situation === false;
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      mentalStatus: {
                                        ...formData?.mentalStatus,
                                        orientation: {
                                          ...formData?.mentalStatus?.orientation,
                                          situation: result ? null : false,
                                          // person: false,
                                          // place: false,
                                          // time: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  No
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Level of intellectual functioning
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.levelOfIntellectualFunctioning?.belowAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField(
                                      "levelOfIntellectualFunctioning",
                                      "belowAverage",
                                      !formData?.mentalStatus?.levelOfIntellectualFunctioning?.belowAverage
                                    );
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.levelOfIntellectualFunctioning?.average === true}
                                  onClick={() => {
                                    updateMentalStatusField(
                                      "levelOfIntellectualFunctioning",
                                      "average",
                                      !formData?.mentalStatus?.levelOfIntellectualFunctioning?.average
                                    );
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.levelOfIntellectualFunctioning?.aboveAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField(
                                      "levelOfIntellectualFunctioning",
                                      "aboveAverage",
                                      !formData?.mentalStatus?.levelOfIntellectualFunctioning?.aboveAverage
                                    );
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Fund of Knowledge:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.fundofKnowledge?.belowAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField("fundofKnowledge", "belowAverage", !formData?.mentalStatus?.fundofKnowledge?.belowAverage);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.fundofKnowledge?.average === true}
                                  onClick={() => {
                                    updateMentalStatusField("fundofKnowledge", "average", !formData?.mentalStatus?.fundofKnowledge?.average);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.fundofKnowledge?.aboveAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField("fundofKnowledge", "aboveAverage", !formData?.mentalStatus?.fundofKnowledge?.aboveAverage);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Judgment:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.judgment?.belowAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField("judgment", "belowAverage", !formData?.mentalStatus?.judgment?.belowAverage);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.judgment?.average === true}
                                  onClick={() => {
                                    updateMentalStatusField("judgment", "average", !formData?.mentalStatus?.judgment?.average);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.judgment?.aboveAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField("judgment", "aboveAverage", !formData?.mentalStatus?.judgment?.aboveAverage);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-500" colSpan={1} style={{ padding: "0.2rem", fontWeight: "bold" }}>
                        Insight into problems:
                      </td>
                      <td colSpan={11} style={{ padding: "0.2rem" }}>
                        <Table className="tableTwo">
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.insightIntoProblems?.belowAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField("insightIntoProblems", "belowAverage", !formData?.mentalStatus?.insightIntoProblems?.belowAverage);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Below Average
                                </label>
                              </td>

                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.insightIntoProblems?.average === true}
                                  onClick={() => {
                                    updateMentalStatusField("insightIntoProblems", "average", !formData?.mentalStatus?.insightIntoProblems?.average);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Average
                                </label>
                              </td>
                              <td>
                                <input
                                  style={{ fontSize: "larger" }}
                                  type="radio"
                                  className="form-check-input"
                                  disabled={false}
                                  checked={formData?.mentalStatus?.insightIntoProblems?.aboveAverage === true}
                                  onClick={() => {
                                    updateMentalStatusField("insightIntoProblems", "aboveAverage", !formData?.mentalStatus?.insightIntoProblems?.aboveAverage);
                                  }}
                                />
                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                  Above Average
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <h5 className="fw-500" style={{ margin: "auto" }}>
                CLINICAL IMPRESSION/INTERPRETIVE SUMMARY:
              </h5>
            </Card>
            <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
              <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                <CKEditor
                  editor={Editor}
                  data={formData?.mentalStatus?.clinicalImpressionSummary || ""}
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      mentalStatus: {
                        ...formData?.mentalStatus,
                        clinicalImpressionSummary: data,
                      },
                    }));
                  }}
                />
              </div>
            </Card>
          </div>
        </Row>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                // updateAssesmentDetails();
                // const updateDetails = {
                //   mentalStatus: {
                //     ...formData?.mentalStatus,
                //   },
                //   formHeader: {
                //     ...formData?.formHeader,
                //   },
                // };
                // handleBulkFormDataUpdate(updateDetails);
                // nextStep();
                handleUpdateData();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 10 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormTen;
