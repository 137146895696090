import { Client } from "./Client";
import { ExperienceTag } from "./ExperienceTag";
import { License } from "./License";
import { Payment } from "./Payment";
import { Profession, ProfessionLicense } from "./Profession";
import { Qualification } from "./Qualification";
import { ReviewData } from "./Review";
import { Upload } from "./Upload";
import { User } from "./User";
import { WorkingHour } from "./WorkingHour";

export interface Therapist extends User {
  claimOpen?: any;
  therapyState: string[];
  payRateType: any;
  therapist?: any;
  name: string;
  _id: string;
  ethnicityId?: any;
  gender: string;
  description: string;
  dateOfBirth: Date;
  licenseId?: License[];
  qualifications: Qualification[];
  profession?: Profession;
  professionLicense?: ProfessionLicense & string;
  disclosureStatementId?: Upload;
  dislikedClients?: Client[];
  experiencedIn?: ExperienceTag[];
  loginVerification: string;
  workingHours?: WorkingHour[];
  coverPhotoId?: Upload;
  isAvailable?: boolean;
  yearsOfExperience?: number;
  reviews?: ReviewData[];
  payment?: Payment[];
  vimeoId?: string;
  aiGenerateCount?: number;
  aiReviewSubmitted?: boolean;
  blockedDates?: BlockedDate[];
  stateList?: string[];
  blackTherapyPriorityNumber?: number;
  mensMentalTherapyPriorityNumber?: number;
  relationshipTherapyPriorityNumber?: number;
  signature?: any;
  grantedAccessFileFolderPermission?: boolean;
  fullName?: string;
}

export interface DiscoverTherapist {
  yearsOfExperienceMin?: number;
  yearsOfExperienceMax?: number;
  gender?: string;
  insuranceCompanies?: string[];
  profession?: string;
  ethnicity?: string;
  experiencedIn?: string[];
  searchTherapistName?: string;
  zipCode?: string;
  state?: string;
}

export interface SearchTherapistName {
  name: string;
}

export interface PriorityNumber {
  priorityNumber?: number;
  blackTherapyPriorityNumber?: number;
  mensMentalTherapyPriorityNumber?: number;
  userId?: string;
  relationshipTherapyPriorityNumber?: number;
}

export interface AppointmentsCount {
  completedAppointments: string;
  pendingAppointments: string;
}

export interface BlockedDate {
  title: string;
  start: Date;
  end: Date;
  display: string;
  className: string;
  extendedProps: any;
}

export enum TherapistGrantedStatus {
  GRANTED = "Granted",
  NOT_GRANTED = "Not Granted",
  ALL = "All",
}

export enum AINotesType {
  SOAP = "SOAP",
  PIE = "PIE",
}
