import React, { useContext, useState, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledTooltip } from "reactstrap";
import Call from "../../assets/images/icons/u_phone-volume.png";
import Video from "../../assets/images/icons/fi_video.png";
import arrowIcon from "../../assets/images/icons/fi_arrow-right-circle.svg";
import { Appointment, AppointmentStatus, AppointmentType, ApprovalStatus, Repeat } from "src/models/Appointment";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import NoUpcommingIcon from "../../assets/images/icons/no_upcomming.svg";
import images from "../../assets/images";
import { Role } from "src/models/Role";
import UserContext from "src/context/UserContext";
import LocalCallContext from "src/context/LocalCallContext";
import { CallInitializeData } from "src/models/CallInitializeData";
import { toast } from "react-toastify";
import CallInitializationContext from "src/context/CallInitializationContext";
import AvatarModeEnableModal from "../Popup/AvatarModeEnableModal";
import { Util } from "src/Util";
import { PremiumStatus } from "src/models/Client";
import Modal18 from "../Popup/Modal18";
import Modal17 from "../Popup/Modal17";
import Modal13 from "../Popup/Modal13";
import Modal12 from "../Popup/Modal12";
import Modal11 from "../Popup/Modal11";
import MessageModal from "../Popup/MessageModal";
import Modal4 from "../Popup/Modal4";
import Modal22 from "../Popup/Modal22";
import Modal5 from "../Popup/Modal5";
import AllowTranscribeModal from "../Popup/AllowTranscribeModal";
import SkeltonUpcommingAppointment from "src/common/skeleton/SkeltonUpcommingAppointment";
import { v4 as uuidv4 } from "uuid";
import OngoingCallRefreshModal from "../Popup/OngoingCallRefreshModal";
import { VonageServices } from "../Chat/vonage/vonageServices";
import Modal29 from "src/pages/Popup/Modal29";
import Modal28 from "src/pages/Popup/Modal28";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { environment } from "src/environment/environment";
import { ClientService } from "src/services/ClientService";
import Swal from "sweetalert2";

const stripePromise = loadStripe(environment.stripe_publishable_key);
interface upcomingAppointmentsProps {
  upcomingAppointments: Appointment[];
  isLoading: boolean;
}
const UpcomingAppointmentsNew: React.FC<upcomingAppointmentsProps> = ({ upcomingAppointments, isLoading }) => {
  const [user, setUser] = useContext(UserContext);
  const [isRight, setIsRight] = useState(false);
  const [isAppointment, setIsAppointment] = useState<Appointment>({} as Appointment);
  const [loggedUser] = useContext(UserContext);
  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [showModal, setShowModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showTooEarly, setShowTooEarly] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [showAvatarModeEnablePopup, setShowAvatarModeEnablePopup] = useState(false);
  const [allowTranscribeModalShow, setAllowTranscribeModalShow] = useState<boolean>(false);
  const [allowTranscribeForUser, setAllowTranscribeForUser] = useState<boolean>(false);
  const [showOngoingCallRefreshModal, setShowOngoingCallRefreshModal] = useState(false);
  const [showImcompleteCoPayments, setShowIncompleteCopayments] = useState(false);
  const [showPayImcompleteCoPayments, setShowPayIncompleteCopayments] = useState(false);
  const [coPaymentAmount, setCopaymentAmount] = useState(0);
  const [showMeetingData, setMeetingData] = useState({ startNew: true, userId: "", meetingTime: "" as any, appointmentId: "", meetingId: "", isAppointmentBased: true, callType: "", });
  const [clientIdForSchedule, setClientIdForSchedule] = useState("");
  useEffect(() => {
    if (user?.incognitoPopupShow == false) {
      setShowAvatarModeEnablePopup(true);

    }
  }, []);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const history = useHistory();

  const setAppointment = (appointment: Appointment) => {
    setIsAppointment(appointment);
  };

  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);


  const startMeetingNew2 = async (userId: any, isVideoCall: boolean, appointmentId: string | undefined, startTime: Date, endTime: Date, status?: string, approvedStatus?: string
  ) => {
    console.log("approvedStatus ", approvedStatus);

    if (loggedUser?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN) {
      if (userId == "") {
        alert("No user id is provided.");
      } else if (localCallInitialize) {
        setShowOngoingCallRefreshModal(true);
      } else {
        const now = moment(new Date());
        const timeDifferenceWithNow = moment.duration(moment(startTime).diff(now));
        const timeDifferenceWithNowAsMinutes = timeDifferenceWithNow.asMinutes();

        const timeDifferenceWithStartAndEnd = moment.duration(moment(endTime).diff(moment(startTime)));
        const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();

        if (approvedStatus != ApprovalStatus.APPROVED) {
          setShowNotApproved(true);
        } else if (status != AppointmentStatus.PENDING) {
          setShowAlreadyCompleted(true);
        } else if (timeDifferenceWithNowAsMinutes > 31) {
          if (loggedUser?.role == "CLIENT") {
            const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(loggedUser?._id);
            if (meetingRes.success) {
              let totalAmount = 0;
              for (const meeting of meetingRes.data) {
                const amount = parseFloat(meeting?.copayment?.amount) || 0;
                totalAmount += amount;
              }
              if (totalAmount > 0) {
                setCopaymentAmount(totalAmount)
                setShowPayIncompleteCopayments(true)
              } else {
                setShowTooEarly(true);
              }
            }
          } else {
            const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
            startZoomCall(true, userId, finalMeetingTime, appointmentId, "");
          }
        } else if (timeDifferenceWithNowAsMinutes < -1441) {
          setShowExpired(true);
        } else {
          const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
          startZoomCall(true, userId, finalMeetingTime, appointmentId, "");
        }
      }
    }
  };

  const startZoomCall = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string) => {
    if (user?.role == "CLIENT") {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setShowPayIncompleteCopayments(true)
          setClientIdForSchedule(userId)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, callType: "START" })
        } else {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek. success){
            if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: appointmentId,
                recieverId: userId,
                meetingTime: meetingTime,
                isTranscribeAllowed: true,
                meetingId: meetingId,
                isAudioCall: false,
              };
              // setLocalCallInitialize(true);
              setLocalCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }
        }
      }
    } else {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(userId);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setClientIdForSchedule(userId)
          setShowIncompleteCopayments(true)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, callType: "START" })
          const meetingRes: any = await ClientService.sendSMSAndEmailForClient(userId, totalAmount);
        } else {
          const callInitializeData: CallInitializeData = {
            start: startNew,
            isInstantMeeting: false,
            isAppointmentBased: true,
            appointmentId: appointmentId,
            recieverId: userId,
            meetingTime: meetingTime,
            isTranscribeAllowed: true,
            meetingId: meetingId,
            isAudioCall: false,
          };
          // setLocalCallInitialize(true);
          setLocalCallInitializeData(callInitializeData);
          setOutGoinCallTimeOutFunction();
          const roomNamePartOne = user?._id;
          const roomNamePartTwo = userId;
          const uniqueId = uuidv4();
          const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
          const vonageUrl = `/room/${roomName}`;
          history.push(vonageUrl);
          // history.push("/meeting_screen");
        }
      }
    }
  };


  const setPaidCoAmount = async (paidAmount: any) => {
    if (paidAmount > 0) {
      await ClientService.payAllPendingCopaymentAmounts().then(async res => {
        if (res.success) {
          setShowPayIncompleteCopayments(false)
          if (user?.role == "CLIENT") {
            if (showMeetingData?.callType == "START") {
              const todayDate = moment().format('YYYY-MM-DD');
              const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(showMeetingData?.userId, todayDate, user._id)
              if (checkClientHasCompletedSessionsOnThisWeek. success){
                if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
                  toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                      position: toast.POSITION.TOP_RIGHT,
                      className: "foo-bar",
                  });
                } else {
                  const callInitializeData: CallInitializeData = {
                    start: showMeetingData?.startNew,
                    isInstantMeeting: false,
                    isAppointmentBased: true,
                    appointmentId: showMeetingData?.appointmentId,
                    recieverId: showMeetingData?.userId,
                    meetingTime: showMeetingData?.meetingTime,
                    isTranscribeAllowed: true,
                    meetingId: showMeetingData?.meetingId,
                    isAudioCall: false,
                  };
                  // setLocalCallInitialize(true);
                  setLocalCallInitializeData(callInitializeData);
                  setOutGoinCallTimeOutFunction();
                  const roomNamePartOne = user?._id;
                  const roomNamePartTwo = showMeetingData?.userId;
                  const uniqueId = uuidv4();
                  const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                  const vonageUrl = `/room/${roomName}`;
                  history.push(vonageUrl);
                }
              }
            }
            if (showMeetingData?.callType == "JOIN") {
              const meetingRes: any = await VonageServices.getMeetingByMeetingId(showMeetingData?.meetingId);
              if (meetingRes.success) {
                const vonageSessionName = meetingRes.data.vonageSessionName;
                const callInitializeData: CallInitializeData = {
                  start: showMeetingData?.startNew,
                  isInstantMeeting: false,
                  isAppointmentBased: true,
                  appointmentId: showMeetingData?.appointmentId,
                  recieverId: showMeetingData?.userId,
                  meetingTime: showMeetingData?.meetingTime,
                  isTranscribeAllowed: true,
                  meetingId: showMeetingData?.meetingId,
                  isAudioCall: false,
                };
                // setLocalCallInitialize(true);
                setLocalCallInitializeData(callInitializeData);
                setOutGoinCallTimeOutFunction();
                const roomNamePartOne = user?._id;
                const roomNamePartTwo = showMeetingData?.userId;
                const uniqueId = uuidv4();
                const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                const vonageUrl = `/room/${roomName}`;
                history.push(vonageUrl);
              }
            }
          } else {
            toast.error(`Already On Progress`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          toast.error(`Something went wrong. Please try again later.`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      })
    }
  }

  const setAfterPaidCoAmount = async (paidAmount: any) => {
    if (paidAmount > 0) {
      setShowPayIncompleteCopayments(false)
      if (user?.role == "CLIENT") {
        if (showMeetingData?.callType == "START") {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(showMeetingData?.userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek. success){
             if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: showMeetingData?.startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: showMeetingData?.appointmentId,
                recieverId: showMeetingData?.userId,
                meetingTime: showMeetingData?.meetingTime,
                isTranscribeAllowed: true,
                meetingId: showMeetingData?.meetingId,
                isAudioCall: false,
              };
              // setLocalCallInitialize(true);
              setLocalCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = showMeetingData?.userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }
        }
        if (showMeetingData?.callType == "JOIN") {
          const meetingRes: any = await VonageServices.getMeetingByMeetingId(showMeetingData?.meetingId);
          if (meetingRes.success) {
            const vonageSessionName = meetingRes.data.vonageSessionName;
            const callInitializeData: CallInitializeData = {
              start: showMeetingData?.startNew,
              isInstantMeeting: false,
              isAppointmentBased: true,
              appointmentId: showMeetingData?.appointmentId,
              recieverId: showMeetingData?.userId,
              meetingTime: showMeetingData?.meetingTime,
              isTranscribeAllowed: true,
              meetingId: showMeetingData?.meetingId,
              isAudioCall: false,
            };
            // setLocalCallInitialize(true);
            setLocalCallInitializeData(callInitializeData);
            setOutGoinCallTimeOutFunction();
            const roomNamePartOne = user?._id;
            const roomNamePartTwo = showMeetingData?.userId;
            const uniqueId = uuidv4();
            const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
            const vonageUrl = `/room/${roomName}`;
            history.push(vonageUrl);
          }
        }
      } else {
        toast.error(`Already On Progress`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };

  const setJoinCall = () => {
    setShowIncompleteCopayments(false)
    startZoomCallCopayment(true, showMeetingData?.userId, showMeetingData?.meetingTime, showMeetingData?.appointmentId, "");
  }
  const startZoomCallCopayment = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string) => {
    if (user?.role == "CLIENT") {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setShowPayIncompleteCopayments(true)
          setClientIdForSchedule(userId)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, callType: "START" })
        } else {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek. success){
            if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: appointmentId,
                recieverId: userId,
                meetingTime: meetingTime,
                isTranscribeAllowed: true,
                meetingId: meetingId,
                isAudioCall: false,
              };
              // setLocalCallInitialize(true);
              setLocalCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }
        }
      }
    } else {
      const callInitializeData: CallInitializeData = {
        start: startNew,
        isInstantMeeting: false,
        isAppointmentBased: true,
        appointmentId: appointmentId,
        recieverId: userId,
        meetingTime: meetingTime,
        isTranscribeAllowed: true,
        meetingId: meetingId,
        isAudioCall: false,
      };
      // setLocalCallInitialize(true);
      setLocalCallInitializeData(callInitializeData);
      setOutGoinCallTimeOutFunction();
      const roomNamePartOne = user?._id;
      const roomNamePartTwo = userId;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room/${roomName}`;
      history.push(vonageUrl);
      // history.push("/meeting_screen");
    }
  };


  const setShowOngoingCallRefreshModalFunc = (showModal: boolean) => {
    setShowOngoingCallRefreshModal(showModal);
  };

  const handleStartMeeting = (session: Appointment) => {
    const todayDate = moment().startOf("day");
    const userType = user?.role === Role.CLIENT ? "Therapist" : "Client";
  
    const olderSession = upcomingAppointments
      ?.filter((appt) => 
        moment(appt.start).isSameOrAfter(todayDate) &&
        moment(appt.start).isBefore(session.start) &&
        moment(appt.start).format("YYYY-MM-DD") !== moment(session.start).format("YYYY-MM-DD") &&
        (appt.therapistId._id === session.therapistId._id || appt.clientId._id === session.clientId._id)
      )
      .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())[0];
  
    if (olderSession) {
      Swal.fire({
        icon: "warning",
        title: "Incorrect Session Date",
        html: `You are about to start the session scheduled for <b>${moment(session.start).format(
          "dddd, MMMM D, YYYY"
        )}</b>, but you have an earlier scheduled session on <b>${moment(olderSession.start).format(
          "dddd, MMMM D, YYYY"
        )}</b> with this ${userType}. Are you sure you want to proceed?`,
        showCancelButton: true,
        confirmButtonText: "Proceed Anyway",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          startMeeting(session);
        }
      });
  
      return;
    }
  
    startMeeting(session);
  };

  const startMeeting = (session: Appointment) => {
    user?.role === Role.CLIENT
      ? startMeetingNew2(
          session.therapistId._id,
          true,
          session._id,
          session.start,
          session.end,
          session.status,
          session.approvedStatus
        )
      : startMeetingNew2(
          session.clientId._id,
          true,
          session._id,
          session.start,
          session.end,
          session.status,
          session.approvedStatus
        );
  };

  return (
    <React.Fragment>
      {showOngoingCallRefreshModal && <OngoingCallRefreshModal showModal={showOngoingCallRefreshModal} setShowModal={setShowOngoingCallRefreshModalFunc} />}
      {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
      {showNotApproved && <Modal17 setShowModal={setShowNotApproved} showModal={showNotApproved} />}
      {showAlreadyCompleted && <Modal13 setShowModal={setShowAlreadyCompleted} showModal={showAlreadyCompleted} />}
      {showExpired && <Modal12 setShowModal={setShowExpired} showModal={showExpired} />}
      {showTooEarly && <Modal11 setShowModal={setShowTooEarly} showModal={showTooEarly} />}
      {showModal && <MessageModal setShowModal={setShowModal} showModal={showModal} message={"Please subscribe to a provided plan to access this feature."} />}
      {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
      {showModalForTherapist && !user?.subscriptionId && !user?.insuranceId && user?.premiumStatus != PremiumStatus.ACTIVE && (
        <Modal22 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />
      )}
      {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}
      <Elements stripe={stripePromise}>
        {showPayImcompleteCoPayments && <Modal28 setShowModal={setShowPayIncompleteCopayments} showModal={showPayImcompleteCoPayments} copaymentAmount={coPaymentAmount} setPayAmount={setPaidCoAmount} setAfterPayCoAmount={setAfterPaidCoAmount} />}
      </Elements>
      {showImcompleteCoPayments && <Modal29 setShowModal={setShowIncompleteCopayments} showModal={showImcompleteCoPayments} copaymentAmount={coPaymentAmount} clientId={clientIdForSchedule} setCallModal={setJoinCall} />}

      <Card className="p-2">
        <CardBody className="p-7-dashboard">
          <h5 className="mb-0 fs-14 fc-black mb-2">Upcoming Appointments</h5>

          {upcomingAppointments?.map((upcomingAppointments: Appointment, index: number) => (
            <Card
              key={index}
              className="appointment-card1 cursor-pointer"
              onClick={() => {
                toggleRightCanvas();
                setAppointment(upcomingAppointments);
              }}
            >
              <CardBody className="p-10-dashboard">
                <div className="d-flex justify-content-between  row">
                  <div className="flex col-6">
                    <span className="d-none d-sm-flex justify-content-center align-items-center">
                      <img className="icon-h-24" src={arrowIcon} />
                    </span>
                    <div className="d-flex justify-content-center align-items-center min-width-100">
                      <p className="mb-0 d-flex justify-content-center align-items-center ms-1 fc-black">
                        {upcomingAppointments.clientId?._id !== user?._id
                          ? upcomingAppointments.clientId?.firstname + " " + upcomingAppointments.clientId?.lastname
                          : upcomingAppointments.therapistId?.firstname + " " + upcomingAppointments.therapistId?.lastname}
                      </p>
                    </div>
                  </div>
                  <div className="left-border-discover col-6 d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center min-width-100">
                      <p className="mb-0 d-flex justify-content-center align-items-center ms-1 fc-black">
                        {`${moment(upcomingAppointments.start).format("hh:mm A")}`} - {`${moment(upcomingAppointments.start).format("YYYY-MM-DD")}`}
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}

          {upcomingAppointments?.length == 0 && !isLoading && (
            <div className="">
              <div className="container no-upcoming-card d-flex justify-content-center align-items-center">
                <div className="overlay-upcoming"></div>
                <img className="image-upcoming" src={NoUpcommingIcon} alt="Overlay Image" />
                <div className="text-upcoming">No Upcoming Appointments</div>
              </div>
            </div>
          )}
          {isLoading && <SkeltonUpcommingAppointment />}
          {upcomingAppointments?.length == 3 && (
            <div className="d-flex justify-content-end mt-4">
              <Link to="/appointments">
                <button className="btn btn-primary btn-sm">
                  View More <i className="bx bx-right-arrow-alt ms-2"></i>
                </button>
              </Link>
            </div>
          )}
          <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas} className={"appointment-sidebar"}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
              <i className="fa fa-circle" style={{ color: isAppointment?.color }}></i> {isAppointment?.title}
            </OffcanvasHeader>

            <OffcanvasBody>
              {loggedUser && loggedUser.role == Role.CLIENT && (
                <div className="row mt-4">
                  {showAvatarModeEnablePopup && (
                    <AvatarModeEnableModal
                      loggedUser={user}
                      setLoggedUser={setUser}
                      showModal={showAvatarModeEnablePopup}
                      setShowModal={setShowAvatarModeEnablePopup}
                    />
                  )}
                  <div className="col-lg-6">
                    <div className="profile-top-dash">
                      {isAppointment.therapistId?.photoId ? (
                        <div
                          className="profile-img1 bg-gray-400 rounded-sm imageFit  mb-3"
                          style={{
                            backgroundImage: `url("${Util.fileURL(isAppointment.therapistId?.photoId?._id)}")`,
                            height: "100px",
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        ></div>
                      ) : (
                        <div className="img-fluid mb-4 imageProfile1">
                          <span className={"big-profile-pic bg_f3d9c0"}>{isAppointment.therapistId?.firstname?.charAt(0)}</span>
                        </div>
                      )}

                      <div className="user-info-d mb-4">
                        <h4>
                          {isAppointment.therapistId?.firstname} {isAppointment.therapistId?.lastname}
                        </h4>
                        {isAppointment.therapistId?.workingHours?.length ? (
                          <div className="available-day flx">
                            <>
                              <i className="bx bxs-calendar-event" id="cale">
                                <UncontrolledTooltip placement="top" target={"cale"}>
                                  Available Days
                                </UncontrolledTooltip>
                              </i>

                              {isAppointment.therapistId?.workingHours?.map((obj: any, i: number) => {
                                return (
                                  <span className="day-tag" key={i} id={"day" + i}>
                                    {obj.day.charAt(0)}
                                    <UncontrolledTooltip placement="top" target={"day" + i}>
                                      {obj.day}
                                    </UncontrolledTooltip>
                                  </span>
                                );
                              })}
                            </>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 profile-top-d">
                    <div className=" left-sid-col">
                      <p id="cale-d">
                        <i className="bx bxs-calendar" /> {moment(isAppointment.start).format("dddd")}, {moment(isAppointment.start).format("MMM Do")}
                      </p>
                      <p id="cale-d">
                        <i className="bx bxs-time" /> {moment(isAppointment.start).format("LT")} - {moment(isAppointment.end).format("LT")}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {loggedUser && loggedUser.role == Role.THERAPIST && (
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <div className="profile-top-dash">
                      {isAppointment.createdBy?.photoId ? (
                        <div
                          className="profile-img1 bg-gray-400 rounded-sm imageFit  mb-3"
                          style={{
                            backgroundImage: `url("${Util.fileURL(isAppointment.createdBy?.photoId?._id)}")`,
                            height: "100px",
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        ></div>
                      ) : (
                        <div className="img-fluid mb-4 imageProfile1">
                          <span className={"big-profile-pic bg_f3d9c0"}>{isAppointment.createdBy?.firstname?.charAt(0)}</span>
                        </div>
                      )}

                      <div className="user-info">
                        <h4>
                          {isAppointment.createdBy?.firstname} {isAppointment.createdBy?.lastname}
                        </h4>
                        {isAppointment.therapistId?.workingHours?.length && (
                          <div className="available-day flx">
                            <p>
                              Status: <span className="subscription_status">{isAppointment.createdBy?.verifiedStatus}</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 profile-top-d">
                    <div className=" left-sid-col">
                      <p id="cale-d">
                        <i className="bx bxs-calendar" /> {moment(isAppointment.start).format("dddd")}, {moment(isAppointment.start).format("MMM Do")}
                      </p>
                      <p id="cale-d">
                        <i className="bx bxs-time" /> {moment(isAppointment.start).format("LT")} - {moment(isAppointment.end).format("LT")}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="horiz-line mt-4 mb-4"></div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <Label className="form-label">Title</Label>
                  <h5>{isAppointment?.title}</h5>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <Label className="form-label">Session Type</Label>
                  <h5>
                    {isAppointment.typeOfMeeting == AppointmentType.TEXT && <i className="bx bxs-message-rounded"></i>}
                    {isAppointment.typeOfMeeting == AppointmentType.VIDEO && <i className="bx bxs-video"></i>}
                    {isAppointment.typeOfMeeting == AppointmentType.VOICE && <i className="bx bxs-phone-call"></i>}
                    &nbsp; {isAppointment?.typeOfMeeting}
                  </h5>
                </div>
                <div className="col-md-6">
                  <Label className="form-label">Repeat</Label>
                  <h5>
                    {isAppointment.repeatInfo?.repeatType && isAppointment.repeatInfo?.repeatType == Repeat.DOES_NOT_REPEAT && <i className="bx bx-block"></i>}
                    {isAppointment.repeatInfo?.repeatType && isAppointment.repeatInfo?.repeatType == Repeat.WEEKLY && <i className="bx bx-calendar-minus"></i>}
                    &nbsp; {isAppointment.repeatInfo?.repeatType}
                  </h5>
                </div>
              </div>

              {isAppointment.color && (
                <div className="row mb-3">
                  <div className="col-md-12">
                    <Label className="form-label">Color</Label>
                    <div className="cus-color" style={{ backgroundColor: `${isAppointment.color}` }}></div>
                  </div>
                </div>
              )}
              {isAppointment.status == AppointmentStatus.PENDING &&
                isAppointment.approvedStatus == ApprovalStatus.APPROVED &&
                isAppointment.typeOfMeeting == AppointmentType.VIDEO &&
                (isAppointment.meetingStatus == null || isAppointment.meetingStatus != "STARTED") && (
                  <div className="text-center mt-4">
                    <button
                      onClick={() => handleStartMeeting(isAppointment)}
                      // onClick={() => {
                      //   loggedUser?.role == Role.CLIENT
                      //     ? startMeetingNew2(
                      //       isAppointment.therapistId._id != null ? isAppointment.therapistId._id : "",
                      //       true,
                      //       isAppointment._id,
                      //       isAppointment.start,
                      //       isAppointment.end,
                      //       isAppointment.status,
                      //       isAppointment.approvedStatus
                      //     )
                      //     : startMeetingNew2(
                      //       isAppointment.clientId?._id,
                      //       true,
                      //       isAppointment._id,
                      //       isAppointment.start,
                      //       isAppointment.end,
                      //       isAppointment.status,
                      //       isAppointment.approvedStatus
                      //     );
                      // }}
                      className="btn btn-primary  btn-bg"
                    >
                      Start Video Call
                    </button>
                  </div>
                )}
              {isAppointment.status == AppointmentStatus.PENDING &&
                isAppointment.approvedStatus == ApprovalStatus.APPROVED &&
                isAppointment.typeOfMeeting == AppointmentType.VIDEO &&
                isAppointment.meetingStatus != null &&
                isAppointment.meetingStatus == "STARTED" &&
                isAppointment.meetingId != null &&
                isAppointment.meetingStartedBy != null && (
                  <div className="text-center mt-4">
                    <button
                      onClick={() => {
                        const timeDifferenceWithStartAndEnd = moment.duration(moment(isAppointment.end).diff(moment(isAppointment.start)));
                        const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();
                        const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
                        // joinMeeting(
                        //   isAppointment.meetingId,
                        //   loggedUser?.role == Role.CLIENT ? isAppointment.therapistId._id : isAppointment.clientId._id,
                        //   isAppointment.therapistId._id,
                        //   finalMeetingTime,
                        //   true,
                        //   true,
                        //   true,
                        //   true,
                        //   isAppointment._id
                        // );
                        startZoomCall(
                          false,
                          user?.role == Role.CLIENT ? isAppointment.therapistId._id : isAppointment.clientId._id,
                          finalMeetingTime,
                          isAppointment._id,
                          ""
                        );
                      }}
                      className="btn btn-primary  btn-bg"
                    >
                      Join Call
                    </button>
                  </div>
                )}
            </OffcanvasBody>
          </Offcanvas>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UpcomingAppointmentsNew;
