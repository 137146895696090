import React, { useEffect, useState, useMemo } from "react";
import { Card, Col, Input, Row } from "reactstrap";
import moment from "moment";
import { OtherSupports } from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";

const NewFormSeven = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;
  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    bioPsychosocialDevelopemntHistory: {
      developmentHistory: "",
      childhoodHistory: "",
      fosterCare: "",
      siblings: "",
      familyHistoryOfMHOrSAIssues: "",
      currentSpouse: "",
      childrenStepChildren: "",
      relationsIssues: "",
      otherSupports: {
        family: "",
        church: "",
        employer: "",
        friends: "",
        other: "",
        otherSupport: "",
      },
    },
    bioPsychosocialEducation: {
      isApplicable: null,
      schoolGradeName: "",
      lastIep: "",
      teacher: "",
      learningAbility: "",
      disabilityArea: "",
      educationalProblems: "",
      behaviorProblem: "",
      repetedGrades: "",
      socialInteraction: "",
      suspension: "",
      expulsion: "",
      isPsychologicalTestingCompleted: null,
      date: "",
      expulsionTestResults: "",
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        bioPsychosocialDevelopemntHistory: data?.bioPsychosocialDevelopemntHistory,
        bioPsychosocialEducation: data?.bioPsychosocialEducation,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   bioPsychosocialDevelopemntHistory: data?.bioPsychosocialDevelopemntHistory,
      //   bioPsychosocialEducation: data?.bioPsychosocialEducation,
      // };
      // setFormData(updateValues);

      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));
    }
  }, [memoizedUpdateValues]);

  const handleUpdateData = () => {
    const updateDetails = {
      formHeader: {
        ...formData?.formHeader,
      },
      bioPsychosocialDevelopemntHistory: {
        ...formData?.bioPsychosocialDevelopemntHistory,
      },
      bioPsychosocialEducation: {
        ...formData?.bioPsychosocialEducation,
      },
    };
    handleBulkFormDataUpdate(updateDetails);
  };

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
              // nextStep();
            }}
          >
            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
          </button>
        </div>
        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Card className="match-card-header p-2 mb-2">
          <div style={{ display: "flex" }}>
            <h5 className="fw-500" style={{ margin: "auto" }}>
              BIOPSYCHOSOCIAL DEVELOPMENTAL HISTORY:
            </h5>
          </div>
        </Card>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-body-second p-3">
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Developmental History:{" "}
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.developmentHistory || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        developmentHistory: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Relevant childhood history:
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.childhoodHistory || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        childhoodHistory: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Foster Care:
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.fosterCare || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        fosterCare: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Siblings:
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.siblings || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        siblings: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Family history of MH or SA issues:
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.familyHistoryOfMHOrSAIssues || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        familyHistoryOfMHOrSAIssues: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Current Spouse/Significant Other:
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.currentSpouse || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        currentSpouse: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Children/Step-Children:
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.childrenStepChildren || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        childrenStepChildren: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                  Current Relational Issues w/ Family (potential involvement w/treatment):
                  <span className="fw-bold">
                    {" "}
                    ( <span style={{ color: "red", fontSize: "15px" }}>*</span>)
                  </span>
                </label>
                <Input
                  value={formData?.bioPsychosocialDevelopemntHistory?.relationsIssues || ""}
                  onChange={e => {
                    setFormData((formData: Partial<DigitalForm>) => ({
                      ...formData,
                      bioPsychosocialDevelopemntHistory: {
                        ...formData?.bioPsychosocialDevelopemntHistory,
                        relationsIssues: e.target.value,
                      },
                    }));
                  }}
                  type="textarea"
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Row style={{ marginBottom: "0.5rem" }}>
                    <h6>What other supports do you have?</h6>
                    <div className="col-lg-3 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.family || ""}
                        checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.family === OtherSupports.family}
                        onClick={e => {
                          const result = formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.family === OtherSupports.family;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            bioPsychosocialDevelopemntHistory: {
                              ...formData?.bioPsychosocialDevelopemntHistory,
                              otherSupports: {
                                ...formData?.bioPsychosocialDevelopemntHistory?.otherSupports,
                                family: result ? "" : OtherSupports.family,
                                otherSupport: "",
                                other: "",
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Family{" "}
                      </label>
                    </div>
                    <div className="col-lg-3 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.church || ""}
                        checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.church === OtherSupports.church}
                        onClick={e => {
                          const result = formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.church === OtherSupports.church;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            bioPsychosocialDevelopemntHistory: {
                              ...formData?.bioPsychosocialDevelopemntHistory,
                              otherSupports: {
                                ...formData?.bioPsychosocialDevelopemntHistory?.otherSupports,
                                church: result ? "" : OtherSupports.church,
                                otherSupport: "",
                                other: "",
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Church{" "}
                      </label>
                    </div>
                    <div className="col-lg-3 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.employer || ""}
                        checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.employer === OtherSupports.employer}
                        onClick={e => {
                          const result = formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.employer === OtherSupports.employer;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            bioPsychosocialDevelopemntHistory: {
                              ...formData?.bioPsychosocialDevelopemntHistory,
                              otherSupports: {
                                ...formData?.bioPsychosocialDevelopemntHistory?.otherSupports,
                                employer: result ? "" : OtherSupports.employer,
                                otherSupport: "",
                                other: "",
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Employer{" "}
                      </label>
                    </div>
                    <div className="col-lg-3 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.friends || ""}
                        checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.friends === OtherSupports.friends}
                        onClick={e => {
                          const result = formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.friends === OtherSupports.friends;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            bioPsychosocialDevelopemntHistory: {
                              ...formData?.bioPsychosocialDevelopemntHistory,
                              otherSupports: {
                                ...formData?.bioPsychosocialDevelopemntHistory?.otherSupports,
                                friends: result ? "" : OtherSupports.friends,
                                otherSupport: "",
                                other: "",
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Friends{" "}
                      </label>
                    </div>
                    <div className="col-lg-8 d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem" }}
                        type="checkbox"
                        className="form-check-input"
                        value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.other || ""}
                        checked={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.other === OtherSupports.other}
                        onClick={e => {
                          const result = formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.other === OtherSupports.other;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            bioPsychosocialDevelopemntHistory: {
                              ...formData?.bioPsychosocialDevelopemntHistory,
                              otherSupports: {
                                ...formData?.bioPsychosocialDevelopemntHistory?.otherSupports,
                                other: result ? "" : OtherSupports.other,
                                otherSupport: "",
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        Other:{" "}
                      </label>

                      <input
                        style={{
                          width: "100%",
                          //  border: "none",
                          marginTop: "0.1rem",
                          backgroundColor: "unset",
                          border: "1px dotted  #ccc",
                          padding: "2px",
                          outline: "none",
                          borderRadius: "8px",
                        }}
                        type="text"
                        id="name"
                        disabled={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.other !== OtherSupports.other}
                        value={formData?.bioPsychosocialDevelopemntHistory?.otherSupports?.otherSupport || ""}
                        onChange={e => {
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            bioPsychosocialDevelopemntHistory: {
                              ...formData?.bioPsychosocialDevelopemntHistory,
                              otherSupports: {
                                ...formData?.bioPsychosocialDevelopemntHistory?.otherSupports,
                                otherSupport: e.target.value,
                              },
                            },
                          }));
                        }}
                      />
                    </div>
                  </Row>
                </div>
              </Col>
            </Card>

            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto", marginRight: "25%" }}>
                  EDUCATION
                </h5>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ fontSize: "larger" }}
                    checked={formData?.bioPsychosocialEducation?.isApplicable === true}
                    onClick={e => {
                      setFormData(() => ({
                        ...formData,
                        bioPsychosocialEducation: {
                          ...formData.bioPsychosocialEducation,
                          isApplicable: formData?.bioPsychosocialEducation?.isApplicable === true ? false : true,
                        },
                      }));
                    }}
                  />
                  <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                    Mark if not applicable
                  </label>
                </div>
              </div>
            </Card>
            <Card className="match-card-body p-3">
              <Row>
                <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                  For age 18 and under or for clients till in school complete the following questions :
                </label>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          {" "}
                          If currently in school <br />- Name/grade :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",
                            //  border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          id="name"
                          value={formData?.bioPsychosocialEducation?.schoolGradeName || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                schoolGradeName: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Last IEP :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",

                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.lastIep || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                lastIep: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Teacher/Counselor :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.teacher || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                teacher: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Learning Abilities/Strengths :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.learningAbility || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                learningAbility: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          If enrolled in “exceptional children’s programs”,specify disability area :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.disabilityArea || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                disabilityArea: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Educational Problems :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.educationalProblems || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                educationalProblems: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Behavior Problems in school :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.behaviorProblem || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                behaviorProblem: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Repeated Grades? If so,which? :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.repetedGrades || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                repetedGrades: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Social Interactions (peers/teachers) :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.socialInteraction || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                socialInteraction: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Suspensions :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.suspension || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                suspension: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Expulsions :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.expulsion || ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                expulsion: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-0">
                <Col lg={4}>
                  <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                    Current Psychological Testing Completed?
                  </h6>
                </Col>
                <Col lg={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      // value={PsychologicalTestingCompletedStatus.yes}
                      checked={formData?.bioPsychosocialEducation?.isPsychologicalTestingCompleted === true}
                      onClick={e => {
                        const result = formData?.bioPsychosocialEducation?.isPsychologicalTestingCompleted === true;
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          bioPsychosocialEducation: {
                            ...formData?.bioPsychosocialEducation,
                            isPsychologicalTestingCompleted: result ? null : true,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Yes
                    </label>
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      // value={PsychologicalTestingCompletedStatus.no}
                      checked={formData?.bioPsychosocialEducation?.isPsychologicalTestingCompleted === false}
                      onClick={e => {
                        const result = formData?.bioPsychosocialEducation?.isPsychologicalTestingCompleted === false;
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          bioPsychosocialEducation: {
                            ...formData?.bioPsychosocialEducation,
                            isPsychologicalTestingCompleted: result ? null : false,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      No
                    </label>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="col-auto d-flex">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      Date :
                    </h6>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                      <div className="col d-flex">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                          }}
                          type="date"
                          value={formData?.bioPsychosocialEducation?.date ? moment.utc(formData?.bioPsychosocialEducation?.date).format("YYYY-MM-DD") : ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                date: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          If so, specify tests and include results :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",

                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.bioPsychosocialEducation?.expulsionTestResults}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              bioPsychosocialEducation: {
                                ...formData?.bioPsychosocialEducation,
                                expulsionTestResults: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
        </Row>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                // const updateDetails = {
                //   formHeader: {
                //     ...formData?.formHeader,
                //   },
                //   bioPsychosocialDevelopemntHistory: {
                //     ...formData?.bioPsychosocialDevelopemntHistory,
                //   },
                //   bioPsychosocialEducation: {
                //     ...formData?.bioPsychosocialEducation,
                //   },
                // };
                // handleBulkFormDataUpdate(updateDetails);
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 7 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormSeven;
