import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../../context/UserContext";
import { Util } from "../../../Util";

interface WorkingHoursComponentProps {
  workingHours: any;
}

const WorkingHoursComponent: React.FC<WorkingHoursComponentProps> = ({ workingHours }: WorkingHoursComponentProps) => {

  const [groupedWorkingHours, setGroupedWorkingHours] = useState<any>([]);

  const daysOrder: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  useEffect(() => {
    try {
      if(workingHours){
        const groupedHours = workingHours.reduce((acc: any, curr: any) => {
          const day = curr.day;

          if (!acc[day]) {
            acc[day] = { day: day, hours: [] };
          }

          const finalText = `${Util.convertUTCDateToLocalDate12Hour(curr.startTime)} - ${Util.convertUTCDateToLocalDate12Hour(curr.endTime)}`;

          acc[day].hours.push(finalText);

          return acc;
        }, {});

        const result = Object.values(groupedHours).sort((a: any, b: any) => {
          return (daysOrder[a.day] || 0) - (daysOrder[b.day] || 0);
        });

        setGroupedWorkingHours(result);
      }
      
    } catch (error) {
        
    }
  }, [workingHours])
  
  return (
    <React.Fragment>
      {groupedWorkingHours && groupedWorkingHours?.length && groupedWorkingHours?.length > 0 ? 
        <div className="card-heading mb-4">
          {groupedWorkingHours.map((dayValue: any, index: any) => (
            <div key={index} className="mb-3">
              <h5 className="font-size-14 mb-2">
                {dayValue?.day ?? ""}
              </h5>
              {dayValue && dayValue?.hours && dayValue?.hours?.length && dayValue?.hours?.length > 0 && dayValue?.hours.map((hour: any, index: any) => (
                <h5 className="font-size-14 mb-2" key={index}>
                  {hour ?? ""}
                </h5>
              ))}
            </div>
          ))}
        </div> : 
        <div className="card-heading mb-4 d-flex align-items-center justify-content-center">No available working days and hours</div>
      }
    </React.Fragment>
  );
};

export default WorkingHoursComponent;
