import React, { useRef, useEffect, useState, useCallback, useContext, ChangeEvent } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, ListGroup, ListGroupItem, Progress, Form, FormGroup, Input, Label } from "reactstrap";
import { UploadedItem } from "./types";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useDropzone } from "react-dropzone";
import "./style.css";
import { TrainingDocumentFoldersService } from "./../../services/TrainingDocumentFoldersService";
import { PreSignedUrl } from "./../../models/TrainingDocumentFolders";
import UserContext from "src/context/UserContext";
import { toast } from "react-toastify";
import axios from "axios";

interface FileFolderModalProps {
  toggle: () => void;
  modal: boolean;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  items: UploadedItem[];
  parentFolderId: string | null;
}

const FileFolderModal: React.FC<FileFolderModalProps> = ({ toggle, modal, handleFileChange, items, parentFolderId }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [uploadMessage, setUploadMessage] = useState<string[]>([]);
  const [user] = useContext(UserContext);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);

  const [fileDescription, setFileDescription] = useState<string>("");
  const [fileDetailsError, setFileDetailsError] = useState<string>("");
  const [fileTitle, setFileTitle] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const handleUpload = async () => {
    if (!fileTitle || !fileDescription) {
      setFileDetailsError("File Title and Description are required");
      return;
    }
    setFileDetailsError("");
    if (files.length === 0) return;
    setUploading(true);

    const filesArray = Array.from(files);

    const userId = user?._id || "";

    try {
      const response = await TrainingDocumentFoldersService.getPreSignedUrls({
        files: filesArray.map(file => ({
          name: file.name,
          type: file.type,
        })),
        parentFolderId: parentFolderId,
      });

      console.log(response);
      setUploadMessage([]);

      for (const data of response.data) {
        console.log(data);
        if (!data.success) {
          uploadMessage.push(`${data.fileName} : ${data.message}`);
          setUploadMessage([...uploadMessage, `${data.fileName} : ${data.message}`]);
        }
      }

      const urls: PreSignedUrl[] = response.data;

      console.log(urls);

      const successfulUploads: Array<{ file: File; urlObject: PreSignedUrl }> = [];

      //Upload files to S3 using the pre-signed URLs

      const uploadPromises = urls.map((urlObject: { url: string; key: string; success: boolean }, index: number) => {
        const file = filesArray[index];
        const instance = axios.create({
          transformRequest: (data, headers) => {
            delete headers.authorization;
            return data;
          },
        });

        const res = instance
          .put(urlObject.url, filesArray[index], {
            headers: {
              "Content-Type": filesArray[index].type,
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / total);
              uploadProgress[index] = percentCompleted;
              setUploadProgress([...uploadProgress]);
            },
          })
          .then(response => {
            console.log("File uploaded successfully:", response);
            successfulUploads.push({ file, urlObject });
            return { success: true, data: response.data }; // Return success information if needed
          })
          .catch(error => {
            console.error("Error uploading file:", error);
            return { success: false, error }; // Return error information
          });
        console.log("upload promise");
        console.log(res);
        return res;
      });
      await Promise.all(uploadPromises);

      // const uploadPromises = urls.map((urlObject, index) => {
      //   const file = filesArray[index];

      //   const headers = {
      //     "Content-Type": file.type,
      //   };

      //   //use this for preventing unnessasary header issue
      //   if (urlObject.success) {
      //     return fetch(urlObject.url, {
      //       method: "PUT",
      //       body: file,
      //       headers: headers,
      //     })
      //       .then(response => {
      //         if (!response.ok) {
      //           throw new Error(`Error uploading file ${file.name}: ${response.statusText}`);
      //         }
      //         successfulUploads.push({ file, urlObject });
      //         console.log(`File ${file.name} uploaded successfully`, response);
      //       })
      //       .catch(error => {
      //         console.error(`Error uploading file ${file.name}`, error.message);
      //       });
      //   } else {
      //     return Promise.resolve();
      //   }
      // });

      // await Promise.all(uploadPromises);

      if (successfulUploads.length > 0) {
        // Only proceed to create database records for successfully uploaded files
        const dbPromises = successfulUploads.map(({ file, urlObject }) =>
          TrainingDocumentFoldersService.createTrainingDocumentFiles({
            parentFolderId: parentFolderId,
            fileNameInAwsBucket: urlObject.key,
            originalFileName: file.name,
            type: file.type,
            title: fileTitle,
            description: fileDescription,
            createdBy: userId,
          })
        );

        await Promise.all(dbPromises);

        setUploadStatus("Files uploaded and database records created successfully");
        toast.success("Files uploaded and database records created successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setFileDescription("");
        setFileTitle("");
      } else {
        toast.error("No files were uploaded successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setUploadStatus("No files were uploaded successfully");
        setUploadProgress([]);
        setFileDescription("");
        setFileTitle("");
      }
      setFiles([]);
    } catch (error) {
      setUploadStatus("Error uploading file");
      console.error("Error uploading file:", error);

      toast.error("Error uploading Files.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setUploadProgress([]);
      setFileDescription("");
      setFileTitle("");
    } finally {
      // setUploadMessage([]);
      setUploading(false);
      setUploadProgress([]);
      setFileDescription("");
      setFileTitle("");
      toggle();
      // setFiles([]);
      // setUploadMessage([]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const removeSelectedFile = (number: number) => {
    const existFiles = files.filter((file: File, index: number) => index !== number);
    setFiles(existFiles);
    console.log("remove files");
    console.log(existFiles);
  };

  const clearData = () => {
    setFiles([]);
    setUploadMessage([]);
    setUploadStatus("");
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        clearData();
      }}
    >
      <ModalHeader toggle={toggle}>Upload Files/Folders</ModalHeader>
      <ModalBody style={{ maxHeight: "100vh", overflowY: "auto" }}>
        {/* <Col>
            <Button onClick={handleButtonClick}>Upload Files/Folders</Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              data-webkitdirectory=""
              data-mozdirectory=""
              data-directory=""
              multiple
              onChange={handleFileChange}
            />
            <ul>
              {items.length > 0 &&
                items.map((item, index) => (
                  <li style={{ listStyleType: "none" }} key={index}>
                    {item.isDirectory ? "📁" : "📄"} {item.path} - {item.size} bytes
                  </li>
                ))}
            </ul>
          </Col> */}
        {uploadStatus === null ? null : (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <p>{uploadStatus}</p>
          </div>
        )}
        {uploadMessage.length !== 0 && (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <ul>
              {uploadMessage.map((data, index) => (
                <li style={{ listStyleType: "none" }} key={index}>
                  {data}
                </li>
              ))}
            </ul>
          </div>
        )}

        {files.length !== 0 && (
          <Form className="col-md-12 col-10">
            {fileDetailsError && <p className="text-center text-danger">{fileDetailsError}</p>}
            <FormGroup>
              <Label for="exampleEmail">Title</Label>
              <Input
                id="exampleEmail"
                name="title"
                placeholder="Title"
                type="text"
                value={fileTitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFileTitle(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Description</Label>
              <Input
                id="examplePassword"
                name="description"
                placeholder="Description"
                type="text"
                value={fileDescription}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFileDescription(e.target.value)}
                required
              />
            </FormGroup>
          </Form>
        )}

        <div {...getRootProps()} className="d-flex flex-col  justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center " style={{ backgroundColor: "white", border: "2px dashed #555", width: "95%" }}>
            <div
              className="w-100"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                gap: "1",
              }}
            >
              <input {...getInputProps()} />

              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    paddingTop: "15px",
                  }}
                >
                  Drag and drop some files here, or click to select files
                </p>
              )}

              {files.length > 0 ? (
                <div
                  style={{
                    width: "60%",
                    maxHeight: "100px",
                    overflowY: "auto",
                    border: "1px solid #ddd",
                    padding: "10px",
                    zIndex: "140",
                  }}
                  className=" w-100"
                >
                  {files.map((file, index) => (
                    <div key={index}>
                      {file.name}

                      {uploadProgress[index] && <Progress value={uploadProgress[index] || 0}>{uploadProgress[index] || 0}%</Progress>}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex flex-col py-3 justify-center align-items-center">
                  <div>
                    <IoCloudUploadOutline size={30} />
                  </div>

                  <p>
                    Drop files here to upload,
                    <br /> or use the Upload button
                  </p>
                  <button className="bg-gray">Upload</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={uploading}
          onClick={() => {
            handleUpload();
          }}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>{" "}
        <Button
          color="secondary"
          onClick={() => {
            toggle();
            clearData();
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileFolderModal;
