import React from 'react';
import { Therapist } from 'src/models/Therapist';

type MainDetailsContextData = [
    therapistData: Therapist| null,
    setTherapistData: (therapist: Therapist| null) => void,
    publicAppoinmentData: any | null,
    setPublicAppoinmentData: (appoinment: any)=> void,
    userDataMainContext: any,
    setUserDataMainContext: (user: any)=> void,
    passwordMainContext: any,
    setPasswordMainContext: (pass: any)=> void,
    clickSkipButton: boolean,
    setClickSkipButton: (skip: boolean) => void
]

const MainDetailsContext = React.createContext<MainDetailsContextData>([null, ()=>({}), null, ()=>({}), null, ()=>({}), null, ()=>({}), false, ()=>({})])

export default MainDetailsContext;