// src/Tabs/ClinicalNotes.tsx
import React, { useContext, useEffect, useState } from "react";
import Close from "../../../assets/images/icons/u_times-circle.png";
import { Card, CardBody, Col, Input, Modal, ModalBody, ModalHeader, OffcanvasHeader, Row } from "reactstrap";
import moment from "moment/moment";
import { Role } from "../../../models/Role";
import PIEDiagnosisNoteViewNew from "../../Chat/DiagnosisNote/PIEDiagnosisNoteViewNew";
import DiagnosisNoteScreen from "../../Chat/DiagnosisNote/DiagnosisNoteView";
import images from "../../../assets/images";
import TranscribeHistoryCard from "../../Chat/DiagnosisNote/TranscribeHistoryCard";
import Modal23 from "../../Popup/Modal23";
import { VideoChatService } from "../../../services/VideoChatService";
import { TranscribeDetails } from "../../../models/TranscribeDetails";
import UserContext from "../../../context/UserContext";
import { TherapistService } from "../../../services/TherapistService";
import { toast } from "react-toastify";
import { TreatmentHistory, TreatmentHistoryData } from "../../../models/TreatmentHistory";
import Swal from "sweetalert2";

interface ClinicalNotesProps {
  clientId: string;
}

const ClinicalNotesSaas: React.FC<ClinicalNotesProps> = ({ clientId }) => {
  const [isTranscribeOpen, setTranscribeOpen] = useState(false);
  const [isTranscribeError, setIsTranscribeError] = useState(false);
  const [isTranscribeLoading, setIsTranscribeLoading] = useState(false);
  const [transcribe, setTranscribe] = useState<TranscribeDetails>();
  const [transcribeTextList, setTranscribeTextList] = useState<string[] | []>([]);
  const [searchTextForTranscribe, setSearchTextForTranscribe] = useState("");
  const [isDiagnosisNoteOpen, setIsDiagnosisNoteOpen] = useState(false);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [noteType, setNoteType] = useState<string | undefined>();
  const [totalSpentDuration, setTotalSpentDuration] = useState(0);
  const [isVersion, setIsVersion] = useState<boolean>(false);
  const [showPIENote, setShowPIENote] = useState(false);
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const [isDiagnosisError, setIsDiagnosisError] = useState(false);
  const [isDiagnosisLoading, setIsDiagnosisLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(true);
  const [offset, setOffset] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [noteIdAdd, setNoteId] = useState("");
  const [singleTreatmentHistoryData, setSingleTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [modalCenter, setModalCenter] = useState(false);
  const [isTreatmentLazyLoading, setIsTreatmentLazyLoading] = useState(false);
  const [showLazyLoadingButton, setShowLazyLoadingButton] = useState(true);
  const [treatmentHistoryData, setTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [audioURL, setAudioURL] = useState("");
  const [showRecording, setShowRecording] = useState(false);
  const [selectedTreatmentHistoryForMerge, setSelectedTreatmentHistoryForMerge] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [isCreating] = useState(false);

  useEffect(() => {
    setIsTreatmentError(false);
    setIsTreatmentLoading(true);

    {
      user?.role == Role.THERAPIST && getTreatmentHistoryNotesInitial(clientId);
    }

    {
      user?.role == Role.CLIENT && getTreatmentHistoryNotesInitialByTherapistId(clientId);
    }
  }, []);

  useEffect(() => {
    if (diagnosisId != "") {
      setIsDiagnosisNoteOpen(true);
    }
  }, [diagnosisId]);

  function showDiagnosis(diagnosisId: string, totalSpentDuration: number, noteTypeFrom: string, isVersion: boolean) {
    if (diagnosisId) {
      setNoteType(noteTypeFrom);
      setTotalSpentDuration(totalSpentDuration);
      setDiagnosisId(diagnosisId);
      setIsVersion(isVersion);
      setIsDiagnosisNoteOpen(true);

      if (noteTypeFrom) {
        if (noteTypeFrom === "PIE") {
          setShowPIENote(true);
          setShowAiGeneratedPopup(false);
        } else {
          setShowPIENote(false);
          setShowAiGeneratedPopup(true);
        }
      }
    }
  }

  function closeDiagnosis() {
    setDiagnosisId("");
    setIsDiagnosisError(false);
    setIsDiagnosisLoading(false);
    setIsDiagnosisNoteOpen(false);
  }

  const getTreatmentHistoryNotesInitial = (id: string) => {
    setOffset(1);

    TherapistService.getTreatmentHistory(id, limit * 1, 0).then(res => {
      if (res.success) {
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };

  const getTreatmentHistoryNotesInitialByTherapistId = (id: string) => {
    setOffset(1);
    TherapistService.getTreatmentHistoryByTherapistId(id, limit * 1, 0).then(res => {
      if (res.success) {
        setIsTreatmentLoading(false);
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
      } else {
        setIsTreatmentLoading(false);
        setIsTreatmentError(true);
      }
    });
  };

  function togCenter(noteId: string) {
    setNoteId(noteId);
    const selectedHistory = treatmentHistoryDetails.filter(c => c._id === noteId);
    const selectedHistorydata = selectedHistory[0];
    setSingleTreatmentHistoryData(selectedHistorydata);
    setModalCenter(!modalCenter);
  }

  function togCenterClose() {
    setModalCenter(false);
  }

  const loadMoreTreatmentHistoryNotesByTherapistID = (id: string) => {
    setIsTreatmentLazyLoading(true);

    TherapistService.getTreatmentHistoryByTherapistId(id, limit, offset * limit).then(res => {
      if (res.success) {
        setIsTreatmentLazyLoading(false);
        if (res.data.length != 0) {
          const newData = treatmentHistoryDetails.concat(res.data);
          setTreatmentHistory(newData);
          if (newData.length < limit * (offset + 1)) {
            setShowLazyLoadingButton(false);
          }
          setOffset(offset + 1);
        } else {
          setShowLazyLoadingButton(false);
        }
      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsTreatmentLazyLoading(false);
      }
    });
  };

  const loadMoreTreatmentHistoryNotes = (id: string) => {
    setIsTreatmentLazyLoading(true);
    TherapistService.getTreatmentHistory(id, limit, offset * limit).then(res => {
      if (res.success) {
        setIsTreatmentLazyLoading(false);
        if (res.data.length != 0) {
          const newData = treatmentHistoryDetails.concat(res.data);
          setTreatmentHistory(newData);
          if (newData.length < limit * (offset + 1)) {
            setShowLazyLoadingButton(false);
          }
          setOffset(offset + 1);
        } else {
          setShowLazyLoadingButton(false);
        }
      } else {
        toast.error(res.error ? res.error : "Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsTreatmentLazyLoading(false);
      }
    });
  };

  const showAudio = async (meetingId: string, path: string, showAudio: boolean) => {
    const data = {
      meetingId,
      path,
    };

    const result = await TherapistService.getAudioPresignUrlForSessionHistory(data);

    if (result) {
      setAudioURL(result.data);
      setShowRecording(showAudio);
    }
  };

  async function showTranscribe(transcribeId: string) {
    setIsTranscribeError(false);
    setIsTranscribeLoading(true);
    setTranscribeOpen(true);
    const response = await VideoChatService.getTranscribeDetails(transcribeId);

    if (response.success) {
      setIsTranscribeError(false);
      setIsTranscribeLoading(false);
      setTranscribe(response.data);
      setTranscribeTextList(response.data.transcriptText);
    } else {
      setIsTranscribeError(true);
      setIsTranscribeLoading(false);
    }
  }

  const searchTranscribe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextForTranscribe(event.target.value);
    const textList: string[] = [];
    const combinedArray = textList.concat(transcribe?.transcriptText != null ? transcribe?.transcriptText : []);
    const result = combinedArray.filter(word => word.match(event.target.value));
    setTranscribeTextList(result);
  };

  function closeTranscribe() {
    setIsTranscribeError(false);
    setIsTranscribeLoading(false);
    setTranscribeOpen(false);
    setSearchTextForTranscribe("");
  }


  function updateDiagnosisData(isUpdate: boolean) {
    if (isUpdate == true) {
      getTreatmentHistoryNotesInitial(clientId);
    }
  }

  function onShareWithSubTherapy(data: TreatmentHistory) {
    const historyKey = treatmentHistoryDetails?.findIndex((treatmentHistoryData: TreatmentHistoryData) => treatmentHistoryData._id == data?._id);
    const previousHistory = { ...treatmentHistoryDetails[historyKey] };
    const updatedHistory = { ...previousHistory, subTherapistId: data?.subTherapistId };
    treatmentHistoryDetails[historyKey] = updatedHistory;
    setTreatmentHistory(treatmentHistoryDetails);
  }

  function saveNewNote(transcribeData: any, noteType: string) {
    const selectedHistory = treatmentHistoryDetails.filter(c => c._id === transcribeData._id);

    (selectedHistory[0] as any).diagnosisNoteDetails.updatedByTherapist = true;
    (selectedHistory[0] as any).diagnosisNoteDetails.noteType = noteType;
  }

  return (
    <div style={{ padding: "10px" }}>
      <h3 style={{ color: "#f27e0c", marginTop: "3px" }}>Patient Session Notes</h3>
      <div>
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px",
          }}
        >
          {!isTranscribeOpen && isDiagnosisNoteOpen && (
            <div className="diagnosis-header">
              <h5 className="mb-0 font-size-15">Diagnosis Note</h5>
              <button type="button" onClick={closeDiagnosis} className="bg-transparent-color" data-dismiss="modal" aria-label="Close">
                <img src={Close} alt="Close" className="close-icon-d" />
              </button>
            </div>
          )}
        </div>
        <div>
          {isTranscribeOpen && !isDiagnosisNoteOpen && (
            <OffcanvasHeader toggle={closeTranscribe}>
              Transcribe for meeting from {moment(transcribe?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")} to
              {" " + moment(transcribe?.createdAt || null).format("YYYY-MM-DD h:mm:ss a")}
            </OffcanvasHeader>
          )}

          {/* {!isTranscribeOpen && !isDiagnosisNoteOpen && (
            <OffcanvasHeader
              toggle={() =>{}}
              style={{
                padding: "20px",
              }}
            >
              Session History &nbsp;&nbsp;{" "}
              {selectedTreatmentHistoryForMerge.length > 0 && user?.role == Role.THERAPIST && (
                <button className="btn btn-success" onClick={mergeList}>
                  Merge Sessions
                </button>
              )}
            </OffcanvasHeader>
          )}*/}

          <div className={"w-100"}>
            <div className="row chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  className="scrollbar-container ps--active-y"
                  style={{
                    height: isTranscribeOpen || isDiagnosisNoteOpen ? "80vh" : "83vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {isDiagnosisNoteOpen && (
                    <Modal
                      isOpen={isDiagnosisNoteOpen}
                      toggle={closeDiagnosis}
                      className="diagnosis-modal"
                      centered
                      style={{ maxWidth: '90%', width: '90%' }}
                    >
                      <ModalHeader toggle={closeDiagnosis}>
                        {noteType === "PIE" ? "PIE Diagnosis Note" : "Diagnosis Note"}
                      </ModalHeader>
                      <ModalBody style={{ maxHeight: '80vh', overflowY: 'auto' }} >
                        {noteType === "PIE" ? (
                          <PIEDiagnosisNoteViewNew
                            diagnosisId={diagnosisId}
                            totalSpentDuration={totalSpentDuration}
                            updateDiagnosisData={updateDiagnosisData}
                            isVersion={isVersion}
                          />
                        ) : (
                          <DiagnosisNoteScreen
                            diagnosisId={diagnosisId}
                            totalSpentDuration={totalSpentDuration}
                            updateDiagnosisData={updateDiagnosisData}
                            isVersion={isVersion}
                          />
                        )}
                      </ModalBody>
                    </Modal>
                  )}

                  {/* {isDiagnosisNoteOpen && <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData}></DiagnosisNoteScreen>} */}
                  {isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {isTranscribeLoading && !isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {isDiagnosisLoading && !isDiagnosisError && isDiagnosisNoteOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {!isTreatmentLoading && isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isTranscribeLoading && isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isDiagnosisLoading && isDiagnosisError && isDiagnosisNoteOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isDiagnosisNoteOpen &&
                    !isTreatmentLoading &&
                    !isTreatmentError &&
                    !isTranscribeOpen &&
                    treatmentHistoryDetails &&
                    treatmentHistoryDetails.length == 0 && (
                      <p className="text-center text-muted font-italic">No patient session notes are created yet.</p>
                    )}

                  {!isDiagnosisNoteOpen && !isTranscribeLoading && !isTranscribeError && isTranscribeOpen && !transcribe?.transCribeInProcess && (
                    <div className="search-box chat-search-box chat-sec1">
                      <div className="position-relative">
                        <Input
                          onChange={event => searchTranscribe(event)}
                          id="search-transcribe-text"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchTextForTranscribe}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  )}
                  {!isDiagnosisNoteOpen &&
                    !isTranscribeLoading &&
                    !isTranscribeError &&
                    isTranscribeOpen &&
                    !transcribe?.transCribeInProcess &&
                    transcribeTextList.map((trans: any, index) => {
                      return (
                        <Card key={index} className="cursor_pointer mb-1">
                          <CardBody>
                            <h5 className="font-size-13 mb-0">{trans}</h5>
                          </CardBody>
                        </Card>
                      );
                    })}
                  {!isDiagnosisNoteOpen && !isTranscribeLoading && !isTranscribeError && isTranscribeOpen && transcribe?.transCribeInProcess && (
                    <h5 className="font-size-13 mb-1">Transcribing on process please try again later</h5>
                  )}

                  {user?.role == Role.THERAPIST && (
                    <>
                      <div className="row">
                        <div className="col-xl-10">
                          {!isDiagnosisNoteOpen &&
                            !isTreatmentLoading &&
                            !isTreatmentError &&
                            !isTranscribeOpen &&
                            treatmentHistoryDetails
                              ?.sort((a, b) => (a.meetingStartedTime > b.meetingStartedTime ? -1 : 1))
                              .map((tr: any, index) => {
                                const pattern = new RegExp(
                                  "^(https?:\\/\\/)?" +
                                  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                                  "((\\d{1,3}\\.){3}\\d{1,3}))" +
                                  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                                  "(\\?[;&a-z\\d%_.~+=-]*)?" +
                                  "(\\#[-a-z\\d_]*)?$",
                                  "i"
                                );
                                return (
                                  <Card
                                    key={index}
                                    onClick={() => {
                                      if (!tr.isMeetingTranscribe) {
                                        togCenter(tr?._id);
                                      }
                                    }}
                                    className="cursor_pointer"
                                  >
                                    {!tr.isMeetingTranscribe && (
                                      <CardBody className="custom-card1">
                                        <Col xl={12}>
                                          <h5 className="font-size-15 title-line">&#9929; Normal Treatment History</h5>
                                        </Col>

                                        <Row>
                                          <Col xl={6}>
                                            <h5 className="font-size-15">
                                              {tr?.therapistId?.firstname} {tr?.therapistId?.lastname}
                                            </h5>
                                          </Col>

                                          <Col xl={6}>
                                            <span className="text-muted fw-medium " style={{ float: "right" }}>
                                              {moment(tr?.createdAt || null).format("YYYY-MM-DD")}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Col xl={12}>{!tr.isMeetingTranscribe && <span className="text-muted fw-medium ">{tr?.note}</span>}</Col>
                                      </CardBody>
                                    )}

                                    {tr.isMeetingTranscribe && (
                                      <TranscribeHistoryCard
                                        transcribeData={tr}
                                        isCreating={isCreating}
                                        showAudioFunction={showAudio}
                                        showDiagnosisFunction={showDiagnosis}
                                        showTranscribeFunction={showTranscribe}
                                        selectedTreatmentHistoryForMerge={selectedTreatmentHistoryForMerge}
                                        setSelectedTreatmentHistoryForMerge={setSelectedTreatmentHistoryForMerge}
                                        setSelectedClient={setSelectedClient}
                                        updateDiagnosisData={updateDiagnosisData}
                                        onShareWithSubTherapy={onShareWithSubTherapy}
                                        saveNewNote={saveNewNote}
                                      ></TranscribeHistoryCard>
                                    )}
                                  </Card>
                                );
                              })}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row">
                    {user?.role == Role.CLIENT && (
                      <>
                        {!isDiagnosisNoteOpen &&
                          !isTreatmentLoading &&
                          !isTreatmentError &&
                          !isTranscribeOpen &&
                          treatmentHistoryDetails
                            ?.sort((a, b) => (a.meetingStartedTime > b.meetingStartedTime ? -1 : 1))
                            .map((tr: any, index) => {
                              const pattern = new RegExp(
                                "^(https?:\\/\\/)?" +
                                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                                "(\\#[-a-z\\d_]*)?$",
                                "i"
                              );

                              return (
                                <div key={index} className="col-6">
                                  <Card className="cursor_pointer">
                                    {!tr.isMeetingTranscribe && (
                                      <CardBody className="custom-card1">
                                        <Col xl={12}>
                                          <h5 className="font-size-15 title-line">&#9929; Normal Treatment History</h5>
                                        </Col>

                                        <Row>
                                          <Col xl={6}>
                                            <h5 className="font-size-15">
                                              {tr?.therapistId?.firstname} {tr?.therapistId?.lastname}
                                            </h5>
                                          </Col>

                                          <Col xl={6}>
                                            <span className="text-muted fw-medium " style={{ float: "right" }}>
                                              {moment(tr?.createdAt || null).format("YYYY-MM-DD")}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Col xl={12}>{!tr.isMeetingTranscribe && <span className="text-muted fw-medium ">{tr?.note}</span>}</Col>
                                      </CardBody>
                                    )}

                                    {tr.isMeetingTranscribe && (
                                      <TranscribeHistoryCard
                                        transcribeData={tr}
                                        isCreating={isCreating}
                                        showAudioFunction={showAudio}
                                        showDiagnosisFunction={showDiagnosis}
                                        showTranscribeFunction={showTranscribe}
                                        selectedTreatmentHistoryForMerge={selectedTreatmentHistoryForMerge}
                                        setSelectedTreatmentHistoryForMerge={setSelectedTreatmentHistoryForMerge}
                                        setSelectedClient={setSelectedClient}
                                        updateDiagnosisData={updateDiagnosisData}
                                        onShareWithSubTherapy={onShareWithSubTherapy}
                                        saveNewNote={saveNewNote}
                                      ></TranscribeHistoryCard>
                                    )}
                                  </Card>
                                </div>
                              );
                            })}
                      </>
                    )}
                  </div>

                  {user?.role == Role.THERAPIST && (
                    <>
                      {!isDiagnosisNoteOpen &&
                        !isTreatmentLoading &&
                        !isTreatmentError &&
                        !isTranscribeOpen &&
                        showLazyLoadingButton &&
                        treatmentHistoryDetails &&
                        treatmentHistoryDetails.length >= 10 && (
                          <button
                            type="button"
                            className="btn btn-primary  relative mt-2 mb-2"
                            style={{
                              bottom: "0",
                              float: "left",
                              background: isTreatmentLazyLoading ? "grey" : "",
                              border: "none",
                            }}
                            onClick={() => {
                              if (!isTreatmentLazyLoading) {
                                loadMoreTreatmentHistoryNotes(clientId);
                              }
                            }}
                          >
                            {isTreatmentLazyLoading ? "Loading .." : "Show More"}
                          </button>
                        )}
                    </>
                  )}

                  {user?.role == Role.CLIENT && (
                    <>
                      {!isDiagnosisNoteOpen &&
                        !isTreatmentLoading &&
                        !isTreatmentError &&
                        !isTranscribeOpen &&
                        showLazyLoadingButton &&
                        treatmentHistoryDetails &&
                        treatmentHistoryDetails.length >= 10 && (
                          <button
                            type="button"
                            className="btn btn-primary  relative mt-2 mb-2"
                            style={{
                              bottom: "0",
                              float: "left",
                              background: isTreatmentLazyLoading ? "grey" : "",
                              border: "none",
                            }}
                            onClick={() => {
                              if (!isTreatmentLazyLoading) {
                                loadMoreTreatmentHistoryNotesByTherapistID(clientId);
                              }
                            }}
                          >
                            {isTreatmentLazyLoading ? "Loading .." : "Show More"}
                          </button>
                        )}
                    </>
                  )}

                  {showRecording && <Modal23 setShowModal={setShowRecording} showModal={showRecording} audioURL={audioURL} />}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalNotesSaas;
