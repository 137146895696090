import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { TechTicket } from "../../models/TechTicket";
import Spinner from "../../common/spinner/spinner";
import moment from "moment";
import Swal from "sweetalert2";
import TechTicketContext from "src/context/TechTicketRequest";

const TechTickets: React.FC = () => {
  const [techTickets, setTechTickets] = useState<TechTicket[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const LIMIT = 20;
  const [seeMore, setSeeMore] = useState(false);
  const [offset, setOffset] = useState(1);
  const [techTikcetsCount, setTechTikcetsCount] = useState(20);
  const [techTicketCount, getAllTechTicketCount] = useContext(TechTicketContext);
  
  useEffect(() => {
    getAllTechTickets();
  }, []);

  const getAllTechTickets = () => {
    setIsLoading(true)

    AdminService.getAllTechTickets(LIMIT, offset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setTechTickets(res.data.techTicketsList);
        setTechTikcetsCount(res.data.techTicketsList.length);
        setIsLoading(false)
      } else {
        setTechTickets([]);
        setIsLoading(false);
        setSeeMore(false);
      }
    });
  };

  const seeMoreFc = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    setIsLoading(true);

    AdminService.getAllTechTickets(20, updatedSkip).then(res => {
      if (res.success) {
        res.data.techTicketsList.map((tTicket: any) => {
          setTechTickets(techTcikets => [...techTcikets, tTicket]);
        });

        setTechTikcetsCount(res.data.techTicketsList.length);
      }

      setIsLoading(false);
    });
  };

  const markAsRead = (techTicketId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to mark as read?",
      showCancelButton: true,
      confirmButtonText: "Mark as Read",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.updateTechTicketStatus(techTicketId).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Successfully Updated!",
              confirmButtonColor: "#FD7F00",
            });

            getAllTechTickets();
            getAllTechTicketCount(techTicketCount - 1);
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed update. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Tech Tickets"} breadcrumbItem={"Tech Tickets"} />
          
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {techTickets !== undefined && techTickets.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Title</th>
                              <th scope="col">Message</th>
                              <th scope="col">Attachment</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {techTickets &&
                              techTickets.map((request, index) => (
                                <tr key={request._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{request?.createdBy?.firstname + " " + request?.createdBy?.lastname}</td>
                                  <td>{moment(request?.createdAt).format("YYYY-MM-DD hh:mm A")}</td>
                                  <td>{request.title}</td>
                                  <td>{request.message}</td>
                                  <td>
                                    <a className="btn btn-danger btn-sm" href={request?.uploadId?.url} target="_blank" rel="noreferrer">View File</a>
                                  </td>
                                  <td>
                                    {request?.isRead === false ?
                                      <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                          markAsRead(request?._id);
                                        }}
                                      >
                                        Mark as Read
                                      </button>
                                      :
                                      <span className="inprogress-2">Seen</span>
                                    }
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      {techTikcetsCount >= 20 && (
                        <>
                          <button className="btn btn-warning text-center mt-4" onClick={seeMoreFc}>
                            See More
                          </button>
                        </>
                      )}

                      {techTikcetsCount == 0 && <span>Tech tickets not found....</span>}
                    </>
                  ) : (
                    <>
                      {isLoading ? <Spinner /> : <div>Tech tickets not found.</div>}</>
                  )}
                </Col>
              </Row>
              <Row>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default TechTickets;
