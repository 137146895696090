import React, { useState } from "react";

const CustomSeeMoreText: React.FC<{
  content: string;
}> = ({ content }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const truncatedContent = content.length > 100 ? `${content.slice(0, 100)}...` : content;

  return (
    <span>
      {showFullText ? content : truncatedContent}
      {content.length > 100 && (
        <button onClick={toggleText} className="sms-reminder-showmore">
          {showFullText ? "Show Less" : "Show More"}
        </button>
      )}
    </span>
  );
};

export default CustomSeeMoreText;
