import axios from "axios";
import { NotificationDetails, NotificationModel } from "../models/Notification";
import { AppResponse } from "../models/Response";
import { User } from "../models/User";
import { Util } from "../Util";

export class NotificationService {
  public static async sendNotification(
    data: NotificationModel
  ): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl("createNotification");
    return await axios.post<Partial<User>, AppResponse<User>>(url, data);
  }

  public static async getNotifications(
    getAll: boolean,
    limit?: number,
    offset?: number
  ): Promise<AppResponse<NotificationDetails>> {
    let url;

    if (getAll) {
      url = Util.apiAuthUrl("getNotifications/" + limit + "/" + offset);
    } else {
      url = Util.apiAuthUrl("getNotifications");
    }

    return await axios.get<
      Partial<NotificationDetails>,
      AppResponse<NotificationDetails>
    >(url);
  }

  public static async markAllNotificationsRead(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("markAllNotificationsRead");

    return await axios.post<Partial<any>, AppResponse<any>>(url);
  }

  public static async deleteNotification(
    notificationId: string
  ): Promise<AppResponse<NotificationModel>> {
    const url = Util.apiAuthUrl("deleteNotification/" + notificationId);

    return await axios.delete<
      Partial<NotificationModel>,
      AppResponse<NotificationModel>
    >(url);
  }
}
