import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { LegalImpairment, MedicalHistoryTableModel } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";
import moment from "moment";

const FormFour = (props: any) => {
    const { formId, assesmenData, getAssesmentByClientId, customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })
    const [edit, setEdit] = useState<boolean>(true)

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 4,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: {
                    medicalHistoryTableSchema: assesmenData.medicalHistory?.medicalHistoryTableSchema?.map((medicalhistory: MedicalHistoryTableModel) => ({
                        illness: medicalhistory.illness,
                        treatmentandResponse: medicalhistory.treatmentandResponse,
                        barrierstoRecovery: medicalhistory.barrierstoRecovery,
                    })) || [],
                    baselineMammogram: assesmenData.medicalHistory?.baselineMammogram || "",
                    strengths: assesmenData.medicalHistory?.strengths || "",
                    needs: assesmenData.medicalHistory?.needs || ""
                },
                healthNotes: assesmenData.healthNotes,
                legalHistory: assesmenData.legalHistory
            }
            setFormData(updateAssesment);
        }
    }, [customActiveTab, assesmenData]);

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    const addRowMember = () => {
        setFormData((prevState: ClinicalAssetment) => ({
            ...prevState,
            medicalHistory: {
                ...prevState.medicalHistory,
                medicalHistoryTableSchema: [
                    ...(prevState.medicalHistory?.medicalHistoryTableSchema || []),
                    {}
                ]
            }
        }));
    };

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 4,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: {
                medicalHistoryTableSchema: formData.medicalHistory?.medicalHistoryTableSchema?.map(medicalhistory => ({
                    illness: medicalhistory.illness,
                    treatmentandResponse: medicalhistory.treatmentandResponse,
                    barrierstoRecovery: medicalhistory.barrierstoRecovery,
                })) || [],
                baselineMammogram: formData.medicalHistory?.baselineMammogram || "",
                strengths: formData.medicalHistory?.strengths || "",
                needs: formData.medicalHistory?.needs || ""
            },
            healthNotes: formData.healthNotes,
            legalHistory: formData.legalHistory
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )} */}

                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '15%' }}>Medical History / Reported Complications and Symptoms</h5>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        style={{ fontSize: "larger" }}
                                    //need to inergrate
                                    //     defaultChecked={}
                                    //     onChange={} />
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if none
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "33%" }}>illness</td>
                                            <td className="fw-500" style={{ width: "34%" }}>Treatment and Response</td>
                                            <td className="fw-500" style={{ width: "33%" }}>Barriers to Recovery</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData?.medicalHistory?.medicalHistoryTableSchema?.map((medical, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={medical.illness}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.medicalHistory?.medicalHistoryTableSchema || [])];
                                                                    updatedServicesHistory[index].illness = e.target.value;
                                                                    setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, medicalHistoryTableSchema: updatedServicesHistory } });
                                                                }}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={medical.treatmentandResponse}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.medicalHistory?.medicalHistoryTableSchema || [])];
                                                                    updatedServicesHistory[index].treatmentandResponse = e.target.value;
                                                                    setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, medicalHistoryTableSchema: updatedServicesHistory } });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={medical.barrierstoRecovery}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.medicalHistory?.medicalHistoryTableSchema || [])];
                                                                    updatedServicesHistory[index].barrierstoRecovery = e.target.value;
                                                                    setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, medicalHistoryTableSchema: updatedServicesHistory } });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                        <div>
                                            <div className="py-3 pl-1 d-flex justify-content-start">
                                                {edit &&
                                                    <Button color="primary" onClick={addRowMember}>Add More Details</Button>
                                                }
                                            </div>
                                        </div>
                                        <tr>
                                            <td colSpan={3}>
                                                <div className="col-auto">
                                                    <Row>
                                                        <Col>
                                                            <div className="row" style={{ alignItems: "center", marginTop: "0.5rem" }}>
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>If female and older than 40, baseline mammogram?</h6>
                                                                </div>

                                                                <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                                                    <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                        value={'yes'}
                                                                        name="olderthan40"
                                                                        checked={formData.medicalHistory?.baselineMammogram === 'yes'}
                                                                        onClick={() => setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, baselineMammogram: 'yes' } })}
                                                                    />
                                                                    <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                                                </div>
                                                                <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                                                    <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                        value={'no'}
                                                                        name="olderthan40"
                                                                        checked={formData.medicalHistory?.baselineMammogram === 'no'}
                                                                        onClick={() => setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, baselineMammogram: 'no' } })}
                                                                    />
                                                                    <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Card style={{ backgroundColor: "#F1F1F1", margin: "0.5rem" }}>
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                                                    </div>
                                                                    <div className="col">
                                                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                            type="text"
                                                                            id="name"
                                                                            readOnly={!edit}
                                                                            value={formData.medicalHistory?.strengths || ""}
                                                                            onChange={e => setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, strengths: e.target.value } })}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Card style={{ backgroundColor: "#F1F1F1", marginRight: "0.5rem", marginLeft: "0.5rem", marginBottom: "0.5rem" }}>
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified needs :</h6>
                                                                    </div>
                                                                    <div className="col">
                                                                        <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                            type="text"
                                                                            id="name"
                                                                            readOnly={!edit}
                                                                            value={formData.medicalHistory?.needs || ""}
                                                                            onChange={e => setFormData({ ...formData, medicalHistory: { ...formData.medicalHistory, needs: e.target.value } })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                            <Card className="match-card-body p-3">
                                <Row>
                                    <Col>
                                        <h6 className="fw-500" style={{ margin: "0.2rem", marginBottom: "0.5rem" }}>Notes :</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Allergies (any time):</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto  d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="allergies"
                                                checked={formData.healthNotes?.allergies?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, allergies: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="allergies"
                                                    checked={formData.healthNotes?.allergies?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, allergies: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.allergies?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.allergies?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, allergies: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Enuresis or Encopresis:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto  d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="enuresisOrEncopresis"
                                                checked={formData.healthNotes?.enuresisOrEncopresis?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, enuresisOrEncopresis: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="enuresisOrEncopresis"
                                                    checked={formData.healthNotes?.enuresisOrEncopresis?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, enuresisOrEncopresis: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.enuresisOrEncopresis?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.enuresisOrEncopresis?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, enuresisOrEncopresis: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Vision Complications:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="visionComplications"
                                                checked={formData.healthNotes?.visionComplications?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, visionComplications: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="visionComplications"
                                                    checked={formData.healthNotes?.visionComplications?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, visionComplications: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.visionComplications?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.visionComplications?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, visionComplications: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Hearing Complications:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="hearingComplications"
                                                checked={formData.healthNotes?.hearingComplications?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, hearingComplications: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="hearingComplications"
                                                    checked={formData.healthNotes?.hearingComplications?.isReported === false || !edit}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, hearingComplications: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.hearingComplications?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.hearingComplications?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, hearingComplications: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Dental Complications:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="dentalComplications"
                                                checked={formData.healthNotes?.dentalComplications?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dentalComplications: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="dentalComplications"
                                                    checked={formData.healthNotes?.dentalComplications?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dentalComplications: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.dentalComplications?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.dentalComplications?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dentalComplications: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Diet Restrictions:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="dietRestrictions"
                                                checked={formData.healthNotes?.dietRestrictions?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dietRestrictions: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="dietRestrictions"
                                                    checked={formData.healthNotes?.dietRestrictions?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dietRestrictions: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.dietRestrictions?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.dietRestrictions?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dietRestrictions: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Seizures:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="seizures"
                                                checked={formData.healthNotes?.seizures?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, seizures: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="seizures"
                                                    checked={formData.healthNotes?.seizures?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, seizures: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.seizures?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.seizures?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, seizures: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Somatic Complaints:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="somaticComplaints"
                                                checked={formData.healthNotes?.somaticComplaints?.isReported === true}
                                                onClick={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, somaticComplaints: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="somaticComplaints"
                                                    checked={formData.healthNotes?.somaticComplaints?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, somaticComplaints: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.somaticComplaints?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.somaticComplaints?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, somaticComplaints: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Heart/ Blood Pressure:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="heartBloodPressure"
                                                checked={formData.healthNotes?.heartBloodPressure?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, heartBloodPressure: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="heartBloodPressure"
                                                    checked={formData.healthNotes?.heartBloodPressure?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, heartBloodPressure: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.heartBloodPressure?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.heartBloodPressure?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, heartBloodPressure: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Diabetes:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="diabetes"
                                                checked={formData.healthNotes?.diabetes?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, diabetes: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="diabetes"
                                                    checked={formData.healthNotes?.diabetes?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, diabetes: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.diabetes?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.diabetes?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, diabetes: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>Hx of eating disorder:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="hxofEatingDisorder"
                                                checked={formData.healthNotes?.hxofEatingDisorder?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, hxofEatingDisorder: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="hxofEatingDisorder"
                                                    checked={formData.healthNotes?.hxofEatingDisorder?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, hxofEatingDisorder: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.hxofEatingDisorder?.isReported !== false || !edit}
                                                                value={formData.healthNotes?.hxofEatingDisorder?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, hxofEatingDisorder: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Problems Sleeping:</h6>
                                            </div>

                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    checked={formData.healthNotes?.problemsSleeping?.isReported === true}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, problemsSleeping: { isReported: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                            </div>
                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    checked={formData.healthNotes?.problemsSleeping?.goingtoSleep === true}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, problemsSleeping: { goingtoSleep: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Going to sleep</label>
                                            </div>
                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    checked={formData.healthNotes?.problemsSleeping?.stayingAsleep === true}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, problemsSleeping: { stayingAsleep: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Staying asleep</label>
                                            </div>
                                            <div className="col-xl-3">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="problemsSleeping"
                                                    checked={formData.healthNotes?.problemsSleeping?.Nightmares === true}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, problemsSleeping: { Nightmares: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Nightmares</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of head injury:</h6>
                                    </Col>
                                    <Col xl={3}>
                                        <div className="col-auto d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="historyofHeadInjury"
                                                checked={formData.healthNotes?.historyofHeadInjury?.isReported === true}
                                                onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, historyofHeadInjury: { isReported: true } } })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="historyofHeadInjury"
                                                    checked={formData.healthNotes?.historyofHeadInjury?.isReported === false}
                                                    onClick={() => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, historyofHeadInjury: { isReported: false } } })}
                                                />
                                            </div>
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                disabled={formData.healthNotes?.historyofHeadInjury?.isReported !== false}
                                                                value={formData.healthNotes?.historyofHeadInjury?.explanation || ""}
                                                                onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, historyofHeadInjury: { explanation: e.target.value, isReported: false } } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Date of last physical:</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        type="date"
                                                        id="name"
                                                        value={moment.utc(formData.healthNotes?.dateofLastPhysical || "").format("YYYY-MM-DD")}
                                                        onChange={e => setFormData({ ...formData, healthNotes: { ...formData.healthNotes, dateofLastPhysical: e.target.value } })}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Legal History</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row style={{ marginBottom: "0.5rem" }}>
                                <div className="row">
                                    <div className="col-auto pr-0 d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={formData.legalHistory?.ifNoCurrent}
                                            onClick={() => setFormData({
                                                ...formData, legalHistory: {
                                                    ...formData.legalHistory,
                                                    ifNoCurrent: !formData.legalHistory?.ifNoCurrent
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="col-10 p-0">
                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Check if no current / previous legal history reported</label>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Current legal charges?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        {/* <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                        </div> */}
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.legalHistory?.currentlegalCharges?.explanation || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, legalHistory: {
                                                                    ...formData.legalHistory,
                                                                    currentlegalCharges: { explanation: e.target.value }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Probation officer?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        {/* <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={e => setFormData({
                                                    ...formData, legalHistory: {
                                                        ...formData.legalHistory,
                                                        currentlegalCharges: { explanation: e.target.value }
                                                    }
                                                })}
                                            />
                                        </div> */}
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.legalHistory?.probationOfficer?.name || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, legalHistory: {
                                                                    ...formData.legalHistory,
                                                                    probationOfficer: { name: e.target.value }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Currently on probation?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        {/* <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={e => setFormData({
                                                    ...formData, legalHistory: {
                                                        ...formData.legalHistory,
                                                        currentlegalCharges: { explanation: e.target.value }
                                                    }
                                                })}
                                            />
                                        </div> */}
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.legalHistory?.currentlyonProbation?.name || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, legalHistory: {
                                                                    ...formData.legalHistory,
                                                                    currentlyonProbation: { name: e.target.value }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of incarcerations?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        {/* <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"        
                                            />
                                        </div> */}
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Dates/Charges :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.legalHistory?.historyofIncarcerations?.name || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, legalHistory: {
                                                                    ...formData.legalHistory,
                                                                    historyofIncarcerations: {
                                                                        ...formData.legalHistory?.historyofIncarcerations,
                                                                        name: e.target.value
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row" style={{ marginBottom: "0.5rem" }}>
                                        <div className="col-xl-3" style={{ marginBottom: "0.5rem" }}>
                                        </div>

                                        <div className="col-xl-9 d-flex">
                                            <div className="row">
                                                <div className="col-auto pr-0">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="saftyPlanNotNeeded"
                                                        checked={formData.legalHistory?.historyofIncarcerations?.additionalInformation}
                                                        onClick={() => setFormData({
                                                            ...formData, legalHistory: {
                                                                ...formData.legalHistory,
                                                                historyofIncarcerations: {
                                                                    ...formData.legalHistory?.historyofIncarcerations,
                                                                    additionalInformation: !formData.legalHistory?.historyofIncarcerations?.additionalInformation
                                                                }
                                                            }
                                                        })}
                                                    />
                                                </div>
                                                <div className="col-10 p-0">
                                                    <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Additional Information on Supplemental Pages</label>
                                                </div>
                                            </div>
                                            {/* <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.legalHistory?.historyofIncarcerations?.additionalInformation}
                                                onClick={() => setFormData({
                                                    ...formData, legalHistory: {
                                                        ...formData.legalHistory,
                                                        historyofIncarcerations: {
                                                            ...formData.legalHistory?.historyofIncarcerations,
                                                            additionalInformation: !formData.legalHistory?.historyofIncarcerations?.additionalInformation
                                                        }
                                                    }
                                                })
                                                }
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Additional Information on Supplemental Pages</label> */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of legal involvement?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        {/* <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                        </div> */}
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.legalHistory?.historyoflegalInvolvement?.name || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, legalHistory: {
                                                                    ...formData.legalHistory,
                                                                    historyoflegalInvolvement: { name: e.target.value }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Family legal involvement?</h6>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        {/* <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                        </div> */}
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            readOnly={!edit}
                                                            type="text"
                                                            id="name"
                                                            value={formData.legalHistory?.familylegalInvolvement?.name || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, legalHistory: {
                                                                    ...formData.legalHistory,
                                                                    familylegalInvolvement: { name: e.target.value }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Specific terms of probation / other : </h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.legalHistory?.specificTermsofProbation?.name || ""}
                                                    onChange={e => setFormData({
                                                        ...formData, legalHistory: {
                                                            ...formData.legalHistory,
                                                            specificTermsofProbation: { name: e.target.value }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-xl-5" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Overall degree of impairment related to legal issues :</h6>
                                        </div>

                                        <div className="col-xl-2 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legalImpairment"
                                                value={LegalImpairment.LOW || ''}
                                                checked={formData.legalHistory?.legalImpairment?.name === LegalImpairment.LOW}
                                                onClick={() => setFormData({
                                                    ...formData, legalHistory: {
                                                        ...formData.legalHistory, legalImpairment: {
                                                            name: LegalImpairment.LOW as LegalImpairment
                                                        }
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low</label>
                                        </div>
                                        <div className="col-xl-2 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="legalImpairment"
                                                value={LegalImpairment.MEDIUM || ''}
                                                checked={formData.legalHistory?.legalImpairment?.name === LegalImpairment.MEDIUM}
                                                onClick={() => setFormData({
                                                    ...formData, legalHistory: {
                                                        ...formData.legalHistory, legalImpairment: {
                                                            name: LegalImpairment.MEDIUM as LegalImpairment
                                                        }
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium</label>
                                        </div>
                                        <div className="col-xl-2 d-flex">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }} type="radio"
                                                className="form-check-input"
                                                name="legalImpairment"
                                                value={LegalImpairment.HIGH || ''}
                                                checked={formData.legalHistory?.legalImpairment?.name === LegalImpairment.HIGH}
                                                onClick={() => setFormData({
                                                    ...formData, legalHistory: {
                                                        ...formData.legalHistory, legalImpairment: {
                                                            name: LegalImpairment.HIGH as LegalImpairment
                                                        }
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary mr-2 relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={() => {
                                updateAssesmentDetails();
                            }}
                        >
                            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 4 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormFour;