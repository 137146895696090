import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Row, Input } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import PhoneInput from "react-phone-number-input";
import { UnitedHealthCareAuthForm } from "src/models/AuthorizationForm/united-health-care-auth-form-model";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { AuthFormType } from "src/models/AuthorizationFormData";
import SignaturePad from "react-signature-canvas";
import moment from "moment";

const UnitedHealthCareForm = (props: any) => {
    const { clientId, insuranceCompanyId, formId, getUpdateFormId, unitedHealthCareData } = props;
    const [formData, setFormData] = useState<UnitedHealthCareAuthForm>({});
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdate, setIsUpdate] = useState(props.isUpdate);
    const [clinicianSign, setClinicianSign] = useState<boolean>(false);

    useEffect(() => {
        setIsUpdate(props.isUpdate);
    }, [props.isUpdate]);

    useEffect(() => {
        const updateUnitedHealthCareData: UnitedHealthCareAuthForm = {
            memberInformation: unitedHealthCareData?.memberInformation,
            prescriberInformation: unitedHealthCareData?.prescriberInformation,
            medicationInformation: unitedHealthCareData?.medicationInformation,
            clinicalInformation: unitedHealthCareData?.clinicalInformation,
            additionalInformation: unitedHealthCareData?.additionalInformation,
            date: unitedHealthCareData?.date,
            signature: unitedHealthCareData?.signature,
        };
        setFormData(updateUnitedHealthCareData);
    }, [unitedHealthCareData]);

    function autoResizeTextarea(e: any) {
        const textarea = e.target;
        textarea.style.height = 'auto';

        const newHeight = Math.min(textarea.scrollHeight, 3 * 16);
        textarea.style.height = `${newHeight}px`;

        textarea.style.overflowY = textarea.scrollHeight > newHeight ? 'scroll' : 'hidden';
    }

    const saveUnitedHealthCareAuthForm = async () => {
        setIsSaving(true);
        const unitedHealthCareAuthFormDetails: UnitedHealthCareAuthForm = {
            _id: formId,
            clientId: clientId,
            insuranceCompanyId: insuranceCompanyId,
            authFormType: AuthFormType.UnitedHealthCareAuthForm,
            memberInformation: formData?.memberInformation,
            prescriberInformation: formData?.prescriberInformation,
            medicationInformation: formData?.medicationInformation,
            clinicalInformation: formData?.clinicalInformation,
            additionalInformation: formData?.additionalInformation,
            date: formData?.date,
            signature: formData?.signature
        }
        try {
            let res;
            if (isUpdate === false) {
                res = await AuthorizationFormService.createUnitedHealthCareAuthForm(unitedHealthCareAuthFormDetails);
                    if (res && res.success && res.data) {
                        getUpdateFormId(res.data._id);
                        setIsUpdate(true);
                        toast.success("United Health Care form data has been successfully saved!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        props.closePopup();
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
            } else {
                res = await AuthorizationFormService.updateAuthorizationForm(AuthFormType.UnitedHealthCareAuthForm, unitedHealthCareAuthFormDetails);
                if (res && res.success && res.data) {
                    getUpdateFormId(res.data._id);
                    toast.success("United Health Care form has been successfully updated!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    props.closePopup();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error(`Error occurred! Error details: ${error}.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        const therapySign = formData?.signature;
        if (therapySign === undefined || !therapySign || therapySign === "") {
          setClinicianSign(false);
        } else {
          setClinicianSign(true);
        }
    }, [unitedHealthCareData, formData]);

    const signPad = useRef<any>({
        minDistance: 0,
        throttle: 9,
        velocityFilterWeight: 0.7,
    });
    
    function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
        let timeout: ReturnType<typeof setTimeout>;
        return function (...args: Parameters<T>) {
          clearTimeout(timeout);
          timeout = setTimeout(() => func(...args), wait);
        };
    }
    
    const resizeCanvas = () => {
        if (signPad?.current) {
          const canvas = signPad.current.getCanvas();
          if (canvas) {
            const container = canvas.parentNode as HTMLElement;
            if (container) {
              const ratio = Math.max(window.devicePixelRatio || 1, 1);
              canvas.width = container.offsetWidth * ratio;
              canvas.height = container.offsetHeight * ratio;
              const context = canvas.getContext("2d");
              if (context) {
                context.scale(ratio, ratio);
              }
              signPad.current.clear();
            }
          }
        }
    };
    
    useEffect(() => {
        try {
          if (signPad?.current) {
              setTimeout(() => {
                resizeCanvas();
            }, 0);
          }
    
          const debouncedResize = debounce(resizeCanvas, 200);
          window.addEventListener("resize", debouncedResize);
    
          // Using ResizeObserver to handle changes in container size
          const observer = new ResizeObserver(() => {
            resizeCanvas();
          });
    
          if(signPad?.current) {
            const container = signPad.current.getCanvas().parentNode as HTMLElement;
            if(container) {
              observer.observe(container);
            }
          }
    
          return () => {
           window.removeEventListener("resize", debouncedResize);
           observer.disconnect();
          };
        } catch (error) {
    
        }
    
    }, []);

    function clearsign(): void {
        if(signPad?.current) {
          console.log("clear!");
          signPad.current.clear();
          const signatureData = null;
        }
    }
    
    function saveSign() {
        if(signPad?.current) {
           const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
           setFormData({ ...formData, signature: signatureData });
        }
    }
    
    function editSign() {
        setClinicianSign(false);
    }

    // const formatDate = (date: any) => {
    //     if (!date) return '';
    //     const d = new Date(date);
    //     const year = d.getFullYear();
    //     const month = String(d.getMonth() + 1).padStart(2, '0');
    //     const day = String(d.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    //   };

    const formatDate = (date: any) => {
        if (!date) return '';
        return moment.utc(date).format('YYYY-MM-DD');
    };

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
                <Card className="match-card-header-secondary"style={{ marginBottom: "0.5rem", marginTop: "0.85rem",border: "none", borderRadius: "8px" }}>
                    <Row className="align-items-center pt-2">
                        <Col xs="12" sm="5" className="text-center text-sm-start">
                            <div>
                                <img
                                    src="/static/assets/img/authorization/united_health_care_community.png"
                                    alt="United Healthcare Insurance Company Logo"
                                    className="img-fluid"
                                    style={{ maxWidth: '250px' }}
                                />
                            </div>
                        </Col>
                        <Col xs="12" sm="7" className="text-center text-sm-end">
                            <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                                <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request Form</span><br />
                            </p>
                        </Col>
                    </Row>
                    <hr style={{ border: "1px solid #B2B2B2", margin: "0" }} />
                    <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>

                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                <p style={{ margin: "0" }}>
                                    Please complete this entire form and fax it to:<strong> 866-940-7328</strong>. If you have questions, please call<strong> 800-310-6826</strong>.
                                    This form may contain multiple pages. Please complete all pages to avoid a delay in our decision.
                                    Allow at least 24 hours for review.
                                </p>
                            </div>

                        </div>
                    </Row>
                </Card>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Member Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Member Name :</label>
                                        </Col>
                                        <Col xs="12" sm="10" >
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.memberInformation?.memberName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            memberName: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Member ID:</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.memberInformation?.medicaidID || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            medicaidID: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label  style={{ fontSize: "12px" }}>Date of birth :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formatDate(formData?.memberInformation?.dateOfBirth)}
                                                    onChange={(e) => {
                                                        const date = new Date(e.target.value);
                                                        setFormData({
                                                            ...formData,
                                                            memberInformation: {
                                                                ...formData?.memberInformation,
                                                                dateOfBirth: date
                                                            }
                                                        });
                                                    }} /></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.memberInformation?.phone || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            memberInformation: {
                                                                ...formData?.memberInformation,
                                                                phone: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Allergies:</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    value={formData?.memberInformation?.allergies || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            allergies: e.target.value
                                                        }
                                                    })}
                                                ></textarea>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label  style={{ fontSize: "12px" }}> Street Address :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.memberInformation?.streetAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            streetAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="1" className="mb-2 mb-sm-0">
                                            <label  style={{ fontSize: "12px" }}>City :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.memberInformation?.city || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            city: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="1">
                                            <label style={{ fontSize: "12px" }}>State :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.memberInformation?.state || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            state: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label  style={{ fontSize: "12px" }}>Zip code :</label>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.memberInformation?.zipCode || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        memberInformation: {
                                                            ...formData?.memberInformation,
                                                            zipCode: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{ height: '2px', backgroundColor: '#848484', margin: '1rem 0', width: '100%' }}></div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={4}><h6 className="fw-500" style={{ margin: "0.2rem",fontSize: "12px"  }}>Is the requested medication:</h6></Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="new"
                                                    checked={formData?.memberInformation?.isRequestedMedication?.new === true}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isRequestedMedication: { new: true } } })}
                                                />
                                                <label className="fw-500 " style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>New or</label>
                                            </div>
                                        </Col>
                                        <Col xl={5}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="radio"
                                                    name="new"
                                                    className="form-check-input"
                                                    checked={formData?.memberInformation?.isRequestedMedication?.new === false}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isRequestedMedication: { new: false } } })}
                                                />
                                                <label className="fw-500 " style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>Continuation of Therapy</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="col-auto d-flex">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem" }}>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h6 className="fw-500" style={{ margin: "0.3rem",fontSize: "12px"  }}>If continuation, list start date: </h6>
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="date"
                                                                    disabled={formData?.memberInformation?.isRequestedMedication?.new !== false}
                                                                    value={formatDate(formData?.memberInformation?.isRequestedMedication?.continuationDate)}
                                                                    onChange={e => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isRequestedMedication: { continuationDate: new Date(e.target.value), new: false } } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={4}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem",fontSize: "12px"  }}>Is this patient currently hospitalized ?</h6>
                                        </Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isHospitalized"
                                                    checked={formData?.memberInformation?.isHospitalized?.yes === true}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isHospitalized: { yes: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>Yes</label>
                                            </div>
                                        </Col>
                                        <Col xl={5}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isHospitalized"
                                                    checked={formData?.memberInformation?.isHospitalized?.yes === false}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isHospitalized: { yes: false } } })}
                                                />
                                                <label className="fw-500 " style={{ margin: "0.2rem", marginInlineStart: "0.3rem" ,fontSize: "12px" }}>No</label>
                                            </div>
                                        </Col></Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="col-auto  d-flex">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem" }}>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h6 className="fw-500" style={{ margin: "0.3rem",fontSize: "12px"  }}>If recently discharged, list discharge date: </h6>
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="date"
                                                                    disabled={formData?.memberInformation?.isHospitalized?.yes !== false}
                                                                    value={formatDate(formData?.memberInformation?.isHospitalized?.dischargeDate)}
                                                                    onChange={e => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isHospitalized: { dischargeDate: new Date(e.target.value), yes: false } } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={4}><h6 className="fw-500" style={{ margin: "0.2rem",fontSize: "12px"  }}>Is this member pregnant ?</h6></Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isPregnant"
                                                    checked={formData?.memberInformation?.isPregnant?.yes === true}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: { yes: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>Yes</label>
                                            </div>
                                        </Col>
                                        <Col xl={5}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="isPregnant"
                                                    checked={formData?.memberInformation?.isPregnant?.yes === false}
                                                    onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: { yes: false } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>No</label>
                                            </div>
                                        </Col></Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="row">
                                                <div className="col">
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem" }}>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h6 className="fw-500" style={{ margin: "0.3rem",fontSize: "12px"  }}> If yes, what is this member’s due date? </h6>
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="date"
                                                                    disabled={formData?.memberInformation?.isPregnant?.yes !== true}
                                                                    value={formatDate(formData?.memberInformation?.isPregnant?.dueDate)}
                                                                    onChange={e => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: { dueDate: new Date(e.target.value), yes: true } } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prescriber Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Provider Name :</label>
                                        </Col>
                                        <Col xs="12" sm="10" >
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescriberInformation?.providerName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            providerName: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>NPI# :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescriberInformation?.npi || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            npi: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Speciality:</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.prescriberInformation?.specialty || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            specialty: e.target.value
                                                        }
                                                    })}
                                                ></textarea>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Office Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.prescriberInformation?.officePhone || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            prescriberInformation: {
                                                                ...formData?.prescriberInformation,
                                                                officePhone: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Office Fax :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.prescriberInformation?.officeFax || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            prescriberInformation: {
                                                                ...formData?.prescriberInformation,
                                                                officeFax: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label  style={{ fontSize: "12px" }}> Office Street Address :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.prescriberInformation?.officeAddress || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            officeAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="1" className="mb-2 mb-sm-0">
                                            <label  style={{ fontSize: "12px" }}>City :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescriberInformation?.city || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            city: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="1">
                                            <label  style={{ fontSize: "12px" }}>State :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescriberInformation?.state || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            state: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label  style={{ fontSize: "12px" }}>Zip code :</label>
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.prescriberInformation?.zipCode || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        prescriberInformation: {
                                                            ...formData?.prescriberInformation,
                                                            zipCode: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0", }}>Medication Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Medication :</label>
                                        </Col>
                                        <Col xs="12" sm="10" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.medicationInformation?.medication || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        medicationInformation: {
                                                            ...formData?.medicationInformation,
                                                            medication: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Directions for use:</label>
                                        </Col>
                                        <Col xs="12" sm="10" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.medicationInformation?.useDirections || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        medicationInformation: {
                                                            ...formData?.medicationInformation,
                                                            useDirections: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Strength :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.medicationInformation?.strength || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        medicationInformation: {
                                                            ...formData?.medicationInformation,
                                                            strength: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label  style={{ fontSize: "12px" }}>Quantity :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.medicationInformation?.quantity || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        medicationInformation: {
                                                            ...formData?.medicationInformation,
                                                            quantity: e.target.value
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xl={3}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem",fontSize: "12px"  }}>Medication Administered:</h6>
                                        </Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="medicationAdministered"
                                                    checked={formData?.medicationInformation?.medicationAdministered?.selfAdministered === true}
                                                    onClick={() => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { selfAdministered: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>Self-Administered</label>
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="medicationAdministered"
                                                    checked={formData?.medicationInformation?.medicationAdministered?.physicianOffice === true}
                                                    onClick={() => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { physicianOffice: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>Physician’s Office</label>
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                            <div className="col-auto  d-flex">
                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="medicationAdministered"
                                                    checked={formData?.medicationInformation?.medicationAdministered?.other === true}
                                                    onClick={() => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { other: true } } })}
                                                />
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem",fontSize: "12px"  }}>Other</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    {formData?.medicationInformation?.medicationAdministered?.other && (
                                        <Row>
                                            <Col xl={12}>
                                                <div className="row">
                                                    <div className="col">
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem", marginTop: "0.4rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem",fontSize: "12px"  }}> If Other ? </h6>
                                                                </div>
                                                                <div className="col">
                                                                    <input
                                                                        style={{
                                                                            width: "100%",
                                                                            border: "none",
                                                                            marginTop: "0.1rem",
                                                                            backgroundColor: "unset",
                                                                        }}
                                                                        type="text"
                                                                        value={formData?.medicationInformation?.medicationAdministered?.otherDescription || ""}
                                                                        onChange={e => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { otherDescription: e.target.value, other: true } } })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Clinical Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Card style={{ padding: '1rem', backgroundColor: '#FFFFFF', border: '1px solid #D3D3D3', marginBottom: '0.5rem' }}>
                                        <Row className="align-items-center">
                                            <Col xs="12" sm="12" >
                                                <label  style={{ fontSize: "12px" }}>What is the patient’s diagnosis for the medication being requested? </label>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mb-2">
                                            <Col xs="12" sm="12" className="mb-2 mb-sm-0">
                                                <div className="d-flex align-items-center">
                                                    <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                        value={formData?.clinicalInformation?.diagnosisForMedication || ""}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            clinicalInformation: {
                                                                ...formData?.clinicalInformation,
                                                                diagnosisForMedication: e.target.value
                                                            }
                                                        })} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mb-2">
                                            <Col xs="12" sm="3">
                                                <label style={{ fontSize: "12px" }}>ICD-10 Code(s) :</label>
                                            </Col>
                                            <Col xs="12" sm="9">
                                                <div className="d-flex align-items-center">
                                                    <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                        value={formData?.clinicalInformation?.icdCode || ""}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            clinicalInformation: {
                                                                ...formData?.clinicalInformation,
                                                                icdCode: e.target.value
                                                            }
                                                        })} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Row>

                                        <Card className="match-card-body p-3">
                                            <Row><h6 style={{ fontWeight: "600", textDecoration: "underline" }}>Please refer to the patient’s PDL at <a href="https://www.uhcprovider.com" target="_blank" rel="noreferrer noopener">www.uhcprovider.com</a> for a list of preferred alternatives</h6></Row>
                                            <Card className="bg-light-gray" style={{ height: "275px", marginBottom: "0.5rem" }}>
                                                <div style={{ margin: "0.2rem" }}>
                                                    <h6 style={{ fontWeight: "600", display: "inline" }}>What medication(s) does the patient have a history of failure to ?</h6>
                                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                                        (Please specify ALL medication(s)/strengths tried, directions,
                                                        length of trial, and reason for discontinuation of each medication)
                                                    </span>
                                                </div>
                                                <div className="editor-area ck-content"
                                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                                    <CKEditor
                                                        editor={Editor}
                                                        id="floatingTextarea"
                                                        config={{
                                                            height: '100%',
                                                            toolbarCanCollapse: true,
                                                        }}
                                                        data={formData?.clinicalInformation?.medicationHistoryOfFailure}
                                                        onChange={(event: any, editor: any) => {
                                                            const data = editor.getData();
                                                            setFormData(prevState => ({
                                                                ...prevState,
                                                                clinicalInformation: {
                                                                    ...prevState?.clinicalInformation,
                                                                    medicationHistoryOfFailure: data
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </Card>
                                            <Card className="bg-light-gray" style={{ height: "275px", marginBottom: "0.5rem" }}>
                                                <div style={{ margin: "0.2rem" }}>
                                                    <h6 style={{ fontWeight: "600", display: "inline" }}>What medication(s) does the patient have a contraindication or intolerance to ? </h6>
                                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                                        (Please specify ALL medication(s) with the
                                                        associated contraindication to or specific issues resulting in intolerance to each medication)
                                                    </span>
                                                </div>
                                                <div className="editor-area ck-content"
                                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                                    <CKEditor
                                                        editor={Editor}
                                                        id="floatingTextarea"
                                                        data={formData?.clinicalInformation?.medicationContraindication}
                                                        onChange={(event: any, editor: any) => {
                                                            const data = editor.getData();
                                                            setFormData(prevState => ({
                                                                ...prevState,
                                                                clinicalInformation: {
                                                                    ...prevState?.clinicalInformation,
                                                                    medicationContraindication: data
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </Card>
                                            <Card className="bg-light-gray" style={{ height: "275px", marginBottom: "0.5rem" }}>
                                                <div style={{ margin: "0.2rem" }}>
                                                    <h6 style={{ fontWeight: "600", display: "inline" }}>Are there any supporting laboratory or test results related to the patient’s diagnosis? </h6>
                                                    <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                                        (Please specify or provide
                                                        documentation)
                                                    </span>
                                                </div>
                                                <div className="editor-area ck-content"
                                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                                    <CKEditor
                                                        editor={Editor}
                                                        id="floatingTextarea"
                                                        data={formData?.clinicalInformation?.labResults}
                                                        onChange={(event: any, editor: any) => {
                                                            const data = editor.getData();
                                                            setFormData(prevState => ({
                                                                ...prevState,
                                                                clinicalInformation: {
                                                                    ...prevState?.clinicalInformation,
                                                                    labResults: data
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </Card>
                                        </Card>
                                    </Row>

                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Additional Information That May Be Important For This Review</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">

                                    <div className="editor-area ck-content"
                                        style={{ height: "250px", marginTop: "0", overflowY: "auto" }}>
                                        <CKEditor
                                            editor={Editor}
                                            id="floatingTextarea"
                                            data={formData?.additionalInformation}
                                            onChange={(event: any, editor: any) => {
                                                const data = editor.getData();
                                                setFormData(prevState => ({
                                                    ...prevState,
                                                    additionalInformation: data
                                                  }));
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row className="align-items-center mb-2 ">
                    <Col xs="12" sm="4">
                        <div className="d-flex flex-column align-items-center">
                            <div className="sign">
                                <div className='sigCanvasNewDiv'>
                                    {clinicianSign ? (
                                        <img className="sigCanvasNew" src={formData?.signature} />
                                    ) : (
                                        <SignaturePad
                                        backgroundColor="rgb(245,242,241)"
                                        ref={signPad}
                                        penColor="black"
                                        canvasProps={{ className: "sigCanvasNew" }}
                                        ></SignaturePad>
                                    )}
                                </div>
                            </div>
                            {!clinicianSign ? (
                                <div className="d-flex justify-content-between mt-3">
                                    <div>
                                        <a onClick={() => { clearsign(); }} className="btn btn-primary btn-sm mr-1">Clear</a>
                                    </div>
                                    <div>
                                        <a onClick={() => { saveSign(); }} className="btn btn-primary btn-sm">Save</a>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <a onClick={() => editSign()} className="btn btn-primary btn-sm mt-2">Edit</a>
                                </div>
                            )}
                            <label style={{ marginTop: "0.5rem",fontSize: "12px"  }}>Provider Signature </label>
                        </div>
                    </Col>
                    <Col xs="12" sm="4">
                    </Col>
                    <Col xs="12" sm="4">
                        <div className="d-flex flex-column align-items-center">
                            <Input
                                type="date"
                                style={{
                                    fontSize: "0.70rem",
                                    lineHeight: "1",
                                    border: "none",
                                    borderBottom: "1px solid #000",
                                    borderRadius: "0",
                                    backgroundColor: "transparent"
                                }}
                                value={formatDate(formData?.date)}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    date: new Date(e.target.value) 
                                })}
                            />
                            <label style={{ marginTop: "0.5rem",fontSize: "12px"  }}>Date </label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="card-bodyDiv" style={{ width: '100%' }}>
                        <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                <p style={{ margin: "0" }}>
                                    <strong>Confidentiality Notice:</strong> This transmission contains confidential information belonging to the sender and UnitedHealthcare. This
                                    information is intended only for the use of UnitedHealthcare. If you are not the intended recipient, you are hereby notified that any
                                    disclosure, copying, distribution or action involving the contents of this document is prohibited. If you have received this telecopy in
                                    error, please notify the sender immediately.
                                </p>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={saveUnitedHealthCareAuthForm}
                            disabled={isSaving}
                        >
                            {isSaving ? "Saving..." : "Save"}
                        </button>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default UnitedHealthCareForm;