import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  ButtonGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Modal,
  ModalBody,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Client, PremiumStatus } from "../../models/Client";
import { UserRole, GenderTypes, UserStatus, InsuranceActiveStatus, SubscriptionActiveStatus, CopaymentStatus } from "../../models/User";
import { SubscriptionStatus } from "../../models/CardTypes";
import moment from "moment";
import { SocketContext } from "../../context/ScoketContext";
import { NotificationEvent, NotificationModel, NotificationVarient } from "../../models/Notification";
import UserContext from "../../context/UserContext";
import { NotificationService } from "../../services/NotificationService";
import Spinner from "../../common/spinner/spinner";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";
import pending from "../../assets/images/icons/pending.svg";
import completed from "../../assets/images/icons/completed.svg";
import rejected from "../../assets/images/icons/rejected.svg";
import view from "../../assets/images/icons/list-view.svg";
import Close from "../../assets/images/icons/close.png";
import Select from "react-select";
import { AppResponse } from "src/models/Response";
import { Therapist } from "src/models/Therapist";
import AppointmentModal from "src/pages/Popup/AdminStatModals/ScheduleAppointmentModal";
import MatchModal from "src/pages/Popup/AdminStatModals/matchModal";
import ViewClientInsurance from "./AdminViewClientComponents/viewClientInsuarance";
import AddClientInsurance from "./AdminViewClientComponents/addClientInsuarance";
import ViewSingleClientReminderSms from "./AdminViewClientComponents/viewSingleClientReminderSms";
import ViewClientProfile from "./AdminViewClientComponents/viewClientProfile";
import ClientSessionsView from "./AdminViewClientComponents/viewClientSession";
import ProfileInsuranceDetails from "../Profile/InsuranceProfile";
import NotificationContext from "src/context/NotificationContext";
import { Role } from "src/models/Role";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { MeetingData } from "src/models/MeetingData";
import ClientDiagnosisCodeViewComp from "./ClientView/ClientDiagnosisCodeViewCom";
import UnmatchTherapistModal from "../Popup/AdminStatModals/unmatchTherapistModal";

const ViewAllClients: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [userCount, setUserCount] = useState(10);
  const [clientOffset, setClientOffset] = useState(1);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isGender, setIsGender] = useState(GenderTypes.ALL as GenderTypes);
  const [isSubscription, setSubscription] = useState(SubscriptionStatus.ALL as SubscriptionStatus);
  const [states, setStates] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [insuranceCompanies, setInsuranceCompanies] = useState<Array<{_id: string, insuranceCompany: string}>>([]);
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState('');
  const [copaymentSetAmount, setClientCopaymentAmount] = useState({ copaymentAmount: Number as any });
  const [isOpenCopaymentAmountModal, setIsOpenCopaymentAmountModal] = useState(false);
  const [isOpenClientReviewModal, setIsOpenClientReviewModal] = useState(false);
  const [error, setError] = useState(false);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [status, setStatus] = useState("");
  const socket = useContext(SocketContext);
  const [user] = useContext(UserContext);
  const [isOpenBlockReasonModal, setIsOpenBlockReasonModal] = useState(false)
  const [isOpenViewInsuarance, setIsOpenViewInsuarance] = useState(false)
  const [isOpenAddInsuarance, setIsOpenAddInsuarance] = useState(false)
  const [isOpenInsuarance, setIsOpenInsuarance] = useState(false)
  const [userId, setUserId] = useState<string>()
  const [reason, setReason] = useState({ sendReason: "" });
  const [reasonError, setReasonError] = useState(false);
  const [moreOptionsdropdownOpen, setMoreOptionsDropdownOpen] = useState(false);
  const [moreOptionClientId, setMoreOptionClientId] = useState('')
  const [selectedTherapist, setSelectedTherapist] = useState('')
  const [appointmentStatus, setAppointmentStatus] = useState('')
  const [TherapistOptions, setTherapistOptions] = useState([{ value: '', label: 'All' },])
  const [activeClient, setActiveClient] = useState('')
  const [clientData, setClientData] = useState<Client>({} as Client);
  const [modalOpenSession, setModalOpenSession] = useState(false);
  const [modalOpenMatch, setModalOpenMatch] = useState(false);
  const [isCopaymentAdded, setIsCopaymentAdded] = useState(false);
  const [clickedClientId, setClickedClientId] = useState<any>();
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(0);
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const [clickedClientData, setClickedClientData] = useState<Client>();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isUpdateReviewMode, setIsUpdateReviewMode] = useState(false);
  const [notificationDeatils, setNotificationDeatils, selectedClientsIdForChat, setSelectedClientsIdForChat, selectedClientsDetailsForChat, setSelectedClientsDetailsForChat] = useContext(NotificationContext);
  const [reviewUser, setReviewUser] = useState<Client>();
  const [reviewData, setReviewData] = useState({ reviewMessage: "" });
  const [rating, setRating] = useState(0);
  const [isOpenSessionView, setIsOpenSessionView] = useState(false);
  const [recentSessions, setRecentSessions] = useState([] as MeetingData[]);

  const [insuranceActiveStatus, setInsuranceActiveStatus] = useState("");
  const [copaymentStatus, setCopaymentStatus] = useState("");
  const [modalOpenUnmatch, setModalOpenUnmatch] = useState(false);

  const handleReviewMessage = (e: { target: { value: any; }; }) => {
    setReviewData({ reviewMessage: e.target.value });
  };

  useEffect(() => {
    searchClients();
    getAllTherapist();
    getAllStates();
    getAllInsuranceCompanies();
  }, []);

  useEffect(() => {
    setIsCopaymentAdded(!!copaymentSetAmount.copaymentAmount);
  }, [isOpenCopaymentAmountModal]);

  const getAllStates = () => {
    AdminService.getAllClientStates().then(res => {
      if (res.success) {
        setStates(res.data);
      }
    });
  };

  const getAllInsuranceCompanies = () => {
    AdminService.getAllClientInsuranceCompanies().then(res => {
      if (res.success) {
        setInsuranceCompanies(res.data);
      }
    });
  };

  const searchClients = () => {
    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: status,
      isSubscription: isSubscription,
      zipCode: zipCode,
      state: selectedState,
      insuranceCompanyId: selectedInsuranceCompany,
      role: UserRole.CLIENT,
      therapistId: selectedTherapist,
      appointmentStatus: appointmentStatus,
      clientActiveStatus: activeClient,
      insuranceActiveStatus: insuranceActiveStatus,
      copaymentStatus: copaymentStatus,
    };

    setIsLoading(true);

    AdminService.searchClients(data, limit, clientOffset).then(res => {
      if (res.success) {
        setClients(res.data?.userSet);
        setUserCount(res.data?.userSet.length);
      }

      setIsLoading(false);
    });
  };

  const filterClients = () => {
    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: status,
      isSubscription: isSubscription,
      zipCode: zipCode,
      state: selectedState,
      insuranceCompanyId: selectedInsuranceCompany,
      role: UserRole.CLIENT,
      therapistId: selectedTherapist,
      appointmentStatus: appointmentStatus,
      clientActiveStatus: activeClient,
      insuranceActiveStatus: insuranceActiveStatus,
      copaymentStatus: copaymentStatus,
    };

    setIsLoading(true);

    const updatedOfsetSkip = 1;
    setClientOffset(updatedOfsetSkip);

    AdminService.searchClients(data, limit, 1).then(res => {
      if (res.success) {
        setClients(res.data?.userSet);
        setUserCount(res.data?.userSet.length);
      }else{
        toast.error(`Error loading data`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }

      setIsLoading(false);
      toggleFilter();
    });
  };

  const handleCoPaymentAmountChange = (value: string) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      const amount = parseFloat(value);
      if (!isNaN(amount) && amount >= 0 && amount <= 10000) {
        setClientCopaymentAmount({ copaymentAmount: value });
      }
    }
  };

  const toggleMoreOptionDropdown = (therapistId: any) => {
    setMoreOptionClientId(therapistId);
    setMoreOptionsDropdownOpen(prevState => !prevState);
  };

  const navigateToProfile = (userId: string | undefined) => {
    // if (userId != undefined) history.push(`/profile/${userId}`);
    if (userId != undefined) {
      setSelectedClientId(userId)
      setCurrentPageComponents(2)
    }
  };

  const navigateToInsurance = (userId: string | undefined) => {
    // toggleViewInsuaranceModal()
    if (userId != undefined) {
      setClickedClientId(userId)
      setIsOpenViewInsuarance(true)
    }
    // if (userId != undefined) history.push(`/insurance/${userId}`);
  };

  const insuranceDetails = (userData: Client | undefined) => {
    if (userData != undefined) {
      setClickedClientData(userData)
      setIsOpenInsuarance(true)
    }
  };

  const sessionDetails = (client: Client | undefined, therapistId: any | undefined) => {
    if (client != undefined && therapistId != undefined) {
      try {
        setIsLoading(true);
        AdminService.getRecentSessionsForClient(client._id, therapistId).then(res => {
          if (res && res.success) {
            setRecentSessions(res.data);
            setIsLoading(false);
          } else {
            toast.error(`Encountered an error while retrieving the recent session details for this client!`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
            setIsLoading(false);
          }
        });
      } catch (error) {
        toast.error("Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
      setIsOpenSessionView(true);
    }
  };

  const updateClientCoAmount = (userData: Client | undefined) => {
    if (userData != undefined) {
      setClickedClientData(userData)
      setIsOpenInsuarance(true)
    }
  };


  const navigateToAddInsurance = (userId: string | undefined) => {
    if (userId != undefined) {
      setClickedClientId(userId)
      setIsOpenAddInsuarance(true)
    }

    // if (userId != undefined) history.push(`/add-insurance-by-admin/${userId}`);
  };
  const toggleReasonModal = () => {
    setIsOpenBlockReasonModal(!isOpenBlockReasonModal)
  }
  const toggleViewInsuaranceModal = () => {
    setClickedClientId(null)
    setIsOpenViewInsuarance(!isOpenViewInsuarance)
  }
  const toggleAddModal = () => {
    setClickedClientId(null)
    setIsOpenAddInsuarance(!isOpenAddInsuarance)
  }

  const toggleModal = () => {
    setClickedClientId(null)
    setIsOpenInsuarance(!isOpenInsuarance)
  }

  const toggleModalForViewSession = () => {
    setRecentSessions([])
    setIsOpenSessionView(!isOpenSessionView)
  }

  const blockUserWithReason = (userId: string | undefined) => {
    setReasonError(false);
    setUserId(userId);
    setIsOpenBlockReasonModal(true);
  };
  const blockUser = (userId: string | undefined) => {
    setReasonError(false);
    if (userId && reason.sendReason && reason.sendReason.length > 0) {
      // Swal.fire({
      //   icon: "warning",
      //   title: "Are you sure you want to block?",
      //   showCancelButton: true,
      //   confirmButtonText: "Block",
      //   confirmButtonColor: "#FD7F00",
      //   cancelButtonColor: "#FD7F00",
      // }).then(result => {
      //   if (result.isConfirmed) {
      const data = {
        userId: userId,
        reasonToBlock: reason.sendReason,
      };
      AdminService.blockUserByAdmin(data).then(res => {
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "User blocked!",
            confirmButtonColor: "#FD7F00",
          });

          const clientKey = clients.findIndex((data: any) => data?.client?._id === userId);

          if (clientKey !== -1) {
            const previousClient = { ...clients[clientKey] };
            const updatedClient = {
              ...previousClient,
              client: { ...previousClient.client, blockedByAdmin: true },
            };
            clients[clientKey] = updatedClient;
          }
          setClients(clients);
          toggleReasonModal();
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to block the user.",
            confirmButtonColor: "#FD7F00",
          });
        }
      });
      //   }
      // });
    } else {
      setReasonError(true);
    }
  };

  const unblockUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to unblock?",
        showCancelButton: true,
        confirmButtonText: "Unblock",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            userId: userId,
            reasonToBlock: reason.sendReason,
          };
          AdminService.unblockUserByAdmin(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User unblocked!",
                confirmButtonColor: "#FD7F00",
              });
              const clientKey = clients.findIndex((data: any) => data?.client?._id === userId);

              if (clientKey !== -1) {
                const previousClient = { ...clients[clientKey] };
                const updatedClient = {
                  ...previousClient,
                  client: { ...previousClient.client, blockedByAdmin: false },
                };
                clients[clientKey] = updatedClient;
              }
              setClients(clients);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to unblock the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const deleteUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          AdminService.deleteUser(userId).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User deleted!",
                confirmButtonColor: "#FD7F00",
              });
              const updatedClientList = clients.filter((data: any) => data?.client?._id !== userId);
              setClients(updatedClientList);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to delete the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };

  const togglePremiumMembership = (userId: string | undefined) => {
    if (userId) {
      const clientKey = clients.findIndex((data: any) => data?.client?._id == userId);
      const previousClient = { ...clients[clientKey] };

      if (
        (previousClient.premiumStatus == null ||
          previousClient.premiumStatus == PremiumStatus.REVOKED ||
          previousClient.premiumStatus == PremiumStatus.NOT_SET) &&
        previousClient.insuranceId !== undefined
      ) {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to set this client a premium user?",
          showCancelButton: true,
          confirmButtonText: "Sure",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
        }).then(result => {
          if (result.isConfirmed) {
            AdminService.togglePremiumMembership(userId).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "Membership upgraded!",
                  confirmButtonColor: "#FD7F00",
                });

                const updatedClient = { ...previousClient, premiumStatus: PremiumStatus.ACTIVE };
                clients[clientKey] = updatedClient;
                setClients(clients);

                const PremiumUserNotification: NotificationModel = {
                  senderId: user?._id,
                  receiverId: userId,
                  event: NotificationEvent.BECAME_PREMIUM,
                  link: "/payment-history/" + userId,
                  content: "Congratulations! You have been granted premium user membership.",
                  variant: NotificationVarient.SUCCESS,
                };

                NotificationService.sendNotification(PremiumUserNotification).then(res => {
                  const socketData: sendNotificationSocketData = {
                    socketId: res.data.socketId,
                    notifyData: PremiumUserNotification,
                    senderId: user?._id,
                    receiverId: userId,
                  };

                  socket.emit("send-notification", socketData);
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Failed upgrade the membership",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to set this client a generic user?",
          showCancelButton: true,
          confirmButtonText: "Sure",
          confirmButtonColor: "#FD7F00",
          cancelButtonColor: "#FD7F00",
        }).then(result => {
          if (result.isConfirmed) {
            AdminService.togglePremiumMembership(userId).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: "Membership downgraded!",
                  confirmButtonColor: "#FD7F00",
                });

                const updatedClient = { ...previousClient, premiumStatus: PremiumStatus.REVOKED };
                clients[clientKey] = updatedClient;
                setClients(clients);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Failed downgrade the membership",
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        });
      }
    }
  };

  const seeMore = () => {
    setIsLoading(true);

    const updatedOffset = clientOffset + 1;
    setClientOffset(updatedOffset);

    const data = {
      searchableString: isSearchableString,
      gender: isGender,
      status: status,
      isSubscription: isSubscription,
      zipCode: zipCode,
      state: selectedState,
      insuranceCompanyId: selectedInsuranceCompany,
      role: UserRole.CLIENT,
      therapistId: selectedTherapist,
      appointmentStatus: appointmentStatus,
      clientActiveStatus: activeClient,
      insuranceActiveStatus: insuranceActiveStatus,
      copaymentStatus: copaymentStatus,
    };

    AdminService.searchClients(data, 10, updatedOffset).then(res => {
      if (res.success) {
        res.data.userSet.map((data: any) => {
          setClients(clients => [...clients, data]);
        });

        setUserCount(res.data?.userSet.length);
      }

      setIsLoading(false);
    });
  };

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  };


  const changeUnreadMessageCount = (clientIndex:number) => {
    try {
      if(clients != null && clients.length != null && clientIndex != null && clientIndex >= 0 && clientIndex < clients.length){
        const updatedArray = clients;
        updatedArray[clientIndex].unreadTwilioMessageCount = 0;
        setClients(updatedArray);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    if (value === '') {
      setClientCopaymentAmount({ ...copaymentSetAmount, copaymentAmount: null });
    } else if (Number(value) >= 0 && Number(value) <= 9999) {
      setClientCopaymentAmount({ ...copaymentSetAmount, copaymentAmount: Number(value) });
    }
  };

  const clientReviewModal = (client: Client) => {
    try {
      setIsLoading(true);
      AdminService.getExistingReviewByClientId(client._id).then(res => {
        if (res && res.success) {
          setReviewData(res.data);
          setRating(Number(res.data?.ratingValue));
          setIsUpdateReviewMode(res.data !== null);
          setIsLoading(false);
        } else {
          toast.error(`Encountered an error while retrieving the review details for this user!`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
          setIsLoading(false);
        }
      });
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    setUserId(client._id);
    setReviewUser(client);
    setIsOpenClientReviewModal(true);
  };

  const toggleClientReviewModal = () => {
    setIsOpenClientReviewModal(!isOpenClientReviewModal);
    setRating(0);
  };

  const submitClientReview = () => {
    const data = {
      clientId: userId,
      reviewMessage: reviewData?.reviewMessage,
      ratingValue: rating,
    };

    AdminService.reviewSubmissionByAdminOnBehalfOfClient(data).then(res => {
      if (res && res.success) {
        Swal.fire({
          icon: "success",
          title: "Review Successfully Submitted!",
          confirmButtonColor: "#FD7F00",
        });
        setIsOpenClientReviewModal(false);
      } else {
        Swal.fire({
          icon: "error",
          title: res.error,
          confirmButtonColor: "#FD7F00",
        });
      }
    });
  };

  const updateClaimAmountModal = (client: Client) => {
    setClientCopaymentAmount({ ...copaymentSetAmount, copaymentAmount: client?.copaymentAmount });
    setUserId(client._id);
    setIsUpdateMode(!!client?.copaymentAmount);
    setIsOpenCopaymentAmountModal(true);
  };

  const toggleCopaymentAmountModal = () => {
    setIsOpenCopaymentAmountModal(!isOpenCopaymentAmountModal);
  };
  const updateClientCopayment = () => {
    setError(false);
    const data = {
      clientId: userId,
      copaymentAmount: copaymentSetAmount?.copaymentAmount ? copaymentSetAmount?.copaymentAmount : 0,
    };

    if (data?.copaymentAmount < 0) {
      setError(true);
    } else {
      AdminService.updateCopaymentByAdmin(data).then(res => {
        if (res.success) {
          const clientKey = clients.findIndex((data: any) => data?.client?._id === userId);
          const previousClient = { ...clients[clientKey] };
          const copaymentSet = previousClient.client.copaymentAmount !== null && previousClient.client.copaymentAmount !== undefined;
          const updatedClient = {
            ...previousClient,
            client: { ...previousClient.client, copaymentAmount: copaymentSetAmount?.copaymentAmount },
          };
          clients[clientKey] = updatedClient;
          setClients([...clients]);
          const popupMessage = copaymentSet ? "Successfully updated!" : "Successfully added!";

          Swal.fire({
            icon: "success",
            title: popupMessage,
            confirmButtonColor: "#FD7F00",
          });
          setIsOpenCopaymentAmountModal(false);
        } else {
          Swal.fire({
            icon: "error",
            title: res.error,
            confirmButtonColor: "#FD7F00",
          });
        }
      });
    }
  };

  const navigateToClientSessions = (clientId: string | undefined) => {
    // if (clientId) history.push(`/client_sessions/${clientId}`)
    if (clientId != undefined) {
      setSelectedClientId(clientId)
      setCurrentPageComponents(3)
    }
  }

  const getAllTherapist = () => {
    AdminService.getAllTherapistsList().then((res: AppResponse<Therapist[]>) => {
      if (res.success) {
        const dataList = res.data?.map((therapist: any) => ({
          value: therapist._id,
          label: `${therapist.firstname} ${therapist.lastname}`,
        }));
        TherapistOptions.push(...dataList);
      }
    });
  };

  const handleChange = (selectedOption: any) => {
    setSelectedTherapist(selectedOption.value);
  };

  const customStyles = {
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '170px',
      overflowY: 'auto'
    })
  };

  const viewReminderSms = (client: Client) => {
    // history.push(`/client-reminder-sms/${client?._id}`);

    if (client?._id != undefined) {
      setCurrentPageComponents(1)
      setSelectedClientId(client?._id)
    }
  }

  const toggleModalSession = () => {
    setModalOpenSession(!modalOpenSession);
  };

  const toggleModalMatch = () => {
    setModalOpenMatch(!modalOpenMatch);
  };

  const handleInsuranceAdded = (insuranceInfo: any) => {
    const clientKey = clients?.findIndex((client: Client) => client?.client?._id == insuranceInfo?.clientId);
    if (clientKey !== -1) {
      const updatedClients = [...clients];
      const updatedClientData = {
        ...updatedClients[clientKey].client,
        insuranceId: insuranceInfo?.insuaranceId,
      };
      updatedClients[clientKey].client = updatedClientData;
      setClients(updatedClients);
    }
    toggleAddModal();
  };

  const handleCopaymentAmount = (copaymentData: any) => {
    const clientKey = clients?.findIndex((client: Client) => client?.client?._id == copaymentData?.clientId);
    if (clientKey !== -1) {
      const updatedClients = [...clients];
      const updatedClientData = {
        ...updatedClients[clientKey].client,
        copaymentAmount: copaymentData?.copaymentAmount,
      };
      updatedClients[clientKey].client = updatedClientData;
      setClients(updatedClients);
    }
  };


  const callBackPage = () => {
    setCurrentPageComponents(0)
  }

  const checkEligibilityByClientId = (clientId: any) => {
    AdminService.checkEligibilty(clientId).then(res => {
      if (res.success) {
        Swal.fire({
          icon: "success",
          title: 'Success!',
          html: `
          <p>Co-Payment Amount: ${res.data.coPaymentValue}</p>
          <p>Eligibility check successful!</p>
          `,
          confirmButtonColor: "#FD7F00",
        });

        const clientKey = clients?.findIndex((client: Client) => client?.client?._id == clientId);
        if (clientKey !== -1) {
          const updatedClients = [...clients];
          const updatedClientData = {
            ...updatedClients[clientKey].client,
            claimEligibilityDetails: res.data,
          };
          updatedClients[clientKey].client = updatedClientData;
          setClients(updatedClients);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: res.error,
          confirmButtonColor: "#FD7F00",
        });
      }
    });
  };


  const checkEligibilityByClientIdMd = (clientId: any) => {
    AdminService.checkEligibiltyMd(clientId).then(res => {
      if (res.success) {
        Swal.fire({
          icon: "success",
          title: 'Success!',
          html: `
          <p>Eligibility MD check successful!</p>
          `,
          confirmButtonColor: "#FD7F00",
        });
        const clientKey = clients?.findIndex((client: Client) => client?.client?._id == clientId);
        if (clientKey !== -1) {
          const updatedClients = [...clients];
          const updatedClientData = {
            ...updatedClients[clientKey].client,
            claimEligibilityMdDetails: res.data,
          };
          updatedClients[clientKey].client = updatedClientData;
          setClients(updatedClients);
        }
      } else {
        const clientKey = clients?.findIndex((client: Client) => client?.client?._id == clientId);
        if (clientKey !== -1) {
          const updatedClients = [...clients];
          const updatedClientData = {
            ...updatedClients[clientKey].client,
            claimEligibilityMdErrorDetails: res.error,
          };
          updatedClients[clientKey].client = updatedClientData;
          setClients(updatedClients);
        }
        Swal.fire({
          icon: "error",
          title: res.error,
          confirmButtonColor: "#FD7F00",
        });
      }
    });
  };


  const viewErrorMessage = (errorMsg: any) => {
    Swal.fire(errorMsg);
  }

  const toggleModalUnmatch = () => {
    setModalOpenUnmatch(!modalOpenUnmatch);
  };


  return (
    <>
      {
        currentPageComponents == 0 && (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs title={"View Clients"} breadcrumbItem={"View Clients"} />

                <Card className="m-h">
                  <CardBody>
                    <Row>
                      <Col className="d-flex admin-filter">
                        <button className="btn btn-sm mr-2" onClick={toggleFilter}>
                          Filter <i className="bx bx-filter-alt"></i>
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="mt10">
                        {clients !== undefined && clients.length > 0 ? (
                          <>
                            <div className="table-responsive" style={{ minHeight: "70vh" }}>
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Upcoming Appointment</th>
                                    <th scope="col">Past Appointment</th>
                                    <th scope="col">Therapist</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">D ICD 10 Codes</th>
                                    <th scope="col">State</th>
                                    <th scope="col">Skip</th>
                                    <th scope="col">Verified Status</th>
                                    <th scope="col">Active Status</th>
                                    <th scope="col">Subscription Status</th>
                                    <th scope="col">Copayment Amount</th>
                                    <th scope="col">Eligibility</th>
                                    <th scope="col">Insurance</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {clients &&
                                    clients.map((data: any, index: number) => (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>

                                        <td>
                                          {data?.client.firstname}&nbsp;{data?.client.lastname}
                                        </td>

                                        <td>{data?.client.gender}</td>

                                        <td>{data?.client.email}</td>

                                        <td>
                                          {data?.latestAppointmentDate?.start ? (
                                            moment(data?.latestAppointmentDate?.start).format("YYYY-MM-DD hh:mm:ss A")
                                          ) : (
                                            <span>-</span>
                                          )}
                                        </td>

                                        <td>
                                          {data?.pastAppointmentDate?.start ? (
                                            moment(data?.pastAppointmentDate?.start).format("YYYY-MM-DD hh:mm:ss A")
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <br />
                                          {data?.pastAppointmentDate?.start ? (
                                            <>
                                              {data?.pastAppointmentDate?.status == "PENDING" || data?.pastAppointmentDate?.status == "WAITING_FOR_APPROVAL" || data?.pastAppointmentDate?.status == "OVERDUE" ? (
                                                <>
                                                  {data?.missedJoinCaller &&
                                                    <>
                                                      {data?.missedJoinCaller} -
                                                      <img src={rejected} className="img-fluid imageupcomming" />
                                                      <br />
                                                    </>
                                                  }
                                                  <img src={pending} className="img-fluid imageupcomming" />
                                                </>
                                              ) : data?.pastAppointmentDate?.status == "REJECTED" ? (
                                                <img src={rejected} className="img-fluid imageupcomming" />
                                              ) : data?.pastAppointmentDate?.status == "COMPLETED" ? (
                                                <img src={completed} className="img-fluid imageupcomming" />
                                              ) : (
                                                <></>
                                              )}

                                              {(data?.pastAppointmentDate?.start ? (
                                                <>
                                                  &nbsp;<button
                                                    className="p-0"
                                                    onClick={() => {
                                                      sessionDetails(data?.client, data?.pastAppointmentDate?.therapistId);
                                                    }}
                                                    title="View Recent Sessions"
                                                  >
                                                    <img src={view} className="img-fluid imageupcomming" />
                                                  </button>
                                                </>
                                              ) : (<></>)
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </td>

                                        <td>
                                          {data?.latestClient ? data?.latestClient : <span>-</span>}
                                          {data?.latestClient?.therapistId?.lastname}
                                        </td>

                                        <td>{data?.client.primaryPhone}</td>

                                        <td>
                                            <ClientDiagnosisCodeViewComp diagnosisCodeForClient={data?.diagnosisCodeForClient ?? []} secondaryDiagnosisCodeForClient={data?.secondaryDiagnosisCodeForClient ?? []}></ClientDiagnosisCodeViewComp>
                                        </td>

                                        <td>
                                          <>{data?.client.state}</>
                                        </td>

                                        <td>
                                          <>{data?.client.skip == true ? <>True</> : <>False</>}</>
                                        </td>

                                        <td>
                                          {data?.client.verifiedStatus == "VERIFIED" ? (
                                            <span className="verified">Verified</span>
                                          ) : (
                                            <span className="pending">Pending</span>
                                          )}
                                        </td>
                                        <td>{data?.client?.clientActiveStatus == true ? "True" : data?.client?.clientActiveStatus == false ? "False" : "-"}</td>

                                        <td>
                                          {data?.client.premiumStatus == PremiumStatus.ACTIVE ? (
                                            <span className="premium">Premium</span>
                                          ) : (
                                            <>
                                              {(data?.client.subscriptionId != null && data?.client.subscriptionId != "") ||
                                                data?.client.testSubscriptionStatus == "active" ? (
                                                <>
                                                  {data?.client.subscriptionStatus == "active" || data?.client.testSubscriptionStatus == "active" ? (
                                                    <span className="subscribed">Subscribed</span>
                                                  ) : (
                                                    <span className="subscribed-not-active">Inactive</span>
                                                  )}
                                                </>
                                              ) : (
                                                <span className="normal">Normal</span>
                                              )}
                                            </>
                                          )}
                                        </td>

                                        <td>{(data?.client?.copaymentAmount != null && data?.client?.copaymentAmount != undefined) ? data?.client?.copaymentAmount : "-"}</td>

                                        <td>
                                          {data?.client.claimEligibilityMdDetails || data?.client.claimEligibilityDetails
                                            ? <td>
                                              <span className="verified-2">SUCCESS</span>
                                            </td>
                                            : data?.client.claimEligibilityMdErrorDetails
                                              ? <td>
                                                 <span className="inactive-2 cursor-pointer" onClick={() => viewErrorMessage(data?.client.claimEligibilityMdErrorDetails)} title={data?.client.claimEligibilityMdErrorDetails}>FAILED</span>
                                              </td>
                                              : <td>
                                                <span className="notSubmitted-2">NOT&nbsp;SUBMITTED</span>
                                              </td>
                                          }
                                        </td>

                                        <td>
                                          {data?.client.insuranceId !== undefined ? (
                                            <>
                                              <button
                                                className="btn btn-sm btn-success mr-2"
                                                onClick={() => {
                                                  insuranceDetails(data?.client);
                                                }}
                                                title="View Insurance"
                                              >
                                                View Insurance
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {data?.client.subscriptionId != null && data?.client.subscriptionId != "" || data?.client.testSubscriptionStatus == "active" ? (
                                                <>
                                                  {data?.client.subscriptionStatus == "active" || data?.client.testSubscriptionStatus == "active" ? (
                                                    <span>no insurance</span>
                                                  ) : (
                                                    <span>no insurance</span>
                                                  )}
                                                </>
                                              ) : (
                                                <button
                                                  className="btn btn-sm btn-warning mr-2"
                                                  onClick={() => {
                                                    insuranceDetails(data?.client);
                                                  }}
                                                  title="add Insurance"
                                                >
                                                  Add Insurance
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </td>

                                        <td>
                                          <div className="flex">
                                            <Dropdown
                                              isOpen={moreOptionsdropdownOpen && moreOptionClientId == data?.client?._id}
                                              toggle={() => toggleMoreOptionDropdown(data?.client?._id)}
                                            >
                                              <DropdownToggle caret className="btn-sm custom-toggle-admin">
                                                <i className="bx bx-dots-vertical-rounded custom-toggle-icon-admin"></i>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem>
                                                  <button
                                                    className="btn btn-sm btn-primary mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      navigateToProfile(data?.client._id);
                                                    }}
                                                    title="View Profile"
                                                  >
                                                    <i className="fa fa-user"></i> View Profile
                                                  </button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                  {data?.client.blockedByAdmin ? (
                                                    <button
                                                      className="btn-sm btn btn-success mr-2 minw-100-drop-item"
                                                      onClick={() => unblockUser(data?.client._id)}
                                                      title="Unblock Client"
                                                    >
                                                      <i className="fa fa-unlock"></i> Unblock Client
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn-sm btn btn-warning mr-2 minw-100-drop-item"
                                                      onClick={() => blockUserWithReason(data?.client._id)}
                                                      title="Block Client"
                                                    >
                                                      <i className="fa fa-ban"></i> Block Client
                                                    </button>
                                                  )}
                                                </DropdownItem>
                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-danger mr-2 minw-100-drop-item"
                                                    onClick={() => deleteUser(data?.client._id)}
                                                    title="Delete Client"
                                                  >
                                                    <i className="fa fa-trash"></i> Delete Client
                                                  </button>
                                                </DropdownItem>
                                                {data?.client.premiumStatus == PremiumStatus.ACTIVE && data?.client.insuranceId !== undefined ? (
                                                  <DropdownItem>
                                                    <button
                                                      className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                      style={{ background: "#2BDFBB", borderColor: "#2BDFBB" }}
                                                      onClick={() => togglePremiumMembership(data?.client._id)}
                                                      title="Make Regular User"
                                                    >
                                                      <i className="fa fa-undo"></i> Make Regular User
                                                    </button>
                                                  </DropdownItem>
                                                ) : (data?.client.premiumStatus == null ||
                                                  data?.client.premiumStatus == PremiumStatus.REVOKED ||
                                                  data?.client.premiumStatus == PremiumStatus.NOT_SET) &&
                                                  data?.client.insuranceId !== undefined ? (
                                                  <DropdownItem>
                                                    <button
                                                      className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                      onClick={() => togglePremiumMembership(data?.client._id)}
                                                      title="Make Premium User"
                                                    >
                                                      <i className="fa fa-star"></i> Make Premium User
                                                    </button>
                                                  </DropdownItem>
                                                ) : null}

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-insurance mr-2 minw-100-drop-item"
                                                    onClick={() => navigateToClientSessions(data?.client._id)}
                                                    title="View Client Sessions"
                                                  >
                                                    <i className="fa fa-calendar"></i> View Client Sessions
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                    onClick={() => updateClaimAmountModal(data?.client)}
                                                    title={data?.client.copaymentAmount ? "Update Co-payment" : "Add Co-payment"}
                                                  >
                                                    <i className="fa fa-dollar-sign"></i> {data?.client.copaymentAmount ? "Update Co-payment" : "Add Co-payment"}
                                                  </button>
                                                </DropdownItem>
                                                {/* <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-reminderSMS mr-2 minw-100-drop-item"
                                                    onClick={() => viewReminderSms(data?.client)}
                                                    title="View Reminder SMS"
                                                  >
                                                    <i className="fa fa-bell"></i> Reminder SMS
                                                  </button>
                                                </DropdownItem> */}
                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-insurance mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      toggleModalSession();
                                                      setClientData(data?.client);
                                                    }}
                                                    title="Schedule Appointment"
                                                  >
                                                    <i className="fa fa-calendar"></i> Schedule Appointment
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-danger mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      toggleModalMatch();
                                                      setClientData(data?.client);
                                                    }}
                                                    title="Match with clinician"
                                                  >
                                                    <i className="fa fa-calendar"></i> Match with clinician
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-success mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      checkEligibilityByClientId(data?.client?._id);
                                                    }}
                                                    title="Check Eligibility"
                                                  >
                                                    <i className="fa fa-check"></i> Check Eligibility
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-normal mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      checkEligibilityByClientIdMd(data?.client?._id);
                                                    }}
                                                    title="Check Eligibility MD"
                                                  >
                                                    <i className="fa fa-check"></i> Check Eligibility MD
                                                  </button>
                                                </DropdownItem>

                                                {user && user?.role && (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN) && (
                                                  <DropdownItem>
                                                    <button
                                                      className="btn-sm btn btn-client-chat mr-2 minw-100-drop-item"
                                                      onClick={() => {
                                                        try {
                                                          setSelectedClientsDetailsForChat(data && data.client ? (`${data.client.firstname ?? "Client"} ${data.client.lastname ?? "Client"} (${data.client.primaryPhone ?? "Unknown"})`):"");
                                                          setSelectedClientsIdForChat(data && data.client && data.client._id ? data.client._id : "");
                                                          changeUnreadMessageCount(index);
                                                        } catch (error) {

                                                        }
                                                      }}
                                                      title="Client Chat"
                                                    >
                                                      <i className="fa fa-comments"></i> Client Chat
                                                    </button>
                                                  </DropdownItem>
                                                )}

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                    onClick={() => clientReviewModal(data?.client)}
                                                    title="Client Review"
                                                  >
                                                    <i className="fa fa-sticky-note"></i> Client Review
                                                  </button>
                                                </DropdownItem>

                                                <DropdownItem>
                                                  <button
                                                    className="btn-sm btn btn-info mr-2 minw-100-drop-item"
                                                    onClick={() => {
                                                      toggleModalUnmatch();
                                                      setClientData(data?.client);
                                                    }}
                                                    title="Unmatch Therapist"
                                                  >
                                                    <i className="fa fa-user-friends"></i> Unmatch Therapist
                                                  </button>
                                                </DropdownItem>

                                              </DropdownMenu>
                                            </Dropdown>
                                            {data && data.unreadTwilioMessageCount &&  data.unreadTwilioMessageCount > 0 ? <span className="twilio-message-notify-dot d-flex justify-content-center align-items-center">
                                              <i className="fa fa-comments"/>
                                            </span>:<div></div>}
                                            {data?.client.lavniTestAccount && <span className="test-account ms-2 test-account-card">#Test Account</span>}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            {userCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                  {
                                    isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      : <>See More</>
                                  }
                                </button>
                              </>
                            )}
                            {userCount == 0 && <span>No more clients available...</span>}
                          </>
                        ) : (
                          <>{isLoading ? <Spinner /> : <div>No Clients</div>}</>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <AppointmentModal isOpen={modalOpenSession} toggle={toggleModalSession} setIsOpen={setModalOpenSession} clientData={clientData} />
                <MatchModal isOpen={modalOpenMatch} toggle={toggleModalMatch} clientData={clientData} />
                <Offcanvas className="offcanvas-end2" isOpen={isFilterOptions} direction="end" toggle={toggleFilter}>
                  <OffcanvasHeader toggle={toggleFilter}>Filter Clients</OffcanvasHeader>

                  <OffcanvasBody>
                    <Row className="mt-4">
                      <Col lg={12}>
                        <FormGroup>
                          <Label>Search by Name, Email or Phone</Label>
                          <Input
                            id="search"
                            name="text"
                            placeholder="Search Users (ex: first/last name, email or phone)"
                            type="text"
                            onChange={e => setIsSearchableString(e.target.value)}
                            value={isSearchableString}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <FormGroup>
                          <Label>Search by Therapist Name</Label>
                          <Select
                            styles={customStyles}
                            id="therapistName"
                            options={TherapistOptions}
                            onChange={handleChange}
                            value={TherapistOptions.find(option => option.value === selectedTherapist)}
                            placeholder="Select Status"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>Appointment</Label>
                        <FormGroup>
                          <Input id="exampleSelect" name="select" type="select" onChange={e => setAppointmentStatus(e.target.value)} value={appointmentStatus}>
                            <option value="ALL"> All </option>
                            <option value="UPCOMMING_APPOINTMENT"> Upcomming Appointments </option>
                            <option value="NO_UPCOMMING_APPOINTMENT"> No Upcomming Appointments </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>Insurance Company</Label>
                        <FormGroup>
                          <Input id="insuranceCompanySelect" name="select" type="select" onChange={e => setSelectedInsuranceCompany(e.target.value)} value={selectedInsuranceCompany}>
                            <option value="">All</option>
                            {insuranceCompanies.map((company) => (
                              <option key={company._id} value={company._id}>{company.insuranceCompany}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>State</Label>
                        <FormGroup>
                          <Input id="stateSelect" name="select" type="select" onChange={e => setSelectedState(e.target.value)} value={selectedState}>
                            <option value="">All</option>
                            {states.map((state, index) => (
                              <option key={index} value={state}>{state}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>Active Status</Label>
                        <FormGroup>
                          <Input id="exampleSelect" name="select" type="select" onChange={e => setActiveClient(e.target.value)} value={activeClient}>
                            <option value=""> All </option>
                            <option value="ACTIVE"> Active Clients </option>
                            <option value="INACTIVE"> Inactive Clients </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <FormGroup>
                          <Label>Search by Zip Code</Label>
                          <Input id="search" name="text" placeholder="Search ZipCode" type="text" value={zipCode} onChange={e => setZipCode(e.target.value)} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>Gender</Label>
                        <ButtonGroup className="flex-wrap flex w-100">
                          <Button
                            color="primary"
                            defaultChecked
                            defaultValue={GenderTypes.ALL}
                            outline
                            onClick={() => setIsGender(GenderTypes.ALL)}
                            active={isGender === GenderTypes.ALL}
                          >
                            All
                          </Button>
                          <Button color="primary" outline onClick={() => setIsGender(GenderTypes.MALE)} active={isGender === GenderTypes.MALE}>
                            Male
                          </Button>
                          <Button color="primary" outline onClick={() => setIsGender(GenderTypes.FEMALE)} active={isGender === GenderTypes.FEMALE}>
                            Female
                          </Button>
                          <Button color="primary" outline onClick={() => setIsGender(GenderTypes.NonBinary)} active={isGender === GenderTypes.NonBinary}>
                            Non Binary
                          </Button>
                          <Button color="primary" outline onClick={() => setIsGender(GenderTypes.OTHER)} active={isGender === GenderTypes.OTHER}>
                            Other
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <Label>Verified Status</Label>
                        <FormGroup>
                          <Input id="exampleSelect" name="select" type="select" onChange={e => setStatus(e.target.value)} value={status}>
                            <option value={UserStatus.ALL}> All </option>
                            <option value={UserStatus.VERIFIED}> Verified </option>
                            <option value={UserStatus.PENDING}> Pending </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>Subscription Status</Label>
                        <ButtonGroup className="w-100">
                          <Button
                            color="primary"
                            defaultChecked
                            defaultValue={SubscriptionStatus.ALL}
                            outline
                            onClick={() => setSubscription(SubscriptionStatus.ALL)}
                            active={isSubscription === SubscriptionStatus.ALL}
                          >
                            ALL
                          </Button>
                          <Button
                            color="primary"
                            outline
                            onClick={() => setSubscription(SubscriptionStatus.ACTIVE)}
                            active={isSubscription === SubscriptionStatus.ACTIVE}
                          >
                            Active
                          </Button>
                          <Button
                            color="primary"
                            outline
                            onClick={() => setSubscription(SubscriptionStatus.PAST_DUE)}
                            active={isSubscription === SubscriptionStatus.PAST_DUE}
                          >
                            Past due
                          </Button>
                          <Button
                            color="primary"
                            outline
                            onClick={() => setSubscription(SubscriptionStatus.CANCELED)}
                            active={isSubscription === SubscriptionStatus.CANCELED}
                          >
                            cancelled
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <Label>Insurance Availability</Label>
                        <FormGroup>
                          <Input id="exampleSelect" name="select" type="select" onChange={e => setInsuranceActiveStatus(e.target.value)} value={insuranceActiveStatus}>
                            <option value={InsuranceActiveStatus.ALL}> All </option>
                            <option value={InsuranceActiveStatus.WITH_INSURANCE}> With Insurance </option>
                            <option value={InsuranceActiveStatus.WITHOUT_INSURANCE}> Without Insurance </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={12}>
                        <Label>Copayment Status</Label>
                        <FormGroup>
                          <Input id="exampleSelect" name="select" type="select" onChange={e => setCopaymentStatus(e.target.value)} value={copaymentStatus}>
                            <option value={CopaymentStatus.ALL}> All </option>
                            <option value={CopaymentStatus.EXISTING}> With Copayment </option>
                            <option value={CopaymentStatus.GREATER}> {`With Copayment (> 0)`} </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col lg={12}>
                        <button className={`btn ${isLoading ? "btn-primary" : "btn-success"} w-100`} onClick={() => {
                            if(!isLoading){
                              filterClients();
                            }else{
                              toast.error(`Please wait !`, {
                                position: toast.POSITION.TOP_RIGHT,
                                className: "foo-bar",
                              });
                            }
                          }}
                        >
                          {isLoading ? "Loading..." : "Search"}
                        </button>
                      </Col>
                    </Row>

                  </OffcanvasBody>
                </Offcanvas>
              </Container>
            </div>
            <Modal isOpen={isOpenBlockReasonModal} toggle={toggleReasonModal} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleReasonModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Block Client </span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <p className="mb-1">Reason</p>
                    <textarea rows={4} style={{ width: "100%" }} placeholder="Type a reason" onChange={(e: any) => setReason({ ...reason, sendReason: e.target.value })}></textarea>
                    {
                      reasonError && <p className="text-danger f-10">Please enter reason</p>
                    }
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() => blockUser(userId)}>Block</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenCopaymentAmountModal} toggle={toggleCopaymentAmountModal} centered className="modal-md">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleCopaymentAmountModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center"> {isUpdateMode ? "Update Co-Payment Amount" : "Add Co-Payment Amount"}</span>
                </Row>

                <>
                  <Row className="mb-4">
                    <Col xl={4}>
                      <label>Co-Payment Amount($)</label>
                    </Col>
                    <Col xl={8}>
                      <Input
                        type="number"
                        name="value"
                        placeholder="Enter Value"
                        min="0"
                        max="9999"
                        onChange={handleInputChange}
                        value={copaymentSetAmount?.copaymentAmount === null ? '' : copaymentSetAmount?.copaymentAmount}
                      >
                      </Input>
                      {
                        error && !copaymentSetAmount?.copaymentAmount && (
                          <p className="text-danger fs-10-n">Please enter amount</p>
                        )
                      }
                    </Col>
                  </Row>
                </>

                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2"
                      onClick={() => updateClientCopayment()}
                    >{isUpdateMode ? "Update Amount" : "Add Amount"}</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenClientReviewModal} toggle={toggleClientReviewModal} centered className="modal-md">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleClientReviewModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center"> {isUpdateReviewMode ? "Update Client Review" : "Add Client Review"}</span>
                </Row>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Name</label>
                    </Col>
                    <Col xl={10}>
                      <Input
                        type="text"
                        className="form-control"
                        value={`${reviewUser?.firstname || 'Client'} ${reviewUser?.lastname || 'User'}`.trim()}
                        disabled
                      />
                    </Col>
                  </Row>
                </>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Email</label>
                    </Col>
                    <Col xl={10}>
                      <Input
                        type="text"
                        className="form-control"
                        value={reviewUser?.email}
                        disabled
                      />
                    </Col>
                  </Row>
                </>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Review<span className="text-danger">*</span></label>
                    </Col>
                    <Col xl={10}>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="formrow-firstname-Input"
                        style={{ height: "100%", minHeight: "160px" }}
                        placeholder="Write a review on behalf of client..."
                        onChange={handleReviewMessage}
                        value={reviewData?.reviewMessage || ""}
                        name="review"
                        required
                      />
                    </Col>
                  </Row>
                </>

                <>
                  <Row className="mb-4">
                    <Col xl={2}>
                      <label>Rate<span className="text-danger">*</span></label>
                    </Col>
                    <Col xl={10}>
                      <Rating initialValue={rating} onClick={(rate: any) => setRating(rate)} size={20} transition fillColor={"#F2C94C"} />
                    </Col>
                  </Row>
                </>
                <h6 className="req-field"><span className="text-danger">*</span> Indicates a required fields.</h6>

                <Row className="mt-3">
                  <Col className="md-8"></Col>
                  <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
                    <button className="btn btn-warning create-client-custom2 float-right mr-2"
                      onClick={() => submitClientReview()}
                    >{isUpdateReviewMode ? "Update Client Review" : "Add Client Review"}</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenViewInsuarance} toggle={toggleViewInsuaranceModal} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleViewInsuaranceModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">View Insuarance </span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <ViewClientInsurance userId={clickedClientId} />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenAddInsuarance} toggle={toggleAddModal} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleAddModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Add Insuarance </span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <AddClientInsurance userId={clickedClientId} onInsuranceAdded={handleInsuranceAdded} />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenInsuarance} toggle={toggleModal} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleModal}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Insuarance Details</span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <ProfileInsuranceDetails clientData={clickedClientData} onCopaymentAmount={handleCopaymentAmount} />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal isOpen={isOpenSessionView} toggle={toggleModalForViewSession} centered className="modal-lg">
              <div style={{ textAlign: "center", paddingRight: "0px" }}>
                <span></span>
                <Button
                  close
                  onClick={toggleModalForViewSession}
                  style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    padding: "0",
                    zIndex: "10",
                  }}
                  className="btn-s-20"
                >
                  <img src={Close} alt="Close" className="mt-1" />
                </Button>
              </div>
              <ModalBody className="ps-4 pe-4">
                <Row>
                  <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Most Recent Sessions</span>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {recentSessions !== undefined && recentSessions.length > 0 ? (
                      <div className="d-flex justify-content-center referal-link-btn">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Meeting Date</th>
                              <th scope="col">Meeting Duration</th>
                              <th scope="col">Spent Duration</th>
                              <th scope="col">Participant Count</th>
                              <th scope="col">Therapist</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentSessions.length > 0 &&
                              recentSessions.map((data: MeetingData, i: number) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data?.regularMeetingDate ? moment(data?.regularMeetingDate).format("YYYY-MM-DD hh:mm:ss A") : data?.createdAt ? moment(data?.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                    <td>{data.meetingDuration}</td>
                                    <td>{data?.spentDuration != null ? data?.spentDuration.toFixed(2) : "--"}</td>
                                    <td>{data?.participantCount}</td>
                                    <td>
                                      <Link to={"/profile/" + data.therapistId?._id}>
                                        {data?.therapistId?.firstname} {data?.therapistId?.lastname}
                                      </Link>
                                    </td>
                                    <td>
                                      <span className={data?.callingStatus + " session-h5"}>
                                        {data?.callingStatus}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>{isLoading ? <Spinner /> : <div>No recent sessions for this Client.</div>}</>
                    )}
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <UnmatchTherapistModal isOpen={modalOpenUnmatch} toggle={toggleModalUnmatch} clientData={clientData} />

          </React.Fragment>
        )
      }

      {
        currentPageComponents == 1 && (
          <ViewSingleClientReminderSms callBackPage={() => callBackPage()} selectedClientId={selectedClientId} />
        )
      }

      {
        currentPageComponents == 2 && (
          <ViewClientProfile callBackPage={() => callBackPage()} selectedClientId={selectedClientId} />
        )
      }

      {
        currentPageComponents == 3 && (

          <ClientSessionsView callBackPage={() => callBackPage()} userId={selectedClientId} />
        )
      }

    </>

  );
};

export default ViewAllClients;
