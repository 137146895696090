import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";

interface FolderModalProps {
  toggle: () => void;
  createFolder: (name: string) => void;
  modal: boolean;
}

const FolderModal: React.FC<FolderModalProps> = ({ toggle, modal, createFolder }) => {
  const [folderName, setFolderName] = useState("");
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create Folder</ModalHeader>
        <ModalBody>
          <Input placeholder="Folder Name" value={folderName} onChange={e => setFolderName(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=> {toggle(); setFolderName("")}}>
            Cancel
          </Button>{" "}
          <Button color="secondary" onClick={() => {createFolder(folderName);setFolderName("") }}>
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FolderModal;
