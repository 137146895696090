import React, { useEffect, useState } from "react";
import "./css/ClientDetails.css";
import { SaasTherapistService } from "src/services/SaasTherapistService";
import { toast } from "react-toastify";
import MyTasks from "./TodoTabs/MyTasks";
import PendingClinicalAppointments from "./TodoTabs/PendingClinicalAppointments";
import PendingTherapyPlans from "./TodoTabs/PendingTherapyPlans";
import PendingAuthorizationForms from "./TodoTabs/PendingAuthorizationForms";
import PendingSessionNotes from "./TodoTabs/PendingSessionNotes";
import PendingAppointments from "./TodoTabs/PendingAppointments";

const ToDoSaas = () => {
  const [pendingClinicalDetails, setPendingClinicalDetails] = useState<any[]>([]);
  const [pendingTherapyPlans, setPendingTherapyPlans] = useState<any[]>([]);
  const [pendingAuthorizationForms, setPendingAuthorizationForms] = useState<any[]>([]);
  const [pendingSessionNotes, setPendingSessionNotes] = useState<any[]>([]);
  const [pendingAppointments, setPendingAppointments] = useState<any[]>([]);
  const [pendingClinicalDetailsCount, setPendingClinicalDetailsCount] = useState(0);
  const [pendingTherapyPlansCount, setPendingTherapyPlansCount] = useState(0);
  const [pendingAuthorizationFormsCount, setPendingAuthorizationFormsCount] = useState(0);
  const [pendingSessionNotesCount, setPendingSessionNotesCount] = useState(0);
  const [pendingAppointmentsCount, setPendingAppointmentsCount] = useState(0);
  const [activeTab, setActiveTab] = useState("MyTasks");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetchAllTodoDetails();

    // Check if the screen size is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchAllTodoDetails = async () => {
    try {
      const res = await SaasTherapistService.getTodoDetails();
      if (res.success) {
        setPendingClinicalDetails(res.data.pendingClinicalDocuments);
        setPendingTherapyPlans(res.data.pendingTherapyPlans);
        setPendingAuthorizationForms(res.data.pendingAuthorizationForms);
        setPendingSessionNotes(res.data.pendingSessionNotes);
        setPendingAppointments(res.data.pendingAppointments);
        setPendingClinicalDetailsCount(res.data.pendingClinicalDocuments.length);
        setPendingTherapyPlansCount(res.data.pendingTherapyPlans.length);

        const pendingAuthorizationFormsCount = res?.data?.pendingAuthorizationForms?.reduce(
          (
            total: any,
            form: {
              insuranceCompanies: { filter: (arg0: (company: any) => boolean) => { (): any; new (): any; length: any } };
            }
          ) => {
            const count = form.insuranceCompanies.filter(company => company.authorizationFormAvailability === true).length;
            return total + count;
          },
          0
        );

        setPendingAuthorizationFormsCount(pendingAuthorizationFormsCount);
        setPendingSessionNotesCount(res.data.pendingSessionNotes.length);
        setPendingAppointmentsCount(res.data.pendingAppointments.length);
      } else {
        toast.error("Error loading data", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Failed to fetch clients", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOptionSelect = (option: string) => {
    setActiveTab(option);
    setDropdownOpen(false);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "MyTasks":
        return <MyTasks clientId={""} />;
      case "PendingClinicalAppointments":
        return <PendingClinicalAppointments pendingClinicalDetails={pendingClinicalDetails} />;
      case "PendingTherapyPlans":
        return <PendingTherapyPlans pendingTherapyPlans={pendingTherapyPlans} />;
      case "PendingAuthorizationForms":
        return <PendingAuthorizationForms pendingAuthorizationForms={pendingAuthorizationForms} />;
      case "PendingSessionNotes":
        return <PendingSessionNotes pendingSessionNotes={pendingSessionNotes} />;
      case "PendingAppointments":
        return <PendingAppointments pendingAppointments={pendingAppointments} />;
      default:
        return null;
    }
  };

  return (
    <div className="clinical-documents-container">
      <div className="clinical-documents-card">
        <h2 className="client-details-title p-2 mb-2">Therapist To-do List</h2>

        {isMobile ? (
          <div className="mobile-dropdown">
            <div className="custom-dropdown">
              <div className="dropdown-selected" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {activeTab}
                <i
                  className={`bx ${dropdownOpen ? "bx-chevron-up" : "bx-chevron-down"} dropdown-icon`}
                  style={{ marginLeft: "8px", transition: "transform 0.3s ease" }}
                ></i>
              </div>
              {dropdownOpen && (
                <ul className="dropdown-options">
                  <li style={activeTab === "MyTasks" ? { backgroundColor: "#f27e0c", color: "white" } : {}} onClick={() => handleOptionSelect("MyTasks")}>
                    My Tasks
                  </li>
                  <li
                    style={activeTab === "PendingClinicalAppointments" ? { backgroundColor: "#f27e0c", color: "white" } : {}}
                    onClick={() => handleOptionSelect("PendingClinicalAppointments")}
                  >
                    Pending Clinical Assessments ({pendingClinicalDetailsCount})
                  </li>
                  <li
                    style={activeTab === "PendingTherapyPlans" ? { backgroundColor: "#f27e0c", color: "white" } : {}}
                    onClick={() => handleOptionSelect("PendingTherapyPlans")}
                  >
                    Pending Therapy Plans ({pendingTherapyPlansCount})
                  </li>
                  <li
                    style={activeTab === "PendingAuthorizationForms" ? { backgroundColor: "#f27e0c", color: "white" } : {}}
                    onClick={() => handleOptionSelect("PendingAuthorizationForms")}
                  >
                    Pending Prior Authorization Request Forms ({pendingAuthorizationFormsCount})
                  </li>
                  <li
                    style={activeTab === "PendingSessionNotes" ? { backgroundColor: "#f27e0c", color: "white" } : {}}
                    onClick={() => handleOptionSelect("PendingSessionNotes")}
                  >
                    Pending Patient Session Notes ({pendingSessionNotesCount})
                  </li>
                  <li
                    style={activeTab === "PendingAppointments" ? { backgroundColor: "#f27e0c", color: "white" } : {}}
                    onClick={() => handleOptionSelect("PendingAppointments")}
                  >
                    Pending Follow-Up Appointments ({pendingAppointmentsCount})
                  </li>
                </ul>
              )}
            </div>
          </div>
        ) : (
          <ul className="nav nav-tabs desktop-tabs">
            <li className="saas-nav-item">
              <button className={`saas-nav-link ${activeTab === "MyTasks" ? "active" : ""}`} onClick={() => setActiveTab("MyTasks")}>
                My Tasks
              </button>
            </li>
            <li className="saas-nav-item">
              <button
                className={`saas-nav-link ${activeTab === "PendingClinicalAppointments" ? "active" : ""}`}
                onClick={() => setActiveTab("PendingClinicalAppointments")}
              >
                Pending Clinical Assessments
                <div className={"task-count-circle"}>{pendingClinicalDetailsCount}</div>
              </button>
            </li>
            <li className="saas-nav-item">
              <button className={`saas-nav-link ${activeTab === "PendingTherapyPlans" ? "active" : ""}`} onClick={() => setActiveTab("PendingTherapyPlans")}>
                Pending Therapy Plans
                <div className={"task-count-circle"}>{pendingTherapyPlansCount}</div>
              </button>
            </li>
            <li className="saas-nav-item">
              <button
                className={`saas-nav-link ${activeTab === "PendingAuthorizationForms" ? "active" : ""}`}
                onClick={() => setActiveTab("PendingAuthorizationForms")}
              >
                Pending Prior Authorization Request Forms
                <div className={"task-count-circle"}>{pendingAuthorizationFormsCount}</div>
              </button>
            </li>
            <li className="saas-nav-item">
              <button className={`saas-nav-link ${activeTab === "PendingSessionNotes" ? "active" : ""}`} onClick={() => setActiveTab("PendingSessionNotes")}>
                Pending Patient Session Notes
                <div className={"task-count-circle"}>{pendingSessionNotesCount}</div>
              </button>
            </li>
            <li className="saas-nav-item">
              <button className={`saas-nav-link ${activeTab === "PendingAppointments" ? "active" : ""}`} onClick={() => setActiveTab("PendingAppointments")}>
                Pending Follow-Up Appointments
                <div className={"task-count-circle"}>{pendingAppointmentsCount}</div>
              </button>
            </li>
          </ul>
        )}

        <div className="todo-tab-content">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default ToDoSaas;
