import { AnyKindOfDictionary } from "lodash";
import * as React from "react";
import { LocalCall } from "../models/LocalCall";

const initialData: LocalCall = {
  callId: "",
  onOngoingCall: false,
  recieversUseDefaultAvatarValue: true,
  recieversAvatarId: "",
  recieversAvatarBackgroundId: "",
  recieversIncogniteModeOn: true,
  meetingMainTherapistId: "",
  isVideoCall: false,
  meetingDuration: 30,
  recieversUserId: "",
  recieversName: "",
  recieverCallRecordingAllowed: false,
  recieversSocketId: "",
  callEndedForBothUsers: false,
  videoSdkToken: "",
  isAudioCall: false
};

type LocalCallData = [
  localUserOnCall: LocalCall,
  setLocalUserOnCall: (valueForCallInitialize: boolean, localCall: LocalCall, endCallForBoth: boolean, startOutGoingTimeout: boolean) => void,
  cancellCallAfterCallSend: (valueForCallInitialize: boolean, localCall: LocalCall) => void,
  setOutGoinCallTimeOut: () => void,
  setNearestAppointmentFunction: (resData: AnyKindOfDictionary) => void,
  startTimerWhenBothJoined: () => void,
  resetTimerWhenSecondTimeJoined: () => void,
  hideTimerOnly: () => void
];

const LocalCallContext = React.createContext<LocalCallData>([initialData, () => ({}), () => ({}), () => ({}), () => ({}), () => ({}), () => ({}), () => ({})]);

export default LocalCallContext;
