import React from "react";
import "../../assets/css/skeleton.css";

const SkeltonGraph: React.FC = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="skeleton">
              <div className="col-sm-12 col-12">
                <div className="flex flex-row justify-content-center align-items-center">
                  <div className="line h12 w25 mt-1"></div>
                </div>
                <div className="flex1">
                  <div className="flex flex-row justify-content-center align-items-center">
                    <div className="line h150 w100 mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SkeltonGraph;
