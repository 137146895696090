import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { AdminPermissionEnum, UserRole } from "src/models/User";
import UserContext from "../../context/UserContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

interface AdminRoutePermissionProps extends RouteProps {
  path?: string | readonly string[];
  component: React.ComponentType<any>;
  isAuthProtected?: boolean;
}

function getEnumKeyByValue(enumObj: any, value: string): string | undefined {
  return Object.keys(enumObj).find(key => enumObj[key] === value);
}

const AdminRoutePermission: React.FC<AdminRoutePermissionProps> = ({
  path,
  component: Component,
  isAuthProtected,
  ...rest
}) => {
  const [userFinal] = useContext(UserContext);
  const history = useHistory();
  const [swalShown, setSwalShown] = useState(false);
  let keyName: string | undefined;

  useEffect(() => {
    if (typeof path === 'string') {
      keyName = getEnumKeyByValue(AdminPermissionEnum, path);
      
      const adminPermission = userFinal?.adminPermission as { [key: string]: boolean } | undefined;
      const role = userFinal?.role;

      if (adminPermission && keyName !== undefined && !swalShown) {
        if (role != "SUPER_ADMIN" && adminPermission[keyName] === false) {
          Swal.fire({
            icon: "warning",
            title: "You don't have permission to this page!",
            showCancelButton: false,
            confirmButtonText: "Go Back",
            confirmButtonColor: "#f46a6a",
            allowOutsideClick: false
          }).then(result => {
            if (result.isConfirmed) {
              history.goBack();
            }
          });
          setSwalShown(true);
        }
      }
    }
  }, [path, userFinal, swalShown, history]);

  if (typeof path === 'string') {
    keyName = getEnumKeyByValue(AdminPermissionEnum, path);
    const adminPermission = userFinal?.adminPermission as { [key: string]: boolean } | undefined;
    const role = userFinal?.role;

    if ((adminPermission && keyName !== undefined && adminPermission[keyName] !== false) || role == "SUPER_ADMIN") {
      return (
        <Route
          {...rest}
          render={props => {
            return <Component {...props} />;
          }}
        />
      );
    }
  }

  return null;
};

AdminRoutePermission.propTypes = {
  component: PropTypes.any.isRequired,
  isAuthProtected: PropTypes.bool,
};

export default AdminRoutePermission;
