import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Container, Nav, NavItem, CardText, NavLink, CardFooter, Row, TabContent, Modal, TabPane, } from "reactstrap";
import UserContext from "../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Upload } from "../../models/Upload";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { Insurance, ReceiverModel } from "../../models/Insurance";
import Select from "react-select";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import { disableCursor } from "@fullcalendar/react";


const InsuranceFormOne: React.FC<{
    addFormTwoHandler: any;
    addOrganizationData: (value: any) => void,
    addInsuranceCard: (value: any) => void,
    addInsuranceCardBack: (value: any) => void
}> = (props: any) => {




    const [user, setUser] = useContext(UserContext);
    const [insuranceData, setInsuranceData] = useState<ReceiverModel>({} as ReceiverModel);
    const [insuranceCard, selectInsuranceCard] = useState({ preview: "", preview2: "", raw: "" });
    const insuranceCardRef = useRef<any>();
    insuranceCardRef.current = insuranceCard;
    const [insuranceCardBack, selectInsuranceCardBack] = useState({ preview: "", preview2: "", raw: "" });
    const insuranceCardBackRef = useRef<any>();
    insuranceCardBackRef.current = insuranceCardBack;
    const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
    const [selecteInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag>({} as SelectedInsuranceCompanyTag);

    useEffect(() => {
        getAllInsuranceCompanies();
    }, []);

    const selectInsuranceFile = (e: any) => {
        if (e.target.files.length) {
            selectInsuranceCard({
                preview: URL.createObjectURL(e.target.files[0]) as any,
                preview2: e.target.files[0],
                raw: e.target.files
            });
        }
    };

    const selectInsuranceBackFile = (e: any) => {
        if (e.target.files.length) {
            selectInsuranceCardBack({
                preview: URL.createObjectURL(e.target.files[0]) as any,
                preview2: e.target.files[0],
                raw: e.target.files
            });
        }
    };

    const updateTherapistDetails = () => {
        if (!selecteInsuranceCompanyTag) {
            toast.error("Organization Name id is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        } else {
            const subcribersData: any = {
                organizationName: selecteInsuranceCompanyTag.value,
            }
            props.addOrganizationData(subcribersData)
            props.addInsuranceCard(insuranceCardRef.current.raw)
            props.addInsuranceCardBack(insuranceCardBackRef.current.raw)
            props.nextStep()
        }
    }

    const getAllInsuranceCompanies = () => {
        InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
            if (res.success) {
                const tagList = res.data.map((tag: any) => {
                    return { value: tag._id, label: tag.insuranceCompany };
                });
                setSearchInsuranceCompanyTag(tagList);
            } else {
                setSearchInsuranceCompanyTag([]);
            }
        });
    };





    const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
        if (file) {
            switch (file.extension || undefined || null) {
                case ".PNG":
                case ".png":
                    return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
                case ".docx":
                case ".DOCX":
                    return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
                case ".pdf":
                case ".PDF":
                    return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
                case ".txt":
                case ".TXT":
                    return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
                case ".mp4":
                    return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
                case ".mkv":
                    return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
                case ".jpg":
                case ".JPG":
                case ".JPEG":
                case ".jpeg":
                case ".tiff":
                case ".TIFF":
                case ".gif":
                case ".GIF":
                    return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
                default:
                    return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
            }
        } else {
            return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
        }
    };

    const InsuranceCardFrontFiles = () => {
        switch (insuranceCardRef.current.preview2.type) {
            case "image/png":
            case "IMAGE/PNG":
                return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
            case ".docx":
            case ".DOCX":
            case "image/doc":
            case "application/msword":
                return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
            case "application/pdf":
                return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
            case "text/plain":
            case ".TXT":
                return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
            case "image/jpeg":
            case "image/jpg":
            case ".JPEG":
            case ".jpeg":
                return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
            default:
                selectInsuranceCard({
                    preview: "",
                    preview2: "",
                    raw: ""
                });
                return toast.error("Only images & pdf can be uploaded.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
        }
    }

    const InsuranceCardBackFiles = () => {
        switch (insuranceCardBackRef.current.preview2.type) {
            case "image/png":
            case "IMAGE/PNG":
                return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
            case ".docx":
            case ".DOCX":
            case "image/doc":
            case "application/msword":
                return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
            case "application/pdf":
                return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
            case "text/plain":
            case ".TXT":
                return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
            case "image/jpeg":
            case "image/jpg":
            case ".JPEG":
            case ".jpeg":
                return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
            default:
                selectInsuranceCardBack({
                    preview: "",
                    preview2: "",
                    raw: "",
                });
                return toast.error("Only images & pdf can be uploaded.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
        }
    };


    const createOption = (label: string) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });
    const handleCreate = (inputValue: string) => {
        if (inputValue.length > 0) {
            if (!/^[A-Za-z\s- ]*$/.test(inputValue[inputValue.length - 1])) {
                return toast.error("Only letters are allowed!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }

            const newOption = createOption(inputValue);
            const tagList: any = newOption
            searchInsuranceCompanyTag.push(tagList);
        }
    };

    return (
        <>
            <Card className="BorderRadiusLeftRight paddingInsurance">
                <div className="card-bodyDiv">
                    <Row>
                        <div className="card-heading mb-2">
                            <h5 className="font-size-20 profileFont mb-4 float-right">Step 01</h5>
                        </div>
                    </Row>

                    <Row>
                        <Col xl="6">

                            <div>
                                <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                                </div>
                                <Select
                                    placeholder="Choose or create company"
                                    onChange={(newValue: any) => setSelectedInsuranceCompanyTag(newValue)}
                                    options={searchInsuranceCompanyTag}
                                    className="creatableEdit mb-4"
                                    required
                                />
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col xl="6">
                            {insuranceCardRef.current.preview ? (
                                <div>
                                    {(insuranceCardRef.current.preview) && (
                                        <label htmlFor="file-upload1" className="file-upload m-auto">
                                            <span className="text-primary mt-5 m-auto cursor-pointer">Change Your insurance card front picture</span>

                                            <input id="file-upload1"
                                                type="file"
                                                style={{ display: "none" }}
                                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                                onChange={selectInsuranceFile}
                                                multiple />
                                        </label>
                                    )}
                                    <div>
                                        <div className="flex">
                                            <div style={{ fontSize: "62px" }}>
                                                {InsuranceCardFrontFiles()}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}

                            {!insuranceCardRef.current.preview && (

                                <label htmlFor="file-upload1" style={{ border: '1px dashed' }} className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                    <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                        <img src={uploadIcon} />
                                        <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card front picture</p>
                                    </div>

                                    <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                    <input id="file-upload1"
                                        type="file"
                                        style={{ display: "none" }}
                                        className="file_upload_input mb-4"
                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                        onChange={selectInsuranceFile}
                                        multiple />
                                </label>
                            )}
                        </Col>

                        <Col xl="6">
                            {insuranceCardBackRef.current.preview ? (
                                <div>
                                    {(insuranceCardBackRef.current.preview) && (
                                        <label htmlFor="file-upload2" className="file-upload m-auto">
                                            <span className="text-primary mt-5 m-auto cursor-pointer">Change Your insurance card back picture</span>

                                            <input id="file-upload2"
                                                type="file"
                                                style={{ display: "none" }}
                                                accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                                onChange={selectInsuranceBackFile}
                                                multiple />
                                        </label>
                                    )}
                                    <div>
                                        <div className="flex">
                                            <div style={{ fontSize: "62px" }}>
                                                {InsuranceCardBackFiles()}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}

                            {!insuranceCardBackRef.current.preview && (

                                <label htmlFor="file-upload3" style={{ border: '1px dashed' }} className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer">
                                    <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                        <img src={uploadIcon} />
                                        <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card back picture </p>
                                    </div>
                                    <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                    <input id="file-upload3"
                                        type="file"
                                        style={{ display: "none" }}
                                        className="file_upload_input mb-4"
                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                        onChange={selectInsuranceBackFile}
                                        multiple />
                                </label>
                            )}
                        </Col>

                    </Row>



                </div>

                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2" style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={() => {
                                updateTherapistDetails();
                            }}>
                            Next
                        </button>
                    </Col>
                </Row>
            </Card>

        </>
    );

};

export default InsuranceFormOne;
