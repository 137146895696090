import React, { useContext, useEffect, useState } from "react";
import { Modal, Row, CardBody, Card, Col, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import UserContext from "../../context/UserContext";
import {} from "../../models/HomeWork";
import { Role } from "../../models/Role";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { HomeworkService } from "../../services/HomeworkService";
import { useParams } from "react-router-dom";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { toast } from "react-toastify";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";
import { SocketContext } from "../../context/ScoketContext";
import { UploadDocument } from "../../models/UploadDocument";
import { Util } from "../../Util";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";
import Spinner from "../../common/spinner/spinner";
import moment from "moment";

interface UDProps {
  isOpened: boolean;
  returnBool: any;
  userId:string
}

const UploadDocuments: React.FC<UDProps> = ({ isOpened, returnBool,userId }) => {
  const [loggedUser] = useContext(UserContext);
 // const { userId } = useParams<ParameterTypes>();
  const [isUDModal, setUDModal] = React.useState(false);
  const [isUpdate, setUpdate] = React.useState(false);
  const [uploadFile, setUploadFile] = useState({ preview: "", preview2: "", raw: [] as any });
  const [isAllDocs, setIsAllDocs] = useState([] as UploadDocument[]);
  const socket = useContext(SocketContext);
  const limit = 10;
  const [docCount, setDocCount] = useState(10);
  const [offset, setOffset] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];
  const SUPPORTED_FILE_FORMATS = ["doc", "pdf", "txt", "docx", "odt", "ods"];

  useEffect(() => {
    checkLoggedUser();
  }, []);

  const checkLoggedUser = () => {
    switch (loggedUser!.role) {
      case Role.THERAPIST:
        getAllDocumentsByTherapis();
        break;
      case Role.CLIENT:
        getAllDocumentsByClient();
        break;
    }
  };

  //dup method *1*
  const getAllDocumentsByTherapis = () => {
    setIsLoading(true);
    HomeworkService.getAllDocumentsByTherapist(userId, limit, 1).then(res => {
      if (res.success) {
        setIsAllDocs(res.data);
        setDocCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  //dup method *1*
  const getAllDocumentsByClient = () => {
    setIsLoading(true);
    HomeworkService.getAllDocumentsByClients(userId, limit, 1).then(res => {
      if (res.success) {
        setIsAllDocs(res.data);
        setDocCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;
    setIsLoading(true);
    setOffset(updatedSkip);

    HomeworkService.getAllDocumentsByTherapist(userId, 10, updatedSkip).then(res => {
      if (res.success) {
        res.data.map((doc: any) => {
          setIsAllDocs(isAllDocs => [...isAllDocs, doc]);
        });
        setDocCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const seeMore2 = () => {
    const updatedSkip = offset + 1;
    setIsLoading(true);
    setOffset(updatedSkip);

    HomeworkService.getAllDocumentsByClients(userId, 10, updatedSkip).then(res => {
      if (res.success) {
        res.data.map((doc: any) => {
          setIsAllDocs(isAllDocs => [...isAllDocs, doc]);
        });
        setDocCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const toggleHGCanvas = () => {
    setUpdate(false);
    return returnBool(false);
  };

  const UDModalOpen = () => {
    setUDModal(!isUDModal);
    setUpdate(false);
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const selectDocumentFile = (e: any) => {
    if (e.target.files.length && e.target.files.length <= 1) {
      const extension = e.target.files[0].name.split(".").pop().toLowerCase();
      const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);
      const isAFile = isSupported(SUPPORTED_FILE_FORMATS, extension);

      if (isAnImage || isAFile) {
        setUploadFile({
          preview: URL.createObjectURL(e.target.files[0]) as any,
          preview2: e.target.files[0],
          raw: [...e.target.files],
        });
      } else {
        return toast.error("File format not supported!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } else {
      toast.error("You can select maximum 1 files!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  // to upload document
  const uploadDocs = () => {
    const data = {
      clientId: userId,
    };
    HomeworkService.createDocument(data, uploadFile.raw).then(res => {
      if (res?.success) {
        toast.success("Created Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        checkLoggedUser();
        UDModalOpen();
        setUploadFile({ preview: "", preview2: "", raw: [] as any });

        const uploadDocumentNotification: NotificationModel = {
          senderId: loggedUser?._id,
          receiverId: userId,
          event: NotificationEvent.UPLOAD_DOCUMENT,
          link: "/chat/" + userId,
          content: "Document file is Uploaded by " + loggedUser?.firstname + " " + loggedUser?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(uploadDocumentNotification).then(res => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: uploadDocumentNotification,
            senderId: loggedUser?._id,
            receiverId: userId,
          };

          socket.emit("send-notification", socketData);
        });
      } else {
        toast.error(res?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  return (
    <>
      <Offcanvas
        isOpen={isOpened}
        direction="end"
        toggle={toggleHGCanvas}
        className={`${loggedUser?.role == Role.CLIENT && " goals-and-homework-canvas-therapist "} ${
          loggedUser?.role == Role.THERAPIST && " goals-and-homework-canvas-therapist "
        } `}
      >
        <OffcanvasHeader toggle={toggleHGCanvas}>Upload Documents</OffcanvasHeader>

        <OffcanvasBody>
          <div className="row  pt20" id="style-1">
            <div className="col-md-6 col-sm-12 ">
              <div className=" sm-mb5">
                <a href="#" onClick={UDModalOpen}>
                  {loggedUser?.role == Role.THERAPIST && "Upload Document"} {loggedUser?.role == Role.THERAPIST && <i className="bx bx-plus-circle"></i>}
                </a>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            {!isLoading ? (
              <div className="col-md-12">
                {isAllDocs && loggedUser?.role == Role.THERAPIST ? (
                  <Row>
                    {isAllDocs.map((uploadDoc: UploadDocument, index: number) => {
                      return (
                        <Col lg={`${loggedUser?.role == Role.CLIENT && 4} ${loggedUser?.role == Role.THERAPIST && 4}`} key={index}>
                          <Card>
                            <CardBody>
                              <div className="attachment">
                                <p className="hw-attach" id="up">
                                  <i className="bx bx-paperclip"></i>&nbsp;
                                  <span onClick={() => window.open(Util.fileURL(uploadDoc?.uploadDocumentId?._id), "_blank")}>
                                    {uploadDoc?.uploadDocumentId?.originalName}
                                  </span>
                                  <div>
                                    <small className="mb-0 text-muted">{moment.utc(uploadDoc?.uploadDocumentId?.createdAt).format("YYYY-MM-DD HH:mm:ss A")}</small>
                                  </div>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}

                    {docCount >= 10 && (
                      <>
                        <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMore}>
                          See More
                        </button>
                      </>
                    )}

                    {!isAllDocs.length && <p>Upload documents not found !</p>}
                  </Row>
                ) : (
                  <Row>
                    {isAllDocs.map((uploadDoc: UploadDocument, index: number) => {
                      return (
                        <Col lg={`${loggedUser?.role == Role.CLIENT && 12} ${loggedUser?.role == Role.THERAPIST && 12}`} key={index}>
                          <Card>
                            <CardBody>
                              <div className="attachment">
                                <p className="hw-attach" id="up">
                                  <i className="bx bx-paperclip"></i>&nbsp;
                                  <span onClick={() => window.open(Util.fileURL(uploadDoc?.uploadDocumentId?._id), "_blank")}>
                                    {uploadDoc?.uploadDocumentId?.originalName}
                                  </span>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}

                    {docCount >= 10 && (
                      <>
                        <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMore2}>
                          See More
                        </button>
                      </>
                    )}

                    {!isAllDocs.length && <p>Upload documents not found !</p>}
                  </Row>
                )}
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </OffcanvasBody>
      </Offcanvas>

      <Modal
        isOpen={isUDModal}
        toggle={() => {
          UDModalOpen();
        }}
        centered
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0">Upload Document</h4>

          <button
            type="button"
            onClick={() => {
              setUDModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="row mb-4">
              <div className="col">
                <label
                  htmlFor="file-upload1"
                  style={{ border: "1px dashed" }}
                  className="text-left mb-4 file_upload file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                >
                  <div className="flex flex-wrap items-center pl-5 pt-4 pb-1 file_upload_content">
                    <img src={uploadIcon} />
                    <p className="m-0 text-black">&nbsp;&nbsp;Upload Document File</p>
                  </div>
                  <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                  <input
                    id="file-upload1"
                    type="file"
                    style={{ display: "none" }}
                    className="file_upload_input mb-4"
                    accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                    onChange={selectDocumentFile}
                    multiple
                  />
                </label>
                <div className="uploads">
                  {uploadFile.raw.map((file: any, i: number) => {
                    return (
                      <p className={`hw-attach`} key={i}>
                        <i className="bx bx-paperclip"></i>&nbsp;
                        <span
                          onClick={() => {
                            window.open(URL.createObjectURL(file) as any, "_blank");
                          }}
                        >
                          {file.name}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col">
              <div className="tab-pane">
                <div className="row text-center">
                  <div className="col-md-6">
                    {!isUpdate ? (
                      <button
                        type="button"
                        className="btn btn-primary  relative m-2 w100-full"
                        style={{
                          bottom: "0",
                        }}
                        onClick={() => uploadDocs()}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary  relative m-2 w100-full"
                        style={{
                          bottom: "0",
                        }}
                      >
                        Update
                      </button>
                    )}
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-light relative m-2 w100-full"
                      style={{
                        bottom: "0",
                      }}
                      onClick={UDModalOpen}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadDocuments;
