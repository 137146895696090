import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

interface NotesResponse {
  _id: {
    _id: string;
    fullName: string;
  };
  notes: {
    diagnosisNoteId: string;
    meetingStartedTime: string;
    isMeetingTranscribe: boolean;
    noteType: string | null;
    updatedByTherapist: boolean;
    createdAt: string;
    diagnosisNoteVersions: any[];
    status?: string;
    lastFeedbackFromAuditor?: string;
  }[];
}

interface UpdateSessionNoteRequest {
  diagnosisNoteId: string;
  status: string;
  feedback?: string;
}

export class AuditSessionsPopUpService {
  public static async getNotesForTherapists(therapistId: string, clientId: string): Promise<AppResponse<NotesResponse[]>> {
    const url = Util.apiAuthUrl(`getNotesForTherapists/${therapistId}?clientId=${clientId}`);
    return await axios.get<void, AppResponse<NotesResponse[]>>(url);
  }

  public static async updateSessionNotes(therapistId: string, clientId: string, notes: UpdateSessionNoteRequest[]): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`updateSessionNotes/${therapistId}`);
    return await axios.post<void, AppResponse<any>>(url, {
      clientId,
      notes
    });
  }

  public static async updateDiagnosisNote(diagnosisNoteId: string, status: string, lastFeedbackFromAuditor?: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`diagnosisNote/${diagnosisNoteId}`);
    const payload: { status: string; lastFeedbackFromAuditor?: string } = { status };
    if (lastFeedbackFromAuditor) {
      payload.lastFeedbackFromAuditor = lastFeedbackFromAuditor;
    }
    return await axios.put<void, AppResponse<any>>(url, payload);
  }
}
