import React, { useEffect, useState } from "react";
import { AdminService } from "src/services/AdminService";
import TechTicketContext from "src/context/TechTicketRequest";

const TechTicketUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadTechTicketCount, setUnreadTechTicketCount] = useState<number>(0);
  const [offset] = useState(1);

  const LIMIT = 0;

  useEffect(() => {
    AdminService.getAllTechTickets(LIMIT, offset).then(res => {
      if (res.success) {
        setUnreadTechTicketCount(res.data.count)
      }
    });
  }, []);

  return <TechTicketContext.Provider value={[unreadTechTicketCount, setUnreadTechTicketCount]}>{children}</TechTicketContext.Provider>;
};

export default TechTicketUtils;