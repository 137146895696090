import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import PublicAppointmentAvailableSlots from "./PublicAppointmentAvailableSlots";
import { toast } from "react-toastify";
import Spinner from "src/common/spinner/spinner";
import moment from "moment";
import { Util } from "src/Util";
import CustomAppointmentCalendar from "./CustomAppointmentCalendar";
interface availableSlot {
  startTime: Date;
}

interface availableSlotFrom {
  startTime: string;
  endTime: string;
  daysOfWeek: number[];
}

interface Props {
  availableSlotsFromNew: availableSlotFrom[];
  prevAppointmentsNew: any[];
  prevBlockedDatesListNew: any[];
  addEvent: (selectedDetails: any) => void;
  selectedMonthFrom: Date;
  selectMonth: (selectedMonthIn: Date) => void;
  isLoadingNextMonth: boolean;
}

const PublicAppointmentCalendar = ({
  availableSlotsFromNew,
  prevAppointmentsNew,
  prevBlockedDatesListNew,
  addEvent,
  selectedMonthFrom,
  selectMonth,
  isLoadingNextMonth,
}: Props) => {

  // console.log('PublicAppointmentCalendar Props: availableSlotsFromNew', {
  //   availableSlotsFromNew
  // });
  // console.log('PublicAppointmentCalendar Props: prevAppointmentsNew', {
  //   prevAppointmentsNew
  // });
  // console.log('PublicAppointmentCalendar Props: prevBlockedDatesListNew', {
  //   prevBlockedDatesListNew
  // });
  // console.log('PublicAppointmentCalendar Props: selectedMonthFrom', {
  //   selectedMonthFrom
  // });
  // console.log('PublicAppointmentCalendar Props: isLoadingNextMonth', {
  //   isLoadingNextMonth
  // });
  
  
  const [isLoadingSlots, setIsLoadingSlots] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<availableSlot[]>([]);

  const todayDate: Date = new Date();
  const tomorrowDate: Date = moment(todayDate).add(1, "day").startOf("day").toDate();

  function pickNextMonth(selectedMonth: Date) {
    setIsLoadingSlots(true);
    setSelectedDate(null);
    setAvailableTimeSlots([]);
    selectMonth(selectedMonth);
  }

  function onDatePick(value: Date | null, isInitial: boolean, prevAppointmentsListInFunc: any[], prevBlockedDatesListInFunc: any[], availableSlotsInFunc: any) {
    try {
      if (isInitial) {
        setIsLoadingSlots(true);
        setAvailableTimeSlots([]);
        setSelectedDate(null);
        if (value) {
          setAvailableSlotsFunction(value, prevAppointmentsListInFunc, prevBlockedDatesListInFunc, availableSlotsInFunc);
        } else {
          setIsLoadingSlots(false);
        }
      } else {
        if (isLoadingSlots) {
          toast.error("Please wait !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setAvailableSlotsFunction(value, prevAppointmentsListInFunc, prevBlockedDatesListInFunc, availableSlotsInFunc);
        }
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }

  function setAvailableSlotsFunction(dateSelected: Date | null, prevAppointmentsListIn: any[], prevBlockedDatesListIn: any[], availableSlotsIn: any) {
    try {
      setIsLoadingSlots(true);
      setSelectedDate(dateSelected);

      if (prevAppointmentsListIn && prevBlockedDatesListIn && availableSlotsIn && availableSlotsIn.length && availableSlotsIn.length > 0 && dateSelected) {
        const selectedDyOfWeek = dateSelected.getDay();

        const availableHours: string[][] = [];

        availableSlotsIn
          .filter((obj: any) => obj.daysOfWeek[0] === selectedDyOfWeek)
          .map((obj: any) => {
            const availableHourSingle: string[] = [];

            for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
              if (hour == parseInt(obj.startTime)) {
                const mST = moment(obj.startTime, "HH:mm").minute();

                if (mST == 0) {
                  availableHourSingle.push(moment({ hour }).format("H:mm A"));
                }
              } else {
                availableHourSingle.push(moment({ hour }).format("H:mm A"));
              }

              if (hour != parseInt(obj.endTime)) {
                availableHourSingle.push(
                  moment({
                    hour,
                    minute: 30,
                  }).format("H:mm A")
                );
              } else {
                const mET = moment(obj.endTime, "HH:mm").minute();

                if (mET == 30) {
                  availableHourSingle.push(
                    moment({
                      hour,
                      minute: 30,
                    }).format("H:mm A")
                  );
                }
              }
            }

            availableHours.push(availableHourSingle);
          });

        let allAvailableSlotsForSelectedDate: availableSlot[] = [];

        availableHours.map((slotArray: string[], i: number) => {
          slotArray.map((slot: string, i: number) => {
            const startTimeString = slot;
            const startTimeStringTime = moment(startTimeString, "HH:mm A");
            const selectedStartDateTime = moment(dateSelected)
              .hours(startTimeStringTime.hours())
              .minutes(startTimeStringTime.minutes())
              .seconds(0)
              .format("YYYY-MM-DD HH:mm:ss");
            const convertedStartDate = moment(selectedStartDateTime, "YYYY-MM-DD HH:mm:ss").toDate();

            const startTForCheck = moment(convertedStartDate);

            const endTForCheck = moment(startTForCheck).add(60, "minutes");

            if (
              moment(tomorrowDate).isSameOrBefore(moment(convertedStartDate)) &&
              slotArray.includes(moment(startTForCheck).format("H:mm A")) &&
              slotArray.includes(moment(endTForCheck).format("H:mm A"))
            ) {
              allAvailableSlotsForSelectedDate.push({ startTime: convertedStartDate });
            }
          });
        });

        allAvailableSlotsForSelectedDate = allAvailableSlotsForSelectedDate.filter(
          (slot, index, self) => index === self.findIndex(s => moment(s.startTime).valueOf() === moment(slot.startTime).valueOf())
        );

        allAvailableSlotsForSelectedDate = allAvailableSlotsForSelectedDate.filter(slot => {
          const isInBlockedRange = prevBlockedDatesListIn.some((range: any) => {
            return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(slot.startTime));
          });

          if (isInBlockedRange) {
            return false;
          }

          const appointmentAlreadyExists = prevAppointmentsListIn.some((range: any) => {
            return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(slot.startTime));
          });
          return !isInBlockedRange && !appointmentAlreadyExists;
        });

        allAvailableSlotsForSelectedDate.sort((slot1, slot2) => {
          return moment(slot1.startTime).valueOf() - moment(slot2.startTime).valueOf();
        });
        setAvailableTimeSlots(allAvailableSlotsForSelectedDate);
      } else {
        setAvailableTimeSlots([]);
      }
      setIsLoadingSlots(false);
    } catch (error) {
      setIsLoadingSlots(false);
      setAvailableTimeSlots([]);
      return toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }

  return (
    <div>
      <Row>
        <Col lg={7} md={6} className="d-flex justify-content-center align-items-start mb-4">
          <CustomAppointmentCalendar
            selectedMonthFrom={selectedMonthFrom}
            availableSlotsFromNew={availableSlotsFromNew}
            prevAppointmentsNew={prevAppointmentsNew}
            prevBlockedDatesListNew={prevBlockedDatesListNew}
            selectMonth={pickNextMonth}
            isLoadingNextMonth={isLoadingNextMonth}
            onDatePickFrom={onDatePick}
            selectedDate={selectedDate}
          />
        </Col>
        {!isLoadingSlots && !isLoadingNextMonth ? (
          <Col lg={5} md={6}>
            {availableTimeSlots && availableTimeSlots.length && availableTimeSlots.length > 0 ? (
              <div className="public-appointment-slot-list-main time-slot-row">
                  {availableTimeSlots.map((slot: availableSlot, index: any) => {
                    return <PublicAppointmentAvailableSlots key={index} slotData={slot} addEvent={addEvent} />;
                  })}
              </div>
            ) : (
              <div className="flex flex-col items-center text-center">
                {selectedDate ? "No available slots" : "Please select a date to show available slots"}
              </div>
            )}
          </Col>
        ) : (
          <Col lg={5} md={6}>
            <div className="flex flex-col items-center" style={{ minHeight: "200px" }}>
              <Spinner />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PublicAppointmentCalendar;
