import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserContext from "../../context/UserContext";
import { Container, Row, Col, Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import "guidechimp/dist/guidechimp.min.css";
import "../../assets/css/subscription.css";
import "../../assets/css/rc-tabs.css";
import Tabs, { TabPane } from "rc-tabs";
import "../../../node_modules/rc-tabs/assets/index.css";
import { TransactionService } from "../../services/TransactionService";
import { MeetingType, Transaction, TransactionMeeting, TransactionType } from "../../models/Transaction";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { Util } from "../../Util";
import MeetingHistoryTab from "./MeetingHistoryTab";
import { ParameterTypes } from "../../utils/ParameterTypes";
import stripeConnect from "../../assets/images/stripe_connect.png";
import Spinner from "../../common/spinner/spinner";
import moment from "moment";
import { ReferralEarning } from "src/models/ReferralEarning";
import { ReferralService } from "src/services/ReferralService";

const TherapistEarnings: React.FC = () => {
  const { stripeConnected } = useParams<ParameterTypes>();
  const [user, setUser] = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(false);
  const [transactions, setTransactions] = useState([] as Transaction[]);
  const [rewards, setRewards] = useState([] as ReferralEarning[]);
  const [rewardEarnings, setRewardEarnings] = useState(0);
  const [monthlyMeetings, setMonthlyMeetings] = useState([] as TransactionMeeting[]);
  const [type1Meetings, setType1Meetings] = useState([] as TransactionMeeting[]);
  const [type2Meetings, setType2Meetings] = useState([] as TransactionMeeting[]);
  const [registerLink, setRegisterLink] = useState("");
  const [firstDayOfNextMonth, setFirstDayOfNextMonth] = useState("");
  const limit = 20;
  const [tranceCount, setTranceCount] = useState(20);
  const [rewardCount, setRewardCount] = useState(10);
  const [offset, setOffset] = useState(1);
  const [offset2, setOffset2] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [monthlyPayment, setMonthlyPayment] = useState<any[]>([])

  useEffect(() => {
    window.screen.width <= 768 ? setIsMobile(true) : setIsMobile(false);
  }, [window.screen.width]);

  useEffect(() => {
    transactionsCheck();
    recentMonthlyTransaction();
    getMeetings();
    rewardsCheck();

    const date = new Date();

    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    setFirstDayOfNextMonth(firstDayOfMonth.getMonth() + 1 + "/" + firstDayOfMonth.getDate() + "/" + firstDayOfMonth.getFullYear());
  }, []);

  const createStripeConnectedAccount = () => {
    TransactionService.createStripeConnectedAccount().then((res: any) => {
      if (res.success) {
        setRegisterLink(res.data.url);
      } else {
        toast.error("Failed to create stripe connected account.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const verifyStripeAccount = () => {
    TransactionService.verifyStripeAccount().then((res: any) => {
      if (res.success) {

        if (res.data.enabledForPayments) {
          setUser({ ...user, stripeChargesEnabled: true, stripeDetailsSubmitted: true });
        } else {
          setRegisterLink(res.data.accountLink.url);
        }
      } else {
        toast.error("Verify Stripe Failed.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const transactionsCheck = () => {
    setIsLoading(true);
    TransactionService.getAllTransactions(limit,offset).then(res => {
      if (res.success) {
        setTransactions(res.data);
        setTranceCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const rewardsCheck = () => {
    setIsLoading(true);
    ReferralService.getSingleTherapistRewardDetails().then(res => {
      if (res.success) {
        setRewards(res.data);
        setRewardCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const recentMonthlyTransaction = () => {
    setIsLoading(true);
    TransactionService.getRecentMonthlyPayment().then(res => {
      if (res.success) {
        setMonthlyPayment(res.data)
      }
      setIsLoading(false);
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);
    setIsLoading(true);
    TransactionService.getAllTransactions(limit,offset).then(res => {
      if (res.success) {
        res.data.map((data: any) => {
          setTransactions(transactions => [...transactions, data]);
        });
        setTranceCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };

  const seeMoreRewards = () => {
    const updatedSkip2 = offset2 + 1;

    setOffset2(updatedSkip2);
    setIsLoading(true);
    ReferralService.getSingleTherapistRewardDetails().then(res => {
      if (res.success) {
        res.data.map((data: any) => {
          setRewards(rewards => [...rewards, data]);
        });
        setRewardCount(res.data?.length);
      }
      setIsLoading(false);
    });
  };
  
  useEffect(() => {
    const calculateTotalEarnings = () => {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      const currentMonthRewards = rewards.filter(reward => {
        const rewardDate = new Date(reward.createdAt);
        return reward.paidStatus === "PAID" &&
               rewardDate.getMonth() === currentMonth &&
               rewardDate.getFullYear() === currentYear;
      });

      const earnings = currentMonthRewards.reduce((total, reward) => total + reward.transactionAmount, 0);
      setRewardEarnings(earnings);
    };

    calculateTotalEarnings();
  }, [rewards]);

  const getMeetings = () => {
    TransactionService.getMonthlyMeetings().then(res => {
      if (res.success) {
        setMonthlyMeetings(res.data);

        setType1Meetings(res.data.filter((t: TransactionMeeting) => t.meetingType == MeetingType.THIRTYMINUTUES));

        setType2Meetings(res.data.filter((t: TransactionMeeting) => t.meetingType == MeetingType.SIXTYMINUTUES));
      }
    });
  };

  const createStripeDashboardLink = () => {
    TransactionService.createStripeLoginLink().then(res => {
      if (res.success) {
        setUser({ ...user, stripeConnectedLoginLink: res.data });
      }
    });
  };

  useEffect(() => {
    if (stripeConnected && stripeConnected == "stripe-connected") {
      TransactionService.verifyStripeAccount().then((res: any) => {
        if (res.success) {
          if (res.data.enabledForPayments) {
            setUser({ ...user, stripeChargesEnabled: true, stripeDetailsSubmitted: true });
          }
        } else {
          toast.error("Verify Stripe Failed.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  }, [stripeConnected]);

  const calculateTotal = (recentTransaction: any) => {
    let total = 0;

    recentTransaction?.map((t: any) => {
      total = total + t.transactionAmount;
    });

    return total.toFixed(2);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Client"} breadcrumbItem={"Earnings"} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="card-heading">
                      <h1 className="notifications-title">Your Overall Earning Statistics</h1>

                      <div className="siler-line mb20"></div>
                    </div>
                  </Row>

                  <div className="subscribe-section2">
                    <Tabs defaultActiveKey="1" tabPosition={isMobile ? "top" : "left"}>
                      <TabPane tab="Earnings" key="1">
                        <div className="my-sub-sec">
                          <div className="row">
                            <h3 className="mb-3">Earnings</h3>

                            <div className="col-lg-12 es1">
                              <div className="earning-sec1">
                                <h5>Total Earnings</h5>

                                <span className="sub-item1-text">
                                  {transactions && transactions.length > 0 ? <>${(transactions[0].accumulatedTotalEarnings + rewardEarnings).toFixed(2)}</> : `$${rewardEarnings}`}
                                </span>
                              </div>

                              <div className="earning-sec2"></div>

                              <div className="earning-sec3-new ms-md-5">
                                <h5>Balance Available</h5>
                                <div className="d-sm-flex gap-4">
                                  {
                                    monthlyPayment?.map((payment, index) => (
                                      <div key={index} className="">
                                        <span className="sub-item3-text">
                                          {/* {transactions && transactions.length > 0 ? <>${transactions[0].accumulatedBalance.toFixed(2)}</> : "$0"} */}
                                          {
                                            '$' + calculateTotal(payment?.document?.therapistTransaction[0]?.recentTransaction)
                                          }
                                        </span>
                                        <p>{moment(payment?.document?.timePeriodStartAt).format("YYYY-MM-DD")} - {moment(payment?.document?.timePeriodEndAt).format("YYYY-MM-DD")}</p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-4">
                            <h3 className="mb-3">This month Earnings</h3>

                            <div className="col-lg-12 es1">
                              <div className="earning-sec4">
                                <h5>Earnings</h5>

                                <span className="sub-item-black-text">${type1Meetings.length * 25 + type2Meetings.length * 50}</span>
                              </div>

                              <div className="earning-sec2"></div>

                              <div className="earning-sec4">
                                <h5>Total Meetings</h5>

                                <span className="sub-item-black-text">{monthlyMeetings && monthlyMeetings.length}</span>
                              </div>

                              <div className="earning-sec2"></div>

                              <div className="earning-sec4">
                                <h5>30min Sessions</h5>

                                <span className="sub-item-black-text">{type1Meetings && type1Meetings.length}</span>
                              </div>

                              <div className="earning-sec2"></div>

                              <div className="earning-sec4">
                                <h5>1hr Sessions</h5>

                                <span className="sub-item-black-text">{type2Meetings && type2Meetings.length}</span>
                              </div>

                              <div className="earning-sec2"></div>

                              <div className="earning-sec4 border-none">
                                <h5>Total Hours</h5>

                                <span className="sub-item-black-text">{Util.getHoursAndMinutes(type1Meetings.length * 30 + type2Meetings.length * 60)}</span>
                              </div>

                              <div className="earning-sec2"></div>

                              <div className="earning-sec4 border-none">
                                <h5>Rewards</h5>

                                <span className="sub-item-black-text">${rewardEarnings}</span>
                              </div>

                            </div>
                          </div>

                          <div className="row">
                            <div className="my-sub-sec withdraw-money">
                              {user?.stripeConnectedAccountId && user.stripeChargesEnabled && user.stripeDetailsSubmitted && (
                                <>
                                  {user?.stripeConnectedLoginLink ? (
                                    <Link to={{ pathname: user?.stripeConnectedLoginLink }} target="_blank" className="btn btn-info">
                                      Stripe Dashboard&nbsp;&nbsp;<i className="fa fa-bookmark"></i>
                                    </Link>
                                  ) : (
                                    <button
                                      className="btn btn-info"
                                      onClick={() => {
                                        createStripeDashboardLink();
                                      }}
                                    >
                                      Create Stripe Dashboard Link To Check Balance!
                                    </button>
                                  )}
                                </>
                              )}

                              {!user?.stripeConnectedAccountId ? (
                                registerLink == "" ? (
                                  <div>
                                    <CardTitle className="mb-4 font-size-20">First you need to register with Lavni Stripe Connect.</CardTitle>

                                    <div className="flex">
                                      <img
                                        src={stripeConnect}
                                        className="stripe-connect"
                                        onClick={() => {
                                          createStripeConnectedAccount();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <CardTitle className="mb-4 font-size-20">
                                      You need to complete stripe details in order to get paid on Lavni Platform.
                                    </CardTitle>

                                    <div className="flex">
                                      <Link to={{ pathname: registerLink }} target="_blank" className="btn btn-info">
                                        Complete Stripe Account&nbsp;&nbsp;<i className="fa fa-address-card"></i>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              ) : !user.stripeChargesEnabled || !user.stripeDetailsSubmitted ? (
                                <>
                                  {registerLink == "" ? (
                                    <div>
                                      <CardTitle className="mb-4 font-size-20">You need to verify Lavni Stripe Connect.</CardTitle>

                                      <div className="flex">
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => {
                                            verifyStripeAccount();
                                          }}
                                        >
                                          Verify Stripe Account!
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <CardTitle className="mb-4 font-size-20">
                                        You need to complete stripe details in order to get paid on Lavni Platform.
                                      </CardTitle>

                                      <div className="flex">
                                        <Link to={{ pathname: registerLink }} target="_blank" className="btn btn-info">
                                          Complete Stripe Account&nbsp;&nbsp;<i className="fa fa-address-card"></i>
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* {transactions && transactions.length > 0 && (
                                    <>
                                      {transactions[0].accumulatedBalance > 0 ? (
                                        <div className="mt30">
                                          <h4 className="mb-3">
                                            Your available balance{" "}
                                            <span className="sub-item4-text">${Math.round(transactions[0].accumulatedBalance * 100) / 100}</span> will be
                                            withdrwan automatically at the end of the month. ({firstDayOfNextMonth.toString()})
                                          </h4>
                                        </div>
                                      ) : (
                                        <div className="mt30">
                                          <h4 className="mb-3">You should have positive balance to withdraw funds!</h4>
                                        </div>
                                      )}
                                    </>
                                  )} */}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tab="Transactions" key="2">
                        <Row>
                          <Col sm="12" className="mt10">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Meeting Type</th>
                                  <th scope="col">Contract Price</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Accumulated Balance</th>
                                  <th scope="col">Total Earnings</th>
                                  <th scope="col">Client</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactions.length > 0 ? (
                                  <>
                                    {transactions.map((t, index) => (
                                      <tr key={index}>
                                        <td>{format(new Date(t.createdAt).getTime(), "MMMM do, yyyy H:mm a")}</td>
                                        <td>{t.type}</td>
                                        <td>
                                          {
                                            t?.meetingId?.meetingId?.includes("Regular") ?
                                              <div className="regular_call text-nowrap">Regular Call</div>
                                              : <div className="normal_call text-nowrap">Normal Call</div>
                                          }
                                        </td>
                                        <td>{user?.payRateType?.type == "PERCENTAGE_USER" && t?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.contractPrice ? t?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.contractPrice : '-'}</td>
                                        <td>
                                          {t.type == TransactionType.EARNING ? "+" : "-"}${Math.round(t.transactionAmount * 100) / 100}
                                        </td>
                                        <td>${Math.round(t.accumulatedBalance * 100) / 100}</td>
                                        <td>${Math.round(t.accumulatedTotalEarnings * 100) / 100}</td>
                                        <td>
                                          {t?.meetingId && t?.meetingId?.clientId ? (
                                            <Link to={`/profile/details/${t?.meetingId?.clientId?._id}`} className="btn btn-info btn-sm">
                                              {t?.meetingId?.clientId?.firstname} {t?.meetingId?.clientId?.lastname}
                                            </Link>
                                          ) : (
                                            <>-</>
                                          )}
                                        </td>
                                        <td>
                                          {t?.eligibleForPayment === false && t?.paidStatus !== "PAID" && (
                                            <>
                                              <label className="text-warning">
                                                Hold
                                              </label>

                                              <i className="bx bx-info-circle clarity-info" id="hold_reason"></i>

                                              <UncontrolledTooltip placement="auto-start" target={"hold_reason"}>
                                                The payment for this transaction is currently on hold due to the quality of the submitted assessment. Please check the assessment and improve the quality.
                                              </UncontrolledTooltip>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                    {tranceCount >= 10 && (
                                      <>
                                        <button className="btn btn-warning text-center mt-4 button_center" onClick={seeMore}>
                                          See More
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>{isLoading && <Spinner className="bouncer2" />}</>
                                )}
                              </tbody>
                            </table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tab="Meetings History" key="3">
                        <MeetingHistoryTab></MeetingHistoryTab>
                      </TabPane>

                      <TabPane tab="Rewards" key="4">
                      <Row>
                          <Col sm="12" className="mt10">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">Reward Type</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Paid Status</th>
                                  <th scope="col">From Therapist</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rewards.length > 0 ? (
                                  <>
                                    {rewards.map((t, index) => (
                                      <tr key={index}>
                                        <td>{format(new Date(t.createdAt).getTime(), "MMMM do, yyyy H:mm a")}</td>
                                        <td>{t.rewardType}</td>
                                        <td>${Math.round(t.transactionAmount * 100) / 100}</td>
                                        <td>
                                          {t.paidStatus === undefined ? (
                                            <div>
                                              <span className="pending-2">Pending</span>
                                            </div>
                                          ) : t.paidStatus === "PAID" ? (
                                            <div>
                                              <span className="verified-2">Paid</span>
                                            </div>
                                          ) : null}
                                        </td>
                                        <td>
                                          { (t?.rewardReferralId && t?.rewardReferralId?.referredUserId) ? (
                                            <>
                                              <Link to={"/profile/details/" + t?.rewardReferralId?.referredUserId?._id} className="btn btn-info btn-sm">
                                                {t?.rewardReferralId?.referredUserId?.firstname} {t?.rewardReferralId?.referredUserId?.lastname}
                                              </Link>
                                            </>
                                          ) : (
                                            <>-</>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                    {rewardCount >= 10 && (
                                      <>
                                        <button className="btn btn-warning text-center mt-4 button_center" onClick={seeMoreRewards}>
                                          See More
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>{isLoading && <Spinner className="bouncer2" />}</>
                                )}
                              </tbody>
                            </table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TherapistEarnings;
