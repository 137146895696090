import React from "react";

interface NoteHeaderProps {
  step: number;
}

const NoteHeader: React.FC<NoteHeaderProps> = ({ step }: NoteHeaderProps) => {
  return (
    <div className="flex justify-content-center mx-100-list">
      <div className="flex">
        <div className="bubble-wrap">
          <div className={step >= 1 ? "multistep-bubble-completed" : "multistep-bubble"}>
            <div className="bubble-text">S</div>
          </div>
        </div>
        <div className="align-self-center">
          <hr className="bubble-line mb-0"></hr>
        </div>
      </div>
      <div className="flex">
        <div className="bubble-wrap">
          <div className={step >= 2 ? "multistep-bubble-completed" : "multistep-bubble"}>
            <div className="bubble-text">0</div>
          </div>
        </div>
        <div className="align-self-center">
          <hr className="bubble-line mb-0"></hr>
        </div>
      </div>
      <div className="flex">
        <div className="bubble-wrap">
          <div className={step >= 3 ? "multistep-bubble-completed" : "multistep-bubble"}>
            <div className="bubble-text">A</div>
          </div>
        </div>
        <div className="align-self-center">
          <hr className="bubble-line mb-0"></hr>
        </div>
      </div>
      <div className="bubble-wrap">
        <div className={step >= 4 ? "multistep-bubble-completed" : "multistep-bubble"}>
          <div className="bubble-text">P</div>
        </div>
      </div>
    </div>
  );
};

export default NoteHeader;
