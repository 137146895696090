import { AuthFormType } from "./AuthorizationFormData";

export interface InsuranceCompany {
    _id: string;
    isPrimary?: boolean;
    name: string;
    fax?: string;
    authorizationFormAvailability?: boolean;
    authorizationForms: string;
    authFormType: AuthFormType;
    insuranceDocApprovalId?: string;
    therapistApprovalStatus?: string;
    adminApprovalStatus?: string;
    messageId?: string;
    messageStatus?: string;
    link: string;
}

export interface Client {
    _id: string;
    firstname?: string;
    lastname?: string;
    email?: string;
}

export interface Therapist {
    _id: string;
    firstname?: string;
    lastname?: string;
    email?: string;
}

export interface ClientInsuranceDocs {
    _id: string;
    insuranceCompanies: InsuranceCompany[];
    clinicalAssessment?: string;
    digitalAssessment?: string;
    therapyPlan?: string;
    meetingCount: number;
    client: Client;
    therapist?: Therapist;
}

export interface UploadedInsuranceDocument {
    _id: string;
    clinicalAssessmentUploadId?: string;
    therapyPlanUploadId?: string;
    authorizationFormUploadId?: string;
}

export interface FaxInfo {
    _id: string;
    messageId?: string;
    messageStatus?: string;
    fromPhoneNumber?: string;
    toPhoneNumber?: string;
    creationTime?: Date;
    lastModifiedTime?: Date;
    readStatus?: string;
    faxPageCount?: number;
}

export enum UploadCategory {
    CLINICAL_ASSESSMENT = "CLINICAL_ASSESSMENT",
    THERAPY_PLAN = "THERAPY_PLAN",
    AUTHORIZATION_FORM = "AUTHORIZATION_FORM",
}

export enum SubmissionApprovalStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}
