import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";

const HideTimerWhenCallModal: React.FC<{
  showModal: boolean;
  hideTimer: (hide: boolean, hidePermanently: boolean) => void;
}> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.hideTimer(false, false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">Do you want to remove this timer for all calls or just close it for now?</CardTitle>
            <div className="d-flex justify-content-around flexColumn">
              <button type="button" onClick={() => props.hideTimer(true, true)} className="btn btn-primary">
                For All
              </button>
              <button type="button" onClick={() => props.hideTimer(true, false)} className="btn btn-primary btnMargin">
                For Now
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default HideTimerWhenCallModal;

