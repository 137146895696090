export interface test {
  name: string;
}

export interface formHeader {
  date?: Date | string;
  insuranceId?: string;
  lastName?: string;
  firstname?: string;
  mi?: string;
  currentAddress?:{
    streetAddress?: string,
    unit?: string,
    city?: string,
    state?: string,
    zipCode?: string,
  },
}

export interface generalInformationModel {
  name?: string;
  dob?: Date | string;
  knownAs?: string;
  age?: string | number;
  primaryLanguage?: PrimaryLanguageType;
  consumerPhone?: ConsumerPhoneType;
  legalGuarianship?: LegalGuardianshipType;
  guardianPhone?: GuardianPhoneType;
  primaryInformant?: PrimaryInformantType;
  informantPhone?: InformantPhoneType;
  genderAtBirth?: GenderAtBirthType;
  genderIdentity?: GenderIdentitytype;
  sexualOrientation?: SexualOrientationType;
  ethnicity?: EthnicityTypes;
  raceType?: RaceType;
  livingArrangement?: LivingArrangementType;
  maritalStatus?: MaritalStatusType;
  familySize?: string;
  currentEmploymentStatus?: CurrentEmploymentHistory;
  employmentHistory?: string;

  education?: EducationType;
}

export interface SexualOrientationType {
  sexualOrientations?: string;
  isInterestingLGBTService?: boolean | null;
}

export interface PrimaryInformantType {
  primaryInformant?: {
    self?: boolean | null;
    other?: boolean | null;
  };
  other?: string;
}

export interface InformantPhoneType {
  sameasAbove?: boolean | null;
  home?: boolean | null;
  work?: boolean | null;
  cell?: boolean | null;
  informantPhoneNumber?: string;
}

export interface GuardianPhoneType {
  sameasAbove?: boolean | null;
  home?: boolean | null;
  work?: boolean | null;
  cell?: boolean | null;
  guardianPhoneNumber?: string;
}

export interface PrimaryLanguageType {
  primaryLanguageReadablityStatus?: boolean | null;
  english?: boolean | null;
  spanish?: boolean | null;
  other?: boolean | null;
  otherLanguage?: string;
}
export interface ConsumerPhoneType {
  consumerPhone?: {
    home?: boolean | null;
    work?: boolean | null;
    cellPhone?: boolean | null;
    other?: boolean | null;
  };
  otherConsumerPhoneType?: string;
  otherConsumerPhoneNumber?: string;
}

export interface MaritalStatusType {
  married?: boolean | null;
  widowed?: boolean | null;
  divorced?: boolean | null;
  singleNeverMarried?: boolean | null;
  separated?: boolean | null;
  domesticPartnership?: boolean | null;
}

export interface EducationType {
  highestGradeCompleted?: boolean | null;
  highSchoolgraduate?: boolean | null;
  ged?: boolean | null;
  someCollege?: boolean | null;
  associateDegree?: boolean | null;
  bachelordegree?: boolean | null;
  graduateSchool?: boolean | null;
  specialEdClasses?: boolean | null;
  technicalTradeSchool?: boolean | null;
}

export interface CurrentEmploymentHistory {
  unemployed?: boolean | null;
  notavailableforwork?: boolean | null;
  employedFullTime?: boolean | null;
  homemaker?: boolean | null;
  armedForcesNationalGuard?: boolean | null;
  employedPartTime?: boolean | null;
  retired?: boolean | null;
  student?: boolean | null;
  disabilityIncome?: boolean | null;
}

export interface EthnicityTypes {
  notHispanicOrigin?: boolean | null;
  hispanicCuban?: boolean | null;
  hispanicMexicanAmerican?: boolean | null;
  hispanicPuertoRican?: boolean | null;
  hispanicOther?: boolean | null;
}

export interface GenderIdentitytype {
  male?: boolean | null;
  female?: boolean | null;
  nonBinary?: boolean | null;
}

export interface GenderAtBirthType {
  male?: boolean | null;
  female?: boolean | null;
  femalePregnant?: boolean | null;
}

export interface LegalGuardianshipType {
  legalGuarianship?: {
    self?: boolean | null;
    other?: boolean | null;
  };
  otherLegalGuarianship?: string;
}

export interface LivingArrangementType {
  livingArrangement?: {
    privateResidence?: boolean | null;
    institution?: boolean | null;
    ownResidence?: boolean | null;
    other?: boolean | null;
    roomHouseDorm?: boolean | null;
    adultCareHome?: boolean | null;
    alternativeFamilyLiving?: boolean | null;
    nursingHome?: boolean | null;
    communityICFMR?: boolean | null;
    correctionalFacility?: boolean | null;
    homeless?: boolean | null;
    residentialFacility?: boolean | null;
  };
  other?: string;
}

export interface RaceType {
  race?: {
    blackAfricanAmerican?: boolean | null;
    alaskaNative?: boolean | null;
    whiteCaucasian?: boolean | null;
    asian?: boolean | null;
    americanIndianNativeAmerican?: boolean | null;
    pacificIslander?: boolean | null;
    multiracial?: boolean | null;
    other?: boolean | null;
  };
  otherRace?: string;
}

export interface GenderAtBirth {
  genderAtBirth: GenderBirth;
}

export interface PresentingProblem {
  description?: string;
  historyOfProblem?: string;
}

export interface HistoryOfProblem {
  historyOfProblem?: string;
}

export interface SymptomChicklist {
  depressiveSymptoms?: {
    symtoms?: DepressiveSymptomsType;
    comment?: string;
  };
  manicSymptoms?: {
    symtoms?: ManicSymptomsType;
    comment?: string;
  };
  conductLegalProblem?: {
    symtoms?: ConductLegalProblemType;
    comment?: string;
  };
  psychosis?: {
    symtoms?: PsychosisType;
    comment?: string;
  };
  anxietySymptoms?: {
    symtoms?: AnxietySymptomsType;
    comment?: string;
  };
  attentionSymptoms?: {
    symtoms?: AttentionSymptomsType;
    comment?: string;
  };
}

export interface DepressiveSymptomsType {
  sadnessFeelsEmpty?: string;
  insomniaHypersomnia?: string;
  fatigueOrLossOfEnergy?: string;
  crying?: string;
  psychomotorAgitationOrRetardation?: string;
  diminishedAbilityToThinkOrConcentrate?: string;
  diminishedInterest?: string;
  significantWeightLossorGainORDecrease?: string;
  recurrentThoughtsOfDeath?: string;
  feelingsOfWorthlessness?: string;
  none?: string;
}

export interface ManicSymptomsType {
  irrationalAnger?: string;
  decreasedSleep?: string;
  irritability?: string;
  distractibility?: string;
  moreTalkative?: string;
  flightOfIdeas?: string;
  inflatedselfEsteem?: string;
  increaseInGoalDirectedActivity?: string;
  excessiveInvolvement?: string;
  none?: string;
}

export interface ConductLegalProblemType {
  fireSetting?: string;
  lying?: string;
  stealing?: string;
  fighting?: string;
  substanceAbuse?: string;
  none?: string;
  oppositionalDefiant?: string;
  gangInvolvement?: string;
  arrestsConviction?: string;
  impulsivity?: string;
  familyDesertion?: string;
  exhibitionism?: string;
  sexualActingOut?: string;
  consistentIrresponsibility?: string;
  propertyDestruction?: string;
  other?: string;
}

export interface PsychosisType {
  delusions?: string;
  paranoia?: string;
  sensoryHallucinations?: string;
  auditoryHallucinations?: string;
  visualHallucinations?: string;
  none?: string;
  ideasOfReference?: string;
  disorganizedSpeech?: string;
  other?: string;
}

export interface AnxietySymptomsType {
  anxietyWorry?: string;
  avoidance?: string;
  panicAttacks?: string;
  obsessions?: string;
  compulsions?: string;
  markedlyDiminished?: string;
  intensePsychological?: string;
  feelingOFDetachment?: string;
  somaticComplaints?: string;
  dissociativeEpisodes?: string;
  restrictedAffect?: string;
  intrusivereOccurringThoughts?: string;
  difficultyConcentrating?: string;
  hypervigilance?: string;
  exaggeratedStartleResponse?: string;
  nightmaresReoccurringDreams?: string;
  irritabilityOrOutburstsOfAnger?: string;
  senseOfForeshortenedFuture?: string;
  avoidanceOfActivities?: string;
  unwantedMemories?: string;
  none?: string;
}

export interface AttentionSymptomsType {
  failsToFinishTasks?: string;
  inattentive?: string;
  fidgety?: string;
  forgetful?: string;
  difficultyFollowingDirections?: string;
  difficultyOrganizingThoughts?: string;
  difficultyAwaitingTurn?: string;
  poorAttentionSpan?: string;
  talksExcessively?: string;
  interruptsOrIntrudesOnOthers?: string;
  feelsAlways?: string;
  failsToGiveAttention?: string;
  avoidsDislikesTasks?: string;
  easilyDistracted?: string;
  blurtsOutAnswersBeforeQuestions?: string;
  none?: string;
}

export interface BiologicalFunctions {
  sleep?: {
    sleepStatus?: SleepType;
    comment?: string;
  };
  nutritionalStatus?: {
    nutritionalStatus?: NutritionalStatusType;
    comment?: string;
  };

  otherBiologicalFunction?: OtherBiologicalFunctionType;
  sexualActivity?: SexualActivity;
}

export interface SexualActivity {
  sexuallyActive?: {
    sexuallyActiveness?: boolean | null;
    activeWith?: string;
  };
  protectionAgainstHepatitisHiv?: {
    protection?: boolean | null;
    how?: string;
  };

  protectionAgainstPregnancy?: {
    protection?: boolean | null;
    how?: string;
  };

  atRiskBehavior?: {
    risk?: boolean | null;
    describe?: string;
  };
  otherSymtoms?: string;
  comments?: string;
}

export interface NutritionalStatusType {
  increasedAppetite?: string;
  weightloss?: string;
  bingeing?: string;
  dentalProblems?: string;
  decreasedAppetite?: string;
  eatingdisorder?: string;
  foodAllergies?: string;
  weightgain?: string;
  noDifficulties?: string;
}

export interface SleepType {
  increased?: string;
  decreased?: string;
  restless?: string;
  difficultyFallingaSleep?: string;
  difficultyRemainingAsleep?: string;
  earlyMorningAwakening?: string;
  noDifficulties?: string;
}

export enum Sleep {
  increased = "Increased",
  decreased = "Decreased",
  restless = "Restless",
  difficultyFallingaSleep = "Difficulty falling asleep",
  difficultyRemainingAsleep = "Difficulty remaining asleep",
  earlyMorningAwakening = "Early morning awakening",
  noDifficulties = "No difficulties",
}

export interface OtherBiologicalFunctionType {
  amenorrhea?: string;
  encopresis?: string;
  increased?: string;
  decreased?: string;
  other?: string;
  enuresis?: string;
}
//note this for
export interface AlcoholAndDrugUseHistory {
  historyStatus: string;
}

export interface NameOfSubstance {
  ageOfFirstUse: string;
  regularUse: string;
  dateLastUse: Date | string;
  route: string;
  amount: number | string;
  frequency: number | string;
  problemWithUse: problemWithUseType;
  comment: string;
}

export interface problemWithUseType {
  tolerance?: string;
  withdrawal?: string;
  social?: string;
  legal?: string;
  financial?: string;
  increasedAmount?: string;
  desireToCutDown?: string;
  usewhilehazardous?: string;
  other?: string;
  occupational?: string;
  timeDevoted?: string;
  physicalAndOrPsychological?: string;
}

export interface TobaccoUseType {
  tobaccoUse?: boolean | null;
  tobaccoType?: string;
  howOften?: string;
  howMuch?: string;
  howLong?: string;
  interestEndUse?: boolean | null;
  interestReducingUse?: boolean | null;
  endingUse?: string;
  longestAbstinence?: string;
  whenWas?: string;
  relapse?: string;
  comment?: string;
  experiencedSymptoms?: ExperienceSymptomsType;
}

export enum TobaccoUseTypeStatus {
  yes = "YES",
  no = "NO",
}

export interface ExperienceSymptomsType {
  agitationRestlessness?: string;
  confusion?: string;
  nauseaVomiting?: string;
  muscleTwitching?: string;
  chills?: string;
  deliriumTremens?: string;
  tremorsShaking?: string;
  anxiety?: string;
  racingPulse?: string;
  rapidBreathing?: string;
  sweats?: string;
  hallucinations?: string;
  backJointPain?: string;
  other?: string;
  difficultySleeping?: string;
  runnyNose?: string;
  tearyEyes?: string;
  craving?: string;
  cramps?: string;
  seizures?: string;
  none?: string;
}

export interface ReleventMedicalInformation {
  physicianDiagnosedConditions?: PhysicianDiagnosedConditionsType;
  cancerType?: string;
  otherType?: string;
  lastVisitPrimaryCarePhysician?: string;
  comment?: string;
  anyAllergies?: string;
  hypersensitivities?: string;
  patientPainLevel?: PatientPainLevelType;
}

export interface PatientPainLevelType {
  currentLevelOfPhysicalPain?: number | string;
  isCurrentReceivingTreatementPain?: string;
  isReceivingTreatement?: string;
  treatementDetails?: TreatementDetails[];
  disablityStatus?: DisabilityStatusType;
  adjustDisability?: string;
  requireEquipmentOrServices?: string;
}

export interface TreatementDetails {
  medication?: string;
  does?: string;
  frequency?: string;
  lastUse?: Date | string;
}

export interface PhysicianDiagnosedConditionsType {
  allergies?: string;
  gynecological?: string;
  pancreatitis?: string;
  anemia?: string;
  headInjury?: string;
  respiratory?: string;
  arthritis?: string;
  heartDisease?: string;
  seizureDisorder?: string;
  asthma?: string;
  hepatitis?: string;
  std?: string;
  brainDisorder?: string;
  highBloodPressure?: string;
  stroke?: string;
  cancer?: string;
  lowBloodPressure?: string;
  thyroidDisease?: string;
  chronicPain?: string;
  cirrhosisoftheliver?: string;
  immuneDisease?: string;
  tuberculosis?: string;
  diabetes?: string;
  kidneyDisease?: string;
  ulcer?: string;
  eatingDisorder?: string;
  muscleDisorder?: string;
  mensHealthProblems?: string;
  hivAids?: string;
  none?: string;
  other?: string;
}

export interface DisabilityStatusType {
  hearingImpairment?: string;
  sightImpairment?: string;
  intellectualDevelopmentalDisability?: string;
  other?: string;
  none?: string;
  comment?: string;
}

export enum ReceivingTreatementStatus {
  yes = "YES",
  no = "NO",
}

export enum ReceivingTreatementPainStatus {
  na = "N/A",
  no = "No",
}

export enum PhysicianDiagnosedConditions {
  allergies = "Allergies",
  gynecological = "Gynecological",
  pancreatitis = "Pancreatitis",
  anemia = "Anemia",
  headInjury = "Head Injury",
  respiratory = "Respiratory",
  arthritis = "Arthritis",
  heartDisease = "Heart Disease",
  seizureDisorder = "Seizure Disorder",
  asthma = "Asthma",
  hepatitis = "Hepatitis",
  std = "STD",
  brainDisorder = "Brain Disorder",
  highBloodPressure = "High Blood Pressure",
  stroke = "Stroke",
  cancer = "Cancer",
  lowBloodPressure = "Low Blood Pressure",
  thyroidDisease = "Thyroid Disease",
  chronicPain = "Chronic Pain",
  cirrhosisoftheliver = "Cirrhosis of the liver",
  immuneDisease = "Immune Disease",
  tuberculosis = "Tuberculosis",
  diabetes = "Diabetes",
  kidneyDisease = "Kidney Disease",
  ulcer = "Ulcer",
  eatingDisorder = "Eating Disorder",
  muscleDisorder = "Muscle Disorder",
  mensHealthProblems = "Men’s Health Problems",
  hivAids = "HIV/AIDS",
  none = "None",
  other = "Other",
}

//biopsycho development history
export interface BioPsychosocialDevelopemntHistory {
  developmentHistory?: string;
  childhoodHistory?: string;
  fosterCare?: string;
  siblings?: string;
  familyHistoryOfMHOrSAIssues?: string;
  currentSpouse?: string;
  childrenStepChildren?: string;
  relationsIssues?: string;
  otherSupports?: OtherSupportType;
}

export interface OtherSupportType {
  family?: string;
  church?: string;
  employer?: string;
  friends?: string;
  other?: string;
  otherSupport?: string;
}

//end biopsychosocial development history

//education page 7
export interface BioPsychosocialEducation {
  isApplicable?: boolean | null;
  schoolGradeName?: string;
  lastIep?: string;
  teacher?: string;
  learningAbility?: string;
  disabilityArea?: string;
  educationalProblems?: string;
  behaviorProblem?: string;
  repetedGrades?: string;
  socialInteraction?: string;
  suspension?: string;
  expulsion?: string;
  isPsychologicalTestingCompleted?: boolean | null;
  date?: Date | string;
  expulsionTestResults?: string;
}

export enum PsychologicalTestingCompletedStatus {
  yes = "YES",
  no = "NO",
}

//end education page 7

//page 8
export interface TraumaHistory {
  isBadAccident?: boolean | null;
  badAccidentDescribe?: string;
  isNaturalDisaster?: boolean | null;
  naturalDisasterdDescribe?: string;
  isMilitaryCombat?: boolean | null;
  militoryCombatDescribe?: string;
  isSeriousAttack?: boolean | null;
  seriousAttackDescribe?: string;
  isSexualCoercion?: boolean | null;
  ageOfSexualCoercion?: number;
  describeSexualCoercion?: string;
  isTraumaticExperience?: boolean | null;
  describeTraumaticExperience?: string;
  intrusiveThoughts?: boolean | null;
  avoidanceBehavior?: boolean | null;
  hypervigilance?: boolean | null;
  emotionalDetachment?: boolean | null;
  selfBlame?: boolean | null;
}

export enum TraumaHistoryBadAccidentStats {
  yes = "YES",
  no = "NO",
}

//end page 8

//page 9 start
export interface RiskToSelfAndOthers {
  currentRiskToSelf?: CurrentRiskToSelf;
  currentRiskToOthers?: CurrentRiskToOthers;
  historyOfSuicidalBehavior?: HistoryOfSuicidalBehavior;
  evaluationOfRisk?: EvaluationOfRisk;
}

export interface EvaluationOfRisk {
  selfHarming?: {
    lowToNoRisk?: boolean | null;
    moderateRisk?: boolean | null;
    highRisk?: boolean | null;
    imminentRisk?: boolean | null;
  };
  assaultive?: {
    lowToNoRisk?: boolean | null;
    moderateRisk?: boolean | null;
    highRisk?: boolean | null;
    imminentRisk?: boolean | null;
  };
  actionEvaluation?: string;
  beliefSystem?: string;
  roleOfBeliefinlife?: string;
  roleOfBeliefRecovery?: string;
}

export interface HistoryOfSuicidalBehavior {
  describeHomicidalBehavior?: string;
  describeSeriousPhysicalHarm?: string;
  describeAggression?: string;
  describeHistoryOfSuicidalBehavior?: string;

  isHistoryOfSuicidalBehavior?: boolean | null;
  isSeriousHarmSelfOthers?: boolean | null;
  isHomicidalBehavior?: boolean | null;
  isAggressionViolenceOthers?: boolean | null;
}

export interface CurrentRiskToOthers {
  isHomicidalThreats: boolean | null;
  isPersistentIdeation?: boolean | null;
  isSpecificPlan?: boolean | null;
  isLethalIntent?: boolean | null;
  isHallucinationCommands?: boolean | null;
  describeHallucination?: string;
}

export interface CurrentRiskToSelf {
  isSelfInjured?: boolean | null;
  repetitiveIdeation?: boolean | null;
  isSuicidePlan?: boolean | null;
  isLethalIntent?: boolean | null;
  isCommandHallucinations: boolean | null;
  isSuicidalThreats?: boolean | null;
  describeSelfInjured?: string;
  describeSuicidalThreats?: string;
  describeCommandHallucinations?: string;
}

export enum HistoryOfSuicidalBehaviorEnum {
  yes = "YES",
  no = "NO",
}

export enum CurrentRiskToOthersEnum {
  yes = "YES",
  no = "No",
}

export enum CurrentRiskToSelfEnum {
  yes = "YES",
  no = "No",
}

//page 9 end

//page 10 start
export interface MentalStatus {
  physicalStature?: {
    small?: boolean | null;
    average?: boolean | null;
    tall?: boolean | null;
  };
  weight?: {
    thin?: boolean | null;
    average?: boolean | null;
    overweight?: boolean | null;
    obese?: boolean | null;
  };
  grooming?: {
    wellGroomed?: boolean | null;
    normal?: boolean | null;
    neglected?: boolean | null;
    bizarre?: boolean | null;
  };
  clothing?: {
    neatClean?: boolean | null;
    inappropriate?: boolean | null;
    dirty?: boolean | null;
    seductive?: boolean | null;
    disheveled?: boolean | null;
    bizarre?: boolean | null;
  };
  posture?: {
    normal?: boolean | null;
    tense?: boolean | null;
    stooped?: boolean | null;
    rigid?: boolean | null;
    slumped?: boolean | null;
    bizarre?: boolean | null;
  };
  attitude?: {
    cooperative?: boolean | null;
    passive?: boolean | null;
    guarded?: boolean | null;
    irritable?: boolean | null;
    manipulative?: boolean | null;
    seductive?: boolean | null;
    suspicious?: boolean | null;
    defensive?: boolean | null;
    dramatic?: boolean | null;
    silly?: boolean | null;
    hostile?: boolean | null;
    critical?: boolean | null;
    resistant?: boolean | null;
    sarcastic?: boolean | null;
    uninterested?: boolean | null;
    argumentative?: boolean | null;
  };
  motor?: {
    nonremarkable?: boolean | null;
    tremor?: boolean | null;
    slowed?: boolean | null;
    tics?: boolean | null;
    restless?: boolean | null;
    agitated?: boolean | null;
  };
  speech?: {
    normal?: boolean | null;
    rapid?: boolean | null;
    slurred?: boolean | null;
    loud?: boolean | null;
    paucity?: boolean | null;
    pressured?: boolean | null;
    mute?: boolean | null;
  };
  affect?: {
    appropriate?: boolean | null;
    inappropriate?: boolean | null;
    flat?: boolean | null;
    restricted?: boolean | null;
    blunted?: boolean | null;
    labile?: boolean | null;
  };
  mood?: {
    euthymic?: boolean | null;
    confused?: boolean | null;
    pessimistic?: boolean | null;
    depressed?: boolean | null;
    anxious?: boolean | null;
    euphoric?: boolean | null;
    apathetic?: boolean | null;
    angry?: boolean | null;
  };
  thoughtForm?: {
    goaldirected?: boolean | null;
    appropriate?: boolean | null;
    logical?: boolean | null;
    tangentialthinking?: boolean | null;
    circumstantial?: boolean | null;
    looseassociations?: boolean | null;
    confused?: boolean | null;
    incoherent?: boolean | null;
    perseverations?: boolean | null;
    flightofidea?: boolean | null;
    slownessofthought?: boolean | null;
  };
  thoughtContent?: {
    appropriate?: boolean | null;
    paranoid?: boolean | null;
    suspicions?: boolean | null;
    persecutions?: boolean | null;
    paucity?: boolean | null;
    delusions?: boolean | null;
    bizarre?: boolean | null;
    hypochondriac?: boolean | null;
    ideasofreference?: boolean | null;
  };
  preoccupations?: {
    phobias?: boolean | null;
    guilt?: boolean | null;
    other?: boolean | null;
    somatic?: boolean | null;
    religion?: boolean | null;
    suicide?: boolean | null;
    homicidal?: boolean | null;
  };
  hallucinations?: {
    auditory?: boolean | null;
    other?: boolean | null;
    visual?: boolean | null;
    sensory?: boolean | null;
  };
  orientation?: OrientationType;
  levelOfIntellectualFunctioning?: {
    belowAverage?: boolean | null;
    average?: boolean | null;
    aboveAverage?: boolean | null;
  };
  fundofKnowledge?: {
    belowAverage?: boolean | null;
    average?: boolean | null;
    aboveAverage?: boolean | null;
  };
  judgment?: {
    belowAverage?: boolean | null;
    average?: boolean | null;
    aboveAverage?: boolean | null;
  };
  insightIntoProblems?: {
    belowAverage?: boolean | null;
    average?: boolean | null;
    aboveAverage?: boolean | null;
  };
  clinicalImpressionSummary?: string;
}

export interface OrientationType {
  person?: boolean | null;
  place?: boolean | null;
  time?: boolean | null;
  situation?: boolean | null;
}

export enum OrientationEnumType {
  yes = "YES",
  no = "NO",
}

//page 10 end

//page 11
export interface DiagnoseRecommendationDetails {
  diagnosis?: Diagnose[];
  recommendation?: RecommendationsType;
  printedName?: string;
  signature?: any;
  date?: string;
  clientSignature?: string;
  dateOfClinicianSignature?: string;
}

export interface Diagnose {
  code?: string;
  diagnosis?: DiagnoseType[];
}

export interface DiagnoseType {
  value?: string;
  label?: string;
}

// export interface Recommendations {
//   asamLevelCareRecommended?: RecommendationsType;
// }

export interface RecommendationsType {
  notApplicable?: string;
  levelSAIOP?: string;
  levelResidential?: string;
  levelMedicalDetox?: string;
  levelOutpatienttreatment?: string;
  levelComprehensiveoutpatient?: string;
  levelNonhospital?: string;
}
//page 11 end

export interface OutPatientTreatementHistory {
  applicableState?: boolean | null;
  outPatienttreatementHistoryDetails?: treatementHistoryDetailsType[] | [];
}

export interface treatementHistoryDetailsType {
  agencyOrService?: string;
  dateOfTreatement?: Date | string;
  diagnosis?: string;
  medication?: string;
}

export interface InpatientTreatementHistory {
  applicableState?: string;
  inPatientTreatementHistory?: InPatienttreatementHistoryDetails[] | [];
  pastTreatement?: string;
  additionalMedicalInformation?: string;
}

export interface InPatienttreatementHistoryDetails {
  treatementProgram?: string;
  dateOfTreatements?: Date | string;
  reasonForTreatement?: string;
  diagnosis?: string;
  medication?: string;
}

export enum InpatientTreatementHistoryApplicableEnum {
  yes = "YES",
  no = "No",
}

export interface ReleventDDSInformation {
  ddsInvolvement?: boolean | null;
  ddsCare?: string;
  ddsCareDescription?: string;
  ddsSocialWorker?: string;
  phone?: string;
}

export enum DDSInvolvmentType {
  yes = "YES",
  no = "NO",
}

export interface RelevantLegalInformation {
  legalInvolvement?: boolean | null;
  countOfArrestet?: number;
  currentCharges?: string;
  attorneyName?: string;
  currentProbationRequirement?: string;
  probationOffice?: string;
  previousChargesJailOrPrison?: string;
}

export enum DDSInvolvmentLegalType {
  yes = "YES",
  no = "NO",
}

export interface LastVisitPrimaryCarePhysicianType {
  unableToRecall?: string;
  greaterThanYear?: string;
}

export enum DiagnosesRecommendations {
  notApplicable = " Not applicable (no substance abuse)",
  levelSAIOP = "Level 2.1 (SAIOP)",
  levelResidential = "Level 3.1, 3.3, 3.5 Residential",
  levelMedicalDetox = "Level 4 Medical Detox",
  levelOutpatienttreatment = "Level 1 Outpatient treatment",
  levelComprehensiveoutpatient = "Level 2.5 Comprehensive outpatient (SACOT)",
  levelNonhospital = "Level 3.7 Non-hospital medical detox",
}

export enum SelfHarming {
  lowtoNorisk = "Low to no risk",
  moderateRisk = "Moderate risk",
  highRisk = "High risk",
  imminentRisk = "Imminent risk",
}

export enum HormicidalAssaultive {
  LowtoNorisk = "Low to no risk",
  ModerateRisk = "Moderate risk",
  HighRisk = "High risk",
  ImminentRisk = "Imminent risk",
}

export enum OtherSupports {
  family = "Family",
  church = "Church",
  employer = "Employer",
  friends = "Friends",
  other = "Other",
}

export enum DDsCare {
  substantiated = "Substantiated",
  investigation = "Investigation",
  closed = "Closed",
}

export enum DisabilityStatus {
  hearingImpairment = "Hearing Impairment",
  sightImpairment = "Sight Impairment",
  intellectualDevelopmentalDisability = "Intellectual/Developmental Disability",
  other = "Other",
  none = "None",
}

export enum LastVisitPrimaryCarePhysician {
  unableToRecall = "Client unable to recall date of last visit",
  greaterThanYear = "Greater than 1 year,patient advised to see primary care doctor.",
}

export enum ExperiencedSymptoms {
  agitationRestlessness = "  Agitation/Restlessness/Irritability",
  confusion = "Confusion",
  nauseaVomiting = "Nausea/Vomiting",
  muscleTwitching = "Muscle Twitching",
  chills = "Chills",
  deliriumTremens = "Delirium Tremens",
  tremorsShaking = "Tremors/Shaking",
  anxiety = "Anxiety",
  racingPulse = "Racing Pulse",
  rapidBreathing = "Rapid Breathing",
  sweats = "Sweats",
  hallucinations = "Hallucinations",
  backJointPain = "Back/Joint Pain",
  other = "Other:",
  difficultySleeping = "Difficulty Sleeping",
  runnyNose = "Runny Nose",
  tearyEyes = "Teary Eyes",
  craving = "Craving",
  cramps = "Cramps",
  seizures = "Seizures",
  none = "None",
}

export enum ProblemWithUse {
  Tolerance = "Tolerance",
  Withdrawal = "Withdrawal",
  Social = "Social",
  Legal = "Legal",
  Financial = "Financial",
  IncreasedAmount = "IncreasedAmount",
  DesireToCutDown = "Desire to cut down",
  Usewhilehazardous = "Use whilehazardous",
  Other = "Other",
  Occupational = "Occupational",
  TimeDevoted = "Time devoted to using & seeking",
  PhysicalAndOrPsychological = "Physical and/or psychological problems with continued use ",
}

export enum AlcoholAndDrugUseHistoryStatus {
  currentUse = "Current Use",
  denies = "Denies",
  unknown = "Unknown ",
}

export enum SexualActivityStatus {
  NotSexuallyActive = "Not Sexually Active",
  SexuallyActive = "Sexually Active",
}

export enum SexualActivityWith {
  men = "Men",
  women = "Women",
  both = "Both",
}

export enum ProtectionAgainstHepatitisHiv {
  yes = "Yes",
  no = "No",
}

export enum UnwantedPregnancy {
  yes = "Yes",
  no = "No",
}

export enum AtRisk {
  yes = "Yes",
  no = "No",
}

export enum OtherBiologicalFunction {
  amenorrhea = "Amenorrhea (cessation of the period)",
  encopresis = "Encopresis (fecal incontinence)",
  increased = "Increased libido",
  decreased = "Decreased libido",
  other = "Other",
  enuresis = "Enuresis (urinary incontinence)",
}

export enum NutritionalStatus {
  increasedAppetite = "Increased Appetite/Food Intake",
  weightloss = "Weight loss of 10lbs or more in the last 3 months",
  bingeing = "Bingeing",
  dentalProblems = "Dental Problems",
  decreasedAppetite = "Decreased Appetite/Food Intake",
  eatingdisorder = "Eating disorder-related habits and/or behaviors",
  foodAllergies = "Food Allergies",
  weightgain = "Weight gain of 10lbs or more in the last 3 months",
  noDifficulties = "No Difficulties",
}

export enum AttentionSymptoms {
  failsToFinishTasks = "Fails to finish tasks",
  inattentive = "Inattentive",
  fidgety = "Fidgety",
  forgetful = "Forgetful",
  difficultyFollowingDirections = "Difficulty following directions",
  difficultyOrganizingThoughts = "Difficulty organizing thoughts",
  difficultyAwaitingTurn = "Difficulty awaiting turn",
  poorAttentionSpan = "Poor attention span",
  talksExcessively = "Talks excessively",
  interruptsOrIntrudesOnOthers = "Interrupts or intrudes on others",
  FeelsAlways = "Feels always “on the go”,run like a motor",
  FailsToGiveAttention = "Fails to give attention to detail",
  AvoidsDislikesTasks = "Avoids,dislikes tasks that require sustained mental effort",
  EasilyDistracted = "Easily distracted by extraneous stimuli",
  BlurtsOutAnswersBeforeQuestions = "Blurts out answers before questions have been completed",
  none = "None",
}

export enum AnxietySymptoms {
  anxietyWorry = "Anxiety/worry",
  avoidance = "Avoidance",
  panicAttacks = "Panic Attacks",
  obsessions = "Obsessions",
  compulsions = "Compulsions",
  markedlyDiminished = "Markedly diminished interest/participation in usual activities",
  intensePsychological = "Intense psychological or physical reactivity to exposure to cues that represent traumatic event",
  feelingOFDetachment = "Feeling of detachment or estrangement from others",
  somaticComplaints = "Somatic complaints",
  dissociativeEpisodes = "Dissociative episodes",
  restrictedAffect = "Restricted affect",
  intrusivereOccurringThoughts = "Intrusivere occurring thoughts",
  difficultyConcentrating = "Difficulty concentrating",
  hypervigilance = "Hypervigilance",
  exaggeratedStartleResponse = "Exaggerated startle response",
  nightmaresReoccurringDreams = "Nightmares, reoccurring dreams",
  irritabilityOrOutburstsOfAnger = "Irritability or outbursts of anger",
  senseOfForeshortenedFuture = "Sense of foreshortened future",
  avoidanceOfActivities = "Avoidance of activities or situations that remind of a stressful experience",
  unwantedMemories = "Unwanted memories,thoughts,or images of a stressful experience/flashbacks",
  none = "None",
}

export enum Psychosis {
  delusions = "Delusions",
  paranoia = "Paranoia",
  sensoryHallucinations = "Sensory hallucinations",
  AuditoryHallucinations = "Auditory hallucinations",
  visualHallucinations = "Visual hallucinations",
  none = "None",
  ideasOfReference = "Ideas of reference",
  disorganizedSpeech = "Disorganized speech and/or behavior",
  other = "Other",
}

export enum ConductLegalProblem {
  fireSetting = "Fire Setting",
  lying = "Lying",
  stealing = "Stealing",
  fighting = "Fighting",
  substanceAbuse = "Substance Abuse",
  none = "None",
  oppositionalDefiant = "Oppositional Defiant",
  gangInvolvement = "Gang Involvement",
  arrestsConviction = "Arrests/Conviction",
  impulsivity = "Impulsivity",
  familyDesertion = "Family Desertion",
  exhibitionism = "Exhibitionism",
  sexualActingOut = "Sexual Acting Out",
  consistentIrresponsibility = "Consistent Irresponsibility",
  propertyDestruction = "Property Destruction",
  Other = "Other",
}

export enum ManicSymptoms {
  irrationalAnger = "Irrational anger",
  decreasedSleep = "Decreased need for sleep",
  irritability = "Irritability",
  distractibility = "Distractibility",
  moreTalkative = "More talkative than usual or pressure to keep talking",
  flightOfIdeas = "Flight of ideas or feeling like thoughts are racing",
  inflatedselfEsteem = "Inflated self-esteem or grandiosity",
  increaseInGoalDirectedActivity = "Increase in goal-directed activity or psychomotor agitation",
  excessiveInvolvement = "Excessive involvement in pleasurable activities that have a high potential for painful consequence",
  none = "None",
}

export enum DepressiveSymptoms {
  sadnessFeelsEmpty = "sadness/Feels Empty",
  InsomniaHypersomnia = "Insomnia/Hypersomnia",
  FatigueOrLossOfEnergy = "Fatigue or loss of energy",
  Crying = "Crying",
  PsychomotorAgitationOrRetardation = "Psychomotor agitation or retardation",
  DiminishedAbilityToThinkOrConcentrateORIndecisiveness = "Diminished ability to think or concentrate –OR- indecisiveness",
  DiminishedInterestInUsualActivities = "Diminished interest in usual activities",
  SignificantWeightLossorWeightGainORDecreaseIncreaseInAppetite = "Significant weight lossor weight gain OR decrease/increase in appetite",
  RecurrentThoughtsOfDeathRecurrentSuicideAttemptOrPlan = "Recurrent thoughts of death (not just fear of dying), recurrent SI w/o plan, suicide attempt or plan for committing suicide",
  FeelingsOfWorthlessnessOrExcessiveInappropriateGuilt = "Feelings of worthlessness or excessive/inappropriate guilt",
  None = "None",
}

export enum Education {
  highestGradeCompleted = "Highest Grade Completed",
  highSchoolgraduate = "High School graduate",
  ged = "GED",
  someCollege = "Some College",
  associateDegree = "Associate Degree",
  bachelordegree = "Bachelor’s degree",
  graduateSchool = "Graduate School",
  specialEdClasses = "Special Ed/Classes",
  technicalTradeSchool = "Technical Trade School",
}

export enum CurrentEmploymentStatus {
  unemployed = "Unemployed",
  notavailableforwork = "Not available for work",
  employedFullTime = "Employed Full Time",
  homemaker = "Homemaker",
  armedForcesNationalGuard = "Armed Forces/National Guard",
  employedPartTime = "Employed Part Time",
  retired = "Retired",
  student = "Student",
  disabilityIncome = "Disability Income",
}

export enum MaritalStatus {
  married = "Married",
  widowed = "Widowed",
  divorced = "Divorced",
  singleNeverMarried = "Single,Never Married",
  Separated = "Separated",
  domesticPartnership = "Domestic Partnership",
}

export enum LivingArrangement {
  privateResidence = "Private Residence",
  institution = "Institution",
  ownResidence = "Own Residence",
  other = "Other",
  roomHouseDorm = "Room,House,Dorm,etc.",
  adultCareHouse = "Adult Care Home",
  alternativeFamilyLiving = "Alternative Family Living",
  nursingHome = "Nursing Home",
  communityICFMR = "Community ICF-MR",
  correctionalFacility = "Correctional Facility",
  homelessOrAtRisk = "Homeless (or at risk)",
  residentialFacility = "Residential Facility",
}

export enum Race {
  blackAfricanAmarican = "Black/African American",
  alaskaNative = "Alaska Native",
  whiteCaucasian = "White/Caucasian",
  asian = "Asian",
  americanIndianNativeAmerican = "American Indian/Native American",
  pacificIslander = "Pacific Islander",
  multiratcial = "Multiracial",
  other = "Other",
}

export enum Ethnicity {
  notHispanicOrigin = "Not Hispanic Origin",
  hispanicCuban = "Hispanic,Cuban",
  hispanicMexicanAmarican = "Hispanic,Mexican American",
  hispanicPuertoRican = "Hispanic,Puerto Rican",
  hispanicOther = "Hispanic,Other",
}

export enum GenderIdentity {
  male = "male",
  female = "female",
  nonBinary = "nonBinary",
}

export enum GenderBirth {
  male = "male",
  female = "female",
  femaleAndPregnant = "pregnant",
}

export enum InformantPhone {
  sameAsAbove = "sameAsAbove",
  home = "home",
  work = "work",
  cell = "cell",
}

export enum PrimaryInformant {
  self = "self",
  other = "other",
}

export enum GuardianPhone {
  sameAsAbove = "sameAsAbove",
  home = "home",
  work = "work",
  cell = "cell",
}

export enum LegalGuarianship {
  self = "self",
  other = "other",
}

export enum ConsumerPhone {
  home = "home",
  work = "work",
  cellPhone = "cellPhone",
  other = "other",
}

export enum SexualOrientation {
  interestLgbtqService = "InterestLgbtqService",
  notInterestLgbtqService = "NotInterestLgbtqService",
}

export enum PhysicalStature {
  small = "Small",
  average = "Average",
  tall = "Tall",
}

export enum Weight {
  thin = "Thin",
  average = "Average",
  overweight = "Overweight",
  obese = "Obese",
}

export enum Grooming {
  wellGroomed = "Well Groomed",
  normal = "Normal",
  neglected = "Neglected",
  bizarre = "Bizarre",
}

export enum Clothing {
  neat = "Neat/Clean",
  dirty = "Dirty",
  disheveled = "Disheveled",
  inappropriate = "Inappropriate",
  seductive = "Seductive",
  bizarre = "Bizarre",
}

export enum Posture {
  normal = "Normal",
  tense = "Tense",
  stooped = "Stooped",
  rigid = "Rigid",
  slumped = "Slumped",
  bizarre = "Bizarre",
}

export enum Attitude {
  cooperative = "Cooperative",
  passive = "Passive",
  guarded = "Guarded",
  irritable = "Irritable",
  manipulative = "Manipulative",
  seductive = "Seductive",
  suspicious = "Suspicious",
  defensive = "Defensive",
  dramatic = "Dramatic",
  silly = "Silly",
  hostile = "Hostile",
  critical = "Critical",
  resistant = "Resistant",
  sarcastic = "Sarcastic",
  uninterested = "Uninterested",
  argumentative = "Argumentative",
}

export enum Motor {
  nonRemarkable = "Non-remarkable",
  tremor = "Tremor",
  slowed = "Slowed",
  tics = "Tics",
  restless = "Restless",
  agitated = "Agitated",
}

export enum Speech {
  normal = "Normal",
  rapid = "Rapid",
  slurred = "Slurred",
  loud = "Loud",
  paucity = "Paucity",
  pressured = "Pressured",
  mute = "Mute",
}

export enum Affect {
  appropriate = "Appropriate",
  inappropriate = "Inappropriate",
  flat = "Flat",
  restricted = "Restricted",
  blunted = "Blunted",
  labile = "Labile",
}

export enum Mood {
  euthymic = "Euthymic",
  confused = "Confused",
  pessimistic = "Pessimistic",
  depressed = "Depressed",
  anxious = "Anxious",
  euphoric = "Euphoric",
  apathetic = "Apathetic",
  angry = "Angry",
}

export enum ThoughtForm {
  goalDirected = "Goal directed",
  appropriate = "Appropriate",
  logical = "Logical",
  tangentialThinking = "Tangential thinking",
  circumstantial = "Circumstantial",
  looseAssociations = "Loose associations",
  confused = "Confused",
  incoherent = "Incoherent",
  perseverations = "Perseverations",
  flightofIdea = "Flight of idea",
  slownessofThought = "Slowness of thought association",
}

export enum ThoughtContent {
  appropriate = "Appropriate",
  paranoid = "Paranoid",
  suspicions = "Suspicions",
  persecutions = "Persecutions",
  paucity = "Paucity",
  delusions = "Delusions",
  bizarre = "Bizarre",
  hypochondriac = "Hypochondriac",
  ideasofReference = "Ideas of reference",
}

export enum Preoccupations {
  phobias = "Phobias",
  guilt = "Guilt",
  other = "Other",
  somatic = "Somatic",
  religion = "Religion",
  suicide = "Suicide",
  homicidal = "Homicidal",
}

export enum Hallucinations {
  auditory = "Auditory",
  other = "Other",
  visual = "Visual",
  sensory = "Sensory",
}

export enum Levelofintellectualfunctioning {
  belowAverage = "Below Average",
  average = "Average",
  aboveAverage = " Above Average",
}

export enum FundofKnowledge {
  belowAverage = "Below Average",
  average = "Average",
  aboveAverage = " Above Average",
}
export enum Judgment {
  belowAverage = "Below Average",
  average = "Average",
  aboveAverage = " Above Average",
}

export enum InsightIntoProblems {
  belowAverage = "Below Average",
  average = "Average",
  aboveAverage = " Above Average",
}
