export const environment = {
    api_url: "https://api.lavnihealth.com",
    app_url: "https://mylavni.com",
    pdf_service: "https://pdf-service-prod.lavni-dev.site",
    file_access_url: "/api/public/file",
    socket_uri: "https://api.lavnihealth.com",
    vimeo_access_token: "454e142f357c51cb27c528fd23197a31",
    vimeo_base_url: "https://api.vimeo.com",
    stripe_publishable_key: "pk_live_51LHfN6Iit1GJhbbN8Jg48z714WiHxX1FthKyushGOGAcfmDreEWpq5wTATACqc74sAlgMkKXTpLThnShMVKLrQCE00DdlmaRug",
    google_recaptcha_site_key: "6LcZN-UgAAAAAIDu2QPY1aSSRE6-HEmrRE9fQddZ",
    google_recaptcha_secret_key: "6LcZN-UgAAAAAKt8UKnsuTgOB2UNW5k3_alFF-XP",
    google_analytics_tracking_code: "UA-232342560-1",
    facebook_pixel_code: "642813639532772",
    NPI2: "1215673256",
    product1Pricing: 299,
    recorded_files_access_url: "/api/public/recorded_files",
    google_calendar_client_id: "777497092645-555kt06i5r6af4nmk26c8qfhaadi2skk.apps.googleusercontent.com",
    google_api_key: "AIzaSyBkgyY_BC62QXOyG5AobOA8SehYKq2uX-Q",
    google_calendar_scope: "https://www.googleapis.com/auth/calendar",
    google_calendar_discovery_docs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ],
    chat_encryption_key: "obvwoqcbv21801f19d0zibcoavwpnq",
    google_client_Id:"777497092645-555kt06i5r6af4nmk26c8qfhaadi2skk.apps.googleusercontent.com",
    facebook_app_id:"419468823373664",
    group_chat_encryption_key: "obvwoqcbv21801f19d0zibcoavwpnq",
    admin_client_chat_encryption_key: "obvwoqcbv21801f19d0zibcoavwpnq"
};
