import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import PublicAppointmentCalendar from 'src/common/main/public-appointment/PublicAppointmentCalendar.tsx';
import AppointmentForm from './AppointmentForm';
import { AppointmentService } from 'src/services/AppointmentService';
import { NotificationService } from 'src/services/NotificationService';
import { NotificationEvent, NotificationVarient } from 'src/models/Notification';
import { SocketContext } from 'src/context/ScoketContext';
import UserContext from 'src/context/UserContext';
import { toast } from 'react-toastify';
import { Util } from 'src/Util';
import { ClientService } from 'src/services/ClientService';
import LocalCallContext from 'src/context/LocalCallContext';
import { TherapistService } from 'src/services/TherapistService';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    outline: 'none',
    width: '90%',
    maxWidth: '1200px',
    height: '90vh',
    overflowY: 'auto',
    position: 'relative',
  }
}));

export default function CustomModal({ clientId, therapistId, open, handleClose, role }) {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [user] = useContext(UserContext);
  const [localUserOnCall] = useContext(LocalCallContext);
  const socket = useContext(SocketContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [therapistAvailableHours, setTherapistAvailableHours] = useState([]);
  const [blockDatesListForSelectedMonth, setBlockDatesListForSelectedMonth] = useState([]);
  const [appointmentListForSelectedMonth, setAppointmentListForSelectedMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().add(1, 'day').startOf('day').toDate());
  const [isLoadingNextMonth, setIsLoadingNextMonth] = useState(false);

  useEffect(() => {
    if (open && therapistId) {
      getTherapistData(therapistId);
      getClientDetails(clientId);
    }
  }, [open, therapistId]);

  const getClientDetails = async (clientId) => {
    try {
      // console.log('clientId:', clientId);
      // console.log('role:', role);
      const res = await ClientService.getClientByClientId(clientId);
      if (res && res.success && res.data && res.data._id && res.data.role === "CLIENT") {
        setClientDetails(res.data);
      } else {
        console.error('Invalid client response:', res);
        toast.error('Error getting client data');
      }
    } catch (error) {
      console.error('Error in getClientDetails:', error);
      toast.error('Failed to get client details');
    }
  };

  const dayOfWeekAsNumber = (day) => {
    const dayMap = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6
    };
    return dayMap[day];
  };

  const getTherapistData = async (id) => {
    if (!id) return;
    
    try {
      setIsLoadingNextMonth(true);
      const res = await TherapistService.getTherapistDetailsbyIdPublic(id);
      
      if (res?.success && res.data?.workingHours) {
        const availableDays = [];

        if (res.data.workingHours?.length) {
          res.data.workingHours.forEach((obj) => {
            const dayAsNumber = dayOfWeekAsNumber(obj.day);

            if (Util.convertUTCDateToLocalDate(obj.endTime) > Util.convertUTCDateToLocalDate(obj.startTime)) {
              availableDays.push({
                startTime: Util.convertUTCDateToLocalDate(obj.startTime),
                endTime: Util.convertUTCDateToLocalDate(obj.endTime),
                daysOfWeek: [dayAsNumber],
              });
            } else {
              availableDays.push({
                startTime: Util.convertUTCDateToLocalDate(obj.startTime),
                endTime: "24:00 AM",
                daysOfWeek: [dayAsNumber - 1],
              });

              availableDays.push({
                startTime: "00:00 AM",
                endTime: Util.convertUTCDateToLocalDate(obj.endTime),
                daysOfWeek: [dayAsNumber],
              });
            }
          });
        } else {
          availableDays.push({
            startTime: "00:00 AM",
            endTime: "00:00 PM",
            daysOfWeek: [10],
          });
        }

        setTherapistAvailableHours(availableDays);
        const blockedDates = res.data.blockedDates || [];
        setBlockDatesListForSelectedMonth(blockedDates);

        // Only fetch appointments if we have a valid selectedMonth
        if (selectedMonth) {
          const appointments = await AppointmentService.getAllAppointmentByTherapistIdPublicForMonth(id, selectedMonth);
          if (appointments?.success) {
            // Convert dates to Date objects
            const updatedAppointments = appointments.data.map(appointment => ({
              ...appointment,
              start: moment(appointment.start).toDate(),
              end: moment(appointment.end).toDate()
            }));

            // Filter appointments for selected month
            const monthForCheck = moment(selectedMonth).month();
            const yearForCheck = moment(selectedMonth).year();

            const filteredAppointments = updatedAppointments.filter(item => {
              const startMonth = moment(item.start).month();
              const startYear = moment(item.start).year();
              const endMonth = moment(item.end).month();
              const endYear = moment(item.end).year();

              return (startMonth === monthForCheck && startYear === yearForCheck) || 
                     (endMonth === monthForCheck && endYear === yearForCheck);
            });

            setAppointmentListForSelectedMonth(filteredAppointments);
          }
        }
      }
    } catch (error) {
      console.error('Error in getTherapistData:', error);
      toast.error('Error getting therapist schedule');
    } finally {
      setIsLoadingNextMonth(false);
    }
  };



  const getAvailableTimeBySelectedDay = (dayNumber, availableHours) => {
    const availableTimeSlots = [];

    availableHours
      .filter((obj) => obj.daysOfWeek[0] === dayNumber)
      .forEach((obj) => {
        const availableHourSingle = [];

        for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
          if (hour === parseInt(obj.startTime)) {
            const mST = moment(obj.startTime, "HH:mm").minute();
            if (mST === 0) {
              availableHourSingle.push(moment({ hour }).format("H:mm A"));
            }
          } else {
            availableHourSingle.push(moment({ hour }).format("H:mm A"));
          }

          if (hour !== parseInt(obj.endTime)) {
            availableHourSingle.push(
              moment({
                hour,
                minute: 30,
              }).format("H:mm A")
            );
          } else {
            const mET = moment(obj.endTime, "HH:mm").minute();
            if (mET === 30) {
              availableHourSingle.push(
                moment({
                  hour,
                  minute: 30,
                }).format("H:mm A")
              );
            }
          }
        }

        availableTimeSlots.push(availableHourSingle);
      });

    return availableTimeSlots;
  };

  const addEvent = (selectedDetails) => {
    try {
      const selectedDate = moment(selectedDetails.startStr).toISOString();

      const isInRange = allBlockDatesListInTherapist.some(range => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate));
      });

      if (isInRange) {
        return false;
      }

      if (selectedDetails) {
        if (therapistAvailableHours == null || !therapistAvailableHours || therapistAvailableHours.length === 0) {
          setEventOnHolidays(true);
          openCreateAppointmentPopup(selectedDetails);
        } else {
          setEventOnHolidays(false);
          const dayNumber = dayOfWeekAsNumber(moment(selectedDetails.start).format("dddd"));
          const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, therapistAvailableHours);

          let isAvailableTime = false;

          hoursSlots.forEach((slotArray) => {
            if(slotArray.includes(moment(selectedDetails.start).format("H:mm A")) && 
               slotArray.includes(moment(selectedDetails.end).format("H:mm A"))) {
              isAvailableTime = true;
            }
          });

          if (!isAvailableTime) {
            Swal.fire({
              icon: "error",
              title: "Therapist is not available during this time.",
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#f46a6a",
            });
          } else {
            openCreateAppointmentPopup(selectedDetails);
          }
        }
      }
    } catch (error) {
      console.error('Error in addEvent:', error);
      toast.error("Something went wrong!");
    }
  };

  const setArraysOnlyForSelectedMonth = (monthForCheckFrom, allBlockedSlotsFrom, allAppointmentsFrom) => {
    const monthForCheck = moment(monthForCheckFrom).month();
    const yearForCheck = moment(monthForCheckFrom).year();

    if (allBlockedSlotsFrom) {
      const filteredBlockedDatesForThisMonth = allBlockedSlotsFrom.filter(item => {
        const startMonth = moment(item.start).month();
        const startYear = moment(item.start).year();
        const endMonth = moment(item.end).month();
        const endYear = moment(item.end).year();

        return (startMonth === monthForCheck && startYear === yearForCheck) || 
               (endMonth === monthForCheck && endYear === yearForCheck);
      });

      setBlockDatesListForSelectedMonth(filteredBlockedDatesForThisMonth);
    }

    if (allAppointmentsFrom) {
      const filteredAppointmentsForThisMonth = allAppointmentsFrom.filter(item => {
        const startMonth = moment(item.start).month();
        const startYear = moment(item.start).year();
        const endMonth = moment(item.end).month();
        const endYear = moment(item.end).year();

        return (startMonth === monthForCheck && startYear === yearForCheck) || 
               (endMonth === monthForCheck && endYear === yearForCheck);
      });
      setAppointmentListForSelectedMonth(filteredAppointmentsForThisMonth);
    }
  };

  const selectAnotherMonthAndRefreshAppointmentList = (selectedMonthIn) => {
    try {
      if (storedTherapistData && storedTherapistData._id) {
        setIsLoadingNextMonth(true);
        setArraysOnlyForSelectedMonth(selectedMonthIn, allBlockDatesListInTherapist, allAppointmentListInTherapist);
        setSelectedMonth(selectedMonthIn);
        setIsLoadingNextMonth(false);
      }
    } catch (error) {
      setIsLoadingNextMonth(false);
      toast.error("Something went wrong");
    }
  };

  const handleSelectMonth = (date) => {
    setSelectedMonth(date);
    // Only fetch new data if we have a valid therapist ID
    if (therapistId) {
      getTherapistData(therapistId);
    }
  };

  const handleDatePick = (selectedDetails) => {
    const startTime = moment(selectedDetails.start);
    const formattedDetails = {
      ...selectedDetails,
      start: startTime.toDate(),
      end: moment(startTime).add(1, 'hour').toDate()
    };

    setSelectedDate(formattedDetails);
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSubmit = async (appointmentData) => {
    if (!clientDetails?._id) {
      toast.error('Client details not found');
      return;
    }

    try {
      setIsSubmitting(true);

      const startTime = moment(appointmentData.selectedDate);
      const endTime = moment(startTime).add(1, 'hour');

      const appointmentObj = {
        therapistId: therapistId,
        clientId: clientDetails._id,
        title: appointmentData.title,
        type: "Video - 1 Hour Session",
        start: startTime.toDate(),
        end: endTime.toDate(),
        reminders: [30],
        repeatInfo: {
          repeatType: appointmentData.repeat,
          interval: "",
          repeatDays: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
          },
          endingDate: Util.monthsNextFrom(startTime.toDate(), 1),
          endingAfter: 10,
          endingType: "",
        },
        color: appointmentData.color,
        groupId: therapistId + "_" + startTime.valueOf(),
        eventOnHolidays: false,
      };
      let res;
      if(role === "CLIENT") {
        res = await AppointmentService.createAppointmentByClient(appointmentObj);
      } else {
        res = await AppointmentService.createAppointmentByTherapist(appointmentObj);
      }
      

      if (res && res.success) {
        // Send notification
        const appointmentNotification = {
          senderId: therapistId,
          receiverId: clientDetails._id,
          event: NotificationEvent.APPOINMENT_CREATED,
          link: "/appointments",
          content: "Appointment is created by " + user.firstname + " " + user.lastname,
          variant: NotificationVarient.INFO,
        };

        const notificationRes = await NotificationService.sendNotification(appointmentNotification);
        
        const socketData = {
          socketId: notificationRes.data.socketId,
          notifyData: appointmentNotification,
          senderId: therapistId,
          receiverId: clientDetails._id,
        };

        socket.emit("send-notification", socketData);
        
        toast.success('Appointment created successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        handleClose();
      } else {
        toast.error(res?.error || 'Failed to create appointment', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      toast.error('Something went wrong!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <PublicAppointmentCalendar
            availableSlotsFromNew={therapistAvailableHours}
            prevAppointmentsNew={appointmentListForSelectedMonth}
            prevBlockedDatesListNew={blockDatesListForSelectedMonth}
            addEvent={handleDatePick}
            selectedMonthFrom={selectedMonth}
            selectMonth={handleSelectMonth}
            isLoadingNextMonth={isLoadingNextMonth}
          />
        );
      case 2:
        return (
          <div>
            <div className="mb-3">
              <button 
                className="btn btn-secondary"
                onClick={handleBack}
              >
                Back to Calendar
              </button>
            </div>
            <AppointmentForm
              selectedDate={selectedDate}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              defaultValues={{
                title: "Lavni Therapy session",
                type: "Video - 1 Hour Session",
                repeat: "Does not repeat",
                date: selectedDate ? moment(selectedDate.start).format('MM/DD/YYYY') : '',
                time: selectedDate ? moment(selectedDate.start).format('hh:mm A') : ''
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {renderStepContent()}
        </div>
      </Fade>
    </Modal>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
