import React, { useContext, useEffect, useState } from "react";
import "../../../assets/css/chat_screen.css";
import { Card, CardBody, CardTitle, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

import GroupChatContext from "src/context/GroupChatContext";

import images from "src/assets/images";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
import Spinner from "src/common/spinner/spinner";
import UserContext from "src/context/UserContext";
import { MemberInSelectedChatGroupModel } from "src/models/GroupChat/MemberInSelectedChatGroup";
import Swal from "sweetalert2";
import AddGroupChatMemberModal from "src/pages/Popup/GroupChatModals/AddGroupChatMemberModal";
import { Util } from "src/Util";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import { NotificationService } from "src/services/NotificationService";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import { SocketContext } from "src/context/ScoketContext";
import axios from "axios";

const GroupMembersView: React.FC = (props: any) => {
  const socket = useContext(SocketContext);
  const [user, setUser] = useContext(UserContext);
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);
  const [membersInSelectedChatGroup, setMembersInSelectedChatGroup] = useState([] as MemberInSelectedChatGroupModel[]);

  const [isGettingMembers, setIsGettingMembers] = useState(true);
  const [isGettingMembersError, setIsGettingMembersError] = useState(false);
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [removingMemberId, setRemovingMemberId] = useState("");
  const [showAddMemberPopup, setShowAddMemberPopup] = useState(false);
  const SCREEN_SIZE = 991;
  const SCREEN_SIZE_FOR_TOP_BAR = user?.role == "THERAPIST" ? 670 : 485;
  const SCREEN_SIZE_FOR_TOP_BAR_MINI = user?.role == "THERAPIST" ? 565 : 520;
  const SCREEN_SIZE_FOR_TOP_BAR_XS = 455;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [totalDataCountInDb, setTotalDataCountInDb] = useState(0);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [finalSearchableString, setFinalSearchableString] = useState("");
  const [isUpdatingMainStatus, setIsUpdatingMainStatus] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    if (
      selectedGroupData != null &&
      selectedGroupData._id != null &&
      selectedGroupData._id != "" &&
      selectedGroupIdForShowMembers != null &&
      selectedGroupIdForShowMembers != "" &&
      selectedGroupIdForShowMembers == selectedGroupData._id
    ) {
      setOffset(0);
      setTotalDataCountInDb(0);
      setIsSearchableString("");
      setFinalSearchableString("");
      setMembersInSelectedChatGroup([]);
      initialLoading(true, false, false, true, false);
    }
  }, []);

  const initialLoading = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    console.log("initialLoading chat members");

    setIsGettingMembers(true);
    await getAllMembers(next, isSearch, isRemove, isInitial, isAdd);
    setIsGettingMembers(false);
  };

  const getAllMembers = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    try {
      let previousCount = 0;
      let finalOffset = offset;
      if (isInitial || isSearch) {
        finalOffset = 0;
      } else if (isRemove) {
        previousCount = membersInSelectedChatGroup.length;
        if (isRemove && previousCount > 1) {
          if (finalOffset - 1 >= 0) {
            finalOffset = finalOffset - 1;
          }
        } else {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      } else if (isAdd) {
        if (finalOffset - 1 >= 0) {
          finalOffset = finalOffset - 1;
        }
      } else {
        if (!next) {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      }
      const dataObject = {
        groupId: selectedGroupData._id,
        searchableString: isSearch ? isSearchableString : finalSearchableString,
        limit: limit,
        offset: finalOffset * limit,
      };
      console.log(offset);
      const res = await GroupChatService.getAllChatGroupsMembers(dataObject);
      if (res.success && res.data) {
        if (res.data != null && res.data.members != null && res.data.count != null) {
          setTotalDataCountInDb(res.data.count);
          setMembersInSelectedChatGroup(res?.data.members);

          if (isSearch) {
            setFinalSearchableString(isSearchableString);
          }
          if (isSearch || isInitial) {
            setOffset(1);
          } else {
            if (isRemove) {
              if (isRemove && previousCount == 1) {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            } else if (isAdd) {
            } else {
              if (next) {
                setOffset(offset + 1);
              } else {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            }
          }
        }
      } else {
        setIsGettingMembersError(true);
        toast.error("Retrieving chat groups members failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsGettingMembersError(true);
      toast.error("Retrieving chat groups members failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const backToGroup = () => {
    setMembersInSelectedChatGroup([]);
    setSelectedGroupIdForShowMembers("");
    setSelectedGroupIdForShowRequests("");
  };

  const removeMember = async (memberId: string, groupId: string) => {
    try {
      if (
        !isRemovingMember &&
        user?.role == "THERAPIST" &&
        user._id == selectedGroupData.createdBy &&
        user?._id != memberId &&
        memberId != selectedGroupData.createdBy &&
        selectedGroupData._id == groupId
      ) {
        setRemovingMemberId(memberId);
        setIsRemovingMember(true);
        const userData = {
          groupId: groupId,
          memberId: memberId,
        };
        const res = await GroupChatService.removeGroupMember(userData);
        if (res && res.success) {
          try {
            const grpChatventNotification: NotificationModel = {
              senderId: user?._id,
              receiverId: memberId,
              event: NotificationEvent.GRP_CHAT_MEMBER_REMOVED,
              link: `/group-chat`,
              content: `You're Removed from ${selectedGroupData.title ?? ""} ${selectedGroupData.type == "PUBLIC" ? "peer support" : "private"} chat group.`,
              variant: NotificationVarient.INFO,
            };

            NotificationService.sendNotification(grpChatventNotification).then(res => {
              if (res && res.success && res.data && res.data.socketId) {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: grpChatventNotification,
                  receiverId: memberId,
                  senderId: user?._id,
                };

                socket.emit("send-notification", socketData);
              }
            });
          } catch (error) {}

          setIsRemovingMember(false);

          toast.success("Member removed successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          initialLoading(true, false, true, false, false);
        } else {
          setIsRemovingMember(false);
          toast.error("Failed to remove member!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsRemovingMember(false);
      } else {
        toast.error("Please wait, removing member in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsRemovingMember(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const closeModal = (value: boolean) => {
    try {
      setShowAddMemberPopup(false);
      if (membersInSelectedChatGroup != null && membersInSelectedChatGroup.length != null && value && offset > 0 && membersInSelectedChatGroup.length < limit) {
        initialLoading(true, false, false, false, true);
      }
    } catch (error) {}
  };

  const updateMainStatus = async (memberId: string) => {
    console.log('Starting updateMainStatus with memberId:', memberId);
    try {
      setIsUpdatingMainStatus(true);
      console.log('Setting isUpdatingMainStatus to true');

      // Optimistically update UI
      const updatedMembers = membersInSelectedChatGroup.map(m => ({
        ...m,
        is_main: m._id === memberId
      }));
      console.log('Updated members optimistically:', updatedMembers);
      setMembersInSelectedChatGroup(updatedMembers);

      // Make API call
      console.log('Making API call to updateMainMember');
      const response = await GroupChatService.updateMemberMainStatus({
        groupId: selectedGroupData._id,
        memberId: memberId,
        isMain: true
      });
      console.log('API response:', response);

      // Update UI with server response
      if (response.success) {
        console.log('API call successful');
        toast.success('Main client updated successfully!');
        // Keep the optimistically updated UI state
      } else {
        console.log('API call failed:', response.data);
        // Revert optimistic update
        setMembersInSelectedChatGroup(membersInSelectedChatGroup);
        toast.error('Failed to update main client');
      }
    } catch (error) {
      console.error('Error in updateMainStatus:', error);
      // Revert optimistic update
      setMembersInSelectedChatGroup(membersInSelectedChatGroup);
      toast.error('Failed to update main client');
    } finally {
      console.log('Setting isUpdatingMainStatus to false');
      setIsUpdatingMainStatus(false);
    }
  };

  return (
    <React.Fragment>
      {user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy && selectedGroupData._id == selectedGroupIdForShowMembers && showAddMemberPopup && (
        <AddGroupChatMemberModal setShowModal={closeModal} showModal={showAddMemberPopup} />
      )}
      <div className="page-content">
        <Container fluid>
          <div className="flex flex-wrap mb-4 mt-1 ">
            <div
              className="flex d-inline-block cursor-pointer align-items-center justify-content-center"
              onClick={() => {
                backToGroup();
              }}
            >
              <i className="fas fa-regular fa-arrow-left "></i>
              <CardTitle className="align-items-center justify-content-center m-0">&nbsp; Back to Group</CardTitle>
            </div>
          </div>

          <Row>
            <Col lg={12} className="">
              <Card style={{ minHeight: "67vh" }}>
                {screenSize > SCREEN_SIZE_FOR_TOP_BAR_MINI ? (
                  <div className="pt-2 pb-2  chat-header-bg" style={{ paddingLeft: "6vw", paddingRight: "6vw" }}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center" style={{ paddingRight: "0px", width: "100%" }}>
                          <h5 className="font-size-15 mb-0 text-truncate d-inline-block align-items-center justify-content-center fc-black me-4">
                            Group Members
                          </h5>
                          <div className="d-flex flex-column ms-4">
                            <div className="d-flex justify-content-center">
                              <div onClick={event => event.stopPropagation()}>
                                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString(e.target.value)} />
                              </div>
                              <span
                                className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  if (!isGettingMembers) {
                                    initialLoading(true, true, false, false, false);
                                  } else {
                                    toast.error("Please wait", {
                                      position: toast.POSITION.BOTTOM_RIGHT,
                                      className: "foo-bar",
                                    });
                                  }
                                }}
                              >
                                <i className="bx bx-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {user?.role == "THERAPIST" &&
                        user._id == selectedGroupData.createdBy &&
                        selectedGroupData._id == selectedGroupIdForShowMembers &&
                        (screenSize > SCREEN_SIZE_FOR_TOP_BAR ? (
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="btn align-items-center justify-content-center p-0 add-members-btn"
                              onClick={() => {
                                if (
                                  user?.role == "THERAPIST" &&
                                  user._id == selectedGroupData.createdBy &&
                                  selectedGroupData._id == selectedGroupIdForShowMembers
                                ) {
                                  setShowAddMemberPopup(true);
                                }
                              }}
                            >
                              <img className="ai-icon-size me-2" src={images.addSignIcon} />
                              <span className="font-size-14 fc-black">Add members</span>
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="btn align-items-center justify-content-center p-0 add-members-btn"
                              onClick={() => {
                                if (
                                  user?.role == "THERAPIST" &&
                                  user._id == selectedGroupData.createdBy &&
                                  selectedGroupData._id == selectedGroupIdForShowMembers
                                ) {
                                  setShowAddMemberPopup(true);
                                }
                              }}
                            >
                              <img className="" src={images.addSignIcon} />
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="pt-2 pb-2 ps-3 pe-3 chat-header-bg">
                    <div className="d-flex flex-column align-items-start justify-content-between">
                      <div className="d-flex align-items-center" style={{ paddingRight: "0px", width: "100%" }}>
                        <h5 className="font-size-15 mb-0 text-truncate d-inline-block align-items-center justify-content-center fc-black">Group Members</h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-start" style={{ paddingRight: "0px", width: "100%" }}>
                        <div className="d-flex flex-row align-items-center justify-content-between mt-2" style={{ paddingRight: "0px", width: "100%" }}>
                          <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center">
                              <div onClick={event => event.stopPropagation()}>
                                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString(e.target.value)} />
                              </div>
                              <span
                                className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  if (!isGettingMembers) {
                                    initialLoading(true, true, false, false, false);
                                  } else {
                                    toast.error("Please wait", {
                                      position: toast.POSITION.BOTTOM_RIGHT,
                                      className: "foo-bar",
                                    });
                                  }
                                }}
                              >
                                <i className="bx bx-search"></i>
                              </span>
                            </div>
                          </div>

                          {user?.role == "THERAPIST" &&
                            user._id == selectedGroupData.createdBy &&
                            selectedGroupData._id == selectedGroupIdForShowMembers &&
                            (screenSize > SCREEN_SIZE_FOR_TOP_BAR_XS ? (
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  type="button"
                                  className="btn align-items-center justify-content-center p-0 add-members-btn"
                                  onClick={() => {
                                    if (
                                      user?.role == "THERAPIST" &&
                                      user._id == selectedGroupData.createdBy &&
                                      selectedGroupData._id == selectedGroupIdForShowMembers
                                    ) {
                                      setShowAddMemberPopup(true);
                                    }
                                  }}
                                >
                                  <img className="ai-icon-size me-2" src={images.addSignIcon} />
                                  <span className="font-size-14 fc-black">Add members</span>
                                </button>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  type="button"
                                  className="btn align-items-center justify-content-center p-0 ms-2 add-members-btn"
                                  onClick={() => {
                                    if (
                                      user?.role == "THERAPIST" &&
                                      user._id == selectedGroupData.createdBy &&
                                      selectedGroupData._id == selectedGroupIdForShowMembers
                                    ) {
                                      setShowAddMemberPopup(true);
                                    }
                                  }}
                                >
                                  <img className="" src={images.addSignIcon} />
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <CardBody className="p-0 pt-3">
                  <div
                    className={screenSize > SCREEN_SIZE_FOR_TOP_BAR_MINI ? "container " : "container ps-3 pe-3"}
                    style={{ maxWidth: 1400, paddingLeft: "6vw", paddingRight: "6vw" }}
                  >
                    <div className="row ">
                      {!isGettingMembers &&
                        selectedGroupData != null &&
                        selectedGroupData._id != null &&
                        selectedGroupData._id != "" &&
                        selectedGroupIdForShowMembers != null &&
                        selectedGroupIdForShowMembers != "" &&
                        selectedGroupIdForShowMembers == selectedGroupData._id &&
                        membersInSelectedChatGroup != null &&
                        membersInSelectedChatGroup.length != null &&
                        membersInSelectedChatGroup.length > 0 && (
                          <div className="col-12 d-flex justify-content-start align-items-center mb-3">
                            <h5 className="mb-0 d-flex justify-content-start fs-14 align-items-start fc-black">{`Members (${totalDataCountInDb})`}</h5>
                          </div>
                        )}
                      <div
                        className="mb-4"
                        style={
                          !isGettingMembers &&
                          selectedGroupData != null &&
                          selectedGroupData._id != null &&
                          selectedGroupData._id != "" &&
                          selectedGroupIdForShowMembers != null &&
                          selectedGroupIdForShowMembers != "" &&
                          selectedGroupIdForShowMembers == selectedGroupData._id &&
                          membersInSelectedChatGroup != null &&
                          membersInSelectedChatGroup.length != null &&
                          membersInSelectedChatGroup.length > 0
                            ? { maxHeight: "50vh", overflowY: "auto" }
                            : {}
                        }
                      >
                        <div className="">
                          {isGettingMembers ? (
                            <div className="group-chat-empty-view" style={{ minHeight: "67vh" }}>
                              <Spinner />
                            </div>
                          ) : !isGettingMembers &&
                            isGettingMembersError &&
                            (membersInSelectedChatGroup == null || membersInSelectedChatGroup.length == null || membersInSelectedChatGroup.length <= 0) ? (
                            <div className="group-chat-empty-view" style={{ minHeight: "67vh" }}>
                              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                              <div className="mt-1">Error getting data</div>
                            </div>
                          ) : !isGettingMembers &&
                            selectedGroupData != null &&
                            selectedGroupData._id != null &&
                            selectedGroupData._id != "" &&
                            selectedGroupIdForShowMembers != null &&
                            selectedGroupIdForShowMembers != "" &&
                            selectedGroupIdForShowMembers == selectedGroupData._id &&
                            membersInSelectedChatGroup != null &&
                            membersInSelectedChatGroup.length != null &&
                            membersInSelectedChatGroup.length > 0 ? (
                            membersInSelectedChatGroup?.map((member, index: number) => (
                              <div key={index}>
                                <Col lg={8} md={10}>
                                  <li
                                    className={
                                      isRemovingMember && removingMemberId == member._id
                                        ? "d-flex d-inline-block mb-1 p-2 rounded bg-light-gray"
                                        : "d-flex d-inline-block mb-1 p-2 rounded"
                                    }
                                  >
                                    <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                                      <Col lg={9} md={8} sm={8} xs={7}>
                                        <div className="d-flex flex-row justify-content-start align-items-center me-1">
                                          {member != null && member.photoId != null && member.photoId._id != null ? (
                                            <div className="d-flex d-inline-block align-self-center me-3">
                                              <img src={Util.fileURL(member.photoId._id)} className="rounded-circle avatar-sm imageFit" />
                                            </div>
                                          ) : (
                                            <div className="d-flex d-inline-block align-self-center me-3">
                                              <img src={images.defaultGroupIcon} className="rounded-circle avatar-sm imageFit" />
                                            </div>
                                          )}
                                          <h5 className="text-truncate mb-0 ">
                                            {user?._id == member._id ? "You" : `${member.firstname ?? ""} ${member.lastname ?? ""}`}
                                          </h5>
                                        </div>
                                      </Col>
                                      <Col lg={3} md={4} sm={4} xs={5}>
                                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center flex-nowrap">
                                              <span className="badge bg-light text-dark me-2">
                                                {member.role != null ? (member.role == "SUPER_ADMIN" || member.role == "SUB_ADMIN" || member.role == "ADMIN" ? "Admin" : "Member") : "Member"}
                                              </span>

                                              {member.role === "MEMBER" && (
                                                <div 
                                                  className="form-check form-check-inline mb-0"
                                                  onClick={(e) => {
                                                    console.log("Div clicked");
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="mainClient"
                                                    className="form-check-input"
                                                    checked={member.is_main === true || member.is_main === "true"}
                                                    readOnly
                                                    style={{
                                                      cursor: user?.role === "THERAPIST" && 
                                                             user._id === selectedGroupData.createdBy && 
                                                             !isUpdatingMainStatus &&
                                                             !(member.is_main === true || member.is_main === "true")
                                                        ? 'pointer' 
                                                        : 'not-allowed',
                                                      opacity: isUpdatingMainStatus ? 0.6 : 1
                                                    }}
                                                    onClick={(e) => {
                                                      console.log("Radio clicked:", {
                                                        memberId: member._id,
                                                        currentStatus: member.is_main,
                                                        isTherapist: user?.role === "THERAPIST",
                                                        isCreator: user?._id === selectedGroupData.createdBy,
                                                        isUpdating: isUpdatingMainStatus
                                                      });
                                                      
                                                      if (user?.role === "THERAPIST" && 
                                                          user._id === selectedGroupData.createdBy && 
                                                          !isUpdatingMainStatus &&
                                                          !(member.is_main === true || member.is_main === "true")) {
                                                        console.log("Calling updateMainStatus");
                                                        e.stopPropagation();
                                                        updateMainStatus(member._id);
                                                      }
                                                    }}
                                                  />
                                                  <label 
                                                    className={`form-check-label small ms-1 ${isUpdatingMainStatus ? 'text-muted' : ''}`}
                                                    style={{cursor: 'inherit'}}
                                                    onClick={(e) => {
                                                      console.log("Label clicked");
                                                      e.stopPropagation();
                                                    }}
                                                  >
                                                    Main Client
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                          </div>

                                          {!(isRemovingMember && removingMemberId == member._id) &&
                                            user?.role == "THERAPIST" &&
                                            user?._id == selectedGroupData.createdBy &&
                                            user?._id != member._id &&
                                            member._id != selectedGroupData.createdBy &&
                                            !(member.is_main === true || member.is_main === "true") && (
                                              <div
                                                className="cursor-pointer ms-2"
                                                onClick={() => {
                                                  Swal.fire({
                                                    icon: "warning",
                                                    title: "Are you sure you want to remove this member from group",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Remove",
                                                    confirmButtonColor: "#FD7F00",
                                                    cancelButtonColor: "#FD7F00",
                                                  }).then(result => {
                                                    if (result.isConfirmed) {
                                                      removeMember(member._id, selectedGroupData._id);
                                                    }
                                                  });
                                                }}
                                              >
                                                <img src={images.close} alt="Close" className="ai-icon-size" />
                                              </div>
                                            )}
                                        </div>
                                      </Col>
                                    </div>
                                  </li>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div className="group-chat-empty-view" style={{ minHeight: "67vh" }}>
                              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                              <div className="mt-1">No available members</div>
                            </div>
                          )}
                        </div>
                      </div>
                      {!isGettingMembers &&
                        selectedGroupData != null &&
                        selectedGroupData._id != null &&
                        selectedGroupData._id != "" &&
                        selectedGroupIdForShowMembers != null &&
                        selectedGroupIdForShowMembers != "" &&
                        selectedGroupIdForShowMembers == selectedGroupData._id &&
                        membersInSelectedChatGroup != null &&
                        membersInSelectedChatGroup.length != null &&
                        membersInSelectedChatGroup.length > 0 && (
                          <Col lg={8} md={10}>
                            <Row className=" mt-2 mb-4">
                              <div className={offset > 1 ? "d-flex justify-content-between " : "d-flex justify-content-end "}>
                                <div>
                                  {offset > 1 && (
                                    <button
                                      className="btn btn-secondary btn-sm"
                                      onClick={() => {
                                        if (!isGettingMembers) {
                                          initialLoading(false, false, false, false, false);
                                        } else {
                                          toast.error("Please wait", {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                            className: "foo-bar",
                                          });
                                        }
                                      }}
                                    >
                                      <i className="bx bx-left-arrow-alt me-2"></i> Back
                                    </button>
                                  )}
                                </div>
                                <div>
                                  {offset > 0 && totalDataCountInDb > limit * offset && (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        if (!isGettingMembers) {
                                          initialLoading(true, false, false, false, false);
                                        } else {
                                          toast.error("Please wait", {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                            className: "foo-bar",
                                          });
                                        }
                                      }}
                                    >
                                      Next <i className="bx bx-right-arrow-alt ms-2"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Row>
                          </Col>
                        )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default GroupMembersView;
