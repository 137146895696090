import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";

const BadNetwork: React.FC<{
  showModal: boolean;
  setShowModal: () => void;
}> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal()} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-15">
              Your internet speed is slow. It is recommended to connect with audio only during Your meeting in order for a smooth calling experience.
            </CardTitle>
            <div className="d-flex justify-content-center flexColumn">
              <button type="button" onClick={() => props.setShowModal()} className="btn btn-primary btnMargin">
                Close
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default BadNetwork;
