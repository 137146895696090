import React, { useState, useContext, useEffect, useRef } from "react";
import { Util } from "../../../Util";
import images from "../../../assets/images";
import UserContext from "../../../context/UserContext";
import "../../../assets/css/chat_screen.css";
import { Card, CardTitle, Col, Form, Input, Label, Row } from "reactstrap";
import { Role } from "../../../models/Role";
import { toast } from "react-toastify";
import LocalCallContext from "../../../context/LocalCallContext";
import CallInitializationContext from "../../../context/CallInitializationContext";
import Select from "react-select";
import { CPTCode, DiagnosisNoteForUpdate, DiagnosisNoteFromResponse } from "../../../models/DiagnosisNote";
import { VideoChatService } from "../../../services/VideoChatService";
import moment from "moment";
import NoteHeader from "./NoteHeader";
import SubjectiveTab from "../../Notes/Tabs/SubjectiveTab";
import { RefNames, Stages } from "../../../models/Notes";
import ObjectiveTab from "../../Notes/Tabs/ObjectiveTab";
import AssessmentTab from "../../Notes/Tabs/AssessmentTab";
import PlanTab from "../../Notes/Tabs/PlanTab";
import options from "./Dataset/options";
import SignaturePad from "react-signature-canvas";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor"
import DOMPurify from "dompurify";
import { TherapistService } from "src/services/TherapistService";
import { UserRole } from "src/models/User";
import PIENoteHeader from './PIENoteHeader';
import {NoteType} from '../../../models/DiagnosisNote'
import { FormVersionService } from "src/services/FormVersionService";
import Swal from "sweetalert2";


interface diagnosisNoteScreenProps {
  diagnosisId: any;
  totalSpentDuration: number;
  updateDiagnosisData: (isUpdate: boolean) => void;
  isVersion: boolean;
}
const PIEDiagnosisNoteViewNew: React.FC<diagnosisNoteScreenProps> = ({ diagnosisId, totalSpentDuration, updateDiagnosisData, isVersion }) => {
  const [user, setUser] = useContext(UserContext);
  const [historyOfPresenseIllnessFileArray, setHistoryOfPresenseIllnessFileArray] = useState<any[]>([]);
  const [assessmentFileArray, setAssessmentFileArray] = useState<any[]>([]);
  const [mentalBehaviouralStatusFileArray, setMentalBehaviouralStatusFileArray] = useState<any[]>([]);
  const [carePlanFileArray, setCarePlanFileArray] = useState<any[]>([]);
  const [historyOfPresentIllnessAttachmentsIdsForDelete, setHistoryOfPresentIllnessAttachmentsIdsForDelete] = useState<any[]>([]);
  const [mentalBehavioralStatusAttachmentsIdsForDelete, setMentalBehavioralStatusAttachmentsIdsForDelete] = useState<any[]>([]);
  const [assessmentAttachmentsIdsForDelete, setAssessmentAttachmentsIdsForDelete] = useState<any[]>([]);
  const [carePlanAttachmentsIdsForDelete, setCarePlanAttachmentsIdsForDelete] = useState<any[]>([]);
  const [historyOfPresentIllnessAttachmentsFromResponse, setHistoryOfPresentIllnessAttachmentsFromResponse] = useState<any[]>([]);
  const [mentalBehavioralStatusAttachmentsFromResponse, setMentalBehavioralStatusAttachmentsFromResponse] = useState<any[]>([]);
  const [assessmentAttachmentsFromResponse, setAssessmentAttachmentsFromResponse] = useState<any[]>([]);
  const [carePlanAttachmentsFromResponse, setCarePlanAttachmentsFromResponse] = useState<any[]>([]);
  const [previousNote, setPreviousNote] = useState<DiagnosisNoteFromResponse>({} as DiagnosisNoteFromResponse);
  const [step, setStep] = useState<number>(1);
  const [editNoteClicked, setEditNoteClicked] = useState<boolean>(false);
  const [chiefComplaint, setChiefComplaint] = useState<string>("");
  const [chiefComplaintError, setChiefComplaintError] = useState<boolean>(false);
  const [historyOfPresenseIllness, setHistoryOfPresenseIllness] = useState<string>("");
  const [historyOfPresenseIllnessError, setHistoryOfPresenseIllnessError] = useState<boolean>(false);
  const [mentalBehaviouralStatus, setMentalBehaviouralStatus] = useState<string>("");
  const [mentalBehaviouralStatusError, setMentalBehaviouralStatusError] = useState<boolean>(false);
  const [intervention, setIntervention] = useState<string>("");
  const [primaryDiagnosisICD10Codes, setPrimaryDiagnosisICD10Codes] = useState<any[]>([]);
  const [primaryDiagnosisICD10CodesError, setprimaryDiagnosisICD10CodesError] = useState<boolean>(false);
  const [secondaryDiagnosisICD10Codes, setSecondaryDiagnosisICD10Codes] = useState<any[]>([]);
  const [assessments, setAssessments] = useState<string>("");
  const [assessmentsError, setAssessmentsError] = useState<boolean>(false);
  const [procedureNotes, setProcedureNotes] = useState<string>("");
  const [carePlan, setCarePlan] = useState<string>("");
  const [carePlanError, setCarePlanError] = useState<boolean>(false);
  const [procedureNoteError, setProcedureNoteError] = useState<boolean>(false);
  const [goals, setGoals] = useState<string[]>([]);
  const [goalsText, setGoalsText] = useState<string>("");
  const [goalsError, setGoalsError] = useState<boolean>(false);
  const [signature, setSignature] = useState<any>();
  const [editsignature, seteditSignature] = useState<boolean>(false);
  const [activeVerticalTab, setactiveVerticalTab] = useState("1");
  const [currentStage, setCurrentStage] = useState<string>("subjective");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isDataLoadingError, setIsDataLoadingError] = useState<boolean>(false);
  const [firstTimeUpdatedByTherapist, setFirstTimeUpdatedByTherapist] = useState<boolean>(false);
  const SUPPORTED_IMAGE_FORMATS_FOR_HOPI = ["pdf"];
  const SUPPORTED_VIDEO_FORMATS_FOR_ASSIGNMENT = ["pdf", "txt", "docx", "doc"];
  const SUPPORTED_VIDEO_FORMATS_FOR_ANY = ["pdf", "txt", "docx", "doc", "jpg", "png", "jpeg"];
  const [cursorPosition, setCursorPosition] = useState("");
  const [element, setElement] = useState<string>("");
  const [cptCode, setCPTCode] = useState<string>("");
  const [cptCodeError, setCPTCodeError] = useState<boolean>(false);
  const [cptCodeList] = useState([
    { cptCode: "-1", cptCodeDescription: "Select" },
    { cptCode: "90791", cptCodeDescription: "Psychiatric diagnostic evaluation" },
    { cptCode: "90792", cptCodeDescription: "Psychiatric diagnostic evaluation with medical services" },
    { cptCode: "90832", cptCodeDescription: "Psychotherapy, 16-37 minutes" },
    { cptCode: "90833", cptCodeDescription: "Psychotherapy, 16-37 minutes with E/M service, listed seperately" },
    { cptCode: "90834", cptCodeDescription: "Psychotherapy, 38-52 minutes" },
    { cptCode: "90836", cptCodeDescription: "Psychotherapy, 38-52 minutes with E/M service, listed seperately" },
    { cptCode: "90837", cptCodeDescription: "Psychotherapy, 53+ minutes" },
    { cptCode: "90838", cptCodeDescription: "Psychotherapy, 53+ minutes with E/M service, listed seperately" },
    { cptCode: "90839", cptCodeDescription: "Psychotherapy for crisis, 30-74 minutes" },
    { cptCode: "90840", cptCodeDescription: "Psychotherapy for crisis, each additional 30 minutes beyond initial 74min, upto two add-ons per 90839" },
    { cptCode: "90846", cptCodeDescription: "Family psytx w/o patient 50 minutes" },
    { cptCode: "90847", cptCodeDescription: "Family psytx w/patient 50 minutes" },
    { cptCode: "90853", cptCodeDescription: "Group psychotherapy" },
    { cptCode: "00001", cptCodeDescription: "Non Billable" },
  ]);

  const historyOfPresenceRef = useRef<HTMLTextAreaElement>(null);
  const chiefComplaintRef = useRef<HTMLTextAreaElement>(null);
  const procedureNotesRef = useRef<HTMLTextAreaElement>(null);
  const carePlanRef = useRef<HTMLTextAreaElement>(null);
  const [testData, setTestData] = useState("");

  useEffect(() => {
    switch (cursorPosition) {
      case "1":
        // if (chiefComplaintRef && chiefComplaintRef.current) {
        //   setElement(chiefComplaintRef.current?.name);
        // }
        setElement('chief_complaint')
        break;
      case "2":
        // if (historyOfPresenceRef && historyOfPresenceRef.current) {
        //   setElement(historyOfPresenceRef.current?.name);
        // }
        setElement('history_of_present_illness')
        break;
      case "3":
        // if (procedureNotesRef && procedureNotesRef.current) {
        //   setElement(procedureNotesRef.current.name);
        // }
        setElement('procedure_notes')
        break;
      case "4":
        // if (carePlanRef && carePlanRef.current) {
        //   setElement(carePlanRef.current?.name);
        // }
        setElement('care_plan')
        break;
      default:
        break;
    }
  }, [cursorPosition]);

  useEffect(() => {
    if (chiefComplaint !== "" || chiefComplaint == undefined || chiefComplaint == null) {
      setChiefComplaintError(false);
    }
    if (historyOfPresenseIllness !== "" || historyOfPresenseIllness == undefined || historyOfPresenseIllness == null) {
      setHistoryOfPresenseIllnessError(false);
    }
    if (mentalBehaviouralStatus !== "" || mentalBehaviouralStatus == undefined || mentalBehaviouralStatus == null) {
      setMentalBehaviouralStatusError(false);
    }
    if (assessments !== "" || assessments == undefined || assessments == null) {
      setAssessmentsError(false);
    }
    if (procedureNotes !== "" || procedureNotes == undefined || procedureNotes == null) {
      setProcedureNoteError(false);
    }
    if (carePlan !== "" || carePlan == undefined || carePlan == null) {
      setCarePlanError(false);
    }
  }, [chiefComplaint, historyOfPresenseIllness, mentalBehaviouralStatus, assessments, procedureNotes, carePlan]);

  useEffect(() => {
    setIsDataLoading(true);
    setIsDataLoadingError(false);
    if (!isVersion) {
      VideoChatService.getDiagnosisNoteDetails(diagnosisId).then(res => {
        if (res.success) {
          if (res.data.updatedByTherapist && res.data.updatedByTherapist == true) {
            setFirstTimeUpdatedByTherapist(true);
          } else {
            setEditNoteClicked(true);
            setFirstTimeUpdatedByTherapist(false);
            if (user?.role == Role.THERAPIST) {
              toast.success("Previous Note Data Loaded", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }

          setPreviousNote(res.data);

          setChiefComplaint(res.data.chiefComplaint);

          setHistoryOfPresenseIllness(res.data.historyOfPresentIllness);

          setMentalBehaviouralStatus(res.data.mentalBehavioralStatus);

          setAssessments(res.data.asssessments);

          setCarePlan(res.data.carePlan);

          setPrimaryDiagnosisICD10Codes(res.data.diagnosisICDcodes);
          setPrimaryDiagnosisICD10Codes
          setSecondaryDiagnosisICD10Codes(res.data.secondaryDiagnosisICDcodes);

          setIntervention(res.data.intervention);

          setCPTCode(res.data.cptCode);

          setProcedureNotes(res.data.procedureNotes);

          if (user?.signature && user?.role == "THERAPIST" && res?.data?.therapistId?._id && user?._id && res?.data?.therapistId?._id == user?._id) {
            setSignature(user?.signature);
          } else if (res?.data?.signature) {
            setSignature(res?.data?.signature);
          }
          
          // if (user?.role == "THERAPIST" && user?.signature) {
          //   setSignature(user?.signature)
          // } else {
          //   setSignature(res.data.signature)
          // }

          setGoals(res.data.selectedGoals);

          setHistoryOfPresentIllnessAttachmentsFromResponse(res.data.historyOfPresentIllnessAttachments);

          setMentalBehavioralStatusAttachmentsFromResponse(res.data.mentalBehavioralStatusAttachments);

          setAssessmentAttachmentsFromResponse(res.data.assessmentAttachments);

          setCarePlanAttachmentsFromResponse(res.data.carePlanAttachments);

          setIsDataLoadingError(false);
          seteditSignature(false);
          setIsDataLoading(false);
        } else {
          setIsDataLoadingError(true);
          setIsDataLoading(false);
        }
      });
    } else {
      FormVersionService.getDiagnosisNoteVersion(diagnosisId).then(res => {
        if (res.success) {
          setFirstTimeUpdatedByTherapist(true);
          setPreviousNote(res.data);
          setChiefComplaint(res.data.chiefComplaint);
          setCarePlan(res.data.carePlan);
          setCPTCode(res.data.cptCode);

          if (user?.signature && user?.role == "THERAPIST" && res?.data?.therapistId?._id && user?._id && res?.data?.therapistId?._id == user?._id) {
            setSignature(user?.signature);
          } else if (res?.data?.signature) {
            setSignature(res?.data?.signature);
          }

          setIsDataLoadingError(false);
          seteditSignature(false);
          setIsDataLoading(false);
        } else {
          setIsDataLoadingError(true);
          setIsDataLoading(false);
        }
      });
    }
  }, []);

  const customStylesForICD10Codes = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any) => ({
      ...styles,
      borderColor: primaryDiagnosisICD10CodesError ? "red" : "#ced4da",
    }),
  };

  const handlePrimaryDiagnosisChange = (newValue: any) => {
    if (newValue.length == 0 && cptCode !== "00001") {
      setprimaryDiagnosisICD10CodesError(true);
    } else {
      setprimaryDiagnosisICD10CodesError(false);
    }
    // const finalArray = [...newValue];
    // setPrimaryDiagnosisICD10Codes(finalArray);
    if (newValue.length > 0) {
      const finalArray = [...newValue];
      setPrimaryDiagnosisICD10Codes(finalArray);
    } else {
      setPrimaryDiagnosisICD10Codes([]);
    }
  };

  const handleSecondaryDiagnosisChange = (newValue: any) => {
    if (newValue.length > 0) {
      const finalArray = [...newValue];
      setSecondaryDiagnosisICD10Codes(finalArray);
    } else {
      setSecondaryDiagnosisICD10Codes([]);
    }
  };

  const handleCPTCodeChange = (event: any) => {
    if (event.target.value == "-1" || event.target.value == undefined || event.target.value == null) {
      setCPTCodeError(true);
    } else {
      setCPTCodeError(false);
    }

    if (event.target.value !== "00001" && primaryDiagnosisICD10Codes.length <= 0) {
      setprimaryDiagnosisICD10CodesError(true);
    }
    else {
      setprimaryDiagnosisICD10CodesError(false);
    }

    if (event.target.value !== "00001" && goals.length <= 0) {
      setGoalsError(true);
    }
    else {
      setGoalsError(false);
    }

    setCPTCode(event.target.value);
  };

  const deleteGoal = (index: number) => {
    goals.splice(index, 1);

    const finalArray = [...goals];
    if (finalArray.length > 0) {
      setGoalsError(false);
    } else {
      setGoalsError(true);
    }
    setGoals(finalArray);
  };

  // const handleGoalsChange = (newValue: any) => {
  //   if (newValue.trim() != "") {
  //     const finalArray = [...goals];
  //     finalArray.push(newValue);
  //     if (finalArray.length > 0) {
  //       setGoalsError(false);
  //       setGoals([...finalArray]);
  //     } else {
  //       setGoalsError(true);
  //     }
  //   } else {
  //     if (goals.length <= 0) {
  //       setGoalsError(true);
  //     }
  //   }
  // };

  const handleGoalsChange = (newValue: any) => {
    if (newValue.trim() != "") {
      const finalArray = [...goals];
      finalArray.push(newValue);
      if (finalArray.length <= 0 && cptCode !== "00001") {
        setGoalsError(true);
      } else {
        setGoalsError(false);
        setGoals([...finalArray]);
      }
    } else {
      if (goals.length <= 0 && cptCode !== "00001") {
        setGoalsError(true);
      } else {
        setGoalsError(false);
      }
    }
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const handlePresenseIllnessFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_IMAGE_FORMATS_FOR_HOPI, extension);
        if (supported) {
          const exists = historyOfPresenseIllnessFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...historyOfPresenseIllnessFileArray, event.target.files[0]];
            setHistoryOfPresenseIllnessFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };

  const deletePresenseIllnessFile = (index: number) => {
    historyOfPresenseIllnessFileArray.splice(index, 1);

    const finalArray = [...historyOfPresenseIllnessFileArray];

    setHistoryOfPresenseIllnessFileArray(finalArray);
  };

  const handleMentalBehaviourFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_VIDEO_FORMATS_FOR_ANY, extension);
        if (supported) {
          const exists = mentalBehaviouralStatusFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...mentalBehaviouralStatusFileArray, event.target.files[0]];
            setMentalBehaviouralStatusFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };

  const deleteMentalBehaviourFile = (index: number) => {
    mentalBehaviouralStatusFileArray.splice(index, 1);

    const finalArray = [...mentalBehaviouralStatusFileArray];

    setMentalBehaviouralStatusFileArray(finalArray);
  };

  const handleAssessmentFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_VIDEO_FORMATS_FOR_ASSIGNMENT, extension);
        if (supported) {
          const exists = assessmentFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...assessmentFileArray, event.target.files[0]];
            setAssessmentFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };

  const deleteAssessmentFile = (index: number) => {
    assessmentFileArray.splice(index, 1);

    const finalArray = [...assessmentFileArray];

    setAssessmentFileArray(finalArray);
  };

  const handleCarePlanFileChange = (event: any) => {
    try {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split(".").pop().toLowerCase();
        const supported = isSupported(SUPPORTED_VIDEO_FORMATS_FOR_ANY, extension);
        if (supported) {
          const exists = carePlanFileArray.find(file => file.name === event.target.files[0].name);

          if (exists) {
            toast.error("File Already Selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return false;
          } else {
            const finalArray = [...carePlanFileArray, event.target.files[0]];
            setCarePlanFileArray(finalArray);
          }
        } else {
          toast.error("File type is not supported!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      } else {
        toast.error("No file is selected!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    } catch (error) {
      toast.error("No file is selected!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }
  };

  const deleteCarePlanFile = (index: number) => {
    carePlanFileArray.splice(index, 1);

    const finalArray = [...carePlanFileArray];

    setCarePlanFileArray(finalArray);
  };

  const validateStepOne = () => {
    if (chiefComplaint.trim() == "") {
      setChiefComplaintError(true);
      toast.error("Please add chief complaint!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setChiefComplaintError(false);
    }

    // if (historyOfPresenseIllness.trim() == "") {
    //   setHistoryOfPresenseIllnessError(true);
    //   toast.error("Please add history of present illness!", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: "foo-bar",
    //   });
    // } else {
    //   setHistoryOfPresenseIllnessError(false);
    // }

    if (chiefComplaint.trim() != "" ) {
      setChiefComplaintError(false);
      // setHistoryOfPresenseIllnessError(false);
      setStep(step + 1);
      setCurrentStage("objective");
    }
  };

  const validateStepTwo = () => {
    if (mentalBehaviouralStatus.trim() == "") {
      setMentalBehaviouralStatusError(true);
      toast.error("Please add mental/behavioral status!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setMentalBehaviouralStatusError(false);
      setStep(step + 1);
      setCurrentStage("assessment");
    }
  };

  const validateStepThree = async () => {
    if (carePlan.trim() == "") {
      setCarePlanError(true);
      toast.error("Please add diagnosis notes!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setCarePlanError(false);
    }

    if (cptCode !== "00001" && primaryDiagnosisICD10Codes.length <= 0) {
      setprimaryDiagnosisICD10CodesError(true);
    } else {
      setprimaryDiagnosisICD10CodesError(false);
    }

    if (cptCode === "-1" || cptCode == undefined || cptCode == null || !cptCode) {
      setCPTCodeError(true);
      toast.error("Please add CPT code!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setCPTCodeError(false);
    }

    if (goals.length <= 0 && cptCode !== "00001") {
      setGoalsError(true);
    } else {
      setGoalsError(false);
    }

    if (carePlan.trim() != "" && cptCode.trim() != "" && cptCode !== "-1" && cptCode.length > 0 && cptCode && cptCode !== undefined && cptCode !== null) {
      
      if ((cptCode !== "00001" && primaryDiagnosisICD10Codes.length > 0 && goals.length > 0) || cptCode === "00001") {
        if (firstTimeUpdatedByTherapist && editNoteClicked) {
          const reasonForEditing = await getReasonForEditing();
          if (reasonForEditing && reasonForEditing.trim() != "") {
            setCarePlanError(false);
            setprimaryDiagnosisICD10CodesError(false);
            setCPTCodeError(false);
            setGoalsError(false);
            setStep(step + 1);
            saveNewNote(reasonForEditing);
            setCurrentStage("plan");
          } else {
            toast.error("We are unable to update the diagnosis note without a valid reason. Please provide the reason for amendment.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            });
          }
        } else {
          setCarePlanError(false);
          setprimaryDiagnosisICD10CodesError(false);
          setCPTCodeError(false);
          setGoalsError(false);
          setStep(step + 1);
          saveNewNote(null);
          setCurrentStage("plan");
        }
      } else {
        setGoalsError(true);
      }
    }
  };

  // const validateStepFourAndSave = () => {
  //   let isError = false;
  //   if (carePlan.trim() == "") {
  //     isError = true;
  //     setCarePlanError(true);
  //     toast.error("Please add Care Plan!", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });
  //   } else {
  //     setCarePlanError(false);
  //   }

  //   if (procedureNotes.trim() == "") {
  //     isError = true;
  //     setProcedureNoteError(true);
  //     toast.error("Please add procedure/intervention notes!", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });
  //   } else {
  //     setProcedureNoteError(false);
  //   }

  //   if (goals.length <= 0) {
  //     isError = true;
  //     setGoalsError(true);
  //   } else {
  //     setGoalsError(false);
  //   }

  //   if (carePlan.trim() != "" && goals.length > 0 && procedureNotes.trim() != "" && !isError) {
  //     setCarePlanError(false);
  //     setGoalsError(false);
  //     saveNewNote(null);
  //   } else {
  //     isError = true;
  //   }
  // };

  const getReasonForEditing = async () => {
    const result = await Swal.fire({
      title: 'Reason for Amendment',
      input: 'text',
      inputLabel: 'Please provide a reason for amending this diagnosis note.',
      inputPlaceholder: 'Enter reason for amending...',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to provide a reason!';
        }
        return null;
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
    });

    return result?.isConfirmed ? result?.value : null;
  }

  const [isSavingData, setIsSavingData] = useState<boolean>(false);
  const saveNewNote = async (reasonForEditing: any) => {

    if (!signature || signature?.length == undefined || signature?.length == 0) {
      toast.error("Signature is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else {
      setIsSavingData(true);

      const noteData: DiagnosisNoteForUpdate = {
        _id: diagnosisId,
        chiefComplaint: chiefComplaint,
        historyOfPresentIllness: historyOfPresenseIllness,
        historyOfPresentIllnessAttachments: historyOfPresenseIllnessFileArray,
        diagnosisICDcodes: primaryDiagnosisICD10Codes,
        secondaryDiagnosisICDcodes: secondaryDiagnosisICD10Codes,
        cptCode: cptCode,
        mentalBehavioralStatus: mentalBehaviouralStatus,
        mentalBehavioralStatusAttachments: mentalBehaviouralStatusFileArray,
        asssessments: assessments,
        assessmentAttachments: assessmentFileArray,
        procedureNotes: procedureNotes,
        carePlan: carePlan,
        signature: signature,
        carePlanAttachments: carePlanFileArray,
        selectedGoals: goals,
        historyOfPresentIllnessAttachmentsIdsForDelete: historyOfPresentIllnessAttachmentsIdsForDelete,
        mentalBehavioralStatusAttachmentsIdsForDelete: mentalBehavioralStatusAttachmentsIdsForDelete,
        assessmentAttachmentsIdsForDelete: assessmentAttachmentsIdsForDelete,
        carePlanAttachmentsIdsForDelete: carePlanAttachmentsIdsForDelete,
        intervention: intervention,
        noteType: NoteType.PIE,
        reasonForEditing: reasonForEditing
      };

      const res = await VideoChatService.updateDiagnosisNote(noteData);

      saveSignTherapist(noteData.signature);

      if (res.success) {
        if (res.data.updatedByTherapist && res.data.updatedByTherapist == true) {
          setFirstTimeUpdatedByTherapist(true);
        } else {
          setEditNoteClicked(true);
          setFirstTimeUpdatedByTherapist(false);
        }
        updateDiagnosisData(true);
        setIsDataLoading(true);
        setEditNoteClicked(false);
        setIsSavingData(false);
        setPreviousNote(res.data);
        setHistoryOfPresenseIllnessFileArray([]);
        setMentalBehaviouralStatusFileArray([]);
        setAssessmentFileArray([]);
        setCarePlanFileArray([]);
        setHistoryOfPresentIllnessAttachmentsIdsForDelete([]);
        setMentalBehavioralStatusAttachmentsIdsForDelete([]);
        setAssessmentAttachmentsIdsForDelete([]);
        setCarePlanAttachmentsIdsForDelete([]);

        setChiefComplaint(res.data.chiefComplaint);

        setHistoryOfPresenseIllness(res.data.historyOfPresentIllness);

        setMentalBehaviouralStatus(res.data.mentalBehavioralStatus);

        setAssessments(res.data.asssessments);

        setCarePlan(res.data.carePlan);

        setPrimaryDiagnosisICD10Codes(res.data.diagnosisICDcodes);

        setSecondaryDiagnosisICD10Codes(res.data.secondaryDiagnosisICDcodes);

        setIntervention(res.data.intervention);

        setProcedureNotes(res.data.procedureNotes);

        setSignature(res.data.signature);

        setGoals(res.data.selectedGoals);

        setHistoryOfPresentIllnessAttachmentsFromResponse(res.data.historyOfPresentIllnessAttachments);

        setMentalBehavioralStatusAttachmentsFromResponse(res.data.mentalBehavioralStatusAttachments);

        setAssessmentAttachmentsFromResponse(res.data.assessmentAttachments);

        setCarePlanAttachmentsFromResponse(res.data.carePlanAttachments);

        setStep(1);

        setCurrentStage(Stages.SUBJECTIVE);

        setIsDataLoading(false);

        toast.success("Diagnosis Report Is Ready", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

      } else {
        toast.error(res.error ? res.error : "Server error occurred.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsSavingData(false);
      }
    }
  };

  const toggleVertical = (tab: React.SetStateAction<string>) => {
    if (activeVerticalTab !== tab) {
      setactiveVerticalTab(tab);
    }
  };

  const goBack = (step: number) => {
    const stages = ["subjective", "objective", "assessment", "plan"];
    if (step !== 1) {
      setStep(step - 1);
      setCurrentStage(stages[step - 2]);
    }

    if (step == 1) {
      setEditNoteClicked(false);
      setCurrentStage(stages[0]);
    }
  };

  // const handleChiefComplaint = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   if (event.target.value == "" || event.target.value == undefined || (event.target.value == null && chiefComplaint == "")) {
  //     setChiefComplaintError(true);
  //   } else {
  //     setChiefComplaintError(false);
  //   }
  //   setChiefComplaint(event.target.value);
  // };
  const handleChiefComplaintData = (event: any) => {
    if (!event) {
      setChiefComplaintError(true);
    } else {
      setChiefComplaintError(false);
    }
    setChiefComplaint(event);
  };

  const handleHistoryOfPresenseIllnessData = (event: any) => {
    if (!event) {
      setHistoryOfPresenseIllnessError(true);
    } else {
      setHistoryOfPresenseIllnessError(false);
    }
    setHistoryOfPresenseIllness(event);
  };

  const handleBehaviouralStatusData = (event: any) => {
    if (!event) {
      setMentalBehaviouralStatusError(true);
    } else {
      setMentalBehaviouralStatusError(false);
    }
    setMentalBehaviouralStatus(event);
  };

  const handleAssessmentsData = (event: any) => {
    if (!event) {
      setAssessmentsError(true);
    } else {
      setAssessmentsError(false);
    }
    setAssessments(event);
  };

  const handleProcedureNotesData = (event: any) => {
    if (!event) {
      setProcedureNoteError(true);
    } else {
      setProcedureNoteError(false);
    }
    setProcedureNotes(event);
  };

  const handleCarePlanData = (event: any) => {
    if (!event) {
      setCarePlanError(true);
    } else {
      setCarePlanError(false);
    }
    setCarePlan(event);
  };
  const signPad = useRef<SignaturePad>(null);

  function clearsign(): void {
    if(signPad?.current) {
       signPad.current.clear();
    }

  }

  const chacksignature = (sign: any) => {
    if (Object.keys(sign).length == 0) {
      return false;
    } else {
      return true;
    }
  };

  function savesign() {
     if(signPad.current !== null) {
        setSignature(signPad.current.getTrimmedCanvas().toDataURL("image/png"));
        seteditSignature(false);
     }


    // saveSignTherapist(signPad.current.getTrimmedCanvas().toDataURL("image/png"));
  }

  const saveSignTherapist = async (signature: any) => {
    const data = {
      signature: signature
    }
    const res = await TherapistService.updateTherapistSignature(data);
    if (res.success) {
      setUser({ ...user, signature: res.data.signature });
    }
  }

  const GenerateDiagnosisNotePdf = () => {

    const data = { 
      isVersion: isVersion
    }

    const diagnosisNoteType = isVersion
    ? FormVersionService.downloadDiagnosisPIENoteVersionDetails
    : VideoChatService.downloadDiagnosisPIENoteDetails;

    diagnosisNoteType(diagnosisId, data).then(res => {
      const pdfName = "DiagnosisReport_" + previousNote.patientID + "_" + moment(previousNote.meetingId.regularMeetingDate || previousNote.meetingId.createdAt || null).format("YYYY_MM_DD_HH_MMA") + ".pdf";
      if (res.success) {
        try {
          window.open(Util.downloadDiagnosisNoteDetail(pdfName));
          toast.success("Diagnosis Report Download Success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } catch {
          toast.error("Server error occurred", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Server error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const editorConfiguration = {
    // removePlugins: ["MediaEmbed"],
    toolbar: {
      items: ['heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'fontSize',
        'fontFamily',
        '|',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'imageUpload',
        'blockQuote',
        '|',
        'undo',
        'redo']
    },
  };

  //canvas
  function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const resizeCanvas = () => {
    try {
      if (signPad?.current) {
        const canvas = signPad.current.getCanvas();
        if (canvas) {
          const container = canvas.parentNode as HTMLElement;
          if (container) {
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = container.offsetWidth * ratio;
            canvas.height = container.offsetHeight * ratio;
            const context = canvas.getContext("2d");
            if (context) {
              context.scale(ratio, ratio);
            }
            signPad.current.clear();
          }
        }
      }
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    try {
      if (signPad?.current) {
        // Delay the initial resize to ensure SignaturePad is fully initialized
        setTimeout(() => {
          resizeCanvas();
        }, 0);
      }

      const debouncedResize = debounce(resizeCanvas, 200);
      window.addEventListener("resize", debouncedResize);

      // Using ResizeObserver to handle changes in container size
      const observer = new ResizeObserver(() => {
        resizeCanvas();
      });

      if (signPad?.current) {
        const container = signPad.current.getCanvas().parentNode as HTMLElement;
        if (container) {
          observer.observe(container);
        }
      }

      return () => {
        window.removeEventListener("resize", debouncedResize);
        observer.disconnect();
      };

    } catch (error) {
      console.log(error)
    }
  }, []);

  return (
    <>
      <React.Fragment>
        {!isDataLoading && !isDataLoadingError && !firstTimeUpdatedByTherapist && user?.role != Role.THERAPIST && (
          <div className="text-wrap text-break text-center p-2">
            <Label> Diagnosis Note Not Updated By Therapist </Label>
          </div>
        )}

        {isDataLoading && !isDataLoadingError && (
          <div className="flex justify-content-center mt-5">
            <img src={images.cubicLoader} style={{ width: "90px" }} />
          </div>
        )}

        {!isDataLoading && isDataLoadingError && <div className="loadingText mt-5">Server error occurred</div>}
        {!editNoteClicked && !isDataLoading && !isDataLoadingError && firstTimeUpdatedByTherapist && (
          <div className="flex justify-content-center">
            <Col lg="12">
              <div className="p-3" >
                <Form>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      {previousNote.meetingId && previousNote.meetingId.createdAt && (
                        <div className="text-wrap text-break font-size-20 text-center p-2 pl-0">
                          <Label> {"Diagnosis Report " + moment(previousNote.meetingId.regularMeetingDate || previousNote.meetingId.createdAt || null).format("YYYY-MM-DD")}</Label>
                        </div>
                      )}
                      <div className="d-flex justify-content-end">
                        {(previousNote.therapistId._id == user?._id || user?.role  == "SUPER_ADMIN" || user?.role  == "SUB_ADMIN") && (
                          <div className="text-center mr-5">
                            <a
                              onClick={() => {
                                GenerateDiagnosisNotePdf();
                              }}
                              className="btn btn-primary btn-sm  mt-3"
                            >
                              Download
                            </a>
                          </div>
                        )}
                        {!isVersion && previousNote.therapistId._id == user?._id && (
                          <div className="text-center">
                            <a
                              onClick={() => {
                                if (user?.role == "THERAPIST" && user?.signature) {
                                  setSignature(user?.signature);
                                }
                                setEditNoteClicked(true);
                              }}
                              className="btn btn-primary btn-sm edit-btn mt-3"
                            >
                              Edit
                            </a>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* {previousNote.meetingId && totalSpentDuration != null && (
                      <div className="text-wrap text-break h5 " style={{ textAlign: "center" }}>
                        <Label> {"Duration " + totalSpentDuration.toFixed(0) + " mins"}</Label>
                      </div>
                    )} */}
                    <Row className="">
                      <Col lg={6} className="mb-4">
                        <div className="diagnosis-card p-3  ">
                          <p className="diagnosis-title1 ">Insurance Details</p>
                          <div className="underline-d"></div>

                          <p className="diagnosis-title2 mb-0 pb-2 pt-4">Receiver</p>
                          <div className="flex row diagnosis-data-container min-h-100">
                            <div className="col-3 pt-1 pb-1 diagnosis-title3">Organization</div>
                            <div className="col-9 pt-1 pb-1 diagnosis-title4">
                              {previousNote?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany && (
                                <div className="text-wrap text-break">{previousNote?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany}</div>
                              )}
                            </div>
                          </div>

                          <p className="diagnosis-title2 mb-0 pb-2  pt-2">Subscriber</p>
                          <div className="flex row ">
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Member Id</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote?.clientId?.insuranceId?.subscriber?.memberId && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote?.clientId?.insuranceId?.subscriber?.memberId}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Policy Number</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote?.clientId?.insuranceId?.subscriber?.policyNumber && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.clientId.insuranceId.subscriber?.policyNumber}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row diagnosis-data-container min-h-100 mt-3">
                            <div className="col-6 pt-1 pb-1 diagnosis-title3">Payment Responsibility Level Code</div>
                            {previousNote?.clientId?.insuranceId?.subscriber?.paymentResponsibilityLevelCode && (
                              <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote?.clientId?.insuranceId?.subscriber?.paymentResponsibilityLevelCode}</p>
                            )}
                          </div>

                          <div className="flex row mt-3">
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">First Name</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote?.clientId?.insuranceId?.subscriber?.firstName && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.clientId.insuranceId.subscriber?.firstName}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Last Name</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote?.clientId?.insuranceId?.subscriber?.lastName && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.clientId.insuranceId.subscriber?.lastName}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row mt-3">
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Gender</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote?.clientId?.insuranceId?.subscriber?.gender && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.clientId.insuranceId.subscriber?.gender}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">DOB</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote?.clientId?.insuranceId?.subscriber?.dateOfBirth && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.clientId.insuranceId.subscriber?.dateOfBirth}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row diagnosis-data-container min-h-100 mt-3">
                            <div className="col-3 pt-1 pb-1 diagnosis-title3">Address</div>
                            <div className="col-9 pt-1 pb-1 diagnosis-title4">
                              {previousNote?.clientId?.insuranceId?.subscriber?.address && (
                                <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">
                                  {previousNote.clientId.insuranceId.subscriber?.address?.address1 + ", "}
                                  {previousNote.clientId.insuranceId.subscriber?.address?.city + ", "}
                                  {previousNote.clientId.insuranceId.subscriber?.address?.state + ", "}
                                  {previousNote.clientId.insuranceId.subscriber?.address?.postalCode + ", "}
                                </p>
                              )}
                            </div>
                          </div>
                          {previousNote?.clientId?.insuranceId?.insuranceCardId?.url && (
                            <div className="link-diagnosis-btn mt-3">
                              <p className="hw-attach  text-wrap text-break" key={1} id="1">
                                <i className="bx bx-paperclip"></i>&nbsp;
                                <span onClick={() => window.open(previousNote?.clientId?.insuranceId?.insuranceCardId?.url, "_blank")}>
                                  {previousNote?.clientId?.insuranceId?.insuranceCardId?.originalName
                                    ? "View File"
                                    : "File"}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="diagnosis-card min-h-100 p-3 ">
                          <p className="diagnosis-title1">Client Details</p>
                          <div className="underline-d"></div>

                          <div className="flex row mt-5">
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Name</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote.clientId.firstname && previousNote.clientId.lastname && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">
                                        {previousNote.clientId.firstname} {previousNote.clientId.lastname}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Patient Id</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote.patientID && <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break"> {previousNote.patientID} </p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row diagnosis-data-container min-h-100 mt-3">
                            <div className="col-3 pt-1 pb-1 diagnosis-title3">Address</div>
                            <div className="col-9 pt-1 pb-1 ">
                              {previousNote.clientId.streetAddress && previousNote.clientId.city && previousNote.clientId.state && (
                                <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">
                                  {previousNote.clientId.streetAddress + ","} {previousNote.clientId.city + ","} {previousNote.clientId.state}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="flex row mt-3">
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">DOB</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote.clientId.dateOfBirth && (
                                      <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">

                                        {moment(previousNote.clientId.dateOfBirth || null).format("YYYY-MM-DD")}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Gender</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote.clientId.gender && <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.clientId.gender}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row mt-3">
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Account Number</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote.patientAcountNo && <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.patientAcountNo}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6  ">
                              <div className="diagnosis-data-container min-h-100">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title3">Encounter Id</p>
                                  </div>
                                  <div className="col-6 pt-1 pb-1">
                                    {previousNote.encounterID && <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.encounterID}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row diagnosis-data-container min-h-100 mt-3">
                            <div className="col-3 pt-1 pb-1 diagnosis-title3">Encounter Type</div>
                            <div className="col-9 pt-1 pb-1">
                              {previousNote.encounterType && <p className="pt-1 pb-1 mb-0 plr-12 diagnosis-title4 text-wrap text-break">{previousNote.encounterType}</p>}
                            </div>
                          </div>

                        </div>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col lg={12} className="mb-4">
                        <div className="diagnosis-card p-3">
                          <p className="diagnosis-title2 mb-0 pb-2">Problem</p>


                          <div className="flex row ">
                            <div className="col-12  ">
                              {/* <p className="diagnosis-title5 mb-0 pb-1">Chief Complaint :</p> */}
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {/* <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{previousNote.chiefComplaint}</p> */}
                                    <p
                                      className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"
                                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previousNote.chiefComplaint) }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {previousNote.historyOfPresentIllnessAttachments && previousNote.historyOfPresentIllnessAttachments.length > 0 && (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#FD7F00",
                                }}
                                className="mb-0 pt-3 pb-1"
                              >
                                Attachments
                              </p>
                              <div className="">
                                <div className="link-diagnosis-btn" >
                                  {previousNote.historyOfPresentIllnessAttachments &&
                                    previousNote.historyOfPresentIllnessAttachments.length > 0 &&
                                    previousNote.historyOfPresentIllnessAttachments.map((file, index) => (
                                      <div key={index} className=" flex ">
                                        <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                          <i className="bx bx-paperclip"></i>&nbsp;
                                          <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? "View File" : "File"}</span>
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </>
                          )}

                          <p className="diagnosis-title2 mb-0 pb-2 pt-4">Intervention</p>


                          <div className="flex row ">
                            <div className="col-12  ">
                              {/* <p className="diagnosis-title5 mb-0 pb-1">Mental / Behavioral Status :</p> */}
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {/* <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{previousNote.mentalBehavioralStatus}</p> */}
                                    <p
                                      className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"
                                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previousNote.mentalBehavioralStatus) }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {previousNote.mentalBehavioralStatusAttachments && previousNote.mentalBehavioralStatusAttachments.length > 0 && (
                            <>
                              <Label
                                style={{
                                  fontSize: "14px",
                                  color: "#FD7F00",
                                }}
                                className="mb-0 pt-3 pb-1"
                              >
                                Attachments
                              </Label>
                              <div className="">
                                <div className="link-diagnosis-btn">
                                  {previousNote.mentalBehavioralStatusAttachments &&
                                    previousNote.mentalBehavioralStatusAttachments.length > 0 &&
                                    previousNote.mentalBehavioralStatusAttachments.map((file, index) => (
                                      <div key={index} className=" flex ">
                                        <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                          <i className="bx bx-paperclip"></i>&nbsp;
                                          <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? "View File" : "File"}</span>
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </>
                          )}

                          <p className="diagnosis-title2 mb-0 pb-2 pt-4">Evaluation </p>


                          <div className="flex row ">
                            <div className="col-4  ">
                              <p className="diagnosis-title5 mb-0 pb-1">CPT Code :</p>
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">
                                      {previousNote.cptCode && cptCodeList.filter((code: CPTCode) => code.cptCode == previousNote.cptCode)[0].cptCodeDescription}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-4  ">
                              <p className="diagnosis-title5 mb-0 pb-1">Diagnosis ICD 10 Codes :</p>
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {previousNote.diagnosisICDcodes && previousNote.diagnosisICDcodes.length > 0 && (
                                      <>
                                        {previousNote.diagnosisICDcodes.map((text, index) => (
                                          <div key={index} className="mt-1 mb-2 flex ">
                                            <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4" >
                                              {text.label}
                                            </p>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-4  ">
                              <p className="diagnosis-title5 mb-0 pb-1">Secondary Diagnosis ICD 10 Codes : </p>
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {previousNote.secondaryDiagnosisICDcodes && previousNote.secondaryDiagnosisICDcodes.length > 0 && (
                                      <>
                                        {previousNote.secondaryDiagnosisICDcodes.map((text, index) => (
                                          <div key={index} className="mt-1 mb-2 flex ">
                                            <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">
                                              {text.label}
                                            </p>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row pt-2">
                            <div className="col-12  ">
                              <p className="diagnosis-title5 mb-0 pb-1">Goals :</p>
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {/* <div className="flex plr-12">
                                      {previousNote.selectedGoals && previousNote.selectedGoals.length > 0 && (
                                        <>
                                          {previousNote.selectedGoals.length > 0 &&
                                            previousNote.selectedGoals.map((text, index) => (
                                              <span key={index}>
                                                {text && <p className="mb-0  pt-1 pb-1 diagnosis-title4 ms-1 me-1">{text}{index + 1 != previousNote.selectedGoals?.length && ','}</p>}
                                              </span>
                                            ))}
                                        </>
                                      )}
                                    </div> */}
                                    <div className="flex plr-12">
                                      {previousNote.selectedGoals && previousNote.selectedGoals.length > 0 && (
                                        <div className="row">
                                          <div className="col-12">
                                            <p className="mb-0 pt-1 pb-1 diagnosis-title4 ms-1 me-1 text-wrap-d">
                                              {previousNote.selectedGoals.map((text, index) => (
                                                <span key={index} className="ps-1 pe-1">
                                                  <span>
                                                    {text}
                                                    {index + 1 !== previousNote.selectedGoals.length && ','}
                                                  </span>
                                                </span>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {!previousNote.selectedGoals && (
                                      <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{previousNote.asssessments}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex row pt-2">
                            <div className="col-12  ">
                              <p className="diagnosis-title5 mb-0 pb-1">Care plan :</p>
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {/* <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{previousNote.asssessments}</p> */}
                                    <p
                                      className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"
                                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previousNote.carePlan) }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {previousNote.assessmentAttachments && previousNote.assessmentAttachments.length > 0 && (
                            <>
                              <Label
                                style={{
                                  fontSize: "14px",
                                  color: "#FD7F00",
                                }}
                                className="mb-0 pt-3 pb-1"
                              >
                                Attachments
                              </Label>
                              <div className="">
                                <div className="link-diagnosis-btn">
                                  {previousNote.assessmentAttachments &&
                                    previousNote.assessmentAttachments.length > 0 &&
                                    previousNote.assessmentAttachments.map((file, index) => (
                                      <div key={index} className=" flex ">
                                        <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                          <i className="bx bx-paperclip"></i>&nbsp;
                                          <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? "View File" : "File"}</span>
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col lg={4} className="mb-4">
                        <div className="diagnosis-card p-3  min-h-100 ">

                          <p className="diagnosis-title2 mb-0 pb-2 pt-4">Signature</p>
                          <div className="flex row min-h-100">
                            {chacksignature(signature) ? <img style={{ width: "full", height: "auto" }} src={signature} /> : null}
                          </div>

                        </div>
                      </Col>
                      <Col lg={8} className="mb-4">
                        <div className="diagnosis-card min-h-100 p-3 ">
                          <p className="diagnosis-title2 mb-0 pb-2 pt-4">Provider </p>


                          <div className="flex row ">
                            <div className="col-4 min-h-100 ">
                              <p className="diagnosis-title5 mb-0 pb-1">Therapist Name :</p>
                              <div className="diagnosis-data-container ">
                                <div className="row">
                                  <div className="col-12">
                                    {previousNote.therapistId.firstname && previousNote.therapistId.lastname && (
                                      <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">
                                        {previousNote.therapistId.firstname} {previousNote.therapistId.lastname}
                                      </p>
                                    )}
                                    {!previousNote.therapistId.firstname && !previousNote.therapistId.lastname && (
                                      <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{"-"}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {previousNote.therapistId._id == user?._id && (
                              <div className="col-4 min-h-100 ">
                                <p className="diagnosis-title5 mb-0 pb-1">NPI :</p>
                                <div className="diagnosis-data-container ">
                                  <div className="row">
                                    <div className="col-6">
                                      {previousNote.therapistId.nPI1 ? (
                                        <div className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> {previousNote.therapistId.nPI1} </div>
                                      ) : (
                                        <div className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {previousNote.therapistId._id == user?._id && (
                              <div className="col-4 min-h-100 ">
                                <p className="diagnosis-title5 mb-0 pb-1">NPI 2 :</p>
                                <div className="diagnosis-data-container ">
                                  <div className="row">
                                    <div className="col-6">
                                      {previousNote.therapistId.nPI2 ? (
                                        <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> {previousNote.therapistId.nPI2} </p>
                                      ) : (
                                        <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {previousNote.therapistId._id == user?._id && (
                            <div className="flex row pt-2">
                              <div className="col-4 min-h-100 ">
                                <p className="diagnosis-title5 mb-0 pb-1">License :</p>
                                <div className="diagnosis-data-container ">
                                  <div className="row">
                                    <div className="col-12">
                                      {previousNote.therapistId.license ? (
                                        <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> {previousNote.therapistId.license} </p>
                                      ) : (
                                        <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 min-h-100 ">
                                <p className="diagnosis-title5 mb-0 pb-1">Taxonomy Code :</p>
                                <div className="diagnosis-data-container ">
                                  <div className="row">
                                    <div className="col-6">
                                      {previousNote.therapistId.taxonomyCode ? (
                                        <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> {previousNote.therapistId.taxonomyCode} </p>
                                      ) : (
                                        <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4"> - </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 min-h-100 ">
                                <p className="diagnosis-title5 mb-0 pb-1">Address :</p>
                                <div className="diagnosis-data-container ">
                                  <div className="row">
                                    <div className="col-6">
                                      <p className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4 min-h-100">
                                        {previousNote.therapistId.streetAddress && previousNote.therapistId.city && previousNote.therapistId.state && (
                                          <span>
                                            {previousNote.therapistId.streetAddress} {previousNote.therapistId.city} {previousNote.therapistId.state}
                                          </span>
                                        )}
                                        {!previousNote.therapistId.streetAddress && !previousNote.therapistId.city && !previousNote.therapistId.state && (
                                          <span className="mb-0 plr-12 pt-1 pb-1 diagnosis-title4">{"-"}</span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Col>
          </div >
        )}

        {
          editNoteClicked && !isDataLoading && !isDataLoadingError && (
            <div className="flex justify-content-center">
              <Col lg="12" className="mt10">
                <div className="edit-note-wrap">
                  <Form>
                    <div className="mb-3">
                      {previousNote.meetingId && previousNote.meetingId.createdAt && (
                        <div className="text-wrap text-break text-center ">
                          <Label className="mt-3 font-size-20">

                            {"Diagnosis Report " + moment(previousNote.meetingId.regularMeetingDate || previousNote.meetingId.createdAt || null).format("YYYY-MM-DD")}
                          </Label>
                        </div>
                      )}

                      <div className="py-5">
                        <PIENoteHeader step={step} />
                      </div>

                      {step == 1 && (
                        <Row>
                          {/* <Col lg={6}>
                            <Card>
                              <div className="content-list-body">
                                <SubjectiveTab
                                  stage={currentStage}
                                  activeVerticalTab={activeVerticalTab}
                                  toggleVertical={toggleVertical}
                                  chiefComplaint={chiefComplaint}
                                  setChiefComplaint={setChiefComplaint}
                                  element={element}
                                  historyOfPresenseIllness={historyOfPresenseIllness}
                                  setHistoryOfPresenseIllness={setHistoryOfPresenseIllness}
                                />
                              </div>
                            </Card>
                          </Col> */}
                          <Col lg={12}>
                            <Card>
                              <div className="p-3">
                                <CardTitle className="font-size-20 mb-3 text-center">
                                  PROBLEM
                                  {/* <span className="text-muted font-size-15">What the client tells you </span> */}
                                </CardTitle>

                                <div className="note-tab">
                                  <div className="mb-3">
                                    <p className="text-muted font-size-15">
                                      <strong>Problem</strong>
                                    </p>

                                    {/* <textarea
                                    className={!chiefComplaintError ? "form-control " : "form-control is-invalid"}
                                    style={{ resize: "none" }}
                                    rows={8}
                                    name={RefNames.CHIEF_COMPLAINT}
                                    ref={chiefComplaintRef}
                                    value={chiefComplaint}
                                    onClick={() => setCursorPosition("1")}
                                    onChange={event => {
                                      handleChiefComplaint(event);
                                    }}
                                  ></textarea> */}
                                    <div className="editor-area ck-content">
                                      <CKEditor
                                        editor={Editor}
                                        style={{
                                          maxHeight: "300px",
                                          border: chiefComplaint ? "1px solid black" : "2px solid red",
                                          backgroundColor: "red"
                                        }}
                                        onChange={(event: any, editor: { getData: () => any }) => {
                                          const data = editor.getData();
                                          handleChiefComplaintData(data);
                                        }}
                                        onFocus={(event: any, editor: any) => {
                                          setCursorPosition("1");
                                        }}
                                        data={`${chiefComplaint}`}
                                        config={editorConfiguration}
                                      />
                                      {/* <CKEditor
                                      editor={Editor}
                                      ref={chiefComplaintRef}
                                      stlye={{ minHeight: "300px",border: "1px solid red" }}
                                      onChange={(event: any, editor: { getData: () => any }) => {
                                        const data = editor.getData();
                                        handleChiefComplaintData(data);
                                      }}
                                      onFocus={(event: any, editor: any) => {
                                        setCursorPosition("1");
                                      }}
                                      data={`${chiefComplaint}`}
                                      config={editorConfiguration}
                                      data-name={RefNames.CHIEF_COMPLAINT}
                                    /> */}
                                    </div>

                                    {chiefComplaintError && <div className="invalid-feedback">This field is required.</div>}
                                  </div>


                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      )}

                      {step == 2 && (
                        <>
                          <Row>
                            {/* <Col lg={6}>
                              <Card>
                                <div className="content-list-body">
                                  <ObjectiveTab
                                    stage={currentStage}
                                    activeVerticalTab={activeVerticalTab}
                                    toggleVertical={toggleVertical}
                                    mentalBehaviouralStatus={mentalBehaviouralStatus}
                                    setMentalBehaviouralStatus={setMentalBehaviouralStatus}
                                  />
                                </div>

                                {historyOfPresenseIllnessError && <div className="invalid-feedback">This can not be empty</div>}
                              </Card>
                            </Col> */}
                            <Col lg={12}>
                              <Card>
                                <div className="p-3">
                                  <CardTitle className="font-size-20 mb-3 text-center">
                                    INTERVENTION
                                     {/* <span className="text-muted font-size-15">Observable and measurable data</span> */}
                                  </CardTitle>

                                  <div className="note-tab">
                                    <div className="mb-3">
                                      {/* <p className="text-muted font-size-15">
                                        <strong>Mental/Behavioral Status :</strong>
                                      </p> */}

                                      {/* <textarea
                                      className={mentalBehaviouralStatusError ? "form-control is-invalid" : "form-control"}
                                      style={{ height: "20vh" }}
                                      value={mentalBehaviouralStatus}
                                      onChange={event => {
                                        if (event.target.value.trim() == "") {
                                          setMentalBehaviouralStatusError(true);
                                        } else {
                                          setMentalBehaviouralStatusError(false);
                                        }
                                        setMentalBehaviouralStatus(event.target.value);
                                      }}
                                    ></textarea> */}
                                      <div className="editor-area ck-content">
                                        <CKEditor
                                          editor={Editor}
                                          stlye={{ maxHeight: "300px" }}
                                          onChange={(event: any, editor: { getData: () => any }) => {
                                            const data = editor.getData();
                                            handleBehaviouralStatusData(data)
                                          }}
                                          // onFocus={(event: any, editor: any) => {
                                          //   setCursorPosition("3");
                                          // }}
                                          data={`${mentalBehaviouralStatus}`}
                                          config={editorConfiguration}
                                        // name={RefNames.HISTORY_OF_PRESENT_ILLNESS}
                                        // ref={historyOfPresenceRef}
                                        />
                                      </div>
                                    </div>

                                    {mentalBehaviouralStatusError && <div className="invalid-feedback">This can not be empty</div>}

                                    {/* <div className="mb-3 ml-3">
                                      <Label
                                        htmlFor="handle-mental-be-fileChange"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          color: "#FD7F00",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        + Add Attachments
                                      </Label>

                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="handle-mental-be-fileChange"
                                        placeholder="Article File"
                                        name="articleFile"
                                        onChange={event => {
                                          handleMentalBehaviourFileChange(event);
                                        }}
                                        style={{ display: "none" }}
                                        required
                                      />

                                      {mentalBehaviouralStatusFileArray.length > 0 &&
                                        mentalBehaviouralStatusFileArray.map((file, index) => (
                                          <div key={index} className="mt-1 mb-2 flex ">
                                            <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                              {file.name}
                                            </div>
                                            <div className="pull-left">
                                              <i
                                                className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                                onClick={() => {
                                                  deleteMentalBehaviourFile(index);
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        ))}
                                    </div> */}

                                    {/* {mentalBehavioralStatusAttachmentsFromResponse.length > 0 && (
                                      <div className="mb-3 ml-3">
                                        {mentalBehavioralStatusAttachmentsFromResponse.length > 0 &&
                                          mentalBehavioralStatusAttachmentsFromResponse.map((file, index) => (
                                            <div key={index} className="mt-1 mb-2 flex ">
                                              <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                                <i className="bx bx-paperclip"></i>&nbsp;
                                                <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                              </p>
                                              <div className="pull-left">
                                                <i
                                                  className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                                  onClick={() => {
                                                    const finalArray = [...mentalBehavioralStatusAttachmentsIdsForDelete, file._id];
                                                    setMentalBehavioralStatusAttachmentsIdsForDelete(finalArray);
                                                    mentalBehavioralStatusAttachmentsFromResponse.splice(index, 1);

                                                    const finalArray2 = [...mentalBehavioralStatusAttachmentsFromResponse];

                                                    setMentalBehavioralStatusAttachmentsFromResponse(finalArray2);
                                                  }}
                                                ></i>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}



                      {step == 3 && (
                        <>
                          <Row>
                            {/* <Col lg={6}>
                              <Card>
                                <div className="content-list-body">
                                  <PlanTab
                                    stage={currentStage}
                                    activeVerticalTab={activeVerticalTab}
                                    toggleVertical={toggleVertical}
                                    carePlan={carePlan}
                                    setCarePlan={setCarePlan}
                                    procedureNotes={procedureNotes}
                                    element={element}
                                    setProcedureNotes={setProcedureNotes}
                                  />
                                </div>
                              </Card>
                            </Col> */}
                            <Col lg={12}>
                              <Card>
                                <div className="p-3">
                                  <CardTitle className="font-size-20 mb-3 text-center">EVALUATION</CardTitle>

                                  <div className="note-tab">

                                   <div className="mb-3">
                                      {/* <p className="text-muted font-size-15">
                                        <strong>Diagnosis Notes:</strong>
                                      </p> */}

                                      <div className="editor-area ck-content">
                                        <CKEditor
                                          editor={Editor}
                                          stlye={{ maxHeight: "300px" }}
                                          onChange={(event: any, editor: { getData: () => any }) => {
                                            const data = editor.getData();
                                            handleCarePlanData(data)
                                          }}
                                          // onFocus={(event: any, editor: any) => {
                                          //   setCursorPosition("3");
                                          // }}
                                          data={`${carePlan}`}
                                          config={editorConfiguration}
                                        // name={RefNames.HISTORY_OF_PRESENT_ILLNESS}
                                        // ref={historyOfPresenceRef}
                                        />
                                      </div>
                                    </div>

                                     {carePlanError && <div className="invalid-feedback">This can not be empty</div>}

                                    {/* <div className="mb-3 ml-3">
                                      <Label
                                        htmlFor="handle-care-plan-fileChange"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          color: "#FD7F00",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        + Add Attachments
                                      </Label>
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="handle-care-plan-fileChange"
                                        placeholder="Article File"
                                        name="articleFile"
                                        onChange={event => {
                                          handleCarePlanFileChange(event);
                                        }}
                                        style={{ display: "none" }}
                                        required
                                      />
                                      {carePlanFileArray.length > 0 &&
                                        carePlanFileArray.map((file, index) => (
                                          <div key={index} className="mt-1 mb-2 flex ">
                                            <div className=" text-wrap text-break" style={{ maxWidth: "45vw" }}>
                                              {file.name}
                                            </div>
                                            <div className="pull-left">
                                              <i
                                                className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                                onClick={() => {
                                                  deleteCarePlanFile(index);
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        ))}
                                      &nbsp;
                                    </div> */}

                                    {/* {carePlanAttachmentsFromResponse.length > 0 && (
                                      <div className="mb-3 ml-3">
                                        {carePlanAttachmentsFromResponse.length > 0 &&
                                          carePlanAttachmentsFromResponse.map((file, index) => (
                                            <div key={index} className="mt-1 mb-2 flex ">
                                              <p className="hw-attach  text-wrap text-break" key={index} id={index.toString()}>
                                                <i className="bx bx-paperclip"></i>&nbsp;
                                                <span onClick={() => window.open(file.url, "_blank")}>{file.originalName ? file.originalName : "File"}</span>
                                              </p>
                                              <div className="pull-left">
                                                <i
                                                  className="fa fa-trash fa-lg remove-icon text-danger pl10"
                                                  onClick={() => {
                                                    const finalArray = [...carePlanAttachmentsIdsForDelete, file._id];
                                                    setCarePlanAttachmentsIdsForDelete(finalArray);
                                                    carePlanAttachmentsFromResponse.splice(index, 1);

                                                    const finalArray2 = [...carePlanAttachmentsFromResponse];

                                                    setCarePlanAttachmentsFromResponse(finalArray2);
                                                  }}
                                                ></i>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    )} */}


                                    <div className="mb-3">
                                      <p className="text-muted font-size-15">
                                        <strong>CPT Code:</strong>
                                      </p>

                                      <select
                                        className={cptCodeError ? "form-control is-invalid" : "form-control"}
                                        value={cptCode}
                                        onChange={event => handleCPTCodeChange(event)}
                                      >
                                        {cptCodeList &&
                                          cptCodeList.map((code: CPTCode, index: number) => {
                                            return (
                                              <option key={index} value={code.cptCode}>
                                                {code.cptCodeDescription}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {cptCodeError && (
                                        <div style={{ width: "100%", marginTop: " 0.25rem", fontSize: "80%", color: " #f46a6a" }}>This can not be empty</div>
                                      )}

                                    </div>

                                    <div className="mb-3">
                                      <p className="text-muted font-size-15">
                                        <strong>Primary Diagnosis ICD 10 codes:</strong>
                                      </p>

                                      <Select
                                        closeMenuOnSelect={false}
                                        defaultValue={primaryDiagnosisICD10Codes}
                                        isMulti
                                        options={options}
                                        styles={customStylesForICD10Codes}
                                        onChange={handlePrimaryDiagnosisChange}
                                      />
                                    </div>

                                    {primaryDiagnosisICD10CodesError && (
                                      <div style={{ width: "100%", marginTop: " 0.25rem", fontSize: "80%", color: " #f46a6a" }}>This can not be empty</div>
                                    )}

                                    <div className="mb-3">
                                      <p className="text-muted font-size-15">
                                        <strong>Secondary Diagnosis ICD 10 codes:</strong>
                                      </p>

                                      <Select
                                        closeMenuOnSelect={false}
                                        defaultValue={secondaryDiagnosisICD10Codes}
                                        isMulti
                                        options={options}
                                        styles={customStylesForICD10Codes}
                                        onChange={handleSecondaryDiagnosisChange}
                                      />
                                    </div>





                                    <div className="mb-3">
                                      <p className="text-muted font-size-15">
                                        <strong>Goals :</strong>
                                      </p>

                                      <div className="flex">
                                        <textarea
                                          className={goalsError ? "form-control is-invalid" : "form-control"}
                                          style={{ height: "5vh" }}
                                          value={goalsText}
                                          onChange={event => {
                                            setGoalsText(event.target.value);
                                          }}
                                        ></textarea>
                                        <div className="ml-3 mt-1" style={{ textAlign: "center" }}>
                                          <a
                                            onClick={() => {
                                              handleGoalsChange(goalsText);
                                              setGoalsText("");
                                            }}
                                            className="btn btn-primary btn-sm"
                                          >
                                            +&nbsp;Add
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                    {goalsError && (
                                      <div style={{ width: "100%", marginTop: " 0.25rem", fontSize: "80%", color: " #f46a6a" }}>This can not be empty</div>
                                    )}

                                    {goals.length > 0 && (
                                      <div className="form-control mb-3" style={{ background: "#F5F2F1" }}>
                                        {goals.length > 0 &&
                                          goals.map((text, index) => (
                                            <div key={index} className="flex ">
                                              <p className="text-wrap text-break" key={index} id={index.toString()}>
                                                <span>&#9679;&nbsp;{text}</span>
                                              </p>
                                              <div className="pull-left">
                                                <i
                                                  className="fa fa-close fa-lg remove-icon text-danger pl10"
                                                  onClick={() => {
                                                    deleteGoal(index);
                                                  }}
                                                ></i>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                    <div className="mb-3 ">
                                      <p className="text-muted font-size-15">
                                        <strong>Signature :</strong>
                                      </p>
                                      {editsignature || !chacksignature(signature) ? (
                                        <>
                                          <div className="d-flex justify-content-between mb-4">
                                            <div className="text-center">
                                              <a
                                                onClick={() => {
                                                  clearsign();
                                                }}
                                                className="btn btn-primary btn-sm  mt-3"
                                              >
                                                Clear
                                              </a>
                                            </div>
                                            <div className="text-center">
                                              <a
                                                onClick={() => {
                                                  savesign();
                                                }}
                                                className="btn btn-primary btn-sm  mt-3"
                                              >
                                                Save
                                              </a>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-center">
                                            <div className="sign">
                                              <div className='sigCanvasClientAssesmentDiagoniceNoteNew'>
                                                  <SignaturePad backgroundColor="rgb(245,242,241)" ref={signPad} canvasProps={{  className:"sigCanvasClientAssesmentDiagoniceNote" }} penColor="black"></SignaturePad>
                                              </div>

                                            </div>

                                          </div>
                                        </>
                                      ) : (
                                        <div className="d-flex flex-col justify-content-between">
                                          <img className="sigCanvasClientAssesmentDiagoniceNote" src={signature} />
                                          <div className="text-center">
                                            <a onClick={() => seteditSignature(true)} className="btn btn-primary btn-sm  mt-3">
                                              Edit
                                            </a>
                                          </div>
                                        </div>

                                      )}
                                    </div>


                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}

                      <div className={step == 1 ? "flex flex-row-reverse" : "flex justify-content-between"}>
                        {!isSavingData && !firstTimeUpdatedByTherapist && step != 1 && (
                          <div className={step == 1 ? "d-none" : "mb-3"}>
                            <a
                              onClick={() => {
                                goBack(step);
                              }}
                              className="btn btn-primary"
                            >
                              Previous
                            </a>
                          </div>
                        )}

                        {!isSavingData && firstTimeUpdatedByTherapist && (
                          <div className={step == 1 ? "d-none" : "mb-3"}>
                            <a
                              onClick={() => {
                                goBack(step);
                              }}
                              className="btn btn-primary"
                            >
                              Previous
                            </a>
                          </div>
                        )}

                        <div className="mb-3">
                          <a
                            onClick={() => {
                              if (!isSavingData) {
                                if (step == 1) {
                                  validateStepOne();
                                } else if (step == 2) {
                                  validateStepTwo();
                                } else if (step == 3) {
                                  validateStepThree();
                                }
                                // else if (step == 4) {
                                //   // validateStepFourAndSave();
                                // }
                              }
                            }}
                            className={isSavingData ? "btn btn-secondary" : "btn btn-primary"}
                          >
                            {!isSavingData ? (step == 3 ? "Save Changes" : "Next") : "Saving..."}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </div>
          )
        }
      </React.Fragment >
    </>
  );
};

export default PIEDiagnosisNoteViewNew;
