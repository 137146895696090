import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, Col, Row, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import bgIcon from "../../assets/images/layouts/Intersect.png";
import videoIcon from "../../assets/images/icons/fi_video.png";
import chatIcon from "../../assets/images/icons/u_comment-dots.png";
import calendarIcon from "../../assets/images/icons/u_calender.png";
import { Carousel } from "react-bootstrap";
import likeIcon from "../../assets/images/icons/fi_thumbs-up.svg";
import dislikeIcon from "../../assets/images/icons/fi_thumbs-down.svg";
import UserContext from "src/context/UserContext";
import { CommonService } from "src/services/CommonService";
import { TherapistService } from "src/services/TherapistService";
import { ExperienceTag, SelectedExperienceTag } from "src/models/ExperienceTag";
import { Ethinicity } from "src/models/Ethnicity";
import { Profession } from "src/models/Profession";
import { ArticleService } from "src/services/ArticleService";
import Select from "react-select";
import { Tags } from "../../common/custom-components/Tags";
import { toast } from "react-toastify";
import { ClientService } from "src/services/ClientService";
import { ClientPreferences } from "src/models/Client";
import { DiscoverTherapist, Therapist } from "src/models/Therapist";
import { Util } from "src/Util";
import Swal from "sweetalert2";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import { NotificationService } from "src/services/NotificationService";
import { SocketContext } from "src/context/ScoketContext";
import { NavLink } from "react-router-dom";
import AppointmentModal from "../../pages/Popup/ScheduleAppointmentByClientModal";
interface discoverTherapists {
  discoverTherapists: Therapist[];
  onNext: MouseEventHandler<HTMLButtonElement>;
  onBack: MouseEventHandler<HTMLButtonElement>;
  requestTherapistCount: number;
  refreshList: (data: any) => void;
  offset: number;
  sentSelectedUser: (data: any) => void;
}
// interface matchedTherapistListProps {
//   matchedTherapists: FriendRequest[]
//   onNext: MouseEventHandler<HTMLButtonElement>;
//   onBack: MouseEventHandler<HTMLButtonElement>;
//   requestTherapistCount: number;
//   offset: number;
// }
const DiscoverProfessionals: React.FC<discoverTherapists> = ({
  discoverTherapists,
  onNext,
  onBack,
  requestTherapistCount,
  refreshList,
  offset,
  sentSelectedUser,
}) => {
  const [user, setUser] = useContext(UserContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [gender, setGender] = useState(user?.preference?.gender);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [isFilterUpdateOpen, setIsFilterUpdateOpen] = useState(false);
  const [selectedExpTags, setSelectedExpTags] = useState<any[]>([] as SelectedExperienceTag[]);
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [searchTherapistName, setSearchTherapistName] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [nextEventReady, setNextEventReady] = useState(true);
  const socket = useContext(SocketContext);
  const [therapistList, setTherapistList] = useState([] as Therapist[]);
  const [therapistvideo, setTherapisvideo] = useState({} as Therapist);
  const [modalcentervideo, setModalCenterVideo] = useState<boolean>(false);
  const [modalOpenSession, setModalOpenSession] = useState(false);
  const [clickedTherapistData, setClickedTherapistData] = useState<any>({} as any);
  const [clickedTherapistId, setClickedTherapistId] = useState<any>();

  const toggleModalSession = () => {
    setModalOpenSession(!modalOpenSession);
  };

  const toggleFilterCanvas = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const changeGender = useCallback(
    (gender: string) => () => {
      setIsFilterUpdateOpen(true);
      setGender(gender);
    },
    []
  );

  useEffect(() => {
    setTherapistList(discoverTherapists);
  }, [discoverTherapists]);

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthnicityList(res.data);
    });

    TherapistService.getProfessions().then(res => {
      setProfessionsDetails(res.data);
    });

    const selectedExp = user?.preference?.experiencedIn?.map(item => {
      if (item) {
        return { value: item._id, label: item.experienceTag };
      }
    });

    setSelectedExpTags(selectedExp ? selectedExp : []);
    setGender(user?.preference?.gender ? user?.preference?.gender : "");
    setSelectedEthnicity(user?.preference?.ethnicityId ? user?.preference?.ethnicityId : "");
    setSelectedProfession(user?.preference?.professionId ? user?.preference?.professionId : "");

    getAllExperienceTags();
  }, []);

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });

        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };
  const handlExpTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label, value: tag.value };
      });

      if (updatedTags) {
        setIsFilterUpdateOpen(true);

        setSelectedExpTags(updatedTags);
      }
    }
  };
  const removeExpTag = (removedtag: any) => {
    setIsFilterUpdateOpen(true);

    setSelectedExpTags(selectedExpTags.filter(tag => tag.label !== removedtag));
  };

  const updateTherapistSearchPreferences = () => {
    const clientPreferences: ClientPreferences = {
      gender: gender,
      ethnicityId: selectedEthnicity,
      professionId: selectedProfession,
      experiencedIn: selectedExpTags.map(tag => {
        return tag.value;
      }),
    };

    ClientService.addPreferences(clientPreferences).then(res => {
      if (res.success) {
        setUser({ ...user, preference: clientPreferences });

        toast.success(res.success, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsFilterUpdateOpen(false);
      }
    });
  };
  const applyFilters = () => {
    // setSkip(0);
    // setBackBtnEnable(false);
    const expTags = selectedExpTags?.map((tag: { label: any; value: any }) => {
      return tag.value;
    });

    const discoverObject: DiscoverTherapist = {
      ethnicity: selectedEthnicity,
      profession: selectedProfession,
      experiencedIn: expTags,
      gender: gender,
      searchTherapistName: searchTherapistName,
      // state: user?.state,
    };

    // searchTherapists(discoverObject);

    toggleFilterCanvas();

    const clientPreferences: ClientPreferences = {
      gender: gender,
      ethnicityId: selectedEthnicity || user?.preference?.ethnicityId,
      professionId: selectedProfession || user?.preference?.professionId,
      experiencedIn: selectedExpTags.map(tag => {
        return tag.value;
      }),
    };

    setUser({ ...user, preference: clientPreferences });
  };

  const handleLike = (therapist: Therapist) => {
    if (!nextEventReady) {
      toast.error(`Please wait until previous request is completed!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (therapist) {
      // Swal.fire({
      //   icon: "success",
      //   title: "Do you like to chat with " + therapist.firstname + " " + therapist.lastname + "?",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes",
      //   confirmButtonColor: "#50a5f1",
      //   cancelButtonText: "Later",
      //   cancelButtonColor: "#f46a6a",
      // }).then(result => {
      //   if (result.isConfirmed) {
      setNextEventReady(false);

      ClientService.likeTherapist(therapist._id).then((res: any) => {
        setTherapistList(therapistList.filter((t: Therapist) => t._id !== therapist._id));

        // setIsSent(!isSent);
        refreshList(therapist);

        // setChangeState(Math.random());

        toast.success(`Request is sent to the Therapist!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        const notificationData: NotificationModel = {
          senderId: user?._id,
          receiverId: therapist._id,
          event: NotificationEvent.REQUEST_SENT,
          link: "/dashboard",
          content: "New request is received from " + user?.firstname + " " + user?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(notificationData).then((res: any) => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: notificationData,
            receiverId: therapist._id,
            senderId: user?._id,
          };

          socket.emit("send-notification", socketData);
        });

        setNextEventReady(true);
        openAppointmentModal(therapist);
      });
      //   }
      // });
    } else {
      toast.error(`No therapist id is provided!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  const openAppointmentModal = (therapist: Therapist) => {
    setClickedTherapistData(therapist);
    setClickedTherapistId(therapist?._id);
    toggleModalSession();
  };
  const handledislike = (therapist: Therapist) => {
    if (!nextEventReady) {
      toast.error(`Please wait until previous request is completed!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure want to dislike " + therapist.firstname + " " + therapist.lastname + "?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        setNextEventReady(false);

        ClientService.dislikeTherapist(therapist._id).then(res => {
          const newList = therapistList.filter((t: Therapist) => t._id !== therapist._id);

          setTherapistList(newList);

          // refreshList(therapist);

          // reduceSkip(therapist._id!);

          // setChangeState(Math.random());

          toast.success(`Therapist is disliked!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setNextEventReady(true);
        });
      }
    });
  };

  const watchvideo = (therapist: Therapist) => {
    setTherapisvideo(therapist);
    setModalCenterVideo(true);
  };
  const handleMatchedTherapist = (therapist: any) => {
    if (therapist) {
      handleLike(therapist);
      toggleModalSession();
    }
  };

  const handleClickedMatch = (therapist: Therapist) => {
    if (therapist) {
      setClickedTherapistData(therapist);
      toggleModalSession();
    }
  };

  const checkMatch = (stateList: any, singleState: any) => {
    if (user?.state) {
      if (user?.state == singleState || stateList?.includes(user?.state)) {
        return (false)
      } else {
        return (true)
      }
    } else {
      return (true)
    }
  }

  return (
    <React.Fragment>
      {/* <Row>
        <div className="d-flex justify-content-end mb-4">
          <button className="btn btn-primary personalized-btn-dashboard btn-sm" onClick={toggleFilterCanvas}>Personalize my match&nbsp;<i className="fa fa-filter"></i></button>
        </div>
      </Row> */}
      <Row className="">
        {therapistList?.map((therapist: Therapist, index: number) => (
          <Col key={index} md={4} className="d-flex justify-content-center">
            <Card className="match-card-dashboard position-relative max-w-263">
              <div className="d-flex justify-content-between">
                <img src={bgIcon} alt="Icon" className="card-img-top " style={{ width: "123.68px", height: "85.72px" }} />
                <button className="btn btn-primary btn-size-40 call-btn-bg mt-2 me-2 z-index-100" onClick={() => watchvideo(therapist)}>
                  <img src={videoIcon} className="btn-icon-2" />
                </button>
              </div>
              <CardBody className="d-flex flex-column justify-content-center align-items-center match-card-dashboard-body">
                <NavLink to={"#"}>
                  <span onClick={() => sentSelectedUser(therapist)}>
                    {!therapist?.photoId || therapist?.photoId == null || therapist?.photoId == undefined ? (
                      <div className="avatar-md mx-auto mx-auto mb-4 ">
                        <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>{therapist?.firstname?.charAt(0)}</span>
                      </div>
                    ) : (
                      <div className="col-12 d-flex justify-content-center align-items-center mb-4">
                        <img src={Util.fileURL(therapist?.photoId?._id)} className="avatar-md rounded-circle" />
                      </div>
                    )}
                  </span>
                </NavLink>
                <h5 className="mb-0 d-flex mt-2 justify-content-start fs-14 align-items-start fc-black">{therapist?.firstname}</h5>
                <p className="mb-0">THERAPIST</p>
                <hr className="match-card-hr" />
                <div className="row discover-prof-container w-100">
                  <Carousel indicators={false} interval={2000} controls={false}>
                    {therapist?.experiencedIn?.map((experience: any, index: number) => (
                      <Carousel.Item key={index}>
                        <div>
                          <div className="match-footer-card-item2 d-flex justify-content-center align-items-center">
                            <span className="p-3 pt-2 pb-2">
                              {" "}
                              {experience.experienceTag.length > 20 ? `${experience.experienceTag.substring(0, 20)}...` : experience.experienceTag}
                            </span>
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>

                  {therapist?.experiencedIn?.length == 0 && (
                    <div className="row discover-prof-container w-100 d-flex justify-content-center align-items-center">No experience tags</div>
                  )}

                  <div className="overlay-discover"></div>
                </div>
              </CardBody>
              <CardFooter className="match-card-footer">
                <div className="d-flex justify-content-between min-width-100 mt-1 mb-1">
                  <div>
                    {/* <img src={likeIcon} className="btn-icon-2 cursor-pointer" onClick={() => { handlelike(therapist) }} /> */}
                    <button
                      className="btn btn-primary btn-sm match-btn1"
                      onClick={() => {
                        // toggleModalSession();
                        // setClickedTherapistData(therapist);
                        handleClickedMatch(therapist);
                        // setClickedTherapistId(therapist?._id);
                      }}
                      disabled={checkMatch(therapist?.therapyState, therapist?.state)}
                    >
                      Match
                    </button>
                  </div>
                  <div>
                    <img
                      src={dislikeIcon}
                      className="btn-icon-2 cursor-pointer"
                      onClick={() => {
                        handledislike(therapist);
                      }}
                    />
                    {/* <button className="btn btn-primary btn-sm" onClick={() => {
                                             handledislike(therapist)
                                            }}>Dismatch</button> */}
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
      {discoverTherapists?.length == 0 && (
        <div className="col-md-12 text-center mt50 mb50">
          There are currently no licensed clinicians that match your criteria in your area.
          <br />
          Please be patient as we onboard more licensed clinicians in your area.
        </div>
      )}
      <Row>
        <div className="d-flex justify-content-between ">
          <div>
            {offset > 1 && (
              <button className="btn btn-secondary btn-sm" onClick={onBack}>
                <i className="bx bx-left-arrow-alt me-2"></i> Back
              </button>
            )}
          </div>
          <div>
            {therapistList?.length > 2 && discoverTherapists?.length !== 0 && (
              <button className="btn btn-primary btn-sm" onClick={onNext}>
                Next <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
            )}
          </div>
        </div>
      </Row>

      <AppointmentModal
        isOpen={modalOpenSession}
        toggle={toggleModalSession}
        setIsOpen={setModalOpenSession}
        therapistData={clickedTherapistData}
        sendTherapistData={handleMatchedTherapist}
      />
      
      <Offcanvas isOpen={isFilterOpen} direction="end" toggle={toggleFilterCanvas} className="discover-canvas">
        <OffcanvasHeader toggle={toggleFilterCanvas}>Personalize my match</OffcanvasHeader>

        <OffcanvasBody>
          <div className="row  pt20 pl10">
            <div className="filter-option">
              <form className="mb-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Therapist Name..."
                      aria-label="Therapist's name"
                      value={searchTherapistName}
                      onChange={e => setSearchTherapistName(e.target.value)}
                    />
                  </div>
                </div>
              </form>

              <div className="mb-3">Gender:</div>

              <div className="col-xl-12 pr-3 mb-3">
                <div style={{ display: "flex" }} className="flex-wrap">
                  <div className="mb-3" style={{ marginRight: "1rem" }}>
                    <span className={gender == "" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("")}>
                      All
                    </span>
                  </div>

                  <div className="mb-3 " style={{ marginRight: "1rem" }}>
                    <span className={gender == "Female" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Female")}>
                      Female
                    </span>
                  </div>

                  <div className="mb-3 " style={{ marginRight: "1rem" }}>
                    <span className={gender == "Male" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Male")}>
                      Male
                    </span>
                  </div>

                  <div className="mb-3 " style={{ marginRight: "1rem" }}>
                    <span className={gender == "NonBinary" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("NonBinary")}>
                      Non Binary
                    </span>
                  </div>

                  <div className="mb-3">
                    <span className={gender == "Other" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Other")}>
                      Other
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Ethnicity:</div>

              <div className="col-xl-12 mb-3">
                <select
                  className="form-control"
                  onChange={e => {
                    setSelectedEthnicity(e.target.value);
                    setIsFilterUpdateOpen(true);
                  }}
                  defaultValue={selectedEthnicity}
                >
                  <option value="">Select Ethnicity</option>
                  {ethnicityList &&
                    ethnicityList.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.ethnicity}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Profession:</div>

              <div className="col-xl-12 mb-3">
                <select
                  className="form-control"
                  onChange={e => {
                    setSelectedProfession(e.target.value);
                    setIsFilterUpdateOpen(true);
                  }}
                  defaultValue={selectedProfession}
                >
                  <option value="">Select Profession</option>
                  {professionsDetails &&
                    professionsDetails.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="filter-option ">
              <div className="mb-3">Areas Of Specialization:</div>

              <Select
                options={searchExpTag}
                isMulti
                value={selectedExpTags}
                onChange={(newValue: any) => handlExpTags(newValue)}
                display="none"
                className="creatableEdit"
                placeholder="Choose an area"
                controlShouldRenderValue={false}
                isClearable={false}
              />

              <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2 mb-3">
                {selectedExpTags.map((tag, id) => {
                  return tag && <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
                })}
              </div>
            </div>

            <div className="filter-option  text-center">
              <button type="button" className="btn btn-primary mb-3 pt-2 pb-2 profile_watch_buttons" onClick={applyFilters}>
                Apply
              </button>
            </div>

            <div className="filter-option  text-center">
              {isFilterUpdateOpen && (
                <div className="flex">
                  <div className="mb-3 text-center" style={{ margin: "auto" }}>
                    Do you want to update current preferences?
                  </div>
                  <button type="button" className="btn btn-success btn-sm mb-3 mt-3" onClick={updateTherapistSearchPreferences}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      {modalcentervideo && (
        <div
          className="lightbox1"
          onClick={() => {
            setModalCenterVideo(false);
          }}
        >
          <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
            {therapistvideo.vimeoId ? (
              <>
                <div className="modal-body1">
                  <iframe
                    src={"https://player.vimeo.com/video/" + therapistvideo?.vimeoId}
                    width="800"
                    className="videoSize"
                    height="400"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Lavni Health"
                  ></iframe>
                </div>
              </>
            ) : (
              <>
                <div className="transcode-status-profile">
                  No video is added by {therapistvideo.firstname} {therapistvideo.lastname}{" "}
                  {!(therapistvideo.firstname && therapistvideo.lastname) && "Setected User"}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DiscoverProfessionals;
