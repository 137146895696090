const aes256 = require("aes256");
import { environment } from "src/environment/environment";

const key = environment.admin_client_chat_encryption_key;

export const encryptAdminClientMessage = (text: string) => {
  try {
    if (text != null && text != "" && text.trim() != "") {
      const encrypted = aes256.encrypt(key, text);
      return encrypted;
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const decryptAdminClientMessage = (cipher: string) => {
  try {
    if (cipher && cipher?.length > 0) {
      const decrypted = aes256.decrypt(key, cipher);
      return decrypted;
    }

    return cipher;
  } catch (error) {
    console.log("Error");
    console.log(error);

    return "";
  }
};
