import { ClientPreferences, Client } from "./Client";
import { Ethinicity } from "./Ethnicity";
import { ExperienceTag } from "./ExperienceTag";
import { Payment } from "./Payment";
import { Profession, ProfessionLicense } from "./Profession";
import { FriendRequest } from "./FriendRequest";
import { ReviewData } from "./Review";
import { Role } from "./Role";
import { Therapist } from "./Therapist";
import { Upload } from "./Upload";
import { WorkingHour } from "./WorkingHour";
import { Insurance } from "./Insurance";
import { InsuranceCompanyTags } from "./InsuranceCompany";
import { SaasModelType } from "./SaasModelType";

export enum UserRole {
  THERAPIST = "THERAPIST",
  CLIENT = "CLIENT",
  SUPER_ADMIN = "SUPER_ADMIN",
  SUB_ADMIN = "SUB_ADMIN",
}

export enum GenderTypes {
  ALL = "",
  MALE = "Male",
  FEMALE = "Female",
  NonBinary = "NonBinary",
  OTHER = "Other",
}

export enum ClaimStatus {
  ALL = "",
  SUCCESS = "ACTIVE",
  SUCCESSMD = "ACTIVEMD",
  PAID = "PAID",
  PAIDMD = "PAIDMD",
  UNPAID = "UNPAID",
  UNPAIDMD = "UNPAIDMD",
  FAILED = "INACTIVE",
  NOTSUBMITTED = "NOTSUBMITTED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_SUBMISSION = "PENDING_SUBMISSION",
}

export enum InsuranceActiveStatus {
  ALL = "",
  WITH_INSURANCE = "WITH_INSURANCE",
  WITHOUT_INSURANCE = "WITHOUT_INSURANCE"
}

export enum CopaymentStatus {
  ALL = "",
  EXISTING = "EXISTING",
  GREATER = "GREATER"
}

export enum SubscriptionActiveStatus {
  ALL = "",
  WITH_SUBSCRIPTION = "WITH_SUBSCRIPTION",
  WITHOUT_SUBSCRIPTION = "WITHOUT_SUBSCRIPTION"
}

export interface TherapistResponseModel {
  therapistSet: Therapist[];
  count: number;
}

export interface ClientResponseModel {
  clientSet: Client[];
  count: number;
}

export interface UserResponseModel {
  userSet: any[];
  count: number;
}

export interface UserCountResponseModel {
  clientCount: number;
  pendingClientCount: number;
  therapistCount: number;
}

export interface ClientCountResponseModel {
  premClientCount: number;
  revokedClientCount: number;
}

export enum UserStatus {
  ALL = "",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
}

export enum AdminPermissionEnum {
  statistics = "/statistics",
  adminDashboard = "/admin-dashboard",
  viewEthnicity = "/view-ethnicity",
  viewExperienceTags = "/view-experience-tags",
  viewInsuranceCompanies = "/view-insurance-companies",
  createAppointmentAdmin = "/create-appointment-admin",
  viewProfessions = "/view-professions",
  documents = "/documents",
  accessManagement = "/access-management",
  viewHashTags = "/view-hash-tags",
  viewThemeImage = "/view-theme-image",
  reportReviews = "/report-reviews",
  reviews = "/reviews",
  contactUs = "/contact-us",
  articles = "/articles",
  feedback = "/feedback",
  newsLetterEmails = "/newsletter-emails",
  marketingEmails = "/marketing-emails",
  viewMeetingsAndRecordings = "/view-meetings-and-recordings",
  viewAllClients = "/view-all-clients",
  manageClients = "/manage-clients",
  premiumClients = "/premium-clients",
  reminderSms = "/reminder-sms",
  viewAllTherapists = "/view-all-therapists",
  manageTherapists = "/manage-therapists",
  viewTherapistReviews = "/view-therapist-reviews",
  viewTherapistsSoapReviews = "/view-therapists-soap-reviews",
  educationalDetails = "/educational-details",
  licenseDetails = "/license-details",
  therapistRequests = "/therapist-requests",
  availableBalances = "/available-balances",
  adminApprovePayment = "/admin-approve-payment",
  referralEarnings = "/referral-earnings",
  clientRewards = "/client-rewards",
  notifications = "/notifications",
  sessionFeedback = "/session-feedback",
  approvalQueue = "/approval-queue",
  techTickets = "/tech-tickets",
  createEthnicity = "/create-ethnicity",
  createThemeImage = "/create-theme-image",
  createExpereinceTag = "/create-experience-tags",
  createInsuranceCompany = "/create-insurance-company",
  createProfession = "/create-profession",
  updateProfession = "/update-profession/:professionId",
  createHashTag = "/create-hash-tag",
  manageProfile = "/profile/:userId",
  profile = "/profile",
}

export interface User {
  _id?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  password?: string;
  role?: Role;
  username?: string;
  gender?: string;
  verificationCode?: string;
  verifiedStatus?: string;
  medium?: "EMAIL";
  photoId: Upload;
  socketId?: string;
  facebookId?: string;
  signedUpAs?: string;
  firebaseTokens?: string[];
  lastLoggedIn?: Date;
  activeStatus?: string;
  adminApproved?: boolean;
  friendRequests?: FriendRequest[];
  socialSecurity?: string;
  cAQH?: string;
  nPI1?: string;
  taxIdentification?: string;
  license?: string;
  issueDate?: string;
  expirationDate?: string;
  schoolName?: string;
  dateOfGraduation?: string;
  schoolStreetAddress?: string;
  schoolCity?: string;
  schoolState?: string;
  schoolZipCode?: string;
  taxonomyCode?: string;
  malpracticePolicy?: string;
  malpracticeExpirationDate?: string;
  middleInitials?: string;
  maritalStatus?: string;
  streetAddress?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipCode?: string | any;
  primaryPhone?: string;
  createdAt?: Date;
  homePhone?: string;
  workPhone?: string;
  voiceMail?: string;
  stripeCustomerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  testSubscriptionStatus?: string;
  stripeConnectedAccountId?: string;
  stripeConnectedLoginLink?: string;
  stripeChargesEnabled?: boolean;
  stripeDetailsSubmitted?: boolean;
  blockedByAdmin?: boolean;
  insuranceId?: Insurance;
  lavniTestAccount?: boolean;
  chatWordCount?: number;
  callRecordingAllowed?: boolean;
  timeZone?: string;
  googleCalendarAccess?: boolean;
  message?: string;
  priorityNumber?: number;
  conversationId?: string;
  primaryTherapist?: any;
  adminPermission?: AdminPermissions;
}

export interface UserDetails {
  _id?: string;
  userId?: string;
  password?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  description?: string;
  role?: Role;
  username?: string;
  incognito?: boolean;
  incognitoPopupShow?: boolean;
  avatarImage?: string;
  useDefaultAvatar?: boolean;
  token?: string;
  deletingVideoId?: string;
  reviews?: ReviewData[];
  deletingCoverPhotoId?: string;
  verificationCode?: string;
  verifiedStatus?: string;
  dateOfBirth?: Date;
  gender?: string;
  ethnicityId?: Ethinicity;
  profession?: Profession & string;
  professionLicense?: ProfessionLicense & string;
  yearsOfExperience?: number;
  workingHours?: WorkingHour[];
  experiencedIn?: ExperienceTag[];
  insuranceCompanies?: InsuranceCompanyTags[];
  likedTherapists?: Therapist[];
  medium?: "EMAIL";
  photoId?: Upload;
  deletingPhotoId?: string;
  coverPhotoId?: Upload;
  videoId?: Upload;
  socketId?: string;
  paymentDetails?: Payment[];
  facebookId?: string;
  signedUpAs?: string;
  firebaseTokens?: string[];
  lastLoggedIn?: Date;
  activeStatus?: string;
  adminApproved?: boolean;
  friendRequests?: FriendRequest[];
  preference?: ClientPreferences;
  guideComplete?: boolean;
  disclosureStatementId?: Upload;
  vimeoId?: string;
  socialSecurity?: string;
  cAQH?: string;
  nPI1?: string;
  taxIdentification?: string;
  license?: string;
  issueDate?: string;
  expirationDate?: string;
  schoolName?: string;
  dateOfGraduation?: string;
  schoolStreetAddress?: string;
  schoolCity?: string;
  schoolState?: string;
  schoolZipCode?: string;
  taxonomyCode?: string;
  malpracticePolicy?: string;
  malpracticeExpirationDate?: string;
  middleInitials?: string;
  maritalStatus?: string;
  streetAddress?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipCode?: string | any;
  primaryPhone?: string;
  homePhone?: string;
  workPhone?: string;
  voiceMail?: string;
  freeUser?: boolean;
  stripeCustomerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  testSubscriptionStatus?: string;
  stripeConnectedAccountId?: string;
  stripeConnectedLoginLink?: string;
  stripeChargesEnabled?: boolean;
  stripeDetailsSubmitted?: boolean;
  premiumStatus?: string;
  premiumMembershipDate?: Date;
  insuranceId?: Insurance;
  lavniTestAccount?: boolean;
  isPremiumUserNotificationRead?: boolean;
  chatWordCount?: any;
  avatarId?: string;
  callRecordingAllowed?: boolean;
  hideCallTimer?: boolean;
  timeZone?: string;
  googleCalendarAccess?: boolean;
  message?: string;
  reminderTime?: {
    day?: boolean;
    min30?: boolean;
    hour1?: boolean;
  };
  reminderType?: {
    email?: boolean;
    text?: boolean;
  };
  pinNumber?: string;
  caqhUserName?: string;
  caqhPassword?: string;
  medicaidUsername?: string;
  MedicaidPassword?: string;
  psychologyTodayUsername?: string;
  psychologyTodayPassword?: string;
  therapyState?: string[];
  signature?: any;
  payRateType?: PayRateType;
  adminPermission?: AdminPermissions;
  createdAt?: Date;
  isSignature?: boolean;
  assesmentSignature?: any;
  skip?: boolean;
  copaymentAmount?: number;
  primaryTherapist?: any;
  grantedAccessFileFolderPermission?: boolean;
  aiNotesType?:string;
  medicaidId?:string;
  saasModelType?: SaasModelType;
  isNewDashboard?: boolean;
}

export interface PasswordDetails {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface PinDetails {
  oldPinNumber: string;
  newPinNumber: string;
  confirmPinNumber: string;
  password: string;
}

export interface PayRateType {
  type: string;
  percentage?: number;
}

export interface AdminPermissions {
  statistics?: boolean;
  adminDashboard?: boolean;
  viewEthnicity?: boolean;
  viewExperienceTags?: boolean;
  viewInsuranceCompanies?: boolean;
  createAppointmentAdmin?: boolean;
  viewProfessions?: boolean;
  documents?: boolean;
  accessManagement?: boolean;
  viewHashTags?: boolean;
  viewThemeImage?: boolean;
  reportReviews?: boolean;
  reviews?: boolean;
  contactUs?: boolean;
  articles?: boolean;
  feedback?: boolean;
  newsLetterEmails?: boolean;
  marketingEmails?: boolean;
  viewMeetingsAndRecordings?: boolean;
  viewAllClients?: boolean;
  manageClients?: boolean;
  premiumClients?: boolean;
  reminderSms?: boolean;
  viewAllTherapists?: boolean;
  manageTherapists?: boolean;
  viewTherapistReviews?: boolean;
  viewTherapistsSoapReviews?: boolean;
  educationalDetails?: boolean;
  licenseDetails?: boolean;
  therapistRequests?: boolean;
  availableBalances?: boolean;
  adminApprovePayment?: boolean;
  referralEarnings?: boolean;
  clientRewards?: boolean;
  notifications?: boolean;
  sessionFeedback?: boolean;
  approvalQueue?: boolean;
  techTickets?: boolean;
}

export interface ApproveReject {
  userId?: string;
  status: boolean;
}

export interface ApproveRejectReview {
  reviewId?: string;
  status: string;
}

export interface UserDetails2 {
  userId?: string;
  _id?: string;
  password?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  description?: string;
  role?: Role;
  username?: string;
  incognito?: boolean;
  incognitoPopupShow?: boolean;
  token?: string;
  deletingVideoId?: string;
  deletingStatementId?: string;
  disclosureStatementId?: Upload;
  reviews?: ReviewData[];
  deletingCoverPhotoId?: string;
  verificationCode?: string;
  verifiedStatus?: string;
  dateOfBirth?: Date;
  gender?: string;
  ethnicityId?: Ethinicity;
  profession?: Profession & string;
  professionLicense?: ProfessionLicense & string;
  yearsOfExperience?: number;
  workingHours?: WorkingHour[];
  experiencedIn?: string[];
  insuranceCompanies?: string[];
  likedTherapists?: Therapist[];
  medium?: "EMAIL";
  photoId?: Upload;
  deletingPhotoId?: string;
  coverPhotoId?: Upload;
  videoId?: Upload;
  socketId?: string;
  paymentDetails?: Payment[];
  facebookId?: string;
  signedUpAs?: string;
  firebaseTokens?: string[];
  lastLoggedIn?: Date;
  activeStatus?: string;
  adminApproved?: boolean;
  friendRequests?: FriendRequest[];
  preference?: ClientPreferences;
  vimeoId?: string;
  socialSecurity?: string;
  cAQH?: string;
  nPI1?: string;
  taxIdentification?: string;
  license?: string;
  issueDate?: string;
  expirationDate?: string;
  schoolName?: string;
  dateOfGraduation?: string;
  schoolStreetAddress?: string;
  schoolCity?: string;
  schoolState?: string;
  schoolZipCode?: string;
  taxonomyCode?: string;
  malpracticePolicy?: string;
  malpracticeExpirationDate?: string;
  middleInitials?: string;
  maritalStatus?: string;
  streetAddress?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipCode?: string | any;
  primaryPhone?: string;
  homePhone?: string;
  workPhone?: string;
  voiceMail?: string;
  stripeCustomerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  testSubscriptionStatus?: string;
  stripeConnectedAccountId?: string;
  stripeConnectedLoginLink?: string;
  stripeChargesEnabled?: boolean;
  stripeDetailsSubmitted?: boolean;
  lavniTestAccount?: boolean;
  isPremiumUserNotificationRead?: boolean;
  timeZone?: string;
  googleCalendarAccess?: boolean;
  reminderTime?: {
    day?: boolean;
    min30?: boolean;
    hour1?: boolean;
  };
  reminderType?: {
    email?: boolean;
    text?: boolean;
  };
  caqhUserName?: string;
  caqhPassword?: string;
  medicaidUsername?: string;
  MedicaidPassword?: string;
  psychologyTodayUsername?: string;
  psychologyTodayPassword?: string;
  priorityNumber?: number;
  therapyState?: string[];
  medicaidId?:string;
}
