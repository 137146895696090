import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../../assets/css/admin.css";
import { ArticleService } from "../../services/ArticleService";
import { HashTag } from "../../models/HashTag";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CommonService } from "../../services/CommonService";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";

const ViewHashTags: React.FC = () => {
  const [hashTags, setHashTags] = useState<HashTag[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const LIMIT = 10;
  const [seeMore, setSeeMore] = useState(false);
  const [isOffset, setOffset] = useState<number>(0);

  useEffect(() => {
    getAllHashTag();
  }, []);

  const getAllHashTag = () => {
    setIsLoading(true)
    ArticleService.getAllHashTags(LIMIT, isOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setHashTags(res.data);
        setIsLoading(false)
      } else {
        setHashTags([]);
        setIsLoading(false);
        setSeeMore(false);
      }

    });
  };

  const seeMoreFc = () => {
    setIsLoading(true);
    const updatedOffset = isOffset + LIMIT;
    setOffset(updatedOffset);

    ArticleService.getAllHashTags(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setHashTags(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setSeeMore(false);
      }

    });
  }

  const deleteHashTag = (id: string | any) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        CommonService.deleteHashTag(id).then(res => {
          if (res.success) {
            getAllHashTag();
            toast.success("Hash Tag is Deleted!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Somthing went wrong!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };

  const updateHashTag = (hashTag: HashTag | undefined) => {
    if (hashTag) {
      Swal.fire({
        title: "Update Hash Tag",
        input: "text",
        inputValue: hashTag.name,
        inputAttributes: {
          autocapitalize: "off",
          placeholder: 'Update Hash Tag',
        },
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          if (result.value == "" || result.value == undefined || result.value == null) {
            Swal.fire({
              icon: "error",
              title: "Invalid value. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          } else {
            const data = {
              hashTagId: hashTag._id,
              updatedHashTag: result.value,
            };
            AdminService.updateHashTags(data).then(res => {
              if (res.success) {
                Swal.fire({
                  icon: "success",
                  title: res.message,
                  confirmButtonColor: "#FD7F00",
                });
                getAllHashTag();
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.error,
                  confirmButtonColor: "#FD7F00",
                });
              }
            });
          }
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"View Hash Tags"} breadcrumbItem={"View Hash Tags"} />
          <Row>
            <Col xl={12}>
              <NavLink
                to={"/create-hash-tag"}
                className="btn btn-primary relative mb-3"
                style={{
                  bottom: "0",
                  float: "right",
                }}
              >
                Create Hash Tag
              </NavLink>
            </Col>
          </Row>
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {!isLoading ? (
                    <div className="table-responsive">
                      {hashTags !== undefined ? (
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Hash Tags</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {hashTags &&
                              hashTags.map((hashTags, index) => (
                                <tr key={hashTags._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{hashTags.name}</td>
                                  <td>
                                    <button className="btn btn-success btn-sm mr-2" onClick={() => updateHashTag(hashTags)}>
                                      <i className="bx bx-edit"></i> Update
                                    </button>
                                    <button className="btn btn-danger btn-sm mr-2" onClick={() => deleteHashTag(hashTags._id)}>
                                      <i className="bx bx-trash"></i> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <div>No Hash Tags</div>
                      )}

                    </div>
                  ) : (
                    <Spinner />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xl="12 mt30 text-center">
                    {seeMore && (
                        <button className="btn btn-warning" onClick={() => seeMoreFc()}>
                        See More
                        </button>
                    )}
                    </Col>
                </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewHashTags;
