import React, { useEffect } from "react";
import { CardTitle, Col, Row, Modal, CardBody, Button } from "reactstrap";
import 'react-h5-audio-player/lib/styles.css';

const Modal27: React.FC<{
  therapistName: string;
  therapistStateList: any[];
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  updateClientState: (state: string) => void;
}> = props => {
  const [selectedState, setSelectedState] = React.useState("");

  useEffect(() => {
    if(props.therapistStateList?.length > 0) {
      setSelectedState(props.therapistStateList[0]);
    }
  }, []);

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered className="modal-md">
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>

          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-question-circle text-primary"></i>
            </div>

            <CardTitle className="mb-4 text-center font-size-20">Please select a state</CardTitle>

            <Row>
              <Col xl="12">
                <h5 className="text-center">
                  {props.therapistStateList.length != 0 ? props.therapistName + " is available only on following states." :
                    <>
                      {props.therapistName + " hasn't selected a state."}

                      <br/>
                    </>}
                </h5>
              </Col>

              <Col xl="2"></Col>

              <Col xl="8" className="text-center">
                {
                  props.therapistStateList.length != 0 &&
                  <>
                    <br/>

                    <select onChange={e => setSelectedState(e.target.value)} className="form-control">
                      {props.therapistStateList.map((state, index) => (
                        <option value={state} key={index}>{state}</option>
                      ))}
                    </select>
                  </>
                }
              </Col>

              <Col xl="2"></Col>
            </Row>

            {
              props.therapistStateList.length != 0 &&
              <Col xl="12" className="text-center">
                <Button
                    className="pay-btn btn btn-info"
                    variant="contained"
                    color="success"
                    onClick={() => props.updateClientState(selectedState)}
                >
                    Proceed
                </Button>
              </Col>
            }
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal27;