import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreIcon from "@material-ui/icons/More";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import HouseIcon from "@material-ui/icons/House";
import styles from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import useCopyMeetingUrl from "../../hooks/useCopyMeetingUrl";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";
import UserContext from "../../../../../../context/UserContext";
import LocalCallContext from "../../../../../../context/LocalCallContext";

import React from "react";
const MeetingInfo = ({ roomId, onOpenDiagnosisNote, onOpenClinicalAssessment }) => {
  const { copyUrl } = useCopyMeetingUrl();
  const [title, setTitle] = React.useState("Copy");
  const [loggedUser] = React.useContext(UserContext);
  const [localUserOnCall, setLocalUserOnCall] = React.useContext(LocalCallContext);

  const ListItemLink = props => {
    return <ListItem button component="a" {...props} />;
  };
  const titleToolTip = "Meeting Info";

  const handleClick = () => {
    setTitle("Copied");
    copyUrl();
  };

  const handleClose = () => {
    setTimeout(() => {
      setTitle("Copy");
    }, 500);
  };
  const localClasses = styles();

  const toggleTreatmentCanvas = () => {
    onOpenDiagnosisNote(true);
  };

  const clinicalAssessment = () => {
    onOpenClinicalAssessment(true);
  };

  return (
    <List className={localClasses.list}>
      <div className={localClasses.container}>
        <Typography className={localClasses.header} variant="h5">
          Joining info
        </Typography>
        <ListItem>{window.location.href}</ListItem>
        <Tooltip title={title} onClose={handleClose} aria-label="add">
          <Button onClick={handleClick} variant="contained" color="primary" className={localClasses.button} endIcon={<FileCopyIcon>send</FileCopyIcon>}>
            Copy URL
          </Button>
        </Tooltip>

        <Divider />
        <Typography className={localClasses.header} variant="h5">
          App info
        </Typography>
        <ListItem>
          <ListItemIcon>
            <MoreIcon variant="contained" color="primary" />
          </ListItemIcon>
          <ListItemText
            className={localClasses.versionLabel}
            // primary={`Version ` + process.env.REACT_APP_VERSION}
            primary={`Lavni Inc`}
          />
        </ListItem>
        <Divider />
        <Typography className={localClasses.header} variant="h5">
          Session info
        </Typography>

        <ListItem>
          <ListItemText className={localClasses.sessionLabel} secondary={`Session Id: ` + roomId} />
        </ListItem>
        {loggedUser?.role == "THERAPIST" && localUserOnCall?.recieversUserId && (
          <Tooltip title={"Clinical Assessment"} aria-label="add">
            <Button
              onClick={clinicalAssessment}
              variant="contained"
              color="primary"
              className={localClasses.button}
            >
              Clinical Assessment
            </Button>
          </Tooltip>
        )}
      </div>
    </List>
  );
};
MeetingInfo.propTypes = {
  roomId: PropTypes.string.isRequired, // Adjust the type accordingly
  onOpenDiagnosisNote: PropTypes.func,
  onOpenClinicalAssessment: PropTypes.func,
};
export default MeetingInfo;
