import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styles from "./styles.js";
import useRoom from "../../hooks/useRoom";
import { UserContextForVonage } from "../../context/UserContextForVonage";

import ToolBar from "../ToolBar/index";

import NetworkToast from "../NetworkToast/index";
import useScreenSharing from "../../hooks/useScreenSharing";
import { VonageServices } from "../../../vonageServices";
import UserContext from "src/context/UserContext";
import WaitingToast from "../WaitingToast/index.js";

export default function VideoRoom() {
  const [user, setUser] = useContext(UserContextForVonage);
  const [credentials, setCredentials] = useState(null);
  const [error, setError] = useState(null);
  const { createCall, room, participants, connected, networkStatus, cameraPublishing, localParticipant } = useRoom();
  const { isScreenSharing, startScreenSharing, stopScreenSharing } = useScreenSharing({ room });
  const roomContainer = useRef();
  const classes = styles();
  const { roomName } = useParams();
  const [loggedUser] = useContext(UserContext);
  const [showWaitingForOthersMsg, setShowWaitingForOthersMsg] = useState(true);


  useEffect(() => {
    const disableBackButton = (event) => {
      event.preventDefault();
      window.history.forward();
    };

    // Disable the back button
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);

  useEffect(() => {
    getVonageCredentials();
  }, []);

  const getVonageCredentials = async () => {
    try {
      const sessionData = await VonageServices.createVonageSession(roomName);
      console.log(sessionData);
      if (sessionData) {
        setCredentials({
          apikey: sessionData.apiKey,
          sessionId: sessionData.sessionId,
          token: sessionData?.token,
        });
        await VonageServices.updateSessionIdInMeeting(roomName, sessionData.sessionId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    if(participants.length > 0 ) {
      if(showWaitingForOthersMsg){
        setShowWaitingForOthersMsg(false);
      }
    } else {
      setShowWaitingForOthersMsg(true);
    }
  },[participants])

  useEffect(() => {
    try {
      console.log("VR 02");
      if (credentials) {
        createCall(credentials, roomContainer.current, user.userName, user.videoFilter, {
          ...user.defaultSettings,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [createCall, credentials, user]);

  if (error) return <div className={classes.errorContainer}>There was an error fetching the data from the server</div>;

  return (
    <div id="callContainer" className={classes.callContainer}>
      <div id="roomContainer" className={classes.roomContainer} ref={roomContainer}>
        {/* {showWaitingForOthersMsg && <h5 className={classes.waitingText}>Waiting for other party to join...</h5>} */}
        <NetworkToast networkStatus={networkStatus} />
        {showWaitingForOthersMsg && <WaitingToast/>}
        <div id="screenSharingContainer" className={classes.screenSharingContainer}>
          {isScreenSharing && <div className={classes.screenSharingOverlay}>You Are Screensharing</div>}
        </div>
      </div>
      <ToolBar
        room={room}
        participants={participants}
        localParticipant={localParticipant}
        connected={connected}
        cameraPublishing={cameraPublishing}
        isScreenSharing={isScreenSharing}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
      ></ToolBar>
    </div>
  );
}
