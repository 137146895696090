import React, { useEffect, useState } from "react";
const classNames = require('classnames');
import { Row, Col, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import ChildTab from "../ChildTabs/ChildTab";
import { Phrase, TabTypes } from "../../../models/Notes";
import { NoteService } from "../../../services/NoteService";
import Spinner from "../../../common/spinner/spinner";
import { truncate } from "lodash";

interface PlanTabProps {
  stage: string;
  activeVerticalTab: string;
  toggleVertical: (value: string) => void;
  carePlan: string;
  procedureNotes: string;
  element: string;
  setCarePlan: (value: string) => void;
  setProcedureNotes: (value: string) => void;
}

const PlanTab: React.FC<PlanTabProps> = ({
  stage,
  activeVerticalTab,
  carePlan,
  procedureNotes,
  element,
  toggleVertical,
  setCarePlan,
  setProcedureNotes,
}: PlanTabProps) => {
  const [supportivePhrases, setSupportivePhrases] = useState<Phrase[]>([]);
  const [cognitivePhrases, setCognitivePhrases] = useState<Phrase[]>([]);
  const [psychoanalyticPhrases, setPsychoanalyticPhrases] = useState<Phrase[]>([]);
  const [otherPhrases, setOtherPhrases] = useState<Phrase[]>([]);
  const [planPhrases, setPlanPhrases] = useState<Phrase[]>([]);
  const [isLoded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    NoteService.getAllPhrases(stage).then(res => {
      if (res.success) {
        setSupportivePhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.SUPPORTIVE));
        setCognitivePhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.COGNITIVE_BEHAVIORAL));
        setPsychoanalyticPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.PSYCHOANALYTIC));
        setOtherPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.OTHER_INTERVENTIONS));
        setPlanPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.ONGOING_PLAN));
        setIsLoaded(true);
      } else {
        setSupportivePhrases([]);
        setCognitivePhrases([]);
        setPsychoanalyticPhrases([]);
        setPlanPhrases([]);
        setTimeout(() => {
          setIsLoaded(true);
        }, 500);
      }
    });
  }, [stage]);

  return (
    <>
      {!isLoded && <Spinner className="bouncer" />}
      <Row>
        <Col lg={3}>
          <Nav pills className="flex-column">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "1",
                })}
                onClick={() => {
                  toggleVertical("1");
                }}
                to={""}
              >
                Supportive
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "2",
                })}
                onClick={() => {
                  toggleVertical("2");
                }}
                to={""}
              >
                Cognitive Behavioral
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "3",
                })}
                onClick={() => {
                  toggleVertical("3");
                }}
                to={""}
              >
                Psychoanalytic / Psychodynamic
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "4",
                })}
                onClick={() => {
                  toggleVertical("4");
                }}
                to={""}
              >
                Other Interventions
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "5",
                })}
                onClick={() => {
                  toggleVertical("5");
                }}
                to={""}
              >
                Ongoing Plan
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col lg={9}>
          <TabContent activeTab={activeVerticalTab} className="text-muted">
            {supportivePhrases && supportivePhrases.length > 0 && (
              <ChildTab
                tabId={"1"}
                states={supportivePhrases}
                element={element}
                tabType={TabTypes.SUPPORTIVE}
                message={"The main therapeutic interventions consisted of:"}
                carePlan={carePlan}
                setCarePlan={setCarePlan}
                procedureNotes={procedureNotes}
                setProcedureNotes={setProcedureNotes}
              />
            )}

            {cognitivePhrases && cognitivePhrases.length > 0 && (
              <ChildTab
                tabId={"2"}
                states={cognitivePhrases}
                element={element}
                tabType={TabTypes.SUPPORTIVE}
                message={"The main therapeutic interventions consisted of:"}
                carePlan={carePlan}
                setCarePlan={setCarePlan}
                procedureNotes={procedureNotes}
                setProcedureNotes={setProcedureNotes}
              />
            )}

            {psychoanalyticPhrases && psychoanalyticPhrases.length > 0 && (
              <ChildTab
                tabId={"3"}
                states={psychoanalyticPhrases}
                element={element}
                tabType={TabTypes.PSYCHOANALYTIC}
                message={"The main therapeutic interventions consisted of:"}
                carePlan={carePlan}
                setCarePlan={setCarePlan}
                procedureNotes={procedureNotes}
                setProcedureNotes={setProcedureNotes}
              />
            )}

            {otherPhrases && otherPhrases.length > 0 && (
              <ChildTab
                tabId={"4"}
                states={otherPhrases}
                element={element}
                tabType={TabTypes.OTHER_INTERVENTIONS}
                message={"The main therapeutic interventions consisted of:"}
                carePlan={carePlan}
                setCarePlan={setCarePlan}
                procedureNotes={procedureNotes}
                setProcedureNotes={setProcedureNotes}
              />
            )}

            {planPhrases && planPhrases.length > 0 && (
              <ChildTab
                tabId={"5"}
                states={planPhrases}
                element={element}
                tabType={TabTypes.ONGOING_PLAN}
                message={"The ongoing treatment plan includes:"}
                carePlan={carePlan}
                setCarePlan={setCarePlan}
                procedureNotes={procedureNotes}
                setProcedureNotes={setProcedureNotes}
              />
            )}
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default PlanTab;
