import React, { useEffect, useState } from "react";
import { AdminService } from "src/services/AdminService";
import ReviewContext from "src/context/ReviewContext";

const ReviewUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadReviewsCount, setUnreadReviewsCount] = useState<number>(0);
  const [offset] = useState(0);

  const LIMIT = 0;

  useEffect(() => {
    AdminService.getAllLavniPendingReviews(LIMIT, offset).then(res => {
      if (res.success) {
        setUnreadReviewsCount(res.data.length)
      }
    });
  }, []);

  return <ReviewContext.Provider value={[unreadReviewsCount, setUnreadReviewsCount]}>{children}</ReviewContext.Provider>;
};

export default ReviewUtils;