import React, { useEffect, useState } from "react";
import { AdminService } from "src/services/AdminService";
import ContactContext from "src/context/UserContactRequest";

const ContactUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadContactCount, setUnreadContactCount] = useState<number>(0);
  const [offset] = useState(0);

  const LIMIT = 0;

  useEffect(() => {
    AdminService.getAllContactUsRequests(LIMIT, offset).then(res => {
      if (res.success) {
        setUnreadContactCount(res.data.count)
      }
    });
  }, []);

  return <ContactContext.Provider value={[unreadContactCount, setUnreadContactCount]}>{children}</ContactContext.Provider>;
};

export default ContactUtils;