import React, { useContext, useState } from "react";
import { CardTitle, Col, Row, Modal, CardBody, CardFooter } from "reactstrap";
import UserContext from "../../context/UserContext";
import { UserRole } from "../../models/User";
import group1 from "../../assets/images/group-1.svg";
import group2 from "../../assets/images/group-2.svg";
import Modal19 from "./Modal19";
import Modal20 from "./Modal20";

const Modal22: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void }> = props => {
  const [user] = useContext(UserContext);
  const [showModal19, setShowModal19] = useState(false);
  const [showModal20, setShowModal20] = useState(false);
  const [showMainModal, setShowMainModal] = useState(true);

  const outOfPocket = () => {
    setShowMainModal(false);
    setShowModal19(true);
  };

  const provider = () => {
    setShowMainModal(false);
    setShowModal20(true);
  };

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} size="lg" centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          {showMainModal &&
            <CardBody>
              {
                user?.role == "CLIENT" ? (
                  <>
                    <CardTitle className="mb-4 text-center font-size-18">How would you like to pay for the appointment?</CardTitle>

                    <div className="contact-links d-flex font-size-20">
                      <div className="flex-fill">
                        <img src={group1} alt="" className="pb-4  block groupIcon justify-content-center m-auto" />
                        <button className="btn btn-primary btn-sm block justify-content-center m-auto"
                          onClick={() => outOfPocket()}
                        >
                          <span className="font-size-12"><span className="icon_text_s1">Out of Pocket</span></span>

                        </button>
                      </div>
                      
                      <div className="flex-fill">
                        <img src={group2} alt="" className="pb-4  block groupIcon justify-content-center m-auto" />
                        <button className="btn btn-dark-blue btn-sm block justify-content-center m-auto"
                              onClick={() => provider()}>
                          <span className="font-size-12"><span className="icon_text_s1">My Insurance Provider</span></span>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <CardTitle className="mb-4 text-center font-size-20">This client doesn&lsquo;t have an active subscription.</CardTitle>
                )
              }

            </CardBody>
          }

          {showModal19 && <Modal19 setShowModal19={setShowModal19} showModal19={showModal19} />}
          {showModal20 && <Modal20 setShowModal20={setShowModal20} showModal20={showModal20} />}
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal22;
