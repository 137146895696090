import React, { useEffect } from "react";
import { CardTitle, Col, Row, Modal, CardBody, Button } from "reactstrap";
import 'react-h5-audio-player/lib/styles.css';
import TherapistCard from "../Chat/DiagnosisNote/TherapistCard";
import { TreatmentHistory } from "src/models/TreatmentHistory";

const NoteShareTherapistModal: React.FC<{
  noteData?: any;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onShare:  (value: TreatmentHistory) => void;
}> = props => {
  

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered className="modal-md">
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          

          <CardBody>
            <TherapistCard noteData={props.noteData} onShare={props.onShare}/>

          
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default NoteShareTherapistModal;