import React, { useState } from "react";
import { Modal, ModalBody, Container, Card, CardBody, Row, Col, Input, Button } from "reactstrap";

const SessionFeedbackModal = (props: any) => {
  const [rating, setRating] = useState(5);
  const [feedback, setFeedback] = useState("");
  const handleRating = (newRating: any) => {
    setRating(newRating);
  };
  const handleFeedbackChange = (e: any) => {
    setFeedback(e.target.value);
  };
  const submitFeedback = () => {
    props.toggle(rating, feedback, false);
  };
  return (
    <Modal isOpen={props.isOpen} centered className="main-model">
          <button type="button" onClick={() => {
            props.toggle(rating, feedback, true)
          }} style={{fontSize: '12px',width: '20px',height: '20px',padding: '2px',margin: '2px'}} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
        </button>
      <ModalBody style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}>
        <Container fluid>
          <Card style={{ minHeight: "30vh" }}>
            <CardBody>
              <Row>
                <div className="card-heading mb-2">
                  <h5 className="font-size-17 profileFont mb-4">Please Rate Your Session Today!</h5>
                </div>
              </Row>
              <Row className="mb-4">
                <Col>
                  <div className="rating-stars">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <i
                        key={star}
                        className={`fa fa-star`}
                        aria-hidden="true"
                        style={{
                          color: star <= rating ? "#F4C542" : "#D3D3D3",
                          cursor: "pointer",
                          fontSize: "24px",
                        }}
                        onClick={() => handleRating(star)}
                      ></i>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <Input
                    type="textarea"
                    placeholder="Please Leave Any Additional Feedback About Your Session Here!"
                    value={feedback}
                    onChange={handleFeedbackChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button color="primary" onClick={submitFeedback}>
                    Submit Feedback
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </ModalBody>
    </Modal>
  );
};
export default SessionFeedbackModal;