import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { VideoChatService } from "src/services/VideoChatService";
import { toast } from "react-toastify";
import { Card, Col, CardBody, Modal, Input, Row, Container } from "reactstrap";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import TimePicker from "react-time-picker";
import "./AudioRecorder2.scss";
import moment from "moment";
import MicRecorder from "mic-recorder-to-mp3";
import CustomTimePicker2 from "src/components/CustomTimePicker2";
import { TherapistService } from "./../../services/TherapistService";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const AddNewSessionTwilioCallModal: React.FC<{
  addNewSessionPopUp: boolean;
  userId: string;
  userName: string;
  setAddNewSessionPopUp: (value: boolean) => void;
  callStartTime: Date;
}> = props => {
  const [createDate, setCreateDate] = useState(new Date().toString());
  const userId = props.userId;
  const chatBoxUsername = props.userName;

  const clickCloseAddNewSessionPopUp = () => {
    props.setAddNewSessionPopUp(false);

    // setSelectedSpendDuration(0);
    // setSelectedStartTime(null);
  };

  const addNewSession = async () => {
    const data = {
      recieverId: userId,
      callDuration: 60,
      spendDuration: 60,
      regularMeetingStartTime: moment(props.callStartTime).format("hh:mm A"),
      regularMeetingDate: new Date().toString(),
      audioAvailable: false,
      meetingType: "Twilio"
    };
    const res = await TherapistService.createRegularMeetingForTWilio(data);

    if (res.success) {
      toast.success("Regular meeting added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar ",
      });
      props.setAddNewSessionPopUp(false);
    }
  };

  return props.addNewSessionPopUp ? (
    <Modal isOpen={props.addNewSessionPopUp} centered className="main-model">
      <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}>
        <Container fluid>
          <Card style={{ minHeight: "57vh" }}>
            <CardBody>
              <Row>
                <div className="card-heading mb-2">
                  <h5 className="font-size-17 profileFont mb-4">Session Information</h5>
                </div>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Client Name<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    <Input type="text" id="readOnlyField" readOnly value={chatBoxUsername} />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Date<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    <Input value={createDate} disabled={true} />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col xl="6">
                  <div className="card-heading mb-2 add-session-popup">
                    <h5 className="font-size-14 profileFont mb-2">
                      Start Time<span style={{ color: "#990000" }}>*</span>
                    </h5>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="card-heading mb-2 flex">
                    <h5>{moment(props.callStartTime).format("MMMM Do YYYY, h:mm:ss a")}</h5>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col xs="6" className="mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    onClick={() => {
                      addNewSession();
                    }}
                  >
                    Add new session
                  </button>
                </Col>
                <Col xs="6" className="mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      clickCloseAddNewSessionPopUp();
                    }}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </Modal>
  ) : null;
};

export default AddNewSessionTwilioCallModal;
