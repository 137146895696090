import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";

const MessageModal: React.FC<{ message: string; showModal: boolean; setShowModal: (value: boolean) => void }> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">{props.message}</CardTitle>
            <div className="flex justify-content-center">
              <button type="button" onClick={() => props.setShowModal(false)} className="btn btn-primary">
                Close
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default MessageModal;
