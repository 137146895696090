import React, { useEffect, useState } from "react";
import { Modal, OffcanvasHeader } from "reactstrap";
import DiagnosisNoteScreen from "../Chat/DiagnosisNote/DiagnosisNoteView";
import Close from "../../assets/images/icons/u_times-circle.png";

const SessionHistoryModalForAdmin: React.FC<{
  showModal: boolean;
  userId: any;
  setShowModal: () => void;
  isVersion: boolean;
}> = props => {
  const [showLaz, updateDiagnosisData] = useState(true);

  return props.showModal ? (
    <>
      <Modal
        isOpen={props.showModal}
        centered
        fullscreen
        scrollable
        style={{
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div
          className="modal-head"
          style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}
        >
          <div className="diagnosis-header">
            <h5 className="mb-0 font-size-15">Diagnosis Note</h5>
            <button
              type="button"
              onClick={() => props.setShowModal()}
              className="bg-transparent-color"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={Close} alt="Close" className="close-icon-d" />
            </button>
          </div>
        </div>
        <div
          className="scrollbar-container  ps--active-y"
          style={{
            height: "89vh",
            overflowY: "auto",
            padding: "20px",
          }}
        >
          {/* <OffcanvasHeader toggle={() => props.setShowModal()}>{""}</OffcanvasHeader> */}

          <DiagnosisNoteScreen diagnosisId={props.userId} totalSpentDuration={0} updateDiagnosisData={updateDiagnosisData} isVersion={props.isVersion}></DiagnosisNoteScreen>

          <div></div>
        </div>
      </Modal>
    </>
  ) : null;
};

export default SessionHistoryModalForAdmin;
