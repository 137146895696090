import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./loading-layer.scss";

const LoadingLayer = (props: { content: any }) => {
  const { content } = props;
  const reload = () => window.location.reload();
  return (
    <div className="loading-layer">
      <LoadingOutlined style={{ fontSize: "86px", color: "#fff" }} />
      <p className="loading-text text-center">{content}</p>
      {content == "Network connection lost, Please reload to connect!" && (
        <button type="button" className="btn btn-primary mb-3 pt-2 pb-2" onClick={reload}>
          Reload
        </button>
      )}
    </div>
  );
};

export default LoadingLayer;
