import React, { useContext, useEffect } from "react";
import UserContext from "../context/UserContext";

interface VerifyNewDashboardProps {
  children: any;
  onAccessChange: (hasAccess: boolean) => void; // Define the function prop
}

const VerifyNewDashboard: React.FC<VerifyNewDashboardProps> = ({ children, onAccessChange }): any => {
  const [user] = useContext(UserContext);
  const isNewDashboard = user && (user?.isNewDashboard ?? true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const currentUser = parseJwt(token);
      if (currentUser && currentUser.exp * 1000 < Date.now()) {
        logout();
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isNewDashboard) {
      onAccessChange(true);
    } else {
      onAccessChange(false);
    }
  }, [])

  const parseJwt = (token: string | null) => {
    if (token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  return isNewDashboard ? <>{children}</> : null;
};

export default VerifyNewDashboard;