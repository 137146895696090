import { HomeWork } from "../models/HomeWork";
import { AppResponse } from "../models/Response";
import { Util } from '../Util';
import axios from 'axios';
import { UploadDocument } from "../models/UploadDocument";

export class HomeworkService {
    public static async getAllHomeworksByTherapist(
        userId: string,
        limit?: number,
        offset?: number): Promise<AppResponse<HomeWork[]>> {
        const url = Util.apiAuthUrl('allHomeworksByTherapist/' + userId + "/" + limit + "/" + offset);
        return await axios.get<void, AppResponse<HomeWork[]>>(url);
    }

    public static async getAllHomeworksByClient(
        userId: string,
        limit?: number,
        offset?: number
    ): Promise<AppResponse<HomeWork[]>> {
        const url = Util.apiAuthUrl('allHomeworksByClient/' + userId + "/" + limit + "/" + offset);
        return await axios.get<void, AppResponse<HomeWork[]>>(url);
    }

    public static async getHomeworkById(hId: string): Promise<AppResponse<HomeWork>> {
        const url = Util.apiAuthUrl("homeworkBy/" + hId);
        return await axios.get<Partial<HomeWork>, AppResponse<HomeWork>>(url);
    }

    public static async deleteHomework(hId: string): Promise<AppResponse<HomeWork>> {
        const url = Util.apiAuthUrl("deleteHomeworkBy/" + hId);
        return await axios.delete<Partial<HomeWork>, AppResponse<HomeWork>>(url);
    }

    public static async createHomework(data: Partial<HomeWork>, fileToUpload: any): Promise<AppResponse<HomeWork>> {
        const url = Util.apiAuthUrl('createHomework');
        const formData: FormData = new FormData();
        formData.append("homeworkDetails", JSON.stringify(data) as any);
        for (const file of fileToUpload) {
            formData.append("uploads", file);
        }
        return await axios.post<FormData, AppResponse<HomeWork>>(url, formData);
    }

    public static async updateHomework(
        data: Partial<HomeWork>,
        fileToUpload: any
    ): Promise<AppResponse<HomeWork>> {
        const url = Util.apiAuthUrl("updateHomework");
        const formData: FormData = new FormData();
        formData.append("homeworkDetails", JSON.stringify(data) as any);

        for (const file of fileToUpload) {
            formData.append("uploads", file);
        }

        return await axios.post<FormData, AppResponse<HomeWork>>(url, formData);
    }

    public static async createDocument(data: Partial<UploadDocument>, fileToUpload: any): Promise<AppResponse<UploadDocument>> {
        const url = Util.apiAuthUrl('upload_documents');
        const formData: FormData = new FormData();
        formData.append("clientDetails", JSON.stringify(data) as any);
        for (const file of fileToUpload) {
            formData.append("uploadDocumennt", file);
        }
        return await axios.post<FormData, AppResponse<UploadDocument>>(url, formData);
    }

    public static async getAllDocumentsByTherapist(
        userId: string,
        limit?: number,
        offset?: number): Promise<AppResponse<UploadDocument[]>> {
        const url = Util.apiAuthUrl('upload_documents_by_therapists/' + userId + "/" + limit + "/" + offset);
        return await axios.get<void, AppResponse<UploadDocument[]>>(url);
    }

    public static async getAllDocumentsByClients(
        userId: string,
        limit?: number,
        offset?: number
    ): Promise<AppResponse<UploadDocument[]>> {
        const url = Util.apiAuthUrl('upload_documents_by_clients/' + userId + "/" + limit + "/" + offset);
        return await axios.get<void, AppResponse<UploadDocument[]>>(url);
    }

}