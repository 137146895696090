import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

export default function EndCallIcon({ classes, handleEndCall, screenWidth }) {
  return (
    <>
      {

        screenWidth < 530 ?
          <Tooltip title="End call" aria-label="add">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="videoCamera"
              className={classes.toolbarButtonsSm}
              style={{ backgroundColor: '#FF0000' }}
              onClick={handleEndCall}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          :
          <Tooltip title="End call" aria-label="add">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="videoCamera"
              className={classes.toolbarButtons}
              style={{ backgroundColor: '#FF0000' }}
              onClick={handleEndCall}
            >
              <span style={{ fontSize: '14px', fontWeight:'bold' }}>End Call</span>
            </IconButton>
          </Tooltip>
      }
    </>

  );
}

EndCallIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  handleEndCall: PropTypes.func.isRequired,
  screenWidth: PropTypes.any,
};
