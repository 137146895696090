
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../assets/css/admin.css";
const classNames = require('classnames');
import { Client } from "../../models/Client";
import { AdminService } from "../../services/AdminService";
import { ApproveReject } from "../../models/User";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Spinner from "../../common/spinner/spinner";

const ManageClients: React.FC = () => {
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [pendingClients, setPendingClients] = useState([] as Client[]);
    const [approvedClients, setApprovedClients] = useState([] as Client[]);
    const [isLoading, setIsLoading] = useState(true);

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const LIMIT_P_C = 10;
    const LIMIT_A_C = 10;

    const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
    const [seeMore_p_c, setSeeMore_p_c] = useState(false);

    const [isOffset_a_c, setOffset_a_c] = useState<number>(0);
    const [seeMore_a_c, setSeeMore_a_c] = useState(false);

    useEffect(() => {
        AdminService.getAllPendingClients(LIMIT_P_C, isOffset_p_c).then(res => {
            if (res.success) {
                setPendingClients(res.data);
                setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
            } else {
                setSeeMore_p_c(false);
            }
        });

        AdminService.getAllApprovedClients(LIMIT_A_C, isOffset_a_c).then(res => {
            if (res.success) {
                setApprovedClients(res.data);
                setSeeMore_a_c(res.data.length > 0 && res.data.length == LIMIT_A_C);
            } else {
                setSeeMore_a_c(false);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const approveRejectClient = (e: any) => {
        const clientId = e.currentTarget.getAttribute("data-value1");
        const status = e.currentTarget.getAttribute("data-value2");

        if (status == "true") {
            Swal.fire({
                icon: "info",
                title: "Are you sure want to approve this client?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#50a5f1",
                cancelButtonText: "No",
                cancelButtonColor: "#f46a6a",
            }).then(result => {
                if (result.isConfirmed) {
                    const data: ApproveReject = {
                        userId: clientId,
                        status: true
                    }

                    AdminService.approveRejectClient(data).then(res => {
                        const approvedClient = pendingClients.filter((c) => c._id === clientId)[0];

                        setApprovedClients(approvedClients => [...approvedClients, approvedClient]);

                        const updatedPendingClientList = pendingClients.filter((c) => c._id !== clientId);

                        setPendingClients(updatedPendingClientList);
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "warning",
                title: "Are you sure want to remove this client?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#50a5f1",
                cancelButtonText: "No",
                cancelButtonColor: "#f46a6a",
            }).then(result => {
                if (result.isConfirmed) {
                    const data: ApproveReject = {
                        userId: clientId,
                        status: false
                    }

                    AdminService.approveRejectClient(data).then(res => {
                        const removedClient = approvedClients.filter((c) => c._id === clientId)[0];

                        setPendingClients(pendingClients => [...pendingClients, removedClient]);

                        const updatedApprovedClientList = approvedClients.filter((c) => c._id !== clientId);

                        setApprovedClients(updatedApprovedClientList);
                    });
                }
            });
        }
    }

    const approvedSeeMore = () => {
        const updatedSkip = isOffset_a_c + LIMIT_A_C;
        setOffset_a_c(updatedSkip);

        AdminService.getAllApprovedClients(LIMIT_A_C, updatedSkip).then(res => {
            if (res.success) {
                setApprovedClients(prev => {
                    return [...new Set([...prev, ...res.data])];
                  });
                setSeeMore_a_c(res.data.length > 0 && res.data.length == LIMIT_A_C);
            } else {
                setSeeMore_a_c(false);
            }
        });
    }

    const pendingSeeMore = () => {
        const updatedSkip = isOffset_p_c + LIMIT_P_C;
        setOffset_p_c(updatedSkip);

        AdminService.getAllPendingClients(LIMIT_P_C, updatedSkip).then(res => {
            if (res.success) {
                setPendingClients(prev => {
                    return [...new Set([...prev, ...res.data])];
                  });
                setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
            } else {
                setSeeMore_p_c(false);
            }
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Manage Clients"} breadcrumbItem={"Manage Clients"} />

                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl="12">
                                    <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-view-grid-outline"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Pending Clients</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-format-list-bulleted"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Approved Clients</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <div className="table-responsive">
                                    {isLoading ? (
                                            <Spinner />
                                        ) : (
                                        <TabContent activeTab={customActiveTab} className="p-3 text-muted" >
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                pendingClients.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                <th scope="col">Profile</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                pendingClients && pendingClients.map((client, index) => (
                                                                                    <tr key={client._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{client.firstname} {client.lastname}</td>
                                                                                        <td>{client.email}</td>
                                                                                        <td>{client.primaryPhone}</td>
                                                                                        <td>
                                                                                            <Link to={"/profile/" + client._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td>
                                                                                        <td><span className="btn btn-warning status">Pending</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-success" onClick={approveRejectClient} data-value1={client._id} data-value2={true}>Approve</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Pending Clients</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_p_c && (
                                                            <button className="btn btn-warning" onClick={() => pendingSeeMore()}>
                                                            See More
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>

                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                approvedClients.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                <th scope="col">Profile</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                approvedClients && approvedClients.map((client, index) => (
                                                                                    <tr key={client._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{client.firstname} {client.lastname}</td>
                                                                                        <td>{client.email}</td>
                                                                                        <td>{client.primaryPhone}</td>
                                                                                        <td>
                                                                                            <Link to={"/profile/" + client._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td>
                                                                                        <td><span className="btn btn-success status">Approved</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-danger" onClick={approveRejectClient} data-value1={client._id} data-value2={false}>Reject</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Approved Clients</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_a_c && (
                                                            <button className="btn btn-warning" onClick={() => approvedSeeMore()}>
                                                            See More
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageClients;
