import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import CoverImage from '../../../../../../assets/images/end-meeting.png'
import Animation from '../../../../../../assets/images/end-meeting-animation.gif'
import CorrectMark from '../../../../../../assets/images/right mark.png'

import styles from './styles';

export default function EndCall() {
  const history = useHistory();
  const classes = styles();

  useEffect(()=> {
    const popupTimeout = setTimeout(() => {
      window.location.href = "/dashboard";
    }, 5000);

    return () => clearTimeout(popupTimeout);
  }, [])
  const redirectNewMeeting = () => {
    history.push('/');
  };

  const redirectToDashboard = () => {
    window.location.href = "/dashboard";
  };
  return (
    <div className={classes.container}>
      <div className={classes.meetingInfo}>
        <div className={classes.infoContainer}>
          <div className={classes.meetingInfoNew}>
            <img src={CoverImage} className={classes.coverImg} />
            <div className={classes.overlay}>
              <img src={Animation} />
            </div>
            <div className={classes.overlay}>
              <img src={CorrectMark} className={classes.correctImg} />
            </div>
          </div>
          <p className={classes.overlayText}>Meeting Successful</p>
          <p className={classes.overlayText2}>Thank you for taking this journey with us for your personal growth and well-being.</p>
          <div className={classes.btnContainer}>
            <button
              className="btn btn-primary mb-4"
              onClick={redirectToDashboard}
            >
              Back to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}