import React, { useContext, useEffect, useState } from "react";
import PersonalInfo from "./ClientsChartTabs/PersonalInfo";
import FormsClientSaas from "./ClientsChartTabs/FormsClientSaas";
import AssessmentClientSaas from "./ClientsChartTabs/AssessmentClientSaas";
import InsuranceSaas from "./ClientsChartTabs/InsuranceSaas";
import ClinicalNotesSaas from "./ClientsChartTabs/ClinicalNotesSaas";
import "./css/ClientDetails.css";
import { SaasTherapistService } from "src/services/SaasTherapistService";
import { toast } from "react-toastify";
import PreAuthorizationSaas from "./ClientsChartTabs/PreAuthorizationSaas";
import { Util } from "../../Util";
import Spinner from "../../common/spinner/spinner";
import defaultImage from "../../assets/images/default_profile.png";
import UserContext from "../../context/UserContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CardTitle, Col, Row } from "reactstrap";
import TherapyPlanSaas from "./ClientsChartTabs/TherapyPlanSaas";
import JotformSaas from "./ClientsChartTabs/JotformSaas";

const ClientsChart = () => {
  const [activeTab, setActiveTab] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientId, setSelectedClientId] = useState<string | undefined>("");
  const [clientDetails, setClientDetails] = useState<any[]>([]);
  const [filteredClientDetails, setFilteredClientDetails] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useContext(UserContext);
  const [isSaasTherapist, setIsSaasTherapist] = useState(false);
  const SCREEN_SIZE = 991;
  const updateScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    fetchAllClients();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, [updateScreenSize]);

  useEffect(() => {
    if (selectedClientId == undefined) {
      setShouldDisplay(true);
    } else {
      setShouldDisplay(false);
    }
  }, [selectedClientId]);

  useEffect(() => {
    user?.saasModelType === "SAAS" ? setIsSaasTherapist(true) : setIsSaasTherapist(false);
  }, []);

  const fetchAllClients = async () => {
    setIsLoading(true);
    try {
      const res = await SaasTherapistService.getAllClients();
      if (res.success) {
        setClientDetails(res.data);
        setFilteredClientDetails(res.data);
        console.log(res.data);

        if (res.data.length > 0) {
          setSelectedClient(res.data[0].clientDetails.firstname ?? "");
          setSelectedClientId(res.data[0].clientDetails._id ?? "");
          res.data[0].hasSessionNotes ? setActiveTab("clinicalNotes") : setActiveTab("assessment");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        toast.error("Error loading data", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch clients", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      setIsLoading(false);
    }
  };

  const filterClients = (searchText: string) => {
    const trimmedText = searchText.trim();
    setSearchQuery(trimmedText);
    const data = clientDetails.filter(client =>
      `${client.clientDetails?.firstname} ${client.clientDetails?.lastname}`.toLowerCase().includes(trimmedText.toLowerCase())
    );
    setFilteredClientDetails(data);
  };

  const renderTabContent = () => {
    const clientId = selectedClientId ?? "";
    return activeTab === "personal" ? (
      <PersonalInfo clientId={clientId} />
    ) : activeTab === "forms" ? (
      <FormsClientSaas clientId={clientId} />
    ) : activeTab === "assessment" ? (
      <AssessmentClientSaas clientId={clientId} />
    ) : activeTab === "treatmentPlan" ? (
      <TherapyPlanSaas clientId={clientId} />
    ) : activeTab === "clinicalNotes" ? (
      <ClinicalNotesSaas clientId={clientId} />
    ) : activeTab === "insurance" ? (
      <InsuranceSaas clientId={clientId} />
    ) : activeTab === "preAuth" ? (
      <PreAuthorizationSaas clientId={clientId} />
    ) : activeTab === "jotform" ? (
      <JotformSaas clientId={clientId} />
    ) : null;
  };

  const handleClientChange = (client: any) => {
    openChatBox();
    setSelectedClient(client.clientDetails?.firstname ?? "");
    setSelectedClientId(client.clientDetails?._id);
    setActiveTab("");
    client.hasSessionNotes ? setActiveTab("clinicalNotes") : setActiveTab("assessment");

  };

  const [sortType, setSortType] = useState("asc");
  const toggleSorting = () => {
    if (sortType === "asc") {
      sortingAZ();
      setSortType("desc");
    } else {
      sortingZA();
      setSortType("asc");
    }
  };

  const sortingAZ = () => {
    const sortedData = [...clientDetails].sort((a, b) =>
      a.clientDetails.firstname.localeCompare(b.clientDetails.firstname)
    );
    setClientDetails(sortedData);
  };

  const sortingZA = () => {
    const sortedData = [...clientDetails].sort((a, b) =>
      b.clientDetails.firstname.localeCompare(a.clientDetails.firstname)
    );
    setClientDetails(sortedData);
  };


  const openChatBox = () => {
    if (screenSize <= SCREEN_SIZE) {
      setShouldDisplay(false);
    }
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const back = () => {
    setSelectedClientId(undefined);
    setShouldDisplay(true);
  };


  const leftNavBarComponent = () => (
    <div className="client-main-layout col-12">
      <div className="client-list">
        <div className={"w-300 d-flex flex-row justify-content-start align-item-center mb-2 p-2"}>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}> Client List</span>
          <span className={"ms-2"}>
            <i onClick={toggleSorting} className={"bx bx-sort"}></i>
          </span>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group mb-2">
              <label htmlFor="searchInput" className="visually-hidden">
                Search
              </label>
              <input
                type="text"
                placeholder="Search clients..."
                value={searchQuery}
                onChange={e => filterClients(e.target.value)}
                id="searchInput"
                className="form-control search-input-stat rounded-start"
              />
              <button
                className="btn btn-warning d-flex justify-content-center align-items-center rounded-end"
                type="button"
                onClick={() => filterClients(searchQuery)}
              >
                <i className="bx bx-search"></i>
              </button>
            </div>
          </div>
        </div>

        <div className={"client-list-scroll"}>
          {isLoading ? (
            <div className={"d-flex justify-content-center align-items-center"} style={{ minHeight: "25vh", width: "100%", position: "relative" }}>
              <Spinner className={"bouncer"} />
            </div>
          ) : (
            <ul className="list-unstyled chat-list" id="recent-list">
              <div style={{ height: "68vh", overflow: "hidden" }}>
                <PerfectScrollbar style={{ height: "100%" }}>
                  <div>
                    {filteredClientDetails.map((client, index) => (
                      <li
                        key={index}
                        className={`client-list-item mb-1 ${selectedClientId === client.clientDetails?._id ? "selected" : ""}`}
                        onClick={() => handleClientChange(client)}
                      >
                        <div className="profile-circle">
                          <img
                            src={client.clientDetails?.photoId ? Util.fileURL(client.clientDetails?.photoId) : defaultImage}
                            className="profile-image"
                            alt="Profile"
                          />
                        </div>
                        {client.clientDetails?.firstname} {client.clientDetails?.lastname}
                      </li>
                    ))}
                  </div>
                </PerfectScrollbar>
              </div>
            </ul>
          )}
        </div>
      </div>
    </div>
  );

  const rightSideView = () => (
    <div className={screenSize > SCREEN_SIZE ? "w-100 user-chat" : ""}>
      <h2 className="client-details-title p-2">Client Chart: {selectedClient} </h2>
      <p className="client-details-subtitle ps-2">Electronic Health Record</p>
      <ul className="nav nav-tabs">
        <li className="saas-nav-item">
          <button className={`saas-nav-link ${activeTab === "personal" ? "active" : ""}`} onClick={() => setActiveTab("personal")}>
            Personal
          </button>
        </li>
        <li className="saas-nav-item">
          <button className={`saas-nav-link ${activeTab === "assessment" ? "active" : ""}`} onClick={() => setActiveTab("assessment")}>
            Clinical Assessment
          </button>
        </li>
        <li className="saas-nav-item">
          <button className={`saas-nav-link ${activeTab === "clinicalNotes" ? "active" : ""}`} onClick={() => setActiveTab("clinicalNotes")}>
            Session Notes
          </button>
        </li>
        <li className="saas-nav-item">
          <button className={`saas-nav-link ${activeTab === "treatmentPlan" ? "active" : ""}`} onClick={() => setActiveTab("treatmentPlan")}>
            Treatment Plans
          </button>
        </li>
        <li className="saas-nav-item">
          <button className={`saas-nav-link ${activeTab === "preAuth" ? "active" : ""}`} onClick={() => setActiveTab("preAuth")}>
            Prior Authorization
          </button>
        </li>
        {isSaasTherapist && (
          <li className="saas-nav-item">
            <button className={`saas-nav-link ${activeTab === "insurance" ? "active" : ""}`} onClick={() => setActiveTab("insurance")}>
              Insurance
            </button>
          </li>
        )}
        <li className="saas-nav-item">
          <button className={`saas-nav-link ${activeTab === "jotform" ? "active" : ""}`} onClick={() => setActiveTab("jotform")}>
            Forms
          </button>
        </li>
      </ul>
      <div key={selectedClientId} className="client-tab-content">
        {renderTabContent()}
      </div>
    </div>
  );

  return (
    <div className={"page-content"}>
      {(screenSize < SCREEN_SIZE) && !shouldDisplay ? (
        <div className="flex flex-wrap cursor-pointer pb-2" onClick={() => back()}>
          <i className="mt-1 fas fa-regular fa-arrow-left "></i>
          <CardTitle className="cursor_pointer">&nbsp; Back</CardTitle>
        </div>
      ):(<></>)}
      <Row>
        <Col lg="12">
          <div className="d-lg-flex">
            <div className="chat-leftsidebar me-lg-4">
              <div className="chat-leftsidebar-nav">
                {(screenSize <= SCREEN_SIZE && shouldDisplay) || screenSize > SCREEN_SIZE ? (
                  leftNavBarComponent()
                ) : (
                  <>{(selectedClientId !== undefined || selectedClientId !== null || selectedClientId !== "") && rightSideView()}</>
                )}
              </div>
            </div>

            {selectedClientId !== undefined && screenSize > SCREEN_SIZE ? (
              rightSideView()
            ) : (
              <div className={screenSize > SCREEN_SIZE ? "bg-white chat-body1" : "no-panel"}>
                <div className="d-flex justify-content-center h-100 align-items-center" style={{ fontSize: "18px", padding: "10px" }}>
                  <p>Please select a client to view details</p>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ClientsChart;