import { tr } from "date-fns/locale";
import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { level } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

const FormSix = (props: any) => {
    const { formId, assesmenData, getAssesmentByClientId, customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })
    const [edit, setEdit] = useState<boolean>(true)

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                stepCount: 6,
                _id: formId,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                asamDimensions: assesmenData.asamDimensions,
                schoolName: assesmenData.schoolName,
                highestEducation: assesmenData.highestEducation,
                employmentVocational: assesmenData.employmentVocational,
                rank: assesmenData.rank,
                yearsServed: assesmenData.yearsServed,
                reasonforDischarge: assesmenData.reasonforDischarge,
                serviceConnectedDisability: assesmenData.serviceConnectedDisability,
                independentLiving: assesmenData.independentLiving
            }
            setFormData(updateAssesment);
        }
    }, [assesmenData, customActiveTab]);

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            stepCount: 6,
            _id: formId,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            asamDimensions: formData.asamDimensions,
            schoolName: formData.schoolName,
            highestEducation: formData.highestEducation,
            employmentVocational: formData.employmentVocational,
            rank: formData.rank,
            yearsServed: formData.yearsServed,
            reasonforDischarge: formData.reasonforDischarge,
            serviceConnectedDisability: formData.serviceConnectedDisability,
            independentLiving: formData.independentLiving
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>ASAM Dimensions</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left", minWidth: "940px" }}>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td colSpan={2}>
                                                <h6 style={{ margin: "0.3rem" }}>ASAM Dimension Information completed for all consumers with a diagnosis of substance abuse or dependency.</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-600" style={{ width: "17%", padding: "0.2rem" }}>Information</td>
                                            <td className="fw-600" style={{ width: "83%", padding: "0.2rem" }}>Level</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Intoxicated/<br />Withdrawal Symptoms
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.LOW}
                                                        name="intoxicationWithdrawal"
                                                        checked={formData.asamDimensions?.intoxicationWithdrawal === level.LOW}
                                                        onClick={() => setFormData({
                                                            ...formData, asamDimensions: {
                                                                ...formData.asamDimensions, intoxicationWithdrawal: level.LOW as level
                                                            }
                                                        })}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low -
                                                        <span className="fw-500">
                                                            Not under the influence; no withdrawal potential
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="intoxicationWithdrawal"
                                                        value={level.MEDIUM}
                                                        checked={formData.asamDimensions?.intoxicationWithdrawal === level.MEDIUM}
                                                        onClick={() => setFormData({
                                                            ...formData, asamDimensions: {
                                                                ...formData.asamDimensions, intoxicationWithdrawal: level.MEDIUM as level
                                                            }
                                                        })}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium -
                                                        <span className="fw-500">
                                                            Recent use, potential for intoxication; presenting with initial withdrawal symptoms
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.HIGH}
                                                        name="intoxicationWithdrawal"
                                                        checked={formData.asamDimensions?.intoxicationWithdrawal === level.HIGH}
                                                        onClick={() => setFormData({
                                                            ...formData, asamDimensions: {
                                                                ...formData.asamDimensions, intoxicationWithdrawal: level.HIGH as level
                                                            }
                                                        })}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High -
                                                        <span className="fw-500">
                                                            Severe withdrawal history; presenting with seizures, CIWA score greater than 10
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Medical Conditions
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.LOW}
                                                        name="medicalConditions"
                                                        checked={formData.asamDimensions?.medicalConditions === level.LOW}
                                                        onClick={() => setFormData({
                                                            ...formData, asamDimensions: {
                                                                ...formData.asamDimensions, medicalConditions: level.LOW as level
                                                            }
                                                        })}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low -
                                                        <span className="fw-500">
                                                            No current medical problems; no diagnosed medical condition; no care from PCP or prescribed meds
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.MEDIUM}
                                                        name="medicalConditions"
                                                        checked={formData.asamDimensions?.medicalConditions === level.MEDIUM}
                                                        onClick={() => setFormData({
                                                            ...formData, asamDimensions: {
                                                                ...formData.asamDimensions, medicalConditions: level.MEDIUM as level
                                                            }
                                                        })}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium -
                                                        <span className="fw-500">
                                                            Diagnosed medical condition; care from PCP; problematic response to conditions and/or care
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={level.HIGH}
                                                        name="medicalConditions"
                                                        checked={formData.asamDimensions?.medicalConditions === level.HIGH}
                                                        onClick={() => setFormData({
                                                            ...formData, asamDimensions: {
                                                                ...formData.asamDimensions, medicalConditions: level.HIGH as level
                                                            }
                                                        })}
                                                    />
                                                    <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High -
                                                        <span className="fw-500">
                                                            Life threatening medical condition; medical problems interfering with treatment; hospitalization needed
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Psychiatric<br />Co-Morbidity
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="psychiatricCoMorbidities"
                                                            checked={formData.asamDimensions?.psychiatricCoMorbidities === level.LOW}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, psychiatricCoMorbidities: level.LOW as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>No current cognitive/emotional/behavioral conditions</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="psychiatricCoMorbidities"
                                                            checked={formData.asamDimensions?.psychiatricCoMorbidities === level.MEDIUM}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, psychiatricCoMorbidities: level.MEDIUM as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Psychiatric Symptoms, including cognitive, emotional, behavioral; complications interfering with recovery efforts</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="psychiatricCoMorbidities"
                                                            checked={formData.asamDimensions?.psychiatricCoMorbidities === level.HIGH}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, psychiatricCoMorbidities: level.HIGH as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Active DTO/s, S/HI; destructive, violent, or threatening behaviors, refusing to attend program schedule</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Motivation for Treatment
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="mortivationforTreatment"
                                                            checked={formData.asamDimensions?.mortivationforTreatment === level.LOW}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, mortivationforTreatment: level.LOW as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Accepting need for treatment; attending, participating, and can ID future goals, plans</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="mortivationforTreatment"
                                                            checked={formData.asamDimensions?.mortivationforTreatment === level.MEDIUM}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, mortivationforTreatment: level.MEDIUM as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Ambivalent about treatment; seeking help to appease others; avoiding consequences</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="mortivationforTreatment"
                                                            checked={formData.asamDimensions?.mortivationforTreatment === level.HIGH}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, mortivationforTreatment: level.HIGH as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Denial of need for treatment despite severe consequences; refusing or is unable to engage due to DIM3, DIM5 symptoms interfering</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Relapse Prevention
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="relapsePrevention"
                                                            checked={formData.asamDimensions?.relapsePrevention === level.LOW}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, relapsePrevention: level.LOW as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Recognizes onset signs; uses coping skills with CD or psychiatric problems</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="relapsePrevention"
                                                            checked={formData.asamDimensions?.relapsePrevention === level.MEDIUM}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, relapsePrevention: level.MEDIUM as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Limited awareness of relapse triggers or onset signs</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="relapsePrevention"
                                                            checked={formData.asamDimensions?.relapsePrevention === level.HIGH}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, relapsePrevention: level.HIGH as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Beliefs problematic re: continued CD use despite attendance; revisions in treatment plan; unable to recognize relapse triggers or onset signs, or recognize and employ coping skills</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td rowSpan={3} className="fw-600" style={{ paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Recovery Environment
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.LOW}
                                                            name="recoveryEviornment"
                                                            checked={formData.asamDimensions?.recoveryEviornment === level.LOW}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, recoveryEviornment: level.LOW as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low - </label>
                                                    </div>
                                                    <div className="col-auto p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Supportive Recovery environment, with accessible MH, CD Support</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.MEDIUM}
                                                            name="recoveryEviornment"
                                                            checked={formData.asamDimensions?.recoveryEviornment === level.MEDIUM}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, recoveryEviornment: level.MEDIUM as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Medium - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Moderately supportive with problematic access to MH, CD support</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingLeft: "0.2rem", }}>
                                                <div className="row">
                                                    <div className="col-auto pr-0">
                                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                            type="radio"
                                                            className="form-check-input"
                                                            value={level.HIGH}
                                                            name="recoveryEviornment"
                                                            checked={formData.asamDimensions?.recoveryEviornment === level.HIGH}
                                                            onClick={() => setFormData({
                                                                ...formData, asamDimensions: {
                                                                    ...formData.asamDimensions, recoveryEviornment: level.HIGH as level
                                                                }
                                                            })}
                                                        />
                                                        <label className="fw-600" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High - </label>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Environment does not support recovery behaviors or efforts; resides with active substance users or abusive individuals</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Education</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Name of school :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="schoolName"
                                                    readOnly={!edit}
                                                    value={formData.schoolName || ""}
                                                    onChange={e => setFormData({ ...formData, schoolName: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Highest level of education :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="educationLevel"
                                                    readOnly={!edit}
                                                    value={formData.highestEducation || ""}
                                                    onChange={e => setFormData({ ...formData, highestEducation: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>Employment / Vocational</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }} />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if N/A
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={6}>
                                    <div className="row">
                                        <div className="col-5" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Currently Employed:</h6>
                                        </div>

                                        <div className="col-3 d-flex">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                checked={formData.employmentVocational?.currentlyEmployed === true}
                                                name="currentlyEmployed"
                                                onClick={() => setFormData(prevState => ({
                                                    ...prevState,
                                                    employmentVocational: {
                                                        ...prevState.employmentVocational,
                                                        currentlyEmployed: true
                                                    }
                                                }))}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                        </div>
                                        <div className="col-3 d-flex">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                checked={formData.employmentVocational?.currentlyEmployed === false}
                                                name="currentlyEmployed"
                                                onClick={() => setFormData(prevState => ({
                                                    ...prevState,
                                                    employmentVocational: {
                                                        ...prevState.employmentVocational,
                                                        currentlyEmployed: false
                                                    }
                                                }))}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Current position :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="position1"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.currentPosition || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            currentPosition: e.target.value
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Employed since :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="date"
                                                    id="employedSince"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.employedSince || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            employedSince: e.target.value
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}># of jobs within last five years :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="number"
                                                    id="numOfJobs"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.numJobsLastFiveYears || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            numJobsLastFiveYears: parseInt(e.target.value)
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} md={12}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>SSDI Claim Status (if applied) :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="ssid"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.ssdiClaimStatus || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            ssdiClaimStatus: e.target.value
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={6} md={12}>
                                    <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>SSDI Eligible?</h6>
                                        </div>

                                        <div className="col-2 form-check form-check-inline">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                checked={formData.employmentVocational?.ssdiEligible === true}
                                                name="ssdiEligible"
                                                onClick={() => setFormData(prevState => ({
                                                    ...prevState,
                                                    employmentVocational: {
                                                        ...prevState.employmentVocational,
                                                        ssdiEligible: true
                                                    }
                                                }))}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                        </div>
                                        <div className="col-2 form-check form-check-inline">
                                            <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                checked={formData.employmentVocational?.ssdiEligible === false}
                                                name="ssdiEligible"
                                                onClick={() => setFormData(prevState => ({
                                                    ...prevState,
                                                    employmentVocational: {
                                                        ...prevState.employmentVocational,
                                                        ssdiEligible: false
                                                    }
                                                }))}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Satisfaction with current employment situation. (If not employed, is it by choice? Explain)</h6>
                                        </div>
                                        <div className="col">
                                            <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                type="text"
                                                id="satisfaction"
                                                readOnly={!edit}
                                                value={formData.employmentVocational?.employSatisfaction || ""}
                                                onChange={e => setFormData(prevState => ({
                                                    ...prevState,
                                                    employmentVocational: {
                                                        ...prevState.employmentVocational,
                                                        employSatisfaction: e.target.value
                                                    }
                                                }))}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>Employment History, including reasons for job changes :</h6>
                                        </div>
                                        <div className="col">
                                            <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                type="text"
                                                id="employmentHistory"
                                                readOnly={!edit}
                                                value={formData.employmentVocational?.employmentHistory || ""}
                                                onChange={e => setFormData(prevState => ({
                                                    ...prevState,
                                                    employmentVocational: {
                                                        ...prevState.employmentVocational,
                                                        employmentHistory: e.target.value
                                                    }
                                                }))}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Barriers / Challenges to improving current situation, if desired :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="barriers"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.barriersChallenges || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            barriersChallenges: e.target.value
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="strengths"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.identifiedStrengths || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            identifiedStrengths: e.target.value
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified needs :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="needs"
                                                    readOnly={!edit}
                                                    value={formData.employmentVocational?.identifiedNeeds || ""}
                                                    onChange={e => setFormData(prevState => ({
                                                        ...prevState,
                                                        employmentVocational: {
                                                            ...prevState.employmentVocational,
                                                            identifiedNeeds: e.target.value
                                                        }
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '35%' }}>Military</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }} />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if N/A
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col lg={4}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Branch / Rank:</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="position2"
                                                    readOnly={!edit}
                                                    value={formData.rank || ""}
                                                    onChange={e => setFormData({ ...formData, rank: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={3}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Years Served :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="number"
                                                    id="servedYears"
                                                    readOnly={!edit}
                                                    value={formData.yearsServed}
                                                    onChange={e => setFormData({ ...formData, yearsServed: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={5}>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Reason for Discharge :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="reason"
                                                    readOnly={!edit}
                                                    value={formData.reasonforDischarge}
                                                    onChange={e => setFormData({ ...formData, reasonforDischarge: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Service connected Disability?</h6>
                                </Col>
                                <Col xl={2}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="yesOrNo"
                                            checked={formData.serviceConnectedDisability?.yesOrNo === false}
                                            onClick={() => setFormData({
                                                ...formData, serviceConnectedDisability: { yesOrNo: false }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="yesOrNo"
                                                checked={formData.serviceConnectedDisability?.yesOrNo === true}
                                                onClick={() => setFormData({
                                                    ...formData, serviceConnectedDisability: { yesOrNo: true }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.serviceConnectedDisability?.yesOrNo !== true || !edit}
                                                            value={formData.serviceConnectedDisability?.explaination || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, serviceConnectedDisability: { explaination: e.target.value, yesOrNo: true }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col>
                                    <div className="row" style={{ borderBottom: "groove" }}>
                                        <div className="col-md-auto col-xs-12" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Service connected Disability?</h6>
                                        </div>

                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                checked={formData.serviceConnectedDisability?.yesOrNo === true}
                                                onChange={e => setFormData({
                                                    ...formData, serviceConnectedDisability: {
                                                        ...formData.serviceConnectedDisability, yesOrNo: e.target.checked
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                                        </div>
                                        <div className="col-auto">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                checked={formData.serviceConnectedDisability?.yesOrNo === false}
                                                onChange={e => setFormData({
                                                    ...formData, serviceConnectedDisability: {
                                                        ...formData.serviceConnectedDisability, yesOrNo: !e.target.checked
                                                    }
                                                })}
                                            />
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}

                            <Row>
                                <h6 className="fw-600 mt-2">Independent Living and Self-Care Skills</h6>
                            </Row>

                            <Row className="justify-content-between">
                                <div className="col-lg-auto col">
                                    <h6>Consumer needs assistance with the following skills (check all that apply) :</h6>
                                </div>
                                <div className="col-lg-auto col d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Check if no needs</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.feedingSelf}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        feedingSelf: !formData.independentLiving?.assistanceWithSkills?.feedingSelf
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Feeding self</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.financialAssistance}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        financialAssistance: !formData.independentLiving?.assistanceWithSkills?.financialAssistance
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Financial assistance</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.applyingForBenefits}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        applyingForBenefits: !formData.independentLiving?.assistanceWithSkills?.applyingForBenefits
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Applying for benefits</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.mealPreparation}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        mealPreparation: !formData.independentLiving?.assistanceWithSkills?.mealPreparation
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Meal preparation</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.legalAssistance}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        legalAssistance: !formData.independentLiving?.assistanceWithSkills?.legalAssistance
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Legal assistance</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.academicEnrollment}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        academicEnrollment: !formData.independentLiving?.assistanceWithSkills?.academicEnrollment
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Academic enrollment</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.groceryShopping}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        groceryShopping: !formData.independentLiving?.assistanceWithSkills?.groceryShopping
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Grocery shopping</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.vocationalAssistance}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        vocationalAssistance: !formData.independentLiving?.assistanceWithSkills?.vocationalAssistance
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Vocational assistance</label>
                                </div>
                                <div className="col-xl-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.attendingCourtMandatedEvents}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        attendingCourtMandatedEvents: !formData.independentLiving?.assistanceWithSkills?.attendingCourtMandatedEvents
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Attending court mandated events</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.nutrition}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        nutrition: !formData.independentLiving?.assistanceWithSkills?.nutrition
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Nutrition</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.accessingSpecializedServices}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        accessingSpecializedServices: !formData.independentLiving?.assistanceWithSkills?.accessingSpecializedServices
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Accessing specialized services</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.basicHygiene}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        basicHygiene: !formData.independentLiving?.assistanceWithSkills?.basicHygiene
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Basic age-appropriate hygiene</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.accessingSupportSystems}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        accessingSupportSystems: !formData.independentLiving?.assistanceWithSkills?.accessingSupportSystems
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Accessing support systems</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.toiletTraining}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        toiletTraining: !formData.independentLiving?.assistanceWithSkills?.toiletTraining
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Toilet training</label>
                                </div>
                                <div className="col-lg-4 d-flex">
                                    <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData.independentLiving?.assistanceWithSkills?.transportation}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                independentLiving: {
                                                    ...prevState.independentLiving,
                                                    assistanceWithSkills: {
                                                        ...prevState.independentLiving?.assistanceWithSkills,
                                                        transportation: !formData.independentLiving?.assistanceWithSkills?.transportation
                                                    }
                                                }
                                            }))
                                        }}
                                    />
                                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Transportation</label>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-4 d-flex">
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                    Other :
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        border: "none",
                                                        marginTop: "0.1rem",
                                                        backgroundColor: "unset",
                                                    }}
                                                    type="text"
                                                    id="name"
                                                    value={formData.independentLiving?.assistanceWithSkills?.otherSkills || ""}
                                                    onChange={e => setFormData({
                                                        ...formData, independentLiving: {
                                                            ...formData.independentLiving, assistanceWithSkills: {
                                                                ...formData.independentLiving?.assistanceWithSkills, otherSkills: e.target.value
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&
                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 6 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormSix;