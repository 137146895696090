import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Button, Card, CardBody, CardTitle, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledTooltip } from "reactstrap";
import arrowIcon from "src/assets/images/icons/fi_arrow-right-circle.svg";
import NoUpcommingIcon from "src/assets/images/icons/no_upcomming.svg";
import UserContext from "src/context/UserContext";
import { Role } from "src/models/Role";
import { VonageNativeSdkService } from "src/services/VonageNativeSdkService";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { joinToVonageNativeVideoCall } from 'src/pages/Chat/vonage/vonage-native-sdk/hooks/useJoinCall';
import RefreshIcon from '@mui/icons-material/Refresh';

const ShowOngoingCalls: React.FC = () => {
  const [ongoingCallList, setOngoingCallList] = useState<any>([]);
  const [user, setUser] = useContext(UserContext);
  
  const getOngoingCallFunc = async () => {
    const ongoingCallRes: any = await VonageNativeSdkService.getOngoingCallsByUserId();
    console.log(ongoingCallRes);
    if (ongoingCallRes.success){
      setOngoingCallList(ongoingCallRes?.data?.ongoingMeetingData);
    }
  }

  useEffect(() => {
    getOngoingCallFunc();
  }, [])

  const joinToMeeting = (vonageSessionName: string) => {
    try {
      joinToVonageNativeVideoCall(vonageSessionName)
    } catch (error) {
      console.log(error);
      
    }
  }
  return (
    <React.Fragment>
      <Card className="p-2 w-100">
        <CardBody className="p-7-dashboard">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="mb-0 fs-14 fc-black">Ongoing Calls</h5>
          <Button outline onClick={getOngoingCallFunc}>
            <RefreshIcon/>
          </Button>
        </div>
          {ongoingCallList.length > 0 ? (
            ongoingCallList?.map((ongoingCall: any, index: number) => (
              <Card
                key={index}
                className="appointment-card1 cursor-pointer"
                onClick={() => {}}
              >
                <CardBody className="p-10-dashboard">
                  <div className="d-flex justify-content-between  row">
                    <div className="flex col-6">
                      <span className="d-none d-sm-flex justify-content-center align-items-center">
                        <PhoneCallbackIcon style={{color: '#898e93', fontSize: '30px'}}/>
                      </span>
                      <div className="d-flex justify-content-center align-items-center min-width-100">
                        <p className="mb-0 d-flex justify-content-center align-items-center ms-1 fc-black">
                          {user?.role && user.role === Role.THERAPIST ? (`${ongoingCall?.clientData?.firstname} ${ongoingCall?.clientData?.lastname}`) : 
                            (`${ongoingCall?.therapistData?.firstname} ${ongoingCall?.therapistData?.lastname}`)}
                        </p>
                      </div>
                    </div>
                    <div className="left-border-discover col-6 d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center min-width-100">
                      <Button color="success" className="btn fw-bold px-4 py-2 rounded-pill" onClick={() => joinToMeeting(ongoingCall?.vonageSessionName)}>
                        Join
                      </Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))
          ) : (
            <div className="">
              <div className="container no-upcoming-card d-flex justify-content-center align-items-center">
                <div className="text-upcoming">No Ongoing Calls </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ShowOngoingCalls;
