import React, { useEffect, useState } from "react";
const classNames = require('classnames');
import { Row, Col, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import ChildTab from "../ChildTabs/ChildTab";
import { Phrase, TabTypes } from "../../../models/Notes";
import { NoteService } from "../../../services/NoteService";
import Spinner from "../../../common/spinner/spinner";

interface ObjectiveTabProps {
  stage: string;
  activeVerticalTab: string;
  toggleVertical: (value: string) => void;
  mentalBehaviouralStatus: string;
  setMentalBehaviouralStatus: (value: string) => void;
}

const ObjectiveTab: React.FC<ObjectiveTabProps> = ({
  stage,
  activeVerticalTab,
  mentalBehaviouralStatus,
  toggleVertical,
  setMentalBehaviouralStatus,
}: ObjectiveTabProps) => {
  const [mentalPhrases, setMentalPhrases] = useState<Phrase[]>([]);
  const [affectivePhrases, setAffectivePhrases] = useState<Phrase[]>([]);
  const [isLoded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    NoteService.getAllPhrases(stage).then(res => {
      if (res.success) {
        setMentalPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.MENTAL_STATE));
        setAffectivePhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.AFFECTIVE_STATE));
        setIsLoaded(true);
      } else {
        setMentalPhrases([]);
        setAffectivePhrases([]);
        setTimeout(() => {
          setIsLoaded(true);
        }, 500);
      }
    });
  }, [stage]);

  return (
    <>
      {!isLoded && <Spinner className="bouncer" />}

      <Row>
        <Col lg={3}>
          <Nav pills className="flex-column">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "1",
                })}
                onClick={() => {
                  toggleVertical("1");
                }}
              >
                Affective State
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "2",
                })}
                onClick={() => {
                  toggleVertical("2");
                }}
              >
                Mental State
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col lg={9}>
          <TabContent activeTab={activeVerticalTab} className="text-muted">
            {affectivePhrases && affectivePhrases.length > 0 && (
              <ChildTab
                tabId={"1"}
                states={affectivePhrases}
                tabType={TabTypes.AFFECTIVE_STATE}
                message={"The client's affective and emotional state appeared:"}
                mentalBehaviouralStatus={mentalBehaviouralStatus}
                setMentalBehaviouralStatus={setMentalBehaviouralStatus}
              />
            )}

            {mentalPhrases && mentalPhrases.length > 0 && (
              <ChildTab
                tabId={"2"}
                states={mentalPhrases}
                tabType={TabTypes.MENTAL_STATE}
                message={"The client's mental state included:"}
                mentalBehaviouralStatus={mentalBehaviouralStatus}
                setMentalBehaviouralStatus={setMentalBehaviouralStatus}
              />
            )}
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default ObjectiveTab;
