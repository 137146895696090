import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Input, Row, Table } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { HouseHoldMemberModel, LeisureActivityModel, currentLivingSituation, relationDescription } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";
import { tr } from "date-fns/locale";

const FormTwo = (props: any) => {
    const { assesmenData } = props;
    const { formId } = props;
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const SCREEN_SIZE = 1200;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [] }
    })
    const [edit, setEdit] = useState<boolean>(true);

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                stepCount: 2,
                _id: formId,
                currentServices: assesmenData.currentServices,
                religiousCulturalLanguagePref: assesmenData.religiousCulturalLanguagePref,
                leisureActivity: assesmenData.leisureActivity.map((activity: LeisureActivityModel) => ({
                    description: activity.description || "",
                    frequency: activity.frequency || "",
                    barriers: activity.barriers || "",
                })),
                identifiedStrengths: assesmenData.identifiedStrengths,
                identifiedNeeds: assesmenData.identifiedNeeds,
                currentLivingSituation: assesmenData.currentLivingSituation,
                currentAddressDuration: assesmenData.currentAddressDuration,
                frequentMoves: assesmenData.frequentMoves,
                strengths: assesmenData.strengths,
                needs: assesmenData.needs,
                houseHoldMember: assesmenData.houseHoldMember.map((member: HouseHoldMemberModel) => ({
                    firstName: member.firstName,
                    lastName: member.lastName,
                    age: member.age,
                    gender: member.gender,
                    consumerRelation: member.consumerRelation,
                    relationDescription: member.relationDescription,
                })),
                immediateFamilyOutside: assesmenData.immediateFamilyOutside,
                feelingUnsafe: assesmenData.feelingUnsafe,
                incomeSource: assesmenData.incomeSource,
                transportSource: assesmenData.transportSource,
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] }

            }
            setFormData(updateAssesment);
        }
    }, [assesmenData]);

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    const addRow = () => {
        setFormData({
            ...formData,
            leisureActivity: [...formData.leisureActivity, {}]
        });
    };

    const addRowMember = () => {
        setFormData({
            ...formData,
            houseHoldMember: [...formData.houseHoldMember, {}]
        });
    };

    const updateAssesmentDetails = () => {

        const updateAssesment: ClinicalAssetment = {
            stepCount: 2,
            _id: formId,
            currentServices: formData.currentServices,
            religiousCulturalLanguagePref: formData.religiousCulturalLanguagePref,
            leisureActivity: formData.leisureActivity.map(activity => ({
                description: activity.description || "",
                frequency: activity.frequency || "",
                barriers: activity.barriers || "",
            })),
            identifiedStrengths: formData.identifiedStrengths,
            identifiedNeeds: formData.identifiedNeeds,
            currentLivingSituation: formData.currentLivingSituation,
            currentAddressDuration: formData.currentAddressDuration,
            frequentMoves: formData.frequentMoves,
            strengths: formData.strengths,
            needs: formData.needs,
            houseHoldMember: formData.houseHoldMember.map(member => ({
                firstName: member.firstName,
                lastName: member.lastName,
                age: member.age,
                gender: member.gender,
                consumerRelation: member.consumerRelation,
                relationDescription: member.relationDescription,
            })),
            immediateFamilyOutside: formData.immediateFamilyOutside,
            feelingUnsafe: formData.feelingUnsafe,
            incomeSource: formData.incomeSource,
            transportSource: formData.transportSource,
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] }

        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    };


    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Current Services</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Mental Health:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="mentalHealth"
                                            checked={formData.currentServices?.mentalHealth?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, mentalHealth: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="mentalHealth"
                                                checked={formData.currentServices?.mentalHealth?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, mentalHealth: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.currentServices?.mentalHealth?.nonReported !== false || !edit}
                                                            value={formData.currentServices?.mentalHealth?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, mentalHealth: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Substance Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="substanceAbuse"
                                            checked={formData.currentServices?.substanceAbuse?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, substanceAbuse: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="substanceAbuse"
                                                checked={formData.currentServices?.substanceAbuse?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, substanceAbuse: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.currentServices?.substanceAbuse?.nonReported !== false || !edit}
                                                            value={formData.currentServices?.substanceAbuse?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, substanceAbuse: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Medical:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="medical"
                                            checked={formData.currentServices?.medical?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, medical: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="medical"

                                                checked={formData.currentServices?.medical?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, medical: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.currentServices?.medical?.nonReported !== false || !edit}
                                                            value={formData.currentServices?.medical?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, medical: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Vocational/ Ed:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="vocational"
                                            checked={formData.currentServices?.vocational?.nonReported === true}
                                            onClick={e => setFormData({
                                                ...formData, currentServices: {
                                                    ...formData.currentServices, vocational: { nonReported: true }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="vocational"
                                                checked={formData.currentServices?.vocational?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, vocational: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.currentServices?.vocational?.nonReported !== false || !edit}
                                                            value={formData.currentServices?.vocational?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, vocational: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Developmental:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="developmental"
                                            checked={formData.currentServices?.developmental?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, developmental: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="developmental"
                                                checked={formData.currentServices?.developmental?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, developmental: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.currentServices?.developmental?.nonReported !== false || !edit}
                                                            value={formData.currentServices?.developmental?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, developmental: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Other:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="other"
                                            checked={formData.currentServices?.other?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, other: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="other"
                                                checked={formData.currentServices?.other?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, other: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.currentServices?.other?.nonReported !== false || !edit}
                                                            value={formData.currentServices?.other?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, other: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Attitude about treatment over time :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.currentServices?.treatmentAttitudeOverTime || ""}
                                                    onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, treatmentAttitudeOverTime: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Factors that have contributed to or inhibited previous recovery efforts :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.currentServices?.previousRecoveryFactors || ""}
                                                    onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, previousRecoveryFactors: e.target.value } })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Notes :</h6>
                                <div className="editor-area ck-content"
                                    style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <CKEditor
                                        editor={Editor}
                                        id="floatingTextarea"
                                        data={formData.currentServices?.notes}
                                        onChange={(event: ClinicalAssetment, editor: any) => {
                                            const data = editor.getData();
                                            setFormData(prevState => ({
                                                ...prevState,
                                                currentServices: {
                                                    ...prevState?.currentServices,
                                                    notes: data
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                    onChange={e => setFormData({ ...formData, currentServices: { ...formData.currentServices, notes: e.target.value } })}
                                /> */}
                            </Card>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Religious / Cultural / Language Preferences</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={4}>
                                    <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="religion"
                                            checked={formData.religiousCulturalLanguagePref?.christian === true}
                                            onClick={e => setFormData({
                                                ...formData, religiousCulturalLanguagePref: {
                                                    ...formData.religiousCulturalLanguagePref, christian: true,
                                                    doesntKnow: false,
                                                    otherReligion: { isOtherReligion: false }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Christian</label>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <Row>
                                        <div className="col-auto pr-0">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="religion"
                                                checked={formData.religiousCulturalLanguagePref?.doesntKnow === true}
                                                onClick={e => setFormData({
                                                    ...formData, religiousCulturalLanguagePref: {
                                                        ...formData.religiousCulturalLanguagePref, doesntKnow: true,
                                                        christian: false,
                                                        otherReligion: { isOtherReligion: false }
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col-5 p-0">
                                            <label className="fw-500" style={{ margin: "0.2rem" }}>Doesn’t Know</label>
                                        </div>
                                    </Row>
                                    {/* <div className="col-auto">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="religion"
                                            checked={formData.religiousCulturalLanguagePref?.doesntKnow === true}
                                            onClick={e => setFormData({
                                                ...formData, religiousCulturalLanguagePref: {
                                                    ...formData.religiousCulturalLanguagePref, doesntKnow: true,
                                                    christian: false,
                                                    otherReligion: { isOtherReligion: false }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Doesn’t Know</label>
                                    </div> */}
                                </Col>
                                <Col xl={4}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="religion"
                                                checked={formData.religiousCulturalLanguagePref?.otherReligion?.isOtherReligion === true}
                                                onClick={e => setFormData({
                                                    ...formData,
                                                    religiousCulturalLanguagePref: {
                                                        ...formData.religiousCulturalLanguagePref,
                                                        otherReligion: { isOtherReligion: true },
                                                        christian: false,
                                                        doesntKnow: false
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            Other :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.religiousCulturalLanguagePref?.otherReligion?.isOtherReligion !== true || !edit}
                                                            value={formData.religiousCulturalLanguagePref?.otherReligion?.otherReligionName || ""}
                                                            onChange={e => setFormData({
                                                                ...formData,
                                                                religiousCulturalLanguagePref: {
                                                                    ...formData.religiousCulturalLanguagePref,
                                                                    otherReligion: {
                                                                        ...formData.religiousCulturalLanguagePref?.otherReligion,
                                                                        otherReligionName: e.target.value
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="language"
                                            checked={formData.religiousCulturalLanguagePref?.english === true}
                                            onClick={e => setFormData({
                                                ...formData, religiousCulturalLanguagePref: {
                                                    ...formData.religiousCulturalLanguagePref, english: true,
                                                    spanish: false,
                                                    otherlanguage: { isOtherLanguage: false }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>English</label>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="language"
                                            checked={formData.religiousCulturalLanguagePref?.spanish === true}
                                            onClick={e => setFormData({
                                                ...formData, religiousCulturalLanguagePref: {
                                                    ...formData.religiousCulturalLanguagePref, spanish: true,
                                                    english: false,
                                                    otherlanguage: { isOtherLanguage: false }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Spanish</label>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <div className="row">
                                        <div className="col-auto d-flex" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="language"

                                                checked={formData.religiousCulturalLanguagePref?.otherlanguage?.isOtherLanguage === true}
                                                onClick={e => setFormData({
                                                    ...formData,
                                                    religiousCulturalLanguagePref: {
                                                        ...formData.religiousCulturalLanguagePref,
                                                        otherlanguage: {
                                                            ...formData.religiousCulturalLanguagePref?.otherlanguage,
                                                            isOtherLanguage: true
                                                        },
                                                        spanish: false,
                                                        english: false,
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                            Other :
                                                        </h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.religiousCulturalLanguagePref?.otherlanguage?.isOtherLanguage !== true || !edit}
                                                            value={formData.religiousCulturalLanguagePref?.otherlanguage?.otherLanguageName || ""}
                                                            onChange={e => setFormData({
                                                                ...formData,
                                                                religiousCulturalLanguagePref: {
                                                                    ...formData.religiousCulturalLanguagePref,
                                                                    otherlanguage: {
                                                                        ...formData.religiousCulturalLanguagePref?.otherlanguage,
                                                                        otherLanguageName: e.target.value
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Leisure Activities / Interests</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }}>
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "33%" }}>Description of Activity</td>
                                            <td className="fw-500" style={{ width: "34%" }}>Frequency</td>
                                            <td className="fw-500" style={{ width: "33%" }}>Barriers</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.leisureActivity?.map((activity, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={formData.leisureActivity[index]?.description || ""}
                                                                onChange={e => {
                                                                    const updatedActivities = [...(formData.leisureActivity || [])];
                                                                    updatedActivities[index].description = e.target.value;
                                                                    setFormData({ ...formData, leisureActivity: updatedActivities });
                                                                }}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={formData.leisureActivity[index]?.frequency}
                                                                onChange={e => {
                                                                    const updatedActivities = [...(formData.leisureActivity || [])];
                                                                    updatedActivities[index].frequency = e.target.value;
                                                                    setFormData({ ...formData, leisureActivity: updatedActivities });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={formData.leisureActivity[index]?.barriers || ""}
                                                                onChange={e => {
                                                                    const updatedActivities = [...(formData.leisureActivity || [])];
                                                                    updatedActivities[index].barriers = e.target.value;
                                                                    setFormData({ ...formData, leisureActivity: updatedActivities });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                    <div className="py-3 d-flex justify-content-start">
                                        {edit && <Button color="primary" onClick={addRow}>Add More Details</Button>}
                                    </div>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="col-auto">
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", margin: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        type="text"
                                                                        id="strengths_1"
                                                                        readOnly={!edit}
                                                                        value={formData.identifiedStrengths || ""}
                                                                        onChange={e => setFormData({ ...formData, identifiedStrengths: e.target.value })}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginRight: "0.5rem", marginLeft: "0.5rem", marginBottom: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified needs :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        type="text"
                                                                        id="needs_1"
                                                                        readOnly={!edit}
                                                                        value={formData.identifiedNeeds || ""}
                                                                        onChange={e => setFormData({ ...formData, identifiedNeeds: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </td>
                                    </tr>
                                </Table>
                            </div>

                            <Row>
                                <Col>
                                    <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Current Living Situation :</h6>
                                        </div>

                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.RENTING || ''}
                                                name="currentLivingSituation"
                                                checked={formData.currentLivingSituation === currentLivingSituation.RENTING}
                                                onClick={e => setFormData({ ...formData, currentLivingSituation: currentLivingSituation.RENTING })}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Renting</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.OWN}
                                                name="currentLivingSituation"
                                                checked={formData.currentLivingSituation === currentLivingSituation.OWN}
                                                onClick={e => setFormData({ ...formData, currentLivingSituation: currentLivingSituation.OWN as currentLivingSituation })}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Own</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.HOUSE}
                                                name="currentLivingSituation"
                                                checked={formData.currentLivingSituation === currentLivingSituation.HOUSE}
                                                onClick={e => { setFormData({ ...formData, currentLivingSituation: currentLivingSituation.HOUSE as currentLivingSituation }) }}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>House</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.APT}
                                                name="currentLivingSituation"
                                                checked={formData.currentLivingSituation === currentLivingSituation.APT}
                                                onClick={e => { setFormData({ ...formData, currentLivingSituation: currentLivingSituation.APT }) }}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Apartment</label>
                                        </div>
                                        <div className="col-auto form-check form-check-inline" style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}>
                                            <input className="form-check-input"
                                                type="radio"
                                                style={{ fontSize: "larger" }}
                                                value={currentLivingSituation.MOBILEHOME}
                                                name="currentLivingSituation"
                                                checked={formData.currentLivingSituation === currentLivingSituation.MOBILEHOME}
                                                onClick={e => { setFormData({ ...formData, currentLivingSituation: currentLivingSituation.MOBILEHOME }) }}
                                            />
                                            <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Mobile Home</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="col-xl-7">
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>How long at current address?</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    id="name"
                                                    readOnly={!edit}
                                                    value={formData.currentAddressDuration || ""}
                                                    onChange={e => setFormData({ ...formData, currentAddressDuration: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-xl-5">
                                    {/* <div className="row">
                                        <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                            <h6 className="fw-500" style={{ margin: "0.2rem" }}>Frequent moves?</h6>
                                        </div>
                                        <div className="col-auto form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                                checked={formData.frequentMoves}
                                                onClick={e => setFormData({ ...formData, frequentMoves: !formData.frequentMoves })}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="row d-flex" style={{ marginBottom: "0.5rem" }}>
                                        <div className="col-auto pr-0">
                                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={formData.frequentMoves}
                                                onClick={e => setFormData({ ...formData, frequentMoves: !formData.frequentMoves })}
                                            />
                                        </div>
                                        <div className="col-10 p-0">
                                            <label className="fw-500 mb-0" style={{ margin: "0.2rem" }}>Frequent moves?</label>
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>Identified strengths :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly={!edit}
                                                    value={formData.strengths || ""}
                                                    id="strengths_2"
                                                    onChange={e => setFormData({ ...formData, strengths: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h6 className="fw-500" style={{ margin: "0.3rem" }}>dentified needs :</h6>
                                            </div>
                                            <div className="col">
                                                <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                    type="text"
                                                    readOnly={!edit}
                                                    id="needs_2"
                                                    value={formData.needs || ""}
                                                    onChange={e => setFormData({ ...formData, needs: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "920px" }}>
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "21%" }}>Household members<br />(First name & last initial)</td>
                                            <td className="fw-500" style={{ width: "8%" }}>Age</td>
                                            <td className="fw-500" style={{ width: "13%" }}>Gender</td>
                                            <td className="fw-500" style={{ width: "18%" }}>Relationship to Consumer</td>
                                            <td className="fw-500" style={{ width: "40%" }}>Describe relationship</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.houseHoldMember?.map((member, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.houseHoldMember[index]?.firstName}
                                                            onChange={(e) => {
                                                                const updatedActivities = [...(formData.houseHoldMember || [])];
                                                                updatedActivities[index] = { ...member, firstName: e.target.value };
                                                                setFormData({ ...formData, houseHoldMember: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="number"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.houseHoldMember[index]?.age}
                                                            onChange={(e) => {
                                                                const updatedActivities = [...(formData.houseHoldMember || [])];
                                                                updatedActivities[index] = { ...member, age: parseInt(e.target.value) };
                                                                setFormData({ ...formData, houseHoldMember: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={formData.houseHoldMember[index]?.gender}
                                                            onChange={(e) => {
                                                                const updatedActivities = [...(formData.houseHoldMember || [])];
                                                                updatedActivities[index] = { ...member, gender: e.target.value };
                                                                setFormData({ ...formData, houseHoldMember: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={member.consumerRelation}
                                                            onChange={(e) => {
                                                                const updatedActivities = [...(formData.houseHoldMember || [])];
                                                                updatedActivities[index] = { ...member, consumerRelation: e.target.value };
                                                                setFormData({ ...formData, houseHoldMember: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <Row style={{ justifyContent: "space-evenly", alignItems: "center" }}>
                                                        <div className="col-auto">
                                                            <input
                                                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                type="radio"
                                                                className="form-check-input"
                                                                name={`relationDescription_${index}`}
                                                                checked={member.relationDescription === relationDescription.POOR}
                                                                onClick={(e) => {
                                                                    setFormData(prevState => {
                                                                        const updatedActivities = [...prevState.houseHoldMember];
                                                                        updatedActivities[index] = { ...member, relationDescription: relationDescription.POOR as relationDescription };
                                                                        return { ...prevState, houseHoldMember: updatedActivities };
                                                                    });
                                                                }}
                                                            />
                                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <input
                                                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                type="radio"
                                                                className="form-check-input"
                                                                name={`relationDescription_${index}`}
                                                                checked={member.relationDescription === relationDescription.FAIR}
                                                                onClick={(e) => {
                                                                    setFormData(prevState => {
                                                                        const updatedActivities = [...prevState.houseHoldMember];
                                                                        updatedActivities[index] = { ...member, relationDescription: relationDescription.FAIR as relationDescription };
                                                                        return { ...prevState, houseHoldMember: updatedActivities };
                                                                    });
                                                                }}
                                                            />
                                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <input
                                                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                type="radio"
                                                                className="form-check-input"
                                                                name={`relationDescription_${index}`}
                                                                checked={member.relationDescription === relationDescription.GOOD}
                                                                onClick={(e) => {
                                                                    setFormData(prevState => {
                                                                        const updatedActivities = [...prevState.houseHoldMember];
                                                                        updatedActivities[index] = { ...member, relationDescription: relationDescription.GOOD as relationDescription };
                                                                        return { ...prevState, houseHoldMember: updatedActivities };
                                                                    });
                                                                }}
                                                            />
                                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Good</label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <input
                                                                style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                type="radio"
                                                                className="form-check-input"
                                                                name={`relationDescription_${index}`}
                                                                checked={member.relationDescription === relationDescription.GREAT}
                                                                onClick={(e) => {
                                                                    setFormData(prevState => {
                                                                        const updatedActivities = [...prevState.houseHoldMember];
                                                                        updatedActivities[index] = { ...member, relationDescription: relationDescription.GREAT as relationDescription };
                                                                        return { ...prevState, houseHoldMember: updatedActivities };
                                                                    });
                                                                }}
                                                            />
                                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Great</label>
                                                        </div>
                                                    </Row>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <div className="py-3 pl-1 d-flex justify-content-start">
                                        {edit && <Button color="primary" onClick={addRowMember}>Add More Details</Button>}
                                    </div>
                                </Table>
                            </div>

                            <Row>
                                <Col xl={"auto"}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Are there other members of immediate family living outside of household?</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="immediateFamilyOutside"
                                            checked={formData.immediateFamilyOutside?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, immediateFamilyOutside: { nonReported: true } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="immediateFamilyOutside"
                                                checked={formData.immediateFamilyOutside?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, immediateFamilyOutside: { nonReported: false } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.immediateFamilyOutside?.nonReported !== false || !edit}
                                                            value={formData.immediateFamilyOutside?.explanation || ""}
                                                            onChange={e => setFormData({
                                                                ...formData, immediateFamilyOutside: { explanation: e.target.value, nonReported: false }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={"auto"}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Does consumer feel threatened or unsafe in current living environment?</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="feelingUnsafe"
                                            checked={formData.feelingUnsafe?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, feelingUnsafe: { nonReported: true } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}> None reported</label>
                                    </div>
                                </Col>
                                <Col xl={9}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="feelingUnsafe"
                                                checked={formData.feelingUnsafe?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, feelingUnsafe: { nonReported: false } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.feelingUnsafe?.nonReported !== false || !edit}
                                                            value={formData.feelingUnsafe?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, feelingUnsafe: { explanation: e.target.value, nonReported: false } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={5}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Source of income for consumer or guardians :</h6>
                                </Col>
                                <Col xl={5}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="incomeSource"
                                                checked={formData.incomeSource?.employed || false}
                                                //onClick={e => setFormData({ ...formData, incomeSource: { employed: true, unemployed: false, where: "" } })}
                                                onClick={() => {
                                                    setFormData(prevFormData => ({
                                                        ...prevFormData,
                                                        incomeSource: {
                                                            ...prevFormData.incomeSource,
                                                            employed: true,
                                                            unemployed: false,
                                                            SSDI: false,
                                                            explanation: ""
                                                        }
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Employed : Where?</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            disabled={formData.incomeSource?.employed === false}
                                                            type="text"
                                                            value={formData.incomeSource?.where || ""}
                                                            onChange={e => setFormData({ ...formData, incomeSource: { ...formData.incomeSource, where: e.target.value } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={2}>
                                    <div className="col-auto pb-1 d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="incomeSource"
                                            checked={formData.incomeSource?.SSDI || false}
                                            onClick={() => {
                                                setFormData(prevFormData => ({
                                                    ...prevFormData,
                                                    incomeSource: {
                                                        ...prevFormData.incomeSource,
                                                        employed: false,
                                                        unemployed: false,
                                                        SSDI: true,
                                                        explanation: "",
                                                        where: ""
                                                    }
                                                }));
                                            }}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>SSDI</label>
                                        {/* <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="checkbox"
                                            className="form-check-input"                       
                                            checked={formData.incomeSource?.SSDI}
                                            onClick={e => {
                                                setFormData(prevFormData => ({
                                                    ...prevFormData,
                                                    incomeSource: {
                                                        ...prevFormData.incomeSource,
                                                        SSDI: !formData.incomeSource?.SSDI
                                                    }
                                                }));
                                            }}
                                        // onClick={e => {
                                        //     const updatedIncomeSource = {
                                        //         ...formData.incomeSource,
                                        //         SSDI: !formData.incomeSource?.SSDI
                                        //     };
                                        //     setFormData({
                                        //         ...formData,
                                        //         incomeSource: updatedIncomeSource
                                        //     });
                                        // }}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>SSDI</label> */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={5}>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="incomeSource"
                                                checked={formData.incomeSource?.unemployed || false}
                                                //onClick={e => setFormData({ ...formData, incomeSource: { unemployed: true, employed: false, explanation: "" } })}
                                                onClick={() => {
                                                    setFormData(prevFormData => ({
                                                        ...prevFormData,
                                                        incomeSource: {
                                                            ...prevFormData.incomeSource,
                                                            unemployed: true,
                                                            employed: false,
                                                            SSDI: false,
                                                            where: ""
                                                        }
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Unemployed : Yes; Explain:</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            disabled={formData.incomeSource?.unemployed === false}
                                                            type="text"
                                                            value={formData.incomeSource?.explanation || ""}
                                                            onChange={e => setFormData({ ...formData, incomeSource: { ...formData.incomeSource, explanation: e.target.value } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={5}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Source of transportation for consumer or guardians :</h6>
                                </Col>
                                <Col xl={"auto"}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            checked={formData.transportSource?.hasaCar === true}
                                            onClick={e => setFormData({ ...formData, transportSource: { hasaCar: true } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Has car</label>
                                    </div>
                                </Col>
                                <Col xl={"auto"}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            checked={formData.transportSource?.publicTransport === true}
                                            onClick={e => setFormData({ ...formData, transportSource: { publicTransport: true } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Public Transportation</label>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                                paddingBottom: "7.5px !important"
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&
                            <button
                                type="button"
                                className="btn btn-primary relative mr-2 mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 2 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormTwo;