import React, { useContext, useEffect, useState } from "react";
import ChatContext from "../context/ChatContext";
import { SocketContext } from "../context/ScoketContext";
import UserContext from "../context/UserContext";
import { ChatService } from "../services/ChatService";

const ChatUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadChatCount, setUnreadChatCount] = useState<number>(0);
  const [loggedUser] = useContext(UserContext);
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (loggedUser?._id) {
      getUnreadChatCount(loggedUser?._id);
    }
  }, []);
  useEffect(() => {
    try {
      socket?.on("refresh-unread-notification", () => {
        if (loggedUser?._id) {
          getUnreadChatCount(loggedUser?._id);
        }
      });
    } catch (error) {}
  }, [socket]);

  const getUnreadChatCount = async (userId: string) => {
    try {
      const chatCount = await ChatService.getUnreadChatCount(userId);
      const chatCountNumber: number = chatCount.data;

      if (chatCount.success) {
        setUnreadChatCount(chatCountNumber);
      }
    } catch (error) {}
  };

  return <ChatContext.Provider value={[unreadChatCount, setUnreadChatCount]}>{children}</ChatContext.Provider>;
};

export default ChatUtils;