import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import HeaderMain from "../main/HeaderMain";
import FooterMain from "../main/FooterMain";
import successful from "../../assets/images/successful.png";
import { useParams } from "react-router";
import { ParameterTypes } from "src/utils/ParameterTypes";
import { CommonService } from "src/services/CommonService";

const StripeCoPayment: React.FC = () => {
  const { meetingId } = useParams<ParameterTypes>();
  const [loading, setLoading] = useState(false);
  const [paid, setSetPaid] = useState(false);
  const [coValue, setCoValue] = useState("");
  useEffect(() => {
    const data = {
      meetingId: meetingId
    }
    CommonService.checkCoPaymentStatus(data).then(res => {
      if (res.success) {
        setCoValue(res.data.copayment?.amount);
        if (res.data.copayment?.status == "PAID") {
          setSetPaid(true);
        }
      }
    });
  }, [meetingId]);


  const handlePayment = async () => {
    setLoading(true);

    try {
      const data = {
        coValue: coValue,
        meetingId: meetingId
      }
      await CommonService.checkoutPaymentLink(data).then(res => {
        if (res.success) {
          window.location.href = res.data;
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <div className="content-wrapper">
      <HeaderMain />
      <section
        className="bg-image-set wrapper image-wrapper bg-image bg-overlays bg-overlay-light-600 text-white"
        data-image-src="./../static/assets/img/photos/bg3.jpg"
      >
        <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center"></div>
      </section>

      <section className="wrapper bg-light2 angled upper-end" id="recoverform">
        <div className="container1 pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
              <div className="displaycenter">
                <div className="card cardChange">
                  <div className="card-body  text-center">
                    <section className="wrapper">
                      <div className="container d-flex justify-content-center align-items-center">
                        <div className="bg-payment-card2 mt-50 mb-50">
                          <div className="row">
                            <div className="col-md-8 col-lg-12 col-xl-12 mx-auto text-center">
                              {!paid ?
                                <p className="copayment-text-h mt-set-2 mt-md-2 mt-lg-3 ms-lg-4 me-lg-4 mb-3">You are due co-payment of ${coValue}.</p>
                                :
                                <p className="copayment-text-h mt-set-2 mt-md-2 mt-lg-3 ms-lg-4 me-lg-4 mb-3">Payment has already been paid.</p>
                              }
                            </div>
                          </div>
                          {!paid &&
                            <button className="btn btn-sm btn-primary mainText rounded-pill6" onClick={handlePayment} disabled={loading}>
                              {loading ? "Processing..." : "Pay Now"}
                            </button>
                          }
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="overflow-hidden">
        <div className="divider text-navy mx-n2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" style={{ color: "#343f52" }}>
            <path
              fill="currentColor"
              d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
            />
          </svg>
        </div>
      </div>
      <FooterMain />
    </div>
  );
};

export default StripeCoPayment;
