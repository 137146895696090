import React from "react";
import { CardTitle, Col, Row, Modal } from "reactstrap";

const Modal1: React.FC<{ trigger?: boolean; primaryBtn?: string; secondaryBtn?: string; setTrigger?: any; header?: string; setOnchange: any }> = (props) => {
    return (props.trigger) ? (
        <>
            <Modal isOpen={props.trigger} centered >
                <div className="modal-header">
                    <button
                        type="button"
                        onClick={() => props.setTrigger(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <CardTitle className="mb-4 text-center">{props.header}</CardTitle>

                    <Row>
                        <Col xl="3"></Col>
                        <Col xl="6"> <button
                            type="button"
                            className="btn btn-primary w-45 "
                            onClick={() => props.setOnchange()}
                        >
                            {props.primaryBtn}
                        </button>
                            <button
                                type="button"
                                className="btn  w-45 ml-3  card_shadow"
                                onClick={() => props.setTrigger(false)}
                            >
                                {props.secondaryBtn}
                            </button></Col>
                        <Col xl="3"></Col>
                    </Row>
                </div>
            </Modal>
        </>
    ) : null;
}

export default Modal1;