import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { environment } from "../../environment/environment";
import { NotificationEvent, NotificationModel } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";
import Spinner from "../../common/spinner/spinner";
import NotificationContext from "src/context/NotificationContext";
import UserContext from "src/context/UserContext";
import { Role } from "src/models/Role";

const AllNotifications: React.FC = () => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([] as NotificationModel[]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [userCount, setUserCount] = useState(10);
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [
    notificationDeatils,
    setNotificationDeatils,
    selectedClientsIdForChat,
    setSelectedClientsIdForChat,
    selectedClientsDetailsForChat,
    setSelectedClientsDetailsForChat,
  ] = useContext(NotificationContext);
  const [user] = useContext(UserContext);

  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    setIsLoading(true);
    NotificationService.getNotifications(true, limit, 0).then((res: any) => {
      if (res.success) {
        setNotifications(res.data.notifications);
        setUserCount(res.data?.notifications.length);
        setOffset(0);
      }
      setIsLoading(false);
    });
  };

  const deleteNotification = (event: any, notificationId: string) => {
    event.stopPropagation();

    Swal.fire({
      icon: "warning",
      title: "Are you sure want to remove this notification?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        NotificationService.deleteNotification(notificationId).then(res => {
          const newList = notifications.filter(item => item._id !== notificationId);

          setNotifications(newList);
        });
      }
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 10;

    setOffset(updatedSkip);

    setIsLoading(true);
    NotificationService.getNotifications(true, 10, updatedSkip).then((res: any) => {
      if (res.success) {
        res.data?.notifications.map((notification: any) => {
          setNotifications(notifications => [...notifications, notification]);
        });
        setUserCount(res.data?.notifications.length);
      }
      setIsLoading(false);
    });
  };
  return (
    <React.Fragment>
      <div className="page-content m-bg-color-white">
        <Container fluid>
          <Row>
            <Col lg={8} className="offset-lg-2">
              <Card>
                <CardBody>
                  <Row>
                    <div className="card-heading">
                      <h1 className="notifications-title">All Notifications</h1>

                      <div className="siler-line mb20"></div>
                      <>
                        {notifications.length > 0 ? (
                          <>
                            {notifications.map((n: NotificationModel) => (
                              <Col lg={12} className="notify-tag mb20" key={n._id}>
                                <Row
                                  onClick={() => {
                                    try {
                                      if (n.event && n.event == NotificationEvent.NEW_TWILIO_MESSAGE_FROM_CLIENT) {
                                        if (n.senderId && n.content && selectedClientsIdForChat != null && selectedClientsIdForChat != n.senderId) {
                                          if (user && user?.role && (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN)) {
                                            const incomingContent = n.content;
                                            const contentToRemove = "New message received from";
                                            const newContent: string = incomingContent.replace(contentToRemove, "").trim();
                                            setSelectedClientsDetailsForChat(newContent);
                                            setSelectedClientsIdForChat(n.senderId);
                                          }
                                        }
                                      } else {
                                        const items = n.link.split(environment.app_url);

                                        if (items.length > 1) {
                                          history.push(n.link.split(environment.app_url)[1] + "#" + n._id);
                                        } else {
                                          history.push(n.link + "#" + n._id);
                                        }
                                      }
                                    } catch (error) {}
                                  }}
                                >
                                  <Col lg={1} className="div-notify">
                                    <div className={"notify-bg-" + n.variant + " square-notify"}></div>
                                  </Col>

                                  <Col lg={10}>
                                    {n.content}

                                    <br />

                                    <span className="time-ago">
                                      <i className="mdi mdi-clock-outline"></i> {moment(n.createdAt?.toString()).fromNow()}
                                    </span>
                                  </Col>

                                  <Col lg={1} className="div-notify">
                                    <i
                                      className="fa fa-times delete-notify-i"
                                      onClick={event => {
                                        deleteNotification(event, n._id!);
                                      }}
                                    ></i>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                            {userCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4 button_center" onClick={seeMore}>
                                  See More
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {isLoading ? (
                              <Spinner className="bouncer2" />
                            ) : (
                              <div className="text-reset notification-item no-notifications">No New Notifications.</div>
                            )}
                          </>
                        )}
                      </>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllNotifications;
