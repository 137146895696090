import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { ClaimProvider, ClaimStatusResponse } from "../../models/Insurance";
import { AdminService } from "../../services/AdminService";

interface ClaimStatusModalProps {
  insuranceId: string;
  tradingServiceId: string;
  diagnosisNoteId: string;
  showModal: boolean;
  toggleClaimStatusModal: () => void;
}

const ClaimStatusModal: React.FC<ClaimStatusModalProps> = ({ insuranceId, tradingServiceId, diagnosisNoteId, showModal, toggleClaimStatusModal }) => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [claimStatus, setClaimStatus] = useState<ClaimStatusResponse>({} as ClaimStatusResponse);
  useEffect(() => {
    getClaimStatusByInsuranceId(insuranceId, tradingServiceId, diagnosisNoteId);
  }, [insuranceId]);

  const getClaimStatusByInsuranceId = (insuranceId: string, tradingServiceId: string, diagnosisNoteId: string) => {
    setisLoading(true);

    AdminService.getClaimStatusByInsurancecId(insuranceId, tradingServiceId, diagnosisNoteId).then(res => {
      if (res.success) {
        setClaimStatus(res.data);
        setisLoading(false);
      } else {
        setClaimStatus({} as ClaimStatusResponse);
        setisLoading(false);
      }
    });
  };

  return showModal ? (
    <React.Fragment>
      <Modal isOpen={showModal} toggle={toggleClaimStatusModal} centered scrollable fullscreen className="p-5">
        {isLoading && <Spinner className="bouncer" />}

        <ModalHeader className="bg-modal">
          <button type="button" onClick={toggleClaimStatusModal} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
        </ModalHeader>

        <ModalBody className="px-5 bg-modal">
          <h2 className="mb-3 text-center text-muted">Claim Status</h2>
          <Row className="mb-3">
            <Col lg={4}>
              <Card className="overflow-hidden">
                <CardBody>
                  <Row>
                    <div className="flex flex-wrap flex-column">
                      <h5 className="font-size-15 text-truncate">Insurance</h5>
                    </div>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="6" sm="6">
                          <p className="text-muted mb-0">Organization Name</p>
                          <h5 className="font-size-15">{claimStatus?.payer?.organizationName}</h5>
                        </Col>
                        <Col lg="6" sm="6">
                          <p className="text-muted mb-0">Payer Identification</p>
                          <h5 className="font-size-15">{claimStatus?.payer?.payerIdentification}</h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {claimStatus?.providers?.map((provider: ClaimProvider, index: number) => {
                return (
                  <Card className="overflow-hidden" key={index}>
                    <CardBody>
                      <Row>
                        <div className="flex flex-wrap flex-column">
                          <h5 className="font-size-15 text-truncate">{provider?.providerType}</h5>
                        </div>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Row>
                            <Col lg="6" sm="6">
                              <p className="text-muted mb-0">Organization Name</p>
                              <h5 className="font-size-15">{provider?.organizationName}</h5>
                            </Col>
                            <Col lg="6" sm="6">
                              <p className="text-muted mb-0">Tax ID</p>
                              <h5 className="font-size-15">{provider?.taxId ? provider.taxId : "-"}</h5>
                            </Col>
                            <Col lg="6" sm="6">
                              <p className="text-muted mb-0">NPI</p>
                              <h5 className="font-size-15">{provider?.npi ? provider.npi : "-"}</h5>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}

              <Card className="overflow-hidden">
                <CardBody>
                  <Row>
                    <div className="flex flex-wrap flex-column">
                      <h5 className="font-size-15 text-truncate">Subscriber</h5>
                    </div>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="6" sm="6">
                          <p className="text-muted mb-0">Member ID</p>
                          <h5 className="font-size-15">{claimStatus?.subscriber?.memberId}</h5>
                        </Col>
                        <Col lg="6" sm="6">
                          <p className="text-muted mb-0">Firstname</p>
                          <h5 className="font-size-15">{claimStatus?.subscriber?.firstName}</h5>
                        </Col>
                        <Col lg="6" sm="6">
                          <p className="text-muted mb-0">Lastname</p>
                          <h5 className="font-size-15">{claimStatus?.subscriber?.lastName}</h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg={8}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" className="mt10">
                      <div className="table-responsive">
                        {claimStatus?.claims && (
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Status Category</th>
                                <th scope="col">Status</th>
                                <th scope="col">Effective Date</th>
                                <th scope="col">Service Claimed Date</th>
                                <th scope="col">Submitted Amount</th>
                                <th scope="col">Paid Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {claimStatus?.claims &&
                                claimStatus?.claims.map((claim, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{claim?.claimStatus?.statusCategoryCodeValue}</td>
                                    <td>{claim?.claimStatus?.statusCodeValue}</td>
                                    <td>{moment(claim?.claimStatus?.effectiveDate).format("YYYY-MM-DD")}</td>
                                    <td>{moment(claim?.claimStatus?.submittedAmount).format("YYYY-MM-DD")}</td>
                                    <td>{`$${claim?.claimStatus?.submittedAmount}`}</td>
                                    <td>{`$${claim?.claimStatus?.amountPaid}`}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  ) : null;
};

export default ClaimStatusModal;
