import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import CallInitializationContext from "src/context/CallInitializationContext";
import LocalCallContext from "src/context/LocalCallContext";
import { CallInitializeData } from "src/models/CallInitializeData";
import { VonageServices } from "src/pages/Chat/vonage/vonageServices";
import CallGif from "../../assets/images/video-call-gif.gif";

interface ongoingGroupCallProps {
  ongoingGroupCall: any[];
  closeOngoingGroupCallNotificationFunction: (val: boolean) => void;
}

const CheckOngoingGroupCallNotification: React.FC<ongoingGroupCallProps> = ({ ongoingGroupCall, closeOngoingGroupCallNotificationFunction }) => {
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  const history = useHistory();
  const ongoingGroupCallData = ongoingGroupCall[0];

  const handleCloseButtonClick = () => {
    closeOngoingGroupCallNotificationFunction(false);
  };

  const joinToMeeting = async () => {
    console.log(ongoingGroupCall);

    const meetingRes = await VonageServices.getMeetingDetailsByGroupCallId(ongoingGroupCallData?.groupCallId);

    if (meetingRes.success) {
      const meetingData = meetingRes.data;
      const callInitializeData: CallInitializeData = {
        start: false,
        isInstantMeeting: true,
        isAppointmentBased: false,
        appointmentId: "ddddddd",
        recieverId: "xxxxxxx",
        meetingTime: meetingData.meetingDuration,
        isTranscribeAllowed: true,
        meetingId: "",
        isAudioCall: meetingData.isAudioCall,
      };

      // setLocalCallInitialize(true);
      setLocalCallInitializeData(callInitializeData);
      setOutGoinCallTimeOutFunction();
      closeOngoingGroupCallNotificationFunction(false);
      const vonageUrl = `/room-group/${meetingData.vonageSessionName}`;
      history.push(vonageUrl);
    }
  };

  return (
    <div>
      <div className="call-div-new " style={{ zIndex: 9998 }}>
        <div className="icon-call-container">
          <img src={CallGif} className="call-gif" />
        </div>
        <div className="call-inside-div-2">
          <p className="call-title gif-call-text">
            {ongoingGroupCallData?.superAdminFirstName} {ongoingGroupCallData?.superAdminLastName} started the group call at{" "}
            {moment(ongoingGroupCallData?.startTime).format("hh:mm A")}
          </p>
          <div className="call-action-btns">
            <div className="btn btn-primary accept-btn-n" onClick={joinToMeeting}>
              Join
            </div>
            <div className="btn btn-danger reject-btn-n" onClick={handleCloseButtonClick}>
              End
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOngoingGroupCallNotification;
