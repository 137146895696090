import React, { useState, useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import FormFour from "./FormFour";
import FormFive from "./FormFive";

toast.configure();

const OnboardingPage: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [twoFeeTypes, setTwoFeeTypes] = useState(false);

  const addFormTwoHandler = () => setTwoFeeTypes(true);

  const removeFormTwoHandler = () => setTwoFeeTypes(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="containerOnboarding">
          <div className="card-heading mb-2">
            <h5 className="font-size-25 profileFont mb-4 text-center">Welcome to Lavni</h5>
          </div>
          <Row className="p-4">
            {user?.role == Role.THERAPIST ? (
              <Col xl={12}>
                {/* <StepWizard>
                  <FormOne addFormTwoHandler={addFormTwoHandler} />
                  <FormTwo removeFormTwoHandler={removeFormTwoHandler} />
                  <FormThree removeFormTwoHandler={removeFormTwoHandler} />
                  <FormFive removeFormTwoHandler={removeFormTwoHandler} />
                </StepWizard> */}
              </Col>
            ) : (
              <Col xl={12}>
                <StepWizard>
                  <FormOne addFormTwoHandler={addFormTwoHandler} />
                  <FormFour removeFormTwoHandler={removeFormTwoHandler} />
                </StepWizard>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OnboardingPage;
