import React from "react";
import Creatable from "react-select/creatable";
import { Tags } from "../../../../common/custom-components/Tags";
import { ExperienceTag, SelectedExperienceTag } from "../../../../models/ExperienceTag";

interface TherapistFiltersProps {
  selectedExperienceTags: SelectedExperienceTag[];
  experienceTags: ExperienceTag[];
  selectedOwnership: string;
  handleExperienceTags: (value: any) => void;
  setSelectedOwnership: (value: string) => void;
  removeExperienceTag: (value: string) => void;
  setTherapistQuery: () => void;
}

const TherapistFilters: React.FC<TherapistFiltersProps> = ({
  experienceTags,
  selectedOwnership,
  selectedExperienceTags,
  handleExperienceTags,
  setSelectedOwnership,
  removeExperienceTag,
  setTherapistQuery,
}: TherapistFiltersProps) => {
  const DropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const creatableComponents = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
  };

  return (
    <>
      <div className="text-muted f-18 mb-3">Filter through the Articles</div>
      <div className="search-box">
        <p className="text-muted mb-2">Sort by</p>
        <div className="mb-3" style={{ display: "flex" }}>
          <div
            className="pr-3"
            onClick={() => {
              setSelectedOwnership("all");
            }}
          >
            <input
              className="form-check-input pointer1"
              type="radio"
              value="all"
              name="type"
              checked={selectedOwnership === "all"}
              onChange={() => {
                setSelectedOwnership("all");
              }}
            />
            <label className="text-muted radio-btn-text" htmlFor="defaultCheck1">
              &nbsp;All
            </label>
          </div>
          <div
            className="pr-3 pl-3"
            onClick={() => {
              setSelectedOwnership("me");
            }}
          >
            <input
              className="form-check-input pointer1"
              type="radio"
              name="type"
              value="me"
              checked={selectedOwnership === "me"}
              onChange={() => {
                setSelectedOwnership("me");
              }}
            />
            <label className="text-muted radio-btn-text" htmlFor="defaultCheck1">
              &nbsp;Only my
            </label>
          </div>
        </div>

        <p className="text-muted mb-2">Search by tags</p>
        <div className="position-relative">
          <Creatable
            options={experienceTags}
            isMulti
            components={creatableComponents}
            onChange={(newValue: any) => handleExperienceTags(newValue)}
            value={selectedExperienceTags}
            display="none"
            className="creatableEdit"
            placeholder="Choose an area"
            controlShouldRenderValue={false}
            isClearable={false}
          />
          <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2 mb-3">
            {selectedExperienceTags.map((tag, id) => {
              return <Tags label={tag.label} id={id} key={id} removeTag={removeExperienceTag} />;
            })}
          </div>
        </div>
      </div>

      <div>
        <button className="btn btn-primary .btn.block w-100" onClick={setTherapistQuery}>
          Apply
        </button>
      </div>
    </>
  );
};

export default TherapistFilters;
