import React from "react";
import { Modal } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Close from "../../assets/images/icons/u_times-circle.png";
import PdfGeneratePage from "../NewAssessmentDigitalForm/GeneratePdf/PdfGeneratePage";
import PdfUploadPage from "../NewAssessmentDigitalForm/GeneratePdf/PdfUploadPage";
import { TPType } from "src/models/therapyPlan/therapyPlan";

const NewClinicalAssessmentPdfModal: React.FC<{ showModal: boolean; identifierId: any; setShowModal: (value: boolean) => void; isAdmin: boolean; eventType: "UPLOAD" | "DOWNLOAD"; insuranceDocApprovalId: any; setModalRefresher: ((value: boolean) => void) | null; digitalAssessmentType: TPType }> = props => {

    function closeAssessment() {
        props.setShowModal(false);
    }

    function uploadModalRefresher() {
        props.setModalRefresher?.(true);
    }
    
    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const eventType = props.eventType;
    const insuranceDocApprovalId = props.insuranceDocApprovalId;
    const digitalAssessmentType = props.digitalAssessmentType;

    const modalClassName = "assesment-modal";

    return props.showModal ? (
        <>
            <Modal isOpen={props.showModal}
                className={modalClassName}
                centered
                fullscreen
                scrollable
            >
                <div
                    className="modal-head"
                    style={{
                        zIndex: 9998,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px"
                    }}
                >
                    <div className="assessment-header"
                        style={{ margin: "5px"}}>
                        <button
                            type="button"
                            onClick={closeAssessment}
                            className="bg-transparent-color"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={Close} alt="Close" className="close-icon-d" />
                        </button>
                    </div>
                </div>
                <div
                    className="modal-body scrollbar-container ps--active-y"
                    style={{ backgroundColor: "#fff", borderRadius: "5px" }}
                    >
                    {eventType === "DOWNLOAD" ? (
                        <PdfGeneratePage identifierId={identifierId} isAdmin={isAdmin} onPdfComplete={closeAssessment} digitalAssessmentType={digitalAssessmentType} />
                    ) : (
                        <PdfUploadPage identifierId={identifierId} isAdmin={isAdmin} insuranceDocApprovalId={insuranceDocApprovalId} onPdfComplete={closeAssessment} onUploadModalRefresher={uploadModalRefresher} />
                    )}
                </div>

                <div className="assessment-footer"
                    style={{ margin: "5px"}}>
                </div>
            </Modal>
        </>
    ) : null;
};

export default NewClinicalAssessmentPdfModal;