import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Offcanvas,
  CardTitle,
  ModalHeader,
  OffcanvasHeader,
  ModalBody,
  OffcanvasBody,
  Table,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Label,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/services/AdminService";
import { Therapist } from "src/models/Therapist";
import Swal from "sweetalert2";
import { GenderTypes, UserRole, UserStatus } from "src/models/User";
import { SubscriptionStatus } from "src/models/CardTypes";
import { Transaction, TransactionType } from "src/models/Transaction";
import { TransactionService } from "src/services/TransactionService";
import "src/assets/css/admin.css";
import Spinner from "src/common/spinner/spinner";
import { format } from "date-fns";
import { MeetingData } from "src/models/MeetingData";
import moment from "moment";
import { toast } from "react-toastify";
import { string } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewUserPublicProfile from "../ViewUserPublicProfile";
import User from "../../../assets/images/icons/user.png";
import Trash from "../../../assets/images/icons/trash.png";
import File from "../../../assets/images/icons/file.png";
import ProfileModal from "src/pages/Popup/AdminStatModals/profileModal";
import PassDueNotesModal from "src/pages/Popup/AdminStatModals/passDueNotes";
import warning from "../../../assets/images/icons/warning.png";
import Close from "../../../assets/images/icons/close.png";
import { TreatmentHistoryData } from "src/models/TreatmentHistory";

const TherapistTable: React.FC = () => {
  const [isRight, setIsRight] = useState(false);
  const [isRight1, setIsRight1] = useState(false);
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [meetingData, setMeetingData] = useState<MeetingData[]>([] as MeetingData[]);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [offsetTranscribe, setOffsetTranscribe] = useState(1);
  const limitTranscribe = 10;
  const [transcribeCount, setTranscribeCount] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isGender, setIsGender] = useState(GenderTypes.ALL as GenderTypes);
  const [isSubscription, setSubscription] = useState(SubscriptionStatus.ALL as SubscriptionStatus);
  const [isSearchableString, setIsSearchableString] = useState({ searchableString: null, status: null, zipCode: null });
  const [isCreating, setCreating] = useState(false);
  const [userCount, setUserCount] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [isAccmulatedBalance, setIsAccmulatedBalance] = useState({ accumulatedBalance: "" });
  const history = useHistory();
  const [isView2, setIsView2] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [modalOpenRemove, setModalOpenRemove] = useState(false);
  const [modalOpenNotes, setModalOpenNotes] = useState(false);
  const [clientId, setClientId] = useState<string>("");
  const [openTheraphistSearchModal, setOpenTheraphistSearchModal] = useState(false);
  const [duenotes, setDuenotes] = useState<any[]>([]);
  const [therapistId, setTherapistId] = useState("");
  const [seemoreCountNotes, setSeeMoreCountNotes] = useState(10);
  const [offsetNote, setOffsetNote] = useState<number>(0);
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [isOpenBlockReasonModal, setIsOpenBlockReasonModal] = useState(false)
  const [reason, setReason] = useState({ sendReason: "" });
  const [reasonError, setReasonError] = useState(false)

  useEffect(() => {
    searchTherapists();
  }, []);

  const eventModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleTheraphistsSearchModal = () => {
    setOpenTheraphistSearchModal(!openTheraphistSearchModal);
  };

  const getUpdateTransactionsByTherapist = async (terapistId: any) => {
    setOffsetTranscribe(1);
    await TransactionService.getAllTransactionsByTherapist(terapistId, limitTranscribe, 1).then(res => {
      if (res.success) {
        setTransactions(res.data);
        setTranscribeCount(res.data?.length);
      } else {
        toast.error("Somthing went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const searchTherapists = () => {
    const data = {
      searchableString: isSearchableString.searchableString,
      monthAndYear: moment(selectedMonth).format("MM/YYYY"),
    };

    setIsLoading(true);

    AdminService.searchTherapistsByAdminStatistic(data, limit, 0).then(res => {
      if (res.success) {
        setTherapists(res.data.userSet);
        setUserCount(res.data.userSet.length);
        setOffset(0);
        setOpenTheraphistSearchModal(false);
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  };

  const deleteUser = (clientId: any | undefined) => {
    if (clientId && reason.sendReason && reason.sendReason.length>0) {
      const data = {
        userId: clientId,
        reasonToBlock: reason.sendReason
      };
      AdminService.blockUserByAdmin(data).then(res => {
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "User Blocked!",
            confirmButtonColor: "#FD7F00",
          });
          toggleReasonModal()
          // searchTherapists();
          const clientKey = therapists.findIndex((data: any) => data?.therapist?._id === clientId);
        
          if (clientKey !== -1) {
            const previousClient = { ...therapists[clientKey] };
            const updatedClient = {
              ...previousClient,
              therapist: { ...previousClient.therapist, blockedByAdmin: true }
            };
            therapists[clientKey] = updatedClient;
          }
              setTherapists(therapists);
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to delete the user.",
            confirmButtonColor: "#FD7F00",
          });
          toggleReasonModal()
        }
      });
    }else{
      setReasonError(true)
    }
  };

  const seeMore = () => {
    const updatedSkip = offset + 10;

    setOffset(updatedSkip);

    const data = {
      searchableString: isSearchableString.searchableString,
      gender: isGender,
      status: isSearchableString.status,
      isSubscription: isSubscription,
      zipCode: isSearchableString.zipCode,
      role: UserRole.CLIENT,
    };

    AdminService.searchTherapistsByAdminStatistic(data, 10, updatedSkip).then(res => {
      setIsLoading(true);

      if (res.success) {
        res.data.userSet.map((therapist: any) => {
          setTherapists(therapists => [...therapists, therapist]);
        });

        setUserCount(res.data.userSet.length);
      }

      setIsLoading(false);
    });
  };

  const hideAndShow2 = () => {
    setIsView2(!isView2);
  };

  const handleDateChange = (date: any) => {
    setSelectedMonth(date);
  };

  const handleCreateClient = () => {
    setShowModal(false);
  };

  const toggleModalProfile = () => {
    setModalOpenProfile(!modalOpenProfile);
  };
  const toggleModalRemove = () => {
    setModalOpenRemove(!modalOpenRemove);
  };
  const toggleModalPassDueNOtes = () => {
    setModalOpenNotes(!modalOpenNotes);
  };

  const seeMoreNotes = () => {
    setIsLoadingNotes(true);
    const updatedOffset = offsetNote + 10;
    setOffsetNote(updatedOffset);
    const data = {
      therapistId: therapistId,
    };
    AdminService.getAllPassDueNotesByTherapist(data, limit, updatedOffset).then(res => {
      setIsLoadingNotes(true);
      if (res.success) {

        setDuenotes(prev => {
          return [...new Set([...prev, ...res.data])];
        });
      }
      setSeeMoreCountNotes(res.data.length);
      setIsLoadingNotes(false);
    });
  };

  const showPastDueNotes = (userId: string) => {
    toggleModalPassDueNOtes();
    const data = {
      therapistId: userId,
    };


    setIsLoading(true);

    AdminService.getAllPassDueNotesByTherapist(data, limit, 0).then(res => {

      if (res.success) {

        setDuenotes(res.data);
        setOffset(0);
      }

      setIsLoading(false);
    });
  };

  const unblockUser = (userId: string | undefined) => {
    if (userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to unblock?",
        showCancelButton: true,
        confirmButtonText: "Unblock",
        confirmButtonColor: "#FD7F00",
        cancelButtonColor: "#FD7F00",
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            userId: userId,
          };
          AdminService.unblockUserByAdmin(data).then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "User unblocked!",
                confirmButtonColor: "#FD7F00",
              });
              const clientKey = therapists.findIndex((data: any) => data?.therapist?._id === clientId);
        
          if (clientKey !== -1) {
            const previousClient = { ...therapists[clientKey] };
            const updatedClient = {
              ...previousClient,
              therapist: { ...previousClient.therapist, blockedByAdmin: false }
            };
            therapists[clientKey] = updatedClient;
          }
              setTherapists(therapists);
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed to unblock the user.",
                confirmButtonColor: "#FD7F00",
              });
            }
          });
        }
      });
    }
  };
  const toggleReasonModal = () => {
    setIsOpenBlockReasonModal(!isOpenBlockReasonModal)
    setReasonError(false)
  }
  return (
    <React.Fragment>
      <Container fluid>
        <Card className="card">
          <CardBody>
            <Row onClick={isView2 ? hideAndShow2 : hideAndShow2} className={`${isView2 ? "cursor-p" : "cursor-p"}`}>
              <Col sm={4} md={4} lg={4} xl={4} xxl={4} className="d-flex gap-4 col-8">
                <Col className="d-flex justify-content-center align-items-center" md={12} lg={12} xl={12} xxl={12}>
                  <h4 className="card-title " style={{ flex: 1 }}>
                    Therapist Profile
                  </h4>
                </Col>
              </Col>
              <Col sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex justify-content-end align-items-center gap-md-2 col-4">
                {!isView2 && (
                  <>
                    <div className="d-sm-flex justify-content-center pr-2 d-none d-sm-block">
                      <div className="d-flex justify-content-center align-items-center pr-2">
                        <span className="text-center mb-0 single-line-text mr-2">Search : </span>
                      </div>
                      <div onClick={(event) => event.stopPropagation()}>
                        <input
                          className="search-input-stat form-control me-2"
                          onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })}
                        />
                      </div>
                    </div>
                    <div onClick={(event) => event.stopPropagation()}>
                      <DatePicker
                        selected={selectedMonth}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="Select Month"
                        className="form-control d-none d-sm-block "
                      />
                    </div>

                    <span className="btn btn-warning  btn-sm  mx-2 d-none d-sm-block" onClick={(event) => {
                      event.stopPropagation(); searchTherapists();
                    }}>
                      <i className="bx bx-search"></i>
                    </span>

                    <span className="btn btn-warning  btn-sm  mx-2 d-sm-none" onClick={(event) => {
                      event.stopPropagation(); toggleTheraphistsSearchModal();
                    }}>
                      <i className="bx bx-search"></i>
                    </span>

                    <Modal isOpen={openTheraphistSearchModal} centered style={{ overflow: "visible" }}>
                      <div className="modal-body" style={{ overflow: "visible" }}>
                        <button type="button" onClick={() => toggleTheraphistsSearchModal()} className="close" data-dismiss="modal" aria-label="Close">
                          <span>&times;</span>
                        </button>
                        <CardBody style={{ overflow: "visible" }}>
                          <CardTitle className="mb-4 text-center font-size-15">Search Therapists</CardTitle>
                          <Row>
                            <div className="d-flex justify-content-center">
                              <div className="d-flex justify-content-center align-items-center pr-2">
                                <span className="text-center mb-0 single-line-text mr-2">Search: </span>
                              </div>
                              <input
                                className="search-input-stat form-control"
                                onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })}
                              />
                            </div>
                          </Row>
                          <Row className="mt-2 mb-2">
                            <DatePicker
                              selected={selectedMonth}
                              onChange={handleDateChange}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              placeholderText="Select Month"
                              className="form-control"
                            />
                          </Row>
                          <div className="d-flex justify-content-center flexColumn">
                            <button type="button" onClick={() => searchTherapists()} className="btn btn-primary btnMargin">
                              Search
                            </button>
                          </div>
                        </CardBody>
                      </div>
                    </Modal>
                  </>
                )}

                <div className="d-sm-flex justify-content-center align-items-center flex-wrap cursor-pointer" onClick={hideAndShow2}>
                  {isView2 ? <i className="bx bxs-down-arrow mr-2 more-icon-admin"></i> : <i className="bx bxs-up-arrow mr-2 more-icon-admin"></i>}
                </div>
              </Col>
            </Row>
            <Row>
              <div className={!isView2 ? `is-view-admin overflow-hidden` : "is-hide-admin  overflow-hidden"}>
                <Col sm="12" className="mt10">
                  <div className="table-responsive" style={{ maxHeight: "235px", overflowY: "auto" }}>
                    {therapists !== undefined && therapists.length > 0 ? (
                      <>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              <th scope="col">State</th>
                              <th scope="col">Total Appointments</th>
                              <th scope="col">Average Weekly Appointments</th>
                              <th scope="col">Monthly Volume</th>
                              <th scope="col">Avarage Missed Appointments</th>
                              <th scope="col">Status</th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {therapists &&
                              therapists.map((data: any, index) => (
                                <tr key={data.therapist._id}>
                                  <th scope="row">{index + 1}</th>

                                  <td>
                                    {data.therapist.firstname?.charAt(0) + ". "}{data.therapist.lastname}
                                  </td>

                                  <td>{data.therapist.gender}</td>

                                  <td>{data.therapist.state}</td>
                                  <td>{data.totalAppointments}</td>
                                  <td>{data.averageWeeklyAppointments}</td>
                                  <td>{data.therapistSessionsCountMonth}</td>
                                  <td>{data.avarageMissedAppointments}</td>

                                  <td>
                                    {data.therapist.verifiedStatus == "VERIFIED" ? (
                                      <span className="verified-2">Verified</span>
                                    ) : (
                                      <span className="pending-2">Pending</span>
                                    )}
                                  </td>

                                  <td>
                                    <div className="flex">
                                      <button
                                        className="btn-sm btn btn-success mr-2"
                                        title="View Profile"
                                        onClick={() => {
                                          toggleModalProfile();
                                          setClientId(data?.therapist._id);

                                        }}
                                      >
                                        <img src={User} />
                                      </button>


                                      {data?.therapist.blockedByAdmin ? (
                                        <button className="btn-sm btn btn-success mr-2" onClick={() => unblockUser(data?.therapist._id)} title="Unblock Therapist">
                                          <i className="fa fa-unlock"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="btn-sm btn btn-danger mr-2"
                                          onClick={() => {
                                            // toggleModalRemove();
                                            toggleReasonModal()
                                            setClientId(data?.therapist._id);
                                          }}
                                          title="Block Therapist"
                                        >
                                          <i className="fa fa-ban"></i>
                                        </button>
                                      )}
                                      {/* <button className="btn-sm btn btn-danger mr-2" onClick={() => deleteUser(therapist._id)} title="Delete Therapist">
                                        <img src={Trash} />
                                      </button> */}

                                      <button
                                        className="btn-sm btn btn-primary btn-rose mr-2"
                                        title="Past due notes"
                                        onClick={() => {
                                          showPastDueNotes(data?.therapist._id);
                                          setTherapistId(data?.therapist._id);
                                        }}
                                      >
                                        <img src={File} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {userCount >= 10 && (
                          <>
                            <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                              See More
                            </button>
                          </>
                        )}

                        {userCount == 0 && <span>No more therapists available...</span>}
                      </>
                    ) : (
                      <>
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          <>
                            <div>No Therapist</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <Modal isOpen={showModal} centered toggle={eventModalToggle} unmountOnClose={true}>
        <ModalHeader toggle={eventModalToggle}>Create Therapist</ModalHeader>

        <ModalBody className="awsome-area">
          <Row className="mb-4">
            <Col lg={2}>
              <label>First Name</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Name"
                onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              // value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={2}>
              <label>Last name</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Last Name"
                onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              // value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={2}>
              <label>phone number</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Last Name"
                onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              // value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={2}>
              <label>Email</label>
            </Col>
            <Col lg={10}>
              <Input
                type="text"
                name="repeat"
                placeholder="Email"
                onChange={e => setIsAccmulatedBalance({ ...isAccmulatedBalance, accumulatedBalance: e.target.value })}
              // value={isAccmulatedBalance.accumulatedBalance || ""}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={2}>
              <label>Password</label>
            </Col>
            <Col lg={10}>
              <Input type="password" name="repeat" placeholder="Password" />
            </Col>
          </Row>
          <Row>
            <Col>
              <button className="btn btn-warning float-right mr-2" onClick={handleCreateClient}>
                Create
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <ProfileModal isOpen={modalOpenProfile} toggle={toggleModalProfile} clientId={clientId} />

      {/* delete modal */}
      <Modal isOpen={modalOpenRemove} toggle={toggleModalRemove} centered>
        <ModalBody>
          <div className="text-center">
            
            <div className="d-flex justify-content-center align-items-center">
              <span className="bg-light-red mb-3 btn-48 d-flex justify-content-center align-items-center">
                <img src={warning} className="btn-s-24" />
              </span>
            </div>
            <div>
              <h5 className="mb-2">Delete Client</h5>
            </div>
            <div>
              <p className="alertText mt-2">Are you sure you want to delete this Client ?</p>
            </div>
            <div className="mt-2">
              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  deleteUser(clientId);
                }}
              >
                Yes
              </button>
              <button className="btn btn-secondary ms-2" style={{ background: "#34C38F", borderBlock: "34C38F" }} onClick={toggleModalRemove}>
                No
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenBlockReasonModal} toggle={toggleReasonModal} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggleReasonModal}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
          className="btn-s-20"
        >
          <img src={Close} alt="Close" className="mt-1" />
        </Button>
      </div>
      <ModalBody className="ps-4 pe-4">
        <Row>
          <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Block Therapist </span>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <p className="mb-1">Reason</p>
            <textarea rows={4} style={{ width: "100%" }} placeholder="Type a reason" onChange={(e: any) => setReason({ ...reason, sendReason: e.target.value })}></textarea>
            {
              reasonError && <p className="text-danger f-10">Please enter reason</p>
            }
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="md-8"></Col>
          <Col className="text-right" style={{ backgroundColor: "#FFFFFF" }}>
            <button className="btn btn-warning create-client-custom2 float-right mr-2" onClick={() =>  deleteUser(clientId)}>Block</button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
      {/* pass due notes */}
      <Modal isOpen={modalOpenNotes} toggle={toggleModalPassDueNOtes} centered className="modal-lg">
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={toggleModalPassDueNOtes}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
          >
            <img src={Close} alt="Close" className="me-2 mt-2" />
          </Button>
        </div>
        <ModalBody className="ps-4 pe-4">
          <Card className="overflow-auto">
            <CardBody>
              <div className="d-sm-flex flex-wrap cursor-pointer">
                <div className="card-title text-center font-size-18 mb-2" style={{ flex: 1 }}>
                  Past Due Notes
                </div>
              </div>
              <Row>
                <Col lg="7"></Col>
                <Col lg="5" className="mb-3 d-flex  justify-content-md-end align-items-center">
                  {/* <div className="d-flex justify-content-center align-items-center pr-2">
                    <span className="text-center mb-0 single-line-text mr-2">Search : </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    
                  /> */}
                </Col>
              </Row>
              {duenotes !== undefined && duenotes.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Date</th>
                          <th scope="col">Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {duenotes &&
                          duenotes.map((data: any, index) => (
                            <>
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{moment(data.createdAt.toString()).format("YY/MM/DD")}</td>
                                <td>{data.note && data.note}</td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {seemoreCountNotes >= 10 && (
                    <>
                      <button className="btn btn-warning text-center mt-4" onClick={seeMoreNotes}>
                        See More
                      </button>
                    </>
                  )}
                  {userCount == 0 && <span>No more clients available...</span>}
                </>
              ) : (
                <>{isLoading ? <Spinner /> : <div>No Available Notes</div>}</>
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default TherapistTable;
