import axios from "axios";
import { InsuranceCompanyTags } from "../models/InsuranceCompany";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class InsuranceCompanyService {
    public static async getAllInsuranceCompany(limit?: number, offset?: number): Promise<AppResponse<InsuranceCompanyTags[]>> {
        const url = Util.apiAuthUrl(`getInsuranceCompanies/${limit}/${offset}`);
        return await axios.get<void, AppResponse<InsuranceCompanyTags[]>>(url);
    }

    public static async getAllInsuranceCompanyPublic(): Promise<AppResponse<InsuranceCompanyTags[]>> {
        const url = Util.apiPublicUrl(`getInsuranceCompanies`);
        return await axios.get<void, AppResponse<InsuranceCompanyTags[]>>(url);
    }

    public static async getInsuaranceCompanyByState(data: any): Promise<AppResponse<InsuranceCompanyTags[]>> {
        const url = Util.apiPublicUrl(`getInsuaranceCompanyByState`);
        return await axios.post<void, AppResponse<InsuranceCompanyTags[]>>(url, data);
    }

    public static async createInsuranceCompany(insuranceCompany: InsuranceCompanyTags): Promise<AppResponse<InsuranceCompanyTags>> {
        const url = Util.apiAuthUrl(`create/insuranceCompany`);
        return await axios.post<void, AppResponse<InsuranceCompanyTags>>(url, insuranceCompany);
    }

    public static async deleteInsuranceCompany(insuranceCompanyId: string): Promise<AppResponse<InsuranceCompanyTags>> {
        const url = Util.apiAuthUrl(`deleteInsuranceCompany/${insuranceCompanyId}`);
        return await axios.delete<void, AppResponse<InsuranceCompanyTags>>(url);
    }

    public static async updateInsuranceCompany(data: any): Promise<AppResponse<InsuranceCompanyTags>> {
        const url = Util.apiAuthUrl(`updateInsuranceCompany`);
        return await axios.post<void, AppResponse<InsuranceCompanyTags>>(url, data);
    }

    public static async getInsuaranceCoPaymentAndContractPriceById(insuranceCompanyId: string): Promise<AppResponse<any>> {
        const url = Util.apiPublicUrl(`getInsuaranceCoPaymentAndContractPriceById/${insuranceCompanyId}`);
        return await axios.get(url);
    }
    public static async getInsuranceByClient(): Promise<AppResponse<any[]>> {
        const url = Util.apiAuthUrl(`get/insurancesByClient`);
        return await axios.get<void, AppResponse<any[]>>(url);
    }

    public static async getInsuranceByAdmin(data: any): Promise<AppResponse<any[]>> {
        const url = Util.apiAuthUrl(`get/insurancesByAdmin`);
        return await axios.post<void, AppResponse<any[]>>(url, data);
    }
}