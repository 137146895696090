import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class PaymentService {
    public static async clientSubscribe(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`subscribe`);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }

    public static async clientCreate(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`createClient`);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }


    public static async attachNewPaymentMethod(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`attachPaymentMethod`);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }

    public static async makeMonthlyInvoicePayment(invoiceId: string): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`makeMonthlyInvoicePayment/` + invoiceId);
        return await axios.post<Partial<any>, AppResponse<any>>(url);
    }

    public static async getActiveSubscription(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`currentSubscription`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }

    public static async getPaymentMethods(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`paymentMethods`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }

    public static async getBillingHistory(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`billingHistory`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }

    public static async cancelSubscription(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`cancelSubscription`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }

    public static async resumeSubscription(): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`resumeSubscription`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }

    public static async changeDefaultPaymentMethod(data: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`changeDefaultPaymentMethod`);
        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }
}

