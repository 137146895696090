import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserContext from "../../context/UserContext";
import { Invoice, PaymentStatus } from "../../models/Invoice";
import { AdminService } from "../../services/AdminService";
import Tabs, { TabPane } from "rc-tabs";
import { CardDetails } from "../../models/Payment";
import PremiumBankCard from "./PremiumBankCard";
import Swal from "sweetalert2";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "../../assets/css/premium.css";
import { PaymentService } from "../../services/PaymentService";
import Spinner from "../../common/spinner/spinner";
import { ParameterTypes } from "src/utils/ParameterTypes";
import { useParams } from "react-router-dom";
import ProfileInsuranceDetails from "../Profile/InsuranceProfile";
import PoweredByStripeBadge from "../../assets/images/poweredbystripe/powered-by-stripe-black.svg";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontWeight: 600,
      fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#424770',
      },

      '::placeholder': {
        color: '#9BACC8',
      },

      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#fb1c50',
      ':focus': {
        color: '#FA755A',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  }
};

const

  PaymentHistory: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [invoices, setInvoices] = useState<Invoice[]>();
    const [user] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const [isMobile, setIsMobile] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<CardDetails[]>([]);
    const [isPaymentMethodAdding, setIsPaymentMethodAdding] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState("");
    const { activeTab } = useParams<ParameterTypes>();
    const [payingId, setPayingId] = useState("");

    useEffect(() => {
      window.screen.width <= 768 ? setIsMobile(true) : setIsMobile(false);
    }, [window.screen.width]);

    useEffect(() => {
      getCurrentUserPaymentHistory(100, 1);

      PaymentService.getPaymentMethods().then((response: any) => {
        if (response.success) {
          setPaymentMethods(response?.data?.paymentMethods?.data);
          setDefaultPaymentMethodId(response?.data?.stripeCustomer?.invoice_settings?.default_payment_method);
        }
      });

      if (activeTab == "2") {
        setActiveKey(activeTab);
      }
    }, []);

    const getCurrentUserPaymentHistory = (limit: number, offset: number) => {
      setIsLoading(true);

      AdminService.getCurrentUserPaymentHistory(limit, offset).then(res => {
        if (res.success) {
          setInvoices(res.data);
          setIsLoading(false);
        } else {
          setInvoices([]);
          setIsLoading(false);
        }
      });
    };

    function onTabsChange(e: any) {
      setActiveKey(e);
    }

    const changeDefaultPaymentMethod = (newPaymentMethodId: any) => {
      Swal.fire({
        icon: "warning",
        title: "Are you sure want to change default payment method?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          const selectedPaymentMethod = {
            paymentMethodId: newPaymentMethodId,
          };

          PaymentService.changeDefaultPaymentMethod(selectedPaymentMethod).then((response: any) => {
            if (response.success) {
              setDefaultPaymentMethodId(newPaymentMethodId);

              toast.success("Payment method is changed successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            } else {
              toast.error("Unable to change payment method!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          });
        }
      });
    };

    const addNewPaymentMethod = async () => {
      PaymentService.attachNewPaymentMethod('lak');

      if (!stripe || !elements) {
        toast.error("Stripe.js has not yet loaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }

      setIsPaymentMethodAdding(true);

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
        billing_details: {
          email: user?.email,
        },
      });

      if (result.error) {
        toast.error(result.error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsPaymentMethodAdding(false);
      } else {
        const paymentMethod = {
          paymentMethod: result.paymentMethod.id,
        };

        PaymentService.attachNewPaymentMethod(paymentMethod).then(res => {
          if (res.success) {
            setPaymentMethods([res.data, ...paymentMethods]);

            toast.success("New payment method is added successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            elements.getElement(CardElement)?.clear();

            setShowAddCard(false);

            setIsPaymentMethodAdding(false);

            setDefaultPaymentMethodId(res.data.id);
          } else {
            toast.error("Failed to add your card at this moment!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setIsPaymentMethodAdding(false);
          }
        });
      }
    };

    const makeMonthlyInvoicePayment = (index: number, invoice: Invoice) => {
      if (!user?.stripeCustomerId) {
        toast.error("Please add a payment method first!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setActiveKey("2");

        return false;
      }

      Swal.fire({
        icon: "warning",
        title: "Are you sure want to make due payment for month " + invoice.paymentMonth + "?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          setPayingId(invoice._id);

          PaymentService.makeMonthlyInvoicePayment(invoice._id).then(res => {
            if (res.success) {
              invoice.paymentStatus = PaymentStatus.PAID;

              toast.success("Payment is successfully made!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            } else {
              toast.error("Unable to charge your card at this moment!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            setPayingId("");
          });
        }
      });
    }

    const handleCopaymentAmount = (copaymentData: any) => {
      console.log("copaymentData", copaymentData)
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Client"} breadcrumbItem={"Premium Access"} />

            {!isLoading ? (
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="subscribe-section2">
                        <Tabs activeKey={activeKey} onChange={onTabsChange} tabPosition={isMobile ? "top" : "left"}>
                          <TabPane tab="Payment History" key="1">
                            <Row>
                              <Col sm="12">
                                <div className="table-responsive">
                                  {invoices && invoices !== undefined && invoices.length > 0 ? (
                                    <table className="table tableRes table-responsice">
                                      <thead className="thead-dark">
                                        <tr>
                                          <th scope="col">Id</th>
                                          <th scope="col">Month</th>
                                          <th scope="col">Insurance Payment</th>
                                          <th scope="col">Pending Payment</th>
                                          <th scope="col">Payment Status</th>
                                          <th scope="col">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {invoices &&
                                          invoices.map((invoice, index) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{invoice.paymentMonth}</td>
                                              <td>
                                                ${invoice.paidAmountByInsurance}
                                              </td>
                                              <td>
                                                ${invoice.dueAmount}
                                              </td>
                                              <td>
                                                {invoice.paymentStatus == PaymentStatus.PENDING ? (
                                                  <span className="payment-status-pending">Pending</span>
                                                ) : (
                                                  <span className="payment-status-paid ">Paid</span>
                                                )}
                                              </td>
                                              <td>
                                                {
                                                  invoice.paymentStatus == PaymentStatus.PENDING ? (
                                                    <button className="btn btn-sm btn-success" disabled={payingId == invoice._id ? true : false} onClick={() => {
                                                      makeMonthlyInvoicePayment(index, invoice);
                                                    }}>
                                                      {
                                                        payingId == invoice._id ? <>Paying</> : <>Pay now</>
                                                      }
                                                    </button>
                                                  ) : (
                                                    <>-</>
                                                  )}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <div className="no-appointments">
                                      No payment history.
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane tab="Manage Cards" key="2">
                            <Row>
                              <Col lg={6} className="payment-methods-sec">
                                <PremiumBankCard paymentData={paymentMethods} defaultPaymentMethodId={defaultPaymentMethodId} changeDefaultPaymentMethod={changeDefaultPaymentMethod} />
                              </Col>

                              <Col lg={6} className="add-payment-method">
                                <button className="btn btn-primary" data-value2={true} onClick={() => setShowAddCard(true)}>
                                  Add New Payment Method
                                </button>

                                {showAddCard && (
                                  <div className="mt60">
                                    <CardElement options={CARD_ELEMENT_OPTIONS} className="card-element" />

                                    <div className="pay-div float-right">
                                      {
                                        isPaymentMethodAdding ? (
                                          <Spinner className="bouncer2" />
                                        ) : (
                                          <Button className="mt-3 btn btn-info" disabled={isPaymentMethodAdding} variant="contained" color="primary" onClick={addNewPaymentMethod}>
                                            Add Card
                                          </Button>
                                        )
                                      }
                                    </div>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <div className={"d-flex w-100 justify-content-center align-items-center mt-3"}>
                                <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
                                  <img src={PoweredByStripeBadge} alt="Powered by Stripe" width={100}/>
                                </a>
                              </div>
                            </Row>
                          </TabPane>

                          <TabPane tab="Insurance Details" key="3">
                            <Container className="containerInsurance">
                              <div className="card-heading mb-2">
                                <h5 className="font-size-25 profileFont mb-4 mt-5 text-center">
                                  Insurance Information
                                </h5>
                              </div>
                              <div className="profileInsuranceContainer1">
                                <ProfileInsuranceDetails onCopaymentAmount={handleCopaymentAmount} />
                              </div>
                            </Container>
                          </TabPane>
                        </Tabs>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Spinner />
            )}

          </Container>
        </div>
      </React.Fragment>
    );
  };
export default PaymentHistory;
