import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useContext, useState } from "react";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import { CardTitle, Col, Row, Modal, CardBody, Input, Label, Button } from "reactstrap";
import GroupChatContext from "src/context/GroupChatContext";
import UserContext from "src/context/UserContext";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import { SessionsInSelectedChatGroupModel } from "src/models/GroupChat/SessionsInSelectedChatGroup";
import { GroupChatService } from "src/services/GroupChatService";
import Close from "../../../assets/images/icons/close.png";
import CustomTimePickerGroupChatSession from "src/pages/Chat/GroupChat/Common/CustomTimePickerGroupChatSession";

toast.configure();

interface UpdateGroupChatSessionModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  selectedSession: SessionsInSelectedChatGroupModel;
}

const UpdateGroupChatSessionModal: React.FC<UpdateGroupChatSessionModalProps> = ({
  showModal,
  setShowModal,
  selectedSession,
}: UpdateGroupChatSessionModalProps) => {
  const [user, setUser] = useContext(UserContext);
  const [selectedGroupData] = useContext(GroupChatContext);
  const now = new Date();
  const prevSelectedDateTime = new Date(selectedSession.start);
  const prevSelectedMomentString = moment(selectedSession.start).format();
  // const nowIndaysJs = dayjs();
  // const midnightToday = nowIndaysJs.startOf("day");
  const [isUpdatingSession, setIsUpdatingSession] = useState(false);
  const [timeValue, setTimeValue] = React.useState<string>(prevSelectedMomentString);
  const [dateValue, setDateValue] = React.useState<Date>(prevSelectedDateTime);
  const [isTimeSelected, setIsTimeSelected] = useState(false);

  const UpdateGroupChatSession = async () => {
    try {
      if (
        user?.role == "THERAPIST" &&
        user._id == selectedGroupData.createdBy &&
        user._id == selectedSession.createdBy &&
        selectedGroupData._id == selectedSession.groupId &&
        timeValue != null &&
        dateValue != null
      ) {
        if (!isUpdatingSession) {
          const selectedTime = timeValue;
          const finalStartTime = moment(selectedTime).format("H:mm A");
          const finalStartDate = moment(dateValue).format("YYYY-MM-DD");
          const combinedDateTime = moment(`${finalStartDate} ${finalStartTime}`, "YYYY-MM-DD H:mm A");

          // if (!isTimeSelected) {
          //   toast.error("Please select a time", {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //     className: "foo-bar",
          //   });
          //   return;
          // }

          if (combinedDateTime.minutes() !== 0 && combinedDateTime.minutes() !== 30) {
            toast.error("Please select a valid time", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return;
          }
          if (!combinedDateTime.isAfter(moment())) {
            toast.error("Please select upcoming date and time", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            return;
          }

          const sessionData = {
            sessionId: selectedSession._id,
            start: combinedDateTime,
          };
          setIsUpdatingSession(true);
          const res = await GroupChatService.updateGroupChatSession(sessionData);
          if (res && res.success) {
            setIsUpdatingSession(false);
            // try {
            //   if (
            //     sessionsInSelectedChatGroup != null &&
            //     sessionsInSelectedChatGroup.length != null &&
            //     sessionsInSelectedChatGroup.length >= 0 &&
            //     res.data != null
            //   ) {
            //     const newSession: SessionsInSelectedChatGroupModel = res.data;
            //     if (newSession != null && newSession._id != null && newSession._id != "") {
            //       const previousSessions: SessionsInSelectedChatGroupModel[] = sessionsInSelectedChatGroup;
            //       const newSessionsArray: SessionsInSelectedChatGroupModel[] = [newSession];
            //       const finalSessionsArray: SessionsInSelectedChatGroupModel[] = [...previousSessions, ...newSessionsArray];
            //       setSessionsInSelectedChatGroup(finalSessionsArray);
            //     }
            //   }
            // } catch (error) {}
            toast.success("Session updated successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setShowModal(true);
          } else {
            setIsUpdatingSession(false);
            toast.error("Failed to update session!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
          setIsUpdatingSession(false);
        } else {
          toast.error("Please wait, session creation in progress!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Please select valid data!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsUpdatingSession(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return showModal ? (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-body pt-1">
          {/* <button type="button" onClick={() => setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button> */}
          <Button
            close
            onClick={() => {
              if (!isUpdatingSession) {
                setShowModal(false);
              } else {
                toast.error("Please wait, session creation in progress!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            }}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
          >
            <img src={Close} alt="Close" className="" />
          </Button>
          <CardBody>
            <CardTitle className="mb-3 mt-3 text-left font-size-16 g-session-title2">Update Session</CardTitle>
            <Row>
              <Col>
                <Row className="d-flex justify-content-center align-items-center ">
                  <Col lg={6} md={6} sm={4} xs={12} className="mb-4 date-picker-container-g">
                    <DatePicker
                      minDate={now}
                      value={dateValue}
                      onChange={(date: Date) => {
                        setDateValue(date);
                      }}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={8} xs={12} className="mb-4">
                    <CustomTimePickerGroupChatSession selectedTimeValue={timeValue} setSelectedTimeValue={setTimeValue}></CustomTimePickerGroupChatSession>
                  </Col>
                </Row>

                <div className="mb-3">
                  <button className={isUpdatingSession ? "btn btn-secondary w-100" : "btn btn-primary w-100"} onClick={UpdateGroupChatSession}>
                    {isUpdatingSession ? "Loading..." : "Update"}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Modal>
    </>
  ) : null;
};

export default UpdateGroupChatSessionModal;
