import axios from 'axios';
import { TrainingDocument } from '../models/Document';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';

export class DocumentService {

    public static async createTrainingDocument(data: Partial<TrainingDocument>, fileToUpload: any): Promise<AppResponse<TrainingDocument>> {
        const url = Util.apiAuthUrl('add-training-doc');

        const formData: FormData = new FormData();

        formData.append("trainingDetails", JSON.stringify(data) as any);

        for (const file of fileToUpload) {
            formData.append("uploads", file);
        }

        return await axios.post<FormData, AppResponse<TrainingDocument>>(url, formData);
    }

    public static async updateTrainingDocument( data: Partial<TrainingDocument>, fileToUpload: any ): Promise<AppResponse<TrainingDocument>> {
        const url = Util.apiAuthUrl("update-training-doc");
        const formData: FormData = new FormData();
        formData.append("trainingDetails", JSON.stringify(data) as any);

        for (const file of fileToUpload) {
            formData.append("uploads", file);
        }

        return await axios.post<FormData, AppResponse<TrainingDocument>>(url, formData);
    }

    public static async getAllDocuments(limit?: number, offset?: number): Promise<AppResponse<TrainingDocument[]>> {
        const url = Util.apiAuthUrl("get-all-training-doc/" + limit + "/" + offset);
        return await axios.get<void, AppResponse<TrainingDocument[]>>(url);
    }

    public static async deleteDocument(tId: string): Promise<AppResponse<TrainingDocument>> {
        const url = Util.apiAuthUrl("delete-training-document-by-id/" + tId);
        return await axios.delete<Partial<TrainingDocument>, AppResponse<TrainingDocument>>(url);
    }

    public static async searchDocuments(searchableText: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl("search-documents/" + limit + "/" + offset);
    
        return await axios.post<Partial<TrainingDocument>, AppResponse<any>>(url, searchableText);
      }
      public static async searchDocumentsAdmin(searchableText: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl("search-documents-admin/" + limit + "/" + offset);
        return await axios.post<Partial<TrainingDocument>, AppResponse<any>>(url, searchableText);
      }
      public static async getDocument(tId: string): Promise<AppResponse<TrainingDocument>> {
        const url = Util.apiAuthUrl("get-training-document-by-id/" + tId);
        return await axios.delete<Partial<TrainingDocument>, AppResponse<TrainingDocument>>(url);
    }


}