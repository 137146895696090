import React, { useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Appointment, ApprovalStatus, ModalType, Repeat } from "../../models/Appointment";
import { AppointmentService } from "../../services/AppointmentService";
import moment from "moment";
import { BlockedDate, Therapist } from "../../models/Therapist";
import { TherapistService } from "../../services/TherapistService";
import user1 from "../../assets/images/default_profile.png";
import profileBackground from "../../assets/images/default_cover.png";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import { toast } from "react-toastify";
import { WorkingHour } from "../../models/WorkingHour";
import { SocketContext } from "../../context/ScoketContext";
import images from "../../assets/images";
import { Util } from "../../Util";
import "react-date-picker/dist/DatePicker.css";
import Swal from "sweetalert2";
import { NotificationService } from "src/services/NotificationService";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import BlockDatesModal from "../Popup/BlockDatesModal";
import AvailableDatesModal from "../Popup/AvailableDatesModal";
import { ClientService } from "src/services/ClientService";
import Modal4 from "../Popup/Modal4";
import { Client } from "src/models/Client";
import AppointmentModal from "../Popup/AppointmentModal";
import MessageModal from "../Popup/MessageModal";
import momentTimezone from "moment-timezone";
import { ChatGroupService } from "../../services/ChatGroupService";

interface AllAppointmentProps {
  calendar: boolean;
}

const AppointmentCalendarTherapist: React.FC<AllAppointmentProps> = ({ calendar }) => {
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<any>();
  const [user, setUser] = useContext(UserContext);
  const calendarComponentRef: any = React.createRef();
  const [isEventViewModal, setIsEventViewModal] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [appointments, setAppointments] = useState([] as any[]);
  const [blockDatesList, setBlockDatesList] = useState([] as any[]);
  const [newBlockDates, setNewBlockDates] = useState([] as any[]);
  const [dateSelectEnable, setDateSelectEnable] = useState<boolean>(false);
  const [blockDateSelectEnable, setBlockDateSelectEnable] = useState<boolean>(false);
  const [availableDateSelectEnable, setAvailableDateSelectEnable] = useState<boolean>(false);
  const socket = useContext(SocketContext);
  const [errorModalText, setErrorModalText] = useState("");
  const [workingDaysOfTherapist, setWorkingDaysOfTherapist] = useState<string[]>([]);
  const [therapist, setTherapist] = useState({} as Therapist);
  const [appointmentDetails, setAppointmentDetails] = useState<Appointment>();
  const [timeList, setTimeList] = useState<string[]>([]);
  const [therapistAvailableHours, setTherapistAvailableHours] = useState([] as any);
  const [availableHours, setAvailableHours] = useState([] as any);
  const [availableHours12HourList, setAvailableHours12HourList] = useState([] as any);
  const [showBlockedDatesPanel, setShowBlockedDatesPanel] = useState<boolean>(false);
  const [showAvailableDatesPanel, setShowAvailableDatesPanel] = useState<boolean>(false);
  const [workingHoursAvailable, setWorkingHoursAvailable] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSavingAvailableTimes, setIsSavingAvailableTimes] = useState<boolean>(false);
  const [currentSavingBlockedDate, setCurrentSavingBlockedDate] = useState("");
  const [currentSavingAvailableDate, setCurrentSavingAvailableDate] = useState({} as any);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [isEventModal, setIsEventModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: "",
  });
  const [groupCalls, setGroupCalls] = useState<any[]>([]);

  function getCurrentHour() {
    const now = new Date();
    return `${now.getHours()}:00:00`;
  }

  const locale = "en";
  const hours: string[] = [];

  useEffect(() => {
    setUserId(user?._id);

    const userID = user?._id;

    if (userID) {
      setIsFriend(true);

      if (user?.role == Role.THERAPIST) {
        getAllAppointmentByTherapist(userID);
      }

      createHoursForTimeSlots();
    }
  }, []);

  const createHoursForTimeSlots = () => {
    moment.locale(locale);

    for (let hour = 0; hour < 24; hour++) {
      hours.push(moment({ hour }).format("H:mm A"));
      hours.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }

    setTimeList(hours);
  };

  const getAllAppointmentByTherapist = async (userID: string) => {
    try {
      const therapistResponse = await TherapistService.getTherapistDetailsbyId(userID);
      
      if (therapistResponse.success) {
        setIsLoading(false);
        setTherapist(therapistResponse.data);
        checkAvailableTime(therapistResponse.data?.workingHours);
        getWorkingDaysOfWeek(therapistResponse.data?.workingHours);
        const blockedSlots = therapistResponse.data?.blockedDates || [];

        // Lấy cuộc hẹn thông thường
        const appointmentsResponse = await AppointmentService.getAppointmentsByTherapist();
        const appointmentArr = appointmentsResponse.data;

        const newAppointments = appointmentArr?.map(appointment => ({
          ...appointment,
          title: `${moment(appointment.start).format("YYYY/MM/DD")} ${
            appointment?.clientId?.subscriptionStatus == "active" || appointment?.clientId?.premiumStatus == "active" ? "✅" : "⚠️"
          }<br>With ${appointment?.clientId?.firstname} ${appointment?.clientId?.lastname}`,
        }));

        const newBlockedDates = blockedSlots?.map(bs => ({
          ...bs,
          extendedProps: { type: "BLOCKED_EVENT", start: bs.start, end: bs.end },
        }));

        // Lấy cuộc gọi nhóm
        const groupCallsResponse = await ChatGroupService.getAllChatGroupCallsByTherapistId(userID, 1000, 0);
        
        let formattedGroupCalls: any[] = [];
        if (groupCallsResponse.success) {
          formattedGroupCalls = groupCallsResponse.data.sessions.map((session: any) => {
            // Thời gian kết thúc mặc định sau 1 giờ nếu không có end time
            const endTime = session.end ? session.end : moment(session.start).add(1, 'hour').toISOString();
            
            return {
              ...session,
              id: session._id,
              title: `${moment(session.start).format("YYYY/MM/DD")}<br>Group session: ${session.groupDetails.title}`,
              start: session.start,
              end: endTime,
              extendedProps: { 
                type: "GROUP_CALL", 
                _id: session._id,
                groupId: session.groupId,
                groupDetails: session.groupDetails 
              },
              backgroundColor: "#4e3fb1", // Màu khác để phân biệt
              borderColor: "#4e3fb1"
            };
          });
          
          setGroupCalls(formattedGroupCalls);
        }

        // Gộp tất cả sự kiện vào appointments
        const allEvents = [...newAppointments, ...newBlockedDates, ...formattedGroupCalls];
        setAppointments(allEvents);
        setBlockDatesList(newBlockedDates);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setIsLoading(false);
      toast.error("Có lỗi khi tải dữ liệu lịch.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  const eventViewModalToggle = () => {
    setIsEventViewModal(!isEventViewModal);
  };

  const selectBlockAvailableDates = async (selectedDatesList: any) => {
    if (blockDateSelectEnable) {
      const selectedDate = moment(selectedDatesList.startStr).toISOString();

      const isInRange = blockDatesList.some(range => {
        return Util.isDateInBlockedRangeTherapistAppointments(moment(range.start), moment(range.end), moment(selectedDate));
      });

      if (isInRange) {
        return false;
      }

      const blockDatesRange: BlockedDate = {
        title: "Not available.",
        start: selectedDatesList.startStr,
        end: selectedDatesList.endStr,
        display: "background",
        className: "fc-nonbusiness disabled",
        extendedProps: { type: "BLOCKED_EVENT", start: selectedDatesList.startStr, end: selectedDatesList.endStr },
      };

      const appointmentsAvailable = await TherapistService.validateAppoimentsAvailable({ blockDatesRange: blockDatesRange });

      if (appointmentsAvailable.data.length > 0) {
        return toast.error("Sorry! Already scheduled appointments during the time range you picked.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        setNewBlockDates(newBlockDates => [...newBlockDates, blockDatesRange]);
        setBlockDatesList(blockDatesList => [...blockDatesList, blockDatesRange]);
        setAppointments(appointments => [...appointments, blockDatesRange]);
      }
    } else {
      const startDay = moment(selectedDatesList.startStr);
      const endDay = moment(selectedDatesList.endStr);

      const availableDays: { startTime: any; endTime: any; daysOfWeek: any[]; day: string }[] = [];
      const availableDays12Hour: { startTime: any; endTime: any; daysOfWeek: any[]; day: string }[] = [];
      const availableDays24hourUTC: { startTime: any; endTime: any; day: string }[] = [];

      if (startDay.day() == endDay.day()) {
        const utcStartTime = moment(startDay, "h:mm A").utc().format("H:mm A");
        const utcEndTime = moment(endDay, "h:mm A").utc().format("H:mm A");

        availableDays.push({
          startTime: startDay.format("HH:mm A"),
          endTime: endDay.format("HH:mm A"),
          daysOfWeek: [startDay.day()],
          day: getDayName(startDay.day()),
        });

        availableDays12Hour.push({
          startTime: startDay.format("HH:mm A"),
          endTime: endDay.format("HH:mm A"),
          daysOfWeek: [startDay.day()],
          day: getDayName(startDay.day()),
        });

        availableDays24hourUTC.push({
          startTime: utcStartTime,
          endTime: utcEndTime,
          day: getDayName(startDay.day()),
        });

        if (!workingHoursAvailable) {
          setTherapistAvailableHours(availableDays);
          setWorkingHoursAvailable(true);
        } else {
          setTherapistAvailableHours((therapistAvailableHours: any) => [...therapistAvailableHours, availableDays[0]]);
        }

        const newArrayList1 = availableHours12HourList.concat(availableDays12Hour[0]);
        const newArrayList2 = availableHours.concat(availableDays24hourUTC[0]);

        setAvailableHours12HourList(newArrayList1.sort(customSort));
        setAvailableHours(newArrayList2.sort(customSort));
      } else {
        return toast.error("Please select time ranges within the same day.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };

  const viewEvent = (event: any) => {
    const eventType = event.event._def.extendedProps.type;

    if (eventType === "BLOCKED_EVENT") {
      Swal.fire({
        icon: "warning",
        title: "Are you sure want to remove this blocked date?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          deleteBlockDateRange(event.event._def.extendedProps.start, event.event._def.extendedProps.end);
        }
      });
    } else if (eventType === "GROUP_CALL") {
      // Xử lý khi click vào cuộc gọi nhóm
      Swal.fire({
        icon: "info",
        title: "Group Session Information",
        html: `
          <div>
            <p><strong>Group:</strong> ${event.event._def.extendedProps.groupDetails.title}</p>
            <p><strong>Description:</strong> ${event.event._def.extendedProps.groupDetails.description || 'N/A'}</p>
            <p><strong>Type:</strong> ${event.event._def.extendedProps.groupDetails.type}</p>
            <p><strong>Start Time:</strong> ${moment(event.event.start).format('YYYY/MM/DD HH:mm')}</p>
          </div>
        `,
        confirmButtonColor: "#50a5f1",
      });
    } else {
      const appointmentId = event.event._def.extendedProps._id;

      if (!appointmentId) {
        return false;
      }

      AppointmentService.viewSingleAppointmentPublic(appointmentId).then((res: any) => {
        if (res.success) {
          setAppointmentDetails(res.data);
          setIsEventViewModal(true);
        } else {
          setErrorModalText(res.error);
          setAppointmentDetails(undefined);
          setEventTimeValidationViewModal(true);
        }
      });
    }
  };

  const getWorkingDaysOfWeek = (workingHours: any) => {
    const workingDays: string[] = [];

    workingHours?.map((session: any) => {
      if (!workingDays.includes(session.day)) workingDays.push(session.day);
    });

    const dup = [...new Set(workingDays)];

    setWorkingDaysOfTherapist(dup);
  };

  const checkAvailableTime = (workingHoursOfTherapist: WorkingHour[] | undefined) => {
    const availableDays: { startTime: any; endTime: any; daysOfWeek: any[]; day: string }[] = [];
    const availableDays12Hour: { startTime: any; endTime: any; daysOfWeek: any[]; day: string }[] = [];

    if (workingHoursOfTherapist?.length) {
      workingHoursOfTherapist?.map((obj: any, i: number) => {
        const dayAsNumber = dayOfWeekAsNumber(obj.day);

        availableDays.push({
          startTime: Util.convertUTCDateToLocalDate(obj.startTime),
          endTime: Util.convertUTCDateToLocalDate(obj.endTime),
          daysOfWeek: [dayAsNumber],
          day: obj.day,
        });

        availableDays12Hour.push({
          startTime: Util.convertUTCDateToLocalDate12Hour(obj.startTime),
          endTime: Util.convertUTCDateToLocalDate12Hour(obj.endTime),
          daysOfWeek: [dayAsNumber],
          day: obj.day,
        });
      });

      const sortedList1 = workingHoursOfTherapist.sort(customSort);
      const sortedList2 = availableDays12Hour.sort(customSort);

      setWorkingHoursAvailable(true);
      setAvailableHours(sortedList1);
      setAvailableHours12HourList(sortedList2);
    } else {
      availableDays.push({
        startTime: "00:00 AM",
        endTime: "00:00 PM",
        daysOfWeek: [10],
        day: "Monday",
      });

      setWorkingHoursAvailable(false);
    }

    setTherapistAvailableHours(availableDays);
  };

  const customSort = (a: any, b: any) => {
    const daysOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  };

  const dayOfWeekAsNumber = (day: string) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
  };

  const getDayName = (index: number) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][index];
  };

  const updateBlockDates = () => {
    setDateSelectEnable(true);
    setBlockDateSelectEnable(true);
  };

  const syncWithGoogle=()=>{
    console.log("syncWithGoogle");
  }

  const updateAvailableDates = () => {
    setDateSelectEnable(true);
    setAvailableDateSelectEnable(true);
  };

  const saveBlockedDates = async () => {
    const savedBlockedDates = await TherapistService.updateBlockedDates({
      newBlockDates: newBlockDates,
      blockedDates: blockDatesList,
    });

    if (savedBlockedDates.success) {
      // // // setAppointments(appointments => [...appointments, newBlockDates]);

      setAppointments(appointments => [...appointments, ...newBlockDates.filter(date => date)]);

      setDateSelectEnable(false);
      setBlockDateSelectEnable(false);
      setAvailableDateSelectEnable(false);

      if (newBlockDates.length > 0) {
        setNewBlockDates([]);

        return toast.success("Blocked dates are saved successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } else {
      return toast.error(savedBlockedDates.error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const saveAvailableDates = async () => {
    setDateSelectEnable(false);
    setBlockDateSelectEnable(false);
    setAvailableDateSelectEnable(false);

    const sortedAvailableHours = Util.mergeOverlappedWorkingHours(availableHours);
    const savedTherapist = await TherapistService.updateTherapistAvailableTimes({ workingHours: sortedAvailableHours });

    if (savedTherapist.success) {
      return toast.success("Available times are updated successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      return toast.error("Sorry, Unable to save your working hours.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const deleteBlockDateRange = async (deleteBlockStart: string, deleteBlockEnd: string) => {
    setIsSaving(true);
    setCurrentSavingBlockedDate(deleteBlockStart);

    const newBlockedDates = blockDatesList.filter(prevItem => prevItem.start !== deleteBlockStart || prevItem.end !== deleteBlockEnd);

    const savedBlockedDates = await TherapistService.updateBlockedDates({ blockedDates: newBlockedDates });

    if (savedBlockedDates.success) {
      setBlockDatesList(prevState => prevState.filter(prevItem => prevItem.start !== deleteBlockStart || prevItem.end !== deleteBlockEnd));

      setAppointments(prevState =>
        prevState.filter(prevItem => prevItem.start !== deleteBlockStart || prevItem.end !== deleteBlockEnd || prevItem.className !== "fc-nonbusiness disabled")
      );

      setIsSaving(false);
      setCurrentSavingBlockedDate("");
    } else {
      return toast.error("Sorry, Unable to delete block date range.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const deleteAvailableDateRange = async (availableDateObj12Hr: any) => {
    setIsSavingAvailableTimes(true);

    const filterCondition = (element: any) =>
      element.startTime == availableDateObj12Hr.startTime && element.endTime == availableDateObj12Hr.endTime && element.day == availableDateObj12Hr.day;

    const index = availableHours12HourList.findIndex(filterCondition);

    const availableDateObj = availableHours[index];

    setCurrentSavingAvailableDate(availableDateObj12Hr);

    const newAvailaledDatesDB = availableHours.filter((prevItem: any) => {
      return prevItem.startTime != availableDateObj.startTime || prevItem.endTime != availableDateObj.endTime || prevItem.day != availableDateObj.day;
    });

    const savedAvailableDates = await TherapistService.updateTherapistAvailableTimes({ workingHours: newAvailaledDatesDB });

    if (savedAvailableDates.success) {
      setTherapistAvailableHours((prevState: any) =>
        prevState.filter(
          (prevItem: any) =>
            prevItem.startTime != Util.convertUTCDateToLocalDate(availableDateObj.startTime) ||
            prevItem.endTime != Util.convertUTCDateToLocalDate(availableDateObj.endTime) ||
            prevItem.day != availableDateObj.day
        )
      );

      setAvailableHours((prevState: any) =>
        prevState.filter(
          (prevItem: any) =>
            prevItem.startTime != availableDateObj.startTime || prevItem.endTime != availableDateObj.endTime || prevItem.day != availableDateObj.day
        )
      );

      setAvailableHours12HourList((prevState: any) =>
        prevState.filter(
          (prevItem: any) =>
            prevItem.startTime != availableDateObj12Hr.startTime || prevItem.endTime != availableDateObj12Hr.endTime || prevItem.day != availableDateObj12Hr.day
        )
      );

      if (newAvailaledDatesDB.length == 0) {
        const availableDays: { startTime: any; endTime: any; daysOfWeek: any[]; day: string }[] = [];

        availableDays.push({
          startTime: "00:00 AM",
          endTime: "00:00 PM",
          daysOfWeek: [10],
          day: "Monday",
        });

        setTherapistAvailableHours(availableDays);
        setWorkingHoursAvailable(false);
      }
    } else {
      return toast.error("Sorry, Unable to delete available time range.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const deleteAppointment = (appointment: any) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
      allowOutsideClick: screenSize < 991 ? false : true,
    }).then(result => {
      if (result.isConfirmed) {
        const deletingAppointment = {
          appointmentId: appointment?._id,
        };

        AppointmentService.deleteAppointment(deletingAppointment).then(res => {
          if (res.success) {
            eventViewModalToggle();
            getAllAppointmentByTherapist(userId);

            toast.success("Your appointment is deleted successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            const appointmentNotification: NotificationModel = {
              senderId: appointment?.therapistId?._id,
              receiverId: appointment?.clientId?._id,
              event: NotificationEvent.APPOINMENT_DELETED,
              link: "/appointments",
              content: "Appointment deleted by " + appointment?.therapistId?.firstname + " " + appointment?.therapistId?.lastname,
              variant: NotificationVarient.WARNING,
            };

            NotificationService.sendNotification(appointmentNotification);
          } else {
            Swal.fire({
              icon: "error",
              title: res.error ? res.error : "Something went wrong. Please try again later.",
              confirmButtonColor: "#FD7F00",
              allowOutsideClick: screenSize < 991 ? false : true,
            });
          }
        });
      }
    });
  };

  const editAppointment = async (data: Appointment | undefined) => {
    if (data && user && user.role == Role.THERAPIST) {
      const res = await ClientService.getClientByClientId(data.clientId._id!);

      if (res.success) {
        if (
          res.data.premiumStatus != "active" &&
          (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
          res.data.testSubscriptionStatus != "active"
        ) {
          return setShowModalForTherapist(true);
        } else {
          getWorkingDaysOfWeek(data?.therapistId.workingHours);

          setAppointment({
            ...appointment,
            id: data._id || "",
            title: data.title,
            startTime: moment(data.start).format("H:mm A"),
            endTime: moment(data.end).format("H:mm A"),
            date: moment(data.start).format("YYYY-MM-DD HH:mm"),
            repeat: (data.repeatInfo?.repeatType as Repeat) || Repeat.DOES_NOT_REPEAT,
            color: data.color,
            meetingType: data.typeOfMeeting,
            therapist: data?.therapistId,
            client: data.clientId,
            selectedDate: moment(data.start).toISOString(),
          });

          setTimeValue(data.start.toString());
          setHourValue(moment(data.start).hour());
          setMinuteValue(moment(data.start).minute());

          setIsEventModal(!isEventModal);
        }
      } else {
        return toast.error("Invalid client id." + data.clientId._id!, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };

  const changeUpdatedAppointment = (updatedAppointment: any) => {
    setAppointmentDetails({
      ...appointmentDetails!,
      start: updatedAppointment.start,
      end: updatedAppointment.end,
      color: updatedAppointment.color,
    });

    getAllAppointmentByTherapist(userId);
  };

  return (
    <React.Fragment>
      {showBlockedDatesPanel && (
        <BlockDatesModal
          blockDatesList={blockDatesList}
          showModal={showBlockedDatesPanel}
          setShowModal={setShowBlockedDatesPanel}
          deleteBlockDateRange={deleteBlockDateRange}
          isSaving={isSaving}
          currentSavingBlockedDate={currentSavingBlockedDate}
        />
      )}

      {showAvailableDatesPanel && (
        <AvailableDatesModal
          availableDatesList={therapistAvailableHours}
          availableHours12HourList={availableHours12HourList}
          setShowModal={setShowAvailableDatesPanel}
          showModal={showAvailableDatesPanel}
          deleteAvailableTimeRange={deleteAvailableDateRange}
          isSavingAvailableTimes={isSavingAvailableTimes}
          currentSavingObj={currentSavingAvailableDate}
        />
      )}

      <div className="pt-2">
        <Container fluid={true}>
          {isLoading && (
            <div className="flex justify-content-center mt-5">
              <img src={images.cubicLoader} style={{ width: "90px" }} />
            </div>
          )}

          {!isLoading && (
            <>
              <Row>
                <Col lg={12} sm={12}>
                  <Row>
                    <div className="dates-section">
                      <div className="avialble-times me-1">
                        {availableDateSelectEnable ? (
                          <button className="btn available-times-btn-enabled" onClick={() => saveAvailableDates()}>
                            Save Changes
                          </button>
                        ) : (
                          <>
                            <button className="btn available-times-btn" onClick={() => updateAvailableDates()}>
                              Available Times
                            </button>

                            {
                              /*    <div className="remove-blocked-dates ms-1">
                                    <div
                                      className={"btn btn-secondary"}
                                      onClick={() => {
                                        setShowAvailableDatesPanel(true);
                                      }}
                                    >
                                      <i className="bx bx-show fs-5"></i>
                                    </div>
                                  </div>*/
                              // <div className="remove-blocked-dates ms-1">
                                <button
                                  className="btn btn-secondary ms-1"
                                  onClick={() => {
                                    setShowAvailableDatesPanel(true);
                                  }}
                                >
                                  Delete Available Times
                                </button>
                              // </div>
                            }
                          </>
                        )}
                      </div>

                      <div className="block-dates">
                        {blockDateSelectEnable ? (
                          <button className="btn block-dates-btn-enabled" onClick={() => saveBlockedDates()}>
                            Save Changes
                          </button>
                        ) : (
                          <>
                            <button className="btn block-dates-btn" onClick={() => updateBlockDates()}>
                              Block Dates
                            </button>
                          </>
                        )}
                      </div>
                      {/* <div className={"block-dates"}>
                        <button
                          className="btn sync-with-google ms-1"
                          onClick={() => {
                            syncWithGoogle();
                          }}
                        >
                          Sync With Google
                        </button>
                      </div> */}
                    </div>
                  </Row>

                  <FullCalendar
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    slotMinTime={15}
                    scrollTime={getCurrentHour()}
                    editable={false}
                    slotDuration={"00:30:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    eventTimeFormat={{
                      hour12: false,
                    }}
                    firstDay={dayOfWeekAsNumber(moment(new Date()).format("dddd"))}
                    initialView="timeGridWeek"
                    selectLongPressDelay={-1}
                    longPressDelay={-1}
                    eventLongPressDelay={-1}
                    // events={appointments}
                    events={appointments.map(appointment => {
                      // const startMoment = momentTimezone(appointment.start);
                      // const endMoment = momentTimezone(appointment.end);

                      const startMoment = momentTimezone(appointment.start).tz("America/New_York");
                      const endMoment = momentTimezone(appointment.end).tz("America/New_York");
                      
                      // const startOffset = startMoment.isDST() ? -4 * 60 : -5 * 60;
                      // const endOffset = endMoment.isDST() ? -4 * 60 : -5 * 60;
                      return {
                        ...appointment,
                        // start: startMoment.utcOffset(startOffset, true).toDate(),
                        // end: endMoment.utcOffset(endOffset, true).toDate(),
                        start: startMoment.toDate(),
                        end: endMoment.toDate()
                      };
                    })}
                    droppable={false}
                    selectable={dateSelectEnable}
                    eventClick={viewEvent}
                    ref={calendarComponentRef}
                    select={(e: any) => selectBlockAvailableDates(e)}
                    businessHours={therapistAvailableHours}
                    // eventContent={(arg) => {
                    //   return { html: `<span>${arg.event.title}</span>` }; // Ensures only title is displayed
                    // }}
                    eventContent={(arg) => {
                      return (
                        <div 
                          className="event-content" 
                          style={{pointerEvents: 'none'}}
                          dangerouslySetInnerHTML={{ __html: arg.event.title }}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>

      <AppointmentModal
        clientId={appointment.client._id}
        appointment={appointment}
        modalType={ModalType.EDIT}
        showModal={isEventModal}
        setShowModal={setIsEventModal}
        setAppointment={setAppointment}
        errorModalText={errorModalText}
        setErrorModalText={setErrorModalText}
        setShowErrorModal={setShowErrorModal}
        setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
        setShowModalForTherapist={setShowModalForTherapist}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        updateMatchedClient={() => {}}
        hourValue={hourValue}
        setHourValue={setHourValue}
        minuteValue={minuteValue}
        setMinuteValue={setMinuteValue}
        updateAppointmentObject={changeUpdatedAppointment}
      />

      {showErrorModal && (
        <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
      )}

      {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}

      <Modal isOpen={isEventViewModal} centered toggle={eventViewModalToggle}>
        <ModalHeader toggle={eventViewModalToggle}>
          Session with client {appointmentDetails?.clientId?.firstname} {appointmentDetails?.clientId?.lastname}
        </ModalHeader>

        <ModalBody>
        {user?.role == Role.THERAPIST && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="col-xl-12">
                      <div className="row">
                        <div className="col-xl-12">
                          <Row>
                            <div
                              className="profileImage-appoint imageFit BorderRadiusLeftRight"
                              style={{
                                backgroundImage:
                                  appointmentDetails?.clientId?.coverPhotoId == null || appointmentDetails?.clientId?.coverPhotoId == undefined
                                    ? `url(${profileBackground})`
                                    : `url("${Util.fileURL(appointmentDetails?.clientId?.coverPhotoId?._id)}")`,
                              }}
                            ></div>
                            <Row>
                              <Col xl={4}>
                                <div
                                  className="img-thumbnail imageFit rounded-circle imageFit avatar-md profile-user-wid-pro-appoint items-center mb-4 profileImageShow"
                                  style={{
                                    backgroundImage:
                                      appointmentDetails?.clientId?.photoId == null || appointmentDetails?.clientId?.photoId == undefined
                                        ? `url(${user1})`
                                        : `url("${Util.fileURL(appointmentDetails?.clientId?.photoId?._id)}")`,
                                    borderRadius: "10px",
                                    position: "relative",
                                  }}
                                ></div>
                              </Col>
                              <Col xl={8}>
                                <span className="appointment-profile-name2">
                                  {appointmentDetails?.clientId?.firstname} {appointmentDetails?.clientId?.lastname}
                                </span>
                              </Col>
                            </Row>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row className="mb-2">
            <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-time-five"></i>
            </Col>
            <Col lg={5} md={5} sm={5} xs={12}>
              <h5>
                {moment(appointmentDetails?.start).format("dddd")}, {moment(appointmentDetails?.start).format("YYYY/MM/DD")}
              </h5>
            </Col>
            <Col lg={3} md={3} sm={3} xs={12}>
              <h5>{moment(appointmentDetails?.start).format("h:mm A")}</h5>
            </Col>
            <Col lg={3} md={3} sm={3} xs={12}>
              <h5>{moment(appointmentDetails?.end).format("h:mm A")}</h5>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className={Util.meetingTypeIcon(appointmentDetails?.typeOfMeeting)}></i>
            </Col>
            <Col>
              <h5>{appointmentDetails?.typeOfMeeting}</h5>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className={Util.meetingRepeatTypeIcon(appointmentDetails?.repeatInfo?.repeatType)}></i>
            </Col>
            <Col>
              <h5>{appointmentDetails?.repeatInfo?.repeatType}</h5>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-palette"></i>
            </Col>
            <Col>
              <div
                className="color-palet"
                style={{ backgroundColor: appointmentDetails?.therapistId?._id == user?._id ? appointmentDetails?.color : "#665a5a" }}
              ></div>
            </Col>
          </Row>

          {appointmentDetails?.clientId?.premiumStatus != "active" && appointmentDetails?.clientId?.subscriptionStatus != "active" && (
            <>
              <Row className="mb-4">
                <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
                  ⚠️
                </Col>
                <Col>This client&apos;s insurance information is missing or they did not submit yet!</Col>
              </Row>
            </>
          )}

          {appointmentDetails?.therapistId?._id === userId && user?.role == Role.THERAPIST && (
            <>
              {appointmentDetails?.approvedStatus == ApprovalStatus.PENDING ? (
                <Row>
                  <Col className="appointment-status">
                    <span className="text-warning">Pending Approval!</span>
                  </Col>

                    <Col>
                      <div className="btn-flex">
                        <button className="btn btn-sm btn-success m-2" onClick={() => editAppointment(appointmentDetails)}>
                          <i className="bx bx-edit"></i> &nbsp; Reschedule
                        </button>

                      <button className="btn btn-sm btn-danger m-2" onClick={() => deleteAppointment(appointmentDetails)}>
                        <i className="bx bx-trash"></i> &nbsp; Delete
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : appointmentDetails?.approvedStatus == ApprovalStatus.REJECTED ? (
                <Row>
                  <Col className="appointment-status">
                    <span className="text-danger">Appointment Rejected!</span>
                  </Col>

                  <Col>
                    <div className="btn-flex">
                      <button className="btn btn-sm btn-danger m-2" onClick={() => deleteAppointment(appointmentDetails)}>
                        <i className="bx bx-trash"></i> &nbsp; Delete
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="appointment-status">
                    <span className="text-danger">Appointment Approved!</span>
                  </Col>

                    <Col>
                      <div className="btn-flex">
                        <button className="btn btn-sm btn-success m-2" onClick={() => editAppointment(appointmentDetails)}>
                          <i className="bx bx-edit"></i> &nbsp; Reschedule
                        </button>

                      <button className="btn btn-sm btn-danger m-2" onClick={() => deleteAppointment(appointmentDetails)}>
                        <i className="bx bx-trash"></i> &nbsp; Delete
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AppointmentCalendarTherapist;
