import moment from "moment";
import Linkify from "react-linkify";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import parse from "html-react-parser";
import React from "react";
import { useContext } from "react";
import UserContext from "src/context/UserContext";
import { toast } from "react-toastify";
import { Util } from "src/Util";
import { ClientChatMessageModel } from "src/models/AdminClientChat/ClientChatMessage";
import { decryptAdminClientMessage } from "src/pages/Chat/GroupChat/Common/AdminClientChatEncryption";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  clientMessageFrom: ClientChatMessageModel;
  openReplyPanel: (messageDetails: ClientChatMessageModel) => void;
}

const DisplayClientChatMessageComp = ({ clientMessageFrom, openReplyPanel }: Props) => {
  const [user, setUser] = useContext(UserContext);

  const copyToClipboard = async (textFrom: string) => {
    try {
      const linkifyElement = document.getElementById(`text-component-${clientMessageFrom._id}`);

      if (linkifyElement) {
        const plainText = linkifyElement.innerText;

        if ("clipboard" in navigator) {
          return await navigator.clipboard.writeText(plainText);
        } else {
          return document.execCommand("copy", true, plainText);
        }
      }
    } catch (error) {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }
  };

  const handleCopy = (text: string) => {
    const copiedTextInString = text;

    if (copiedTextInString != undefined || copiedTextInString != null || copiedTextInString != "") {
      copyToClipboard(copiedTextInString).then(() => {
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
    } else {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  return (
    <li
      key={clientMessageFrom._id}
      className={
        clientMessageFrom.createdBy == user?._id || clientMessageFrom.createdBy == "SUPER_ADMIN" ? "right bg-light break-word-show-msg" : "break-word-show-msg"
      }
    >
      <div className="conversation-list">
        <UncontrolledDropdown>
          <DropdownToggle href="#" className="btn nav-btn" tag="i">
            <i className="bx bx-dots-vertical-rounded" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                handleCopy(clientMessageFrom.messageText ? decryptAdminClientMessage(clientMessageFrom.messageText) : "");
              }}
            >
              Copy
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                openReplyPanel(clientMessageFrom);
                null;
              }}
            >
              Reply
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <div
          className={`ctext-wrap ${
            clientMessageFrom.createdBy == user?._id || clientMessageFrom.createdBy == "SUPER_ADMIN" ? "bg-secondary bg-soft" : ""
          } text-start`}
        >
          <div className="conversation-name">
            {clientMessageFrom.createdBy == user?._id || clientMessageFrom.createdBy == "SUPER_ADMIN" ? "You" : clientMessageFrom.firstname ?? "Client"}
          </div>

          <div id={`text-component-${clientMessageFrom._id}`}>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a className="chat-link text-info" target="blank" style={{ textDecoration: "underline" }} rel="noopener" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {clientMessageFrom.messageText ? parse(decryptAdminClientMessage(clientMessageFrom.messageText)) : null}
            </Linkify>
          </div>

          <p className="chat-time mb-0 align-items-center d-flex mt-0">
            {`${moment(clientMessageFrom.createdAt).fromNow()} ${
              clientMessageFrom.messageStatus != null ? "( " + clientMessageFrom.messageStatus + " )" : null
            }`}
          </p>
        </div>
      </div>
    </li>
  );
};

export default DisplayClientChatMessageComp;
