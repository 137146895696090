import React, { useEffect, useRef, useState, useContext } from "react";
import { Row, Col, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { ClientService } from "../../services/ClientService";
import { AddressModel, DependentModel, Insurance, InsuranceCompanyModel, SubscriberModel } from "../../models/Insurance";
import "../../assets/css/admin.css";
import moment from "moment";
import Spinner from "../../common/spinner/spinner";
import { Upload } from "../../models/Upload";
import SessionHistoryModalForAdmin from "../Popup/SessionHistoryModalForAdmin";
import ViewEligibilityModal from "../Popup/ViewEligibilityModal";
import InsuranceClaimModal from "../Popup/InsuranceClaimModal";
import ClaimStatusModal from "../Popup/ClaimStatusModal";
import { Util } from "../../Util";
import TradingParterModal from "../Popup/TradingParterModal";
import Select from "react-select";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "src/models/InsuranceCompany";
import { toast } from "react-toastify";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";
import { Client } from "src/models/Client";
import UserContext from "src/context/UserContext";
import Swal from "sweetalert2";
import { Slider } from "@material-ui/core";
import Cropper from "react-easy-crop";
import getCroppedInsuranceImgAsFile from "./cropInsuranceImage";
import compressPdf from "./compressPdf";
import pdfIcon from "../../assets/images/pdf_file_icon.png";
import { fileTypeFromBlob } from 'file-type';

interface ViewClientInsuranceProps {
  userId: string;
  clientSingleInsuranceData: any;
  onInsuranceUpdated: (insuranceInfo: any) => void;
  onCopaymentAmount: (copaymentInfo: any) => void;
  clientData?: Client;
  onClientUpdated: (clientInfo: any) => void;
  clientIsuranceList?: any[];
}

interface InsuranceCardState {
  preview: string;
  preview2: File | string;
  raw: File[] | string;
}

const ViewInsuranceByClient: React.FC<ViewClientInsuranceProps> = ({ userId, clientSingleInsuranceData, onInsuranceUpdated, onCopaymentAmount, clientData, onClientUpdated, clientIsuranceList }) => {
  const [user, setUser] = useContext(UserContext);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [insuranceDetails, setInsuranceDetails] = useState<Insurance>({} as Insurance);
  const [clientDetails, setClientDetails] = useState<Client>({} as Client);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showNote, setShowNote] = useState(false);
  const [noteId, setNoteId] = useState<string>("");
  const [showEligibilityModal, setShowEligibilityModal] = useState<boolean>(false);
  const [showClaimModal, setShowClaimModal] = useState<boolean>(false);
  const [showClaiStatusmModal, setShowClaimStatusModal] = useState<boolean>(false);
  const [tradingServiceId, setTradingServiceId] = useState<string>("");
  const [showIdModal, setShowIdModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState("");
  const { activeTab } = useParams<ParameterTypes>();
  const [editProfile, setShowEditProfile] = useState(false);
  const [selecteInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag>({} as SelectedInsuranceCompanyTag);
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [insuranceCard, selectInsuranceCard] = useState<InsuranceCardState>({ preview: "", preview2: "", raw: "", });
  const insuranceCardRef = useRef<any>();
  insuranceCardRef.current = insuranceCard;
  const [insuranceCardBack, selectInsuranceCardBack] = useState<InsuranceCardState>({ preview: "", preview2: "", raw: "", });
  const insuranceCardBackRef = useRef<any>();
  insuranceCardBackRef.current = insuranceCardBack;
  const [subscriberData, setSubscriberData] = useState<SubscriberModel>({} as SubscriberModel);
  const [dateValue, setDateValue] = useState(null);
  const [addressData, setAddressData] = useState<AddressModel>({} as AddressModel);
  const [dependentAddressData, setDependentAddressData] = useState<AddressModel>({} as AddressModel);
  const [dependentData, setDependentData] = useState<DependentModel>({} as DependentModel);
  const [dependentDateValue, setDependentDateValue] = useState(null);
  const [haveAgree, setHaveAgree] = useState("No");
  const [insuranceData, setInsuranceData] = useState<InsuranceCompanyModel>({} as InsuranceCompanyModel);
  const [isLoading, setIsLoading] = useState(true);
  const [clickedClientData, setClickedClientData] = useState<any>()
  const [saveDisabled, setDisabled] = useState(false)
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image2, setImage2] = useState<File | null>(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const thirdData = useRef<any>();
  thirdData.current = image;
  const [insuranceModalType, setInsuranceModalType] = useState<"front" | "back">("front");
  const [frontFile, setFrontFile] = useState("");
  const [backFile, setBackFile] = useState("");

  useEffect(() => {
    if (user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") {
      setClickedClientData(clientData)
    }
  }, [clientData]);

  useEffect(() => {
    viewInsuranceByUserId(userId);

    if (activeTab) {
      setcustomActiveTab(activeTab);
    }
  }, []);

  useEffect(() => {
    if (tradingServiceId == "" || tradingServiceId == undefined || tradingServiceId == null) {
      return;
    } else {
      if (modalType === "1") {
        setShowEligibilityModal(!showEligibilityModal);
      }

      if (modalType === "2") {
        setShowClaimStatusModal(!showClaiStatusmModal);
      }
    }
  }, [tradingServiceId]);

  useEffect(() => {
    viewDataByUserID()
  }, []);

  useEffect(() => {
    viewInsuranceCompanyById();
  }, [clientSingleInsuranceData]);

  const viewInsuranceCompanyById = () => {
    setInsuranceDetails(clientSingleInsuranceData);
    setSelectedInsuranceCompanyTag({
      ...selecteInsuranceCompanyTag,
      ...{ value: clientSingleInsuranceData?.insuranceCompanyId?._id, label: clientSingleInsuranceData?.insuranceCompanyId?.insuranceCompany.toLowerCase() },
    });
    setShowEditProfile(false);
    setFrontFile(clientSingleInsuranceData?.insuranceCardId);
    setBackFile(clientSingleInsuranceData?.insuranceCardBackId);
  };

  const viewDataByUserID = () => {
    ClientService.getClientByClientId(userId).then(res => {
      setIsLoading(false);
      const data: any = {
        state: res.data?.state,
      }
      InsuranceCompanyService.getInsuaranceCompanyByState(data).then((res: any) => {
        if (res.success) {
          const tagList = res.data.map((tag: any) => {
            return { value: tag._id, label: tag.insuranceCompany };
          });
          setSearchInsuranceCompanyTag(tagList);
        } else {
          setSearchInsuranceCompanyTag([]);
        }
      });
      setClientDetails(res?.data)
    });
  };

  const viewInsuranceByUserId = (userId: string) => {
    setIsLoaded(false);
    ClientService.ViewInsuranceByClientId(userId).then(res => {
      if (res.success) {
        setInsuranceDetails(res.data);
        setIsLoaded(true);
      } else {
        setInsuranceDetails({} as Insurance);
        setIsLoaded(true);
      }
    });
  };

  function setEditProfile() {
    setShowEditProfile(true);
  }

  let state;
  function getStateByZipCode(zipString: string) {
    if (typeof zipString != "string") {
      toast.error("Must pass the zipcode as a string.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }

    if (zipString.length != 5) {
      return;
    }

    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      state = "Colorado";
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      state = "Florida";
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      state = "Maryland";
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode == 5501 || zipcode == 5544) {
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      state = "New Mexico";
    } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      state = "Ohio";
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      state = "Tennessee";
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      state = "Vermont";
    } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode == 20598) {
      state = "Virginia";
    } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      state = "Wyoming";
    } else {
      state = "";
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    return state;
  }
  const updateTherapistDetails = () => {
    setDisabled(true);
    const address: any = {
      address1: addressData?.address1 || insuranceDetails?.subscriber?.address?.address1,
      city: addressData?.city || insuranceDetails?.subscriber?.address?.city,
      state: addressData?.state || insuranceDetails?.subscriber?.address?.state,
      postalCode: addressData?.postalCode || insuranceDetails?.subscriber?.address?.postalCode,
    };
    const dependentAddress: any = {
      address1: dependentAddressData?.address1 || insuranceDetails?.dependent?.address?.address1,
      city: dependentAddressData?.city || insuranceDetails?.dependent?.address?.city,
      state: dependentAddressData?.state || insuranceDetails?.dependent?.address?.state,
      postalCode: dependentAddressData?.postalCode || insuranceDetails?.dependent?.address?.postalCode,
    };
    const subcribersData: any = {
      memberId: subscriberData?.memberId || insuranceDetails?.subscriber?.memberId,
      policyNumber: subscriberData?.policyNumber || insuranceDetails?.subscriber?.policyNumber,
      firstName: subscriberData?.firstName || insuranceDetails?.subscriber?.firstName,
      lastName: subscriberData?.lastName || insuranceDetails?.subscriber?.lastName,
      gender: subscriberData?.gender || insuranceDetails?.subscriber?.gender,
      dateOfBirth: (dateValue as any) || insuranceDetails?.subscriber?.dateOfBirth,
      paymentResponsibilityLevelCode: subscriberData?.paymentResponsibilityLevelCode || insuranceDetails?.subscriber?.paymentResponsibilityLevelCode,
      address: address,
    };

    const dependent: any = {
      memberId: dependentData?.memberId || insuranceDetails?.dependent?.memberId,
      policyNumber: dependentData?.policyNumber || insuranceDetails?.dependent?.policyNumber,
      firstName: dependentData?.firstName || insuranceDetails?.dependent?.firstName,
      lastName: dependentData?.lastName || insuranceDetails?.dependent?.lastName,
      gender: dependentData?.gender || insuranceDetails?.dependent?.gender,
      dateOfBirth: (dependentDateValue as any) || insuranceDetails?.dependent?.dateOfBirth,
      paymentResponsibilityLevelCode: dependentData?.paymentResponsibilityLevelCode || insuranceDetails?.dependent?.paymentResponsibilityLevelCode,
      relationshipToSubscriberCode: dependentData?.relationshipToSubscriberCode || insuranceDetails?.dependent?.relationshipToSubscriberCode,
      address: dependentAddress,
    };

    if (insuranceCardRef.current.raw && insuranceCardBackRef.current.raw) {
      const data: any = {
        insuranceId: insuranceDetails?._id,
        clientId: insuranceDetails.clientId,
        insuranceCompanyId: insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceDetails?.insuranceCardId,
        insuranceCardBackId: insuranceDetails?.insuranceCardBackId,
        dependent: dependent,
        deletingInsuranceCardFrontId: insuranceDetails?.insuranceCardId?._id || "none",
        deletingInsuranceCardBackId: insuranceDetails?.insuranceCardBackId?._id || "none",
      };

      ClientService.UpdateInsurancePlanByUser(insuranceDetails.clientId, data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setDisabled(false);
          onInsuranceUpdated(res?.data);
          setInsuranceDetails(res.data);
          setShowEditProfile(false);
          InsuranceCompanyService.getInsuaranceCoPaymentAndContractPriceById(insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value).then(res => {
            const copaymentInfo = {
              clientId: insuranceDetails.clientId,
              copaymentAmount: res.data?.coPayment,
            };

            onCopaymentAmount(copaymentInfo);
          });

          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

        } else {
          setDisabled(false);
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });

    } else if (insuranceCardRef.current.raw && !insuranceCardBackRef.current.raw) {
      const data: any = {
        insuranceId: insuranceDetails?._id,
        clientId: insuranceDetails?.clientId,
        insuranceCompanyId: insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceDetails?.insuranceCardId,
        insuranceCardBackId: insuranceDetails?.insuranceCardBackId,
        dependent: dependent,
        deletingInsuranceCardFrontId: insuranceDetails?.insuranceCardId?._id || "none",
        deletingInsuranceCardBackId: "none",
      };

      ClientService.UpdateInsurancePlanByUser(insuranceDetails.clientId, data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          onInsuranceUpdated(res?.data);
          setInsuranceDetails(res.data);
          setDisabled(false);
          InsuranceCompanyService.getInsuaranceCoPaymentAndContractPriceById(insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value).then(res => {
            const copaymentInfo = {
              clientId: insuranceDetails.clientId,
              copaymentAmount: res.data?.coPayment,
            };

            onCopaymentAmount(copaymentInfo);
          });
          setShowEditProfile(false);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setDisabled(false);
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });

    } else if (insuranceCardBackRef.current.raw && !insuranceCardRef.current.raw) {
      const data: any = {
        insuranceId: insuranceDetails?._id,
        clientId: insuranceDetails?.clientId,
        insuranceCompanyId: insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: insuranceDetails?.insuranceCardId,
        insuranceCardBackId: insuranceDetails?.insuranceCardBackId,
        dependent: dependent,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: insuranceDetails?.insuranceCardBackId?._id || "none",
      };

      ClientService.UpdateInsurancePlanByUser(insuranceDetails.clientId, data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setDisabled(false);
          onInsuranceUpdated(res?.data);
          setInsuranceDetails(res.data);
          InsuranceCompanyService.getInsuaranceCoPaymentAndContractPriceById(insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value).then(res => {
            const copaymentInfo = {
              clientId: insuranceDetails.clientId,
              copaymentAmount: res.data?.coPayment,
            };

            onCopaymentAmount(copaymentInfo);
          });
          setShowEditProfile(false);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setDisabled(false);
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
      insuranceData
    } else {
      const data: any = {
        insuranceId: insuranceDetails?._id,
        clientId: insuranceDetails?.clientId,
        insuranceCompanyId: insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value,
        subscriber: subcribersData,
        insuranceCardId: frontFile,
        insuranceCardBackId: backFile,
        dependent: dependent,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: "none",
      };

      ClientService.UpdateInsurancePlanByUser(insuranceDetails.clientId, data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          setDisabled(false);
          onInsuranceUpdated(res?.data);
          setInsuranceDetails(res.data);
          setShowEditProfile(false);
          InsuranceCompanyService.getInsuaranceCoPaymentAndContractPriceById(insuranceData.insuranceCompany || selecteInsuranceCompanyTag.value).then(res => {
            const copaymentInfo = {
              clientId: insuranceDetails.clientId,
              copaymentAmount: res.data?.coPayment,
            };

            onCopaymentAmount(copaymentInfo);
          });
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setDisabled(false);
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  const statementFiles = () => {
    switch (insuranceCardRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCard({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const insuranceCardBackFiles = () => {
    switch (insuranceCardBackRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCardBack({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  function handleDependentDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDependentDateValue(dateValue);
  }

  const dependentDb = moment.utc(dependentDateValue || insuranceDetails?.dependent?.dateOfBirth).format("YYYY-MM-DD");

  function handleDependentPostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setDependentAddressData({ ...dependentAddressData, postalCode: e, state: selectedState ? selectedState : "" });
  }

  const toggleEligibilityModal = () => {
    setModalType("");
    setTradingServiceId("");
    setShowEligibilityModal(!showEligibilityModal);
  };

  const closseNote = () => {
    setShowNote(!showNote);
  };

  const toggleClaimModal = () => {
    setShowClaimModal(!showClaimModal);
  };

  const toggleClaimStatusModal = () => {
    setModalType("");
    setTradingServiceId("");
    setShowClaimStatusModal(!showClaiStatusmModal);
  };

  const createOption = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }

  const db = (dateValue || clientDetails?.dateOfBirth) ? moment.utc(dateValue || clientDetails?.dateOfBirth).format("YYYY-MM-DD") : null;

  function handlePostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setAddressData({ ...addressData, postalCode: e, state: selectedState ? selectedState : "" });
  }

  const handleClickApplyDefault = () => {

    const secondaryInsurance = clientIsuranceList?.filter(insurance => insurance._id != insuranceDetails?._id)[0];
    Swal.fire({
      icon: "question",
      title: "Are you sure " + insuranceDetails?.insuranceCompanyId?.insuranceCompany + " is set as your default insurance?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "Later",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        setDisabled(true);
        const data = {
          clientId: user?.role == 'CLIENT' ? user?._id : clientData?._id,
          insuranceId: insuranceDetails?._id,
          secondaryInsuranceId: secondaryInsurance?._id ?? null,
        }
        ClientService.updateDefaultInsurance(data).then((res: any) => {
          if (res?.success) {
            const insuranceNewData: Insurance = {
              clientId: res?.data?.insuranceData?.clientId ?? null,
              insuranceCardBackId: res?.data?.insuranceData?.insuranceCardBackId ?? null,
              insuranceCardId: res?.data?.insuranceData?.insuranceCardId ?? null,
              insuranceCompanyId: res?.data?.insuranceData?.insuranceCompanyId?._id ?? null,
              subscriber: res?.data?.insuranceData?.subscriber ?? null,
              _id: res?.data?.insuranceData?._id ?? null,
            }
            setDisabled(false);
            if (user?.role == "CLIENT") {
              setUser({
                ...user,
                insuranceId: insuranceNewData
              });
            } else if (user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") {
              setClickedClientData((clickedClientData: any) => ({
                ...clickedClientData,
                insuranceId: res?.data?.insuranceData?._id,
              }));
              onClientUpdated(res?.data?.insuranceData?._id)
            }

            toast.success(`Updated successfully!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error(res?.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

        });
      }
    });
  }

  const handleFrontFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload(e.target.files[0], "front");
    }
  };

  const handleBackFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload(e.target.files[0], "back");
    }
  };

  const toggleInsuranceModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCrop = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      const croppedImageNew = await getCroppedInsuranceImgAsFile(image2, croppedArea, croppedAreaPixels);
      setCroppedImage(croppedImageNew as File | null);
    } catch (error) {
      toast.error(`Error capturing cropped image. Error details: ${error}.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleZoomChange = (event: any, newZoom: any) => {
    setZoom(newZoom);
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const handleFileUpload = async (file: File, type: "front" | "back") => {
    const fileType = await fileTypeFromBlob(file);

    if (fileType?.mime === "application/pdf") {
      const compressedPdf = await compressPdf(file);
      if (type === "front") {
        selectInsuranceCard({
          preview: URL.createObjectURL(compressedPdf),
          preview2: compressedPdf,
          raw: [compressedPdf],
        });
      } else {
        selectInsuranceCardBack({
          preview: URL.createObjectURL(compressedPdf),
          preview2: compressedPdf,
          raw: [compressedPdf],
        });
      }
    } else if (fileType?.mime === "image/jpeg" || fileType?.mime === "image/png") {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && typeof event.target.result === "string") {
          setImage({ preview: event.target.result, raw: "" });
          setImage2(file);
          setInsuranceModalType(type);
          toggleInsuranceModal();
        }
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("You have selected an unsupported file. Please select an image or PDF file.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const handleSave = () => {
    if (croppedImage) {
      if (insuranceModalType === "front") {
        selectInsuranceCard({
          preview: URL.createObjectURL(croppedImage),
          preview2: croppedImage,
          raw: [croppedImage],
        });
      } else {
        selectInsuranceCardBack({
          preview: URL.createObjectURL(croppedImage),
          preview2: croppedImage,
          raw: [croppedImage],
        });
      }
      toggleInsuranceModal();
    }
  };

  return (
    <>
      <div>
        {!isLoaded && <Spinner className="bouncer" />}

        {showEligibilityModal && (
          <ViewEligibilityModal
            tradingServiceId={tradingServiceId}
            insuranceId={insuranceDetails._id}
            showModal={showEligibilityModal}
            toggleModal={toggleEligibilityModal}
          />
        )}

        {showNote && <SessionHistoryModalForAdmin setShowModal={closseNote} showModal={showNote} userId={noteId} isVersion={false} />}

        {showClaimModal && (
          <InsuranceClaimModal diagnosisNoteId={noteId} insuranceId={insuranceDetails._id} showModal={showClaimModal} toggleClaimModal={toggleClaimModal} />
        )}

        {showClaiStatusmModal && (
          <ClaimStatusModal
            insuranceId={insuranceDetails?._id}
            tradingServiceId={tradingServiceId}
            diagnosisNoteId={noteId}
            showModal={showClaiStatusmModal}
            toggleClaimStatusModal={toggleClaimStatusModal}
          />
        )}

        {showIdModal && <TradingParterModal setTradingServiceId={setTradingServiceId} showModal={showIdModal} setShowModal={setShowIdModal} />}

        <div className="">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h5 className="font-size-17 profileFont mb-4">Insurance Information</h5>
                </Col>
              </Row>
              <Row>
                <Col>


                  <div className="">
                    {insuranceDetails !== undefined && insuranceDetails?._id?.length > 0 && (
                      <>
                        <div className="flex flex-row-reverse flex-wrap mb-3" >
                          {!editProfile && (
                            <button
                              type="button"
                              className="btn btn-primary relative mb-4 "
                              style={{
                                bottom: "0",
                                float: "right",
                              }}
                              onClick={() => {
                                setEditProfile();
                              }}
                            >
                              <i className="fa fa-pencil-square-o mr-1"></i>
                              Edit Insurance
                            </button>
                          )}
                          {editProfile && (
                            <div>
                              <button
                                type="button"
                                className={
                                  saveDisabled ? "btn btn-primary relative mb-4 updating" : "btn btn-primary relative mb-4"
                                }
                                style={{
                                  bottom: "0",
                                  float: "right",
                                }}
                                disabled={saveDisabled}
                                onClick={() => {
                                  updateTherapistDetails();
                                }}
                              >
                                {saveDisabled ? "Saving..." : "Save"}
                              </button>
                            </div>
                          )}
                          {
                            user?.role == "CLIENT" && (user?.insuranceId?._id != insuranceDetails?._id) && (
                              <button
                                type="button"
                                className={
                                  saveDisabled ? "btn btn-secondary relative mb-4 mr-2 updating" : "btn btn-secondary relative mb-4 mr-2"
                                }
                                style={{
                                  bottom: "0",
                                }}
                                disabled={saveDisabled}
                                onClick={() => {
                                  handleClickApplyDefault();
                                }}
                              >
                                {saveDisabled ? "Updating..." : "Apply as Default"}
                              </button>

                            )
                          }

                          {
                            (user?.role === "SUPER_ADMIN" || user?.role == "SUB_ADMIN") && insuranceDetails?._id != clickedClientData?.insuranceId && (
                              <button
                                type="button"
                                className={
                                  saveDisabled ? "btn btn-secondary mr-2 updating" : "btn btn-secondary mr-2"
                                }
                                disabled={saveDisabled}
                                onClick={() => {
                                  handleClickApplyDefault();
                                }}
                              >
                                {saveDisabled ? "Updating..." : "Apply as Default"}
                              </button>
                            )
                          }
                        </div>

                        {!editProfile && (
                          <div className="card-bodyDiv">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-20 profileFont mb-4 mt-3">Receiver</h5>
                            </div>
                            <Row>
                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Organization </h5>
                                  </div>

                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.insuranceCompanyId?.insuranceCompany || "-"}</h5>
                                  </div>
                                </div>
                              </Col>
                              <Col xl="6">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Insurance card picture</h5>
                                </div>

                                <div className="d-sm-flex">
                                  {clientSingleInsuranceData?.insuranceCardId != null && (
                                    <div className="flex  mb-2">
                                      <div className="mr-5">
                                        <Link
                                          to={{ pathname: Util.fileURL(clientSingleInsuranceData?.insuranceCardId) }}
                                          target="_blank"
                                          className="events-icon2"
                                          style={{ fontSize: "62px" }}
                                        >
                                          <div
                                            className="img-thumbnail imageFit  imageFit avatar-2xl profile-user-wid-pro items-center margin_center mt-3 profileImageShow"
                                            style={{
                                              backgroundImage: `url("${Util.fileURL(clientSingleInsuranceData?.insuranceCardId)}")`,
                                              borderRadius: "0px",
                                              width: "120px",
                                              height: "130px",
                                            }}
                                          ></div>
                                        </Link>
                                      </div>
                                    </div>
                                  )}

                                  {clientSingleInsuranceData?.insuranceCardBackId != null && (
                                    <div className="flex  mb-2">
                                      <div className="mr-2">
                                        <Link
                                          to={{ pathname: Util.fileURL(clientSingleInsuranceData?.insuranceCardBackId) }}
                                          target="_blank"
                                          className="events-icon2"
                                          style={{ fontSize: "62px" }}
                                        >
                                          <div
                                            className="img-thumbnail imageFit  imageFit avatar-2xl profile-user-wid-pro items-center margin_center mt-3 profileImageShow"
                                            style={{
                                              backgroundImage: `url("${Util.fileURL(clientSingleInsuranceData?.insuranceCardBackId)}")`,
                                              borderRadius: "0px",
                                              width: "120px",
                                              height: "130px",
                                            }}
                                          ></div>
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            <div className="card-heading mb-2">
                              <h5 className="font-size-20 profileFont mb-4">Subscriber</h5>
                            </div>
                            <Row>
                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.memberId || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.paymentResponsibilityLevelCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.firstName || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.lastName || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                </div>
                                <div className="card-heading mb-4 pr-5">
                                  <h5 className="font-size-14 mb-4">{clientDetails?.gender ? clientDetails?.gender : insuranceDetails?.subscriber?.gender ? insuranceDetails?.subscriber?.gender : "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{clientDetails?.dateOfBirth ? moment.utc(clientDetails?.dateOfBirth).format("YYYY-MM-DD") : ("-")}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Policy Number</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.policyNumber || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.address?.address1 || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">City</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.address?.city || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.address?.state || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.subscriber?.address?.postalCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="card-heading mb-2">
                              <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                            </div>

                            <Row>
                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.memberId || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.paymentResponsibilityLevelCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.firstName || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.lastName || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                </div>
                                <div className="card-heading mb-4 pr-5">
                                  <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.gender || "-"}</h5>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.dateOfBirth ? moment.utc(insuranceDetails?.dependent?.dateOfBirth).format("YYYY-MM-DD") : ("-")}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Policy Number</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.policyNumber || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.relationshipToSubscriberCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.address?.address1 || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">City</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.address?.city || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">State</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.address?.state || "-"}</h5>
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <h5 className="font-size-14 mb-4">{insuranceDetails?.dependent?.address?.postalCode || "-"}</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {editProfile && (
                          <div className="card-bodyDiv">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-20 profileFont mb-4">Receiver</h5>
                            </div>

                            <Row>
                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Select
                                      placeholder="Choose or create company"
                                      onChange={(newValue: any) => setSelectedInsuranceCompanyTag(newValue)}
                                      options={searchInsuranceCompanyTag}
                                      value={selecteInsuranceCompanyTag || ""}
                                      className="creatableEdit mb-4"
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>

                              <Col xl="6">
                                {(insuranceDetails?.insuranceCardId != null && !insuranceCardRef.current.preview) && (
                                  <div className="flex mb-2">
                                    <div className="mr-2">
                                      <Link
                                        to={{ pathname: Util.fileURL(frontFile) }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-eye m-1" />Click here to view existing insurance card front file
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                {insuranceCardRef.current.preview ? (
                                  <div>
                                    {insuranceCardRef.current.preview2?.type === "application/pdf" ? (
                                      <div>
                                        <a
                                          href={insuranceCardRef.current.preview}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ display: "block", marginBottom: "10px" }}
                                        >
                                          <img
                                            src={pdfIcon}
                                            alt="PDF Icon"
                                            style={{ width: "30px", height: "40px" }}
                                          />
                                          <span>&nbsp;View selected PDF</span>
                                        </a>
                                      </div>
                                    ) : (
                                      <img
                                        src={insuranceCardRef.current.preview}
                                        alt="Selected insurance card front"
                                        style={{ maxWidth: "50%", height: "auto", marginBottom: "10px" }}
                                      />
                                    )}

                                    <label htmlFor="file-upload-front" className="file-upload m-auto">
                                      <span className="text-primary mt-5 m-auto cursor-pointer">
                                        Change your insurance card front side
                                      </span>
                                      <input
                                        id="file-upload-front"
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, application/pdf"
                                        onChange={handleFrontFileChange}
                                      />
                                    </label>

                                    <div>
                                      <div className="flex">
                                        <div style={{ fontSize: "62px" }}>{statementFiles()}</div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <label
                                    htmlFor="file-upload-front"
                                    style={{ border: "1px dashed" }}
                                    className="mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 rounded cursor-pointer"
                                  >
                                    <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                      <img src={uploadIcon} alt="Upload Icon" />
                                      <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card front side</p>
                                    </div>
                                    <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>
                                    <input
                                      id="file-upload-front"
                                      type="file"
                                      style={{ display: "none" }}
                                      className="file_upload_input mb-4"
                                      accept="image/png, image/jpeg, application/pdf"
                                      onChange={handleFrontFileChange}
                                    />
                                  </label>
                                )}
                              </Col>

                              <Col xl="6">
                                {(insuranceDetails?.insuranceCardBackId != null && !insuranceCardBackRef.current.preview) && (
                                  <div className="flex mb-2">
                                    <div className="mr-2">
                                      <Link
                                        to={{ pathname: Util.fileURL(backFile) }}
                                        target="_blank"
                                      >
                                        <i className="fa fa-eye m-1" />Click here to view existing insurance card back file
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                {insuranceCardBackRef.current.preview ? (
                                  <div>
                                    {insuranceCardBackRef.current.preview2?.type === "application/pdf" ? (
                                      <div>
                                        <a
                                          href={insuranceCardBackRef.current.preview}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ display: "block", marginBottom: "10px" }}
                                        >
                                          <img
                                            src={pdfIcon}
                                            alt="PDF Icon"
                                            style={{ width: "30px", height: "40px" }}
                                          />
                                          <span>&nbsp;View selected PDF</span>
                                        </a>
                                      </div>
                                    ) : (
                                      <img
                                        src={insuranceCardBackRef.current.preview}
                                        alt="Selected insurance card back"
                                        style={{ maxWidth: "50%", height: "auto", marginBottom: "10px" }}
                                      />
                                    )}
                                    <label htmlFor="file-upload2" className="file-upload m-auto">
                                      <span className="text-primary mt-5 m-auto cursor-pointer">Change your insurance card back side</span>
                                      <input
                                        id="file-upload2"
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, application/pdf"
                                        onChange={handleBackFileChange}
                                      />
                                    </label>

                                    <div>
                                      <div className="flex">
                                        <div style={{ fontSize: "62px" }}>{insuranceCardBackFiles()}</div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <label
                                    htmlFor="file-upload2"
                                    style={{ border: "1px dashed" }}
                                    className="mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 rounded cursor-pointer"
                                  >
                                    <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                      <img src={uploadIcon} alt="Upload Icon" />
                                      <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card back side</p>
                                    </div>
                                    <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>
                                    <input
                                      id="file-upload2"
                                      type="file"
                                      style={{ display: "none" }}
                                      className="file_upload_input mb-4"
                                      accept="image/png, image/jpeg, application/pdf"
                                      onChange={handleBackFileChange}
                                    />
                                  </label>
                                )}
                              </Col>
                            </Row>

                            <div className="card-heading mb-2">
                              <h5 className="font-size-20 profileFont mb-4">Subscriber</h5>
                            </div>
                            <Row>
                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Member Id "
                                      name="Member Id "
                                      defaultValue={insuranceDetails?.subscriber?.memberId || ""}
                                      onChange={e => setSubscriberData({ ...subscriberData, memberId: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Payment Responsibility Level Code"
                                      name="Payment Responsibility Level Code"
                                      defaultValue={insuranceDetails?.subscriber?.paymentResponsibilityLevelCode || ""}
                                      onChange={e => setSubscriberData({ ...subscriberData, paymentResponsibilityLevelCode: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="First name"
                                      name="First name"
                                      defaultValue={insuranceDetails?.subscriber?.firstName || ""}
                                      onChange={e => setSubscriberData({ ...subscriberData, firstName: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Last name"
                                      name="Last name"
                                      defaultValue={insuranceDetails?.subscriber?.lastName || ""}
                                      onChange={e => setSubscriberData({ ...subscriberData, lastName: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                </div>
                                <div className="card-heading mb-4 pr-5">
                                  <Row>
                                    <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios1"
                                          value="Female"
                                          defaultChecked={clientDetails?.gender == "Female"}
                                          onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                          Female
                                        </label>
                                      </div>
                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios2"
                                          value="Male"
                                          defaultChecked={clientDetails?.gender == "Male"}
                                          onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                          Male
                                        </label>
                                      </div>
                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios3"
                                          value="NonBinary"
                                          defaultChecked={clientDetails?.gender == "NonBinary"}
                                          onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor="exampleRadios3">
                                          Non Binary
                                        </label>
                                      </div>
                                      <div className="form-check mb-3">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios4"
                                          value="Other"
                                          defaultChecked={clientDetails?.gender == "Other"}
                                          onChange={e => setSubscriberData({ ...subscriberData, gender: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor="exampleRadios4">
                                          Other
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Date of birth"
                                      defaultValue={db || ""}
                                      disabled
                                      onChange={e => handleDateUpdate(e)}
                                      max={moment().format("YYYY-MM-DD")}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="policy Number"
                                      name="policy Number"
                                      defaultValue={insuranceDetails?.subscriber?.policyNumber || ""}
                                      onChange={e => setSubscriberData({ ...subscriberData, policyNumber: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Address"
                                      name="Address"
                                      defaultValue={insuranceDetails?.subscriber?.address?.address1 || ""}
                                      onChange={e => setAddressData({ ...addressData, address1: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">City</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="City"
                                      name="City"
                                      defaultValue={insuranceDetails?.subscriber?.address?.city || ""}
                                      onChange={e => setAddressData({ ...addressData, city: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                  </div>
                                  <div className="card-heading mb-4 pr-5">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Postal Code"
                                      name="Postal Code"
                                      defaultValue={insuranceDetails?.subscriber?.address?.postalCode}

                                      onChange={e => handlePostalCode(e.target.value)}
                                      required
                                    />
                                  </div>
                                </div>
                              </Col>

                              {addressData?.state && (
                                <Col xl="6">
                                  <div>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">State</h5>
                                    </div>
                                    <div className="card-heading mb-4 pr-5">
                                      <h5 className="font-size-14 profileFont mb-2">{addressData?.state || insuranceDetails?.subscriber?.address?.state}</h5>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>

                            {insuranceDetails?.dependent?.memberId ? (
                              <>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                                </div>
                                <Row>
                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Member Id "
                                          name="Member Id "
                                          defaultValue={insuranceDetails?.dependent?.memberId || ""}
                                          onChange={e => setDependentData({ ...dependentData, memberId: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Payment Responsibility Level Code"
                                          name="Payment Responsibility Level Code"
                                          defaultValue={insuranceDetails?.dependent?.paymentResponsibilityLevelCode || ""}
                                          onChange={e => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="First name"
                                          name="First name"
                                          defaultValue={insuranceDetails?.dependent?.firstName || ""}
                                          onChange={e => setDependentData({ ...dependentData, firstName: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Last name"
                                          name="Last name"
                                          defaultValue={insuranceDetails?.dependent?.lastName || ""}
                                          onChange={e => setDependentData({ ...dependentData, lastName: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                    </div>
                                    <div className="card-heading mb-4 pr-5">
                                      <Row>
                                        <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                          <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="exampleRadiosss"
                                              id="exampleRadios11"
                                              value="Female"
                                              defaultChecked={insuranceDetails?.dependent?.gender == "Female"}
                                              onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                            />
                                            <label className="form-check-label" htmlFor="exampleRadios11">
                                              Female
                                            </label>
                                          </div>
                                          <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="exampleRadiosss"
                                              id="exampleRadios22"
                                              value="Male"
                                              defaultChecked={insuranceDetails?.dependent?.gender == "Male"}
                                              onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                            />
                                            <label className="form-check-label" htmlFor="exampleRadios22">
                                              Male
                                            </label>
                                          </div>

                                          <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="exampleRadiosss"
                                              id="exampleRadios33"
                                              value="NonBinary"
                                              defaultChecked={insuranceDetails?.dependent?.gender == "NonBinary"}
                                              onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                            />
                                            <label className="form-check-label" htmlFor="exampleRadios33">
                                              Non Binary
                                            </label>
                                          </div>
                                          <div className="form-check mb-3">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="exampleRadiosss"
                                              id="exampleRadios44"
                                              value="Other"
                                              defaultChecked={insuranceDetails?.dependent?.gender == "Other"}
                                              onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                            />
                                            <label className="form-check-label" htmlFor="exampleRadios44">
                                              Other
                                            </label>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="date"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Date of birth"
                                          value={dependentDb || ""}
                                          onChange={e => handleDependentDateUpdate(e)}
                                          max={moment().format("YYYY-MM-DD")}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="policy Number"
                                          name="policy Number"
                                          defaultValue={insuranceDetails?.dependent?.policyNumber || ""}
                                          onChange={e => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Relationship To Subscriber Code"
                                          name="Relationship To Subscriber Code"
                                          defaultValue={insuranceDetails?.dependent?.relationshipToSubscriberCode || ""}
                                          onChange={e => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Address"
                                          name="Address"
                                          defaultValue={insuranceDetails?.dependent?.address?.address1 || ""}
                                          onChange={e => setDependentAddressData({ ...dependentAddressData, address1: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">City</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="City"
                                          name="City"
                                          defaultValue={insuranceDetails?.dependent?.address?.city || ""}
                                          onChange={e => setDependentAddressData({ ...dependentAddressData, city: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Postal Code"
                                          name="Postal Code"
                                          defaultValue={insuranceDetails?.dependent?.address?.postalCode}
                                          onChange={e => handleDependentPostalCode(e.target.value)}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  {dependentAddressData?.state && (
                                    <Col xl="6">
                                      <div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">State</h5>
                                        </div>
                                        <div className="card-heading mb-4 flex">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            {addressData?.state || insuranceDetails?.subscriber?.address?.state}
                                          </h5>
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row>
                                  <Col xl="12">
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-14 profileFont mb-2">Do you wish to add any dependents?</h5>
                                    </div>
                                  </Col>

                                  <Col xl="4">
                                    <div className="card-heading mt-2 mb-3">
                                      <select className="form-control" onChange={e => setHaveAgree(e.target.value)}>
                                        <option selected>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No" selected>
                                          No
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                                {haveAgree == "Yes" && (
                                  <>
                                    <>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
                                      </div>
                                      <Row>
                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Member Id "
                                                name="Member Id "
                                                defaultValue={insuranceDetails?.dependent?.memberId || ""}
                                                onChange={e => setDependentData({ ...dependentData, memberId: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Payment Responsibility Level Code"
                                                name="Payment Responsibility Level Code"
                                                defaultValue={insuranceDetails?.dependent?.paymentResponsibilityLevelCode || ""}
                                                onChange={e => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="First name"
                                                name="First name"
                                                defaultValue={insuranceDetails?.dependent?.firstName || ""}
                                                onChange={e => setDependentData({ ...dependentData, firstName: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Last name"
                                                name="Last name"
                                                defaultValue={insuranceDetails?.dependent?.lastName || ""}
                                                onChange={e => setDependentData({ ...dependentData, lastName: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                          </div>
                                          <div className="card-heading mb-4 pr-5">
                                            <Row>
                                              <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadiosss"
                                                    id="exampleRadios11"
                                                    value="Female"
                                                    defaultChecked={insuranceDetails?.dependent?.gender == "Female"}
                                                    onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                                  />
                                                  <label className="form-check-label" htmlFor="exampleRadios11">
                                                    Female
                                                  </label>
                                                </div>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadiosss"
                                                    id="exampleRadios22"
                                                    value="Male"
                                                    defaultChecked={insuranceDetails?.dependent?.gender == "Male"}
                                                    onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                                  />
                                                  <label className="form-check-label" htmlFor="exampleRadios22">
                                                    Male
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadiosss"
                                                    id="exampleRadios33"
                                                    value="NonBinary"
                                                    defaultChecked={insuranceDetails?.dependent?.gender == "NonBinary"}
                                                    onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                                  />
                                                  <label className="form-check-label" htmlFor="exampleRadios33">
                                                    Non Binary
                                                  </label>
                                                </div>
                                                <div className="form-check mb-3">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadiosss"
                                                    id="exampleRadios44"
                                                    value="Other"
                                                    defaultChecked={insuranceDetails?.dependent?.gender == "Other"}
                                                    onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                                                  />
                                                  <label className="form-check-label" htmlFor="exampleRadios44">
                                                    Other
                                                  </label>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="date"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Date of birth"
                                                value={dependentDb || ""}
                                                onChange={e => handleDependentDateUpdate(e)}
                                                max={moment().format("YYYY-MM-DD")}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="policy Number"
                                                name="policy Number"
                                                defaultValue={insuranceDetails?.dependent?.policyNumber || ""}
                                                onChange={e => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Relationship To Subscriber Code"
                                                name="Relationship To Subscriber Code"
                                                defaultValue={insuranceDetails?.dependent?.relationshipToSubscriberCode || ""}
                                                onChange={e => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Address</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Address"
                                                name="Address"
                                                defaultValue={insuranceDetails?.dependent?.address?.address1 || ""}
                                                onChange={e => setDependentAddressData({ ...dependentAddressData, address1: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">City</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="City"
                                                name="City"
                                                defaultValue={insuranceDetails?.dependent?.address?.city || ""}
                                                onChange={e => setDependentAddressData({ ...dependentAddressData, city: e.target.value })}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xl="6">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                                            </div>
                                            <div className="card-heading mb-4 pr-5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Postal Code"
                                                name="Postal Code"
                                                defaultValue={insuranceDetails?.dependent?.address?.postalCode}

                                                onChange={e => handleDependentPostalCode(e.target.value)}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        {dependentAddressData?.state && (
                                          <Col xl="6">
                                            <div>
                                              <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">State</h5>
                                              </div>
                                              <div className="card-heading mb-4 flex">
                                                <h5 className="font-size-14 profileFont mb-2">
                                                  {addressData?.state || insuranceDetails?.subscriber?.address?.state}
                                                </h5>
                                              </div>
                                            </div>
                                          </Col>
                                        )}
                                      </Row>
                                    </>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        toggle={toggleInsuranceModal}
        centered
        style={{
          margin: "auto",
        }}
      >
        <ModalHeader toggle={toggleInsuranceModal}>Crop insurance {insuranceModalType === "front" ? "front" : "back"} image</ModalHeader>
        <ModalBody>
          <div style={{ width: "100%", height: "250px" }}>
            <Cropper
              image={image.preview}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              cropShape="rect"
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={handleCrop}
              initialCroppedAreaPercentages={initialCroppedArea}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
            <Slider value={zoom} min={1} max={3} step={0.1} onChange={handleZoomChange} aria-labelledby="Zoom" />
          </div>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px", width: "100%" }}>
            <Button color="primary" onClick={handleSave} style={{ flex: 1, maxWidth: "100px" }}>
              Save
            </Button>
            <Button color="secondary" onClick={toggleInsuranceModal} onClose={onClose} style={{ flex: 1, maxWidth: "100px" }}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewInsuranceByClient;
