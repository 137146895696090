import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export interface CopaymentResponse {
  success: boolean;
  data?: {
    copayment?: {
      status?: string;
      amount?: number;
    };
  };
  message?: string;
  error?: string;
}

export class CopaymentService {
  /**
   * Xử lý thanh toán copayment của client
   * @param {string} clientId - ID của client
   * @param {number} copayment - Số tiền copayment
   * @param {string} vonageSessionId - Vonage session ID để liên kết với thanh toán
   * @returns {Promise<CopaymentResponse>} - Kết quả xử lý thanh toán
   */
  static async processClientCopayment(
    clientId: string, 
    copayment: number, 
    vonageSessionId: string
  ): Promise<CopaymentResponse> {
    const url = Util.apiAuthUrl("pay-copayment");
    
    const data = {
      clientId,
      copayment,
      vonageSessionId
    };
    
    return await axios.post(url, data);
  }
  
  /**
   * Lấy thông tin copayment của client
   * @param {string} clientId - ID của client
   * @returns {Promise<CopaymentResponse>} - Thông tin copayment
   */
  static async getClientCopayment(clientId: string): Promise<CopaymentResponse> {
    const url = Util.apiAuthUrl("get-copayment");
    
    const params = {
      client_id: clientId
    };
    
    return await axios.get(url, { params });
  }
  
  /**
   * Kiểm tra trạng thái thanh toán của một cuộc họp
   * @param {string} vonageSessionId - Vonage session ID của cuộc họp
   * @returns {Promise<CopaymentResponse>} - Thông tin trạng thái thanh toán
   */
  static async getMeetingCopaymentStatus(vonageSessionId: string): Promise<CopaymentResponse> {
    const url = Util.apiAuthUrl("get-copayment-status-of-a-meeting");
    
    const data = {
      vonageSessionId
    };
    
    return await axios.post(url, data);
  }
} 