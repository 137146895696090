import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, ModalBody, Modal, Button, FormGroup, Label, Input } from "reactstrap";
import { AdminService } from "../../services/AdminService";
import { TransactionTherapistPaymemt } from '../../models/Transaction';
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";
import moment from "moment";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Close from "../../assets/images/icons/close.png";
import { SelectedInsuranceCompanyTag } from "src/models/InsuranceCompany";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";

const AdminApprovePayment: React.FC = () => {
    const [therapistsPayments, setTherapistsPayments] = useState<TransactionTherapistPaymemt[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [seeMoreTransactions, setSeeMoreTransactions] = useState<any[] | undefined>()
    const [isOpenViewMoreModal, setIsOpenViewMoreModal] = useState(false)
    const [isTransationUpdateModal, setIsTransationUpdateModal] = useState<boolean>(false)
    const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag[]>([]);
    const [transationListId, setTransationListId] = useState('')
    const [disabledPayBtn, setDisabledPayBtn] = useState(false)
    const [monthlyPamentId, setMonthlyPamentId] = useState('')
    const LIMIT = 10;
    const [paymentCount, setPaymentCount] = useState(10);
    const [paymentOffset, setPaymentOffset] = useState(1);
    const [updatesTransationData, setUpdateTransactionData] = useState({ monthlyPaymentId: '', transactionId: '', transactionAmount: 0, insuaranceCompanyName: '' })
    useEffect(() => {
        getAllTherapistBlances();
        getAllInsuranceCompanies()
    }, []);

    const getAllInsuranceCompanies = () => {
        InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
            if (res.success) {
                const tagList = res.data.map((tag: any) => {
                    return { value: tag._id, label: tag.insuranceCompany };
                });
                setSearchInsuranceCompanyTag(tagList);
            } else {
                setSearchInsuranceCompanyTag([]);
            }
        });
    };

    const getAllTherapistBlances = () => {
        setIsLoading(true);
        AdminService.getMonthlyAllAccumulatedBalance(LIMIT, paymentOffset).then(res => {
            if (res.success) {
                setTherapistsPayments(res.data);
                setPaymentCount(res.data?.length);
                setIsLoading(false);
            }
        });
    }

    const seeMorePayments = () => {
        setIsLoading(true);
        const updatedOffset = paymentOffset + 1;
        setPaymentOffset(updatedOffset);
        AdminService.getMonthlyAllAccumulatedBalance(LIMIT, updatedOffset).then(res => {
          if (res.success) {
            setTherapistsPayments(previousmeeting => {
              return [...new Set([...previousmeeting, ...res.data])];
            });
            setPaymentCount(res.data?.length);
            setIsLoading(false);
          } else {
            setTherapistsPayments([] as TransactionTherapistPaymemt[]);
            setIsLoading(false);
          }
        });
      };

    const approvePayment = (e: any) => {
        const paymentId = e.currentTarget.getAttribute("data-value1");
        Swal.fire({
            icon: "info",
            title: "Are you sure want to approve this payment?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                const data: any = {
                    paymentId: paymentId,
                    verifiedStatus: "APPROVED"
                }

                AdminService.payMonthlyAllAccumulatedBalance(data).then(res => {
                    if (res.success) {
                        getAllTherapistBlances();
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    }

    const declarePayment = (e: any) => {
        Swal.fire({
            icon: "info",
            title: "Are you sure want to decline this payment?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                AdminService.declaredMonthlyAmount().then(res => {
                    if (res.success) {
                        getAllTherapistBlances();
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    }

    const deletePayment = (e: any) => {
        const paymentId = e.currentTarget.getAttribute("data-value1");
        Swal.fire({
            icon: "info",
            title: "Are you sure want to delete this payment?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                AdminService.deleteMonthlyAmountRecord(paymentId).then(res => {
                    if (res.success) {
                        getAllTherapistBlances();
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    }

    const runCrownjob1stOfMonth = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure want to run the cron job?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                AdminService.runCrownJob1stOfMonth().then(res => {
                    if (res.success) {
                        getAllTherapistBlances();
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    };

    const runCrownjob15thOfMonth = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure want to run the cron job?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                AdminService.runCrownJob15thOfMonth().then(res => {
                    if (res.success) {
                        getAllTherapistBlances();
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                });
            }
        });
    };

    const handleClickViewMore = (transaction: any, monthlyPayment: any) => {
        setUpdateTransactionData(
            {
                ...updatesTransationData,
                monthlyPaymentId: monthlyPayment?._id,
            })
        setTransationListId(transaction?._id)
        setSeeMoreTransactions(transaction?.recentTransaction)

        setIsOpenViewMoreModal(true)
    }

    const toggleViewMoreModal = () => {
        setIsOpenViewMoreModal(!isOpenViewMoreModal)
        setUpdateTransactionData(
            {
                ...updatesTransationData,
                monthlyPaymentId: '',
            })
        setTransationListId('')
    }

    const toggleTransactionUpdateModal = () => {
        setIsTransationUpdateModal(!isTransationUpdateModal)
    }

    const calculateTotal = (recentTransaction: any) => {
        let total = 0;

        recentTransaction?.map((t: any) => {
            total = total + t.transactionAmount;
        });

        return total.toFixed(2);
    }

    const updateMonthlyTransaction = (transaction: any) => {
        setUpdateTransactionData({
            ...updatesTransationData,
            transactionAmount: transaction?.transactionAmount,
            insuaranceCompanyName: transaction?.insuranceCompany,
            transactionId: transaction?._id?._id || transaction?._id,
        });

        toggleTransactionUpdateModal();
    }

    const updateTransation = () => {
        if (!updatesTransationData?.transactionAmount) {
            toast.error("Amount is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            return false;
        }

        if (updatesTransationData?.transactionAmount <= 0) {
            toast.error("Valid amount is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            return false;
        }

        const data = {
            monthlyPaymentId: updatesTransationData?.monthlyPaymentId,
            transactionId: updatesTransationData?.transactionId,
            transactionAmount: updatesTransationData?.transactionAmount,
            insuaranceCompanyName: updatesTransationData?.insuaranceCompanyName
        }

        if (data?.monthlyPaymentId && data?.transactionId) {
            AdminService.updatemonthlyTransaction(data).then((res: any) => {
                if (res.success) {
                    const newMonthlyPaymentId = res.data?._id
                    const newMPayment = res.data
                    const newMList = therapistsPayments?.map(payment => {
                        if (payment._id === newMonthlyPaymentId) {
                            return newMPayment;
                        } else {
                            return payment;
                        }
                    });

                    setTherapistsPayments(newMList)
                    setUpdateTransactionData({
                        ...updatesTransationData,
                        transactionAmount: 0,
                        insuaranceCompanyName: '',
                        transactionId: '',
                    });

                    toggleTransactionUpdateModal();

                    const newTransactionList = res.data?.therapistTransaction?.filter((t: any) => t._id == transationListId);

                    setSeeMoreTransactions(newTransactionList[0]?.recentTransaction);

                    toast.success("Updated Seccessfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        } else {
            toast.error("Invalid Monthly payment id or transaction id", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }
    const transferAmmountByTherapist = (transactions: any, therapistId: string, monthlyPaymentId: string) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure want to pay?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
        }).then(result => {
            if (result.isConfirmed) {
                setDisabledPayBtn(true)
                setMonthlyPamentId(monthlyPaymentId)
                const transactionIds = transactions?.map((transaction: any) => transaction?._id?._id);

                if (monthlyPaymentId && therapistId && transactionIds?.length > 0) {
                    const data = {
                        transactionIds: transactionIds,
                        therapistId: therapistId,
                        monthlyPaymentId: monthlyPaymentId
                    }
                    AdminService.updatemonthlyTransactionByTherapistId(data).then((res: any) => {
                        if (res.success) {
                            updatePaidStatus(therapistId, monthlyPaymentId)
                        } else {
                            toast.error(res?.error || res?.errorData?.raw?.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: 'foo-bar'
                            });
                            setDisabledPayBtn(false)
                            setMonthlyPamentId('')
                        }
                    })
                } else {
                    toast.error("Invalid Monthly payment id or transaction ids", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                    setDisabledPayBtn(false)
                    setMonthlyPamentId('')
                }
            }
        });


    }

    const updatePaidStatus = (therapistId: string, monthlyPaymentId: string) => {
        const therapistObj = therapistsPayments.find(obj => obj._id === monthlyPaymentId);
        if (therapistObj) {
            const transactionObj = therapistObj.therapistTransaction.find(trans => trans._id === therapistId);
            if (transactionObj) {
                transactionObj.paidStatus = "paid";
            }
        }
        toast.success("Paid Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
        });
        setDisabledPayBtn(false)
        setMonthlyPamentId('')
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Therapists"} breadcrumbItem={"Monthly Payment"} />
                    <Row>
                        <Col xl={12}>
                            <button
                                className="btn btn-primary relative mb-3"
                                style={{ bottom: "0", float: "right" }}
                                onClick={runCrownjob15thOfMonth}
                            >
                                Run cron job as 15th of month
                            </button>
                            <button
                                className="btn btn-primary relative mb-3 me-2"
                                style={{ bottom: "0", float: "right" }}
                                onClick={runCrownjob1stOfMonth}
                            >
                                Run cron job as 1st of month
                            </button>
                        </Col>
                    </Row>
                    <Card className="m-h">
                        <CardBody>
                            <Row>
                                <Col sm="12" className="mt10">
                                    {therapistsPayments !== undefined && therapistsPayments.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead className="thead-dark">
                                                        <tr style={{ borderBottom: "1px solid rgb(80 78 78 / 50%)", borderTop: "1px solid rgb(80 78 78 / 50%)" }}>
                                                            <th scope="col">index</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Stripe Account</th>
                                                            <th scope="col">Total Earnings</th>
                                                            <th scope="col">Available Balance</th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {therapistsPayments && therapistsPayments.map((therapist, index) => (
                                                            therapist?.therapistTransaction && therapist?.therapistTransaction.map((t, j) => {
                                                                const key = `${therapist?._id}-${j}`;

                                                                return (
                                                                    <tr key={key} style={j === therapist?.therapistTransaction.length - 1 ? { borderBottom: "3px solid rgb(80 78 78 / 50%)", height: "65px", borderTop: "1px solid rgb(80 78 78 / 50%)" } : { borderBottom: "1px solid rgb(80 78 78 / 50%)", bottom: "110px" }}>
                                                                        {j === 0 ? <td valign="middle" rowSpan={therapist?.therapistTransaction?.length}>{index + 1}</td> : null}
                                                                        <td>{t?.firstname + ' ' + t?.lastname}</td>
                                                                        <td>{t?.email}</td>
                                                                        <td>{t?.stripeConnectedAccountId}</td>
                                                                        <td>
                                                                            {t?.recentTransaction ? Math.round(t?.recentTransaction[0]?.accumulatedTotalEarnings * 100) / 100 : null}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                calculateTotal(t?.recentTransaction)
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                t?.recentTransaction?.length > 0 && (
                                                                                    <button onClick={() => handleClickViewMore(t, therapist)} className="btn btn-primary">Details</button>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                t?.recentTransaction?.length > 0 && t?.paidStatus != "paid" && t?.paidStatus != "unpaid" && therapist?.verifiedStatus != "DECLINED" && therapist?.verifiedStatus != "APPROVED" && therapist?.verifiedStatus != "IGNORED" && (
                                                                                    <button className="btn btn-success"
                                                                                        disabled={disabledPayBtn && monthlyPamentId == therapist?._id}
                                                                                        onClick={() => transferAmmountByTherapist(t?.recentTransaction, t?._id, therapist?._id)}
                                                                                    >
                                                                                        Pay
                                                                                    </button>
                                                                                )
                                                                            }
                                                                            {
                                                                                t?.recentTransaction?.length > 0 && t?.paidStatus == "paid" && (
                                                                                    <div className="d-flex">
                                                                                        <span className="verified-2 mt-2"
                                                                                        >
                                                                                            Paid
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {/* {
                                                                                t?.recentTransaction?.length > 0 && therapist?.verifiedStatus == "DECLARED" && t?.paidStatus != "paid" && (
                                                                                    <div className="d-flex">
                                                                                        <span className="cancel-2 mt-2"
                                                                                        >
                                                                                            unpaid
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            } */}
                                                                        </td>

                                                                        {j === 0 ? <td valign="middle" rowSpan={therapist?.therapistTransaction?.length}>{(therapist?.timePeriodStartAt && therapist?.timePeriodEndAt) ? `${moment(therapist?.timePeriodStartAt).format("YYYY-MM-DD")}` + '(' + `${moment(therapist?.timePeriodStartAt).format("h:mm:ss a")}` + ') - ' + `${moment(therapist?.timePeriodEndAt).format("YYYY-MM-DD")}` + '(' + `${moment(therapist?.timePeriodEndAt).format("h:mm:ss a")})` : moment(therapist?.createdAt.toString()).format('YYYY/MM/DD')}</td> : null}
                                                                        {j === 0 ? <td valign="middle" rowSpan={therapist?.therapistTransaction?.length}>{therapist?.verifiedStatus}</td> : null}
                                                                        {j === 0 ? (
                                                                            <td valign="middle" rowSpan={therapist?.therapistTransaction?.length}>
                                                                                <button className="btn btn-success mr-2" onClick={approvePayment} disabled={therapist?.verifiedStatus === "PENDING" ? false : true} data-value1={therapist._id} data-value2={true}>Approve</button>

                                                                                <button className="btn btn-warning mr-2" onClick={declarePayment} disabled={therapist?.verifiedStatus === "PENDING" ? false : true} data-value2={true}>Decline</button>

                                                                                <button className="btn btn-danger" onClick={deletePayment} data-value1={therapist._id} data-value2={true}>Delete</button>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                );
                                                            })
                                                        ))}


                                                    </tbody>
                                                </table>
                                            </div>
                                            {paymentCount >= 10 && (
                                                <>
                                                    <button className="btn btn-warning text-center mt-4" onClick={seeMorePayments}>
                                                        {
                                                            isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                : <>See More</>
                                                        }
                                                    </button>
                                                </>
                                            )}
                                            {paymentCount == 0 && <span>No more meetings and recordings...</span>}
                                        </>
                                    ) : (
                                        <>{isLoading ? <Spinner /> : <div>No meetings and recordings</div>}</>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal isOpen={isOpenViewMoreModal} toggle={toggleViewMoreModal} centered className="modal-xl">
                        <div style={{ textAlign: "center", paddingRight: "0px" }}>
                            <span></span>
                            <Button
                                close
                                onClick={toggleViewMoreModal}
                                style={{
                                    position: "absolute",
                                    right: "15px",
                                    background: "none",
                                    border: "none",
                                    padding: "0",
                                    zIndex: "10",
                                }}
                                className="btn-s-20"
                            >
                                <img src={Close} alt="Close" className="mt-1" />
                            </Button>
                        </div>
                        <ModalBody className="ps-4 pe-4">
                            <Row>
                                <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">More Details</span>
                            </Row>
                            <div className="table-responsive">
                                {seeMoreTransactions && isOpenViewMoreModal && seeMoreTransactions.length > 0 ? (
                                    <>
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr style={{ borderBottom: "1px solid rgb(80 78 78 / 50%)", borderTop: "1px solid rgb(80 78 78 / 50%)" }}>
                                                    <th scope="col">Index</th>
                                                    <th scope="col">Client Name</th>
                                                    <th scope="col">Transction Created Date</th>
                                                    <th scope="col">Meeting Started Date</th>
                                                    <th scope="col">Meeting Type</th>
                                                    <th scope="col"></th>
                                                    <th scope="col">Insuarance Company</th>
                                                    <th scope="col">Contract Price</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    seeMoreTransactions && isOpenViewMoreModal && seeMoreTransactions?.map((transaction: any, index: number) => (
                                                        <tr key={index}>
                                                            <td><p>{index + 1}</p></td>
                                                            <td>
                                                                <p>
                                                                    {transaction?.meetingId?.clientId?.firstname} {transaction?.meetingId?.clientId?.lastname}
                                                                </p>
                                                            </td>
                                                            <td><p>{moment(transaction?._id?.createdAt).format('YYYY-MM-DD HH:mm A')}</p></td>
                                                            <td><p>
                                                                {
                                                                    transaction?.meetingId?.meetingId?.includes("Regular") ?
                                                                        moment(transaction?.meetingId?.regularMeetingDate).format('YYYY-MM-DD HH:mm A')
                                                                        : moment(transaction?.meetingId?.createdAt).format('YYYY-MM-DD HH:mm A')
                                                                }
                                                            </p></td>
                                                            <td><p>
                                                                {
                                                                    transaction?.meetingId?.meetingId?.includes("Regular") ?
                                                                        <div className="regular_call">Regular Call</div>
                                                                        : <div className="normal_call">Normal Call</div>
                                                                }
                                                            </p></td>
                                                            <td>{(transaction?.insuranceCompany && transaction?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany != transaction?.insuranceCompany) ? <i className="fa fa-flag text-success "></i> : <></>}</td>
                                                            <td>
                                                                <p>{transaction?.insuranceCompany ? transaction?.insuranceCompany : transaction?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany}</p>
                                                                <p>{(transaction?.insuranceCompany && transaction?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany != transaction?.insuranceCompany) ? transaction?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.insuranceCompany : <></>}</p>
                                                            </td>
                                                            <td><p>{transaction?.meetingId?.therapistId?.payRateType?.type == "PERCENTAGE_USER" ? `$ ${transaction?.meetingId?.clientId?.insuranceId?.insuranceCompanyId?.contractPrice}` : '-'}</p></td>
                                                            <td><p>$ {Math.round(transaction?.transactionAmount * 100) / 100}</p></td>
                                                            <td><button className="btn btn-success" onClick={() => updateMonthlyTransaction(transaction)}>Update</button></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={isTransationUpdateModal} toggle={toggleTransactionUpdateModal} centered className="modal-md">
                        <div style={{ textAlign: "center", paddingRight: "0px" }}>
                            <span></span>
                            <Button
                                close
                                onClick={toggleTransactionUpdateModal}
                                style={{
                                    position: "absolute",
                                    right: "15px",
                                    background: "none",
                                    border: "none",
                                    padding: "0",
                                    zIndex: "10",
                                }}
                                className="btn-s-20"
                            >
                                <img src={Close} alt="Close" className="mt-1" />
                            </Button>
                        </div>
                        <ModalBody className="ps-4 pe-4">
                            <Row>
                                <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Update Transation</span>
                            </Row>
                            <Row className="">
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>Amount</Label>
                                        <Input
                                            onChange={(e: any) => setUpdateTransactionData({ ...updatesTransationData, transactionAmount: e.target.value })}
                                            value={updatesTransationData?.transactionAmount}
                                            name="number" placeholder="Amount..." type="number" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="">
                                <Col lg={12}>
                                    <Label>Filter by Insurance Company</Label>
                                    <FormGroup>
                                        <Input
                                            id="exampleSelect"
                                            name="select"
                                            type="select"
                                            onChange={(e: any) => setUpdateTransactionData({ ...updatesTransationData, insuaranceCompanyName: e.target.value })}
                                            value={updatesTransationData?.insuaranceCompanyName}
                                        >
                                            <option >Select Insurance Company </option>
                                            {searchInsuranceCompanyTag &&
                                                searchInsuranceCompanyTag.map(opt => (
                                                    <option value={opt.label} key={opt.value}>
                                                        {opt.label}
                                                    </option>
                                                ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col lg={12} className="d-flex justify-content-center align-items-center">
                                    <button className="btn btn-success " onClick={() => updateTransation()}>
                                        Update
                                    </button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default AdminApprovePayment;
