export interface LavniReview {
    _id?: string;
    name?: string;
    email?: string;
    reviewMessage?: string;
    ratingValue: string;
    status: LavniReviewStatus;
    clientId?: string;
    therapistId?: string;
}

export enum LavniReviewStatus {
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    APPROVED ="APPROVED"
}