import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";

const FormFifteen = (props: any) => {
    const updateAssesmentDetails = () => {

        if (document.getElementById("fname")?.nodeValue === "") {
            toast.error("Name is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } else if (document.getElementById("lname")?.nodeValue === "") {
            toast.error("Name is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } else {
            const data = {
                fname: document.getElementById("fname")?.nodeValue,
                lname: document.getElementById("lname")?.nodeValue,
            };

            props.nextStep();
        }
    };

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
            <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>PHQ-9 Patient Depression Questionnaire</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">For initial diagnosis :</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ol>
                                                <li className="mb-2">
                                                    Patient completes PHQ-9 Quick Depression Assessment.
                                                </li>
                                                <li className="mb-2">
                                                    If there are at least 4<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s in the shaded section (including Questions #1 and #2), consider a depressive  disorder. Add score to determine severity.
                                                </li>
                                            </ol>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3"> Consider Major Depressive Disorder</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ul>
                                                <li>if there are at least 5<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s in the shaded section (one of which corresponds to Question #1 or #2)</li>
                                            </ul>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3"> Consider Other Depressive Disorder</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ul>
                                                <li>if there are 2-4<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s in the shaded section (one of which corresponds to Question #1 or #2)</li>
                                            </ul>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Card className="match-card-body-second p-3">
                                <h6 className="fw-500 mb-2" style={{ textAlign: "justify" }}><b>Note :</b>  Since the questionnaire relies on patient self-report, all responses should be verified by the clinician,  and a definitive diagnosis
                                    is made on clinical grounds taking into account how well the patient understood  the questionnaire, as well as other relevant information
                                    from the patient.   Diagnoses of Major Depressive Disorder or Other Depressive Disorder also require impairment of social,  occupational,
                                    or other important areas of functioning (Question #10) and ruling out normal bereavement, a  history of a Manic Episode (Bipolar Disorder),  and a physical disorder, medication, or other drug as the  biological cause of the depressive symptoms.</h6>
                            </Card>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">To monitor severity over time for newly diagnosed patients or patients in current treatment for  depression:</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ol>
                                                <li className="mb-2">
                                                    Patients may complete questionnaires at baseline and at regular intervals (eg, every 2 weeks) at home and bring them in at their next appointment for scoring or they may complete the questionnaire during each scheduled appointment.
                                                </li>
                                                <li className="mb-2">
                                                    Add up<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>s by column. For every<span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>: Several days = 1 More than half the days = 2 Nearly every day = 3
                                                </li>
                                                <li className="mb-2">
                                                    Add together column scores to get a TOTAL score.
                                                </li>
                                                <li className="mb-2">
                                                    Refer to the accompanying <b>PHQ-9 Scoring Box</b> to interpret the TOTAL score.
                                                </li>
                                                <li className="mb-2">
                                                    Results may be included in patient files to assist you in setting up a treatment goal, determining degree of  response, as well as guiding treatment intervention.
                                                </li>
                                            </ol>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">Scoring:  add up all checked boxes on PHQ-9</h6>
                                    </div>
                                    <div style={{ marginInlineStart: "2%" }}>
                                        <h6 className="fw-500 mb-3">
                                            <ul style={{ listStyleType: "none" }}>
                                                <li className="mb-2">
                                                    <b>For every</b><span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:check"></span>Not at all = 0; Several days = 1
                                                </li>
                                                <li className="mb-2">
                                                    More than half the days = 2; Nearly every day = 3
                                                </li>
                                            </ul>
                                        </h6>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col>
                                    <div>
                                        <h6 className="fw-600 mb-3">Interpretation of Total Score</h6>
                                    </div>
                                    <div>
                                        <Table className="clinical-table-bordered table-sm" style={{ verticalAlign: "middle" }}>
                                            <tbody>
                                                <tr style={{ textAlign: "center" }}>
                                                    <td className="fw-600" style={{ width: "30%", paddingLeft: "0.2rem" }}>Total Score</td>
                                                    <td className="fw-600" style={{ width: "70%", paddingLeft: "0.2rem" }}>Depression Severity</td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        1-4
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Minimal depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        5-9
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Mild depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        10-14
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Moderate depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        15-19
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Moderately severe depression
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="fw-500">
                                                    <td>
                                                        20-27
                                                    </td>
                                                    <td style={{ textAlign: "left", paddingLeft: "0.3rem" }}>
                                                        <div className="col-auto">
                                                            Severe depression
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "center", marginTop: "8rem" }}>
                                <div className="col-auto fw-500" style={{ fontSize: "smaller", textAlign: "center" }}><i>
                                    PHQ9 Copyright © Pfizer Inc. All rights reserved. Reproduced with permission. PRIME-MD ® is a trademark of Pfizer Inc.  A2663B 10-04-2005
                                </i></div>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                           Next
                        </button>

                        {/* <button
                            type="button"
                            className="btn btn-primary mr-2 relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={() => {
                                updateAssesmentDetails();
                            }}
                        >
                            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                        </button> */}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 15 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default FormFifteen;