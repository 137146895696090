import React, { useEffect, useState } from "react";
import NotificationContext from "../context/NotificationContext";
import { NotificationDetails } from "../models/Notification";
import { NotificationService } from "../services/NotificationService";

const SystemNotifications: React.FC<{ children: any }> = ({ children }): any => {
  const [notifications, setNotifications] = useState<NotificationDetails>(({} as NotificationDetails) || undefined);
  const [selectedClientsIdForChat, setSelectedClientsIdForChat] = useState<string>("");
  const [selectedClientsDetailsForChat, setSelectedClientsDetailsForChat] = useState<string>("");

  useEffect(() => {
    NotificationService.getNotifications(false, 10, 0).then((res: any) => {
      if (res.success) {
        setNotifications(res.data);
      }
    });
  }, []);

  return (
    <NotificationContext.Provider
      value={[
        notifications,
        setNotifications,
        selectedClientsIdForChat,
        setSelectedClientsIdForChat,
        selectedClientsDetailsForChat,
        setSelectedClientsDetailsForChat,
      ]}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default SystemNotifications;
