import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, Container } from "reactstrap";
import { FeedbackList } from '../../models/Feedback';
import { CommonService } from "../../services/CommonService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


const Support: React.FC = () => {
    const [isFeedback, setFeedback] = useState({} as FeedbackList);
    const [transcodeStatus, setTranscodeStatus] = useState("");

    const sendFeedBack = () => {
        if (isFeedback.feedback) {
            CommonService.sendFeedBack(isFeedback).then(res => {
                if (res.success) {
                    toast.success('Your feedback has been received. One of our agents will reach out to you soon.', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });

                    setFeedback({ ...isFeedback, feedback: "" });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            })
        } else {
            toast.error('Enter your feedback first.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Lavni" breadcrumbItem="Support & Feedback" />
                    <div className="row ">
                        <div className="col-lg-6 col-md-12 support-cont">
                            <Card className="details">
                                <div className="row">
                                    <div className="col">
                                        <h4>If you need help, you can:</h4>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>Medical Emergency: <a href="tel:911">911</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>National Suicide Prevention Lifeline: <a href="tel:988">988</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>National Suicide Prevention Lifeline Chat: <a href="https://suicidepreventionlifeline.org/chat/" target='_blank' rel="noreferrer">website</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>SAMHSAs National Helpline: <a href="tel:1-800-662-4357">1-800-662-4357</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col">
                                        <h4>Support page info:</h4>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>Report bug: <a href="mailto:info@mylavni.com">info@mylavni.com</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>Therapist help: <a href="mailto:crystal@mylavni.com">crystal@mylavni.com</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h5>Customer service: <a href="mailto:admin@mylavni.com">admin@mylavni.com</a></h5>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <h5>If you are experiencing domestic violence, call<br />the National Domestic Violence Hotline at 1-800-799-7233,<br />or go to <Link to={{ pathname: 'https://www.thehotline.org/' }} target="_blank">thehotline.org</Link>. All calls are toll-free and confidential.<br />The hotline is available 24/7 in more than 170 languages. </h5>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <Card className="details">
                                <div className="row">
                                    <div className="col">
                                        <h4>Feedback:</h4>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="form-group">
                                            <textarea onChange={(e: any) => setFeedback({ ...isFeedback, feedback: e.target.value })} value={isFeedback.feedback} className="form-control feedback-message" placeholder="Enter your feedback here..."></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <button className="btn btn-success" onClick={() => sendFeedBack()}>Send Feedback</button>
                                    </div>
                                </div>
                            </Card>

                            <Card className="details support-cont">
                                <div className="row">
                                    <div className="col">
                                        <h5>
                                            Para apoyo 24/7 en Salud Mental, en Inglés o Español,
                                            llame a la l nea libre de cargos de la Administraci n de
                                            Servicios de Salud Mental y Abuso de Sustancias al 800-662-4357.
                                            También es puede comunicarse con un Consejero capacitado en intervenci
                                            n en crisis usando la l nea &quot;Lifeline&quot; llamando o enviando un mensaje de texto al 988,
                                            o comunic ndose al servicio de Textos en Crisis enviando la palabra &quot;HOME&quot; al 741741
                                        </h5>
                                    </div>
                                </div>
                            </Card>

                            <Card className="details">
                                <div className="row">
                                    <div className="col">
                                        <h4>How to sign up for payment</h4>
                                    </div>
                                </div>
                                <div id="videoModal2" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
                                    <>
                                        {
                                            transcodeStatus == "in_progress" ? (
                                                <div className="transcode-status-profile">
                                                    Video is still transcoding! Will available in a minute!
                                                </div>
                                            ) : (
                                                <div className="modal-body1">
                                                    <iframe src={'https://player.vimeo.com/video/760233559'} width="800" className="videoSize" height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                                                </div>
                                            )
                                        }
                                    </>
                                </div>
                            </Card>

                            <Card className="details">
                                <div className="row">
                                    <div className="col">
                                        <h4>How to access resources on lavni</h4>
                                    </div>
                                </div>
                                <div id="videoModal2" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
                                    <>
                                        {
                                            transcodeStatus == "in_progress" ? (
                                                <div className="transcode-status-profile">
                                                    Video is still transcoding! Will available in a minute!
                                                </div>
                                            ) : (
                                                <div className="modal-body1">
                                                    <iframe src={'https://player.vimeo.com/video/745875662'} width="800" className="videoSize" height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                                                </div>
                                            )
                                        }
                                    </>
                                </div>
                            </Card>

                            <Card className="details">
                                <div className="row">
                                    <div className="col">
                                        <h4>How to match with a therapist & schedule an appointment</h4>
                                    </div>
                                </div>
                                <div id="videoModal2" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
                                    <>
                                        {
                                            transcodeStatus == "in_progress" ? (
                                                <div className="transcode-status-profile">
                                                    Video is still transcoding! Will available in a minute!
                                                </div>
                                            ) : (
                                                <div className="modal-body1">
                                                    <iframe src={'https://player.vimeo.com/video/745875234'} width="800" className="videoSize" height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                                                </div>
                                            )
                                        }
                                    </>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Support;