import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, NavLink } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { Report, ReviewStatus } from "../../models/Report";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Spinner from "../../common/spinner/spinner";

export const ReportReviews: React.FC = () => {
  const [reportList, setReportList] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const LIMIT = 10;
  const [seeMore, setSeeMore] = useState(false);
  const [isOffset, setOffset] = useState<number>(0);

  useEffect(() => {
    getAllReportReviews();
  }, []);

  const getAllReportReviews = () => {
    setIsLoading(true);
    AdminService.getAllReportReviews(LIMIT, isOffset).then(res => {
      if (res.success) {
        setReportList(res.data);
      } else {
        setReportList([]);
      }
      setIsLoading(false);
    });
  };

  const seeMoreFc = () => {
    const updatedOffset = isOffset + LIMIT;
    setOffset(updatedOffset);

    AdminService.getAllReportReviews(LIMIT, updatedOffset).then(res => {
      if (res.success) {
        setReportList(prev => {
          return [...new Set([...prev, ...res.data])];
        });
      }
    });
  }

  const blockUser = (userId: string, reportId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to block?",
      showCancelButton: true,
      confirmButtonText: "Block",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        const data = {
          userId: userId,
          reportId: reportId,
        };
        AdminService.blockUserByAdmin(data).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "User blocked!",
              confirmButtonColor: "#FD7F00",
            });
            getAllReportReviews();
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to block the user.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  const unblockUser = (userId: string, reportId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to unblock?",
      showCancelButton: true,
      confirmButtonText: "Block",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        const data = {
          userId: userId,
          reportId: reportId,
        };
        AdminService.unblockUserByAdmin(data).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "User unblocked!",
              confirmButtonColor: "#FD7F00",
            });
            getAllReportReviews();
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to unblock the user.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  const redirectToProfile = (userId: string | undefined) => {
    history.push(`/profile/${userId}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Report Reviews"} breadcrumbItem={"Report Reviews"} />
          <Card>
            <CardBody>
              {isLoading ? ( 
                <Spinner />
              ) : (
                <Row>
                  <Col xl="12" className="mt10">
                    <div className="table-responsive">
                      {reportList && reportList?.length > 0 ? (
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Reported By</th>
                              <th scope="col">Reported</th>
                              <th scope="col">Reason</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportList &&
                              reportList.map((report, index) => (
                                <tr key={report?._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    <NavLink
                                      onClick={() => {
                                        redirectToProfile(report?.reportedBy?._id);
                                      }}
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      {report?.reportedBy?.firstname} {report?.reportedBy?.lastname}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink
                                      onClick={() => {
                                        redirectToProfile(report?.reported._id);
                                      }}
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      {report?.reported?.firstname} {report?.reported?.lastname}
                                    </NavLink>
                                  </td>
                                  <td>{report?.reason}</td>
                                  <td>
                                    {report?.status === ReviewStatus.PENDING || report?.status === ReviewStatus.UNBLOCKED ? (
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => {
                                          blockUser(report?.reported._id, report._id);
                                        }}
                                      >
                                        Block
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                          unblockUser(report.reported._id, report._id);
                                        }}
                                      >
                                        Unblock
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <div>No Reports to review.</div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xl="12 mt30 text-center">
                  {seeMore && (
                      <button className="btn btn-warning" onClick={() => seeMoreFc()}>
                      See More
                      </button>
                  )}
                  </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
