import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Input, Button, ButtonGroup, Label, UncontrolledTooltip } from "reactstrap";
import { AdminService } from "../../services/AdminService";
import { UserRole } from "../../models/User";
import { PayStatus, ReferralEarning, RewardType } from '../../models/ReferralEarning';
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import TherapistRewardContext from "src/context/TherapistRewardCount";

const ReferralEarnings: React.FC = () => {
  const [referralEarnings, setReferralEarnings] = useState<ReferralEarning[]>([]);
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isRewardType, setIsRewardType] = useState(RewardType.ALL as RewardType);
  const [payStatus, setPayStatus] = useState(PayStatus.UNPAID as PayStatus);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [referralEarningsCount, setReferralEarningsCount] = useState(10);
  const [count, setCount] = useState(0);
  const [referralPaymentId, setReferralPaymentId] = useState('');
  const [disabledPayBtn, setDisabledPayBtn] = useState(false);
  const [therapistRewardCount, getAllTherapistRewardCount] = useContext(TherapistRewardContext);

  useEffect(() => {
    getAllReferralEarningBlances();
  }, []);

  useEffect(() => {
    searchReferralEarnings(true);
  }, []);

  const getAllReferralEarningBlances = () => {
    setIsLoading(true);
    AdminService.getAllReferralEarnings(limit, offset).then(res => {
      if (res.success) {
        const earnings = res.data.therapistRewardsList.map((earning: { [x: string]: any; therapistId: any; }) => {

          if (!earning.paidStatus) {
            earning.paidStatus = "UNPAID";
          }

          const { therapistId, ...rest } = earning;

          return {
            ...rest,
            therapistId: therapistId?._id || null,
            email: therapistId?.email || null,
            firstname: therapistId?.firstname || null,
            lastname: therapistId?.lastname || null,
          };
        });
        setReferralEarnings(earnings);
        setReferralEarningsCount(earnings.length);
      }
      setIsLoading(false);
    });
  };

  const searchReferralEarnings = (onload: boolean) => {
    const data = {
      searchableString: isSearchableString,
      rewardType: isRewardType,
      payStatus: payStatus
    }

    setIsLoading(true);

    AdminService.searchAllReferralEarningsByAdmin(data, limit, offset).then(res => {
      if (res.success) {
        setReferralEarnings(res.data.userSet);
        setReferralEarningsCount(res.data.userSet.length);
        setCount(res.data?.totalCount)
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  }

  const filterTherapists = () => {
    const data = {
      searchableString: isSearchableString,
      rewardType: isRewardType,
      payStatus: payStatus
    }

    setIsLoading(true);

    const updatedOfsetSkip = 1;
    setOffset(updatedOfsetSkip);

    AdminService.searchAllReferralEarningsByAdmin(data, limit, 1).then(res => {
      if (res.success) {
        setReferralEarnings(res.data.userSet);
        setReferralEarningsCount(res.data.userSet.length);
        setCount(res.data?.totalCount)
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  }

  const seeMore = () => {
    setIsLoading(true);

    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    const data = {
      searchableString: isSearchableString,
      rewardType: isRewardType,
      payStatus: payStatus
    }

    AdminService.searchAllReferralEarningsByAdmin(data, limit, updatedSkip).then(res => {
      if (res.success) {
        res.data.userSet.map((referralEarning: any) => {
          setReferralEarnings(referralEarnings => [...referralEarnings, referralEarning]);
        });

        setReferralEarningsCount(res.data.userSet.length);
      }

      setIsLoading(false);
    });
  }

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const data = {
        searchableString: isSearchableString,
        rewardType: isRewardType,
        payStatus: payStatus
      }

      setIsLoading(true);

      AdminService.searchAllReferralEarningsByAdmin(data, limit, offset).then(res => {
        if (res.success) {
          setReferralEarnings(res.data.userSet);
          setReferralEarningsCount(res.data.userSet.length);
        }

        setIsLoading(false);

        if (!onload) {
          setFilterOptions(!isFilterOptions);
        }
      });
    }
  };

  const approvePayment = (e: any) => {
    const referralEarningId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
        icon: "info",
        title: "Are you sure want to approve this referral earning record?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            referralEarningId: referralEarningId,
            verifiedStatus: "APPROVED"
          }
          AdminService.approveOrDeclineReferralEarningRecord(data).then(res => {
            if (res.success) {
              getAllReferralEarningBlances();
              getAllTherapistRewardCount(therapistRewardCount - 1);
              toast.success(res.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            } else {
              toast.error(res.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            }
          });
        }
    });
  }

  const declinePayment = (e: any) => {
    const referralEarningId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
        icon: "info",
        title: "Are you sure want to decline this referral earning record?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            referralEarningId: referralEarningId,
            verifiedStatus: "DECLINED"
          }
          AdminService.approveOrDeclineReferralEarningRecord(data).then(res => {
            if (res.success) {
              getAllReferralEarningBlances();
              toast.success(res.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            } else {
              toast.error(res.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            }
          });
        }
    });
  }

  const deletePayment = (e: any) => {
    const referralEarningId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
        icon: "info",
        title: "Are you sure want to delete this referral earning record?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
            AdminService.deleteReferralEarningRecord(referralEarningId).then(res => {
                if (res.success) {
                    getAllReferralEarningBlances();
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        }
    });
  }

  const processReferralEarningPayment = (therapistId: string, referralEarningId: string) => {
    Swal.fire({
        icon: "warning",
        title: "Are you sure want to pay?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
            setDisabledPayBtn(true)
            setReferralPaymentId(referralEarningId)

            if (referralEarningId && therapistId) {
                const data = {
                    therapistId: therapistId,
                    referralEarningId: referralEarningId
                }
                AdminService.transferReferralEarningPayment(data).then((res: any) => {
                    if (res.success) {
                        updatePaidStatus(referralEarningId)
                    } else {
                        toast.error(res?.error || res?.errorData?.raw?.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                        setDisabledPayBtn(false)
                        setReferralPaymentId('')
                    }
                })
            } else {
                toast.error("Invalid referral earning id.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                setDisabledPayBtn(false)
                setReferralPaymentId('')
            }
        }
    });
  }

  const updatePaidStatus = (referralEarningId: string) => {
    const therapistObj = referralEarnings.find(obj => obj._id === referralEarningId);
    if (therapistObj) {
      therapistObj.paidStatus = "PAID";
    }
    toast.success("Referral Earning payment Paid Successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
    });
    setDisabledPayBtn(false)
    setReferralPaymentId('')
  };

  const bulkPaymentForRewards = () => {
    Swal.fire({
        icon: "warning",
        title: "Select an option: Pay 'Approved Only' or 'Approved or Pending' as a bulk payment?",
        html: `
          <div>
            <label>
              <input type="radio" name="bulkPaymentType" value="APPROVED_ONLY" checked>
              Approved Only
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="bulkPaymentType" value="APPROVED_OR_PENDING">
              Approved or Pending
            </label>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
        preConfirm: () => {
          const selectedOptionElement = document.querySelector('input[name="bulkPaymentType"]:checked') as HTMLInputElement;
          if (selectedOptionElement) {
              const selectedOption = selectedOptionElement.value;
              return { bulkPaymentType: selectedOption };
          } else {
              Swal.showValidationMessage('Please select an option');
              return false;
          }
        }
    }).then(result => {
        if (result.isConfirmed) {
            const data = result.value;
            AdminService.payReferralBonusOfCurrentMonthAsBulk(data).then(res => {
                if (res.success) {
                  getAllReferralEarningBlances();
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        }
    });
  };
  
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Therapists"} breadcrumbItem={"Referral Earnings"} />
            <Row>
                <Col xl={12}>
                    <button
                        className="btn btn-primary relative mb-3"
                        style={{
                            bottom: "0",
                            float: "right",
                        }}
                        onClick={bulkPaymentForRewards}
                    >
                        Pay Rewards for Current Month
                    </button>
                </Col>
            </Row>
            <Card className="m-h">
              <CardBody>
                
                <Row className="d-flex justify-content-between align-items-center">
                  <div className="d-flex admin-filter">
                    <button className="btn btn-sm mr-2" onClick={toggleFilter}>Filter <i className='bx bx-filter-alt'></i></button>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="d-flex me-2 me-lg-4">
                      <div className="reward-type-dot own-first-bg"></div>
                      <span className="d-none d-sm-block">OWNFIRST <i className='bx bx-info-circle' id="OWNFIRST"></i></span>
                      <UncontrolledTooltip placement="bottom" target={"OWNFIRST"}>
                        First session completion bonus for therapist who SignUp using a referral link.
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex me-2 me-lg-4">
                      <div className="reward-type-dot refer-first-bg"></div>
                      <span className="d-none d-sm-block">REFERFIRST <i className='bx bx-info-circle' id="REFERFIRST"></i></span>
                      <UncontrolledTooltip placement="bottom" target={"REFERFIRST"}>
                        First session completion bonus for therapist who generated the referral link.
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex me-2 me-lg-4">
                      <div className="reward-type-dot own-second-bg"></div>
                      <span className="d-none d-sm-block">OWNSECOND <i className='bx bx-info-circle' id="OWNSECOND"></i></span>
                      <UncontrolledTooltip placement="bottom" target={"OWNSECOND"}>
                        Twenty sessions completion bonus for therapist who SignUp using a referral link.
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex me-2 me-lg-4">
                      <div className="reward-type-dot refer-second-bg"></div>
                      <span className="d-none d-sm-block">REFERSECOND <i className='bx bx-info-circle' id="REFERSECOND"></i></span>
                      <UncontrolledTooltip placement="bottom" target={"REFERSECOND"}>
                        Twenty sessions completion bonus for therapist who generated the referral link.
                      </UncontrolledTooltip>
                    </div>
                  </div>

                </Row>

                <Row>
                  <Col sm="12" className="mt10">
                    <div className="table-responsive" style={{ minHeight: "70vh" }}>
                      {referralEarnings !== undefined && referralEarnings.length > 0 ? (
                        <>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Reward Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Paid Status</th>
                                <th scope="col">Verified Status</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {referralEarnings &&
                                referralEarnings.map((referralEarning, index) => (
                                  <tr key={referralEarning._id}>
                                    <td scope="row" valign="middle">{index + 1}</td>

                                    <td valign="middle">{referralEarning?.firstname}&nbsp;{referralEarning?.lastname}</td>

                                    <td valign="middle">{referralEarning?.email}</td>

                                    <td valign="middle">
                                      { 
                                        (referralEarning.rewardType == RewardType.OWNFIRST) && (
                                          <span className="own-first-bg reward-bg-pad">{referralEarning.rewardType}</span>
                                        )
                                      }
                                      { 
                                        (referralEarning.rewardType == RewardType.REFERFIRST) && (
                                          <span className="refer-first-bg reward-bg-pad">{referralEarning.rewardType}</span>
                                        )
                                      }
                                      { 
                                        (referralEarning.rewardType == RewardType.OWNSECOND) && (
                                          <span className="own-second-bg reward-bg-pad">{referralEarning.rewardType}</span>
                                        )
                                      }
                                      { 
                                        (referralEarning.rewardType == RewardType.REFERSECOND) && (
                                          <span className="refer-second-bg reward-bg-pad">{referralEarning.rewardType}</span>
                                        )
                                      }
                                    </td>
                                    
                                    <td valign="middle">${referralEarning.transactionAmount}</td>

                                    <td valign="middle">
                                        {
                                            referralEarning?.verifiedStatus === "APPROVED" && (referralEarning.paidStatus === undefined || referralEarning.paidStatus == "UNPAID") && (
                                                <button className="btn btn-success"
                                                    disabled={disabledPayBtn && referralPaymentId == referralEarning?._id}
                                                    onClick={() => processReferralEarningPayment(referralEarning?.therapistId, referralEarning?._id)}
                                                >
                                                    Pay
                                                </button>
                                            )
                                        }
                                        {
                                            (referralEarning?.paidStatus) && referralEarning?.paidStatus == "PAID" && (
                                                <div className="d-flex">
                                                    <span className="verified-2 mt-2"
                                                    >
                                                        Paid
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </td>

                                    <td valign="middle">{referralEarning?.verifiedStatus}</td>

                                    <td valign="middle">
                                        <button className="btn btn-success mr-2" onClick={approvePayment} disabled={referralEarning?.verifiedStatus === "PENDING" ? false : true} data-value1={referralEarning._id} data-value2={true}>Approve</button>

                                        <button className="btn btn-warning mr-2" onClick={declinePayment} disabled={referralEarning?.verifiedStatus === "PENDING" ? false : true} data-value1={referralEarning._id} data-value2={true}>Decline</button>

                                        <button className="btn btn-danger" onClick={deletePayment} data-value1={referralEarning._id} data-value2={true}>Delete</button>
                                    </td>

                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>

                          {
                            referralEarningsCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                  {
                                    isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : <>See More</>
                                  }
                                </button>
                              </>
                            )
                          }

                          {referralEarningsCount == 0 && <span>No more referral earnings available...</span>}
                        </>
                      ) : (
                        <>
                          {
                            isLoading ? (
                              <Spinner />
                            ) : (
                              <><div>No Referral Earnings</div></>
                            )
                          }
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        <Offcanvas
          className="offcanvas-end2"
          isOpen={isFilterOptions}
          direction="end"
          toggle={toggleFilter}>
          <OffcanvasHeader toggle={toggleFilter}>
            Filter Referral Earnings
          </OffcanvasHeader>
          <OffcanvasBody>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Name or Email</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search Users (ex: first name, last name or email)"
                    type="text"
                    onKeyPress={handleKeyPress}
                    onChange={e => setIsSearchableString(e.target.value)}
                    value={isSearchableString}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={12}>
                <Label>Reward Type</Label>
                <ButtonGroup className="flex-wrap flex">
                  <Button color="primary" defaultChecked defaultValue={RewardType.ALL} outline onClick={() => setIsRewardType(RewardType.ALL)} active={isRewardType === RewardType.ALL}>All</Button>
                  <Button color="primary" outline onClick={() => setIsRewardType(RewardType.OWNFIRST)} active={isRewardType === RewardType.OWNFIRST}>Own First</Button>
                  <Button color="primary" outline onClick={() => setIsRewardType(RewardType.REFERFIRST)} active={isRewardType === RewardType.REFERFIRST}>Refer First</Button>
                  <Button color="primary" outline onClick={() => setIsRewardType(RewardType.OWNSECOND)} active={isRewardType === RewardType.OWNSECOND}>Own Second</Button>
                  <Button color="primary" outline onClick={() => setIsRewardType(RewardType.REFERSECOND)} active={isRewardType === RewardType.REFERSECOND}>Refer Second</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <Label>Paid Status</Label>
                <ButtonGroup className="flex-wrap flex">
                  <Button color="primary" outline onClick={() => setPayStatus(PayStatus.ALL)} active={payStatus === PayStatus.ALL}>All</Button>
                  <Button color="primary" outline onClick={() => setPayStatus(PayStatus.PAID)} active={payStatus === PayStatus.PAID}>Paid</Button>
                  <Button color="primary" defaultChecked defaultValue={PayStatus.UNPAID} outline onClick={() => setPayStatus(PayStatus.UNPAID)} active={payStatus === PayStatus.UNPAID}>Unpaid</Button>
                </ButtonGroup>
              </Col>
            </Row>

            <div className="col-xl-12 mb-3 mt-5">
              <Col lg={12}>
                <button className="btn btn-success w-100" onClick={() => filterTherapists()}>Search</button>
              </Col>
            </div>
          </OffcanvasBody>
        </Offcanvas>

      </React.Fragment>
    </>
  );
};

export default ReferralEarnings;