import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import { CommonService } from "src/services/CommonService";
import html2PDF from "jspdf-html2canvas";
import Swal from "sweetalert2";
import { DigitalForm } from "src/models/AssessmentDigitalForm/AssessmentDigitalForms";
import AssessmentForm from "./AssessmentForm";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import { FormVersionService } from "src/services/FormVersionService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

toast.configure();

const PdfGeneratePage: React.FC<{ identifierId: any; isAdmin: boolean; onPdfComplete: () => void; digitalAssessmentType: TPType }> = props => {
  const [buttonText, setButtonText] = useState("Download Clinical Assessment");
  const [isDisabled, setIsDisabled] = useState(false);
  const identifierId = props.identifierId;
  const isAdmin = props.isAdmin;
  const digitalAssessmentType = props.digitalAssessmentType;
  const [assesmenData, setAssesmenData] = useState<DigitalForm>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
    },
    generalInformation: {
      name: "",
      dob: "",
      knownAs: "",
      age: "",
      primaryLanguage: {
        primaryLanguageReadablityStatus: null,
        english: null,
        other: null,
        otherLanguage: "",
      },
      consumerPhone: {
        consumerPhone: {
          home: null,
          work: null,
          cellPhone: null,
          other: null,
        },
        otherConsumerPhoneType: "",
        otherConsumerPhoneNumber: "",
      },
      legalGuarianship: {
        legalGuarianship: {
          self: null,
          other: null,
        },
        otherLegalGuarianship: "",
      },
      guardianPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
        guardianPhoneNumber: "",
      },
      primaryInformant: {
        primaryInformant: {
          self: null,
          other: null,
        },
        other: "",
      },
      informantPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
      },
      genderAtBirth: {
        male: null,
        female: null,
        femalePregnant: null,
      },
      genderIdentity: {
        male: null,
        female: null,
        nonBinary: null,
      },
      sexualOrientation: {
        sexualOrientations: "",
        isInterestingLGBTService: null,
      },
      ethnicity: {
        notHispanicOrigin: null,
        hispanicCuban: null,
        hispanicMexicanAmerican: null,
        hispanicPuertoRican: null,
        hispanicOther: null,
      },
      raceType: {
        race: {
          blackAfricanAmerican: null,
          alaskaNative: null,
          whiteCaucasian: null,
          asian: null,
          americanIndianNativeAmerican: null,
          pacificIslander: null,
          multiracial: null,
          other: null,
        },
        otherRace: "",
      },
      livingArrangement: {
        livingArrangement: {
          privateResidence: null,
          institution: null,
          ownResidence: null,
          other: null,
          roomHouseDorm: null,
          adultCareHome: null,
          alternativeFamilyLiving: null,
          nursingHome: null,
          communityICFMR: null,
          correctionalFacility: null,
          homeless: null,
          residentialFacility: null,
        },
        other: "",
      },
      maritalStatus: {
        married: null,
        widowed: null,
        divorced: null,
        singleNeverMarried: null,
        separated: null,
        domesticPartnership: null,
      },
      familySize: "",
      currentEmploymentStatus: {
        unemployed: null,
        notavailableforwork: null,
        employedFullTime: null,
        homemaker: null,
        armedForcesNationalGuard: null,
        employedPartTime: null,
        retired: null,
        student: null,
        disabilityIncome: null,
      },
      employmentHistory: "",
      education: {
        highestGradeCompleted: null,
        highSchoolgraduate: null,
        ged: null,
        someCollege: null,
        associateDegree: null,
        bachelordegree: null,
        graduateSchool: null,
        specialEdClasses: null,
        technicalTradeSchool: null,
      },
    },
    presentingProblem: {
      description: "",
      historyOfProblem: "",
    },
    historyOfProblem: "",
    symptomChicklist: {
      depressiveSymptoms: { symtoms: {}, comment: "" },
      manicSymptoms: { symtoms: {}, comment: "" },
      conductLegalProblem: { symtoms: {}, comment: "" },
      psychosis: { symtoms: {}, comment: "" },
      anxietySymptoms: { symtoms: {}, comment: "" },
      attentionSymptoms: { symtoms: {}, comment: "" },
    },
    biologicalFunction: {
      sleep: { sleepStatus: {}, comment: "" },
      nutritionalStatus: { nutritionalStatus: {}, comment: "" },
      otherBiologicalFunction: {
        amenorrhea: "",
        encopresis: "",
        increased: "",
        decreased: "",
        other: "",
        enuresis: "",
      },
      sexualActivity: {
        sexuallyActive: { sexuallyActiveness: null, activeWith: "" },
        protectionAgainstHepatitisHiv: { protection: null, how: "" },
        protectionAgainstPregnancy: { protection: null, how: "" },
        atRiskBehavior: { risk: null, describe: "" },
        otherSymtoms: "",
        comments: "",
      },
    },
    alcoholAndDrugUseHistory: {
      historyStatus: "",
    },
    nameOfSubstance: [
      {
        ageOfFirstUse: "",
        regularUse: "",
        dateLastUse: "",
        route: "",
        amount: 0,
        frequency: 0,
        problemWithUse: {},
        comment: "",
      },
    ],
    tobaccoUseTypeDetails: {
      tobaccoUse: null,
      tobaccoType: "",
      howOften: "",
      howMuch: "",
      howLong: "",
      interestEndUse: null,
      interestReducingUse: null,
      endingUse: "",
      longestAbstinence: "",
      whenWas: "",
      relapse: "",
      comment: "",
      experiencedSymptoms: {
        agitationRestlessness: "",
        confusion: "",
        nauseaVomiting: "",
        muscleTwitching: "",
        chills: "",
        deliriumTremens: "",
        tremorsShaking: "",
        anxiety: "",
        racingPulse: "",
        rapidBreathing: "",
        sweats: "",
        hallucinations: "",
        backJointPain: "",
        other: "",
        difficultySleeping: "",
        runnyNose: "",
        tearyEyes: "",
        craving: "",
        cramps: "",
        seizures: "",
        none: "",
      },
    },
    releventMedicalInformation: {
      physicianDiagnosedConditions: {
        allergies: "",
        gynecological: "",
        pancreatitis: "",
        anemia: "",
        headInjury: "",
        respiratory: "",
        arthritis: "",
        heartDisease: "",
        seizureDisorder: "",
        asthma: "",
        hepatitis: "",
        std: "",
        brainDisorder: "",
        highBloodPressure: "",
        stroke: "",
        cancer: "",
        lowBloodPressure: "",
        thyroidDisease: "",
        chronicPain: "",
        cirrhosisoftheliver: "",
        immuneDisease: "",
        tuberculosis: "",
        diabetes: "",
        kidneyDisease: "",
        ulcer: "",
        eatingDisorder: "",
        muscleDisorder: "",
        mensHealthProblems: "",
        hivAids: "",
        none: "",
        other: "",
      },
      cancerType: "",
      otherType: "",
      lastVisitPrimaryCarePhysician: "",
      comment: "",
      anyAllergies: "",
      hypersensitivities: "",
      patientPainLevel: {
        currentLevelOfPhysicalPain: 0,
        isCurrentReceivingTreatementPain: "",
        isReceivingTreatement: "",
        treatementDetails: [],
        disablityStatus: {
          hearingImpairment: "",
          sightImpairment: "",
          intellectualDevelopmentalDisability: "",
          other: "",
          none: "",
          comment: "",
        },
        adjustDisability: "",
        requireEquipmentOrServices: "",
      },
    },
    outPatientTreatementHistory: {
      applicableState: null,
      outPatienttreatementHistoryDetails: [],
    },
    inpatientTreatementHistory: {
      applicableState: "",
      inPatientTreatementHistory: [],
      pastTreatement: "",
      additionalMedicalInformation: "",
    },
    bioPsychosocialDevelopemntHistory: {
      developmentHistory: "",
      childhoodHistory: "",
      fosterCare: "",
      siblings: "",
      familyHistoryOfMHOrSAIssues: "",
      currentSpouse: "",
      childrenStepChildren: "",
      relationsIssues: "",
      otherSupports: {
        family: "",
        church: "",
        employer: "",
        friends: "",
        other: "",
      },
    },
    bioPsychosocialEducation: {
      isApplicable: null,
      schoolGradeName: "",
      lastIep: "",
      teacher: "",
      learningAbility: "",
      disabilityArea: "",
      educationalProblems: "",
      behaviorProblem: "",
      repetedGrades: "",
      socialInteraction: "",
      suspension: "",
      expulsion: "",
      isPsychologicalTestingCompleted: null,
      date: "",
      expulsionTestResults: "",
    },
    traumaHistory: {
      isBadAccident: null,
      badAccidentDescribe: "",
      isNaturalDisaster: null,
      naturalDisasterdDescribe: "",
      isMilitaryCombat: null,
      militoryCombatDescribe: "",
      isSeriousAttack: null,
      seriousAttackDescribe: "",
      isSexualCoercion: null,
      ageOfSexualCoercion: 0,
      describeSexualCoercion: "",
      isTraumaticExperience: null,
      describeTraumaticExperience: "",
      intrusiveThoughts: null,
      avoidanceBehavior: null,
      hypervigilance: null,
      emotionalDetachment: null,
      selfBlame: null,
    },
    riskToSelfAndOthers: {
      currentRiskToSelf: {
        isSelfInjured: null,
        describeSelfInjured: "",
        isSuicidalThreats: null,
        describeSuicidalThreats: "",
        repetitiveIdeation: null,
        isSuicidePlan: null,
        isLethalIntent: null,
        isCommandHallucinations: null,
        describeCommandHallucinations: "",
      },
      currentRiskToOthers: {
        isHomicidalThreats: null,
        isPersistentIdeation: null,
        isSpecificPlan: null,
        isLethalIntent: null,
        isHallucinationCommands: null,
        describeHallucination: "",
      },
      historyOfSuicidalBehavior: {
        isHistoryOfSuicidalBehavior: null,
        describeHistoryOfSuicidalBehavior: "",
        isHomicidalBehavior: null,
        describeHomicidalBehavior: "",
        isSeriousHarmSelfOthers: null,
        describeSeriousPhysicalHarm: "",
        isAggressionViolenceOthers: null,
        describeAggression: "",
      },
      evaluationOfRisk: {
        selfHarming: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        assaultive: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        actionEvaluation: "",
        beliefSystem: "",
        roleOfBeliefinlife: "",
        roleOfBeliefRecovery: "",
      },
    },
    mentalStatus: {
      physicalStature: {
        small: null,
        average: null,
        tall: null,
      },
      weight: {
        thin: null,
        average: null,
        overweight: null,
        obese: null,
      },
      grooming: {
        wellGroomed: null,
        normal: null,
        neglected: null,
        bizarre: null,
      },
      clothing: {
        neatClean: null,
        inappropriate: null,
        dirty: null,
        seductive: null,
        disheveled: null,
        bizarre: null,
      },
      posture: {
        normal: null,
        tense: null,
        stooped: null,
        rigid: null,
        slumped: null,
        bizarre: null,
      },
      attitude: {
        cooperative: null,
        passive: null,
        guarded: null,
        irritable: null,
        manipulative: null,
        seductive: null,
        suspicious: null,
        defensive: null,
        dramatic: null,
        silly: null,
        hostile: null,
        critical: null,
        resistant: null,
        sarcastic: null,
        uninterested: null,
        argumentative: null,
      },
      motor: {
        nonremarkable: null,
        tremor: null,
        slowed: null,
        tics: null,
        restless: null,
        agitated: null,
      },
      speech: {
        normal: null,
        rapid: null,
        slurred: null,
        loud: null,
        paucity: null,
        pressured: null,
        mute: null,
      },
      affect: {
        appropriate: null,
        inappropriate: null,
        flat: null,
        restricted: null,
        blunted: null,
        labile: null,
      },
      mood: {
        euthymic: null,
        confused: null,
        pessimistic: null,
        depressed: null,
        anxious: null,
        euphoric: null,
        apathetic: null,
        angry: null,
      },
      thoughtForm: {
        goaldirected: null,
        appropriate: null,
        logical: null,
        tangentialthinking: null,
        circumstantial: null,
        looseassociations: null,
        confused: null,
        incoherent: null,
        perseverations: null,
        flightofidea: null,
        slownessofthought: null,
      },
      thoughtContent: {
        appropriate: null,
        paranoid: null,
        suspicions: null,
        persecutions: null,
        paucity: null,
        delusions: null,
        bizarre: null,
        hypochondriac: null,
        ideasofreference: null,
      },
      preoccupations: {
        phobias: null,
        guilt: null,
        other: null,
        somatic: null,
        religion: null,
        suicide: null,
        homicidal: null,
      },
      hallucinations: {
        auditory: null,
        other: null,
        visual: null,
        sensory: null,
      },
      orientation: {
        person: null,
        place: null,
        time: null,
        situation: null,
      },
      levelOfIntellectualFunctioning: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      fundofKnowledge: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      judgment: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      insightIntoProblems: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      clinicalImpressionSummary: "",
    },
    releventDDSInformation: {
      ddsInvolvement: null,
      ddsCare: "",
      ddsCareDescription: "",
      ddsSocialWorker: "",
      phone: "",
    },
    relevantLegalInformation: {
      legalInvolvement: null,
      countOfArrestet: 0,
      currentCharges: "",
      attorneyName: "",
      currentProbationRequirement: "",
      probationOffice: "",
      previousChargesJailOrPrison: "",
    },
    diagnoseRecommendationDetails: {
      // diagnosis: {
      //   code: "",
      //   diagnosis: [],
      // },
      diagnosis: [],
      recommendation: {},
      printedName: "",
      signature: "",
      date: "",
      dateOfClinicianSignature: "",
    },
  });

  const childRef = useRef<{ getRowHeights: () => { height: number; element: HTMLDivElement }[] }>(null);

  const contentRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   // Get updated heights when component mounts
  //   if (childRef.current) {
  //     const rowHeights = childRef.current.getRowHeights();
  //     console.log("Dynamic row heights:", rowHeights);
  //   }

  //   // Optional: Listen for window resize to get updated heights
  //   const handleResize = () => {
  //     if (childRef.current) {
  //       const rowHeights = childRef.current.getRowHeights();
  //       console.log("Updated row heights after resize:", rowHeights);
  //     }
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // useLayoutEffect(() => {
  //   if (childRef.current) {
  //     const rowHeights = childRef.current.getRowHeights();
  //     console.log("Row heights:", rowHeights); // This should log the heights correctly after render
  //   }
  // }, []);

  useEffect(() => {
    getAssesmentData();
  }, []);

  const getAssesmentData = async () => {
    try {
      const data = {
        assesmentId: identifierId,
      };

      if (digitalAssessmentType === TPType.ORIGINAL) {
        const res = await CommonService.getNewTypeOfClinicalAssessmentDetailsForDownload(data);

        if (res && res?.success && res?.data) {
          setAssesmenData(res?.data);
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        const res = await FormVersionService.getNewTypeOfClinicalAssessmentVersionForDownload(identifierId);
        if (res && res?.success && res?.data) {
          setAssesmenData(res?.data);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const replaceTextareasWithDivs = () => {
    const textareas = document.querySelectorAll("textarea");
    textareas.forEach(textarea => {
      const text = textarea.value;
      const div = document.createElement("div");

      div.style.whiteSpace = "pre-wrap";
      div.style.wordWrap = "break-word";
      div.style.border = textarea.style.border;
      div.style.padding = textarea.style.padding;
      div.style.width = textarea.style.width;
      div.style.height = textarea.style.height;
      div.textContent = text;

      const parent = textarea.parentNode;
      if (parent) {
        parent.insertBefore(div, textarea);
        textarea.style.display = "none";
      }
    });
  };

  function divideSectionsIntoPages() {
    const pages: { element: HTMLDivElement; height: number }[][] = []; // Each sub-array represents sections on one page
    let currentPage: { element: HTMLDivElement; height: number }[] = [];
    let accumulatedHeight = 0;
    const pageHeight = 842;
    // const maxWidth = 595;

    const rowData = childRef?.current?.getRowHeights();
    console.log(rowData);

    rowData?.forEach(({ element, height }) => {
      console.log(element);

      // if (element) {
      //   element.style.maxWidth = `${maxWidth}px`;
      //   element.style.width = "100%";
      //   element.style.boxSizing = "border-box";
      //   element.style.overflowX = "hidden";
      // }
      if (height >= pageHeight) {
        // If the section alone exceeds page height, treat it as a single page
        if (currentPage.length > 0) {
          pages.push(currentPage); // Save the current page if not empty
          currentPage = [];
          accumulatedHeight = 0;
        }
        pages.push([{ element, height }]); // Add this oversized section as its own page
      } else if (accumulatedHeight + height > pageHeight) {
        // If adding the section exceeds page height, start a new page
        pages.push(currentPage);
        currentPage = [{ element, height }];
        accumulatedHeight = height;
      } else {
        // Otherwise, add the section to the current page
        currentPage.push({ element, height });
        accumulatedHeight += height;
      }
    });

    // Add the last page if there are any remaining sections
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  }

  // const generatePDF = async () => {
  //   setIsDisabled(true);
  //   replaceTextareasWithDivs();

  //   Swal.fire({
  //     icon: "info",
  //     title: "PDF Preparation In Progress!",
  //     html: `
  //           <p>The clinical assessment is being prepared. Please wait while we generate it.</p>
  //           <p>Once the preparation is complete, it will be automatically downloaded to your device.</p>
  //           `,
  //     showConfirmButton: false,
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });

  //   setButtonText("PDF preparation in progress. Please wait...");

  //   try {
  //     const pdf = new jsPDF("p", "pt", "a4");
  //     const pagesCollection = document.getElementsByClassName("form-page");
  //     // const pages = Array.from(pagesCollection);
  //     const pages = divideSectionsIntoPages();
  //     console.log("pages");
  //     console.log(pages);

  //     const now = new Date();
  //     const formattedDate = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`;
  //     const formattedTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
  //     const fileName = `ClinicalAssessment_${formattedDate}_${formattedTime}.pdf`;

  //     const imgWidth = 595; // A4 width in pt
  //     const pageHeight = 842; // A4 height in pt

  //     for (let i = 0; i < pages.length; i++) {
  //       // Create a container div for the elements of this page

  //       const pageElements = document.createElement("div");

  //       // Apply flex styles to ensure no gaps between elements
  //       pageElements.style.display = "flex";
  //       pageElements.style.flexDirection = "column";
  //       pageElements.style.alignItems = "stretch";
  //       pageElements.style.justifyContent = "flex-start";
  //       pageElements.style.width = `${imgWidth}px`;
  //       pageElements.style.maxWidth = `${imgWidth}px`;
  //       pageElements.style.boxSizing = "border-box";

  //       pages[i].forEach(obj => {
  //         // Clone each element to avoid manipulating original DOM elements
  //         const clonedElement = obj.element.cloneNode(true) as HTMLElement;

  //         // const tables = clonedElement.getElementsByTagName("table");
  //         // Array.from(tables).forEach(table => {
  //         //   table.style.width = "100%";
  //         //   table.style.maxWidth = `${imgWidth}px`;
  //         //   table.style.tableLayout = "fixed";

  //         //   table.style.overflowX = "hidden";

  //         //   // Fix table cells to fit within the A4 width
  //         //   const cells = table.getElementsByTagName("td");
  //         //   Array.from(cells).forEach(cell => {
  //         //     cell.style.wordBreak = "break-word";
  //         //     cell.style.whiteSpace = "normal";
  //         //   });
  //         // });

  //         clonedElement.style.cssText = `
  //         margin: 0;
  //         padding: 0;
  //         width: 100%;
  //         height: auto,
  //         box-sizing: border-box;
  //         page-break-inside: avoid;
  //         overflow-x: hidden;
  //       `;
  //         // clonedElement.style.margin = "0";
  //         // clonedElement.style.padding = "0";
  //         // clonedElement.style.height = "auto"; // Ensures cloned element takes minimal space

  //         pageElements.appendChild(clonedElement);
  //       });

  //       // Append `pageElements` to an off-screen container
  //       const offScreenContainer = document.createElement("div");
  //       // offScreenContainer.style.position = "absolute";
  //       // offScreenContainer.style.top = "-9999px";
  //       // offScreenContainer.style.left = "-9999px";
  //       offScreenContainer.style.cssText = `
  //       position: fixed;
  //       top: -9999px;
  //       left: -9999px;
  //       background: white;
  //       width: ${imgWidth}px !important;
  //       max-width: ${imgWidth}px !important;
  //       height: ${pageHeight}px;
  //       box-sizing: border-box !important;

  //     `;

  //       offScreenContainer.appendChild(pageElements);
  //       document.body.appendChild(offScreenContainer);

  //       //  Use html2canvas to capture the combined page elements

  //       const canvas = await html2canvas(pageElements, {
  //         scale: 2,
  //         useCORS: true,

  //         onclone: clonedDoc => {
  //           // Ensure cloned document is visible and styles are applied
  //           clonedDoc.body.style.visibility = "visible";
  //         },
  //       });

  //       if (canvas.width > 0 && canvas.height > 0) {
  //         console.log("Canvas is fully loaded");
  //       } else {
  //         console.error("Canvas failed to load properly");
  //       }

  //       const imgData = canvas.toDataURL("image/jpeg", 1.0);

  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       if (i > 0) pdf.addPage();
  //       pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight > pageHeight ? pageHeight : imgHeight);

  //       // Clean up by removing the off-screen container
  //       document.body.removeChild(offScreenContainer);
  //     }

  //     pdf.save(`ClinicalAssessment_${formattedDate}_${formattedTime}.pdf`);

  //     toast.success("The clinical assessment has been successfully downloaded! Check your download folder.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Something went wrong! Try again later.", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });
  //   } finally {
  //     setButtonText("Download Clinical Assessment");
  //     setIsDisabled(false);
  //     props.onPdfComplete();
  //     Swal.close();
  //   }
  // };

  const generatePDF = async () => {
    setIsDisabled(true);
    replaceTextareasWithDivs();
    Swal.fire({
      icon: "info",
      title: "PDF Preparation In Progress!",
      html: `
            <p>The clinical assessment is being prepared. Please wait while we generate it.</p>
            <p>Once the preparation is complete, it will be automatically downloaded to your device.</p>
            `,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setButtonText("PDF preparation in progress. Please wait...");
    try {
      const pdf = new jsPDF("p", "pt", "a4");
      const pagesCollection = document.getElementsByClassName("form-page");
      // const pages = Array.from(pagesCollection);
      const pages = divideSectionsIntoPages();
      console.log("pages");
      console.log(pages);
      const now = new Date();
      const formattedDate = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`;
      const formattedTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
      const fileName = `ClinicalAssessment_${formattedDate}_${formattedTime}.pdf`;
      for (let i = 0; i < pages.length; i++) {
        // Create a container div for the elements of this page
        const pageElements = document.createElement("div");
        // Apply flex styles to ensure no gaps between elements
        pageElements.style.display = "flex";
        pageElements.style.flexDirection = "column";
        pageElements.style.alignItems = "stretch";
        pageElements.style.justifyContent = "flex-start";
        pages[i].forEach(obj => {
          // Clone each element to avoid manipulating original DOM elements
          const clonedElement = obj.element.cloneNode(true) as HTMLElement;
          clonedElement.style.cssText = `
          margin: 0;
          padding: 0;
          width: 100%;
          height: auto,
          box-sizing: border-box;
          page-break-inside: avoid;
        `;
          // clonedElement.style.margin = "0";
          // clonedElement.style.padding = "0";
          // clonedElement.style.height = "auto"; // Ensures cloned element takes minimal space
          pageElements.appendChild(clonedElement);
        });

        // await Promise.all(
        //   pages[i].map(obj => {
        //     const clonedElement = obj.element.cloneNode(true) as HTMLElement;
        //     clonedElement.style.cssText = `
        //               margin: 0;
        //               padding: 0;
        //               width: 100%;
        //               height: auto;
        //               page-break-inside: avoid;
        //               overflow-x: hidden;
        //             `;
        //     pageElements.appendChild(clonedElement);
        //   })
        // );

        const imgWidth = 595; // A4 width in pt
        const pageHeight = 842; // A4 height in pt
        // Append `pageElements` to an off-screen container
        const offScreenContainer = document.createElement("div");
        // offScreenContainer.style.position = "absolute";
        // offScreenContainer.style.top = "-9999px";
        // offScreenContainer.style.left = "-9999px";
        offScreenContainer.style.cssText = `
        position: fixed;
        top: -9999px;
        left: -9999px;
        background: white;
        width: ${imgWidth}px;
        height: ${pageHeight}px;
      `;
        offScreenContainer.appendChild(pageElements);
        document.body.appendChild(offScreenContainer);
        // Use html2canvas to capture the combined page elements
        // await new Promise(resolve => setTimeout(resolve, 500));
        const canvas = await html2canvas(pageElements, {
          scale: 2,
          useCORS: true,
          onclone: clonedDoc => {
            // Ensure cloned document is visible and styles are applied
            clonedDoc.body.style.visibility = "visible";
          },
        });
        if (canvas.width > 0 && canvas.height > 0) {
          console.log("Canvas is fully loaded");
        } else {
          console.error("Canvas failed to load properly");
        }
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        if (i > 0) pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight > pageHeight ? pageHeight : imgHeight);
        // Clean up by removing the off-screen container
        document.body.removeChild(offScreenContainer);
      }
      pdf.save(`ClinicalAssessment_${formattedDate}_${formattedTime}.pdf`);
      toast.success("The clinical assessment has been successfully downloaded! Check your download folder.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Try again later.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } finally {
      setButtonText("Download Clinical Assessment");
      setIsDisabled(false);
      props.onPdfComplete();
      Swal.close();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content mt-0" style={{ padding: 0 }}>
        <Container className="containerOnboarding" style={{ padding: 0 }}>
          <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
            <Col>
              <div>
                <h5 className="font-size-25 profileFont mt-4 text-center">COMPREHENSIVE CLINICAL ASSESSMENT</h5>
              </div>
              <div className="d-flex justify-content-center">
                <span>Click the button below to create and download the PDF to your device.</span>
              </div>
              <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-primary mb-2" onClick={generatePDF} disabled={isDisabled}>
                  {buttonText}
                </button>
              </div>
              <div>
                <StepWizard>
                  <AssessmentForm ref={childRef} assesmenData={assesmenData} />
                </StepWizard>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PdfGeneratePage;
