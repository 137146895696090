import React, { useEffect, useRef, useState } from "react";
import { VideoChatService } from "../../services/VideoChatService";
import { MeetingData } from "../../models/MeetingData";
import moment from "moment";
import { Util } from "../../Util";
import { toast } from "react-toastify";
import Modal23 from "../Popup/Modal23";

const MeetingHistoryTabChild: React.FC<{ meeting: MeetingData }> = props => {
  const [isCreating, setCreating] = useState(false);
  const [meeting, setMeeting] = useState(props.meeting);
  const [showRecording, setShowRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const createTranscribe = () => {
    setCreating(true);

    VideoChatService.createTranscribe(props.meeting.meetingId, props.meeting.bothJoinedAt ? true : false).then(res => {
      if (res.success) {
      } else {
        if (mounted) {
          setCreating(false);
          if ((res.error && res.errorCode && res.errorCode == 600) || res.errorCode == 0) {
            toast.success("Recordings of meeting are still processing.Please try again shortly", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Server error occured", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        }
      }
    });
  };

  const shareRecord = () => {
    const value = meeting.recordingSharedWithClient != null ? !meeting.recordingSharedWithClient : true;

    if (
      !props.meeting.transcribingInProcess &&
      props.meeting.transcribeCreated &&
      props.meeting.recordingAllowed &&
      props.meeting.recordingAllowed == true &&
      props.meeting.audioFiles &&
      props.meeting.audioFiles.length != 0 &&
      props.meeting.audioFiles[0] &&
      props.meeting.audioFiles[0].url
    ) {
      VideoChatService.recordingShareMeetingWithLink(meeting.meetingId, value).then(res => {
        if (res.success) {
          setMeeting({ ...meeting, recordingSharedWithClient: res.data.recordingSharedWithClient });
        } else {
          toast.error(res.error ? res.error : "Server error occurred", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      toast.error("This record cannot be shared with the client.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <>
      <tbody>
        <tr>
          <td>{moment(props.meeting.createdAt || null).format("YYYY/MM/DD")}</td>
          <td>{props.meeting?.meetingId}</td>
          <td>{props.meeting?.clientId?.username}</td>
          <td>{props.meeting?.meetingDuration}</td>
        </tr>
      </tbody>

      {showRecording && <Modal23 setShowModal={setShowRecording} showModal={showRecording} audioURL={audioURL} />}
    </>
  );
};

export default MeetingHistoryTabChild;
