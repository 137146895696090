import { string } from 'prop-types';
import { Upload } from './Upload';
import { User } from './User';

export enum AudienceType {
    PUBLIC = "public",
    CLIENTS = "clients",
    THERAPISTS = "therapists",
}

export interface TrainingDocument {
    _id?: string;
    documentTitle: string;
    documentDescription: string;
    uploads?: Upload[];
    videoUrl?: string;
    vimoIds?: string[];
    createdBy: string;
    editedBy?: User[];
    createdAt?: Date;
    audience: AudienceType;
    deletingUploadIds?: any[]
}

export interface DocumentSearchQuery {
    documentTitle: string;
    documentDescription: string;
}