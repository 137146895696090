import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from "reactstrap";

interface RenameFileModalProps {
  isOpen: boolean;
  toggle: () => void;
  reanemFile: string;
  changeRenameFile: (e: any) => void;
  renameFileName: () => Promise<void>;
}

const RenameFileModal: React.FC<RenameFileModalProps> = ({ isOpen, toggle, reanemFile, changeRenameFile, renameFileName }) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Rename File Name</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">File Name</Label>
              <Input id="exampleEmail" name="FileName" placeholder="File Name" value={reanemFile} onChange={e => changeRenameFile(e)} type="text" />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=> {renameFileName()}}>
            Submit
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RenameFileModal;
