import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, ModalBody, Modal, Row, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AdminService } from "../../services/AdminService";
import Papa from "papaparse";
import Swal from "sweetalert2";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import CSV from "../../assets/images/csv/Sample_csv.png";
import { MarketingEmails } from "src/models/MarketingEmails";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor"

const ViewMarketingEmails: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [marketingEmails, setMarketingEmails] = useState([] as MarketingEmails[]);
  const [meetingOffset, setMeetingOffset] = useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  const LIMIT = 10;
  const [file, setFile] = useState<string>();
  const [emails, setEmails] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [customEmailBody, setCustomEmailBody] = useState<any>("");
  const [emailSubject, setEmailSubject] = useState<any>("Welcome to Lavni!");

  useEffect(() => {
    getAllMeetingsAndRecords();
  }, []);

  const getAllMeetingsAndRecords = () => {
    setIsLoading(true);
    AdminService.getAllEmailList(LIMIT, meetingOffset).then(res => {
      if (res.success) {
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setMarketingEmails(res.data);
        setIsLoading(false);
      } else {
        setMarketingEmails([] as MarketingEmails[]);
        setSeeMore(false);
        setIsLoading(false);
      }
    });
  };

  // const seeMoreMeetingsAndRecordings = () => {
  //   setIsLoading(true);
  //   const updatedOffset = meetingOffset + 1;
  //   setMeetingOffset(updatedOffset);
  //   AdminService.getAllRecordsAndMeetings(LIMIT, updatedOffset).then(res => {
  //     if (res.success) {
  //       setMeetingsAndRecordings(previousmeeting => {
  //         return [...new Set([...previousmeeting, ...res.data])];
  //       });
  //       setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
  //       setIsLoading(false);
  //     } else {
  //       setMeetingsAndRecordings([] as MarketingEmails[]);
  //       setSeeMore(false);
  //       setIsLoading(false);
  //     }
  //   });
  // };

  const handleFileChange = (event: any) => {
    const extension = event.target.files[0].name.split(".").pop().toLowerCase();

    if (extension !== "csv") {
      setFile("");
      return toast.error("Invalid file format. Please upload a CSV file to continue.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    } else {
      setFile(URL.createObjectURL(event.target.files[0]));
      setIsDisabled(true);
      csvToJson(event.target.files[0]);
    }
  };

  const csvToJson = (file: any) => {

    Papa.parse(file, {
      header: true,
      complete: (results: any) => {
        const emailLists = results.data;
        const emailList: any[] = [];

        for (const obj of emailLists) {
          if (obj) {
            emailList.push(obj);
          }
        }

        AdminService.sendMarketingEmailList(emailList).then(res => {
          if (res.success) {
            getAllMeetingsAndRecords();
            setIsDisabled(false);
          } else {
            setMarketingEmails([] as MarketingEmails[]);
          }
        });
      },
    });
  };

  const sendMarketingEmails = () => {
    if (emailSubject == '' || emailSubject == null || emailSubject === undefined) {
      return toast.error("Email subject is required!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }

    if (customEmailBody == '' || customEmailBody == null || customEmailBody === undefined) {
      return toast.error("Email Content is required!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }

    if (emails && emails.length > 0) {
      const data = {
        emailList: emails,
        emailSubject: emailSubject,
        emailBody: customEmailBody,
      };

      AdminService.sendMarketingEmails(data).then(res => {
        if (res.success) {
          setFile("");
          setIsDisabled(false);
          setIsOpen(false);
          return toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        } else {
          setFile("");
          setIsDisabled(false);
          return toast.error(res.error, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      setFile("");
      setIsDisabled(false);
      return toast.error("Please select a CSV file containing emails to continue.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const removeFile = () => {
    setFile("");
    setIsDisabled(false);
  };

  const downloadCSV = () => {
    saveAs(CSV, 'sample.csv');
  }

  const loadDefaultTemplate = () => {
    const defaultMail = `
      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        My name is Laura Valentine, and I am a Licensed Clinical Mental Health Counselor. I would love to introduce you to Lavni; a behavioral and mental health online platform for underserved communities. We are making a difference in breaking the stigma against seeking mental health care.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        People in our communities are suffering in silence because they are afraid to say that they are not ok. Undiagnosed and untreated mental illness continues to be a major concern in our community. Underserved communities are twice as likely not to receive care for their mental condition.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        We are looking for licensed mental health clinicians like yourself to help us make a difference in our communities.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        Our application helps clinicians complete session notes easily using our AI-enabled notes feature. We offer competitive pay, referrals, flexibility, and the convenience of working from the comfort of your home.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        I would love to share more with you. Check out our website for information about joining our community of healers. We need your help to impact the broken-hearted as they journey towards overcoming their pain.
      </p>

      <br>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">
        Together, we can transform lives.
      </p>

      <p style="margin:25px 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Warm Regards,</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Laura Valentine, LCMHC</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Co-founder, Lavni Inc.</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">https://mylavni.com</p>

      <p style="margin:0 0 0px 0;font-size:15px;line-height:24px;font-family:Arial,sans-serif;">Direct: 919.358.3012 | laura@mylavni.com</p>

      <br>

      <p style="margin:0 0 0px 0;font-size:12px;line-height:18px;font-family:Arial,sans-serif;">
        HIPAA CONFIDENTIALITY STATEMENT: This document and/or its attachments may contain sensitive information that requires protection under Federal or State law. Authorized recipients of such information are required to protect it in a safe, secure and confidential manner. If you are not the intended recipient, you are hereby notified that any disclosure, copying, distribution or action taken in reliance on the contents of those documents is strictly prohibited. You are requested to notify the sender immediately, delete the email with any accompanying attachments, and destroy any copies you may have made.
      </p>
    `

    if (customEmailBody === '') {
      setCustomEmailBody(defaultMail);
    } else {
      setCustomEmailBody('');
    }


  }

  const editorConfiguration = {
    removePlugins: ['EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'Table'],
  };
  const isOpenModal = (data: any) => {
    setEmails(data);
    setIsOpen(!isOpen);
  };

  const deleteMeetingsAndRecordings = (id: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.deleteMarketingEmailsById(id).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Marketing emails deleted!",
              confirmButtonColor: "#FD7F00",
            });
            getAllMeetingsAndRecords();
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to delete.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={`Lavni`} breadcrumbItem={"Marketing Emails"} />
            <Card>
              <CardBody>
                <Row>
                  <Col className="d-flex admin-filter mb-4">
                    <Label
                      htmlFor="form-upload-file"
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        color: "#FD7F00",
                        marginBottom: "0px",
                        marginRight: "20px"
                      }}
                    >
                      + Upload CSV
                    </Label>
                    {isDisabled ? (
                      <Input type="file" className="form-control" id="form-upload-file" style={{ display: "none", cursor: "unset" }} required disabled />
                    ) : (
                      <Input
                        type="file"
                        className="form-control"
                        id="form-upload-file"
                        onChange={event => {
                          handleFileChange(event);
                        }}
                        style={{ display: "none" }}
                        required
                      />
                    )}

                    <button onClick={() => downloadCSV()} className="btn btn-sm btn-success ">Download Sample CSV</button>

                  </Col>
                </Row>
                {isLoading && <Spinner className="bouncer" />}
                <Row>
                  <Col xl="12">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">View Emails</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketingEmails.length > 0 &&
                          marketingEmails.map((data: MarketingEmails, i: number) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data?.emaiList?.map((d: any, s: number) => {
                                  return (
                                    <div key={s}>
                                      {d?.email}
                                    </div>
                                  )
                                })}</td>
                                <td>
                                  <div className="flex p-0 ">
                                    <button className="btn btn-danger btn-sm mr-2" onClick={() => deleteMeetingsAndRecordings(data?._id)}>
                                      Remove
                                    </button>

                                    <button className="btn btn-success btn-sm" onClick={() => isOpenModal(data?.emaiList)}>
                                      Send Email
                                    </button>

                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Col>
                </Row>

                {marketingEmails.length == 0 && <div className="text-muted text-center">No Marketing Emails.</div>}

                <Row>
                  <Col xl="12 mt30 text-center">
                    {/* {seeMore && (
                      <button className="btn btn-warning" onClick={() => seeMoreMeetingsAndRecordings()}>
                        See More
                      </button>
                    )} */}
                  </Col>
                </Row>

                <Offcanvas
                  className="offcanvas-end2"
                  isOpen={isOpen}
                  direction="end"
                  style={{ width: "100%" }}
                  toggle={isOpenModal}>
                  <OffcanvasHeader toggle={isOpenModal}>
                    Marketing Email:
                  </OffcanvasHeader>

                  <OffcanvasBody>
                    <div className="row  pt20 pl10" id="style-1">
                      <div className="row mb-4 mt-2">
                        <div className="col">
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Marketing Email Subject"
                            name="articleTitle"
                            value={emailSubject}
                            onChange={event => {
                              setEmailSubject(event.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col">
                          <CKEditor
                            editor={Editor}
                            stlye={{ minHeight: "300px" }}
                            onChange={(event: any, editor: { getData: () => any }) => {
                              const data = editor.getData();
                              setCustomEmailBody(data);
                            }}
                            data={customEmailBody}
                            config={editorConfiguration}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col">
                          <button onClick={() => loadDefaultTemplate()} className="btn btn-sm btn-primary ml-2">{customEmailBody === '' ? 'Load Default Email' : 'Clear Default Email'}</button>
                        </div>
                      </div>
                      <div className="flex flex-row-reverse p-0 ">
                        <button className="btn btn-success mr-20" onClick={() => sendMarketingEmails()}>
                          Send Email
                        </button>
                        <button
                          className="btn btn-danger mr-2"
                          onClick={() => {
                            removeFile();
                          }}

                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </OffcanvasBody>
                </Offcanvas>
              </CardBody>
            </Card>
          </Container>
        </div>

      </React.Fragment>
    </>
  );
};

export default ViewMarketingEmails;
