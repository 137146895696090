const getCroppedImgAsFile = async (imageSrc:any, crop:any, initialCroppedArea:any) => {
  return new Promise((resolve, reject) => {
    const handleImageError = (error:any) => {
      console.error("Error loading image:", error);
      reject(new Error("Error loading image"));
    };

    const loadImage = (src:any) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          console.error("Failed to get canvas context");
          reject(new Error("Failed to get canvas context"));
          return;
        }

        // Calculate scaleX and scaleY
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        // Calculate dimensions for the cropped area
        const croppedWidth = initialCroppedArea.width * scaleX;
        const croppedHeight = initialCroppedArea.height * scaleY;

        // Set fixed width and height for the saved image
        const savedWidth = 200; // Set your desired width here
        const savedHeight = 200; // Set your desired height here

        // Calculate the scaling factors for resizing
        const scaleFactorX = savedWidth / crop.width;
        const scaleFactorY = savedHeight / crop.height;

        // Draw the cropped area onto the canvas and resize
        canvas.width = savedWidth;
        canvas.height = savedHeight;
        ctx.drawImage(
          image,
          initialCroppedArea.x * scaleX,
          initialCroppedArea.y * scaleY,
          croppedWidth,
          croppedHeight,
          0,
          0,
          savedWidth,
          savedHeight
        );

        // Convert canvas to blob
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }
            const file = new File([blob], "croppedImage.jpg", {
              type: "image/jpeg",
              lastModified: Date.now() // Optional: set last modified date
            });
            resolve(file);
          },
          "image/jpeg",
          0.9 // Set quality parameter here instead
        );
      };

      image.onerror = (error) => handleImageError(error);

      image.src = src;
    };

    if (imageSrc instanceof FileList && imageSrc.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === "string") {
          loadImage(event.target.result);
        } else {
          handleImageError("Invalid image data");
        }
      };
      reader.onerror = () => handleImageError("Error reading file");
      reader.readAsDataURL(imageSrc[0]);
    } else if (imageSrc instanceof File) {
      loadImage(URL.createObjectURL(imageSrc));
    } else {
      handleImageError("Invalid image source");
    }
  });
};

export default getCroppedImgAsFile;