import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DocSideBar from './Components/DocSideBar';
import DocumentLists from './Components/DocumentLists';
import { MediaUploaderVimeo } from "../../utils/MediaUploaderVimeo";
import { toast } from "react-toastify";
import { VidemoService } from "../../services/VimeoService";
import { TrainingDocument, AudienceType } from '../../models/Document';
import { DocumentService } from "../../services/DocumentService";
import UserContext from "../../context/UserContext";
import { Util } from "../../Util";
import Swal from "sweetalert2";

const DocumentLayout: React.FC = () => {
    const [loggedUser, setLoggedUser] = useContext(UserContext);
    const [searchQuery, setSearchQuery] = useState([] as any);
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const SUPPORTED_VIDEO_FORMATS = ["mp4", "mkv", "webm", "mov", "ogg", "mpeg", "mpg", "flv"];
    const SUPPORTED_DOCUMENT_FORMATS = ["doc", "pdf", "jpg", "png", "docx", "txt"];
    const [fileType, setFileType] = useState("");
    const [progress, setProgress] = useState(0);
    const [isVideos, setVideos] = useState([] as any);
    const [isDocuments, setDocuments] = useState([] as any);
    const [isDeletedDocuments, setDeletedDocuments] = useState([] as any);
    const [showProgress, setShowProgress] = useState(false);
    
    const [showDocuments, setShowDocuments] = useState(false);
    const [showVideos, setShowVideos] = useState(false);

    const [transcodeStatus, setTranscodeStatus] = useState("");
    const uploader = new MediaUploaderVimeo();
    const [isTrainingDoc, setTrainiingDoc] = useState({} as TrainingDocument);
    const [seeMoreCount, setSeeMoreCount] = useState(0);
    const [isSearchableText, setSearchableText] = useState({} as any);
    let updatedSkip = 0;
    const [skip, setSkip] = useState(0);
    const [seeMoreBtnDisable, setMoreBtnDisable] = useState(true);
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        searchDocuments({});
    }, [])

    const searchDocuments = (obj: any) => {
        setSearchableText(obj);
        updatedSkip = skip + seeMoreCount;
        DocumentService.searchDocuments(obj, 5, updatedSkip).then(res => {
            if (res.success) {
                if (res.data.length < 5) {
                    setMoreBtnDisable(false);
                }
                setSkip(updatedSkip);
                setSearchQuery([...searchQuery, ...res.data]);
                setSeeMoreCount(res.data.length);
            }
            setIsShow(true);
        })
        
    }
    
    const setMoreFC = () => {
        searchDocuments(isSearchableText);
    }
    
    const isSupported = (array: string[], extension: string) => {
        let isFound = false;
        array.map((item: string) => {
            if (item === extension) {
                isFound = true;
            }
            return item;
        });
        return isFound;
    };

    const refreshTransCodeStatus = (vimeoId: string) => {
        VidemoService.checkTranscodeStatus(vimeoId).then((res: any) => {
            setTranscodeStatus(res.transcode.status);
        });
    }

    const isOpenModal = () => {
        setIsOpen(!isOpen);
    }

    const isCloseModal = () => {
        setTrainiingDoc({} as TrainingDocument);
        isOpenModal();
    }

    const addFile = () => {
        inputRef.current?.click();
    }

    const selectFileAndDocuments = async (e: any) => {
        const extension = e.target.files[0].name.split(".").pop().toLowerCase();
        const isVideo = isSupported(SUPPORTED_VIDEO_FORMATS, extension);
        const isDocument = isSupported(SUPPORTED_DOCUMENT_FORMATS, extension);

        if (isVideo) {
            setFileType("VIDEO");

            if (e.target.files.length) {
                setShowProgress(true);

                const videoMeta = await uploader.upload(e.target.files[0], (bytesUploaded: number, bytesTotal: number) => {
                    setProgress(Math.floor((bytesUploaded * 100) / bytesTotal));
                });

                if (videoMeta) {''
                    setVideos([...isVideos, videoMeta.id])
                }

                setShowProgress(false);

                setProgress(0);

                refreshTransCodeStatus(videoMeta.id);

            } else {
                toast.error("Please select training documents or videos!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        }
        
        if (isDocument) {
            setFileType("DOCUMENT");
            
            if (e.target.files.length) {
                setShowProgress(true);
                
                setDocuments([...isDocuments, e.target.files[0]])
 
                setShowProgress(false);
                
                setProgress(0);

            } else {
                toast.error("Please select training documents or videos!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        }
    }

    const submitData = () => {
        if (isTrainingDoc.documentTitle == '' || isTrainingDoc.documentTitle == null) {
            return toast.error("Please add title for training documents or videos!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (isTrainingDoc.documentDescription == '' || isTrainingDoc.documentDescription == null) {
            return toast.error("Please add description for training documents or videos!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (!(isVideos.length > 0) && !(isDocuments.length > 0)) {
            return toast.error("Please add at least one documents or videos!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        const data: TrainingDocument = {
            documentTitle: isTrainingDoc.documentTitle,
            documentDescription: isTrainingDoc.documentDescription,
            audience: isTrainingDoc.audience,
            vimoIds: isVideos,
            createdBy: loggedUser?._id as string
        }
        
        DocumentService.createTrainingDocument(data, isDocuments).then(res => {
            if (res.success) {
                isOpenModal();
                setTrainiingDoc({} as TrainingDocument);
                setVideos([] as any);
                setDocuments([] as any);
                setSearchQuery((item: any) => [res.data, ...item]); // to refresh with newly object...
                return toast.success("Training Documents and Videos added Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        });
    }

    const updateData = () => {
        if (isTrainingDoc.documentTitle == '' || isTrainingDoc.documentTitle == null) {
            return toast.error("Please add title for training documents or videos!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (isTrainingDoc.documentDescription == '' || isTrainingDoc.documentDescription == null) {
            return toast.error("Please add description for training documents or videos!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (!(isVideos.length > 0) && !(isDocuments.length > 0)) {
            return toast.error("Please add at least one documents or videos!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        const updateData: TrainingDocument = {
            _id: isTrainingDoc._id,
            documentTitle: isTrainingDoc.documentTitle,
            documentDescription: isTrainingDoc.documentDescription,
            audience: isTrainingDoc.audience,
            vimoIds: isVideos,
            createdBy: loggedUser?._id as string,
            deletingUploadIds: isDeletedDocuments,
            uploads: isTrainingDoc.uploads
        }

        DocumentService.updateTrainingDocument(updateData, isDocuments).then(res => {
            if (res.success) {
                isOpenModal();
                setTrainiingDoc({} as TrainingDocument);
                setVideos([] as any);
                setDocuments([] as any);
                const removedPrevObj = searchQuery.filter((obj: TrainingDocument) => obj._id !== res.data._id)
                setSearchQuery([res.data, ...removedPrevObj]); // to refresh with newly object...
                return toast.success("Training Documents and Videos updated Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        });
    }

    const setTrainiingDocToEdit = (data: any) => {
        setTrainiingDoc(data);
        setVideos(data.vimoIds);
        setDocuments(data.uploads);
    }

    const showDocs = () => {
        setShowDocuments(!showDocuments)
        setShowVideos(false)
    }

    const showUVideos = () => {
        setShowVideos(!showVideos)
        setShowDocuments(false)
    }

    const removeDoc = (doc: any, i: number) => {
        let remainingArr: [] = [];

        Swal.fire({
            icon: "warning",
            title: `Are you want to remove ${doc.name} ?`,
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
          }).then(result => {
            if (result.isConfirmed) {
                if (i !== -1) {
                    remainingArr = isDocuments.filter((data: any, x: number) => x != i);
                    setDocuments(remainingArr);
                    setDeletedDocuments([...isDeletedDocuments, doc._id])
                }
            }
          });

    }

    const removeVdo = (vdo: string, i: number) => {
        let remainingArr: [] = [];

        Swal.fire({
            icon: "warning",
            title: `Are you want to remove video ?`,
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
          }).then(result => {
            if (result.isConfirmed) {
                remainingArr = isVideos.filter((data: any, x: number) => x != i);
                setVideos(remainingArr);
            }
          });

    }

    const removedDocUpdate = (id: any) => {
        const newData = searchQuery.filter((item: any) => item._id !== id);
        if (newData) setSearchQuery(newData);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <title>Trainning Documents & Video | Lavni</title>
                <Container fluid>
                    <Breadcrumbs title="Lavni" breadcrumbItem="Documents" />
                    <Row>
                        <Col xl={3} lg={4}>
                            <DocSideBar searchDocuments={searchDocuments} isOpen={isOpenModal} />
                        </Col>
                        <Col xl={9} lg={8} className="justify-content-center">
                            <DocumentLists 
                            searchQuery={searchQuery} 
                            isShow={isShow}
                            dataForEdit={(data: any) => {setTrainiingDocToEdit(data); isOpenModal()}} 
                            removedDoc={(data: any) => {removedDocUpdate(data)}} 
                            seeMore={setMoreFC}
                            disablebtn={seeMoreBtnDisable}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={isOpen} centered toggle={isOpenModal} className="p-c-details">
                {/* <SoonTag text="Soon" /> */}
                <ModalHeader>
                    <h4>{isTrainingDoc?._id ? 'Update' : 'Add'} Training Documents & Video</h4>
                </ModalHeader>
                <ModalBody>
                <Row>
                    <Col>
                    <Form>
                        <FormGroup>
                            <Label for="exampleEmail">Document Title</Label>
                            <Input type="text" onChange={(e) => setTrainiingDoc({ ...isTrainingDoc, documentTitle: e.target.value })} defaultValue={isTrainingDoc.documentTitle} name="title" id="title" placeholder="Document Title" />
                        </FormGroup>

                        <FormGroup>
                        <Label for="exampleSelect">Audience</Label>
                        <Input type="select" onChange={(e) => setTrainiingDoc({ ...isTrainingDoc, audience: e.target.value as AudienceType })} defaultValue={isTrainingDoc.audience} name="select" id="exampleSelect">
                            <option value={AudienceType.PUBLIC}>Public</option>
                            <option value={AudienceType.CLIENTS}>Clients</option>
                            <option value={AudienceType.THERAPISTS}>Therapists</option>
                        </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="exampleText">Description</Label>
                            <Input type="textarea" onChange={(e) => setTrainiingDoc({ ...isTrainingDoc, documentDescription: e.target.value  })} defaultValue={isTrainingDoc.documentDescription} name="text" id="exampleText" />
                        </FormGroup>
                    </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(isVideos.length > 0 || isDocuments.length > 0) && <p>You added <span className="doc-file" onClick={showDocs}>{isDocuments.length} documents</span> and <span className="doc-video" onClick={showUVideos}>{isVideos.length} videos</span></p>}
                        <ul className="trainig-doc">
                            {
                                showDocuments && isDocuments.map((doc: any, i: number) => {
                                    return <li key={i} >
                                                <img src={Util.fileType(doc.extension, doc._id)} width={40} height={40} id={'doc'+i} onClick={() => {window.open(URL.createObjectURL(doc) as any, "_blank");}} />
                                                <small onClick={() => removeDoc(doc, i)}>Delete</small>
                                                <UncontrolledTooltip placement="top" target={'doc'+i}>
                                                    {
                                                        isDocuments._id ? `${doc.name}` : `${doc.originalName}`
                                                    }
                                                </UncontrolledTooltip>
                                            </li>
                                })
                            }
                        </ul>

                        <ul className="trainig-vdo">
                            {
                                showVideos && isVideos.map((vdo: any, i: number) => {
                                    return <li key={i}>
                                        {
                                            <div className="t-vdo">
                                                <iframe src={'https://player.vimeo.com/video/' + vdo} width="100" className="videoSize2" height="50" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                                                <small onClick={() => removeVdo(vdo, i)}>Delete</small>
                                            </div>
                                        }
                                    </li>
                                })
                            }
                        </ul>

                    </Col>
                </Row>

                <Row>
                    <Col>
                    <div className="drop_box">
                        <header>
                            <h4>Select Training Document here</h4>
                        </header>
                        <p>Files Supported: PDF, TEXT, DOC , DOCX, MP4, MKV, WEBM, MOV, OGG, MPEG, MPG, FLV</p>
                        <input ref={inputRef} type="file" onChange={selectFileAndDocuments} hidden accept=".doc,.docx,.pdf,video/mp4,video/x-m4v,video/*" id="fileID" className="doc-file-upload" />

                        {
                            showProgress ? (
                                <Col className="profile-video2">
                                    Uploading &nbsp; <span className="progress-color">{progress}%</span>
                                </Col>
                            ) : (
                                <button className="btn btn-default" onClick={addFile}>Choose Document or Video</button>
                            )
                        }
                    </div>
                    </Col>

                </Row>
                <Row>

                </Row>
                <Row className="mt-4">
                    <Col>
                    {
                        isTrainingDoc?._id ? (
                            <button className="btn btn-md text-center btn-primary w-full" onClick={updateData}>
                                Update
                            </button>
                        ) : (
                            <button className="btn btn-md text-center btn-primary w-full" onClick={submitData}>
                                Submit
                            </button>
                        )
                    }

                    </Col>
                    <Col>
                    <button className="btn btn-md text-center btn-default w-full" onClick={isCloseModal}>
                        Cancel
                    </button>
                    </Col>
                </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )

}

export default DocumentLayout;