import React, { useEffect, useRef, useState} from "react";
import { toast } from "react-toastify";
import { Card, Col, Row, Table, Input, UncontrolledTooltip } from "reactstrap";
import PhoneInput from "react-phone-number-input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { HealthyBlueAuthForm } from "src/models/AuthorizationForm/healthy-blue-auth-form-model";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { HealthyBlueServiceInformationArray } from "src/models/AuthorizationForm/subModels/HealthyBlueAuthFormSubModels/healthy-blue-service-information-model";
import { AuthFormType } from "src/models/AuthorizationFormData";
import SignaturePad from "react-signature-canvas";
import moment from "moment";

const HealthyBlueForm = (props: any) => {
    const { clientId, insuranceCompanyId, formId, getUpdateFormId, healthyBlueData } = props;
    const [formData, setFormData] = useState<HealthyBlueAuthForm>();
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [clinicianSign, setClinicianSign] = useState<boolean>(false);

    useEffect(() => {
        setIsUpdate(props.isUpdate);
    }, [props.isUpdate]);

    useEffect(() => {
        const updateHealthyBlueData: HealthyBlueAuthForm = {
            generalInformation: healthyBlueData?.generalInformation,
            serviceInformation: healthyBlueData?.serviceInformation,
            providerInformation: healthyBlueData?.providerInformation,
            practitionerInformation: healthyBlueData?.practitionerInformation,
            authorization: healthyBlueData?.authorization,
            referenceNumber: healthyBlueData?.referenceNumber,
            signature: healthyBlueData?.signature,
        };
        setFormData(updateHealthyBlueData);
    }, [healthyBlueData]);

    // const formatDate = (date: any) => {
    //     if (!date) return '';
    //     const d = new Date(date);
    //     const year = d.getFullYear();
    //     const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    //     const day = String(d.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    const formatDate = (date: any) => {
        if (!date) return '';
        return moment.utc(date).format('YYYY-MM-DD');
    };
    
    function autoResizeTextarea(e: any) {
        const textarea = e.target;
        textarea.style.height = 'auto';

        const newHeight = Math.min(textarea.scrollHeight, 3 * 16);
        textarea.style.height = `${newHeight}px`;

        textarea.style.overflowY = textarea.scrollHeight > newHeight ? 'scroll' : 'hidden';
    }

    const saveHealthyBlueAuthForm = async () => {
            setIsSaving(true);
            const healthyBlueAuthFormDetails: HealthyBlueAuthForm = {
                _id: formId,
                clientId: clientId,
                insuranceCompanyId: insuranceCompanyId,
                authFormType: AuthFormType.HealthyBlueAuthForm,
                generalInformation: formData?.generalInformation,
                serviceInformation: formData?.serviceInformation,
                providerInformation: formData?.providerInformation,
                practitionerInformation: formData?.practitionerInformation,
                authorization: formData?.authorization,
                referenceNumber: formData?.referenceNumber,
                signature: formData?.signature,
            }
            try{
            let res;
            if (isUpdate === false) {
                res = await AuthorizationFormService.createHealthyBlueAuthForm(healthyBlueAuthFormDetails).then(res => {
                    if (res && res.success && res.data) {
                        getUpdateFormId(res.data._id);
                        setIsUpdate(true);
                        toast.success("Healthy Blue Form Data has been successfully saved!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        props.closePopup();
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
            } else {
                res = await AuthorizationFormService.updateAuthorizationForm(AuthFormType.HealthyBlueAuthForm, healthyBlueAuthFormDetails).then(res => {
                    if (res && res.success && res.data) {
                        getUpdateFormId(res.data._id);
                        toast.success("Healthy Blue Form data has been successfully updated!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        props.closePopup();
                    } else {
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
            }
        } catch (error) {
            toast.error(`Error occurred! Error details: ${error}.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        const therapySign = formData?.signature;
        if (therapySign === undefined || !therapySign || therapySign === "") {
          setClinicianSign(false);
        } else {
          setClinicianSign(true);
        }
    }, [healthyBlueData, formData]);

    const signPad = useRef<any>({
        minDistance: 0,
        throttle: 9,
        velocityFilterWeight: 0.7,
    });
    
    function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
        let timeout: ReturnType<typeof setTimeout>;
        return function (...args: Parameters<T>) {
          clearTimeout(timeout);
          timeout = setTimeout(() => func(...args), wait);
        };
    }
    
    const resizeCanvas = () => {
        if (signPad?.current) {
          const canvas = signPad.current.getCanvas();
          if (canvas) {
            const container = canvas.parentNode as HTMLElement;
            if (container) {
              const ratio = Math.max(window.devicePixelRatio || 1, 1);
              canvas.width = container.offsetWidth * ratio;
              canvas.height = container.offsetHeight * ratio;
              const context = canvas.getContext("2d");
              if (context) {
                context.scale(ratio, ratio);
              }
              signPad.current.clear();
            }
          }
        }
    };
    
    useEffect(() => {
        try {
          if (signPad?.current) {
              setTimeout(() => {
                resizeCanvas();
            }, 0);
          }
    
          const debouncedResize = debounce(resizeCanvas, 200);
          window.addEventListener("resize", debouncedResize);
    
          // Using ResizeObserver to handle changes in container size
          const observer = new ResizeObserver(() => {
            resizeCanvas();
          });
    
          if(signPad?.current) {
            const container = signPad.current.getCanvas().parentNode as HTMLElement;
            if(container) {
              observer.observe(container);
            }
          }
    
          return () => {
           window.removeEventListener("resize", debouncedResize);
           observer.disconnect();
          };
        } catch (error) {
    
        }
    
    }, []);

    function clearsign(): void {
        if(signPad?.current) {
          console.log("clear!");
          signPad.current.clear();
          const signatureData = null;
        }
    }
    
    function saveSign() {
        if(signPad?.current) {
           const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
           setFormData({ ...formData, signature: signatureData });
        }
    }
    
    function editSign() {
        setClinicianSign(false);
    }

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
                <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
                    <Row className="align-items-center pt-2">
                        <Col xs="12" sm="5" className="text-center text-sm-start">
                            <div>
                                <img
                                    src="/static/assets/img/authorization/healthy_blue_nc.png"
                                    alt="Healthy Blue Insurance Company Logo"
                                    className="img-fluid"
                                    style={{ maxWidth: '300px' }}
                                />
                            </div>
                        </Col>
                        <Col xs="12" sm="7" className="text-center text-sm-end">
                            <p className="p-2" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                                <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request Form</span><br />
                            </p>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px" }}>
                    <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>

                            <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                <p style={{ margin: "0 0 1rem 0" }}>
                                    Prior authorization is the approval of medically necessary services for Healthy Blue members.
                                    Prior authorization applies to the requested service(s) only and does not guarantee payment,
                                    nor does it guarantee the amount billed will be the amount reimbursed. The member must be
                                    Medicaid eligible and a Healthy Blue member on the date of service or date the equipment or
                                    prosthesis is received by the member.
                                </p>
                                <p style={{ margin: "0" }}>
                                    <span style={{ display: "block", marginBottom: "0.5rem" }}>
                                        <strong>Return To :</strong>
                                    </span>
                                    <span style={{ marginLeft: "1rem" }}> • Inpatient: <strong>855-817-5788</strong></span><br />
                                    <span style={{ marginLeft: "1rem" }}> • Outpatient: <strong>855-817-5788</strong></span><br />
                                    <span style={{ marginLeft: "1rem" }}> • Behavioral Health Inpatient: <strong>844-439-3574</strong></span><br />
                                    <span style={{ marginLeft: "1rem" }}> • Behavioral Health Outpatient:<strong>844-429-9636</strong></span><br />
                                    <span style={{ marginLeft: "1rem" }}> • Nursing Facility, IP Rehab, LTACH Services: <strong>844-451-2694</strong></span><br />
                                </p>
                            </div>
                        </div>
                    </Row>
                </Card>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>General Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}> Member Name :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.memberName ?? ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            memberName: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="nameInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="nameInfo">
                                                        Enter the member’s name as it appears on the Medicaid identification
                                                        card.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="3" >
                                            <label style={{ fontSize: "12px" }}>Date of birth :</label>
                                        </Col>
                                        <Col xs="12" sm="3">
                                            <div className="d-flex align-items-center">
                                                <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                 value={formatDate(formData?.generalInformation?.dateOfBirth)}
                                                onChange={(e) => {
                                                    const date = new Date(e.target.value);
                                                    setFormData({
                                                        ...formData,
                                                       generalInformation: {
                                                            ...formData?.generalInformation,
                                                            dateOfBirth: date
                                                        }
                                                    });
                                                }} /><span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="dobInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="dobInfo">
                                                        Enter the member’s date of birth.
                                                    </UncontrolledTooltip>
                                                </span></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Address (street, city, state, ZIP code) :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.memberAddress ?? ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            memberAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="addressInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="addressInfo">
                                                        Enter the member’s address, city, state, and ZIP.
                                                    </UncontrolledTooltip>
                                                </span></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.generalInformation?.phone?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            generalInformation: {
                                                                ...formData?.generalInformation,
                                                                phone: value
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="phoneInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="phoneInfo">
                                                        Enter the member’s telephone number.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <label style={{ fontSize: "12px" }}>Medicaid ID/subscriber ID :</label>
                                        </Col>
                                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                value={formData?.generalInformation?.medicaidId ?? ''}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    generalInformation: {
                                                        ...formData?.generalInformation,
                                                        medicaidId: e.target.value
                                                    }
                                                })}/>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="MIDInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="MIDInfo">
                                                        Enter the member’s Medicaid ID/subscriber ID number as
                                                        shown on the Medicaid identification card or county letter of eligibility.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">

                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Diagnosis code :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                value={formData?.generalInformation?.diagnosisCode ?? ''}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    generalInformation: {
                                                        ...formData?.generalInformation,
                                                        diagnosisCode: e.target.value
                                                    }
                                                })}/>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="DCInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="DCInfo">
                                                        Enter the diagnosis codes.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Diagnosis description :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.diagnosisDescription ?? ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            diagnosisDescription: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="DDInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="DDInfo">
                                                        Enter the diagnosis description.
                                                        If there is more than one diagnosis, enter all descriptions
                                                        appropriate to the services being requested.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="7">
                                            <label style={{ fontSize: "12px" }}>Name and address of facility where services are to be rendered, if other than home or office :</label>
                                        </Col>
                                        <Col xs="12" sm="5">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.generalInformation?.facilityName ?? ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        generalInformation: {
                                                            ...formData?.generalInformation,
                                                            facilityName: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="NAInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="NAInfo">
                                                        Name and address of the facility where services are to
                                                        be rendered, if service is to be provided at a location
                                                        other than the home or office.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Service Information</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <Table responsive className="clinical-table-bordered table-md ">
                                    <thead>
                                        <tr>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                    Ref. NO
                                                    <i className='bx bx-info-circle ms-2' id="refInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="refInfo">
                                                        Enter the unique designator
                                                        (1 – 10) identifying each separate line on the request.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                    Procedure code
                                                    <i className='bx bx-info-circle ms-2' id="procedureCodeInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="procedureCodeInfo">
                                                        Enter the procedure codes for the
                                                        services being requested.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                    Place of Service
                                                    <i className='bx bx-info-circle ms-2' id="serviceInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="serviceInfo">
                                                        Enter the code to indicate where the service was provided.
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                    From
                                                    <i className='bx bx-info-circle ms-2' id="fromInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="fromInfo">
                                                        Enter the from date that services will begin
                                                        if authorization is approved (MM/DD/YY format).
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                    Through
                                                    <i className='bx bx-info-circle ms-2' id="throughInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="throughInfo">
                                                        Enter the through date the services will terminate if authorization is approved (MM/DD/YY format).
                                                    </UncontrolledTooltip>
                                                </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                Description of service/item
                                                <i className='bx bx-info-circle ms-2' id="descriptionInfo" style={{ cursor: 'pointer' }}></i>
                                                <UncontrolledTooltip placement="bottom" target="descriptionInfo">
                                                    Enter a specific description
                                                    of the service or item being requested.
                                                </UncontrolledTooltip>
                                            </div>
                                            </th>
                                            <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}> 
                                                <div className="d-flex align-items-center justify-content-center" style={{fontSize: "12px"}}>
                                                Qty. or units
                                                <i className='bx bx-info-circle ms-2' id="qtyInfo" style={{ cursor: 'pointer' }}></i>
                                                <UncontrolledTooltip placement="bottom" target="qtyInfo">
                                                    Enter the quantity or units of the
                                                    service or item being requested.
                                                </UncontrolledTooltip>
                                            </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(10)].map((_, index) => (
                                            <tr key={index}>
                                                <td style={{ border: "1px solid #B2B2B2", position: 'relative' }}>
                                                    <div style={{ position: 'absolute', top: '0', left: '0', padding: '4px', textAlign: 'center' }}>
                                                        ({index + 1})
                                                    </div>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1", paddingLeft: '30px' }} 
                                                     value={formData?.serviceInformation?.serviceInformationArray?.[index]?.referenceNumber ?? ''}
                                                    onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            referenceNumber: e.target.value,
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }}/>
                                                </td>
                                                <td>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                       value={formData?.serviceInformation?.serviceInformationArray?.[index]?.procedureCode ?? ''}
                                                      onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            procedureCode: e.target.value,
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }}/>
                                                </td>
                                                <td>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                      value={formData?.serviceInformation?.serviceInformationArray?.[index]?.servicePlace ?? ''}
                                                     onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            servicePlace: e.target.value,
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }}/>
                                                </td>
                                                <td>
                                                    <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                    value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.startDate)}
                                                    onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            startDate: new Date(e.target.value),
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }}/>
                                                </td>
                                                <td>
                                                    <Input type="date" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                    value={formatDate(formData?.serviceInformation?.serviceInformationArray?.[index]?.endDate)}
                                                    onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            endDate: new Date(e.target.value),
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }}/>
                                                </td>
                                                <td>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }} 
                                                     value={formData?.serviceInformation?.serviceInformationArray?.[index]?.serviceDescription ?? ''}
                                                    onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            serviceDescription: e.target.value,
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }}/>
                                                </td>
                                                <td>
                                                    <Input type="text" style={{ fontSize: "0.70rem", border: "none", lineHeight: "1" }}
                                                     value={formData?.serviceInformation?.serviceInformationArray?.[index]?.itemQuantity ?? ''}
                                                    onChange={(e) => {
                                                        const updatedServiceInfoArray = formData?.serviceInformation?.serviceInformationArray
                                                            ? [...formData.serviceInformation.serviceInformationArray]
                                                            : Array(10).fill({} as HealthyBlueServiceInformationArray);
                                                        updatedServiceInfoArray[index] = {
                                                            ...updatedServiceInfoArray[index],
                                                            itemQuantity: e.target.value,
                                                        };
                                                        setFormData({
                                                            ...formData,
                                                            serviceInformation: {
                                                                ...formData?.serviceInformation,
                                                                serviceInformationArray: updatedServiceInfoArray,
                                                            },
                                                        });
                                                    }} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Card className="bg-light-gray" style={{ height: "250px", marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                                    <div style={{ margin: "0.2rem" }}>
                                        <h6 style={{ fontWeight: "600", display: "inline" }}>Detailed explanation of medical necessity for services, equipment, procedures or prostheses  </h6>
                                        <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                            (Attach additional pages if necessary)
                                        </span>
                                    </div>
                                    <div className="editor-area ck-content"
                                        style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                        <CKEditor
                                            editor={Editor}
                                            id="floatingTextarea"
                                            config={{
                                                height: '100%',
                                                toolbarCanCollapse: true,
                                            }}
                                            data={formData?.serviceInformation?.necessityExplanation}
                                            onChange={(event: any, editor: any) => {
                                                const data = editor.getData();
                                                setFormData(prevState => ({
                                                    ...prevState,
                                                    serviceInformation: {
                                                        ...prevState?.serviceInformation,
                                                        necessityExplanation: data
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                </Card>

                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Provider</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Provider name :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                value={formData?.providerInformation?.providerName ?? ''}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    providerInformation: {
                                                        ...formData?.providerInformation,
                                                        providerName: e.target.value
                                                    }
                                                })}/>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="providerNInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="providerNInfo">
                                                        Enter the requested provider’s information.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Telephone :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.providerInformation?.phoneNumber?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                phoneNumber: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="telephoneInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="telephoneInfo">
                                                        Enter the number for the requesting provider.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Address  :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.providerInformation?.providerAddress ?? ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            providerAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="mailInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="mailInfo">
                                                        Enter the complete mailing address in this field.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>NPI :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}  
                                                 value={formData?.providerInformation?.npi ?? ''}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    providerInformation: {
                                                        ...formData?.providerInformation,
                                                        npi: e.target.value
                                                    }
                                                })}/>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="NPInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="NPInfo">
                                                        Enter the National Provider Identifier.Enter the provider’s NPI and taxonomy code (if applicable).
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Fax number :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.providerInformation?.faxNumber ?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                faxNumber: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="faxInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="faxInfo">
                                                        Enter the fax number for the requesting provider.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Practitioner</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Name :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                value={formData?.practitionerInformation?.providerName?? ''}
                                                 onChange={(e) => setFormData({
                                                    ...formData,
                                                    practitionerInformation: {
                                                        ...formData?.practitionerInformation,
                                                       providerName: e.target.value
                                                    }
                                                })}/>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="practitionerNInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="practitionerNInfo">
                                                        Enter the name of the prescribing/performing practitioner.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Telephone :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.practitionerInformation?.phone?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            practitionerInformation: {
                                                                ...formData?. practitionerInformation,
                                                                phone: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="telephonePInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="telephonePInfo">
                                                        Enter the prescribing/performing practitioner telephone number,
                                                        including area code.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Address  :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <textarea
                                                    id="customTextarea"
                                                    className="form-control"
                                                    rows={1}
                                                    style={{
                                                        overflow: 'hidden',
                                                        resize: 'none',
                                                        maxHeight: '7.5em',
                                                        lineHeight: '1',
                                                        fontSize: "0.70rem",
                                                        width: '100%'
                                                    }}
                                                    value={formData?.practitionerInformation?.providerAddress?? ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        practitionerInformation: {
                                                            ...formData?.practitionerInformation,
                                                           providerAddress: e.target.value
                                                        }
                                                    })}
                                                ></textarea>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="addressPInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="addressPInfo">
                                                        Enter the address, city, state, and ZIP code.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Fax number :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.practitionerInformation?.faxNumber ?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            practitionerInformation: {
                                                                ...formData?. practitionerInformation,
                                                                faxNumber: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="faxPInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="faxPInfo">
                                                        Enter the fax number for the prescribing/performing provider.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prior Authorization Request Form Completed By</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Contact Name :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }} 
                                                 value={formData?.authorization?.contactName?? ''}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    authorization: {
                                                        ...formData?.authorization,
                                                       contactName: e.target.value
                                                    }
                                                })}/>
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="contactNInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="contactNInfo">
                                                        Enter the name of the person completing the Prior Authorization
                                                        Request Form.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Phone number :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.authorization?.phoneNumber?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                           authorization: {
                                                                ...formData?. authorization,
                                                                phoneNumber: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="telephonePInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="telephonePInfo">
                                                        Enter the telephone number for the person completing the Prior
                                                        Authorization Request Form.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Fax number :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.authorization?.faxNumber ?? ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                           authorization: {
                                                                ...formData?. authorization,
                                                                faxNumber: value 
                                                            }
                                                        });
                                                    }}
                                                />
                                                <span className="ms-2 d-flex align-items-center">
                                                    <i className='bx bx-info-circle' id="faxPInfo" style={{ cursor: 'pointer' }}></i>
                                                    <UncontrolledTooltip placement="bottom" target="faxPInfo">
                                                        Enter the Fax number for the person completing the Prior Authorization
                                                        Request Form.
                                                    </UncontrolledTooltip>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>For Plan Use Only</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Denial Reason(s): Refer to table above by reference numbers (REF NO.) :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <textarea
                                                id="customTextarea"
                                                className="form-control"
                                                rows={1}
                                                style={{
                                                    overflow: 'hidden',
                                                    resize: 'none',
                                                    maxHeight: '7.5em',
                                                    lineHeight: '1',
                                                    fontSize: "0.70rem",
                                                    width: '100%'
                                                }}
                                                value={formData?.referenceNumber?? ''}
                                                onInput={(e) => autoResizeTextarea(e)}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    referenceNumber: e.target.value 
                                                })}
                                            ></textarea>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2" style={{ backgroundColor: "#F1F1F1", border: "none", borderRadius: "15px", padding: "10px" }}>
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>IF APRROVED :<br />
                                                Services Authorized to Begin</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex flex-column align-items-center">
                                                <div className="sign">
                                                    <div className='sigCanvasNewDiv'>
                                                        {clinicianSign ? (
                                                            <img className="sigCanvasNew" src={formData?.signature} />
                                                        ) : (
                                                            <SignaturePad
                                                            backgroundColor="rgb(245,242,241)"
                                                            ref={signPad}
                                                            penColor="black"
                                                            canvasProps={{ className: "sigCanvasNew" }}
                                                            ></SignaturePad>
                                                        )}
                                                    </div>
                                                </div>
                                                {!clinicianSign ? (
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div>
                                                            <a onClick={() => { clearsign(); }} className="btn btn-primary btn-sm mr-1">Clear</a>
                                                        </div>
                                                        <div>
                                                            <a onClick={() => { saveSign(); }} className="btn btn-primary btn-sm">Save</a>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <a onClick={() => editSign()} className="btn btn-primary btn-sm mt-2">Edit</a>
                                                    </div>
                                                )}
                                                <label style={{ marginTop: "0.5rem",fontSize: "12px"  }}>Signature</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={saveHealthyBlueAuthForm}
                            disabled={isSaving}
                        >
                            {isSaving ? "Saving..." : "Save"}
                        </button>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default HealthyBlueForm;