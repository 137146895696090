import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Modal } from "reactstrap";
import DiscoverIcon from "../../assets/images/icons/discover_icon.png";
import SessionCard from "./SessionCard";
import images from "../../assets/images";
import UserContext from "src/context/UserContext";
import { TherapistService } from "src/services/TherapistService";
import { TreatmentHistoryData } from "src/models/TreatmentHistory";
import { Util } from "src/Util";
import DiagnosisNoteScreen from "../Chat/DiagnosisNote/DiagnosisNoteView";
import Modal23 from "../Popup/Modal23";
import SkeltonCompletedSession from "src/common/skeleton/SkeltonCompletedSession";
import Close from "../../assets/images/icons/u_times-circle.png";
import PIEDiagnosisNoteViewNew from './../Chat/DiagnosisNote/PIEDiagnosisNoteViewNew';

interface completedSessionsProps {
  allTreatmentSessionStats: any[];
  isLoading: boolean;
  updateDiagnosisNote: (isUpdate: boolean) => void;
}

const CompletedSessions: React.FC<completedSessionsProps> = ({ allTreatmentSessionStats, isLoading, updateDiagnosisNote }) => {
  const [selectedTreatmentHistoryForMerge, setSelectedTreatmentHistoryForMerge] = useState<string[]>([]);
  const [user] = useContext(UserContext);
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [offset, setOffset] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(true);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [totalSpentDuration, setTotalSpentDuration] = useState(0);
  const [isCreating] = useState(false);
  const [isDiagnosisNoteOpen, setIsDiagnosisNoteOpen] = useState(false);
  const [showRecording, setShowRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sessionList, setSessionList] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [searchName, setSearchName] = useState<string>("")
  const [isViewMoreLoading, setViewMoreIsLoading] = useState<boolean>(false);
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const [showPIENote, setShowPIENote] = useState(false);
  const [noteType, setNoteType] = useState<string | undefined>();
  const [isVersion, setIsVersion] = useState<boolean>(false);

  useEffect(() => {
    setIsTreatmentError(false);
    setIsTreatmentLoading(true);
  }, []);

  useEffect(() => {
    setSessionList(allTreatmentSessionStats)
  }, [allTreatmentSessionStats]);

  useEffect(() => {
    if (diagnosisId != "") {
      setIsDiagnosisNoteOpen(true);
      setOpenModal(true)
    }
  }, [diagnosisId]);

  const viewMoreSessions = () => {
    const userId = user?._id;

    const data = {
      searchClientName: searchName
    }

    const updatedOffset = offset + 1;

    setOffset(updatedOffset);
    setViewMoreIsLoading(true);

    TherapistService.getSessions(data, userId, limit, updatedOffset).then(res => {
      if (res.success) {
        setSessionList([...sessionList, ...res.data]);
        setViewMoreIsLoading(false)
      } else {
        setViewMoreIsLoading(true)
      }
    });
  }

  const searchSessions = () => {
    const userId = user?._id;

    const data = {
      searchClientName: searchName
    }

    TherapistService.getSessions(data, userId, limit, offset).then(res => {
      if (res.success) {
        setSessionList(res.data)
      }
    });
  }

  function showDiagnosis(diagnosisId: string, totalSpentDuration: number, noteType: string | undefined, isVersion: boolean) {
    if (diagnosisId) {
      setNoteType(noteType);
      setDiagnosisId(diagnosisId);
      setTotalSpentDuration(totalSpentDuration);
      setIsVersion(isVersion);

      if(noteType) {
          if(noteType=== 'PIE') {
                setShowPIENote(true);
                setShowAiGeneratedPopup(false);
          } else {
                setShowPIENote(false);
                setShowAiGeneratedPopup(true);
          }
      }
    }
  }

  function updateDiagnosisData(isUpdate: boolean) {
    if (isUpdate == true) {
      updateDiagnosisNote(true);
    }
  }

  function saveNewNote(transcribeData: any, noteType: string) {
    const selectedHistory = sessionList.filter(c => c._id == transcribeData._id);

    (selectedHistory[0] as any).diagnosisNoteDetails.updatedByTherapist = true;
    (selectedHistory[0] as any).diagnosisNoteDetails.noteType = noteType;
  }

  function showAudio(audioUrl: string, showAudio: boolean) {
    setAudioURL(Util.audioURL(audioUrl));
    setShowRecording(showAudio);
  }

  function closeDiagnosis() {
    setDiagnosisId("");
    setIsDiagnosisNoteOpen(false);
    setOpenModal(false)
  }

  return (
    <React.Fragment>
      <Card className="w-100">
        <CardBody className="m-p-zero">
          <div className="d-flex justify-content-between mb-3">
            <div className="flex ">
              <span className="rounded-icon-bg-n d-flex justify-content-center align-items-center">
                <img className="icon-h-20" src={DiscoverIcon} />
              </span>
              <h5 className="mb-0 d-flex justify-content-center align-items-center ms-3 fc-black">Completed Sessions</h5>
            </div>
            <div className="flex ">
              <div className="d-sm-flex justify-content-center pr-2 d-none d-sm-block">
                <div className="d-flex justify-content-center align-items-center pr-2">
                </div>

                <div >
                  <input
                    className="search-input-stat form-control me-2"
                    placeholder="Client name..."
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </div>
              </div>

              <span
                className="btn btn-warning  btn-sm  mx-2 d-none d-sm-block search-icon-dashboard"
                onClick={(event) => {
                  searchSessions();
                }}
              >
                <i className="bx bx-search"></i>
              </span>
            </div>
          </div>
          <div className=" custom-vertical-scroll">
            {sessionList?.map((session: any, index: number) => (
              <SessionCard
                transcribeData={session}
                key={index}
                selectedTreatmentHistoryForMerge={selectedTreatmentHistoryForMerge}
                setSelectedTreatmentHistoryForMerge={setSelectedTreatmentHistoryForMerge}
                setSelectedClient={setSelectedClient}
                showDiagnosisFunction={showDiagnosis}
                isCreating={isCreating}
                showAudioFunction={showAudio}
                updateDiagnosisData={updateDiagnosisData}
                saveNewNote={saveNewNote}
              />
            ))}

            {
              sessionList?.length == 0 && !isLoading && (
                <div className="text-upcoming mt-10">No Sessions</div>
              )
            }

            {isLoading && (
              <SkeltonCompletedSession />
            )}
          </div>

          <div className="d-flex justify-content-end mt-2">
            {
              sessionList?.length % 10 === 0 && sessionList?.length != 0 && !isLoading &&
              <button className="btn btn-primary" onClick={() => viewMoreSessions()}>
                {isViewMoreLoading ?
                  (
                    <div className="flex justify-content-center">
                      <img src={images.cubicLoader} style={{ width: "25px" }} />
                    </div>
                  ) : (
                    <>
                      View More </>)}
              </button>

            }
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={openModal}
        centered
        fullscreen
        scrollable
        style={{
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div className="modal-content" style={{
          width: "100%",
        }}
        >
          <div className="modal-head" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }} >
            <div className="diagnosis-header">
              <h5 className="mb-0 font-size-15">Diagnosis Note</h5>
              <button
                type="button"
                onClick={closeDiagnosis}
                className="bg-transparent-color"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={Close} alt="Close" className="close-icon-d" />
              </button>
            </div>
          </div>

          <div>
            <div className="row pt20 pl10 chat-conversation p-3">
              <ul className="list-unstyled">
                <div
                  className="scrollbar-container ps--active-y"
                  style={{
                    // height: isTranscribeOpen || isDiagnosisNoteOpen ? "80vh" : "83vh",
                    height: isDiagnosisNoteOpen ? "80vh" : "83vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
              {isDiagnosisNoteOpen && (noteType)
                    ? noteType == "PIE"
                      ? showPIENote && (
                          <PIEDiagnosisNoteViewNew
                            diagnosisId={diagnosisId}
                            totalSpentDuration={totalSpentDuration}
                            updateDiagnosisData={updateDiagnosisData}
                            isVersion={isVersion}
                          />
                        )
                      : showAiGeneratedPopup && (
                          <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData} isVersion={isVersion} />
                        )
                    : isDiagnosisNoteOpen && (
                        <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration} updateDiagnosisData={updateDiagnosisData} isVersion={isVersion} />
                      )}

                  {/* {isDiagnosisNoteOpen && <DiagnosisNoteScreen diagnosisId={diagnosisId} totalSpentDuration={totalSpentDuration}   updateDiagnosisData={updateDiagnosisData}></DiagnosisNoteScreen>} */}

                  {/* {isTreatmentLoading && !isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {isTranscribeLoading && !isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {isDiagnosisLoading && !isDiagnosisError && isDiagnosisNoteOpen && (
                    <div style={{ textAlign: "center" }}>
                      <img src={images.cubicLoader} style={{ width: "90px", marginTop: "10vh" }} />
                    </div>
                  )}

                  {!isTreatmentLoading && isTreatmentError && !isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isTranscribeLoading && isTranscribeError && isTranscribeOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )}

                  {!isDiagnosisLoading && isDiagnosisError && isDiagnosisNoteOpen && (
                    <div style={{ textAlign: "center" }}>
                      <h5>Server error occurred</h5>
                    </div>
                  )} */}

                  {/* {!isDiagnosisNoteOpen &&
                    !isTreatmentLoading &&
                    !isTreatmentError &&
                    !isTranscribeOpen &&
                    treatmentHistoryDetails &&
                    treatmentHistoryDetails.length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        <h5>No treatment history notes are created yet</h5>
                      </div>
                    )} */}

                  {showRecording && <Modal23 setShowModal={setShowRecording} showModal={showRecording} audioURL={audioURL} />}


                </div>
              </ul>
            </div>


          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CompletedSessions;
