import React from "react";
import { Container, Card, CardBody, Button } from "reactstrap";

const PageNotFound: React.FC = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardBody>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <Button href="/" color="primary">
              Go to Homepage
            </Button>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default PageNotFound;
