import React, { useEffect, useState, useContext, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClientService } from "src/services/ClientService";
import { AppointmentService } from "src/services/AppointmentService";
import { Therapist } from "src/models/Therapist";
import { Client } from "src/models/Client";
import { AuthService } from "src/services/AuthService";
import MainDetailsContext from "src/context/MainDetailsContext";
import FooterMain from "./FooterMain";
import HeaderMain from "./HeaderMain";

const PublicVerifySignup: React.FC = (props: any) => {
  const [verifyCode, setVerifyCode] = useState("");
  const [isDisable, setDisable] = useState(false);
  const [time, setTime] = useState(30);
  const [reset, setReset] = useState(false);
  const storedClient = localStorage.getItem('client');
  const storedPasswordObj = localStorage.getItem('password');
  const storedClientData: any = JSON.parse(storedClient as any);
  const storedpasswordData: any = JSON.parse(storedPasswordObj as any);
  const [
    therapistData,
    setTherapistData,
    publicAppoinmentData,
    setPublicAppoinmentData,
    userDataMainContext,
    setUserDataMainContext,
    passwordMainContext,
    setPasswordMainContext,
  ] = useContext(MainDetailsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const startTimer = () => {
      timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
    };

    const stopTimer = () => {
      clearInterval(timer);
      setReset(true);
    };

    if (time > 0) {
      startTimer();
    } else {
      stopTimer();
    }

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const handleReset = () => {
    setTime(60);
    setReset(false);
  };

  const resednCode = () => {
    handleReset();
    const userID = storedClientData?._id;
    const data = {
      userId: userID,
    };
    if (userID) {
      ClientService.resendVerifyCode(data).then(res => {
        if (res.success) {
          toast.success("Resend Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };
  const createClient = () => {
    let updatedUser;
    if (!verifyCode) {
      toast.error("Verify code is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      setDisable(true);
      updatedUser = {
        verificationCode: verifyCode,
        userId: storedClientData?._id,
      };
      setDisable(true);
      ClientService.verifyUser(updatedUser).then(res => {
        if (res.success) {
          setDisable(true);
          const data = {
            email: storedClientData?.email,
            password: storedpasswordData,
            medium: "EMAIL",
          };
          AuthService.userLogin(data).then(res => {
            if (res.success) {
              setDisable(true);
              const currentDate = new Date().toUTCString();
              AuthService.setCookie("currentDate", currentDate, 365);
              location.href = "/dashboard";
            }
          });
        } else {
          setDisable(false);
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  return (
    <>
      <div className="content-wrapper ">
        <HeaderMain />
        <section
          className="bg-image-set wrapper image-wrapper bg-image bg-overlays bg-overlay-light-600 text-white"
          data-image-src="./../static/assets/img/photos/bg3.jpg"
        >
          <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center"></div>
        </section>

        <section className="wrapper bg-light2 angled upper-end" id="recoverform">
          <div className="container1 pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
                <div className="displaycenter">
                  <div className="card cardChange">
                    <div className="card-body  text-center">
                      <Row style={{ minHeight: "70vh" }}>
                        <Col xl="7" sm="12" className="pading-signup py-15 ">
                          <h1 className="mb-3  text-start mt-5  text-center">Client Signup</h1>
                          <p className="lead mb-6 text-start  text-center">Enter the verification code sent to your email/text</p>
                          <form className="text-start mb-3 needs-validation">
                            <Row>
                              <Col xl="1"></Col>
                              <Col xl="10">
                                <div className="card-heading mb-4 flex">
                                  <Input
                                    type="text"
                                    className="form-control public-input"
                                    id="formrow-firstname-Input"
                                    placeholder="Verification code"
                                    name="email"
                                    onChange={e => setVerifyCode(e.target.value)}
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xl="1"></Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="d-flex justify-content-center align-items-center">
                                  <p className=" text-center fs-15">
                                    {time > 0 && <span>Wait: {time} To </span>}
                                    <span className="pl-1">
                                      {reset ? (
                                        <a className="cursor-p" onClick={() => resednCode()}>
                                          Resend Code
                                        </a>
                                      ) : (
                                        <a className="disabled-resent">Resend </a>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="1"></Col>
                              <Col xl="10">
                                <div className="  text-center">
                                  <input
                                    className="btn btn-primary btn-send mb-3 px-md py-3 br-10 fsize-1"
                                    type="button"
                                    value="Verify Code & Sign Up"
                                    disabled={isDisable}
                                    onClick={createClient}
                                  />
                                </div>
                              </Col>
                              <Col xl="1"></Col>
                            </Row>
                          </form>
                        </Col>
                        <Col xl="5" className="bg-public2"></Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="overflow-hidden">
          <div className="divider text-navy mx-n2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" style={{ color: "#343f52" }}>
              <path
                fill="currentColor"
                d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
              />
            </svg>
          </div>
        </div>
        <FooterMain />
      </div>
    </>
  );
};
export default PublicVerifySignup;
