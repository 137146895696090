import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import CoverImage from '../../../../../../assets/images/end-meeting.png'
import Animation from '../../../../../../assets/images/end-meeting-animation.gif'
import CorrectMark from '../../../../../../assets/images/right mark.png'
import styles from './styles';
import SessionFeedbackModal from 'src/pages/Popup/SessionFeedBackModal';
import UserContext from "src/context/UserContext";
import { VonageServices } from "../../../vonageServices";
import { toast } from "react-toastify";

export default function EndCall() {
  const history = useHistory();
  const [recordings, setRecordings] = useState(null);
  const classes = styles();
  const { sessionId } = useParams();
  const [isOpenSessionFeedbackModal, setIsOpenSessionFeedbackModal] = useState(true);
  const [loggedUser] = useContext(UserContext);

  useEffect(()=> {
    console.log("sessionId ", sessionId);
    // const popupTimeout = setTimeout(() => {
    //   window.location.href = "/dashboard";
    // }, 5000);

    // return () => clearTimeout(popupTimeout);
  }, [])

  const redirectToDashboard = () => {
    window.location.href = "/dashboard";
  };

  const closeSessionFeedbackModal = async (rate, feedback, isCloseBtnClicked) => {
    try {
      console.log("Close modal ", rate, feedback);
      setIsOpenSessionFeedbackModal(false);
      if (!isCloseBtnClicked){
        const data = {
          meetingId: sessionId, 
          rate, 
          feedback, 
          createdBy: loggedUser?._id
        }
        const feedbackRes = await VonageServices.saveSessionFeedback(data);
        console.log(feedbackRes);
        if (feedbackRes.success){
          toast.success(`Your feedback added successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        }
      }
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 3000);
    } catch (error) {
      console.log(error);
    }
    
  }

  return (
    <div>
      <SessionFeedbackModal isOpen ={isOpenSessionFeedbackModal} toggle={closeSessionFeedbackModal} />
      <div className={classes.container}>
        <div className={classes.meetingInfo}>
          <div className={classes.infoContainer}>
            <div className={classes.meetingInfoNew}>
              <img src={CoverImage} className={classes.coverImg} />
              <div className={classes.overlay}>
                <img src={Animation} />
              </div>
              <div className={classes.overlay}>
                <img src={CorrectMark} className={classes.correctImg} />
              </div>
            </div>
            <p className={classes.overlayText}>Meeting Successful</p>
            <p className={classes.overlayText2}>Thank you for taking this journey with us for your personal growth and well-being.</p>
            <div className={classes.btnContainer}>
              <button
                className="btn btn-primary mb-4"
                onClick={redirectToDashboard}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
