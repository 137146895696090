
import React, { useState, useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import InsuranceFormOne from "./InsuranceFormOne";
import InsuranceFormTwo from "./InsuranceFormTwo";
import InsuranceFormThree from "./InsuranceFormThree";
import ViewInsuranceForm from "./ViewInsurance";

toast.configure();

const AddInsurance: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [twoFeeTypes, setTwoFeeTypes] = useState(false);

  const addFormTwoHandler = () => setTwoFeeTypes(true);

  const removeFormTwoHandler = () => setTwoFeeTypes(false);
  const [organization, setOrganization] = useState("");
  const [insuranceCard, setInsuranceCardData] = useState({});
  const [insuranceCardBack, setInsuranceCardBackData] = useState({});
  const [subscriberData, setSubscribersData] = useState({});
  const [insuranceData, setInsuranceData] = useState({});
  const setOrganizationData = (organizationName: string) => {
    setOrganization(organizationName);
  }

  const setSubscriberData = (data: string) => {
    setSubscribersData(data)
  }

  const addInsuranceCard = (data: string) => {
    setInsuranceCardData(data)
  }

  const addInsuranceCardBack = (data: string) => {
    setInsuranceCardBackData(data)
  }

  const setInsuranceDetails = (data: string) => {
    setInsuranceData(data)
  }

  return (
    <React.Fragment>
      <Col xl={12}>
        {user?.insuranceId == undefined ?
          <>
            <StepWizard>
              <InsuranceFormOne addFormTwoHandler={addFormTwoHandler} addOrganizationData={setOrganizationData} addInsuranceCard={addInsuranceCard} addInsuranceCardBack={addInsuranceCardBack} />
              <InsuranceFormTwo removeFormTwoHandler={removeFormTwoHandler} addSubCribeData={setSubscriberData} />
              <InsuranceFormThree removeFormTwoHandler={removeFormTwoHandler} addInsuranceDetails={setInsuranceDetails} firstData={organization} secondData={subscriberData} insuranceCardImage={insuranceCard} insuranceCardImageBack={insuranceCardBack} />
              <ViewInsuranceForm removeFormTwoHandler={removeFormTwoHandler} lastData={insuranceData} />
            </StepWizard>
          </>
          :
          <ViewInsuranceForm removeFormTwoHandler={removeFormTwoHandler} lastData={insuranceData} />
        }
      </Col>
    </React.Fragment>
  );
};

export default AddInsurance;
