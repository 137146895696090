import React from "react";
import moment from "moment";

interface dayObject {
  isDate: boolean;
  date: Date;
  isValid: boolean;
}

interface Props {
  data: dayObject;
  onDateClick: (date: Date) => void;
  selectedDate: Date | null;
}

const CustomAppointmentCalendarDateComponent = ({ data, onDateClick, selectedDate }: Props) => {
  const today: Date = new Date();
  const isSelected = data && data.isDate && data.date && selectedDate && moment(data.date).isSame(moment(selectedDate), "day");
  const isToday = data && data.isDate && data.date && moment(data.date).isSame(moment(today), "day");

  return (
    <div>
      {data && data.isDate && data.date ? (
        <div
          className={`d-flex justify-content-center align-items-center rounded-circle mt-1 ${
            data.isValid
              ? isSelected
                ? "custom-appointment-calender-available-date-selected"
                : isToday
                ? "custom-appointment-calender-available-date-today"
                : "custom-appointment-calender-available-date-not-selected"
              : isToday
              ? "custom-appointment-calender-available-date-today-unavailable"
              : "custom-appointment-calender-unavailable-date"
          }`}
          onClick={() => {
            if (data && data.isDate && data.date && data.isValid && !isSelected) {
              onDateClick(data.date);
            }
          }}
        >
          {moment(data.date).date().toString()}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center rounded-circle mt-1 custom-appointment-calender-empty-date"></div>
      )}
    </div>
  );
};

export default CustomAppointmentCalendarDateComponent;
