import React from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import "./../../assets/css/onboarding.css";
import "./../../assets/css/publicMedia.css";
import TherapistList from "./therapistList";
import HeaderMain from "./HeaderMain";
import FooterMain from "./FooterMain";

const Main: React.FC = () => {
  return (
    <div className="content-wrapper ">
      <HeaderMain />
      <section
        className="bg-image-set wrapper image-wrapper bg-image bg-overlays bg-overlay-light-600 text-white"
        data-image-src="./../static/assets/img/photos/bg3.jpg"
      >
        <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center"></div>
      </section>

      <section className="wrapper bg-light2 angled upper-end" id="recoverform">
        <div className="container1 pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
              <TherapistList />
            </div>
          </div>
        </div>
      </section>

      <div className="overflow-hidden">
        <div className="divider text-navy mx-n2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" style={{ color: "#343f52" }}>
            <path fill="currentColor"
              d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z" />
          </svg>
        </div>
      </div>
      <FooterMain />
    </div>
  );
};

export default Main;
