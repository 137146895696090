import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
  Input,
  Modal,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  ButtonGroup,
  Button,
  ModalBody
} from "reactstrap";
const classNames = require("classnames");
import DiscoverArticle from "./DiscoverArticle";
import QuickChat from "./QuickChat";
import WelcomeCompNew from "./WelcomeCompNew";
import IncommingCall from "./IncommingCall";
import UpcomingAppointmentsNew from "./UpcommingAppointmentNew";
import AvatarDashboard from "./AvaterDashboard";
import DiscoverProfessionals from "./DiscoverProfessionals";
import ClientMatches from "./ClientMatches";
import NewAppointmentsNew from "./NewAppointment";
import { TherapistService } from "src/services/TherapistService";
import { Appointment } from "src/models/Appointment";
import { ClientService } from "src/services/ClientService";
import { ViewArticleModel } from "src/models/Article";
import { FriendRequest } from "src/models/FriendRequest";
import { DiscoverTherapist, Therapist } from "src/models/Therapist";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import Select from "react-select";
import { ClientPreferences } from "src/models/Client";
import UserContext from "src/context/UserContext";
import { Ethinicity } from "src/models/Ethnicity";
import { ExperienceTag, SelectedExperienceTag } from "src/models/ExperienceTag";
import { Profession } from "src/models/Profession";
import { Tags } from "src/common/custom-components/Tags";
import { CommonService } from "src/services/CommonService";
import { ArticleService } from "src/services/ArticleService";
import UserDetails from "./common/UserDetails";
import UpComingGroupCall from "./UpComingGroupCall";
import { useHistory } from 'react-router-dom';
import Close from "../../assets/images/icons/close.png";
import { GoogleLogin } from "react-google-login";
import { environment } from "../../environment/environment";
import ShowOngoingCalls from "./common/ShowOngoingCalls";

const ClientDashboardNew: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [tabNo, setTabNo] = useState<string>("1");
  const [upcomingAppointments, setUpcomingAppointments] = useState([] as Appointment[]);
  const [matchedTherapists, setMatchedTherapists] = useState([] as FriendRequest[]);
  const [matchedTherapistMobile, setMatchedTherapistMobile] = useState([] as FriendRequest[]);
  const [discoverTherapists, setDiscoverTherapists] = useState([] as Therapist[]);
  const [newAppointments, setNewAppointments] = useState([] as Appointment[]);
  const [articles, setNewArticles] = useState([] as ViewArticleModel[]);
  const [requestTherapistCount, setRequestTherapistCount] = useState<number>(0);
  const [requestTherapistCount2, setRequestTherapistCount2] = useState<number>(0);
  const [clientStats, setClientStats] = useState<number>(0);
  const [skip, setSkip] = useState(1);
  const [skip2, setSkip2] = useState(1);
  const [modal_center, setModalCenter] = React.useState(false);
  const [addReview, setReview] = useState<any>({} as any);
  const [rating1, setRating1] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdateOpen, setIsFilterUpdateOpen] = useState(false);
  const [gender, setGender] = useState(user?.preference?.gender);
  const [selectedExpTags, setSelectedExpTags] = useState<any[]>([] as SelectedExperienceTag[]);
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [searchTherapistName, setSearchTherapistName] = useState("");
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);
  const [isProfileDetails, setProfileDetails] = useState<any>({} as any);
  const [isReviewTherapistData, setReviewTherapistData] = useState<any>([] as any);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const SCREEN_SIZE = 991;
  let updatedSkip = 3;
  let updatedSkip2 = 3;
  const [upCommingGroupCalls, setUpCommingGroupCalls] = useState([]);
  const history = useHistory();
  const [isOpenGooglebtnModal, setIsOpenGooglebtnModal] = useState(false);
  const [showGoogleBtn, setShowGoogleBtn] = useState(false);

  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout;
    
    if (user?.createdAt && user?.googleCalendarAccess == undefined) {
      const currentTime = new Date();
      const createdAtTime = new Date(user?.createdAt);
      const timeDifference = currentTime.getTime() - createdAtTime.getTime();
      const thirtyMinutes = 30* 60 * 1000;

      if (timeDifference <= thirtyMinutes) {
        redirectTimeout = setTimeout(() => {
          setIsOpenGooglebtnModal(true);
        }, 10000); // 20 seconds in milliseconds
      }
    }

    return () => clearTimeout(redirectTimeout);
  }, [user?.createdAt]);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  const toggle = (tab: string) => {
    setTabNo(tab);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const reviewCompleted = localStorage.getItem("review");
    if (reviewCompleted) {
      setModalCenter(true);
    }
  }, []);

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthnicityList(res.data);
    });

    TherapistService.getProfessions().then(res => {
      setProfessionsDetails(res.data);
    });

    const selectedExp = user?.preference?.experiencedIn?.map(item => {
      if (item) {
        return { value: item._id, label: item.experienceTag };
      }
    });

    setSelectedExpTags(selectedExp ? selectedExp : []);
    setGender(user?.preference?.gender ? user?.preference?.gender : "");
    setSelectedEthnicity(user?.preference?.ethnicityId ? user?.preference?.ethnicityId : "");
    setSelectedProfession(user?.preference?.professionId ? user?.preference?.professionId : "");

    getAllExperienceTags();
  }, []);

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });

        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };

  const loadData = () => {
    ClientService.getClientDashboardStats().then(res => {
      setUpcomingAppointments(res.data?.upcommingAppointmentStats);
      setMatchedTherapists(res.data?.matchedTherapistStats);
      setMatchedTherapistMobile(res.data?.matchedTherapistMobileStatus);
      setDiscoverTherapists(res.data?.allTherapistStats);
      setUpcomingAppointments(res.data?.upcommingAppointmentStats);
      setNewAppointments(res.data?.pendingAppointmentStats);
      setNewArticles(res.data?.articleStats);
      setClientStats(res.data?.clientStats);
      setUpCommingGroupCalls(res?.data?.allUpCommingGroupCalls);
      setIsLoading(false);
    });

    getRequestTherapyCount();
    getRequestDiscoverTherapyCount();
  };

  const handleNext = () => {
    updatedSkip = skip + 1;

    TherapistService.getAllAddedTherapists(3, updatedSkip).then(res => {
      setMatchedTherapists(res.data);
      setSkip(updatedSkip);
    });
  };

  const handleNextMobile = () => {
    updatedSkip = skip + 1;

    TherapistService.getAllAddedTherapists(1, updatedSkip).then(res => {
      setMatchedTherapistMobile(res.data);
      setSkip(updatedSkip);
    });
  };

  const handleBack = () => {
    updatedSkip = skip - 1;

    TherapistService.getAllAddedTherapists(3, updatedSkip).then(res => {
      setMatchedTherapists(res.data);
      setSkip(updatedSkip);
    });
  };

  const handleBackMobile = () => {
    updatedSkip = skip - 1;

    TherapistService.getAllAddedTherapists(1, updatedSkip).then(res => {
      setMatchedTherapistMobile(res.data);
      setSkip(updatedSkip);
    });
  };

  const getRequestTherapyCount = () => {
    TherapistService.getAllAddedTherapists(1000, 0).then(res => {
      setRequestTherapistCount(res.data?.length);
    });
  };

  const data: DiscoverTherapist = {};
  const handleDiscoverNext = () => {
    updatedSkip2 = skip2 + 1;

    TherapistService.searchTherapists(data, 3, updatedSkip2).then(res => {
      setDiscoverTherapists(res.data);
      setSkip2(updatedSkip2);
    });
  };

  const handleDiscoverBack = () => {
    updatedSkip2 = skip2 - 1;

    TherapistService.searchTherapists(data, 3, updatedSkip2).then(res => {
      setDiscoverTherapists(res.data);
      setSkip2(updatedSkip2);
    });
  };

  const getRequestDiscoverTherapyCount = () => {
    TherapistService.searchTherapists(data, 1000, 1).then(res => {
      setRequestTherapistCount2(res.data?.length);
    });
  };

  function togCenterReview() {
    setModalCenter(!modal_center);
  }

  const AddReviewData = () => {
    const reviewData = { review: addReview.review, stars: rating1 };

    ClientService.addClientReview(reviewData).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setReview({ review: "" });

        setRating1(0);

        setModalCenter(false);

        localStorage.removeItem("review");
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const toggleFilterCanvas = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const updateTherapistSearchPreferences = () => {
    const clientPreferences: ClientPreferences = {
      gender: gender,
      ethnicityId: selectedEthnicity,
      professionId: selectedProfession,
      experiencedIn: selectedExpTags.map(tag => {
        return tag.value;
      }),
    };

    ClientService.addPreferences(clientPreferences).then(res => {
      if (res.success) {
        setUser({ ...user, preference: clientPreferences });

        toast.success(res.success, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsFilterUpdateOpen(false);
      }
    });
  };

  const changeGender = useCallback(
    (gender: string) => () => {
      setIsFilterUpdateOpen(true);
      setGender(gender);
    },
    []
  );

  const handlExpTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label, value: tag.value };
      });

      if (updatedTags) {
        setIsFilterUpdateOpen(true);

        setSelectedExpTags(updatedTags);
      }
    }
  };

  const removeExpTag = (removedtag: any) => {
    setIsFilterUpdateOpen(true);

    setSelectedExpTags(selectedExpTags.filter(tag => tag.label !== removedtag));
  };

  const refreshList = (data: any) => {
    loadData();
    const filteredT = discoverTherapists.filter(t => t._id !== data._id);
    setDiscoverTherapists(filteredT);
  };

  const applyFilters = () => {
    const expTags = selectedExpTags?.map((tag: { label: any; value: any }) => {
      return tag.value;
    });

    const discoverObject: DiscoverTherapist = {
      ethnicity: selectedEthnicity,
      profession: selectedProfession,
      experiencedIn: expTags,
      gender: gender,
      searchTherapistName: searchTherapistName
    };

    updatedSkip2 = 1;

    TherapistService.searchTherapists(discoverObject, 3, updatedSkip2).then(res => {
      setDiscoverTherapists(res.data);
      toggleFilterCanvas();
      setSkip2(updatedSkip2);
    });
  };

  const viewSelectedUserProfile = (data: any) => {
    TherapistService.getTherapistDetailsbyId(data._id).then(res => {
      setProfileDetails(res.data);
    });

    setIsViewProfile(true);
  };

  const toggleGooglebtnModal = () => {
    setIsOpenGooglebtnModal(!isOpenGooglebtnModal)
    setShowGoogleBtn(false)
  }

  const responseGoogle = (response: any) => {
        if (response) {
            CommonService.getGoogleCalendarRefreshToken(response)
                .then(res => {
                    if (res) {
                        toast.success("Google calendar sign in success.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        
                        handleNoGoogleSync()
                    } else {
                        toast.error("Google calendar sign in fail.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                })
                .catch(err => {
                    toast.error("Google calendar sign in fail.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                });
        }
    };
    const responseError = (error: any) => {
        toast.error("Google calendar sign in fail.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
        });
    };

    const handleNoGoogleSync = async () => {
      const res = await ClientService.setGoogleCalendarAccessToFalse();
      if (res.success){
        setUser({...user, googleCalendarAccess: false});
        setIsOpenGooglebtnModal(false);
      }
      
    }
    const handleYesGoogleSync = () => {
        setShowGoogleBtn(true)
    }

  return (
    <React.Fragment>
      <div className="page-content m-bg-color-white">
        <div>
          <Row>
            <Col xl="4">
              <Row>
                <div className="overflow-hidden">
                  <WelcomeCompNew clientStats={clientStats} isLoading={isLoading} />
      
                  {newAppointments?.length > 0 && !isLoading && <NewAppointmentsNew newAppointments={newAppointments} isLoading={isLoading} />}
                  {upcomingAppointments?.length > 0 && !isLoading && <UpcomingAppointmentsNew upcomingAppointments={upcomingAppointments} isLoading={isLoading} />}

                  <div className="d-none d-xl-block">
                    <AvatarDashboard />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="overflow-hidden">
                  <ShowOngoingCalls />
                </div>
              </Row>
              <Row>
                <div className="overflow-hidden">
                  {upCommingGroupCalls.length > 0 && <UpComingGroupCall upcomingGroupCalls={upCommingGroupCalls} isLoading={isLoading} />}
                </div>
              </Row>
            </Col>

            <Col xl="8">
              <div className="d-none d-xl-block">
                <Row>
                  <Col xl="6" className="d-flex justify-content-center">
                    <DiscoverArticle articles={articles} isLoading={isLoading} />
                  </Col>
                  <Col xl="6" className="d-flex justify-content-center">
                    <QuickChat />
                  </Col>
                </Row>
              </div>

              <Card className={`${!isViewProfile ? "show" : "hidden"}`}>
                <CardBody className="m-p-zero">
                  <Row>
                    <Col>
                      <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: tabNo === "1" })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            <span>My Matches</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: tabNo === "2" })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            <span className="">Discover Licensed Professionals</span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      {tabNo === "2" && (
                        <Row>
                          <div className="d-flex justify-content-end mt-2 mb-4">
                            <button className="btn btn-primary personalized-btn-dashboard btn-sm" onClick={toggleFilterCanvas}>
                              Personalize my match&nbsp;<i className="fa fa-filter"></i>
                            </button>
                          </div>
                        </Row>
                      )}

                      <TabContent activeTab={tabNo} className="mt-3">
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              {screenSize > SCREEN_SIZE ?
                                <ClientMatches
                                  isLoading={isLoading}
                                  matchedTherapists={matchedTherapists}
                                  onNext={handleNext}
                                  onBack={handleBack}
                                  requestTherapistCount={requestTherapistCount}
                                  offset={skip}
                                  sentSelectedUser={(data: any) => {
                                    viewSelectedUserProfile(data);
                                  }}
                                />
                                :
                                <ClientMatches
                                  isLoading={isLoading}
                                  matchedTherapists={matchedTherapistMobile}
                                  onNext={handleNextMobile}
                                  onBack={handleBackMobile}
                                  requestTherapistCount={requestTherapistCount}
                                  offset={skip}
                                  sentSelectedUser={(data: any) => {
                                    viewSelectedUserProfile(data);
                                  }}
                                />
                              }
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <DiscoverProfessionals
                                discoverTherapists={discoverTherapists}
                                onNext={handleDiscoverNext}
                                onBack={handleDiscoverBack}
                                requestTherapistCount={requestTherapistCount2}
                                offset={skip2}
                                sentSelectedUser={(data: any) => {
                                  viewSelectedUserProfile(data);
                                }}
                                refreshList={(data: any) => {
                                  refreshList(data);
                                }}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <span className={`${isViewProfile ? "show" : "hidden"}`}>
                <UserDetails viewDetails={(e: any) => setIsViewProfile(e)} profileDetails={isProfileDetails} reviewTherapist={isReviewTherapistData} />
              </span>
              <div className="d-block d-xl-none">
                <Row>
                  <Col xl="6">
                    <QuickChat />
                  </Col>
                  <Col xl="6">
                    <DiscoverArticle articles={articles} isLoading={isLoading} />
                  </Col>
                </Row>
                <AvatarDashboard />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          togCenterReview();
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Leave a review</h5>
          <button
            type="button"
            onClick={() => {
              setModalCenter(false);
              localStorage.removeItem("review");
            }}
            className="review-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="mb-2">
            <Input
              type="textarea"
              className="form-control"
              id="formrow-firstname-Input"
              style={{ height: "100%", minHeight: "160px" }}
              placeholder="Your review..."
              onChange={e => setReview({ ...addReview, review: e.target.value })}
              value={addReview.review}
              name="articleTitle"
              required
            />
          </div>

          <Row className="mb-4">
            <Col xl="12" style={{ display: "flex" }}>
              <CardTitle>Rate the therapist:</CardTitle>
              <Rating initialValue={rating1} onClick={(rate: any) => setRating1(rate)} size={20} transition fillColor={"#F2C94C"} />
            </Col>
          </Row>

          <Row>
            <Col xl="3"></Col>
            <Col xl="6">

              <button type="button" className="btn btn-primary w-45 " onClick={() => AddReviewData()}>
                Save
              </button>
              <button
                type="button"
                className="btn  w-45 ml-3  card_shadow"
                onClick={() => {
                  setModalCenter(false);
                  localStorage.removeItem("review");
                }}
              >
                Cancel
              </button>
            </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
      <Offcanvas isOpen={isFilterOpen} direction="end" toggle={toggleFilterCanvas} className="discover-canvas">
        <OffcanvasHeader toggle={toggleFilterCanvas}>Personalize my match</OffcanvasHeader>

        <OffcanvasBody>
          <div className="row  pt20 pl10">
            <div className="filter-option">
              <form className="mb-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Therapist Name..."
                      aria-label="Therapist's name"
                      value={searchTherapistName}
                      onChange={e => setSearchTherapistName(e.target.value)}
                    />
                  </div>
                </div>
              </form>

              <div className="mb-3">Gender:</div>

              <div className="mb-3">
                <div className="flex-wrap">
                  <ButtonGroup className="flex-wrap flex">
                    <Button color="primary" defaultChecked defaultValue={""} outline onClick={changeGender("")} active={gender === ""}>All</Button>
                    <Button color="primary" outline onClick={changeGender("Male")} active={gender === "Male"}>Male</Button>
                    <Button color="primary" outline onClick={changeGender("Female")} active={gender === "Female"}>Female</Button>
                    <Button color="primary" outline onClick={changeGender("NonBinary")} active={gender === "NonBinary"}>Non Binary</Button>
                    <Button color="primary" outline onClick={changeGender("Other")} active={gender === "Other"}>Other</Button>
                  </ButtonGroup>
                </div>
              </div>

              {/* <div className="col-xl-12 pr-3 mb-3">
                <div style={{ display: "flex" }} className="flex-wrap">
                  <div className="mb-3" style={{ marginRight: "1rem" }}>
                    <span className={gender == "" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("")}>
                      All
                    </span>
                  </div>

                  <div className="mb-3 " style={{ marginRight: "1rem" }}>
                    <span className={gender == "Female" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Female")}>
                      Female
                    </span>
                  </div>

                  <div className="mb-3 " style={{ marginRight: "1rem" }}>
                    <span className={gender == "Male" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Male")}>
                      Male
                    </span>
                  </div>

                  <div className="mb-3 " style={{ marginRight: "1rem" }}>
                    <span className={gender == "NonBinary" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("NonBinary")}>
                      Non Binary
                    </span>
                  </div>

                  <div className="mb-3">
                    <span className={gender == "Other" ? "gender-tag-active" : "gender-tag"} onClick={changeGender("Other")}>
                      Other
                    </span>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="filter-option">
              <div className="mb-3">Ethnicity:</div>

              <div className="col-xl-12 mb-3">
                <select
                  className="form-control"
                  onChange={e => {
                    setSelectedEthnicity(e.target.value);
                    setIsFilterUpdateOpen(true);
                  }}
                  defaultValue={selectedEthnicity}
                >
                  <option value="">Select Ethnicity</option>
                  {ethnicityList &&
                    ethnicityList.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.ethnicity}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Profession:</div>

              <div className="col-xl-12 mb-3">
                <select
                  className="form-control"
                  onChange={e => {
                    setSelectedProfession(e.target.value);
                    setIsFilterUpdateOpen(true);
                  }}
                  defaultValue={selectedProfession}
                >
                  <option value="">Select Profession</option>
                  {professionsDetails &&
                    professionsDetails.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="filter-option ">
              <div className="mb-3">Areas Of Specialization:</div>

              <Select
                options={searchExpTag}
                isMulti
                value={selectedExpTags}
                onChange={(newValue: any) => handlExpTags(newValue)}
                display="none"
                className="creatableEdit"
                placeholder="Choose an area"
                controlShouldRenderValue={false}
                isClearable={false}
              />

              <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2 mb-3">
                {selectedExpTags.map((tag, id) => {
                  return tag && <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
                })}
              </div>
            </div>

            <div className="filter-option  text-center">
              <button type="button" className="btn btn-primary mb-3 pt-2 pb-2 profile_watch_buttons" onClick={applyFilters}>
                Apply
              </button>
            </div>

            <div className="filter-option  text-center">
              {isFilterUpdateOpen && (
                <div className="flex">
                  <div className="mb-3 text-center" style={{ margin: "auto" }}>
                    Do you want to update current preferences?
                  </div>
                  <button type="button" className="btn btn-success btn-sm mb-3 mt-3" onClick={updateTherapistSearchPreferences}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      {/* <Modal isOpen={isOpenSignatureModal} centered className="modal-md" >
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}>
          <div className="call-title" style={{ paddingBottom: "2vh" }}>
            <h5>Draw Your Signature</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mb-6">
            <div className="m-2">
              <div className="d-flex flex-column justify-content-center">
                <div className="">
                  <SignaturePad backgroundColor="rgb(245,242,241)" ref={signPad} penColor="black" canvasProps={{ style: { width: 320, minWidth: "100%", maxWidth: "100%" } }}></SignaturePad>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <a 
                      onClick={() => {
                        clearsign();
                      }}
                      className="btn btn-primary btn-sm me-2"
                    >
                      Clear
                    </a>
                    <a
                      onClick={() => {
                        saveSign();
                      }}
                      className="btn btn-primary btn-sm"
                    >
                      Save
                    </a>
                  </div>
                  <button
                    className="btn btn-info accept-btn"
                    onClick={() => {
                      updateAssesmentSignature();
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="call-action-btns" style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}>

          </div>
        </div>
      </Modal> */}
      <Modal isOpen={isOpenGooglebtnModal} toggle={toggleGooglebtnModal} centered className="modal-lg">
                <div style={{ textAlign: "center", paddingRight: "0px" }}>
                    <span></span>
                    <Button
                        close
                        onClick={toggleGooglebtnModal}
                        style={{
                            position: "absolute",
                            right: "15px",
                            background: "none",
                            border: "none",
                            padding: "0",
                            zIndex: "10",
                        }}
                        className="btn-s-20"
                    >
                        <img src={Close} alt="Close" className="mt-1" />
                    </Button>
                </div>
                <ModalBody className="ps-4 pe-4">
                    <Row>
                        <span className="font-size-18 profileFont mb-4 cursor-pointer pt-4 text-center">Would you like to sync your Lavni appointments with your Google Calendar? </span>
                    </Row>
                    {
                        showGoogleBtn && (
                            <Row>
                                <div className="d-flex justify-content-center align-items-center mb-2">
                                    <GoogleLogin
                                        clientId={environment.google_calendar_client_id}
                                        buttonText="Sign in with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseError}
                                        responseType="code"
                                        accessType="offline"
                                        cookiePolicy={"single_host_origin"}
                                        scope="https://www.googleapis.com/auth/calendar openid email profile"
                                    />
                                </div>
                            </Row>
                        )
                    }
                    {
                        !showGoogleBtn && (
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex justify-content-center align-items-center">

                                        <button className="btn btn-primary float-right mr-2" onClick={() => handleYesGoogleSync()}>Yes</button>

                                        <button className="btn btn-secondary float-right ms-2" onClick={() => handleNoGoogleSync()}>No</button>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                </ModalBody>
            </Modal>
    </React.Fragment>
  );
};

export default ClientDashboardNew;
