import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { ArticleService } from "../../../services/ArticleService";
import { SearchQuery, ViewArticleModel } from "../../../models/Article";
import ArticlePane from "./ArticlePane";
import InfiniteScroll from "react-infinite-scroll-component";
import UserContext from "../../../context/UserContext";
import SkeletonGroup3 from "../../../common/skeleton/SkeletonGroup3";
import ArticleContext from "../../../context/ArticleContext";
import { Util } from "../../../Util";
import PublicArticlePane from "./PublicArticlePane";

interface ArticleListProps {
  searchQuery?: SearchQuery;
}

const ArticleList: React.FC<ArticleListProps> = ({ searchQuery }: ArticleListProps) => {
  const [activeTab] = useState("1");
  const [articleDetails, setArticleDetails] = useState<ViewArticleModel[]>([]);
  const [searchedArticleDetails, setSearchedArticleDetails] = useState<ViewArticleModel[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(1);
  const [userDetails] = useContext(UserContext);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const LIMIT = 5;

  useEffect(() => {
    setSearchedArticleDetails([]);
    setOffset(1);
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery?.role == "CLIENT") {
      const query = {
        experienceTags: searchQuery.experienceTags,
        hashTags: searchQuery.hashTags,
      };

      searchPublicArticlesByTags(query, LIMIT, offset);
    }

    if (searchQuery?.role == "THERAPIST") {
      const query = {
        searchTags: searchQuery?.searchTags,
        type: searchQuery.type,
      };

      searchArticlesByTypeAndTags(query, LIMIT, offset);
    }

    getAllArticlesPublic(LIMIT, offset);
  }, [offset, searchQuery]);

  const getAllArticlesPublic = (limit: number, offset: number) => {
    setShowSkeleton(true);

    ArticleService.getAllArticlesPublic(limit, offset).then(res => {
      if (res.success) {
        setArticleDetails(previousDetails => {
          return [...new Set([...previousDetails, ...res.data.articleSet])];
        });

        setHasMore(res.data.count > 0);
      } else {
        setArticleDetails([]);

        setHasMore(false);
      }

      setShowSkeleton(false);
    });
  };

  const searchPublicArticlesByTags = (searchQuery: any, limit: number, offset: number) => {
    setShowSkeleton(true);
    ArticleService.searchPublicArticlesByTags(searchQuery, limit, offset).then(res => {
      if (res.success) {
        setSearchedArticleDetails(prevDetails => {
          return [...new Set([...prevDetails, ...res.data.articleSet])];
        });
        setHasMore(res.data.count > 0);
        setShowSkeleton(false);
      } else {
        setTimeout(() => {
          setShowSkeleton(false);
        }, 2000);

        setSearchedArticleDetails([]);
        setHasMore(false);
      }
    });
  };

  const searchArticlesByTypeAndTags = (searchQuery: any, limit: number, offset: number) => {
    setShowSkeleton(true);
    ArticleService.searchArticlesByTypeAndTags(searchQuery, limit, offset).then(res => {
      if (res.success) {
        setSearchedArticleDetails(prevDetails => {
          return [...new Set([...prevDetails, ...res.data.articleSet])];
        });
        setHasMore(res.data.count > 0);
        setShowSkeleton(false);
      } else {
        setTimeout(() => {
          setShowSkeleton(false);
        }, 2000);
        setSearchedArticleDetails([]);
        setHasMore(false);
      }
    });
  };

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <div>
                      <Row className="align-items-center">
                        <Col xs={4}>
                          <div>
                            <h1 className="mb-0 f-18">Article List</h1>
                          </div>
                        </Col>

                        <Col xs={8}>
                          <div className="float-end">
                            <ul className="nav nav-pills">
                              <NavItem>
                                <NavLink className="disabled" to="#" tabIndex={-1}>
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <Link to="/blogs/" className="nav-link active">
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link to="/public-articles-grid" className="nav-link">
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </Col>
                      </Row>

                      {searchedArticleDetails.length > 0 ? (
                        <div className="infinite-scroll">
                          <InfiniteScroll
                            dataLength={searchedArticleDetails.length}
                            next={() => {
                              setOffset(offset + 1);
                            }}
                            hasMore={hasMore}
                            loader={null}
                            className="infinite-scroll"
                          >
                            <>
                              <ArticleContext.Provider value={[searchedArticleDetails, setSearchedArticleDetails]}>
                                <PublicArticlePane userId={userDetails?._id} imageUrl={Util.fileURL(userDetails?.photoId?._id)} userData={userDetails} />
                              </ArticleContext.Provider>
                            </>
                          </InfiniteScroll>
                        </div>
                      ) : searchedArticleDetails.length == 0 && searchQuery ? (
                        showSkeleton ? (
                          <div className="mt-5">
                            <SkeletonGroup3 />
                          </div>
                        ) : (
                          <div>No articles to display.</div>
                        )
                      ) : articleDetails.length > 0 ? (
                        <div className="infinite-scroll">
                          <InfiniteScroll
                            dataLength={articleDetails.length}
                            next={() => {
                              setOffset(offset + 1);
                            }}
                            hasMore={hasMore}
                            loader={null}
                            className="infinite-scroll"
                          >
                            <>
                              <ArticleContext.Provider value={[articleDetails, setArticleDetails]}>
                                <PublicArticlePane userId={userDetails?._id} imageUrl={Util.fileURL(userDetails?.photoId?._id)} userData={userDetails} />
                              </ArticleContext.Provider>
                            </>
                            {showSkeleton ? (
                              <div className="mt-5">
                                <SkeletonGroup3 />
                              </div>
                            ) : (
                              <></>
                            )}
                          </InfiniteScroll>
                        </div>
                      ) : (
                        <>
                          {showSkeleton ? (
                            <div className="mt-5">
                              <SkeletonGroup3 />
                            </div>
                          ) : (
                            <div>No articles created yet!</div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tabId="2">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <h5>Archive</h5>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="me-2">
                          <h4>2020</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">03</span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> What is mental health?
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> Suicide prevention
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i>&nbsp; Psychotherapy, or talking therapies
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="me-2">
                          <h4>2019</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">06</span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> Make social connection a priority
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> How medication help your mental health
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> Suicide prevention
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i>&nbsp; Understanding good mental health
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i>&nbsp; Psychotherapy, or talking therapies
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> Make social connection a priority
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="me-2">
                          <h4>2018</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">03</span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> Risk factors for mental health conditions
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i>
                          Schizophrenia disorders
                        </Link>

                        <Link to="/blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium me-1"></i> Make social connection a priority
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ArticleList;
