import * as React from "react";
import { GroupChatModel } from "src/models/GroupChat/GroupChat";
import { MemberInSelectedChatGroupModel } from "src/models/GroupChat/MemberInSelectedChatGroup";
import { SessionsInSelectedChatGroupModel } from "src/models/GroupChat/SessionsInSelectedChatGroup";

const initialSelectedGroup: GroupChatModel = { _id: "", title: "", description: "", type: "", createdAt: "", createdBy: "" };

type GroupChatContextData = [
  selectedGroupData: GroupChatModel,
  setSelectedGroupData: (selectedChatGroup: GroupChatModel) => void,
  selectedGroupIdForShowMembers: string,
  setSelectedGroupIdForShowMembers: (groupId: string) => void,
  selectedGroupIdForShowRequests: string,
  setSelectedGroupIdForShowRequests: (groupId: string) => void,
  reloadSessionList: boolean,
  setReloadSessionList: (value: boolean) => void
];

const GroupChatContext = React.createContext<GroupChatContextData>([initialSelectedGroup, () => ({}), "", () => ({}), "", () => ({}), false, () => ({})]);

export default GroupChatContext;
