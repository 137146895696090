import React from "react";
import "../../assets/css/skeleton.css";

const SkeltonCompletedSession: React.FC = () => {
  return (
    <React.Fragment>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton mb-4 h81">
              <div className="col-sm-5 col-xl-5 col-5">
                <div className="flex flex-column align-items-start ">
                  <div className="line mt-1 h8 w100"></div>
                  <div className="line mt-1 h8 w100"></div>
                </div>
              </div>
              <div className="col-sm-7 col-xl-7 col-7">
                <div className="flex flex-row align-items-center justify-content-around ">
                  <div className="line mt-0 h25 w50"></div>
                  <div className="line mb-1 mt-0 h25 w25"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton mb-4 h81">
              <div className="col-sm-5 col-xl-5 col-5">
                <div className="flex flex-column align-items-start ">
                  <div className="line mt-1 h8 w100"></div>
                  <div className="line mt-1 h8 w100"></div>
                </div>
              </div>
              <div className="col-sm-7 col-xl-7 col-7">
                <div className="flex flex-row align-items-center justify-content-around ">
                  <div className="line mt-0 h25 w50"></div>
                  <div className="line mb-1 mt-0 h25 w25"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton mb-4 h81">
              <div className="col-sm-5 col-xl-5 col-5">
                <div className="flex flex-column align-items-start ">
                  <div className="line mt-1 h8 w100"></div>
                  <div className="line mt-1 h8 w100"></div>
                </div>
              </div>
              <div className="col-sm-7 col-xl-7 col-7">
                <div className="flex flex-row align-items-center justify-content-around ">
                  <div className="line mt-0 h25 w50"></div>
                  <div className="line mb-1 mt-0 h25 w25"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row max-w-100">
        <div className="col-sm-12 col-xl-12 col-12">
          <div className="box">
            <div className="skeleton mb-4 h81">
              <div className="col-sm-5 col-xl-5 col-5">
                <div className="flex flex-column align-items-start ">
                  <div className="line mt-1 h8 w100"></div>
                  <div className="line mt-1 h8 w100"></div>
                </div>
              </div>
              <div className="col-sm-7 col-xl-7 col-7">
                <div className="flex flex-row align-items-center justify-content-around ">
                  <div className="line mt-0 h25 w50"></div>
                  <div className="line mb-1 mt-0 h25 w25"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SkeltonCompletedSession;
