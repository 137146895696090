import moment from "moment";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col, Card, CardHeader, CardBody, Label, CardFooter, Progress, Input } from "reactstrap";
import Swal from "sweetalert2";
import UserContext from "../../../context/UserContext";
import { Goal } from '../../../models/Goal';
import { Role } from "../../../models/Role";
import { GoalService } from "../../../services/GoalService";
import { ParameterTypes } from "../../../utils/ParameterTypes";
import { DueDate } from '../../../models/subModel/DueDate';
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../../models/Notification";
import { NotificationService } from "../../../services/NotificationService";
import { SocketContext } from "../../../context/ScoketContext";
import { HStatus } from '../../../models/HomeWork';
import { Util } from "../../../Util";
import { sendNotificationSocketData } from "../../../models/sendNotificationCallSocketData";
import Spinner from "../../../common/spinner/spinner";

interface GProps {
    goals: Goal[];
    goal?: any;
    editModal?: any;
    refreshList?: any;
    activeTab?: any;
    seeMoreBtn?: any;
    seeMoreBtn2?: any
    goalCounts?: any;
    isLoadings?: any;
}

const Goals: React.FC<GProps> = ({
    goals,
    goal,
    editModal,
    refreshList,
    activeTab,
    seeMoreBtn,
    seeMoreBtn2,
    goalCounts,
    isLoadings
}) => {
    const { userId } = useParams<ParameterTypes>();
    const [goalDates, setGoalDates] = React.useState(false);
    const [goalDatesDrop, setGoalDatesDrop] = React.useState<any>();
    const [loggedUser] = useContext(UserContext);
    const socket = useContext(SocketContext);

    const checkIndexForDropDown = (index: number) => {
        if (goalDatesDrop === index)
            setGoalDates(!goalDates);
        else
            setGoalDates(true);
        setGoalDatesDrop(index);
    }

    const deleteGoal = (id: any | undefined) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
        }).then(result => {
            if (result.isConfirmed) {
                GoalService.deleteGoal(id).then(res => {
                    if (res.success) {
                        refreshList(res.success);
                        toast.error('Deleted!', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                        const goalNotification: NotificationModel = {
                            senderId: loggedUser?._id,
                            receiverId: userId,
                            event: NotificationEvent.GOAL_ASSIGNED,
                            link: "/chat/" + userId,
                            content: "Goal deleted by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                            variant: NotificationVarient.WARNING
                        }

                        NotificationService.sendNotification(goalNotification).then(res => {
                            const socketData: sendNotificationSocketData = {
                                socketId: res.data.socketId,
                                notifyData: goalNotification,
                                senderId: loggedUser?._id,
                                receiverId: userId,
                            };

                            socket.emit("send-notification", socketData);
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: res.error ? res.error : "Something went wrong. Please try again later.",
                            confirmButtonColor: "#FD7F00",
                        });
                    }
                });
            }
        });

    }

    const checkedCompletedDateAndUpdate = (goalId: any, i: number) => {
        const goal: Goal | undefined = goals.find(ob => ob._id === goalId);
        const completedDates: DueDate[] = goal?.completedDates || []
        completedDates[i].isComplete = !completedDates[i].isComplete;

        if (completedDates[i].isComplete && goal) {
            const divider: any = goal?.completedDates?.length || undefined;
            goal.prograss = (goal?.prograss + (100 / divider));
        }

        if (!completedDates[i].isComplete && goal) {
            const divider: any = goal?.completedDates?.length || undefined;
            goal.prograss = (goal?.prograss - (100 / divider));
        }

        GoalService.updateGoal(goal?._id as string, goal).then(res => {
            if (res.success) {
                toast.success(`Update Success!`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                refreshList(res.success);
                const goalNotification: NotificationModel = {
                    senderId: loggedUser?._id,
                    receiverId: userId,
                    event: NotificationEvent.GOAL_UPDATED,
                    link: "/chat/" + userId,
                    content: "Goal's due date is updated by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                    variant: NotificationVarient.INFO
                }

                NotificationService.sendNotification(goalNotification).then(res => {
                    const socketData: sendNotificationSocketData = {
                        socketId: res.data.socketId,
                        notifyData: goalNotification,
                        senderId: loggedUser?._id,
                        receiverId: userId,
                    };

                    socket.emit("send-notification", socketData);
                });
            } else {
                toast.error(res.error ? res.error : 'Server error occurred!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    }

    const getGoalById = (id: any) => {
        GoalService.getGoalById(id).then(res => {
            if (res.success) {
                goal(res.data);
                editModal(true);
            }
        })
    }

    const numberRound = (num: number, decimalPlaces = 0) => {
        const p = Math.pow(10, decimalPlaces);
        return Math.round(num * p) / p;
    }

    const complteGoal = (goal: Goal) => {
        const goalUpdate = goal;
        const isValid = goal.completedDates.some((obj: DueDate) => !obj.isComplete)
        if (isValid) {
            toast.error('Complete your day to day task before finished!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } else {
            goalUpdate.isComplete = true;

            GoalService.updateGoal(goalUpdate?._id as string, goalUpdate).then(res => {
                if (res.success) {
                    toast.success(`Goal successfully archived!`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    refreshList(res.success);
                    const goalNotification: NotificationModel = {
                        senderId: loggedUser?._id,
                        receiverId: userId,
                        event: NotificationEvent.GOAL_COMPLETED,
                        link: "/chat/" + userId,
                        content: "Assigned Goal completed by " + loggedUser?.firstname + ' ' + loggedUser?.lastname,
                        variant: NotificationVarient.INFO
                    }

                    NotificationService.sendNotification(goalNotification).then(res => {
                        const socketData: sendNotificationSocketData = {
                            socketId: res.data.socketId,
                            notifyData: goalNotification,
                            senderId: loggedUser?._id,
                            receiverId: userId,
                        };

                        socket.emit("send-notification", socketData);
                    });
                } else {
                    toast.error(res.error ? res.error : 'Server error occurred!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            });
        }
    }


    return (
        <React.Fragment>
            <>
                {
                    goals.length > 0 ? (
                        <Row >
                            {goals.map((goal: Goal, index: number) => {
                                return (
                                    <Col lg={`${loggedUser?.role == Role.CLIENT && 3} ${loggedUser?.role == Role.THERAPIST && 12}`} key={index}>
                                        <Card>
                                            <CardHeader>
                                                <h6>{goal.title}</h6>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="description">
                                                    <p>{goal.description}</p>
                                                </div>
                                                <hr />
                                                <div className="d-flex progress-title">
                                                    <Label className="t-grey">PROGRESS</Label>
                                                    <p>{numberRound(goal.prograss)}%</p>
                                                </div>
                                                <div className="goal-progressbar">
                                                    <Progress value={goal.prograss} />
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className="goal-footer-item">
                                                    <div className="goal-date">
                                                        <p className="goal-footer"><span>DUE DATE:</span>&nbsp;{moment(goal.dueDate).format('LL')}</p>
                                                        <ul>
                                                            {
                                                                goal.completedDates.map((obj: any, i: number) => {
                                                                    return (
                                                                        <li key={i}><i className={`bx bxs-circle ${obj.isComplete ? ' green-38a ' : ' t-grey '}`}></i></li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <button className="btn-tick" onClick={() => { checkIndexForDropDown(index) }}><i className={`bx ${(goalDatesDrop == index && goalDates) ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'} `}></i></button>
                                                </div>
                                                {
                                                    (goalDatesDrop == index && goalDates) ? (
                                                        <div className="row">
                                                            {
                                                                goal.completedDates.map((obj: any, i: number) => {
                                                                    return (
                                                                        <div className="col-md-6 d-flex goal-due-date mb-2" key={i}>
                                                                            <Input type="checkbox" className="lg" disabled={goal.isComplete} defaultChecked={obj.isComplete} onClick={(e: any) => checkedCompletedDateAndUpdate(goal._id, i)} /> &nbsp;
                                                                            <Label check className="ml-2">
                                                                                {moment(obj.date).format('LL')}
                                                                            </Label>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    ) : null
                                                }
                                            </CardFooter>
                                            <CardBody>
                                                <div className="d-flex bottom-goal-btn-group">
                                                    <div className="left-btn d-flex">
                                                        <button className="rounded-btn close-btn " onClick={() => deleteGoal(goal?._id)}><i className='bx bx-x'></i></button> &nbsp;&nbsp;
                                                        <button className="rounded-btn edit-btn" onClick={() => { getGoalById(goal?._id); activeTab('1') }}><i className='bx bxs-pencil' ></i></button>
                                                    </div>

                                                    <div className="right-btn d-flex">
                                                        <span className="staus-bg mr-2" style={{ backgroundColor: `${Util.statusColor(goal.isComplete ? HStatus.COMPLETED : HStatus.ONGOING)}`, color: `${Util.statusFontColor(goal.isComplete ? HStatus.COMPLETED : HStatus.ONGOING)}` }}>{goal.isComplete ? HStatus.COMPLETED : HStatus.ONGOING}</span>
                                                        <button className={`rounded-btn ${goal.isComplete ? 'footer-active-btn2' : 'complete-btn '}`} onClick={() => complteGoal(goal)} disabled={goal.isComplete}><i className='bx bx-check'></i></button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })
                            }

                            {loggedUser?.role == Role.CLIENT &&
                                goalCounts >= 8 && (
                                    <>
                                        <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMoreBtn}>
                                            See More
                                        </button>
                                    </>
                                )
                            }

                            {loggedUser?.role == Role.THERAPIST &&
                                goalCounts >= 8 && (
                                    <>
                                        <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMoreBtn2}>
                                            See More
                                        </button>
                                    </>
                                )
                            }
                        </Row>
                    ) : (
                        <>
                            {isLoadings ? (
                                <Spinner className="bouncer2" />
                            ) : (
                                <div className="text-reset notification-item no-notifications">
                                    Goal not found !
                                </div>
                            )
                            }
                        </>
                    )
                }

            </>

        </React.Fragment>
    )
}

export default Goals;