import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class AdminClientChatService {
  public static async sendTwilioMessage(formData: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`clientchat/sendTwilioMessage`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, formData);
  }

  public static async getTwilioMessageHistoryWithClient(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`clientchat/twilioMessageHistory`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
}
