import React, {useContext} from 'react';
import styles from './styles';
import { Typography } from '@material-ui/core';

import ContactsIcon from '@material-ui/icons/Contacts';
import PropTypes from 'prop-types';
import UserContext from "src/context/UserContext";
import LocalCallContext from "src/context/LocalCallContext";

const ChatMessages = ({ chatMessages, chatClass }) => {
  const messagesEndRef = React.useRef(null);
  const [loggedUser] = useContext(UserContext);
  const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);

  React.useEffect(() => {
    scrollToLastMessage();
    console.log(loggedUser);
    console.log(localUserOnCall);
    console.log(chatMessages);
  }, [chatMessages]);

  const scrollToLastMessage = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const classes = styles();
  return (
    <div className={chatClass}>
      {chatMessages && chatMessages.length > 0 ? (
        chatMessages.map((msg, key) => {
          return (
            <div
              ref={messagesEndRef}
              className={`${classes.messageContainer} ${
                msg?.from?.name ? '' : classes.myMessage
              }`}
              key={key}
            >
              <div className={classes.chatAvatar}>
                <ContactsIcon className={classes.iconChat} />
                <Typography color="textSecondary" variant="subtitle1">
                  {msg?.from?.isMe ? `Me:` : `${localUserOnCall.recieversName}:`}
                  {/* <span className={classes.time}>{msg.date}</span> */}
                </Typography>
                <Typography
                  className={classes.time}
                  color="textSecondary"
                  variant="subtitle1"
                >
                  {msg.date}
                </Typography>
              </div>
              <div className={classes.chatContent}>
                {/* <ChatIcon className={classes.iconChat} /> */}
                <Typography color="textPrimary" variant="body1">
                  {msg.data}
                </Typography>
              </div>
            </div>
          );
        })
      ) : (
        <div>There are no messages</div>
      )}
    </div>
  );
};
ChatMessages.propTypes = {
  chatMessages: PropTypes.array,
  chatClass: PropTypes.string,
};
export default ChatMessages;
