import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import "./../../assets/css/onboarding.css";
import "./../../assets/css/publicMedia.css";
import CoverImage from "./../../assets/images/icons/sharing box redraw.png";
import { TherapistService } from "src/services/TherapistService";
import UsersIcon from "./../../assets/images/icons/fi_users.svg";
import ReferralBonusIcon from "./../../assets/images/icons/fi_referral_bonus.svg";
import ReferralCompletionBonusIcon from "./../../assets/images/icons/referral_completed_bonus.svg";
import NaviagtionIcon from "./../../assets/images/icons/fi_navigation.svg";
import GiftIcon from "./../../assets/images/icons/fi_gift (1).svg";
import ListIcon from "./../../assets/images/icons/referal_nav.png";
import Close from "./../../assets/images/icons/fi_x-circle.png";
import Cover from "./../../assets/images/icons/modal cover.svg";
import Coppy from "./../../assets/images/icons/fi_copy.png";
import Share from "./../../assets/images/icons/fi_share-2.png";
import Email from "./../../assets/images/icons/fi_mail.png";
import Message from "./../../assets/images/icons/message.png";
import { Input, Modal } from "reactstrap";
import { environment } from "src/environment/environment";
import PhoneInput from "react-phone-number-input";
import UserContext from "src/context/UserContext";
import TextArea from "antd/lib/input/TextArea";

const Main: React.FC = () => {
  const [togleModalReferal, setTogleModalReferal] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [url, setUrl] = useState("");
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [referalTherapistId, setTreferalTherapistId] = useState(user?._id);
  const [messageContent,setMessageContent] = useState('')

  useEffect(() => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-therapist-share/" + referalTherapistId;
    setUrl(userPublicUrl);
    const smsContent = `Hey There!\n\nHope you're doing well. This is ${user?.firstname} ${user?.lastname} I wanted to share something valuable with you I've recently started using Lavni, a fantastic mental health platform for therapists. \n\nThey're offering a special $50 bonus for new joiners, and I thought you might be interested! It's a great platform for running solo or group therapy practices, and it's completely free. \n\nClick this link to get started:\n\n ${userPublicUrl}.\n\nTake care!`;
    setMessageContent(smsContent)
  }, []);

  const referalModal = () => {
    setTogleModalReferal(!togleModalReferal);
  };

  const handleCopyURL = () => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-therapist-share/" + referalTherapistId;
    navigator.clipboard
      .writeText(userPublicUrl)
      .then(() => {
        toast.success("Link copied to clipboard! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      })
      .catch(() => {
        toast.error("Link is not copied try again! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
  };

  const handleShare = () => {
    setShowSharePopup(!showSharePopup);
  };

  const sendSMS = () => {
    const data = { messageContent, phoneNumber };
    const phoneno = /^\+1[0-9]{10}$/;

    if (!messageContent) {
      toast.error(`Please enter your message!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      if (!phoneNumber.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        TherapistService.sendReferrealMessage(data).then(res => {
          if (res.success) {
            toast.success(`You have sent SMS successfully!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setPhoneNumber("");
            setShowSharePopup(!showSharePopup);
          } else {
            toast.error(` SMS sending failed!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setPhoneNumber("");
          }
        });
      }
    }
  };
  const sendEmail = () => {
    const data = { messageContent, emailAddress };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!messageContent) {
      toast.error(`Please enter your message!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      if (!emailAddress.match(emailRegex)) {
        toast.error("Please enter valid Email Address", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        TherapistService.sendReferrealEmail(data).then(res => {
          if (res.success) {
            toast.success(`You have sent email successfully!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setEmailAddress("");
            setShowSharePopup(!showSharePopup);
          } else {
            toast.error(` Email sending failed!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setEmailAddress("");
          }
        });
      }
    }
  };
  return (
    <div>
      <div className="content-wrapper mt-refferal-page">
        <section className="bg-refferal ">
          <div className="container ">
            <h1 className="refferal-title text-center text-white-referral pt-4 pt-md-12 mt-referral-title">Welcome to Lavni Therapist Referral Program</h1>
            <div className="d-flex align items-center flex-column">
              <button className="btn btn-primary mt-4 referral-btn-d fs-18" onClick={referalModal}>
                Send a refferal <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
              <img src={CoverImage} className="max-100-referral cover-img-referral" />
            </div>

            <div className="row mb-8 mb-md-12">
              <div className="col-md-4  d-flex flex-column align-items-md-start align-items-center">
                <img src={UsersIcon} />
                <p className="text-white-referral fs-20 mt-2">Step 1:</p>
                <p className="text-white-referral text-md-start text-center fs-15">
                  Send your referral link to a great therapist.
                </p>
              </div>
              <div className="col-md-4  d-flex flex-column align-items-md-start align-items-center">
                <img src={NaviagtionIcon} />
                <p className="text-white-referral fs-20 mt-2">Step 2:</p>
                <p className="text-white-referral text-md-start text-center fs-15">
                  When the therapist you referred signs up, fully credentialed, and finishes four sessions, you will receive a $50 cash bonus which will be paid to you via direct deposit on the next payment cycle.
                </p>
              </div>
              <div className="col-md-4  d-flex flex-column align-items-md-start align-items-center">
                <img src={GiftIcon} />
                <p className="text-white-referral fs-20 mt-2">Step 3:</p>
                <p className="text-white-referral text-md-start text-center fs-15">
                  After the therapist you referred completes 20 sessions on Lavni, you get another $50 cash bonus.
                </p>
              </div>
            </div>

            <br/>
          </div>
        </section>

        <section className="">
          <div className="container pt-4 pt-md-12">
            <h1 className="text-black-referral text-center mb-3">Rewards</h1>
            <div className=" mt-4">
              <div className="row justify-content-center">
                <div className="col-md-2"></div>
                <div className="col-md-4 d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <img src={ReferralBonusIcon} alt="Referral Bonus Icon" />
                    <h4 className="text-white-referral text-center mt-3 mb-1">Referral Bonus</h4>
                    <p className="text-white-referral text-center fs-15">$50 cash when the referred therapist signs up, joins Lavni and finishes four sessions.</p>
                  </div>
                </div>
                <div className="col-md-4  d-flex justify-center">
                  <div className="referral-item-card d-flex flex-column align-items-center mb-4 p-5">
                    <img src={ReferralCompletionBonusIcon} alt="Session Completion Bonus Icon" />
                    <h4 className="text-white-referral text-center mt-3 mb-1">Session Completion Bonus</h4>
                    <p className="text-white-referral text-center fs-15">
                      An additional $50 cash after the referred therapist completes 20 sessions on the Lavni platform.
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="m-terms-referral">
          <div className="container">
            <h1 className="text-black-referral text-center mb-3">Terms and Conditions</h1>
            <div className="d-flex align-items-center mb-2 mb-md-4">
              <img src={ListIcon} className="me-3 me-md-5" />
              <p className="mb-0 text-black-referral fs-15">
                To qualify for the bonuses, the referred therapist must successfully join Lavni and complete the specified number of sessions.
              </p>
            </div>
            <div className="d-flex align-items-center mb-2 mb-md-4">
              <img src={ListIcon} className="me-3 me-md-5" />
              <p className="mb-0 text-black-referral fs-15">Cash bonuses will be processed and transferred after the completion of each milestone.</p>
            </div>
            <div className="d-flex align-items-center mb-2 mb-md-4">
              <img src={ListIcon} className="me-3 me-md-5" />
              <p className="mb-0 text-black-referral fs-15">Lavni reserves the right to modify or terminate the referral program at any time.</p>
            </div>
          </div>
        </section>

        <section className="bg-refferal ">
          <div className="container pt-pb-join-container-referral">
            <h1 className="text-white-referral text-center mb-3">
              The more therapists you refer, the more you earn! Join
              <br /> us in building a thriving community on Lavni.
            </h1>
            <div className="d-flex align items-center flex-column">
              <button className="btn btn-primary mt-4 referral-btn-d fs-18" onClick={referalModal}>
                Send a refferal <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={togleModalReferal} toggle={referalModal} centered className="modal-lg">
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}>
          <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "2vh" }}>
            <div className="image-container2">
              <img src={Cover} className="meeting-ended-image-size" alt="Meeting Cover" />

              <div className="d-flex justify-content-center pt-5 referal-link-btn">
                <input type="url" value={url} className="form-control form-control-sm mr-2" placeholder="Enter URL" />
                <button className="btn btn-primary referal-option-btn mr-2" onClick={handleCopyURL}>
                  <img src={Coppy} alt="coppy" />
                </button>
                <button className="btn btn-primary referal-option-btn" onClick={handleShare}>
                  
                  <img src={Share} alt="share" />
                </button>
              </div>

              <div className="button-container btn-container-position-top">
                <button type="button" onClick={referalModal} className="bg-transparent-color" data-dismiss="modal" aria-label="Close">
                  <img src={Close} alt="Close" className="" />
                </button>
              </div>
            </div>
          </div>
          {showSharePopup && (
            <>
              <div className="d-flex  py-2 referal-link-btn row">
                <div className="d-flex  col-12 justify-content-md-end justify-content-center">
                  <TextArea
                    value={messageContent}
                    onChange={e => setMessageContent(e.target.value)}
                    placeholder="Enter Your Message"
                    required
                    className=""
                    rows={13}
                    maxLength={600}
                  />
                </div>
              </div>
              <div className="d-flex  py-2 referal-link-btn row mb-4">
                <div className="d-flex justify-content-center align-items-center col-md-6 col-12 mb-2 mb-md-0">
                  <PhoneInput
                    value={phoneNumber}
                    placeholder="Enter phone number"
                    className="mr-2 sms-referral-input-mob"
                    defaultCountry="US"
                    onChange={(value: string) => setPhoneNumber(value)}
                  />
                  <button className="btn btn-primary d-flex justify-content-center align-items-center btn-sm via-message-btn" onClick={sendSMS}>
                    <span className="d-none d-md-block">Via Message</span>
                    <img src={Message} className="mx-2" />
                  </button>
                </div>

                <div className="d-flex col-md-6 col-12 justify-content-md-end justify-content-center">
                  <Input
                    type="email"
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    placeholder="Enter Email Address"
                    required
                    className="email-referral-input mr-2"
                  />
                  <button className="btn btn-primary d-flex justify-content-center align-items-center btn-sm" onClick={sendEmail}>
                    <span className="d-none d-md-block">Via Email</span>
                    <img src={Email} alt="email-icon" className="mx-2" />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Main;
