import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import ReplySection from "./ArticleReply";
import userImage from "../../assets/images/avatar.jpeg";
import { Util } from "../../Util";

interface CommentProps {
  firstname: string;
  lastname: string;
  imageUrl: any;
  date: string;
  comment: string;
  replies: any[];
  commentId: string;
  articleId: any;
  addReply: (
    articleId: string,
    commentId: string,
    reply: string,
    setReply: any
  ) => void;
}

const CommentSection: React.FC<CommentProps> = ({
  firstname,
  lastname,
  imageUrl,
  date,
  comment,
  replies,
  commentId,
  articleId,
  addReply,
}: CommentProps) => {
  const [userDetails] = useContext(UserContext);
  const [showReply, setShowReply] = useState(false);
  const [reply, setReply] = useState("");

  const handleReply = (
    event: any,
    articleId: string,
    commentId: string,
    reply: string,
    setReply: any
  ) => {
    if (event.keyCode == 13) {
      addReply(articleId, commentId, reply, setReply);
      setReply("");
    }
  };

  return (
    <div className="d-flex py-3 border-top">
      <div className="avatar-xs me-3">
        {imageUrl == null ? (
          <img
            src={userImage}
            className="img-fluid d-block rounded-circle"
            style={{ height: "2rem" }}
          />
        ) : (
          <img
            src={imageUrl}
            className="img-fluid d-block rounded-circle"
            style={{ height: "2rem" }}
          />
        )}
      </div>
      <div className="flex-grow-1">
        <h5 className="font-size-14 mb-1">
          {firstname == "CLIENT" || firstname == "THERAPIST"
            ? "#" + firstname
            : firstname}
          {lastname == undefined ? "" : lastname}
          <small className="text-muted float-end">{date}</small>
        </h5>
        <p className="text-muted">{comment}</p>
        <div
          className="text-success pointer1"
          onClick={() => setShowReply(!showReply)}
        >
          <i className="mdi mdi-reply"></i> Reply
        </div>
        {replies
          //.sort((a, b) => (a.date > b.date ? -1 : 1))
          .map((item: any, index: number) => {
            return (
              <ReplySection
                firstname={
                  item.userId.firstname == null ||
                  item.userId.firstname == undefined ||
                  item.userId.firstname == ""
                    ? item.userId.role
                    : item.userId.firstname
                }
                lastname={
                  item.userId.lastname == null ||
                  item.userId.lastname == undefined ||
                  item.userId.lastname == ""
                    ? undefined
                    : item.userId.lastname
                }
                date={item.date}
                imageUrl={
                  item.userId.photoId === null ? null :
                  Util.fileURL(item.userId.photoId?._id)
                }
                reply={item.reply}
                key={index}
              />
            );
          })}
        {showReply ? (
          <div>
            <div className="d-flex py-3">
              <div className="avatar-xs me-3">
                <img src={ Util.fileURL(userDetails?.photoId?._id) }
                  className="img-fluid d-block rounded-circle"
                  style={{ height: "2rem" }}
                />
              </div>
              <div
                className="flex justify-content-between w-100"
                style={{ flexDirection: "row" }}
              >
                <div className="px-2 w-100">
                  <input
                    type="text"
                    className="form-control shadow-sm bg-white rounded"
                    placeholder="Add a comment..."
                    name="reply"
                    value={reply}
                    onChange={event => {
                      setReply(event.target.value);
                    }}
                    onKeyDown={event => {
                      handleReply(event, articleId, commentId, reply, setReply);
                    }}
                  />
                </div>
                <button
                  className="btn btn-primary px-2"
                  onClick={() => {
                    addReply(articleId, commentId, reply, setReply);
                  }}
                >
                  Reply
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CommentSection;
