import React, { useCallback, useEffect, useRef, useState } from 'react';
import OT from '@opentok/client';
// import './openTokVideoChat.css';
import { IoMdExit } from "react-icons/io";
import { BsRecordCircle } from "react-icons/bs";
import { BsRecordCircleFill } from "react-icons/bs";
import { useHistory } from 'react-router-dom';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideoCam from "@mui/icons-material/Videocam";
import VideocamOff from "@mui/icons-material/VideocamOff";
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { VonageServices } from '../../vonageServices';
import { environment } from 'src/environment/environment'
import { VonageNativeSdkService } from 'src/services/VonageNativeSdkService';
import styles from './openTokVideoChat2.module.css';
import { Role } from 'src/models/Role';

interface VideoChatProps {
  apiKey?: string;
  sessionId?: string;
  token?: string;
  serverBaseUrl?: string;
}

interface CaptionsData {
  id: string;
}

interface ArchiveData {
  id: string;
  status: string;
  error?: string;
}

interface SessionResponse {
  apiKey: string;
  sessionId: string;
  token: string;
}

interface OTError {
  message: string;
  code?: number;
  name?: string;
}

interface Transcription {
  text: string;
  timestamp: Date;
}

interface ConversationHistory {
  transcriptions: Transcription[];
}

const VideoChat: React.FC<VideoChatProps> = () => {
  const serverBaseUrl = process.env.REACT_APP_API_URL;
  const appRunningUrl = process.env.REACT_APP_URL;
  const [session, setSession] = useState<OT.Session | null>(null);
  const [publisher, setPublisher] = useState<OT.Publisher | null>(null);
  const [subscriber, setSubscriber] = useState<OT.Subscriber | null>(null);
  const [captions, setCaptions] = useState<CaptionsData | null>(null);
  const [archive, setArchive] = useState<ArchiveData | null>(null);
  const [showWaitingMsg, setShowWaitingMsg] = useState(true);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isCaptionStarted, setIsCaptionStarted] = useState(false);
  const [isArchiveStarted, setIsArchiveStarted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const captionsRemovalTimerRef = useRef<NodeJS.Timeout>();
  const connectionsRef = useRef(new Set<string>());
  const conversationHistoryRef = useRef<ConversationHistory>({
    transcriptions: [],
  });
  const history = useHistory();
  const publisherRef = useRef<HTMLDivElement>(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [screenSharePublisher, setScreenSharePublisher] = useState<OT.Publisher | null>(null);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);
  const { uniqueId, appointmentId } = useParams<{ uniqueId: string, appointmentId: string }>();
  const otherUserRole = useRef<string | null>(null);
  const loggedUserRole = useRef<string | null>(null);
  const [isDisableLeaveButton, setIsDisableLeaveButton] = useState<boolean>(false)

  useEffect(() => {
    console.log("uniqueId ", uniqueId);
    
    const disableBackButton = (event: any) => {
      event.preventDefault();
      window.history.forward();
    };

    // Disable the back button
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);

  useEffect(() => {
  
    const handleUnload = () => {
      removeCallContextFromlocalStorage();
    };
  
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  useEffect(() => {
    const localCallData = localStorage.getItem('callContext')
    let callContext = null;
    if (localCallData) {
      try {
        callContext = JSON.parse(localCallData);
        console.log("callContext ", callContext);
        console.log("Role ", Role);
        
        const role = callContext.loggedUserRole === Role.THERAPIST 
          ? Role.CLIENT 
          : Role.THERAPIST;
          
        otherUserRole.current = role;
        loggedUserRole.current = callContext.loggedUserRole === Role.THERAPIST 
          ? Role.THERAPIST 
          : Role.CLIENT;
      } catch (error) {
        console.error("Error parsing callContext from localStorage:", error);
        callContext = null;
      }
    } else {
      otherUserRole.current = Role.THERAPIST;
      loggedUserRole.current = Role.CLIENT;
    }
  }, [])

  useEffect(() => {
    if (loggedUserRole.current === Role.THERAPIST){
      startCaptions();
    }
  }, [sessionId, token]) 

  const removeCallContextFromlocalStorage = () => {
    console.log("XXX");
    localStorage.removeItem('callContext');
  }

  const handleError = (error: OTError | undefined) => {
    if (error) {
      const errorMessage = error.message || 'An unknown error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);

      // Handle specific OpenTok error codes
      if (error.code) {
        switch (error.code) {
          case 1004:
            console.error('Authentication error. Check your credentials.');
            break;
          case 1005:
            console.error('Invalid session ID.');
            break;
          case 1006:
            console.error('Connect failed.');
            break;
          case 1011:
            console.error('Invalid parameter values.');
            break;
          case 1013:
            console.error('No stream found.');
            break;
          case 1500:
            console.error('Unable to connect to the server.');
            break;
          default:
            console.error(`OpenTok error: ${error.code}`);
        }
      }

      // Attempt recovery based on error type
      if (error.name === 'OT_MEDIA_ERR_DEVICE_NOT_FOUND') {
        console.error('No camera/microphone found. Please check your devices.');
      } else if (error.name === 'OT_AUTHENTICATION_ERROR') {
        // Attempt to refresh credentials
        refreshSession();
      }
    }
  };

  const addTranscription = (text: string) => {
    console.log("otherUserRole ", otherUserRole.current);
    
    if (otherUserRole.current){
      const newTranscription = {
        text : `${otherUserRole.current}: ${text}`,
        timestamp: new Date(),
      };
  
      conversationHistoryRef.current = {
        transcriptions: [
          ...conversationHistoryRef.current.transcriptions,
          newTranscription
        ]
      }
    }
  };

  const refreshSession = async () => {
    console.log("refreshSession");
    
    try {
      const sessionData: any = await VonageServices.createVonageSession(uniqueId);
      console.log(sessionData);
      
      if (sessionData) {
        setApiKey(sessionData.apiKey);
        setSessionId(sessionData.sessionId);
        setToken(sessionData?.token,);
        initializeSession(sessionData.apiKey, sessionData.sessionId, sessionData?.token,);
      }
      // const response = await fetch(`${serverBaseUrl}/session`);
      // if (!response.ok) {
      //   throw new Error('Failed to refresh session');
      // }
      // const json: SessionResponse = await response.json();      
      // setApiKey(json.apiKey);
      // setSessionId(json.sessionId);
      // setToken(json.token);
      // initializeSession(json.apiKey, json.sessionId, json.token);
    } catch (error) {
      handleError(error as OTError);
    }
  };

  const postData = async (url: string, data: object = {}) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      handleError(error as OTError);
      throw error; // Re-throw to handle in calling function
    }
  };

  const startCaptions = async () => {
    try {
      if (!sessionId || !token) {
        return;
      }
      console.log("Start caption function called");
      
      const dataForStartCaptions = {
        sessionId: sessionId,
        token: token,
      }
      const captionsData: any = await VonageNativeSdkService.startCaptions(dataForStartCaptions);
      console.log("captionsData ", captionsData);
      if(captionsData.success){
        const startArchieveData = await VonageNativeSdkService.startArchiveVonageNative(sessionId);
        console.log("startArchieveData ", startArchieveData);
        
      }
      
      // setCaptions(captionsData);
      setIsCaptionStarted(true);
    } catch (error) {
      handleError(error as OTError);
      setIsCaptionStarted(false);
    }
  };

  const stopCaptions = async () => {
    if (!captions) return;
    try {
      await postData(`${serverBaseUrl}/captions/${captions.id}/stop`, {});
      setCaptions(null);
      setIsCaptionStarted(false);
    } catch (error) {
      handleError(error as OTError);
      // Keep current state if stop operation fails
    }
  };

  // const disconnectSession = (ses: OT.Session) => {
  //   try {
  //     if (ses) {
  //       ses.disconnect();
  //       console.log(conversationHistory);
        
  //       console.log('Disconnected from the session');
  //     }
  //   } catch (error) {
  //     handleError(error as OTError);
  //   }
  // };

  const disconnectSession = async (ses: OT.Session) => {
    console.log("disconnectSession");
    
    try {
      setIsDisableLeaveButton(true);
      const data = await VonageNativeSdkService.updateTranscribes(uniqueId, conversationHistoryRef.current.transcriptions, loggedUserRole.current);
      if (loggedUserRole.current === 'THERAPIST'){
        const stopArchiveRes = await VonageNativeSdkService.stopArchiveVonageNative(uniqueId);
        console.log("stopArchiveRes", stopArchiveRes);
      }
      console.log(data);
      setIsDisableLeaveButton(false);
      if (ses) {
        ses.disconnect();
        console.log('Disconnected from the session');
        history.push(`/session/end-room`);
      } 
      if (publisher) {
        publisher.destroy(); // Stop camera & microphone access
      }
    } catch (error) {
      handleError(error as Error | OTError);
      setIsDisableLeaveButton(false);
    }
  };

  const handleLeave = async () => {
    try {
      setIsDisableLeaveButton(true);
      const cancelCallRes: any = await VonageNativeSdkService.cancelVonageNativeCall(uniqueId);
      console.log(cancelCallRes);
      
      if (cancelCallRes.success) {
        if(cancelCallRes.data.participantCount == 2){
          if (session) {
            session.signal({
              type: 'msg',
              data: 'leavedFromCall',
            }, (error) => {
              if (error) {
                handleError(error as OTError);
              }
            });
          }
        } else {
          if (session) {
            disconnectSession(session);
          }
        }
        
      } else {
        if (session) {
          disconnectSession(session);
        }
      }
    } catch (error) {
      handleError(error as OTError);
      setIsDisableLeaveButton(false);
      // Attempt forced disconnect on error
      if (session) {
        disconnectSession(session);
      }
    }
  };

  const initializeSession = (apiKey: any, sessionId: any, token: any) => {
    try {
      if (!apiKey || !sessionId || !token) {
        throw new Error('Missing required credentials');
      }
      const newSession = OT.initSession(apiKey, sessionId);

      OT.on("exception", function(event) {
        console.error('OpenTok Exception:', event.message);
        handleError(new Error(event.message));
      });

      newSession.on('connectionCreated', (event) => {
        try {
          if (event.connection.connectionId !== newSession.connection?.connectionId) {
            connectionsRef.current.add(event.connection.connectionId);
            setShowWaitingMsg(false);
          }
        } catch (error) {
          handleError(error as OTError);
        }
      });

      newSession.on('connectionDestroyed', (event) => {
        try {
          connectionsRef.current.delete(event.connection.connectionId);
          setShowWaitingMsg(true);
        } catch (error) {
          handleError(error as OTError);
        }
      });

      newSession.on('streamCreated', async (event) => {
        try {
          
          const subscriberOptions: any = {
            insertMode: 'append',
            width: '100%',
            height: '100%',
          };

          newSession.on('signal:msg', (event: any) => {
            if (event.data === 'leavedFromCall') {
              console.log("Leave from call signal triggered");
              disconnectSession(newSession);
            }
          });

          let parentElementId = 'subscriber';
          if (event.stream.videoType === 'screen'){
            parentElementId = 'screen-share-subscriberVideo';
            setIsScreenShareOn(true);
          }
          const newSubscriber = newSession.subscribe(
            event.stream,
            parentElementId,
            subscriberOptions,
            handleError
          );
          setSubscriber(newSubscriber);

          try {
            await newSubscriber.subscribeToCaptions(true);
          } catch (err) {
            console.warn('Caption subscription error:', err);
          }

          newSubscriber.on('captionReceived', (captionEvent) => {
            try {
              
              const subscriberContainer = document.querySelector('.OT_subscriber');
              if (!subscriberContainer) return;

              const oldCaptionBox = subscriberContainer.querySelector('.caption-box');
              if (oldCaptionBox) oldCaptionBox.remove();

              const captionBox = document.createElement('div');
              captionBox.classList.add('caption-box');
              captionBox.textContent = captionEvent.caption;
              shouldAddCaption(captionEvent.caption, captionEvent.caption)
              clearTimeout(captionsRemovalTimerRef.current);
              captionsRemovalTimerRef.current = setTimeout(() => {
                captionBox.textContent = '';
              }, 5000);

              subscriberContainer.appendChild(captionBox);
            } catch (error) {
              handleError(error as OTError);
            }
          });
        } catch (error) {
          handleError(error as OTError);
        }
      });

      // newSession.on('streamDestroyed', (event) => {
      //   console.log("streamDestroyed ", event);
      //   if(event.stream.videoType === 'screen') {
      //     setIsScreenShareOn(false);
      //   }
      //   setSubscriber(null);
      // });

      const publisherOptions: any = {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        publishCaptions: true,
        publishVideo: false,
        publishAudio: false 
      };

      const newPublisher = OT.initPublisher('publisher', publisherOptions, handleError);
      setPublisher(newPublisher);

      newSession.connect(token, (error) => {
        if (error) {
          handleError(error);
        } else {
          newSession.publish(newPublisher, handleError);
          setSession(newSession);
        }
      });
    } catch (error) {
      handleError(error as OTError);
    }
  };

  let lastCaption = '';

  const shouldAddCaption = (newCaption: string, id: string) => {
    const cleanNewCaption = newCaption.trim().toLowerCase();
    const cleanLastCaption = lastCaption.trim().toLowerCase();
  
    if (!cleanNewCaption || cleanNewCaption === cleanLastCaption) {
      return false;
    }

    const isExpansion = cleanNewCaption.includes(cleanLastCaption);
    if(!isExpansion){
      addTranscription(lastCaption);
    }
    lastCaption = newCaption;
    return !isExpansion;
  };

  useEffect(() => {
    const initSession = async () => {
      try {
        if (process.env.API_KEY && process.env.TOKEN && process.env.SESSION_ID) {
          setApiKey(process.env.API_KEY);
          setSessionId(process.env.SESSION_ID);
          setToken(process.env.TOKEN);
          initializeSession(process.env.API_KEY, process.env.SESSION_ID, process.env.TOKEN);
        } else {
          const sessionData: any = await VonageNativeSdkService.createVonageSession(uniqueId);
          if (sessionData) {
            console.log(sessionData);
            
            setApiKey(sessionData.apiKey);
            setSessionId(sessionData.sessionId);
            setToken(sessionData?.token,);
            await VonageNativeSdkService.updateSessionIdInMeeting(uniqueId, sessionData.sessionId);
            initializeSession(sessionData.apiKey, sessionData.sessionId, sessionData?.token,);
          }
        }
      } catch (error) {
        handleError(error as OTError);
        alert('Failed to get OpenTok sessionId and token.');
      }
    };

    initSession();

    return () => {
      if (session) {
        try {
          disconnectSession(session);
        } catch (error) {
          handleError(error as OTError);
        }
      }
    };
  }, []);

  const toggleMute = () => {
    try {
      if (publisher) {
        publisher.publishAudio(!isMuted);
        setIsMuted(!isMuted);
      }
    } catch (error) {
      handleError(error as OTError);
    }
  };

  const toggleVideo = () => {
    try {
      if (publisher) {
        publisher.publishVideo(!isVideoOff);
        setIsVideoOff(!isVideoOff);
      }
    } catch (error) {
      handleError(error as OTError);
    }
  };

  const startScreenSharing = () => {
    OT.checkScreenSharingCapability((response: OT.ScreenSharingCapabilityResponse) => {
      console.log(response);
      
      if (!response.supported || response.extensionRegistered === false) {
        alert('Screen sharing not supported');
      } else if (response.extensionInstalled === false) {
        alert('Please install the screen sharing extension and refresh the page');
      } else {
        const publisherOptions: OT.PublisherProperties = {
          videoSource: 'screen',
          publishAudio: false,
          maxResolution: { width: 1920, height: 1080 }
        };
        const screenPublisher = OT.initPublisher(publisherRef.current!, publisherOptions, (error) => {
          if (error) {
            handleError(error);
          } else {
            session?.publish(screenPublisher, handleError);
            setScreenSharePublisher(screenPublisher);
            setIsScreenSharing(true);
          }
        });
      }
    });
  };

  const stopScreenSharing = () => {
    if (screenSharePublisher) {
      console.log("stopScreenSharing");
      
      session?.unpublish(screenSharePublisher);
      setScreenSharePublisher(null);
      setIsScreenSharing(false);
      setIsScreenShareOn(false);
    }
  };

  const copyLinkToClipBoard = () => {
    const linkToCopy = `${environment.app_url}/waiting-room/${uniqueId}`;
    console.log("linkToCopy ", linkToCopy);
    console.log(environment.app_url);
    
    navigator.clipboard.writeText(linkToCopy).then(() => {
      // setCopySuccess('Copied!');
    }).catch(err => {
      // setCopySuccess('Failed to copy!');
      console.error('Failed to copy: ', err);
    });
    
  }

  return (
    <div className={styles.videoContainerMain}>
      {error && <div className="error-message">{error}</div>}
      <div className={styles.videoGrid}>
        <div className={styles.videoLeft}>
          <div id="publisher" className={styles.videoBox}></div>
        </div>
        <div className={styles.videoRight}>
          <div id="subscriber" className={styles.videoBox}></div>
        </div>
        {showWaitingMsg && <div className={styles.waitingMsg}>Waiting for other party to join</div>}
        <div id={styles.networkStatus} />
      </div>

      <div className={styles.controlsContainer}>
        <div className={styles.buttonGroup}>
          <div className={styles.controlGroup}>
            {isMuted ? (
              <button onClick={toggleMute} className={styles.commonButtonSelected}>
                <MicIcon />
              </button>
            ) : (
              <button onClick={toggleMute} className={styles.commonButton}>
                <MicOffIcon />
              </button>
            )}
          </div>
          <div className={styles.controlGroup}>
            {isVideoOff ? (
              <button onClick={toggleVideo} className={styles.commonButtonSelected}>
                <VideoCam />
              </button>
            ) : (
              <button onClick={toggleVideo} className={styles.commonButton}>
                <VideocamOff />
              </button>
            )}
          </div>
          {/* <div className={styles.controlGroup}>
            {!isCaptionStarted ? (
              <button onClick={startCaptions} className={styles.commonButton}>
                <ClosedCaptionIcon />
              </button>
            ) : (
              <button onClick={stopCaptions} className={styles.commonButtonSelected}>
                <ClosedCaptionDisabledIcon />
              </button>
            )}
          </div> */}
        {/* <div className={styles.controlGroup}>
          {!isArchiveStarted ? (
            <button onClick={startArchiving} className={styles.commonButton} disabled={!!archive}>
              <BsRecordCircle />
            </button>
          ) : (
            <button onClick={stopArchiving} className={styles.commonButtonSelected} disabled={!archive}>
              <BsRecordCircleFill />
            </button>
          )}
        </div> */}
        {/* <div className="control-group">
          {!isScreenSharing ? (
            <button onClick={startScreenSharing} className='common-button'>
              <ScreenShareIcon />
            </button>
          ) : (
            <button onClick={stopScreenSharing} className='common-button-selected'>
              <StopScreenShareIcon />
            </button>
          )}
        </div> */}
        {/* <div className={styles.controlGroup}>
          <button className={styles.commonButton} onClick={copyLinkToClipBoard}>
            <ContentCopyIcon />
          </button>
        </div> */}
        <div className={styles.controlGroup}>
          <button className={styles.exitButton} onClick={handleLeave} disabled={isDisableLeaveButton}>
            <IoMdExit />
          </button>
        </div>
      </div>
    </div>
    {/* <div ref={subscriberRef} className='screen-share-subscriberVideo' style={{display: subscriber ? 'block' : 'none'}} /> */}
    {isScreenShareOn && <div className={styles.screenShareWrapper}>
      <div className={styles.screenShareContainer}>
        <div className={styles.screenShareHeader}>
          <h2 className={styles.screenShareTitle}>Screen Sharing Session</h2>
        </div>
        <div className={styles.screenShareVideoContainer}>
          <div className={styles.screenSharePublisherVideo} style={{width: subscriber ? '20%' : '100%'}}/>
          <div 
            id={styles.screenShareSubscriberVideo} 
            style={{display: subscriber ? 'block' : 'none'}}
          />
        </div>
      </div>
    </div>}
  </div>
  );
};

export default VideoChat;