import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Button, CardTitle } from "reactstrap";
import UserContext from "../../context/UserContext";
import Tabs, { TabPane } from "rc-tabs";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { PaymentService } from "../../services/PaymentService";
import { CardContent, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { BillingHistory, CardDetails } from "../../models/Payment";
import { Link } from "react-router-dom";
import moment from "moment";
import BankCard from "./BankCard";
import InsuranceImg from "./../../assets/images/clients/insurance.svg";
import { RouteName } from "../../RouteName";
import "guidechimp/dist/guidechimp.min.css";
import "../../assets/css/subscription.css";
import "../../assets/css/rc-tabs.css";
import "../../../node_modules/rc-tabs/assets/index.css";
import AddInsurance from "../Insurances/Insurance";

toast.configure();

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontWeight: 600,
      fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":focus": {
        color: "#424770",
      },

      "::placeholder": {
        color: "#9BACC8",
      },

      ":focus::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#fb1c50",
      ":focus": {
        color: "#FA755A",
      },
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  },
};

const ClientSubscription: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const [payFormOn, setpayFormOn] = useState(false);
  const [subscription, setSubscription] = useState({} as any);
  const [activeKey, setActiveKey] = useState("1");
  const [periodEnd, setPeriodEnd] = useState("");
  const [periodStart, setPeriodStart] = useState("");
  const [billingHistory, setBilingHistory] = useState<BillingHistory[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<CardDetails[]>([]);
  const [activeCard, setActiveCard] = useState("");
  const [showAddCard, setShowAddCard] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.screen.width <= 768 ? setIsMobile(true) : setIsMobile(false);
  }, [window.screen.width]);

  useEffect(() => {
    if (user?.subscriptionId) {
      getSubscriptionDetails(user?.subscriptionId, true);
    }
  }, []);

  const getSubscriptionDetails = (subscriptionId: string, onLoad: boolean) => {
    if (subscriptionId) {
      PaymentService.getActiveSubscription().then((subscriptionItem: any) => {
        if (subscriptionItem.success) {
          if (onLoad) {
            setUser({ ...user, subscriptionStatus: subscriptionItem.data.status });
          }

          setSubscription(subscriptionItem.data);

          const periodStart = new Date(subscriptionItem.data.current_period_start * 1000);
          const periodEnd = new Date(subscriptionItem.data.current_period_end * 1000);

          setPeriodStart(periodStart.getMonth() + 1 + "/" + periodStart.getDate() + "/" + periodStart.getFullYear());
          setPeriodEnd(periodEnd.getMonth() + 1 + "/" + periodEnd.getDate() + "/" + periodEnd.getFullYear());

          PaymentService.getPaymentMethods().then((response: any) => {
            setPaymentMethods(response?.data?.paymentMethods?.data);

            const filteredCard = (response?.data?.paymentMethods?.data || []).filter((c: CardDetails) => c.id == subscriptionItem.data.default_payment_method);

            if (filteredCard.length > 0) {
              setActiveCard(filteredCard[0].card.last4);
            }
          });
        }
      });
    }

    PaymentService.getBillingHistory().then((billingHistory: any) => {
      setBilingHistory(billingHistory?.data?.data);
    });
  };

  function onTabsChange(e: any) {
    setActiveKey(e);
  }

  const handleSubmitSubscription = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe.js has not yet loaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    setIsSubscribing(true);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
      billing_details: {
        email: user?.email,
      },
    });

    if (result.error) {
      toast.error(result.error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      setIsSubscribing(false);
    } else {
      const subscriptionDetails = {
        paymentMethod: result.paymentMethod.id,
      };

      PaymentService.clientSubscribe(subscriptionDetails).then(res => {
        if (res.success) {
          const { clientSecret, status, subscriptionId, subscriptionStatus } = res.data;

          if (status === "requires_action") {
            stripe.confirmCardPayment(clientSecret).then(function (result) {
              if (result.error) {
                toast.error(result.error.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                setIsSubscribing(false);
              } else {
                setUser({ ...user, subscriptionId: subscriptionId, subscriptionStatus: subscriptionStatus });

                toast.success("Congratulations! Your subscription is successful.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });

                setIsSubscribing(false);

                elements.getElement(CardElement)?.clear();

                getSubscriptionDetails(subscriptionId, false);
              }
            });
          } else {
            setUser({ ...user, subscriptionId: subscriptionId, subscriptionStatus: subscriptionStatus });

            toast.success("Congratulations! Your subscription is successful.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setIsSubscribing(false);

            elements.getElement(CardElement)?.clear();

            getSubscriptionDetails(subscriptionId, false);
          }
        } else {
          toast.error(res.error ? res.error : "Failed to subscribe. Please contact support.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setIsSubscribing(false);

          return false;
        }
      });
    }
  };

  const cancelSubscription = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure want to cancel subscription?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        PaymentService.cancelSubscription().then((response: any) => {
          if (response.success) {
            setSubscription({ ...subscription, cancel_at_period_end: true });

            toast.success("Subscription will be cancelled at period end.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Error occured while cancelling subscription.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };

  const resumeSubscription = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure want to resume subscription?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        PaymentService.resumeSubscription().then((response: any) => {
          if (response.success) {
            setSubscription({ ...subscription, cancel_at_period_end: false });

            toast.success("Subscription is resumed successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Unable to resume your subscription.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };

  const addNewPaymentMethod = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe.js has not yet loaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
      billing_details: {
        email: user?.email,
      },
     
    });
  
    if (result.error) {
      toast.error(result.error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const paymentMethod = {
        paymentMethod: result.paymentMethod.id,
      };

      PaymentService.attachNewPaymentMethod(paymentMethod).then(res => {
        if (res.success) {
          setPaymentMethods([res.data, ...paymentMethods]);

          toast.success("New payment method is added successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          elements.getElement(CardElement)?.clear();

          setShowAddCard(false);
        } else {
          toast.error("Failed to add your card at this moment!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  const changeDefaultPaymentMethod = (newPaymentMethodId: any) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure want to change default payment method?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        const selectedPaymentMethod = {
          paymentMethodId: newPaymentMethodId,
        };

        PaymentService.changeDefaultPaymentMethod(selectedPaymentMethod).then((response: any) => {
          if (response.success) {
            setSubscription({ ...subscription, default_payment_method: newPaymentMethodId });

            toast.success("Payment method changed successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Unable to change payment method!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    });
  };

  const back = () => {
    setpayFormOn(false);
  };

  const backMain = () => {
    history.back();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {payFormOn ? (
            <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
              <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
              <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
            </div>
          ) : (
            <div className="flex flex-wrap  cursor-pointer" onClick={() => backMain()}>
              <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
              <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
            </div>
          )}
          <Breadcrumbs title={"Client"} breadcrumbItem={"Subscription"} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="card-heading">
                      <h1 className="notifications-title">Manage My Subscription</h1>
                    </div>

                    <div className="card-heading flex justify-content-between">
                      <div className="siler-line mb20"></div>

                      <br />
                      <br />
                    </div>
                  </Row>

                  {user?.subscriptionId || user?.testSubscriptionStatus == "active" ? (
                    <div className="subscribe-section2">
                      <Tabs activeKey={activeKey} onChange={onTabsChange} tabPosition={isMobile ? "top" : "left"}>
                        <TabPane tab="My Subscription" key="1">
                          <div className="my-sub-sec">
                            <div className="row">
                              <div className="col-lg-4 col-12 mb20">
                                <b>Subscription Status</b>

                                <hr />

                                {user?.subscriptionStatus == "active" || user?.testSubscriptionStatus == "active" ? (
                                  <>
                                    <span className="sub-item1">Active</span> Valid Until : <b>{periodEnd}</b>
                                  </>
                                ) : user?.subscriptionStatus == "past_due" ? (
                                  <>
                                    <span className="sub-item1 sub-item1-past-due">Past Due</span>
                                    <span className="text-danger">
                                      {/* <b>Please check your payment options!</b> */}
                                      <b>Your subscription renewal date has expired. Please pay the due amount to renew your subscription.</b>
                                    </span>
                                  </>
                                ) : user?.subscriptionStatus == "incomplete" ? (
                                  <>
                                    <span className="sub-item1 sub-item1-past-due">Incomplete</span>
                                    <span className="text-danger">
                                      {/* <b>Please check your payment options!</b> */}
                                      <b>Initial attempt for the payment failed! Please try again later.</b>
                                    </span>
                                  </>
                                ) : user?.subscriptionStatus == "incomplete_expired" ? (
                                  <>
                                    <span className="sub-item1 sub-item1-past-due">Expired</span>
                                    <span className="text-danger">
                                      {/* <b>Please check your payment options!</b> */}
                                      <b>Time allotted to your last subscription attempt expired. Please try again if you wish to continue.</b>
                                    </span>
                                  </>
                                ) : user?.subscriptionStatus == "canceled" ? (
                                  <>
                                    <span className="sub-item1 sub-item1-canceled">Subscription Cancelled</span>

                                    <br />
                                    <br />
                                    <br />

                                    <span
                                      className="sub-item1-re-subscribe"
                                      onClick={() => {
                                        setUser({ ...user, subscriptionId: "" });
                                        setpayFormOn(false);
                                      }}
                                    >
                                      Re-Subscribe
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div className="col-lg-8 col-12">
                                <b>Active Card</b>

                                <hr />

                                {activeCard && <>Card Ending .... {activeCard}&nbsp;&nbsp;&nbsp;</>}

                                <span
                                  className="bg-warning sub-item2 ml0"
                                  onClick={() => {
                                    setActiveKey("3");
                                  }}
                                >
                                  Manage Cards
                                </span>

                                <br />
                                <br />

                                {subscription && (
                                  <>
                                    Lavni health ($299) 1 month subscription. Started at: <b>{periodStart}</b>
                                  </>
                                )}

                                {(user?.subscriptionStatus == "active" ||
                                  user?.subscriptionStatus == "past_due" ||
                                  user?.testSubscriptionStatus == "active") && (
                                  <>
                                    {subscription.cancel_at_period_end == true && (
                                      <>
                                        <br />
                                        <br />

                                        <span className="text-danger">
                                          <b>Your subscription will be cancelled at period end.</b>
                                        </span>

                                        <div className="my-sub-sec cancel-subscription pull-right">
                                          <button
                                            className="btn btn-info btn-sm"
                                            onClick={() => {
                                              resumeSubscription();
                                            }}
                                          >
                                            Resume Subscription
                                          </button>
                                        </div>
                                      </>
                                    )}

                                    {subscription.cancel_at_period_end == false && (
                                      <div className="my-sub-sec cancel-subscription pull-right">
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => {
                                            cancelSubscription();
                                          }}
                                        >
                                          Cancel Subscription
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tab="Billing Details" key="2">
                          <Row>
                            <Col sm="12" className="mt10">
                              <table className="table tableRes">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Id</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Download Invoice</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {billingHistory &&
                                    billingHistory.map((billingHistory, index) => (
                                      <tr key={index}>
                                        <td>{moment.unix(billingHistory.created).format("MMMM Do YYYY").toString()}</td>
                                        <td>{index + 1}</td>
                                        <td>{`$${parseInt(billingHistory?.amount) / 100}`}</td>
                                        <td>{billingHistory?.charges?.data[0].billing_details?.email}</td>
                                        <td>
                                          <Link to={{ pathname: billingHistory?.charges?.data[0].receipt_url }} target="_blank">
                                            <i className="fa fa-file-invoice" style={{ color: "#495057", fontSize: "22px" }}></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tab="My Cards" key="3">
                          <Row>
                            <Col xxl={6}>
                              <BankCard paymentData={paymentMethods} subscription={subscription} changeDefaultPaymentMethod={changeDefaultPaymentMethod} />
                            </Col>

                            <Col lg={6}>
                              <button className="btn btn-primary" data-value2={true} onClick={() => setShowAddCard(true)}>
                                Add New
                              </button>

                              {showAddCard && (
                                <>
                                  <CardElement options={CARD_ELEMENT_OPTIONS} className="card-element mt-3 " />

                                  <div className="pay-div float-right">
                                    <Button className="mt-3 btn btn-info" variant="contained" color="primary" onClick={addNewPaymentMethod}>
                                      Add Card
                                    </Button>
                                  </div>
                                </>
                              )}
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tab="Insurance Details" key="4">
                          <Container className="containerInsurance">
                            <div className="card-heading mb-2">
                              <h5 className="font-size-25 profileFont mb-4 mt-5 text-center">Insurance Information</h5>
                            </div>
                            <Row>
                              <AddInsurance />
                            </Row>
                          </Container>
                        </TabPane>
                      </Tabs>
                    </div>
                  ) : (
                    <Row className="subscribe-section1">
                      {/* <div className="subscribe-section1"> */}

                      {payFormOn ? (
                        <>
                          <Col lg={6} className="subscribe-product2">
                            <CardContent className="pay-content">
                              <TextField
                                label="Email"
                                id="outlined-email-input"
                                helperText={`Email you'll recive updates and receipts on`}
                                margin="normal"
                                variant="outlined"
                                type="email"
                                required
                                value={user?.email}
                                disabled={true}
                                fullWidth
                              />

                              <CardElement options={CARD_ELEMENT_OPTIONS} className="card-element" />

                              <div className="pay-div">
                                <Button
                                  className="pay-btn btn btn-info"
                                  variant="contained"
                                  color="primary"
                                  onClick={handleSubmitSubscription}
                                  disabled={isSubscribing}
                                >
                                  {isSubscribing ? "Please Wait..." : "Subscribe"}&nbsp;&nbsp;<i className="fa fa-star"></i>
                                </Button>
                              </div>
                            </CardContent>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col lg={3} className="subscribe-product cell ">
                            <div className="col-lg-12 product-sec2">
                              <div className="subscribe-text">
                                <span className="price">$299</span> / month
                              </div>

                              <div className="feature-item">
                                <div className="fi-sec1">
                                  <i className="fa fa-angle-double-right"></i>
                                </div>
                                <div>Everything in free plan</div>
                              </div>

                              <div className="feature-item">
                                <div className="fi-sec1">
                                  <i className="fa fa-angle-double-right"></i>
                                </div>
                                <div>Weekly video/phone sessions with a licensed therapist</div>
                              </div>

                              <div className="feature-item">
                                <div className="fi-sec1">
                                  <i className="fa fa-angle-double-right"></i>
                                </div>
                                <div>Chat securely with your therapist anytime</div>
                              </div>

                              <div className="feature-item">
                                <div className="fi-sec1">
                                  <i className="fa fa-angle-double-right"></i>
                                </div>
                                <div>Regular goal tracking by you and your therapist</div>
                              </div>

                              <div className="feature-item">
                                <div className="fi-sec1">
                                  <i className="fa fa-angle-double-right"></i>
                                </div>
                                <div>Incognito mode</div>
                              </div>

                              <button
                                className="btn btn-info subscribe-btn"
                                onClick={() => {
                                  setpayFormOn(true);
                                }}
                              >
                                Subscribe&nbsp;&nbsp;<i className="fa fa-star"></i>
                              </button>
                            </div>
                          </Col>
                          <Col lg={3} className="subscribe-product insuraceBoxCenter cell ">
                            <div className="col-lg-12 product-sec2 insuraceBoxCenter">
                              <div>
                                <img src={InsuranceImg} className="insuranceImage" />
                              </div>
                              {user?.insuranceId == undefined ? (
                                <Link to={{ pathname: RouteName.INSURANCE }}>
                                  <button className="btn btn-info subscribe-btn ">Connect with insurance</button>
                                </Link>
                              ) : (
                                <Link to={{ pathname: RouteName.INSURANCE }}>
                                  <button className="btn btn-info subscribe-btn ">View insurance details</button>
                                </Link>
                              )}
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClientSubscription;
