
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { AdminService } from "../../services/AdminService";
import { TransactionPaymemt } from '../../models/Transaction';
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";

const ViewAvailableBalances: React.FC = () => {
  const [therapistsPayments, setTherapistsPayments] = useState<TransactionPaymemt[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    searchTherapists(true);
  }, []);




  const searchTherapists = (onload: boolean) => {
    setIsLoading(true);

    AdminService.getAllAccumulatedBalance().then(res => {
      if (res.success) {
        setTherapistsPayments(res.data);
      }
    });
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Therapists"} breadcrumbItem={"All Current Available Balance"} />
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  <div className="table-responsive">
                    {therapistsPayments !== undefined && therapistsPayments.length > 0 ? (
                      <>
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Stripe Account</th>
                              <th scope="col">Total Earnings</th>
                              <th scope="col">Available Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {therapistsPayments &&
                              therapistsPayments.map((therapist, index) => (
                                <tr key={therapist?._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    {therapist?.firstname + " " + therapist?.lastname}
                                  </td>
                                  <td>{therapist?.email}</td>

                                  <td>{therapist?.stripeConnectedAccountId}</td>

                                  <td> ${Math.round(therapist?.recentTransaction?.accumulatedTotalEarnings * 100) / 100}</td>

                                  <td> ${Math.round(therapist?.recentTransaction?.accumulatedBalance * 100) / 100}</td>

                                </tr>
                              ))
                            }

                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {
                          isLoading ? (
                            <Spinner />
                          ) : (
                            <><div>No Available Balance</div></>
                          )
                        }
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewAvailableBalances;
