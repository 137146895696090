import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Input, Button, ButtonGroup, Label, UncontrolledTooltip } from "reactstrap";
import { AdminService } from "../../services/AdminService";
import { UserRole } from "../../models/User";
import { PayStatus, RewardType } from '../../models/ReferralEarning';
import "../../assets/css/admin.css";
import Spinner from "../../common/spinner/spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ClientReward } from "src/models/ClientReward";
import ClientRewardContext from "src/context/ClientRewardCount";

const ClientRewards: React.FC = () => {
  const [rewards, setRewards] = useState<ClientReward[]>([]);
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOptions, setFilterOptions] = useState(false);
  const [isRewardType, setIsRewardType] = useState(RewardType.ALL as RewardType);
  const [payStatus, setPayStatus] = useState(PayStatus.ALL as PayStatus);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [rewardsCount, setRewardsCount] = useState(10);
  const [count, setCount] = useState(0);
  const [clientRewardCount, getAllClientRewardCount] = useContext(ClientRewardContext);

  useEffect(() => {
    getAllClientRewardBlances();
  }, []);

  useEffect(() => {
    searchClientRewards(true);
  }, []);

  const getAllClientRewardBlances = () => {
    setIsLoading(true);
    AdminService.getAllClientRewards(limit, offset).then(res => {
      if (res.success) {
        const earnings = res.data.clientRewardsList.map((earning: { [x: string]: any; clientId: any; }) => {

          if (!earning.paidStatus) {
            earning.paidStatus = "UNPAID";
          }

          const { clientId, ...rest } = earning;

          return {
            ...rest,
            clientId: clientId?._id || null,
            email: clientId?.email || null,
            firstname: clientId?.firstname || null,
            lastname: clientId?.lastname || null,
            clientActiveStatus: clientId?.clientActiveStatus,
          };
        });
        setRewards(earnings);
        setRewardsCount(earnings.length);
      }
      setIsLoading(false);
    });
  };

  const searchClientRewards = (onload: boolean) => {
    const data = {
      searchableString: isSearchableString,
      rewardType: isRewardType,
      payStatus: payStatus
    }

    setIsLoading(true);

    AdminService.searchAllClientRewardsByAdmin(data, limit, offset).then(res => {
      if (res.success) {
        setRewards(res.data.userSet);
        setRewardsCount(res.data.userSet.length);
        setCount(res.data?.totalCount)
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  }

  const filterRewards = () => {
    const data = {
      searchableString: isSearchableString,
      rewardType: isRewardType,
      payStatus: payStatus
    }

    setIsLoading(true);

    const updatedOfsetSkip = 1;
    setOffset(updatedOfsetSkip);

    AdminService.searchAllClientRewardsByAdmin(data, limit, 1).then(res => {
      if (res.success) {
        setRewards(res.data.userSet);
        setRewardsCount(res.data.userSet.length);
        setCount(res.data?.totalCount)
      }

      setIsLoading(false);

      if (!onload) {
        setFilterOptions(!isFilterOptions);
      }
    });
  }

  const seeMore = () => {
    setIsLoading(true);

    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    const data = {
      searchableString: isSearchableString,
      rewardType: isRewardType,
      payStatus: payStatus
    }

    AdminService.searchAllClientRewardsByAdmin(data, limit, updatedSkip).then(res => {
      if (res.success) {
        res.data.userSet.map((reward: any) => {
          setRewards(rewards => [...rewards, reward]);
        });

        setRewardsCount(res.data.userSet.length);
      }

      setIsLoading(false);
    });
  }

  const toggleFilter = () => {
    setFilterOptions(!isFilterOptions);
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const data = {
        searchableString: isSearchableString,
        rewardType: isRewardType,
        payStatus: payStatus
      }

      setIsLoading(true);

      AdminService.searchAllClientRewardsByAdmin(data, limit, offset).then(res => {
        if (res.success) {
          setRewards(res.data.userSet);
          setRewardsCount(res.data.userSet.length);
        }

        setIsLoading(false);

        if (!onload) {
          setFilterOptions(!isFilterOptions);
        }
      });
    }
  };

  const markAsPaid = (e: any) => {
    const rewardId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
        icon: "info",
        title: "Are you sure want to mark as 'PAID' this reward record?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
          const data: any = {
            rewardId: rewardId,
            paidStatus: "PAID"
          }
          AdminService.setMarkedAsPaidClientRewardRecord(data).then(res => {
            if (res.success) {
              getAllClientRewardBlances();
              getAllClientRewardCount(clientRewardCount - 1);
              toast.success(res.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            } else {
              toast.error(res.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: 'foo-bar'
              });
            }
          });
        }
    });
  }

  const deleteReward = (e: any) => {
    const rewardId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
        icon: "info",
        title: "Are you sure want to delete this reward record?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
    }).then(result => {
        if (result.isConfirmed) {
            AdminService.deleteClientRewardRecord(rewardId).then(res => {
                if (res.success) {
                    getAllClientRewardBlances();
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        }
    });
  }
  
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Client"} breadcrumbItem={"Rewards"} />
            <Card className="m-h">
              <CardBody>
                
                <Row className="d-flex justify-content-between align-items-center">
                  <div className="d-flex admin-filter">
                    <button className="btn btn-sm mr-2" onClick={toggleFilter}>Filter <i className='bx bx-filter-alt'></i></button>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="d-flex me-2 me-lg-4">
                      <div className="reward-type-dot own-first-bg"></div>
                      <span className="d-none d-sm-block">OWNFIRST <i className='bx bx-info-circle' id="OWNFIRST"></i></span>
                      <UncontrolledTooltip placement="bottom" target={"OWNFIRST"}>
                        Fourth session completion bonus for client who SignUp using a referral link.
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex me-2 me-lg-4">
                      <div className="reward-type-dot refer-first-bg"></div>
                      <span className="d-none d-sm-block">REFERFIRST <i className='bx bx-info-circle' id="REFERFIRST"></i></span>
                      <UncontrolledTooltip placement="bottom" target={"REFERFIRST"}>
                        Fourth session completion bonus for client who generated the referral link.
                      </UncontrolledTooltip>
                    </div>
                  </div>

                </Row>

                <Row>
                  <Col sm="12" className="mt10">
                    <div className="table-responsive" style={{ minHeight: "70vh" }}>
                      {rewards !== undefined && rewards.length > 0 ? (
                        <>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Reward Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Client Active Status</th>
                                <th scope="col">Paid Status</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {rewards &&
                                rewards.map((reward, index) => (
                                  <tr key={reward._id}>
                                    <td scope="row" valign="middle">{index + 1}</td>

                                    <td valign="middle">{reward?.firstname}&nbsp;{reward?.lastname}</td>

                                    <td valign="middle">{reward?.email}</td>

                                    <td valign="middle">
                                      { 
                                        (reward.rewardType == RewardType.OWNFIRST) && (
                                          <span className="own-first-bg reward-bg-pad">{reward.rewardType}</span>
                                        )
                                      }
                                      { 
                                        (reward.rewardType == RewardType.REFERFIRST) && (
                                          <span className="refer-first-bg reward-bg-pad">{reward.rewardType}</span>
                                        )
                                      }
                                    </td>
                                    
                                    <td valign="middle">${reward.transactionAmount}</td>

                                    <td valign="middle">
                                      {
                                        (reward?.clientActiveStatus === undefined || reward?.clientActiveStatus == false) && (
                                          <div className="d-flex">
                                            <span className="pending-2 mt-2">Inactive Client</span>
                                          </div>
                                        )
                                      }
                                      {
                                        (reward?.clientActiveStatus) && reward?.clientActiveStatus === true && (
                                          <div className="d-flex">
                                            <span className="verified-2 mt-2">Active Client</span>
                                          </div>
                                        )
                                      }
                                    </td>

                                    <td valign="middle">
                                      {
                                        (reward?.paidStatus === undefined || reward.paidStatus == "UNPAID") && (
                                          <div className="d-flex">
                                            <span className="pending-2 mt-2">Pending</span>
                                          </div>
                                        )
                                      }
                                      {
                                        (reward?.paidStatus) && reward?.paidStatus == "PAID" && (
                                          <div className="d-flex">
                                            <span className="verified-2 mt-2">Paid</span>
                                          </div>
                                        )
                                      }
                                    </td>

                                    <td valign="middle">
                                      <button className="btn btn-success mr-2" onClick={markAsPaid} disabled={!(reward?.paidStatus === undefined || reward?.paidStatus === "UNPAID")} data-value1={reward._id} data-value2={true}>{reward?.paidStatus === PayStatus.PAID ? "Paid" : "Mark as Paid"}</button>
                                      {reward?.paidStatus != PayStatus.PAID && (
                                        <button
                                          className="btn btn-danger"
                                          onClick={deleteReward}
                                          data-value1={reward._id}
                                          data-value2={true}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>

                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>

                          {
                            rewardsCount >= 10 && (
                              <>
                                <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                  {
                                    isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : <>See More</>
                                  }
                                </button>
                              </>
                            )
                          }

                          {rewardsCount == 0 && <span>No more rewards available...</span>}
                        </>
                      ) : (
                        <>
                          {
                            isLoading ? (
                              <Spinner />
                            ) : (
                              <><div>No Reward</div></>
                            )
                          }
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        <Offcanvas
          className="offcanvas-end2"
          isOpen={isFilterOptions}
          direction="end"
          toggle={toggleFilter}>
          <OffcanvasHeader toggle={toggleFilter}>
            Filter Client Rewards
          </OffcanvasHeader>
          <OffcanvasBody>
            <Row className="mt-4">
              <Col lg={12}>
                <FormGroup>
                  <Label>Search by Name or Email</Label>
                  <Input
                    id="search"
                    name="text"
                    placeholder="Search Client (ex: first name, last name or email)"
                    type="text"
                    onKeyPress={handleKeyPress}
                    onChange={e => setIsSearchableString(e.target.value)}
                    value={isSearchableString}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={12}>
                <Label>Reward Type</Label>
                <ButtonGroup className="flex-wrap flex">
                  <Button color="primary" defaultChecked defaultValue={RewardType.ALL} outline onClick={() => setIsRewardType(RewardType.ALL)} active={isRewardType === RewardType.ALL}>All</Button>
                  <Button color="primary" outline onClick={() => setIsRewardType(RewardType.OWNFIRST)} active={isRewardType === RewardType.OWNFIRST}>Own First</Button>
                  <Button color="primary" outline onClick={() => setIsRewardType(RewardType.REFERFIRST)} active={isRewardType === RewardType.REFERFIRST}>Refer First</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <Label>Paid Status</Label>
                <ButtonGroup className="flex-wrap flex">
                  <Button color="primary" outline onClick={() => setPayStatus(PayStatus.ALL)} active={payStatus === PayStatus.ALL}>All</Button>
                  <Button color="primary" outline onClick={() => setPayStatus(PayStatus.PAID)} active={payStatus === PayStatus.PAID}>Paid</Button>
                  <Button color="primary" defaultChecked defaultValue={PayStatus.UNPAID} outline onClick={() => setPayStatus(PayStatus.UNPAID)} active={payStatus === PayStatus.UNPAID}>Unpaid</Button>
                </ButtonGroup>
              </Col>
            </Row>

            <div className="col-xl-12 mb-3 mt-5">
              <Col lg={12}>
                <button className="btn btn-success w-100" onClick={() => filterRewards()}>Search</button>
              </Col>
            </div>
          </OffcanvasBody>
        </Offcanvas>

      </React.Fragment>
    </>
  );
};

export default ClientRewards;