import React, { useContext, useRef, useState, useEffect } from "react";
import EmojiIcon from "../../assets/images/icons/chat_emoji.svg";
import PdfIcon from "../../assets/images/icons/chat_pdf.svg";
import CallIcon from "../../assets/images/icons/fi_phone.svg";
import VideoIcon2 from "../../assets/images/icons/fi_video.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Input, Modal, Offcanvas, OffcanvasHeader, Row, UncontrolledTooltip } from "reactstrap";
import DiscoverIcon from "../../assets/images/icons/discover_icon.png";
import ChatIcon from "../../assets/images/icons/chat_icon.png";
import videoIcon from "../../assets/images/icons/fi_video (1).png";
import CloseIcon from "../../assets/images/icons/close.png";
import VideoIcon from "../../assets/images/icons/v_call_icon.svg";
import SendIcon from "../../assets/images/icons/send.svg";
import { SocketContext } from "src/context/ScoketContext";
import { ChatModel } from "src/models/Chat";
import { ChatService } from "src/services/ChatService";
import UserContext from "src/context/UserContext";
import { DoDecrypt, DoEncrypt } from "../Chat/ChatComponents/Aes";
import moment from "moment";
import { toast } from "react-toastify";
import { PremiumStatus, testSubscriptionStatus } from "src/models/Client";
import MessageModal from "../Popup/MessageModal";
import { User } from "src/models/User";
import InfiniteScroll from "react-infinite-scroll-component";
import DisplayMessageComp from "../Chat/ChatComponents/DisplayMessageComp";
import Picker from "emoji-picker-react";
import ReplyMessageDisplayComp from "../Chat/ChatComponents/ReplyMessageDisplayComp";
import { MessageModel } from "src/models/MessageModel";
import { Role } from "src/models/Role";
import { CallInitializeData } from "src/models/CallInitializeData";
import CallInitializationContext from "src/context/CallInitializationContext";
import LocalCallContext from "src/context/LocalCallContext";
import { VideoChatService } from "src/services/VideoChatService";
import { TranscribeDetails } from "src/models/TranscribeDetails";
import images from "src/assets/images";
import { TreatmentHistory, TreatmentHistoryData } from "src/models/TreatmentHistory";
import Modal6 from "../Popup/Modal6";
import defaultImage from "../../assets/images/default_profile.png";
import { v4 as uuidv4 } from "uuid";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import OngoingCallRefreshModal from "../Popup/OngoingCallRefreshModal";
import Spinner from "src/common/spinner/spinner";
import { isEmpty } from "lodash";
import { ClientService } from "src/services/ClientService";
import Modal29 from "../Popup/Modal29";
import { Util } from "src/Util";

const TherapistQuickChat: React.FC = () => {
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [conversationList, setConversationList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [currentChat, setCurrentChat] = useState<ChatModel | any>(undefined);
  const socket = useContext(SocketContext);
  const [socketRecieveMsg, setSocketRecieveMsg] = useState<any>("");
  const [hasChatForRecievedSocketMsg, setHasChatForRecievedSocketMsg] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [messageList, setMessageList] = useState<any>([]);
  const WORD_COUNT = 500;
  const [wordCount, setWordCount] = useState(0);
  const [showWordCountModal, setShowWordCountModal] = useState(false);
  const [chatId, setChatId] = useState<any>("");
  const [currentRecipientUser, setCurrentRecipientUser] = useState<User | undefined>(undefined);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const SCREEN_SIZE = 991;
  const [chatBoxUsername, setChatBoxUsername] = useState("");
  const [chatBoxUserStatus, setChatBoxUserStatus] = useState("online");
  const [messagesOffsetPageNo, setMessagesOffsetPageNo] = useState(1);
  const [messageBox, setMessageBox] = useState<HTMLInputElement | null>(null);
  const [messageBoxPopUp, setMessageBoxPopUp] = useState<HTMLInputElement | null>(null);
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<MessageModel | undefined>(undefined);
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);
  const [fileType, setFileType] = useState("");
  const [uploadedFile, setUploadedFile] = useState({ preview: "", raw: "" });
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const SUPPORTED_FILE_TYPES = ["doc", "docx", "odt", "pdf", "xls", "xlsx", "ppt", "pptx", "txt"];
  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];
  const [showTimeSelectModal, setShowTimeSelectModal] = useState(false);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [showNotAfriend, setShowNotAfriend] = useState(false);
  const [chattingWith, setChattingWith] = useState("");
  const history = useHistory();
  const [localUserOnCall, setLocalUserOnCall, cancellCallAfterCallSend, setOutGoinCallTimeOutFunction, setNearestAppointmentFunction] =
    useContext(LocalCallContext);
  const [isRecipientTyping, setIsRecipientTyping] = useState({ isTyping: true, chatId: "" });
  const [onlineUsers, setOnlineUsers] = useState<User[]>([]);
  const [isTreatmentOpen, setIsTreatmentOpen] = useState(false);
  const [showSessionHistoryModal, setShowSessionHistoryModal] = useState<boolean>(false); //change here
  const [isTranscribeOpen, setTranscribeOpen] = useState(false);
  const [transcribeId, setTranscribeId] = useState("");
  const [isTranscribeError, setIsTranscribeError] = useState(false);
  const [isTranscribeLoading, setIsTranscribeLoading] = useState(false);
  const [transcribe, setTranscribe] = useState<TranscribeDetails>();
  const [transcribeTextList, setTranscribeTextList] = useState<string[] | []>([]);
  const [searchTextForTranscribe, setSearchTextForTranscribe] = useState("");
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(true);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const [noteIdAdd, setNoteId] = useState("");
  const [singleTreatmentHistoryData, setSingleTreatmentHistoryData] = useState<TreatmentHistory>({} as TreatmentHistory);
  const [modalCenter, setModalCenter] = useState(false);
  const [isTreatmentLazyLoading, setIsTreatmentLazyLoading] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [showImcompleteCoPayments, setShowIncompleteCopayments] = useState(false);
  const [coPaymentAmount, setCopaymentAmount] = useState(0);
  const [showOngoingCallRefreshModal, setShowOngoingCallRefreshModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const editorRef = useRef<any>(null);
  const editorConfiguration = {
    toolbar: ["bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
    placeholder: "Type a message",
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollTop + 100;
    }
    if (messageBoxPopUp) {
      messageBoxPopUp.scrollTop = messageBoxPopUp.scrollTop + 100;
    }
  };

  useEffect(() => {
    if (!isEmpty(messageList)) {
      scrollToBottom();
    }
  }, [messageList]);

  const addMessage = () => {
    if (attachmentFile) {
      sendMessageWithAttachment();
    } else {
      sendMessage();
    }
  };

  const sendMessageWithAttachment = async () => {
    try {
      setIsSending(true);
      setShowEmojiPanel(false);

      const curMessage = editorRef.current.getData().toString();

      if ((curMessage && curMessage != "" && curMessage.trim() != "" && !attachmentFile) || attachmentFile) {
        setIsMessageSending(true);

        const messageData = {
          senderId: user?._id,
          chatId: chatId,
          text: curMessage ? DoEncrypt(curMessage) : null,
          unreadMessageObj: { unreadUserId: chatId, msgCount: 1 },
          ...(selectedMessage && { mentionedMessageId: selectedMessage._id }),
        };

        const sentMessage = await ChatService.sendMessageWithAttachment(messageData, attachmentFile!);

        if (sentMessage.success) {
          setUploadedFile({ preview: "", raw: "" });
          setFileType("");
          setAttachmentFile(null);
          setShowReplyPanel(false);
          setSelectedMessage(undefined);
          if (editorRef.current) {
            editorRef.current.setData("");
            editorRef.current.focus();
          }

          socket.emit("send-message", { ...sentMessage?.data, receiverId: currentRecipientUser?._id });
          setMessageList([...messageList, sentMessage?.data]);
        } else {
          toast.error("Something Wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        // setcurMessage("");

        setIsMessageSending(false);
      } else {
        toast.error("You can't send empty message!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Something Wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setIsMessageSending(false);
    }
    setIsSending(false);
  };

  const sendMessage = async () => {
    try {
      setIsSending(true);
      setShowEmojiPanel(false);

      const curMessage = editorRef.current.getData().toString();
      if (curMessage && curMessage != "" && curMessage.trim() != "") {
        const messageData = {
          senderId: user?._id,
          chatId: chatId,
          text: DoEncrypt(curMessage),
          unreadMessageObj: { unreadUserId: chatId, msgCount: 1 },
          ...(selectedMessage && { mentionedMessageId: selectedMessage._id }),
        };

        const tempChatId = Math.floor(Math.random() * 1000);

        const message: any = {
          _id: tempChatId,
          senderId: user,
          chatId: chatId,
          text: DoEncrypt(curMessage),
          createdAt: new Date(),
          AttachmentUploadId: null,
          ...(selectedMessage && { mentionedMessage: selectedMessage }),
        };

        const socketMessage = {
          _id: Math.floor(Math.random() * 100),
          senderId: user,
          chatId: chatId,
          text: DoEncrypt(curMessage),
          createdAt: new Date(),
          receiverId: currentRecipientUser?._id,
          AttachmentUploadId: null,
        };

        setMessageList([...messageList, message]);
        setChatLastMessage(currentChat, message);
        // setcurMessage("");

        const sentMessage = await ChatService.sendMessage(messageData);

        if (sentMessage.success) {
          setUploadedFile({ preview: "", raw: "" });
          setFileType("");
          setAttachmentFile(null);
          setShowReplyPanel(false);
          setSelectedMessage(undefined);
          if (editorRef.current) {
            editorRef.current.setData("");
            editorRef.current.focus();
          }

          socket.emit("send-message", { ...sentMessage?.data, receiverId: currentRecipientUser?._id });

          setMessageList((prevState: any) => {
            return prevState.map((message: any) => {
              if (message._id == tempChatId.toString()) {
                return { ...sentMessage?.data };
              }
              return message;
            });
          });
        } else {
          toast.error("Something Wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("You can't send empty message!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Something Wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    setIsSending(false);
  };

  const setChatLastMessage = (currentChat: any, msg: any) => {
    const updatedConversationList = conversationList?.map((conv: any) => {
      if (conv?.chat?._id == currentChat?.chat?._id) {
        if (conv.chat.unreadMessage) {
          conv.chat.unreadMessage.lastMessage = msg;
        } else {
          conv.chat.unreadMessage = { lastMessage: msg };
        }
        return conv;
      } else {
        return conv;
      }
    });
    setConversationList(updatedConversationList);
  };

  const setMessageCountToZero = (conve: any) => {
    const updatedConversationList = conversationList?.map((conv: any) => {
      if (conv.chat._id === conve?.chat?._id) {
        conv.unreadMessageCount = 0;
        return conv;
      } else {
        return conv;
      }
    });
    setConversationList(updatedConversationList);
  };

  const [isLoadingConversationListLazy, setLoadingConversationListLazy] = useState(false);
  const [isLoadingConversationList, setIsLoadingConversationList] = React.useState<boolean>(true);

  const toggleChatModal = async (state: boolean, conve?: any, offset?: number) => {
    try {
      setIsLoadingConversationList(true);
      setLoadingConversationListLazy(false);
      setMessagesOffsetPageNo(1);
      setMessageList([]);

      setShowEmojiPanel(false);
      setUploadedFile({ preview: "", raw: "" });
      setFileType("");
      setAttachmentFile(null);
      setShowReplyPanel(false);
      setSelectedMessage(undefined);

      setShowChatModal(state);

      if (state == true) {
        if (editorRef.current) {
          editorRef.current.setData("");
          editorRef.current.focus();
        }
        const upcomingMessageUser = conve?.chat?.members?.find((u: any) => u._id !== user?._id);
        // const unreadMessagesListOfSpecificUser = await ChatService.getChatByChatIdForDashboard(upcomingMessageUser?._id, conve?.chat?._id)
        try {
          setCurrentChat(conve);
          setChatId(upcomingMessageUser?._id);
          setChattingWith(upcomingMessageUser?._id || "");
          setCurrentRecipientUser(upcomingMessageUser);
          setChatBoxUsername(upcomingMessageUser?.firstname + " " + upcomingMessageUser?.lastname || "");
          const chatMessages = await ChatService.getAllMessagesByChatId(upcomingMessageUser?._id, offset);

          if (chatMessages.success) {
            setIsLoadingConversationList(false);
            setMessageList(chatMessages.data.reverse());
            // const res = await ChatService.markAsReadMessages(conve?.chat?._id);
            // const conversationListNew = conversationList.filter((conv: any) => conv?.chat._id != conve?.chat?._id)
            // setConversationList(conversationListNew);
          } else {
            toast.error("Something Wrong!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } catch (error) {
          toast.error("Something Wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        setCurrentChat(undefined);
      }

      setMessageCountToZero(conve);
      // setMessageCountToZero(currentChat)
      setMessageAsRead(conve?.chat?._id);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      if (socket) {
        socket?.on("receive-message", (data: any) => {
          setSocketRecieveMsg(data);
        });
        socket?.on("get-online-users", (users: any) => {
          setOnlineUsers(users);
        });
        socket?.on("recipient-is-typing", (data: any) => {
          setIsRecipientTyping(data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [socket]);

  useEffect(() => {
    const hasDesiredValue = conversationList.some((conv: any) => conv.chat._id == socketRecieveMsg.chatId);
    if (hasDesiredValue || socketRecieveMsg == "") {
      setHasChatForRecievedSocketMsg(true);
    }
    if (hasDesiredValue == true) {
      const updatedConversationList = conversationList?.map((conv: any) => {
        if (conv.chat._id == socketRecieveMsg?.chatId) {
          if (currentChat?.chat?._id != conv.chat._id) {
            conv.unreadMessageCount = conv.unreadMessageCount + 1;
          }
          if (conv.chat.unreadMessage) {
            conv.chat.unreadMessage.lastMessage = socketRecieveMsg;
          } else {
            conv.chat.unreadMessage = { lastMessage: socketRecieveMsg };
          }
          return conv;
        } else {
          return conv;
        }
      });
      const updatedConversationIndex = updatedConversationList.findIndex((conv: any) => conv.chat._id === socketRecieveMsg?.chatId);

      // Get the updated conversation to top of the list
      const newConversationList = [...updatedConversationList];
      newConversationList.splice(updatedConversationIndex, 1);
      newConversationList.unshift(updatedConversationList[updatedConversationIndex]);
      if (newConversationList.length >= 5) {
        setConversationList(newConversationList.splice(0, 5));
      } else {
        setConversationList(newConversationList);
      }

      if (socketRecieveMsg?.chatId == currentChat?.chat?._id) {
        setMessageList([...messageList, socketRecieveMsg]);
      }
    } else {
      ChatService.getChatByChatIdForDashboard(user?._id, socketRecieveMsg?.chatId).then((res: any) => {
        if (res.success) {
          const chatnew = res.data;
          // chatnew.unreadMessage.lastMessage = socketRecieveMsg;
          const newConversationList = [chatnew, ...conversationList];
          if (newConversationList.length >= 5) {
            setConversationList(newConversationList.splice(0, 5));
          } else {
            setConversationList(newConversationList);
          }
        }
      });
    }
  }, [socketRecieveMsg]);

  const setMessageAsRead = async (convId: any) => {
    const res = await ChatService.markAsReadMessages(convId);
  };

  useEffect(() => {
    getUnreadChatList(user?._id);
  }, []);

  const getUnreadChatList = (userId: any) => {
    setIsLoading(true);
    ChatService.getAllUnreadChatsFromUserIdForDashboard(userId).then((res: any) => {
      setConversationList(res.data);
      setIsLoading(false);
    });
  };

  const getMessagesByChatId = async (chatId: string, offset = 1) => {
    try {
      if (offset == 1) {
        setLoadingConversationListLazy(false);
        setIsLoadingConversationList(true);
        setMessageList([]);
      } else {
        if (isLoadingConversationList || isLoadingConversationListLazy) {
          return;
        }
        setLoadingConversationListLazy(true);
      }

      const chatMessages = await ChatService.getAllMessagesByChatId(chatId, 20, offset);

      if (chatMessages.success) {
        if (offset > 1) {
          setMessageList((prevState: any) => [...chatMessages.data.reverse(), ...prevState]);
        } else {
          setMessageList(chatMessages.data.reverse());
        }
        if (offset == 1) {
          setIsLoadingConversationList(false);
        }
        setLoadingConversationListLazy(false);
      } else {
        setLoadingConversationListLazy(false);
        toast.error("Something Wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setLoadingConversationListLazy(false);
      toast.error("Something Wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const openReplyPanel = (messageDetails: MessageModel) => {
    setShowReplyPanel(!showReplyPanel);
    setSelectedMessage(messageDetails);
  };

  const copyToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopy = (text: string) => {
    const copiedTextInString = text;

    if (copiedTextInString != undefined || copiedTextInString != null || copiedTextInString != "") {
      copyToClipboard(copiedTextInString).then(() => {
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
    } else {
      toast.error("Failed to copy the text to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const deleteMessage = async (messageId: string) => {
    try {
      const deletedMsg = await ChatService.deleteMessage(messageId);
      if (deletedMsg.success) {
        const tempMsgList = messageList.filter((message: any) => message._id != messageId);
        setMessageList(tempMsgList);
      } else {
        toast.error("Could not delete the message!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("Could not delete the message!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const openEmojiPanel = () => {
    try {
      editorRef.current.focus();
      setShowEmojiPanel(!showEmojiPanel);
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onEmojiClick = (event: any, { emoji }: any) => {
    try {
      const editor = editorRef.current;
      const model = editor.model;

      const selection = model.document.selection;

      if (selection.isCollapsed) {
        const position = selection.getFirstPosition();

        model.change((writer: any) => {
          writer.insertText(emoji, position);
        });
      } else {
        const range = selection.getFirstRange();

        model.change((writer: any) => {
          writer.insertText(emoji, range.end);
        });
      }

      editor.focus();
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      const extension = event.target.files[0].name.split(".").pop().toLowerCase();
      const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);
      const isAFile = isSupported(SUPPORTED_FILE_TYPES, extension);

      if (isAFile) {
        setUploadedFile({
          raw: event.target.files[0],
          preview: "",
        });
        setAttachmentFile(event.target.files[0]);
        setFileType("FILE");
      } else if (isAnImage) {
        setUploadedFile({
          raw: event.target.files[0],
          preview: URL.createObjectURL(event.target.files[0]),
        });
        setAttachmentFile(event.target.files[0]);
        setFileType("IMAGE");
      } else {
        toast.error("File format is not supported!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    }
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const removeFile = () => {
    setUploadedFile({ raw: "", preview: "" });
    setFileType("");
    setAttachmentFile(null);
  };

  const onIconClick = () => {
    if (
      (user?.subscriptionId && user.subscriptionStatus == "active") ||
      user?.premiumStatus == PremiumStatus.ACTIVE ||
      user?.testSubscriptionStatus == testSubscriptionStatus.ACTIVE ||
      user?.role == Role.THERAPIST
    ) {
      const input = document.getElementById("image-input");
      if (input) {
        input.click();
      }
    }
  };

  const sendTypingStatusTrue = () => {
    socket.emit("recipient-typing-receive", { recipientId: currentRecipientUser?._id, chatId: currentChat?._id });
  };

  const sendTypingStatusFalse = () => {
    socket.emit("recipient-typing-end-receive", { recipientId: currentRecipientUser?._id, chatId: currentChat?._id });
  };

  const startInstantMeetingInitial = (time: number, showModal: boolean, getCall: boolean, isVideoCall: boolean) => {
    setShowTimeSelectModal(showModal);

    if (isVideoCall) {
      if (getCall) {
        startInstantMeeting(chattingWith, isVideoCall, time);
      }
    } else {
      startAudioCallMeeting(chattingWith, time);
    }
  };

  const startInstantMeeting = (userId: any, isVideoCall: boolean, meetingTime: number) => {
    if (user?.role == Role.THERAPIST) {
      if (userId == "") {
        alert("No valid user id is provided!");
      } else if (localCallInitialize) {
        setShowOngoingCallRefreshModal(true);
      } else {
        const callInitializeData: CallInitializeData = {
          start: true,
          isInstantMeeting: true,
          isAppointmentBased: false,
          appointmentId: "ddddddd",
          recieverId: userId,
          meetingTime: meetingTime,
          isTranscribeAllowed: true,
          meetingId: "",
          isAudioCall: false,
        };

        setLocalCallInitializeData(callInitializeData);
        setOutGoinCallTimeOutFunction();
        const roomNamePartOne = user?._id;
        const roomNamePartTwo = userId;
        const uniqueId = uuidv4();
        const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
        const vonageUrl = `/room/${roomName}`;
        history.push(vonageUrl);
      }
    }
  };

  const startAudioCallMeeting = (userId: any, meetingTime: number) => {
    if (userId == "") {
      alert("No valid user id is provided!");
    } else if (localCallInitialize) {
      setShowOngoingCallRefreshModal(true);
    } else {
      const callInitializeData: CallInitializeData = {
        start: true,
        isInstantMeeting: true,
        isAppointmentBased: false,
        appointmentId: "ddddddd",
        recieverId: userId,
        meetingTime: meetingTime,
        isTranscribeAllowed: true,
        meetingId: "",
        isAudioCall: true,
      };

      setLocalCallInitializeData(callInitializeData);
      setOutGoinCallTimeOutFunction();
      const roomNamePartOne = user?._id;
      const roomNamePartTwo = userId;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room/${roomName}`;
      history.push(vonageUrl);
    }
  };

  const toggleTreatmentCanvas = () => {
    setShowSessionHistoryModal(true);
  };
  async function showTranscribe(transcribeId: string) {
    setTranscribeId(transcribeId);
    setIsTranscribeError(false);
    setIsTranscribeLoading(true);
    setTranscribeOpen(true);
    const response = await VideoChatService.getTranscribeDetails(transcribeId);
    if (response.success) {
      setIsTranscribeError(false);
      setIsTranscribeLoading(false);
      setTranscribe(response.data);
      setTranscribeTextList(response.data.transcriptText);
    } else {
      setIsTranscribeError(true);
      setIsTranscribeLoading(false);
    }
  }
  function closeTranscribe() {
    setIsTranscribeError(false);
    setIsTranscribeLoading(false);
    setTranscribeOpen(false);
    setSearchTextForTranscribe("");
  }

  const searchTranscribe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextForTranscribe(event.target.value);
    const textList: string[] = [];
    const combinedArray = textList.concat(transcribe?.transcriptText != null ? transcribe?.transcriptText : []);
    const result = combinedArray.filter(word => word.match(event.target.value));
    setTranscribeTextList(result);
  };

  function togCenter(noteId: string) {
    setNoteId(noteId);
    const selectedHistory = treatmentHistoryDetails.filter(c => c._id === noteId);
    const selectedHistorydata = selectedHistory[0];
    setSingleTreatmentHistoryData(selectedHistorydata);
    setModalCenter(!modalCenter);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const joinZoomCall = async () => {
    const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(chattingWith);
    if (meetingRes.success) {
      let totalAmount = 0;
      for (const meeting of meetingRes.data) {
        const amount = parseFloat(meeting?.copayment?.amount) || 0;
        totalAmount += amount;
      }
      if (totalAmount > 0) {
        setCopaymentAmount(totalAmount);
        setShowIncompleteCopayments(true);
        const meetingRes: any = await ClientService.sendSMSAndEmailForClient(chattingWith, totalAmount);
      } else {
        setShowTimeSelectModal(true);
      }
    }
  };
  const setJoinCall = () => {
    setShowIncompleteCopayments(false);
    setShowTimeSelectModal(true);
  };

  const setShowOngoingCallRefreshModalFunc = (showModal: boolean) => {
    setShowOngoingCallRefreshModal(showModal);
  };

  const messageDisplayComponent = () => (
    <div>
      <div className={screenSize > SCREEN_SIZE ? "w-100 user-chat" : "no-panel"}>
        {/* <AddNewSessionModal addNewSessionPopUp={addNewSessionPopUp} userId={userId} userName={chatBoxUsername} setAddNewSessionPopUp={setAddNewSessionPopUp} /> */}
        <Card>
          <div className="call-title w-100 flex flex-row justify-content-between align-items-center chat-header-bg">
            <div className="container">
              <div className="row chat-header-row">
                <div className="col-8 d-flex justify-content-start align-items-center">
                  <div className="d-flex justify-content-start align-items-center p-2">
                    <div className=" d-flex justify-content-center align-items-center">
                      <img
                        src={currentRecipientUser?.photoId ? currentRecipientUser?.photoId?.url : defaultImage}
                        className="rounded-circle avatar-xs imageFit"
                      />
                    </div>
                    <h5 className="mb-0 d-flex ms-2 justify-content-start fs-14 align-items-start fc-black">{chatBoxUsername}</h5>
                  </div>
                </div>
                <div className="col-4 chat-close-icon-container">
                  <img className="cursor-pointer" src={CloseIcon} onClick={() => toggleChatModal(false, currentChat)} />
                </div>
              </div>
            </div>
          </div>
          {!isLoadingConversationList && (
            <div>
              <div className="chat-conversation p-3">
                <ul className="list-unstyled">
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 470,
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                    ref={(ref: any) => setMessageBox(ref)}
                  >
                    <InfiniteScroll
                      hasMore={true}
                      next={() => /* fetchPreviousMessages(messageRequest) */ {
                        getMessagesByChatId(chatId, messagesOffsetPageNo + 1);
                        setMessagesOffsetPageNo(messagesOffsetPageNo + 1);
                      }}
                      inverse={true}
                      loader={null}
                      dataLength={messageList.length}
                      scrollableTarget="scrollableDiv"
                    >
                      {messageList.length > 0 &&
                        messageList.map((message: any, index: number) => (
                          <DisplayMessageComp
                            currentUser={currentRecipientUser!}
                            message={message}
                            key={index}
                            openReplyPanel={openReplyPanel}
                            handleCopy={handleCopy}
                            deleteMessage={deleteMessage}
                          />
                        ))}
                    </InfiniteScroll>
                  </div>
                </ul>
              </div>

              {showEmojiPanel && (
                <div className="emoji-panel1 position-absolute" style={{ zIndex: 9999 }}>
                  <Picker onEmojiClick={onEmojiClick} />
                </div>
              )}

              {showReplyPanel && (
                <>
                  <div className="reply-container">
                    <ReplyMessageDisplayComp
                      selectedMessage={selectedMessage}
                      setSelectedMessage={setSelectedMessage}
                      setShowReplyPanel={setShowReplyPanel}
                      showReplyPanel={showReplyPanel}
                    />
                  </div>
                </>
              )}

              {uploadedFile.preview && fileType == "IMAGE" ? (
                <>
                  <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                    <div className="upload-area">
                      <div className="chat-image-remove-button">
                        <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                      </div>
                      <div className="chat-upload-preview-container">
                        <div
                          className="chat-upload-preview imageFit pr-0 form-control"
                          style={{
                            backgroundImage: uploadedFile.preview == null || uploadedFile.preview == undefined ? "" : `url(${uploadedFile.preview})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : uploadedFile.preview == "" && fileType == "FILE" ? (
                <>
                  <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                    <div className="upload-area">
                      <div className="chat-image-remove-button">
                        <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                      </div>
                      <div className="chat-upload-preview-container">
                        <i className="icon-copy fa fa-file iconFiles iconEdit p-2" style={{ fontSize: "70px" }}></i>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="p-3 chat-input-section input-chat">
                <div className="input-chatDiv">
                  <li className="list-inline-item" style={{ color: "#fd7f00", marginRight: "10px" }}>
                    <div
                      className="mdi mdi-emoticon-happy-outline pointer1"
                      id="Imagetooltip2"
                      onClick={() => {
                        openEmojiPanel();
                      }}
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      <UncontrolledTooltip placement="bottom" target="Imagetooltip2">
                        Pick an Emoji
                      </UncontrolledTooltip>
                    </div>
                  </li>
                  <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                    <div
                      className="mdi mdi-file-image-outline pointer1"
                      id="Imagetooltip"
                      onClick={onIconClick}
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      <input type="file" id="image-input" style={{ display: "none" }} onChange={onFileChange} />
                      <UncontrolledTooltip placement="bottom" target="Imagetooltip">
                        Attach a file
                      </UncontrolledTooltip>
                    </div>
                  </li>
                </div>
                <div className="input-chattext">
                  <div className="">
                    <div className="chat-input-editor">
                      <CKEditor
                        editor={Editor}
                        onReady={(editor: any) => {
                          editorRef.current = editor;
                          editor.focus();
                        }}
                        data={""}
                        config={editorConfiguration}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-chat-btns">
                  <Button
                    type="button"
                    disabled={isSending}
                    color="primary"
                    onClick={() => addMessage()}
                    className="btn btn-primary btn-rounded chat-send"
                    style={{ marginTop: "5px" }}
                  >
                    <span className="d-none d-sm-inline-block me-2">Send</span>
                    <i className="mdi mdi-send" />
                  </Button>
                  &nbsp;&nbsp;
                  {user?.role === Role.THERAPIST && (
                    <Button
                      type="button"
                      color="info"
                      onClick={() => {
                        joinZoomCall();
                      }}
                      className="btn btn-primary btn-rounded chat-send"
                      style={{ marginTop: "5px" }}
                    >
                      <span className="d-none d-sm-inline-block me-2">{fileType === "IMAGE" || fileType === "FILE" ? "Call" : "Start Call"}</span>
                      <i className="mdi mdi-video"></i>
                    </Button>
                  )}
                </div>
              </div>
              {isRecipientTyping?.isTyping && isRecipientTyping?.chatId === currentChat?._id ? <p>{currentRecipientUser?.firstname} typing...</p> : null}
            </div>
          )}

          {isLoadingConversationList && (
            <div>
              <div className="chat-conversation p-3">
                <ul className="list-unstyled">
                  <div
                    style={{
                      height: 470,
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <div className="group-chat-empty-view">
                      <Spinner />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          )}

          {/* {showModal && <MessageModal message="Please subscribe to a provided plan to access this feature." setShowModal={setShowModal} showModal={showModal} />} */}
          {/* {isHomeWorkOpen && <HomeWorksAndGoals userId={userId} isOpened={isHomeWorkOpen} returnBool={(e: boolean) => setIsHomeWorkOpen(e)} />} */}
          {/* {isUploadDocumentsOpen && <UploadDocuments userId={userId} isOpened={isUploadDocumentsOpen} returnBool={(e: boolean) => setIsUploadDocumentsOpen(e)} />} */}
        </Card>
      </div>

      {screenSize < SCREEN_SIZE ? (
        <Modal isOpen={showChatModal} centered className="modal-lg">
          <div className={screenSize > SCREEN_SIZE ? "w-100 user-chat" : /* "no-panel" */ ""}>
            {/* <AddNewSessionModal addNewSessionPopUp={addNewSessionPopUp} userId={userId} userName={chatBoxUsername} setAddNewSessionPopUp={setAddNewSessionPopUp} /> */}
            <Card>
              <div className="call-title w-100 flex flex-row justify-content-between align-items-center chat-header-bg">
                <div className="container">
                  <div className="row chat-header-row">
                    <div className="col-8 d-flex justify-content-start align-items-center">
                      <div className="d-flex justify-content-start align-items-center p-2">
                        <div className=" d-flex justify-content-center align-items-center">
                          <img
                            src={currentRecipientUser?.photoId ? currentRecipientUser?.photoId?.url : defaultImage}
                            className="rounded-circle avatar-xs imageFit"
                          />
                        </div>
                        <h5 className="mb-0 d-flex ms-2 justify-content-start fs-14 align-items-start fc-black">{chatBoxUsername}</h5>
                      </div>
                    </div>
                    <div className="col-4 chat-close-icon-container">
                      <img className="cursor-pointer" src={CloseIcon} onClick={() => toggleChatModal(false, currentChat)} />
                    </div>
                  </div>
                </div>
              </div>
              {!isLoadingConversationList && (
                <div>
                  <div className="chat-conversation p-3">
                    <ul className="list-unstyled">
                      <div
                        id="scrollableDivPopUp"
                        style={{
                          height: 470,
                          overflow: "auto",
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                        ref={(ref: any) => setMessageBoxPopUp(ref)}
                      >
                        <InfiniteScroll
                          hasMore={true}
                          next={() => /* fetchPreviousMessages(messageRequest) */ {
                            getMessagesByChatId(chatId, messagesOffsetPageNo + 1);
                            setMessagesOffsetPageNo(messagesOffsetPageNo + 1);
                          }}
                          inverse={true}
                          loader={null}
                          dataLength={messageList.length}
                          scrollableTarget="scrollableDivPopUp"
                        >
                          {messageList.length > 0 &&
                            messageList.map((message: any, index: number) => (
                              <DisplayMessageComp
                                currentUser={currentRecipientUser!}
                                message={message}
                                key={index}
                                openReplyPanel={openReplyPanel}
                                handleCopy={handleCopy}
                                deleteMessage={deleteMessage}
                              />
                            ))}
                        </InfiniteScroll>
                      </div>
                    </ul>
                  </div>

                  {showEmojiPanel && (
                    <div className="emoji-panel-pop-up position-absolute" style={{ zIndex: 9999 }}>
                      <Picker onEmojiClick={onEmojiClick} />
                    </div>
                  )}

                  {showReplyPanel && (
                    <>
                      <div className="reply-container">
                        <ReplyMessageDisplayComp
                          selectedMessage={selectedMessage}
                          setSelectedMessage={setSelectedMessage}
                          setShowReplyPanel={setShowReplyPanel}
                          showReplyPanel={showReplyPanel}
                        />
                      </div>
                    </>
                  )}

                  {uploadedFile.preview && fileType == "IMAGE" ? (
                    <>
                      <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                        <div className="upload-area">
                          <div className="chat-image-remove-button">
                            <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                          </div>
                          <div className="chat-upload-preview-container">
                            <div
                              className="chat-upload-preview imageFit pr-0 form-control"
                              style={{
                                backgroundImage: uploadedFile.preview == null || uploadedFile.preview == undefined ? "" : `url(${uploadedFile.preview})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : uploadedFile.preview == "" && fileType == "FILE" ? (
                    <>
                      <div className="upload-container position-absolute" style={{ zIndex: 9999, marginBottom: "130px" }}>
                        <div className="upload-area">
                          <div className="chat-image-remove-button">
                            <i className="fa fa-times-circle pointer1" onClick={removeFile}></i>
                          </div>
                          <div className="chat-upload-preview-container">
                            <i className="icon-copy fa fa-file iconFiles iconEdit p-2" style={{ fontSize: "70px" }}></i>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="p-3 chat-input-section">
                    <Row>
                      <Col className="col-auto">
                        <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                          <div
                            className="mdi mdi-emoticon-happy-outline pointer1"
                            id="Imagetooltip2"
                            onClick={() => {
                              openEmojiPanel();
                            }}
                            style={{
                              fontSize: "25px",
                            }}
                          >
                            <UncontrolledTooltip placement="bottom" target="Imagetooltip2">
                              Pick an Emoji
                            </UncontrolledTooltip>
                          </div>
                        </li>
                        <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                          <div
                            className="mdi mdi-file-image-outline pointer1"
                            id="Imagetooltip"
                            onClick={onIconClick}
                            style={{
                              fontSize: "25px",
                            }}
                          >
                            <input type="file" id="image-input" style={{ display: "none" }} onChange={onFileChange} />
                            <UncontrolledTooltip placement="bottom" target="Imagetooltip">
                              Attach a file
                            </UncontrolledTooltip>
                          </div>
                        </li>
                      </Col>

                      <Col className="col" style={{ padding: "0px" }}>
                        <div className="">
                          <div className="chat-input-editor">
                            <CKEditor
                              editor={Editor}
                              onReady={(editor: any) => {
                                editorRef.current = editor;
                                editor.focus();
                              }}
                              data={""}
                              config={editorConfiguration}
                            />
                          </div>
                        </div>
                      </Col>

                      {fileType == "IMAGE" || fileType == "FILE" ? (
                        <Col className="col-auto m-auto">
                          <Button
                            type="button"
                            color="primary"
                            disabled={isSending}
                            onClick={() => addMessage()}
                            className="btn btn-primary btn-rounded chat-send w-md "
                          >
                            {/* <span className="d-none d-sm-inline-block me-2">Send</span> */}
                            <i className="mdi mdi-send" />
                          </Button>
                          &nbsp;&nbsp;
                          {user?.role == Role.THERAPIST && (
                            <Button
                              type="button"
                              color="info"
                              onClick={() => {
                                joinZoomCall();
                              }}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              {/* <span className="d-none d-sm-inline-block me-2">Start Video Call</span> */}
                              <i className="mdi mdi-video"></i>
                            </Button>
                          )}
                        </Col>
                      ) : (
                        <Col className="col-auto">
                          <Button
                            type="button"
                            color="primary"
                            disabled={isSending}
                            onClick={() => addMessage()}
                            className="btn btn-primary btn-rounded chat-send w-md "
                          >
                            <span className="d-none d-sm-inline-block me-2">Send</span>
                            <i className="mdi mdi-send" />
                          </Button>
                          &nbsp;&nbsp;
                          {user?.role == Role.THERAPIST && (
                            <Button
                              type="button"
                              color="info"
                              onClick={() => {
                                joinZoomCall();
                              }}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">Call</span>
                              <i className="mdi mdi-video"></i>
                            </Button>
                          )}
                        </Col>
                      )}
                    </Row>
                    {isRecipientTyping?.isTyping && isRecipientTyping?.chatId == currentChat?._id ? <p>{currentRecipientUser?.firstname} typing...</p> : null}
                  </div>
                </div>
              )}
              {isLoadingConversationList && (
                <div>
                  <div className="chat-conversation p-3">
                    <ul className="list-unstyled">
                      <div
                        style={{
                          height: 470,
                          overflow: "auto",
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                      >
                        <div className="group-chat-empty-view">
                          <Spinner />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              )}

              {/* {showModal && <MessageModal message="Please subscribe to a provided plan to access this feature." setShowModal={setShowModal} showModal={showModal} />} */}
              {/* {isHomeWorkOpen && <HomeWorksAndGoals userId={userId} isOpened={isHomeWorkOpen} returnBool={(e: boolean) => setIsHomeWorkOpen(e)} />} */}
              {/* {isUploadDocumentsOpen && <UploadDocuments userId={userId} isOpened={isUploadDocumentsOpen} returnBool={(e: boolean) => setIsUploadDocumentsOpen(e)} />} */}
            </Card>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {showOngoingCallRefreshModal && <OngoingCallRefreshModal showModal={showOngoingCallRefreshModal} setShowModal={setShowOngoingCallRefreshModalFunc} />}
      {showTimeSelectModal && <Modal6 setShowModal={startInstantMeetingInitial} showModal={showTimeSelectModal} />}
      {showImcompleteCoPayments && (
        <Modal29
          setShowModal={setShowIncompleteCopayments}
          showModal={showImcompleteCoPayments}
          copaymentAmount={coPaymentAmount}
          clientId={chattingWith}
          setCallModal={setJoinCall}
        />
      )}
      <Card className="w-100">
        <CardBody className="m-p-zero">
          <div className="flex mb-3">
            <span className="rounded-icon-bg-n d-flex justify-content-center align-items-center">
              <img className="icon-h-20" src={ChatIcon} />
            </span>
            <h5 className="mb-0 d-flex justify-content-center align-items-center ms-3 fc-black">Quick Chat</h5>
          </div>
          <div className="d-flex flex-column flex-md-row">
            <div className="col-md-6 col-12">
              <div className="pe-md-3">
                <div className=" custom-vertical-scroll" style={{ maxHeight: "470px" }}>
                  {conversationList?.length == 0 ? (
                    isLoading ? (
                      <div className="flex justify-content-center mt-5">
                        <img src={images.cubicLoader} style={{ width: "90px" }} />
                      </div>
                    ) : (
                      <div className={screenSize > SCREEN_SIZE ? "bg-white chat-body1" : "no-panel"}>
                        <div className="d-flex justify-content-center h-100 align-items-center" style={{ fontSize: "18px", padding: "10px" }}>
                          <p>No quick chats.</p>
                        </div>
                      </div>
                    )
                  ) : (
                    conversationList?.map(
                      (conversation: any, index: number) =>
                        conversation?.chat.members?.find((u: any) => u._id !== user?._id)?.firstname != null && (
                          <Card
                            className={conversation?.unreadMessageCount > 0 ? "bg-light-dark-orange cursor-pointer" : "bg-light-gray cursor-pointer"}
                            key={index}
                            onClick={() => toggleChatModal(true, conversation, 20)}
                          >
                            <CardBody>
                              <div className="row">
                                <div className="col-2 d-flex justify-content-center align-items-center">
                                  <img
                                    src={
                                      conversation?.chat?.members?.find((u: any) => u._id !== user?._id)?.photoId
                                        ? conversation?.chat?.members?.find((u: any) => u._id !== user?._id)?.photoId?.url
                                        : defaultImage
                                    }
                                    className="rounded-circle avatar-xs imageFit"
                                  />
                                </div>
                                <div className="col-10 d-flex justify-content-between">
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <h5 className="mb-0 d-flex justify-content-start fs-14 align-items-start fc-black">
                                        {conversation?.chat.members?.find((u: any) => u._id !== user?._id)?.firstname}{" "}
                                        {conversation?.chat.members?.find((u: any) => u._id !== user?._id)?.lastname}
                                      </h5>
                                      {/* <span className="btn btn-secondary video-btn-bg2 convert-pending-note btn-sm ms-2">
                                                      Start Session <img src={videoIcon} className="ai-icon-size2 " />
                                                  </span> */}
                                    </div>
                                    <p className="text-truncate mb-0" style={{ width: "150px" }}>
                                      {conversation &&
                                      conversation.chat &&
                                      conversation.chat.unreadMessage &&
                                      conversation.chat.unreadMessage.lastMessage &&
                                      conversation.chat.unreadMessage.lastMessage.text
                                        ? Util.htmlToTextFunction(DoDecrypt(conversation?.chat.unreadMessage?.lastMessage?.text))
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-column justify-content-end align-items-end ">
                                    {innerWidth > 450 && conversation?.chat.unreadMessage?.lastMessage?.createdAt && (
                                      <p className="mb-0">{moment(conversation?.chat.unreadMessage?.lastMessage.createdAt).format("MM/DD/YYYY h:mm:ss A")}</p>
                                    )}
                                    {/* <p className="mb-0">{moment(conversation?.chat.unreadMessage?.lastMessage.createdAt).format("MM/DD/YYYY h:mm:ss A")}</p> */}
                                    {conversation?.unreadMessageCount > 0 && (
                                      <div className="notofication-icon-dashboard d-flex justify-content-center align-items-center">
                                        <span className="fs-10">{conversation?.unreadMessageCount}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        )
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              {showChatModal ? (
                messageDisplayComponent()
              ) : (
                <div className={screenSize > SCREEN_SIZE ? "bg-white chat-body22" : "no-panel"}>
                  {/* <div className="card-height1"> */}
                  <div className="d-flex justify-content-center h-100 align-items-center" style={{ fontSize: "18px", padding: "10px" }}>
                    <p>Select someone to chat with.</p>
                    {/* </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TherapistQuickChat;
