import React, { useState, useContext, useEffect } from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";
import UserContext from "src/context/UserContext";
import "./Modal6.scss";

const OngoingCallRefreshModal: React.FC<{
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}> = props => {
  const [user] = useContext(UserContext);

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button
            type="button"
            onClick={() => props.setShowModal(false)}
            style={{ fontSize: "12px", width: "20px", height: "20px", padding: "2px", margin: "2px" }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">There is an ongoing meeting, Refresh the page to join the meeting</CardTitle>

            <div className="d-flex justify-content-center flexColumn">
              <button type="button" onClick={() => window.location.reload()} className="btn btn-primary mr-20 btnMargin callBtn">
                Reload to connect
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default OngoingCallRefreshModal;
