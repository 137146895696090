import React, { useState } from "react";
import { Container, Row, Card, CardTitle } from "reactstrap";
import "../../assets/css/admin.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import { Util } from "../../Util";

const CreateThemeImage: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const SUPPORTED_IMAGE_FORMATS = ["jpg", "png", "jpeg", "tiff", "webp"];

  const back = () => {
    history.back();
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const selectCoverFile = (event: any) => {
    if (event.target.files.length) {
      const extension = event.target.files[0].name.split(".").pop().toLowerCase();
      const isAnImage = isSupported(SUPPORTED_IMAGE_FORMATS, extension);

      if (isAnImage) {
        setShowProgress(true);
        const formData: FormData = new FormData();
        for (const file of event.target.files) {
          if (event.target.files) {
            formData.append("themeImage", file);
          }
        }
        axios
          .post(Util.apiAuthUrl(`updateThemeImage`), formData, {
            onUploadProgress: data => {
              setProgress(Math.round((100 * data.loaded) / data.total));
            },
          })
          .then((res: any) => {
            if (res.success) {
              toast.success("Theme Image Uploaded!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              setProgress(0);
              window.history.back();
            } else {
              toast.error(res.data.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });

              return false;
            }
          })
          .catch(error => {
            if (error) {
              toast.error("API Error! Failed to upload the profile cover banner!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          });

        return false;
      } else {
        toast.error("Only images are supported!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
          <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
          <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
        </div>
        <Container className="containerOnboarding mt-5">
          {/* <Breadcrumbs title={"Create Ethnicity"} breadcrumbItem={"Create Ethnicity"} /> */}
          <Card className="BorderRadiusLeftRight pt-4 pl-3 pb-2">
            <div className="card-bodyDiv">
              <Row>
                <label
                  htmlFor="file-upload"
                  className="file-upload relative flex p-5 flex-col text-gray-400 z-10  rounded cursor-pointer"
                  style={{
                    margin: "auto",
                    borderRadius: "0.25rem",
                    cursor: "pointer",
                    display: "flex",
                    zIndex: "10",
                    position: "relative",
                  }}
                >
                  <div
                    className="btn-video relative mt-2 pl-3 pr-3 pt-2 pb-2 p-5 mb-4 cursor-pointer"
                    style={{
                      bottom: "0",
                      textAlign: "center",
                    }}
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 24 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 content-center items-center justify-center relative"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <path
                        d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                        fill="#fd7f00"
                      />
                      <path
                        d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                        fill="#fd7f00"
                      />
                    </svg>
                    Upload Theme Image
                  </div>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                text/plain, image/doc"
                    onChange={selectCoverFile}
                    multiple
                  />
                </label>
              </Row>
              <Row>
                {showProgress && (
                  <div className="profile-video2">
                    Uploading &nbsp; <span className="progress-color">{progress}%</span>
                  </div>
                )}
              </Row>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateThemeImage;
