import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Card, CardBody, ModalBody, Modal, ModalHeader, FormGroup, Input, Label, } from "reactstrap";
import { AdminService } from "src/services/AdminService";
import { BlockedDate, Therapist } from "src/models/Therapist";
import "src/assets/css/admin.css";
import Spinner from "src/common/spinner/spinner";
import { Client, PremiumStatus, testSubscriptionStatus } from "src/models/Client";
import { SocketContext } from "src/context/ScoketContext";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Appointment, ApprovalStatus, Repeat } from "src/models/Appointment";
import { AppointmentService } from "src/services/AppointmentService";
import moment from "moment";
import { TherapistService } from "src/services/TherapistService";
import user1 from "src/assets/images/default_profile.png";
import profileBackground from "src/assets/images/default_cover.png";
import { TwitterPicker } from "react-color";
import UserContext from "src/context/UserContext";
import { Role } from "src/models/Role";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { WorkingHour } from "src/models/WorkingHour";
import { NotificationModel, NotificationEvent, NotificationVarient } from "src/models/Notification";
import { NotificationService } from "src/services/NotificationService";
import MessageModal from "src/pages/Popup/MessageModal";
import Swal from "sweetalert2";
import images from "src/assets/images";
import { Util } from "src/Util";
import Modal4 from "src/pages/Popup/Modal4";
import Modal5 from "src/pages/Popup/Modal5";
import Modal11 from "src/pages/Popup/Modal11";
import Modal12 from "src/pages/Popup/Modal12";
import Modal13 from "src/pages/Popup/Modal13";
import Modal14 from "src/pages/Popup/Modal14";
import { ClientService } from "src/services/ClientService";
import Modal15 from "src/pages/Popup/Modal15";
import Modal17 from "src/pages/Popup/Modal17";
import Modal18 from "src/pages/Popup/Modal18";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import DatePicker from "react-date-picker";
import CustomTimePickerForAdmin from "./CustomTimePickerForAdmin";
import momentTimezone from "moment-timezone";

type appointmentProps = {
  clientData: Client;
};

const ScheduleAppointmentsAdmin: React.FC<appointmentProps> = ({ clientData }) => {
  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [offset, setOffset] = useState(0);
  const limit = 4;
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchableString, setIsSearchableString] = useState({ searchableString: null, status: null, zipCode: null });
  const [userCount, setUserCount] = useState(5);
  const [isView, setIsView] = useState<boolean>(true);
  const [isView2, setIsView2] = useState<boolean>(true);
  const [selectTherapist, setSelectedTherapist] = useState<Therapist>({} as Therapist);
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [isCalender, setIsCalender] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [user] = useContext(UserContext);
  const calendarComponentRef: any = React.createRef();
  const [isEventModal, setIsEventModal] = useState(false);
  const [isEventViewModal, setIsEventViewModal] = useState(false);
  const [haveRead, setHaveRead] = useState(false);
  const [haveAgreed, setHaveAgreed] = useState(false);
  const [haveAgreedPolicy, setHaveAgreedPolicy] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [appointments, setAppointments] = useState([] as any[]);
  const [blockDatesList, setBlockDatesList] = useState([] as any[]);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: "",
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: ""
  });

  const socket = useContext(SocketContext);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");

  const [workingDaysOfTherapist, setWorkingDaysOfTherapist] = useState<string[]>([]);

  const [therapist, setTherapis] = useState({} as Therapist);

  const [appointmentDetails, setAppointmentDetails] = useState<Appointment>();

  const [isHours, setIsHours] = useState<string[]>([]);

  const [isAvailableHours, setIsAvailableHours] = useState([] as any);

  const locale = "en";
  const hours: string[] = [];

  const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(false);

  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [showTooEarly, setShowTooEarly] = useState(false);
  const [appointmentCreated, setAppointmentCreated] = useState(false);
  const [appointmentUpdated, setAppointmentUpdated] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [viewTab, setViewTab] = useState<number>(1);

  useEffect(() => {
    setOffset(0)
    getTherapists();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);

    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, []);

  useEffect(() => {
    getAllAppointmentsBySelectedTherapistId();
  }, [selectTherapist]);

  const hideAndShow = () => {
    setIsView(!isView);
    setIsView2(true);
  };

  const hideAndShow2 = () => {
    setIsView2(!isView2);
    setIsView(true);
  };

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  const getTherapists = () => {
    const data2 = {
      clientId: clientData?._id,
      searchableString: isSearchableString.searchableString
    };

    setIsLoading(true);

    AdminService.searchMatchedTherapistsByAdmin(data2, limit, 0 * limit).then(res => {
      if (res.success) {
        setTherapists(res.data.userSet);
        setUserCount(res.data.count);
        setOffset(0);
      }

      setIsLoading(false);
    });
  };

  const searchTherapists = () => {
    const data2 = {
      clientId: clientData?._id,
      searchableString: isSearchableString.searchableString
    };

    setIsLoading(true);

    AdminService.searchMatchedTherapistsByAdmin(data2, limit, 0 * limit).then(res => {
      if (res.success) {
        setTherapists(res.data.userSet);
        setUserCount(res.data.count);
        setOffset(0);
      }

      setIsLoading(false);
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    const data = {
      clientId: clientData?._id,
      searchableString: isSearchableString.searchableString
    };

    AdminService.searchMatchedTherapistsByAdmin(data, limit, updatedSkip * limit).then(res => {
      setIsLoading(true);

      if (res.success) {
        setTherapists([...therapists, ...res.data.userSet]);
        setUserCount(res.data.count);
      }

      setIsLoading(false);
    });
  };

  const next = (therapist: any) => {
    setSelectedTherapist(therapist);
    setViewTab(2);

    if (therapist._id.length == undefined) {
      return toast.error(`Please select therapist.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (clientData._id?.length == undefined) {
      return toast.error(`Please select client.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      ClientService.checkIfUserIsFriendAdmin(clientData._id, therapist._id).then(res => {
        if (res.success) {
          setIsFriend(true);
          setIsCalender(true);

          TherapistService.getTherapistDetailsbyIdAdmin(therapist._id as string, clientData._id as string).then(res => {
            if (res.success) {
              setIsLoading(false);
              setTherapis(res.data);
              checkAvailableTime(res.data?.workingHours);
              getWorkingDaysOfWeek(res.data?.workingHours);

              const blockedSlots = res.data?.blockedDates || [];

              setBlockDatesList(blockedSlots);

              getAllAppointmentsBySelectedTherapistId(blockedSlots);
            }
          });

          createHoursForTimeSlots();
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setIsFriend(false);
          setIsLoading(false);
        }
      });
    }
  };

  const getAvailableTimeBySelectedDay = (dayNumber: number, isAvailableHours: any) => {
    const availableHours: string[] = [];

    isAvailableHours
      .filter((obj: any) => obj.daysOfWeek[0] === dayNumber)
      ?.map((obj: any) => {
        for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
          if (hour == parseInt(obj.startTime)) {
            const mST = moment(obj.startTime, "HH:mm").minute();

            if (mST == 0) {
              availableHours.push(moment({ hour }).format("H:mm A"));
            }
          } else {
            availableHours.push(moment({ hour }).format("H:mm A"));
          }

          if (hour != parseInt(obj.endTime)) {
            availableHours.push(
              moment({
                hour,
                minute: 30,
              }).format("H:mm A")
            );
          } else {
            const mET = moment(obj.endTime, "HH:mm").minute();

            if (mET == 30) {
              availableHours.push(
                moment({
                  hour,
                  minute: 30,
                }).format("H:mm A")
              );
            }
          }
        }
      });

    return availableHours;
  };

  const createHoursForTimeSlots = () => {
    moment.locale(locale);

    for (let hour = 0; hour < 24; hour++) {
      hours.push(moment({ hour }).format("H:mm A"));
      hours.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }
    setIsHours(hours);
  };

  const getAllAppointmentsBySelectedTherapistId = (blockedSlots?: BlockedDate[]) => {
    AppointmentService.getAllAppointmentByTherapistIdAdmin(selectTherapist?._id).then(res => {
      res.data?.map((item: any) => {
        if (item.clientId?._id == clientData?._id) {
        } else {
          item.color = "#665a5a";
        }
      });

      if (res.data) {
        if (blockedSlots) {
          setAppointments([...res.data, ...blockedSlots]);
        } else if (selectTherapist.blockedDates) {
          setAppointments([...res.data, ...selectTherapist.blockedDates]);
        } else {
          setAppointments([...res.data, ...blockDatesList]);
        }
      }
    });
  };

  const eventModalToggle = () => {
    setIsEventModal(!isEventModal);
  };

  const eventViewModalToggle = () => {
    setIsEventViewModal(!isEventViewModal);
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const handleChangeComplete = (color: any) => {
    appointment.color = color.hex;
  };

  const addEvent = (selectedDetails: any) => {
    const selectedDate = moment(selectedDetails.startStr).toISOString();

    const isInRange = blockDatesList.some(range => {
      return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate));
    });

    if (isInRange) {
      return false;
    }

    setIsUpdate(false);

    if (
      clientData &&
      ((clientData.subscriptionId != null && clientData.subscriptionStatus == "active") ||
        clientData.premiumStatus == PremiumStatus.ACTIVE ||
        clientData.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
    ) {
      if (selectedDetails) {
        const isAvailableHoursArray = checkAvailableTime(therapist.workingHours);

        const dayNumber = dayOfWeekAsNumber(moment(selectedDetails.start).format("dddd"));

        const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHoursArray);

        if (
          (hoursSlots.length > 0 && !hoursSlots.includes(moment(selectedDetails.start).format("H:mm A"))) ||
          !hoursSlots.includes(moment(selectedDetails.end).format("H:mm A"))
        ) {
          Swal.fire({
            icon: "warning",
            title:
              therapist && therapist.firstname && therapist.lastname
                ? therapist?.firstname + " " + therapist?.lastname + " working hours are not within selected time. Do you still want to proceed?"
                : "Your therapist's working hours are not within selected time. Do you still want to proceed?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#50a5f1",
            cancelButtonText: "No",
            cancelButtonColor: "#f46a6a",
            allowOutsideClick: screenSize < 991 ? false : true,
          }).then(result => {
            if (result.isConfirmed) {
              openCreateAppointmentPopup(selectedDetails);
            }
          });
        } else {
          openCreateAppointmentPopup(selectedDetails);
        }
      }
    } else {
      setShowModalForTherapist(true);
    }
  };

  const openCreateAppointmentPopup = (selectedDetails: any) => {
    AppointmentService.getAllAppointmentAndDetailsByUserIdAdmin(
      Util.calculateWeekNumberAndDates(selectedDetails?.start, clientData._id! as any, selectTherapist?._id)
    ).then(res => {
      if (res.success) {
        if (res.data && res.data.sessionTimeOfWeek >= 60 && clientData && Util.skipCheckingForPremiumUsers(clientData)) {
          setErrorModalText("Client's weekly session time has exceeded.");
          return setEventTimeValidationViewModal(true);
        } else if (res.data && res.data.sessionTimeOfWeek < 60) {
          if (!selectedDetails.start) {
            return toast.error("Please select valid date.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          if (!moment(new Date()).isBefore(moment(selectedDetails.start))) {
            return toast.error(`Sorry! You can't create appointment in a past date!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        }

        if (clientData?.role == Role.CLIENT) {
          setTimeValue(selectedDetails.start.toString());
          setHourValue(moment(selectedDetails.start).hour());
          setMinuteValue(moment(selectedDetails.start).minute());

          setAppointment({
            ...appointment,
            date: moment(selectedDetails.start).format(),
            startTime: moment(selectedDetails.start).format("H:mm A"),
            endTime: moment(selectedDetails.start).add(60, "minutes").format("H:mm A"),
            selectedDate: moment(selectedDetails.start).toISOString()
          });

          setIsEventModal(!isEventModal);
        }
      }
    });
  };

  const viewEvent = (event: any) => {
    try {
      if (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN) {
        if (clientData) {
          const appointmentId = event.event._def.extendedProps._id;

          AppointmentService.viewSingleAppointmentAdmin(appointmentId).then((res: any) => {
            if (res.success) {
              setAppointmentDetails(res.data);
              setIsEventViewModal(true);
            } else {
              setErrorModalText(res.error);
              setAppointmentDetails(undefined);
              setEventTimeValidationViewModal(true);
            }
          });
        } else {
          toast.error(`Invalid client data !`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        if (
          clientData &&
          ((clientData.subscriptionId != null && clientData.subscriptionStatus == "active") ||
            clientData.premiumStatus == PremiumStatus.ACTIVE ||
            clientData.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
        ) {
          const appointmentId = event.event._def.extendedProps._id;

          AppointmentService.viewSingleAppointmentAdmin(appointmentId).then((res: any) => {
            if (res.success) {
              setAppointmentDetails(res.data);
              setIsEventViewModal(true);
            } else {
              setErrorModalText(res.error);
              setAppointmentDetails(undefined);
              setEventTimeValidationViewModal(true);
            }
          });
        } else {
          setShowModalForTherapist(true);
        }
      }
    } catch (error) {
      toast.error(`Something went wrong !`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getWorkingDaysOfWeek = (workingHours: any) => {
    const workingDays: string[] = [];
    workingHours?.map((session: any) => {
      if (!workingDays.includes(session.day)) workingDays.push(session.day);
    });

    const dup = [...new Set(workingDays)];
    setWorkingDaysOfTherapist(dup);
  };

  const checkAvailableTime = (workingHoursOfTherapist: WorkingHour[] | undefined) => {
    const availableDays: { startTime: any; endTime: any; daysOfWeek: any[] }[] = [];

    if (workingHoursOfTherapist?.length) {
      workingHoursOfTherapist?.map((obj: any) => {
        const dayAsNumber = dayOfWeekAsNumber(obj.day);

        if (Util.convertUTCDateToLocalDate(obj.endTime) > Util.convertUTCDateToLocalDate(obj.startTime)) {
          return availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        } else {
          availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: "24:00 AM",
            daysOfWeek: [dayAsNumber - 1],
          });

          return availableDays.push({
            startTime: "00:00 AM",
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        }
      });
    } else {
      availableDays.push({
        startTime: "00:00 AM",
        endTime: "00:00 PM",
        daysOfWeek: [10]
      });
    }

    setIsAvailableHours(availableDays);

    return availableDays;
  };

  const createAppointment = () => {
    if (
      clientData &&
      ((clientData.subscriptionId != null && clientData.subscriptionStatus == "active") ||
        clientData.premiumStatus == PremiumStatus.ACTIVE ||
        clientData.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
    ) {
      const selectedDate = moment(appointment.selectedDate);

      if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
        setErrorModalText("You have selected invalid time.");

        return setEventTimeValidationViewModal(true);
      }

      const isInRange = blockDatesList.some(range => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
      });

      if (isInRange) {
        setErrorModalText("Sorry! Therapist `" + therapist.firstname + " " + therapist.lastname + "` has blocked this date.");

        return setEventTimeValidationViewModal(true);
      }

      AppointmentService.getAllAppointmentAndDetailsByUserIdAdmin(
        Util.calculateWeekNumberAndDates(appointment.date, clientData._id!, selectTherapist._id)
      ).then(res => {
        if (res.success) {
          const timeStart: any = new Date(
            new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)
          );

          const timeEnd: any = new Date(
            new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)
          );

          let humanTime;

          if (timeStart > timeEnd) {
            const tStart1: any = new Date(appointment.date).setHours(
              parseInt(appointment.startTime.split(":")[0]),
              parseInt(appointment.startTime.split(":")[1]),
              0,
              0
            );

            const tEnd1: any = new Date(appointment.date).setHours(24, 0, 0, 0);

            const ht1 = Math.abs(tEnd1 - tStart1) / 60000;

            const tStart2: any = new Date(appointment.date).setHours(0, 0, 0, 0);

            const tEnd2: any = new Date(appointment.date).setHours(
              parseInt(appointment.endTime.split(":")[0]),
              parseInt(appointment.endTime.split(":")[1]),
              0,
              0
            );

            const ht2 = Math.abs(tEnd2 - tStart2) / 60000;

            humanTime = ht1 + ht2;
          } else {
            humanTime = Math.abs(timeStart - timeEnd) / 60000;
          }

          if (humanTime > 60) {
            setErrorModalText("You can create only 30 mins and 1 hour sessions.");
            return setEventTimeValidationViewModal(true);
          }

          if (humanTime + res.data.sessionTimeOfWeek > 60 && Util.skipCheckingForPremiumUsers(clientData)) {
            setErrorModalText(`Selected client's session time has exceeded for the selected week.`);

            return setEventTimeValidationViewModal(true);
          } else {
            if (!appointment.startTime) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const mST = moment(appointment.startTime, "HH:mm").minute();

            if (mST != 0 && mST != 30) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!appointment.date) {
              return toast.error("Please select valid date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!appointment.title) {
              return toast.error(`Please add title.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!appointment.repeat) {
              return toast.error(`Pleas select repeat type.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveRead && therapist.disclosureStatementId?.url) {
              return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveAgreed) {
              return toast.error(`You must agree to the terms & conditions of Lavni Inc. before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveAgreedPolicy) {
              return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }


            if (!moment(new Date()).isBefore(appointment.date)) {
              return toast.error(`Sorry! You can't create appointment in a past date!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const dayNumber = dayOfWeekAsNumber(moment(appointment.date).format("dddd"));

            const isAvailableHoursArray = checkAvailableTime(therapist.workingHours);

            const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHoursArray);

            if (!hoursSlots.includes(appointment.startTime) || !hoursSlots.includes(appointment.endTime)) {
              Swal.fire({
                icon: "warning",
                title:
                  therapist && therapist.firstname && therapist.lastname
                    ? therapist?.firstname + " " + therapist?.lastname + " working hours are not within selected time. Do you still want to proceed?"
                    : "Your therapist's working hours are not within selected time. Do you still want to proceed?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#50a5f1",
                cancelButtonText: "No",
                cancelButtonColor: "#f46a6a",
                allowOutsideClick: screenSize < 991 ? false : true,
              }).then(result => {
                if (result.isConfirmed) {
                  addAppointmentByAdmin(true);
                }
              });
            } else {
              addAppointmentByAdmin(false);
            }
          }
        }
      });
    } else {
      setShowModalForTherapist(true);
    }
  };

  const addAppointmentByAdmin = (eventOnHolidays: boolean) => {
    setDisabledCreateBtn(true);

    const appointmentObj = {
      therapistId: selectTherapist?._id,
      clientId: clientData?._id,
      start: new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)),
      end: new Date(new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)),
      reminders: [30],
      title: appointment.title,
      repeatInfo: {
        repeatType: appointment.repeat,
        interval: "",
        repeatDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        endingDate: Util.monthsNextFrom(new Date(appointment.date), 1),
        endingAfter: 10,
        endingType: "",
      },
      color: appointment.color,
      groupId:
        clientData?._id +
        "_" +
        Util.dateConvertToMilisecond(
          new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
        ),
      eventOnHolidays: eventOnHolidays,
    };

    AppointmentService.createAppointmentByAdmin(appointmentObj).then(res => {
      if (res.success) {
        setIsEventModal(false);

        getAllAppointmentsBySelectedTherapistId();

        const appointmentNotification: NotificationModel = {
          senderId: clientData?._id,
          receiverId: selectTherapist?._id,
          event: NotificationEvent.APPOINMENT_CREATED,
          link: "/appointments",
          content: "Appointment Created by " + clientData?.firstname + " " + clientData?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(appointmentNotification).then(res => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: appointmentNotification,
            senderId: clientData?._id,
            receiverId: selectTherapist?._id,
          };

          socket.emit("send-notification", socketData);
        });

        setAppointmentCreated(true);

        setDisabledCreateBtn(false);
      } else {
        setErrorModalText(res.error as string);
        setDisabledCreateBtn(false);

        return setEventTimeValidationViewModal(true);
      }
    });
  };

  const updateAppointment = () => {
    if (
      clientData &&
      ((clientData.subscriptionId != null && clientData.subscriptionStatus == "active") ||
        clientData.premiumStatus == PremiumStatus.ACTIVE ||
        clientData.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
    ) {
      const selectedDate = moment(appointment.selectedDate);

      if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
        setErrorModalText("You have selected invalid time.");

        return setEventTimeValidationViewModal(true);
      }

      const isInRange = blockDatesList.some(range => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
      });

      if (isInRange) {
        setErrorModalText("Sorry! Therapist `" + therapist.firstname + " " + therapist.lastname + "` has blocked this date.");

        return setEventTimeValidationViewModal(true);
      }

      AppointmentService.getAllAppointmentAndDetailsByUserIdAdmin(
        Util.calculateWeekNumberAndDates(appointment.date, clientData._id!, selectTherapist?._id)
      ).then(res => {
        if (res.success) {
          if (
            res.data.sessionTimeOfWeek != 0 &&
            res.data.sessionTimeOfWeek != 60 &&
            Util.skipCheckingForPremiumUsers(clientData)
          ) {
            setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

            return setEventTimeValidationViewModal(true);
          }

          if (res.data.sessionTimeOfWeek != 0 && Util.skipCheckingForPremiumUsers(user)) {
            const meetingIsOnCurrentSelectedWeek = res.data.allSessionOfWeek.filter(obj => obj._id === appointment.id);

            if (res.data.sessionTimeOfWeek == 60) {
              if (meetingIsOnCurrentSelectedWeek.length == 0) {
                setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                return setEventTimeValidationViewModal(true);
              } else {
                if (res.data.allSessionOfWeek.length > 1) {
                  setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                  return setEventTimeValidationViewModal(true);
                }
              }
            }
          }

          if (!appointment.startTime) {
            return toast.error("Please select valid start time.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          const mST = moment(appointment.startTime, "HH:mm").minute();

          if (mST != 0 && mST != 30) {
            return toast.error("Please select valid start time.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          if (!appointment.date) {
            return toast.error("Please select valid date.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          if (
            !moment(new Date()).isBefore(
              moment(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
            )
          ) {
            return toast.error(`Sorry! You can't create appointment in a past date!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          const dayNumber = dayOfWeekAsNumber(moment(appointment.date).format("dddd"));

          const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHours);

          if (!hoursSlots.includes(appointment.startTime) || !hoursSlots.includes(appointment.endTime)) {
            Swal.fire({
              icon: "warning",
              title:
                therapist && therapist.firstname && therapist.lastname
                  ? therapist?.firstname + " " + therapist?.lastname + " working hours are not within selected time. Do you still want to proceed?"
                  : "Your therapist's working hours are not within selected time. Do you still want to proceed?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#50a5f1",
              cancelButtonText: "No",
              cancelButtonColor: "#f46a6a",
              allowOutsideClick: screenSize < 991 ? false : true,
            }).then(result => {
              if (result.isConfirmed) {
                updateOpenedAppointment(true);
              }
            });
          } else {
            updateOpenedAppointment(false);
          }
        } else {
          setErrorModalText(`No appointments found for this Therapist.`);
        }
      });
    } else {
      setShowModalForTherapist(true);
    }
  };

  const updateOpenedAppointment = (eventOnHolidays: boolean) => {
    if (!appointment.title) {
      return toast.error(`Please add title.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.repeat) {
      return toast.error(`Please select repeat type.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveRead && therapist.disclosureStatementId?.url) {
      return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreed) {
      return toast.error(`You must agree to the terms & conditions of Lavni  before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreedPolicy) {
      return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    setDisabledUpdateBtn(true);

    const obj = {
      appointmentId: appointment.id,
      therapistId: appointment.therapist._id,
      clientId: appointment.client._id,
      start: new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)),
      end: new Date(new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)),
      reminders: [30],
      title: appointment.title,
      repeatInfo: {
        repeatType: appointment.repeat,
        interval: "",
        repeatDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        endingDate: Util.monthsNextFrom(new Date(appointment.date), 1),
        endingAfter: 10,
        endingType: "",
      },
      color: appointment.color,
      groupId:
        clientData?._id +
        "_" +
        Util.dateConvertToMilisecond(
          new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
        ),
      eventOnHolidays: eventOnHolidays,
    };

    AppointmentService.updateAppointment(obj).then(res => {
      if (res.success) {
        setIsUpdate(false);
        setAppointmentUpdated(true);
        setIsEventModal(false);
        getAllAppointmentsBySelectedTherapistId();

        const appointmentNotification: NotificationModel = {
          senderId: clientData?._id,
          receiverId: selectTherapist?._id,
          event: NotificationEvent.APPOINMENT_UPDATED,
          link: "/appointments",
          content: "Appointment Created by " + clientData?.firstname + " " + clientData?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(appointmentNotification).then(res => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: appointmentNotification,
            senderId: clientData?._id,
            receiverId: selectTherapist?._id,
          };

          socket.emit("send-notification", socketData);
        });

        setDisabledUpdateBtn(false);
      } else {
        setDisabledUpdateBtn(false);
        setErrorModalText(res.error as string);
        return setEventTimeValidationViewModal(true);
      }
    });
  };

  const dayOfWeekAsNumber = (day: string) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
  };

  const deleteAppointment = (id: any | undefined) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
      allowOutsideClick: screenSize < 991 ? false : true,
    }).then(result => {
      if (result.isConfirmed) {
        const deletingAppointment = {
          appointmentId: id
        };

        AppointmentService.deleteAppointment(deletingAppointment).then(res => {
          if (res.success) {
            eventViewModalToggle();
            getAllAppointmentsBySelectedTherapistId();

            toast.success("Your appointment is deleted successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            const appointmentNotification: NotificationModel = {
              senderId: clientData?._id,
              receiverId: selectTherapist?._id,
              event: NotificationEvent.APPOINMENT_DELETED,
              link: "/appointments",
              content: "Appointment deleted by " + clientData?.firstname + " " + clientData?.lastname,
              variant: NotificationVarient.WARNING,
            };

            NotificationService.sendNotification(appointmentNotification);
          } else {
            Swal.fire({
              icon: "error",
              title: res.error ? res.error : "Something went wrong. Please try again later.",
              confirmButtonColor: "#FD7F00",
              allowOutsideClick: screenSize < 991 ? false : true,
            });
          }
        });
      }
    });
  };

  const editAppointment = (data: Appointment | undefined) => {
    if (data) {
      setIsUpdate(true);

      setAppointment({
        ...appointment,
        id: data._id || "",
        title: data.title,
        startTime: moment(data.start).format("H:mm A"),
        endTime: moment(data.end).format("H:mm A"),
        date: moment(data.start).format("YYYY-MM-DD"),
        repeat: (data.repeatInfo?.repeatType as Repeat) || Repeat.DOES_NOT_REPEAT,
        color: data.color,
        therapist: data.therapistId,
        client: data.clientId,
        selectedDate: moment(data.start).toISOString()
      });

      setTimeValue(data.start.toString());
      setHourValue(moment(data.start).hour());
      setMinuteValue(moment(data.start).minute());

      eventViewModalToggle();

      eventModalToggle();
    }
  };

  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content pt-pb-0">
        {viewTab == 1 && (
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="card-heading mb-2 cursor-pointer">
                      <h5 className="font-size-18 profileFont mb-2 cursor-pointer">Select Therapist</h5>
                    </div>
                  </Col>
                  <Col className="d-flex admin-filter">
                    <div className="d-flex justify-content-center pr-2 ">
                      <div className="d-flex justify-content-center align-items-center pr-2">
                        <span className="text-center mb-0 single-line-text mr-2">Search : </span>
                      </div>
                      <div onClick={(event) => event.stopPropagation()}>
                        <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
                      </div>

                    </div>
                    <span className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        searchTherapists()
                      }}
                    >
                      <i className="bx bx-search"></i>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="mt10">
                    <div className="table-responsive">
                      {therapists !== undefined && therapists.length > 0 ? (
                        <>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Email</th>
                                <th scope="col">Zipcode</th>
                                <th scope="col">State</th>
                                <th scope="col">Verified Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {therapists &&
                                therapists?.map((therapist, index) => (
                                  <tr
                                    key={therapist._id}
                                    onClick={() => next(therapist)}
                                    className={selectTherapist?._id == therapist?._id ? "tableSelectedColor cursor-p" : "cursor-p"}
                                  >
                                    <th scope="row">{index + 1}</th>

                                    <td>
                                      {therapist.firstname} {therapist.lastname}
                                    </td>

                                    <td>{therapist.gender}</td>

                                    <td>{therapist.email}</td>

                                    <td>{therapist.zipCode}</td>

                                    <td>{therapist.state}</td>

                                    <td>
                                      {therapist.verifiedStatus == "VERIFIED" ? (
                                        <span className="verified-2">Verified</span>
                                      ) : (
                                        <span className="pending-2">Pending</span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {userCount > therapists?.length && (
                            <>
                              <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                                See More
                              </button>
                            </>
                          )}
                          {userCount == 0 && <span>No more therapists available...</span>}
                        </>
                      ) : (
                        <>
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            <>
                              <div>No Therapist</div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        )}

        {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
        {showNotApproved && <Modal17 setShowModal={setShowNotApproved} showModal={showNotApproved} />}
        {showAlreadyCompleted && <Modal13 setShowModal={setShowAlreadyCompleted} showModal={showAlreadyCompleted} />}
        {showExpired && <Modal12 setShowModal={setShowExpired} showModal={showExpired} />}
        {showTooEarly && <Modal11 setShowModal={setShowTooEarly} showModal={showTooEarly} />}
        {appointmentCreated && <Modal14 setShowModal={setAppointmentCreated} showModal={appointmentCreated} />}
        {appointmentUpdated && <Modal15 setShowModal={setAppointmentUpdated} showModal={appointmentUpdated} />}
        {showModal && (
          <MessageModal setShowModal={setShowModal} showModal={showModal} message={"Please subscribe to a provided plan to access this feature."} />
        )}
        {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
        {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}

        {viewTab == 2 && (
          <div className="page-content" style={{ paddingTop: "0px" }}>
            {isCalender && (
              <Container fluid={true}>
                {isLoading && (
                  <div className="flex justify-content-center mt-5">
                    <img src={images.cubicLoader} style={{ width: "90px" }} />
                  </div>
                )}

                {!isLoading && (
                  <>
                    {isFriend ? (
                      <>
                        <Row>
                          <Col lg={3}>
                            <div id="external-events">
                              {/* {selectTherapist?.role == Role.THERAPIST && <Calendar onChange={(e: Date) => changeDate(e)} value={dateValue} />} */}
                              {
                                <>
                                  <Label className="mb-2 d-flex j-btwn">
                                    <span> Therapist</span>
                                    {isView ? (
                                      <i className="bx bxs-down-arrow mr-2 more-icon" onClick={hideAndShow}></i>
                                    ) : (
                                      <i className="bx bxs-up-arrow mr-2 more-icon" onClick={hideAndShow}></i>
                                    )}
                                  </Label>

                                  <Card className={!isView ? `view-therapist-card` : "hide-therapist-card"}>
                                    <div className="col-xl-12">
                                      <div className="row">
                                        <div className="col-xl-12">
                                          <Row>
                                            <Col>
                                              <div
                                                className="profileImage-appoint imageFit BorderRadiusLeftRight"
                                                style={{
                                                  backgroundImage:
                                                    therapist?.coverPhotoId == null || therapist?.coverPhotoId == undefined || !therapist?.coverPhotoId?._id
                                                      ? `url(${profileBackground})`
                                                      : `url("${Util.fileURL(therapist?.coverPhotoId?._id)}")`,
                                                }}
                                              ></div>
                                              <Row>
                                                <Col xl={4} lg={4}>
                                                  <div className="avatar-md profile-user-wid mb-4">
                                                    <div
                                                      className="img-thumbnail avatar-md profileImageShow rounded-circle imageFit mr-profile"
                                                      style={{
                                                        backgroundImage:
                                                          therapist?.photoId == null || therapist?.photoId == undefined
                                                            ? `url(${user1})`
                                                            : `url("${Util.fileURL(therapist?.photoId?._id)}")`,
                                                        borderRadius: "10px",
                                                        position: "relative",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </Col>
                                                <Col xl={8} lg={8}>
                                                  {therapist?.role == "THERAPIST" && (
                                                    <span className="appointment-profile-name2">
                                                      {therapist?.firstname} {therapist?.lastname}
                                                    </span>
                                                  )}
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xl={12}>
                                              <div className="working-hours">
                                                <Label className="px-2">
                                                  <i className="bx bx-time-five"></i> &nbsp;Working Hours:
                                                </Label>
                                                <div className="working-hours">
                                                  {therapist?.workingHours &&
                                                    therapist?.workingHours?.map((opt, index) => (
                                                      <Label className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2" key={index}>
                                                        {opt.day} {opt.day && "|"} {Util.convertUTCDateToLocalDate(opt.startTime)} -
                                                        {Util.convertUTCDateToLocalDate(opt.endTime)}
                                                      </Label>
                                                    ))}
                                                  {!therapist?.workingHours?.length && <p>No working hours added.</p>}
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </CardBody> */}
                                  </Card>
                                </>
                              }
                            </div>
                          </Col>
                          <Col lg={9} sm={12}>
                            <FullCalendar
                              plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin, timeGridPlugin]}
                              slotMinTime={15}
                              editable={false}
                              slotDuration={"00:30:00"}
                              handleWindowResize={true}
                              themeSystem="bootstrap"
                              headerToolbar={{
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay",
                              }}
                              eventTimeFormat={{
                                hour12: false,
                              }}
                              firstDay={dayOfWeekAsNumber(moment(new Date()).format("dddd"))}
                              initialView="timeGridWeek"
                              selectLongPressDelay={-1}
                              longPressDelay={-1}
                              eventLongPressDelay={-1}
                              // events={appointments}
                              events={appointments.map(appointment => {
                                const startMoment = momentTimezone(appointment.start).tz('America/New_York');
                                const endMoment = momentTimezone(appointment.end).tz('America/New_York');
                                const startOffset = startMoment.isDST() ? -4 * 60 : -5 * 60;
                                const endOffset = endMoment.isDST() ? -4 * 60 : -5 * 60;
                                return {
                                  ...appointment,
                                  start: startMoment.utcOffset(startOffset, true).toDate(),
                                  end: endMoment.utcOffset(endOffset, true).toDate()
                                };
                              })}
                              droppable={false}
                              selectable={true}
                              eventClick={viewEvent}
                              ref={calendarComponentRef}
                              select={(e: any) => addEvent(e)}
                              businessHours={isAvailableHours}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <h5 className="text-center mt50">Sorry! You haven&lsquo;t connected with this Therapist yet.</h5>
                    )}
                  </>
                )}
              </Container>
            )}
          </div>
        )}

        {showErrorModal && (
          <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
        )}

        <Modal isOpen={isEventModal} centered toggle={eventModalToggle} unmountOnClose={true}>
          <ModalHeader toggle={eventModalToggle}>
            <div className="title-input">
              <FormGroup>
                <Input
                  className="modal-title new-input-v"
                  name="title"
                  type="text"
                  placeholder="Add title - Optional"
                  onChange={e => setAppointment({ ...appointment, title: e.target.value })}
                  value={appointment.title}
                />
                <span className="awsome_input_border" />
              </FormGroup>
            </div>
          </ModalHeader>

          <ModalBody className="awsome-area">
            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className="bx bx-time-five"></i>
              </Col>
              <Col lg={11} md={11} sm={11} xs={12}>
                <Row>
                  <Col lg={4} md={4} sm={4} xs={6} className="sm-mb5">
                    <DatePicker
                      minDate={moment().toDate()}
                      value={moment(appointment?.date).toDate()}
                      onChange={(date: Date) => {
                        const selectedTime = moment(date);

                        selectedTime.hour(hourValue).minute(minuteValue);

                        const stateT = moment(selectedTime).format("H:mm A");
                        const endT = moment(selectedTime).add(60, "minutes").format("H:mm A");
                        const sDate = moment(selectedTime).toISOString();

                        setAppointment({ ...appointment, startTime: stateT, endTime: endT, selectedDate: sDate, date: selectedTime.toString()});
                      }}
                    />
                  </Col>

                  <Col lg={8} md={8} sm={8} xs={6}>
                    <CustomTimePickerForAdmin timeValue={timeValue} setTimeValue={setTimeValue} appointment={appointment} setAppointment={setAppointment} setHourValue={setHourValue} setMinuteValue={setMinuteValue} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className="bx bx-video"></i>
              </Col>
              <Col lg={11} md={11} sm={11} xs={12}>
                Video - 1 Hour Session
              </Col>
            </Row>

            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className={Util.meetingRepeatTypeIcon(appointment.repeat)}></i>
              </Col>
              <Col lg={11} md={11} sm={11} xs={12}>
                {
                  appointment.id && isUpdate ? <>
                    <div>{appointment.repeat == Repeat.DOES_NOT_REPEAT && <span className="does-not-repeat">Does Not Repeat</span>}</div>
                    <div>{appointment.repeat == Repeat.WEEKLY && <span className="weekly">Weekly</span>}</div>
                    <div>{appointment.repeat == Repeat.BI_WEEKLY && <span className="bi-weekly">Bi Weekly</span>}</div>
                  </> : <Input
                    type="select"
                    name="repeat"
                    onChange={e => setAppointment({ ...appointment, repeat: e.target.value as Repeat })}
                    value={appointment.repeat}
                  >
                    <option defaultChecked value={Repeat.DOES_NOT_REPEAT}>
                      Does Not Repeat
                    </option>
                    <option value={Repeat.WEEKLY}>Weekly</option>
                    <option value={Repeat.BI_WEEKLY}>Bi Weekly</option>
                  </Input>
                }
              </Col>
            </Row>

            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className="bx bx-palette"></i>
              </Col>
              <Col lg={11} md={11} sm={11} xs={12}>
                <TwitterPicker width="100%" onChangeComplete={handleChangeComplete} color={appointment.color} />
              </Col>
            </Row>

            {therapist.disclosureStatementId?.url && (
              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                <Col className="" lg={11} md={11} sm={11} xs={12}>
                  <FormGroup check inline>
                    <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                    <Label check>
                      I have read the&nbsp;
                      <Link to={{ pathname: Util.fileURL(therapist.disclosureStatementId?._id) }} target="_blank">
                        disclosure statement&nbsp;
                      </Link>
                      & Accept.
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
              <Col className="" lg={11} md={11} sm={11} xs={12}>
                <FormGroup check inline>
                  <Input type="checkbox" onChange={(e: any) => setHaveAgreedPolicy(e.target.checked)} />
                  <Label check>
                    I agree to&nbsp;
                    <a href="/noshow-policy" className="hover" target="_blank">
                      No Show and Cancellation Policy&nbsp;
                    </a>
                    of Lavni Inc.
                  </Label>
                </FormGroup>
              </Col>
            </Row>


            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
              <Col className="" lg={11} md={11} sm={11} xs={12}>
                <FormGroup check inline>
                  <Input type="checkbox" onChange={(e: any) => setHaveAgreed(e.target.checked)} />
                  <Label check>
                    I agree to&nbsp;
                    <a href="/consent-document" className="hover" target="_blank">
                      Consent Document&nbsp;
                    </a>
                    of Lavni Inc.
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            {appointment.id && isUpdate ? (
              <Row>
                <Col className="cont-center" lg={1}></Col>
                <Col className="appoint-btn">
                  <button className={disabledUpdateBtn ? "session-btn updating" : "session-btn"} disabled={disabledUpdateBtn} onClick={updateAppointment}>
                    Update
                  </button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="cont-center" lg={1}></Col>
                <Col className="appoint-btn">
                  <button className={disabledCreateBtn ? "session-btn updating" : "session-btn"} disabled={disabledCreateBtn} onClick={createAppointment}>
                    Create
                  </button>
                </Col>
              </Row>
            )}
          </ModalBody>
        </Modal>

        <Modal isOpen={isEventViewModal} centered toggle={eventViewModalToggle}>
          <ModalHeader toggle={eventViewModalToggle}>Session with client {appointmentDetails?.clientId?.firstname + " " + appointmentDetails?.clientId?.lastname}</ModalHeader>
          <ModalBody>
            {clientData?.role == Role.CLIENT && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="col-xl-12">
                        <div className="row">
                          <div className="col-xl-12">
                            <Row>
                              <div
                                className="profileImage-appoint imageFit BorderRadiusLeftRight"
                                style={{
                                  backgroundImage:
                                    appointmentDetails?.therapistId?.coverPhotoId == null ||
                                      appointmentDetails?.therapistId?.coverPhotoId == undefined ||
                                      !appointmentDetails?.therapistId?.coverPhotoId?._id
                                      ? `url(${profileBackground})`
                                      : `url("${Util.fileURL(appointmentDetails?.therapistId?.coverPhotoId?._id)}")`,
                                }}
                              ></div>
                              <Row>
                                <Col xl={4}>
                                  <div
                                    className="img-thumbnail imageFit rounded-circle imageFit avatar-md profile-user-wid-pro-appoint items-center mb-4 profileImageShow"
                                    style={{
                                      backgroundImage:
                                        appointmentDetails?.therapistId?.photoId == null || appointmentDetails?.therapistId?.photoId == undefined
                                          ? `url(${user1})`
                                          : `url("${Util.fileURL(appointmentDetails?.therapistId?.photoId?._id)}")`,
                                      borderRadius: "10px",
                                      position: "relative",
                                    }}
                                  ></div>
                                </Col>
                                <Col xl={8}>
                                  {
                                    <span className="appointment-profile-name1">
                                      {appointmentDetails?.therapistId?.firstname} {appointmentDetails?.therapistId?.lastname}
                                    </span>
                                  }
                                </Col>
                              </Row>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            <Row className="mb-2">
              <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className="bx bx-time-five"></i>
              </Col>

              <Col lg={5} md={5} sm={5} xs={12}>
                <h5>
                  {moment(appointmentDetails?.start).format("dddd")}, {moment(appointmentDetails?.start).format("MMM Do YY")}
                </h5>
              </Col>

              <Col lg={3} md={3} sm={3} xs={12}>
                <h5>{moment(appointmentDetails?.start).format("h:mm A")}</h5>
              </Col>

              <Col lg={3} md={3} sm={3} xs={12}>
                <h5>{moment(appointmentDetails?.end).format("h:mm A")}</h5>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className={Util.meetingTypeIcon(appointmentDetails?.typeOfMeeting)}></i>
              </Col>

              <Col>
                <h5>{appointmentDetails?.typeOfMeeting}</h5>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className={Util.meetingRepeatTypeIcon(appointmentDetails?.repeatInfo?.repeatType)}></i>
              </Col>

              <Col>
                <h5>
                  {appointmentDetails?.repeatInfo?.repeatType == Repeat.DOES_NOT_REPEAT && "Does Not Repeat"}
                  {appointmentDetails?.repeatInfo?.repeatType == Repeat.WEEKLY && "Weekly"}
                  {appointmentDetails?.repeatInfo?.repeatType == Repeat.BI_WEEKLY && "Bi Weekly"}
                </h5>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col className="cont-center1 sm-hide" lg={1} md={1} sm={1} xs={1}>
                <i className="bx bx-palette"></i>
              </Col>

              <Col>
                <div
                  className="color-palet"
                  style={{ backgroundColor: appointmentDetails?.clientId?._id == clientData?._id ? appointmentDetails?.color : "#665a5a" }}
                ></div>
              </Col>
            </Row>

            {clientData?.role == Role.CLIENT &&
              (appointmentDetails?.createdBy?._id === clientData._id ||
                (appointmentDetails?.createdBy?._id === appointmentDetails?.therapistId?._id && appointmentDetails?.clientId?._id == clientData._id) ||
                user?.role == "SUPER_ADMIN" || user?.role == "SUB_ADMIN") && (
                <>
                  {appointmentDetails?.approvedStatus == ApprovalStatus.PENDING ? (
                    <Row>
                      <Col className="appointment-status">
                        <span className="text-warning">Pending Approval!</span>
                      </Col>

                      <Col>
                        <div className="btn-flex">
                          <button className="btn btn-sm btn-success m-2" onClick={() => editAppointment(appointmentDetails)}>
                            <i className="bx bx-edit"></i> &nbsp; Reschedule
                          </button>
                          <button className="btn btn-sm btn-danger m-2" onClick={() => deleteAppointment(appointmentDetails?._id)}>
                            <i className="bx bx-trash"></i> &nbsp; Delete
                          </button>
                        </div>
                      </Col>
                    </Row>
                  ) : appointmentDetails?.approvedStatus == ApprovalStatus.REJECTED ? (
                    <Row>
                      <Col className="appointment-status">
                        <span className="text-danger">Appointment Rejected!</span>
                      </Col>

                      <Col>
                        <div className="btn-flex">
                          <button className="btn btn-sm btn-success m-2" onClick={() => editAppointment(appointmentDetails)}>
                            <i className="bx bx-edit"></i> &nbsp; Reschedule
                          </button>
                          <button className="btn btn-sm btn-danger m-2" onClick={() => deleteAppointment(appointmentDetails?._id)}>
                            <i className="bx bx-trash"></i> &nbsp; Delete
                          </button>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="appointment-status">
                        <span className="text-danger">Appointment Approved!</span>
                      </Col>

                      <Col>
                        <div className="btn-flex">
                          <button className="btn btn-sm btn-success m-2" onClick={() => editAppointment(appointmentDetails)}>
                            <i className="bx bx-edit"></i> &nbsp; Reschedule
                          </button>
                          <button className="btn btn-sm btn-danger m-2" onClick={() => deleteAppointment(appointmentDetails?._id)}>
                            <i className="bx bx-trash"></i> &nbsp; Delete
                          </button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
          </ModalBody>
        </Modal>

        <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
          <ModalBody>
            <div className="modal-val">
              <h5 className="model-error">{errorModalText}</h5>

              <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
                Got It
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ScheduleAppointmentsAdmin;
