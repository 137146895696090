import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, Col, Input, Row, Table } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import {
  treatementHistoryDetailsType,
  InPatienttreatementHistoryDetails,
  DDsCare,
  InpatientTreatementHistoryApplicableEnum, InpatientTreatementHistory
} from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";

const NewFormSix = (props: any) => {
  const { previousStep, nextStep, handleBulkFormDataUpdate, data } = props;

  const [inPatientPastTreatement, setInPatenPastTreatement] = useState("");
  const [inPatientAdditionalInformation, setInPatientAdditionalInformation] = useState("");

  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    outPatientTreatementHistory: {
      applicableState: null,
      outPatienttreatementHistoryDetails: [],
    },
    inpatientTreatementHistory: {
      applicableState: "",
      inPatientTreatementHistory: [],
      pastTreatement: "",
      additionalMedicalInformation: "",
    },
    releventDDSInformation: {
      ddsInvolvement: null,
      ddsCare: "",
      ddsCareDescription: "",
      ddsSocialWorker: "",
      phone: "",
    },
    relevantLegalInformation: {
      legalInvolvement: null,
      // countOfArrestet: 0,
      currentCharges: "",
      attorneyName: "",
      currentProbationRequirement: "",
      probationOffice: "",
      previousChargesJailOrPrison: "",
    },
  });
  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        outPatientTreatementHistory: data?.outPatientTreatementHistory,
        inpatientTreatementHistory: data?.inpatientTreatementHistory,
        releventDDSInformation: data?.releventDDSInformation,
        relevantLegalInformation: data?.relevantLegalInformation,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   outPatientTreatementHistory: data?.outPatientTreatementHistory,
      //   inpatientTreatementHistory: data?.inpatientTreatementHistory,
      //   releventDDSInformation: data?.releventDDSInformation,
      //   relevantLegalInformation: data?.relevantLegalInformation,
      // };

      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));
      setInPatenPastTreatement(memoizedUpdateValues?.inpatientTreatementHistory?.pastTreatement);
      setInPatientAdditionalInformation(memoizedUpdateValues?.inpatientTreatementHistory?.additionalMedicalInformation);
      // setFormData(updateValues);
    }
  }, [memoizedUpdateValues]);

  useEffect(() => {
    if (
      !formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ||
      formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.length === 0
    ) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        outPatientTreatementHistory: {
          ...formData?.outPatientTreatementHistory,
          outPatienttreatementHistoryDetails: [...(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ?? []), {}],
        },
      }));
    }

    if (!formData?.inpatientTreatementHistory?.inPatientTreatementHistory || formData?.inpatientTreatementHistory?.inPatientTreatementHistory?.length === 0) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        inpatientTreatementHistory: {
          ...formData?.inpatientTreatementHistory,
          inPatientTreatementHistory: [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? []), {}],
        },
      }));
    }
  }, [formData]);

  const addoutPatientHistoryDetails = () => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      outPatientTreatementHistory: {
        ...formData?.outPatientTreatementHistory,
        outPatienttreatementHistoryDetails: [...(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ?? []), {}],
      },
    }));
  };

  const addOutPatientHistoryDEtails = () => {
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      inpatientTreatementHistory: {
        ...formData?.inpatientTreatementHistory,
        inPatientTreatementHistory: [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? []), {}],
      },
    }));
  };

  const handleUpdateData = () => {
    const updateData = {
      formHeader: {
        ...formData?.formHeader,
      },
      outPatientTreatementHistory: {
        ...formData?.outPatientTreatementHistory,
      },
      inpatientTreatementHistory: {
        ...formData?.inpatientTreatementHistory,
        pastTreatement: inPatientPastTreatement,
        additionalMedicalInformation: inPatientAdditionalInformation,
      },
      releventDDSInformation: {
        ...formData?.releventDDSInformation,
      },
      relevantLegalInformation: {
        ...formData?.relevantLegalInformation,
      },
    };

    handleBulkFormDataUpdate(updateData);
  };
  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              //   updateAssesmentDetails();
              handleUpdateData();
              // nextStep();
            }}
          >
            {"Save & Continue"}
          </button>
        </div>

        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Card className="match-card-header p-2 mb-2">
          <div style={{ display: "flex" }}>
            <h5 className="fw-500" style={{ margin: "auto", marginRight: "10%" }}>
              OUTPATIENT MH/DD/SA TREATMENT HISTORY
            </h5>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                style={{ fontSize: "larger" }}
                checked={formData?.outPatientTreatementHistory?.applicableState === true}
                onClick={e => {
                  setFormData((formData: Partial<DigitalForm>) => ({
                    ...formData,
                    outPatientTreatementHistory: {
                      ...formData?.outPatientTreatementHistory,
                      applicableState: formData?.outPatientTreatementHistory?.applicableState === true ? false : true,
                    },
                  }));
                }}
              />
              <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                Mark if not applicable
              </label>
            </div>
          </div>
        </Card>
        <Card className="match-card-body p-3">
          <div className="table-responsive">
            <Table responsive className="clinical-table-bordered table-md ">
              <thead>
                <tr>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Agency / Practitioner
                    </div>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      or Service
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Dates of Treatment
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Diagnosis
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Medication(s)
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails) &&
                  formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.map((outDetails: treatementHistoryDetailsType, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.agencyOrService || ""}
                            onChange={e => {
                              const newDetails = [...(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ?? [])];
                              newDetails[index] = { ...newDetails[index], agencyOrService: e.target.value };
                              setFormData({
                                ...formData,
                                outPatientTreatementHistory: {
                                  ...formData?.outPatientTreatementHistory,
                                  outPatienttreatementHistoryDetails: newDetails,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="date"
                            value={
                              formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.dateOfTreatement
                                ? moment
                                    .utc(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.dateOfTreatement)
                                    .format("YYYY-MM-DD")
                                : ""
                            }
                            onChange={e => {
                              const newDetails = [...(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ?? [])];
                              newDetails[index] = { ...newDetails[index], dateOfTreatement: e.target.value };
                              setFormData({
                                ...formData,
                                outPatientTreatementHistory: {
                                  ...formData?.outPatientTreatementHistory,
                                  outPatienttreatementHistoryDetails: newDetails,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.diagnosis || ""}
                            onChange={e => {
                              const newDetails = [...(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ?? [])];
                              newDetails[index] = { ...newDetails[index], diagnosis: e.target.value };
                              setFormData({
                                ...formData,
                                outPatientTreatementHistory: {
                                  ...formData?.outPatientTreatementHistory,
                                  outPatienttreatementHistoryDetails: newDetails,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails?.[index]?.medication || ""}
                            onChange={e => {
                              const newDetails = [...(formData?.outPatientTreatementHistory?.outPatienttreatementHistoryDetails ?? [])];
                              newDetails[index] = { ...newDetails[index], medication: e.target.value };
                              setFormData({
                                ...formData,
                                outPatientTreatementHistory: {
                                  ...formData?.outPatientTreatementHistory,
                                  outPatienttreatementHistoryDetails: newDetails,
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <div className="py-3 d-flex justify-content-start">
                {
                  <Button color="primary" onClick={addoutPatientHistoryDetails}>
                    Add More Details
                  </Button>
                }
              </div>
            </Table>
          </div>
        </Card>

        <Card className="match-card-header p-2 mb-2">
          <div style={{ display: "flex" }}>
            <h5 className="fw-500" style={{ margin: "auto", marginRight: "10%" }}>
              INPATIENT MH/DD/SA TREATMENT HISTORY
            </h5>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                style={{ fontSize: "larger" }}
                value={InpatientTreatementHistoryApplicableEnum.yes}
                checked={formData?.inpatientTreatementHistory?.applicableState === InpatientTreatementHistoryApplicableEnum.yes}
                onClick={e => {
                  const result = formData?.inpatientTreatementHistory?.applicableState === InpatientTreatementHistoryApplicableEnum.yes;
                  setFormData(() => ({
                    ...formData,
                    inpatientTreatementHistory: {
                      ...formData.inpatientTreatementHistory,
                      applicableState: result ? "" : InpatientTreatementHistoryApplicableEnum.yes,
                    },
                  }));
                }}
              />
              <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                Mark if not applicable
              </label>
            </div>
          </div>
        </Card>
        <Card className="match-card-body p-3">
          <div className="table-responsive">
            <Table responsive className="clinical-table-bordered table-md ">
              <thead>
                <tr>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Hospital / Treatment
                    </div>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Program
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Dates of Treatment
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Reason for hospital or
                    </div>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      treatment
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Diagnosis
                    </div>
                  </th>
                  <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                      Medication(s)
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(formData?.inpatientTreatementHistory?.inPatientTreatementHistory) &&
                  formData?.inpatientTreatementHistory?.inPatientTreatementHistory.map((inPatientDetails: InPatienttreatementHistoryDetails, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={inPatientDetails?.treatementProgram}
                            onChange={e => {
                              const newDetails = [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? [])];
                              newDetails[index] = { ...newDetails[index], treatementProgram: e.target.value };
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                inpatientTreatementHistory: {
                                  ...formData.inpatientTreatementHistory,
                                  inPatientTreatementHistory: newDetails,
                                },
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="date"
                            value={inPatientDetails?.dateOfTreatements ? moment.utc(inPatientDetails?.dateOfTreatements).format("YYYY-MM-DD") : ""}
                            onChange={e => {
                              const newDetails = [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? [])];
                              newDetails[index] = { ...newDetails[index], dateOfTreatements: e.target.value };
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                inpatientTreatementHistory: {
                                  ...formData.inpatientTreatementHistory,
                                  inPatientTreatementHistory: newDetails,
                                },
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={inPatientDetails?.reasonForTreatement}
                            onChange={e => {
                              const newDetails = [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? [])];
                              newDetails[index] = { ...newDetails[index], reasonForTreatement: e.target.value };
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                inpatientTreatementHistory: {
                                  ...formData.inpatientTreatementHistory,
                                  inPatientTreatementHistory: newDetails,
                                },
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={inPatientDetails?.diagnosis}
                            onChange={e => {
                              const newDetails = [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? [])];
                              newDetails[index] = { ...newDetails[index], diagnosis: e.target.value };
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                inpatientTreatementHistory: {
                                  ...formData.inpatientTreatementHistory,
                                  inPatientTreatementHistory: newDetails,
                                },
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="col-auto">
                          <input
                            style={{
                              fontSize: "0.70rem",
                              width: "100%",
                              border: "none",
                              backgroundColor: "unset",
                              textAlign: "left",
                              paddingLeft: "0.2rem",
                            }}
                            type="text"
                            value={inPatientDetails?.medication}
                            onChange={e => {
                              const newDetails = [...(formData?.inpatientTreatementHistory?.inPatientTreatementHistory ?? [])];
                              newDetails[index] = { ...newDetails[index], medication: e.target.value };
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                inpatientTreatementHistory: {
                                  ...formData.inpatientTreatementHistory,
                                  inPatientTreatementHistory: newDetails,
                                },
                              }));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <div className="py-3 d-flex justify-content-start">
                {
                  <Button color="primary" onClick={addOutPatientHistoryDEtails}>
                    Add More Details
                  </Button>
                }
              </div>
            </Table>

            <Card>
              <Row>
                <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                  Adherence to past treatment (Meds, support groups, etc)
                  {formData?.inpatientTreatementHistory?.applicableState !== InpatientTreatementHistoryApplicableEnum.yes ? (
                    <span>
                      : (<span style={{ color: "red", fontSize: "15px" }}>*</span>)
                    </span>
                  ) : (
                    ""
                  )}
                </label>
              </Row>
              <Row>
                <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                  <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                    <CKEditor
                      editor={Editor}
                      data={inPatientPastTreatement || ""}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        // setFormData((formData: Partial<DigitalForm>) => ({
                        //   ...formData,
                        //   inpatientTreatementHistory: {
                        //     ...formData?.inpatientTreatementHistory,
                        //     pastTreatement: data,
                        //   },
                        // }));
                        setInPatenPastTreatement(data);
                      }}
                    />
                  </div>
                </Card>
              </Row>
            </Card>

            <Card>
              <Row>
                <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                  Additional medical information
                  {formData?.inpatientTreatementHistory?.applicableState !== InpatientTreatementHistoryApplicableEnum.yes ? (
                    <span>
                      : (<span style={{ color: "red", fontSize: "15px" }}>*</span>)
                    </span>
                  ) : (
                    ""
                  )}
                </label>
              </Row>
              <Row>
                <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                  <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                    <CKEditor
                      editor={Editor}
                      data={inPatientAdditionalInformation || ""}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        // setFormData((formData: Partial<DigitalForm>) => ({
                        //   ...formData,
                        //   inpatientTreatementHistory: {
                        //     ...formData?.inpatientTreatementHistory,
                        //     additionalMedicalInformation: data,
                        //   },
                        // }));
                        setInPatientAdditionalInformation(data);
                      }}
                    />
                  </div>
                </Card>
              </Row>
            </Card>
          </div>
        </Card>

        <Card className="match-card-header p-2 mb-2">
          <div style={{ display: "flex" }}>
            <h5 className="fw-500" style={{ margin: "auto" }}>
              RELEVANT DSS INFORMATION:
            </h5>
          </div>
        </Card>
        <div className="card-bodyDiv">
          <Row>
            <Card className="match-card-body p-3">
              <Row>
                <Col xl={8}>
                  <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                    DSS Involvement?
                  </h6>
                </Col>
                <Col xl={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      // value={DDSInvolvmentType.yes}
                      checked={formData?.releventDDSInformation?.ddsInvolvement === true}
                      onClick={e => {
                        const result = formData?.releventDDSInformation?.ddsInvolvement === true;
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          releventDDSInformation: {
                            ...formData?.releventDDSInformation,
                            ddsInvolvement: result ? null : true,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Yes
                    </label>
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      // value={DDSInvolvmentType.no}
                      checked={formData?.releventDDSInformation?.ddsInvolvement === false}
                      onClick={e => {
                        const result = formData?.releventDDSInformation?.ddsInvolvement === false;
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          releventDDSInformation: {
                            ...formData?.releventDDSInformation,
                            ddsInvolvement: result ? null : false,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      No
                    </label>
                  </div>
                </Col>
                <div className="col">
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <Row>
                        <Col xl={1}></Col>

                        <Col xl={3}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            DSS Case
                          </h6>
                        </Col>
                        <Col xl={3}>
                          <div className="col-auto d-flex">
                            <input
                              style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              value={DDsCare.substantiated}
                              checked={formData?.releventDDSInformation?.ddsCare === DDsCare.substantiated}
                              onClick={e => {
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  releventDDSInformation: {
                                    ...formData.releventDDSInformation,
                                    ddsCare: formData?.releventDDSInformation?.ddsCare === DDsCare.substantiated ? "" : DDsCare.substantiated,
                                  },
                                }));
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Substantiated
                            </label>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="col-auto d-flex">
                            <input
                              style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              value={DDsCare.investigation}
                              checked={formData?.releventDDSInformation?.ddsCare === DDsCare.investigation}
                              onClick={e => {
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  releventDDSInformation: {
                                    ...formData.releventDDSInformation,
                                    ddsCare: formData?.releventDDSInformation?.ddsCare === DDsCare.investigation ? "" : DDsCare.investigation,
                                  },
                                }));
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Investigation
                            </label>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="col-auto d-flex">
                            <input
                              style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              value={DDsCare.closed}
                              checked={formData?.releventDDSInformation?.ddsCare === DDsCare.closed}
                              onClick={e => {
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  releventDDSInformation: {
                                    ...formData.releventDDSInformation,
                                    ddsCare: formData?.releventDDSInformation?.ddsCare === DDsCare.closed ? "" : DDsCare.closed,
                                  },
                                }));
                              }}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                              Closed
                            </label>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            DSS Basis for Involvement: (APS, CPS & brief description):
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.releventDDSInformation?.ddsCareDescription || ""}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      releventDDSInformation: {
                                        ...formData.releventDDSInformation,
                                        ddsCareDescription: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            DSS Social Worker Name:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.releventDDSInformation?.ddsSocialWorker || ""}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      releventDDSInformation: {
                                        ...formData.releventDDSInformation,
                                        ddsSocialWorker: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Phone #:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.releventDDSInformation?.phone || ""}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      releventDDSInformation: {
                                        ...formData.releventDDSInformation,
                                        phone: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Row>
            </Card>
          </Row>
        </div>

        <Card className="match-card-header p-2 mb-2">
          <div style={{ display: "flex" }}>
            <h5 className="fw-500" style={{ margin: "auto" }}>
              RELEVANT LEGAL INFORMATION:
            </h5>
          </div>
        </Card>
        <div className="card-bodyDiv">
          <Row>
            <Card className="match-card-body p-3">
              <Row>
                <Col xl={8}>
                  <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                    LEGAL Involvement?
                  </h6>
                </Col>
                <Col xl={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      // value={DDSInvolvmentLegalType.yes}
                      checked={formData?.relevantLegalInformation?.legalInvolvement === true}
                      onClick={e => {
                        const result = formData?.relevantLegalInformation?.legalInvolvement === true;
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          relevantLegalInformation: {
                            ...formData?.relevantLegalInformation,
                            legalInvolvement: result ? null : true,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Yes
                    </label>
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="col-auto d-flex">
                    <input
                      style={{ fontSize: "larger", marginRight: "0.5rem" }}
                      type="radio"
                      className="form-check-input"
                      // value={DDSInvolvmentLegalType.no}
                      checked={formData?.relevantLegalInformation?.legalInvolvement === false}
                      onClick={e => {
                        const result = formData?.relevantLegalInformation?.legalInvolvement === false;
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          relevantLegalInformation: {
                            ...formData?.relevantLegalInformation,
                            legalInvolvement: result ? null : false,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      No
                    </label>
                  </div>
                </Col>
                <div className="col">
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Number of Arrests in past 30 days:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="number"
                                  disabled={false}
                                  value={formData?.relevantLegalInformation?.countOfArrestet}
                                  max={30}
                                  min={0}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      relevantLegalInformation: {
                                        ...formData?.relevantLegalInformation,
                                        countOfArrestet: Number(e.target.value),
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Current Charges:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.relevantLegalInformation?.currentCharges}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      relevantLegalInformation: {
                                        ...formData?.relevantLegalInformation,
                                        currentCharges: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Attorney Name:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.relevantLegalInformation?.attorneyName}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      relevantLegalInformation: {
                                        ...formData?.relevantLegalInformation,
                                        attorneyName: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Current Probation Requirements:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.relevantLegalInformation?.currentProbationRequirement}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      relevantLegalInformation: {
                                        ...formData?.relevantLegalInformation,
                                        currentProbationRequirement: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Probation Officer/Court Counselor:
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.relevantLegalInformation?.probationOffice}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      relevantLegalInformation: {
                                        ...formData?.relevantLegalInformation,
                                        probationOffice: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={1}></Col>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                            Previous Charge(s)/Convictions/Incarceration(s)/JAIL or PRISON):
                          </h6>
                        </Col>
                        <Col xl={7}>
                          <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                            <div className="row">
                              <div className="col">
                                <input
                                  style={{
                                    width: "100%",
                                    // border: "none",
                                    marginTop: "0.1rem",
                                    backgroundColor: "unset",
                                    border: "1px dotted  #ccc",
                                    padding: "2px",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type="text"
                                  disabled={false}
                                  value={formData?.relevantLegalInformation?.previousChargesJailOrPrison}
                                  onChange={e => {
                                    setFormData((formData: Partial<DigitalForm>) => ({
                                      ...formData,
                                      relevantLegalInformation: {
                                        ...formData?.relevantLegalInformation,
                                        previousChargesJailOrPrison: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Row>
            </Card>
          </Row>
        </div>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 6 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormSix;
