import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "reactstrap";
import moment from "moment";
import { DiagnosisModel, treatmentGoalStatusCode, TreatmentSessionModel } from "src/models/therapyPlan/therapyPlanSubModel";
import { therapyPlanGeneratePdf } from "src/models/therapyPlan/therapyPlanGeneratePdf";
import Select from "react-select";
import options from "../../Chat/DiagnosisNote/Dataset/options";
import { CommonService } from "../../../services/CommonService";
import { toast } from "react-toastify";

const TherapyPlanForm = (props: any) => {
  const { therapyPlanData } = props;
  const [isClientSigned, setIsClientSigned] = useState<boolean>();
  const [clientSignature, setClientSignature] = useState<boolean>();
  const [lrpSignature, setLrpSignature] = useState<boolean>();
  const [formData, setFormData] = useState<therapyPlanGeneratePdf>({
    diagnosis: [{}],
    treatmentSession: [{}],
    treatmentSession3: [{}],
  });
  const [primaryDiagnosisICD10CodesError, setprimaryDiagnosisICD10CodesError] = useState<boolean>(false);
  const [clientName, setClientName] = useState("");
  const [goalCount,setGoalCount]=useState(1);
  useEffect(() => {
    const updateFormOneData: therapyPlanGeneratePdf = {
      planCreationDate: therapyPlanData?.planCreationDate,
      creationDate: therapyPlanData?.creationDate,
      diagnosis: therapyPlanData?.diagnosis.map((diagnosisModel: DiagnosisModel) => ({
        description: diagnosisModel?.description,
      })),
      goalDate: therapyPlanData?.goalDate,
      goalInformation: therapyPlanData?.goalInformation,
      treatmentSession: therapyPlanData?.treatmentSession.map((treatmentSessionModel: TreatmentSessionModel) => ({
        targetDate: treatmentSessionModel?.targetDate,
        reviewDate: treatmentSessionModel?.reviewDate,
        statusCode: treatmentSessionModel?.statusCode,
        notes: treatmentSessionModel?.notes,
      })),
      goalDate2: therapyPlanData?.goalDate2,
      goalInformation2: therapyPlanData?.goalInformation2,
      treatmentSession2: therapyPlanData?.treatmentSession2.map((treatmentSessionModel: TreatmentSessionModel) => ({
        targetDate: treatmentSessionModel?.targetDate,
        reviewDate: treatmentSessionModel?.reviewDate,
        statusCode: treatmentSessionModel?.statusCode,
        notes: treatmentSessionModel?.notes,
      })),
      goalDate3: therapyPlanData?.goalDate3,
      goalInformation3: therapyPlanData?.goalInformation3,
      treatmentSession3: therapyPlanData?.treatmentSession3.map((treatmentSessionModel: TreatmentSessionModel) => ({
        targetDate: treatmentSessionModel?.targetDate,
        reviewDate: treatmentSessionModel?.reviewDate,
        statusCode: treatmentSessionModel?.statusCode,
        notes: treatmentSessionModel?.notes,
      })),
      clientSignatureDetails: therapyPlanData?.clientSignatureDetails,
      clientSignature: therapyPlanData?.clientSignature,
      lrpSignatureDetails: therapyPlanData?.lrpSignatureDetails,
      lrpSignature: therapyPlanData?.lrpSignature,
      clinicianSignatureDetails: therapyPlanData?.clinicianSignatureDetails,
      clinicianTwoSignatureDetails: therapyPlanData?.clinicianTwoSignatureDetails,
      clinicianSignature: therapyPlanData?.clinicianSignature,
      clinicianTwoSignature: therapyPlanData?.clinicianTwoSignature,
    };
    setFormData(updateFormOneData);
    if (therapyPlanData?.goalInformation2?.goalObjective){
      setGoalCount(2);
    }
    if (therapyPlanData?.goalInformation3?.goalObjective){
      setGoalCount(3);
    }
  }, [therapyPlanData]);

  useEffect(() => {
    setClientName(therapyPlanData?.goalInformation?.responsibleParties?.clientName);
  }, [therapyPlanData]);

  useEffect(() => {
    const clientData = formData.clientSignature;
    const lrpData = formData.lrpSignature;
    if ((clientData === undefined || !clientData || clientData.trim() === "") && (lrpData === undefined || !lrpData || lrpData.trim() === "")) {
      setIsClientSigned(false);
    } else {
      setIsClientSigned(true);
    }
    const clientSign = formData.clientSignature;
    if (clientSign === undefined || !clientSign || clientSign.trim() === "") {
      setClientSignature(false);
    } else {
      setClientSignature(true);
    }
    const lrpSign = formData.lrpSignature;
    if (lrpSign === undefined || !lrpSign || lrpSign === "") {
      setLrpSignature(false);
    } else {
      setLrpSignature(true);
    }
  }, [therapyPlanData, formData]);
  const customStylesForICD10Codes = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any) => ({
      ...styles,
      borderColor: primaryDiagnosisICD10CodesError ? "red" : "#ced4da",
    }),
  };
  useEffect(() => {
    if (therapyPlanData) {
      getClientNameAndTherapistName(therapyPlanData._id);
    }
  }, [therapyPlanData]);

  const [therapistCategorizationByType, setTherapistCategorizationByType] = useState("");

  const getClientNameAndTherapistName = async (clientId: string) => {
    try {
      const data = { clientId };
      const res = await CommonService.getClientNameAndTherapistName(data);
      console.log(res);
      console.warn("res plan form");
      if (res?.success) {
        setClientName(res.data.clientName);
        setTherapistCategorizationByType(res.data.therapistCategorizationByType);
      }
    } catch (error) {
      toast.error("Error retrieving client and therapist name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <>
      {/* Form-1: THERAPY PLAN & DIAGNOSIS */}
      <div>
        <div className="form-page">
        <Card className="BorderRadiusLeftRight m-0">
          <div className="mt-4"></div>
          <Card className="match-card-header p-2 mb-2">
            <h5 className="fw-500" style={{ margin: "auto" }}>
              THERAPY PLAN
            </h5>
          </Card>
          <Row>
            <Col xxl={5} xl={6}>
              <Card style={{ marginBottom: "0.3rem", backgroundColor: "#F1F1F1" }}>
                <div className="row">
                  <div className="col-auto">
                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                      PLAN CREATION DATE:
                    </h6>
                  </div>
                  <div className="col">
                    <input
                      style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      // type="date"
                      type="text"
                      id="planCreationDate"
                      readOnly
                      // value={formData.planCreationDate ? moment.utc(formData.planCreationDate).format("YYYY-MM-DD") : ""}
                      value={formData.planCreationDate ? moment.utc(formData.planCreationDate).format("MM-DD-YYYY") : ""}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={12} xl={6}>
              <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1" }}>
                <div className="row">
                  <div className="col" style={{ fontSize: "12px" }}>
                    (Plan will be reviewed AS NEEDED, at minimum ANNUALLY, and expires ONE YEAR from creation date, on:&nbsp;
                    <input
                      style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                      // type="date"
                      type="text"
                      id="creationDate"
                      readOnly
                      // value={formData.creationDate ? moment.utc(formData.creationDate).format("YYYY-MM-DD") : ""}
                      value={formData.creationDate ? moment.utc(formData.creationDate).format("MM-DD-YYYY") : ""}
                    />
                    )
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="card-bodyDiv">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  DIAGNOSIS
                </h5>
              </Card>
              <Card className="match-card-body-second p-3">
                {formData.diagnosis?.map((diagnosisModel, index) => (
                  <div key={index} className="ai-border mb-3 text-start">
                    <div className="ps-3 pe-3 pt-3 pb-3">
                      <div className="mb-3">
                        <p className="text-muted font-size-15">
                          <strong> Diagnosis :</strong>
                        </p>

                        <Select
                          key={formData?.diagnosis?.[index]?.description?.length}
                          closeMenuOnSelect={false}
                          defaultValue={formData?.diagnosis?.[index]?.description ?? []}
                          isMulti
                          options={options}
                          styles={customStylesForICD10Codes}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-flex flex-row justify-content-end"></div>
              </Card>
            </div>
          </Row>

          <Row>
            <div className="card-bodyDiv">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  TREATMENT GOALS
                </h5>
              </Card>
              <Card className="match-card-body p-3">
                <div style={{ overflowX: "auto" }}>
                  <Card style={{ marginBottom: "0.5rem" }}>
                    <div className="row info-in-mobile">
                      <div>
                        <h6 className="fw-500 text-center" style={{ margin: "0.3rem" }}>
                          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>S</span>pecific&nbsp;
                          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>M</span>easurable&nbsp;
                          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>A</span>ttainable&nbsp;
                          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>R</span>elevant&nbsp;
                          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>T</span>ime-limited
                        </h6>
                      </div>
                    </div>
                  </Card>

                  <Card style={{ marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          Goal Date:
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                          type="date"
                          id="goalDate"
                          readOnly
                          value={formData.goalDate ? moment.utc(formData.goalDate).format("YYYY-MM-DD") : ""}
                        />
                      </div>
                    </div>
                  </Card>

                  <Table className="clinical-table-bordered table-md">
                    <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "55%" }}>
                          Goal/Objectives
                        </td>
                        <td className="fw-500" style={{ width: "30%" }}>
                          Service/Intervention/Frequency
                        </td>
                        <td className="fw-500" style={{ width: "15%" }}>
                          Responsible Parties
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "top" }}>
                      <tr style={{ height: "2rem" }}>
                        <td>
                          <div className="col-auto text-left">
                            <div>
                              <div>
                                {formData.goalInformation?.responsibleParties?.clientName
                                  ? formData.goalInformation?.responsibleParties?.clientName
                                  : clientName}
                                s&apos; goal (in their words) is:{" "}
                              </div>

                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <textarea
                                    style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                    id="name"
                                    readOnly
                                    // placeholder={`Client Name will successfully transition into active treatment for identified concerns as evidenced by:\n1. Having scheduled, attended, and completed each session\n2. Having completed a Comprehensive Clinical Assessment, and\n3. Having developed a Treatment Plan\nby the end of Session #3.`}
                                    value={formData.goalInformation?.goalObjective?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                      ?.replace(/&nbsp;/g, " ")
                                      ?.replace(/<[^>]*>/g, "") || ""
                                    }
                                  />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              <div>How will we know progress is being made?</div>
                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <textarea
                                    style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                    id="name"
                                    readOnly
                                    // placeholder={`How will we know progress is being made?`}
                                    value={formData.goalInformation?.progressIdentifier?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                      ?.replace(/&nbsp;/g, " ")
                                      ?.replace(/<[^>]*>/g, "") || ""
                                    }
                                  />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              <div>{clientName} wants to achieve this goal</div>
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                id="name"
                                readOnly
                                // placeholder={`How will we know progress is being made?`}
                                value={formData.goalInformation?.timeframe || ""}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center">
                            <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                              <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                  id="name"
                                  readOnly
                                  // placeholder={`Individual Outpatient Therapy provided at least monthly`}
                                  value={formData.goalInformation?.serviceInterventionFrequency?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                    ?.replace(/&nbsp;/g, " ")
                                    ?.replace(/<[^>]*>/g, "") || ""
                                  }
                                />
                              </div>
                            </Card>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center">
                            <div>
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset", height: "50px" }}
                                placeholder="Client Name"
                                readOnly
                                value={formData.goalInformation?.responsibleParties?.clientName || ""}
                              />
                            </div>
                            <div>
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset", height: "100px" }}
                                placeholder="LAVNI Therapist, Name and Credentials"
                                readOnly
                                value={
                                  formData.goalInformation?.responsibleParties?.therapistNameCredentials
                                    ? `${formData.goalInformation?.responsibleParties?.therapistNameCredentials},\nLavni Therapist`
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="clinical-table-bordered table-md">
                    <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Target Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Review Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Status
                        </td>
                        <td className="fw-500" style={{ width: "70%" }}>
                          Notes(Details)
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.treatmentSession?.map((treatmentSessionModel, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly
                                  value={formData.treatmentSession?.[index]?.targetDate || ""}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly
                                  value={formData.treatmentSession?.[index]?.reviewDate}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <select
                                  className="submissionForm"
                                  id="4"
                                  value={formData.treatmentSession?.[index]?.statusCode || ""}
                                  onChange={e => {
                                    const updatedActivities = [...(formData.treatmentSession || [])];
                                    updatedActivities[index].statusCode = e.target.value as treatmentGoalStatusCode;
                                    setFormData({ ...formData, treatmentSession: updatedActivities });
                                  }}
                                >
                                  <option value={treatmentGoalStatusCode.A}>A</option>
                                  <option value={treatmentGoalStatusCode.O}>O</option>
                                  <option value={treatmentGoalStatusCode.R}>R</option>
                                  <option value={treatmentGoalStatusCode.D}>D</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset" }}
                                  id="name"
                                  readOnly
                                  value={formData.treatmentSession?.[index]?.notes}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                  <Card style={{ marginBottom: "0.5rem", width: "auto" }}>
                    <div className="row info-in-mobile">
                      <div>
                        <h6 className="fw-500 text-center">
                          <span className="me-3">A=Achieved</span>
                          <span className="me-3">O=Ongoing</span>
                          <span className="me-3">R=Revised</span>
                          <span>D=Discontinued</span>
                        </h6>
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
          </Row>
        </Card>
        </div>
      </div>

        {/* Form-2: Additional Goals (only render if has content) */}
        {(goalCount >= 2 || goalCount === 3) && (
        <div className="form-page">
          <Card className="BorderRadiusLeftRight m-0">
            <div className="mt-4"></div>
            {/*(goalCount >= 2 ||*/}
            {goalCount>=2 && (<Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ margin: "auto" }}>
                    TREATMENT GOALS
                  </h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div style={{ overflowX: "auto" }}>
                    <Card style={{ marginBottom: "0.5rem" }}>
                      <div className="row info-in-mobile">
                        <div>
                          <h6 className="fw-500 text-center" style={{ margin: "0.3rem" }}>
                            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>S</span>pecific&nbsp;
                            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>M</span>easurable&nbsp;
                            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>A</span>ttainable&nbsp;
                            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>R</span>elevant&nbsp;
                            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>T</span>ime-limited
                          </h6>
                        </div>
                      </div>
                    </Card>

                    <Card style={{ marginBottom: "0.5rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                            Goal Date:
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                            type="date"
                            id="goalDate2"
                            readOnly
                            value={formData.goalDate2 ? moment.utc(formData.goalDate2).format("YYYY-MM-DD") : ""}
                          />
                        </div>
                      </div>
                    </Card>

                    <Table className="clinical-table-bordered table-md">
                      <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "55%" }}>
                          Goal/Objectives
                        </td>
                        <td className="fw-500" style={{ width: "30%" }}>
                          Service/Intervention/Frequency
                        </td>
                        <td className="fw-500" style={{ width: "15%" }}>
                          Responsible Parties
                        </td>
                      </tr>
                      </thead>
                      <tbody style={{ verticalAlign: "top" }}>
                      <tr style={{ height: "2rem" }}>
                        <td>
                          <div className="col-auto text-left">
                            <div>
                              <div>
                                {formData.goalInformation2?.responsibleParties?.clientName
                                  ? formData.goalInformation2?.responsibleParties?.clientName
                                  : clientName}
                                s&apos; goal (in their words) is:{" "}
                              </div>
                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <textarea
                                      style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                      id="name"
                                      readOnly
                                      // placeholder={`Client Name will successfully transition into active treatment for identified concerns as evidenced by:\n1. Having scheduled, attended, and completed each session\n2. Having completed a Comprehensive Clinical Assessment, and\n3. Having developed a Treatment Plan\nby the end of Session #3.`}
                                      value={formData.goalInformation2?.goalObjective?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                        ?.replace(/&nbsp;/g, " ")
                                        ?.replace(/<[^>]*>/g, "") || ""
                                      }
                                    />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              <div>How will we know progress is being made?</div>
                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <textarea
                                      style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                      id="name"
                                      readOnly
                                      // placeholder={`How will we know progress is being made?`}
                                      value={formData.goalInformation2?.progressIdentifier?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                        ?.replace(/&nbsp;/g, " ")
                                        ?.replace(/<[^>]*>/g, "") || ""
                                      }
                                    />
                                </div>
                              </Card>
                            </div>
                            <div className="d-flex flex-col m-1">
                              <div>{clientName} wants to achieve this goal</div>
                              <textarea
                                style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                id="name"
                                readOnly
                                // placeholder={`How will we know progress is being made?`}
                                value={formData.goalInformation2?.timeframe || ""}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center">
                            <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                              <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <textarea
                                    style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                    id="name"
                                    readOnly
                                    // placeholder={`Individual Outpatient Therapy provided at least monthly`}
                                    value={formData.goalInformation2?.serviceInterventionFrequency?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                      ?.replace(/&nbsp;/g, " ")
                                      ?.replace(/<[^>]*>/g, "") || ""
                                    }
                                  />
                              </div>
                            </Card>
                          </div>
                        </td>
                        <td>
                          <div className="col-auto align-content-center">
                            <div>
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset", height: "50px" }}
                                  placeholder="Client Name"
                                  readOnly
                                  value={formData.goalInformation2?.responsibleParties?.clientName || ""}
                                />
                            </div>
                            <div>
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset", height: "100px" }}
                                  placeholder="LAVNI Therapist, Name and Credentials"
                                  readOnly
                                  value={
                                    formData.goalInformation2?.responsibleParties?.therapistNameCredentials
                                      ? `${formData.goalInformation2?.responsibleParties?.therapistNameCredentials},\nLavni Therapist`
                                      : ""
                                  }
                                />
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </Table>

                    <Table className="clinical-table-bordered table-md">
                      <thead style={{ verticalAlign: "middle" }}>
                      <tr>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Target Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Review Date
                        </td>
                        <td className="fw-500" style={{ width: "10%" }}>
                          Status Code
                        </td>
                        <td className="fw-500" style={{ width: "70%" }}>
                          Notes(Details)
                        </td>
                      </tr>
                      </thead>
                      <tbody>
                      {formData.treatmentSession2?.map((treatmentSessionModel, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly
                                  value={formData.treatmentSession2?.[index]?.targetDate || ""}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                  type="date"
                                  id="name"
                                  readOnly
                                  value={formData.treatmentSession2?.[index]?.reviewDate}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <select className="submissionForm" id="4" disabled value={formData.treatmentSession2?.[index]?.statusCode || ""}>
                                  <option value={treatmentGoalStatusCode.A}>A</option>
                                  <option value={treatmentGoalStatusCode.O}>O</option>
                                  <option value={treatmentGoalStatusCode.R}>R</option>
                                  <option value={treatmentGoalStatusCode.D}>D</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                  <textarea
                                    style={{ width: "100%", border: "none", backgroundColor: "unset" }}
                                    id="name"
                                    readOnly
                                    value={formData.treatmentSession2?.[index]?.notes}
                                  />
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                      </tbody>
                    </Table>
                    <Card style={{ marginBottom: "0.5rem" }}>
                      <div className="row info-in-mobile">
                        <div>
                          <h6 className="fw-500 text-center">
                            <span className="me-3">A=Achieved</span>
                            <span className="me-3">O=Ongoing</span>
                            <span className="me-3">R=Revised</span>
                            <span>D=Discontinued</span>
                          </h6>
                        </div>
                      </div>
                    </Card>


                  </div>
                </Card>
              </div>
            </Row>)}
            {goalCount==3&&(
              <Row>
                <div className="card-bodyDiv">
                  <Card className="match-card-header p-2 mb-2">
                    <h5 className="fw-500" style={{ margin: "auto" }}>
                      TREATMENT GOALS
                    </h5>
                  </Card>
                  <Card className="match-card-body p-3">
                    <div style={{ overflowX: "auto" }}>
                      <Card style={{ marginBottom: "0.5rem" }}>
                        <div className="row info-in-mobile">
                          <div>
                            <h6 className="fw-500 text-center" style={{ margin: "0.3rem" }}>
                              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>S</span>pecific&nbsp;
                              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>M</span>easurable&nbsp;
                              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>A</span>ttainable&nbsp;
                              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>R</span>elevant&nbsp;
                              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>T</span>ime-limited
                            </h6>
                          </div>
                        </div>
                      </Card>

                      <Card style={{ marginBottom: "0.5rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                              Goal Date:
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              style={{ width: "auto", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                              type="date"
                              id="goalDate3"
                              readOnly
                              value={formData.goalDate3 ? moment.utc(formData.goalDate3).format("YYYY-MM-DD") : ""}
                            />
                          </div>
                        </div>
                      </Card>

                      <Table className="clinical-table-bordered table-md">
                        <thead style={{ verticalAlign: "middle" }}>
                        <tr>
                          <td className="fw-500" style={{ width: "55%" }}>
                            Goal/Objectives
                          </td>
                          <td className="fw-500" style={{ width: "30%" }}>
                            Service/Intervention/Frequency
                          </td>
                          <td className="fw-500" style={{ width: "15%" }}>
                            Responsible Parties
                          </td>
                        </tr>
                        </thead>
                        <tbody style={{ verticalAlign: "top" }}>
                        <tr style={{ height: "2rem" }}>
                          <td>
                            <div className="col-auto text-left">
                              <div>
                                <div>{clientName ? clientName : "Client"}s&apos; goal (in their words) is:</div>
                                <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                  <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <textarea
                                      style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                      id="name"
                                      readOnly
                                      // placeholder={`Client Name will successfully transition into active treatment for identified concerns as evidenced by:\n1. Having scheduled, attended, and completed each session\n2. Having completed a Comprehensive Clinical Assessment, and\n3. Having developed a Treatment Plan\nby the end of Session #3.`}
                                      value={formData.goalInformation3?.goalObjective?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                        ?.replace(/&nbsp;/g, " ")
                                        ?.replace(/<[^>]*>/g, "") || ""
                                      }
                                    />
                                  </div>
                                </Card>
                              </div>
                              <div className="d-flex flex-col m-1">
                                <div>How will we know progress is being made?</div>
                                <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                  <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                    <textarea
                                      style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                      id="name"
                                      readOnly
                                      // placeholder={`How will we know progress is being made?`}
                                      value={formData.goalInformation3?.progressIdentifier?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                        ?.replace(/&nbsp;/g, " ")
                                        ?.replace(/<[^>]*>/g, "") || ""
                                      }
                                    />
                                  </div>
                                </Card>
                              </div>
                              <div className="d-flex flex-col m-1">
                                <div>{clientName} wants to achieve this goal</div>
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                  id="name"
                                  readOnly
                                  // placeholder={`How will we know progress is being made?`}
                                  value={formData.goalInformation3?.timeframe || ""}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="col-auto align-content-center">
                              <Card className="bg-light-gray" style={{ height: "150px", marginBottom: "0.1rem" }}>
                                <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                  <textarea
                                    style={{ width: "100%", border: "none", backgroundColor: "unset", height: "150px" }}
                                    id="name"
                                    readOnly
                                    // placeholder={`Individual Outpatient Therapy provided at least monthly`}
                                    value={formData.goalInformation3?.serviceInterventionFrequency?.replace(/<li>|<\/li>|<p>|<\/p>/g, "\n")
                                      ?.replace(/&nbsp;/g, " ")
                                      ?.replace(/<[^>]*>/g, "") || ""
                                    }
                                  />
                                </div>
                              </Card>
                            </div>
                          </td>
                          <td>
                            <div className="col-auto align-content-center">
                              <div>
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset", height: "50px" }}
                                  placeholder="Client Name"
                                  readOnly
                                  value={formData.goalInformation3?.responsibleParties?.clientName || ""}
                                />
                              </div>
                              <div>
                                <textarea
                                  style={{ width: "100%", border: "none", backgroundColor: "unset", height: "100px" }}
                                  placeholder="LAVNI Therapist, Name and Credentials"
                                  readOnly
                                  value={
                                    formData.goalInformation3?.responsibleParties?.therapistNameCredentials
                                      ? `${formData.goalInformation3?.responsibleParties?.therapistNameCredentials},\nLavni Therapist`
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </Table>

                      <Table className="clinical-table-bordered table-md">
                        <thead style={{ verticalAlign: "middle" }}>
                        <tr>
                          <td className="fw-500" style={{ width: "10%" }}>
                            Target Date
                          </td>
                          <td className="fw-500" style={{ width: "10%" }}>
                            Review Date
                          </td>
                          <td className="fw-500" style={{ width: "10%" }}>
                            Status Code
                          </td>
                          <td className="fw-500" style={{ width: "70%" }}>
                            Notes(Details)
                          </td>
                        </tr>
                        </thead>
                        <tbody>
                        {formData.treatmentSession3?.map((treatmentSessionModel, index) => (
                          <>
                            <tr key={index}>
                              <td>
                                <div className="col-auto">
                                  <input
                                    style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                    type="date"
                                    id="name"
                                    readOnly
                                    value={formData.treatmentSession3?.[index]?.targetDate || ""}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-auto">
                                  <input
                                    style={{ width: "80%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                    type="date"
                                    id="name"
                                    readOnly
                                    value={formData.treatmentSession3?.[index]?.reviewDate}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-auto">
                                  <select className="submissionForm" id="4" disabled value={formData.treatmentSession3?.[index]?.statusCode || ""}>
                                    <option value={treatmentGoalStatusCode.A}>A</option>
                                    <option value={treatmentGoalStatusCode.O}>O</option>
                                    <option value={treatmentGoalStatusCode.R}>R</option>
                                    <option value={treatmentGoalStatusCode.D}>D</option>
                                  </select>
                                </div>
                              </td>
                              <td>
                                <div className="col-auto">
                                  <textarea
                                    style={{ width: "100%", border: "none", backgroundColor: "unset" }}
                                    id="name"
                                    readOnly
                                    value={formData.treatmentSession3?.[index]?.notes}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                        </tbody>
                      </Table>
                      <Card style={{ marginBottom: "0.5rem" }}>
                        <div className="row info-in-mobile">
                          <div>
                            <h6 className="fw-500 text-center">
                              <span className="me-3">A=Achieved</span>
                              <span className="me-3">O=Ongoing</span>
                              <span className="me-3">R=Revised</span>
                              <span>D=Discontinued</span>
                            </h6>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Card>
                </div>
              </Row>
            )}


          </Card>
        </div>
        )}


      {/* Form-3: SIGNATURES */}
      <div className="form-page">
      <Card className="BorderRadiusLeftRight m-0">
          <div className="mt-4"></div>
          <Row>
            <div className="card-bodyDiv">
              <Card className="match-card-header p-2 mb-2">
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  SIGNATURES
                </h5>
              </Card>

              <Card className="match-card-body p-3">
                <Row>
                  <h6 className="fw-600">
                    I confirm my involvement in the development of this Treatment Plan. My signature means that I agree with the goals developed and services to
                    be provided.
                  </h6>
                </Row>

                {!isClientSigned && (
                  <Row>
                    <div className="card-bodyDiv mt-3">
                      <Card className="match-card-body-second p-3">
                        <h6 className="fw-200">
                          Note: The client signature will appear here after it has been signed by the client or the legally responsible person(LRP).
                        </h6>
                      </Card>
                    </div>
                  </Row>
                )}

                {clientSignature && formData.clientSignature != undefined && (
                  <Row>
                    <div className="row justify-content-between" style={{ textAlign: "center" }}>
                      <div className="col-lg-3 md-12 m-2">
                        <div className="d-flex flex-column justify-content-between">
                          <div className="sign">{formData?.clientSignature && <img className="sigCanvasNew" src={formData?.clientSignature} />}</div>
                        </div>
                        <div className="col-auto mt-3">
                          <h6 className="fw-500">Signature of Client</h6>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          id="clientName"
                          readOnly
                          value={formData.clientSignatureDetails?.name ||clientName|| ""}
                        />
                        <h6 className="fw-500">Client Name</h6>
                      </div>

                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          readOnly
                          value={
                            formData.clientSignatureDetails?.date ? moment.utc(formData.clientSignatureDetails?.date).format("MM-DD-YYYY") : (
                              formData.planCreationDate ? moment.utc(formData.planCreationDate).format("MM-DD-YYYY") : ""
                            )
                          }
                        />
                        <h6 className="fw-500">Date</h6>
                      </div>
                      <div className="col-sm-3 m-2"></div>
                    </div>
                  </Row>
                )}

                {lrpSignature && formData.lrpSignature != undefined && (
                  <Row>
                    <div className="row justify-content-between" style={{ textAlign: "center" }}>
                      <div className="col-lg-3 md-12 m-2">
                        <div className="d-flex flex-column justify-content-between">
                          <div className="sign">{formData?.lrpSignature && <img className="sigCanvasNew" src={formData?.lrpSignature} />}</div>
                        </div>
                        <div className="col-auto mt-3">
                          <h6 className="fw-500">Signature of LRP</h6>
                          <div className="col-auto fw-500 mb-1" style={{ fontSize: "smaller" }}>
                            <i>(Legally Responsible Person, if not client)</i>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          readOnly
                          value={formData.lrpSignatureDetails?.name || ""}
                        />
                        <h6 className="fw-500">LRP Name</h6>
                      </div>

                      <div className="col-lg-2 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          readOnly
                          value={formData.lrpSignatureDetails?.relationship || ""}
                        />
                        <h6 className="fw-500">LRP Relationship</h6>
                      </div>

                      <div className="col-lg-2 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="date"
                          readOnly
                          value={formData.lrpSignatureDetails?.date ? moment.utc(formData.lrpSignatureDetails?.date).format("YYYY-MM-DD") : ""}
                        />
                        <h6 className="fw-500">Date</h6>
                      </div>
                      <div className="col-lg-2 col-md-12 m-2"></div>
                    </div>
                  </Row>
                )}

                {/* <Row>
                  <h6 className="fw-600">My signature below confirms Medical Necessity for services requested is present and constitutes the Service Order.</h6>
                </Row> */}

                {therapistCategorizationByType === "ASSOCIATE" && (
                  <Row>
                    <div className="row justify-content-between" style={{ textAlign: "center" }}>
                      <div className="col-lg-3 md-12 m-2">
                        <div className="d-flex flex-column justify-content-between">
                          <div className="sign">{formData?.clinicianSignature && <img className="sigCanvasNew" src={formData?.clinicianSignature} />}</div>
                        </div>
                        <div className="col-auto mt-3">
                          <h6 className="fw-500">Associate Clinician Signature</h6>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          id="clinicianName"
                          readOnly
                          value={formData.clinicianSignatureDetails?.name || ""}
                        />
                        <h6 className="fw-500">Clinician Name</h6>
                      </div>

                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="date"
                          readOnly
                          value={formData.clinicianSignatureDetails?.date ? moment.utc(formData.clinicianSignatureDetails?.date).format("MM-DD-YYYY") : ""}
                        />
                        <h6 className="fw-500">Date</h6>
                      </div>
                      <div className="col-sm-3 m-2"></div>
                    </div>
                  </Row>
                )}

                <Row>
                  <h6 className="fw-600">My signature below confirms Medical Necessity for services requested is present and constitutes the Service Order.</h6>
                </Row>

                {(therapistCategorizationByType === "LICENSED"||therapistCategorizationByType === "ASSOCIATE") && (
                  <Row>
                    <div className="row justify-content-between" style={{ textAlign: "center" }}>
                      <div className="col-lg-3 md-12 m-2">
                        <div className="d-flex flex-column justify-content-between">
                          <div className="sign">
                            {formData?.clinicianTwoSignature && <img className="sigCanvasNew" src={formData?.clinicianTwoSignature} />}
                          </div>
                        </div>
                        <div className="col-auto mt-3">
                          <h6 className="fw-500">Fully Licensed Professional Signature</h6>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="text"
                          id="clinicianName"
                          readOnly
                          value={formData.clinicianTwoSignatureDetails?.name || ""}
                        />
                        <h6 className="fw-500">Clinician Name</h6>
                      </div>

                      <div className="col-lg-3 col-md-12 m-2 signatureAssesment1">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid gray",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            textAlign: "center",
                          }}
                          type="date"
                          readOnly
                          value={formData.clinicianTwoSignatureDetails?.date ? moment.utc(formData.clinicianTwoSignatureDetails?.date).format("MM-DD-YYYY") : ""}
                        />
                        <h6 className="fw-500">Date</h6>
                      </div>

                      <div className="col-sm-3 m-2"></div>
                    </div>
                    <div className="col-12 text-center mt-2">
                      <div className="fw-500" style={{ fontSize: "smaller" }}>
                        <i>(MD/DO/NP/PA/PhD signature REQUIRED for Associate Level Professionals)</i>
                      </div>
                    </div>
                  </Row>
                )}
              </Card>
            </div>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default TherapyPlanForm;
