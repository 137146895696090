import moment from "moment";
import React, { useEffect, useState } from "react";
import { CardBody, Card, CardTitle, Container } from "reactstrap";
import { TreatmentHistoryData } from "../../models/TreatmentHistory";
import { VideoChatService } from "../../services/VideoChatService";
import SessionHistoryModalForAdmin from "../Popup/SessionHistoryModalForAdmin";
import { useHistory, useParams } from "react-router-dom";
import { ParameterTypes } from "../../utils/ParameterTypes";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Spinner from "../../common/spinner/spinner";
import SessionHistoryPIEModalForAdmin from './../Popup/SessionHistoryPIEModalForAdmin';

interface ClientSessionsViewProps {
  therapistId?: string;
}

const ClientSessionsView: React.FC<ClientSessionsViewProps> = ({ therapistId }: ClientSessionsViewProps) => {
  const [isTreatmentLoading, setIsTreatmentLoading] = useState(false);
  const [isTreatmentError, setIsTreatmentError] = useState(false);
  const [offset, setOffset] = useState<number>(1);
  const [isDiagnosisNoteOpen, setIsDiagnosisNoteOpen] = useState(false);
  const [diagnosisId, setDiagnosisId] = useState("");
  const [treatmentHistoryDetails, setTreatmentHistory] = useState<TreatmentHistoryData[]>([] as TreatmentHistoryData[]);
  const { userId } = useParams<ParameterTypes>();
  const history = useHistory();
  const [noteType, setNoteType] = useState<string | undefined>();
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const [showPIENote, setShowPIENote] = useState(false);

  useEffect(() => {
    if (diagnosisId != "") {
      setIsDiagnosisNoteOpen(true);
    }
  }, [diagnosisId]);

  useEffect(() => {
    setIsTreatmentError(false);
    if (userId) getTreatmentHistoryNotesInitial(userId);
  }, []);

  const getTreatmentHistoryNotesInitial = (id: string) => {
    setIsTreatmentLoading(true);
    setOffset(1);
    VideoChatService.getTreatmentHistoryForClientByAdmin(id).then(res => {
      if (res.success) {
        setIsTreatmentError(false);
        setTreatmentHistory(res.data);
        setIsTreatmentLoading(false);
      } else {
        setTimeout(() => {
          setIsTreatmentLoading(false);
        }, 500);
        setIsTreatmentError(true);
      }
    });
  };

  useEffect(() => {
  if (isDiagnosisNoteOpen && (noteType)) {

  } else {

  }
}, [isDiagnosisNoteOpen, noteType, showPIENote, showAiGeneratedPopup]);

  const showDiagnosis = (diagnosisId: string) => {
    if (diagnosisId) {
      setDiagnosisId(diagnosisId);

       const filteredTreatmentDetails = treatmentHistoryDetails.filter((tr) => tr?.diagnosisNoteDetails?._id === diagnosisId);
       const type = filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType;

        if(!type && filteredTreatmentDetails[0]?.diagnosisNoteDetails?.updatedByTherapist) {
            setNoteType('SOAP');
            setShowAiGeneratedPopup(true);

            setShowPIENote(false);

      }
       else if(type) {
          console.log("condition 02")
          setNoteType(filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType);
          if(filteredTreatmentDetails[0]?.diagnosisNoteDetails?.noteType == 'SOAP') {
              setShowAiGeneratedPopup(true);
              setShowPIENote(false);
            } else {
                 setShowPIENote(true);
                 setShowAiGeneratedPopup(false);
              }
      }
       else {
          console.log("condition 04")
          setNoteType(undefined)

      }
    }
  };

  const closeDiagnosis = () => {
    setDiagnosisId("");
    setIsDiagnosisNoteOpen(false);
  };

  const back = () => {
    history.goBack();
  };

  return (
    <React.Fragment>

      {
                       isDiagnosisNoteOpen && (noteType) ? (
                        (noteType == 'PIE' ) ? (
                                         showPIENote && <SessionHistoryPIEModalForAdmin
                                            setShowModal={closeDiagnosis}
                                            showModal={isDiagnosisNoteOpen}
                                            userId={diagnosisId}
                                            isVersion={false}
                                           />

                                         ) : (
                                        showAiGeneratedPopup &&  <SessionHistoryModalForAdmin
                                           setShowModal={closeDiagnosis}
                                           showModal={isDiagnosisNoteOpen}
                                           userId={diagnosisId}
                                           isVersion={false}
                                             />
                                          )

                                        )
                                        : (
                                       <SessionHistoryModalForAdmin
                                          setShowModal={closeDiagnosis}
                                          showModal={isDiagnosisNoteOpen}
                                          userId={diagnosisId}
                                          isVersion={false}
                                         />

                                       )

                    }

      {/* {isDiagnosisNoteOpen && <SessionHistoryModalForAdmin setShowModal={closeDiagnosis} showModal={isDiagnosisNoteOpen} userId={diagnosisId} />} */}

      {isTreatmentLoading ? (
        <Spinner className="bouncer"></Spinner>
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Lavni" breadcrumbItem="Sessions" />
            <div className="flex flex-wrap justify-content-between">
              <div className="flex cursor-pointer" onClick={() => back()}>
                <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                <CardTitle className="mb-4  cursor-pointer">&nbsp; Back</CardTitle>
              </div>
            </div>

            {!isTreatmentLoading && isTreatmentError && (
              <div style={{ textAlign: "center" }}>
                <h5>Server error occurred</h5>
              </div>
            )}

            {!isTreatmentLoading && !isTreatmentError && treatmentHistoryDetails && treatmentHistoryDetails.length == 0 && (
              <div style={{ textAlign: "center" }}>
                <h5>No sessions created yet</h5>
              </div>
            )}

            {!isTreatmentLoading &&
              !isTreatmentError &&
              treatmentHistoryDetails?.map((tr: any, index) => {
                return (
                  <Card key={index} onClick={() => { }} className="cursor_pointer">
                    {tr.isMeetingTranscribe && (
                      <CardBody>
                        {tr?.meetingId?.appointmentId ? (
                          <>
                            <h5 className="font-size-15 mb-1">
                              Appointment Start Time <span className="session-h5">{moment(tr?.appointmentDetails?.start || null).format("YYYY-MM-DD h:mm:ss a")}</span> / Meeting Started at <span className="session-h5">{moment(tr?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                            </h5>

                          </>
                        ) : (
                          <>
                            <h5 className="font-size-15 mb-1">
                              Meeting Started At <span className="session-h5">{moment(tr?.meetingStartedTime || null).format("YYYY-MM-DD h:mm:ss a")}</span>
                            </h5>
                          </>
                        )}

                        {tr.isMeetingTranscribe && (
                          <a
                            onClick={() => {
                              if (tr.isMeetingTranscribe) {
                                showDiagnosis(tr?.diagnosisnote?._id);
                              }
                            }}
                            className="btn btn-secondary "
                            style={{ marginTop: "10px" }}
                          >
                            View / Edit Notesssss
                          </a>
                        )}
                      </CardBody>
                    )}
                  </Card>
                );
              })}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClientSessionsView;
