import React, { useEffect, useState } from "react";
import { TabPane, Row, Input, Card } from "reactstrap";
import { Phrase, RefNames, Stages } from "../../../models/Notes";
import CreateEditPhrase from "../../Popup/CreateEditPhrase";

interface ChildTabProps {
  message: string;
  tabId: string;
  states: Phrase[];
  tabType: string;
  chiefComplaint?: string;
  assessments?: string;
  carePlan?: string;
  procedureNotes?: string;
  element?: string;
  historyOfPresenseIllness?: string;
  mentalBehaviouralStatus?: string;
  setCarePlan?: (value: string) => void;
  setProcedureNotes?: (value: string) => void;
  setChiefComplaint?: (value: string) => void;
  setAssessments?: (value: string) => void;
  setHistoryOfPresenseIllness?: (value: string) => void;
  setMentalBehaviouralStatus?: (value: string) => void;
}

export interface EditProps {
  currentPosition: number;
  state: Phrase;
}

const ChildTab: React.FC<ChildTabProps> = ({
  message,
  tabId,
  states,
  tabType,
  chiefComplaint,
  assessments,
  carePlan,
  procedureNotes,
  element,
  historyOfPresenseIllness,
  mentalBehaviouralStatus,
  setCarePlan,
  setAssessments,
  setChiefComplaint,
  setProcedureNotes,
  setHistoryOfPresenseIllness,
  setMentalBehaviouralStatus,
}: ChildTabProps) => {
  const [content, setContent] = useState<Phrase[]>([]);
  const [displayCreateModal, setDisplayCreateModal] = useState<boolean>(false);
  const [displayEditModal, setDisplayEditModal] = useState<boolean>(false);
  const [editProps, setEditProps] = useState<EditProps>({} as EditProps);

  const SUBJECTIVE_TABS = [
    "relational/01",
    "trauma/02",
    "identity/03",
    "process/04",
    "mourning_loss/05",
    "adjustment/06",
    "daily_life/07",
    "specific_difficulties/08",
  ];

  const OBJECTIVE_TABS = ["affective_state/09", "mental_state/10"];

  const ASSESSMENT_TABS = ["global_assessment/11", "level_of_functioning/12", "significant_developments/13", "outstanding_issues/14"];

  useEffect(() => {
    const sortedStates = groupBy(states, "priority");
    setContent(sortedStates);
  }, [states]);

  const handleSearch = (searchValue: string) => {
    const filteredList = states.filter((phrase: Phrase) => phrase.value.toLowerCase().includes(searchValue.toLowerCase()));
    setContent(filteredList);
  };

  const findCurrentStage = (tabType: string) => {
    if (SUBJECTIVE_TABS.includes(tabType)) {
      return Stages.SUBJECTIVE;
    } else if (OBJECTIVE_TABS.includes(tabType)) {
      return Stages.OBJECTIVE;
    } else if (ASSESSMENT_TABS.includes(tabType)) {
      return Stages.ASSESSMENT;
    } else {
      return Stages.PLAN;
    }
  };

  const onContentSelect = (id: number) => {
    const selectedContent = document?.getElementById(id.toString() + tabType)?.innerHTML;
    const currentStage = findCurrentStage(tabType);

    if (selectedContent) {
      if (currentStage == Stages.SUBJECTIVE) {
        if (element && element == RefNames.CHIEF_COMPLAINT)
          if (setChiefComplaint) setChiefComplaint(chiefComplaint + " " + selectedContent.toLocaleLowerCase() + ";");

        if (element && element == RefNames.HISTORY_OF_PRESENT_ILLNESS)
          if (setHistoryOfPresenseIllness) setHistoryOfPresenseIllness(historyOfPresenseIllness + " " + selectedContent.toLocaleLowerCase() + ";");
      }

      if (currentStage == Stages.OBJECTIVE) {
        if (setMentalBehaviouralStatus) setMentalBehaviouralStatus(mentalBehaviouralStatus + " " + selectedContent.toLocaleLowerCase() + ";");
      }

      if (currentStage == Stages.ASSESSMENT) {
        if (setAssessments) setAssessments(assessments + " " + selectedContent.toLocaleLowerCase() + ";");
      }

      if (currentStage == Stages.PLAN) {
        if (element && element == RefNames.CARE_PLAN) if (setCarePlan) setCarePlan(carePlan + " " + selectedContent.toLocaleLowerCase() + ";");

        if (element && element == RefNames.PROCEDURE_NOTES)
          if (setProcedureNotes) setProcedureNotes(procedureNotes + " " + selectedContent.toLocaleLowerCase() + ";");
      }
    }
  };

  const groupBy = (dataList: any[], key: any): any => {
    let returnValue;

    if (dataList.length > 0) {
      const pivotIndex = Math.floor((dataList.length - 1) / 2);
      const pivotItem = dataList[pivotIndex];
      const less: any[] = [];
      const more: any[] = [];

      dataList.splice(pivotIndex, 1);
      dataList.forEach((value: any) => {
        value[key] <= pivotItem[key] ? less.push(value) : more.push(value);
      });

      returnValue = groupBy(less, key).concat([pivotItem], groupBy(more, key));
    } else {
      returnValue = dataList;
    }

    return returnValue;
  };

  const showCreateModal = (event: any) => {
    event.preventDefault();
    setDisplayCreateModal(!displayCreateModal);
  };

  const showEditModal = (event: any, state: Phrase) => {
    event.preventDefault();

    const currentPosition = content.findIndex((phrase: Phrase) => phrase._id == state._id);

    const data: EditProps = {
      currentPosition: currentPosition,
      state: state,
    };

    setEditProps(data);
    setDisplayEditModal(!displayEditModal);
  };

  return (
    <>
      <CreateEditPhrase
        showModal={displayCreateModal}
        lastItem={content[content.length - 1]}
        tabType={tabType}
        content={content}
        modalType={"create"}
        setContent={setContent}
        findCurrentStage={findCurrentStage}
        setShowModal={setDisplayCreateModal}
      />

      <CreateEditPhrase
        editProps={editProps}
        showModal={displayEditModal}
        content={content}
        modalType={"edit"}
        setContent={setContent}
        setShowModal={setDisplayEditModal}
      />

      <TabPane tabId={tabId}>
        <Row className="note-content-body mb-3">
          <div className="search-box chat-search-box mb-3 mt-3" style={{ borderRadius: "5px" }}>
            <div className="position-relative">
              <Input
                id="search-user"
                type="text"
                className="form-control"
                placeholder="Search..."
                style={{ borderRadius: "5px" }}
                onChange={event => handleSearch(event.target.value)}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
          <div className="flex justify-content-between align-items-baseline">
            <p className="m-0">
              <strong>{message}</strong>
            </p>
            <div>
              <button
                className="btn btn-primary"
                onClick={event => {
                  showCreateModal(event);
                }}
              >
                <i className="fa fa-plus"> Add new</i>
              </button>
            </div>
          </div>
          <div className="goal-container mb-3">
            {content &&
              content.length > 0 &&
              content.map((state: Phrase, index: number) => {
                return (
                  <Card className="phrase" key={index}>
                    <div className="flex justify-content-between">
                      <div
                        className="p-2 cursor-pointer"
                        style={{ width: "90%" }}
                        id={index.toString() + tabType}
                        contentEditable={false}
                        onClick={() => onContentSelect(index)}
                      >
                        {state.value}
                      </div>
                      {!state.systemGenerated && (
                        <div className="note-edit" onClick={event => showEditModal(event, state)}>
                          Edit <i className="fa fa-pencil-alt"></i>
                        </div>
                      )}
                    </div>
                  </Card>
                );
              })}
          </div>
        </Row>
      </TabPane>
    </>
  );
};

export default ChildTab;
