import { RepeatInfo } from "./subModel/RepeatInfo";
import { Therapist } from "./Therapist";

export enum NoteType {
  PIE = 'PIE',
  SOAP= 'SOAP'
}


export interface DiagnosisNoteFromResponse {
  _id: string;
  clientId: any;
  therapistId: any;
  meetingId: any;
  updated: boolean;
  updatedByTherapist: boolean;
  patientID: string;
  patientAcountNo: string;
  encounterID: string;
  encounterDate: Date;
  encounterType: string;
  cptCode: string;
  // gg
  chiefComplaint: string;
  historyOfPresentIllness: string;
  historyOfPresentIllnessAttachments: any[];
  diagnosisICDcodes: any[];
  secondaryDiagnosisICDcodes: any[];
  mentalBehavioralStatus: string;
  mentalBehavioralStatusAttachments: any[];
  asssessments: string;
  assessmentAttachments: any[];
  procedureNotes: string;
  carePlan: string;
  signature: any;
  carePlanAttachments: any[];
  selectedGoals: any[];
  intervention: string;
  noteType?: string | null;
  //procedureCodes: ProcedureCodeModel[]
}
export interface DiagnosisNoteForUpdate {
  _id: string;
  chiefComplaint: string;
  historyOfPresentIllness: string;
  historyOfPresentIllnessAttachments: any[];
  diagnosisICDcodes: any[];
  secondaryDiagnosisICDcodes: any[];
  cptCode: string;
  mentalBehavioralStatus: string;
  mentalBehavioralStatusAttachments: any[];
  asssessments: string;
  assessmentAttachments: any[];
  procedureNotes: string;
  carePlan: string;
  carePlanAttachments: any[];
  selectedGoals: any[];
  signature:object;
  historyOfPresentIllnessAttachmentsIdsForDelete: any[];
  mentalBehavioralStatusAttachmentsIdsForDelete: any[];
  assessmentAttachmentsIdsForDelete: any[];
  carePlanAttachmentsIdsForDelete: any[];
  intervention?: string;
  noteType?: string | null;
  //procedureCodes: ProcedureCodeModel[]
  reasonForEditing?: string;
}

export interface TherapistSession {
  _id: ClientDetails;
  notes: NoteList[];
}

export interface ClientSession {
  _id: TherapistDetails;
  notes: NoteList[];
}

export interface ClientDetails {
  clientId: string;
  fullName: string;
}

export interface TherapistDetails {
  therapistId: string;
  fullName: string;
}

export interface NoteList {
  clientId: string;
  diagnosisNoteId: string;
  meetingStartedTime: string;
  appointmentStart?: string;
  isMeetingTranscribe: boolean;
  appointmentId?: string;
  claimStatus?: string;
  errorMsg?: string;
  noteType?: string | null;
  updatedByTherapist?: boolean;
  createdAt: Date;
  diagnosisNoteVersions?: DiagnosisNoteVersion[];
}

export interface ProcedureCodeModel {
  procedureCode: string;
  procedureIdentifier: string;
}

export interface CPTCode {
  cptCode:string;
  cptCodeDescription: string;
}

export interface DiagnosisNoteVersionIdentifier {
  isVersion: boolean;
}

export interface DiagnosisNoteVersion {
  _id: string;
  updatedByTherapist: boolean;
  noteType: string;
  reasonForEdit: string;
  versionCreatedAt: Date;
}
