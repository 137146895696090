import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, Col, Label, Row, Modal, Container } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { WorkingHour } from "../../../models/WorkingHour";
import UserContext from "../../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import moment from "moment";
import { CommonService } from "../../../services/CommonService";
import { UserDetails2 } from "../../../models/User";
import { useHistory } from "react-router-dom";
import { Util } from "../../../Util";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import CustomTimePicker2 from "src/components/CustomTimePicker2";
import { RouteName } from "src/RouteName";

const TherapistWorkingDaysOnboardingPage: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([] as WorkingHour[]);
  const [modalCenterWorkingHours, setModalCenterWorkingHours] = useState(false);
  const [newWorkingHour, setNewWorkingHour] = useState({
    startTime: "0:00 AM",
    endTime: "0:00 AM",
    day: "",
  });

  const history = useHistory();
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [isHours, setIsHours] = useState<string[]>([]);
  const locale = "en";
  const hours: string[] = [];
  const [statementFile, setStatementFile] = useState({ preview: "", preview2: "", raw: "" });
  const statementFileRef = useRef<any>();
  statementFileRef.current = statementFile;
  const [selectedDates, setSelectedDates] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);

  type Value = Date | string | null;
  const [startTimeValue, setStartTimeValue] = useState<string | null>(null);
  const [endTimeValue, setEndTimeValue] = useState<string | null>(null);
  const dinput = document.getElementById("mui-1") as HTMLButtonElement | null;
  const dinput2 = document.getElementById("mui-2") as HTMLButtonElement | null;

  if (dinput != null) {
    dinput.disabled = true;
  }

  if (dinput2 != null) {
    dinput2.disabled = true;
  }

  useEffect(() => {
    const workingHours = user?.workingHours;

    if (workingHours) {
      setWorkingHours(workingHours);
    }

    const workingDays = user?.workingHours?.map(c => c.day);

    // const newList = selectedDates.filter(function (word) {
    //   return !workingDays?.includes(word);
    // });
    // setSelectedDates(newList);
    createHoursForTimeSlots();
  }, []);

  const setEndTime = (e: any) => {
    setSelectedEndTime(e);
    setNewWorkingHour({ ...newWorkingHour, endTime: moment(e, "HH:mm").format("HH:mm a") });
  };

  const createHoursForTimeSlots = () => {
    moment.locale(locale); // optional - can remove if you are only dealing with one locale
    for (let hour = 0; hour < 24; hour++) {
      hours.push(moment({ hour }).format("H:mm A"));
      hours.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }
    setIsHours(hours);
  };
  function togCenterWorkingHoursClose() {
    setModalCenterWorkingHours(false);
  }
  function togCenterWorkingHours() {
    setModalCenterWorkingHours(!modalCenterWorkingHours);
  }

  const setNewWorkingDay = (e: any) => {
    setNewWorkingHour({ ...newWorkingHour, day: e });
  };

  // const updateTimeSlots = () => {
  //   if (newWorkingHour.day == "" || newWorkingHour.startTime == "" || newWorkingHour.endTime == "") {
  //     toast.error("Please fill all working hours details!", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });

  //     return false;
  //   } else if (moment(newWorkingHour.startTime, ["h:mm A"]).format("HH:mm") >= moment(newWorkingHour.endTime, ["h:mm A"]).format("HH:mm")) {
  //     toast.error("Your end time should be after the start time.", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       className: "foo-bar",
  //     });
  //   } else {
  //     // setSelectedDates(selectedDates.filter(tag => tag != newWorkingHour.day));
  //     setWorkingHours([...workingHours, newWorkingHour]);
  //     setNewWorkingHour({ ...newWorkingHour, day: "" });
  //     togCenterWorkingHoursClose();
  //   }
  // };
  const updateTimeSlots = () => {
    if (newWorkingHour.day == "" || newWorkingHour.startTime == "" || newWorkingHour.endTime == "") {
      toast.error("Please fill all working hours details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (Util.convertUTCDateToLocalDate(newWorkingHour.startTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) {
      toast.error("Your end time should be after the start time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const overlap = workingHours.some(existingHour => {
        return (
          existingHour.day === newWorkingHour.day &&
          ((Util.convertUTCDateToLocalDate(existingHour.startTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
            Util.convertUTCDateToLocalDate(existingHour.startTime) < Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) ||
            (Util.convertUTCDateToLocalDate(existingHour.endTime) > Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
              Util.convertUTCDateToLocalDate(existingHour.endTime) <= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) ||
            (Util.convertUTCDateToLocalDate(existingHour.startTime) <= Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
              Util.convertUTCDateToLocalDate(existingHour.endTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)))
        );
      });

      if (overlap) {
        toast.error("Working hours overlap with existing hours.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        setWorkingHours([...workingHours, newWorkingHour]);
        setNewWorkingHour({ ...newWorkingHour, day: "" });
        togCenterWorkingHoursClose();
      }
    }
  };

  const saveFilterWorkingHours = (value: any) => {
    setWorkingHours(workingHours?.filter(deleteValue => value != (deleteValue as any)));
    setSelectedDates([...selectedDates, value.day]);
  };

  const updateTherapistDetails = () => {
    const updatedUser: UserDetails2 = {
      firstname: user?.firstname,
      lastname: user?.lastname,
      username: user?.username,
      gender: user?.gender,
      dateOfBirth: user?.dateOfBirth,
      email: user?.email,
      zipCode: user?.zipCode,
      state: user?.state,
      primaryPhone: user?.primaryPhone,
      deletingStatementId: "none",
      ethnicityId: user?.ethnicityId?._id as any,
      description: user?.description,
      profession: user?.profession?._id,
      professionLicense: user?.professionLicense?._id,
      yearsOfExperience: user?.yearsOfExperience,
      experiencedIn: user?.experiencedIn?.map(tag => {
        return tag.experienceTag;
      }),
      insuranceCompanies: user?.insuranceCompanies?.map(tag => {
        return tag.insuranceCompany;
      }),
      workingHours: workingHours,
      timeZone: user?.timeZone,
      caqhUserName: user?.caqhUserName,
      caqhPassword: user?.caqhPassword,
      medicaidUsername: user?.medicaidUsername,
      MedicaidPassword: user?.MedicaidPassword,
      psychologyTodayUsername: user?.psychologyTodayUsername,
      psychologyTodayPassword: user?.psychologyTodayPassword,
    };
    CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
      if (res.success) {
        setUser(res.data);
        history.push(RouteName.THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  return (
    <>
      <div className="page-content">
        <Container className="containerOnboarding">
          <div className="card-heading mb-2">
            <h5 className="font-size-25 profileFont mb-4 text-center">Welcome to Lavni</h5>
          </div>
          <Row className="p-4">
            <Col xl={12}>
              <Card className="BorderRadiusLeftRight p-3">
                <div className="card-bodyDiv">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-20  profileFont mb-4 mb-2">Working days and hours</h5>
                  </div>
                </div>

                <Row>
                  <Col xl={8}>
                    {user?.role == "THERAPIST" && (
                      <div>
                        <div className="py-0 w-full mb-4" style={{ width: "100%" }}>
                          {workingHours &&
                            workingHours.map((opt, index) => (
                              <Row className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2 timing" key={index}>
                                <Col className="col-10">
                                  {opt.day} | {Util.convertUTCDateToLocalDate12Hour(opt.startTime)} - {Util.convertUTCDateToLocalDate12Hour(opt.endTime)}
                                </Col>
                                <Col className="col-2">
                                  <i
                                    onClick={() => saveFilterWorkingHours(opt as any)}
                                    style={{ borderColor: "#414141" }}
                                    className="fas fa-times-circle  cursor_pointer"
                                  ></i>
                                </Col>
                              </Row>
                            ))}
                        </div>

                        <div className="py-0 w-full mb-4" style={{ width: "100%" }}>
                          <Row className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2 timing">
                            <Col className="col-12">
                              <button
                                className="btn btn-sm dotted "
                                onClick={() => {
                                  togCenterWorkingHours();
                                }}
                              >
                                Add New
                              </button>
                            </Col>
                          </Row>
                        </div>

                        <Modal
                          isOpen={modalCenterWorkingHours}
                          toggle={() => {
                            togCenterWorkingHours();
                          }}
                          centered
                        >
                          <div className="modal-header">
                            <h5 className="font-size-14 mb-2">Working days and hours:</h5>
                            <button
                              type="button"
                              onClick={() => {
                                togCenterWorkingHoursClose();
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span>&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="py-0 w-full" style={{ width: "100%" }}>
                              <div className=" flex-wrap -mx-2 md:space-y-0 mt-4 px-2" style={{ display: "flex" }}>
                                <select className="form-control" defaultValue={newWorkingHour.day} onChange={e => setNewWorkingDay(e.target.value)}>
                                  <option>Select Day</option>
                                  {selectedDates.map((day: string, i: number) => {
                                    return (
                                      <option key={i} value={day}>
                                        {day}
                                      </option>
                                    );
                                  })}
                                </select>

                                <Row className="mt-3">
                                  <Col className="col-6">
                                    <Label className="form-label">Start Time</Label>
                                    <CustomTimePicker2
                                      timeValue={startTimeValue || ""}
                                      setTimeValue={(value: string) => {
                                        setStartTimeValue(value);
                                        if (value) {
                                          const utcDate = moment(value, "hh:mm A").utc().format("H:mm A");
                                          setNewWorkingHour({ ...newWorkingHour, startTime: utcDate });
                                        }
                                      }}
                                    />
                                  </Col>

                                  <Col className="col-6">
                                    <Label className="form-label">End Time</Label>
                                    <CustomTimePicker2
                                      timeValue={endTimeValue || ""}
                                      setTimeValue={(value: string) => {
                                        setEndTimeValue(value);
                                        if (value) {
                                          const utcDate = moment(value, "hh:mm A").utc().format("H:mm A");
                                          setNewWorkingHour({ ...newWorkingHour, endTime: utcDate });
                                        }
                                      }}
                                    />
                                  </Col>

                                  <Col className="col-12 mt-3">
                                    <button className="btn btn-primary btn-sm font-size-14" onClick={updateTimeSlots}>
                                      Add Time Slot
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <button
                      type="button"
                      className="btn btn-skip  relative mt-3 mr-2 mb-4"
                      style={{
                        bottom: "0",
                      }}
                      onClick={() => {
                        history.push(RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE);
                      }}
                    >
                      <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                      Back
                    </button>
                  </Col>
                  <Col xl={6}>
                    <button
                      type="button"
                      className="btn btn-skip   mr-2  ml-4  relative mt-3 mb-2"
                      style={{
                        bottom: "0",
                        float: "right",
                      }}
                      onClick={() => {
                        history.push(RouteName.THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE);
                      }}
                    >
                      Skip
                    </button>

                    <a
                      className="btn btn-primary   mr-2 relative mt-3 mb-2"
                      style={{
                        bottom: "0",
                        float: "right",
                      }}
                      onClick={() => {
                        updateTherapistDetails();
                      }}
                    >
                      Save & Continue
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TherapistWorkingDaysOnboardingPage;
