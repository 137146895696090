import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";

const StartAudioModal: React.FC<{ showModal: boolean; setShowModal: () => void }> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          {/* <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button> */}
          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-warning text-danger"></i>
            </div>

            <CardTitle className="mb-4 text-center font-size-20">Join with device audio</CardTitle>

            <div className="flex justify-content-center">
              <button type="button" onClick={() => props.setShowModal()} className="btn btn-primary">
                Join
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default StartAudioModal;
