import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { Client } from "../models/Client";
import { PriorityNumber, Therapist } from "../models/Therapist";
import {
  ApproveReject,
  ApproveRejectReview,
  ClientCountResponseModel,
  ClientResponseModel,
  TherapistResponseModel,
  User,
  UserCountResponseModel,
  UserDetails,
  UserResponseModel,
} from "../models/User";
import { Report } from "../models/Report";
import { ContactUs } from "../models/ContactUs";
import { Ethinicity, EthinicityResponseModel } from "../models/Ethnicity";
import { EducationDetails } from "../models/EducationalDetails";
import { LicenseDetails } from "../models/License";
import { ExperienceTag } from "../models/ExperienceTag";
import { HashTag } from "../models/HashTag";
import { Article } from "../models/Article";
import { Feedback, FeedbackList } from "../models/Feedback";
import { Invoice } from "../models/Invoice";
import { ClientSession, TherapistSession } from "../models/DiagnosisNote";
import { ClaimStatusResponse, ClaimSubmissionDetails, Eligibility, Insurance } from "../models/Insurance";
import { MeetingData } from "../models/MeetingData";
import { MarketingEmails } from "src/models/MarketingEmails";
import { TransactionPaymemt } from "src/models/Transaction";
import { CustomerReviewData } from "src/models/Review";
import { ClaimERAHistoryData, ClaimHistoryData, TreatmentClaimHistoryData } from "src/models/TreatmentHistory";
import { LavniReview } from "src/models/LavniReview";

interface ClaimResponse {
  _id: string;
  clientId: {
    role: string;
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
    insuranceId: {
      _id: string;
      insuranceCompanyId: {
        _id: string;
        insuranceCompany: string;
      };
    };
  };
  therapistId: {
    role: string;
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
  };
  note: string;
  isMeetingTranscribe: boolean;
  meetingStartedTime: string;
  meetingId: string;
  diagnosisNoteId: string;
  createdAt: string;
  updatedAt: string;
  claimStatus?: string;
  flag?: boolean;
}

export class AdminService {
  public static async getDetailClaimsStatusOfTherapist(therapistId: string): Promise<AppResponse<ClaimResponse[]>> {
    const url = Util.apiAuthUrl(`get-detail-claims-status-of-therapist/${therapistId}`);
    return await axios.get<void, AppResponse<ClaimResponse[]>>(url);
  }

  public static async getAllPendingClients(limit?: number, offset?: number): Promise<AppResponse<Client[]>> {
    const url = Util.apiAuthUrl(`getAllPendingClients/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Client[]>>(url);
  }

  public static async getAllApprovedClients(limit?: number, offset?: number): Promise<AppResponse<Client[]>> {
    const url = Util.apiAuthUrl(`getAllApprovedClients/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Client[]>>(url);
  }

  public static async approveRejectClient(data: ApproveReject): Promise<AppResponse<Client>> {
    const url = Util.apiAuthUrl(`approveRejectClient`);
    return await axios.post<void, AppResponse<Client>>(url, data);
  }
  
  public static async getAllPendingTherapists(limit?: number, offset?: number): Promise<AppResponse<Therapist[]>> {
    const url = Util.apiAuthUrl(`getAllPendingTherapists/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Therapist[]>>(url);
  }

  public static async getAllApprovedTherapists(limit?: number, offset?: number): Promise<AppResponse<Therapist[]>> {
    const url = Util.apiAuthUrl(`getAllApprovedTherapists/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Therapist[]>>(url);
  }

  public static async getAllClientStates(): Promise<AppResponse<string[]>> {
    const url = Util.apiAuthUrl('get-all-client-state');
    return await axios.get<void, AppResponse<string[]>>(url);
  }

  public static async getAllClientInsuranceCompanies(): Promise<AppResponse<Array<{_id: string, insuranceCompany: string}>>> {
    const url = Util.apiAuthUrl('get-all-client-insurance-company');
    return await axios.get<void, AppResponse<Array<{_id: string, insuranceCompany: string}>>>(url);
  }

  public static async approveRejectTherapist(data: ApproveReject): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl(`approveRejectTherapist`);
    return await axios.post<void, AppResponse<Therapist>>(url, data);
  }

  public static async getAllClients(limit?: number, offset?: number): Promise<AppResponse<ClientResponseModel>> {
    const url = Util.apiAuthUrl(`getAllClients/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<ClientResponseModel>>(url);
  }

  public static async searchClients(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchClientsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async getRecentSessionsForClient(clientId: any, therapistId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getRecentSessionsForClientByAdmin/${clientId}/${therapistId}`);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url);
  }

  //search friend client
  public static async searchFriendClients(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchFriendClientsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async searchClientStatistics(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchClientsByAdminStatistic/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async searchTherapistStatistics(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchTherapistsByAdminStatistic/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async searchMeetingsAndRecordingsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchMeetingsAndRecordingsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async searchTherapistsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchTherapistsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //dup
  public static async searchTherapistsByAdminDup(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`filterTherapistByTimeRange/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //doc access
  public static async searchTherapistsDocumentAccessByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`filterTherapistsDocumentAccessByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //doc permission
  public static async editTherapistFolderDocumentPermissionByAdmin(data: {
    therapistId: string;
    grantedAccessFileFolderPermission: string;
  }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`editUserFolderDocumentPermission`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //file deletion
  public static async deleteFilesByDocumentPermissionByGrantedUser(data: { fileId: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteFile`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //folder deletions
  public static async deleteFoldersByDocumentPermissionByGrantedUser(data: { folderId: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteFolder`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //rename folder
  public static async renameFolder(data: { folderId: string; folderName: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`renameFolder`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //rename files
  public static async renameFile(data: { fileId: string; originalFileName: string }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`renameFile`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  //search files and folders
  public static async searchFilesAndFolders(data: { searchString: string; parentFolderId: string | null }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchFilesFolders`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchAllTherapistsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchAllTherapistsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchAllReferralEarningsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchAllReferralEarningsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchAllClientRewardsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchAllClientRewardsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchMatchedTherapistsByAdmin(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchMatchedTherapistsByClientId/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchMatchTherapistsByClientId(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchMatchTherapistsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchTherapistsByAdminStatistic(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchTherapistsByAdminStatistic/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAllAdminUsers(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllAdminUsers/` + limit + "/" + offset);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllTherapists(limit?: number, offset?: number): Promise<AppResponse<TherapistResponseModel>> {
    const url = Util.apiAuthUrl(`getAllTherapists/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<TherapistResponseModel>>(url);
  }

  public static async getAllReportReviews(limit?: number, offset?: number): Promise<AppResponse<Report[]>> {
    const url = Util.apiAuthUrl(`getAllReportReviews` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Report[]>>(url);
  }

  public static async getAllContactUsRequests(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllContactUsRequests/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getAllTechTickets(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllTechTickets/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getAllArticles(limit?: number, offset?: number): Promise<AppResponse<Article[]>> {
    const url = Util.apiAuthUrl(`getAllArticledetails/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Article[]>>(url);
  }

  public static async getAllPendingEducationalDetails(limit?: number, offset?: number): Promise<AppResponse<EducationDetails[]>> {
    const url = Util.apiAuthUrl(`getAllPendingEducationalDetails/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<EducationDetails[]>>(url);
  }

  public static async getAllApprovedEducationalDetails(limit?: number, offset?: number): Promise<AppResponse<EducationDetails[]>> {
    const url = Util.apiAuthUrl(`getAllApprovedEducationalDetails/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<EducationDetails[]>>(url);
  }

  public static async getAllPendingLicenseDetails(limit?: number, offset?: number, name?: string): Promise<AppResponse<LicenseDetails[]>> {
    let url = Util.apiAuthUrl(`getAllPendingLicenseDetails/${limit}/${offset}`);
    if (name && name.trim() !== "") {
      url += `?name=${encodeURIComponent(name)}`;
    }
    return await axios.get<void, AppResponse<LicenseDetails[]>>(url);
  }

  public static async getAllApprovedLicenseDetails(limit?: number, offset?: number, name?: string): Promise<AppResponse<LicenseDetails[]>> {
    let url = Util.apiAuthUrl(`getAllApprovedLicenseDetails/${limit}/${offset}`);
    if (name && name.trim() !== "") {
      url += `?name=${encodeURIComponent(name)}`;
    }
    return await axios.get<void, AppResponse<LicenseDetails[]>>(url);
  }

  public static async getAllFeedbacks(limit: number, offset: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllFeedbacks/${limit}/${offset}`);
    return await axios.post<Partial<Feedback>, AppResponse<any>>(url);
  }

  public static async getAllTherapistSessions(therapistId: string): Promise<AppResponse<TherapistSession[]>> {
    const url = Util.apiAuthUrl(`getNotesForTherapists/${therapistId}`);
    return await axios.get<void, AppResponse<TherapistSession[]>>(url);
  }

  public static async viewProfileByUserId(userId: string): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl(`viewProfileByUserId/${userId}`);
    return await axios.get<void, AppResponse<UserDetails>>(url);
  }

  public static async getEligibilityDetails(insuranceId: string, tradingServiceId: string): Promise<AppResponse<Eligibility>> {
    const url = Util.apiAuthUrl(`eligibilityCheck/${insuranceId}/${tradingServiceId}`);
    return await axios.post<void, AppResponse<Eligibility>>(url);
  }

  public static async blockUserByAdmin(data: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`blockUserByAdmin/`);
    return await axios.post<void, AppResponse<string>>(url, data);
  }

  public static async unblockUserByAdmin(data: any): Promise<AppResponse<Report>> {
    const url = Util.apiAuthUrl(`unblockUserByAdmin`);
    return await axios.post<void, AppResponse<Report>>(url, data);
  }

  public static async deleteEthnicityType(ethnicityId: string): Promise<AppResponse<Ethinicity>> {
    const url = Util.apiAuthUrl(`deleteEthnicity/${ethnicityId}`);
    return await axios.delete<void, AppResponse<Ethinicity>>(url);
  }

  public static async updateEthnicityType(data: any): Promise<AppResponse<Ethinicity>> {
    const url = Util.apiAuthUrl(`updateEthnicity`);
    return await axios.post<void, AppResponse<Ethinicity>>(url, data);
  }

  public static async updateExperienceTags(data: any): Promise<AppResponse<ExperienceTag>> {
    const url = Util.apiAuthUrl(`updateExperienceTags`);
    return await axios.post<void, AppResponse<ExperienceTag>>(url, data);
  }

  public static async updateFeedbackStatus(feedbackId: string): Promise<AppResponse<FeedbackList>> {
    const url = Util.apiAuthUrl(`updateFeedbackStatus/${feedbackId}`);
    return await axios.post<void, AppResponse<FeedbackList>>(url);
  }

  public static async updateContactRequestStatus(contactRequestId: string): Promise<AppResponse<ContactUs>> {
    const url = Util.apiAuthUrl(`updateContactRequestStatus/${contactRequestId}`);
    return await axios.post<void, AppResponse<ContactUs>>(url);
  }

  public static async updateTechTicketStatus(techTicketId: string): Promise<AppResponse<ContactUs>> {
    const url = Util.apiAuthUrl(`updateTechTicketStatus/${techTicketId}`);
    return await axios.post<void, AppResponse<ContactUs>>(url);
  }

  public static async updateHashTags(data: any): Promise<AppResponse<HashTag>> {
    const url = Util.apiAuthUrl(`updateHashTags`);
    return await axios.post<void, AppResponse<HashTag>>(url, data);
  }

  public static async toggleEducationalDetailsReviewStatus(educationId: string): Promise<AppResponse<EducationDetails>> {
    const url = Util.apiAuthUrl(`toggleEducationalDetailsReviewStatus/${educationId}`);
    return await axios.post<void, AppResponse<EducationDetails>>(url);
  }

  public static async toggleLicenseDetailsReviewStatus(licenseId: string): Promise<AppResponse<LicenseDetails>> {
    const url = Util.apiAuthUrl(`toggleLicenseDetailsReviewStatus/${licenseId}`);
    return await axios.post<void, AppResponse<LicenseDetails>>(url);
  }

  public static async togglePremiumMembership(clientId: string): Promise<AppResponse<Client>> {
    const url = Util.apiAuthUrl(`togglePremiumMembership/${clientId}`);
    return await axios.post<void, AppResponse<Client>>(url);
  }

  public static async deleteArticleById(articleId: string): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`deleteArticleById/${articleId}`);
    return await axios.delete<void, AppResponse<string>>(url);
  }

  public static async deleteThemeImageById(imageId: string): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`deleteThemeImage/${imageId}`);
    return await axios.delete<void, AppResponse<string>>(url);
  }

  public static async deleteMeetingsAndRecordingById(id: string): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`deleteMeetingsAndRecordings/${id}`);
    return await axios.delete<void, AppResponse<string>>(url);
  }

  public static async deleteUser(userId: string): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`deleteUser/${userId}`);
    return await axios.delete<void, AppResponse<string>>(url);
  }

  public static async getAllTherapistsByProfessionId(id: string, limit?: number, offset?: number): Promise<AppResponse<TherapistResponseModel>> {
    const url = Util.apiAuthUrl(`getTherapistByProfession/` + id + "/" + limit + "/" + offset);
    return await axios.get<void, AppResponse<TherapistResponseModel>>(url);
  }

  public static async getAllPremiumClients(limit: number, offset: number): Promise<AppResponse<Client[]>> {
    const url = Util.apiAuthUrl(`getAllPremiumClients/${limit}/${offset}`);
    return await axios.get<void, AppResponse<Client[]>>(url);
  }

  public static async getAllPremiumMembershipRevokedClients(limit: number, offset: number): Promise<AppResponse<Client[]>> {
    const url = Util.apiAuthUrl(`getAllPremiumMembershipRevokedClients/${limit}/${offset}`);
    return await axios.get<void, AppResponse<Client[]>>(url);
  }

  public static async getAllPremiumAndRevokedClients(): Promise<AppResponse<ClientCountResponseModel>> {
    const url = Util.apiAuthUrl(`getAllPremiumAndRevokedClients`);
    return await axios.get<void, AppResponse<ClientCountResponseModel>>(url);
  }

  public static async getAllRecordsAndMeetings(limit: number, offset: number): Promise<AppResponse<MeetingData[]>> {
    const url = Util.apiAuthUrl(`getAllMeetingsAndRecordings/${limit}/${offset}`);
    return await axios.get<void, AppResponse<MeetingData[]>>(url);
  }

  public static async chargePaymentAndSendEmail(data: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`chargePaymentAndSendEmail`);
    return await axios.post<void, AppResponse<string>>(url, data);
  }

  public static async getPaymentHistoryByClient(clientId: string, limit: number, offset: number): Promise<AppResponse<Invoice[]>> {
    const url = Util.apiAuthUrl(`getPaymentHistoryByClient/${clientId}/${limit}/${offset}`);
    return await axios.get<void, AppResponse<Invoice[]>>(url);
  }

  public static async getCurrentUserPaymentHistory(limit: number, offset: number): Promise<AppResponse<Invoice[]>> {
    const url = Util.apiAuthUrl(`getCurrentUserPaymentHistory/${limit}/${offset}`);
    return await axios.get<void, AppResponse<Invoice[]>>(url);
  }

  public static async getClaimStatusByInsurancecId(
    insuranceId: string,
    tradingServiceId: string,
    diagnosisNoteId: string
  ): Promise<AppResponse<ClaimStatusResponse>> {
    const url = Util.apiAuthUrl(`getClaimStatusByInsuranceId/${insuranceId}/${tradingServiceId}/${diagnosisNoteId}`);
    return await axios.get<void, AppResponse<ClaimStatusResponse>>(url);
  }

  public static async getAllSessionsByClientId(clientId: string, limit: number, offset: number): Promise<AppResponse<ClientSession[]>> {
    const url = Util.apiAuthUrl(`getNoteListByClientId/${clientId}/${limit}/${offset}`);
    return await axios.get<void, AppResponse<ClientSession[]>>(url);
  }

  public static async getClaimDetails(noteId: string): Promise<AppResponse<ClaimSubmissionDetails[]>> {
    const url = Util.apiAuthUrl(`getClaimDetailsByInsuranceId/${noteId}`);
    return await axios.get<void, AppResponse<ClaimSubmissionDetails[]>>(url);
  }

  public static async sendMarketingEmails(data: any): Promise<AppResponse<null>> {
    const url = Util.apiAuthUrl(`sendMarketingEmail1`);
    return await axios.post<void, AppResponse<null>>(url, data);
  }

  public static async submitClaim(data: any, insuranceId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`submitClaim/${insuranceId}`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async submitClaimPartition(data: { therapistId: string; clientIds: string[] }): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`submit-claim-md`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async checkEligibiltyAdmin(data: any, insuranceId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`checkEligibilityByAdmin/${insuranceId}`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async checkEligibilty(userId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`checkEligibility/${userId}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async checkEligibiltyMd(userId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`checkEligibilityMD/${userId}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  // bellow all for admin statistics

  public static async getAllUserCounts(): Promise<AppResponse<UserCountResponseModel>> {
    const url = Util.apiAuthUrl(`get-user-counts`);
    return await axios.get<void, AppResponse<UserCountResponseModel>>(url);
  }

  public static async getAllAppointmentsStatistics(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-all-appointment-statistics`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getAllSubscriptionCounts(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-all-subscriptions`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getAllTotalRevenue(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get/totalRevenue`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getAllAdminStatistics(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-all-admin-statistics`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllAdminStatisticsOnDemand(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-all-admin-statistics-on-demand`);
    return await axios.post<void, AppResponse<any>>(url,{});
  }

  public static async getEthinicityList(limit?: number, offset?: number): Promise<AppResponse<EthinicityResponseModel>> {
    const url = Util.apiPublicUrl(`getEthnicityTypes/${limit}/${offset}`);

    return await axios.get<Partial<EthinicityResponseModel>, AppResponse<EthinicityResponseModel>>(url);
  }

  public static async tranceferAmmount(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`transferAmont`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async tranceferAmmountBySession(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`transferAmontSession`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async markAsPaidAmmount(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`markAsPaidAmmount`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllAccumulatedBalance(): Promise<AppResponse<TransactionPaymemt[]>> {
    const url = Util.apiAuthUrl(`get/accumulatedBalances`);
    return await axios.get<Partial<TransactionPaymemt[]>, AppResponse<TransactionPaymemt[]>>(url);
  }

  public static async getMonthlyAllAccumulatedBalance(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getMonthlyAmounts/${limit}/${offset}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllReferralEarnings(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getReferralEarnings/${limit}/${offset}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async getAllClientRewards(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getClientRewards/${limit}/${offset}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async searchInsuranceDocumentsByCondition(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchInsuranceDocumentsByCondition/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async sendReminderEmailToTherapistRegardingIncompletedForm(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendReminderEmailToTherapistRegardingIncompletedForm`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async payMonthlyAllAccumulatedBalance(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`payMonthlyAmounts`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async declaredMonthlyAmount(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`declaredMonthlyAmount`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async deleteMonthlyAmountRecord(id: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteMonthlyAmountRecord/${id}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async approveOrDeclineReferralEarningRecord(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`approveOrDeclineReferralEarningRecord`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async deleteReferralEarningRecord(id: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteReferralEarningRecord/${id}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async transferReferralEarningPayment(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`transferReferralEarningPayment`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async setMarkedAsPaidClientRewardRecord(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`markedAsPaidClientRewardRecord`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async deleteClientRewardRecord(id: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteClientRewardRecord/${id}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async payReferralBonusOfCurrentMonthAsBulk(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`payReferralBonusOfCurrentMonthAsBulk`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async runCrownJob1stOfMonth(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`runCrownJob1stOfMonth`);
    return await axios.post<void, AppResponse<any>>(url);
  }
  public static async runCrownJob15thOfMonth(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`runCrownJob15thOfMonth`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async sendMarketingEmailList(data: any): Promise<AppResponse<MarketingEmails[]>> {
    const url = Util.apiAuthUrl(`saveMarketingEmails`);
    return await axios.post<void, AppResponse<MarketingEmails[]>>(url, data);
  }

  public static async getAllEmailList(limit: number, offset: number): Promise<AppResponse<MarketingEmails[]>> {
    const url = Util.apiAuthUrl(`getAllMarketingEmails/${limit}/${offset}`);
    return await axios.get<void, AppResponse<MarketingEmails[]>>(url);
  }

  public static async deleteMarketingEmailsById(id: string): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl(`deleteMarketingEmails/${id}`);
    return await axios.delete<void, AppResponse<string>>(url);
  }

  public static async getAllMeetingsByTherapistStatistic(data: any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`getAllMeetingsByTherapist`);
    return await axios.post<void, AppResponse<any[]>>(url, data);
  }

  public static async getAllLavniPendingReviews(limit?: number, offset?: number): Promise<AppResponse<LavniReview[]>> {
    const url = Util.apiAuthUrl(`get-all-lavni-pending-reviews/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<LavniReview[]>>(url);
  }

  public static async getAllLavniApprovedReviews(limit?: number, offset?: number): Promise<AppResponse<LavniReview[]>> {
    const url = Util.apiAuthUrl(`get-all-lavni-approved-reviews/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<LavniReview[]>>(url);
  }

  public static async approveRejectLavniReview(data: ApproveRejectReview): Promise<AppResponse<CustomerReviewData>> {
    const url = Util.apiAuthUrl(`update-lavni-review`);
    return await axios.post<void, AppResponse<CustomerReviewData>>(url, data);
  }

  public static async getAllTherapistPendingReviews(limit?: number, offset?: number): Promise<AppResponse<CustomerReviewData[]>> {
    const url = Util.apiAuthUrl(`get-all-therapist-pending-reviews/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<CustomerReviewData[]>>(url);
  }

  public static async getAllTherapistSOAPReviews(limit?: number, offset?: number): Promise<AppResponse<CustomerReviewData[]>> {
    const url = Util.apiAuthUrl(`get-all-therapists-soap-reviews/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<CustomerReviewData[]>>(url);
  }

  public static async getAllTherapistApprovedReviews(limit?: number, offset?: number): Promise<AppResponse<CustomerReviewData[]>> {
    const url = Util.apiAuthUrl(`get-all-therapist-approved-reviews/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<CustomerReviewData[]>>(url);
  }

  public static async approveRejectReview(data: ApproveRejectReview): Promise<AppResponse<CustomerReviewData>> {
    const url = Util.apiAuthUrl(`update-customer-review`);
    return await axios.post<void, AppResponse<CustomerReviewData>>(url, data);
  }

  public static async getAllClaimsByAdmin(data: any, limit: number, offset: number): Promise<AppResponse<ClaimHistoryData[]>> {
    const url = Util.apiAuthUrl(`getAllClaims/${limit}/${offset}`);
    return await axios.post<void, AppResponse<ClaimHistoryData[]>>(url, data);
  }

  public static async getAllClaimsERAByAdmin(data: any, limit: number, offset: number): Promise<AppResponse<ClaimERAHistoryData[]>> {
    const url = Util.apiAuthUrl(`getERALists/${limit}/${offset}`);
    return await axios.post<void, AppResponse<ClaimERAHistoryData[]>>(url, data);
  }

  public static async getAllClinicalNotesByAdmin(data: any, limit: number, offset: number): Promise<AppResponse<TreatmentClaimHistoryData[]>> {
    const url = Util.apiAuthUrl(`getAllClinicalNotes/${limit}/${offset}`);
    return await axios.post<void, AppResponse<TreatmentClaimHistoryData[]>>(url, data);
  }

  public static async getAllPassDueNotesByTherapist(data: any, limit: number, offset: number): Promise<AppResponse<TreatmentClaimHistoryData[]>> {
    const url = Util.apiAuthUrl(`getAllPassDueNotes/${limit}/${offset}`);
    return await axios.post<void, AppResponse<TreatmentClaimHistoryData[]>>(url, data);
  }

  public static async adminCreateUser(data: any): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl(`create-user`);
    return await axios.post<void, AppResponse<User>>(url, data);
  }

  public static async adminUpdateUser(data: any): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl(`update-user`);
    return await axios.post<void, AppResponse<User>>(url, data);
  }

  public static async updateFirstTimeLogin(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`update-first-time-login`);
    return await axios.post<Partial<any>, AppResponse<any>>(url);
  }

  public static async sendReminderNote(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendReminderClinicalNotes`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async sendClaim(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`runClaimByAdmin`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async sendReminderClient(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendReminderClient`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async changeUserPasswordByAdmin(data: any, id: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`changeUserPasswordAdmin/${id}`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async sendEmailToMultipleUsers(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendMailToMultipleUsers`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }
  public static async sendSMSToMultipleUsers(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendSMSToMultipleUsers`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async AddInsurancePlanByAdmin(userId: string, data: any, fileToUpload: FileList, fileToUploadBack: FileList): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("create/insuranceByAdmin/" + userId);
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }

    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }
    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }

  public static async uploadInsurancePdf(insuranceDocApprovalId: string, uploadCategory: string, file: File | Blob, fileName: string): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl(`uploadInsurancePdf/${uploadCategory}/${insuranceDocApprovalId}`);
    const formData: FormData = new FormData();
    formData.append("file", file, fileName);
    return await axios.post<FormData, AppResponse<Insurance>>(ep,formData);
  }

  public static async getUploadedInsuranceDocStatus(insuranceDocApprovalId?: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getUploadedInsuranceDocStatus/" + insuranceDocApprovalId);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async adminApprovalForInsuranceDocumentsFaxing(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`adminApprovalForInsuranceDocumentsFaxing`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getTotalSpentDuration(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getMergeMeetingByNoteId`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  // public static async updateClientDobByAdmin(data: ClientDob): Promise<AppResponse<ClientDob>> {
  //   const url = Util.apiAuthUrl("updateClientDobByAdmin");
  //   return await axios.post<void, AppResponse<ClientDob>>(url, data);
  // }

  public static async updateTherapistPnumberByAdmin(data: PriorityNumber): Promise<AppResponse<PriorityNumber>> {
    const url = Util.apiAuthUrl("updateTherapistPnumberByAdmin");
    return await axios.post<void, AppResponse<PriorityNumber>>(url, data);
  }

  public static async updateClaimStatusByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("updateClaimOpen");
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updateBlackTherapyPnumberByAdmin(data: PriorityNumber): Promise<AppResponse<PriorityNumber>> {
    const url = Util.apiAuthUrl("updateBlackTherapyPnumberByAdmin");
    return await axios.post<void, AppResponse<PriorityNumber>>(url, data);
  }

  public static async updateMensTherapyPnumberByAdmin(data: PriorityNumber): Promise<AppResponse<PriorityNumber>> {
    const url = Util.apiAuthUrl("updateMensMentalTherapyPnumberByAdmin");
    return await axios.post<void, AppResponse<PriorityNumber>>(url, data);
  }

  public static async updateRelationshipTherapyPnumberByAdmin(data: PriorityNumber): Promise<AppResponse<PriorityNumber>> {
    const url = Util.apiAuthUrl("updateRelationshipTherapyPnumberByAdmin");
    return await axios.post<void, AppResponse<PriorityNumber>>(url, data);
  }

  public static async getAllTherapistListCount(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllTherapistCountByAdmin`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getTherapistPriorityNumber(userId: string): Promise<AppResponse<number[]>> {
    const url = Util.apiAuthUrl(`getTherapistPriorityNumberByAdmin`);
    return await axios.post<void, AppResponse<number[]>>(url);
  }

  public static async updatePayRateByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`updateTherpistPayRateType`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updateCopaymentByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`updateClientCopaymentAmount`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async reviewSubmissionByAdminOnBehalfOfClient(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`reviewSubmissionByAdminOnBehalfOfClient`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async reviewSubmissionByAdminOnBehalfOfTherapist(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`reviewSubmissionByAdminOnBehalfOfTherapist`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getExistingReviewByClientId(id: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getExistingReviewByClientId/${id}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async getExistingReviewByTherapistId(id: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getExistingReviewByTherapistId/${id}`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async updatemonthlyTransaction(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`updateMonthlyPaymentAndTransaction`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async deleteTreatmentHistoryByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteTreatmentHistoryByAdmin`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updateTreatmentHistoryStatusByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`updateClaimStatus`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async removeTreatmentHistoryFlagByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`removeTreatmentHistoryFlag`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async updatemonthlyTransactionByTherapistId(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`transferAmontForSelcetedTherapist`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAllTherapistsList(): Promise<AppResponse<Therapist[]>> {
    const url = Util.apiAuthUrl(`getAllTherapistsList`);
    return await axios.get<void, AppResponse<Therapist[]>>(url);
  }

  public static async getAllReminderSms(limit: number, offset: number): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`getAllReminderSms/${limit}/${offset}`);
    return await axios.get<void, AppResponse<any[]>>(url);
  }

  public static async sendCustomSmsToClient(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`sendCustomSms`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getReminderSmsByClient(clientId: any, limit: number, offset: number): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`getReminderSmsByClientId/${clientId}/${limit}/${offset}`);
    return await axios.get<void, AppResponse<any[]>>(url);
  }

  public static async AddInsurancePlanByUser(userId: string, data: any, fileToUpload: FileList, fileToUploadBack: FileList): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("create/insuranceByUser/" + userId);
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }

    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }
    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }

  public static async AddSeondaryInsurancePlanByUser(
    userId: string,
    data: any,
    fileToUpload: FileList,
    fileToUploadBack: FileList
  ): Promise<AppResponse<Insurance>> {
    const ep = Util.apiAuthUrl("create/secondaryInsuranceByUser/" + userId);
    const formData: FormData = new FormData();
    formData.append("insuranceDetails", JSON.stringify(data) as any);

    for (const file of fileToUpload) {
      formData.append("insuranceCardFront", file);
    }

    for (const file of fileToUploadBack) {
      formData.append("insuranceCardBack", file);
    }
    return await axios.post<FormData, AppResponse<Insurance>>(ep, formData);
  }
  public static async getTherapistScores(limit: number, offset: number): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`getTherapistScoreDetails/${limit}/${offset}`);
    return await axios.get<void, AppResponse<any[]>>(url);
  }

  public static async getTherapistScoreConstants(): Promise<AppResponse<any[]>> {
    const url = Util.apiPublicUrl(`getTherapistScoreConstants`);
    return await axios.get<void, AppResponse<any[]>>(url);
  }

  public static async createTherapistScoreConstants(data: any): Promise<AppResponse<any[]>> {
    const url = Util.apiPublicUrl(`createTherapistScoreConstants`);
    return await axios.post<void, AppResponse<any[]>>(url, data);
  }

  public static async getAllSessionFeedbacks(limit: number, offset: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllSessionFeedback/${limit}/${offset}`);
    return await axios.get<Partial<Feedback>, AppResponse<any>>(url);
  }

  public static async getAllPendingTherapistRequests(limit?: number, offset?: number): Promise<AppResponse<Therapist[]>> {
    const url = Util.apiAuthUrl(`getAllPendingTherapistRequests/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Therapist[]>>(url);
  }

  public static async getAllApprovedTherapistRequests(limit?: number, offset?: number): Promise<AppResponse<Therapist[]>> {
    const url = Util.apiAuthUrl(`getAllApprovedTherapistRequests/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Therapist[]>>(url);
  }

  public static async getAllRejectedTherapistRequests(limit?: number, offset?: number): Promise<AppResponse<Therapist[]>> {
    const url = Util.apiAuthUrl(`getAllRejectedTherapistRequests/` + limit + "/" + offset);
    return await axios.get<void, AppResponse<Therapist[]>>(url);
  }

  public static async approveTherapistRequest(data: ApproveReject): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl(`approveTherapistRequest`);
    return await axios.post<void, AppResponse<Therapist>>(url, data);
  }

  public static async rejectTherapistRequest(data: ApproveReject): Promise<AppResponse<Therapist>> {
    const url = Util.apiAuthUrl(`rejectTherapistRequest`);
    return await axios.post<void, AppResponse<Therapist>>(url, data);
  }

  public static async searchDocumentDownloads(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchDocumentDownloadsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async getClinicalAssestmentDetailsByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClinicalAssestmentDetailsByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getTherapyPlanDetailsByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getTherapyPlanDetailsByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClientUsingClinicalAssessmentId(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClientUsingClinicalAssessmentId");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClientUsingTherapyPlanId(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClientUsingTherapyPlanId");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClinicalAssestmentDetailsWithSessionDataByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClinicalAssestmentDetailsWithSessionDataByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClinicalAssestmentDetailsForDownloadByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClinicalAssestmentDetailsForDownloadByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getEraListForClaim(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getEraListForClaim`);
    return await axios.post<Partial<UserResponseModel>, AppResponse<any>>(url, data);
  }

  public static async getDigitalAssessmentDataByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getDigitalAssessmentDataByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getClientUsingDigitalAssessmentIdByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getClientUsingDigitalAssessmentIdByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getDigitalAssestmentSessionDataByAdmin(data: {assesmentId: string}): Promise<AppResponse<any>> {
      const url = Util.apiAuthUrl("getDigitalAssestmentSessionDataByAdmin");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async releaseOrHoldPaymentForSpecificTherapist(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`releaseOrHoldPaymentForSpecificTherapist`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async searchMatchClientsByTherapistId(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchMatchClientsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async searchMatchedTherapistsByClientId(data: any, limit?: number, offset?: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`searchMatchedTherapistsByAdmin/` + limit + "/" + offset);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async unmatchMatchedClientOrTherapist(therapistId: any, clientId: any): Promise<AppResponse<string>> {
    const url = Util.apiAuthUrl("unmatchMatchedClientOrTherapist/" + therapistId + "/" + clientId);
    return await axios.post<Partial<Client>, AppResponse<string>>(url);
  }

  public static async generateAppointmentAuthTokenByAdmin(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`generateAppointmentAuthTokenByAdmin`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async getAppointmentAuthTokenByAdmin(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAppointmentAuthTokenByAdmin`);
    return await axios.post<void, AppResponse<any>>(url);
  }

  public static async getAllStates(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getAllStatesByAdmin`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async deleteState(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteStateByAdmin`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

  public static async addState(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`addStateByAdmin`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }

}
