import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { Util } from "../../../Util";
import videoIcon from "../../../assets/images/icon_video.svg";
import Swal from "sweetalert2";
import { DocumentService } from "../../../services/DocumentService";
import { toast } from "react-toastify";
import UserContext from "../../../context/UserContext";

interface DocumentListProps {
    searchQuery?: any;
    isShow: boolean;
    dataForEdit: (data?: any) => void
    seeMore: () => void,
    disablebtn: boolean,
    removedDoc: (data?: any) => void
}
  
  const DocumentLists: React.FC<DocumentListProps> = ({ searchQuery, dataForEdit, seeMore, disablebtn, removedDoc, isShow }: DocumentListProps) => {
    const [loggedUser, setLoggedUser] = useContext(UserContext);
    const [documentsDetails, setDocumentsDetails] = useState<any[]>([]);
    const [mainVideo, setMainVideo] = useState<any>();
    const [modalcentervideo, setModalCenterVideo] = React.useState(false);
    const [isLoad, setIsLoad] = React.useState(false);

    useEffect(() => {
        setIsLoad(isShow);
        setDocumentsDetails(searchQuery);
    }, [searchQuery, isShow])

    const viewVideo = (vdo: any) => {
        setMainVideo(vdo)
        setModalCenterVideo(!modalcentervideo);
    }

    const deleteDoc = (id: string) => {
        if (id) {
            Swal.fire({
                icon: "warning",
                title: "Are you sure you want to delete?",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#FD7F00",
                cancelButtonColor: "#FD7F00",
              }).then(result => {     
                if (result.isConfirmed) {
                    DocumentService.deleteDocument(id).then(res => {
                        if (res.success) {
                            toast.success('Deleted!', {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                            const refreshedArr = documentsDetails.filter(item => item._id !== id);
                            if (refreshedArr) {
                                setDocumentsDetails(refreshedArr);
                                removedDoc(id)
                            }
                        }
                    });
                }
            });
        }
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {isLoad ?
                        <>
                            {
                                searchQuery?.length > 0 && <>
                                {
                                    documentsDetails.map((data: any, i: number) => {
                                        return (
                                            <Row className="mb-4" key={i}>
                                            <Col lg={4}>
                                                <div className="document-thumbnail ">
                                                    {
                                                        data.vimoIds.length > 0 ? (
                                                            <iframe src={'https://player.vimeo.com/video/' + data.vimoIds[0]} width="100%" className="videoSize22" height="250" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                                                        ) : (
                                                            <div className="no-videos">
                                                                <div className="no-video-cont">
                                                                    <img src={Util.fileType(data.uploads[0].extension, data.uploads[0]._id)}  width={'100%'} height={200} onClick={() => {window.open(`${Util.fileURL(data.uploads[0]._id)}`, "_blank");}} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <h3 className="doc-text-color mb-2">{data.documentTitle}</h3>
                                                <ul className="list-inline d-sm-none">
                                                        <li className="list-inline-item mr-2">
                                                            <i className={Util.audienceType(data.audience)} id={`audience-target`+i}></i>
                                                        </li>
                                                        {
                                                            (loggedUser?._id === data.createdBy._id) && (
                                                                <>
                                                                    <li className="list-inline-item" onClick={() => deleteDoc(data._id)}>
                                                                        <i className='bx bx-trash' ></i>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <i className='bx bx-edit-alt' onClick={() => dataForEdit(data)}></i>
                                                                    </li>
                                                                </>
                                                            )
                                                        }

                                                    </ul>
                                                <h5 className="doc-text-color2 mb-2"><i className='bx bxs-user'></i> &nbsp; {data?.createdBy?.firstname} {data?.createdBy?.lastname} | <i className='bx bxs-calendar-alt' ></i> &nbsp; {moment(data.createdAt).format('L')}</h5>
                                                <p>{data.documentDescription}</p>

                                                <div className="doc-files">
                                                    <ul>
                                                        <li>
                                                            {
                                                                data.uploads.length > 0 && data.uploads.map((up: any, i: number) => {
                                                                    return <li key={i} >
                                                                        <img src={Util.fileType(data.uploads[0].extension, data.uploads[0]._id)} width={'100%'} height={40} id={'doc'+i} onClick={() => {window.open(`${Util.fileURL(up._id)}`, "_blank");}} />
                                                                        <UncontrolledTooltip placement="top" target={'doc'+i}>{up.originalName}</UncontrolledTooltip>
                                                                    </li>
                                                                })
                                                            }
                                                            {
                                                                data.vimoIds.length > 0 && data.vimoIds.map((vdo: any, i: number) => {
                                                                    return <li key={i}>
                                                                        {
                                                                            <div className="t-vdo2" onClick={() => viewVideo(vdo)} >
                                                                                <img src={videoIcon} className="mr-2" />
                                                                            </div>
                                                                        }
                                                                    </li>
                                                                })
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
            
                                                <span className="audience">
                                                    <ul className="list-inline text-end h-sm-w">
                                                        <li className="list-inline-item mr-2">
                                                            <i className={Util.audienceType(data.audience)} id={`audience-target`+i}></i>
                                                        </li>
                                                        {
                                                            (loggedUser?._id === data.createdBy._id) && (
                                                                <>
                                                                    <li className="list-inline-item" onClick={() => deleteDoc(data._id)}>
                                                                        <i className='bx bx-trash' ></i>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <i className='bx bx-edit-alt' onClick={() => dataForEdit(data)}></i>
                                                                    </li>
                                                                </>
                                                            )
                                                        }

                                                    </ul>
                                                </span>
                                                <UncontrolledTooltip placement="top" target={'audience-target'+i}>
                                                    {data.audience.toUpperCase()}
                                                </UncontrolledTooltip>
                                            </Col>
                                        </Row>
                                        )
                                    })
                                }
                                <Row>
                                    <Col className="text-center mt-5">
                                        {disablebtn && <button className="btn btn-sm btn-primary" onClick={() => seeMore()}>See More</button>}
                                    </Col>
                                </Row>
                                </>
                            }
                            {
                                (searchQuery?.length === 0) &&
                                <>
                                    <p className="training-videos">No training documents available!</p>
                                </>
                            }
                        </> : <>
                            <p className="training-videos">Loading</p>
                        </>
                    }

                </CardBody>
            </Card>

            {
                modalcentervideo &&
                <div className="lightbox1" onClick={() => {
                    setModalCenterVideo(false);
                }}>
                    <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
                        
                        <div className="modal-body1">
                        {/* {mainVideo} */}
                            <iframe src={'https://player.vimeo.com/video/' + mainVideo} width="800" className="videoSize" height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                        </div>
                                    
                    </div>
                </div>
            }
        </React.Fragment>
    )
  }

  export default DocumentLists;