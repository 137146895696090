import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import user1 from "../../../assets/images/default_profile.png";
import profileBackground from "../../../assets/images/default_cover.png";
import videoIcon from "../../../assets/images/icon_video.svg";
import { Util } from "../../../Util";
import Spinner from "../../../common/spinner/spinner";
import { TherapistService } from "src/services/TherapistService";
import Modal27 from "src/pages/Popup/Modal27";
import { toast } from "react-toastify";
import WorkingHoursComponent from "./WorkingHoursComponent";
import HeaderMain from "src/common/main/HeaderMain";

const PublicUserDetails: React.FC = () => {
  const history = useHistory();
  const [modalcentervideo, setModalCenterVideo] = React.useState(false);
  const [userProfileDetails, setUserProfileDetails] = React.useState({} as any);
  const [showSelectStatePanel, setShowSelectStatePanel] = useState<boolean>(false);
  const [therapistStatesList, setTherapistStatesList] = useState([] as string[]);

  useEffect(() => {
    localStorage.removeItem("state");
    const splittedURL = window.location.pathname.split("/");
    const fullName = splittedURL[splittedURL.length - 1];
    
    viewSelectedUserProfileByFullName(fullName);
  }, []);

  const viewSelectedUserProfileByFullName = (fullName: string) => {
    TherapistService.getTherapistDetailsByFullName(fullName).then(res => {
      if(res.success){
        setUserProfileDetails(res.data);
        console.log(res.data);
        console.log(res.data?.therapyState);
        const newStatesList = res.data?.therapyState;
        if (res.data?.state && !newStatesList.includes(res.data?.state)) {
          newStatesList.push(res.data.state);
        }
        setTherapistStatesList(newStatesList);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        })
      }
      
    });
  };

  const viewSelectedUserProfile = (id: any) => {
    TherapistService.getTherapistDetailsbyIdPublic(id).then(res => {
      setUserProfileDetails(res.data);

      const newStatesList = res.data?.therapyState;

      if (res.data.state && !newStatesList.includes(res.data?.state)) {
        newStatesList.push(res.data.state);
      }

      setTherapistStatesList(newStatesList);
    });
  };

  function togCenterVideo() {
    setModalCenterVideo(!modalcentervideo);
  }

  const handleClickAppointment = () => {
    const state = localStorage.getItem("state");
    if (state) {
      console.log("state ", state);
      console.log("therapistStatesList ",therapistStatesList)
      
      if (therapistStatesList?.includes(state)) {
        history.push(`/public-appoinments/${userProfileDetails._id}`);
      } else {
        setShowSelectStatePanel(true);
      }
    } else {
      setShowSelectStatePanel(true);
    }
  };

  const updateClientState = (state: any) => {
    if (!state) {
      toast.error("Please select state to proceed.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    localStorage.setItem("state", state);
    localStorage.removeItem("therapyType");
    history.push(`/public-appoinments/${userProfileDetails._id}`);
  };

  return (
    <React.Fragment>
      {/* <section className="top-bar">
        <div className="container">
          <div className="row rowWidth">
            <div className="col-6 col-md-8 ">
              <div className="contact-info">
                <p className="contact-item2 mainText2">Phone: +1 (980) 890 7522</p>
                <p className="contact-item2 mainText md-hide">Email: info@mylavni.com</p>
              </div>
            </div>
            <div className="col-6 col-md-4  text-md-end social-icons-container01">
              <a href="https://www.instagram.com/mylavni/">
                <span className="iconify" data-icon="uil:instagram"></span>
              </a>
              <a href="https://www.facebook.com/lavnihealth">
                <span className="iconify" data-icon="uil:facebook-f"></span>
              </a>
              <a href="https://www.linkedin.com/company/lavni/">
                <span className="iconify" data-icon="uil:linkedin"></span>
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <HeaderMain />

      <div>
        <Card className="d-flex justify-content-center align-items-center">
          <Row className="container">
            <Col className="col-xl-12 pt-5 pb-5">
              {userProfileDetails ? (
                <div className="card-bodyDiv1">
                  <Row className="">
                    <Col xl="12">
                      <Row>
                        <div
                          className="profileImage imageFit BorderRadiusLeftRight"
                          style={{
                            backgroundImage:
                              userProfileDetails?.coverPhotoId == null || userProfileDetails?.coverPhotoId == undefined || !userProfileDetails?.coverPhotoId
                                ? `url(${profileBackground})`
                                : `url(${Util.fileURL(userProfileDetails?.coverPhotoId?._id)})`,
                          }}
                        ></div>

                        <Row>
                          <Col xl={6}>
                            {userProfileDetails?.photoId && (
                              <div
                                className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl profile-user-wid-pro items-center margin_center mb-4 profileImageShow"
                                style={{
                                  backgroundImage:
                                    userProfileDetails?.photoId == null || userProfileDetails?.photoId == undefined
                                      ? `url(${user1})`
                                      : `url(${Util.fileURL(userProfileDetails?.photoId?._id)})`,
                                  borderRadius: "10px",
                                  position: "relative",
                                }}
                              ></div>
                            )}
                          </Col>

                          <Col xl={6}>
                            {userProfileDetails?.role == "THERAPIST" && (
                              <div>
                                {userProfileDetails?.vimeoId && (
                                  <div
                                    className="btn-video relative mt-3 pl-3 pr-3 pt-1 pb-1 mr-2 mb-4 cursor-pointer"
                                    style={{
                                      bottom: "0",
                                      float: "right",
                                    }}
                                    onClick={() => {
                                      togCenterVideo();
                                    }}
                                  >
                                    <img src={videoIcon} className="mr-2" />
                                    Watch Video
                                  </div>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Row>

                      <Row className=" pl-5 pr-5 mb-4">
                        <Col xl="12">
                          <Row>
                            {userProfileDetails?.role == "THERAPIST" && (
                              <Col xl={4}>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-17 profileFont mb-3">About Me</h5>
                                </div>
                                <div className="card-heading mb-2 pr-5">
                                  <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userProfileDetails?.description || "-" }} />
                                </div>
                              </Col>
                            )}

                            <Col xl={4}>
                              {userProfileDetails?.role == "THERAPIST" && (
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">Professional experience</h5>
                                  </div>

                                  <div className="card-heading mb-2">
                                    {userProfileDetails?.experiencedIn?.length == 0 && "-"}

                                    {userProfileDetails?.experiencedIn?.map((ex: any) => {
                                      return (
                                        <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                          {ex.experienceTag}
                                        </Link>
                                      );
                                    })}
                                  </div>

                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Profession</h5>
                                  </div>

                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 mb-3">{userProfileDetails?.profession?.name || "-"}</h5>
                                  </div>

                                  {userProfileDetails?.professionLicense?._id && (
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Profession License</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{userProfileDetails?.professionLicense?.name || "-"}</h5>
                                      </div>
                                    </div>
                                  )}

                                  {userProfileDetails?.role == "THERAPIST" && (
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Insurance Infomation</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        {userProfileDetails?.insuranceCompanies.length == "-"}
                                        {userProfileDetails?.insuranceCompanies.map((ex: any) => {
                                          return (
                                            <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                              {ex.insuranceCompany}
                                            </Link>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Col>

                            <Col>
                              {userProfileDetails?.role == "THERAPIST" && (
                                <div>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-3">Working days and hours</h5>
                                  </div>
                                  <div>
                                    <WorkingHoursComponent workingHours={userProfileDetails?.workingHours}></WorkingHoursComponent>
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12 mt50">
                              <hr />
                            </Col>
                            <Col xl="6"></Col>
                            <Col xl="6" className="text-right"></Col>
                          </Row>

                          <Row>
                            {/* <div className="card-heading mb-2">
                                                            <h5 className="font-size-17 profileFont mb-4">Personal Information</h5>
                                                        </div> */}

                            <Row>
                              {/* <Col xl="4">
                                                                <div className="card-heading mb-2">
                                                                    <h5 className="font-size-14 profileFont mb-2">Name</h5>
                                                                </div>
                                                                <div className="card-heading mb-2">
                                                                    <h5 className="font-size-14 mb-3">
                                                                        {userProfileDetails?.firstname || "-"} {userProfileDetails?.lastname}
                                                                    </h5>
                                                                </div>
                                                            </Col> */}

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-4">{userProfileDetails?.gender || "-"}</h5>
                                </div>
                              </Col>

                              {userProfileDetails?.role != "THERAPIST" && (
                                <Col xl="4">
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">Age</h5>
                                  </div>
                                  <div className="card-heading mb-2">
                                    {userProfileDetails?.dateOfBirth ? (
                                      <h5 className="font-size-14 mb-4">{moment().diff(moment(userProfileDetails?.dateOfBirth), "years")} years</h5>
                                    ) : (
                                      <h5 className="font-size-14 mb-4">{!userProfileDetails?.dateOfBirth && "-"}</h5>
                                    )}
                                  </div>
                                </Col>
                              )}

                              <Col xl="4">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 profileFont mb-2">Ethnicity</h5>
                                </div>
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-4">{userProfileDetails?.ethnicityId?.ethnicity || "-"}</h5>
                                </div>
                              </Col>

                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Spinner />
              )}
            </Col>
          </Row>
        </Card>

        {modalcentervideo && (
          <div
            className="lightbox1"
            onClick={() => {
              setModalCenterVideo(false);
            }}
          >
            <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
              <div className="modal-body1">
                <iframe
                  src={"https://player.vimeo.com/video/" + userProfileDetails?.vimeoId}
                  width="800"
                  className="videoSize"
                  height="400"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Lavni Health"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        {showSelectStatePanel && (
          <Modal27
            therapistName={userProfileDetails.firstname + " " + userProfileDetails.lastname}
            therapistStateList={userProfileDetails?.therapyState}
            showModal={showSelectStatePanel}
            setShowModal={setShowSelectStatePanel}
            updateClientState={updateClientState}
          />
        )}
      </div>

      <div
        className="card bg-white position-fixed d-flex justify-content-center align-items-center"
        style={{
          bottom: "0px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          marginBottom: "0px",
        }}
      >
        <div className="card-body">
          <button onClick={() => handleClickAppointment()} className="btn btn-primary btn-block rounded-pill5">
            Schedule Appointment
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicUserDetails;
