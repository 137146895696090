import React from "react";
import { Modal } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Close from "../../assets/images/icons/u_times-circle.png";
import AuthorizationFormPdfGeneratePage from "../AuthorizationForm/GeneratePdf/AuthorizationFormPdfGeneratePage";
import { AuthFormType } from "src/models/AuthorizationFormData";
import AuthorizationFormPdfUploadPage from "../AuthorizationForm/GeneratePdf/AuthorizationFormPdfUploadPage";

const AuthorizationFormPdfModal: React.FC<{ showModal: boolean; identifierId: any; setShowModal: (value: boolean) => void; isAdmin: boolean; eventType: "UPLOAD" | "DOWNLOAD"; insuranceDocApprovalId: any; setModalRefresher: ((value: boolean) => void) | null; authorizationFormType: AuthFormType; insuranceCompanyId: any; }> = props => {

    function closeAuthorizationFormGeneratePage() {
        props.setShowModal(false);
    }

    function uploadModalRefresher() {
        props.setModalRefresher?.(true);
    }
    
    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const eventType = props.eventType;
    const insuranceDocApprovalId = props.insuranceDocApprovalId;
    const authorizationFormType = props.authorizationFormType;
    const insuranceCompanyId = props.insuranceCompanyId;
    const modalClassName = "assesment-modal";

    return props.showModal ? (
        <>
            <Modal isOpen={props.showModal}
                className={modalClassName}
                centered
                fullscreen
                scrollable
            >
                <div
                    className="modal-head"
                    style={{
                        zIndex: 9998,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px"
                    }}
                >
                    <div className="assessment-header"
                        style={{ margin: "5px"}}>
                        <button
                            type="button"
                            onClick={closeAuthorizationFormGeneratePage}
                            className="bg-transparent-color"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={Close} alt="Close" className="close-icon-d" />
                        </button>
                    </div>
                </div>
                <div
                    className="modal-body scrollbar-container ps--active-y"
                    style={{ backgroundColor: "#fff", borderRadius: "5px" }}
                    >
                    {eventType === "DOWNLOAD" ? (
                        <AuthorizationFormPdfGeneratePage identifierId={identifierId} insuranceCompanyId={insuranceCompanyId} authorizationFormType={authorizationFormType} isAdmin={isAdmin} onPdfComplete={closeAuthorizationFormGeneratePage} />
                    ) : (
                        <AuthorizationFormPdfUploadPage identifierId={identifierId} insuranceDocApprovalId={insuranceDocApprovalId} authorizationFormType={authorizationFormType} isAdmin={isAdmin} onPdfComplete={closeAuthorizationFormGeneratePage} onUploadModalRefresher={uploadModalRefresher} />
                    )}
                </div>

                <div className="assessment-footer"
                    style={{ margin: "5px"}}>
                </div>
            </Modal>
        </>
    ) : null;
};

export default AuthorizationFormPdfModal;