import React, { useState, useContext } from "react";
import { Card, Col, Input, Row } from "reactstrap";
import UserContext from "../../context/UserContext";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AddressModel, DependentModel } from "../../models/Insurance";
import { ClientService } from "../../services/ClientService";
import Swal from "sweetalert2";

const InsuranceFormThree: React.FC<{
  removeFormTwoHandler: any;
  firstData: any;
  secondData: any;
  insuranceCardImage: any;
  insuranceCardImageBack: any;
  addInsuranceDetails: (value: any) => void;
}> = (props: any) => {
  const [user, setUser] = useContext(UserContext);
  const [dependentData, setDependentData] = useState<DependentModel>({} as DependentModel);
  const [dateValue, setDateValue] = useState(null);
  const [addressData, setAddressData] = useState<AddressModel>({} as AddressModel);
  const [haveAgree, setHaveAgree] = useState("No");
  let dependent: any;
  const [saveDisabled, setDisabled] = useState(false)

  const updateTherapistDetails = () => {
    const address: any = {
      address1: addressData?.address1,
      city: addressData?.city,
      state: addressData?.state,
      postalCode: addressData?.postalCode,
    };

    if (haveAgree == "No") {
      const data: any = {
        clientId: user?._id,
        insuranceCompanyId: props.firstData.organizationName,
        subscriber: props.secondData,
        dependent: dependent,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: "none",
      };
      setDisabled(true)
      ClientService.addInsurancePlan(data, props.insuranceCardImage, props.insuranceCardImageBack).then(res => {
        if (res.success) {
          setUser({ ...user, insuranceId: res.data });
          props.addInsuranceDetails(res.data);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          props.nextStep();
        } else {
          Swal.fire({
            icon: "warning",
            title: "Do you still want to proceed?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#FD7F00",
          }).then(result => {
            if (result.isConfirmed) {
              setDisabled(false)
              setUser({ ...user, insuranceId: res.errorData });

              props.addInsuranceDetails(res.errorData);
            } else {
              setDisabled(false)
            }
          });
          toast.error(res.errorCode, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      if (!dependentData?.memberId) {
        toast.error("Dependent member id is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else if (!dependentData?.firstName) {
        toast.error("Dependent first name is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return false;
      } else if (!addressData?.state) {
        toast.error("No valid state found matching for dependent.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        dependent = {
          memberId: dependentData?.memberId,
          policyNumber: dependentData?.policyNumber,
          firstName: dependentData?.firstName,
          lastName: dependentData?.lastName,
          gender: dependentData?.gender,
          dateOfBirth: dateValue as any,
          paymentResponsibilityLevelCode: dependentData?.paymentResponsibilityLevelCode,
          relationshipToSubscriberCode: dependentData?.relationshipToSubscriberCode,
          address: address,
        };

        const data: any = {
          clientId: user?._id,
          insuranceCompanyId: props.firstData.organizationName,
          subscriber: props.secondData,
          dependent: dependent,
          deletingInsuranceCardFrontId: "none",
          deletingInsuranceCardBackId: "none",
        };
        setDisabled(true)
        ClientService.addInsurancePlan(data, props.insuranceCardImage, props.insuranceCardImageBack).then(res => {
          if (res.success) {
            setUser({ ...user, insuranceId: res.data });

            props.addInsuranceDetails(res.data);

            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            props.nextStep();
          } else {
            Swal.fire({
              icon: "warning",
              title: "Do you still want to proceed?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#FD7F00",
              cancelButtonColor: "#FD7F00",
            }).then(result => {
              if (result.isConfirmed) {
                setDisabled(false)
                setUser({ ...user, insuranceId: res.errorData });

                props.addInsuranceDetails(res.errorData);
                props.nextStep();
              } else {
                setDisabled(false)
              }
            });
            toast.error(res.errorCode, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    }
  };

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }

  const db = moment.utc(user?.dateOfBirth).format("YYYY-MM-DD");

  function handlePostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setAddressData({ ...addressData, state: selectedState ? selectedState : "", postalCode: e });
  }

  let state;
  function getStateByZipCode(zipString: string) {
    if (typeof zipString != "string") {
      toast.error("Must pass the zipcode as a string.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    if (zipString.length != 5) {
      return false;
    }

    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      state = "Colorado";
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      state = "Florida";
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      state = "Maryland";
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode == 5501 || zipcode == 5544) {
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      state = "New Mexico";
    } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      state = "Ohio";
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      state = "Tennessee";
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      state = "Vermont";
    } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode == 20598) {
      state = "Virginia";
    } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      state = "Wyoming";
    } else {
      state = "";
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    return state;
  }

  return (
    <>
      <Card className="BorderRadiusLeftRight paddingInsurance">
        <div className="card-bodyDiv">
          <Row>
            <div className="card-heading mb-2">
              <h5 className="font-size-20 profileFont mb-4 float-right">Step 03</h5>
            </div>
          </Row>

          <Row>
            <Col xl="12">
              <div className="card-heading mb-2">
                <h5 className="font-size-14 profileFont mb-2">Do you wish to add any dependents?</h5>
              </div>
            </Col>

            <Col xl="4">
              <div className="card-heading mt-2 mb-3">
                <select className="form-control" onChange={e => setHaveAgree(e.target.value)}>
                  <option selected>Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No" selected>
                    No
                  </option>
                </select>
              </div>
            </Col>
          </Row>

          {haveAgree == "Yes" && (
            <>
              <div className="card-heading mb-2">
                <h5 className="font-size-20 profileFont mb-4">Dependent</h5>
              </div>
              <Row>
                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Member Id "
                        name="Member Id "
                        value={dependentData?.memberId || ""}
                        onChange={e => setDependentData({ ...dependentData, memberId: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Payment Responsibility Level Code</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Payment Responsibility Level Code"
                        name="Payment Responsibility Level Code"
                        value={dependentData?.paymentResponsibilityLevelCode || ""}
                        onChange={e => setDependentData({ ...dependentData, paymentResponsibilityLevelCode: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="First name"
                        name="First name"
                        value={dependentData?.firstName || ""}
                        onChange={e => setDependentData({ ...dependentData, firstName: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Last name"
                        name="Last name"
                        value={dependentData?.lastName || ""}
                        onChange={e => setDependentData({ ...dependentData, lastName: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                  </div>
                  <div className="card-heading mb-4">
                    <Row>
                      <Col style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios1"
                            value="Female"
                            defaultChecked={dependentData?.gender == "Female"}
                            onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                            Female
                          </label>
                        </div>
                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios2"
                            value="Male"
                            defaultChecked={dependentData?.gender == "Male"}
                            onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios2">
                            Male
                          </label>
                        </div>

                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios2"
                            value="NonBinary"
                            defaultChecked={dependentData?.gender == "NonBinary"}
                            onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios2">
                            Non Binary
                          </label>
                        </div>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadioss"
                            id="exampleRadios3"
                            value="Other"
                            defaultChecked={dependentData?.gender == "Other"}
                            onChange={e => setDependentData({ ...dependentData, gender: e.target.value })}
                          />
                          <label className="form-check-label" htmlFor="exampleRadios3">
                            Other
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Date Of Birth</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Date of birth"
                        value={db || ""}
                        onChange={e => handleDateUpdate(e)}
                        max={moment.utc().format("YYYY-MM-DD")}
                        disabled
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">policy Number</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="policy Number"
                        name="policy Number"
                        value={dependentData?.policyNumber || ""}
                        onChange={e => setDependentData({ ...dependentData, policyNumber: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Relationship To Subscriber Code</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Relationship To Subscriber Code"
                        name="Relationship To Subscriber Code"
                        value={dependentData?.relationshipToSubscriberCode || ""}
                        onChange={e => setDependentData({ ...dependentData, relationshipToSubscriberCode: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Address</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Address"
                        name="Address"
                        value={addressData?.address1 || ""}
                        onChange={e => setAddressData({ ...addressData, address1: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">City</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="City"
                        name="City"
                        value={addressData?.city || ""}
                        onChange={e => setAddressData({ ...addressData, city: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col xl="6">
                  <div>
                    <div className="card-heading mb-2">
                      <h5 className="font-size-14 profileFont mb-2">Postal Code</h5>
                    </div>
                    <div className="card-heading mb-4 flex">
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Postal Code"
                        name="Postal Code"
                        value={addressData?.postalCode}
                        onChange={e => handlePostalCode(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>

                {addressData?.state && (
                  <Col xl="6">
                    <div>
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">State</h5>
                      </div>
                      <div className="card-heading mb-4 flex">
                        <h5 className="font-size-14 profileFont mb-2">{addressData?.state}</h5>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}
        </div>
        <Row>
          <Col xl={6}></Col>
          <Col xl={6}>
            <button
              type="button"
              className={saveDisabled ? "btn btn-success relative mt-3 mb-2 updating" : "btn btn-success relative mt-3 mb-2"}
              style={{
                bottom: "0",
                float: "right",
              }}
              disabled={saveDisabled}
              onClick={() => {
                updateTherapistDetails();
              }}
            >
              {saveDisabled ? "Saving..." : "Finalize"}
            </button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InsuranceFormThree;
