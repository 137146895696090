import moment from "moment";
import React, { useContext } from "react";
import ArticleContext from "../../../context/ArticleContext";
import UserContext from "../../../context/UserContext";
import { ArticleService } from "../../../services/ArticleService";
import { ArticleListView } from "./ArticleListView";

interface ArticlePaneProps {
  userId: any;
  imageUrl: any;
  userData: any;
}

const ArticlePane: React.FC<ArticlePaneProps> = ({ userId, imageUrl, userData }: ArticlePaneProps) => {
  const [article, setArticle] = useContext(ArticleContext);
  const [user] = useContext(UserContext);

  const likePost = (postId: any) => {
    ArticleService.addLike(postId).then(res => {
      if (res.success) {
        const updatedArticle = article.map(item => {
          if (item._id == postId) {
            if (item.likedBy?.includes(userId)) {
              item.likedBy = item.likedBy?.filter(id => id !== userId);
            } else {
              item.likedBy?.push(userId);
            }
          }
          return item;
        });
        setArticle(updatedArticle);
      } else {
        setArticle(article);
      }
    });
  };

  const addComment = (postId: any, comment: string, setComment: any) => {
    let dataset = null;

    const updatedArticle = article.map(item => {
      if (item._id == postId) {
        const data = {
          _id: new Date().valueOf().toString(),
          userId: {
            firstname: user?.firstname,
            lastname: user?.lastname,
            photoId: {
              url: imageUrl,
              _id: userData?.photoId?._id,
              type: userData?.photoId?.type,
            },
            role: user?.role,
            _id: userId,
          },
          comment: comment,
          replies: [],
          date: new Date().toISOString(),
        };
        item.comments.push(data);
        dataset = {
          comment: item.comments,
        };
      }
      return item;
    });

    setArticle(updatedArticle);
    setComment("");

    ArticleService.updateCommentReply(postId, dataset);
  };

  const addReply = (articleId: string, commentId: string, reply: string, setReply: any) => {
    let data = null;

    const updatedArticle = article.map(item => {
      if (item._id == articleId) {
        const replyData = {
          userId: {
            _id: userId,
            firstname: user?.firstname,
            lastname: user?.lastname,
            photoId: {
              url: imageUrl,
              _id: userData?.photoId?._id,
              type: userData?.photoId?.type,
            },
            role: user?.role,
          },
          reply: reply,
          date: new Date().toISOString(),
        };
        const comment = item.comments.filter(comment => comment._id == commentId);

        comment[0].replies = comment[0].replies?.concat(replyData);
        item.comments.map(a => (a._id == commentId ? comment : a));

        data = {
          comment: item.comments,
        };
      }
      return item;
    });
    setArticle(updatedArticle);
    setReply("");

    ArticleService.updateCommentReply(articleId, data);
  };

  return (
    <div>
      {article.map((item, index) => {
        return (
          <div key={index}>
            <ArticleListView
              articleBody={item.articleBody}
              articleId={item._id}
              articleFile={item.uploadId}
              vimeoId={item.vimeoId}
              articleTitle={item.articleTitle}
              comments={item.comments}
              likedBy={item.likedBy}
              publishedDate={moment(item.createdAt.toString()).format("YYYY/MM/DD")}
              userId={userId}
              likePost={likePost}
              addComment={addComment}
              addReply={addReply}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ArticlePane;
