import * as React from "react";
import { TimeOut } from "../models/TimeOut";

const initialData: TimeOut = {
  isTimeOutRunning: true,
  userOnMeetingScreen: false,
  showExtendPopup: false,
  callAcceptedByReciever: false,
};

type LocalCallTimeOutlData = [callTimeOut: TimeOut, setCallTimeOut: (data: TimeOut) => void, setExtendedCallTimeOut: (time: number) => void];

const LocalCallTimeOutContext = React.createContext<LocalCallTimeOutlData>([initialData, () => ({}), () => ({})]);

export default LocalCallTimeOutContext;
