import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import { CommonService } from "../../services/CommonService";

const CallSettingTab: React.FC = (props: any) => {

  const [user, setUser] = useContext(UserContext);
  const [changeAllowRecordingChangingStatus, setChangeAllowRecordingChangingStatus] = useState<boolean>(false);
  const [changeHideCallTimerStatus, setChangeHideCallTimerStatus] = useState<boolean>(false);

  const mounted = useRef(false);

  useEffect(() => {

    mounted.current = true;

    return () => {
      mounted.current = false;
    };

  }, []);

  function changeAllowRecording(value: boolean) {

    if (changeAllowRecordingChangingStatus) {

      toast.success("Already changing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

    } else {

      setChangeAllowRecordingChangingStatus(true);
      setUser({ ...user, callRecordingAllowed: value });

      CommonService.updateAllowRecording(value).then(res => {
        if (res.success) {

          if (mounted) {
            setChangeAllowRecordingChangingStatus(false);
          }

          toast.success(value ? "You have enabled allow call transcripts." : "You have disabled allow call transcripts.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

        } else {

          setUser({ ...user, callRecordingAllowed: !value });
          if (mounted) {
            setChangeAllowRecordingChangingStatus(false);
          }

          toast.error("Allow call recording update failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

        }
      });
    }
  }

  function changeHideCallTimer(value: boolean) {

    if (changeHideCallTimerStatus) {

      toast.success("Already changing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

    } else {

      setChangeHideCallTimerStatus(true);
      setUser({ ...user, hideCallTimer: value });

      CommonService.updateHideCallTimer(value).then(res => {
        if (res.success) {

          if (mounted) {
            setChangeHideCallTimerStatus(false);
          }

          toast.success(!value ? "Call timer will be visible on your calls." : "Call timer will be no longer visible on your calls.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

        } else {

          setUser({ ...user, hideCallTimer: !value });

          if (mounted) {
            setChangeHideCallTimerStatus(false);
          }

          toast.error("Hide callTimer update failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

        }
      });
    }
  }
  
  function changeIncognitoMode(value: boolean) {
      setUser({ ...user, incognito: value });

      CommonService.updateIncognitoMode(value).then(res => {
        if (res.success) {
          if (res.success && value) {
            toast.success("You have enabled incognito mode.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
          if (res.success && !value) {
            toast.success("You have disabled incognito mode.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

        } else {
          setUser({ ...user, incognito: !value });
          toast.error("Incognito mode update failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

        }
      });
  }

  return (
    <React.Fragment>
      <Col lg="10">
        {/* <Row>
          <div className="d-flex">
            <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
              Allow Call Transcript
            </h5>

            <div className="form-check form-switch form-switch-lg">
              <label className="form-check-label" htmlFor="newsletter">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="newsletter"
                  checked={user?.callRecordingAllowed != null && user?.callRecordingAllowed == true ? true : false}
                  onChange={e => {}}
                  onClick={() => {
                    changeAllowRecording(user?.callRecordingAllowed != null ? !user?.callRecordingAllowed : true);
                  }}
                />
              </label>
            </div>
          </div>
        </Row> */}
        <Row>
          <div className="d-flex">
            <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
              Hide Call Timer
            </h5>

            <div className="form-check form-switch form-switch-lg">
              <label className="form-check-label" htmlFor="newsletter">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="newsletter"
                  checked={user?.hideCallTimer != null && user?.hideCallTimer == true ? true : false}
                  onChange={e => {}}
                  onClick={() => {
                    changeHideCallTimer(user?.hideCallTimer != null ? !user?.hideCallTimer : true);
                  }}
                />
              </label>
            </div>
          </div>
        </Row>
        {user?.role == 'CLIENT'&& <Row>
          <div className="d-flex">
            <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
              Avatar Mode
            </h5>

            <div className="form-check form-switch form-switch-lg">
              <label className="form-check-label" htmlFor="newsletter">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="newsletter"
                  checked={user?.incognito == true ? true : false}
                  onChange={e => {}}
                  onClick={() => {
                    changeIncognitoMode(!user?.incognito);
                  }}
                />
              </label>
            </div>
          </div>
        </Row>}
      </Col>
    </React.Fragment>
  );
};

export default CallSettingTab;
