import React, { useState } from "react";
import { CardTitle, Input, Modal } from "reactstrap";

interface TradingParterModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setTradingServiceId: (value: string) => void;
}

const TradingParterModal: React.FC<TradingParterModalProps> = ({ showModal, setShowModal, setTradingServiceId }: TradingParterModalProps) => {
  const [serviceId, setServiceId] = useState<string>("");
  const [errors, setErrors] = useState({
    serviceId: false,
  });

  const handleChange = (serviceId: string) => {
    if (serviceId == "" || serviceId == undefined || serviceId == null) {
      setErrors({ serviceId: true });
    } else {
      setErrors({ serviceId: false });      
    }
    setServiceId(serviceId);
  };

  const validateEntries = () => {
    let isValid = true;
    if (serviceId == "" || serviceId == undefined || serviceId == null) {
      isValid = false;
    }
    return isValid;
  };

  const submitServiceId = () => {
    const isValid = validateEntries();

    if (isValid) {
      setTradingServiceId(serviceId);
      setShowModal(!showModal);
    } else {
      setErrors({ serviceId: true });
    }
  };

  return (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-header" style={{ border: "none" }}>
          <button type="button" onClick={() => setShowModal(!showModal)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <CardTitle className="text-left">Trading Partner Service Id</CardTitle>

          <div className="mb-3">
            <Input
              type="text"
              className={errors.serviceId ? "form-control is-invalid" : "form-control"}
              value={serviceId}
              onChange={event => handleChange(event?.target.value)}
            ></Input>

            {errors.serviceId && <div className="invalid-feedback">This field is required!</div>}
          </div>

          <div className="flex flex-row-reverse mb-3">
            <button className="btn btn-success" onClick={submitServiceId}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TradingParterModal;
