import React, { useContext } from "react";
import { CardTitle, CardBody } from "reactstrap";
import UserContext from "../../context/UserContext";
import { useHistory } from "react-router-dom";

const Modal19: React.FC<{ showModal19: boolean; setShowModal19: (value: boolean) => void }> = props => {
    const [user] = useContext(UserContext);
    const history = useHistory();
    return props.showModal19 ? (
        <>
            <CardBody>
                {
                    user?.role == "CLIENT" ? (
                        <>
                            <CardTitle className="padding-l-r font-size-18">How would you like to pay for the appointment?</CardTitle>
                            <p className="mb-5 padding-l-r">/ Out of pocket</p>
                            <h6 className="padding-l-r">We Accepts Cards</h6>
                            
                            <div className="contact-links d-flex font-size-20 mb-4 padding-l-r">
                                <button className="btn btn-dark-blue1 mr-20">
                                    <span className="font-size-12 "><span className="icon_text_s1">Debit</span></span>
                                </button>
                        
                        
                                <button className="btn btn-dark-yellow  mr-20">
                                    <span className="font-size-12 "><span className="icon_text_s1">Credit</span></span>
                                </button>

                                <button className="btn btn-dark-pink mr-20">
                                    <span className="font-size-12"><span className="icon_text_s1">FSA</span></span>
                                </button>
                        
                                <button className="btn btn-dark-green  ">
                                    <span className="font-size-12"><span className="icon_text_s1">HSA</span></span>
                                </button>
                            </div>

                            <div className="padding-l-r mb-5">
                                <button type="button" className="btn btn-primary btn-sm"  onClick={() => {
                                    history.push(`/subscription`);
                                }}>
                                    Continue &nbsp;
                                    <i className="fas fa-regular fa-arrow-right "></i>
                                </button>
                            </div>
                        </>

                    ) : (
                        <CardTitle className="mb-4 text-center font-size-20">This client doesn&lsquo;t have an active subscription.</CardTitle>
                    )
                }
            </CardBody>
        </>
    ) : null;
};

export default Modal19;
