import React, { useState, useEffect } from "react";

import { File, Folder } from "./types";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card } from "reactstrap";
import { CiFolderOn } from "react-icons/ci";
import { CiLink } from "react-icons/ci";
import { IoMdMore } from "react-icons/io";
import "./style.css";
import { TrainingDocumentFolder } from "./../../models/TrainingDocumentFolders";

interface FolderListComponentProps {
  folder: TrainingDocumentFolder;
  selectedFolder: (folder: TrainingDocumentFolder) => void;
  deleteFolders: (folderId: string) => void;
  handleClickOutside: () => void;
  handleContextMenu: (event: React.MouseEvent, cardId: string) => void;
  isDeleteActionDropDownOpen: boolean;
  toggleDeleteActionDropDown: () => void;
  toggleFolderRenameModal: () => void;
}

const FolderListComponent: React.FC<FolderListComponentProps> = ({
  folder,
  selectedFolder,
  deleteFolders,
  handleClickOutside,
  handleContextMenu,
  isDeleteActionDropDownOpen,
  toggleDeleteActionDropDown,
  toggleFolderRenameModal,
}) => {
  const history = useHistory();

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const handleContextMenu = (event: React.MouseEvent) => {
  //   event.preventDefault();
  //   setDropdownOpen(true);
  // };

  // const handleClickOutside = () => {
  //   setDropdownOpen(false);
  // };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // onClick={() => history.push(`folder/9384848`)}
  return (
    <div className="folder" onContextMenu={event => handleContextMenu(event, folder._id)}>
      {/* <div className="folder-hoverclass">
        <div className="folder-hover-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Form>
            <FormGroup check inline>
              <Input type="checkbox" />
              <Label check></Label>
            </FormGroup>
          </Form>
          <div className="folder-hover-panel">
            <Button>Share</Button>
            <CiLink size={20} />
            <IoMdMore size={30} />
          </div>
        </div>
      </div> */}
      <div
        onClick={() => {
          selectedFolder(folder);
          // handleNavigation(`/training-documents/folder/${folder.id}`);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link to={`/documents/folder/${folder._id}`}>
          <CiFolderOn size={100} />
        </Link>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            {/* <Card
              onContextMenu={handleContextMenu}
              //  style={{ width: "200px", height: "200px", cursor: "context-menu" }}
            >
              ...
            </Card> */}

            {isDeleteActionDropDownOpen && (
              <Dropdown
                isOpen={isDeleteActionDropDownOpen}
                toggle={toggleDeleteActionDropDown}
                style={{
                  zIndex: "20",
                  top: "0px",
                  // position: "absolute",
                  // top: `${dropdownPosition.y}px`,
                  // left: `${dropdownPosition.x}px`,
                  // display: "block",
                }}
              >
                <DropdownToggle tag="div" className="d-none" />
                <DropdownMenu>
                  <DropdownItem header>Actions</DropdownItem>
                  <DropdownItem onClick={() => deleteFolders(folder._id)}>Delete Folder</DropdownItem>
                  <DropdownItem onClick={toggleFolderRenameModal}>Rename Folder</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>

        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px", // Adjust the max-width as needed
          }}
          // className="folder-name"
        >
          {folder.folderName}
        </p>
      </div>
    </div>
  );
};

export default FolderListComponent;
