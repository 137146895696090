import { PDFDocument } from "pdf-lib";

const compressPdf = async (file: File): Promise<File> => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    pdfDoc.setTitle("Client Insurance PDF");
    pdfDoc.setAuthor("Lavni Inc");

    const compressedPdfBytes = await pdfDoc.save({ useObjectStreams: true });

    const compressedPdfFile = new File([compressedPdfBytes], file.name, {
      type: "application/pdf",
      lastModified: Date.now(),
    });

    return compressedPdfFile;
};

export default compressPdf;