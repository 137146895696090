
import React, { useState } from "react";
import { Container, Row, Col, Card, Input, CardTitle } from "reactstrap";
import "../../assets/css/admin.css";
import { CommonService } from "../../services/CommonService";
import { Ethinicity } from "../../models/Ethnicity";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const CreateEthnicity: React.FC = () => {

    const [ethnicityList, setEthnicityList] = useState<Ethinicity>({} as Ethinicity);
    const back = () => {
        history.back()
    }


    const AddEthnicity = () => {
        if (!ethnicityList?.ethnicity) {
            toast.error("Ethnicity is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        } else {
            const updatedEthnicity: Ethinicity = {
                ethnicity: ethnicityList?.ethnicity
            };
            CommonService.createEthnicity(updatedEthnicity).then((res) => {
                if (res.success) {
                    setEthnicityList(res.data);
                    toast.error(res.success, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                    window.history.back();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                }
            });
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="flex flex-wrap  cursor-pointer" onClick={() => back()}>
                    <i className="mb-4 mt-1 fas fa-regular fa-arrow-left "></i>
                    <CardTitle className="mb-4  cursor_pointer">&nbsp; Back</CardTitle>
                </div>
                <Container className="containerOnboarding mt-5">
                    {/* <Breadcrumbs title={"Create Ethnicity"} breadcrumbItem={"Create Ethnicity"} /> */}

                    <Card className="BorderRadiusLeftRight pt-4 pl-3 pb-2">
                        <div className="card-bodyDiv">
                            <Row>
                                <Col xl="6">
                                    <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Ethnicity</h5>
                                    </div>
                                    <div className="card-heading mb-4">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Ethnicity"
                                            name="Ethnicity"
                                            value={ethnicityList?.ethnicity || ""}
                                            onChange={(e) => setEthnicityList({ ...ethnicityList, ethnicity: e.target.value })}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}></Col>
                                <Col xl={6}>
                                    <button
                                        type="button"
                                        className="btn btn-primary relative mt-3 mb-2" style={{
                                            bottom: "0",
                                            float: "right",
                                        }}
                                        onClick={() => {
                                            AddEthnicity();
                                        }}>
                                        Save
                                    </button>


                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateEthnicity;
