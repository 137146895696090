
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../assets/css/admin.css";
const classNames = require('classnames');
import { Therapist } from "../../models/Therapist";
import { AdminService } from "../../services/AdminService";
import { ApproveReject } from "../../models/User";
import Swal from "sweetalert2";
import moment from "moment";
import { Link } from "react-router-dom";
import Spinner from "../../common/spinner/spinner";

const ManageTherapists: React.FC = () => {
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [pendingTherapists, setPendingTherapists] = useState([] as Therapist[]);
    const [approvedTherapists, setApprovedTherapists] = useState([] as Therapist[]);
    const [isLoading, setIsLoading] = useState(true);
    
    const LIMIT_P_T = 10;
    const LIMIT_A_T = 10;

    const [isOffset_p_t, setOffset_p_t] = useState<number>(0);
    const [seeMore_p_t, setSeeMore_p_t] = useState(false);

    const [isOffset_a_t, setOffset_a_t] = useState<number>(0);
    const [seeMore_a_t, setSeeMore_a_t] = useState(false);


    useEffect(() => {
        AdminService.getAllPendingTherapists(LIMIT_P_T, isOffset_p_t).then(res => {
            if (res.success) {
                setPendingTherapists(res.data);
                setSeeMore_p_t(res.data.length > 0 && res.data.length == LIMIT_P_T);
            } else {
                setSeeMore_p_t(false);
            }
        });

        AdminService.getAllApprovedTherapists(LIMIT_A_T, isOffset_a_t).then(res => {
            if (res.success) {
                setApprovedTherapists(res.data);
                setSeeMore_a_t(res.data.length > 0 && res.data.length == LIMIT_A_T);
            } else {
                setSeeMore_a_t(false);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const seeMorePendingTherapist = () => {
        const updatedSkip = isOffset_p_t + LIMIT_P_T;
        setOffset_p_t(updatedSkip);
        AdminService.getAllPendingTherapists(LIMIT_P_T, updatedSkip).then(res => {
            if (res.success) {
                setPendingTherapists(prev => {
                    return [...new Set([...prev, ...res.data])];
                });
                setSeeMore_p_t(res.data.length > 0 && res.data.length == LIMIT_P_T);
            } else {
                setSeeMore_p_t(false);
            }
        });
    }

    const seeMoreApprovedTherapist = () => {
        const updatedSkip = isOffset_a_t + LIMIT_A_T;
        setOffset_a_t(updatedSkip);
        AdminService.getAllApprovedTherapists(LIMIT_A_T, updatedSkip).then(res => {
            if (res.success) {
                setApprovedTherapists(prev => {
                    return [...new Set([...prev, ...res.data])];
                });
                setSeeMore_a_t(res.data.length > 0 && res.data.length == LIMIT_A_T);
            } else {
                setSeeMore_a_t(false);
            }
        });
    }

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const approveRejectTherapist = (e: any) => {
        const therapistId = e.currentTarget.getAttribute("data-value1");
        const status = e.currentTarget.getAttribute("data-value2");

        if (status == "true") {
            Swal.fire({
                icon: "info",
                title: "Are you sure want to approve this therapist?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#50a5f1",
                cancelButtonText: "No",
                cancelButtonColor: "#f46a6a",
            }).then(result => {
                if (result.isConfirmed) {
                    const data: ApproveReject = {
                        userId: therapistId,
                        status: true
                    }

                    AdminService.approveRejectTherapist(data).then(res => {
                        const approvedTherapist = pendingTherapists.filter((c) => c._id === therapistId)[0];

                        setApprovedTherapists(approvedTherapists => [...approvedTherapists, approvedTherapist]);

                        const updatedPendingTherapistList = pendingTherapists.filter((c) => c._id !== therapistId);

                        setPendingTherapists(updatedPendingTherapistList);
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "warning",
                title: "Are you sure want to remove this therapist?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#50a5f1",
                cancelButtonText: "No",
                cancelButtonColor: "#f46a6a",
            }).then(result => {
                if (result.isConfirmed) {
                    const data: ApproveReject = {
                        userId: therapistId,
                        status: false
                    }

                    AdminService.approveRejectTherapist(data).then(res => {
                        const removedTherapist = approvedTherapists.filter((c) => c._id === therapistId)[0];

                        setPendingTherapists(pendingTherapists => [...pendingTherapists, removedTherapist]);

                        const updatedApprovedTherapistList = approvedTherapists.filter((c) => c._id !== therapistId);

                        setApprovedTherapists(updatedApprovedTherapistList);
                    });
                }
            });
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Manage Therapists"} breadcrumbItem={"Manage Therapists"} />

                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl="12">
                                    <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-view-grid-outline"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Pending Therapists</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-format-list-bulleted"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Approved Therapists</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <div className="table-responsive">
                                    {isLoading ? (
                                            <Spinner />
                                        ) : (
                                        <TabContent activeTab={customActiveTab} className="p-3 text-muted" >
                                        <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                pendingTherapists.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                <th scope="col">Profile</th>
                                                                                <th scope="col">Created Date</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                pendingTherapists && pendingTherapists.map((therapist, index) => (
                                                                                    <tr key={therapist._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{therapist.firstname} {therapist.lastname}</td>
                                                                                        <td>{therapist.email}</td>
                                                                                        <td>{therapist.primaryPhone}</td>
                                                                                        <td>
                                                                                            <Link to={"/profile/" + therapist._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td>
                                                                                        <td>{therapist?.createdAt ? moment(therapist?.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                                                                        <td><span className="btn btn-warning status">Pending</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-success" onClick={approveRejectTherapist} data-value1={therapist._id} data-value2={true}>Approve</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Pending Therapists</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_p_t && (
                                                            <button className="btn btn-warning" onClick={() => seeMorePendingTherapist()}>
                                                                See More
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>

                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                approvedTherapists.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                <th scope="col">Profile</th>
                                                                                <th scope="col">Created Date</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                approvedTherapists && approvedTherapists.map((therapist, index) => (
                                                                                    <tr key={therapist._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{therapist.firstname} {therapist.lastname}</td>
                                                                                        <td>{therapist.email}</td>
                                                                                        <td>{therapist.primaryPhone}</td>
                                                                                        <td>
                                                                                            <Link to={"/profile/" + therapist._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td>
                                                                                        <td>{therapist?.createdAt ? moment(therapist?.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                                                                        <td><span className="btn btn-success status">Approved</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-danger" onClick={approveRejectTherapist} data-value1={therapist._id} data-value2={false}>Reject</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Approved Therapists</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_a_t && (
                                                            <button className="btn btn-warning" onClick={() => seeMoreApprovedTherapist()}>
                                                                See More
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                         )}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageTherapists;

