import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FormVersionService } from 'src/services/FormVersionService';
import { CommonService } from 'src/services/CommonService';

interface Props {
  formId?: string;
  updateAssesment?: any;
  getAssesmentByClientId: () => void;
  nextStep: () => void;
  onModalRefresher: () => void;
  setIsVersionCreated: (created: boolean) => void;
  closePopup: () => void;
}

export async function handleAmendment({
  formId,
  updateAssesment,
  getAssesmentByClientId,
  nextStep,
  onModalRefresher,
  setIsVersionCreated,
  closePopup,
}: Props) {
  const result = await Swal.fire({
    title: 'Reason for Amendment',
    input: 'text',
    inputLabel: 'Please provide a reason for amending this clinical assessment.',
    inputPlaceholder: 'Enter reason for amending...',
    inputValidator: (value) => {
      if (!value) {
        return 'You need to provide a reason!';
      }
      return null;
    },
    showCancelButton: true,
    confirmButtonText: 'Save',
  });

  if (result?.isConfirmed && result?.value) {
    const currentClinicalAssessment = await FormVersionService.getCurrentClinicalAssessment(formId);

    if (currentClinicalAssessment && currentClinicalAssessment.success && currentClinicalAssessment.data) {
      const versioningData = {
        ...currentClinicalAssessment.data,
        clinicalAssesmentId: currentClinicalAssessment.data._id,
        reasonForEdit: result?.value,
      };

      const versionRes = await FormVersionService.createClinicalAssessmentVersion(versioningData);
      if (!versionRes || !versionRes.success) {
        toast.error('Something went wrong when saving the assessment', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar',
        });
        return;
      }

      const res = await CommonService.updateClinicalAssesment(updateAssesment);
      if (res && res.success && res.data) {
        onModalRefresher();
        closePopup();
        toast.success('Clinical assessment has been successfully updated!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar',
        });
        getAssesmentByClientId();
        nextStep();
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar',
        });
      }
      setIsVersionCreated(true);
    } else {
      toast.error('Error retrieving the current clinical assessment', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar',
      });
    }
  }
}
