import axios from 'axios';
import { Goal } from '../models/Goal';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';

export class GoalService {
    public static async createGoal(data: Partial<Goal>): Promise<AppResponse<Goal>> {
        const url = Util.apiAuthUrl('createGoal');
        return await axios.post<FormData, AppResponse<Goal>>(url, data);
    }

    public static async updateGoal(goalId: string, data: Partial<Goal> | undefined): Promise<AppResponse<Goal>> {
        const url = Util.apiAuthUrl('updateGoal/' + goalId);
        return await axios.post<FormData, AppResponse<Goal>>(url, data);
    }

    public static async getGoalById(goalId: string): Promise<AppResponse<Goal>> {
        const url = Util.apiAuthUrl("getGoal/" + goalId);
        return await axios.get<Partial<Goal>, AppResponse<Goal>>(url);
    }

    public static async getAllGoalById(
        createdId: string,
        assignedId: string,
        limit?: number,
        offset?: number): Promise<AppResponse<Goal[]>> {
        const url = Util.apiAuthUrl(`getAllGoals/` + createdId + `/` + assignedId + "/" + limit + "/" + offset);
        return await axios.get<void, AppResponse<Goal[]>>(url);
    }

    public static async deleteGoal(goalId: string): Promise<AppResponse<Goal>> {
        const url = Util.apiAuthUrl("deleteGoal/" + goalId);
        return await axios.delete<Partial<Goal>, AppResponse<Goal>>(url);
    }
}