import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FormVersionService } from "src/services/FormVersionService";
import { CommonService } from "src/services/CommonService";

interface Props {
  formId?: string;
  updateAssesment?: any;
  nextStep: () => void;
  onModalRefresher: () => void;
  setIsVersionCreated: (created: boolean) => void;
  closeClinicalAssessmentModal: () => void;
}

export async function handleAmendment({ 
  formId,
  updateAssesment,
  nextStep,
  onModalRefresher,
  setIsVersionCreated,
  closeClinicalAssessmentModal,
}: Props) {
  const result = await Swal.fire({
    title: "Reason for Amendment",
    input: "text",
    inputLabel: "Please provide a reason for amending this digital assessment.",
    inputPlaceholder: "Enter reason for amending...",
    inputValidator: value => {
      if (!value) {
        return "You need to provide a reason!";
      }
      return null;
    },
    showCancelButton: true,
    confirmButtonText: "Save",
  });

  if (result?.isConfirmed && result?.value) {
    const currentDigitalAssessment = await FormVersionService.getCurrentNewTypeOfClinicalAssessment(formId);

    // if (currentDigitalAssessment && currentDigitalAssessment.success && currentDigitalAssessment.data) {
      const versioningData = {
        ...currentDigitalAssessment.data,
        digitalAssessmentId: currentDigitalAssessment.data._id,
        reasonForEdit: result?.value,
      };

      const versionRes = await FormVersionService.createNewTypeOfClinicalAssessmentVersion(versioningData);
      // if (!versionRes || !versionRes.success) {
      //   toast.error("Error saving the current version before updating", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     className: "foo-bar",
      //   });
      //   return;
      // }

      const res = await CommonService.updateDigitalAssessmentDetails(updateAssesment);
      if (res && res.success && res.data) {
        onModalRefresher();
        closeClinicalAssessmentModal();
        toast.success("Digital assessment has been successfully updated!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        nextStep();
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
      setIsVersionCreated(true);
    // } else {
    //   toast.error("Error retrieving the current clinical assessment", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     className: "foo-bar",
    //   });
    // }
  }
}
