import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { UserDetails } from "../models/User";
import { environment } from "../environment/environment";
import UserContext from "../context/UserContext";
import { AuthService } from "../services/AuthService";
import { SocketContext } from "../context/ScoketContext";
import { AudioContext } from "../context/AudioContext";
import { toast } from "react-toastify";
import LocalCallContext from "../context/LocalCallContext";
import { LocalCall } from "../models/LocalCall";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grow } from "@mui/material";
import NotificationContext from "../context/NotificationContext";
import { NotificationService } from "../services/NotificationService";
import { NotificationEvent, NotificationModel, NotificationVarient } from "../models/Notification";
import { CardTitle, Col, Row, Modal, CardBody, CardFooter, Container, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import CallInitializationContext from "../context/CallInitializationContext";
import LocalCallTimeOutContext from "../context/LocalCallTimeOutlContext";
import { TimeOut } from "../models/TimeOut";
import CountDownTimer from "../pages/Chat/CountDownTimer";
import CallerNotOnline from "../pages/Popup/CallerNotOnlineModal";
import { NearestAppointment } from "../models/NearestAppointment";
import { VideoChatService } from "../services/VideoChatService";
import { Role } from "../models/Role";
import moment from "moment";
import { Appointment, AppointmentStatus, AppointmentType, ApprovalStatus } from "../models/Appointment";
import Modal13 from "../pages/Popup/Modal13";
import Modal12 from "../pages/Popup/Modal12";
import Modal11 from "../pages/Popup/Modal11";
import MessageModal from "../pages/Popup/MessageModal";
import Modal4 from "../pages/Popup/Modal4";
import Modal5 from "../pages/Popup/Modal5";
import Modal17 from "../pages/Popup/Modal17";
import Modal18 from "../pages/Popup/Modal18";
import HideTimerWhenCallModal from "../pages/Popup/HideTimerWhenCallModal";
import { PremiumStatus } from "../models/Client";
import CallEndContext from "../context/CallEndContext";
import { StartCallSocketData } from "../models/startCallSocketData";
import { CommonService } from "../services/CommonService";
import { IncompletedCall } from "../models/IncompletedCall";
import Lottie from "lottie-react";
import ringing from "../assets/images/animations/ringing.json";
import Modal22 from "../pages/Popup/Modal22";
import { sendNotificationSocketData } from "../models/sendNotificationCallSocketData";
import { CallInitializeData } from "../models/CallInitializeData";
import ZoomVideo from "@zoom/videosdk";
import ZoomContext from "../pages/Chat/VideoCallNew/context/ZoomContext";
import { createMeeting } from "src/pages/Chat/ZoomNew/utils/util";
import Close from "../assets/images/icons/u_times-circle.png";
import CoverMeeting from "../assets/images/meeting img.gif";
import CallGif from "../assets/images/video-call-gif.gif";
import ShowPopupSheduleContext from "src/context/ShowSchedulePopupContext";
import { AppointmentService } from "src/services/AppointmentService";
import { Util } from "../Util";
import images from "../assets/images";
import FaceIcon from "../assets/images/face.png";
import { RouteName } from "src/RouteName";
import OtpInput from "react-otp-input";
import { useIdleTimer } from "react-idle-timer";
import { VonageServices } from "src/pages/Chat/vonage/vonageServices";
import { v4 as uuidv4 } from "uuid";
import OngoingGroupCallNotification from "../pages/Popup/OngoingGroupCallNotification";
import OngoingCallRefreshModal from "src/pages/Popup/OngoingCallRefreshModal";
import { ClientService } from "src/services/ClientService";
import Modal29 from "src/pages/Popup/Modal29";
import Modal28 from "src/pages/Popup/Modal28";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import ClientChatView from "src/pages/SuperAdmin/ClientChat/ClientChatView";
import { onMessageListner, requestFCMToken, requestNotificationPermission } from "src/utils/firebaseMessageUtils";
import {VonageNativeSdkService} from 'src/services/VonageNativeSdkService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const stripePromise = loadStripe(environment.stripe_publishable_key);

const zmClient = ZoomVideo.createClient();

const SocketConnection2: React.FC<{ children: any }> = ({ children }): any => {
  const mounted = useRef(false);
  const location = useLocation();
  const [socketConnection, setSocketConnection] = useState({} as any);
  const [user, setUser] = useContext(UserContext);
  const audio = useContext(AudioContext);
  const [incomingCall, setIncomingCall] = useState(false);
  const [incomingSocketId, setIncomingSocketId] = useState("");
  const [callId, setCallId] = useState("");
  const [outgoingCall, setOutgoingCall] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [notificationDeatils, setNotificationDeatils, selectedClientsIdForChat, setSelectedClientsIdForChat, selectedClientsDetailsForChat, setSelectedClientsDetailsForChat] = useContext(NotificationContext);
  const [newCall, setNewCall] = useState(false);
  const [newIncomingSocketId, setNewIncomingSocketId] = useState("");
  const [newCallId, setNewCallId] = useState("");
  const [recieversUseDefaultAvatarValue, setRecieversUseDefaultAvatarValue] = useState(false);
  const [meetingMainTherapistId, setMeetingMainTherapistId] = useState("");
  const [recieversAvatarId, setRecieversAvatarId] = useState("");
  const [recieversUserId, setRecieversUserId] = useState("");
  const [recieversUserIdWhenNotInCall, setRecieversUserIdWhenNotInCall] = useState("");
  const [recieverName, setRecieverName] = useState("");
  const [videosdkTokenFromSocket, setVideosdkTokenFromSocket] = useState("");
  const [isVideoCall, setIsVideoCall] = useState(false);
  const [callRecordingAllowed, setCallRecordingAllowed] = useState<boolean>(false);
  const [recieversAvatarBackgroundId, setRecieversAvatarBackgroundId] = useState("");
  const [recieversIncogniteModeOn, setRecieversIncogniteModeOn] = useState(true);
  const [meetingDuration, setMeetingDuration] = useState(30);
  const [offset, setOffset] = useState(1);
  const limit = 100;
  const [localUserOnCall, setLocalUserOnCall] = useState<LocalCall>({
    callId: callId,
    onOngoingCall: false,
    recieversUseDefaultAvatarValue: true,
    recieversAvatarId: "",
    recieversAvatarBackgroundId: "",
    recieversIncogniteModeOn: true,
    meetingMainTherapistId: "",
    isVideoCall: false,
    meetingDuration: 30,
    recieversUserId: "",
    recieversName: "",
    recieverCallRecordingAllowed: false,
    recieversSocketId: "",
    callEndedForBothUsers: false,
    videoSdkToken: "",
    isAudioCall: false,
  });
  const [localCallInitialize, setLocalCallInitialize] = useState(false);

  const [callTimeOut, setCallTimeOut] = useState<TimeOut>({
    isTimeOutRunning: true,
    userOnMeetingScreen: false,
    showExtendPopup: false,
    callAcceptedByReciever: false,
  });
  const [callingTimeOut, setCallingTimeOut] = useState<any>();
  const [extendedCallingTimeOut, setExtendedCallingTimeOut] = useState<any>();
  const [outgoinTimeOut, setOutgoinTimeOut] = useState<any>();
  const history = useHistory();
  const [playing, setPlaying] = useState(false);
  const [rejectAnsweringCall, setRejectAnsweringCall] = useState(false);
  const [sendNoAnswerNotification, setSendNoAnswerNotification] = useState(false);
  const [initialTimeOut, setInitialTimeOut] = useState<any>();
  const [callerName, setCallerName] = useState("");
  const [endCallForBoth, setEndCallForBoth] = useState(false);
  const [callIdForEndCall, setCallIdForEndCall] = useState<string>("");
  const [socketIdForEndCall, setSocketIdForEndCall] = useState<string>("");
  const [senderSocketIdForEndCall, setSenderSocketIdForEndCall] = useState<string>("");
  const [endCall, setEndCall] = useState(false);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [callEndWhenTimeOut, setCallEndWhenTimeOut] = useState(false);
  const [showPopUpAfterCallEndWhenOutgoinTimeOut, setShowPopUpAfterCallEndWhenOutgoinTimeOut] = useState(false);
  const [showExtendPopUp, setShowExtendPopUp] = useState(false);
  const [extendTimeWhenSocketRecieved, setExtendTimeWhenSocketRecieved] = useState(false);
  const [extendTime, setExtendTime] = useState(0);
  const [showCountdownTimer, setShowCountdownTimer] = useState(false);
  const [countDownTime, setCountDownTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const showBeforeTime = 10;
  const extendingTime = 30;
  const endCallAfterNoResponse = 20;
  const [callEndWhenOutgoinTimeOut, setCallEndWhenOutgoinTimeOut] = useState(false);
  const [endCallWhenStatusRecieved, setEndCallWhenStatusRecieved] = useState(false);
  const [bothJoined, setBothJoined] = useState(false);
  const [showPopUpForNoAnswer, setShowPopUpForNoAnswer] = useState(false);
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [nearestAppointment, setNearestAppointment] = useState<NearestAppointment>({
    _id: "",
    therapistId: "",
    start: "",
    end: "",
    typeOfMeeting: "",
    clientId: "",
    status: "",
    noOfCallingTriesByClient: 0,
    noOfCallingTriesByTherapist: 0,
  });
  const [incompletedCall, setIncompletedCall] = useState<IncompletedCall>({
    _id: "",
    meetingId: "",
    meetingDuration: 0,
    clientId: "",
    therapistId: "",
    accepted: false,
    transcribeAllowed: false,
    isAppointmentBased: false,
    appointmentId: "",
    recordingAllowed: false,
    createdBy: "",
    callingStatus: "",
  });
  const [incompletedMeetingId, setIncompletedMeetingId] = useState<string>("");
  const [nearestAppointmentId, setNearestAppointmentId] = useState<string>("");
  const [isInitial, setIsInitial] = useState(true);
  const [showMissedCallPopUpWithNearestAppointment, setShowMissedCallPopUpWithNearestAppointment] = useState(false);
  const [showNearestAppointmentPopUp, setShowNearestAppointmentPopUp] = useState(false);
  const [showIncompletedOwnCreatedMeeting, setShowIncompletedOwnCreatedMeeting] = useState(false);
  const [showIncompletedOtherCreatedMeeting, setShowIncompletedOtherCreatedMeeting] = useState(false);
  const [showTooEarly, setShowTooEarly] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);
  const [changeHideCallTimerStatus, setChangeHideCallTimerStatus] = useState<boolean>(false);
  const [hideCallTimerWhenCall, setHideCallTimerWhenCall] = useState<boolean>(false);
  const [showHideCallTimerPopUp, setShowHideCallTimerPopUp] = useState<boolean>(false);
  const [callingOnActionProgress, setCallingOnActionProgress] = useState(false);
  const [zoomIncomingCall, setZoomIncomingCall] = useState(false);
  const [zoomUrl, setZoomUrl] = useState("");
  // const [allCallEndedModal, setAllCallEndedModal] = useState(true);
  const [therapistIdForSchedule, setTherapistIdForSchedule] = useState("");
  const [clientIdForSchedule, setClientIdForSchedule] = useState("");
  const [showScheduleInTherapistChat, setShowScheduleInTherapistChat] = useState<boolean>(false);
  const [meetingEndedProperly, setMeetingEndedProperly] = useState<boolean>(false);
  const [upcomingAppointments, setUpcomingAppointments] = useState<any>();
  const [pastAppointments, setPastAppointments] = useState<any>();
  const [pendingAppointments, setPendingAppointments] = useState<any>();
  const [nextAppointments, setNextAppointments] = useState<any>();
  const [isAppointmentError, setIsAppointmentError] = useState<boolean>();
  const [isAppointmentLoading, setIsAppointmentLoading] = useState<boolean>();
  const [confirmSession, setConfirmSession] = useState<any>();
  const [meetingEndedProperlyMeetingId, setMeetingEndedProperlyMeetingId] = useState<any>();
  const [appointmentSuccess, setAppointmentSuccess] = useState<boolean>(false);
  const [isOpenPinPopup, setIsOpenPinPopup] = useState<boolean>(false);
  const [pinNumber, setPinNumber] = useState("");
  const [pinTryCount, setPinTryCount] = useState(0);
  const [allowTranscribeForUser, setAllowTranscribeForUser] = useState<boolean>(false);
  const [ongoingGroupCall, setOngoingGroupCall] = useState<any>([]);
  const [showOngoingGroupCallNotification, setShowOngoingGroupCallNotification] = useState<boolean>(false);
  const [showOngoingCallRefreshModal, setShowOngoingCallRefreshModal] = useState(false);
  const [showImcompleteCoPayments, setShowIncompleteCopayments] = useState(false);
  const [showPayImcompleteCoPayments, setShowPayIncompleteCopayments] = useState(false);
  const [coPaymentAmount, setCopaymentAmount] = useState(0);
  const [showMeetingData, setMeetingData] = useState({ startNew: true, userId: "", meetingTime: "" as any, appointmentId: "", meetingId: "", isAppointmentBased: true, allowTranscribe: true || undefined, callType: "", });
  const localUserOnCallRef = useRef(localUserOnCall);

    // Update the ref whenever the state changes
  useEffect(() => {
    localUserOnCallRef.current = localUserOnCall;
  }, [localUserOnCall]);


const handlePermissionChange = useCallback(async (newPermission: string) => {
    console.log("Notification Permission:", newPermission);

    if (newPermission === 'granted') {
      try {
        const token = await requestFCMToken(newPermission);
        
        if (token) {
          console.log("FCM Token:", token);
          const res = await VonageNativeSdkService.updateUserFCMToken(token);
          console.log(res);
          
        }
      } catch (error) {
        console.error("Error updating FCM token:", error);
      }
    } else {
      toast(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <i className="fa fa-bell" aria-hidden="true" style={{ fontSize: '30px', color: 'red' }}></i>
          <p style={{ fontSize: '16px', fontWeight: '500', margin: '0' }}>Notification permission not granted. Unable to send browser notifications!</p>
        </div>,
        {
          position: toast.POSITION.TOP_LEFT,
          className: "foo-bar",
        }
      );
    }
  }, []);

  useEffect(() => {
    const fetchFCMToken = async () => {
      try {
        await requestNotificationPermission(handlePermissionChange);
      } catch (error) {
        console.error("Error requesting notification permission:", error);
      }
    };

    fetchFCMToken();
  }, [handlePermissionChange]);

  const showCustomToastWithLink = (url: string, title: string) => {
    toast(
      <div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '16px',
          }}
        >
          {title}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          <textarea
            readOnly
            value={url}
            style={{
              width: '100%',
              height: '40px',
              overflow: 'hidden',
              resize: 'none',
              border: 'none',
              outline: 'none',
              background: 'transparent',
              color: 'inherit',
              cursor: 'default',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: '8px',
              cursor: 'pointer',
              color: '#28a745',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '8px',
            }}
            onClick={() => navigator.clipboard.writeText(url)}
          >
            <ContentCopyIcon style={{ fontSize: '24px', color: '#28a745' }} />
            <span style={{ fontSize: '12px', marginTop: '4px', color: '#555' }}>Copy</span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <button
            onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
            style={{
              padding: '6px 12px',
              backgroundColor: '#ff8000',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '4px',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Join Session
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <p style={{ marginTop: '12px', color: '#555' }}>
            You can also find this link in your email or text messages..
          </p>
        </div>
        
      </div>,
      {
        autoClose: false,
        closeButton: true,
        draggable: false,
        closeOnClick: false,
      }
    );
  };


  onMessageListner().then((payload: any) => {
    console.log("Recieved foreground message ", payload);
    showCustomToastWithLink(payload.notification.body, payload.notification.title);
  }).catch(error => {
    console.log(error);
    
  })

  const openPinPopup = () => {
    if (user?.role == Role.THERAPIST) {
      setIsOpenPinPopup(true);
    } else {
      logout();
    }
  };
  const { isIdle, reset } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 2,
    onIdle: () => {
      openPinPopup();
      reset();
    },
  });

  useEffect(() => {
    if (user?.callRecordingAllowed == true) {
      setAllowTranscribeForUser(true);
    }
  }, [user]);

  const checkPinNumber = () => {
    const userPin = user?.pinNumber;
    if (userPin) {
      if (pinNumber?.length == 4) {
        setPinTryCount(pinTryCount + 1);
        if (userPin == pinNumber) {
          setPinTryCount(0);
          setPinNumber("");
          setIsOpenPinPopup(false);
          toast.success(`Successfully verified.`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        } else {
          if (pinTryCount > 2) {
            logout();
          }
          toast.error("Incorrect pin number.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Invalid pin number.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } else {
      toast.error("User not created; pin number.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  useEffect(() => {
    if (
      (newCall && incomingCall && !localUserOnCall.onOngoingCall) ||
      (newCall && incomingCall && localUserOnCall.onOngoingCall && localUserOnCall.callId != newCallId)
    ) {
      sendBussyEvent(socketConnection, newIncomingSocketId, newCallId, recieversUserId);
    }

    if (
      (newCall && !incomingCall && !localUserOnCall.onOngoingCall) ||
      (newCall && !incomingCall && localUserOnCall.onOngoingCall && localUserOnCall.callId != newCallId)
    ) {
      setIncomingSocketId(newIncomingSocketId);
      setRecieversUserIdWhenNotInCall(recieversUserId);
      setCallId(newCallId);
      setOutgoingCall(false);
      setIncomingCall(true);
      setPlaying(true);
      setNewCall(false);
      if (initialTimeOut) {
        clearTimeout(initialTimeOut);
      }
      const timeOut = setTimeout(() => {
        setRejectAnsweringCall(true);
      }, 30000);
      setInitialTimeOut(timeOut);
    }
  }, [newCall]);

  useEffect(() => {
    if (
      (rejectAnsweringCall && incomingCall && !localUserOnCall.onOngoingCall) ||
      (rejectAnsweringCall && incomingCall && localUserOnCall.onOngoingCall && localUserOnCall.callId != callId)
    ) {
      rejectAnswering(socketConnection, incomingSocketId, callId, recieversUserIdWhenNotInCall);
    }
    if (!incomingCall) {
      setRejectAnsweringCall(false);
    }
  }, [rejectAnsweringCall]);

  useEffect(() => {
    if (
      (sendNoAnswerNotification && !localUserOnCall.onOngoingCall) ||
      (sendNoAnswerNotification && localUserOnCall.onOngoingCall && localUserOnCall.callId == callIdForEndCall)
    ) {
      if (!bothJoined) {
        sendNoAnswerNotificationFuncton();
      }
    }
  }, [sendNoAnswerNotification]);

  useEffect(() => {
    if (!playing) {
      audio.pause();
    }

    if (playing && incomingCall) {
      audio.play();
    }

    if (!playing || !incomingCall) {
      audio.addEventListener("ended", () => {
        audio.play();
      });
    }
  }, [playing]);

  useEffect(() => {
    if (
      endCallForBoth &&
      callIdForEndCall != "" &&
      socketIdForEndCall != "" &&
      senderSocketIdForEndCall != "" &&
      localUserOnCall.onOngoingCall &&
      callIdForEndCall == localUserOnCall.callId
    ) {
      toast.success(`Call ended by ${localUserOnCall.recieversName}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      setEndCall(true);
    }
    setEndCallForBoth(false);
    setCallIdForEndCall("");
    setSocketIdForEndCall("");
    setSenderSocketIdForEndCall("");
    if (endCallForBoth && !localUserOnCall.onOngoingCall) {
      cancellCallWhenStatusRecived();
    }
  }, [endCallForBoth]);

  const [twilioMessageNotification, setTwilioMessageNotification] = useState<any>();

   useEffect(() => {
    try {
      if(user && 
        user?.role && 
        (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN) && 
        twilioMessageNotification && 
        twilioMessageNotification.content &&
        twilioMessageNotification.variant && 
        twilioMessageNotification.messageSenderId &&
        selectedClientsIdForChat != null &&
        twilioMessageNotification.messageSenderId != selectedClientsIdForChat){
        const message = twilioMessageNotification.content;
        const variant = twilioMessageNotification.variant;
        NotificationService.getNotifications(false, 10, 0).then((res: any) => {
          if (res.success) {
            setNotificationDeatils(res.data);
          }
        });

        enqueueSnackbar(message, {
          TransitionComponent: Grow,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: variant,
        });
      }
    } catch (error) {
        
    }
  }, [twilioMessageNotification]);

  useEffect(() => {
    const socketConnection = io(environment.api_url);

    setSocketConnection(socketConnection);

    socketConnection.on("new-connect", (data: any) => {
      const updatedUser: UserDetails = {
        socketId: data.socket_id,
      };

      AuthService.updateSocketId(updatedUser).then(res => {
        if (res.success) {
          setUser({ ...user, socketId: data.socket_id });
        }
      });
    });

    if (user && user._id) {
      socketConnection.emit("new-user-add", user._id);
    }

    socketConnection.on("end-call-for-both-recieved", (data: any) => {
      const localCall: LocalCall = {
        callId: "",
        onOngoingCall: false,
        recieversUseDefaultAvatarValue: true,
        recieversAvatarId: "",
        recieversAvatarBackgroundId: "",
        recieversIncogniteModeOn: true,
        meetingMainTherapistId: "",
        isVideoCall: false,
        meetingDuration: 30,
        recieversUserId: "",
        recieversName: "",
        recieverCallRecordingAllowed: false,
        recieversSocketId: "",
        callEndedForBothUsers: false,
        videoSdkToken: "",
        isAudioCall: false,
      };
      setLocalUserOnCall(localCall);

      setOutgoingCall(false);
      setIncomingCall(false);
      setPlaying(false);
      setCallIdForEndCall(data.callId);
      setSocketIdForEndCall(data.socketId);
      setSenderSocketIdForEndCall(data.senderSocketId);
      setEndCallForBoth(true);
    });

    socketConnection.on("incoming-call", (data: any) => {
      setRecieversUseDefaultAvatarValue(data.useDefaultAvatar);
      setRecieversAvatarId(data.avatarId);
      setCallRecordingAllowed(data.recieverRcordingAllowed);
      setRecieversUserId(data.recieversUserId);
      setRecieverName(data.callerName);
      setVideosdkTokenFromSocket(data.videoSdkToken);
      setRecieversAvatarBackgroundId(data.avatarBackgroundId);
      setMeetingDuration(data.meetingDuration);
      setIsVideoCall(data.isVideoCall);
      setRecieversIncogniteModeOn(data.incognito);
      setNewIncomingSocketId(data.senderSocketId);
      setMeetingMainTherapistId(data.therapistId);
      setNewCallId(data.callId);
      setCallerName(data.callerName);
      setNewCall(true);
      setLocalCallInitialize(true);
      setShowIncompletedOwnCreatedMeeting(false);
      setShowIncompletedOtherCreatedMeeting(false);
      setShowMissedCallPopUpWithNearestAppointment(false);
      setShowNearestAppointmentPopUp(false);
    });

    const handleIncomingCall = (data: any) => {
      if (localUserOnCallRef.current?.onOngoingCall) {
        setNewIncomingSocketId(data.senderSocketId);
        setNewCallId(data.callId);
        setRecieversUserId(data.recieversUserId);
        sendBussyEvent(socketConnection, data.senderSocketId, data.callId, data.recieversUserId);
        toast.error(`You have a missed call from ${data?.callerName}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar',
        });
      } else {
        setRecieversUseDefaultAvatarValue(data.useDefaultAvatar);
        setRecieversAvatarId(data.avatarId);
        setCallRecordingAllowed(data.recieverRcordingAllowed);
        setRecieversUserId(data.recieversUserId);
        setRecieverName(data.callerName);
        setVideosdkTokenFromSocket(data.videoSdkToken);
        setRecieversAvatarBackgroundId(data.avatarBackgroundId);
        setMeetingDuration(data.meetingDuration);
        setIsVideoCall(data.isVideoCall);
        setRecieversIncogniteModeOn(data.incognito);
        setNewIncomingSocketId(data.senderSocketId);
        setMeetingMainTherapistId(data.therapistId);
        setNewCallId(data.callId);
        setCallerName(data.callerName);
        setNewCall(true);
        setShowIncompletedOwnCreatedMeeting(false);
        setShowIncompletedOtherCreatedMeeting(false);
        setShowMissedCallPopUpWithNearestAppointment(false);
        setShowNearestAppointmentPopUp(false);
      }
    };

    socketConnection.on('vonage-incoming-call', handleIncomingCall);

    socketConnection.on("status-received", (data: any) => {
      setIncomingCall(false);
      setOutgoingCall(false);
      setCallId(data.callId);
      setPlaying(false);
      if (initialTimeOut) {
        clearTimeout(initialTimeOut);
      }

      if (data.status == "Rejected") {
        setCallIdForEndCall(data.callId);
        setEndCallWhenStatusRecieved(true);
        toast.error(`Call rejected by ` + data.senderName, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setRejectAnsweringCall(false);
      } else if (data.status == "NoAnswer") {
        setCallIdForEndCall(data.callId);
        setShowPopUpForNoAnswer(true);
        setSendNoAnswerNotification(true);
        toast.error(`Call not answered by ` + data.senderName, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      } else if (data.status == "Bussy") {
        toast.error(data.senderName + ` is busy right now!`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        setCallIdForEndCall(data.callId);
        VonageServices.busyVonageCall(data.callId).then((res: any) => {
          if(res?.success){
            const dataForLocalCall = {
              callId: callId,
              onOngoingCall: false,
              recieversUseDefaultAvatarValue: true,
              recieversAvatarId: "",
              recieversAvatarBackgroundId: "",
              recieversIncogniteModeOn: true,
              meetingMainTherapistId: "",
              isVideoCall: false,
              meetingDuration: 30,
              recieversUserId: "",
              recieversName: "",
              recieverCallRecordingAllowed: false,
              recieversSocketId: "",
              callEndedForBothUsers: false,
              videoSdkToken: "",
              isAudioCall: false,
            }

            localUserOnCallRef.current = dataForLocalCall;
          }
        });

      }
      setLocalCallInitialize(false);
      window.location.href = "/dashboard";
    });

    socketConnection.on("call-time-extended", (data: any) => {
      setExtendTime(data.extendedTime);
    });

    socketConnection.on("call-canceled", (data: any) => {
      setEndCallWhenCancellStatusRecieved(true);
    });

    socketConnection.on("notification-received", (notifyRes: any) => {
      try {
        const message = notifyRes.data.notifyData.content;
        const variant = notifyRes.data.notifyData.variant;
        if(notifyRes.data.notifyData.event && notifyRes.data.notifyData.event == NotificationEvent.NEW_TWILIO_MESSAGE_FROM_CLIENT){
          setTwilioMessageNotification(notifyRes.data.notifyData);
        }else{
          NotificationService.getNotifications(false, 10, 0).then((res: any) => {
            if (res.success) {
              setNotificationDeatils(res.data);
            }
          });
    
          enqueueSnackbar(message, {
            TransitionComponent: Grow,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: variant,
          });
        }
      } catch (error) {
        
      }
    });

    socketConnection.on("incoming-zoom-call", (data: any) => {
      setZoomIncomingCall(true);
      setZoomUrl(data.zoomUrl);
    });

    socketConnection.on("end-call-for-all-vonage-recieved", (data: any) => {
      const resetDataForCall = {
        callId: "",
        onOngoingCall: false,
        recieversUseDefaultAvatarValue: true,
        recieversAvatarId: "",
        recieversAvatarBackgroundId: "",
        recieversIncogniteModeOn: true,
        meetingMainTherapistId: "",
        isVideoCall: false,
        meetingDuration: 30,
        recieversUserId: "",
        recieversName: "",
        recieverCallRecordingAllowed: false,
        recieversSocketId: "",
        callEndedForBothUsers: false,
        videoSdkToken: "",
        isAudioCall: false,
      };
      setLocalUserOnCall(resetDataForCall);

      const userName = user?.firstname + " " + user?.lastname;
      if (data?.therapistName == userName) {
        toast.success(`Group session has been ended.`);
      } else {
        toast.success(`Group session with ${data?.therapistName} has been ended.`);
      }
    });

    
    return () => {
      socketConnection.off('vonage-incoming-call', handleIncomingCall);
    };
  }, []);

  useEffect(() => {
    if (callEndWhenTimeOut) {
      setPlaying(false);
      setShowCountdownTimer(false);
      setExtendTime(0);
      setCountDownTime({ hours: 0, minutes: 0, seconds: 0 });
      const timeoutData: TimeOut = {
        isTimeOutRunning: false,
        userOnMeetingScreen: callTimeOut.userOnMeetingScreen,
        showExtendPopup: false,
        callAcceptedByReciever: false,
      };

      setCallTimeOut(timeoutData);
      setCallEndWhenTimeOut(false);
    }
  }, [callEndWhenTimeOut]);

  useEffect(() => {
    if (showExtendPopUp) {
      VideoChatService.checkRemainingTime(localUserOnCall.recieversUserId, extendingTime).then(res => {
        if (res.success) {
          if (res.data.isMeetingTimeRemained != null && res.data.isMeetingTimeRemained) {
            const timeoutData: TimeOut = {
              isTimeOutRunning: true,
              userOnMeetingScreen: callTimeOut.userOnMeetingScreen,
              showExtendPopup: true,
              callAcceptedByReciever: false,
            };
            setCallTimeOut(timeoutData);
          }
        }
      });

      setShowExtendPopUp(false);
    }
  }, [showExtendPopUp]);

  useEffect(() => {
    if (extendTime != 0) {
      setExtendTimeWhenSocketRecieved(true);
    }
  }, [extendTime]);

  useEffect(() => {
    if (extendTimeWhenSocketRecieved) {
      const timeoutData: TimeOut = {
        isTimeOutRunning: true,
        userOnMeetingScreen: callTimeOut.userOnMeetingScreen,
        showExtendPopup: true,
        callAcceptedByReciever: false,
      };

      setCallTimeOut(timeoutData);
      setExtendTimeWhenSocketRecieved(false);
      setExtendedCallTimeoutWhenStatusRecieved();
    }
  }, [extendTimeWhenSocketRecieved]);

  useEffect(() => {
    if (countDownTime.hours != 0 || countDownTime.minutes != 0) {
      setShowCountdownTimer(true);
    }
  }, [countDownTime]);

  const [endCallWhenCancellStatusRecieved, setEndCallWhenCancellStatusRecieved] = useState(false);

  useEffect(() => {
    if (endCallWhenCancellStatusRecieved) {
      setEndCallWhenCancellStatusRecieved(false);
      cancellCallWhenStatusRecived();
    }
  }, [endCallWhenCancellStatusRecieved]);

  const cancellCallWhenStatusRecived = () => {
    if (initialTimeOut) {
      clearTimeout(initialTimeOut);
    }

    setPlaying(false);

    setLocalCallInitialize(false);

    toast.error(`Your call has been cancelled!`, {
      position: toast.POSITION.TOP_RIGHT,
      className: "foo-bar",
    });

    setIncomingCall(false);
    setOutgoingCall(false);
    setIncomingSocketId("");
    setRecieversUserIdWhenNotInCall("");
  };

  useEffect(() => {
    if (endCallWhenStatusRecieved && localUserOnCall.onOngoingCall && localUserOnCall.callId == callIdForEndCall) {
      setCallIdForEndCall("");
      setEndCallWhenStatusRecieved(false);
      setEndCall(true);
    }
  }, [endCallWhenStatusRecieved]);

  // useEffect(() => {
  //   if (showPopUpForNoAnswer && localUserOnCall.onOngoingCall && localUserOnCall.callId == callIdForEndCall) {
  //     setShowPopUpForNoAnswer(false);
  //     if (!bothJoined) {
  //       setShowPopUpAfterCallEndWhenOutgoinTimeOut(true);
  //     }
  //   }
  // }, [showPopUpForNoAnswer]);

  useEffect(() => {
    if (user?.role == Role.CLIENT) {
      VonageServices.checkOngoingGroupCallForClient().then((res: any) => {
        setShowOngoingGroupCallNotification(true);
        setOngoingGroupCall(res?.data);
      });
    }
  }, []);

  const closeOngoingGroupCallNotificationFunction = (val: boolean) => {
    setShowOngoingGroupCallNotification(val);
  };

  useEffect(() => {
    if (isInitial && user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN && !location.pathname.includes("lavni-session")) {
      setIsInitial(false);
      VideoChatService.checkForNearestAppointment().then((res: any) => {
        let appointmentIdFromCall;

        if (res.success && res.data && res.data.incompleteCallData && res.data.incompleteCallData.length && res.data.incompleteCallData.length > 0) {
          setIncompletedCall(res.data.incompleteCallData[0]);
          if (res.data.incompleteCallData[0].isAppointmentBased && res.data.incompleteCallData[0].appointmentId) {
            appointmentIdFromCall = res.data.incompleteCallData[0].appointmentId;
          }
        }
        if (
          res.success &&
          res.data &&
          res.data.appointmentData &&
          res.data.appointmentData.length &&
          res.data.appointmentData.length > 0 &&
          appointmentIdFromCall != res.data.appointmentData[0]._id
        ) {
          setNearestAppointment(res.data.appointmentData[0]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (nearestAppointment && nearestAppointment._id && nearestAppointment._id != "" && user?.role != Role.SUPER_ADMIN) {
      setNearestAppointmentId(nearestAppointment._id);
    }
  }, [nearestAppointment]);

  useEffect(() => {
    if (nearestAppointmentId && nearestAppointmentId != "" && user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN) {
      if (user?.role == Role.CLIENT) {
        if (nearestAppointment.noOfCallingTriesByTherapist && nearestAppointment.noOfCallingTriesByTherapist > 0) {
          setShowNearestAppointmentPopUp(true);
        } else {
          setShowNearestAppointmentPopUp(true);
        }
      } else {
        if (nearestAppointment.noOfCallingTriesByClient && nearestAppointment.noOfCallingTriesByClient > 0) {
          setShowNearestAppointmentPopUp(true);
        } else {
          setShowNearestAppointmentPopUp(true);
        }
      }
      setIsInitial(false);
    }
  }, [nearestAppointmentId]);

  useEffect(() => {
    if (incompletedCall && incompletedCall._id && incompletedCall._id != "" && user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN) {
      setIncompletedMeetingId(incompletedCall._id);
    }
  }, [incompletedCall]);

  useEffect(() => {
    if (incompletedCall && incompletedCall._id && incompletedCall._id != "" && user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN) {
      if (incompletedCall.createdBy == user?._id) {
        setShowIncompletedOwnCreatedMeeting(true);
      }
      if (incompletedCall.createdBy != user?._id) {
        setShowIncompletedOtherCreatedMeeting(true);
      }
      setIsInitial(false);
    }
  }, [incompletedMeetingId]);

  useEffect(() => {
    if (localUserOnCall && localUserOnCall.onOngoingCall) {
      setShowNearestAppointmentPopUp(false);
      setShowMissedCallPopUpWithNearestAppointment(false);
    }
  }, [localUserOnCall]);

  useEffect(() => {
    if (user?.role == "CLIENT") {
      if (meetingEndedProperly) {
        getEndedScheduleMeetingData(meetingEndedProperlyMeetingId);
      }
    }
    localStorage.removeItem("meetingIdUrl");
  }, [meetingEndedProperly && showSchedulePopup]);

  const setCallTimeout = (time: number) => {
    setShowCountdownTimer(false);
    const timeoutData: TimeOut = {
      isTimeOutRunning: true,
      userOnMeetingScreen: callTimeOut.userOnMeetingScreen,
      showExtendPopup: false,
      callAcceptedByReciever: true,
    };
    setCallTimeOut(timeoutData);
    if (callingTimeOut) {
      clearTimeout(callingTimeOut);
    }
    if (extendedCallingTimeOut) {
      clearTimeout(extendedCallingTimeOut);
    }
    setShowCountdownTimer(false);
    setExtendTime(0);
    const meetingTime = time * 60 * 1000;
    if (time >= showBeforeTime) {
      const meetingTimeForExtendPopUp = time == showBeforeTime ? 2 * 60 * 1000 : (time - showBeforeTime) * 60 * 1000;
      const extendTimeOut = setTimeout(() => {
        setShowExtendPopUp(true);
      }, meetingTimeForExtendPopUp);
      setExtendedCallingTimeOut(extendTimeOut);
    }
    const callTime = setTimeout(() => {
      setCallEndWhenTimeOut(true);
    }, meetingTime);
    setCallingTimeOut(callTime);
    setCountDownTime({ hours: time >= 60 ? 1 : 0, minutes: time >= 60 ? 0 : time, seconds: 0 });
  };

  const setExtendedCallTimeout = (time: number) => {
    const timeoutData: TimeOut = {
      isTimeOutRunning: true,
      userOnMeetingScreen: callTimeOut.userOnMeetingScreen,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };
    setCallTimeOut(timeoutData);
    if (callingTimeOut) {
      clearTimeout(callingTimeOut);
    }
    if (extendedCallingTimeOut) {
      clearTimeout(extendedCallingTimeOut);
    }
    const meetingTime = (time + showBeforeTime) * 60 * 1000;
    const callTime = setTimeout(() => {
      setCallEndWhenTimeOut(true);
    }, meetingTime);
    setCallingTimeOut(callTime);
    setCountDownTime({ hours: time >= 60 ? 1 : 0, minutes: time >= 60 ? 0 : time + showBeforeTime, seconds: 0 });
    const latestSocketId = localUserOnCall.recieversSocketId;
    const data = {
      status: "Extended",
      socketId: latestSocketId,
      callId: localUserOnCall.callId,
      extendedTime: time,
      receiverId: localUserOnCall.recieversUserId,
      senderId: user?._id,
    };
    socketConnection.emit("extend-call-time", data, () => { });
    VideoChatService.updateMeetingTimeWhenExtend(localUserOnCall.callId, extendingTime);
  };

  const setExtendedCallTimeoutWhenStatusRecieved = () => {
    setShowCountdownTimer(false);
    toast.success(`meeting extended by ` + extendTime + `minutes`, {
      position: toast.POSITION.TOP_RIGHT,
      className: "foo-bar",
    });
    const timeoutData: TimeOut = {
      isTimeOutRunning: true,
      userOnMeetingScreen: callTimeOut.userOnMeetingScreen,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };
    setCallTimeOut(timeoutData);
    if (callingTimeOut) {
      clearTimeout(callingTimeOut);
    }
    if (extendedCallingTimeOut) {
      clearTimeout(extendedCallingTimeOut);
    }
    const meetingTime = (extendTime + showBeforeTime) * 60 * 1000;
    const callTime = setTimeout(() => {
      setCallEndWhenTimeOut(true);
    }, meetingTime);
    setCallingTimeOut(callTime);
    setCountDownTime({ hours: extendTime >= 60 ? 1 : 0, minutes: extendTime >= 60 ? 0 : extendTime + showBeforeTime, seconds: 0 });
  };
  const [acceptCallActionOnProgress, setAcceptCallActionOnProgress] = useState(false);

  const rejectCall = () => {
    if (initialTimeOut) {
      clearTimeout(initialTimeOut);
    }
    setPlaying(false);
    const data = {
      status: "Rejected",
      socketId: incomingSocketId,
      callId: callId,
      senderName: user?.firstname,
      receiverId: recieversUserIdWhenNotInCall,
      senderId: user?._id,
    };
    VideoChatService.cancellCall(callId, false, "");
    socketConnection.emit("respond-call", data, () => {
      setLocalCallInitialize(false);
      toast.error(`Call rejected.`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      setIncomingCall(false);
      setOutgoingCall(false);
      setIncomingSocketId("");
      setRecieversUserIdWhenNotInCall("");
    });
  };

  const rejectAnswering = (socketCon: any, socketId: any, callId: any, recieverId: any) => {
    setPlaying(false);

    // const data = {
    //   status: "NoAnswer",
    //   socketId: socketId,
    //   callId: callId,
    //   myUserId: user?._id,
    //   senderName: user?.firstname,
    //   receiverId: recieverId,
    //   senderId: user?._id,
    // };
    setRejectAnsweringCall(false);
    // if (!localUserOnCall.onOngoingCall || (localUserOnCall.onOngoingCall && localUserOnCall.callId != callId)) {
    //   VideoChatService.cancellCall(callId, false, "");
    //   socketConnection.emit("respond-call", data, () => {
    //     setLocalCallInitialize(false);
    //     setIncomingCall(false);
    //     setOutgoingCall(false);
    //     setIncomingSocketId("");
    //     setRecieversUserIdWhenNotInCall("");
    //   });
    // }
    setLocalCallInitialize(false);
    setIncomingCall(false);
    setOutgoingCall(false);
    setIncomingSocketId("");
    setRecieversUserIdWhenNotInCall("");
  };

  const sendBussyEvent = (socketCon: any, socketId: any, callId: any, recieverId: any) => {
    setNewCall(false);
    const data = {
      status: "Bussy",
      socketId: socketId,
      callId: callId,
      myUserId: user?._id,
      senderName: user?.firstname,
      receiverId: recieverId,
      senderId: user?._id,
    };
    
    socketCon.emit("respond-call", data, () => {
      setLocalCallInitialize(false);
      setNewCall(false);
      setNewCallId("");
      setNewIncomingSocketId("");
      setRecieversUseDefaultAvatarValue(false);
      setRecieversAvatarBackgroundId("");
      setMeetingDuration(30);
      setRecieversAvatarId("");
      setCallRecordingAllowed(false);
      setRecieversUserId("");
      setRecieversUserIdWhenNotInCall("");
      setRecieverName("");
      setVideosdkTokenFromSocket("");
      setRecieversIncogniteModeOn(true);
      setMeetingMainTherapistId("");
      setIsVideoCall(false);
    });
  };

  const sendNoAnswerNotificationFuncton = async () => {
    const previousSocketId = localUserOnCall.recieversSocketId;
    const previousUserId = localUserOnCall.recieversUserId;

    setEndCallWhenStatusRecieved(true);
    setPlaying(false);

    const notificationData: NotificationModel = {
      senderId: user?._id,
      event: NotificationEvent.MISSED_CALL,
      link: "/chat",
      content: "You missed call from" + " " + user?.firstname + " " + user?.lastname,
      variant: NotificationVarient.INFO,
      isVideoCallNotificaton: true,
    };

    const socketData: sendNotificationSocketData = {
      socketId: previousSocketId,
      notifyData: notificationData,
      receiverId: previousUserId,
      senderId: user?._id,
    };

    await VideoChatService.createVideoCallNotification(
      previousUserId,
      NotificationEvent.MISSED_CALL,
      "/chat",
      "You missed call from" + " " + user?.firstname + " " + user?.lastname,
      NotificationVarient.INFO
    );

    setSendNoAnswerNotification(false);
    socketConnection.emit("send-notification", socketData);
  };

  function setDataWhenCallEnds(valueForCallInitialize: boolean, localCall: LocalCall, endCallForBoth: boolean, startOutGoingTimeout: boolean) {
    try {
      setIncomingCall(false);
      setOutgoingCall(false);
      setPlaying(false);
      setEndCall(false);
      let callId;
      let recieverSocketId;
      let recieverUserId;
      let mySocketId;
      let previousUserId;
      if (endCallForBoth) {
        callId = localUserOnCall.callId;
        recieverSocketId = localUserOnCall.recieversSocketId;
        recieverUserId = localUserOnCall.recieversUserId;
        previousUserId = localUserOnCall.recieversUserId;
        mySocketId = socketConnection.id;
      }

      if (!startOutGoingTimeout && outgoinTimeOut) {
        clearTimeout(outgoinTimeOut);
      }
      if (callingTimeOut) {
        clearTimeout(callingTimeOut);
      }
      if (extendedCallingTimeOut) {
        clearTimeout(extendedCallingTimeOut);
      }
      setShowCountdownTimer(false);
      setCountDownTime({ hours: 0, minutes: 0, seconds: 0 });
      setExtendTime(0);
      const timeoutData: TimeOut = {
        isTimeOutRunning: true,
        userOnMeetingScreen: false,
        showExtendPopup: false,
        callAcceptedByReciever: false,
      };
      setCallTimeOut(timeoutData);

      if (!valueForCallInitialize) {
        setIncomingCall(false);
        setOutgoingCall(false);
        setCallId("");
        setPlaying(false);
      }
      setLocalUserOnCall(localCall);
      setLocalCallInitialize(valueForCallInitialize);
      // with link
      if (startOutGoingTimeout) {
        setOutGoinCallTimeOutFunctionFromEndCall();
      }
      if (valueForCallInitialize) {
        const timeoutData: TimeOut = {
          isTimeOutRunning: true,
          userOnMeetingScreen: false,
          showExtendPopup: false,
          callAcceptedByReciever: true,
        };
        setCallTimeOut(timeoutData);
      }
      // end call socket emit
      if (endCallForBoth) {
        const data = {
          socketId: recieverSocketId,
          senderSocketId: mySocketId,
          callId: callId,
          receiverId: recieverUserId,
          senderId: user?._id,
        };

        socketConnection.emit("end-call-for-both", data);
      }
      //modal
      // if (!valueForCallInitialize ) {
      //   setAllCallEndedModal(true)
      // if (!startOutGoingTimeout){
      //   setAllCallEndedModal(true)
      // }else {
      //   setAllCallEndedModal(false)
      // }
      // }
    } catch (error) {
      console.log(error);
    }
  }

  function setLocalCallInitializedFunction(value: boolean) {
    setLocalCallInitialize(value);
  }

  function setTimeOutDataFunction(data: TimeOut) {
    setCallTimeOut(data);
  }

  function setExtendedTimeOutDataFunction(time: number) {
    setShowCountdownTimer(false);
    setExtendedCallTimeout(time);
  }

  function cancellCallAfterCallSend(valueForCallInitialize: boolean, localCall: LocalCall) {
    setOutgoingCall(false);
    setPlaying(false);
    setEndCall(false);
    const previousSocketId = localUserOnCall.recieversSocketId;
    const previousUserId = localUserOnCall.recieversUserId;
    if (outgoinTimeOut) {
      clearTimeout(outgoinTimeOut);
    }
    if (callingTimeOut) {
      clearTimeout(callingTimeOut);
    }
    if (extendedCallingTimeOut) {
      clearTimeout(extendedCallingTimeOut);
    }
    setShowCountdownTimer(false);
    setCountDownTime({ hours: 0, minutes: 0, seconds: 0 });
    setExtendTime(0);
    const timeoutData: TimeOut = {
      isTimeOutRunning: true,
      userOnMeetingScreen: false,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };
    setCallTimeOut(timeoutData);

    if (!valueForCallInitialize) {
      setIncomingCall(false);
      setOutgoingCall(false);
      setCallId("");
      setPlaying(false);
    }
    setLocalUserOnCall(localCall);
    setLocalCallInitialize(valueForCallInitialize);

    // send socket
    setPlaying(false);
    const data = {
      socketId: previousSocketId,
      callId: callId,
      receiverId: previousUserId,
      senderId: user?._id,
    };
    socketConnection.emit("cancel-call", data, () => {
      setLocalCallInitialize(false);

      toast.error(`Your call has been cancelled!`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });

      setIncomingCall(false);
      setOutgoingCall(false);
      setIncomingSocketId("");
      setRecieversUserIdWhenNotInCall("");
    });
  }

  function setOutGoinCallTimeOutFunction() {
    // console.log("setOutGoinCallTimeOutFunction");
    // const timeOutTime = 15 * 1000;
    // if (outgoinTimeOut) {
    //   console.log(4);
    //   clearTimeout(outgoinTimeOut);
    // }
    // const timeOut = setTimeout(() => {
    //   console.log("setCallEndWhenOutgoinTimeOut");
    //   setCallEndWhenOutgoinTimeOut(true);
    // }, timeOutTime);
    // console.log("setOutGoinCallTimeOutFunction2");
    // setOutgoinTimeOut(timeOut);
  }
  function setOutGoinCallTimeOutFunctionFromEndCall() {
    const timeOutTime = endCallAfterNoResponse * 60 * 1000;
    if (outgoinTimeOut) {
      clearTimeout(outgoinTimeOut);
    }
    const timeOut = setTimeout(() => {
      setCallEndWhenOutgoinTimeOut(true);
    }, timeOutTime);

    setBothJoined(false);
    setOutgoinTimeOut(timeOut);
  }

  function setNearestAppointmentFunction(res: any) {
    let appointmentIdFromCall;

    if (res.success && res.data && res.data.incompleteCallData && res.data.incompleteCallData.length && res.data.incompleteCallData.length > 0) {
      setIncompletedCall(res.data.incompleteCallData[0]);
      if (res.data.incompleteCallData[0].isAppointmentBased && res.data.incompleteCallData[0].appointmentId) {
        appointmentIdFromCall = res.data.incompleteCallData[0].appointmentId;
      }
    }

    if (
      res.success &&
      res.data &&
      res.data.appointmentData &&
      res.data.appointmentData.length &&
      res.data.appointmentData.length > 0 &&
      appointmentIdFromCall != res.data.appointmentData[0]._id
    ) {
      setNearestAppointment(res.data.appointmentData[0]);
    }
  }

  function setEndCallFunction(value: boolean) {
    setIncomingCall(false);
    setOutgoingCall(false);
    setPlaying(false);
    setEndCall(value);
  }
  function setShowSchedulePopupFunction(value: boolean) {
    setShowSchedulePopup(value);
  }
  function setTherapistIdForScheduleFunction(value: string) {
    setTherapistIdForSchedule(value);
  }
  function setClientIdForScheduleFunction(value: string) {
    setClientIdForSchedule(value);
  }

  const startMeetingNew = async (
    userId: any,
    isVideoCall: boolean,
    appointmentId: string | undefined,
    startTime: Date,
    endTime: Date,
    status?: string,
    approvedStatus?: string,
    allowTranscribe?: boolean
  ) => {
    if (user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN) {
      if (userId == "") {
        alert("No user id is provided.");
      } else if (localCallInitialize) {
        setShowOngoingCallRefreshModal(true);
      } else {
        const now = moment(new Date());
        const timeDifferenceWithNow = moment.duration(moment(startTime).diff(now));
        const timeDifferenceWithNowAsMinutes = timeDifferenceWithNow.asMinutes();

        const timeDifferenceWithStartAndEnd = moment.duration(moment(endTime).diff(moment(startTime)));
        const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();

        if (!(status == AppointmentStatus.PENDING || status == AppointmentStatus.WAITING_FOR_APPROVAL)) {
          setShowAlreadyCompleted(true);
        } else if (timeDifferenceWithNowAsMinutes > 31) {
          if (user?.role == "CLIENT") {
            const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(userId);
            if (meetingRes.success) {
              let totalAmount = 0;
              for (const meeting of meetingRes.data) {
                const amount = parseFloat(meeting?.copayment?.amount) || 0;
                totalAmount += amount;
              }
              if (totalAmount > 0) {
                setCopaymentAmount(totalAmount)
                setShowPayIncompleteCopayments(true)
                setIncomingCall(false)
              } else {
                setShowTooEarly(true);
              }
            }
          } else {
            const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
            // pingCallNew(userId, isVideoCall, appointmentId, finalMeetingTime);
            startZoomCall(true, userId, finalMeetingTime, appointmentId, "", allowTranscribe);
          }
        } else if (timeDifferenceWithNowAsMinutes < -1441) {
          setShowExpired(true);
        } else {
          const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);
          // pingCallNew(userId, isVideoCall, appointmentId, finalMeetingTime);
          startZoomCall(true, userId, finalMeetingTime, appointmentId, "", allowTranscribe);
        }
      }
    }
  };

  const startZoomCall = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string, allowTranscribe: boolean | undefined) => {
    if (user?.role == "CLIENT") {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setShowPayIncompleteCopayments(true)
          setIncomingCall(false)
          setClientIdForSchedule(userId)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, allowTranscribe: allowTranscribe as any, callType: "START" })
        } else {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek. success){
            if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: appointmentId,
                recieverId: userId,
                meetingTime: meetingTime,
                isTranscribeAllowed: true,
                meetingId: meetingId,
                isAudioCall: false,
                transcribeAllowedForLoggedUser: allowTranscribe,
              };
              // setLocalCallInitialize(true);
              setCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }
        }
      }
    } else {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(userId);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setClientIdForSchedule(userId)
          setShowIncompleteCopayments(true)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, allowTranscribe: allowTranscribe as any, callType: "START" })
          const meetingRes: any = await ClientService.sendSMSAndEmailForClient(userId, totalAmount);
        } else {
          const callInitializeData: CallInitializeData = {
            start: startNew,
            isInstantMeeting: false,
            isAppointmentBased: true,
            appointmentId: appointmentId,
            recieverId: userId,
            meetingTime: meetingTime,
            isTranscribeAllowed: true,
            meetingId: meetingId,
            isAudioCall: false,
            transcribeAllowedForLoggedUser: allowTranscribe,
          };
          // setLocalCallInitialize(true);
          setCallInitializeData(callInitializeData);
          setOutGoinCallTimeOutFunction();
          const roomNamePartOne = user?._id;
          const roomNamePartTwo = userId;
          const uniqueId = uuidv4();
          const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
          const vonageUrl = `/room/${roomName}`;
          history.push(vonageUrl);
          // history.push("/meeting_screen");
        }
      }
    }
  };


  function hideCallTimerPopUpSet() {
    setShowHideCallTimerPopUp(true);
  }

  function setShowScheduleInTherapistChatFunction(value: boolean) {
    setShowScheduleInTherapistChat(value);
  }
  function setMeetingEndedProperlyFunction(value: boolean) {
    setMeetingEndedProperly(value);
  }
  function setUpcomingAppointmentsFunction(value: any) {
    setUpcomingAppointments(value);
  }

  function hideCallTimer(hide: boolean, hidePermanently: boolean) {
    setShowHideCallTimerPopUp(false);
    if (hide) {
      setHideCallTimerWhenCall(true);
      if (hidePermanently) {
        if (changeHideCallTimerStatus) {
          toast.success("Already changing", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          setChangeHideCallTimerStatus(true);
          setUser({ ...user, hideCallTimer: true });
          CommonService.updateHideCallTimer(true).then(res => {
            if (res.success) {
              if (mounted) {
                setChangeHideCallTimerStatus(false);
              }
            } else {
              setUser({ ...user, hideCallTimer: false });
              if (mounted) {
                setChangeHideCallTimerStatus(false);
              }
            }
          });
        }
      }
    }
  }

  const cancellCall = (meetingId: string | undefined, isAppointment: boolean, sendSocket: boolean, recieverUserId: string | undefined) => {
    setCallingOnActionProgress(true);
    VideoChatService.cancellCall(meetingId, isAppointment, meetingId).then(res => {
      if (res.success) {
        if (isAppointment) {
          setShowNearestAppointmentPopUp(false);
          setShowMissedCallPopUpWithNearestAppointment(false);
        } else {
          setShowIncompletedOwnCreatedMeeting(false);
          setShowIncompletedOtherCreatedMeeting(false);
        }
        setCallingOnActionProgress(false);
        if (sendSocket) {
          const data = {
            status: "Rejected",
            socketId: "",
            callId: meetingId,
            senderName: user?.firstname,
            receiverId: recieverUserId,
            senderId: user?._id,
          };
          socketConnection.emit("respond-call", data, () => { });
        }
      } else {
        setCallingOnActionProgress(false);
        toast.error("Server error occured , Please try again", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const sendCalcellCallStatusWhenCallends = () => {
    if (outgoinTimeOut) {
      clearTimeout(outgoinTimeOut);
    }

    setCallTimeout(localUserOnCall.meetingDuration);

    VideoChatService.acceptCallWithMeetingLink(localUserOnCall.callId);
  };

  const startTimerWhenBothJoinedFunction = () => {
    setBothJoined(true);
    setHideCallTimerWhenCall(false);
    if (outgoinTimeOut) {
      clearTimeout(outgoinTimeOut);
    }
    setCallTimeout(localUserOnCall.meetingDuration);
    setMeetingEndedProperly(true);
    setShowSchedulePopup(false);
    setMeetingEndedProperlyMeetingId(localUserOnCall.callId);
  };

  const resetTimerWhenSecondTimeJoined = () => {
    if (outgoinTimeOut) {
      clearTimeout(outgoinTimeOut);
    }
  };

  const [callInitializeData, setCallInitializeData] = useState<CallInitializeData>({
    start: false,
    isInstantMeeting: false,
    isAppointmentBased: false,
    isTranscribeAllowed: false,
    appointmentId: "",
    recieverId: "",
    meetingTime: 30,
    meetingId: "",
    isAudioCall: false,
  });

  function setCallInitializeDataFunction(initializeData: CallInitializeData) {
    setCallInitializeData(initializeData);
  }

  const joinZoomCall = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string, isAppointmentBased: boolean, allowTranscribe: boolean) => {
    if (user?.role == "CLIENT") {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setShowPayIncompleteCopayments(true)
          setIncomingCall(false)
          setClientIdForSchedule(user?._id as any)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: user?._id as any, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, allowTranscribe: allowTranscribe as any, callType: "JOIN" })
        } else {
          if (!acceptCallActionOnProgress) {
            setPlaying(false);
            setIncomingCall(false);
            setOutgoingCall(false);
            setIncomingSocketId("");
            setRecieversUserIdWhenNotInCall("");
            setShowIncompletedOwnCreatedMeeting(false);
            setShowIncompletedOtherCreatedMeeting(false);
            setShowMissedCallPopUpWithNearestAppointment(false);
            setShowNearestAppointmentPopUp(false);
            const meetingRes: any = await VonageServices.getMeetingByMeetingId(meetingId);
            if (meetingRes.success) {
              const vonageSessionName = meetingRes.data.vonageSessionName;
              const callInitializeData: CallInitializeData = {
                start: startNew,
                isInstantMeeting: false,
                isAppointmentBased: isAppointmentBased,
                appointmentId: appointmentId,
                recieverId: user?._id as any,
                meetingTime: meetingTime,
                isTranscribeAllowed: true,
                meetingId: meetingId,
                isAudioCall: meetingRes.data.isAudioCall,
                transcribeAllowedForLoggedUser: allowTranscribe,
              };
              // setLocalCallInitialize(true);
              setCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const vonageUrl = `/room/${vonageSessionName}`;
              history.push(vonageUrl);
            }
            // history.push("/meeting_screen");
          } else {
            toast.error(`Already On Progress`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        }
      } else {
        toast.error(`Already On Progress`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    } else {
      if (!acceptCallActionOnProgress) {
        setPlaying(false);
        setIncomingCall(false);
        setOutgoingCall(false);
        setIncomingSocketId("");
        setRecieversUserIdWhenNotInCall("");
        setShowIncompletedOwnCreatedMeeting(false);
        setShowIncompletedOtherCreatedMeeting(false);
        setShowMissedCallPopUpWithNearestAppointment(false);
        setShowNearestAppointmentPopUp(false);
        const meetingRes: any = await VonageServices.getMeetingByMeetingId(meetingId);
        if (meetingRes.success) {
          const vonageSessionName = meetingRes.data.vonageSessionName;
          const callInitializeData: CallInitializeData = {
            start: startNew,
            isInstantMeeting: false,
            isAppointmentBased: isAppointmentBased,
            appointmentId: appointmentId,
            recieverId: userId,
            meetingTime: meetingTime,
            isTranscribeAllowed: true,
            meetingId: meetingId,
            isAudioCall: meetingRes.data.isAudioCall,
            transcribeAllowedForLoggedUser: allowTranscribe,
          };
          // setLocalCallInitialize(true);
          setCallInitializeData(callInitializeData);
          setOutGoinCallTimeOutFunction();
          const vonageUrl = `/room/${vonageSessionName}`;
          history.push(vonageUrl);
        }
        // history.push("/meeting_screen");
      } else {
        toast.error(`Already On Progress`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };
  const setPaidCoAmount = async (paidAmount: any) => {
    console.log("paidAmount", paidAmount)
    if (paidAmount > 0) {
      await ClientService.payAllPendingCopaymentAmounts().then(async res => {
        console.log("res", res)
        if (res.success) {
          setShowPayIncompleteCopayments(false)
          setIncomingCall(false)
          if (user?.role == "CLIENT") {
            if (showMeetingData?.callType == "START") {
              const todayDate = moment().format('YYYY-MM-DD');
              const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(showMeetingData?.userId, todayDate, user._id)
              if (checkClientHasCompletedSessionsOnThisWeek. success){
                if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
                  toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                      position: toast.POSITION.TOP_RIGHT,
                      className: "foo-bar",
                  });
                } else {
                  const callInitializeData: CallInitializeData = {
                    start: showMeetingData?.startNew,
                    isInstantMeeting: false,
                    isAppointmentBased: true,
                    appointmentId: showMeetingData?.appointmentId,
                    recieverId: showMeetingData?.userId,
                    meetingTime: showMeetingData?.meetingTime,
                    isTranscribeAllowed: true,
                    meetingId: showMeetingData?.meetingId,
                    isAudioCall: false,
                    transcribeAllowedForLoggedUser: showMeetingData?.allowTranscribe,
                  };
                  // setLocalCallInitialize(true);
                  setCallInitializeData(callInitializeData);
                  setOutGoinCallTimeOutFunction();
                  const roomNamePartOne = user?._id;
                  const roomNamePartTwo = clientIdForSchedule;
                  const uniqueId = uuidv4();
                  const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                  const vonageUrl = `/room/${roomName}`;
                  history.push(vonageUrl);
                }
              }
              
            }
            if (showMeetingData?.callType == "JOIN") {
              const meetingRes: any = await VonageServices.getMeetingByMeetingId(showMeetingData?.meetingId);
              if (meetingRes.success) {
                const vonageSessionName = meetingRes.data.vonageSessionName;
                const callInitializeData: CallInitializeData = {
                  start: showMeetingData?.startNew,
                  isInstantMeeting: false,
                  isAppointmentBased: true,
                  appointmentId: showMeetingData?.appointmentId,
                  recieverId: showMeetingData?.userId,
                  meetingTime: showMeetingData?.meetingTime,
                  isTranscribeAllowed: true,
                  meetingId: showMeetingData?.meetingId,
                  isAudioCall: false,
                  transcribeAllowedForLoggedUser: showMeetingData?.allowTranscribe,
                };
                // setLocalCallInitialize(true);
                setCallInitializeData(callInitializeData);
                setOutGoinCallTimeOutFunction();
                const vonageUrl = `/room/${vonageSessionName}`;
                history.push(vonageUrl);
              }
            }
          } else {
            toast.error(`Already On Progress`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something is wrong with your card details. Please check your card details or add new card & try again.",
            showCancelButton: true,
            confirmButtonText: "Navigate to payment option page1",
            confirmButtonColor: "#FD7F00",
            cancelButtonColor: "#d33",
          }).then(result => {
            if (result.isConfirmed) {
              history.push(`/payment-history/2`);
            }
          });
        }
      })
    }
  };


  const setAfterPaidCoAmount = async (paidAmount: any) => {
    if (paidAmount > 0) {
          setShowPayIncompleteCopayments(false)
          setIncomingCall(false)
          if (user?.role == "CLIENT") {
            if (showMeetingData?.callType == "START") {
              const todayDate = moment().format('YYYY-MM-DD');
              const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(showMeetingData?.userId, todayDate, user._id)
              if (checkClientHasCompletedSessionsOnThisWeek. success){
                if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
                  toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                      position: toast.POSITION.TOP_RIGHT,
                      className: "foo-bar",
                  });
                } else {
                  const callInitializeData: CallInitializeData = {
                    start: showMeetingData?.startNew,
                    isInstantMeeting: false,
                    isAppointmentBased: true,
                    appointmentId: showMeetingData?.appointmentId,
                    recieverId: showMeetingData?.userId,
                    meetingTime: showMeetingData?.meetingTime,
                    isTranscribeAllowed: true,
                    meetingId: showMeetingData?.meetingId,
                    isAudioCall: false,
                    transcribeAllowedForLoggedUser: showMeetingData?.allowTranscribe,
                  };
                  // setLocalCallInitialize(true);
                  setCallInitializeData(callInitializeData);
                  setOutGoinCallTimeOutFunction();
                  const roomNamePartOne = user?._id;
                  const roomNamePartTwo = clientIdForSchedule;
                  const uniqueId = uuidv4();
                  const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
                  const vonageUrl = `/room/${roomName}`;
                  history.push(vonageUrl);
                }
              }
            }
            if (showMeetingData?.callType == "JOIN") {
              const meetingRes: any = await VonageServices.getMeetingByMeetingId(showMeetingData?.meetingId);
              if (meetingRes.success) {
                const vonageSessionName = meetingRes.data.vonageSessionName;
                const callInitializeData: CallInitializeData = {
                  start: showMeetingData?.startNew,
                  isInstantMeeting: false,
                  isAppointmentBased: true,
                  appointmentId: showMeetingData?.appointmentId,
                  recieverId: showMeetingData?.userId,
                  meetingTime: showMeetingData?.meetingTime,
                  isTranscribeAllowed: true,
                  meetingId: showMeetingData?.meetingId,
                  isAudioCall: false,
                  transcribeAllowedForLoggedUser: showMeetingData?.allowTranscribe,
                };
                // setLocalCallInitialize(true);
                setCallInitializeData(callInitializeData);
                setOutGoinCallTimeOutFunction();
                const vonageUrl = `/room/${vonageSessionName}`;
                history.push(vonageUrl);
              }
            }
          } else {
            toast.error(`Already On Progress`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
    }
  };

  const setJoinCall = () => {
    setShowIncompleteCopayments(false)
    startZoomCallCopayment(true, showMeetingData?.userId, showMeetingData?.meetingTime, showMeetingData?.appointmentId, "", showMeetingData?.allowTranscribe);
  }
  const startZoomCallCopayment = async (startNew: boolean, userId: any, meetingTime: any, appointmentId: any, meetingId: string, allowTranscribe: boolean | undefined) => {
    if (user?.role == "CLIENT") {
      const meetingRes: any = await ClientService.getAllPendingCopaymentAmounts(user?._id);
      if (meetingRes.success) {
        let totalAmount = 0;
        for (const meeting of meetingRes.data) {
          const amount = parseFloat(meeting?.copayment?.amount) || 0;
          totalAmount += amount;
        }
        if (totalAmount > 0) {
          setCopaymentAmount(totalAmount)
          setShowPayIncompleteCopayments(true)
          setIncomingCall(false)
          setClientIdForSchedule(userId)
          setMeetingData({ ...showMeetingData, startNew: startNew, userId: userId, meetingTime: meetingTime, appointmentId: appointmentId, meetingId: meetingId, allowTranscribe: allowTranscribe as any, callType: "START" })
        } else {
          const todayDate = moment().format('YYYY-MM-DD');
          const checkClientHasCompletedSessionsOnThisWeek = await ClientService.checkClientHasCompletedSessionsOnThisWeek(userId, todayDate, user._id)
          if (checkClientHasCompletedSessionsOnThisWeek. success){
            if (checkClientHasCompletedSessionsOnThisWeek.data.isCompletedThisWeekMeeting){
              toast.error(`We are restricting the calls because you have completed your sessions for this week. But your therapist can still initiate a call from their end`, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "foo-bar",
              });
            } else {
              const callInitializeData: CallInitializeData = {
                start: startNew,
                isInstantMeeting: false,
                isAppointmentBased: true,
                appointmentId: appointmentId,
                recieverId: userId,
                meetingTime: meetingTime,
                isTranscribeAllowed: true,
                meetingId: meetingId,
                isAudioCall: false,
                transcribeAllowedForLoggedUser: allowTranscribe,
              };
              // setLocalCallInitialize(true);
              setCallInitializeData(callInitializeData);
              setOutGoinCallTimeOutFunction();
              const roomNamePartOne = user?._id;
              const roomNamePartTwo = userId;
              const uniqueId = uuidv4();
              const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
              const vonageUrl = `/room/${roomName}`;
              history.push(vonageUrl);
            }
          }
        }
      }
    } else {
      const callInitializeData: CallInitializeData = {
        start: startNew,
        isInstantMeeting: false,
        isAppointmentBased: true,
        appointmentId: appointmentId,
        recieverId: userId,
        meetingTime: meetingTime,
        isTranscribeAllowed: true,
        meetingId: meetingId,
        isAudioCall: false,
        transcribeAllowedForLoggedUser: allowTranscribe,
      };
      // setLocalCallInitialize(true);
      setCallInitializeData(callInitializeData);
      setOutGoinCallTimeOutFunction();
      const roomNamePartOne = user?._id;
      const roomNamePartTwo = userId;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room/${roomName}`;
      history.push(vonageUrl);
      // history.push("/meeting_screen");
    }
  };


  const hideTimerOnly = () => {
    console.log("Hide Timer---");
    // setShowCountdownTimer(false);
  };

  const handleClickedShedule = () => {
    if (user?.role == "THERAPIST") {
      // setShowSchedulePopup(false)
      // setShowScheduleInTherapistChatFunction(true)
    } else if (user?.role == "CLIENT") {
      setShowSchedulePopup(false);
      setMeetingEndedProperly(false);
      setAppointmentSuccess(false);
      history.push(`/create-appointment/${therapistIdForSchedule}`);
    }
  };
  const autoCreateAppointment = (upcommingObj: any) => {
    const repeatInfo = {
      repeatType: upcommingObj?.repeatInfo?.repeatType,
      interval: upcommingObj?.repeatInfo?.interval,
      repeatDays: upcommingObj?.repeatInfo?.repeatDays,
      endingDate: new Date(new Date(upcommingObj?.repeatInfo?.endingDate).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      endingAfter: upcommingObj?.repeatInfo?.endingAfter,
      endingType: upcommingObj?.repeatInfo?.endingType,
    };
    const appointmentObj = {
      clientId: upcommingObj?.clientId?._id,
      therapistId: upcommingObj?.therapistId?._id,
      start: new Date(new Date(upcommingObj?.start).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      end: new Date(new Date(upcommingObj?.end).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      reminders: upcommingObj?.reminders,
      title: upcommingObj?.title,
      repeatInfo: repeatInfo,
      color: upcommingObj?.color,
      groupId: upcommingObj?.groupId,
      eventOnHolidays: false,
    };
    AppointmentService.createAppointmentByClient(appointmentObj).then((res: any) => {
      if (res.success) {
        setNextAppointments([res?.data]);
        setAppointmentSuccess(true);
        setConfirmSession(res?.data);
      } else {
        console.log("res error", res?.error);
      }
    });
  };
  const handleClickedConfirmSchedule = () => {
    updateScheduleApprovalStatus(confirmSession?._id, ApprovalStatus.APPROVED);
  };
  const updateScheduleApprovalStatus = (appointmentId: string | undefined, approvedStatus: string) => {
    const data = {
      appointmentId: appointmentId,
      approvedStatus: approvedStatus,
      status: ApprovalStatus.PENDING,
    };

    AppointmentService.updateApprovalStatusAutoCreatedAppointment(data).then((res: any) => {
      if (res) {
        setShowSchedulePopupFunction(false);
        setMeetingEndedProperlyFunction(false);
        setAppointmentSuccess(false);
        if (res.success) {
          return toast.success("Appointment confirmed successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          return toast.error("Appointment confirm Failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    });
  };
  const getEndedScheduleMeetingData = (meetingId: any) => {
    setAppointmentSuccess(false);
    setNextAppointments(null);
    const data = {
      meetingId: meetingId,
    };
    AppointmentService.getAppointmentMeetingEndSessionData(data).then((res: any) => {
      if (res) {
        const endAt: moment.Moment = moment();
        if (res.success) {
          const bothJoined: moment.Moment = moment(res?.data?.bothJoinedAt);
          const isAppointmentBased = res?.data?.isAppointmentBased;
          const sessionTime = res?.data?.meetingDuration;

          if (endAt && bothJoined && isAppointmentBased) {
            const minutesDiff: number = endAt.diff(bothJoined, "minutes");
            const successPercantage = (minutesDiff / sessionTime) * 100;
            if (successPercantage > 79) {
              getAllAppointmentDetails();
            }
          }
        } else {
          console.log("res==>", res);
        }
      }
    });
  };
  const getAllAppointmentDetails = () => {
    AppointmentService.viewAllAppointmentsByUser("all", limit, offset).then(res => {
      if (res.success) {
        const upcoming = [] as Appointment[];
        const past = [] as Appointment[];
        const pending = [] as Appointment[];
        const rejected = [] as Appointment[];

        res.data.map((appointment: Appointment) => {
          if (appointment.status == AppointmentStatus.REJECTED) {
            rejected.push(appointment);
          } else if (appointment.status == AppointmentStatus.WAITING_FOR_APPROVAL) {
            pending.push(appointment);
          } else if (new Date(appointment.end) > new Date() && appointment.status == AppointmentStatus.PENDING) {
            upcoming.push(appointment);
          } else {
            past.push(appointment);
          }
        });
        setPendingAppointments(pending);
        setUpcomingAppointments(upcoming);
        setPastAppointments(past);
        if (upcoming?.length > 0) {
          setNextAppointments(upcoming);
          setConfirmSession(upcoming[0]);
          setAppointmentSuccess(true);
        } else {
          autoCreateAppointment(past[0]);
        }

        setIsAppointmentLoading(false);
        setIsAppointmentError(false);
      } else {
        setTimeout(() => {
          setIsAppointmentLoading(false);
        }, 500);

        setIsAppointmentError(true);
      }
    });
  };

  const setShowOngoingCallRefreshModalFunc = (showModal: boolean) => {
    setShowOngoingCallRefreshModal(showModal);
  };

  const [isOpenClientChat, setIsOpenClientChat] = useState(false);

  const toggleClientChat = () => {
    setSelectedClientsIdForChat("");
    setSelectedClientsDetailsForChat("");
  };

  useEffect(() => {
    try {
      if(user && user?.role && (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN)){
        if(selectedClientsIdForChat != null){
          if(selectedClientsIdForChat != ""){
            setIsOpenClientChat(true);
          }else{
            setIsOpenClientChat(false);
          }
        }
      }
    } catch (error) {
        
    }
  }, [selectedClientsIdForChat]);


  return (
    <SocketContext.Provider value={socketConnection}>
      <ZoomContext.Provider value={zmClient}>
        <CallEndContext.Provider value={[endCall, setEndCallFunction]}>
          <ShowPopupSheduleContext.Provider
            value={[
              showSchedulePopup,
              setShowSchedulePopupFunction,
              therapistIdForSchedule,
              setTherapistIdForScheduleFunction,
              clientIdForSchedule,
              setClientIdForScheduleFunction,
              showScheduleInTherapistChat,
              setShowScheduleInTherapistChatFunction,
              meetingEndedProperly,
              setMeetingEndedProperlyFunction,
              upcomingAppointments,
              setUpcomingAppointmentsFunction,
            ]}
          >
            <LocalCallTimeOutContext.Provider value={[callTimeOut, setTimeOutDataFunction, setExtendedTimeOutDataFunction]}>
              <LocalCallContext.Provider
                value={[
                  localUserOnCall,
                  setDataWhenCallEnds,
                  cancellCallAfterCallSend,
                  setOutGoinCallTimeOutFunction,
                  setNearestAppointmentFunction,
                  startTimerWhenBothJoinedFunction,
                  resetTimerWhenSecondTimeJoined,
                  hideTimerOnly,
                ]}
              >
                <CallInitializationContext.Provider
                  value={[localCallInitialize, setLocalCallInitializedFunction, callInitializeData, setCallInitializeDataFunction]}
                >
                  <div>
                    {showOngoingCallRefreshModal && (
                      <OngoingCallRefreshModal showModal={showOngoingCallRefreshModal} setShowModal={setShowOngoingCallRefreshModalFunc} />
                    )}
                    {showAlreadyCompleted && <Modal13 setShowModal={setShowAlreadyCompleted} showModal={showAlreadyCompleted} />}
                    {showNotApproved && <Modal17 setShowModal={setShowNotApproved} showModal={showNotApproved} />}
                    {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
                    {showExpired && <Modal12 setShowModal={setShowExpired} showModal={showExpired} />}
                    {showTooEarly && <Modal11 setShowModal={setShowTooEarly} showModal={showTooEarly} />}
                    {showModal && (
                      <MessageModal setShowModal={setShowModal} showModal={showModal} message={"Please subscribe to a provided plan to access this feature."} />
                    )}
                    {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
                    {showModalForTherapist && !user?.subscriptionId && !user?.insuranceId && user?.premiumStatus != PremiumStatus.ACTIVE && (
                      <Modal22 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />
                    )}
                    {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}
                    {showPopUpAfterCallEndWhenOutgoinTimeOut && (
                      <CallerNotOnline
                        showModal={showPopUpAfterCallEndWhenOutgoinTimeOut}
                        setShowModal={setShowPopUpAfterCallEndWhenOutgoinTimeOut}
                        callerName={localUserOnCall.recieversName}
                      />
                    )}
                    <Elements stripe={stripePromise}>
                      {showPayImcompleteCoPayments && <Modal28 setShowModal={setShowPayIncompleteCopayments} showModal={showPayImcompleteCoPayments} copaymentAmount={coPaymentAmount} setPayAmount={setPaidCoAmount} setAfterPayCoAmount={setAfterPaidCoAmount} />}
                    </Elements>
                    {showImcompleteCoPayments && <Modal29 setShowModal={setShowIncompleteCopayments} showModal={showImcompleteCoPayments} copaymentAmount={coPaymentAmount} clientId={clientIdForSchedule} setCallModal={setJoinCall} />}
                    {children}
                    {incomingCall && user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN && !window.location.pathname.includes("room") && (
                      <Modal isOpen={incomingCall} centered>
                        <div
                          className="modal-body"
                          style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}
                        >
                          <div className="call-title" style={{ paddingBottom: "2vh" }}>
                            <i className="fa fa-phone"></i>&nbsp; Incoming Call from {callerName}
                          </div>
                          <div>
                            <Lottie animationData={ringing} loop={true} style={{ height: "150px" }} />
                          </div>
                          <div
                            className="call-action-btns"
                            style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}
                          >
                            <div
                              className="btn btn-info accept-btn"
                              onClick={() => {
                                joinZoomCall(false, "", meetingDuration, "", callId, false, allowTranscribeForUser);
                              }}
                            >
                              Accept
                            </div>
                            <div className="btn btn-danger reject-btn" onClick={rejectCall}>
                              Reject
                            </div>
                          </div>
                          {/* <div className="d-flex" style={{textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh"}}>
                              <h5 className="font-size-14 mb-4" style={{ paddingTop: "8px", paddingRight: "15px" }}>
                                Allow Call Transcript
                              </h5>

                              <div className="form-check form-switch form-switch-lg">
                                <label className="form-check-label" htmlFor="newsletter">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="newsletter"
                                    checked={allowTranscribeForUser}
                                    // checked={user?.callRecordingAllowed != null && user?.callRecordingAllowed == true ? true : false}
                                    onChange={e => {}}
                                    onClick={() => {
                                      setAllowTranscribeForUser(!allowTranscribeForUser)
                                      // changeAllowRecording(user?.callRecordingAllowed != null ? !user?.callRecordingAllowed : true);
                                    }}
                                  />
                                </label>
                              </div>
                            </div> */}
                        </div>
                      </Modal>
                    )}

                    {showOngoingGroupCallNotification && !location.pathname.includes("room") && user?.role == Role.CLIENT && ongoingGroupCall.length > 0 && (
                      <OngoingGroupCallNotification
                        ongoingGroupCall={ongoingGroupCall}
                        closeOngoingGroupCallNotificationFunction={closeOngoingGroupCallNotificationFunction}
                      />
                    )}

                    {!showAlreadyCompleted &&
                      !showExpired &&
                      !showTooEarly &&
                      !showModal &&
                      !showModalForTherapist &&
                      !showModalExceeded &&
                      !showPopUpAfterCallEndWhenOutgoinTimeOut &&
                      showNearestAppointmentPopUp &&
                      !showMissedCallPopUpWithNearestAppointment &&
                      !localUserOnCall.onOngoingCall &&
                      !incomingCall &&
                      !showIncompletedOtherCreatedMeeting &&
                      !showIncompletedOwnCreatedMeeting &&
                      user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN && (
                        <div className="call-div-new-2" style={{ zIndex: 9998 }}>
                          <div className="icon-call-container">
                            <img src={CallGif} className="call-gif" />
                          </div>
                          <div className="call-inside-div-2">
                            <p className="call-title gif-call-text">
                              {nearestAppointment.timeDifference && nearestAppointment.timeDifference >= 0
                                ? `Upcoming appointment with  ${user?.role == Role.CLIENT ? nearestAppointment.therapistId?.firstname : nearestAppointment.clientId?.firstname
                                } at`
                                : `Due appointment with ${user?.role == Role.CLIENT ? nearestAppointment.therapistId?.firstname : nearestAppointment.clientId?.firstname
                                } at`}
                              <span className="ms-1">{moment(nearestAppointment.start).format("hh:mm A")}</span>
                            </p>

                            <div className="call-action-btns">
                              {(
                                (nearestAppointment.status == AppointmentStatus.WAITING_FOR_APPROVAL || nearestAppointment.status == AppointmentStatus.PENDING) &&
                                (nearestAppointment.approvedStatus == ApprovalStatus.PENDING || nearestAppointment.approvedStatus == ApprovalStatus.APPROVED)
                              ) && (nearestAppointment.meetingStatus == null || nearestAppointment.meetingStatus != "STARTED") && (
                                  <>
                                    <div className="btn btn-primary accept-btn-n"
                                      onClick={() => {
                                        if (!callingOnActionProgress) {
                                          setShowNearestAppointmentPopUp(false);
                                          user?.role == Role.CLIENT
                                            ? startMeetingNew(
                                              nearestAppointment.therapistId._id,
                                              true,
                                              nearestAppointment._id,
                                              nearestAppointment.start,
                                              nearestAppointment.end,
                                              nearestAppointment.status,
                                              nearestAppointment.approvedStatus,
                                              allowTranscribeForUser
                                            )
                                            : startMeetingNew(
                                              nearestAppointment.clientId._id,
                                              true,
                                              nearestAppointment._id,
                                              nearestAppointment.start,
                                              nearestAppointment.end,
                                              nearestAppointment.status,
                                              nearestAppointment.approvedStatus,
                                              allowTranscribeForUser
                                            );
                                        }
                                      }}
                                    >
                                      Start
                                    </div>

                                    <div className="btn btn-danger reject-btn-n"
                                      onClick={() => {
                                        if (!callingOnActionProgress) {
                                          setShowNearestAppointmentPopUp(false);
                                        }
                                      }}
                                    >
                                      Close
                                    </div>
                                  </>
                                )}

                              {((nearestAppointment.status == AppointmentStatus.PENDING && nearestAppointment.approvedStatus == ApprovalStatus.APPROVED) ||
                                (nearestAppointment.status == AppointmentStatus.WAITING_FOR_APPROVAL &&
                                  nearestAppointment.approvedStatus == ApprovalStatus.PENDING)) &&
                                nearestAppointment.typeOfMeeting == AppointmentType.VIDEO &&
                                nearestAppointment.meetingStatus != null &&
                                nearestAppointment.meetingStatus == "STARTED" &&
                                nearestAppointment.meetingId != null &&
                                nearestAppointment.meetingStartedBy != null && (
                                  <div
                                    className="btn btn-primary accept-btn"
                                    onClick={() => {
                                      if (!callingOnActionProgress) {
                                        const timeDifferenceWithStartAndEnd = moment.duration(
                                          moment(nearestAppointment.end).diff(moment(nearestAppointment.start))
                                        );
                                        const timeDifferenceWithStartAndEndAsMinutes = timeDifferenceWithStartAndEnd.asMinutes();
                                        const finalMeetingTime = Math.trunc(timeDifferenceWithStartAndEndAsMinutes);

                                        joinZoomCall(
                                          false,
                                          user?.role == Role.CLIENT ? nearestAppointment.therapistId._id : nearestAppointment.clientId._id,
                                          finalMeetingTime,
                                          nearestAppointment._id,
                                          "",
                                          true,
                                          allowTranscribeForUser
                                        );
                                      }
                                    }}
                                  >
                                    Join
                                  </div>
                                )}

                              {((nearestAppointment.status == AppointmentStatus.PENDING && nearestAppointment.approvedStatus == ApprovalStatus.APPROVED) ||
                                (nearestAppointment.status == AppointmentStatus.WAITING_FOR_APPROVAL &&
                                  nearestAppointment.approvedStatus == ApprovalStatus.PENDING)) &&
                                nearestAppointment.typeOfMeeting == AppointmentType.VIDEO &&
                                nearestAppointment.meetingStatus != null &&
                                nearestAppointment.meetingStatus == "STARTED" &&
                                nearestAppointment.meetingId != null &&
                                nearestAppointment.meetingStartedBy != null && (
                                  <div
                                    className="btn btn-danger reject-btn"
                                    onClick={() => {
                                      if (!callingOnActionProgress) {
                                        cancellCall(nearestAppointment.meetingId, true, false, "");
                                      }
                                    }}
                                  >
                                    {nearestAppointment.meetingStartedBy == user?._id ? "End" : "Reject"}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                    {!showAlreadyCompleted &&
                      !showExpired &&
                      !showTooEarly &&
                      !showModal &&
                      !showModalForTherapist &&
                      !showModalExceeded &&
                      !showPopUpAfterCallEndWhenOutgoinTimeOut &&
                      !showMissedCallPopUpWithNearestAppointment &&
                      !localUserOnCall.onOngoingCall &&
                      !incomingCall &&
                      !showIncompletedOtherCreatedMeeting &&
                      showIncompletedOwnCreatedMeeting &&
                      user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN &&
                      !window.location.pathname.includes("room") && (
                        <Modal isOpen={showIncompletedOwnCreatedMeeting} centered>
                          <div
                            className="modal-body"
                            style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}
                          >
                            <div className="call-title" style={{ paddingBottom: "2vh" }}>
                              <i className="fa fa-phone"></i>&nbsp;
                              {incompletedCall.isAppointmentBased
                                ? `Ongoing Appointment with ${user?.role == Role.CLIENT ? incompletedCall?.therapistId?.firstname : incompletedCall?.clientId?.firstname
                                }`
                                : `Ongoing Call With ${user?.role == Role.CLIENT ? incompletedCall?.therapistId?.firstname : incompletedCall?.clientId?.firstname
                                }`}
                            </div>
                            <div
                              className="call-action-btns"
                              style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}
                            >
                              <div
                                className="btn btn-info accept-btn"
                                onClick={() => {
                                  if (!callingOnActionProgress) {
                                    joinZoomCall(
                                      false,
                                      incompletedCall.therapistId._id,
                                      incompletedCall.meetingDuration,
                                      incompletedCall.appointmentId,
                                      incompletedCall._id,
                                      incompletedCall.isAppointmentBased,
                                      allowTranscribeForUser
                                    );
                                  }
                                }}
                              >
                                Join
                              </div>
                              <div
                                className="btn btn-danger reject-btn"
                                onClick={() => {
                                  if (!callingOnActionProgress) {
                                    cancellCall(incompletedCall._id, false, false, "");
                                  }
                                }}
                              >
                                End
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}

                    {!showAlreadyCompleted &&
                      !showExpired &&
                      !showTooEarly &&
                      !showModal &&
                      !showModalForTherapist &&
                      !showModalExceeded &&
                      !showPopUpAfterCallEndWhenOutgoinTimeOut &&
                      !showMissedCallPopUpWithNearestAppointment &&
                      !localUserOnCall.onOngoingCall &&
                      !incomingCall &&
                      !showIncompletedOwnCreatedMeeting &&
                      showIncompletedOtherCreatedMeeting &&
                      user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN &&
                      !window.location.pathname.includes("room") && (
                        <Modal isOpen={showIncompletedOtherCreatedMeeting} centered>
                          <div
                            className="modal-body"
                            style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}
                          >
                            <div className="call-title" style={{ paddingBottom: "2vh" }}>
                              <i className="fa fa-phone"></i>&nbsp;
                              {incompletedCall.isAppointmentBased
                                ? `Ongoing Appointment with ${user?.role == Role.CLIENT ? incompletedCall.therapistId.firstname : incompletedCall.clientId.firstname
                                }`
                                : `Incoming Call From ${user?.role == Role.CLIENT ? incompletedCall.therapistId.firstname : incompletedCall.clientId.firstname
                                }`}
                            </div>
                            <div
                              className="call-action-btns"
                              style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}
                            >
                              <div
                                className="btn btn-info accept-btn"
                                onClick={() => {
                                  if (!callingOnActionProgress) {
                                    joinZoomCall(
                                      false,
                                      incompletedCall.therapistId._id,
                                      incompletedCall.meetingDuration,
                                      incompletedCall.appointmentId,
                                      incompletedCall._id,
                                      incompletedCall.isAppointmentBased,
                                      allowTranscribeForUser
                                    );
                                  }
                                }}
                              >
                                Join
                              </div>
                              <div
                                className="btn btn-danger reject-btn"
                                onClick={() => {
                                  if (!callingOnActionProgress) {
                                    cancellCall(
                                      incompletedCall._id,
                                      false,
                                      true,
                                      user?.role == Role.CLIENT ? incompletedCall.therapistId._id : incompletedCall.clientId._id
                                    );
                                  }
                                }}
                              >
                                Reject
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}

                    {!user?.hideCallTimer && !hideCallTimerWhenCall && showCountdownTimer && (countDownTime.hours != 0 || countDownTime.minutes != 0) && (
                      <CountDownTimer
                        hoursMinSecs={moment()
                          .add(countDownTime.hours, "h")
                          .add(countDownTime.minutes, "m")
                          .add(countDownTime.seconds, "s")
                          .toDate()
                          .toISOString()}
                        hideTimer={hideCallTimerPopUpSet}
                      ></CountDownTimer>
                    )}

                    {showHideCallTimerPopUp && user?.role != Role.SUPER_ADMIN && user?.role != Role.SUB_ADMIN &&  (
                      <HideTimerWhenCallModal showModal={showHideCallTimerPopUp} hideTimer={hideCallTimer}></HideTimerWhenCallModal>
                    )}

                    {zoomIncomingCall && !window.location.pathname.includes("room") && (
                      <Modal isOpen={zoomIncomingCall} centered>
                        <div
                          className="modal-body"
                          style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}
                        >
                          <div className="call-title" style={{ paddingBottom: "2vh" }}>
                            <i className="fa fa-phone"></i>&nbsp; Incoming Call from {callerName}
                          </div>
                          <div>
                            <Lottie animationData={ringing} loop={true} style={{ height: "150px" }} />;
                          </div>
                          <div
                            className="call-action-btns"
                            style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}
                          >
                            <div
                              className="btn btn-info accept-btn"
                              onClick={() => {
                                history.push(zoomUrl);
                                setZoomIncomingCall(false);
                              }}
                            >
                              Accept
                            </div>
                            <div
                              className="btn btn-danger reject-btn"
                              onClick={() => {
                                setZoomIncomingCall(false);
                              }}
                            >
                              Reject
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )}

                    {showSchedulePopup && user?.role == "CLIENT" && meetingEndedProperly && appointmentSuccess && (
                      <Modal isOpen={showSchedulePopup} centered className="modal-lg">
                        <div
                          className="modal-body"
                          style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}
                        >
                          <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "2vh" }}>
                            <div className="image-container2">
                              <img src={CoverMeeting} className="meeting-ended-image-size" alt="Meeting Cover" />
                              <div className="button-container btn-container-position-top">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowSchedulePopupFunction(false);
                                    setMeetingEndedProperlyFunction(false);
                                    setAppointmentSuccess(false);
                                  }}
                                  className="bg-transparent-color"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <img src={Close} alt="Close" className="" />
                                </button>
                              </div>
                            </div>

                            <h4 className="fw-semibold mt-3 pl-3 pr-3 pl-md-1 pr-md-1">Congratulations on completing your session. </h4>
                            <span className="pl-3 pr-3 pl-md-1 pr-md-1">
                              You are doing a great job and I’m happy for you. You are one step closer to your goals!
                              <span>
                                <img src={FaceIcon} className="ended-meeting-emoji" />
                              </span>
                              Please confirm your next session below
                            </span>

                            {!isAppointmentLoading &&
                              !isAppointmentError &&
                              nextAppointments?.length > 0 &&
                              nextAppointments
                                .sort((a: any, b: any) => (a.start > b.start ? 1 : -1))
                                .slice(0, 1)
                                .map((session: Appointment) => {
                                  const staratDate = new Date(session?.start);
                                  const oneWeekLater = new Date(nextAppointments?.length > 0 ? session?.start : staratDate.getTime() + 7 * 24 * 60 * 60 * 1000);
                                  return (
                                    <div
                                      className="upcoming-appointment-main-list-item d-flex flex-column justify-content-center align-items-center"
                                      key={session._id}
                                    >
                                      <div className="mt-2 upcoming-appointment-sub-list-item2 d-flex flex-column justify-content-center align-items-center">
                                        <div className="upcoming-appointment-sub-list-item-front-box">
                                          <p className="mb-0 font-size-14" style={{ color: "black" }}>
                                            {upcomingAppointments?.length > 0 ? "Your next session with" : "Your next suggested session with"}&nbsp;
                                            {session.therapistId.firstname ? session.therapistId.firstname : pastAppointments[0].therapistId.firstname}
                                            {session.therapistId.lastname ? session.therapistId.lastname : pastAppointments[0].therapistId.lastname}
                                          </p>
                                          <div style={{ display: "flex", marginTop: 5 }} className="justify-content-center">
                                            <img src={images.clockIcon} className="clock-icon" />
                                            <h4 className="mb-0 font-size-12" style={{ color: "#575151" }}>
                                              {Util.formatAMPM(oneWeekLater)} | &nbsp;
                                            </h4>
                                            <div style={{ display: "flex" }}>
                                              <img src={images.appointmentIcon} className="appontment-icon" />
                                              <h4 className="mb-0 font-size-12" style={{ color: "black" }}>
                                                {weekday[oneWeekLater.getDay()] + ", " + monthNames[oneWeekLater.getMonth()] + " " + oneWeekLater.getDate()}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                            {!isAppointmentLoading && !isAppointmentError && nextAppointments?.length > 0 && (
                              <>
                                <button onClick={() => handleClickedConfirmSchedule()} className="btn btn-primary mt-4  ">
                                  Please Confirm
                                </button>
                                <div className="divider-icon2 my-1">or</div>
                              </>
                            )}

                            <button onClick={() => handleClickedShedule()} className="btn btn-primary  mb-3">
                              Schedule Appointment
                            </button>
                          </div>
                        </div>
                      </Modal>
                    )}

                    {isOpenPinPopup && user?.role == Role.THERAPIST && (
                      <Modal isOpen={isOpenPinPopup} centered className="modal-sm">
                        <div
                          className="modal-body"
                          style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}
                        >
                          <div className="call-title" style={{ paddingBottom: "2vh" }}>
                            <h5>Enter Pin Number</h5>
                          </div>
                          <div className="d-flex flex-column justify-content-center align-items-center mb-6">
                            <OtpInput
                              value={pinNumber}
                              onChange={setPinNumber}
                              numInputs={4}
                              renderSeparator={<span className="mx-2"></span>}
                              renderInput={props => <input {...props} className="input-pin-box" />}
                              inputType={"number"}
                            />
                            {pinTryCount > 0 && <p className="text-danger mb-0 mt-1">You can try {3 - pinTryCount} times</p>}
                          </div>
                          <div
                            className="call-action-btns"
                            style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}
                          >
                            <div
                              className="btn btn-info accept-btn"
                              onClick={() => {
                                checkPinNumber();
                              }}
                            >
                              Send
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )
                    }

                    {user && user?.role && (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN) && (
                      <div className="page-content">
                        <Container fluid>
                          <Offcanvas className="offcanvas-client-chat offcanvas-ends-client-chat" isOpen={isOpenClientChat} direction="end" toggle={toggleClientChat}>
                            <OffcanvasHeader toggle={toggleClientChat}>{`Message history with ${selectedClientsDetailsForChat ?? ""}`}</OffcanvasHeader>
                            <OffcanvasBody className="p-0">
                              <ClientChatView clientId={selectedClientsIdForChat}></ClientChatView>
                            </OffcanvasBody>
                          </Offcanvas>
                        </Container>
                      </div>
                    )}

                  </div>
                </CallInitializationContext.Provider>
              </LocalCallContext.Provider>
            </LocalCallTimeOutContext.Provider>
          </ShowPopupSheduleContext.Provider>
        </CallEndContext.Provider>
      </ZoomContext.Provider>
    </SocketContext.Provider>
  );
};

export default SocketConnection2;
