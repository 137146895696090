import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Modal,
  TabPane,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Creatable from "react-select/creatable";
import Select from "react-select";
import user1 from "../../assets/images/default_profile.png";
import videoIcon from "../../assets/images/icon_video.svg";
import googleImage from "../../assets/images/google_logo.png";
import pencil from "../../assets/images/pencil.svg";
import close from "../../assets/images/close.png";
import profileBackground from "../../assets/images/default_cover.png";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import { Tags } from "../../common/custom-components/Tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
const classNames = require("classnames");
import { ExperienceTag, SelectedExperienceTag } from "../../models/ExperienceTag";
import { CommonService } from "../../services/CommonService";
import { Ethinicity } from "../../models/Ethnicity";
import { PasswordDetails, PinDetails, UserDetails, UserDetails2 } from "../../models/User";
import { Qualification } from "../../models/Qualification";
import { TherapistService } from "../../services/TherapistService";
import { Upload } from "../../models/Upload";
import { License } from "../../models/License";
import { Profession, ProfessionLicense } from "../../models/Profession";
import { ArticleService } from "../../services/ArticleService";
import { WorkingHour } from "../../models/WorkingHour";
import { CustomerReviewData } from "../../models/Review";
import { ClientService } from "../../services/ClientService";
import { confirm } from "react-confirm-box";
import axios from "axios";
import { Util } from "../../Util";
import { MediaUploaderVimeo } from "../../utils/MediaUploaderVimeo";
import { DateRangePicker } from "rsuite";
import Spinner from "../../common/spinner/spinner";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ApiCalendar from "react-google-calendar-api";
import { environment } from "../../environment/environment";
import "react-phone-number-input/style.css";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import OtpInput from "react-otp-input";
import { Role } from "src/models/Role";
import getCroppedImgAsFile from "./cropImage";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import StripeDetails from "./StripeDetails";
import CopaymentDetails from "./CopaymentDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CustomTimePicker2 from "src/components/CustomTimePicker2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ParameterTypes } from "src/utils/ParameterTypes";
import ProfileInsuranceDetails from "./InsuranceProfile";
import NotesSettings from "./NotesSettings";

const stripePromise = loadStripe(environment.stripe_publishable_key);

const config = {
  clientId: environment.google_calendar_client_id,
  apiKey: environment.google_api_key,
  scope: environment.google_calendar_scope,
  discoveryDocs: environment.google_calendar_discovery_docs,
};

const apiCalendar = new ApiCalendar(config);

toast.configure();

const UserProfile: React.FC = () => {
  const { activeTab } = useParams<ParameterTypes>();
  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);
  const [user, setUser] = useContext(UserContext);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [modalCenter, setModalCenter] = useState(false);
  const [modalCenter2, setModalCenter2] = useState(false);
  const [editProfile, setShowEditProfile] = useState(false);
  const [modalCenterWorkingHours, setModalCenterWorkingHours] = useState(false);
  const [educationData, setEducationData] = useState<Qualification>({} as Qualification);
  const [isOpen, setIsOpen] = useState(false);
  const [profession, setProfession] = useState<Profession>({ _id: "", name: "" });
  const [expereinceYears, setExpereinceYears] = useState(0);
  const [newWorkingHour, setNewWorkingHour] = useState({
    startTime: "0:00 AM",
    endTime: "0:00 AM",
    day: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([] as WorkingHour[]);
  const [selectedExpTags, setSelectedExpTags] = useState<SelectedExperienceTag[]>([] as SelectedExperienceTag[]);
  const [selecteInsuranceCompanyTags, setSelectedInsuranceCompanyTags] = useState<SelectedInsuranceCompanyTag[]>([] as SelectedInsuranceCompanyTag[]);
  const [selectTherapyTags, setSelectedTherapyTags] = useState<any[]>([] as any[]);
  const [searchTherapyTags, setSearchTherapyTags] = useState<any[]>([] as any[]);
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] = useState("1");
  const [ethinicityList, setEthinicityList] = useState<Ethinicity[]>([]);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [professionsLicensDetails, setProfessionsLicensDetails] = useState<ProfessionLicense[]>([] as ProfessionLicense[]);
  const [selectedProfessionsLicensDetails, setSelectedProfessionsLicensDetails] = useState({} as ProfessionLicense);
  const [setEthenicity, setUpdateEthenacity] = useState("");
  const [dateValue, setDateValue] = useState(null);
  const [idDateValue, setIdDateValue] = useState(null);
  const [dogDateValue, setDogDateValue] = useState(null);
  const [edDateValue, setEdDateValue] = useState(null);
  const [medDateValue, setMedDateValue] = useState(null);
  const [changePassword, setChangePassword] = useState<PasswordDetails>({} as PasswordDetails);
  const [educationsDetailsList, setEducationsDetailsList] = useState<Qualification[]>([] as Qualification[]);
  const [reviewTherapist, setReviewTherapistData] = useState<CustomerReviewData[]>([] as CustomerReviewData[]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [modalcentervideo, setModalCenterVideo] = React.useState(false);
  const [modalcentercoverimage, setModalCenterCoverImage] = React.useState(false);
  const [modalcentercovertheme, setModalCenterCoverTheme] = React.useState(false);
  const [themeImages, setThemeImages] = useState<Upload[]>([] as Upload[]);
  const uploader = new MediaUploaderVimeo();
  const [fileType, setFileType] = useState("");
  const SUPPORTED_VIDEO_FORMATS = ["mp4", "mkv", "webm", "mov", "ogg", "mpeg", "mpg", "flv"];
  const [isHours, setIsHours] = useState<string[]>([]);
  const [isUpating, setIsUpating] = useState(false);
  const locale = "en";
  const hours: string[] = [];
  const [selectedDates, setSelectedDates] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
  const [googleLoginStatus, setGoogleLoginStatus] = useState<boolean>();
  type Value = Date | string | null;
  const [startTimeValue, setStartTimeValue] = useState<string | null>(null);
  const [endTimeValue, setEndTimeValue] = useState<string | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("Eastern Daylight Time");
  const dinput = document.getElementById("mui-1") as HTMLButtonElement | null;
  const dinput2 = document.getElementById("mui-2") as HTMLButtonElement | null;
  const [isgoogleCalendarAccess, setgoogleCalendarAccess] = useState(user?.googleCalendarAccess);
  const [googleUserEmail, setGoogleUserEmail] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const [showPassword3, setShowPassword3] = useState(true);
  const [oldPinNumber, setOldPinNumber] = useState("");
  const [newPinNumber, setNewPinNumber] = useState("");
  const [confirmPinNumber, setConfirmPinNumber] = useState("");
  const [changePinNumber, setChangePinNumber] = useState<PinDetails>({} as PinDetails);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image2, setImage2] = useState<File | null>(null);
  const [therapistCategorizationByType, setTherapistCategorizationByType] = useState("");
  // const [activeKey, setActiveKey] = useState("1");

  const editorConfiguration = {
    toolbar: {
      items: ["heading", "|", "bold", "italic", "|", "bulletedList", "numberedList", "|", "imageUpload", "blockQuote", "|", "undo", "redo"],
    },
  };

  if (dinput != null) {
    dinput.disabled = true;
  }

  if (dinput2 != null) {
    dinput2.disabled = true;
  }

  function togCenterVideo() {
    setModalCenterVideo(!modalcentervideo);
  }

  function togCenterCoverImage() {
    setModalCenterCoverImage(!modalcentercoverimage);
    setModalCenterCoverTheme(false);
  }

  function togCenterCoverTheme() {
    setModalCenterCoverTheme(!modalcentercovertheme);
  }

  const [licensesData, setLicensesData] = useState<License>({} as License);
  const licensesRef = useRef<any>();
  licensesRef.current = licensesData;

  const [uploads, setUploads] = useState<Upload[]>([] as Upload[]);
  const uploadsRef = useRef<any>();
  uploadsRef.current = uploads;

  const [educationId, setEducationId] = useState("");
  const educationPopupId = useRef<any>();
  educationPopupId.current = educationId;

  const [licensesId, setLicensesId] = useState("");
  const licensesPopupId = useRef<any>();
  licensesPopupId.current = licensesId;

  const [licenses, setShowLicenses] = React.useState(false);
  const licensesPopup = useRef<any>();
  licensesPopup.current = licenses;

  const educationRef = useRef<any>();
  educationRef.current = educationData;

  const [educationFile, setEducationFile] = useState({ preview: "", preview2: "", raw: "" });
  const educationFileRef = useRef<any>();
  educationFileRef.current = educationFile;

  const [statementFile, setStatementFile] = useState({ preview: "", preview2: "", raw: "" });
  const statementFileRef = useRef<any>();
  statementFileRef.current = statementFile;

  const [image, setImage] = useState({ preview: "", raw: "" });
  const thirdData = useRef<any>();
  thirdData.current = image;

  const [deletingUploadId, setDeletingUploadId] = React.useState<any[]>([]);
  const removeArrayRef = useRef<any>();
  removeArrayRef.current = deletingUploadId;

  const [licensesFile, setLicensesFile] = useState({ preview: "", preview2: "", raw: "" });
  const licensesFileRef = useRef<any>();
  licensesFileRef.current = licensesFile;

  const [licensesDetailsList, setLicensesDetailsList] = useState<License[]>([] as License[]);
  const licensesDetailsRef = useRef<any>();
  licensesDetailsRef.current = licensesDetailsList;

  const history = useHistory();

  const [timezones] = useState([
    { value: "-1", label: "Select" },
    { value: "Hawaii Standard Time", label: "Hawaii Standard Time" },
    { value: "Hawaii-Aleutian Daylight Time", label: "Hawaii-Aleutian Daylight Time" },
    { value: "Alaska Daylight Time", label: "Alaska Daylight Time" },
    { value: "Pacific Daylight Time", label: "Pacific Daylight Time" },
    { value: "Mountain Standard Time", label: "Mountain Standard Time" },
    { value: "Mountain Daylight Time", label: "Mountain Daylight Time" },
    { value: "Central Daylight Time", label: "Central Daylight Time" },
    { value: "Eastern Daylight Time", label: "Eastern Daylight Time" },
  ]);

  useEffect(() => {
    setProfession({} as Profession);
    setUserdata(user);
    setProfession({ _id: user?.profession?._id, name: user?.profession?.name });
    const updateAdd = user?.experiencedIn?.map((tag: { experienceTag: any; _id: any }) => {
      return { label: tag.experienceTag, value: tag._id };
    });

    if (updateAdd) {
      setSelectedExpTags(updateAdd);
    }

    if (apiCalendar.tokenClient) {
      setGoogleLoginStatus(true);
    } else {
      setGoogleLoginStatus(false);
    }

    if (activeTab == "9") {
      setverticalActiveTabWithIcon(activeTab);
    }
  }, [editProfile]);

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthinicityList(res.data);
    });

    TherapistService.getProfessions().then(res => {
      setProfessionsDetails(res.data);
    });

    TherapistService.getProfessionLicense().then(res => {
      setProfessionsLicensDetails(res.data);
    });
    const data = {
      therapistId: user?._id,
    };
    ClientService.getTherapistApprovedReviewByTherapistId(data, 1000, 0).then(res => {
      setReviewTherapistData(res.data);
    });

    viewAllEducations();
    viewAllLicenses();
    getAllExperienceTags();
    viewAllThemeImages();
    getAllInsuranceCompanies();

    const updateAdd = user?.experiencedIn?.map((tag: { experienceTag: any; _id: any }) => {
      return { label: tag.experienceTag, value: tag._id };
    });

    if (updateAdd) {
      setSelectedExpTags(updateAdd);
    }

    const updateInsurance = user?.insuranceCompanies?.map((tag: { insuranceCompany: any; _id: any }) => {
      return { label: tag?.insuranceCompany, value: tag?._id };
    });

    if (updateInsurance) {
      setSelectedInsuranceCompanyTags(updateInsurance);
    }

    if (user && user.therapyState) {
      const updatedTags = user.therapyState.map((tag: string) => {
        return { label: tag };
      });
      setSelectedTherapyTags(updatedTags);
    }

    const workingHours = user?.workingHours;

    if (workingHours) {
      setWorkingHours(workingHours);
    }

    const workingDays = user?.workingHours?.map(c => c.day);

    // const newList = selectedDates.filter(function (word) {
    //   return !workingDays?.includes(word);
    // });

    // setSelectedDates(newList);

    createHoursForTimeSlots();

    if (apiCalendar.tokenClient) {
      setGoogleLoginStatus(true);
    } else {
      setGoogleLoginStatus(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getTherapistCategorizationByType();
    }
  }, [user]);
  const TherapyState = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "Ohio",
    "Montana",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington DC",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const getTherapistCategorizationByType = async () => {
    try {
      const res = await CommonService.getTherapistCategorizationByType();
      if (res.success) {
        setTherapistCategorizationByType(res.data.therapistCategorizationByType);
      }
    } catch (error) {
      toast.error("Error in getting client name and therapist name", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };
  const getStateList = async () => {
    const res = await CommonService.getAllStatesPublic();
    if (res.success) {
      const option = res?.data?.map((state: { stateName: string }) => ({ value: state?.stateName, label: state?.stateName }));

      setSearchTherapyTags(option);
    }
  };

  useEffect(() => {
    getStateList();
  }, []);

  const handleTherapyStateTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any }) => {
      return { label: tag.label };
    });

    if (updatedTags) {
      setSelectedTherapyTags(updatedTags);
      setSearchTherapyTags(searchTherapyTags.filter(tagObj => !updatedTags.find((updatedTag: { label: any }) => updatedTag.label === tagObj.label)));
    }
  };

  const removeTherapyStateTag = (removedtag: any) => {
    setSelectedTherapyTags(selectTherapyTags.filter(tagObj => tagObj.label !== removedtag));

    const addTags = { value: removedtag, label: removedtag };
    if (addTags) {
      setSearchTherapyTags([...searchTherapyTags, addTags]);
    }
  };

  const selectedProfessions = professionsLicensDetails?.filter(c => c.professionId === profession?._id);

  const createHoursForTimeSlots = () => {
    moment.locale(locale);

    for (let hour = 0; hour < 24; hour++) {
      hours.push(moment({ hour }).format("H:mm A"));
      hours.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }

    setIsHours(hours);
  };

  const changeExpereinceYears = (event: any, newValue: any) => {
    setExpereinceYears(newValue);
  };

  function togCenter() {
    setEducationId("");
    setEducationData({ degree: "", university: "", startYear: "", endYear: "", fieldOfStudy: "" });

    setEducationFile({
      preview: "",
      preview2: "",
      raw: "",
    });

    setModalCenter(!modalCenter);
    removeBodyCss();
  }

  function togCenterWorkingHours() {
    setModalCenterWorkingHours(!modalCenterWorkingHours);
    removeBodyCss();
  }

  function togCenterWorkingHoursClose() {
    setModalCenterWorkingHours(false);
  }

  function togCenterClose() {
    setEducationId("");
    setModalCenter(false);
  }

  function togCenter2() {
    setLicensesId("");
    setLicensesData({ title: "" });
    setLicensesFile({
      preview: "",
      preview2: "",
      raw: "",
    });
    setModalCenter2(!modalCenter2);
    removeBodyCss();
  }

  function togCenter2Close() {
    setLicensesId("");
    setModalCenter2(false);
  }

  function setEditProfile() {
    setShowEditProfile(true);
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const dropdownIndicator = () => {
    return (
      <div style={{ marginLeft: "5px", marginRight: "10px" }}>
        <i className="fa fa-search"></i>
      </div>
    );
  };

  const creatableComponents = {
    DropdownIndicator: dropdownIndicator,
    IndicatorSeparator: null,
  };

  const toggleVerticalIcon = (tab: any) => {
    if (verticalActiveTabWithIcon != tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  const setNewWorkingDay = (e: any) => {
    setNewWorkingHour({ ...newWorkingHour, day: e });
  };

  const inputProps = {
    placeholder: "Start Year",
    className: "form-control",
  };

  const inputProps1 = {
    placeholder: "End Year",
    className: "form-control",
  };

  const handlExpTags = (value: any) => {
    if (value.length > 0) {
      if (!/^[A-Za-z\s- ]*$/.test(value[value.length - 1].label)) {
        return toast.error("Only letters are allowed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTags = value?.map((tag: { label: any; value: any }) => {
        return { label: tag.label.trim(), value: tag.value.trim() };
      });

      if (updatedTags) {
        setSelectedExpTags(updatedTags);
      }
    }
  };

  const removeExpTag = (removedtag: any) => {
    setSelectedExpTags(selectedExpTags.filter(tag => tag.label != removedtag));
  };

  const handlInsuranceCompanyTags = (value: any) => {
    const updatedTags = value?.map((tag: { label: any; value: any }) => {
      return { label: tag.label, value: tag.value };
    });

    if (updatedTags) {
      setSelectedInsuranceCompanyTags(updatedTags);
    }
  };

  const handleTimezone = (event: any) => {
    const value = event.target.value;
    if (value) {
      setSelectedTimezone(value);
    }
  };

  const removeInsuranceCompanyTag = (removedtag: any) => {
    setSelectedInsuranceCompanyTags(selecteInsuranceCompanyTags.filter(tag => tag.label != removedtag));
  };

  const viewAllEducations = () => {
    setIsLoading(true);
    TherapistService.getAllEducations(user?._id as any).then(res => {
      setEducationsDetailsList(res.data);
      setIsLoading(false);
      if (educationPopupId.current) {
        const selectedCategory = res.data?.filter(c => c._id === educationPopupId.current);
        const selectedCategorydata = selectedCategory[0];
        setEducationData(selectedCategorydata);
        setUploads(selectedCategorydata.uploadId as []);
      }
    });
  };

  const setShowEducationDetails = (value: any) => {
    setEducationId(value);
    viewAllEducations();
    setEducationData({ degree: "", university: "", startYear: "", endYear: "", fieldOfStudy: "" });
    setEducationFile({
      preview: "",
      preview2: "",
      raw: "",
    });
    setModalCenter(true);
  };

  const setShowLicensesDetails = (value: any) => {
    setLicensesId(value);
    viewAllLicenses();
    setLicensesData({ title: "" });
    setLicensesFile({
      preview: "",
      preview2: "",
      raw: "",
    });

    setModalCenter2(true);
  };

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });
        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };

  const getAllInsuranceCompanies = () => {
    InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.insuranceCompany };
        });
        setSearchInsuranceCompanyTag(tagList);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const imageTypeRegex = /image\/(png|jpg|jpeg|gif|bmp|tif|tiff|eps)/gm;

  const handleZoomChange = (event: any, newZoom: any) => {
    setZoom(newZoom);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCrop = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      const croppedImageNew = await getCroppedImgAsFile(image2, croppedArea, croppedAreaPixels);
      setCroppedImage(croppedImageNew as File | null);
    } catch (error) {
      console.error("Error capturing cropped image:", error);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && typeof event.target.result === "string") {
          setImage({ preview: event.target.result, raw: "" });
          setImage2(e.target.files?.[0] ?? null);
          toggleModal();
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const selectFile = (e: any) => {
    if (croppedImage && croppedImage.type.match(imageTypeRegex)) {
      const formData: FormData = new FormData();
      formData.append("profileImage", croppedImage);

      axios
        .post(Util.apiAuthUrl(`updateProfileImage`), formData, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            setUser(res.data);
            toggleModal();
            toast.success("Profile Image Uploaded!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setProgress(0);
          } else {
            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            toast.error("API Error! Failed to upload the profile image!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });

      return false;
    } else {
      toast.error("Only images can be uploaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const selectVideoFile = async (e: any) => {
    const extension = e.target.files[0].name.split(".").pop().toLowerCase();
    const isAVideo = isSupported(SUPPORTED_VIDEO_FORMATS, extension);

    if (isAVideo) {
      setFileType("VIDEO");
    } else {
      toast.error("File type is not supported!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (e.target.files.length) {
      setShowProgress(true);

      const videoMeta = await uploader.upload(e.target.files[0], (bytesUploaded: number, bytesTotal: number) => {
        setProgress(Math.floor((bytesUploaded * 100) / bytesTotal));
      });

      const updatedUser: UserDetails = {
        vimeoId: videoMeta.id,
      };

      TherapistService.updateProfileVideo(updatedUser).then(res => {
        if (res.success) {
          setUser(res.data);

          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setShowProgress(false);

          setProgress(0);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      toast.error("Please select video file!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const selectCoverFile = (e: any) => {
    if (e.target.files.length && e.target.files[0].type.match(imageTypeRegex)) {
      const formData: FormData = new FormData();
      for (const file of e.target.files) {
        if (e.target.files) {
          formData.append("profileCoverImage", file);
        }
      }
      axios
        .post(Util.apiAuthUrl(`updateProfileCoverImage`), formData, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            setUser(res.data);
            toast.success("Profile Cover Banner Uploaded!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setProgress(0);
            setModalCenterCoverImage(false);
          } else {
            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            toast.error("API Error! Failed to upload the profile cover banner!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });

      return false;
    } else {
      toast.error("Only images can be uploaded.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const selectCoverTherapistFile = (e: any) => {
    if (e.length) {
      const theme = {
        themeId: e,
      };
      axios
        .post(Util.apiAuthUrl(`updateProfileCoverImage`), theme, {
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res: any) => {
          if (res.success) {
            setUser(res.data);
            toast.success("Profile Cover Banner Uploaded!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            setProgress(0);
            setModalCenterCoverImage(false);
            setModalCenterCoverTheme(false);
          } else {
            toast.error(res.data.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            return false;
          }
        })
        .catch(error => {
          if (error) {
            toast.error("API Error! Failed to upload the profile cover banner!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      return false;
    }
  };

  const updateUserNotification = () => {
    const updatedUser: UserDetails2 = {
      reminderTime: userdata?.reminderTime,
      reminderType: userdata?.reminderType,
    };

    CommonService.updateUserNotification(updatedUser).then(res => {
      if (res.success) {
        setUser(res.data);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };
  const updateTherapistDetails = () => {
    const phoneno = /^\+1[0-9]{10}$/;
    const zipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    const digit = /^[0-9\b]+$/;
    const updateTherapyStateList = selectTherapyTags?.map(obj => obj?.label);

    if (therapistCategorizationByType !== "") {
      updateTherapistCategorizationByType();
    }
    if ((!dateValue as any) && !userdata?.dateOfBirth) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.zipCode.match(zipcode)) {
      toast.error("Please enter valid zipcode!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.state) {
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.socialSecurity && userdata?.socialSecurity?.length != 9) {
      toast.error("Social security number should be 9 characters long.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.socialSecurity && !userdata?.socialSecurity?.match(digit)) {
      toast.error("Please enter only numbers for social security number.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!selectedTimezone || selectedTimezone == "-1") {
      toast.error("Please select a timezone!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (statementFileRef.current.raw) {
      setIsUpating(true);
      const updatedUser: UserDetails2 = {
        firstname: userdata?.firstname,
        lastname: userdata?.lastname,
        middleInitials: userdata?.middleInitials,
        username: userdata?.username,
        gender: userdata?.gender,
        dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
        email: userdata?.email,
        ethnicityId: (setEthenicity as any) || userdata?.ethnicityId?._id,
        description: userdata?.description,
        deletingStatementId: userdata?.disclosureStatementId?._id || "none",
        profession: profession?._id || userdata?.profession?._id,
        professionLicense: userdata?.professionLicense?._id,
        experiencedIn: selectedExpTags.map(tag => {
          return tag.label;
        }),
        insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
          return tag.label;
        }),
        workingHours: workingHours,
        socialSecurity: userdata?.socialSecurity,
        cAQH: userdata?.cAQH,
        nPI1: userdata?.nPI1,
        taxIdentification: userdata?.taxIdentification,
        license: userdata?.license,
        issueDate: (idDateValue as any) || userdata?.issueDate,
        expirationDate: (edDateValue as any) || userdata?.expirationDate,
        schoolName: userdata?.schoolName,
        dateOfGraduation: (dogDateValue as any) || userdata?.dateOfGraduation,
        schoolStreetAddress: userdata?.schoolStreetAddress,
        schoolCity: userdata?.schoolCity,
        schoolState: userdata?.schoolState,
        schoolZipCode: userdata?.schoolZipCode,
        taxonomyCode: userdata?.taxonomyCode,
        malpracticePolicy: userdata?.malpracticePolicy,
        malpracticeExpirationDate: (medDateValue as any) || userdata?.malpracticeExpirationDate,
        primaryPhone: userdata?.primaryPhone,
        streetAddress: userdata?.streetAddress,
        city: userdata?.city,
        state: userdata?.state,
        zipCode: userdata?.zipCode,
        timeZone: selectedTimezone,
        googleCalendarAccess: isgoogleCalendarAccess,
        reminderTime: userdata?.reminderTime,
        reminderType: userdata?.reminderType,
        caqhUserName: userdata?.caqhUserName,
        caqhPassword: userdata?.caqhPassword,
        medicaidUsername: userdata?.medicaidUsername,
        MedicaidPassword: userdata?.MedicaidPassword,
        psychologyTodayUsername: userdata?.psychologyTodayUsername,
        therapyState: updateTherapyStateList,
        medicaidId: userdata?.medicaidId,
      };

      CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
        if (res.success) {
          setUser(res.data);
          setStatementFile({
            preview: "",
            preview2: "",
            raw: "",
          });
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          setShowEditProfile(false);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsUpating(false);
      });
    } else if (selectedProfessionsLicensDetails?._id && profession?._id) {
      if ((!dateValue as any) && !userdata?.dateOfBirth) {
        toast.error("Date of birth is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!userdata?.primaryPhone) {
        toast.error("Primary Phone is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!userdata?.primaryPhone.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.zipCode.match(zipcode)) {
        toast.error("Please enter valid zipcode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.state) {
        toast.error("No state found matching.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.socialSecurity && userdata?.socialSecurity?.length !== 9) {
        toast.error("Incomplete social security number", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.socialSecurity && !userdata?.socialSecurity?.match(digit)) {
        toast.error("Please enter only numbers for social security number.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!selectedTimezone || selectedTimezone == "-1") {
        toast.error("Please select a timezone!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else {
        setIsUpating(true);
        const updatedUser: UserDetails2 = {
          firstname: userdata?.firstname,
          lastname: userdata?.lastname,
          middleInitials: userdata?.middleInitials,
          username: userdata?.username,
          gender: userdata?.gender,
          dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
          email: userdata?.email,
          ethnicityId: (setEthenicity as any) || userdata?.ethnicityId?._id,
          description: userdata?.description,
          deletingPhotoId: "none",
          deletingVideoId: "none",
          deletingStatementId: userdata?.disclosureStatementId?._id || "none",
          profession: profession?._id || userdata?.profession?._id,
          professionLicense: selectedProfessionsLicensDetails?._id,
          yearsOfExperience: expereinceYears || userdata?.yearsOfExperience,
          experiencedIn: selectedExpTags.map(tag => {
            return tag.label;
          }),
          insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
            return tag.label;
          }),
          workingHours: workingHours,
          socialSecurity: userdata?.socialSecurity,
          cAQH: userdata?.cAQH,
          nPI1: userdata?.nPI1,
          taxIdentification: userdata?.taxIdentification,
          license: userdata?.license,
          issueDate: (idDateValue as any) || userdata?.issueDate,
          expirationDate: (edDateValue as any) || userdata?.expirationDate,
          schoolName: userdata?.schoolName,
          dateOfGraduation: (dogDateValue as any) || userdata?.dateOfGraduation,
          schoolStreetAddress: userdata?.schoolStreetAddress,
          schoolCity: userdata?.schoolCity,
          schoolState: userdata?.schoolState,
          schoolZipCode: userdata?.schoolZipCode,
          taxonomyCode: userdata?.taxonomyCode,
          malpracticePolicy: userdata?.malpracticePolicy,
          malpracticeExpirationDate: (medDateValue as any) || userdata?.malpracticeExpirationDate,
          primaryPhone: userdata?.primaryPhone,
          streetAddress: userdata?.streetAddress,
          city: userdata?.city,
          state: userdata?.state,
          zipCode: userdata?.zipCode,
          timeZone: selectedTimezone,
          googleCalendarAccess: isgoogleCalendarAccess,
          caqhUserName: userdata?.caqhUserName,
          caqhPassword: userdata?.caqhPassword,
          medicaidUsername: userdata?.medicaidUsername,
          MedicaidPassword: userdata?.MedicaidPassword,
          psychologyTodayUsername: userdata?.psychologyTodayUsername,
          psychologyTodayPassword: userdata?.psychologyTodayPassword,
          therapyState: updateTherapyStateList,
          medicaidId: userdata?.medicaidId,
        };

        CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
          if (res.success) {
            setUser(res.data);
            setStatementFile({
              preview: "",
              preview2: "",
              raw: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setShowEditProfile(false);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          setIsUpating(false);
        });
      }
    } else if (selectedProfessionsLicensDetails?._id == undefined && profession?._id) {
      if ((!dateValue as any) && !userdata?.dateOfBirth) {
        toast.error("Date of birth is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!userdata?.primaryPhone) {
        toast.error("Primary Phone is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!userdata?.primaryPhone.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.zipCode.match(zipcode)) {
        toast.error("Please enter valid zipcode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.state) {
        toast.error("No state found matching.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.socialSecurity && userdata?.socialSecurity?.length !== 9) {
        toast.error("Incomplete social security number", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.socialSecurity && !userdata?.socialSecurity?.match(digit)) {
        toast.error("Please enter only numbers for social security number.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!selectedTimezone || selectedTimezone == "-1") {
        toast.error("Please select a timezone!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else {
        setIsUpating(true);
        const updatedUser: UserDetails2 = {
          firstname: userdata?.firstname,
          lastname: userdata?.lastname,
          middleInitials: userdata?.middleInitials,
          username: userdata?.username,
          gender: userdata?.gender,
          dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
          email: userdata?.email,
          ethnicityId: (setEthenicity as any) || userdata?.ethnicityId?._id,
          description: userdata?.description,
          deletingPhotoId: "none",
          deletingVideoId: "none",
          deletingStatementId: userdata?.disclosureStatementId?._id || "none",
          profession: profession?._id || userdata?.profession?._id,
          yearsOfExperience: expereinceYears || userdata?.yearsOfExperience,
          experiencedIn: selectedExpTags.map(tag => {
            return tag.label;
          }),
          insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
            return tag.label;
          }),
          workingHours: workingHours,
          socialSecurity: userdata?.socialSecurity,
          cAQH: userdata?.cAQH,
          nPI1: userdata?.nPI1,
          taxIdentification: userdata?.taxIdentification,
          license: userdata?.license,
          issueDate: (idDateValue as any) || userdata?.issueDate,
          expirationDate: (edDateValue as any) || userdata?.expirationDate,
          schoolName: userdata?.schoolName,
          dateOfGraduation: (dogDateValue as any) || userdata?.dateOfGraduation,
          schoolStreetAddress: userdata?.schoolStreetAddress,
          schoolCity: userdata?.schoolCity,
          schoolState: userdata?.schoolState,
          schoolZipCode: userdata?.schoolZipCode,
          taxonomyCode: userdata?.taxonomyCode,
          malpracticePolicy: userdata?.malpracticePolicy,
          malpracticeExpirationDate: (medDateValue as any) || userdata?.malpracticeExpirationDate,
          primaryPhone: userdata?.primaryPhone,
          streetAddress: userdata?.streetAddress,
          city: userdata?.city,
          state: userdata?.state,
          zipCode: userdata?.zipCode,
          timeZone: selectedTimezone,
          googleCalendarAccess: isgoogleCalendarAccess,
          caqhUserName: userdata?.caqhUserName,
          caqhPassword: userdata?.caqhPassword,
          medicaidUsername: userdata?.medicaidUsername,
          MedicaidPassword: userdata?.MedicaidPassword,
          psychologyTodayUsername: userdata?.psychologyTodayUsername,
          psychologyTodayPassword: userdata?.psychologyTodayPassword,
          therapyState: updateTherapyStateList,
          medicaidId: userdata?.medicaidId,
        };

        CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
          if (res.success) {
            setUser(res.data);
            setStatementFile({
              preview: "",
              preview2: "",
              raw: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setShowEditProfile(false);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          setIsUpating(false);
        });
      }
    } else {
      if ((!dateValue as any) && !userdata?.dateOfBirth) {
        toast.error("Date of birth is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!userdata?.primaryPhone) {
        toast.error("Primary Phone is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!userdata?.primaryPhone.match(phoneno)) {
        toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.zipCode.match(zipcode)) {
        toast.error("Please enter valid zipcode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.state) {
        toast.error("No state found matching.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.socialSecurity && userdata?.socialSecurity?.length !== 9) {
        toast.error("Social security number should be 9 characters long. ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (userdata?.socialSecurity && !userdata?.socialSecurity?.match(digit)) {
        toast.error("Please enter only numbers for social security number.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else if (!selectedTimezone || selectedTimezone == "-1") {
        toast.error("Please select a timezone!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        return false;
      } else {
        setIsUpating(true);
        const updatedUser: UserDetails2 = {
          firstname: userdata?.firstname,
          lastname: userdata?.lastname,
          middleInitials: userdata?.middleInitials,
          username: userdata?.username,
          gender: userdata?.gender,
          dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
          email: userdata?.email,
          ethnicityId: (setEthenicity as any) || userdata?.ethnicityId?._id,
          description: userdata?.description,
          deletingPhotoId: "none",
          deletingVideoId: "none",
          deletingStatementId: "none",
          profession: profession?._id || userdata?.profession?._id,
          professionLicense: userdata?.professionLicense?._id,
          yearsOfExperience: expereinceYears || userdata?.yearsOfExperience,
          experiencedIn: selectedExpTags.map(tag => {
            return tag.label;
          }),
          insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
            return tag.label;
          }),
          workingHours: workingHours,
          socialSecurity: userdata?.socialSecurity,
          cAQH: userdata?.cAQH,
          nPI1: userdata?.nPI1,
          taxIdentification: userdata?.taxIdentification,
          license: userdata?.license,
          issueDate: (idDateValue as any) || userdata?.issueDate,
          expirationDate: (edDateValue as any) || userdata?.expirationDate,
          schoolName: userdata?.schoolName,
          dateOfGraduation: (dogDateValue as any) || userdata?.dateOfGraduation,
          schoolStreetAddress: userdata?.schoolStreetAddress,
          schoolCity: userdata?.schoolCity,
          schoolState: userdata?.schoolState,
          schoolZipCode: userdata?.schoolZipCode,
          taxonomyCode: userdata?.taxonomyCode,
          malpracticePolicy: userdata?.malpracticePolicy,
          malpracticeExpirationDate: (medDateValue as any) || userdata?.malpracticeExpirationDate,
          primaryPhone: userdata?.primaryPhone,
          streetAddress: userdata?.streetAddress,
          city: userdata?.city,
          state: userdata?.state,
          zipCode: userdata?.zipCode,
          timeZone: selectedTimezone,
          googleCalendarAccess: isgoogleCalendarAccess,
          reminderTime: userdata?.reminderTime,
          reminderType: userdata?.reminderType,
          caqhUserName: userdata?.caqhUserName,
          caqhPassword: userdata?.caqhPassword,
          medicaidUsername: userdata?.medicaidUsername,
          MedicaidPassword: userdata?.MedicaidPassword,
          psychologyTodayUsername: userdata?.psychologyTodayUsername,
          psychologyTodayPassword: userdata?.psychologyTodayPassword,
          therapyState: updateTherapyStateList,
          medicaidId: userdata?.medicaidId,
        };

        CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
          if (res.success) {
            setUser(res.data);
            setStatementFile({
              preview: "",
              preview2: "",
              raw: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });

            setShowEditProfile(false);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          setIsUpating(false);
        });
      }
    }
  };

  const updateTherapistCategorizationByType = async () => {
    let data = {
      therapistCategorizationByType: therapistCategorizationByType,
    };
    let response = await CommonService.updateTherapistCategorizationByType(data);
    if (!response.success) {
      toast.error(response.error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const beforeToday = (date: Date) => {
    return moment(date).isBefore(new Date());
  };

  const updateClientDetails = () => {
    const phoneno = /^\+1[0-9]{10}$/;
    const zipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if ((!dateValue as any) && !userdata?.dateOfBirth) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.zipCode.match(zipcode)) {
      toast.error("Please enter valid zipcode!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.zipCode !== null && userdata?.zipCode !== undefined && userdata?.zipCode.trim() !== "" && !userdata?.state) {
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.firstname) {
      toast.error("first name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.lastname) {
      toast.error("last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.gender) {
      toast.error("gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (!userdata?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.homePhone && !userdata?.homePhone?.match(phoneno)) {
      toast.error("Please enter valid home phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (userdata?.workPhone && !userdata?.workPhone?.match(phoneno)) {
      toast.error("Please enter valid work phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else {
      setIsUpating(true);

      const updatedUser: UserDetails = {
        firstname: userdata?.firstname,
        lastname: userdata?.lastname,
        username: userdata?.username,
        gender: userdata?.gender,
        dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
        email: userdata?.email,
        ethnicityId: (setEthenicity as any) || userdata?.ethnicityId?._id,
        description: userdata?.description,
        incognito: userdata?.incognito as any,
        middleInitials: userdata?.middleInitials,
        maritalStatus: userdata?.maritalStatus,
        streetAddress: userdata?.streetAddress,
        unit: userdata?.unit,
        city: userdata?.city,
        state: userdata?.state,
        zipCode: userdata?.zipCode,
        primaryPhone: userdata?.primaryPhone,
        homePhone: userdata?.homePhone,
        workPhone: userdata?.workPhone,
        voiceMail: userdata?.voiceMail,
      };

      CommonService.updateClientProfile(updatedUser).then(res => {
        if (res.success) {
          setUser(res.data);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setShowEditProfile(false);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        setIsUpating(false);
      });
    }
  };

  const selectEducationFile = (e: any) => {
    if (e.target.files.length) {
      setEducationFile({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const selectStatementFile = (e: any) => {
    if (e.target.files.length) {
      setStatementFile({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const saveEducationDetails = () => {
    if (!educationRef.current.university) {
      toast.error("Please enter university name !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!educationRef.current.degree) {
      toast.error("Please enter degree name !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!educationRef.current.startYear) {
      toast.error("Please select start year !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!educationRef.current.endYear) {
      toast.error("Please select end year !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (educationRef.current.startYear >= educationRef.current.endYear) {
      toast.error("Current startYear must be lesser than current endYear", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    const educationData = { ...educationRef.current, userId: user?._id };

    CommonService.educationDetails(educationData, educationFileRef.current.raw).then(res => {
      if (res.success) {
        setEducationData(res.data);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setModalCenter(false);
        viewAllEducations();
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const removeEducationSelectedImage = (value: any) => {
    if (educationRef.current.uploadId) {
      setDeletingUploadId([...removeArrayRef.current, value.toString()]);
      const removeLocalImage = uploadsRef.current.filter((val: any) => {
        return value !== val._id;
      });
      setUploads(removeLocalImage);
    }
  };

  const selectLicensesFile = (e: any) => {
    if (e.target.files.length) {
      setLicensesFile({
        preview: URL.createObjectURL(e.target.files[0]),
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const removeLicensesSelectedImage = (value: any) => {
    if (licensesRef.current.uploadId) {
      setDeletingUploadId([...removeArrayRef.current, value.toString()]);
      const removeLocalImage = uploadsRef.current.filter((val: any) => {
        return value !== val._id;
      });
      setUploads(removeLocalImage);
    }
  };

  const updateEducationDetails = () => {
    if (!educationRef.current.university) {
      toast.error("Please enter university name !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!educationRef.current.degree) {
      toast.error("Please enter degree name !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!educationRef.current.startYear) {
      toast.error("Please select start year !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!educationRef.current.endYear) {
      toast.error("Please select end year !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    const educationData = { ...educationRef.current, userId: user?._id, educationId: educationPopupId.current, deletingUploadIds: removeArrayRef.current };

    CommonService.updateEducationDetails(educationData, educationFileRef.current.raw).then(res => {
      if (res.success) {
        setEducationData(res.data);
        viewAllEducations();
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setModalCenter(false);
        setDeletingUploadId([]);
        setEducationData({ degree: "", university: "", startYear: "", endYear: "", fieldOfStudy: "" });
        setEducationFile({
          preview: "",
          preview2: "",
          raw: "",
        });
        setEducationId("");
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const options = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };

  const deleteEducationDetails = async (value: any) => {
    const result = await confirm("Are you sure you want to delete this educational detail?", options);

    if (result) {
      CommonService.deleteEducationData(value).then(res => {
        if (res.success) {
          viewAllEducations();
          toast.success("Education Details Deleted!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });

      return false;
    }

    return false;
  };

  const saveLicensesDetails = () => {
    const licensesData = { ...licensesRef.current, userId: user?._id };

    CommonService.licensesDetails(licensesData, licensesFileRef.current.raw).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setLicensesData(res.data);
        viewAllLicenses();
        setShowLicenses(false);
        setModalCenter2(false);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const updateLicensesDetails = () => {
    const licensesData = { ...licensesRef.current, userId: user?._id, licenseId: licensesPopupId.current, deletingUploadIds: removeArrayRef.current };

    CommonService.updatelicensesDetails(licensesData, licensesFileRef.current.raw).then(res => {
      if (res.success) {
        setLicensesData(res.data);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        viewAllLicenses();
        setShowLicenses(false);
        setModalCenter2(false);
        setDeletingUploadId([]);
        setLicensesId("");
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const viewAllLicenses = () => {
    setIsLoading(true);
    TherapistService.getAllLicenses(user?._id as any).then(res => {
      setLicensesDetailsList(res.data);
      setIsLoading(false);
      if (licensesPopupId.current) {
        const selectedCategory = res.data?.filter(c => c._id === licensesPopupId.current);
        const selectedCategorydata = selectedCategory[0];
        setLicensesData(selectedCategorydata);
        setUploads(selectedCategorydata?.uploadId as []);
      }
    });
  };

  const deleteLicensesDetails = async (value: any) => {
    const result = await confirm("Are you sure you want to delete this license detail?", options);
    if (result) {
      CommonService.deleteLicensesData(value).then(res => {
        if (res.success) {
          viewAllLicenses();
          toast.success("License Details Deleted!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
      return false;
    }
  };

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }

  function handleIdDateUpdate(e: any) {
    const dateValue1 = e.target.value;
    setIdDateValue(dateValue1);
  }

  function handleDogDateUpdate(e: any) {
    const dateValue2 = e.target.value;
    setDogDateValue(dateValue2);
  }

  function handleEdDateUpdate(e: any) {
    const dateValue3 = e.target.value;
    setEdDateValue(dateValue3);
  }

  function handleMedDateUpdate(e: any) {
    const dateValue4 = e.target.value;
    setMedDateValue(dateValue4);
  }

  const setChangePasswordBtn = () => {
    CommonService.changePassword(changePassword).then(res => {
      if (res.success) {
        setChangePassword({ newPassword: "", confirmPassword: "", oldPassword: "" });
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const setChangePinBtn = () => {
    if (!newPinNumber || newPinNumber?.length != 4) {
      toast.error("Please enter new pin number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    const data = {
      newPinNumber: newPinNumber,
      password: changePinNumber.password,
    };

    TherapistService.updatePinNumber(data).then(res => {
      if (res.success) {
        setUser(res.data);
        setChangePinNumber({ ...changePinNumber, password: "" });
        setNewPinNumber("");

        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const updateTimeSlots = () => {
    if (newWorkingHour.day == "" || newWorkingHour.startTime == "" || newWorkingHour.endTime == "") {
      toast.error("Please fill all working hours details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else if (Util.convertUTCDateToLocalDate(newWorkingHour.startTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) {
      toast.error("Your end time should be after the start time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const overlap = workingHours.some(existingHour => {
        return (
          existingHour.day === newWorkingHour.day &&
          ((Util.convertUTCDateToLocalDate(existingHour.startTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
            Util.convertUTCDateToLocalDate(existingHour.startTime) < Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) ||
            (Util.convertUTCDateToLocalDate(existingHour.endTime) > Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
              Util.convertUTCDateToLocalDate(existingHour.endTime) <= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)) ||
            (Util.convertUTCDateToLocalDate(existingHour.startTime) <= Util.convertUTCDateToLocalDate(newWorkingHour.startTime) &&
              Util.convertUTCDateToLocalDate(existingHour.endTime) >= Util.convertUTCDateToLocalDate(newWorkingHour.endTime)))
        );
      });

      if (overlap) {
        toast.error("Working hours overlap with existing hours.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        setWorkingHours([...workingHours, newWorkingHour]);
        setNewWorkingHour({ ...newWorkingHour, day: "" });
        togCenterWorkingHoursClose();
      }
    }
  };

  const saveFilterWorkingHours = (value: any) => {
    setWorkingHours(workingHours?.filter(deleteValue => value !== (deleteValue as any)));
    setSelectedDates([...selectedDates, value.day]);
  };

  const project = () => {
    switch (educationFileRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit p-2"></i>;
      default:
        setEducationFile({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const statementFiles = () => {
    switch (statementFileRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        setStatementFile({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const licensesProject = () => {
    switch (licensesFileRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        setLicensesFile({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  const db = moment.utc(dateValue || user?.dateOfBirth).format("YYYY-MM-DD");
  const id = moment.utc(idDateValue || user?.issueDate).format("YYYY-MM-DD");
  const dog = moment.utc(dogDateValue || user?.dateOfGraduation).format("YYYY-MM-DD");
  const ed = moment.utc(edDateValue || user?.expirationDate).format("YYYY-MM-DD");
  const med = moment.utc(medDateValue || user?.malpracticeExpirationDate).format("YYYY-MM-DD");

  const toDay = moment();
  const birthDate = moment.utc(user?.dateOfBirth);

  const viewAllThemeImages = () => {
    CommonService.getThemesImages().then(res => {
      setThemeImages(res.data);
    });
  };

  function handlePostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setUserdata({ ...userdata, state: selectedState ? selectedState : "", zipCode: e });
  }

  let state;

  function getStateByZipCode(zipString: string) {
    if (typeof zipString !== "string") {
      toast.error("Must pass the zipcode as a string.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (zipString.length !== 5) {
      return false;
    }

    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      state = "Colorado";
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      state = "Florida";
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      state = "Maryland";
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode == 5501 || zipcode == 5544) {
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      state = "New Mexico";
    } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      state = "Ohio";
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      state = "Tennessee";
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      state = "Vermont";
    } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode == 20598) {
      state = "Virginia";
    } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      state = "Wyoming";
    } else {
      state = "";
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    return state;
  }

  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          clientId: environment.google_calendar_client_id,
          scope: "https://www.googleapis.com/auth/calendar openid email profile",
        })
        .then(() => {
          const googleAuthInstance = gapi.auth2.getAuthInstance();
          if (googleAuthInstance.isSignedIn.get()) {
            const googleUser = googleAuthInstance.currentUser.get();
            if (googleUser) {
              const userEmail = googleUser.getBasicProfile().getEmail();
              setGoogleUserEmail(userEmail);
            } else {
              console.log("Google user is undefined.");
            }
          }
        });
    };

    gapi.load("client:auth2", initClient);
  }, []);

  const responseGoogle = (response: any) => {
    console.log("Response from google ", response);

    if (response) {
      CommonService.getGoogleCalendarRefreshToken(response)
        .then(res => {
          console.log(res);

          if (res) {
            setgoogleCalendarAccess(true);
            toast.success("Google calendar sign in success.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          } else {
            toast.error("Google calendar sign in fail.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        })
        .catch(err => {
          toast.error("Google calendar sign in fail.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        });
    }
  };

  const responseError = (error: any) => {
    toast.error("Google calendar sign in fail.", {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
    });
  };

  const logoutCalendar = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to sign out with google calendar sync ?",
      showCancelButton: true,
      confirmButtonText: "Sign out",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        CommonService.googleCalendarLogout()
          .then(res => {
            if (res) {
              setgoogleCalendarAccess(false);
              toast.success("Google calendar sign out success.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            } else {
              toast.error("Google calendar sign out fail.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          })
          .catch(err => {
            toast.error("Google calendar sign out fail.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          });
      }
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const toggleShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleCopyURL = () => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-user-details/" + user?._id;
    navigator.clipboard
      .writeText(userPublicUrl)
      .then(() => {
        toast.success("Link copied to clipboard! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      })
      .catch(() => {
        toast.error("Link is not copied try again! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
  };

  const handleCopaymentAmount = (copaymentData: any) => {
    console.log("copaymentData", copaymentData);
  };
  const changeTherapistCategorizationByType = (value: string) => {
    setTherapistCategorizationByType(value);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Lavni" breadcrumbItem="Profile Details" />*/}
          <Row>
            <Col xl={12}>
              <Card style={{ minHeight: "67vh" }}>
                <CardBody>
                  <Row>
                    <Col xl="3">
                      <Nav className="flex-column vertical-icon mt-5 pl-5 pr-6">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "1",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("1");
                            }}
                          >
                            <i className="fas fa-home"></i> Personal details
                          </NavLink>
                        </NavItem>

                        {user?.role == "THERAPIST" && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                "mb-2": true,
                                active: verticalActiveTabWithIcon === "8",
                              })}
                              onClick={() => {
                                toggleVerticalIcon("8");
                              }}
                            >
                              <i className="fa fa-dollar-sign"></i> Stripe Details
                            </NavLink>
                          </NavItem>
                        )}

                        {user?.role == "CLIENT" && user?.insuranceId && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                "mb-2": true,
                                active: verticalActiveTabWithIcon === "9",
                              })}
                              onClick={() => {
                                toggleVerticalIcon("9");
                              }}
                            >
                              <i className="fa fa-dollar-sign"></i> Co-payment
                            </NavLink>
                          </NavItem>
                        )}

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "4",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("4");
                            }}
                          >
                            <i className="fas fa-key"></i> Change Password{user?.role == Role.THERAPIST && "/Pin"}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "5",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("5");
                            }}
                          >
                            <i className="fa fa-cog"></i> Notification Settings
                          </NavLink>
                        </NavItem>

                        {user?.role == "THERAPIST" && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                "mb-2": true,
                                active: verticalActiveTabWithIcon === "6",
                              })}
                              onClick={() => {
                                toggleVerticalIcon("6");
                              }}
                            >
                              <i className="fas fa-user-graduate"></i> Education
                            </NavLink>
                          </NavItem>
                        )}

                        {user?.role == "THERAPIST" && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                "mb-2": true,
                                active: verticalActiveTabWithIcon === "7",
                              })}
                              onClick={() => {
                                toggleVerticalIcon("7");
                              }}
                            >
                              <i className="fas fa-star"></i> Reviews
                            </NavLink>
                          </NavItem>
                        )}

                        {user?.role == "CLIENT" && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                "mb-2": true,
                                active: verticalActiveTabWithIcon === "2",
                              })}
                              onClick={() => {
                                toggleVerticalIcon("2");
                              }}
                            >
                              <i className="fas fa-star"></i> Insurance
                            </NavLink>
                          </NavItem>
                        )}

                        {user?.role == "THERAPIST" && (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                "mb-2": true,
                                active: verticalActiveTabWithIcon === "10",
                              })}
                              onClick={() => {
                                toggleVerticalIcon("10");
                              }}
                            >
                              <i className="fas fa-file"></i> Notes Settings
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Col>

                    <Col xl="9">
                      <TabContent activeTab={verticalActiveTabWithIcon} className="text-muted mt-4 mt-xl-0">
                        <TabPane tabId="1">
                          <Row>
                            <Row>
                              <Col xl="12">
                                <div
                                  className="profileImage imageFit"
                                  style={{
                                    backgroundImage:
                                      user?.coverPhotoId == null || user?.coverPhotoId == undefined
                                        ? `url(${profileBackground})`
                                        : `url(${Util.fileURL(user?.coverPhotoId?._id)})`,
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      togCenterCoverImage();
                                    }}
                                    className=" imageFit cursor-pointer w-10 h-10 rounded-full absolute bottom-2 right-2 justify-center items-center text-center flex"
                                    style={{
                                      borderRadius: "9999px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "#DDDDDD",
                                      cursor: "pointer",
                                      width: "2.5rem",
                                      height: "2.5rem",
                                      display: "flex",
                                      position: "absolute",
                                      textAlign: "center",
                                      right: "1rem",
                                      top: "1rem",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="18"
                                      viewBox="0 0 24 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="content-center items-center justify-center relative"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <path
                                        d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                                        fill="#FFFFFF"
                                      />
                                      <path
                                        d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                                        fill="#FFFFFF"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl={6}>
                                <div
                                  className="img-thumbnail imageFit rounded-circle imageFit avatar-2xl profile-user-wid-pro items-center margin_center mb-4 profileImageShow"
                                  style={{
                                    backgroundImage:
                                      user?.photoId == null || user?.photoId == undefined ? `url(${user1})` : `url("${Util.fileURL(user?.photoId?._id)}")`,
                                    borderRadius: "10px",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    className="cursor-pointer imageFit w-10 h-10 rounded-full absolute bottom-2 right-2 justify-center items-center text-center flex"
                                    style={{
                                      borderRadius: "9999px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "#DDDDDD",
                                      cursor: "pointer",
                                      width: "2.5rem",
                                      height: "2.5rem",
                                      display: "flex",
                                      position: "absolute",
                                      textAlign: "center",
                                      right: "0rem",
                                    }}
                                  >
                                    <label
                                      htmlFor="file-upload-profile"
                                      className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                                      style={{
                                        margin: "auto",
                                        borderRadius: "0.25rem",
                                        cursor: "pointer",
                                        display: "flex",
                                        zIndex: "10",
                                        position: "relative",
                                      }}
                                    >
                                      <svg
                                        width="24"
                                        height="18"
                                        viewBox="0 0 24 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="content-center items-center justify-center relative"
                                        style={{
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "relative",
                                        }}
                                      >
                                        <path
                                          d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                                          fill="#FFFFFF"
                                        />
                                        <path
                                          d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                                          fill="#FFFFFF"
                                        />
                                      </svg>

                                      <input
                                        id="file-upload-profile"
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                text/plain, image/doc"
                                        onChange={handleFileChange}
                                        multiple
                                      />
                                    </label>
                                  </div>
                                </div>

                                <div>
                                  <Modal
                                    isOpen={modalOpen}
                                    toggle={toggleModal}
                                    centered
                                    style={{
                                      margin: "auto",
                                    }}
                                  >
                                    <ModalHeader toggle={toggleModal}>Profile Image</ModalHeader>
                                    <ModalBody>
                                      <div style={{ width: "100%", height: "250px" }}>
                                        <Cropper
                                          image={image.preview}
                                          crop={crop}
                                          zoom={zoom}
                                          aspect={1 / 1}
                                          cropShape="round"
                                          showGrid={false}
                                          onCropChange={setCrop}
                                          onCropComplete={handleCrop}
                                          initialCroppedAreaPercentages={initialCroppedArea}
                                          // cropSize={{ height: 300, width: 300 }}
                                        />
                                      </div>
                                    </ModalBody>
                                    <ModalFooter>
                                      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                                        <Slider value={zoom} min={1} max={3} step={0.1} onChange={handleZoomChange} aria-labelledby="Zoom" />
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", gap: "10px", width: "100%" }}>
                                        <Button color="primary" onClick={selectFile} style={{ flex: 1, maxWidth: "100px" }}>
                                          Save
                                        </Button>
                                        <Button color="secondary" onClick={toggleModal} onClose={onClose} style={{ flex: 1, maxWidth: "100px" }}>
                                          Cancel
                                        </Button>
                                      </div>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              </Col>

                              {user?.role == "THERAPIST" && (
                                <Col xl={6}>
                                  <div className="profile-video0">
                                    <div className="profile-video1">
                                      <label
                                        htmlFor="file-upload-video"
                                        className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                                        style={{
                                          borderRadius: "0.25rem",
                                          cursor: "pointer",
                                          display: "flex",
                                          zIndex: "10",
                                          position: "relative",
                                        }}
                                      >
                                        {user?.vimeoId && (
                                          <div
                                            className="cursor-pointer imageFit w-10 h-10 mt-3  mr-5 rounded-full absolute bottom-2 right-2 justify-center items-center text-center flex"
                                            style={{
                                              borderRadius: "9999px",
                                              backgroundColor: "#DDDDDD",
                                              cursor: "pointer",
                                              width: "2.5rem",
                                              height: "2.5rem",
                                              display: "flex",
                                              textAlign: "center",
                                            }}
                                          >
                                            <i
                                              className="fa fa-video-camera content-center items-center justify-center relative"
                                              style={{ color: "#FFFFFF", fontSize: "18px" }}
                                            ></i>
                                          </div>
                                        )}

                                        <input
                                          id="file-upload-video"
                                          type="file"
                                          className=" btn-video relative  mt-3 mb-4"
                                          style={{
                                            bottom: "0",
                                            display: "none",
                                          }}
                                          accept="video/mp4,video/x-m4v,video/*"
                                          onChange={selectVideoFile}
                                        />
                                      </label>

                                      {user?.vimeoId && (
                                        <div
                                          className="btn-video relative mt-3 pl-3 pr-3 pt-2 mb-4 cursor-pointer"
                                          style={{
                                            bottom: "0",
                                          }}
                                          onClick={() => {
                                            togCenterVideo();
                                          }}
                                        >
                                          <img src={videoIcon} className="mr-2" />
                                          Watch Video
                                        </div>
                                      )}

                                      <label
                                        htmlFor="file-upload-video"
                                        className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                                        style={{
                                          margin: "auto",
                                          borderRadius: "0.25rem",
                                          cursor: "pointer",
                                          display: "flex",
                                          zIndex: "10",
                                          position: "relative",
                                        }}
                                      >
                                        {!user?.vimeoId && (
                                          <div
                                            className={user?.vimeoId ? "btn-video relative p-1 mt-3 mb-4 " : "btn-video relative mt-3 mb-4 p-1"}
                                            style={{
                                              bottom: "0",
                                            }}
                                          >
                                            <img src={videoIcon} className="mr-1" />
                                            Upload Video
                                          </div>
                                        )}

                                        <input
                                          id="file-upload-video"
                                          type="file"
                                          className=" btn-video relative  mt-3 mb-4"
                                          style={{
                                            bottom: "0",
                                            display: "none",
                                          }}
                                          accept="video/mp4,video/x-m4v,video/*"
                                          onChange={selectVideoFile}
                                          multiple
                                        />
                                      </label>
                                    </div>

                                    {showProgress && (
                                      <div className="profile-video2">
                                        Uploading &nbsp; <span className="progress-color">{progress}%</span>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </Row>

                            <Row>
                              <Col xl={12}>
                                {!editProfile && (
                                  <button
                                    type="button"
                                    className="btn btn-primary relative mb-4 ms-2"
                                    style={{
                                      bottom: "0",
                                      float: "right",
                                    }}
                                    onClick={() => {
                                      setEditProfile();
                                    }}
                                  >
                                    <i className="fa fa-pencil-square-o mr-1 "></i>
                                    Edit Profile
                                  </button>
                                )}

                                {user?.role == "THERAPIST" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary relative mb-4 ms-2"
                                    style={{
                                      bottom: "0",
                                      float: "right",
                                    }}
                                    onClick={handleCopyURL}
                                  >
                                    <i className="fa fa-pencil-square-o mr-1"></i>
                                    Copy Referral Link
                                  </button>
                                )}

                                {editProfile && (
                                  <div>
                                    {user?.role == "THERAPIST" && (
                                      <button
                                        type="button"
                                        className={isUpating ? "btn btn-primary relative mb-4 ml-10 updating" : "btn btn-primary relative mb-4 ml-10"}
                                        style={{
                                          bottom: "0",
                                          float: "right",
                                        }}
                                        disabled={isUpating}
                                        onClick={() => {
                                          updateTherapistDetails();
                                        }}
                                      >
                                        {isUpating ? "Saving..." : "Save Changes"}
                                      </button>
                                    )}

                                    {user?.role == "CLIENT" && (
                                      <button
                                        type="button"
                                        className={isUpating ? "btn btn-primary relative mb-4 ml-10 updating" : "btn btn-primary relative mb-4 ml-10"}
                                        style={{
                                          bottom: "0",
                                          float: "right",
                                        }}
                                        disabled={isUpating}
                                        onClick={() => {
                                          updateClientDetails();
                                        }}
                                      >
                                        {isUpating ? "Saving..." : "Save Changes"}
                                      </button>
                                    )}

                                    <button
                                      type="button"
                                      className="btn btn-light  relative mr-2 mb-4"
                                      style={{
                                        bottom: "0",
                                        float: "right",
                                      }}
                                      onClick={() => {
                                        setShowEditProfile(false);
                                      }}
                                    >
                                      <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                                      Back
                                    </button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Row>

                          {modalcentervideo && (
                            <div
                              className="lightbox1"
                              onClick={() => {
                                setModalCenterVideo(false);
                              }}
                            >
                              <div
                                id="videoModal1"
                                className="modal1 hide1 fade1 in1"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="videoModalLabel"
                                aria-hidden="false"
                              >
                                <div className="modal-body1">
                                  <iframe
                                    src={"https://player.vimeo.com/video/" + user?.vimeoId}
                                    width="800"
                                    className="videoSize"
                                    height="400"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                    title="Lavni Health"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          )}

                          <Modal
                            isOpen={modalcentercoverimage}
                            toggle={() => {
                              togCenterCoverImage();
                            }}
                            centered
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0">
                                <br />
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setModalCenterCoverImage(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span>&times;</span>
                              </button>
                            </div>

                            {modalcentercovertheme && (
                              <Row>
                                {themeImages.length == 0 && (
                                  <div className="font-size-14 mb-5 mt-1" style={{ textAlign: "center" }}>
                                    No theme images to display.
                                  </div>
                                )}
                                {themeImages?.map((img: any, i: any) => {
                                  return (
                                    <Col xl={6} key={i} className="p-4">
                                      <div className="themeImgBorder">
                                        <div
                                          className="profileThemeImage  imageFit cursor-pointer"
                                          style={{
                                            backgroundImage: img == null || img == undefined ? `url(${profileBackground})` : `url(${Util.fileURL(img?._id)})`,
                                          }}
                                          onClick={() => selectCoverTherapistFile(img?._id)}
                                        ></div>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            )}

                            {!modalcentercovertheme && (
                              <div>
                                <Row>
                                  <Col xl={3}></Col>
                                  <Col xl={6}>
                                    <div
                                      className="btn-video relative mt-3 pl-3 pr-3 pt-2 pb-2 mb-2 cursor-pointer"
                                      style={{
                                        bottom: "0",
                                        textAlign: "center",
                                      }}
                                      onClick={() => {
                                        togCenterCoverTheme();
                                      }}
                                    >
                                      Select Theme
                                    </div>
                                  </Col>
                                  <Col xl={3}></Col>
                                </Row>
                                <h5 className="text-center">Or</h5>

                                <Row>
                                  <Col xl={3}></Col>
                                  <Col xl={6}>
                                    <label
                                      htmlFor="file-upload"
                                      className="file-upload relative flex flex-col text-gray-400 z-10  rounded cursor-pointer"
                                      style={{
                                        margin: "auto",
                                        borderRadius: "0.25rem",
                                        cursor: "pointer",
                                        display: "flex",
                                        zIndex: "10",
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        className="btn-video relative mt-2 pl-3 pr-3 pt-2 pb-2 mb-4 cursor-pointer"
                                        style={{
                                          bottom: "0",
                                          textAlign: "center",
                                        }}
                                      >
                                        <svg
                                          width="20"
                                          height="18"
                                          viewBox="0 0 24 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="mr-2 content-center items-center justify-center relative"
                                          style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            position: "relative",
                                          }}
                                        >
                                          <path
                                            d="M12 16C14.7614 16 17 13.7614 17 11C17 8.23858 14.7614 6 12 6C9.23858 6 7 8.23858 7 11C7 13.7614 9.23858 16 12 16Z"
                                            fill="#fd7f00"
                                          />
                                          <path
                                            d="M21.9999 2.99999H18.8286C18.4277 2.99999 18.0512 2.84376 17.7676 2.56054L15.9394 0.732421C15.4673 0.260249 14.8393 0 14.1714 0H9.82859C9.16062 0 8.53269 0.260249 8.06052 0.732421L6.23239 2.56054C5.94871 2.84376 5.57226 2.99999 5.17138 2.99999H2.00001C0.896951 2.99999 0 3.89695 0 4.99996V17.9999C0 19.1029 0.896951 19.9999 2.00001 19.9999H22C23.103 19.9999 24 19.103 24 17.9999V4.99996C24 3.89695 23.103 2.99999 21.9999 2.99999ZM12 16.9999C8.69141 16.9999 5.99999 14.3085 5.99999 10.9999C5.99999 7.69138 8.69141 4.99996 12 4.99996C15.3085 4.99996 18 7.69138 18 10.9999C18 14.3085 15.3085 16.9999 12 16.9999ZM20 7.99995C19.4487 7.99995 19 7.55122 19 6.99997C19 6.44872 19.4487 5.99999 20 5.99999C20.5512 5.99999 21 6.44872 21 6.99997C21 7.55122 20.5512 7.99995 20 7.99995Z"
                                            fill="#fd7f00"
                                          />
                                        </svg>
                                        Upload Image
                                      </div>
                                      <input
                                        id="file-upload"
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                text/plain, image/doc"
                                        onChange={selectCoverFile}
                                        multiple
                                      />
                                    </label>
                                  </Col>
                                  <Col xl={3}></Col>
                                </Row>
                              </div>
                            )}
                          </Modal>

                          <Row>
                            {!editProfile && (
                              <>
                                <Row>
                                  {user?.role == "CLIENT" && (
                                    <Col xl={12}>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-17 profileFont mb-3">About Me</h5>
                                      </div>
                                      <div className="card-heading mb-2 pr-5">
                                        <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userdata?.description || "-" }} />
                                      </div>
                                    </Col>
                                  )}
                                  {user?.role == "THERAPIST" && (
                                    <Col xl={4}>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-17 profileFont mb-3">About Me</h5>
                                      </div>
                                      <div className="card-heading mb-4 pr-5">
                                        <h5 className="font-size-14 mb-4" dangerouslySetInnerHTML={{ __html: userdata?.description || "-" }} />
                                      </div>
                                    </Col>
                                  )}
                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-3">Working days and hours</h5>
                                        </div>
                                        <div className="card-heading mb-4">
                                          {user?.workingHours?.length == 0 && "-"}
                                          {workingHours &&
                                            workingHours.map((opt, index) => (
                                              <h5 className="font-size-14 mb-2" key={index}>
                                                {opt.day} | {Util.convertUTCDateToLocalDate12Hour(opt.startTime)} -{" "}
                                                {Util.convertUTCDateToLocalDate12Hour(opt.endTime)}
                                              </h5>
                                            ))}
                                        </div>
                                      </div>
                                    )}
                                  </Col>

                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-3">Professional experience</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          {user?.experiencedIn?.length == 0 && "-"}
                                          {user?.experiencedIn?.map((ex: any) => {
                                            return (
                                              <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                                {ex.experienceTag}
                                              </Link>
                                            );
                                          })}
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Profession</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.profession?.name || "-"}</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Therapist Category</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">
                                            {therapistCategorizationByType == "ASSOCIATE"
                                              ? "Associate Therapist"
                                              : therapistCategorizationByType == "LICENSED"
                                              ? "Fully Licenced Therapist"
                                              : "-"}
                                          </h5>
                                        </div>
                                        {user?.professionLicense?._id && (
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Profession License</h5>
                                            </div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">{user?.professionLicense?.name || "-"}</h5>
                                            </div>
                                          </div>
                                        )}

                                        {user?.disclosureStatementId !== null && (
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">Your professional disclosure statement:</h5>
                                            </div>

                                            <div className="flex  mb-2">
                                              <div className="mr-2">
                                                <a
                                                  // to={{ pathname: Util.fileURL(user?.disclosureStatementId?.url) }}
                                                  href={user?.disclosureStatementId?.url}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  className="events-icon2"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {/* <FileThumbnail file={user?.disclosureStatementId} /> */}
                                                  {user?.disclosureStatementId?.originalName}
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-3 ">Time Zone</h5>
                                        </div>

                                        <h5 className="font-size-14 mb-2"> {user?.timeZone ? user?.timeZone : "Eastern Daylight Timezone"}</h5>
                                      </>
                                    )}
                                  </Col>

                                  <Col xl={4}></Col>

                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Selected Insurance Companies</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          {user?.insuranceCompanies?.length == 0 && "-"}
                                          {user?.insuranceCompanies?.map((ex: any) => {
                                            return (
                                              <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                                {ex.insuranceCompany}
                                              </Link>
                                            );
                                          })}
                                        </div>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}

                            {editProfile && (
                              <>
                                <Row>
                                  {user?.role == "CLIENT" && (
                                    <Col xl={12}>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-17 profileFont mb-3">
                                          About Me
                                          {userdata?.description?.length !== undefined && userdata?.description?.length !== 0 ? null : (
                                            <div className="tagError">Missing</div>
                                          )}
                                        </h5>
                                      </div>
                                      <div className="editor-area ck-content">
                                        <CKEditor
                                          editor={Editor}
                                          style={{
                                            maxHeight: "300px",
                                          }}
                                          onChange={(event: any, editor: { getData: () => any }) => {
                                            const Data = editor.getData();
                                            setUserdata({ ...userdata, description: Data });
                                          }}
                                          data={userdata?.description || ""}
                                          config={editorConfiguration}
                                        />
                                      </div>
                                    </Col>
                                  )}

                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-3">
                                            About Me
                                            {userdata?.description?.length !== undefined && userdata?.description?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>
                                        <CKEditor
                                          editor={Editor}
                                          style={{
                                            maxHeight: "300px",
                                          }}
                                          onChange={(event: any, editor: { getData: () => any }) => {
                                            const Data = editor.getData();
                                            setUserdata({ ...userdata, description: Data });
                                          }}
                                          data={userdata?.description || ""}
                                          config={editorConfiguration}
                                        />
                                      </div>
                                    )}
                                  </Col>

                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-3">
                                            Working days and hours
                                            {userdata?.workingHours?.length !== undefined && userdata?.workingHours?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="py-0 w-full mb-4" style={{ width: "100%" }}>
                                          {workingHours &&
                                            workingHours.map((opt, index) => (
                                              <Row className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2 timing" key={index}>
                                                <Col className="col-10">
                                                  {opt.day} | {Util.convertUTCDateToLocalDate12Hour(opt.startTime)} -{" "}
                                                  {Util.convertUTCDateToLocalDate12Hour(opt.endTime)}
                                                </Col>
                                                <Col className="col-2">
                                                  <i
                                                    onClick={() => saveFilterWorkingHours(opt as any)}
                                                    style={{ borderColor: "#414141" }}
                                                    className="fas fa-times-circle  cursor_pointer"
                                                  ></i>
                                                </Col>
                                              </Row>
                                            ))}
                                        </div>

                                        <div className="py-0 w-full mb-4" style={{ width: "100%" }}>
                                          <Row className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2 timing">
                                            <Col className="col-12">
                                              <button
                                                className="btn btn-sm dotted "
                                                onClick={() => {
                                                  togCenterWorkingHours();
                                                }}
                                              >
                                                Add New
                                              </button>
                                            </Col>
                                          </Row>
                                        </div>

                                        <Modal
                                          isOpen={modalCenterWorkingHours}
                                          toggle={() => {
                                            togCenterWorkingHours();
                                          }}
                                          centered
                                        >
                                          <div className="modal-header">
                                            <h5 className="font-size-14 mb-2">Working days and hours:</h5>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                togCenterWorkingHoursClose();
                                              }}
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span>&times;</span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="py-0 w-full" style={{ width: "100%" }}>
                                              <div className=" flex-wrap -mx-2 md:space-y-0 mt-4 px-2" style={{ display: "flex" }}>
                                                <select
                                                  className="form-control"
                                                  defaultValue={newWorkingHour.day}
                                                  onChange={e => setNewWorkingDay(e.target.value)}
                                                >
                                                  <option>Select Day</option>
                                                  {selectedDates.map((day: string, i: number) => {
                                                    return (
                                                      <option key={i} value={day}>
                                                        {day}
                                                      </option>
                                                    );
                                                  })}
                                                </select>

                                                <Row className="mt-3">
                                                  <Col className="col-6">
                                                    <Label className="form-label">Start Time</Label>
                                                    <CustomTimePicker2
                                                      timeValue={startTimeValue || ""}
                                                      setTimeValue={(value: string) => {
                                                        setStartTimeValue(value);
                                                        if (value) {
                                                          const utcDate = moment(value, "hh:mm A").utc().format("H:mm A");
                                                          setNewWorkingHour({ ...newWorkingHour, startTime: utcDate });
                                                        }
                                                      }}
                                                    />
                                                  </Col>

                                                  <Col className="col-6">
                                                    <Label className="form-label">End Time</Label>
                                                    <CustomTimePicker2
                                                      timeValue={endTimeValue || ""}
                                                      setTimeValue={(value: string) => {
                                                        setEndTimeValue(value);
                                                        if (value) {
                                                          const utcDate = moment(value, "hh:mm A").utc().format("H:mm A");
                                                          setNewWorkingHour({ ...newWorkingHour, endTime: utcDate });
                                                        }
                                                      }}
                                                    />
                                                  </Col>

                                                  <Col className="col-12 mt-3">
                                                    <button className="btn btn-primary btn-sm font-size-14" onClick={updateTimeSlots}>
                                                      Add Time Slot
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          </div>
                                        </Modal>
                                      </>
                                    )}
                                  </Col>

                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-17 profileFont mb-3">Professional experience</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-4">
                                            Experienced in:
                                            {userdata?.experiencedIn?.length !== undefined && userdata?.experiencedIn?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <Creatable
                                          options={searchExpTag}
                                          isMulti
                                          components={creatableComponents}
                                          onChange={(newValue: any) => handlExpTags(newValue)}
                                          value={selectedExpTags}
                                          display="none"
                                          className="creatableEdit"
                                          placeholder="Choose an area"
                                          controlShouldRenderValue={false}
                                          isClearable={false}
                                        />

                                        <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                                          {selectedExpTags.map((tag, id) => {
                                            return <Tags label={tag.label} id={id} key={id} removeTag={removeExpTag} />;
                                          })}
                                        </div>

                                        <div className="card-heading mb-4 mt-4">
                                          <h5 className="font-size-14 mb-2">Your profession:</h5>
                                        </div>

                                        <div className="mb-2">
                                          <div className="col-xl-12">
                                            <select
                                              className="form-control"
                                              onChange={e => {
                                                const selectedLabel = e.target.options[e.target.selectedIndex].text;

                                                setProfession({ _id: e.target.value, name: selectedLabel });
                                              }}
                                              value={(profession?._id as any) || userdata?.profession?._id}
                                            >
                                              <option>Select Profession</option>
                                              {professionsDetails &&
                                                professionsDetails.map(opt => (
                                                  <option value={opt._id} key={opt._id}>
                                                    {opt.name}
                                                  </option>
                                                ))}
                                            </select>
                                          </div>
                                        </div>

                                        {selectedProfessions != undefined && selectedProfessions.length != 0 ? (
                                          <div className="mb-2">
                                            <div className="col-xl-12">
                                              <select
                                                className="form-control"
                                                onChange={e =>
                                                  setSelectedProfessionsLicensDetails({ ...selectedProfessionsLicensDetails, _id: e.target.value })
                                                }
                                                value={(selectedProfessionsLicensDetails?._id as string) || user?.professionLicense?._id}
                                              >
                                                <option>Select License</option>
                                                {selectedProfessions &&
                                                  selectedProfessions.map(opt => (
                                                    <option value={opt._id} key={opt._id}>
                                                      {opt.name}
                                                    </option>
                                                  ))}
                                              </select>
                                            </div>
                                          </div>
                                        ) : null}

                                        {profession?.name === "Counselors, Clinicians, Therapists" && (
                                          <>
                                            <div className="card-heading mb-4 mt-4">
                                              <label htmlFor="therapistCategorizationByType">Therapist Categorization Type: </label>
                                              <div id="therapistCategorizationByType" className="d-flex flex-row ms-2">
                                                <select
                                                  className="form-select"
                                                  value={(therapistCategorizationByType==""||therapistCategorizationByType=="None")? "None":therapistCategorizationByType}
                                                  onChange={e => {
                                                    changeTherapistCategorizationByType(e.target.value);
                                                  }}
                                                >
                                                  <option value="None">Not Selected</option>
                                                  <option value="ASSOCIATE">Associate therapist</option>
                                                  <option value="LICENSED">Fully licensed therapist</option>
                                                </select>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        {profession?.name === "Counselors, Clinicians, Therapists" && (
                                          <>
                                            <div className="card-heading mb-4 mt-4">
                                              <h5 className="font-size-14 mb-2">Your professional disclosure statement:</h5>
                                            </div>

                                            {user?.disclosureStatementId != null && (
                                              <div className="flex  mb-2">
                                                <div className="mr-2">
                                                  <a
                                                    href={user?.disclosureStatementId?.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="events-icon2"
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {user?.disclosureStatementId?.originalName}
                                                  </a>
                                                </div>
                                              </div>
                                            )}

                                            {statementFileRef.current.preview ? (
                                              <div>
                                                {statementFileRef.current.preview && (
                                                  <label htmlFor="file-upload1" className="file-upload m-auto">
                                                    <span className="text-primary pt-5 m-auto cursor-pointer">Change disclosure statement</span>

                                                    <input
                                                      id="file-upload1"
                                                      type="file"
                                                      style={{ display: "none" }}
                                                      accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                    text/plain, image/doc"
                                                      onChange={selectStatementFile}
                                                      multiple
                                                    />
                                                  </label>
                                                )}
                                                <div>
                                                  <div className="flex">
                                                    <div style={{ fontSize: "62px" }}>{statementFiles()}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {!statementFileRef.current.preview && (
                                              <label
                                                htmlFor="file-upload1"
                                                style={{ border: "1px dashed" }}
                                                className="mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 ounded cursor-pointer upload-box"
                                              >
                                                <div className="flex flex-wrap items-center file_upload_content">
                                                  <span className="text-black">
                                                    <i className="fa fa-upload cloud-upload"></i>&nbsp;&nbsp;Upload your disclosure statement
                                                  </span>
                                                </div>

                                                <span className="text-sm file_upload_p">.PDF, .JPG, .PNG</span>

                                                <input
                                                  id="file-upload1"
                                                  type="file"
                                                  style={{ display: "none" }}
                                                  className="file_upload_input mb-4"
                                                  accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                                  onChange={selectStatementFile}
                                                  multiple
                                                />
                                              </label>
                                            )}
                                          </>
                                        )}

                                        <div className="card-heading mb-4 mt-4">
                                          <h5 className="font-size-14 mb-2">Sync appointments with google calendar</h5>
                                        </div>

                                        {isgoogleCalendarAccess == true ? (
                                          <div>
                                            <div className="d-flex justify-content-start ml-3">
                                              <a
                                                id="btn-id"
                                                onClick={() => {
                                                  logoutCalendar();
                                                }}
                                                className="btnFbnCalendar"
                                              >
                                                <img src={googleImage} style={{ height: "15px", marginRight: "5px", marginLeft: "3px" }}></img>Sign out Google
                                                Calendar
                                              </a>
                                            </div>
                                            <p>{googleUserEmail}</p>
                                          </div>
                                        ) : (
                                          <div id="btn-id" className="btnFbnCalendar">
                                            <GoogleLogin
                                              clientId={environment.google_calendar_client_id}
                                              buttonText="Sign in with Google"
                                              onSuccess={responseGoogle}
                                              onFailure={responseError}
                                              responseType="code"
                                              accessType="offline"
                                              cookiePolicy={"single_host_origin"}
                                              scope="https://www.googleapis.com/auth/calendar openid email profile"
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">
                                            Timezone
                                            {userdata?.timeZone ? null : <div className="tagError">Missing</div>}
                                          </h5>
                                        </div>

                                        <select className="form-control" value={selectedTimezone} onChange={event => handleTimezone(event)}>
                                          {timezones &&
                                            timezones.map((zone: { label: string; value: string }, index: number) => {
                                              return (
                                                <option key={index} value={zone.value}>
                                                  {zone.label}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </>
                                    )}
                                  </Col>

                                  <Col xl={4}></Col>

                                  <Col xl={4}>
                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">
                                            Are you credential with the following insurance companies?:
                                            {userdata?.insuranceCompanies?.length !== undefined && userdata?.insuranceCompanies?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <Select
                                          onChange={(newValue: any) => handlInsuranceCompanyTags(newValue)}
                                          value={selecteInsuranceCompanyTags}
                                          isMulti
                                          options={searchInsuranceCompanyTag}
                                          className="basic-multi-select"
                                          placeholder="Choose a company"
                                          display="none"
                                          controlShouldRenderValue={false}
                                          classNamePrefix="select"
                                          isClearable={false}
                                        />

                                        <div className="flex flex-wrap flex-row  pl-3 pr-3 mt-2">
                                          {selecteInsuranceCompanyTags.map((tag, id) => {
                                            return <Tags label={tag.label} id={id} key={id} removeTag={removeInsuranceCompanyTag} />;
                                          })}
                                        </div>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}

                            <Row>
                              <Col xl="12 mt50">
                                <hr />
                              </Col>
                              <Col xl="6"></Col>
                              <Col xl="6" className="text-right"></Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <Row>
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-17 profileFont mb-4">Personal Information</h5>
                                  </div>
                                </Row>

                                {!editProfile && (
                                  <Row>
                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Name</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">
                                          {user?.firstname || "-"} {user?.middleInitials} {user?.lastname}
                                        </h5>
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Gender</h5>
                                      </div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{user?.gender || "-"}</h5>
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Age</h5>
                                      </div>

                                      <div className="card-heading mb-3">
                                        {user?.dateOfBirth ? (
                                          <h5 className="font-size-14 mb-2">{moment().diff(moment(user?.dateOfBirth), "years")} years</h5>
                                        ) : (
                                          <h5 className="font-size-14 mb-3">{!user?.dateOfBirth && "-"}</h5>
                                        )}
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Email</h5>
                                      </div>

                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{user?.email || "-"}</h5>
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Ethnicity</h5>
                                      </div>

                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{user?.ethnicityId?.ethnicity || "-"}</h5>
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">User Name</h5>
                                      </div>

                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 mb-3">{user?.username || "-"}</h5>
                                      </div>
                                    </Col>

                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Primary Phone/Cell</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">
                                              {user?.primaryPhone && user?.primaryPhone?.length <= 10
                                                ? user?.primaryPhone
                                                : user?.primaryPhone?.slice(user?.primaryPhone?.length - 10) || "-"}
                                            </h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Street Address</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.streetAddress || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">City</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.city || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">State</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.state || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Zip Code</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.zipCode || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl={4}>
                                          {user?.role == "THERAPIST" && (
                                            <>
                                              <div className="card-heading mb-2">
                                                <h5 className="font-size-14 profileFont mb-2">Selected Therapy States</h5>
                                              </div>

                                              <div className="card-heading mb-2">
                                                {user?.therapyState?.length === 0
                                                  ? "-"
                                                  : user?.therapyState?.map((state: string, index: number) => (
                                                      <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={index}>
                                                        {state}
                                                      </Link>
                                                    ))}
                                              </div>
                                            </>
                                          )}
                                        </Col>
                                      </>
                                    )}

                                    {user?.role == "CLIENT" && (
                                      <>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Marital Status</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.maritalStatus || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Street Address</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.streetAddress || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Apt./Unit</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.unit || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">City</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.city || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Zip Code</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.zipCode || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">State</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.state || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Primary Phone/Cell</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">
                                              {user?.primaryPhone && user?.primaryPhone?.length <= 10
                                                ? user?.primaryPhone
                                                : user?.primaryPhone?.slice(user?.primaryPhone?.length - 10) || "-"}
                                            </h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Secondary/Home Phone</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.homePhone?.slice(user?.homePhone?.length - 10) || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Work Phone</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.workPhone?.slice(user?.workPhone?.length - 10) || "-"}</h5>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">May we leave a voicemail?</h5>
                                          </div>

                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.voiceMail || "-"}</h5>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                )}

                                {editProfile && (
                                  <Row>
                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">
                                          Name<span style={{ color: "#990000" }}>*</span>
                                        </h5>
                                      </div>

                                      <div className="card-heading mb-2 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="First name"
                                          name="articleTitle"
                                          value={userdata?.firstname || ""}
                                          onChange={e => setUserdata({ ...userdata, firstname: e.target.value })}
                                          required
                                        />

                                        <Input
                                          type="text"
                                          className="form-control ml-3"
                                          id="formrow-firstname-Input"
                                          placeholder="Middle Initials"
                                          name="Middle Initials"
                                          value={userdata?.middleInitials || ""}
                                          onChange={e => setUserdata({ ...userdata, middleInitials: e.target.value })}
                                          required
                                        />

                                        <Input
                                          type="text"
                                          className="form-control ml-3"
                                          id="formrow-firstname-Input"
                                          placeholder="Last name"
                                          name="articleTitle"
                                          value={userdata?.lastname || ""}
                                          onChange={e => setUserdata({ ...userdata, lastname: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">
                                          Gender<span style={{ color: "#990000" }}>*</span>
                                        </h5>
                                      </div>

                                      <div className="card-heading mb-2">
                                        <Row>
                                          <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exampleRadioss"
                                                id="exampleRadios1"
                                                value="Female"
                                                defaultChecked={userdata?.gender === "Female"}
                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                              />
                                              <label className="form-check-label" htmlFor="exampleRadios1">
                                                Female
                                              </label>
                                            </div>

                                            <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exampleRadioss"
                                                id="exampleRadios2"
                                                value="Male"
                                                defaultChecked={userdata?.gender === "Male"}
                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                              />
                                              <label className="form-check-label" htmlFor="exampleRadios2">
                                                Male
                                              </label>
                                            </div>

                                            <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exampleRadioss"
                                                id="exampleRadios3"
                                                value="NonBinary"
                                                defaultChecked={userdata?.gender === "NonBinary"}
                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                              />
                                              <label className="form-check-label" htmlFor="exampleRadios3">
                                                Non Binary
                                              </label>
                                            </div>

                                            <div className="form-check mb-3">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exampleRadioss"
                                                id="exampleRadios4"
                                                value="Other"
                                                defaultChecked={userdata?.gender === "Other"}
                                                onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                              />
                                              <label className="form-check-label" htmlFor="exampleRadios4">
                                                Other
                                              </label>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">
                                          Date Of BirthDay<span style={{ color: "#990000" }}>*</span>
                                        </h5>
                                      </div>

                                      <div className="card-heading mb-3">
                                        <Input
                                          type="date"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Date of birth"
                                          value={db || ""}
                                          onChange={e => handleDateUpdate(e)}
                                          max={moment().format("YYYY-MM-DD")}
                                          required
                                        />
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">
                                          Email<span style={{ color: "#990000" }}>*</span>
                                        </h5>
                                      </div>

                                      <div className="card-heading mb-3">
                                        <Input
                                          type="email"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Email"
                                          disabled
                                          name="articleTitle"
                                          value={userdata?.email || ""}
                                          onChange={e => {}}
                                          required
                                        />
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">
                                          Select as ethnicity
                                          {userdata?.ethnicityId?._id?.length !== undefined && userdata?.ethnicityId?._id?.length !== 0 ? null : (
                                            <div className="tagError">Missing</div>
                                          )}
                                        </h5>
                                      </div>

                                      <div className="col-xl-12 mb-3">
                                        <select
                                          className="form-control"
                                          value={(setEthenicity as any) || user?.ethnicityId?._id}
                                          onChange={e => setUpdateEthenacity(e.target.value)}
                                        >
                                          <option>Select</option>
                                          {ethinicityList &&
                                            ethinicityList.map(opt => (
                                              <option value={opt._id} key={opt._id}>
                                                {opt.ethnicity}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </Col>

                                    <Col xl="4">
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">
                                          User Name<span style={{ color: "#990000" }}>*</span>
                                        </h5>
                                      </div>

                                      <div className="card-heading mb-3">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="User name"
                                          name="articleTitle"
                                          value={userdata?.username || ""}
                                          onChange={e => setUserdata({ ...userdata, username: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </Col>

                                    {user?.role == "THERAPIST" && (
                                      <>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              Primary Phone/Cell<span style={{ color: "#990000" }}>*</span>
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <PhoneInput
                                              placeholder="Primary Phone "
                                              value={userdata?.primaryPhone}
                                              defaultCountry="US"
                                              // containerClass="form-control"
                                              // inputClass="form-control"
                                              // containerStyle={{
                                              //     border: "10px solid black",
                                              //     background: "lightblue"
                                              // }}
                                              // inputStyle={{
                                              //     background: "lightblue"
                                              // }}
                                              onChange={(e: string) => setUserdata({ ...userdata, primaryPhone: e })}
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              Street Address
                                              {/* Street Address<span style={{ color: "#990000" }}>*</span> */}
                                              {userdata?.streetAddress?.length !== undefined && userdata?.streetAddress?.length !== 0 ? null : (
                                                <div className="tagError">Missing</div>
                                              )}
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="Street Address"
                                              name="Street Address"
                                              value={userdata?.streetAddress}
                                              onChange={e => setUserdata({ ...userdata, streetAddress: e.target.value })}
                                              required
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              {/* City<span style={{ color: "#990000" }}>*</span> */}
                                              City<span style={{ color: "#990000" }}>*</span>
                                              {userdata?.city?.length !== undefined && userdata?.city?.length !== 0 ? null : (
                                                <div className="tagError">Missing</div>
                                              )}
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="City"
                                              name="City"
                                              value={userdata?.city}
                                              onChange={e => setUserdata({ ...userdata, city: e.target.value })}
                                              required
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">
                                                {/* Zip Code<span style={{ color: "#990000" }}>*</span> */}
                                                Zip Code
                                              </h5>
                                            </div>

                                            <div className="card-heading mb-3">
                                              <Input
                                                // type="number"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Zip Code"
                                                name="Zip Code"
                                                value={userdata?.zipCode}
                                                onChange={e => handlePostalCode(e.target.value)}
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Col>

                                        {user?.state && (
                                          <Col xl="4">
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">State</h5>
                                            </div>

                                            <div className="card-heading mb-4 flex">
                                              <h5 className="font-size-14 profileFont mb-2">{user?.state}</h5>
                                            </div>
                                          </Col>
                                        )}

                                        <Col xl={4}>
                                          <>
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 mb-3">
                                                Select States That You Prefer:
                                                {selectTherapyTags?.length !== undefined && selectTherapyTags?.length !== 0 ? null : (
                                                  <div className="tagError">Missing</div>
                                                )}
                                              </h5>
                                            </div>

                                            <Select
                                              onChange={(newValue: any) => handleTherapyStateTags(newValue)}
                                              value={selectTherapyTags}
                                              isMulti
                                              options={searchTherapyTags}
                                              className="basic-multi-select"
                                              placeholder="Choose a State"
                                              display="none"
                                              controlShouldRenderValue={false}
                                              classNamePrefix="select"
                                              isClearable={false}
                                            />

                                            <div className="flex flex-wrap flex-row pl-3 pr-3 mt-2">
                                              {selectTherapyTags.map((tagObj, id) => (
                                                <Tags label={tagObj.label} id={id} key={id} removeTag={removeTherapyStateTag} />
                                              ))}
                                            </div>
                                          </>
                                        </Col>
                                      </>
                                    )}

                                    {user?.role == "CLIENT" && (
                                      <>
                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              {/* Marital Status<span style={{ color: "#990000" }}>*</span> */}
                                              Marital Status
                                              {userdata?.maritalStatus?.length !== undefined && userdata?.maritalStatus?.length !== 0 ? null : (
                                                <div className="tagError">Missing</div>
                                              )}
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Row>
                                              <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios9"
                                                    value="Single"
                                                    defaultChecked={userdata?.maritalStatus == "Single"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios9">
                                                    Single
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios21"
                                                    value="Engaged"
                                                    defaultChecked={userdata?.maritalStatus == "Engaged"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios21">
                                                    Engaged
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios31"
                                                    value="Married"
                                                    defaultChecked={userdata?.maritalStatus == "Married"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios31">
                                                    Married
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios4"
                                                    value="Partner"
                                                    defaultChecked={userdata?.maritalStatus == "Partner"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios4">
                                                    Partner
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3" style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios5"
                                                    value="Separated"
                                                    defaultChecked={userdata?.maritalStatus == "Separated"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios5">
                                                    Separated
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios6"
                                                    value="Divorced"
                                                    defaultChecked={userdata?.maritalStatus == "Divorced"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios6">
                                                    Divorced
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios7"
                                                    value="Re-married"
                                                    defaultChecked={userdata?.maritalStatus == "Re-married"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios7">
                                                    Re-married
                                                  </label>
                                                </div>

                                                <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios8"
                                                    value="Widowed"
                                                    defaultChecked={userdata?.maritalStatus == "Widowed"}
                                                    onChange={e => setUserdata({ ...userdata, maritalStatus: e.target.value })}
                                                  />

                                                  <label className="form-check-label" htmlFor="exampleRadios8">
                                                    Widowed
                                                  </label>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              Street Address
                                              {/* Street Address<span style={{ color: "#990000" }}>*</span> */}
                                              {userdata?.streetAddress?.length !== undefined && userdata?.streetAddress?.length !== 0 ? null : (
                                                <div className="tagError">Missing</div>
                                              )}
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="Street Address"
                                              name="Street Address"
                                              value={userdata?.streetAddress}
                                              onChange={e => setUserdata({ ...userdata, streetAddress: e.target.value })}
                                              required
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Apt./Unit</h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="Apt./Unit"
                                              name="Apt./Unit"
                                              value={userdata?.unit}
                                              onChange={e => setUserdata({ ...userdata, unit: e.target.value })}
                                              required
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              {/* City<span style={{ color: "#990000" }}>*</span> */}
                                              City
                                              {userdata?.city?.length !== undefined && userdata?.city?.length !== 0 ? null : (
                                                <div className="tagError">Missing</div>
                                              )}
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="City"
                                              name="City"
                                              value={userdata?.city}
                                              onChange={e => setUserdata({ ...userdata, city: e.target.value })}
                                              required
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              {/* Zip Code<span style={{ color: "#990000" }}>*</span> */}
                                              Zip Code
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <Input
                                              type="number"
                                              className="form-control"
                                              id="formrow-firstname-Input"
                                              placeholder="Zip Code"
                                              name="Zip Code"
                                              value={userdata?.zipCode}
                                              onChange={e => handlePostalCode(e.target.value)}
                                              required
                                            />
                                          </div>
                                        </Col>

                                        {user?.state && (
                                          <Col xl="4">
                                            <div className="card-heading mb-2">
                                              <h5 className="font-size-14 profileFont mb-2">State</h5>
                                            </div>

                                            <div className="card-heading mb-4 flex">
                                              <h5 className="font-size-14 profileFont mb-2">{user?.state}</h5>
                                            </div>
                                          </Col>
                                        )}

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              Primary Phone/Cell<span style={{ color: "#990000" }}>*</span>
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <PhoneInput
                                              placeholder="Primary Phone "
                                              value={userdata?.primaryPhone}
                                              defaultCountry="US"
                                              onChange={(e: string) => setUserdata({ ...user, primaryPhone: e })}
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Secondary/Home Phone</h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <PhoneInput
                                              placeholder="Home Phone"
                                              value={userdata?.homePhone}
                                              defaultCountry="US"
                                              onChange={(e: string) => setUserdata({ ...userdata, homePhone: e })}
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">Work Phone</h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <PhoneInput
                                              placeholder="WorkPhone Phone"
                                              value={userdata?.workPhone}
                                              defaultCountry="US"
                                              onChange={(e: string) => setUserdata({ ...userdata, workPhone: e })}
                                            />
                                          </div>
                                        </Col>

                                        <Col xl="4">
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">
                                              May we leave a voicemail?
                                              {userdata?.voiceMail?.length !== undefined && userdata?.voiceMail?.length !== 0 ? null : (
                                                <div className="tagError">Missing</div>
                                              )}
                                            </h5>
                                          </div>

                                          <div className="card-heading mb-3">
                                            <select
                                              className="form-control"
                                              value={userdata?.voiceMail}
                                              onChange={e => setUserdata({ ...userdata, voiceMail: e.target.value })}
                                            >
                                              <option selected>Select</option>
                                              <option value="Yes">Yes</option>
                                              <option value="No">No</option>
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                )}
                              </Col>
                            </Row>

                            {user?.role == "THERAPIST" && (
                              <Row>
                                <Col xl="12 mt50">
                                  <hr />
                                </Col>
                                <Col xl="6"></Col>
                                <Col xl="6" className="text-right"></Col>
                              </Row>
                            )}

                            {user?.role == "THERAPIST" && (
                              <Row>
                                <Col xl="12">
                                  <Row>
                                    <div className="card-heading mb-2">
                                      <h5 className="font-size-17 profileFont mb-4">Credentialing Information </h5>
                                    </div>
                                  </Row>

                                  {!editProfile && (
                                    <Row>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Google Calendar Sync</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          {user?.googleCalendarAccess == true ? (
                                            <div>
                                              <h5 className="badge bg-primary font-size-11 m-1 p-2">Active</h5>
                                              <p>{googleUserEmail}</p>
                                            </div>
                                          ) : (
                                            <h5 className="badge bg-primary font-size-11 m-1 p-2">Inactive</h5>
                                          )}
                                        </div>
                                        {/* <Link to="#" className="badge bg-primary font-size-11 m-1 p-2" key={ex?._id}>
                                                {ex.insuranceCompany}
                                              </Link> */}
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Social Security</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.socialSecurity || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">CAQH</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.cAQH || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">CAQH User Name</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.caqhUserName || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">CAQH Pasword</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.caqhPassword || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">NPI 1</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.nPI1 || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">NPI 2</h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{environment.NPI2}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Tax Identification </h5>
                                        </div>
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.taxIdentification || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 profileFont mb-2">License </h5>
                                          </div>
                                          <div className="card-heading mb-2">
                                            <h5 className="font-size-14 mb-3">{user?.license || "-"}</h5>
                                          </div>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Original Issue Date </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          {user?.issueDate ? (
                                            <h5 className="font-size-14 mb-2">{moment(user?.issueDate || null).format("YYYY-MM-DD")}</h5>
                                          ) : (
                                            <h5 className="font-size-14 mb-3">{!user?.issueDate && "-"}</h5>
                                          )}
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Expiration Date </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          {user?.expirationDate ? (
                                            <h5 className="font-size-14 mb-2">{moment(user?.expirationDate || null).format("YYYY-MM-DD")}</h5>
                                          ) : (
                                            <h5 className="font-size-14 mb-3">{!user?.expirationDate && "-"}</h5>
                                          )}
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Graduate School Name </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.schoolName || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Date of Graduation </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{!user?.dateOfGraduation && "-"}</h5>
                                          {user?.dateOfGraduation && (
                                            <h5 className="font-size-14 mb-2">{moment(user?.dateOfGraduation || null).format("YYYY-MM-DD")}</h5>
                                          )}
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">School's Street Address </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.schoolStreetAddress || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">School's City </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.schoolCity || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">School's State </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.schoolState || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">School's Zip Code </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.schoolZipCode || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Taxonomy Code </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.taxonomyCode || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Malpractice Policy </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.malpracticePolicy || "-"}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-1">
                                          <h5 className="font-size-14 profileFont mb-1">Malpractice Expiration Date </h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          {user?.malpracticeExpirationDate ? (
                                            <h5 className="font-size-14 mb-3">{moment(user?.malpracticeExpirationDate || null).format("YYYY-MM-DD")}</h5>
                                          ) : (
                                            <h5 className="font-size-14 mb-3">{!user?.malpracticeExpirationDate && "-"}</h5>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Medicaid User Name</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.medicaidUsername || "-"}</h5>
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Medicaid Password</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.MedicaidPassword || "-"}</h5>
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Medicaid ID</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.medicaidId || "-"}</h5>
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Psychology Today User name</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.psychologyTodayUsername || "-"}</h5>
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Psychology Today Password</h5>
                                        </div>

                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 mb-3">{user?.psychologyTodayPassword || "-"}</h5>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}

                                  {editProfile && (
                                    <Row>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Social Security
                                            {userdata?.socialSecurity?.length !== undefined && userdata?.socialSecurity?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="password"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Social Security"
                                            name="Social Security"
                                            value={userdata?.socialSecurity || ""}
                                            maxLength={9}
                                            minLength={9}
                                            onChange={e => setUserdata({ ...userdata, socialSecurity: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            CAQH
                                            {userdata?.cAQH?.length !== undefined && userdata?.cAQH?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="CAQH"
                                            maxLength={8}
                                            name="CAQH"
                                            value={userdata?.cAQH || ""}
                                            onChange={e => setUserdata({ ...userdata, cAQH: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            CAQH User Name
                                            {userdata?.caqhUserName?.length !== undefined && userdata?.caqhUserName?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-caqhname-Input"
                                            placeholder=""
                                            name="CAQH Name"
                                            value={userdata?.caqhUserName || ""}
                                            onChange={e => setUserdata({ ...userdata, caqhUserName: e.target.value })}
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            CAQH Password
                                            {userdata?.caqhPassword?.length !== undefined && userdata?.caqhPassword?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-caqhpsd-Input"
                                            placeholder=""
                                            name="CAQH psd"
                                            value={userdata?.caqhPassword || ""}
                                            onChange={e => setUserdata({ ...userdata, caqhPassword: e.target.value })}
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            NPI 1
                                            {userdata?.nPI1?.length !== undefined && userdata?.nPI1?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>
                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="NPI 1"
                                            name="NPI 1"
                                            maxLength={10}
                                            value={userdata?.nPI1 || ""}
                                            onChange={e => setUserdata({ ...userdata, nPI1: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">NPI 2</h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <h5 className="font-size-14 mb-3"> {environment.NPI2}</h5>
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Tax Identification
                                            {userdata?.taxIdentification?.length !== undefined && userdata?.taxIdentification?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Tax Identification"
                                            name="Tax Identification"
                                            value={userdata?.taxIdentification || ""}
                                            onChange={e => setUserdata({ ...userdata, taxIdentification: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            License
                                            {userdata?.license?.length !== undefined && userdata?.license?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="License"
                                            name="License"
                                            value={userdata?.license || ""}
                                            onChange={e => setUserdata({ ...userdata, license: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Original Issue Date
                                            {idDateValue || userdata?.issueDate?.length !== 0 ? null : <div className="tagError">Missing</div>}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Original Issue Date"
                                            value={id || ""}
                                            onChange={e => handleIdDateUpdate(e)}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Expiration Date
                                            {edDateValue ||
                                            (userdata?.expirationDate?.length !== undefined && userdata?.expirationDate?.length !== 0) ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Expiration Date"
                                            value={ed || ""}
                                            onChange={e => handleEdDateUpdate(e)}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Graduate School Name
                                            {userdata?.schoolName?.length !== undefined && userdata?.schoolName?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Graduate School Name "
                                            name="Graduate School Name "
                                            value={userdata?.schoolName || ""}
                                            onChange={e => setUserdata({ ...userdata, schoolName: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Date of Graduation
                                            {dogDateValue ||
                                            (userdata?.dateOfGraduation?.length !== undefined && userdata?.dateOfGraduation?.length !== 0) ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Date of Graduation"
                                            value={dog || ""}
                                            onChange={e => handleDogDateUpdate(e)}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            School's Street Address
                                            {userdata?.schoolStreetAddress?.length !== undefined && userdata?.schoolStreetAddress?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="School's Street Address"
                                            name="School's Street Address"
                                            value={userdata?.schoolStreetAddress || ""}
                                            onChange={e => setUserdata({ ...userdata, schoolStreetAddress: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            School's City
                                            {userdata?.schoolCity?.length !== undefined && userdata?.schoolCity?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="School's City"
                                            name="School's City"
                                            value={userdata?.schoolCity || ""}
                                            onChange={e => setUserdata({ ...userdata, schoolCity: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            School's State
                                            {userdata?.schoolState?.length !== undefined && userdata?.schoolState?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="School's State"
                                            name="School's State"
                                            value={userdata?.schoolState || ""}
                                            onChange={e => setUserdata({ ...userdata, schoolState: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            School's Zip Code
                                            {userdata?.schoolZipCode?.length !== undefined && userdata?.schoolZipCode?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="School's Zip Code"
                                            name="School's Zip Code"
                                            value={userdata?.schoolZipCode || ""}
                                            onChange={e => setUserdata({ ...userdata, schoolZipCode: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Taxonomy Code
                                            {userdata?.taxonomyCode?.length !== undefined && userdata?.taxonomyCode?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Taxonomy Code"
                                            maxLength={10}
                                            name="Taxonomy Code"
                                            value={userdata?.taxonomyCode || ""}
                                            onChange={e => setUserdata({ ...userdata, taxonomyCode: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Malpractice Policy
                                            {userdata?.malpracticePolicy?.length !== undefined && userdata?.malpracticePolicy?.length !== 0 ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Malpractice Policy"
                                            name="Malpractice Policy"
                                            value={userdata?.malpracticePolicy || ""}
                                            onChange={e => setUserdata({ ...userdata, malpracticePolicy: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">
                                            Malpractice Expiration Date
                                            {medDateValue ||
                                            (userdata?.malpracticeExpirationDate?.length !== undefined &&
                                              userdata?.malpracticeExpirationDate?.length !== 0) ? null : (
                                              <div className="tagError">Missing</div>
                                            )}
                                          </h5>
                                        </div>

                                        <div className="card-heading mb-2 flex">
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Malpractice Expiration Date"
                                            value={med || ""}
                                            onChange={e => handleMedDateUpdate(e)}
                                            required
                                          />
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Medicaid User Name</h5>
                                        </div>

                                        <div className="card-heading mb-3">
                                          <Input
                                            // type="number"
                                            className="form-control"
                                            id="Medicaid User Name"
                                            placeholder="Medicaid username"
                                            name="Medicaid username"
                                            value={userdata?.medicaidUsername}
                                            onChange={e => setUserdata({ ...userdata, medicaidUsername: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Medicaid Password</h5>
                                        </div>

                                        <div className="card-heading mb-3">
                                          <Input
                                            // type="number"
                                            className="form-control"
                                            id="fMedicaid password"
                                            placeholder="Medicaid password"
                                            name="Medicaid password"
                                            value={userdata?.MedicaidPassword}
                                            onChange={e => setUserdata({ ...userdata, MedicaidPassword: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Medicaid ID</h5>
                                        </div>

                                        <div className="card-heading mb-3">
                                          <Input
                                            className="form-control"
                                            id="fMedicaid Id"
                                            placeholder="Medicaid Id"
                                            name="Medicaid Id"
                                            value={userdata?.medicaidId}
                                            onChange={e => setUserdata({ ...userdata, medicaidId: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>
                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Psychology Today User Name</h5>
                                        </div>

                                        <div className="card-heading mb-3">
                                          <Input
                                            // type="number"
                                            className="form-control"
                                            id="psychology today username"
                                            placeholder="psychology today username"
                                            name="psychology today username"
                                            value={userdata?.psychologyTodayUsername}
                                            onChange={e => setUserdata({ ...userdata, psychologyTodayUsername: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="4">
                                        <div className="card-heading mb-2">
                                          <h5 className="font-size-14 profileFont mb-2">Psychology Today Password</h5>
                                        </div>

                                        <div className="card-heading mb-3">
                                          <Input
                                            className="form-control"
                                            id="psychology today password"
                                            placeholder="psychology today password"
                                            name="psychology today password"
                                            value={userdata?.psychologyTodayPassword}
                                            onChange={e => setUserdata({ ...userdata, psychologyTodayPassword: e.target.value })}
                                            required
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            )}
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          {user?.role == "CLIENT" && (
                            <>
                              <Col xl="12">
                                <Row>
                                  <ProfileInsuranceDetails onCopaymentAmount={handleCopaymentAmount} />
                                </Row>
                              </Col>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 mb-2">Payment:</h5>
                            </div>

                            <Col xl="4">
                              <Form>
                                <div className="mb-2">
                                  <Label htmlFor="formrow-firstname-Input">Card number</Label>

                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Card number"
                                    name="articleTitle"
                                    maxLength={16}
                                    required
                                  />
                                </div>

                                <Row>
                                  <Label htmlFor="formrow-firstname-Input">Expires date</Label>

                                  <Col xl="6">
                                    <div className="mb-2">
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="mm"
                                        name="articleTitle"
                                        required
                                        maxLength={2}
                                      />
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div className="mb-2">
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="yy"
                                        name="articleTitle"
                                        required
                                        maxLength={2}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mb-4">
                                  <Label htmlFor="formrow-firstname-Input">CVV</Label>

                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="CVV"
                                    name="articleTitle"
                                    maxLength={3}
                                    required
                                  />
                                </div>

                                <button type="button" className="btn btn-primary w-50 " style={{ float: "right" }}>
                                  {isOpen ? "Add Card" : "Save Card"}
                                </button>
                              </Form>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="4">
                          <Row>
                            <div className="col-md-6">
                              <div className="card-heading mb-2">
                                <h5 className="font-size-14 mb-2">Change Password:</h5>
                              </div>

                              <Col>
                                <Form>
                                  <div className="mb-2">
                                    <Label htmlFor="formrow-firstname-Input">Old password</Label>

                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Old password"
                                      name="articleTitle"
                                      maxLength={40}
                                      onChange={e => setChangePassword({ ...changePassword, oldPassword: e.target.value })}
                                      value={changePassword.oldPassword || ""}
                                      required
                                    />
                                  </div>
                                  <div className="mb-2">
                                    <Label htmlFor="formrow-firstname-Input">New password</Label>

                                    <Input
                                      type={showPassword ? "password" : "text"}
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="New password"
                                      name="articleTitle"
                                      maxLength={41}
                                      onChange={e => setChangePassword({ ...changePassword, newPassword: e.target.value })}
                                      value={changePassword.newPassword || ""}
                                      required
                                    />
                                  </div>
                                  <input type="checkbox" onChange={toggleShowPassword} /> Show Password
                                  <div className="mt-3"></div>
                                  <div className="mb-4">
                                    <Label htmlFor="formrow-firstname-Input">Confirm new password</Label>
                                    <Input
                                      type={showPassword2 ? "password" : "text"}
                                      className="form-control"
                                      id="formrow-firstname-Input1"
                                      placeholder="Confirm new password"
                                      name="articleTitle"
                                      maxLength={40}
                                      onChange={e => setChangePassword({ ...changePassword, confirmPassword: e.target.value })}
                                      value={changePassword.confirmPassword || ""}
                                      required
                                    />
                                    <input type="checkbox" onChange={toggleShowPassword2} className="mt-3" /> Show Password
                                  </div>
                                  <button type="button" className="btn btn-primary w-50" style={{ float: "right" }} onClick={setChangePasswordBtn}>
                                    Save
                                  </button>
                                </Form>
                              </Col>
                            </div>
                            {user?.role == Role.THERAPIST && (
                              <div className="col-md-6">
                                <div className="card-heading mb-2">
                                  <h5 className="font-size-14 mb-2">Change Pin :</h5>
                                </div>
                                <Col>
                                  <Form>
                                    {/* <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">Old Pin</Label>
  
                                      <OtpInput
                                        value={oldPinNumber}
                                        onChange={setOldPinNumber}
                                        numInputs={4}
                                        renderSeparator={<span className="mx-2"></span>}
                                        renderInput={(props) => <input  {...props} className="input-pin-box" />}
                                        inputType={'number'}
                                      />
                                    </div> */}
                                    <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">New Pin</Label>

                                      <OtpInput
                                        value={newPinNumber}
                                        onChange={setNewPinNumber}
                                        numInputs={4}
                                        renderSeparator={<span className="mx-2"></span>}
                                        renderInput={props => <input {...props} className="input-pin-box" />}
                                        inputType={"number"}
                                      />
                                    </div>
                                    {/* <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">Confirm Pin</Label>
  
                                      <OtpInput
                                        value={confirmPinNumber}
                                        onChange={setConfirmPinNumber}
                                        numInputs={4}
                                        renderSeparator={<span className="mx-2"></span>}
                                        renderInput={(props) => <input  {...props} className="input-pin-box" />}
                                        inputType={'number'}
                                      />
                                    </div> */}
                                    <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">Password</Label>
                                      <Input
                                        type={showPassword3 ? "password" : "text"}
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Old password"
                                        name="articleTitle"
                                        maxLength={40}
                                        onChange={e => setChangePinNumber({ ...changePinNumber, password: e.target.value })}
                                        value={changePinNumber.password || ""}
                                        required
                                      />
                                      <input type="checkbox" onChange={toggleShowPassword3} className="mt-3" /> Show Password
                                    </div>

                                    <button type="button" className="btn btn-primary w-50" style={{ float: "right" }} onClick={setChangePinBtn}>
                                      Save
                                    </button>
                                  </Form>
                                </Col>
                              </div>
                            )}
                          </Row>
                        </TabPane>

                        <TabPane tabId="5">
                          <Row>
                            <div className="card-heading mb-2">
                              <h5 className="font-size-14 mb-2">Notification Settings:</h5>
                            </div>

                            <Col xl="4">
                              <Form>
                                <Label htmlFor="formrow-firstname-Input" className="font-size-14 mb-2">
                                  Select Notification Type
                                </Label>
                                <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-label"
                                    id="email"
                                    name="notificationType"
                                    defaultChecked={user?.reminderType?.email}
                                    onChange={e =>
                                      setUserdata({
                                        ...userdata,
                                        reminderType: { ...(userdata ? userdata.reminderType : {}), email: e.target.checked ? true : false },
                                      })
                                    }
                                  />
                                  <label className="form-check-label" htmlFor="email">
                                    E mail
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-label"
                                    id="text"
                                    name="notificationType"
                                    defaultChecked={user?.reminderType?.text !== false ? true : false}
                                    onChange={e =>
                                      setUserdata({
                                        ...userdata,
                                        reminderType: { ...(userdata ? userdata.reminderType : {}), text: e.target.checked ? true : false },
                                      })
                                    }
                                  />
                                  <label className="form-check-label" htmlFor="text">
                                    Text
                                  </label>
                                </div>
                                <Row mb="10">
                                  <button type="button" className="btn btn-primary w-50" onClick={updateUserNotification} style={{ float: "right" }}>
                                    Save
                                  </button>
                                </Row>
                              </Form>
                            </Col>
                            <Col xl="4">
                              <Form>
                                <Label htmlFor="formrow-firstname-Input" className="font-size-14 mb-2">
                                  Select Reminder time
                                </Label>
                                <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-label"
                                    id="beforeaday"
                                    name="notificationTime"
                                    defaultChecked={user?.reminderTime?.day}
                                    onChange={e =>
                                      setUserdata({
                                        ...userdata,
                                        reminderTime: { ...(userdata ? userdata.reminderTime : {}), day: e.target.checked ? true : false },
                                      })
                                    }
                                    // onChange={e => setUserdata({ ...userdata, reminderTime: e.target.checked ? e.target.value : "" })}
                                  />
                                  <label className="form-check-label" htmlFor="beforeaday">
                                    before a day
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-label"
                                    id="before01hour"
                                    name="notificationTime"
                                    defaultChecked={user?.reminderTime?.hour1}
                                    onChange={e =>
                                      setUserdata({
                                        ...userdata,
                                        reminderTime: { ...(userdata ? userdata.reminderTime : {}), hour1: e.target.checked ? true : false },
                                      })
                                    }
                                    // onChange={e => setUserdata({ ...userdata, reminderTime: e.target.checked ? e.target.value : "" })}
                                  />
                                  <label className="form-check-label" htmlFor="before01hour">
                                    before 01 hour
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-label"
                                    id="embefore30minutesail"
                                    name="notificationTime"
                                    defaultChecked={user?.reminderTime?.min30}
                                    onChange={e =>
                                      setUserdata({
                                        ...userdata,
                                        reminderTime: { ...(userdata ? userdata.reminderTime : {}), min30: e.target.checked ? true : false },
                                      })
                                    }
                                  />
                                  <label className="form-check-label" htmlFor="embefore30minutesail">
                                    before 30 minutes
                                  </label>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="6">
                          {!isLoading ? (
                            <Row>
                              <Col xl="5">
                                <div className="card-heading mb-4">
                                  <h5 className="font-size-14 mb-2">Education</h5>
                                </div>

                                {educationsDetailsList.length == 0 && <div className="font-size-14 mb-5 mt-1">No education details to display.</div>}

                                {educationsDetailsList.map((educationlist: any) => {
                                  return (
                                    <Card className="p-2 card_shadow" key={educationlist._id}>
                                      <Row>
                                        <div className="flex float_right" style={{ justifyContent: "end" }}>
                                          <img src={pencil} className="cursor_pointer" onClick={() => setShowEducationDetails(educationlist._id)}></img>
                                          <img
                                            src={close}
                                            className="cursor_pointer close-2 ms-1"
                                            style={{ height: "20px" }}
                                            onClick={() => {
                                              deleteEducationDetails(educationlist._id);
                                            }}
                                          />
                                          {/* <button
                                            type="button"
                                            onClick={() => {
                                              deleteEducationDetails(educationlist._id);
                                            }}
                                            className="close closeIcon"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span>&times;</span>
                                          </button> */}
                                        </div>
                                      </Row>

                                      <Row>
                                        <p className="pt-1 font-size-15 pl-4" style={{ fontWeight: "500" }}>
                                          {educationlist?.university}
                                        </p>
                                        <p className="text-md pl-4 mb-1">{educationlist?.degree}</p>
                                        <p className="text-sm  pl-4 mb-1">
                                          {educationlist?.startYear}-{educationlist?.endYear}
                                        </p>
                                      </Row>
                                      <Row>
                                        {educationlist?.uploadId?.map((uploadItem: Upload) => {
                                          return (
                                            <Link
                                              to={{ pathname: Util.fileURL(uploadItem?._id) }}
                                              target="_blank"
                                              key={uploadItem._id}
                                              className="text-md pl-4 mb-1"
                                            >
                                              <p>{uploadItem?.originalName}</p>
                                            </Link>
                                          );
                                        })}
                                      </Row>
                                    </Card>
                                  );
                                })}

                                <button
                                  type="button"
                                  className="btn btn-primary w-50 center mb-4 m-auto"
                                  style={{ margin: "auto", display: "block" }}
                                  onClick={() => {
                                    togCenter();
                                  }}
                                >
                                  Add an education
                                </button>

                                <Modal
                                  isOpen={modalCenter}
                                  toggle={() => {
                                    togCenter();
                                  }}
                                  centered
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0">Add education</h5>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        togCenterClose();
                                      }}
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span>&times;</span>
                                    </button>
                                  </div>

                                  <div className="modal-body">
                                    <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Universty<span className="text-danger">*</span>
                                      </Label>

                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Universty"
                                        name="articleTitle"
                                        value={educationRef?.current.university || ""}
                                        onChange={e => setEducationData({ ...educationRef.current, university: e.target.value })}
                                        required
                                      />
                                    </div>

                                    <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Degree<span className="text-danger">*</span>
                                      </Label>

                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Degree"
                                        name="articleTitle"
                                        value={educationRef.current.degree || ""}
                                        onChange={e => setEducationData({ ...educationRef.current, degree: e.target.value })}
                                        required
                                      />
                                    </div>

                                    <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Field of study<span className="text-danger">*</span>
                                      </Label>

                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Field of study"
                                        name="articleTitle"
                                        value={educationRef.current.fieldOfStudy || ""}
                                        onChange={e => setEducationData({ ...educationRef.current, fieldOfStudy: e.target.value })}
                                        required
                                      />
                                    </div>

                                    <Row>
                                      <Col xl="6">
                                        <div className="mb-2">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Start year<span className="text-danger">*</span>
                                          </Label>

                                          <Datetime
                                            dateFormat="YYYY"
                                            timeFormat={false}
                                            inputProps={inputProps}
                                            value={educationRef.current.startYear || ""}
                                            onChange={(date: any) =>
                                              setEducationData({ ...educationRef.current, startYear: typeof date !== "string" ? date.year().toString() : date })
                                            }
                                          />
                                        </div>
                                      </Col>

                                      <Col xl="6">
                                        <div className="mb-2">
                                          <Label htmlFor="formrow-firstname-Input">
                                            End year<span className="text-danger">*</span>
                                          </Label>

                                          <Datetime
                                            dateFormat="YYYY"
                                            timeFormat={false}
                                            inputProps={inputProps1}
                                            value={educationRef.current.endYear || ""}
                                            onChange={(date: any) =>
                                              setEducationData({ ...educationRef.current, endYear: typeof date !== "string" ? date.year().toString() : date })
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    {educationPopupId.current && (
                                      <div className="flex mb-2">
                                        {uploadsRef.current.map((safe: any) => {
                                          return (
                                            <div key={safe._id} className="mr-2">
                                              <div onClick={() => removeEducationSelectedImage(safe._id)} className="event-remove cursor-pointer">
                                                <img src={deleteIcon} style={{ float: "right" }} />
                                              </div>

                                              <Link
                                                to={{ pathname: Util.fileURL(safe._id) }}
                                                target="_blank"
                                                className="events-icon2"
                                                style={{ fontSize: "62px" }}
                                              >
                                                <FileThumbnail file={safe} />
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}

                                    {educationFileRef.current.preview || educationRef.current.uploadId?.path ? (
                                      <div>
                                        {(educationFileRef.current.preview || educationRef.current.uploadId?.path) && (
                                          <label htmlFor="file-upload1" className="file-upload m-auto">
                                            <span className="text-primary pt-5 m-auto cursor-pointer">
                                              <i className="fas fa-file-upload me-2"></i>Click here to modify the currently selected file(s)
                                            </span>
                                            <input
                                              id="file-upload1"
                                              type="file"
                                              style={{ display: "none" }}
                                              accept="image/png,image/jpeg,application/pdf,image/doc"
                                              onChange={selectEducationFile}
                                              multiple
                                            />
                                          </label>
                                        )}

                                        <div>
                                          <div className="flex">
                                            <div style={{ fontSize: "62px" }}>{project()}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {!educationFileRef.current.preview && !educationRef.current.uploadId?.path && (
                                      <label
                                        htmlFor="file-upload1"
                                        style={{ border: "1px dashed" }}
                                        className=" mb-4 file_upload file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer upload-box"
                                      >
                                        <div className="flex flex-wrap items-center file_upload_content">
                                          <span className="text-black">
                                            <i className="fa fa-upload cloud-upload"></i>&nbsp;&nbsp;Upload your disclosure statement
                                          </span>
                                        </div>

                                        <span className="text-sm file_upload_p">.PDF, .JPG, .PNG</span>

                                        <input
                                          id="file-upload1"
                                          type="file"
                                          style={{ display: "none" }}
                                          className="file_upload_input mb-4"
                                          accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                          onChange={selectEducationFile}
                                          multiple
                                        />
                                      </label>
                                    )}

                                    <label htmlFor="required-fields">
                                      <span className="text-danger">*</span> <i>Indicates required fields.</i>
                                    </label>

                                    {educationPopupId.current ? (
                                      <div className="grid grid-cols-2 gap-4 flex pt-20">
                                        <div
                                          className="btn btn-primary mb-4 w-50"
                                          style={{ margin: "auto", display: "block" }}
                                          onClick={() => {
                                            updateEducationDetails();
                                          }}
                                        >
                                          Update
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="btn btn-primary mb-4 w-50"
                                        style={{ margin: "auto", display: "block" }}
                                        onClick={() => {
                                          saveEducationDetails();
                                        }}
                                      >
                                        Save
                                      </div>
                                    )}
                                  </div>
                                </Modal>
                              </Col>

                              <Col xl="5">
                                <div className="card-heading mb-4">
                                  <h5 className="font-size-14 mb-2">Licenses & Certificates</h5>
                                </div>

                                {licensesDetailsRef.current.length == 0 && <div className="font-size-14 mb-5 mt-1">No license details to display.</div>}

                                {licensesDetailsRef.current.map((list: any) => {
                                  return (
                                    <Card className="p-2 card_shadow" key={list._id}>
                                      <Row>
                                        <div className="flex float_right" style={{ justifyContent: "end" }}>
                                          <img src={pencil} className="cursor_pointer" onClick={() => setShowLicensesDetails(list?._id)}></img>
                                          <img
                                            src={close}
                                            className="cursor_pointer close-2 ms-1"
                                            style={{ height: "20px" }}
                                            onClick={() => {
                                              deleteLicensesDetails(list._id);
                                            }}
                                          />
                                          {/* <button
                                            type="button"
                                            onClick={() => {
                                              deleteLicensesDetails(list._id);
                                            }}
                                            className="close-2 closeIcon"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span>&times;</span>
                                          </button> */}
                                        </div>
                                      </Row>

                                      <Row>
                                        <p className="pt-1 font-size-15 pl-4 " style={{ fontWeight: "500" }}>
                                          {list?.title}
                                        </p>
                                        {list?.uploadId?.map((uploadItem: Upload) => {
                                          return (
                                            <Link
                                              to={{ pathname: Util.fileURL(uploadItem?._id) }}
                                              target="_blank"
                                              key={uploadItem._id}
                                              className="text-md pl-4 mb-1"
                                            >
                                              <p>{uploadItem?.originalName}</p>
                                            </Link>
                                          );
                                        })}
                                      </Row>
                                    </Card>
                                  );
                                })}

                                <button
                                  type="button"
                                  className="btn btn-primary w-50 center m-auto"
                                  style={{ margin: "auto", display: "block" }}
                                  onClick={() => {
                                    togCenter2();
                                  }}
                                >
                                  Add a license
                                </button>

                                <Modal
                                  isOpen={modalCenter2}
                                  toggle={() => {
                                    togCenter2();
                                  }}
                                  centered
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0">Add licenses & certificates</h5>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        togCenter2Close();
                                      }}
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span>&times;</span>
                                    </button>
                                  </div>

                                  <div className="modal-body">
                                    <div className="mb-2">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Title of document<span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Title of document"
                                        name="articleTitle"
                                        value={licensesRef.current.title || ""}
                                        onChange={e => setLicensesData({ ...licensesRef.current, title: e.target.value })}
                                        required
                                      />
                                    </div>

                                    {licensesPopupId.current && (
                                      <div className="flex  mb-2">
                                        {uploadsRef.current.map((safe: any) => {
                                          return (
                                            <div key={safe._id} className="mr-2">
                                              <div onClick={() => removeLicensesSelectedImage(safe._id)} className="event-remove cursor-pointer">
                                                <img src={deleteIcon} style={{ float: "right" }} />
                                              </div>

                                              <Link
                                                to={{ pathname: Util.fileURL(safe._id) }}
                                                target="_blank"
                                                className="events-icon2"
                                                style={{ fontSize: "62px" }}
                                              >
                                                <FileThumbnail file={safe} />
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}

                                    {licensesFileRef.current.preview || licensesRef.current.uploadId?.path ? (
                                      <div className="mt-3 mb-3">
                                        {(licensesFileRef.current.preview || licensesRef.current.uploadId?.path) && (
                                          <div className="d-flex flex-column align-items-center">
                                            <label htmlFor="file-upload1" className="file-upload text-center" style={{ width: 'fit-content', padding: '8px 16px', cursor: 'pointer' }}>
                                              <span className="text-primary cursor-pointer">
                                                <i className="fas fa-file-upload me-2"></i>Click here to modify the currently selected file(s)
                                              </span>

                                              <input
                                                id="file-upload1"
                                                type="file"
                                                style={{ display: "none" }}
                                                accept="image/png,image/jpeg,application/pdf,image/doc"
                                                onChange={selectLicensesFile}
                                                multiple
                                              />
                                            </label>
                                            <div className="mt-2">
                                              <div className="flex justify-content-center">
                                                <div style={{ fontSize: "62px" }}>{licensesProject()}</div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-3 mb-3">
                                        <label
                                          htmlFor="file-upload1"
                                          style={{ border: "1px dashed", display: "block" }}
                                          className="mb-4 file_upload file-upload relative flex flex-col text-gray-400 z-10 border-custom-text-gray-500 rounded cursor-pointer upload-box"
                                        >
                                          <div className="flex flex-wrap items-center pl-5 pt-4 pb-1 file_upload_content">
                                            <img src={uploadIcon} />
                                            <p className="m-0 text-black">
                                              &nbsp;&nbsp;Select relevant file(s)<span className="text-danger">*</span>
                                            </p>
                                          </div>

                                          <p className="m-0 pl-10 text-sm pb-2 file_upload_p">Allowed: .pdf, .png, .jpg, .jpeg</p>

                                          <input
                                            id="file-upload1"
                                            type="file"
                                            style={{ display: "none" }}
                                            className="file_upload_input mb-4"
                                            accept="image/png,image/jpeg,application/pdf,image/doc"
                                            onChange={selectLicensesFile}
                                            multiple
                                          />
                                        </label>
                                      </div>
                                    )}

                                    <label htmlFor="required-fields">
                                      <span className="text-danger">*</span> <i>Indicates required fields.</i>
                                    </label>

                                    {licensesPopupId.current ? (
                                      <div className="grid grid-cols-2 gap-4 flex pt-20">
                                        <div
                                          className="btn btn-primary mb-4 w-50"
                                          style={{ margin: "auto", display: "block" }}
                                          onClick={() => {
                                            updateLicensesDetails();
                                          }}
                                        >
                                          Update
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="btn btn-primary mb-4 w-50"
                                        style={{ margin: "auto", display: "block" }}
                                        onClick={() => {
                                          saveLicensesDetails();
                                        }}
                                      >
                                        Save
                                      </div>
                                    )}
                                  </div>
                                </Modal>
                              </Col>
                            </Row>
                          ) : (
                            <Spinner />
                          )}
                        </TabPane>

                        <TabPane tabId="7">
                          <Row className="col-xl-12">
                            {user?.role == "THERAPIST" && reviewTherapist.length == 0 && <div className="font-size-14 mb-5 mt-1">No Reviews to display.</div>}

                            {user?.role == "THERAPIST" &&
                              reviewTherapist.map((reviewDate, _id) => {
                                const result: any[] = [];
                                const results: any[] = [];
                                for (let i = 0; i < reviewDate.stars; i++) {
                                  result.push(
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
                                      <path
                                        d="M19.9478 8.05776C19.8169 7.63517 19.4577 7.33502 19.0327 7.29506L13.2602 6.74811L10.9776 1.17306C10.8093 0.764478 10.426 0.5 10.0001 0.5C9.5742 0.5 9.19089 0.764478 9.02258 1.17401L6.73996 6.74811L0.966512 7.29506C0.542308 7.33598 0.184023 7.63517 0.0523364 8.05776C-0.0793501 8.48035 0.0422653 8.94386 0.363165 9.23605L4.72652 13.2292L3.43987 19.1434C3.34572 19.5782 3.50747 20.0277 3.85324 20.2885C4.03909 20.4287 4.25654 20.5 4.47581 20.5C4.66487 20.5 4.85241 20.4468 5.02072 20.3417L10.0001 17.2363L14.9776 20.3417C15.3419 20.5704 15.801 20.5495 16.146 20.2885C16.4919 20.0269 16.6535 19.5773 16.5594 19.1434L15.2727 13.2292L19.6361 9.23684C19.957 8.94386 20.0795 8.48115 19.9478 8.05776Z"
                                        fill="#F2C94C"
                                      />
                                    </svg>
                                  );
                                }

                                for (let i = reviewDate?.stars; i < 5; i++) {
                                  results.push(
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
                                      <path
                                        d="M19.9478 8.05776C19.8169 7.63517 19.4577 7.33502 19.0327 7.29506L13.2602 6.74811L10.9776 1.17306C10.8093 0.764478 10.426 0.5 10.0001 0.5C9.5742 0.5 9.19089 0.764478 9.02258 1.17401L6.73996 6.74811L0.966512 7.29506C0.542308 7.33598 0.184023 7.63517 0.0523364 8.05776C-0.0793501 8.48035 0.0422653 8.94386 0.363165 9.23605L4.72652 13.2292L3.43987 19.1434C3.34572 19.5782 3.50747 20.0277 3.85324 20.2885C4.03909 20.4287 4.25654 20.5 4.47581 20.5C4.66487 20.5 4.85241 20.4468 5.02072 20.3417L10.0001 17.2363L14.9776 20.3417C15.3419 20.5704 15.801 20.5495 16.146 20.2885C16.4919 20.0269 16.6535 19.5773 16.5594 19.1434L15.2727 13.2292L19.6361 9.23684C19.957 8.94386 20.0795 8.48115 19.9478 8.05776Z"
                                        stroke="#F2C94C"
                                      />
                                    </svg>
                                  );
                                }

                                return (
                                  <Col xl="6" md="6" sm="12" key={_id} className="flex-list odd-child col">
                                    <Card className="discover-cardp-1 border shadow-none card" style={{ display: "flex", flex: "1 1 50%" }}>
                                      <CardBody className="card-main-sec">
                                        <div className="card-s1">
                                          <div className="card-s2">
                                            <div className="card-s3-1">
                                              {!reviewDate?.clientId?.photoId ? (
                                                <div className="avatar-md mx-auto mb-4">
                                                  <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>
                                                    {reviewDate?.clientId?.firstname?.charAt(0)}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="mb-4">
                                                  <img className="rounded-circle avatar-md imageFit" src={Util.fileURL(reviewDate?.clientId?.photoId?._id)} />
                                                </div>
                                              )}
                                            </div>

                                            <div className="card-s2-2">
                                              <h5 className="font-size-15 mb-1">
                                                {!reviewDate?.clientId?.firstname && !reviewDate?.clientId?.lastname ? (
                                                  <span className="text-dark">#Client</span>
                                                ) : (
                                                  <span className="text-dark">
                                                    <span>
                                                      {reviewDate?.clientId?.firstname}
                                                      {reviewDate?.clientId?.lastname}
                                                    </span>
                                                  </span>
                                                )}
                                              </h5>

                                              <Col xl="12" style={{ display: "flex" }}>
                                                {result}
                                                {results}
                                              </Col>
                                            </div>
                                          </div>
                                        </div>
                                        <Row className="mb-2">
                                          <Col xl={8}>
                                            <span className="text-muted">{reviewDate?.review}</span>
                                          </Col>
                                          <Col xl={4}>
                                            <span style={{ display: "flex", float: "right" }}>{moment(reviewDate?.createdAt).fromNow()}</span>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                );
                              })}
                          </Row>
                        </TabPane>
                        <TabPane tabId="8">
                          <StripeDetails />
                        </TabPane>
                        <TabPane tabId="9">
                          <Elements stripe={stripePromise}>
                            <CopaymentDetails />
                          </Elements>
                        </TabPane>
                        <TabPane tabId="10">
                          <NotesSettings></NotesSettings>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
