import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { CustomerReviewData } from "src/models/Review";
import Spinner from "../../common/spinner/spinner";

const ViewTherapistsSOAPReview: React.FC = () => {
  const [pendingReviews, setPendingReviews] = useState([] as CustomerReviewData[]);
  const [isLoading, setIsLoading] = useState(true);

  const LIMIT_P_C = 10;

  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  useEffect(() => {
    AdminService.getAllTherapistSOAPReviews(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setPendingReviews(res.data);
        setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
      } else {
        setSeeMore_p_c(false);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const pendingSeeMore = () => {
    const updatedSkip = isOffset_p_c + LIMIT_P_C;
    setOffset_p_c(updatedSkip);

    AdminService.getAllTherapistSOAPReviews(LIMIT_P_C, updatedSkip).then(res => {
      if (res.success) {
        setPendingReviews((prev: any) => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
      } else {
        setSeeMore_p_c(false);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"SOAP Reviews"} breadcrumbItem={"SOAP Reviews"} />

          <Card>
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  <div className="table-responsive">
                    {isLoading ? (
                      <Spinner />
                    ) : pendingReviews.length > 0 ? (
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Review</th>
                            <th scope="col">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingReviews &&
                            pendingReviews.map((client, index) => (
                              <tr key={client?._id}>
                                <td>
                                  {client.therapistId?.firstname} {client.therapistId?.lastname}
                                </td>
                                <td>
                                  {client.review}
                                </td>
                                <td>
                                  {client.stars}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <div>No Pending Reviews</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12 mt30 text-center">
                  {seeMore_p_c && (
                    <button className="btn btn-warning" onClick={() => pendingSeeMore()}>
                      See More
                    </button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewTherapistsSOAPReview;
