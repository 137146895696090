import React, { MouseEventHandler, useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, Col, Input, Modal, Row } from "reactstrap";
import bgIcon from "../../assets/images/layouts/Intersect.png";
import closeIcon from "../../assets/images/icons/close_icon.png";
import videoIcon from "../../assets/images/icons/fi_video.png";
import chatIcon from "../../assets/images/icons/u_comment-dots.png";
import calendarIcon from "../../assets/images/icons/u_calender.png";
import linkIcon from "../../assets/images/icons/fi_link.svg"
import { Carousel } from 'react-bootstrap';
import { FriendRequest } from "src/models/FriendRequest";
import { Util } from "src/Util";
import { NavLink, useHistory } from "react-router-dom";
import { Therapist } from "src/models/Therapist";
import Close from "../../assets/images/icons/fi_x-circle.png";
import Cover from "../../assets/images/icons/modal cover.svg";
import Coppy from "../../assets/images/icons/fi_copy.png";
import Share from "../../assets/images/icons/fi_share-2.png";
import Email from "../../assets/images/icons/fi_mail.png";
import Message from "../../assets/images/icons/message.png";
import { environment } from "src/environment/environment";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { ClientService } from "src/services/ClientService";
import UserContext from "src/context/UserContext";
import Swal from "sweetalert2";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import { NotificationService } from "src/services/NotificationService";
import { SocketContext } from "src/context/ScoketContext";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import images from "../../assets/images";
import SketetonClientMatches from "src/common/skeleton/SkeltonClientMatches";

interface matchedTherapistListProps {
  matchedTherapists: FriendRequest[]
  onNext: MouseEventHandler<HTMLButtonElement>;
  onBack: MouseEventHandler<HTMLButtonElement>;
  requestTherapistCount: number;
  offset: number;
  isLoading: boolean;
  sentSelectedUser: (data: any) => void;
}
const ClientMatches: React.FC<matchedTherapistListProps> = ({ matchedTherapists, onNext, onBack, requestTherapistCount, offset, isLoading, sentSelectedUser }) => {

  const [user] = useContext(UserContext);
  const [therapist, setTherapist] = useState({} as Therapist);
  const [modalcentervideo, setModalCenterVideo] = useState<boolean>(false);
  const [togleModalReferal, setTogleModalReferal] = useState(false);
  const [referalTherapistId, setTreferalTherapistId] = useState("");
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [emailAddress, setEmailAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState('');
  const history = useHistory();
  const [url, setUrl] = useState("")
  const socket = useContext(SocketContext);
  const [nextEventReady, setNextEventReady] = useState(true);
  const [requestList, setRequestList] = useState([] as FriendRequest[]);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const SCREEN_SIZE = 991;


  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    setRequestList(matchedTherapists);
  }, [matchedTherapists]);

  useEffect(() => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-user-details/" + referalTherapistId;
    setUrl(userPublicUrl);
  }, [referalTherapistId]);

  const navigateToCreateAppointment = (request: FriendRequest) => {
    history.push(`/create-appointment/${request.therapistId._id}`);

  }

  const watchvideo = (request: Therapist) => {
    setTherapist(request)
    setModalCenterVideo(true);
  }

  const referalModal = () => {
    setTogleModalReferal(!togleModalReferal)
  }

  const getTherapistId = (userId: string | undefined) => {

    if (userId !== undefined) {
      setTreferalTherapistId(userId);
    }
  }
  const handleCopyURL = () => {
    const splittedURL = environment.app_url;
    const userPublicUrl = splittedURL + "/public-user-details/" + referalTherapistId;
    navigator.clipboard
      .writeText(userPublicUrl)
      .then(() => {
        toast.success("Link copied to clipboard! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      })
      .catch(() => {
        toast.error("Link is not copied try again! ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      });
  };

  const handleShare = () => {
    setShowSharePopup(!showSharePopup);
  };

  const sendEmail = () => {
    const data = { url, emailAddress }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailAddress.match(emailRegex)) {
      toast.error("Please enter valid Email Address", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      ClientService.sendReferrealEmail(data).then(res => {
        if (res.success) {
          toast.success(`You have sent email successfully!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setEmailAddress("")
          setShowSharePopup(!showSharePopup)
        } else {
          toast.error(` Email sending failed!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setEmailAddress("")
        }
      });
    }
  }

  const sendSMS = () => {
    const data = { url, phoneNumber }
    const phoneno = /^\+1[0-9]{10}$/;

    if (!phoneNumber.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      ClientService.sendReferrealMessage(data).then(res => {
        if (res.success) {
          toast.success(`You have sent SMS successfully!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setPhoneNumber("")
          setShowSharePopup(!showSharePopup)
        } else {
          toast.error(` SMS sending failed!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          setPhoneNumber("")
        }
      });
    }
  }

  const navigateToChat = (request: any) => {
    let userId = "";
    if (user?.role == "CLIENT") {
      userId = request._id;
    }
    history.push(`/chat/${userId}`);
  };

  const removeFriend = (request: FriendRequest) => {
    if (!nextEventReady) {
      toast.error(`Please wait until previous request is completed!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }


    Swal.fire({
      icon: "warning",
      title: "Are you sure want to stop seeing this therapist? (Please note this will remove all your scheduled appointments with this therapist.) Do you want to proceed?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "Later",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        setNextEventReady(false);

        ClientService.unfriendTherapist(request._id).then(res => {
          setRequestList(requestList.filter((req: FriendRequest) => req._id !== request._id));
          setNextEventReady(true);

          const notificationData: NotificationModel = {
            senderId: user?._id,
            receiverId: request?.therapistId._id,
            event: NotificationEvent.REQUEST_REMOVED,
            link: "/dashboard",
            content: "You have removed from friend list By " + user?.firstname + " " + user?.lastname,
            variant: NotificationVarient.WARNING,
          };

          NotificationService.sendNotification(notificationData).then((res: any) => {
            const socketData: sendNotificationSocketData = {
              socketId: res.data.socketId,
              notifyData: notificationData,
              receiverId: request?.therapistId?._id,
              senderId: user?._id,
            };

            socket.emit("send-notification", socketData);
          });
          toast.success(`You have unfriend successfully!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Row className="pt-4">
        {requestList?.map((matchedTherapist: any, index: number) =>
          <Col key={index} md={4} className="d-flex justify-content-center">
            <Card className="match-card-dashboard position-relative max-w-263">
              <div className="d-flex justify-content-between">
                <img src={bgIcon} alt="Icon" className="card-img-top " style={{ width: '123.68px', height: '85.72px' }} />
                <img src={closeIcon} alt="Icon" className="closeIcon-sizes mt-1 cursor-pointer me-1 z-index-100" onClick={() => removeFriend(matchedTherapist)} />
              </div>
              <CardBody className="d-flex flex-column justify-content-center align-items-center match-card-dashboard-body">
                <NavLink to={"#"}>
                  <span onClick={() => sentSelectedUser(matchedTherapist?.therapistId)}>
                    {!matchedTherapist?.therapistId?.photoId || matchedTherapist?.therapistId?.photoId == null ||
                      matchedTherapist?.therapistId?.photoId == undefined ? (
                      <div className="avatar-md mx-auto mb-4">
                        <span className={"avatar-title rounded-circle bg-soft font-size-16 card-avatar"}>
                          {matchedTherapist?.therapistId?.firstname?.charAt(0)}
                        </span>
                      </div>
                    ) : (
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <img src={Util.fileURL(matchedTherapist?.therapistId?.photoId?._id)} className="avatar-md rounded-circle" />
                      </div>
                    )}
                  </span>
                </NavLink>
                <h5 className="mb-0 d-flex mt-2 justify-content-start fs-14 align-items-start fc-black">{matchedTherapist.therapistId.firstname}</h5>
                <p className="mb-0">THERAPIST</p>
                <hr className="match-card-hr" />
                <div className="d-flex justify-content-center gap-4 min-width-100">
                  <button className="btn btn-primary btn-size-40 call-btn-bg" onClick={() => watchvideo(matchedTherapist.therapistId)}><img src={videoIcon} className="btn-icon-2" /></button>

                  <button className="btn btn-primary btn-size-40 calendar-btn-bg " onClick={() => { referalModal(), getTherapistId(matchedTherapist.therapistId._id) }}><img src={linkIcon} className="btn-icon-2" /></button>
                </div>
                <div>
                  <button className="btn btn-primary btn-sm schedule-app-btn-bg mt-3" onClick={() => navigateToCreateAppointment(matchedTherapist)}><img src={calendarIcon} className="me-2" />Schedule Appointment</button>
                </div>

              </CardBody>
              <CardFooter className="match-card-footer" style={{ height: "65px", backgroundColor: "#EDEDED" }}>
                <Carousel indicators={false} interval={2000} controls={false} className="pt-1">
                  {matchedTherapist?.therapistId?.experiencedIn.map((experience: any, index: number) =>
                    <Carousel.Item key={index}>
                      <div className="match-footer-card-item d-flex justify-content-center align-items-center">
                        <span className="p-3 pt-2 pb-2">{experience.experienceTag.length > 20
                          ? `${experience.experienceTag.substring(0, 20)}...` : experience.experienceTag}</span>
                      </div>
                    </Carousel.Item>
                  )}


                </Carousel>

                {matchedTherapist?.therapistId?.experiencedIn?.length == 0 && <div className="text-upcoming">No experience tags</div>}
                <div className="">
                </div>
              </CardFooter>
            </Card>
          </Col>
        )}
      </Row>
      {requestList?.length == 0 && !isLoading && <div className="text-upcoming">No Matched Therapists</div>}
      <Row>
        {isLoading && (
          <SketetonClientMatches />
        )}
        {screenSize > SCREEN_SIZE ? <div className="d-flex justify-content-between ">
          <div>
            {offset > 1 && !isLoading && (
              <button className="btn btn-secondary btn-sm" onClick={onBack}>
                <i className="bx bx-left-arrow-alt me-2"></i> Back
              </button>
            )}
          </div>
          <div>
            {requestList?.length > 2 && (
              <button className="btn btn-primary btn-sm" onClick={onNext}>
                Next <i className="bx bx-right-arrow-alt ms-2"></i>
              </button>
            )}
          </div>
        </div>
          :

          <div className="d-flex justify-content-between ">
            <div>
              {offset > 1 && !isLoading && (
                <button className="btn btn-secondary btn-sm mb-4" onClick={onBack}>
                  <i className="bx bx-left-arrow-alt me-2"></i> Back
                </button>
              )}
            </div>
            <div>
              {requestList?.length >= 1 && (
                <button className="btn btn-primary btn-sm mb-4" onClick={onNext}>
                  Next <i className="bx bx-right-arrow-alt ms-2"></i>
                </button>
              )}
            </div>
          </div>
        }

      </Row>

      {/* referal link modal */}
      <Modal isOpen={togleModalReferal} toggle={referalModal} centered className="modal-lg">

        <div
          className="modal-body"
          style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", padding: "0px" }}
        >
          <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "2vh" }}>
            <div className="image-container2">
              <img src={Cover} className="meeting-ended-image-size" alt="Meeting Cover" />

              <div className="d-flex justify-content-center py-5 referal-link-btn">
                <input type="url" value={url} className="form-control form-control-sm mr-2" placeholder="Enter URL" />
                <button className="btn btn-primary referal-option-btn mr-2" onClick={handleCopyURL}><img src={Coppy} alt="coppy" /></button>
                <button className="btn btn-primary referal-option-btn" onClick={handleShare}> <img src={Share} alt="share" /></button>
              </div>

              <div className="button-container btn-container-position-top">
                <button
                  type="button"
                  onClick={
                    referalModal
                  }
                  className="bg-transparent-color"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={Close} alt="Close" className="" />
                </button>
              </div>
            </div>
          </div>
          {showSharePopup && (
            <div className="d-flex  py-2 referal-link-btn row">
              <div className="d-flex justify-content-center align-items-center col-md-6 col-12 mb-2 mb-md-0">
                <PhoneInput
                  value={phoneNumber}
                  placeholder="Enter phone number"
                  className="mr-2 sms-referral-input-mob"
                  defaultCountry="US"
                  onChange={(value: string) => setPhoneNumber(value)}
                />
                <button className="btn btn-success d-flex justify-content-center align-items-center btn-sm via-message-btn" onClick={sendSMS}>
                  <span className="d-none d-md-block">Via Message</span>
                  <img src={Message} className="mx-2" />
                </button>
              </div>

              <div className="d-flex col-md-6 col-12 justify-content-md-end justify-content-center">
                <Input
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  placeholder="Enter Email Address"
                  required
                  className="email-referral-input mr-2"
                />
                <button className="btn btn-success d-flex justify-content-center align-items-center btn-sm" onClick={sendEmail} >
                  <span className="d-none d-md-block">Via Email</span>
                  <img src={Email} alt="email-icon" className="mx-2" />
                </button>
              </div>
            </div>
          )}

        </div>
      </Modal>

      {
        modalcentervideo &&
        <div className="lightbox1" onClick={() => {
          setModalCenterVideo(false);
        }}>
          <div id="videoModal1" className="modal1 hide1 fade1 in1" tabIndex={-1} role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false">
            {
              therapist.vimeoId ? (
                <>
                  <div className="modal-body1">
                    <iframe src={'https://player.vimeo.com/video/' + therapist?.vimeoId} width="800" className="videoSize" height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Lavni Health"></iframe>
                  </div>
                </>
              ) : (
                <>
                  <div className="transcode-status-profile">No video is added by {therapist.firstname} {therapist.lastname} {!(therapist.firstname && therapist.lastname) && 'Setected User'}</div>
                </>

              )
            }
          </div>
        </div>
      }
    </React.Fragment >
  );
};

export default ClientMatches;
