export enum NotificationEvent {
  REQUEST_SENT = "REQUEST_SENT",
  REQUEST_APPROVED = "REQUEST_APPROVED",
  APPOINMENT_CREATED = "APPOINMENT_CREATED",
  APPOINMENT_UPDATED = "APPOINMENT_UPDATED",
  APPOINMENT_DELETED = "APPOINMENT_DELETED",
  HOMEWORK_ASSIGNED = "HOMEWORK_ASSIGNED",
  HOMEWORK_UPDATED = "HOMEWORK_UPDATED",
  HOMEWORK_APPROVED = "HOMEWORK_APPROVED",
  HOMEWORK_REJECTED = "HOMEWORK_REJECTED",
  GOAL_ASSIGNED = "GOAL_ASSIGNED",
  GOAL_UPDATED = "GOAL_UPDATED",
  GOAL_COMPLETED = "GOAL_COMPLETED",
  MISSED_CALL = "MISSED_CALL",
  BECAME_PREMIUM = "BECAME_PREMIUM",
  REQUEST_REMOVED = "REQUEST_REMOVED",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
  GRP_CHAT_MEMBER_ADDED = "GRP_CHAT_MEMBER_ADDED",
  GRP_CHAT_MEMBER_REMOVED = "GRP_CHAT_MEMBER_REMOVED",
  GRP_CHAT_JOIN_REQUEST_SEND = "GRP_CHAT_JOIN_REQUEST_SEND",
  GRP_CHAT_JOIN_REQUEST_CANCELED = "GRP_CHAT_JOIN_REQUEST_CANCELED",
  GRP_CHAT_JOIN_REQUEST_ACCEPTED = "GRP_CHAT_JOIN_REQUEST_ACCEPTED",
  GRP_CHAT_JOIN_REQUEST_REJECTED = "GRP_CHAT_JOIN_REQUEST_REJECTED",
  NEW_TWILIO_MESSAGE_FROM_CLIENT = "NEW_TWILIO_MESSAGE_FROM_CLIENT",
}

export enum NotificationVarient {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

export interface NotificationModel {
  _id?: string;
  senderId?: string;
  receiverId?: string;
  event: NotificationEvent;
  link: string;
  content: string;
  variant?: NotificationVarient;
  createdAt?: Date;
  isVideoCallNotificaton?: boolean;
  isPremiumUserNotificationRead?: boolean;
}

export interface NotificationDetails {
  notifications: NotificationModel[];
  unreadCount: number;
}
