import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, Col, Container, Input, Row } from "reactstrap";
import UserContext from "../../../context/UserContext";
import moment from "moment";
import { Ethinicity } from "../../../models/Ethnicity";
import { CommonService } from "../../../services/CommonService";
import { UserDetails, UserDetails2 } from "../../../models/User";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Profession } from "../../../models/Profession";
import { ArticleService } from "../../../services/ArticleService";
import { ExperienceTag, SelectedExperienceTag } from "../../../models/ExperienceTag";
import { WorkingHour } from "../../../models/WorkingHour";
import { Role } from "../../../models/Role";
import { SelectedInsuranceCompanyTag } from "../../../models/InsuranceCompany";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { RouteName } from "src/RouteName";

const TherapistPersonalInformationOnboardingPage: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const history = useHistory();
  const [dateValue, setDateValue] = useState(user?.dateOfBirth ? moment(user?.dateOfBirth).format("YYYY-MM-DD") : "");
  const [setEthenicity, setUpdateEthenacity] = useState("");
  const [ethinicityList, setEthinicityList] = useState<Ethinicity[]>([]);
  const [profession, setProfession] = useState({} as Profession);
  const [searchExpTag, setSearchExpTag] = useState<ExperienceTag[]>([]);
  const [expereinceYears, setExpereinceYears] = useState(0);
  const [newWorkingHour, setNewWorkingHour] = useState({} as WorkingHour);
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([] as WorkingHour[]);
  const [selectedExpTags, setSelectedExpTags] = useState<SelectedExperienceTag[]>([] as SelectedExperienceTag[]);
  const [selecteInsuranceCompanyTags, setSelectedInsuranceCompanyTags] = useState<SelectedInsuranceCompanyTag[]>([] as SelectedInsuranceCompanyTag[]);
  const [professionsDetails, setProfessionsDetails] = useState<Profession[]>([] as Profession[]);
  const [statementFile, setStatementFile] = useState({ preview: "", preview2: "", raw: "" });
  const statementFileRef = useRef<any>();
  const [timezones] = useState([
    { value: "-1", label: "Select" },
    { value: "Hawaii Standard Time", label: "Hawaii Standard Time" },
    { value: "Hawaii-Aleutian Daylight Time", label: "Hawaii-Aleutian Daylight Time" },
    { value: "Alaska Daylight Time", label: "Alaska Daylight Time" },
    { value: "Pacific Daylight Time", label: "Pacific Daylight Time" },
    { value: "Mountain Standard Time", label: "Mountain Standard Time" },
    { value: "Mountain Daylight Time", label: "Mountain Daylight Time" },
    { value: "Central Daylight Time", label: "Central Daylight Time" },
    { value: "Eastern Daylight Time", label: "Eastern Daylight Time" },
  ]);

  statementFileRef.current = statementFile;

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthinicityList(res.data);
    });

    getAllExperienceTags();

    const updateAdd = user?.experiencedIn?.map((tag: { experienceTag: any; _id: any }) => {
      return { label: tag.experienceTag, value: tag._id };
    });

    if (updateAdd) {
      setSelectedExpTags(updateAdd);
    }

    const updateInsurance = user?.insuranceCompanies?.map((tag: { insuranceCompany: any; _id: any }) => {
      return { label: tag?.insuranceCompany, value: tag?._id };
    });

    if (updateInsurance) {
      setSelectedInsuranceCompanyTags(updateInsurance);
    }

    const workingHours = user?.workingHours;

    if (workingHours) {
      setWorkingHours(workingHours);
    }

    setProfession(user?.profession || professionsDetails[0]);
  }, []);

  const getAllExperienceTags = () => {
    ArticleService.getAllExperienceTags().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.experienceTag };
        });
        setSearchExpTag(tagList);
      } else {
        setSearchExpTag([]);
      }
    });
  };

  const updateTherapistDetails = () => {
    if (!user?.firstname) {
      toast.error("first name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.lastname) {
      toast.error("last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.gender) {
      toast.error("Gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.username) {
      toast.error("User name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!dateValue as any) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    } else if (!user?.zipCode) {
      toast.error("Zipcode is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.state) {
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.timeZone || user?.timeZone == "-1") {
      toast.error("Timezone is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const updatedUser: UserDetails2 = {
        firstname: user?.firstname,
        lastname: user?.lastname,
        gender: user?.gender,
        zipCode: user?.zipCode,
        state: user?.state,
        username: user?.username,
        dateOfBirth: dateValue as any,
        deletingStatementId: "none",
        email: user?.email,
        primaryPhone: user?.primaryPhone,
        ethnicityId: (setEthenicity as any) || user?.ethnicityId?._id,
        description: user?.description,
        profession: profession?._id || user?.profession?._id,
        yearsOfExperience: expereinceYears || user?.yearsOfExperience,
        experiencedIn: selectedExpTags.map(tag => {
          return tag.label;
        }),
        insuranceCompanies: selecteInsuranceCompanyTags.map(tag => {
          return tag.label;
        }),
        workingHours: user?.workingHours,
        timeZone: user?.timeZone,
      };
      CommonService.updateTherapistProfile(updatedUser, statementFileRef.current.raw).then(res => {
        if (res.success) {
          setUser(res.data);
          history.push(RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  const updateClientDetails = () => {
    if (!user?.firstname) {
      toast.error("First name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.lastname) {
      toast.error("Last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.gender) {
      toast.error("Gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.username) {
      toast.error("Username is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!dateValue as any) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.zipCode) {
      toast.error("Zipcode is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!user?.state) {
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const updatedUser: UserDetails = {
        firstname: user?.firstname,
        lastname: user?.lastname,
        username: user?.username,
        gender: user?.gender,
        zipCode: user?.zipCode,
        state: user?.state,
        dateOfBirth: dateValue as any,
        email: user?.email,
        primaryPhone: user?.primaryPhone,
        ethnicityId: (setEthenicity as any) || user?.ethnicityId?._id,
        description: user?.description,
        incognito: user?.incognito as any,
      };

      CommonService.updateClientProfile(updatedUser).then(res => {
        if (res.success) {
          setUser(res.data);
          history.push(RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE);
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }

  function handlePostalCode(e: any) {
    const selectedState = getStateByZipCode(e);
    setUser({ ...user, state: selectedState ? selectedState : "", zipCode: e });
  }

  function handleTimezone(e: any) {
    setUser({ ...user, timeZone: e.target.value });
  }

  let state;

  function getStateByZipCode(zipString: string) {
    if (typeof zipString != "string") {
      toast.error("Must pass the zipcode as a string.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return false;
    }

    if (zipString.length != 5) {
      return false;
    }

    const zipcode = parseInt(zipString, 10);

    if (zipcode >= 35000 && zipcode <= 36999) {
      state = "Alabama";
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      state = "Alaska";
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      state = "Arizona";
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      state = "Arkansas";
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      state = "California";
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      state = "Colorado";
    } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
      state = "Connecticut";
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      state = "Delaware";
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      state = "Florida";
    } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
      state = "Georgia";
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      state = "Hawaii";
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      state = "Idaho";
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      state = "Illinois";
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      state = "Indiana";
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      state = "Iowa";
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      state = "Kansas";
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      state = "Kentucky";
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      state = "Louisiana";
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      state = "Maine";
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      state = "Maryland";
    } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode == 5501 || zipcode == 5544) {
      state = "Massachusetts";
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      state = "Michigan";
    } else if (zipcode >= 55000 && zipcode <= 56899) {
      state = "Minnesota";
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      state = "Mississippi";
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      state = "Missouri";
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      state = "Montana";
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      state = "North Carolina";
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      state = "North Dakota";
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      state = "Nebraska";
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      state = "Nevada";
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      state = "New Hampshire";
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      state = "New Jersey";
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      state = "New Mexico";
    } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
      state = "New York";
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      state = "Ohio";
    } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
      state = "Oklahoma";
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      state = "Oregon";
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      state = "Pennsylvania";
    } else if (zipcode >= 300 && zipcode <= 999) {
      state = "Puerto Rico";
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      state = "Rhode Island";
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      state = "South Carolina";
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      state = "South Dakota";
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      state = "Tennessee";
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
      state = "Texas";
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      state = "Utah";
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      state = "Vermont";
    } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode == 20598) {
      state = "Virginia";
    } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
      state = "Washington DC";
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      state = "Washington";
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      state = "West Virginia";
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      state = "Wisconsin";
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      state = "Wyoming";
    } else {
      state = "";
      toast.error("No state found matching.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    return state;
  }

  return (
    <>
      <div className="page-content">
        <Container className="containerOnboarding">
          <div className="card-heading mb-2">
            <h5 className="font-size-25 profileFont mb-4 text-center">Welcome to Lavni</h5>
          </div>
          <Row className="p-4">
            <Col xl={12}>
              <Card className="BorderRadiusLeftRight p-5">
                <div className="card-bodyDiv">
                  <div className="card-heading mb-2">
                    <h5 className="font-size-20 profileFont mb-4">Your Personal Information</h5>
                  </div>
                  <Row>
                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          First Name<span style={{ color: "#990000" }}>*</span>
                        </h5>
                      </div>
                      <div className="card-heading mb-4">
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="First name"
                          name="articleTitle"
                          value={user?.firstname || ""}
                          onChange={e => setUser({ ...user, firstname: e.target.value })}
                          required
                        />
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          Last Name<span style={{ color: "#990000" }}>*</span>
                        </h5>
                      </div>
                      <div className="card-heading mb-4">
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Last name"
                          name="articleTitle"
                          value={user?.lastname || ""}
                          onChange={e => setUser({ ...user, lastname: e.target.value })}
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          Gender<span style={{ color: "#990000" }}>*</span>
                        </h5>
                      </div>
                      <div className="card-heading mb-4">
                        <Row>
                          <Col style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="Female"
                                defaultChecked={user?.gender == "Female"}
                                onChange={e => setUser({ ...user, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                Female
                              </label>
                            </div>
                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios2"
                                value="Male"
                                defaultChecked={user?.gender == "Male"}
                                onChange={e => setUser({ ...user, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios2">
                                Male
                              </label>
                            </div>
                            <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios2"
                                value="NonBinary"
                                defaultChecked={user?.gender == "NonBinary"}
                                onChange={e => setUser({ ...user, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios2">
                                Non Binary
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios3"
                                value="Other"
                                defaultChecked={user?.gender == "Other"}
                                onChange={e => setUser({ ...user, gender: e.target.value })}
                              />
                              <label className="form-check-label" htmlFor="exampleRadios3">
                                Other
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2"> Select as ethnicity</h5>
                      </div>

                      <div className="col-xl-12 mb-4">
                        <select
                          className="form-control"
                          value={(setEthenicity as any) || user?.ethnicityId?._id}
                          onChange={e => setUpdateEthenacity(e.target.value)}
                        >
                          <option>Select</option>
                          {ethinicityList &&
                            ethinicityList.map(opt => (
                              <option value={opt._id} key={opt._id}>
                                {opt.ethnicity}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          User Name<span style={{ color: "#990000" }}>*</span>
                        </h5>
                      </div>
                      <div className="card-heading mb-4">
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="User Name"
                          name="User Name"
                          value={user?.username || ""}
                          onChange={e => setUser({ ...user, username: e.target.value })}
                          required
                        />
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          Date Of BirthDay<span style={{ color: "#990000" }}>*</span>
                        </h5>
                      </div>
                      <div className="card-heading mb-3">
                        <Input
                          type="date"
                          className="form-control"
                          name="date"
                          placeholder="Date of birth"
                          value={dateValue}
                          onChange={e => handleDateUpdate(e)}
                          max={moment().format("YYYY-MM-DD")}
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col xl="6">
                            <div className="card-heading mb-2">
                                <h5 className="font-size-14 profileFont mb-2">Primary Phone/Cell<span style={{ color: "#990000" }}>*</span></h5>
                            </div>
                            <div className="card-heading mb-3">
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Primary Phone "
                                    name="Primary Phone"
                                    value={user?.primaryPhone}
                                    onChange={(e) => setUser({ ...user, primaryPhone: e.target.value })}
                                    required
                                />
                            </div>
                        </Col> */}
                    {user?.role == "THERAPIST" && (
                      <Col xl="6">
                        <div className="card-heading mb-2">
                          <h5 className="font-size-14 profileFont mb-2">
                            Timezone<span style={{ color: "#990000" }}>*</span>
                          </h5>
                        </div>
                        <div className="card-heading mb-3">
                          <select className="form-control" value={user?.timeZone} onChange={event => handleTimezone(event)}>
                            {timezones &&
                              timezones.map((zone: { label: string; value: string }, index: number) => {
                                return (
                                  <option key={index} value={zone.value}>
                                    {zone.label}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </Col>
                    )}

                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          Zip Code<span style={{ color: "#990000" }}>*</span>
                        </h5>
                      </div>
                      <div className="card-heading mb-3">
                        <Input
                          // type="number"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Zip Code"
                          name="Zip Code"
                          value={user?.zipCode}
                          onChange={e => handlePostalCode(e.target.value)}
                          required
                        />
                      </div>
                    </Col>

                    {user?.state && (
                      <Col xl="6">
                        <div>
                          <div className="card-heading mb-2">
                            <h5 className="font-size-14 profileFont mb-2">State</h5>
                          </div>
                          <div className="card-heading mb-4 flex">
                            <h5 className="font-size-14 profileFont mb-2">{user?.state}</h5>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xl="6">
                      <div className="card-heading mb-2">
                        <h5 className="font-size-14 profileFont mb-2">
                          Primary Phone Number {(user?.primaryPhone == undefined || null) && <span style={{ color: "#990000" }}>*</span>}
                        </h5>
                      </div>
                      <div className="card-heading mb-4">
                        <PhoneInput
                          placeholder="Primary Phone"
                          value={user?.primaryPhone || ""}
                          defaultCountry="US"
                          onChange={(e: string) => setUser({ ...user, primaryPhone: e })}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col xl={6}></Col>
                  <Col xl={6}>
                    {user?.role == Role.THERAPIST ? (
                      <button
                        type="button"
                        className="btn btn-primary relative mt-3 mb-2"
                        style={{
                          bottom: "0",
                          float: "right",
                        }}
                        onClick={() => {
                          updateTherapistDetails();
                        }}
                      >
                        Save & Continue
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary relative mt-3 mb-2"
                        style={{
                          bottom: "0",
                          float: "right",
                        }}
                        onClick={() => {
                          updateClientDetails();
                        }}
                      >
                        Save & Continue
                      </button>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TherapistPersonalInformationOnboardingPage;
