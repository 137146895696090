import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const ClaimsSaas = () => {
  return (
    <div className="page-content m-bg-color-white">
    <Container fluid>
      <Row>
        <Col lg={8} className="offset-lg-2">
          <Card>
            <CardBody>
              <Row>
                <div className="card-heading">
                  <h1 className="notifications-title">Claims Section To Be Continued...</h1>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  );
};

export default ClaimsSaas;
